import { Button, Modal, ModalContent, ModalTitle, PL } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { connectModal } from '~/hocs';
import { ModalProp } from '~/redux/reducers/modals';

type Props = {
  modal: ModalProp<any>;
};

class ResearchAnnoucementModalComponent extends React.Component<Props> {
  render() {
    return (
      <Modal open={this.props.modal.isOpened} onClose={this.handleCancel}>
        <ModalContent>
          <div
            style={{
              margin: `0 auto`,
              width: 310,
            }}
          >
            <ModalTitle>New, but not really!</ModalTitle>
            <div
              style={{
                padding: '16px 0',
                textAlign: 'center',
              }}
            >
              {/* @ts-expect-error - TS2322 - Type '{ alt: string; name: "watchlist"; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'. */}
              <Illustration alt="" name="watchlist" />
            </div>
            <div
              style={{
                paddingBottom: 16,
              }}
            >
              <PL>
                We have now combined Markets and Discover to give you the new
                and improved Research.
              </PL>
            </div>

            <div
              style={{
                paddingBottom: 32,
              }}
            >
              <PL>
                Search stocks and ETFs, browse Model Portfolios, and keep up
                with market news here.
              </PL>
            </div>

            <div
              style={{
                textAlign: 'center',
              }}
            >
              <Button
                fullWidth
                kind="primary"
                size="large"
                label="Got it"
                onClick={this.handleCancel}
              />
            </div>
          </div>
        </ModalContent>
      </Modal>
    );
  }

  handleCancel = (): void => {
    this.props.modal.hide();
  };
}

const enhancer = connectModal('RESEARCH_ANNOUNCEMENT');

export const ResearchAnnoucementModal = enhancer(
  ResearchAnnoucementModalComponent,
) as any;
