import { Button, HS, PL } from '@m1/liquid-react';
import * as React from 'react';

import { useSupportLink } from '~/hooks/useSupportLink';
import { Grid } from '~/toolbox/grid';
import { Link } from '~/toolbox/link';

export const MaintenancePage = () => {
  const supportLink = useSupportLink('SUPPORT_REQUEST');
  return (
    <Grid
      constrain
      style={{
        paddingTop: 100,
        minWidth: '47%',
      }}
    >
      <Grid.Row xsCenter>
        <Grid.Col xs={5}>
          <HS
            content="We are down momentarily for routine maintenance."
            mb={16}
          />
          <PL
            content="We’re sorry for the inconvenience but will be back up and running shortly."
            mb={8}
          />
          <PL mb={48}>
            If you have an urgent question, please contact our{' '}
            <Link {...supportLink}>Support Team</Link>. Thank you!
          </PL>
          <Button
            kind="primary"
            size="large"
            fullWidth
            label="Go Home"
            onClick={() => {
              // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'Location'.
              window.location = 'https://www.m1.com';
            }}
          />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};
