import { HXS, Box, type TextProps, HS } from '@m1/liquid-react';
import * as React from 'react';

import { usePrevious } from '~/hooks/usePrevious';
import { useSelector } from '~/redux/hooks';

import { formatNumber } from '~/utils';

import { PriceDigit } from './PriceDigit';

export type SecurityCurrentQuotePriceProps = TextProps & {
  isCrypto?: boolean;
  lastPrice: number | null | undefined;
};

export const SecurityCurrentQuotePrice = ({
  isCrypto,
  lastPrice,
}: SecurityCurrentQuotePriceProps) => {
  const value = useSelector((state) => state.securityDetails.snapshot.value);
  const latestPrice = value ?? lastPrice;
  const previouslySeenPrice = usePrevious(lastPrice);

  const bit = React.useMemo<-1 | 0 | 1>(() => {
    if (
      typeof latestPrice === 'number' &&
      typeof previouslySeenPrice === 'number'
    ) {
      if (previouslySeenPrice < latestPrice) {
        return 1;
      }
      if (latestPrice > previouslySeenPrice) {
        return -1;
      }
    }
    return 0;
  }, [latestPrice, previouslySeenPrice]);

  if (typeof latestPrice !== 'number') {
    return <HXS content="$--" />;
  }

  const pieces: Array<string | number> = formatNumber(
    latestPrice,
    isCrypto && latestPrice < 1 ? '0,0.00[00000000]' : '0,0.00',
  )
    .split('')
    .map((piece: string) => {
      const parsed = parseInt(piece, 10);
      return Number.isNaN(parsed) ? piece : parsed;
    });

  return (
    <Box data-testid="selector-current-quote-price">
      <HS as="span" fontWeight={500}>
        $
        {pieces.map((piece, index) => {
          return typeof piece === 'number' ? (
            <PriceDigit bit={bit} key={pieces.length - index} value={piece} />
          ) : (
            <span key={pieces.length - index}>{piece}</span>
          );
        })}
      </HS>
    </Box>
  );
};
