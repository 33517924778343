import * as React from 'react';

import { CollectIdentityInfoStep } from './CollectIdentityInfoStep';

export const CollectSecondaryIdentityInfoStep = () => (
  <CollectIdentityInfoStep
    holder="secondary"
    title="Secondary Applicant Identity Verification"
  />
);
