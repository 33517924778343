import { Flex } from '@m1/liquid-react';
import React from 'react';

import { DepositPill } from './DepositPill';

type DepositPillSectionProps = {
  transferAmounts: Array<number | undefined>;
  onClick: (amount: number | undefined) => void;
};

export const DepositPillSection = ({
  transferAmounts,
  onClick,
}: DepositPillSectionProps) => (
  <Flex justifyContent="center" mt={16}>
    {transferAmounts.map((amount) => (
      <DepositPill key={amount} amount={amount} onClick={onClick} />
    ))}
  </Flex>
);
