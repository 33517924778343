import * as React from 'react';

// FIXME(Wolf): Why are we importing the whole library here?
import 'react-plaid-link';
import type { PlaidLinkError, PlaidLinkOnExitMetadata } from 'react-plaid-link';

import { SelectPlaidInnerLink } from '~/components/SelectPlaidLink/SelectPlaidInnerLink';
import { useGeneratePlaidIncomeLinkTokenMutation } from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useSentry } from '~/hooks/useSentry';
import { completePlaidIncomeVerification } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { useToast } from '~/toasts';

export const PlaidVerificationFlow = () => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const analytics = useAnalytics();
  const sentry = useSentry();

  const [generatePlaidIncomeLinkToken, { data }] =
    useGeneratePlaidIncomeLinkTokenMutation();
  const generateToken = () => {
    generatePlaidIncomeLinkToken({
      variables: {
        input: {
          incomeEnableMultipleItems: true,
        },
      },
      onCompleted: (data) => {
        if (!data.generatePlaidIncomeLinkToken?.didSucceed) {
          sentry.message(
            'PlaidLink: An error occurred while attempting to generate link token',
            {
              level: 'error',
              extra: {
                error: data.generatePlaidIncomeLinkToken?.outcome,
              },
            },
          );
        }
      },
    });
  };

  const onExit = React.useCallback(
    (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
      if (error) {
        sentry.message('PlaidLink exited with error', {
          level: 'warning',
          extra: {
            error,
            metadata,
          },
        });
        addToast({
          content:
            'There was an issue verifying your income through Plaid. You can try again or contact Client Support for help.',
          kind: 'alert',
          duration: 'long',
        });
      } else {
        sentry.message('Plaid Income verification exited without error', {
          extra: {
            metadata,
          },
        });
      }
      analytics.recordEvent('m1_personal_loan_income_exited');

      dispatch(
        completePlaidIncomeVerification({
          onExit: true,
          onSuccess: false,
        }),
      );
    },
    [dispatch, sentry],
  );

  const onSuccess = React.useCallback(() => {
    analytics.recordEvent('m1_personal_loan_income_result_received');
    dispatch(
      completePlaidIncomeVerification({
        onExit: false,
        onSuccess: true,
      }),
    );
  }, [dispatch]);

  React.useEffect(() => {
    generateToken();
  }, []);

  const linkToken =
    data?.generatePlaidIncomeLinkToken?.outcome?.plaidIncomeLinkToken;

  if (!linkToken) {
    return null;
  }

  return (
    <SelectPlaidInnerLink
      onSuccess={onSuccess}
      onExit={onExit}
      linkToken={linkToken}
    />
  );
};
