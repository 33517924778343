import { Flex, PXL, styled } from '@m1/liquid-react';
import * as React from 'react';

import { DottedArrow } from '~/components/DottedArrow';
import { FulfillmentConditionInlineDescriptionTags } from '~/components/FulfillmentConditionInlineDescriptionTags';
import { TransferParticipantIcon } from '~/components/transfers/TransferParticipantIcon';
import { useSmartTransferWaterfallEntryQuery } from '~/graphql/hooks';
import { TransferParticipantFragment } from '~/graphql/types';
import type { SmartTransferFulfillmentConditionInput } from '~/redux/actions/smartTransfer/smartTransferActions.types';
import { Spinner } from '~/toolbox/spinner';

type SmartTransferWaterfallEntryProps = {
  balanceTriggerType: 'OVER_BALANCE' | 'UNDER_BALANCE';
  contraParticipantId: string | null | undefined;
  disabled?: boolean;
  focusAccountId: string;
  fulfillmentCondition: SmartTransferFulfillmentConditionInput;
  isInEditOrderMode: boolean;
  onEditContraClick: (arg0: string) => void;
};

const StyledPXLTransferParticipantName = styled(PXL)`
  overflow: hidden;
  max-width: 240px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
`;

const StyledPXLEditCTA = styled(PXL)`
  color: ${(props) => props.theme.colors.primary};
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
`;

export const SmartTransferWaterfallEntry = ({
  balanceTriggerType,
  contraParticipantId,
  disabled,
  focusAccountId,
  fulfillmentCondition,
  isInEditOrderMode,
  onEditContraClick,
}: SmartTransferWaterfallEntryProps) => {
  const isUnderBalance = balanceTriggerType === 'UNDER_BALANCE';
  const onEditClick = () => {
    if (contraParticipantId) {
      onEditContraClick(contraParticipantId);
    }
  };

  const { data, loading } = useSmartTransferWaterfallEntryQuery({
    variables: {
      contraParticipantId: contraParticipantId as string,
      focusAccountId,
    },
    skip: !contraParticipantId,
  });

  if (loading) {
    return <Spinner />;
  }
  if (!data?.focus || !data.contra) {
    return null;
  }

  const focus = data.focus as TransferParticipantFragment;
  const contra = data.contra as TransferParticipantFragment;
  return (
    <Flex justifyContent="space-between" width="100%">
      <Flex flexDirection="column">
        <Flex alignItems="center">
          <TransferParticipantIcon
            disabled={disabled}
            transferParticipant={focus}
          />
          <DottedArrow direction={isUnderBalance ? 'LEFT' : 'RIGHT'} />
          <TransferParticipantIcon
            disabled={disabled}
            transferParticipant={contra}
          />
          <StyledPXLTransferParticipantName
            content={contra.transferParticipantName}
            ml={16}
          />
        </Flex>
        <FulfillmentConditionInlineDescriptionTags
          disabled={disabled}
          mt={8}
          {...fulfillmentCondition}
        />
      </Flex>
      {!isInEditOrderMode && (
        <StyledPXLEditCTA onClick={onEditClick} content="Edit" />
      )}
    </Flex>
  );
};
