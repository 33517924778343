import * as React from 'react';
import { Field } from 'redux-form';

import { withProps } from '~/hocs';
import { Radio } from '~/toolbox/radio';

// @ts-expect-error - TS7031 - Binding element 'input' implicitly has an 'any' type. | TS7031 - Binding element 'meta' implicitly has an 'any' type.
const component = ({ input, meta, ...rest }) => {
  return React.createElement(Radio.Group, {
    ...rest,
    ...meta,
    ...input,
    error: readError(meta),
  });
};

// @ts-expect-error - TS7006 - Parameter 'meta' implicitly has an 'any' type.
const readError = (meta) => {
  return meta.submitFailed ? meta.error : null;
};

const enhancer = withProps({
  component,
});

export const RadioGroupField = enhancer(Field) as any;
