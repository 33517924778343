import { Box, styled, css, keyframes } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';

// Styled Components
const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const shrink = keyframes`
  100% {
    padding: 0;
    margin: 0;
    height: 0;
  }
`;

export const StyledIcon = styled(Box)<{ inline?: boolean }>`
  padding: ${(props) => (props.inline ? '0 8px 0 0' : '4px 16px 0 8px')};
`;

export const StyledRootBox = styled(Box)<{
  isDismissed: boolean;
}>`
  animation: ${(props) =>
    props.isDismissed &&
    css`
      ${fadeOut} 0.2s linear forwards, ${shrink} 0.2s linear 0.15s forwards
    `};
  background: ${(props) => props.theme.colors.backgroundNeutralSecondary};
  background-color: ${(props) => props.theme.colors.backgroundNeutralSecondary};
  border: ${(props) => `1px solid ${props.theme.colors.borderMain}`};
  border-radius: 8px;
  display: flex;
  padding: 16px;
`;

export const StyledCloseIcon = styled(Icon)`
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.1s ease-in-out;
  z-index: 1;

  &:hover {
    opacity: 1;
  }
`;

export const StyledContent = styled(Box)`
  flex: 1 1 auto;
`;

export const StyledInlineAnnouncement = styled(Box)<{
  isDescriptionEmpty: boolean;
}>`
  flex-direction: column;
  width: 100%
    ${({ isDescriptionEmpty }) =>
      isDescriptionEmpty
        ? css`
            display: flex;
            align-self: center;
          `
        : css`
            display: initial;
            align-self: initial;
          `};
`;

export const StyledTimeDescription = styled(Box)<{
  timeDescription: string | null | undefined;
}>`
  display: flex;
  margin-top: 16px;
  justify-content: ${({ timeDescription }) =>
    timeDescription ? 'space-between' : 'flex-end'};
`;
