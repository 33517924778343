import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import {
  CheckSystemStatusDocument,
  CheckSystemStatusQueryResult,
} from '~/graphql/hooks';

// @ts-expect-error - TS2305 - Module '"~/graphql/types"' has no exported member 'SystemFunctionalityEnumValues'.
import { SystemFunctionalityEnumValues, SystemInfo } from '~/graphql/types';
import { delay } from '~/utils';

import { apolloQuerySaga } from '../apolloQuerySaga';

const POLL_INTERVAL = 1000 * 60;

export function* pollSystemStatus(): SagaIterator<void> {
  let hasFetched = false;

  // eslint-disable-next-line
  while (true) {
    yield call(fetchSystemInfo, hasFetched);
    hasFetched = true;
    yield call(delay, POLL_INTERVAL);
  }
}

export function* isSystemBlocked(): SagaIterator<boolean> {
  const system = yield call(fetchSystemInfo);
  return Boolean(
    system &&
      Array.isArray(system.blockedFunctionality) &&
      system.blockedFunctionality.length === 0,
  );
}

export function* isFunctionBlocked(
  systemFunction: SystemFunctionalityEnumValues,
): SagaIterator<boolean> {
  const system = yield call(fetchSystemInfo);
  if (
    system &&
    Array.isArray(system.blockedFunctionality) &&
    (system.blockedFunctionality.length === 0 ||
      system.blockedFunctionality.includes(systemFunction))
  ) {
    return true;
  }

  return false;
}

function* fetchSystemInfo(
  force: boolean = false,
): SagaIterator<SystemInfo | null | undefined> {
  let system: CheckSystemStatusQueryResult | null | undefined = null;
  try {
    system = yield call(apolloQuerySaga, {
      query: CheckSystemStatusDocument,
      fetchPolicy: force ? 'no-cache' : 'cache-first',
    });
  } catch (e: any) {
    // Schema guarantees system will be there, so the only way system would
    // still be null is if the query failed. Let's not have the app bomb
    // entirely if so.
  }
  return system?.data?.system;
}
