import { Box, PL, Card } from '@m1/liquid-react';
import * as React from 'react';

import { useDisclosuresForCryptoPageQuery } from '~/graphql/hooks';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

export const DisclosuresForCryptoPage = () => {
  const { data, loading } = useDisclosuresForCryptoPageQuery();
  if (loading) {
    return <Spinner />;
  }
  if (!data?.viewer.invest?.requiredDocuments) {
    return (
      <PL content="Crypto disclosures are currently unavailable. Please try again later." />
    );
  }
  return (
    <Card p={32}>
      {data.viewer.invest.requiredDocuments.documents.map((document) => {
        return (
          <Box key={`${document.title}`} mt={4}>
            <Link to={document.url} target="_blank">
              {document.title}
            </Link>
          </Box>
        );
      })}
    </Card>
  );
};
