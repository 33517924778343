import { makeLimit } from '~/components/research/Screener/utils';
import {
  FundLimitTypeEnum,
  HistoricalQuotePeriodEnum,
  FundScreenerQueryVariables,
  SortDirectionEnum,
  FundSortTypeEnum,
} from '~/graphql/types';

export const defaultLimits = {
  FUND_TOTAL_ASSETS: makeLimit(FundLimitTypeEnum.FundTotalAssets),
  FUND_NET_EXPENSE_RATIO: makeLimit(FundLimitTypeEnum.FundNetExpenseRatio),
  FUND_DIVIDEND_YIELD: makeLimit(FundLimitTypeEnum.FundDividendYield),
};

export const INITIAL_FUND_SCREENER_VARIABLES: FundScreenerQueryVariables = {
  first: 20,
  period: HistoricalQuotePeriodEnum.OneYear,
  after: null,
  sort: {
    direction: SortDirectionEnum.Desc,
    type: FundSortTypeEnum.FundTotalAssets,
  },
  filterCategory: [],
  limit: [],
};

export const PERIOD_TO_SORT: Partial<
  Record<HistoricalQuotePeriodEnum, FundSortTypeEnum>
> = {
  [HistoricalQuotePeriodEnum.OneYear]: FundSortTypeEnum.PriceChange_1Y,
  [HistoricalQuotePeriodEnum.TwoYears]: FundSortTypeEnum.PriceChange_2Y,

  [HistoricalQuotePeriodEnum.FiveYears]: FundSortTypeEnum.PriceChange_5Y,
};
