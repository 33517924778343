export const PHONE_VERIFICATION_ACTIONS = Object.freeze({
  FINISHED_PHONE_VERIFICATION: 'FINISHED_PHONE_VERIFICATION' as const,
  INITIATE_PHONE_VERIFICATION_SUCCEEDED:
    'INITIATE_PHONE_VERIFICATION_SUCCEEDED' as const,
});

export type FinishedPhoneVerification = {
  payload: {
    outcome: any;
  };
  type: typeof PHONE_VERIFICATION_ACTIONS.FINISHED_PHONE_VERIFICATION;
};

export type InitiatePhoneVerificationSucceededAction = {
  payload: unknown;
  type: typeof PHONE_VERIFICATION_ACTIONS.INITIATE_PHONE_VERIFICATION_SUCCEEDED;
};

export const initiatePhoneVerificationSucceeded = (
  payload: InitiatePhoneVerificationSucceededAction['payload'],
): InitiatePhoneVerificationSucceededAction => ({
  payload,
  type: PHONE_VERIFICATION_ACTIONS.INITIATE_PHONE_VERIFICATION_SUCCEEDED,
});

export const finishedPhoneVerification = (
  payload: FinishedPhoneVerification['payload'],
): FinishedPhoneVerification => ({
  type: PHONE_VERIFICATION_ACTIONS.FINISHED_PHONE_VERIFICATION,
  payload,
});

export type PhoneVerificationAction =
  | FinishedPhoneVerification
  | InitiatePhoneVerificationSucceededAction;
