import { Box, Flex, HM } from '@m1/liquid-react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { BackArrow } from '~/components/BackArrow';
import { useOpenInvestJointAccountWizardContext } from '~/flows/components/OpenInvestJointAccountWizard/hooks/useOpenInvestJointAccountWizardContext';
import { StyledOption } from '~/pages/onboarding/financial-suitability-wizard/consts';

import { submitReactFormData } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

import { TimeHorizonEnumDropdownOptions } from '~/static-constants';

export interface TimeHorizonValues {
  timeHorizon: string;
}

export const TimeHorizon = () => {
  const dispatch = useDispatch();

  const submittedFormValues = useSelector((state) => {
    if (!state.reactHookForm.openJointInvestAccountTimeHorizon) {
      return {
        timeHorizon: undefined,
      };
    }

    return state.reactHookForm.openJointInvestAccountTimeHorizon;
  });

  const { setValue, handleSubmit } = useForm<TimeHorizonValues>({
    defaultValues: submittedFormValues,
  });

  const wiz = useOpenInvestJointAccountWizardContext();

  const onSubmit = (data: TimeHorizonValues) => {
    dispatch(submitReactFormData({ openJointInvestAccountTimeHorizon: data }));
    wiz.next();
  };

  const handleOptionClick = (name: string) => {
    setValue('timeHorizon', name);
    handleSubmit(onSubmit)();
  };

  return (
    <Flex maxWidth={588} flexDirection="column" mx="auto">
      <Box pt={32}>
        <BackArrow content="Back" onClick={() => wiz.back()} />
      </Box>
      <HM mt={48} mb={32}>
        How long are you planning to invest?
      </HM>
      <form onSubmit={handleSubmit(onSubmit)}>
        {TimeHorizonEnumDropdownOptions.map(({ name, description }) => (
          <StyledOption
            tabIndex={0}
            key={name}
            onClick={() => handleOptionClick(name)}
            onKeyDown={(event: React.KeyboardEvent) => {
              if (event.key === 'Enter' || event.code === 'Space') {
                handleOptionClick(name);
              }
            }}
          >
            {description}
          </StyledOption>
        ))}
      </form>
    </Flex>
  );
};
