import * as React from 'react';

import { CommonTransactionAmountCell } from '~/components/CommonTransactionsTable/CommonTransactionAmountCell';
import { CommonTransactionDescriptionCell } from '~/components/CommonTransactionsTable/CommonTransactionDescriptionCell';
import { CommonTransactionValueCell } from '~/components/CommonTransactionsTable/CommonTransactionValueCell';
import { CreditCardRewardsPill } from '~/components/credit-card/CreditCardRewardsAppPill';
import {
  CommonTransaction,
  CommonTransactionStatusEnum,
  CreditCardCommonTransactionMetadata,
} from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';

export const CreditCardCommonTransactionRow = ({
  transaction,
  handleClick,
  isHighlighted,
}: {
  transaction: CommonTransaction & {
    metadata: CreditCardCommonTransactionMetadata;
  };
  handleClick: (transactionId: string) => void;
  isHighlighted: boolean;
}) => {
  return (
    <GridTable.Row
      isHighlighted={isHighlighted}
      usesCaretLeftIndicator
      onClick={() => handleClick(transaction?.id)}
      className="common-transaction-table-row"
    >
      <GridTable.Cell
        content={
          <CommonTransactionDescriptionCell
            isHighlighted={isHighlighted}
            logoUrl={transaction?.logoUrl}
            logoStyles={{ width: '32px', height: '32px' }}
            icon={transaction?.icon}
            content={transaction?.title}
          />
        }
      />
      <GridTable.Cell
        content={<CommonTransactionValueCell content={transaction?.subtitle} />}
        textAlign="left"
      />
      <GridTable.Cell
        content={
          transaction?.metadata?.rewardTierPill && (
            <CreditCardRewardsPill
              appPill={transaction?.metadata?.rewardTierPill}
            />
          )
        }
        textAlign="right"
      />
      <GridTable.Cell
        className="stickyColumn"
        content={
          <CommonTransactionAmountCell
            pill={transaction?.pill}
            amount={transaction?.amount}
            strikeThrough={
              transaction?.status === CommonTransactionStatusEnum.Canceled ||
              transaction?.status === CommonTransactionStatusEnum.Failed
            }
          />
        }
      />
    </GridTable.Row>
  );
};
