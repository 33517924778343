import * as React from 'react';

/**
 * Implements setTimeout to allow for delayed function calls that allow something
 * such as visual fade out effects such as those utilized by css transition
 */

export const useDelay = (
  delayFunction: (...args: Array<any>) => any,
  delay: number | null | undefined,
): void => {
  const ref = React.useRef();

  React.useEffect(() => {
    // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'undefined'.
    ref.current = window.setTimeout(delayFunction, delay || 2000);

    return () => window.clearTimeout(ref.current);
  }, [delayFunction, delay]);
};
