import {
  PM,
  Flex,
  styled,
  HXS,
  PS,
  Button,
  Modal,
  ModalContent,
} from '@m1/liquid-react';
import * as React from 'react';

import { useHomeDisclosuresQuery } from '~/graphql/hooks';
import { RichText } from '~/lens-toolbox/RichText/RichText';

import { COPYRIGHT_COPY } from '~/static-constants';

const StyledDisclosureContainer = styled(Flex)`
  flex-direction: column;
  padding: 8px 16px;
  gap: 8px;
  height: 72px;
  border-top: 1px solid ${({ theme }) => theme.colors.borderMain};
  max-width: 100%;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.SMALL}) {
    flex-direction: row;
    padding: 24px 80px;
    justify-content: space-between;
  }
`;

export const HomeDisclosures = () => {
  const [openDisclosuresModal, setOpenDisclosuresModal] = React.useState(false);
  const { data } = useHomeDisclosuresQuery();
  const disclosuresLinkText =
    data?.viewer?.overview?.disclosuresFooter?.linkText;
  const paragraphs = data?.viewer?.overview?.disclosuresFooter?.paragraphs;

  return (
    <StyledDisclosureContainer>
      <Button
        kind="link"
        label={disclosuresLinkText}
        onClick={() => setOpenDisclosuresModal(true)}
        underline
      />

      <Modal
        open={openDisclosuresModal}
        onClose={() => setOpenDisclosuresModal(false)}
      >
        <ModalContent>
          <HXS content="Disclosures" fontWeight={300} marginBottom={24} />
          {paragraphs?.map((text, i) => (
            <PS key={i} mb={16} fontWeight={400}>
              {Array.isArray(text?.content) &&
              typeof text?.content?.length === 'number' &&
              text.content.length > 1 ? (
                <RichText
                  containerProps={{
                    display: 'inline-block',
                  }}
                  textProps={{
                    color: 'foregroundNeutralMain',
                    marginLeft: 0,
                  }}
                  linkProps={{
                    font: 'PS',
                    fontWeight: 400,
                    kind: 'secondary',
                    underline: true,
                  }}
                  richText={text}
                />
              ) : (
                text && (
                  <RichText
                    textProps={{
                      color: 'foregroundNeutralMain',
                      marginLeft: 0,
                    }}
                    linkProps={{
                      font: 'PS',
                      fontWeight: 400,
                      kind: 'secondary',
                      underline: true,
                    }}
                    richText={text}
                  />
                )
              )}
            </PS>
          ))}
        </ModalContent>
      </Modal>
      <PM
        color="foregroundNeutralSecondary"
        fontWeight={600}
        content={COPYRIGHT_COPY}
      />
    </StyledDisclosureContainer>
  );
};
