import Basil from 'basil.js';
import EventEmitter from 'eventemitter3';

type ValidKeys = 'accessToken' | 'refreshToken';

export class AuthManager extends EventEmitter {
  keys: Array<ValidKeys> = ['accessToken', 'refreshToken'];
  _basil: Basil;

  constructor() {
    super();

    this._basil = new Basil({
      namespace: 'm1_finance_auth',
      storages: ['session', 'memory', 'cookie'],
      expireDays: 1,
    });

    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.key === 'getSessionStorage') {
        this._broadcastSession();
      } else if (event.key === 'updateSessionStorage') {
        // this._updateSession(event);
      } else if (event.key === 'clearSessionStorage') {
        this._clearSession();
      }
    });
  }

  load(): Promise<void> {
    return new Promise((resolve) => {
      // @ts-expect-error - TS7006 - Parameter 'event' implicitly has an 'any' type.
      const callback = (event) => {
        if (event.key === 'sessionStorage' && event.newValue) {
          this._loadIntoSession(event);
        }
      };

      window.setTimeout(() => {
        window.removeEventListener('storage', callback);
        resolve();
      }, 500);

      window.addEventListener('storage', callback);

      try {
        // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
        window.localStorage.setItem('getSessionStorage', Date.now());
      } catch (e: any) {
        // Do nothing
      }
    });
  }

  updateRequiredAuth(
    auth:
      | { accessToken: string | null; refreshToken: string | null }
      | null
      | undefined,
  ): void {
    if (
      typeof auth?.accessToken !== 'string' ||
      typeof auth.refreshToken !== 'string'
    ) {
      throw new Error('Unable to authenticate.');
    }
    const { accessToken, refreshToken } = auth;
    this.update({ accessToken, refreshToken });
  }

  update(obj: Record<ValidKeys, string>): void {
    for (const key of this.keys) {
      this._basil.set(key, obj[key]);
    }
    this.emit('updated', obj);
  }

  clear() {
    this._clearSession();
    try {
      // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
      window.localStorage.setItem('clearSessionStorage', Date.now());
    } catch (e: any) {
      // Do nothing
    }
  }

  get(key: ValidKeys): string | null | undefined {
    return this._basil.get(key);
  }

  _broadcastSession(): void {
    try {
      localStorage.setItem(
        'sessionStorage',
        JSON.stringify(this._getFromSession()),
      );
      localStorage.removeItem('sessionStorage');
    } catch (e: any) {
      // Do nothing
    }
  }

  _loadIntoSession(event: StorageEvent): void {
    try {
      if (event.newValue) {
        const data = JSON.parse(event.newValue);
        for (const key in data) {
          if (data.hasOwnProperty(key) && data[key]) {
            this._basil.set(key, data[key]);
          }
        }
        this.emit('loaded', data);
      }
    } catch (e: any) {
      // Do nothing.
    }
  }

  _updateSession(obj: Record<string, unknown>): void {
    window.localStorage.setItem('updateSessionStorage', JSON.stringify(obj));
  }

  _getFromSession() {
    const obj = {};
    for (const key of this.keys) {
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'ValidKeys' can't be used to index type '{}'.
      obj[key] = this._basil.get(key);
    }
    return obj;
  }

  _clearSession(): void {
    for (const key of this.keys) {
      this._basil.set(key, null);
    }
  }
}
