import { Box, Button, HM, PXL, Flex } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { AppContext } from '~/AppContext';
import { BackButton } from '~/flows/components/personal-loans/application/components/BackButton';
import { PersonalLoansFinancialInformationForm } from '~/forms';
import { useSelector } from '~/redux/hooks';
import { Divider } from '~/toolbox/divider';

type FinancialInformationProps = {
  onFinishStep: (arg0: {
    annualIncome: number;
    employmentStatus: string;
    monthlyHousingCosts: number;
  }) => void;
};

export const FinancialInformation = ({
  onFinishStep,
}: FinancialInformationProps) => {
  const { analytics } = React.useContext(AppContext);
  const { annualIncome, monthlyHousingCosts, employmentStatus } = useSelector(
    (state) => state.newFlows.PERSONAL_LOANS_APPLICATION,
  );

  // @ts-expect-error - TS7006 - Parameter 'financialInformation' implicitly has an 'any' type.
  const submit = (financialInformation) => onFinishStep(financialInformation);

  const initialValues = {
    annualIncome,
    monthlyHousingCosts,
    employmentStatus,
  };

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex flexDirection="column" width="50%">
          <Box pt={32}>
            <BackButton />
          </Box>
          <HM pt={16}>Financial Information</HM>
          <PXL pt={16} pb={64}>
            Help us understand your full financial situation.
          </PXL>
          <PersonalLoansFinancialInformationForm
            onSubmit={(e: React.ChangeEvent<HTMLInputElement>): void => {
              analytics.recordEvent(
                'm1_personal_loan_financial_profile_completed',
              );
              submit(e);
            }}
            initialValues={initialValues}
          />
        </Flex>
        <Flex>
          <Illustration name="loanFinancialId" width={480} height={360} />
        </Flex>
      </Flex>
      <Divider spacing="relaxed" />
      <Box>
        <Button
          form="personalLoansFinancialInformationForm"
          kind="primary"
          size="large"
          label="Continue"
          type="submit"
        />
      </Box>
    </>
  );
};
