import { Box, Button, Flex, HM, PXL } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import React from 'react';

import { useNavigate } from '~/hooks/useNavigate';

export const SkippedInitialDepositStep = () => {
  const navigate = useNavigate();
  return (
    <Box
      flexDirection="column"
      margin="0 auto"
      display="flex"
      alignItems="center"
      width={588}
    >
      <Flex flexDirection="column" textAlign="left" mt={88}>
        <Box alignSelf="center">
          <Illustration name="rememberToFund" />
        </Box>
        <HM mt={56}>Congrats! It’s time to build wealth.</HM>
        <PXL mt={24} width="588">
          Your invest account is now open. When you’re ready to trade, just
          remember to fund your account.
        </PXL>
        <Flex mt={48}>
          <Button
            size="large"
            label="Visit my M1 dashboard"
            onClick={() => navigate({ to: '/d/home' })}
          />
          <Button
            kind="text"
            ml={24}
            onClick={() =>
              navigate({
                to: '/d/invest/portfolio-organizer',
                options: {
                  state: {
                    event: {
                      initConfig: {
                        confirmationDialog: {
                          showApplicableLocations: false,
                        },
                        mode: 'NEW_ROOT_PIE',
                        returnTo: '/d/invest',
                        sliceableIds: [],
                      },
                      type: 'INITIALIZE_ORGANIZER',
                    },
                  },
                },
              })
            }
            label="Build my first portfolio"
          />
        </Flex>
      </Flex>
    </Box>
  );
};
