import * as React from 'react';

export const useApolloPrevNext = ({
  refetch,
}: {
  refetch: (args: any) => void;
}) => {
  const [cursors, setCursors] = React.useState<string[]>([]);

  const handlePreviousPage = React.useCallback(() => {
    const newCursors = cursors.slice(0, -1);
    const after = newCursors[newCursors.length - 1] || null;
    refetch({
      after,
    });

    setCursors(newCursors);
  }, [cursors, refetch]);

  const handleNextPage = React.useCallback(
    (endCursor: string | null) => {
      if (typeof endCursor === 'string') {
        const after = endCursor;
        refetch({
          after,
        });
        setCursors((prevCursors) => [...prevCursors, after]);
      }
    },
    [refetch],
  );

  const hasPreviousPage = cursors.length > 0;

  return { handleNextPage, handlePreviousPage, hasPreviousPage };
};
