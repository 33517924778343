import { ApolloError } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  HXS,
  Image,
  Modal,
  ModalContent,
  PL,
  PS,
} from '@m1/liquid-react';
import * as React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import {
  useAccountConfigurationDividendsPageQuery,
  useSetAccountConfigurationForDividendsMutation,
  useSetAccountConfigurationForDividendsForHoldingMutation,
} from '~/graphql/hooks';
import {
  AccountConfigDividendSettingType,
  AccountConfigurationFragment as Account,
  SetAccountConfigurationForDividendsMutation,
} from '~/graphql/types';
import { useActiveAccount } from '~/hooks/useActiveAccount';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { useToast } from '~/toasts';
import { Divider } from '~/toolbox/divider';
import { GridTable } from '~/toolbox/grid-table';

import { Spinner } from '~/toolbox/spinner';

import { DividendSettings } from './DividendSettings';

type AccountConfigurationForDividends = NonNullable<
  NonNullable<NonNullable<Account['configuration']>['dividends']>
>;

type Holding = NonNullable<
  NonNullable<AccountConfigurationForDividends>['holdings']
>[number];

export const DividendManagementPage = () => {
  const activeAccountId = useActiveAccount(true);

  const { data } = useAccountConfigurationDividendsPageQuery({
    variables: {
      accountId: activeAccountId,
    },
  });

  if (!data) {
    return <Spinner />;
  }
  if (!data.account) {
    throw new Error('Unable to resolve Account.');
  }
  if (data.account.__typename !== 'Account') {
    throw new Error('Unexpected node type.');
  }
  if (!data.account?.configuration) {
    // Since account configuration is currently behind a FF, the non-existence of a configuration
    // isn't necessarily an error.
    // TODO: Once we have full release, this should become a logged error.
    return null;
  }

  return <DividendManagementSettings account={data.account} />;
};

export function DividendManagementSettings({ account }: { account: Account }) {
  const dividends = account.configuration?.dividends;
  if (!dividends) {
    throw new Error(
      'Unable to render `DividendManagementSettings` without `AccountConfigurationDividends` data.',
    );
  }

  const { addToast } = useToast();

  const form = useForm<{
    selected: AccountConfigDividendSettingType | null | undefined;
    transferAccountId: string | null;
    securities: Record<
      string,
      {
        valueLabel: string;
        selected: AccountConfigDividendSettingType;
        transferAccountId: string | null;
      }
    >;
  }>({
    defaultValues: {
      selected: dividends.selectedSettingType,
      transferAccountId:
        dividends.settings
          ?.find(
            (setting) =>
              setting.value ===
              AccountConfigDividendSettingType.TransferToAccount,
          )
          ?.accounts?.find((account) => account?.selected)?.transferParticipant
          ?.id || null,
      securities: dividends.holdings?.reduce(
        (securitySettings, holding) => ({
          ...securitySettings,
          [holding.position.symbol as string]: {
            valueLabel: holding.settings?.find((setting) => setting.selected)
              ?.valueLabel,
            selected: holding.settings?.find((setting) => setting.selected)
              ?.value,
            transferAccountId:
              holding.settings
                ?.find((setting) => setting.selected)
                ?.accounts?.find((account) => account?.selected)
                ?.transferParticipant?.id || null,
          },
        }),
        {},
      ),
    },
  });

  const [mutate] = useSetAccountConfigurationForDividendsMutation();

  const formState = form.watch();
  const selected = formState.selected;

  const onConfigurationMutationComplete = ({
    didSucceed,
    errorMessage,
    outcome,
  }: SetAccountConfigurationForDividendsMutation['accountConfigurationDividends']) => {
    if (didSucceed) {
      if (outcome?.successMessage) {
        addToast({
          content: outcome.successMessage,
          kind: 'success',
          duration: 'short',
        });
      }
    } else if (errorMessage) {
      addToast({
        content: errorMessage,
        kind: 'alert',
      });
    }
  };

  const onConfigurationMutationError = () => {
    addToast({
      content: 'An error has occurred. Please try again later.',
      kind: 'alert',
    });
  };

  // This effect handles account-level settings changes
  React.useEffect(() => {
    const isTransferToAccount =
      formState.selected === AccountConfigDividendSettingType.TransferToAccount;
    const changedSelectedAccount =
      isTransferToAccount &&
      formState.transferAccountId !== null &&
      dividends.settings
        ?.find(
          (setting) =>
            setting.value ===
            AccountConfigDividendSettingType.TransferToAccount,
        )
        ?.accounts?.find((account) => account?.selected === true)
        ?.transferParticipant?.id !== formState.transferAccountId;
    const accountLevelSettingChanged =
      formState.selected && form.formState.isDirty && !isTransferToAccount;

    if (accountLevelSettingChanged || changedSelectedAccount) {
      mutate({
        variables: {
          input: {
            accountId: account.id,
            type: formState.selected!,
            ...(isTransferToAccount && {
              transferAccountId: formState.transferAccountId,
            }),
          },
        },
        onCompleted: (data) =>
          onConfigurationMutationComplete(data.accountConfigurationDividends),
        onError: onConfigurationMutationError,
      });
    }
  }, [formState.selected, formState.transferAccountId]);

  return (
    <FormProvider {...form}>
      <Box pb={64} pt={24} maxWidth={800}>
        <Flex flexDirection="column" gap={12}>
          <HXS>{dividends.heading}</HXS>
          {dividends.subheading && (
            <PL color="foregroundNeutralSecondary">{dividends.subheading}</PL>
          )}
          {dividends.contextualLink && (
            <LinkableLink linkable={dividends.contextualLink} />
          )}
        </Flex>
        <Flex flexDirection="column" gap={16} mt={32}>
          {selected && (
            <DividendSettings
              settings={dividends.settings ?? []}
              value={selected}
            />
          )}
          {selected === AccountConfigDividendSettingType.CustomBySymbol && (
            <GridTable
              gridTemplateColumns="auto 80px"
              css={{ minWidth: 300, marginTop: 16 }}
            >
              <GridTable.HeaderRow>
                <GridTable.HeaderCell label="Name" />
                <GridTable.HeaderCell align="right" label="Method" />
              </GridTable.HeaderRow>
              {dividends.holdings?.map((holding) => (
                <SettingForHoldingNavigableRow
                  account={account}
                  holding={holding}
                  key={holding.position.symbol}
                  onCompleted={(data) =>
                    onConfigurationMutationComplete(
                      data.accountConfigurationDividendsForHoldings,
                    )
                  }
                  onError={onConfigurationMutationError}
                />
              ))}
            </GridTable>
          )}
        </Flex>
        {dividends.footerText && (
          <Flex flexDirection="column" gap={8} mt={64}>
            {dividends.footerText.map((text, index) => (
              <PS
                color="foregroundNeutralSecondary"
                content={text}
                key={index}
              />
            ))}
          </Flex>
        )}
      </Box>
    </FormProvider>
  );
}

function SettingForHoldingNavigableRow({
  account,
  holding,
  onCompleted,
  onError,
}: {
  account: Account;
  holding: Holding;
  onCompleted?: (result: any) => void;
  onError?: (error: ApolloError) => void;
}) {
  const form = useFormContext();
  const [mutate] = useSetAccountConfigurationForDividendsForHoldingMutation();
  // The persisted (saved) setting for the holding
  const selected = holding.settings?.find((s) => s.selected)?.value;
  // The "live" (before the "save" button has been pressed) value of the setting
  const formValue = form.watch('securities')[holding.position.symbol as string];

  React.useEffect(() => {
    // Null out the `transferAccountId` if the setting is changed anything other than `TransferToAccount`
    if (
      formValue.selected !== AccountConfigDividendSettingType.TransferToAccount
    ) {
      form.setValue(
        `securities.${holding.position.symbol}.transferAccountId`,
        null,
      );
    } else if (formValue.transferAccountId === null) {
      // Set the `transferAccountId` to the first value if the value is changed back to `TransferToAccount`
      form.setValue(
        `securities.${holding.position.symbol}.transferAccountId`,
        holding.settings
          ?.find(
            (s) =>
              s.value === AccountConfigDividendSettingType.TransferToAccount,
          )
          ?.accounts?.find((a) => a && a.selected)?.transferParticipant?.id ??
          null,
      );
    }
    // Update the `valueLabel` to match the selected setting
    form.setValue(
      `securities.${holding.position.symbol}.valueLabel`,
      holding.settings?.find((s) => s.value === formValue.selected)?.valueLabel,
    );
  }, [formValue.selected]);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const onCloseModal = () => {
    // Revert back to original setting if not saved
    if (formValue.selected !== selected) {
      form.setValue(`securities.${holding.position.symbol}.selected`, selected);
      form.setValue(
        `securities.${holding.position.symbol}.valueLabel`,
        holding.settings?.find((s) => s.value === selected)?.valueLabel,
      );
    }
    setIsModalOpen(false);
  };

  return (
    <Modal
      onClose={onCloseModal}
      onOpen={() => setIsModalOpen(true)}
      open={isModalOpen}
      key={holding.position.symbol}
      trigger={
        <GridTable.NavigableRow key={holding.position.symbol}>
          <GridTable.Cell>
            <Flex flexDirection="row">
              <Box mr={8} alignSelf="flex-end">
                {holding.position.security?.profile?.logoUrl && (
                  <Image
                    alt=""
                    height={32}
                    width={32}
                    src={holding.position.security?.profile?.logoUrl}
                  />
                )}
              </Box>
              <Flex flexDirection="column">
                <PS color="foregroundNeutralSecondary" fontWeight={400}>
                  {holding.position.symbol}
                </PS>
                <PL color="foregroundNeutralMain" fontWeight={400}>
                  {holding.position.descriptor}
                </PL>
              </Flex>
            </Flex>
          </GridTable.Cell>
          <GridTable.Cell textAlign="right">
            {formValue.valueLabel ?? 'CASH'}
          </GridTable.Cell>
        </GridTable.NavigableRow>
      }
    >
      <ModalContent width="normal" withPadding={false} padding={32}>
        <HXS fontWeight={300}>Choose a routing method.</HXS>
        <Flex flexDirection="row" mt={24}>
          <Box mr={8} alignSelf="flex-end">
            {holding.position.security?.profile?.logoUrl && (
              <Image
                alt=""
                height={32}
                width={32}
                src={holding.position.security?.profile?.logoUrl}
              />
            )}
          </Box>
          <Flex flexDirection="column">
            <PS color="foregroundNeutralSecondary" fontWeight={400}>
              {holding.position.symbol}
            </PS>
            <PL color="foregroundNeutralMain" fontWeight={400}>
              {holding.position.descriptor}
            </PL>
          </Flex>
        </Flex>
        <Divider />
        <Flex flexDirection="column" gap={16}>
          {selected && (
            <DividendSettings
              name={`securities.${holding.position.symbol}`}
              settings={holding.settings ?? []}
              value={formValue.selected}
            />
          )}
        </Flex>
        <Button
          disabled={
            formValue.selected ===
              AccountConfigDividendSettingType.TransferToAccount &&
            !formValue.transferAccountId
          }
          fullWidth
          label="Save"
          mt={40}
          onClick={() => {
            mutate({
              onCompleted: (data) => {
                onCompleted?.(data);
                setIsModalOpen(false);
              },
              onError,
              variables: {
                input: {
                  accountId: account.id,
                  symbol: holding.position.symbol as string,
                  type: formValue.selected,
                  ...(formValue.transferAccountId && {
                    transferAccountId: formValue.transferAccountId,
                  }),
                },
              },
            });
          }}
        />
      </ModalContent>
    </Modal>
  );
}
