import * as React from 'react';
import { Route } from 'react-router-dom';

import { SaveMarketingRoute } from '~/pages/save/marketing/SaveMarketingRoute';
import { SavePage } from '~/pages/save/SavePage';

import { SavingsRoute } from './savings';

export const SaveRoute = () => {
  return (
    <Route path="save" element={<SavePage />}>
      {SavingsRoute()}
      {SaveMarketingRoute()}
    </Route>
  );
};
