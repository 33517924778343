import { Flex, styled, PL } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

const StyledBackButtonContainer = styled(Flex)`
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.foregroundNeutralSecondary};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.foregroundNeutralMain};
  }
`;

export type BackButtonProps = {
  backlabel?: string;
  onClick: () => void;
};

export const BackButton = ({
  backlabel = 'Back',
  onClick,
}: BackButtonProps) => (
  <StyledBackButtonContainer onClick={onClick}>
    <Icon color="primary" name="arrowLeft20" />
    <PL>{backlabel}</PL>
  </StyledBackButtonContainer>
);
