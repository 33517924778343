import { OldPie, Pie, PortfolioSlice, Slice } from '~/pie-trees';

export const PORTFOLIO_EDITOR_ACTIONS = Object.freeze({
  ADD_SLICES_TO_PORTFOLIO_EDITOR: 'ADD_SLICES_TO_PORTFOLIO_EDITOR' as const,
  ADJUSTED_PORTFOLIO_EDITOR_SLICE_PERCENTAGE:
    'ADJUSTED_PORTFOLIO_EDITOR_SLICE_PERCENTAGE' as const,
  CLICKED_PORTFOLIO_EDITOR_BREADCRUMB:
    'CLICKED_PORTFOLIO_EDITOR_BREADCRUMB' as const,
  CREATED_PORTFOLIO_EDITOR_NEW_PIE_SLICE:
    'CREATED_PORTFOLIO_EDITOR_NEW_PIE_SLICE' as const,
  FETCH_PIE_DATA_FOR_PORTFOLIO_EDITOR:
    'FETCH_PIE_DATA_FOR_PORTFOLIO_EDITOR' as const,
  FETCHED_PIE_DATA_FOR_PORTFOLIO_EDITOR:
    'FETCHED_PIE_DATA_FOR_PORTFOLIO_EDITOR' as const,
  FETCHED_PIE_SLICEABLES_FOR_PORTFOLIO_EDITOR:
    'FETCHED_PIE_SLICEABLES_FOR_PORTFOLIO_EDITOR' as const,
  GO_BACK_FROM_SET_DESTINATION_TARGETS:
    'GO_BACK_FROM_SET_DESTINATION_TARGETS' as const,
  GO_BACK_FROM_SET_SOURCE_PIE: 'GO_BACK_FROM_SET_SOURCE_PIE' as const,
  REMOVE_PORTFOLIO_EDITOR_ACTIVE_SLICE_IDS:
    'REMOVE_PORTFOLIO_EDITOR_ACTIVE_SLICE_IDS' as const,
  REMOVE_PORTFOLIO_EDITOR_SLICES: 'REMOVE_PORTFOLIO_EDITOR_SLICES' as const,
  SAVE_PORTFOLIO_EDITOR: 'SAVE_PORTFOLIO_EDITOR' as const,
  UPDATE_PIE_NAME_FOR_PORTFOLIO_EDITOR:
    'UPDATE_PIE_NAME_FOR_PORTFOLIO_EDITOR' as const,
  UPDATE_PIE_DESCRIPTION_FOR_PORTFOLIO_EDITOR:
    'UPDATE_PIE_DESCRIPTION_FOR_PORTFOLIO_EDITOR' as const,
  UPDATE_PORTFOLIO_EDITOR_ACTIVE_SLICE_IDS:
    'UPDATE_PORTFOLIO_EDITOR_ACTIVE_SLICE_IDS' as const,
  MOVE_SLICES: 'MOVE_SLICES' as const,
  CLICKED_PORTFOLIO_EDITOR_PIE_SLICE:
    'CLICKED_PORTFOLIO_EDITOR_PIE_SLICE' as const,
  CLICKED_MOVE_SLICES_DESTINATION: 'CLICKED_MOVE_SLICES_DESTINATION' as const,
  OPEN_EDITOR_CONFIRMATION_DIALOG: 'OPEN_EDITOR_CONFIRMATION_DIALOG' as const,
  CLOSE_EDITOR_CONFIRMATION_DIALOG: 'CLOSE_EDITOR_CONFIRMATION_DIALOG' as const,
});

export type MoveSlicesInput = {
  destinationPiePortfolioSliceId: string;
  destinationPieSerialized: string;
  moveSliceIds: Array<string>;
  sourcePieBeforeUpdatesSerialized: string;
  sourcePiePortfolioSliceId: string;
  sourcePieSerialized: string;
};

type SliceableIds = Array<string>;
export type AddSlicesToPortfolioEditorAction = {
  payload: SliceableIds;
  type: typeof PORTFOLIO_EDITOR_ACTIONS.ADD_SLICES_TO_PORTFOLIO_EDITOR;
};

export type AdjustedPortfolioEditorSlicePercentageAction = {
  payload: {
    id: string;
    percentage: number;
  };
  type: typeof PORTFOLIO_EDITOR_ACTIONS.ADJUSTED_PORTFOLIO_EDITOR_SLICE_PERCENTAGE;
};

export type CreatedPortfolioEditorNewPieSliceAction = {
  payload: Slice;
  type: typeof PORTFOLIO_EDITOR_ACTIONS.CREATED_PORTFOLIO_EDITOR_NEW_PIE_SLICE;
};

export type ClickedPortfolioEditorPieSliceAction = {
  payload: Pie;
  type: typeof PORTFOLIO_EDITOR_ACTIONS.CLICKED_PORTFOLIO_EDITOR_PIE_SLICE;
};

export type ClickedPortfolioEditorBreadcrumbAction = {
  payload: Array<string>;
  type: typeof PORTFOLIO_EDITOR_ACTIONS.CLICKED_PORTFOLIO_EDITOR_BREADCRUMB;
};

export type ClickedMoveSlicesDestinationAction = {
  payload: {
    destinationPortfolioSliceId: string;
    destinationSliceableId: string;
    slicesToMove: Array<PortfolioSlice>;
  };
  type: typeof PORTFOLIO_EDITOR_ACTIONS.CLICKED_MOVE_SLICES_DESTINATION;
};

export type FetchPieDataForPortfolioEditorAction = {
  payload: {
    pieId: string;
    slicesToMove?: Array<Slice>;
    type: 'SOURCE_PIE' | 'DESTINATION_PIE';
  };
  type: typeof PORTFOLIO_EDITOR_ACTIONS.FETCH_PIE_DATA_FOR_PORTFOLIO_EDITOR;
};

export type FetchedPieDataForPortfolioEditorAction = {
  payload: {
    pie: OldPie;
    type: 'SOURCE_PIE' | 'DESTINATION_PIE';
  };
  type: typeof PORTFOLIO_EDITOR_ACTIONS.FETCHED_PIE_DATA_FOR_PORTFOLIO_EDITOR;
};

export type FetchedPieSliceablesForPortfolioEditorAction = {
  payload: Array<Slice>;
  type: typeof PORTFOLIO_EDITOR_ACTIONS.FETCHED_PIE_SLICEABLES_FOR_PORTFOLIO_EDITOR;
};

export type GoBackFromSetDestinationTargetsAction = {
  payload: {
    history: Array<Pie>;
    sourcePie: Pie;
  };
  type: typeof PORTFOLIO_EDITOR_ACTIONS.GO_BACK_FROM_SET_DESTINATION_TARGETS;
};

export type GoBackFromSetSourcePieAction = {
  payload: {
    activePieId: string;
    history: Array<Pie>;
    sourcePie: Pie;
  };
  type: typeof PORTFOLIO_EDITOR_ACTIONS.GO_BACK_FROM_SET_SOURCE_PIE;
};

export type RemovePortfolioEditorSelectedSliceIdsAction = {
  payload: {
    ids: Array<string>;
  };
  type: typeof PORTFOLIO_EDITOR_ACTIONS.REMOVE_PORTFOLIO_EDITOR_ACTIVE_SLICE_IDS;
};

export type RemovePortfolioEditorSlicesAction = {
  payload: Array<Slice>;
  type: typeof PORTFOLIO_EDITOR_ACTIONS.REMOVE_PORTFOLIO_EDITOR_SLICES;
};

export type SavePortfolioEditorAction = {
  payload: string | null | undefined;
  type: typeof PORTFOLIO_EDITOR_ACTIONS.SAVE_PORTFOLIO_EDITOR;
};

export type UpdatePieNameForPortfolioEditorAction = {
  payload: string;
  type: typeof PORTFOLIO_EDITOR_ACTIONS.UPDATE_PIE_NAME_FOR_PORTFOLIO_EDITOR;
};

export type UpdatePieDescriptionForPortfolioEditorAction = {
  payload: string;
  type: typeof PORTFOLIO_EDITOR_ACTIONS.UPDATE_PIE_DESCRIPTION_FOR_PORTFOLIO_EDITOR;
};

export type UpdatePortfolioEditorSelectedSliceIdsAction = {
  payload: {
    ids: Array<string>;
  };
  type: typeof PORTFOLIO_EDITOR_ACTIONS.UPDATE_PORTFOLIO_EDITOR_ACTIVE_SLICE_IDS;
};

export type MoveSlicesAction = {
  payload: MoveSlicesInput;
  type: typeof PORTFOLIO_EDITOR_ACTIONS.MOVE_SLICES;
};

export type OpenEditorConfirmationDialogAction = {
  payload: unknown;
  type: typeof PORTFOLIO_EDITOR_ACTIONS.OPEN_EDITOR_CONFIRMATION_DIALOG;
};

export type CloseEditorConfirmationDialogAction = {
  payload: unknown;
  type: typeof PORTFOLIO_EDITOR_ACTIONS.CLOSE_EDITOR_CONFIRMATION_DIALOG;
};

export type PortfolioEditorAction =
  | AddSlicesToPortfolioEditorAction
  | AdjustedPortfolioEditorSlicePercentageAction
  | CreatedPortfolioEditorNewPieSliceAction
  | ClickedPortfolioEditorPieSliceAction
  | ClickedPortfolioEditorBreadcrumbAction
  | ClickedMoveSlicesDestinationAction
  | CloseEditorConfirmationDialogAction
  | FetchPieDataForPortfolioEditorAction
  | FetchedPieDataForPortfolioEditorAction
  | FetchedPieSliceablesForPortfolioEditorAction
  | GoBackFromSetDestinationTargetsAction
  | GoBackFromSetSourcePieAction
  | OpenEditorConfirmationDialogAction
  | RemovePortfolioEditorSelectedSliceIdsAction
  | RemovePortfolioEditorSlicesAction
  | SavePortfolioEditorAction
  | UpdatePieNameForPortfolioEditorAction
  | UpdatePieDescriptionForPortfolioEditorAction
  | UpdatePortfolioEditorSelectedSliceIdsAction
  | MoveSlicesAction;
