import { PL, OneColumnConstrained, Box, styled, theme } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useLoanApplicationRejectedQuery } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import { LinkableButton } from '~/lens-toolbox/LinkableButton';
import { Spinner } from '~/toolbox/spinner';

import { useIsPhoneDevice } from '../utils/isPhoneDevice';

import { StyledHeader } from './StyledHeader';

const StyledIllustration = styled(Illustration)`
  height: 168px;
  width: 169px;

  @media screen and (min-width: ${theme.breakpoints.SMALL}) {
    height: 240px;
    width: 242px;
  }
`;

export const ApplicationRejected = () => {
  const navigate = useNavigate();
  const isPhoneDevice = useIsPhoneDevice();

  const { data, loading } = useLoanApplicationRejectedQuery();

  if (loading) {
    return <Spinner fullScreen centered />;
  }
  const { title, subtitle, ctaLink } =
    data?.viewer.borrow?.personalLoans?.applicationFlow
      ?.loanDeclinedErrorScreen ?? {};

  if (!title || !subtitle || !ctaLink) {
    return <GenericSystemError />;
  }

  return (
    <OneColumnConstrained>
      <Box display="flex" justifyContent="center" mt={48}>
        <StyledIllustration name="loanNotApproved" />
      </Box>
      <StyledHeader content={title} mt={24} />
      <PL content={subtitle} pt={8} pb={8} mb={32} />
      <LinkableButton
        onClick={() => {
          navigate({ to: '/d/home' });
        }}
        kind="primary"
        linkable={ctaLink}
        size="large"
        fullWidth={Boolean(isPhoneDevice)}
      />
    </OneColumnConstrained>
  );
};
