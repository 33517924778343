import { Box, Button, HXS } from '@m1/liquid-react';
import * as React from 'react';

import { useNavigate } from '~/hooks/useNavigate';

import { M1Logo } from '~/toolbox/M1Logo';

export const VerifyEmailSuccess = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box pb={32}>
        <M1Logo />
      </Box>
      <HXS content="Success! Your email has been verified." pb={64} />
      <Button
        label="Continue"
        onClick={() => {
          navigate({ to: '/d/home' });
        }}
        kind="primary"
        size="large"
      />
    </>
  );
};
