import { PS } from '@m1/liquid-react';
import * as React from 'react';

import { ExternalLink } from '~/components/ExternalLink';
import { useIraContributionYearsQuery } from '~/graphql/hooks';
import { IraContributionYear, IraContributionYearEnum } from '~/graphql/types';
import { formatNumber } from '~/utils';

export type IRSContributionLimitsDescriptionProps = {
  contributionYears?: Array<IraContributionYear>;
  contributionYearSelected?: IraContributionYearEnum | null;
  fontSize?: number | `${number}${string}`;
};

export const IRSContributionLimitsDescription = ({
  contributionYears,
  contributionYearSelected,
  fontSize,
}: IRSContributionLimitsDescriptionProps) => {
  const { data } = useIraContributionYearsQuery({
    skip: Boolean(contributionYears),
  });

  const iraContributionYears =
    contributionYears ?? data?.viewer.irsRegulations.iraContributionYears;

  const iraContributionYear = React.useMemo(() => {
    if (iraContributionYears) {
      // Note: When contributionYearSelected is undefined, we assume that it is the current year:
      const isCurrentYear =
        contributionYearSelected !== IraContributionYearEnum.PriorYear;
      const year = iraContributionYears.find(
        (d) => d.isCurrent === isCurrentYear,
      );
      return year
        ? {
            year: year?.year,
            limitUnder50: formatNumber(year.limitUnder50, '$0,0'),
            limitOver50: formatNumber(year.limitOver50, '$0,0'),
          }
        : null;
    }
    return null;
  }, [iraContributionYears, contributionYearSelected]);

  if (!iraContributionYear) {
    return null;
  }

  return (
    <PS
      content={
        <span>
          The IRA contribution limit for {iraContributionYear.year} is{' '}
          {iraContributionYear.limitUnder50}, or{' '}
          {iraContributionYear.limitOver50} if you’re age 50 or older. Your Roth
          IRA contributions may also be limited based on your filing status and
          income.{' '}
          <ExternalLink
            css={{
              fontSize: fontSize ?? 12,
              fontWeight: 'inherit',
              lineHeight: 'inherit',
              textDecoration: 'underline',
            }}
            destination="SUPPORT_ROTH_IRA"
          >
            Read more
          </ExternalLink>
          .
        </span>
      }
    />
  );
};
