import {
  Box,
  Button,
  Flex,
  HXS,
  PL,
  PM,
  PS,
  Card,
  Tooltip,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import {
  Illustration,
  type IllustrationsType,
} from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { useCreateTransferShowReceiptQuery } from '~/graphql/hooks';
import { TransferParticipantTypeEnum } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { AppInformationalMessageCard } from '~/lens-toolbox/AppInformationalMessageCard';
import { useDispatch, useSelector } from '~/redux/hooks';
import { CustomCreateTransferInstanceOutcome } from '~/redux/reducers/newFlows/reducers/createTransferReducer';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';
import { formatNumber } from '~/utils';

type ShowReceiptStepProps = {
  onFinishStep: (...args: Array<any>) => any;
};

type PendingTransferCardInstanceProps = {
  transferInstance: any;
};
type AdditionalInfoBannerProps = {
  transferInstance: any;
};

type AutoInvestCardForTransferInstanceProps = {
  outcome: CustomCreateTransferInstanceOutcome;
  transferInstance: any;
};

const PendingTransferInstanceCard = ({
  transferInstance,
}: PendingTransferCardInstanceProps) => {
  const isPayment =
    transferInstance.isCreditCardPayment ||
    transferInstance.isPersonalLoanPayment;

  return (
    <Card mt={32} p={12}>
      <Flex justifyContent="space-between" alignItems="center">
        <PM
          fontWeight={600}
          content={isPayment ? 'Pending Payment' : 'Pending Transfer'}
        />
        {typeof transferInstance.amount === 'number' && (
          <PM content={formatNumber(transferInstance.amount, '$0,0[.]00')} />
        )}
      </Flex>
      {transferInstance.timingDescription && (
        <PS
          color="foregroundNeutralMain"
          content={transferInstance.timingDescription}
          mt={8}
        />
      )}
    </Card>
  );
};

const AdditionalInfoBanner = ({
  transferInstance,
}: AdditionalInfoBannerProps) => {
  return (
    <Box mt={24}>
      <AppInformationalMessageCard
        appInformationalMessageCard={transferInstance.additionalInfoCard}
      />
    </Box>
  );
};

const AutoInvestCardForTransferInstance = ({
  transferInstance,
  outcome,
}: AutoInvestCardForTransferInstanceProps) => {
  const dispatch = useDispatch();
  const isAutoInvestOnForDestination =
    typeof outcome.autoInvestImplications
      ?.maxCashThresholdOnDestinationAccount === 'number';
  const destinationAccountId = transferInstance.to?.id;
  const hasNonZeroEstimatedTradesAmount =
    outcome.autoInvestImplications?.estimatedBuyTradesDestinationAmount &&
    typeof outcome.autoInvestImplications
      .estimatedBuyTradesDestinationAmount === 'number' &&
    outcome.autoInvestImplications.estimatedBuyTradesDestinationAmount > 0;

  return (
    <Card mt={16} p={12}>
      <Flex flexDirection="column">
        <Flex alignItems="center">
          <Icon name="autoInvest24" />
          <PM
            fontWeight={600}
            content={`Auto-invest ${
              isAutoInvestOnForDestination ? 'On' : 'Off'
            }`}
            ml={8}
          />
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" mt={8}>
          <Flex alignItems="center">
            {!isAutoInvestOnForDestination && (
              <PS
                color="foregroundNeutralMain"
                content="Your deposit will be held in your cash balance"
              />
            )}
            {isAutoInvestOnForDestination &&
              hasNonZeroEstimatedTradesAmount && (
                <PS color="foregroundNeutralMain" content="Upcoming trades" />
              )}
            {!isAutoInvestOnForDestination && (
              <Tooltip
                placement="bottom-start"
                icon="tooltip16"
                iconColor="foregroundPrimary"
                triggerBoxProps={{
                  ml: 4,
                  style: {
                    backgroundColor: 'inherit',
                    verticalAlign: 'middle',
                  },
                }}
                body={
                  <PS content="Turn Auto-Invest on or make a buy order to invest." />
                }
              />
            )}
            {isAutoInvestOnForDestination &&
              hasNonZeroEstimatedTradesAmount && (
                <Tooltip
                  placement="bottom-start"
                  icon="tooltip16"
                  iconColor="primary"
                  triggerBoxProps={{
                    ml: 4,
                    style: {
                      backgroundColor: 'inherit',
                      verticalAlign: 'middle',
                    },
                  }}
                  body={
                    <PS content="Actual trades may vary slightly as prices fluctuate. Estimated trades are approximated based on auto-invest, transfers, orders, rebalances and more." />
                  }
                />
              )}
          </Flex>
          {isAutoInvestOnForDestination &&
            hasNonZeroEstimatedTradesAmount &&
            typeof outcome.autoInvestImplications
              ?.estimatedBuyTradesDestinationAmount === 'number' && (
              <Link
                children={
                  outcome.autoInvestImplications
                    .estimatedBuyTradesDestinationAmount === 1
                    ? `${outcome.autoInvestImplications.estimatedBuyTradesDestinationAmount} buy`
                    : `${outcome.autoInvestImplications.estimatedBuyTradesDestinationAmount} buys`
                }
                onClick={() => {
                  dispatch({
                    type:
                      transferInstance.to?.__typename === 'Account' &&
                      transferInstance.to.registration === 'CRYPTO'
                        ? 'SET_ACTIVE_CRYPTO_ACCOUNT'
                        : 'SET_ACTIVE_INVEST_ACCOUNT',
                    payload: destinationAccountId,
                  });
                }}
                to="/d/invest/portfolio/trading"
              />
            )}
        </Flex>
        {isAutoInvestOnForDestination &&
          typeof outcome.autoInvestImplications
            ?.estimatedBuyTradesDestinationAmount !== 'number' && (
            <PS
              content="View estimated trades details on your portfolio in Invest"
              color="foregroundNeutralMain"
              pt={8}
            />
          )}
      </Flex>
    </Card>
  );
};

const getImageName = (
  transferInstanceType: TransferParticipantTypeEnum | null | undefined,
): IllustrationsType => {
  switch (transferInstanceType) {
    case 'INVEST':
      return 'transferToPortfolioCreated';
    case 'SPEND':
      return 'transferToBankCreated';

    // TODO update these next three cases once we know which images to use
    case 'BORROW':
    case 'LOAN':
    case 'EXTERNAL':
      return 'transferSuccess';
    case 'PHYSICAL_CHECK':
      return 'checkRequestSubmitted';

    // TODO update these next three cases once we know which images to use
    case 'REWARDS':
    case 'CRYPTO':
    case 'CRYPTO_EXTERNAL':
      return 'transferSuccess';
    default:
      return 'transferSuccess';
  }
};

export const ShowReceiptStep = ({ onFinishStep }: ShowReceiptStepProps) => {
  const { nodeId, outcome } = useSelector((state) => ({
    nodeId: state.newFlows.CREATE_TRANSFER.createdNodeId,
    outcome: state.newFlows.CREATE_TRANSFER.outcome,
  }));
  const { data, loading } = useCreateTransferShowReceiptQuery({
    variables: {
      id: nodeId as string,
    },
    skip: !nodeId,
  });

  if (loading) {
    return <Spinner />;
  }

  const node = data?.node && 'to' in data.node ? data.node : null;

  if (!node) {
    return null;
  }

  const readScheduleDescription = (): string | null | undefined => {
    if (node.__typename !== 'ScheduledTransferRule' || !node.schedule) {
      return null;
    }
    switch (node.schedule.__typename) {
      case 'MonthlySchedule':
        return 'monthly';
      case 'WeekOfMonthSchedule':
        return 'monthly on a week';
      case 'BiweeklySchedule':
        return 'biweekly';
      case 'WeeklySchedule':
        return 'weekly';
      default:
        return null;
    }
  };

  const readContent = (): [string, React.JSX.Element] => {
    const toName = node.to ? node.to.transferParticipantName : null;
    const transferOrPayment =
      node.__typename === 'TransferInstance' &&
      (node.isCreditCardPayment || node.isPersonalLoanPayment)
        ? 'Payment'
        : 'Transfer';
    switch (node.__typename) {
      case 'TransferInstance':
        return [
          `${transferOrPayment} created`,
          <React.Fragment key={toName}>
            Your {transferOrPayment.toLowerCase()}{' '}
            {toName ? (
              <>
                to <b>{toName}</b>
              </>
            ) : null}{' '}
            has been created!
          </React.Fragment>,
        ];
      case 'ScheduledTransferRule': {
        const description = readScheduleDescription();
        const isAutoPay =
          node.isCreditCardAutoPay || node.isPersonalLoanAutoPay;
        return isAutoPay
          ? [
              'AutoPay enabled',
              <React.Fragment key={toName}>
                AutoPay{' '}
                {toName && (
                  <>
                    to your <b>{toName}</b>
                  </>
                )}{' '}
                has been enabled!
              </React.Fragment>,
            ]
          : [
              'Schedule created',
              <React.Fragment key={toName}>
                Your {description && <b>{description}</b>} schedule{' '}
                {toName && (
                  <>
                    to <b>{toName}</b>
                  </>
                )}{' '}
                has been created!
              </React.Fragment>,
            ];
      }

      default:
        throw new Error('Invalid node type in ShowReceiptStep.readContent.');
    }
  };

  const ViewDetailsButton = () => {
    const navigate = useNavigate();

    switch (node.__typename) {
      case 'TransferInstance':
        return (
          <Button
            kind="secondary"
            label={
              node.isCreditCardPayment || node.isPersonalLoanPayment
                ? 'View payment details'
                : 'View transfer details'
            }
            onClick={() => {
              navigate({
                to: '/d/c/transfer-details/:transferInstanceId',
                params: { transferInstanceId: node.id },
              });
            }}
            size="large"
          />
        );
      case 'ScheduledTransferRule': {
        const isAutoPay =
          node.isCreditCardAutoPay || node.isPersonalLoanAutoPay;
        return (
          <Button
            kind="secondary"
            label={`View ${isAutoPay ? 'AutoPay' : 'schedule'} details`}
            onClick={() => {
              navigate({
                to: '/d/c/rule-details/:transferRuleId',
                params: { transferRuleId: node.id },
              });
            }}
            size="large"
          />
        );
      }
      default:
        return null;
    }
  };

  const [title, body] = readContent();

  const toParticipantInstanceType = node?.to?.transferParticipantType;

  const imageName = getImageName(toParticipantInstanceType);

  return (
    <Flex alignItems="center" flexDirection="column">
      <HXS content={title} mt={16} mb={32} />
      <Flex alignItems="center" justifyContent="center" flexDirection="column">
        <Illustration
          name={imageName}
          style={{ objectFit: 'contain', marginBottom: 16 }}
        />
        <Box width={380}>
          <PL content={body} mt={16} />
          {node?.__typename === 'TransferInstance' && (
            <PendingTransferInstanceCard transferInstance={node} />
          )}
          {node.__typename === 'TransferInstance' &&
            node.additionalInfoCard && (
              <AdditionalInfoBanner transferInstance={node} />
            )}

          {node.__typename === 'TransferInstance' &&
            // @ts-expect-error - TS2339 - Property 'autoInvestImplications' does not exist on type 'CustomCreateTransferInstanceOutcome | CustomSetScheduledTransferRuleOutcome'.
            outcome?.autoInvestImplications !== null &&
            outcome?.__typename === 'CreateTransferInstanceOutcome' && (
              <AutoInvestCardForTransferInstance
                transferInstance={node}
                outcome={outcome}
              />
            )}
        </Box>
      </Flex>
      <ButtonGroup mt={256}>
        <ViewDetailsButton />
        <Button
          kind="primary"
          label="Done"
          onClick={onFinishStep}
          size="large"
        />
      </ButtonGroup>
    </Flex>
  );
};
