import { Box, type BoxProps } from '@m1/liquid-react';
import * as React from 'react';

import { RadioChoice, RadioChoiceProps, RadioChoiceValue } from './choice';

type Props = {
  // @ts-expect-error - TS2694 - Namespace 'React' has no exported member 'ChildrenArray'.
  children?: React.ChildrenArray<
    (React.ReactElement<typeof RadioChoice> | null | undefined) | boolean
  >;
  choices?: Array<RadioChoiceProps>;
  onChange: (...args: Array<any>) => any;
  size?: 'small' | 'standard';
  style?: Record<string, any>;
  value: RadioChoiceValue | null | undefined;
} & Omit<BoxProps, 'onChange'>;

const getBoxProps = (props: any) => {
  const {
    onChange, // Do not include in  `rest`, it causes issues with value selection - all the others are fine
    onBlur,
    ...rest
  } = props;
  return rest;
};

export class RadioGroup extends React.Component<Props> {
  static defaultProps = {
    onChange: () => {},
  };

  render() {
    const props = getBoxProps(this.props);
    return <Box {...props}>{this.renderChildrenOrChoices()}</Box>;
  }

  renderChildrenOrChoices() {
    if (this.props.choices) {
      return this.props.choices.map((choice) => {
        // @ts-expect-error - TS2769 - No overload matches this call.
        return React.createElement(RadioChoice, {
          ...choice,
          size: this.props.size,
          checked: this.props.value === choice.value,
          onChange: this.props.onChange,
        });
      });
    } else if (this.props.children) {
      const children = Array.isArray(this.props.children)
        ? this.props.children.filter(Boolean)
        : this.props.children;
      return React.Children.map(children, (choice) => {
        return React.cloneElement(choice, {
          size: this.props.size,
          checked: this.props.value === choice.props.value,
          onChange: this.props.onChange,
        });
      });
    }

    return null;
  }
}
