import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useSavingsJointInvitedQuery } from '~/graphql/hooks';
import { AppInfoPage } from '~/lens-toolbox/AppInfoPage';
import { Spinner } from '~/toolbox/spinner';

export const SavingsJointInvited = ({
  onFinishStep,
}: {
  onFinishStep: (arg: {
    accepted: boolean;
    accountType?: string | null;
  }) => void;
}) => {
  const { data, loading, error } = useSavingsJointInvitedQuery();

  if (loading) {
    return <Spinner fullScreen />;
  }

  const { jointInvitedPage } = data?.viewer.save?.savings?.onboarding ?? {};
  const accountType =
    data?.viewer.save?.savings?.onboarding?.jointInvitedPageOnboardingValue;

  if (!jointInvitedPage || error) {
    return <GenericSystemError />;
  }

  return (
    <Box>
      <AppInfoPage
        appInfoPage={jointInvitedPage}
        onPrimaryButtonClick={() =>
          onFinishStep({
            accepted: true,
            accountType,
          })
        }
        onSecondaryButtonClick={() =>
          onFinishStep({
            accepted: false,
          })
        }
      />
    </Box>
  );
};
