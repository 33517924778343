import * as React from 'react';

import { MyPiesPageContext } from './types';

export const MyPiesContext = React.createContext({} as MyPiesPageContext);

export const useMyPiesContext = () => {
  const myPiesResult = React.useContext(MyPiesContext);

  if (!myPiesResult.queryData) {
    throw new Error(
      'useMyPiesContext must be used within a MyPiesContextProvider',
    );
  }
  return myPiesResult;
};
