import { Button, HXXS, Text, InputProps, Card } from '@m1/liquid-react';
import memoize from 'lodash-es/memoize';
import * as React from 'react';
import { change, FormSection, InjectedFormProps } from 'redux-form';

import { IRSContributionLimitsDescription } from '~/components/IRSContributionLimitsDescription';
import { ConfirmModal } from '~/components/modals/confirm-modal';
import { TransferParticipantCellRenderer } from '~/components/transfers/TransferParticipantCellRenderer';
import { useGetScheduledTransferRuleQuery } from '~/graphql/hooks';

import {
  ContraTransferParticipantInput,
  IraDistributionReasonEnum,
  RecurrenceScheduleInput,
  ScheduledTransferIndicatorEnum,
  ScheduledTransferPresetOption,
  ScheduledTransferRule,
  SetScheduledTransferRuleInput,
  TransferParticipant,
} from '~/graphql/types';
import { compose, connectForm } from '~/hocs';
import { useDispatch } from '~/redux/hooks';
import { TRANSFER_SCHEDULE_FREQUENCIES } from '~/static-constants';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { DropdownGroup } from '~/toolbox/Dropdown';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';
import { formatNumber } from '~/utils';

import { PaymentPresets } from '../components/PaymentPresets';

import {
  createTransferFormHoc,
  TransfersAlias,
} from './CreateOneTimeOrScheduledTransfer';
import {
  RetirementContributionYearDropdownField,
  RetirementDisbursementReasonDropdownField,
  ScheduleFrequencyFields,
  TransferAmountField,
  TransferParticipantDropdownField,
} from './fields';
import { required } from './validators';

type EditTransferScheduleFormProps = InjectedFormProps<any> & {
  fromParticipant: ContraTransferParticipantInput | null | undefined;
  fromParticipantId: string | null | undefined;
  initialValues: Partial<SetScheduledTransferRuleInput>;
  iraDistributionReason: IraDistributionReasonEnum;
  isPaymentAmountSelected: boolean;
  onClickDelete: (id: string) => void;
  onSubmit: (payload: any) => void;
  scheduledTransferRule: ScheduledTransferRule;
  showTransactionError: boolean;
  toParticipant: ContraTransferParticipantInput | null | undefined;
  toParticipantId: string | null | undefined;
  viewer:
    | {
        transfers: TransfersAlias | null | undefined;
      }
    | null
    | undefined;
};

const paymentOtherValue = 'OTHER_AMOUNT' as const;
type TransferOtherAmountEnumValue = typeof paymentOtherValue;

export const getInitialFrequency = (
  schedule: RecurrenceScheduleInput,
): ValueOf<typeof TRANSFER_SCHEDULE_FREQUENCIES> | null | undefined => {
  let initialFreq = null;
  Object.keys(schedule).forEach((scheduleType) => {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'RecurrenceScheduleInput'.
    if (schedule[scheduleType] !== null) {
      initialFreq = scheduleType;
    }
  });
  return initialFreq;
};

const getScheduleAndLabel = (
  indicator: ScheduledTransferIndicatorEnum | TransferOtherAmountEnumValue,
  presets: Array<ScheduledTransferPresetOption> | null | undefined,
) => {
  let label;
  const autoPayPaymentValue =
    indicator === paymentOtherValue ? 'FIXED_AMOUNT' : indicator;

  if (presets) {
    const preset = presets.find(
      ({ indicator: presetIndicator }) => presetIndicator === indicator,
    );
    label = preset?.label;
  }

  return {
    schedule: {
      monthly: {
        autoPayPaymentValue,
      },
    },
    label,
  };
};

type AmountFieldProps = InputProps & {
  autoFocus?: boolean;
  isRequired?: boolean;
  label?: string | null | undefined;
  showTransactionError: boolean;
  transfers: TransfersAlias;
};

const AmountField = ({
  transfers,
  autoFocus = true,
  label = 'Enter amount',
  showTransactionError,
  isRequired = true,
  ...props
}: AmountFieldProps) => {
  const [dirty, setDirty] = React.useState(false);

  const limitsValidation = React.useCallback(
    (value: string) => {
      const valueAsNum = Number(value);
      if (value && transfers.requirements) {
        if (
          transfers.requirements.minTransferAmount &&
          valueAsNum < transfers.requirements.minTransferAmount
        ) {
          return `The minimum transfer amount is ${formatNumber(
            transfers.requirements.minTransferAmount,
          )}`;
        }

        if (
          transfers.requirements.maxTransferAmount &&
          valueAsNum > transfers.requirements.maxTransferAmount
        ) {
          return `The maximum transfer amount is ${formatNumber(
            transfers.requirements.maxTransferAmount,
          )}`;
        }
      }
    },
    [transfers.requirements],
  );

  const validations = [limitsValidation];
  if (isRequired === true) {
    // @ts-expect-error - TS2345 - Argument of type '(value: any) => string | null | undefined' is not assignable to parameter of type '(value: any) => string | undefined'.
    validations.push(required);
  }

  return (
    <TransferAmountField
      {...props}
      autoFocus={autoFocus}
      name="amount"
      label={label}
      nonSubmissionError={showTransactionError}
      validate={validations}
      onChange={() => !dirty && setDirty(true)}
    />
  );
};

const PaymentAmountInput = ({
  showTransactionError,
  transfers,
}: {
  showTransactionError: boolean;
  transfers: TransfersAlias;
}) => {
  const dispatch = useDispatch();
  const reqs = transfers.requirements;
  const presets = reqs?.scheduledTransferAmountPresets;

  if (!presets) {
    return null;
  }

  // @ts-expect-error - TS2531 - Object is possibly 'null'. | TS2367 - This condition will always return 'false' since the types 'Maybe<string> | undefined' and 'number' have no overlap.
  const isOtherDisabled = presets.every((preset) => preset.value === 0);

  return (
    <Card label="Select amount" mt={16}>
      <PaymentPresets
        amountField={
          <AmountField
            transfers={transfers}
            autoFocus={false}
            disabled={false}
            showTransactionError={showTransactionError}
            isRequired={false}
            onFocus={() => {
              dispatch(
                change(
                  'edit-transfer-schedule',
                  'paymentAmount',
                  paymentOtherValue,
                ),
              );
            }}
          />
        }
        // @ts-expect-error - TS2322 - Type 'Maybe<ScheduledTransferPresetOption>[]' is not assignable to type 'TransferAmountPresetOption[] | ScheduledTransferPresetOption[]'.
        presets={presets}
        paymentOtherValue={paymentOtherValue}
        isOtherDisabled={isOtherDisabled}
        isSchedule
        onSelect={(value) => {
          if (value !== paymentOtherValue) {
            dispatch(change('edit-transfer-schedule', 'amount', ''));
          }
        }}
      />
    </Card>
  );
};

const AmountSection = ({
  isAutoPay,
  showTransactionError,
  transfers,
}: {
  isAutoPay: boolean;
  showTransactionError: boolean;
  transfers: TransfersAlias;
}) => {
  return (
    <>
      <HXXS content="Amount" mt={24} />
      {isAutoPay ? (
        <PaymentAmountInput
          transfers={transfers}
          showTransactionError={showTransactionError}
        />
      ) : (
        <AmountField
          transfers={transfers}
          showTransactionError={showTransactionError}
        />
      )}
    </>
  );
};

const AccountsSection = ({
  destinationOptions,
  sourceOptions,
}: {
  destinationOptions: Array<DropdownGroup> | null | undefined;
  sourceOptions: Array<DropdownGroup> | null | undefined;
}) => (
  <>
    <HXXS content="Accounts" />
    <TransferParticipantDropdownField
      label="From"
      name="fromParticipantId"
      options={destinationOptions}
      placeholder="Select account"
      validate={[required]}
    />
    <TransferParticipantDropdownField
      label="To"
      name="toParticipantId"
      options={sourceOptions}
      placeholder="Select account"
      style={{
        marginTop: 16,
      }}
      validate={[required]}
    />
  </>
);

const AutoPayAccountsSection = ({
  fromParticipantId,
  toParticipantId,
}: {
  fromParticipantId: string | null | undefined;
  toParticipantId: string | null | undefined;
}) => (
  <>
    <HXXS content="Accounts" />
    <Card label="From">
      <TransferParticipantCellRenderer
        includeDescription
        id={fromParticipantId}
      />
    </Card>
    <Card label="To">
      <TransferParticipantCellRenderer
        includeDescription
        id={toParticipantId}
      />
    </Card>
  </>
);

const ScheduleSection = ({
  schedule,
  transfers,
}: {
  schedule: RecurrenceScheduleInput;
  transfers: TransfersAlias | null | undefined;
}) => (
  <FormSection name="schedule">
    <HXXS content="Schedule" mt={16} />
    <ScheduleFrequencyFields
      initialFrequency={getInitialFrequency(schedule)}
      isEvenWeek={transfers?.isEvenWeek}
    />
  </FormSection>
);

type HandleSubmitType = {
  amount: string;
  fromParticipantId: string;
  iraContributionYear: string | null | undefined;
  iraDistributionReason: string | null | undefined;
  paymentAmount: ScheduledTransferIndicatorEnum | TransferOtherAmountEnumValue;
  schedule: RecurrenceScheduleInput;
  toParticipantId: string;
};

class EditTransferScheduleFormComponent extends React.Component<EditTransferScheduleFormProps> {
  render() {
    const transfers = this.readTransfers();
    const isAutoPay =
      this.props.scheduledTransferRule.isCreditCardAutoPay ||
      this.props.scheduledTransferRule.isPersonalLoanAutoPay;
    return (
      <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
        {!isAutoPay && (
          <AccountsSection
            destinationOptions={this.readSourceParticipants(transfers)}
            sourceOptions={this.readDestinationParticipants(transfers)}
          />
        )}
        {isAutoPay && (
          <AutoPayAccountsSection
            fromParticipantId={this.props.scheduledTransferRule.from?.id}
            toParticipantId={this.props.scheduledTransferRule.to?.id}
          />
        )}
        {transfers && (
          <AmountSection
            isAutoPay={isAutoPay}
            transfers={transfers}
            showTransactionError={this.props.showTransactionError}
          />
        )}
        {this.renderDistributionReason()}
        {this.renderContributionYear()}
        {!isAutoPay && (
          <ScheduleSection
            // @ts-expect-error - TS2322 - Type 'RecurrenceScheduleInput | undefined' is not assignable to type 'RecurrenceScheduleInput'.
            schedule={this.props.initialValues.schedule}
            transfers={transfers}
          />
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 64,
          }}
        >
          <ButtonGroup>
            <ConfirmModal
              title={`Are you sure you want to delete this ${
                isAutoPay ? 'AutoPay ' : ''
              }schedule?`}
              trigger={
                <Button kind="destructive" label="Delete" size="large" />
              }
              onConfirm={() =>
                this.props.onClickDelete(this.props.scheduledTransferRule.id)
              }
            />
            <Button
              disabled={
                this.props.showTransactionError ||
                (isAutoPay && !this.props.isPaymentAmountSelected)
              }
              label="Save"
              kind="primary"
              size="large"
              type="submit"
            />
          </ButtonGroup>
        </div>
      </form>
    );
  }

  renderDistributionReason(): React.ReactNode | null | undefined {
    const { viewer } = this.props;
    if (
      viewer &&
      viewer.transfers &&
      viewer.transfers.requirements &&
      viewer.transfers.requirements.isIraDistributionReasonRequired
    ) {
      return (
        <>
          <RetirementDisbursementReasonDropdownField
            name="iraDistributionReason"
            label="Distribution Reason"
            isRecurringTransfer
            style={{
              marginTop: 16,
            }}
          />
          {(this.props.iraDistributionReason === 'DEATH' ||
            this.props.iraDistributionReason === 'DISABILITY') && (
            <Card backgroundColor="backgroundNeutralTertiary" p={16} mt={16}>
              <Text
                content={
                  <span>
                    Supporting documents will need to be sent to{' '}
                    <Link to="mailto:help@m1.com" usePlainAnchor>
                      help@m1.com
                    </Link>{' '}
                    before the completion of the distribution.
                  </span>
                }
              />
            </Card>
          )}
        </>
      );
    }
    return null;
  }

  renderContributionYear(): React.ReactNode | null | undefined {
    const { viewer } = this.props;
    if (
      viewer &&
      viewer.transfers &&
      viewer.transfers.requirements &&
      viewer.transfers.requirements.isIraContributionYearRequired
    ) {
      return (
        <>
          <RetirementContributionYearDropdownField
            name="iraContributionYear"
            label="Contribution Year"
            style={{
              marginTop: 16,
            }}
            validate={[required]}
          />
          <Card backgroundColor="backgroundNeutralTertiary" p={16} mt={16}>
            <IRSContributionLimitsDescription />
          </Card>
        </>
      );
    }
    return null;
  }

  handleSubmit = ({ amount, paymentAmount, ...rest }: HandleSubmitType) => {
    const isAutoPay =
      this.props.scheduledTransferRule.isCreditCardAutoPay ||
      this.props.scheduledTransferRule.isPersonalLoanAutoPay;
    let scheduleAndLabel;

    if (isAutoPay) {
      scheduleAndLabel = getScheduleAndLabel(
        paymentAmount,
        // @ts-expect-error - TS2345 - Argument of type 'Maybe<Maybe<ScheduledTransferPresetOption>[]> | undefined' is not assignable to parameter of type 'ScheduledTransferPresetOption[] | null | undefined'.
        this.props.viewer?.transfers?.requirements
          ?.scheduledTransferAmountPresets,
      );
    }

    this.props.onSubmit({
      ...rest,
      ...scheduleAndLabel,
      isCreditCardPayment: isAutoPay,
      amount: Number(amount) || 0,
    });
  };

  readTransfers(): TransfersAlias | null | undefined {
    return this.props.viewer?.transfers;
  }

  readSourceParticipants = memoize(
    (transfers: TransfersAlias | null | undefined) => {
      if (
        transfers &&
        transfers.sourceParticipants &&
        Array.isArray(transfers.sourceParticipants.list)
      ) {
        return this.readParticipants(transfers.sourceParticipants.list);
      }

      return null;
    },
  );

  readDestinationParticipants = memoize(
    (transfers: TransfersAlias | null | undefined) => {
      if (
        transfers &&
        transfers.destinationParticipants &&
        Array.isArray(transfers.destinationParticipants.list)
      ) {
        return this.readParticipants(transfers.destinationParticipants.list);
      }

      return null;
    },
  );

  readParticipants(
    participants: Array<TransferParticipant>,
  ): Array<DropdownGroup> {
    const sources = new Map();
    for (const {
      id,
      transferParticipantType,
      transferParticipantName,
    } of participants) {
      const option = {
        label: transferParticipantName,
        value: id,
      };
      const participantTypeLabel =
        transferParticipantType === 'SAVE' ? 'EARN' : transferParticipantType;
      const options = sources.get(participantTypeLabel);
      if (options) {
        sources.set(participantTypeLabel, [...options, option]);
      } else {
        sources.set(participantTypeLabel, [option]);
      }
    }
    return Array.from(sources, ([label, options]) => ({
      label,
      options,
    }));
  }
}

const enhancer = compose<any, any>(
  (Component: any) => (props: any) => {
    const { data, loading } = useGetScheduledTransferRuleQuery({
      variables: {
        scheduledTransferRuleId: props.scheduledTransferRuleId,
      },
    });

    if (loading) {
      return <Spinner />;
    }

    const scheduledTransferRule =
      data?.scheduledTransferRule?.__typename === 'ScheduledTransferRule'
        ? data.scheduledTransferRule
        : null;
    return (
      <Component {...props} scheduledTransferRule={scheduledTransferRule} />
    );
  },
  connectForm((props) => ({
    form: 'edit-transfer-schedule',
    initialValues: collectInitialValues(props),
  })),
  createTransferFormHoc('edit-transfer-schedule', true),
);

// @ts-expect-error - TS7006 - Parameter 'props' implicitly has an 'any' type.
function collectInitialValues(props) {
  const indicator = props.scheduledTransferRule.schedulePresetIndicator;
  return {
    fromParticipantId: props.scheduledTransferRule.from.id,
    toParticipantId: props.scheduledTransferRule.to.id,
    amount: props.scheduledTransferRule.amount,
    paymentAmount: indicator === 'FIXED_AMOUNT' ? paymentOtherValue : indicator,
    iraContributionYear: props.scheduledTransferRule.iraContributionYear,
    iraDistributionReason: props.scheduledTransferRule.iraDistributionReason,
    schedule: readRecurrenceSchedule(props.scheduledTransferRule),
  };
}

export function readRecurrenceSchedule(
  scheduledTransferRule: ScheduledTransferRule,
): RecurrenceScheduleInput {
  if (!scheduledTransferRule.schedule) {
    throw new Error('Unable to read recurrence schedule.');
  }
  const empty = {
    monthly: null,
    weekOfMonth: null,
    biweekly: null,
    weekly: null,
  };
  // @ts-expect-error - TS2339 - Property '__typename' does not exist on type 'RecurrenceSchedule'.
  switch (scheduledTransferRule.schedule.__typename) {
    case 'MonthlySchedule':
      return {
        ...empty,
        monthly: {
          // @ts-expect-error - TS2339 - Property 'dayOfMonth' does not exist on type 'RecurrenceSchedule'.
          dayOfMonth: scheduledTransferRule.schedule.dayOfMonth,
          autoPayPaymentValue: null,
          creditCardPaymentValue: null,
        },
      };
    case 'WeekOfMonthSchedule':
      return {
        ...empty,
        weekOfMonth: {
          // @ts-expect-error - TS2339 - Property 'weekOfMonth' does not exist on type 'RecurrenceSchedule'.
          weekOfMonth: scheduledTransferRule.schedule.weekOfMonth,
          // @ts-expect-error - TS2339 - Property 'dayOfWeek' does not exist on type 'RecurrenceSchedule'.
          dayOfWeek: scheduledTransferRule.schedule.dayOfWeek,
        },
      };
    case 'BiweeklySchedule':
      return {
        ...empty,
        biweekly: {
          // @ts-expect-error - TS2339 - Property 'dayOfWeek' does not exist on type 'RecurrenceSchedule'.
          dayOfWeek: scheduledTransferRule.schedule.dayOfWeek,
          // @ts-expect-error - TS2339 - Property 'isEvenWeeks' does not exist on type 'RecurrenceSchedule'.
          isEvenWeeks: scheduledTransferRule.schedule.isEvenWeeks,
        },
      };
    case 'WeeklySchedule':
      return {
        ...empty,
        weekly: {
          // @ts-expect-error - TS2339 - Property 'dayOfWeek' does not exist on type 'RecurrenceSchedule'.
          dayOfWeek: scheduledTransferRule.schedule.dayOfWeek,
        },
      };
    default:
      throw new Error('Unhandled RecurrenceSchedule type.');
  }
}

export const EditTransferScheduleForm = enhancer(
  EditTransferScheduleFormComponent,
) as any;
