import { Flex, styled } from '@m1/liquid-react';
import * as React from 'react';
import { useSprings, animated, interpolate } from 'react-spring';
import { useGesture } from 'react-use-gesture';

import { Spinner } from '../spinner';

type DragAndDropProps = {
  children: Array<{
    key: string;
    render: (arg0: {
      active: boolean;
      disabled: boolean;
      isFirst: boolean;
      isLast: boolean;
      isMouseDown: boolean;
    }) => React.ReactNode;
  }>;
  containerWidth?: number;
  disableLast?: boolean;
  minHeight?: number;
  onDragEnd: (arg0: Array<string>) => void;
};

type PositionDimensions = {
  end: number;
  start: number;
};

// Utilities
const move = (
  arr: Array<string>,
  from: number,
  to: number,
  disableLast?: boolean,
): Array<string> => {
  if (disableLast && to === arr.length - 1) {
    return arr;
  }

  const arrCopy = [...arr];
  const grabbedElementIndex = arrCopy.splice(from, 1)[0];
  arrCopy.splice(to, 0, grabbedElementIndex);
  return arrCopy;
};

// Styled Components
const StyledContainer = styled(Flex)<{
  containerWidth?: number;
  height: number;
  isMouseDown: boolean;
}>`
  background-color: ${(props) =>
    props.isMouseDown && props.theme.colors.backgroundNeutralTertiary};
  position: relative;
  height: ${(props) => props.height}px;
  width: ${(props) =>
    props.containerWidth ? `${props.containerWidth}px` : '100%'};
`;

const StyledItem = styled(animated.div)<{
  $disabled: boolean;
  $isLoading: boolean;
  $isMouseDown: boolean;
}>`
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  cursor: ${(props) => {
    if (props.$disabled) {
      return 'not-allowed';
    }
    if (props.$isMouseDown) {
      return 'grabbing';
    }
    return 'grab';
  }};
  user-select: none;
  opacity: ${(props) => (props.$isLoading ? 0 : 1)};
`;

const StyledSpinnerContainer = styled(Flex)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${(props) => props.theme.colors.backgroundNeutralSecondary};
  z-index: 2000;
`;

// Drag and Drop Component
export const DragAndDrop = ({
  children,
  containerWidth,
  disableLast,
  minHeight,
  onDragEnd,
}: DragAndDropProps) => {
  // loading indicator should display when children are fetching data asynchronously
  const [isLoading, setIsLoading] = React.useState(true);
  // height of all children - used to determine container height
  const [childrenRectsHeight, setChildrenRectsHeight] = React.useState(
    minHeight || 150,
  );
  // activeIndex represents the index of the child component currently being dragged
  const [activeIndex, setActiveIndex] = React.useState<
    (string | null | undefined) | (number | null | undefined)
  >(null);
  const childRefDirectory = React.useRef({});
  const [isMouseDown, setIsMouseDown] = React.useState<boolean>(false);
  // An array that keeps track of each of the start and end pixels from the top of the DnD container for each child rendered. This list is reordered on drag and is
  // utilized to determine item position based on mouse position (i.e. moving a child up or down in the list).
  const [positionDimensions, setPositionDimensions] = React.useState<
    Array<PositionDimensions>
  >([]);
  // Each item needs a unique key to identify each child
  // @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
  const getKey = React.useMemo(() => (item) => item.key, []);
  const keyOrder = React.useRef<Array<string>>(
    children.map((item) => getKey(item)),
  );

  // If an onDragEnd prop is passed in, the function is called with the current key order after an item is dropped
  const handleOnDragEnd = React.useMemo(() => {
    if (typeof onDragEnd === 'function' && keyOrder.current) {
      return {
        onDragEnd: () => onDragEnd(keyOrder.current),
      };
    }
    return undefined;
  }, [onDragEnd]);

  // Map Springs - Animates items as they are dragged
  const mapSpring = React.useMemo(() => {
    // @ts-expect-error - TS7006 - Parameter 'down' implicitly has an 'any' type. | TS7006 - Parameter 'activeKey' implicitly has an 'any' type. | TS7006 - Parameter 'activePos' implicitly has an 'any' type.
    return (keyList = keyOrder.current, down, activeKey, activePos) =>
      // @ts-expect-error - TS7006 - Parameter 'index' implicitly has an 'any' type.
      (index) => {
        const key = getKey(children[index]);
        const { current: childRefs } = childRefDirectory;
        // Per design, at this point the only item that could be disabled is the last element in a list
        const isDisabled = disableLast && index === children.length - 1;

        // If the mouse is down and the current spring is selected and not disabled, we return
        // the below configuration that is fed into <animated.div />;
        // height: activePos keeps the element at the same height as the mouse position.
        if (down && key === activeKey && !isDisabled) {
          return {
            active: 'true',
            height: activePos,
            zIndex: 1,
            // @ts-expect-error - TS7006 - Parameter 'n' implicitly has an 'any' type.
            immediate: (n) =>
              n === 'active' || n === 'height' || n === 'zIndex',
          };
        }

        // If the element is not actively selected, we must calculate the element's "height" or distance
        // from the top of the container. This is done by determining any child whose index is beore
        // the current child's index height and incrementing all of these values into the height variable.
        // This will determine any non-actively selected child's position relative to the top of the container.
        let height = 0;
        for (let i = 0; i < keyList.indexOf(key); i++) {
          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
          if (childRefs && keyList && childRefs[keyList[i]]) {
            // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
            height += childRefs[keyList[i]].clientHeight;
          }
        }

        return {
          active: '',
          height,
          zIndex: 0,
          immediate: isLoading,
        };
      };
  }, [children, getKey, disableLast, childRefDirectory, isLoading]);

  // @ts-expect-error - TS2554 - Expected 4 arguments, but got 0. | TS2769 - No overload matches this call.
  const [springs, setSprings] = useSprings(children.length, mapSpring());

  // Use Gesture - Assists with the necessary UI interactions
  // Specifically, see the docs here: https://use-gesture.netlify.app/docs/hooks#handling-multiple-gestures-in-one-hook-with-usegesture
  const bind = useGesture({
    onDrag({ args: [activeKey, index], down, movement }) {
      // If the last child is disabled and the current index is the last index, we simply return
      // and do not allow the user to drag the last element anywhere.
      if (disableLast && index === children.length - 1) {
        return;
      }

      const { current: childRefs } = childRefDirectory;

      setIsMouseDown(down);
      if (down) {
        setActiveIndex(activeKey);
      }

      // Movement gives us the [x, y] offset based on cursor position. We want
      // the y value because we are working with a vertical list, hence the use
      // of movement[1].
      const offset = movement[1];
      if (offset === 0) {
        return;
      }
      // Starting position (or index in the keyOrder) of the currently dragged element
      const startingPosition = keyOrder.current.indexOf(activeKey);
      let height = 0;

      // Calculate the active element's initial "height" or distance from the top of the container
      for (let i = 0; i < keyOrder.current.indexOf(activeKey); i++) {
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
        if (childRefs && childRefs[keyOrder.current[i]]) {
          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
          height += childRefs[keyOrder.current[i]].clientHeight;
        }
      }

      // Store half of the currently active element's height
      const halfOwnHeight =
        childRefs &&
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
        childRefs[keyOrder.current[keyOrder.current.indexOf(activeKey)]]
          .clientHeight / 2;
      // Height (or distance from top of container) plus the offset amount (updated by onDrag as the mouse moves) gives us the activePosition of the selected element.
      const activePos = height + offset;
      // The next position (or index in the keyOrder - i.e. where the element is dropped) of the currently dragged element.
      let next;

      // If the element is dragged above the container, the next position is automatically assigned to 0
      // and the element becomes the first item in the keyOrder
      if (activePos + halfOwnHeight < 0) {
        next = 0; // Otherwise, if the element is dragged past the end of the container, the next position is automatically
        // assigned to the last position and the element becomes the last item in the keyOrder
      } else if (
        activePos + halfOwnHeight >
        positionDimensions[positionDimensions.length - 1].end
      ) {
        next = positionDimensions.length - 1; // Finally, if the element is being actively dragged through the list, we determine the next position number
        // by the active position number relative to the start and end postion of each of the other elements.
      } else {
        next = positionDimensions.findIndex(
          (positionObj) =>
            activePos + halfOwnHeight > positionObj.start &&
            activePos + halfOwnHeight < positionObj.end,
        );
      }

      // The new order of the elements is determined using the move function
      const newOrder = move(
        keyOrder.current,
        startingPosition,
        next,
        disableLast,
      );

      // We update the springs and the visual order of the elements in the browser
      // @ts-expect-error - TS2349 - This expression is not callable.
      setSprings(mapSpring(newOrder, down, activeKey, activePos));
      if (!down) {
        keyOrder.current = newOrder;
      }
    },
    // Handle onDragEnd prop
    ...handleOnDragEnd,
  });

  // Position Dimensions - Determines the start and end point from the top of the draggable container for child element.
  // Used to determine order of elements when dragging.
  React.useEffect(() => {
    const positionDimensions = [];
    const { current: childRefs } = childRefDirectory;

    if (keyOrder && keyOrder.current) {
      for (let i = 0; i < keyOrder.current.length; i++) {
        // We don't include the last element's dimensions if it is disabled as we do not allow users to drag
        // enabled elements to the disabled element's position.
        if (disableLast && i === keyOrder.current.length - 1) {
          break;
        }
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
        if (childRefs && childRefs[keyOrder.current[i]]) {
          if (positionDimensions.length) {
            // @ts-expect-error - TS7022 - 'priorEnd' implicitly has type 'any' because it does not have a type annotation and is referenced directly or indirectly in its own initializer.
            const priorEnd = positionDimensions[i - 1].end;
            positionDimensions.push({
              start: priorEnd,
              // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
              end: priorEnd + childRefs[keyOrder.current[i]].clientHeight,
            });
          } else {
            positionDimensions.push({
              start: 0,
              // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
              end: childRefs[keyOrder.current[i]].clientHeight,
            });
          }
        }
      }
    }
    setPositionDimensions(positionDimensions);
  }, [keyOrder, childRefDirectory, disableLast, childrenRectsHeight]);

  // Determines the container height
  React.useEffect(() => {
    const interval = window.setInterval(() => {
      const { current: childRefs } = childRefDirectory;

      if (!childRefs) {
        return;
      }

      let calculatedContainerHeight = 0;

      for (const key in childRefs) {
        if (childRefs) {
          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
          const currentRef = childRefs[key];
          if (currentRef) {
            calculatedContainerHeight += Number(currentRef.clientHeight);
          }
        }
      }

      setChildrenRectsHeight((prevState) => {
        if (prevState === calculatedContainerHeight) {
          // @ts-expect-error - TS2349 - This expression is not callable. | TS2554 - Expected 4 arguments, but got 0.
          setSprings(mapSpring());
          setIsLoading(false);
          window.clearInterval(interval);
        }
        return calculatedContainerHeight;
      });
    }, 500);

    return () => window.clearInterval(interval);
  }, []);

  return (
    <StyledContainer
      containerWidth={containerWidth}
      height={childrenRectsHeight}
      isMouseDown={isMouseDown}
      flexDirection="column"
    >
      {/* @ts-expect-error - TS2339 - Property 'map' does not exist on type 'ForwardedProps<CSSProperties>'. | TS7031 - Binding element 'height' implicitly has an 'any' type. | TS7031 - Binding element 'zIndex' implicitly has an 'any' type. | TS7006 - Parameter 'index' implicitly has an 'any' type. */}
      {springs.map(({ height, zIndex }, index) => {
        const item = children[index];
        const key = getKey(item);

        return (
          <StyledItem
            {...bind(key, index)}
            active={activeIndex === key ? key : undefined}
            // @ts-expect-error - TS2769 - No overload matches this call.
            $disabled={disableLast && index === children.length - 1}
            $isLoading={isLoading}
            $isMouseDown={isMouseDown}
            key={key} // This is a stop-gap solution for a Firefox bug with react-use-gesture. It is a known issue that should be resolved in version 8.
            // See, https://github.com/pmndrs/react-use-gesture/issues/188
            onDragStart={(e) => e.preventDefault()}
            ref={(el) => {
              // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{}'.
              childRefDirectory.current[key] = el;
            }}
            style={{
              transform: interpolate([height], (y) => {
                return `translate3d(0, ${y}px, 0)`;
              }),
              zIndex,
            }}
          >
            {item.render({
              active: activeIndex === key,
              disabled: disableLast ? index === children.length - 1 : false,
              isFirst: keyOrder.current.indexOf(key) === 0,
              isLast:
                keyOrder.current.indexOf(key) === keyOrder.current.length - 1,
              isMouseDown,
            })}
          </StyledItem>
        );
      })}
      {isLoading && (
        <StyledSpinnerContainer
          alignItems="center"
          justifyContent="center"
          m={16}
        >
          <Spinner />
        </StyledSpinnerContainer>
      )}
    </StyledContainer>
  );
};
