import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useAddContraEntryQuery } from '~/graphql/hooks';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { FulfillmentConditionRefetchContainerAddContra } from './FulfillmentConditionRefetchContainerAddContra';
import type { LocalContraParticipant } from './types';

type AddContraEntryProps = {
  newContraParticipantEntryOptionId: string;
  onFinishAddContraEntry: (arg0: LocalContraParticipant) => void;
  smartTransferRuleId: string;
};

export const AddContraEntry = ({
  newContraParticipantEntryOptionId,
  onFinishAddContraEntry,
  smartTransferRuleId,
}: AddContraEntryProps) => {
  const contraParticipantId: string | null | undefined = useSelector(
    (state) =>
      state.newFlows.CREATE_SMART_TRANSFER.activeContraParticipantEntry
        ?.contraParticipantId,
  );

  const { data, loading, refetch } = useAddContraEntryQuery({
    variables: {
      newSmartTransferContraParticipantEntryOptionId:
        newContraParticipantEntryOptionId,
      contraParticipantId: contraParticipantId ?? '',
    },
  });

  if (loading) {
    return (
      <Box mt={32}>
        <Spinner />
      </Box>
    );
  }

  if (
    data?.node?.__typename !== 'NewSmartTransferContraParticipantEntryOption'
  ) {
    return <GenericSystemError />;
  }
  return (
    <FulfillmentConditionRefetchContainerAddContra
      onFinishAddContraEntry={onFinishAddContraEntry}
      newSmartTransferContraParticipantEntryOption={data.node}
      smartTransferRuleId={smartTransferRuleId}
      refetch={refetch}
    />
  );
};
