import { Box, HXS } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { PageErrorBoundary } from '~/components/page-error-boundary';
import { useParams } from '~/hooks/useParams';
import { useSearchParams } from '~/hooks/useSearchParams';

import { SmartTransferContraParticipantEntryTransferInstancesTable } from './SmartTransferContraParticipantEntryTransferInstancesTable';

export const SmartTransferContraParticipantEntryInstancesPage = () => {
  const { smartTransferContraParticipantEntryId } = useParams();
  const [searchParams] = useSearchParams();
  const smartTransferRuleId = searchParams.get('smartTransferRuleId');

  return (
    <Box maxWidth={600} my={0} mx="auto" py={100}>
      <PageErrorBoundary retryButtonLabel="Exit">
        {smartTransferRuleId && (
          <BackArrow
            content="Smart Transfer Details"
            mb={16}
            params={{ smartTransferRuleId }}
            to="/d/c/smart-transfer-details/:smartTransferRuleId"
          />
        )}

        <HXS content="Transfer activity" mb={16} />
        <SmartTransferContraParticipantEntryTransferInstancesTable
          contraParticipantEntryId={
            smartTransferContraParticipantEntryId as string
          }
        />
      </PageErrorBoundary>
    </Box>
  );
};
