import { HM, PL, Box } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { CollectTrustedContactForm } from '~/forms';
import { TrustedContactInput } from '~/graphql/types';
import {
  collectedInvestTrustedContact,
  skippedInvestTrustedContact,
} from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { Link } from '~/toolbox/link';

export const SavingsCollectTrustedContact = ({
  onFinishStep,
}: {
  onFinishStep: () => void;
}) => {
  const dispatch = useDispatch();
  const { trustedContact } = useSelector(
    (state) => state.newFlows.INVEST_ONBOARDING.input,
  );

  const handleSubmit = (trustedContact: Maybe<TrustedContactInput>): void => {
    if (trustedContact) {
      dispatch(
        collectedInvestTrustedContact({
          trustedContact,
        }),
      );
      onFinishStep();
    }
  };

  const handleSkip = (): void => {
    dispatch(skippedInvestTrustedContact());
    onFinishStep();
  };

  return (
    <Box maxWidth={588} mx="auto">
      <Box my={32}>
        <BackArrow content="Back" onClick={() => window.history.back()} />
      </Box>
      <HM content="Would you like to add a trusted contact?" />
      <PL mt={12} mb={32}>
        You can have one trusted contact (18 or older) for your M1 accounts.
        We’ll only contact them in certain situations. Skip if you’ve already
        added a trusted contact and don’t wish to change it.{' '}
        <Link
          to="https://help.m1.com/en/articles/9331942-what-is-a-trusted-contact"
          target="_blank"
        >
          Learn more about trusted contacts
        </Link>
      </PL>
      <CollectTrustedContactForm
        onSubmit={handleSubmit}
        onSkip={handleSkip}
        initialValues={trustedContact}
      />
    </Box>
  );
};
