import { Box, Button, Flex, HM, PL, PM } from '@m1/liquid-react';
import * as React from 'react';

import { AppContext } from '~/AppContext';
import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { useESignAgreementQuery } from '~/graphql/hooks';
import { AppState } from '~/redux';
import { useSelector } from '~/redux/hooks';
import { SavingsAccountType } from '~/redux/reducers/newFlows/reducers/savingsOnboardingReducer';
import { Checkbox } from '~/toolbox/checkbox';
import { Link } from '~/toolbox/link/Link';
import { Pill } from '~/toolbox/Pill';
import { Spinner } from '~/toolbox/spinner';

type SavingsESignProps = {
  onFinishStep: () => void;
};

export const SavingsESign = ({ onFinishStep }: SavingsESignProps) => {
  const { analytics } = React.useContext(AppContext);
  const [isAccepted, setIsAccepted] = React.useState(false);
  const [isViewed, setIsViewed] = React.useState(false);
  const [errorShow, setErrorShow] = React.useState(false);
  const handleChange = (): void => setIsAccepted(!isAccepted);
  const eSignClick = (): void => {
    setIsViewed(true);
    setErrorShow(false);
  };
  // Selectors
  const onboardingValue = useSelector<SavingsAccountType | null>(
    (state: AppState) => state.newFlows.SAVINGS_ONBOARDING.accountType,
  );
  const { data, loading } = useESignAgreementQuery({
    variables: {
      onboardingValue,
    },
    skip: !onboardingValue,
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (
    !onboardingValue ||
    !data?.viewer.save?.savings?.savingsAgreements?.eSignAgreement
  ) {
    return <GenericSystemError />;
  }
  const savingsAgreements = data.viewer.save.savings.savingsAgreements;

  return (
    <Flex
      alignItems="baseline"
      flexDirection="column"
      justifyContent="center"
      maxWidth={588}
      mx="auto"
    >
      <Box my={32}>
        <BackArrow
          content="Back"
          onClick={() => {
            window.history.back();
          }}
        />
      </Box>
      <HM content="To keep going, you need to view and agree to receive electronic disclosures for your new account." />
      {savingsAgreements.eSignAgreement.url ? (
        <Box mt={36}>
          <Link
            underline
            cursor="pointer"
            target="_blank"
            to={savingsAgreements.eSignAgreement.url}
            onClick={eSignClick}
          >
            <PL>{savingsAgreements.eSignAgreement.title}</PL>
          </Link>

          {isViewed ? (
            <Pill ml={16} kind="success" label="Viewed" />
          ) : (
            <Pill ml={16} kind="caution" label="Not Viewed" />
          )}
        </Box>
      ) : null}
      <Box my={32} height={0}>
        {errorShow ? (
          <PM
            fontWeight={600}
            color="critical"
            content="Please review the required documents above."
          />
        ) : null}
      </Box>
      <Checkbox
        label="I accept the E-signature and Electronic Disclosures Agreement"
        onChange={handleChange}
        mt={64}
        ml={-8}
        value={isAccepted}
      />
      <Box flexDirection="column" justifyContent="left" mb={16}>
        <Button
          disabled={!isAccepted}
          mt={16}
          kind="primary"
          size="large"
          label="Continue"
          type="submit"
          onClick={() => {
            if (!isViewed && isAccepted) {
              setErrorShow(true);
            } else {
              analytics.recordEvent(
                'm1_hysa_setup_disclosures_confirm_clicked',
              );
              onFinishStep();
            }
          }}
        />
      </Box>
    </Flex>
  );
};
