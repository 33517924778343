import { NetworkStatus } from '@apollo/client';
import { Box, HXXS, Skeleton, Flex, SkeletonProvider } from '@m1/liquid-react';
import * as React from 'react';

import { PaginationButtonGroup } from '~/components/PaginationButtonGroup';
import { SetupChecklistCard } from '~/components/SetupChecklistCard';
import { useEarnCommonTransactionsQuery } from '~/graphql/hooks';
import { EarnCommonTransactionsQuery } from '~/graphql/types';
import { useApolloPrevNext } from '~/hooks/useApolloPrevNext';
import { isNotNil } from '~/utils';

import { EarnCommonTransactionsTable } from './EarnCommonTransactionsTable';

export const EarnPaginatedTransactions = ({
  accountId,
  handleDetailsModalOpen,
  selectedTransactionId,
}: {
  accountId: string;
  handleDetailsModalOpen: (transactionId: string) => void;
  selectedTransactionId: string | null;
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const { data, refetch, networkStatus } = useEarnCommonTransactionsQuery({
    variables: { accountId: accountId, first: 7 },
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setIsLoading(false),
  });
  const { handleNextPage, handlePreviousPage, hasPreviousPage } =
    useApolloPrevNext({ refetch });

  const node = data?.node as ExtractTypename<
    EarnCommonTransactionsQuery['node'],
    'SavingsAccount'
  >;

  const transactionsData = node?.earnProductLandingPage?.transactions;
  const emptyStateContent =
    node?.earnProductLandingPage?.transactionsEmptyState;

  const pageInfo = transactionsData?.pageInfo;

  const tableHeaders = transactionsData?.tableHeaders;
  const transactions =
    transactionsData?.edges
      ?.map((edge) => edge && edge.node)
      .filter(isNotNil) ?? [];

  React.useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setIsLoading(true);
    }
  }, [networkStatus]);

  return (
    <SkeletonProvider isLoading={isLoading} fadeOut>
      <Box mt={32}>
        {node?.setupChecklist && (
          <Skeleton skeletonWidth="100%">
            <Flex flexDirection="column" mb={64}>
              <Flex>
                <SetupChecklistCard
                  fromParticipantId={node?.initialTransferParticipant?.id}
                  toParticipantId={node?.id}
                  setupChecklist={node?.setupChecklist}
                  width="100%"
                />
              </Flex>
            </Flex>
          </Skeleton>
        )}
        <Flex mb={16} justifyContent="space-between">
          <HXXS
            content="Transactions"
            fontWeight={300}
            mr={4}
            color="foregroundNeutralSecondary"
          />
          <PaginationButtonGroup
            handleNextPage={() =>
              pageInfo?.endCursor && handleNextPage(pageInfo?.endCursor)
            }
            hasNextPage={pageInfo?.hasNextPage ?? false}
            hasPreviousPage={hasPreviousPage}
            handlePreviousPage={handlePreviousPage}
          />
        </Flex>
        <Skeleton skeletonWidth="100%" skeletonHeight={400}>
          <EarnCommonTransactionsTable
            tableHeaders={tableHeaders}
            transactions={transactions}
            emptyStateContent={emptyStateContent}
            handleDetailsModalOpen={handleDetailsModalOpen}
            selectedTransactionId={selectedTransactionId}
          />
        </Skeleton>
      </Box>
    </SkeletonProvider>
  );
};
