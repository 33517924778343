import { PM, Flex, styled, Box, Tooltip } from '@m1/liquid-react';
import * as React from 'react';

// Prop Types
type FilterOrTogglePopupProps = {
  placement: 'bottom-end' | 'bottom-start';
  children: React.ReactNode;
  closeOnClick?: boolean;
  icon?: React.ReactNode;
  label: string | React.ReactElement<any>;
};

const StyledText = styled(PM)`
  color: ${(props) => props.theme.colors.foregroundNeutralMain};
  font-weight: 600;
`;

// Styled Components
const StyledFilterOrTogglePopupContainer = styled(Flex)`
  background-color: ${(props) => props.theme.colors.backgroundNeutralTertiary};
  border-radius: 16px;
  cursor: pointer;
  padding: 6px 16px;
  transition: all 0.1s ease-in-out;

  /* TODO: fix dark mode hover */
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(18, 18, 61, 0.2);

    .dark {
      box-shadow: 0 4px 8px 0 rgba(163, 171, 223, 0.8);
    }
  }
`;

const StyledArrow = styled(Box)<{
  isOpen: boolean;
}>`
  border-bottom: ${(props) =>
    props.isOpen && `6px solid ${props.theme.colors.foregroundNeutralMain}`};
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: ${(props) =>
    !props.isOpen && `6px solid ${props.theme.colors.foregroundNeutralMain}`};
  height: 0;
  margin: 2px 0 0 6px;
  transition: all 0.1s ease-in-out;
  width: 0;
`;

export const FilterOrTogglePopup = (props: FilterOrTogglePopupProps) => {
  const { placement, children, icon, label } = props;
  return (
    <Tooltip
      placement={placement}
      trigger="click"
      body={<Box>{children}</Box>}
      maxWidth={600}
    >
      <StyledFilterOrTogglePopupContainer
        alignItems="center"
        data-testid="filter-pop-up-container"
      >
        {icon && icon}
        <StyledText content={label} />
        <StyledArrow isOpen={false} />
      </StyledFilterOrTogglePopupContainer>
    </Tooltip>
  );
};
