import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import { useFeedbackFormQuery } from '~/graphql/hooks';
import { DashboardNavigation } from '~/hooks/useDashboardNavigation';

import { TopNavLink } from './TopNavLink';

export const TopNavSettings = ({
  selectedNavigation,
}: {
  selectedNavigation: DashboardNavigation;
}) => {
  const { data } = useFeedbackFormQuery();
  const showForm = data?.viewer.feedbackForm?.showFeedbackForm;

  const items = [
    {
      id: 'profile',
      to: '/d/settings/profile',
      label: 'Profile',
    },
    {
      id: 'billing',
      to: '/d/settings/billing',
      label: 'Fees',
    },
    {
      id: 'invest',
      to: '/d/settings/invest',
      label: 'Invest',
    },
    {
      id: 'security',
      to: '/d/settings/security',
      label: 'Security',
    },
    {
      id: 'payments',
      to: '/d/settings/payments',
      label: 'Referral payments',
    },
    {
      id: 'documents',
      to: '/d/settings/documents',
      label: 'Documents',
    },
    {
      id: 'accounts',
      to: '/d/settings/accounts',
      label: 'Accounts',
    },
    {
      id: 'notifications',
      to: '/d/settings/notifications',
      label: 'Notifications',
    },
    {
      id: 'appearance',
      to: '/d/settings/appearance',
      label: 'Appearance',
    },
  ].concat(
    showForm === true
      ? [
          {
            id: 'feedback',
            to: '/d/settings/feedback',
            label: 'Feedback',
          },
        ]
      : [],
  );

  return (
    <Flex flexDirection="row">
      {items.map((item) => {
        return (
          <TopNavLink
            selected={selectedNavigation.activeL3Nav === item.id}
            key={item.to}
            label={item.label}
            linkTo={item.to}
          />
        );
      })}
    </Flex>
  );
};
