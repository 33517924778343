import * as React from 'react';

import { EXTERNAL_LINKS } from '~/static-constants/static-constants';
import { Link } from '~/toolbox/link/Link';
import type { LinkProps } from '~/toolbox/link/Link.types';

export type ExternalLinkProps = Omit<LinkProps, 'content' | 'to'> & {
  destination: keyof typeof EXTERNAL_LINKS;
  label?: LinkProps['children'];
  params?: Record<string, string> | null | undefined;
};

/**
 * @deprecated
 * Use Link from ~/toolbox/Link.tsx instead.
 */
export const ExternalLink = ({
  destination,
  params,
  label,
  children,
  ...rest
}: ExternalLinkProps) => {
  const baseUrl = EXTERNAL_LINKS[destination];
  const url = new URL(baseUrl);

  if (params) {
    Object.keys(params).forEach((key) => {
      url.searchParams.set(key, params[key]);
    });
  }
  return (
    <Link {...rest} to={url.href} target="_blank">
      {label ?? children}
    </Link>
  );
};
