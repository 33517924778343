import { styled } from '@m1/liquid-react';
import * as React from 'react';

import { HelpPopup } from '~/components/HelpPopup';
import { Grid } from '~/toolbox/grid';

export const RiskPopup = () => {
  return (
    <HelpPopup
      label="Risk"
      body={
        <Grid>
          <StyledHeaderRow>
            <Grid.Col xs={6} xsTextRight>
              Volatility*
            </Grid.Col>
            <Grid.Col xs={6} xsTextLeft>
              Risk Level
            </Grid.Col>
          </StyledHeaderRow>
          <Row metric="< 4.84" grade="Low" />
          <Row metric="4.84 - 9.68" grade="Medium - Low'" />

          <Row metric="9.68 - 14.52" grade="Medium" />
          <Row metric="14.52 - 19.36" grade="Medium - High" />
          <Row metric="> 19.36" grade="High" />

          <StyledFooterRow>
            <Grid.Col xs={12}>S&P 500 Volatility - 12.10</Grid.Col>
            <Grid.Col xs={12}>* 5-Year Monthly Standard Deviation</Grid.Col>
          </StyledFooterRow>
        </Grid>
      }
    />
  );
};

type RowProps = {
  metric: string;
  grade: string;
  colSpans?: [number, number];
};

const Row = ({ metric, grade, colSpans = [6, 6] }: RowProps) => {
  return (
    <StyledRow>
      <Grid.Col xs={colSpans[0]} xsTextRight>
        {metric}
      </Grid.Col>
      <Grid.Col xs={colSpans[1]} xsTextLeft>
        {grade}
      </Grid.Col>
    </StyledRow>
  );
};

const StyledHeaderRow = styled(Grid.Row as any)`
  color: ${({ theme }) => theme.colors.foregroundNeutralMain};
`;

const StyledFooterRow = styled(Grid.Row as any)`
  margin-top: 2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.colors.foregroundNeutralMain};
  text-align: center;
`;

const StyledRow = styled(Grid.Row as any)`
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.foregroundNeutralMain};
`;
