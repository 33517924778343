import * as React from 'react';

import {
  StartApplication,
  PreFilledSummary,
  FinancialInformation,
  FinalSummary,
  ApplicationRejected,
  ApplicationPending,
  AcceptOffer,
  AccountConfirmation,
  RemoveCreditFreeze,
  UploadDocuments,
  InitialErrorPage,
  ProcessingApplication,
  ProcessingOpenAccount,
} from './steps';

export const STEPS = {
  START_APPLICATION: 'start',
  PREFILLED_SUMMARY: 'summary',
  FINANCIAL_INFORMATION: 'finances',
  EDIT_FINANCIAL_INFORMATION: 'edit',
  SUBMIT_APPLICATION: 'submit',
  APPLICATION_APPROVED: 'approved',
  APPLICATION_REJECTED: 'submitted',
  APPLICATION_SUBMITTED: 'pending',
  REMOVE_CREDIT_FREEZE: 'frozen',
  OFFER_ACCEPTED: 'account-open',
  UPLOAD_DOCUMENTS: 'upload-documents',
  INITIAL_ERROR_PAGE: 'oops',
  PROCESSING_APPLICATION: 'hang-tight',
  PROCESSING_OPEN_ACCOUNT: 'get-excited',
};

export const CreditCardApplicationContainer = ({ step, ...rest }: any) => {
  const steps = {
    // Start application, disclosures
    [STEPS.START_APPLICATION]: StartApplication,
    // Step One, Application Summary
    [STEPS.PREFILLED_SUMMARY]: PreFilledSummary,
    // Step Two, User Input, Financial Information
    [STEPS.FINANCIAL_INFORMATION]: FinancialInformation,
    // Edit Financial Information
    [STEPS.EDIT_FINANCIAL_INFORMATION]: FinancialInformation,
    // Step Three, Review Application Summary
    [STEPS.SUBMIT_APPLICATION]: FinalSummary,
    // Step Four, Application Results
    [STEPS.APPLICATION_APPROVED]: AcceptOffer,
    [STEPS.APPLICATION_REJECTED]: ApplicationRejected,
    [STEPS.APPLICATION_SUBMITTED]: ApplicationPending,
    [STEPS.REMOVE_CREDIT_FREEZE]: RemoveCreditFreeze,
    [STEPS.UPLOAD_DOCUMENTS]: UploadDocuments,
    [STEPS.OFFER_ACCEPTED]: AccountConfirmation,
    // Processing application / Opening account
    [STEPS.PROCESSING_APPLICATION]: ProcessingApplication,
    [STEPS.PROCESSING_OPEN_ACCOUNT]: ProcessingOpenAccount,
    // Error Loading Initial Data
    [STEPS.INITIAL_ERROR_PAGE]: InitialErrorPage,
  };

  const Step = steps[step];
  return Step ? <Step {...rest} /> : null;
};
