import { SagaIterator } from 'redux-saga';
import { call, select, take } from 'redux-saga/effects';

import { NavigateFunction } from '~/hooks/useNavigate';
import { ACTION_TYPES as ACTIONS } from '~/redux/actions';

export function* openAdditionalAccountsMonitor(): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );

  yield take([
    ACTIONS.ACCOUNT_SETUP_FLOW_CANCELED,
    ACTIONS.ACCOUNT_SETUP_FLOW_FINISHED,
    ACTIONS.INVEST_ACCOUNT_SETUP_FLOW_CANCELED,
  ]);

  yield call(navigate, { to: '/d/invest/portfolio' });
}
