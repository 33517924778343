import * as React from 'react';

export const usePrevious = <T>(value: T): T | null | undefined => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    // @ts-expect-error - TS2322 - Type 'T' is not assignable to type 'null'.
    ref.current = value;
  });
  return ref.current;
};
