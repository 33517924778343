import {
  Button,
  ButtonProps,
  Flex,
  HXS,
  PM,
  Text,
  styled,
  TextProps,
  Modal,
  ModalContent,
} from '@m1/liquid-react';
import * as React from 'react';

// title is currently only used in PortfolioDetailsFooter
export type DisclosureLinkProps = OmitUnion<
  ButtonProps,
  'label' | 'font' | 'fontWeight'
> &
  Pick<TextProps, 'font' | 'fontWeight'> & {
    linkText: string | null | undefined;
    paragraphs: React.ReactNode[];
    underline?: boolean;
    title?: string;
  };

const StyledButtonLink = styled(Button)`
  span {
    font-weight: 400;
  }
`;

const FlexWithGap = styled(Flex)`
  flex-flow: column;
  gap: 16px;
  max-height: 90vh;
  padding: 0 8px;
  overflow-y: auto;
`;

export const DisclosureLink = ({
  font,
  fontWeight,
  linkText,
  paragraphs,
  title,
  underline = true,
  ...rest
}: DisclosureLinkProps) => {
  const [isOpen, setModalOpen] = React.useState<boolean>(false);

  if (!linkText) {
    return null;
  }

  return (
    <Text font={font} fontWeight={fontWeight}>
      <StyledButtonLink
        kind="link"
        onClick={() => setModalOpen(true)}
        underline={underline}
        label={linkText}
        {...rest}
      />
      <Modal open={isOpen} onClose={() => setModalOpen(false)}>
        <ModalContent>
          <FlexWithGap>
            {title && <HXS content={title} fontWeight={300} />}
            {paragraphs.map((p, i) => {
              return (
                p && (
                  <PM key={i} color="foregroundNeutralSecondary">
                    {p}
                  </PM>
                )
              );
            })}
          </FlexWithGap>
        </ModalContent>
      </Modal>
    </Text>
  );
};
