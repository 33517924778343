import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { SetOrderFlow } from '~/flows/components';
import { useParams } from '~/hooks/useParams';
import { useSearchParams } from '~/hooks/useSearchParams';

export const SetOrderPage = () => {
  const [searchParams] = useSearchParams();
  const direction = searchParams.get('direction') as 'buy' | 'sell' | null;
  const showBuyingPowerOverview = searchParams.get('buyingPower') === 'true';
  const { portfolioSliceId } = useParams();

  const isFromHoldingsPositionDetail =
    searchParams.get('isFromHoldingsPositionDetail') === 'true';

  return (
    <Flex justifyContent="center" p="100px 0">
      <PageErrorBoundary>
        <SetOrderFlow
          basePath={`d/c/set-order/${encodeURIComponent(portfolioSliceId as string)}`}
          direction={direction ?? 'buy'}
          showBuyingPowerOverview={showBuyingPowerOverview}
          isFromHoldingsPositionDetail={isFromHoldingsPositionDetail}
          portfolioSliceId={portfolioSliceId}
        />
      </PageErrorBoundary>
    </Flex>
  );
};
