import { Button, HS, Flex, PL, Card } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';
import { InjectedFormProps, formValueSelector } from 'redux-form';

import { GenericSystemError } from '~/components/GenericSystemError';
import { TransfersDisclaimer } from '~/components/TransfersDisclaimer';
import { TextField } from '~/forms/fields';
import { required } from '~/forms/validators';
import { useNiaReportQuery } from '~/graphql/hooks';
import { compose, connectForm, UNSAFE_connectRedux } from '~/hocs';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import type { AppState } from '~/redux';
import { setIraNiaAmount } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { CREATE_TRANSFER_FLOW_STEPS as STEPS } from '~/static-constants';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';
import { formatCurrencyWithCommas } from '~/utils/formatting';
import { normalizeCurrencyWithNegativeValue } from '~/utils/normalizers';

type NiaReportProps = InjectedFormProps<any> & {
  onFinishStep: (step?: ValueOf<typeof STEPS>) => void;
};

const NiaReport = ({ handleSubmit, onFinishStep }: NiaReportProps) => {
  const dispatch = useDispatch();
  const { data, loading } = useNiaReportQuery();
  const form = formValueSelector('nia-amount');
  const niaAmount = useSelector<number | null | undefined>((state) =>
    form(state, 'niaAmount'),
  );

  const submit = () => {
    dispatch(setIraNiaAmount(Number(niaAmount)));
    onFinishStep();
  };

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.viewer) {
    return <GenericSystemError />;
  }

  return (
    <>
      <HS>Report your earnings or losses</HS>
      <Card mt={32} p={32}>
        <form onSubmit={handleSubmit(submit)}>
          <TextField
            name="niaAmount"
            label="Net Income Attributable"
            maskType="money"
            validate={[required]}
            normalize={normalizeCurrencyWithNegativeValue}
            format={formatCurrencyWithCommas}
            value={niaAmount}
            maxLength={10}
          />
          {data.viewer.transfers?.niaDisclaimer && (
            <TransfersDisclaimer
              mt={36}
              mb={16}
              disclaimer={data.viewer.transfers.niaDisclaimer}
            />
          )}
          {data.viewer.transfers?.niaWithholdingDisclaimer && (
            <PL content={data.viewer.transfers.niaWithholdingDisclaimer.text} />
          )}
          <Flex flexDirection="row" alignItems="center" mb={64} mt={34}>
            <Link to="/d/c/create-transfer/calculate-nia" mr={3}>
              Calculate Net Income Attributable
            </Link>
            <Icon name="caretRight16" color="foregroundNeutralSecondary" />
          </Flex>
          <Flex flexDirection="column">
            {data.viewer.transfers?.niaLearnMore && (
              <LinkableLink
                mb={24}
                linkable={data.viewer.transfers.niaLearnMore}
              />
            )}
            <Link target="_blank" to="https://www.irs.gov/publications/p590a">
              IRS guidance
            </Link>
          </Flex>
          <Flex alignItems="start" flexDirection="row" mt={64}>
            <Button
              kind="secondary"
              label="Back"
              mt={21}
              mr={15}
              onClick={() => {
                dispatch(setIraNiaAmount(Number(niaAmount)));

                onFinishStep(STEPS.IRA_DISTRIBUTION_SETUP);
              }}
              size="large"
            />
            <Button
              disabled={!niaAmount}
              kind="primary"
              label="Continue"
              mt={21}
              type="submit"
              size="large"
            />
          </Flex>
        </form>
      </Card>
    </>
  );
};

const enhancer = compose<any, any>(
  UNSAFE_connectRedux(
    (state: AppState): Record<string, any> => ({
      initialValues: {
        niaAmount:
          state.newFlows.CREATE_TRANSFER.excessIraDistribution.niaAmount,
      },
    }),
  ),
  connectForm({
    form: 'nia-amount',
  }),
);

export const ConnectedNiaReport = enhancer(NiaReport) as any;
