import castArray from 'lodash-es/castArray';
import xor from 'lodash-es/xor';

import { ACTION_TYPES } from '~/redux/actions';
import { APP_MODES } from '~/static-constants';

export type SelectedSlicesState = Array<string>;

const initialState: SelectedSlicesState = [];

export const selectedSlicesReducer = (
  state: SelectedSlicesState = initialState,
  action: any,
): SelectedSlicesState => {
  switch (action.type) {
    case ACTION_TYPES.CLICKED_SLICEABLE_SELECTOR:
      return xor(state, castArray(action.payload));
    case ACTION_TYPES.START_ADD_SLICES:
    case ACTION_TYPES.FINISHED_ADD_SLICES:
      return initialState;
    case ACTION_TYPES.UPDATE_MODE:
      return action.payload === APP_MODES.ADD ? initialState : state;
    default:
      return state;
  }
};
