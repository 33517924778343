import { NOTIFICATION_LOCATIONS } from '~/static-constants';

export const VERIFY_EMAIL_BANNER_ACTIONS = Object.freeze({
  CLICKED_RESEND_VERIFICATION_EMAIL:
    'CLICKED_RESEND_VERIFICATION_EMAIL' as const,
  REFRESH_EMAIL_VERIFICATION_STATUS:
    'REFRESH_EMAIL_VERIFICATION_STATUS' as const,
  RESEND_EMAIL_VERIFICATION: 'RESEND_EMAIL_VERIFICATION' as const,
  RESET_VERIFY_EMAIL_BANNER: 'RESET_VERIFY_EMAIL_BANNER' as const,
});

export type ClickedResendVerificationEmailAction = {
  payload?: {
    errorNotificationLocation?: ValueOf<typeof NOTIFICATION_LOCATIONS>;
    pathToRedirectToOnSuccess?: string;
    successNotificationLocation?: ValueOf<typeof NOTIFICATION_LOCATIONS>;
  };
  type: typeof VERIFY_EMAIL_BANNER_ACTIONS.CLICKED_RESEND_VERIFICATION_EMAIL;
};

export type ResendEmailVerificationAction = {
  payload: unknown;
  type: typeof VERIFY_EMAIL_BANNER_ACTIONS.RESEND_EMAIL_VERIFICATION;
};

export type RefreshEmailVerificationStatusAction = {
  payload: unknown;
  type: typeof VERIFY_EMAIL_BANNER_ACTIONS.REFRESH_EMAIL_VERIFICATION_STATUS;
};

export type ResetVerifyEmailBannerAction = {
  payload: unknown;
  type: typeof VERIFY_EMAIL_BANNER_ACTIONS.RESET_VERIFY_EMAIL_BANNER;
};

export const resetVerifyEmailBanner = (
  payload: ResetVerifyEmailBannerAction['payload'],
): ResetVerifyEmailBannerAction => ({
  payload,
  type: VERIFY_EMAIL_BANNER_ACTIONS.RESET_VERIFY_EMAIL_BANNER,
});

export const clickedResendVerificationEmail = (
  payload: ClickedResendVerificationEmailAction['payload'],
): ClickedResendVerificationEmailAction => ({
  type: VERIFY_EMAIL_BANNER_ACTIONS.CLICKED_RESEND_VERIFICATION_EMAIL,
  payload: payload,
});

export const refreshEmailVerificationStatus = (
  payload: RefreshEmailVerificationStatusAction['payload'],
): RefreshEmailVerificationStatusAction => ({
  payload,
  type: VERIFY_EMAIL_BANNER_ACTIONS.REFRESH_EMAIL_VERIFICATION_STATUS,
});

export const resendEmailVerification = (
  payload: ResendEmailVerificationAction['payload'],
): ResendEmailVerificationAction => ({
  payload,
  type: VERIFY_EMAIL_BANNER_ACTIONS.RESEND_EMAIL_VERIFICATION,
});

export type VerifyEmailBannerAction =
  | ClickedResendVerificationEmailAction
  | ResendEmailVerificationAction
  | RefreshEmailVerificationStatusAction
  | ResetVerifyEmailBannerAction;
