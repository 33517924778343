import { Flex } from '@m1/liquid-react';
import React from 'react';

import { RecurringDepositFrequencyFields } from './RecurringDepositFrequencyFields';
import { RecurringDepositToggle } from './RecurringDepositToggle';

type RecurringDepositSectionProps = {
  onChange: () => void;
  isScheduleSwitchOn: boolean;
};

export const RecurringDepositSection = ({
  isScheduleSwitchOn,
  onChange,
}: RecurringDepositSectionProps) => (
  <Flex flexDirection="column" mt={16} mx="auto">
    <RecurringDepositToggle
      isScheduleSwitchOn={isScheduleSwitchOn}
      onChange={onChange}
    />
    <RecurringDepositFrequencyFields isScheduleSwitchOn={isScheduleSwitchOn} />
  </Flex>
);
