import { InvestActivityNodeFragment } from '~/graphql/types';
import { formatNumber } from '~/utils';

export const handleTradeSummary = (
  activity: Extract<
    InvestActivityNodeFragment,
    { __typename: 'InvestActivityTradeSummaryEntry' }
  >,
) => {
  const { countOfBuys, countOfSells } = activity;
  let buyText = '';
  let sellText = '';
  if (countOfBuys && countOfBuys > 0) {
    const buyTitle = countOfBuys === 1 ? 'buy' : 'buys';

    // Add newline at end of buy text if more sells
    if (countOfSells && countOfSells > 0) {
      buyText = `${countOfBuys} ${buyTitle}\n`;
    } else {
      buyText = `${countOfBuys} ${buyTitle}`;
    }
  }

  if (countOfSells && countOfSells > 0) {
    if (countOfSells === 1) {
      sellText = `${countOfSells} sell`;
    } else {
      sellText = `${countOfSells} sells`;
    }
  }

  return buyText + sellText;
};

export const getActivitySummary = (
  activity?: InvestActivityNodeFragment | null,
): string | null => {
  if (!activity) {
    return null;
  }
  switch (activity.__typename) {
    case 'InvestActivityCashEntry':
      if (activity.isRelatedToSecurity) {
        return activity.cashSecurity && activity.cashSecurity.security
          ? `${activity.cashSecurity.security.symbol} ${activity.cashSecurity.security.name}`
          : '--';
      } else if (activity.transferDetails?.transferSummary) {
        return activity.transferDetails.transferSummary;
      }

      return null;
    case 'InvestActivityPositionEntry':
      if (!activity.positionSecurity) {
        return null;
      }

      return activity.positionSecurity.security
        ? `${activity.positionSecurity.security.symbol} ${activity.positionSecurity.security.name}`
        : activity.positionSecurity.descriptor;
    case 'InvestActivityTradeEntry':
      if (!activity.tradeSecurity) {
        return null;
      }

      return activity.tradeSecurity.security
        ? `${activity.tradeSecurity.security.symbol} ${activity.tradeSecurity.security.name}`
        : activity.tradeSecurity.descriptor;

    case 'InvestActivityTradeSummaryEntry':
      return handleTradeSummary(activity);
    default:
      return null;
  }
};

export const getActivityValue = (
  activity?: InvestActivityNodeFragment | null,
): string | null => {
  if (!activity) {
    return null;
  }
  switch (activity.__typename) {
    case 'InvestActivityTradeSummaryEntry':
      return `${
        activity.amountOfBuys && activity.amountOfBuys > 0
          ? formatNumber(activity.amountOfBuys)
          : ''
      }
      ${
        activity.amountOfSells && activity.amountOfSells > 0
          ? formatNumber(activity.amountOfSells)
          : ''
      }`;
    case 'InvestActivityTradeEntry':
      return activity.amount ? formatNumber(activity.amount) : '--';
    case 'InvestActivityCashEntry':
      return activity.amount ? activity.amount.toString() : '--';
    case 'InvestActivityPositionEntry':
      return activity.quantity ? activity.quantity.toString() : '--';
    default:
      return null;
  }
};
