import classNames from 'classnames';
import * as React from 'react';

import { TableBody } from './body';
import { TableCell } from './cell';
import { TableFoot } from './foot';
import { TableHead } from './head';
import { TableHeaderCell } from './header-cell';
import { TableRow } from './row';
import style from './style.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export class Table extends React.Component<Props> {
  static Head = TableHead;
  static Foot = TableFoot;
  static HeaderCell = TableHeaderCell;
  static Body = TableBody;
  static Row = TableRow;
  static Cell = TableCell;

  render() {
    const { className, children } = this.props;
    const classes = classNames(style.root, className);

    return <table className={classes}>{children}</table>;
  }
}
