import { Button } from '@m1/liquid-react';
import * as React from 'react';
import { InjectedFormProps, change } from 'redux-form';

import { LoginNotifications } from '~/components/notifications';
import { compose, connectForm, UNSAFE_connectRedux } from '~/hocs';
import { useSearchParams } from '~/hooks/useSearchParams';
import type { AppState } from '~/redux';
import { submitLoginForm } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { STAGING_PASSWORD } from '~/static-constants';
import { Grid } from '~/toolbox/grid';
import { Link } from '~/toolbox/link';

import { PasswordField, TextField } from './fields';
import { required, email, trimWhitespace } from './validators';

type LoginFormProps = InjectedFormProps<any> & {
  attemptInProgress: boolean;
  color: string;
};

const LoginFormComponent = ({
  color,
  attemptInProgress,
  handleSubmit,
}: LoginFormProps) => {
  const dispatch = useDispatch();
  const isProduction =
    __NODE_ENV__ === 'production' &&
    (__ENV__ === 'production' || __ENV__ === 'beta');

  const [searchParams] = useSearchParams();
  const [username, setUsername] = React.useState('');
  const [lastKey, setLastKey] = React.useState('');
  const [typingPaused, setTypingPaused] = React.useState(false);

  const user = searchParams.get('user');
  const users = searchParams.get('users');
  const autoLogin = searchParams.get('autoLogin');
  const [hasSetQueryParamUser, setHasSetQueryParamUser] = React.useState(false);

  const {
    username: initialUsername,
    password: initialPassword,
    isModalFlow,
    hasUsernameAndPassword,
  } = useSelector((state: AppState) => {
    const form = state.form['login-form'];
    return {
      hasUsernameAndPassword: Boolean(
        form?.values?.username === user && form.values.password,
      ),
      isModalFlow: Number.isInteger(
        state.modals.LOGIN_OR_REGISTER?.payload?.activeTab,
      ),
      username: state.modals.LOGIN_OR_REGISTER?.payload?.username,
      password: state.modals.LOGIN_OR_REGISTER?.payload?.password,
    };
  });
  const queryUsers = users || user;
  const hasQueryParamUsers = Boolean(queryUsers);
  const canUseQueryParamUsers = hasQueryParamUsers && !hasSetQueryParamUser;

  React.useEffect(() => {
    if (isModalFlow) {
      dispatch(change('login-form', 'username', initialUsername));
      dispatch(change('login-form', 'password', initialPassword));
    }
  }, [initialUsername, initialPassword, isModalFlow, dispatch]);

  React.useEffect(() => {
    if (
      !isProduction &&
      user &&
      autoLogin === 'true' &&
      hasUsernameAndPassword
    ) {
      // @ts-ignore this works without all the arguments it is expecting
      handleSubmit();
    }
  }, [handleSubmit, hasUsernameAndPassword, isProduction, autoLogin, user]);

  React.useEffect(() => {
    if (!isProduction && hasQueryParamUsers) {
      const parsedUsers = (queryUsers ?? '').split(',');
      const user = parsedUsers[0];
      if (user) {
        dispatch(change('login-form', 'username', user));
        setUsername(user);
      }
    }
  }, [dispatch, isProduction, location, queryUsers, hasQueryParamUsers]);

  const autofillPassword = React.useCallback(() => {
    // only autofill if it ends with @ex since we know it's most likely @example.com, or if it's the first time using query param users
    if (username.endsWith('@ex') || canUseQueryParamUsers) {
      const account = username.split('@ex')[0];
      const autofilledUsername = `${account}@example.com`;
      if (account) {
        dispatch(change('login-form', 'username', autofilledUsername));
      }
      dispatch(change('login-form', 'password', STAGING_PASSWORD));
      if (canUseQueryParamUsers) {
        setHasSetQueryParamUser(true);
      }

      // Temporarily disallow typing so you don't accidentally type more characters
      setTypingPaused(true);
      window.setTimeout(() => {
        setTypingPaused(false);
      }, 1000);
    }
  }, [dispatch, canUseQueryParamUsers, username]);

  React.useEffect(() => {
    // Only autofill password if not in production AND not pressing backspace (since that could be annoying) AND EITHER the last typed key matches the last letter of the username OR the user is from the query param
    if (
      !isProduction &&
      lastKey !== 'Backspace' &&
      lastKey === username.slice(-1)
    ) {
      autofillPassword();
    }
  }, [lastKey, username, autofillPassword, isProduction]);

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (
      !isProduction &&
      e.clipboardData.getData('Text').includes('@example.com')
    ) {
      dispatch(change('login-form', 'password', STAGING_PASSWORD));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          padding: `8px 0`,
        }}
      >
        <LoginNotifications />
      </div>

      <Grid.Row>
        <Grid.Col xs={12}>
          {/* We need to change this to type=text to bypass the type=email input validation */}
          <TextField
            name="username"
            type="text"
            label="Email"
            validate={[required, email]}
            autoFocus
            color={color}
            normalize={trimWhitespace}
            // @ts-expect-error - TS7006 - Parameter 'e' implicitly has an 'any' type.
            onChange={(e) => setUsername(e.target.value)}
            // @ts-expect-error - TS7006 - Parameter 'e' implicitly has an 'any' type.
            onKeyDown={(e) =>
              typingPaused && e.key !== 'Enter'
                ? e.preventDefault()
                : setLastKey(e.key)
            }
            onPaste={handlePaste}
          />
          <PasswordField color={color} />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row
        style={{
          margin: '10px -10px',
        }}
      >
        <Grid.Col xsTextCenter xs={12}>
          <Link to="/forgot-password">Forgot Password</Link>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row
        style={{
          marginTop: 24,
        }}
      >
        <Grid.Col xs={12}>
          <Button
            disabled={attemptInProgress}
            type="submit"
            label="Log In"
            kind="primary"
            size="large"
            fullWidth
          />
        </Grid.Col>
      </Grid.Row>
    </form>
  );
};

const enhancer = compose<any, any>(
  UNSAFE_connectRedux(
    (state: AppState): Partial<LoginFormProps> => ({
      attemptInProgress: state.newFlows.login.attemptInProgress,
      initialValues: {
        username: state.newFlows.login.username || state.config.email || '',
      },
    }),
    {
      onSubmit: submitLoginForm,
    },
  ),
  connectForm({
    form: 'login-form',
  }),
);
export const LoginForm = enhancer(LoginFormComponent) as any;
