import * as React from 'react';
import { Route } from 'react-router-dom';

import { SetupIRAAccountPage } from './SetupIRAAccountPage';

export const SetupIraAccountRoute = () => {
  return (
    <Route
      path="setup-ira-account/:step?"
      element={<SetupIRAAccountPage />}
      handle={{
        fallbackRoute: {
          to: '/onboarding',
        },
      }}
    />
  );
};
