import { Box, Button, Flex, HXXS, PL } from '@m1/liquid-react';
import * as React from 'react';

import { RETIREMENT_ROLLOVER_CHOICES } from '~/static-constants';
import { Radio } from '~/toolbox/radio';

type RetirementRolloverTypeStepProps = {
  onFinishStep: (arg0: Choices) => void;
};

type Choices = ValueOf<typeof RETIREMENT_ROLLOVER_CHOICES>;

export const RetirementRolloverTypeStep = ({
  onFinishStep,
}: RetirementRolloverTypeStepProps) => {
  const [choice, setChoice] = React.useState<Choices | null>(null);

  const handleChange = (choice: Choices) => {
    setChoice(choice);
  };

  const handleClick = () => {
    if (!choice) {
      return;
    }

    onFinishStep(choice);
  };

  return (
    <Flex flexDirection="column">
      <HXXS content="What kind of 401k are you rolling over?" mt={16} mb={32} />
      <Radio.Group onChange={handleChange} value={choice}>
        <Radio.Choice
          label={<PL content="Traditional (most common)" fontWeight={600} />}
          value={RETIREMENT_ROLLOVER_CHOICES.TRADITIONAL}
        />
        <Radio.Choice
          label={<PL content="Roth" fontWeight={600} />}
          value={RETIREMENT_ROLLOVER_CHOICES.ROTH}
        />
      </Radio.Group>
      <Box>
        <Button
          disabled={!choice}
          kind="primary"
          label="Continue"
          mt={64}
          onClick={handleClick}
          size="large"
        />
      </Box>
    </Flex>
  );
};
