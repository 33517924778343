import * as React from 'react';

import { LabelWithValue } from '~/components/label-with-value';
import { TransferRuleDetail_ScheduledTransferRule_Fragment } from '~/graphql/types';
import { Grid } from '~/toolbox/grid';

import { ScheduleDetails } from './ScheduleDetails';

export const TransferDetails = ({
  isAutoPay,
  transferRule,
}: {
  isAutoPay: boolean;
  transferRule: TransferRuleDetail_ScheduledTransferRule_Fragment;
}) => (
  <Grid.Row>
    <Grid.Col xs={6}>
      <LabelWithValue
        label="Transfer from"
        value={
          transferRule.from ? transferRule.from.transferParticipantName : '--'
        }
      />
      <LabelWithValue
        label="Transfer to"
        style={{
          marginTop: 12,
        }}
        value={transferRule.to ? transferRule.to.transferParticipantName : '--'}
      />
    </Grid.Col>
    <Grid.Col xs={6}>
      <ScheduleDetails transferRule={transferRule} isAutoPay={isAutoPay} />
    </Grid.Col>
  </Grid.Row>
);
