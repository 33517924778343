import merge from 'lodash-es/merge';
import pick from 'lodash-es/pick';

import {
  CompletePhoneVerificationInput,
  InitiatePhoneVerificationInput,
  InitiatePhoneVerificationOutcome,
} from '~/graphql/types';
import { ACTION_TYPES as ACTIONS, ROUTING_ACTIONS } from '~/redux/actions';
import { PHONE_VERIFICATION_FLOW_STEPS as STEPS } from '~/static-constants';

import { FlowState } from '../newFlowsReducer.types';
import { createFlowReducer, createStepReducer } from '../utils';

export type PhoneVerificationFlowState = FlowState<
  typeof STEPS,
  {
    basePath: string;
    completeInput: Partial<CompletePhoneVerificationInput>;
    initiateInput: Partial<InitiatePhoneVerificationInput>;
    initiateOutcome: Partial<InitiatePhoneVerificationOutcome>;
  }
>;

export const phoneVerificationFlowInitialState: PhoneVerificationFlowState = {
  basePath: '',
  completeInput: {},
  initiateInput: {},
  initiateOutcome: {},
  step: STEPS.COLLECT_PHONE_NUMBER,
  stepTitle: 'Open Account',
};

const stepReducer = createStepReducer(phoneVerificationFlowInitialState);

function reducer(
  state: PhoneVerificationFlowState = phoneVerificationFlowInitialState,
  action: any,
): PhoneVerificationFlowState {
  switch (action.type) {
    case ACTIONS.BEGIN_FLOW:
      return {
        ...phoneVerificationFlowInitialState,
        ...pick(action.payload, Object.keys(phoneVerificationFlowInitialState)),
        step: phoneVerificationFlowInitialState.step,
      };
    case ACTIONS.FINISHED_FLOW_STEP:
      switch (action.meta.step) {
        case STEPS.COLLECT_PHONE_NUMBER:
          return merge({}, state, {
            initiateInput: action.payload,
          });
        case STEPS.VERIFY_RECEIVED_CODE:
          return merge({}, state, {
            completeInput: action.payload,
          });
        default:
          return state;
      }
    case ACTIONS.INITIATE_PHONE_VERIFICATION_SUCCEEDED:
      return merge({}, state, {
        completeInput: action.payload,
      });
    case ROUTING_ACTIONS.LOCATION_CHANGE:
      return {
        ...state,
        step: stepReducer(state, action),
      };
    default:
      return state;
  }
}

export const phoneVerification = createFlowReducer(
  'PHONE_VERIFICATION',
  reducer,
);
