import { Flex } from '@m1/liquid-react';
import noop from 'lodash-es/noop';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { FundAccountForm } from '~/forms';
import { useFundAccountPageQuery } from '~/graphql/hooks';
import {
  RecurrenceScheduleInput,
  FundedAccountFragment,
  IraContributionYearEnum,
} from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { useSelector } from '~/redux/hooks';
import { Container } from '~/toolbox/container';
import { Spinner } from '~/toolbox/spinner';
import { isNil, isTrue, toArray } from '~/utils';

import { FundAccountConfirmation } from './components/FundAccountConfirmation';

const RedirectToInvest = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate({ to: '/d/invest' });
  }, [navigate]);

  return null;
};

export const InvestFundAccountPage = () => {
  const navigate = useNavigate();

  const activeAccountId = useSelector((state) => state.global.activeAccountId);
  const {
    fromParticipantId,
    fundAccountAmount,
    schedule,
    iraContributionYear,
  } = useSelector<{
    fromParticipantId: string | null;
    fundAccountAmount: number;
    schedule: RecurrenceScheduleInput | null;
    iraContributionYear: IraContributionYearEnum | null;
  }>((state) => {
    const fundAccountFormValues = state.form['fund-account']?.values;
    return {
      fromParticipantId: fundAccountFormValues?.fromParticipantId ?? null,
      fundAccountAmount: fundAccountFormValues?.fundAccountAmount ?? 0,
      schedule: fundAccountFormValues?.schedule ?? null,
      iraContributionYear: fundAccountFormValues?.iraContributionYear ?? null,
    };
  });

  const [showConfirmation, setShowConfirmation] =
    React.useState<boolean>(false);
  const [isScheduleSwitchOn, setIsScheduleSwitchOn] =
    React.useState<boolean>(true);

  const { data, loading } = useFundAccountPageQuery({
    variables: { accountId: activeAccountId ?? '' },
  });

  const handleNextStepClick = () => {
    setShowConfirmation(true);
  };

  if (isTrue(loading)) {
    return <Spinner fullScreen />;
  }

  const account = data?.account as FundedAccountFragment;
  const viewer = data?.viewer;

  if (isNil(account) || isNil(viewer)) {
    return <GenericSystemError />;
  }

  const participantsList = toArray(viewer.transfers?.participants?.list);

  if (participantsList.length === 0 || account.status === 'REJECTED') {
    return <RedirectToInvest />;
  }

  return (
    <Container>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        maxWidth={600}
        mx="auto"
      >
        {showConfirmation && (
          <FundAccountConfirmation
            account={account}
            amount={fundAccountAmount}
            fromParticipantId={fromParticipantId}
            isCryptoAccount={account.isCryptoAccount ?? false}
            isScheduleSwitchOn={isScheduleSwitchOn}
            iraContributionYear={iraContributionYear}
            onCancelClick={() => setShowConfirmation(false)}
            participantsList={participantsList}
            schedule={schedule}
          />
        )}
        <FundAccountForm
          account={account}
          fromParticipantId={fromParticipantId}
          isCryptoAccount={account.isCryptoAccount}
          isScheduleSwitchOn={isScheduleSwitchOn}
          invalidExternalParticipants={
            account.isCryptoAccount ? ['EXTERNAL', 'SPEND'] : []
          }
          onNextStepClick={handleNextStepClick}
          onSkip={() => navigate({ to: '/d/invest' })}
          onSubmit={noop}
          schedule={schedule}
          setIsScheduleSwitchOn={setIsScheduleSwitchOn}
          showForm={!showConfirmation}
          transfers={viewer.transfers}
        />
      </Flex>
    </Container>
  );
};
