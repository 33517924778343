import { defaultProps } from '~/hocs';

import { formatPhoneNumber } from '~/utils/formatting';

import { normalizePhoneNumber } from '~/utils/normalizers';

import { required, phoneNumber } from '../validators';

import { TextField } from './text-field';

const enhancer = defaultProps({
  autoComplete: 'tel-national',
  format: formatPhoneNumber,
  label: 'Phone number',
  maxLength: 14,
  name: 'phoneNumber',
  normalize: normalizePhoneNumber,
  validate: [required, phoneNumber],
});
/**
 * @deprecated Use the other phone number component from PhoneNumberField.tsx
 */
export const PhoneNumberField = enhancer(TextField) as any;
