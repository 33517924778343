import { SagaIterator } from 'redux-saga';

import { ACTION_TYPES, updateUserData } from '~/redux/actions';

import { WatchlistState } from '../reducers/watchlistReducer';

import { getLoggers } from './common/getLoggersSaga';
import { call, put, select, takeEvery } from './effects';

export function* watchlistSaga(): SagaIterator {
  const actions = [
    ACTION_TYPES.ADD_TO_WATCHLIST,
    ACTION_TYPES.REMOVE_FROM_WATCHLIST,
  ];

  yield takeEvery(actions, function* (action: any): SagaIterator {
    const { analytics } = yield call(getLoggers);

    const watchlist: WatchlistState = yield select<WatchlistState>(
      (state) => state.watchlist,
    );

    const payload = {
      key: 'watchlist',
      value: JSON.stringify(watchlist),
    };

    yield put(updateUserData(payload));

    const logMessage =
      action.type === ACTION_TYPES.ADD_TO_WATCHLIST
        ? 'watchlistItemAdded'
        : 'watchlistItemRemoved';
    analytics.recordAppAnalyticsEvent({
      name: logMessage,
      customParameters: [
        {
          name: 'sliceable_type',
          value: action.payload.securityType,
        },
      ],
      valueParameter: null,
    });
  });
}
