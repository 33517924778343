import { Card } from '@m1/liquid-react';

import { withProps } from '~/hocs';
import { Link } from '~/toolbox/link/Link';

const enhancer = withProps({
  as: Link,
});

export const NavigableCard = enhancer(Card) as any;
