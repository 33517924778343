import { theme } from '@m1/liquid-react';
import * as React from 'react';

export type Props = {
  width?: number | string;
};

export const M1Logo = ({ width = 55 }: Props) => {
  const widthWithUnits = typeof width === 'number' ? `${width}px` : width;

  return (
    <svg
      display="inline-block"
      fill={theme.colors.foregroundNeutralMain}
      width={width}
      height={`calc(${widthWithUnits} / 1.21739)`}
      viewBox="0 0 67 59"
    >
      <g fill={theme.colors.foregroundNeutralMain} fillRule="evenodd">
        <path d="M16.304.113H.58a.597.597 0 0 0-.583.61v5.99c0 .162.061.317.17.431L33.192 41.75a.564.564 0 0 0 .823 0l11.154-11.635-.101-.024.057-.095L16.714.291a.57.57 0 0 0-.41-.178" />
        <path d="M50.407.214L35.91 15.45a.358.358 0 0 0 0 .489l10.984 11.606a.32.32 0 0 0 .473-.003l2.85-3.089c.047-.05.13-.016.13.055v33.702c0 .192.147.347.33.347H66.67c.182 0 .33-.155.33-.347V.46a.339.339 0 0 0-.33-.346H50.64a.322.322 0 0 0-.233.101M.546 58.556h15.169a.562.562 0 0 0 .549-.576V28.73a.589.589 0 0 0-.16-.405L.934 12.357c-.345-.363-.937-.107-.937.406V57.98c0 .318.246.576.549.576" />
      </g>
    </svg>
  );
};
