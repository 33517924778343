import * as React from 'react';

export const useDebounce = (callback: (args: any) => any, delay: number) => {
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const debounceCallback = React.useCallback(
    (...args: any) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        callback(args);
      }, delay);
    },
    [callback, delay],
  );

  return debounceCallback;
};
