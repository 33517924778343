import { PM, styled } from '@m1/liquid-react';
import * as React from 'react';

import { Link } from '~/toolbox/link';

const StyledPM = styled(PM)`
  display: initial;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-weight: 600;
`;

export const IntercomLink = () => {
  const onContactUsClick = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };

  if (!window.Intercom) {
    return (
      <Link to="https://help.m1.com" target="_blank">
        Contact support
      </Link>
    );
  }
  return <StyledPM onClick={onContactUsClick} content="Contact support" />;
};
