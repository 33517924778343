import * as React from 'react';

import { CollectProfileInput } from '~/components/CollectProfileInput';
import { GenericSystemError } from '~/components/GenericSystemError';
import { useOnboardingIdentityFirstQuestionsQuery } from '~/graphql/hooks';
import { Spinner } from '~/toolbox/spinner';
import { getEnumEntries } from '~/utils';
export const CollectLiquidNetWorthStep = () => {
  const { data, loading } = useOnboardingIdentityFirstQuestionsQuery();

  if (loading) {
    return <Spinner />;
  }

  if (!data?.viewer) {
    return <GenericSystemError />;
  }

  const questions =
    data?.viewer.user?.onboardingIdentityFirst?.userProfileSection?.questions;
  const question = questions?.find(
    (question) => question?.questionType === 'LIQUID_NET_WORTH',
  );

  const title =
    question?.title || 'What do you have in cash and liquid investments';

  return (
    <CollectProfileInput
      field="suitability.liquidNetWorth"
      question={
        title ? title : 'What do you have in cash and liquid investments?'
      }
      content={question?.subtitle}
      options={getEnumEntries('LiquidNetWorthEnum')}
    />
  );
};
