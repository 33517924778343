import * as React from 'react';
import { Route } from 'react-router-dom';

import { SecurityDetailsLayout } from '../../SecurityDetailsLayout';

export const ResearchStockDetailsRoute = () => {
  return (
    <Route
      path="details/:equityId"
      element={<SecurityDetailsLayout type="EQUITY" />}
    />
  );
};
