import { Box, Button, Flex, HXS, PL, PM, Card } from '@m1/liquid-react';
import * as React from 'react';

import { Checkbox } from '~/toolbox/checkbox';
import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';

type TrustAccountTypeSelectStepProps = {
  onFinishStep: () => void;
};

export const TrustAccountSelectedStep = ({
  onFinishStep,
}: TrustAccountTypeSelectStepProps) => {
  const [checked, setChecked] = React.useState<boolean>(false);

  return (
    <Container>
      <Flex flexDirection="column">
        <HXS content="Trust account" pt={32} />
        <PL
          content="Investment accounts for trusts are unable to be completed entirely online. M1 will send you the required forms to complete the signup process."
          pt={16}
        />
        <Card
          borderColor="transparent"
          backgroundColor="backgroundNeutralTertiary"
          mt={16}
          px={8}
          py={16}
        >
          <Flex alignItems="center">
            <Checkbox
              checked={checked}
              onChange={() => {
                setChecked(!checked);
              }}
              label={
                <PM pl={8}>
                  I understand there is a $5,000 minimum for opening an entity
                  account. I have read and agree to the{' '}
                  <Link
                    to="https://www.m1.com/M1_Account_Agreement.pdf"
                    target="_blank"
                  >
                    M1 Client Agreement
                  </Link>
                  , and acknowledge receipt of the{' '}
                  <Link to="https://www.m1.com/legal/privacy/" target="_blank">
                    M1 Privacy Policy
                  </Link>
                  .
                </PM>
              }
            />
          </Flex>
        </Card>
        <Box pt={32}>
          <Button
            disabled={!checked}
            label="Submit"
            onClick={onFinishStep}
            size="large"
          />
        </Box>
      </Flex>
    </Container>
  );
};
