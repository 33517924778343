import type { LogLevel } from './types';

export class AbstractError extends Error {
  name: string = 'AbstractError';

  level: LogLevel = 'error';
  tags: Array<[string, string]> | null | undefined;
}

export class ConcreteError extends AbstractError {
  name = 'ConcreteError';
}
export class GroupedError extends AbstractError {
  name = 'GroupedError';
}
