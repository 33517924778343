import { Flex, PXL, css, styled } from '@m1/liquid-react';
import * as React from 'react';

import { useEnvironment } from '~/hooks/useEnvironment';
import { ENVIRONMENT_OPTIONS } from '~/static-constants';
import { Dropdown } from '~/toolbox/Dropdown';

import { BuildNotesModal } from './BuildNotesModal';

const DropdownContainer = styled(Flex)<{ $fullWidth: boolean | undefined }>`
  gap: 16px;
  // No other good way to style the Dropdown
  ${({ $fullWidth }) =>
    $fullWidth
      ? css`
          justify-content: start;
          > div {
            flex: 1;
          }
        `
      : css`
          justify-content: center;
        `}
  > div {
    padding-top: 0;
  }
`;

export const EnvironmentDropdown = ({ fullWidth }: { fullWidth?: boolean }) => {
  const [environment, setEnvironment] = useEnvironment();
  const isProduction =
    __NODE_ENV__ === 'production' && __ENV__ === 'production';

  if (isProduction) {
    // For redundancy's sake
    return null;
  }
  const dropdownValue =
    environment.label === 'custom' ? '__CUSTOM__' : environment.value;

  const setEnvironmentOption = (env: string): void => {
    let customEnv = null;
    if (env === '__CUSTOM__') {
      /* eslint-disable no-alert */
      customEnv = prompt(
        'Please enter the environment url.',
        environment.value,
      );
      if (!customEnv) {
        return;
      }
      if (!confirm(`The url you entered is: ${customEnv}`)) {
        setEnvironmentOption(env);
        return;
      }
    }
    const environmentToSet = customEnv || env;
    const foundEnv = ENVIRONMENT_OPTIONS.find(
      (e) => e.value === environmentToSet,
    ) || {
      label: 'custom',
      value: environmentToSet,
    };
    setEnvironment(foundEnv);
  };

  return (
    <DropdownContainer
      $fullWidth={fullWidth}
      alignItems="center"
      position="sticky"
      zIndex={2}
      top={0}
      backgroundColor="backgroundNeutralSecondary"
      title={
        environment.label === 'custom'
          ? `Custom Env: ${environment.value}`
          : undefined
      }
      data-testid="environment-picker"
    >
      <PXL fontWeight={600}>Current Env:</PXL>
      <Dropdown
        onChange={setEnvironmentOption}
        isSearchable
        options={ENVIRONMENT_OPTIONS}
        value={dropdownValue}
        name="set-test-environment"
        size="small"
      />
      <BuildNotesModal />
    </DropdownContainer>
  );
};
