import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { RewardsSummaryRoute } from './summary';

export const CreditCardRewardsRoute = () => {
  return (
    <Route path="rewards">
      {RewardsSummaryRoute()}
      <Route
        index
        element={<Navigate to="/d/spend/credit/rewards/summary" replace />}
      />
    </Route>
  );
};
