import { Flex, PM, Tooltip } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

export const DividendPerShareTooltip = () => {
  return (
    <Tooltip
      placement="bottom-end"
      body={
        <Flex flexDirection="column" p={16}>
          <PM mb={16}>Estimated dividends includes:</PM>
          <ul>
            <li style={{ marginBottom: '16px' }}>
              <PM fontWeight={400}>
                Those that have been declared but have not yet reached the
                ex-dividend date. Total payment amounts for these are based on
                your current shares, but you can still buy/sell shares before
                the ex-dividend date, impacting total payment.
              </PM>
            </li>
            <li>
              <PM fontWeight={400}>
                Those that haven't been declared. We are presuming these
                dividends will be issued. Total payments amounts are estimated
                based on the holding's current annualized dividend yield, the
                previous trading day's closing price, and the number of shares
                you currently own. Buying or selling these shares could impact
                total payment amount. This estimation is based on various
                factors and is not guaranteed.
              </PM>
            </li>
          </ul>
        </Flex>
      }
    >
      <Icon name="tooltip16" ml={4} />
    </Tooltip>
  );
};
