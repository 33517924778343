import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { FullyPaidLendingSettingsPage } from './FullyPaidLendingSettingsPage/FullyPaidLendingSettingsPage';
import { InvestSettingsLayout } from './InvestSettingsLayout';

export const InvestSettingsRoute = () => {
  return (
    <Route path="invest" element={<InvestSettingsLayout />}>
      <Route index element={<Navigate to="fully-paid-lending" replace />} />
      <Route
        path="fully-paid-lending"
        element={<FullyPaidLendingSettingsPage />}
      />
    </Route>
  );
};
