import { SagaIterator } from 'redux-saga';
import { call, setContext, takeEvery, takeLatest } from 'redux-saga/effects';

import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import { IDENTITY_PROFILE_FLOW_STEPS as STEPS } from '~/static-constants';

import { changeStep } from '../utils';

export function* identityProfileSaga(): SagaIterator<void> {
  yield takeLatest('BEGIN_IDENTITY_PROFILE_FLOW', identityFlow);
}

function* identityFlow(action: Record<string, any>): SagaIterator<void> {
  const { onFinish, basePath } = action.payload;
  yield setContext({
    basePath,
  });

  yield takeEvery(
    ACTIONS.SUBMITTED_CONTACT_INFO_FORM,
    finishedContactInfo,
    // @ts-expect-error - TS2554 - Expected 2 arguments, but got 3.
    onFinish,
  );

  yield takeEvery(
    ACTIONS.SUBMITTED_IDENTITY_INFO_FORM,
    finishedCollectingPrimaryInfo,
    // @ts-expect-error - TS2554 - Expected 2 arguments, but got 3.
    onFinish,
  );
  yield takeEvery(ACTIONS.COLLECTED_HOLDER_SSN, finishedSSN, onFinish);
}

function* finishedContactInfo(): SagaIterator<void> {
  yield call(changeStep, STEPS.COLLECT_PRIMARY_IDENTITY_INFO);
}

function* finishedCollectingPrimaryInfo(): SagaIterator<void> {
  yield call(changeStep, STEPS.COLLECT_SSN, true);
}

// @ts-expect-error - TS7006 - Parameter 'onFinish' implicitly has an 'any' type.
function* finishedSSN(onFinish): SagaIterator<void> {
  yield call(onFinish);
}
