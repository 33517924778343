import * as React from 'react';

import { useOnboardingProgressIndicatorQuery } from '~/graphql/hooks';
import type { AppState } from '~/redux';
import { useSelector } from '~/redux/hooks';

import { ONBOARDING_FLOW_STEPS as STEPS } from '~/static-constants';
import { Progress } from '~/toolbox/progress';

import { AccountSetupFlowProgress } from './AccountSetupFlowProgress';

import { InitialFundingFlowProgress } from './InitialFundingFlowProgress';

export const OnboardingProgressIndicator = () => {
  const step = useSelector((state: AppState) => state.newFlows.onboarding.step);

  switch (step) {
    case STEPS.PHONE_VERIFICATION:
    case STEPS.SETUP_ACCOUNT:
      return <SetupAccountStep />;
    case STEPS.INITIAL_FUNDING:
      // @ts-expect-error - TS2741 - Property 'percent' is missing in type '{}' but required in type 'Omit<ProgressProps, never>'.
      return <InitialFundingFlowProgress />;
    default:
      return <Progress percent={0} />;
  }
};

const SetupAccountStep = () => {
  const { data, error } = useOnboardingProgressIndicatorQuery();

  const verification = data?.viewer.user?.requiresPhoneVerification;
  if (error || verification === undefined || verification === null) {
    return <Progress percent={0} />;
  }

  return (
    <AccountSetupFlowProgress isPhoneVerificationRequired={verification} />
  );
};
