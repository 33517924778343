import { MutationHookOptions } from '@apollo/client';
import { isNil } from 'lodash-es';
import { useEffect } from 'react';

import { useCreatePieShareUrlMutation } from '~/graphql/hooks';
import {
  readPieShareUrlData,
  setPieShareUrlData,
} from '~/graphql/local/reactiveVars/pieShareUrl';
import {
  CreatePieShareUrlMutation,
  CreatePieShareUrlMutationVariables,
} from '~/graphql/types';

/**
 * Trigger the `createPieShareUrl` mutation on render and cache the result.
 * We get the pie share data from a mutation, but we want it to behave more
 * like a query with caching. This hook will first check the local cache
 * and skip the mutation if we already have the data. Otherwise, it will
 * call the mutation and cache the result.
 * @param pieId Pie ID mutation variable
 * @param baseOptions Mutation options
 * @returns Mutation result
 */
export const useCreatePieShareUrlOnRender = (
  pieId: string,
  baseOptions?: MutationHookOptions<
    CreatePieShareUrlMutation,
    CreatePieShareUrlMutationVariables
  >,
) => {
  const [createPieShareUrl, { data, loading, error }] =
    useCreatePieShareUrlMutation(baseOptions);

  const cachedData = readPieShareUrlData(pieId);

  useEffect(() => {
    if (isNil(cachedData)) {
      createPieShareUrl({
        variables: { input: { pieId } },
        onCompleted: (data) => {
          if (data?.createPieShareUrl?.result.didSucceed === true) {
            setPieShareUrlData(pieId, data);
          }
        },
      });
    }
  }, [pieId, createPieShareUrl, cachedData]);

  // Prefer data from the mutation if we have it, otherwise return cached data.
  return { data: data ?? cachedData, loading, error };
};
