import { Button } from '@m1/liquid-react';
import * as React from 'react';
import { FieldArray } from 'redux-form';

import { connectForm } from '~/hocs';

import { SsnField } from './fields';

type Props = {
  handleSubmit: (...args: Array<any>) => any;
  names: Array<{
    firstName: string;
    lastName: string;
  }>;
  onSubmit: (...args: Array<any>) => any;
};

class ConfirmBeneficiariesFormComponent extends React.Component<Props> {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
        {}
        <FieldArray name="ssns" component={this.renderFields} />
        <br />
        <Button
          label="Continue"
          kind="primary"
          size="large"
          type="submit"
          fullWidth
        />
      </form>
    );
  }

  // @ts-expect-error - TS7031 - Binding element 'fields' implicitly has an 'any' type.
  renderFields = ({ fields }) => {
    const { names } = this.props;
    // @ts-expect-error - TS7006 - Parameter 'member' implicitly has an 'any' type. | TS7006 - Parameter 'index' implicitly has an 'any' type.
    return fields.map((member, index) => (
      <SsnField
        key={member}
        name={member}
        label={`${names[index].firstName} ${names[index].lastName}`}
        autoFocus={index === 0}
      />
    ));
  };

  handleSubmit = ({ ssns }: Record<string, any>): void => {
    this.props.onSubmit(ssns);
  };
}

export const ConfirmBeneficiariesForm = connectForm({
  form: 'confirm-beneficiaries',
})(ConfirmBeneficiariesFormComponent);
