import { useUpdateProfileMutation } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import { useToast } from '~/toasts';

import { PRODUCT_SELECTION_ROUTE_MAP } from '../steps/utils';

export const useUpdateProfileHook = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [updateProfile, { loading: updateProfileLoading }] =
    useUpdateProfileMutation();

  const updateProfileMutation = async (
    data: any,
    product?: string | null | undefined,
  ) => {
    // updateProfile and route to Module 3 for selected product
    await updateProfile({
      variables: {
        input: {
          profile: {
            primary: {
              employment: {
                ...data?.primary?.employment,
              },
              backupWithholding: {
                ...data?.primary?.backupWithholding,
              },
              exchangeOrFinraAffiliationDisclosure: {
                ...data?.primary?.exchangeOrFinraAffiliationDisclosure,
              },
              controlPersonDisclosure: {
                ...data?.primary?.controlPersonDisclosure,
                companySymbols:
                  data?.primary?.controlPersonDisclosure?.companySymbols?.map(
                    (v: string) => v.trim(),
                  ),
              },
              politicalExposureDisclosure: {
                ...data?.primary?.politicalExposureDisclosure,
                immediateFamilyMembers:
                  data?.primary?.politicalExposureDisclosure?.immediateFamilyMembers?.map(
                    (v: string) => v.trim(),
                  ),
              },
            },
            suitability: {
              totalNetWorth: data?.suitability?.totalNetWorth,
              liquidNetWorth: data?.suitability?.liquidNetWorth,
            },
          },
        },
      },
      onCompleted: () => {
        if (product) {
          const to =
            PRODUCT_SELECTION_ROUTE_MAP[
              product as keyof typeof PRODUCT_SELECTION_ROUTE_MAP
            ];
          navigate({ to });
        } else {
          navigate({ to: '/d/home' });
        }
      },
      onError: () =>
        addToast({
          content: 'Error updating profile. Please try again later.',
          kind: 'alert',
          duration: 'long',
        }),
    });
  };

  return {
    updateProfileMutation,
    updateProfileLoading,
  };
};
