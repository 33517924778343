import { Button, Flex, HXXS, PL } from '@m1/liquid-react';
import * as React from 'react';

import { useUserCryptoEligibilityQuery } from '~/graphql/hooks';
import { INVEST_ACCOUNT_TYPES } from '~/static-constants';
import { Radio } from '~/toolbox/radio';
import { Spinner } from '~/toolbox/spinner';

type AccountTypes = ValueOf<typeof INVEST_ACCOUNT_TYPES>;

type SelectAccountTypeProps = {
  onFinishStep: (argo0: AccountTypes) => void;
  defaultAccountType?: AccountTypes;
};

export const SelectAccountTypeStep = ({
  onFinishStep,
  defaultAccountType,
}: SelectAccountTypeProps) => {
  const [accountType, setAccountType] = React.useState<
    ValueOf<typeof INVEST_ACCOUNT_TYPES> | undefined
  >(defaultAccountType);

  const { data, loading } = useUserCryptoEligibilityQuery();
  const handleContinue = (): void => {
    if (accountType) {
      onFinishStep(accountType);
    }
  };

  if (loading) {
    return <Spinner fullScreen />;
  }
  return (
    <>
      <HXXS content="Select account type" pt={16} />
      <PL
        content="Choose between a taxable or retirement investing account."
        pt={16}
      />
      <Radio.Group onChange={setAccountType} value={accountType} mt={24}>
        <Radio.Choice
          label={
            <>
              <PL content="Individual Investing" fontWeight={600} />
              <PL content="Open a taxable individual brokerage account" />
            </>
          }
          value={INVEST_ACCOUNT_TYPES.INDIVIDUAL_TAXABLE}
        />
        {data?.viewer.crypto?.isEnabled && data.viewer.crypto.isEligible && (
          <Radio.Choice
            label={
              <>
                <Flex alignItems="center">
                  <PL content="Crypto Account" mr={8} fontWeight={600} />
                </Flex>
                <PL content="Open a taxable investment account to hold cryptocurrency" />
              </>
            }
            value={INVEST_ACCOUNT_TYPES.CRYPTO}
          />
        )}
        <Radio.Choice
          label={
            <>
              <PL content="Joint Investing" fontWeight={600} />
              <PL content="Open a taxable joint brokerage account. Please note that we will ask for the personal information for both people on the account." />
            </>
          }
          value={INVEST_ACCOUNT_TYPES.JOINT_TAXABLE}
        />
        <Radio.Choice
          label={
            <>
              <PL content="Retirement" fontWeight={600} />
              <PL content="Open a Traditional, Roth or SEP IRA or rollover an existing 401(k)" />
            </>
          }
          value={INVEST_ACCOUNT_TYPES.RETIREMENT}
        />
        <Radio.Choice
          label={
            <>
              <PL content="Trust Account" fontWeight={600} />
              <PL content="Open an investment account for your trust." />
            </>
          }
          value={INVEST_ACCOUNT_TYPES.OTHER}
        />
        <Radio.Choice
          label={
            <>
              <Flex alignItems="center">
                <PL content="Custodial Account" mr={8} fontWeight={600} />
              </Flex>
              <PL content="Open a taxable brokerage account for a minor. Ownership of this account will need to be transferred when the minor reaches the age of maturity." />
            </>
          }
          value={INVEST_ACCOUNT_TYPES.CUSTODIAL}
        />
      </Radio.Group>

      <Button
        disabled={!accountType}
        kind="primary"
        label="Continue"
        mt={64}
        onClick={handleContinue}
        size="large"
      />
    </>
  );
};
