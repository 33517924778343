import { LM, Box } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { togglePortfolioOrganizerHelperDialogAction } from '~/redux/actions';
import { useSelector, useDispatch } from '~/redux/hooks';

export type HelperDialogToggleProps = {
  resetDialogPageCallback: () => void;
};

export const HelperDialogToggle = ({
  resetDialogPageCallback,
}: HelperDialogToggleProps) => {
  const dispatch = useDispatch();
  const showHelperDialogs = useSelector(
    (state) => state.portfolioOrganizer.showHelperDialogs,
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={() => {
        resetDialogPageCallback();
        dispatch(togglePortfolioOrganizerHelperDialogAction());
      }}
      style={{
        cursor: 'pointer',
      }}
    >
      <LM color="primary" content="View Helper" mr={8} />
      <Icon
        name={showHelperDialogs ? 'helpFill24' : 'help24'}
        color="primary"
      />
    </Box>
  );
};
