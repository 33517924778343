import memoize from 'lodash-es/memoize';

import { withProps } from '~/hocs';

import { normalizeCurrency } from '~/utils/normalizers';

import { required } from '../validators';

import { TextField } from './text-field';

const validate = memoize(createValidator);

const enhancer = withProps((props) => ({
  name: 'amount',
  label: 'Amount',
  normalize: normalizeCurrency,
  // @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter. | TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'Record<string, any>'.
  validate: validate(...readLimit(props)),
  autoComplete: 'off',
  maxLength: 9,
}));

// TODO: Improve this abstraction
function readLimit(props: Record<string, any>) {
  // @ts-expect-error - TS7034 - Variable 'limits' implicitly has type 'any[]' in some locations where its type cannot be determined.
  let limits = [];
  if (props.accountLimit) {
    // @ts-expect-error - TS7005 - Variable 'limits' implicitly has an 'any[]' type.
    limits = limits.concat(props.accountLimit);
  }
  if (props.transferLimit) {
    limits = limits.concat(props.transferLimit);
  }
  return limits;
}

// This must be passed scalar arguments so the result is properly memoized;
function createValidator(
  accountLimit: number,
  accountMessage: string,
  transferLimit: number,
  transferMessage: string,
): Array<(...args: Array<any>) => any> {
  const rules = [required];

  if (transferLimit) {
    rules.push((value) => {
      if (Number(value) > transferLimit) {
        return transferMessage;
      }
    });
  }

  if (accountLimit) {
    rules.push((value) => {
      if (Number(value) > accountLimit) {
        return accountMessage;
      }
    });
  }

  return rules;
}

export const LEGACY_TransferAmountField = enhancer(TextField) as any;
