import * as React from 'react';

import { useNavigate } from '~/hooks/useNavigate';

export const Redirect = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate({ to: '/d/open-invest-account' });
  });

  return null;
};
