import * as React from 'react';
import { Route } from 'react-router-dom';

import { EditSmartTransferPage } from './EditSmartTransferPage';

export const EditSmartTransferRoute = () => {
  return (
    <Route
      path="edit-smart-transfer/:smartTransferRuleId"
      element={<EditSmartTransferPage />}
      handle={{
        fallbackRoute: {
          to: '/d/transfers/rules',
        },
      }}
    >
      <Route path="new-contra-entry/:newContraParticipantEntryOptionId" />
      <Route path="edit-contra-entry/:contraParticipantEntryEditRequirementsId" />
    </Route>
  );
};
