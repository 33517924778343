export type AuthState = {
  accessToken: string | null | undefined;
  refreshToken: string | null | undefined;
};

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
};

export const authReducer = (
  state: AuthState = initialState,
  action: Record<string, any>,
): AuthState => {
  switch (action.type) {
    case 'AUTH_SESSION_UPDATED':
      return action.payload;
    default:
      return state;
  }
};
