import { SagaIterator } from 'redux-saga';
import { all, call, put, setContext, spawn } from 'redux-saga/effects';

import { initializeApp } from '~/redux/actions';

import { appInitWatcher } from './appInitialization';
import { authSaga } from './auth/authSaga';
import { displayErrorNotificationSaga } from './displayErrorNotificationSaga';
import { displaySuccessNotificationSaga } from './displaySuccessNotificationSaga';
import { eventsSaga } from './events';
import { flowsSaga } from './flows';
import {
  startIntercom,
  updateIntercom,
  shutdownIntercom,
} from './intercomSaga';
import { logoutSaga } from './logoutSaga';
import { monitorPages } from './monitors';
import { openInvestAccountSaga } from './open-invest-account';
import { pieEditorSaga } from './pieEditorSaga';
import { portfolioOrganizerSaga } from './portfolioOrganizer/portfolioOrganizerSaga';
import { reacceptLegalTermsWatcher } from './reacceptLegalTermsSaga';
import { searchSliceablesSaga } from './searchSliceablesSaga';
import { selectPaymentsAccount } from './selectPaymentsAccountSaga';
import { moveUnmanagedHoldingsSaga } from './unmanagedHoldings';
import { updateProfileSaga } from './updateProfileSaga';
import { updateUserDataSaga } from './updateUserDataSaga';
import { watchlistSaga } from './watchlistSaga';

export function* rootSaga(context: Record<string, any>): SagaIterator<void> {
  yield setContext(context);

  yield call(authSaga, context.auth);

  yield all([
    spawn(appInitWatcher),
    spawn(displayErrorNotificationSaga),
    spawn(displaySuccessNotificationSaga),
    spawn(eventsSaga),
    spawn(flowsSaga),
    spawn(logoutSaga),
    spawn(monitorPages),
    spawn(openInvestAccountSaga),
    spawn(pieEditorSaga),
    spawn(portfolioOrganizerSaga),
    spawn(reacceptLegalTermsWatcher),
    spawn(searchSliceablesSaga),
    spawn(selectPaymentsAccount),
    spawn(shutdownIntercom),
    spawn(startIntercom),
    spawn(moveUnmanagedHoldingsSaga),
    spawn(updateIntercom),
    spawn(updateProfileSaga),
    spawn(updateUserDataSaga),
    spawn(watchlistSaga),
  ]);

  yield put(initializeApp());
}
