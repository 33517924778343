import { styled } from '@m1/liquid-react';
import * as React from 'react';

export type SecondaryHeaderProps = {
  children?: React.ReactNode;
  css?: string;
  suppressZIndex?: boolean;
};

const StyledSecondaryHeader = styled.div<SecondaryHeaderProps>`
  position: relative;
  transition: none 0.2s ease-in-out;
  transition-property: background-color, border-bottom-color;

  background-color: inherit;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderMain};
  ${(props) => props.css};
  ${(props) => {
    if (!props.suppressZIndex) {
      return 'z-index: 100;';
    }
  }};
`;

export const SecondaryHeader = (props: SecondaryHeaderProps) => (
  <StyledSecondaryHeader {...props} />
);
