import { throttle } from 'lodash-es';
import * as React from 'react';

type DynamicSizingReturnType = [
  { height: number; width: number },
  React.RefObject<HTMLDivElement>,
];

type DynamicSizingOptions =
  | {
      shouldResize?: boolean;
      monitorChanges?: boolean;
    }
  | undefined;

/**
 * hook useDynamicSizing
 * @param options shouldResize - should the hook listen to window resize events and set dimensions on mount (defaults true)
 *                monitorChanges - should the hook listen to changes in the element (defaults false)
 * @returns [{ height, width }, ref]
 */
export const useDynamicSizing = (options: DynamicSizingOptions = {}) => {
  const { shouldResize = true, monitorChanges = false } = options ?? {};
  const [height, setHeight] = React.useState<number>(0);
  const [width, setWidth] = React.useState<number>(0);
  const resizeRef = React.useRef<HTMLDivElement | null>(null);

  const handleResize = React.useCallback((): void => {
    if (resizeRef.current) {
      const { height, width } = resizeRef.current.getBoundingClientRect();
      if (height) {
        setHeight(height);
      }
      if (width) {
        setWidth(width);
      }
    }
  }, [resizeRef.current]);

  const throttledResize = throttle(handleResize, 1000, {
    leading: false,
    trailing: true,
  });

  React.useEffect(() => {
    if (!monitorChanges || !resizeRef.current) {
      return;
    }

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' || mutation.type === 'childList') {
          throttledResize();
        }
      });
    });

    observer.observe(resizeRef.current as Node, {
      childList: true,
      subtree: true,
      attributes: true,
    });

    return () => observer.disconnect();
  }, [resizeRef.current]);

  React.useEffect(() => {
    if (shouldResize) {
      handleResize();
      window.addEventListener('resize', handleResize);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, shouldResize, resizeRef]);
  return [
    {
      height,
      width,
    },
    resizeRef,
  ] as DynamicSizingReturnType;
};
