import { Button, Flex, Image, styled, PM } from '@m1/liquid-react';
import React from 'react';

import iconPdf from '~/images/icons/icon-pdf.svg';

import { DocumentRemoveModal } from './DocumentRemoveModal';

type DocumentPreviewProps = {
  closeModal: () => void;
  handleClickedRemove: () => void;
  handleRemoveImage: () => void;
  imagePreview: string | null | undefined;
  isModalOpen: boolean;
  selectedFile: File;
};

const StyledImagePreview = styled.img`
  max-height: 150px;
  max-width: 450px;
  align-self: center;
`;

export const DocumentPreview = (props: DocumentPreviewProps) => (
  <Flex flexDirection="column" justifyContent="center" ml="auto">
    {props.imagePreview && props.selectedFile.type !== 'application/pdf' && (
      <StyledImagePreview src={props.imagePreview} />
    )}
    <Flex>
      {props.selectedFile.type === 'application/pdf' && (
        <Image alt="" src={iconPdf} height={24} mr={8} mb={16} />
      )}
      <PM fontWeight={600} content={props.selectedFile.name} mb={8} />
    </Flex>
    <Button
      kind="destructive-text"
      label="Remove"
      style={{
        height: 'auto',
        padding: '0px',
        justifyContent: 'flex-start',
      }}
      onClick={props.handleClickedRemove}
    />
    <DocumentRemoveModal
      isModalOpen={props.isModalOpen}
      onConfirmation={props.handleRemoveImage}
      closeModal={props.closeModal}
    />
  </Flex>
);
