import * as React from 'react';

import { PeriodSelectorContainer, Period, Label } from './elements';

export type PeriodSelectorProps = {
  onChangePeriod: (...args: Array<any>) => any;
  period: string;
  periods: Array<{
    label: string;
    value: string;
  }>;
};

export const PeriodSelector = ({
  onChangePeriod,
  period,
  periods,
}: PeriodSelectorProps) => (
  <PeriodSelectorContainer>
    {periods.map(({ value, label }, index) => (
      <Period
        $isActive={period === value}
        alignItems="center"
        justifyContent="center"
        key={`${value}-${index}`}
        onClick={() => onChangePeriod(value)}
      >
        <Label>{label}</Label>
      </Period>
    ))}
  </PeriodSelectorContainer>
);
