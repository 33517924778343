import {
  Button,
  Flex,
  HS,
  HXS,
  HXXS,
  Modal,
  ModalContent,
  PL,
  PM,
  PXL,
  useLocalStorage,
  styled,
  Box,
  useIsProduction,
} from '@m1/liquid-react';
import Markdown, { RuleType } from 'markdown-to-jsx';
import * as React from 'react';
import { withProps } from 'recompose';

import { Checkbox } from '~/toolbox/checkbox';
import { Link } from '~/toolbox/link';

const MarkdownContainer = styled(Box)`
  ul,
  ol {
    margin-left: 24px;
    margin-bottom: 16px;

    li {
      margin-top: 4px;
    }
  }

  // First child of this Box should have no margin
  > *:first-child {
    margin-top: 0 !important;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  h1,
  h2 {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderMain};
    padding-bottom: 8px;
  }

  p {
    margin-bottom: 16px;
  }

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.foregroundPrimary};

    &:visited {
      color: ${({ theme }) => theme.colors.foregroundPrimary};
    }
  }

  pre {
    border-radius: 6px;
    font-size: 12px;
    margin-bottom: 16px;
    padding: 16px;
    background-color: ${({ theme }) => theme.colors.backgroundNeutralMain};
  }

  code {
    border-radius: 6px;
    padding: 3px 6px;
    background-color: ${({ theme }) => theme.colors.backgroundNeutralMain};
  }
`;

function preprocessMarkdown(html: string): string {
  // Replace unoquoted attribute values with quoted values
  return html.replace(/(\b\w+=)([^"'\s>]+)/g, '$1"$2"');
}

export const BuildNotesModal = () => {
  // Tracks if the user has seen these exact build notes before - using the notes as
  // the key means build notes will be shown automatically when the key (the notes) changes
  // unless hide_future_build_notes is true.
  const [hasSeenExactBuildNotes, setHasSeenExactBuildNotes] = useLocalStorage(
    __BUILD_NOTES__,
    false,
  );
  // Tracks if the user does not want to see future build notes automatically
  const [hideFutureBuildNotes, setHideFutureBuildNotes] = useLocalStorage(
    'hide_future_build_notes',
    false,
  );
  const [showBuildNotes, setShowBuildNotes] = React.useState<boolean>(
    Boolean(__BUILD_NOTES__) &&
      !hasSeenExactBuildNotes &&
      !hideFutureBuildNotes,
  );

  const isProduction = useIsProduction();
  if (isProduction) {
    return null;
  }

  return __BUILD_NOTES__ ? (
    <>
      <Button
        size="small"
        kind="secondary"
        style={{ flexShrink: 0 }}
        onClick={() => setShowBuildNotes((prev) => !prev)}
      >
        View build notes
      </Button>
      <Modal
        open={showBuildNotes}
        onClose={() => {
          setShowBuildNotes(false);
          setHasSeenExactBuildNotes(true);
        }}
      >
        <ModalContent width="wide">
          <Markdown
            options={{
              wrapper: MarkdownContainer,
              renderRule(next, node) {
                // Handling images as links because GH image urls do not work outside of GH without an auth token,
                // but they do work as links
                if ('tag' in node && node.tag === 'img') {
                  // Handle HTML img elements
                  // @ts-expect-error src is actually there
                  return <Link to={node.attrs.src}>Image</Link>;
                }
                if (node.type === RuleType.image) {
                  // Handle GH image link markdown ![title](url)
                  return (
                    // @ts-expect-error we want to inherit font size and use our link
                    <Link to={node.target} target="_blank" fontSize="inherit">
                      Image
                    </Link>
                  );
                }
                if (node.type === RuleType.link) {
                  return (
                    // @ts-expect-error we want to inherit font size and use our link
                    <Link to={node.target} target="_blank" fontSize="inherit">
                      {next()}
                    </Link>
                  );
                }
                return next();
              },
              overrides: {
                h1: {
                  component: withProps({ as: 'h1' } as const)(HS),
                },
                h2: {
                  component: withProps({ as: 'h2' } as const)(HXS),
                },
                h3: {
                  component: withProps({ as: 'h3' } as const)(HXXS),
                },
                h4: {
                  component: withProps({ fontWeight: 600, as: 'h4' } as const)(
                    PXL,
                  ),
                },
                h5: {
                  component: withProps({ fontWeight: 600, as: 'h5' } as const)(
                    PL,
                  ),
                },
                p: {
                  component: withProps({ fontWeight: 600, as: 'h6' } as const)(
                    PM,
                  ),
                },
              },
            }}
          >
            {preprocessMarkdown(__BUILD_NOTES__)}
          </Markdown>
          <Flex mt={32} justifyContent="flex-end">
            <Checkbox
              label="Don't auto-open modal on new builds of this PR"
              checked={hideFutureBuildNotes}
              onChange={() => setHideFutureBuildNotes((prev) => !prev)}
            />
          </Flex>
        </ModalContent>
      </Modal>
    </>
  ) : null;
};
