import { styled, Flex, PM, Box, theme } from '@m1/liquid-react';
import React from 'react';

import { AppLink, AppRichTextDisclosureLinkFragment } from '~/graphql/types';

import { COPYRIGHT_COPY } from '~/static-constants';
import { createRichTextParagraphs } from '~/utils/createRichTextParagraphs';

import { LinkableLink } from '../../lens-toolbox/LinkableLink';
import { DisclosureLink } from '../DisclosureLink/DisclosureLink';

export const footerHeight = 72;

const FooterContainer = styled(Flex)<{ breakpointOverride?: string }>`
  width: 100%;

  #desktopContent {
    display: none;
  }

  ${({ breakpointOverride }) => `
    @media screen and (min-width: ${breakpointOverride ? theme.breakpoints[breakpointOverride as keyof typeof theme.breakpoints] : theme.breakpoints.SMALL}) {
      #mobileContent {
        display: none;
      }

      #desktopContent {
        display: inherit;
        bottom: 0;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
      }
    }
`}
`;

const DesktopContent = styled(Flex)`
  height: ${footerHeight}px;
  border-top: 1px solid;
  border-top-color: ${({ theme }) => theme.colors.borderMain};
  background: ${({ theme }) => theme.colors.backgroundNeutralSecondary};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 80px;
`;

const MobileContent = styled(Flex)`
  align-items: center;
  width: 100%;
`;

const StyledLinkableLink = styled(LinkableLink)`
  padding: 12px 8px;
  white-space: nowrap;
  color: ${theme.colors.foregroundPrimary};

  font-weight: 400;
  text-decoration: underline;
  width: 100%;
`;

/**
 *
 * @param disclosureLinks is an array of AppLinks to be rendered instead of the footerLink if present
 * @param footerLink is an AppLink that is present in each instance of <Footer/>, except ORC
 */
export const Footer = ({
  disclosureLinks,
  footerDisclosures,
  footerLink,
  showMobileFooter,
  breakpointOverride,
}: {
  disclosureLinks?: Array<Maybe<AppLink>> | null;
  footerDisclosures?: Array<Maybe<AppRichTextDisclosureLinkFragment>> | null;
  footerLink?: AppLink | null;
  showMobileFooter?: boolean;
  breakpointOverride?: string;
}) => {
  const hasDisclosureLinks = disclosureLinks && disclosureLinks.length > 0;

  return (
    <FooterContainer breakpointOverride={breakpointOverride}>
      <DesktopContent id="desktopContent">
        <Flex alignItems="baseline">
          {hasDisclosureLinks &&
            disclosureLinks.map((link) => {
              return (
                link && (
                  <Box key={link.url} mr={32}>
                    <StyledLinkableLink linkable={link} font="PL" />
                  </Box>
                )
              );
            })}
          {!hasDisclosureLinks && footerLink && (
            <StyledLinkableLink linkable={footerLink} font="PL" />
          )}
          {footerDisclosures?.map((disclosure, index) => (
            <Box key={index} mr={32}>
              {disclosure && (
                <DisclosureLink
                  linkText={disclosure?.linkText}
                  paragraphs={createRichTextParagraphs(disclosure.paragraphs)}
                  mr={32}
                />
              )}
            </Box>
          ))}
        </Flex>
        <PM
          fontWeight={600}
          color="foregroundNeutralSecondary"
          content={COPYRIGHT_COPY}
        />
      </DesktopContent>
      <MobileContent
        id="mobileContent"
        alignContent="center"
        flexDirection="column"
        p={8}
        pb={24}
      >
        {footerDisclosures?.map((disclosure, index) => (
          <Flex key={index} p={8} mb={8} flexDirection="column">
            {footerLink && (
              <StyledLinkableLink linkable={footerLink} font="PL" />
            )}
            {disclosure && (
              <Box padding={8}>
                <DisclosureLink
                  linkText={disclosure?.linkText}
                  paragraphs={createRichTextParagraphs(disclosure.paragraphs)}
                />
              </Box>
            )}
          </Flex>
        ))}

        {showMobileFooter && (
          <PM
            fontWeight={600}
            color="foregroundNeutralSecondary"
            content={COPYRIGHT_COPY}
          />
        )}
      </MobileContent>
    </FooterContainer>
  );
};
