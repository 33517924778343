import { styled, Box, BoxProps } from '@m1/liquid-react';
import * as React from 'react';

export type StickyProps = {
  children?: React.ReactNode;
  stickyPosition?: 'top' | 'bottom';
  stickyOffset?: number;
  stickyOff?: boolean;
} & BoxProps;

const StickyDiv = styled(Box)<{
  $isShorterThanWindow: boolean;
  $stickyPosition: string;
  $stickyOffset: number;
}>`
  position: sticky;

  ${({ $stickyPosition, $stickyOffset }) =>
    $stickyPosition === 'top'
      ? `top: ${$stickyOffset}px;`
      : `bottom: ${$stickyOffset}px;`}
`;

export const Sticky = ({
  stickyPosition = 'top',
  stickyOffset = 0,
  children,
  stickyOff,
  ...rest
}: StickyProps) => {
  const stickyRef = React.useRef<HTMLDivElement>(null);

  if (stickyOff) {
    return children;
  }

  return (
    <StickyDiv
      data-testid="sticky"
      $stickyPosition={stickyPosition}
      $stickyOffset={stickyOffset}
      ref={stickyRef}
      {...rest}
    >
      {children}
    </StickyDiv>
  );
};
