import { Box, LL } from '@m1/liquid-react';
import debounce from 'lodash-es/debounce';
import * as React from 'react';

import { useInvestAcatWizardContraSearchFormContainerRefetchQuery } from '~/graphql/hooks';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { SearchInput } from '~/toolbox/search-input';

import { useAcatWizardFormContext } from '../hooks/useAcatWizardFormContext';

export const InvestAcatWizardContraSearchFormContainerComponent = () => {
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const { setValue, next } = useAcatWizardFormContext();

  const { data, refetch } =
    useInvestAcatWizardContraSearchFormContainerRefetchQuery({
      variables: {
        searchTerm: '',
      },
    });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = event.currentTarget.value;
    setSearchTerm(userInput);
    debounce(
      () =>
        refetch({
          searchTerm: userInput,
        }),
      200,
    )();
  };

  const searchForm =
    data?.viewer.invest?.acatWizard?.brokerageSelectionContent?.searchForm;

  const contras = searchForm?.contraParticipantList?.edges;

  const content = `${searchForm?.contraUnavailableNotice?.content || 'Not seeing your brokerage?'} `;

  const suggestions = contras?.reduce((acc, contra) => {
    if (contra?.node?.name && contra.node.id) {
      return acc.concat({
        value: contra.node.id,
        text: contra.node.name,
      });
    }
    return acc;
  }, [] as any[]);

  const handleSuggestion = (suggestion: any) => {
    const brokerageId = suggestion.value;
    const matchingBrokerage = contras?.filter(
      (contra) => brokerageId === contra?.node?.id,
    );

    if (matchingBrokerage?.[0]?.node) {
      const { id } = matchingBrokerage[0].node;

      setValue('externalBrokerageId', id);
      next();
    }
  };

  return (
    <SearchInput
      value={searchTerm}
      fullWidth
      onChange={onChange}
      suggestions={suggestions}
      onSelectSuggestion={(suggestion) => handleSuggestion(suggestion)}
      emptyComponent={
        <Box>
          <LL mb={8} content={content} />
          {searchForm?.contraUnavailableNotice?.link && (
            <LinkableLink linkable={searchForm.contraUnavailableNotice.link} />
          )}
        </Box>
      }
      placeholder={searchForm?.placeholder || 'Search'}
    />
  );
};
