import * as React from 'react';
import { Route } from 'react-router-dom';

import { ManualReviewPage } from '~/pages/dashboard/borrow/personal/loan-application/ManualReviewPage';

import { ApplicationReceived } from '../components/ApplicationReceived';
import { ApplicationRejected } from '../components/ApplicationRejected';
import { DisbursementError } from '../components/DisbursementError';
import { NotEligiblePage } from '../components/NotEligible';

export const PersonalLoanApplicationErrorRoute = () => {
  return (
    <Route path="direct-loan-application-error">
      <Route path="application-received" element={<ApplicationReceived />} />
      <Route path="application-rejected" element={<ApplicationRejected />} />
      <Route path="not-eligible" element={<NotEligiblePage />} />
      <Route path="manual-review" element={<ManualReviewPage />} />
      <Route path="disbursement-error" element={<DisbursementError />} />
    </Route>
  );
};
