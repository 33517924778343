import { Button, HXXS, Modal, ModalContent, PL } from '@m1/liquid-react';
import * as React from 'react';

import { ExternalLink } from '~/components/ExternalLink';

export const IRAContributionLimitModal = () => {
  return (
    <Modal
      trigger={
        <Button
          kind="link"
          label="Learn about maximum IRA contributions"
          mt={32}
        />
      }
    >
      <ModalContent withPadding>
        <HXXS>
          The IRS limits how much you can contribute to IRAs each year.{' '}
        </HXXS>
        <PL p="16px 0 8px">
          ¹Commission-free trading of stocks and ETFs refers to $0 commissions
          charged by M1 Finance LLC for self-directed brokerage accounts. Other
          fees may apply such as regulatory, account closures and ADR fees. For
          complete list of fees, visit M1 Fee Schedule.
        </PL>
        <PL p="8px 0">
          ³If you choose to transfer your account to another broker-dealer, only
          the full shares are guaranteed to transfer. Fractional shares may need
          to be liquidated and transferred as cash.
        </PL>
        <ExternalLink
          destination="IRA_MINIMUM_BALANCES"
          css={{ padding: '16px 0 8px 0' }}
        >
          Learn more about minimum balances
        </ExternalLink>
      </ModalContent>
    </Modal>
  );
};
