import { Text, styled } from '@m1/liquid-react';

type StyledTextProps = {
  $disabled: boolean;
  $showLoading: boolean;
  $showSuccess: boolean;
};

export const StyledUpdateText = styled(Text)<StyledTextProps>`
  transform: translateX(0);
  transition: all 0.2s linear;

  ${(props) => {
    if (props.$showLoading) {
      return `transform: translateX(-14px);`;
    }
    if (props.$showSuccess) {
      return `transform: translateX(-8px);`;
    }
  }}
  ${(props) => {
    if (props.$disabled || props.$showLoading) {
      return `
        pointer-events: none;
        cursor: not-allowed;
      `;
    }
    return `
      cursor: pointer;
    `;
  }};
`;
