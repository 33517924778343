import { Flex } from '@m1/liquid-react';
import * as React from 'react';

export const GeneralErrorPage = () => {
  React.useEffect(() => {
    throw new Error('General Personal Loan Error.');
  }, []);

  return <Flex mt={72} width={700} />;
};
