import { Button, Flex } from '@m1/liquid-react';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';

import { useSelector } from '../../redux/hooks/useSelector';

type DepositActionButtonsProps = {
  onNextClick: () => void;
  onSkip?: () => void;
  useOnboardingLayout: boolean;
  maximumDepositAmount?: number;
  minimumDepositAmount?: number;
  showSkipCTA?: boolean;
};

export const DepositActionButtons = ({
  onNextClick,
  onSkip,
  useOnboardingLayout,
  maximumDepositAmount,
  minimumDepositAmount,
  showSkipCTA,
}: DepositActionButtonsProps) => {
  const smallBreakpoint = useMediaQuery({
    query: RESPONSIVE_BREAKPOINTS.SMALL,
  });

  const { fundAccountAmount } = useSelector<{
    fundAccountAmount: number;
  }>((state) => {
    return {
      fundAccountAmount:
        state.form['fund-account']?.values?.fundAccountAmount ?? 0,
    };
  });

  const isAboveMax =
    typeof maximumDepositAmount !== 'undefined'
      ? fundAccountAmount > maximumDepositAmount
      : false;
  const isBelowMin =
    typeof minimumDepositAmount !== 'undefined'
      ? fundAccountAmount < minimumDepositAmount
      : false;

  return (
    <Flex
      alignItems="center"
      flexDirection={useOnboardingLayout ? 'row' : 'column'}
      mx="auto"
      mt={useOnboardingLayout ? 48 : 24}
      flexWrap={smallBreakpoint ? 'wrap' : undefined}
    >
      <Button
        kind="primary"
        disabled={!fundAccountAmount || isAboveMax || isBelowMin}
        label={
          useOnboardingLayout ? 'Confirm deposit' : 'Next: Review and confirm'
        }
        onClick={onNextClick}
        size="large"
      />
      {showSkipCTA ? (
        <Button
          kind="text"
          onClick={onSkip}
          label={
            useOnboardingLayout
              ? "Skip, I'll do this later"
              : "I'll fund my account later"
          }
          size="large"
          ml={useOnboardingLayout ? 24 : 0}
          mt={useOnboardingLayout ? 0 : 16}
        />
      ) : null}
    </Flex>
  );
};
