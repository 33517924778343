import { Button, Text, Card } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { Notifications } from '~/components/notifications';
import { TransferParticipantCellRenderer } from '~/components/transfers/TransferParticipantCellRenderer';
import { connectForm } from '~/hocs';

import { TransferAmountField } from './fields';
import { required } from './validators';

type Props = {
  fromParticipantId: string;
  handleSubmit: (...args: Array<any>) => any;
  invalid: boolean;
  minimumInitialDeposit: number;
  onSubmit: (...args: Array<any>) => any;
  toParticipantId: string;
};

const CreateInitialDepositFormComponent = ({
  minimumInitialDeposit = 0,
  fromParticipantId,
  onSubmit,
  toParticipantId,
  invalid,
}: Props) => {
  const minimum = (value: string) =>
    Number(value) < minimumInitialDeposit
      ? `Minimum initial deposit is $${minimumInitialDeposit}.`
      : null;

  const handleSubmit = ({ amount, ...rest }: any) =>
    onSubmit({
      ...rest,
      amount: Number(amount),
    });
  return (
    <form onSubmit={handleSubmit(handleSubmit)}>
      <Notifications location="CREATE_INITIAL_TRANSFER" />
      <TransferAmountField
        autoFocus
        name="amount"
        showErrorBeforeSubmit
        style={{
          marginTop: 24,
        }}
        validate={[required, minimum]}
      />
      <Text as="h2" color="foregroundNeutralMain" content="Deposit steps" />
      <Card mt={8} p={16}>
        <TransferParticipantCellRenderer id={fromParticipantId} />
        <Icon
          name="queuePending32"
          style={{
            transform: 'rotate(0.25turn)',
          }}
        />
        <TransferParticipantCellRenderer id={toParticipantId} />
      </Card>
      <div
        style={{
          marginTop: 64,
          textAlign: 'center',
        }}
      >
        <Button
          disabled={invalid}
          kind="primary"
          label="Confirm Deposit"
          size="large"
          type="submit"
        />
      </div>
    </form>
  );
};

const enhancer = connectForm({
  form: 'initial-deposit',
});

export const CreateInitialDepositForm = enhancer(
  CreateInitialDepositFormComponent,
);
