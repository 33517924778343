import {
  Button,
  HXS,
  PL,
  Flex,
  Modal,
  ModalContent,
  ModalTitle,
} from '@m1/liquid-react';
import * as React from 'react';

type WithdrawModalProps = {
  onClick: () => void;
  onClose: () => void;
  open: boolean;
};

export const WithdrawModal = ({
  open,
  onClick,
  onClose,
}: WithdrawModalProps) => (
  <Modal open={open} onClose={onClose} onExit={onClose}>
    <ModalContent width="narrow">
      <Flex justifyContent="center" textAlign="center" flexDirection="column">
        <ModalTitle>
          <HXS mt={40}>Are you sure?</HXS>
        </ModalTitle>
      </Flex>
      <PL textAlign="center">
        If you decide to apply again later, you will have to complete all of
        these steps again.
      </PL>
      <Flex justifyContent="center">
        <Flex flexDirection="column" textAlign="center" mt={32}>
          <Button
            kind="primary"
            size="large"
            onClick={onClick}
            label="Yes, withdraw application"
          />
          <Button
            mt={8}
            size="large"
            kind="text"
            onClick={onClose}
            label="No, resume application"
          />
        </Flex>
      </Flex>
    </ModalContent>
  </Modal>
);
