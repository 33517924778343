import { beginConnectBankFlow } from '~/redux/actions';

import { makeFlowComponent } from '../utils';

import { ConnectBankFlowContainer } from './ConnectBankFlowContainer';

export const ConnectBankFlow = makeFlowComponent({
  name: 'connectBank',
  begin: beginConnectBankFlow,
  Component: ConnectBankFlowContainer,
});
