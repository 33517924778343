export const readQuestionQueryParam = ({
  searchParams,
}: {
  searchParams: URLSearchParams;
}): number | null | undefined => {
  const q = searchParams.get('q');
  if (
    q &&
    // eslint-disable-next-line
    Number(q) === Number(q)
  ) {
    return Number(q);
  }
  return null;
};
