import { Box, Button, Flex, HXS, PL, styled } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { AppContext } from '~/AppContext';
import { Container } from '~/toolbox/container';

import { StyledApplicationBottomCardContainer } from '../components/ApplicationCard';
import { StyledApplicationContainer } from '../components/ApplicationContainer';
import { AspectRatioFrame } from '../components/AspectRatioFrame';
import { SaveAndCloseButton } from '../components/SaveAndCloseButton';
import { PersonalLoansWithdrawModal } from '../components/WithdrawModal';

type PlaidIncomeVerificationProps = {
  onFinishStep: (arg0: { withdrawingApplication?: boolean }) => void;
};

const StyledContainer = styled(Container)`
  width: 100%;
`;

const StyledContentContainer = styled(Container)`
  min-height: 800px;
  max-width: 600px;
`;

export const PlaidIncomeVerification = ({
  onFinishStep,
}: PlaidIncomeVerificationProps) => {
  const { analytics } = React.useContext(AppContext);
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);

  return (
    <StyledContainer>
      <StyledContentContainer>
        <StyledApplicationContainer>
          <Flex justifyContent="center" flexDirection="column">
            <Box width={486}>
              <AspectRatioFrame ratio="21:9">
                <Illustration
                  // @ts-expect-error - TS2322 - Type '{ alt: string; name: "bankConnectionBank"; width: number; height: number; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'.
                  alt="bank connection image"
                  name="bankConnectionBank"
                />
              </AspectRatioFrame>
            </Box>
          </Flex>
          <Flex alignItems="center" justifyContent="center">
            <Flex flexDirection="column">
              <Container marginTop={24}>
                <HXS content="We need to verify your income." mb={8} />
                <PL content="Please use Plaid to log in to the bank where you deposit your income. This won’t impact your bank connection(s) between M1 and Plaid." />
                <br />
                <PL content="Once you start this quick process, you’ll need to finish it to proceed with your loan application." />
                <br />
                <PL mb={24}>
                  {`Offers are good for 30 days, or you can `}
                  <Button
                    kind="link"
                    label="withdraw"
                    onClick={() => {
                      analytics.recordEvent(
                        'm1_personal_loan_income_withdraw_clicked',
                      );
                      setModalIsOpen(true);
                    }}
                    style={{
                      textDecoration: 'underline',
                      fontWeight: 400,
                    }}
                  />
                  {` your application now.`}
                </PL>
                <StyledApplicationBottomCardContainer
                  style={{ marginLeft: 0 }}
                />
                <Flex>
                  <Button
                    kind="primary"
                    size="large"
                    label="Verify using Plaid"
                    type="submit"
                    onClick={() => {
                      analytics.recordEvent('m1_personal_loan_income_started');
                      onFinishStep({});
                    }}
                  />
                  <SaveAndCloseButton />
                </Flex>
              </Container>
            </Flex>
          </Flex>
        </StyledApplicationContainer>
        <PersonalLoansWithdrawModal
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          onFinish={() =>
            onFinishStep({
              withdrawingApplication: true,
            })
          }
        />
      </StyledContentContainer>
    </StyledContainer>
  );
};
