import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { PlaidRedirectPage } from '~/pages/plaid-redirect/PlaidRedirectPage';

import { RequireAccountOrProfileGuard } from '~/router/Authentication/RequireAccountOrProfileGuard';
import { RequireAuthGuard } from '~/router/Authentication/RequireAuthGuard';

import { BankConnectionRoute } from './bank-connection';
import { BorrowRoute } from './borrow';
import { ContactUsRoute } from './contact-us';
import { CoversRoute } from './covers';
import { CryptoRoute } from './crypto';
import { DashboardLayout } from './DashboardLayout';
import { DocumentUploadRoute } from './document-upload';
import { HomeRoute } from './home';
import { InvestRoute } from './invest';
import { MoveMoneyRoute } from './move-money';
import { OpenInvestAccountRoute } from './open-invest-account';
import { OpenInvestJointAccountRoute } from './open-invest-joint-account';
import { PromotionCampaignAcceptance } from './promotions';
import { ReferralsRoute } from './referrals';
import { ResearchRoute } from './research';
import { SaveRoute } from './save';
import { SavingsOnboardingInitialFunding } from './savings-onboarding-initial-funding';
import { SettingsRoute } from './settings';
import { SpendRoute } from './spend';
import { TransfersRoute } from './transfers';
import { WaitlistRoute } from './waitlist';
import { WizardsRoute } from './wizards';

export const DashboardRoutes = () => {
  return (
    <Route
      path="d"
      element={
        <RequireAuthGuard>
          <RequireAccountOrProfileGuard>
            <DashboardLayout />
          </RequireAccountOrProfileGuard>
        </RequireAuthGuard>
      }
    >
      <Route index element={<Navigate to="/d/invest" replace />} />
      {BorrowRoute()}
      {CoversRoute()}
      {ContactUsRoute()}
      {CryptoRoute()}
      {DocumentUploadRoute()}
      {OpenInvestAccountRoute()}
      {OpenInvestJointAccountRoute()}
      {HomeRoute()}
      {InvestRoute()}
      {BankConnectionRoute()}
      {MoveMoneyRoute()}
      {PromotionCampaignAcceptance()}
      {ReferralsRoute()}
      {ResearchRoute()}
      {SavingsOnboardingInitialFunding()}
      {SettingsRoute()}
      {SaveRoute()}
      {SpendRoute()}
      {TransfersRoute()}
      {WaitlistRoute()}
      {WizardsRoute()}
      <Route path="portfolio" element={<Navigate to="/d/invest" replace />} />
      <Route
        path="wire-transfer"
        element={<Navigate to="/d/c/wire-transfer" replace />}
      />
      <Route
        path="funding"
        element={<Navigate to="/d/invest/funding" replace />}
      />
      <Route
        path="funding/*"
        element={<Navigate to="/d/invest/funding" replace />}
      />
      <Route path="plaid-redirect" element={<PlaidRedirectPage />} />
    </Route>
  );
};
