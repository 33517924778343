import { Button, PM, Box, HS, Modal, ModalContent } from '@m1/liquid-react';
import * as React from 'react';

import { ButtonGroup } from '~/toolbox/ButtonGroup';

export const EditorExitConfirmationModal = ({
  onCancel,
  onConfirm,
  open,
}: {
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
}) => {
  return (
    <Modal onClose={onCancel} open={open}>
      <ModalContent>
        <Box maxWidth={380} mx="auto" pt={16} textAlign="center">
          <HS content="Are you sure?" />
          <PM
            content="Changes to your Pie will not be saved on exit."
            mt={16}
            textAlign="left"
          />
        </Box>
        <ButtonGroup behavior="centered" mt={128}>
          <Button kind="secondary" label="Cancel" onClick={onCancel} />
          <Button kind="primary" label="Confirm" onClick={onConfirm} />
        </ButtonGroup>
      </ModalContent>
    </Modal>
  );
};
