import * as React from 'react';

import { makeFlowComponent } from '~/flows/components/utils';
import { beginRegisterUserFlow } from '~/redux/actions';
import { REGISTER_USER_FLOW_STEPS as STEPS } from '~/static-constants';

import { DirectToPersonalLoansSignUpForm } from '../components/DirectToPersonalLoansSignUpForm';

export const RegisterUserPersonalLoansDirectFlow = makeFlowComponent({
  name: 'registerUser',
  begin: beginRegisterUserFlow,
  stepElements: {
    [STEPS.COLLECT_USER_DETAILS]: <DirectToPersonalLoansSignUpForm />,
  },
});
