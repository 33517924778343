import { PXL, PM, Flex, Tooltip } from '@m1/liquid-react';
import * as React from 'react';

import { RadioGroupField } from '~/forms/fields';
import {
  TransferAmountPresetOption,
  ScheduledTransferPresetOption,
} from '~/graphql/types';
import { Radio } from '~/toolbox/radio';
import { formatCurrencyWithCommas } from '~/utils/formatting';
type PaymentPresetsProps = {
  amountField: React.ReactNode;
  isOtherDisabled: boolean;
  isSchedule: boolean;
  onSelect: (value: any) => void;
  paymentOtherValue: string;
  presets:
    | Array<TransferAmountPresetOption>
    | Array<ScheduledTransferPresetOption>;
};

export const PaymentPresets = ({
  amountField,
  paymentOtherValue,
  onSelect,
  presets,
  isOtherDisabled,
  isSchedule,
}: PaymentPresetsProps) => {
  return (
    <RadioGroupField
      style={{
        padding: '16px 0 64px 16px',
      }}
      name="paymentAmount"
      size="small"
      onChange={onSelect}
    >
      {presets.map((preset) => {
        // @ts-expect-error - TS2345 - Argument of type 'string | number | null | undefined' is not assignable to parameter of type 'string'.
        const disabled = parseFloat(preset.value) <= 0;
        const paymentValue =
          typeof preset.value === 'number' ? preset.value.toFixed(2) : '0.00';
        const secondaryContent = isSchedule
          ? preset.value
          : `$${formatCurrencyWithCommas(paymentValue)}`;
        return (
          <Radio.Choice
            key={preset.label}
            label={
              <>
                <PXL
                  fontWeight={600}
                  display="inline-flex"
                  alignItems="center"
                  content={
                    <>
                      {preset.label}{' '}
                      {preset.description && (
                        <Tooltip
                          placement="bottom-start"
                          body={<PM content={preset.description} />}
                          icon="tooltip16"
                          iconColor="foregroundPrimary"
                        />
                      )}
                    </>
                  }
                  color={
                    disabled
                      ? 'foregroundNeutralSecondary'
                      : 'foregroundNeutralMain'
                  }
                />
                <PM
                  color={
                    disabled
                      ? 'foregroundNeutralSecondary'
                      : 'foregroundNeutralMain'
                  }
                  content={secondaryContent}
                />
              </>
            }
            disabled={disabled} // Create a unique value in case multiple options of same value for one-time payments
            // Scheduled CC payments just use the indicator as the value
            value={
              (preset.__typename === 'ScheduledTransferPresetOption' &&
                preset.indicator) ||
              `${String(preset.value)}-${String(preset.label)}`
            }
          />
        );
      })}
      <Radio.Choice
        key={paymentOtherValue}
        disabled={isOtherDisabled}
        label={
          <Flex inline alignItems="center" m="-16px 0 -8px 0" height={70}>
            <PXL
              fontWeight={600}
              content={isSchedule ? 'Fixed amount' : 'Other amount'}
              color={
                isOtherDisabled
                  ? 'foregroundNeutralSecondary'
                  : 'foregroundNeutralMain'
              }
              minWidth={130}
              mt={8}
            />
            <Flex height={60}>{amountField}</Flex>
          </Flex>
        }
        value={paymentOtherValue}
      />
    </RadioGroupField>
  );
};
