export const MARGIN_CALL_INFORMATION_ACTIONS = Object.freeze({
  COMPLETE_DISABLE_AUTO_INVEST_MUTATION:
    'COMPLETE_DISABLE_AUTO_INVEST_MUTATION' as const,
  SET_MARGIN_CALL_INFORMATION_PREVIOUS_ROUTE:
    'SET_MARGIN_CALL_INFORMATION_PREVIOUS_ROUTE' as const,
  SUBMIT_DISABLE_AUTO_INVEST_MUTATION: 'DISABLE_AUTO_INVEST' as const,
  UPDATE_ACTIVE_POSITION_ID: 'UPDATE_ACTIVE_POSITION_ID' as const,
  RESET_MARGIN_CALL_INFORMATION_STATE:
    'RESET_MARGIN_CALL_INFORMATION_STATE' as const,
});

export type CompleteDisableAutoInvestMutationAction = {
  payload: unknown;
  type: typeof MARGIN_CALL_INFORMATION_ACTIONS.COMPLETE_DISABLE_AUTO_INVEST_MUTATION;
};

export const completeDisableAutoInvestMutation = (
  payload: CompleteDisableAutoInvestMutationAction['payload'],
): CompleteDisableAutoInvestMutationAction => ({
  payload,
  type: MARGIN_CALL_INFORMATION_ACTIONS.COMPLETE_DISABLE_AUTO_INVEST_MUTATION,
});

export type SetMarginCallInformationPreviousRouteAction = {
  payload: unknown;
  type: typeof MARGIN_CALL_INFORMATION_ACTIONS.SET_MARGIN_CALL_INFORMATION_PREVIOUS_ROUTE;
};

export const setMarginCallInformationPreviousRoute = (
  payload: SetMarginCallInformationPreviousRouteAction['payload'],
): SetMarginCallInformationPreviousRouteAction => ({
  payload,
  type: MARGIN_CALL_INFORMATION_ACTIONS.SET_MARGIN_CALL_INFORMATION_PREVIOUS_ROUTE,
});

export type SubmitDisableAutoInvestMutationAction = {
  payload: unknown;
  type: typeof MARGIN_CALL_INFORMATION_ACTIONS.SUBMIT_DISABLE_AUTO_INVEST_MUTATION;
};

export const submitDisableAutoInvestMutation = (
  payload: SubmitDisableAutoInvestMutationAction['payload'],
): SubmitDisableAutoInvestMutationAction => ({
  payload,
  type: MARGIN_CALL_INFORMATION_ACTIONS.SUBMIT_DISABLE_AUTO_INVEST_MUTATION,
});

export type UpdateActivePositionIdAction = {
  payload: unknown;
  type: typeof MARGIN_CALL_INFORMATION_ACTIONS.UPDATE_ACTIVE_POSITION_ID;
};

export const updateActivePositionId = (
  payload: UpdateActivePositionIdAction['payload'],
): UpdateActivePositionIdAction => ({
  payload,
  type: MARGIN_CALL_INFORMATION_ACTIONS.UPDATE_ACTIVE_POSITION_ID,
});

export type ResetMarginCallInformationStateAction = {
  payload: unknown;
  type: typeof MARGIN_CALL_INFORMATION_ACTIONS.RESET_MARGIN_CALL_INFORMATION_STATE;
};
export const resetMarginCallInformationState = (
  payload: ResetMarginCallInformationStateAction['payload'],
): ResetMarginCallInformationStateAction => ({
  payload,
  type: MARGIN_CALL_INFORMATION_ACTIONS.RESET_MARGIN_CALL_INFORMATION_STATE,
});

export type MarginCallInformationAction =
  | CompleteDisableAutoInvestMutationAction
  | SetMarginCallInformationPreviousRouteAction
  | SubmitDisableAutoInvestMutationAction
  | UpdateActivePositionIdAction
  | ResetMarginCallInformationStateAction;
