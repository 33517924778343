import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';
import { components } from 'react-select';

import { SelectProps } from './Dropdown.types';

type DropdownIndicatorProps = {
  className: string;
  selectProps: SelectProps;
};

export const DropdownIndicator = ({ ...rest }: DropdownIndicatorProps) => (
  // @ts-expect-error - TS2786 - 'components.DropdownIndicator' cannot be used as a JSX component.
  <components.DropdownIndicator {...rest}>
    <Icon name="caretDown20" />
  </components.DropdownIndicator>
);
