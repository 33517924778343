import { Box, HM } from '@m1/liquid-react';
import * as React from 'react';

import { useIdentityFirstFinancialSuitabilityQuestionsQuery } from '~/graphql/hooks';
import { OnboardingIdentityFirstQuestionType } from '~/graphql/types';
import { useChangeStep } from '~/hooks/useChangeStep';
import { useLocation } from '~/hooks/useLocation';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { FINANCIAL_SUITABILITY_QUESTION_MAP } from '../consts';
import { AvailableCashForm } from '../forms/AvailableCashForm';
import { useUpdateProfileHook } from '../hooks/updateProfileHook';
import { useFinancialSuitabilityWizardContext } from '../hooks/useFinancialSuitabilityWizardContext';
import { FinancialSuitabilityFormFields } from '../hooks/useFinancialSuitabilityWizardForm';
import { FinancialSuitabilityWizardSteps } from '../types';

export const CollectAvailableCashWizardStep = () => {
  const product = useSelector(
    (state) => state.navigation.financialSuitabilityProduct,
  );
  const { goTo } = useFinancialSuitabilityWizardContext();
  const location = useLocation();
  const handleChangeRouteStep = useChangeStep({
    basePath: 'onboarding/financial-details',
  });

  const { data: questionsData, loading } =
    useIdentityFirstFinancialSuitabilityQuestionsQuery();
  const questions =
    questionsData?.viewer?.user?.onboardingIdentityFirst
      ?.financialSuitabilitySection?.questions;
  const availableCashQuestion = questions?.find(
    (q) =>
      q?.questionType === OnboardingIdentityFirstQuestionType.LiquidNetWorth,
  );
  const nextQuestion = availableCashQuestion?.nextQuestionType;

  const { updateProfileMutation, updateProfileLoading } =
    useUpdateProfileHook();
  usePortaledSpinner(updateProfileLoading);

  const onSubmit = (data: FinancialSuitabilityFormFields) => {
    updateProfileMutation(data, product);
  };

  const handleNext = () => {
    const wizardStep = FINANCIAL_SUITABILITY_QUESTION_MAP[nextQuestion!];
    handleChangeRouteStep({
      step: wizardStep as FinancialSuitabilityWizardSteps,
    });
    goTo(wizardStep);
  };

  React.useEffect(() => {
    if (location?.pathname?.includes('disclosures')) {
      handleChangeRouteStep({
        step: 'disclosures' as FinancialSuitabilityWizardSteps,
      });
      goTo('disclosures');
    }
    if (location?.pathname?.includes('employment')) {
      handleChangeRouteStep({
        step: 'employment' as FinancialSuitabilityWizardSteps,
      });
      goTo('employment');
    }
    if (location?.pathname?.includes('net-worth')) {
      handleChangeRouteStep({
        step: 'net-worth' as FinancialSuitabilityWizardSteps,
      });
      goTo('net-worth');
    }
  }, [location?.pathname, handleChangeRouteStep, goTo]);

  if (loading) {
    return <Spinner fullScreen />;
  }

  return (
    <Box width={588}>
      <HM
        pt={64}
        mb={64}
        content={
          availableCashQuestion?.title ??
          'Approximately how much do you have in cash and investments?'
        }
      />
      <AvailableCashForm
        nextQuestion={nextQuestion}
        onSubmit={onSubmit}
        handleNext={handleNext}
      />
    </Box>
  );
};
