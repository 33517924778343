import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';

import { useSavingsConnectBankQuery } from '~/graphql/hooks';
import { AppInfoPage } from '~/lens-toolbox/AppInfoPage';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

type SavingsConnectBankProps = {
  onFinishStep: (arg?: 'skip') => void;
};

export const SavingsConnectBank = ({
  onFinishStep,
}: SavingsConnectBankProps) => {
  const accountId = useSelector(
    (state) =>
      state.newFlows.SAVINGS_ONBOARDING.accountId ??
      state.global.savingsAccountId,
  );
  const { data, loading, error } = useSavingsConnectBankQuery({
    variables: {
      accountId,
    },
  });
  if (loading) {
    return <Spinner fullScreen />;
  }

  const { connectBankPage } = data?.viewer.save?.savings?.onboarding ?? {};
  if (!connectBankPage || error) {
    return <GenericSystemError />;
  }

  return (
    <AppInfoPage
      appInfoPage={connectBankPage}
      onPrimaryButtonClick={onFinishStep}
      onSkipClick={() => onFinishStep('skip')}
    />
  );
};
