import { Button } from '@m1/liquid-react';
import * as React from 'react';

import { SliceableNameCell } from '~/components/pie';
import { AccountOrdersTableFragment } from '~/graphql/types';
import { clickedCancelOrder } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { GridTable, GridTableProps } from '~/toolbox/grid-table';
import { formatNumber } from '~/utils';

export type AccountOrdersTableProps = ExpectedProps;

type ExpectedProps = Omit<
  GridTableProps<AccountOrdersTableFragment>,
  'gridTemplateColumns'
> & {
  account: AccountOrdersTableFragment;
  direction: 'buy' | 'sell';
  label?: string;
};

export const AccountOrdersTable = ({
  account,
  direction,
  emptyMessage = 'You have no pending orders',
  label = 'Pending buy and sell orders only',
  ...rest
}: AccountOrdersTableProps) => {
  const dispatch = useDispatch();
  const readPortfolioSlicesWithOrders = () => {
    switch (direction) {
      case 'buy':
        return account.portfolioSlicesWithBuyOrders;
      case 'sell':
        return account.portfolioSlicesWithSellOrders;
      default:
        return [];
    }
  };

  const portfolioSlices = readPortfolioSlicesWithOrders();

  return (
    <GridTable
      {...rest}
      emptyMessage={emptyMessage}
      gridTemplateColumns="360px auto 70px"
    >
      <GridTable.HeaderRow>
        <GridTable.HeaderCell label={label} />
        <GridTable.HeaderCell label="" />
        <GridTable.HeaderCell label="" />
      </GridTable.HeaderRow>
      {portfolioSlices &&
        portfolioSlices.map((portfolioSlice) => {
          if (!portfolioSlice?.orderStatus?.order) {
            return null;
          }

          return (
            <GridTable.Row key={portfolioSlice.id} __navigable={false}>
              <GridTable.Cell
                content={<SliceableNameCell sliceable={portfolioSlice.to} />}
              />
              <GridTable.Cell
                content={
                  portfolioSlice.orderStatus && portfolioSlice.orderStatus.order
                    ? formatNumber(
                        Math.abs(portfolioSlice.orderStatus.order.cashFlow),
                        '$0,0[.]00',
                      )
                    : '--'
                }
                textAlign="right"
              />
              <GridTable.Cell textAlign="right">
                <Button
                  kind="link"
                  label="Cancel"
                  style={{ fontSize: '16px' }}
                  onClick={() =>
                    dispatch(clickedCancelOrder(portfolioSlice.id))
                  }
                />
              </GridTable.Cell>
            </GridTable.Row>
          );
        })}
    </GridTable>
  );
};
