import React from 'react';
import { useSelector } from 'react-redux'; // using the one from ~/reducers breaks the app for some reason

import type { AppState } from '~/redux';
import { useDispatch } from '~/redux/hooks';

import { TEST_ENVIRONMENT_KEY } from '~/static-constants';

const isProduction = () =>
  __NODE_ENV__ === 'production' &&
  (__ENV__ === 'production' || __ENV__ === 'beta');

export const getEnvironment = (
  initialValue?: EnvironmentOption | null | undefined,
): EnvironmentOption => {
  const defaultEnv = {
    label: __ENV__,
    value: window.config?.graphql?.endpoint,
  };

  try {
    if (isProduction()) {
      return defaultEnv;
    }

    // Get from local storage by key
    const item = window.localStorage.getItem(TEST_ENVIRONMENT_KEY);
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : (initialValue ?? defaultEnv);
  } catch (e: any) {
    // If error also return initialValue
    // non-production code, allow console log.
    // eslint-disable-next-line no-console
    console.log(e);
    return initialValue ?? defaultEnv;
  }
};

export const useEnvironment = (): [
  EnvironmentOption,
  (value: EnvironmentOption) => void,
] => {
  const dispatch = useDispatch();
  const isProduction =
    __NODE_ENV__ === 'production' &&
    (__ENV__ === 'production' || __ENV__ === 'beta');

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const environment =
    useSelector((state: AppState) => state.global.environment) ??
    getEnvironment();

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = React.useCallback(
    (value: EnvironmentOption) => {
      try {
        if (isProduction) {
          return;
        }

        window.config.graphql.endpoint = value.value;

        // Save to local storage
        window.localStorage.setItem(
          TEST_ENVIRONMENT_KEY,
          JSON.stringify(value),
        );
        dispatch({
          type: 'SET_ENVIRONMENT',
          payload: value,
        });
      } catch (e: any) {
        // A more advanced implementation would handle the error case
        // non-production code, allow console log.
        // eslint-disable-next-line no-console
        console.log(e);
      }
    },
    [dispatch, isProduction],
  );
  return [environment, setValue];
};
