import {
  MonthlyScheduleDateEnum,
  WeekOfMonthScheduleWeekEnum,
  WeeklyScheduleDayEnum,
} from '~/graphql/types';

import { EnumDropdown } from './types';

export const WeekOfMonthScheduleWeekEnumDropdownOptions: EnumDropdown<WeekOfMonthScheduleWeekEnum> =
  [
    {
      'name': WeekOfMonthScheduleWeekEnum.Week_1,
      'description': 'First',
    },
    {
      'name': WeekOfMonthScheduleWeekEnum.Week_2,
      'description': 'Second',
    },
    {
      'name': WeekOfMonthScheduleWeekEnum.Week_3,
      'description': 'Third',
    },
    {
      'name': WeekOfMonthScheduleWeekEnum.Week_4,
      'description': 'Fourth',
    },
    {
      'name': WeekOfMonthScheduleWeekEnum.Week_5,
      'description': 'Fifth',
    },
  ];
export const WeeklyScheduleDayEnumDropdownOptions: EnumDropdown<WeeklyScheduleDayEnum> =
  [
    {
      'name': WeeklyScheduleDayEnum.Sunday,
      'description': 'Sunday',
    },
    {
      'name': WeeklyScheduleDayEnum.Monday,
      'description': 'Monday',
    },
    {
      'name': WeeklyScheduleDayEnum.Tuesday,
      'description': 'Tuesday',
    },
    {
      'name': WeeklyScheduleDayEnum.Wednesday,
      'description': 'Wednesday',
    },
    {
      'name': WeeklyScheduleDayEnum.Thursday,
      'description': 'Thursday',
    },
    {
      'name': WeeklyScheduleDayEnum.Friday,
      'description': 'Friday',
    },
    {
      'name': WeeklyScheduleDayEnum.Saturday,
      'description': 'Saturday',
    },
  ];

export const MonthlyScheduleDateEnumDropdownOptions: EnumDropdown<MonthlyScheduleDateEnum> =
  [
    {
      'name': MonthlyScheduleDateEnum.Day_1,
      'description': '1st',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_2,
      'description': '2nd',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_3,
      'description': '3rd',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_4,
      'description': '4th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_5,
      'description': '5th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_6,
      'description': '6th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_7,
      'description': '7th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_8,
      'description': '8th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_9,
      'description': '9th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_10,
      'description': '10th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_11,
      'description': '11th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_12,
      'description': '12th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_13,
      'description': '13th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_14,
      'description': '14th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_15,
      'description': '15th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_16,
      'description': '16th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_17,
      'description': '17th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_18,
      'description': '18th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_19,
      'description': '19th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_20,
      'description': '20th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_21,
      'description': '21st',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_22,
      'description': '22nd',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_23,
      'description': '23rd',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_24,
      'description': '24th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_25,
      'description': '25th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_26,
      'description': '26th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_27,
      'description': '27th',
    },
    {
      'name': MonthlyScheduleDateEnum.Day_28,
      'description': '28th',
    },
    {
      'name': MonthlyScheduleDateEnum.Last,
      'description': '(Last day of the month)',
    },
  ];
