import { styled } from '@m1/liquid-react';

export const StyledTransferRadioContainer = styled.label`
  align-items: center;
  display: inline-flex;
  justify-content: center;
`;

export const StyledTransferRadio = styled.input`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
`;
