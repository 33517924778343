type WatchlistUserData = ReadonlyArray<string> | null | undefined;

type OtherUserData = ReadonlyArray<{
  key: string;
  value: string | null | undefined;
}>;

export type ParsedUserData = {
  others: OtherUserData;
  watchlist: WatchlistUserData;
};

export const parseUserData = (
  userData: ReadonlyArray<{
    key: string;
    value: string | null | undefined;
  }>,
): ParsedUserData => {
  let watchlist: ReadonlyArray<string> | null | undefined = null;
  const others: Array<{
    key: string;
    value: string | null | undefined;
  }> = [];

  for (const datum of userData) {
    // A user's watchlist is kept within User.data. Since it's
    // handled differently, need to break it out prior to taking other
    // actions on the rest of user data.
    if (datum.key === 'watchlist') {
      if (datum.value) {
        try {
          const parsed = JSON.parse(datum.value);

          // While we can't verify here that these are indeed Sliceable ID's,
          // we can do a quick check to see if they match expected return type.
          if (
            Array.isArray(parsed) &&
            parsed.every((p) => typeof p === 'string')
          ) {
            watchlist = parsed;
          }
        } catch (e: any) {
          // Do nothing.
        }
      }
    } else {
      others.push({
        key: datum.key.replace(/^web-/, ''),
        value: datum.value,
      });
    }
  }

  return {
    others,
    watchlist,
  };
};
