import { Box, Button, Flex, HM, PL } from '@m1/liquid-react';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { BackArrow } from '~/components/BackArrow';
import { ControlledCheckbox } from '~/components/form/ControlledCheckbox';
import { ControlledInput } from '~/components/form/ControlledInput';
import { useOpenInvestJointAccountWizardContext } from '~/flows/components/OpenInvestJointAccountWizard/hooks/useOpenInvestJointAccountWizardContext';
import {
  ControlPersonDisclosure,
  ExchangeOrFinraAffiliationDisclosure,
  PoliticalExposureDisclosure,
} from '~/graphql/types';
import { submitReactFormData } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { Checkbox } from '~/toolbox/checkbox';

export interface DisclosuresFormValues {
  backupWithholding:
    | {
        isSubjectToBackupWithholding: boolean;
      }
    | null
    | undefined;
  exchangeOrFinraAffiliationDisclosure:
    | ExchangeOrFinraAffiliationDisclosure
    | null
    | undefined;
  controlPersonDisclosure: ControlPersonDisclosure | null | undefined;
  politicalExposureDisclosure: PoliticalExposureDisclosure | null | undefined;
}

export const Disclosures = () => {
  const dispatch = useDispatch();

  const submittedFormValues = useSelector((state) => {
    if (
      Boolean(state.reactHookForm.openJointInvestAccountDisclosures) === false
    ) {
      return {
        backupWithholding: {
          isSubjectToBackupWithholding: false,
        },
        exchangeOrFinraAffiliationDisclosure: {
          isAffiliated: false,
          firmName: undefined,
        },
        controlPersonDisclosure: {
          isControlPerson: false,
          companySymbols: undefined,
        },
        politicalExposureDisclosure: {
          immediateFamilyMembers: undefined,
          isPoliticallyExposed: false,
          politicalOrganization: undefined,
        },
      };
    }

    return state.reactHookForm.openJointInvestAccountDisclosures;
  });

  const { control, handleSubmit, watch, setValue } =
    useForm<DisclosuresFormValues>({
      defaultValues: submittedFormValues,
    });

  const [noDisclosures, setNoDisclosures] = React.useState(true);

  const values = watch();

  const {
    exchangeOrFinraAffiliationDisclosure,
    controlPersonDisclosure,
    politicalExposureDisclosure,
    backupWithholding,
  } = values;

  const wiz = useOpenInvestJointAccountWizardContext();

  const uncheckAllDisclosures = () => {
    setNoDisclosures(true);
    // exchangeOrFinraAffiliationDisclosure
    setValue('exchangeOrFinraAffiliationDisclosure.isAffiliated', false);
    setValue('exchangeOrFinraAffiliationDisclosure.firmName', null);

    // controlPersonDisclosure
    setValue('controlPersonDisclosure.isControlPerson', false);
    setValue('controlPersonDisclosure.companySymbols', null);

    // politicalExposureDisclosure
    setValue('politicalExposureDisclosure.isPoliticallyExposed', false);
    setValue('politicalExposureDisclosure.politicalOrganization', null);
    setValue('politicalExposureDisclosure.immediateFamilyMembers', null);

    // isSubjectToBackupWithholding
    setValue('backupWithholding.isSubjectToBackupWithholding', false);
  };

  const isAffiliated =
    exchangeOrFinraAffiliationDisclosure?.isAffiliated ?? false;
  const isControlPerson = controlPersonDisclosure?.isControlPerson ?? false;
  const isPoliticallyExposed =
    politicalExposureDisclosure?.isPoliticallyExposed ?? false;
  const isSubjectToBackupWithholding =
    backupWithholding?.isSubjectToBackupWithholding ?? false;

  React.useEffect(() => {
    setNoDisclosures(
      !(
        isAffiliated ||
        isControlPerson ||
        isPoliticallyExposed ||
        isSubjectToBackupWithholding
      ),
    );
    // Clear out fields tied to any disclosures if it's unchecked
    if (!isAffiliated) {
      setValue('exchangeOrFinraAffiliationDisclosure.firmName', null);
    }
    if (!isControlPerson) {
      setValue('controlPersonDisclosure.companySymbols', null);
    }
    if (!isPoliticallyExposed) {
      setValue('politicalExposureDisclosure.politicalOrganization', null);
      setValue('politicalExposureDisclosure.immediateFamilyMembers', null);
    }

    if (!isSubjectToBackupWithholding) {
      setValue('backupWithholding.isSubjectToBackupWithholding', false);
    }
  }, [
    isAffiliated,
    isControlPerson,
    isPoliticallyExposed,
    isSubjectToBackupWithholding,
    setValue,
  ]);

  const onSubmit: SubmitHandler<DisclosuresFormValues> = (data) => {
    dispatch(submitReactFormData({ openJointInvestAccountDisclosures: data }));
    wiz.next();
  };

  return (
    <Flex maxWidth={588} flexDirection="column" mx="auto">
      <Box pt={32}>
        <BackArrow content="Back" onClick={() => wiz.back()} />
      </Box>
      <HM mt={48} mb={32}>
        Do any of these apply?
      </HM>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <ControlledCheckbox
            name="exchangeOrFinraAffiliationDisclosure.isAffiliated"
            label="I am affiliated with or work for a broker/dealer"
            control={control}
          />
          {isAffiliated && (
            <ControlledInput
              name="exchangeOrFinraAffiliationDisclosure.firmName"
              label="Firm Name"
              placeholder="M1 Finance, etc."
              control={control}
            />
          )}
        </Box>
        <Box>
          <ControlledCheckbox
            name="controlPersonDisclosure.isControlPerson"
            label="I am a 10% shareholder or director of a publicly traded company."
            control={control}
          />
          {isControlPerson && (
            <ControlledInput
              name="controlPersonDisclosure.companySymbols"
              label="Symbols"
              placeholder="GOOG, AMZN, META"
              control={control}
              valueIsArray
            />
          )}
        </Box>
        <Box>
          <ControlledCheckbox
            name="politicalExposureDisclosure.isPoliticallyExposed"
            label="I am or an immediate family member is a current or former Public Official."
            control={control}
          />
          {isPoliticallyExposed && (
            <>
              <ControlledInput
                name="politicalExposureDisclosure.politicalOrganization"
                label="Affiliated public entities"
                placeholder="e.g. Director of the Port Authority"
                control={control}
              />
              <ControlledInput
                name="politicalExposureDisclosure.immediateFamilyMembers"
                label="Names of immediate family members"
                placeholder="e.g. George Washington, President of USA"
                valueIsArray
                control={control}
              />
            </>
          )}
        </Box>
        <Box>
          <ControlledCheckbox
            name="backupWithholding.isSubjectToBackupWithholding"
            label="I am subject to backup withholding by the IRS."
            my={8}
            control={control}
          />
        </Box>
        <Box>
          <Checkbox
            label={<PL fontWeight={600}>None of these describes me.</PL>}
            checked={noDisclosures}
            onChange={() => !noDisclosures && uncheckAllDisclosures()}
          />
        </Box>
        <Button
          type="submit"
          kind="primary"
          size="large"
          label="Continue"
          mt={32}
        />
      </form>
    </Flex>
  );
};
