import * as React from 'react';
import { Route } from 'react-router-dom';

import { ForwardIfAuthGuard } from '~/router/Authentication/ForwardIfAuthGuard';

import { ForgotPasswordConfirmRoute } from './forgot-password-confirm';

import { InitiateResetPasswordPage } from './InitiateResetPasswordPage';
import { InitiateResetPasswordPageLayout } from './InitiateResetPasswordPageLayout';

export const ForgotPasswordRoute = () => {
  return (
    <Route
      path="forgot-password"
      element={
        <ForwardIfAuthGuard>
          <InitiateResetPasswordPageLayout />
        </ForwardIfAuthGuard>
      }
    >
      {ForgotPasswordConfirmRoute()}
      <Route index element={<InitiateResetPasswordPage />} />
    </Route>
  );
};
