import * as React from 'react';
import { Route } from 'react-router-dom';

import { AvailableFundingSourcesPage } from '~/pages/dashboard/bank-connection/AvailableFundingSourcesPage';

export const AvailableFundingSourcesRoute = () => {
  return (
    <Route
      path="available-funding-sources"
      element={<AvailableFundingSourcesPage />}
    />
  );
};
