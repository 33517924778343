import * as React from 'react';

import { MOVE_MONEY_FLOW_STEPS as STEPS } from '~/static-constants';

import { AtomicDirectDepositStep } from './steps/AtomicDirectDepositStep';
import { MoveMoneyStep } from './steps/MoveMoney/MoveMoneyStep';

export class MoveMoneyContainer extends React.Component<any> {
  static steps = {
    [STEPS.MOVE_MONEY]: MoveMoneyStep,
    [STEPS.DIRECT_DEPOSIT]: MoveMoneyStep,
    [STEPS.DIRECT_DEPOSIT_HYSA]: AtomicDirectDepositStep,
    [STEPS.DIRECT_DEPOSIT_INVEST]: AtomicDirectDepositStep,
  };

  render() {
    const Step = MoveMoneyContainer.steps[this.props.step];
    // @ts-expect-error - TS2741 - Property 'onFinishStep' is missing in type '{}' but required in type 'MoveMoneyProps'.
    return Step ? <Step {...this.props} /> : null;
  }
}
