import { Box, Button, Flex, HXS, PL } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useApplicationPendingQuery } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';

export const ApplicationPending = () => {
  const navigate = useNavigate();
  const { data, loading } = useApplicationPendingQuery();
  if (loading) {
    return null;
  }

  if (!data?.viewer.credit?.activeApplication?.status) {
    return <GenericSystemError />;
  }

  const generatePageContent = () => {
    switch (data.viewer.credit?.activeApplication?.status) {
      case 'SUBMITTED':
      case 'IN_REVIEW':
      case 'ERROR_SUBMITTING_APPLICATION':
        return 'Your application for an M1 credit card has been submitted. Your information will be verified and you will be notified when a decision is made.';
      case 'ACCEPTED_PENDING_FINAL_APPROVAL':
      case 'ERROR_ACCEPTING_OFFER':
        return 'Your decision on the provided offer has been recieved. There are a few things that still need to be processed. You will be notified once processing is complete.';
      default:
        throw new Error(
          'Unexpected application status on Application Pending screen',
        );
    }
  };

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      mt={150}
      justifyContent="center"
    >
      <Illustration name="creditCardPending" />
      <Box width={500} textAlign="center">
        <HXS content="Application Pending" />
        <PL mt={32} content={generatePageContent()} />
        <Button
          kind="primary"
          label="Done"
          mt={64}
          onClick={() => {
            navigate({ to: '/d/spend/credit/marketing' });
          }}
          size="large"
        />
      </Box>
    </Flex>
  );
};
