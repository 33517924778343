import { PL, LM, LL, PM, Flex, Box } from '@m1/liquid-react';
import * as React from 'react';

import { ExternalLink } from '~/components/ExternalLink';
import { ControlImg } from '~/toolbox/carousel';

import { DialogPagingButton } from './DialogPagingButton';
import { HelperDialogProps, DialogSequenceConfig } from './HelperDialog';
import { StyledDialog } from './HelperDialog.styled';
import { HelperDialogCloseIcon } from './HelperDialogCloseIcon';

export type MultiDialogConfig = Omit<HelperDialogProps, 'children'>;

export type MultiHelperDialogProps = {
  children: React.ReactNode;
  dialogConfigs: Array<MultiDialogConfig>;
};

const controlsContentAlignment = (
  sequence?: DialogSequenceConfig,
  // @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter. | TS7006 - Parameter 'pageNumber' implicitly has an 'any' type.
  pageNumber,
) => {
  let alignment = 'center';
  const maxDialogPage = sequence ? sequence.maxDialogPage : 1;

  if (pageNumber === 1 && maxDialogPage !== 1) {
    alignment = 'flex-end';
  } else if (pageNumber === maxDialogPage && maxDialogPage !== 1) {
    alignment = 'flex-start';
  }

  return alignment;
};

export const MultiHelperDialog = ({
  children,
  dialogConfigs,
}: MultiHelperDialogProps) => {
  if (!dialogConfigs.length) {
    return null;
  }

  return (
    <Box position="relative" display="inline-block">
      {children}

      {dialogConfigs.map((config, index) => {
        const alignment = config.alignment || 'center';
        const direction = config.direction || 'up';
        const margin = config.margin || 0;
        const height = config.height || 220;
        const width = config.width || 400;

        return (
          config.isVisible && (
            <StyledDialog
              alignment={alignment}
              backgroundColor="backgroundPrimarySubtle"
              borderRadius={8}
              direction={direction}
              height={height}
              key={`${index}`}
              margin={margin}
              padding={16}
              position="absolute"
              width={width}
            >
              <Flex justifyContent="flex-start" mb={8}>
                <HelperDialogCloseIcon />
              </Flex>
              <Flex justifyContent="space-between">
                <Flex flexDirection="column">
                  <PL
                    fontWeight={600}
                    color="foregroundNeutralMain"
                    content={config.title}
                    mb={8}
                  />
                  {typeof config.content === 'string' ? (
                    <PM
                      color="foregroundNeutralMain"
                      content={config.content}
                    />
                  ) : (
                    config.content
                  )}
                  <Flex
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                  >
                    <LL
                      mt={8}
                      content={
                        <ExternalLink destination="PORTFOLIO_ORGANIZER_FAQ">
                          View FAQs
                        </ExternalLink>
                      }
                    />
                  </Flex>
                </Flex>
                <Flex
                  flexDirection="column"
                  ml={16}
                  justifyContent={controlsContentAlignment(
                    config.sequence,
                    config.pageNumber,
                  )}
                >
                  {config.sequence && config.pageNumber !== 1 && (
                    <DialogPagingButton
                      margin="0 0 16px 0"
                      onClick={config.sequence.decrementDialogPage}
                    >
                      <ControlImg direction="vertical" />
                    </DialogPagingButton>
                  )}
                  <Flex
                    alignItems="center"
                    backgroundColor="borderSuccess"
                    borderRadius={10}
                    color="success"
                    height={25}
                    justifyContent="center"
                    minWidth={42}
                  >
                    {config.pageNumber && (
                      <LM
                        content={
                          config.sequence
                            ? `${config.pageNumber}/${config.sequence.maxDialogPage}`
                            : `${config.pageNumber}/${config.pageNumber}`
                        }
                      />
                    )}
                  </Flex>
                  {config.sequence &&
                    config.pageNumber !== config.sequence.maxDialogPage && (
                      <DialogPagingButton
                        margin="16px 0 0 0"
                        onClick={config.sequence.incrementDialogPage}
                      >
                        <ControlImg direction="vertical_reversed" />
                      </DialogPagingButton>
                    )}
                </Flex>
              </Flex>
            </StyledDialog>
          )
        );
      })}
    </Box>
  );
};
