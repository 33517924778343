import * as React from 'react';

import { AppContext } from '~/AppContext';

type UseLaunchDarklyResult<T> = {
  didSucceed: boolean;
  flagResult: T | null | undefined;
  isReady: boolean;
};

export const useLaunchDarkly = <T>(
  flagName: string,
  defaultValue: T,
): UseLaunchDarklyResult<T> => {
  const { launchDarkly } = React.useContext(AppContext);
  const [flagResult, setFlagResult] = React.useState<T | null>(null);
  const [didSucceed, setDidSucceed] = React.useState(false);

  const isReady = launchDarkly.checkLaunchDarklyReady();

  React.useEffect(() => {
    const res = launchDarkly.evaluateFlag(flagName, defaultValue);
    setFlagResult(res);
    setDidSucceed(true);
  }, [flagName, defaultValue, isReady]);

  return {
    didSucceed,
    flagResult,
    isReady,
  };
};
