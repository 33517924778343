import moment from 'moment';
import * as React from 'react';

type Props = {
  // @ts-expect-error - TS2709 - Cannot use namespace 'moment' as a type.
  date: string | moment | Array<number> | Date;
  format: string;
  formatTemplate: string;
  fromNowCutOff: [number, string];
  fromNowTemplate: string;
};

export class RelativeDate extends React.Component<Props> {
  static dateId = '${date}';

  static defaultProps = {
    format: 'll',
    formatTemplate: RelativeDate.dateId,
    fromNowCutOff: [24, 'hours'],
    fromNowTemplate: RelativeDate.dateId,
  };

  render() {
    const m = moment(this.props.date);
    // @ts-expect-error - TS2769 - No overload matches this call.
    const someTimeAgo = moment().subtract(...this.props.fromNowCutOff);

    const [template, date] = m.isBefore(someTimeAgo)
      ? [this.props.formatTemplate, m.format(this.props.format)]
      : [this.props.fromNowTemplate, m.fromNow()];

    return template.replace(RelativeDate.dateId, date);
  }
}
