import { HXS, PL, Box } from '@m1/liquid-react';
import * as React from 'react';

export const FeedbackFormDescription = () => {
  return (
    <Box m={32}>
      <HXS mb={12}>How are we doing?</HXS>
      <PL>
        Thanks for using M1. Your feedback can help improve the experience for
        everyone, and we look forward to reading it.
      </PL>
    </Box>
  );
};
