import {
  PieBacktestPeriodEnum,
  SortDirectionEnum,
  SystemPieSortTypeEnum,
} from '~/graphql/types';

export const performancePeriods = [
  {
    label: '1Y',
    value: 'ONE_YEAR',
  },
  {
    label: '3Y',
    value: 'THREE_YEARS',
  },
  {
    label: '5Y',
    value: 'FIVE_YEARS',
  },
];

export const PERIOD_TO_SORT: Partial<
  Record<PieBacktestPeriodEnum, SystemPieSortTypeEnum>
> = {
  ONE_YEAR: SystemPieSortTypeEnum.Performance_1Y,
  THREE_YEARS: SystemPieSortTypeEnum.Performance_3Y,
  FIVE_YEARS: SystemPieSortTypeEnum.Performance_5Y,
};

export const DEFAULT_SORT = {
  type: SystemPieSortTypeEnum.Name,
  direction: SortDirectionEnum.Asc,
};

export const INITIAL_GENERAL_INVESTING_SORT = {
  type: SystemPieSortTypeEnum.RiskGrade,
  direction: SortDirectionEnum.Asc,
};
