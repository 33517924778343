import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useOnboardingIdentityFirstQuestionsQuery } from '~/graphql/hooks';
import { Spinner } from '~/toolbox/spinner';

import { CollectHolderSsnStep } from './CollectHolderSSNStep';

export const CollectPrimaryHolderSsnStep = (rest: any) => {
  const { data, loading, error } = useOnboardingIdentityFirstQuestionsQuery();

  if (loading) {
    return <Spinner />;
  }

  if (!data?.viewer || error) {
    return <GenericSystemError />;
  }

  const questions =
    data?.viewer.user?.onboardingIdentityFirst?.userProfileSection?.questions;
  const question = questions?.find(
    (question) => question?.questionType === 'SOCIAL_SECURITY_NUMBER',
  );

  const { title, subtitle, disclaimerTooltipContent } = question || {
    title: null,
    subtitle: null,
  };

  return (
    <CollectHolderSsnStep
      holder="primary"
      title={title}
      subtitle={subtitle}
      disclaimerTooltipContent={disclaimerTooltipContent}
      {...rest}
    />
  );
};
