import { ACTION_TYPES } from '~/redux/actions';

export type NotificationBundle = {
  location: string | null | undefined;
  message: string;
  type: string;
};

export type NotificationsState = Array<NotificationBundle>;

export const initialState: NotificationsState = [];

export const notificationsReducer = (
  state: NotificationsState = initialState,
  action: any,
): NotificationsState => {
  switch (action.type) {
    case ACTION_TYPES.DISMISS_NOTIFICATIONS:
      if (typeof action.payload === 'string') {
        // Dismiss by location
        return state.filter(
          (notification) => notification.location !== action.payload,
        );
      }
      if (action.payload?.location) {
        // Dismiss by notification equality
        return state.filter(
          (notification) =>
            JSON.stringify(notification) !== JSON.stringify(action.payload),
        );
      }
      return initialState;
    case ACTION_TYPES.FINISHED_FLOW_STEP:
      return initialState;
    case ACTION_TYPES.DISPLAY_NOTIFICATION:
      return [...state, action.payload];
    default:
      return state;
  }
};
