import { Box, Button, Flex, HM, PL, PS } from '@m1/liquid-react';
import every from 'lodash-es/every';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { usePersonalLoansLandingPageQuery } from '~/graphql/hooks';
import { RequiredDocument } from '~/graphql/types';
import { Checkbox } from '~/toolbox/checkbox';
import { Link } from '~/toolbox/link';
import { Pill, PillKind } from '~/toolbox/Pill';
import { Spinner } from '~/toolbox/spinner';

import {
  StyledApplicationBottomCardContainer,
  StyledApplicationCenteredCardContainer,
} from '../components/ApplicationCard';
import { StyledApplicationContainer } from '../components/ApplicationContainer';
import { BackButton } from '../components/BackButton';

const PILL_KIND_MAPPING: Record<string, PillKind> = {
  'CAUTION': 'caution',
  'DANGER': 'danger',
  'DANGER_INVERSE': 'danger',
  'IMPORTANT': 'important',
  'IMPORTANT_INVERSE': 'important',
  'SUCCESS': 'success',
  'WHATS_NEW': 'new',
  'WHATS_NEW_INVERSE': 'new',
};

type LandingPageProps = {
  onFinishStep: (signature: TermsAndConditionsSignature) => void;
};

type TermsAndConditionsSignature = {
  termsAndConditionsSignature: string;
};

type LooseObject = Record<string, boolean>;

export const LandingPage = ({ onFinishStep }: LandingPageProps) => {
  const [documentsPillState, setDocumentsPillState] =
    React.useState<LooseObject>({});
  const [requiredDocumentError, setRequiredDocumentError] =
    React.useState<boolean>(false);
  const [isChecked, setIsChecked] = React.useState<boolean>(false);

  const checkRequiredDocuments = (
    requiredDocuments: RequiredDocument[],
    pillState = documentsPillState,
  ) => {
    return every(requiredDocuments, (document) => {
      return pillState[document.title];
    });
  };

  const handleFinish = (
    requiredDocuments: RequiredDocument[],
    termsAndConditionsSignature: string,
  ) => {
    if (checkRequiredDocuments(requiredDocuments)) {
      setRequiredDocumentError(false);
      onFinishStep({
        termsAndConditionsSignature,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      setRequiredDocumentError(true);
    }
  };

  const { data, loading } = usePersonalLoansLandingPageQuery();
  if (loading) {
    return <Spinner fullScreen centered />;
  }

  const personalLoans = data?.viewer.borrow?.personalLoans;
  const loanDisclaimer =
    personalLoans?.applicationFlow?.landingPage?.loanDisclaimer;

  const requiredDocuments =
    personalLoans?.applicationFlow?.requiredDocuments?.documents;
  const termsAndConditionsSignature =
    personalLoans?.applicationFlow?.requiredDocuments?.signature;
  const optionalDocuments =
    personalLoans?.applicationFlow?.optionalDocuments?.documents;

  if (!personalLoans || !requiredDocuments || !termsAndConditionsSignature) {
    return <GenericSystemError />;
  }
  return (
    <StyledApplicationContainer>
      <BackButton previousRouteName="/d/borrow">
        <StyledApplicationCenteredCardContainer width={500}>
          <HM
            content="Please read and accept the below to continue onto loan preferences and offers"
            mb={16}
            mt={48}
          />
          <Box>
            {requiredDocuments?.map((document) => (
              <Flex flexDirection="row" mb={12} key={document.title}>
                <Link
                  to={document.url}
                  target="_blank"
                  textDecoration="underline"
                  onClick={() => {
                    if (document.title) {
                      const newPillState = {
                        ...documentsPillState,
                        [document.title]: true,
                      };
                      setDocumentsPillState(newPillState);
                      if (
                        checkRequiredDocuments(requiredDocuments, newPillState)
                      ) {
                        setRequiredDocumentError(false);
                      }
                    }
                  }}
                >
                  <PL style={{ lineHeight: '16px' }}>{document.title}</PL>
                </Link>
                <Pill
                  kind={
                    documentsPillState[document.title]
                      ? PILL_KIND_MAPPING['SUCCESS']
                      : PILL_KIND_MAPPING['CAUTION']
                  }
                  label={
                    documentsPillState[document.title] ? 'Viewed' : 'Not viewed'
                  }
                  size="small"
                  ml={8}
                />
              </Flex>
            ))}
            {requiredDocumentError && (
              <PL
                mb={24}
                color="critical"
                content="To move forward with the loan you must first view the Terms of Use."
              />
            )}
            {optionalDocuments?.map((document) => (
              <Flex flexDirection="row" mb={12} key={document?.title}>
                <Link
                  to={document?.url}
                  target="_blank"
                  textDecoration="underline"
                >
                  <PL>{document?.title}</PL>
                </Link>
              </Flex>
            ))}
          </Box>
          <Flex alignItems="center" mb={24} mt={32}>
            <Checkbox
              css="margin-left: -8px"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              size="medium"
              label={
                <Box color="foregroundNeutralSecondary">
                  I have read and agree to the documents above
                </Box>
              }
            />
          </Flex>
          <Box py={24}>
            <PS color="foregroundNeutralSecondary">{loanDisclaimer}</PS>
          </Box>
          <StyledApplicationBottomCardContainer
            style={{ margin: '0px', maxWidth: '600px' }}
          >
            <Button
              kind="primary"
              size="large"
              label="Continue"
              disabled={!isChecked}
              type="submit"
              onClick={() =>
                handleFinish(requiredDocuments, termsAndConditionsSignature)
              }
              mb={32}
            />
          </StyledApplicationBottomCardContainer>
        </StyledApplicationCenteredCardContainer>
      </BackButton>
    </StyledApplicationContainer>
  );
};
