import { Box, PL } from '@m1/liquid-react';
import React from 'react';

import { ControlledDropdown } from '~/components/form/ControlledDropdown';
import { RadioChoice } from '~/components/form/Radio';

import { TransferParticipantCell } from '~/components/transfers/TransferParticipantCell';

import { DividendConfigurationSettingFragment } from '~/graphql/types';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';

type DividendSettingsProps = {
  name?: null | undefined | `securities.${string}`;
  settings: DividendConfigurationSettingFragment[];
  value: string;
};

const getSelectedAccount = (
  accounts: NonNullable<DividendConfigurationSettingFragment['accounts']>,
) => accounts.find((account) => account?.selected)?.transferParticipant?.id;

const getDefaultAccount = (
  accounts: NonNullable<DividendConfigurationSettingFragment['accounts']>,
): string | undefined => {
  const selectedAccount = getSelectedAccount(accounts);
  // Accounts are returned from Lens sorted, so we can just return the first one.
  return selectedAccount ?? accounts[0]?.transferParticipant?.id;
};

export const DividendSettings = ({
  settings,
  value,
  name,
}: DividendSettingsProps) => {
  return (
    <Box
      css={{
        '& > label': {
          width: '100%',
        },
        '& > label > input': {
          alignSelf: 'flex-start',
          marginTop: 4,
        },
      }}
    >
      {settings.map((setting) => (
        <RadioChoice
          name={`${name ? `${name}.` : ''}selected`}
          disabled={setting.disabled}
          label={
            <Box>
              <PL
                color={
                  setting.disabled ? 'foregroundNeutralTertiary' : undefined
                }
                fontWeight={600}
              >
                {setting.label}
              </PL>
              {setting.description && (
                <PL color="foregroundNeutralSecondary">
                  {setting.description}
                </PL>
              )}
              {setting.contextualLink && (
                <LinkableLink linkable={setting.contextualLink} mt={8} />
              )}
              {value === setting.value && (
                <>
                  {setting.accounts && (
                    <Box
                      flexGrow={1}
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <ControlledDropdown
                        disabled={!setting.accounts.length}
                        defaultValue={getDefaultAccount(setting.accounts)}
                        style={{ control: { maxHeight: 'auto' } }}
                        name={`${name ? `${name}.` : ''}transferAccountId`}
                        isSearchable={false}
                        options={setting.accounts
                          .filter((account) => account?.transferParticipant)
                          .map((account) => {
                            return {
                              label: (
                                <TransferParticipantCell
                                  transferParticipant={
                                    account!.transferParticipant!
                                  }
                                  includeDescription
                                  titleWeight={400}
                                />
                              ),
                              value: account?.transferParticipant?.id ?? '',
                            };
                          })}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>
          }
          key={setting.value}
          value={setting.value}
        />
      ))}
    </Box>
  );
};
