import { Box, Button, Flex } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { AppContext } from '~/AppContext';
import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import {
  PostDocumentUploadRefetchDocument,
  useCompleteDocumentUploadRequestMutation,
  useGeneratePresignedDocumentUploadRequestUrlMutation,
} from '~/graphql/hooks';
import { DocumentManagementPageQuery } from '~/graphql/types';
import { useToast } from '~/toasts';

import { DocumentLoadingPage } from '../components/DocumentLoadingPage';
import { DocumentForm } from '../types';

import { DocumentUploadRequestForm } from './DocumentUploadRequestForm';

export const DocumentUploadRequestPage = ({
  documentRequestCenter,
}: {
  documentRequestCenter: Maybe<
    DocumentManagementPageQuery['viewer']['documentUploadRequestsCenter']
  >;
}) => {
  const { analytics } = React.useContext(AppContext);
  const { addToast } = useToast();

  const [showLoadingScreen, setShowLoadingScreen] = React.useState(false);
  const goBack = () => window.history.back();

  const handleUploadError = () => {
    analytics.recordEvent('m1_document_upload_unsuccessful');
    addToast({
      content: 'The upload has failed. Please try again.',
      kind: 'alert',
      duration: 'short',
    });
  };

  const [completeDocumentUpload] = useCompleteDocumentUploadRequestMutation({
    onCompleted: goBack,
    onError: handleUploadError,
    refetchQueries: [{ query: PostDocumentUploadRefetchDocument }],
  });

  const [requestDocumentUrl] =
    useGeneratePresignedDocumentUploadRequestUrlMutation({
      onError: handleUploadError,
    });

  const uploadForm = useForm<DocumentForm>();

  if (showLoadingScreen) {
    return <DocumentLoadingPage />;
  }

  const {
    acceptableDocumentTypes,
    acceptableFileTypes,
    acceptableMimeTypes,
    associatedServiceType,
    businessPurpose,
    uploadLabel,
    id: documentUploadRequestId,
  } = documentRequestCenter?.documentUploadRequests?.edges?.[0]?.node ?? {};

  if (
    !acceptableDocumentTypes ||
    !acceptableFileTypes ||
    !acceptableMimeTypes
  ) {
    return <GenericSystemError />;
  }

  const onUpload = (input: DocumentForm) => {
    const { documentType, selectedFile } = input;
    if (selectedFile && documentUploadRequestId) {
      setShowLoadingScreen(true);
      requestDocumentUrl({
        variables: {
          input: {
            documentType,
            documentUploadRequestId,
            fileName: selectedFile.name,
          },
        },
        onCompleted: async (data) => {
          const presignedUrl =
            data.generatePresignedDocumentUploadRequestUrl?.outcome
              ?.presignedUrl;
          if (presignedUrl) {
            const response = await fetch(presignedUrl, {
              headers: {
                'Content-Type': selectedFile.type,
                'X-Amz-Server-Side-Encryption': 'AES256',
                'Content-Disposition': 'inline',
              },
              method: 'PUT',
              body: selectedFile,
            });
            if (!response.ok) {
              handleUploadError();
            } else {
              analytics.recordAppAnalyticsEvent({
                name: 'm1_document_upload_success',
                valueParameter: null,
                customParameters: [
                  {
                    name: 'associated_service',
                    value: associatedServiceType ?? '',
                  },
                  {
                    name: 'business_purpose',
                    value: businessPurpose ?? '',
                  },
                ],
                customBoolParameters: [],
                customNumberParameters: [],
              });
              completeDocumentUpload({
                refetchQueries: ['DocumentManagementPage', 'HomeAnnouncements'],
                variables: {
                  input: {
                    documentUploadRequestId,
                  },
                },
              });
            }
          }
        },
      });
    }
  };

  return (
    <FormProvider {...uploadForm}>
      <Flex
        alignContent="center"
        backgroundColor="backgroundNeutralSecondary"
        flexDirection="column"
        maxWidth={550}
        mx="auto"
        my={32}
      >
        <BackArrow
          content="Back"
          onClick={() => {
            analytics.recordEvent('m1_document_upload_exit');
            goBack();
          }}
        />
        <Box mx="auto">
          <Flex justifyContent="center">
            <Illustration name="userIdCard" />
          </Flex>
        </Box>
        <Flex justifyContent="center" maxWidth={550}>
          <DocumentUploadRequestForm
            documentRequestCenter={documentRequestCenter}
          />
        </Flex>
        <Box textAlign="center" mt={64}>
          <Button
            label={uploadLabel ?? 'Upload document'}
            size="large"
            disabled={!uploadForm.formState.isValid}
            onClick={uploadForm.handleSubmit(onUpload)}
            type="submit"
          />
        </Box>
      </Flex>
    </FormProvider>
  );
};
