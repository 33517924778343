import { Button, HotButton, type ButtonProps } from '@m1/liquid-react';
import * as React from 'react';

import { PieOrganizerEvent } from '~/components/PortfolioOrganizerPage';
import { useNavigate } from '~/hooks/useNavigate';
import { useSelector } from '~/redux/hooks';

export type PieOrganizerNavigationButtonProps = ButtonProps & {
  event: PieOrganizerEvent;
  isCrypto?: boolean;
  buttonType?: 'BUTTON' | 'HOT_BUTTON';
  onClick?: () => void;
  label: string;
};

export const PieOrganizerNavigationButton = ({
  event,
  fullWidth = false,
  buttonType = 'BUTTON',
  kind,
  label,
  onClick,
  ...rest
}: PieOrganizerNavigationButtonProps) => {
  const navigate = useNavigate();
  const { state } = useSelector((state) => state.portfolioOrganizer.session);
  const { input } = useSelector((state) => state.newFlows.accountSetup);
  const isCryptoOnboarding = input.registration === 'CRYPTO';
  const finalEvent: PieOrganizerEvent =
    event.type === 'ADDING_SLICES' && state === 'UNINITIALIZED'
      ? {
          type: 'INITIALIZE_ORGANIZER',
          initConfig: {
            confirmationDialog: {
              showApplicableLocations: false,
            },
            mode: event.context || 'NEW_PIE',
            // default to new pie if we aren't given a mode via context
            returnTo: isCryptoOnboarding
              ? '/d/invest/fund-account'
              : '/d/invest',
            legacyIds: event.legacyIds,
            sliceableIds: event.sliceableIds,
            isCrypto: event.isCrypto,
          },
        }
      : event;

  const handleClick = () => {
    onClick?.();
    navigate({
      to: '/d/invest/portfolio-organizer',
      options: { state: { event: finalEvent } },
    });
  };

  return buttonType === 'HOT_BUTTON' ? (
    <HotButton
      {...rest}
      label={label}
      onClick={handleClick}
      size="small"
      icon="editPie"
    />
  ) : (
    <Button
      {...rest}
      fullWidth={fullWidth}
      kind={kind}
      label={label}
      onClick={() => {
        navigate({
          to: '/d/invest/portfolio-organizer',
          options: { state: { event: finalEvent } },
        });
      }}
    />
  );
};
