import { globalColors, PM, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { Link } from '~/toolbox/link';

export const StyledRouterLink = styled(Link)`
  width: 100%;
  padding: 12px 16px 12px 12px;

  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${({ theme }) => theme.colors.backgroundFeatureFlat};
  border: 1px solid ${({ theme }) => theme.colors.borderMain};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.foregroundNeutralOnDark};

  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundFeatureFlat};
    border-color: ${({ theme }) => theme.colors.teal02};
    ${() => {
      return `box-shadow: 0 8px 16px 0 rgba(${globalColors.achromaticPureBlack[0]} / 0.3);`;
    }}
    color: ${({ theme }) => theme.colors.foregroundNeutralOnDark};
  }

  > p {
    width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > * {
    &:first-child {
      flex-grow: 0;
      margin-right: 12px;
    }

    &:last-child {
      flex-grow: 0;
      margin-left: 12px;
    }
  }
`;

type FundingSourceBankConnectorProps = {
  externalName?: string | null | undefined;
  type: 'savings' | 'personal_loans' | 'fs_standard';
  onClick: () => void;
};

export const FundingSourceBankConnector = ({
  externalName = 'Connect Bank',
  type,
  onClick,
}: FundingSourceBankConnectorProps) => {
  return (
    <StyledRouterLink
      onClick={onClick}
      to="/d/bank-connection"
      query={{ type }}
      id="funding-source-bank-connector"
    >
      <Icon name="accountBankPrimary20" />
      <PM color="foregroundNeutralOnDark" content={externalName} />
      <Icon name="caretRight16" style={{ top: -1, position: 'relative' }} />
    </StyledRouterLink>
  );
};
