import { makeVar } from '@apollo/client';

import { SliceableCellFragment } from '../../types';

export const selectedSlicesVar = makeVar<SliceableCellFragment[]>([]);

export const resetSelectedSlices = () => selectedSlicesVar([]);

export const clickedSliceCheckbox = (slice: SliceableCellFragment) => {
  const currentSlices = selectedSlicesVar();
  const newArr = [...currentSlices];
  const index = newArr.findIndex((item) => item.id === slice.id);

  if (index === -1) {
    newArr.push(slice);
  } else {
    newArr.splice(index, 1);
  }
  selectedSlicesVar(newArr);
};
