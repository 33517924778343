import { Box, Flex, PS, spacingUnits } from '@m1/liquid-react';
import isNil from 'lodash-es/isNil';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { ControlledDropdown } from '~/components/form/ControlledDropdown';
import { ControlledInput } from '~/components/form/ControlledInput';

import { IRSContributionLimitsDescription } from '~/components/IRSContributionLimitsDescription';
import { useIraDistributionInfoQuery } from '~/graphql/hooks';
import {
  Account,
  IraContributionYear,
  IraContributionYearEnum,
  TransferWizardParticipantDetailsFragment,
} from '~/graphql/types';
import { IraDistributionReasonEnumDropdownOptions } from '~/static-constants';

import { Link } from '~/toolbox/link';

import { CreateTransferContext } from '../CreateTransferProvider';
import { TransferDetailsFormValues } from '../steps/TransferDetails.types';

const getContributionYearDropdownOptions = (
  contributionYears: IraContributionYear[],
) =>
  contributionYears.map((contributionYear) => ({
    name:
      contributionYear.year === new Date().getFullYear()
        ? IraContributionYearEnum.CurrentYear
        : IraContributionYearEnum.PriorYear,
    description: (
      <>{`${contributionYear.year === new Date().getFullYear() ? 'Current Year' : 'Previous Year'}`}</>
    ),
  }));

const IRAContributionFields = ({
  contributionYears,
  disableContributionYear,
}: {
  contributionYears: IraContributionYear[];
  disableContributionYear: boolean;
}) => {
  const { control, watch } = useFormContext<TransferDetailsFormValues>();
  const [isRollover, contributionYear] = watch([
    'isRollOver',
    'contributionYear',
  ]);
  const showContributionYear = isRollover === false;

  return (
    <Flex flexDirection="column" width="100%">
      <ControlledDropdown
        name="isRollOver"
        label="Is this a rollover"
        source={[
          { name: true, description: 'Yes' },
          { name: false, description: 'No' },
        ]}
        rules={{
          validate: (value) => !isNil(value) || 'This field is required',
        }}
        style={{
          marginTop: 0,
        }}
        info="Use within 60 days of distribution"
      />
      {showContributionYear && (
        <>
          <ControlledDropdown
            name="contributionYear"
            label="Contribution year"
            control={control}
            source={getContributionYearDropdownOptions(contributionYears)}
            disabled={disableContributionYear}
            rules={{
              validate: (value) => !isNil(value),
              required: {
                value: showContributionYear,
                message: 'A contribution year is required',
              },
            }}
            style={{
              marginTop: 16,
            }}
            info={
              <IRSContributionLimitsDescription
                contributionYears={contributionYears}
                fontSize={12}
                contributionYearSelected={contributionYear}
              />
            }
          />
        </>
      )}
    </Flex>
  );
};

const IRADistributionFields = ({
  contributionYears,
}: {
  contributionYears: IraContributionYear[];
}) => {
  const { control, watch, setValue, getFieldState } =
    useFormContext<TransferDetailsFormValues>();

  const [distributionReason, sourceId, federalWithholding, stateWithholding] =
    watch([
      'distributionReason',
      'sourceId',
      'federalWithholding',
      'stateWithholding',
    ]);
  const showContributionYear =
    distributionReason === 'EXCESS_CONTRIBUTION_REMOVAL_BEFORE_TAX_DEADLINE';

  const { data } = useIraDistributionInfoQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      accountId: sourceId as string,
    },
  });

  const account = data?.node as Account | undefined;

  const iraDistributionReasons = account?.iraDistributionReasons;

  // Filter to only show the options that are available for the account.
  // Use enum options here rather than just using the API response because
  // 1. The response from lens does not include display copy,
  // 2. We want to use the sort order defined in the custom enum,
  // 3. Clients need to opt-in to new reasons by adding them to the enum.
  // If we didn't get reasons from the account schema, show all local options.
  const filteredOptions = iraDistributionReasons
    ? IraDistributionReasonEnumDropdownOptions.filter((option) =>
        iraDistributionReasons.includes(option.name),
      )
    : IraDistributionReasonEnumDropdownOptions;

  React.useEffect(() => {
    if (
      account?.iraWithholding &&
      isNil(federalWithholding) &&
      isNil(stateWithholding)
    ) {
      setValue(
        'federalWithholding',
        account.iraWithholding.defaultFederalWithholding * 100,
      );
      setValue(
        'stateWithholding',
        account.iraWithholding.defaultStateWithholding * 100,
      );
    }
  }, [account, federalWithholding, setValue, stateWithholding]);

  // Clear out fields if conditional value (`distributionReason`) no longer requires it
  React.useEffect(() => {
    if (!showContributionYear && Boolean(contributionYears)) {
      setValue('contributionYear', undefined);
      setValue('niaAmount', undefined);
    }
  }, [contributionYears, setValue, showContributionYear]);

  return (
    <Flex flexDirection="column" width="100%">
      <ControlledInput
        name="federalWithholding"
        control={control}
        label="Federal tax withholding (%)"
        defaultValue={account?.iraWithholding?.defaultFederalWithholding}
        maskType="percent"
        rules={{
          required: 'This field is required',
          min: {
            value: 0,
            message: 'A value less than 0 is not allowed',
          },
          max: {
            value: 100,
            message: 'A value greater than 100 is not allowed',
          },
        }}
      />
      <ControlledInput
        name="stateWithholding"
        control={control}
        label="State tax withholding (%)"
        defaultValue={account?.iraWithholding?.defaultStateWithholding}
        maskType="percent"
        rules={{
          required: 'This field is required',
          min: {
            value: 0,
            message: 'A value less than 0 is not allowed',
          },
          max: {
            value: 100,
            message: 'A value greater than 100 is not allowed',
          },
        }}
      />
      <Box
        px={16}
        mb={16}
        mt={getFieldState('stateWithholding').invalid ? 8 : -4}
      >
        <PS color="foregroundNeutralSecondary">
          {account?.iraWithholding?.stateWithholdingInformationText}
        </PS>
      </Box>
      <ControlledDropdown
        name="distributionReason"
        label="Distribution Reason"
        control={control}
        source={filteredOptions}
        rules={{
          required: {
            value: true,
            message: 'A distribution reason is required',
          },
        }}
        id="distributionReason"
      />
      {showContributionYear && (
        <>
          <ControlledDropdown
            name="contributionYear"
            label="Tax Year"
            control={control}
            style={{ marginBottom: 4 }}
            source={getContributionYearDropdownOptions(contributionYears)}
            rules={{
              required: {
                value: showContributionYear,
                message: 'A contribution year is required',
              },
            }}
            id="contributionYear"
          />
          <Box px={16} mb={8}>
            <PS color="foregroundNeutralSecondary">
              {data?.viewer.transfers?.niaWithholdingDisclaimer?.text}
            </PS>
          </Box>
          <ControlledInput
            name="niaAmount"
            label="Net Income Attributable"
            maskType="money"
            control={control}
            defaultValue={0}
            min={0}
            max={100000}
            helpText={data?.viewer.transfers?.niaDisclaimer?.text}
          />
        </>
      )}
      <Flex flexDirection="column" gap="16px">
        {data?.viewer.transfers?.iraDistributionReasonFAQ?.url && (
          <Link
            to={data?.viewer.transfers?.iraDistributionReasonFAQ?.url}
            fontWeight={400}
            underline
            target="_blank"
          >
            {data?.viewer.transfers?.iraDistributionReasonFAQ?.title}
          </Link>
        )}
        {account?.iraWithholding?.iraWithholdingLink?.url && (
          <Link
            to={account?.iraWithholding?.iraWithholdingLink?.url}
            fontWeight={400}
            underline
            target="_blank"
          >
            {account?.iraWithholding?.iraWithholdingLink?.title}
          </Link>
        )}
        {data?.viewer.transfers?.iraOvercontributionFAQ?.url && (
          <Link
            to={data?.viewer.transfers?.iraOvercontributionFAQ?.url}
            fontWeight={400}
            underline
            target="_blank"
          >
            {data?.viewer.transfers?.iraOvercontributionFAQ?.title}
          </Link>
        )}
        {data?.viewer.transfers?.niaLearnMore?.url && (
          <Link
            to={data?.viewer.transfers?.niaLearnMore?.url}
            fontWeight={400}
            underline
            target="_blank"
          >
            {data?.viewer.transfers?.niaLearnMore?.title}
          </Link>
        )}
        <Link
          target="_blank"
          to="https://www.irs.gov/publications/p590a"
          fontWeight={400}
          underline
        >
          IRS guidance
        </Link>
      </Flex>
    </Flex>
  );
};

export const TransferIRAFields = ({
  sourceDetails,
  destinationDetails,
  disableContributionYear,
}: {
  sourceDetails: TransferWizardParticipantDetailsFragment | null;
  destinationDetails: TransferWizardParticipantDetailsFragment | null;
  disableContributionYear: boolean;
}) => {
  const transferContext = React.useContext(CreateTransferContext);
  const { getValues, setValue } = useFormContext<TransferDetailsFormValues>();

  const isDistribution = (sourceDetails?.account as Account)?.isRetirement;
  const isContribution = (destinationDetails?.account as Account)?.isRetirement;

  const data = transferContext?.data;

  const contributionYears = React.useMemo(
    () => data?.viewer.irsRegulations.iraContributionYears ?? [],
    [data],
  );

  // If no IRA account is involved, clear out the form values
  React.useEffect(() => {
    if (isContribution || isDistribution) {
      return;
    }
    // TODO: might have to clear fields even when switching between contribution/distribution
    const haveIraValue = getValues([
      'isRollOver',
      'contributionYear',
      'distributionReason',
      'federalWithholding',
      'stateWithholding',
    ]).find((value) => !isNil(value));

    if (!isNil(haveIraValue)) {
      setValue('isRollOver', undefined);
      setValue('contributionYear', undefined);
      setValue('distributionReason', undefined);
      setValue('federalWithholding', undefined);
      setValue('stateWithholding', undefined);
    }
  }, [getValues, isContribution, isDistribution, setValue]);

  if (!isContribution && !isDistribution) {
    return null;
  }

  return (
    <Flex justifyContent="center" mb={spacingUnits.l}>
      {isDistribution ? (
        <IRADistributionFields contributionYears={contributionYears} />
      ) : (
        <IRAContributionFields
          contributionYears={contributionYears}
          disableContributionYear={disableContributionYear}
        />
      )}
    </Flex>
  );
};
