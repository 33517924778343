import * as React from 'react';

import { useAcatWizardQuery } from '~/graphql/hooks';
import { AcatWizardQuery } from '~/graphql/types';
import { useLocation } from '~/hooks/useLocation';
import { Spinner } from '~/toolbox/spinner';

export const AcatWizardQueryContext =
  React.createContext<AcatWizardQuery | null>(null);

export const AcatWizardQueryProvider = ({
  children,
}: React.PropsWithChildren) => {
  const location = useLocation();

  const { data, loading } = useAcatWizardQuery({
    variables: {
      forIra: Boolean(location?.state?.isIRA),
    },
  });

  if (!data && loading) {
    return <Spinner fullScreen />;
  } else if (!data) {
    // This will be caught by the error boundary and render a generic error message.
    // If we want to define a more specific error state we can do so as part of the ACATs bet.
    throw new Error('Failed to load ACAT wizard data');
  }

  return (
    <AcatWizardQueryContext.Provider value={data}>
      {children}
    </AcatWizardQueryContext.Provider>
  );
};
