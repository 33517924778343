import { Button } from '@m1/liquid-react';
import merge from 'lodash-es/merge';
import * as React from 'react';
import { InjectedFormProps, FormSection } from 'redux-form';

import { connectForm } from '~/hocs';
import { FIELD_MAX_LENGTHS } from '~/static-constants';
import { Grid } from '~/toolbox/grid';
import { getEnumEntries } from '~/utils';

import {
  AddressFields,
  DateOfBirthField,
  TextField,
  DropdownField,
} from './fields';
import { required, printableAsciiChars, date } from './validators';

export type BeneficiaryFormProps = InjectedFormProps<any> & {
  onSubmit: (payload: any) => void;
};

class BeneficiaryFormComponent extends React.Component<BeneficiaryFormProps> {
  static suffixSource = [
    {
      description: 'None',
      name: '__NONE__',
    },
    ...getEnumEntries('NameSuffixEnum'),
  ];

  static dobValidations = [
    required,
    printableAsciiChars,
    date({
      checkAge: false,
    }),
  ];

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
        <Grid.Row>
          <Grid.Col xs={12}>
            <TextField
              name="firstName"
              label="First name"
              maxLength={FIELD_MAX_LENGTHS.FIRST_NAME}
              validate={[required, printableAsciiChars]}
            />
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col xs={8}>
            <TextField
              name="lastName"
              label="Last name"
              maxLength={FIELD_MAX_LENGTHS.LAST_NAME}
              validate={[required, printableAsciiChars]}
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <DropdownField
              name="suffix"
              label="Suffix"
              source={BeneficiaryFormComponent.suffixSource}
              parseToNull="__NONE__"
            />
          </Grid.Col>
        </Grid.Row>
        <FormSection name="address">
          <AddressFields kind="NORMAL" />
        </FormSection>

        <Grid.Row>
          <Grid.Col xs={4}>
            <DateOfBirthField
              validate={BeneficiaryFormComponent.dobValidations}
            />
          </Grid.Col>
          <Grid.Col xs={8}>
            <TextField
              name="relationship"
              label="Relationship"
              validate={[required]}
            />
          </Grid.Col>
        </Grid.Row>

        <Grid.Row />

        <Grid.Row xsStart>
          <Grid.Col xs={4}>
            <br />
            <Button label="Submit" kind="primary" size="large" type="submit" />
          </Grid.Col>
        </Grid.Row>
      </form>
    );
  }

  // @ts-expect-error - TS7006 - Parameter 'values' implicitly has an 'any' type.
  handleSubmit = (values): void => {
    this.props.onSubmit(
      merge({}, values, {
        address: {
          country: 'USA',
        },
      }),
    );
  };
}

export const BeneficiaryForm = connectForm({
  form: 'beneficiary',
})(BeneficiaryFormComponent);
