export const KNOW_YOUR_CUSTOMER_ACTIONS = Object.freeze({
  BEGIN_KNOW_YOUR_CUSTOMER_FLOW: 'BEGIN_KNOW_YOUR_CUSTOMER_FLOW' as const,
  FINISHED_KNOW_YOUR_CUSTOMER: 'FINISHED_KNOW_YOUR_CUSTOMER' as const,
});

export type BeginKnowYourCustomerFlow = {
  payload: {
    basePath: string;
  };
  type: typeof KNOW_YOUR_CUSTOMER_ACTIONS.BEGIN_KNOW_YOUR_CUSTOMER_FLOW;
};

export type FinishedKnowYourCustomer = {
  type: typeof KNOW_YOUR_CUSTOMER_ACTIONS.FINISHED_KNOW_YOUR_CUSTOMER;
};

export const beginKnowYourCustomerFlow = (
  payload: BeginKnowYourCustomerFlow['payload'],
): BeginKnowYourCustomerFlow => ({
  type: KNOW_YOUR_CUSTOMER_ACTIONS.BEGIN_KNOW_YOUR_CUSTOMER_FLOW,
  payload,
});

export const finishedKnowYourCustomer = (): FinishedKnowYourCustomer => ({
  type: KNOW_YOUR_CUSTOMER_ACTIONS.FINISHED_KNOW_YOUR_CUSTOMER,
});

export type KnowYourCustomerAction =
  | BeginKnowYourCustomerFlow
  | FinishedKnowYourCustomer;
