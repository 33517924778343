import {
  PXL,
  PS,
  Text,
  Image,
  css,
  styled,
  type TextProps,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

export type CellIconName =
  | 'queuePending32'
  | 'bell24'
  | 'hold24'
  | 'refresh24'
  | 'alert24';

const Root = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
`;

const Content = styled.div`
  flex: 0 1 auto;
  min-width: 0;
`;

const StyledImage = styled(Image)<{
  iconAlignSelf: string;
}>`
  flex: 0 0 32px;
  margin-right: 12px;
  align-self: ${({ iconAlignSelf }) => iconAlignSelf};
`;

const OverflowText = styled(Text)<{
  overflowEllipsis: boolean;
}>(({ overflowEllipsis }) =>
  overflowEllipsis
    ? css`
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `
    : ``,
);

export type CellProps = {
  className?: string;
  content?: TextProps['content'];
  icon?: React.ReactNode;
  // Prefer iconName where possible.
  iconAlignSelf?: 'auto' | 'flex-start' | 'flex-end' | 'center' | 'baseline';
  iconName?: CellIconName;
  overflowEllipsis?: boolean;
  subtitle?: TextProps['content'];
  // Deprecated. Use content instead.
  title?: TextProps['content'] | null | undefined;
  titleFont?: 'PL' | 'PXL' | 'PM';
  titleWeight?: 400 | 600;
};

export const Cell = ({
  content,
  title,
  subtitle,
  iconAlignSelf = 'center',
  titleFont = 'PL',
  titleWeight = 400,
  iconName,
  icon,
  overflowEllipsis = false,
  className,
}: CellProps) => {
  const renderIcon = () => {
    if (!iconName && !icon) {
      throw new Error('Must provide either icon or iconName to Cell.');
    }
    if (iconName) {
      return <Icon alignSelf={iconAlignSelf} mr={12} name={iconName} />;
    } else if (typeof icon === 'string') {
      // @ts-expect-error - TS2769 - No overload matches this call.
      return <StyledImage iconAlignSelf={iconAlignSelf} src={icon} />;
    }

    const props = {
      style: {
        alignSelf: iconAlignSelf,
        marginRight: '12px',
        flex: '0 0 32px',
      },
    };
    // @ts-expect-error - TS2769 - No overload matches this call.
    return React.cloneElement(icon, props);
  };

  const textContent = content || subtitle;
  return (
    <Root className={className}>
      {renderIcon()}
      <Content>
        {title && (
          <OverflowText
            as={PXL}
            color="foregroundNeutralMain"
            content={title}
            font={titleFont}
            fontWeight={titleWeight}
            overflowEllipsis={overflowEllipsis}
          />
        )}
        {textContent && (
          <OverflowText
            as={PS}
            color="foregroundNeutralSecondary"
            content={textContent}
            font="PS"
            mt={2}
            overflowEllipsis={overflowEllipsis}
          />
        )}
      </Content>
    </Root>
  );
};
