import * as React from 'react';
import { Route } from 'react-router-dom';

import { ResearchModelPortfolioCategoryDetailsRoute } from './category-details';
import { ModelPortfolioDetailsRoute } from './details';
import { ModelPortfolioPage } from './ModelPortfolioPage';

export const ModelPortfolioCategoriesRoute = () => {
  return (
    <>
      <Route path="model-portfolios">
        {ResearchModelPortfolioCategoryDetailsRoute()}
        {ModelPortfolioDetailsRoute()}
        <Route index element={<ModelPortfolioPage />} />
      </Route>
    </>
  );
};
