import { Box, Flex, styled, Card, CardProps } from '@m1/liquid-react';
import { Icon, type AllIconName } from '@m1/liquid-react/icons';
import * as React from 'react';

import { TransferParticipantCell } from '~/components/transfers/TransferParticipantCell';
import { TransferInstanceStepCardFragment } from '~/graphql/types';

import { TransferInstanceStepCell } from './TransferInstanceStepCell';

const StyledStepParticipant = styled(Flex)`
  &:not(:first-child) {
    margin-top: 2px;
  }

  &:not(:last-child) {
    margin-bottom: 2px;
  }
`;

type TransferInstanceStepCard = NonNullable<
  TransferInstanceStepCardFragment['steps']
>[number];

const readStepArrow = (step: TransferInstanceStepCard) => {
  let name = 'queuePending32';
  if (step.status.isEnded) {
    step.status.isCompleted
      ? (name = 'queueSuccess32')
      : (name = 'queueFailed32');
  }

  return (
    <Box height={32} width={32}>
      <Icon
        style={{
          transform: 'rotate(0.25turn)',
        }}
        position="absolute"
        name={name as AllIconName}
      />
    </Box>
  );
};

export type TransferInstanceStepsCardProps = CardProps & {
  transferInstanceSteps: TransferInstanceStepCardFragment;
};

export const TransferInstanceStepsCard = ({
  transferInstanceSteps,
  ...rest
}: TransferInstanceStepsCardProps) => {
  const { from, steps } = transferInstanceSteps;

  if (!from || !steps || steps.some((step) => !step.to)) {
    return null;
  }

  return (
    <Card p={16} {...rest}>
      <StyledStepParticipant alignItems="center">
        <TransferParticipantCell transferParticipant={from} />
      </StyledStepParticipant>
      {steps.map((step) => (
        <React.Fragment key={step.id}>
          {readStepArrow(step)}
          <StyledStepParticipant alignItems="center">
            <TransferInstanceStepCell transferInstanceStep={step} />
          </StyledStepParticipant>
        </React.Fragment>
      ))}
    </Card>
  );
};
