import { VERIFY_EMAIL_BANNER_ACTIONS } from '../actions/verifyEmailBannerActions';

export type VerifyEmailBannerState = {
  refreshing: boolean;
  resending: boolean;
};

const initialState: VerifyEmailBannerState = {
  resending: false,
  refreshing: false,
};

export const verifyEmailBannerReducer = (
  state: VerifyEmailBannerState = initialState,
  action: any,
): VerifyEmailBannerState => {
  switch (action.type) {
    case VERIFY_EMAIL_BANNER_ACTIONS.RESEND_EMAIL_VERIFICATION:
      return {
        ...state,
        resending: true,
      };
    case VERIFY_EMAIL_BANNER_ACTIONS.REFRESH_EMAIL_VERIFICATION_STATUS:
      return {
        ...state,
        refreshing: true,
      };
    case VERIFY_EMAIL_BANNER_ACTIONS.RESET_VERIFY_EMAIL_BANNER:
      return initialState;
    default:
      return state;
  }
};
