import { Flex, styled } from '@m1/liquid-react';
import * as React from 'react';

export type ProgressDotProps = {
  /**
   * The total number of carousel items
   */
  total: number;
  /**
   * The index of the selected carousel item
   */
  selected: number;
};

const StyledDot = styled.div<{
  $selected: boolean;
}>`
  width: 7px;
  height: 7px;

  background-color: ${(props) =>
    props.$selected
      ? props.theme.colors.foregroundNeutralSecondary
      : props.theme.colors.foregroundNeutralTertiary};
  border-radius: 50%;
  margin: 0 4px;
`;

export const ProgressDots = ({ total, selected }: ProgressDotProps) => {
  const progressDots = [];
  for (let i = 0; i < total; i++) {
    progressDots.push(<StyledDot $selected={selected === i} key={i} />);
  }

  return (
    <Flex justifyContent="center" mt={8}>
      {progressDots.map((dot) => dot)}
    </Flex>
  );
};
