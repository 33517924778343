import * as React from 'react';

import { toggleSmartTransferHelper } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';
import { ToggleHelper } from '~/toolbox/helper';

export const SmartTransferToggle = () => {
  const dispatch = useDispatch();
  const isHelperOn = useSelector<boolean>(
    (state) => state.newFlows.CREATE_SMART_TRANSFER.isHelperOn,
  );
  const { addToast } = useToast();
  const [helperPillOpen, setHelperPillOpen] = React.useState(false);
  const handleToggleHelper = () => {
    setHelperPillOpen(!helperPillOpen);
    dispatch(toggleSmartTransferHelper());
    addToast({
      content: isHelperOn ? 'Helper switched off.' : 'Helper switched on.',
      kind: 'success',
      duration: 'short',
    });
  };

  React.useEffect(() => {
    if (helperPillOpen) {
      window.setTimeout(() => {
        setHelperPillOpen(!helperPillOpen);
      }, 2000);
    }
  }, [helperPillOpen]);

  return (
    <ToggleHelper
      handleToggleHelper={handleToggleHelper}
      isHelperOn={isHelperOn}
      helperPillOpen={helperPillOpen}
    />
  );
};
