import { styled, Flex, Box } from '@m1/liquid-react';

import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';

import { Container } from '~/toolbox/container';

export const StyledApplicationRightCardContainer = styled(Container)`
  display: flex;
  max-width: 600px;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media screen and ${RESPONSIVE_BREAKPOINTS.SMALL} {
    padding-top: 40px;
  }
`;

export const StyledApplicationLeftCardContainer = styled(Container)`
  max-width: 600px;
  width: 100%;
`;

export const StyledApplicationCenteredCardContainer = styled(Container)`
  max-width: 600px;
`;

export const StyledApplicationTopCardContainer = styled(Flex)`
  width: 100%;
  max-width: 1200px;
  padding-bottom: 24px;
  position: relative;

  @media screen and ${RESPONSIVE_BREAKPOINTS.SMALL} {
    flex-wrap: wrap;
  }
`;

export const StyledApplicationBottomCardContainer = styled(Box)`
  width: 100%;
  max-width: 1200px;
  padding-top: 24px;
  margin-left: 10px;
  border-top: 1px solid;
  border-color: ${({ theme }) => theme.colors.borderMain};
`;
