import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

// TODO: remove once we stop supporting the old spend deep links for HYSA/savings
export const SavingsRedirectRoute = () => {
  return (
    <Route path="savings">
      <Route
        index
        element={<Navigate to="/d/save/savings/transactions" replace />}
      />

      <Route path="transactions">
        <Route
          index
          element={<Navigate to="/d/save/savings/transactions" replace />}
        />
      </Route>

      <Route path="account">
        <Route
          index
          element={<Navigate to="/d/save/savings/account" replace />}
        />
      </Route>
    </Route>
  );
};
