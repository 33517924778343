import { styled, Box, theme, css } from '@m1/liquid-react';

import { CONTENT_PADDING } from '~/hooks/useLayout';

export const StyledPageContainer = styled(Box)<{ contentWidth?: number }>`
  ${({ contentWidth }) => {
    if (contentWidth) {
      return `
        max-width: ${contentWidth}px;
      `;
    }

    return ``;
  }}

  width: 100%;
`;

export const StyledPageContent = styled(Box)<{
  contentWidth?: number;
  withoutBottomPadding?: boolean;
}>`
  ${({ contentWidth }) => {
    if (contentWidth) {
      return `
        max-width: ${contentWidth}px;
      `;
    }

    return ``;
  }}

  width: 100%;
  padding: 32px 16px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: ${theme.breakpoints.XSMALL}) {
    padding: 0 ${CONTENT_PADDING}px 64px;
  }

  & {
    ${({ withoutBottomPadding }) =>
      withoutBottomPadding
        ? css`
            padding-bottom: 0;
          `
        : null};
  }
`;

export const StyledHeading = styled(Box)`
  padding: 16px 19px 8px 62px;
`;

export const StyledHeadingLabel = styled(Box)`
  font-size: 11px;
  font-weight: 400 !important;
  color: ${({ theme }) => theme.colors.foregroundNeutralSecondary};
  padding-bottom: 8px;
  border-bottom: ${({ theme }) => `solid 1px ${theme.colors.grey04}`};
`;

export const StyledBorder = styled(Box)`
  height: 1px;
  min-height: 1px;
  border-top: ${({ theme }) => `solid 1px ${theme.colors.grey04}`};
  margin: 8px 19px 8px 62px;
`;
