import * as React from 'react';

import { HomePageQuery } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { FundingSourceBankConnector } from '~/pages/dashboard/transfers/components';

type HomePageQueryViewer = HomePageQuery['viewer'];

type HomeHeaderBankConnectorProps = {
  save: HomePageQueryViewer['save'] | null | undefined;
  transfers: HomePageQueryViewer['transfers'] | null | undefined;
};

export const HomeHeaderBankConnector = ({
  save,
  transfers,
}: HomeHeaderBankConnectorProps) => {
  const analytics = useAnalytics();
  const onClick = () => {
    analytics.recordEvent('connected_bank_clicked');
  };
  if (transfers?.activeFundingSource) {
    return (
      <FundingSourceBankConnector
        externalName={transfers.activeFundingSource.externalName}
        type="fs_standard"
        onClick={onClick}
      />
    );
  }

  if (save?.savings?.hasSavingsAccounts) {
    return <FundingSourceBankConnector type="savings" onClick={onClick} />;
  }

  return null;
};
