import * as React from 'react';

import { useSelector } from '~/redux/hooks';
import { APP_MODES } from '~/static-constants';
import { Pill } from '~/toolbox/Pill';

import { BreadCrumb } from './BreadCrumb';

type UserPieBreadCrumbsProps = {
  navigable: boolean;
};

export const UserPieBreadCrumbs = ({ navigable }: UserPieBreadCrumbsProps) => {
  const mode = useSelector((state) => state.mode);
  const toLink =
    mode === APP_MODES.ADD ? '/d/c/add-slices/my-pies' : '/d/research/my-pies';

  return (
    <Pill.Group>
      <BreadCrumb
        label="My Pies"
        {...(navigable && { linkProps: { to: toLink } })}
      />
    </Pill.Group>
  );
};
