import * as React from 'react';
import { Route } from 'react-router-dom';

import { PersonalLoansApplicationPage } from './PersonalLoansApplicationPage';

export const PersonalLoansApplicationRoute = () => {
  return (
    <Route
      path="loan-application/:step?"
      handle={{
        navigationState: {
          forceRefetch: true,
        },
        fallbackRoute: {
          to: 'landing-page',
        },
      }}
      element={<PersonalLoansApplicationPage />}
    />
  );
};
