import { styled, Box } from '@m1/liquid-react';

import { Radio } from '~/toolbox/radio';

export const StyledFilters = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  > * {
    width: 100%;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--colors-border-main);

    &:last-child {
      border-bottom-color: transparent;
    }
  }
`;

export const StyledFilterLabelContainer = styled(Box)`
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const StyledRadioButton = styled(Radio.Choice as any)`
  font-size: 1.2rem;

  input {
    min-width: 14px;
    height: 14px;
    margin-right: 8px;
  }

  &:hover {
    color: var(--colors-foreground-neutral-tertiary);
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const StyledFiltersContainer = styled(Box)`
  position: absolute;
  top: 0;
  background-color: ${({ theme }) => theme.colors.backgroundNeutralSecondary};
  border-left: 1px solid ${({ theme }) => theme.colors.borderMain};
  width: 300px;
  min-height: 100%;
  z-index: 2;
  padding: 16px;
  transition: all 0.3s ease-out;
`;
