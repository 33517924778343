import { Box, Flex, HXS, PL, PM, PS, Card } from '@m1/liquid-react';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { formValueSelector } from 'redux-form';

import { PortfolioOrderForm } from '~/forms';
import {
  useMarginBorrowTextQueryLazyQuery,
  useSetupOrderStepQuery,
} from '~/graphql/hooks';
import {
  Security,
  SetupOrderStepPortfolioSliceFragment,
  AppTooltipFragment,
} from '~/graphql/types';
import { AppTooltip } from '~/lens-toolbox/AppTooltip';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';
import { CashFormatter } from '~/utils';

import { DisclosureLink } from '../components/DisclosureLink';

import { OrderDirectionToggle } from '../components/OrderDirectionToggle';
import { PortfolioOrderSubmitButton } from '../components/PortfolioOrderSubmitButton';
import { calculateMonthlyInterest } from '../utils/calculateMonthlyInterest';
import { toFixedPointNumber } from '../utils/toFixedPointNumber';

type SetupOrderValue = {
  amount: number;
};

const formSelector = formValueSelector('portfolio-order');

type SetupOrderStepProps = {
  onFinishStep: (...args: Array<any>) => any;
};

const LineItem = ({
  label,
  value,
  tooltip,
}: {
  label: Maybe<string>;
  value: Maybe<string>;
  tooltip?: Maybe<AppTooltipFragment>;
}) =>
  value ? (
    <Flex alignItems="center" justifyContent="space-between">
      <PM display="inline-flex">
        {label} {tooltip && <AppTooltip appTooltip={tooltip} />}
      </PM>
      <PM fontWeight={600} content={value} />
    </Flex>
  ) : null;

export const SetupOrderStep = ({ onFinishStep }: SetupOrderStepProps) => {
  const formState = useSelector((state) => ({
    cashFlow: formSelector(state, 'cashFlow'),
    direction: state.newFlows.SET_ORDER.direction,
    amountInput: get(state.form, 'portfolio-order.values.amount', 0),
    input: state.newFlows.SET_ORDER.input,
    hasValidTransactionInput:
      isEmpty(get(state.form, 'portfolio-order.syncErrors', {})) ?? false,
    hasAnyTransactionInput:
      get(state.form, 'portfolio-order.values.amount', false) ?? false,
  }));

  const [borrowedAmountText, setBorrowedAmountText] = React.useState<
    string | null
  >(null);

  const portfolioSliceId = useSelector(
    (state) => state.newFlows.SET_ORDER.portfolioSliceId,
  );

  const { data, loading, refetch } = useSetupOrderStepQuery({
    skip: !portfolioSliceId,
    variables: {
      sellAmount: 0,
      portfolioSliceId: portfolioSliceId as string,
    },
  });

  const [getMarginBorrowedText, { data: borrowAmountText }] =
    useMarginBorrowTextQueryLazyQuery();

  const portfolioSlice = data?.node as
    | SetupOrderStepPortfolioSliceFragment
    | null
    | undefined;

  React.useEffect(() => {
    if (portfolioSliceId) {
      refetch({
        portfolioSliceId,
        sellAmount: portfolioSlice?.value?.total
          ? portfolioSlice.value.total
          : 0,
      });
    }
  }, [portfolioSlice?.value?.total, refetch, portfolioSliceId]);

  React.useEffect(() => {
    if (borrowAmountText?.node && 'buyingPower' in borrowAmountText.node) {
      setBorrowedAmountText(
        borrowAmountText?.node.buyingPower.marginBorrowingText,
      );
    } else {
      setBorrowedAmountText(null);
    }
  }, [borrowAmountText?.node]);

  const borrowedAmount = portfolioSlice?.account.isCryptoAccount
    ? 0
    : Math.max(
        0,
        (formState.amountInput -
          (portfolioSlice?.manualOrderBreakdown?.cashBuyingPower
            ?.numericValue ?? 0)) *
          Number(formState.direction === 'buy'),
      );

  React.useEffect(() => {
    if (borrowedAmount > 0) {
      // only fetch borrow text if there there is
      getMarginBorrowedText({
        variables: {
          portfolioSliceId: portfolioSliceId as string,
          borrowAmount: borrowedAmount,
        },
      });
      // setBorrowedAmount(borrowedAmount);
    } else {
      // setBorrowedAmount(0);
      setBorrowedAmountText(null);
    }
  }, [borrowedAmount]);

  if (loading) {
    return <Spinner />;
  }

  const isCryptoAccount = portfolioSlice?.account.isCryptoAccount;
  const ratePercent = portfolioSlice?.account.borrowAccount?.rate?.ratePercent;
  const {
    slice,
    cashBuyingPower,
    marginBuyingPower,
    totalBuyingPower,
    tradeWindow,
  } = portfolioSlice?.manualOrderBreakdown ?? {};

  if (!portfolioSlice) {
    return null;
  }

  const readMaintenanceEquityRequirementReductionBySelling = ():
    | number
    | null
    | undefined => {
    const allocatedPositions =
      portfolioSlice.investments?.allocatedPositions.edges;
    if (allocatedPositions?.length === 1) {
      const allocatedPosition = allocatedPositions[0]?.node;
      if (allocatedPosition && allocatedPosition.position.marginability) {
        return allocatedPosition.position.marginability
          .maintenanceEquityRequirementReductionBySelling;
      }
    }

    return null;
  };

  const isSliceTradable =
    (portfolioSlice.to as Security)?.status !== 'DELISTED' &&
    (portfolioSlice.to as Security)?.status !== 'INACTIVE';
  const currentValue = portfolioSlice.value?.total ?? 0;
  const isOrderBlocked =
    (!isSliceTradable && formState.direction === 'buy') ||
    (formState.direction === 'sell' && currentValue <= 0);
  const isMarginAccount = Boolean(portfolioSlice.account.borrowAccount);
  const hasOpenMaintenanceCall =
    portfolioSlice.account.borrowAccount?.status?.hasOpenMaintenanceCall ??
    false;
  const maintenanceEquityRequirementReductionBySelling =
    readMaintenanceEquityRequirementReductionBySelling();
  const showTransactionError =
    formState.hasAnyTransactionInput && !formState.hasValidTransactionInput;
  const isAvailableForOrdersDisabled =
    formState.direction === 'sell' &&
    portfolioSlice.account.maxCashThreshold === 0;
  const monthlyInterest = calculateMonthlyInterest(ratePercent, borrowedAmount);

  const handleSubmit = (values: SetupOrderValue): void => {
    onFinishStep({
      cashFlow:
        formState.direction === 'buy'
          ? toFixedPointNumber(Number(values.amount - borrowedAmount), 2)
          : toFixedPointNumber(-Number(values.amount), 2),
      direction: formState.direction,
      marginCashFlow: toFixedPointNumber(borrowedAmount, 2),
      tradeWindow:
        portfolioSlice.account.trading?.nextWindowForAccount ?? 'MORNING',
    });
  };

  return (
    <Box width={490}>
      <HXS>Trade {slice?.label}</HXS>
      <Card mt={40}>
        <Box p="24px 32px 64px 32px">
          <Box textAlign="center">
            <OrderDirectionToggle />
          </Box>
          <Box mt={48}>
            <PortfolioOrderForm
              currentValue={currentValue}
              direction={formState.direction}
              maxCashThreshold={portfolioSlice.account.maxCashThreshold}
              isCrypto={Boolean(isCryptoAccount)}
              isMarginAccount={isMarginAccount}
              isOrderBlocked={isOrderBlocked}
              onSubmit={handleSubmit}
              showInputError={showTransactionError}
              totalBuyingPower={
                isCryptoAccount
                  ? cashBuyingPower?.numericValue
                  : totalBuyingPower?.numericValue
              }
              borrowedAmountText={borrowedAmountText}
              ratePercent={ratePercent}
            >
              {!isSliceTradable && formState.direction === 'buy' ? (
                <Card
                  borderColor="transparent"
                  backgroundColor="backgroundWarningSubtle"
                  padding={16}
                >
                  <PL fontWeight={600}>You can't buy this asset</PL>
                  <PL fontWeight={400}>
                    This is a delisted holding. You can sell it, but you can’t
                    buy more.
                  </PL>
                </Card>
              ) : null}
              {hasOpenMaintenanceCall &&
                formState.direction === 'sell' &&
                typeof maintenanceEquityRequirementReductionBySelling ===
                  'number' && (
                  <Card
                    borderColor="transparent"
                    backgroundColor="backgroundNeutralTertiary"
                    mt={16}
                  >
                    <PS
                      content={
                        <>
                          Selling the entire value of{' '}
                          <b>{CashFormatter.format(currentValue)}</b> and paying
                          it to your margin call will reduce your call by{' '}
                          <b>
                            {CashFormatter.format(
                              maintenanceEquityRequirementReductionBySelling,
                            )}
                          </b>
                          .
                        </>
                      }
                      mx={16}
                      my={12}
                    />
                  </Card>
                )}
              <Flex
                flexDirection="column"
                gap={8}
                style={{
                  opacity: isAvailableForOrdersDisabled ? 0.5 : 1,
                }}
              >
                <LineItem
                  label={slice?.label}
                  value={slice?.labelValue}
                  tooltip={slice?.tooltip}
                />
                {formState.direction === 'buy' && (
                  <>
                    <LineItem
                      label={cashBuyingPower?.label}
                      value={cashBuyingPower?.labelValue}
                      tooltip={cashBuyingPower?.tooltip}
                    />
                    <LineItem
                      label={marginBuyingPower?.label}
                      value={marginBuyingPower?.labelValue}
                      tooltip={marginBuyingPower?.tooltip}
                    />
                    {typeof monthlyInterest === 'number' && (
                      <LineItem
                        label="Estimated monthly margin interest"
                        value={CashFormatter.format(monthlyInterest)}
                      />
                    )}
                  </>
                )}
                <LineItem
                  label={tradeWindow?.label}
                  value={tradeWindow?.labelValue}
                  tooltip={tradeWindow?.tooltip}
                />
              </Flex>
              <Box mt={48}>
                <DisclosureLink
                  direction={formState.direction}
                  isCrypto={portfolioSlice.account.isCryptoAccount ?? false}
                />
              </Box>
              <Box mt={32} textAlign="center">
                <PortfolioOrderSubmitButton
                  disabled={isOrderBlocked}
                  label={
                    isMarginAccount && borrowedAmount
                      ? `Borrow and buy`
                      : `Confirm ${formState.direction}`
                  }
                  direction={formState.direction}
                />
              </Box>
            </PortfolioOrderForm>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};
