import { AppAction } from '~/redux/actions';
import { ToastProps } from '~/toolbox/toast';

export type ToastState = {
  toast: ToastProps | null | undefined;
};

const initialState: ToastState = {
  toast: null,
};

export const toastsReducer = (
  state: ToastState = initialState,
  action: AppAction,
): ToastState => {
  switch (action.type) {
    case 'ADD_TOAST':
      return {
        ...state,
        toast: action.payload,
      };
    case 'CLEAR_TOAST':
      return initialState;
    default:
      return state;
  }
};
