import { styled, Box } from '@m1/liquid-react';
import * as React from 'react';

import { SliceableNameCell } from '~/components/pie';
import { SliceableCellFragment } from '~/graphql/types';

const StyledResult = styled(Box)<{
  children: React.ReactNode;
  onClick: (...args: Array<any>) => any;
}>`
  padding: 10px 13px;
  background-color: ${(props) => props.theme.colors.backgroundNeutralSecondary};

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.borderMain};
  }

  &:hover {
    background: ${(props) => props.theme.colors.backgroundNeutralMain};
    cursor: pointer;
  }
`;

type FilterBySymbolResultProps = {
  onClick: (symbol: string) => void;
  security: Extract<SliceableCellFragment, { symbol: string }>;
};

export const FilterBySymbolResult = ({
  onClick,
  security,
}: FilterBySymbolResultProps) => {
  const handleClick = (): void => {
    onClick(security.symbol);
  };

  return (
    <StyledResult onClick={handleClick}>
      <SliceableNameCell sliceable={security} />
    </StyledResult>
  );
};
