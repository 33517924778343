import { Flex } from '@m1/liquid-react';
import * as React from 'react';

export const EarnDirectDepositManualSetup = () => {
  return (
    <Flex>
      Manual Setup
      <div>
        <p>Option 1</p>
        <p>Option 2</p>
        <p>Option 3</p>
      </div>
    </Flex>
  );
};
