import { Banner, HXS, PL, PXL } from '@m1/liquid-react';
import * as React from 'react';

import { ExternalLink } from '~/components/ExternalLink';
import { GenericSystemError } from '~/components/GenericSystemError';
import { useCustodialBeneficiaryInfoQuery } from '~/graphql/hooks';
import { useSupportLink } from '~/hooks/useSupportLink';
import { useSelector } from '~/redux/hooks';
import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

import { CustodialBeneficiaryNode } from '../types';

import { StyledInformationContainer } from './CustodialBeneficiary.styled';
import { CustodialBeneficiaryAddressFieldsContainer } from './CustodialBeneficiaryAddressFieldsContainer';
import { CustodialBeneficiaryForm } from './CustodialBeneficiaryForm';

export const CustodialBeneficiaryPage = () => {
  const accountId = useSelector<string | null | undefined>(
    (state) => state.global.investAccountId,
  );
  const supportLink = useSupportLink();

  const { data, loading } = useCustodialBeneficiaryInfoQuery({
    variables: {
      accountId: accountId as string,
    },
    skip: !accountId,
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  const account = data?.node as CustodialBeneficiaryNode | null | undefined;

  if (!account?.custodialAccountBeneficiary) {
    return <GenericSystemError />;
  }

  return (
    <Container css="max-width: 660px" padded text marginTop={50}>
      <HXS mb={16} content="Custodial Beneficiary" />
      <Banner
        content="Contact Support to make changes to personal information"
        kind="information"
        size="inline"
        action={<Link {...supportLink}>Contact Support</Link>}
      />
      <PXL fontWeight={600} mt={16} mb={16} content="Personal Information" />
      <CustodialBeneficiaryAddressFieldsContainer
        custodialAccountBeneficiary={account.custodialAccountBeneficiary}
      />
      <CustodialBeneficiaryForm
        custodialAccountBeneficiary={account.custodialAccountBeneficiary}
      />
      <StyledInformationContainer p={16} mt={16} mb={16} my={48}>
        <PXL fontWeight={600} content="Custodial Account Type" mb={16} />
        <PL content={account.custodialAccountDescription ?? '--'} mb={16} />
        <ExternalLink
          label="Learn more about UTMA and UGMA"
          destination="SUPPORT_CUSTODIAL_ACCOUNT"
        />
      </StyledInformationContainer>
    </Container>
  );
};
