export const BENEFICIARY_ACTIONS = Object.freeze({
  PREPARE_BENEFICIARIES: 'PREPARE_BENEFICIARIES' as const,
  CLEANUP_BENEFICIARIES: 'CLEANUP_BENEFICIARIES' as const,
  UPDATE_BENEFICIARY: 'UPDATE_BENEFICIARY' as const,
  ADD_BENEFICIARY: 'ADD_BENEFICIARY' as const,
});

// TODO: type these payload
export type PrepareBeneficiariesAction = {
  payload: unknown;
  type: typeof BENEFICIARY_ACTIONS.PREPARE_BENEFICIARIES;
};

export type CleanupBeneficiariesAction = {
  payload: unknown;
  type: typeof BENEFICIARY_ACTIONS.CLEANUP_BENEFICIARIES;
};

export type AddBeneficiaryAction = {
  payload: unknown;
  type: typeof BENEFICIARY_ACTIONS.ADD_BENEFICIARY;
};

export type UpdateBeneficiaryAction = {
  payload: unknown;
  type: typeof BENEFICIARY_ACTIONS.UPDATE_BENEFICIARY;
};

export type BeneficiaryAction =
  | PrepareBeneficiariesAction
  | CleanupBeneficiariesAction
  | AddBeneficiaryAction
  | UpdateBeneficiaryAction;
