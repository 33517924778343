import { makeVar } from '@apollo/client';

import { CreatePieShareUrlMutation } from '~/graphql/types';

export const pieShareUrlData = makeVar<
  Record<string, CreatePieShareUrlMutation>
>({});

export const setPieShareUrlData = (
  pieId: string,
  data: CreatePieShareUrlMutation,
) => {
  pieShareUrlData({ ...pieShareUrlData(), [pieId]: data });
};

export const clearPieShareUrlData = (pieId: string) => {
  const { [pieId]: oldValue, ...rest } = pieShareUrlData();
  pieShareUrlData(rest);
};

export const readPieShareUrlData = (pieId: string) => pieShareUrlData()[pieId];
