import { HotButton } from '@m1/liquid-react';
import capitalize from 'lodash-es/capitalize';
import * as React from 'react';

import {
  PortfolioDetailsSliceQuery,
  PortfolioSliceButtonFragment,
  Security,
} from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { clickedSetOrder } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

export type PortfolioSliceOrderButtonProps = {
  account: PortfolioSliceButtonFragment;
  portfolioSlice: Extract<
    PortfolioDetailsSliceQuery['portfolioSlice'],
    { id: string }
  >;
  type: 'BUY' | 'SELL';
};

export const PortfolioSliceOrderButton = ({
  account,
  portfolioSlice,
  type,
}: PortfolioSliceOrderButtonProps) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  const handleClick = React.useCallback(() => {
    analytics.recordEvent('m1_invest_managepie_element_clicked', null, {
      'click_id': type.toLowerCase(),
    });
    dispatch(
      clickedSetOrder({
        portfolioSliceId: portfolioSlice.id,
        setAsSellOrder: type === 'SELL',
      }),
    );
  }, [dispatch, portfolioSlice]);

  const hasPendingOrder = Boolean(portfolioSlice.orderStatus?.isSetOnSelf);
  const canTradeSliceable =
    (portfolioSlice.to as Security)?.status !== 'INACTIVE';
  const isDisabled = hasPendingOrder || !account.isFunded || !canTradeSliceable;

  return (
    <HotButton
      data-testid={`portfolio-slice-${type.toLowerCase()}-button`}
      disabled={isDisabled}
      label={capitalize(type)}
      onClick={handleClick}
      size="small"
      icon={type === 'BUY' ? 'add' : 'subtract'}
    />
  );
};
