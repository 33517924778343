import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { dismissNotifications } from '~/redux/actions';
import { NOTIFICATION_BANNER_TIMEOUT } from '~/static-constants';
import { delay } from '~/utils';

export function* dismissNotificationsAfterDelay(
  timeout: number = NOTIFICATION_BANNER_TIMEOUT,
): SagaIterator<void> {
  yield call(delay, timeout);
  yield put(dismissNotifications());
}
