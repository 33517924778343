import { Color } from '@m1/liquid-react';

import {
  AppPillKind,
  CommonTransactionAmount,
  CommonTransactionStatusEnum,
} from '~/graphql/types';

export const getTextColorForAmount = (
  amount?: CommonTransactionAmount | null,
) => {
  if (amount?.color) {
    return amount?.color as Color;
  }
  return 'foregroundNeutralMain';
};

export type CommonTransactionStatusClientEnum =
  | 'Created'
  | 'Pending'
  | 'Canceled'
  | 'Failed'
  | 'Complete';

export const mapCommonTransactionStatusEnum = (
  status: CommonTransactionStatusClientEnum,
): CommonTransactionStatusEnum | null => {
  switch (status) {
    case 'Created':
      return CommonTransactionStatusEnum.Created;
    case 'Pending':
      return CommonTransactionStatusEnum.Pending;
    case 'Canceled':
      return CommonTransactionStatusEnum.Canceled;
    case 'Failed':
      return CommonTransactionStatusEnum.Failed;
    case 'Complete':
      return CommonTransactionStatusEnum.Complete;
    default:
      return null;
  }
};

export const mapToPillKind = (kind: AppPillKind | null | undefined) => {
  switch (kind) {
    case 'CAUTION':
      return 'warning';
    case 'DANGER':
      return 'danger';
    case 'SUCCESS':
      return 'success';
    default:
      return 'neutral';
  }
};

export const formatContentDescription = (content?: string | null) => {
  if (!content) {
    return '';
  }

  // cut off the content at the first comma
  const commaIndex = content?.indexOf(',');
  const adjustedContent =
    commaIndex && commaIndex > -1 ? content?.substring(0, commaIndex) : content;

  // truncate content if it is too long
  const truncatedContent =
    adjustedContent && adjustedContent.length > 32
      ? `${adjustedContent.substring(0, 32)}...`
      : adjustedContent;

  return truncatedContent;
};

export const isCommonTransactionStatusEnum = (
  header: string,
): header is CommonTransactionStatusClientEnum => {
  const validEnumValues = [
    'Created',
    'Pending',
    'Canceled',
    'Failed',
    'Complete',
  ];

  return validEnumValues.includes(header);
};
