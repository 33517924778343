import { Icon } from '@m1/liquid-react/icons';
import React from 'react';

import { AcatInstanceStatus } from '../types';

type TransferArrowProps = {
  status: AcatInstanceStatus | null | undefined;
};

export const TransferArrow = ({ status }: TransferArrowProps) => {
  if (status?.isCompleted) {
    return (
      <Icon
        name="queueSuccess32"
        style={{
          display: 'block',
          transform: 'rotate(0.25turn)',
        }}
      />
    );
  } else if (status?.isFailed) {
    return (
      <Icon
        name="queueFailed32"
        color="critical"
        style={{
          display: 'block',
          transform: 'rotate(0.25turn)',
        }}
      />
    );
  }
  return (
    <Icon
      name="queuePending32"
      style={{
        display: 'block',
        transform: 'rotate(0.25turn)',
      }}
    />
  );
};
