import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useSavePageQuery } from '~/graphql/hooks';
import { enteredPage, exitedPage } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { SaveRedirect } from './SaveRedirect';

export const SavePage = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(enteredPage('SPEND_OVERVIEW', undefined));
    return () => {
      dispatch(exitedPage('SPEND_OVERVIEW', undefined));
    };
  }, []);

  const { data, loading } = useSavePageQuery();

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.viewer) {
    return <GenericSystemError />;
  }

  const savings = data?.viewer?.save?.savings;

  const hasSavingsAccounts =
    typeof savings?.hasSavingsAccounts === 'boolean'
      ? savings.hasSavingsAccounts
      : null;

  return (
    <SaveRedirect hasSavingsAccounts={Boolean(hasSavingsAccounts)}>
      <Outlet />
    </SaveRedirect>
  );
};
