import { Enhancer, withProps } from '~/hocs';
import { formatCurrencyWithCommas } from '~/utils/formatting';
import { normalizeCurrency } from '~/utils/normalizers';

import { TextField, TextFieldProps } from './text-field';

const enhancer: Enhancer<TextFieldProps, any> = withProps({
  name: 'amount',
  normalize: normalizeCurrency,
  format: formatCurrencyWithCommas,
  maskType: 'money',
  autoComplete: 'off',
  maxLength: 12,
});

export const TransferAmountField = enhancer(TextField) as any;
