import moment from 'moment';
import * as React from 'react';

import { AppContext } from '~/AppContext';
import { TransferInstanceNodeFragment } from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';

import { TransferInstanceAmountText } from './TransferInstanceAmountText';
import {
  DisplayFlags,
  TransferInstanceStatusPill,
} from './TransferInstanceStatusPill';
import { TransferParticipantCell } from './TransferParticipantCell';

type TransferInstanceDetailsSource = '/d/transfers' | '/d/home';

type TransferCompletedRowProps = {
  rowAnalytics?: string;
  transferInstance: TransferInstanceNodeFragment;
  routeToNavigateToOnFinish?: TransferInstanceDetailsSource;
  transferInstancePillDisplayFlags?: Partial<DisplayFlags>;
};

export const TransfersCompletedTableRow = ({
  transferInstance,
  rowAnalytics,
  routeToNavigateToOnFinish,
  transferInstancePillDisplayFlags,
}: TransferCompletedRowProps) => {
  const { analytics } = React.useContext(AppContext);

  return (
    <GridTable.NavigableRow
      id="transfers-completed-table-row"
      key={transferInstance.id}
      params={{
        transferInstanceId: transferInstance.id,
      }}
      query={{
        previousRouteName: routeToNavigateToOnFinish,
      }}
      onClick={() => {
        rowAnalytics && analytics.recordEvent(rowAnalytics);
      }}
      to="/d/c/transfer-details/:transferInstanceId"
    >
      <GridTable.Cell
        content={moment(transferInstance.startDate).format('ll')}
      />
      <GridTable.Cell
        content={
          transferInstance.from ? (
            <TransferParticipantCell
              transferParticipant={transferInstance.from}
            />
          ) : (
            '--'
          )
        }
      />
      <GridTable.Cell
        content={
          transferInstance.to ? (
            <TransferParticipantCell
              transferParticipant={transferInstance.to}
            />
          ) : (
            '--'
          )
        }
      />
      <GridTable.Cell
        content={
          <>
            <TransferInstanceStatusPill
              onlyShowIf={transferInstancePillDisplayFlags}
              transferInstance={transferInstance}
            />
            <TransferInstanceAmountText
              emphasizeFailureState
              ml={8}
              transferInstance={transferInstance}
            />
          </>
        }
        textAlign="right"
      />
    </GridTable.NavigableRow>
  );
};
