import { Box, SkeletonProvider } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { useAcatWizardRolloverOverviewQuery } from '~/graphql/hooks';
import { AppInfoPage } from '~/lens-toolbox/AppInfoPage';

import { useAcatWizardFormContext } from '../hooks/useAcatWizardFormContext';

export const AcatWizardRolloverOverviewStep = () => {
  const { data, loading } = useAcatWizardRolloverOverviewQuery();
  const { goTo } = useAcatWizardFormContext();

  if (!loading && !data) {
    return <GenericSystemError />;
  }

  return (
    <SkeletonProvider isLoading={loading}>
      <BackArrow
        onClick={() => goTo('select-transfer-type')}
        content="Back"
        mt={56}
      />
      <Box mt={-64}>
        <AppInfoPage
          containerProps={{ maxWidth: 792 }}
          appInfoPage={
            data?.viewer.invest?.acatWizard?.rolloverInformationScreenContent
          }
          onPrimaryButtonClick={() => goTo('rollover-initiate')}
        />
      </Box>
    </SkeletonProvider>
  );
};
