export const EVENT_ACTIONS = Object.freeze({
  CLICKED_ON_PORTFOLIO_LINK: 'CLICKED_ON_PORTFOLIO_LINK' as const,
  CLICKED_ON_PORTFOLIO_SLICE: 'CLICKED_ON_PORTFOLIO_SLICE' as const,
  CLICKED_ADD_TO_PORTFOLIO: 'CLICKED_ADD_TO_PORTFOLIO' as const,
  CLICKED_CANCEL_ORDER: 'CLICKED_CANCEL_ORDER' as const,
  CLICKED_ON_PIE_CATEGORY: 'CLICKED_ON_PIE_CATEGORY' as const,
  CLICKED_SET_ORDER: 'CLICKED_SET_ORDER' as const,
  CLICKED_ADD_SHARED_PIE: 'CLICKED_ADD_SHARED_PIE' as const,
  CLICKED_CONNECT_BANK: 'CLICKED_CONNECT_BANK' as const,
  CLICKED_VERIFY_BANK: 'CLICKED_VERIFY_BANK' as const,
  CONFIRM_CANCEL_ORDER: 'CONFIRM_CANCEL_ORDER' as const,
});

export type ClickedAddToPortfolioAction = {
  payload: string;
  type: typeof EVENT_ACTIONS.CLICKED_ADD_TO_PORTFOLIO;
};

export type ClickedOnPieCategoryAction = {
  payload: string;
  type: typeof EVENT_ACTIONS.CLICKED_ON_PIE_CATEGORY;
};

export type ClickedOnPortfolioSliceAction = {
  payload: string;
  type: typeof EVENT_ACTIONS.CLICKED_ON_PORTFOLIO_SLICE;
};

export type ClickedSetOrderAction = {
  payload: {
    isFromHoldingsPositionDetail?: boolean;
    portfolioSliceId: string;
    previousRouteName?: string | null | undefined;
    setAsSellOrder?: boolean;
  };
  type: typeof EVENT_ACTIONS.CLICKED_SET_ORDER;
};

export type ClickedAddSharedPieAction = {
  payload: {
    isCrypto?: boolean | null | undefined;
    shareToken: string;
    flow?: 'login' | 'signup';
    username?: string;
    password?: string;
    isPieActive?: boolean;
  };
  type: typeof EVENT_ACTIONS.CLICKED_ADD_SHARED_PIE;
};

// TODO: implement types for these actions payloads
export type ClickedCancelOrderAction = {
  payload: unknown;
  type: typeof EVENT_ACTIONS.CLICKED_CANCEL_ORDER;
};

export type ConfirmCancelOrderAction = {
  payload: unknown;
  type: typeof EVENT_ACTIONS.CONFIRM_CANCEL_ORDER;
};

export type ClickedConnectBankAction = {
  payload: unknown;
  type: typeof EVENT_ACTIONS.CLICKED_CONNECT_BANK;
};

export type ClickedVerifyBankAction = {
  payload: unknown;
  type: typeof EVENT_ACTIONS.CLICKED_VERIFY_BANK;
};

export type EventAction =
  | ClickedAddToPortfolioAction
  | ClickedCancelOrderAction
  | ClickedSetOrderAction
  | ClickedOnPieCategoryAction
  | ClickedOnPortfolioSliceAction
  | ClickedAddSharedPieAction
  | ConfirmCancelOrderAction
  | ClickedConnectBankAction
  | ClickedVerifyBankAction;
