import { Box, Flex } from '@m1/liquid-react';
import * as React from 'react';

import {
  // @ts-expect-error - TS2305 - Module '"~/graphql/types"' has no exported member 'IntradayQuotePeriodEnumValues'.
  IntradayQuotePeriodEnumValues,
  // @ts-expect-error - TS2305 - Module '"~/graphql/types"' has no exported member 'HistoricalQuotePeriodEnumValues'.
  HistoricalQuotePeriodEnumValues,
} from '~/graphql/types';
import { setSecurityQuotePeriod } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

import { Period } from './elements';

export type SecondaryPeriod = {
  label: string;
  value: IntradayQuotePeriodEnumValues | HistoricalQuotePeriodEnumValues;
};

export type SecondaryPeriodSelectorProps = {
  period: string;
  periods: Array<SecondaryPeriod>;
};

export const SecondaryPeriodSelector = ({
  period,
  periods,
}: SecondaryPeriodSelectorProps) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setSecurityQuotePeriod('LATEST_DAY'));
  }, []);

  return (
    <Flex borderRadius={16} inline padding={1}>
      {periods.map(({ value, label }, index) => (
        <Period
          $isActive={period === value}
          alignItems="center"
          justifyContent="center"
          key={`${value}-${index}`}
          onClick={() => dispatch(setSecurityQuotePeriod(value))}
        >
          <Box zIndex={1}>{label}</Box>
        </Period>
      ))}
    </Flex>
  );
};
