import { PXL, HXS, PM, Flex, ModalTitle } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

type ConfirmationHeaderProps = {
  accountName: string | null | undefined;
  pieName: string;
};

export const ConfirmationHeader = ({
  accountName,
  pieName,
}: ConfirmationHeaderProps) => (
  <Flex alignItems="center" flexDirection="column" mb={64} mt={16} width="100%">
    <ModalTitle>
      <HXS color="foregroundNeutralMain">Confirm Changes</HXS>
    </ModalTitle>
    {accountName && (
      <PM fontWeight={600} color="foregroundNeutralSecondary" mb={4}>
        {accountName}
      </PM>
    )}
    <Flex alignItems="center" mb={16}>
      <Icon name="rootPies24" />
      <PXL fontWeight={600} display="inline-flex" ml={8}>
        {pieName}
      </PXL>
    </Flex>
  </Flex>
);
