import * as React from 'react';
import { Route } from 'react-router-dom';

import { TransferInstanceDetailsPage } from './TransferInstanceDetailsPage';

export const TransferInstanceDetailsRoute = () => {
  return (
    <Route
      path="transfer-details/:transferInstanceId"
      element={<TransferInstanceDetailsPage />}
      handle={{
        fallbackRoute: {
          to: '/d/transfers',
        },
      }}
    />
  );
};
