import { Box, PL, Card } from '@m1/liquid-react';
import * as React from 'react';

import { useDisclosuresForPromotionsQuery } from '~/graphql/hooks';

import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

export const DisclosuresForPromotionsPage = () => {
  const { data, loading, error } = useDisclosuresForPromotionsQuery();

  if (loading) {
    return <Spinner />;
  }

  const agreements = data?.viewer.promotions?.promotionsAgreements;

  // If there are no agreements then this page will not be displayed in settings
  // See ProfileNavigation.tsx > hasPromotionalAgreements
  if (error || !agreements) {
    return (
      <PL content="Promotions disclosures are currently unavailable. Please try again later." />
    );
  }

  return (
    <Card p={32}>
      {agreements.documents.map((document) => {
        return (
          <Box key={`${document.title}`} mt={4}>
            <Link to={document.url} target="_blank">
              {document.title}
            </Link>
          </Box>
        );
      })}
    </Card>
  );
};
