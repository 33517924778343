import { Button, Flex, styled } from '@m1/liquid-react';
import * as React from 'react';

import { AppContext } from '~/AppContext';

import { PersonalLoansWithdrawModal } from './WithdrawModal';
const StyledWithdrawButtonContainer = styled(Flex)`
  position: absolute;
  right: 8px;
  z-index: 100;
`;

export type WithdrawButtonProps = {
  mt: number;
  onFinish: () => void;
};

export const WithdrawButton = ({ mt, onFinish }: WithdrawButtonProps) => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const { analytics } = React.useContext(AppContext);

  return (
    <>
      <PersonalLoansWithdrawModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        onFinish={onFinish}
      />

      <StyledWithdrawButtonContainer mt={mt}>
        <Button
          kind="text"
          size="large"
          label="Withdraw application"
          onClick={() => {
            analytics.recordEvent('m1_personal_loan_income_withdraw_clicked');
            return setModalIsOpen(true);
          }}
        />
      </StyledWithdrawButtonContainer>
    </>
  );
};
