import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { CreditCardMarketingRoute } from '../spend-marketing';

import { CreditCardClosedRoute } from './closed';
import { CreditCardLandingPageRoute } from './credit-card-landing-page';
import { CreditCardPage } from './CreditCardPage';
import { CreditCardRewardsRoute } from './rewards';

export const CreditCardRoute = () => (
  <Route path="credit" element={<CreditCardPage />}>
    {CreditCardClosedRoute()}
    {CreditCardLandingPageRoute()}
    {CreditCardRewardsRoute()}
    {CreditCardMarketingRoute()}
    <Route index element={<Navigate to="/d/spend" replace />} />
  </Route>
);
