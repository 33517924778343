import { Flex } from '@m1/liquid-react';
import React from 'react';

import { SecurityDetail_Fund_Fragment } from '~/graphql/types';
import { useSecurityDetailsContext } from '~/pages/dashboard/research/SecurityDetailsProvider';
import { Divider } from '~/toolbox/divider';
import { Section } from '~/toolbox/section';

import { FundAllocations } from './FundAllocations';
import { FundTopHoldings } from './FundTopHoldings';

export const FundDetailsSection = () => {
  const {
    queryResult: { data },
  } = useSecurityDetailsContext();

  const security = data?.security as
    | SecurityDetail_Fund_Fragment
    | null
    | undefined;

  if (!security) {
    return null;
  }

  return (
    <>
      <Section>
        <Section.Header label="Allocations" size="small" />
        <Section.Content>
          <FundAllocations fund={security} />
        </Section.Content>
      </Section>

      <Flex flexDirection="column">
        <Divider />
        <FundTopHoldings fund={security} />
      </Flex>
    </>
  );
};
