import * as React from 'react';

import { useCreateFundingSourceRelationshipMutation } from '~/graphql/hooks';
import { FundingSourceNodeFragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useSearchParams } from '~/hooks/useSearchParams';
import { useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';
import { filterNilValues } from '~/utils/objects';

import { AvailableFundingSourceCard } from './AvailableFundingSourceCard';

type AvailableFundingSourceProps = {
  fundingSource: FundingSourceNodeFragment;
};

export const SavingsAvailableFundingSource = ({
  fundingSource,
}: AvailableFundingSourceProps) => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const connectionType = searchParams.get('type');

  const previousRouteName =
    connectionType === 'savings' ? '/d/spend/savings/transactions' : undefined;

  const savingsAccountId = useSelector(
    (state) => state.global.savingsAccountId,
  );
  const [createFundingSourceRelationship, { loading }] =
    useCreateFundingSourceRelationshipMutation();

  usePortaledSpinner(loading);

  const handleSavingsFundingSource = async (
    fundingSourceId: string,
  ): Promise<void> => {
    createFundingSourceRelationship({
      variables: {
        input: {
          accountId: savingsAccountId as string,
          fundingSourceId,
        },
      },
      onCompleted: (data) => {
        navigate({
          to: '/d/c/create-transfer',
          query: filterNilValues({
            toParticipantId:
              data.createFundingSourceRelationship.outcome
                ?.internalTransferParticipant?.id,
            fromParticipantId:
              data.createFundingSourceRelationship.outcome
                ?.fundingSourceTransferParticipant?.id,
            previousRouteName,
          }),
        });
      },
      onError: (err) => {
        addToast({
          content: err.message,
          duration: 'short',
          kind: 'alert',
        });
        navigate({ to: '/d/spend/savings/transactions' });
      },
    });
  };

  return (
    <AvailableFundingSourceCard
      handleActiveFundingSource={handleSavingsFundingSource}
      fundingSource={fundingSource}
    />
  );
};
