import { HXS } from '@m1/liquid-react';
import React from 'react';

import { List } from '~/toolbox/list';
import { Section } from '~/toolbox/section';

import { usePieDetailsContext } from './hooks/usePieDetailsContext';

export const PieMethodologySection = () => {
  const { pie } = usePieDetailsContext();

  if (
    pie?.__typename !== 'SystemPie' ||
    !Array.isArray(pie.methodology) ||
    pie.methodology.length < 1
  ) {
    return null;
  }

  return (
    <Section>
      <HXS content="Methodology" mt={16} />
      <List font="PL" mt={12}>
        {pie.methodology.map((m, i) => (
          <List.Item content={m} key={i} />
        ))}
      </List>
    </Section>
  );
};
