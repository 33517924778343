import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { PortfolioEditorFlow } from '~/flows/components/portfolio-editor/PortfolioEditorFlow';
import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';
import { addSlicesToPortfolioEditorAction } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

export const PortfolioEditorPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const handleFinish = (): void => {
    navigate({ to: '/d/invest' });
  };

  React.useEffect(() => {
    if (state?.sliceableIds) {
      dispatch(addSlicesToPortfolioEditorAction(state?.sliceableIds));
    }
  }, [dispatch, state?.sliceableIds]);

  return (
    <PageErrorBoundary onClickRetry={handleFinish} retryButtonLabel="Exit">
      <PortfolioEditorFlow
        basePath="d/invest/portfolio-editor"
        preserveStateOnUnmount
        portfolioSliceableId={state?.portfolioSliceableId}
        portfolioSliceId={state?.portfolioSliceId}
        onFinish={handleFinish}
      />
    </PageErrorBoundary>
  );
};
