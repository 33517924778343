import { Flex, styled, theme } from '@m1/liquid-react';
import * as React from 'react';

import { DisclosureLink } from '~/components/DisclosureLink/DisclosureLink';
import { ExternalLink } from '~/components/ExternalLink/ExternalLink';
import { Feedback } from '~/components/Feedback';

export type PortfolioDetailsFooterProps = {
  isCrypto: boolean;
};

const FooterContainer = styled(Flex)`
  align-items: center;
  border-top: 1px solid ${theme.colors.borderMain};
  margin-top: 32px;
  padding: 32px 64px;
`;

export const PortfolioDetailsFooter = ({
  isCrypto,
}: PortfolioDetailsFooterProps) => {
  return (
    <FooterContainer>
      <DisclosureLink
        title="Disclosures"
        linkText="Disclosures"
        paragraphs={[
          !isCrypto ? (
            <>
              M1 Holdings is a technology company offering a range of financial
              products and services through its wholly-owned, separate but
              affiliated operating subsidiaries, M1 Finance LLC and M1 Spend
              LLC. Brokerage products and services offered by M1 Finance LLC, an
              SEC registered broker-dealer and Member&nbsp;
              <ExternalLink
                label="FINRA"
                destination="FINRA"
                font="PM"
                fontWeight={400}
              />
              &nbsp;/&nbsp;
              <ExternalLink
                label="SIPC"
                destination="SIPC"
                font="PM"
                fontWeight={400}
              />
              .
            </>
          ) : null,
          `This chart represents your M1 pie value over a specific point in time.
          Performance is shown on a pie level, not on the account level.
          Uninvested cash and unmanaged holdings are not taken into account.
          Your Money Weighted Rate of Return (MWRR) is calculated using the
          value of your portfolio at a beginning date and an end date, taking
          into account any net cashflows. Net cashflows include buys, sells,
          corporate actions, earned dividends, and any pie slice movements that
          would cause positive or negative cash flow. Cost basis adjustments are
          not accounted for in this chart and certain information may be
          incomplete for tax reporting purposes. This information is general in
          nature and should not be considered tax advice or used to make
          investment decisions. Account data shown represents past performance
          and does not guarantee future results. All investing involves risk,
          including the risk of losing the money you invest. Past performance
          does not guarantee future performance. Using M1 Borrow's margin
          account can add to these risks, and you should review our margin
          account risk disclosure before borrowing. Nothing in this
          informational site is an offer, solicitation of an offer, or advice to
          buy or sell any security and you are encouraged to consult your
          personal investment, legal, or tax advisors.`,
          isCrypto ? (
            <Feedback
              key={2}
              content="Provide crypto feedback"
              destination="SUPPORT_CRYPTO"
            />
          ) : null,
        ]}
      />
    </FooterContainer>
  );
};
