import keys from 'lodash-es/keys';
import pick from 'lodash-es/pick';

import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import { CONNECT_BANK_FLOW_STEPS } from '~/static-constants';

import { FlowState } from '../newFlowsReducer.types';

export type BankConnectionType =
  | 'invest'
  | 'spend'
  | 'savings'
  | 'personal_loans'
  | 'personal_loans_direct'
  | 'fs_standard'
  | null;

export type ConnectBankFlowState = FlowState<
  string,
  {
    accountId: string | null | undefined;
    allowSkipping: boolean;
    canNavigateBackFromMicrodeposits: boolean;
    connectionType: BankConnectionType;
    fundingSourceId: string | null | undefined;
    redirectUrl: string | null | undefined;
  }
>;

export const connectBankFlowInitialState: ConnectBankFlowState = {
  accountId: null,
  allowSkipping: false,
  canNavigateBackFromMicrodeposits: true,
  connectionType: null,
  fundingSourceId: null,
  redirectUrl: null,
  step: CONNECT_BANK_FLOW_STEPS.LOAD_STATUS,
  stepTitle: 'Fund your account',
};

export function connectBank(
  state: ConnectBankFlowState = connectBankFlowInitialState,
  action: any,
): ConnectBankFlowState {
  switch (action.type) {
    case ACTIONS.BEGIN_CONNECT_BANK_FLOW:
      return Object.assign(
        {
          ...connectBankFlowInitialState,
        },
        pick(action.payload, keys(connectBankFlowInitialState)),
      );
    case ACTIONS.CHANGED_CONNECT_BANK_FLOW_STEP:
      return Object.assign(
        {
          ...state,
        },
        {
          step: action.payload,
        },
      );
    case ACTIONS.END_FLOW:
      if (action.meta.flow === 'connectBank') {
        return connectBankFlowInitialState;
      }
      return state;
    default:
      return state;
  }
}
