import { Button, Flex } from '@m1/liquid-react';

import React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useWizardContext } from '~/flows/wizard';
import {
  useInitializeTwoFactorAuthMutation,
  useTwoFactorAuthQuery,
} from '~/graphql/hooks';
import { useToast } from '~/toasts';
import { Spinner } from '~/toolbox/spinner';

type AttemptAuthProps = {
  exitFlow: () => void;
};

export const InitializeTwoFactorChange = ({ exitFlow }: AttemptAuthProps) => {
  const { addToast } = useToast();

  const { goTo } = useWizardContext();

  const { data, loading: twoFactorAuthQueryLoading } = useTwoFactorAuthQuery({
    fetchPolicy: 'network-only',
  });

  const isTwoFactorAuthEnabled = data?.viewer?.user?.isTwoFactorAuthEnabled;

  const [initializeTwoFactorAuth, { loading: initializeTwoFactorAuthLoading }] =
    useInitializeTwoFactorAuthMutation({
      variables: {
        input: {},
      },
      onCompleted: async () => {
        goTo('AUTH_APP_SETUP');
      },
      onError: (error) => {
        const requiresSteppedUpAuth = error.graphQLErrors.some(
          (graphqlError) =>
            graphqlError.extensions.code === 'MFA_TOKEN_REQUIRED',
        );

        if (requiresSteppedUpAuth) {
          goTo('PERFORM_ENHANCED_AUTH');
        } else {
          addToast({
            content: error.message,
            kind: 'alert',
          });

          exitFlow();
        }
      },
    });

  React.useEffect(() => {
    if (twoFactorAuthQueryLoading) {
      return;
    }

    if (typeof isTwoFactorAuthEnabled === 'boolean') {
      // If the user has 2FA enabled, send them to the disable step.
      // Otherwise, attempt to generate a new 2FA token.
      if (isTwoFactorAuthEnabled) {
        goTo('DISABLE_TWO_FACTOR_AUTH');
      } else {
        initializeTwoFactorAuth({
          variables: {
            input: {},
          },
        });
      }
    }
  }, [
    twoFactorAuthQueryLoading,
    isTwoFactorAuthEnabled,
    goTo,
    initializeTwoFactorAuth,
  ]);

  const loading = twoFactorAuthQueryLoading || initializeTwoFactorAuthLoading;

  if (
    !twoFactorAuthQueryLoading &&
    typeof data?.viewer?.user?.isTwoFactorAuthEnabled !== 'boolean'
  ) {
    return (
      <GenericSystemError
        button={<Button label="Try again" onClick={exitFlow} />}
      />
    );
  }

  return (
    <Flex flexDirection="column" alignItems="center" width="100%">
      {loading && <Spinner fullScreen />}
    </Flex>
  );
};
