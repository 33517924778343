import { Flex, HM } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { OnboardingHeader } from '~/components/Onboarding/OnboardingHeader';
import { AspectRatioFrame } from '~/flows/components/personal-loans/application/components/AspectRatioFrame';
import { useInvestProductSelectionQuery } from '~/graphql/hooks';
import { ProductListIdentifier } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useBreakpoints } from '~/hooks/useBreakpoints';
import { useNavigate } from '~/hooks/useNavigate';
import { setOnboardingProduct } from '~/redux/actions/newFlows/onboarding';
import { useDispatch } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';
import { isNotNil } from '~/utils';

import { AccountSelectionCard } from '../setup-ira-account/components/AccountSelectionCard';

export const InvestProductSelectionPage = () => {
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isDesktop, isMedium } = useBreakpoints();
  const { data, loading } = useInvestProductSelectionQuery();
  const investProductSelectionScreen =
    data?.viewer.onboarding?.onboardingInvest.investProductSelectionScreen;

  const analyticsEvent =
    data?.viewer.onboarding?.onboardingInvest.investProductSelectionScreen
      .screenViewedAnalyticsEvent;

  React.useEffect(() => {
    if (analyticsEvent) {
      analytics.recordAppAnalyticsEvent(analyticsEvent);
    }
  }, [analytics, analyticsEvent]);

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!investProductSelectionScreen) {
    return <GenericSystemError />;
  }

  const investProducts = investProductSelectionScreen.productSelection || [];

  const handleClick = (value: string) => {
    if (value === ProductListIdentifier.InvestRetirement) {
      dispatch(
        setOnboardingProduct({
          destination: 'Invest',
          productIdentifier: ProductListIdentifier.InvestRetirement,
        }),
      );

      navigate({ to: '/onboarding/setup-ira-account' });
    } else {
      dispatch(
        setOnboardingProduct({
          destination: 'Invest',
          productIdentifier: ProductListIdentifier.InvestIndividual,
        }),
      );

      navigate({ to: '/onboarding/phone-verification' });
    }
  };

  return (
    // This extra Flex container here is to set the Pages background color.
    // Since the inner container has a maxWidth of 1200, we need both of them.
    <Flex
      backgroundColor="backgroundNeutralSecondary"
      height="100vh"
      flexDirection="column"
      px={isMedium ? 48 : 0}
      width="100%"
    >
      <OnboardingHeader flowName="onboarding" />
      <Flex mt={136} maxWidth={1200} mx="auto">
        <Flex
          flexDirection="column"
          width={isDesktop ? '50%' : '100%'}
          mr={isDesktop ? 126 : 0}
        >
          <HM content={investProductSelectionScreen.title} mb={48} />
          <>
            {investProducts.filter(isNotNil).map((card, index) => (
              <AccountSelectionCard
                key={index}
                card={card}
                onClick={handleClick}
              />
            ))}
          </>
        </Flex>
        {isDesktop && (
          <Flex flexDirection="column" p={24} width="50%">
            <AspectRatioFrame ratio="4:3">
              <Illustration name="directToIRA1Web" />
            </AspectRatioFrame>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
