import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { PositionDetails } from '~/components/invest/PositionDetails/PositionDetailsRenderer';
import { useNavigate } from '~/hooks/useNavigate';
import { useParams } from '~/hooks/useParams';
import { clickedOnPortfolioSlice } from '~/redux/actions/events';
import { useDispatch } from '~/redux/hooks';

export const PositionDetailsPage = () => {
  const { positionId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box mx="auto" pt={64} maxWidth={780}>
      <BackArrow content="Holdings" mb={16} onClick={() => navigate(-1)} />
      <PositionDetails
        onRowClick={(id) => {
          dispatch(clickedOnPortfolioSlice(id));
        }}
        positionId={positionId as string}
      />
    </Box>
  );
};
