import { Box, HL, styled } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { InvestmentsTable } from '~/components/invest/InvestmentsTable/InvestmentsTable';
import { useMarginCallInformationHoldingsOverviewQuery } from '~/graphql/hooks';
import { useSelector } from '~/redux/hooks';
import { MARGIN_CALL_INFORMATION_STEPS as STEPS } from '~/static-constants';
import { Spinner } from '~/toolbox/spinner';

type MarginCallInformationHoldingsOverviewProps = {
  onFinishStep: (step?: ValueOf<typeof STEPS> | string) => void;
};

const StyledContentContainer = styled(Box)`
  margin: auto;
  width: 1194px;
`;

export const MarginCallInformationHoldingsOverview = ({
  onFinishStep,
}: MarginCallInformationHoldingsOverviewProps) => {
  const borrowAccountId = useSelector(
    (state) => state.newFlows.MARGIN_CALL_INFORMATION.borrowAccountId,
  );

  const { data, loading } = useMarginCallInformationHoldingsOverviewQuery({
    variables: {
      borrowAccountId: borrowAccountId as string,
    },
    skip: !borrowAccountId,
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (data?.node?.__typename !== 'BorrowAccount') {
    return <GenericSystemError />;
  }

  return (
    <StyledContentContainer>
      <BackArrow
        content="Resolve Call Options"
        mt={110}
        onClick={() => onFinishStep(STEPS.RESOLVE_CALL_OPTIONS)}
      />
      <HL content="Holdings" mb={30} mt={24} />
      {data.node.investAccount?.id && (
        <InvestmentsTable
          accountId={data.node.investAccount?.id}
          onRowClick={(positionId) => onFinishStep(positionId)}
        />
      )}
    </StyledContentContainer>
  );
};
