import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalTitle,
} from '@m1/liquid-react';
import * as React from 'react';

import { ButtonGroup } from '~/toolbox/ButtonGroup';

type Props = {
  cancelCtaCopy?: string;
  confirmCtaCopy?: string;
  hasError?: boolean;
  onConfirm: (...args: Array<any>) => any;
  title?: React.ReactNode;
  trigger: React.ReactElement<any>;
};

export const ConfirmModal = ({
  cancelCtaCopy = 'No',
  confirmCtaCopy = 'Yes',
  hasError,
  onConfirm,
  title = 'Are you sure?',
  trigger,
}: Props) => {
  const [open, setOpen] = React.useState<boolean | undefined>(undefined);
  React.useEffect(() => {
    if (hasError) {
      setOpen(false);
    }
  }, [hasError]);

  return (
    <Modal open={open} trigger={trigger}>
      <ModalContent centered>
        <ModalTitle>{title}</ModalTitle>
        <Flex justifyContent="center" mt={32}>
          <ButtonGroup>
            <Button
              label={cancelCtaCopy}
              kind="secondary"
              size="large"
              onClick={() => {
                setOpen(false);
              }}
            />
            <Button
              label={confirmCtaCopy}
              kind="primary"
              size="large"
              type="submit"
              onClick={() => onConfirm()}
            />
          </ButtonGroup>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
