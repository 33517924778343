import { Button } from '@m1/liquid-react';
import * as React from 'react';

import { useAnalytics } from '~/hooks/useAnalytics';
import { useSelector } from '~/redux/hooks';

type PortfolioSubmitButtonProps = {
  direction: 'buy' | 'sell';
  disabled: boolean;
  label: string;
};

export const PortfolioOrderSubmitButton = ({
  direction,
  disabled,
  label,
}: PortfolioSubmitButtonProps) => {
  const analytics = useAnalytics();
  const { currentValue, validationErrors } = useSelector((state) => {
    const formState = state.form?.['portfolio-order'];

    return {
      currentValue: parseFloat(formState?.values?.amount ?? '0'),
      validationErrors: formState?.syncErrors ?? {},
    };
  });

  const isInfinite = !Number.isFinite(currentValue);
  const hasValidationErrors = Object.keys(validationErrors).length > 0;

  const isDisabled =
    disabled ||
    isInfinite ||
    hasValidationErrors ||
    (direction === 'buy' && currentValue < 1) ||
    (direction === 'sell' && currentValue === 0);

  return (
    <Button
      disabled={isDisabled}
      kind="primary"
      label={label}
      size="large"
      type="submit"
      fullWidth
      onClick={() =>
        analytics.recordEvent(`m1_invest_${direction}_confirm_clicked`)
      }
    />
  );
};
