import * as React from 'react';

import { ProcessingRequest } from '~/components/ProcessingRequest';

export const ProcessingOpenAccount = () => {
  return (
    <ProcessingRequest
      heading="We are setting up your account"
      content="This may take up to a minute."
    />
  );
};
