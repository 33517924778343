import { ApolloLink, Operation } from '@apollo/client';

import { AnalyticsReporter } from '~/loggers';
import { isNotNil } from '~/utils';

import { Mutation } from './types';

// Some older mutations have different payload structures.
// For this utility, we're only handling the most current shape with a `didSucceed` field.
// And we are only interested in successful mutations.
type MutationPayload = Mutation[keyof Omit<Mutation, '__typename'>];
type ModernMutationPayload = Extract<MutationPayload, { didSucceed: boolean }>;
type SuccessPayload = ModernMutationPayload & {
  didSucceed: true;
};

const isMutation = (operation: Operation) =>
  operation.query.definitions.some(
    (op) => op.kind === 'OperationDefinition' && op.operation === 'mutation',
  );

const isSuccessPayload = (payload: unknown): payload is SuccessPayload => {
  return (
    isNotNil(payload) && 'didSucceed' in payload && payload.didSucceed === true
  );
};

// Most mutations have a successAnalyticsEvent field under outcome.
// One (SetRootPie) has it directly under the payload.
const getSuccessAnalyticsEvent = (payload: SuccessPayload) => {
  if ('successAnalyticsEvent' in payload) {
    return payload.successAnalyticsEvent;
  }
  if (
    'outcome' in payload &&
    isNotNil(payload.outcome) &&
    'successAnalyticsEvent' in payload.outcome
  ) {
    return payload.outcome.successAnalyticsEvent;
  }
  return null;
};

/**
 * Handle successful mutation responses.
 * Log the success analytics event if it exists under outcome.successAnalyticsEvent.
 * @param analytics Analytics reporter
 * @returns an ApolloLink instance
 */
export const mutationSuccessLink = (analytics: AnalyticsReporter) =>
  new ApolloLink((operation, forward) => {
    if (!isMutation(operation)) {
      return forward(operation);
    }
    return forward(operation).map((response) => {
      // Mutation responses have the shape of { mutationName: MutationPayload }.
      // Typically there is only one mutation per request, but it's possible to have more.
      const entries = Object.entries(response.data ?? {});
      for (const [_mutationName, payload] of entries) {
        // For each payload, check if the mutation was successful.
        if (isSuccessPayload(payload)) {
          // If the outcome has a successAnalyticsEvent, log it.
          const event = getSuccessAnalyticsEvent(payload);
          if (isNotNil(event)) {
            analytics.recordAppAnalyticsEvent(event);
          }
        }
      }

      return response;
    });
  });
