import { HXS, PL, Flex, styled } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

const StyledEmptyFlexContainer = styled(Flex)`
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
  max-width: 550px;
  text-align: center;
`;

export const InvestActivityEmptyContainer = () => (
  <StyledEmptyFlexContainer alignItems="center" flexDirection="column">
    <Illustration
      name="investActivityEmptyState"
      style={{
        marginTop: 16,
      }}
    />
    <HXS content="You have no activity yet" mt={32} mb={16} />
    <PL
      content="Once there are trades executed, dividends paid out, or any other type of activity that affects your account, they will appear here."
      mt={16}
      mb={16}
    />
  </StyledEmptyFlexContainer>
);
