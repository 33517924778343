import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { TransfersActivityRoute } from '~/pages/dashboard/transfers/transfers-activity';
import { TransfersRulesRoute } from '~/pages/dashboard/transfers/transfers-rules';

import { RolloversRoute } from './rollovers/RolloversRoute';
import { TransfersPage } from './TransfersPage';

export const TransfersRoute = () => {
  return (
    <Route path="transfers" element={<TransfersPage />}>
      {TransfersRulesRoute()}
      {TransfersActivityRoute()}
      {RolloversRoute()}
      <Route index element={<Navigate to="/d/transfers/activity" replace />} />
    </Route>
  );
};
