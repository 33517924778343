export const ACCOUNT_ACTIONS = Object.freeze({
  SET_ACTIVE_INVEST_ACCOUNT: 'SET_ACTIVE_INVEST_ACCOUNT' as const,
  SET_ACTIVE_CRYPTO_ACCOUNT: 'SET_ACTIVE_CRYPTO_ACCOUNT' as const,
  SET_ACTIVE_BORROW_ACCOUNT: 'SET_ACTIVE_BORROW_ACCOUNT' as const,
  SET_ACTIVE_CHECKING_ACCOUNT: 'SET_ACTIVE_CHECKING_ACCOUNT' as const,
  SET_ACTIVE_CREDIT_ACCOUNT: 'SET_ACTIVE_CREDIT_ACCOUNT' as const,
  SET_ACTIVE_SAVINGS_ACCOUNT: 'SET_ACTIVE_SAVINGS_ACCOUNT' as const,
  SET_ACTIVE_PERSONAL_LOAN_ACCOUNT: 'SET_ACTIVE_PERSONAL_LOAN_ACCOUNT' as const,
  SKIPPED_TRUSTED_CONTACT: 'SKIPPED_TRUSTED_CONTACT' as const,
  INITIAL_ACCOUNTS_SELECTED: 'INITIAL_ACCOUNTS_SELECTED' as const,
  INVEST_ACCOUNT_SELECTED: 'INVEST_ACCOUNT_SELECTED' as const,
  LOAD_ACCOUNT_DOCUMENT: 'LOAD_ACCOUNT_DOCUMENT' as const,
});

export type SetActiveInvestAccountAction = {
  payload: string | null | undefined;
  type: typeof ACCOUNT_ACTIONS.SET_ACTIVE_INVEST_ACCOUNT;
};

export type SetActiveCryptoAccountAction = {
  payload: string | null | undefined;
  type: typeof ACCOUNT_ACTIONS.SET_ACTIVE_CRYPTO_ACCOUNT;
};

export type SetActiveBorrowAccountAction = {
  payload: string;
  type: typeof ACCOUNT_ACTIONS.SET_ACTIVE_BORROW_ACCOUNT;
};

export type SetActiveCheckingAccountAction = {
  payload: string;
  type: typeof ACCOUNT_ACTIONS.SET_ACTIVE_CHECKING_ACCOUNT;
};

export type SetActiveCreditAccountAction = {
  payload: string;
  type: typeof ACCOUNT_ACTIONS.SET_ACTIVE_CREDIT_ACCOUNT;
};

export type SetActiveSavingsAccountAction = {
  payload: string;
  type: typeof ACCOUNT_ACTIONS.SET_ACTIVE_SAVINGS_ACCOUNT;
};

export type SetActivePersonalLoanAccountAction = {
  payload: string;
  type: typeof ACCOUNT_ACTIONS.SET_ACTIVE_PERSONAL_LOAN_ACCOUNT;
};

export type SkippedTrustedContactAction = {
  type: typeof ACCOUNT_ACTIONS.SKIPPED_TRUSTED_CONTACT;
};

export type InvestAccountSelectedAction = {
  payload: string;
  type: typeof ACCOUNT_ACTIONS.INVEST_ACCOUNT_SELECTED;
};

export type AccountAction =
  | InvestAccountSelectedAction
  | SetActiveInvestAccountAction
  | SetActiveCryptoAccountAction
  | SetActiveBorrowAccountAction
  | SetActiveCheckingAccountAction
  | SetActiveCreditAccountAction
  | SetActiveSavingsAccountAction
  | SetActivePersonalLoanAccountAction
  | SkippedTrustedContactAction;
