import { Flex, Skeleton } from '@m1/liquid-react';
import * as React from 'react';

const SkeletonGridLine = () => {
  return (
    <Flex alignItems="center" my={16} flex="1">
      <Skeleton skeletonWidth={40} skeletonHeight={20} mr={16} />
      <Skeleton skeletonHeight={1} flex="1" />
    </Flex>
  );
};

const SkeletonRangeSelector = () => (
  <Skeleton skeletonWidth={40} skeletonHeight={20} ml={8} />
);

export const StockChartSkeleton = () => {
  return (
    <Flex width="100%" height="100%" flexDirection="column" pb={16}>
      <Flex justifyContent="flex-end">
        <SkeletonRangeSelector />
        <SkeletonRangeSelector />
        <SkeletonRangeSelector />
        <SkeletonRangeSelector />
        <SkeletonRangeSelector />
        <SkeletonRangeSelector />
        <SkeletonRangeSelector />
      </Flex>
      <SkeletonGridLine />
      <SkeletonGridLine />
      <SkeletonGridLine />
      <SkeletonGridLine />
      <SkeletonGridLine />
    </Flex>
  );
};
