import { Box, styled, Flex } from '@m1/liquid-react';

export const StyledDetailsCardDivider = styled(Box)`
  height: 1px;
  min-height: 1px;
  border-top: ${({ theme }) => `solid 1px ${theme.colors.borderMain}`};
`;

export const StyledDetailsCard = styled(Flex)`
  min-height: 48px;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.MEDIUM}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledDetailsCardContainer = styled(Flex)`
  flex-direction: column;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.MEDIUM}) {
    max-width: 273px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.SMALL}) {
    max-width: 689px;
  }
`;
