import * as React from 'react';

import { GetTransferWizardPreloadQueryResult } from '~/graphql/hooks';
import { TransferParticipantSideEnum } from '~/graphql/types';
import { useSearchParams } from '~/hooks/useSearchParams';
import { CREATE_TRANSFER_FLOW_MODES } from '~/static-constants';

type TransferMode = keyof typeof CREATE_TRANSFER_FLOW_MODES;
export type CreateTransferContextType = {
  mode: TransferMode;
  pivot: TransferParticipantSideEnum;
  fromParticipantIdQueryParam: string | null;
  toParticipantIdQueryParam: string | null;
  idempotencyKey: string | null | undefined;
} & GetTransferWizardPreloadQueryResult;

export const CreateTransferContext =
  React.createContext<CreateTransferContextType | null>(null);
export const CreateTransferProvider = ({
  children,
  data,
  idempotencyKey,
}: {
  children: React.ReactChild;
  data: GetTransferWizardPreloadQueryResult;
  idempotencyKey: string | null | undefined;
}) => {
  const [params] = useSearchParams();

  /*
   * The pivot is the side of the transfer that filters the participants for the other side.
   * Pivoting on 'From' means that the 'To' side will be filtered by the 'From' side.
   */
  const pivot =
    (params.get('pivot') ?? '').toLowerCase() === 'destination'
      ? TransferParticipantSideEnum.To
      : TransferParticipantSideEnum.From;
  const mode = (params.get('mode') ?? 'ONE_TIME') as TransferMode;
  const fromParticipantIdQueryParam = params.get('fromParticipantId') ?? null;
  const toParticipantIdQueryParam = params.get('toParticipantId') ?? null;

  return (
    <CreateTransferContext.Provider
      value={{
        mode,
        fromParticipantIdQueryParam,
        toParticipantIdQueryParam,
        idempotencyKey,
        pivot,
        ...data,
      }}
    >
      {children}
    </CreateTransferContext.Provider>
  );
};
