import React from 'react';

import { SliceableBreadCrumbs } from '~/components/pie/SliceableBreadCrumbs/SliceableBreadCrumbs';
import { SecurityDetailFragment } from '~/graphql/types';
import { useSecurityDetailsContext } from '~/pages/dashboard/research/SecurityDetailsProvider';

import { MissingSecurityData } from './MissingSecurityData';
import { SecurityDataContainer } from './SecurityDataContainer';

export const SecurityRelatedCategories = () => {
  const {
    queryResult: { data },
    disableBreadcrumbs,
  } = useSecurityDetailsContext();

  const security = data?.security as SecurityDetailFragment | null | undefined;

  if (!security) {
    return (
      <SecurityDataContainer headingContent="Related categories">
        <MissingSecurityData title="No category data" />
      </SecurityDataContainer>
    );
  }

  return (
    <SecurityDataContainer
      headingContent="Related categories"
      subHeadingContent={`Explore categories related to ${security.symbol}`}
    >
      <SliceableBreadCrumbs
        navigable={!disableBreadcrumbs}
        sliceable={security}
      />
    </SecurityDataContainer>
  );
};
