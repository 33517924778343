import { Box, PL, styled } from '@m1/liquid-react';
import * as React from 'react';

import { change, formValueSelector } from 'redux-form';

import { AppContext } from '~/AppContext';
import { mapPlaceToAddress } from '~/forms/fields/AddressAutofill.helpers';
import { FormMocker } from '~/forms/FormMockers/FormMocker';
import { useDispatch, useSelector } from '~/redux/hooks';

import {
  printableAsciiChars,
  required,
  valueMatchesInput,
} from '../validators';

import { TextField } from './text-field';

type Autocomplete = google.maps.places.Autocomplete;

// types
type PlaceResult = google.maps.places.PlaceResult;

type AddressAutofillProps = {
  setShowAddressAutofill: (arg0: boolean) => void;
};

type EscapeHatchProps = {
  setShowAddressAutofill: (arg0: boolean) => void;
  place: PlaceResult | null;
};

// custom hooks
function useLoadGmapsScript(hasLoaded: boolean) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    let script: HTMLScriptElement;

    if (!hasLoaded) {
      script = document.createElement('script');

      script.src = `https://maps.googleapis.com/maps/api/js?key=${window.config.googleMaps.apiKey}&libraries=places&callback=initMap`;
      script.async = true;

      window.initMap = () => {
        dispatch({
          type: 'SET_HAS_LOADED_GMAPS_LIBRARY',
        });
      };

      document.body.appendChild(script);
    }

    return () => {
      if (script && window.initMap) {
        script.removeEventListener('load', window.initMap);
      }
    };
  }, [dispatch, hasLoaded]);
}

function usePreventSubmissionOnEnter(
  htmlInputElement: HTMLInputElement | undefined,
) {
  React.useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    };

    if (htmlInputElement) {
      htmlInputElement.addEventListener('keypress', onKeyPress);
    }

    return () => {
      if (htmlInputElement) {
        htmlInputElement.removeEventListener('keypress', onKeyPress);
      }
    };
  }, [htmlInputElement]);
}

function useGetGoogleAutoCompleteInstance(
  htmlInputElement: HTMLInputElement | undefined,
  hasGoogleLoaded: boolean,
): Autocomplete | null {
  // It is important to memoize this so we don't instantiate multiple instances.
  // This effects our google handles session tokens and in turn, our billing.
  return React.useMemo(() => {
    if (!htmlInputElement || !hasGoogleLoaded) {
      return null;
    }

    return new google.maps.places.Autocomplete(htmlInputElement, {
      componentRestrictions: { country: 'us' },
      fields: ['address_components', 'formatted_address'],
      types: ['geocode'],
    });
  }, [hasGoogleLoaded, htmlInputElement]);
}

type UseSetupGooglePlaceListenerCallbackInput = {
  hasGoogleLoaded: boolean;
  onPlaceSelect: (arg0: PlaceResult) => void;
  googleMapsAutoCompleteInstance: Autocomplete | null;
};

function useSetupGooglePlaceListenerCallback({
  hasGoogleLoaded,
  onPlaceSelect,
  googleMapsAutoCompleteInstance,
}: UseSetupGooglePlaceListenerCallbackInput) {
  React.useEffect(() => {
    if (!hasGoogleLoaded || !googleMapsAutoCompleteInstance) {
      return;
    }

    googleMapsAutoCompleteInstance.addListener('place_changed', () => {
      const place = googleMapsAutoCompleteInstance.getPlace();

      onPlaceSelect(place);
    });
  }, [googleMapsAutoCompleteInstance, hasGoogleLoaded, onPlaceSelect]);
}

function useSyncReduxFormStateWithAutoSelectedAddress(
  place: PlaceResult | null,
) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!place) {
      return;
    }

    const formattedAddressForLens = mapPlaceToAddress(place);

    if (!formattedAddressForLens) {
      return;
    }

    for (const [key, value] of Object.entries(formattedAddressForLens)) {
      dispatch(change('contact-info-modern', `homeAddress.${key}`, value));
    }
  }, [place, dispatch]);
}

// helper components
const StyledPL = styled(PL)<{ $show: boolean }>`
  position: absolute;
  top: -28px;
  right: 0;
  font-weight: 600;
  cursor: pointer;
  opacity: ${(props) => (props.$show ? 1 : 0)};
  pointer-events: ${(props) => (props.$show ? 'initial' : 'none')};
  transition: 200ms;
`;

const EscapeHatch = ({ setShowAddressAutofill, place }: EscapeHatchProps) => {
  const { analytics } = React.useContext(AppContext);

  const reduxFormValue = useSelector<string | undefined>((state) => {
    const formSelector = formValueSelector('contact-info-modern');
    return formSelector(state, 'homeAddress.autofilledAddress');
  });

  const dispatch = useDispatch();
  return (
    <StyledPL
      content="I don't see my address"
      color="primary"
      onClick={() => {
        analytics.recordEvent('m1_address_autofill_manual_entry_clicked');

        dispatch(
          change('contact-info-modern', 'homeAddress.autofilledAddress', ''),
        );
        setShowAddressAutofill(false);
      }}
      $show={Boolean(
        reduxFormValue && place?.formatted_address !== reduxFormValue,
      )}
    />
  );
};

/**
 * Custom RF validator designed to guard against a user selecting a place that isn't a complete address.
 * Examples include are 'Chicago, IL' or 'United States'.
 */
function hasSelectedValidPlace(place: PlaceResult | null): () => void | string {
  if (!place) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return function () {};
  }

  const mailingAddress = mapPlaceToAddress(place);

  if (!mailingAddress) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return function () {};
  }

  const { city, lineOne, postalCode, stateOrProvince } = mailingAddress;

  // eslint-disable-next-line consistent-return
  return function () {
    if (!city || !lineOne || !postalCode || !stateOrProvince) {
      return 'Please enter a valid address';
    }
  };
}

export const AddressAutofill = ({
  setShowAddressAutofill,
}: AddressAutofillProps) => {
  const { analytics } = React.useContext(AppContext);
  const [place, setPlace] = React.useState<PlaceResult | null>(null);

  const onPlaceSelect = (place: PlaceResult) => {
    if (!place || !place.formatted_address) {
      setPlace(null);
    }

    analytics.recordEvent('m1_address_autofill_suggestion_selected');

    setPlace(place);
  };

  const autoFilledAddressInitial = useSelector<string | undefined>((state) => {
    return state.form['contact-info-modern']?.initial?.homeAddress
      ?.autofilledAddress;
  });

  const validators = React.useMemo<Array<() => void | string>>(() => {
    const placeToCompare = place?.formatted_address || autoFilledAddressInitial;

    return [
      required,
      valueMatchesInput(placeToCompare, 'Please choose a location to continue'),
      hasSelectedValidPlace(place),
    ];
  }, [place, autoFilledAddressInitial]);

  const hasGoogleLoaded = useSelector<boolean>(
    (state) => state.global.hasLoadedGmapsLibrary,
  );

  useLoadGmapsScript(hasGoogleLoaded);

  // Ideally, this would be handled through a Ref if our input wasn't buried several levels deep.
  // Querying the DOM on a per-render basis suffices for now.
  const htmlInputElement = document.getElementById(
    'autocomplete',
  ) as HTMLInputElement;

  usePreventSubmissionOnEnter(htmlInputElement);

  const googleMapsAutoCompleteInstance = useGetGoogleAutoCompleteInstance(
    htmlInputElement,
    hasGoogleLoaded,
  );

  useSetupGooglePlaceListenerCallback({
    hasGoogleLoaded,
    onPlaceSelect,
    googleMapsAutoCompleteInstance,
  });

  useSyncReduxFormStateWithAutoSelectedAddress(place);

  React.useEffect(() => {
    //  manually setting display:none for any pac-containers if navigated away (only Chrome does this automatically)
    return () => {
      const pacContainerElements = document.querySelectorAll('.pac-container');
      pacContainerElements?.forEach((element) =>
        element.setAttribute('style', 'display: none'),
      );
    };
  }, []);

  return (
    <Box position="relative">
      <EscapeHatch
        setShowAddressAutofill={setShowAddressAutofill}
        place={place}
      />
      <TextField
        id="autocomplete"
        name="autofilledAddress"
        placeholder="Street address"
        maxLength={100}
        validate={validators}
        value={place?.formatted_address}
      />
      <TextField
        name="lineTwo"
        label="Apt, Fl, Suite (Opt.)"
        maxLength={30}
        validate={printableAsciiChars}
      />
      <FormMocker
        formName="contact-info-modern"
        label="Mock an autofilled address!"
        fields={[
          {
            name: 'homeAddress.autofilledAddress',
            value: '123 Main St, Chicago, IL 60647, USA',
          },
        ]}
        onClick={() => {
          setPlace({
            formatted_address: '123 Main St, Chicago, IL 60647, USA',
            address_components: [
              {
                long_name: '123',
                short_name: '123',
                types: ['street_number'],
              },
              {
                long_name: 'Main St',
                short_name: 'N Main St',
                types: ['route'],
              },
              {
                long_name: 'Chicago',
                short_name: 'Chicago',
                types: ['locality', 'political'],
              },
              {
                long_name: 'Illinois',
                short_name: 'IL',
                types: ['administrative_area_level_1', 'political'],
              },
              {
                long_name: 'United States',
                short_name: 'US',
                types: ['country', 'political'],
              },
              {
                long_name: '60647',
                short_name: '60647',
                types: ['postal_code'],
              },
              {
                long_name: '1014',
                short_name: '1014',
                types: ['postal_code_suffix'],
              },
            ],
          });
        }}
      />
    </Box>
  );
};
