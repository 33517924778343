import * as React from 'react';
import { Route } from 'react-router-dom';

import { MyPiesResearchPage } from '~/pages/dashboard/research/my-pies/components/MyPiesResearchPage';

import { AddMyPieDetailsRoute } from './details';

export const AddMyPiesRoute = () => {
  return (
    <Route path="my-pies">
      {AddMyPieDetailsRoute()}
      <Route index element={<MyPiesResearchPage />} />
    </Route>
  );
};
