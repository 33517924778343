import { Box, Flex, HM, Tooltip } from '@m1/liquid-react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { BackArrow } from '~/components/BackArrow';
import { useOpenInvestJointAccountWizardContext } from '~/flows/components/OpenInvestJointAccountWizard/hooks/useOpenInvestJointAccountWizardContext';
import { StyledOption } from '~/pages/onboarding/financial-suitability-wizard/consts';

import { submitReactFormData } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { LiquidityNeedsEnumDropdownOptions } from '~/static-constants';

export interface LiquidityNeedsValues {
  liquidityNeeds: string;
}

export const LiquidityNeeds = () => {
  const dispatch = useDispatch();

  const submittedFormValues = useSelector((state) => {
    if (!state.reactHookForm.openJointInvestAccountLiquidityNeeds) {
      return {
        liquidityNeeds: undefined,
      };
    }

    return state.reactHookForm.openJointInvestAccountLiquidityNeeds;
  });

  const { setValue, handleSubmit } = useForm<LiquidityNeedsValues>({
    defaultValues: submittedFormValues,
  });

  const wiz = useOpenInvestJointAccountWizardContext();

  const onSubmit = (data: LiquidityNeedsValues) => {
    dispatch(
      submitReactFormData({ openJointInvestAccountLiquidityNeeds: data }),
    );

    wiz.next();
  };

  const handleOptionClick = (name: string) => {
    setValue('liquidityNeeds', name);
    handleSubmit(onSubmit)();
  };

  return (
    <Flex maxWidth={588} flexDirection="column" mx="auto">
      <Box pt={32}>
        <BackArrow content="Back" onClick={() => wiz.back()} />
      </Box>
      <Flex alignItems="center" mt={48} mb={32}>
        <HM>How important is liquidity to you?</HM>
        <Tooltip body="Here, we're hoping to learn how important is it for you to be able to turn your investments into cash quickly, without losing much value." />
      </Flex>
      <form onSubmit={handleSubmit(onSubmit)}>
        {LiquidityNeedsEnumDropdownOptions.map(({ name, description }) => (
          <StyledOption
            tabIndex={0}
            key={name}
            onClick={() => handleOptionClick(name)}
            onKeyDown={(event: React.KeyboardEvent) => {
              if (event.key === 'Enter' || event.code === 'Space') {
                handleOptionClick(name);
              }
            }}
          >
            {description}
          </StyledOption>
        ))}
      </form>
    </Flex>
  );
};
