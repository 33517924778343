import moment from 'moment';

import { SectionRowData } from '~/components/Onboarding/IdentityFirstConfirmationScreenHelpers/IdentityFirstConfirmationScreenSection';
import {
  MailingAddress,
  NameSuffixEnum,
  IdentityFirstConfirmationProfileFragment,
  LiquidNetWorthEnum,
} from '~/graphql/types';
import {
  formatSuffix,
  maskSsn,
  formatCurrencyWithCommasOrNull,
  getEnumEntries,
} from '~/utils';
import { formatPhoneNumber } from '~/utils/formatting';

export type BuildFullNameInput = {
  firstName: string;
  lastName: string;
  middleInitial: string | null;
  suffix: NameSuffixEnum | null;
};

export type UserProfileData = {
  phoneNumber: string;
  firstName: string;
  middleInitial?: string | null;
  suffix?: NameSuffixEnum | null;
  lastName: string;
  dateOfBirth: string;
  homeAddress: MailingAddress;
  countryOfCitizenship: string;
  primarySsn?: string;
  annualIncome?: number;
  liquidNetWorth?: LiquidNetWorthEnum;
};

export const buildFullName = ({
  firstName,
  middleInitial,
  lastName,
  suffix,
}: BuildFullNameInput): string => {
  const formattedSuffix = formatSuffix(suffix);

  return [firstName, middleInitial, lastName, formattedSuffix]
    .filter(Boolean)
    .join(' ');
};

export const formatAddressAsAnArray = (address: MailingAddress): string[] => {
  const { lineOne, lineTwo, city, stateOrProvince, postalCode, country } =
    address;
  const formattedAddress = [];

  formattedAddress.push(lineOne);

  if (lineTwo) {
    formattedAddress.push(lineTwo);
  }

  formattedAddress.push(`${city}, ${stateOrProvince} ${postalCode}`);
  formattedAddress.push(country);

  return formattedAddress;
};

const formatLiquidNetWorth = (liquidNetWorth: LiquidNetWorthEnum): string => {
  const liquidNetWorthEnumEntries = getEnumEntries('LiquidNetWorthEnum');
  const liquidNetWorthEntry = liquidNetWorthEnumEntries?.find(
    (entry) => entry.name === liquidNetWorth,
  );
  return liquidNetWorthEntry?.description ?? '';
};

export const getKeyValuePairsFromInput = (
  input: UserProfileData,
): Array<SectionRowData> => {
  const results = [
    {
      label: 'Legal name',
      value: buildFullName({
        firstName: input.firstName,
        lastName: input.lastName,
        middleInitial: input.middleInitial || null,
        suffix: input.suffix || null,
      }),
    },
    {
      label: 'Address',
      value: input.homeAddress ? formatAddressAsAnArray(input.homeAddress) : [],
    },
    {
      label: 'Date of birth',
      value: input.dateOfBirth,
    },
  ];

  if (input.phoneNumber) {
    results.unshift({
      label: 'Phone',
      value: formatPhoneNumber(input.phoneNumber),
    });
  }

  if (input.annualIncome) {
    results.push({
      label: 'Annual income',
      value: formatCurrencyWithCommasOrNull(input.annualIncome) ?? '',
    });
  }

  if (input.liquidNetWorth) {
    results.push({
      label: 'Available cash',
      value: formatLiquidNetWorth(input.liquidNetWorth),
    });
  }

  if (input.primarySsn) {
    results.push({
      label: 'SSN',
      value: maskSsn(input.primarySsn),
    });
  }

  return results;
};

export const mapResponseToUserProfileData = (
  profile: IdentityFirstConfirmationProfileFragment,
): UserProfileData => {
  const { primary } = profile;

  return {
    phoneNumber: primary?.phoneNumber,
    firstName: primary?.firstName,
    middleInitial: primary?.middleInitial,
    suffix: primary?.suffix as NameSuffixEnum | null,
    lastName: primary?.lastName,
    dateOfBirth: primary?.dateOfBirth
      ? moment(primary?.dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY')
      : '',
    homeAddress: primary?.homeAddress as MailingAddress,
    countryOfCitizenship: primary?.countryOfCitizenship,
  };
};
