import * as React from 'react';

import { useCollectPrimaryDisclosuresStepQuery } from '~/graphql/hooks';

import { CollectDisclosuresChild } from './CollectDisclosuresChild';

type CollectPrimaryDisclosuresStepProps = {
  onFinishStep?: () => void;
};
export const CollectPrimaryDisclosuresStep = ({
  onFinishStep,
}: CollectPrimaryDisclosuresStepProps) => {
  const { data } = useCollectPrimaryDisclosuresStepQuery({
    fetchPolicy: 'network-only',
  });
  const queriedPrimaryProfile = data?.viewer.profile?.primary;

  React.useEffect(() => {
    // prevent user from going back on this screen, as they cannot edit their identity profile
    return () => {
      history.go(1);
    };
  }, []);

  return (
    <CollectDisclosuresChild
      profile={queriedPrimaryProfile}
      holder="primary"
      title="Do any of these apply?"
      body="Per federal regulations, we are required to ask for these disclosures. For most people, none of these will apply."
      onSubmit={() => onFinishStep?.()}
    />
  );
};
