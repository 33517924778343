import { Box, Button, Flex, HM, HXXS, PL } from '@m1/liquid-react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { ControlledCheckbox } from '~/components/form/ControlledCheckbox';
import { ControlledDropdown } from '~/components/form/ControlledDropdown';
import { ControlledInput } from '~/components/form/ControlledInput';

import type { FinancialSuitabilityInfoFormValues } from '~/components/Onboarding/FinancialInformationConfirm';
import { invalidWhiteSpace, validInput } from '~/forms/validators';
import { FinancialInformationDueDiligenceFragment } from '~/graphql/types';

import { FIELD_MAX_LENGTHS } from '~/static-constants';
import { Checkbox } from '~/toolbox/checkbox';
import { Divider } from '~/toolbox/divider';
import { getEnumEntries } from '~/utils';

type FinancialSuitabilityFormProps = {
  defaultValues: FinancialSuitabilityInfoFormValues;
  occupationOptions:
    | NonNullable<
        FinancialInformationDueDiligenceFragment['dueDiligence']
      >['occupationDropdownOptions']
    | null
    | undefined;
  onSubmit: (formValues: Record<string, any>) => void;
};

export const FinancialSuitabilityForm = ({
  defaultValues,
  occupationOptions,
  onSubmit,
}: FinancialSuitabilityFormProps) => {
  const { control, handleSubmit, watch, setValue } =
    useForm<FinancialSuitabilityInfoFormValues>({
      defaultValues,
    });
  const [noDisclosures, setNoDisclosures] = React.useState(true);
  const values = watch();

  const {
    exchangeOrFinraAffiliationDisclosure,
    controlPersonDisclosure,
    politicalExposureDisclosure,
    isSubjectToBackupWithholding,
    employmentStatus,
    annualIncomeAmount,
  } = values;

  const isAffiliated =
    exchangeOrFinraAffiliationDisclosure?.isAffiliated ?? false;
  const isControlPerson = controlPersonDisclosure?.isControlPerson ?? false;
  const isPoliticallyExposed =
    politicalExposureDisclosure?.isPoliticallyExposed ?? false;
  const isEmployed =
    employmentStatus === 'EMPLOYED' || employmentStatus === 'SELF_EMPLOYED';
  const occupationsWithNames = occupationOptions?.map((option) => {
    return {
      name: option.label,
      description: option.label,
    };
  });

  const uncheckAllDisclosures = () => {
    setNoDisclosures(true);
    // exchangeOrFinraAffiliationDisclosure
    setValue('exchangeOrFinraAffiliationDisclosure.isAffiliated', false);
    setValue('exchangeOrFinraAffiliationDisclosure.firmName', null);

    // controlPersonDisclosure
    setValue('controlPersonDisclosure.isControlPerson', false);
    setValue('controlPersonDisclosure.companySymbols', null);

    // politicalExposureDisclosure
    setValue('politicalExposureDisclosure.isPoliticallyExposed', false);
    setValue('politicalExposureDisclosure.politicalOrganization', null);
    setValue('politicalExposureDisclosure.immediateFamilyMembers', null);

    // isSubjectToBackupWithholding
    setValue('isSubjectToBackupWithholding', false);
  };

  React.useEffect(() => {
    setNoDisclosures(
      !(
        isAffiliated ||
        isControlPerson ||
        isPoliticallyExposed ||
        (isSubjectToBackupWithholding ?? false)
      ),
    );
    // Clear out fields tied to any disclosures if it's unchecked
    if (!isAffiliated) {
      setValue('exchangeOrFinraAffiliationDisclosure.firmName', null);
    }
    if (!isControlPerson) {
      setValue('controlPersonDisclosure.companySymbols', null);
    }
    if (!isPoliticallyExposed) {
      setValue('politicalExposureDisclosure.politicalOrganization', null);
      setValue('politicalExposureDisclosure.immediateFamilyMembers', null);
    }
    setValue('annualIncomeAmount', Number(annualIncomeAmount));
  }, [
    isAffiliated,
    isControlPerson,
    isPoliticallyExposed,
    annualIncomeAmount,
    isSubjectToBackupWithholding,
    setValue,
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HM content="Edit financial information" pt={8} />
      <Flex pt={32} flexDirection="column">
        <HXXS content="Do any of these apply?" fontWeight={500} />
        <Divider spacing="compact" />
        <Box>
          <ControlledCheckbox
            name="exchangeOrFinraAffiliationDisclosure.isAffiliated"
            label="I am affiliated with or work for a broker/dealer"
            control={control}
          />
          {isAffiliated && (
            <ControlledInput
              name="exchangeOrFinraAffiliationDisclosure.firmName"
              label="Firm name"
              placeholder="M1 Finance, etc."
              control={control}
            />
          )}
        </Box>
        <Box>
          <ControlledCheckbox
            name="controlPersonDisclosure.isControlPerson"
            label="I am a 10% shareholder or director of a publicly traded company."
            control={control}
          />
          {isControlPerson && (
            <ControlledInput
              name="controlPersonDisclosure.companySymbols"
              label="Symbols"
              placeholder="GOOG, AMZN, META"
              control={control}
              valueIsArray
            />
          )}
        </Box>
        <Box>
          <ControlledCheckbox
            name="politicalExposureDisclosure.isPoliticallyExposed"
            label="I am or an immediate family member is a current or former Public Official."
            control={control}
          />
          {isPoliticallyExposed && (
            <>
              <ControlledInput
                name="politicalExposureDisclosure.politicalOrganization"
                label="Affiliated public entities"
                placeholder="e.g. Director of the Port Authority"
                control={control}
              />
              <ControlledInput
                name="politicalExposureDisclosure.immediateFamilyMembers"
                label="Names of immediate family members"
                placeholder="e.g. George Washington, President of USA"
                valueIsArray
                control={control}
              />
            </>
          )}
        </Box>
        <Box>
          <ControlledCheckbox
            name="isSubjectToBackupWithholding"
            label="I am subject to backup withholding by the IRS."
            my={8}
            control={control}
          />
        </Box>
        <Box>
          <Checkbox
            label={<PL fontWeight={600}>None of these describes me.</PL>}
            checked={noDisclosures}
            onChange={() => !noDisclosures && uncheckAllDisclosures()}
          />
        </Box>
        <Divider spacing="compact" />
        <Box>
          <ControlledDropdown
            name="employmentStatus"
            label="Employment status"
            placeholder="Select"
            control={control}
            source={getEnumEntries('EmploymentStatusEnum')}
            rules={{
              required: 'Required',
            }}
          />
        </Box>
        {isEmployed && (
          <>
            <Box>
              <ControlledInput
                name="employer"
                label="Employer"
                control={control}
                rules={{
                  required: 'Required',
                  maxLength: FIELD_MAX_LENGTHS.DEFAULT,
                  validate: (value: any): boolean =>
                    !invalidWhiteSpace.test(value) || validInput.test(value),
                }}
              />
            </Box>
            <Box>
              <ControlledDropdown
                name="occupation"
                label="Occupation/Industry"
                source={occupationsWithNames}
                control={control}
                rules={{
                  required: 'Required',
                }}
              />
            </Box>
          </>
        )}
        <>
          <Box>
            <ControlledInput
              name="annualIncomeAmount"
              label="Annual income"
              maskType="money"
              control={control}
              rules={{
                required: 'Required',
                maxLength: 11,
              }}
            />
          </Box>
          <Box>
            <ControlledDropdown
              name="totalNetWorth"
              label="Total net worth"
              placeholder="Select"
              source={getEnumEntries('TotalNetWorthEnum')}
              control={control}
              rules={{
                required: 'Required',
              }}
            />
          </Box>
          <Box>
            <ControlledDropdown
              name="liquidNetWorth"
              label="Liquid net worth"
              placeholder="Select"
              source={getEnumEntries('LiquidNetWorthEnum')}
              control={control}
              rules={{
                required: 'Required',
              }}
            />
          </Box>
          <Divider spacing="compact" />
          <Box mt={20}>
            <Button type="submit" label="Save" />
          </Box>
        </>
      </Flex>
    </form>
  );
};
