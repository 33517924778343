import { Button, PS } from '@m1/liquid-react';
import * as React from 'react';

import { InvestDisclosuresType } from '~/graphql/types';
import { useModal } from '~/hooks/useModal';

export const DisclosureLink = ({
  direction,
  isCrypto,
}: {
  direction: 'buy' | 'sell';
  isCrypto: boolean;
}) => {
  const { showModal } = useModal('TRADE_DISCLOSURES');

  return (
    <PS>
      Value may change slightly with {direction} timing.{' '}
      <Button
        kind="link"
        label="Learn more about how M1 trades and view important disclosures"
        size="small"
        onClick={() =>
          showModal({
            disclosuresFor: isCrypto
              ? InvestDisclosuresType.Crypto
              : InvestDisclosuresType.Securities,
          })
        }
      />
      .
    </PS>
  );
};
