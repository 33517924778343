import { Box, Button, Flex, HXS, PL } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';

import { useApplicationRejectedQuery } from '~/graphql/hooks';

type ApplicationRejectedProps = {
  onFinishStep: () => void;
};

export const ApplicationRejected = ({
  onFinishStep,
}: ApplicationRejectedProps) => {
  const { data, loading } = useApplicationRejectedQuery();
  if (loading) {
    return null;
  }

  if (!data?.viewer.credit) {
    return <GenericSystemError />;
  }

  const { activeApplication } = data.viewer.credit;

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      mt={150}
      justifyContent="center"
    >
      <Box width={600}>
        <HXS content="You are unable to be approved for an M1 Credit Card." />
        <PL mt={32}>
          At this time, you are unable to be approved for an M1 Credit Card. You
          will soon receive an email from M1’s underwriting agent with more
          details about this decision. You may apply again any time after{' '}
          <b>{activeApplication?.nextApplicationEligibilityDate}.</b>
        </PL>
        <Flex justifyContent="center">
          <Button
            kind="primary"
            label="Done"
            mt={64}
            onClick={onFinishStep}
            size="large"
          />
        </Flex>
      </Box>
    </Flex>
  );
};
