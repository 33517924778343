import { styled, Flex } from '@m1/liquid-react';

export const SpeedometerContainer = styled(Flex)`
  padding-top: 16px;
  padding-bottom: 16px;
  position: relative;
  height: 180px;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.MEDIUM}) {
    height: 150px;
  }
`;

export const SpeedometerSvg = styled.svg`
  overflow: visible !important;
  position: absolute;
  height: 160px;
  width: 299px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.MEDIUM}) {
    height: 120px;
    width: 225px;
  }
`;

export const SpeedometerInsideBorderSvg = styled.svg`
  overflow: visible !important;
  position: absolute;
  height: 132px;
  width: 250px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.MEDIUM}) {
    height: 100px;
    width: 1900px;
  }
`;

export const StyledContentContainer = styled(Flex)`
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  height: 90px;
  width: 180px;
  gap: 8px;
  p {
    font-size: 14px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.MEDIUM}) {
    height: 80px;
    width: 164px;
    gap: 0px;
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 12px;
    }
  }
`;
