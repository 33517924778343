import { Flex, styled, MarginProps, BoxProps } from '@m1/liquid-react';
import * as React from 'react';

export type ButtonGroupBehavior = 'none' | 'fill-space' | 'centered';

export type ButtonGroupProps = MarginProps & {
  behavior?: ButtonGroupBehavior;
  children: React.ReactNode;
} & BoxProps;

const StyledButtonGroupRoot = styled(Flex)<{ $behavior: ButtonGroupBehavior }>`
  gap: 12px;
  > * {
    ${({ $behavior }) => {
      if ($behavior === 'fill-space') {
        return `
          width: 100% !important;
          min-width: auto !important;
        `;
      }
      return '';
    }};
  }
  ${({ $behavior }) => {
    if ($behavior === 'centered') {
      return `justify-content: center`;
    }
    return '';
  }};
`;

export const ButtonGroup = ({
  behavior = 'none',
  children,
  ...rest
}: ButtonGroupProps) => {
  return (
    <StyledButtonGroupRoot {...rest} $behavior={behavior}>
      {children}
    </StyledButtonGroupRoot>
  );
};
