import { css, Flex, PL, styled } from '@m1/liquid-react';
import * as React from 'react';

import { AppImage } from '~/graphql/types';
import { Logo } from '~/toolbox/logo';

import { AppImage as AppImageComponent } from '../../lens-toolbox/AppImage';

import { formatContentDescription } from './utils';

type CommonTransactionDescriptionCellProps = {
  logoUrl?: Maybe<string>;
  logoStyles?: Maybe<React.CSSProperties>;
  icon?: Maybe<AppImage>;
  content?: Maybe<string>;
  isHighlighted?: boolean;
};

const SlideContainer = styled(Flex)<{ isHighlighted?: boolean }>`
  margin-left: -32px;
  transition: margin-left 0.75s;

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      margin-left: 0;
    `}
`;

export const CommonTransactionDescriptionCell = ({
  logoUrl,
  logoStyles,
  icon,
  content,
  isHighlighted,
}: CommonTransactionDescriptionCellProps) => {
  const IconComponent = icon ? (
    <AppImageComponent pl={isHighlighted ? 0 : 32} appImage={icon} />
  ) : null;
  const formattedContent = formatContentDescription(content);

  return (
    <SlideContainer
      justifyContent="flex-start"
      alignItems="center"
      gap={16}
      isHighlighted={isHighlighted}
    >
      {logoUrl ? (
        <Logo
          style={{
            ...logoStyles,
            borderRadius: '4px',
            paddingBottom: 0,
            userSelect: 'none',
            marginLeft: isHighlighted ? 0 : '32px',
          }}
          content={logoUrl}
          placeholder=""
        />
      ) : (
        IconComponent
      )}
      <PL
        pl={isHighlighted || logoUrl ? 0 : 32}
        content={formattedContent}
        color="foregroundNeutralMain"
      />
    </SlideContainer>
  );
};
