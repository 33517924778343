import { Flex, css, styled } from '@m1/liquid-react';

export const PeriodSelectorContainer = styled.div`
  background: ${({ theme }) => theme.colors.backgroundNeutralSecondary};
  border-radius: 16px;
  display: inline-flex;
  padding: 1px;
`;

type PeriodProps = {
  $isActive: boolean;
  onClick: () => void;
};

export const Period = styled(Flex)<PeriodProps>(
  ({ $isActive, theme }) => css`
    font-size: 12px;
    height: 30px;
    line-height: normal;
    position: relative;
    width: 37px;

    :first-child,
    :last-child {
      width: 30px;
    }

    &::before {
      border-radius: 50%;
      content: '';
      position: absolute;
    }

    ${$isActive
      ? css`
          color: ${theme.colors.foregroundNeutralInverse};

          &::before {
            background-color: ${theme.colors.primary};
            height: 38px;
            left: -1px;
            pointer-events: none;
            top: -4px;
            width: 38px;
          }

          :first-child,
          :last-child {
            &::before {
              left: -4px;
            }
          }
        `
      : css`
          color: ${theme.colors.foregroundNeutralMain};

          &:hover {
            cursor: pointer;

            &::before {
              background-color: ${theme.colors.foregroundNeutralTertiary};
              height: 30px;
              width: 30px;
            }
          }
        `};
  `,
);

export const Label = styled.span`
  z-index: 1;
`;
