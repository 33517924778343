import { css, Flex, styled, Text } from '@m1/liquid-react';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';

import { Link, type LinkProps } from '../link';

export type NavigationItem = LinkProps & {
  label?: React.ReactNode;
};

export type NavigationProps = React.PropsWithChildren<{
  items?: NavigationItem[];
  orientation: 'horizontal' | 'vertical';
}>;

const StyledNav = styled.nav<{ $orientation: 'horizontal' | 'vertical' }>`
  display: flex;
  flex-direction: ${({ $orientation }) =>
    $orientation === 'horizontal' ? 'row' : 'column'};
  align-items: stretch;

  @media screen and (${RESPONSIVE_BREAKPOINTS.SMALL}) {
    flex-wrap: wrap;
    justify-content: center;
  }

  a {
    color: ${(props) => props.theme.colors.foregroundNeutralMain};
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    min-width: 96%;
  }
`;

const linkStyles = css`
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border-left: 4px solid
    ${({ theme }) => theme.colors.backgroundNeutralTertiary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundNeutralTertiary};
    color: ${({ theme }) => theme.colors.foregroundNeutralMain};
  }
`;

const StyledNavLink = styled(NavLink)`
  ${linkStyles}

  &.active {
    border-left: 4px solid ${({ theme }) => theme.colors.secondary};
  }
`;

const StyledLink = styled(Link)`
  ${linkStyles}
`;

const isExternalLink = (link: string) => /^https?/.test(link);

export const Navigation = ({
  children,
  items,
  orientation,
}: NavigationProps) => {
  if (!items) {
    return children ?? null;
  }

  return (
    <StyledNav $orientation={orientation}>
      {items.map(({ label, onClick, to, ...rest }) => {
        const pathname = typeof to === 'string' ? to : to.pathname;
        if (typeof pathname !== 'string') {
          return null;
        }

        return (
          <Flex key={pathname} onClick={onClick}>
            {isExternalLink(pathname) ? (
              <StyledLink
                title={`View ${label}`}
                target="_blank"
                to={to}
                fontWeight={400}
                font="PL"
                lineHeight="20px"
                {...rest}
              >
                {label}
              </StyledLink>
            ) : (
              <StyledNavLink to={to} {...rest}>
                <Text fontWeight={400} font="PL" lineHeight="20px">
                  {label}
                </Text>
              </StyledNavLink>
            )}
          </Flex>
        );
      })}
    </StyledNav>
  );
};
