import * as React from 'react';

import { useMultiStepForm } from '~/components/form/MultiStepForm';

import type {
  AcatSteps,
  AcatWizardFields,
  UseAcatFormProps,
} from '../AcatWizard.types';

import { AcatWizardConfirmedAssetsStep } from '../steps/AcatWizardConfirmedAssetsStep';
import { AcatWizardConfirmTransferStep } from '../steps/AcatWizardConfirmTransferStep';
import { AcatWizardContraBrokerageDetailsStep } from '../steps/AcatWizardContraBrokerageDetailsStep';
import { AcatWizardDestinationAccountStep } from '../steps/AcatWizardDestinationAccountStep';
import { AcatWizardRolloverInitiateStep } from '../steps/AcatWizardRolloverInitiateStep';
import { AcatWizardRolloverOverviewStep } from '../steps/AcatWizardRolloverOverviewStep';
import { AcatWizardSelectAssetsStep } from '../steps/AcatWizardSelectAssetsStep';
import { AcatWizardSelectContraBrokerageStep } from '../steps/AcatWizardSelectContraBrokerageStep';
import { AcatWizardSelectTransferTypeStep } from '../steps/AcatWizardSelectTransferTypeStep';

export const useAcatWizardForm = ({ defaultValues }: UseAcatFormProps) => {
  // Wizard doesn't facilitate flow-forks very well (the `next` method assumes a linear flow)
  const steps: Record<AcatSteps, React.ReactElement> = {
    'select-transfer-type': <AcatWizardSelectTransferTypeStep />,
    'select-contra': <AcatWizardSelectContraBrokerageStep />,
    'contra-details': <AcatWizardContraBrokerageDetailsStep />,
    'destination-account': <AcatWizardDestinationAccountStep />,
    'select-assets': <AcatWizardSelectAssetsStep />,
    'acat-confirm': <AcatWizardConfirmTransferStep />,
    'rollover-overview': <AcatWizardRolloverOverviewStep />,
    'rollover-initiate': <AcatWizardRolloverInitiateStep />,
    'acat-wizard-confirmed-assets': <AcatWizardConfirmedAssetsStep />,
  };
  const acatWizardForm = useMultiStepForm<AcatWizardFields, AcatSteps>(steps, {
    defaultValues,
  });

  return acatWizardForm;
};
