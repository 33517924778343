import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import { DiscoverSearch } from '~/components/research';
import { SliceableStatusEnum, SliceableTypeEnum } from '~/graphql/types';

export const TopNavResearch = () => {
  return (
    <Flex alignItems="center" height="60px">
      <DiscoverSearch
        filterStatuses={[SliceableStatusEnum.Active]}
        filterTypes={[
          SliceableTypeEnum.EquitySecurity,
          SliceableTypeEnum.FundSecurity,
          SliceableTypeEnum.SystemPie,
          SliceableTypeEnum.UserPie,
        ]}
      />
    </Flex>
  );
};
