import { Box, PS } from '@m1/liquid-react';

import * as React from 'react';

import { SliceableNameCell } from '~/components/pie';
import { OnDemandTradeFragment } from '~/graphql/types';
import { AppPill } from '~/lens-toolbox/AppPill';

import { GridTable } from '~/toolbox/grid-table';
import { formatNumber } from '~/utils';

const formatCryptoValue = (value: number | null | undefined) => {
  return value
    ? `$${formatNumber(
        value,
        value < 1 ? '0,0.00[00000000]' : '0,0.00',
      ).toUpperCase()}`
    : '--';
};

export const OnDemandAllocatedTrade = ({
  trade,
}: {
  trade: OnDemandTradeFragment;
}) => {
  const {
    allocatedQuantity,
    allocatedPrice,
    allocatedAmount,
    of,
    note,
    statusPill,
  } = trade;
  const symbol = of?.symbol ?? '';
  return (
    <>
      <GridTable.Cell>
        <SliceableNameCell sliceable={of} />
      </GridTable.Cell>
      <GridTable.Cell textAlign="right">
        <Box>{statusPill && <AppPill appPill={statusPill} />}</Box>
        {note && <PS mt={4}>{note}</PS>}
      </GridTable.Cell>
      <GridTable.Cell textAlign="right">
        {formatCryptoValue(allocatedPrice)}
      </GridTable.Cell>
      <GridTable.Cell textAlign="right">
        {typeof allocatedQuantity === 'number'
          ? `${allocatedQuantity} ${symbol}`
          : '--'}
      </GridTable.Cell>
      <GridTable.Cell textAlign="right">
        {formatCryptoValue(allocatedAmount)}
      </GridTable.Cell>
    </>
  );
};
