import { Flex, HXS, HXXS, PL } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import moment from 'moment';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { SmartTransferRulesTable } from '~/components/SmartTransfersRulesTable';
import { TransfersRulesTable } from '~/components/TransfersRulesTable';
import {
  useTransfersRulesPageQuery,
  useUpdateUserDataMutation,
} from '~/graphql/hooks';
import { Pill } from '~/toolbox/Pill';
import { Spinner } from '~/toolbox/spinner';

export const TransfersRulesPage = () => {
  const { data, loading } = useTransfersRulesPageQuery({
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  const [updateUserData] = useUpdateUserDataMutation();
  React.useEffect(
    () => () => {
      updateUserData({
        variables: {
          input: {
            entries: [
              {
                key: 'smartTransferRuleViewDate',
                value: moment().toISOString(),
              },
            ],
          },
        },
      });
    },
    [updateUserData],
  );

  if (loading) {
    return <Spinner fullScreen />;
  }
  const viewer = data?.viewer;

  if (!viewer?.transfers) {
    return <GenericSystemError />;
  }

  const { scheduledTransferRules, smartTransferRules } = viewer.transfers;
  const shouldDisplayNewPill = viewer.user?.data
    ? !viewer.user.data.some(
        (dataPoint) => dataPoint.key.indexOf('smartTransferRuleViewDate') > -1,
      )
    : false;

  const shouldRenderSmartTransfers = Boolean(
    smartTransferRules && smartTransferRules.length,
  );

  // render transfer schedules if we have smart transfers or if do not have smart transfers but have schedules transfers
  const shouldRenderTransferRules = Boolean(
    shouldRenderSmartTransfers ||
      (scheduledTransferRules && scheduledTransferRules.length > 0),
  );

  // render an an empty state if we are not showing any rules
  const shouldRenderEmptyState = Boolean(
    !shouldRenderSmartTransfers && !shouldRenderTransferRules,
  );

  if (shouldRenderEmptyState) {
    return (
      <Flex
        alignItems="center"
        flexDirection="column"
        mx="auto"
        my={64}
        width={464}
      >
        <Illustration height={240} name="noTransferRulesCreated" />
        <HXS content="No transfer rules have been created" mt={64} />
        <PL
          color="foregroundNeutralMain"
          content="Press move money to create a new transfer rule."
          mt={32}
          textAlign="center"
        />
      </Flex>
    );
  }

  return (
    <>
      {shouldRenderTransferRules && (
        <>
          <Flex alignItems="center" justifyContent="space-between" mb={16}>
            <HXXS
              content="Recurring transfers"
              fontWeight={300}
              color="foregroundNeutralSecondary"
            />
          </Flex>
          {scheduledTransferRules && (
            <TransfersRulesTable
              scheduledTransferRules={scheduledTransferRules}
            />
          )}
        </>
      )}
      {shouldRenderSmartTransfers && (
        <>
          <Flex alignItems="center" mb={16} mt={32}>
            <HXXS
              content="Smart Transfers"
              fontWeight={300}
              color="foregroundNeutralSecondary"
            />
            {shouldDisplayNewPill && (
              <Pill kind="new" label="New" ml={8} size="small" />
            )}
          </Flex>
          {smartTransferRules && (
            <SmartTransferRulesTable smartTransferRules={smartTransferRules} />
          )}
        </>
      )}
    </>
  );
};
