import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { RenderFailurePage } from '~/components/render-failure-page';
import { LoginRoute } from '~/pages/login';
import { LogoutRoute } from '~/pages/logout';
import { MaintenanceRoute } from '~/pages/maintenance';
import { NotFoundRoute } from '~/pages/not-found';
import { PlaidRedirectRoute } from '~/pages/plaid-redirect';
import { SharePieRoute } from '~/pages/share';
import { VerifyEmailRoute } from '~/pages/verify-email';

import { AppLayout } from './AppLayout';
import { DashboardRoutes } from './dashboard';
import { ForgotPasswordRoute } from './forgot-password';
import { OnboardingRoute } from './onboarding';
import { PersonalLoanDirectWizardRoute } from './personal-loan-direct';
import { PersonalLoanApplicationErrorRoute } from './personal-loan-direct/error-routes/PersonalLoanErrorRoute';
import { SignupRoutes } from './signup';
import { StartRoute } from './start';

export const AppRoute = () => {
  return (
    <Route
      path="/"
      element={<AppLayout />}
      errorElement={<RenderFailurePage />}
    >
      <Route index element={<Navigate to="/d" replace />} />
      {ForgotPasswordRoute()}
      {DashboardRoutes()}
      {LoginRoute()}
      {LogoutRoute()}
      {MaintenanceRoute()}
      {OnboardingRoute()}
      {StartRoute()}
      {PlaidRedirectRoute()}
      {SharePieRoute()}
      {SignupRoutes()}
      {PersonalLoanDirectWizardRoute()}
      {VerifyEmailRoute()}
      {PersonalLoanApplicationErrorRoute()}
      {NotFoundRoute()}

      <Route
        path="savings-onboarding"
        element={<Navigate to="/onboarding/savings-onboarding" replace />}
      />
      <Route
        path="d/funding"
        element={<Navigate to="d/invest/funding" replace />}
      />
      <Route
        path="d/funding/*"
        element={<Navigate to="d/invest/funding" replace />}
      />
      <Route
        path="activate-your-card"
        element={<Navigate to="d/c/activate-credit-card" replace />}
      />
      <Route
        path="d/open-invest-account"
        element={<Navigate to="d/c/open-account" replace />}
      />
      <Route
        path="savings-onboarding"
        element={<Navigate to="onboarding/savings-onboarding" replace />}
      />
    </Route>
  );
};
