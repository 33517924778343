import { Box, Button, HM, HXXS, PL, PM, PXL, styled } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useLoanOffersAndSubmitPageQuery } from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useSelector } from '~/redux/hooks';
import { Container } from '~/toolbox/container';
import { Radio } from '~/toolbox/radio';
import { Spinner } from '~/toolbox/spinner';

import {
  StyledApplicationBottomCardContainer,
  StyledApplicationLeftCardContainer,
  StyledApplicationRightCardContainer,
  StyledApplicationTopCardContainer,
} from '../components/ApplicationCard';
import { StyledApplicationContainer } from '../components/ApplicationContainer';
import { SaveAndCloseButton } from '../components/SaveAndCloseButton';
import { WithdrawButton } from '../components/WithdrawButton';

type LoanOffersAndSubmitPageProps = {
  onFinishStep: (arg0: {
    applicationId: string;
    offerId?: string | null | undefined;
    withdrawingApplication?: boolean;
  }) => void;
};

const StyledContainer = styled(Container)`
  min-height: 600px;
`;

export const LoanOffersAndSubmitPage = ({
  onFinishStep,
}: LoanOffersAndSubmitPageProps) => {
  const analytics = useAnalytics();
  const [loanOption, setLoanOption] = React.useState<string | null | undefined>(
    null,
  );
  const { applicationId } = useSelector(
    (state) => state.newFlows.PERSONAL_LOANS_APPLICATION,
  );

  const { data, loading } = useLoanOffersAndSubmitPageQuery();
  if (loading) {
    return <Spinner fullScreen centered />;
  }

  const offers =
    data?.viewer.borrow?.personalLoans?.activeApplication?.applicationOffers
      ?.edges;

  const creditCheckDisclaimer =
    data?.viewer.borrow?.personalLoans?.activeApplication
      ?.creditCheckDisclaimer;

  if (!offers || offers.length === 0) {
    return <GenericSystemError />;
  }

  const handleChange = (value: string): void => {
    setLoanOption(value);
  };
  return (
    <StyledContainer>
      <Box height={48} mb={-48}>
        <WithdrawButton
          mt={24}
          onFinish={() => {
            onFinishStep({
              applicationId,
              withdrawingApplication: true,
            });
          }}
        />
      </Box>

      <StyledApplicationContainer>
        <StyledApplicationTopCardContainer
          style={{
            paddingBottom: '24px',
          }}
        >
          <StyledApplicationLeftCardContainer>
            <HM>You’re pre-approved</HM>
            <PXL mt={24} mb={48}>
              {creditCheckDisclaimer}
            </PXL>

            <Radio.Group onChange={handleChange} value={loanOption}>
              {offers.map((offer, i) => {
                const offerDetails = offer?.node;
                return (
                  <Radio.Choice
                    key={offerDetails?.offerTitle}
                    label={
                      <>
                        <HXXS
                          fontWeight={600}
                          alignItems="center"
                          content={<>{offerDetails?.offerTitle}</>}
                        />
                        <PL
                          alignItems="center"
                          content={<>{offerDetails?.offerSubtitle}</>}
                          color="foregroundNeutralSecondary"
                        />
                      </>
                    }
                    value={offerDetails?.id}
                    border={i !== 0}
                  />
                );
              })}
            </Radio.Group>
            {/* TODO: SHOULD BE UPDATED TO BE LENS DRIVEN ONCE AVAILABLE */}
            <PM mt={64} color="foregroundNeutralSecondary">
              B2 Bank, NA, Member FDIC, Equal Opportunity Lender.
            </PM>
          </StyledApplicationLeftCardContainer>
          <StyledApplicationRightCardContainer>
            <Illustration name="loanApproved" />
          </StyledApplicationRightCardContainer>
        </StyledApplicationTopCardContainer>
        <StyledApplicationBottomCardContainer>
          <Button
            kind="primary"
            size="large"
            label="Submit application"
            type="submit"
            disabled={Boolean(!loanOption)}
            onClick={() => {
              analytics.recordEvent('m1_personal_loan_application_submitted');
              onFinishStep({
                offerId: loanOption,
                applicationId: applicationId,
              });
            }}
          />
          <SaveAndCloseButton />
        </StyledApplicationBottomCardContainer>
      </StyledApplicationContainer>
    </StyledContainer>
  );
};
