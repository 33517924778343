import {
  Button,
  css,
  Flex,
  PM,
  SemanticColorNames,
  styled,
  Input,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { useDispatch } from '~/redux/hooks';
import { clamp, isNumberString } from '~/utils';

export type PercentageAdjustorProps = {
  allowZeroPercentSlices?: boolean;
  onPercentageChange?: (percentage: number) => void;
  fromPercentage: Maybe<number>;
  showButtons: Maybe<boolean>;
  percentage: Maybe<number>;
  backgroundColor?: SemanticColorNames;
};

const PercentageButton = styled(Button)`
  padding: 0 8px;
  height: unset;

  > span {
    margin-right: 0;
  }
`;

const FromPercentage = styled(PM)`
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: center;
  padding-left: 8px;
  padding-right: 60%;
  pointer-events: none;
`;

const PercentageContainer = styled(Flex)<{ $showFromPercentage: boolean }>`
  width: 96px;
  position: relative;
  text-align: center;
  ${({ $showFromPercentage }) =>
    $showFromPercentage &&
    css`
      && input {
        padding-left: 65%;
        padding-right: 0;
      }
    `}
`;

const PercentageInput = styled(Input)`
  transition: background-color
    ${({ backgroundColor }) => (backgroundColor ? 0 : 1)}s;
`;

export const PercentageAdjustor = ({
  allowZeroPercentSlices,
  onPercentageChange,
  fromPercentage,
  showButtons,
  percentage,
  backgroundColor,
}: PercentageAdjustorProps) => {
  const dispatch = useDispatch();
  const percentageMin = allowZeroPercentSlices ? 0 : 1;
  const [inputIsEmpty, setInputIsEmpty] = React.useState<boolean>(false);

  const onStepwisePercentageAdjustorClick = React.useCallback(
    (
      e: React.SyntheticEvent<HTMLButtonElement>,
      operation: 'subtract' | 'add',
    ) => {
      // prevent click event from bubbling the chain.
      // this is done to allow our draggable click handler to safely execute.
      e.stopPropagation();

      if (typeof percentage === 'number') {
        const newPercentage =
          operation === 'subtract'
            ? Math.max(percentageMin, percentage - 1)
            : Math.max(percentageMin, percentage + 1);
        onPercentageChange?.(newPercentage);
      }
    },
    [dispatch, percentageMin, percentage],
  );

  const onBlur = React.useCallback(() => {
    if (inputIsEmpty) {
      setInputIsEmpty(false);

      onPercentageChange?.(percentageMin);
    }
  }, [dispatch, inputIsEmpty, percentageMin, percentage]);

  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === '') {
        setInputIsEmpty(true);
      } else if (isNumberString(event.target.value)) {
        const percentage = clamp(
          Number(event.target.value),
          percentageMin,
          100,
        );

        if (inputIsEmpty) {
          setInputIsEmpty(false);
        }

        onPercentageChange?.(percentage);
      }
    },
    [dispatch, inputIsEmpty, percentageMin, percentage],
  );

  return typeof percentage !== 'number' ? null : (
    <Flex alignItems="center">
      {showButtons && (
        <PercentageButton
          onClick={(e) => onStepwisePercentageAdjustorClick(e, 'subtract')}
          kind="text"
          label=""
          leftIcon="minusBubble24"
        />
      )}
      <PercentageContainer
        position="relative"
        alignItems="center"
        $showFromPercentage={
          typeof fromPercentage === 'number' && fromPercentage !== percentage
        }
      >
        {typeof fromPercentage === 'number' && (
          <>
            <FromPercentage color="foregroundNeutralTertiary" zIndex="1">
              {fromPercentage}
            </FromPercentage>
            <Icon
              position="absolute"
              left="calc(50% - 10px)"
              name="arrowRight20"
              color="foregroundNeutralMain"
              zIndex="1"
            />
          </>
        )}
        <PercentageInput
          maxLength={3}
          onClick={(e: any) => e.stopPropagation()}
          onBlur={onBlur}
          onChange={onChange}
          value={inputIsEmpty ? '' : percentage}
          backgroundColor={backgroundColor}
          size="small"
          style={{ textAlign: 'center' }}
        />
      </PercentageContainer>
      {showButtons && (
        <PercentageButton
          onClick={(e) => onStepwisePercentageAdjustorClick(e, 'add')}
          kind="text"
          label=""
          leftIcon="addBubble24"
        />
      )}
    </Flex>
  );
};
