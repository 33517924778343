import { HM, PL } from '@m1/liquid-react';
import capitalize from 'lodash-es/capitalize';
import * as React from 'react';

import { TransferRuleDetailFragment } from '~/graphql/types';
import { formatNumber } from '~/utils';

export const RuleHeaderDetails = ({
  isAutoPay,
  transferRule,
}: {
  isAutoPay: boolean;
  transferRule: TransferRuleDetailFragment;
}) => {
  switch (transferRule.__typename) {
    case 'ScheduledTransferRule':
      return (
        <>
          <PL as="h5" color="foregroundNeutralMain" content="Amount" />
          <HM
            content={
              isAutoPay &&
              transferRule.schedulePresetIndicator &&
              transferRule.schedulePresetIndicator !== 'FIXED_AMOUNT'
                ? capitalize(
                    transferRule.schedulePresetIndicator.replace('_', ' '),
                  )
                : formatNumber(transferRule.amount, '$0,0[.]00')
            }
            style={{
              marginTop: 8,
            }}
          />
        </>
      );
    default:
      return null;
  }
};
