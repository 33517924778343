import React from 'react';
import {
  NavigateOptions,
  Params,
  // eslint-disable-next-line no-restricted-imports
  useNavigate as useRouterNavigate,
} from 'react-router-dom';

import { buildPath } from '~/router';

export type NavigatePropsObject = {
  to: string;
  params?: Params;
  query?: Record<string, string>;
  options?: NavigateOptions;
};

export type Navigate = NavigatePropsObject | number;
export type NavigateFunction = (navigateProps: Navigate) => void;

export const useNavigate = () => {
  const routerNavigate = useRouterNavigate();
  const navigate = React.useCallback(
    (navigateProps: Navigate) => {
      if (typeof navigateProps === 'number') {
        routerNavigate(navigateProps);
      } else {
        const { to, params, query, options } = navigateProps;
        routerNavigate(buildPath(to, query, params), options);
      }
    },
    [routerNavigate],
  );

  return navigate;
};
