import { Box, SkeletonProvider, TwoColumn4by8 } from '@m1/liquid-react';
import * as React from 'react';

import { AnnouncementContext } from '~/components/announcement';
import { useMarginLandingPageQuery } from '~/graphql/hooks';
import {
  AnnouncementContextEnum,
  MarginLandingPageQuery,
} from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { useSelector } from '~/redux/hooks';

import { BorrowUnavailableContainer } from './BorrowUnavailableContainer';
import { MarginLandingPageHeader } from './MarginLandingPageHeader';
import { MarginLandingPageLeftColumn } from './MarginLandingPageLeftColumn';
import { MarginLandingPageRightColumn } from './MarginLandingPageRightColumn';

export const MarginLandingPage = () => {
  const borrowAccountId = useSelector((state) => state.global.borrowAccountId);
  const { data, loading } = useMarginLandingPageQuery({
    variables: {
      id: borrowAccountId as string,
    },
    skip: !borrowAccountId,
    fetchPolicy: 'cache-and-network',
  });
  const navigate = useNavigate();

  const borrowAccount = data?.node as Maybe<
    ExtractTypename<MarginLandingPageQuery['node'], 'BorrowAccount'>
  >;

  if (!loading) {
    if (
      !borrowAccount ||
      typeof borrowAccount.hasBorrowedBefore !== 'boolean' ||
      !data?.viewer
    ) {
      return <BorrowUnavailableContainer />;
    }

    if (!borrowAccount.hasBorrowedBefore) {
      navigate({
        to: '/d/borrow/marketing',
        query: {
          borrowAccountId: borrowAccount.id,
        },
      });
    }
  }

  const borrowAnnouncement = data?.viewer?.announcements?.forBorrow;

  const { investAccount } = borrowAccount ?? {};
  const {
    title,
    availableToBorrow,
    loanInterest,
    health,
    header,
    accountDetails,
    primaryCta,
    secondaryCta,
    banners,
    howBillingWorks,
    promotionCards,
    priorityPromotionCards,
  } = borrowAccount?.marginProductLandingPage ?? {};

  return (
    <SkeletonProvider isLoading={loading}>
      <MarginLandingPageHeader
        title={title}
        header={header}
        accountDetails={accountDetails?.[0]}
        primaryCta={primaryCta}
        secondaryCta={secondaryCta}
        banners={banners}
      />
      <Box mt={32}>
        {borrowAnnouncement && (
          <AnnouncementContext
            announcement={borrowAnnouncement}
            context={AnnouncementContextEnum.Borrow}
            mb={16}
          />
        )}
        <TwoColumn4by8>
          <MarginLandingPageLeftColumn
            availableToBorrow={availableToBorrow}
            loanInterest={loanInterest}
            howBillingWorks={howBillingWorks}
            promotionCards={promotionCards}
            priorityPromotionCards={priorityPromotionCards}
          />
          <MarginLandingPageRightColumn
            health={health}
            investAccountId={investAccount?.id}
          />
        </TwoColumn4by8>
      </Box>
    </SkeletonProvider>
  );
};
