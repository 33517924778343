import * as React from 'react';
import { formValues } from 'redux-form';

import { FIELD_MAX_LENGTHS } from '~/static-constants';
import { Collapse } from '~/toolbox/collapse';
import { Grid } from '~/toolbox/grid';
import { getEnumEntries } from '~/utils';

import { required, printableAsciiChars } from '../validators';

import { DropdownField } from './dropdown-field';
import { TextField } from './text-field';

type EmploymentFieldsType = {
  backgroundColor?: 'backgroundNeutralMain' | 'backgroundNeutralSecondary';
  dropdownOptions: ReadonlyArray<{
    label: string;
  }>;
  hideSlideAnimation?: boolean;
  modernLayout?: boolean | null | undefined;
};

export const EmploymentFields = (props: EmploymentFieldsType) => {
  const { dropdownOptions, hideSlideAnimation = false } = props;

  const occupationsWithNames = dropdownOptions.map((option) => {
    return {
      name: option.label,
      description: option.label,
    };
  });

  return (
    <div>
      <Grid.Row>
        <Grid.Col sm={12} xs={12}>
          <DropdownField
            backgroundColor={props.backgroundColor}
            name="status"
            label="Employment status"
            placeholder="Select"
            source={getEnumEntries('EmploymentStatusEnum')}
            validate={[required]}
          />
        </Grid.Col>
      </Grid.Row>
      {/* @ts-expect-error - TS2322 - Type '{ children: Element; hideSlideAnimation: boolean; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<{} & string, any, any>> & Readonly<{} & string>'. | TS2786 - 'RenderIfEmployed' cannot be used as a JSX component. */}

      <RenderIfEmployed hideSlideAnimation={hideSlideAnimation}>
        {!props.modernLayout ? (
          <Grid.Row>
            <Grid.Col xs={6}>
              <TextField
                backgroundColor={props.backgroundColor}
                name="employer"
                label="Employer"
                maxLength={FIELD_MAX_LENGTHS}
                validate={[required, printableAsciiChars]}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <DropdownField
                backgroundColor={props.backgroundColor}
                name="occupation"
                label="Occupation/Industry"
                source={occupationsWithNames}
                validate={[required]}
              />
            </Grid.Col>
          </Grid.Row>
        ) : (
          <>
            <Grid.Row
              style={{
                marginTop: 8,
              }}
            >
              <Grid.Col sm={12} xs={12}>
                <TextField
                  backgroundColor={props.backgroundColor}
                  name="employer"
                  label="Employer"
                  maxLength={FIELD_MAX_LENGTHS}
                  validate={[required, printableAsciiChars]}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row
              style={{
                marginTop: 16,
              }}
            >
              <Grid.Col sm={12} xs={12}>
                <DropdownField
                  backgroundColor={props.backgroundColor}
                  name="occupation"
                  label="Occupation/Industry"
                  source={occupationsWithNames}
                  validate={[required]}
                />
              </Grid.Col>
            </Grid.Row>
          </>
        )}
      </RenderIfEmployed>
    </div>
  );
};

const EmployerFields = (props: {
  children: React.ReactNode;
  hideSlideAnimation: boolean;
  status: string;
}) => {
  const isEmployed =
    props.status === 'EMPLOYED' || props.status === 'SELF_EMPLOYED';
  return (
    <>
      {props.hideSlideAnimation ? (
        <>{isEmployed && props.children}</>
      ) : (
        // @ts-expect-error - TS2769 - No overload matches this call.
        <Collapse isOpened={isEmployed} unmountOnClose>
          {isEmployed && props.children}
        </Collapse>
      )}
    </>
  );
};

// @ts-expect-error - TS2345 - Argument of type '(props: {    children: React.ReactNode;    hideSlideAnimation: boolean;    status: string;}) => JSX.Element' is not assignable to parameter of type 'ComponentType<{} & string>'.
const RenderIfEmployed = formValues('status')(EmployerFields);
