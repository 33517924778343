import {
  LayoutableProps,
  PM,
  PS,
  Flex,
  styled,
  HXXS,
  Box,
  Tooltip,
  Card,
} from '@m1/liquid-react';
import * as React from 'react';

import { PortfolioSliceFirstPositionSectionFragment } from '~/graphql/types';
import { useSelector } from '~/redux/hooks';
import { COST_BASIS_DISCLOSURE } from '~/static-constants/COST_BASIS_DISCLOSURE';

import { formatNumber, isNil } from '~/utils';

export type PortfolioSliceFirstPositionSectionProps = {
  portfolioSlice: PortfolioSliceFirstPositionSectionFragment;
};

const gutterSize = 16;

const Divider = styled(Box)`
  margin: 12px 0 0 0;
  border-top: 1px solid ${({ theme }) => theme.colors.borderMain};
`;

const StyledCard = styled(Card)`
  padding: 16px 20px;

  > div > div {
    width: calc(50% - ${0.5 * gutterSize}px);
    height: fit-content;

    &:last-of-type ${Divider} {
      display: none;
    }
  }
`;

export const PortfolioSliceFirstPositionSection = ({
  portfolioSlice,
}: PortfolioSliceFirstPositionSectionProps) => {
  const isCrypto = useSelector((state) => state.global.activeAccountIsCrypto);

  const allocatedPosition = readAllocatedPosition(portfolioSlice);

  if (!allocatedPosition) {
    return null;
  }

  return (
    <Flex flexDirection="column" mt={32}>
      <StyledCard>
        <HXXS
          content="Position"
          mb={16}
          fontWeight={300}
          color="foregroundSecondary"
        />
        <Flex flexWrap="wrap" gap={gutterSize}>
          <PositionMetric
            label={isCrypto ? 'Quantity' : 'Shares'}
            value={allocatedPosition.position.quantity}
          />
          {allocatedPosition.allocationPercent !== 100 ? (
            <PositionMetric
              label="% of total"
              value={
                allocatedPosition.allocationPercent > 0
                  ? allocatedPosition.allocationPercent.toFixed(1)
                  : 'Less than 0.1'
              }
            />
          ) : null}
          <PositionMetric
            label="Average share price"
            value={
              allocatedPosition.position.cost
                ? formatNumber(
                    allocatedPosition.position.cost.averageSharePrice,
                  )
                : '$--'
            }
          />
          <PositionMetric
            label="Cost"
            value={
              allocatedPosition.position.cost
                ? formatNumber(allocatedPosition.position.cost.cost)
                : '--'
            }
            tooltip={
              <Tooltip
                icon="tooltip20"
                iconColor="foregroundPrimary"
                body={<PM content={COST_BASIS_DISCLOSURE} px={16} py={12} />}
              />
            }
          />
          {allocatedPosition.position.marginability && (
            <>
              <PositionMetric
                label="Margin eligible"
                tooltip={
                  <Tooltip
                    icon="tooltip20"
                    iconColor="foregroundPrimary"
                    body={
                      <PM
                        content="Whether or not this security can be leveraged with M1 Borrow. If a symbol has a maintenance requirement of 100% it is not eligible for M1 Borrow."
                        px={16}
                        py={12}
                      />
                    }
                  />
                }
                value={
                  allocatedPosition.position.marginability.isMarginable
                    ? 'Yes'
                    : 'No'
                }
              />
              <PositionMetric
                label="Maintenance requirement"
                tooltip={
                  <Tooltip
                    icon="tooltip20"
                    iconColor="foregroundPrimary"
                    body={
                      <PM
                        content="The minimum amount of equity that must be maintained when leveraged with M1 Borrow."
                        px={16}
                        py={12}
                      />
                    }
                  />
                }
                value={`${allocatedPosition.position.marginability.maintenanceEquityRequirementPercent}%`}
              />
            </>
          )}
        </Flex>
      </StyledCard>
    </Flex>
  );
};

const PositionMetric = ({
  label,
  tooltip,
  value,
  ...rest
}: LayoutableProps & {
  label: string;
  tooltip?: React.ReactNode;
  value: React.ReactNode;
}) => (
  <Flex {...rest} flexDirection="column">
    <Flex alignItems="center" mb={4}>
      <PS content={label} color="foregroundNeutralSecondary" />
      {tooltip}
    </Flex>
    <PM content={value} fontWeight={600} />
    <Divider />
  </Flex>
);

const readAllocatedPosition = (
  portfolioSlice: PortfolioSliceFirstPositionSectionFragment,
):
  | NonNullable<
      NonNullable<
        NonNullable<
          PortfolioSliceFirstPositionSectionFragment['investments']
        >['allocatedPositions']['edges']
      >[number]
    >['node']
  | null
  | undefined => {
  const allocatedPositions =
    portfolioSlice.investments?.allocatedPositions.edges;
  if (isNil(allocatedPositions) || allocatedPositions.length < 1) {
    return null;
  }
  return allocatedPositions[0]?.node;
};
