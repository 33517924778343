import { Box, Button, HM, PXL } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { AspectRatioFrame } from '~/flows/components/personal-loans/application/components/AspectRatioFrame';
import {
  Cell,
  Divider,
  Grid,
  Stack,
} from '~/flows/components/personal-loans/application/components/Layout';
import { usePersonalLoansNotEligiblePageQuery } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { Spinner } from '~/toolbox/spinner';

export const PersonalLoansNotEligiblePage = () => {
  const navigate = useNavigate();
  const { data, loading } = usePersonalLoansNotEligiblePageQuery();
  if (loading) {
    return <Spinner fullScreen centered />;
  }

  const title =
    data?.viewer.borrow?.personalLoans?.applicationFlow?.loanIneligibilityScreen
      ?.title;

  const subtitle =
    data?.viewer.borrow?.personalLoans?.applicationFlow?.loanIneligibilityScreen
      ?.subtitle;

  const eligibilityCriteriaLink =
    data?.viewer.borrow?.personalLoans?.applicationFlow?.loanIneligibilityScreen
      ?.eligibilityCriteriaLink;

  if (!title || !subtitle) {
    return <GenericSystemError />;
  }

  return (
    <Box backgroundColor="backgroundNeutralSecondary" height="100vh">
      <Grid>
        <Stack column="left-start / span 6" css="gap: 16px;">
          <Cell as={HM}>{title}</Cell>
          <Cell vertical css="gap: 8px;">
            <PXL>{subtitle}</PXL>
          </Cell>
          {eligibilityCriteriaLink && (
            <Cell>
              <LinkableLink linkable={eligibilityCriteriaLink} />
            </Cell>
          )}
        </Stack>
        <AspectRatioFrame
          ratio="16:9"
          css="grid-column: span 5 / right-end; margin-block: auto;"
        >
          <Illustration name="bankConnectionPending" />
        </AspectRatioFrame>

        <Stack css="gap: 24px; align-items: flex-start;" column="content">
          <Divider />
          <Cell>
            <Button
              kind="primary"
              label="Return Home"
              onClick={() => {
                navigate({ to: '/d/home' });
              }}
              size="large"
            />
          </Cell>
        </Stack>
      </Grid>
    </Box>
  );
};
