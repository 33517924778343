import { SagaIterator } from 'redux-saga';
import { call, fork, select } from 'redux-saga/effects';

import { NavigateFunction } from '~/hooks/useNavigate';
import { AppState } from '~/redux/reducers';
import { WAITLIST_STEPS as STEPS } from '~/static-constants';

import { makeFlowFuncs } from '../utils';

const { takeFlow, takeFlowStep } = makeFlowFuncs('WAITLIST');

export function* waitlistSaga(): SagaIterator<void> {
  yield fork(takeFlow, beginWaitlistFlow);
}

function* beginWaitlistFlow(): SagaIterator<void> {
  yield fork(
    takeFlowStep,
    STEPS.WAITLIST_CONFIRMATION,
    finishedWaitlistConfirmation,
  );
}

function* finishedWaitlistConfirmation(): SagaIterator {
  const navigate: NavigateFunction = yield select(
    (state: AppState) => state.routing.navigate,
  );

  yield call(navigate, { to: '/d/invest' });
}
