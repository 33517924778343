import partial from 'lodash-es/partial';

import { UNSAFE_connectRedux } from '~/hocs';
import type { AppState } from '~/redux';
import { NewFlowsState } from '~/redux/reducers/newFlows';
import { Progress } from '~/toolbox/progress';

type FlowStateAccessor = (state: AppState) => ValueOf<NewFlowsState>;
export type StepProgressMap = Record<string, number>;

export function stepToProgress(
  stateAccessor: FlowStateAccessor,
  stepsToProgress: StepProgressMap,
) {
  const reader = partial(readProgress, stepsToProgress);
  const enhancer = UNSAFE_connectRedux((state: AppState): any => ({
    percent: reader(stateAccessor(state).step),
  }));

  return enhancer(Progress);
}

function readProgress(progressMap: StepProgressMap, step: string): number {
  return progressMap[step] || 5;
}
