import { HXS, Card } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { NavigableButton } from '~/components/NavigableButton';
import { useSupportLink } from '~/hooks/useSupportLink';

export const SelectContraBrokerageEmptyState = () => {
  const supportLink = useSupportLink('SUPPORT_TRANSFER_ACCOUNT');
  return (
    <Card alignItems="center" display="flex" flexDirection="column" p={32}>
      <HXS content="Get started with an account transfer" mb={32} />
      {/* @ts-expect-error - TS2322 - Type '{ alt: string; name: "accountTransfer"; mb: number; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'. */}
      <Illustration alt="Transfer Arrows" name="accountTransfer" mb={32} />
      <NavigableButton
        label="Submit a Transfer Request"
        size="large"
        {...supportLink}
      />
    </Card>
  );
};
