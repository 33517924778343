import { Box, Flex, HM, PXL, Button } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import React from 'react';

import { useNavigate } from '~/hooks/useNavigate';
import { Link } from '~/toolbox/link';

export const NoDividendsView = () => {
  const navigate = useNavigate();

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      pt={100}
      maxWidth={633}
      margin="0 auto"
    >
      <Illustration name="holdings" />
      <Box mt={66}>
        <HM>No dividend activity yet.</HM>
      </Box>
      <Box mt={16} mb={48} textAlign="center">
        <PXL>
          Track your dividend income, view projected earnings, and understand
          your performance here.
        </PXL>
      </Box>
      <Box mb={16}>
        <Button
          kind="secondary"
          label="Explore pre-built dividend portfolios"
          size="large"
          onClick={() =>
            navigate({ to: '/d/research/expert-pies/FIXED_INCOME' })
          }
        />
      </Box>
      <Link to="https://m1.com/blog/how-do-dividends-work/" target="_blank">
        Learn more about dividends
      </Link>
    </Flex>
  );
};
