import * as React from 'react';
import { Route } from 'react-router-dom';

import { PendingLiquidationRoute } from './pending-liquidation';
import { UnmanagedHoldingsPage } from './UnmanagedHoldingsPage';

export const UnmanagedHoldingsRoute = () => {
  return (
    <Route path="unmanaged-holdings">
      {PendingLiquidationRoute()}

      <Route index element={<UnmanagedHoldingsPage />} />
    </Route>
  );
};
