import moment from 'moment';
import React from 'react';

import { SecurityDetail_CryptoAsset_Fragment } from '~/graphql/types';

import { SecurityDataRow } from '../SecurityDataRow';

type CryptoProfileDataProps = {
  crypto: SecurityDetail_CryptoAsset_Fragment;
};

export const CryptoProfileData = ({ crypto }: CryptoProfileDataProps) => {
  const {
    coinType,
    createdDate,
    developmentStatus,
    orgStructure,
    hashAlgorithm,
    openSource,
  } = crypto;
  return (
    <>
      <SecurityDataRow headingContent="Coin type" value={coinType} />
      {createdDate && (
        <SecurityDataRow
          headingContent="Created date"
          value={moment(createdDate).format('ll')}
        />
      )}
      <SecurityDataRow
        headingContent="Development status"
        value={developmentStatus}
      />
      <SecurityDataRow headingContent="Open source" value={openSource} />
      <SecurityDataRow
        headingContent="Organization structure"
        value={orgStructure}
      />
      <SecurityDataRow headingContent="Hash algorithm" value={hashAlgorithm} />
    </>
  );
};
