export const BORROW_MARGIN_ACTIONS = Object.freeze({
  UPDATE_HAS_USER_DISMISSED_MARGIN_CALL_DIALOG:
    'UPDATE_HAS_USER_DISMISSED_MARGIN_CALL_DIALOG' as const,
});

export type UpdateHasUserDismissedMarginCallDialog = {
  payload: boolean;
  type: typeof BORROW_MARGIN_ACTIONS.UPDATE_HAS_USER_DISMISSED_MARGIN_CALL_DIALOG;
};

export const updateHasUserDismissedMarginCallDialog = (
  payload: UpdateHasUserDismissedMarginCallDialog['payload'],
): UpdateHasUserDismissedMarginCallDialog => ({
  type: BORROW_MARGIN_ACTIONS.UPDATE_HAS_USER_DISMISSED_MARGIN_CALL_DIALOG,
  payload: payload,
});

export type BorrowMarginAction = UpdateHasUserDismissedMarginCallDialog;
