import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  ACTION_TYPES,
  cleanupBeneficiaries,
  hideLoadingSpinner,
  showLoadingSpinner,
  UpdateProfileAction,
} from '~/redux/actions';

import { getLoggers } from './common';
import { updateUserProfile } from './common/updateUserProfile';

export function* updateProfileSaga(): SagaIterator {
  yield takeEvery(ACTION_TYPES.UPDATE_PROFILE, handleUpdateProfile);
}

function* handleUpdateProfile(action: UpdateProfileAction): SagaIterator {
  const { analytics, onSuccess, profile, successMessage } = action.payload;
  const { analytics: analyticsLogger } = yield call(getLoggers);

  try {
    yield put(showLoadingSpinner());

    const { name, event } = analytics
      ? analytics
      : { name: 'account', event: 'accountOwnershipUpdated' };

    const result = yield call(updateUserProfile, profile, true);

    if (result?.didSucceed) {
      analyticsLogger.mutation(name, event);

      // Make sure all 'marked for deletion' beneficiaries are removed from view
      // @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
      yield put(cleanupBeneficiaries());

      if (successMessage) {
        yield put({
          payload: {
            content: successMessage,
            duration: 'short',
            kind: 'success',
          },
          type: 'ADD_TOAST',
        });
      }

      if (onSuccess) {
        onSuccess();
      }
    } else {
      yield put({
        payload: {
          content:
            'Something went wrong. Please go back to verify what you entered is correct, or contact Client Support.',
          duration: 'short',
          kind: 'alert',
        },
        type: 'ADD_TOAST',
      });
    }
  } catch (e: any) {
    yield put({
      payload: {
        content: e.message,
        duration: 'short',
        kind: 'alert',
      },
      type: 'ADD_TOAST',
    });
  } finally {
    yield put(hideLoadingSpinner());
  }
}
