import {
  Box,
  Button,
  Flex,
  PL,
  PS,
  PXL,
  Card,
  Tooltip,
} from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { AppContext } from '~/AppContext';
import { useNavigate } from '~/hooks/useNavigate';
import { useDispatch } from '~/redux/hooks';

export const CryptoCreatePieCard = () => {
  const { analytics } = React.useContext(AppContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Card display="flex" mb={32} p={16}>
      <Box mr={16}>
        {/* @ts-expect-error - TS2322 - Type '{ alt: string; name: "cryptoPie"; height: number; width: number; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'. */}
        <Illustration alt="" name="cryptoPie" height={62} width={62} />
      </Box>
      <Box flex="1">
        <PXL fontWeight={600} mb={8}>
          Create a crypto pie{' '}
          <Tooltip
            placement="bottom-start"
            triggerBoxProps={{
              style: { verticalAlign: 'middle' },
              ml: 4,
            }}
            body={
              <PS content="As a reminder, you can't put stocks or funds into Crypto Accounts." />
            }
            icon="tooltip16"
            iconColor="primary"
          />
        </PXL>
        <PL>Fill it with coins, Pies, or both.</PL>
      </Box>
      <Flex alignItems="center">
        <Button
          label="Create a Pie"
          onClick={() => {
            analytics.recordEvent('m1_crypto_build_new_pie_selected');

            navigate({
              to: '/d/c/add-slices/crypto',
              options: { state: { context: 'NEW_ROOT_PIE' } },
            });

            dispatch({
              type: 'UPDATE_IS_CRYPTO_PORTFOLIO_ORGANIZER',
              payload: true,
            });
          }}
          size="large"
        />
      </Flex>
    </Card>
  );
};
