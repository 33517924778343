import * as d3 from 'd3';
import moment from 'moment';
import React, { useRef, useEffect, useCallback } from 'react';

import { Spinner } from '~/toolbox/spinner';

export type HistoricalSparklinesChartProps = {
  data: Array<Record<string, any>> | null | undefined;
  height?: number;
  width?: number;
  x: string;
  y: string;
};

export const HistoricalSparklinesChart: React.FC<
  HistoricalSparklinesChartProps
> = ({ data, height = 20, width = 105, x: xProp, y: yProp }) => {
  const canvas = useRef<HTMLCanvasElement>(null);

  const x = d3.scaleTime().range([0, width]);
  const y = d3.scaleLinear().range([height - 1, 1]);

  const line = d3
    .line<Record<string, any>>()
    .x((d) => x(d[xProp]))
    .y((d) => y(d[yProp]));

  const handleResize = useCallback(() => {
    if (canvas.current) {
      const parent = canvas.current.parentElement;
      if (parent) {
        const parentWidth = parent.clientWidth;
        const parentHeight = parent.clientHeight;

        const newWidth = parentWidth < width ? parentWidth : width;
        const newHeight = parentHeight < height ? parentHeight : height;

        canvas.current.width = newWidth;
        canvas.current.height = newHeight;
        drawToCanvas({
          data,
          height: newHeight,
          width: newWidth,
          x: xProp,
          y: yProp,
        });
      }
    }
  }, [data, height, width, xProp, yProp]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    drawToCanvas({ data, height, width, x: xProp, y: yProp });
  }, [data, height, width, xProp, yProp]);

  const drawToCanvas = ({
    data,
    height,
    width,
    x: xProp,
    y: yProp,
  }: {
    data: Array<Record<string, any>> | null | undefined;
    height: number;
    width: number;
    x: string;
    y: string;
  }) => {
    if (!canvas.current || !data || !xProp || !yProp) {
      return;
    } else if (!Array.isArray(data)) {
      return;
    }

    const ctx = canvas.current.getContext('2d');

    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, width!, height!);
    ctx.lineWidth = 2;
    ctx.strokeStyle = '#279D4F'; // currently this will always be green, attempting to use the first & last data points to determine color can create a mismatch with the percentage change color
    line.context(ctx);

    const dataPoints = [];
    let xMin;
    let xMax;
    let yMin;
    let yMax;

    const BUCKET_SIZE = 0.8;
    const fillPer = width! / data.length;

    let bucket = 0;
    for (const datum of data) {
      bucket += fillPer;

      if (bucket < BUCKET_SIZE) {
        continue;
      } else {
        bucket -= 1;
      }

      const x = moment(datum[xProp]).toDate();
      const y = datum[yProp];

      dataPoints.push({
        [xProp]: x,
        [yProp]: y,
      });

      if (!(xMin instanceof Date) || x < xMin) {
        xMin = x;
      }

      if (!(xMax instanceof Date) || x > xMax) {
        xMax = x;
      }

      if (typeof yMin !== 'number' || y < yMin) {
        yMin = y;
      }

      if (typeof yMax !== 'number' || y > yMax) {
        yMax = y;
      }
    }
    if (!xMin || !xMax) {
      return;
    }
    x.domain([xMin, xMax]);
    y.domain([yMin, yMax]);
    x.range([0, width]);
    y.range([height, 0]);

    ctx.beginPath();
    line(dataPoints);
    ctx.stroke();
  };

  const canvasWidth = canvas.current ? canvas.current.width : width;
  const canvasHeight = canvas.current ? canvas.current.height : height;

  if (!data) {
    return (
      <Spinner
        radius={20}
        thickness={2}
        secondaryColor="backgroundNeutralSecondary"
      />
    );
  } else if (Array.isArray(data) && data.length === 0) {
    return;
  }

  return <canvas ref={canvas} height={canvasHeight} width={canvasWidth} />;
};
