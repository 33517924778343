import { Flex, HXXS } from '@m1/liquid-react';
import * as React from 'react';

import { ChartableSliceChart } from '~/components/ChartableSliceChart/ChartableSliceChart';
import { ChartableSliceChartProvider } from '~/components/ChartableSliceChart/hooks/ChartableSliceChartContext';

export const SliceableResearch = ({ ids }: { ids: string[] }) => {
  return (
    <Flex
      color="foregroundNeutralMain"
      flexDirection="column"
      width="100%"
      mt={16}
    >
      <HXXS fontWeight={300} color="foregroundNeutralMain">
        Historical price
      </HXXS>
      <ChartableSliceChartProvider>
        <ChartableSliceChart
          height={300}
          chartableSliceIds={ids}
          chartName="pie_editor"
          features={{ navigator: false }}
        />
      </ChartableSliceChartProvider>
    </Flex>
  );
};
