import { useApolloClient } from '@apollo/client';

import {
  AccountTradingStatusDocument,
  useSetMaxCashThresholdMutation,
} from '~/graphql/hooks';
import {
  AccountTradingStatusQuery,
  SetMaxCashThresholdInput,
} from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useDispatch } from '~/redux/hooks';
import { useToast } from '~/toasts';

type SaveMaxCashThresholdAnalytics =
  | 'm1_auto_invest_toggled_on'
  | 'm1_auto_invest_toggled_off'
  | 'm1_auto_invest_cash_threshold_updated';

export const useSetMaxCashThreshold = () => {
  const [setThreshold, mutationResult] = useSetMaxCashThresholdMutation();
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const cache = useApolloClient().cache;

  const { addToast } = useToast();
  const handleUpdate = ({
    input,
    analyticEvent,
  }: {
    input: SetMaxCashThresholdInput;
    analyticEvent: SaveMaxCashThresholdAnalytics;
  }) => {
    setThreshold({
      variables: {
        input,
      },
      onCompleted: () => {
        analytics.recordEvent(analyticEvent);
        dispatch({
          type: 'AUTO_INVEST_TOGGLE',
          payload: input.maxCashThreshold !== null,
        });
      },
      onError: (err) => {
        addToast({
          content: err.message,
          kind: 'alert',
        });
      },
      optimisticResponse: () => {
        const accountCache: AccountTradingStatusQuery | null = cache.readQuery({
          query: AccountTradingStatusDocument,
          variables: {
            accountId: input.accountId,
          },
        });

        let estimatedTrading = null;

        if (
          accountCache?.node?.__typename === 'Account' &&
          accountCache?.node?.estimatedTrading
        ) {
          estimatedTrading = accountCache?.node?.estimatedTrading;
        }

        return {
          setMaxCashThreshold: {
            __typename: 'SetMaxCashThresholdPayload',
            result: {
              __typename: 'SetMaxCashThresholdResult',
              didSucceed: true,
              inputError: null,
            },
            account: {
              __typename: 'Account',
              id: input.accountId,
              maxCashThreshold: input.maxCashThreshold ?? null,
              estimatedTrading,
            },
          },
        };
      },
    });
  };
  return { handleUpdate, mutationResult };
};
