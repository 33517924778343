import { Button, useSkeleton } from '@m1/liquid-react';
import * as React from 'react';

import {
  useLinkable,
  OnClickLinkable,
  LinkableFragments,
} from '~/hooks/useLinkable';
import { Link, LinkProps } from '~/toolbox/link';

export type LinkableLinkProps = Omit<LinkProps, 'to'> & {
  linkable: LinkableFragments;
  onClick?: OnClickLinkable;
};

export type LinkableSkeletonLinkProps = Omit<LinkableLinkProps, 'linkable'> & {
  linkable: Maybe<LinkableFragments>;
};

export const LinkableLink = ({
  linkable,
  onClick,
  ...rest
}: LinkableLinkProps) => {
  const { routeName, onClickLinkable, queryParams } = useLinkable(
    linkable,
    onClick,
  );

  // TODO: Remove this after RR upgrade and removal of route name support.
  if (routeName) {
    return (
      <Link
        {...rest}
        onClick={onClickLinkable}
        to={routeName}
        query={queryParams}
      >
        {linkable.title}
      </Link>
    );
  } else if ('url' in linkable) {
    return (
      <Link
        {...rest}
        to={linkable.url}
        onClick={onClickLinkable}
        target="_blank"
      >
        {linkable.title}
      </Link>
    );
  } else if (onClick) {
    return (
      // @ts-expect-error - Spreading rest here causes TS to complain due to some missmatch in the types for the onClick handler.
      <Button
        {...rest}
        kind="link"
        label={linkable.title}
        onClick={onClickLinkable}
      />
    );
  }

  return null;
};

/** Use this and wrap this in a Skeleton for nullable linkable  */
export const LinkableSkeletonLink = ({
  linkable,
  ...props
}: LinkableSkeletonLinkProps) => {
  const { isLoading } = useSkeleton();

  if (linkable) {
    return <LinkableLink linkable={linkable} {...props} />;
  } else if (isLoading && !linkable) {
    return (
      // @ts-expect-error - something about spreading props here is causing a typechecking error.
      <Button {...props} kind="link" label="Loading" disabled />
    );
  }
  return null;
};
