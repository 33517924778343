import { ListItem, styled } from '@m1/liquid-react';
import * as React from 'react';

import {
  AppAnalyticsEvent,
  MoveMoneyListItemFragment,
  MoveMoneyOptionsQuery,
} from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import { Spinner } from '~/toolbox/spinner';

import { EmailVerificationCard } from '../cards/EmailVerificationCard/EmailVerificationCard';

import { toListItemProps } from './MoveMoneyMenu.utils';

export type MoveMoneyOptionsProps = {
  callToAction?:
    | NonNullable<
        NonNullable<MoveMoneyOptionsQuery['viewer']['transfers']>['moveMoneyV2']
      >['callToAction']
    | null
    | undefined;
  options?:
    | {
        primaryOptions: Array<MoveMoneyListItemFragment>;
        secondaryOptions: Array<MoveMoneyListItemFragment>;
      }
    | null
    | undefined;
  setIsOpen: (isOpen: boolean) => void;
};

export type MoveMoneyClick = {
  route: string | null | undefined;
  event: AppAnalyticsEvent | null | undefined;
};

// Override default last item styling from ListItem.
const StyledList = styled.ul`
  li:last-child {
    margin-bottom: 0px;
  }
`;

export const MoveMoneyOptions = ({
  callToAction,
  options,
  setIsOpen,
}: MoveMoneyOptionsProps) => {
  const navigate = useNavigate();
  const analytics = useAnalytics();

  const onNavigate = (params: MoveMoneyClick) => {
    setIsOpen(false);
    if (params.event) {
      analytics.recordAppAnalyticsEvent(params.event);
    }
    navigate({ to: params.route ?? '/d/home' });
  };

  if (!options) {
    return <Spinner />;
  }

  const { primaryOptions, secondaryOptions } = options;
  const optionsToDisplay = [...primaryOptions, ...secondaryOptions];

  return (
    <>
      {callToAction && (
        <EmailVerificationCard
          content={
            callToAction.title ??
            'You need to verify your email before transfers can go through.'
          }
          notificationLocation="MOVE_MONEY_MENU"
        />
      )}
      <StyledList>
        {optionsToDisplay.map((item, i) => {
          const props = toListItemProps(item, onNavigate);
          return <ListItem key={`option-${i}`} {...props} />;
        })}
      </StyledList>
    </>
  );
};
