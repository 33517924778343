import { Box, HM, PXL } from '@m1/liquid-react';
import {
  Illustration,
  type IllustrationsType,
} from '@m1/liquid-react/illustrations';
import * as React from 'react';

import {
  StyledApplicationRightCardContainer,
  StyledApplicationLeftCardContainer,
  StyledApplicationBottomCardContainer,
  StyledApplicationTopCardContainer,
} from './ApplicationCard';
import { StyledApplicationContainer } from './ApplicationContainer';
import { BackButton } from './BackButton';

type StepTemplateProps = {
  title: string;
  content?: string;
  additionalContent?: Array<string | null> | null | undefined;
  button: React.ReactNode;
  showBackButton?: boolean;
  illustrationName?: IllustrationsType;
  children?: React.ReactNode;
  appImage?: React.ReactNode;
};

export const StepTemplate = ({
  title,
  content,
  additionalContent,
  button,
  appImage,
  illustrationName,
  showBackButton = false,
  children,
}: StepTemplateProps) => {
  return (
    <Box pt={8}>
      <StyledApplicationContainer>
        <StyledApplicationTopCardContainer>
          <StyledApplicationLeftCardContainer>
            {showBackButton && <BackButton />}
            <HM content={title} mt={14} />
            {content && <PXL content={content} mt={16} />}
            {additionalContent &&
              additionalContent
                .filter(Boolean)
                .map((item: any, idx: number) => (
                  <PXL key={idx} content={item} mt={16} />
                ))}

            {children && children}
          </StyledApplicationLeftCardContainer>

          <StyledApplicationRightCardContainer>
            {illustrationName && <Illustration name={illustrationName} />}
            {appImage}
          </StyledApplicationRightCardContainer>
        </StyledApplicationTopCardContainer>

        <StyledApplicationBottomCardContainer>
          {button}
        </StyledApplicationBottomCardContainer>
      </StyledApplicationContainer>
    </Box>
  );
};
