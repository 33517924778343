import { theme } from '@m1/liquid-react';
import { useMediaQuery } from 'react-responsive';

import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';

import { useLayout } from './useLayout';

export const useBreakpoints = () => {
  // TODO: I think we should start using the breakpoint values defined by bootstrap, but need to confirm with design.
  // X-Small 	          xsm 	  <576px
  // Small 	            sm 	    ≥576px
  // Medium 	          md 	    ≥768px
  // Large 	            lg 	    ≥992px
  // Extra large 	      xl 	    ≥1200px
  // Extra extra large 	xxl 	  ≥1400px

  const isDesktop = useMediaQuery({
    query: RESPONSIVE_BREAKPOINTS.LARGE,
  });

  const isTablet = useMediaQuery({
    query: RESPONSIVE_BREAKPOINTS.TABLET,
  });

  const isPhone = useMediaQuery({
    query: RESPONSIVE_BREAKPOINTS.SMALL,
  });

  const isMedium = useMediaQuery({
    query: RESPONSIVE_BREAKPOINTS.MEDIUM,
  });

  // BELOW are using breakpoints from liquid and incorporating sidenavwidth

  const { sideNavWidth } = useLayout();

  const adjustedXXSmallBreakpoint =
    parseInt(theme.breakpoints.XXSMALL, 10) + sideNavWidth;

  const isXXSmallBreakpoint = useMediaQuery({
    query: `(min-width: ${adjustedXXSmallBreakpoint}px)`,
  });

  const adjustedSmallBreakpoint =
    parseInt(theme.breakpoints.SMALL, 10) + sideNavWidth;

  const isSmallBreakpoint = useMediaQuery({
    query: `(min-width: ${adjustedSmallBreakpoint}px)`,
  });
  const adjustedMediumBreakpoint =
    parseInt(theme.breakpoints.MEDIUM, 10) + sideNavWidth;

  const isMediumBreakpoint = useMediaQuery({
    query: `(min-width: ${adjustedMediumBreakpoint}px)`,
  });

  return {
    isDesktop,
    isMedium,
    isTablet,
    isPhone,
    isXXSmallBreakpoint,
    isSmallBreakpoint,
    isMediumBreakpoint,
  };
};
