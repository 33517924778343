import { Flex, PM, PXL, styled, theme } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { useInvestPortfolioDetailsHeaderQuery } from '~/graphql/hooks';
import { useBreakpoints } from '~/hooks/useBreakpoints';
import { Divider } from '~/toolbox/divider';
import { Link } from '~/toolbox/link';

import { BuyingPowerPopup } from './BuyingPowerPopup';

const BuyingPowerValue = styled(Flex)`
  flex-direction: column;
  padding-top: 8px;

  &:nth-child(even) {
    border-left: 1px solid ${theme.colors.borderFeature};
    padding-left: 24px;
  }

  @media screen and (min-width: ${({ theme }) =>
      parseInt(theme.breakpoints.XXSMALL, 10) + 75 + 'px'}) {
    &:not(:first-child) {
      border-left: 1px solid ${theme.colors.borderFeature};
      padding-left: 24px;
    }
    padding-right: 24px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.MEDIUM}) {
    border-left: 1px solid ${theme.colors.borderFeature};
    padding-left: 24px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.LARGE}) {
    padding-left: 32px;
    width: 176px;
  }
`;

export const PortfolioBuyingPowerDetails = ({
  sliceId,
}: {
  sliceId: string;
}) => {
  const { isXXSmallBreakpoint } = useBreakpoints();
  const isMobile = !isXXSmallBreakpoint;

  const { data: marginBuyingPowerData } = useInvestPortfolioDetailsHeaderQuery({
    variables: {
      nodeId: sliceId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const buyingPowerValues =
    marginBuyingPowerData?.node && 'buyingPower' in marginBuyingPowerData.node
      ? marginBuyingPowerData.node.buyingPower
      : null;

  return (
    <>
      {buyingPowerValues?.cash && (
        <BuyingPowerValue>
          <Flex alignItems="center">
            <PM
              content={buyingPowerValues?.cash?.title || 'Cash'}
              color="foregroundNeutralSecondary"
            />
            <BuyingPowerPopup
              hasMarginBuyingPowerFlag
              tooltipInfo={buyingPowerValues?.cash?.tooltip}
            />
          </Flex>
          <PXL color="foregroundNeutralMain" fontWeight={600}>
            {buyingPowerValues?.cash?.value ?? '$--'}
          </PXL>
        </BuyingPowerValue>
      )}
      {buyingPowerValues?.margin && (
        <>
          {isMobile && <Divider spacing="none" hidden />}

          <BuyingPowerValue>
            <Flex alignItems="center">
              <PM
                content={buyingPowerValues.margin?.title || 'Margin'}
                color="foregroundNeutralSecondary"
              />
              <BuyingPowerPopup
                hasMarginBuyingPowerFlag
                tooltipInfo={buyingPowerValues.margin?.tooltip}
              />
            </Flex>
            <Link
              to={`/d/c/set-order/${sliceId}?buyingPower=true`}
              font="PXL"
              fontWeight={600}
              fontSize="18px"
            >
              {buyingPowerValues.margin?.value ?? '$--'}
              <Icon name="caretRight16" />
            </Link>
          </BuyingPowerValue>
          <BuyingPowerValue pr={10}>
            <Flex alignItems="center" height="24px">
              <PM
                content={
                  buyingPowerValues?.total?.title || 'Total buying power'
                }
                color="foregroundNeutralSecondary"
              />
            </Flex>
            <PXL color="foregroundNeutralMain" fontWeight={600}>
              {buyingPowerValues?.total?.value ?? '$--'}
            </PXL>
          </BuyingPowerValue>
        </>
      )}
    </>
  );
};
