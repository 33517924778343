import { styled, Button } from '@m1/liquid-react';
import React from 'react';

type BackButtonProps = {
  onBackClick: () => void;
};

export const BackButtonContainer = styled.div`
  background: transparent;
  margin-bottom: 24px;
`;

export const BackButton = ({ onBackClick }: BackButtonProps) => {
  return (
    <BackButtonContainer>
      <Button
        kind="text"
        label="Back"
        leftIcon="caretLeft24"
        onClick={() => onBackClick()}
        mt={-32}
        ml={-32}
      />
    </BackButtonContainer>
  );
};
