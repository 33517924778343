import { defaultProps } from '~/hocs';

import { formatDate } from '~/utils/formatting';

import { required, printableAsciiChars, date } from '../validators';

import { TextField } from './text-field';

const validations = [
  required,
  printableAsciiChars,
  date({
    checkAge: true,
  }),
];

const enhancer = defaultProps({
  name: 'dateOfBirth',
  label: 'Date of birth',
  maxLength: 30,
  format: formatDate,
  placeholder: 'MM/DD/YYYY',
  validate: validations,
});

export const DateOfBirthField = enhancer(TextField) as any;
