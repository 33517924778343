import { styled } from '@m1/liquid-react';
import * as React from 'react';

import { Tab, TabProps } from './Tab';

export type TabsProps = {
  as?: React.ElementType;
  children: React.ReactElement<TabProps> | React.ReactElement<TabProps>[];
};

const StyledTabs = styled.div<TabsProps>`
  display: inline-flex;
  flex-direction: row;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

export class Tabs extends React.Component<TabsProps> {
  static Tab = Tab;

  render() {
    const { children, ...rest } = this.props;
    return <StyledTabs {...rest}>{children}</StyledTabs>;
  }
}
