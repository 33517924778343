import { Flex, PL, PM, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { SecurityLogo } from '~/components/SecurityLogo';

import { MoveDrawerRowsListFragment } from '~/graphql/types';

import { MoveDrawerRow } from './MoveDrawerRow';

const StyledDescription = styled(PL)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledScrollableList = styled(Flex)`
  overflow-y: scroll;
`;

export type MoveDrawerRowsListProps = {
  receivedSecurities: MoveDrawerRowsListFragment;
  selectedCheckboxes: Array<string>;
};

export const MoveDrawerRowsList = ({
  receivedSecurities,
  selectedCheckboxes,
}: MoveDrawerRowsListProps) => {
  const { moveSecurities, moveSecuritiesConfirmTargets } = receivedSecurities;
  const destinations = moveSecurities?.validPortfolioSliceDestinations;

  if (!destinations || !moveSecuritiesConfirmTargets) {
    return null;
  }

  return (
    <Flex flexDirection="column">
      <StyledScrollableList flexDirection="column" maxHeight={240}>
        {destinations.map((destination, index) => {
          const portfolioLinks = destination?.to.portfolioLinks.length
            ? destination.to.portfolioLinks
                .filter((link) => !link.isRootSlice)
                .map((link) => link)
            : [];

          const locationsDestinationAppearsIn: string[] = [];
          portfolioLinks.forEach((portfolioLink) => {
            const ancestors =
              portfolioLink.__typename === 'ChildPortfolioSlice' &&
              portfolioLink.ancestors?.length
                ? portfolioLink.ancestors
                    .map((ancestor) => (ancestor ? ancestor.to.name : ''))
                    .filter(Boolean)
                : [];

            const description = [...ancestors, destination?.to.name]
              .filter(Boolean)
              .join(' > ');

            locationsDestinationAppearsIn.push(description);
          });

          return (
            destination && (
              <MoveDrawerRow
                key={index}
                flow={{
                  destinationId: destination.to.id,
                  parentPortfolioSliceId: destination.id,
                  type: 'MOVE_FLOW',
                  unmanagedHoldingIdsForMergedSlices: selectedCheckboxes,
                  unmanagedHoldingsToMove: selectedCheckboxes,
                }}
                moveSecuritiesConfirmTargets={moveSecuritiesConfirmTargets}
                selectedCheckboxes={selectedCheckboxes}
              >
                <SecurityLogo content={<Icon name="createPie32" />} />
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  height={48}
                  width="80%"
                >
                  <PL color="foregroundNeutralMain">{destination.to.name}</PL>
                  {locationsDestinationAppearsIn.length > 0 && (
                    <StyledDescription color="foregroundNeutralSecondary">
                      {locationsDestinationAppearsIn[0]}
                    </StyledDescription>
                  )}
                </Flex>
              </MoveDrawerRow>
            )
          );
        })}
      </StyledScrollableList>
      <Flex
        borderColor="borderMain"
        borderStyle="solid"
        borderWidth="1px 0 0 0"
      >
        <MoveDrawerRow
          flow={{
            type: 'CREATE_AND_MOVE_FLOW',
            unmanagedHoldingIdsForMergedSlices: selectedCheckboxes,
            unmanagedHoldingsToMove: selectedCheckboxes,
          }}
          key="create-pie"
          moveSecuritiesConfirmTargets={moveSecuritiesConfirmTargets}
          selectedCheckboxes={selectedCheckboxes}
        >
          <SecurityLogo content={<Icon name="createPie24" />} />
          <PM color="foregroundNeutralMain">Create Pie</PM>
        </MoveDrawerRow>
      </Flex>
    </Flex>
  );
};
