import { Flex, Input } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { Slice } from '~/pie-trees';
import { useDispatch } from '~/redux/hooks';
import { clamp, isNumberString } from '~/utils';

export type SliceableListItemPercentageAdjustorProps = {
  allowZeroPercentSlices?: boolean;
  slice: Slice;
  'data-testid'?: string;
};

export const SliceableListItemPercentageAdjustor = ({
  allowZeroPercentSlices,
  slice,
  'data-testid': dataTestId,
}: SliceableListItemPercentageAdjustorProps) => {
  const dispatch = useDispatch();
  const percentageMin = allowZeroPercentSlices ? 0 : 1;
  const [inputIsEmpty, setInputIsEmpty] = React.useState<boolean>(false);

  const onStepwisePercentageAdjustorClick = React.useCallback(
    (
      e: React.SyntheticEvent<HTMLDivElement>,
      operation: 'subtract' | 'add',
    ) => {
      // prevent click event from bubbling the chain.
      // this is done to allow our draggable click handler to safely execute.
      e.stopPropagation();

      const percentage =
        operation === 'subtract'
          ? Math.max(percentageMin, slice.percentage - 1)
          : Math.max(percentageMin, slice.percentage + 1);

      dispatch({
        payload: {
          id: slice.to.__id,
          percentage,
        },
        type: 'ADJUSTED_PORTFOLIO_EDITOR_SLICE_PERCENTAGE',
      });
    },
    [dispatch, percentageMin, slice.to.__id, slice.percentage],
  );

  const onBlur = React.useCallback(() => {
    if (inputIsEmpty) {
      setInputIsEmpty(false);

      dispatch({
        payload: {
          id: slice.to.__id,
          percentage: percentageMin,
        },
        type: 'ADJUSTED_PORTFOLIO_EDITOR_SLICE_PERCENTAGE',
      });
    }
  }, [dispatch, inputIsEmpty, percentageMin, slice.to.__id]);

  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === '') {
        setInputIsEmpty(true);
      } else if (isNumberString(event.target.value)) {
        const percentage = clamp(
          Number(event.target.value),
          percentageMin,
          100,
        );

        if (inputIsEmpty) {
          setInputIsEmpty(false);
        }

        dispatch({
          payload: {
            id: slice.to.__id,
            percentage,
          },
          type: 'ADJUSTED_PORTFOLIO_EDITOR_SLICE_PERCENTAGE',
        });
      }
    },
    [dispatch, inputIsEmpty, percentageMin, slice.to.__id],
  );

  return (
    <Flex alignItems="center" maxWidth="140px">
      <Flex
        alignItems="center"
        height={45}
        justifyContent="center"
        width={90}
        onClick={(e: React.MouseEvent<HTMLDivElement>) =>
          onStepwisePercentageAdjustorClick(e, 'subtract')
        }
      >
        <Icon name="minusBubble24" color="primary" />
      </Flex>
      <Input
        maxLength={3} // prevent click event from bubbling the chain
        // this is done to allow our draggable click handler to safely execute.
        onClick={(e: any) => e.stopPropagation()}
        onBlur={onBlur}
        onChange={onChange}
        value={inputIsEmpty ? '' : slice.percentage}
        width={40}
        size="small"
        style={{ textAlign: 'center' }}
        data-testid={dataTestId ? `${dataTestId}-input` : undefined}
      />
      <Flex
        alignItems="center"
        height={45}
        justifyContent="center"
        width={90}
        onClick={(e: React.MouseEvent<HTMLDivElement>) =>
          onStepwisePercentageAdjustorClick(e, 'add')
        }
      >
        <Icon name="addBubble24" color="primary" />
      </Flex>
    </Flex>
  );
};
