import { Box } from '@m1/liquid-react';
import React from 'react';

import { onScreenerSortChange } from '~/components/research/Screener/utils';
import { useModelPortfolioScreenerQuery } from '~/graphql/hooks';
import { PieBacktestPeriodEnum, SystemPieSortTypeEnum } from '~/graphql/types';
import { useParams } from '~/hooks/useParams';
import { Grid } from '~/toolbox/grid';

import {
  FilterContainer,
  CategoryDetailsHeader,
  RetirementFilter,
  ModelPortfolioTableBody,
  ModelPortfolioTableHeader,
  StyledModelPortfolioTable,
} from './components';
import { DEFAULT_SORT, INITIAL_GENERAL_INVESTING_SORT } from './constants';

export const CategoryDetailsPage = () => {
  const { categoryKey } = useParams();
  const [period, setPeriod] = React.useState(PieBacktestPeriodEnum.FiveYears);
  const [sort, setSort] = React.useState(DEFAULT_SORT);

  React.useEffect(() => {
    if (categoryKey === 'GENERAL_INVESTING') {
      setSort(INITIAL_GENERAL_INVESTING_SORT);
    } else {
      setSort(DEFAULT_SORT);
    }
  }, [categoryKey]);

  const { data, refetch } = useModelPortfolioScreenerQuery({
    variables: {
      filterCategory: [categoryKey ?? ''],
      sort,
    },
    errorPolicy: 'all',
  });

  const handleCategoryChange = (category: string) => {
    refetch({ filterCategory: category });
  };

  const handleSortChange = (sortType: SystemPieSortTypeEnum) => {
    const newSort = onScreenerSortChange(sort, sortType)[0];
    setSort(newSort);
  };

  const pies = data?.viewer.screenSystemPies.edges;

  return (
    <div>
      <CategoryDetailsHeader categoryKey={categoryKey} />
      {categoryKey === 'RETIREMENT' && (
        <FilterContainer>
          <RetirementFilter onChangeFilter={handleCategoryChange} />
        </FilterContainer>
      )}
      <Box mt="1.5rem">
        <Grid constrain>
          <Grid.Row>
            <Grid.Col xs={12}>
              <StyledModelPortfolioTable>
                <ModelPortfolioTableHeader
                  onSortChange={handleSortChange}
                  sort={sort}
                  period={period}
                  setPeriod={setPeriod}
                />
                {pies && (
                  <ModelPortfolioTableBody period={period} pies={pies} />
                )}
              </StyledModelPortfolioTable>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Box>
    </div>
  );
};
