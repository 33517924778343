import { Flex, PM, Tooltip } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

export const SharesTooltip = () => {
  return (
    <Tooltip
      placement="bottom-end"
      body={
        <Flex flexDirection="column">
          <PM fontWeight={400} mb={16}>
            Shares of holdings with a pending status reflect how many shares you
            owned at the end of the record date. This number is fixed and
            determines your payment.
          </PM>
          <PM fontWeight={400}>
            Shares of holdings with an estimated status reflect how many shares
            you currently own. You can still buy or sell shares of these
            holdings before the ex-dividend date, which would impact your total
            payment.
          </PM>
        </Flex>
      }
    >
      <Icon name="tooltip16" ml={4} />
    </Tooltip>
  );
};
