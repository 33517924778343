import React from 'react';

import { Link, LinkProps } from '~/toolbox/link';
import { Pill } from '~/toolbox/Pill';

export const BreadCrumb = ({
  label,
  linkProps,
}: {
  label: string;
  linkProps?: LinkProps;
}) => {
  return linkProps ? (
    <Link {...linkProps}>
      <Pill kind="interactive" label={label} size="medium" />
    </Link>
  ) : (
    <Pill label={label} />
  );
};
