import { Box, styled } from '@m1/liquid-react';
import * as React from 'react';

import { useSelector } from 'react-redux';

import { AppInfoPageFragment } from '~/graphql/types';
import { AppInfoPage } from '~/lens-toolbox/AppInfoPage';
import { AppState } from '~/redux';

export const StyledBox = styled(Box)`
  background-color: ${({ theme }) => theme.colors.backgroundNeutralSecondary};
  height: 100vh;
`;

type PromotionSignUpOutcomePageProps = {
  onFinishStep?: (args?: any) => void;
};
export const PromotionSignUpOutcomePage = ({
  onFinishStep,
}: PromotionSignUpOutcomePageProps) => {
  const promotionSignUpOutcomePage: Maybe<AppInfoPageFragment> = useSelector(
    (state: AppState) => state.newFlows.registerUser.promotionSignUpOutcomePage,
  );

  const handleClick = () => {
    if (onFinishStep) {
      onFinishStep();
    }
  };

  if (!promotionSignUpOutcomePage) {
    return null;
  }
  return (
    <StyledBox>
      <AppInfoPage
        appInfoPage={promotionSignUpOutcomePage}
        onPrimaryButtonClick={handleClick}
        onSecondaryButtonClick={handleClick}
      />
    </StyledBox>
  );
};
