import { Modal, ModalContent } from '@m1/liquid-react';
import * as React from 'react';

import { CreatePieForm } from '../Forms';

export const CreatePieFormModal = ({
  closeCreatePieForm,
  open,
}: {
  closeCreatePieForm: () => void;
  open: boolean;
}) => {
  return (
    <Modal onClose={closeCreatePieForm} open={open}>
      <ModalContent width="wide" withPadding={false}>
        <CreatePieForm closeCreatePieForm={closeCreatePieForm} />
      </ModalContent>
    </Modal>
  );
};
