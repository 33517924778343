import * as React from 'react';

import { AdaptiveMultiFactorAuthForm } from '~/forms/AdaptiveMultiFactorAuthForm';
import { LoginForm } from '~/forms/LoginForm';
import { TwoFactorAuthForm } from '~/forms/two-factor-auth-form';
import { beginLoginFlow, changeLoginFlowStep } from '~/redux/actions';
import { LOGIN_FLOW_STEPS as STEPS } from '~/static-constants';

import { WarnMobileUsers } from '../register-user/steps/warn-mobile-users';
import { makeFlowComponent } from '../utils';

const CollectCredentialsStep = ({ color }: { color?: string }) => (
  <LoginForm color={color} />
);

export const LoginFlow = makeFlowComponent({
  name: 'login',
  begin: beginLoginFlow,
  changeStep: changeLoginFlowStep,
  stepElements: {
    [STEPS.COLLECT_CREDENTIALS]: <CollectCredentialsStep />,
    [STEPS.COLLECT_TWO_FACTOR_AUTH_CODE]: <TwoFactorAuthForm />,
    [STEPS.COLLECT_AMFA_CODE]: <AdaptiveMultiFactorAuthForm />,
    [STEPS.WARN_MOBILE_USERS]: <WarnMobileUsers />,
  },
});
