import { SagaIterator } from 'redux-saga';
import { all, call, getContext } from 'redux-saga/effects';

import type { LaunchDarkly } from '~/launch-darkly';
import type { AnalyticsReporter, SentryReporter } from '~/loggers';

export type Loggers = {
  analytics: AnalyticsReporter;
  launchDarkly: LaunchDarkly;
  sentry: SentryReporter;
};

export function* getAnalyticsReporter(): SagaIterator<AnalyticsReporter> {
  const analytics: AnalyticsReporter = yield getContext('analytics');
  return analytics;
}
export function* getSentryReporter(): SagaIterator<SentryReporter> {
  const sentry: SentryReporter = yield getContext('sentry');
  return sentry;
}

export function* getLaunchDarkly(): SagaIterator<LaunchDarkly> {
  const launchDarkly: LaunchDarkly = yield getContext('launchDarkly');
  return launchDarkly;
}

export function* getLoggers(): SagaIterator<Loggers> {
  const loggers: Loggers = yield all({
    analytics: call(getAnalyticsReporter),
    sentry: call(getSentryReporter),
    launchDarkly: call(getLaunchDarkly),
  });

  return loggers;
}
