import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import {
  ClickedOnPortfolioSliceDocument,
  ClickedOnPortfolioSliceQueryResult,
  ClickedOnSecuritySagaDocument,
  ClickedOnSecuritySagaQueryResult,
  SliceableTypenameSagaDocument,
  SliceableTypenameSagaQueryResult,
} from '~/graphql/hooks';

import { NavigateFunction } from '~/hooks/useNavigate';
import { ACTION_TYPES as ACTIONS, showModal } from '~/redux/actions';
import { ModeState } from '~/redux/reducers/modeReducer';
import { APP_MODES } from '~/static-constants';

import type { AppState } from '../../reducers/types';
import { apolloQuerySaga } from '../apolloQuerySaga';
import { select } from '../effects';

import { clickedAddSharedPie } from './clickedAddSharedPieSaga';
import { clickedAddToPortfolio } from './clickedAddToPortfolioSaga';
import { clickedCancelOrder } from './clickedCancelOrderSaga';
import { clickedResendVerificationEmail } from './clickedResendVerificationEmailSaga';
import { clickedSetOrder } from './clickedSetOrderSaga';
import { initializeWatchers } from './initializeWatchers';

export function* eventsSaga(): SagaIterator<void> {
  const watchers = [
    [ACTIONS.CLICKED_ON_SLICEABLE, resolveWithPayload(clickedOnSliceable)],
    [ACTIONS.CLICKED_ON_PIE_CATEGORY, resolveWithPayload(clickedOnPieCategory)],
    [
      ACTIONS.CLICKED_ON_PORTFOLIO_SLICE,
      resolveWithPayload(clickedOnPortfolioSlice),
    ],
    [ACTIONS.CLICKED_CONNECT_BANK, resolveWithPayload(clickedConnectBank)],
    [
      ACTIONS.CLICKED_ADD_TO_PORTFOLIO,
      resolveWithPayload(clickedAddToPortfolio),
    ],
    [ACTIONS.CLICKED_ADD_SHARED_PIE, resolveWithPayload(clickedAddSharedPie)],
    [ACTIONS.CLICKED_CANCEL_ORDER, resolveWithPayload(clickedCancelOrder)],
    [ACTIONS.CLICKED_SET_ORDER, resolveWithPayload(clickedSetOrder)],
    [
      ACTIONS.CLICKED_RESEND_VERIFICATION_EMAIL,
      resolveWithPayload(clickedResendVerificationEmail),
    ],
  ];

  // @ts-expect-error - TS2769 - No overload matches this call.
  yield call(initializeWatchers, watchers);
}

function* clickedOnSliceable(
  sliceableId: string,
  isCrypto?: boolean,
): SagaIterator<void> {
  const appMode: ModeState = yield select((state: AppState) => state.mode);

  const {
    locationState,
    navigate,
  }: {
    locationState: any | undefined;
    navigate: NavigateFunction;
  } = yield select((state: AppState) => {
    const { locationBeforeTransitions, navigate } = state.routing;

    return {
      locationState: locationBeforeTransitions?.location.state,
      navigate,
    };
  });

  const { data }: SliceableTypenameSagaQueryResult = yield call(
    apolloQuerySaga,
    { query: SliceableTypenameSagaDocument, variables: { sliceableId } },
  );
  const sliceable = data?.node;

  switch (sliceable?.__typename) {
    case 'CryptoAsset':
    case 'Equity':
    case 'Fund':
      yield call(clickedOnSecurity, sliceableId);
      break;
    case 'SystemPie': {
      const routePath =
        appMode === APP_MODES.ADD
          ? '/d/c/add-slices/model-portfolios/details/:modelPortfolioId'
          : '/d/research/model-portfolios/details/:modelPortfolioId';

      yield call(navigate, {
        to: routePath,
        params: { modelPortfolioId: sliceableId },
        options: { state: locationState },
      });
      break;
    }
    case 'UserPie': {
      const routePath =
        appMode === APP_MODES.ADD
          ? '/d/c/add-slices/my-pies/details/:userPieId'
          : '/d/research/my-pies/details/:userPieId';

      yield call(navigate, {
        to: routePath,
        params: { userPieId: sliceableId },
        query: { isCrypto: isCrypto ? 'true' : 'false' },
        options: { state: locationState },
      });
      break;
    }
    default: // Unrecognized Sliceable type. Do nothing.
  }
}

function* clickedOnPortfolioSlice(
  portfolioSliceId: string,
): SagaIterator<void> {
  const {
    activeAccountId,
    navigate,
  }: {
    activeAccountId: string;
    navigate: NavigateFunction;
  } = yield select((state: AppState) => ({
    activeAccountId: state.global.activeAccountId,
    navigate: state.routing.navigate,
  }));

  const { data }: ClickedOnPortfolioSliceQueryResult = yield call(
    apolloQuerySaga,
    { query: ClickedOnPortfolioSliceDocument, variables: { portfolioSliceId } },
  );

  if (
    data?.node &&
    'account' in data.node &&
    data.node.account.id !== activeAccountId
  ) {
    yield put({
      type:
        data.node.account.registration === 'CRYPTO'
          ? 'SET_ACTIVE_CRYPTO_ACCOUNT'
          : 'SET_ACTIVE_INVEST_ACCOUNT',
      payload: data.node.account.id,
    });
  }

  yield call(navigate, {
    to: `/d/invest/portfolio/:portfolioSliceId`,
    params: {
      portfolioSliceId,
    },
  });
}

/** @deprecated Transition to using clickedOnSliceable. */
function* clickedOnSecurity(securityId: string): SagaIterator<void> {
  const appMode: ModeState = yield select((state: AppState) => state.mode);

  const {
    locationState,
    navigate,
  }: {
    locationState: any;
    navigate: NavigateFunction;
  } = yield select((state: AppState) => {
    const { locationBeforeTransitions, navigate } = state.routing;

    return {
      locationState: locationBeforeTransitions?.location.state,
      navigate,
    };
  });

  const { data }: ClickedOnSecuritySagaQueryResult = yield call(
    apolloQuerySaga,
    { query: ClickedOnSecuritySagaDocument, variables: { securityId } },
  );

  const security = data?.node && 'isActive' in data.node ? data.node : null;

  if (security?.isActive) {
    // TODO: Make this not brittle.
    // Determine if we need to navigate within the add-slices route
    const navPrefix =
      appMode === APP_MODES.ADD ? '/d/c/add-slices' : '/d/research';
    const options = {
      state: locationState,
    };
    switch (security.__typename) {
      case 'Equity':
        yield call(navigate, {
          to: `${navPrefix}/stocks/details/:equityId`,
          params: {
            equityId: securityId,
          },
          options,
        });
        break;
      case 'Fund':
        yield call(navigate, {
          to: `${navPrefix}/funds/details/:fundId`,
          params: {
            fundId: securityId,
          },
          options,
        });
        break;
      case 'CryptoAsset':
        yield call(navigate, {
          to: `${navPrefix}/crypto/details/:cryptoId`,
          params: {
            cryptoId: securityId,
          },
          options,
        });
        break;
      default:
        break;
    }
    // @ts-expect-error - TS2339 - Property '__typename' does not exist on type 'Security'.
  } else if (security.__typename !== 'CryptoAsset') {
    yield put(showModal('SECURITY_STATUS', securityId));
  }
}

function* clickedOnPieCategory(categoryKey: string): SagaIterator<void> {
  const {
    appMode,
    navigate,
    state,
  }: {
    appMode: AppState['mode'];
    navigate: AppState['routing']['navigate'];
    state: NonNullable<
      AppState['routing']['locationBeforeTransitions']
    >['location']['state'];
  } = yield select((state: AppState) => {
    const { locationBeforeTransitions, navigate } = state.routing;

    return {
      appMode: state.mode,
      navigate,
      state: locationBeforeTransitions?.location.state,
    };
  });

  // TODO: Make this not brittle.
  // Determine if we need to navigate within the add-slices route
  const navPrefix =
    appMode === APP_MODES.ADD ? '/d/c/add-slices' : '/d/research';

  yield call(navigate, {
    to: `${navPrefix}/model-portfolios/:categoryKey`,
    params: {
      categoryKey,
    },
    options: { state },
  });
}

function* clickedConnectBank(): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );

  yield call(navigate, { to: '/d/c/connect-bank' });
}

function resolveWithPayload(
  fn: (...args: Array<any>) => any,
): (...args: Array<any>) => any {
  return function (action: any): any {
    return fn(action.payload, action.isCrypto);
  };
}
