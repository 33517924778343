// Given an array of numbers and a value, return the index of the
// entry that is closest to the value.
export function closestIndex(
  array: Array<number>,
  value: number,
): number | null | undefined {
  let closestIndex = 0;

  if (array.length === 0) {
    return undefined;
  }

  if (array.length === 1) {
    return closestIndex;
  }

  for (let index = 1; index < array.length; index++) {
    if (Math.abs(value - array[index]) < value - array[closestIndex]) {
      closestIndex = index;
    }
  }

  return closestIndex;
}

export type Changes<T> =
  | Array<T>
  | Array<
      [
        Record<string, any> | null | undefined,
        Record<string, any> | null | undefined,
      ]
    >;
