import * as React from 'react';

import { SectionContent } from './content';
import { SectionHeader } from './header';
import style from './style.module.scss';

export type SectionProps = {
  action?: React.ReactNode;
  children?: React.ReactNode;
  content?: React.ReactNode;
  header?: string;
};

export class Section extends React.Component<SectionProps> {
  static Header = SectionHeader;
  static Content = SectionContent;

  render() {
    return (
      <div className={style.root}>
        {this.props.header && (
          <SectionHeader action={this.props.action} label={this.props.header} />
        )}
        {this.props.content ? (
          <SectionContent>{this.props.content}</SectionContent>
        ) : (
          this.props.children
        )}
      </div>
    );
  }
}
