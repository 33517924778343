import * as React from 'react';

import { InvestAccountSettingsFragment } from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';
import { getEnumDescription } from '~/utils';

import { RenameableAccountCell } from './RenameableAccountCell';
import { RenameableRowProps } from './types';

type InvestAccountsRowsProps = {
  investAccounts: InvestAccountSettingsFragment['accounts'] | null | undefined;
} & RenameableRowProps;

export const InvestAccountsRows = ({
  investAccounts,
  hoverRow,
  activeRow,
  setActiveRow,
  setHoverRow,
}: InvestAccountsRowsProps) => (
  <>
    {(investAccounts?.edges ?? []).map((edge) =>
      edge?.node ? (
        <GridTable.Row
          onMouseEnter={() => setHoverRow(edge.node?.id)}
          onMouseLeave={() => setHoverRow(null)}
          key={edge.node?.id}
        >
          <RenameableAccountCell
            // Only allow edit if the account has a root portfolio.
            // Otherwise it just has a default name.
            showEdit={hoverRow === edge.node.id && edge.node.hasPortfolio}
            accountId={edge.node.id}
            initialAccountName={edge.node.name}
            isActive={activeRow === edge.node.id}
            onEditClick={(accountId: string) => setActiveRow(accountId)}
          />
          <GridTable.Cell content={edge.node.number} />
          <GridTable.Cell
            content={getEnumDescription(
              edge.node.registration,
              'AccountRegistrationEnum',
            )}
          />
          <GridTable.Cell
            content={getEnumDescription(edge.node.status, 'AccountStatusEnum')}
          />
        </GridTable.Row>
      ) : null,
    )}
  </>
);
