export const FLOW_ACTIONS = Object.freeze({
  START_FLOW: 'START_FLOW' as const,
  BEGIN_FLOW: 'BEGIN_FLOW' as const,
  UPDATE_FLOW: 'UPDATE_FLOW' as const,
  FINISHED_FLOW_STEP: 'FINISHED_FLOW_STEP' as const,
  END_FLOW: 'END_FLOW' as const,
});

export type BeginFlowAction = {
  payload: any;
  meta: {
    flow: any;
  };
  type: typeof FLOW_ACTIONS.BEGIN_FLOW;
};

export type StartFlowAction = {
  payload: any;
  type: typeof FLOW_ACTIONS.START_FLOW;
};

export type UpdateFlowAction = {
  payload: Record<string, any>;
  type: typeof FLOW_ACTIONS.UPDATE_FLOW;
};

export type FinishedFlowStepAction<TPayload = any> = {
  payload: TPayload;
  meta: {
    flow: any;
    step: string;
  };
  type: typeof FLOW_ACTIONS.FINISHED_FLOW_STEP;
};

export type EndFlowAction = {
  payload: any;
  meta: {
    flow: any;
    step?: string;
  };
  type: typeof FLOW_ACTIONS.END_FLOW;
};

export const startFlow = (flow: string, params?: any): StartFlowAction => {
  const _payload = {
    flow,
    params,
  };

  return {
    payload: _payload,
    type: FLOW_ACTIONS.START_FLOW,
  };
};

export const beginFlow = (flow: string, params?: any): BeginFlowAction => {
  return {
    type: FLOW_ACTIONS.BEGIN_FLOW,
    meta: {
      flow,
    },
    payload: params,
  };
};

export const updateFlow = (
  payload: UpdateFlowAction['payload'],
): UpdateFlowAction => ({
  payload,
  type: FLOW_ACTIONS.UPDATE_FLOW,
});

export const finishedFlowStep = (
  flow: string,
  step: string,
  params: any,
): FinishedFlowStepAction => {
  return {
    type: FLOW_ACTIONS.FINISHED_FLOW_STEP,
    meta: {
      flow,
      step,
    },
    payload: params,
  };
};

export const endFlow = <T>(flow: string, flowState: T): EndFlowAction => {
  return {
    type: FLOW_ACTIONS.END_FLOW,
    meta: {
      flow,
    },
    payload: flowState,
  };
};

export type FlowAction =
  | BeginFlowAction
  | StartFlowAction
  | UpdateFlowAction
  | FinishedFlowStepAction
  | EndFlowAction;
