import * as React from 'react';

import { useSelector } from '~/redux/hooks';
import { APP_MODES } from '~/static-constants';
import { Pill } from '~/toolbox/Pill';

import { BreadCrumb } from './BreadCrumb';

type CryptoBreadCrumbsProps = {
  navigable: boolean;
};

export const CryptoBreadCrumbs = ({
  navigable = true,
}: CryptoBreadCrumbsProps) => {
  const mode = useSelector((state) => state.mode);
  const toLink =
    mode === APP_MODES.ADD ? '/d/c/add-slices/crypto' : '/d/research/crypto';

  return (
    <Pill.Group>
      <BreadCrumb
        label="Crypto"
        {...(navigable && { linkProps: { to: toLink } })}
      />
    </Pill.Group>
  );
};
