import * as React from 'react';

export const useTransactionDetailsModal = () => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [selectedTransactionId, setSelectedTransactionId] = React.useState<
    string | null
  >(null);

  const handleDetailsModalOpen = (transactionId: string) => {
    // if the clicked transaction is the same as the previously selected transaction, close the modal
    if (transactionId === selectedTransactionId && showModal) {
      handleDetailsModalClose();
    } else {
      setSelectedTransactionId(transactionId);
      setShowModal(true);
    }
  };

  const handleDetailsModalClose = () => {
    setShowModal(false);
  };

  return {
    selectedTransactionId,
    onModalOpen: handleDetailsModalOpen,
    onModalClose: handleDetailsModalClose,
    showModal,
  };
};
