import { Box, Flex, HL, LL, LM, PM, PXS, Tooltip } from '@m1/liquid-react';
import React from 'react';

import { CreditCardRewardsSummaryFragment } from '~/graphql/types';
import { AppPill } from '~/lens-toolbox/AppPill';

type RewardsCurrentCycleEstimateContainerProps = {
  currentCycleRewardsEstimate: NonNullable<
    CreditCardRewardsSummaryFragment['currentCycleRewardsEstimate']
  >;
};

export const RewardsCurrentCycleEstimateContainer = ({
  currentCycleRewardsEstimate,
}: RewardsCurrentCycleEstimateContainerProps) => {
  const { label, value, pill, subtitle, tooltipContent } =
    currentCycleRewardsEstimate;
  return (
    <Flex flexDirection="column">
      <Flex alignItems="center">
        <LM content={label} mr={8} />
        {tooltipContent && (
          <Tooltip
            placement="bottom-start"
            icon="tooltip16"
            iconColor="primaryTint"
            trigger="click"
            body={
              <Box width={400}>
                {tooltipContent.title && (
                  <LL mt={8} ml={12} content={tooltipContent.title} />
                )}
                <PM content={tooltipContent.content} m="8px 12px" />
              </Box>
            }
          />
        )}
        {pill && (
          <Flex justifyContent="end" flex="1">
            <AppPill appPill={pill} />
          </Flex>
        )}
      </Flex>
      <HL content={value} />
      {subtitle && <PXS content={subtitle} />}
    </Flex>
  );
};
