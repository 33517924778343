import * as React from 'react';

import { GetInitialFundingWizardPreloadQueryResult } from '~/graphql/hooks';

export type InitialFundingContextType =
  GetInitialFundingWizardPreloadQueryResult['data'];

export const InitialFundingContext =
  React.createContext<InitialFundingContextType | null>(null);

export const InitialFundingProvider = ({
  children,
  data,
}: {
  children: React.ReactChild;
  data: GetInitialFundingWizardPreloadQueryResult['data'];
}) => {
  return (
    <InitialFundingContext.Provider value={data}>
      {children}
    </InitialFundingContext.Provider>
  );
};
