import { Box, Flex, HS, PL } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { useRewardsViewAllProgramsComponentQuery } from '~/graphql/hooks';
import { CreditCardOwnersRewardsProgramHighlightLinkEnum } from '~/graphql/types';
import { useParams } from '~/hooks/useParams';
import { Spinner } from '~/toolbox/spinner';

import { RewardsTableViewAllTable } from './RewardsTableViewAll';

export const RewardsViewAllProgramsComponent = () => {
  const params = useParams();
  const programType =
    params.programType === 'owned'
      ? CreditCardOwnersRewardsProgramHighlightLinkEnum.OwnedStocks
      : CreditCardOwnersRewardsProgramHighlightLinkEnum.UnownedStocks;

  const { data, loading } = useRewardsViewAllProgramsComponentQuery({
    variables: {
      programType,
    },
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  const programContent =
    data?.viewer.credit?.rewardsProgramBreakdownByOwnedOrUnowned;

  if (!programContent) {
    return <GenericSystemError />;
  }

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      mt={150}
      justifyContent="center"
    >
      <Box width={600}>
        <BackArrow
          content="Rewards Portal"
          mb={16}
          to="/d/spend/credit/rewards/summary"
        />
        <HS mb={8} content={programContent.title} />
        <PL mb={16} content={programContent.subtitle} />
        {programContent.programs && (
          <RewardsTableViewAllTable
            rewardsProgram={programContent.programs.edges}
            programType={programType === 'OWNED_STOCKS' ? 'owned' : 'unowned'}
          />
        )}
      </Box>
    </Flex>
  );
};
