import { ROUTING_ACTIONS, RouteAction } from '~/redux/actions';

import { RoutingState } from './routingReducer.types';
import { buildAppHistory } from './routingReducer.utils';

const navigateNotSet = () => {
  if (__NODE_ENV__ !== 'production') {
    // eslint-disable-next-line no-console
    console.error(
      'Navigate not set, make sure you are firing off the SET_NAVIGATE action on route initialization.',
    );
  }
};

const navigate = () => {
  navigateNotSet();
};

export const initialState: RoutingState = {
  appHistory: [],
  locationBeforeTransitions: null,
  navigate,
};

export const routingReducer = (
  state: RoutingState = initialState,
  action: RouteAction,
): RoutingState => {
  switch (action.type) {
    case ROUTING_ACTIONS.LOCATION_CHANGE: {
      const appHistory = buildAppHistory(state, action.payload);
      return Object.assign({}, state, {
        appHistory,
        locationBeforeTransitions: {
          ...action.payload,
        },
      });
    }

    case ROUTING_ACTIONS.SET_NAVIGATE: {
      return {
        ...state,
        navigate: action.payload,
      };
    }
    default:
      return state;
  }
};
