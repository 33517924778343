import { Box, Flex, HM, Tooltip } from '@m1/liquid-react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { BackArrow } from '~/components/BackArrow';
import { renderTableTooltip } from '~/components/CollectProfileInput';
import { useOpenInvestJointAccountWizardContext } from '~/flows/components/OpenInvestJointAccountWizard/hooks/useOpenInvestJointAccountWizardContext';
import { StyledOption } from '~/pages/onboarding/financial-suitability-wizard/consts';

import { submitReactFormData } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { RiskToleranceEnumDropdownOptions } from '~/static-constants';

export interface RiskToleranceValues {
  riskTolerance: string;
}

export const RiskTolerance = () => {
  const dispatch = useDispatch();

  const submittedFormValues = useSelector((state) => {
    if (!state.reactHookForm.openJointInvestAccountRiskTolerance) {
      return {
        riskTolerance: undefined,
      };
    }

    return state.reactHookForm.openJointInvestAccountRiskTolerance;
  });

  const { setValue, handleSubmit } = useForm<RiskToleranceValues>({
    defaultValues: submittedFormValues,
  });

  const wiz = useOpenInvestJointAccountWizardContext();

  const onSubmit = (data: RiskToleranceValues) => {
    dispatch(
      submitReactFormData({ openJointInvestAccountRiskTolerance: data }),
    );

    wiz.next();
  };

  const handleOptionClick = (name: string) => {
    setValue('riskTolerance', name);
    handleSubmit(onSubmit)();
  };
  const markup = renderTableTooltip({
    header: [
      'Risk Tolerance',
      'Comfort with big swings in investment value',
      'Potential for long-term growth',
    ],
    content: [
      ['Low', '+', '+'],
      ['Medium', '++', '++'],
      ['High', '+++', '+++'],
    ],
  });

  return (
    <Flex maxWidth={588} flexDirection="column" mx="auto">
      <Box pt={32}>
        <BackArrow content="Back" onClick={() => wiz.back()} />
      </Box>
      <Flex alignItems="center" mt={48} mb={32}>
        <HM>What's your risk tolerance?</HM>
        <Tooltip body={markup} />
      </Flex>
      <form onSubmit={handleSubmit(onSubmit)}>
        {RiskToleranceEnumDropdownOptions.map(({ name, description }) => (
          <StyledOption
            tabIndex={0}
            key={name}
            onClick={() => handleOptionClick(name)}
            onKeyDown={(event: React.KeyboardEvent) => {
              if (event.key === 'Enter' || event.code === 'Space') {
                handleOptionClick(name);
              }
            }}
          >
            {description}
          </StyledOption>
        ))}
      </form>
    </Flex>
  );
};
