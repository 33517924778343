import { SagaIterator } from 'redux-saga';
import {
  call,
  select,
  setContext,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import { BeginResolveCallFlowAction } from '~/redux/actions/newFlows/resolveMarginCall/resolveMarginCallActions.types';
import { RESOLVE_CALL_STEPS as STEPS } from '~/static-constants';

import type { AppState } from '../../../reducers/types';
import { changeStep } from '../utils';

export function* resolveCallSaga(): SagaIterator<void> {
  yield takeLatest(ACTIONS.BEGIN_RESOLVE_CALL_FLOW, beginResolveCallFlow);
}

function* beginResolveCallFlow(
  action: BeginResolveCallFlowAction,
): SagaIterator<void> {
  const { basePath, onFinish } = action.payload;
  const previousRouteName = action.payload.previousRouteName;

  // Setup basePath context for step routing.
  yield setContext({
    basePath,
  });

  // Setup action sagas
  yield takeEvery(
    ACTIONS.SELECTED_CREATE_TRANSFER_RESOLUTION_OPTION,
    selectedCreateTransferResolutionOption,
  );

  yield takeEvery(
    ACTIONS.SELECTED_SELL_POSITION_RESOLUTION_OPTION,
    selectedSellPositionResolutionOption,
  );

  yield takeEvery(
    ACTIONS.RETURN_TO_RESOLVE_MARGIN_CALL_INFORMATION,
    returnToResolveMarginCall,
  );

  yield takeEvery(ACTIONS.FINISHED_SET_ORDER_FLOW, onFinish);
  yield takeEvery(ACTIONS.CLICKED_CANCEL_SET_ORDER, onFinish);

  yield call(
    changeStep,
    STEPS.RESOLVE_MARGIN_CALL,
    false,
    {},
    { previousRouteName },
  );
}

function* selectedCreateTransferResolutionOption(): SagaIterator<void> {
  const borrowAccountId = yield select(
    (state: AppState) => state.newFlows.RESOLVE_CALL.borrowAccountId,
  );
  const previousRouteName = yield select(
    (state) => state.newFlows.RESOLVE_CALL.previousRouteName,
  );
  yield call(
    changeStep,
    STEPS.CREATE_TRANSFER,
    false,
    {},
    { toParticipantId: borrowAccountId, previousRouteName },
  );
}

function* selectedSellPositionResolutionOption(): SagaIterator<void> {
  const previousRouteName = yield select(
    (state) => state.newFlows.RESOLVE_CALL.previousRouteName,
  );
  yield call(changeStep, STEPS.SET_ORDER, false, {}, { previousRouteName });
}

function* returnToResolveMarginCall(): SagaIterator<void> {
  const previousRouteName = yield select(
    (state) => state.newFlows.RESOLVE_CALL.previousRouteName,
  );
  yield call(
    changeStep,
    STEPS.RESOLVE_MARGIN_CALL,
    false,
    {},
    { previousRouteName },
  );
}
