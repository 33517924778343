import { Input, InputProps } from '@m1/liquid-react';
import * as React from 'react';
import { Field, WrappedFieldProps } from 'redux-form';

import { withProps } from '~/hocs';

export type TextFieldProps = WrappedFieldProps &
  Omit<InputProps, 'error'> & {
    nonSubmissionError?: { error: string | null };
    isFocused: boolean;
    error?: string | null;
  };

const renderInput = ({
  input,
  meta,
  nonSubmissionError,
  error,
  ...rest
}: TextFieldProps) => {
  return React.createElement(Input, {
    ...rest,
    ...meta,
    ...input,
    value: typeof input.value === 'string' ? input.value : '',
    error: readError({ meta, nonSubmissionError, error }),
  });
};

const readError = ({
  meta,
  nonSubmissionError,
  error,
}: Pick<TextFieldProps, 'meta' | 'nonSubmissionError' | 'error'>) => {
  if (nonSubmissionError) {
    return nonSubmissionError.error || meta.error;
  }
  if (meta.submitFailed) {
    return meta.error;
  }
  if (error) {
    return error;
  }
  return null;
};

const enhancer = withProps({
  component: renderInput,
});

export const TextField = enhancer(Field) as any;
