import isPlainObject from 'lodash-es/isPlainObject';

export type WithoutTypename<T extends object> = {
  [k in keyof T as Exclude<k, '__typename'>]: T[k] extends object
    ? WithoutTypename<T[k]>
    : T[k];
};

export const withoutTypename = <T extends object>(
  obj: T,
): WithoutTypename<typeof obj> =>
  isPlainObject(obj)
    ? Object.entries(obj)
        .filter(([k]) => k !== '__typename')
        .map(([k, v]) => [
          k,
          Array.isArray(v)
            ? v.map((vi) => withoutTypename(vi))
            : withoutTypename(v),
        ])
        .reduce((out, [k, v]) => ({ ...out, [`${k}`]: v }), {} as T)
    : obj;

export type WithoutNulls<T> = T extends object
  ? NonNullable<{
      [K in keyof T]-?: WithoutNulls<NonNullable<T[K]>>;
    }>
  : NonNullable<T>;

export const withoutNulls = <T extends object>(obj: T): WithoutNulls<T> =>
  (isPlainObject(obj)
    ? Object.entries(obj)
        .filter(([_, v]) => v !== null && v !== undefined)
        .map(([k, v]) => [
          k,
          Array.isArray(v)
            ? v.map((vi) => withoutTypename(vi))
            : withoutTypename(v),
        ])
        .filter(([_, v]) => !isPlainObject(v) || Object.keys(v).length > 0)
        .reduce((out, [k, v]) => ({ ...out, [`${k}`]: v }), {})
    : obj) as WithoutNulls<T>;
