import * as React from 'react';

import { useNavigate } from '~/hooks/useNavigate';

import { useSelector } from '~/redux/hooks';

export const ForwardIfAuthGuard = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const isLoggedIn = useSelector((state) => Boolean(state.auth.accessToken));
  const [isEvaluating, setIsEvaluating] = React.useState(true);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isEvaluating) {
      if (isLoggedIn) {
        navigate({ to: '/d/home', options: { replace: true } });
      } else {
        setIsEvaluating(false);
      }
    }
  }, [navigate, isLoggedIn, setIsEvaluating, isEvaluating]);

  if (isEvaluating) {
    return null;
  }

  return children;
};
