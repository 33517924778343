import { Box, Flex, PM, Skeleton, Tooltip, theme } from '@m1/liquid-react';
import Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import moment from 'moment';
import React from 'react';

import {
  DividendTrackerDataPeriod,
  DividendTopEarnerFragment,
} from '~/graphql/types';
import { CashFormatter } from '~/utils';

type DividendReportChartProps = {
  dividendPeriod: DividendTrackerDataPeriod;
  upcomingDividends: Array<number>;
  earnedDividends: Array<number>;
  pendingDividends: Array<number>;
  topEarners: Array<DividendTopEarnerFragment[]>;
};

const monthLabelsForPeriod = (period: DividendTrackerDataPeriod) => {
  const labels = [];
  const date = moment();
  let month = 0;

  switch (period) {
    case DividendTrackerDataPeriod.LastYear:
      date.subtract(1, 'year').startOf('year');
      break;
    case DividendTrackerDataPeriod.Last_12Months:
      date.subtract(12, 'months');
      date.add(1, 'month'); // adding one month so we include current month
      break;
    case DividendTrackerDataPeriod.ThisYear:
      date.startOf('year');
      break;
    case DividendTrackerDataPeriod.Next_12Months:
    default:
      // Next twelve months is NO-OP here!
      break;
  }

  while (month++ < 12) {
    labels.push(date.format("MMM 'YY"));
    date.add(1, 'month');
  }

  return labels;
};

export const DividendReportChart = ({
  dividendPeriod,
  upcomingDividends,
  earnedDividends,
  pendingDividends,
  topEarners,
}: DividendReportChartProps) => {
  const options = React.useMemo(() => {
    const series = [
      {
        name: 'Estimated',
        data: upcomingDividends,
        fill: theme.colors.backgroundPlot,
      },
      {
        name: 'Pending',
        data: pendingDividends,
        fill: theme.colors.datapointTint,
      },
      {
        name: 'Paid',
        data: earnedDividends,
        fill: theme.colors.datapoint, // there is no `datapoint` color, but this seems to be its (current) proxy
      },
    ];

    const opts: Highcharts.Options = {
      chart: {
        type: 'column',
        backgroundColor: theme.colors.backgroundNeutralSecondary,
        style: {
          fontFamily: 'Inter',
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      colors: series.map((s) => s.fill),
      xAxis: {
        categories: monthLabelsForPeriod(dividendPeriod),
        tickmarkPlacement: 'on',
        tickLength: 8,
        tickPosition: 'outside',
        tickWidth: 1,
        lineColor: theme.colors.borderMain,
        tickColor: theme.colors.foregroundNeutralTertiary,
        labels: {
          style: {
            color: theme.colors.foregroundNeutralSecondary,
            fontSize: '12px',
          },
        },
      },
      yAxis: {
        gridLineColor: theme.colors.borderMain,
        labels: {
          format: '${value}',
          style: {
            color: theme.colors.foregroundNeutralSecondary,
            fontSize: '12px',
          },
        },
        title: {
          text: '',
        },
      },
      // @ts-expect-error series formatting is valid according to docs
      series,
      plotOptions: {
        column: {
          borderWidth: 0,
          pointPadding: -0.12,
          stacking: 'normal',
        },
        series: {
          // @ts-expect-error Types are wrong, stacking is valid according to docs
          stacking: false,
          grouping: false,
          stickyTracking: false,
        },
      },
      tooltip: {
        useHTML: true,
        outside: true,
        formatter: function () {
          const totalYValue = this.points?.reduce(
            // @ts-ignore point.y exists
            (total, point) => total + point.y,
            0,
          );
          const labelRowsColumns = [
            [this.x, `$${totalYValue}`],
            ...(this.points ?? []).map((point) => {
              const value = (point.y as number).toFixed(2);
              return [`\u25A0${point.series.name}`, `$${value}`];
            }),
          ];

          const htmlLabels = labelRowsColumns.map(([label, value], i) => {
            // First entry is the header of the tooltip
            if (i === 0) {
              return `<div style="color:${theme.colors.foregroundNeutralMain};display:flex;flex-direction:row;justify-content:space-between;min-width:200px;">
                <span>${label}</span>
                <strong>$${totalYValue?.toFixed(2)}</strong>
              </div><br />`;
            }

            const point = this.points?.[i - 1] ?? {
              color: series[i - 1],
              series: { name: series[i - 1].name },
            };
            return `<div style="display:flex;flex-direction:row;justify-content:space-between;">
              <span style="color:${theme.colors.foregroundNeutralMain};"><span style="color:${point.color};margin-right:8px;">\u25A0</span>${point.series.name}</span>
              <span style="color:${theme.colors.foregroundNeutralMain};">${value}</span>
            </div>`;
          });

          const topEarnersForMonth = topEarners[this.point.x].map(
            (topEarner) =>
              `<div style="display:flex;flex-direction:row;min-width:200px;margin:8px 0;font-size:12px;margin-top:8px;">
            ${
              topEarner.securityRef.security
                ? `
                  <div style="display:flex; align-items:center;">
                    <img height="24" width="24" style="border:1px solid ${theme.colors.backgroundNeutralMain};padding:4px;" src="${topEarner.securityRef.security.profile?.logoUrl}" />
                  </div>
                  <div style="display:flex;flex-direction:column;margin-left:8px;">
                    <div style="color:${theme.colors.foregroundNeutralSecondary};">${topEarner.securityRef.security.symbol}</div>
                    <div style="color:${theme.colors.foregroundNeutralMain};overflow:hidden;width:120px;text-overflow:ellipsis;">${topEarner.securityRef.security.name}</div>
                  </div>
                  <div style="color:${theme.colors.foregroundNeutralSecondary};display:flex;flex-direction:column;flex-grow:1;align-items:flex-end;justify-content:center;">
                    ${CashFormatter.format(topEarner.amount)}
                  </div>
                  `
                : `
                  <div style="display:flex; align-items:center; justify-content:space-between; width:100%">
                    <div>${topEarner.securityRef.descriptor}</div>
                    <div style="color:${theme.colors.foregroundNeutralSecondary}">${CashFormatter.format(topEarner.amount)}</div>
                  </div>`
            }

            </div>`,
          );

          const topEarnersSeparator = `<div style="height:1px;min-width:200px;border-top:1px solid ${theme.colors.foregroundNeutralTertiary};margin:8px 0;"></div><div style="color:${theme.colors.foregroundNeutralSecondary};font-size:12px;margin-top:8px;">Top holding(s)</div>`;

          return `${htmlLabels.join('')}${topEarnersForMonth.length ? topEarnersSeparator : ''}${topEarnersForMonth.join('')}`;
        },
        shared: true,
        backgroundColor: theme.colors.backgroundNeutralSecondary,
        borderRadius: 8,
        padding: 10,
        shadow: {
          color: 'rgba(0, 0, 0, 0.2)',
          offsetX: 2,
          offsetY: 3,
          opacity: 0.15,
          width: 10,
        },
        style: {
          fontSize: '14px',
          opacity: 1,
          // @ts-expect-error border radius can also be string with pixel value
          borderRadius: '8px',
        },
      },
      legend: {
        // Note: we have manually created the legend below (in order to support tooltips)
        enabled: false,
      },
    };

    return opts;
  }, [
    dividendPeriod,
    earnedDividends,
    upcomingDividends,
    pendingDividends,
    topEarners,
  ]);

  return (
    <>
      <Skeleton skeletonWidth="100%">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </Skeleton>

      <Flex
        style={{ backgroundColor: theme.colors.backgroundNeutralSecondary }}
        flexDirection="row"
        justifyContent="flex-end"
        px={16}
        py={8}
      >
        <Skeleton skeletonWidth={100} marginRight={8}>
          <Flex alignItems="center" mr={16}>
            <Box height={16} width={16} backgroundColor="datapoint" mr={8} />
            Paid
          </Flex>
        </Skeleton>
        <Skeleton
          alignItems="center"
          display="flex"
          skeletonWidth={100}
          justifyContent="center"
          mr={16}
        >
          <Box height={16} width={16} backgroundColor="datapointTint" mr={8} />
          Pending
          <Tooltip
            body={
              <PM fontWeight={400}>
                Pending dividends have been earned (are past their ex-date) but
                not yet paid by the company. Buying power will be updated once
                the dividend is paid.
              </PM>
            }
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            icon="tooltip16"
            placement="bottom-end"
          />
        </Skeleton>
        <Skeleton
          alignItems="center"
          display="flex"
          justifyContent="center"
          skeletonWidth={100}
        >
          <Flex
            height={16}
            width={16}
            backgroundColor="backgroundPlot"
            mr={8}
          />
          Estimated
          <Tooltip
            body={
              <PM fontWeight={400}>
                Estimated dividends include:
                <br />
                <br />• Dividends declared by the company but not yet earned or
                paid. Estimated payment amounts are calculated by current M1
                holdings. Purchases or sales prior to ex-date will impact these
                amounts.
                <br />
                <br />• Projections of future dividends based on prior period
                payments and annualized dividend yield. The calculation takes
                dividend yield, current end of day market value and current
                holdings to make the projections. These dividends have not been
                announced and are not guaranteed.
              </PM>
            }
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            icon="tooltip16"
            placement="bottom-end"
          />
        </Skeleton>
      </Flex>
    </>
  );
};
