import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { SmartTransferCreateForm } from '~/components/SmartTransferCreateForm';
import { useSetSmartTransferQuery } from '~/graphql/hooks';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

type SetSmartTransferProps = {
  onFinishStep: (
    arg0:
      | {
          newSmartTransferContraParticipantEntryOptionId: string;
        }
      | null
      | undefined,
  ) => void;
};

export const SetSmartTransfer = ({ onFinishStep }: SetSmartTransferProps) => {
  const focusAccountId = useSelector(
    (state) => state.newFlows.CREATE_SMART_TRANSFER.input.focusParticipantId,
  );
  const focusOptionId = useSelector(
    (state) => state.newFlows.CREATE_SMART_TRANSFER.focusOptionId,
  );
  const { data, loading, refetch } = useSetSmartTransferQuery({
    variables: {
      focusOptionId: focusOptionId as string,
      focusAccountId: focusAccountId as string,
      insertionContext: null,
    },
    skip: !focusAccountId || !focusOptionId,
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (
    data?.node?.__typename !== 'NewSmartTransferFocusOption' ||
    !focusAccountId
  ) {
    return <GenericSystemError />;
  }
  return (
    <Box maxWidth={582} mx="auto" pt={100}>
      <SmartTransferCreateForm
        editRequirements={data.node.editRequirements}
        focusAccountId={focusAccountId}
        onFinishStep={onFinishStep}
        refetch={refetch}
      />
    </Box>
  );
};
