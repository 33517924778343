import { useForm } from 'react-hook-form';

import { OnlineAccountRegistrationEnum } from '~/graphql/types';

export type IRAWizardFormFields = {
  accountType: Extract<
    OnlineAccountRegistrationEnum,
    | OnlineAccountRegistrationEnum.RothIra
    | OnlineAccountRegistrationEnum.SepIra
    | OnlineAccountRegistrationEnum.TraditionalIra
  > | null;
  isRolloverOrTransfer: boolean;
};

export const useIRAWizardForm = () => {
  return useForm<IRAWizardFormFields>({
    mode: 'onChange',
    defaultValues: {
      accountType: null,
      isRolloverOrTransfer: false,
    },
  });
};
