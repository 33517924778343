export const IDENTITY_PROFILE_ACTIONS = Object.freeze({
  BEGIN_IDENTITY_PROFILE_FLOW: 'BEGIN_IDENTITY_PROFILE_FLOW' as const,
  FINISHED_CREATE_PROFILE: 'FINISHED_CREATE_PROFILE' as const,
  SET_PROFILE_CREATED: 'SET_PROFILE_CREATED' as const,
  SET_HAS_PRODUCT: 'SET_HAS_PRODUCT' as const,
  SET_USER_HAS_ONBOARDED: 'SET_USER_HAS_ONBOARDED' as const,
  EDIT_PROFILE_IDENTITY: 'EDIT_PROFILE_IDENTITY' as const,
  EDIT_KNOW_YOUR_CUSTOMER: 'EDIT_KNOW_YOUR_CUSTOMER' as const,
});

export type BeginIdentityProfileFlow = {
  payload: {
    basePath: string;
    onFinish: () => void;
  };
  type: typeof IDENTITY_PROFILE_ACTIONS.BEGIN_IDENTITY_PROFILE_FLOW;
};

export type EditProfileIdentity = {
  type: typeof IDENTITY_PROFILE_ACTIONS.EDIT_PROFILE_IDENTITY;
};

export type EditKnowYourCustomer = {
  type: typeof IDENTITY_PROFILE_ACTIONS.EDIT_KNOW_YOUR_CUSTOMER;
};

export type FinishedCreateProfile = {
  type: typeof IDENTITY_PROFILE_ACTIONS.FINISHED_CREATE_PROFILE;
};

export type SetProfileCreated = {
  payload: boolean | null | undefined;
  type: typeof IDENTITY_PROFILE_ACTIONS.SET_PROFILE_CREATED;
};

export type SetUserHasOnboarded = {
  payload: boolean | null;
  type: typeof IDENTITY_PROFILE_ACTIONS.SET_USER_HAS_ONBOARDED;
};

export type SetHasProduct = {
  payload: boolean;
  type: typeof IDENTITY_PROFILE_ACTIONS.SET_HAS_PRODUCT;
};

export const beginIdentityProfileFlow = (
  payload: BeginIdentityProfileFlow['payload'],
): BeginIdentityProfileFlow => ({
  type: IDENTITY_PROFILE_ACTIONS.BEGIN_IDENTITY_PROFILE_FLOW,
  payload,
});

export const editProfileIdentity = (): EditProfileIdentity => ({
  type: IDENTITY_PROFILE_ACTIONS.EDIT_PROFILE_IDENTITY,
});

export const editKnowYourCustomer = (): EditKnowYourCustomer => ({
  type: IDENTITY_PROFILE_ACTIONS.EDIT_KNOW_YOUR_CUSTOMER,
});

export const finishedCreateProfile = (): FinishedCreateProfile => ({
  type: IDENTITY_PROFILE_ACTIONS.FINISHED_CREATE_PROFILE,
});

export const setProfileCreated = (
  payload: SetProfileCreated['payload'],
): SetProfileCreated => ({
  type: IDENTITY_PROFILE_ACTIONS.SET_PROFILE_CREATED,
  payload,
});

export const setUserHasOnboarded = (
  payload: SetUserHasOnboarded['payload'],
): SetUserHasOnboarded => ({
  type: IDENTITY_PROFILE_ACTIONS.SET_USER_HAS_ONBOARDED,
  payload,
});

export type IdentityProfileAction =
  | BeginIdentityProfileFlow
  | EditProfileIdentity
  | SetHasProduct
  | FinishedCreateProfile
  | SetProfileCreated
  | SetUserHasOnboarded;
