import { useState, useEffect } from 'react';

import { useSentry } from '~/hooks/useSentry';

// using unknown to match the type defined in 'lottie-react'
type LottieAnimationData = unknown;

type UseDynamicLottieFetch = {
  loading: boolean;
  animationData: LottieAnimationData;
};

export const useLottieDynamicFetch = (
  fileName: string,
): UseDynamicLottieFetch => {
  const [animationData, setAnimationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const sentry = useSentry();

  useEffect(() => {
    const fetchAnimation = async () => {
      setLoading(true);

      const filePath = `/animations/${fileName}.json`;

      try {
        const response = await fetch(filePath);
        const lottie = await response.json();

        setAnimationData(lottie);
      } catch (error) {
        sentry.message('Unable to load lottie animation file.', {
          error: `${JSON.stringify(error, null, 2)}`,
          filePath,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchAnimation();
  }, [fileName, sentry]);

  return { animationData, loading };
};
