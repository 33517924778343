import { Flex, PL, PM } from '@m1/liquid-react';
import moment from 'moment';
import React from 'react';

import { Gain } from '~/components/gain';
import { PaginationButtonGroup } from '~/components/PaginationButtonGroup';
import { useBorrowActivityQuery } from '~/graphql/hooks';

import { useSelector } from '~/redux/hooks';
import { GridTable } from '~/toolbox/grid-table';
import { Spinner } from '~/toolbox/spinner';

export const BorrowActivityTable = () => {
  const [cursors, setCursors] = React.useState<string[]>([]);
  const borrowAccountId = useSelector((state) => state.global.borrowAccountId);
  const { data, loading, refetch, previousData } = useBorrowActivityQuery({
    variables: {
      borrowAccountId: borrowAccountId as string,
      first: 10,
    },
    skip: !borrowAccountId,
  });

  const borrowAccountActivity =
    data?.borrowAccount?.__typename === 'BorrowAccount'
      ? data.borrowAccount.marginProductLandingPage?.activity
      : null;

  if (loading && !previousData) {
    return <Spinner />;
  }

  if (!borrowAccountActivity) {
    return <PL content="Activity temporarily unavailable." my={16} />;
  }

  const handleClickLoadPrevPage = () => {
    const newCursors = cursors.slice(0, -1);
    const after = newCursors[newCursors.length - 1] || null;

    refetch({
      after,
    });

    setCursors(newCursors);
  };

  const handleNextPage = () => {
    if (typeof borrowAccountActivity?.pageInfo?.endCursor === 'string') {
      const after = borrowAccountActivity.pageInfo.endCursor;

      refetch({
        after,
      });

      setCursors((prevCursors) => [...prevCursors, after]);
    }
  };

  return (
    <>
      <Flex mt={-48} mb={8} alignItems="center" justifyContent="flex-end">
        <PaginationButtonGroup
          handleNextPage={handleNextPage}
          handlePreviousPage={handleClickLoadPrevPage}
          hasNextPage={Boolean(borrowAccountActivity?.pageInfo.hasNextPage)}
          hasPreviousPage={cursors.length > 0}
        />
      </Flex>
      <GridTable
        emptyMessage="No activity to display."
        gridTemplateColumns="120px 200px 120px auto"
        isNewStyle
        isNavigable={false}
      >
        <GridTable.HeaderRow>
          <GridTable.HeaderCell label="Date" />
          <GridTable.HeaderCell label="Type" />
          <GridTable.HeaderCell label="Description" />
          <GridTable.HeaderCell label="Amount" justifySelf="end" />
        </GridTable.HeaderRow>

        {loading ? (
          <Spinner />
        ) : (
          borrowAccountActivity.edges?.map((edge) => {
            if (!edge?.node) {
              return (
                <GridTable.Row key="unavailable">
                  <GridTable.Cell>
                    <PM content="Entry unavailable" fontStyle="italic" />
                  </GridTable.Cell>
                </GridTable.Row>
              );
            }

            return (
              <GridTable.Row key={edge.node.date}>
                <GridTable.Cell content={moment(edge.node.date).format('ll')} />
                <GridTable.Cell content={edge.node.title} />
                <GridTable.Cell content={edge.node.subTitle} />
                <GridTable.Cell
                  content={<Gain value={edge.node.amount} />}
                  justifySelf="end"
                />
              </GridTable.Row>
            );
          })
        )}
      </GridTable>
    </>
  );
};
