import { Button, PL } from '@m1/liquid-react';
import truncate from 'lodash-es/truncate';
import React from 'react';

import { AppContext } from '~/AppContext';

type SecurityDescriptionProps = {
  description: string | null | undefined;
};

export const SecurityProfileDescription = ({
  description,
}: SecurityDescriptionProps) => {
  const [showMore, setShowMore] = React.useState(false);
  const { analytics } = React.useContext(AppContext);

  if (!description) {
    return null;
  }
  return (
    <PL mb={32}>
      {showMore
        ? description
        : `${truncate(description, {
            length: 290,
            separator: ' ',
          })}`}
      {description.length > 290 && (
        <>
          {' '}
          <Button
            kind="link"
            label={`show ${showMore ? 'less' : 'more'}`}
            onClick={() => {
              if (!showMore) {
                analytics.recordAppAnalyticsEvent({
                  name: 'm1_security_details_data_discovered',
                  customParameters: [
                    {
                      name: 'data_id',
                      value: 'additional_profile_information',
                    },
                  ],
                  customBoolParameters: [],
                  customNumberParameters: [],
                  valueParameter: null,
                });
              }
              setShowMore(!showMore);
            }}
          />
        </>
      )}
    </PL>
  );
};
