import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import { Container } from '~/toolbox/container';
import { Spinner } from '~/toolbox/spinner';

export const LoadingStatus = () => {
  return (
    <Container centered>
      <Flex pb={16}>
        <Spinner fullScreen />
      </Flex>
    </Container>
  );
};
