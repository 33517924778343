import * as React from 'react';
import { FieldValues, FormProvider } from 'react-hook-form';

import { MultiStepFormContextValue } from './types';

export const MultiStepFormContext =
  React.createContext<MultiStepFormContextValue<any, any> | null>(null);

export const MultiStepFormProvider = <T extends FieldValues, K extends string>({
  children,
  value,
}: {
  children: React.ReactNode;
  value: MultiStepFormContextValue<T, K>;
}) => {
  return (
    <FormProvider {...value}>
      <MultiStepFormContext.Provider value={value}>
        {children}
      </MultiStepFormContext.Provider>
    </FormProvider>
  );
};
