/**
 * Prepends a 2-digit hex opacity value to a theme color
 * Useful for adding opacity to theme colors without effecting child element styling
 * @param {string} 2-digit hex opacity code
 * @param {string} Theme color ('#' + 6-digit color code)
 * @returns {string} 8-digit hex color code
 */
export const prependOpacityHexCode = (
  opacityHexCode: string,
  sixDigitHexCode: string,
): string => `#${opacityHexCode}${sixDigitHexCode.split('#')[1]}`;
