import * as React from 'react';
import { Route } from 'react-router-dom';

import { MoveMoneyPage } from './MoveMoneyPage';

export const MoveMoneyRoute = () => {
  return (
    <Route
      path="move-money/:step?"
      element={<MoveMoneyPage />}
      handle={{
        fallbackRoute: {
          to: '/d/transfers',
        },
      }}
    />
  );
};
