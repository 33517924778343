import { FloatingPanel, ListItem } from '@m1/liquid-react';
import * as React from 'react';

import { useDispatch } from '~/redux/hooks';

import { CondensableButton } from './PieEditor.styled';

export const PieEditorOptions = ({
  onTogglePieVisual,
}: {
  onTogglePieVisual: () => void;
}) => {
  const dispatch = useDispatch();
  const onCollapseAll = (collapsed: boolean) => {
    dispatch({
      type: 'PIE_EDITOR_UPDATE_COLLAPSED',
      payload: { collapsed },
    });
  };

  return (
    <FloatingPanel
      controlled
      active={false}
      placement="bottom-end"
      content={
        <ul style={{ marginTop: -16 }}>
          <ListItem
            header="Expand all"
            onClick={() => onCollapseAll(false)}
            size="SMALL"
            padding="16px"
            selectable
            withoutArrow
            // @ts-ignore
            icon={{ name: 'expandArrow20' }}
          />
          <ListItem
            header="Collapse all"
            onClick={() => onCollapseAll(true)}
            size="SMALL"
            padding="16px"
            selectable
            withoutArrow
            // @ts-ignore
            icon={{ name: 'exitArrow20' }}
          />
          <ListItem
            header="Toggle pie visual"
            onClick={onTogglePieVisual}
            size="SMALL"
            padding="16px"
            selectable
            withoutArrow
            icon={{ name: 'portfolio24' }}
          />
        </ul>
      }
      trigger={
        // @ts-ignore
        <CondensableButton
          kind="text"
          leftIcon="more24"
          label=""
          size="medium"
          condensed
        />
      }
    />
  );
};
