import * as React from 'react';
import { Route } from 'react-router-dom';

import { PieDetailsPage } from '~/components/pie/PieDetailsPage/PieDetailsPage';

export const AddMyPieDetailsRoute = () => {
  return (
    <Route
      path="details/:pieId"
      element={<PieDetailsPage disableEditing disableSharing />}
    />
  );
};
