import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { PersonalLoanLandingPageRoute } from './landing-page';
import { PersonalLoansApplicationRoute } from './loan-application';

import { PersonalLoansNotEligibleRoute } from './not-eligible';
import { PersonalLoansPage } from './PersonalLoansPage';

const queryParams = new URLSearchParams({
  mode: 'SCHEDULE',
  toParticipantType: 'LOAN',
  previousRouteName: '/d/borrow/personal/manage-loan',
});

export const PersonalLoansRoute = () => {
  return (
    <Route path="personal" element={<PersonalLoansPage />}>
      {PersonalLoanLandingPageRoute()}
      {PersonalLoansApplicationRoute()}
      {PersonalLoansNotEligibleRoute()}
      <Route
        path="autopay"
        element={
          <Navigate
            to={{
              pathname: '/d/move-money',
              search: queryParams.toString(),
            }}
          />
        }
      />
    </Route>
  );
};
