import { Box, Flex, LayoutableProps, LS, PM } from '@m1/liquid-react';
import * as React from 'react';

import { SecurityMetricsFragment } from '~/graphql/types';
import { formatNumber } from '~/utils';

export type SecurityMetricsProps = LayoutableProps & {
  security: SecurityMetricsFragment;
};

type Metric = [
  React.ReactElement<any> | string,
  React.ReactElement<any> | string | number,
];

export const SecurityMetrics = ({
  security,
  ...rest
}: SecurityMetricsProps) => {
  // @ts-expect-error - TS2345 - Argument of type '() => (string | number)[][]' is not assignable to parameter of type '() => Metric[]'.
  const metrics = React.useMemo<Array<Metric>>(() => {
    if (security.__typename === 'Equity') {
      const metrics = [];
      const equity = security;

      if (!equity.fundamentals) {
        return [];
      }
      const { marketCap, peRatio, dividendYield } = equity.fundamentals;

      if (typeof marketCap === 'number') {
        metrics.push([
          'Market Cap',
          formatNumber(marketCap, '0.0a').toUpperCase(),
        ]);
      }

      if (typeof peRatio === 'number') {
        metrics.push(['P/E Ratio', peRatio]);
      }

      metrics.push([
        'Dividend Yield',
        typeof dividendYield === 'number'
          ? `${formatNumber(dividendYield, '0.00')}%`
          : '--',
      ]);

      return metrics;
    }

    if (security.__typename === 'Fund') {
      const metrics = [];
      const fund = security;

      if (fund.fundFundamentals) {
        const { totalAssets, netExpenseRatio } = fund.fundFundamentals;
        metrics.push([
          'AUM',
          typeof totalAssets === 'number'
            ? formatNumber(totalAssets, '0.0a').toUpperCase()
            : '--',
        ]);
        metrics.push([
          'Expense Ratio',
          typeof netExpenseRatio === 'number' ? `${netExpenseRatio}%` : '--',
        ]);
      }

      if (fund.fundamentals) {
        const { dividendYield } = fund.fundamentals;
        metrics.push([
          'Dividend Yield',
          typeof dividendYield === 'number'
            ? `${formatNumber(dividendYield, '0.00')}%`
            : '--',
        ]);
      }

      return metrics;
    }

    if (security.__typename === 'CryptoAsset') {
      const metrics = [];
      const crypto = security;
      const { marketCap, circulatingSupply, volume } = crypto;

      if (typeof marketCap === 'number') {
        metrics.push([
          'Market Cap',
          formatNumber(marketCap, '0.0a').toUpperCase(),
        ]);
      }

      if (typeof circulatingSupply === 'number') {
        metrics.push([
          'Circulating Supply',
          formatNumber(circulatingSupply, '0.0a').toUpperCase(),
        ]);
      }

      if (typeof volume === 'number') {
        metrics.push(['Volume', formatNumber(volume, '0.0a').toUpperCase()]);
      }

      return metrics;
    }

    return [];
  }, [security]);
  if (metrics.length === 0) {
    return null;
  }
  return (
    <Flex p="16px 22px 18px" justifyContent="space-between" {...rest}>
      {metrics.map(([label, value], index) => (
        <Flex flexDirection="column" key={`metrics-map-${index}`} width={200}>
          <Box mb={6}>
            <LS color="foregroundNeutralSecondary" content={label} />
          </Box>
          <PM fontWeight={600} content={value} />
        </Flex>
      ))}
    </Flex>
  );
};
