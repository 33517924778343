import React from 'react';

import { useAnalytics } from '~/hooks/useAnalytics';
import { LoanInfoForm } from '~/pages/personal-loan-direct/components/LoanInfoForm';

export const LoanUseAndDuration = () => {
  const analytics = useAnalytics();

  React.useEffect(() => {
    analytics.pageView('/direct-loan-application/loan-information');
  }, [analytics]);

  return (
    <>
      <LoanInfoForm />
    </>
  );
};
