import { Box, Button, HXS, PL, PS, styled } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';

import {
  useLatestLegalAgreementsQuery,
  useSignAgreementBundleMutation,
} from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import { useToast } from '~/toasts';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

const StyledContainer = styled(Container)`
  margin-left: auto;
  margin-right: auto;
  width: 490px;
`;

const StyledLink = styled(Link)`
  display: block;
  margin-top: 8px;
`;

export const ReacceptLegalTermsPage = () => {
  const { data, loading } = useLatestLegalAgreementsQuery();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [signAgreementBundle] = useSignAgreementBundleMutation();

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.viewer) {
    return <GenericSystemError />;
  }

  const documents = data.viewer.latestAgreements.documents;

  const handleConfirm = () => {
    signAgreementBundle({
      variables: {
        input: {
          signature: data.viewer.latestAgreements.signature,
        },
      },
      onCompleted: () => navigate({ to: '/d/invest/portfolio' }),
      onError: (e) => {
        addToast({
          content: e.message,
          kind: 'alert',
        });
      },
    });
  };

  return (
    <StyledContainer marginTop={120}>
      <HXS content="Updated terms & conditions" />
      <PL
        content="We’re updating our terms and conditions so we can provide even more new features to help you manage your money. Click the links below to review and accept these changes."
        mt={16}
      />
      <Box mt={24} mb={48}>
        {documents.map((d) => (
          <StyledLink key={d.url} to={d.url} target="_blank">
            {d.title}
          </StyledLink>
        ))}
      </Box>

      <PS
        content="By clicking Accept, you acknowledge and accept the changes to our terms and services."
        mt={16}
      />
      <ButtonGroup mt={16}>
        <Button
          kind="secondary"
          marginRight={16}
          label="Cancel"
          onClick={() => {
            navigate({ to: '/d/invest/portfolio' });
          }}
          size="large"
        />
        <Button label="Accept" size="large" onClick={handleConfirm} />
      </ButtonGroup>
    </StyledContainer>
  );
};
