import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';

import { useInvestBankConnectionQuery } from '~/graphql/hooks';
import {
  AchRelationshipStatusEnum,
  InvestBankConnectionAccountNodeFragment,
} from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { InvestAccountBankConnection } from './components/AccountBankConnection';
import { BankConnectionActions } from './components/BankConnectionActions';
import { BankImage } from './components/BankImage';

type InvestBankConnectionState =
  | 'NO_BANK'
  | 'PENDING_MICRO_DEPOSITS'
  | 'CONNECTED';

/**
 * This page is for connecting bank accounts for Invest.
 *
 * @class InvestBankConnectionPageComponent
 * @extends {React.Component<Props>}
 */

const CONNECTION_STATE_MAPPING: Record<
  AchRelationshipStatusEnum,
  InvestBankConnectionState
> = {
  APPROVED: 'CONNECTED',
  CANCELED: 'NO_BANK',
  PENDING: 'PENDING_MICRO_DEPOSITS',
  REJECTED: 'NO_BANK',
};

export const InvestBankConnectionPage = () => {
  const navigate = useNavigate();

  const activeAccountId = useSelector((state) => state.global.activeAccountId);

  const { data, loading } = useInvestBankConnectionQuery({
    variables: {
      id: activeAccountId as string,
    },
    skip: !activeAccountId,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.account) {
    return <GenericSystemError />;
  }

  const account = data.account as InvestBankConnectionAccountNodeFragment;

  const user = data.viewer?.user;
  const transfers = data.viewer?.transfers;
  const fundingSources = data.viewer?.fundingSources;

  const lastAchRelationshipStatus = account?.lastAchRelationship?.status;

  const bankConnectionState = lastAchRelationshipStatus
    ? CONNECTION_STATE_MAPPING[lastAchRelationshipStatus]
    : 'NO_BANK';

  const hasConnectedBank = bankConnectionState !== 'NO_BANK';
  if (!hasConnectedBank && fundingSources?.hasFundingSources) {
    navigate({
      to: '/d/invest/available-funding-sources',
      query: { type: 'invest' },
    });
  }
  return (
    <Box m="64px auto 0" textAlign="center" width={489}>
      <BankImage achRelationship={account.lastAchRelationship} />
      <InvestAccountBankConnection
        account={account ? account : null}
        isEligibleForFundingSourceUpdate={
          transfers?.isEligibleForFundingSourceUpdate
        }
        isPrimaryEmailVerified={user?.isPrimaryEmailVerified}
      />
      {hasConnectedBank && (
        <>
          <Box mt={32}>
            <BankConnectionActions
              account={account}
              isPrimaryEmailVerified={user?.isPrimaryEmailVerified}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
