import { Box, Flex, HXS, PXL, styled, Card } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { FulfillmentConditionInlineDescriptionTags } from '~/components/FulfillmentConditionInlineDescriptionTags';
import { TransferParticipantCellRenderer } from '~/components/transfers/TransferParticipantCellRenderer';

import { FulfillmentConditionInputs } from '~/flows/components/create-smart-transfer/FulfillmentConditionInputs.apollo';
import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import { SmartTransferFulfillmentConditionInput } from '~/redux/actions/smartTransfer/smartTransferActions.types';

import type { LocalContraEntry } from './types';

type FulfillmentConditionRefetchContainerEditComponentProps = {
  contraEntryToEdit: LocalContraEntry;
  editRequirements: any;
  onFinishEditContraEntry: (
    arg0: LocalContraEntry,
    arg1: 'REPLACE' | 'REMOVE',
  ) => void;

  smartTransferRuleId: string;
};

const StyledLeftColumnBox = styled(Box)`
  background-color: ${(props) => props.theme.colors.backgroundNeutralSecondary};
`;

const StyledSelectedAccount = styled(Flex)`
  border: 1px solid ${(props) => props.theme.colors.foregroundNeutralTertiary};
  border-radius: 8px;
  padding: 16px 32px;
`;

export const FulfillmentConditionRefetchContainerEditContra = ({
  contraEntryToEdit,
  editRequirements,
  onFinishEditContraEntry,
  smartTransferRuleId,
}: FulfillmentConditionRefetchContainerEditComponentProps) => {
  const [contraEntryToEditInState, setContraToEditInState] =
    React.useState<LocalContraEntry>(contraEntryToEdit);
  const onFulfillmentDataChange = React.useCallback(
    (fulfillmentCondition: SmartTransferFulfillmentConditionInput) => {
      setContraToEditInState((contraEntryToEditInState) => {
        // type check is done here to appease flow since contraEntryToEditInState is a union type.
        if (contraEntryToEditInState.type === 'CONTRA_PARTICIPANT_ENTRY') {
          return {
            ...contraEntryToEditInState,
            fulfillmentCondition,
          };
        }
        return {
          ...contraEntryToEditInState,
          fulfillmentCondition,
        };
      });
    },
    [],
  );

  if (!editRequirements.fulfillmentConditionOptionSet) {
    throw new Error('Error reading fulfillmentConditionOptionSet fragment ref');
  }
  return (
    <>
      <StyledLeftColumnBox height="100vh" py={100} width="50%">
        <Box maxWidth={550} ml="auto" mr={64}>
          <BackArrow
            content="Edit Smart Transfer"
            mb={16}
            params={{
              smartTransferRuleId,
            }}
            to="/d/c/edit-smart-transfer/:smartTransferRuleId"
          />
          <HXS content={editRequirements.title} />
          <Card
            isElevated
            backgroundColor="backgroundNeutralMain"
            mt={64}
            minHeight={230}
            p="12px 32px"
          >
            <PXL
              fontWeight={600}
              content={editRequirements.contraParticipantLabel}
              mb={16}
              mt={24}
            />
            <StyledSelectedAccount>
              <TransferParticipantCellRenderer
                includeDescription
                id={contraEntryToEdit.contraParticipantId}
              />
            </StyledSelectedAccount>
            {contraEntryToEditInState.fulfillmentCondition && (
              <FulfillmentConditionInlineDescriptionTags
                {...contraEntryToEditInState.fulfillmentCondition}
                mb={48}
                mt={16}
              />
            )}
          </Card>
        </Box>
      </StyledLeftColumnBox>
      <Box width="50%">
        <FulfillmentConditionInputs
          fulfillmentConditionOptionSet={
            editRequirements.fulfillmentConditionOptionSet
          }
          isEditMode
          onFinishStep={({ contraEditType }) => {
            onFinishEditContraEntry(
              contraEntryToEditInState,
              contraEditType === ACTIONS.REPLACE_ACTIVE_CONTRA_PARTICIPANT
                ? 'REPLACE'
                : 'REMOVE',
            );
          }}
          onFulfillmentDataChange={onFulfillmentDataChange}
          preSelectedFulfillmentConditionDataOfActiveContra={
            contraEntryToEdit.fulfillmentCondition
          }
          preamble={
            editRequirements.fulfillmentConditionOptionSetListingPreamble
          }
        />
      </Box>
    </>
  );
};
