import { Flex, PM } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import {
  exportToCsv,
  prepareInvestmentsForDownload,
} from '~/components/DownloadCSV/utils';
import { InvestmentsFragment } from '~/graphql/types';

type HoldingsDownloadProps = {
  header: string;
  investments: InvestmentsFragment | null | undefined;
  setIsOpen: (isOpen: boolean) => void;
};

export const HoldingsDownload = ({
  header,
  investments,
  setIsOpen,
}: HoldingsDownloadProps) => {
  const handleClick = () => {
    setIsOpen(false);
    exportToCsv({
      filename: 'Holdings-' + moment().format('MMM-DD-YYYY'),
      headers: [
        'Symbol',
        'Name',
        'Quantity',
        'Avg. Price',
        'Cost Basis',
        'Unrealized Gain ($)',
        'Unrealized Gain (%)',
        'Value',
      ],
      rows: prepareInvestmentsForDownload(investments),
    });
  };

  return (
    <Flex height={40} onClick={handleClick}>
      <PM color="foregroundNeutralMain" content={header} ml={16} />
    </Flex>
  );
};
