import classNames from 'classnames';
import * as React from 'react';

import style from './style.module.scss';

type Props = {
  children:
    | Array<React.ReactElement<React.ComponentProps<any>, any>>
    | React.ReactElement<React.ComponentProps<any>, any>;
  className?: string;
  onClick?: ((...args: Array<any>) => any) | null | undefined;
};

export class TableRow extends React.Component<Props> {
  render() {
    const { className, children, ...others } = this.props;
    const classes = classNames(style.tableRow, className, {
      [style.click]: this.props.onClick,
    });

    return (
      // @ts-expect-error - TS2322 - Type '(e: Event) => void' is not assignable to type 'MouseEventHandler<HTMLTableRowElement>'.
      <tr className={classes} {...others} onClick={this.handleClick}>
        {children}
      </tr>
    );
  }

  handleClick = (e: Event): void => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };
}
