import * as React from 'react';

import { useGetTransferTimingQuery } from '~/graphql/hooks';
import { TransferTypeEnum } from '~/graphql/types';
import { OnClickLinkable } from '~/hooks/useLinkable';
import { useIsEqualSelector } from '~/redux/hooks';
import { CREATE_TRANSFER_FLOW_MODES as MODES } from '~/static-constants';

import { TransferDetailsMessageCard } from './TransferDetailsMessageCard';

export type TransferDetailsMessageCardProps = {
  onTransferSuggestionLinkClick: OnClickLinkable;
};

export const LegacyFormTransferDetailsMessageCard = ({
  onTransferSuggestionLinkClick,
}: TransferDetailsMessageCardProps) => {
  const variables = useIsEqualSelector((state) => {
    const isSchedule = state.newFlows.CREATE_TRANSFER.mode === MODES.SCHEDULE;

    const formValues = state.form['transfer-instance'].values;

    return {
      amount: Number(formValues.amount),
      transferType: isSchedule
        ? TransferTypeEnum.ScheduledTransferRule
        : TransferTypeEnum.TransferInstance,
      fromParticipantId: formValues.fromParticipantId,
      toParticipantId: formValues.toParticipantId,
    };
  });

  const { data, previousData, loading } = useGetTransferTimingQuery({
    variables,
  });

  // The query re-runs on form change, basically each keystroke.
  // We can continue to show the previous value while the new one is loading.
  // Once we're done loading, don't fall back so we aren't showing stale data.
  const dataSource = data ?? (loading ? previousData : null);
  const transferDetailsMessage =
    dataSource?.viewer.transfers?.requirements?.detailsMessage;

  if (!transferDetailsMessage) {
    return null;
  }

  return (
    <TransferDetailsMessageCard
      mt={16}
      backgroundColor="backgroundNeutralTertiary"
      borderColor="transparent"
      detailsMessage={transferDetailsMessage}
      onTransferSuggestionLinkClick={onTransferSuggestionLinkClick}
    />
  );
};
