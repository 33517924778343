import { Pie } from './types';

/**
 * A helper utility that traverses the pie tree in a depth first search
 * manner and checks for inactive slices.
 * @param pie - The pieTree to traverse
 * @returns - Returns true if an inactive slice is found, false otherwise.
 */
export const hasInactiveSlices = (pie: Pie): boolean => {
  // If our pie doesn't have slices than it doesn't
  // have any inactive slices, return false
  if (!pie.slices) {
    return false;
  }

  for (const slice of pie.slices) {
    // @ts-expect-error - TS2339 - Property 'isActive' does not exist on type 'Sliceable'.
    if (slice.to.isActive === false) {
      return true;
    }

    /**
     * Temporarily commenting out the recursive checks on the pieTree
     * for inactiveSlices because it creates a situation where the
     * user tries to edit a pie and cant due to a sub pie having an
     * inactive slice.
     */

    // TODO: Undo this once moving slices is done
    // and we can enable clicking into user pies and DnD again
    // if (
    //   slice.to.type === 'old_pie' ||
    //   (slice.to.type === 'new_pie' && slice.to.slices)
    // ) {
    //   // if we recurse and find an inactive slice,
    //   // return true else just continue the loop.
    //   if (!hasInactiveSlices(slice.to)) {
    //     continue;
    //   } else {
    //     return true;
    //   }
    // }
  }

  // If we get to here and haven't returned true than
  // no inactive slices were found, return false
  return false;
};
