import { PL, Flex } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { Link } from '~/toolbox/link';

export const AccountTradingWindowInProgress = () => {
  return (
    <Flex borderRadius={8} backgroundColor="primary" p={16} width="100%">
      <Flex flexDirection="column" width="70%">
        <PL fontWeight={600} color="foregroundNeutralOnDark" mb={8}>
          Trade window in progress
        </PL>
        <PL mb={8} color="foregroundNeutralOnDark">
          You can move or liquidate holdings after the current trade window
          closes.
        </PL>
        <Link
          kind="inverse"
          to="https://help.m1.com/en/articles/9332079-how-do-trade-windows-work"
          target="_blank"
        >
          Learn more
        </Link>
      </Flex>
      <Flex width="30%">
        {/* @ts-expect-error - TS2322 - Type '{ alt: string; name: "pendingTrades"; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'. */}
        <Illustration alt="schedule image" name="pendingTrades" />
      </Flex>
    </Flex>
  );
};
