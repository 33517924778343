import * as React from 'react';
import { Route } from 'react-router-dom';

import { SmartTransferContraParticipantEntryInstancesPage } from './SmartTransferContraParticipantEntryInstancesPage';

export const SmartTransferContraParticipantEntryInstancesRoute = () => (
  <Route
    element={<SmartTransferContraParticipantEntryInstancesPage />}
    handle={{
      fallbackRoute: {
        to: '/d/c/smart-transfer-details',
      },
    }}
    path="smart-transfer-instances/:smartTransferContraParticipantEntryId"
  />
);
