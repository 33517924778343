import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { Footer } from '~/components/Footer';
import { useLayout } from '~/hooks/useLayout';
import { StyledPageContent } from '~/pages/dashboard/navigation/Navigation.styled';
import { setBorrowAccountDestination } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

import { MastHeader } from '../../invest/portfolio/PortfolioDetailsPage/PortfolioDetailsPage.styled';

import { MarginLandingPage } from './components/MarginLandingPage';

export const BorrowAccountDetailsPage = () => {
  const { contentWidth } = useLayout();

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(setBorrowAccountDestination('margin'));
  }, [dispatch]);

  return (
    <Box width="100%">
      <MastHeader />
      <StyledPageContent contentWidth={contentWidth}>
        <MarginLandingPage />
      </StyledPageContent>
      <Footer footerDisclosures={null} />
    </Box>
  );
};
