import { PL, LS, Flex, MarginProps, Card } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import copy from 'copy-to-clipboard';
import * as React from 'react';

type CopyableCardProps = MarginProps & {
  label?: string;
  size?: 'small' | 'medium';
  children?: React.ReactNode;
  value: string;
};

export const CopyableCard = ({
  size = 'medium',
  label,
  value,
  children,
  ...rest
}: CopyableCardProps) => {
  const [hasCopied, setHasCopied] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);

  const renderCopyAction = () => {
    if (hasCopied) {
      return <PL color="primary" content="Copied" fontWeight={600} />;
    } else if (isHovered) {
      return <PL color="primary" content="Copy" fontWeight={600} />;
    }
    return <Icon name="copy20" />;
  };

  const handleClick = () => {
    copy(value);
    setHasCopied(true);
  };

  const handleMouseLeave = () => {
    setHasCopied(false);
    setIsHovered(false);
  };

  const renderedContent = children ?? value;

  return (
    <Card
      p="11px 12px 11px 19px"
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      backgroundColor={isHovered ? 'backgroundInfoSubtle' : undefined}
      borderColor={isHovered ? 'secondaryTint' : undefined}
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={handleMouseLeave}
      position="relative"
      {...rest}
    >
      <Flex
        alignItems="flex-start"
        flexDirection="column"
        width="100%"
        mr={40}
        overflowX="hidden"
      >
        {label && <LS color="foregroundNeutralMain" content={label} />}
        {size === 'medium' ? (
          <PL content={renderedContent} fontWeight={600} />
        ) : (
          <PL content={renderedContent} />
        )}
      </Flex>
      <Flex
        justifyContent="flex-end"
        minWidth={40}
        position="absolute"
        right={12}
      >
        {renderCopyAction()}
      </Flex>
    </Card>
  );
};
