import * as React from 'react';

import { InvestOnboardingFlow } from '~/flows/components/onboarding/invest/InvestOnboardingFlow';
import { enteredPage, exitedPage } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

// Eventually if we get to a place where we have a single invest account flow, replace this flow with `open-invest-account`.
// For now, treat "invest onboarding" as its own flow. It handles collecting suitability information and opening an Individual account.
export const SetupInvestAccount = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(enteredPage('OPEN_ADDITIONAL_ACCOUNT', undefined));

    return () => dispatch(exitedPage('OPEN_ADDITIONAL_ACCOUNT', undefined));
  }, []);

  return (
    <InvestOnboardingFlow
      basePath="onboarding/setup-invest-account"
      onFinish={() => {}}
    />
  );
};
