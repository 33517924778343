import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { SmartTransferDetails } from '~/components/SmartTransferDetails';
import { useParams } from '~/hooks/useParams';

export const SmartTransferDetailsPage = () => {
  const { smartTransferRuleId } = useParams();

  return (
    <Box margin="0 auto" pb={100} pt={64} width={490}>
      <PageErrorBoundary>
        <SmartTransferDetails
          smartTransferRuleId={smartTransferRuleId as string}
        />
      </PageErrorBoundary>
    </Box>
  );
};
