import { PL } from '@m1/liquid-react';
import React from 'react';

export type BorrowMarketingUseCaseVariables = {
  baseRate: string;
  borrowableAmount: string;
  investedAmount: string;
  maxBorrowableAccountValuePercent: string;
};

type BorrowUseCaseDialogContentProps = {
  dialogContent: BorrowMarketingUseCaseVariables;
  route: string;
};

export const BorrowUseCaseDialogContent = ({
  dialogContent,
  route,
}: BorrowUseCaseDialogContentProps) => {
  const {
    baseRate,
    borrowableAmount,
    investedAmount,
    maxBorrowableAccountValuePercent,
  } = dialogContent;

  switch (route) {
    case 'business':
      return (
        <>
          <PL
            content="After months of careful consideration, and a loyal client base built over years, Sally is making a big life move to open her own salon. She is almost done setting up her space, but she still needs about $3,500 to finish."
            mb={16}
          />
          <PL
            content="Sally knows she has options: she can apply for a loan through her bank, but that could take weeks; she can liquidate some investments out of her $10,000 M1 portfolio, but that’s money she is setting aside for her 12-year-old daughter’s college fund, and doesn’t want to get sidetracked; or, instead of pulling money out of her portfolio, she can borrow against it to get the cash she needs (without an application and at a super-low rate)."
            mb={16}
          />
          <PL
            content={`With M1 Margin Loans, customers can borrow up to ${maxBorrowableAccountValuePercent} percent of their portfolio value. With $10,000 invested, Sally can borrow exactly the amount she needs, when she needs it. Sally is able to open on time and, with greater profit share now that she’s working for herself, she’s able to pay down her Margin Loan at her convenience while still growing her daughter’s college fund.`}
          />
        </>
      );
    case 'buying-power':
      return (
        <>
          <PL
            content={`When making decisions in his portfolio, there are times when Omar is ready to buy and doesn’t want to wait on a bank transfer. Instead of waiting on a deposit to clear, he can instantly borrow up to ${maxBorrowableAccountValuePercent} of his portfolio’s value to get extra buying power and invest before his trade window opens.`}
            mb={16}
          />
          <PL content="In some cases, Omar sees investing opportunities, but he doesn’t have the necessary cash in his bank. Not to worry, because Omar knows he can borrow against his portfolio with a Margin Loan for extra buying power and make his desired investment anyway. He can choose his own repayment schedule for the borrowed funds, all while keeping his portfolio invested in the market. Omar knows that generally, as long as his investment returns are greater than the low annual interest rate he’ll pay on his M1 Margin Loan, the interest pays for itself, and he may be better set up to reach his goals." />
        </>
      );
    case 'emergency':
      return (
        <>
          <PL
            content={`Maya was laid off and out of work for six months. During that time, she relied on her credit card for day-to-day living expenses but she had to figure out what to do about her other larger expenses. While her M1 portfolio held over ${investedAmount}, those investments were set aside for the future. She didn’t want to draw from it because she wanted to keep her longer-term portfolio intact and avoid potential capital gains taxes.`}
            mb={16}
          />
          <PL
            content={`Instead, Maya used M1 Margin Loan to provide the much needed cash flow while she was out of work and to pay down her higher-interest-rate credit card. The portfolio line of credit backed by her ${investedAmount} account allowed her to borrow up to ${borrowableAmount}. Since Maya needed just a fraction of that, she requested just enough to cover her expenses and pay off the credit card in one lump sum. After securing a new job, she was able to pay back the loan - on her terms.`}
          />
        </>
      );
    case 'home-renovations':
      return (
        <>
          <PL
            content="The full kitchen remodel Ricardo has in mind comes up to about $50,000, but the home equity loan options he’s been researching have high interest rates. This isn’t the only major expenditure Ricardo has planned, so he’s interested in ways to raise the funds without worrying about high interest rates."
            mb={16}
          />
          <PL content="Using margin, Ricardo takes out a $50,000 loan against his portfolio to pay for the renovation. He gets the kitchen upgrade he’s been dreaming of, while his portfolio stays invested in the strategy he believes in." />
        </>
      );
    case 'refinance':
      return (
        <>
          <PL
            content="Though Priya is now an accomplished professional in her field, she accrued a hefty $200,000 in student loan debt from years of undergrad and post-grad education. Keeping up with monthly payments towards high principal with an aggressive interest rate means she’s had to make tradeoffs in her personal goals."
            mb={16}
          />
          <PL
            content={`But, she’s been steadily investing in her M1 Brokerage Account that is now eligible for margin, meaning she can tap in to a portfolio line of credit to supplement her payments. With a base rate of ${baseRate}, a M1 Margin Loan costs much less than her private and federal student loans, and since she can choose her own payback schedule, Priya enjoys more flexibility that lets her keep up with her personal goals.`}
            mb={16}
          />
          <PL content="Better yet, since M1 Margin Loan interest rates are often lower than rates charged for many other types of loans—home equity lines of credit, credit cards, or auto loans—Priya knows she can turn to a Margin Loan again the next time she needs a large sum of money." />
        </>
      );
    case 'wedding':
      return (
        <>
          <PL
            content="Steve and Lisa are planning their dream wedding with the perfect venue in mind. But even with a reasonable budget, deposits for all the details are adding up to quite the sum to pay down upfront."
            mb={16}
          />
          <PL
            content={`With a ${investedAmount} M1 portfolio, they can tap into their investments for up to ${borrowableAmount} so that they can comfortably fund their wedding without worrying about payback schedules or high interest rates.`}
            mb={16}
          />
          <PL content="By the time their wedding day rolls around—knowing they were able to avoid high credit card interest rates and payback amounts—Steve and Lisa can dance down the aisle as they start their new chapter." />
        </>
      );
    default:
      return null;
  }
};
