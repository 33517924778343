import { Box, Flex, PL, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { useMediaQuery } from 'react-responsive';

import { BackArrow } from '~/components/BackArrow';

import { useAnalytics } from '~/hooks/useAnalytics';
import { AppImage } from '~/lens-toolbox/AppImage';
import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';

import { AcatWizardBackButton } from '../components/AcatWizardBackButton';
import { AcatWizardErrorState } from '../components/AcatWizardErrorState';
import { AcatWizardStepCard } from '../components/AcatWizardStepCard';
import { AcatWizardStepContainer } from '../components/AcatWizardStepContainer';
import { InvestAcatWizardContraSearchFormContainerComponent } from '../components/InvestAcatWizardContraSearchFormContainer';
import { SelectContraBrokerageEmptyState } from '../components/SelectContraBrokerageEmptyState';
import { useAcatWizardFormContext } from '../hooks/useAcatWizardFormContext';
import { useAcatWizardQueryContext } from '../hooks/useAcatWizardQueryContext';

type StyledParticipantRowProps = {
  showBottomBorder: boolean;
};

const StyledBrokerageList = styled(Box)`
  flex: 0 1 auto;
  overflow-y: auto;
  column-gap: 24px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.XSMALL}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledBrokerageOption = styled(Flex)`
  cursor: pointer;

  &:hover {
    transition: background-color 200ms;
    background-color: ${(props) => props.theme.colors.backgroundInfoSubtle};
  }
`;

const StyledParticipantRow = styled.div<StyledParticipantRowProps>`
  width: 100%;
  padding: 12px 0;

  // Only applies border to all items except last 2 which are on the bottom of each column
  border-bottom: ${(props) =>
    props.showBottomBorder
      ? `1px solid ${props.theme.colors.borderMain}`
      : 'none'};
`;

export const AcatWizardSelectContraBrokerageStep = () => {
  const isMobile = useMediaQuery({
    query: RESPONSIVE_BREAKPOINTS.SMALL,
  });
  const data = useAcatWizardQueryContext();
  const { back, setValue, next } = useAcatWizardFormContext();

  const analytics = useAnalytics();

  React.useEffect(() => {
    analytics.pageView('m1_acat_wizard_select_contra_brokerage_screen_view');
  }, [analytics]);

  const brokerageSelectionContent =
    data.viewer.invest?.acatWizard?.brokerageSelectionContent;

  if (!brokerageSelectionContent) {
    return (
      <AcatWizardErrorState>
        <BackArrow content="Back" onClick={() => back()} />
      </AcatWizardErrorState>
    );
  }

  const { contraParticipants, header } = brokerageSelectionContent;
  if (!contraParticipants?.edges || contraParticipants.edges.length === 0) {
    return <SelectContraBrokerageEmptyState />;
  }

  const contraParticipantListLength = contraParticipants.edges?.length;
  const numberOfColumns = isMobile ? 1 : 2;

  return (
    <AcatWizardStepContainer>
      <AcatWizardBackButton />
      <AcatWizardStepCard header={header}>
        <Flex py={16}>
          <InvestAcatWizardContraSearchFormContainerComponent />
        </Flex>
        <StyledBrokerageList>
          {contraParticipants.edges.map((edge, idx) => {
            if (!edge?.node) {
              return null;
            }

            const participant = edge.node;
            if (!participant.name) {
              return null;
            }

            return (
              <StyledBrokerageOption
                alignItems="center"
                justifyContent="space-between"
                key={participant.id}
                onClick={() => {
                  setValue('externalBrokerageId', participant.id);
                  next();
                }}
              >
                <StyledParticipantRow
                  showBottomBorder={Boolean(
                    contraParticipantListLength &&
                      Boolean(
                        idx < contraParticipantListLength - numberOfColumns,
                      ),
                  )}
                >
                  <Flex alignItems="center">
                    {participant.icon ? (
                      <AppImage
                        alt="brokerage logo"
                        appImage={participant.icon}
                        style={{
                          borderRadius: '4px',
                          marginLeft: '8px',
                        }}
                        height={40}
                        width={40}
                      />
                    ) : (
                      <Icon
                        name="accountBankPrimary32"
                        style={{
                          borderRadius: '4px',
                        }}
                      />
                    )}
                    <PL content={participant.name} ml={16} />
                  </Flex>
                </StyledParticipantRow>
              </StyledBrokerageOption>
            );
          })}
        </StyledBrokerageList>
      </AcatWizardStepCard>
    </AcatWizardStepContainer>
  );
};
