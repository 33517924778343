import { Button } from '@m1/liquid-react';
import * as React from 'react';

import { PieOrganizerConfig } from '~/components/PortfolioOrganizerPage';

import {
  MoveSecuritiesConfirmTargetsFragment,
  ValidSliceDestinationFragment,
} from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';

type UnmanagedHoldingsSelectParentPieContinueButtonProps = {
  label: string;
  moveSecuritiesConfirmTargets: MoveSecuritiesConfirmTargetsFragment;
  newPie: any;
  previousPieEditorConfig: PieOrganizerConfig;
  selectedRow: ValidSliceDestinationFragment | null | undefined;
};

export const UnmanagedHoldingsSelectParentPieContinueButton = ({
  label,
  moveSecuritiesConfirmTargets,
  newPie,
  previousPieEditorConfig,
  selectedRow,
}: UnmanagedHoldingsSelectParentPieContinueButtonProps) => {
  const navigate = useNavigate();
  const unmanagedHoldingsToMove =
    previousPieEditorConfig.unmanagedHoldingsFlow?.unmanagedHoldingsToMove;

  const grandparentPortfolioSliceId = selectedRow?.id;

  const onClick = React.useCallback(() => {
    if (selectedRow) {
      const pieOrganizerConfig: PieOrganizerConfig = {
        buttons: {
          backButton: {
            label: 'Choose a location',
            state: {
              newPie,
              previousPieEditorConfig,
            },
            to: '/d/invest/select-parent-pie',
          },
          primary: moveSecuritiesConfirmTargets.saveTargetsCtaLabel
            ? moveSecuritiesConfirmTargets.saveTargetsCtaLabel
            : 'Continue',
        },
        confirmationDialog: {
          showApplicableLocations: false,
        },
        disabledFeatures: [
          'ADD_SLICES',
          'MOVE_SLICES',
          'REMOVE_SLICES',
          'CREATE_PIE',
        ],
        mode: 'EDIT_PIE',
        pieId: selectedRow.to.id,
        returnTo: '/d/invest/unmanaged-holdings',
        sliceableIds: [newPie.id],
        subtitle: moveSecuritiesConfirmTargets.header
          ? moveSecuritiesConfirmTargets.header
          : undefined,
        subtitleLink: moveSecuritiesConfirmTargets.link,
        title: moveSecuritiesConfirmTargets.title
          ? moveSecuritiesConfirmTargets.title
          : undefined,
        unmanagedHoldingsFlow: {
          // clear our unmanagedHoldingIdsForMergedSlices because in the create state,
          // the uh is the grandchild of the pie being edited, not the immediate child
          unmanagedHoldingIdsForMergedSlices: [],
          unmanagedHoldingsToMove: unmanagedHoldingsToMove
            ? unmanagedHoldingsToMove
            : [],
          grandparentPortfolioSliceId,
          newPieId: newPie.id,
          type: 'CREATE_AND_MOVE_FLOW',
        },
        zeroPercentDisabled: true,
      };

      navigate({
        to: '/d/invest/portfolio-organizer',
        options: {
          state: {
            event: {
              type: 'INITIALIZE_ORGANIZER',
              initConfig: pieOrganizerConfig,
            },
          },
        },
      });
    }
  }, [
    navigate,
    moveSecuritiesConfirmTargets,
    newPie,
    previousPieEditorConfig,
    selectedRow,
  ]);

  return (
    <Button
      disabled={!selectedRow}
      label={label}
      onClick={onClick}
      size="large"
    />
  );
};
