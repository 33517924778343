import { Box, Button, Flex, PL } from '@m1/liquid-react';
import * as React from 'react';

import { RadioChoice } from '~/components/form/Radio';
import { GenericSystemError } from '~/components/GenericSystemError';
import { useAnalytics } from '~/hooks/useAnalytics';

import { useLaunchDarkly } from '~/hooks/useLaunchDarkly';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';

import { EXTERNAL_LINKS } from '~/static-constants';

import { AcatWizardBackButton } from '../components/AcatWizardBackButton';
import { AcatWizardStepCard } from '../components/AcatWizardStepCard';
import { AcatWizardStepContainer } from '../components/AcatWizardStepContainer';
import { useAcatWizardFormContext } from '../hooks/useAcatWizardFormContext';
import { useAcatWizardQueryContext } from '../hooks/useAcatWizardQueryContext';
import { handleEnterKeyDownUtils } from '../utils';

export const AcatWizardSelectTransferTypeStep = () => {
  const analytics = useAnalytics();
  const data = useAcatWizardQueryContext();
  const { goTo, next, watch } = useAcatWizardFormContext();
  const selectedTransferType = watch('selectedTransferType');
  const { flagResult: newRolloverFlow, isReady: isNewRolloverFlowReady } =
    useLaunchDarkly('acat_transfers_wizard_ira_rollovers_v1', false);

  const handleContinue = () => {
    const event = acatTransferTypeScreenContent?.selections.find(
      (selection) => selection.navigation === selectedTransferType,
    )?.analyticsEvent;
    if (event) {
      analytics.recordAppAnalyticsEvent(event);
    }

    if (selectedTransferType === 'ROLLOVER') {
      if (newRolloverFlow && isNewRolloverFlowReady) {
        goTo('rollover-overview');
      } else {
        window.open(EXTERNAL_LINKS.ROLLOVER_401K_TO_IRA, '_blank');
      }
    } else {
      next();
    }
  };

  const acatTransferTypeScreenContent =
    data?.viewer.invest?.acatWizard?.acatTransferTypeScreenContent;
  if (!acatTransferTypeScreenContent) {
    return <GenericSystemError />;
  }

  return (
    <AcatWizardStepContainer>
      <AcatWizardBackButton />
      <AcatWizardStepCard
        header={acatTransferTypeScreenContent.header}
        onKeyPress={(event) => {
          if (selectedTransferType) {
            handleEnterKeyDownUtils(event, true, () => handleContinue());
          }
        }}
      >
        {acatTransferTypeScreenContent.headerLink && (
          <LinkableLink
            linkable={acatTransferTypeScreenContent.headerLink}
            mb={32}
          />
        )}

        {acatTransferTypeScreenContent.selections.map((selection, idx) => (
          <RadioChoice
            name="selectedTransferType"
            key={idx}
            value={selection.navigation}
            label={
              <Box my={8}>
                <PL content={selection.label} />
                <PL
                  content={selection.sublabel}
                  color="foregroundNeutralSecondary"
                />
              </Box>
            }
          />
        ))}
      </AcatWizardStepCard>
      <Flex>
        <Button
          disabled={!selectedTransferType}
          label={acatTransferTypeScreenContent.ctaLabel}
          onClick={() => handleContinue()}
          size="large"
        />
      </Flex>
    </AcatWizardStepContainer>
  );
};
