import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import {
  PortfolioTradingDetailsDocument,
  useStartCryptoOnDemandTradeWindowMutation,
} from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import { useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';
import { Spinner } from '~/toolbox/spinner';

import { PortfolioTradingDetails } from './components/PortfolioTradingDetails';

export const OnDemandPortfolioTradingDetailsPage = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();

  const accountId = useSelector((state) => state.global.activeAccountId);
  const [startCryptoOnDemandTradeWindow, { loading }] =
    useStartCryptoOnDemandTradeWindowMutation();

  const handleExecuteTradeWindow = () => {
    if (accountId) {
      startCryptoOnDemandTradeWindow({
        variables: {
          input: {
            accountId,
          },
        },
        onCompleted: (data) =>
          navigate({
            to: '/d/invest/portfolio/on-demand-trading/:tradeWindowId',
            params: {
              tradeWindowId:
                data?.startCryptoOnDemandTradeWindow?.outcome?.tradeWindowId,
            },
          }),
        onError: (e) =>
          addToast({
            content: e.message,
            duration: 'long',
            kind: 'alert',
          }),
        refetchQueries: [
          {
            query: PortfolioTradingDetailsDocument,
            variables: {
              accountId,
            },
          },
        ],
      });
    }
  };

  return (
    <PageErrorBoundary>
      <Box p={16}>
        {loading ? (
          <Spinner fullScreen />
        ) : (
          <PortfolioTradingDetails onExecuteTrades={handleExecuteTradeWindow} />
        )}
      </Box>
    </PageErrorBoundary>
  );
};
