import { styled, css, theme, Flex } from '@m1/liquid-react';

import { StyledPageContent } from '../navigation/Navigation.styled';

export const ResponstivePaddingStyles = css`
  margin: 0 16px;

  @media screen and (min-width: ${theme.breakpoints.XXSMALL}) {
    margin: 0 80px;
  }
`;

export const StyledLandingPageContent = styled(StyledPageContent)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`;

export const ShrinkContainer = styled(Flex)<{ shouldShrink: boolean }>`
padding-top: 8px;
gap:24px;
flex-direction: column;
min-width: 225px;
transition: transform  0.75s, opacity .75s;
opacity: 1;

${({ shouldShrink }) =>
  shouldShrink &&
  css`
    transform: scale(0.75);
    opacity: 0.1;
  `})}
`;
