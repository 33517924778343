import mapValues from 'lodash-es/mapValues';
import * as React from 'react';
// @ts-expect-error - TS2305 - Module '"react-spring/renderprops"' has no exported member 'animated'.
import { animated, Transition } from 'react-spring/renderprops';

import style from './style.module.scss';

type Steps = Record<string, React.ComponentType<any>>;
type Props = {
  step: string;
  steps: Steps;
};

export class StepFadeTransition extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return this.props.step !== nextProps.step;
  }

  render() {
    const { steps, ...rest } = this.props;
    const animatedSteps = mapValues(
      steps,
      (Step) => (style: React.CSSProperties) => (
        <animated.div style={style}>
          <Step {...rest} />
        </animated.div>
      ),
    );

    return (
      <div className={style.root}>
        <Transition
          native
          items={this.props.step}
          from={{
            position: 'absolute',
            opacity: 0,
          }}
          enter={{
            position: 'relative',
            opacity: 1,
          }}
          leave={{
            position: 'absolute',
            opacity: 0,
            pointerEvents: 'none',
          }}
        >
          {/* @ts-expect-error - TS7006 - Parameter 'step' implicitly has an 'any' type. */}
          {(step) => animatedSteps[step]}
        </Transition>
      </div>
    );
  }
}

export function createStepFade(steps: Steps): React.ComponentType<any> {
  return class extends React.Component<any> {
    render() {
      // @ts-expect-error - TS2769 - No overload matches this call.
      return <StepFadeTransition {...this.props} steps={steps} />;
    }
  };
}
