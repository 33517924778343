import classNames from 'classnames';
import * as React from 'react';

import style from './style.module.scss';

type Props = {
  children?: React.ReactElement<React.ComponentProps<any>, any>;
  fixed: boolean;
};

export class TableFoot extends React.Component<Props> {
  static defaultProps = {
    fixed: false,
  };

  render() {
    const classes = classNames(style.tableFoot, {
      [style.fixedFooter]: this.props.fixed,
    });
    return <tfoot className={classes}>{this.props.children}</tfoot>;
  }
}
