import { HXS, PM, Flex, Box } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

export const SliceableListNoSlicesCard = () => {
  return (
    <Box
      backgroundColor="backgroundNeutralSecondary"
      borderColor="borderMain"
      borderRadius={8}
      borderStyle="solid"
      borderWidth={1}
      mt={4}
      p="60px 80px"
      width="100%"
    >
      <Flex alignItems="center" flexDirection="column" justifyContent="center">
        {/* @ts-expect-error - TS2322 - Type '{ alt: string; name: "searchStocks"; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'. */}
        <Illustration alt="" name="searchStocks" />
        <HXS my={20}>You have no Slices</HXS>
        <PM color="foregroundNeutralSecondary">
          To add Slices, tap Add Slice.
        </PM>
      </Flex>
    </Box>
  );
};
