import * as React from 'react';
import { Route } from 'react-router-dom';

import { RESOLVE_CALL_STEPS as STEPS } from '~/static-constants';

import { CreateTransferPage } from './create-transfer';
import { ResolveMarginCallPage } from './margin-call-information';
import { ResolveMarginCallLayout } from './ResolveMarginCallLayout';
import { SetOrder } from './set-order';

export const ResolveCallRoute = () => (
  <Route
    element={<ResolveMarginCallLayout />}
    handle={{
      fallbackRoute: {
        to: '/d/borrow',
      },
    }}
    path="resolve-call"
  >
    <Route
      element={<ResolveMarginCallPage />}
      path={`${STEPS.RESOLVE_MARGIN_CALL}/:resolveMarginCallStep?`}
    />
    <Route
      element={<CreateTransferPage />}
      path={`${STEPS.CREATE_TRANSFER}/:createTransferStep?`}
    />
    <Route element={<SetOrder />} path={`${STEPS.SET_ORDER}/:setOrderStep?`} />
  </Route>
);
