import { PXL, Flex, Text } from '@m1/liquid-react';
import React from 'react';

import type { AnnouncementFragment } from '~/graphql/types';
import { AppImage } from '~/lens-toolbox/AppImage';
import { LinkableButton } from '~/lens-toolbox/LinkableButton';

import {
  AnnouncementNudgeContainer,
  AnnouncementNudgeContent,
  AnnouncementIconContainer,
} from './StyledAnnouncementNudge';
export const AnnouncementNudge = ({
  announcement,
}: {
  announcement: AnnouncementFragment;
}) => {
  return (
    <AnnouncementNudgeContainer>
      <AnnouncementNudgeContent>
        {announcement?.announcementIcon && (
          <AnnouncementIconContainer>
            <AppImage alt="icon" appImage={announcement.announcementIcon} />
          </AnnouncementIconContainer>
        )}
        <Flex alignItems="center">
          <PXL fontWeight={600}>
            {announcement.title}{' '}
            {announcement.description && (
              <Text fontWeight={400} content={announcement.description} />
            )}
          </PXL>
          {announcement.appLink && (
            <LinkableButton
              ml={16}
              size="small"
              linkable={announcement.appLink}
            />
          )}
        </Flex>
      </AnnouncementNudgeContent>
    </AnnouncementNudgeContainer>
  );
};
