import * as React from 'react';
import {
  Controller,
  Path,
  FieldValues,
  ControllerProps,
} from 'react-hook-form';

import { CodeInput, CodeInputProps } from '~/toolbox/CodeInput/CodeInput';

type CodeInputFieldProps<
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues = FieldValues,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  CodeInputProps & {
    onChange?: (value: string) => void;
  };

export const ControlledCodeInput = <
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues,
>({
  control,
  name,
  rules,
  onChange,
  ...codeInputProps
}: CodeInputFieldProps<TName, TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState, ...rest }) => {
        return (
          <CodeInput
            {...field}
            {...codeInputProps}
            {...rest}
            onChange={(value) => {
              field.onChange(value);

              if (onChange) {
                onChange(value);
              }
            }}
            error={fieldState.error?.message}
          />
        );
      }}
    />
  );
};
