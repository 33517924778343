export function makeFormConfig(
  obj: Record<string, any> = {},
): Record<string, any> {
  return obj;
}

export function stripPhoneNumberFormatting(
  phoneNumber: string | undefined,
): string | null {
  if (!phoneNumber) {
    return null;
  }

  return phoneNumber.replace(/[^0-9]/g, '');
}
