import { Text, Flex } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';
import { useController } from 'react-hook-form';
import { components } from 'react-select';

import { PossibleOptions, Dropdown } from '~/toolbox/Dropdown';

import { useAcatWizardFormContext } from '../hooks/useAcatWizardFormContext';
import type { InvestAcatDestinationAccountContent } from '../hooks/useAcatWizardQueryContext';

type PlaceholderProps = {
  disabled: boolean;
  options?: PossibleOptions | null | undefined;
};

// FIXME(Wolf): Fix this once you upgrade react-select.
const ReactSelectPlaceholder = components.Placeholder as any;

const Placeholder = ({ disabled = false, ...props }: PlaceholderProps) => (
  <ReactSelectPlaceholder {...props} disabled={disabled}>
    <Flex alignItems="center">
      <Icon name="accountBankDisabled32" />
      <Text content="Select an account" ml={16} />
    </Flex>
  </ReactSelectPlaceholder>
);

const SingleValue = (props: any) => {
  const validHTMLProps = {
    ...props,
    cx: null,
  };

  return (
    <Flex {...validHTMLProps} alignItems="center" gap={16}>
      <Icon css={{ paddingTop: 0 }} name="accountInvestAccent32" />
      <Flex flexDirection="column" gap={2}>
        <Text color="foregroundNeutralSecondary">
          {props.data.sublabel || props.data.label}
        </Text>
      </Flex>
    </Flex>
  );
};

export const AcatWizardAccountDropdown = ({
  accounts,
  label,
}: {
  accounts: InvestAcatDestinationAccountContent['validParticipants'];
  label: InvestAcatDestinationAccountContent['validParticipantsLabel'];
}) => {
  const form = useAcatWizardFormContext();
  const { field } = useController({
    control: form.control,
    name: 'destinationAccountId',
  });

  if (!accounts?.edges) {
    throw new Error('AcatWizardAccountDropdown requires accounts.');
  }

  const accountOptions = accounts.edges.map((edge) => ({
    label: edge?.node?.name ?? '',
    sublabel: edge?.node?.accountTypeWithNumberDescriptor,
    value: edge?.node?.id ?? '',
  }));

  return (
    <Dropdown
      components={{
        Placeholder,
        SingleValue,
      }}
      label={label ?? ''}
      name={field.name}
      options={accountOptions}
      optionContent={(props) => (
        <Flex alignItems="center" gap={16}>
          <Icon css={{ paddingTop: 0 }} name="accountInvestAccent32" />
          <Flex flexDirection="column" gap={2}>
            <Text content={props.data.label} />
            {props.data.sublabel &&
              props.data.label !== props.data.sublabel && (
                <Text color="foregroundNeutralSecondary">
                  {props.data.sublabel}
                </Text>
              )}
          </Flex>
        </Flex>
      )}
      onChange={field.onChange}
      value={field.value}
    />
  );
};
