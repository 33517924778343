import { HXS, PL } from '@m1/liquid-react';
import React from 'react';

import { SliceableFragment } from '~/graphql/types';
import { Section } from '~/toolbox/section';

import { SliceableBreadCrumbs } from '../SliceableBreadCrumbs';

import { usePieDetailsContext } from './hooks/usePieDetailsContext';
import { PieAnalysisMetrics } from './PieAnalysisMetrics';

export const PieProfileSection = () => {
  const { pie, disableBreadcrumbs } = usePieDetailsContext();
  if (!pie) {
    return null;
  }
  return (
    <Section>
      <HXS content="Profile" mt={16} mb={12} />
      <div
        style={{
          paddingBottom: 16,
        }}
      >
        {pie.portfolioLinks && (
          <SliceableBreadCrumbs
            navigable={!disableBreadcrumbs}
            sliceable={pie as SliceableFragment}
          />
        )}
      </div>
      {pie.description && <PL content={pie.description} mb={16} />}
      {pie.analysis && (
        <PieAnalysisMetrics
          pieAnalysis={pie.analysis}
          isCrypto={pie.__typename === 'CryptoSystemPie'}
        />
      )}
    </Section>
  );
};
