import React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { MarketingScreenTemplate } from '~/components/MarketingScreenTemplate';
import { useSpendMarketingScreenQuery } from '~/graphql/hooks';
import { useLocation } from '~/hooks/useLocation';

export const SpendMarketingPage = () => {
  // Results of this query can change based on a user's eligibility.
  // This can happen async, or as a result of different mutations.
  // Rather than trying to update based on any possible change,
  // we can always make a network call to get the latest state.
  // But since the most common case is for eligibility not to change
  // while the user is navigating the app, use `cache-and-network`
  // so we can load something immediately from the cache.
  const { data, loading } = useSpendMarketingScreenQuery({
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });
  // When the user is on a marketing page and navigates to a cover page,
  // we want to prevent any flickering or shifting of the content
  const location = useLocation();
  const isCoverPage = location.pathname.includes('d/c/');

  if (loading || isCoverPage) {
    return null;
  }

  if (!data?.viewer) {
    return <GenericSystemError />;
  }

  const title = data?.viewer?.spend?.spendMarketingScreen?.header;
  const productMarketingCards =
    data?.viewer?.spend?.spendMarketingScreen?.productMarketingCards;
  const disclosures = data?.viewer?.spend?.spendMarketingScreen?.disclosures;
  const disclosureLinks =
    data?.viewer?.spend?.spendMarketingScreen?.disclosureLinks;

  return loading ? null : (
    <MarketingScreenTemplate
      title={title}
      productMarketingCards={productMarketingCards}
      disclosures={disclosures}
      disclosureLinks={disclosureLinks}
    />
  );
};
