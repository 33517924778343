import { useTheme, styled, css, Box } from '@m1/liquid-react';
import * as React from 'react';

const Div = styled(Box)<{
  favoritable: boolean;
}>`
  svg {
    cursor: pointer;
    overflow: visible;
    width: 32px;
    #heart {
      transform-origin: center;
      animation: animateHeartOut 0.3s linear forwards;
    }
    #main-circ {
      transform-origin: 29.5px 29.5px;
    }

    ${({ favoritable }) =>
      !favoritable &&
      css`
        visibility: hidden;
      `}
  }

  input {
    display: none;
  }

  input:checked + label svg {
    #heart {
      transform: scale(0.2);
      fill: url(#plus-gradient);
      animation: animateHeart 0.3s linear forwards 0.25s;
    }
    #main-circ {
      transition: all 2s;
      animation: animateCircle 0.3s linear forwards;
      opacity: 1;
    }
    #grp1 {
      opacity: 1;
      transition: 0.1s all 0.3s;
      #oval1 {
        transform: scale(0) translate(0, -30px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }
      #oval2 {
        transform: scale(0) translate(10px, -50px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }
    #grp2 {
      opacity: 1;
      transition: 0.1s all 0.3s;
      #oval1 {
        transform: scale(0) translate(30px, -15px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }
      #oval2 {
        transform: scale(0) translate(60px, -15px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }
    #grp3 {
      opacity: 1;
      transition: 0.1s all 0.3s;
      #oval1 {
        transform: scale(0) translate(30px, 0px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }
      #oval2 {
        transform: scale(0) translate(60px, 10px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }
    #grp4 {
      opacity: 1;
      transition: 0.1s all 0.3s;
      #oval1 {
        transform: scale(0) translate(30px, 15px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }
      #oval2 {
        transform: scale(0) translate(40px, 50px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }
    #grp5 {
      opacity: 1;
      transition: 0.1s all 0.3s;
      #oval1 {
        transform: scale(0) translate(-10px, 20px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }
      #oval2 {
        transform: scale(0) translate(-60px, 30px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }
    #grp6 {
      opacity: 1;
      transition: 0.1s all 0.3s;
      #oval1 {
        transform: scale(0) translate(-30px, 0px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }
      #oval2 {
        transform: scale(0) translate(-60px, -5px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }
    #grp7 {
      opacity: 1;
      transition: 0.1s all 0.3s;
      #oval1 {
        transform: scale(0) translate(-30px, -15px);
        transform-origin: 0 0 0;
        transition: 0.5s transform 0.3s;
      }
      #oval2 {
        transform: scale(0) translate(-55px, -30px);
        transform-origin: 0 0 0;
        transition: 1.5s transform 0.3s;
      }
    }
    #grp2 {
      opacity: 1;
      transition: 0.1s opacity 0.3s;
    }
    #grp3 {
      opacity: 1;
      transition: 0.1s opacity 0.3s;
    }
    #grp4 {
      opacity: 1;
      transition: 0.1s opacity 0.3s;
    }
    #grp5 {
      opacity: 1;
      transition: 0.1s opacity 0.3s;
    }
    #grp6 {
      opacity: 1;
      transition: 0.1s opacity 0.3s;
    }
    #grp7 {
      opacity: 1;
      transition: 0.1s opacity 0.3s;
    }
  }

  @keyframes animateCircle {
    40% {
      transform: scale(10);
      opacity: 1;
      fill: #d3b3b5;
    }
    55% {
      transform: scale(11);
      opacity: 1;
      fill: #cfa1d5;
    }
    65% {
      transform: scale(12);
      opacity: 1;
      fill: #cc8ef5;
    }
    75% {
      transform: scale(13);
      opacity: 1;
      fill: transparent;
      stroke: #cc8ef5;
      stroke-width: 0.5;
    }
    85% {
      transform: scale(17);
      opacity: 1;
      fill: transparent;
      stroke: #cc8ef5;
      stroke-width: 0.2;
    }
    95% {
      transform: scale(18);
      opacity: 1;
      fill: transparent;
      stroke: #cc8ef5;
      stroke-width: 0.1;
    }
    100% {
      transform: scale(19);
      opacity: 1;
      fill: transparent;
      stroke: #cc8ef5;
      stroke-width: 0;
    }
  }

  @keyframes animateHeart {
    0% {
      transform: scale(0.2);
    }
    40% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes animateHeartOut {
    0% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const UserFavorite = ({
  onFavorite,
  user,
  username,
}: {
  onFavorite?: (username: TestUsername, checked: boolean) => void;
  user: TestUser | null | undefined;
  username: TestUsername;
}) => {
  const theme = useTheme();

  return (
    <Div favoritable={Boolean(onFavorite)}>
      <input
        type="checkbox"
        id={username}
        onChange={(e) => onFavorite && onFavorite(username, e.target.checked)}
        checked={user?.isFavorite ?? false}
      />
      <label htmlFor={username}>
        <svg viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
          <g
            id="Group"
            fill="none"
            fillRule="evenodd"
            transform="translate(467 392)"
          >
            <path
              d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z"
              id="heart"
              fill="#AAB8C2"
            />
            <circle
              id="main-circ"
              fill="#E2264D"
              opacity="0"
              cx="29.5"
              cy="29.5"
              r="1.5"
            />
            <linearGradient id="plus-gradient" gradientTransform="rotate(45)">
              <stop offset="5%" stopColor={theme.colors.m1Plus} />
              <stop offset="95%" stopColor={theme.colors.m1Plus} />
            </linearGradient>

            <g id="grp7" opacity="0" transform="translate(7 6)">
              <circle id="oval1" fill="#9CD8C3" cx="2" cy="6" r="2" />
              <circle id="oval2" fill="#8CE8C3" cx="5" cy="2" r="2" />
            </g>

            <g id="grp6" opacity="0" transform="translate(0 28)">
              <circle id="oval1" fill="#CC8EF5" cx="2" cy="7" r="2" />
              <circle id="oval2" fill="#91D2FA" cx="3" cy="2" r="2" />
            </g>

            <g id="grp3" opacity="0" transform="translate(52 28)">
              <circle id="oval2" fill="#9CD8C3" cx="2" cy="7" r="2" />
              <circle id="oval1" fill="#8CE8C3" cx="4" cy="2" r="2" />
            </g>

            <g id="grp2" opacity="0" transform="translate(44 6)">
              <circle id="oval2" fill="#CC8EF5" cx="5" cy="6" r="2" />
              <circle id="oval1" fill="#CC8EF5" cx="2" cy="2" r="2" />
            </g>

            <g id="grp5" opacity="0" transform="translate(14 50)">
              <circle id="oval1" fill="#91D2FA" cx="6" cy="5" r="2" />
              <circle id="oval2" fill="#91D2FA" cx="2" cy="2" r="2" />
            </g>

            <g id="grp4" opacity="0" transform="translate(35 50)">
              <circle id="oval1" fill="#F48EA7" cx="6" cy="5" r="2" />
              <circle id="oval2" fill="#F48EA7" cx="2" cy="2" r="2" />
            </g>

            <g id="grp1" opacity="0" transform="translate(24)">
              <circle id="oval1" fill="#9FC7FA" cx="2.5" cy="3" r="2" />
              <circle id="oval2" fill="#9FC7FA" cx="7.5" cy="2" r="2" />
            </g>
          </g>
        </svg>
      </label>
    </Div>
  );
};
