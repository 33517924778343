import { Flex, styled } from '@m1/liquid-react';

export const StyledPageContainer = styled(Flex)`
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  width: 100vw;
`;

export const StyledPieDetailsContainer = styled(Flex)<{
  isLessThan1000: boolean;
}>`
  flex: 0 0 360px;
  flex-direction: column;
  margin-right: 0;
  margin-top: 0;
  width: 100%;
`;

export const StyledSliceablesContainer = styled(Flex)<{
  isLessThan1000: boolean;
}>`
  flex: 1;
  flex-direction: column;
  margin-left: ${(props) => (props.isLessThan1000 ? 0 : '24px')};
  padding-top: ${(props) => (props.isLessThan1000 ? '24px' : 0)};
  width: 100%;
  container: styledSliceablesContainer / inline-size;
`;
