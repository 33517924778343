import { styled } from '@m1/liquid-react';
import React from 'react';

import { Pill } from '~/toolbox/Pill';
import { formatCurrencyWithCommasOrNull } from '~/utils';

const StyledPill = styled(Pill)`
  cursor: pointer;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.backgroundPrimarySubtle};
  padding: 12px;
  border-radius: 16px;
  font-weight: 500;

  &:hover {
    ${({ theme }) => `color: ${theme.colors.backgroundNeutralSecondary};
    background-color: ${theme.colors.primaryTint};
    transition: 200ms;
  `}
`;

type DepositPillProps = {
  onClick: (amount: number | undefined) => void;
  amount?: number;
};

export const DepositPill = ({ onClick, amount }: DepositPillProps) => (
  <StyledPill
    onClick={() => onClick(amount)}
    key={amount}
    mx={7}
    size="large"
    label={formatCurrencyWithCommasOrNull(amount) ?? '$0'}
  />
);
