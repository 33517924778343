import {
  Box,
  Flex,
  HS,
  HXXS,
  LS,
  PL,
  PM,
  PXL,
  styled,
  theme,
  css,
} from '@m1/liquid-react';

export const StyledContainer = styled(Box)`
  margin-top: 24px;
  position: sticky;
  top: 100px;
  z-index: 100;
  width: 100%;
  max-height: 710px;
`;

// TODO: update to use LiRe boxShadow once it's available
export const StyledBox = styled(Box)<{ isOpen: boolean }>`
  position: absolute;
  width: inherit;
  background-color: ${theme.colors.backgroundNeutralSecondary};
  border-radius: 8px;
  box-shadow: 0px 4px 24px 0px rgba(83, 96, 115, 0.2);
  transition:
    max-height 0.75s,
    height 0.75s,
    padding 0.25s 0.5s;

  ${({ isOpen }) => {
    return isOpen
      ? css`
          transition:
            max-height 0.75s,
            height 0.75s;
          max-height: 710px;
          padding: 24px;
        `
      : css`
          max-height: 0;
          padding: 0;
        `;
  }};

  overflow-x: hidden;
`;

export const StyledHeaderContainer = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const StyledHeader = styled(HXXS)`
  font-weight: 300;
`;

export const StyledPrimaryDetailsContainer = styled(Flex)`
  margin-bottom: 12px;
  flex-wrap: wrap;
  column-gap: 72px;
  row-gap: 8px;
`;

export const StyledPrimaryDetailsItemContainer = styled(Flex)`
  flex-direction: column;
`;

export const StyledPrimaryDetailsItemLabel = styled(PM)`
  font-weight: 400;
  color: ${theme.colors.foregroundNeutralSecondary};
`;

export const StyledPrimaryDetailsItemValue = styled(HS)<{
  strikeThrough?: boolean;
}>`
  ${({ strikeThrough }) => {
    if (strikeThrough) {
      return css`
        text-decoration: line-through;
      `;
    }
  }};
  font-weight: 300;
`;

export const StyledDetailSectionHeader = styled(PXL)`
  font-weight: 300;
  color: ${theme.colors.foregroundNeutralSecondary};
`;

export const StyledDetailSectionContainer = styled(Flex)`
  flex-direction: column;
  margin-bottom: 16px;
`;

export const StyledDetailLabel = styled(PM)`
  font-weight: 400;
  color: ${theme.colors.foregroundNeutralSecondary};
`;

export const StyledDetailValue = styled(PL)`
  margin-top: 4px;
  font-weight: 400;
`;

export const StyledPillContainer = styled(Box)`
  margin-top: 8px;
`;

export const StyledDetailMetadataContainer = styled(Flex)`
  margin-top: 8px;
  border-left: 1px solid ${theme.colors.borderMain};
  flex-direction: column;
`;

export const StyledLinkBox = styled(Box)`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 246px;
  padding: 4px;

  border: 1px solid ${theme.colors.borderMain};
  border-radius: 35px;

  &:hover {
    background-color: ${theme.colors.backgroundNeutralTertiary};
    border-color: ${theme.colors.primary};
  }

  & > svg {
    vertical-align: middle;
  }
`;

export const StyledLinkText = styled(LS)`
  color: ${theme.colors.foregroundNeutralSecondary};
  margin-left: 4px;
  margin-right: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ContentSlider = styled(Flex)<{
  shouldSlide: boolean;
  isOpen: boolean;
}>`
  flex-direction: column;
  transition: opacity 0.75s;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};

  ${({ shouldSlide }) =>
    shouldSlide &&
    css`
      overflow-x: hidden;
      position: relative;
      animation: run 0.75s;

      @keyframes run {
        0% {
          left: 100%;
          opacity: 0;
        }
        100% {
          left: 0;
          opacity: 1;
        }
      }
    `}
`;
