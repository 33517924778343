import * as React from 'react';

import { CollectProfileInput } from '~/components/CollectProfileInput';
import { LoadingStatus } from '~/components/LoadingStatus';
import {
  CollectPrimaryContactInfoStep,
  CollectPrimaryIdentityInfoStep,
  CollectSecondaryContactInfoStep,
  CollectSecondaryIdentityInfoStep,
  CollectAnnualIncomeStep,
  CollectPrimaryEmploymentStep,
  CollectIntroductionSourceStep,
  CollectLiquidNetWorthStep,
  CollectTotalNetWorthStep,
  CollectPrimaryHolderSsnStep,
  CollectSecondaryHolderSsnStep,
} from '~/components/SharedOnboardingSteps';
import { CollectPrimaryDisclosuresStep } from '~/components/SharedOnboardingSteps/CollectPrimaryDisclosuresStep';
import { ProfileConfirmation } from '~/pages/onboarding/ProfileConfirmation';
import { beginAccountSetupFlowModern } from '~/redux/actions';
import {
  RiskToleranceEnumDropdownOptions,
  IDENTITY_FIRST_FLOW_STEPS as STEPS,
  TimeHorizonEnumDropdownOptions,
} from '~/static-constants';
import { getEnumEntries } from '~/utils';

import { makeFlowComponent } from '../../utils';

export const IdentityFirstFlow = makeFlowComponent({
  name: 'IDENTITY_FIRST',
  begin: beginAccountSetupFlowModern,
  stepElements: {
    [STEPS.LOAD_STATUS]: <LoadingStatus />,
    [STEPS.COLLECT_PRIMARY_DISCLOSURES]: <CollectPrimaryDisclosuresStep />,

    [STEPS.COLLECT_CUSTODIAL_ACCOUNT_INFO]: (
      <CollectSecondaryIdentityInfoStep />
    ),
    [STEPS.COLLECT_PRIMARY_CONTACT_INFO]: <CollectPrimaryContactInfoStep />,
    [STEPS.COLLECT_PRIMARY_IDENTITY_INFO]: <CollectPrimaryIdentityInfoStep />,
    [STEPS.COLLECT_PRIMARY_SSN]: <CollectPrimaryHolderSsnStep />,
    [STEPS.CONFIRM_PROFILE]: <ProfileConfirmation />,

    [STEPS.COLLECT_SECONDARY_CONTACT_INFO]: <CollectSecondaryContactInfoStep />,
    [STEPS.COLLECT_SECONDARY_IDENTITY_INFO]: (
      <CollectSecondaryIdentityInfoStep />
    ),
    [STEPS.COLLECT_SECONDARY_SSN]: <CollectSecondaryHolderSsnStep />,

    [STEPS.COLLECT_ANNUAL_INCOME]: <CollectAnnualIncomeStep />,
    [STEPS.COLLECT_EMPLOYMENT]: <CollectPrimaryEmploymentStep />,

    [STEPS.COLLECT_TOTAL_NET_WORTH]: <CollectTotalNetWorthStep />,
    [STEPS.COLLECT_LIQUID_NET_WORTH]: <CollectLiquidNetWorthStep />,
    [STEPS.COLLECT_INVESTMENT_EXPERIENCE]: (
      <CollectProfileInput
        field="suitability.investmentExperience"
        question="What's your level of investment experience?"
        options={getEnumEntries('InvestmentExperienceEnum')}
      />
    ),
    [STEPS.COLLECT_RISK_TOLERANCE]: (
      <CollectProfileInput
        field="suitability.riskTolerance"
        question="What's your risk tolerance?"
        tooltip={{
          table: {
            header: [
              'Risk Tolerance',
              'Comfort with big swings in investment value',
              'Potential for long-term growth',
            ],
            content: [
              ['Low', '+', '+'],
              ['Medium', '++', '++'],
              ['High', '+++', '+++'],
            ],
          },
        }}
        options={RiskToleranceEnumDropdownOptions}
      />
    ),
    [STEPS.COLLECT_TIME_HORIZON]: (
      <CollectProfileInput
        field="suitability.timeHorizon"
        question="How long are you planning to invest?"
        options={TimeHorizonEnumDropdownOptions}
      />
    ),
    [STEPS.COLLECT_LIQUIDITY_NEEDS]: (
      <CollectProfileInput
        field="suitability.liquidityNeeds"
        question="How important is liquidity to you?"
        tooltip={{
          text: [
            "Here, we're hoping to learn how important is it for you to be able to turn your investments into cash quickly, without losing much value.",
          ],
        }}
        options={getEnumEntries('LiquidityNeedsEnum')}
      />
    ),
    [STEPS.COLLECT_INTRODUCTION_SOURCE]: <CollectIntroductionSourceStep />,
  },
});
