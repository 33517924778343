import { Flex, Card } from '@m1/liquid-react';

import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { BackArrow } from '~/components/BackArrow';
import { ConnectedPie } from '~/components/ConnectedPie';
import { SliceListAllocationFeedbackBanner } from '~/components/PieEditors/PortfolioEditor/components/Banners';
import { EditSlicesButtonGroup } from '~/components/PieEditors/PortfolioEditor/components/Buttons';
import { EditorHeader } from '~/components/PieEditors/PortfolioEditor/components/EditorHeader';
import { PortfolioNameAndDescriptionForm } from '~/components/PieEditors/PortfolioEditor/components/Forms';
import {
  StyledPageContainer,
  StyledPieDetailsContainer,
  StyledSliceablesContainer,
} from '~/components/PieEditors/PortfolioEditor/components/Layout';
import { EditorExitConfirmationModal } from '~/components/PieEditors/PortfolioEditor/components/Modals';
import { MoveSlicesConfirmationModal } from '~/components/PieEditors/PortfolioEditor/components/Modals/MoveSlicesConfirmationModalWrapper/MoveSlicesConfirmationModal';
import { PieControls } from '~/components/PieEditors/PortfolioEditor/components/PieControls';
import {
  SliceableListNoSlicesCard,
  SliceablesList,
} from '~/components/PieEditors/PortfolioEditor/components/SliceablesList';
import { useSourcePieStepQuery } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import { readPieTreeByPath } from '~/pie-trees';
import { moveSlices as moveSlicesAction } from '~/redux/actions';
import { MoveSlicesInput } from '~/redux/actions/newFlows/portfolioEditor/portfolioEditorActions.types';
import { useDispatch, useSelector } from '~/redux/hooks';
import { PORTFOLIO_EDITOR_STEPS } from '~/static-constants';
import { useToast } from '~/toasts';

type SourcePieStepProps = {
  onFinishStep: (arg0: {
    goBack?: boolean;
    step?: ValueOf<typeof PORTFOLIO_EDITOR_STEPS>;
  }) => void;
};

export const SourcePieStep = ({ onFinishStep }: SourcePieStepProps) => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const activeInvestAccountId = useSelector(
    (state) => state.global.activeAccountId,
  );

  const { pie, rootPie, sourcePiePortfolioSliceId } = useSelector((state) => {
    const { sourcePie, sourcePiePortfolioSliceId, path } =
      state.newFlows.PORTFOLIO_EDITOR;

    return {
      pie: readPieTreeByPath(sourcePie, path),
      rootPie: sourcePie,
      sourcePiePortfolioSliceId,
    };
  });

  const [isExitModalOpen, setIsExitModalOpen] = React.useState<boolean>(false);

  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    React.useState<boolean>(false);

  const isLessThan1260 = useMediaQuery({
    query: '(max-width: 1260px)',
  });

  const isLessThan1000 = useMediaQuery({
    query: '(max-width: 1000px)',
  });
  const dispatch = useDispatch();
  const accountId = activeInvestAccountId || '';
  const portfolioSliceIdsToMove: Array<string> = [];

  const { data } = useSourcePieStepQuery({
    variables: {
      activeAccountId: accountId,
      portfolioSliceIdsToMove,
    },
  });

  const moveSlices =
    data?.node?.__typename === 'Account' ? data.node.moveSlices : null;

  if (!accountId) {
    addToast({
      content:
        'Something went wrong. Please try again or contact us for further assistance.',
      duration: 'short',
      kind: 'alert',
    });
  }

  const subheader = moveSlices?.setSourceSliceContent?.copy;
  const subheaderTooltipText = moveSlices?.setSourceSliceContent?.tooltip?.text;

  const title = pie?.name ? `Edit ${pie.name}` : 'Edit your Pie';

  const pieData = pie.slices?.map((slice) => ({
    id: slice.to.__id,
    percentage: slice.percentage,
  }));

  return (
    <StyledPageContainer>
      <EditorHeader
        backButton={
          <BackArrow
            content="Back"
            onClick={() => {
              onFinishStep({
                goBack: true,
              });
            }}
          />
        }
        onCancel={() => {
          setIsExitModalOpen(true);
        }}
        onContinue={() => {
          setConfirmationDialogOpen(true);
        }}
        rootPie={rootPie}
        title={title || 'Edit Your Pie'}
        subtitle={subheader}
        tooltipText={subheaderTooltipText}
      />

      <Flex
        flexDirection={isLessThan1000 ? 'column' : 'row'}
        justifyContent="space-between"
        maxWidth={1200}
        mt={0}
        mx="auto"
        pt={isLessThan1000 ? 0 : 32}
        width="100%"
      >
        <StyledPieDetailsContainer isLessThan1000={isLessThan1000}>
          <PortfolioNameAndDescriptionForm
            name={pie?.name}
            description={pie?.description}
          />

          <Card backgroundColor="backgroundNeutralSecondary" mt={16}>
            {pieData && (
              <Flex justifyContent="center">
                <ConnectedPie
                  data={pieData}
                  height={380}
                  innerRadius={100}
                  width={330}
                />
              </Flex>
            )}
          </Card>
        </StyledPieDetailsContainer>
        <StyledSliceablesContainer isLessThan1000={isLessThan1000}>
          <Flex
            alignItems={
              isLessThan1260 && !isLessThan1000 ? 'flex-start' : 'center'
            }
            flexDirection={isLessThan1260 ? 'column' : 'row'}
            justifyContent="space-between"
            pb={16}
            width="100%"
          >
            <PieControls from="/d/invest/portfolio-editor/set-source" />

            <Flex
              flexDirection="column"
              height="100%"
              justifyContent="flex-end"
              mt={isLessThan1000 ? 32 : 0}
            >
              <EditSlicesButtonGroup
                moveSlices={moveSlices}
                pie={pie}
                user={data?.viewer.user}
              />
            </Flex>
          </Flex>

          {pie?.slices?.length ? (
            <>
              <SliceListAllocationFeedbackBanner mb={8} slices={pie.slices} />
              <SliceablesList pie={pie} />
            </>
          ) : (
            <Flex alignItems="center">
              <SliceableListNoSlicesCard />
            </Flex>
          )}
        </StyledSliceablesContainer>
      </Flex>

      <EditorExitConfirmationModal
        onCancel={() => {
          setIsExitModalOpen(false);
        }}
        onConfirm={() => {
          setIsExitModalOpen(false);

          navigate({
            to: `/d/invest/portfolio/${sourcePiePortfolioSliceId}`,
          });
        }}
        open={isExitModalOpen}
      />

      <MoveSlicesConfirmationModal
        active={confirmationDialogOpen}
        onCancel={() => setConfirmationDialogOpen(false)}
        onConfirm={(moveSlicesInput: MoveSlicesInput) => {
          dispatch(moveSlicesAction(moveSlicesInput));
          setConfirmationDialogOpen(false);
        }}
      />
    </StyledPageContainer>
  );
};
