import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { PortfolioSliceTargetActualFragment } from '~/graphql/types';

export type PortfolioSliceTargetActualProps = {
  portfolioSlice: PortfolioSliceTargetActualFragment;
};

export const PortfolioSliceTargetActual = ({
  portfolioSlice,
}: PortfolioSliceTargetActualProps) => {
  if (portfolioSlice.__typename !== 'ChildPortfolioSlice') {
    return null;
  }

  const actual = portfolioSlice.portionOfParentValuePercent
    ? `${portfolioSlice.portionOfParentValuePercent}%`
    : '--';

  return (
    <Box>
      <Box data-role="actual">{actual}</Box>
      <Box data-role="target">
        <b>{portfolioSlice.percentage}%</b>
      </Box>
    </Box>
  );
};
