import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { InitialFundingFlow } from '~/flows';
import {
  useAccountLastAchRelationshipIdQuery,
  useGetInitialFundingWizardPreloadLazyQuery,
} from '~/graphql/hooks';
import { useLocation } from '~/hooks/useLocation';

import { finishedInitialFundingFlow } from '~/redux/actions';
import { useSelector, useDispatch } from '~/redux/hooks';
import { Grid } from '~/toolbox/grid';
import { Spinner } from '~/toolbox/spinner';

import { InitialFundingProvider } from './InitialFundingProvider';

export const InitialFundingPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const accountId = useSelector((state) => state.global.activeAccountId);

  const {
    data: accountData,
    loading: getAccountLoading,
    error: getAccountError,
  } = useAccountLastAchRelationshipIdQuery({
    variables: { accountId: accountId as string },
    skip: !accountId,
  });

  const account = accountData?.node;

  // Preload wizard data:
  const [preloadInitialFundingScreens, preloadResult] =
    useGetInitialFundingWizardPreloadLazyQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    });

  const {
    data: preloadData,
    loading: preloading,
    error: preloadError,
  } = preloadResult;

  React.useEffect(() => {
    if (account?.__typename === 'Account') {
      preloadInitialFundingScreens({
        variables: {
          accountRegistration: account.registration,
        },
      });
    }
  }, [account]);

  if (getAccountLoading || preloading || !preloadResult) {
    return <Spinner fullScreen />;
  }

  if (getAccountError || preloadError) {
    return <GenericSystemError />;
  }

  if (account?.__typename !== 'Account') {
    return null;
  }

  return (
    <PageErrorBoundary>
      <Box mt={40}>
        <Grid constrain>
          <Grid.Row>
            <Grid.Col xs={12}>
              <InitialFundingProvider data={preloadData}>
                <InitialFundingFlow
                  accountId={accountId as string}
                  achRelationshipId={account?.lastAchRelationship?.id}
                  basePath={location.pathname}
                  onFinish={() => dispatch(finishedInitialFundingFlow())}
                />
              </InitialFundingProvider>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Box>
    </PageErrorBoundary>
  );
};
