import isNil from 'lodash-es/isNil';
import moment from 'moment';
import numbro from 'numbro';

import {
  MailingAddress,
  MailingAddressInput,
  MailingAddressBeneficiaryInput,
  SendCheckRecipientInput,
  Scalars,
} from '~/graphql/types';

import { SUFFIX_ABBREVIATIONS } from '~/static-constants';

import { getOnlyNumbers } from './normalizers';

/**
 * @deprecated Use `CashFormatter` format method instead.
 *
 * Returns a number formatting using numbro as a string.
 *
 * MDN docs:
 * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat Intl.NumberFormat}
 *
 * numbro docs:
 * {@link https://numbrojs.com/format.html New formatting style} - {@link https://numbrojs.com/old-format.html Old formatting style}
 */
export function formatNumber(
  value: number | null,
  format: string | numbro.Format = '$0,0.00',
) {
  return numbro(value).format(format);
}

export const CashFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const CashIntegerFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

export const CompactCashFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  notation: 'compact',
  minimumSignificantDigits: 1,
  maximumSignificantDigits: 4,
});

/**
 * Formats an integer into a string with commas.
 * @param {number} value
 * @returns {string} formatted number with commas
 */
export function formatNumberWithCommas(
  value: number | null | undefined,
): string | null | undefined {
  if (!value) {
    return null;
  }
  return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function toCurrencyOrCrypto(
  value: number,
  isCrypto: boolean,
  format: string = '$0,0.00',
): string {
  const finalFormat = isCrypto && value < 1 ? '$0,0.00[000000]' : format;
  return formatNumber(value, finalFormat);
}

export function formatPhoneNumber(value: string | null | undefined): string {
  if (!value) {
    return '';
  }
  const onlyNums = getOnlyNumbers(value);
  const onlyNumsLength = onlyNums.length;
  const splitValue = value?.split(/[^0-9]/);
  if (
    onlyNumsLength < 10 &&
    onlyNumsLength > 7 &&
    splitValue.length - 1 === 4 &&
    splitValue[1].length <= 3 &&
    splitValue[3].length <= 3
  ) {
    return value;
  }

  if (onlyNumsLength <= 3) {
    return onlyNums;
  } else if (onlyNumsLength <= 7) {
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3);
  }
  return (
    '(' +
    onlyNums.slice(0, 3) +
    ') ' +
    onlyNums.slice(3, 6) +
    '-' +
    onlyNums.slice(6, 10)
  );
}

export function formatDate(value: string, previousValue: string): string {
  if (!value) {
    return value;
  }

  const onlyNums = getOnlyNumbers(value);
  const splitValue = value.split('/');
  if (
    splitValue.length - 1 === 2 &&
    onlyNums.length < 8 &&
    onlyNums.length > 6 &&
    splitValue[0].length <= 2 &&
    splitValue[1].length <= 2
  ) {
    return value;
  }

  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 2) {
      return onlyNums.slice(0, 2) + '/' + onlyNums.slice(2);
    }
    if (onlyNums.length === 4) {
      return (
        onlyNums.slice(0, 2) +
        '/' +
        onlyNums.slice(2, 4) +
        '/' +
        onlyNums.slice(4, 8)
      );
    }
  }

  if (onlyNums.length <= 2) {
    return onlyNums;
  }

  if (onlyNums.length <= 4) {
    return onlyNums.slice(0, 2) + '/' + onlyNums.slice(2);
  }

  return (
    onlyNums.slice(0, 2) +
    '/' +
    onlyNums.slice(2, 4) +
    '/' +
    onlyNums.slice(4, 8)
  );
}

export function formatCurrency(value: string): string {
  // eslint-disable-next-line
  if (!isNaN(Number(value)) && value !== '') {
    return `$${value}`;
  }

  return '';
}

export function formatCurrencyWithCommas(value: string | number): string {
  if (isNil(value)) {
    return '';
  }
  return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatCurrencyWithCommasOrNull(
  value: number | null | undefined,
): string | null | undefined {
  return typeof value === 'number'
    ? `$${formatCurrencyWithCommas(value.toString())}`
    : null;
}

export const formatSuffix = (suffix: string | null | undefined) => {
  if (
    suffix &&
    (suffix === SUFFIX_ABBREVIATIONS.JR || suffix === SUFFIX_ABBREVIATIONS.SR)
  ) {
    return suffix[0] + suffix.substr(1).toLowerCase();
  }
  return suffix;
};

/**
 * Formats time stamp to adhere to logic for rendering trading window times
 * @param {string} timeStamp of the trading window start time
 * @returns {string} formatted string to be rendered
 */
export function formatTradingTimeByTimeStamp(timeStamp: string) {
  // @ts-expect-error - TS2345 - Argument of type 'null' is not assignable to parameter of type 'MomentInput | undefined'.
  return moment(timeStamp).calendar(null, {
    sameDay: () =>
      moment(timeStamp).minutes() === 0 ? 'h A [today]' : 'h:mm A [today]',
    nextDay: () =>
      moment(timeStamp).minutes() === 0
        ? 'h A [tomorrow]'
        : 'h:mm A [tomorrow]',
    nextWeek: () =>
      moment(timeStamp).minutes() === 0 ? 'h A dddd' : 'h:mm A dddd',
  });
}

export const buildAddress = (
  address:
    | MailingAddress
    | MailingAddressInput
    | MailingAddressBeneficiaryInput
    | SendCheckRecipientInput,
): string => {
  const { lineOne, lineTwo, city, stateOrProvince, postalCode } = address;
  const formattedAddress = [];

  if (lineTwo) {
    formattedAddress.push(`${lineOne},`, `${lineTwo},`);
  } else {
    formattedAddress.push(`${lineOne},`);
  }

  formattedAddress.push(`${city},`, stateOrProvince, postalCode);

  return formattedAddress.join(' ');
};

export const maskSsn = (
  ssn: Scalars['SocialSecurityNumber']['input'],
): string => {
  const matches = ssn.match(/^(\d{3})(\d{2})(\d{4})$/);
  if (Array.isArray(matches) && matches[3] && matches[3].length > 0) {
    return '●●● - ●● - ' + matches[3];
  }

  return '-';
};

export const formatLongFormDate = (date: string): string => {
  return moment(date).format('MMM D, YYYY');
};

export function formatDateForAccountMutation(date: string): string {
  return moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');
}

/* Takes in a date of MM/DD/YYYY and returns it in the format of YYYY-MM-DD */
export function formatDateDashes(value: string): string {
  if (!value) {
    return value;
  }

  const match = value.match(/(\d{2})\/(\d{2})\/(\d{4})/);
  if (!match) {
    throw new Error('Invalid date format. Expected MM/DD/YYYY');
  }

  const onlyNums = getOnlyNumbers(value);

  if (onlyNums.length <= 4) {
    return onlyNums;
  }

  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4);
  }

  return (
    onlyNums.slice(4, 8) +
    '-' +
    onlyNums.slice(0, 2) +
    '-' +
    onlyNums.slice(2, 4)
  );
}

export function transformDateToSlashes(
  value: string,
  previousValue: string,
): string {
  /* Sometimes BE will send date in dash format so need to convert */
  if (value.includes('-')) {
    const splitValue = value.split('-');
    if (
      splitValue.length === 3 &&
      splitValue[0].length === 4 &&
      splitValue[1].length === 2 &&
      splitValue[2].length === 2
    ) {
      return `${splitValue[1]}/${splitValue[2]}/${splitValue[0]}`;
    }
  }

  // Use existing date formatting function if the date is already in slashes
  return formatDate(value, previousValue);
}
