import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalTitle,
  PM,
} from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { TutorialModalFragment } from '~/graphql/types';
import { AppImage } from '~/lens-toolbox/AppImage';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { updateUserData } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

type TutorialModalProps = {
  moveSlices: TutorialModalFragment | null | undefined;
  open: boolean;
  setShowMoveSlicesTutorial: (val: boolean) => void;
};

export const TutorialModal = ({
  open,
  moveSlices,
  setShowMoveSlicesTutorial,
}: TutorialModalProps) => {
  const dispatch = useDispatch();

  const handleCloseTutorial = React.useCallback(() => {
    setShowMoveSlicesTutorial(false);

    dispatch(
      updateUserData({
        key: 'MOVE_VALUE_LATEST_CLICK',
        value: new Date().toISOString(),
      }),
    );
  }, [dispatch, setShowMoveSlicesTutorial]);

  const tutorial = moveSlices?.moveSlicesTutorialContent;

  if (!tutorial) {
    return <GenericSystemError />;
  }

  return (
    <Modal open={open} onClose={handleCloseTutorial}>
      <ModalContent>
        <ModalTitle>{tutorial.header}</ModalTitle>
        <Flex flexDirection="column" mt={16}>
          {tutorial.items?.map((item, index) => (
            <Box key={index} mb={16}>
              {item?.icon && <AppImage appImage={item.icon} />}
              <PM>{item?.copy}</PM>
            </Box>
          ))}
        </Flex>

        <Box mb={32}>
          {tutorial.learnMore && <LinkableLink linkable={tutorial.learnMore} />}
        </Box>
        <Button
          label={tutorial.exitLabel || 'Continue'}
          kind="primary"
          size="large"
          onClick={handleCloseTutorial}
        />
      </ModalContent>
    </Modal>
  );
};
