import { Box, Flex } from '@m1/liquid-react';
import * as React from 'react';

import { useCreditCardApplicationSummaryQuery } from '~/graphql/hooks';
import { Spinner } from '~/toolbox/spinner';

import { FinalSummaryComponent } from '../components/FinalSummaryComponent';

type FinalSummaryProps = {
  onFinishStep: () => void;
};

export const FinalSummary = ({ onFinishStep }: FinalSummaryProps) => {
  const { data, loading } = useCreditCardApplicationSummaryQuery();
  if (loading) {
    return <Spinner />;
  }

  if (!data?.viewer.credit) {
    throw new Error('Error loading the Final Summary component.');
  }

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      alignContent="center"
      pb={45}
    >
      <Box width={700}>
        <FinalSummaryComponent
          creditInformation={data.viewer.credit}
          onFinishStep={onFinishStep}
        />
      </Box>
    </Flex>
  );
};
