import { Flex, LL, PXL } from '@m1/liquid-react';
import * as React from 'react';

import {
  NextAvailableTradeWindowSectionFragment,
  TradingWindowTypeEnum,
} from '~/graphql/types';

import { formatTradingTimeByTimeStamp } from '~/utils';

type NextTradeWindowSectionProps = {
  nextTradeWindow: NextAvailableTradeWindowSectionFragment;
};

const WINDOW_DESCRIPTION_MAPPING: Record<TradingWindowTypeEnum, string | null> =
  {
    MORNING: 'Morning',
    AFTERNOON: 'Afternoon',
    ADMIN: null,
    LEGACY: null,
    ON_DEMAND: null,
  };

export const NextTradeWindowSection = ({
  nextTradeWindow,
}: NextTradeWindowSectionProps) => {
  const { label, trading } = nextTradeWindow;

  if (!trading) {
    return null;
  }

  const { nextTradingTime, nextWindowForAccount } = trading;

  return (
    <Flex flexDirection="column" mb={32}>
      {label && (
        <PXL fontWeight={600} color="foregroundNeutralSecondary" mb={8}>
          {label}
        </PXL>
      )}
      {nextWindowForAccount && nextTradingTime && (
        <Flex
          alignItems="center"
          borderColor="borderMain"
          borderRadius={8}
          borderStyle="solid"
          borderWidth={1}
          justifyContent="space-between"
          p={12}
          width="100%"
        >
          <Flex flexDirection="column">
            <LL>{WINDOW_DESCRIPTION_MAPPING[nextWindowForAccount]}</LL>
            <PXL fontWeight={600}>
              {formatTradingTimeByTimeStamp(nextTradingTime)}
            </PXL>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
