import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';

export const AcatWizardErrorState = ({
  children,
}: {
  children?: React.ReactElement<typeof BackArrow>;
}) => (
  <Box mx="auto">
    {children}
    <GenericSystemError />
  </Box>
);
