import { HXXS, PL } from '@m1/liquid-react';
import React from 'react';

import { Spinner } from '~/toolbox/spinner';

import { formatNumber } from '~/utils';

import { StyledTitle } from './ScreenerTable.styled';

type ScreenerTableProps = {
  loading: boolean;
  total: number | null | undefined;
};

export const ScreenerTableTitle = ({ loading, total }: ScreenerTableProps) => {
  return (
    <StyledTitle>
      <HXXS fontWeight={300} content="Results" />
      <span
        style={{
          paddingLeft: 8,
        }}
      >
        {loading ? (
          <Spinner radius={24} thickness={3} />
        ) : (
          <PL
            color="foregroundNeutralMain"
            content={`${total ? formatNumber(total, '0,0') : 0} total`}
          />
        )}
      </span>
    </StyledTitle>
  );
};
