import { Flex } from '@m1/liquid-react';
import * as React from 'react';

export type DialogPagingButtonProps = {
  children?: React.ReactElement<any>;
  margin?: string | number;
  onClick: () => void;
};

export const DialogPagingButton = ({
  children,
  margin,
  onClick,
}: DialogPagingButtonProps) => (
  <Flex
    alignItems="center"
    borderRadius={26}
    backgroundColor="foregroundNeutralInverse"
    justifyContent="center"
    height={42}
    margin={margin}
    onClick={onClick}
    style={{
      cursor: 'pointer',
    }}
    width={42}
  >
    {children}
  </Flex>
);
