import { semanticColors } from '@m1/liquid-react';

import { ToastKind, ToastKindConfig, ToastProps } from './Toast.types';

export const collectKindStyles = (
  kind: ToastProps['kind'],
): ToastKindConfig => {
  const toastTheme: Record<ToastKind, ToastKindConfig> = {
    alert: {
      backgroundColor: 'backgroundCriticalSubtle',
      border: `1px solid ${semanticColors.borderCritical}`,
      color: 'foregroundNeutralMain',
      linkColor: 'foregroundCritical',
    },
    warning: {
      border: `1px solid ${semanticColors.borderWarning}`,
      backgroundColor: 'backgroundWarningSubtle',
      color: 'foregroundNeutralMain',
      linkColor: 'foregroundWarning',
    },
    informational: {
      backgroundColor: 'backgroundInfoSubtle',
      border: `1px solid ${semanticColors.borderInfo}`,
      color: 'foregroundNeutralMain',
      linkColor: 'foregroundNeutralMain',
    },
    success: {
      backgroundColor: 'backgroundSuccessSubtle',
      border: `1px solid ${semanticColors.borderSuccess}`,
      color: 'foregroundNeutralMain',
      linkColor: 'foregroundSuccess',
    },
  };

  return toastTheme[kind] ?? toastTheme.informational;
};
