import {
  IraContributionYearEnum,
  RecurrenceScheduleInput,
} from '~/graphql/types';

export enum TransferFrequencyEnum {
  OneTime = 'one-time',
  Weekly = 'weekly',
  EveryTwoWeeks = 'every-two-weeks',
  Monthly = 'monthly',
}

export type TransferDetailsFormValues = {
  amount: string | undefined;
  sourceId: string | undefined;
  destinationId: string | undefined;
  frequency: TransferFrequencyEnum;
  schedule?: RecurrenceScheduleInput;
  federalWithholding?: number | null;
  stateWithholding?: number | null;
  distributionReason?: string | null;
  contributionYear?: IraContributionYearEnum | null;
  isRollOver?: boolean;
  niaAmount?: number | null;
};
