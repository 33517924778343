import * as React from 'react';
import { Route, Navigate } from 'react-router-dom';

import { ModelPortfolioPage } from '~/pages/dashboard/research/model-portfolios/ModelPortfolioPage';

import { AddModelPortfolioCategoryDetailsRoute } from './category-details';
import { AddModelPortfolioDetailsRoute } from './pie-details';

export const AddModelPortfoliosRoute = () => {
  return (
    <>
      <Route
        path="/d/c/add-slices/expert-pies"
        element={<Navigate to="/d/c/add-slices/model-portfolios" replace />}
      />
      <Route
        path="/d/c/add-slices/expert-pies/:categoryKey"
        element={
          <Navigate
            to="/d/c/add-slices/model-portfolios/:categoryKey"
            replace
          />
        }
      />
      <Route
        path="/d/c/add-slices/expert-pies/details/:modelPortfolioId"
        element={
          <Navigate
            to="/d/c/add-slices/model-portfolios/details/:modelPortfolioId"
            replace
          />
        }
      />
      <Route path="model-portfolios">
        {AddModelPortfolioCategoryDetailsRoute()}
        {AddModelPortfolioDetailsRoute()}
        <Route index element={<ModelPortfolioPage />} />
      </Route>
    </>
  );
};
