import { Box, Button } from '@m1/liquid-react';
import * as React from 'react';

export const GetReferralLinkButton = ({
  onClick,
}: {
  onClick?: () => void;
}) => (
  <Box mt={32}>
    <Button
      kind="primary"
      size="large"
      label="Get My Referral Link"
      onClick={onClick}
    />
  </Box>
);
