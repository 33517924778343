import * as React from 'react';

import { CollectIntroductionSourceStep as CollectIntroductionSource } from '~/components/SharedOnboardingSteps';
import { finishedKnowYourCustomer } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

export const CollectIntroductionSourceStep = () => {
  const dispatch = useDispatch();

  return (
    <CollectIntroductionSource
      onFinish={() => dispatch(finishedKnowYourCustomer())}
    />
  );
};
