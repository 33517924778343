import { Button, HS, PXL, PS, Flex, Card } from '@m1/liquid-react';
import * as React from 'react';
import { InjectedFormProps, formValueSelector } from 'redux-form';

import { GenericSystemError } from '~/components/GenericSystemError';
import { TextField } from '~/forms/fields';
import { required } from '~/forms/validators';
import { useCalculateNiaQuery } from '~/graphql/hooks';
import { connectForm } from '~/hocs';
import { useCurrencyValidation } from '~/hooks/useCurrencyValidation';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { setIraNiaAmount } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { CREATE_TRANSFER_FLOW_STEPS as STEPS } from '~/static-constants';
import { Spinner } from '~/toolbox/spinner';
import { formatCurrencyWithCommas } from '~/utils/formatting';
import { normalizeCurrency } from '~/utils/normalizers';

import { calculateNiaAmount } from '../../utils/calculateNiaAmount';

type CalculateNiaProps = InjectedFormProps<any> & {
  onFinishStep: (step?: ValueOf<typeof STEPS>) => void;
};

const getNiaDisplayValue = (
  amount: string | null | undefined,
  niaAmount: number | null | undefined,
): string | null | undefined => {
  if (!amount || typeof niaAmount !== 'number') {
    return null;
  } else if (niaAmount < 0) {
    return amount.replace('-', '-$');
  }
  return `$${amount}`;
};

const getNiaAmount = (
  excessAmount: string | null | undefined,
  acbAmount: string | null | undefined,
  aobAmount: string | null | undefined,
): number | null | undefined => {
  if (excessAmount && acbAmount && aobAmount) {
    const amount = calculateNiaAmount(
      Number(excessAmount),
      Number(acbAmount),
      Number(aobAmount),
    );
    return amount;
  }
  return null;
};

const CalculateNia = ({
  handleSubmit,
  onFinishStep,
  reset,
  valid,
}: CalculateNiaProps) => {
  const dispatch = useDispatch();
  const { data, loading } = useCalculateNiaQuery();
  const form = formValueSelector('calculateNiaAmount');
  const excessAmount = useSelector<string | null | undefined>((state) =>
    form(state, 'excessAmount'),
  );
  const acbAmount = useSelector<string | null | undefined>((state) =>
    form(state, 'acbAmount'),
  );
  const aobAmount = useSelector<string | null | undefined>((state) =>
    form(state, 'aobAmount'),
  );

  const niaAmount = getNiaAmount(excessAmount, acbAmount, aobAmount);
  const formattedNiaAmount = useCurrencyValidation(niaAmount?.toFixed(2));
  const niaDisplayValue = getNiaDisplayValue(formattedNiaAmount, niaAmount);
  const submit = () => {
    dispatch(setIraNiaAmount(niaAmount));
    onFinishStep();
  };

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.viewer) {
    return <GenericSystemError />;
  }

  return (
    <>
      <HS>Calculate Net Income Attributable</HS>
      <Card mt={32} p={32}>
        <form onSubmit={handleSubmit(submit)}>
          <TextField
            name="excessAmount"
            label="Excess to be removed"
            maskType="money"
            validate={[required]}
            normalize={normalizeCurrency}
            format={formatCurrencyWithCommas}
            maxLength={10}
          />
          <TextField
            name="acbAmount"
            label="Adjusted closing balance"
            maskType="money"
            validate={[required]}
            normalize={normalizeCurrency}
            format={formatCurrencyWithCommas}
            maxLength={10}
          />
          <TextField
            name="aobAmount"
            label="Adjusted opening balance"
            maskType="money"
            validate={[required]}
            normalize={normalizeCurrency}
            format={formatCurrencyWithCommas}
            maxLength={10}
          />
          <Flex mt={8} alignItems="center" justifyContent="space-between">
            <PXL
              content={`Net income attributable = ${niaDisplayValue || '$0'}`}
              color={
                typeof niaAmount === 'number'
                  ? 'foregroundNeutralMain'
                  : 'foregroundNeutralTertiary'
              }
            />
            <Button kind="link" label="Clear" onClick={reset} />
          </Flex>
          <PS
            mt={35}
            content="This tool is provided to assist you with calculating NIA and may not be accurate."
          />
          <PS
            mt={11}
            content="M1 does not provide tax advice, you should consult your own tax advisor regarding your personal circumstances before taking any action that may have tax consequences."
          />
          {data.viewer.transfers?.niaLearnMore && (
            <LinkableLink
              mt={16}
              linkable={data.viewer.transfers.niaLearnMore}
            />
          )}
          <Flex alignItems="start" flexDirection="row" mt={64}>
            <Button
              kind="secondary"
              label="Back"
              mt={21}
              mr={15}
              onClick={() => onFinishStep(STEPS.NIA_REPORT)}
              size="large"
            />
            <Button
              disabled={!valid}
              kind="primary"
              label="Apply"
              type="submit"
              mt={21}
              size="large"
            />
          </Flex>
        </form>
      </Card>
    </>
  );
};

export const ConnectedCalculateNia = connectForm({
  form: 'calculateNiaAmount',
})(CalculateNia);
