import { Box, ResolvedThemeMode, styled, useThemeMode } from '@m1/liquid-react';
import * as d3 from 'd3';
import * as React from 'react';
// @ts-expect-error - TS2305 - Module '"react-spring/renderprops"' has no exported member 'Spring'.
import { Spring } from 'react-spring/renderprops';

import { PortfolioSlicePieFragment } from '~/graphql/types';
import { clickedOnPortfolioSlice, highlightSlice } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

import { prependOpacityHexCode } from '~/utils';

import { PieMiddleContent } from './PieMiddleContent';

export type PortfolioSlicePieProps = {
  colors: Map<
    string,
    {
      activeColor: string;
      inactiveColor: string;
    }
  >;
  height?: number;
  highlightedSlice?: string | null | undefined;
  portfolioSlice: PortfolioSlicePieFragment;
  sort: (a: any, b: any) => number;
  // TODO: Update when real types are available
  width?: number;
};

const StyledSvgContainer = styled.svg<{
  themeMode: ResolvedThemeMode;
}>`
  ${(props) => {
    if (props.themeMode !== 'dark') {
      return `filter: drop-shadow(2px 2px 8px ${prependOpacityHexCode(
        '2C',
        /* 15% opacity */ props.theme.colors.foregroundNeutralMain,
      )});`;
    }
  }}
`;

const StyledGhostSlicePath = styled.path`
  cursor: pointer;
  fill: none;
  pointer-events: fill;
`;

const StyledSlicesGroup = styled.g`
  pointer-events: none;
`;

const StyledSlicePath = styled.path<{
  $fillColor?: string;
}>`
  fill: ${(props) => props.$fillColor};
  transition: opacity 0.15s ease-in-out;
`;

const StyledCircle = styled.circle`
  fill: ${(props) => props.theme.colors.backgroundNeutralSecondary};
  pointer-events: none;
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.18));
`;

type Radii = [number, number, number];
const deriveRadiiFromWidth = (width: number): Radii => {
  const r = width / 2;
  const inner = r * 0.55;
  return [inner, inner + (r - inner) / 2, inner + r * 0.075];
};

export const PortfolioSlicePie = ({
  colors,
  height = 320,
  highlightedSlice,
  portfolioSlice,
  sort,
  width = 320,
}: PortfolioSlicePieProps) => {
  const dispatch = useDispatch();
  const { activeThemeMode } = useThemeMode();

  const slices = React.useMemo(() => {
    let children;
    if (
      'ancestors' in portfolioSlice &&
      portfolioSlice.ancestors &&
      portfolioSlice.to.__typename &&
      ['Equity', 'Fund', 'CryptoAsset'].includes(portfolioSlice.to.__typename)
    ) {
      const ancestor =
        portfolioSlice.ancestors[portfolioSlice.ancestors.length - 1];
      if (!ancestor) {
        throw new Error(
          'Unable to render PortfolioSlicePie on a ChildPortfolioSlice without an ancestor.',
        );
      }
      children = ancestor.children;
    } else {
      children = portfolioSlice.children;
    }
    const filteredChildren = children.filter(Boolean);
    if (filteredChildren.length !== children.length) {
      throw new Error(
        'Unable to render PortfolioSlicePie if a child is missing.',
      );
    }
    return filteredChildren;
  }, [portfolioSlice]);

  const { arc, data, ghostArc, onTargetRadius } = React.useMemo<{
    arc: (...args: Array<any>) => any;
    data: any;
    ghostArc: (...args: Array<any>) => any;
    onTargetRadius: number;
  }>(() => {
    let max = 200;
    let isAnySliceFunded = false;
    for (const slice of slices) {
      if (
        typeof slice?.targetValuePercent === 'number' &&
        slice.targetValuePercent > max
      ) {
        max = slice.targetValuePercent;
      }

      if (!isAnySliceFunded && slice?.value?.total) {
        isAnySliceFunded = true;
      }
    }

    const containsMultipleSlices = slices.length > 1;
    const [innerRadius, onTargetRadius, sliceMinRadius] =
      deriveRadiiFromWidth(width);

    const domain = [0, 100, Math.max(max, 200)];
    const range = containsMultipleSlices
      ? [sliceMinRadius, onTargetRadius, width / 2]
      : [onTargetRadius, onTargetRadius, onTargetRadius];

    const scale = d3.scaleLinear().domain(domain).range(range).clamp(true);

    // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
    const calculateOuterRadius = (d) => {
      if (isAnySliceFunded && d.data.value && d.data.value.total === 0) {
        return scale(0);
      }

      const value =
        d.data.targetValuePercent !== 0 &&
        d.data.targetValuePercent !== null &&
        containsMultipleSlices
          ? d.data.targetValuePercent
          : 100;

      return scale(value);
    };

    const arc = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(calculateOuterRadius);

    const ghostArc = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius((d) => {
        return Math.max(calculateOuterRadius(d), onTargetRadius);
      });

    const pie = d3
      .pie()
      // @ts-expect-error - TS2339 - Property 'percentage' does not exist on type 'number | { valueOf(): number; }'.
      .value((slice) => slice.percentage)
      .sort(sort);

    const data = pie(slices as any);

    return {
      arc,
      data,
      ghostArc,
      onTargetRadius,
    };
  }, [slices, sort, width]);

  const handleMouseEnterSlice = React.useCallback(
    (slice: { id: string }) => dispatch(highlightSlice(slice.id)),
    [dispatch],
  );

  const handleMouseLeaveSlice = React.useCallback(
    // @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
    () => dispatch(highlightSlice()),
    [dispatch],
  );

  const storeHighlightedSliceId = useSelector<string | null | undefined>(
    (state) => state.interface.highlightedSliceId,
  );

  const highlightedSliceId = storeHighlightedSliceId ?? highlightedSlice;
  const middleContentSlice =
    // @ts-expect-error - TS2531 - Object is possibly 'null'.
    slices.find((s) => s.id === highlightedSliceId) || portfolioSlice;
  const sliceGroupOffset = `translate(${width / 2}, ${height / 2})`;

  return (
    <Box
      height={height}
      margin="0 auto"
      position="relative"
      textAlign="center"
      width={width}
    >
      <StyledSvgContainer
        themeMode={activeThemeMode}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      >
        <StyledCircle r={onTargetRadius} cx={width / 2} cy={height / 2} />

        {/* Ghost slices - These are used purely as click targets */}
        <g transform={sliceGroupOffset}>
          {slices.map(
            (slice, index) =>
              slice && (
                <StyledGhostSlicePath
                  key={slice.id}
                  d={ghostArc(data[index])}
                  onClick={() => dispatch(clickedOnPortfolioSlice(slice.id))}
                  onTouchStart={() => handleMouseEnterSlice(slice)}
                  onTouchEnd={() => handleMouseLeaveSlice()}
                  onMouseEnter={() => handleMouseEnterSlice(slice)}
                  onMouseLeave={() => handleMouseLeaveSlice()}
                />
              ),
          )}
        </g>

        <StyledSlicesGroup transform={sliceGroupOffset}>
          {slices.map((slice, index) => {
            if (!slice) {
              return null;
            }
            const angles = data[index];

            const fillColor = colors.get(slice.id);
            const colorVariant =
              highlightedSliceId && slice.id !== highlightedSliceId
                ? 'inactiveColor'
                : 'activeColor';

            return (
              <Spring
                key={slice.id}
                to={{
                  startAngle: angles.startAngle,
                  endAngle: angles.endAngle,
                }}
              >
                {/* @ts-expect-error - TS7031 - Binding element 'startAngle' implicitly has an 'any' type. | TS7031 - Binding element 'endAngle' implicitly has an 'any' type. */}
                {({ startAngle, endAngle }) => (
                  <StyledSlicePath
                    $fillColor={
                      fillColor ? fillColor[colorVariant] : 'transparent'
                    }
                    d={arc({
                      ...angles,
                      startAngle,
                      endAngle,
                    })}
                    // @ts-expect-error - TS2769 - No overload matches this call.
                    index={index}
                  />
                )}
              </Spring>
            );
          })}
        </StyledSlicesGroup>
      </StyledSvgContainer>
      <PieMiddleContent
        metricsToDisplay={
          !highlightedSliceId || highlightedSliceId === portfolioSlice.id
            ? 'PERFORMANCE'
            : 'PERCENTAGE'
        }
        slice={middleContentSlice}
      />
    </Box>
  );
};
