import { Box, Button, Flex } from '@m1/liquid-react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { AppContext } from '~/AppContext';
import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import {
  useDocumentRequirementsQuery,
  useGenerateGenericPresignedDocumentUrlMutation,
} from '~/graphql/hooks';
import {
  DocumentAssociatedService,
  DocumentBusinessPurpose,
} from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { useToast } from '~/toasts';
import { Spinner } from '~/toolbox/spinner';

import { DocumentLoadingPage } from '../components/DocumentLoadingPage';
import { DocumentGenericForm } from '../types';

import { DocumentUploadGenericForm } from './DocumentUploadGenericForm';

export const DocumentUploadGenericPage = ({
  associatedService,
  businessPurpose,
}: {
  associatedService: DocumentAssociatedService;
  businessPurpose: DocumentBusinessPurpose;
}) => {
  const { analytics } = React.useContext(AppContext);
  const { addToast } = useToast();

  const [showLoadingScreen, setShowLoadingScreen] = React.useState(false);
  const { data, loading } = useDocumentRequirementsQuery();

  const navigate = useNavigate();
  const goHome = () => navigate({ to: '/d/home' });

  const handleUploadError = () => {
    analytics.recordEvent('m1_document_upload_unsuccessful');
    addToast({
      content: 'The upload has failed. Please try again.',
      kind: 'alert',
      duration: 'short',
    });
  };

  const [requestDocumentUrl] = useGenerateGenericPresignedDocumentUrlMutation({
    onError: handleUploadError,
  });

  const uploadForm = useForm<DocumentGenericForm>();

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (showLoadingScreen) {
    return <DocumentLoadingPage />;
  }

  const documentRequirements =
    data?.viewer.documentUploadRequestsCenter?.documentRequirements;

  if (!documentRequirements) {
    return <GenericSystemError />;
  }

  const onUpload = async (input: DocumentGenericForm) => {
    const { selectedFile } = input;
    if (selectedFile) {
      setShowLoadingScreen(true);
      await requestDocumentUrl({
        variables: {
          input: {
            associatedService,
            businessPurpose,
            fileName: selectedFile.name,
          },
        },
        onCompleted: async (data) => {
          const presignedUrl =
            data.generateGenericPresignedDocumentUrl?.outcome?.presignedUrl;
          if (presignedUrl) {
            const response = await fetch(presignedUrl, {
              headers: {
                'Content-Type': selectedFile.type,
                'X-Amz-Server-Side-Encryption': 'AES256',
                'Content-Disposition': 'inline',
              },
              method: 'PUT',
              body: selectedFile,
            });
            if (!response.ok) {
              handleUploadError();
            } else {
              analytics.recordAppAnalyticsEvent({
                name: 'm1_document_upload_success',
                valueParameter: null,
                customParameters: [
                  {
                    name: 'associated_service',
                    value: 'Other',
                  },
                  {
                    name: 'business_purpose',
                    value: 'Other',
                  },
                ],
                customBoolParameters: [],
                customNumberParameters: [],
              });
              addToast({
                content: 'File uploaded successfully.',
                kind: 'success',
                duration: 'long',
              });
              goHome();
            }
          }
        },
      });
    }
  };

  return (
    <FormProvider {...uploadForm}>
      <Flex
        alignContent="center"
        backgroundColor="backgroundNeutralSecondary"
        flexDirection="column"
        maxWidth={550}
        mx="auto"
        my={32}
      >
        <BackArrow
          content="Back"
          onClick={() => {
            analytics.recordEvent('m1_document_upload_exit');
            goHome();
          }}
        />
        <Flex justifyContent="center" maxWidth={550}>
          <DocumentUploadGenericForm
            documentRequirements={documentRequirements}
          />
        </Flex>
        <Box textAlign="center" mt={64}>
          <Button
            label="Upload file"
            size="large"
            disabled={!uploadForm.formState.isValid}
            onClick={uploadForm.handleSubmit(onUpload)}
            type="submit"
          />
        </Box>
      </Flex>
    </FormProvider>
  );
};
