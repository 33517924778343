import React from 'react';

import { useGetExcludedBuysQuery } from '~/graphql/hooks';

import { AccountEstimatedTradesTable } from './AccountEstimatedTradesTable';
import { LoadMoreButton } from './LoadMoreButton';
import { NoTradesContent } from './NoTradesContent';

type ExcludedBuysTableProps = {
  accountId: string;
  style?: any;
};

export const ExcludedBuysTable = ({
  accountId,
  style,
}: ExcludedBuysTableProps) => {
  const { data, loading, variables, refetch } = useGetExcludedBuysQuery({
    variables: {
      accountId,
      first: 100,
    },
  });

  const account = data?.account?.__typename === 'Account' ? data.account : null;

  const excludedBuys = account?.estimatedTrading?.excludedBuys;

  const pageInfo = excludedBuys?.pageInfo;

  const handleFetchMore = React.useCallback(() => {
    const oldFirst = variables?.first ?? 0;
    refetch({
      first: oldFirst + 100,
    });
  }, [refetch, variables]);

  if (!excludedBuys?.edges && !loading) {
    return (
      <NoTradesContent
        picture="cryptoNoPendingBuys"
        content="No excluded buys"
      />
    );
  }

  return (
    <AccountEstimatedTradesTable
      totalTrades={excludedBuys?.total ?? 0}
      edges={excludedBuys?.edges}
      style={style}
      loadMoreTradesButton={
        <LoadMoreButton
          handleLoadMore={handleFetchMore}
          loading={loading}
          pageInfo={pageInfo}
        />
      }
    />
  );
};
