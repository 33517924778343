import { Banner, Flex } from '@m1/liquid-react';
import * as React from 'react';

import { Link } from '~/toolbox/link';

const BannerAction = () => {
  return (
    <Flex ml={32}>
      <Link
        to="/d/invest/portfolio-organizer"
        state={{
          event: {
            initConfig: {
              confirmationDialog: {
                showApplicableLocations: false,
              },
              mode: 'NEW_ROOT_PIE',
              returnTo: '/d/invest',
              sliceableIds: [],
            },
            type: 'INITIALIZE_ORGANIZER',
          },
        }}
      >
        Build Portfolio
      </Link>
    </Flex>
  );
};

export const BuildPortfolioBanner = () => {
  return (
    <Banner
      action={<BannerAction />}
      content="Your holdings are currently not in a Pie. Create a Pie to start building your portfolio." // TODO update once we do Icons
      kind="alert"
      maxWidth={1200}
      mt={12}
      size="wide"
    />
  );
};
