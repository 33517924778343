import { Box, PS, PXL } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import { PercentGain } from '~/components/percent-gain';
import { TruncateStringContent } from '~/components/TruncateStringContent';

import { useSelector } from '~/redux/hooks';

import { usePieDetailsContext } from './hooks/usePieDetailsContext';
import { StyledPieHighlightContainer } from './PieDetails.styled';

const shortDate = 'M/D';
const longDate = 'MM/DD/YY';

export const PieHighlights = () => {
  const highlightedSlice = useSelector(
    (state) => state.interface.highlightedSliceId,
  );

  const { pie, chartInfo } = usePieDetailsContext();

  const pieHighlightData = React.useMemo(() => {
    const percent =
      chartInfo?.percentChange ??
      pie?.analysis?.backtesting?.valuePercentChange;
    const slice = pie?.slices.find(
      (slice) => slice.to.id && slice.to.id === highlightedSlice,
    );

    const isSameYear =
      chartInfo?.startDate?.year() === chartInfo?.endDate?.year() &&
      chartInfo?.startDate?.year() === moment().year();

    if (slice) {
      return {
        name: slice.to.name,
        percentage: `${slice.percentage}%`,
        startDate: chartInfo?.startDate?.format(
          isSameYear ? shortDate : longDate,
        ),
        endDate: chartInfo?.endDate?.format(isSameYear ? shortDate : longDate),
      };
    }
    return {
      name: pie?.name,
      percentage: percent ? <PercentGain value={percent} /> : '--',
      startDate: chartInfo?.startDate?.format(
        isSameYear ? shortDate : longDate,
      ),
      endDate: chartInfo?.endDate?.format(isSameYear ? shortDate : longDate),
    };
  }, [highlightedSlice, pie, chartInfo]);

  return (
    <StyledPieHighlightContainer>
      <TruncateStringContent>{pieHighlightData.name}</TruncateStringContent>
      <Box pt={4}>
        <PXL as="div" fontWeight={600} content={pieHighlightData.percentage} />
        <PS>
          {pieHighlightData.startDate} - {pieHighlightData.endDate}
        </PS>
      </Box>
    </StyledPieHighlightContainer>
  );
};
