import { Button, ButtonProps } from '@m1/liquid-react';
import React from 'react';

import { Link } from '~/toolbox/link/Link';
import { LinkProps } from '~/toolbox/link/Link.types';

export type NavigableButtonProps = ButtonProps & LinkProps;

export const NavigableButton = (props: NavigableButtonProps) => {
  return (
    <Link {...props} style={{ display: 'block' }}>
      <Button {...props} />
    </Link>
  );
};
