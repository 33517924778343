import find from 'lodash-es/find';

import { enumsByName } from '../graphql/enumsByName';

export type EnumName = keyof typeof enumsByName;

/**
 * Entry associated with an enumeration containing a name and corresponding
 * description.
 */
export interface EnumEntry {
  name: string;
  description: string;
}

/**
 * Returns an array of objects containing the name and the description associated
 * with the specified enum name.
 *
 * @example
 *   console.log(getEnumEntries('AchTransferRejectionReasonEnum');
 *   >> [{ 'name': 'UNKNOWN', 'description': 'The rejection reason is unknown.' }]
 */
export function getEnumEntries(enumName: EnumName): EnumEntry[] {
  const matchingEntries = enumsByName[enumName] ?? null;

  if (matchingEntries === null) {
    throw new Error(`Could not find enum ${enumName}`);
  }

  return matchingEntries as EnumEntry[];
}

/**
 * Finds the description of given searchValue from the specified enum group
 * name (if found) and returns the result.
 * @param searchValue Value of the "name" field to search for within the enum group
 * @param enumName Name of the enum group to search
 * @param defaultValue Value to display if searchValue isn't in enum entries
 */
export function getEnumDescription(
  searchValue: string | null | undefined,
  enumName: EnumName,
  defaultValue: string = '',
): string {
  const enumEntries = getEnumEntries(enumName);

  const found = find(enumEntries, (item) => item.name === searchValue);

  return found?.description ?? defaultValue;
}

/**
 * Maps a string to an enum value. If the string does not match any enum value,
 * the default value is returned.
 * @param input String to map to an enum value
 * @param enumType Enum type to map the string to
 * @param defaultValue Default value to return if the string does not match any enum value
 */
export function mapStringToEnumValue<T extends { [key: string]: string }>(
  input: string | null | undefined,
  enumType: T,
  defaultValue: T[keyof T],
): T[keyof T] {
  let foundValue = null;
  if (input) {
    // Get all enum values as an array
    const enumValues = Object.values(enumType);

    // Check if any enum value matches the input (case-insensitive, and spaces are removed)
    foundValue = enumValues.find(
      (enumValue) =>
        enumValue.replace(/[-_\s]/g, '').toLowerCase() ===
        input.replace(/[-_\s]/g, '').toLowerCase(),
    );
  }

  return (foundValue as T[keyof T]) ?? defaultValue;
}
