import { styled } from '@m1/liquid-react';
import * as React from 'react';

export type PillGroupProps = {
  children?: React.ReactNode;
  justifyContent?: 'center' | 'left' | 'right';
};

const Root = styled.div<PillGroupProps>`
  display: flex;
  margin-bottom: -8px;
  justify-content: ${({ justifyContent = 'default' }) => justifyContent};

  > * {
    whitespace: nowrap;
    margin-right: 8px;
    margin-bottom: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const PillGroup = ({ children, ...props }: PillGroupProps) => {
  return <Root {...props}>{children}</Root>;
};
