import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useCountryOfCitizenshipFieldQuery } from '~/graphql/hooks';
import { OnlineAccountRegistrationEnum } from '~/graphql/types';

import { ControlledDropdown } from '../ControlledDropdown';

type CountryOfCitizenshipDropdownProps = {
  accountRegistration: OnlineAccountRegistrationEnum;
  disabled?: boolean;
  control: any;
  warning?: any;
  backgroundColor?: any;
};

export const CountryOfCitizenshipDropdown = ({
  accountRegistration,
  disabled = false,
  control,
  warning,
  backgroundColor,
}: CountryOfCitizenshipDropdownProps) => {
  const { data, loading } = useCountryOfCitizenshipFieldQuery({
    variables: {
      accountRegistration,
    },
  });

  if (loading) {
    return null;
  }

  if (!data?.viewer.invest?.permissibleCountriesForAccountOpen) {
    return <GenericSystemError />;
  }

  const options = data.viewer.invest.permissibleCountriesForAccountOpen;

  return (
    <ControlledDropdown
      control={control}
      id="countryOfCitizenship"
      name="countryOfCitizenship"
      label="Country of citizenship"
      placeholder="Select"
      source={options}
      disabled={disabled}
      backgroundColor={backgroundColor ?? 'backgroundNeutralMain'}
      warning={warning}
      rules={{ required: 'Required' }}
    />
  );
};
