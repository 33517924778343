import { styled, Box } from '@m1/liquid-react';
import React from 'react';

import { BackArrow } from '~/components/BackArrow';

import { useGetPortfolioSliceQuery } from '~/graphql/hooks';
import { PortfolioNameFragment } from '~/graphql/types';
import { Link } from '~/toolbox/link';
import { LinkProps } from '~/toolbox/link/Link.types';

import { isNil } from '~/utils';

type PortfolioSliceNavigableButtonProps = {
  portfolioSlice?: {
    id: string;
    to: {
      name: string;
    };
  };
};

const StyledPortfolioSliceNavigableButton = styled(Box)`
  display: inline-flex;
  padding: 6px 12px;

  a {
    display: inline-flex;
    flex-direction: row;
  }
`;

export const PortfolioSliceNavigableButton = ({
  portfolioSlice,
}: PortfolioSliceNavigableButtonProps) => {
  const routerLinkProps: LinkProps = isNil(portfolioSlice)
    ? { to: '/d/invest/portfolio' }
    : {
        to: '/d/invest/portfolio/:portfolioSliceId',
        params: { portfolioSliceId: portfolioSlice.id },
      };

  return (
    <StyledPortfolioSliceNavigableButton>
      <Link {...routerLinkProps}>
        <BackArrow content={portfolioSlice?.to?.name ?? 'Portfolio'} />
      </Link>
    </StyledPortfolioSliceNavigableButton>
  );
};

export const PortfolioSliceNavigableButtonRenderer = ({
  id,
}: {
  id: string;
}) => {
  const { data, loading } = useGetPortfolioSliceQuery({
    variables: {
      id,
    },
  });

  if (loading || !data?.node) {
    return null;
  }

  const portfolioSlice = data.node as PortfolioNameFragment;

  return <PortfolioSliceNavigableButton portfolioSlice={portfolioSlice} />;
};
