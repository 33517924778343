import { Flex, HXXS, PL, PM, styled, Card } from '@m1/liquid-react';
import { Icon, type AllIconName } from '@m1/liquid-react/icons';
import * as React from 'react';

import { ListItemFragment } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';

const StyledCard = styled(Card)`
  align-items: center;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderMain};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const StyledListItem = styled.li`
  margin-bottom: 4px;
  margin-left: 24px;
`;

export type AccountSelectionCardProps = {
  card: ListItemFragment;
  onClick: (value: string) => void;
};

export const AccountSelectionCard = ({
  card: {
    analyticsEventClick,
    value,
    header,
    icon,
    subheader,
    subheaderItems,
    disabled,
  },
  onClick,
}: AccountSelectionCardProps) => {
  const analytics = useAnalytics();

  const handleClick = disabled
    ? undefined
    : {
        onClick: () => {
          if (analyticsEventClick) {
            analytics.recordAppAnalyticsEvent(analyticsEventClick);
          }
          if (value) {
            // Checks for value since this schema has changed and doesn't make it required
            onClick(value);
          }
        },
      };

  return (
    <StyledCard
      py={32}
      {...handleClick}
      color={disabled ? 'foregroundNeutralTertiary' : 'foregroundNeutralMain'}
      width="100%"
    >
      <Flex flexDirection="column" width="100%">
        <Flex alignItems="center" mb={8}>
          {icon?.names && (
            <Flex width={40}>
              <Icon name={icon.names[0] as AllIconName} />
            </Flex>
          )}
          <HXXS content={header} />
        </Flex>
        {subheader && (
          <Flex flexDirection="column" pl={icon?.names ? 40 : 0}>
            <PL content={subheader} mb={8} />
            {subheaderItems && subheaderItems.length > 0 && (
              <ul>
                {subheaderItems.map((subheader, index) => (
                  <StyledListItem key={index}>
                    <PM content={subheader} />
                  </StyledListItem>
                ))}
              </ul>
            )}
          </Flex>
        )}
      </Flex>
      {!disabled && (
        <Flex justifyContent="flex-end" width={32}>
          <Icon name="caretRight16" />
        </Flex>
      )}
    </StyledCard>
  );
};
