import { PM } from '@m1/liquid-react';
import React from 'react';

import { Gain } from '~/components/gain';
import { PercentGain } from '~/components/percent-gain';
import { SliceableNameCell } from '~/components/pie';
import { BorrowAccountFragment, InvestmentFragment } from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';
import { formatNumber } from '~/utils';

type InvestmentTableRowProps = {
  onRowClick?: (positionId: string) => void;
  position: InvestmentFragment;
  borrowAccount?: BorrowAccountFragment['borrowAccount'];
};

const readCurrency = (value: number | null | undefined) => {
  return typeof value === 'number' ? formatNumber(value) : '--';
};

export const InvestmentTableRow = ({
  position,
  onRowClick,
  borrowAccount,
}: InvestmentTableRowProps) => {
  const {
    id,
    positionSecurity: { security, descriptor },
    quantity,
    cost,
    marginability,
    unrealizedGain,
    value,
  } = position;

  const navigableProps = onRowClick
    ? {
        onClick: () => onRowClick(id),
      }
    : {
        to: '/d/invest/holdings/position/:positionId',
        params: {
          positionId: id,
        },
      };

  const positionAvg = readCurrency(cost?.averageSharePrice);
  const positionCost = readCurrency(cost?.cost);
  const positionValue = readCurrency(value?.value);
  const positionMarginability = marginability
    ? formatNumber(
        marginability.maintenanceEquityRequirementPercent / 100,
        '0%',
      )
    : '--';

  const unrealizedGainContent = unrealizedGain ? (
    <span>
      <Gain value={unrealizedGain.gain} />
      <PercentGain includeParenthesis value={unrealizedGain.gainPercent} />
    </span>
  ) : (
    '--'
  );

  return (
    <GridTable.NavigableRow {...navigableProps}>
      <GridTable.Cell
        content={
          security ? (
            <SliceableNameCell sliceable={security} truncated />
          ) : (
            <PM content={descriptor} />
          )
        }
      />
      <GridTable.Cell
        content={formatNumber(quantity, '0,0.[00000]')}
        textAlign="right"
      />
      <GridTable.Cell content={positionAvg} textAlign="right" />
      <GridTable.Cell content={positionCost} textAlign="right" />
      {borrowAccount && (
        <GridTable.Cell content={positionMarginability} textAlign="right" />
      )}
      <GridTable.Cell content={unrealizedGainContent} textAlign="right" />
      <GridTable.Cell content={positionValue} textAlign="right" />
    </GridTable.NavigableRow>
  );
};
