import * as React from 'react';
import { Route } from 'react-router-dom';

import { PortfolioEditorPage } from './PortfolioEditorPage';

export const PortfolioEditorRoute = () => {
  return (
    <Route
      path="portfolio-editor/:step?"
      element={<PortfolioEditorPage />}
      handle={{
        fallbackRoute: {
          to: '/d/invest',
        },
      }}
    />
  );
};
