import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';

import { useSavingsFundingCompleteQuery } from '~/graphql/hooks';
import { AppInfoPage } from '~/lens-toolbox/AppInfoPage';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

type SavingsFundingCompleteProps = {
  onFinishStep: () => void;
};

export const SavingsFundingComplete = ({
  onFinishStep,
}: SavingsFundingCompleteProps) => {
  // This should exist
  const accountId = useSelector(
    (state) =>
      state.newFlows.SAVINGS_ONBOARDING.accountId ??
      state.global.savingsAccountId,
  ) as string;

  const { data, loading, error } = useSavingsFundingCompleteQuery({
    variables: {
      accountId,
    },
  });

  const { fundingCompletePage } = data?.viewer.save?.savings?.onboarding ?? {};

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!fundingCompletePage || error) {
    return <GenericSystemError />;
  }

  return (
    <AppInfoPage
      appInfoPage={fundingCompletePage}
      onPrimaryButtonClick={() => onFinishStep()}
      onSecondaryButtonClick={() => onFinishStep()}
    />
  );
};
