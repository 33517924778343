import { HXS, PL } from '@m1/liquid-react';
import * as React from 'react';

import { CustodialContactInfoForm } from '~/forms';

type CustodialStepProps = {
  onFinishStep: () => void;
};

const CollectCustodialContactInfoStep = ({
  onFinishStep,
}: CustodialStepProps) => {
  return (
    <>
      <HXS content="Custodial information" my={16} />
      <PL
        content="Enter the personal and address information for the minor that will be associated with this custodial account."
        mb={16}
      />
      <CustodialContactInfoForm
        onSubmit={() => {
          onFinishStep();
        }}
      />
    </>
  );
};

export const CustodialStep = ({ onFinishStep }: CustodialStepProps) => {
  return <CollectCustodialContactInfoStep onFinishStep={onFinishStep} />;
};
