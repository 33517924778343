import { useSelector } from '~/redux/hooks';

// eslint-disable-next-line no-redeclare
export function useActiveAccount(required: true): string;
// eslint-disable-next-line no-redeclare
export function useActiveAccount(required: false): string | null | undefined;
// eslint-disable-next-line no-redeclare
export function useActiveAccount(required: boolean = false) {
  const activeAccountId = useSelector((state) => state.global.activeAccountId);
  if (required && !activeAccountId) {
    throw new Error('Active account ID required but not found.');
  }
  return activeAccountId;
}
