import * as React from 'react';
import { InjectedFormProps } from 'redux-form';

import { TransferParticipantDropdownField } from '~/forms/fields';
import { required } from '~/forms/validators';
import { connectForm } from '~/hocs';
import { DropdownOption } from '~/toolbox/Dropdown';

export type AutoPayEnrollmentFormFields = {
  transferParticipantId: string;
};

export type AutoPayEnrollmentFormSubmitHandler = (
  values: AutoPayEnrollmentFormFields,
) => void;

export type AutoPayEnrollmentFormProps = {
  accounts: DropdownOption[];
  initialValues?: AutoPayEnrollmentFormFields;
};

export const AutoPayEnrollmentForm = connectForm({
  form: 'autoPayEnrollmentForm',
})(
  ({
    handleSubmit,
    accounts,
  }: InjectedFormProps<AutoPayEnrollmentFormFields> &
    AutoPayEnrollmentFormProps) => (
    <form id="autoPayEnrollmentForm" onSubmit={handleSubmit}>
      <TransferParticipantDropdownField
        label="Withdraw from"
        name="transferParticipantId"
        options={accounts}
        placeholder="Select account"
        validate={[required]}
      />
    </form>
  ),
  // Here we're asserting what type this component should be.
  // By default it's React.ComponentClass<any, any>, which throws
  // an error as being invalid JSX. This is due to `connectForm`
  // being incorrectly typed. This is a bit of a hacky fix, but
  // it eliminates the need for a ts-expect-error comment and it
  // gives us "good-enough" type information for the component's
  // required props. Typescript requires casting to `unknwon`
  // first before we can change the type to React.FC<Props>
) as unknown as React.FC<
  AutoPayEnrollmentFormProps & {
    onSubmit: AutoPayEnrollmentFormSubmitHandler;
  }
>;
