export const SPEND_ONBOARDING_ACTIONS = Object.freeze({
  BEGIN_SPEND_ONBOARDING_FLOW: 'BEGIN_SPEND_ONBOARDING_FLOW' as const,
  FINISHED_IDENTITY_PROFILE: 'FINISHED_IDENTITY_PROFILE' as const,
  SET_SPEND_ONBOARDING_DISCLOSURES: 'SET_SPEND_ONBOARDING_DISCLOSURES' as const,
  FINISHED_SPEND_ONBOARDING: 'FINISHED_SPEND_ONBOARDING' as const,
  SET_SPEND_ACCOUNT_NAME: 'SET_SPEND_ACCOUNT_NAME' as const,
  RESET_DUE_DILIGENCE_ANSWERS: 'RESET_DUE_DILIGENCE_ANSWERS' as const,
});

export type BeginSpendOnboardingFlow = {
  payload: {
    basePath: string;
    onFinish: (arg0: boolean) => void;
  };
  type: typeof SPEND_ONBOARDING_ACTIONS.BEGIN_SPEND_ONBOARDING_FLOW;
};

export type SetSpendAccountNameAction = {
  payload: string;
  type: typeof SPEND_ONBOARDING_ACTIONS.SET_SPEND_ACCOUNT_NAME;
};

export type FinishedIdentityProfileAction = {
  type: typeof SPEND_ONBOARDING_ACTIONS.FINISHED_IDENTITY_PROFILE;
};

export type FinishedSpendOnboarding = {
  type: typeof SPEND_ONBOARDING_ACTIONS.FINISHED_SPEND_ONBOARDING;
};

export type ResetDueDiligenceAnswersAction = {
  payload: number;
  type: typeof SPEND_ONBOARDING_ACTIONS.RESET_DUE_DILIGENCE_ANSWERS;
};

export const beginSpendOnboardingFlow = (
  payload: BeginSpendOnboardingFlow['payload'],
): BeginSpendOnboardingFlow => ({
  type: SPEND_ONBOARDING_ACTIONS.BEGIN_SPEND_ONBOARDING_FLOW,
  payload,
});

export const finishedIdentityProfile = (): FinishedIdentityProfileAction => ({
  type: SPEND_ONBOARDING_ACTIONS.FINISHED_IDENTITY_PROFILE,
});

export const finishedSpendOnboarding = (): FinishedSpendOnboarding => ({
  type: SPEND_ONBOARDING_ACTIONS.FINISHED_SPEND_ONBOARDING,
});

export type SpendOnboardingAction =
  | BeginSpendOnboardingFlow
  | FinishedIdentityProfileAction
  | FinishedSpendOnboarding
  | ResetDueDiligenceAnswersAction
  | SetSpendAccountNameAction;
