import { Flex, HXS, PL } from '@m1/liquid-react';
import * as React from 'react';

import { AppContext } from '~/AppContext';
import { BackArrow } from '~/components/BackArrow';
import { LinkableCard } from '~/components/cards';
import { GenericSystemError } from '~/components/GenericSystemError';
import { TransferEmailVerificationCard } from '~/components/transfer-email-verification-card';
import { useMoveMoneyStepQuery } from '~/graphql/hooks';
import { MoveMoneyOptionFragment, MoveMoneyType } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { AppImage } from '~/lens-toolbox/AppImage';
import { AppPill } from '~/lens-toolbox/AppPill';
import { useDispatch, useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';
import { parseQuery } from '~/utils';

type MoveMoneyProps = {
  onFinishStep: () => void;
};

export const MoveMoneyStep = ({ onFinishStep }: MoveMoneyProps) => {
  const navigate = useNavigate();
  const { analytics } = React.useContext(AppContext);
  const dispatch = useDispatch();
  const previousRouteName = useSelector(
    (state) => state.newFlows.MOVE_MONEY.previousRouteName,
  );

  let type = MoveMoneyType.Transfers;
  const queries = parseQuery(window.location.search);
  let query: MoveMoneyType;

  if (
    queries.type &&
    Object.values(MoveMoneyType).includes(
      (query = queries.type.toUpperCase() as MoveMoneyType),
    )
  ) {
    // Valid type is provided as query param, so use that
    type = query;
  } else if (previousRouteName) {
    if (previousRouteName.includes('savings')) {
      type = MoveMoneyType.Save;
    } else if (previousRouteName.includes('spend')) {
      type = MoveMoneyType.Spend;
    }
  } else if (window.location.pathname.includes('move-money/direct-deposit')) {
    type = MoveMoneyType.DirectDeposit;
  }

  const { data, loading, error } = useMoveMoneyStepQuery({
    variables: {
      type,
      previousRouteName,
    },
  });

  if (loading) {
    return <Spinner fullScreen />;
  }
  if (error || !data?.viewer.transfers?.moveMoney) {
    return <GenericSystemError />;
  }

  const { title, backButtonLabel, categories } =
    data.viewer.transfers.moveMoney;

  const showTransferEmailVerificationCard =
    type === MoveMoneyType.Transfers &&
    typeof data.viewer.user?.isPrimaryEmailVerified === 'boolean' &&
    !data.viewer.user.isPrimaryEmailVerified;

  const handleOptionClick = (option: MoveMoneyOptionFragment) => {
    navigate({ to: option.link?.internalPath ?? '/d/home' });

    onFinishStep();
    if (option.link?.analyticsEvent) {
      analytics.recordAppAnalyticsEvent(option.link.analyticsEvent);
    }
    option.userDataEntries?.forEach((payload) =>
      dispatch({
        type: 'UPDATE_USER_DATA',
        payload,
      }),
    );
  };

  return (
    <Flex flexDirection="column">
      {previousRouteName && (
        <BackArrow mb={24} content={backButtonLabel} to={previousRouteName} />
      )}
      {showTransferEmailVerificationCard && (
        <TransferEmailVerificationCard resendEmailStyle="link" />
      )}
      <HXS content={title} mb={16} />
      {categories.map((category) => (
        <React.Fragment key={category.label}>
          <PL fontWeight={600} content={category.label} pt={32} />
          {category.options?.map((option) => {
            const titleContentProp = option.pill && {
              titleContent: (
                <Flex alignItems="center">
                  {option.title}
                  <AppPill ml={8} appPill={option.pill} />
                </Flex>
              ),
            };
            return (
              <LinkableCard
                key={option.title}
                title={option.title}
                {...titleContentProp}
                icon={<AppImage appImage={option.icon} />}
                onClick={() => handleOptionClick(option)}
                mt={16}
                subtitle={option.subtitle}
              />
            );
          })}
        </React.Fragment>
      ))}
    </Flex>
  );
};
