import { Flex, PM, Button, Tooltip } from '@m1/liquid-react';
import * as React from 'react';

import { useInvestmentsTablePaginationQuery } from '~/graphql/hooks';
import {
  InvestmentFragment,
  InvestmentsFragment,
  PositionSortOptionInput,
  PositionSortTypeEnum,
  SortDirectionEnum,
} from '~/graphql/types';
import { InvestmentsUnavailable } from '~/pages/dashboard/invest/holdings/components/InvestmentsUnavailable';

import { useSelector } from '~/redux/hooks';
import { GridTable } from '~/toolbox/grid-table';
import { Spinner } from '~/toolbox/spinner';

import { InvestmentTableInfoSection } from './InvestmentTableInfoSection';

import { InvestmentTableRow } from './InvestmentTableRow';

const {
  AverageSharePrice,
  Cost,
  MaintenanceEquityRequirementPercent,
  Quantity,
  Symbol,
  UnrealizedGainPercent,
  Value,
} = PositionSortTypeEnum;

const { Asc, Desc } = SortDirectionEnum;

export type InvestmentsTableProps = {
  accountId: string;
  onRowClick?: (positionId: string) => void;
};

type TableHeaderProps = {
  align: 'left' | 'right';
};

const initialPositionsSort: PositionSortOptionInput = {
  direction: Desc,
  type: Value,
};

export const InvestmentsTable = ({
  accountId,
  onRowClick,
}: InvestmentsTableProps) => {
  const [positionsSort, setPositionSort] = React.useState(initialPositionsSort);

  const { data, loading, refetch, fetchMore } =
    useInvestmentsTablePaginationQuery({
      variables: {
        accountId,
        first: 100,
        positionsSort: initialPositionsSort,
      },
    });

  const qtyLabel = useSelector((state) =>
    state.global.activeAccountIsCrypto ? 'Quantity' : 'Shares',
  );

  if (loading) {
    return <Spinner />;
  }

  const account = data?.account;
  if (account?.__typename !== 'Account' || !account.balance.investments) {
    return <InvestmentsUnavailable />;
  }

  const investments = account.balance.investments;

  if (data?.account?.__typename !== 'Account') {
    return null;
  }

  const { balance, borrowAccount } = data.account;

  const pageInfo = balance.investments?.positions.pageInfo;

  const handleTableHeaderClick = (sortKey: PositionSortTypeEnum): void => {
    let sortDirection = positionsSort.direction;
    if (positionsSort.type === sortKey) {
      sortDirection = sortDirection === Asc ? Desc : Asc;
    } else {
      sortDirection = Desc;
    }

    const newSort = {
      type: sortKey,
      direction: sortDirection,
    };

    setPositionSort(newSort);

    refetch({
      positionsSort: newSort,
    });
  };

  const renderTableHeader = (
    key: PositionSortTypeEnum | null | undefined,
    label: React.ReactNode,
    headerStyle?: TableHeaderProps,
  ) => {
    if (key) {
      return (
        <GridTable.HeaderCell
          {...headerStyle}
          label={label}
          onClick={() => handleTableHeaderClick(key)}
          sortDirection={
            key === positionsSort.type ? positionsSort.direction : null
          }
        />
      );
    }
    return <GridTable.HeaderCell {...headerStyle} label={label} />;
  };

  const handleViewMoreClick = (): void => {
    if (!pageInfo?.hasNextPage) {
      return;
    }

    fetchMore({
      variables: {
        after: pageInfo.endCursor,
      },
    });
  };

  return (
    <>
      <InvestmentTableInfoSection
        accountOriginator={account.originator}
        investments={investments as InvestmentsFragment}
        borrowAccount={borrowAccount}
      />
      <GridTable
        emptyMessage="You have no holdings"
        gridTemplateColumns={
          borrowAccount
            ? '210px 80px 80px 80px 120px 150px auto'
            : '220px 120px 120px 120px 150px auto'
        }
      >
        <GridTable.HeaderRow sticky>
          {renderTableHeader(Symbol, 'Name')}
          {renderTableHeader(Quantity, qtyLabel, {
            align: 'right',
          })}
          {renderTableHeader(AverageSharePrice, 'Avg. price', {
            align: 'right',
          })}
          {renderTableHeader(Cost, 'Cost basis', {
            align: 'right',
          })}
          {borrowAccount &&
            renderTableHeader(
              MaintenanceEquityRequirementPercent,
              <>
                Maintenance req.
                <Tooltip
                  placement="bottom-start"
                  offset={[0, 4]}
                  iconColor="foregroundPrimary"
                  icon="tooltip16"
                  body={
                    <PM
                      content="The minimum amount of equity that must be maintained when leveraged with M1 Borrow."
                      m="8px 12px"
                      textAlign="left"
                    />
                  }
                />
              </>,
              {
                align: 'right',
              },
            )}
          {renderTableHeader(UnrealizedGainPercent, 'Unrealized gain', {
            align: 'right',
          })}
          {renderTableHeader(Value, 'Value', {
            align: 'right',
          })}
        </GridTable.HeaderRow>
        {(investments?.positions.edges ?? []).map((position) => {
          if (!position?.node) {
            return null;
          }
          return (
            <InvestmentTableRow
              position={position.node as InvestmentFragment}
              key={position.node.id}
              onRowClick={onRowClick}
              borrowAccount={borrowAccount}
            />
          );
        })}
      </GridTable>
      {pageInfo?.hasNextPage && (
        <Flex justifyContent="center" mt={16}>
          <Button
            kind="secondary"
            label="Load more"
            onClick={handleViewMoreClick}
          />
        </Flex>
      )}
    </>
  );
};
