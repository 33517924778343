import { Box, Flex, HM } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';

import { useSavingsAccountTypeQuery } from '~/graphql/hooks';
import { AccountSelectionCard } from '~/pages/setup-ira-account/components/AccountSelectionCard';

type SavingsAccountTypeProps = {
  onFinishStep: (name: string | null) => void;
};

export const SavingsAccountType = ({
  onFinishStep,
}: SavingsAccountTypeProps) => {
  const { loading, data } = useSavingsAccountTypeQuery({});
  const accountTypePage =
    data?.viewer.save?.savings?.onboarding?.accountTypePage;
  if (loading) {
    return null;
  }
  return (
    <Flex
      alignItems="baseline"
      flexDirection="column"
      justifyContent="center"
      maxWidth={588}
      mx="auto"
    >
      <Box my={32}>
        <BackArrow content="Back" onClick={() => window.history.back()} />
      </Box>
      <HM content={accountTypePage?.title} mb={16} />
      {accountTypePage?.accountTypes?.map((type) => (
        <AccountSelectionCard
          card={type}
          key={type.value}
          onClick={onFinishStep}
        />
      ))}
    </Flex>
  );
};
