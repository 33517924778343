import { Box, Button, Flex, HM, HXXS } from '@m1/liquid-react';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { BackArrow } from '~/components/BackArrow';
import { ControlledInput } from '~/components/form/ControlledInput';
import { GenericSystemError } from '~/components/GenericSystemError';
import { useOpenInvestJointAccountWizardContext } from '~/flows/components/OpenInvestJointAccountWizard/hooks/useOpenInvestJointAccountWizardContext';
import { FormMockerReactHookForm } from '~/forms/FormMockers/FormMockerReactHookForm';
import { useCountryOfCitizenshipFieldQuery } from '~/graphql/hooks';
import { OnlineAccountRegistrationEnum } from '~/graphql/types';
import { submitReactFormData } from '~/redux/actions';
import { useSelector, useDispatch } from '~/redux/hooks';
import { Dropdown } from '~/toolbox/Dropdown';

import { Spinner } from '~/toolbox/spinner';

export interface IdentityFormValues {
  dateOfBirth: string;
  citizenship: string;
}

export const Identity = () => {
  const submittedFormValues = useSelector((state) => {
    if (!state.reactHookForm.openJointInvestAccountIdentity) {
      return {
        dateOfBirth: undefined,
        citizenship: undefined,
      };
    }
    return state.reactHookForm.openJointInvestAccountIdentity;
  });

  const dispatch = useDispatch();

  const { control, watch, register, setValue, handleSubmit } =
    useForm<IdentityFormValues>({
      defaultValues: submittedFormValues,
    });

  const wiz = useOpenInvestJointAccountWizardContext();

  const citizenship = watch('citizenship');

  const { data, loading } = useCountryOfCitizenshipFieldQuery({
    variables: {
      accountRegistration: OnlineAccountRegistrationEnum.Joint,
    },
  });

  const onSubmit: SubmitHandler<IdentityFormValues> = (data) => {
    wiz.next();
    dispatch(
      submitReactFormData({
        openJointInvestAccountIdentity: data,
      }),
    );
  };

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.viewer.invest?.permissibleCountriesForAccountOpen) {
    return <GenericSystemError />;
  }

  return (
    <Flex maxWidth={588} flexDirection="column" mx="auto">
      <Box pt={32}>
        <BackArrow content="Back" onClick={() => wiz.back()} />
      </Box>
      <HM mt={48}>Secondary Applicant Identity Verification</HM>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HXXS pt={32}>Date of birth</HXXS>
        <ControlledInput
          name="dateOfBirth"
          label="Date of birth"
          placeholder="MM/DD/YYYY"
          control={control}
          rules={{ required: true }}
          maskType="date"
          maxLength={30}
          backgroundColor="backgroundNeutralMain"
        />
        <HXXS pt={32}>Citizenship</HXXS>
        <Dropdown
          {...register('citizenship', {
            required: true,
          })}
          name="citizenship"
          label="Country of citizenship"
          onChange={(value) => {
            setValue('citizenship', value);
          }}
          source={data.viewer.invest.permissibleCountriesForAccountOpen}
          value={citizenship}
          backgroundColor="backgroundNeutralMain"
        />
        <Button
          type="submit"
          kind="primary"
          size="large"
          label="Continue"
          mt={32}
        />
        <FormMockerReactHookForm
          fields={[
            {
              name: 'dateOfBirth',
              value: '06/30/1990',
            },
            {
              name: 'citizenship',
              value: 'USA',
            },
          ]}
          setValue={setValue}
        />
      </form>
    </Flex>
  );
};
