import { PM, useIsProduction } from '@m1/liquid-react';
import * as React from 'react';
import { change } from 'redux-form';

import { useDispatch } from '~/redux/hooks';

type FormMockerProps = {
  label?: string;
  formName: string;
  fields: Array<{
    name: string;
    value: any;
  }>;
  onClick?: () => void;
};

export const FormMocker = ({
  onClick,
  fields,
  formName,
  label = 'Mock this form!',
}: FormMockerProps) => {
  const dispatch = useDispatch();

  const onLabelClick = React.useCallback(() => {
    if (onClick) {
      onClick();
    }

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      dispatch(change(formName, field.name, field.value));
    }
  }, [dispatch, fields, formName]);

  const isProduction = useIsProduction();

  if (isProduction) {
    return null;
  }

  return (
    <PM
      content={label}
      onClick={onLabelClick}
      color="primary"
      cursor="pointer"
      pt={8}
    />
  );
};
