import * as React from 'react';

import { MyPieFragment } from '~/graphql/types';

import { ArchivePieModal } from './ArchivePieModal';
import { PieActionsPopup } from './PieActionsPopup';
import { PieListItem } from './PieListItem';
import { onPieArchiveCallbackInput } from './types';
import { useMyPiesContext } from './useMyPiesContext';

type PieListProps = {
  noPiesMessage?: string | null | undefined;
  onClick: (pieId: string) => void;
  userPies: MyPieFragment['node'][];
};

type PieArchiveData =
  | (onPieArchiveCallbackInput & {
      viewerId: string | undefined;
    })
  | null;

export const PieList = ({ onClick, userPies }: PieListProps) => {
  const [showArchiveModal, setShowArchiveModal] = React.useState(false);
  const [pieArchiveData, setPieArchiveData] =
    React.useState<PieArchiveData>(null);
  const {
    minimal,
    queryData: { data },
  } = useMyPiesContext();

  const viewerId = data?.viewer.id;

  const handlePieArchive = React.useCallback(
    (payload: onPieArchiveCallbackInput) => {
      setShowArchiveModal(true);

      setPieArchiveData({
        ...payload,
        viewerId,
      });
    },
    [viewerId],
  );
  return (
    <>
      {userPies.map((pie) => (
        <PieListItem
          pie={pie!}
          key={pie?.id}
          actions={
            minimal ? null : (
              <PieActionsPopup
                pie={pie!}
                onPieArchiveCallback={handlePieArchive}
              />
            )
          }
          onClick={(pieId: string) => onClick(pieId)}
        />
      ))}
      {pieArchiveData && (
        <ArchivePieModal
          showDeleteModal={showArchiveModal}
          setShowDeleteModal={setShowArchiveModal}
          pieArchiveMutationData={pieArchiveData}
        />
      )}
    </>
  );
};
