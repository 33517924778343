import { Button } from '@m1/liquid-react';
import * as React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { Grid } from '~/toolbox/grid';

import { SsnField } from './fields';
import { makeFormConfig } from './utils';

const formConfig = makeFormConfig({
  form: 'identity-info',
});
export const VerifySsnForm = reduxForm(formConfig)(
  // @ts-expect-error - TS2345 - Argument of type 'typeof (Anonymous class)' is not assignable to parameter of type 'ComponentType<InjectedFormProps<{}, {}, string>>'.
  class extends React.Component<
    InjectedFormProps<any> & {
      onSubmit: (...args: Array<any>) => any;
      submitButtonLabel: string;
    }
  > {
    static defaultProps = {
      submitButtonLabel: 'Continue',
    };

    render() {
      return (
        <form onSubmit={this.props.handleSubmit}>
          <Grid>
            <Grid.Row xsTextCenter>
              <Grid.Col xs={12}>
                <SsnField name="ssn" label="Social security number" autoFocus />
              </Grid.Col>
            </Grid.Row>
            <br />

            <Grid.Row xsTextCenter>
              <Grid.Col xs={12}>
                <Button
                  type="submit"
                  kind="primary"
                  size="large"
                  fullWidth
                  label={this.props.submitButtonLabel}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </form>
      );
    }
  },
);
