import {
  ACCOUNT_SETUP_ACTIONS,
  AccountSetupLegacyAction,
} from './accountSetup(legacy)';
import { CONNECT_BANK_ACTIONS, ConnectBankAction } from './connectBankActions';
import {
  CREATE_SEND_CHECK_ACTIONS,
  CreateSendCheckAction,
} from './createSendCheckActions';
import {
  CREATE_SMART_TRANSFER_ACTIONS,
  CreateSmartTransferAction,
} from './createSmartTransferActions';
import {
  CREATE_TRANSFER_ACTIONS,
  CreateTransferAction,
} from './createTransferActions';
import { IDENTITY_FIRST_ACTIONS, IdentityFirstAction } from './identityFirst';
import {
  IDENTITY_PROFILE_ACTIONS,
  IdentityProfileAction,
} from './identityProfileActions';
import {
  INITIAL_FUNDING_ACTIONS,
  InitialFundingAction,
} from './initialFunding';
import {
  INVEST_ONBOARDING_ACTIONS,
  InvestOnboardingAction,
} from './investOnboarding';
import {
  KNOW_YOUR_CUSTOMER_ACTIONS,
  KnowYourCustomerAction,
} from './knowYourCustomerActions';
import { LOGIN_ACTIONS, LoginAction } from './login';
import {
  MARGIN_CALL_INFORMATION_ACTIONS,
  MarginCallInformationAction,
} from './marginCallInformationActions';
import { MOVE_MONEY_ACTIONS, MoveMoneyAction } from './moveMoneyActions';
import { ONBOARDING_ACTIONS, OnboardingAction } from './onboarding';
import {
  PERSONAL_LOAN_APPLICATION_ACTIONS,
  PersonalLoansApplicationAction,
} from './personalLoanApplicationActions';
import {
  PHONE_VERIFICATION_ACTIONS,
  PhoneVerificationAction,
} from './phoneVerificationActions';
import {
  PORTFOLIO_EDITOR_ACTIONS,
  PortfolioEditorAction,
} from './portfolioEditor';
import {
  REGISTER_USER_ACTIONS,
  RegisterUserAction,
} from './registerUserActions';
import {
  RESOLVE_MARGIN_CALL_ACTIONS,
  ResolveMarginCallAction,
} from './resolveMarginCall';
import {
  SAVINGS_ONBOARDING_ACTIONS,
  SavingsOnboardingAction,
} from './savingsOnboardingActions';
import { SET_ORDER_ACTIONS, SetOrderAction } from './setOrderActions';
import {
  SPEND_ONBOARDING_ACTIONS,
  SpendOnboardingAction,
} from './spendOnboardingActions';

export * from './accountSetup(legacy)';
export * from './connectBankActions';
export * from './createSendCheckActions';
export * from './createSmartTransferActions';
export * from './createTransferActions';
export * from './identityFirst';
export * from './identityProfileActions';
export * from './initialFunding/initialFundingActions';
export * from './investOnboarding';
export * from './knowYourCustomerActions';
export * from './login/loginActions';
export * from './marginCallInformationActions';
export * from './moveMoneyActions';
export * from './onboarding/onboardingActions';
export * from './personalLoanApplicationActions';
export * from './phoneVerificationActions';
export * from './portfolioEditor';
export * from './registerUserActions';
export * from './resolveMarginCall';
export * from './savingsOnboardingActions';
export * from './setOrderActions';
export * from './spendOnboardingActions';

export const NEW_FLOWS_ACTIONS = Object.freeze({
  ...ACCOUNT_SETUP_ACTIONS,
  ...CONNECT_BANK_ACTIONS,
  ...CREATE_SEND_CHECK_ACTIONS,
  ...CREATE_SMART_TRANSFER_ACTIONS,
  ...CREATE_TRANSFER_ACTIONS,
  ...IDENTITY_FIRST_ACTIONS,
  ...IDENTITY_PROFILE_ACTIONS,
  ...INITIAL_FUNDING_ACTIONS,
  ...INVEST_ONBOARDING_ACTIONS,
  ...KNOW_YOUR_CUSTOMER_ACTIONS,
  ...LOGIN_ACTIONS,
  ...MARGIN_CALL_INFORMATION_ACTIONS,
  ...MOVE_MONEY_ACTIONS,
  ...ONBOARDING_ACTIONS,
  ...PERSONAL_LOAN_APPLICATION_ACTIONS,
  ...PHONE_VERIFICATION_ACTIONS,
  ...PORTFOLIO_EDITOR_ACTIONS,
  ...REGISTER_USER_ACTIONS,
  ...RESOLVE_MARGIN_CALL_ACTIONS,
  ...SAVINGS_ONBOARDING_ACTIONS,
  ...SET_ORDER_ACTIONS,
  ...SPEND_ONBOARDING_ACTIONS,
});

export type NewFlowAction =
  | AccountSetupLegacyAction
  | ConnectBankAction
  | CreateSendCheckAction
  | CreateSmartTransferAction
  | CreateTransferAction
  | IdentityFirstAction
  | IdentityProfileAction
  | InitialFundingAction
  | InvestOnboardingAction
  | KnowYourCustomerAction
  | LoginAction
  | MarginCallInformationAction
  | MoveMoneyAction
  | OnboardingAction
  | PersonalLoansApplicationAction
  | PhoneVerificationAction
  | PortfolioEditorAction
  | RegisterUserAction
  | ResolveMarginCallAction
  | SavingsOnboardingAction
  | SetOrderAction
  | SpendOnboardingAction;
