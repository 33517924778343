import * as React from 'react';

// Generic utility function for ENTER key action
// to be used for ACAT Wizard flow pages
export const handleEnterKeyDownUtils = (
  event: React.KeyboardEvent,
  enabled: boolean,
  callback: () => void,
): void => {
  if (enabled && event.key === 'Enter') {
    callback();
  }
};
