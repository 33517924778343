import {
  useTheme,
  PS,
  Flex,
  Box,
  Tooltip,
  SemanticColorNames,
  validTooltipIcons,
  HL,
} from '@m1/liquid-react';
import * as d3 from 'd3';
import * as React from 'react';

import { AppTooltipFragment } from '~/graphql/types';

import {
  SpeedometerContainer,
  SpeedometerInsideBorderSvg,
  SpeedometerSvg,
  StyledContentContainer,
} from './Speedometer.styled';

type SpeedometerProps = {
  percentage: number;
  content?: string | null;
  tooltip: AppTooltipFragment | null | undefined;
};

export const Speedometer = ({
  percentage,
  content,
  tooltip,
}: SpeedometerProps) => {
  const { colors } = useTheme();
  const { backgroundNeutralTertiary, borderMain } = colors;

  const angle = Number(Math.PI);
  const arc = d3
    .arc()
    .innerRadius(105)
    .outerRadius(120)
    .startAngle(-Math.PI * 0.0);
  // @ts-expect-error - TS2555 - Expected at least 1 arguments, but got 0.
  const bg = arc.endAngle(angle)();
  // @ts-expect-error - TS2555 - Expected at least 1 arguments, but got 0.
  const fill = arc.endAngle(angle * (percentage / 100))();

  return (
    <SpeedometerContainer
      alignItems="center"
      alignSelf="center"
      flexGrow="1"
      justifyContent="center"
    >
      <SpeedometerSvg viewBox="0 -15 240 135">
        <g transform="translate(120, 120) rotate(-90)">
          <path
            d={bg}
            fill={backgroundNeutralTertiary}
            stroke={backgroundNeutralTertiary}
          />
          <>
            <path
              d={fill}
              fill="url(#paint0_linear_4508_230445)"
              stroke="url(#paint0_linear_4508_230445)"
            />
            <linearGradient
              id="paint0_linear_4508_230445"
              x1="220.283"
              y1="150.649"
              x2="0"
              y2="150.649"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3D5A94" />
              <stop offset="1" stopColor="#A6C9DA" />
            </linearGradient>
          </>
        </g>
      </SpeedometerSvg>
      {/* Inside border */}
      <SpeedometerInsideBorderSvg
        viewBox="0 -13 247 137"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M247.208 124.169C247.208 107.865 244.024 91.7199 237.837 76.6566C231.649 61.5934 222.581 47.9065 211.148 36.3776C199.716 24.8486 186.144 15.7034 171.206 9.46398C156.269 3.22457 140.259 0.0131829 124.091 0.0131836C107.924 0.0131843 91.9139 3.22457 76.9767 9.46399C62.0395 15.7034 48.4672 24.8487 37.0347 36.3776C25.6022 47.9065 16.5335 61.5934 10.3463 76.6567C4.15912 91.7199 0.974608 107.865 0.974609 124.169H3.38098C3.38098 108.183 6.50325 92.3542 12.5695 77.5853C18.6358 62.8164 27.5273 49.3971 38.7363 38.0935C49.9453 26.7899 63.2523 17.8234 77.8976 11.7059C92.5428 5.58848 108.24 2.43987 124.091 2.43987C139.943 2.43986 155.64 5.58848 170.285 11.7059C184.931 17.8234 198.238 26.7899 209.447 38.0935C220.656 49.3971 229.547 62.8164 235.613 77.5853C241.68 92.3542 244.802 108.183 244.802 124.169L247.208 124.169Z"
          fill={borderMain}
        />
      </SpeedometerInsideBorderSvg>
      <StyledContentContainer>
        <HL
          fontWeight={300}
          color="foregroundSecondary"
          content={`${percentage}%`}
        />
        {content && (
          <Flex flexDirection="row" alignSelf="center">
            <PS
              color="foregroundNeutralSecondary"
              content={
                <Box>
                  {content}
                  {tooltip && (
                    <Tooltip
                      icon={
                        (tooltip?.icon
                          ?.names?.[0] as (typeof validTooltipIcons)[number]) ||
                        'tooltip16'
                      }
                      iconColor={
                        (tooltip?.icon?.color as SemanticColorNames) ||
                        'foregroundPrimary'
                      }
                      body={tooltip?.body}
                      header={tooltip?.header}
                      placement="bottom-end"
                    />
                  )}
                </Box>
              }
              fontWeight={400}
              alignSelf="center"
            />
          </Flex>
        )}
      </StyledContentContainer>
    </SpeedometerContainer>
  );
};
