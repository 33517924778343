// ignore initial ?, & or # in the string provided
// parse name / value pairs from a string into an key / value object
export const parseQuery = (querystring: string) => {
  const qs = querystring.trim().replace(/^[?#&]/, '');
  const params = new URLSearchParams(qs);
  return Object.fromEntries(params);
};

// take a key/value onbject and return a query string
export const stringifyQuery = (queryObject: Record<string, string>) => {
  const params = new URLSearchParams(queryObject);
  return params.toString();
};
