import { Button, Flex, Modal, ModalContent, styled } from '@m1/liquid-react';
import * as React from 'react';

import {
  ConfirmationDialogPieFragment,
  PieChangesConfirmationFragment,
} from '~/graphql/types';
import { useToast } from '~/toasts';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

import {
  mapAddedOrUpdated,
  mapApplicableLocations,
  mapDescription,
  mapName,
  mapRemoved,
} from './ConfirmationDialog.helpers';
import { ConfirmationGroup } from './ConfirmationGroup';
import { ConfirmationHeader } from './ConfirmationHeader';

type ConfirmationDialogProps = {
  active: boolean;
  onCancel: () => void;
  onConfirm: (e: React.SyntheticEvent<any>) => void | Promise<void>;
  pie: ConfirmationDialogPieFragment;
  pieChangesConfirmation: PieChangesConfirmationFragment | null | undefined;
  showApplicableLocations: boolean;
};

const StyledOverflowFlex = styled(Flex)`
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: ${({ theme }) => theme.colors.backgroundPrimarySubtle}
    transparent;
  &::-webkit-scrollbar-track-piece {
    color: transparent;
  }
`;

export const ConfirmationDialog = ({
  active,
  onCancel,
  onConfirm,
  pie,
  pieChangesConfirmation,
  showApplicableLocations,
}: ConfirmationDialogProps) => {
  const { addToast } = useToast();

  const nameChanges = mapName(pieChangesConfirmation?.pieDetails);
  const descriptionChanges = mapDescription(pieChangesConfirmation?.pieDetails);

  const portfolioLinks =
    pie.__typename === 'UserPie' && pie.portfolioLinks.length
      ? pie.portfolioLinks
          .filter((link) => !link.isRootSlice)
          .map((link) => link)
      : [];

  const applicableLocations = showApplicableLocations
    ? mapApplicableLocations(portfolioLinks, pie.name)
    : null;

  const accountNickname = portfolioLinks.length
    ? `Acct: ${portfolioLinks[0].account.name}`
    : null;

  React.useEffect(() => {
    if (!pieChangesConfirmation) {
      addToast({
        content:
          'An Error occurred, while attempting to retrieve pie changes. Please contact support.',
        kind: 'alert',
        duration: 'short',
      });
    }
  }, [addToast, pieChangesConfirmation]);

  if (!pieChangesConfirmation) {
    return null;
  }

  return (
    <Modal open={active} onClose={onCancel}>
      <ModalContent width="wide">
        <Flex flexDirection="column">
          <Flex>
            <ConfirmationHeader
              accountName={accountNickname}
              pieName={pie.name}
            />
          </Flex>
          <Flex>
            <StyledOverflowFlex
              flexDirection="column"
              maxHeight={500}
              width="100%"
            >
              {applicableLocations && (
                <ConfirmationGroup
                  changes={applicableLocations}
                  message={pieChangesConfirmation.applicableLocationsMessage}
                  title="Applicable locations"
                />
              )}
              {pieChangesConfirmation.removed && (
                <ConfirmationGroup
                  changes={mapRemoved(pieChangesConfirmation.removed)}
                  message={pieChangesConfirmation.removed?.message}
                  title="Removed"
                />
              )}
              {pieChangesConfirmation.addedOrUpdated && (
                <ConfirmationGroup
                  changes={mapAddedOrUpdated(
                    pieChangesConfirmation.addedOrUpdated,
                  )}
                  message={pieChangesConfirmation.addedOrUpdated?.message}
                  title="Updates"
                />
              )}
              <ConfirmationGroup
                changes={nameChanges}
                headerLabel="Pie name"
                title="Pie Details"
              />
              <ConfirmationGroup
                changes={descriptionChanges}
                headerLabel="Pie Description"
                title={nameChanges.length ? null : 'Pie Details'}
              />
            </StyledOverflowFlex>
          </Flex>
        </Flex>
        <ButtonGroup behavior="centered" mt={32}>
          <Button
            label="Cancel"
            kind="secondary"
            size="medium"
            onClick={onCancel}
          />
          <Button
            label="Confirm"
            kind="primary"
            size="medium"
            onClick={onConfirm}
          />
        </ButtonGroup>
      </ModalContent>
    </Modal>
  );
};
