import { Button } from '@m1/liquid-react';
import * as React from 'react';

import { Pie, hasInactiveSlices, isPieTreeValid } from '~/pie-trees';

type EditorSaveButtonProps = {
  onClick: () => void;
  pieHasChanges?: boolean;
  rootPie: Pie | null | undefined;
};

export const EditorSaveButton = ({
  onClick,
  pieHasChanges,
  rootPie,
}: EditorSaveButtonProps) => {
  const isDisabled = React.useMemo(() => {
    return Boolean(
      !rootPie ||
        !isPieTreeValid(rootPie) ||
        hasInactiveSlices(rootPie) ||
        (pieHasChanges !== undefined && !pieHasChanges),
    );
  }, [rootPie, pieHasChanges]);

  return (
    <Button
      disabled={isDisabled}
      kind="inverse-primary"
      label="Save"
      onClick={() => onClick()}
    />
  );
};
