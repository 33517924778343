import { UniqueIdentifier } from '@dnd-kit/core';

import {
  IndividualHistoricalTree,
  PieEditorModelKind,
  TreeItems,
} from '~/pages/dashboard/wizards/pie-editor/PieEditor.types';
import { RemoteSecurity } from '~/pie-trees';

export const PIE_EDITOR_ACTIONS = {
  PIE_EDITOR_SET_INITIAL_PIE: 'PIE_EDITOR_SET_INITIAL_PIE',
  PIE_EDITOR_CLEAR_PIE: 'PIE_EDITOR_CLEAR_PIE',
  PIE_EDITOR_REMOVE_SLICE: 'PIE_EDITOR_REMOVE_SLICE',
  PIE_EDITOR_MOVE_SLICE: 'PIE_EDITOR_MOVE_SLICE',
  PIE_EDITOR_UPDATE_PERCENTAGE: 'PIE_EDITOR_UPDATE_PERCENTAGE',
  PIE_EDITOR_EQUALIZE_PIE: 'PIE_EDITOR_EQUALIZE_PIE',
  PIE_EDITOR_RENAME_PIE: 'PIE_EDITOR_RENAME_PIE',
  PIE_EDITOR_UPDATE_COLLAPSED: 'PIE_EDITOR_UPDATE_COLLAPSED',
  PIE_EDITOR_UPDATE_HISTORY_INDEX: 'PIE_EDITOR_UPDATE_HISTORY_INDEX',
  PIE_EDITOR_CONVERT_SYSTEM_PIE: 'PIE_EDITOR_CONVERT_SYSTEM_PIE',
  PIE_EDITOR_AUTO_FIX: 'PIE_EDITOR_AUTO_FIX',
  PIE_EDITOR_ADD_SLICES_START: 'PIE_EDITOR_ADD_SLICES_START',
  PIE_EDITOR_ADD_SLICES_COMPLETE: 'PIE_EDITOR_ADD_SLICES_COMPLETE',
  PIE_EDITOR_UPDATE_HISTORY_TREE: 'PIE_EDITOR_UPDATE_HISTORY_TREE',
} as const;

export type PieEditorUpdateHistoryTree = {
  type: typeof PIE_EDITOR_ACTIONS.PIE_EDITOR_UPDATE_HISTORY_TREE;
  payload: IndividualHistoricalTree;
};

export type PieEditorSetInitialPie = {
  payload: {
    treeItems: TreeItems;
    rootId: string | null;
    kind: PieEditorModelKind;
    uncollapse?: boolean;
    isCrypto: boolean;
  };
  type: typeof PIE_EDITOR_ACTIONS.PIE_EDITOR_SET_INITIAL_PIE;
};

export type PieEditorClearPie = {
  type: typeof PIE_EDITOR_ACTIONS.PIE_EDITOR_CLEAR_PIE;
};

export type PieEditorAddSlicesStart = {
  type: typeof PIE_EDITOR_ACTIONS.PIE_EDITOR_ADD_SLICES_START;
  payload: {
    parentId: UniqueIdentifier;
    meta: { pie: string };
  } | null;
};

export type PieEditorAddSlicesComplete = {
  type: typeof PIE_EDITOR_ACTIONS.PIE_EDITOR_ADD_SLICES_COMPLETE;
  payload: {
    parentId?: UniqueIdentifier;
    addToSelfPie?: boolean;
    slices: {
      id: string | undefined;
      isPie: boolean;
      symbolOrName: string;
      securityInfo?: Maybe<RemoteSecurity['securityInfo']>;
    }[];
  };
};

export type PieEditorRemoveSlice = {
  payload: { id: UniqueIdentifier };
  type: typeof PIE_EDITOR_ACTIONS.PIE_EDITOR_REMOVE_SLICE;
};

export type PieEditorMoveSlice = {
  payload: {
    items: TreeItems;
    activeId: UniqueIdentifier;
    overId: UniqueIdentifier;
    depth: number;
    parentId: UniqueIdentifier | null;
  };
  type: typeof PIE_EDITOR_ACTIONS.PIE_EDITOR_MOVE_SLICE;
};

export type PieEditorUpdatePercentage = {
  payload: {
    id: UniqueIdentifier;
    slice: string;
    fromPercentage: number | null | undefined;
    percentage: number;
  };
  type: typeof PIE_EDITOR_ACTIONS.PIE_EDITOR_UPDATE_PERCENTAGE;
};

export type PieEditorEqualizePie = {
  payload: { id: UniqueIdentifier; meta: { pie: string } };
  type: typeof PIE_EDITOR_ACTIONS.PIE_EDITOR_EQUALIZE_PIE;
};

export type PieEditorRenamePie = {
  payload: {
    id: UniqueIdentifier;
    name: string;
    meta: { old: string; new: string };
  };
  type: typeof PIE_EDITOR_ACTIONS.PIE_EDITOR_RENAME_PIE;
};

export type PieEditorUpdateCollapsed = {
  payload:
    | {
        id: UniqueIdentifier;
        actOnParent?: boolean;
        collapsed?: boolean;
        meta?: { pie?: string };
      }
    | {
        collapsed: boolean;
      };
  type: typeof PIE_EDITOR_ACTIONS.PIE_EDITOR_UPDATE_COLLAPSED;
};

export type PieEditorUpdateHistoryIndex = {
  payload: {
    index: number;
    isReset?: boolean;
  };
  type: typeof PIE_EDITOR_ACTIONS.PIE_EDITOR_UPDATE_HISTORY_INDEX;
};

export type PieEditorConvertSystemPie = {
  payload: {
    id: UniqueIdentifier;
    meta: { pie: string };
  };
  type: typeof PIE_EDITOR_ACTIONS.PIE_EDITOR_CONVERT_SYSTEM_PIE;
};

export type PieEditorAutoFix = {
  type: typeof PIE_EDITOR_ACTIONS.PIE_EDITOR_AUTO_FIX;
  payload: {
    enabled: boolean;
  };
};

export type PieEditorAction =
  | PieEditorUpdateHistoryTree
  | PieEditorAddSlicesStart
  | PieEditorAddSlicesComplete
  | PieEditorAutoFix
  | PieEditorClearPie
  | PieEditorConvertSystemPie
  | PieEditorEqualizePie
  | PieEditorMoveSlice
  | PieEditorRemoveSlice
  | PieEditorRenamePie
  | PieEditorSetInitialPie
  | PieEditorUpdateCollapsed
  | PieEditorUpdateHistoryIndex
  | PieEditorUpdatePercentage;
