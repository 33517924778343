import * as React from 'react';

import { Sliceable_Equity_Fragment } from '~/graphql/types';
import { useSelector } from '~/redux/hooks';
import { APP_MODES } from '~/static-constants';
import { Pill } from '~/toolbox/Pill';

import { BreadCrumb } from './BreadCrumb';

type StockBreadCrumbsProps = {
  stock: Sliceable_Equity_Fragment;
  navigable: boolean;
};

export const StockBreadCrumbs = ({
  stock,
  navigable,
}: StockBreadCrumbsProps) => {
  const mode = useSelector((state) => state.mode);
  const toLink =
    mode === APP_MODES.ADD ? '/d/c/add-slices/stocks' : '/d/research/stocks';

  const profileSector = stock.profile?.sector;
  const profileIndustry = stock.profile?.industry;

  return (
    <Pill.Group>
      <BreadCrumb
        label="Stocks"
        {...(navigable && { linkProps: { to: toLink } })}
      />

      {profileSector && (
        <BreadCrumb
          label={profileSector}
          {...(navigable && {
            linkProps: {
              to: toLink,
              query: {
                filterCategory: [profileSector],
              },
            },
          })}
        />
      )}
      {profileSector && profileIndustry && (
        <BreadCrumb
          label={profileIndustry}
          {...(navigable && {
            linkProps: {
              to: toLink,
              query: {
                filterCategory: [profileSector, profileIndustry],
              },
            },
          })}
        />
      )}
    </Pill.Group>
  );
};
