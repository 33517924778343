import React from 'react';

import { onScreenerSortChange } from '~/components/research/Screener/utils';
import { useCryptoScreenerQuery } from '~/graphql/hooks';
import {
  CryptoSortTypeEnum,
  HistoricalQuotePeriodEnum,
  SortDirectionEnum,
} from '~/graphql/types';

import { CryptoScreenerQueryResultContextValue } from './types';

const CryptoScreenerQueryResultContext = React.createContext(
  {} as CryptoScreenerQueryResultContextValue,
);

type CryptoScreenerProviderProps = {
  children: React.ReactNode;
};
export const CryptoScreenerProvider = ({
  children,
}: CryptoScreenerProviderProps) => {
  const queryResult = useCryptoScreenerQuery({
    variables: {
      first: 100,
      after: null,
      period: HistoricalQuotePeriodEnum.OneYear,
      sort: {
        direction: SortDirectionEnum.Desc,
        type: CryptoSortTypeEnum.MarketCap,
      },
    },
  });

  const handlePeriodChange = (period: HistoricalQuotePeriodEnum) => {
    queryResult.refetch({
      period,
    });
  };

  const handleSortChange = (sortType: CryptoSortTypeEnum) => {
    queryResult.refetch({
      sort: onScreenerSortChange(queryResult.variables?.sort, sortType),
    });
  };

  return (
    <CryptoScreenerQueryResultContext.Provider
      value={{
        handlePeriodChange,
        handleSortChange,
        queryResult,
      }}
    >
      {children}
    </CryptoScreenerQueryResultContext.Provider>
  );
};
export const useCryptoScreenerResult = () => {
  const queryResult = React.useContext(CryptoScreenerQueryResultContext);
  if (!queryResult) {
    throw new Error(
      'useCryptoScreenerResult must be used within a CryptoScreenerProvider',
    );
  }
  return queryResult;
};
