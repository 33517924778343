import { SagaIterator, eventChannel } from 'redux-saga';
import { call, put, spawn, takeEvery } from 'redux-saga/effects';

import { AuthManager } from '~/auth';

export function* authSaga(auth: AuthManager): SagaIterator<void> {
  const channel = eventChannel((emitter) => {
    // @ts-expect-error - TS7006 - Parameter 'obj' implicitly has an 'any' type.
    const loaded = (obj) => emitter(obj);
    // @ts-expect-error - TS7006 - Parameter 'obj' implicitly has an 'any' type.
    const updated = (obj) => emitter(obj);
    auth.on('loaded', loaded);
    auth.on('updated', updated);

    return () => {
      auth.off('loaded', loaded);
      auth.off('updated', updated);
    };
  });

  yield spawn(function* (): SagaIterator<void> {
    yield takeEvery(channel, function* (obj: unknown): SagaIterator<void> {
      yield put({
        type: 'AUTH_SESSION_UPDATED',
        payload: obj,
      });
    });
  });

  yield call([auth, 'load']);
}
