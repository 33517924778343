import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { CreditApplicationDocument } from '~/graphql/hooks';

import { apolloQuerySaga } from '../../apolloQuerySaga';

export type ApplicantInformation = {
  applicationId: string;
  applicationStatus: string;
  creditAccountId: string;
  hasActiveCreditCardAccount: boolean;
};

export function* fetchCreditApplicationQuery(): SagaIterator<
  ApplicantInformation | null | undefined
> {
  try {
    const { data } = yield call(apolloQuerySaga, {
      query: CreditApplicationDocument,
    });

    return {
      applicationId: data?.viewer?.credit?.activeApplication?.id,
      applicationStatus: data?.viewer?.credit?.activeApplication?.status,
      hasActiveCreditCardAccount: data?.viewer?.credit.hasActiveAccount,
      creditAccountId: data?.viewer?.credit.activeAccount?.id,
    };
  } catch (e: any) {
    throw new Error(`error fetchUserCreditCardApplicationData: ${e}`);
  }
}
