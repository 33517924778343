export const MODALS = {
  ARCHIVE_PIE: 'ARCHIVE_PIE' as const,
  CASH_CONTROL: 'CASH_CONTROL' as const,
  INACTIVITY_WARNING: 'INACTIVITY_WARNING' as const,
  LOGIN_OR_REGISTER: 'LOGIN_OR_REGISTER' as const,
  LIQUIDATION: 'LIQUIDATION' as const,
  ORDER_ACTION_CANCEL: 'ORDER_ACTION_CANCEL' as const,
  ORDER_ACTION_LOCKED: 'ORDER_ACTION_LOCKED' as const,
  POSITION_PORTFOLIO_LINKS:
    'POSITION_PORTFOLIO_LINKS' as const,
  REACCEPT_LEGAL_TERMS: 'REACCEPT_LEGAL_TERMS' as const,
  REBALANCE_ACTION_CANCEL:
    'REBALANCE_ACTION_CANCEL' as const,
  REBALANCE_ACTION_LOCKED:
    'REBALANCE_ACTION_LOCKED' as const,
  REBALANCE_ACTION_SET: 'REBALANCE_ACTION_SET' as const,
  REBALANCE_PORTFOLIO: 'REBALANCE_PORTFOLIO' as const,
  RESEARCH_ANNOUNCEMENT: 'RESEARCH_ANNOUNCEMENT' as const,
  SECURITY_STATUS: 'SECURITY_STATUS' as const,
  SELECT_INVEST_ACCOUNT: 'SELECT_INVEST_ACCOUNT' as const,
  SHARE_PIE: 'SHARE_PIE' as const,
  TRADE_DISCLOSURES: 'TRADE_DISCLOSURES' as const,
  TRANSFER_SCHEDULE: 'TRANSFER_SCHEDULE' as const,
};
