import { Flex, HXS, LS, PXL, Card } from '@m1/liquid-react';
import * as React from 'react';

import type { ReceivedSecuritiesCardFragment } from '~/graphql/types';

import { AppImage } from '../../lens-toolbox/AppImage';
import { LinkableButton } from '../../lens-toolbox/LinkableButton';

export type UnmanagedHoldingsCardProps = {
  receivedSecuritiesCard: ReceivedSecuritiesCardFragment;
};

export const UnmanagedHoldingsCard = ({
  receivedSecuritiesCard,
}: UnmanagedHoldingsCardProps) => {
  const { header, icon, link, sections } = receivedSecuritiesCard;

  return (
    <Card
      alignItems="center"
      as={Flex}
      backgroundColor="backgroundNeutralMain"
      borderColor="foregroundNeutralTertiary"
      justifyContent="space-between"
      padding={20}
    >
      <Flex inline flexDirection="column">
        <Flex alignItems="center">
          {icon && <AppImage appImage={icon} height={42} width={42} />}
          <HXS ml={16}>{header}</HXS>
        </Flex>
        <Flex ml={16} pt={8} pl={42}>
          {sections &&
            sections.map((section, index) => (
              <Flex
                flexDirection="column"
                key={index}
                mr={index !== sections.length - 1 ? 64 : 0}
              >
                <LS color="foregroundNeutralSecondary" mb={8}>
                  {section?.label}
                </LS>
                <PXL fontWeight={600}>{section?.value || '--'}</PXL>
              </Flex>
            ))}
        </Flex>
      </Flex>
      <Flex inline justifyContent="flex-end">
        {link && <LinkableButton linkable={link} kind="primary" size="large" />}
      </Flex>
    </Card>
  );
};
