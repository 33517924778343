import { Box, Flex, HXS, PL, Card } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { TransferParticipantCell } from '~/components/transfers/TransferParticipantCell';

import { useSelectFocusAccountQuery } from '~/graphql/hooks';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

type SelectFocusAccountProps = {
  focusOptionId: string;
  onFinishStep: (participantId: string) => void;
};

export const SelectFocusAccount = ({
  onFinishStep,
}: SelectFocusAccountProps) => {
  const focusOptionId = useSelector(
    (state) => state.newFlows.CREATE_SMART_TRANSFER.focusOptionId,
  );
  const { data, loading } = useSelectFocusAccountQuery({
    variables: {
      focusOptionId: focusOptionId as string,
    },
    skip: !focusOptionId,
  });

  if (loading) {
    return <Spinner />;
  }

  if (
    data?.node?.__typename !== 'NewSmartTransferFocusOption' ||
    !data.node.focusParticipants
  ) {
    return <GenericSystemError />;
  }

  const focusParticipants = data.node.focusParticipants;

  const readAccountTypeName = () => {
    if (!focusParticipants.list || focusParticipants.list.length === 0) {
      return null;
    }
    const firstAccountType = focusParticipants.list[0].transferParticipantType;
    const areAllAccountsSameType = focusParticipants.list.every(
      ({ transferParticipantType }) =>
        transferParticipantType === firstAccountType,
    );
    if (!areAllAccountsSameType) {
      // if we have multiple different types of accounts, keep copy generic.
      return null;
    }
    switch (firstAccountType) {
      case 'BORROW':
        return 'Borrow';
      case 'INVEST':
        return 'Invest';
      case 'SPEND':
        return 'Spend Checking';
      default:
        return null;
    }
  };

  const accountTypeName = readAccountTypeName();
  return (
    <Box maxWidth={490} mx="auto" pt={100}>
      <BackArrow
        content="Select Smart Transfer Type"
        mb={16}
        to="/d/c/create-smart-transfer/initialize-smart-transfer"
      />
      <HXS
        content={
          accountTypeName
            ? `Select ${accountTypeName} Account`
            : 'Select Account'
        }
      />
      <PL
        content="Select the account you want as the focus for your Smart Transfer"
        my={24}
      />
      {focusParticipants?.list?.map((transferParticipant) => {
        // TODO: ask design if there should be a hover state here
        return (
          <Card
            key={transferParticipant.id}
            onClick={() => {
              onFinishStep(transferParticipant.id);
            }}
            my={16}
            p="12px 16px"
          >
            <Flex alignItems="center" justifyContent="space-between">
              <TransferParticipantCell
                includeDescription
                transferParticipant={transferParticipant}
              />
              <Icon name="caretRight16" color="foregroundNeutralSecondary" />
            </Flex>
          </Card>
        );
      })}
    </Box>
  );
};
