import { Flex, HXS } from '@m1/liquid-react';
import * as React from 'react';

import { Sticky } from '~/components/sticky';

export const MarketTrendsSection = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <Sticky>
      <Flex mb={16} flexDirection="column" mr={16}>
        <HXS pb={16} pt={16}>
          Markets
        </HXS>
        <Flex flexDirection="column">{children}</Flex>
      </Flex>
    </Sticky>
  );
};
