import React from 'react';

import { useSliceableClick } from '~/hooks/useSliceableClick';
import { Section } from '~/toolbox/section';

import { SliceList } from '../SliceList/SliceList';

import { usePieDetailsContext } from './hooks/usePieDetailsContext';

export const PieSliceTable = () => {
  const { pie, isAccountSetup, onClickSlice } = usePieDetailsContext();
  const onClick = useSliceableClick();

  // Account setup should not allow slice clicking or cursor: pointer
  const handleClickOnSlice = isAccountSetup
    ? undefined
    : (sliceableId: string) => {
        if (onClickSlice) {
          onClickSlice(sliceableId);
        } else {
          onClick(sliceableId);
        }
      };

  const sortedSlices = pie?.slices?.slice().sort((a, b) => {
    if (a.percentage !== b.percentage) {
      return a.percentage < b.percentage ? 1 : -1;
    }

    // Percentages are equal, so compare names
    const aName = a.to.name?.toLowerCase();
    const bName = b.to.name?.toLowerCase();
    if (aName && bName && aName !== bName) {
      return aName > bName ? 1 : -1;
    }

    // Names are equal, so compare IDs
    const aId = a.to.id;
    const bId = b.to.id;
    if (aId && bId) {
      return aId < bId ? 1 : -1;
    }

    return 0;
  });

  return (
    <Section>
      <Section.Header label={`Slices (${pie?.slices.length})`} />
      <SliceList
        handleClickOnSlice={handleClickOnSlice}
        slices={sortedSlices || []}
      />
    </Section>
  );
};
