import { Button } from '@m1/liquid-react';
import isBoolean from 'lodash-es/isBoolean';
import * as React from 'react';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';

import { ControlledDropdown } from '~/components/form/ControlledDropdown';

import { ControlledInput } from '~/components/form/ControlledInput';
import { TruncateStringContent } from '~/components/TruncateStringContent';

import { BeneficiaryInfoFragment } from '~/graphql/types';
import { DropdownOption } from '~/toolbox/Dropdown';
import { GridTable } from '~/toolbox/grid-table';

const beneficiaryTypeOptions: DropdownOption[] = [
  {
    label: 'Primary',
    value: 'primary',
  },
  {
    label: 'Contingent',
    value: 'contingent',
  },
];

type BeneficiaryRowProps<T extends FieldValues> = {
  name: Path<T>;
  beneficiary: BeneficiaryInfoFragment;
  formMethods: UseFormReturn<T>;
  onEditBeneficiaryInfo: () => void;
  onRemoveBeneficiary: () => void;
  onRestoreBeneficiary: () => void;
  isRemoved: boolean;
};

export const BeneficiaryRow = <T extends FieldValues>({
  name,
  beneficiary,
  formMethods,
  onEditBeneficiaryInfo,
  onRemoveBeneficiary,
  onRestoreBeneficiary,
  isRemoved,
}: BeneficiaryRowProps<T>) => {
  const { lineOne, lineTwo, city, stateOrProvince, postalCode } =
    beneficiary.address;
  const address = `${lineOne}${
    lineTwo ? ` ${lineTwo},` : ','
  } ${city}, ${stateOrProvince} ${postalCode}`;

  const isPrimary = React.useMemo(() => {
    if (beneficiary.isPrimary === true) {
      return 'primary';
    }

    if (isBoolean(beneficiary.isPrimary)) {
      return 'contingent';
    }

    return null;
  }, [beneficiary.isPrimary]);

  return (
    <GridTable.Row>
      <GridTable.Cell>
        <Button
          kind="link"
          label={`${beneficiary.firstName} ${beneficiary.lastName}`}
          style={{
            textDecoration: isRemoved ? 'line-through' : 'none',
          }}
          onClick={onEditBeneficiaryInfo}
        />
      </GridTable.Cell>
      <GridTable.Cell>
        <TruncateStringContent
          style={{ textDecoration: isRemoved ? 'line-through' : undefined }}
        >
          {address}
        </TruncateStringContent>
      </GridTable.Cell>
      <GridTable.Cell>
        {/* Uncontrolled dropdown because this field should be a checkbox, but is a dropdown */}
        <ControlledDropdown
          name={`${name}.isPrimary` as Path<T>}
          control={formMethods.control}
          options={beneficiaryTypeOptions}
          value={isPrimary}
          disabled={isRemoved}
          size="small"
          transformValue={(value) => (value ? 'primary' : 'contingent')}
          transformInput={(value) => value === 'primary'}
        />
      </GridTable.Cell>
      <GridTable.Cell>
        <ControlledInput
          name={`${name}.sharePercentage` as Path<T>}
          control={formMethods.control}
          disabled={isRemoved}
          size="small"
          rules={{
            pattern: {
              message: 'Value must be between 0-100',
              value: /\d{3}/,
            },
            min: 0,
            max: 100,
          }}
          // For some reason, this needs to be 4 to allow 100%
          maxLength={4}
          maskType="percent"
          mask={{
            decimalScale: 0,
          }}
        />
      </GridTable.Cell>
      <GridTable.Cell>
        <Button
          kind="link"
          onClick={isRemoved ? onRestoreBeneficiary : onRemoveBeneficiary}
          label={isRemoved ? 'Restore' : 'Remove'}
        />
      </GridTable.Cell>
    </GridTable.Row>
  );
};
