import * as React from 'react';

import { useFeedbackFormQuery } from '~/graphql/hooks';
import { Grid } from '~/toolbox/grid';

import { UserFeedbackForm } from './components/FeedbackForm';
import { FeedbackFormDescription } from './components/FeedbackFormDescription';

export const FeedbackFormPage = () => {
  const { data } = useFeedbackFormQuery();
  const showForm = data?.viewer.feedbackForm?.showFeedbackForm;

  if (!showForm) {
    return null;
  }

  return (
    <Grid.Row>
      <Grid.Col>
        <FeedbackFormDescription />
      </Grid.Col>
      <Grid.Col>
        <UserFeedbackForm />
      </Grid.Col>
    </Grid.Row>
  );
};
