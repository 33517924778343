import { Flex, HXXS } from '@m1/liquid-react';
import * as React from 'react';

import { PaginationButtonGroup } from '~/components/PaginationButtonGroup';

import { TransfersCompletedTable } from '~/components/transfers/TransfersCompletedTable';
import { useTransfersPastInstancesQuery } from '~/graphql/hooks';
import { useApolloPrevNext } from '~/hooks/useApolloPrevNext';

export const TransfersPastInstancesTable = ({
  hasPendingInstances,
}: {
  hasPendingInstances: boolean;
}) => {
  const { data, loading, refetch } = useTransfersPastInstancesQuery({
    variables: {
      first: 15,
    },
  });
  const { handleNextPage, handlePreviousPage, hasPreviousPage } =
    useApolloPrevNext({ refetch });

  if (loading) {
    return null;
  }

  const pageInfo = data?.viewer.transfers?.pastInstances?.pageInfo;
  return (
    <>
      <Flex
        alignItems="flex-end"
        justifyContent="space-between"
        mt={hasPendingInstances ? 32 : 0}
        mb={16}
      >
        <HXXS
          content="Completed transfers"
          fontWeight={300}
          color="foregroundNeutralSecondary"
        />
        <PaginationButtonGroup
          handleNextPage={() => handleNextPage(pageInfo?.endCursor ?? null)}
          handlePreviousPage={handlePreviousPage}
          hasPreviousPage={hasPreviousPage}
          hasNextPage={pageInfo?.hasNextPage ?? false}
        />
      </Flex>
      <TransfersCompletedTable
        emptyMessage={
          data?.viewer.transfers
            ? 'You have no completed transfers.'
            : 'Error fetching your completed transfers. Please try again later.'
        }
        routeToNavigateToOnFinish="/d/transfers"
        transferInstanceConnection={data?.viewer.transfers?.pastInstances}
        transferInstancePillDisplayFlags={{
          isFailed: true,
          isCanceled: true,
        }}
        style={{
          marginTop: 8,
        }}
      />
    </>
  );
};
