import { Pie, RemotePie, RemoteSlice, Slice } from './types';

/**
 * A helper function that takes an array of slices and filters out
 * slices with zero percents before mapping them to a remote slice
 * @param {*} slices - The array of slices to map
 * @returns - An array of remote slices
 */
const filterAndPrepareSlices = (slices: Array<Slice>): Array<RemoteSlice> => {
  const finalSlices = [];

  for (const slice of slices) {
    if (slice.percentage > 0) {
      finalSlices.push(prepareSlice(slice));
    }
  }

  return finalSlices;
};

/**
 * A helper utility that takes a pieTree and strips unwanted fields out
 * and removes zero percent slices to prepare it for use in the updatePieTree mutation.
 * @param {*} pie - The pieTree to prepare for updating
 * @returns - A remote pie
 */
export const preparePieTreeForUpdate = (pie: Pie): RemotePie =>
  pie.type === 'old_pie'
    ? {
        id: pie.__id ?? pie.id,
        description: pie.description,
        name: pie.name,
        type: 'old_pie',
        slices: pie.slices ? filterAndPrepareSlices(pie.slices) : null,
      }
    : {
        name: pie.name,
        description: pie.description,
        type: 'new_pie',
        slices: pie.slices ? filterAndPrepareSlices(pie.slices) : [],
      };

/**
 * A Helper function that takes a local Slice and maps it to a RemoteSlice
 * @param {*} slice - The slice to map
 * @returns - A remote slice
 */
const prepareSlice = (slice: Slice): RemoteSlice => {
  switch (slice.to.type) {
    case 'security':
      return {
        percentage: slice.percentage,
        to: {
          id: slice.to.id,
          type: 'security',
        },
      };
    case 'new_pie':
      return {
        percentage: slice.percentage,
        to: {
          name: slice.to.name,
          description: slice.to.description,
          slices: slice.to.slices
            ? filterAndPrepareSlices(slice.to.slices)
            : [],
          type: 'new_pie',
        },
      };
    case 'old_pie':
      // TODO: once clicking into sub pies is reenabled we
      // will have to update this to account for user pies.
      // So a user can update the name and descriptions and
      // slices of their sub pies.
      return {
        percentage: slice.percentage,
        to: {
          id: slice.to.id,
          type: 'old_pie',
          slices: null,
        },
      };
    default:
      throw new Error('Unhandled slice type.');
  }
};
