import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { PersonalLoansRoute } from '~/pages/dashboard/borrow/personal';

import { BorrowPage } from './BorrowPage';
import { BorrowSplashPage } from './BorrowSplashPage';
import { MarginRoute } from './margin';

import { BorrowMarketingRoute } from './marketing';

export const BorrowRoute = () => {
  return (
    <Route path="borrow" element={<BorrowPage />}>
      {PersonalLoansRoute()}
      {BorrowMarketingRoute()}
      {MarginRoute()}
      <Route index element={<BorrowSplashPage />} />
      <Route path="/d/borrow/*" element={<Navigate to="/d/borrow" replace />} />
    </Route>
  );
};
