import { LS, Flex } from '@m1/liquid-react';
import * as React from 'react';

import { readPieTreeByPath, Slice } from '~/pie-trees';
import { useDispatch, useSelector } from '~/redux/hooks';
import { getActivePie } from '~/redux/reducers/newFlows/reducers/portfolioEditorReducer';
import { Checkbox } from '~/toolbox/checkbox';
import { toArray } from '~/utils';

type SelectorResponseShape = {
  allSliceIds: Array<string>;
  selectedSliceIds: Array<string>;
};

export const SelectAllCheckbox = () => {
  const dispatch = useDispatch();

  const [isSelectAllChecked, setIsSelectAllChecked] = React.useState(false);

  const { allSliceIds, selectedSliceIds } = useSelector<SelectorResponseShape>(
    (state) => {
      const { path } = state.newFlows.PORTFOLIO_EDITOR;
      const pieTree = getActivePie(state.newFlows.PORTFOLIO_EDITOR);

      const { slices } = pieTree
        ? readPieTreeByPath(pieTree, path)
        : { slices: [] };

      const validSlices = toArray<Slice[]>(slices);

      const allSliceIds = validSlices.map((slice) => slice.to.__id);
      const selectedSliceIds = validSlices.reduce<string[]>(
        (result, { to }) => {
          if (to.__checked) {
            result.push(to.__id);
          }
          return result;
        },
        [],
      );

      return {
        allSliceIds,
        selectedSliceIds,
      };
    },
  );

  const areAllSlicesChecked = Boolean(
    selectedSliceIds.length && selectedSliceIds.length === allSliceIds.length,
  );

  const onSelectAllCheckboxChange = React.useCallback(() => {
    if (areAllSlicesChecked) {
      dispatch({
        type: 'REMOVE_PORTFOLIO_EDITOR_ACTIVE_SLICE_IDS',
        payload: {
          ids: allSliceIds,
        },
      });
    } else {
      dispatch({
        type: 'UPDATE_PORTFOLIO_EDITOR_ACTIVE_SLICE_IDS',
        payload: {
          ids: allSliceIds,
        },
      });
    }

    setIsSelectAllChecked(!isSelectAllChecked);
  }, [areAllSlicesChecked, isSelectAllChecked, dispatch, allSliceIds]);

  return (
    <Flex ml={4}>
      <Checkbox
        checked={areAllSlicesChecked}
        onChange={onSelectAllCheckboxChange}
        label={<LS content="Select all" pl={8} />}
        size="large"
      />
    </Flex>
  );
};
