import { LS } from '@m1/liquid-react';
import classNames from 'classnames';
import * as React from 'react';

import style from './style.module.scss';

type SortDirectionProp = 'ASC' | 'asc' | 'DESC' | 'desc';
type SortDirection = 'asc' | 'desc';
export type TableHeaderCellProps = {
  align?: 'left' | 'center' | 'right';
  children?: React.ReactNode;
  colSpan?: number;
  content?: React.ReactNode;
  onClick?: (...args: Array<any>) => any;
  sort?: SortDirectionProp | null | undefined;
  width?: string | number;
};

export class TableHeaderCell extends React.Component<TableHeaderCellProps> {
  static defaultProps = {
    align: 'left',
    colSpan: 1,
    width: 'auto',
  };

  render() {
    const { align, children, content, width } = this.props;
    const sort = this.readSort();

    const classes = classNames(style.tableHeader, {
      [style.click]: Boolean(this.props.onClick),
      [style.alignLeft]: align === 'left',
      [style.alignCenter]: align === 'center',
      [style.alignRight]: align === 'right',
    });

    return (
      <th
        className={classes}
        colSpan={this.props.colSpan}
        // @ts-expect-error - TS2322 - Type '(e: Event) => void' is not assignable to type 'MouseEventHandler<HTMLTableHeaderCellElement>'.
        onClick={this.handleClick}
        style={{
          width,
        }}
      >
        {sort && align === 'right' && this.renderSortArrow(sort)}
        {content || children}
        {sort && align !== 'right' && this.renderSortArrow(sort)}
      </th>
    );
  }

  renderSortArrow(sort: SortDirection) {
    const arrow = sort === 'desc' ? '▼' : '▲';
    const key = {
      style: {
        marginRight: this.props.align === 'right' ? 4 : undefined,
        marginLeft: this.props.align === 'left' ? undefined : 4,
      },
    };
    return <LS content={arrow} color="foregroundNeutralSecondary" {...key} />;
  }

  handleClick = (e: Event): void => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  readSort(): SortDirection | null | undefined {
    const { sort } = this.props;
    // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'SortDirection | null | undefined'.
    return sort && sort.toLowerCase();
  }
}
