import { Box, Flex, HM, styled } from '@m1/liquid-react';
import * as React from 'react';

import { SecurityPageNotifications } from '~/components/notifications';
import { TwoFactorWizard } from '~/pages/dashboard/settings/security/components/TwoFactorWizard';

import { ChangePasswordForm } from './components/ChangePasswordForm';
import { TwoFactorAuthForm } from './components/TwoFactorAuthForm';

const StyledFlex = styled(Flex)`
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const SettingsSecurityPage = () => {
  const [showEnhancedAuthFlow, setShowEnhancedAuthFlow] = React.useState(false);

  const handleTwoFactorAuthInitialization = () => {
    setShowEnhancedAuthFlow(true);
  };

  if (showEnhancedAuthFlow) {
    return (
      <TwoFactorWizard
        exitFlow={() => {
          setShowEnhancedAuthFlow(false);
        }}
      />
    );
  }

  return (
    <Box>
      <SecurityPageNotifications />
      <StyledFlex px={8}>
        <Box flex="1 1 50%" mx={8} mb={24}>
          <HM fontWeight={300} content="Change password" />
          <ChangePasswordForm />
        </Box>

        <Box flex="1 1 50%" pl={32}>
          <TwoFactorAuthForm
            onInitializeAttempt={handleTwoFactorAuthInitialization}
          />
        </Box>
      </StyledFlex>
    </Box>
  );
};
