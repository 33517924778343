import { Flex, Box, useTheme } from '@m1/liquid-react';
import * as React from 'react';

import { Pie } from '~/pie-trees';
import { GridTable } from '~/toolbox/grid-table';
import {
  generateSliceFillColorsArray,
  pickSliceFillColor,
} from '~/utils/slices';

import { EditorFeature } from '../../PortfolioEditor.types';

import { SelectAllCheckbox } from './SelectAllCheckbox';
import { SliceableListItem } from './SliceableListItem';

export type SliceablesListProps = {
  allowZeroPercentSlices?: boolean;
  disabledFeatures?: Array<EditorFeature>;
  pie: Pie;
};

export const SliceablesList = ({
  allowZeroPercentSlices,
  disabledFeatures,
  pie,
}: SliceablesListProps) => {
  const theme = useTheme();

  if (!pie.slices) {
    return null;
  }
  const slices = pie.slices;

  const hideCheckbox =
    disabledFeatures?.includes('DELETE_SLICES') &&
    disabledFeatures?.includes('MOVE_SLICES');

  const colors = generateSliceFillColorsArray(
    theme.pieSliceColors,
    slices.length,
  );

  return (
    <Box
      maxHeight={500}
      style={{
        overflow: 'auto',
      }}
    >
      <GridTable
        emptyMessage="Slices will appear here"
        gridTemplateColumns="70% auto"
      >
        <GridTable.HeaderRow>
          <GridTable.HeaderCell
            label={hideCheckbox ? null : <SelectAllCheckbox />}
          />
          <Flex display="inline-flex" justifyContent="flex-end" pr={40}>
            <GridTable.HeaderCell align="right" label="Target (%)" />
          </Flex>
        </GridTable.HeaderRow>

        {slices.map((slice, index) => {
          const sliceColor = pickSliceFillColor(colors, index, false);

          return (
            <SliceableListItem
              allowZeroPercentSlices={allowZeroPercentSlices}
              disabledFeatures={disabledFeatures}
              key={index}
              sliceColor={sliceColor}
              slice={slice}
            />
          );
        })}
      </GridTable>
    </Box>
  );
};
