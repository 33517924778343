import { styled, css, Box, theme } from '@m1/liquid-react';
import * as React from 'react';

import { GridContext } from './context';

export type GridTableHeaderRowProps = React.PropsWithChildren<{
  sticky?: boolean;
  style?: Record<string, any>;
  isNewStyle?: boolean;
  isVirtualized?: boolean;
}>;

const newStyleCss = css`
  grid-template-rows: 32px;
  padding: 0 2px;
  border-bottom: 1px solid ${theme.colors.borderMain};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.XXSMALL}) {
    grid-column-gap: 24px;
  }
`;

const oldStyleCss = css`
  grid-template-rows: 42px;
  padding: 0 8px;

  background: ${theme.colors.backgroundNeutralTertiary};
  border: 1px solid ${theme.colors.borderMain};
  border-radius: 10px 10px 0 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.XXSMALL}) {
    grid-column-gap: 16px;
    padding: 0 16px;
  }
`;

export const StyledGridTableHeaderRow = styled(Box)<
  GridTableHeaderRowProps & {
    gridTemplateColumns: string;
  }
>`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  grid-column-gap: 8px;

  ${({ isNewStyle }) => (isNewStyle ? newStyleCss : oldStyleCss)}

  ${({ isVirtualized }) =>
    isVirtualized &&
    css`
      padding-left: 16px;
      padding-right: 24px;
      @media screen and (min-width: ${({ theme }) =>
          theme.breakpoints.XXSMALL}) {
        padding-left: 16px;
        padding-right: 24px;
      }
    `}

  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      top: -2px;
      z-index: 1;
    `};
`;

export const GridTableHeaderRow = ({
  children,
  sticky = false,
  ...props
}: GridTableHeaderRowProps) => {
  const { gridTemplateColumns, isNewStyle, isVirtualized } =
    React.useContext(GridContext);
  return (
    <StyledGridTableHeaderRow
      {...props}
      {...{
        gridTemplateColumns,
        sticky,
        isNewStyle,
        isVirtualized,
      }}
    >
      {children}
    </StyledGridTableHeaderRow>
  );
};
