import {
  FinishedEditingSmartTransferAction,
  FinishedEditingTransferScheduleAction,
  SMART_TRANSFER_ACTIONS,
} from './smartTransferActions.types';

export const finishedEditingTransferSchedule = (
  payload: FinishedEditingTransferScheduleAction['payload'],
): FinishedEditingTransferScheduleAction => ({
  payload,
  type: SMART_TRANSFER_ACTIONS.FINISHED_EDITING_TRANSFER_SCHEDULE,
});

export const finishedEditingSmartTransfer = (
  payload: FinishedEditingSmartTransferAction['payload'],
): FinishedEditingSmartTransferAction => ({
  type: SMART_TRANSFER_ACTIONS.FINISHED_EDITING_SMART_TRANSFER,
  payload: payload,
});
