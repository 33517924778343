import { Flex, styled } from '@m1/liquid-react';
import React, { useRef } from 'react';

export type CodeInputProps = {
  value: string;
  onChange: (value: string) => void;
  error?: string;
};

const StyledFlex = styled(Flex)<{ $hasError: boolean }>`
  input {
    border-bottom: 1px solid
      ${({ theme }) => theme.colors.foregroundNeutralMain} !important;

    color: ${({ theme, $hasError }) => {
      return $hasError
        ? theme.colors.critical
        : theme.colors.foregroundNeutralMain;
    }};
    font-size: 28px;
    line-height: 1;
    margin-left: 4px;
    margin-right: 4px;
    padding: 4px;
    text-align: center;
    width: 40px;
    background-color: inherit;
  }
`;

export const CodeInput = ({ value, onChange, error }: CodeInputProps) => {
  const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(6).fill(null));

  const handleInputChange =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value.slice(-1); // Only take the last character typed
      const newValue = value.split('');

      // Update the specific digit
      newValue[index] = inputValue;

      // Join the array into the string and emit change
      const updatedValue = newValue.join('');
      onChange(updatedValue);

      // Move focus to the next input if a digit was added
      if (inputValue && index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    };

  const handleKeyDown =
    (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
      // Handle backspace
      if (e.key === 'Backspace' && !value[index] && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').slice(0, 6);
    onChange(pastedData);

    // Focus on the last filled input
    const focusIndex = pastedData.length < 6 ? pastedData.length : 5;
    inputRefs.current[focusIndex]?.focus();
  };

  return (
    <StyledFlex gap={8} $hasError={Boolean(error)}>
      {Array.from({ length: 6 }).map((_, index) => (
        <input
          key={index}
          data-testid={`code-input-${index + 1}`}
          type="text"
          maxLength={1}
          value={value[index] || ''}
          onChange={handleInputChange(index)}
          onKeyDown={handleKeyDown(index)}
          onPaste={index === 0 ? handlePaste : undefined}
          ref={(el) => (inputRefs.current[index] = el)}
        />
      ))}
    </StyledFlex>
  );
};
