import { HXS } from '@m1/liquid-react';
import React from 'react';

import { PercentGain } from '~/components/percent-gain';
import { Section } from '~/toolbox/section';

type PieGraphSectionHeaderProps = {
  valuePercentChange: number | null | undefined;
};

export const PieGraphSectionHeader = ({
  valuePercentChange,
}: PieGraphSectionHeaderProps) => (
  <Section.Header
    label="Performance"
    action={
      <HXS
        as="span"
        content={
          valuePercentChange ? <PercentGain value={valuePercentChange} /> : '--'
        }
      />
    }
  />
);
