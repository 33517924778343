import { HM, Flex, PL, PM, Box } from '@m1/liquid-react';
import * as React from 'react';

import { HelpPopup } from '~/components/HelpPopup';
import { ContactInfoFormModern } from '~/forms';
import {
  submittedContactInfoForm,
  submittedContactInfoFormModern,
} from '~/redux/actions';
import { useSelector, useDispatch } from '~/redux/hooks';
import { pickByPaths } from '~/utils';

type CollectContactInfoStepProps = {
  body?: React.ReactElement<any> | string;
  holder: 'primary' | 'secondary';
  showPhoneNumber?: boolean;
  skipAddressAutofill?: boolean;
  title: string;
};

export const CollectContactInfoStep = ({
  title,
  holder,
  body,
  showPhoneNumber,
  skipAddressAutofill,
}: CollectContactInfoStepProps) => {
  const dispatch = useDispatch();
  const savedValues = useSelector((state) => {
    return state.newFlows.IDENTITY_FIRST.input[holder];
  });

  const initialValues = pickByPaths(savedValues, [
    'firstName',
    'middleInitial',
    'lastName',
    'suffix',
    'homeAddress',
    'phoneNumber',
  ]);

  // @ts-expect-error - TS7006 - Parameter 'values' implicitly has an 'any' type.
  const handlePrimaryContactFormSubmission = (values) => {
    const primaryPayload = {
      holder,
      values: {
        primary: values,
      },
    };

    dispatch(submittedContactInfoFormModern(primaryPayload));
  };

  // @ts-expect-error - TS7006 - Parameter 'values' implicitly has an 'any' type.
  const handleSecondaryContactFormSubmission = (values) => {
    const secondaryPayload = {
      holder,
      values: {
        secondary: values,
      },
    };

    /*
     * TODO: update this to use the modern action once we update subsequent accounts.
     * dispatch(submittedContactInfoFormModern(secondaryPayload));
     */
    dispatch(submittedContactInfoForm(secondaryPayload));
  };

  const remoteDisclaimerTooltipContent = useSelector(
    (state) =>
      state.newFlows.IDENTITY_FIRST.onboardingQuestions.NAME_AND_ADDRESS
        ?.disclaimerTooltipContent,
  );

  const tooltipBody = (remoteDisclaimerTooltipContent || [])
    .map((content, index) => {
      return content ? <PM key={index}>{content}</PM> : null;
    })
    .filter(Boolean);

  return (
    <Box width={588} mx="auto">
      <Box mb={16} textAlign="center">
        <Flex alignItems="center">
          <HM content={title} />
        </Flex>
        {body && <PL content={body} mt={32} />}
      </Box>
      <ContactInfoFormModern
        autoFocus
        tooltip={tooltipBody.length ? <HelpPopup body={tooltipBody} /> : null}
        initialValues={initialValues}
        // @ts-expect-error - TS7006 - Parameter 'values' implicitly has an 'any' type.
        onSubmit={(values) => {
          if (holder === 'primary') {
            handlePrimaryContactFormSubmission(values);
          } else {
            handleSecondaryContactFormSubmission(values);
          }
        }}
        showPhoneNumber={showPhoneNumber}
        skipAddressAutofill={skipAddressAutofill}
      />
    </Box>
  );
};
