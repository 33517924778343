import * as React from 'react';
import { Route } from 'react-router-dom';

import { ActivateCreditCardPage } from './ActivateCreditCardPage';

export const ActivateCreditCardCover = () => {
  return (
    <>
      <Route
        path="activate-credit-card"
        element={<ActivateCreditCardPage />}
        handle={{
          fallbackRoute: {
            to: '/d/spend/credit/transactions',
          },
          navigationState: {
            forceRefetch: true,
          },
        }}
      />
    </>
  );
};
