import * as React from 'react';

import {
  useCreateAchRelationshipViaFundingSourceMutation,
  useCreateFundingSourceRelationshipMutation,
} from '~/graphql/hooks';
import { FundingSourceNodeFragment } from '~/graphql/types';
import { useLaunchDarkly } from '~/hooks/useLaunchDarkly';
import { useNavigate } from '~/hooks/useNavigate';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';

import { AvailableFundingSourceCard } from './AvailableFundingSourceCard';

type AvailableFundingSourceProps = {
  fundingSource: FundingSourceNodeFragment;
};

export const InvestAvailableFundingSource = ({
  fundingSource,
}: AvailableFundingSourceProps) => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const activeAccountId = useSelector((state) => state.global.activeAccountId);
  const { didSucceed, flagResult } = useLaunchDarkly(
    'create-funding-source-relationship',
    false,
  );

  const [createAchRelationshipViaFundingSource, { loading }] =
    useCreateAchRelationshipViaFundingSourceMutation();

  const [createFundingSourceRelationship, { loading: fundingSourceLoading }] =
    useCreateFundingSourceRelationshipMutation();

  usePortaledSpinner(loading || fundingSourceLoading);

  const mutationCompleted = () => {
    navigate({
      to: '/d/invest/fund-account',
    });
  };

  const mutationError = () => {
    addToast({
      content:
        'Something went wrong. Please try again or contact us for further assistance.',
      duration: 'short',
      kind: 'alert',
    });
    navigate({
      to: '/d/invest/bank-connection',
    });
  };

  const handleInvestFundingSource = (fundingSourceId: string) => {
    const variables = {
      input: {
        accountId: activeAccountId as string,
        fundingSourceId,
      },
    };

    if (didSucceed && flagResult === true) {
      createFundingSourceRelationship({
        variables,
        onCompleted: mutationCompleted,
        onError: mutationError,
      });
    } else {
      createAchRelationshipViaFundingSource({
        variables,
        onCompleted: mutationCompleted,
        onError: mutationError,
      });
    }
  };

  return (
    <AvailableFundingSourceCard
      handleActiveFundingSource={handleInvestFundingSource}
      fundingSource={fundingSource}
    />
  );
};
