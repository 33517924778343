import { LS } from '@m1/liquid-react';
import * as React from 'react';

import { NonNullablePieItem } from './types';

type SecondaryLabelProps = {
  pie: NonNullablePieItem;
};

export const PieItemSecondaryLabel = ({ pie }: SecondaryLabelProps) => {
  const rootSlices = pie.portfolioLinks?.filter((link) => link.isRootSlice);

  if (!rootSlices || !rootSlices.length) {
    return null;
  }

  return (
    <LS color="foregroundNeutralSecondary">
      {rootSlices[0].account.name || 'My Account'}
    </LS>
  );
};
