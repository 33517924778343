import { HXXS, Flex, Box, PL } from '@m1/liquid-react';
import * as React from 'react';
import { InjectedFormProps } from 'redux-form';

import { FormMocker } from '~/forms/FormMockers/FormMocker';
import { usePersonalLoansShouldAskForIncomeQuery } from '~/graphql/hooks';
import { connectForm } from '~/hocs';

import { formatCurrencyWithCommas } from '~/utils/formatting';

import { onlyNumbers } from '~/utils/normalizers';

import { TextField } from './fields';

type InitialValues = {
  annualIncome: number;
  employmentStatus: string;
  monthlyHousingCosts: number;
};

export type PersonalLoansFinancialInformationFormProps =
  InjectedFormProps<any> & {
    initialValues: InitialValues;
    handleSubmit: () => void;
  };

const housingValidator = (housing: number): string | null | undefined => {
  if (!housing) {
    return 'Please enter how much you spend on your rent or mortgage.';
  }
  return;
};

const incomeValidator = (income: number): string | null | undefined => {
  if (Number(income) > 99000000) {
    return 'Please enter a valid income';
  }
  if (!income) {
    return 'Please enter income before any taxes are taken out.';
  }
  return;
};

function getMockedFields(shouldAskForAnnualIncome: boolean) {
  const fields = [
    {
      name: 'monthlyHousingCosts',
      value: '300',
    },
  ];

  if (shouldAskForAnnualIncome) {
    fields.push({
      name: 'annualIncome',
      value: '150000',
    });
  }

  return fields;
}

const PersonalLoansFinancialInformationFormComponent = ({
  handleSubmit,
}: PersonalLoansFinancialInformationFormProps) => {
  const format = (
    value: number | null | undefined,
  ): string | null | undefined => {
    return value ? `$${formatCurrencyWithCommas(value.toString())}` : '';
  };

  const { data } = usePersonalLoansShouldAskForIncomeQuery();

  const shouldAskForAnnualIncome = Boolean(
    data?.viewer.borrow?.personalLoans?.applicationFlow
      ?.shouldAskForAnnualIncome,
  );

  return (
    <>
      <form id="personalLoansFinancialInformationForm" onSubmit={handleSubmit}>
        <Box mt={48}>
          {shouldAskForAnnualIncome && (
            <>
              <HXXS
                content="How much do you make before taxes each year?"
                fontWeight={600}
                mt={40}
                mb={12}
              />
              <PL content="Include wages, bonuses and other income. We’ll verify this information later on." />
              <Box width={384}>
                <TextField
                  format={format}
                  name="annualIncome"
                  label="Yearly income"
                  normalize={onlyNumbers}
                  validate={[incomeValidator]}
                  maxLength={11}
                  hint="Enter $0 if you have no income."
                />
              </Box>
            </>
          )}

          <Box mt={32} mb={shouldAskForAnnualIncome ? 0 : 256}>
            <HXXS
              mt={40}
              mb={8}
              fontWeight={600}
              content="How much is your mortgage or rent?"
            />
            <Flex>
              <Box width={384}>
                <TextField
                  format={format}
                  name="monthlyHousingCosts"
                  label="Monthly payment"
                  normalize={onlyNumbers}
                  validate={[housingValidator]}
                  maxLength={11}
                />
              </Box>
            </Flex>
          </Box>
        </Box>
      </form>
      <FormMocker
        formName="personal-loans-financial-information"
        fields={getMockedFields(shouldAskForAnnualIncome)}
        label="Mock this form!"
      />
    </>
  );
};

export const PersonalLoansFinancialInformationForm = connectForm({
  form: 'personal-loans-financial-information',
})(PersonalLoansFinancialInformationFormComponent);
