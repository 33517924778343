import { roundUpToNextPenny } from '~/utils/round';

export function validateWithholdingLessThanTransferAmount({
  stateWithholdingPercentage,
  federalWithholdingPercentage,
  amount,
}: {
  stateWithholdingPercentage: number | null | undefined;
  federalWithholdingPercentage: number | null | undefined;
  amount: number;
}): {
  isValid: boolean;
  stateWithholdingAmount: number;
  federalWithholdingAmount: number;
} {
  const stateWithholdingAmount = calculateWithholdingAmountInDollars({
    withholdingPercentage: stateWithholdingPercentage,
    amount,
  });

  const federalWithholdingAmount = calculateWithholdingAmountInDollars({
    withholdingPercentage: federalWithholdingPercentage,
    amount,
  });

  return {
    isValid: stateWithholdingAmount + federalWithholdingAmount <= amount,
    stateWithholdingAmount,
    federalWithholdingAmount,
  };
}

export function calculateWithholdingAmountInDollars({
  withholdingPercentage,
  amount,
}: {
  withholdingPercentage: number | null | undefined;
  amount: number;
}): number {
  const safePercentage = Number(withholdingPercentage) || 0;
  return roundUpToNextPenny((safePercentage / 100) * amount);
}
