import { Box, css, Flex, styled, useTheme } from '@m1/liquid-react';

import * as React from 'react';

import { SliceById } from '~/components/pie/SliceById';
import { EditModelSlice } from '~/pages/share/SharePiePage.types';
import { Sliceable } from '~/pie-trees';
import { isNewPie } from '~/pie-trees/sliceableHelpers';
import { VirtualRowProps } from '~/toolbox/virtualized/VirtualizedRows';
import { generateSliceFillColorsArray } from '~/utils';

import { SharePiePageUserPieSliceContent } from './SharePiePageUserPieSliceContent';

const StyledSliceColor = styled(Box)`
  width: 4px;
  height: calc(100% - 8px);
  pointer-events: none;
`;

const StyledSliceRow = styled(Flex)`
  align-items: center;
  display: flex;
  height: 62px;
  justify-content: space-between;
  padding: 8px 0px;
  position: relative;
  width: 100%;
  min-width: 0;

  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.borderMain};
  `}
`;

export const SharePiePageSliceListRow: React.FC<
  VirtualRowProps<
    EditModelSlice & { handleClickOnSlice?: (sliceId: string) => void }
  >
> = ({ index, style, data: slices }) => {
  const { pieSliceColors } = useTheme();
  const sliceFillColorsArray = generateSliceFillColorsArray(
    pieSliceColors,
    slices.length,
  );
  const slice = slices[index];
  const { percentage, to, handleClickOnSlice } = slice;

  const isSecurityOrModelPortfolio = to?.type === 'security' || 'old_pie';
  return (
    <StyledSliceRow key={index} css={style}>
      <StyledSliceColor
        css={{
          backgroundColor:
            sliceFillColorsArray[index % sliceFillColorsArray.length] &&
            sliceFillColorsArray[index % sliceFillColorsArray.length]['active'],
        }}
      />
      {isNewPie(to as Sliceable) ? (
        <SharePiePageUserPieSliceContent
          slice={slice}
          onClick={handleClickOnSlice}
        />
      ) : (
        <SliceById
          key={to.id}
          to={to}
          percentage={percentage}
          onClick={handleClickOnSlice}
          isClickable={!isSecurityOrModelPortfolio}
        />
      )}
    </StyledSliceRow>
  );
};
