import React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { useLocation } from '~/hooks/useLocation';
import { useSearchParams } from '~/hooks/useSearchParams';

import { useAcatWizardFormContext } from '../hooks/useAcatWizardFormContext';

const backArrowMapping: Record<string, { label: string; to: string }> = {
  'transfers': {
    label: 'Move Money',
    to: '/d/move-money',
  },
  'invest': {
    label: 'Invest',
    to: '/d/invest',
  },
};

export const AcatWizardBackButton = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { isFirstStep, back } = useAcatWizardFormContext();

  const previousRouteName =
    location.state?.previousRoute || searchParams.get('previousRouteName');

  const previousRouteProps = backArrowMapping[previousRouteName];

  const backArrowProps = React.useMemo(() => {
    if (!isFirstStep) {
      return { onClick: back, content: 'Back' };
    }

    if (previousRouteProps) {
      const { to, label } = previousRouteProps;
      return {
        content: label,
        to,
      };
    }
    return {
      content: 'Back',
      to: '/d/home',
    } as const;
  }, [isFirstStep, previousRouteProps, back]);

  return <BackArrow {...backArrowProps} caret />;
};
