import {
  Skeleton,
  SkeletonProvider,
  Box,
  Text,
  PM,
  Flex,
  styled,
  css,
  Button,
  FloatingPanel,
  ListItem,
  Tooltip,
} from '@m1/liquid-react';
import { Icon, MonochromaticIconName } from '@m1/liquid-react/icons';
import * as React from 'react';
import { followCursor } from 'tippy.js';

import { AssetLogo } from '~/components/pie/SliceableLogo';
import { DiscoverSearch } from '~/components/research';
import { useSliceableContentQuery } from '~/graphql/hooks';

import {
  SecurityStatusEnum,
  SliceableContentQuery,
  SliceableFragment,
  SliceableStatusEnum,
  SliceableTypeEnum,
} from '~/graphql/types';

import { useDelay } from '~/hooks/useDelay';
import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';

import { useDispatch, useSelector } from '~/redux/hooks';

import { Collapse } from '~/toolbox/collapse';
import { Divider } from '~/toolbox/divider';
import { Indicator } from '~/toolbox/indicator';

import { TooltipContainer } from '../../../PieEditor.styled';
import { HighlightEvent, ItemRenderProps } from '../../../PieEditor.types';
import { highlightTime, findItemDeep } from '../../../PieEditor.utils';
import { PercentageAdjustor } from '../TreeItem/PercentageAdjustor';

import { RenameablePieCell } from './RenameablePieCell';
import { SliceableResearch } from './SliceableResearch';

const SliceRow = styled(Flex)<{ $isAddSlice?: boolean }>`
  align-items: center;
  transition: background-color
    ${({ backgroundColor }) => (backgroundColor ? 0 : 1)}s;

  ${({ $isAddSlice }) =>
    $isAddSlice &&
    css`
      cursor: pointer;
    `}
`;

const MoreButtonContainer = styled(Box)`
  border-left: 1px solid ${({ theme }) => theme.colors.borderMain};
  padding-left: 8px;
`;

const MoreButton = styled(Button)`
  padding: 0;
  > span {
    margin-right: 0;
  }
`;

const PercentageContainerWidth = 184;

const PercentageContainer = styled(Box)`
  width: ${PercentageContainerWidth}px;
  text-align: center;
  input {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export const SliceableContent = ({ item, isDragging }: ItemRenderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { originalItems, currentTreeItems, isCryptoState } = useSelector(
    (state) => ({
      originalItems: state.pieEditor.history[0].tree,
      currentTreeItems:
        state.pieEditor.history[state.pieEditor.historyIndex].tree,
      isCryptoState: state.pieEditor.isCrypto,
    }),
  );
  const isRoot = currentTreeItems[0].id === item.id;
  const dispatch = useDispatch();
  const [showAddSlice, setShowAddSlice] = React.useState(false);
  const [showOptions, setShowOptions] = React.useState(false);
  const [showContextMenu, setShowContextMenu] = React.useState(false);
  const [showResearch, setShowResearch] = React.useState(false);
  const [showEditMode, setShowEditMode] = React.useState(false);
  const metaName = React.useRef(item.meta.name ?? 'slice');
  const [fade, setFade] = React.useState<{
    type: HighlightEvent['type'];
  } | null>(null);
  const { id, meta, percentage, children, allowsChildren } = item;
  const { data, loading } = useSliceableContentQuery({
    variables: {
      sliceableId: String(meta.id),
    },
  });

  const node = data?.node as Maybe<
    ExtractTypenameByKey<SliceableContentQuery['node'], 'portfolioLinks'>
  >;
  const isPie =
    (['UserPie', 'CryptoSystemPie', 'SystemPie'].includes(
      node?.__typename as any,
    ) &&
      children.length > 0) ||
    allowsChildren;
  const isStock = node?.__typename === 'Equity';
  const isFund = node?.__typename === 'Fund';
  const isCrypto = node?.__typename === 'CryptoAsset' || isCryptoState;
  const isAddSlice = meta.type === 'add';

  let name = meta.name ?? node?.name;
  metaName.current = node && 'symbol' in node ? node.symbol : (name ?? 'slice');

  const icon = React.useMemo(() => {
    if (meta.isSystemPie) {
      return <Icon name="m1Logo32" color="foregroundNeutralMain" />;
    } else if (isPie) {
      return <Icon name="createPie32" color="foregroundNeutralMain" />;
    } else if (isAddSlice) {
      return <Icon name="add20" color="foregroundNeutralMain" />;
    }

    if (node) {
      const profile = 'profile' in node ? node.profile : null;
      const symbol = 'symbol' in node ? node.symbol : null;
      // We should only have a security with a symbol at this point
      return symbol ? (
        <Box width={32} height={32}>
          {node && <AssetLogo profile={profile} symbol={symbol} />}
        </Box>
      ) : null;
    }
    // Return no icon if we don't know what we have or what to call it - should be rare if not impossible
    return null;
  }, [meta.isSystemPie, isPie, node, isAddSlice]);

  if (isAddSlice) {
    name = name ? `Add slice to ${name}` : name;
  }

  const handleAdd = (slice?: SliceableFragment) => {
    dispatch({
      type: 'PIE_EDITOR_ADD_SLICES_COMPLETE',
      payload: {
        parentId: item.id,
        slices: [
          {
            id: slice?.id,
            isPie: slice?.__typename === 'UserPie',
            symbolOrName:
              slice && 'symbol' in slice
                ? slice.symbol
                : (slice?.name ?? 'slice'),
            securityInfo:
              isPie || !slice || !('symbol' in slice)
                ? undefined
                : {
                    symbol: slice.symbol,
                    isActive: Boolean(slice.isActive),
                    isCrypto: slice.__typename === 'CryptoAsset',
                    status: slice.isActive
                      ? SecurityStatusEnum.Active
                      : SecurityStatusEnum.Inactive,
                  },
          },
        ],
      },
    });
  };

  const percentageSum = children.reduce(
    (acc, child) => (acc += child.percentage ?? 0),
    0,
  );
  const isPiePercentage100 = percentageSum === 100;
  const childrenWithoutAdd = children.filter(
    (child) => child.meta.type !== 'add',
  );

  const original = findItemDeep(originalItems, id);
  const originalPercentage =
    original?.percentage && percentage && original.percentage - percentage !== 0
      ? original.percentage
      : null;

  React.useEffect(() => {
    const checkIfEqualized = (e: CustomEvent<HighlightEvent>) => {
      if (e.detail.ids.includes(String(item.id))) {
        setFade({ type: e.detail.type });
      }
    };

    window.addEventListener(
      'pie-editor-highlight',
      checkIfEqualized as EventListener,
    );
    return () => {
      window.removeEventListener(
        'pie-editor-highlight',
        checkIfEqualized as EventListener,
      );
    };
  }, [item.id]);

  const onDelayCallback = React.useCallback(() => {
    if (fade) {
      setFade(null);
    }
  }, [fade]);

  useDelay(onDelayCallback, highlightTime);

  const handlePercentageChange = React.useCallback(
    (percentage: number) => {
      dispatch({
        type: 'PIE_EDITOR_UPDATE_PERCENTAGE',
        payload: {
          id: item.id,
          slice: metaName.current,
          fromPercentage: original?.percentage,
          percentage,
        },
      });
    },
    [metaName, original?.percentage, dispatch, item.id],
  );

  const Options = React.useMemo(() => {
    return ({ compact }: { compact?: boolean }) => {
      const hasChildren =
        item.children.filter((child) => child.meta.type !== 'add').length > 0;
      let route: string;
      if (isPie) {
        route = `/d/research/my-pies/details/${item.meta.id}`;
      } else if (isStock) {
        route = `/d/research/stocks/details/${item.meta.id}`;
      } else if (isFund) {
        route = `/d/research/funds/details/${item.meta.id}`;
      } else if (isCrypto) {
        route = `/d/research/cryptos/details/${item.meta.id}`;
      }

      const options: {
        header: string;
        icon: { name: MonochromaticIconName<'24'> };
        onClick: () => void;
      }[] = [];

      if (item.meta.id) {
        options.push(
          {
            header: `${showResearch ? 'Hide' : 'Show'} quick research`,
            icon: {
              name: 'chart24',
            },
            onClick: () => setShowResearch((prev) => !prev),
          },
          {
            header: 'Go to research details',
            icon: {
              name: 'research24',
            },
            onClick: () => navigate({ to: route }),
          },
        );
      }

      if (item.removeable) {
        options.unshift({
          header: 'Remove slice',
          icon: {
            name: 'minusBubble24',
          },
          onClick: () =>
            dispatch({
              type: 'PIE_EDITOR_REMOVE_SLICE',
              payload: {
                id: item.id,
              },
            }),
        });
      }

      if (originalPercentage) {
        options.push({
          header: 'Reset slice percentage',
          icon: {
            name: 'percentage24',
          },
          onClick: () =>
            originalPercentage &&
            dispatch({
              type: 'PIE_EDITOR_UPDATE_PERCENTAGE',
              payload: {
                id: item.id,
                slice: metaName.current,
                fromPercentage: percentage,
                percentage: originalPercentage,
              },
            }),
        });
      }
      if (isPie) {
        options.unshift(
          ...[
            {
              header: 'Collapse all other pies',
              icon: {
                name: 'collapseMenu24' as const,
              },
              onClick: () =>
                dispatch({
                  type: 'PIE_EDITOR_UPDATE_COLLAPSED',
                  payload: {
                    id: item.id,
                    collapsed: true,
                    meta: { pie: metaName.current },
                  },
                }),
            },
          ],
        );
        if (hasChildren) {
          options.unshift({
            header: 'Expand sub-pies',
            icon: {
              name: 'expandMenu24',
            },
            onClick: () =>
              dispatch({
                type: 'PIE_EDITOR_UPDATE_COLLAPSED',
                payload: {
                  id: item.id,
                  collapsed: false,
                  meta: { pie: metaName.current },
                },
              }),
          });
        }
        if (isPie && !item.meta.isSystemPie) {
          if (
            hasChildren &&
            item.children.reduce(
              (acc, child) => acc + (child.percentage ?? 0),
              0,
            ) !== 100
          ) {
            options.push({
              header: 'Equalize pie',
              icon: {
                name: 'rebalance24',
              },
              onClick: () => {
                dispatch({
                  type: 'PIE_EDITOR_EQUALIZE_PIE',
                  payload: {
                    id: item.id,
                    meta: {
                      pie: metaName.current,
                    },
                  },
                });
              },
            });
          }
          options.push(
            {
              header: 'Add slices',
              icon: {
                name: 'addSlice24',
              },
              onClick: () => {
                dispatch({
                  type: 'PIE_EDITOR_ADD_SLICES_START',
                  payload: {
                    parentId: item.id,
                    meta: { pie: metaName.current },
                  },
                });
                navigate({
                  to: `/d/c/add-slices${isCrypto ? '/crypto' : ''}`,
                  options: {
                    state: {
                      from: `${location.pathname}${location.search}`,
                    },
                  },
                });
              },
            },
            {
              header: 'Rename pie',
              icon: {
                name: 'edit24',
              },
              onClick: () => setShowEditMode(true),
            },
          );
        } else if (item.meta.isSystemPie) {
          options.push({
            header: 'Convert to normal pie',
            icon: {
              name: 'switch24',
            },
            onClick: () =>
              dispatch({
                type: 'PIE_EDITOR_CONVERT_SYSTEM_PIE',
                payload: {
                  id: item.id,
                  meta: { pie: metaName.current },
                },
              }),
          });
        }
      }

      return (
        <>
          {options.map((option) => (
            <ListItem
              key={option.header}
              {...{
                ...option,
                onClick: () => {
                  option.onClick?.();
                  setShowOptions(false);
                  setShowContextMenu(false);
                },
                size: 'SMALL',
                selectable: true,
                padding: '16px',
                withoutArrow: true,
              }}
              {...(compact ? { padding: '8px' } : {})}
            />
          ))}
        </>
      );
    };
  }, [
    dispatch,
    isCrypto,
    isFund,
    isPie,
    isStock,
    item.children,
    item.id,
    item.meta.id,
    item.meta.isSystemPie,
    item.removeable,
    location.pathname,
    location.search,
    navigate,
    originalPercentage,
    percentage,
    showResearch,
  ]);

  return (
    <SkeletonProvider isLoading={loading}>
      <TooltipContainer>
        <Tooltip
          body={
            <ul style={{ marginTop: -16 }}>
              <Options compact />
            </ul>
          }
          followCursor="initial"
          visible={showContextMenu}
          contentPadding={0}
          onClickOutside={() => setShowContextMenu(false)}
          plugins={[followCursor]}
          placement="right-start"
          interactive
          arrow={false}
          offset={[0, 0]}
          maxWidth={256}
        >
          <Box
            onContextMenu={(e) => {
              if (item.meta.type !== 'add') {
                if (!showContextMenu) {
                  e.preventDefault();
                }
                setShowContextMenu((prev) => !prev);
              }
            }}
            backgroundColor={
              fade?.type === 'row' ? 'backgroundSuccessSubtle' : undefined
            }
          >
            <SliceRow py={12}>
              <SliceRow
                $isAddSlice={isAddSlice}
                onClick={
                  isAddSlice
                    ? () => setShowAddSlice((prev) => !prev)
                    : undefined
                }
                flex={1}
                minWidth={0}
                mr={8}
              >
                <Skeleton mr={16} position="relative">
                  {icon}
                  {item.hasOrder && <Indicator color="java" icon="switch16" />}
                  {item.hasRebalance && (
                    <Indicator color="java" icon="rebalance16" />
                  )}{' '}
                  {item.meta.securityInfo?.isActive === false && (
                    <Indicator color="orange" icon="warning24" />
                  )}
                </Skeleton>
                <Box flex="1" overflow="hidden">
                  <Skeleton skeletonWidth="25%">
                    {node && 'symbol' in node && (
                      <PM
                        color="foregroundNeutralSecondary"
                        content={node.symbol}
                        mr={8}
                      />
                    )}
                  </Skeleton>
                  <Skeleton
                    font="PM"
                    color="foregroundNeutralMain"
                    textOverflow="ellipsis"
                    mr={16}
                    skeletonWidth="25%"
                    overflow="hidden"
                  >
                    {name && isPie && !item.meta.isSystemPie ? (
                      <RenameablePieCell
                        pieName={name}
                        isActive
                        showEditMode={showEditMode}
                        setShowEditMode={setShowEditMode}
                        childrenCount={childrenWithoutAdd.length}
                        onRename={(name) =>
                          dispatch({
                            type: 'PIE_EDITOR_RENAME_PIE',
                            payload: {
                              id: item.id,
                              name,
                              meta: { old: metaName.current, new: name },
                            },
                          })
                        }
                      />
                    ) : (
                      <PM
                        fontWeight={isAddSlice ? 400 : 600}
                        whiteSpace="no-wrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                      >
                        {name}
                        {childrenWithoutAdd.length > 0
                          ? ` · ${childrenWithoutAdd.length}`
                          : ''}
                      </PM>
                    )}
                  </Skeleton>
                </Box>
                <Skeleton display="inline-flex">
                  {children.length > 0 && (!item.collapsed || isRoot) && (
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      mr={isRoot ? 34 : 0}
                      width={isRoot ? 105 : undefined}
                    >
                      <Text
                        color="foregroundNeutralSecondary"
                        mr={4}
                        font={isRoot ? 'PXL' : 'PM'}
                      >
                        Total:{' '}
                      </Text>
                      <Flex
                        font={isRoot ? 'PXL' : 'PM'}
                        color={
                          isPiePercentage100
                            ? 'foregroundNeutralSecondary'
                            : 'foregroundCritical'
                        }
                        fontWeight={600}
                        alignItems="center"
                        mr={8}
                      >
                        {percentageSum}%{' '}
                      </Flex>
                    </Flex>
                  )}
                  {percentage && (
                    <>
                      {item.draggable === false ? (
                        <PercentageContainer
                          ml={-PercentageContainerWidth / 2 + 16}
                        >
                          <PM color="foregroundNeutralSecondary">
                            {item.percentage}%
                          </PM>
                        </PercentageContainer>
                      ) : (
                        <PercentageContainer>
                          <PercentageAdjustor
                            showButtons={!isDragging}
                            fromPercentage={originalPercentage}
                            percentage={item?.percentage}
                            onPercentageChange={handlePercentageChange}
                            backgroundColor={
                              fade?.type === 'percentage'
                                ? 'backgroundSuccessSubtle'
                                : undefined
                            }
                          />
                        </PercentageContainer>
                      )}
                    </>
                  )}
                </Skeleton>
              </SliceRow>
              {!isAddSlice && !isDragging && (
                <FloatingPanel
                  controlled
                  active={showOptions}
                  placement="top-end"
                  content={
                    <ul style={{ marginTop: -16 }}>
                      <Options />
                    </ul>
                  }
                  onShow={() => {
                    setShowOptions(true);
                  }}
                  onHide={() => setShowOptions(false)}
                  trigger={
                    <MoreButtonContainer>
                      {/* @ts-ignore */}
                      <MoreButton
                        kind="text"
                        leftIcon="more24"
                        label=""
                        size="medium"
                        ml={8}
                      />
                    </MoreButtonContainer>
                  }
                />
              )}
            </SliceRow>
            {isAddSlice && (
              // @ts-expect-error - TS2769 - No overload matches this call
              <Collapse isOpened={showAddSlice}>
                <Flex alignItems="center" flexWrap="wrap">
                  <Button
                    kind="text"
                    label="Create pie"
                    leftIcon="createPie16"
                    size="small"
                    onClick={() => handleAdd()}
                    mb={8}
                  />
                  <Box mb={8}>
                    <Divider vertical width="24px" spacing="compact" />
                  </Box>
                  <Button
                    kind="text"
                    label="Add slice"
                    leftIcon="addSlice16"
                    size="small"
                    mb={8}
                    onClick={() => {
                      dispatch({
                        type: 'PIE_EDITOR_ADD_SLICES_START',
                        payload: {
                          parentId: item.id,
                          meta: { pie: metaName.current ?? 'pie' },
                        },
                      });
                      navigate({
                        to: `/d/c/add-slices${isCrypto ? '/crypto' : ''}`,
                        options: {
                          state: {
                            from: `${location.pathname}${location.search}`,
                          },
                        },
                      });
                    }}
                  />
                  <Box mb={8}>
                    <Divider vertical width="24px" spacing="compact" />
                  </Box>
                  {!isCrypto && (
                    <Box mb={8}>
                      <DiscoverSearch
                        onResultClick={handleAdd}
                        placeholder="Quick add by symbol or company name"
                        borderless
                        filterStatuses={[SliceableStatusEnum.Active]}
                        filterTypes={[
                          SliceableTypeEnum.EquitySecurity,
                          SliceableTypeEnum.FundSecurity,
                          SliceableTypeEnum.SystemPie,
                          SliceableTypeEnum.UserPie,
                        ]}
                      />
                    </Box>
                  )}
                </Flex>
              </Collapse>
            )}
            {showResearch && item.meta.id && (
              <SliceableResearch ids={[String(item.meta.id)]} />
            )}
          </Box>
        </Tooltip>
      </TooltipContainer>
    </SkeletonProvider>
  );
};
