import { Button, Text } from '@m1/liquid-react';
import * as React from 'react';

import { useNavigate } from '~/hooks/useNavigate';

export const ForgotPasswordConfirmPage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div
        style={{
          padding: `32px 0 64px 0`,
        }}
      >
        <Text content="An email has been sent with a link to reset your password. It shouldn't take more than a few minutes to arrive." />
      </div>

      <Button
        label="Back to Login"
        kind="primary"
        onClick={() => {
          navigate({ to: '/login' });
        }}
        size="large"
      />
    </div>
  );
};
