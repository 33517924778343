import * as React from 'react';

import { LabelWithValue } from '~/components/label-with-value';
import { TransferRuleDetailFragment } from '~/graphql/types';
import { getEnumDescription } from '~/utils';

export const RecurrenceScheduleDetails = ({
  schedule,
}: {
  schedule: Extract<
    TransferRuleDetailFragment,
    { __typename: 'ScheduledTransferRule' }
  >['schedule'];
}) => {
  if (!schedule?.__typename) {
    return null;
  }
  switch (schedule.__typename) {
    case 'MonthlySchedule':
      return (
        <LabelWithValue
          label="Day of month"
          style={{
            marginTop: 12,
          }}
          value={getEnumDescription(
            schedule.dayOfMonth,
            'MonthlyScheduleDateEnum',
          )}
        />
      );
    case 'BiweeklySchedule':
    case 'WeeklySchedule':
      return (
        <LabelWithValue
          label="Day of week"
          style={{
            marginTop: 12,
          }}
          value={getEnumDescription(
            schedule.dayOfWeek,
            'WeeklyScheduleDayEnum',
          )}
        />
      );
    default:
      return null;
  }
};
