import { Box, HXS } from '@m1/liquid-react';
import {
  Illustration,
  type IllustrationsType,
} from '@m1/liquid-react/illustrations';
import React from 'react';

type NoTradesContentProps = {
  picture: IllustrationsType;
  content: string;
};

export const NoTradesContent = ({ picture, content }: NoTradesContentProps) => {
  return (
    <Box alignSelf="stretch" textAlign="center" p={32}>
      <Illustration height={216} name={picture} width={216} />
      <HXS content={content} mt={32} />
    </Box>
  );
};
