import * as React from 'react';

import { Logo } from '~/toolbox/logo';

type AssetLogoProps = {
  symbol: string;
  profile: { logoUrl?: string | null | undefined } | null | undefined;
};

export const AssetLogo = ({ symbol, profile }: AssetLogoProps) => {
  const content = profile?.logoUrl || '';

  return <Logo content={content} placeholder={symbol} />;
};
