import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';

export const SaveRedirect = ({
  children,
  hasSavingsAccounts,
}: React.PropsWithChildren<{
  hasSavingsAccounts: boolean;
}>) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const validPaths = ['/d/save/', '/d/save', 'd/save'];
  const shouldRedirect = validPaths.includes(pathname);

  React.useEffect(() => {
    if (shouldRedirect) {
      let route = null;
      if (hasSavingsAccounts) {
        route = '/d/save/savings/transactions';
      } else {
        route = '/d/save/marketing';
      }
      navigate({ to: route, options: { replace: true } });
    }
  }, [hasSavingsAccounts, shouldRedirect, navigate]);

  return <Flex width="100%">{children}</Flex>;
};
