import { Flex, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';
import { Transition } from 'react-spring/renderprops';

export type ToggleHelperProps = {
  handleToggleHelper: () => void;
  helperPillOpen?: boolean;
  isHelperOn: boolean | null | undefined;
};

const StyledToggleContainer = styled(Flex)`
  cursor: pointer;
  position: relative;
`;

export const ToggleHelper = ({
  handleToggleHelper,
  isHelperOn,
}: ToggleHelperProps) => {
  return (
    <StyledToggleContainer onClick={handleToggleHelper} width={24}>
      <Transition
        items={isHelperOn}
        from={{
          position: 'absolute',
          opacity: 0,
        }}
        enter={{
          opacity: 1,
        }}
        leave={{
          opacity: 0,
        }}
      >
        {/* @ts-expect-error - TS7006 - Parameter 'toggle' implicitly has an 'any' type. | TS7006 - Parameter 'props' implicitly has an 'any' type. */}
        {(toggle) => (props) => {
          const StyledIcon = styled(Icon)(props);
          return (
            <StyledIcon
              color="primary"
              name={toggle ? 'helpFill24' : 'help24'}
            />
          );
        }}
      </Transition>
    </StyledToggleContainer>
  );
};
