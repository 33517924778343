import { PL, LM, LL, PM, Flex, Box } from '@m1/liquid-react';
import * as React from 'react';

import { ExternalLink } from '~/components/ExternalLink';
import { ControlImg } from '~/toolbox/carousel';

import { DialogPagingButton } from './DialogPagingButton';
import { StyledDialog } from './HelperDialog.styled';
import { Alignment, Direction } from './HelperDialog.types';
import { HelperDialogCloseIcon } from './HelperDialogCloseIcon';

export type DialogSequenceConfig = {
  activeDialogPage: number;
  decrementDialogPage: () => void;
  incrementDialogPage: () => void;
  maxDialogPage: number;
  SEQUENCED_DIALOGS: Record<string, number | null | undefined>;
};

export type HelperDialogProps = {
  alignment?: Alignment;
  children: React.ReactNode;
  content: string | React.ReactNode;
  direction?: Direction;
  height?: number;
  isVisible: boolean;
  kind: 'full-width' | 'normal';
  margin?: string | number;
  pageNumber: number | null | undefined;
  sequence?: DialogSequenceConfig;
  title: string;
  width?: number;
};

export const HelperDialog = ({
  alignment = 'center',
  children,
  content,
  direction = 'up',
  height = 220,
  isVisible,
  kind,
  margin = 0,
  pageNumber,
  sequence,
  title,
  width = 400,
}: HelperDialogProps) => {
  const controlsContentAlignment = React.useCallback(() => {
    let alignment = 'center';
    const maxDialogPage = sequence ? sequence.maxDialogPage : 1;

    if (pageNumber === 1 && maxDialogPage !== 1) {
      alignment = 'flex-end';
    } else if (pageNumber === maxDialogPage && maxDialogPage !== 1) {
      alignment = 'flex-start';
    }

    return alignment;
  }, [pageNumber, sequence]);

  return (
    <Box
      display="inline-block"
      position="relative"
      width={kind === 'normal' ? 'auto' : '100%'}
    >
      {children}

      {isVisible && (
        <StyledDialog
          alignment={alignment}
          backgroundColor="backgroundPrimarySubtle"
          borderRadius={8}
          direction={direction}
          height={height}
          margin={margin}
          padding={16}
          position="absolute"
          width={width}
        >
          <Flex justifyContent="flex-start" mb={8}>
            <HelperDialogCloseIcon />
          </Flex>
          <Flex justifyContent="space-between">
            <Flex flexDirection="column">
              <PL
                fontWeight={600}
                color="foregroundNeutralMain"
                content={title}
                mb={8}
              />
              {typeof content === 'string' ? (
                <PM color="foregroundNeutralMain" content={content} />
              ) : (
                content
              )}
              <Flex
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <LL
                  content={
                    <ExternalLink destination="PORTFOLIO_ORGANIZER_FAQ">
                      View FAQs
                    </ExternalLink>
                  }
                  mt={8}
                />
              </Flex>
            </Flex>
            <Flex
              flexDirection="column"
              ml={16}
              justifyContent={controlsContentAlignment()}
            >
              {sequence && pageNumber !== 1 && (
                <DialogPagingButton
                  margin="0 0 16px 0"
                  onClick={sequence.decrementDialogPage}
                >
                  <ControlImg direction="vertical" />
                </DialogPagingButton>
              )}
              <Flex
                alignItems="center"
                backgroundColor="borderSuccess"
                borderRadius={10}
                color="success"
                height={25}
                justifyContent="center"
                minWidth={42}
              >
                {pageNumber && (
                  <LM
                    content={
                      sequence
                        ? `${pageNumber}/${sequence.maxDialogPage}`
                        : `${pageNumber}/${pageNumber}`
                    }
                  />
                )}
              </Flex>
              {sequence && pageNumber !== sequence.maxDialogPage && (
                <DialogPagingButton
                  margin="16px 0 0 0"
                  onClick={sequence.incrementDialogPage}
                >
                  <ControlImg direction="vertical_reversed" />
                </DialogPagingButton>
              )}
            </Flex>
          </Flex>
        </StyledDialog>
      )}
    </Box>
  );
};
