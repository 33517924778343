import { Flex, Box, Button, styled, PS, PM } from '@m1/liquid-react';
import * as React from 'react';
import { change, InjectedFormProps } from 'redux-form';

import { SignupNotifications } from '~/components/notifications';
import { compose, connectForm, UNSAFE_connectRedux } from '~/hocs';
import type { AppState } from '~/redux';
import { useDispatch, useSelector } from '~/redux/hooks';
import { EXTERNAL_LINKS, FIELD_MAX_LENGTHS } from '~/static-constants';
import { Link } from '~/toolbox/link';

import { PasswordField, TextField } from './fields';
import { required, maxLength, emailTypoWarning } from './validators';

export type RegisterUserFormProps = InjectedFormProps<any> & {
  attemptInProgress: boolean;
  autoFocus: boolean;
};

const StyledBox = styled(Box)`
  width: 100%;
`;

const usernameValidation = [required, maxLength(FIELD_MAX_LENGTHS.USERNAME)];

const RegisterUserFormComponent = (props: RegisterUserFormProps) => {
  const formRef = React.useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const { username, password, isModalFlow, shouldAutoSubmit } = useSelector(
    (state) => ({
      // only auto-submit sign-up (if modal was opened for sign-up)
      shouldAutoSubmit:
        state.modals.LOGIN_OR_REGISTER?.payload?.activeTab === 1,
      isModalFlow: Number.isInteger(
        state.modals.LOGIN_OR_REGISTER?.payload?.activeTab,
      ),
      username: state.modals.LOGIN_OR_REGISTER?.payload?.username,
      password: state.modals.LOGIN_OR_REGISTER?.payload?.password,
    }),
  );

  React.useEffect(() => {
    if (isModalFlow && shouldAutoSubmit && username && password) {
      dispatch(change('register-user', 'username', username));
      dispatch(change('register-user', 'password', password));
      // redux-form has to populate its state before we can submit the form
      // looks like we have to exhaust the event loop before we can auto-submit
      setTimeout(() => {
        formRef.current?.click();
      });
    }
  }, [username, password, isModalFlow]);

  const btnLabel = 'Join M1';

  return (
    <form onSubmit={props.handleSubmit}>
      <Flex>
        <StyledBox>
          <Box py={8}>
            <SignupNotifications />
          </Box>
          <TextField
            name="username"
            type="email"
            label="Email"
            validate={usernameValidation}
            warn={emailTypoWarning}
            maxLength={FIELD_MAX_LENGTHS.USERNAME}
            autoFocus={props.autoFocus || false}
          />
          <PasswordField securePasswordEnabled />
          <PasswordCopy />
          <Flex justifyContent="center">
            <PM my={16} justifyContent="center">
              By creating an account, you agree to the M1 Finance{' '}
              <Link
                to={EXTERNAL_LINKS.LEGAL_TERMS}
                style={{
                  fontWeight: 400,
                  textDecoration: 'underline',
                }}
                font="PM"
                target="_blank"
              >
                Terms of Use
              </Link>
              {' and '}
              <Link
                to={EXTERNAL_LINKS.LEGAL_PRIVACY}
                style={{
                  fontWeight: 400,
                  textDecoration: 'underline',
                }}
                font="PM"
                target="_blank"
              >
                Privacy Policy
              </Link>
            </PM>
          </Flex>
          <Button
            type="submit"
            label={btnLabel}
            kind="primary"
            size="large"
            disabled={props.attemptInProgress}
            fullWidth
            tabIndex={4}
          />
          <button
            type="submit"
            ref={formRef}
            style={{ visibility: 'hidden', display: 'none' }}
          />
        </StyledBox>
      </Flex>
    </form>
  );
};
const PasswordCopy = () => {
  return (
    <PS
      color="foregroundNeutralTertiary"
      fontWeight={400}
      style={{
        textAlign: 'center',
      }}
    >
      10 character minimum; include one lowercase,
      <br /> one uppercase, and one number.
    </PS>
  );
};

const enhancer = compose<any, any>(
  UNSAFE_connectRedux(
    (state: AppState): Partial<RegisterUserFormProps> => ({
      attemptInProgress: state.newFlows.registerUser.attemptInProgress,
      initialValues: {
        username: state.config.email,
      },
    }),
  ),
  connectForm({
    form: 'register-user',
  }),
);

export const RegisterUserForm = enhancer(RegisterUserFormComponent) as any;
