import { Box, PXL, Flex } from '@m1/liquid-react';
import * as React from 'react';

import { useProfileConfirmationRequiredDocumentsQuery } from '~/graphql/hooks';
import { RichText } from '~/lens-toolbox/RichText/RichText';

import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

export const ProfileConfirmationRequiredDocuments = () => {
  const { data, loading } = useProfileConfirmationRequiredDocumentsQuery();

  if (!data?.viewer.onboarding?.profileConfirmationRequiredDocuments) {
    return null;
  }

  const requiredDocuments =
    data?.viewer.onboarding?.profileConfirmationRequiredDocuments?.documents;
  const postamble =
    data?.viewer.onboarding?.profileConfirmationRequiredDocuments
      ?.richPostamble;

  if (loading) {
    return <Spinner />;
  }

  return (
    <Box mt={40}>
      <PXL content="Agreements and Disclosures" fontWeight={600} mb={8} />
      <Flex flexDirection="column">
        {requiredDocuments?.map((document) => (
          <Box key={document.url} pb={8}>
            <Link
              mb="8px"
              key={document.title}
              style={{
                textDecoration: 'underline',
                width: 'fit-content',
                fontWeight: 400,
              }}
              to={document.url}
              target="_blank"
            >
              {document.title}
            </Link>
          </Box>
        ))}
        {postamble &&
          postamble.map(
            (content, i) =>
              content && (
                <Flex mt={i > 0 ? 8 : 0} key={i}>
                  <RichText richText={content} />{' '}
                </Flex>
              ),
          )}
      </Flex>
    </Box>
  );
};
