export const REGISTER_USER_ACTIONS = Object.freeze({
  BEGIN_REGISTER_USER_FLOW: 'BEGIN_REGISTER_USER_FLOW' as const,
  CHANGE_REGISTER_USER_FLOW_STEP: 'CHANGE_REGISTER_USER_FLOW_STEP' as const,
  SUBMIT_REGISTER_USER_FORM: 'SUBMIT_REGISTER_USER_FORM' as const,
  REGISTER_USER_ATTEMPT_FAILED: 'REGISTER_USER_ATTEMPT_FAILED' as const,
  FINISHED_REGISTER_USER_FLOW: 'FINISHED_REGISTER_USER_FLOW' as const,
  FINISHED_REGISTER_SIGNUP_OUTCOME: 'FINISHED_REGISTER_SIGNUP_OUTCOME' as const,
  SET_PROMOTION_SIGNUP_OUTCOME: 'SET_PROMOTION_SIGNUP_OUTCOME' as const,
});

export type BeginRegisterUserFlowAction = {
  payload: unknown;
  type: typeof REGISTER_USER_ACTIONS.BEGIN_REGISTER_USER_FLOW;
};

export type ChangeRegisterUserFlowStepAction = {
  payload: unknown;
  type: typeof REGISTER_USER_ACTIONS.CHANGE_REGISTER_USER_FLOW_STEP;
};

export type SubmitRegisterUserFormAction = {
  payload: unknown;
  type: typeof REGISTER_USER_ACTIONS.SUBMIT_REGISTER_USER_FORM;
};

export const beginRegisterUserFlow = () => ({
  type: REGISTER_USER_ACTIONS.BEGIN_REGISTER_USER_FLOW,
});

export const changeRegisterUserFlowStep = (
  payload: ChangeRegisterUserFlowStepAction['payload'],
): ChangeRegisterUserFlowStepAction => ({
  payload,
  type: REGISTER_USER_ACTIONS.CHANGE_REGISTER_USER_FLOW_STEP,
});

export const submitRegisterUserForm = (
  payload: SubmitRegisterUserFormAction['payload'],
): SubmitRegisterUserFormAction => ({
  payload,
  type: REGISTER_USER_ACTIONS.SUBMIT_REGISTER_USER_FORM,
});

export type RegisterUserAction =
  | BeginRegisterUserFlowAction
  | ChangeRegisterUserFlowStepAction
  | SubmitRegisterUserFormAction;
