/*
 * TODO: We should rethink this component and either restrict it to some
 * subset of Icons or just delete this component completly.
 */

import { Flex, styled, useTheme } from '@m1/liquid-react';
import { Icon, type MonochromaticIconName } from '@m1/liquid-react/icons';
import * as React from 'react';

export type IndicatorProps = {
  color: 'orange' | 'java';
  icon: MonochromaticIconName;
};

const StyledIndicatorContainer = styled(Flex)`
  position: absolute;
  right: -3px;
  bottom: -3px;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.foregroundNeutralMain};
  border: 1px solid ${({ theme }) => theme.colors.borderMain};
  border-radius: 50%;
`;

export const Indicator = ({ color, icon }: IndicatorProps) => {
  const theme = useTheme();
  const readColorStyle = () => {
    switch (color) {
      case 'orange':
        return theme.colors.yellow03;
      case 'java':
        return theme.colors.borderSuccess;
      default:
        return 'transparent';
    }
  };

  return (
    <StyledIndicatorContainer
      style={{
        backgroundColor: readColorStyle(),
      }}
    >
      <Icon display="flex" name={icon} />
    </StyledIndicatorContainer>
  );
};
