import { Box, Flex, Tooltip, PM } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import React from 'react';

import { useWizardContext } from '~/flows/wizard';
import { useSupportLink } from '~/hooks/useSupportLink';
import { Link } from '~/toolbox/link';
import { Progress } from '~/toolbox/progress';

export const PersonalLoanDirectHeader = () => {
  const supportLink = useSupportLink();
  const { currentStepIndex, stepKeys } = useWizardContext();

  const getProgressPercentage = (): number => {
    return ((currentStepIndex + 1) / stepKeys?.length) * 100;
  };

  return (
    <>
      <Box padding="1.5rem 0">
        <Flex
          maxWidth={1200}
          mx="auto"
          px={16}
          alignItems="center"
          justifyContent="space-between"
        >
          <Icon name="m1Logo32" mr={64} />
          <Tooltip
            icon="help24"
            iconColor="secondary"
            body={
              <div
                style={{
                  padding: '12px 20px',
                  textAlign: 'left',
                }}
              >
                <PM>
                  If you need assistance, please visit our{' '}
                  <Link {...supportLink}>Support Center</Link>
                </PM>
              </div>
            }
          />
        </Flex>
      </Box>
      <Progress percent={getProgressPercentage()} color="secondary" />
    </>
  );
};
