import get from 'lodash-es/get';
import orderBy from 'lodash-es/orderBy';

/**
 * Sorts table data based on provided key and direction
 * @param {Array} data Array of objects containing table data
 * @param {string} sortKey Key in data to access value to sort on
 * @param {string} sortDirection Direction to sort: ASC or DESC
 * @returns {Array} Sorted Array
 */
export const tableSort = <T extends any>(
  data: Array<T> | ReadonlyArray<T>,
  sortKey: string,
  sortDirection: 'ASC' | 'DESC',
): Array<T> | ReadonlyArray<T> => {
  if (!data || data.length < 2) {
    return data;
  }

  return orderBy(
    data,
    [
      (datum) => {
        if (typeof sortKey === 'function') {
          // @ts-expect-error - TS2349 - This expression is not callable.
          return sortKey(datum);
        }

        const value = get(datum, sortKey);
        return typeof value === 'string' ? value.toLowerCase() : value;
      },
    ],
    // @ts-expect-error - TS2769 - No overload matches this call.
    [sortDirection.toLowerCase()],
  );
};
