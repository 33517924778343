import * as React from 'react';

import { onlyNumbers } from '~/utils/normalizers';

export const usePercentageValidation = (
  percentageValue: string,
  setPercentageValue: (arg0: string) => void,
  minPercentage: number = 0,
  maxPercentage: number = 100,
): {
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  percentageErrorMessage: string;
  percentageValue: string;
} => {
  const [percentageErrorMessage, setPercentageErrorMessage] =
    React.useState('');

  const onInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const formattedPercentage = onlyNumbers(event.target.value);
      let hasRequiredError = false;
      let hasRangeError = false;
      if (formattedPercentage === '') {
        setPercentageErrorMessage('Percentage is required');
        hasRequiredError = true;
      }
      if (
        Number(formattedPercentage) < minPercentage ||
        Number(formattedPercentage) > maxPercentage
      ) {
        hasRangeError = true;
        setPercentageErrorMessage(
          `Please enter a percentage between ${minPercentage} and ${maxPercentage}.`,
        );
      }
      setPercentageValue(formattedPercentage);
      if (hasRequiredError === false && hasRangeError === false) {
        setPercentageErrorMessage('');
      }
    },
    [maxPercentage, minPercentage, setPercentageValue],
  );

  return {
    onInputChange,
    percentageErrorMessage,
    percentageValue,
  };
};
