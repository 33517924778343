import * as React from 'react';

import { formatCurrencyWithCommas } from '../utils/formatting';
import { normalizeCurrencyWithNegativeValue } from '../utils/normalizers';

export const useCurrencyValidation = (
  input: number | string | null | undefined,
): string | null | undefined => {
  const [value, setValue] = React.useState(null);
  React.useEffect(() => {
    if (input || typeof input === 'number') {
      let formattedValue = normalizeCurrencyWithNegativeValue(input.toString());
      if (formattedValue && typeof formattedValue === 'string') {
        formattedValue = formatCurrencyWithCommas(formattedValue);
        // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'SetStateAction<null>'.
        setValue(formattedValue);
      } else {
        setValue(null);
      }
    }
  }, [input]);

  return value;
};
