import * as React from 'react';
import { Route } from 'react-router-dom';

import { Redirect } from './Redirect';

export const OpenAccountRoute = () => (
  <Route
    path="open-account/:accountSetupStep?"
    element={<Redirect />}
    handle={{
      fallbackRoute: {
        to: '/d/open-invest-account',
      },
    }}
  />
);
