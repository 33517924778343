import { Flex, PXL, Card, CardProps } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { AppContext } from '~/AppContext';
import {
  AnalyticsEventFragment,
  SetupChecklistDestination,
  SetupChecklistFragment,
} from '~/graphql/types';

import { useNavigate } from '~/hooks/useNavigate';
import { Progress } from '~/toolbox/progress';
import { filterNilValues, mapInternalPathToRouteName } from '~/utils';

import {
  StyledCustomCheckbox,
  StyledStepInnerContainer,
  StyledStepOuterContainer,
  StyledTitleContainer,
} from './SetupChecklistCard.styled';

export type RoutelessDestinations = {
  'Build portfolio': () => void;
} & Record<string, any>;

export type SetupChecklistCardProps = {
  fromParticipantId?: string | null;
  toParticipantId?: string | null;
  routelessDestinations?: Partial<RoutelessDestinations>;
  setupChecklist: SetupChecklistFragment;
  width?: CardProps['width'];
};

export const SetupChecklistCard = ({
  fromParticipantId,
  toParticipantId,
  routelessDestinations = {},
  setupChecklist,
  width,
}: SetupChecklistCardProps) => {
  const navigate = useNavigate();
  const { analytics } = React.useContext(AppContext);

  const handleClickRow = React.useCallback(
    (
      destination: SetupChecklistDestination,
      analyticsEvent: AnalyticsEventFragment | null | undefined,
    ): void => {
      if (analyticsEvent) {
        analytics.recordAppAnalyticsEvent(analyticsEvent as any);
      }

      if (destination) {
        //  TODO(RRU): Create a separate function that parses the query params (don't use the one from deep-links).
        const { routeName, queryParams } = mapInternalPathToRouteName(
          destination.path,
        );

        const query = filterNilValues({
          ...queryParams,
          toParticipantId: toParticipantId,
          fromParticipantId: fromParticipantId,
        });

        navigate({ to: routeName, query });
      }
    },
    [analytics, toParticipantId, fromParticipantId, navigate],
  );

  return (
    <Card
      isElevated
      backgroundColor="backgroundNeutralSecondary"
      width={width}
      position="relative"
    >
      <StyledTitleContainer>
        <PXL
          color="foregroundNeutralMain"
          fontWeight={600}
          content={setupChecklist.title}
          pr={8}
        />
        <Flex alignItems="center">
          <Progress
            height={15}
            percent={
              (setupChecklist.stepsCompleted / setupChecklist.stepsTotal) * 100
            }
            style={{
              width: 218,
            }}
          />
          <PXL
            fontWeight={600}
            color="foregroundNeutralMain"
            content={`${setupChecklist.stepsCompleted}/${setupChecklist.stepsTotal}`}
            ml={6}
          />
        </Flex>
      </StyledTitleContainer>
      {setupChecklist.steps.map((step, index) => {
        const isClickable = step.isReady && !step.isCompleted;
        const isDisabled = step.isReady === false && step.isCompleted !== true;

        return (
          <StyledStepOuterContainer
            $isClickable={isClickable ?? false}
            $isDisabled={isDisabled}
            key={step.title}
            onClick={() => {
              if (isClickable && step.destination) {
                handleClickRow(step.destination, step.analyticsEvent);
              } else if (isClickable && routelessDestinations[step.title]) {
                if (step.analyticsEvent) {
                  analytics.recordAppAnalyticsEvent(step.analyticsEvent as any);
                }
                routelessDestinations[step.title]();
              }
            }}
          >
            <StyledStepInnerContainer
              $isLastRow={index === setupChecklist.steps.length - 1}
            >
              <Flex>
                <StyledCustomCheckbox
                  $isClickable={isClickable ?? false}
                  $isDisabled={isDisabled}
                  mr={16}
                >
                  {step.isCompleted && <Icon name="checkBubbleFill24" />}
                </StyledCustomCheckbox>
                <PXL fontWeight={600} content={step.title} />
              </Flex>
              {(isClickable || isDisabled) && (
                <div>
                  <Icon
                    name="caretRight16"
                    color={
                      isDisabled
                        ? 'foregroundNeutralTertiary'
                        : 'foregroundNeutralSecondary'
                    }
                  />
                </div>
              )}
            </StyledStepInnerContainer>
          </StyledStepOuterContainer>
        );
      })}
    </Card>
  );
};
