/* eslint-disable react/sort-comp */

/* eslint-disable no-return-assign */

import classNames from 'classnames';
import * as React from 'react';

import { LegacyTab } from './LegacyTabs.Item';
import style from './style.module.scss';

type Props = {
  children?: Array<React.ReactElement<any>>;
  className?: string | null | undefined;
  index: number;
  onChange?: (...args: Array<any>) => any;
};

type State = {
  pointer: {
    left?: string;
    top?: string;
    width?: string;
  };
};

export class LegacyTabs extends React.Component<Props, State> {
  static Item = LegacyTab;
  static defaultProps = {
    index: -1,
  };

  state = {
    pointer: {},
  };

  root: HTMLElement | null | undefined;
  group: HTMLElement | null | undefined;

  componentDidMount() {
    this.updatePointer(this.props.index);
    window.addEventListener('resize', this.resizeHandler);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.index !== nextProps.index) {
      this.updatePointer(nextProps.index);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  render() {
    const className = classNames(style.root, this.props.className);
    const pointerClasses = classNames(
      style.pointer,
      this.props.index > -1 && style.pointerActive,
    );

    return (
      <div ref={(ref) => (this.root = ref)} className={className}>
        <div className={style.group} ref={(ref) => (this.group = ref)}>
          {this.renderChildren()}
        </div>
        <span className={pointerClasses} style={this.state.pointer} />
      </div>
    );
  }

  renderChildren(): Array<React.ReactElement<any>> {
    // @ts-expect-error - TS2322 - Type 'DetailedReactHTMLElement<any, HTMLElement>[] | null | undefined' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>[]'.
    return React.Children.map(this.props.children, (child, index) => {
      // @ts-expect-error - TS2769 - No overload matches this call.
      return React.cloneElement(child, {
        active: this.props.index === index,
        onClickParent: this.handleHeaderClick.bind(this, index),
        onTouchStart: this.handleHeaderClick.bind(this, index),
      });
    });
  }

  handleHeaderClick = (idx: number): void => {
    if (this.props.onChange) {
      this.props.onChange(idx);
    }
  };

  resizeHandler = (): void => {
    this.updatePointer(this.props.index);
  };

  updatePointer(index: number): void {
    const { root, group } = this;
    if (!root || !group) {
      return;
    }

    const rootLeft = root.getBoundingClientRect().left;
    const groupHeight = group.getBoundingClientRect().height;

    if (group.children[index]) {
      const label = group.children[index].getBoundingClientRect();
      this.setState({
        pointer: {
          top: `${groupHeight}px`,
          left: `${label.left - rootLeft}px`,
          width: `${label.width}px`,
        },
      });
    }
  }
}
