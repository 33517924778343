import { TwoColumn4by8 } from '@m1/liquid-react';
import * as React from 'react';

import {
  MarketTrends,
  MarketNews,
} from '~/pages/dashboard/research/market-news/components';
import { Section } from '~/toolbox/section';

export const MarketNewsPage = () => {
  return (
    <TwoColumn4by8>
      <TwoColumn4by8.Column1>
        <MarketTrends />
      </TwoColumn4by8.Column1>
      <TwoColumn4by8.Column2>
        <Section>
          <Section.Header label="Top Stories" />
          <Section.Content>
            <MarketNews />
          </Section.Content>
        </Section>
      </TwoColumn4by8.Column2>
    </TwoColumn4by8>
  );
};
