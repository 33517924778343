import { Box, styled } from '@m1/liquid-react';
import * as React from 'react';

import { MoveDrawerRowFragment } from '~/graphql/types';
import { UnmanagedHoldingsFlowProps } from '~/redux/actions/pieOrganizer/pieOrganizerConfig.types';
import { Link } from '~/toolbox/link';

const StyledDestinationRow = styled(Box)`
  // this styled component gets rendered as an anchor so box props won't work here
  align-items: center;
  display: flex;
  padding: 16px;
  width: 100%;

  transition: 200ms background-color linear;

  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundNeutralTertiary};
  }
`;

type MoveDrawerRowProps = {
  children: React.ReactNode;
  flow: UnmanagedHoldingsFlowProps;
  moveSecuritiesConfirmTargets: MoveDrawerRowFragment;
  selectedCheckboxes: Array<string>;
};

export const MoveDrawerRow = ({
  children,
  flow,
  moveSecuritiesConfirmTargets,
  selectedCheckboxes,
}: MoveDrawerRowProps) => {
  return (
    <StyledDestinationRow
      as={Link}
      to="/d/invest/portfolio-organizer"
      state={{
        event: {
          type: 'INITIALIZE_ORGANIZER',
          initConfig: {
            buttons: {
              backButton: {
                label: 'Holdings not in Pies',
                to: '/d/invest/unmanaged-holdings',
              },
              primary: moveSecuritiesConfirmTargets.saveTargetsCtaLabel,
            },
            confirmationDialog: {
              showApplicableLocations: false,
            },
            disabledFeatures: [
              'ADD_SLICES',
              'MOVE_SLICES',
              'REMOVE_SLICES',
              'CREATE_PIE',
            ],
            mode: flow.type === 'MOVE_FLOW' ? 'EDIT_PIE' : 'NEW_PIE',
            pieId: flow.type === 'MOVE_FLOW' ? flow.destinationId : null,
            returnTo:
              flow.type === 'MOVE_FLOW' ? '/d/invest/unmanaged-holdings' : null,
            sliceableIds: selectedCheckboxes,
            subtitle: moveSecuritiesConfirmTargets.header,
            subtitleLink: moveSecuritiesConfirmTargets.link,
            title: moveSecuritiesConfirmTargets.title,
            unmanagedHoldingsFlow: flow,
            zeroPercentDisabled: true,
          },
        },
      }}
    >
      {children}
    </StyledDestinationRow>
  );
};
