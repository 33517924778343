import * as Sentry from '@sentry/browser';

import { baseConfig } from './base-config';
import { AbstractError, GroupedError } from './base-errors';
import { serializeObjectForSentryException } from './sentry-serializer';
import type { SentryConfig } from './types';

export class SentryReporter {
  _enabled: boolean = true;

  constructor(dsn: string, sentryConfig: SentryConfig | null | undefined) {
    Sentry.init({
      debug: __DEBUG__,
      enabled: !__DEBUG__,
      ...baseConfig,
      ...sentryConfig,
      dsn,
      ignoreErrors: ['Failed to fetch'],
      beforeBreadcrumb(breadcrumb, hint) {
        if (breadcrumb.category === 'fetch') {
          return null;
        }
        if (breadcrumb.category === 'ui.click') {
          // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
          const { target } = hint.event;
          if (target && target.tagName && target.textContent) {
            // eslint-disable-next-line no-param-reassign
            breadcrumb.message = `element.tagName: '${target.tagName}'\nelement.textContent: '${target.textContent}'\n`;
          }
        }
        return breadcrumb;
      },
      beforeSend(event, hint) {
        // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
        const exception = hint.originalException;
        if (exception instanceof GroupedError) {
          event.fingerprint = [exception.name]; // eslint-disable-line
        }

        // Doing this manually until a pattern develops. Will abstract later.
        if (
          event.extra &&
          event.extra.TypeError &&
          event.extra.TypeError.sourceURL &&
          /^safari-extension:\/\//.test(event.extra.TypeError.sourceURL)
        ) {
          return null;
        }
        return serializeObjectForSentryException(event);
      },
    });
  }

  setUserContext(userContext: Record<string, any>): void {
    Sentry.configureScope((scope) => scope.setUser(userContext));
  }

  exception(
    e: AbstractError | Error,
    additionalData: Record<string, any> = {},
  ): void {
    if (this._shouldReportErrors()) {
      Sentry.withScope((scope) => {
        if (e instanceof AbstractError) {
          if (e.level) {
            // @ts-expect-error - TS2345 - Argument of type 'LogLevel' is not assignable to parameter of type 'Severity'.
            scope.setLevel(e.level);
          }

          if (e.tags) {
            for (const [k, v] of e.tags) {
              scope.setTag(k, v);
            }
          }
        }
        const serializedAdditionalData =
          serializeObjectForSentryException(additionalData);
        for (const [k, v] of Object.entries(serializedAdditionalData)) {
          switch (k) {
            default:
              scope.setExtra(k, v);
          }
        }
        Sentry.captureException(e);
      });
    }
  }

  message(
    message: string,
    additionalData: Record<string, any> | null | undefined,
  ): void {
    if (this._shouldReportErrors()) {
      Sentry.withScope((scope) => {
        if (additionalData) {
          for (const [k, v] of Object.entries(additionalData)) {
            switch (k) {
              default:
                scope.setExtra(k, v);
            }
          }
        }
        Sentry.captureMessage(message);
      });
    }
  }

  _shouldReportErrors(): boolean {
    return this._enabled;
  }
}
