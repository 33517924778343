import { PL, PXS, Image, styled, Flex } from '@m1/liquid-react';

import * as React from 'react';

import { ExternalLink } from '~/components/ExternalLink/ExternalLink';

// marketing dark logos
import { IntercomLink } from '~/components/IntercomLink';
import MarketingPlayStoreStarsDark from '~/images/marketing/android-stars-reviews-dark.svg';
import MarketingPlayStoreStarsWhite from '~/images/marketing/android-stars-reviews-white.svg';
import MarketingAppStoreStarsDark from '~/images/marketing/apple-stars-review-dark.svg';
import MarketingAppStoreStarsWhite from '~/images/marketing/apple-stars-review-white.svg';
import MarketingAppStoreBadgeDark from '~/images/marketing/apple-store-dark.svg';
import MarketingAppStoreBadgeWhite from '~/images/marketing/apple-store-white.svg';
import MarketingPlayStoreBadgeDark from '~/images/marketing/google-play-dark.svg';

// marketing light logos
import MarketingPlayStoreBadgeWhite from '~/images/marketing/google-play-white.svg';

import { Grid } from '~/toolbox/grid';
import { SmallOrWiderScreen } from '~/toolbox/responsive';

const StyledHr = styled.hr`
  border: 1px solid ${(props) => props.theme.colors.foregroundNeutralTertiary};
  opacity: 0.3;
  margin-bottom: 6px;
  max-width: 350px;
`;

const StyledHrApp = styled.hr`
  border: 1px solid ${(props) => props.theme.colors.foregroundNeutralTertiary};
  opacity: 0.3;
  width: 150px;
  display: inline-flex;
  margin-top: 8px;
  margin-bottom: 8px;
`;

type MarketingAndDisclaimerPartialProps = {
  dynamicContactSupportLink: React.ReactNode | null | undefined;
  themeMode: string;
};

export const MarketingAndDisclaimer = ({
  dynamicContactSupportLink,
  themeMode,
}: MarketingAndDisclaimerPartialProps) => {
  // query for dark mode to determine the app stores to use
  const isDarkMode = themeMode === 'dark';
  const needHelpDesktop = (
    <Grid.Row
      style={{
        paddingBottom: 24,
      }}
      xsTextCenter
    >
      <Grid.Col xs={12}>
        <PL as="span" content="Need help?" pr={24} />
        {dynamicContactSupportLink ? (
          dynamicContactSupportLink
        ) : (
          <IntercomLink />
        )}
      </Grid.Col>
    </Grid.Row>
  );

  const needHelpMobile = (
    <Grid.Row
      style={{
        paddingBottom: 24,
      }}
      xsTextCenter
      xsMiddle
    >
      <Grid.Col sm={2} xs={12}>
        <PL content="Need help?" />
      </Grid.Col>
      <Grid.Col sm={3} xs={12}>
        <IntercomLink />
      </Grid.Col>
    </Grid.Row>
  );

  return (
    <>
      <Grid.Row xsCenter>
        <Grid.Col sm={6} xs={12}>
          <AppStores isDarkMode={isDarkMode} />
        </Grid.Col>
      </Grid.Row>
      <SmallOrWiderScreen>
        {(matches) => (matches ? needHelpDesktop : needHelpMobile)}
      </SmallOrWiderScreen>

      <Grid.Row xsCenter>
        <Grid.Col xs={11}>
          <PXS
            color="foregroundNeutralTertiary"
            content="M1 is a technology company offering a range of financial products and services. “M1” refers to M1 Holdings Inc., and its wholly-owned, separate affiliates M1 Finance LLC, M1 Spend LLC, and M1 Digital LLC."
            mb={8}
          />
          <StyledHr />
          <PXS color="foregroundNeutralTertiary" mb={8}>
            M1 High-Yield Cash Account(s) is an investment product offered by M1
            Finance, LLC, an SEC registered broker-dealer, Member{' '}
            <ExternalLink destination="FINRA" font="PXS">
              FINRA
            </ExternalLink>
            /
            <ExternalLink destination="SIPC" font="PXS">
              SIPC
            </ExternalLink>
            . M1 is not a bank and M1 High-Yield Cash Accounts are not a
            checking or savings account. The purpose of this account is to
            invest in securities, and an open M1 Investment account is required
            to participate in the M1 High-Yield Cash Account. All investing
            involves risk, including the risk of losing the money you invest.
          </PXS>
          <PXS color="foregroundNeutralTertiary" mb={8}>
            Stated APY (annual percentage yield) with the M1 High-Yield Cash
            Account is accrued on account balance. Obtaining stated APY requires
            a minimum initial deposit of $100. APY is solely determined by M1
            Finance LLC and its partner banks, and will include administrative
            and account fees that may reduce earnings. Rates are subject to
            change without notice. M1 High-Yield Cash Account is a separate
            offering from, and not linked to, the M1 High Yield Savings Accounts
            offered by M1 Spend LLC’s banking partner. M1 is not a bank.
          </PXS>
          <PXS color="foregroundNeutralTertiary" mb={8}>
            The cash balance in your Cash Account is eligible for FDIC Insurance
            once it is swept to our partner banks and out of your brokerage
            account. Until the cash balance is swept to partner banks, the funds
            are held in a brokerage account and protected by SIPC insurance.
            Once funds are swept to a partner bank, they are no longer held in
            your brokerage account and are not protected by SIPC insurance. FDIC
            insurance is not provided until the funds participating in the sweep
            program leave your brokerage account and into the sweep program.
            FDIC insurance is applied at the customer profile level. Customers
            are responsible for monitoring their total assets at each of the
            sweep program banks. A complete list of participating program banks
            can be found{' '}
            <ExternalLink
              destination="LEGAL_TERMS_CA_DEPOSIT_NETWORK"
              font="PXS"
            >
              here
            </ExternalLink>
            .
          </PXS>
          <StyledHr />
          <PXS color="foregroundNeutralTertiary" mb={16}>
            © {new Date().getFullYear()} M1 Holdings Inc.
          </PXS>
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

const AppStores = ({ isDarkMode }: { isDarkMode: boolean | undefined }) => {
  if (isDarkMode) {
    return <MarketingAppStoresWhite />;
  }
  return <MarketingAppStoresDark />;
};

const MarketingAppStoresDark = () => {
  return (
    <Flex
      maxWidth={396}
      mx="auto"
      justifyContent="space-between"
      mb={24}
      px={16}
    >
      <Flex flexDirection="column">
        <ExternalLink destination="APPLE_APP_STORE">
          <Image alt="" src={MarketingAppStoreBadgeDark} width={155} />
        </ExternalLink>

        <StyledHrApp />

        <Image alt="" src={MarketingAppStoreStarsDark} width={155} />

        <PXS
          color="foregroundNeutralTertiary"
          mt={8}
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            width: 155,
            fontStyle: 'italic',
          }}
        >
          As of March 2024
        </PXS>
      </Flex>
      <Flex flexDirection="column">
        <ExternalLink destination="GOOGLE_PLAY_STORE">
          <Image alt="" src={MarketingPlayStoreBadgeDark} width={155} />
        </ExternalLink>

        <StyledHrApp />

        <Image alt="" src={MarketingPlayStoreStarsDark} width={155} />

        <PXS
          color="foregroundNeutralTertiary"
          mt={8}
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            width: 155,
            fontStyle: 'italic',
          }}
        >
          As of March 2024
        </PXS>
      </Flex>
    </Flex>
  );
};

const MarketingAppStoresWhite = () => {
  return (
    <Flex
      maxWidth={396}
      mx="auto"
      justifyContent="space-between"
      mb={24}
      px={16}
    >
      <Flex flexDirection="column">
        <ExternalLink destination="APPLE_APP_STORE">
          <Image alt="" src={MarketingAppStoreBadgeWhite} width={155} />
        </ExternalLink>

        <StyledHrApp />

        <Image alt="" src={MarketingAppStoreStarsWhite} width={155} />

        <PXS
          color="foregroundNeutralTertiary"
          mt={8}
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            width: 155,
            fontStyle: 'italic',
          }}
        >
          As of March 2024
        </PXS>
      </Flex>
      <Flex flexDirection="column">
        <ExternalLink destination="GOOGLE_PLAY_STORE">
          <Image alt="" src={MarketingPlayStoreBadgeWhite} width={155} />
        </ExternalLink>

        <StyledHrApp />

        <Image alt="" src={MarketingPlayStoreStarsWhite} width={155} />

        <PXS
          color="foregroundNeutralTertiary"
          mt={8}
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            width: 155,
            fontStyle: 'italic',
          }}
        >
          As of March 2024
        </PXS>
      </Flex>
    </Flex>
  );
};
