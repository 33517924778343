import { Text, type TextProps } from '@m1/liquid-react';
import * as React from 'react';

export type PositionGainTextProps = TextProps & {
  render?: (arg0: {
    shouldPluralize: boolean;
    value: string;
  }) => React.ReactNode;
  value: number;
};

export const PositionGainText = ({
  render,
  value,
  ...rest
}: PositionGainTextProps) => {
  const shouldPluralize = value !== 1;
  const valueAsString = readValue(value);
  return (
    <Text
      {...rest}
      color={readColor(value)}
      content={
        render
          ? render({
              shouldPluralize,
              value: valueAsString,
            })
          : valueAsString
      }
    />
  );
};

const readColor = (value: number): TextProps['color'] => {
  if (value > 0) {
    return 'success';
  } else if (value < 0) {
    return 'critical';
  }
  return 'foregroundNeutralMain';
};

const readValue = (quantity: number): string => {
  return quantity > 0
    ? `+${quantity.toString(10)}`
    : `${quantity.toString(10)}`;
};
