import * as React from 'react';

import { Pie, Slice } from '~/pie-trees';

type UseMoveSlicesDisabledResponse = {
  disabled: boolean;
  reason:
    | 'ALL_SLICES_ARE_SELECTED'
    | 'INCLUDES_NEW_SLICES'
    | 'INCLUDES_INACTIVE_SLICES'
    | 'INCLUDES_ZERO_PERCENT_SLICES'
    | null;
};

export const useMoveSlicesDisabled = (
  allSliceIds: Array<string>,
  selectedSlices: Array<Slice>,
  sourcePieBeforeUpdates: Pie,
): UseMoveSlicesDisabledResponse => {
  const selectedSlicesIds = selectedSlices.map((slice) => slice.to.__id);

  const selectedIncludesNewSlices = React.useMemo<boolean>(() => {
    return selectedSlices.some(
      (slice) =>
        !(sourcePieBeforeUpdates.slices || []).find(
          (s) => s.to.__id === slice.to.__id,
        ),
    );
  }, [selectedSlices, sourcePieBeforeUpdates]);

  const selectedIncludesInactiveSlices = React.useMemo<boolean>(() => {
    return selectedSlices.some(
      (slice) => slice.to.type === 'security' && !slice.to.isActive,
    );
  }, [selectedSlices]);

  const selectedIncludesZeroPercentSlices = React.useMemo<boolean>(() => {
    return selectedSlices.some((slice) => slice.percentage === 0);
  }, [selectedSlices]);

  const allSlicesAreSelected = React.useMemo(() => {
    return Boolean(
      selectedSlicesIds.length &&
        selectedSlicesIds.length === allSliceIds.length,
    );
  }, [allSliceIds, selectedSlicesIds]);

  let reason = null;
  if (allSlicesAreSelected) {
    reason = 'ALL_SLICES_ARE_SELECTED';
  } else if (selectedIncludesNewSlices) {
    reason = 'INCLUDES_NEW_SLICES';
  } else if (selectedIncludesInactiveSlices) {
    reason = 'INCLUDES_INACTIVE_SLICES';
  } else if (selectedIncludesZeroPercentSlices) {
    reason = 'INCLUDES_ZERO_PERCENT_SLICES';
  }

  const disabled =
    allSlicesAreSelected ||
    selectedSlices.length === 0 ||
    selectedIncludesNewSlices ||
    selectedIncludesInactiveSlices ||
    selectedIncludesZeroPercentSlices;

  return {
    disabled,
    // @ts-expect-error - TS2322 - Type 'string | null' is not assignable to type '"ALL_SLICES_ARE_SELECTED" | "INCLUDES_NEW_SLICES" | "INCLUDES_INACTIVE_SLICES" | "INCLUDES_ZERO_PERCENT_SLICES" | null'.
    reason,
  };
};
