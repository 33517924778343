import * as React from 'react';

import { CollectHolderSsnStep } from './CollectHolderSSNStep';

export const CollectSecondaryHolderSsnStep = (rest: any) => (
  <CollectHolderSsnStep
    holder="secondary"
    fieldLabel="Secondary account holder SSN"
    {...rest}
  />
);
