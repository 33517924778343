import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { useWizard, WizardProvider } from '~/flows/wizard';
import { AuthAppSetup } from '~/pages/dashboard/settings/security/components/steps/AuthAppSetup';
import { CollectAmfaCode } from '~/pages/dashboard/settings/security/components/steps/CollectAmfaCode';
import { DisableTwoFactorAuth } from '~/pages/dashboard/settings/security/components/steps/DisableTwoFactorAuth';
import { InitializeTwoFactorChange } from '~/pages/dashboard/settings/security/components/steps/InitializeTwoFactorChange';
import { PerformEnhancedAuth } from '~/pages/dashboard/settings/security/components/steps/PerformEnhancedAuth';

type TwoFactorAuthFlowProps = {
  exitFlow: () => void;
};

type TwoFactorWizardSteps =
  | 'INITIALIZE_TWO_FACTOR_CHANGE'
  | 'PERFORM_ENHANCED_AUTH'
  | 'COLLECT_AMFA_CODE'
  | 'DISABLE_TWO_FACTOR_AUTH'
  | 'AUTH_APP_SETUP';

type TwoFactorAuthWizardSteps = Record<
  TwoFactorWizardSteps,
  React.ReactElement
>;

export const TwoFactorWizard = ({ exitFlow }: TwoFactorAuthFlowProps) => {
  const steps: TwoFactorAuthWizardSteps = {
    INITIALIZE_TWO_FACTOR_CHANGE: (
      <InitializeTwoFactorChange exitFlow={exitFlow} />
    ),
    DISABLE_TWO_FACTOR_AUTH: <DisableTwoFactorAuth exitFlow={exitFlow} />,
    PERFORM_ENHANCED_AUTH: <PerformEnhancedAuth exitFlow={exitFlow} />,
    COLLECT_AMFA_CODE: <CollectAmfaCode exitFlow={exitFlow} />,
    AUTH_APP_SETUP: <AuthAppSetup exitFlow={exitFlow} />,
  };

  const { step, ...rest } = useWizard(steps);

  return (
    <Flex width={1200} justifyContent="center">
      <PageErrorBoundary isRetryAllowed onClickRetry={exitFlow}>
        <WizardProvider value={{ step, ...rest }}>{step}</WizardProvider>
      </PageErrorBoundary>
    </Flex>
  );
};
