import { Flex, PL, Card } from '@m1/liquid-react';
import * as React from 'react';

export const RewardProgramEmptyState = ({
  rewardsTitle,
}: {
  rewardsTitle: string | null | undefined;
}) => (
  <>
    <PL fontWeight={600} m="16px 0 8px 0" content={rewardsTitle} />
    <Card height={72} mb={32}>
      <Flex
        alignItems="center"
        justifyContent="center"
        px={16}
        height="100%"
        width="100%"
      >
        <PL
          color="foregroundNeutralSecondary"
          content={
            rewardsTitle === 'Stocks in your portfolio'
              ? 'You do not own any Owner’s Rewards eligible stocks in your portfolio'
              : 'You own all eligible stocks that are part of the Owner’s Rewards program'
          }
        />
      </Flex>
    </Card>
  </>
);
