import { Box } from '@m1/liquid-react';
import React from 'react';

import { ProcessingRequest } from '~/components/ProcessingRequest';

export const ProcessingApplication = () => {
  return (
    <Box mt={-60}>
      <ProcessingRequest
        heading="We have submitted your application."
        content="This may take up to a minute."
      />
    </Box>
  );
};
