import * as React from 'react';
import { Route } from 'react-router-dom';

import { TransferAcatInstanceDetailsPage } from './TransferAcatInstanceDetailsPage';

export const TransferAcatInstanceDetailsRoute = () => {
  return (
    <Route
      path="acat-transfer-details/:transferInstanceId"
      element={<TransferAcatInstanceDetailsPage />}
      handle={{
        fallbackRoute: {
          to: '/d/transfers',
        },
      }}
    />
  );
};
