import * as React from 'react';

import { LabelWithValue } from '~/components/label-with-value';
import { PieAnalysisFragment } from '~/graphql/types';
import { Grid } from '~/toolbox/grid';

export const PieAnalysisMetrics = ({
  pieAnalysis,
  isCrypto,
}: {
  pieAnalysis: PieAnalysisFragment;
  isCrypto?: boolean;
}) => {
  return (
    <Grid.Row>
      <PieAnalysisMetric
        label="Holdings"
        value={
          typeof pieAnalysis?.totalHoldings === 'number'
            ? pieAnalysis.totalHoldings
            : '--'
        }
      />
      {!isCrypto && (
        <>
          <PieAnalysisMetric
            label="Dividend Yield"
            value={
              typeof pieAnalysis?.dividendYield === 'number'
                ? `${pieAnalysis.dividendYield}%`
                : '--'
            }
          />
          <PieAnalysisMetric
            label="Expense Ratio"
            value={
              typeof pieAnalysis?.netExpenseRatio === 'number'
                ? `${pieAnalysis.netExpenseRatio}%`
                : '--'
            }
          />
        </>
      )}
    </Grid.Row>
  );
};

const PieAnalysisMetric = ({
  label,
  value,
}: {
  label: string;
  value: string | number;
}) => (
  <Grid.Col
    sm={4}
    xs={6}
    css={{
      paddingBottom: 10,
    }}
  >
    <LabelWithValue emphasis="neither" label={label} value={value} />
  </Grid.Col>
);
