import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import {
  HasFundingSourceSagaDocument,
  HasFundingSourceSagaQueryResult,
} from '~/graphql/hooks';

import { apolloQuerySaga } from '../apolloQuerySaga';

export function* hasFundingSourceSaga(): SagaIterator<boolean> {
  const { data }: HasFundingSourceSagaQueryResult = yield call(
    apolloQuerySaga,
    {
      query: HasFundingSourceSagaDocument,
    },
  );

  if (!data?.viewer || !data.viewer.fundingSources) {
    throw new Error(
      'Unable to determine whether the user has a funding source',
    );
  }

  return Boolean(data.viewer.fundingSources.hasFundingSources);
}
