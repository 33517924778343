import { Box, TooltipProps } from '@m1/liquid-react';
import * as React from 'react';

import {
  SecurityDetail_Equity_Fragment,
  SecurityDetail_Fund_Fragment,
} from '~/graphql/types';
import { AppImage } from '~/lens-toolbox/AppImage';

import { MissingSecurityData } from './MissingSecurityData';
import { SecurityDataContainer } from './SecurityDataContainer';
import { SecurityDataRow } from './SecurityDataRow';
import { SecurityMarginPopup } from './SecurityMarginPopup';

export type SecurityMarginRequirementsProps = {
  security:
    | SecurityDetail_Equity_Fragment
    | SecurityDetail_Fund_Fragment
    | null
    | undefined;
};

export const SecurityMarginRequirements = ({
  security,
}: SecurityMarginRequirementsProps) => {
  if (!security) {
    return (
      <SecurityDataContainer
        headingContent="Borrowing Data"
        width={380}
        flexDirection="column"
      >
        <MissingSecurityData title="No borrowing data" />
      </SecurityDataContainer>
    );
  }

  const {
    symbol,
    marginEligibileTooltip,
    marginEligibleIcon,
    maintenanceMargin,
    marginRequirementTooltip,
    isMarginable,
  } = security;

  return (
    <Box data-testid="selector-borrow-against-info">
      <SecurityDataContainer
        headingContent={`Borrowing Against ${symbol}`}
        flexDirection="column"
      >
        <>
          <SecurityDataRow
            headingContent={marginEligibileTooltip.label}
            tooltip={
              <SecurityMarginPopup
                icon={
                  marginEligibileTooltip.icon.names?.[0] as TooltipProps['icon']
                }
                iconColor={
                  marginEligibileTooltip.icon.color as TooltipProps['iconColor']
                }
                text={marginEligibileTooltip.text}
              />
            }
            valueIcon={
              marginEligibleIcon && <AppImage appImage={marginEligibleIcon} />
            }
            value={isMarginable ? 'Yes' : 'No'}
          />
          <SecurityDataRow
            headingContent={marginRequirementTooltip.label}
            tooltip={
              <SecurityMarginPopup
                icon={
                  marginRequirementTooltip.icon
                    .names?.[0] as TooltipProps['icon']
                }
                iconColor={
                  marginRequirementTooltip.icon
                    .color as TooltipProps['iconColor']
                }
                text={marginRequirementTooltip.text}
              />
            }
            value={maintenanceMargin && `${maintenanceMargin * 100}%`}
          />
        </>
      </SecurityDataContainer>
    </Box>
  );
};
