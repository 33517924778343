import { CampaignAttributionInput } from '~/graphql/types';
import { ACTION_TYPES as ACTIONS } from '~/redux/actions';

type Tokens = {
  resetPassword: string | null | undefined;
  verifyEmail: string | null | undefined;
};

export type ConfigState = {
  affiliateCode: string | null | undefined;
  attribution: CampaignAttributionInput | null | undefined;
  email: string | null | undefined;
  landingUrl: string | null | undefined;
  pieKey: string | null | undefined;
  promoCode: string | null | undefined;
  referrerCode: string | null | undefined;
  tokens: Tokens;
};

const initialState: ConfigState = {
  attribution: null,
  email: null,
  landingUrl: null,
  pieKey: null,
  promoCode: null,
  referrerCode: null,
  affiliateCode: null,
  tokens: {
    resetPassword: null,
    verifyEmail: null,
  },
};

export const configReducer = (
  state: ConfigState = initialState,
  action: any,
): ConfigState => {
  switch (action.type) {
    case ACTIONS.CACHE_APP_CONFIG:
      return action.payload;
    case ACTIONS.DETERMINED_REFERRAL:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.DETERMINED_CAMPAIGN_ATTRIBUTION:
      return {
        ...state,
        attribution: action.payload,
      };
    default:
      return state;
  }
};
