import { Box, Button, HXXS, PL } from '@m1/liquid-react';
import * as React from 'react';

import { ExternalLink } from '~/components/ExternalLink';
import { clickedCancelInvestAccountSetup } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { Grid } from '~/toolbox/grid';

export const SystemUnavailableStep = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(clickedCancelInvestAccountSetup());
  };

  return (
    <Grid constrain>
      <Grid.Row xsCenter>
        <Grid.Col
          // @ts-expect-error - TS2769 - No overload matches this call.
          style={{
            maxWidth: 420,
          }}
        >
          <Box pb={16}>
            <HXXS content="We are down momentarily for routine maintenance." />
          </Box>
          <Box pb={8}>
            <PL content="We're sorry for the inconvenience but will be back up and running shortly." />
          </Box>
          <Box pb={48}>
            <PL>
              If you have an urgent question, please contact our{' '}
              <ExternalLink destination="SUPPORT_CENTER">
                Support Team
              </ExternalLink>
              . Thank you!
            </PL>
          </Box>
          <Button
            kind="primary"
            size="large"
            fullWidth
            label="Ok"
            onClick={handleClick}
          />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};
