export const INTERFACE_ACTIONS = Object.freeze({
  CLICKED_ON_SLICEABLE: 'CLICKED_ON_SLICEABLE' as const,
  HIGHLIGHT_SLICE: 'HIGHLIGHT_SLICE' as const,
});

export type ClickedOnSliceableAction = {
  isCrypto?: boolean;
  payload: string;
  type: typeof INTERFACE_ACTIONS.CLICKED_ON_SLICEABLE;
};

export type HighlightSliceAction = {
  payload: string | null | undefined;
  type: typeof INTERFACE_ACTIONS.HIGHLIGHT_SLICE;
};

export const clickedOnSliceable = (
  payload: ClickedOnSliceableAction['payload'],
  isCrypto?: boolean,
): ClickedOnSliceableAction => ({
  type: INTERFACE_ACTIONS.CLICKED_ON_SLICEABLE,
  payload: payload,
  isCrypto,
});

export const highlightSlice = (
  payload: HighlightSliceAction['payload'],
): HighlightSliceAction => ({
  type: INTERFACE_ACTIONS.HIGHLIGHT_SLICE,
  payload: payload,
});

export type InterfaceAction = ClickedOnSliceableAction | HighlightSliceAction;
