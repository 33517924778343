import { Box } from '@m1/liquid-react';
import Highcharts from 'highcharts';
import * as React from 'react';

import { CashFormatter } from '~/utils';

type ChartableSliceChartTooltipProps = {
  percentChange: string;
  y: number | null | undefined;
  x: string | number | undefined;
};

export const ChartableSliceChartTooltip = ({
  percentChange,
  y,
  x,
}: ChartableSliceChartTooltipProps) => (
  <>
    <Box color="foregroundNeutralMain">
      {CashFormatter.format(y ?? 0)}
      {percentChange}
    </Box>
    {typeof x === 'number' ? (
      <Box color="foregroundNeutralSecondary" borderColor="borderMain">
        {Highcharts.dateFormat('%B %e, %Y', x)}
      </Box>
    ) : null}
  </>
);
