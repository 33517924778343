import * as React from 'react';

import { CellIconName, Cell } from '~/toolbox/cell';

import { ExternalLink } from '../ExternalLink';

export type TimingDescriptionCellProps = TimingDescriptionCellOptionalProps & {
  dependsOnHolds: boolean | null | undefined;
  dependsOnSells: boolean | null | undefined;
  timingDescription: string | null | undefined;
};

export type TimingDescriptionCellOptionalProps = {
  style?: React.CSSProperties;
};

export const TimingDescriptionCell = (props: TimingDescriptionCellProps) => {
  const { dependsOnHolds, dependsOnSells, timingDescription } = props;
  if (!timingDescription) {
    return null;
  }

  let title;
  let body = timingDescription;
  let iconName: CellIconName = 'hold24';
  if (dependsOnHolds || dependsOnSells) {
    // @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'string'.
    body = (
      <>
        {body}{' '}
        <ExternalLink
          destination="SUPPORT_TRANSFER_HOLDS_SELLS"
          label="Learn more"
          font="LS"
        />
      </>
    );

    if (!dependsOnHolds) {
      title = 'Pending sells';
      iconName = 'bell24';
    } else if (!dependsOnSells) {
      title = 'Pending holds';
    } else {
      title = 'Pending holds and sells';
    }
  }

  return (
    <Cell
      content={body}
      iconName={iconName}
      iconAlignSelf="flex-start"
      title={title}
      titleFont="PM"
    />
  );
};
