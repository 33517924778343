export const PERFORMANCE_PERIOD_OPTIONS = [
  {
    label: '1Y',
    value: 'ONE_YEAR',
  },
  {
    label: '2Y',
    value: 'TWO_YEARS',
  },
  {
    label: '5Y',
    value: 'FIVE_YEARS',
  },
];
