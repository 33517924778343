import { Flex, styled } from '@m1/liquid-react';
import QRious from 'qrious';
import * as React from 'react';

const StyledCantScanButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 8px 0 0;
  padding: 0;
  color: var(--colors-primary);
  cursor: pointer;
  appearance: none;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
  }
`;

type QRCodeDisplayProps = {
  uri: string;
  onCantScanClick?: () => void;
};

export const QRCodeDisplay = ({ uri, onCantScanClick }: QRCodeDisplayProps) => {
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);

  React.useEffect(() => {
    if (canvasRef.current === null) {
      return;
    }

    const _ = new QRious({ element: canvasRef.current, value: uri });
  }, [uri]);

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      my={16}
    >
      <canvas ref={canvasRef} />
      {onCantScanClick && (
        <StyledCantScanButton onClick={onCantScanClick}>
          Can't scan the barcode?
        </StyledCantScanButton>
      )}
    </Flex>
  );
};
