import * as React from 'react';
import { Route } from 'react-router-dom';

import { CustodialBeneficiaryPage } from './components/CustodialBeneficiary';

export const CustodialBeneficiaryRoute = () => {
  return (
    <Route
      path="custodial-beneficiary"
      element={<CustodialBeneficiaryPage />}
    />
  );
};
