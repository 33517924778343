import cloneDeep from 'lodash-es/cloneDeep';
import pick from 'lodash-es/pick';

import { Pie, updatePieTreeByPath } from '~/pie-trees';
import { ACTION_TYPES as ACTIONS, ROUTING_ACTIONS } from '~/redux/actions';
import { PORTFOLIO_EDITOR_STEPS } from '~/static-constants';

import { FlowState } from '../newFlowsReducer.types';
import { createFlowReducer, createStepReducer } from '../utils';

// Helper utility that looks up the active pie being edited and returns a reference to it
export const getActivePie = (
  state: PortfolioEditorFlowState,
): Pie | null | undefined => {
  let pie = null;
  if (state.activePieId && state.activePieId === state.sourcePie.__id) {
    pie = state.sourcePie;
  } else if (
    state.activePieId &&
    state.activePieId === state.destinationPie.__id
  ) {
    pie = state.destinationPie;
  }

  return pie;
};

export type PortfolioEditorFlowState = FlowState<
  typeof PORTFOLIO_EDITOR_STEPS,
  {
    activePieId: string | null | undefined;
    // The id of the pie being edited at any step in the flow
    basePath: string;
    destinationPie: Pie;
    destinationPiePortfolioSliceId: string;
    history: Array<Pie>;
    // used for persisting state for navigating back
    moveSliceIds: Array<string>;
    // an array of portfolio slice ids to move
    path: Array<string>;
    sourcePie: Pie;
    sourcePieBeforeUpdates: Pie;
    sourcePiePortfolioSliceId: string;
  }
>;

// The default or empty state for our Pies in state
const defaultPie = {
  __checked: false,
  __highlighted__: false,
  __shouldEqualizeSlicePercentagesOnAdd: true,
  __id: 'root-portfolio-pie',
  description: '',
  name: 'My Portfolio',
  slices: [],
  type: 'new_pie',
};

const initialState: PortfolioEditorFlowState = {
  basePath: '',
  activePieId: null,
  // @ts-expect-error - TS2322 - Type '{ __checked: boolean; __highlighted__: boolean; __shouldEqualizeSlicePercentagesOnAdd: boolean; __id: string; description: string; name: string; slices: never[]; type: string; }' is not assignable to type 'Pie'.
  destinationPie: defaultPie,
  destinationPiePortfolioSliceId: '',
  history: [],
  path: [],
  moveSliceIds: [],
  // @ts-expect-error - TS2322 - Type '{ __checked: boolean; __highlighted__: boolean; __shouldEqualizeSlicePercentagesOnAdd: boolean; __id: string; description: string; name: string; slices: never[]; type: string; }' is not assignable to type 'Pie'.
  sourcePie: defaultPie,
  // @ts-expect-error - TS2322 - Type '{ __checked: boolean; __highlighted__: boolean; __shouldEqualizeSlicePercentagesOnAdd: boolean; __id: string; description: string; name: string; slices: never[]; type: string; }' is not assignable to type 'Pie'.
  sourcePieBeforeUpdates: defaultPie,
  sourcePiePortfolioSliceId: '',
  step: PORTFOLIO_EDITOR_STEPS.EDIT_PORTFOLIO,
  stepTitle: '',
};

const stepReducer = createStepReducer(initialState);

function reducer(
  state: PortfolioEditorFlowState = initialState,
  action: any,
): PortfolioEditorFlowState {
  switch (action.type) {
    case ACTIONS.BEGIN_FLOW: {
      // If the flow is already started and we have the basePath (i.e we have state) then just return state.
      if (state.basePath && state.basePath === action.payload.basePath) {
        return state;
      }

      return {
        ...initialState,
        ...pick(action.payload, Object.keys(initialState)),
        activePieId: action.payload.portfolioSliceableId,
        sourcePiePortfolioSliceId: action.payload.portfolioSliceId,
        step: initialState.step,
      };
    }
    case ACTIONS.END_FLOW:
      if (action.payload.preserveStateOnUnmount) {
        return state;
      }

      return initialState;
    case ACTIONS.CLICKED_MOVE_SLICES_DESTINATION: {
      const { destinationPortfolioSliceId, slicesToMove } = action.payload;

      // We need to store the source pie in history so we can nav back
      // and have the same state
      const history = [...state.history];
      history.push(cloneDeep(state.sourcePie));

      const moveSliceIds = slicesToMove.map(
        // @ts-expect-error - TS7006 - Parameter 'portfolioSlice' implicitly has an 'any' type.
        (portfolioSlice) => portfolioSlice.id,
      );

      return {
        ...state,
        history,
        moveSliceIds,
        destinationPiePortfolioSliceId: destinationPortfolioSliceId,
      };
    }
    case ACTIONS.FETCHED_PIE_DATA_FOR_PORTFOLIO_EDITOR: {
      const { pie, type } = action.payload;

      if (type === 'SOURCE_PIE') {
        return {
          ...state,
          activePieId: pie.id,
          sourcePie: pie,
          sourcePieBeforeUpdates: cloneDeep(pie),
        };
      }

      if (type === 'DESTINATION_PIE') {
        return {
          ...state,
          activePieId: pie.id,
          destinationPie: pie,
        };
      }

      return state;
    }
    case ACTIONS.FETCHED_PIE_SLICEABLES_FOR_PORTFOLIO_EDITOR: {
      const pie = getActivePie(state);

      if (pie) {
        updatePieTreeByPath(pie, state.path, {
          toAdd: action.payload,
        });

        return cloneDeep(state);
      }

      return state;
    }
    case ACTIONS.UPDATE_PIE_DESCRIPTION_FOR_PORTFOLIO_EDITOR: {
      const pie = getActivePie(state);

      if (pie) {
        updatePieTreeByPath(pie, state.path, {
          toUpdateDescription: action.payload,
        });

        return cloneDeep(state);
      }

      return state;
    }
    case ACTIONS.UPDATE_PIE_NAME_FOR_PORTFOLIO_EDITOR: {
      const pie = getActivePie(state);

      if (pie) {
        updatePieTreeByPath(pie, state.path, {
          toUpdateName: action.payload,
        });

        return cloneDeep(state);
      }

      return state;
    }
    case ACTIONS.CREATED_PORTFOLIO_EDITOR_NEW_PIE_SLICE: {
      const pie = getActivePie(state);

      if (pie) {
        updatePieTreeByPath(pie, state.path, {
          toAdd: [action.payload],
        });

        return cloneDeep(state);
      }

      return state;
    }
    case ACTIONS.CLICKED_PORTFOLIO_EDITOR_PIE_SLICE:
      return {
        ...state,
        path: [...state.path, action.payload.__id],
      };
    case ACTIONS.CLICKED_PORTFOLIO_EDITOR_BREADCRUMB:
      return {
        ...state,
        path: action.payload,
      };
    case ACTIONS.ADJUSTED_PORTFOLIO_EDITOR_SLICE_PERCENTAGE: {
      const pie = getActivePie(state);

      if (pie) {
        updatePieTreeByPath(pie, state.path, {
          toAdjustPercentage: action.payload,
        });

        return cloneDeep(state);
      }
      return state;
    }
    case ACTIONS.GO_BACK_FROM_SET_DESTINATION_TARGETS: {
      const { history, sourcePie } = action.payload;

      return {
        ...state,
        activePieId: sourcePie.__id,
        // @ts-expect-error - TS2322 - Type '{ __checked: boolean; __highlighted__: boolean; __shouldEqualizeSlicePercentagesOnAdd: boolean; __id: string; description: string; name: string; slices: never[]; type: string; }' is not assignable to type 'Pie'.
        destinationPie: defaultPie,
        history,
        moveSliceIds: [],
        sourcePie,
      };
    }
    case ACTIONS.GO_BACK_FROM_SET_SOURCE_PIE: {
      const { history, activePieId, sourcePie } = action.payload;

      return {
        ...state,
        history,
        activePieId,
        sourcePie,
      };
    }
    case ACTIONS.REMOVE_PORTFOLIO_EDITOR_ACTIVE_SLICE_IDS: {
      const pie = getActivePie(state);

      if (pie) {
        updatePieTreeByPath(pie, state.path, {
          toUpdateCheckState: [action.payload.ids, false],
        });

        return cloneDeep(state);
      }

      return state;
    }
    case ACTIONS.REMOVE_PORTFOLIO_EDITOR_SLICES: {
      const pie = getActivePie(state);

      if (pie) {
        updatePieTreeByPath(pie, state.path, {
          toRemove: action.payload,
        });

        return cloneDeep(state);
      }

      return state;
    }
    case ACTIONS.UPDATE_PORTFOLIO_EDITOR_ACTIVE_SLICE_IDS: {
      const pie = getActivePie(state);

      if (pie) {
        updatePieTreeByPath(pie, state.path, {
          toUpdateCheckState: [action.payload.ids, true],
        });

        return cloneDeep(state);
      }
      return state;
    }
    case ROUTING_ACTIONS.LOCATION_CHANGE: {
      const pathname = action.payload.location.pathname;

      if (
        pathname.includes('portfolio-editor') ||
        pathname.includes('add-slices')
      ) {
        return {
          ...state,
          step: stepReducer(state, action),
        };
      }

      return initialState;
    }
    case ACTIONS.FINISHED_FLOW_STEP: {
      const step = action.meta.step;
      const goBack = action.payload.goBack;

      if (step === PORTFOLIO_EDITOR_STEPS.DESTINATION_PIE && !goBack) {
        const history = [...state.history];
        const destinationPie = getActivePie(state);

        // Push the destination pie on the history
        if (destinationPie) {
          history.push(destinationPie);
        }

        return {
          ...state,
          activePieId: state.sourcePie.__id,
          history,
        };
      }

      return state;
    }
    case ACTIONS.CONFIRMED_PIE_ORGANIZER_EXIT:
      return initialState;
    default:
      return state;
  }
}

export const portfolioEditorReducer = createFlowReducer(
  'PORTFOLIO_EDITOR',
  reducer,
);
