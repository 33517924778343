import { stepToProgress } from '~/components/StepToProgress';
import { INITIAL_FUNDING_FLOW_STEPS as STEPS } from '~/static-constants';

export const InitialFundingFlowProgress = stepToProgress(
  (state) => state.newFlows.initialFunding,
  {
    [STEPS.CONNECT_EXTERNAL_BANK]: 33,
    [STEPS.MAKE_INITIAL_DEPOSIT]: 66,
    [STEPS.SKIPPED_DEPOSIT_CONFIRMATION]: 80,
    [STEPS.FUNDED_DEPOSIT_CONFIRMATION]: 80,
  },
);
