import * as React from 'react';
import { Route } from 'react-router-dom';

import { InvestBankConnectionPage } from '~/pages/dashboard/bank-connection/InvestBankConnectionPage';

export const InvestBankConnectionRoute = () => {
  return (
    <Route path="bank-connection" element={<InvestBankConnectionPage />} />
  );
};
