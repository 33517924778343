import { styled, Box } from '@m1/liquid-react';
import * as React from 'react';

type PhantomLandingZoneProps = {
  height: number;
  showLandingZone: boolean;
  verticalPositionOfLandingBackground: number;
};

const StyledContainer = styled(Box)<{
  $height: number;
  $isVisible: boolean;
  $verticalDisplacement: number;
}>`
  background-color: ${({ $isVisible, theme }) =>
    $isVisible
      ? theme.colors.backgroundPrimarySubtle
      : theme.colors.backgroundNeutralSecondary};
  opacity: ${({ $isVisible }) => ($isVisible ? 0.8 : 0)};
  padding-top: ${({ $height }) => $height}px;
  position: absolute;
  transform: translateY(
    ${({ $verticalDisplacement }) => $verticalDisplacement}px
  );
  transition:
    transform 250ms ${({ $isVisible }) => ($isVisible ? 0 : 400)}ms,
    opacity 400ms,
    background-color 400ms,
    z-index 450ms;
  width: 100%;
  z-index: ${({ $isVisible }) => ($isVisible ? 2 : -1)};
`;

export const PhantomLandingZone = ({
  height,
  showLandingZone,
  verticalPositionOfLandingBackground,
}: PhantomLandingZoneProps) => {
  // increase the height by 25% so that the landing zone appears more helpful to the user
  const heightOfPhantomDiv = height * 1.25;

  return (
    <StyledContainer
      $isVisible={showLandingZone}
      $verticalDisplacement={verticalPositionOfLandingBackground}
      $height={heightOfPhantomDiv}
    />
  );
};
