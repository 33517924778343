export const MODE_ACTIONS = Object.freeze({
  UPDATE_MODE: 'UPDATE_MODE' as const,
  RESET_MODE: 'RESET_MODE' as const,
});

export type UpdateModeAction = {
  payload: 'ADD' | 'DEFAULT';
  type: typeof MODE_ACTIONS.UPDATE_MODE;
};

export type ResetModeAction = {
  type: typeof MODE_ACTIONS.RESET_MODE;
};

export const updateMode = (
  payload: UpdateModeAction['payload'],
): UpdateModeAction => ({
  type: MODE_ACTIONS.UPDATE_MODE,
  payload,
});

export const resetMode = (): ResetModeAction => ({
  type: MODE_ACTIONS.RESET_MODE,
});

export type ModeAction = UpdateModeAction | ResetModeAction;
