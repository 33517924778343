import { Flex, PS, Card } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

export const MarketResearchEmptyStateCard = () => (
  <Card
    backgroundColor="backgroundNeutralMain"
    height={310}
    p={32}
    width="100%"
  >
    <Flex
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      height="100%"
    >
      <Icon name="alert16" color="foregroundNeutralSecondary" />
      <PS textAlign="center" color="foregroundNeutralSecondary">
        Sorry, we're having trouble retrieving this information. Try again
        later.
      </PS>
    </Flex>
  </Card>
);
