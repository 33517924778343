import { Box } from '@m1/liquid-react';
import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { AppNotifications } from '~/components/notifications';
import { LayoutProvider } from '~/hooks/useLayout';

import { Navigation } from './navigation/Navigation';

export const DashboardLayout = () => {
  return (
    <LayoutProvider>
      <Box>
        <Box>
          <AppNotifications />
        </Box>

        <Navigation>
          <Outlet />
        </Navigation>
      </Box>
    </LayoutProvider>
  );
};
