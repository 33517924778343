import * as React from 'react';

import { AcatWizardQueryResult } from '~/graphql/hooks';

import { AcatWizardQueryContext } from '../providers/AcatWizardQueryProvider';

export type InvestAcatWizardContent = NonNullable<
  NonNullable<
    NonNullable<AcatWizardQueryResult['data']>['viewer']['invest']
  >['acatWizard']
>;

export type InvestAcatDestinationAccountContent = NonNullable<
  InvestAcatWizardContent['destinationAccountContent']
>;

export const useAcatWizardQueryContext = () => {
  const context = React.useContext(AcatWizardQueryContext);
  if (!context) {
    throw new Error(
      'You must place useAcatWizardQueryContext within AcatWizardQueryProvider',
    );
  }
  return context;
};
