import moment from 'moment';
import * as React from 'react';
import { SubmissionError } from 'redux-form';

import {
  useSavingsFundAccountQuery,
  useCreateTransferInstanceMutation,
  useSetScheduledTransferRuleMutation,
} from '~/graphql/hooks';
import {
  SavingsFundAccountQuery,
  TransferParticipantFragment,
  TransferTypeEnum,
  RecurrenceScheduleInput,
} from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useSelector, useDispatch } from '~/redux/hooks';
import { CREATE_TRANSFER_FLOW_MODES as MODES } from '~/static-constants';
import { useToast } from '~/toasts';
import { Spinner } from '~/toolbox/spinner';
import { formatNumber } from '~/utils';

import { SavingsInitialDepositForm } from '../components/SavingsInitialDepositForm';

type SavingsFundAccountProps = {
  onFinishStep: (payload?: 'skip' | 'general-error') => void;
};
type Transfers = SavingsFundAccountQuery['viewer']['transfers'];
type HandleSubmitArgs = {
  amount: string;
  fromParticipantId: string;
  toParticipantId: string;
  schedule?: RecurrenceScheduleInput;
};

const transferLimitsValidation = (value: string, transfers: Transfers) => {
  const amount = Number(value);
  if (transfers?.requirements) {
    const minTransferAmount = transfers.requirements.minTransferAmount;
    const maxTransferAmount = transfers.requirements.maxTransferAmount;

    if (maxTransferAmount && maxTransferAmount === 0) {
      return `Please fund your account.`;
    }
    if (maxTransferAmount && amount > maxTransferAmount) {
      return `The maximum transfer amount is ${formatNumber(
        maxTransferAmount,
      )}`;
    }

    if (minTransferAmount && amount < minTransferAmount) {
      return `The minimum transfer amount is ${formatNumber(
        minTransferAmount,
      )}`;
    }
  }
};

export const SavingsFundAccount = ({
  onFinishStep,
}: SavingsFundAccountProps) => {
  const analytics = useAnalytics();
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const [updatedFromParticipantId, setFromParticipantId] = React.useState<
    string | null
  >(null);
  const toParticipantId = useSelector((state) => state.global.savingsAccountId);
  const createTransferMode = useSelector(
    (state) => state.newFlows.CREATE_TRANSFER.mode,
  );
  const initialTransferParticipantId = useSelector(
    (state) => state.newFlows.SAVINGS_ONBOARDING.initialTransferParticipant?.id,
  );
  const transferType: TransferTypeEnum =
    createTransferMode === MODES.SCHEDULE
      ? TransferTypeEnum.ScheduledTransferRule
      : TransferTypeEnum.TransferInstance;

  const isSchedule = transferType === TransferTypeEnum.ScheduledTransferRule;
  const date = moment().format('YYYY-MM-DD');
  const fromParticipantId =
    updatedFromParticipantId || initialTransferParticipantId;
  const { data, loading } = useSavingsFundAccountQuery({
    variables: {
      toParticipantId: toParticipantId as string,
      transferType,
      date,
      fromParticipantId: fromParticipantId as string,
    },
    skip: !toParticipantId || !fromParticipantId,
  });
  const [createTransferInstance, { loading: isCreateTransferInstanceLoading }] =
    useCreateTransferInstanceMutation();

  const [
    setScheduledTransferRule,
    { loading: isSetScheduledTransferRuleLoading },
  ] = useSetScheduledTransferRuleMutation();

  if (
    loading ||
    isCreateTransferInstanceLoading ||
    isSetScheduledTransferRuleLoading
  ) {
    return <Spinner fullScreen />;
  }
  if (!data?.viewer || !data?.node) {
    onFinishStep('general-error');
    return null;
  }
  const initialValues = {
    toParticipantId,
    fromParticipantId,
  };

  const transfers = data.viewer.transfers;
  const toParticipantNode = data.node as TransferParticipantFragment;
  const toParticipantDropdownOptions = [
    {
      label: toParticipantNode?.transferParticipantType,
      options: [
        {
          label: toParticipantNode?.transferParticipantName,
          value: toParticipantId,
        },
      ],
    },
  ];
  const onHandleSubmit = ({
    amount,
    fromParticipantId,
    toParticipantId,
    schedule,
  }: HandleSubmitArgs): void => {
    analytics.recordEvent('m1_hysa_onboarding_initial_deposit_confirmed');
    if (transfers) {
      const error = transferLimitsValidation(amount, transfers);
      if (error) {
        throw new SubmissionError({
          amount: error,
        });
      }
    }
    const formatAmount = Number(amount);
    /**
     * If the initial deposit is recurring (mode === scheduled),
     * also create a one time transfer instance
     */
    createTransferInstance({
      variables: {
        input: {
          toParticipantId: toParticipantId,
          fromParticipantId: fromParticipantId,
          amount: formatAmount,
        },
      },
      onCompleted: (createTransferResult) => {
        if (createTransferMode === MODES.SCHEDULE && schedule) {
          setScheduledTransferRule({
            variables: {
              input: {
                toParticipantId: toParticipantId,
                fromParticipantId: fromParticipantId,
                amount: formatAmount,
                schedule: schedule as RecurrenceScheduleInput,
              },
            },
            onCompleted: (data) => {
              dispatch({
                type: 'SCHEDULED_TRANSFER_RULE_SET',
                payload: {
                  outcome: data?.setScheduledTransferRule.outcome,
                },
              });
            },
            onError: (err) => {
              addToast({ kind: 'alert', content: err.message });
            },
          });
        }
        dispatch({
          type: 'TRANSFER_INSTANCE_CREATED',
          payload: {
            outcome: createTransferResult?.createTransferInstance.outcome,
          },
        });

        onFinishStep();
      },
      onError: (err) => {
        addToast({ kind: 'alert', content: err.message });

        onFinishStep();
      },
    });
  };

  const onFromParticipantChange = (id: string): void => {
    setFromParticipantId(id);
  };

  return (
    <SavingsInitialDepositForm
      onHandleSubmit={onHandleSubmit}
      transfers={transfers}
      handleSkip={() => {
        analytics.recordEvent('m1_hysa_onboarding_initial_deposit_skipped');
        onFinishStep('skip');
      }}
      onFinishStep={onFinishStep}
      isSchedule={isSchedule}
      toParticipantId={toParticipantId}
      toParticipantDropdownOptions={toParticipantDropdownOptions}
      initialValues={initialValues}
      onFromParticipantChange={onFromParticipantChange}
    />
  );
};
