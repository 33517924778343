import {
  styled,
  useThemeMode,
  type BoxProps,
  type SemanticColorNames,
} from '@m1/liquid-react';
import {
  Icon,
  monochromaticIconNames,
  polychromaticIconNames,
} from '@m1/liquid-react/icons';
import {
  Illustration,
  illustrationsKeys,
} from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { AppImageFragment } from '~/graphql/types';

export type AppImageProps = {
  alt?: string;
  appImage: AppImageFragment;
  height?: number;
  style?: Record<string, any>;
  // Deprecated
  width?: number;
} & BoxProps;

const StyledImg = styled.img`
  max-width: 100%;
  user-select: none;
`;

export const AppImage = ({
  alt,
  appImage,
  height,
  width,
  ...rest
}: AppImageProps) => {
  const { activeThemeMode } = useThemeMode();
  const { type, names, color, lightTheme, darkTheme } = appImage;
  const sourceSet = activeThemeMode === 'dark' ? darkTheme : lightTheme;

  const imageNames = new Set(names || []);
  const iconProps = {
    ...(color
      ? {
          color: color as SemanticColorNames,
        }
      : {}),
  };

  if (type === 'MONOCHROMATIC_ICON') {
    const name = monochromaticIconNames.find((icon) => imageNames.has(icon));

    if (name) {
      return <Icon name={name} {...iconProps} {...rest} />;
    }
  } else if (type === 'POLYCHROMATIC_ICON') {
    const name = polychromaticIconNames.find((icon) => imageNames.has(icon));

    if (name) {
      return <Icon name={name} {...iconProps} {...rest} />;
    }
  } else if (type === 'ILLUSTRATION') {
    const name = illustrationsKeys.find((illustration) =>
      imageNames.has(illustration),
    );

    if (name) {
      // @ts-expect-error - TS2322 - Type '{ style?: Record<string, any> | undefined; alt: string; name: "mobile" | "timeout" | "holdings" | "mobileDocumentsEmptyState" | "accountTransfer" | "announcements" | "autopayOrcEnabled" | ... 100 more ... | "weddingSmall"; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'.
      return <Illustration alt="" name={name} {...rest} />;
    }
  }

  // If its a legacy or an unknown type fall back on previous implementation
  return (
    <StyledImg
      {...rest}
      alt={alt || ''}
      height={height}
      src={sourceSet.scale1xUrl}
      srcSet={`
        ${sourceSet.scale2xUrl} 2x,
        ${sourceSet.scale3xUrl} 3x
      `}
      width={width}
    />
  );
};
