import { Box, Flex, PL, PS, spacingUnits, Image } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { TransferWizardParticipantFragment } from '~/graphql/types';

import { getParticipantIconName } from '../../utils/lensMappers';

export const getParticipantGroupLabel = (
  participantType: TransferWizardParticipantFragment['transferParticipantType'],
) =>
  participantType !== 'EXTERNAL' &&
  participantType !== 'FUNDING_SOURCE' &&
  participantType !== 'CRYPTO_EXTERNAL'
    ? 'M1 accounts'
    : 'External accounts';

export const participantToDropdownOption = (
  participant: TransferWizardParticipantFragment,
) => ({
  name: participant.id,
  description: (
    <Flex alignItems="center" gap={spacingUnits.s}>
      <Box>
        {participant.__typename === 'FundingSourceAccount' &&
        participant.logoUrl ? (
          <Image
            src={participant.logoUrl}
            borderRadius={4}
            width={32}
            height={32}
            alt=""
          />
        ) : (
          <Icon
            name={getParticipantIconName(participant.transferParticipantType)}
            fallback="accountCheckingPrimary32"
          />
        )}
      </Box>
      {/* Not great setting a max width. But need to force following Ps to overflow/wrap. */}
      <Box maxWidth="330px">
        <PL overflowX="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {participant.transferParticipantName}
        </PL>
        <PS color="foregroundNeutralSecondary" whiteSpace="normal">
          {participant.transferParticipantDescription}
        </PS>
      </Box>
    </Flex>
  ),
});

type DropdownOption = {
  label: string;
  options: Array<{ name: string; description: React.ReactNode | string }>;
};

type DropdownOptions = Record<string, DropdownOption>;

export const participantsToDropdownOptions = (
  participants: Array<TransferWizardParticipantFragment>,
) =>
  participants.reduce((groups, participant) => {
    const groupName = getParticipantGroupLabel(
      participant.transferParticipantType,
    );
    return {
      ...groups,
      [groupName]: {
        label: groupName,
        options: [
          ...(groups[groupName]?.options ?? []),
          participantToDropdownOption(participant),
        ],
      },
    };
  }, {} as DropdownOptions);

// Need to sort values so that "External Accounts" are first. This is required because there
// is no guaranteed order to Object.values resolves the order from keys.
// (see https://stackoverflow.com/a/5525820 for more info)
export const sortDropdownOptions = (options: DropdownOptions) =>
  Object.values(options).sort(
    (a, b) => a.label.charCodeAt(0) - b.label.charCodeAt(0),
  );
