import { Box, HM } from '@m1/liquid-react';
import * as React from 'react';

import { useSetIntroductionSourceMutation } from '~/graphql/hooks';
import { IntroductionSourceEnum } from '~/graphql/types';
import { submittedIntroductionSource } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { getEnumEntries } from '~/utils';

import { IntroductionSourceOption } from './IntroductionSourceOption';

type CollectIntroductionSourceStepProps = {
  onFinishStep?: () => void;
};

export const CollectIntroductionSourceStep = ({
  onFinishStep,
}: CollectIntroductionSourceStepProps) => {
  const dispatch = useDispatch();
  const [setIntroductionSource] = useSetIntroductionSourceMutation();

  const options = React.useMemo(() => {
    const options = [];
    const introSourceEnumEntries = getEnumEntries('IntroductionSourceEnum');
    const sortedIntroSourceEnumEntries = introSourceEnumEntries.sort((a) => {
      if (a.name === IntroductionSourceEnum.Other) {
        return 1;
      }
      return -1;
    });

    for (const option of sortedIntroSourceEnumEntries) {
      if (option.name !== 'DECLINED_TO_ANSWER') {
        options.push(option);
      }
    }

    return options;
  }, []);

  return (
    <Box maxWidth={588} mx="auto">
      <HM content="How did you hear about us?" py={32} />
      {options.map(({ name, description }) => (
        <IntroductionSourceOption
          key={name}
          label={description}
          kind="pill"
          onClick={() => {
            setIntroductionSource({
              variables: {
                input: {
                  introductionSource: name as IntroductionSourceEnum,
                },
              },
            });
            dispatch(submittedIntroductionSource(name));
            onFinishStep && onFinishStep();
          }}
        />
      ))}
      <IntroductionSourceOption
        label="Skip this question"
        kind="plain"
        onClick={() => {
          setIntroductionSource({
            variables: {
              input: {
                introductionSource: IntroductionSourceEnum.DeclinedToAnswer,
              },
            },
          });
          dispatch(submittedIntroductionSource('DECLINED_TO_ANSWER'));
          onFinishStep && onFinishStep();
        }}
      />
    </Box>
  );
};
