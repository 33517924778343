export const roundUpToNextPenny = (x: number): number => {
  return Math.ceil(x * 100) / 100;
};

export const roundToDecimal = (x: number, d: number = 2): number => {
  if (typeof x !== 'number') {
    return NaN;
  }
  return Math.round(x * 10 ** d) / 10 ** d;
};
