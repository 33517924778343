import { Box, Button, Flex, Card } from '@m1/liquid-react';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { StyledFiltersContainer } from '~/components/research/Screener';
import { Grid } from '~/toolbox/grid';

import { StockFilterContainer } from './StockFilterContainer';
import { StockScreenerProvider } from './StockScreenerProvider';
import { StockScreenerTable } from './StockScreenerTable';

export const StockScreenerPage = () => {
  const [filtersOpen, setFiltersOpen] = React.useState(false);

  const isSmallDevice = useMediaQuery({
    query: '(max-width: 992px)',
  });

  return (
    <StockScreenerProvider>
      <Box position="relative" overflowX="hidden">
        <Grid
          style={{
            paddingTop: 16,
            paddingBottom: 16,
          }}
        >
          <Grid.Row>
            {!isSmallDevice && (
              <Grid.Col xs={3}>
                <Card p={16}>
                  <StockFilterContainer
                    setFiltersOpen={setFiltersOpen}
                    filtersOpen={filtersOpen}
                  />
                </Card>
              </Grid.Col>
            )}
            <Grid.Col xs={isSmallDevice ? 12 : 9}>
              {isSmallDevice && (
                <Flex justifyContent="flex-end" mb={8}>
                  <Button
                    kind="text"
                    label="Filters"
                    leftIcon="filter24"
                    style={{ padding: 0, height: 'fit-content' }}
                    onClick={() => setFiltersOpen(true)}
                  />
                </Flex>
              )}
              <StockScreenerTable />
            </Grid.Col>
          </Grid.Row>
        </Grid>
        {isSmallDevice && (
          <StyledFiltersContainer right={filtersOpen ? 0 : -300}>
            <StockFilterContainer
              setFiltersOpen={setFiltersOpen}
              filtersOpen={filtersOpen}
            />
          </StyledFiltersContainer>
        )}
      </Box>
    </StockScreenerProvider>
  );
};
