import { stepToProgress } from '~/components/StepToProgress';
import { SAVINGS_ONBOARDING_FLOW_STEPS as STEPS } from '~/static-constants';

export const SavingsProgressIndicator = stepToProgress(
  (state) => state.newFlows.SAVINGS_ONBOARDING,
  {
    [STEPS.JOINT_INVITED]: 5,
    [STEPS.ACCOUNT_TYPE]: 5,
    [STEPS.ACCOUNT_INVITATION]: 10,
    [STEPS.NAME_ACCOUNT]: 30,
    [STEPS.DUE_DILIGENCE]: 40,
    [STEPS.ESIGN]: 80,
    [STEPS.CONFIRMATION]: 95,
    [STEPS.CONNECT_BANK]: 0,
    [STEPS.FUND_ACCOUNT]: 0,
    [STEPS.FUNDING_COMPLETE]: 0,
  },
);
