import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useCountryOfCitizenshipFieldQuery } from '~/graphql/hooks';
import { OnlineAccountRegistrationEnum } from '~/graphql/types';
import type { AppState } from '~/redux';
import { useSelector } from '~/redux/hooks';
import { OnboardingFlowState } from '~/redux/reducers/newFlows/reducers/onboardingReducer';

import { required } from '../validators';

import { DropdownField } from './dropdown-field';

type CountryOfCitizenshipProps = {
  form: string;
};

function warning(value: string, _: any, props: CountryOfCitizenshipProps) {
  if (value && value !== 'USA') {
    return (
      <>
        {props.form === 'custodial-contact-info' ? (
          <>
            Please note that the beneficiary must be a{' '}
            <b>U.S. permanent resident</b> (green card holder). Sorry, we do not
            currently support visa holders.
          </>
        ) : (
          <>
            Please note that you must be a <b>U.S. permanent resident</b>.
            Sorry, we do not currently support visa holders.
          </>
        )}
      </>
    );
  }

  return null;
}

type CountryOfCitizenshipFieldProps = {
  accountRegistration: OnlineAccountRegistrationEnum;
};

export const CountryOfCitizenshipField = ({
  accountRegistration,
}: CountryOfCitizenshipFieldProps) => {
  const { product } = useSelector<OnboardingFlowState>(
    (state: AppState) => state.newFlows.onboarding,
  );

  const isBorrow = product.productIdentifier === 'BORROW_PERSONAL_LOANS';

  const { data, loading } = useCountryOfCitizenshipFieldQuery({
    variables: {
      accountRegistration,
    },
  });

  if (loading) {
    return null;
  }
  if (!data?.viewer.invest?.permissibleCountriesForAccountOpen) {
    return <GenericSystemError />;
  }
  return (
    <DropdownField
      name="countryOfCitizenship"
      label="Country of citizenship"
      validate={[required]}
      warn={!isBorrow && warning}
      source={data.viewer.invest.permissibleCountriesForAccountOpen}
    />
  );
};
