import { PL, HXS, PXL, Modal, ModalContent } from '@m1/liquid-react';
import isNull from 'lodash-es/isNull';
import * as React from 'react';

import { LoginFlow } from '~/flows/components/login';
import { RegisterUserFlow } from '~/flows/components/register-user';
import { compose, connectModal, withStateHandlers } from '~/hocs';
import { useSelector } from '~/redux/hooks';
import { LegacyTabs } from '~/toolbox/LegacyTabs';

import { PreserveHeight } from '../../preserve-height';

const LoginOrRegisterModalComponent = (props: any) => {
  const { modal, activeTab, changeTab } = props;

  React.useEffect(() => {
    // only change the tab if we're opening the modal
    const isOpened = modal?.isOpened;
    // The active tab can be assigned through the SHOW_MODAL action, so we account for that here
    const activeTab = modal?.payload?.activeTab;
    if (isOpened && !isNull(activeTab) && activeTab !== props.activeTab) {
      changeTab(activeTab);
    }
  }, [modal?.payload?.activeTab]);

  const handleClose = (): void => props.modal.hide();

  const isShowingMobileWarn = useSelector(
    (state) =>
      state.newFlows.registerUser.step === 'mobile' ||
      state.newFlows.login.step === 'mobile' ||
      /^\/share/.test(window.location.pathname),
  );

  return (
    <Modal open={modal.isOpened} onClose={handleClose}>
      <ModalContent>
        <PreserveHeight>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingBottom: 16,
            }}
          >
            <LegacyTabs index={activeTab}>
              <LegacyTabs.Item
                label={
                  <div
                    style={{
                      minWidth: 80,
                      padding: '8px 16px',
                    }}
                  >
                    <HXS content="Log in" />
                  </div>
                }
                onClick={() => changeTab(0)}
              />
              <LegacyTabs.Item
                label={
                  <div
                    style={{
                      minWidth: 80,
                      padding: '8px 16px',
                    }}
                  >
                    <HXS content="Sign up" />
                  </div>
                }
                onClick={() => changeTab(1)}
              />
            </LegacyTabs>
          </div>
          {activeTab === 0 && [
            <div
              key="login-description"
              style={{
                paddingBottom: 8,
                textAlign: 'center',
              }}
            >
              <PL content="Log in to save this Pie to your M1 account." />
            </div>,

            <LoginFlow key="login-flow" />,
          ]}
          {activeTab === 1 && [
            ...(isShowingMobileWarn
              ? [
                  <div
                    key="register-user-description"
                    style={{
                      paddingBottom: 8,
                      textAlign: 'center',
                    }}
                  >
                    <PXL
                      fontWeight={300}
                      content="New to M1? Sign up to explore M1."
                    />
                  </div>,
                ]
              : []),
            <RegisterUserFlow key="register-user-flow" />,
          ]}
        </PreserveHeight>
      </ModalContent>
    </Modal>
  );
};

const enhancer = compose<any, any>(
  connectModal('LOGIN_OR_REGISTER'),
  withStateHandlers(
    () => ({
      activeTab: 0,
    }),
    {
      changeTab: () => (activeTab) => ({
        activeTab,
      }),
    },
  ),
);

export const LoginOrRegisterModal = enhancer(
  LoginOrRegisterModalComponent,
) as any;
