import * as React from 'react';

import { CONNECT_BANK_FLOW_STEPS } from '~/static-constants';

import { Spinner } from '~/toolbox/spinner';

import { SelectPlaidStep } from './steps/SelectPlaidStep';

// TODO: Would be nice to type the props, but its a little harder than expected.
export const ConnectBankFlowContainer = ({
  step,
  onPlaidExit,
  ...rest
}: any) => {
  const steps = {
    [CONNECT_BANK_FLOW_STEPS.LOAD_STATUS]: <Spinner />,
    [CONNECT_BANK_FLOW_STEPS.SELECT_PLAID]: (
      <SelectPlaidStep onPlaidExit={onPlaidExit} {...rest} />
    ),
  };

  const Step = steps[step];
  return Step ? Step : null;
};
