import * as React from 'react';

import { useIraYearDropdownQuery } from '~/graphql/hooks';

import { DropdownField } from './dropdown-field';

export const RetirementContributionYearDropdownField = ({ ...rest }: any) => {
  const { data } = useIraYearDropdownQuery();

  const dropdownOptions = React.useMemo(() => {
    if (!data?.viewer) {
      return [
        {
          name: '--',
          description: null,
        },
      ];
    }

    const currentYear = new Date().getFullYear();

    return data.viewer.irsRegulations.iraContributionYears.map(({ year }) => ({
      // Must be IraContributionYearEnum
      name: year === currentYear ? 'CURRENT_YEAR' : 'PRIOR_YEAR',
      description: year === currentYear ? 'Current year' : 'Previous year',
    }));
  }, [data]);

  return <DropdownField {...rest} source={dropdownOptions} />;
};
