import { Box, Flex, PL, PXL } from '@m1/liquid-react';
import React from 'react';

import { useSelector } from '~/redux/hooks';
import { Divider } from '~/toolbox/divider';

type HardPullConsentProps = {
  loanTermOptions?: ({
    __typename?: 'LoanTermOption' | undefined;
    value: number | null;
    description: string | null;
  } | null)[];
};

export const HardPullConsentDetails = ({
  loanTermOptions,
}: HardPullConsentProps) => {
  const { directToPersonalLoansPreApprovedOffer } = useSelector(
    (state) => state.reactHookForm,
  );

  const loanTermDescription = loanTermOptions?.find(
    (option) =>
      option?.value === Number(directToPersonalLoansPreApprovedOffer?.loanTerm),
  )?.description;

  const consentDetailsValues = [
    {
      title: 'Loan Amount',
      value: directToPersonalLoansPreApprovedOffer?.loanAmount,
    },
    {
      title: 'APR',
      value:
        directToPersonalLoansPreApprovedOffer?.formattedAnnualPercentageInfo,
    },
    {
      title: 'Term',
      value: loanTermDescription,
    },
    {
      title: 'Monthly payments',
      value: directToPersonalLoansPreApprovedOffer?.formattedMonthlyPayment,
    },
  ];

  return (
    <>
      <PXL content="Terms and rate" fontWeight={600} pt={24} />
      <Box my={16}>
        {consentDetailsValues?.map(({ title, value }, idx) => (
          <Box key={idx}>
            <Flex justifyContent="space-between">
              <PL content={title} color="foregroundNeutralSecondary" />
              <PL content={value} fontWeight={600} />
            </Flex>
            {idx + 1 !== consentDetailsValues?.length && <Divider />}
          </Box>
        ))}
      </Box>
    </>
  );
};
