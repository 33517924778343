import * as React from 'react';

import { CreditCardRewardsAppPill } from '~/graphql/types';
import { Pill, PillProps } from '~/toolbox/Pill';

export type CreditCardRewardsAppPillProps = Omit<
  PillProps,
  'kind' | 'label'
> & {
  appPill: CreditCardRewardsAppPill;
};

const PILL_KIND_MAPPING: Record<
  CreditCardRewardsAppPill['kind'],
  PillProps['kind']
> = {
  BRONZE: 'bronze',
  GOLD: 'gold',
  OBSIDIAN: 'obsidian',
  NEUTRAL: 'neutral',
  PLATINUM: 'platinum',
};

const PILL_ICON_MAPPING: Record<
  NonNullable<CreditCardRewardsAppPill['icon']>,
  PillProps['icon']
> = {
  DIAMOND: 'diamond',
};

export const CreditCardRewardsPill = ({
  appPill: { kind, label, icon },
  ...rest
}: CreditCardRewardsAppPillProps) => {
  const pillKind = kind ? PILL_KIND_MAPPING[kind] : 'neutral';
  const pillIcon = icon ? PILL_ICON_MAPPING[icon] : null;

  return <Pill {...rest} icon={pillIcon} kind={pillKind} label={label} />;
};
