import { Button, Modal, ModalContent, ModalTitle, PL } from '@m1/liquid-react';
import * as React from 'react';

import { ButtonGroup } from '~/toolbox/ButtonGroup';

type SmartTransfersNavigationWarningModalProps = {
  isWarningModalOpen: boolean;
  onConfirmation: () => void;
  setIsWarningModalOpen: (arg0: boolean) => void;
};

export const SmartTransfersEndpointNavigationWarningModal = ({
  isWarningModalOpen,
  onConfirmation,
  setIsWarningModalOpen,
}: SmartTransfersNavigationWarningModalProps) => {
  const hideWarningModal = () => {
    setIsWarningModalOpen(false);
  };
  return (
    <Modal open={isWarningModalOpen} onClose={hideWarningModal}>
      <ModalContent>
        <ModalTitle>Are you sure?"</ModalTitle>
        <PL
          content="Going back to change the account will clear all your existing endpoint selections."
          mb={32}
        />
        <ButtonGroup behavior="centered">
          <Button
            label="No, Keep"
            kind="secondary"
            size="medium"
            onClick={hideWarningModal}
          />
          <Button
            label="Yes, Delete"
            kind="primary"
            size="medium"
            onClick={() => {
              hideWarningModal();
              onConfirmation();
            }}
          />
        </ButtonGroup>
      </ModalContent>
    </Modal>
  );
};
