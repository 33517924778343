import { Box, Flex, PL, PXL } from '@m1/liquid-react';
import * as React from 'react';

import { usePersonalLoansRequiredDisclosuresQuery } from '~/graphql/hooks';
import { Link } from '~/toolbox/link';

export const PersonalLoansRequiredDocuments = () => {
  const response = usePersonalLoansRequiredDisclosuresQuery();
  const requiredDisclosures =
    response.data?.viewer.borrow?.personalLoans?.applicationFlow
      ?.requiredDisclosures;

  if (!requiredDisclosures) {
    return null;
  }

  return (
    <Box>
      <PXL content="Agreements" fontWeight={600} pt={16} pb={8} />
      <Flex flexDirection="column" flexWrap="wrap">
        {requiredDisclosures.documents.map((document) => (
          <Box key={document.url} pb={4} py={4}>
            <Link
              style={{
                fontSize: 16,
                fontWeight: 400,
                textDecoration: 'underline',
              }}
              to={document.url}
              target="_blank"
            >
              {document.title}
            </Link>
          </Box>
        ))}
        <PL py={16}>
          Viewing offers <b>won't</b> impact your credit score.
        </PL>
      </Flex>
    </Box>
  );
};
