import { serializePieTree } from './serializePieTree';
import { Pie } from './types';

/**
 * Helper function that takes two pies and calculates whether certain we
 * have changes between the two.
 * @param originalPie - The original version of the pie
 * @param currentPie - The current version of the pie
 * @returns - True if any of the fields have changed on the pies or their slices false otherwise.
 */
export const hasChanges = (originalPie: Pie, currentPie: Pie): boolean =>
  serializePieTree(originalPie) !== serializePieTree(currentPie);
