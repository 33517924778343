import { isArray } from '@apollo/client/utilities';
import React from 'react';

import { EstimatedTradeEdgeFragment } from '~/graphql/types';
import { GridTableProps, GridTable } from '~/toolbox/grid-table';
import { formatNumber } from '~/utils';

import { SliceableNameCell } from '../pie';

type AccountEstimatedTradesTableProps = Pick<
  GridTableProps<EstimatedTradeEdgeFragment>,
  'style' | 'emptyMessage'
> & {
  label?: string;
  loadMoreTradesButton?: React.ReactNode;
  totalTrades: number;
  edges: (EstimatedTradeEdgeFragment | undefined | null)[] | null | undefined;
};

export const AccountEstimatedTradesTable = ({
  label = 'Estimated trades',
  totalTrades,
  edges,
  emptyMessage = 'No estimated trades',
  loadMoreTradesButton,
  style,
}: AccountEstimatedTradesTableProps) => {
  const trades = React.useMemo(() => {
    const trades = [];

    if (isArray(edges)) {
      for (const trade of edges) {
        if (trade?.node) {
          trades.push(trade.node);
        }
      }
    }
    return trades;
  }, [edges]);

  return (
    <GridTable
      style={style}
      emptyMessage={emptyMessage}
      gridTemplateColumns="70% auto"
    >
      <GridTable.HeaderRow>
        <GridTable.HeaderCell
          label={`${label} (including auto-invest, buy, and sell orders - ${totalTrades})`}
        />
        <GridTable.HeaderCell label="" />
      </GridTable.HeaderRow>
      {trades.map((trade) => (
        <GridTable.Row key={trade.of.id}>
          <GridTable.Cell
            content={<SliceableNameCell sliceable={trade.of} />}
          />
          <GridTable.Cell
            content={formatNumber(trade.amount)}
            textAlign="right"
          />
        </GridTable.Row>
      ))}
      {loadMoreTradesButton}
    </GridTable>
  );
};
