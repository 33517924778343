import { Box, Flex, HXS, LS, PL, Card } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useUploadDocumentsQuery } from '~/graphql/hooks';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';

export const UploadDocuments = () => {
  const { data, loading } = useUploadDocumentsQuery();

  if (loading) {
    return null;
  }

  if (!data?.viewer.credit?.onboardingContent) {
    return <GenericSystemError />;
  }

  const { uploadDocumentLink } = data.viewer.credit.onboardingContent;
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      mt={150}
      justifyContent="center"
    >
      <Box width={600}>
        <HXS content="You’re almost there! Additional information is required to process your application." />
        <PL
          mt={16}
          content="Your information could not be validated. Additional documentation is required for a decision to be made on your M1 Credit Card application. Please use the link below to securely upload additional documents."
        />
        <Card
          mt={16}
          mb={32}
          p={16}
          backgroundColor="backgroundNeutralTertiary"
          borderColor="transparent"
        >
          <LS
            color="foregroundNeutralSecondary"
            content="If you have already uploaded your documents, there is no need for a resubmission. It may take up to 5 business days to process your application. An email will be sent once completed."
          />
        </Card>
        {uploadDocumentLink && <LinkableLink linkable={uploadDocumentLink} />}
      </Box>
    </Flex>
  );
};
