export const REMOTE_ACTIONS = Object.freeze({
  SHOW_LOADING_SPINNER: 'SHOW_LOADING_SPINNER' as const,
  HIDE_LOADING_SPINNER: 'HIDE_LOADING_SPINNER' as const,
});

export type ShowLoadingSpinnerAction = {
  type: typeof REMOTE_ACTIONS.SHOW_LOADING_SPINNER;
};

export type HideLoadingSpinnerAction = {
  type: typeof REMOTE_ACTIONS.HIDE_LOADING_SPINNER;
};

export const showLoadingSpinner = (): ShowLoadingSpinnerAction => ({
  type: REMOTE_ACTIONS.SHOW_LOADING_SPINNER,
});

export const hideLoadingSpinner = (): HideLoadingSpinnerAction => ({
  type: REMOTE_ACTIONS.HIDE_LOADING_SPINNER,
});

export type RemoteAction = ShowLoadingSpinnerAction | HideLoadingSpinnerAction;
