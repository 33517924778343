import * as React from 'react';

import { DashboardNavigation } from '~/hooks/useDashboardNavigation';

import { SideNavLink } from './SideNavLink';

type SideNavResearchProps = {
  selectedNavigation: DashboardNavigation;
};

export const SideNavResearch = ({
  selectedNavigation,
}: SideNavResearchProps) => {
  return (
    <>
      <SideNavLink
        selected={selectedNavigation.activeL3Nav === 'market-news'}
        key="research-market-news"
        label="News"
        linkTo="/d/research/market-news"
      />
      <SideNavLink
        selected={selectedNavigation.activeL3Nav === 'stocks'}
        key="research-stocks"
        label="Stocks"
        linkTo="/d/research/stocks"
      />
      <SideNavLink
        selected={selectedNavigation.activeL3Nav === 'funds'}
        key="research-funds"
        label="Funds"
        linkTo="/d/research/funds"
      />
      <SideNavLink
        selected={selectedNavigation.activeL3Nav === 'crypto'}
        key="research-crypto"
        label="Crypto"
        linkTo="/d/research/crypto"
      />
      <SideNavLink
        selected={selectedNavigation.activeL3Nav === 'model-portfolios'}
        key="research-model-portfolios"
        label="Model Portfolios"
        linkTo="/d/research/model-portfolios"
      />
      <SideNavLink
        selected={selectedNavigation.activeL3Nav === 'my-pies'}
        key="research-my-pies"
        label="My Pies"
        linkTo="/d/research/my-pies"
      />
      <SideNavLink
        selected={selectedNavigation.activeL3Nav === 'watchlist'}
        key="d/research/watchlist"
        label="Watchlist"
        linkTo="/d/research/watchlist"
      />
    </>
  );
};
