import { Box, HM } from '@m1/liquid-react';
import * as React from 'react';

import { BackButton } from '~/flows/components/personal-loans/application/components/BackButton';
import { useIdentityFirstFinancialSuitabilityQuestionsQuery } from '~/graphql/hooks';
import { OnboardingIdentityFirstQuestionType } from '~/graphql/types';
import { useChangeStep } from '~/hooks/useChangeStep';
import { useLocation } from '~/hooks/useLocation';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { FINANCIAL_SUITABILITY_QUESTION_MAP } from '../consts';
import { PrimaryDisclosuresForm } from '../forms/PrimaryDisclosuresForm';
import { useUpdateProfileHook } from '../hooks/updateProfileHook';
import { useFinancialSuitabilityWizardContext } from '../hooks/useFinancialSuitabilityWizardContext';
import { FinancialSuitabilityFormFields } from '../hooks/useFinancialSuitabilityWizardForm';
import { FinancialSuitabilityWizardSteps } from '../types';

type CollectPrimaryDisclosuresWizardStepProps = {
  previousRouteName?: string;
};

export const CollectPrimaryDisclosuresWizardStep = ({
  previousRouteName,
}: CollectPrimaryDisclosuresWizardStepProps) => {
  const product = useSelector(
    (state) => state.navigation.financialSuitabilityProduct,
  );
  const { goTo } = useFinancialSuitabilityWizardContext();
  const location = useLocation();

  const handleChangeRouteStep = useChangeStep({
    basePath: 'onboarding/financial-details',
  });

  const { data: questionsData, loading } =
    useIdentityFirstFinancialSuitabilityQuestionsQuery();
  const questions =
    questionsData?.viewer?.user?.onboardingIdentityFirst
      ?.financialSuitabilitySection?.questions;
  const disclosuresQuestion = questions?.find(
    (q) =>
      q?.questionType ===
      OnboardingIdentityFirstQuestionType.PoliticallyExposedPerson,
  );
  const nextQuestion = disclosuresQuestion?.nextQuestionType;

  const { updateProfileMutation, updateProfileLoading } =
    useUpdateProfileHook();
  usePortaledSpinner(updateProfileLoading);

  React.useEffect(() => {
    if (!location?.pathname?.includes('disclosures')) {
      handleChangeRouteStep({
        step: 'disclosures' as FinancialSuitabilityWizardSteps,
      });
    }
  }, [location, handleChangeRouteStep]);

  if (questions?.[0]?.questionType && !disclosuresQuestion) {
    handleChangeRouteStep({
      step: FINANCIAL_SUITABILITY_QUESTION_MAP[
        questions[0].questionType
      ] as FinancialSuitabilityWizardSteps,
    });
    goTo(FINANCIAL_SUITABILITY_QUESTION_MAP[questions[0].questionType]);
  }

  if (loading) {
    return <Spinner fullScreen />;
  }

  const onSubmit = (data: FinancialSuitabilityFormFields) => {
    updateProfileMutation(data, product);
  };

  const handleNext = () => {
    const wizardStep = FINANCIAL_SUITABILITY_QUESTION_MAP[nextQuestion!];
    handleChangeRouteStep({
      step: wizardStep as FinancialSuitabilityWizardSteps,
    });
    goTo(wizardStep);
  };

  return (
    <Box mt={80}>
      <BackButton previousRouteName={previousRouteName}>
        <Box mt={-8} mb={48}>
          <HM
            content={disclosuresQuestion?.title ?? 'Do any of these apply?'}
            pt={64}
          />
        </Box>
        <PrimaryDisclosuresForm
          continueLabel={disclosuresQuestion?.continueLabel ?? 'Continue'}
          nextQuestion={nextQuestion}
          onSubmit={onSubmit}
          handleNext={handleNext}
        />
      </BackButton>
    </Box>
  );
};
