import { Box, Flex, HM, HXS, PL, styled, Card } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';
import { formatNumber } from '~/utils';

import { BORROW_USE_CASES } from '../constants';

type BorrowMarketingUseCasesProps = {
  borrowAccountId: string | null | undefined;
  maxBorrowableAccountValuePercent: number | null | undefined;
};

const StyledUseCaseCard = styled(Card)`
  height: 422px;
  margin-bottom: 32px;
  width: 378px;
`;

const StyledUseCaseCardImageContainer = styled(Flex)`
  background: ${(props) => props.theme.colors.gradientLinearFeature};
  border-radius: 8px 8px 0 0;
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.1);
  height: 220px;
  width: 378px;
`;

const StyledUseCaseCardInfoContainer = styled(Flex)`
  height: 202px;
`;
export const BorrowMarketingUseCases = ({
  borrowAccountId,
  maxBorrowableAccountValuePercent,
}: BorrowMarketingUseCasesProps) => {
  const maxBorrowablePercent =
    typeof maxBorrowableAccountValuePercent === 'number'
      ? `${formatNumber(maxBorrowableAccountValuePercent, '0[.]0[0]')}%`
      : `a portion`;

  return (
    <Box>
      <Container centered marginBottom={64} marginTop={64}>
        <HM content="How you can use M1 Margin Loans" mb={16} />
        <PL
          content={`Stay invested and leverage your portfolio by borrowing up to ${maxBorrowablePercent} of your eligible investments on margin. Start borrowing today, or learn more about the flexible ways you can use M1 Margin Loans.`}
        />
      </Container>
      <Container marginBottom={64}>
        <Flex flexWrap="wrap" justifyContent="space-between">
          {BORROW_USE_CASES.map((useCase) => (
            <StyledUseCaseCard isElevated key={useCase.title}>
              <StyledUseCaseCardImageContainer
                alignItems="center"
                justifyContent="center"
              >
                <Box>
                  <Illustration
                    // @ts-expect-error - TS2322 - Type '{ alt: string; name: "mobile" | "timeout" | "holdings" | "mobileDocumentsEmptyState" | "accountTransfer" | "announcements" | "autopayOrcEnabled" | "balancedPie" | "bankConnectionBank" | ... 98 more ... | "weddingSmall"; height: number; width: number; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'.
                    alt=""
                    name={useCase.image}
                    height={178}
                    width={178}
                  />
                </Box>
              </StyledUseCaseCardImageContainer>
              <StyledUseCaseCardInfoContainer
                flexDirection="column"
                justifyContent="space-between"
                px={32}
                py={24}
              >
                <Box>
                  <HXS content={useCase.title} mb={16} />
                  <PL content={useCase.description} />
                </Box>
                <Link
                  params={{
                    useCase: useCase.route,
                  }}
                  query={
                    borrowAccountId
                      ? {
                          borrowAccountId,
                        }
                      : null
                  }
                  to="/d/c/borrow-use-cases/:useCase"
                >
                  Learn More
                </Link>
              </StyledUseCaseCardInfoContainer>
            </StyledUseCaseCard>
          ))}
        </Flex>
      </Container>
    </Box>
  );
};
