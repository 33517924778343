import {
  Box,
  DataSet,
  Flex,
  HL,
  HXS,
  PM,
  PXL,
  Skeleton,
  SkeletonProvider,
  TabularDataSet,
} from '@m1/liquid-react';
import * as React from 'react';

import { useMediaQuery } from 'react-responsive';

import {
  ProductAccountLandingPageHeader,
  TabularDataItemFragment,
} from '~/graphql/types';
import { useLayout } from '~/hooks/useLayout';
import { HeaderScrollBackground } from '~/pages/dashboard/invest/portfolio/PortfolioDetailsPage/PortfolioDetailsPage.styled';

import { Sticky } from '../sticky';

import {
  BorderLeftDataContainer,
  MainDataSetContainer,
  SecondaryDataSetContainer,
  TabularDataContainer,
  StyledDataSetContainer,
  LandingPageStaticHeader,
  LandingPageDivider,
  AnimatedDataContainer,
  LandingPageHeaderContentContainer,
  LandingPageHeaderSecondaryContentContainer,
} from './LandingPageHeader.styles';
import { formatTabularData, formatTooltip } from './utils';

type LandingPageHeaderProps = {
  loading: boolean;
  title: string;
  ctaComponent: React.ReactNode;
  header: Maybe<ProductAccountLandingPageHeader>;
  accountDetails: Maybe<TabularDataItemFragment>[];
  hasAnnouncement: boolean;
};

const LandingPageDataSet = ({
  compact = false,
  accountDetails,
  header,
  isMedium,
  opacity,
}: {
  compact?: boolean;
  accountDetails: Maybe<TabularDataItemFragment>[];
  header: Maybe<ProductAccountLandingPageHeader>;
  isMedium: boolean;
  opacity?: any;
}) => {
  return compact ? (
    <BorderLeftDataContainer opacity={opacity}>
      <AnimatedDataContainer opacity={opacity}>
        <DataSet
          label={
            <Flex>
              <PM fontWeight={400} color="foregroundNeutralSecondary">
                {header?.title}
              </PM>
              {header?.tooltip && formatTooltip(header.tooltip)}
            </Flex>
          }
          data={<PXL content={header?.value} />}
        />
      </AnimatedDataContainer>
    </BorderLeftDataContainer>
  ) : (
    <TabularDataContainer mt={26}>
      <MainDataSetContainer>
        <DataSet
          label={
            <Flex>
              <PM color="foregroundNeutralSecondary" content={header?.title} />
              {header?.tooltip && formatTooltip(header.tooltip)}
            </Flex>
          }
          data={<HL fontWeight={300} content={header?.value} />}
        />
      </MainDataSetContainer>

      <SecondaryDataSetContainer>
        <TabularDataSet
          forceFirstBorder={!isMedium}
          tabularData={formatTabularData(accountDetails)}
        />
      </SecondaryDataSetContainer>
    </TabularDataContainer>
  );
};

export const LandingPageHeader = ({
  loading,
  title,
  ctaComponent,
  header,
  accountDetails,
  hasAnnouncement,
}: LandingPageHeaderProps) => {
  const isMedium = useMediaQuery({ maxWidth: 1287 });
  const { scrollTop, topBarHeight } = useLayout();

  const topHeight = hasAnnouncement ? 180 : topBarHeight;
  const percentTopBarScrolled = Math.min(1, Math.pow(scrollTop / topHeight, 2));
  const compactOpacity = Math.max(0, 0.015 * (scrollTop - topHeight)); // Fades in as you scroll down
  const fullOpacity = Math.max(0, 1 - 0.125 * (scrollTop - topHeight)); // Fades out as you scroll down
  const isCompact = compactOpacity > 0.5;

  return (
    <SkeletonProvider isLoading={loading}>
      <Sticky zIndex={2}>
        <LandingPageStaticHeader opacity={compactOpacity}>
          <Box width="100%">
            <LandingPageHeaderContentContainer isCompact={isCompact}>
              <Box flexBasis="50%">
                <Skeleton skeletonWidth="25%">
                  <HXS color="foregroundSecondary" fontWeight={300}>
                    {title}
                  </HXS>
                </Skeleton>
              </Box>
              <LandingPageHeaderSecondaryContentContainer isCompact={isCompact}>
                <StyledDataSetContainer opacity={compactOpacity}>
                  <LandingPageDataSet
                    accountDetails={accountDetails}
                    header={header}
                    compact
                    isMedium={isMedium}
                    opacity={compactOpacity}
                  />
                </StyledDataSetContainer>
                <Box marginLeft="auto" alignSelf="center">
                  <Skeleton skeletonHeight="fit-content" skeletonWidth={160}>
                    {ctaComponent}
                  </Skeleton>
                </Box>
              </LandingPageHeaderSecondaryContentContainer>
            </LandingPageHeaderContentContainer>
          </Box>
          <HeaderScrollBackground
            $percentTopBarScrolled={percentTopBarScrolled}
          />
        </LandingPageStaticHeader>
      </Sticky>
      <StyledDataSetContainer opacity={fullOpacity}>
        <LandingPageDataSet
          accountDetails={accountDetails}
          header={header}
          isMedium={isMedium}
        />
        <LandingPageDivider />
      </StyledDataSetContainer>
    </SkeletonProvider>
  );
};
