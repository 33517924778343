import { Box, styled } from '@m1/liquid-react';

export const StyledSliceListHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSliceContainer = styled(Box)`
  position: relative;
  padding: 0 0 0 4px;
  border-top: 1px solid var(--colors-border-main);

  &:last-child {
    border-bottom: 1px solid var(--colors-border-main);
  }
`;

export const StyledSliceColor = styled(Box)`
  position: absolute;
  top: -1px;
  left: 0;
  width: $color-width;
  height: calc(100% + 2px);
  pointer-events: none;
`;
