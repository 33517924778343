import { Box, PL, OneColumnConstrained, styled, theme } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import { usePersonalLoansNotEligiblePageQuery } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { Spinner } from '~/toolbox/spinner';

import { StyledHeader } from './StyledHeader';

const StyledIllustration = styled(Illustration)`
  height: 168px;
  width: 169px;

  @media screen and (min-width: ${theme.breakpoints.SMALL}) {
    height: 240px;
    width: 242px;
  }
`;

export const NotEligiblePage = () => {
  const navigate = useNavigate();

  const { data, loading } = usePersonalLoansNotEligiblePageQuery();
  if (loading) {
    return <Spinner fullScreen centered />;
  }

  const title =
    data?.viewer.borrow?.personalLoans?.applicationFlow?.loanIneligibilityScreen
      ?.title;

  const subtitle =
    data?.viewer.borrow?.personalLoans?.applicationFlow?.loanIneligibilityScreen
      ?.subtitle;

  const eligibilityCriteriaLink =
    data?.viewer.borrow?.personalLoans?.applicationFlow?.loanIneligibilityScreen
      ?.eligibilityCriteriaLink;

  if (!title || !subtitle || !eligibilityCriteriaLink) {
    return <GenericSystemError />;
  }

  return (
    <OneColumnConstrained>
      <Box display="flex" justifyContent="center" mt={48}>
        <StyledIllustration name="loanNotApproved" />
      </Box>
      <StyledHeader content={title} mt={24} />
      <PL content={subtitle} pt={8} pb={8} />
      <Box display="flex" mb={48}>
        <LinkableLink linkable={eligibilityCriteriaLink} />
      </Box>
      <ResponsiveButton
        kind="primary"
        label="Return Home"
        onClick={() => {
          navigate({ to: '/d/home' });
        }}
        size="large"
      />
    </OneColumnConstrained>
  );
};
