import { Box, PL, PS } from '@m1/liquid-react';
import React from 'react';

import { formatLongFormDate, formatSuffix } from '~/utils';

import { CustodialBeneficiaryNode } from '../types';

import {
  StyledAddressFieldsContainer,
  StyledPersonalInformationLeftColumn,
  StyledPersonalInformationRightColumn,
} from './CustodialBeneficiary.styled';

export const CustodialBeneficiaryAddressFieldsContainer = ({
  custodialAccountBeneficiary,
}: {
  custodialAccountBeneficiary: NonNullable<
    CustodialBeneficiaryNode['custodialAccountBeneficiary']
  >;
}) => {
  const {
    countryOfCitizenship,
    dateOfBirth,
    firstName,
    lastName,
    middleInitial,
    suffix,
  } = custodialAccountBeneficiary;
  return (
    <StyledAddressFieldsContainer>
      <StyledPersonalInformationLeftColumn>
        <ContactLabel label="First name" field={firstName} />
        <ContactLabel label="Last name" field={lastName} />
        <ContactLabel
          label="Date of birth"
          field={formatLongFormDate(dateOfBirth)}
        />
      </StyledPersonalInformationLeftColumn>
      <StyledPersonalInformationRightColumn>
        <ContactLabel label="Middle initial" field={middleInitial} />
        <ContactLabel label="Suffix" field={formatSuffix(suffix)} />
        <ContactLabel
          label="Country of citizenship"
          field={countryOfCitizenship}
        />
      </StyledPersonalInformationRightColumn>
    </StyledAddressFieldsContainer>
  );
};

const ContactLabel = ({
  label,
  field,
}: {
  field: string | null | undefined;
  label: string | null | undefined;
}) => {
  return (
    <Box mb={24}>
      <PS color="foregroundNeutralSecondary" content={label} />
      <PL fontWeight={600} content={field || '--'} />
    </Box>
  );
};
