export const AUTH_ACTIONS = Object.freeze({
  REGISTER_USER: 'REGISTER_USER' as const,
  LOGIN: 'LOGIN' as const,
  LOGOUT: 'LOGOUT' as const,
  INITIATE_RESET_PASSWORD: 'INITIATE_RESET_PASSWORD' as const,
  RESET_PASSWORD: 'RESET_PASSWORD' as const,
  CHANGE_PASSWORD: 'CHANGE_PASSWORD' as const,
  PROVIDE_SSN_CHALLENGE: 'PROVIDE_SSN_CHALLENGE' as const,
});

// TODO: refactor these actions to type the payload.
export type LoginAction = {
  payload: unknown;
  type: typeof AUTH_ACTIONS.LOGIN;
};

export type RegisterUserAction = {
  payload: unknown;
  type: typeof AUTH_ACTIONS.REGISTER_USER;
};

export type ResetPasswordAction = {
  payload: unknown;
  type: typeof AUTH_ACTIONS.RESET_PASSWORD;
};

export type LogoutAction = {
  type: typeof AUTH_ACTIONS.LOGOUT;
};

export type ProvideAccountChallengeAction = {
  payload: unknown;
  type: typeof AUTH_ACTIONS.PROVIDE_SSN_CHALLENGE;
};

export type ChangePasswordAction = {
  payload: unknown;
  type: typeof AUTH_ACTIONS.CHANGE_PASSWORD;
};

export type AuthAction =
  | ChangePasswordAction
  | LoginAction
  | LogoutAction
  | ProvideAccountChallengeAction
  | RegisterUserAction
  | ResetPasswordAction;
