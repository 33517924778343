import { ACTION_TYPES as ACTIONS } from '~/redux/actions';

export type RemoteState = {
  isBusy: boolean;
  requests: number;
};

export const initialState: RemoteState = {
  isBusy: false,
  requests: 0,
};

export const remoteReducer = (
  state: RemoteState = initialState,
  action: any,
): RemoteState => {
  switch (action.type) {
    case ACTIONS.SHOW_LOADING_SPINNER:
      return Object.assign({}, state, {
        isBusy: state.requests >= 0,
        requests: state.requests + 1,
      });
    case ACTIONS.HIDE_LOADING_SPINNER:
      return Object.assign({}, state, {
        // we are no longer loading if the number of active requests is zero
        isBusy: !(state.requests <= 1),
        requests: Math.max(0, state.requests - 1),
      });
    default:
      return state;
  }
};
