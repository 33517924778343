import { defaultProps } from '~/hocs';

import { required, printableAsciiChars, ssn } from '../validators';

import { TextField } from './text-field';

const enhancer = defaultProps({
  name: 'ssn',
  label: 'Social security number',
  format: format,
  normalize: normalize,
  validate: [required, printableAsciiChars, ssn],
  placeholder: '123-45-6789',
});

export const SsnField = enhancer(TextField) as any;

export function format(value: string | null | undefined): string {
  if (!value) {
    return '';
  }

  if (value.length < 4) {
    return value;
  } else if (value.length < 6) {
    return `${value.slice(0, 3)}-${value.slice(3, 6)}`;
  }

  return `${value.slice(0, 3)}-${value.slice(3, 5)}-${value.slice(5, 9)}`;
}

export function normalize(
  value: string | null | undefined,
  previous: string | null | undefined,
): string {
  const onlyNums = value ? value.replace(/[^\d]/g, '').slice(0, 9) : '';
  const previousOnlyNums = previous
    ? previous.replace(/[^\d]/g, '').slice(0, 9)
    : '';

  // Not really sure why, but blurring the field causes this function to be
  // called with an undefined value. In that instance, normalize to the last
  // known value.
  return value === undefined || value === null ? previousOnlyNums : onlyNums;
}
