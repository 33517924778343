import * as React from 'react';

import type { SmartTransferFulfillmentConditionInput } from '~/redux/actions/smartTransfer/smartTransferActions.types';

import { DEFAULT_FULFILLMENT_CONDITION_FIELDS } from './constants';

type IndefiniteProps = {
  onFulfillmentDataChange: (
    arg0: SmartTransferFulfillmentConditionInput,
  ) => void;
  onIsContinueAllowedChange: (arg0: boolean) => void;
};

export const Indefinite = ({
  onFulfillmentDataChange,
  onIsContinueAllowedChange,
}: IndefiniteProps) => {
  const initialRender = React.useRef<boolean>(true);
  // when fulfillment condition data changes, fire callback
  React.useEffect(() => {
    if (initialRender.current) {
      onIsContinueAllowedChange(true);
      onFulfillmentDataChange({
        ...DEFAULT_FULFILLMENT_CONDITION_FIELDS,
        fulfillmentConditionType: 'INDEFINITE',
      });
      initialRender.current = false;
    }
  }, [onFulfillmentDataChange, onIsContinueAllowedChange]);
  return null;
};
