import { PXL, PL, Flex, styled, FlexProps } from '@m1/liquid-react';
import * as React from 'react';

import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';

type SecurityDataContainerProps = Omit<FlexProps, 'as' | 'ref'> & {
  children: React.ReactNode | React.ReactNode[];
  headingContent?: string;
  learnMore?: React.ReactNode;
  subHeadingContent?: string;
};

const StyledSecurityInnerContainer = styled(Flex)`
  padding: 20px 30px;
  border: 1px solid ${(props) => props.theme.colors.backgroundNeutralSecondary};
  border-radius: 8px;
  @media screen and ${RESPONSIVE_BREAKPOINTS.MEDIUM} {
    flex-wrap: wrap;
  }
  @media screen and ${RESPONSIVE_BREAKPOINTS.SMALL} {
    flex-wrap: wrap;
  }
`;

export const SecurityDataContainer = ({
  headingContent,
  subHeadingContent,
  children,
  learnMore,
  ...rest
}: SecurityDataContainerProps) => {
  return (
    <Flex flexDirection="column" mt={40} pt={20} {...rest} flexWrap="wrap">
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        mb={subHeadingContent ? 0 : 16}
        mr={4}
        ml={4}
      >
        <PXL fontWeight={600} content={headingContent} />
        {learnMore}
      </Flex>
      {subHeadingContent && (
        <PL
          mb={12}
          color="foregroundNeutralTertiary"
          content={subHeadingContent}
        />
      )}
      <StyledSecurityInnerContainer {...rest}>
        {children}
      </StyledSecurityInnerContainer>
    </Flex>
  );
};
