import { ScheduleKey } from '~/components/form/schedule/types';

import { TransferFrequencyEnum } from '../../steps/TransferDetails.types';

export const isValidTransferFrequency = (
  frequency: string,
): frequency is TransferFrequencyEnum => {
  return Object.values(TransferFrequencyEnum).includes(
    frequency as TransferFrequencyEnum,
  );
};

export const FREQUENCY_TO_LABEL: Record<TransferFrequencyEnum, string> = {
  [TransferFrequencyEnum.OneTime]: 'Once',
  [TransferFrequencyEnum.Weekly]: 'Weekly',
  [TransferFrequencyEnum.EveryTwoWeeks]: 'Every 2 weeks',
  [TransferFrequencyEnum.Monthly]: 'Monthly',
};

type RecurringFrequencies =
  | TransferFrequencyEnum.Weekly
  | TransferFrequencyEnum.EveryTwoWeeks
  | TransferFrequencyEnum.Monthly;

export const FREQUENCY_TO_INPUT_KEYS: Record<
  RecurringFrequencies,
  ScheduleKey
> = {
  'weekly': 'weekly',
  'every-two-weeks': 'biweekly',
  'monthly': 'monthly',
};
