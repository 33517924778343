import { Flex, FlexProps, LayoutableProps, PM, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { SupportLinkDestination, useSupportLink } from '~/hooks/useSupportLink';

import { Link } from '~/toolbox/link';

const StyledFlexBox = styled(Flex)`
  border: 1px solid ${(props) => props.theme.colors.borderMain};
  border-radius: 70px;
  padding: 8px 16px;
`;

export const Feedback = ({
  destination,
  href,
  content,
  ...rest
}: LayoutableProps &
  FlexProps & {
    href?: string;
    content: string;
    destination?: SupportLinkDestination;
  }) => {
  const supportLink = useSupportLink(destination);
  const linkProps = href ? { to: href, target: '_blank' } : supportLink;
  return (
    <Flex width="100%" height="40px" {...rest}>
      <Link {...linkProps}>
        <StyledFlexBox>
          <Icon color="primary" name="ratings20" />
          <PM color="foregroundNeutralMain" content={content} ml={8} />
        </StyledFlexBox>
      </Link>
    </Flex>
  );
};
