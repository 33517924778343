import * as React from 'react';

import {
  CollectPrimaryContactInfoStep,
  CollectPrimaryIdentityInfoStep,
  CollectPrimaryHolderSsnStep,
} from '~/components/SharedOnboardingSteps';
import { IDENTITY_PROFILE_FLOW_STEPS as STEPS } from '~/static-constants';
import { Container } from '~/toolbox/container';

export type IdentityProfileContainerProps = {
  onFinishStep: () => void;
  step: ValueOf<typeof STEPS>;
};

export const IdentityProfileContainer = ({
  step,
  ...rest
}: IdentityProfileContainerProps) => {
  const steps = {
    [STEPS.COLLECT_PRIMARY_CONTACT_INFO]: CollectPrimaryContactInfoStep,
    [STEPS.COLLECT_PRIMARY_IDENTITY_INFO]: CollectPrimaryIdentityInfoStep,
    [STEPS.COLLECT_SSN]: CollectPrimaryHolderSsnStep,
  };

  const Step = steps[step];

  return Step ? (
    <Container marginTop={32}>
      <Step {...rest} />
    </Container>
  ) : null;
};
