import React from 'react';

import { SystemPiePerformancePopup } from '~/components/pie';
import { PeriodSelectorTabs } from '~/components/research';
import {
  PieBacktestPeriodEnum,
  ModelPortfolioScreenerQueryVariables,
  SystemPieSortTypeEnum,
} from '~/graphql/types';
import { Table } from '~/toolbox/table';

import { performancePeriods, PERIOD_TO_SORT } from '../constants';

import { StyledPerformanceTabs } from './CategoryDetails.styled';
import { RiskPopup } from './RiskPopup';

type ModelPortfolioTableHeaderProps = {
  onSortChange: (sort: SystemPieSortTypeEnum) => void;
  sort: Exclude<ModelPortfolioScreenerQueryVariables['sort'], any[]>;
  period: PieBacktestPeriodEnum;
  setPeriod: (period: PieBacktestPeriodEnum) => void;
};

export const ModelPortfolioTableHeader = ({
  onSortChange,
  sort,
  period,
  setPeriod,
}: ModelPortfolioTableHeaderProps) => {
  const periodToSort =
    PERIOD_TO_SORT[period] || SystemPieSortTypeEnum.Performance_5Y;
  return (
    <Table.Head fixed>
      <Table.Row>
        <Table.HeaderCell
          width="37%"
          sort={
            sort?.type === SystemPieSortTypeEnum.Name ? sort.direction : null
          }
          onClick={() => onSortChange(SystemPieSortTypeEnum.Name)}
        >
          Name
        </Table.HeaderCell>

        <Table.HeaderCell width="11%">Holdings</Table.HeaderCell>
        <Table.HeaderCell width="11.5%">Div. Yield</Table.HeaderCell>
        <Table.HeaderCell
          width="11.5%"
          sort={sort?.type === periodToSort ? sort?.direction : null}
          onClick={() => onSortChange(periodToSort)}
        >
          <SystemPiePerformancePopup />
        </Table.HeaderCell>

        <Table.HeaderCell width="16%">
          <StyledPerformanceTabs>
            <PeriodSelectorTabs
              periods={performancePeriods}
              value={period}
              size="tiny"
              onClick={setPeriod}
            />
          </StyledPerformanceTabs>
        </Table.HeaderCell>

        <Table.HeaderCell
          width="13%"
          sort={
            sort?.type === SystemPieSortTypeEnum.RiskGrade
              ? sort?.direction
              : null
          }
          onClick={() => onSortChange(SystemPieSortTypeEnum.RiskGrade)}
        >
          <RiskPopup />
        </Table.HeaderCell>
      </Table.Row>
    </Table.Head>
  );
};
