import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useConfirmationDialogContainerQuery } from '~/graphql/hooks';
import { ConfirmationDialogPieFragment } from '~/graphql/types';
import { Pie, preparePieTreeForUpdate } from '~/pie-trees';
import { useSelector } from '~/redux/hooks';

import { ConfirmationDialog } from './ConfirmationDialog';

type PortfolioEditorConfirmationDialogProps = {
  active: boolean;
  onCancel: () => void;
  onConfirm: (e: React.SyntheticEvent<any>) => void | Promise<void>;
  showApplicableLocations: boolean;
};

export const PortfolioEditorConfirmationDialog = ({
  active,
  onCancel,
  onConfirm,
  showApplicableLocations,
}: PortfolioEditorConfirmationDialogProps) => {
  const pieTree: Pie = useSelector(
    (state) => state.newFlows.PORTFOLIO_EDITOR.sourcePie,
  );

  const { data, loading } = useConfirmationDialogContainerQuery({
    variables: {
      pieId: pieTree.__id,
      newSerializedPieTree: JSON.stringify(preparePieTreeForUpdate(pieTree)),
    },
  });

  if (loading) {
    return null;
  }

  // Some catastrophic error occurred
  if (!data?.node || !data.viewer) {
    return <GenericSystemError />;
  }

  const { node, viewer } = data;

  return (
    <ConfirmationDialog
      active={active}
      onCancel={onCancel}
      onConfirm={onConfirm}
      pie={node as ConfirmationDialogPieFragment}
      pieChangesConfirmation={viewer.invest?.pieChangesConfirmation}
      showApplicableLocations={showApplicableLocations}
    />
  );
};
