import { Box, Button, Flex, HXS, PL } from '@m1/liquid-react';
import * as React from 'react';

import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';

type TrustAccountTypeSelectStepProps = {
  onFinishStep: () => void;
};

export const TrustAccountReceiptStep = ({
  onFinishStep,
}: TrustAccountTypeSelectStepProps) => {
  return (
    <Container>
      <Flex flexDirection="column">
        <HXS content="Almost there..." pt={32} />
        <PL py={16}>
          <>
            Your request to open a brokerage account for{' '}
            <span style={{ fontWeight: 600 }}>Trust</span> has been received.
            You will receive an email shortly with further information on how to
            open your account."
          </>
        </PL>
        <PL
          mb={16}
          content="Because your account hasn't been created yet, the app won't be
              ready. As soon as we receive your information, we will work
              quickly to get you up and running."
        />
        <PL mb={32}>
          If you have additional questions, please contact{' '}
          <Link font="PL" to="/d/contact-us">
            Client Support
          </Link>
          .
        </PL>
        <Box pt={32}>
          <Button label="Finish" onClick={onFinishStep} size="large" />
        </Box>
      </Flex>
    </Container>
  );
};
