import { Button, HS, PL, Box, Modal, ModalContent } from '@m1/liquid-react';
import * as React from 'react';

import { useSelector } from '~/redux/hooks';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

export const PortfolioOrganizerExitConfirmationModal = ({
  onCancel,
  onConfirm,
  open,
}: {
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
}) => {
  const isEditingOrCreatingPortfolio = useSelector((state) => {
    const { session } = state.portfolioOrganizer;

    return session.mode === 'EDIT_ROOT_PIE' || session.mode === 'NEW_ROOT_PIE';
  });

  return (
    <Modal onClose={onCancel} open={open}>
      <ModalContent>
        <Box maxWidth={380} mx="auto" pt={16} textAlign="center">
          <HS content="Are you sure?" />
          <PL
            content={`Changes to your ${
              isEditingOrCreatingPortfolio ? 'portfolio' : 'pie'
            } will not be saved on exit.`}
            mt={16}
            textAlign="left"
          />
        </Box>
        <ButtonGroup behavior="centered" mt={128}>
          <Button kind="secondary" label="Cancel" onClick={onCancel} />
          <Button kind="primary" label="Confirm" onClick={onConfirm} />
        </ButtonGroup>
      </ModalContent>
    </Modal>
  );
};
