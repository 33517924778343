import { type ButtonKind, Skeleton, styled } from '@m1/liquid-react';
import * as React from 'react';

import {
  AppButtonFragment,
  AppLinkFragment,
  LinkableFragment,
} from '~/graphql/types';

import { AppButton } from '../AppButton';
import { LinkableButton } from '../LinkableButton';

export const ResponsiveAppButton = styled(AppButton)`
  margin-top: 48px;
  margin-bottom: 48px;
  min-width: 216px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.SMALL}) {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
    max-width: 384px;
  }
`;

export const ResponsiveLinkable = styled(LinkableButton)`
  button {
    margin-top: 48px;
    margin-bottom: 48px;
    min-width: 216px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.SMALL}) {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
    max-width: 384px;
  }
`;

// Lens will only return a CTA button if there is no CTA link, therefore there is no need for a condition to render either or.
export const ButtonOrLink = ({
  onClick,
  linkable,
  kind,
  button,
}: {
  onClick?: () => void;
  linkable: Maybe<AppLinkFragment | LinkableFragment>;
  kind: ButtonKind;
  button: Maybe<AppButtonFragment>;
}) => {
  return (
    <>
      <Skeleton>
        {linkable && (
          <ResponsiveLinkable kind={kind} size="large" linkable={linkable} />
        )}
        {button && (
          <ResponsiveAppButton
            kind={kind}
            size="large"
            appButton={button}
            type="submit"
            onClick={onClick}
          />
        )}
      </Skeleton>
    </>
  );
};
