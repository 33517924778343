import { Box, Skeleton, styled } from '@m1/liquid-react';
import * as React from 'react';

const StyledMarginListItem = styled(Box)`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.borderMain};
  justify-content: space-between;
  align-items: center;
`;

export const MarginListItemsContainer = styled(Box)`
  // For some reason, \$\{StyledInterestItem} selection doesn't work
  div:first-child {
    border-top: none;
  }
`;

export const MarginListItem = ({
  label,
  value,
}: {
  label: React.ReactNode;
  value?: React.ReactNode;
}) => (
  <StyledMarginListItem font="PM" fontWeight={400} py={14}>
    <Skeleton color="foregroundNeutralSecondary" skeletonWidth="20%">
      {label}
    </Skeleton>
    <Skeleton
      color="foregroundNeutralMain"
      skeletonWidth="20%"
      textAlign="right"
    >
      {value}
    </Skeleton>
  </StyledMarginListItem>
);
