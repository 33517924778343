import React from 'react';

import { useGetEstimatedSellsQuery } from '~/graphql/hooks';

import { AccountEstimatedTradesTable } from './AccountEstimatedTradesTable';
import { LoadMoreButton } from './LoadMoreButton';
import { NoTradesContent } from './NoTradesContent';

type EstimatedSellsTableProps = {
  accountId: string;
  style?: any;
};

export const EstimatedSellsTable = ({
  accountId,
  style,
}: EstimatedSellsTableProps) => {
  const { data, loading, variables, refetch } = useGetEstimatedSellsQuery({
    fetchPolicy: 'network-only',
    variables: {
      accountId,
      first: 100,
    },
  });

  const account = data?.account?.__typename === 'Account' ? data.account : null;

  const sells = account?.estimatedTrading?.sells;

  const pageInfo = sells?.pageInfo;

  const handleFetchMore = React.useCallback(() => {
    const oldFirst = variables?.first ?? 0;
    refetch({
      first: oldFirst + 100,
    });
  }, [refetch, variables]);

  if (!sells?.edges && !loading) {
    return (
      <NoTradesContent
        picture="cryptoNoPendingBuys"
        content="No pending sells"
      />
    );
  }

  return (
    <AccountEstimatedTradesTable
      totalTrades={sells?.total ?? 0}
      edges={sells?.edges}
      style={style}
      loadMoreTradesButton={
        <LoadMoreButton
          handleLoadMore={handleFetchMore}
          loading={loading}
          pageInfo={pageInfo}
        />
      }
    />
  );
};
