import * as React from 'react';

import {
  SecurityStatusEnum,
  SystemPieStatusEnum,
  SliceableStatusEnum,
} from '~/graphql/types';
import { Indicator } from '~/toolbox/indicator';

export type SliceableStatusIndicatorProps = {
  status?: Maybe<
    SliceableStatusEnum | SecurityStatusEnum | SystemPieStatusEnum
  >;
};

export const SliceableStatusIndicator = ({
  status,
}: SliceableStatusIndicatorProps) => {
  const showInactiveOrDelistedIndicator =
    status === 'INACTIVE' || status === 'DELISTED';

  return showInactiveOrDelistedIndicator ? (
    <Indicator color="orange" icon="warning24" />
  ) : null;
};
