import { Box, Flex, PXL, Card } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { AppContext } from '~/AppContext';
import { SliceableLogo } from '~/components/pie/SliceableLogo';
import { ChoosePieStepQuery, SliceableFragment } from '~/graphql/types';

type UserPies = ChoosePieStepQuery['viewer']['userPies'];

type CryptoUserPiesSectionProps = {
  onFinishStep?: (pieId: string) => void;
  userPies: UserPies | null | undefined;
};

export const CryptoUserPiesSection = ({
  onFinishStep,
  userPies,
}: CryptoUserPiesSectionProps) => {
  const { analytics } = React.useContext(AppContext);
  const pies = userPies?.edges || [];

  return (
    <Flex>
      {pies.length > 0 && (
        <Card pb={16}>
          <PXL fontWeight={600} content="Use a Pie you’ve made" m={24} />
          <Flex flexWrap="wrap" px={16} justifyContent="space-between">
            {pies.map(
              (pie) =>
                pie?.node && (
                  <Card
                    key={pie.node.id}
                    onClick={() => {
                      analytics.recordEvent('m1_crypto_pre_built_pie_selected');

                      pie.node?.id && onFinishStep && onFinishStep(pie.node.id);
                    }}
                    height={64}
                    p={16}
                    width="47%"
                    mx={8}
                    mb={8}
                  >
                    <Flex alignItems="center">
                      <Box height={32} width={32}>
                        <SliceableLogo
                          sliceable={pie.node as SliceableFragment}
                        />
                      </Box>
                      <Box
                        flex="1"
                        px={8}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {pie.node.name}
                      </Box>
                      <Flex alignItems="center" mr={16}>
                        <Icon name="caretRight16" />
                      </Flex>
                    </Flex>
                  </Card>
                ),
            )}
          </Flex>
        </Card>
      )}
    </Flex>
  );
};
