// @ts-expect-error - TS2305 - Module '"~/graphql/types"' has no exported member 'MonthlyScheduleDateEnumValues'.
import { MonthlyScheduleDateEnumValues } from '~/graphql/types';

/**
 * Represents a book.
 * @returns Enum for the default date value to be used
 * @description Gets the day of the month and returns that as an enum
 */
export const getDefaultDateValue = (): MonthlyScheduleDateEnumValues => {
  const dayOfMonth = new Date().getDate();

  if (dayOfMonth > 1 && dayOfMonth < 30) {
    return `DAY_${dayOfMonth - 1}`;
  }

  return 'LAST';
};
