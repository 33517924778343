import { Box } from '@m1/liquid-react';
import * as React from 'react';

import {
  CollectAnnualIncomeStep,
  CollectTotalNetWorthStep,
  CollectLiquidNetWorthStep,
} from '~/components/SharedOnboardingSteps';
import { KNOW_YOUR_CUSTOMER_FLOW_STEPS as STEPS } from '~/static-constants';
import { Container } from '~/toolbox/container';

import { CollectIntroductionSourceStep } from './steps';

export type KnowYourCustomerContainerProps = {
  onFinishStep: () => void;
  step: ValueOf<typeof STEPS>;
};

export const KnowYourCustomerContainer = ({
  step,
  ...rest
}: KnowYourCustomerContainerProps) => {
  const steps = {
    [STEPS.COLLECT_ANNUAL_INCOME]: CollectAnnualIncomeStep,
    [STEPS.COLLECT_TOTAL_NET_WORTH]: CollectTotalNetWorthStep,
    [STEPS.COLLECT_LIQUID_NET_WORTH]: CollectLiquidNetWorthStep,
    [STEPS.COLLECT_INTRODUCTION_SOURCE]: CollectIntroductionSourceStep,
  };

  const Step = steps[step];

  return Step ? (
    <Container marginTop={32}>
      <Box maxWidth={700} m="auto">
        {/* @ts-expect-error - TS2559 - Type '{ onFinishStep: () => void; }' has no properties in common with type 'IntrinsicAttributes'. */}
        <Step {...rest} />
      </Box>
    </Container>
  ) : null;
};
