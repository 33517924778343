import { styled, useTheme } from '@m1/liquid-react';
import * as React from 'react';

import { CONTROL_IMG_DIRECTIONS } from '~/static-constants';

type ControlImgDirection = 'reversed' | 'vertical' | 'vertical_reversed';

export type ControlImgProps = {
  direction?: ControlImgDirection;
};

const StyledSvg = styled.svg`
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 4px 16px
      ${(props) => props.theme.colors.foregroundNeutralTertiary};
  }
`;

const StyledGroup = styled.g<{
  $direction: ControlImgDirection | null | undefined;
}>`
  transform-origin: center;
  ${(props) => {
    switch (true) {
      case props.$direction === CONTROL_IMG_DIRECTIONS.REVERSED:
        return `transform: rotate(180deg);`;
      case props.$direction === CONTROL_IMG_DIRECTIONS.VERITCAL:
        return `transform: rotate(90deg);`;
      case props.$direction === CONTROL_IMG_DIRECTIONS.VERTICAL_REVERSED:
        return `transform: rotate(-90deg);`;
      default:
        return;
    }
  }};
`;

export const ControlImg = ({ direction }: ControlImgProps) => {
  const theme = useTheme();
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <circle
        cx="16"
        cy="16"
        r="16"
        fill={theme.colors.backgroundNeutralTertiary}
      />
      <StyledGroup
        $direction={direction}
        clipRule="evenodd"
        fillRule="evenodd"
        stroke={theme.colors.primary}
        strokeLinecap="round"
        strokeWidth={1.5}
      >
        <path d="M 6,16 l 20,0" />
        <path d="M 6,16 l 8,8" />
        <path d="M 6,16 l 8,-8" />
      </StyledGroup>
    </StyledSvg>
  );
};
