import {
  Button,
  HXXS,
  PL,
  Flex,
  Box,
  Modal,
  ModalContent,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import {
  togglePortfolioOrganizerHelperDialogAction,
  updateUserData,
} from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

export type HelperDialogIntroModalProps = {
  isVisible: boolean;
};

export const HelperDialogIntroModal = ({
  isVisible,
}: HelperDialogIntroModalProps) => {
  const [isOpen, setIsOpen] = React.useState(isVisible);
  const dispatch = useDispatch();

  return (
    <Modal closeOnDocumentClick closeIcon={null} open={isVisible && isOpen}>
      <ModalContent withPadding={false}>
        <Flex
          borderRadius="10px 10px 0 0"
          backgroundColor="borderInfo"
          height={224}
        >
          <Flex
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            width="100%"
          >
            <Illustration
              // @ts-expect-error - TS2322 - Type '{ alt: string; name: "helperTutorial"; height: number; width: number; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'.
              alt=""
              name="helperTutorial"
              height={126}
              width={164}
            />
          </Flex>
        </Flex>
        <Flex
          height={280}
          flexDirection="column"
          justifyContent="space-between"
          p={32}
        >
          <Flex alignItems="center" justifyContent="center">
            <Icon name="help24" color="primary" />
            <HXXS ml={12}>Helper</HXXS>
          </Flex>
          <Flex justifyContent="center">
            <PL>
              Located in the top right corner, <b>Helper</b> can be switched on
              to explain what is being displayed on screen. Access to FAQs are
              in <b>Helper</b>. Tap on
              <Box
                as="span"
                style={{
                  margin: '0 8px 0 8px',
                  top: '4px',
                  position: 'relative',
                  display: 'inline',
                }}
              >
                <Icon name="helpFill24" color="primary" />
              </Box>
              to switch off.
            </PL>
          </Flex>
          <Flex justifyContent="center">
            <Button
              label="Close"
              onClick={() => {
                // Set the timestamp on the intro flag so we don't show the intro to the user again.
                dispatch(
                  updateUserData({
                    key: 'portfolioOrganizerIntroHelperDate',
                    value: new Date().toISOString(),
                  }),
                );
                dispatch(togglePortfolioOrganizerHelperDialogAction());
                setIsOpen(false);
              }}
            />
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
