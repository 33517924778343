import classNames from 'classnames';
import * as React from 'react';

import { LegendItem, LegendItemProps } from './item';
import style from './style.module.scss';

type ChildItem = React.ReactElement<typeof LegendItem>;

type Props = {
  children?: ChildItem | ChildItem[];
  direction: 'horizontal' | 'vertical';
  items?: LegendItemProps[];
};

export class Legend extends React.Component<Props> {
  static Item = LegendItem;

  static defaultProps = {
    direction: 'horizontal',
  };

  render() {
    const classes = classNames(
      style.root,
      style[`direction_${this.props.direction}`],
    );
    return <div className={classes}>{this.collectChildren()}</div>;
  }

  collectChildren() {
    return Array.isArray(this.props.items)
      ? this.props.items.map((item, i) => <LegendItem key={i} {...item} />)
      : this.props.children;
  }
}
