import { styled, Box } from '@m1/liquid-react';
import * as React from 'react';

import { EditModelSlice } from '~/pages/share/SharePiePage.types';
import { GridTable } from '~/toolbox/grid-table';
import { VirtualizedRows } from '~/toolbox/virtualized/VirtualizedRows';

import { SharePiePageSliceListRow } from './SharePiePageSliceListRow';

const SliceListGridTable = styled(GridTable)`
  /* Turns off side scrolling table and just uses ellipses overflow */
  > div {
    width: 100%;
  }
`;

type SharePiePageSliceListProps = {
  slices: Array<EditModelSlice>;
  handleClickOnSlice?: (sliceId: string) => void;
};

export const SharePiePageSliceList = ({
  slices,
  handleClickOnSlice,
}: SharePiePageSliceListProps) => {
  const rowList = slices.map((slice) => ({
    ...slice,
    handleClickOnSlice: (sliceId: string) => handleClickOnSlice?.(sliceId),
  }));
  return (
    <Box width="100%">
      <SliceListGridTable
        gridTemplateColumns="1fr 100px"
        css={{
          padding: 0,
          margin: 0,
          width: '100%',
        }}
        isNewStyle
        isNavigable={false}
        virtualization={{
          visibleRowCount: 8,
          rowList,
        }}
      >
        <GridTable.HeaderRow>
          <GridTable.HeaderCell
            label={`Slices in this Pie (${slices.length})`}
          />
          <GridTable.HeaderCell justifySelf="right" label="Target" />
        </GridTable.HeaderRow>
        <VirtualizedRows rowHeight={62} row={SharePiePageSliceListRow} />
      </SliceListGridTable>
    </Box>
  );
};
