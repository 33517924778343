import { Flex, HXS, HXXS, PL, styled } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useWireTransferInstructionsQuery } from '~/graphql/hooks';
import { LinkableFragment } from '~/graphql/types';
import { useBreakpoints } from '~/hooks/useBreakpoints';
import { useNavigate } from '~/hooks/useNavigate';
import { AppImage } from '~/lens-toolbox/AppImage';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { Spinner } from '~/toolbox/spinner';
import { isNil, isNotNil } from '~/utils';

import { useWireTransferContext } from '../../providers/WireTransferProvider';

import { WireTransferInstructionCards } from './WireTransferInstructionCards';

const StyledUnorderedList = styled.ul`
  padding-left: 24px;
`;

const StyledListItem = styled('li')<{
  mt?: number;
}>`
  margin-top: ${({ mt }) => (isNotNil(mt) ? mt : 0)}px;
  margin-bottom: 4px;
`;

export const WireTransferInstructions = () => {
  const navigate = useNavigate();
  const { isDesktop, isTablet } = useBreakpoints();

  const { selectedAccount: accountId } = useWireTransferContext();

  React.useEffect(() => {
    if (!accountId) {
      navigate({ to: '/d/c/wire-transfer' });
    }
  }, [navigate, accountId]);

  const { data, loading } = useWireTransferInstructionsQuery({
    skip: !accountId,
    variables: {
      accountId: accountId as string,
    },
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  const wireInstructions =
    data?.viewer?.transfers?.moveMoneyV2?.wireInstructions;

  if (isNil(wireInstructions)) {
    return <GenericSystemError />;
  }

  const {
    title,
    subtitle,
    accountHeader,
    accountLabel,
    illustration,
    contactUsLink,
    helpfulTips,
    wireInstructionValues,
    wireTransferFAQLink,
  } = wireInstructions;
  const allTips: Array<string | LinkableFragment> = [...helpfulTips];

  if (isNotNil(contactUsLink)) {
    allTips.push(contactUsLink);
  }

  if (isNotNil(wireTransferFAQLink)) {
    allTips.push(wireTransferFAQLink);
  }

  return (
    <Flex
      flexDirection={!isDesktop ? 'column' : 'row'}
      padding={32}
      maxWidth={1200}
      margin="auto"
    >
      {!isTablet && (
        <Flex
          alignItems="center"
          flexDirection="column"
          minWidth={384}
          textAlign="center"
        >
          {isNotNil(illustration) && <AppImage appImage={illustration} />}
          {isNotNil(accountHeader) && <HXS content={accountHeader} mb={8} />}
          {isNotNil(accountLabel) && <PL content={accountLabel} />}
        </Flex>
      )}
      <Flex ml={32} padding={16} flexDirection="column">
        <HXXS content={title} mb={12} />
        <PL content={subtitle} />

        <WireTransferInstructionCards
          wireInstructionValues={wireInstructionValues}
        />

        <Flex flexDirection="column" mt={8}>
          <HXXS content="Helpful tips" mb={12} />
          <StyledUnorderedList>
            {allTips.map((tip, index) => {
              if (typeof tip === 'string') {
                return <StyledListItem key={index}>{tip}</StyledListItem>;
              }

              return (
                <StyledListItem key={index} mt={index === 2 ? 8 : 0}>
                  <LinkableLink linkable={tip} />
                </StyledListItem>
              );
            })}
          </StyledUnorderedList>
        </Flex>
      </Flex>
    </Flex>
  );
};
