import {
  // eslint-disable-next-line no-restricted-imports
  useParams as useRouterParams,
} from 'react-router-dom';

/** Params must be defined */
export const useParams = () => {
  const params = useRouterParams();

  return params;
};
