import includes from 'lodash-es/includes';

// Update this array with any mutation keys that could include Personally Identifiable Information (PII) in lowercase.
const personallyIdentifiableInformationKeys = [
  'accountnumber',
  'additionalemail',
  'agreementsignature',
  'beneficiaryssns',
  'callbackphonenumber',
  'city',
  'country',
  'countryofcitizenship',
  'dateofbirth',
  'email',
  'employer',
  'employment',
  'expiremonth',
  'expireyear',
  'firmname',
  'firstname',
  'fullname',
  'homeaddress',
  'immediatefamilymembers',
  'lastname',
  'lineone',
  'linetwo',
  'middleinitial',
  'newpassword',
  'newpin',
  'nickname',
  'number',
  'occupation',
  'oldpassword',
  'panlastfour',
  'panmasked',
  'password',
  'phone',
  'phonenumber',
  'pin',
  'politicalorganization',
  'postalcode',
  'primaryssn',
  'relationship',
  'routingnumber',
  'secondaryssn',
  'signature',
  'spenddebitcardid',
  'ssn',
  'ssnlastfour',
  'stateorprovince',
  'suffix',
  'username',
  'verificationAnswers',
];

// Serializer will remove any PII keys, as defined in the personallyIdentifiableInformationKeys array, before sending the exception to Sentry
export function serializeObjectForSentryException(
  obj: Record<string, any>,
): Record<string, any> {
  if (typeof obj !== 'object' || !obj) {
    return obj;
  }

  const serializedObjectSet = new Set();
  serializedObjectSet.add(obj);

  const serializedSentryException = {};

  for (const key in obj) {
    if (includes(personallyIdentifiableInformationKeys, key.toLowerCase())) {
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
      serializedSentryException[key] = '[REDACTED]';
    } else if (key.toLowerCase() === 'query') {
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
      serializedSentryException[key] = '[TRUNCATED]';
    } else if (key.toLowerCase() === 'response') {
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
      serializedSentryException[key] =
        serializeResponseObjectForRemoteErrorSentryException(obj[key]);
    } else if (Array.isArray(obj[key])) {
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'. | TS7006 - Parameter 'element' implicitly has an 'any' type.
      serializedSentryException[key] = obj[key].map((element) => {
        if (serializedObjectSet.has(element)) {
          return element;
        }

        const serializedObject = serializeObjectForSentryException(element);
        serializedObjectSet.add(serializedObject);
        return serializedObject;
      });
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      if (serializedObjectSet.has(obj[key])) {
        return obj[key];
      }

      const serializedObject = serializeObjectForSentryException(obj[key]);
      serializedObjectSet.add(serializedObject);
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
      serializedSentryException[key] = serializedObject;
    } else {
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
      serializedSentryException[key] = obj[key];
    }
  }

  return serializedSentryException;
}

export function serializeResponseObjectForRemoteErrorSentryException(
  response: Record<string, any>,
): Record<string, any> {
  if (typeof response !== 'object' || !response) {
    return response;
  }

  const serializedObjectSet = new Set();
  serializedObjectSet.add(response);

  const serializedResponseObject = {};

  for (const key in response) {
    if (response[key] === null) {
      continue;
    } else if (
      includes(personallyIdentifiableInformationKeys, key.toLowerCase())
    ) {
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
      serializedResponseObject[key] = '[REDACTED]';
    } else if (Array.isArray(response[key])) {
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'. | TS7006 - Parameter 'element' implicitly has an 'any' type.
      serializedResponseObject[key] = response[key].map((element) => {
        if (serializedObjectSet.has(element)) {
          return element;
        }

        const serializedObject =
          serializeResponseObjectForRemoteErrorSentryException(element);
        serializedObjectSet.add(serializedObject);
        return serializedObject;
      });
    } else if (typeof response[key] === 'object' && response[key] !== null) {
      if (serializedObjectSet.has(response[key])) {
        return response[key];
      }

      const serializedObject =
        serializeResponseObjectForRemoteErrorSentryException(response[key]);
      serializedObjectSet.add(serializedObject);
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
      serializedResponseObject[key] = serializedObject;
    } else {
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
      serializedResponseObject[key] = response[key];
    }
  }

  return serializedResponseObject;
}
