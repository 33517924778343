import { Button, HXS, PL, Flex, Box, styled } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import React, { useState, useEffect } from 'react';

import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import { GeneralErrorPage } from '~/flows/components/personal-loans/application/steps/GeneralErrorPage';
import { useLoanOffersAndSubmitPageQuery } from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import { useSelector } from '~/redux/hooks';
import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';
import { Spinner } from '~/toolbox/spinner';

import { WithdrawApplicationModal } from '../components/WithdrawApplicationModal';
import { usePersonalLoanDirectWizardContext } from '../personal-loan-direct-wizard/usePersonalLoanDirectWizardContext';
import { useIsPhoneDevice } from '../utils/isPhoneDevice';

const Container = styled(Box)`
  margin-top: 72px;
  margin-bottom: 32px;

  @media screen and ${RESPONSIVE_BREAKPOINTS.SMALL} {
    margin-top: 32px;
    margin-bottom: 64px;
  }
`;

export const IncomeVerificationRequired = () => {
  const isPhoneDevice = useIsPhoneDevice();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const analytics = useAnalytics();
  const { goTo } = usePersonalLoanDirectWizardContext();

  const { data: activeApplicationData, loading: activeApplicationDataLoading } =
    useLoanOffersAndSubmitPageQuery();
  const applicationId =
    activeApplicationData?.viewer?.borrow?.personalLoans?.activeApplication?.id;
  const { loanId } = useSelector((state) => state.reactHookForm.loanInfo);

  useEffect(() => {
    analytics.pageView(
      '/direct-loan-application-error/income-verification-required',
    );
  }, [analytics]);

  if (activeApplicationDataLoading) {
    return <Spinner fullScreen centered />;
  }

  if (!applicationId) {
    return <GeneralErrorPage />;
  }

  return (
    <>
      <Container>
        <Illustration height={100} width="100%" name="bankConnectionBank" />
      </Container>

      <HXS content="We need to verify your income." mb={8} />

      <PL content="Please use Plaid to log in to the bank where you deposit your income. This won’t impact your bank connection(s) between M1 and Plaid." />
      <br />
      <PL content="Once you start this quick process, you’ll need to finish it to proceed with your loan application." />
      <br />
      <PL mb={24}>
        {`Offers are good for 30 days, or you can `}
        <Button
          kind="link"
          style={{ textDecoration: 'underline' }}
          onClick={() => setModalOpen(true)}
          label="withdraw"
        />
        {` your application now.`}
      </PL>

      <WithdrawApplicationModal
        applicationId={applicationId}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        loanId={loanId}
      />
      <Flex
        mt={48}
        flexDirection={isPhoneDevice ? 'column' : 'row'}
        alignItems="center"
      >
        <ResponsiveButton
          label="Verify using Plaid"
          onClick={() => {
            goTo('PLAID_INCOME_VERIFICATION');
          }}
        />
        <Button
          kind="text"
          size="large"
          label="Save and close"
          onClick={() => navigate({ to: '/d/home' })}
        />
      </Flex>
    </>
  );
};
