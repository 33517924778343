import {
  PL,
  OneColumnConstrained,
  styled,
  theme,
  Flex,
} from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';

import { useNavigate } from '~/hooks/useNavigate';

import { StyledHeader } from './StyledHeader';

const StyledIllustration = styled(Illustration)`
  height: 234px;
  width: 234px;

  @media screen and (min-width: ${theme.breakpoints.SMALL}) {
    height: 343px;
    width: 343px;
  }
`;

export const DisbursementError = () => {
  const navigate = useNavigate();

  return (
    <OneColumnConstrained>
      <Flex justifyContent="center" mt={48}>
        <StyledIllustration name="transferUnsuccessfulWide" />
      </Flex>
      <StyledHeader content="We couldn't deposit money into your bank account." />
      <PL
        content="Something went wrong with the transfer. Please contact Client
          Support so we can get this straightened out for you at (312)
          600-2883."
        my={8}
      />
      <ResponsiveButton
        mt={36}
        kind="primary"
        label="Return Home"
        onClick={() => {
          navigate({ to: '/d/home' });
        }}
        size="large"
      />
    </OneColumnConstrained>
  );
};
