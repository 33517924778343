import castArray from 'lodash-es/castArray';
import * as React from 'react';
import { Field } from 'redux-form';

import { withProps } from '~/hocs';
import { Dropdown } from '~/toolbox/Dropdown';

// @ts-expect-error - TS7031 - Binding element 'input' implicitly has an 'any' type. | TS7031 - Binding element 'meta' implicitly has an 'any' type.
const renderDropdown = ({ input, meta, ...rest }) => {
  return React.createElement(Dropdown, {
    ...rest,
    ...meta,
    ...input,
    error: readError(meta),
    parse: readParse(rest),
  });
};

// @ts-expect-error - TS7006 - Parameter 'meta' implicitly has an 'any' type.
function readError(meta) {
  return meta.submitFailed ? meta.error : null;
}

function readParse({
  parse,
  parseToNull,
}: Record<string, any>): ((...args: Array<any>) => any) | null | undefined {
  if (typeof parse === 'function') {
    return parse;
  }

  if (parseToNull) {
    const toCoerce: Array<string> = castArray(parseToNull);
    return (value: string): string | null => {
      return toCoerce.includes(value) ? null : value;
    };
  }
}

const enhancer = withProps((props) => ({
  component: renderDropdown,
  // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'Record<string, any>'.
  parse: readParse(props),
}));

export const DropdownField = enhancer(Field) as any;
