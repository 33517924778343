import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';

export function* showFailureToast(): SagaIterator<void> {
  yield put({
    payload: {
      content:
        'Something went wrong. Please go back to verify what you entered is correct, or contact Client Support.',
      kind: 'alert',
    },
    type: 'ADD_TOAST',
  });
}
