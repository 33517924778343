import { Button, HXS, PL } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { logout } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { Container } from '~/toolbox/container';

export const RenderFailurePage = () => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(logout());

  return (
    <Container centered padded text>
      <Illustration name="warningTriangle" />
      <HXS
        content="Something went wrong"
        mt={32}
        color="foregroundNeutralMain"
      />
      <PL
        content="We're working on it. If the problem persists, please contact support."
        mt={16}
        color="foregroundNeutralSecondary"
      />
      <Button
        kind="primary"
        label="Logout"
        mt={64}
        onClick={handleClick}
        size="large"
      />
    </Container>
  );
};
