import {
  Box,
  Color,
  Flex,
  PL,
  PM,
  Pill,
  Skeleton,
  SkeletonProvider,
} from '@m1/liquid-react';
import { Icon, AllIconName } from '@m1/liquid-react/icons';
import * as React from 'react';

import { useCommonTransactionDetailsQuery } from '~/graphql/hooks';
import {
  CommonTransactionDetail,
  CommonTransactionPrimaryInfo,
  RichTextFragment,
  CommonTransactionDetailsQuery,
  CommonTransactionStatusEnum,
} from '~/graphql/types';
import { Divider } from '~/toolbox/divider';

import { Link } from '~/toolbox/link/Link';

import { RichText } from '../../lens-toolbox/RichText/RichText';
import { mapToPillKind } from '../CommonTransactionsTable/utils';
import { CreditCardRewardsPill } from '../credit-card/CreditCardRewardsAppPill';

import {
  StyledBox,
  StyledContainer,
  StyledDetailLabel,
  StyledDetailValue,
  StyledDetailSectionContainer,
  StyledDetailSectionHeader,
  StyledHeader,
  StyledHeaderContainer,
  StyledPrimaryDetailsContainer,
  StyledPrimaryDetailsItemContainer,
  StyledPrimaryDetailsItemLabel,
  StyledPrimaryDetailsItemValue,
  StyledDetailMetadataContainer,
  StyledLinkBox,
  StyledLinkText,
  StyledPillContainer,
  ContentSlider,
} from './CommonTransactionDetailsCard.styled';

type TransactionDetailsCardProps = {
  onClose: () => void;
  transactionId?: string | null;
  showModal: boolean;
};

export const CommonTransactionDetailsCard = ({
  onClose,
  transactionId = null,
  showModal,
}: TransactionDetailsCardProps) => {
  const ref = React.useRef(null);
  const { data, loading } = useCommonTransactionDetailsQuery({
    skip: !transactionId,
    variables: { transactionId: transactionId || '' },
  });

  React.useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        ref.current &&
        !(ref.current as HTMLElement).contains(event.target) &&
        !event.target.closest('.common-transaction-table-row')
      ) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  React.useEffect(() => {
    const element = document.querySelector(
      '#commonTransactionDetailCard1',
    ) as HTMLElement;

    if (showModal && element) {
      // We only want to render the scrollbar after the modal has fully expanded
      setTimeout(() => {
        element.style.overflowY = 'auto';
      }, 750);
    } else {
      element.style.overflowY = 'hidden';
    }
  }, [showModal]);

  const node = data?.node as ExtractTypename<
    CommonTransactionDetailsQuery['node'],
    'CommonTransaction'
  >;

  const noTransactionFound = !node;
  const transactionDetails = node?.details;
  const header = transactionDetails?.header;
  const status = transactionDetails?.status;
  const description = transactionDetails?.description;
  const primaryDetails = transactionDetails?.primaryDetails;
  const detailSections = transactionDetails?.detailSections;
  const link = transactionDetails?.link;

  return (
    <StyledContainer ref={ref}>
      <StyledBox id="commonTransactionDetailCard1" isOpen={showModal}>
        <SkeletonProvider isLoading={loading} fadeOut>
          <ContentSlider
            key={transactionId}
            shouldSlide={Boolean(transactionId) && showModal}
            isOpen={showModal}
          >
            {transactionId && (
              <StyledHeaderContainer>
                <StyledHeader content={header ?? 'Transaction details'} />
                <Box onClick={onClose} cursor="pointer">
                  <Icon name="close20" />
                </Box>
              </StyledHeaderContainer>
            )}
            <Skeleton skeletonWidth="100%" skeletonHeight={70}>
              {noTransactionFound && transactionId && (
                <PM
                  fontWeight={600}
                  color="foregroundNeutralSecondary"
                  content="Unable to fetch transaction details. Please try again later."
                />
              )}

              {description?.map(
                (content, idx) =>
                  content && (
                    <RichText
                      key={idx}
                      richText={content as RichTextFragment}
                      textProps={{
                        font: 'PM',
                        fontWeight: 600,
                        color: 'foregroundNeutralSecondary',
                      }}
                      containerProps={{ marginBottom: 16 }}
                    />
                  ),
              )}

              <CommonTransactionPrimaryDetailsSection
                primaryDetails={primaryDetails}
                strikeThrough={
                  status === CommonTransactionStatusEnum.Canceled ||
                  status === CommonTransactionStatusEnum.Failed
                }
              />
            </Skeleton>

            <Skeleton skeletonWidth="100%" skeletonHeight={320}>
              {detailSections?.map((section, idx) => (
                <Box key={idx}>
                  <StyledDetailSectionHeader content={section?.header} />
                  {section?.details?.map((detail, idx) => (
                    <CommonTransactionDetailSection
                      key={idx}
                      detail={detail as CommonTransactionDetail}
                    />
                  ))}
                  {idx !== detailSections.length - 1 && <Divider />}
                </Box>
              ))}

              {link?.internalPath && transactionId && (
                <Link
                  params={{ disputeDetailsId: transactionId }}
                  to={link.internalPath}
                >
                  <StyledLinkBox>
                    <Icon
                      color={link?.icon?.color as Color}
                      name={link?.icon?.names?.[0] as AllIconName}
                    />
                    <StyledLinkText content={link?.title} />
                  </StyledLinkBox>
                </Link>
              )}
            </Skeleton>
          </ContentSlider>
        </SkeletonProvider>
      </StyledBox>
    </StyledContainer>
  );
};

export const CommonTransactionPrimaryDetailsSection = ({
  primaryDetails,
  strikeThrough,
}: {
  primaryDetails?: CommonTransactionPrimaryInfo | null;
  strikeThrough?: boolean;
}) => {
  return primaryDetails ? (
    <>
      <StyledPrimaryDetailsContainer>
        <StyledPrimaryDetailsItemContainer gap={3}>
          <StyledPrimaryDetailsItemLabel
            content={primaryDetails?.amount?.label}
          />
          <StyledPrimaryDetailsItemValue
            content={primaryDetails?.amount?.value?.text}
            color={primaryDetails?.amount?.value?.color as Color}
            strikeThrough={strikeThrough}
          />
        </StyledPrimaryDetailsItemContainer>
        <StyledPrimaryDetailsItemContainer gap={8}>
          <StyledPrimaryDetailsItemLabel
            content={primaryDetails?.status?.label}
          />
          <Box>
            {primaryDetails?.status?.pill?.label && (
              <Pill
                kind={mapToPillKind(primaryDetails?.status?.pill?.kind)}
                label={primaryDetails?.status?.pill?.label}
              />
            )}
          </Box>
        </StyledPrimaryDetailsItemContainer>
      </StyledPrimaryDetailsContainer>
      <Divider />
    </>
  ) : null;
};

export const CommonTransactionDetailSection = ({
  detail,
}: {
  detail?: CommonTransactionDetail | null;
}) => {
  return detail ? (
    <StyledDetailSectionContainer>
      <StyledDetailLabel content={detail?.label} />
      {detail?.pill && detail?.pill?.label && (
        <StyledPillContainer>
          <Pill
            kind={mapToPillKind(detail?.pill?.kind)}
            label={detail?.pill?.label}
          />
        </StyledPillContainer>
      )}
      {detail?.rewardTierPill && (
        <StyledPillContainer>
          <CreditCardRewardsPill appPill={detail?.rewardTierPill} />
        </StyledPillContainer>
      )}
      <Flex>
        <StyledDetailValue content={detail?.value?.text} />
      </Flex>
      {detail.metadata && (
        <StyledDetailMetadataContainer>
          {detail.metadata.map((line, idx) => (
            <PL content={line} marginLeft={8} key={idx} />
          ))}
        </StyledDetailMetadataContainer>
      )}

      {detail.disclaimers?.map(
        (disclaimer, idx) =>
          disclaimer && (
            <RichText
              key={idx}
              richText={disclaimer as RichTextFragment}
              textProps={{
                font: 'PS',
                fontWeight: 400,
                color: 'foregroundNeutralSecondary',
              }}
              linkProps={{ font: 'PS', paddingRight: '4px', fontWeight: 600 }}
              containerProps={{
                display: 'inline-block',
                marginTop: 4,
              }}
            />
          ),
      )}
    </StyledDetailSectionContainer>
  ) : null;
};
