import { Box, Flex, PM, styled, Tooltip } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { useSupportLink } from '~/hooks/useSupportLink';
import { Link } from '~/toolbox/link';

const Container = styled(Flex)`
  padding: 0 24px;
  align-items: center;
  justify-content: space-between;
  min-height: 72px;
  width: 100%;
  background: ${({ theme }) => theme.colors.backgroundNeutralSecondary};
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.borderMain}`};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.XSMALL}) {
    padding: 0 120px;
    border-bottom: ${({ theme }) => `4px solid ${theme.colors.borderMain}`};
  }
`;

export const PromoHeader = () => {
  const supportLink = useSupportLink();

  return (
    <Container>
      <Icon name="m1Logo32" mr={64} />
      <Tooltip
        placement="bottom-end"
        icon="help24"
        body={
          <Box textAlign="left">
            <PM textAlign="left">
              If you need assistance, please visit our{' '}
              <Link {...supportLink}>Support Center</Link>
            </PM>
          </Box>
        }
      />
    </Container>
  );
};
