import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';

import { VerifyEmailDocument } from '~/graphql/hooks';
import { VerifyEmailInput } from '~/graphql/types';
import { NavigateFunction } from '~/hooks/useNavigate';

import type { AppState } from '../../reducers/types';
import { apolloMutationSaga } from '../apolloMutationSaga';
import { getLoggers } from '../common';

export function* verifyEmailMonitor(
  verificationToken: string,
): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state: AppState) => state.routing.navigate,
  );
  const { analytics, sentry } = yield call(getLoggers);

  if (!verificationToken) {
    const config = yield select((state: AppState) => state.config);
    sentry.message(
      'User arrived at verify email flow without a verification token.',
      {
        extra: {
          config,
        },
      },
    );

    yield call(navigate, {
      to: '/verify-email/:verificationToken/error',
      params: { verificationToken },
    });

    return;
  }

  try {
    yield call(apolloMutationSaga, {
      mutation: VerifyEmailDocument,
      variables: {
        input: {
          verificationToken,
        } satisfies VerifyEmailInput,
      },
    });
    analytics.mutation('account', 'emailVerified');
    yield call(navigate, {
      to: '/verify-email/:verificationToken/success',
      params: { verificationToken },
    });
  } catch (e: any) {
    yield call(navigate, {
      to: '/verify-email/:verificationToken/error',
      params: { verificationToken },
    });
  }
}
