import { Button, Flex, HXXS } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

type ScreenerFilterProps = {
  setFiltersOpen: (e: boolean) => void;
  handleReset: () => void;
  hasFilters: boolean;
  filtersOpen: boolean;
};

export const ScreenerFilterContainer = ({
  setFiltersOpen,
  handleReset,
  hasFilters,
  children,
}: React.PropsWithChildren<ScreenerFilterProps>) => {
  const isSmallDevice = useMediaQuery({
    query: '(max-width: 992px)',
  });

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <HXXS display="flex">
          <Icon name="filter24" mr={8} /> Filters
        </HXXS>
        {isSmallDevice && (
          <Button
            kind="link"
            label=""
            rightIcon="close16"
            onClick={() => setFiltersOpen(false)}
          />
        )}
        {!isSmallDevice && (
          <Button
            kind="link"
            label="Reset"
            disabled={!hasFilters}
            onClick={handleReset}
          />
        )}
      </Flex>
      {isSmallDevice && (
        <Flex justifyContent="flex-end" mt={12}>
          <Button
            kind="link"
            label="Reset"
            disabled={!hasFilters}
            onClick={handleReset}
          />
        </Flex>
      )}
      {children}
    </>
  );
};
