import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';

import { useModelPortfoliosCategoriesQuery } from '~/graphql/hooks';
import { Grid } from '~/toolbox/grid';
import { Spinner } from '~/toolbox/spinner';

import { CategoryCard } from './CategoryCard';

export const ModelPortfolioCategories = () => {
  const { data, loading, error } = useModelPortfoliosCategoriesQuery();

  if (loading) {
    return <Spinner fullScreen />;
  }

  const subCategories = data?.viewer.screenSystemPies.subCategories;

  if (!subCategories || error) {
    return <GenericSystemError />;
  }

  return (
    <Grid>
      <Grid.Row>
        {subCategories.map((subCategory, index) => (
          <Grid.Col key={index} md={4} sm={6} xs={12}>
            <CategoryCard subCategory={subCategory} />
          </Grid.Col>
        ))}
      </Grid.Row>
    </Grid>
  );
};
