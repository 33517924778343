import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { SavingsTransactionsRoute } from './landing-page/index';
import { SavingsPage } from './SavingsPage';

export const SavingsRoute = () => {
  return (
    <Route path="savings" element={<SavingsPage />}>
      {SavingsTransactionsRoute()}
      <Route
        index
        element={<Navigate to="/d/save/savings/transactions" replace />}
      />
    </Route>
  );
};
