import { PS, styled, Card, Box } from '@m1/liquid-react';
import * as React from 'react';

import { EnvironmentDropdown } from './EnvironmentDropdown';
import { UserLists } from './UserLists';

const UserPickerCard = styled(Card)`
  padding: 8px 8px 0 8px;
  width: 440px;
`;
const UserPickerContainer = styled(Box)<{
  $show: boolean;
}>`
  position: absolute;
  transition: transform 300ms ease-in;
  transform: ${({ $show }) => ($show ? 'translateX(98%)' : 'translateX(0%)')};
  z-index: -1;

  ${UserPickerCard} {
    transition: opacity 300ms ease-in;
    opacity: ${({ $show }) => ($show ? 1 : 0)};
  }
`;

const StyledCardToggleTrigger = styled(Card)`
  background: ${({ theme }) => theme.colors.backgroundNeutralTertiary};
  border-radius: 8px;
  padding: 8px;
  position: absolute;
  right: -86px;
  top: 2px;
  z-index: 1;
`;

export const UserPicker = ({ users: queryUsers }: { users?: string }) => {
  const [showPicker, setShowPicker] = React.useState<boolean>(true);

  return (
    <UserPickerContainer $show={showPicker}>
      <StyledCardToggleTrigger
        isElevated
        onClick={() => setShowPicker(!showPicker)}
        cursor="pointer"
      >
        <PS
          content={showPicker ? 'Close picker' : 'Open picker'}
          color="primary"
          fontWeight={600}
        />
      </StyledCardToggleTrigger>
      <UserPickerCard isElevated backgroundColor="backgroundNeutralSecondary">
        <EnvironmentDropdown />
        <UserLists queryUsers={queryUsers} />
      </UserPickerCard>
    </UserPickerContainer>
  );
};
