import { Modal, ModalContent, PL } from '@m1/liquid-react';
import * as React from 'react';

export type IRAContributionHelpModalProps = {
  onCancel: () => void;
  open: boolean;
};

export const IRAContributionHelpModal = ({
  onCancel,
  open = false,
}: IRAContributionHelpModalProps) => {
  return (
    <Modal onClose={onCancel} open={open}>
      <ModalContent width="wide" withPadding={false}>
        <PL
          color="foregroundNeutralSecondary"
          content="If you made pre-tax and after-tax contributions to your existing account, start by opening a Traditional IRA and transfer over your pre-tax contributions. Then, you can open a Roth IRA and transfer your after-tax contributions to that account."
          p={24}
        />
      </ModalContent>
    </Modal>
  );
};
