import { LM, LS, PL, Flex, styled } from '@m1/liquid-react';
import * as React from 'react';
import { Transition } from 'react-spring/renderprops';

import { ExternalLink } from '~/components/ExternalLink';
import { EXTERNAL_LINKS } from '~/static-constants';
import { ControlImg } from '~/toolbox/carousel';

export type BubbleHelperProps = {
  activeStepNumber?: number;
  description?: string;
  externalLinkDestination?: keyof typeof EXTERNAL_LINKS;
  externalLinkLabel?: string;
  handleGoBackward?: (...args: Array<any>) => any;
  handleGoForward?: (...args: Array<any>) => any;
  isHelperOn?: boolean;
  maxWidth?: number;
  stepNumber: number;
  title?: string;
  totalSteps: number;
};

// Styled Components
const StyledContainer = styled.div<{
  maxWidth?: number;
}>`
  margin: auto;
  margin-top: 8px;
  max-width: ${(props) => props.maxWidth && `${props.maxWidth}px`};
  position: relative;
`;

const StyledArrow = styled.div`
  border-bottom: 10px solid
    ${(props) => props.theme.colors.backgroundPrimarySubtle};
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  height: 0;
  margin-left: 18px;
  width: 0;
`;

const StyledBubbleHelperContainer = styled(Flex)`
  background: ${(props) => props.theme.colors.backgroundPrimarySubtle};
  border-radius: 8px;
`;

const StyledStepContainer = styled(Flex)`
  background: ${(props) => props.theme.colors.borderSuccess};
  border-radius: 10px;
  color: ${(props) => props.theme.colors.success};
  height: 18px;
`;

export const BubbleHelper = ({
  activeStepNumber,
  description,
  externalLinkDestination,
  externalLinkLabel,
  isHelperOn,
  maxWidth = 518,
  stepNumber,
  title,
  totalSteps,
  handleGoForward,
  handleGoBackward,
}: BubbleHelperProps) => {
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    if (isHelperOn && activeStepNumber === stepNumber) {
      setShow(true);
    }
    return () => setShow(false);
  }, [activeStepNumber, isHelperOn, stepNumber, totalSteps]);

  const hideHelperButtons = totalSteps === 1;
  const showNext = !hideHelperButtons && stepNumber < totalSteps;
  const showPrevious = !hideHelperButtons && stepNumber > 1;
  return (
    <Transition
      items={show}
      from={{
        overflow: 'hidden',
        height: 0,
      }}
      enter={{
        height: 'auto',
      }}
      leave={{
        opacity: 0,
      }}
    >
      {/* @ts-expect-error - TS7006 - Parameter 'show' implicitly has an 'any' type. */}
      {(show) =>
        show &&
        // @ts-expect-error - TS7006 - Parameter 'props' implicitly has an 'any' type.
        ((props) => (
          <StyledContainer maxWidth={maxWidth} style={props}>
            <StyledArrow />
            <StyledBubbleHelperContainer alignItems="center" p={16}>
              <Flex flexDirection="column">
                <PL content={title} mb={4} fontWeight={600} />
                <LM content={description} mb={8} />
                {externalLinkDestination && (
                  <ExternalLink
                    destination={externalLinkDestination}
                    label={externalLinkLabel}
                  />
                )}
              </Flex>
              <Flex flexDirection="column" ml={40}>
                {showPrevious && (
                  <Flex mb={10} onClick={handleGoBackward}>
                    <ControlImg direction="vertical" />
                  </Flex>
                )}
                <StyledStepContainer
                  alignItems="center"
                  justifyContent="center"
                >
                  <LS content={`${stepNumber}/${totalSteps}`} mx={8} />
                </StyledStepContainer>
                {showNext && (
                  <Flex mt={10} onClick={handleGoForward}>
                    <ControlImg direction="vertical_reversed" />
                  </Flex>
                )}
              </Flex>
            </StyledBubbleHelperContainer>
          </StyledContainer>
        ))
      }
    </Transition>
  );
};
