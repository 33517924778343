import merge from 'lodash-es/merge';
import set from 'lodash-es/set';

import { InvestAccountOpeningQuestion } from '~/graphql/types';
import { ACTION_TYPES as ACTIONS, ROUTING_ACTIONS } from '~/redux/actions';
import { IDENTITY_FIRST_FLOW_STEPS as STEPS } from '~/static-constants';

import { FlowState } from '../newFlowsReducer.types';
import { createStepReducer } from '../utils';

export type InvestOnboardingFlowState = FlowState<
  string,
  {
    accountId: string | null | undefined;
    basePath: string;
    input: Record<string, any>;
    investSuitabilityQuestions: Record<string, InvestAccountOpeningQuestion>;
    phone: string | null | undefined;
    fullyPaidLendingStatus: boolean;
  }
>;

export const initialInvestOnboardingFlowState: InvestOnboardingFlowState = {
  accountId: null,
  basePath: '',
  input: {},
  investSuitabilityQuestions: {},
  phone: null,
  step: STEPS.LOAD_STATUS,
  stepTitle: '',
  fullyPaidLendingStatus: true,
};

const readStepTitle = (step: ValueOf<typeof STEPS>) => {
  switch (step) {
    case STEPS.COLLECT_INVESTMENT_EXPERIENCE:
    case STEPS.COLLECT_RISK_TOLERANCE:
    case STEPS.COLLECT_TIME_HORIZON:
    case STEPS.COLLECT_LIQUIDITY_NEEDS:
    case STEPS.COLLECT_TRUSTED_CONTACT:
    case STEPS.COLLECT_INTRODUCTION_SOURCE:
      return 'Investment account details';
    case STEPS.REVIEW:
      return 'Confirm';
    default:
      return '';
  }
};

const stepReducer = createStepReducer(initialInvestOnboardingFlowState);

export function investOnboardingReducer(
  state: InvestOnboardingFlowState = initialInvestOnboardingFlowState,
  action: any,
): InvestOnboardingFlowState {
  switch (action.type) {
    case ACTIONS.BEGIN_INVEST_ACCOUNT_SETUP: {
      const { basePath, phone, registration } = action.payload;
      const input = registration
        ? {
            registration,
          }
        : {};

      return {
        ...initialInvestOnboardingFlowState,
        basePath,
        phone,
        input,
      };
    }
    case 'SET_ACTIVE_CRYPTO_ACCOUNT':
    case 'SET_ACTIVE_INVEST_ACCOUNT':
      return {
        ...state,
        accountId: action.payload,
      };
    case ACTIONS.SET_INVEST_SUITABILITY_ONBOARDING_QUESTIONS:
      return {
        ...state,
        investSuitabilityQuestions: action.payload.reduce(
          (
            questions: Record<string, InvestAccountOpeningQuestion>,
            current: InvestAccountOpeningQuestion,
          ) => {
            if (current.questionType) {
              questions[current.questionType] = current;
            }
            return questions;
          },
          {},
        ),
      };
    case ROUTING_ACTIONS.LOCATION_CHANGE: {
      const step = stepReducer(state, action) as ValueOf<typeof STEPS>;
      return {
        ...state,
        step,
        stepTitle: readStepTitle(step),
      };
    }
    case ACTIONS.SET_FULLY_PAID_LENDING_STATUS:
      return {
        ...state,
        fullyPaidLendingStatus: action.payload,
      };
    default:
      return {
        ...state,
        input: inputReducer(state.input, action),
      };
  }
}

// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type. | TS7006 - Parameter 'action' implicitly has an 'any' type.
function inputReducer(state, action) {
  switch (action.type) {
    case ACTIONS.COLLECTED_INVEST_TRUSTED_CONTACT: {
      return merge({}, state, action.payload);
    }
    case ACTIONS.SKIPPED_INVEST_TRUSTED_CONTACT: {
      return merge({}, state, {
        trustedContact: null,
      });
    }
    case ACTIONS.FINISHED_INVEST_ACCOUNT_SETUP_REVIEW:
      return merge({}, state, action.payload);

    case ACTIONS.SUBMITTED_INVEST_PROFILE_INPUT: {
      const { field, value } = action.payload;
      return merge(
        {
          ...state,
        },
        set({}, field, value),
      );
    }
    case ACTIONS.UPDATE_PROFILE:
      return {
        ...state,
        suitability: {
          ...state.suitability,
          ...action.payload.profile.suitability,
        },
      };
    default:
      return state;
  }
}
