import { Button, Flex } from '@m1/liquid-react';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { CollectionFragment } from '~/graphql/types';

import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';
import { GridTable } from '~/toolbox/grid-table';

import { UnmanagedHoldingsTableHeaderRow } from './UnmanagedHoldingsTableHeaderRow';
import {
  UnmanagedHoldingsDesktopTableRow,
  UnmanagedHoldingsMobileTableRow,
} from './UnmanagedHoldingsTableRow';

type HoldingEdges = NonNullable<
  NonNullable<CollectionFragment['holdings']>['edges']
>;

type Holding = NonNullable<NonNullable<HoldingEdges[number]>['node']>;

export type UnmanagedHoldingsTableProps = {
  actionableHoldingIds: Array<string>;
  hideCheckboxes: boolean;
  holdingsCollection: CollectionFragment;
  isTradingInProgress: boolean;
  loadMore: () => void;
  onCheckboxSelect: (checkboxId: string) => void;
  onSelectAll: () => void;
  selectedCheckboxes: Array<string>;
};

export const UnmanagedHoldingsTable = ({
  actionableHoldingIds,
  hideCheckboxes,
  holdingsCollection,
  isTradingInProgress,
  onCheckboxSelect,
  onSelectAll,
  loadMore,
  selectedCheckboxes,
}: UnmanagedHoldingsTableProps) => {
  const isDesktop = useMediaQuery({
    query: RESPONSIVE_BREAKPOINTS.LARGE,
  });

  const gridTemplateColumns = React.useMemo(() => {
    return isDesktop ? 'auto 10% 10% 20% 8px' : 'auto 50% 8px';
  }, [isDesktop]);

  const readNavProps = React.useCallback(
    (holding: Holding) => {
      /**
       * For the pending liquidation case, we need to remove
       * the last col value from gridTemplateColumns
       * because navigable row will add the value for the caret itself.
       */
      if (holding.isPendingLiquidation) {
        const cols = gridTemplateColumns.split(' ');

        return {
          gridTemplateColumns: cols.slice(0, cols.length - 1).join(' '),
          params: {
            id: holding.id,
          },
          to: `/d/invest/unmanaged-holdings/pending-liquidation/:id`,
        };
      }

      return null;
    },
    [gridTemplateColumns],
  );

  const holdings = React.useMemo(() => {
    const finalNodes: Array<Holding> = [];

    holdingsCollection.holdings?.edges?.forEach((edge) => {
      if (edge?.node) {
        finalNodes.push(edge.node);
      }
    });

    return finalNodes;
  }, [holdingsCollection.holdings?.edges]);

  if (!holdingsCollection.holdings) {
    return null;
  }

  const { pageInfo } = holdingsCollection.holdings;

  const RowComponent = isDesktop
    ? UnmanagedHoldingsDesktopTableRow
    : UnmanagedHoldingsMobileTableRow;

  return (
    <GridTable
      emptyMessage="Holdings will appear here"
      gridTemplateColumns={gridTemplateColumns}
    >
      <UnmanagedHoldingsTableHeaderRow
        actionableHoldingIds={actionableHoldingIds}
        hideCheckboxes={hideCheckboxes}
        isTradingInProgress={isTradingInProgress}
        isDesktop={isDesktop}
        onSelectAll={onSelectAll}
        selectedCheckboxes={selectedCheckboxes}
      />
      {holdings.map((holding, index) => (
        <RowComponent
          hideCheckboxes={hideCheckboxes}
          holding={holding}
          isTradingInProgress={isTradingInProgress}
          key={index}
          navProps={readNavProps(holding)}
          onCheckboxSelect={onCheckboxSelect}
          selectedCheckboxes={selectedCheckboxes}
        />
      ))}
      {pageInfo && pageInfo.hasNextPage && (
        <Flex
          alignItems="center"
          borderColor="borderMain"
          borderRadius="0 0 4px 4px"
          borderStyle="solid"
          borderWidth="0 1px 1px 1px"
          height={64}
          justifyContent="center"
        >
          <Button kind="secondary" label="View More" onClick={loadMore} />
        </Flex>
      )}
    </GridTable>
  );
};
