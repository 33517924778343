import { HXS, PXL, Flex } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { PieOrganizerNavigationButton } from '~/components/PieOrganizerNavigationButton/PieOrganizerNavigationButton';
import { useNewPieEditor } from '~/hooks/useNewPieEditor';
import { PieEditorEntryButton } from '~/pages/dashboard/wizards/pie-editor/PieEditorEntryButton';

export const MyPiesEmptyState = ({
  marketingContent,
}: {
  marketingContent?: React.ReactNode;
  minimal?: boolean;
}) => {
  const isPhone = useMediaQuery({
    query: '(max-width: 767px)',
  });
  const { showNewPieEditor } = useNewPieEditor();

  return (
    <Flex flexDirection="column" maxWidth={827} mx="auto" p={8}>
      {marketingContent}
      <Flex p={64} alignSelf="center">
        <Illustration name="buildCustomPies" style={{ maxWidth: 280 }} />
      </Flex>
      <HXS mb={16}>Build custom Pies that match your priorities.</HXS>
      <PXL fontWeight={600} mb={32}>
        You can put these Pies to work immediately, or watch their performance
        before funding.
      </PXL>
      <Flex
        justifyContent="space-evenly"
        flexDirection={isPhone ? 'column' : 'row'}
      >
        <Flex mt={32} justifyContent="center">
          {showNewPieEditor ? (
            <PieEditorEntryButton
              label="Build a stock-and-fund Pie"
              fullWidth
            />
          ) : (
            <PieOrganizerNavigationButton
              event={{
                initConfig: {
                  confirmationDialog: {
                    showApplicableLocations: false,
                  },
                  mode: 'NEW_PIE',
                  sliceableIds: [],
                  returnTo: '/d/research/my-pies',
                },
                type: 'INITIALIZE_ORGANIZER',
              }}
              fullWidth
              kind="primary"
              label="Build a stock-and-fund Pie"
            />
          )}
        </Flex>
        <Flex mt={32} justifyContent="center" width={250}>
          {showNewPieEditor ? (
            <PieEditorEntryButton
              label="Build a crypto Pie"
              kind="secondary"
              leftIcon="crypto24"
              fullWidth
              isCrypto
            />
          ) : (
            <PieOrganizerNavigationButton
              event={{
                initConfig: {
                  confirmationDialog: {
                    showApplicableLocations: false,
                  },
                  mode: 'NEW_PIE',
                  sliceableIds: [],
                  returnTo: '/d/research/my-pies',
                  isCrypto: true,
                },
                type: 'INITIALIZE_ORGANIZER',
              }}
              fullWidth
              kind="primary"
              label="Build a crypto Pie"
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
