import { styled } from '@m1/liquid-react';

import { Link } from '~/toolbox/link/Link';

export const ActivityLink = styled(Link)`
  color: ${({ theme }) => theme.colors.foregroundNeutralMain};
  display: block;
  width: inherit;

  &:hover {
    color: ${({ theme }) => theme.colors.foregroundNeutralMain};
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.backgroundNeutralMain};
  }
`;
