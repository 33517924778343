import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalTitle,
  PL,
} from '@m1/liquid-react';
import React from 'react';

import {
  TwoFactorAuthDocument,
  useDisableTwoFactorAuthMutation,
} from '~/graphql/hooks';
import { useToast } from '~/toasts';

type DisableTwoFactorAuthProps = {
  exitFlow: () => void;
};

export const DisableTwoFactorAuth = ({
  exitFlow,
}: DisableTwoFactorAuthProps) => {
  const [open, setOpen] = React.useState(true);

  const { addToast } = useToast();

  const [disableTwoFactorAuth, { loading }] = useDisableTwoFactorAuthMutation();

  const handleDisableClick = async () => {
    await disableTwoFactorAuth({
      variables: {
        input: {},
      },
      onCompleted: () => {
        addToast({
          content: 'Two-factor authentication is now off.',
          kind: 'success',
          duration: 'short',
        });

        exitFlow();
      },
      onError: () => {
        addToast({
          content: 'Something went wrong. Please try again or contact support.',
          kind: 'alert',
          duration: 'short',
        });
      },
      refetchQueries: [{ query: TwoFactorAuthDocument }],
    });
  };

  const handleClose = () => {
    setOpen(false);
    exitFlow();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContent>
        <ModalTitle>Turn off two-factor authentication</ModalTitle>
        <PL content="Are you sure you want to disable two-factor authentication?" />
        <Flex flexDirection="column">
          <Button
            kind="primary"
            mt={32}
            size="large"
            label="Yes, turn off"
            disabled={loading}
            onClick={handleDisableClick}
          />
          <Button
            kind="link"
            mt={32}
            size="large"
            label="Cancel"
            disabled={loading}
            onClick={handleClose}
          />
        </Flex>
      </ModalContent>
    </Modal>
  );
};
