import { HS, styled } from '@m1/liquid-react';
import * as React from 'react';

import { Gain } from '~/components/gain';
import { PercentGain } from '~/components/percent-gain';
import { useSelector } from '~/redux/hooks';
import { isNil } from '~/utils';

export type SecurityCurrentQuoteDailyChangeProps = {
  latestQuote: {
    changeFromPreviousClosePrice: number | null;
    percentChangeFromPreviousClosePrice: number | null;
  } | null;
  size?: 'default' | 'small';
};

export const SecurityCurrentQuoteDailyChange = ({
  latestQuote,
}: SecurityCurrentQuoteDailyChangeProps) => {
  const { percentChange, valueChange } = useSelector(
    (state) => state.securityDetails.snapshot,
  );

  const changeFromPreviousClosePrice =
    valueChange ?? latestQuote?.changeFromPreviousClosePrice;
  const percentChangeFromPreviousClosePrice =
    percentChange ?? latestQuote?.percentChangeFromPreviousClosePrice;

  return (
    <StyledQuoteText price={changeFromPreviousClosePrice} fontWeight={500}>
      <Gain value={changeFromPreviousClosePrice} />
      <PercentGain value={percentChangeFromPreviousClosePrice} />
    </StyledQuoteText>
  );
};

const StyledQuoteText = styled(HS)<{ price: number | null | undefined }>`
  color: ${({ theme, price }) => {
    // TODO(Wolf): Find out if this is how we should handle a null or undefined price.
    if (isNil(price)) {
      return undefined;
    }
    return price > 0 ? theme.colors.success : theme.colors.critical;
  }};
  display: flex;

  > :first-child {
    margin-right: 8px;
  }
`;
