import { Box, Flex, styled } from '@m1/liquid-react';
import * as React from 'react';

import { Outlet } from 'react-router-dom';

import { useParams } from '~/hooks/useParams';
import { enteredPage, exitedPage } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

const StyledBox = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: ${({ theme: { colors } }) => colors.backgroundNeutralTertiary};
`;

const StyledContainer = styled(Flex)`
  width: 60rem;
  height: 38rem;
  padding: 4.5rem;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: ${({ theme: { colors } }) => colors.backgroundNeutralMain};
  text-align: center;
`;

export const VerifyEmailPage = () => {
  const { verificationToken } = useParams();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(enteredPage('VERIFY_EMAIL', verificationToken));
    return () => dispatch(exitedPage('VERIFY_EMAIL', verificationToken));
  }, [verificationToken, dispatch]);

  return (
    <Flex height="100vh" alignItems="center" justifyContent="center">
      <StyledBox />
      <StyledContainer>
        <Outlet />
      </StyledContainer>
    </Flex>
  );
};
