import { ApolloError } from '@apollo/client';

import { useWizardContext } from '~/flows/wizard';
import { useSetScheduledTransferRuleMutation } from '~/graphql/hooks';
import {
  SetScheduledTransferRuleInput,
  SetScheduledTransferRuleMutation,
} from '~/graphql/types';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { ACTIONS } from '~/redux/actions/rootAction';
import { useDispatch } from '~/redux/hooks';
import { useToast } from '~/toasts';

import { InitialFundingFlowSteps } from '../InitialFundingFlow';

import { useCreateOneTimeTransfer } from './useCreateOneTimeTransfer';

type CreateScheduledTransferCallbackProps =
  | {
      input: SetScheduledTransferRuleInput & { idempotencyKey: string };
      /**
       * When this is true, it will create an initial transfer of the same amount as well as the schedule rule.
       * This is copied logic from initialFundingSaga
       */
      isInitialFunding?: true;
      onCompleted?: (data: SetScheduledTransferRuleMutation) => void;
      onError?: (err: ApolloError) => void;
      showSuccessToast?: boolean;
    }
  | {
      input: SetScheduledTransferRuleInput;
      isInitialFunding?: false;
      onCompleted?: (data: SetScheduledTransferRuleMutation) => void;
      onError?: (err: ApolloError) => void;
      showSuccessToast?: boolean;
    };

export const useCreateScheduledTransfer = ({
  confirmationStep,
}: {
  confirmationStep?: InitialFundingFlowSteps;
} = {}) => {
  const { goTo } = useWizardContext();
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const createOneTime = useCreateOneTimeTransfer();

  const [setScheduledTransferRule, { loading }] =
    useSetScheduledTransferRuleMutation();

  usePortaledSpinner(loading);

  const onCompleted = (
    data: SetScheduledTransferRuleMutation,
    {
      showSuccessToast,
      onCompleted: onCompletedProp,
    }: CreateScheduledTransferCallbackProps,
  ) => {
    if (showSuccessToast) {
      addToast({
        content: 'Your deposit was successful.',
        kind: 'success',
      });
    }

    // Go to the confirmation step of the flow.
    confirmationStep && goTo(confirmationStep);

    onCompletedProp?.(data);
  };

  const onError = (
    err: ApolloError,
    {
      onError: onErrorProp,
      isInitialFunding,
    }: CreateScheduledTransferCallbackProps,
  ) => {
    if (isInitialFunding) {
      dispatch({
        type: ACTIONS.INITIAL_DEPOSIT_MUTATION_FAILED,
        payload: err.message,
      });
    }
    addToast({
      content:
        'We were unable to complete your transfer. Please contact Client Support',
      kind: 'alert',
    });
    onErrorProp?.(err);
  };

  return (args: CreateScheduledTransferCallbackProps) => {
    if (args.isInitialFunding) {
      const { schedule, scheduledTransferRuleId, ...newInput } = args.input;

      createOneTime({
        input: newInput,
        showSuccessToast: false,
      });
    }
    let scheduledTransferInput;
    if ('idempotencyKey' in args.input) {
      // If idempotencyKey exists in args.input, destructure it
      const { idempotencyKey: key, ...input } = args.input;
      scheduledTransferInput = input;
    } else {
      // If idempotencyKey does not exist in args.input, use args.input as is
      scheduledTransferInput = args.input;
    }
    setScheduledTransferRule({
      variables: {
        input: scheduledTransferInput,
      },
      onCompleted: (data) => onCompleted(data, args),
      onError: (err) => onError(err, args),
    });
  };
};
