import { css, styled } from '@m1/liquid-react';
import React from 'react';

const Frame = styled.figure<{
  $ratio: string;
  $contain: string;
}>(
  ({ $ratio, $contain }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: ${$ratio};

    img,
    video {
      flex: 1 1 auto;
      max-height: 100%;
      object-fit: ${$contain};
    }

    svg {
      flex: 1 1 auto;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  `,
);

const ratioValues = {
  '16:9': '16 / 9',
  '4:3': '4 / 3',
  '1:1': '1 / 1',
  '21:9': '21 / 9',
};

const containValues = {
  fit: 'contain',
  fill: 'cover',
};

export interface AspectRatioFrameProps {
  ratio?: keyof typeof ratioValues;
  contain?: keyof typeof containValues;
  children: React.ReactNode;
}

export const AspectRatioFrame: React.FC<AspectRatioFrameProps> = ({
  ratio = `4:3`,
  contain = `fit`,
  ...props
}) => (
  <Frame
    {...props}
    $ratio={ratioValues[ratio]}
    $contain={containValues[contain]}
  />
);
