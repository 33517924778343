import { Button, HM, PXL, HXXS } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useLoanRecapStepQuery } from '~/graphql/hooks';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { AspectRatioFrame } from '../../components/AspectRatioFrame';
import { Cell, Divider, Grid, Stack } from '../../components/Layout';

import { LoanDetailsList } from './elements';

type LoanRecapProps = {
  onFinishStep: (arg0: {
    loanId: string;
    transferParticipantId: string;
  }) => void;
};

export const LoanRecap = ({ onFinishStep }: LoanRecapProps) => {
  const { transferParticipantId, loanId, personalLoanDepositAccount } =
    useSelector((state) => state.newFlows.PERSONAL_LOANS_APPLICATION);

  const handleFinish = () =>
    onFinishStep({
      loanId,
      transferParticipantId,
    });

  const { data, loading } = useLoanRecapStepQuery({
    variables: {
      loanId,
    },
  });
  if (loading) {
    return <Spinner fullScreen centered />;
  }

  const reviewScreen =
    data?.viewer.borrow?.personalLoans?.disbursementFlow?.reviewLoanScreen;
  const docLink = reviewScreen?.loanAgreementDocLink;

  if (!reviewScreen?.details || !docLink) {
    return <GenericSystemError />;
  }

  const loanDetails = reviewScreen.details;
  const selectedAccount = personalLoanDepositAccount
    ? `${personalLoanDepositAccount} Account`
    : 'account';
  return (
    <Grid>
      <Stack column="left-start / span 6" css="gap: 48px;">
        <Cell vertical css="gap: 16px;">
          <HM>You’re all set!</HM>
          <PXL>
            {`Your money should arrive in your `}
            <strong>{selectedAccount}</strong>
            {` in a few days. If you need, you can review your
                    loan agreement in the disclosures section of the app.`}
          </PXL>
          {reviewScreen.customizedMessage?.map((para) => (
            <PXL key={para}>{para}</PXL>
          ))}
        </Cell>
        <Cell vertical css="gap: 16px;">
          <HXXS>Your loan summary</HXXS>
          <LoanDetailsList>
            {loanDetails.map((loanDetail) =>
              loanDetail ? (
                <li key={loanDetail.label}>
                  <span>{loanDetail.label}</span>
                  <span>{loanDetail.value}</span>
                </li>
              ) : null,
            )}
          </LoanDetailsList>

          <LinkableLink
            linkable={docLink}
            textDecoration="underline"
            fontWeight={400}
          />
        </Cell>
      </Stack>
      <AspectRatioFrame
        ratio="4:3"
        css="grid-column: span 5 / right-end; margin-block: auto;"
      >
        <Illustration name="allSet" />
      </AspectRatioFrame>
      <Stack css="gap: 24px;" column="content">
        <Divider />
        <Cell>
          <Button
            label="Go to loan dashboard"
            size="large"
            onClick={handleFinish}
          />
        </Cell>
      </Stack>
    </Grid>
  );
};
