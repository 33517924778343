import { Box, Button, styled } from '@m1/liquid-react';

import { Grid } from '~/toolbox/grid';
import { Table } from '~/toolbox/table';

export const StyledRetirementFilter = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--colors-foreground-neutral-main);
`;

export const StyledRetirementFilterMessage = styled(Box)`
  margin: 8px 1rem 0 1rem;
`;

export const StyledFilterContainer = styled(Box)`
  padding: 0.5rem 0 1rem 0;
  margin-bottom: 0.5rem;
  background: var(--colors-background-neutral-secondary);
  box-shadow: 0 1px 4px var(--colors-background-shadow);
`;

export const StyledModelPortfolioHeaderContainer = styled(Box)<{
  imageSrc: string | null | undefined;
}>`
  position: relative;
  display: flex;
  height: 19rem;
  align-items: flex-end;
  padding: 1rem 0;
  color: var(--colors-foreground-neutral-on-dark);
  background: var(--colors-foreground-neutral-on-light);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: ${({ imageSrc }) => (imageSrc ? `url(${imageSrc})` : null)};

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    background: var(--colors-foreground-neutral-on-light);
    opacity: 0.5;
  }

  p {
    font-weight: 400;
  }
`;

export const StyledModelPortfolioHeaderGrid = styled(Grid as any)`
  position: relative;
  width: 100%;
`;

export const StyledCategoryName = styled(Box)`
  font-size: 32px;
`;

export const StyledBackButtonLink = styled(Button)`
  color: var(--colors-primary-tint);

  &:hover {
    color: var(--colors-primary-tint);
  }
`;

export const StyledModelPortfolioTable = styled(Table as any)`
  th {
    padding-bottom: 0;

    &:nth-child(5)::after {
      display: none;
    }
  }
`;

export const StyledPerformanceCellContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 4.8rem;
`;

export const StyledPerformanceTabs = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding-right: 4.7rem;
`;
