import { Flex, PL, PM, styled, useTheme } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import moment from 'moment';
import * as React from 'react';

import { NavigableCard } from '~/components/cards';
import { TransferRuleDetail_ScheduledTransferRule_Fragment } from '~/graphql/types';
import { Pill } from '~/toolbox/Pill';
import { formatNumber } from '~/utils';

const LatestInstanceCard = styled(NavigableCard)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundNeutralTertiary};
  }
`;

export const LatestInstance = ({
  instance,
}: {
  instance: NonNullable<
    TransferRuleDetail_ScheduledTransferRule_Fragment['latestInstance']
  >;
}) => {
  const theme = useTheme();

  return (
    <>
      <PM
        fontWeight={600}
        color="foregroundNeutralMain"
        content="Last instance"
        mt={16}
      />
      <LatestInstanceCard
        params={{
          transferInstanceId: instance.id,
        }}
        to="/d/c/transfer-details/:transferInstanceId"
        p={16}
        mt={8}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{
          color: theme.colors.foregroundNeutralMain,
        }}
      >
        {instance.startDate ? (
          <PL
            content={moment(instance.startDate).format('ll')}
            fontWeight={600}
          />
        ) : (
          <Pill kind="caution" label="Pending" />
        )}
        <Flex alignItems="center">
          <PL
            content={
              typeof instance.amount === 'number'
                ? formatNumber(instance.amount)
                : '$--'
            }
            style={{
              marginRight: 12,
            }}
          />
          <Icon name="caretRight16" color="foregroundNeutralSecondary" />
        </Flex>
      </LatestInstanceCard>
    </>
  );
};
