import { Box, styled } from '@m1/liquid-react';
import * as React from 'react';

type AudioGraphComponentProps = {
  dateFieldName: string;
  valueFieldName: string;
  valueHistory: ReadonlyArray<any>;
};

const invisibleCss = `
    clip: 'rect(1px, 1px, 1px, 1px)',
    clipPath: 'inset(50%)',
    height: '1px',
    width: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: '0',
    position: 'absolute'
`;

const InvisibleStyledBox = styled(Box)`
  ${invisibleCss}
`;

const getMonthName = (date: Date) =>
  date.toLocaleString('default', {
    month: 'long',
  });

const scaleNumRange = (
  num: number,
  oldMin: number,
  oldMax: number,
  newMin: number,
  newMax: number,
) => {
  return ((num - oldMin) * (newMax - newMin)) / (oldMax - oldMin) + newMin;
};

export const AudioGraph = (props: AudioGraphComponentProps) => {
  const dateFieldName = props.dateFieldName;
  const valueFieldName = props.valueFieldName;

  const prices = props.valueHistory.map((snapshot) =>
    snapshot[valueFieldName] ? snapshot[valueFieldName] : 0,
  );
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);

  const dates = props.valueHistory.map((el) =>
    new Date(el[dateFieldName]).getTime(),
  );
  const minDate = new Date(Math.min(...dates));
  const maxDate = new Date(Math.max(...dates));

  const playAudioChart = () => {
    // @ts-expect-error - TS2339 - Property 'webkitAudioContext' does not exist on type 'Window & typeof globalThis'.
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioContext = new AudioContext();

    const volume = 0.05;
    const audioGraphPlayTime = 10;

    const primaryGainControl = audioContext.createGain();
    primaryGainControl.gain.setValueAtTime(volume, 0);
    primaryGainControl.connect(audioContext.destination);

    const noteOscillator = audioContext.createOscillator();
    noteOscillator.type = 'sine';
    noteOscillator.connect(primaryGainControl);

    let notePlayTime = 0;
    const notePlayTimeIncrement = audioGraphPlayTime / prices.length;

    const LOW_NOTE = 200;
    const HI_NOTE = 1100;

    prices.forEach((price) => {
      const note = scaleNumRange(price, minPrice, maxPrice, LOW_NOTE, HI_NOTE);
      noteOscillator.frequency.setValueAtTime(
        note,
        audioContext.currentTime + notePlayTime,
      );
      notePlayTime += notePlayTimeIncrement;
    });

    noteOscillator.start();
    noteOscillator.stop(audioContext.currentTime + audioGraphPlayTime);
  };

  return (
    <InvisibleStyledBox role="region">
      <InvisibleStyledBox
        aria-label={
          'Value over time chart.' +
          ' Earliest date: ' +
          getMonthName(minDate) +
          ' ' +
          minDate.getDate() +
          ', ' +
          minDate.getFullYear() +
          '. Latest date: ' +
          getMonthName(maxDate) +
          ' ' +
          maxDate.getDate() +
          ', ' +
          maxDate.getFullYear()
        }
      />
      <button aria-label="Listen to audio chart" onClick={playAudioChart} />
    </InvisibleStyledBox>
  );
};
