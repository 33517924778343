import { Box, Button, css, styled, Card } from '@m1/liquid-react';

export const CondensableButton = styled(Button)<{
  condensed?: boolean;
}>`
  ${({ condensed }) =>
    condensed &&
    css`
      padding-left: 8px;
      padding-right: 8px;

      > span {
        margin-right: 0;
      }
    `}
`;

export const ButtonInGroup = styled(CondensableButton)<{
  position?: 'left' | 'right' | 'center';
}>`
  ${({ position }) => {
    if (position && position !== 'center') {
      const opposite = position === 'left' ? 'right' : 'left';
      return css`
        border-top-${opposite}-radius: 0;
        border-bottom-${opposite}-radius: 0;
        border-${opposite}-width: 1px;

        &:hover:enabled, &:focus:enabled, &:disabled {
          border-${opposite}-width: 1px;
        }
      `;
    }
    return css`
      border-radius: 0;
      border-left-width: 1px;
      border-right-width: 1px;

      &:hover:enabled,
      &:focus:enabled,
      &:disabled {
        border-left-width: 1px;
        border-right-width: 1px;
      }
    `;
  }}
`;

export const AiCard = styled(Card)`
  box-shadow: 0 0 8px 8px
    ${({ theme }) => theme.colors.backgroundNeutralSecondary};

  --borderWidth: 16px;
  margin: var(--borderWidth);
  padding: 12px;
  background: ${({ theme }) => theme.colors.backgroundNeutralSecondary};
  position: relative;
  border-radius: calc(var(--borderWidth) / 2);
  border-color: transparent;

  &:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);

    @property --x {
      syntax: '<percentage>';
      inherits: false;
      initial-value: 0%;
    }

    background: radial-gradient(
      ellipse farthest-corner at var(--x) 0%,
      #f79533,
      #f37055,
      #ef4e7b,
      #a166ab,
      #5073b8,
      #1098ad,
      #07b39b,
      #6fba82
    );
    border-radius: calc(var(--borderWidth));
    z-index: -1;
    animation: animatedRadial 3s ease alternate infinite;
    background-size: 300% 300%;
  }

  button,
  button:hover:enabled {
    color: transparent !important;
    background: linear-gradient(
      60deg,
      #f79533,
      #f37055,
      #ef4e7b,
      #a166ab,
      #5073b8,
      #1098ad,
      #07b39b,
      #6fba82
    );
    background-size: 300% 300%;
    animation: animatedGradient 6s ease alternate infinite;
  }

  @keyframes animatedRadial {
    0% {
      --x: 0%;
    }
    50% {
      --x: 50%;
    }
    100% {
      --x: 100%;
    }
  }

  @keyframes animatedGradient {
    0% {
      background-position: 0%;
    }
    50% {
      background-position: 100%;
    }
    100% {
      background-position: 0%;
    }
  }
`;

export const TooltipContainer = styled(Box)`
  > * {
    width: 100%;
  }
`;
