import { Box, styled } from '@m1/liquid-react';

import { Grid } from '~/toolbox/grid';
export const StyledTrigger = styled(Box)`
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  font-weight: 700;
  color: var(--colors-primary);
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
`;

export const StyledActionMenu = styled(Box)`
  min-width: 10rem;
  padding: 1rem 0;
  text-align: left;

  > button {
    width: 100%;
    color: var(--colors-foreground-neutral-main);
    padding: 5px 1.5rem;
    cursor: pointer;

    &:hover {
      background: var(--colors-foreground-neutral-tertiary);
    }
  }
`;

export const StyledPieItemSelectBox = styled(Box)`
  margin-right: 1rem;
`;

export const StyledPieItem = styled(Grid as any)`
  width: 100%;
  padding: 1rem;
  margin: 0 0 0.7rem 0;
  border: 1px solid var(--colors-border-main);
`;
export const StyledPieItemColumn = styled(Grid.Col as any)`
  display: flex;
  width: 100%;
  align-items: center;
`;
export const StyledPieItemLogo = styled(Box)`
  width: 4rem;
  flex: 0 0 auto;
  margin-right: 1rem;
`;
export const StyledPieItemNameAndDescription = styled(Box)`
  max-width: 80%;
`;
export const StyledPieItemDescription = styled(Box)`
  line-height: 1.45em;
  overflow-wrap: break-word;
`;
