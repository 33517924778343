import { Flex } from '@m1/liquid-react';
import debounce from 'lodash-es/debounce';

import * as React from 'react';

// for reference
const MAX_WIDTH = 1600;
const SIDE_EXPANDED = 260;
const SIDE_COLLAPSED = 75;
const COLLAPSE_BREAKPOINT = 1252;
const TOP_BAR_HEIGHT = 60;
export const CONTENT_PADDING = 80;
const TOTAL_CONTENT_WIDTH = MAX_WIDTH + 2 * CONTENT_PADDING;

// open: side nav panel is expanded and pushes content
// overlay: side nav panel is expanded and overlays content
// collapsed: side nav panel shows icons only
type PanelStatus = 'open' | 'overlay' | 'collapsed';

interface LayoutState {
  panelStatus: PanelStatus;
  isOpen: boolean;
  sideNavWidth: number;
  contentWidth: number;
  contentPadding: number;
  topBarHeight: number;
  openPanel: () => void;
  closePanel: () => void;
  scrollTop: number;
  setScrollTop: (top: number) => void;
}

const defaultLayoutState: LayoutState = {
  panelStatus: 'open',
  isOpen: true,
  sideNavWidth: SIDE_EXPANDED,
  contentWidth: TOTAL_CONTENT_WIDTH,
  contentPadding: CONTENT_PADDING,
  topBarHeight: TOP_BAR_HEIGHT,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openPanel: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closePanel: () => {},
  scrollTop: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setScrollTop: () => {},
};

const LayoutContext = React.createContext(defaultLayoutState);

export const useLayout = () => React.useContext(LayoutContext);

interface LayoutProviderProps {
  children: React.ReactNode;
}

export const LayoutProvider = ({ children }: LayoutProviderProps) => {
  const [width, setWidth] = React.useState<number | null | undefined>(null);
  const [panelStatus, setPanelStatus] = React.useState<PanelStatus>('open');
  // once a user opens/closes the sidenav manually, we no longer
  // automatically open/close - to avoid invalidating the action they took
  const [userOpened, setUserOpened] = React.useState<boolean>(false);
  const [scrollTop, setScrollTop] = React.useState(0);

  // create ref to track container's width changes
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  // set width changes to state
  const handleResize = React.useCallback((): void => {
    if (containerRef.current) {
      const { width } = containerRef.current.getBoundingClientRect();
      if (width) {
        if (!userOpened) {
          if (width < COLLAPSE_BREAKPOINT) {
            setPanelStatus('collapsed');
          }
        }
        setWidth(width);
      }
    }
  }, [containerRef, setWidth, userOpened]);

  const debounceResize = debounce(handleResize, 500, { maxWait: 1500 });

  // add event listener and cleanup when done
  React.useEffect(() => {
    debounceResize();
    window.addEventListener('resize', debounceResize);
    return () => window.removeEventListener('resize', debounceResize);
  }, [debounceResize, containerRef]);

  // if the browser size is small enough, the sidenav should pop out as an overlay
  const overlayMode =
    width !== null && width !== undefined && width < COLLAPSE_BREAKPOINT;

  const openPanel = () => {
    setUserOpened(true);
    if (overlayMode) {
      setPanelStatus('overlay');
    } else {
      setPanelStatus('open');
    }
  };

  const closePanel = () => {
    setUserOpened(false);
    setPanelStatus('collapsed');
  };

  const contentWidth = TOTAL_CONTENT_WIDTH;

  const sideNavWidth =
    panelStatus === 'collapsed' ? SIDE_COLLAPSED : SIDE_EXPANDED;

  const isOpen = panelStatus === 'open' || panelStatus === 'overlay';

  return (
    <LayoutContext.Provider
      value={{
        panelStatus,
        isOpen,
        openPanel,
        closePanel,
        contentWidth,
        contentPadding: defaultLayoutState.contentPadding,
        sideNavWidth,
        topBarHeight: TOP_BAR_HEIGHT,
        scrollTop,
        setScrollTop,
      }}
    >
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        minHeight="100vh"
        ref={containerRef}
      >
        {children}
      </Flex>
    </LayoutContext.Provider>
  );
};
