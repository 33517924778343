import { Pie } from '~/pie-trees';
import { OPEN_INVEST_ACCOUNT_STEPS as STEPS } from '~/static-constants';

export const OPEN_INVEST_ACCOUNT_ACTIONS = Object.freeze({
  CHANGE_OPEN_INVEST_ACCOUNT_STEP: 'CHANGE_OPEN_INVEST_ACCOUNT_STEP',
  SET_RETIREMENT_ACCOUNT_TYPE: 'SET_RETIREMENT_ACCOUNT_TYPE',
  CREATE_INVEST_QUICK_CLICK_PIE_AND_NAVIGATE:
    'CREATE_INVEST_QUICK_CLICK_PIE_AND_NAVIGATE',
});

export type RetirementAccountTypes =
  | 'TRADITIONAL_IRA'
  | 'ROTH_IRA'
  | 'SEP_IRA'
  | 'ROLLOVER';

export type SetRetirementAccountType = {
  type: typeof OPEN_INVEST_ACCOUNT_ACTIONS.SET_RETIREMENT_ACCOUNT_TYPE;
  payload: RetirementAccountTypes;
};

export type ChangeOpenInvestStepAction = {
  type: typeof OPEN_INVEST_ACCOUNT_ACTIONS.CHANGE_OPEN_INVEST_ACCOUNT_STEP;
  payload: ValueOf<typeof STEPS>;
};

export type CreateInvestQuickClickPieAndNavigateAction = {
  payload: {
    pie: Pie;
  };
  type: typeof OPEN_INVEST_ACCOUNT_ACTIONS.CREATE_INVEST_QUICK_CLICK_PIE_AND_NAVIGATE;
};

export const setRetirementAccountType = (
  payload: SetRetirementAccountType['payload'],
): SetRetirementAccountType => ({
  type: OPEN_INVEST_ACCOUNT_ACTIONS.SET_RETIREMENT_ACCOUNT_TYPE,
  payload,
});

export const createInvestQuickClickPieAndNavigate = (
  payload: CreateInvestQuickClickPieAndNavigateAction['payload'],
): CreateInvestQuickClickPieAndNavigateAction => ({
  type: OPEN_INVEST_ACCOUNT_ACTIONS.CREATE_INVEST_QUICK_CLICK_PIE_AND_NAVIGATE,
  payload,
});

export type OpenInvestAccountAction =
  | ChangeOpenInvestStepAction
  | CreateInvestQuickClickPieAndNavigateAction
  | SetRetirementAccountType;
