import take from 'lodash-es/take';
import * as React from 'react';

import { SecurityDetail_Fund_Fragment } from '~/graphql/types';
import { useSelector } from '~/redux/hooks';
import { APP_MODES } from '~/static-constants';
import { Pill } from '~/toolbox/Pill';

import { BreadCrumb } from './BreadCrumb';

type FundBreadCrumbsProps = {
  fund: SecurityDetail_Fund_Fragment;
  navigable: boolean;
};

export const FundBreadCrumbs = ({ fund, navigable }: FundBreadCrumbsProps) => {
  const mode = useSelector((state) => state.mode);
  const toLink =
    mode === APP_MODES.ADD ? '/d/c/add-slices/funds' : '/d/research/funds';

  return (
    <Pill.Group>
      <BreadCrumb
        label="Funds"
        {...(navigable && { linkProps: { to: toLink } })}
      />
      {fund.fundCategory?.map((category, index) => {
        const linkProps = navigable
          ? {
              to: toLink,
              query: {
                filterCategory: take(fund.fundCategory, index + 1),
              },
            }
          : undefined;

        return (
          <BreadCrumb label={category} linkProps={linkProps} key={category} />
        );
      })}
    </Pill.Group>
  );
};
