import {
  Box,
  Button,
  Flex,
  HXXS,
  LS,
  styled,
  useTheme,
} from '@m1/liquid-react';
import * as React from 'react';

import { SecurityMiniChartIntraday } from '~/components/security/security-mini-chart-intraday';
import { SecurityNews } from '~/components/security/SecurityNews';
import { SecurityCurrentQuoteDailyChange } from '~/components/security/SecurityQuote/SecurityCurrentQuoteDailyChange';
import { SecurityCurrentQuoteLastTradeTime } from '~/components/security/SecurityQuote/SecurityCurrentQuoteLastTradeTime';
import { SecurityCurrentQuotePrice } from '~/components/security/SecurityQuote/SecurityCurrentQuotePrice';
import {
  IntradayQuoteIntervalEnum,
  IntradayQuotePeriodEnum,
  PortfolioToSecurityFragment,
  SecurityDetailContainerFragment,
} from '~/graphql/types';
import { clickedOnSliceable } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

import { SecurityMetrics } from './SecurityMetrics';

export type PortfolioToSecurityPartialProps = {
  portfolioSlice: PortfolioToSecurityFragment;
};

const StyledMarketsContainer = styled(Box)`
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.borderMain};
`;

const StyledMarketsQuoteContainer = styled(Flex)`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderMain};
`;

const MarketItemContainer = styled(Flex)`
  flex-flow: column;
  padding-bottom: 8px;
  min-width: 200px;
`;

export const PortfolioToSecurityContainer = ({
  portfolioSlice,
}: PortfolioToSecurityPartialProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleClickViewDetails = React.useCallback(() => {
    dispatch(clickedOnSliceable(portfolioSlice.to.id));
  }, [dispatch, portfolioSlice]);
  const security = portfolioSlice.to as SecurityDetailContainerFragment;
  const isCrypto = useSelector((state) => state.global.activeAccountIsCrypto); // TODO - use __typename instead?

  return (
    <Box mt={32}>
      <Flex alignItems="center" justifyContent="space-between">
        <Box mb={16}>
          <HXXS
            fontWeight={300}
            content="Markets"
            color="foregroundSecondary"
          />
        </Box>
        <Button
          kind="link"
          onClick={handleClickViewDetails}
          label={`${security.symbol} Details`}
        />
      </Flex>
      <StyledMarketsContainer mb={32}>
        <StyledMarketsQuoteContainer flexDirection="column" p="18px 22px 8px">
          <Flex justifyContent="space-between" flexWrap="wrap">
            <MarketItemContainer>
              <LS color="foregroundNeutralSecondary" content="Price" mb={4} />
              <SecurityCurrentQuotePrice
                lastPrice={security.latestQuote?.lastPrice}
                isCrypto={isCrypto}
              />
              <SecurityCurrentQuoteLastTradeTime
                latestQuote={security.latestQuote}
              />
            </MarketItemContainer>
            <MarketItemContainer>
              <LS
                color="foregroundNeutralSecondary"
                content="Daily Change"
                mb={4}
              />
              <SecurityCurrentQuoteDailyChange
                latestQuote={security.latestQuote}
              />
            </MarketItemContainer>
            <MarketItemContainer>
              <SecurityMiniChartIntraday
                securityId={security.id}
                period={IntradayQuotePeriodEnum.Today}
                interval={IntradayQuoteIntervalEnum.FiveMinute}
                width={200}
                height={72}
              />
            </MarketItemContainer>
          </Flex>
        </StyledMarketsQuoteContainer>
        <SecurityMetrics security={security} />
      </StyledMarketsContainer>
      {!isCrypto && (
        <Box>
          <hr
            style={{
              borderBottom: `1px solid ${theme.colors.borderMain}`,
              marginBottom: 32,
            }}
          />
          <Box mb={16}>
            <HXXS
              fontWeight={300}
              content="Latest news"
              color="foregroundSecondary"
            />
          </Box>
          <SecurityNews securityId={security.id} />
        </Box>
      )}
    </Box>
  );
};
