import { Modal } from '@m1/liquid-react';
import * as React from 'react';

import { useModal } from '~/hooks/useModal';

import { isNotNil } from '~/utils';

import { SelectInvestAccountModalContent } from './SelectInvestAccountModalContent';

export const SelectInvestAccountModal = () => {
  const {
    hideModal,
    modalState: { isOpened, payload },
  } = useModal('SELECT_INVEST_ACCOUNT');

  const sliceableId: string | null | undefined = payload;

  return (
    <Modal onClose={hideModal} open={isOpened}>
      {isNotNil(sliceableId) && (
        <SelectInvestAccountModalContent sliceableId={sliceableId} />
      )}
    </Modal>
  );
};
