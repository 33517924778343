export const POLL_INTERVAL = 2000;
// POLL_LIMIT equates to ~1.67 minutes (usePollTimer pollCountState increases by 1 every two seconds)
export const POLL_LIMIT = 50;

export const SSN_MAX_LENGTH = 11;

export const APPLICATION_WITHDRAWN_SUCCESS_MESSAGE =
  'Your application has been withdrawn.';

export const APPLICATION_WITHDRAWN_ERROR_MESSAGE =
  'We couldn’t withdraw your application. Try again or contact Client Support.';

export const GENERAL_ERROR_MESSAGE =
  'Something went wrong. Try again or contact Client Support.';

export const LEGAL_NAME_TOOLTIP_CONTENT =
  'We need your legal name to validate your identity. Your legal name is also used on tax documents and trade confirmations.';

export const EMAIL_AND_PASSWORD_DISCLAIMER =
  "You'll use these to log in to M1.";

export const SIGNUP_DISCLAIMER =
  'By selecting "Continue", you acknowledge the agreements and disclosures above, and you are providing M1 and B2 Bank, National Association, Member FDIC, Equal Opportunity Lender, with your written authorization to review your credit report to determine if you are eligible for an M1 Personal Loan.';
