import * as React from 'react';

import { RadioChoice, RadioInput } from './choice';
import { RadioGroup } from './group';

// TODO: Get rid of this class component once we move the radio components to LIRE.
export class Radio extends React.Component<any> {
  static Choice = RadioChoice;
  static Group = RadioGroup;
  static Input = RadioInput;

  // @ts-ignore We can't return a valid element here if we throw an error.
  render() {
    throw new Error('Not a real component. Import Radio.Group/Choice instead');
  }
}
