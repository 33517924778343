import { Button, Modal, ModalContent, ModalTitle, PL } from '@m1/liquid-react';
import * as React from 'react';

import { useNavigate } from '~/hooks/useNavigate';
import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

type SmartTransfersNavigationWarningModalProps = {
  isWarningModalOpen: boolean;
  setIsWarningModalOpen: (arg0: boolean) => void;
};

export const SmartTransfersFocusAccountNavigationWarningModal = ({
  isWarningModalOpen,
  setIsWarningModalOpen,
}: SmartTransfersNavigationWarningModalProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hideWarningModal = () => {
    setIsWarningModalOpen(false);
  };
  const confirmWarningModal = () => {
    hideWarningModal();
    dispatch({
      type: ACTIONS.RESET_SMART_TRANSFER_ACTIVE_CONTRA_PARTICIPANTS,
    });

    navigate({ to: '/d/c/create-smart-transfer/initialize-smart-transfer' });
  };
  return (
    <Modal open={isWarningModalOpen} onClose={hideWarningModal}>
      <ModalContent>
        <ModalTitle>Are you sure?</ModalTitle>
        <PL
          content="Going back to change your focus account will clear all your existing Smart Transfer selections."
          mb={32}
        />
        <ButtonGroup behavior="centered">
          <Button
            label="No, Keep Selections"
            kind="secondary"
            size="medium"
            onClick={hideWarningModal}
          />
          <Button
            label="Yes, Change Account"
            kind="primary"
            size="medium"
            onClick={confirmWarningModal}
          />
        </ButtonGroup>
      </ModalContent>
    </Modal>
  );
};
