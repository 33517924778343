import { Flex, spacingUnits } from '@m1/liquid-react';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { BiweeklyIsEvenWeeksDropdownField } from '~/components/form/schedule/BiweeklyIsEvenWeeksDropdownField';
import { ANALYTICS_FREQUENCY_MAP } from '~/components/form/schedule/constants';
import { DayOfMonthDropdown } from '~/components/form/schedule/DayOfMonthDropdown';
import { DayOfWeekDropdown } from '~/components/form/schedule/DayOfWeekDropdown';
import { useAnalytics } from '~/hooks/useAnalytics';

import { CreateTransferContext } from '../../CreateTransferProvider';
import {
  TransferDetailsFormValues,
  TransferFrequencyEnum,
} from '../../steps/TransferDetails.types';

import {
  FREQUENCY_TO_INPUT_KEYS,
  isValidTransferFrequency,
} from './TransferScheduleOptions.utils';
import { TransferScheduleRadioGroup } from './TransferScheduleRadioGroup';

export const TransferScheduleOptions = ({
  isDisabled,
}: {
  isDisabled: boolean;
}) => {
  const analytics = useAnalytics();

  const transferContext = React.useContext(CreateTransferContext);
  const isEvenWeek = Boolean(
    transferContext?.data?.viewer?.transfers?.isEvenWeek,
  );

  const { setValue, watch, control, clearErrors } =
    useFormContext<TransferDetailsFormValues>();

  const frequency = watch('frequency');

  const onFrequencyChange = (freq: TransferFrequencyEnum) => {
    if (isValidTransferFrequency(freq)) {
      if (freq !== 'one-time') {
        analytics.recordEvent('m1_recurring_deposit_click_frequency', null, {
          frequency: ANALYTICS_FREQUENCY_MAP[FREQUENCY_TO_INPUT_KEYS[freq]],
        });
      }
      setValue('schedule', undefined);
      clearErrors('schedule');
    }
  };

  return (
    <Flex flexDirection="column" mb={`${spacingUnits.l}px`}>
      <fieldset>
        <legend className="visually-hidden">Recurring Transfer Options</legend>
        <Flex justifyContent="space-between">
          <TransferScheduleRadioGroup
            {...{
              name: 'frequency',
              disabled: isDisabled,
              control,
              onFrequencyChange,
            }}
          />
        </Flex>
      </fieldset>
      {frequency === TransferFrequencyEnum.Weekly && (
        <DayOfWeekDropdown name="schedule.weekly.dayOfWeek" />
      )}
      {frequency === TransferFrequencyEnum.EveryTwoWeeks && (
        <Flex flexDirection="column">
          <DayOfWeekDropdown name="schedule.biweekly.dayOfWeek" />
          <BiweeklyIsEvenWeeksDropdownField
            isEvenWeek={isEvenWeek}
            label="Start"
          />
        </Flex>
      )}
      {frequency === TransferFrequencyEnum.Monthly && <DayOfMonthDropdown />}
    </Flex>
  );
};
