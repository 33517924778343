import { UniqueIdentifier } from '@dnd-kit/core';

import {
  TreeItem,
  TreeItems,
} from '~/pages/dashboard/wizards/pie-editor/PieEditor.types';

import { AppState } from '../reducers';

export const selectPieEditorInvalidPercentage = (state: AppState) => {
  const tree = state.pieEditor.history[state.pieEditor.historyIndex].tree;
  const invalidItems: TreeItem[] = [];

  const checkPercentage = (items: TreeItems) => {
    items.forEach((item) => {
      let sum = 0;
      if (item.children.length) {
        sum = item.children.reduce(
          (sum, child) => (sum += child.percentage ?? 0),
          0,
        );
        if (sum !== 100) {
          invalidItems.push(item);
        }
        // Recursively check the children
        checkPercentage(item.children);
      }
    });
  };

  // If the root has no children except the add slice, there is nothing to equalize
  if (tree.length === 1 && tree[0].children.length === 1) {
    return null;
  }

  checkPercentage(tree);
  return invalidItems.length ? invalidItems : null;
};

export const selectPieEditorInactiveItems = (state: AppState) => {
  const tree = state.pieEditor.history[state.pieEditor.historyIndex].tree;
  const inactiveItems: TreeItem[] = [];

  const checkInactive = (items: TreeItems) => {
    items.forEach((item) => {
      if (!item.children.length && item.meta.securityInfo?.isActive === false) {
        inactiveItems.push(item);
      } else {
        checkInactive(item.children);
      }
    });
  };

  checkInactive(tree);
  return inactiveItems.length ? inactiveItems : null;
};

export const selectEmptyPies = (state: AppState) => {
  const tree = state.pieEditor.history[state.pieEditor.historyIndex].tree;
  const emptyPies: TreeItem[] = [];

  const checkEmptyPies = (items: TreeItems) => {
    items.forEach((item) => {
      if (
        item.children.length === 1 &&
        item.children[0].meta.type === 'add' &&
        item.removeable
      ) {
        emptyPies.push(item);
      } else {
        checkEmptyPies(item.children);
      }
    });
  };

  checkEmptyPies(tree);
  return emptyPies.length ? emptyPies : null;
};

export const findCircularReferencePies = (tree: TreeItems) => {
  const circularReferencePies: TreeItem[] = [];

  const checkCircularReference = (
    items: TreeItems,
    parentIds: UniqueIdentifier[],
  ) => {
    items.forEach((item) => {
      if (
        item.meta.id &&
        item.meta.type !== 'add' &&
        parentIds.includes(item.meta.id)
      ) {
        circularReferencePies.push(item);
      } else {
        const nextId = item.meta.id ? [item.meta.id] : [];
        checkCircularReference(item.children, [...parentIds, ...nextId]);
      }
    });
  };

  checkCircularReference(tree, []);
  return circularReferencePies;
};

export const selectCircularReferencePies = (state: AppState) => {
  const tree = state.pieEditor.history[state.pieEditor.historyIndex].tree;
  return findCircularReferencePies(tree);
};
