import { Box, HXXS } from '@m1/liquid-react';
import React from 'react';

import { Spinner } from '~/toolbox/spinner';

export const DocumentLoadingPage = () => (
  <Box width={275} mx="auto" mt={200}>
    <Spinner />
    <HXXS
      content="We’re uploading your document, just a moment…"
      mt={40}
      textAlign="center"
    />
  </Box>
);
