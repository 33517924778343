import { PL, Flex, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { PortfolioSliceParentBreadcrumbFragment } from '~/graphql/types';
import { Link } from '~/toolbox/link';

const BackArrowLink = styled(Link)`
  width: 100%;

  & > span {
    width: 100%;
  }
`;

const BackArrowLabel = styled(PL)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export type PortfolioSliceParentBreadcrumbProps = {
  portfolioSlice: PortfolioSliceParentBreadcrumbFragment | null | undefined;
};

export const PortfolioSliceParentBreadcrumb = ({
  portfolioSlice,
}: PortfolioSliceParentBreadcrumbProps) => {
  const parent = React.useMemo(() => {
    if (portfolioSlice && portfolioSlice.__typename === 'ChildPortfolioSlice') {
      const numOfAncestors = portfolioSlice.ancestors.length;
      for (let i = numOfAncestors; i >= 0; i--) {
        const ancestor = portfolioSlice.ancestors[i];
        if (ancestor) {
          return ancestor;
        }
      }
    }
    return null;
  }, [portfolioSlice]);

  if (!parent) {
    return null;
  }

  return (
    <BackArrowLink
      to="/d/invest/portfolio/:portfolioSliceId"
      params={{
        portfolioSliceId: parent.id,
      }}
    >
      <Flex>
        <Icon name="arrowLeft24" />
        <BackArrowLabel content={parent.to.name} ml={8} fontWeight={600} />
      </Flex>
    </BackArrowLink>
  );
};
