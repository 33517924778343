import { PM, useIsProduction } from '@m1/liquid-react';
import * as React from 'react';

import { UseFormSetValue } from 'react-hook-form';

type FormMockerReactHookFormProps = {
  label?: string;
  fields: Array<{
    name: string;
    value: any;
  }>;
  setValue: UseFormSetValue<any>;
  onClick?: () => void;
};

export const FormMockerReactHookForm = ({
  onClick,
  fields,
  setValue,
  label = 'Mock this form!',
}: FormMockerReactHookFormProps) => {
  const onLabelClick = React.useCallback(() => {
    if (onClick) {
      onClick();
    }

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      // shouldDirty is set to true to trigger validation & mimic a user inputting values
      setValue(field.name, field.value, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [fields, onClick, setValue]);

  const isProduction = useIsProduction();

  if (isProduction) {
    return null;
  }

  return (
    <PM
      content={label}
      onClick={onLabelClick}
      color="primary"
      cursor="pointer"
      pt={8}
    />
  );
};
