import * as React from 'react';

import { useWatchlistQuery } from '~/graphql/hooks';

import { WatchistItemsTable } from './WatchlistItemsTable';

type WatchlistProps = {
  amountToDisplay?: number;
  emptyMessage?: string;
  onlyActive?: boolean;
  sortable?: boolean;
  sortDirection?: 'ASC' | 'DESC';
  sortKey?: 'name' | 'price' | 'change' | 'marketCap' | 'peRatio';
};

export const Watchlist = (providedProps: WatchlistProps) => {
  const { data } = useWatchlistQuery({
    fetchPolicy: 'network-only',
  });

  if (!data) {
    return null;
  }

  const watchlistValue = data?.viewer.user?.data.find(
    (d) => d.key === 'watchlist',
  )?.value;

  const watchlistSecurityIds = watchlistValue ? JSON.parse(watchlistValue) : [];

  return (
    <WatchistItemsTable nodeIds={watchlistSecurityIds} {...providedProps} />
  );
};
