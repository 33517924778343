import { SagaIterator } from 'redux-saga';
import { call, fork, select, take } from 'redux-saga/effects';

import { NavigateFunction } from '~/hooks/useNavigate';
import { ACTION_TYPES as ACTIONS } from '~/redux/actions';

export function* clickedSetOrder(opts: {
  isFromHoldingsPositionDetail?: boolean;
  portfolioSliceId: string;
  previousRouteName?: string;
  setAsSellOrder?: boolean;
}): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );

  // If flow is canceled, return to wherever the user came from.
  // Should this be here or within setOrderFlow? Or maybe on a page monitor?
  yield fork(function* () {
    yield take([
      ACTIONS.CLICKED_CANCEL_SET_ORDER,
      ACTIONS.FINISHED_SET_ORDER_FLOW,
    ]);

    if (opts.previousRouteName) {
      yield call(navigate, { to: opts.previousRouteName });
    } else {
      yield call(navigate, { to: '/d/invest/portfolio' });
    }
  });

  yield call(navigate, {
    to: `/d/c/set-order/:portfolioSliceId`,
    params: { portfolioSliceId: opts.portfolioSliceId },
    query: {
      direction: opts.setAsSellOrder ? 'sell' : 'buy',
      isFromHoldingsPositionDetail: opts.isFromHoldingsPositionDetail
        ? 'true'
        : 'false',
    },
  });
}
