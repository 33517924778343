import { Button, Flex, HotButton, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import React from 'react';
import { useSelector } from 'react-redux';

import { useAnalytics } from '~/hooks/useAnalytics';
import { useModal } from '~/hooks/useModal';
import { hasInactiveSlices } from '~/pie-trees';
import { useToast } from '~/toasts';

type Props = {
  type?: 'button' | 'link';
  label?: string;
  withIcon?: boolean;
  userPieId: string;
};

export const PieShareLink = (props: Props) => {
  const { userPieId, label, withIcon = false, type = 'link', ...rest } = props;
  const analytics = useAnalytics();
  const { showModal } = useModal('SHARE_PIE');
  const { addToast } = useToast();

  const pieTree = useSelector((state: any) => state.portfolioOrganizer.pieTree);
  const hasInactive = hasInactiveSlices(pieTree);

  const handleClick = () => {
    if (hasInactive) {
      addToast({
        content: 'Pies with inactive slices cannot be shared',
        duration: 'short',
        kind: 'alert',
      });
    } else {
      showModal(userPieId);
    }

    analytics.recordEvent('m1_invest_pieshare_feature_clicked');
  };

  return type === 'button' ? (
    <HotButton
      data-testid="pie-share-button"
      label="Share"
      size="small"
      icon="share"
      onClick={handleClick}
    />
  ) : (
    <Flex
      data-testid="pie-share-link"
      marginLeft="auto"
      onClick={handleClick}
      inline
      style={{ cursor: 'pointer' }}
    >
      <StyledPieShareLink
        {...rest}
        kind="link"
        label={label ?? 'Share this pie'}
      />
      {withIcon ? (
        <Icon
          name="share20"
          color="primary"
          display="inline-block"
          marginLeft={8}
        />
      ) : null}
    </Flex>
  );
};

export const StyledPieShareLink = styled(Button)`
  flex: 0 0 auto;
  font-size: 14px;
  line-height: 20px;
  margin-left: auto;
  text-align: right;
`;
