import { PL, Flex } from '@m1/liquid-react';
import * as React from 'react';

type SecurityDataRowProps = {
  headingContent?: string;
  tooltip?: React.ReactNode;
  value: (number | null | undefined) | string;
  valueIcon?: React.ReactNode;
};

export const SecurityDataRow = ({
  headingContent,
  tooltip,
  value,
  valueIcon,
}: SecurityDataRowProps) => {
  if (!value) {
    return null;
  }
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      minWidth={310}
      py={9}
    >
      <Flex alignItems="center">
        <PL content={headingContent} textAlign="start" />
        {tooltip && tooltip}
      </Flex>
      <Flex alignItems="center" maxWidth="50%">
        {valueIcon && <Flex mr={8}>{valueIcon}</Flex>}
        <PL fontWeight={600} content={value} textAlign="end" />
      </Flex>
    </Flex>
  );
};
