import { Flex } from '@m1/liquid-react';
import React from 'react';

import { useSliceableClick } from '~/hooks/useSliceableClick';

import { ConnectedPie } from '../ConnectedPie';

import { usePieDetailsContext } from '../PieDetailsPage/hooks/usePieDetailsContext';
import { PieHighlights } from '../PieDetailsPage/PieHighlights';
import { PieShareLink } from '../PieShareLink';

export const PieChartContainer = () => {
  const { pie, disableSharing, isCrypto } = usePieDetailsContext();
  const onClick = useSliceableClick();

  if (!pie) {
    return null;
  }

  return (
    <Flex alignItems="center" justifyContent="center" flexDirection="column">
      {!disableSharing && !isCrypto && pie?.isOwnPie && (
        <Flex alignItems="flex-end" width="100%" justifyContent="center">
          <PieShareLink userPieId={pie.id} label="Share Pie" withIcon />
        </Flex>
      )}
      <div
        style={{
          padding: '32px 0',
          width: 292,
          position: 'relative',
        }}
      >
        <ConnectedPie
          data={pie.slices.map((slice) => ({
            id: slice.to.id,
            percentage: slice.percentage,
          }))}
          height={292}
          innerRadius={103}
          onClick={({ id }) => onClick(id!)}
          width={292}
        />
        <PieHighlights />
      </div>
    </Flex>
  );
};
