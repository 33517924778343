import {
  Flex,
  HL,
  PM,
  PS,
  PXL,
  Tooltip,
  Skeleton,
  SkeletonProvider,
  styled,
  theme,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import moment from 'moment';
import React, { MouseEventHandler } from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';

import {
  usePortfolioDividendsChartLazyQuery,
  usePortfolioDividendsTablesQuery,
} from '~/graphql/hooks';
import { DividendTrackerDataPeriod } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { useSelector } from '~/redux/hooks';

import { DividendsView } from './DividendsView';
import { NoDividendsView } from './NoDividendsView';

export const PortfolioDividendsPage = () => {
  const [hasShownSkeletonLoader, setHasShownSkeletonLoader] =
    React.useState(false);

  const [dividendPeriod, setDividendPeriod] = React.useState(
    DividendTrackerDataPeriod.ThisYear,
  );

  const [accountMetrics, setAccountMetrics] = React.useState<{
    averageIncome: string;
    totalConfirmed: string;
    totalEarned: string;
    totalPaid: string;
    totalUpcoming: string;
  }>({
    averageIncome: '$--',
    totalConfirmed: '$--',
    totalEarned: '$--',
    totalPaid: '$--',
    totalUpcoming: '$--',
  });

  const navigate = useNavigate();

  const hasDividends = true;
  const accountId = useSelector((state) => state.global.activeAccountId);

  const [
    fetchDividendsChartData,
    { data: chartData, loading: isChartLoading, error: dividendsDataError },
  ] = usePortfolioDividendsChartLazyQuery();

  /*
   * We load the table data separately from the chart data
   * because the table data is not driven by the period chart
   * filters. The table data is always for the current year.
   */
  const { data: tableDividendData, loading: areTablesLoading } =
    usePortfolioDividendsTablesQuery({
      variables: { accountId: accountId as string },
      skip: !accountId,
    });

  React.useEffect(() => {
    fetchDividendsChartData({
      variables: {
        accountId: accountId as string,
        period: dividendPeriod,
      },
      fetchPolicy: 'cache-first',
      onCompleted: (data) => {
        setAccountMetrics({
          averageIncome:
            data?.viewer?.invest?.dividends?.dividendSummary
              ?.averageMonthlyIncome ?? '$--',
          totalEarned:
            data?.viewer?.invest?.dividends?.dividendSummary
              ?.totalIncomeEarned ?? '$--',
          totalPaid:
            data?.viewer?.invest?.dividends?.dividendSummary
              ?.totalPaidForTimeRange ?? '$--',
          totalConfirmed:
            data?.viewer?.invest?.dividends?.dividendSummary
              ?.totalConfirmedForTimeRange ?? '$--',
          totalUpcoming:
            data?.viewer?.invest?.dividends?.dividendSummary
              ?.totalUpcomingForTimeRange ?? '$--',
        });
      },
    });
  }, [dividendPeriod, accountId]);

  React.useEffect(() => {
    if (!hasShownSkeletonLoader) {
      setHasShownSkeletonLoader(true);
    }
  }, [hasShownSkeletonLoader, isChartLoading || areTablesLoading]);

  if (dividendsDataError) {
    return (
      <GenericSystemError content="There was an error loading dividend data. Please try again later." />
    );
  }

  const isSkeletonLoading =
    (!hasShownSkeletonLoader && isChartLoading) || areTablesLoading;

  const account = chartData?.node;
  const isCryptoAccount =
    account?.__typename === 'Account' && account.isCryptoAccount;

  if (isCryptoAccount) {
    navigate({ to: '/d/invest/holdings' });
  }

  return (
    <Flex flexDirection="column" ml={16} pb={64}>
      <SkeletonProvider isLoading={isSkeletonLoading}>
        <Flex
          alignItems="center"
          flexDirection="row"
          alignSelf="stretch"
          flexWrap="nowrap"
          pb={24}
          style={{
            borderBottom: `1px solid ${theme.colors.foregroundNeutralTertiary}`,
          }}
        >
          {hasDividends && (
            <Flex flexDirection="column" flexGrow={1}>
              <Flex
                {...{
                  flexDirection: 'row',
                  flexGrow: 1,
                  justifyContent: 'right',
                  position: 'relative',
                  // Negative value are gross, but relative positioning is preferred over
                  // negative margins due to the destructive nature of negative margins.
                  top: -48, // We move this up to be in-line with the tabs.
                  height: 0, // This prevents extra spacing between the tabs and the summary
                }}
              >
                <Skeleton mr={4}>
                  <ChartPeriodOption
                    onClick={() =>
                      setDividendPeriod(DividendTrackerDataPeriod.LastYear)
                    }
                    // Resolves to a numeric value of last year:
                    label={`${moment().year() - 1}`}
                    selected={
                      dividendPeriod === DividendTrackerDataPeriod.LastYear
                    }
                  />
                </Skeleton>
                <Skeleton mr={4}>
                  <ChartPeriodOption
                    onClick={() =>
                      setDividendPeriod(DividendTrackerDataPeriod.ThisYear)
                    }
                    // Resolves to a numeric value of this year:
                    label={`${moment().year()}`}
                    selected={
                      dividendPeriod === DividendTrackerDataPeriod.ThisYear
                    }
                  />
                </Skeleton>
                <Skeleton>
                  <ChartPeriodOption
                    onClick={() =>
                      setDividendPeriod(DividendTrackerDataPeriod.Next_12Months)
                    }
                    label="NEXT 12M"
                    selected={
                      dividendPeriod === DividendTrackerDataPeriod.Next_12Months
                    }
                  />
                </Skeleton>
              </Flex>
              <Skeleton display="flex" mb={8}>
                <PM color="foregroundNeutralSecondary">Total income</PM>
                <Tooltip
                  body={
                    <PM fontWeight={400}>
                      Includes paid, pending, and estimated dividends in the
                      selected time range. Pending dividends are past their
                      ex-date but not yet paid. Estimated dividends are not
                      guaranteed. Pending and estimated dividends won't appear
                      in buying power or transactions until paid.
                    </PM>
                  }
                >
                  <Icon name="tooltip16" ml={4} />
                </Tooltip>
              </Skeleton>
              <Flex flexDirection="row">
                <Skeleton>
                  <HL fontWeight={300} width="33%" minWidth={200} pr={24}>
                    {accountMetrics.totalEarned ?? '--'}
                  </HL>
                </Skeleton>
                <PerformanceMetricColumn minWidth={132}>
                  <Skeleton mb={8}>
                    <PM color="foregroundNeutralSecondary" whiteSpace="nowrap">
                      Paid income
                    </PM>
                  </Skeleton>
                  <Skeleton>
                    <PXL fontWeight={600}>
                      {accountMetrics.totalPaid ?? '--'}
                    </PXL>
                  </Skeleton>
                </PerformanceMetricColumn>
                <PerformanceMetricColumn minWidth={154}>
                  <Skeleton mb={8}>
                    <PM color="foregroundNeutralSecondary" whiteSpace="nowrap">
                      Pending income
                    </PM>
                  </Skeleton>
                  <Skeleton>
                    <PXL fontWeight={600}>
                      {accountMetrics.totalConfirmed ?? '--'}
                    </PXL>
                  </Skeleton>
                </PerformanceMetricColumn>
                <PerformanceMetricColumn minWidth={166}>
                  <Skeleton mb={8}>
                    <PM color="foregroundNeutralSecondary" whiteSpace="nowrap">
                      Estimated income
                    </PM>
                  </Skeleton>
                  <Skeleton>
                    <PXL fontWeight={600}>
                      {accountMetrics.totalUpcoming ?? '--'}
                    </PXL>
                  </Skeleton>
                </PerformanceMetricColumn>
                <PerformanceMetricColumn minWidth={212}>
                  <Skeleton mb={8}>
                    <PM color="foregroundNeutralSecondary" whiteSpace="nowrap">
                      Average monthly income
                    </PM>
                  </Skeleton>
                  <Skeleton>
                    <PXL fontWeight={600}>
                      {accountMetrics.averageIncome ?? '--'}
                    </PXL>
                  </Skeleton>
                </PerformanceMetricColumn>
              </Flex>
            </Flex>
          )}
        </Flex>
        {hasDividends && accountId ? (
          <DividendsView
            dividendPeriod={dividendPeriod}
            isChartLoading={isChartLoading}
            isSkeletonLoading={isSkeletonLoading}
            chartData={chartData}
            tableDividendData={tableDividendData}
          />
        ) : (
          <NoDividendsView />
        )}
      </SkeletonProvider>
    </Flex>
  );
};

const PerformanceMetricColumn = styled(Flex)`
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 24px;
  padding-right: 24px;
  border-left: 1px solid ${(props) => props.theme.colors.borderMain};
`;

const ChartPeriodOption = ({
  label,
  selected,
  onClick,
}: {
  label: string;
  onClick: MouseEventHandler<HTMLElement>;
  selected?: boolean;
}) => {
  return (
    <PS
      fontWeight={selected ? 600 : 400}
      color={selected ? 'foregroundPrimary' : 'foregroundNeutralSecondary'}
      textDecoration={selected ? 'underline' : 'none'}
      px={3}
      cursor="pointer"
      onClick={onClick}
      minWidth={label.length * 7}
      textAlign="center"
    >
      {label}
    </PS>
  );
};
