import get from 'lodash-es/get';
import uniqueId from 'lodash-es/uniqueId';

import { EditModel, EditModelNewPie, Path } from '../../SharePiePage.types';

export const PATH_DELIMITER = ',';
export const BACKTESTING_INITIAL_VALUE = 100_000;

export const readEditModel = (
  parsedModel: EditModel,
  path: string | null | undefined,
): EditModel => {
  if (!path) {
    return parsedModel;
  }

  return path.includes(PATH_DELIMITER)
    ? get(parsedModel, createTo(path.split(PATH_DELIMITER)))
    : get(parsedModel, createTo([path]));
};

export const createTo = (path: Path): string => {
  return path.map((s) => `slices.${s}.to`).join('.');
};

type SliceableTypeEnum = 'UserPie' | 'SystemPie' | 'Security' | 'Fund';
const mapSliceableTypename = (
  sliceableType: 'security' | 'new_pie' | 'old_pie',
): SliceableTypeEnum => {
  switch (sliceableType) {
    case 'security':
      return 'Security';
    case 'old_pie':
    case 'new_pie':
      return 'UserPie';
    default:
      throw new Error(`Unknown sliceable type: ${sliceableType}`);
  }
};

/**
 * Iterates through the edit model pie tree and add's id's and __typename's to all the "new_pie"s.
 * @param editModel - The pie tree edit model.
 */
export const annotateLocalModel = (editModel: EditModelNewPie): EditModel => {
  editModel.slices = (editModel.slices ?? []).map((slice) => {
    slice = {
      ...slice,
      to: {
        ...slice.to,
        __typename: slice.to.__typename ?? mapSliceableTypename(slice.to.type),
        id: slice.to.id ?? uniqueId('slice-'),
      },
    };

    if (slice.to.type === 'new_pie') {
      annotateLocalModel(slice.to);
    }

    return slice;
  });

  return editModel;
};

export const readPath = (
  pathParam: string | null | undefined,
): Array<string> => {
  if (!pathParam) {
    return [];
  }

  // For URLs like ?path=123,456
  if (pathParam.includes(PATH_DELIMITER)) {
    return pathParam.split(PATH_DELIMITER).filter(Boolean);
  }

  return [pathParam];
};

type SharePieBreadcrumbs = { label: string; to?: string }[];
export const getBreadcrumbLinks = (
  pathParam: string | null | undefined,
  token: string,
  parsedModel: EditModel,
  name: string,
): SharePieBreadcrumbs | null => {
  const pathArray = readPath(pathParam);

  if (pathArray.length > 0) {
    const breadcrumb: SharePieBreadcrumbs = pathArray.map((_, index) => {
      const segment = pathArray.slice(0, index);
      const to = [createTo(segment), 'name'].filter(Boolean).join('.');

      return {
        label: get(parsedModel, to),
        to: segment.length
          ? `/share?token=${token}&path=${segment.join(PATH_DELIMITER)}`
          : `/share?token=${token}`,
      };
    });

    breadcrumb.push({ label: name });
    return breadcrumb;
  }

  return null;
};
