import * as React from 'react';
import { Route } from 'react-router-dom';

import { FinancialSuitabilityPage } from './FinancialSuitabilityPage';

export const FinancialSuitabilityRoute = () => {
  return (
    <Route
      path="financial-details/:step?"
      element={<FinancialSuitabilityPage />}
    />
  );
};
