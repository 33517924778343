import { HXS, PL, Flex, Box } from '@m1/liquid-react';
import * as React from 'react';

import { Spinner } from '~/toolbox/spinner';

export const ProcessingRequest = ({
  content,
  heading,
}: {
  content: string;
  heading: string;
}) => {
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      height="100vh"
      justifyContent="center"
    >
      <Box height={150}>
        <Spinner radius={90} thickness={10} />
      </Box>
      <HXS pt={16} content={heading} />
      <PL pt={16} content={content} />
    </Flex>
  );
};
