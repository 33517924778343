import { Button, Modal, ModalContent, ModalTitle, PL } from '@m1/liquid-react';
import * as React from 'react';

export const MarginHealthDetailsModalContent = () => {
  return (
    <ModalContent>
      <ModalTitle>How to improve your margin health:</ModalTitle>
      <PL
        content="Margin health is calculated using your total account value and Margin Loan balance."
        mb={16}
      />
      <PL content="You can improve by:" />
      <PL mb={16} ml={24}>
        <ul>
          <li>Funding this investment account.</li>
          <li>Repaying some of your Margin Loan. </li>
          <li>Shifting to investments with lower maintenance requirements.</li>
        </ul>
      </PL>
      <PL content="If margin health equals 0%, a margin call is issued. You’d then have to fund your account or repay some margin to avoid investments being sold on your behalf. " />
    </ModalContent>
  );
};

export const MarginHealthDetailsModal = () => (
  <Modal trigger={<Button kind="link" label="View Details" />}>
    <MarginHealthDetailsModalContent />
  </Modal>
);
