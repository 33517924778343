import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { useSearchParams } from '~/hooks/useSearchParams';
import { enteredPage, exitedPage } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

import { SharePiePageContent } from './components/SharePiePageContent';

export const SharedPiePage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const shareToken = searchParams.get('token') ?? '';
  const path = searchParams.get('path') ?? '';

  React.useEffect(() => {
    dispatch(enteredPage('SHARE', undefined));
    return () => dispatch(exitedPage('SHARE', undefined));
  }, [dispatch]);

  return (
    <Box backgroundColor="backgroundNeutralSecondary">
      <SharePiePageContent shareToken={shareToken} path={path} />
    </Box>
  );
};
