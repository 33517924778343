import { Button, HXS, PL, Flex, Box } from '@m1/liquid-react';
import * as React from 'react';

import { useNavigate } from '~/hooks/useNavigate';
import { Checkbox } from '~/toolbox/checkbox';

type RemoveCreditFreezeProps = {
  onFinishStep: () => void;
};

export const RemoveCreditFreeze = ({
  onFinishStep,
}: RemoveCreditFreezeProps) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = React.useState<boolean>(false);

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      mt={150}
      justifyContent="center"
    >
      <Box width={550}>
        <HXS content="A decision could not be made on your credit card application." />
        <fieldset>
          <legend>
            <PL mt={32}>
              Your credit report is frozen, and it is not possible to complete
              the application process. Please temporarily lift the freeze placed
              with Experian, and resubmit your application.
            </PL>
          </legend>
          <Box mt={64}>
            <Checkbox
              mb={16}
              ml={-4}
              label="I confirm that my credit is no longer frozen."
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
            />
            <Button
              kind="secondary"
              label="Close"
              onClick={() => {
                // If the user has an active application, anything under
                // /d/spend redirects back into the application flow
                // which will lead back to this same screen.
                // Close here takes you back home and out of the flow.
                navigate({ to: '/d/home' });
              }}
              size="large"
            />
            <Button
              kind="primary"
              label="Resubmit Application"
              disabled={!isChecked}
              ml={16}
              onClick={onFinishStep}
              size="large"
            />
          </Box>
        </fieldset>
      </Box>
    </Flex>
  );
};
