import { Skeleton, SkeletonProvider } from '@m1/liquid-react';
import Lottie from 'lottie-react';
import * as React from 'react';

import { useLottieDynamicFetch } from '~/lottie/hooks/useLottieDynamicFetch';

type DynamicAnimationProps = {
  fileName: string;
};

export const DynamicAnimation = ({ fileName }: DynamicAnimationProps) => {
  const { loading, animationData } = useLottieDynamicFetch(fileName);

  if (!loading && !animationData) {
    return null;
  }

  return (
    <SkeletonProvider isLoading={loading} fadeOut>
      <Skeleton>
        <Lottie animationData={animationData} />
      </Skeleton>
    </SkeletonProvider>
  );
};
