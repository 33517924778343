import { IraDistributionReasonEnum } from '~/graphql/types';

import { EnumDropdown } from './types';

export const IraDistributionReasonEnumDropdownOptions: EnumDropdown<IraDistributionReasonEnum> =
  [
    {
      'name': IraDistributionReasonEnum.Premature,
      'description': 'Premature',
    },
    {
      'name': IraDistributionReasonEnum.Normal,
      'description': 'Normal',
    },
    {
      'name': IraDistributionReasonEnum.Disability,
      'description': 'Disability',
    },
    {
      'name': IraDistributionReasonEnum.Death,
      'description': 'Death',
    },
    {
      'name':
        IraDistributionReasonEnum.ExcessContributionRemovalBeforeTaxDeadline,
      'description': 'Excess contribution removal before tax deadline',
    },
    {
      'name':
        IraDistributionReasonEnum.ExcessContributionRemovalAfterTaxDeadline,
      'description': 'Excess contribution removal after tax deadline',
    },
  ];
