import { Box, styled } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { PositionDetails } from '~/components/invest/PositionDetails/PositionDetailsRenderer';
import { useSelector } from '~/redux/hooks';
import { MARGIN_CALL_INFORMATION_STEPS as STEPS } from '~/static-constants';

type MarginCallInformationPositionDetailsProvidedProps = {
  onFinishStep: (step?: ValueOf<typeof STEPS>) => void;
  onSellLinkClick?: (...args: Array<any>) => any;
};

type MarginCallInformationPositionDetailsProps =
  MarginCallInformationPositionDetailsProvidedProps;

// Styled Components
const StyledContentContainer = styled(Box)`
  margin: auto;
  width: 783px;
`;

export const MarginCallInformationPositionDetails = ({
  onFinishStep,
  onSellLinkClick,
}: MarginCallInformationPositionDetailsProps) => {
  const positionId = useSelector(
    (state) => state.newFlows.MARGIN_CALL_INFORMATION.positionId,
  );
  const previousRouteName = useSelector(
    (state) => state.newFlows.MARGIN_CALL_INFORMATION.previousRouteName,
  );
  if (!positionId) {
    return null;
  }
  return (
    <StyledContentContainer>
      <BackArrow
        content="Holdings Overview"
        mb={24}
        mt={77}
        onClick={() => onFinishStep(STEPS.HOLDINGS_OVERVIEW)}
      />
      <PositionDetails
        isNavigable={false}
        onSellLinkClick={onSellLinkClick}
        positionId={positionId}
        previousRouteName={previousRouteName}
      />
    </StyledContentContainer>
  );
};
