import { Button, PL } from '@m1/liquid-react';
import * as React from 'react';

import { UNSAFE_connectRedux } from '~/hocs';
import appStore from '~/images/app-store.png';
import playStore from '~/images/play-store.png';
import { Container } from '~/toolbox/container';
import { Grid } from '~/toolbox/grid';
import { Link } from '~/toolbox/link';
import { getMobileOperatingSystem } from '~/utils/getMobileOperatingSystem';

type Props = {
  dispatch: (...args: Array<any>) => any;
};

const WarnMobileUsersComponent: React.FC<Props> = ({ dispatch }) => {
  const mobileOS = getMobileOperatingSystem();
  const handleClick = (): void => {
    dispatch({
      type: 'WARNED_MOBILE_USERS',
    });
  };

  return (
    <Container
      centered
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <PL pb={24}>
        Download the M1 app and log in for the best experience on your mobile
        device.
      </PL>
      {/* @ts-expect-error - TS2769 */}
      <Grid.Row pb={24}>
        {mobileOS === 'iOS' && (
          <Grid.Col xs={12}>
            <Link
              to="https://itunes.apple.com/us/app/m1-finance/id1071915644"
              target="_blank"
              rel="nofollow"
            >
              <img
                src={appStore}
                style={{
                  maxWidth: '100%',
                }}
              />
            </Link>
          </Grid.Col>
        )}
        {mobileOS === 'Android' && (
          <Grid.Col xs={12}>
            <Link
              to="https://play.google.com/store/apps/details?id=com.m1finance.android"
              target="_blank"
              rel="nofollow"
            >
              <img
                src={playStore}
                style={{
                  maxWidth: '100%',
                }}
              />
            </Link>
          </Grid.Col>
        )}
      </Grid.Row>

      <Button
        kind="text"
        label="Continue to desktop site"
        onClick={handleClick}
      />
    </Container>
  );
};

const enhancer = UNSAFE_connectRedux();

export const WarnMobileUsers = enhancer(WarnMobileUsersComponent) as any;
