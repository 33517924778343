import { PL, PM, Flex, Box, styled } from '@m1/liquid-react';
import * as React from 'react';

import { Pill } from '~/toolbox/Pill';
import { formatNumber } from '~/utils';

import { ConfirmationChange } from './ConfirmationDialog.types';

type ConfirmationRowProps = {
  change: ConfirmationChange;
};

const StyledRowContainer = styled(Flex)`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderMain};

  &:last-of-type {
    border-top: none;
    border-bottom: none;
  }
`;

const getFinalValue = (value: number, isPercent: boolean): string =>
  isPercent ? `${value}%` : `${formatNumber(value)}`;

export const ConfirmationRow = (props: ConfirmationRowProps) => {
  const { left, right } = props.change;

  return (
    <StyledRowContainer
      flexDirection="row"
      justifyContent="center"
      minHeight="65px"
      padding={16}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        width={right ? '50%' : '100%'}
      >
        {left.description ? (
          <PM color="foregroundNeutralSecondary" mb={4}>
            {left.label}
          </PM>
        ) : (
          <PL color="foregroundNeutralSecondary" mb={4}>
            {left.label}
          </PL>
        )}
        <Box display="inline-block">{left.description}</Box>
      </Flex>
      {right && (
        <Flex alignItems="center" justifyContent="flex-end" width="50%">
          {right.actionPill && right.actionPill.kind && (
            <Pill
              size="medium"
              kind={right.actionPill.kind}
              label={right.actionPill.label}
              mr={8}
            />
          )}
          {right.value && (
            <PL color="foregroundNeutralMain">
              {getFinalValue(right.value, right.isPercent)}
            </PL>
          )}
        </Flex>
      )}
    </StyledRowContainer>
  );
};
