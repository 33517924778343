import { Box, Flex } from '@m1/liquid-react';
import React from 'react';

import { SetupChecklistCard } from '~/components/SetupChecklistCard';
import { Sticky } from '~/components/sticky';
import { useDynamicSizing } from '~/hooks/useDynamicSizing';
import { PortfolioDetailsFooter } from '~/pages/dashboard/invest/portfolio/PortfolioDetailsPage/components/PortfolioDetailsFooter';

import { PieCardContent } from './components/PieContent';
import { PortfolioDetailsContainer } from './components/PortfolioDetailsContainer';
import { PortfolioSliceActionsButtonGroup } from './components/PortfolioSliceActionsButtonGroup';
import { PortfolioSliceParentBreadcrumb } from './components/PortfolioSliceParentBreadcrumb';
import { PortfolioSliceValueHistoryChart } from './components/PortfolioSliceValueHistoryChart';
import { TradingDetailsSection } from './components/TradingDetailsSection';
import { PortfolioDetailsContext } from './PortfolioDetailsContext';
import {
  PortfolioDetailsPageRight,
  BreadcrumbContainer,
  StyledPortfolioDetailsPageContainer,
} from './PortfolioDetailsPage.styled';

export const PortfolioDetailsDesktopView = () => {
  const { account, portfolioSlice, isCrypto } = React.useContext(
    PortfolioDetailsContext,
  );

  const isAccountSetup = Boolean(!account?.setupChecklist);
  // we use monitor changes due to async content altering the size of elements
  const [{ height: leftColumnHeight }, leftColumnRef] = useDynamicSizing({
    monitorChanges: true,
  });
  const [{ height: chartStatsHeight }, chartStatsRef] = useDynamicSizing({
    monitorChanges: true,
  });
  const [{ height: sliceListHeight }, sliceListRef] = useDynamicSizing({
    monitorChanges: true,
  });

  const lh = leftColumnHeight;
  const ch = chartStatsHeight;
  const sh = sliceListHeight;

  const columnDiff = ch + sh - lh;
  const sliceListPadding =
    columnDiff < 0
      ? ch - columnDiff - 48 // if left column is taller than the sum of chart stats and slice list
      : Math.max(0, ch - columnDiff - 96) + 48; // 96px = sticky header offset, 48px = half of sticky header height

  return (
    <Flex flexDirection="column" width="100%">
      <StyledPortfolioDetailsPageContainer>
        <Box zIndex={1} width={380}>
          {/* Sticky offset to account for sticky header */}
          <Sticky stickyOffset={96}>
            {portfolioSlice.__typename === 'ChildPortfolioSlice' && (
              <BreadcrumbContainer>
                <PortfolioSliceParentBreadcrumb
                  portfolioSlice={portfolioSlice}
                />
              </BreadcrumbContainer>
            )}
            <Box ref={leftColumnRef}>
              <PieCardContent />
              <Box maxWidth={384} mx="auto" mt={16}>
                <PortfolioSliceActionsButtonGroup
                  account={account}
                  portfolioSlice={portfolioSlice}
                />
              </Box>
              <TradingDetailsSection />
            </Box>
          </Sticky>
        </Box>
        {/* The height of this one needs to be height of slice list */}
        <PortfolioDetailsPageRight>
          <Box ref={chartStatsRef}>
            {isAccountSetup ? (
              <PortfolioSliceValueHistoryChart
                portfolioSlice={portfolioSlice}
              />
            ) : (
              account?.setupChecklist && (
                <SetupChecklistCard
                  toParticipantId={account.id}
                  setupChecklist={account.setupChecklist}
                />
              )
            )}
          </Box>
          <Box ref={sliceListRef}>
            <PortfolioDetailsContainer />
          </Box>
          <Box height={sliceListPadding} />
        </PortfolioDetailsPageRight>
      </StyledPortfolioDetailsPageContainer>
      <PortfolioDetailsFooter isCrypto={isCrypto} />
    </Flex>
  );
};
