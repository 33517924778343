import { SagaIterator } from 'redux-saga';
import { call, getContext, takeEvery } from 'redux-saga/effects';

import { RevokeRefreshDocument } from '~/graphql/hooks';
import { RevokeRefreshInput } from '~/graphql/types';

import { ACTION_TYPES } from '~/redux/actions';

import { apolloMutationSaga } from './apolloMutationSaga';

import { getLoggers } from './common';

export function* logoutSaga(): SagaIterator {
  const { analytics, launchDarkly } = yield call(getLoggers);

  yield takeEvery(ACTION_TYPES.LOGOUT, function* (): SagaIterator {
    const auth = yield getContext('auth');

    try {
      auth._basil.set('accessToken', null);
      const refreshToken = auth._basil.get('refreshToken');

      // Don't bother revoking a non-existant token.
      if (refreshToken) {
        auth.clear();
        yield call(apolloMutationSaga, {
          mutation: RevokeRefreshDocument,
          variables: {
            input: {
              refreshToken,
            } satisfies RevokeRefreshInput,
          },
        });
        auth._basil.set('refreshToken', null);
      }

      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    } finally {
      analytics.mutation('account', 'loggedOut');
      launchDarkly.logout();

      // Wait for loggedOut event to fire before reset to make sure
      // the current user's ID is passed correctly
      window.setTimeout(() => analytics.reset(), 400);
      // Wait for cookies and user_id to clear before page reload
      // because the Segment library doesn't support promises
      window.setTimeout(() => document.location.reload(), 400);
    }
  });
}
