import * as React from 'react';

import { PhoneVerificationWizard } from '~/components/phoneVerification/PhoneVerificationWizard';
import { finishedOnboardingPhoneVerification } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

export const PhoneVerificationPage = () => {
  const dispatch = useDispatch();

  return (
    <PhoneVerificationWizard
      onWizardCompletion={(outcome) =>
        dispatch(finishedOnboardingPhoneVerification(outcome))
      }
    />
  );
};
