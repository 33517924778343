import { Button, HXS, Modal, ModalContent, PL } from '@m1/liquid-react';
import * as React from 'react';

import { useRebalancePortfolioSliceMutation } from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useModal } from '~/hooks/useModal';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useToast } from '~/toasts';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

export const RebalanceCancelModal = () => {
  const analytics = useAnalytics();
  const { addToast } = useToast();

  const { hideModal, modalState } = useModal('REBALANCE_ACTION_CANCEL');
  const portfolioSliceId = modalState.payload?.portfolioSliceId;

  const [rebalancePortfolioSlice, { loading }] =
    useRebalancePortfolioSliceMutation({
      variables: {
        input: {
          isRebalance: false,
          portfolioSliceId,
        },
      },
    });

  usePortaledSpinner(loading);

  const handleCancel = (): void => {
    hideModal();
  };

  const handleConfirm = (): void => {
    rebalancePortfolioSlice({
      onCompleted: () => {
        analytics.mutation('portfolio', 'rebalanceCanceledSingle');

        addToast({
          content: 'Rebalance canceled.',
          kind: 'success',
          duration: 'long',
        });

        hideModal();
      },
      onError: () => {
        addToast({
          content:
            'Something went wrong with your request. Please try again later or contact support.',
          kind: 'alert',
          duration: 'long',
        });

        hideModal();
      },
    });
  };

  return (
    <Modal open={modalState.isOpened} onClose={handleCancel}>
      <ModalContent>
        <HXS content="Cancel rebalance" />
        <PL mt={8}>Are you sure you want to cancel your rebalance?</PL>
        <ButtonGroup mt={16}>
          <Button
            label="Keep Rebalance"
            kind="secondary"
            size="medium"
            onClick={handleCancel}
          />
          <Button
            label="Cancel Rebalance"
            kind="primary"
            size="medium"
            onClick={handleConfirm}
          />
        </ButtonGroup>
      </ModalContent>
    </Modal>
  );
};
