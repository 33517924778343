import * as React from 'react';
import { Route } from 'react-router-dom';

import { BorrowUseCasesPage } from './BorrowUseCasesPage';

export const BorrowUseCasesRoute = () => (
  <Route
    element={<BorrowUseCasesPage />}
    handle={{
      fallbackRoute: {
        to: '/d/borrow',
      },
    }}
    path="borrow-use-cases/:useCase?"
  />
);
