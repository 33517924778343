import { Box, Card } from '@m1/liquid-react';
import * as React from 'react';

import { useMarketTrendsQuery } from '~/graphql/hooks';
import { MarketIndexProxyFragment } from '~/graphql/types';
import { Spinner } from '~/toolbox/spinner';

import { IndexesLastUpdated } from './IndexesLastUpdated';
import { MarketIndexProxy } from './MarketIndexProxy';
import { MarketResearchEmptyStateCard } from './MarketResearchEmptyStateCard';
import { MarketTrendsSection } from './MarketTrendsSection';

export const MarketTrends = () => {
  const { loading, data, error } = useMarketTrendsQuery({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return (
      <MarketTrendsSection>
        <Box mt={32}>
          <Spinner />
        </Box>
      </MarketTrendsSection>
    );
  }

  const markets = data?.viewer.markets;

  if ((error && !markets) || !markets?.indexProxies) {
    return (
      <MarketTrendsSection>
        <MarketResearchEmptyStateCard />
      </MarketTrendsSection>
    );
  }
  const { indexProxies } = markets;

  return (
    <MarketTrendsSection>
      <Card mb={16} minWidth={150}>
        {indexProxies.map((market: MarketIndexProxyFragment) => (
          <MarketIndexProxy market={market} key={market.proxy.id} />
        ))}
      </Card>
      <IndexesLastUpdated indexProxies={indexProxies} />
    </MarketTrendsSection>
  );
};
