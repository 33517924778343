export const SET_ORDER_ACTIONS = Object.freeze({
  BEGIN_SET_ORDER_FLOW: 'BEGIN_SET_ORDER_FLOW' as const,
  CHANGE_SET_ORDER_FLOW_STEP: 'CHANGE_SET_ORDER_FLOW_STEP' as const,
  CONFIRM_SET_ORDER: 'CONFIRM_SET_ORDER' as const,
  FINISHED_SET_ORDER_FLOW: 'FINISHED_SET_ORDER_FLOW' as const,
  UPDATE_SET_ORDER_BACK_BUTTON_CALLBACK:
    'UPDATE_SET_ORDER_BACK_BUTTON_CALLBACK' as const,
  CLICKED_ORDER_DIRECTION_TOGGLE: 'CLICKED_ORDER_DIRECTION_TOGGLE' as const,
});

export type ConfirmSetOrderAction = {
  payload: unknown;
  type: typeof SET_ORDER_ACTIONS.CONFIRM_SET_ORDER;
};

export type ClickedOrderDirectionToggleAction = {
  payload: unknown;
  type: typeof SET_ORDER_ACTIONS.CLICKED_ORDER_DIRECTION_TOGGLE;
};

export const confirmSetOrder = (
  payload: ConfirmSetOrderAction['payload'],
): ConfirmSetOrderAction => ({
  payload,
  type: SET_ORDER_ACTIONS.CONFIRM_SET_ORDER,
});

export const clickedOrderDirectionToggle = (
  payload: ClickedOrderDirectionToggleAction['payload'],
): ClickedOrderDirectionToggleAction => ({
  payload,
  type: SET_ORDER_ACTIONS.CLICKED_ORDER_DIRECTION_TOGGLE,
});

export type SetOrderAction =
  | ConfirmSetOrderAction
  | ClickedOrderDirectionToggleAction;
