import * as React from 'react';
import { Route } from 'react-router-dom';

import { CryptoScreener } from '~/pages/dashboard/research/crypto/components';

import { AddCryptoDetailsRoute } from './details';

export const AddCryptoRoute = () => {
  return (
    <Route path="crypto">
      {AddCryptoDetailsRoute()}
      <Route index element={<CryptoScreener />} />
    </Route>
  );
};
