import {
  Flex,
  PM,
  SemanticColorNames,
  ValuePropositionCard,
} from '@m1/liquid-react';
import type { IllustrationsType } from '@m1/liquid-react/illustrations';
import React from 'react';

import { AppImage, PrimaryValue, ValueRow } from '~/graphql/types';
import { AppTooltip } from '~/lens-toolbox/AppTooltip';

type LandingPageValuePropCardProps = {
  title?: string | null;
  primaryValue?: PrimaryValue | null;
  details?: ValueRow[] | null;
  illustration?: AppImage | null;
};

export const LandingPageValuePropCard = ({
  title,
  primaryValue,
  details,
  illustration,
}: LandingPageValuePropCardProps) => {
  return (
    <ValuePropositionCard
      title={title}
      primaryValue={{
        value: primaryValue?.value,
        valueColor: primaryValue?.valueColor as SemanticColorNames,
        description: (
          <Flex>
            <PM
              color={
                (primaryValue?.descriptionColor as SemanticColorNames) ??
                'foregroundNeutralSecondary'
              }
              fontWeight={400}
              content={primaryValue?.description}
            />
            {primaryValue?.tooltip && (
              <AppTooltip
                appTooltip={primaryValue?.tooltip}
                placement="bottom-start"
              />
            )}
          </Flex>
        ),
      }}
      details={details?.filter(Boolean).map((detail) => {
        return (
          <React.Fragment key={detail.label}>
            <Flex>
              <PM
                color={
                  (detail.labelColor as SemanticColorNames) ??
                  'foregroundNeutralSecondary'
                }
                fontWeight={400}
                content={detail.label}
              />
              {detail.tooltip && (
                <AppTooltip
                  appTooltip={detail.tooltip}
                  placement="bottom-start"
                />
              )}
            </Flex>
            <PM
              color={
                (detail.valueColor as SemanticColorNames) ??
                'foregroundNeutralMain'
              }
              content={detail.value}
            />
          </React.Fragment>
        );
      })}
      illustrationName={illustration?.names?.[0] as IllustrationsType}
    />
  );
};
