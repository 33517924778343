import { PM, useTheme, Box, Flex } from '@m1/liquid-react';
import * as React from 'react';

import { PieDetailsNodeFragment } from '~/graphql/types';

import { generateSliceFillColorsArray } from '~/utils/slices';

import { SliceById } from '../SliceById';

import {
  StyledSliceColor,
  StyledSliceContainer,
  StyledSliceListHeader,
} from './SliceList.styled';

type Props = {
  slices: PieDetailsNodeFragment['slices'];
  handleClickOnSlice?: (slice: any) => void;
};

export const SliceList = ({ slices, handleClickOnSlice }: Props) => {
  const { pieSliceColors } = useTheme();
  const sliceFillColorsArray = generateSliceFillColorsArray(
    pieSliceColors,
    slices.length,
  );
  return (
    <div>
      <StyledSliceListHeader pb={8}>
        <Box width="85%">
          <PM fontWeight={600} content="Name" />
        </Box>
        <Flex justifyContent="flex-end" width="15%">
          <PM fontWeight={600} content="Target" />
        </Flex>
      </StyledSliceListHeader>
      {slices.map((slice, index) => {
        return (
          <StyledSliceContainer key={index}>
            <StyledSliceColor
              css={{
                backgroundColor:
                  sliceFillColorsArray[index % sliceFillColorsArray.length] &&
                  sliceFillColorsArray[index % sliceFillColorsArray.length][
                    'active'
                  ],
              }}
            />

            <SliceById
              key={slice.to.id}
              to={slice.to}
              percentage={slice.percentage}
              onClick={handleClickOnSlice}
            />
          </StyledSliceContainer>
        );
      })}
    </div>
  );
};
