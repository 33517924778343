import { styled } from '@m1/liquid-react';
import React from 'react';

import { HistoricalSparklinesChart } from '~/components/charts';
import { PercentGain } from '~/components/percent-gain';
import { usePiePerformanceQuery } from '~/graphql/hooks';
import { PieBacktestPeriodEnum } from '~/graphql/types';

type PieMiniChartProps = {
  period?: PieBacktestPeriodEnum;
  pieId: string;
};

const PieChartContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const PieMiniChartAndReturn = ({
  period = PieBacktestPeriodEnum.OneYear,
  pieId,
}: PieMiniChartProps) => {
  const { data, loading, error } = usePiePerformanceQuery({
    variables: {
      period,
      pieId,
    },
  });

  if (loading || !data?.pie || !('analysis' in data.pie) || error) {
    return null;
  }

  const pieAnalysis = data.pie.analysis;

  const pieReturn = pieAnalysis?.backtesting?.valuePercentChange;

  if (!pieReturn) {
    return <PieChartContainer>--</PieChartContainer>;
  }

  const backtestingSeries = pieAnalysis.backtesting?.series || [];

  return (
    <PieChartContainer>
      <div>
        <HistoricalSparklinesChart
          data={backtestingSeries}
          x="time"
          y="value"
        />
      </div>
      <PercentGain value={pieReturn} />
    </PieChartContainer>
  );
};
