import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import { useCreditCardPageQuery } from '~/graphql/hooks';
import { DashboardNavigation } from '~/hooks/useDashboardNavigation';

import { TopNavLink } from './TopNavLink';

export const TopNavSpend = ({
  selectedNavigation,
}: {
  selectedNavigation: DashboardNavigation;
}) => {
  const { data } = useCreditCardPageQuery({
    variables: {
      keys: [
        'approvedCreditCardApplicationIds',
        'acceptedCreditCardApplicationIds',
      ],
    },
  });

  const credit = data?.viewer.credit;
  const hasClosedCreditCardAccount = credit?.hasClosedAccount ?? false;
  const hasActiveCreditCardAccount = credit?.hasActiveAccount ?? false;
  const hasSuspendedCreditCardAccount = credit?.hasSuspendedAccount ?? false;

  const hasCreditCardAccount =
    hasActiveCreditCardAccount ||
    hasClosedCreditCardAccount ||
    hasSuspendedCreditCardAccount;

  const items = hasCreditCardAccount
    ? [
        {
          id: 'spend-credit-transactions',
          to: '/d/spend/credit/transactions',
          label: 'Transactions',
        },
        {
          id: 'spend-credit-rewards',
          to: '/d/spend/credit/rewards',
          label: 'Rewards',
        },
      ]
    : [];

  return (
    <Flex flexDirection="row">
      {items.map((item) => {
        return (
          <TopNavLink
            selected={selectedNavigation.activeL3Nav === item.id}
            key={item.to}
            label={item.label}
            linkTo={item.to}
          />
        );
      })}
    </Flex>
  );
};
