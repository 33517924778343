// @ts-expect-error - TS2614 - Module '"redux-saga"' has no exported member 'Pattern'. Did you mean to use 'import Pattern from "redux-saga"' instead?
import { Pattern, SagaIterator } from 'redux-saga';
import { all, takeLatest } from 'redux-saga/effects';

type Watchers = Array<
  [Pattern, (action: Record<string, any>) => SagaIterator<void>]
>;
export function* initializeWatchers(watchers: Watchers): SagaIterator<void> {
  yield all(watchers.map(([pattern, saga]) => takeLatest(pattern, saga)));
}
