import { Button, ButtonProps, Flex, PM, Tooltip } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import { useEditPortfolioStepQuery } from '~/graphql/hooks';

import { UserKeysFragment } from '~/graphql/types';
import { PortfolioSlice } from '~/pie-trees';
import { useSelector } from '~/redux/hooks';

import { Spinner } from '~/toolbox/spinner';

import { TutorialModal } from '../Modals';
import { MoveSlicesDestinationMenu } from '../MoveSlicesDestinationMenu';

type MoveSlicesButtonProps = {
  disabled: boolean;
  portfolioSlicesOfSlicesMarkedForDeletion: Array<PortfolioSlice>;
  portfolioSlicesToMove: Array<PortfolioSlice>;

  tooltipText?: ReadonlyArray<string | null | undefined> | null | undefined;
  user: UserKeysFragment | null | undefined;
};

export const MoveSlicesButton = ({
  disabled,
  portfolioSlicesOfSlicesMarkedForDeletion,
  portfolioSlicesToMove,
  tooltipText,
  user,
}: MoveSlicesButtonProps) => {
  const activeAccountId = useSelector((state) => state.global.activeAccountId);

  const portfolioSliceIdsToMove = portfolioSlicesToMove.map(
    (portfolioSlice) => portfolioSlice.id,
  );

  const { loading, data, refetch } = useEditPortfolioStepQuery({
    variables: {
      activeAccountId: activeAccountId as string,
      portfolioSliceIdsToMove,
    },
  });

  const [showMoveSlicesTutorial, setShowMoveSlicesTutorial] =
    React.useState<boolean>(false);

  const shouldShowMoveSlicesTutorial = React.useMemo<boolean>(() => {
    const latestUserMoveValueClick = (user?.data || []).find(
      (flag) => flag.key === 'MOVE_VALUE_LATEST_CLICK',
    );

    const hasUserMovedValue = (user?.data || []).find(
      (flag) => flag.key === 'HAS_MOVED_VALUE',
    );

    const tutorialWasSeenMoreThan3MonthsAgo = latestUserMoveValueClick?.value
      ? moment(latestUserMoveValueClick.value).isBefore(
          moment().subtract(3, 'month'),
        )
      : false;

    return Boolean(
      !hasUserMovedValue &&
        (!latestUserMoveValueClick || tutorialWasSeenMoreThan3MonthsAgo),
    );
  }, [user]);

  const onMoveClick = React.useCallback(() => {
    if (shouldShowMoveSlicesTutorial && !data) {
      setShowMoveSlicesTutorial(true);
    }

    refetch();
  }, [setShowMoveSlicesTutorial, shouldShowMoveSlicesTutorial, refetch, data]);

  const moveSlices =
    data?.node?.__typename === 'Account' ? data.node.moveSlices : null;

  const buttonProps: ButtonProps = {
    disabled: disabled || Boolean(tooltipText),
    label: moveSlices?.moveSlicesLabel || 'Move',
    kind: 'secondary',
    size: 'medium',
  };

  if (buttonProps.disabled) {
    return (
      <Flex alignItems="center">
        <Button {...buttonProps} />
        {tooltipText?.length && (
          <Tooltip
            placement="bottom-end"
            icon="tooltip20"
            iconColor="primary"
            contentPadding="0px"
            body={
              <Flex
                alignItems="center"
                borderRadius={8}
                flexDirection="column"
                justifyContent="center"
                width={280}
              >
                {tooltipText.map((text, index) => (
                  <PM
                    key={index}
                    mb={index !== tooltipText.length - 1 ? 16 : 0}
                  >
                    {text}
                  </PM>
                ))}
              </Flex>
            }
          />
        )}
      </Flex>
    );
  }

  return (
    <Tooltip
      placement="bottom-end"
      icon="tooltip20"
      iconColor="primary"
      onShow={onMoveClick}
      trigger="click"
      contentPadding="0px"
      body={
        loading ? (
          <Flex alignItems="centered" width={335} height={296}>
            <Spinner />
          </Flex>
        ) : (
          <>
            <MoveSlicesDestinationMenu
              moveToPieContent={moveSlices?.moveToPieContent}
              portfolioSlicesOfSlicesMarkedForDeletion={
                portfolioSlicesOfSlicesMarkedForDeletion
              }
              setShowMoveSlicesTutorial={setShowMoveSlicesTutorial}
              slicesToMove={portfolioSlicesToMove}
            />
            <TutorialModal
              open={showMoveSlicesTutorial}
              moveSlices={moveSlices}
              setShowMoveSlicesTutorial={setShowMoveSlicesTutorial}
            />
          </>
        )
      }
    >
      <Flex alignItems="center">
        <Button {...buttonProps} onClick={onMoveClick} />
      </Flex>
    </Tooltip>
  );
};
