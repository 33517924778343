import { Flex, HXS, PL } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

export const EmptyFundingHistory = () => {
  return (
    <Flex flexDirection="column" alignItems="center">
      <Illustration name="mobileDocumentsEmptyState" />
      <HXS content="No funding history" pt={32} pb={4} />
      <PL
        content="There is no funding history available for this account."
        pt={32}
        pb={4}
      />
    </Flex>
  );
};
