import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  ACTION_TYPES,
  displayNotification,
  dismissNotifications,
} from '~/redux/actions';
import {
  NOTIFICATION_LOCATIONS,
  NOTIFICATION_TYPES,
  NOTIFICATION_BANNER_TIMEOUT,
} from '~/static-constants';
import { delay } from '~/utils';

// DEPRECATED: Use specific action creators instead.

// [redux-action-consolidate]
type DisplaySuccessNotificationAction = {
  payload: string;
  type: 'DISPLAY_ERROR_NOTIFICATION';
};

export function* displaySuccessNotificationSaga(): SagaIterator<void> {
  yield takeLatest(
    ACTION_TYPES.DISPLAY_SUCCESS_NOTIFICATION,
    function* (action: DisplaySuccessNotificationAction): SagaIterator<void> {
      yield put(
        displayNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          location: NOTIFICATION_LOCATIONS.SNACKBAR,
          message: action.payload,
        }),
      );

      yield call(delay, NOTIFICATION_BANNER_TIMEOUT);
      yield put(dismissNotifications());
    },
  );
}
