import { Button, HXS, PL } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import PropTypes from 'prop-types';
import * as React from 'react';

import { ConcreteError, SentryReporter } from '~/loggers';

type Props = {
  children: React.ReactNode;
  content: string | React.ReactNode;
  isRetryAllowed: boolean;
  onClickRetry?: (...args: Array<any>) => any;
  retryButtonLabel: string;
  title: string;
};

type State = {
  hasError: [Error, Record<string, any>] | null | undefined;
};

export class PageErrorBoundary extends React.Component<Props, State> {
  declare context: React.ContextType<React.Context<{ sentry: SentryReporter }>>;

  static defaultProps = {
    content:
      'There was a problem. Please try again later. If the problem persists, please contact support.',
    isRetryAllowed: false,
    retryButtonLabel: 'Try again',
    title: 'Oh no!',
  };

  static contextTypes = {
    sentry: PropTypes.object,
  };

  state = {
    hasError: null,
  };

  componentDidCatch(e: Error, info: Record<string, any>) {
    this.setState({
      hasError: [e, info],
    });
    if (!(e instanceof InvariantError)) {
      this.context.sentry.exception(e, {
        extra: info,
      });
    }
  }

  render() {
    return (
      <>{this.state.hasError ? this.renderErrorState() : this.props.children}</>
    );
  }

  renderErrorState() {
    return (
      <div
        style={{
          margin: '0 auto',
          maxWidth: 500,
          padding: '20px 0',
          textAlign: 'center',
        }}
      >
        {/* @ts-expect-error - TS2322 - Type '{ alt: string; width: number; name: "warningTriangle"; mt: number; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'. */}
        <Illustration alt="" width={216} name="warningTriangle" mt={32} />
        <HXS content={this.props.title} mt={32} />
        <PL content={this.props.content} mt={32} />
        {(this.props.isRetryAllowed ||
          typeof this.props.onClickRetry === 'function') && (
          <Button
            kind="primary"
            label={this.props.retryButtonLabel}
            mt={64}
            onClick={this.handleRetry}
            size="large"
          />
        )}
      </div>
    );
  }

  handleRetry = (): void => {
    if (typeof this.props.onClickRetry === 'function') {
      this.props.onClickRetry();
    } else {
      document.location.reload();
    }
  };
}

export class InvariantError extends ConcreteError {
  name = 'InvariantError';

  constructor(message: string, tags?: Array<[string, any]>) {
    super(message);
    if (tags) {
      this.tags = tags;
    }
  }
}
