import * as React from 'react';

import { useNavigate } from '~/hooks/useNavigate';

export const WaitlistPage = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    // Go directly to spend credit
    navigate({ to: '/d/spend/credit', options: { replace: true } });
  }, [navigate]);

  return null;
};
