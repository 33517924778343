import * as React from 'react';
import { Route } from 'react-router-dom';

import { InvestActivityExcludedBuysPage } from './components';
import { InvestActivityPage } from './InvestActivityPage';

export const ActivityDetailsRoute = () => {
  return (
    <>
      <Route
        path="activity/:activityId"
        handle={{
          fallbackRoute: {
            to: '/d/invest/activity',
          },
        }}
      >
        <Route
          path="activity-details"
          element={<InvestActivityPage />}
          handle={{
            fallbackRoute: {
              to: '/d/invest/activity',
            },
          }}
        />
        <Route
          path="excluded-buys"
          element={<InvestActivityExcludedBuysPage />}
        />
      </Route>
    </>
  );
};
