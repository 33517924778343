import { Flex, Box, Text, HM, Card } from '@m1/liquid-react';
import React from 'react';
import { change, InjectedFormProps } from 'redux-form';

import { DepositActionButtons } from '~/forms/FundAccountForm/DepositActionButtons';
import { DepositAmountInput } from '~/forms/FundAccountForm/DepositAmountInput';
import { DepositPillSection } from '~/forms/FundAccountForm/DepositPillSection';
import { RecurringDepositSection } from '~/forms/FundAccountForm/RecurringDepositSection';
import { connectForm } from '~/hocs';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useDispatch } from '~/redux/hooks';
import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';

//  Don't think these are dynamic just yet
const defaultPillAmounts = [500, 1000, 2000];

type SavingsInitialFundingFormComponentProps = InjectedFormProps<any> & {
  transferParticipantDescription: string;
  minimumInitialDeposit: number;
  maximumInitialDeposit: number;
  handleConfirmDeposit: () => void;
  onSkip: () => void;
};

const SavingsInitialFundingFormComponent = ({
  minimumInitialDeposit,
  transferParticipantDescription,
  maximumInitialDeposit,
  handleConfirmDeposit,
  onSkip,
}: SavingsInitialFundingFormComponentProps) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  const [isScheduleSwitchOn, setIsScheduleSwitchOn] =
    React.useState<boolean>(false);

  const onNextClick = () => {
    handleConfirmDeposit();
  };

  return (
    <Container width={550} mt={32}>
      <HM content="Make an initial deposit" mb={24} />
      <Box>
        <Card p={32}>
          <form>
            <Flex justifyContent="center" flexDirection="column" mb={48}>
              <Text
                content={transferParticipantDescription.toUpperCase()}
                color="foregroundNeutralSecondary"
                alignSelf="center"
                fontWeight={600}
              />

              <DepositAmountInput account={null} />
              <DepositPillSection
                transferAmounts={defaultPillAmounts}
                onClick={(amount) => {
                  analytics.recordEvent(
                    'm1_initial_funding_amount_pill_clicked',
                    null,
                    {
                      deposit_amount: amount?.toString() ?? '',
                    },
                  );

                  dispatch(change('fund-account', 'fundAccountAmount', amount));
                }}
              />
            </Flex>
            <Flex flexDirection="column">
              <RecurringDepositSection
                onChange={() => {
                  analytics.recordEvent('m1_toggle_click_frequency', null, {
                    toggle: !isScheduleSwitchOn ? 'on' : 'off',
                  });
                  setIsScheduleSwitchOn(!isScheduleSwitchOn);
                }}
                isScheduleSwitchOn={isScheduleSwitchOn}
              />
              {isScheduleSwitchOn && (
                <Link
                  to="https://m1.com/invest_ach_payment_auth_terms.pdf"
                  target="_blank"
                  mt={32}
                  style={{ textDecoration: 'underline' }}
                >
                  ACH Payment Terms
                </Link>
              )}
              <DepositActionButtons
                maximumDepositAmount={maximumInitialDeposit}
                minimumDepositAmount={minimumInitialDeposit}
                onNextClick={onNextClick}
                onSkip={onSkip}
                useOnboardingLayout
                showSkipCTA
              />
            </Flex>
          </form>
        </Card>
      </Box>
    </Container>
  );
};

export const SavingsInitialFundingForm = connectForm({
  form: 'fund-account',
})(SavingsInitialFundingFormComponent);
