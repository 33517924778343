import { Button, Flex, Card } from '@m1/liquid-react';
import React from 'react';

import { Spinner } from '~/toolbox/spinner';

import { AcatWizardBackButton } from './AcatWizardBackButton';
import { AcatWizardStepContainer } from './AcatWizardStepContainer';

export function AcatWizardLoadingPlaceholder() {
  return (
    <AcatWizardStepContainer>
      <AcatWizardBackButton />
      <Card p={72}>
        <Spinner />
      </Card>
      <Flex>
        <Button disabled label="Continue" onClick={() => {}} size="large" />
      </Flex>
    </AcatWizardStepContainer>
  );
}
