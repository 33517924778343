import {
  OnboardingIdentityFirstQuestion,
  OnboardingIdentityFirstQuestionType,
  InvestAccountOpeningQuestionType,
} from '~/graphql/types';

export const getNextQuestion = (
  currentQuestion:
    | OnboardingIdentityFirstQuestionType
    | InvestAccountOpeningQuestionType,
  allQuestions: Array<OnboardingIdentityFirstQuestion>,
): OnboardingIdentityFirstQuestionType | null =>
  allQuestions.filter(
    (question) => question.questionType === currentQuestion,
  )[0].nextQuestionType || null;
