import { Box, Button, Flex, HXXS } from '@m1/liquid-react';

import * as React from 'react';

import { BankImage } from '~/components/BankImage';
import { useNavigate } from '~/hooks/useNavigate';
import { useSearchParams } from '~/hooks/useSearchParams';
import { CONNECT_BANK_FLOW_STEPS } from '~/static-constants';
import { filterNilValues } from '~/utils/objects';

export const DefaultBankConnectionPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const connectionType = searchParams.get('type');

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      maxWidth={588}
      mt={72}
      mx="auto"
    >
      <Flex justifyContent="center" mb={64}>
        <BankImage />
      </Flex>
      <HXXS
        mb={48}
        content="Securely connect a bank with Plaid to fund your account. It’s easy."
      />
      <Flex
        flexDirection="column"
        justifyContent="center"
        maxWidth={240}
        mx="auto"
        mb={16}
      >
        <Box mx="auto" width={184}>
          <Button
            kind="primary"
            size="medium"
            fullWidth
            label="Connect a bank"
            onClick={() => {
              navigate({
                to: '/d/c/connect-bank',
                query: filterNilValues({
                  initialStep: CONNECT_BANK_FLOW_STEPS.SELECT_PLAID,
                  connectionType,
                  previousRouteName: '/d/spend/savings/transactions',
                }),
              });
            }}
            mt={32}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
