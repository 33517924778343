import { Modal } from '@m1/liquid-react';
import * as React from 'react';

import { connectModal } from '~/hocs';
import { ModalProp } from '~/redux/reducers/modals';

import { SharePieModal as SharePieModalContent } from './SharePieModal';

type Props = {
  modal: ModalProp<string>;
};

class SharePieModalComponent extends React.Component<Props> {
  render() {
    const {
      modal: { isOpened, payload },
    } = this.props;
    return (
      <Modal open={isOpened} onClose={this.handleCancel}>
        {payload && (
          <SharePieModalContent pieId={payload} onError={this.handleCancel} />
        )}
      </Modal>
    );
  }

  handleCancel = (): void => {
    this.props.modal.hide();
  };
}

const enhancer = connectModal('SHARE_PIE');

export const SharePieModal = enhancer(SharePieModalComponent) as any;
