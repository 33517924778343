export const STEPS = {
  // happy paths
  LANDING_PAGE: 'landing-page',
  LOAN_INFORMATION: 'loan-information',
  FINANCIAL_INFORMATION: 'financial-information',
  FINANCIAL_REVIEW: 'financial-review',
  LOAN_OFFERS_AND_SUBMIT: 'loan-offers',
  LOAN_TERMS_AND_ACCEPT: 'loan-terms',
  PROMPT_FOR_BANK: 'prompt-for-bank',
  DEPOSIT_INFO: 'deposit-info',
  AUTOPAY_ENROLLMENT: 'autopay-enrollment',
  LOAN_RECAP: 'loan-recap',
  // sad paths
  GENERAL_ERROR_PAGE: 'oops',
  APPLICATION_REJECTED: 'not-right-now',
  REMOVE_CREDIT_FREEZE: 'frozen-credit',
  NO_LINKED_ACCOUNT: 'no-linked-account',
  DISBURSEMENT_ERROR: 'disbursement-error',
  // others
  PROCESSING_OFFER_SUBMISSION: 'submitting',
  PROCESSING_RESUBMISSION: 'resubmitting',
  PROCESSING_APPLICATION: 'processing-application',
  APPLICATION_RECEIVED: 'application-received',
  INCOME_VERIFICATION: 'plaid-income-verification',
  PLAID_VERIFICATION_FLOW: 'plaid-verification-flow',
  MANUAL_REVIEW: 'manual-review',
  NOT_ELIGIBLE: 'not-eligible',
} as const;
