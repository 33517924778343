import { Box } from '@m1/liquid-react';
import * as React from 'react';
import { PlaidLinkResult } from 'react-plaid-link';

import { ConnectBankFlow } from '~/flows/components/connect-bank/ConnectBankFlow';
import { useWizardContext } from '~/flows/wizard';
import { useNavigate } from '~/hooks/useNavigate';
import { useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';

import { useInitialFundingFormContext } from '../hooks/useInitialFundingForm';

type ConnectExternalBankStepProps = {
  basePath: string;
};

type DataFromState = {
  accountId: string | null | undefined;
  plaidFailure: boolean;
  plaidPayload: Maybe<PlaidLinkResult>;
};

type OnFinishInput = {
  achRelationshipId?: Maybe<string>;
  route?: Maybe<string>;
  locationParams?: Maybe<Record<string, any>>;
  shouldClearToast?: boolean;
};

export const ConnectExternalBankStep = ({
  basePath,
}: ConnectExternalBankStepProps) => {
  const navigate = useNavigate();
  const { clearToast } = useToast();
  const { goTo } = useWizardContext();
  const { setValue } = useInitialFundingFormContext();

  const { accountId, plaidFailure, plaidPayload } = useSelector<DataFromState>(
    (state) => {
      return {
        accountId: state.newFlows.initialFunding.accountId,
        plaidFailure: state.newFlows.onboarding.plaidFailure,
        plaidPayload: state.newFlows.onboarding.plaidPayload,
      };
    },
  );

  const handleFinish = (input: OnFinishInput | undefined) => {
    if (input?.shouldClearToast) {
      clearToast();
    }
    if (input?.achRelationshipId) {
      setValue('achRelationshipId', input?.achRelationshipId);
      goTo('MAKE_INITIAL_DEPOSIT');
    } else if (input?.route) {
      navigate({
        to: input.route,
        query: input?.locationParams?.query,
        options: {
          state: input.locationParams?.state,
        },
      });
    } else {
      navigate({ to: '/d/home' });
    }
  };

  // TODO: Use the 1 column page template here instead of the Box.
  return (
    <Box width={550} margin="0 auto" paddingBottom={64}>
      <ConnectBankFlow
        connectionType="invest"
        accountId={accountId}
        allowSkipping
        basePath={basePath}
        onFinish={handleFinish}
        onPlaidExit={() => goTo('BEGIN_INITIAL_FUNDING')}
        plaidRedirect={plaidPayload}
        plaidFailure={plaidFailure}
        redirectUrl="/onboarding"
        isInitialFundingFlow
      />
    </Box>
  );
};
