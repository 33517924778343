import { PL } from '@m1/liquid-react';
import React from 'react';

import { Switch } from '~/toolbox/switch';

type RecurringDepositToggleProps = {
  onChange: () => void;
  isScheduleSwitchOn: boolean;
};

export const RecurringDepositToggle = ({
  isScheduleSwitchOn,
  onChange,
}: RecurringDepositToggleProps) => (
  <Switch
    checked={isScheduleSwitchOn}
    disabled={false}
    label={<PL fontWeight={600} content="Create a recurring deposit" />}
    onChange={onChange}
  />
);
