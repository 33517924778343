import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { CreditCardApplicationFlow } from '~/flows';
import { useNavigate } from '~/hooks/useNavigate';

export const CreditCardApplicationPage = () => {
  const navigate = useNavigate();
  const handleFinish = (): void => {
    navigate({
      to: '/d/spend/credit',
      options: { state: { forceRefetch: true } },
    });
  };
  return (
    <PageErrorBoundary onClickRetry={handleFinish} retryButtonLabel="Exit">
      <CreditCardApplicationFlow
        basePath="d/c/credit-card-application"
        onFinish={handleFinish}
      />
    </PageErrorBoundary>
  );
};
