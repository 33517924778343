export const MOVE_MONEY_ACTIONS = {
  SET_MOVE_MONEY_FROM: 'SET_MOVE_MONEY_FROM',
  SET_MOVE_MONEY_DIRECT_DEPOSIT_IDS: 'SET_MOVE_MONEY_DIRECT_DEPOSIT_IDS',
} as const;

export type SetMoveMoneyFromAction = {
  payload: string;
  type: typeof MOVE_MONEY_ACTIONS.SET_MOVE_MONEY_FROM;
};

export type SetMoveMoneyDirectDepositIds = {
  payload: {
    hysaIds: Maybe<string[]>;
    investIds: Maybe<string[]>;
  };
  type: typeof MOVE_MONEY_ACTIONS.SET_MOVE_MONEY_DIRECT_DEPOSIT_IDS;
};

export type BeginMoveMoneyFlowAction = {
  meta: {
    flow: 'MOVE_MONEY';
  };
  payload: {
    basePath: '/d/move-money';
    end: () => void;
    initialStep: string;
    isAutoPay: boolean;
    isCheckingDeposit: boolean;
    isFromCredit: boolean;
    isFromSpend: boolean;
    onFinish: () => void;
    onFinishStep: () => void;
    previousRouteName: '/d/c/transfer' | '/d/spend' | '/d/invest';
    step: 'd';
  };
  type: 'BEGIN_FLOW';
};

export type MoveMoneyAction =
  | BeginMoveMoneyFlowAction
  | SetMoveMoneyDirectDepositIds
  | SetMoveMoneyFromAction;
