/**
 * A util that builds a react router route path when given the path parts in an array.
 * @param {*} parts - An array of route parts ex. ['/', 'd', 'invest', 'portfolio']
 * @returns - Returns the final path/route after joining all the parts.
 */
export const buildRoute = (parts: Array<string | null | undefined>): string => {
  // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string'.
  return parts.filter(Boolean).reduce((path, currentPathSegment) => {
    // @ts-expect-error - TS2533 - Object is possibly 'null' or 'undefined'. | TS2533 - Object is possibly 'null' or 'undefined'.
    return path.endsWith('/') || currentPathSegment.startsWith('/')
      ? // @ts-expect-error - TS2533 - Object is possibly 'null' or 'undefined'. | TS2533 - Object is possibly 'null' or 'undefined'.
        path + currentPathSegment
      : path + `/${currentPathSegment}`;
  });
};
