import { Banner } from '@m1/liquid-react';
import * as React from 'react';

import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';

export const AccountAcatPausedBanner = () => {
  return (
    <Container>
      <Banner
        action={
          <Link
            to="https://help.m1.com/en/articles/9332012-how-do-i-transfer-a-brokerage-account-into-m1"
            target="_blank"
          >
            Account Transfer FAQ
          </Link>
        }
        content="We’ve received your account transfer! You will be sent an email containing required next steps within 1 business day."
        kind="alert"
        size="wide"
      />
    </Container>
  );
};
