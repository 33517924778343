import { Skeleton, SkeletonProvider } from '@m1/liquid-react';
import React from 'react';

import { LandingPageValuePropCard } from '~/components/LandingPageValuePropCard/LandingPageValuePropCard';
import { useCreditCardLandingPageValuePropCardQuery } from '~/graphql/hooks';
import { CreditCardLandingPageValuePropCardQuery } from '~/graphql/types';
import { StyledDetailsCardContainer } from '~/pages/dashboard/common/ProductLandingPageCard.styled';

export const CreditCardValuePropositionCard = ({
  accountId,
}: {
  accountId: string;
}) => {
  const { data, loading } = useCreditCardLandingPageValuePropCardQuery({
    variables: { id: accountId },
  });

  const node = data?.node as ExtractTypename<
    CreditCardLandingPageValuePropCardQuery['node'],
    'CreditCardAccount'
  >;

  const primaryValue =
    node?.creditCardProductLandingPage?.valuePropCard?.primaryValue;
  const title = node?.creditCardProductLandingPage?.valuePropCard?.title;
  const details = node?.creditCardProductLandingPage?.valuePropCard?.details;
  const illustration =
    node?.creditCardProductLandingPage?.valuePropCard?.illustration;

  return (
    <SkeletonProvider isLoading={loading}>
      <StyledDetailsCardContainer>
        <Skeleton skeletonWidth="100%" skeletonHeight={270}>
          <LandingPageValuePropCard
            title={title}
            primaryValue={primaryValue}
            details={details}
            illustration={illustration}
          />
        </Skeleton>
      </StyledDetailsCardContainer>
    </SkeletonProvider>
  );
};
