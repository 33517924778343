import {
  ChartableSliceDatumFragment,
  PortfolioSlicePerformancePeriodEnum,
} from '~/graphql/types';

import { StockChartPeriodDuration } from '../charts/StockChart/StockChart.types';

import { ChartableSliceDatumPoint } from './ChartableSliceChart.types';

const pointsArePoints = (point: any): point is ChartableSliceDatumPoint => {
  return point?.datum !== undefined;
};

export const sumDividends = (
  points: Array<ChartableSliceDatumPoint | ChartableSliceDatumFragment> | null,
) => {
  return (
    points?.reduce((sum: number, point) => {
      const dividends = pointsArePoints(point)
        ? point.datum?.sumDividends
        : point.sumDividends;
      return sum + (dividends ?? 0);
    }, 0) ?? 0
  );
};

export const sumTrades = (
  points: Array<ChartableSliceDatumPoint | ChartableSliceDatumFragment> | null,
) => {
  return (
    points?.reduce((sum: number, point) => {
      const trades = pointsArePoints(point)
        ? point.datum?.sumTrades
        : point.sumTrades;
      return sum + (trades ?? 0);
    }, 0) ?? 0
  );
};

export const periodDurationToPeriodEnum = (
  periodDuration: StockChartPeriodDuration,
) => {
  switch (periodDuration) {
    case 'ytd':
      return PortfolioSlicePerformancePeriodEnum.Ytd;
    case 'all':
      return PortfolioSlicePerformancePeriodEnum.Max;
    default: {
      const duration = parseInt(periodDuration, 10);
      if (isNaN(duration)) {
        // return all by default
        return PortfolioSlicePerformancePeriodEnum.Max;
      }
      const timePeriod = periodDuration.slice(-1);

      switch (timePeriod) {
        case 'd':
          return PortfolioSlicePerformancePeriodEnum.OneDay;
        case 'w':
          return PortfolioSlicePerformancePeriodEnum.OneWeek;
        case 'M':
          return periodDuration === '3M'
            ? PortfolioSlicePerformancePeriodEnum.OneQuarter
            : PortfolioSlicePerformancePeriodEnum.OneMonth;
        case 'y':
          return PortfolioSlicePerformancePeriodEnum.OneYear;
        default:
          // return all by default
          return PortfolioSlicePerformancePeriodEnum.Max;
      }
    }
  }
};
