import { Flex, PL, PM, PS, Card } from '@m1/liquid-react';
import moment from 'moment-timezone';
import * as React from 'react';

import { FundingSourceNodeFragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { useSearchParams } from '~/hooks/useSearchParams';
import { CONNECT_BANK_FLOW_STEPS } from '~/static-constants';
import { filterNilValues } from '~/utils/objects';

export const AvailableFundingSourceCard = ({
  handleActiveFundingSource,
  fundingSource,
}: {
  handleActiveFundingSource: (id: string) => void;
  fundingSource: FundingSourceNodeFragment;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const connectionType = searchParams.get('type');

  const previousRouteName =
    connectionType === 'savings' ? '/d/spend/savings/transactions' : null;

  const handlePendingFundingSource = (fundingSourceId: string): void => {
    navigate({
      to: '/d/c/connect-bank',
      query: filterNilValues({
        initialStep: CONNECT_BANK_FLOW_STEPS.SELECT_PLAID,
        connectionType,
        previousRouteName,
        fs: fundingSourceId,
      }),
    });
  };
  const handleCardClick = (fundingSource: FundingSourceNodeFragment) => {
    if (fundingSource.status === 'ACTIVE') {
      handleActiveFundingSource(fundingSource.id);
    } else if (
      fundingSource.status === 'PENDING' &&
      fundingSource.verificationType === 'PLAID_SAME_DAY_MICRODEPOSIT'
    ) {
      handlePendingFundingSource(fundingSource.id);
    }
  };

  let title;
  let subTitle;
  let actionText = null;
  if (fundingSource.status === 'ACTIVE') {
    title = fundingSource.externalName;
    subTitle = fundingSource.accountNumber;
    actionText = 'Connect';
  } else if (fundingSource.status === 'PENDING') {
    if (fundingSource.verificationType === 'PLAID_AUTOMATIC_MICRODEPOSIT') {
      title = 'Last updated';
      subTitle = moment(fundingSource.updatedAt).format('MM/DD/YY');
    }
    if (fundingSource.verificationType === 'PLAID_SAME_DAY_MICRODEPOSIT') {
      title = 'Last updated';
      subTitle = moment(fundingSource.updatedAt).format('MM/DD/YY');
      actionText = 'Verify deposit amounts';
    }
  }
  return (
    <Card
      my={12}
      px={16}
      py={8}
      backgroundColor="backgroundNeutralSecondary"
      onClick={() => handleCardClick(fundingSource)}
      data-testid="card"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex flexDirection="column">
          <PL content={title} />
          <Flex alignItems="center">
            <PM color="foregroundNeutralTertiary" content={subTitle} mr={8} />
          </Flex>
        </Flex>
        <Flex>{actionText && <PS color="primary" content={actionText} />}</Flex>
      </Flex>
    </Card>
  );
};
