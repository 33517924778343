import { styled } from '@m1/liquid-react';
import * as React from 'react';

const StyledClearButton = styled.svg<{
  children: React.ReactNode;
  viewBox: string;
}>`
  height: 16px;
  width: 16px;
  path {
    fill: ${(props) => props.theme.colors.primary};
  }
  circle {
    stroke: ${(props) => props.theme.colors.primary};
  }
`;

export const ClearButton = () => (
  <StyledClearButton viewBox="0 0 16 16">
    <circle cx="8" cy="8" r="7.375" fill="none" strokeWidth="1.25" />
    <path
      fillRule="evenodd"
      d="M5.239 6.68c-.346-.346-.346-.907 0-1.253.345-.346.906-.346 1.252 0l4.404 4.404c.346.346.346.907 0 1.253-.345.346-.906.346-1.252 0L5.239 6.679z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M9.552 5.426c.346-.346.907-.346 1.253 0 .346.346.346.907 0 1.253l-4.404 4.404c-.346.346-.907.346-1.253 0-.346-.346-.346-.907 0-1.252l4.404-4.405z"
      clipRule="evenodd"
    />
  </StyledClearButton>
);
