import { useThemeMode } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';

import { useRolloverTransactionsQuery } from '~/graphql/hooks';
import { useCapitalizeIframe } from '~/hooks/useCapitalizeIframe';
import { Spinner } from '~/toolbox/spinner';

export const RolloversPage = () => {
  const { data, loading } = useRolloverTransactionsQuery();
  const { iframeRef, hasCapitalizeError, handleIframeLoad } =
    useCapitalizeIframe();
  const { activeThemeMode } = useThemeMode();

  if (loading) {
    return <Spinner />;
  }

  if (
    !data?.viewer.transfers?.rollovers?.rolloverTransactions
      ?.transactionsWebviewUrl ||
    hasCapitalizeError
  ) {
    return <GenericSystemError />;
  }

  return (
    <iframe
      ref={iframeRef}
      src={`${
        data.viewer.transfers.rollovers.rolloverTransactions
          .transactionsWebviewUrl
      }&dark=${activeThemeMode === 'dark'}`}
      onLoad={handleIframeLoad}
      style={{ width: '100%', height: '100%', border: 'none' }}
    />
  );
};
