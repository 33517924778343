import { Box } from '@m1/liquid-react';
import {
  Illustration,
  type IllustrationProps,
  type IllustrationsType,
} from '@m1/liquid-react/illustrations';
import * as React from 'react';

import bankStyles from './BankImage.module.scss';

export type BankStatus = 'error' | 'locked' | 'pending' | 'verified';

type BankImageProps = {
  status?: BankStatus;
  style?: Record<string, any>;
} & Partial<IllustrationProps>;

const statusToIconMap: Record<BankStatus, IllustrationsType> = {
  error: 'bankConnectionError',
  locked: 'bankConnectionLocked',
  pending: 'bankConnectionPending',
  verified: 'bankConnectionSuccess',
};

export const BankImage = ({
  status,
  style,
  height = 330,
  width = '100%',
}: BankImageProps) => {
  const illustrationName: IllustrationsType = status
    ? statusToIconMap[status]
    : 'bankConnectionBank';

  return (
    <Box className={bankStyles.root} style={style}>
      <Illustration height={height} width={width} name={illustrationName} />
    </Box>
  );
};
