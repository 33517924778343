import { Box, Button, HXS, styled, Card } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useSmartTransferDetailsQuery } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

import { SmartTransferEnableSwitch } from '../SmartTransferEnableSwitch';
import { SmartTransferRuleActivity } from '../SmartTransferRuleActivity';
import { SmartTransferRuleSummary } from '../SmartTransferRuleSummary';

export type SmartTransferDetailsProps = {
  smartTransferRuleId: string;
};

const StyledSwitchBox = styled(Box)`
  border-top: 1px solid ${(props) => props.theme.colors.borderMain};
`;

export const SmartTransferDetails = ({
  smartTransferRuleId,
}: SmartTransferDetailsProps) => {
  const navigate = useNavigate();
  const { data, loading, refetch } = useSmartTransferDetailsQuery({
    variables: {
      smartTransferRuleId,
    },
  });

  if (loading) {
    return null;
  }
  const smartTransferRule =
    data?.node?.__typename === 'SmartTransferRule' ? data.node : null;
  if (!smartTransferRule) {
    return <GenericSystemError onClickRetry={refetch} />;
  }
  return (
    <>
      <HXS content="Smart Transfer details" mb={32} />
      <Card>
        <Box p={32}>
          <SmartTransferRuleSummary smartTransferRule={smartTransferRule} />
          <SmartTransferRuleActivity
            mt={32}
            smartTransferRule={smartTransferRule}
          />
        </Box>
        <StyledSwitchBox px={32} py={12}>
          <SmartTransferEnableSwitch smartTransferRule={smartTransferRule} />
        </StyledSwitchBox>
      </Card>
      <ButtonGroup mt={64}>
        <Button
          kind="secondary"
          label="Edit"
          onClick={() => {
            navigate({
              to: '/d/c/edit-smart-transfer/:smartTransferRuleId',
              params: { smartTransferRuleId },
            });
          }}
          size="large"
        />
        <Button
          kind="primary"
          label="Done"
          onClick={() => {
            navigate({ to: '/d/transfers/rules' });
          }}
          size="large"
        />
      </ButtonGroup>
    </>
  );
};
