import {
  styled,
  useLocalStorage,
  Color,
  Card,
  CardProps,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

type DismissibleCardProps = {
  iconColor?: Color;
  localStorageKey: string;
  onDismiss?: () => void;
} & CardProps;

const CloseIcon = styled(Icon)`
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.1s ease-in-out;
  z-index: 1;

  &:hover {
    opacity: 1;
  }
  position: absolute;
  top: 16px;
  right: 16px;
  color: ${(props) =>
    props?.color && props.theme.colors[props?.color as Color]};
`;

const StyledCard = styled(Card)`
  position: relative;
  background: ${(props) =>
    props?.backgroundColor &&
    props.theme.colors[props?.backgroundColor as Color]};
`;

export const DismissibleCard = ({
  localStorageKey,
  onDismiss,
  children,
  ...props
}: DismissibleCardProps) => {
  const [isDismissed, setIsDismissed] = useLocalStorage(localStorageKey, false);
  return isDismissed ? null : (
    <StyledCard {...props}>
      {children}

      <CloseIcon
        color={props?.iconColor || 'foregroundNeutralMain'}
        name="close24"
        onClick={(e) => {
          setIsDismissed(true);
          onDismiss && onDismiss();
          e.stopPropagation();
        }}
      />
    </StyledCard>
  );
};
