import * as React from 'react';
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { AppRoute } from '~/pages';

const router = createBrowserRouter(createRoutesFromElements(AppRoute()));

export const AppRouter = () => <RouterProvider router={router} />;
