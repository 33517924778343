import { Flex, PL, css, styled } from '@m1/liquid-react';
import * as React from 'react';

const StyledOptionBase = styled(Flex)(
  ({ theme }) => css`
    align-items: center;
    color: ${theme.colors.primary};
    cursor: pointer;
    justify-content: center;
    padding: 16px 0;

    &:hover,
    &:focus {
      color: ${theme.colors.primaryShade};
      border-color: ${theme.colors.primaryShade};
    }
  `,
);

const StyledPill = styled(StyledOptionBase)(
  ({ theme }) => css`
    border: 2px solid ${theme.colors.primary};
    border-radius: 24px;
    margin: 1rem;
    outline: none;
    padding: 1rem;
    user-select: none;
  `,
);

type IntroductionSourceOptionProps = {
  kind: 'pill' | 'plain';
  label: string;
  onClick: () => void;
};

export const IntroductionSourceOption = ({
  kind,
  label,
  onClick,
}: IntroductionSourceOptionProps) => {
  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.code === 'Space') {
      return onClick();
    }
  };

  const props = {
    tabIndex: 0,
    kind,
    onClick,
    onKeyDown,
  };

  return kind === 'plain' ? (
    <StyledOptionBase {...props}>
      <PL content={label} fontWeight={600} />
    </StyledOptionBase>
  ) : (
    <StyledPill {...props}>
      <PL content={label} fontWeight={600} />
    </StyledPill>
  );
};
