import { TwoColumn3by9 } from '@m1/liquid-react';
import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useRootProfileQuery } from '~/graphql/hooks';
import { Spinner } from '~/toolbox/spinner';

import { ProfileNavigation } from './components/ProfileNavigation';

export const ProfilePage = () => {
  const { loading, data } = useRootProfileQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  const viewer = data?.viewer;
  const profile = viewer?.profile;

  if (!viewer || !profile) {
    return (
      <GenericSystemError content="Your profile is currently unavailable." />
    );
  }

  return (
    <TwoColumn3by9>
      <TwoColumn3by9.Column1>
        <ProfileNavigation viewer={viewer} />
      </TwoColumn3by9.Column1>
      <TwoColumn3by9.Column2>
        <Outlet />
      </TwoColumn3by9.Column2>
    </TwoColumn3by9>
  );
};
