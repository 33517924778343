import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import {
  UserHasCompletedProfileDocument,
  UserHasCompletedProfileQueryResult,
} from '~/graphql/hooks';

import { apolloQuerySaga } from '../apolloQuerySaga';

export function* userHasIncompleteProfile(): SagaIterator<boolean> {
  const { data }: UserHasCompletedProfileQueryResult = yield call(
    apolloQuerySaga,
    {
      query: UserHasCompletedProfileDocument,
    },
  );

  if (!data?.viewer || !data.viewer.user) {
    return false;
  }

  const hasCompletedProfile = data.viewer?.user?.hasCompletedProfile;

  return !hasCompletedProfile;
}
