import { GraphQLRequest } from '@apollo/client';

import { MutationReauthenticateArgs, ReauthenticateMutation } from './types';

// Copied from src/remote/network-layer
const MUTATION_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

// Can move this generic type to another file later
// The query on GraphQLRequest is a document node.
// we need it to be a string to make the request

type CustomGraphQLRequest<T> = Omit<GraphQLRequest<T>, 'query'> & {
  query: string;
};

export const reauthenticate = async (
  refreshToken: string,
  uri: string,
): Promise<ReauthenticateMutation['reauthenticate']> => {
  const body: CustomGraphQLRequest<MutationReauthenticateArgs> = {
    query: `
      mutation Reauthenticate($input: ReauthenticateInput!) {
        reauthenticate(input: $input) {
          outcome {
            accessToken
            refreshToken
          }
        }
      }
    `,
    operationName: 'Reauthenticate',
    variables: { input: { refreshToken } },
  };

  try {
    const reauthenticateResponse = await fetch(uri, {
      method: 'POST',
      headers: MUTATION_HEADERS,
      body: JSON.stringify(body),
    });

    const authResult: { data: ReauthenticateMutation } =
      await reauthenticateResponse.json();

    return authResult.data.reauthenticate;
  } catch (error) {
    return null;
  }
};
