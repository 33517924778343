import { SagaIterator } from 'redux-saga';

import { call } from 'redux-saga/effects';

import { IDENTITY_FIRST_FLOW_STEPS as STEPS } from '~/static-constants';

import { changeStep } from '../../../utils';

export function* finishedChoosePie(action: {
  payload: string;
}): SagaIterator<void> {
  yield call(changeStep, STEPS.PIE_DETAILS, false, {
    query: {
      pieId: action.payload,
    },
  });
}
