import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { StepTemplate } from '~/flows/components/personal-loans/application/components/StepTemplate';
import { useManualReviewPageQuery } from '~/graphql/hooks';
import { AppImage } from '~/lens-toolbox/AppImage';
import { LinkableButton } from '~/lens-toolbox/LinkableButton';
import { Spinner } from '~/toolbox/spinner';

export const ManualReviewPage = () => {
  const { data, error, loading } = useManualReviewPageQuery();

  if (loading) {
    return <Spinner fullScreen centered />;
  }

  const { title, content, ctaLink, illustration } =
    data?.viewer.borrow?.personalLoans?.applicationFlow
      ?.loanManualReviewScreen ?? {};

  if (!title || error) {
    return <GenericSystemError />;
  }

  const illustrationAppImage = illustration && (
    <AppImage appImage={illustration} />
  );

  const button = ctaLink ? (
    <LinkableButton kind="primary" linkable={ctaLink} size="large" />
  ) : null;

  return (
    <StepTemplate
      title={title}
      additionalContent={content}
      button={button}
      appImage={illustrationAppImage}
    />
  );
};
