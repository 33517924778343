import { Flex, styled } from '@m1/liquid-react';
import * as React from 'react';

import { PORTFOLIO_EDITOR_STEPS } from '~/static-constants';

import {
  EditPortfolioStep,
  SourcePieStep,
  DestinationPieStep,
  ConfirmationScreenStep,
} from './steps';

type PortfolioEditorContainerProps = {
  onFinishStep: () => void;
  step: ValueOf<typeof PORTFOLIO_EDITOR_STEPS>;
};

const StyledPortfolioContainer = styled(Flex)`
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const PortfolioEditorContainer = ({
  onFinishStep,
  step,
  ...rest
}: PortfolioEditorContainerProps) => {
  const steps = {
    [PORTFOLIO_EDITOR_STEPS.EDIT_PORTFOLIO]: EditPortfolioStep,
    [PORTFOLIO_EDITOR_STEPS.DESTINATION_PIE]: DestinationPieStep,
    [PORTFOLIO_EDITOR_STEPS.SOURCE_PIE]: SourcePieStep,
    [PORTFOLIO_EDITOR_STEPS.CONFIRMATION_SCREEN]: ConfirmationScreenStep,
  };

  const Step = steps[step];
  return Step ? (
    <StyledPortfolioContainer>
      <Step onFinishStep={onFinishStep} {...rest} />
    </StyledPortfolioContainer>
  ) : null;
};
