import { Button, styled, theme } from '@m1/liquid-react';
import * as React from 'react';

type ButtonProps = React.ComponentProps<typeof Button>;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 100%;
  margin-bottom: 16px;

  @media screen and (min-width: ${theme.breakpoints.XXSMALL}) {
    width: auto;
    max-width: 384px;
  }
`;

export const ResponsiveButton = ({ ...rest }: ButtonProps) => {
  return <StyledButton {...rest} />;
};
