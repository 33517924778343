import { Box, styled } from '@m1/liquid-react';
import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { DiscoverSearch } from '~/components/research';
import { SecondaryHeader } from '~/components/secondary-header';
import { SecondaryNavigation } from '~/components/secondary-navigation';
import { resetSelectedSlices } from '~/graphql/local/reactiveVars/selectedSlices';
import { SliceableStatusEnum, SliceableTypeEnum } from '~/graphql/types';
import { useLayout } from '~/hooks/useLayout';
import { useLocation } from '~/hooks/useLocation';
import type { AppState } from '~/redux';
import { resetMode, updateMode } from '~/redux/actions';
import { useSelector, useDispatch } from '~/redux/hooks';
import { APP_MODES } from '~/static-constants';

import { StyledPageContent } from '../../navigation/Navigation.styled';

import { SliceableSelectBar } from './components';

type NavItem = {
  label: string;
  to: string;
};

const NON_CRYPTO_ITEMS: Array<NavItem> = [
  {
    to: '/d/c/add-slices/stocks',
    label: 'Stocks',
  },
  {
    to: '/d/c/add-slices/funds',
    label: 'Funds',
  },
  {
    to: '/d/c/add-slices/my-pies',
    label: 'My Pies',
  },
  {
    to: '/d/c/add-slices/model-portfolios',
    label: 'Model Portfolios',
  },
  {
    to: '/d/c/add-slices/watchlist',
    label: 'Your Watchlist',
  },
];

const CRYPTO_ITEMS: Array<NavItem> = [
  {
    to: '/d/c/add-slices/crypto',
    label: 'Crypto',
  },
  {
    to: '/d/c/add-slices/my-pies',
    label: 'My Pies',
  },
];

const StyledSecondaryHeader = styled(SecondaryHeader)`
  padding: 10px 0;
`;

const StyledContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AddSlicesPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isCrypto = useSelector(
    (state: AppState) =>
      state.portfolioOrganizer.isCrypto || state.pieEditor.isCrypto || false,
  );
  const { contentWidth } = useLayout();

  React.useEffect(() => {
    dispatch(updateMode(APP_MODES.ADD));

    return () => {
      resetSelectedSlices();
      dispatch(resetMode());
    };
  }, [dispatch]);

  const context =
    location.state?.context &&
    (location.state.context === 'NEW_ROOT_PIE' ||
      location.state.context === 'NEW_PIE')
      ? location.state.context
      : undefined;

  const onCryptoPath = location.pathname.includes('crypto');

  // TODO - how do we handle reloading when on the crypto add slices tab?
  const items = isCrypto || onCryptoPath ? CRYPTO_ITEMS : NON_CRYPTO_ITEMS;

  const fromPath = location.state?.from
    ? location.state.from
    : '/d/invest/portfolio-organizer';

  return (
    <>
      <StyledSecondaryHeader>
        <StyledPageContent contentWidth={contentWidth} withoutBottomPadding>
          <StyledContainer>
            <SecondaryNavigation
              items={items.map((item) => ({
                ...item,
                state: {
                  context,
                  from: fromPath,
                },
              }))}
            />
            <DiscoverSearch
              filterStatuses={[SliceableStatusEnum.Active]}
              filterTypes={
                isCrypto || onCryptoPath
                  ? [SliceableTypeEnum.Crypto]
                  : [
                      SliceableTypeEnum.EquitySecurity,
                      SliceableTypeEnum.FundSecurity,
                      SliceableTypeEnum.SystemPie,
                      SliceableTypeEnum.UserPie,
                    ]
              }
            />
          </StyledContainer>
        </StyledPageContent>
      </StyledSecondaryHeader>
      <StyledPageContent contentWidth={contentWidth}>
        <Outlet />
      </StyledPageContent>
      <SliceableSelectBar
        context={context}
        fromPath={fromPath}
        isCrypto={isCrypto || onCryptoPath}
      />
    </>
  );
};
