import { Button, PL } from '@m1/liquid-react';
import React from 'react';

import { useResendEmailVerificationMutation } from '~/graphql/hooks';
import { Spinner } from '~/toolbox/spinner';

export const ResendEmailVerificationButton = () => {
  const [resendEmailVerification, { loading, data, error }] =
    useResendEmailVerificationMutation({
      variables: {
        input: {},
      },
    });
  if (loading) {
    return <Spinner centered={false} radius={22} thickness={2} />;
  }

  if (error) {
    return <PL color="critical" content="Error!" fontWeight={600} />;
  }

  if (data?.resendEmailVerification?.didSucceed) {
    return <PL content="Email resent." fontWeight={600} />;
  }

  return (
    <Button
      kind="secondary"
      size="medium"
      label="Resend email"
      onClick={() => resendEmailVerification()}
    />
  );
};
