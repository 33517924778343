import { Box, Button, HXS, PL } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useOrderLockedStepQuery } from '~/graphql/hooks';
import {
  OrderStatusFragment,
  PortfolioSliceOrderLockReasonEnum,
} from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { useSelector } from '~/redux/hooks';
import { EXTERNAL_LINKS } from '~/static-constants';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

const LOCK_REASON_COPY_MAPPING: Partial<
  Record<PortfolioSliceOrderLockReasonEnum, string>
> = {
  LOCKED_BY_LIQUIDATION: `Placing an order is unavailable while your account is liquidating.`,
  LOCKED_BY_REBALANCE: `Placing an order is unavailable while there are pending rebalance requests. To place an order, you must first cancel all rebalance requests.`,
  LOCKED_BY_TRADING: `Trading for your account is currently in progress. Please try again after the trade window.`,
};

export const OrderLockedStep = () => {
  const portfolioSliceId = useSelector(
    (state) => state.newFlows.SET_ORDER.portfolioSliceId,
  );
  const navigate = useNavigate();

  const { data, loading } = useOrderLockedStepQuery({
    variables: {
      id: portfolioSliceId as string,
    },
    skip: !portfolioSliceId,
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.node) {
    return <GenericSystemError />;
  }

  const portfolioSlice = data.node as OrderStatusFragment;

  const lockReasonCopy = portfolioSlice.orderStatus?.lockReason
    ? LOCK_REASON_COPY_MAPPING[portfolioSlice.orderStatus.lockReason]
    : `Placing an order is currently unavailable. Please try again later.`;

  const handleClick = () => navigate({ to: '/d/invest/portfolio' });

  return (
    <Box>
      <Box pb={16}>
        <HXS content="Buy/Sell unavailable" />
      </Box>

      <Box pb={8}>
        <PL content={lockReasonCopy} />
      </Box>

      <Box pb={32}>
        <PL>
          <Link to={EXTERNAL_LINKS.ORDERS_FUNCTION_FAQ} target="_blank">
            Learn more about orders
          </Link>
        </PL>
      </Box>

      <Button
        label="OK"
        kind="primary"
        size="large"
        fullWidth
        onClick={handleClick}
      />
    </Box>
  );
};
