import { styled, Box } from '@m1/liquid-react';
import React from 'react';

type ModelPortfolioCategoryBackgroundProps = {
  featureUrl: string;
};

const StyledCategorySnapshotBackgroundRootBox = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--colors-background-neutral-main);
  transition: all 0.2s ease-in-out;
`;

const StyledCategorySnapshotBackgroundBox = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.4;
`;

export const CategoryCardBackground = ({
  featureUrl,
}: ModelPortfolioCategoryBackgroundProps) => (
  <StyledCategorySnapshotBackgroundRootBox>
    <StyledCategorySnapshotBackgroundBox
      style={{
        backgroundImage: `url(${featureUrl})`,
      }}
    />
  </StyledCategorySnapshotBackgroundRootBox>
);
