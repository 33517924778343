import { Button, PM, Flex } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { Pie, readPieTreeByPath } from '~/pie-trees';
import { useSelector, useDispatch } from '~/redux/hooks';
import { getActivePie } from '~/redux/reducers/newFlows/reducers/portfolioEditorReducer';

type BreadcrumbState = {
  path: Array<string>;
  pieTree: Pie | null | undefined;
};

export const EditorBreadcrumbs = () => {
  const dispatch = useDispatch();
  const { pieTree, path } = useSelector<BreadcrumbState>((state) => {
    const { path } = state.newFlows.PORTFOLIO_EDITOR;
    const pieTree = getActivePie(state.newFlows.PORTFOLIO_EDITOR);

    return {
      path,
      pieTree,
    };
  });

  const pies = React.useMemo(() => {
    const pies: Array<Pie> = [];

    if (pieTree) {
      // Base case for the "root" Pie in the tree.
      pies.push(pieTree);

      // For every other segment of the path, push the Pie at that location onto the array.
      if (path.length > 0) {
        for (let i = 1; i <= path.length; i++) {
          pies.push(readPieTreeByPath(pieTree, path.slice(0, i)));
        }
      }
    }

    return pies;
  }, [pieTree, path]);

  return (
    <Flex alignItems="baseline" flexDirection="row">
      {pies.map((pie, index) => {
        const name = pie.name || '';
        const truncatedName =
          name.length > 20 ? `${name.slice(0, 20) || ''}...` : name;

        if (index === pies.length - 1) {
          return <PM content={truncatedName} flex="1 0 auto" key={pie.__id} />;
        }

        return (
          <React.Fragment key={pie.__id}>
            <Button
              kind="link"
              label={truncatedName}
              size="large"
              style={{
                flex: '1 0 auto',
              }}
              onClick={() =>
                dispatch({
                  payload: path.slice(0, index),
                  type: 'CLICKED_PORTFOLIO_EDITOR_BREADCRUMB',
                })
              }
            />
            {index < pies.length && <Icon name="caretRight16" m="0 16px" />}
          </React.Fragment>
        );
      })}
    </Flex>
  );
};
