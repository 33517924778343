import { Pie } from './types';

export const readPieTreeByPath = (pie: Pie, path: Array<string>): Pie => {
  if (path.length > 0) {
    const foundIndex = pie.slices?.findIndex(
      (slice) => slice.to.__id === path[0],
    );

    if (
      typeof foundIndex === 'number' &&
      foundIndex >= 0 &&
      pie.slices &&
      (pie.slices[foundIndex].to.type === 'old_pie' ||
        pie.slices[foundIndex].to.type === 'new_pie')
    ) {
      const nextPie = pie.slices[foundIndex].to;
      return readPieTreeByPath(nextPie, path.slice(1));
    }
  }

  return pie;
};
