import { PL } from '@m1/liquid-react';
import * as React from 'react';

export const SwitchLabel = ({
  isAutoPay,
  isEnabled,
}: {
  isAutoPay: boolean;
  isEnabled: boolean;
}) => {
  if (isAutoPay) {
    return (
      <>
        AutoPay <PL fontWeight={600}>{isEnabled ? 'ON' : 'OFF'}</PL>
      </>
    );
  }
  return 'Enable schedule';
};
