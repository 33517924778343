export const ACCOUNT_SETUP_ACTIONS = Object.freeze({
  BEGIN_ACCOUNT_SETUP_FLOW: 'BEGIN_ACCOUNT_SETUP_FLOW' as const,
  CHANGE_ACCOUNT_SETUP_FLOW_STEP: 'CHANGE_ACCOUNT_SETUP_FLOW_STEP' as const,
  CLICKED_CANCEL_ACCOUNT_SETUP: 'CLICKED_CANCEL_ACCOUNT_SETUP' as const,
  ACCOUNT_SETUP_FLOW_CANCELED: 'ACCOUNT_SETUP_FLOW_CANCELED' as const,
  ACCOUNT_SETUP_FLOW_FINISHED: 'ACCOUNT_SETUP_FLOW_FINISHED' as const,
  SELECTED_ACCOUNT_TYPE: 'SELECTED_ACCOUNT_TYPE' as const,
  SELECTED_CUSTODIAL_ACCOUNT_TYPE: 'SELECTED_CUSTODIAL_ACCOUNT_TYPE' as const,
  SELECTED_RETIREMENT_ACCOUNT_TYPE: 'SELECTED_RETIREMENT_ACCOUNT_TYPE' as const,
  SELECTED_INDIVIDUAL_RETIREMENT_ACCOUNT_TYPE:
    'SELECTED_INDIVIDUAL_RETIREMENT_ACCOUNT_TYPE' as const,
  SELECTED_ROLLOVER_ACCOUNT_TYPE: 'SELECTED_ROLLOVER_ACCOUNT_TYPE' as const,
  SELECTED_OTHER_ACCOUNT_TYPE: 'SELECTED_OTHER_ACCOUNT_TYPE' as const,
  SUBMITTED_CONTACT_INFO_FORM: 'SUBMITTED_CONTACT_INFO_FORM' as const,
  SUBMITTED_CUSTODIAL_CONTACT_INFO_FORM:
    'SUBMITTED_CUSTODIAL_CONTACT_INFO_FORM' as const,
  SUBMITTED_IDENTITY_INFO_FORM: 'SUBMITTED_IDENTITY_INFO_FORM' as const,
  COLLECTED_HOLDER_SSN: 'COLLECTED_HOLDER_SSN' as const,
  EXPLAINED_PROFILE_COLLECTION: 'EXPLAINED_PROFILE_COLLECTION' as const,
  SUBMITTED_PROFILE_INPUT: 'SUBMITTED_PROFILE_INPUT' as const,
  SUBMITTED_INTRODUCTION_SOURCE: 'SUBMITTED_INTRODUCTION_SOURCE' as const,
  FINISHED_ACCOUNT_SETUP_REVIEW: 'FINISHED_ACCOUNT_SETUP_REVIEW' as const,
  FINISHED_READING_ROLLOVER_RECEIPT:
    'FINISHED_READING_ROLLOVER_RECEIPT' as const,
  FINISHED_READING_OTHER_ACCOUNT_RECEIPT:
    'FINISHED_READING_OTHER_ACCOUNT_RECEIPT' as const,
});

type ContactInfoForm = {
  firstName: string;
  homeAddress: {
    city: string;
    lineOne: string;
    lineTwo: string;
    postalCode: string;
    stateOrProvince: string;
  };
  lastName: string;
};

export type IdentityInfoForm = {
  countryOfCitizenship: string;
  dateOfBirth: string;
  employment?: {
    status: string;
    employer?: string | null;
    occupation?: string | null;
  };
};

export type AccountSetupFlowCanceledAction = {
  type: typeof ACCOUNT_SETUP_ACTIONS.ACCOUNT_SETUP_FLOW_CANCELED;
};

export type SubmittedContactInfoForm = {
  payload: {
    holder: string;
    values: {
      primary?: ContactInfoForm;
      secondary?: ContactInfoForm;
    };
  };
  type: typeof ACCOUNT_SETUP_ACTIONS.SUBMITTED_CONTACT_INFO_FORM;
};

export type SubmittedIdentityInfoForm = {
  payload: {
    holder: string;
    values: {
      primary?: IdentityInfoForm;
      secondary?: IdentityInfoForm;
    };
  };
  type: typeof ACCOUNT_SETUP_ACTIONS.SUBMITTED_IDENTITY_INFO_FORM;
};

export type CollectedHolderSsn = {
  payload: {
    holder: string;
    ssn: Record<string, any>;
  };
  type: typeof ACCOUNT_SETUP_ACTIONS.COLLECTED_HOLDER_SSN;
};

export type SubmittedProfileInputAction = {
  payload: {
    field: string;
    value: number | string;
  };
  type: typeof ACCOUNT_SETUP_ACTIONS.SUBMITTED_PROFILE_INPUT;
};

export type ExplainedProfileCollection = {
  payload: any;
  type: typeof ACCOUNT_SETUP_ACTIONS.EXPLAINED_PROFILE_COLLECTION;
};

export type FinishedAccountSetupReviewAction = {
  payload: {
    isCustodialAccount?: boolean;
    signature: string;
  };
  type: typeof ACCOUNT_SETUP_ACTIONS.FINISHED_ACCOUNT_SETUP_REVIEW;
};

export type ChangeAccountSetupFlowStepAction = {
  payload: string;
  type: typeof ACCOUNT_SETUP_ACTIONS.CHANGE_ACCOUNT_SETUP_FLOW_STEP;
};

// TODO: add payload types to all of these action types
export type BeginAccountSetupFlowAction = {
  payload: unknown;
  type: typeof ACCOUNT_SETUP_ACTIONS.BEGIN_ACCOUNT_SETUP_FLOW;
};

export type ClickedCancelAccountSetupAction = {
  payload: unknown;
  type: typeof ACCOUNT_SETUP_ACTIONS.CLICKED_CANCEL_ACCOUNT_SETUP;
};

export type AccountSetupFlowFinishedAction = {
  payload: unknown;
  type: typeof ACCOUNT_SETUP_ACTIONS.ACCOUNT_SETUP_FLOW_FINISHED;
};

export type SelectedAccountTypeAction = {
  payload: unknown;
  type: typeof ACCOUNT_SETUP_ACTIONS.SELECTED_ACCOUNT_TYPE;
};

export type SelectedRetirementAccountTypeAction = {
  payload: unknown;
  type: typeof ACCOUNT_SETUP_ACTIONS.SELECTED_RETIREMENT_ACCOUNT_TYPE;
};

export type SelectedIndividualRetirementAccountTypeAction = {
  payload: unknown;
  type: typeof ACCOUNT_SETUP_ACTIONS.SELECTED_INDIVIDUAL_RETIREMENT_ACCOUNT_TYPE;
};

export type SelectedRolloverAccountTypeAction = {
  payload: unknown;
  type: typeof ACCOUNT_SETUP_ACTIONS.SELECTED_ROLLOVER_ACCOUNT_TYPE;
};

export type SubmittedCustodialContactInfoFormAction = {
  payload: unknown;
  type: typeof ACCOUNT_SETUP_ACTIONS.SUBMITTED_CUSTODIAL_CONTACT_INFO_FORM;
};

export type SelectedOtherAccountTypeAction = {
  payload: unknown;
  type: typeof ACCOUNT_SETUP_ACTIONS.SELECTED_OTHER_ACCOUNT_TYPE;
};

export type SubmittedIntroductionSourceAction = {
  payload: unknown;
  type: typeof ACCOUNT_SETUP_ACTIONS.SUBMITTED_INTRODUCTION_SOURCE;
};

export type FinishedReadingRolloverReceiptAction = {
  payload: unknown;
  type: typeof ACCOUNT_SETUP_ACTIONS.FINISHED_READING_ROLLOVER_RECEIPT;
};

export type FinishedReadingOtherAccountReceiptAction = {
  payload: unknown;
  type: typeof ACCOUNT_SETUP_ACTIONS.FINISHED_READING_OTHER_ACCOUNT_RECEIPT;
};

export type AccountSetupLegacyAction =
  | AccountSetupFlowFinishedAction
  | BeginAccountSetupFlowAction
  | ChangeAccountSetupFlowStepAction
  | ClickedCancelAccountSetupAction
  | CollectedHolderSsn
  | ExplainedProfileCollection
  | FinishedAccountSetupReviewAction
  | FinishedReadingRolloverReceiptAction
  | FinishedReadingOtherAccountReceiptAction
  | SelectedAccountTypeAction
  | SelectedOtherAccountTypeAction
  | SelectedRetirementAccountTypeAction
  | SelectedIndividualRetirementAccountTypeAction
  | SelectedRolloverAccountTypeAction
  | SubmittedContactInfoForm
  | SubmittedIdentityInfoForm
  | SubmittedProfileInputAction
  | SubmittedCustodialContactInfoFormAction
  | SubmittedIntroductionSourceAction;
