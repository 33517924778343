import { PXL, HXL, PXS, Flex, styled } from '@m1/liquid-react';
import * as React from 'react';

import { useLaunchDarkly } from '~/hooks/useLaunchDarkly';
import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';

const StyledSignupFlex = styled(Flex)`
  height: 100%;
  .dark & {
    color: white;
  }
  @media screen and ${RESPONSIVE_BREAKPOINTS.SMALL} {
    margin-left: unset;
  }
`;

const StyledPXL = styled(PXL)`
  white-space: pre-line;
`;
const StyledPXS = styled(PXS)`
  white-space: pre-line;
`;

export const SignupMarketing = () => {
  const { didSucceed, flagResult } = useLaunchDarkly(
    'marketing-signup-content-partial',
    '',
  );
  const marketingContent = React.useMemo(() => {
    // Ensure feature flag result exists and was successfully captured
    if (didSucceed && flagResult) {
      // Try to parse flag result as JSON and handle any potential errors
      return JSON.parse(flagResult);
    }
    return null;
  }, [didSucceed, flagResult]);
  return (
    <StyledSignupFlex
      px={0}
      py={64}
      ml={64}
      justifyContent="center"
      maxWidth={540}
      color="secondary"
      flexDirection="column"
    >
      {marketingContent ? (
        <>
          <HXL content={marketingContent.title} />
          <StyledPXL fontWeight={400} mt={12}>
            {marketingContent.description}{' '}
          </StyledPXL>
          {marketingContent?.disclaimer && (
            <StyledPXS mt={12}>{marketingContent.disclaimer} </StyledPXS>
          )}
        </>
      ) : (
        <>
          <HXL content="Automated tools for elevated wealth building" />
          <PXL fontWeight={400} mt={12} maxWidth={460}>
            Long-term thinkers manage over $7 billion with M1. With M1, you can
            manage your wealth intelligently, while focusing on what's valuable
            over the long term.
          </PXL>
        </>
      )}
    </StyledSignupFlex>
  );
};
