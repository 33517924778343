import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { usePortfolioBannerQuery } from '~/graphql/hooks';
import { AccountBannerFragment } from '~/graphql/types';
import { InformationBanner } from '~/lens-toolbox/InformationBanner';
import { useSelector } from '~/redux/hooks';

import { AccountAcatPausedBanner } from './AccountAcatPausedBanner';
import { CustodialAccountMaturedBanner } from './CustodialAccountMaturedBanner';
import { InvalidPaymentsAccountBanner } from './InvalidPaymentsAccountBanner';
import { ReacceptLegalTermsBanner } from './ReacceptLegalTermsBanner';

export const PortfolioBannerContainer = () => {
  const accountId = useSelector((state) => {
    return state.global.activeAccountId;
  });

  const { data } = usePortfolioBannerQuery({
    variables: {
      accountId: accountId as string,
    },
    skip: !accountId,
    fetchPolicy: 'network-only',
  });

  if (!data) {
    return null;
  }

  const { viewer, node } = data;

  const account = node as AccountBannerFragment | null | undefined;

  let el = null;

  if (viewer?.user?.agreementStatus?.isNewAgreementRequired) {
    el = <ReacceptLegalTermsBanner />;
  } else if (account?.isRejected && account.informationalBanner) {
    el = <InformationBanner appBanner={account.informationalBanner} />;
  } else if (account?.hasBeneficiaryReachedAgeOfMaturity) {
    el = <CustodialAccountMaturedBanner />;
  } else if (viewer?.user?.billing.hasPendingCreditsButNoPaymentsAccount) {
    el = <InvalidPaymentsAccountBanner />;
  } else if (account?.isTradingPausedWithAcat) {
    el = <AccountAcatPausedBanner />;
  } else if (account?.informationalBanner) {
    el = <InformationBanner appBanner={account.informationalBanner} />;
  }

  return el ? (
    <Box my={32} mx="-1rem">
      {el}
    </Box>
  ) : null;
};
