import classNames from 'classnames';
import camelCase from 'lodash-es/camelCase';
import * as React from 'react';

import style from './style.module.scss';

type Direction = 'row' | 'row-reverse' | 'column' | 'column-reverse';

type Props = {
  as?: React.ElementType;
  children?: React.ReactNode;
  className?: string;
  lgAround?: boolean;

  lgBetween?: boolean;
  lgBottom?: boolean;
  lgCenter?: boolean;
  lgDirection?: Direction;

  lgEnd?: boolean;
  lgMiddle?: boolean;
  lgStart?: boolean;
  lgTextCenter?: boolean;
  lgTextLeft?: boolean;
  lgTextRight?: boolean;
  lgTop?: boolean;
  mdAround?: boolean;
  mdBetween?: boolean;
  mdBottom?: boolean;
  mdCenter?: boolean;
  mdDirection?: Direction;
  mdEnd?: boolean;
  mdMiddle?: boolean;
  mdStart?: boolean;
  mdTextCenter?: boolean;
  mdTextLeft?: boolean;
  mdTextRight?: boolean;
  mdTop?: boolean;
  smAround?: boolean;
  smBetween?: boolean;
  smBottom?: boolean;
  smCenter?: boolean;
  smDirection?: Direction;
  smEnd?: boolean;
  smMiddle?: boolean;
  smStart?: boolean;
  smTextCenter?: boolean;
  smTextLeft?: boolean;
  smTextRight?: boolean;
  smTop?: boolean;
  style?: Record<string, any>;
  // Row items are evenly distributed in the line,
  // first item is on the start line, last item on the end line.
  xsAround?: boolean;
  // Row items are evenly distributed in the line with equal space around them
  xsBetween?: boolean;
  // Align row items to the bottom
  xsBottom?: boolean;
  // Justify row items to the center
  xsCenter?: boolean;
  // flex-direction
  xsDirection?: Direction;
  // Justify row items to the end line
  xsEnd?: boolean;
  // Align row items to the middle (vertical alignment)
  xsMiddle?: boolean;
  // Justify row items to the starting line
  xsStart?: boolean;
  // Row items have text aligned center
  xsTextCenter?: boolean;
  // Row items have text aligned left
  xsTextLeft?: boolean;
  // Row items have text aligned right
  xsTextRight?: boolean;
  // Align row items to the top
  xsTop?: boolean;
};

export class Row extends React.Component<Props> {
  static defaultProps = {
    as: 'div',
  };

  render(): JSX.Element {
    const classes = {
      [style.root]: true,
    };
    const sizes = {
      lg: 'lg',
      md: 'md',
      sm: 'sm',
      xs: 'xs',
    };

    Object.keys(sizes).forEach((key) => {
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ lg: string; md: string; sm: string; xs: string; }'.
      const size = sizes[key];
      let prop = size;

      // Ex: <Row xsStart></Row>
      prop = size + 'Start';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop] >= 0) {
        classes[style[prop]] = true;
      }

      // Ex: <Row xsCenter></Row>
      prop = size + 'Center';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop] >= 0) {
        classes[style[prop]] = true;
      }

      // Ex: <Row xsEnd></Row>
      prop = size + 'End';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop] >= 0) {
        classes[style[prop]] = true;
      }

      // Ex: <Row xsTop></Row>
      prop = size + 'Top';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop] >= 0) {
        classes[style[prop]] = true;
      }

      // Ex: <Row xsMiddle></Row>
      prop = size + 'Middle';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop] >= 0) {
        classes[style[prop]] = true;
      }

      // Ex: <Row xsBottom></Row>
      prop = size + 'Bottom';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop] >= 0) {
        classes[style[prop]] = true;
      }

      // Ex: <Row xsAround></Row>
      prop = size + 'Around';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop] >= 0) {
        classes[style[prop]] = true;
      }

      // Ex: <Row xsBetween></Row>
      prop = size + 'Between';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop] >= 0) {
        classes[style[prop]] = true;
      }

      // Ex: <Row xsTextLeft></Row>
      prop = size + 'TextLeft';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop] >= 0) {
        classes[style[prop]] = true;
      }

      // Ex: <Row xsTextCenter></Row>
      prop = size + 'TextCenter';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop] >= 0) {
        classes[style[prop]] = true;
      }

      // Ex: <Row xsTextRight></Row>
      prop = size + 'TextRight';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop] >= 0) {
        classes[style[prop]] = true;
      }

      // This way of passing flex properties minimizes the number of component
      // props but makes it more complex to translate into more styles. Unsure
      // which approach is better. Ideally this could all be done in JS via
      // inline styles, but that'd require dealing with prefixing/breakpoints.
      prop = size + 'Direction';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop]) {
        // xsDirection="row-reverse" -> xsDirectionRowReverse
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
        const direction = camelCase(`${prop}-${this.props[prop]}`);
        classes[style[direction]] = true;
      }
    }, Row);

    // Extract all known props

    /* eslint-disable unused-imports/no-unused-imports,unused-imports/no-unused-vars */
    const {
      xsDirection,
      smDirection,
      mdDirection,
      lgDirection,
      xsStart,
      smStart,
      mdStart,
      lgStart,
      xsCenter,
      smCenter,
      mdCenter,
      lgCenter,
      xsEnd,
      smEnd,
      mdEnd,
      lgEnd,
      xsTop,
      smTop,
      mdTop,
      lgTop,
      xsMiddle,
      smMiddle,
      mdMiddle,
      lgMiddle,
      xsBottom,
      smBottom,
      mdBottom,
      lgBottom,
      xsAround,
      smAround,
      mdAround,
      lgAround,
      xsBetween,
      smBetween,
      mdBetween,
      lgBetween,
      xsTextLeft,
      smTextLeft,
      mdTextLeft,
      lgTextLeft,
      xsTextCenter,
      smTextCenter,
      mdTextCenter,
      lgTextCenter,
      xsTextRight,
      smTextRight,
      mdTextRight,
      lgTextRight,
      as,
      ...others
    } = this.props;

    return React.createElement(
      this.props.as ?? 'div',
      {
        ...others,
        className: classNames(this.props.className, classes),
      },
      this.props.children,
    );
  }
}
