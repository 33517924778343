import { Box, Flex, HXXS, PM, styled, Card } from '@m1/liquid-react';
import { Icon, type IconProps } from '@m1/liquid-react/icons';
import * as React from 'react';

import { ContactUsCard, ContactUsSectionCardFragment } from '~/graphql/types';
import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';

import { CardButton } from './CardButton';

type CardsSectionProps = {
  cards: ContactUsSectionCardFragment[];
};

const CardIconMappings: Record<ContactUsCard['identifier'], IconProps['name']> =
  {
    EMAIL_US: 'mail24',
    CALL_US: 'phone24',
    INSTANT_CHAT: 'help24',
    HELP_CENTER: 'help24',
  };

const StyledFlexCardsContainer = styled(Flex)`
  flex-wrap: wrap;
  justify-content: space-between;
  transform: translateY(-64px);

  @media screen and ${RESPONSIVE_BREAKPOINTS.MEDIUM} {
    justify-content: space-around;
  }
`;

export const CardsSection = ({ cards }: CardsSectionProps) => {
  return (
    <StyledFlexCardsContainer justifyContent="space-between">
      {cards &&
        cards.map(
          (card) =>
            card && (
              <Card
                backgroundColor="backgroundNeutralSecondary"
                borderColor="transparent"
                height={224}
                key={card.identifier}
                mt={16}
                px={12}
                py={12}
                width={275}
              >
                <Flex
                  flexDirection="column"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Box>
                    <Flex alignItems="center">
                      <Icon
                        color="primary"
                        name={CardIconMappings[card.identifier]}
                      />
                      <HXXS content={card.title} pl={8} />
                    </Flex>
                    {card.content?.map((content, i) => (
                      <PM content={content} key={i} pt={i === 0 ? 8 : 16} />
                    ))}
                  </Box>
                  <Box>
                    <CardButton card={card} />
                  </Box>
                </Flex>
              </Card>
            ),
        )}
    </StyledFlexCardsContainer>
  );
};
