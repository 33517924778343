import {
  BeginLoginFLowAction,
  ChangeLoginFlowStepAction,
  ClickedReviewLegalTermsAction,
  ConfirmAcceptLegalTermsAction,
  ConfirmAcceptLegalTermsOnLoginAction,
  LOGIN_ACTIONS,
  LoginAttemptFailedAction,
  Submit2faCodeFormAction,
  SubmitLoginFormAction,
} from './loginActions.types';

export const beginLoginFlow = (
  payload: BeginLoginFLowAction['payload'],
): BeginLoginFLowAction => ({
  payload,
  type: LOGIN_ACTIONS.BEGIN_LOGIN_FLOW,
});

export const loginAttemptFailed = (
  payload: LoginAttemptFailedAction['payload'],
): LoginAttemptFailedAction => ({
  payload,
  type: LOGIN_ACTIONS.LOGIN_ATTEMPT_FAILED,
});

export const changeLoginFlowStep = (
  payload: ChangeLoginFlowStepAction['payload'],
): ChangeLoginFlowStepAction => ({
  payload,
  type: LOGIN_ACTIONS.CHANGE_LOGIN_FLOW_STEP,
});

export const submitLoginForm = (
  payload: SubmitLoginFormAction['payload'],
): SubmitLoginFormAction => ({
  payload,
  type: LOGIN_ACTIONS.SUBMIT_LOGIN_FORM,
});

export const submit2faCodeForm = (
  payload: Submit2faCodeFormAction['payload'],
): Submit2faCodeFormAction => ({
  payload,
  type: LOGIN_ACTIONS.SUBMIT_2FA_CODE_FORM,
});

export const clickedReviewLegalTerms = (
  payload: ClickedReviewLegalTermsAction['payload'],
): ClickedReviewLegalTermsAction => ({
  payload,
  type: LOGIN_ACTIONS.CLICKED_REVIEW_LEGAL_TERMS,
});

export const confirmAcceptLegalTerms = (
  payload: ConfirmAcceptLegalTermsAction['payload'],
): ConfirmAcceptLegalTermsAction => ({
  payload,
  type: LOGIN_ACTIONS.CONFIRM_ACCEPT_LEGAL_TERMS,
});

export const confirmAcceptLegalTermsOnLogin = (
  payload: ConfirmAcceptLegalTermsOnLoginAction['payload'],
): ConfirmAcceptLegalTermsOnLoginAction => ({
  payload,
  type: LOGIN_ACTIONS.CONFIRM_ACCEPT_LEGAL_TERMS_ON_LOGIN,
});
