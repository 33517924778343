import { parseQuery } from './query';

// TODO(RRU): Deleted commented out route names when named routes are removed.

/**
  This is a complete alphabetical list of potential path names that can be returned from
  Lens. It will need to be updated as Lens adds additional path names that can be returned.
  See, https://m1finance.atlassian.net/l/c/fGMTUJ1T

  If a deep link does not have a corresponding named url to be used with react-router, map it to itself.

  Run `yarn sync-deep-links` to sync up web and lens automatically. Write your comments in this comment block only!

  Deep links can now handle dynamic query params. That means the keys do not need query params
  (it is assumed query params may or may not be present), and the value can be the react-router route name.

  @example ```
    // Old method:
    '/d/move-money?autoPay=true': '/d/move-money?autoPay=true',
    // New method (query params will be preserved):
    '/d/move-money': 'move-money',
  ```

  You must make sure that the given route handles query params that you want properly still!

  @todo Update waitlist mapping to 'spend-credit' after CC tab available
  @todo Remove query params from keys and values below
*/
export const deepLinkPaths = {
  '/d/accept-offer': '/d/accept-offer',
  '/d/bank-connection': '/d/bank-connection',
  '/d/borrow': '/d/borrow',
  '/d/borrow/margin': '/d/borrow/margin',
  '/d/borrow/margin/marketing': '/d/borrow/margin/marketing',
  '/d/borrow/marketing': '/d/borrow/marketing',
  '/d/borrow/personal/loan-application': '/d/borrow/personal/loan-application',
  '/d/borrow/personal/loan-application/no-linked-account':
    '/d/borrow/personal/loan-application/no-linked-account',
  '/d/borrow/personal/manage-loan': '/d/borrow/personal/manage-loan',
  '/d/borrow/personal/not-eligible': '/d/borrow/personal/not-eligible',
  '/d/borrow/personal/scra-status': '/d/borrow/personal/manage-loan',
  '/d/borrow/personal/transactions': '/d/borrow/personal/transactions',
  '/d/c/activate-debit-card': '/d/c/activate-debit-card',
  '/d/c/all-plus-benefits': '/d/c/all-plus-benefits',
  '/d/c/confirm-identity': '/d/c/confirm-identity',
  '/d/c/create-send-check/send-check-overview':
    '/d/c/create-send-check/send-check-overview',
  '/d/c/create-transfer': '/d/c/create-transfer',
  '/d/c/transfer': '/d/c/transfer',
  '/d/c/payment': '/d/c/payment',
  '/d/c/credit-card-application': '/d/c/credit-card-application',
  '/d/c/invest/document-upload': '/d/document-upload',
  '/d/c/open-account': '/d/c/open-account',
  '/d/c/plus-billing': '/d/c/plus-billing',
  '/d/c/resend-verification-email': '/d/c/resend-verification-email',
  '/d/contact-us': '/d/contact-us',
  '/d/crypto': '/d/crypto',
  '/d/document-upload': '/d/document-upload',
  '/d/home': '/d/home',
  '/d/invest/activity': '/d/invest/activity',
  '/d/invest/bank-connection': '/d/invest/bank-connection',
  '/d/invest/fund-account': '/d/invest/fund-account',
  '/d/invest/funding': '/d/invest/funding-history',
  '/d/invest/holdings': '/d/invest/holdings',
  '/d/invest/portfolio': '/d/invest/portfolio',
  '/d/invest/unmanaged-holdings': '/d/invest/unmanaged-holdings',
  '/d/move-money': '/d/move-money',
  '/d/open-invest-account': '/d/open-invest-account',
  '/d/plaid-redirect': '/d/plaid-redirect',
  '/d/plus': '/d/plus',
  '/d/referrals': '/d/referrals',
  '/d/research': '/d/research',
  '/d/research/expert-pies': '/d/research/model-portfolios',
  '/d/research/my-pies': '/d/research/my-pies',
  '/d/settings': '/d/settings',
  '/d/settings/documents': '/d/settings/documents',
  '/d/settings/notifications': '/d/settings/notifications',
  '/d/settings/payments': '/d/settings/payments',
  '/d/settings/profile': '/d/settings/profile',
  '/d/settings/security': '/d/settings/security',
  '/d/save': '/d/save',
  '/d/save/checking': '/d/save/checking',
  '/d/save/checking/debit-card': '/d/save/checking/debit-card',
  '/d/save/checking/direct-deposit': '/d/save/checking/direct-deposit',
  '/d/save/checking/transactions': '/d/save/checking/transactions',
  '/d/save/savings': '/d/save/savings',
  '/d/save/savings/transactions': '/d/save/savings/transactions',
  '/d/spend': '/d/spend',
  '/d/spend/checking': '/d/spend/checking',
  '/d/spend/checking/debit-card': '/d/spend/checking/debit-card',
  '/d/spend/checking/direct-deposit': '/d/spend/checking/direct-deposit',
  '/d/spend/checking/transactions': '/d/spend/checking/transactions',
  '/d/spend/credit': '/d/spend/credit',
  '/d/spend/credit/rewards': '/d/spend/credit/rewards',
  '/d/spend/credit/transactions': '/d/spend/credit/transactions',
  '/d/spend/debit-card': '/d/spend/checking/debit-card',
  '/d/spend/direct-deposit': '/d/spend/checking/direct-deposit',
  '/d/spend/savings/transactions': '/d/spend/savings/transactions',
  '/d/spend/transactions': '/d/spend/checking/transactions',
  '/d/transfers': '/d/transfers',
  '/d/w/acat-wizard': '/d/w/acat-wizard',
  '/d/waitlist': '/d/waitlist',
  '/login': '/login',
  '/savings-onboarding': '/savings-onboarding',
  '/onboarding/setup-invest-account': '/onboarding/setup-invest-account',
  '/onboarding/setup-ira-account': '/onboarding/setup-ira-account',
  '/onboarding/financial-details': '/onboarding/financial-details',
} as const satisfies Record<`/${string}`, string>;

type DeepLinkKeys = keyof typeof deepLinkPaths;

type PathAndQueries = {
  routeName: string;
  queryParams: Record<string, string> | undefined;
};

// Ideally the specific route names are a union type.
export function mapInternalPathToRouteName(
  internalPath: string,
): PathAndQueries {
  let queryParams: PathAndQueries['queryParams'];
  const [pathOnly, queries] = internalPath.split('?');

  // First try to match the path without query params, if not, fallback to full path with params
  const matchedPath =
    deepLinkPaths[pathOnly as DeepLinkKeys] ||
    deepLinkPaths[internalPath as DeepLinkKeys];

  if (queries && matchedPath) {
    queryParams = parseQuery(queries);
  }

  // Ensure we're returning a route _path_, not a route _name_:
  const nonRouteNamePath = (matchedPath ?? '').startsWith('/')
    ? matchedPath
    : internalPath;

  return { routeName: nonRouteNamePath, queryParams };
}
