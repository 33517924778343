import * as React from 'react';

import { beginRegisterUserFlow } from '~/redux/actions';
import { REGISTER_USER_FLOW_STEPS as STEPS } from '~/static-constants';

import { makeFlowComponent } from '../utils';

import { CollectUserDetails } from './steps/collect-user-details';
import { WarnMobileUsers } from './steps/warn-mobile-users';

export const RegisterUserFlow = makeFlowComponent({
  name: 'registerUser',
  begin: beginRegisterUserFlow,
  stepElements: {
    [STEPS.COLLECT_USER_DETAILS]: <CollectUserDetails autoFocus />,
    [STEPS.WARN_MOBILE_USERS]: <WarnMobileUsers />,
  },
});
