import { Flex, PL } from '@m1/liquid-react';
import * as React from 'react';

type MissingSecurityDataProps = {
  title: string;
};

export const MissingSecurityData = ({ title }: MissingSecurityDataProps) => {
  return (
    <Flex justifyContent="center" width="100%">
      <PL content={title} />
    </Flex>
  );
};
