import { Button } from '@m1/liquid-react';
import React from 'react';

import { exportToCsv } from './utils';

type DownloadCSVProps = {
  data?: Array<Array<string>>;
  filename: string;
  headers: Array<string>;
  label?: string;
};

export const DownloadCSV = ({
  label,
  headers,
  data,
  filename,
}: DownloadCSVProps) => {
  return (
    <Button
      kind="link"
      rightIcon="download24"
      label={label || 'Download CSV'}
      style={{ fontSize: '16px' }}
      onClick={() =>
        exportToCsv({
          filename: `${filename}.csv`,
          rows: data,
          headers,
        })
      }
    />
  );
};
