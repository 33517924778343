import { Flex, Image, PL } from '@m1/liquid-react';
import * as React from 'react';

import { CreditCardRewardsProgramFragment } from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';

export type RewardsTableCellComponentProps = {
  index: number;
  programType: string;
  rewardDetails: CreditCardRewardsProgramFragment | null | undefined;
};

export const RewardsTableCell = ({
  rewardDetails,
  programType,
  index,
}: RewardsTableCellComponentProps) => {
  if (!rewardDetails) {
    return null;
  }

  const rowContent = (
    <>
      <GridTable.Cell
        content={
          <Flex alignItems="center">
            {rewardDetails.securityLogoUrl && (
              <Image
                src={rewardDetails.securityLogoUrl}
                width={32}
                height={32}
                alt=""
              />
            )}
            <PL ml={16} content={rewardDetails.title} />
          </Flex>
        }
      />
      <GridTable.Cell textAlign="right" content={rewardDetails.value} />
    </>
  );

  if (!rewardDetails.creditCardRewardsDetailsScreenId) {
    return <GridTable.Row key={index}>{rowContent}</GridTable.Row>;
  }

  return (
    <GridTable.NavigableRow
      key={rewardDetails.creditCardRewardsDetailsScreenId}
      params={{
        programType,
        programId: rewardDetails.creditCardRewardsDetailsScreenId,
      }}
      to="/d/c/rewards/program/:programType/:programId"
    >
      {rowContent}
    </GridTable.NavigableRow>
  );
};
