import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { useUnmanagedHoldingsPageQuery } from '~/graphql/hooks';
import { useSelector } from '~/redux/hooks';
import { Container } from '~/toolbox/container';
import { Spinner } from '~/toolbox/spinner';

import { NoUnmanagedHoldings } from './components/NoUnmanagedHoldings';
import { UnmanagedHoldingsSection } from './components/UnmanagedHoldingsSection';
import { UnmanagedHoldingsSummaryCard } from './components/UnmanagedHoldingsSummaryCard';

const ContainerWrapperWithBackArrow = ({
  children,
}: React.PropsWithChildren<any>) => (
  <Container css="height: calc(100vh - 55px);">
    <BackArrow content="My Portfolio" mt={32} to="/d/invest/portfolio" />
    {children}
  </Container>
);

export const UnmanagedHoldingsPage = () => {
  const accountId = useSelector((state) => state.global.activeAccountId);

  const { data, loading } = useUnmanagedHoldingsPageQuery({
    variables: {
      accountId: accountId as string,
      first: 10,
    },
    fetchPolicy: 'network-only',
    skip: !accountId,
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (data?.node?.__typename !== 'Account') {
    return (
      <ContainerWrapperWithBackArrow>
        <GenericSystemError />
      </ContainerWrapperWithBackArrow>
    );
  }

  const account = data.node;

  const unmanagedHoldingsScreen =
    account.receivedSecurities?.unmanagedHoldingsScreen;

  const hasUnmanagedHoldings = Boolean(
    account.receivedSecurities?.unmanagedHoldingsScreen?.hasUnmanagedHoldings,
  );

  const unmanagedHoldingsCollections =
    account.receivedSecurities?.unmanagedHoldingsScreen
      ?.unmanagedHoldingsCollections;

  const hasNonZeroUnmanagedHoldingCollections = Boolean(
    account.receivedSecurities?.unmanagedHoldingsScreen
      ?.unmanagedHoldingsCollections?.length,
  );

  if (
    !unmanagedHoldingsScreen ||
    !hasUnmanagedHoldings ||
    !unmanagedHoldingsCollections ||
    !hasNonZeroUnmanagedHoldingCollections
  ) {
    return (
      <ContainerWrapperWithBackArrow>
        <NoUnmanagedHoldings />
      </ContainerWrapperWithBackArrow>
    );
  }

  return (
    <ContainerWrapperWithBackArrow>
      <Flex flexDirection="column" width="100%">
        <UnmanagedHoldingsSummaryCard
          isTradingWindowInProgress={Boolean(account.trading?.isInProgress)}
          unmanagedHoldingsScreen={unmanagedHoldingsScreen}
        />
        {unmanagedHoldingsCollections.map(
          (unmanagedHoldingCollection, index) =>
            unmanagedHoldingCollection && (
              <UnmanagedHoldingsSection
                key={index}
                collectionId={unmanagedHoldingCollection.id}
                isAccountTradingInProgress={Boolean(
                  account.trading?.isInProgress,
                )}
              />
            ),
        )}
      </Flex>
    </ContainerWrapperWithBackArrow>
  );
};
