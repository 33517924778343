import * as React from 'react';

import { getEnvironment } from '~/hooks/useEnvironment';

import { RenderFailurePage } from './components/render-failure-page';
import { useOnMount } from './hooks/useOnMount';
import { useSelector } from './redux/hooks/useSelector';
import { AppRouter } from './router';

export const App = () => {
  const loadState = useSelector((state) => state.global.loadState);
  const [children, setChildren] = React.useState<any>(null);

  useOnMount(() => {
    // Reset graphql endpoint for local development environment selector
    const isProduction =
      __NODE_ENV__ === 'production' &&
      (__ENV__ === 'production' || __ENV__ === 'beta');

    if (!isProduction) {
      const env = getEnvironment();
      window.config.graphql.endpoint = env.value;
      const configChangeEvent = new Event('graphqlChange');
      window.dispatchEvent(configChangeEvent);
    }
  });

  React.useEffect(() => {
    if (loadState && loadState.done) {
      setChildren(AppRouter);
    } else if (loadState && loadState.error) {
      // Render failure page when loadState.error is true
      setChildren(<RenderFailurePage />);
    }
  }, [loadState]);

  return children;
};
