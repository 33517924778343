import * as React from 'react';
import { Route } from 'react-router-dom';

import { PageErrorBoundary } from '~/components/page-error-boundary';

import { DividendManagementPage } from './DividendManagementPage';

export const InvestAutomationRoutes = () => {
  return (
    <Route
      path="automation/dividend-management"
      element={
        <PageErrorBoundary>
          <DividendManagementPage />
        </PageErrorBoundary>
      }
    />
  );
};
