import * as React from 'react';
import { Route } from 'react-router-dom';

import { PortfolioDividendsRoute } from '../portfolio/PortfolioDividendsPageRoute';

import { HoldingsPage } from './HoldingsPage';
import { PositionDetailsRoute } from './position-details';

export const HoldingRoute = () => {
  return (
    <Route path="holdings" element={<HoldingsPage />}>
      {PositionDetailsRoute()}
      {PortfolioDividendsRoute()}
    </Route>
  );
};
