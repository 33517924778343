import { Flex, PM, Tooltip, TooltipProps } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import React from 'react';

import { useSelector } from '~/redux/hooks';

type CostPopupProps = Omit<TooltipProps, 'body'> & {
  label: string;
};

export const PartialTotalInvestmentsCostPopup = ({
  label,
  ...rest
}: CostPopupProps) => {
  const isCrypto = useSelector<boolean>(
    (state) => state.global.activeAccountIsCrypto,
  );

  if (isCrypto) {
    return null;
  }

  const content =
    'The exact cost for this holding is not currently known because of an action in the symbol. The most common reasons for cost to be unknown is from stock splits, company mergers, or account transfers. Your account will continue to trade as normal and your cost will be up to date in the next few days.';

  return (
    <Tooltip body={<PM content={content} />} {...rest}>
      <Flex alignItems="center" gap={4}>
        {label}
        <Icon name="tooltip20" color="primary" />
      </Flex>
    </Tooltip>
  );
};
