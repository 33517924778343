import {
  SmartTransferBalanceTriggerInput,
  // @ts-expect-error - TS2724 - '"~/graphql/types"' has no exported member named 'SmartTransferFulfillmentConditionTypeEnumValues'. Did you mean 'SmartTransferFulfillmentConditionEditRequirements'?
  SmartTransferFulfillmentConditionTypeEnumValues,
  // @ts-expect-error - TS2305 - Module '"~/graphql/types"' has no exported member 'SmartTransferFundingTimePeriodEnumValues'.
  SmartTransferFundingTimePeriodEnumValues,
} from '~/graphql/types';

export const SMART_TRANSFER_ACTIONS = Object.freeze({
  FINISHED_EDITING_SMART_TRANSFER: 'FINISHED_EDITING_SMART_TRANSFER' as const,
  FINISHED_EDITING_TRANSFER_SCHEDULE:
    'FINISHED_EDITING_TRANSFER_SCHEDULE' as const,
  SET_SMART_TRANSFER_FULFILLMENT_CONDITION_MODE:
    'SET_SMART_TRANSFER_FULFILLMENT_CONDITION_MODE' as const,
  SET_SMART_TRANSFER_FULFILLMENT_CONDITIONS:
    'SET_SMART_TRANSFER_FULFILLMENT_CONDITIONS' as const,
});

export type SmartTransferFulfillmentConditionInput = {
  borrowAmount: number | null | undefined;
  borrowUtilization: number | null | undefined;
  capAmount: number | null | undefined;
  fulfillmentConditionType: SmartTransferFulfillmentConditionTypeEnumValues;
  fundingAmount: number | null | undefined;
  fundingPeriod: SmartTransferFundingTimePeriodEnumValues | null | undefined;
};

export type FinishedEditingSmartTransferAction = {
  payload:
    | string
    | {
        balanceTrigger: SmartTransferBalanceTriggerInput;
        contraParticipantEntries: Array<{
          contraParticipantId: string | null | undefined;
          existingContraParticipantEntryId: string | null | undefined;
          fulfillmentCondition: {
            borrowAmount: number | null | undefined;
            borrowUtilization: number | null | undefined;
            capAmount: number | null | undefined;
            fulfillmentConditionType:
              | 'CASH_BALANCE_CAP'
              | 'CREDIT_BORROWED_CAP'
              | 'CREDIT_UTILIZATION_CAP'
              | 'FUNDING_PER_PERIOD'
              | 'INDEFINITE'
              | 'IRA_YEARLY_FUNDING'
              | 'IRA_WEEKLY_FUNDING_PER_PERIOD'
              | 'IRA_MONTHLY_FUNDING_PER_PERIOD'
              | 'IRA_QUARTERLY_FUNDING_PER_PERIOD';
            fundingAmount: number | null | undefined;
            fundingPeriod:
              | ('WEEK' | 'MONTH' | 'QUARTER' | 'YEAR')
              | null
              | undefined;
          };
        }>;
        existingSmartTransferRuleId: string | null | undefined;
        focusParticipantId: string | null | undefined;
      };
  type: typeof SMART_TRANSFER_ACTIONS.FINISHED_EDITING_SMART_TRANSFER;
};

export type SetSmartTransferFulfillmentConditionModeAction = {
  payload: 'ADD' | 'EDIT';
  type: typeof SMART_TRANSFER_ACTIONS.SET_SMART_TRANSFER_FULFILLMENT_CONDITION_MODE;
};

export type SetSmartTransferFulfillmentConditionAction = {
  payload: SmartTransferFulfillmentConditionInput;
  type: typeof SMART_TRANSFER_ACTIONS.SET_SMART_TRANSFER_FULFILLMENT_CONDITIONS;
};

export type FinishedEditingTransferScheduleAction = {
  payload: unknown;
  type: typeof SMART_TRANSFER_ACTIONS.FINISHED_EDITING_TRANSFER_SCHEDULE;
};

export type SmartTransferAction =
  | FinishedEditingSmartTransferAction
  | FinishedEditingTransferScheduleAction
  | SetSmartTransferFulfillmentConditionModeAction
  | SetSmartTransferFulfillmentConditionAction;
