import * as React from 'react';
import { Route } from 'react-router-dom';

import { Spinner } from '~/toolbox/spinner';

import { VerifyEmailFailure } from './components/VerifyEmailFailure';
import { VerifyEmailSuccess } from './components/VerifyEmailSuccess';
import { VerifyEmailPage } from './VerifyEmailPage';

export const VerifyEmailRoute = () => {
  return (
    <Route
      path="verify-email/:verificationToken?"
      element={<VerifyEmailPage />}
    >
      <Route index element={<Spinner />} />
      <Route path="success" element={<VerifyEmailSuccess />} />
      <Route path="error" element={<VerifyEmailFailure />} />
    </Route>
  );
};
