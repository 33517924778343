import { Flex, styled } from '@m1/liquid-react';

import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';

export const StyledFilterFlex = styled(Flex)`
  justify-content: flex-end;
  flex-grow: 1;
  flex-wrap: wrap;
  margin-bottom: 16px;
  > * {
    margin: 4px 0 4px 12px;
  }
  > :last-child {
    margin: 8px 0 0px 12px;
  }

  @media screen and ${RESPONSIVE_BREAKPOINTS.TABLET} {
    justify-content: center;
  }
`;

export const StyledFilterByTypeOptionRoot = styled(Flex)`
  padding: 4px 0;

  align-items: center;
  justify-content: space-between;

  > :last-child {
    visibility: hidden;
  }

  &:hover :last-child {
    visibility: visible;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;
