import React from 'react';

import { ScreenerFilterContainer } from '~/components/research/Screener';

import { StockLimits } from './StockLimits';
import { StockScreenerCategories } from './StockScreenerCategories';
import { useStockScreenerResult } from './StockScreenerProvider';

type StockFilterContainerProps = {
  setFiltersOpen: React.Dispatch<React.SetStateAction<boolean>>;
  filtersOpen: boolean;
};

export const StockFilterContainer = ({
  setFiltersOpen,
  filtersOpen,
}: StockFilterContainerProps) => {
  const { handleReset, hasFilters } = useStockScreenerResult();

  return (
    <ScreenerFilterContainer
      handleReset={handleReset}
      hasFilters={hasFilters}
      filtersOpen={filtersOpen}
      setFiltersOpen={setFiltersOpen}
    >
      <StockLimits />
      <StockScreenerCategories />
    </ScreenerFilterContainer>
  );
};
