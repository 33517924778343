import { Button } from '@m1/liquid-react';
import React from 'react';

import { ButtonGroup } from '~/toolbox/ButtonGroup';

type PaginationButtonGroupProps = {
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
};

export const PaginationButtonGroup = (props: PaginationButtonGroupProps) => {
  const { handleNextPage, handlePreviousPage, hasPreviousPage, hasNextPage } =
    props;

  return (
    <ButtonGroup>
      <Button
        label="Previous"
        kind="secondary"
        size="small"
        disabled={!hasPreviousPage}
        onClick={handlePreviousPage}
      />
      <Button
        label="Next"
        kind="secondary"
        size="small"
        disabled={!hasNextPage}
        onClick={handleNextPage}
      />
    </ButtonGroup>
  );
};
