import * as React from 'react';

import { logout } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

export const LogoutPage = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  return <Spinner fullScreen />;
};
