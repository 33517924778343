import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
  PL,
  PS,
} from '@m1/liquid-react';
import * as React from 'react';

import { useIraContributionYearsQuery } from '~/graphql/hooks';

import { formatNumberWithCommas } from '~/utils';

import style from './style.module.scss';

type RetirementAccountComparisonModalProps = {
  content: string;
};

export const RetirementAccountComparisonModal = ({
  content,
}: RetirementAccountComparisonModalProps) => {
  const { data } = useIraContributionYearsQuery();
  const currentYear = data?.viewer.irsRegulations.iraContributionYears.find(
    ({ isCurrent }) => isCurrent,
  );
  // Loading state shouldn't be a problem here.
  // The query runs when the link renders, but the limits
  // don't show until the user clicks to open the modal.
  const limitUnder50 = formatNumberWithCommas(currentYear?.limitUnder50);
  const limitOver50 = formatNumberWithCommas(currentYear?.limitOver50);
  const maximumContribution = `$${limitUnder50 ?? '--'} ($${limitOver50 ?? '--'} if you are over 50)`;

  return (
    <Modal trigger={<Button kind="link" label={content} />}>
      <ModalContent width="wide">
        <ModalTitle>M1 Retirement Accounts</ModalTitle>
        <PL color="foregroundNeutralMain">
          Traditional and Roth are the two largest IRAs (individual retirements
          account). Both can help you save for retirement by providing tax
          advantages.
        </PL>
        <PL color="foregroundNeutralMain">
          Before opening a M1 Finance retirement account take a look at the
          differences below. If you plan on transferring over your existing
          retirement account, make sure it is the same type.
        </PL>

        <table className={style.table}>
          <thead>
            <tr>
              {/* @ts-expect-error - TS2322 - Type '{ children: string; width: string; }' is not assignable to type 'DetailedHTMLProps<ThHTMLAttributes<HTMLTableHeaderCellElement>, HTMLTableHeaderCellElement>'. */}
              <th width="40%">Traditional</th>
              {/* @ts-expect-error - TS2322 - Type '{ children: string; width: string; }' is not assignable to type 'DetailedHTMLProps<ThHTMLAttributes<HTMLTableHeaderCellElement>, HTMLTableHeaderCellElement>'. */}
              <th width="40%">Roth</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <PS color="foregroundNeutralMain">
                  Allows people to make pre-tax contributions, it's built for
                  people who expect to be in the same or lower tax bracket in
                  the future
                </PS>
              </td>
              <td>
                <PS color="foregroundNeutralMain">
                  Allows people to make after tax contributions, it's built for
                  people who expect to be in a higher tax bracket in the future
                </PS>
              </td>
            </tr>
            <tr>
              <td>
                <PS color="foregroundNeutralMain">
                  <b>Growth:</b> Tax-Deferred
                  <br />
                  <b>Deductible Contributions:</b> Yes
                </PS>
              </td>
              <td>
                <PS color="foregroundNeutralMain">
                  <b>Growth:</b> Tax-Free
                  <br />
                  <b>Deductible Contributions:</b> No
                </PS>
              </td>
            </tr>
            <tr>
              <td>
                <PS color="foregroundNeutralMain">
                  <b>Contributions:</b> Before-Tax Dollars
                  <br />
                  <b>Maximum:</b> {maximumContribution}
                  <br />
                  <b>Income Restrictions:</b> Anyone with income
                  <br />
                  <b>Age Limit:</b> None
                </PS>
              </td>
              <td>
                <PS color="foregroundNeutralMain">
                  <b>Contributions:</b> After-Tax Dollars
                  <br />
                  <b>Maximum:</b> {maximumContribution}
                  <br />
                  <b>Income Restrictions:</b> Must be below certain thresholds
                  <br />
                  <b>Age Limit:</b> None
                </PS>
              </td>
            </tr>
            <tr
              style={{
                verticalAlign: 'top',
              }}
            >
              <td>
                <PS color="foregroundNeutralMain">
                  <b>Disbursements:</b> Penalty-Free but taxed as current income
                  after age 59 ½<br />
                  <b>Mandatory Distribution:</b> Starts after 72
                </PS>
              </td>
              <td>
                <PS color="foregroundNeutralMain">
                  <b>Disbursements:</b> Penalty and Tax-Free after invested for
                  5 Years and age 59 ½<br />
                  <b>Mandatory Distribution:</b> None
                </PS>
              </td>
            </tr>
            <tr>
              <td>
                <PS color="foregroundNeutralMain">
                  Tax-free growth and tax benefits today
                </PS>
              </td>
              <td>
                <PS color="foregroundNeutralMain">
                  Tax-free growth and tax-free withdrawals in the future
                </PS>
              </td>
            </tr>
          </tbody>
        </table>
      </ModalContent>
    </Modal>
  );
};
