import { PL, LL, LS, Flex } from '@m1/liquid-react';
import * as React from 'react';

import { UnmanagedHoldingFragment } from '~/graphql/types';
import { AppPill } from '~/lens-toolbox/AppPill';

import { Checkbox } from '~/toolbox/checkbox';
import { GridTable } from '~/toolbox/grid-table';

export type MobileTableRowProps = {
  hideCheckboxes: boolean;
  holding: UnmanagedHoldingFragment;
  isTradingInProgress: boolean;
  navProps:
    | {
        gridTemplateColumns: string;
        params: {
          id: string;
        };
        to: string;
      }
    | null
    | undefined;
  onCheckboxSelect: (checkboxId: string) => void;
  selectedCheckboxes: Array<string>;
};

export const UnmanagedHoldingsMobileTableRow = ({
  hideCheckboxes,
  holding,
  isTradingInProgress,
  navProps,
  onCheckboxSelect,
  selectedCheckboxes,
}: MobileTableRowProps) => {
  const {
    id,
    isPendingLiquidation,
    value,
    label,
    sublabel,
    unmanagedHoldingPills,
  } = holding;

  const isSelected = selectedCheckboxes.includes(id);
  const RowComponent = navProps ? GridTable.NavigableRow : GridTable.Row;

  return (
    <RowComponent {...navProps}>
      <GridTable.Cell textAlign="left">
        <Flex alignItems="center">
          {!hideCheckboxes && (
            <Checkbox
              checked={isSelected}
              disabled={isTradingInProgress || Boolean(isPendingLiquidation)}
              mr={8}
              onChange={() => onCheckboxSelect(id)}
              size="large"
            />
          )}
          <Flex flexDirection="column">
            <LL mb={4}>{label}</LL>
            <Flex>
              {sublabel && (
                <LS color="foregroundNeutralSecondary" mr={8}>
                  {sublabel}
                </LS>
              )}
              {Array.isArray(unmanagedHoldingPills) &&
                unmanagedHoldingPills
                  .filter((pill) => pill)
                  .map((pill, index) =>
                    pill ? <AppPill appPill={pill} key={index} mr={8} /> : null,
                  )}
            </Flex>
          </Flex>
        </Flex>
      </GridTable.Cell>
      <GridTable.Cell textAlign="right">
        <PL fontWeight={600} mb={4}>
          {value ? value : '--'}
        </PL>
      </GridTable.Cell>
    </RowComponent>
  );
};
