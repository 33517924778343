import { Flex, styled } from '@m1/liquid-react';
import * as React from 'react';

import { SliceableNameCell } from '~/components/pie';

import {
  SecurityDetail_Fund_Fragment,
  Sliceable_Equity_Fragment,
} from '~/graphql/types';
import { Grid } from '~/toolbox/grid';
import { Section } from '~/toolbox/section';

type FundTopHoldingsProps = {
  fund: SecurityDetail_Fund_Fragment;
};

const StyledContainer = styled(Flex)`
  padding: 8px;
  width: 100%;
  justify-content: space-between;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.MEDIUM}) {
    width: 45%;
  }
`;

export const FundTopHoldings = ({ fund }: FundTopHoldingsProps) => {
  const topHoldings = fund.fundFundamentals?.topHoldings;

  if (!topHoldings?.length) {
    return null;
  }

  return (
    <Section>
      <Section.Header label="Top holdings" size="small" />
      <Section.Content>
        <Grid.Row
          style={{
            justifyContent: 'space-between',
          }}
        >
          {topHoldings.map((holding) => {
            if (!holding.symbol) {
              return null;
            }

            const { symbol, name, weighting } = holding;
            // Not all holdings in `topHoldings` have a corresponding security in our Security master.
            // So let's render faux sliceable for a consistent UI.
            // This way, we don't show logos for only some holdings.
            const sliceable = {
              __typename: 'Fund' as Sliceable_Equity_Fragment['__typename'],
              id: '',
              symbol,
              isActive: true,
              status: fund.status,
              name: name ?? '',
              profile: { logoUrl: '' },
            };

            return (
              <StyledContainer key={name}>
                <Flex width={typeof weighting === 'number' ? '75%' : '100%'}>
                  <SliceableNameCell sliceable={sliceable} />
                </Flex>
                {typeof weighting === 'number' && (
                  <Flex alignItems="center">{weighting}%</Flex>
                )}
              </StyledContainer>
            );
          })}
        </Grid.Row>
      </Section.Content>
    </Section>
  );
};
