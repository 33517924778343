import * as React from 'react';
import { Route } from 'react-router-dom';

import { NavigateWithPrevLocationState } from '~/components/routing/NavigateWithPrevLocationState';

import { AddSlicesPage } from './AddSlicesPage';
import { AddCryptoRoute } from './crypto';
import { AddFundRoute } from './funds';
import { AddModelPortfoliosRoute } from './model-portfolio';
import { AddMyPiesRoute } from './my-pies';
import { AddStocksRoute } from './stocks';
import { AddWatchlistRoute } from './watchlist';

export const AddSlicesRoute = () => {
  return (
    <Route path="add-slices" element={<AddSlicesPage />}>
      {AddModelPortfoliosRoute()}
      {AddFundRoute()}
      {AddMyPiesRoute()}
      {AddStocksRoute()}
      {AddWatchlistRoute()}
      {AddCryptoRoute()}
      <Route index element={<NavigateWithPrevLocationState to="stocks" />} />
    </Route>
  );
};
