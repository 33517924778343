import { Box, Flex, PS } from '@m1/liquid-react';
import * as React from 'react';

import {
  isCommonTransactionStatusEnum,
  mapCommonTransactionStatusEnum,
  CommonTransactionStatusClientEnum,
} from '~/components/CommonTransactionsTable/utils';
import { CommonTransactionStatusEnum } from '~/graphql/types';

export type GridTableGroupsProps<T extends Record<string, any>> = {
  data: T[];
  rowRender: (rows: T[]) => React.ReactNode;
};

export const GridTableGroups = <T extends Record<string, any>>({
  data,
  rowRender,
}: GridTableGroupsProps<T>) => {
  const groups = data.reduce(
    (
      acc: Record<
        string,
        { header: CommonTransactionStatusClientEnum; rows: T[] }
      >,
      d: T,
    ) => {
      const key = d.groupBy;

      if (!acc[key]) {
        acc[key] = {
          header: key,
          rows: [d],
        };
      } else {
        acc[key].rows.push(d);
      }
      return acc;
    },
    {},
  );

  return (
    <>
      {Object.values(groups).map((group) => (
        <GridTableGroup
          key={group.header}
          header={group.header}
          data={group.rows}
          rowRender={rowRender}
        />
      ))}
    </>
  );
};

export type GridTableGroupProps<T extends Record<string, any>> = Partial<
  GridTableGroupsProps<T>
> & {
  emptyMessage?: string | null;
  header: string;
};

// GridTableGroup is a separate component so it can support future grouping functionality (ie collapsibility)
export const GridTableGroup = <T extends Record<string, any>>({
  children,
  emptyMessage,
  header,
  data,
  rowRender,
}: React.PropsWithChildren<GridTableGroupProps<T>>) => {
  const isCommonTransactionCreatedStatus =
    isCommonTransactionStatusEnum(header) &&
    mapCommonTransactionStatusEnum(header) ===
      CommonTransactionStatusEnum.Created;

  const hasRows = Boolean(
    React.Children.count(children) || (data && data.length > 0),
  );

  return (
    <>
      {!isCommonTransactionCreatedStatus && (
        <Flex
          alignItems="center"
          backgroundColor="backgroundNeutralMain"
          height={24}
          px={8}
        >
          <PS
            fontWeight={600}
            content={header}
            color="foregroundNeutralSecondary"
          />
        </Flex>
      )}
      {(() => {
        if (hasRows) {
          return (
            children || (rowRender && data && <Box>{rowRender(data)}</Box>)
          );
        }
        if (emptyMessage) {
          return (
            <PS
              color="foregroundNeutralSecondary"
              content={emptyMessage}
              px={8}
              py={16}
            />
          );
        }
        return null;
      })()}
    </>
  );
};
