import {
  OnboardingIdentityFirstQuestionType,
  InvestAccountOpeningQuestionType,
} from '~/graphql/types';
import {
  IDENTITY_FIRST_FLOW_STEP_VALUES,
  IDENTITY_FIRST_FLOW_STEPS as STEPS,
} from '~/static-constants';

export const mapSuitabilityToQuestion = (
  suit: string,
): OnboardingIdentityFirstQuestionType | InvestAccountOpeningQuestionType => {
  switch (suit) {
    case 'suitability.annualIncomeAmount':
      return OnboardingIdentityFirstQuestionType.AnnualIncome;
    case 'suitability.totalNetWorth':
      return OnboardingIdentityFirstQuestionType.NetWorth;
    case 'suitability.totalNetWorthAmount':
      return OnboardingIdentityFirstQuestionType.NetWorth;
    case 'suitability.liquidNetWorth':
      return OnboardingIdentityFirstQuestionType.LiquidNetWorth;
    case 'suitability.liquidNetWorthAmount':
      return OnboardingIdentityFirstQuestionType.LiquidNetWorth;
    case 'suitability.investmentExperience':
      return InvestAccountOpeningQuestionType.InvestmentExperience;
    case 'suitability.riskTolerance':
      return InvestAccountOpeningQuestionType.RiskTolerance;
    case 'suitability.timeHorizon':
      return InvestAccountOpeningQuestionType.InvestmentDuration;
    case 'suitability.liquidityNeeds':
      return InvestAccountOpeningQuestionType.LiquidityImportance;
    case 'suitability.trustedContact':
      return InvestAccountOpeningQuestionType.TrustedContact;
    default:
      return OnboardingIdentityFirstQuestionType.AnnualIncome;
  }
};

export const mapQuestionToFlowStep = (
  questionType:
    | OnboardingIdentityFirstQuestionType
    | InvestAccountOpeningQuestionType,
  isPrimary: boolean,
): IDENTITY_FIRST_FLOW_STEP_VALUES => {
  const disclosureStep = isPrimary
    ? STEPS.COLLECT_PRIMARY_DISCLOSURES
    : STEPS.COLLECT_SECONDARY_DISCLOSURES;
  switch (questionType) {
    case OnboardingIdentityFirstQuestionType.PoliticallyExposedPerson:
      return disclosureStep;
    case OnboardingIdentityFirstQuestionType.EmploymentStatusAndWhereDoYouWork:
      return STEPS.COLLECT_EMPLOYMENT;
    case OnboardingIdentityFirstQuestionType.AnnualIncome:
      return STEPS.COLLECT_ANNUAL_INCOME;
    case OnboardingIdentityFirstQuestionType.NetWorth:
      return STEPS.COLLECT_TOTAL_NET_WORTH;
    case OnboardingIdentityFirstQuestionType.LiquidNetWorth:
      return STEPS.COLLECT_LIQUID_NET_WORTH;
    case InvestAccountOpeningQuestionType.InvestmentExperience:
      return STEPS.COLLECT_INVESTMENT_EXPERIENCE;
    case InvestAccountOpeningQuestionType.LiquidityImportance:
      return STEPS.COLLECT_LIQUIDITY_NEEDS;
    case InvestAccountOpeningQuestionType.HowDidYouHearAboutM1:
      return STEPS.COLLECT_INTRODUCTION_SOURCE;
    case InvestAccountOpeningQuestionType.InvestmentDuration:
      return STEPS.COLLECT_TIME_HORIZON;
    case InvestAccountOpeningQuestionType.RiskTolerance:
      return STEPS.COLLECT_RISK_TOLERANCE;
    case InvestAccountOpeningQuestionType.TrustedContact:
      return STEPS.COLLECT_TRUSTED_CONTACT;
    default:
      return disclosureStep;
  }
};

export const primaryQuestionMap = (
  questionType: OnboardingIdentityFirstQuestionType | null,
): IDENTITY_FIRST_FLOW_STEP_VALUES => {
  switch (questionType) {
    case 'SOCIAL_SECURITY_NUMBER':
      return STEPS.COLLECT_PRIMARY_SSN;
    case 'NAME_AND_ADDRESS':
      return STEPS.COLLECT_PRIMARY_CONTACT_INFO;
    case 'DATE_OF_BIRTH_AND_CITIZENSHIP':
      return STEPS.COLLECT_PRIMARY_IDENTITY_INFO;
    case OnboardingIdentityFirstQuestionType.AnnualIncome:
      return STEPS.COLLECT_ANNUAL_INCOME;
    case OnboardingIdentityFirstQuestionType.LiquidNetWorth:
      return STEPS.COLLECT_LIQUID_NET_WORTH;
    default:
      return STEPS.COLLECT_PRIMARY_CONTACT_INFO;
  }
};

export const secondaryQuestionMap = (
  questionType: OnboardingIdentityFirstQuestionType,
): IDENTITY_FIRST_FLOW_STEP_VALUES => {
  switch (questionType) {
    case 'SOCIAL_SECURITY_NUMBER':
      return STEPS.COLLECT_SECONDARY_SSN;
    case 'NAME_AND_ADDRESS':
      return STEPS.COLLECT_SECONDARY_CONTACT_INFO;
    case 'DATE_OF_BIRTH_AND_CITIZENSHIP':
      return STEPS.COLLECT_SECONDARY_IDENTITY_INFO;
    default:
      return STEPS.COLLECT_SECONDARY_CONTACT_INFO;
  }
};
