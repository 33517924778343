import { Button, Box, HS, HXXS, PS, Flex, Card } from '@m1/liquid-react';
import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useIraDistributionInfoQuery } from '~/graphql/hooks';
import { Account } from '~/graphql/types';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import {
  setFederalWithholdingPercentage,
  setIraDistributionReason,
  setIraExcessContributionYear,
  setIraNiaAmount,
  setStateWithholdingPercentage,
} from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import {
  CREATE_TRANSFER_FLOW_STEPS as STEPS,
  IraDistributionReasonEnumDropdownOptions,
} from '~/static-constants';
import { useToast } from '~/toasts';
import { Dropdown } from '~/toolbox/Dropdown';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

import { validateWithholdingLessThanTransferAmount } from '../../utils/iraTaxWithholding';

import { IraWithholding } from './IraWithholdingDeprecated';

const EXCESS_CONTRIBUTION_REMOVAL_BEFORE_TAX_DEADLINE =
  'EXCESS_CONTRIBUTION_REMOVAL_BEFORE_TAX_DEADLINE';
type IraDistributionProps = {
  onFinishStep: (...args: Array<any>) => any;
};

export const IraDistributionStep = ({ onFinishStep }: IraDistributionProps) => {
  const formMethods = useForm<{
    stateWithholding: number | null;
    federalWithholding: number | null;
  }>({
    defaultValues: {
      stateWithholding: null,
      federalWithholding: null,
    },
  });
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const {
    excessIraDistribution,
    input,
    federalWithholdingPercentage,
    stateWithholdingPercentage,
  } = useSelector(
    (stateWithholding) => stateWithholding.newFlows.CREATE_TRANSFER,
  );
  const { iraDistributionReason, iraContributionYear } = excessIraDistribution;
  const { fromParticipantId, amount } = input;
  const { data, loading } = useIraDistributionInfoQuery({
    variables: {
      accountId: fromParticipantId as string,
    },
    skip: !fromParticipantId,
  });
  const account = data?.node as Account;
  const iraWithholding = account?.iraWithholding;

  React.useEffect(() => {
    const { defaultFederalWithholding, defaultStateWithholding } =
      iraWithholding ?? {};
    const defaultFederalWithholdingPercentage = defaultFederalWithholding
      ? parseFloat((defaultFederalWithholding * 100).toFixed(2))
      : null;

    const defaultStateWithholdingPercentage = defaultStateWithholding
      ? parseFloat((defaultStateWithholding * 100).toFixed(2))
      : null;

    formMethods.setValue(
      'federalWithholding',
      federalWithholdingPercentage ?? defaultFederalWithholdingPercentage,
    );

    formMethods.setValue(
      'stateWithholding',
      stateWithholdingPercentage ?? defaultStateWithholdingPercentage,
    );
  }, [iraWithholding]);

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.viewer) {
    return <GenericSystemError />;
  }

  const { federalWithholding, stateWithholding } = formMethods.watch();

  const years =
    data.viewer.irsRegulations.iraExcessContributionRemovalYears.map(
      (year) => ({
        // Must be IraContributionYearEnum
        value: year.isCurrent ? 'CURRENT_YEAR' : 'PRIOR_YEAR',
        label: year.isCurrent ? 'Current year' : 'Previous year',
      }),
    );
  const reasonIsExcessContributionRemovalBeforeTaxDeadline =
    iraDistributionReason === EXCESS_CONTRIBUTION_REMOVAL_BEFORE_TAX_DEADLINE;

  const handleBack = () => {
    dispatch(setFederalWithholdingPercentage(null));
    dispatch(setStateWithholdingPercentage(null));
    onFinishStep(STEPS.SETUP_TRANSFER);
  };

  const onSubmit = () => {
    const { isValid } = validateWithholdingLessThanTransferAmount({
      stateWithholdingPercentage: stateWithholding,
      federalWithholdingPercentage: federalWithholding,
      amount: amount ?? 0,
    });
    if (isValid) {
      dispatch(setFederalWithholdingPercentage(Number(federalWithholding)));
      dispatch(setStateWithholdingPercentage(Number(stateWithholding)));
    }

    if (!isValid) {
      addToast({
        content:
          'The total of federal and state withholdings cannot exceed 100%.',
        kind: 'alert',
      });
    } else if (reasonIsExcessContributionRemovalBeforeTaxDeadline) {
      onFinishStep(STEPS.NIA_REPORT);
    } else {
      dispatch(setIraExcessContributionYear(null));
      dispatch(setIraNiaAmount(null));
      onFinishStep();
    }
  };
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Box>
          <HS>One-time transfer</HS>
          <Card mt={32} p={32}>
            {iraWithholding && (
              <IraWithholding iraWithholding={iraWithholding} />
            )}
            <HXXS mt={32}>Distribution Reason</HXXS>
            <Dropdown
              name="iraDistributionReason"
              label="Distribution Reason"
              source={IraDistributionReasonEnumDropdownOptions}
              value={iraDistributionReason || undefined}
              onChange={(reason) => {
                dispatch(setIraDistributionReason(reason));
              }}
            />
            {reasonIsExcessContributionRemovalBeforeTaxDeadline && (
              <>
                <Dropdown
                  name="contributionYear"
                  label="Contribution Year"
                  options={years}
                  value={iraContributionYear || undefined}
                  onChange={(year) =>
                    dispatch(setIraExcessContributionYear(year))
                  }
                  style={{
                    marginTop: 32,
                  }}
                />
                {data.viewer.transfers?.niaWithholdingDisclaimer && (
                  <PS
                    mt={12}
                    mb={36}
                    color="foregroundNeutralSecondary"
                    content={
                      data.viewer.transfers.niaWithholdingDisclaimer.text
                    }
                  />
                )}
                {data.viewer.transfers?.iraDistributionReasonFAQ && (
                  <LinkableLink
                    mb={24}
                    linkable={data.viewer.transfers.iraDistributionReasonFAQ}
                  />
                )}
              </>
            )}
            {data.viewer.transfers?.iraOvercontributionFAQ && (
              <LinkableLink
                mb={48}
                linkable={data.viewer.transfers.iraOvercontributionFAQ}
              />
            )}
            {(iraDistributionReason === 'DEATH' ||
              iraDistributionReason === 'DISABILITY') && (
              <Card
                borderColor="transparent"
                backgroundColor="backgroundNeutralTertiary"
                mt={16}
                p={16}
              >
                <PS
                  color="foregroundNeutralSecondary"
                  content={
                    <>
                      Supporting documents will need to be sent to{' '}
                      <Link to="mailto:help@m1.com" font="PM" usePlainAnchor>
                        help@m1.com
                      </Link>{' '}
                      before the completion of the distribution.
                    </>
                  }
                />
              </Card>
            )}
            <Flex justifyContent="space-evenly" flexDirection="row">
              <Flex width={203}>
                <Button
                  kind="secondary"
                  label="Back"
                  mt={21}
                  onClick={handleBack}
                  fullWidth
                  size="large"
                />
              </Flex>
              <Flex width={203}>
                <Button
                  type="submit"
                  disabled={
                    !iraDistributionReason ||
                    (reasonIsExcessContributionRemovalBeforeTaxDeadline &&
                      !iraContributionYear)
                  }
                  kind="primary"
                  label={
                    reasonIsExcessContributionRemovalBeforeTaxDeadline
                      ? 'Continue'
                      : 'Preview transfer'
                  }
                  mt={21}
                  size="large"
                  fullWidth
                />
              </Flex>
            </Flex>
          </Card>
        </Box>
      </form>
    </FormProvider>
  );
};
