import { ACTION_TYPES as ACTIONS, ROUTING_ACTIONS } from '~/redux/actions';
import { WAITLIST_STEPS as STEPS } from '~/static-constants';

import { FlowState } from '../newFlowsReducer.types';
import { createFlowReducer, createStepReducer } from '../utils';

export type WaitlistFlowState = FlowState<
  typeof STEPS,
  {
    basePath: string;
  }
>;

export const waitlistInitialState: WaitlistFlowState = {
  basePath: '',
  step: STEPS.WAITLIST_CONFIRMATION,
  stepTitle: '',
};

const stepReducer = createStepReducer(waitlistInitialState);

function reducer(
  state: WaitlistFlowState = waitlistInitialState,
  action: any,
): WaitlistFlowState {
  switch (action.type) {
    case ACTIONS.BEGIN_FLOW:
      return {
        ...waitlistInitialState,
        step: action.payload.initialStep || waitlistInitialState.step,
      };
    case ACTIONS.END_FLOW:
      return waitlistInitialState;
    case ROUTING_ACTIONS.LOCATION_CHANGE:
      return {
        ...state,
        step: stepReducer(state, action),
      };
    default:
      return state;
  }
}

export const waitlistReducer = createFlowReducer('WAITLIST', reducer);
