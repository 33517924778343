import * as React from 'react';
import { Path, useFormContext } from 'react-hook-form';

import { printableAsciiChars } from '~/forms/validators';

import { ControlledInput } from '../ControlledInput';

import { AddressFormFields, AddressSectionProps } from './types';

const FIELD_NAME = 'city';

export const CityInput = <NS extends string = ''>({
  backgroundColor,
  disabled,
  namespace,
}: AddressSectionProps<NS>) => {
  const { control } = useFormContext<AddressFormFields<NS>>();
  const name = (
    namespace ? `${namespace as NS}.${FIELD_NAME}` : FIELD_NAME
  ) as Path<AddressFormFields<NS>>;

  return (
    <ControlledInput
      control={control}
      name={name}
      rules={{
        required: 'Required',
        maxLength: 28,
        validate: {
          printableAsciiChars: (value) =>
            typeof value === 'string' && printableAsciiChars(value),
        },
      }}
      backgroundColor={backgroundColor}
      label="City"
      maxLength={28}
      disabled={disabled}
    />
  );
};
