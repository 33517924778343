import * as React from 'react';
import { Route } from 'react-router-dom';

import { FundScreenerPage } from './components';
import { ResearchFundDetailsRoute } from './details';

export const FundsScreenerRoute = () => {
  return (
    <Route
      path="funds"
      // ignoreScrollBehavior
    >
      {ResearchFundDetailsRoute()}
      <Route index element={<FundScreenerPage />} />
    </Route>
  );
};
