import { Button, Flex, PL, spacingUnits, Card } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { clickedResendVerificationEmail } from '~/redux/actions/verifyEmailBannerActions';
import { useDispatch } from '~/redux/hooks';
import { NOTIFICATION_LOCATIONS } from '~/static-constants';

export type EmailVerificationCardProps = {
  content: string;
  notificationLocation?: keyof typeof NOTIFICATION_LOCATIONS;
  margin?: string;
};

export const EmailVerificationCard = ({
  content,
  notificationLocation,
  margin,
}: EmailVerificationCardProps) => {
  const dispatch = useDispatch();
  const iconColumnWidth = 24 + spacingUnits.xxs;

  return (
    <Card
      backgroundColor="backgroundWarningSubtle"
      padding={spacingUnits.xs}
      position="relative"
      margin={margin || '16px 16px -8px 16px'}
      borderColor="borderWarning"
    >
      <Flex inline position="relative" top={6} width={`${iconColumnWidth}px`}>
        <Icon color="warningShade" name="alert24" mr={spacingUnits.xxs} />
      </Flex>
      <Flex
        inline
        flexDirection="column"
        width={`calc(100% - ${iconColumnWidth}px)`}
      >
        <PL color="foregroundNeutralMain" content={content} mb={4} />
        <PL>
          <Button
            kind="destructive-text"
            label="Resend verification email"
            style={{
              padding: 0,
              height: 'auto',
              minHeight: '0px',
              display: 'inline-flex',
            }}
            onClick={() =>
              dispatch(
                clickedResendVerificationEmail({
                  successNotificationLocation: notificationLocation,
                }),
              )
            }
          />
        </PL>
      </Flex>
    </Card>
  );
};
