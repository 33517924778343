import castArray from 'lodash-es/castArray';
import get from 'lodash-es/get';
import partial from 'lodash-es/partial';
import transform from 'lodash-es/transform';
import { SagaIterator } from 'redux-saga';
import { AllEffect } from 'redux-saga/effects';

import { NavigateFunction } from '~/hooks/useNavigate';
import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import type { FLOWS } from '~/redux/reducers/newFlows';
import { buildRoute } from '~/utils/route';

import type { AppState } from '../../../reducers/types';

import {
  all,
  call,
  getContext,
  select,
  setContext,
  takeEvery,
  takeLatest,
} from '../../effects';

// Deprecated. Use createSagaHelpers instead.
export function makeFlowFuncs(name: ValueOf<typeof FLOWS>): any {
  return {
    selectFlowState: partial(selectFlowState, name),
    takeFlow: partial(takeFlow, name),
    takeFlowStep: partial(takeFlowStep, name),
  };
}

/*
  Utility function to handle routing within app flows.
  - $basePath - Provided by an instance of a flow component. Must be set in
    context for this function to take it into account.
  - $step - Provided by a saga function.
*/
export function* changeStep<K extends string>(
  step: K,
  replace: boolean = false,
  otherOptions: Record<string, any> = {},
  query: Record<string, any> = {},
): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );
  const basePath: string = yield getContext('basePath') || '';
  const pathname = buildRoute([basePath, step]);

  yield call(navigate, {
    to: pathname,
    query,
    options: {
      ...otherOptions,
      replace,
    },
  });
}

export function* replaceRouterHistory<K extends string>(
  route: K,
): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );
  const pathname = buildRoute(['', route]);

  yield call(navigate, {
    to: pathname,
    options: {
      replace: true,
    },
  });
}

export function* selectFlowState(
  name: ValueOf<typeof FLOWS>,
  path: string,
): SagaIterator<any> {
  return yield select((state): AppState => {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'typeof FLOWS' can't be used to index type 'NewFlowsState'.
    return get(state.newFlows[name], path, undefined);
  });
}

export function* takeFlow(
  name: ValueOf<typeof FLOWS>,
  saga: any,
  ...rest: Array<any>
): SagaIterator<void> {
  yield takeLatest(
    // @ts-expect-error - TS7006 - Parameter 'a' implicitly has an 'any' type.
    (a) => shouldFlowBegin(name, a),
    function* (action: any): SagaIterator<void> {
      // Setup basePath for routing
      if (action.payload.basePath) {
        yield setContext({
          basePath: action.payload.basePath,
        });
      } else {
        // eslint-disable-next-line
        console.warn(`${name} flow init action is missing a basePath.`);
      }

      // @ts-expect-error - TS2322 - Type 'SagaIterator<any>' is not assignable to type 'StrictEffect<any, any>'.
      const basePathInState = yield selectFlowState(name, 'basePath');
      if (!basePathInState) {
        // eslint-disable-next-line
        console.warn(
          `${name} flow state is missing basePath. Did you forget to provide it to the reducer?`,
        );
      }
      yield call(saga, action, ...rest);
    },
  );
}

function shouldFlowBegin(name: ValueOf<typeof FLOWS>, action: any): boolean {
  return Boolean(
    action.type === ACTIONS.BEGIN_FLOW &&
      action.meta &&
      action.meta.flow === name,
  );
}

export function* takeFlowStep<K>(
  name: ValueOf<typeof FLOWS>,
  step: K | Array<K>,
  saga: any,
  ...rest: Array<any>
): SagaIterator<void> {
  // @ts-expect-error - TS7006 - Parameter 'a' implicitly has an 'any' type.
  yield takeEvery((a) => shouldFlowStepBegin(name, step, a), saga, ...rest);
}

function shouldFlowStepBegin<K>(
  name: ValueOf<typeof FLOWS>,
  step: K | Array<K>,
  action: any,
): boolean {
  const steps = castArray(step);
  return Boolean(
    action.type === ACTIONS.FINISHED_FLOW_STEP &&
      action.meta &&
      action.meta.flow === name &&
      steps.includes(action.meta.step),
  );
}

// @ts-expect-error - TS2739 - Type 'Generator<any, any, unknown>' is missing the following properties from type 'CombinatorEffect<"ALL", any>': combinator, '@@redux-saga/IO', type, payload.
export function* pickContext(...keys: Array<string>): AllEffect<any> {
  return yield all(
    transform(
      keys,
      (result, v) => {
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
        result[v] = getContext(v); // eslint-disable-line
      },
      {},
    ),
  );
}

export * from './createSagaHelpers';
export * from './logDepositFundingEvents';
export * from './logTotalNetWorthAmount';
export * from './logTotalNetWorthAnalytics';
export { logMarketingDeepLinkClickSaga } from './logMarketingDeepLinkClickSaga';
