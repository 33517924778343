import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { AnnouncementContext } from '~/components/announcement';
import { PageErrorBoundary } from '~/components/page-error-boundary';
import { MoveMoneyFlow } from '~/flows';
import { useMoveMoneyAnnouncementQuery } from '~/graphql/hooks';
import { AnnouncementContextEnum } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { useSearchParams } from '~/hooks/useSearchParams';
import { Container } from '~/toolbox/container';
import { Spinner } from '~/toolbox/spinner';

export const MoveMoneyPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const route = searchParams.get('previousRouteName') ?? '/d/transfers';

  const handleFinish = () => navigate({ to: route });

  const { data, loading } = useMoveMoneyAnnouncementQuery();

  if (loading) {
    return <Spinner fullScreen />;
  }

  const forMoveMoneyAnnouncement = data?.viewer.announcements?.forMoveMoney;

  return (
    <>
      {forMoveMoneyAnnouncement && (
        <Container marginTop={64}>
          <AnnouncementContext
            announcement={forMoveMoneyAnnouncement}
            context={AnnouncementContextEnum.MoveMoney}
          />
        </Container>
      )}
      <Box height="100vh" width={490} m="0 auto" p="64px 0 100px 0">
        <PageErrorBoundary onClickRetry={handleFinish} retryButtonLabel="Exit">
          <MoveMoneyFlow
            basePath="/d/move-money"
            initialStep={searchParams.get('initialStep')}
            previousRouteName={route}
            onFinish={handleFinish}
          />
        </PageErrorBoundary>
      </Box>
    </>
  );
};
