import { Box, Button, Flex, HM, PL, PM } from '@m1/liquid-react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { BackArrow } from '~/components/BackArrow';
import { ControlledInput } from '~/components/form/ControlledInput';
import { useOpenInvestJointAccountWizardContext } from '~/flows/components/OpenInvestJointAccountWizard/hooks/useOpenInvestJointAccountWizardContext';
import { FormMockerReactHookForm } from '~/forms/FormMockers';
import { email as emailValidator } from '~/forms/validators';
import { submitReactFormData, clearSingleReactHookForm } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

export interface TrustedContactValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}
export const TrustedContact = () => {
  const dispatch = useDispatch();

  const submittedFormValues = useSelector((state) => {
    if (
      Boolean(state.reactHookForm.openJointInvestAccountTrustedContact) !== true
    ) {
      return {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phoneNumber: undefined,
      };
    }

    return state.reactHookForm.openJointInvestAccountTrustedContact;
  });

  const { control, setValue, formState, handleSubmit, watch, reset } =
    useForm<TrustedContactValues>({
      defaultValues: submittedFormValues,
    });

  const wiz = useOpenInvestJointAccountWizardContext();

  const { firstName, lastName, email, phoneNumber } = watch();

  const isEnabled = firstName && lastName && (email || phoneNumber);

  const onSubmit = (data: TrustedContactValues) => {
    dispatch(
      submitReactFormData({ openJointInvestAccountTrustedContact: data }),
    );

    wiz.next();
  };

  return (
    <Flex maxWidth={588} flexDirection="column" mx="auto">
      <Box pt={32}>
        <BackArrow content="Back" onClick={() => wiz.back()} />
      </Box>
      <HM mt={48} mb={16}>
        Would you like to add a trusted contact?
      </HM>
      <PL mt={12} mb={32}>
        We’re legally required to ask for this. A trusted contact must be 18
        years or older, and we’d only contact them in certain situations, such
        as if there were concerning account activity or we suspected you’re
        being exploited.
      </PL>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ControlledInput
          name="firstName"
          label="First name"
          control={control}
          rules={{
            required: true,
            maxLength: 50,
          }}
          backgroundColor="backgroundNeutralMain"
        />
        <ControlledInput
          name="lastName"
          label="Last name"
          control={control}
          rules={{
            required: true,
            maxLength: 50,
          }}
          backgroundColor="backgroundNeutralMain"
        />
        <ControlledInput
          name="email"
          rules={{
            required: false,
            validate: (value = '') => {
              if (!value) {
                return undefined;
              }
              const errorMessage = emailValidator(value);
              return errorMessage ?? undefined;
            },
          }}
          label="Email"
          control={control}
          backgroundColor="backgroundNeutralMain"
          error={formState.errors.email?.message}
        />
        <ControlledInput
          name="phoneNumber"
          label="Phone number"
          rules={{
            required: false,
            minLength: { value: 14, message: 'Invalid phone number' },
            maxLength: { value: 14, message: 'Invalid phone number' },
          }}
          control={control}
          maskType="phone"
          backgroundColor="backgroundNeutralMain"
          error={formState.errors.phoneNumber?.message}
        />
        <Flex alignItems="center" mt={32}>
          <Button
            disabled={!isEnabled}
            type="submit"
            kind="primary"
            size="large"
            label="Add trusted contact"
          />
          <PM
            onClick={() => {
              reset();

              dispatch(
                clearSingleReactHookForm(
                  'openJointInvestAccountTrustedContact',
                ),
              );

              wiz.next();
            }}
            color="primary"
            content="Skip"
            fontWeight={600}
            pl={32}
            cursor="pointer"
          />
        </Flex>
        <FormMockerReactHookForm
          fields={[
            {
              name: 'firstName',
              value: 'J0n',
            },
            {
              name: 'lastName',
              value: 'Sn0w',
            },
            {
              name: 'email',
              value: 'jon@example.com',
            },
          ]}
          setValue={setValue}
        />
      </form>
    </Flex>
  );
};
