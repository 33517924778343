import { HXS, PL, Flex, Box, styled } from '@m1/liquid-react';
import * as React from 'react';

import { Spinner } from '~/toolbox/spinner';

type LoaderProps = {
  heading?: string;
  content?: string;
};

const StyledBox = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transform: translate(-50%, -50%);
  width: inherit;
  padding-left: 8px;
  padding-right: 8px;
`;

export const Loader = ({ heading, content }: LoaderProps) => {
  return (
    <StyledBox>
      <Box height={150}>
        <Spinner radius={90} thickness={10} />
      </Box>
      {heading && <HXS pt={16} content={heading} />}
      {content && <PL pt={16} content={content} />}
    </StyledBox>
  );
};
