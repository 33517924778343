import { NetworkStatus } from '@apollo/client';
import { Box, Flex, HXXS, Skeleton, SkeletonProvider } from '@m1/liquid-react';
import * as React from 'react';

import { PaginationButtonGroup } from '~/components/PaginationButtonGroup';
import { useCreditCardCommonTransactionsQuery } from '~/graphql/hooks';
import { CreditCardCommonTransactionsQuery } from '~/graphql/types';
import { useApolloPrevNext } from '~/hooks/useApolloPrevNext';
import { isNotNil } from '~/utils';

import { CreditCardCommonTransactionsTable } from './CreditCardCommonTransactionsTable';

export const CreditCardPaginatedTransactions = ({
  accountId,
  handleDetailsModalOpen,
  selectedTransactionId,
}: {
  accountId: string;
  handleDetailsModalOpen: (transactionId: string) => void;
  selectedTransactionId: string | null;
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const { data, refetch, networkStatus } = useCreditCardCommonTransactionsQuery(
    {
      variables: { accountId: accountId, first: 7 },
      notifyOnNetworkStatusChange: true,
      onCompleted: () => setIsLoading(false),
    },
  );
  const { handleNextPage, handlePreviousPage, hasPreviousPage } =
    useApolloPrevNext({ refetch });

  const node = data?.node as ExtractTypename<
    CreditCardCommonTransactionsQuery['node'],
    'CreditCardAccount'
  >;

  const transactionsData = node?.creditCardProductLandingPage?.transactions;
  const emptyStateContent =
    node?.creditCardProductLandingPage?.transactionsEmptyState;

  const pageInfo = transactionsData?.pageInfo;

  const tableHeaders = transactionsData?.tableHeaders;
  const transactions =
    transactionsData?.edges
      ?.map((edge) => edge && edge.node)
      .filter(isNotNil) ?? [];

  React.useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setIsLoading(true);
    }
  }, [networkStatus]);

  return (
    <SkeletonProvider isLoading={isLoading} fadeOut>
      <Box mt={32}>
        <Flex mb={16} justifyContent="space-between">
          <HXXS
            content="Transactions"
            fontWeight={300}
            mr={4}
            color="foregroundNeutralSecondary"
          />
          <PaginationButtonGroup
            handleNextPage={() =>
              pageInfo?.endCursor && handleNextPage(pageInfo?.endCursor)
            }
            hasNextPage={pageInfo?.hasNextPage ?? false}
            hasPreviousPage={hasPreviousPage}
            handlePreviousPage={handlePreviousPage}
          />
        </Flex>
        <Skeleton skeletonWidth="100%" skeletonHeight={400}>
          <CreditCardCommonTransactionsTable
            tableHeaders={tableHeaders}
            transactions={transactions}
            emptyStateContent={emptyStateContent}
            handleDetailsModalOpen={handleDetailsModalOpen}
            selectedTransactionId={selectedTransactionId}
          />
        </Skeleton>
      </Box>
    </SkeletonProvider>
  );
};
