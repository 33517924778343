import * as React from 'react';
import { Route } from 'react-router-dom';

import { CryptoScreener } from './components/CryptoScreener';

import { CryptoDetailsRoute } from './details';

export const ResearchCryptoRoute = () => {
  return (
    <Route
      path="crypto"
      // ignoreScrollBehavior
    >
      {CryptoDetailsRoute()}
      <Route index element={<CryptoScreener />} />
    </Route>
  );
};
