import * as React from 'react';

import { ProfileNavigationFragment } from '~/graphql/types';
import { Navigation } from '~/toolbox/navigation';

type ProfileNavigationProps = {
  viewer: ProfileNavigationFragment;
};
type LaunchDarklyFlags = Record<string, never>;

function isViewerAllowedBeneficiaries({
  viewer,
}: {
  viewer: ProfileNavigationFragment;
}): boolean {
  return Boolean(viewer.profile?.allowsBeneficiaries);
}

function hasActiveCreditAccount({
  viewer,
}: {
  viewer: ProfileNavigationFragment;
}): boolean {
  return Boolean(viewer.credit?.hasActiveAccount);
}

function isCryptoEligible({
  viewer,
}: {
  viewer: ProfileNavigationFragment;
}): boolean {
  return Boolean(viewer.crypto?.isEligible);
}

function hasPromotionalAgreements({
  viewer,
}: {
  viewer: ProfileNavigationFragment;
}): boolean {
  return Boolean(viewer.promotions?.promotionsAgreements?.documents.length);
}

const navigationLinks: Array<{
  condition: ({
    viewer,
    flags,
  }: {
    viewer: ProfileNavigationFragment;
    flags: LaunchDarklyFlags;
  }) => boolean;
  to: string;
  label: string;
}> = [
  {
    condition: () => true,
    to: '/d/settings/profile/contact',
    label: 'Contact Information',
  },
  {
    condition: () => true,
    to: '/d/settings/profile/employment',
    label: 'Employment',
  },
  {
    condition: () => true,
    to: '/d/settings/profile/disclosures',
    label: 'Investor Disclosures',
  },
  {
    condition: () => true,
    to: 'https://m1.com/legal/disclosures/',
    label: 'Disclosures for M1 Brokerage',
  },
  {
    condition: () => true,
    to: 'https://m1.com/legal/agreements/',
    label: 'Agreements for M1 Brokerage',
  },
  {
    condition: hasActiveCreditAccount,
    to: '/d/settings/profile/credit-disclosures',
    label: 'Disclosures for M1 Credit Card',
  },
  {
    condition: () => true,
    to: '/d/settings/profile/cash-disclosures',
    label: 'Disclosures for M1 High-Yield Cash Account',
  },
  {
    condition: () => true,
    to: '/d/settings/profile/savings-disclosures',
    label: 'Disclosures for M1 High-Yield Savings Account',
  },
  {
    condition: isCryptoEligible,
    to: '/d/settings/profile/crypto-disclosures',
    label: 'Disclosures for Crypto Account',
  },
  {
    condition: hasPromotionalAgreements,
    to: '/d/settings/profile/promotions-disclosures',
    label: 'Disclosures for Promotions',
  },
  {
    condition: () => true,
    to: '/d/settings/profile/investment',
    label: 'Investment Profile',
  },
  {
    condition: isViewerAllowedBeneficiaries,
    to: '/d/settings/profile/beneficiaries',
    label: 'Beneficiaries',
  },
  {
    condition: () => true,
    to: '/d/settings/profile/trusted-contact',
    label: 'Trusted Contact',
  },
];

export const ProfileNavigation = (props: ProfileNavigationProps) => {
  const flags = {};
  const viewer = props.viewer;

  return (
    <Navigation
      orientation="vertical"
      items={navigationLinks
        .filter(({ condition }) => condition({ viewer, flags }))
        .map(({ to, label }) => ({
          to,
          label,
        }))}
    />
  );
};
