import * as React from 'react';

import { useWizard, WizardProvider } from '~/flows/wizard';
import { useNavigate } from '~/hooks/useNavigate';
import { setOnboardingSubProduct } from '~/redux/actions/newFlows/onboarding';
import { useDispatch } from '~/redux/hooks';
import { RETIREMENT_ACCOUNT_TYPES } from '~/static-constants';

import { SelectIRAAccountTypeStep } from './steps/SelectIRAAccountTypeStep';
import { SelectIRAFundingTypeStep } from './steps/SelectIRAFundingTypeStep';
import { SelectIRARolloverTypeStep } from './steps/SelectIRARolloverTypeStep';

import { IRAWizardSteps, RetirementAccountValues } from './types';

type IRAWizardFormSteps = Record<IRAWizardSteps, React.ReactElement>;

export const IRAWizard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onWizardComplete = (value: RetirementAccountValues) => {
    if (value !== RETIREMENT_ACCOUNT_TYPES.ROLLOVER) {
      dispatch(setOnboardingSubProduct(value));
    }

    navigate({ to: '/onboarding/setup-invest-account' });
  };

  const steps: IRAWizardFormSteps = {
    SELECT_IRA_FUNDING_TYPE: <SelectIRAFundingTypeStep />,
    SELECT_IRA_ACCOUNT_TYPE: (
      <SelectIRAAccountTypeStep onWizardComplete={onWizardComplete} />
    ),
    SELECT_IRA_ROLLOVER_TYPE: (
      <SelectIRARolloverTypeStep onWizardComplete={onWizardComplete} />
    ),
  };

  const { step, ...rest } = useWizard(steps);

  return <WizardProvider value={{ step, ...rest }}>{step}</WizardProvider>;
};
