import { Button, HM, PXL } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { useNavigate } from '~/hooks/useNavigate';

import { AspectRatioFrame } from '../components/AspectRatioFrame';
import { Cell, Divider, Grid, Stack } from '../components/Layout';

export const DisbursementError = () => {
  const navigate = useNavigate();

  return (
    <Grid>
      <Stack column="left-start / span 6" css="gap: 48px;">
        <Cell vertical css="gap: 16px;">
          <HM>We couldn't deposit money into your bank account.</HM>
          <PXL>
            Something went wrong with the transfer. Please contact Client
            support so we can get this straightened out for you at (312)
            600-2883.
          </PXL>
        </Cell>
      </Stack>
      <AspectRatioFrame
        ratio="16:9"
        css="grid-column: span 5 / right-end; margin-block: auto;"
      >
        <Illustration name="transferUnsuccessfulWide" />
      </AspectRatioFrame>
      <Stack css="gap: 24px;" column="content">
        <Divider />
        <Cell>
          <Button
            kind="primary"
            label="Return Home"
            onClick={() => {
              navigate({ to: '/d/home' });
            }}
            size="large"
          />
        </Cell>
      </Stack>
    </Grid>
  );
};
