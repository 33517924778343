import { SagaIterator } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';

import { ACTION_TYPES, displayNotification } from '~/redux/actions';
import { NOTIFICATION_LOCATIONS, NOTIFICATION_TYPES } from '~/static-constants';

// DEPRECATED: Use specific action creators instead.

// [redux-action-consolidate]
type DisplayErrorNotificationAction = {
  payload: string;
  type: 'DISPLAY_ERROR_NOTIFICATION';
};

export function* displayErrorNotificationSaga(): SagaIterator<void> {
  yield takeEvery(
    ACTION_TYPES.DISPLAY_ERROR_NOTIFICATION,
    function* (action: DisplayErrorNotificationAction): SagaIterator<void> {
      yield put(
        displayNotification({
          type: NOTIFICATION_TYPES.ERROR,
          location: NOTIFICATION_LOCATIONS.SNACKBAR,
          message: action.payload,
        }),
      );
    },
  );
}
