import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import {
  FullUserProfileSagaDocument,
  FullUserProfileSagaQueryResult,
  PartialUserProfileSagaDocument,
  PartialUserProfileSagaQueryResult,
  UpdateProfileDocument,
  UpdateProfileMutationResult,
} from '~/graphql/hooks';

import {
  ProductListIdentifier,
  UpdateProfileInput,
  UpdateProfileMutation,
} from '~/graphql/types';

import { apolloMutationSaga } from '../../apolloMutationSaga';

import { apolloQuerySaga } from '../../apolloQuerySaga';

import { hasUserOnboarded } from '../hasUserOnboardedSaga';

import {
  mergeProfiles,
  profileWithoutUnwantedFields,
} from './updateUserProfileSaga.utils';

export function* updateUserProfile(
  profileInput: Record<string, any> | null,
  queryFullProfile: boolean,
  productIdentifier?: ProductListIdentifier | null,
): SagaIterator<UpdateProfileMutation['updateProfile'] | null | undefined> {
  const query = queryFullProfile
    ? FullUserProfileSagaDocument
    : PartialUserProfileSagaDocument;

  const {
    data,
  }: PartialUserProfileSagaQueryResult | FullUserProfileSagaQueryResult =
    yield call(apolloQuerySaga, { query });

  const viewer = data?.viewer;
  if (!viewer?.profile || !profileInput) {
    return null;
  }

  let profileMergedWithRemoteAndLocal = mergeProfiles(
    viewer.profile as any,
    profileInput,
    {
      isUpdateProfile: true,
    },
  );

  profileMergedWithRemoteAndLocal = {
    ...profileMergedWithRemoteAndLocal,
    primary: profileWithoutUnwantedFields(
      profileMergedWithRemoteAndLocal.primary,
    ),
  };

  if (profileMergedWithRemoteAndLocal.secondary) {
    profileMergedWithRemoteAndLocal = {
      ...profileMergedWithRemoteAndLocal,
      secondary: profileWithoutUnwantedFields(
        profileMergedWithRemoteAndLocal.secondary,
      ),
    };
  }

  const { data: profileData }: UpdateProfileMutationResult = yield call(
    apolloMutationSaga,
    {
      mutation: UpdateProfileDocument,
      variables: {
        input: {
          profile: profileMergedWithRemoteAndLocal,
          productSelection: productIdentifier,
        } as UpdateProfileInput,
      },
    },
  );

  if (profileData?.updateProfile?.didSucceed) {
    yield call(hasUserOnboarded);
  }

  return profileData?.updateProfile;
}
