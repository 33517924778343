import * as React from 'react';
import { Route } from 'react-router-dom';

import { PortfolioTradingDetails } from './components/PortfolioTradingDetails';
import { ExcludedBuysPage } from './excluded-buys/ExcludedBuysPage';
import { OnDemandPortfolioTradingDetailsPage } from './OnDemandPortfolioTradingDetailsPage';
import { OnDemandPortfolioTradingInProgressPage } from './OnDemandPortfolioTradingInProgressPage';
import { PortfolioPage } from './PortfolioPage';

export const PortfolioRoute = () => {
  return (
    <Route path="portfolio">
      <Route index element={<PortfolioPage />} />
      <Route path="trading" element={<PortfolioTradingDetails />} />
      <Route path="on-demand-trading">
        <Route
          path=":tradeWindowId"
          element={<OnDemandPortfolioTradingInProgressPage />}
        />
      </Route>
      <Route path=":portfolioSliceId">
        <Route index element={<PortfolioPage />} />
        <Route path="trading" element={<PortfolioTradingDetails />} />
        <Route
          path="on-demand-trading"
          element={<OnDemandPortfolioTradingDetailsPage />}
        />
        <Route path="excluded-buys" element={<ExcludedBuysPage />} />
      </Route>
    </Route>
  );
};
