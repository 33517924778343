import { styled } from '@m1/liquid-react';
import * as React from 'react';

import { useBorrowAccountDetailsPageQuery } from '~/graphql/hooks';
import { BorrowMarketingHeaderAccountFragment } from '~/graphql/types';

import { useSelector } from '~/redux/hooks';

import { Spinner } from '~/toolbox/spinner';

import { BorrowUnavailableContainer } from '../margin/components/BorrowUnavailableContainer';

import { BorrowMarketingHeader } from './BorrowMarketingHeader';
import { BorrowMarketingUseCases } from './BorrowMarketingUseCases';

const StyledBorrowMarketingContainer = styled.div<{
  hasMultipleBorrowAccounts: boolean;
}>`
  position: relative;
  width: 100%;
`;

export const BorrowMarketingContainer = () => {
  const borrowAccountId = useSelector((state) => state.global.borrowAccountId);
  const { data, loading } = useBorrowAccountDetailsPageQuery({
    variables: {
      borrowAccountId: borrowAccountId as string,
    },
    skip: !borrowAccountId,
  });
  const borrowAccounts = data?.viewer.borrow?.borrowAccounts?.edges;
  const borrowAccount =
    data?.borrowAccount as BorrowMarketingHeaderAccountFragment;

  const hasMultipleBorrowAccounts = !(
    borrowAccounts?.length && borrowAccounts.length < 2
  );

  if (loading) {
    return <Spinner fullScreen />;
  }

  return data ? (
    <StyledBorrowMarketingContainer
      hasMultipleBorrowAccounts={hasMultipleBorrowAccounts}
    >
      <BorrowMarketingHeader
        borrowAccount={borrowAccount}
        viewer={data.viewer}
      />
      <BorrowMarketingUseCases
        borrowAccountId={borrowAccount?.id}
        maxBorrowableAccountValuePercent={
          data.viewer.borrow?.maxBorrowableAccountValuePercent
        }
      />
    </StyledBorrowMarketingContainer>
  ) : (
    <BorrowUnavailableContainer />
  );
};
