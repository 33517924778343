import { Button, HS, PL, Flex, Modal, ModalContent } from '@m1/liquid-react';
import * as React from 'react';

type DocumentRemoveModalProps = {
  closeModal: () => void;
  isModalOpen: boolean;
  onConfirmation: () => void;
};

export const DocumentRemoveModal = ({
  isModalOpen,
  onConfirmation,
  closeModal,
}: DocumentRemoveModalProps) => (
  <Modal open={isModalOpen} onClose={closeModal}>
    <ModalContent>
      <HS content="Remove Document" mb={16} textAlign="center" />
      <PL
        content="Are you sure you want to remove this document?"
        textAlign="center"
        mb={40}
      />
      <Flex alignItems="center" justifyContent="center">
        <Button kind="text" label="Cancel" onClick={closeModal} mr={40} />
        <Button
          label="Remove"
          kind="destructive"
          size="large"
          onClick={() => {
            closeModal();
            onConfirmation();
          }}
        />
      </Flex>
    </ModalContent>
  </Modal>
);
