import { Box, Flex, SkeletonProvider, styled, theme } from '@m1/liquid-react';
import * as React from 'react';

import { ChartableSliceChartProvider } from '~/components/ChartableSliceChart/hooks/ChartableSliceChartContext';
import { GenericSystemError } from '~/components/GenericSystemError';
import {
  useHomeAnnouncementsQuery,
  useLiveNetWorthQuery,
  useHomePageQuery,
} from '~/graphql/hooks';
import { AnnouncementDisplayPreferenceEnum } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useLayout } from '~/hooks/useLayout';

import { AppCard } from '~/lens-toolbox/AppCard/AppCard';
import { RichText } from '~/lens-toolbox/RichText/RichText';
import { Carousel } from '~/toolbox/carousel';

import { HomeAnnouncements } from './components/HomeAnnouncements';
import { HomeContainer } from './components/HomeContainer';
import { HomeDisclosures } from './components/HomeDisclosures';
import { HomeHeader } from './components/HomeHeader';
import { HomeTilesSections } from './components/HomeTilesSections';
import { HomeTransfers } from './components/HomeTransfers';

type StyledHomeProps = {
  topBarHeight: string;
};

const StyledHomeTileSection = styled(Flex)`
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 24px;
  gap: 24px;
  @media screen and (min-width: ${theme.breakpoints.SMALL}) {
    flex-direction: row;
  }
`;

const StyledHome = styled(Flex)<StyledHomeProps>`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${(props) => props.topBarHeight});
`;

export const HomePage = () => {
  const { topBarHeight } = useLayout();
  const analytics = useAnalytics();
  const { data, error, loading } = useHomePageQuery({
    variables: { first: 5 },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });
  const { data: netWorthData } = useLiveNetWorthQuery({
    fetchPolicy: 'network-only',
  });
  const { data: announcementsData } = useHomeAnnouncementsQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (error && !data?.viewer?.overview) {
    return (
      <GenericSystemError
        title="There was an issue when loading your net worth data."
        content="Please try again later."
      />
    );
  }

  // Keeping nullability of data so skeletons can be shown while it is null
  const { netWorth, overview, profile, save, transfers } = data?.viewer ?? {};
  const { liveNetWorth } = netWorthData?.viewer?.netWorth ?? {};
  const { balances, homeTilesSections, promotions } = overview ?? {};

  const announcementIsNudge =
    announcementsData?.viewer.announcements?.forHome?.displayPreference ===
    AnnouncementDisplayPreferenceEnum.Nudge;

  return (
    <SkeletonProvider isLoading={loading} fadeOut>
      <ChartableSliceChartProvider>
        <Box height={`calc(100vh-${topBarHeight}px)`} width="100%">
          <StyledHome topBarHeight={`${topBarHeight}px`}>
            <HomeHeader
              balances={balances}
              netWorth={netWorth}
              profile={profile}
              save={save}
              transfers={transfers}
              showMoveMoney={overview?.showMoveMoney ?? true}
              liveNetWorthData={liveNetWorth}
            />
            {/* If the announcement is a Nudge, we can't put it in the container
      since the blue background needs to be full width */}
            {announcementIsNudge && <HomeAnnouncements />}

            <HomeContainer>
              {!announcementIsNudge && <HomeAnnouncements />}
              <StyledHomeTileSection>
                <HomeTilesSections
                  homeTilesSectionConnection={homeTilesSections}
                  loading={loading}
                />
              </StyledHomeTileSection>
              {/* Many users won't have promos, so don't show skeleton for something they may not see */}
              {promotions?.cards &&
                promotions.cards?.length > 0 &&
                !loading && (
                  <Box my={24}>
                    <Box mb={16}>
                      <RichText
                        richText={promotions.header}
                        textProps={{ font: 'HXXS', fontWeight: 300 }}
                      />
                    </Box>
                    <Carousel
                      items={promotions.cards.map((appCard, i) => (
                        <AppCard
                          key={i}
                          appCard={appCard}
                          mx={1}
                          allowDisplayAnalytics={false}
                          hasSiblingCards={
                            promotions.cards && promotions.cards.length > 1
                          }
                        />
                      ))}
                      onItemChange={(selected) => {
                        const event =
                          promotions.cards?.[selected]?.displayAnalyticsEvent;
                        if (event) {
                          analytics?.recordAppAnalyticsEvent(event);
                        }
                      }}
                      fullSize
                      scrollSnap
                      indicator="numbers"
                      fixedProgressIndicator={false}
                    />
                  </Box>
                )}
              <HomeTransfers transfers={transfers} />
            </HomeContainer>
            <HomeDisclosures />
          </StyledHome>
        </Box>
      </ChartableSliceChartProvider>
    </SkeletonProvider>
  );
};
