import { PL, Flex, styled } from '@m1/liquid-react';
import React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { MarketingScreenTemplate } from '~/components/MarketingScreenTemplate';
import { useInvestMarketingScreenQuery } from '~/graphql/hooks';

import { AnnouncementContextEnum } from '~/graphql/types';

const FooterText = styled(Flex)`
  margin-top: 24px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.SMALL}) {
    margin-top: 0;
    margin-bottom: 50px;
  }
`;

export const InvestMarketingScreen = () => {
  const { data, loading } = useInvestMarketingScreenQuery({
    errorPolicy: 'all',
  });

  if (loading) {
    return null;
  }

  if (!data?.viewer) {
    return <GenericSystemError />;
  }

  const title = data?.viewer?.invest?.investMarketingScreen?.header;
  const productMarketingCards =
    data?.viewer?.invest?.investMarketingScreen?.productMarketingCards;
  const disclosures = data?.viewer?.invest?.investMarketingScreen?.disclosures;
  const footer = data?.viewer?.invest?.investMarketingScreen?.footer;
  const announcement = data?.viewer?.announcements?.forInvestMarketing;

  return (
    <MarketingScreenTemplate
      title={title}
      productMarketingCards={productMarketingCards}
      disclosures={disclosures}
      announcement={announcement}
      context={AnnouncementContextEnum.InvestMarketing}
    >
      <FooterText width="100%" justifyContent="center" textAlign="center">
        <PL content={footer} />
      </FooterText>
    </MarketingScreenTemplate>
  );
};
