import { styled, Flex } from '@m1/liquid-react';

export const StepParticipant = styled(Flex)`
  align-items: center;

  &:not(:first-child) {
    margin-top: 2px;
  }

  &:not(:last-child) {
    margin-bottom: 2px;
  }
`;
