import { LoanApplicationStatusEnum } from '~/graphql/types';

export const getApplicationStatusRoute = (
  status: LoanApplicationStatusEnum,
): {
  route: LoanApplicationStatusEnum | string;
  isWizardStep: boolean;
} => {
  switch (status) {
    case LoanApplicationStatusEnum.OffersProvided:
      return {
        route: 'PRE_APPROVED_OFFERS',
        isWizardStep: true,
      };
    case LoanApplicationStatusEnum.RejectedCreditFrozen:
      return {
        route: 'REJECTED_CREDIT_FROZEN',
        isWizardStep: true,
      };
    case LoanApplicationStatusEnum.IncomeVerificationRequired:
      return {
        route: 'INCOME_VERIFICATION_REQUIRED',
        isWizardStep: true,
      };
    case LoanApplicationStatusEnum.Approved:
      return {
        route: 'BANK_CONNECTION',
        isWizardStep: true,
      };
    case LoanApplicationStatusEnum.Rejected:
      return {
        route: '/direct-loan-application-error/application-rejected',
        isWizardStep: false,
      };
    case LoanApplicationStatusEnum.ManualReviewRequired:
      return {
        route: '/direct-loan-application-error/manual-review',
        isWizardStep: false,
      };
    default:
      return {
        route: '/direct-loan-application-error/application-received',
        isWizardStep: false,
      };
  }
};
