import {
  Box,
  Flex,
  HXS,
  PL,
  PS,
  styled,
  Tooltip,
  useTheme,
} from '@m1/liquid-react';
import * as React from 'react';

import { useSetGoalPageQuery } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import { Container } from '~/toolbox/container';
import { Grid } from '~/toolbox/grid';
import { GridTable } from '~/toolbox/grid-table';
import { Spinner } from '~/toolbox/spinner';

type Option = {
  description: string;
  name: string;
};

type OnboardingTable = {
  content: Array<Array<string>>;
  header: Array<string>;
};

type OnboardingTooltip = {
  table?: OnboardingTable;
  text?: Array<string>;
};

type Props = {
  content?: string;
  field: string;
  options: Array<Option>;
  question: string;
  tooltip?: OnboardingTooltip;
};

const StyledOption = styled(Box)`
  border: 2px solid;
  border-radius: 24px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  margin: 1rem;
  text-align: center;
  outline: none;
  padding: 1rem;
  user-select: none;

  ${(props) => `
    color: ${props.theme.colors.primary};
    border-color: ${props.theme.colors.primary};
  `}

  &:hover {
    ${(props) => `
      color: ${props.theme.colors.primaryShade};
      border-color: ${props.theme.colors.primaryShade};
    `}
  }
`;

const TableTooltip = ({
  table,
}: {
  table: OnboardingTable | null | undefined;
}) => {
  if (!table) {
    return null;
  }
  const headers = table.header.map((text) => (
    <GridTable.HeaderCell label={text} key={text} />
  ));

  const rows = table.content.map((row, idx) => (
    <GridTable.Row key={idx}>
      {row.map((text) => (
        <GridTable.Cell textAlign="left" content={text} key={text} />
      ))}
    </GridTable.Row>
  ));

  return (
    <GridTable gridTemplateColumns="80px 80px 80px auto">
      <GridTable.HeaderRow
        style={{
          height: '88px',
          alignContent: 'center',
        }}
      >
        {headers}
      </GridTable.HeaderRow>
      {rows}
    </GridTable>
  );
};

const CollectGoalsInput = ({
  content,
  field,
  options,
  question,
  tooltip,
}: Props) => {
  const navigate = useNavigate();
  const handleOptionClick = React.useCallback(() => {
    navigate({ to: '/start/product-selection' });
  }, [navigate]);

  return (
    <Grid.Row>
      <Grid.Col xs={12}>
        <Grid.Row>
          <Grid.Col xs={12} xsTextCenter>
            <Flex flexDirection="row" justifyContent="center" mb={16}>
              <HXS content={question} mr={4} />
              {tooltip && (
                <Tooltip
                  placement="bottom-start"
                  icon="help24"
                  body={
                    <Box>
                      {tooltip.text?.map((text: string) => (
                        <PS key={text} content={text} px={16} py={6} />
                      ))}
                      <TableTooltip table={tooltip.table} />
                    </Box>
                  }
                />
              )}
            </Flex>
            {content && <PL content={content} mb={32} />}
          </Grid.Col>
        </Grid.Row>

        <Grid.Row xsCenter>
          <Grid.Col xs={9}>
            {options.map(({ description, name }) => (
              <StyledOption
                key={`${field}:${name}`}
                onClick={() => handleOptionClick()}
              >
                {description}
              </StyledOption>
            ))}
          </Grid.Col>
        </Grid.Row>
      </Grid.Col>
    </Grid.Row>
  );
};

export const SetGoalPage = () => {
  const theme = useTheme();

  const { data, loading } = useSetGoalPageQuery();

  const learnToInvest: Option = {
    description: 'Learn to Invest',
    name: 'LEARN_INVESTMENT_STRATEGIES',
  };
  const growMyWealth: Option = {
    description: 'Grow my wealth',
    name: 'GROW_WEALTH',
  };
  const buildMyCredit: Option = {
    description: 'Build my credit',
    name: 'BUILD_CREDIT',
  };
  const buyAHouse: Option = {
    description: 'Buy a house',
    name: 'PURCHASE_HOME',
  };
  const prepForRetirement: Option = {
    description: 'Prepare for retirement',
    name: 'SAVE_FOR_RETIREMENT',
  };
  const travel: Option = {
    description: 'Save for travel',
    name: 'TRAVEL',
  };
  const other: Option = {
    description: 'Other',
    name: 'OTHER',
  };
  const skip: Option = {
    description: 'Skip for now',
    name: 'SKIP_FOR_NOW',
  };

  if (loading) {
    return <Spinner fullScreen />;
  }

  const collectUserGoalsOnboardingEnabled = Boolean(
    data?.viewer.onboarding?.collectUserGoalsOnboardingEnabled,
  );

  if (!collectUserGoalsOnboardingEnabled) {
    return <ProductSelectionRedirect />;
  }

  return (
    <Container
      css={`
        background-color: ${theme.colors.backgroundNeutralSecondary};
      `}
      marginTop={32}
    >
      <Box maxWidth={700} m="auto">
        <CollectGoalsInput
          question="We're thrilled you're here! What priorities can we help you achieve in the next 5 years?"
          tooltip={{
            text: [
              'We collect this information so we can send you useful material aimed at furthering these goals',
            ],
          }}
          options={[
            learnToInvest,
            growMyWealth,
            buildMyCredit,
            buyAHouse,
            prepForRetirement,
            travel,
            other,
            skip,
          ]}
          field="suitability.totalNetWorth"
        />
      </Box>
    </Container>
  );
};

const ProductSelectionRedirect = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate({ to: '/start/product-selection' });
  }, [navigate]);
  return null;
};
