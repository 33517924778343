import { ListItemProps } from '@m1/liquid-react';

import { AppAnalyticsEvent, MoveMoneyListItemFragment } from '~/graphql/types';

import { MoveMoneyClick } from './MoveMoneyOptions';

export const toListItemProps = (
  { icon, pill, link, ...item }: MoveMoneyListItemFragment,
  clickHandler: ({ route, event }: MoveMoneyClick) => void,
): ListItemProps =>
  ({
    ...item,
    size: item.size ?? 'MEDIUM',
    icon: {
      name: icon?.names?.[0],
      color: icon?.color,
    },
    selectable: true,
    withoutArrow: true,
    ...(pill ? { pill: { ...pill, kind: pill.kind.toLowerCase() } } : {}),
    onClick: () => {
      link?.internalPath &&
        clickHandler({
          route: link?.internalPath,
          event: link?.analyticsEvent as AppAnalyticsEvent,
        });
    },
    padding: '16px',
  }) as unknown as ListItemProps;
