import { useWizardContext } from '~/flows/wizard';

import { FinancialSuitabilityWizardSteps } from '../types';

export const useFinancialSuitabilityWizardContext = () => {
  const context = useWizardContext<FinancialSuitabilityWizardSteps>();

  if (!context) {
    throw new Error(
      'useFinancialSuitabilityWizardContext must be used within a Wizard provider!',
    );
  }

  return context;
};
