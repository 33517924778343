import {
  LL,
  Modal,
  ModalContent,
  ModalTitle,
  PL,
  Skeleton,
  SkeletonProvider,
} from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import { useMarginBillDueQuery } from '~/graphql/hooks';

import {
  BorrowBill,
  MarginBillDueDetailsFragment,
  MarginBillDueQuery,
} from '~/graphql/types';
import { useSelector } from '~/redux/hooks';
import { Divider } from '~/toolbox/divider';
import { Grid } from '~/toolbox/grid';

import { formatNumber } from '~/utils';

type BillStatementDetailsModalProps = {
  borrowBill: Maybe<
    | Pick<
        BorrowBill,
        | 'amount'
        | 'dueDate'
        | 'endDate'
        | 'rateDescription'
        | 'startDate'
        | 'isDue'
      >
    | MarginBillDueDetailsFragment
  >;
  onClose: (...args: Array<any>) => any;
  open: boolean;
};

export const BillStatementDetailsModal = ({
  borrowBill,
  onClose,
  open,
  ...rest
}: BillStatementDetailsModalProps) => {
  return (
    <Modal open={open} onClose={onClose} {...rest}>
      <ModalContent>
        <ModalTitle>Bill statement</ModalTitle>
        <BillRow
          label="Start date"
          value={moment(borrowBill?.startDate).format('ll')}
        />
        <BillRow
          label="End date"
          value={moment(borrowBill?.endDate).format('ll')}
        />
        <BillRow
          label="Due date"
          value={
            borrowBill?.dueDate ? moment(borrowBill.dueDate).format('ll') : '--'
          }
        />
        <BillRow
          label="Amount due"
          value={borrowBill?.amount ? formatNumber(borrowBill.amount) : '--'}
        />
        <BillRow
          label="Interest rate"
          value={
            borrowBill?.rateDescription ? `${borrowBill.rateDescription}` : '--'
          }
        />

        {borrowBill?.isDue && (
          <>
            <Divider spacing="relaxed" />
            <PL
              content="Amount will be automatically paid on the due date from your account's cash balance or, if not enough funds are available, we will extend additional credit to cover the amount."
              mb={16}
            />
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export const BillStatementDetailsQueriedModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const borrowAccountId = useSelector((state) => state.global.borrowAccountId);
  const { data, loading } = useMarginBillDueQuery({
    variables: { borrowAccountId: borrowAccountId as string },
    skip: !borrowAccountId,
  });

  const borrowAccount = data?.node as ExtractNodeTypename<
    MarginBillDueQuery,
    'BorrowAccount'
  >;

  return (
    <SkeletonProvider isLoading={loading}>
      <BillStatementDetailsModal
        borrowBill={borrowAccount?.billDue}
        onClose={onClose}
        open={open}
      />
    </SkeletonProvider>
  );
};

type BillRowProps = {
  label: string;
  value: string;
};

const BillRow = ({ label, value }: BillRowProps) => {
  return (
    <Grid.Row
      style={{
        paddingBottom: 16,
      }}
    >
      <Grid.Col xs={6}>
        <Skeleton>
          <PL content={label} />
        </Skeleton>
      </Grid.Col>
      <Grid.Col xs={6} xsTextRight>
        <Skeleton>
          <LL content={value} />
        </Skeleton>
      </Grid.Col>
    </Grid.Row>
  );
};
