import React from 'react';

import { AppContext } from '~/AppContext';
import { WithdrawModal } from '~/components/WithdrawModal';

type PersonalLoansWithdrawModalProps = {
  modalIsOpen: boolean;
  setModalIsOpen: (isOpen: boolean) => void;
  onFinish: () => void;
};

export const PersonalLoansWithdrawModal = ({
  modalIsOpen,
  setModalIsOpen,
  onFinish,
}: PersonalLoansWithdrawModalProps) => {
  const { analytics } = React.useContext(AppContext);

  return (
    <WithdrawModal
      open={modalIsOpen}
      onClick={() => {
        analytics.recordEvent('m1_personal_loan_speedbump_withdraw_confirmed');
        setModalIsOpen(false);
        return onFinish();
      }}
      onClose={() => {
        analytics.recordEvent('m1_personal_loan_speedbump_resume_clicked');
        return setModalIsOpen(false);
      }}
    />
  );
};
