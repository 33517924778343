import { Box } from '@m1/liquid-react';
import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { useLayout } from '~/hooks/useLayout';
import { StyledPageContent } from '~/pages/dashboard/navigation/Navigation.styled';

export const SettingsPage = () => {
  const { contentWidth, topBarHeight } = useLayout();

  return (
    <StyledPageContent
      contentWidth={contentWidth}
      backgroundColor="backgroundNeutralSecondary"
      minHeight={`calc(100vh - ${topBarHeight}px)`}
      margin="0px auto"
    >
      <PageErrorBoundary>
        <Box mt={48}>
          <Outlet />
        </Box>
      </PageErrorBoundary>
    </StyledPageContent>
  );
};
