import { Flex, Box } from '@m1/liquid-react';
import * as React from 'react';
import { FormProvider } from 'react-hook-form';

import { useFinancialSuitabilityQuery } from '~/graphql/hooks';

import { Profile } from '~/graphql/types';

import { FinancialSuitabilityWizard } from './FinancialSuitabilityWizard';
import { useFinancialSuitabilityWizardForm } from './hooks/useFinancialSuitabilityWizardForm';

export const FinancialSuitabilityPage = () => {
  const { data } = useFinancialSuitabilityQuery();
  const userProfile = data?.viewer?.profile;
  const formMethods = useFinancialSuitabilityWizardForm(userProfile as Profile);

  return (
    <Flex>
      <FormProvider {...formMethods}>
        <Box maxWidth={588} mx="auto">
          <form>
            <FinancialSuitabilityWizard />
          </form>
        </Box>
      </FormProvider>
    </Flex>
  );
};
