import { NOTIFICATION_LOCATIONS, NOTIFICATION_TYPES } from '~/static-constants';

export const NOTIFICATION_ACTIONS = Object.freeze({
  DISPLAY_NOTIFICATION: 'DISPLAY_NOTIFICATION' as const,
  DISMISS_NOTIFICATIONS: 'DISMISS_NOTIFICATIONS' as const,
  DISPLAY_SUCCESS_NOTIFICATION: 'DISPLAY_SUCCESS_NOTIFICATION' as const,
  DISPLAY_ERROR_NOTIFICATION: 'DISPLAY_ERROR_NOTIFICATION' as const,
});

export type DisplayNotificationLocation = ValueOf<
  typeof NOTIFICATION_LOCATIONS
>;
export type DisplayNotificationAction = {
  payload: {
    location: DisplayNotificationLocation;
    message: string;
    type: ValueOf<typeof NOTIFICATION_TYPES>;
  };
  type: typeof NOTIFICATION_ACTIONS.DISPLAY_NOTIFICATION;
};

export type DisplaySuccessNotificationAction = {
  payload: string;
  type: typeof NOTIFICATION_ACTIONS.DISPLAY_SUCCESS_NOTIFICATION;
};

export type DisplayErrorNotificationAction = {
  payload: string;
  type: typeof NOTIFICATION_ACTIONS.DISPLAY_ERROR_NOTIFICATION;
};

export type DismissNotificationsAction = {
  payload?:
    | string
    | {
        location: string | null | undefined;
        message: string;
        type: string;
      };
  type: typeof NOTIFICATION_ACTIONS.DISMISS_NOTIFICATIONS;
};

export type NotificationAction =
  | DisplayNotificationAction
  | DisplaySuccessNotificationAction
  | DisplayErrorNotificationAction
  | DismissNotificationsAction;
