import {
  SemanticColorNames,
  Tooltip,
  Box,
  validTooltipIcons,
} from '@m1/liquid-react';
import * as React from 'react';

import { AppTooltipFragment } from '~/graphql/types';

import { LinkableLink } from '../LinkableLink';

type TooltipProps = React.ComponentProps<typeof Tooltip>;

export const AppTooltip = ({
  appTooltip,
  ...rest
}: {
  appTooltip: AppTooltipFragment;
} & Partial<TooltipProps>) => {
  const name = validTooltipIcons.find((icon) =>
    appTooltip.icon.names?.includes(icon),
  );
  const iconName = name ? { icon: name } : {};

  return (
    <Tooltip
      header={appTooltip.header}
      link={
        appTooltip.link && <LinkableLink font="PS" linkable={appTooltip.link} />
      }
      iconColor={appTooltip.icon.color as SemanticColorNames}
      {...iconName}
      {...rest}
      body={
        <>
          {appTooltip.body.map((p, i) => (
            <Box as="p" key={p} mb={i >= appTooltip.body.length - 1 ? 0 : 8}>
              {p}
            </Box>
          ))}
        </>
      }
    />
  );
};
