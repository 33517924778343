import { PL, Flex, Box, HM } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { usePaymentsPageQuery } from '~/graphql/hooks';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

export const SettingsPaymentsPage = () => {
  const { loading, data, error } = usePaymentsPageQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  const viewer = data?.viewer;

  if ((error && !data) || !viewer) {
    return <GenericSystemError />;
  }

  const { user } = viewer;

  return (
    <Box maxWidth={600} px={16}>
      <HM content="Payments destination" fontWeight={300} />
      <PL pt={8}>Default account for receiving payments</PL>
      <Flex
        alignItems="center"
        borderColor="borderMain"
        borderRadius={8}
        borderStyle="solid"
        borderWidth={1}
        justifyContent="space-between"
        my={16}
        py={16}
        px={20}
      >
        <PL>
          {user?.billing?.selectedPaymentsAccount
            ? user.billing.selectedPaymentsAccount.account.name
            : 'No default account selected'}
        </PL>
        <Link to="/d/c/select-payment-account">Change</Link>
      </Flex>
    </Box>
  );
};
