import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { useNavigate } from '~/hooks/useNavigate';
import { useSearchParams } from '~/hooks/useSearchParams';

import { ResolveMarginCallFlow } from './ResolveMarginCallFlow';

export const ResolveMarginCallContext = React.createContext<{
  handleFinish: () => void;
} | null>(null);

export const ResolveMarginCallLayout = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const basePath = '/d/c/resolve-call';

  const previousRouteName = searchParams.get('previousRouteName');
  const from = searchParams.get('from');
  const previousRoute = previousRouteName ?? from ?? '/d/borrow';
  const borrowAccountId = searchParams.get('borrowAccountId');

  const handleFinish = React.useCallback(
    () => navigate({ to: previousRoute }),
    [navigate, previousRouteName],
  );

  return (
    <PageErrorBoundary onClickRetry={handleFinish} retryButtonLabel="Exit">
      <ResolveMarginCallFlow
        basePath={basePath}
        borrowAccountId={borrowAccountId}
        previousRouteName={previousRouteName}
        onFinish={handleFinish}
      />
    </PageErrorBoundary>
  );
};
