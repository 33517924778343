import {
  Flex,
  styled,
  spacingUnits,
  SemanticColorNames,
} from '@m1/liquid-react';

import { Link } from '../link/Link';

export const StyledToastFlex = styled(Flex)`
  align-items: flex-start;
  border-radius: 8px;
  flex-direction: row;
  min-height: 53px;
  min-width: 296px;
  max-width: 1600px;
  width: 100%;
  padding: ${spacingUnits.xs}px ${spacingUnits.s}px ${spacingUnits.xs}px
    ${spacingUnits.s}px;
  position: relative;
  z-index: 1000000;
  margin-inline: ${spacingUnits.xxxl}px;

  container: StyledToastFlex / inline-size;
`;

export const LinkContainer = styled(Flex)`
  /* match banner */
  && a,
  && a:visited {
    color: inherit;
  }
`;

export const ContentAndLink = styled(Flex)`
  flex-direction: row;
  width: 100%;

  @container StyledToastFlex (max-width: 575px) {
    flex-direction: column;

    ${LinkContainer} {
      margin-top: ${spacingUnits.xs}px;
    }
  }
`;

export const StyledIconButton = styled.button`
  align-self: start;
  background: transparent;
  margin-left: ${spacingUnits.m}px;
`;

export const StyledLink = styled(Link)<{
  color: SemanticColorNames;
  hoverColor?: SemanticColorNames;
}>`
  color: ${({ color, theme }) => theme.colors[color]};
  cursor: pointer;

  &:hover {
    color: ${({ color }) => color};
    text-decoration: underline;
  }
`;
