import { BoxProps, LayoutableProps, Box } from '@m1/liquid-react';
import * as React from 'react';

import { ListItem } from './ListItem';

export type ListProps = LayoutableProps & {
  // @ts-expect-error - TS2694 - Namespace 'React' has no exported member 'ChildrenArray'.
  children: React.ChildrenArray<React.ReactElement<typeof ListItem>>;
  font?: BoxProps['font'];
  ordered: boolean;
};

export const List = ({ children, ordered, ...rest }: ListProps) => {
  return (
    <Box {...rest} as={ordered ? 'ol' : 'ul'}>
      {children}
    </Box>
  );
};

List.Item = ListItem;
List.defaultProps = {
  ordered: false,
};
