import { LayoutableProps } from '@m1/liquid-react';
import * as React from 'react';

import { useDismissAnnouncementsMutation } from '~/graphql/hooks';
import {
  AnnouncementContextEnum,
  AnnouncementFragment,
  AnnouncementDisplayPreferenceEnum,
} from '~/graphql/types';

import { AnnouncementCard } from './AnnouncementCard';
import { AnnouncementModal } from './AnnouncementModal';
import { AnnouncementNudge } from './AnnouncementNudge';

export type AnnouncementContextProps = LayoutableProps & {
  announcement: AnnouncementFragment;
  context: AnnouncementContextEnum;
};

export const AnnouncementContext = ({
  announcement,
  context,
  ...props
}: AnnouncementContextProps) => {
  const [handleDismiss] = useDismissAnnouncementsMutation({
    variables: {
      input: {
        context,
      },
    },
  });

  const onDismiss = () => {
    handleDismiss();
  };

  if (
    announcement?.displayPreference === AnnouncementDisplayPreferenceEnum.Nudge
  ) {
    return <AnnouncementNudge announcement={announcement} />;
  } else if (
    announcement?.displayPreference === AnnouncementDisplayPreferenceEnum.Modal
  ) {
    return (
      <AnnouncementModal announcement={announcement} onDismiss={onDismiss} />
    );
  }

  return (
    <AnnouncementCard
      {...props}
      announcement={announcement}
      layout="wide"
      onDismiss={onDismiss}
    />
  );
};
