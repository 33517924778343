import { Box, BoxProps, css, keyframes, styled } from '@m1/liquid-react';
import * as React from 'react';

const fadeIn = keyframes`
  from {
    opacity: 0;
    top: 0;
  }
  to {
    opacity: 1;
    top: 24px;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    top: 24px;
  }
  to {
    opacity: 0;
    top: 0;
  }
`;

const FadeWrapper = styled(Box)<{
  status: FadeStatus;
  width?: string;
  maxWidth?: string;
}>`
  position: absolute;
  width: ${({ width }) => (width ? width : 'auto')};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '100%')};

  @media (prefers-reduced-motion: no-preference) {
    ${({ status }) =>
      status === 'in'
        ? css`
            animation-name: ${fadeIn};
          `
        : css`
            animation-name: ${fadeOut};
          `};
    animation-duration: 600ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
  }
`;

export type FadeStatus = 'in' | 'out';

export type FadeProps = {
  status: 'in' | 'out';
  children: React.ReactNode;
  width?: string;
  maxWidth?: string;
  onAnimationEnd: () => void;
} & BoxProps;

export const Fade = ({
  children,
  status,
  width,
  maxWidth,
  ...rest
}: FadeProps) => {
  return (
    <FadeWrapper
      {...rest}
      data-testid="animation-fade"
      status={status}
      width={width}
      maxWidth={maxWidth}
    >
      {children}
    </FadeWrapper>
  );
};
