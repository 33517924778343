import { Modal } from '@m1/liquid-react';
import * as React from 'react';

import { useModal } from '~/hooks/useModal';
import { useSelector } from '~/redux/hooks';

import { CashControlModalContent } from './CashControlModalContent';

type CashControlModalProps = {
  trigger: React.ReactElement;
};

export const CashControlModal = ({ trigger }: CashControlModalProps) => {
  const modalData = useModal('CASH_CONTROL');
  const {
    hideModal,
    showModal,
    modalState: { isOpened, payload },
  } = modalData;

  const hideCashMinimumInput = payload?.hideCashMinimumInput;
  const accountId = useSelector((state) => state.global.activeAccountId);

  if (!accountId) {
    return null;
  }

  return (
    <Modal
      onClose={hideModal}
      onOpen={showModal}
      open={isOpened}
      trigger={trigger}
    >
      <CashControlModalContent
        accountId={accountId}
        hideCashMinimumInput={hideCashMinimumInput}
      />
    </Modal>
  );
};
