import { CREATE_TRANSFER_FLOW_STEPS as STEPS } from '~/static-constants';

import {
  createStepFade,
  makeFlowComponent,
} from '../../../../../../flows/components/utils';

import { ConnectedCalculateNia as CalculateNia } from './CalculateNiaDeprecated';
import { ConfirmLiquidationStep } from './ConfirmLiquidationStepDeprecated';
import { ConfirmTransferScheduleStep } from './ConfirmTransferScheduleStepDeprecated';
import { ConfirmTransferStep } from './ConfirmTransferStepDeprecated';
import { IraDistributionStep } from './IraDistributionInfoDeprecated';
import { ConnectedNiaReport as NiaReport } from './NiaReportDeprecated';
import { SetupTransferStep } from './SetupTransferDeprecated';
import { ShowReceiptStep } from './ShowReceiptStepDeprecated';

export const CreateTransferFlow = makeFlowComponent({
  name: 'CREATE_TRANSFER',
  Component: createStepFade({
    [STEPS.SETUP_TRANSFER]: SetupTransferStep,
    [STEPS.CONFIRM_TRANSFER]: ConfirmTransferStep,
    [STEPS.CONFIRM_TRANSFER_SCHEDULE]: ConfirmTransferScheduleStep,
    [STEPS.CONFIRM_LIQUIDATION]: ConfirmLiquidationStep,
    [STEPS.SHOW_RECEIPT]: ShowReceiptStep,
    [STEPS.IRA_DISTRIBUTION_SETUP]: IraDistributionStep,
    [STEPS.NIA_REPORT]: NiaReport,
    [STEPS.CALCULATE_NIA]: CalculateNia,
  }),
});
