import * as React from 'react';

import { useAnalytics } from '~/hooks/useAnalytics';
import { useSelector } from '~/redux/hooks';

import { useCreateOneTimeTransfer } from '../hooks/useCreateOneTimeTransfer';
import { useCreateScheduledTransfer } from '../hooks/useCreateScheduledTransfer';
import { useInitialFundingFormContext } from '../hooks/useInitialFundingForm';

import {
  InitialDepositFormValues,
  MakeInitialDepositStep,
} from './MakeInitialDepositStep';

export const AdditiveMakeInitialDepositStep = () => {
  const analytics = useAnalytics();
  const nextStep = { confirmationStep: 'DEPOSIT_CONFIRMATION' } as const;
  const idempotencyKey = useSelector(
    (state) => state.newFlows.initialFunding.idempotencyKey,
  );
  const createOneTimeTransfer = useCreateOneTimeTransfer(nextStep);
  const createScheduledTransfer = useCreateScheduledTransfer(nextStep);
  const { watch } = useInitialFundingFormContext();
  const { achRelationshipId } = watch();

  return (
    <MakeInitialDepositStep
      showSkipButton={false}
      onSubmit={(formValues: InitialDepositFormValues) => {
        const {
          accountId,
          fundAccountAmount,
          isIraRollover,
          iraContributionYear,
          isOneTimeDeposit,
          schedule,
        } = formValues;

        analytics.recordEvent('m1_initial_deposit_created_onboarding');

        if (
          isOneTimeDeposit &&
          achRelationshipId &&
          accountId &&
          idempotencyKey
        ) {
          createOneTimeTransfer({
            input: {
              amount: fundAccountAmount,
              isIraRollover,
              iraContributionYear,
              fromParticipantId: achRelationshipId,
              toParticipantId: accountId,
              idempotencyKey,
            },
            isInitialFunding: true,
          });
        } else if (achRelationshipId && accountId && idempotencyKey) {
          createScheduledTransfer({
            input: {
              amount: fundAccountAmount,
              iraContributionYear,
              fromParticipantId: achRelationshipId,
              toParticipantId: accountId,
              schedule,
              idempotencyKey,
            },
            isInitialFunding: true,
          });
        }
      }}
    />
  );
};
