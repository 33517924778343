import * as React from 'react';
import { Route } from 'react-router-dom';

import { SetOrderPage } from './SetOrderPage';

export const SetOrderRoute = () => {
  return (
    <Route
      path="set-order/:portfolioSliceId/:step?" // component={SetOrderPage}
      element={<SetOrderPage />}
      handle={{
        fallbackRoute: {
          to: '/d/invest/portfolio',
        },
      }}
    />
  );
};
