import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import {
  HasInvestSuitabilityDocument,
  HasInvestSuitabilityQueryResult,
} from '~/graphql/hooks';

import { apolloQuerySaga } from '../apolloQuerySaga';

export function* hasInvestSuitability(): SagaIterator<boolean> {
  const { data }: HasInvestSuitabilityQueryResult = yield call(
    apolloQuerySaga,
    {
      query: HasInvestSuitabilityDocument,
    },
  );

  if (
    !data?.viewer ||
    !data.viewer.profile ||
    !data.viewer.profile.suitability
  ) {
    throw new Error(
      'Unable to determine if user has existing financial suitability.',
    );
  }

  const { suitability } = data.viewer.profile;

  return Boolean(
    suitability.investmentExperience &&
      suitability.liquidityNeeds &&
      suitability.riskTolerance &&
      suitability.timeHorizon,
  );
}
