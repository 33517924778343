import * as React from 'react';

import { SliceableFragment } from '~/graphql/types';

import { CryptoBreadCrumbs } from './CryptoBreadCrumbs';
import { FundBreadCrumbs } from './FundBreadCrumbs';
import { StockBreadCrumbs } from './StockBreadCrumbs';
import { SystemPieBreadCrumbs } from './SystemPieBreadCrumbs';
import { UserPieBreadCrumbs } from './UserPieBreadCrumbs';

type Props = {
  navigable?: boolean;
  sliceable: SliceableFragment;
};

export const SliceableBreadCrumbs = ({
  sliceable,
  navigable = true,
}: Props) => {
  switch (sliceable.__typename) {
    case 'CryptoAsset':
      return <CryptoBreadCrumbs navigable={navigable} />;
    case 'Equity':
      return <StockBreadCrumbs stock={sliceable} navigable={navigable} />;
    case 'Fund':
      return <FundBreadCrumbs fund={sliceable} navigable={navigable} />;
    case 'SystemPie':
      return (
        <SystemPieBreadCrumbs systemPie={sliceable} navigable={navigable} />
      );
    case 'UserPie':
      return <UserPieBreadCrumbs navigable={navigable} />;
    default:
      return null;
  }
};
