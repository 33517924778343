import { Box, Button, Flex, HXS, PL } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { SmartTransferFocusOptionCards } from '~/components/SmartTransferFocusOptionCards';
import { useInitializeSmartTransferQuery } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';

import {
  OnboardingSmartTransferModal,
  SmartTransferBubble,
  SmartTransferToggle,
} from '../../../../components/SmartTransferHelpers';

import { LoadingState } from './LoadingState';

type InitializeSmartTransferProps = {
  onFinishStep: (focusOptionId: string) => void;
};

const NoSavingsAccount = () => {
  const navigate = useNavigate();

  return (
    <Box maxWidth={490} mx="auto" pt={100}>
      <BackArrow content="Move Money" mb={32} to="/d/move-money" />
      <Flex flexDirection="column" alignItems="center">
        <Illustration name="smartTransfer" />
        <HXS
          content="Enroll in a high-yield account to unlock Smart Transfers"
          mt={64}
          textAlign="center"
        />
        <PL
          content="Transfer money among your M1 accounts automatically with rules you create."
          my={32}
          textAlign="center"
        />
        <Button
          label="Learn More"
          mt={16}
          onClick={() => {
            navigate({ to: '/d/save' });
          }}
          size="large"
        />
      </Flex>
    </Box>
  );
};

export const InitializeSmartTransfer = ({
  onFinishStep,
}: InitializeSmartTransferProps) => {
  const navigate = useNavigate();
  const { data, loading } = useInitializeSmartTransferQuery();
  if (loading) {
    return <LoadingState />;
  }

  if (!data?.viewer.transfers?.isReadyForSmartTransfers) {
    return <NoSavingsAccount />;
  }

  const newSmartTransferFocusOptionSet =
    data.viewer.transfers?.newSmartTransferFocusOptionSet;
  if (!newSmartTransferFocusOptionSet) {
    return <GenericSystemError />;
  }
  const headerParagraph = `Your M1 accounts are interconnected, and money can move between them automatically using rules you set up. The rules are called Smart Transfers.`;

  return (
    <>
      <OnboardingSmartTransferModal user={data.viewer.user} />
      <Box maxWidth={650} mx="auto" pt={100}>
        <BackArrow
          content="Home"
          mb={16}
          onClick={() => {
            navigate({ to: '/d/home' });
          }}
        />
        <Flex justifyContent="space-between">
          <HXS content="Create Smart Transfer" />
          <SmartTransferToggle />
        </Flex>
        <PL mt={16} mb={32} content={headerParagraph} />
        <SmartTransferFocusOptionCards
          newSmartTransferFocusOptionSet={newSmartTransferFocusOptionSet}
          onFocusOptionSelect={(focusOptionId) => {
            onFinishStep(focusOptionId);
          }}
        />
        <SmartTransferBubble
          activeStepNumber={1}
          description="First, select an account that will trigger money movements. Overbalance types move money out of this account and underbalance types refill this account. The quantity of open accounts limits the number of Smart Transfers that can be created."
          stepNumber={1}
          title="Starting a Smart Transfer"
          totalSteps={1}
        />
      </Box>
    </>
  );
};
