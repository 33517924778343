import { Box, Button } from '@m1/liquid-react';
import * as React from 'react';

import { useForm, FormProvider } from 'react-hook-form';

import { useReferralPaymentAccountQuery } from '~/graphql/hooks';
import { SelectPaymentsAccountInput } from '~/graphql/types';
import { Grid } from '~/toolbox/grid';
import { Spinner } from '~/toolbox/spinner';

import { PaymentAccountField } from './PaymentAccountField';

type ChangePaymentAccountFormProps = {
  onSubmit: (d: SelectPaymentsAccountInput) => any;
};

export const ChangePaymentAccountForm = ({
  onSubmit,
}: ChangePaymentAccountFormProps) => {
  const formMethods = useForm<SelectPaymentsAccountInput>();

  const { data, loading } = useReferralPaymentAccountQuery({
    variables: {
      first: 100,
      filterForPaymentsEligibility: true,
    },
    onCompleted: (data) => {
      const selectedAccount =
        data?.viewer.user?.billing.selectedPaymentsAccount?.account.id;
      if (selectedAccount) {
        formMethods.setValue('accountId', selectedAccount);
      }
    },
  });

  if (loading) {
    return <Spinner />;
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Grid.Row>
          <Grid.Col xs={12}>
            <Box mb={64}>
              <PaymentAccountField accounts={data?.viewer.accounts} />
            </Box>
            <Button type="submit" kind="primary" label="Confirm" size="large" />
          </Grid.Col>
        </Grid.Row>
      </form>
    </FormProvider>
  );
};
