import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import {
  SmartTransferEditForm,
  SmartTransferEditFormProps,
} from '~/components/SmartTransferEditForm';
import {
  useEditSmartTransferPagePrefetchQuery,
  useEditSmartTransferPageQuery,
} from '~/graphql/hooks';
import { SmartTransferBalanceTriggerInput } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { useParams } from '~/hooks/useParams';
import {
  enteredPage,
  exitedPage,
  finishedEditingSmartTransfer,
} from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { SmartTransferContraParticipantEntryInput } from '~/redux/reducers/newFlows/reducers/createSmartTransferReducer';
import { Spinner } from '~/toolbox/spinner';

export type EditSmartTransferPageProps = {
  params: RouteParams;
};

type SetSmartTransferRuleInput = {
  balanceTrigger: SmartTransferBalanceTriggerInput;
  contraParticipantEntries: Array<SmartTransferContraParticipantEntryInput>;
  existingSmartTransferRuleId: string | null | undefined;
  focusParticipantId: string | null | undefined;
};

type RouteParams = {
  contraParticipantEntryEditRequirementsId?: string;
  newContraParticipantEntryOptionId?: string;
  smartTransferRuleId: string;
};

const determineFormModeFromRouteParams = (
  params: RouteParams,
): SmartTransferEditFormProps['mode'] => {
  if (params.contraParticipantEntryEditRequirementsId) {
    return {
      contraParticipantEntryEditRequirementsId:
        params.contraParticipantEntryEditRequirementsId,
      type: 'EDIT_CONTRA_ENTRY',
    };
  } else if (params.newContraParticipantEntryOptionId) {
    return {
      newContraParticipantEntryOptionId:
        params.newContraParticipantEntryOptionId,
      type: 'ADD_CONTRA_ENTRY',
    };
  }
  return {
    type: 'EDIT_RULE',
  };
};

export const EditSmartTransferPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { smartTransferRuleId } = params;

  React.useEffect(() => {
    // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
    dispatch(enteredPage('EDIT_SMART_TRANSFER'));
    // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
    return () => dispatch(exitedPage('EDIT_SMART_TRANSFER'));
  }, [dispatch]);

  const { data, loading } = useEditSmartTransferPagePrefetchQuery({
    variables: {
      smartTransferRuleId: smartTransferRuleId as string,
    },
    skip: !smartTransferRuleId,
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  const smartTransferRule =
    data?.node?.__typename === 'SmartTransferRule' ? data.node : null;

  return (
    <Flex justifyContent="center">
      <PageErrorBoundary retryButtonLabel="Exit">
        <SmartTransferEditFormRenderer
          initialContraParticipantEntries={toContraParticipantEntries(
            smartTransferRule,
          )}
          initialInsertionContext={null}
          params={{
            smartTransferRuleId: smartTransferRuleId as string,
            contraParticipantEntryEditRequirementsId:
              params.contraParticipantEntryEditRequirementsId,
            newContraParticipantEntryOptionId:
              params.newContraParticipantEntryOptionId,
          }}
          smartTransferRuleId={smartTransferRuleId as string}
        />
      </PageErrorBoundary>
    </Flex>
  );
};

const toContraParticipantEntries = (smartTransferRule: any): any => {
  if (!smartTransferRule.contraParticipantEntries) {
    throw new Error('Entries required.');
  }

  const entries = [];
  for (const entry of smartTransferRule.contraParticipantEntries) {
    if (!entry) {
      throw new Error('Entry required.');
    }
    entries.push({
      existingContraParticipantEntryId: entry.id,
      fulfillmentCondition: {
        fulfillmentConditionType:
          entry.fulfillmentCondition.fulfillmentConditionType,
        borrowAmount: entry.fulfillmentCondition.borrowAmount,
        borrowUtilization: entry.fulfillmentCondition.utilization,
        capAmount: entry.fulfillmentCondition.capAmount,
        fundingAmount: entry.fulfillmentCondition.fundingAmount,
        fundingPeriod: entry.fulfillmentCondition.fundingPeriod,
      },
    });
  }
  return entries;
};

const SmartTransferEditFormRenderer = ({
  initialContraParticipantEntries,
  initialInsertionContext,
  params,
  smartTransferRuleId,
}: {
  initialContraParticipantEntries: ReadonlyArray<SmartTransferContraParticipantEntryInput>;
  initialInsertionContext: any | null | undefined;
  params: RouteParams;
  smartTransferRuleId: string;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = (input: string): void => {
    dispatch(finishedEditingSmartTransfer(input));
  };

  const handleSubmit = (input: SetSmartTransferRuleInput): void => {
    dispatch(finishedEditingSmartTransfer(input));
  };

  const { data, loading, refetch } = useEditSmartTransferPageQuery({
    variables: {
      contraParticipantEntries: initialContraParticipantEntries as any,
      insertionContext: initialInsertionContext as any,
      smartTransferRuleId,
    },
  });

  const smartTransferRule =
    data?.node?.__typename === 'SmartTransferRule' ? data.node : null;

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!smartTransferRule) {
    return null;
  }

  return (
    <SmartTransferEditForm
      mode={determineFormModeFromRouteParams(params)}
      onClickAddContraEntry={(newContraParticipantEntryOptionId) =>
        navigate({
          to: `/d/c/edit-smart-transfer/:smartTransferRuleId/new-contra-entry/:newContraParticipantEntryOptionId`,
          params: {
            newContraParticipantEntryOptionId,
            smartTransferRuleId,
          },
        })
      }
      onClickEditContraEntry={(contraParticipantEntryEditRequirementsId) =>
        navigate({
          to: `/d/c/edit-smart-transfer/:smartTransferRuleId/edit-contra-entry/:contraParticipantEntryEditRequirementsId`,
          params: {
            contraParticipantEntryEditRequirementsId,
            smartTransferRuleId,
          },
        })
      }
      onDelete={handleDelete}
      onSubmit={handleSubmit}
      smartTransferRule={smartTransferRule}
      refetch={refetch}
    />
  );
};
