import { Box } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { togglePortfolioOrganizerHelperDialogAction } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

export const HelperDialogCloseIcon = () => {
  const dispatch = useDispatch();

  return (
    <Box
      as="span"
      height={16}
      width={16}
      style={{
        cursor: 'pointer',
      }}
    >
      <Icon
        onClick={() => {
          dispatch(togglePortfolioOrganizerHelperDialogAction());
        }}
        name="close16"
      />
    </Box>
  );
};
