import classNames from 'classnames';
import * as React from 'react';

import style from './style.module.scss';

type Props = {
  children?: React.ReactNode;
  className?: string | null | undefined;
  fixed?: boolean;
};

export class TableHead extends React.Component<Props> {
  static defaultProps = {
    className: '',
    fixed: false,
  };

  render() {
    const { fixed, children, className } = this.props;
    const classes = classNames(style.tableHead, className, {
      [style.fixedHeader]: fixed,
    });

    return <thead className={classes}>{children}</thead>;
  }
}
