import { useMemo } from 'react';

type Primitive = string | number | boolean | null;

/**
 * Return a stable reference for a simple array or object, memoized by value.
 *
 * Note this function will stringify the input. This has the potential to be
 * slow or unpredictable for complex objects, large arrays, or types that do
 * not serialize to JSON. The types are intentionally strict to prevent
 * common mistakes; be cautious about using this hook. It is intended for
 * memoizing simple, potentially hard-coded objects or arrays that are used as
 * dependencies to other hooks.
 *
 * See https://github.com/facebook/react/issues/14476#issuecomment-471199055
 * for more details on why this is useful, and why it should be a last resort.
 *
 * @example
 * function MyComponent({
 *   keys = [0, 1, 2]
 * }) {
 *   // wrong: keys will be a new array each render
 *   // useEffect(() => {
 *   //   fetchItems(keys)
 *   // }, [keys])
 *
 *   // correct: memoizedKeys will only change if the values do
 *   const memoizedKeys = useStableReference(keys)
 *   useEffect(() => {
 *     fetchItems(memoizedKeys)
 *   }, [memoizedKeys])
 * }
 *
 * @param value Array or un-nested object of primitives
 * @returns A copy of `value`, memoized by the contents of the array or object
 */
export const useStableReference = <
  T extends Primitive[] | { [key: string]: Primitive },
>(
  value: T,
): T => {
  // Ignore exhaustive deps so that we can compare by value,
  // not by reference. We can use stringify to check equality
  // on a simple object or array of primitives.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => value, [JSON.stringify(value)]);
};
