import CdsSender from 'cross-domain-storage-sender';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore } from 'redux';

import { App } from './app';
import { AuthManager } from './auth';
import { createClient } from './graphql/apolloUtils';
import { LaunchDarkly } from './launch-darkly';
import { AnalyticsReporter, SentryReporter } from './loggers';
import { Provider } from './Provider';
import { ACTION_TYPES } from './redux/actions';
import { createStoreEnhancer } from './redux/createStore';
import { appReducer as rootReducer } from './redux/reducers';
import { rootSaga } from './redux/sagas';

// Import global styles
import './styles/index.module.scss';

async function run(config: Record<string, any>): Promise<void> {
  const auth = new AuthManager();
  const analytics = new AnalyticsReporter();
  const sentry = new SentryReporter(config.sentry.dsn, config.sentry.config);
  const anonymousUserId = await analytics.getValidAnonymousUserId(sentry);
  const launchDarkly = new LaunchDarkly(config.launchDarkly, anonymousUserId);

  const [enhancer, sagaMiddleware, rehydrateStore] =
    createStoreEnhancer(sentry);

  const store = createStore(rootReducer, rehydrateStore(), enhancer);

  store.dispatch({
    type: ACTION_TYPES.SET_ANONYMOUS_USER_ID,
    payload: anonymousUserId,
  });

  const cdsSender = new CdsSender(config.cross_domain_storage.url);

  const apolloClient = createClient(
    store.dispatch,
    anonymousUserId,
    auth,
    analytics,
  );

  const context = {
    analytics,
    auth,
    cdsSender,
    launchDarkly,
    sentry,
    store,
    apolloClient,
  };

  sagaMiddleware.run(rootSaga, context);

  const container = document.getElementById('root') as HTMLElement;
  const root = createRoot(container);
  root.render(
    <Provider {...context}>
      <App />
    </Provider>,
  );
}

// Application entry point.
run(window.config);
