import { Box, styled } from '@m1/liquid-react';

export const StyledTitleContainer = styled(Box)`
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderMain};
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;

export type StyledStepOuterContainerProps = {
  $isClickable: boolean;
  $isDisabled: boolean;
};

export const StyledStepOuterContainer = styled(
  Box,
)<StyledStepOuterContainerProps>`
  background-color: ${(props) => props.theme.colors.backgroundNeutralSecondary};
  ${(props) => props.$isClickable && `cursor: pointer;`} ${(props) =>
    props.$isDisabled &&
    `color: ${props.theme.colors.foregroundNeutralTertiary};`}
  &:last-child {
    border-radius: 0 0 8px 8px;
  }
  &:hover {
    background-color: ${(props) =>
      props.theme.colors.backgroundNeutralTertiary};
  }
`;

export type StyledStepInnerContainerProps = {
  $isLastRow: boolean;
};

export const StyledStepInnerContainer = styled(
  Box,
)<StyledStepInnerContainerProps>`
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderMain};
  display: flex;
  justify-content: space-between;
  margin-left: 16px;
  padding: 16px 16px 16px 0;
  ${(props) => props.$isLastRow && `border-bottom-color: transparent;`};
`;

type StyledCustomCheckboxProps = {
  $isClickable: boolean;
  $isDisabled: boolean;
};

export const StyledCustomCheckbox = styled(Box)<StyledCustomCheckboxProps>`
  width: 22px;
  height: 22px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  color: ${(props) => props.theme.colors.foregroundNeutralInverse};
  font-weight: 700;

  ${({ $isClickable, $isDisabled, theme }) => {
    if ($isClickable) {
      return `
        background-color: ${theme.colors.backgroundNeutralMain};
        border: 1px solid ${theme.colors.foregroundNeutralTertiary};
      `;
    } else if ($isDisabled) {
      return `
        background-color: ${theme.colors.backgroundNeutralMain};
        border: 1px solid ${theme.colors.foregroundNeutralTertiary};
      `;
    }
    return `background-color: ${theme.colors.primary};`;
  }};
`;
