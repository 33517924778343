import { Flex, HM, PL } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useFullyPaidLendingSettingsPageQuery } from '~/graphql/hooks';
import { FullyPaidLendingSettingsPageQuery } from '~/graphql/types';
import { AppImage } from '~/lens-toolbox/AppImage';
import { InformationBanner } from '~/lens-toolbox/InformationBanner';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { Spinner } from '~/toolbox/spinner';

import { AccountStockLoansTable } from './AccountStockLoansTable';
import { FullyPaidLendingOptInSection } from './FullyPaidLendingOptInSection';

export type FullyPaidLendingPage = NonNullable<
  NonNullable<
    NonNullable<
      FullyPaidLendingSettingsPageQuery['viewer']['settings']
    >['invest']
  >['fullyPaidLending']
>;

export const FullyPaidLendingSettingsPage = () => {
  const { data, loading } = useFullyPaidLendingSettingsPageQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (loading && !data) {
    return <Spinner fullScreen />;
  }

  const fullyPaidLending = data?.viewer.settings?.invest?.fullyPaidLending;

  if (!fullyPaidLending) {
    return <GenericSystemError />;
  }

  const {
    accountStockLoanHoldings,
    banner,
    helpLink,
    incomeEarnedSection,
    optInSection,
    subtitle,
    title,
  } = fullyPaidLending;

  return (
    <>
      {banner && (
        <InformationBanner appBanner={banner} contain={false} mb={24} />
      )}
      <HM content={title} fontWeight={300} />
      <PL content={subtitle} mt={8} />
      {helpLink && <LinkableLink linkable={helpLink} mt={8} />}

      {optInSection && (
        <FullyPaidLendingOptInSection mt={24} optInSection={optInSection} />
      )}

      {incomeEarnedSection && (
        <Flex alignItems="center" justifyContent="center" mt={24}>
          {incomeEarnedSection.icon && (
            <AppImage appImage={incomeEarnedSection.icon} />
          )}
          <PL
            color="foregroundNeutralSecondary"
            content={incomeEarnedSection.content}
          />
        </Flex>
      )}

      <AccountStockLoansTable
        accountStockLoanHoldings={accountStockLoanHoldings}
        mt={16}
      />
    </>
  );
};
