import { APP_MODES } from '~/static-constants';

import { ACTION_TYPES } from '../actions';

type MODE_ENUM = 'ADD' | 'EDIT' | 'DEFAULT';

export type ModeState = MODE_ENUM;

export const initialState: ModeState = APP_MODES.DEFAULT;

export const modeReducer = (
  state: ModeState = initialState,
  action: any,
): ModeState => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_MODE:
      return action.payload;
    case ACTION_TYPES.RESET_MODE:
      return APP_MODES.DEFAULT;
    default:
      return state;
  }
};
