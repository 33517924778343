import * as React from 'react';
import { Route, Navigate } from 'react-router-dom';

import { useParams } from '~/hooks/useParams';

import { ResearchCryptoRoute } from './crypto';
import { FundsScreenerRoute } from './funds';
import { ResearchMarketNewsRoute } from './market-news';
import { ModelPortfolioCategoriesRoute } from './model-portfolios';
import { MyPiesResearchRoute } from './my-pies';
import { ResearchLayout } from './ResearchLayout';
import { StockScreenerRoute } from './stocks';
import { WatchlistRoute } from './watchlist';

const ExpertPiesRedirect = () => {
  const { categoryKey } = useParams();
  return (
    <Navigate to={`/d/research/model-portfolios/${categoryKey}`} replace />
  );
};

const ExpertPieDetailsRedirect = () => {
  const { modelPortfolioId } = useParams();
  return (
    <Navigate
      to={`/d/research/model-portfolios/details/${modelPortfolioId}`}
      replace
    />
  );
};

export const ResearchRoute = () => {
  return (
    <Route path="research" element={<ResearchLayout />}>
      <Route path="expert-pies">
        <Route index element={<Navigate to="/d/research/model-portfolios" />} />
        <Route path=":categoryKey" element={<ExpertPiesRedirect />} />
        <Route
          path="details/:modelPortfolioId"
          element={<ExpertPieDetailsRedirect />}
        />
      </Route>
      {ModelPortfolioCategoriesRoute()}
      {FundsScreenerRoute()}
      {ResearchMarketNewsRoute()}
      {MyPiesResearchRoute()}
      {StockScreenerRoute()}
      {ResearchCryptoRoute()}
      {WatchlistRoute()}
      <Route
        index
        element={<Navigate to="/d/research/market-news" replace />}
      />
    </Route>
  );
};
