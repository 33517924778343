import * as React from 'react';

import { Grid } from '~/toolbox/grid';
import { getEnumEntries } from '~/utils';

import {
  required,
  printableAsciiChars,
  maxLength,
  postalCode,
} from '../validators';

import { DropdownField } from './dropdown-field';
import { TextField } from './text-field';

type AddressFieldsProps = {
  backgroundColor?: 'backgroundNeutralMain' | 'backgroundNeutralSecondary';
  condensed?: boolean | null | undefined;
  disabled: boolean;
  kind: 'NORMAL' | 'CUSTODIAL';
  modernLayout?: boolean | null | undefined;
};

export class AddressFields extends React.Component<AddressFieldsProps> {
  static defaultProps = {
    disabled: false,
  };

  static maxCityLength: (...args: Array<any>) => any = maxLength(28);
  static maxLength: (...args: Array<any>) => any = maxLength(30);
  render() {
    const { condensed, modernLayout } = this.props;

    const stateOrProvinceEnum =
      this.props.kind === 'CUSTODIAL'
        ? 'MailingAddressSubdivisionForCustodialBeneficiariesEnum'
        : 'MailingAddressSubdivisionEnum';

    const stateOrProvinceDropdownSource = getEnumEntries(stateOrProvinceEnum);

    if (modernLayout) {
      return (
        <Grid.Row>
          <Grid.Col xs={12}>
            <Grid.Row>
              <Grid.Col sm={condensed ? 12 : 8} xs={12}>
                <TextField
                  backgroundColor={this.props.backgroundColor}
                  name="lineOne"
                  label="Address"
                  placeholder=""
                  maxLength={30}
                  validate={[
                    required,
                    printableAsciiChars,
                    AddressFields.maxLength,
                  ]}
                  disabled={this.props.disabled}
                />
              </Grid.Col>
              <Grid.Col sm={condensed ? 12 : 4} xs={12}>
                <TextField
                  backgroundColor={this.props.backgroundColor}
                  name="lineTwo"
                  label="Apt, Fl, Suite (Opt.)"
                  maxLength={30}
                  validate={[printableAsciiChars, AddressFields.maxLength]}
                  disabled={this.props.disabled}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row
              style={{
                marginTop: 16,
              }}
            >
              <Grid.Col sm={condensed ? 12 : 8} xs={12}>
                <TextField
                  backgroundColor={this.props.backgroundColor}
                  name="city"
                  label="City"
                  maxLength={28}
                  validate={[
                    required,
                    printableAsciiChars,
                    AddressFields.maxCityLength,
                  ]}
                  disabled={this.props.disabled}
                />
              </Grid.Col>
              <Grid.Col sm={condensed ? 12 : 4} xs={12}>
                <DropdownField
                  backgroundColor={this.props.backgroundColor}
                  name="stateOrProvince"
                  label="State"
                  source={stateOrProvinceDropdownSource}
                  validate={required}
                  disabled={this.props.disabled}
                  displayOptionValue
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row
              style={{
                marginTop: 16,
              }}
            >
              <Grid.Col sm={condensed ? 12 : 8} xs={12}>
                <TextField
                  backgroundColor={this.props.backgroundColor}
                  name="postalCode"
                  label="Postal code"
                  maxLength={5}
                  validate={[required, postalCode]}
                  disabled={this.props.disabled}
                />
              </Grid.Col>
              <TextField
                backgroundColor={this.props.backgroundColor}
                name="country"
                type="hidden"
                value="USA"
                disabled={this.props.disabled}
              />
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      );
    }
    return (
      <Grid.Row>
        <Grid.Col xs={12}>
          <Grid.Row>
            <Grid.Col sm={condensed ? 12 : 8} xs={12}>
              <TextField
                backgroundColor={this.props.backgroundColor}
                name="lineOne"
                label="Address"
                placeholder=""
                maxLength={30}
                validate={[
                  required,
                  printableAsciiChars,
                  AddressFields.maxLength,
                ]}
                disabled={this.props.disabled}
              />
            </Grid.Col>
            <Grid.Col sm={condensed ? 12 : 4} xs={12}>
              <TextField
                backgroundColor={this.props.backgroundColor}
                name="lineTwo"
                label="Apt, Fl, Suite (Opt.)"
                maxLength={30}
                validate={[printableAsciiChars, AddressFields.maxLength]}
                disabled={this.props.disabled}
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col sm={condensed ? 12 : 5} xs={12}>
              <TextField
                backgroundColor={this.props.backgroundColor}
                name="city"
                label="City"
                maxLength={28}
                validate={[
                  required,
                  printableAsciiChars,
                  AddressFields.maxCityLength,
                ]}
                disabled={this.props.disabled}
              />
            </Grid.Col>
            <Grid.Col sm={condensed ? 6 : 3} xs={12}>
              <DropdownField
                backgroundColor={this.props.backgroundColor}
                name="stateOrProvince"
                label="State"
                source={stateOrProvinceDropdownSource}
                validate={required}
                disabled={this.props.disabled}
                displayOptionValue
              />
            </Grid.Col>
            <Grid.Col sm={condensed ? 6 : 4} xs={12}>
              <Grid.Row>
                <Grid.Col xs={12}>
                  <TextField
                    backgroundColor={this.props.backgroundColor}
                    name="postalCode"
                    label="Postal code"
                    maxLength={5}
                    validate={[required, postalCode]}
                    disabled={this.props.disabled}
                  />
                </Grid.Col>
                <TextField
                  backgroundColor={this.props.backgroundColor}
                  name="country"
                  type="hidden"
                  value="USA"
                  disabled={this.props.disabled}
                />
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    );
  }
}
