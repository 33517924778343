import {
  Box,
  Button,
  Card,
  LL,
  LS,
  PL,
  PM,
  styled,
  useTheme,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { useResendEmailVerificationMutation } from '~/graphql/hooks';

import { Spinner } from '~/toolbox/spinner';

export type TransferEmailVerificationCardProps = {
  resendEmailStyle: 'button' | 'link';
  style?: Record<string, any>;
};

const StyledNotVerifiedCard = styled(Card)`
  display: flex;
  flex-direction: row;
  padding: 16px;
  margin-bottom: 16px;
`;

export const TransferEmailVerificationCard = ({
  resendEmailStyle,
  style = {},
}: TransferEmailVerificationCardProps) => {
  const { colors } = useTheme();
  const [resendEmailVerification, { loading, data, error }] =
    useResendEmailVerificationMutation();

  const handleResend = () => {
    resendEmailVerification({
      variables: {
        input: {},
      },
    });
  };

  const renderEmailVerificationStatusButton = () => {
    if (loading) {
      return <Spinner radius={22} thickness={2} />;
    }

    if (error) {
      return <LL color="critical" content="Error!" />;
    }

    if (data?.resendEmailVerification?.didSucceed) {
      return <LL content="Email resent." color="primary" />;
    }

    return (
      <Button
        label="RESEND EMAIL"
        kind="secondary"
        size="small"
        onClick={handleResend}
      />
    );
  };

  if (resendEmailStyle === 'link' && loading) {
    return (
      <StyledNotVerifiedCard
        style={{
          ...style,
          background: colors.backgroundNeutralMain,
        }}
      >
        <Spinner radius={22} thickness={2} />
      </StyledNotVerifiedCard>
    );
  }

  if (
    resendEmailStyle === 'link' &&
    data?.resendEmailVerification?.didSucceed
  ) {
    return (
      <StyledNotVerifiedCard
        style={{
          ...style,
          background: colors.backgroundNeutralMain,
          justifyContent: 'center',
        }}
      >
        <LL content="Email resent." />
      </StyledNotVerifiedCard>
    );
  }
  return (
    <StyledNotVerifiedCard
      style={{
        ...style,
        background:
          resendEmailStyle === 'link'
            ? colors.backgroundNeutralMain
            : colors.backgroundNeutralTertiary,
      }}
    >
      <Icon color="critical" name="alert24" />
      <Box ml={16}>
        <PM
          fontWeight={600}
          as="h3"
          content="Verify your email to complete transfers"
        />
        <PL
          content="Please check your email and verify the address by clicking the link we sent. Once verified, we will work on completing your transfers."
          mt={8}
        />
        &nbsp;
        <span
          style={{
            maxHeight: 16,
          }}
        >
          {resendEmailStyle === 'link' &&
            (error ? (
              <LS color="critical" content="Error!" />
            ) : (
              <Button
                kind="link"
                size="small"
                label="Resend email."
                onClick={handleResend}
              />
            ))}
        </span>
      </Box>
      {resendEmailStyle === 'button' && (
        <Box alignSelf="center" m={16} minWidth={100}>
          {renderEmailVerificationStatusButton()}
        </Box>
      )}
    </StyledNotVerifiedCard>
  );
};
