import fill from 'lodash-es/fill';
import pick from 'lodash-es/pick';

import {
  ProfileInput,
  SavingsCoOwnerInformation,
  SavingsCustomerDueDiligenceAnswer,
  SavingsDueDiligenceQuestion,
  TransferParticipant,
} from '~/graphql/types';
import { ACTION_TYPES as ACTIONS, ROUTING_ACTIONS } from '~/redux/actions';
import { SAVINGS_ONBOARDING_FLOW_STEPS as STEPS } from '~/static-constants';

import { FlowState } from '../newFlowsReducer.types';
import { createFlowReducer, createStepReducer } from '../utils';

export type SavingsAccountType =
  | 'IndividualSave'
  | 'JointSaveInitiate'
  | 'JointSaveCoOwner'
  | 'IndividualCash'
  | 'JointCashInitiate'
  | 'JointCashCoOwner';

export type SavingsOnboardingFlowState = FlowState<
  string,
  {
    accountId: string | null;
    accountName: string | null;
    accountType: SavingsAccountType | null;
    basePath: string;
    coOwner: SavingsCoOwnerInformation | null;
    dueDiligenceAnswers: ReadonlyArray<{
      selectedAnswers: SavingsCustomerDueDiligenceAnswer[] | null | undefined;
      questionId: string;
    }>;
    dueDiligenceQuestions:
      | ReadonlyArray<SavingsDueDiligenceQuestion>
      | null
      | undefined;
    hasJointAccountInvitation: boolean | null;
    hasSavingsAccounts: boolean | null | undefined;
    idempotencyKey: string | null | undefined;
    initialTransferParticipant: TransferParticipant | null;
    isCashAccountsEligible: boolean | null;
    isJointAccountsEligible: boolean | null;
    isJointCashAccountsEligible: boolean | null;
    isMultipleAccountsEligible: boolean | null;
    isPreLoadingData: boolean;
    isSubjectToBackupWithholding: boolean | null | undefined;
    profileInput: ProfileInput | null;
    termsAndConditionsSignature: string | null;
    fullyPaidLendingStatus: boolean | null;
  }
>;

export const savingsOnboardingInitialFlowState: SavingsOnboardingFlowState = {
  accountId: null,
  accountName: null,
  accountType: null,
  basePath: '',
  dueDiligenceAnswers: [],
  dueDiligenceQuestions: null,
  hasJointAccountInvitation: null,
  hasSavingsAccounts: null,
  idempotencyKey: null,
  initialTransferParticipant: null,
  isCashAccountsEligible: null,
  isJointAccountsEligible: null,
  isJointCashAccountsEligible: null,
  isMultipleAccountsEligible: null,
  isPreLoadingData: true,
  isSubjectToBackupWithholding: null,
  profileInput: null,
  step: '',
  stepTitle: '',
  termsAndConditionsSignature: null,
  coOwner: null,
  fullyPaidLendingStatus: null,
};

const readStepTitle = (step: ValueOf<typeof STEPS>) => {
  switch (step) {
    case STEPS.ACCOUNT_INVITATION:
      return 'Account Invitation';
    case STEPS.DUE_DILIGENCE:
    case STEPS.ACCOUNT_TYPE:
    case STEPS.NAME_ACCOUNT:
    case STEPS.JOINT_INVITED:
    case STEPS.INVESTMENT_EXPERIENCE:
    case STEPS.TRUSTED_CONTACT:
      return 'Account details';
    case STEPS.ESIGN:
      return 'Delivery of disclosures';
    case STEPS.CONFIRMATION:
      return 'Confirm';
    case STEPS.FUND_ACCOUNT:
    case STEPS.CONNECT_BANK:
      return 'Fund your account';
    default:
      return 'Welcome to M1';
  }
};

const stepReducer = createStepReducer(savingsOnboardingInitialFlowState);

function reducer(
  state: SavingsOnboardingFlowState = savingsOnboardingInitialFlowState,
  action: any,
) {
  switch (action.type) {
    case ACTIONS.BEGIN_FLOW:
      return {
        ...savingsOnboardingInitialFlowState,
        ...pick(action.payload, Object.keys(savingsOnboardingInitialFlowState)),
        step: savingsOnboardingInitialFlowState.step,
      };
    case ACTIONS.END_FLOW:
      return savingsOnboardingInitialFlowState;
    case ROUTING_ACTIONS.LOCATION_CHANGE: {
      const step = stepReducer(state, action);
      return {
        ...state,
        step,
        stepTitle: readStepTitle(step),
      };
    }
    case ACTIONS.FINISHED_FLOW_STEP:
      switch (action.meta.step) {
        case STEPS.NAME_ACCOUNT: {
          return {
            ...state,
            accountName: action.payload,
          };
        }
        case STEPS.DUE_DILIGENCE: {
          const { questionId, selectedAnswers } = action.payload;
          const numOfQuestionAnswered =
            state.dueDiligenceQuestions?.findIndex(
              (q) => q.questionId === questionId,
            ) ?? 0;
          const dueDiligenceAnswers = [...state.dueDiligenceAnswers];
          dueDiligenceAnswers[numOfQuestionAnswered] = {
            selectedAnswers,
            questionId,
          };
          return {
            ...state,
            dueDiligenceAnswers,
          };
        }
        case STEPS.CONFIRMATION: {
          if (action.payload === 'edit') {
            return {
              ...state,
              dueDiligenceAnswers: fill(
                Array(state.dueDiligenceQuestions?.length),
                null,
              ),
            };
          }
          return state;
        }
        default:
          return state;
      }
    case 'SET_PRE_LOADED_DATA': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_DUE_DILIGENCE_QUESTIONS': {
      return {
        ...state,
        ...action.payload,
        dueDiligenceAnswers: fill(
          Array(action.payload.dueDiligenceQuestions.length),
          null,
        ),
      };
    }
    case 'SET_ACCOUNT_TYPE': {
      return {
        ...state,
        accountType: action.payload,
      };
    }
    case 'SET_SAVINGS_ACCOUNT_ID': {
      return {
        ...state,
        accountId: action.payload,
      };
    }
    case 'INITIATE_DATA_PRE_LOAD_REQUEST': {
      return {
        ...state,
        isPreLoadingData: true,
      };
    }
    case 'FINISH_DATA_PRE_LOAD_REQUEST': {
      return {
        ...state,
        isPreLoadingData: false,
      };
    }
    case 'SET_TERMS_CONDITIONS_SIGNATURE': {
      const termsAndConditionsSignature = action.payload;
      return {
        ...state,
        termsAndConditionsSignature,
      };
    }
    case ACTIONS.SET_TRANSFER_IDEMPOTENCY_KEY:
      return {
        ...state,
        idempotencyKey: action.payload,
      };
    case 'SET_INITIAL_TRANSFER_PARTICIPANT': {
      return {
        ...state,
        initialTransferParticipant: action.payload,
      };
    }
    case ACTIONS.SUBMITTED_DISCLOSURES_FORM_MODERN: {
      return {
        ...state,
        profileInput: action.payload.values,
      };
    }
    case 'SET_JOINT_ACCOUNT_INVITEE': {
      const { firstName, lastName, phone, email } = action.payload;
      return {
        ...state,
        coOwner: {
          firstName,
          lastName,
          phoneNumber: phone,
          email,
        },
      };
    }
    case 'SET_FULLY_PAID_LENDING_STATUS':
      return {
        ...state,
        fullyPaidLendingStatus: action.payload,
      };
    default:
      return state;
  }
}

export const savingsOnboardingReducer = createFlowReducer(
  'SAVINGS_ONBOARDING',
  reducer,
);
