import { useAvailableFundingSourcesPageQuery } from '~/graphql/hooks';
import { FundingSourceNodeFragment } from '~/graphql/types';

export const useFundingSources = () => {
  const { data, ...props } = useAvailableFundingSourcesPageQuery({
    fetchPolicy: 'network-only',
  });

  const fundingSources =
    data?.viewer.fundingSources?.fundingSourcesGroup?.edges || null;

  const activeFundingSources: Array<FundingSourceNodeFragment> = [];
  const pendingFundingSources: Array<FundingSourceNodeFragment> = [];

  if (fundingSources) {
    fundingSources.forEach((fundingSource) => {
      if (fundingSource?.node?.status === 'ACTIVE') {
        activeFundingSources.push(fundingSource.node);
      }
      if (fundingSource?.node?.status === 'PENDING') {
        pendingFundingSources.push(fundingSource.node);
      }
    });
  }

  return {
    data,
    fundingSources,
    activeFundingSources,
    pendingFundingSources,
    ...props,
  };
};
