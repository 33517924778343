import { ScheduleKey } from './types';
export const FREQUENCY_OPTIONS: { value: ScheduleKey; label: string }[] = [
  {
    value: 'weekly',
    label: 'Weekly',
  },
  {
    value: 'biweekly',
    label: 'Biweekly',
  },
  {
    value: 'monthly',
    label: 'Monthly',
  },
];

export const ANALYTICS_FREQUENCY_MAP: Record<ScheduleKey, string> = {
  biweekly: 'bi-weekly',
  monthly: 'monthly',
  weekly: 'weekly',
  weekOfMonth: 'week of the month',
};
