import { Icon } from '@m1/liquid-react/icons';
import React from 'react';

import { Cell } from '~/toolbox/cell';

import { AcatInstanceSteps, AcatInstanceStatus } from '../types';

import { StepParticipant } from './StepParticipant';
import { TransferArrow } from './TransferArrow';

type AcatStepFragmentProps = {
  step: NonNullable<AcatInstanceSteps[number]>;
  status: AcatInstanceStatus | null | undefined;
};

export const AcatStepFragment = ({ step, status }: AcatStepFragmentProps) => {
  return (
    <React.Fragment key={step.title}>
      <TransferArrow status={status} />
      <StepParticipant>
        <Cell
          title={step.title}
          subtitle={step.subtitle}
          icon={<Icon name="accountInvestPrimary32" />}
        />
      </StepParticipant>
    </React.Fragment>
  );
};
