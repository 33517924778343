import { Flex, PM, PXL, styled, Card } from '@m1/liquid-react';
import * as React from 'react';

import { Carousel } from '~/toolbox/carousel';

type SmartTransferContraParticipantEntryOptionsProps = {
  newContraParticipantEntryOptionSet: any;
  onOptionSelect: (
    newSmartTransferContraParticipantEntryOptionId: string,
  ) => void;
};

const StyledCard = styled(Card)`
  border-radius: 8px;
  box-shadow: 0px 10px 20px rgba(35, 38, 45, 0.1);
  width: 240px;
  min-height: 120px;
  padding: 16px;
  margin-right: 16px;
  transition: background-color 0.25s linear;
  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundPrimarySubtle};
  }
`;

export const SmartTransferContraParticipantEntryOptions = ({
  newContraParticipantEntryOptionSet,
  onOptionSelect,
}: SmartTransferContraParticipantEntryOptionsProps) => {
  const participantOptions =
    newContraParticipantEntryOptionSet.options.filter(Boolean);

  if (participantOptions.length === 0) {
    throw new Error(
      'Unable to render SmartTransferContraParticipantEntryOptions with no options.',
    );
  }

  if (participantOptions.length === 1) {
    const singleOption = participantOptions[0];
    return (
      <div
        style={{
          marginLeft: 32,
        }}
      >
        <StyledCard isElevated onClick={() => onOptionSelect(singleOption.id)}>
          <Flex justifyContent="space-between">
            <Flex flexDirection="column">
              <PXL
                fontWeight={600}
                color="foregroundNeutralMain"
                content={singleOption.name}
              />
              <PM
                color="foregroundNeutralSecondary"
                content={singleOption.description}
                mt={4}
              />
            </Flex>
          </Flex>
        </StyledCard>
      </div>
    );
  }

  const cards = participantOptions.map(
    (option: any) =>
      option && (
        <StyledCard
          key={option.id}
          isElevated
          onClick={() => onOptionSelect(option.id)}
        >
          <Flex flexDirection="column" justifyContent="space-between">
            <PXL
              fontWeight={600}
              color="foregroundNeutralMain"
              content={option.name}
            />
            <PM
              color="foregroundNeutralSecondary"
              content={option.description}
              mt={4}
            />
          </Flex>
        </StyledCard>
      ),
  );

  return <Carousel items={cards} elementWidth={256} displayQuantity={2} />;
};
