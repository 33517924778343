import debounce from 'lodash-es/debounce';
import { eventChannel, EventChannel, SagaIterator } from 'redux-saga';
import { call, put, race, take } from 'redux-saga/effects';

import { ACTION_TYPES, hideModal, logout, showModal } from '~/redux/actions';
import { delay } from '~/utils';

export function* refreshTokenTimeout(): SagaIterator {
  const AUTO_LOGOUT_TIMER = 1000 * 60 * 10;
  const PROMPT_TIMER = 1000 * 60 * 2;

  const activityChannel = activityChannelFactory(1000 * 5, 1000 * 10);

  while (true) {
    const { autoTimeout } = yield race({
      autoTimeout: call(delay, AUTO_LOGOUT_TIMER),
      action: take(activityChannel),
    });

    if (autoTimeout) {
      yield put(showModal('INACTIVITY_WARNING'));

      const { promptTimeout } = yield race({
        promptTimeout: call(delay, PROMPT_TIMER),
        action: take(ACTION_TYPES.HIDE_MODAL),
      });

      if (promptTimeout) {
        yield put(hideModal('INACTIVITY_WARNING'));
        yield put(logout());
      }
    }
  }
}

function activityChannelFactory(
  debounceTimer: number,
  maxWait: number = 1000,
): EventChannel<UIEvent> {
  return eventChannel((emitter) => {
    const callback = (event: UIEvent) => {
      emitter(event);
    };

    const debouncedCallback = debounce(callback, debounceTimer, { maxWait });

    document.addEventListener('mousemove', debouncedCallback);
    document.addEventListener('touchstart', debouncedCallback);

    return () => {
      document.removeEventListener('mousemove', debouncedCallback);
      document.removeEventListener('touchstart', debouncedCallback);
    };
  });
}
