import { InvestAccountOpeningQuestionType } from '~/graphql/types';
import {
  INVEST_ONBOARDING_FLOW_STEP_VALUES,
  INVEST_ONBOARDING_FLOW_STEPS as STEPS,
} from '~/static-constants';

export const mapSuitabilityToQuestion = (
  suit: string,
): InvestAccountOpeningQuestionType => {
  switch (suit) {
    case 'suitability.investmentExperience':
      return InvestAccountOpeningQuestionType.InvestmentExperience;
    case 'suitability.riskTolerance':
      return InvestAccountOpeningQuestionType.RiskTolerance;
    case 'suitability.timeHorizon':
      return InvestAccountOpeningQuestionType.InvestmentDuration;
    case 'suitability.liquidityNeeds':
      return InvestAccountOpeningQuestionType.LiquidityImportance;
    case 'suitability.trustedContact':
      return InvestAccountOpeningQuestionType.TrustedContact;
    case 'suitability.introductionSource':
      return InvestAccountOpeningQuestionType.HowDidYouHearAboutM1;
    default:
      return InvestAccountOpeningQuestionType.InvestmentExperience;
  }
};

export const mapQuestionToFlowStep = (
  questionType: InvestAccountOpeningQuestionType,
): INVEST_ONBOARDING_FLOW_STEP_VALUES => {
  // TODO: update this when we implement subsequent invest account flows as part of IDF phase 3.
  // const disclosureStep = isPrimary
  //   ? STEPS.COLLECT_PRIMARY_DISCLOSURES
  //   : STEPS.COLLECT_SECONDARY_DISCLOSURES;

  switch (questionType) {
    case InvestAccountOpeningQuestionType.InvestmentExperience:
      return STEPS.COLLECT_INVESTMENT_EXPERIENCE;
    case InvestAccountOpeningQuestionType.LiquidityImportance:
      return STEPS.COLLECT_LIQUIDITY_NEEDS;
    case InvestAccountOpeningQuestionType.HowDidYouHearAboutM1:
      return STEPS.COLLECT_INTRODUCTION_SOURCE;
    case InvestAccountOpeningQuestionType.InvestmentDuration:
      return STEPS.COLLECT_TIME_HORIZON;
    case InvestAccountOpeningQuestionType.RiskTolerance:
      return STEPS.COLLECT_RISK_TOLERANCE;
    case InvestAccountOpeningQuestionType.TrustedContact:
      return STEPS.COLLECT_TRUSTED_CONTACT;
    default:
      return STEPS.COLLECT_INVESTMENT_EXPERIENCE;
  }
};

export const primaryQuestionMap = (
  questionType: InvestAccountOpeningQuestionType | null,
): INVEST_ONBOARDING_FLOW_STEP_VALUES => {
  switch (questionType) {
    case 'INVESTMENT_EXPERIENCE':
    case 'RISK_TOLERANCE':
    case 'INVESTMENT_DURATION':
    case 'LIQUIDITY_IMPORTANCE':
    case 'TRUSTED_CONTACT':
    case 'HOW_DID_YOU_HEAR_ABOUT_M1':
    default:
      return STEPS.COLLECT_INVESTMENT_EXPERIENCE;
  }
};
