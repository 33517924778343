import * as React from 'react';

import { SecurityRiskLevelFragment } from '~/graphql/types';
import { Rating, RatingProps } from '~/toolbox/rating';

export type SecurityRiskLevelProps = Pick<RatingProps, 'align' | 'size'> & {
  security: SecurityRiskLevelFragment;
};

export const SecurityRiskLevel = ({
  security,
  ...rest
}: SecurityRiskLevelProps) => {
  const rating = React.useMemo<number | null | undefined>(() => {
    switch (security.__typename) {
      case 'Equity':
        return security.fundamentals?.peRatioGrade;
      case 'Fund':
        return security.fundFundamentals?.netExpenseRatioGrade;
      default:
        return null;
    }
  }, [security]);

  const ratingLabel = React.useMemo<string | null | undefined>(() => {
    switch (security.__typename) {
      case 'Equity':
        return security.fundamentals?.peRatio?.toFixed(1);
      case 'Fund':
        if (!security.fundFundamentals?.netExpenseRatio) {
          return null;
        }
        return `${security.fundFundamentals.netExpenseRatio.toFixed(2)}%`;
      default:
        return null;
    }
  }, [security]);

  if (typeof rating !== 'number' || typeof ratingLabel !== 'string') {
    return <>--</>;
  }

  return <Rating {...rest} rating={rating} ratingLabel={ratingLabel} />;
};
