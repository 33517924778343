import { Box, Button, Input, PXL } from '@m1/liquid-react';
import * as React from 'react';

import { Pie } from '~/pie-trees';
import { useDispatch, useSelector } from '~/redux/hooks';
import { getActivePie } from '~/redux/reducers/newFlows/reducers/portfolioEditorReducer';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

const isFormValid = (name: string = '', description: string = ''): boolean => {
  return Boolean(
    name.length > 0 && name.length <= 80 && description.length <= 1000,
  );
};

export const CreatePieForm = ({
  closeCreatePieForm,
}: {
  closeCreatePieForm: () => void;
}) => {
  const dispatch = useDispatch();

  const [pieName, setPieName] = React.useState<string>('');
  const [pieDescription, setPieDescription] = React.useState<string>('');

  const newPies = useSelector((state) => {
    const pieTree: Pie | null | undefined = getActivePie(
      state.newFlows.PORTFOLIO_EDITOR,
    );

    if (!pieTree) {
      return [];
    }
    return pieTree.slices?.filter((slice) => slice.to.type === 'new_pie');
  });

  const onDoneCallback = () => {
    if (pieName) {
      const id = `${newPies?.length || 1}-${pieName}`;

      dispatch({
        payload: {
          percentage: 1,
          to: {
            __checked: false,
            __highlighted__: false,
            __id: id,
            __shouldEqualizeSlicePercentagesOnAdd: true,
            description: pieDescription ?? null,
            name: pieName,
            slices: [],
            type: 'new_pie',
          },
        },
        type: 'CREATED_PORTFOLIO_EDITOR_NEW_PIE_SLICE',
      });

      closeCreatePieForm();
    }
  };

  return (
    <Box p={24}>
      <PXL fontWeight={600} pb={8} content="Create a Pie" />
      <form name="create-pie-form">
        <Input
          error={
            pieName.length > 80 ? 'Maximum character limit is 80.' : undefined
          }
          label="Pie name"
          placeholder="Maximum character limit is 80"
          onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
            // @ts-expect-error - TS2339 - Property 'value' does not exist on type 'EventTarget'.
            setPieName(e.target.value);
          }}
        />
        <Input
          error={
            pieDescription.length > 1000
              ? 'Maximum character limit is 1000.'
              : undefined
          }
          label="Description (optional)"
          placeholder="Maximum character limit is 1000"
          onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
            // @ts-expect-error - TS2339 - Property 'value' does not exist on type 'EventTarget'.
            setPieDescription(e.target.value);
          }}
        />
        <ButtonGroup my={8}>
          <Button
            kind="secondary"
            label="Cancel"
            onClick={() => closeCreatePieForm()}
          />
          <Button
            kind="primary"
            label="Add Pie"
            disabled={!isFormValid(pieName, pieDescription)}
            onClick={onDoneCallback}
          />
        </ButtonGroup>
      </form>
    </Box>
  );
};
