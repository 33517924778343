import { useWizardContext } from '~/flows/wizard';

import { type PersonalLoanDirectSteps } from '../types';

export const usePersonalLoanDirectWizardContext = () => {
  const context = useWizardContext<keyof PersonalLoanDirectSteps>();

  if (!context) {
    throw new Error(
      'usePersonalLoanDirectWizardContext must be used within a Wizard provider!',
    );
  }

  return context;
};
