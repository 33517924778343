import { Button, HXS, ModalContent, PL } from '@m1/liquid-react';
import * as React from 'react';

import { useLatestLegalAgreementsQuery } from '~/graphql/hooks';

import { Container } from '~/toolbox/container';
import { Divider } from '~/toolbox/divider';
import { Grid } from '~/toolbox/grid';
import { Link } from '~/toolbox/link';

import { Spinner } from '~/toolbox/spinner';

type Props = {
  onCancel: () => any;
  onConfirm: (signature: string | undefined) => any;
};

export const ReacceptLegalTermsModalContent = ({
  onCancel,
  onConfirm,
}: Props) => {
  const { data, loading } = useLatestLegalAgreementsQuery();

  if (loading) {
    return <Spinner />;
  }
  const handleCancel = (): void => onCancel();
  const handleConfirm = (): void => {
    onConfirm(data?.viewer.latestAgreements.signature);
  };

  const documents = data?.viewer.latestAgreements.documents;
  return (
    <ModalContent width="wide">
      <HXS>Updated Terms & Conditions</HXS>
      <br />
      <br />
      <PL>
        We’re updating our terms and conditions so we can provide even more new
        features to help you manage your money. Click the links below to review
        and accept these changes.
      </PL>
      <Divider spacing="relaxed" />
      <Grid.Row>
        {documents?.map((d) => (
          <Grid.Col key={d.url} xs={6}>
            <Link to={d.url} target="_blank">
              {d.title}
            </Link>
            <br />
            <br />
          </Grid.Col>
        ))}
      </Grid.Row>
      <Container centered>
        <Button
          label="Cancel"
          kind="secondary"
          size="medium"
          onClick={handleCancel}
        />
        &nbsp;&nbsp;&nbsp;
        <Button
          label="Confirm"
          kind="primary"
          size="medium"
          onClick={handleConfirm}
        />
      </Container>
    </ModalContent>
  );
};
