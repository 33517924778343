import { Box, Flex, HXS, PXL, styled, Card } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { FulfillmentConditionInlineDescriptionTags } from '~/components/FulfillmentConditionInlineDescriptionTags';
import { TransferParticipantCellRenderer } from '~/components/transfers/TransferParticipantCellRenderer';

import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import type { SmartTransferFulfillmentConditionInput } from '~/redux/actions/smartTransfer/smartTransferActions.types';
import { useDispatch, useSelector } from '~/redux/hooks';

import { FulfillmentConditionInputs } from './FulfillmentConditionInputs.apollo';

const StyledLeftColumnBox = styled(Box)`
  background-color: ${(props) => props.theme.colors.backgroundNeutralSecondary};
`;

const StyledSelectedAccount = styled(Flex)`
  border: 1px solid ${(props) => props.theme.colors.foregroundNeutralTertiary};
  border-radius: 8px;
  padding: 16px 32px;
`;

type FulfillmentConditionEditContainerComponentProps = {
  editRequirements: any;
  onFinishStep: () => void;
};

export const FulfillmentConditionEditContainer = ({
  editRequirements,
  onFinishStep,
}: FulfillmentConditionEditContainerComponentProps) => {
  // since we are editing, we expect that there will be a contra that matches our activeContra in our contraParticipantEntries array.
  // let's pull it down so we can pre-fill fulfillment condition data
  const existingContraParticipantEntry = useSelector((state) => {
    const activeContraParticipantId =
      state.newFlows.CREATE_SMART_TRANSFER.activeContraParticipantEntry
        ?.contraParticipantId;
    if (activeContraParticipantId) {
      return state.newFlows.CREATE_SMART_TRANSFER.input.contraParticipantEntries.find(
        (entry) => entry.contraParticipantId === activeContraParticipantId,
      );
    }
    return null;
  });
  const dispatch = useDispatch();
  const onFulfillmentDataChange = (
    fulfillmentCondition: SmartTransferFulfillmentConditionInput,
  ) => {
    dispatch({
      type: ACTIONS.SET_SMART_TRANSFER_FULFILLMENT_CONDITIONS,
      payload: fulfillmentCondition,
    });
  };
  if (!editRequirements.fulfillmentConditionOptionSet) {
    throw new Error('Error reading fulfillmentConditionOptionSet fragment ref');
  }
  return (
    <>
      <StyledLeftColumnBox height="100vh" py={100} width="50%">
        <Box maxWidth={550} ml="auto" mr={64}>
          <BackArrow
            content="Create Smart Transfer"
            mb={16}
            params={{
              step: 'set-smart-transfer',
            }}
            to="/d/c/create-smart-transfer/:step"
          />

          <HXS content={editRequirements.title} />
          <Card
            isElevated
            backgroundColor="backgroundNeutralMain"
            mt={64}
            minHeight={230}
            p="12px 32px"
          >
            <PXL
              fontWeight={600}
              content={editRequirements.contraParticipantLabel}
              mb={16}
              mt={24}
            />
            <>
              <StyledSelectedAccount
                alignItems="center"
                justifyContent="space-between"
              >
                {existingContraParticipantEntry && (
                  <TransferParticipantCellRenderer
                    includeDescription
                    id={existingContraParticipantEntry.contraParticipantId}
                  />
                )}
              </StyledSelectedAccount>
              {existingContraParticipantEntry && (
                <FulfillmentConditionInlineDescriptionTags
                  {...existingContraParticipantEntry.fulfillmentCondition}
                  mt={16}
                  mb={48}
                />
              )}
            </>
          </Card>
        </Box>
      </StyledLeftColumnBox>
      <Box width="50%">
        <FulfillmentConditionInputs
          fulfillmentConditionOptionSet={
            editRequirements.fulfillmentConditionOptionSet
          }
          isEditMode
          onFinishStep={onFinishStep}
          onFulfillmentDataChange={onFulfillmentDataChange}
          preSelectedFulfillmentConditionDataOfActiveContra={
            existingContraParticipantEntry?.fulfillmentCondition
          }
          preamble={
            editRequirements.fulfillmentConditionOptionSetListingPreamble
          }
        />
      </Box>
    </>
  );
};
