import * as React from 'react';
import { Route } from 'react-router-dom';

import { StockScreenerPage } from './components';

import { ResearchStockDetailsRoute } from './details';

export const StockScreenerRoute = () => {
  return (
    <Route
      path="stocks"
      // ignoreScrollBehavior
    >
      {ResearchStockDetailsRoute()}
      <Route index element={<StockScreenerPage />} />
    </Route>
  );
};
