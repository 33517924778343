import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { SAVINGS_ONBOARDING_FLOW_STEPS as STEPS } from '~/static-constants';

import { EarnDirectDepositComplete } from './steps/EarnDirectDepositComplete';
import { EarnDirectDepositManualSetup } from './steps/EarnDirectDepositManualSetup';
import { EarnDirectDepositOptions } from './steps/EarnDirectDepositOptions';
import { SavingsAccountInvitationPage } from './steps/SavingsAccountInvitation';
import { SavingsAccountType } from './steps/SavingsAccountType';
import { SavingsCollectTrustedContact } from './steps/SavingsCollectTrustedContact';
import { SavingsConfirmation } from './steps/SavingsConfirmation';
import { SavingsConnectBank } from './steps/SavingsConnectBank';
import { SavingsDueDiligence } from './steps/SavingsDueDiligence';
import { SavingsESign } from './steps/SavingsESign';
import { SavingsFundAccount } from './steps/SavingsFundAccount';
import { SavingsFundingComplete } from './steps/SavingsFundingComplete';
import { SavingsJointInvited } from './steps/SavingsJointInvited';
import { SavingsNameAccount } from './steps/SavingsNameAccount';

export const SavingsOnboardingContainer = ({ step, ...rest }: any) => {
  const steps = {
    [STEPS.JOINT_INVITED]: SavingsJointInvited,
    [STEPS.ACCOUNT_TYPE]: SavingsAccountType,
    [STEPS.ACCOUNT_INVITATION]: SavingsAccountInvitationPage,
    [STEPS.NAME_ACCOUNT]: SavingsNameAccount,
    [STEPS.DUE_DILIGENCE]: SavingsDueDiligence,
    [STEPS.TRUSTED_CONTACT]: SavingsCollectTrustedContact,
    [STEPS.ESIGN]: SavingsESign,
    [STEPS.CONFIRMATION]: SavingsConfirmation,
    [STEPS.FUND_ACCOUNT]: SavingsFundAccount,
    [STEPS.FUNDING_COMPLETE]: SavingsFundingComplete,
    [STEPS.CONNECT_BANK]: SavingsConnectBank,
    [STEPS.DIRECT_DEPOSIT_OPTIONS]: EarnDirectDepositOptions,
    [STEPS.MANUAL_DIRECT_DEPOSIT]: EarnDirectDepositManualSetup,
    [STEPS.DIRECT_DEPOSIT_COMPLETE]: EarnDirectDepositComplete,
    [STEPS.SHOW_ERROR_MESSAGE]: GenericSystemError,
  };
  const Step = steps[step];
  return Step ? <Step {...rest} /> : null;
};
