import { Box, HXS, Card } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { MarketingAndDisclaimerRows } from '~/components/MarketingAndDisclaimerRows';
import { RegisterUserFlow } from '~/flows';
import { useReferralsSignupPageQuery } from '~/graphql/hooks';
import { AppImage } from '~/lens-toolbox/AppImage';
import { enteredPage, exitedPage } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { REGISTER_USER_FLOW_STEPS as STEPS } from '~/static-constants';
import { Grid } from '~/toolbox/grid';
import { M1Logo } from '~/toolbox/M1Logo';
import { SmallOrWiderScreen } from '~/toolbox/responsive';

export const ReferralsSignupPage = () => {
  const dispatch = useDispatch();
  const { data, loading } = useReferralsSignupPageQuery();
  React.useEffect(() => {
    dispatch(enteredPage('REFERRALS_SIGNUP', undefined));

    return () => {
      dispatch(exitedPage('REFERRALS_SIGNUP', undefined));
    };
  }, []);

  const referralTitleContent =
    data?.viewer.referralOffer?.referralsTitle ??
    'Your referral reward is waiting for you!';

  const referralsIllustration =
    data?.viewer?.referralOffer?.referralsIllustration;

  const referralsDisclosureContent =
    data?.viewer?.referralOffer?.referralsDisclosureContent;

  if (loading || !referralsDisclosureContent) {
    return null;
  }

  return (
    <Box p="80px 0 3.5rem">
      <RegisterUserFlow>
        {(props: { step: keyof typeof STEPS; stepElement: any }) => {
          const copy =
            props.step === 'WARN_MOBILE_USERS'
              ? 'Success!'
              : referralTitleContent;
          return (
            <Grid constrain>
              <SmallOrWiderScreen>
                {(matches) => (
                  <Grid.Row
                    xsCenter
                    xsTextCenter
                    style={{
                      paddingBottom: matches ? 64 : 32,
                    }}
                  >
                    <Grid.Col xs={12}>
                      <M1Logo width={42} />
                    </Grid.Col>
                  </Grid.Row>
                )}
              </SmallOrWiderScreen>

              <Grid.Row
                style={{
                  paddingBottom: 48,
                }}
                xsMiddle
              >
                <Grid.Col sm={4} smOffset={1} xs={12} xsTextCenter>
                  <SmallOrWiderScreen>
                    {() => (
                      <>
                        {props.step === STEPS.COLLECT_USER_DETAILS &&
                          (referralsIllustration ? (
                            <AppImage appImage={referralsIllustration} />
                          ) : (
                            <Illustration name="referAndEarn" />
                          ))}

                        <Box pb={32}>
                          <HXS content={copy} />
                        </Box>
                      </>
                    )}
                  </SmallOrWiderScreen>
                </Grid.Col>
                <Grid.Col sm={5} smOffset={1} xs={12}>
                  <SmallOrWiderScreen>
                    {(matches) => (
                      <Card isElevated p={matches ? 32 : 16}>
                        {props.stepElement}
                      </Card>
                    )}
                  </SmallOrWiderScreen>
                </Grid.Col>
              </Grid.Row>
              <MarketingAndDisclaimerRows
                referralsDisclosureContent={referralsDisclosureContent}
                hideAppStoreBadges
              />
            </Grid>
          );
        }}
      </RegisterUserFlow>
    </Box>
  );
};
