import moment from 'moment';
import { SagaIterator } from 'redux-saga';
import { call, fork, put, select, spawn } from 'redux-saga/effects';

import { STEPS } from '~/flows/components/credit-card/application';
import {
  AcceptCreditCardOfferDocument,
  AcceptCreditCardOfferMutationResult,
  ContinueCreditCardApplicationAfterUserCreditIsUnfrozenDocument,
  ContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutationResult,
  SubmitCreditCardApplicationDocument,
  SubmitCreditCardApplicationMutationResult,
} from '~/graphql/hooks';
import {
  AcceptCreditCardOfferInput,
  ContinueCreditCardApplicationAfterUserCreditIsUnfrozenInput,
  SubmitCreditCardApplicationInput,
} from '~/graphql/types';
import { NavigateFunction } from '~/hooks/useNavigate';
import { hideLoadingSpinner, showLoadingSpinner } from '~/redux/actions';
import { delay } from '~/utils';

import { apolloMutationSaga } from '../../apolloMutationSaga';

import {
  getAnalyticsReporter,
  readUserData,
  updateUserData,
} from '../../common';
import { changeStep, makeFlowFuncs } from '../utils';

import {
  ApplicantInformation,
  fetchCreditApplicationQuery,
} from './creditApplicationQuery';

const { takeFlow, takeFlowStep } = makeFlowFuncs('CREDIT_CARD_APPLICATION');

// Timeout for checking status of application & open account
const POLL_INTERVAL = 2000;

export function* creditCardApplicationSaga(): SagaIterator<void> {
  yield fork(takeFlow, beginCreditCardApplicationFlow);
}

function* beginCreditCardApplicationFlow(action: any): SagaIterator<void> {
  const { onFinish } = action.payload;
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );

  yield fork(takeFlowStep, STEPS.START_APPLICATION, finishedStartApplication);

  yield fork(
    takeFlowStep,
    STEPS.PREFILLED_SUMMARY,
    finishedPrefilledSummaryReview,
  );

  yield fork(
    takeFlowStep,
    STEPS.FINANCIAL_INFORMATION,
    finishedFinancialSubmission,
  );

  yield fork(
    takeFlowStep,
    STEPS.EDIT_FINANCIAL_INFORMATION,
    finishedFinancialSubmission,
  );

  yield fork(
    takeFlowStep,
    STEPS.SUBMIT_APPLICATION,
    finishedApplicationSubmission,
  );

  yield fork(
    takeFlowStep,
    STEPS.REMOVE_CREDIT_FREEZE,
    finishedRemovingCreditFreeze,
  );

  yield fork(
    takeFlowStep,
    STEPS.APPLICATION_REJECTED,
    finishedApplicationRejected,
  );

  yield fork(takeFlowStep, STEPS.APPLICATION_APPROVED, acceptedCreditCardOffer);

  yield fork(
    takeFlowStep,
    STEPS.OFFER_ACCEPTED,
    finishedCreditCardApplication,
    onFinish,
  );

  const creditSteps: ApplicantInformation | null | undefined = yield call(
    fetchCreditApplicationQuery,
  );

  if (!creditSteps) {
    yield call(changeStep, STEPS.INITIAL_ERROR_PAGE);
    return;
  }

  const { applicationId, applicationStatus, hasActiveCreditCardAccount } =
    creditSteps;

  if (hasActiveCreditCardAccount) {
    yield call(navigate, { to: '/d/spend/credit' });
  } else if (!applicationStatus || applicationStatus === 'EXPIRED') {
    yield call(changeStep, STEPS.START_APPLICATION, true);
  } else {
    switch (applicationStatus) {
      case 'UPLOAD_DOCUMENTS_REQUESTED':
        yield call(changeStep, STEPS.UPLOAD_DOCUMENTS, true);
        break;
      case 'UNFREEZE_BUREAU_REQUESTED':
        yield call(changeStep, STEPS.REMOVE_CREDIT_FREEZE, true);
        break;
      case 'ERROR_SUBMITTING_APPLICATION':
      case 'ERROR_ACCEPTING_OFFER':
      case 'IN_REVIEW':
      case 'SUBMITTED':
      case 'ACCEPTED_PENDING_FINAL_APPROVAL':
        yield call(changeStep, STEPS.APPLICATION_SUBMITTED, true);
        break;
      case 'APPROVED':
        yield call(changeStep, STEPS.APPLICATION_APPROVED, true);
        yield call(
          logAnalyticsAndUpdateUserData,
          'm1_cc_app_approved',
          'approvedCreditCardApplicationIds',
          applicationId,
        );
        break;
      case 'REJECTED':
        yield call(changeStep, STEPS.APPLICATION_REJECTED, true);
        break;
      case 'ACCEPTED':
        yield call(changeStep, STEPS.OFFER_ACCEPTED);
        yield call(
          logAnalyticsAndUpdateUserData,
          'm1_cc_app_offer_accepted',
          'acceptedCreditCardApplicationIds',
          applicationId,
        );
        break;
      default:
        yield call(changeStep, STEPS.INITIAL_ERROR_PAGE, true);
        return;
    }
  }
}

function* finishedStartApplication(): SagaIterator<void> {
  yield put(showLoadingSpinner());
  const eventTimestamp = yield call(readUserData, 'm1_cc_app_started');

  yield spawn(
    logAnalyticsEvent,
    eventTimestamp ? 'm1_cc_app_started_again' : 'm1_cc_app_started',
  );
  if (!eventTimestamp) {
    yield call(updateUserData, {
      key: 'm1_cc_app_started',
      value: moment().toISOString(),
    });
  }

  yield put(hideLoadingSpinner());
  yield call(changeStep, STEPS.PREFILLED_SUMMARY);
}

function* finishedPrefilledSummaryReview(): SagaIterator<void> {
  yield call(changeStep, STEPS.FINANCIAL_INFORMATION);
}

function* finishedFinancialSubmission(): SagaIterator<void> {
  yield call(changeStep, STEPS.SUBMIT_APPLICATION);
}

function* finishedApplicationSubmission({
  payload,
}: Record<string, any>): SagaIterator<void> {
  if (payload === 'edit') {
    yield call(changeStep, STEPS.EDIT_FINANCIAL_INFORMATION);
  } else {
    yield call(changeStep, STEPS.PROCESSING_APPLICATION);
    try {
      const { data }: SubmitCreditCardApplicationMutationResult = yield call(
        apolloMutationSaga,
        {
          mutation: SubmitCreditCardApplicationDocument,
          variables: {
            input: {
              annualIncome: payload.annualIncome,
              housingType: payload.housingType,
              monthlyExpenses: payload.monthlyExpenses,
              termsAndConditionsSignature: payload.termsAndConditionsSignature,
              userAttestsApplicationData: payload.userAttestsApplicationData,
            } satisfies SubmitCreditCardApplicationInput,
          },
        },
      );

      const submitCreditCardApplication = data?.submitCreditCardApplication;

      if (
        !submitCreditCardApplication ||
        !submitCreditCardApplication.outcome
      ) {
        yield call(changeStep, STEPS.INITIAL_ERROR_PAGE);
        return;
      }

      let shouldReFetch = true;
      let status = null;
      let appId = null;

      const startTime = Date.now();

      while (shouldReFetch) {
        const { applicationId, applicationStatus } = yield call(
          fetchCreditApplicationQuery,
        );
        status = applicationStatus;
        appId = applicationId;

        if (status !== 'SUBMITTED' || Date.now() - startTime >= 60000) {
          shouldReFetch = false;
          break;
        }

        yield call(delay, POLL_INTERVAL);
      }

      if (!status) {
        yield call(changeStep, STEPS.INITIAL_ERROR_PAGE);
        return;
      }

      switch (status) {
        case 'UPLOAD_DOCUMENTS_REQUESTED':
          yield call(changeStep, STEPS.UPLOAD_DOCUMENTS);
          break;
        case 'UNFREEZE_BUREAU_REQUESTED':
          yield call(changeStep, STEPS.REMOVE_CREDIT_FREEZE);
          break;
        case 'ERROR_SUBMITTING_APPLICATION':
        case 'IN_REVIEW':
        case 'SUBMITTED':
          yield call(changeStep, STEPS.APPLICATION_SUBMITTED);
          break;
        case 'APPROVED':
          yield call(changeStep, STEPS.APPLICATION_APPROVED);
          yield call(
            logAnalyticsAndUpdateUserData,
            'm1_cc_app_approved',
            'approvedCreditCardApplicationIds',
            appId,
          );
          break;
        case 'REJECTED':
          yield call(changeStep, STEPS.APPLICATION_REJECTED);
          break;
        default:
          yield call(changeStep, STEPS.INITIAL_ERROR_PAGE);
      }
    } catch (e: any) {
      yield call(changeStep, STEPS.INITIAL_ERROR_PAGE);
    }
  }
}

function* finishedRemovingCreditFreeze(): SagaIterator<void> {
  yield call(changeStep, STEPS.PROCESSING_APPLICATION);
  try {
    const {
      data,
    }: ContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutationResult =
      yield call(apolloMutationSaga, {
        mutation:
          ContinueCreditCardApplicationAfterUserCreditIsUnfrozenDocument,
        variables: {
          input:
            {} satisfies ContinueCreditCardApplicationAfterUserCreditIsUnfrozenInput,
        },
      });

    const continueCreditCardApplicationAfterUserCreditIsUnfrozen =
      data?.continueCreditCardApplicationAfterUserCreditIsUnfrozen;

    if (
      !continueCreditCardApplicationAfterUserCreditIsUnfrozen ||
      !continueCreditCardApplicationAfterUserCreditIsUnfrozen.outcome
    ) {
      yield call(changeStep, STEPS.INITIAL_ERROR_PAGE);
      return;
    }

    let shouldReFetch = true;
    let status = null;
    let appId = null;

    const startTime = Date.now();

    while (shouldReFetch) {
      const { applicationId, applicationStatus } = yield call(
        fetchCreditApplicationQuery,
      );
      status = applicationStatus;
      appId = applicationId;

      if (status !== 'SUBMITTED' || Date.now() - startTime >= 60000) {
        shouldReFetch = false;
        break;
      }

      yield call(delay, POLL_INTERVAL);
    }

    if (!status) {
      yield call(changeStep, STEPS.INITIAL_ERROR_PAGE);
      return;
    }

    switch (status) {
      case 'APPROVED':
        yield call(changeStep, STEPS.APPLICATION_APPROVED);
        yield call(
          logAnalyticsAndUpdateUserData,
          'm1_cc_app_approved',
          'approvedCreditCardApplicationIds',
          appId,
        );
        break;
      case 'EXPIRED':
        yield call(changeStep, STEPS.START_APPLICATION);
        break;
      case 'ACCEPTED':
        yield call(changeStep, STEPS.OFFER_ACCEPTED);
        yield call(
          logAnalyticsAndUpdateUserData,
          'm1_cc_app_offer_accepted',
          'acceptedCreditCardApplicationIds',
          appId,
        );
        break;
      case 'SUBMITTED':
      case 'IN_REVIEW':
      case 'ERROR_SUBMITTING_APPLICATION':
      case 'ERROR_ACCEPTING_OFFER':
      case 'ACCEPTED_PENDING_FINAL_APPROVAL':
        yield call(changeStep, STEPS.APPLICATION_SUBMITTED);
        break;
      case 'REJECTED':
        yield call(changeStep, STEPS.APPLICATION_REJECTED);
        break;
      case 'UNFREEZE_BUREAU_REQUESTED':
        yield call(changeStep, STEPS.REMOVE_CREDIT_FREEZE);
        break;
      case 'UPLOAD_DOCUMENTS_REQUESTED':
        yield call(changeStep, STEPS.UPLOAD_DOCUMENTS);
        break;
      default:
        yield call(changeStep, STEPS.INITIAL_ERROR_PAGE);
    }
  } catch (e: any) {
    yield call(changeStep, STEPS.INITIAL_ERROR_PAGE);
  }
}

function* acceptedCreditCardOffer(): SagaIterator<void> {
  yield call(changeStep, STEPS.PROCESSING_OPEN_ACCOUNT);
  try {
    const { data }: AcceptCreditCardOfferMutationResult = yield call(
      apolloMutationSaga,
      {
        mutation: AcceptCreditCardOfferDocument,
        variables: {
          input: {
            userAttestsCha: true,
          } satisfies AcceptCreditCardOfferInput,
        },
      },
    );

    const acceptCreditCardOffer = data?.acceptCreditCardOffer;

    if (!acceptCreditCardOffer || !acceptCreditCardOffer.outcome) {
      yield call(changeStep, STEPS.INITIAL_ERROR_PAGE);
      return;
    }

    let shouldReFetch = true;
    let status = null;
    let appId = null;

    const startTime = Date.now();

    while (shouldReFetch) {
      const { applicationId, applicationStatus } = yield call(
        fetchCreditApplicationQuery,
      );
      status = applicationStatus;
      appId = applicationId;

      if (
        status !== 'ACCEPTED_PENDING_FINAL_APPROVAL' ||
        Date.now() - startTime >= 60000
      ) {
        shouldReFetch = false;
        break;
      }

      yield call(delay, POLL_INTERVAL);
    }

    if (!status) {
      yield call(changeStep, STEPS.INITIAL_ERROR_PAGE);
      return;
    }

    const { creditAccountId } = yield call(fetchCreditApplicationQuery);
    switch (status) {
      case 'UNFREEZE_BUREAU_REQUESTED':
        yield call(changeStep, STEPS.REMOVE_CREDIT_FREEZE);
        break;
      case 'ERROR_ACCEPTING_OFFER':
      case 'ACCEPTED_PENDING_FINAL_APPROVAL':
        yield call(changeStep, STEPS.APPLICATION_SUBMITTED);
        break;
      case 'REJECTED':
        yield call(changeStep, STEPS.APPLICATION_REJECTED);
        break;
      case 'ACCEPTED':
        yield put({
          type: 'SET_ACTIVE_CREDIT_ACCOUNT',
          payload: creditAccountId,
        });
        yield put({
          type: 'SET_SPEND_ACCOUNT_DESTINATION',
          payload: 'credit',
        });
        yield call(changeStep, STEPS.OFFER_ACCEPTED);
        yield call(
          logAnalyticsAndUpdateUserData,
          'm1_cc_app_offer_accepted',
          'acceptedCreditCardApplicationIds',
          appId,
        );
        break;
      default:
        yield call(changeStep, STEPS.INITIAL_ERROR_PAGE);
    }
  } catch (e: any) {
    yield call(changeStep, STEPS.INITIAL_ERROR_PAGE);
  }
}

function* finishedCreditCardApplication(
  onFinish: (...args: Array<any>) => any,
): SagaIterator<void> {
  const { creditAccountId } = yield call(fetchCreditApplicationQuery);
  yield put({
    type: 'SET_ACTIVE_CREDIT_ACCOUNT',
    payload: creditAccountId,
  });
  yield put({
    type: 'SET_SPEND_ACCOUNT_DESTINATION',
    payload: 'credit',
  });
  yield call(onFinish);
}

function* finishedApplicationRejected() {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );
  yield call(navigate, { to: '/d/home' });
}

function* logAnalyticsEvent(eventName: string): SagaIterator<void> {
  const analytics = yield call(getAnalyticsReporter);
  yield call([analytics, 'recordEvent'], eventName);
}

function* logAnalyticsAndUpdateUserData(
  eventName: string,
  key: string,
  appId: string | null | undefined,
): SagaIterator<void> {
  if (!appId) {
    return;
  }

  const userData = yield call(readUserData, key);

  if (!userData) {
    return;
  }

  // userData is a stringified array, so convert to actual array
  const applicationIds = userData.split(',');

  if (!applicationIds.includes(appId)) {
    yield spawn(logAnalyticsEvent, eventName);

    yield call(updateUserData, {
      key: key,
      value: [appId].concat(applicationIds).join(','), // convert back to string
    });
  }
}
