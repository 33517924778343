import React from 'react';

import { useWizard, WizardProvider } from '~/flows/wizard';

import { TransferDetailsStep } from './steps/TransferDetails';
import { TransferNiaCalculatorStep } from './steps/TransferNiaCalculator';
import { TransferValidationStep } from './steps/TransferValidation';

export type CreateTransferWizardStep =
  | 'TRANSFER_DETAILS'
  | 'TRANSFER_NIA_CALCULATOR'
  | 'TRANSFER_VALIDATION';

export const CreateTransferWizard = (pageContext: any) => {
  const steps = {
    TRANSFER_DETAILS: <TransferDetailsStep />,
    TRANSFER_NIA_CALCULATOR: <TransferNiaCalculatorStep />,
    TRANSFER_VALIDATION: <TransferValidationStep />,
  };

  const { step, ...wizardContext } = useWizard<CreateTransferWizardStep>(steps);

  return (
    <WizardProvider value={{ step, ...wizardContext, ...pageContext }}>
      {step}
    </WizardProvider>
  );
};
