import * as React from 'react';

import { ProcessingRequest } from '~/components/ProcessingRequest';

export const ProcessingResubmission = () => (
  <ProcessingRequest
    heading="We are resubmitting your application"
    content="This may take up to a minute."
  />
);
