import { Button, Flex, PS, Skeleton } from '@m1/liquid-react';
import * as React from 'react';

type NotificationPreferencesFooterProps = {
  isSaveEnabled: boolean;
  handleSaveClick: () => Promise<void>;
};

export const NotificationPreferencesFooter = ({
  isSaveEnabled,
  handleSaveClick,
}: NotificationPreferencesFooterProps) => (
  <>
    <PS
      width={420}
      content="Some types of emails cannot be disabled, such as trade confirmations, statements, and information about your investments."
    />
    <Flex my={14} alignSelf="flex-end" height={42} alignItems="center">
      <Skeleton display="flex" justifyContent="flex-end">
        <Button
          disabled={!isSaveEnabled}
          label="Save"
          onClick={handleSaveClick}
          size="medium"
        />
      </Skeleton>
    </Flex>
  </>
);
