import { Box, PL } from '@m1/liquid-react';
import React from 'react';

import { ReferrerStatus } from '~/graphql/types';

import { ReferralCompletions } from './ReferralCompletions';
import { ShareUrlContainer } from './ShareUrlContainer';

type HasExistingReferralLinkProps = {
  referrerStatus: Partial<ReferrerStatus> | null | undefined;
};

export const HasExistingReferralLinkContainer = ({
  referrerStatus,
}: HasExistingReferralLinkProps) => {
  if (!referrerStatus) {
    return <p>Error fetching referrals information. Please try again later.</p>;
  }
  const { shareUrl, shareMessage, completeReferralCount } = referrerStatus;

  return (
    <Box mt={16}>
      {!shareUrl ? (
        <PL
          content="Error fetching referral URL. Please try again later."
          mt={16}
        />
      ) : (
        <ShareUrlContainer shareMessage={shareMessage} shareUrl={shareUrl} />
      )}
      <ReferralCompletions completeReferralCount={completeReferralCount ?? 0} />
    </Box>
  );
};
