import { PS, PL, Flex, Box, styled, theme, Button } from '@m1/liquid-react';
import React, { useEffect } from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import { usePersonalLoanDirectPreApprovedOffersPageQuery } from '~/graphql/hooks';
import { PersonalLoanDirectQuestionEnum } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import { submitReactFormData } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { StyledHeader } from '../components/StyledHeader';

import { WithdrawApplicationModal } from '../components/WithdrawApplicationModal';
import { usePersonalLoanDirectWizardContext } from '../personal-loan-direct-wizard/usePersonalLoanDirectWizardContext';
import { getQuestionProps } from '../utils/getQuestionprops';

import { OfferCard } from './components';

const StyledWithdrawText = styled(PL)`
  margin-bottom: 24px;
  text-align: center;

  @media screen and (min-width: ${theme.breakpoints.XSMALL}) {
    text-align: left;
  }
`;

const ButtonContainer = styled(Flex)`
  margin-top: 24px;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  @media screen and (min-width: ${theme.breakpoints.XXSMALL}) {
    justify-content: start;
    flex-direction: row;
    margin-top: 48px;
  }
`;

export const PreApprovedOffers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  const [selectedOfferId, setSelectedOfferId] = React.useState<string | null>(
    null,
  );
  const [modalOpen, setModalOpen] = React.useState(false);
  const { goTo } = usePersonalLoanDirectWizardContext();

  useEffect(() => {
    analytics.pageView('/direct-loan-application/loan-offers');
  }, [analytics]);

  const { data, loading } = usePersonalLoanDirectPreApprovedOffersPageQuery();

  const offers =
    data?.viewer.borrow?.personalLoans?.activeApplication?.applicationOffers
      ?.edges || [];

  const loanDisclosures =
    data?.viewer.borrow?.personalLoans?.activeApplication?.loanDisclosure;

  const questions =
    data?.viewer?.borrow?.personalLoans?.applicationFlowDirect?.questions;

  const applicationId =
    data?.viewer.borrow?.personalLoans?.activeApplication?.id;

  if (loading) {
    return <Spinner fullScreen centered />;
  }

  let props;

  if (questions) {
    props = getQuestionProps({
      question: PersonalLoanDirectQuestionEnum.PreApprovedOffers,
      questions,
    });
  }

  const { title, continueLabel, nextQuestionType } = props || {};

  if (!offers?.length || !applicationId) {
    return <GenericSystemError />;
  }

  const handleSelectOffer = (offerId: string) => setSelectedOfferId(offerId);

  const handleSubmit = () => {
    const selectedoffer = offers?.find(
      (offer) => offer?.node?.id === selectedOfferId,
    );

    if (
      selectedoffer &&
      selectedoffer?.node?.formattedMonthlyPayment &&
      selectedoffer?.node?.formattedAnnualPercentageInfo.formattedValue
    ) {
      dispatch(
        submitReactFormData({
          directToPersonalLoansPreApprovedOffer: {
            offerId: selectedoffer.node.id,
            formattedMonthlyPayment:
              selectedoffer?.node?.formattedMonthlyPayment,
            formattedAnnualPercentageInfo:
              selectedoffer?.node?.formattedAnnualPercentageInfo.formattedValue,
            loanAmount: selectedoffer?.node?.formattedAmount,
            loanTerm: selectedoffer?.node?.monthlyDuration,
          },
        }),
      );

      goTo(nextQuestionType ?? 'EMPLOYMENT_STATUS_AND_INFO');
    }
  };

  return (
    <Box maxWidth={588}>
      <WithdrawApplicationModal
        applicationId={applicationId}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
      />
      <StyledHeader content={title} mb={24} />
      {offers?.map((offer) => {
        const offerDetails = offer?.node;
        return (
          <OfferCard
            key={offerDetails?.id}
            id={offerDetails?.id || ''}
            selectedOfferId={selectedOfferId}
            formattedMonthlyDuration={
              offerDetails?.formattedMonthlyDuration || ''
            }
            formattedAmount={offerDetails?.formattedAmount || ''}
            formattedMonthlyPayment={
              offerDetails?.formattedMonthlyPayment || ''
            }
            handleSelectOffer={handleSelectOffer}
            formattedAnnualPercentageInfo={
              offerDetails?.formattedAnnualPercentageInfo.formattedValue || ''
            }
          />
        );
      })}
      <StyledWithdrawText>
        {`Offers are good for 30 days, or you can `}
        <Button
          kind="link"
          style={{
            textDecoration: 'underline',
          }}
          label="withdraw"
          onClick={() => setModalOpen(true)}
        />
        {` your application now.`}
      </StyledWithdrawText>
      {loanDisclosures?.map((disclosure, index) => {
        return (
          <PS
            key={index}
            content={disclosure}
            color="foregroundNeutralSecondary"
            mb={16}
          />
        );
      })}
      <ButtonContainer>
        <ResponsiveButton
          label={continueLabel ?? 'Continue'}
          onClick={() => handleSubmit()}
          size="large"
        />
        <ResponsiveButton
          kind="text"
          size="large"
          label="Save and close"
          onClick={() => navigate({ to: '/d/home' })}
        />
      </ButtonContainer>
    </Box>
  );
};
