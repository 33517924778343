import { PL } from '@m1/liquid-react';
import * as React from 'react';

import { Container } from '~/toolbox/container';
import { Spinner } from '~/toolbox/spinner';

export const LoadStatusStep = () => {
  return (
    <Container centered>
      <Spinner />
      <PL
        color="foregroundNeutralMain"
        content="Loading your order status..."
        mt={16}
      />
    </Container>
  );
};
