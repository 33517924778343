import { Box, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import classNames from 'classnames';
import camelCase from 'lodash-es/camelCase';
import * as React from 'react';

import { NotificationBundle } from '~/redux/reducers/notificationsReducer';
import { NOTIFICATION_TYPES } from '~/static-constants';

import style from './style.module.scss';

export type NotificationProps = {
  disableDismissNotifications?: boolean;
  notification: NotificationBundle;
};

const StyledMessageButton = styled(Box)<{
  notification: string;
}>`
  text-align: right;
  color: ${({ theme, notification }) => {
    switch (notification) {
      case 'success':
        return `${theme.colors.success}`;
      case 'error':
        return `${theme.colors.critical}`;
      default:
        return `${theme.colors.foregroundNeutralMain}`;
    }
  }};
`;

export class Notification extends React.Component<NotificationProps> {
  render() {
    const { notification } = this.props;

    const classes = classNames(style.root, {
      [style[camelCase(notification.type)]]: notification.type,
    });

    return (
      <div
        style={
          this.props.disableDismissNotifications
            ? {
                cursor: 'default',
              }
            : undefined
        }
        className={classes}
      >
        {notification.type === NOTIFICATION_TYPES.ERROR_ALT
          ? this.renderErrorAlt()
          : this.renderNotification()}
      </div>
    );
  }

  renderNotification() {
    const { notification } = this.props;

    return (
      <div className={style.wrapper}>
        <div className={style.messageText}>
          {this.props.notification.message}
        </div>
        {!this.props.disableDismissNotifications && (
          <StyledMessageButton notification={camelCase(notification.type)}>
            Dismiss
          </StyledMessageButton>
        )}
      </div>
    );
  }

  renderErrorAlt() {
    return (
      <div className={style.altWrapper}>
        <Icon name="warning24" mr={8} />
        <div className={style.messageText}>
          {this.props.notification.message}
        </div>
      </div>
    );
  }
}
