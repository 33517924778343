import { makeLimit } from '~/components/research/Screener/utils';
import {
  HistoricalQuotePeriodEnum,
  SecuritySortTypeEnum,
  SecurityTypeEnum,
  SortDirectionEnum,
  SecurityLimitTypeEnum,
  SecurityScreenerQueryVariables,
} from '~/graphql/types';

export const INITIAL_EQUITY_SCREENER_VARIABLES: SecurityScreenerQueryVariables =
  {
    first: 20,
    period: HistoricalQuotePeriodEnum.OneYear,
    after: null,
    sort: {
      direction: SortDirectionEnum.Desc,
      type: SecuritySortTypeEnum.MarketCap,
    },
    filterTypes: [SecurityTypeEnum.Equity],
    filterCategory: [],
    limit: [],
  };

export const defaultSecurityLimits = {
  MARKET_CAP: makeLimit(SecurityLimitTypeEnum.MarketCap),
  PE_RATIO: makeLimit(SecurityLimitTypeEnum.PeRatio),
  DIVIDEND_YIELD: makeLimit(SecurityLimitTypeEnum.DividendYield),
};

export const PERIOD_TO_SORT: Partial<
  Record<HistoricalQuotePeriodEnum, SecuritySortTypeEnum>
> = {
  [HistoricalQuotePeriodEnum.OneYear]: SecuritySortTypeEnum.PriceChange_1Y,
  [HistoricalQuotePeriodEnum.TwoYears]: SecuritySortTypeEnum.PriceChange_2Y,

  [HistoricalQuotePeriodEnum.FiveYears]: SecuritySortTypeEnum.PriceChange_5Y,
};
