import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { SortDirectionEnum } from '~/graphql/types';

import { GridTable } from '~/toolbox/grid-table';

import { InvestActivityFormValues } from '../ActivityPage';

export const SortHeader = () => {
  const { setValue, watch, getValues } =
    useFormContext<InvestActivityFormValues>();
  const handleSortChange = () => {
    const currentSort = getValues('sort');
    const newSort =
      !Array.isArray(currentSort) &&
      currentSort?.direction === SortDirectionEnum.Asc
        ? SortDirectionEnum.Desc
        : SortDirectionEnum.Asc;

    setValue('sort.direction', newSort);
  };

  const sortDirection = watch('sort.direction');
  return (
    <GridTable.HeaderCell
      label="Date"
      onClick={handleSortChange}
      sortDirection={sortDirection}
    />
  );
};
