import { CreditCardApplicationStatusEnum } from '~/graphql/types';

type EventContext = {
  eventName: string;
  userDataKey: string;
  userDataIndex: number;
};

export const getCreditApplicationStatusEventContext = (
  creditApplicationStatus: CreditCardApplicationStatusEnum | undefined,
): EventContext | null => {
  let eventContext = null;
  switch (creditApplicationStatus) {
    case 'APPROVED':
      eventContext = {
        eventName: 'm1_cc_app_approved',
        userDataKey: 'approvedCreditCardApplicationIds',
        userDataIndex: 0,
      };
      break;
    case 'ACCEPTED':
      eventContext = {
        eventName: 'm1_cc_app_offer_accepted',
        userDataKey: 'acceptedCreditCardApplicationIds',
        userDataIndex: 1,
      };
      break;
    default:
      break;
  }

  return eventContext;
};
