import { Flex } from '@m1/liquid-react';
import * as React from 'react';

export const InitialErrorPage = () => {
  React.useEffect(() => {
    throw new Error('General CC Error.');
  }, []);

  return <Flex mt={72} width={700} />;
};
