import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import {
  AutoPayApplicationSetUpDocument,
  AutoPayApplicationSetUpMutationResult,
} from '~/graphql/hooks';
import { AutoPayApplicationSetUpInput } from '~/graphql/types';

import { hideLoadingSpinner, showLoadingSpinner } from '~/redux/actions';
import { apolloMutationSaga } from '~/redux/sagas/apolloMutationSaga';
import { getLoggers } from '~/redux/sagas/common';
import { changeStep } from '~/redux/sagas/flows/utils';
import { delay, getEnumDescription } from '~/utils';

import { STEPS } from '../../constants';

export const SET_PERSONAL_LOAN_AUTOPAY_ACCOUNT =
  'SET_PERSONAL_LOAN_AUTOPAY_ACCOUNT' as const;

export const setPersonalLoanAutoPayAccount = (payload: Maybe<string>) => ({
  payload,
  type: SET_PERSONAL_LOAN_AUTOPAY_ACCOUNT,
});

export type SetPersonalLoanAutoPayAccountAction = ReturnType<
  typeof setPersonalLoanAutoPayAccount
>;

export function* finishedAutoPayEnrollment({
  payload,
}: {
  payload?: AutoPayApplicationSetUpInput & {
    skipOnError?: boolean;
  };
}): SagaIterator<void> {
  const { analytics } = yield call(getLoggers);

  if (payload) {
    // User attempts to submit the Form
    try {
      yield put(showLoadingSpinner());

      if (payload.skipOnError) {
        analytics.recordEvent('m1_personal_loan_autopay_error');
        yield call(changeStep, STEPS.LOAN_RECAP);
      } else {
        const { data }: AutoPayApplicationSetUpMutationResult = yield call(
          apolloMutationSaga,
          {
            mutation: AutoPayApplicationSetUpDocument,
            variables: {
              input: {
                loanId: payload.loanId,
                transferParticipantId: payload.transferParticipantId,
              } satisfies AutoPayApplicationSetUpInput,
            },
          },
        );

        if (!data?.autoPayApplicationSetUp?.didSucceed) {
          analytics.recordEvent('m1_personal_loan_autopay_error');
          const enumDescription = getEnumDescription(
            data?.autoPayApplicationSetUp?.error,
            'AutoPayApplicationSetUpErrorEnum',
          );
          yield call(changeStep, STEPS.LOAN_RECAP);
          yield put({
            payload: {
              content:
                enumDescription ||
                'There was an error setting up AutoPay. Please try again once the funds reach your account.',
              duration: 'short',
              kind: 'alert',
            },
            type: 'ADD_TOAST',
          });
        }

        analytics.recordEvent('m1_personal_loan_autopay_confirmed');
        yield call(delay, 1000);
        yield call(changeStep, STEPS.LOAN_RECAP);
      }
    } catch (e: any) {
      analytics.recordEvent('m1_personal_loan_autopay_error');

      yield call(changeStep, STEPS.LOAN_RECAP);
      yield put({
        payload: {
          content: e.message,
          duration: 'short',
          kind: 'alert',
        },
        type: 'ADD_TOAST',
      });
    } finally {
      yield put(hideLoadingSpinner());
    }
  } else {
    // User skipped setting up AutoPay
    analytics.recordEvent('m1_personal_loan_autopay_skipped');
    yield call(changeStep, STEPS.LOAN_RECAP);
  }
}
