import { Button } from '@m1/liquid-react';
import React from 'react';
import { InjectedFormProps } from 'redux-form';

import { connectForm } from '~/hocs';
import { DropdownGroup } from '~/toolbox/Dropdown';

import { TransferParticipantDropdownField } from './fields';
import { required } from './validators';

type InitialValues = {
  transferParticipantId: string | null | undefined;
};

type PersonalLendingDepositDestinationProps = InjectedFormProps<any> & {
  continueCta: string;
  depositDestinations: Array<DropdownGroup>;
  initialValues: InitialValues;
};

const PersonalLendingDepositDestinationComponent = ({
  handleSubmit,
  depositDestinations,
  continueCta,
}: PersonalLendingDepositDestinationProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <TransferParticipantDropdownField
        label="Deposit To"
        name="transferParticipantId"
        options={depositDestinations}
        placeholder="Select account"
        validate={[required]}
      />
      <Button mt={32} label={continueCta} type="submit" size="large" />
    </form>
  );
};

export const PersonalLendingDepositDestination = connectForm({
  form: 'personal-lending-deposit-destination',
})(PersonalLendingDepositDestinationComponent);
