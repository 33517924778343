import { Box, Flex, styled } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { useCryptoEligibilityQuery } from '~/graphql/hooks';
import { Spinner } from '~/toolbox/spinner';

import { CryptoContent } from './components/CryptoContent';
import { CryptoModal } from './components/CryptoModal';

const StyledFlexBgContainer = styled(Flex)`
  min-height: 100vh;
  position: relative;
  width: 100%;
`;

const StyledBackgroundImage = styled(Illustration)`
  height: 100%;
  position: absolute;
  inset: 0;
`;

export const CryptoPage = () => {
  const isSmallDevice = useMediaQuery({
    query: '(max-width: 992px)',
  });

  const [modalVisible, setModalVisible] = React.useState(false);
  const openModal = () => setModalVisible(true);

  const { data, loading } = useCryptoEligibilityQuery();

  if (loading) {
    return (
      <Box mt={32}>
        <Spinner fullScreen />
      </Box>
    );
  }

  if (!data?.viewer.crypto) {
    return <GenericSystemError />;
  }
  const { crypto } = data.viewer;
  const { isEligible } = crypto;

  if (isSmallDevice) {
    return (
      <StyledFlexBgContainer>
        <StyledBackgroundImage name="cryptoWaveBackground" />
        <Flex
          maxWidth={1200}
          mt={32}
          flex="1"
          textAlign="center"
          flexDirection="column"
        >
          <BackArrow content="Back" mb={16} ml={8} />
          <CryptoContent onLearnMoreClick={openModal} isEligible={isEligible} />
        </Flex>
      </StyledFlexBgContainer>
    );
  }

  return (
    <StyledFlexBgContainer>
      <StyledBackgroundImage name="cryptoWaveBackground" />
      <CryptoModal
        modalVisible={modalVisible}
        closeModal={() => setModalVisible(false)}
      />
      <Flex
        justifyContent="space-between"
        borderRadius="8px 8px 0 0"
        flex="1"
        flexDirection="row"
        maxWidth={1200}
        mx="auto"
        zIndex={1}
      >
        <Flex width="100%" justifyContent="center">
          <Flex flexDirection="column">
            <BackArrow
              content="Back"
              mt={32}
              mb={16}
              onClick={() => window.history.back()}
            />
            <CryptoContent
              onLearnMoreClick={openModal}
              isEligible={isEligible}
            />
          </Flex>
        </Flex>
        <Flex width="100%" justifyContent="center" mt={164}>
          <Illustration name="cryptoPie" width={300} height={300} />
        </Flex>
      </Flex>
    </StyledFlexBgContainer>
  );
};
