import { Text } from '@m1/liquid-react';
import React from 'react';

import { EXTERNAL_LINKS } from '~/static-constants';
import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';

export const InvalidTokenPartial = () => {
  return (
    <Container centered padded="wide" text>
      <Text>
        Our systems are currently experiencing difficulty loading this Pie. If
        the problem persists, please contact{' '}
        <Link to={EXTERNAL_LINKS.SUPPORT_CENTER} target="_blank">
          support
        </Link>
        .
      </Text>
    </Container>
  );
};
