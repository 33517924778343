import * as React from 'react';

export const useWizard = <K extends string>(
  steps: Record<K, React.ReactElement>,
) => {
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0);

  const stepKeys = Object.keys(steps) as K[];

  const stepKey = stepKeys[currentStepIndex];

  const step = steps[stepKey];

  const isFirstStep = currentStepIndex === 0;
  const isLastStep = currentStepIndex === stepKeys.length - 1;

  const handleNext = () => setCurrentStepIndex((prevIndex) => prevIndex + 1);

  const handleBack = () => setCurrentStepIndex((prevIndex) => prevIndex - 1);

  const handleGoTo = (stepKey: K) => {
    const stepIndex = stepKeys.indexOf(stepKey);
    setCurrentStepIndex(stepIndex);
  };

  return {
    back: handleBack,
    next: handleNext,
    step,
    currentStepIndex,
    goTo: handleGoTo,
    isFirstStep,
    isLastStep,
    steps,
    stepKeys,
    stepKey,
  };
};
