import type { IllustrationsType } from '@m1/liquid-react/illustrations';

export type BorrowMarketingUseCase = {
  description: string;
  image: IllustrationsType;
  route: string;
  title: string;
};

export const BORROW_USE_CASES: ReadonlyArray<BorrowMarketingUseCase> = [
  {
    description:
      'When you think it’s the right time to buy, use a Margin Loan to invest more into your portfolio.',
    image: 'extraBuyingPower',
    route: 'buying-power',
    title: 'Get extra buying power',
  },
  {
    description:
      'Say “I do” without worrying about high interest rates or payments right after your honeymoon.',
    image: 'wedding',
    route: 'wedding',
    title: 'Pay for your wedding',
  },
  {
    description:
      'Cover the gaps in funding with a Margin Loan without disrupting your other earmarked funds.',
    image: 'startBusiness',
    route: 'business',
    title: 'Start a business',
  },
  {
    description:
      'Keep your portfolio invested even when there are unplanned events in your life.',
    image: 'emergencyExpense',
    route: 'emergency',
    title: 'Handle an emergency',
  },
  {
    description:
      'Use a Margin Loan as an alternative to a home equity loan and renovate your house into your dream home.',
    image: 'homeRemodel',
    route: 'home-renovations',
    title: 'Remodel your home',
  },
  {
    description:
      'Eliminate high-interest student loans, credit card debt, or auto loans.',
    image: 'refinanceDebt',
    route: 'refinance',
    title: 'Refinance other debt',
  },
];
