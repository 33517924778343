import { Text, Flex } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';
import { useController } from 'react-hook-form';
import { components } from 'react-select';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { SingleValueProps } from 'react-select/src/components/SingleValue';

import { ReferralPaymentAccountQuery } from '~/graphql/types';

import { Dropdown } from '~/toolbox/Dropdown';

type PaymentAccountFieldProps = {
  accounts:
    | ReferralPaymentAccountQuery['viewer']['accounts']
    | null
    | undefined;
};

export const PaymentAccountField = ({ accounts }: PaymentAccountFieldProps) => {
  const { field } = useController({
    name: 'accountId',
    rules: {
      required: true,
    },
  });

  const source = React.useMemo(() => {
    if (!accounts?.edges) {
      return [
        {
          name: 'Loading...',
          description: null,
        },
      ];
    }

    const sources = [];
    for (const edge of accounts.edges) {
      if (edge && edge.node) {
        sources.push({
          name: edge.node.id,
          description: edge.node.name,
        });
      }
    }
    return sources;
  }, [accounts]);

  return (
    <Dropdown
      {...field}
      label="Payment Account"
      source={source}
      optionContent={(props: any) => (
        <Flex alignItems="center">
          <Icon name="accountInvestPrimary32" />
          <Text content={props.data.label} ml={16} />
        </Flex>
      )}
      components={{ Placeholder, SingleValue }}
    />
  );
};

// FIXME(Wolf): Fix this once you upgrade react-select.
const ReactSelectPlaceholder = components.Placeholder as any;

const Placeholder = (props: PlaceholderProps<any>) => {
  return (
    <ReactSelectPlaceholder {...props}>
      <Flex alignItems="center">
        <Icon name="accountAccountsAccent32" />
        <Text content="Select" ml={16} />
      </Flex>
    </ReactSelectPlaceholder>
  );
};

// FIXME(Wolf): Fix this once you upgrade react-select.
const ReactSelectSingleValue = components.SingleValue as any;

const SingleValue = (props: SingleValueProps<any>) => {
  return (
    <ReactSelectSingleValue {...props}>
      <Flex alignItems="center">
        <Icon name="accountInvestPrimary32" />
        <Text font="LL" content={props.selectProps.value.label} ml={16} />
      </Flex>
    </ReactSelectSingleValue>
  );
};
