import { Box, Input } from '@m1/liquid-react';
import * as React from 'react';

import { usePercentageValidation } from '~/hooks/usePercentageValidation';
import type { SmartTransferFulfillmentConditionInput } from '~/redux/actions/smartTransfer/smartTransferActions.types';

import { DEFAULT_FULFILLMENT_CONDITION_FIELDS } from './constants';

type CreditUtilizationProps = {
  onFulfillmentDataChange: (
    arg0: SmartTransferFulfillmentConditionInput,
  ) => void;
  onIsContinueAllowedChange: (arg0: boolean) => void;
  preSelectedFulfillmentConditionDataOfActiveContra:
    | SmartTransferFulfillmentConditionInput
    | null
    | undefined;
};

export const CreditUtilization = ({
  onFulfillmentDataChange,
  onIsContinueAllowedChange,
  preSelectedFulfillmentConditionDataOfActiveContra,
}: CreditUtilizationProps) => {
  const defaultPercentageValueInitial =
    typeof preSelectedFulfillmentConditionDataOfActiveContra?.borrowUtilization ===
    'number'
      ? `${preSelectedFulfillmentConditionDataOfActiveContra.borrowUtilization}`
      : '';
  const [percentageValueInitial, setPercentageValue] = React.useState(
    defaultPercentageValueInitial,
  );
  const { onInputChange, percentageErrorMessage, percentageValue } =
    usePercentageValidation(percentageValueInitial, setPercentageValue);
  // fire parent callback to sync continue state
  React.useEffect(() => {
    const isContinueAllowed = percentageValue !== '' && !percentageErrorMessage;
    onIsContinueAllowedChange(isContinueAllowed);
  }, [percentageValue, percentageErrorMessage, onIsContinueAllowedChange]);

  // when fulfillment condition data changes, fire callback
  React.useEffect(() => {
    onFulfillmentDataChange({
      ...DEFAULT_FULFILLMENT_CONDITION_FIELDS,
      borrowUtilization: Number(percentageValue),
      fulfillmentConditionType: 'CREDIT_UTILIZATION_CAP',
    });
  }, [percentageValue, onFulfillmentDataChange]);
  return (
    <Box>
      <Input
        autoComplete="off"
        onChange={onInputChange}
        value={percentageValue}
        error={percentageErrorMessage}
        label="Enter percentage"
        maskType="money"
      />
    </Box>
  );
};
