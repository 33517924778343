export const CREATE_SMART_TRANSFER_ACTIONS = Object.freeze({
  ADD_ACTIVE_CONTRA_PARTICIPANT: 'ADD_ACTIVE_CONTRA_PARTICIPANT' as const,
  ADD_SMART_TRANSFER_CONTRA_PARTICIPANT_ID:
    'ADD_SMART_TRANSFER_CONTRA_PARTICIPANT_ID' as const,
  ADD_SMART_TRANSFER_EDIT_REQUIREMENTS_ID:
    'ADD_SMART_TRANSFER_EDIT_REQUIREMENTS_ID' as const,
  DELETE_ACTIVE_CONTRA_PARTICIPANT: 'DELETE_ACTIVE_CONTRA_PARTICIPANT' as const,
  REORDER_ACTIVE_CONTRA_PARTICIPANTS:
    'REORDER_ACTIVE_CONTRA_PARTICIPANTS' as const,
  REPLACE_ACTIVE_CONTRA_PARTICIPANT:
    'REPLACE_ACTIVE_CONTRA_PARTICIPANT' as const,
  RESET_SMART_TRANSFER_ACTIVE_CONTRA_PARTICIPANTS:
    'RESET_SMART_TRANSFER_ACTIVE_CONTRA_PARTICIPANTS' as const,
  SAVE_FOCUS_ACCOUNT_ID: 'SAVE_FOCUS_ACCOUNT_ID' as const,
  SET_SMART_TRANSFER_BALANCE_TRIGGER_DATA:
    'SET_SMART_TRANSFER_BALANCE_TRIGGER_DATA' as const,
  SET_SMART_TRANSFER_FULFILLMENT_CONDITIONS:
    'SET_SMART_TRANSFER_FULFILLMENT_CONDITIONS' as const,
  SET_SMART_TRANSFER_FULFILLMENT_CONDITION_MODE:
    'SET_SMART_TRANSFER_FULFILLMENT_CONDITION_MODE' as const,
  SET_SMART_TRANSFER_SUCCESS: 'SET_SMART_TRANSFER_SUCCESS' as const,
  TOGGLE_SMART_TRANSFER_HELPER: 'TOGGLE_SMART_TRANSFER_HELPER' as const,
});

export type AddSmartTransferContraParticipantIdAction = {
  payload: string;
  type: typeof CREATE_SMART_TRANSFER_ACTIONS.ADD_SMART_TRANSFER_CONTRA_PARTICIPANT_ID;
};

export type ToggleSmartTransferHelperAction = {
  type: typeof CREATE_SMART_TRANSFER_ACTIONS.TOGGLE_SMART_TRANSFER_HELPER;
};

export type ReorderSmartTransferActiveContraParticipants = {
  payload: Array<string>;
  type: typeof CREATE_SMART_TRANSFER_ACTIONS.REORDER_ACTIVE_CONTRA_PARTICIPANTS;
};

export type ResetSmartTransferActiveContraParticipantsAction = {
  type: typeof CREATE_SMART_TRANSFER_ACTIONS.RESET_SMART_TRANSFER_ACTIVE_CONTRA_PARTICIPANTS;
};

export type SaveSetSmartTransferBalanceTriggerDataAction = {
  payload: {
    amount: number;
    balanceTriggerType: string;
    underBalanceRefillTarget?: number;
  };
  type: typeof CREATE_SMART_TRANSFER_ACTIONS.SET_SMART_TRANSFER_BALANCE_TRIGGER_DATA;
};

export type AddSmartTransferEditRequirementsIdAction = {
  payload: {
    contraParticipantId: string;
    editRequirementsId: string;
  };
  type: typeof CREATE_SMART_TRANSFER_ACTIONS.ADD_SMART_TRANSFER_EDIT_REQUIREMENTS_ID;
};

export const addSmartTransferContraParticipantIdAction = (
  payload: AddSmartTransferContraParticipantIdAction['payload'],
): AddSmartTransferContraParticipantIdAction => ({
  payload,
  type: CREATE_SMART_TRANSFER_ACTIONS.ADD_SMART_TRANSFER_CONTRA_PARTICIPANT_ID,
});

export const toggleSmartTransferHelper =
  (): ToggleSmartTransferHelperAction => ({
    type: CREATE_SMART_TRANSFER_ACTIONS.TOGGLE_SMART_TRANSFER_HELPER,
  });

export const reorderActiveContraParticipants = (
  payload: ReorderSmartTransferActiveContraParticipants['payload'],
): ReorderSmartTransferActiveContraParticipants => ({
  type: CREATE_SMART_TRANSFER_ACTIONS.REORDER_ACTIVE_CONTRA_PARTICIPANTS,
  payload,
});

export type CreateSmartTransferAction =
  | AddSmartTransferEditRequirementsIdAction
  | AddSmartTransferContraParticipantIdAction
  | ResetSmartTransferActiveContraParticipantsAction
  | ReorderSmartTransferActiveContraParticipants
  | SaveSetSmartTransferBalanceTriggerDataAction
  | ToggleSmartTransferHelperAction;
