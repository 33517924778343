import { Flex, PL, PM, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { MovesSlicesDestinationRowFragment } from '~/graphql/types';

import { PortfolioSlice } from '~/pie-trees';
import { clickedMoveSlicesDestination } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { useToast } from '~/toasts';
import { Logo } from '~/toolbox/logo';

const StyledDestinationRow = styled(Flex)`
  cursor: pointer;
  transition: 200ms background-color linear;

  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundInfoSubtle};
  }
`;

type MoveSlicesDestinationRowProps = {
  portfolioSlice: NonNullable<
    MovesSlicesDestinationRowFragment['portfolioSlice']
  >;
  validity: MovesSlicesDestinationRowFragment['validity'];
  slicesToMove: Array<PortfolioSlice>;
};

export const MoveSlicesDestinationRow = ({
  portfolioSlice,
  validity,
  slicesToMove,
}: MoveSlicesDestinationRowProps) => {
  const dispatch = useDispatch();
  const { addToast } = useToast();

  const destinationLabel = React.useMemo(() => {
    const ancestors =
      'ancestors' in portfolioSlice ? portfolioSlice?.ancestors : [];

    if (!ancestors.length || ancestors.length === 1) {
      return null;
    }

    return ancestors.reduce((finalString, ancestor, index) => {
      const isLastItem = index === ancestors.length - 1;

      if (ancestor) {
        return isLastItem
          ? `${finalString} ${ancestor.to.name}`
          : `${finalString} ${ancestor.to.name} /`;
      }

      return finalString;
    }, '');
  }, [portfolioSlice]);

  return (
    <StyledDestinationRow
      alignItems="center"
      p={12}
      onClick={() => {
        if (validity?.isValid) {
          dispatch(
            clickedMoveSlicesDestination({
              destinationPortfolioSliceId: portfolioSlice.id,
              destinationSliceableId: portfolioSlice.to.id,
              slicesToMove,
            }),
          );
        } else {
          addToast({
            content:
              validity?.message ||
              'Something went wrong. Please try again or contact us for further assistance.',
            kind: 'alert',
            duration: 'short',
          });
        }
      }}
    >
      <Logo
        content={<Icon name="createPie32" />}
        placeholder=""
        style={{
          borderRadius: '4px',
          height: '26px',
          marginRight: 8,
          paddingBottom: 0,
          userSelect: 'none',
          width: '26px',
        }}
      />
      <Flex
        flexDirection="column"
        justifyContent="center"
        height={48}
        width="80%"
      >
        <PL color="foregroundNeutralMain">{portfolioSlice.to.name}</PL>
        {destinationLabel && (
          <PM color="foregroundNeutralSecondary">{destinationLabel}</PM>
        )}
      </Flex>
    </StyledDestinationRow>
  );
};
