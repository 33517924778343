import React from 'react';

import {
  StyledTitleBar,
  StyledPaginationButtonContainer,
  StyledResultTable,
  ScreenerTableTitle,
  ScreenerPaginationButtonGroup,
} from '~/components/research/Screener';
import { ScreenerFundFragment } from '~/graphql/types';
import { Table } from '~/toolbox/table';

import { useFundScreenerResult } from './FundScreenerProvider';

import { FundScreenerTableHeader } from './FundScreenerTableHeader';
import { FundScreenerTableRow } from './FundScreenerTableRow';

export const FundScreenerTable = () => {
  const {
    queryResult: { data, loading },
    handleNextPage,
    handlePreviousPage,
    hasPreviousPage,
  } = useFundScreenerResult();

  const total = data?.viewer.securities.total;
  const hasNextPage = data?.viewer.securities.pageInfo.hasNextPage;
  const securities = data?.viewer.securities.edges;

  return (
    <div>
      <StyledTitleBar>
        <ScreenerTableTitle loading={loading} total={total} />
        <ScreenerPaginationButtonGroup
          hasNextPage={hasNextPage}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          hasPreviousPage={hasPreviousPage}
        />
      </StyledTitleBar>
      <StyledResultTable>
        <FundScreenerTableHeader />
        {Boolean(securities?.length) && (
          <Table.Body
            emptyMessage="No results to display!"
            dataTestId="fund-results-table-body"
          >
            {securities?.map((edge, index) => (
              <FundScreenerTableRow
                fund={edge as ScreenerFundFragment}
                key={index}
              />
            ))}
          </Table.Body>
        )}
      </StyledResultTable>

      <StyledPaginationButtonContainer>
        <ScreenerPaginationButtonGroup
          hasNextPage={hasNextPage}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          hasPreviousPage={hasPreviousPage}
        />
      </StyledPaginationButtonContainer>
    </div>
  );
};
