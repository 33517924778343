import { Box, Button, Text } from '@m1/liquid-react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { ControlledInput } from '~/components/form/ControlledInput';
import { InitiateResetPasswordInput } from '~/graphql/types';
import { useSelector } from '~/redux/hooks';

type InitiateResetPasswordFormProps = {
  onSubmit: (input: InitiateResetPasswordInput) => void;
};

export const InitiateResetPasswordForm = ({
  onSubmit,
}: InitiateResetPasswordFormProps) => {
  const stateEmail = useSelector((state) => state.config.email);

  const { handleSubmit, control } = useForm<InitiateResetPasswordInput>({
    defaultValues: {
      email: stateEmail || '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box p="16px 0">
        <Text content="Please enter your email and we'll send you a link to reset your password." />
      </Box>
      <ControlledInput
        backgroundColor="backgroundNeutralSecondary"
        name="email"
        label="Email"
        rules={{
          required: true,
        }}
        autoFocus
        control={control}
      />
      <Box pt={16}>
        <Button
          label="Submit"
          kind="primary"
          size="large"
          fullWidth
          type="submit"
        />
      </Box>
    </form>
  );
};
