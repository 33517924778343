import * as React from 'react';
import { useFormContext, Path } from 'react-hook-form';

import { getEnumEntries } from '~/utils';

import { ControlledDropdown } from '../ControlledDropdown';

import { AddressFormFields, StateSectionProps } from './types';

const FIELD_NAME = 'stateOrProvince';

export const StateOrProvinceDropdown = <NS extends string = ''>({
  backgroundColor,
  disabled,
  kind,
  namespace,
}: StateSectionProps<NS>) => {
  const { control } = useFormContext<AddressFormFields<NS>>();
  const name = (
    namespace ? `${namespace as NS}.${FIELD_NAME}` : FIELD_NAME
  ) as Path<AddressFormFields<NS>>;
  const stateOrProvinceOptions =
    kind === 'CUSTODIAL'
      ? 'MailingAddressSubdivisionForCustodialBeneficiariesEnum'
      : 'MailingAddressSubdivisionEnum';

  const options = getEnumEntries(stateOrProvinceOptions);

  return (
    <ControlledDropdown
      control={control}
      name={name}
      backgroundColor={backgroundColor}
      label="State"
      source={options}
      disabled={disabled}
      displayOptionValue
      rules={{
        required: 'Required',
      }}
    />
  );
};
