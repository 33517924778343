import { Flex, styled } from '@m1/liquid-react';
import * as React from 'react';

import { Link } from '~/toolbox/link';

// after section here reserves space for bold text
// so when we select the item and it becomes bold, it doesnt shift a few pixels
const StyledLabel = styled.p<{ label: string; selected: boolean }>`
  font-size: 14px;
  line-height: 24px;
  height: 24px;
  letter-spacing: 0px;
  font-weight: ${({ selected }) => (selected ? '600' : '400')};
  &::after {
    display: block;
    content: '${({ label }) => label}';
    font-weight: 600;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }
`;

const StyledExternalLink = styled.a`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0px;
  margin-left: 16px;
`;

const StyledNavLink = styled(Flex)<{
  selected: boolean;
}>`
  display: inline-flex;
  padding: 16px 12px;
  margin: 0;
  border: none;
  color: ${({ theme }) => theme.colors.foregroundNeutralMain};
  user-select: none;
  cursor: pointer;
  border-bottom: ${({ selected, theme }) => {
    return selected
      ? `2px solid ${theme.colors.foregroundNeutralSecondary}`
      : `transparent`;
  }};
  &:link {
    border-bottom: ${({ selected, theme }) => {
      return selected
        ? `2px solid ${theme.colors.foregroundNeutralSecondary}`
        : `transparent`;
    }};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.foregroundNeutralMain};
    background-color: ${({ theme }) => theme.colors.backgroundNeutralTertiary};
  }
  &:focus {
    border-bottom: ${({ selected, theme }) =>
      selected
        ? `2px solid ${theme.colors.foregroundSecondary}`
        : `transparent`};
  }
`;

export type TopNavLinkProps = {
  label: string;
  linkTo?: string;
  openInNewWindow?: string;
  selected?: boolean;
};

export const TopNavLink = ({
  label,
  linkTo = '',
  openInNewWindow = '',
  selected = false,
}: React.PropsWithChildren<TopNavLinkProps>) => {
  const getNavProps = (linkTo: string, openInNewWindow: string) => {
    if (openInNewWindow !== '') {
      return {
        as: StyledExternalLink,
        href: openInNewWindow,
        target: '_blank',
      };
    }
    if (linkTo !== '') {
      return { as: Link, to: linkTo };
    }
    return {};
  };

  const linkProps = getNavProps(linkTo, openInNewWindow);

  return (
    <StyledNavLink tabIndex={0} selected={selected} {...linkProps}>
      <Flex flexDirection="column">
        <StyledLabel
          label={label}
          selected={selected}
          color="foregroundNeutralMain"
          data-testid={`top-nav-link-${label.toLocaleLowerCase().replaceAll(' ', '')}`}
        >
          {label}
        </StyledLabel>
      </Flex>
    </StyledNavLink>
  );
};
