import { Box, PL, styled } from '@m1/liquid-react';
import * as React from 'react';

import { List } from '~/toolbox/list';

const PROFILE_CERTIFICATION_HEADER =
  'Under penalties of perjury, I certify that:';

const PROFILE_CERTIFICATION_LIST = [
  'The number I entered is my correct social security number.',
  'I’m a U.S. citizen or permanent resident, and/or I’m considered a U.S. person for tax purposes.',
  'I am exempt from FATCA reporting.',
];

const PROFILE_DISCLAIMER_TEXT =
  'The Internal Revenue Service does not require your consent to any provision of this account application other than the certifications required to avoid backup withholding.';

const StyledList = styled(List)`
  color: ${(props) => props.theme.colors.foregroundNeutralSecondary};
`;

export const ProfileConfirmationCertificationText = () => {
  return (
    <Box my={24}>
      <PL
        color="foregroundNeutralSecondary"
        content={PROFILE_CERTIFICATION_HEADER}
        mb={8}
      />
      <StyledList ordered mb={24}>
        {PROFILE_CERTIFICATION_LIST.map((value) => {
          return <List.Item key={value} content={value} mb={16} pl={10} />;
        })}
      </StyledList>
      <PL content={PROFILE_DISCLAIMER_TEXT} />
    </Box>
  );
};
