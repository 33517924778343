import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { FullyPaidLendingDisclosure } from '~/components/FullyPaidLendingDisclosure';
import { useSavingsConfirmationQuery } from '~/graphql/hooks';

export const SavingsFullyPaidLendingDisclosure = ({
  onboardingValue,
}: {
  onboardingValue: string;
}) => {
  const dispatch = useDispatch();

  const { data, loading } = useSavingsConfirmationQuery({
    variables: {
      onboardingValue,
    },
    skip: !onboardingValue,
  });

  const disclosure =
    data?.viewer?.save?.savings?.onboarding?.fullyPaidLendingDisclosure;

  const handleDispatch = useCallback(
    (fplOptIn: boolean) => {
      dispatch({
        type: 'SET_FULLY_PAID_LENDING_STATUS',
        payload: fplOptIn,
      });
    },
    [dispatch],
  );

  if (!disclosure || loading) {
    return null;
  }

  return (
    <FullyPaidLendingDisclosure
      disclosure={disclosure}
      handleDispatch={handleDispatch}
    />
  );
};
