import * as React from 'react';
import { Route } from 'react-router-dom';

import { ReacceptLegalTermsPage } from './ReacceptLegalTermsPage';

export const ReacceptLegalTermsRoute = () => {
  return (
    <Route path="reaccept-legal-terms" element={<ReacceptLegalTermsPage />} />
  );
};
