import { PL, OneColumnConstrained, Box, styled, theme } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import { useNavigate } from '~/hooks/useNavigate';

import { StyledHeader } from './StyledHeader';

const StyledIllustration = styled(Illustration)`
  height: 168px;
  width: 169px;

  @media screen and (min-width: ${theme.breakpoints.SMALL}) {
    height: 240px;
    width: 242px;
  }
`;

export const ApplicationReceived = () => {
  const navigate = useNavigate();

  const title = 'Your application has been received.';
  const content =
    'Please check back tomorrow to review your application status.';

  return (
    <OneColumnConstrained>
      <Box display="flex" justifyContent="center" mt={48}>
        <StyledIllustration name="loanApproved" />
      </Box>
      <StyledHeader content={title} mt={24} />
      <PL content={content} pt={8} pb={8} mb={32} />
      <ResponsiveButton
        kind="primary"
        label="Return Home"
        onClick={() => navigate({ to: '/d/home' })}
        size="large"
      />
    </OneColumnConstrained>
  );
};
