import * as React from 'react';

import { useNavigate } from '~/hooks/useNavigate';

import { useSelector } from '~/redux/hooks';
import {
  hasAccount as hasAccountSelector,
  hasProfile as hasProfileSelector,
  userHasOnboarded as userHasOnboardedSelector,
} from '~/redux/selectors';

export const RequireAccountOrProfileGuard = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const [isEvaluating, setIsEvaluating] = React.useState(true);

  const { hasProfile, hasAccount, userHasOnboarded } = useSelector((state) => ({
    hasProfile: hasProfileSelector(state),
    hasAccount: hasAccountSelector(state),
    userHasOnboarded: userHasOnboardedSelector(state),
  }));

  React.useEffect(() => {
    if (isEvaluating) {
      if (!hasProfile && !hasAccount && !userHasOnboarded) {
        navigate({
          to: '/start/product-selection',
          options: { replace: true },
        });
      } else {
        setIsEvaluating(false);
      }
    }
  }, [
    navigate,
    hasProfile,
    hasAccount,
    userHasOnboarded,
    setIsEvaluating,
    isEvaluating,
  ]);

  if (isEvaluating) {
    return null;
  }

  return children;
};
