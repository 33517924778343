import { Box, Flex, PM, type LayoutableProps } from '@m1/liquid-react';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { AssetLogo } from '~/components/pie/SliceableLogo';
import type { AccountStockLoanGroupsFragment } from '~/graphql/types';
import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';
import { GridTable } from '~/toolbox/grid-table';

export function AccountStockLoansTable({
  accountStockLoanHoldings,
  ...rest
}: LayoutableProps & {
  accountStockLoanHoldings: Array<AccountStockLoanGroupsFragment> | null;
}) {
  const isLargeScreen = useMediaQuery({ query: RESPONSIVE_BREAKPOINTS.LARGE });
  return (
    <GridTable
      isNewStyle
      emptyStateContent={null}
      gridTemplateColumns={isLargeScreen ? 'auto 160px' : 'auto 96px'}
      {...rest}
    >
      <GridTable.HeaderRow>
        <GridTable.HeaderCell label="Account" textAlign="left" />
        <GridTable.HeaderCell label="Shares" textAlign="right" />
      </GridTable.HeaderRow>
      {accountStockLoanHoldings?.map((accountHoldings, index) => (
        <GridTable.Group
          emptyMessage={accountHoldings.emptyGroupMessage}
          header={accountHoldings.header}
          key={index}
        >
          {accountHoldings.stockLoans?.map((loan) => (
            <AccountStockLoanTableRow
              key={loan.securityReference.descriptor}
              stockLoan={loan}
            />
          ))}
        </GridTable.Group>
      ))}
    </GridTable>
  );
}

export function AccountStockLoanTableRow({
  stockLoan,
}: {
  stockLoan: NonNullable<AccountStockLoanGroupsFragment['stockLoans']>[number];
}) {
  const { descriptor, security } = stockLoan.securityReference;

  return (
    <GridTable.Row>
      <GridTable.Cell textAlign="left">
        <Flex alignItems="center">
          {security?.profile && (
            <Box height={24} width={24} mr={8}>
              <AssetLogo profile={security.profile} symbol={security.symbol} />
            </Box>
          )}
          <Flex flexDirection="column">
            {(() => {
              if (security) {
                return (
                  <>
                    <PM
                      color="foregroundNeutralSecondary"
                      content={security.symbol}
                    />
                    <PM content={security.name} fontWeight={600} />
                  </>
                );
              }

              return (
                <PM color="foregroundNeutralSecondary" content={descriptor} />
              );
            })()}
          </Flex>
        </Flex>
      </GridTable.Cell>
      <GridTable.Cell textAlign="right">
        <PM content={stockLoan.quantity} />
      </GridTable.Cell>
    </GridTable.Row>
  );
}
