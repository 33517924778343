import { SagaIterator } from 'redux-saga';
import { call, fork, put, select } from 'redux-saga/effects';

import { SMART_TRANSFER_STEPS as STEPS } from '~/flows/components/create-smart-transfer/constants';
import {
  DetermineInitialSmartTransferStepDocument,
  DetermineInitialSmartTransferStepQueryResult,
  FocusParticipantsOnFocusOptionDocument,
  FocusParticipantsOnFocusOptionQueryResult,
  SetSmartTransferRuleDocument,
  SetSmartTransferRuleMutationResult,
} from '~/graphql/hooks';
import {
  SetSmartTransferRuleInput,
  TransferParticipant,
} from '~/graphql/types';
import { NavigateFunction } from '~/hooks/useNavigate';
import { SentryReporter } from '~/loggers';
import {
  ACTION_TYPES as ACTIONS,
  FinishedFlowStepAction,
  hideLoadingSpinner,
  showLoadingSpinner,
} from '~/redux/actions';

import type { AppState } from '../../../reducers/types';
import { apolloMutationSaga } from '../../apolloMutationSaga';
import { apolloQuerySaga } from '../../apolloQuerySaga';
import { getSentryReporter } from '../../common';
import { changeStep, makeFlowFuncs } from '../utils';

type ParticipantList = Pick<TransferParticipant, 'id'>[];

function* queryFocusParticipantsByFocusOptionId(
  focusOptionId: string,
): SagaIterator<ParticipantList> {
  const { data }: FocusParticipantsOnFocusOptionQueryResult = yield call(
    apolloQuerySaga,
    {
      query: FocusParticipantsOnFocusOptionDocument,
      variables: {
        focusOptionId,
      },
    },
  );
  if (
    data &&
    data.node?.__typename === 'NewSmartTransferFocusOption' &&
    data.node.focusParticipants &&
    data.node.focusParticipants.list
  ) {
    return data.node.focusParticipants.list;
  }
  throw new Error(
    'Unable to read focusParticipants from provided focusOption node ID.',
  );
}

const { takeFlow, takeFlowStep } = makeFlowFuncs('CREATE_SMART_TRANSFER');

export function* createSmartTransferSaga(): SagaIterator<void> {
  yield fork(takeFlow, beginCreateSmartTransfersFlow);
}

function* beginCreateSmartTransfersFlow(): SagaIterator<void> {
  yield fork(
    takeFlowStep,
    STEPS.INTRODUCE_SMART_TRANSFER,
    finishedIntroduceSmartTransfer,
  );
  yield fork(
    takeFlowStep,
    STEPS.INITIALIZE_SMART_TRANSFER,
    finishedInitializeSmartTransfer,
  );
  yield fork(
    takeFlowStep,
    STEPS.SELECT_FOCUS_ACCOUNT,
    finishedSelectFocusAccount,
  );
  yield fork(takeFlowStep, STEPS.SET_SMART_TRANSFER, finishedSetSmartTransfer);
  yield fork(
    takeFlowStep,
    STEPS.SET_FULFILLMENT_CONDITION,
    finishedSetFulfillmentCondition,
  );
  yield fork(
    takeFlowStep,
    STEPS.CONFIRM_SMART_TRANSFER,
    finishedConfirmSmartTransfer,
  );
  yield fork(
    takeFlowStep,
    STEPS.SMART_TRANSFER_RECEIPT,
    finishedSmartTransferReceipt,
  );

  try {
    const { data }: DetermineInitialSmartTransferStepQueryResult = yield call(
      apolloQuerySaga,
      { query: DetermineInitialSmartTransferStepDocument },
    );

    if (!data?.viewer) {
      throw new Error(
        'Could not fetch appropriate remote data to determine which screen to direct the user to.',
      );
    }

    if (data.viewer?.user?.data) {
      const hasUserViewedSmartTransfersIntroScreen = data.viewer.user.data.find(
        (dataPoint) => dataPoint.key.indexOf('smartTransferIntroDate') > -1,
      );
      if (!hasUserViewedSmartTransfersIntroScreen) {
        yield call(changeStep, STEPS.INTRODUCE_SMART_TRANSFER);
      } else {
        yield call(changeStep, STEPS.INITIALIZE_SMART_TRANSFER);
      }
    } else {
      yield call(changeStep, STEPS.INITIALIZE_SMART_TRANSFER);
    }
  } catch (e: any) {
    yield call(changeStep, STEPS.REMOTE_ERROR);
  }
}

function* finishedIntroduceSmartTransfer() {
  yield call(changeStep, STEPS.INITIALIZE_SMART_TRANSFER);
}

function* finishedInitializeSmartTransfer(
  action: FinishedFlowStepAction<string>,
): SagaIterator<void> {
  const { payload } = action;
  const sentry: SentryReporter = yield call(getSentryReporter);
  let focusParticipants: ParticipantList;
  try {
    focusParticipants = yield call(
      queryFocusParticipantsByFocusOptionId,
      payload,
    );
  } catch (e) {
    sentry.message('Error getting Smart Transfer focus options', {
      extra: {
        focusOptionId: payload,
      },
      rawError: e,
    });
    yield put({
      payload: {
        content:
          'Something went wrong with your request. Please try again later or contact support.',
        duration: 'short',
        kind: 'alert',
      },
      type: 'ADD_TOAST',
    });
    return;
  }
  if (focusParticipants.length === 1) {
    yield put({
      type: ACTIONS.SAVE_FOCUS_ACCOUNT_ID,
      payload: focusParticipants[0].id,
    });
    yield call(changeStep, STEPS.SET_SMART_TRANSFER);
  } else {
    yield call(changeStep, STEPS.SELECT_FOCUS_ACCOUNT);
  }
}

function* finishedSelectFocusAccount(onFinishStep: any) {
  const { payload } = onFinishStep;
  yield put({
    type: ACTIONS.SAVE_FOCUS_ACCOUNT_ID,
    payload,
  });
  yield call(changeStep, STEPS.SET_SMART_TRANSFER);
}

function* finishedSetSmartTransfer(action: any) {
  yield put({
    payload: 'ADD',
    type: ACTIONS.SET_SMART_TRANSFER_FULFILLMENT_CONDITION_MODE,
  });
  const nextStep = action.payload
    ? STEPS.SET_FULFILLMENT_CONDITION
    : STEPS.CONFIRM_SMART_TRANSFER;
  yield call(changeStep, nextStep);
}

function* finishedSetFulfillmentCondition(action: any) {
  const { payload } = action;
  yield put({
    type: payload.contraEditType,
  });
  yield call(changeStep, STEPS.SET_SMART_TRANSFER);
}

function* finishedConfirmSmartTransfer() {
  const {
    input,
    navigate,
  }: {
    input: SetSmartTransferRuleInput;
    navigate: NavigateFunction;
  } = yield select((state: AppState) => ({
    input: state.newFlows.CREATE_SMART_TRANSFER.input,
    navigate: state.routing.navigate,
  }));

  try {
    yield put(showLoadingSpinner());
    const result: SetSmartTransferRuleMutationResult = yield call(
      apolloMutationSaga,
      {
        mutation: SetSmartTransferRuleDocument,
        variables: {
          input,
        },
      },
    );
    if (
      Boolean(result.data?.setSmartTransferRule?.didSucceed) &&
      result.data?.setSmartTransferRule?.outcome?.rule?.id
    ) {
      yield put({
        type: 'SET_SMART_TRANSFER_SUCCESS',
        payload: result.data.setSmartTransferRule.outcome.rule.id,
      });
    }
    yield call(changeStep, STEPS.SMART_TRANSFER_RECEIPT);
  } catch (e: any) {
    const message = e.message;

    if (
      e.graphQLErrors.some((err: any) => err.extensions.code === 'NOT_ELIGIBLE')
    ) {
      yield call(navigate, { to: '/d/transfers' });
    } else {
      yield put({
        payload: {
          content: message,
          kind: 'alert',
        },
        type: 'ADD_TOAST',
      });
    }
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function* finishedSmartTransferReceipt(onFinish: (...args: Array<any>) => any) {
  yield call(onFinish);
}
