import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { TotalNetWorthEnum } from '~/graphql/types';

import { getLoggers } from '../../common';

export function* logTotalNetWorthAmount(
  totalNetWorth: TotalNetWorthEnum,
): SagaIterator {
  const { analytics } = yield call(getLoggers);

  let totalNetWorthValueRounded: number;
  switch (totalNetWorth) {
    case 'UP_TO_INFINITY':
      totalNetWorthValueRounded = 5_000_000;
      break;
    case 'UP_TO_5000000':
      totalNetWorthValueRounded = 1_000_000;
      break;
    case 'UP_TO_1000000':
      totalNetWorthValueRounded = 500_000;
      break;
    case 'UP_TO_500000':
      totalNetWorthValueRounded = 200_000;
      break;
    case 'UP_TO_200000':
      totalNetWorthValueRounded = 100_000;
      break;
    case 'UP_TO_100000':
      totalNetWorthValueRounded = 50_000;
      break;
    case 'UP_TO_50000':
    default:
      totalNetWorthValueRounded = 0;
      break;
  }

  // We need to divide the value of `total_net_worth_value_rounded` by 1000
  // in order for this to work in the Google Ad Platform. This change is
  // required because Google can't accept values that high for their "revenue"
  // which is how we are using this event for optimization.
  totalNetWorthValueRounded = totalNetWorthValueRounded / 1000;

  analytics.recordAppAnalyticsEvent({
    name: 'm1_initial_total_net_worth_received',
    valueParameter: null,
    customParameters: [
      {
        name: 'value',
        value: totalNetWorthValueRounded,
      },
      {
        name: 'currency',
        value: 'USD',
      },
    ],
  });
}
