export const UNMANAGED_HOLDINGS_ACTIONS = Object.freeze({
  MOVE_UNMANAGED_HOLDINGS: 'MOVE_UNMANAGED_HOLDINGS' as const,
});

export type MoveUnmanagedHoldingsAction = {
  payload: {
    parentPortfolioSliceId: string;
    unmanagedHoldingIds: Array<string>;
  };
  type: typeof UNMANAGED_HOLDINGS_ACTIONS.MOVE_UNMANAGED_HOLDINGS;
};

export const moveUnmanagedHoldings = (
  payload: MoveUnmanagedHoldingsAction['payload'],
) => ({
  payload,
  type: UNMANAGED_HOLDINGS_ACTIONS.MOVE_UNMANAGED_HOLDINGS,
});

export type UnmanagedHoldingsAction = MoveUnmanagedHoldingsAction;
