import { Box, HXL, HXXS, styled } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useContactUsPageQuery } from '~/graphql/hooks';
import { ContactUsSectionCardFragment } from '~/graphql/types';
import { Container } from '~/toolbox/container';
import { Divider } from '~/toolbox/divider';
import { Spinner } from '~/toolbox/spinner';

import { CardsSection } from './components/CardsSection';
import { ContactUsVideoTutorialsSection } from './components/VideoTutorialsSection';
import { ViewAllSection } from './components/ViewAllSection';

const StyledBoxHeader = styled(Box)`
  background: ${(props) => props.theme.colors.backgroundFeatureFlat};
  min-height: 260px;
  width: 100%;
`;

export const ContactUsPage = () => {
  const { error, data, loading } = useContactUsPageQuery();

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (error || !data) {
    return <GenericSystemError />;
  }

  const { contactUs } = data.viewer;

  return (
    <Box backgroundColor="backgroundNeutralTertiary" width="100%" height="100%">
      <StyledBoxHeader>
        <Container>
          {contactUs?.title && (
            <HXL
              color="foregroundNeutralOnDark"
              content={contactUs.title}
              fontWeight={300}
              pt={16}
              pb={8}
            />
          )}
          {contactUs?.subtitle && (
            <HXXS
              color="foregroundNeutralOnDark"
              content={contactUs.subtitle}
              py={8}
            />
          )}
        </Container>
      </StyledBoxHeader>
      <Container>
        <CardsSection
          cards={
            (contactUs?.cards?.filter(Boolean) ??
              []) as Array<ContactUsSectionCardFragment>
          }
        />
        {contactUs?.videoTutorialsSection && (
          <ContactUsVideoTutorialsSection
            videoTutorialsSection={contactUs?.videoTutorialsSection}
          />
        )}
        {contactUs?.videoTutorialsSection?.viewAll && (
          <>
            <Divider spacing="relaxed" />
            <ViewAllSection
              viewAll={contactUs?.videoTutorialsSection?.viewAll}
            />
          </>
        )}
      </Container>
    </Box>
  );
};
