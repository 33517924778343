import * as React from 'react';

import {
  PortfolioSlicePerformancePeriodEnum,
  PortfolioToSecurityFragment,
} from '~/graphql/types';

import {
  PortfolioDetailsContext,
  PortfolioDetailsContextType,
} from '../PortfolioDetailsContext';

import { PortfolioToPieContainer } from './PortfolioToPieContainer';
import { PortfolioToSecurityContainer } from './PortfolioToSecurityContainer';

function hasDescription(
  slice: PortfolioDetailsContextType['portfolioSlice'],
): slice is PortfolioDetailsContextType['portfolioSlice'] & {
  to: { description: string };
} {
  return (
    slice?.to?.__typename === 'UserPie' || slice?.to?.__typename === 'SystemPie'
  );
}

export const PortfolioDetailsContainer = () => {
  const {
    portfolioSlice,
    sorter,
    chartPeriod,
    memoizedGenerateColorsForPie,
    sort,
    onChangeSort,
  } = React.useContext(PortfolioDetailsContext);

  const props = {
    colors: memoizedGenerateColorsForPie,
    portfolioSlice,
    period: PortfolioSlicePerformancePeriodEnum.Max,
  };

  if (!portfolioSlice) {
    return null;
  }

  const isPie =
    portfolioSlice.to.__typename === 'UserPie' ||
    portfolioSlice.to.__typename === 'SystemPie' ||
    portfolioSlice.to.__typename === 'CryptoSystemPie';
  return !isPie ? (
    <PortfolioToSecurityContainer
      portfolioSlice={portfolioSlice as PortfolioToSecurityFragment}
    />
  ) : (
    <PortfolioToPieContainer
      {...props}
      onChangeSort={onChangeSort}
      chartPeriod={chartPeriod}
      sort={sorter}
      sortDirection={sort[0].sortDirection}
      sortKey={sort[0].sortKey}
      portfolioSliceId={portfolioSlice.id}
      portfolioSliceDescription={
        hasDescription(portfolioSlice) ? portfolioSlice.to?.description : null
      }
      deallocatedChildren={portfolioSlice.deallocatedChildren}
    />
  );
};
