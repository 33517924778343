import React from 'react';

import { Grid } from '~/toolbox/grid';

import { StyledFilterContainer } from './CategoryDetails.styled';

type FilterContainerProps = {
  children: React.ReactNode;
};

export const FilterContainer = ({ children }: FilterContainerProps) => (
  <StyledFilterContainer>
    <Grid>
      <Grid.Row>
        <Grid.Col xs={12}>{children}</Grid.Col>
      </Grid.Row>
    </Grid>
  </StyledFilterContainer>
);
