import { PL } from '@m1/liquid-react';
import * as React from 'react';

import { ExternalLink } from '~/components/ExternalLink';
import { Container } from '~/toolbox/container';

export const InvestmentsUnavailable = () => {
  return (
    <Container centered padded="wide" text>
      <PL>
        Sorry, holdings are currently unavailable. Please give it a few and try
        again. If the problem persists, please contact{' '}
        <ExternalLink destination="SUPPORT_CENTER" label="support" />.
      </PL>
    </Container>
  );
};
