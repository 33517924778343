import { Box, Flex, PL, PM, PXL, Card } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import React from 'react';

import { NavigableButton } from '~/components/NavigableButton';

import { ResendEmailVerificationButton } from '~/components/ResendEmailVerificationButton';
import { useTradingPausedQuery } from '~/graphql/hooks';
import { useSupportLink } from '~/hooks/useSupportLink';
import { LinkableButton } from '~/lens-toolbox/LinkableButton';
import { useSelector } from '~/redux/hooks';

export const AccountTradingPausedCard = () => {
  const supportLink = useSupportLink();

  const accountId = useSelector((state) => state.global.activeAccountId);

  const { data, loading } = useTradingPausedQuery({
    skip: !accountId,
    variables: {
      accountId: accountId as string,
    },
  });

  if (loading) {
    // return null;
  }

  if (data?.node?.__typename !== 'Account') {
    return null;
  }

  const { tradingPause } = data.node;

  if (!tradingPause) {
    return null;
  }

  const isPausedForEmailVerification =
    !data.viewer.user?.isPrimaryEmailVerified;

  const readTradingPausedTitle = () => {
    if (isPausedForEmailVerification) {
      return 'Verify your email to start trading';
    }

    return tradingPause?.title ?? 'Trading is currently paused';
  };

  const readTradingPausedSubtitle = () => {
    if (isPausedForEmailVerification) {
      return 'Please check your email and verify the address by clicking the link we sent. Once verified, you will be able to start trading.';
    }

    return (
      tradingPause?.description ??
      'Trading is temporarily paused due to service on your account. Please contact support if you believe there is an issue.'
    );
  };

  const renderPauseReasonExplanation = () => {
    if (isPausedForEmailVerification) {
      return <ResendEmailVerificationButton />;
    }

    if (tradingPause?.appLink) {
      return (
        <LinkableButton
          kind="secondary"
          linkable={tradingPause.appLink}
          size="medium"
        />
      );
    }

    return (
      <NavigableButton
        kind="secondary"
        label="Contact Support"
        size="medium"
        {...supportLink}
      />
    );
  };

  const iconName = isPausedForEmailVerification
    ? 'mailElevated32'
    : 'tradeAlertError32';

  return (
    <Card p={24} mt={24}>
      <Flex display="flex" flexDirection="row">
        <Icon name={iconName} />
        <Box ml={16}>
          <PXL
            display="flex"
            alignItems="center"
            height={24}
            mt={4}
            content={readTradingPausedTitle()}
            fontWeight={500}
          />
          <PM
            color="foregroundNeutralSecondary"
            content={readTradingPausedSubtitle()}
            mt={8}
          />
          <PL
            content={renderPauseReasonExplanation()}
            mt={20}
            fontWeight={600}
          />
        </Box>
      </Flex>
    </Card>
  );
};
