import partial from 'lodash-es/partial';
import { connect as UNSAFE_connectRedux } from 'react-redux';

import type { AppState } from '~/redux';
import { showModal, hideModal } from '~/redux/actions';
import { MODALS } from '~/static-constants/modals';

export function connectModal(modal: ValueOf<typeof MODALS>) {
  // @ts-expect-error - TS2345 - Argument of type 'ValueOf<{ ARCHIVE_PIE: "ARCHIVE_PIE"; CASH_CONTROL: "CASH_CONTROL"; INACTIVITY_WARNING: "INACTIVITY_WARNING"; LOGIN_OR_REGISTER: "LOGIN_OR_REGISTER"; LIQUIDATION: "LIQUIDATION"; ORDER_ACTION_CANCEL: "ORDER_ACTION_CANCEL"; ... 12 more ...; VERIFY_NEW_USER_EMAIL: "VERIFY_NEW_USER_EMAIL"; }>' is not assignable to parameter of type '"VERIFY_NEW_USER_EMAIL"'.
  const showThisModal = partial(showModal, modal);
  // @ts-expect-error - TS2769 - No overload matches this call.
  const hideThisModal = () => hideModal(modal);

  return UNSAFE_connectRedux(
    (state: AppState) => state.modals[modal],
    {
      show: showThisModal,
      hide: hideThisModal,
    },
    (s, d, o) => ({
      modal: {
        ...s,
        ...d,
      },
      ...o,
    }),
  );
}
