import React from 'react';
import { usePlaidLink, PlaidLinkPropTypes } from 'react-plaid-link';

type SelectPlaidInnerLinkProps = Omit<
  PlaidLinkPropTypes,
  'children' | 'token'
> & {
  linkToken: string;
};

export const SelectPlaidInnerLink = (props: SelectPlaidInnerLinkProps) => {
  const { linkToken: token, onSuccess, onExit } = props;

  const config = {
    token,
    onSuccess,
    onExit,
  };

  const { open, ready } = usePlaidLink(config);

  React.useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  return null;
};
