import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { Spinner } from '~/toolbox/spinner';

export type LoadingStateProps = {
  delay?: number;
};

export const LoadingState = ({ delay = 2200 }: LoadingStateProps) => {
  const initialDelay = React.useRef<number>(delay);
  const [isStillLoading, setIsStillLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const timer = window.setTimeout(
      () => setIsStillLoading(true),
      initialDelay.current,
    );
    return () => window.clearTimeout(timer);
  }, [initialDelay]);

  if (isStillLoading) {
    return (
      <Box mt={64}>
        <Spinner />
      </Box>
    );
  }

  return null;
};
