import { Box, Flex, HXS, PL } from '@m1/liquid-react';
import * as React from 'react';

import { CloseButton } from '~/components/Cover/components/CloseButton/CloseButton';
import { useWizardContext } from '~/flows/wizard/useWizardContext';
import { RichTextFragment } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import { useOnMount } from '~/hooks/useOnMount';
import { AppButton } from '~/lens-toolbox/AppButton';
import { AppImage } from '~/lens-toolbox/AppImage';
import { RichText } from '~/lens-toolbox/RichText/RichText';
import { DynamicAnimation } from '~/lottie';
import {
  InitialFundingContext,
  InitialFundingContextType,
} from '~/pages/onboarding/initial-funding/InitialFundingProvider';

type InitialFundingIntroductionStepProps = {
  onPrimaryCtaClick?: () => void;
};

export const InitialFundingIntroductionStep = ({
  onPrimaryCtaClick,
}: InitialFundingIntroductionStepProps) => {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const { next } = useWizardContext();
  const context = React.useContext(
    InitialFundingContext,
  ) as InitialFundingContextType;

  const { initialFundingScreens } =
    context?.viewer?.transfers?.initialFunding ?? {};

  const {
    title,
    content,
    pageViewAnalyticsEvent,
    primaryCtaButton,
    secondaryCtaButton,
    skipCta,
  } = initialFundingScreens?.initialFundingOverviewScreen ?? {};

  useOnMount(() => {
    if (pageViewAnalyticsEvent) {
      analytics.recordAppAnalyticsEvent(pageViewAnalyticsEvent);
    }
  });

  const onExit = () => {
    navigate({ to: '/d/home' });
    if (!skipCta?.analyticsEvent) {
      return;
    }
    analytics.recordAppAnalyticsEvent(skipCta?.analyticsEvent);
  };

  return (
    <>
      <CloseButton
        color="foregroundNeutralSecondary"
        onClick={() => {
          onExit();
        }}
        top={96}
        right={40}
      />
      <Flex
        mt={56}
        maxWidth="488px"
        alignItems="left"
        flexDirection="column"
        margin="0 auto"
      >
        <DynamicAnimation fileName="long-term-wealth" />
        <Box padding={10} mt={24}>
          {title && <HXS mb={32}>{title}</HXS>}
          {content?.map((item) => (
            <Flex key={item.title} mb={16}>
              {item?.icon && <AppImage appImage={item.icon} />}
              <Box ml={16}>
                <PL fontWeight={item?.description ? 600 : 400}>{item.title}</PL>
                {item.description && (
                  <RichText
                    richText={item.description as RichTextFragment}
                    textProps={{
                      font: 'PL',
                      color: 'foregroundNeutralMain',
                      paddingRight: 4,
                    }}
                    linkProps={{
                      font: 'PL',
                      fontWeight: 600,
                    }}
                    containerProps={{
                      display: 'inline-block',
                    }}
                  />
                )}
              </Box>
            </Flex>
          ))}
        </Box>

        <Box pl={10} width="100%">
          {primaryCtaButton && (
            <AppButton
              mt={16}
              kind="primary"
              appButton={primaryCtaButton}
              onClick={() => {
                if (onPrimaryCtaClick) {
                  onPrimaryCtaClick();
                } else {
                  next();
                }
              }}
            />
          )}

          {secondaryCtaButton && (
            <AppButton
              ml={8}
              kind="text"
              appButton={secondaryCtaButton}
              onClick={() => {
                navigate({
                  to: '/d/invest/portfolio-organizer',
                  options: {
                    state: {
                      event: {
                        initConfig: {
                          confirmationDialog: {
                            showApplicableLocations: false,
                          },
                          mode: 'NEW_ROOT_PIE',
                          returnTo: '/d/invest',
                          sliceableIds: [],
                        },
                        type: 'INITIALIZE_ORGANIZER',
                      },
                    },
                  },
                });
              }}
            />
          )}
        </Box>
      </Flex>
    </>
  );
};
