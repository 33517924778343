import {
  Button,
  PL,
  Flex,
  Box,
  styled,
  PXL,
  PM,
  Modal,
  ModalContent,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import truncate from 'lodash-es/truncate';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { RelativeDate } from '~/components/relative-date';
import { SecurityNewsArticleFragment } from '~/graphql/types';

import { Link } from '~/toolbox/link/Link';

type NewsArticleProps = {
  article: SecurityNewsArticleFragment;
  size: 'small' | 'large';
  index: number;
  onPlayVideo?: (url: string) => void;
};

type NewsListProps = {
  articles: SecurityNewsArticleFragment[];
  canFetchMore: boolean;
  emptyLabel?: string;
  hasBorder?: boolean;
  hasNextPage: boolean;
  loadMoreLabel?: string;
  onLoadMoreClick: (...args: Array<any>) => any;
  size: 'small' | 'large';
};

type StyledImageContainerProps = {
  storyImage: string;
  height?: number;
  width?: number;
};

const StyledNewsItem = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderMain};
  justify-content: space-between;
  padding: 1.6rem;
  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundNeutralTertiary};
  }
`;

const StyledImageContainer = styled(Flex)<StyledImageContainerProps>`
  position: relative;
  align-items: center;
  ${({ storyImage }) => `background-image: url('${storyImage}');`}
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  flex: 0 0 auto;
  margin-left: 20px;
  justify-content: center;
  overflow: hidden;
`;

type StyledNewsListProps = {
  hasBorder: boolean;
};

const topStoryStyles = (width = '50%') => `
display: inline-flex;
flex: 0 0 50%;
width: ${width};

// last 2 links
&:nth-last-child(-n + 2) > div:last-child {
  border-bottom: none;
}

& > div {
  flex-direction: column;
  min-height: 435px;
  max-height: 435px;
  width: 100%;

  // the headline and summary container
  & > :first-child {
    flex: 0 1 100%;
    display: flex;
    overflow: hidden;
    order: 2;

    // summary text
    & > p {
      overflow: hidden;
    }
  }

  // the headline image container
  & > :last-child {
    height: 250px;
    margin-left: 0;
    margin-bottom: 16px;
    order: 1;
    width: 100%;
  }
}
`;

const StyledNewsList = styled(Box)<StyledNewsListProps>`
  border-radius: ${({ hasBorder }) => hasBorder && '8px'};
  background-color: ${({ theme }) => theme.colors.backgroundNeutralSecondary};
  ${({ hasBorder, theme }) =>
    hasBorder && `border: 1px solid ${theme.colors.borderMain};`}

  flex-direction: row;
  flex-wrap: wrap;

  a:last-child > div:last-child {
    border-bottom: none;
  }

  @media (max-width: 768px) {
    // For mobile, only one story every four gets the larger styling
    a:nth-child(5n + 1) {
      ${topStoryStyles('100%')}
    }
  }

  // For screens over tablet max-size
  @media (min-width: 769px) {
    // first and second, and every four headlines, apply side-by-side styles
    a:nth-child(6n + 1),
    a:nth-child(6n + 2) {
      ${topStoryStyles()}
    }
  }
`;

const VideoPlayer = ({
  url,
  height,
  width,
  isPlaying,
}: {
  url?: string;
  height: number;
  width: number;
  isPlaying: boolean;
}) => {
  const videoId = (url?.match(/v=([-_.A-Za-z0-9]+)/) ?? [])[1];

  if (!isPlaying) {
    return <Icon name="playVideo48" css={{ position: 'absolute' }} />;
  }

  return (
    <iframe
      width={width}
      height={height}
      src={`https://www.youtube.com/embed/${videoId}?showInfo=0&autoplay=1`}
      allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;web-share"
      allowFullScreen
    />
  );
};

export const NewsList = ({
  articles,
  canFetchMore = true,
  emptyLabel = 'No Recent News',
  hasNextPage = false,
  hasBorder = true,
  loadMoreLabel = 'Load More',
  onLoadMoreClick,
  size = 'small',
}: NewsListProps) => {
  const [activeVideoUrl, setActiveVideoUrl] = React.useState<
    string | undefined
  >(undefined);

  if (!articles || articles.length === 0) {
    return (
      <Flex width="100%" justifyContent="center">
        {emptyLabel}
      </Flex>
    );
  }
  const loadMore = hasNextPage && canFetchMore;
  // The largest width for the video is contrained by the Modal component's
  // max-width (and the modal has l/r padding of 50.4)
  const modalContentWidth = Math.min(570, document.body.offsetWidth - 100.8);

  return (
    <>
      <Modal
        open={Boolean(activeVideoUrl)}
        onClose={() => setActiveVideoUrl(undefined)}
      >
        <ModalContent width="wide">
          <Box pt={10} />
          {/* we size the video according to 16:9 */}
          <VideoPlayer
            width={modalContentWidth}
            height={modalContentWidth * 0.56}
            url={activeVideoUrl}
            isPlaying
          />
        </ModalContent>
      </Modal>
      <StyledNewsList hasBorder={hasBorder}>
        <Box pb={8}>
          {articles.map((article, index) => (
            <NewsArticle
              key={article.url}
              size={size}
              article={article}
              index={index}
              onPlayVideo={setActiveVideoUrl}
            />
          ))}
        </Box>
        {loadMore && (
          <Box textAlign="center" mb={16}>
            <Button
              label={loadMoreLabel}
              kind="secondary"
              onClick={() => onLoadMoreClick()}
            />
          </Box>
        )}
      </StyledNewsList>
    </>
  );
};

const NewsArticle = ({
  article,
  size,
  index,
  onPlayVideo,
}: NewsArticleProps) => {
  const [isPlayingVideo, setIsPlayingVideo] = React.useState(false);

  const {
    date,
    headline,
    source,
    sourceLogoUrl,
    summary,
    url,
    imageUrl,
    newsType,
  } = article;

  const imageSize = size === 'large' ? 106 : 72;

  const displayHeadline =
    size === 'large'
      ? headline
      : truncate(headline, {
          length: 135,
        });
  const displaySummary =
    size === 'large'
      ? summary
      : // @ts-expect-error - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'string | undefined'.
        truncate(summary, {
          length: 105,
        });

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  // first, second, and every four headlines apply side-by-side "large layout" styles
  const isLargeLayout = !isMobile && (index % 6 === 0 || index % 6 === 1);
  const isVideo = newsType === 'VIDEO';
  const [videoWidth, videoHeight] = isLargeLayout ? [407, 250] : [106, 60];

  return (
    <Link to={url} target="_blank" key={url}>
      <StyledNewsItem
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          if (!isVideo) {
            return;
          }

          e.preventDefault();
          e.stopPropagation();
          if (isLargeLayout) {
            setIsPlayingVideo(true);
          } else {
            onPlayVideo?.(url);
          }
        }}
      >
        <Flex flexDirection="column" justifyContent="space-between">
          <PXL
            as="h4"
            fontWeight={600}
            pb={4}
            content={displayHeadline}
            color="foregroundNeutralMain"
          />
          <PL
            color="foregroundNeutralSecondary"
            content={displaySummary || ''}
          />
          {source && (
            <Flex pt={16} alignItems="center">
              {sourceLogoUrl && (
                <img
                  src={sourceLogoUrl}
                  width={20}
                  height={20}
                  style={{
                    marginRight: 4,
                  }}
                />
              )}
              <PM color="foregroundNeutralSecondary">
                {source} • <RelativeDate date={date} />
              </PM>
            </Flex>
          )}
        </Flex>
        {imageUrl && (
          <StyledImageContainer
            storyImage={imageUrl}
            height={imageSize}
            width={imageSize}
          >
            {isVideo ? (
              <VideoPlayer
                width={videoWidth}
                height={videoHeight}
                url={url}
                isPlaying={isPlayingVideo}
              />
            ) : null}
          </StyledImageContainer>
        )}
      </StyledNewsItem>
    </Link>
  );
};
