import { Button, Flex, HM, PL } from '@m1/liquid-react';
import React from 'react';
import { useForm } from 'react-hook-form';

import { CopyIconButton } from '~/components/CopyIconButton';
import { useWizardContext } from '~/flows/wizard';
import {
  TwoFactorAuthDocument,
  useEnableTwoFactorAuthMutation,
  useInitializeTwoFactorAuthMutation,
} from '~/graphql/hooks';
import { AuthenticateErrorEnum } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { QRCodeDisplay } from '~/pages/dashboard/settings/security/components/QRCodeDisplay';
import { useToast } from '~/toasts';
import { ControlledCodeInput } from '~/toolbox/CodeInput/ControlledCodeInput';
import { Spinner } from '~/toolbox/spinner';

type AuthAppSetupProps = {
  exitFlow: () => void;
};

type CodeInputFormValues = {
  code: string;
};

export const AuthAppSetup = ({ exitFlow }: AuthAppSetupProps) => {
  const { addToast } = useToast();

  const { back } = useWizardContext();

  const [
    initializeTwoFactorAuth,
    {
      loading: initializeTwoFactorAuthLoading,
      data: initializeTwoFactorAuthData,
    },
  ] = useInitializeTwoFactorAuthMutation({
    variables: {
      input: {},
    },
    onCompleted: (data) => {
      if (!data.initializeTwoFactorAuth?.outcome) {
        throw new Error('Invalid mutation response when generating 2FA.');
      }
    },
    onError: () => {
      addToast({
        content: 'Something went wrong. Please try again or contact support.',
        kind: 'alert',
        duration: 'short',
      });
    },
  });

  React.useEffect(() => {
    initializeTwoFactorAuth();
  }, [initializeTwoFactorAuth]);

  const analytics = useAnalytics();

  React.useEffect(() => {
    analytics.pageView('m1_2FA_setup_page_view');
  }, [analytics]);

  const [enableTwoFactorAuth, { loading: enableTwoFactorAuthLoading }] =
    useEnableTwoFactorAuthMutation({
      onCompleted: () => {
        addToast({
          content: 'Success! Two-factor authentication is on.',
          kind: 'success',
          duration: 'short',
        });

        exitFlow();
      },
      onError: (error) => {
        const authenticateError =
          error.graphQLErrors.reduce<AuthenticateErrorEnum | null>(
            (acc, graphqlError) => {
              if (graphqlError.extensions?.code === 'INCORRECT_CODE') {
                return AuthenticateErrorEnum.IncorrectCode;
              }

              return acc;
            },
            null,
          );

        if (authenticateError === AuthenticateErrorEnum.IncorrectCode) {
          addToast({
            content: 'Incorrect code. Please try again.',
            kind: 'alert',
            duration: 'short',
          });
        } else {
          addToast({
            content:
              'Something went wrong. Please try again or contact support.',
            kind: 'alert',
            duration: 'short',
          });
        }

        setError('code', {
          message: 'Incorrect code. Please try again.',
        });
      },
      refetchQueries: [{ query: TwoFactorAuthDocument }],
    });

  const { control, handleSubmit, watch, setError, formState } =
    useForm<CodeInputFormValues>({
      defaultValues: {
        code: '',
      },
    });

  const codeValue = watch('code');

  if (initializeTwoFactorAuthLoading || !initializeTwoFactorAuthData) {
    return <Spinner fullScreen />;
  }

  if (
    !initializeTwoFactorAuthData.initializeTwoFactorAuth?.outcome?.uri ||
    !initializeTwoFactorAuthData.initializeTwoFactorAuth?.outcome?.secret
  ) {
    throw new Error('Unexpected response from initializeTwoFactorAuth.');
  }

  const { uri, secret } =
    initializeTwoFactorAuthData.initializeTwoFactorAuth.outcome;

  return (
    <Flex flexDirection="column" maxWidth={550}>
      <HM
        mt={64}
        mb={16}
        content="Add M1 to your chosen authentication app."
        fontWeight={300}
        onClick={back}
      />
      <Flex flexDirection="column" gap={32} justifyContent="space-between">
        <PL pt={16}>
          <b>Step 1: </b>Download and open any authenticator app on your mobile
          device. Ex: Google Authenticator
        </PL>
        <Flex flexDirection="column">
          <PL>
            <b>Step 2: </b>Open the authenticator app and scan this QR code.
          </PL>
          <Flex py={32}>
            <QRCodeDisplay uri={uri} />
          </Flex>
          <PL>Or, copy this code into your authenticator app.</PL>
          <Flex pt={16} alignItems="center">
            <PL color="primary" content={secret} />
            <CopyIconButton
              color="primary"
              size="small"
              name="copy20"
              value={secret}
              onCopyClickCallback={() => {
                addToast({
                  content: 'Code has been copied to clipboard!',
                  kind: 'success',
                  duration: 'short',
                });
              }}
            />
          </Flex>
        </Flex>
        <PL pb={16}>
          <b>Step 3: </b>Your authentication app will create a one-time, 6-digit
          code for M1. Enter it below.
        </PL>
      </Flex>
      <form
        name="2fa-input-form"
        onSubmit={handleSubmit(async (data) => {
          await enableTwoFactorAuth({
            variables: {
              input: {
                code: data.code,
              },
            },
          });
        })}
      >
        <ControlledCodeInput
          control={control}
          name="code"
          value={codeValue}
          // TODO: figure out how to remove onChange from prop set
          onChange={() => {}}
          rules={{
            required: true,
            minLength: 6,
            maxLength: 6,
          }}
        />
        <Flex pt={64} gap={32}>
          <Button
            type="submit"
            kind="primary"
            label="Next"
            disabled={
              !formState.isValid ||
              initializeTwoFactorAuthLoading ||
              enableTwoFactorAuthLoading
            }
          />
          <Button kind="link" label="Cancel" onClick={exitFlow} />
        </Flex>
      </form>
    </Flex>
  );
};
