import { Color, styled, useTheme } from '@m1/liquid-react';
import * as React from 'react';

export type DottedArrowProps = {
  color?: Color;
  direction?: 'LEFT' | 'RIGHT';
  height?: number;
  width?: number;
};

const StyledDottedArrow = styled.svg<{
  $direction: string;
}>`
  ${(props) => {
    if (props.$direction === 'LEFT') {
      return `transform: rotate(180deg);`;
    }
  }};
`;

export const DottedArrow = ({
  color = 'foregroundNeutralMain',
  width = 30,
  height = 12,
  direction = 'RIGHT',
}: DottedArrowProps) => {
  const theme = useTheme();
  const c = theme.colors[color];
  return (
    <StyledDottedArrow
      $direction={direction}
      height={height}
      width={width}
      viewBox="0 0 30 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 6C2 5.44772 1.55228 5 1 5C0.447715 5 -6.78525e-08 5.44772 -4.37114e-08 6C-1.95703e-08 6.55228 0.447715 7 1 7C1.55228 7 2 6.55228 2 6Z"
          fill={c}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 6C8 5.44772 7.55228 5 7 5C6.44772 5 6 5.44772 6 6C6 6.55228 6.44772 7 7 7C7.55228 7 8 6.55228 8 6Z"
          fill={c}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 6C14 5.44772 13.5523 5 13 5C12.4477 5 12 5.44772 12 6C12 6.55228 12.4477 7 13 7C13.5523 7 14 6.55228 14 6Z"
          fill={c}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 6C20 5.44772 19.5523 5 19 5C18.4477 5 18 5.44772 18 6C18 6.55228 18.4477 7 19 7C19.5523 7 20 6.55228 20 6Z"
          fill={c}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9672 0.166011L29.4004 5.59921C29.6217 5.82056 29.6217 6.17944 29.4004 6.40079L23.9672 11.834C23.8609 11.9403 23.7167 12 23.5664 12C23.2534 12 22.9996 11.7462 22.9996 11.4332L22.9996 0.566798C22.9996 0.416475 23.0593 0.272307 23.1656 0.166011C23.387 -0.0553376 23.7458 -0.0553377 23.9672 0.166011ZM28.198 6L24.1332 1.93517L24.1332 10.0648L28.198 6Z"
          fill={c}
        />
      </g>
    </StyledDottedArrow>
  );
};
