import * as React from 'react';

export type MarketProps = {
  value: string | null | undefined;
};

export const Market = (props: MarketProps) => {
  const { value } = props;

  if (value === undefined || value === null) {
    return null;
  }

  const format = (): string => {
    const _value = value.toLowerCase();

    if (_value.indexOf('new york') > -1 || _value.indexOf('nyse') > -1) {
      return 'NYSE';
    }

    if (_value.indexOf('nasdaq') > -1) {
      return 'NASDAQ';
    }

    return value;
  };

  return <span>{format()}</span>;
};
