import * as React from 'react';
import { Route } from 'react-router-dom';

import { UnmanagedHoldingsSelectParentPiePage } from './UnmanagedHoldingsSelectParentPiePage';

export const UnmanagedHoldingsSelectParentPieRoute = () => {
  return (
    <Route
      path="select-parent-pie"
      element={<UnmanagedHoldingsSelectParentPiePage />}
    />
  );
};
