import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { MarginCallInformationFlow } from '~/flows';
import { selectedSellPositionResolutionOption } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

export const ResolveMarginCallPage = () => {
  const basePath = 'd/c/resolve-call/resolve-margin-call';
  const dispatch = useDispatch();
  const previousRouteName = useSelector(
    (state) => state.newFlows.RESOLVE_CALL.previousRouteName,
  );

  const { borrowAccountId, lastMarginCallInformationFlowStep } = useSelector(
    (state) => ({
      borrowAccountId: state.newFlows.RESOLVE_CALL.borrowAccountId,
      lastMarginCallInformationFlowStep:
        state.newFlows.RESOLVE_CALL.lastMarginCallInformationFlowStep,
    }),
  );

  return (
    <PageErrorBoundary>
      <MarginCallInformationFlow
        basePath={basePath}
        borrowAccountId={borrowAccountId}
        initialStep={lastMarginCallInformationFlowStep}
        previousRouteName={previousRouteName}
        onSellLinkClick={(opts: Record<string, any>) =>
          // @ts-expect-error - TS2345 - Argument of type 'Record<string, any>' is not assignable to parameter of type '{ isFromHoldingsPositionDetail?: boolean | undefined; portfolioSliceId: string; previousRouteName?: string | undefined; setAsSellOrder?: boolean | undefined; }'.
          dispatch(selectedSellPositionResolutionOption(opts))
        }
        preserveStateOnUnmount
      />
    </PageErrorBoundary>
  );
};
