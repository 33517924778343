import { Text } from '@m1/liquid-react';
import * as React from 'react';

import { usePrevious } from '~/hooks/usePrevious';

export type PriceDigitProps = {
  bit: -1 | 0 | 1;
  value: number;
};

export const PriceDigit = ({ bit, value }: PriceDigitProps) => {
  const [didChange, setDidChange] = React.useState<boolean>(false);
  const lastValue = usePrevious(value);

  React.useEffect(() => {
    if (lastValue !== value) {
      setDidChange(true);
    }
  }, [lastValue, value]);

  React.useEffect(() => {
    if (didChange) {
      const timer = window.setTimeout(() => setDidChange(false), 1300);
      return () => window.clearTimeout(timer);
    }
  }, [didChange]);

  const color = React.useMemo(() => {
    if (didChange && bit === 1) {
      return 'success';
    }
    if (didChange && bit === 0) {
      return 'critical';
    }
  }, [bit, didChange]);

  return <Text color={color} content={value} />;
};
