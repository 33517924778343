import * as React from 'react';
import { Route } from 'react-router-dom';

import { SmartTransferDetailsPage } from './SmartTransferDetailsPage';

export const SmartTransferDetailsRoute = () => {
  return (
    <Route
      element={<SmartTransferDetailsPage />}
      handle={{
        fallbackRoute: {
          to: '/d/transfers/rules',
        },
      }}
      path="smart-transfer-details/:smartTransferRuleId"
    />
  );
};
