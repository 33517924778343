import { Button, HXS, PL, Flex, Image, styled, Modal } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

export type OnboardingHelperModalProps = {
  handleCloseOnboardingHelperModal: () => void;
  helperBodyContent: React.ReactNode;
  helperHeaderContent: string;
  helperImageSource: string;
  helperModalOpen: boolean;
};

// Styled Components
const StyledImageContainer = styled(Flex)`
  background: ${(props) => props.theme.colors.secondaryTint};
  border-radius: 10px 10px 0 0;
  height: 224px;
`;

const StyledPl = styled(PL)`
  margin: 32px;

  & > svg {
    vertical-align: bottom;
  }
`;

export const OnboardingHelperModal = ({
  helperBodyContent,
  handleCloseOnboardingHelperModal,
  helperHeaderContent,
  helperImageSource,
  helperModalOpen,
}: OnboardingHelperModalProps) => (
  <Modal open={helperModalOpen} onClose={handleCloseOnboardingHelperModal}>
    <Flex flexDirection="column">
      <StyledImageContainer
        alignItems="center"
        justifyContent="center"
        width={489}
      >
        <Image alt="" src={helperImageSource} />
      </StyledImageContainer>
      <Flex alignItems="center" flexDirection="column" mt={32} width={489}>
        <Flex alignItems="center" justifyContent="center">
          <Icon name="help24" />
          <HXS content={helperHeaderContent} ml={12} />
        </Flex>
        <StyledPl content={helperBodyContent} />
        <Button
          label="Close"
          mb={24}
          onClick={handleCloseOnboardingHelperModal}
          size="large"
        />
      </Flex>
    </Flex>
  </Modal>
);
