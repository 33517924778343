import {
  Button,
  HXXS,
  PL,
  LL,
  PM,
  PS,
  Flex,
  Box,
  styled,
  Card,
  Tooltip,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { Pie, getAllPiesFromPieTree, readPieTreeByPath } from '~/pie-trees';
import { useDispatch, useSelector } from '~/redux/hooks';

type PieDestinationMenuProps = {
  onCreatePieClick: () => void;
};

type PieDestinationClickCallbackParams = {
  id: string;
  name: string | null | undefined;
};

const EmptyState = () => {
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      pl={16}
    >
      <Box pt={16}>
        <Illustration
          // @ts-expect-error - TS2322 - Type '{ alt: string; height: number; width: number; name: "investActivityEmptyState"; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'.
          alt=""
          height={128}
          width={128}
          name="investActivityEmptyState"
        />
      </Box>
      <HXXS content="You have no Pies" pt={16} />
      <PM
        color="foregroundNeutralSecondary"
        content="To add a Pie, click Create Pie. Your slices will then appear in your new pie."
        py={16}
        textAlign="center"
      />
    </Flex>
  );
};

const StyledHeadingContainerFlex = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderMain};
`;

const StyledPieItemBox = styled(Box)`
  cursor: pointer;
  transition: background-color 200ms;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderMain};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundPrimarySubtle};
  }
`;

type SelectorResponseShape = {
  allPies: Array<Pie>;
  selectedSliceIds: Array<string>;
};

export const PieDestinationMenu = ({
  onCreatePieClick,
}: PieDestinationMenuProps) => {
  const { allPies, selectedSliceIds } = useSelector<SelectorResponseShape>(
    (state) => {
      const { slices } = readPieTreeByPath(
        state.portfolioOrganizer.pieTree,
        state.portfolioOrganizer.path,
      );
      return {
        allPies: getAllPiesFromPieTree(state.portfolioOrganizer.pieTree),
        selectedSliceIds: slices
          ? slices.reduce((result, { to }) => {
              if (to.__checked) {
                // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'never'.
                result.push(to.__id);
              }
              return result;
            }, [])
          : [],
      };
    },
  );

  const filteredPies = allPies.filter((pie) => {
    // TODO: for edit, should allow "old pies" but we need to check if they're system pies or not
    if (pie.type !== 'new_pie') {
      return false;
    }

    // if the current pie is selected, filter it out
    if (pie.__checked) {
      return false;
    }

    // if the pie contains slices that are currently selected by the active pie, filter it out
    if (pie.slices && pie.slices.length) {
      for (const slice of pie.slices) {
        if (selectedSliceIds.includes(slice.to.__id)) {
          return false;
        }
      }
    }

    // TODO: last check is to check for pie cycles
    return true;
  });

  const dispatch = useDispatch();

  const handlePieDestinationClick = React.useCallback(
    ({ id, name }: PieDestinationClickCallbackParams) => {
      dispatch({
        type: 'MOVED_PORTFOLIO_ORGANIZER_SLICES_ONTO_OTHER_SLICE',
        payload: {
          destinationOntoSliceableId: id,
          destinationOntoSliceableName: name,
          movedSliceIds: selectedSliceIds,
        },
      });
    },
    [dispatch, selectedSliceIds],
  );

  return (
    <Card
      backgroundColor="backgroundNeutralSecondary"
      isElevated
      textAlign="left"
    >
      <StyledHeadingContainerFlex alignItems="center" py={12} px={16}>
        <PL
          color="foregroundNeutralSecondary"
          content="Available Pie(s)"
          pr={4}
          fontWeight={600}
        />
        <Tooltip
          placement="bottom-start"
          icon="tooltip16"
          iconColor="foregroundPrimary"
          body={
            <PS
              content="This only displays Pies that selected slice(s) can be moved into. Pies that are not available cannot be edited or the slice(s) you've selected have a duplicate that already exists in that Pie."
              m="8px 12px"
            />
          }
        />
      </StyledHeadingContainerFlex>
      {filteredPies.length === 0 ? (
        <EmptyState />
      ) : (
        filteredPies.map((pie: Pie, i) => (
          <StyledPieItemBox
            py={12}
            key={i}
            onClick={() => {
              handlePieDestinationClick({
                id: pie.__id,
                name: pie.name,
              });
            }}
          >
            <Flex alignItems="center" px={16}>
              <Icon name="createPie32" />
              <LL content={pie.name} pl={8} />
            </Flex>
          </StyledPieItemBox>
        ))
      )}
      <Button
        kind="link"
        label="Create Pie"
        leftIcon="createPie24"
        style={{
          fontSize: '16px',
          margin: '12px 16px',
        }}
        onClick={onCreatePieClick}
      />
    </Card>
  );
};
