import { Modal } from '@m1/liquid-react';
import * as React from 'react';

import { useModal } from '~/hooks/useModal';
import { confirmAcceptLegalTermsOnLogin } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

import { ReacceptLegalTermsModalContent } from './ReacceptLegalTermsModalContent';

export const ReacceptLegalTermsModal = () => {
  const {
    hideModal,
    modalState: { isOpened },
  } = useModal('REACCEPT_LEGAL_TERMS');

  const dispatch = useDispatch();

  const handleConfirm = (signature: string | undefined) => {
    dispatch(confirmAcceptLegalTermsOnLogin(signature));
  };
  return (
    <Modal open={isOpened} onClose={hideModal}>
      <ReacceptLegalTermsModalContent
        onCancel={hideModal}
        onConfirm={handleConfirm}
      />
    </Modal>
  );
};
