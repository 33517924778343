import { Theme } from '@m1/liquid-react';
import classNames from 'classnames';
import * as d3 from 'd3';
import clamp from 'lodash-es/clamp';
import clone from 'lodash-es/clone';
import head from 'lodash-es/head';
import last from 'lodash-es/last';
import uniqueId from 'lodash-es/uniqueId';
import moment from 'moment-timezone';
import * as React from 'react';
import ReactDOM from 'react-dom';

import {
  SetSecuritySnapshotAction,
  setSecuritySnapshot,
} from '~/redux/actions';
import { closestIndex } from '~/utils';

import style from './style.module.scss';
import type { DataPoint, Notation } from './types';

type TooltipKind = 'dark' | 'light';
type Input = {
  chart: React.ElementRef<any> | null | undefined;
  chartWrapper: React.ElementRef<any> | null | undefined;
  data: Array<DataPoint>;
  dispatcher: (action: SetSecuritySnapshotAction) => void;
  height: number;
  intradayDateMinutes: Array<string> | null | undefined;
  notations: Array<Notation>;
  startLineValue: number | null | undefined;
  theme: Theme;
  width: number;
  yAxisFormat?: string;
};
type D3Selection = Record<string, any>;

const parseDate = (
  format: string = 'MMM D',
): ((...args: Array<any>) => any) => {
  return (date: string): string => {
    return moment.utc(date).format(format);
  };
};

const makeTooltipClasses = (
  kind: TooltipKind | null | undefined,
  flags: Record<string, any> = {},
): string => {
  return classNames(style.tooltip, kind && style[kind], {
    [style.forNearLeft]: flags.isOverLeft,
    [style.forNearRight]: flags.isOverRight,
  });
};

const makeWidgetTooltipClasses = (flags: Record<string, any> = {}): string => {
  return classNames(style.tooltip, style.dark, {
    [style.widgetForNearLeft]: flags.isOverLeft,
    [style.widgetForNearRight]: flags.isOverRight,
  });
};

export class SecondaryHistoricalChartD3 {
  static defaultProps = {
    yAxisFormat: 'f',
  };
  chartMargin: {
    bottom: number;
    left: number;
    right: number;
    top: number;
  };
  d3: Record<string, any> = {};
  // @ts-expect-error - TS2564 - Property 'notationsContainerSelection' has no initializer and is not definitely assigned in the constructor.
  notationsContainerSelection: D3Selection;
  input: Input;
  tooltipId: string = uniqueId('secondary-historical-chart-tooltip');
  // @ts-expect-error - TS2564 - Property 'tooltipSelection' has no initializer and is not definitely assigned in the constructor.
  tooltipSelection: D3Selection;
  // @ts-expect-error - TS2564 - Property 'tooltipTargetSelection' has no initializer and is not definitely assigned in the constructor.
  tooltipTargetSelection: D3Selection;
  TOOLTIP_ARROW_PADDING: number;
  // @ts-expect-error - TS2564 - Property 'xAxisSelection' has no initializer and is not definitely assigned in the constructor.
  xAxisSelection: D3Selection;
  // @ts-expect-error - TS2564 - Property 'yAxisSelection' has no initializer and is not definitely assigned in the constructor.
  yAxisSelection: D3Selection;
  // @ts-expect-error - TS2564 - Property 'valueLineSelection' has no initializer and is not definitely assigned in the constructor.
  valueLineSelection: D3Selection;
  // @ts-expect-error - TS2564 - Property 'startLineSelection' has no initializer and is not definitely assigned in the constructor.
  startLineSelection: D3Selection;
  WIDGET_SIZE: number;

  constructor(input: Input) {
    this.input = input;
    this.chartMargin = {
      top: 0,
      right: 0,
      bottom: 30,
      left: 0,
    };
    this.WIDGET_SIZE = 20;
    this.TOOLTIP_ARROW_PADDING = 14;
    this.setupChart();
  }

  setupChart() {
    const yScale = d3.scaleLinear().rangeRound([this.input.height, 0]);
    const xRange = this.calculateXRange(this.input.data);
    const xDomain = this.calculateXDomain(this.input.data);
    // @ts-expect-error - TS2345 - Argument of type 'Date[]' is not assignable to parameter of type 'Iterable<string>'.
    const xScale = d3.scaleOrdinal().domain(xDomain).range(xRange);
    let xAxis;
    if (this.input.intradayDateMinutes) {
      const intradayDayXAxis = d3
        .scaleOrdinal()
        // @ts-expect-error - TS2345 - Argument of type 'Date[]' is not assignable to parameter of type 'Iterable<string>'.
        .domain(xDomain)
        .range([0, this.input.width]);
      xAxis = d3
        // @ts-expect-error - TS2345 - Argument of type 'string[] & ScaleOrdinal<string, unknown, never>' is not assignable to parameter of type 'AxisScale<string>'.
        .axisBottom(intradayDayXAxis)
        .tickFormat(parseDate())
        .tickSizeInner(-this.input.height)
        .tickSizeOuter(0)
        .tickPadding(8);
    } else {
      xAxis = d3
        // @ts-expect-error - TS2345 - Argument of type 'string[] & ScaleOrdinal<string, unknown, never>' is not assignable to parameter of type 'AxisScale<string>'.
        .axisBottom(xScale)
        .tickFormat(parseDate())
        .tickSizeInner(-this.input.height)
        .tickSizeOuter(0)
        .tickPadding(8);
    }

    const yAxis = d3
      .axisLeft(yScale)
      .ticks(clamp(this.input.height / 80, 4, 6), this.input.yAxisFormat)
      // @ts-expect-error - TS2339 - Property 'toFixed' does not exist on type 'NumberValue'.
      .tickFormat((d) => '$' + d.toFixed(0))
      .tickSizeInner(-this.input.width)
      .tickSizeOuter(0)
      .tickPadding(0);
    const valueLine = d3
      .line()
      // @ts-expect-error - TS2339 - Property 'date' does not exist on type '[number, number]'.
      .x((d) => xScale(d.date))
      // @ts-expect-error - TS2551 - Property 'value' does not exist on type '[number, number]'. Did you mean 'values'?
      .y((d) => yScale(d.value));

    d3.selectAll(`g.chart-element`).remove();
    const svg = d3
      // @ts-expect-error - TS2769 - No overload matches this call.
      .select(this.input.chart)
      .attr(
        'width',
        this.input.width + this.chartMargin.left + this.chartMargin.right,
      )
      .attr(
        'height',
        this.input.height + this.chartMargin.top + this.chartMargin.bottom,
      )
      .append('g')
      .attr('class', 'chart-element')
      .attr(
        'transform',
        `translate(${this.chartMargin.left},${this.chartMargin.top})`,
      );

    svg.selectAll(`g.${style.xAxis}`).remove();
    this.xAxisSelection = svg
      .append('g')
      .attr('class', style.xAxis)
      .attr('transform', `translate(0,${this.input.height})`);

    svg.selectAll(`g.${style.yAxis}`).remove();
    this.yAxisSelection = svg.append('g').attr('class', style.yAxis);

    svg.selectAll(`path.${style.line}`).remove();
    this.valueLineSelection = svg
      .append('path')
      .attr('class', style.line)
      .attr('shape-rendering', 'geometricPrecision');

    this.startLineSelection = svg
      .append('path')
      .attr('class', style.startLine)
      .attr('shape-rendering', 'crispEdges');

    d3.selectAll(`div.${style.tooltip}`).remove();
    this.tooltipSelection = d3
      // @ts-expect-error - TS2769 - No overload matches this call.
      .select(this.input.chartWrapper)
      .append('div')
      // @ts-expect-error - TS2554 - Expected 1-2 arguments, but got 0.
      .attr('class', makeTooltipClasses())
      .attr('id', this.tooltipId);

    svg.selectAll(`rect.tooltip-target-selection`).remove();
    this.tooltipTargetSelection = svg
      .append('rect')
      .attr('class', 'tooltip-target-selection')
      .attr('width', this.input.width)
      .attr('height', this.input.height - this.WIDGET_SIZE)
      .attr('fill-opacity', 0);

    this.notationsContainerSelection = svg.append('g').attr('class', 'widgets');
    const hoverTargetHeight = this.input.height - this.chartMargin.bottom;
    const hoverTargetWidth =
      this.input.width - this.chartMargin.left - this.chartMargin.right;
    const hoverTarget = d3
      .select('#hoverTarget')
      .style('height', `${hoverTargetHeight}px`)
      .style('width', `${hoverTargetWidth}px`);
    const scrubber = d3
      .select('#scrubber')
      .style('height', `${this.input.height}px`);
    const { gradientLinearFeature } = this.input.theme.colors;

    hoverTarget
      .on('mouseover', () => {
        const dataPoints = this.getDataPoints();
        if (dataPoints.length >= 2) {
          scrubber
            // @ts-expect-error - TS2339 - Property 'transition' does not exist on type 'Selection<BaseType, unknown, HTMLElement, any>'.
            .transition()
            .duration(300)
            .style('opacity', 1)
            .style('background', gradientLinearFeature);
        }
      })
      .on('mousemove touchstart touchend touchmove', () => {
        const dataPoints = this.getDataPoints();
        if (dataPoints.length < 2) {
          return;
        }
        // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'ContainerElement'.
        const [mouseLocation] = d3.mouse(this.input.chart);
        const xRange = xScale.range();
        // @ts-expect-error - TS2345 - Argument of type 'unknown[]' is not assignable to parameter of type 'number[]'.
        const index = closestIndex(xRange, mouseLocation);
        if (index === undefined || index === null) {
          return;
        }
        const xValue = xRange[index];

        const valueSnapshot = this.input.data.find((d) => {
          if (this.input.data[index]) {
            return d.date === this.input.data[index].date;
          }
          return null;
        });

        if (!valueSnapshot) {
          return;
        }

        if (valueSnapshot.percentChange === null) {
          valueSnapshot.percentChange = '--';
        }

        const { date, percentChange, value, valueChange, shareVolume } =
          valueSnapshot;
        this.input.dispatcher(
          setSecuritySnapshot({
            date,
            percentChange,
            value,
            valueChange,
            shareVolume,
          }),
        );

        scrubber.style('left', `${xValue}px`);
      })
      .on('mouseout', () => {
        scrubber
          // @ts-expect-error - TS2339 - Property 'transition' does not exist on type 'Selection<BaseType, unknown, HTMLElement, any>'.
          .transition()
          .duration(300)
          .style('opacity', 0)
          .style('background-color', 'transparent');

        const latestTrade = last(this.input.data);
        this.input.dispatcher(
          setSecuritySnapshot({
            // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
            percentChange: latestTrade.percentChange,
            // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
            valueChange: latestTrade.valueChange,
          }),
        );
      });
    this.d3 = {
      valueLine,
      xScale,
      yScale,
      xAxis,
      yAxis,
    };
    this.updateChart(true);
  }

  updateChart(initialRender: boolean = false): void {
    const hasData =
      Array.isArray(this.input.data) && this.input.data.length >= 1;

    this.updateScales(hasData);
    this.updateAxes(hasData);
    this.updateStartLine();
    this.updateValueLine(initialRender);
    this.updateNotations();
  }

  updateScales(chartHasData: boolean): SecondaryHistoricalChartD3 {
    const { data, startLineValue } = this.input;
    const { xScale, yScale } = this.d3;
    let xDomain;
    const xRange = this.calculateXRange(data);
    if (chartHasData) {
      xDomain = data.map((d) => d.date);
    } else {
      const xInterpolator = d3.interpolateDate(
        moment().subtract(1, 'month').toDate(),
        moment().toDate(),
      );
      // @ts-expect-error - TS2345 - Argument of type '(a: any, b: any) => Date' is not assignable to parameter of type '(t: number) => Date'. | TS7006 - Parameter 'a' implicitly has an 'any' type. | TS7006 - Parameter 'b' implicitly has an 'any' type.
      xDomain = d3.quantize((a, b) => {
        // @ts-expect-error - TS2554 - Expected 1 arguments, but got 2.
        return clone(xInterpolator(a, b));
      }, 1);
    }

    let yDomain;
    if (chartHasData) {
      let [min, max] = d3.extent(data, (d) => d.value);
      if (startLineValue !== undefined && startLineValue !== null) {
        // @ts-expect-error - TS2345 - Argument of type 'number | undefined' is not assignable to parameter of type 'number'.
        min = Math.min(min, startLineValue);
        // @ts-expect-error - TS2345 - Argument of type 'number | undefined' is not assignable to parameter of type 'number'.
        max = Math.max(max, startLineValue);
      }
      // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
      const scalePadding = (max - min) * 0.15;
      // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
      yDomain = [min - scalePadding, max + scalePadding];
    } else {
      yDomain = [0, 100];
    }

    this.d3.xDomain = xDomain;
    this.d3.yDomain = yDomain;
    this.d3.xRange = xRange;

    xScale.domain(xDomain);
    yScale.domain(yDomain);
    xScale.range(xRange);
    return this;
  }

  updateAxes(chartHasData: boolean): SecondaryHistoricalChartD3 {
    const { data } = this.input;
    const { xAxis, xDomain, yAxis } = this.d3;

    let ticks;
    let tickValues;

    if (chartHasData) {
      // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
      const firstDate = moment(head(data).date);
      // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
      const lastDate = moment(last(data).date);
      if (!firstDate.diff(lastDate, 'days') && this.input.intradayDateMinutes) {
        tickValues = [
          head(this.input.intradayDateMinutes),
          last(this.input.intradayDateMinutes),
        ];
        ticks = tickValues.length;
      } else if (!firstDate.diff(lastDate, 'weeks')) {
        // one week
        const days = new Map();
        for (const datum of data) {
          const d = moment(datum.date);
          if (!days.has(d.day())) {
            days.set(d.day(), d);
          }
        }
        tickValues = Array.from(days.values()).map((day) => day.toISOString());
        ticks = tickValues.length;
      } else {
        // historical 1M...5Y
        const lengthInterpolator = d3.interpolate(
          0,
          Math.max(data.length - 1, 0),
        );

        ticks = Math.max(Math.min(data.length, 6), 2);
        tickValues = d3
          .quantize(lengthInterpolator, ticks)
          .map((d) => Math.round(d))
          .map((d) => data[d].date);
      }
    } else {
      ticks = 6;
      tickValues = xDomain;
    }

    const tickFormat = (date: string): string => {
      const lastTick = moment(last(xDomain));
      const firstTick = moment(head(xDomain));
      if (firstTick.isSame(lastTick, 'day')) {
        return moment(date).format('LT');
      } else if (lastTick.year() !== firstTick.year()) {
        return parseDate(`MMM D 'YY`)(date);
      }

      return parseDate()(date);
    };

    xAxis.ticks(ticks).tickValues(tickValues).tickFormat(tickFormat);

    this.xAxisSelection.call(xAxis);
    this.yAxisSelection.style('opacity', Number(chartHasData)).call(yAxis);

    return this;
  }

  updateValueLine(isInitialRender: boolean): SecondaryHistoricalChartD3 {
    const { height, data } = this.input;
    const { valueLine, xScale } = this.d3;
    const LINE_ANIMATION_DURATION = 600;
    const t = d3
      .transition()
      .ease(d3.easeCircleOut)
      .duration(LINE_ANIMATION_DURATION);
    this.valueLineSelection.datum(data);
    if (isInitialRender) {
      this.valueLineSelection
        .attr(
          'd',
          d3
            .line()
            // @ts-expect-error - TS2339 - Property 'date' does not exist on type '[number, number]'.
            .x((d) => xScale(d.date))
            .y(height),
        )
        .transition(t);
    }
    this.valueLineSelection.attr('d', valueLine);

    return this;
  }

  updateStartLine(): SecondaryHistoricalChartD3 {
    const { width, data, startLineValue } = this.input;
    const { yScale } = this.d3;
    let value;
    if (startLineValue) {
      value =
        startLineValue !== undefined && startLineValue !== null
          ? yScale(startLineValue)
          : // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
            yScale(head(data).value);
      this.startLineSelection.attr('d', `M 0, ${value} L ${width}, ${value}`);
    } else {
      this.startLineSelection.attr('d', null);
    }
    return this;
  }

  updateNotations(): SecondaryHistoricalChartD3 {
    const { height, notations } = this.input;
    const { xScale } = this.d3;
    const widgetSize = this.WIDGET_SIZE;
    function calculateXValue(date: string): number {
      return Math.min(xScale(date), window.innerWidth - widgetSize);
    }

    const notationGroups = this.notationsContainerSelection
      .selectAll('g')
      // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
      .data(notations, (d) => d.date);

    const enteringNotations = notationGroups.enter().append('g');
    enteringNotations
      .attr('class', `${style.point} ${style.filled}`)
      .insert('rect')
      .attr('width', this.WIDGET_SIZE)
      .attr('height', this.WIDGET_SIZE)
      .attr(
        'transform',
        `translate(${-this.WIDGET_SIZE / 2},${-this.WIDGET_SIZE / 2})`,
      )
      // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
      .on('mouseover', (d) => {
        const xValue = calculateXValue(d.date);
        const viewportFlags = {
          isOverLeft: d3.event.pageX < 55,
          isOverRight: window.innerWidth - xValue < 55,
        };

        this.tooltipSelection
          .attr('class', makeWidgetTooltipClasses(viewportFlags))
          .style('left', `${xValue}px`)
          .style(
            'top',
            `${
              this.input.height - this.TOOLTIP_ARROW_PADDING - this.WIDGET_SIZE
            }px`,
          );

        const tooltipElem = document.querySelector(`#${this.tooltipId}`);
        if (tooltipElem) {
          ReactDOM.render(d.value, tooltipElem, this.showTooltip);
        }
      })
      .on('mouseout', this.hideTooltip);

    enteringNotations
      .append('text')
      .attr('x', '0')
      .attr('y', '3')
      // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
      .html((d) => d.label);

    // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
    enteringNotations.merge(notationGroups).attr('transform', (d) => {
      return `translate(${calculateXValue(d.date)}, ${
        height - this.WIDGET_SIZE / 2
      })`;
    });

    notationGroups.exit().remove();

    return this;
  }

  showTooltip = (): void => {
    this.tooltipSelection.style('opacity', 1);
  };

  hideTooltip = (): void => {
    this.tooltipSelection.style('opacity', 0);
  };

  calculateXDomain(dataPoints: ReadonlyArray<DataPoint>): Array<Date> {
    return dataPoints.length > 1
      ? dataPoints.map(({ date }) => moment(date).toDate())
      : [moment().subtract(1, 'days').toDate(), moment().toDate()];
  }
  calculateXRange(dataPoints: ReadonlyArray<DataPoint>): Array<number> {
    if (dataPoints.length > 1) {
      const interpolator = d3.interpolate(0, this.input.width);
      const intraDay = this.input.intradayDateMinutes;
      const currentlTime = moment().startOf('minute').toISOString();
      const intraDayCurrentTime = intraDay?.indexOf(currentlTime);

      // range for intraday chart - using the current time set to determine the chart range
      if (intraDay && intraDayCurrentTime && intraDayCurrentTime >= 0) {
        // getting the minute separated dates to use
        const currentTradingPeriod = intraDay.slice(0, intraDayCurrentTime);
        // how we're making progress throughout the day. The intraday range should only be a percentage of full width.
        const currentTradingWidth =
          this.input.width * (currentTradingPeriod.length / intraDay.length);
        // Using width-adjusted values to interpolate trade values.
        const intraDayInterpolator = d3.interpolate(0, currentTradingWidth);
        return d3
          .range(dataPoints.length)
          .map((d) => intraDayInterpolator(d / (dataPoints.length - 1)));
      }

      return d3
        .range(dataPoints.length)
        .map((d) => interpolator(d / (dataPoints.length - 1)));
    }
    return [0, this.input.width];
  }

  getDataPoints(): ReadonlyArray<DataPoint> {
    return this.input.data || [];
  }

  removeChart(): void {
    this.valueLineSelection.remove();
    this.xAxisSelection.remove();
    this.yAxisSelection.remove();
    this.startLineSelection.remove();
    this.notationsContainerSelection.remove();
  }
}
