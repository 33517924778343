import { Button } from '@m1/liquid-react';
import React from 'react';

import { KeyDataModal } from './KeyDataModal';

export const KeyDataLearnMore = () => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <Button
        kind="link"
        label="Learn about Key Data"
        onClick={() => setShowModal(true)}
      />
      <KeyDataModal isOpen={showModal} closeModal={() => setShowModal(false)} />
    </>
  );
};
