import { combineReducers } from 'redux';

import { authReducer } from './authReducer';
import { beneficiariesReducer } from './beneficiariesReducer';
import { borrowMarginReducer } from './borrowMarginReducer';
import { configReducer } from './configReducer';
import { createFormsReducer } from './formReducer';
import { globalReducer } from './globalReducer';
import { interfaceReducer } from './interfaceReducer';
import { createModalsReducer } from './modals';
import { modeReducer } from './modeReducer';
import { navigationReducer } from './navigationReducer';
import { createFlowsReducer } from './newFlows';
import { notificationsReducer } from './notificationsReducer';
import { pieEditorReducer } from './pieEditorReducer';
import { portfolioOrganizerReducer } from './portfolioOrganizer';
import { createReactHookFormReducer } from './reactHookFormReducer';
import { remoteReducer } from './remoteReducer';
import { routingReducer } from './routing';
import { securityDetailsReducer } from './securityDetailsReducer';
import { selectedSlicesReducer } from './selectedSlicesReducer';
import { storageReducer } from './storageReducer';
import { toastsReducer } from './toastsReducer';
import { userDataReducer } from './userDataReducer';
import { verifyEmailBannerReducer } from './verifyEmailBannerReducer';
import { watchlistReducer } from './watchlistReducer';

// Finally, create the root reducer by wrapping the combined application reducer
// with a special storage reducer that reloads persisted state back into the
// store.
export const appReducer = storageReducer(
  combineReducers({
    auth: authReducer,
    config: configReducer,
    beneficiaries: beneficiariesReducer,
    borrowMargin: borrowMarginReducer,
    form: createFormsReducer(),
    reactHookForm: createReactHookFormReducer(),
    global: globalReducer,
    interface: interfaceReducer,
    modals: createModalsReducer(),
    mode: modeReducer,
    navigation: navigationReducer,
    newFlows: createFlowsReducer(),
    notifications: notificationsReducer,
    pieEditor: pieEditorReducer,
    portfolioOrganizer: portfolioOrganizerReducer,
    remote: remoteReducer,
    routing: routingReducer,
    securityDetails: securityDetailsReducer,
    selectedSlices: selectedSlicesReducer,
    toasts: toastsReducer,
    userData: userDataReducer,
    verifyEmailBanner: verifyEmailBannerReducer,
    watchlist: watchlistReducer,
  }),
);
