import { HXS, Flex } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

export const NoUnmanagedHoldings = () => (
  <Flex alignItems="center" flexDirection="column" mt={32} width="100%">
    <Illustration name="balancedPie" height={165} width={165} />
    <HXS mt={32}>All holdings are in your portfolio</HXS>
  </Flex>
);
