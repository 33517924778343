import { PXL, HXL, Text, Flex, styled } from '@m1/liquid-react';
import * as React from 'react';

import { useLaunchDarkly } from '~/hooks/useLaunchDarkly';
import { EXTERNAL_LINKS, RESPONSIVE_BREAKPOINTS } from '~/static-constants';

import { Link } from '~/toolbox/link';

const StyledLoginFlex = styled(Flex)`
  justify-content: center;
  height: 100%;
  padding-left: 0px;
  padding-right: 0px;

  .dark & {
    color: white;
  }
  @media screen and ${RESPONSIVE_BREAKPOINTS.SMALL} {
    margin-left: unset;
  }
`;

const StyledPXL = styled(PXL)`
  white-space: pre-line;
`;

export const LoginMarketing = () => {
  const { didSucceed, flagResult } = useLaunchDarkly(
    'marketing-web-login-content-partial',
    '',
  );
  const marketingContent = React.useMemo(() => {
    // Ensure feature flag result exists and was successfully captured
    if (didSucceed && flagResult) {
      // Try to parse flag result as JSON and handle any potential errors
      return JSON.parse(flagResult);
    }
    return null;
  }, [didSucceed, flagResult]);
  return (
    <StyledLoginFlex
      px={32}
      py={64}
      maxWidth={540}
      color="secondary"
      flexDirection="column"
    >
      {marketingContent ? (
        <>
          <HXL content={marketingContent.title} />
          <StyledPXL fontWeight={400} mt={12}>
            {marketingContent.description}{' '}
          </StyledPXL>
          <StyledPXL fontWeight={400} mt={12}>
            {marketingContent.link_label}{' '}
            <Link
              fontSize="18px"
              font="PM"
              to={marketingContent.disclosure_href}
              target="_blank"
              content={marketingContent.disclosure_text}
            />{' '}
          </StyledPXL>
          <Text fontWeight={400} mt={12} fontSize="12px">
            {marketingContent.disclaimer}
          </Text>
        </>
      ) : (
        <>
          <HXL content="Refer a friend for an investing bonus" />
          <PXL fontWeight={600} mt={12}>
            Know someone who would love M1? Send them our way and you could both
            earn $100 to invest. <br />
            <Link
              fontSize="18px"
              font="PM"
              to={EXTERNAL_LINKS.REFERRAL_PROGRAM_TERMS}
              target="_blank"
            >
              Promotional terms and conditions apply.
            </Link>{' '}
          </PXL>
          <Text fontWeight={400} mt={12} fontSize="14px">
            Referrals must deposit $5,000 within 30 days of account opening to
            qualify.
          </Text>
        </>
      )}
    </StyledLoginFlex>
  );
};
