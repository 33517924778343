import { useDispatch } from 'react-redux';

import { clickedOnSliceable } from '~/redux/actions';

export const useSliceableClick = () => {
  const dispatch = useDispatch();

  const onClick = (id: string) => {
    dispatch(clickedOnSliceable(id));
  };

  return onClick;
};
