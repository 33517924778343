import * as React from 'react';

import { WizardContextValue } from './types';

export const WizardProviderContext =
  React.createContext<WizardContextValue<any> | null>(null);

export const WizardProvider = <K extends string>({
  children,
  value,
}: {
  children: React.ReactNode;
  value: WizardContextValue<K>;
}) => {
  return (
    <WizardProviderContext.Provider value={value}>
      {children}
    </WizardProviderContext.Provider>
  );
};
