import * as React from 'react';
import { PlaidLinkPropTypes } from 'react-plaid-link';

import { useGeneratePlaidLinkItemAddClientTokenMutation } from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useSentry } from '~/hooks/useSentry';
import { skippedPlaidConnection } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

import { SelectPlaidInnerLink } from './SelectPlaidInnerLink';

type SelectPlaidLinkProps = Pick<PlaidLinkPropTypes, 'onSuccess' | 'onExit'> & {
  // TODO(Wolf): Find out if we can remove this prop, it's passed in but is never used.
  onConnectBankFlowStep?: boolean;
  redirectUrl?: string;
};

export const SelectPlaidLink = (props: SelectPlaidLinkProps) => {
  const [generatePlaidLinkItemAddClientToken, { data }] =
    useGeneratePlaidLinkItemAddClientTokenMutation();
  const analytics = useAnalytics();
  const sentry = useSentry();

  // use selector to grab fundingSourceId from connectBank state.
  const { fundingSourceId, connectionType } = useSelector(
    (state) => state.newFlows.connectBank,
  );
  const dispatch = useDispatch();

  const plaidMicrosEnabled = connectionType !== 'spend';
  const generateToken = () => {
    generatePlaidLinkItemAddClientToken({
      variables: {
        input: {
          fundingSourceId,
          sameDayMicroEnabled: plaidMicrosEnabled,
          automatedMicroEnabled: plaidMicrosEnabled,
        },
      },
      onCompleted: (data) => {
        if (data.generatePlaidLinkItemAddClientToken?.didSucceed) {
          analytics.recordEvent('m1_plaid_link_event');
        } else {
          sentry.message(
            'PlaidLink: An error occurred while attempting to generate link token',
            {
              level: 'error',
              extra: {
                error: data.generatePlaidLinkItemAddClientToken?.outcome,
              },
            },
          );
          dispatch(skippedPlaidConnection());
        }
      },
    });
  };
  React.useEffect(() => {
    generateToken();
  }, []);

  const linkToken =
    data?.generatePlaidLinkItemAddClientToken?.outcome?.plaidLinkItemAddToken;

  if (!linkToken) {
    return null;
  }

  return <SelectPlaidInnerLink {...props} linkToken={linkToken} />;
};
