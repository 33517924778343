import * as React from 'react';
import { Route } from 'react-router-dom';

import { PromotionCampaignAcceptancePage } from './PromotionCampaignAcceptancePage';

export const PromotionCampaignAcceptance = () => {
  return (
    <Route
      path="accept-offer"
      element={<PromotionCampaignAcceptancePage />}
      handle={{
        fallbackRoute: {
          to: '/d/home',
        },
      }}
    />
  );
};
