import * as React from 'react';

export const Diamond = (
  <>
    <path d="M3.05982 4.61778H0.369421C0.222002 4.61778 0.0930099 4.54864 0.0377278 4.42763C-0.0175544 4.30662 -0.0175544 4.16833 0.0745825 4.06461L3.00454 0.45166C3.05982 0.382513 3.17038 0.330652 3.26252 0.313365L7.05856 0.00220264C7.20598 -0.0150842 7.3534 0.0713499 7.42711 0.192358C7.50082 0.330652 7.46396 0.486234 7.3534 0.589955L3.33623 4.51406C3.26252 4.58321 3.17038 4.61778 3.05982 4.61778ZM1.10652 3.92631H2.89397L6.13719 0.78011L3.50208 1.00484L1.10652 3.92631Z" />
    <path d="M11.0208 4.61851H3.06012C2.9127 4.61851 2.78371 4.53208 2.72843 4.41107C2.67315 4.29006 2.69157 4.13448 2.80214 4.03076L6.81931 0.106651C6.89302 0.0375033 7.00358 0.00292969 7.09572 0.00292969C7.18786 0.00292969 7.29842 0.0375033 7.3537 0.106651L11.2787 4.03076C11.3709 4.13448 11.4077 4.27278 11.3524 4.41107C11.2972 4.54936 11.1682 4.61851 11.0208 4.61851ZM3.92621 3.92704H10.1547L7.09572 0.849984L3.92621 3.92704Z" />
    <path d="M7.09556 11.9997C6.985 11.9997 6.89286 11.9651 6.81915 11.8787L0.0931533 4.49722C0.00101634 4.3935 -0.017411 4.25521 0.0378711 4.1342C0.0931533 4.01319 0.222145 3.92676 0.369564 3.92676H3.05996C3.20738 3.92676 3.31795 3.9959 3.39166 4.11691L7.42725 11.4984C7.51939 11.654 7.46411 11.8441 7.29826 11.9478C7.24298 11.9824 7.16927 11.9997 7.09556 11.9997ZM1.16194 4.61823L5.32653 9.18195L2.83883 4.61823H1.16194Z" />
    <path d="M13.6372 4.61808H10.9284C10.8178 4.61808 10.7257 4.5835 10.6519 4.51436L6.81905 0.590246C6.70849 0.486525 6.69006 0.330944 6.76377 0.209936C6.83748 0.0889281 6.9849 0.00249393 7.13232 0.0197808L10.7257 0.34823C10.8362 0.365517 10.9284 0.400091 10.9836 0.486525L13.9136 4.08219C14.0057 4.18591 14.0057 4.3242 13.9504 4.44521C13.8952 4.56622 13.7662 4.61808 13.6372 4.61808ZM11.0942 3.9266H12.8817L10.5045 1.00513L8.01683 0.780401L11.0942 3.9266Z" />
    <path d="M7.0955 11.9997C7.02179 11.9997 6.94808 11.9824 6.8928 11.9478C6.72695 11.8614 6.67167 11.6713 6.76381 11.4984L10.6151 4.11691C10.6704 3.9959 10.7994 3.92676 10.9468 3.92676H13.6372C13.7846 3.92676 13.9136 3.9959 13.9689 4.1342C14.0242 4.25521 14.0058 4.3935 13.9136 4.49722L7.37191 11.8787C7.2982 11.9478 7.20606 11.9997 7.0955 11.9997ZM11.168 4.61823L8.77239 9.21653L12.8448 4.61823H11.168Z" />
    <path d="M7.09548 12.0004C6.94806 12.0004 6.83749 11.9312 6.76378 11.8102L2.72819 4.42876C2.67291 4.32504 2.67291 4.18675 2.74661 4.08302C2.82032 3.9793 2.93089 3.91016 3.05988 3.91016H11.0021C11.1311 3.91016 11.2416 3.9793 11.3153 4.06574C11.3891 4.16946 11.3891 4.29047 11.3338 4.39419L7.42717 11.8102C7.35346 11.9312 7.22447 12.0004 7.09548 12.0004ZM3.66798 4.61892L7.09548 10.894L10.4308 4.6362H3.66798V4.61892Z" />
    <path d="M2.94125 0.653069L2.69867 4.25879L3.43429 4.30234L3.67687 0.696622L2.94125 0.653069Z" />
    <path d="M11.0627 0.6372L10.3281 0.694336L10.6463 4.295L11.3809 4.23786L11.0627 0.6372Z" />
  </>
);
