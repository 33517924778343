import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ShowModalActions } from '~/redux/actions/modalActions';

export const MODAL_ACTION_TYPES = {
  SHOW_MODAL: 'SHOW_MODAL' as const,
  HIDE_MODAL: 'HIDE_MODAL' as const,
};

type ActionTypeToPayloadMap = {
  [K in keyof ShowModalActions]: ShowModalActions[K]['payload'] extends void
    ? {}
    : ShowModalActions[K]['payload'];
};

export type ActionType = keyof ActionTypeToPayloadMap;

type ModalState<T extends ActionType> = {
  isOpened: boolean;
  payload: ActionTypeToPayloadMap[T];
};

type ActionWithPayload<T extends ActionType> = {
  payload: ActionTypeToPayloadMap[T];
};

type ActionCreator<T extends ActionType> = (
  payload: ActionTypeToPayloadMap[T],
) => ActionWithPayload<T>;

type UseModalProps<T extends ActionType> = {
  showModal: ActionCreator<T>;
  hideModal: () => void;
  modalState: ModalState<T>;
};

export const useModal = <T extends ActionType>(modal: T): UseModalProps<T> => {
  const dispatch = useDispatch();
  const modalState = useSelector(
    (state: { modals: Record<T, ModalState<T>> }) => state.modals[modal],
  );

  const show = React.useCallback(
    (payload: ActionTypeToPayloadMap[T]) =>
      dispatch({
        type: MODAL_ACTION_TYPES.SHOW_MODAL,
        meta: { modal },
        payload,
      }),
    [dispatch, modal],
  );

  const hide = React.useCallback(
    () =>
      dispatch({
        type: MODAL_ACTION_TYPES.HIDE_MODAL,
        meta: { modal },
      }),
    [dispatch, modal],
  );

  return { showModal: show, hideModal: hide, modalState };
};
