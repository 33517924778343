import { useReactiveVar } from '@apollo/client';
import { Button, Flex, styled, useTheme } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { SliceableLogo } from '~/components/pie';
import { PieOrganizerNavigationButton } from '~/components/PieOrganizerNavigationButton/PieOrganizerNavigationButton';
import { TruncateStringContent } from '~/components/TruncateStringContent';
import {
  clickedSliceCheckbox,
  selectedSlicesVar,
} from '~/graphql/local/reactiveVars/selectedSlices';
import { SecurityStatusEnum, SliceableCellFragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';

import { useNewPieEditor } from '~/hooks/useNewPieEditor';
import { clickedSliceableSelector } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { Grid } from '~/toolbox/grid';
import { TransitionGroup } from '~/toolbox/transition-group';

import style from './style.module.scss';

const StyledBarContainer = styled(Flex)`
  border-top: ${(props) => `1px solid ${props.theme.colors.borderMain}`};
  box-shadow: 0 0 16px rgba(black, 0.4); // TODO get this working.
`;

type SliceableSelectBarProps = {
  className?: string;
  context?: 'NEW_ROOT_PIE' | 'NEW_PIE';
  fromPath: '/d/invest/portfolio-organizer' | '/d/invest/portfolio-editor';
  isCrypto?: boolean;
};

export const SliceableSelectBar = ({
  context,
  fromPath,
  isCrypto,
}: SliceableSelectBarProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedSlices = useReactiveVar(selectedSlicesVar);
  const { showNewPieEditor } = useNewPieEditor();

  const theme = useTheme();

  if (selectedSlices?.length <= 0) {
    return null;
  }

  const handleRemoveSliceable = (sliceable: SliceableCellFragment) => {
    dispatch(clickedSliceableSelector(sliceable.id));
    clickedSliceCheckbox(sliceable);
  };

  const sliceableIds = selectedSlices.map((slice) => slice.id);

  return (
    <TransitionGroup>
      <StyledBarContainer
        backgroundColor="backgroundNeutralSecondary"
        bottom={0}
        height={65}
        left={0}
        position="fixed"
        width="100%"
        zIndex={1}
      >
        <Grid constrain>
          <Grid.Row
            xsMiddle
            style={{
              height: '100%',
            }}
          >
            <Grid.Col xs={10}>
              <Flex alignItems="center" overflowX="auto">
                {[...selectedSlices].reverse().map((sliceable) => (
                  <Flex
                    alignItems="center"
                    backgroundColor="backgroundNeutralTertiary"
                    borderRadius={8}
                    justifyContent="space-between"
                    key={sliceable.id}
                    position="relative"
                    mr={16}
                    p={8}
                  >
                    <div className={style.identifiers}>
                      <span className={style.logo}>
                        <SliceableLogo sliceable={sliceable} />
                      </span>
                      <Flex flex="0 1 auto" mb={2}>
                        <TruncateStringContent
                          color={theme.colors.foregroundNeutralMain}
                        >
                          {sliceable.name}
                        </TruncateStringContent>
                      </Flex>
                    </div>
                    <Icon
                      flex="0 1 auto"
                      ml={4}
                      cursor="pointer"
                      name="close16"
                      onClick={() => handleRemoveSliceable(sliceable)}
                    />
                  </Flex>
                ))}
              </Flex>
            </Grid.Col>
            <Grid.Col xs={2}>
              {fromPath === '/d/invest/portfolio-organizer' ? (
                <PieOrganizerNavigationButton
                  event={{
                    type: 'ADDING_SLICES',
                    legacyIds: null,
                    sliceableIds,
                    context: context,
                    isCrypto: isCrypto ?? false,
                  }}
                  size="medium"
                  kind="primary"
                  label="Add"
                />
              ) : (
                <Button
                  kind="primary"
                  label="Add"
                  onClick={() => {
                    if (
                      showNewPieEditor &&
                      fromPath.includes('/w/pie-editor')
                    ) {
                      dispatch({
                        type: 'PIE_EDITOR_ADD_SLICES_COMPLETE',
                        payload: {
                          slices: selectedSlices.map((slice) => {
                            const isPie = [
                              'UserPie',
                              'CryptoSystemPie',
                              'SystemPie',
                            ].includes(slice.__typename as any);
                            return {
                              id: slice.id,
                              isPie,
                              symbolOrName:
                                'symbol' in slice ? slice.symbol : slice.name,
                              securityInfo:
                                isPie || !('symbol' in slice)
                                  ? undefined
                                  : {
                                      symbol: slice.symbol,
                                      isActive: slice.isActive,
                                      status: slice.isActive
                                        ? SecurityStatusEnum.Active
                                        : SecurityStatusEnum.Inactive,
                                      isCrypto: Boolean(isCrypto),
                                    },
                            };
                          }),
                        },
                      });
                    }
                    navigate({
                      to: fromPath,
                      options: { state: { sliceableIds } },
                    });
                  }}
                />
              )}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </StyledBarContainer>
    </TransitionGroup>
  );
};
