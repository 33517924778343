import { beginInvestAccountSetupFlow } from '~/redux/actions';

import { makeFlowComponent } from '../../utils';

import { InvestOnboardingContainer } from './InvestOnboardingContainer';

export const InvestOnboardingFlow = makeFlowComponent({
  name: 'INVEST_ONBOARDING',
  begin: beginInvestAccountSetupFlow,
  Component: InvestOnboardingContainer,
});
