import { Flex, HM, PXL } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { PersonalLendingDepositDestination } from '~/forms';
import { useDepositInfoPageQuery } from '~/graphql/hooks';
import { setPersonalLoanDepositAccount } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { StyledApplicationContainer } from '../components/ApplicationContainer';

type DepositInfoPageProps = {
  onFinishStep: (arg0: {
    loanId: string;
    transferParticipantId: string;
  }) => void;
};

export const DepositInfoPage = ({ onFinishStep }: DepositInfoPageProps) => {
  const dispatch = useDispatch();
  const { transferParticipantId, loanId } = useSelector(
    (state) => state.newFlows.PERSONAL_LOANS_APPLICATION,
  );

  const { data, loading } = useDepositInfoPageQuery({
    variables: {
      loanId,
    },
  });

  if (loading) {
    return <Spinner fullScreen centered />;
  }

  const disbursementProps =
    data?.viewer.borrow?.personalLoans?.disbursementFlow?.loanDisbursement;

  const title = disbursementProps?.title;
  const subtitle = disbursementProps?.subtitle;
  const destinationList = disbursementProps?.destinationList;
  const continueCta = disbursementProps?.continueCta;

  if (
    !disbursementProps ||
    !title ||
    !subtitle ||
    !destinationList ||
    !continueCta
  ) {
    return <GenericSystemError />;
  }

  const initialValues = {
    transferParticipantId:
      Array.isArray(destinationList) && destinationList.length === 1
        ? destinationList?.[0]?.id
        : transferParticipantId,
  };

  const list = destinationList ?? [];

  const accountOptions = list.map((participant) => ({
    label: participant?.transferParticipantName,
    value: participant?.id,
  }));

  const submit = (depositInformation: any) => {
    const transferParticipant = list.find(
      (deposit) => deposit?.id === depositInformation.transferParticipantId,
    );

    if (!transferParticipant) {
      throw new Error(
        'Unable to find destination account after attempting to submit',
      );
    }

    dispatch(
      setPersonalLoanDepositAccount(
        transferParticipant.transferParticipantName,
      ),
    );

    onFinishStep({
      loanId,
      transferParticipantId: depositInformation.transferParticipantId,
    });
  };

  return (
    <StyledApplicationContainer>
      <Flex justifyContent="center">
        <Illustration
          name="loanWide"
          height={216}
          width="100%"
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      </Flex>
      <Flex justifyContent="center" marginTop={48}>
        <Flex flexDirection="column" maxWidth={580}>
          <HM content={title} mb="24px" />
          <PXL content={subtitle} mb="16px" />
          <PersonalLendingDepositDestination
            initialValues={initialValues}
            depositDestinations={accountOptions}
            continueCta={continueCta}
            onSubmit={submit}
          />
        </Flex>
      </Flex>
    </StyledApplicationContainer>
  );
};
