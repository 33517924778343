import { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  fork,
  put,
  select,
  setContext,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { Beneficiary, InvestAccountOpeningQuestion } from '~/graphql/types';
import { NavigateFunction } from '~/hooks/useNavigate';
import {
  setInvestSuitabilityOnboardingQuestions,
  ACTION_TYPES as ACTIONS,
  startFlow,
} from '~/redux/actions';
import { userHasIncompleteProfile } from '~/redux/sagas/common';
import { INVEST_ONBOARDING_FLOW_STEPS } from '~/static-constants';
import { delay } from '~/utils';

import {
  BeginInvestAccountSetupFlow,
  ChangeInvestAccountSetupStep,
} from '../../../../actions/newFlows/investOnboarding/investOnboardingActions.types';
import { isFunctionBlocked } from '../../../systemStatus';
import { changeStep } from '../../utils';

import {
  getInitialInvestQuestion,
  handleInvestSuitabilityNavigation,
} from './investOnboardingHelpers';
import { primaryQuestionMap } from './mappings';
import { handleFinishedReview } from './profile-review';
import {
  queryInvestAccountQuestions,
  queryHasActiveInvestAccount,
} from './remote';

type Profile = {
  beneficiaries: Array<Beneficiary>;
  primary: {
    firstName: string;
  };
  secondary:
    | {
        firstName: string;
      }
    | null
    | undefined;
  status: string;
};

type ViewerQueryResult = {
  profile: Profile;
};

export function* investOnboardingSaga(): SagaIterator<void> {
  yield takeLatest(
    ACTIONS.BEGIN_INVEST_ACCOUNT_SETUP,
    beginInvestOnboardingFlow,
  );
}

function* beginInvestOnboardingFlow(
  action: BeginInvestAccountSetupFlow,
): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );
  const investAccountQuestions: Array<InvestAccountOpeningQuestion> =
    yield call(queryInvestAccountQuestions);
  const hasActiveInvestAccount: boolean = yield call(
    queryHasActiveInvestAccount,
  );
  yield put(setInvestSuitabilityOnboardingQuestions(investAccountQuestions));

  // This saga is only fit for users who don't have an active invest account otherwise they should use d/open-invest-account route. This helps prevent users from going back and reopening an account on accident.
  if (hasActiveInvestAccount) {
    const navigate: NavigateFunction = yield select(
      (state) => state.routing.navigate,
    );
    yield call(navigate, { to: '/d/home' });
    return;
  }

  const [isOpenAccountBlocked]: [boolean, ViewerQueryResult] = yield all([
    call(isFunctionBlocked, 'OPEN_ACCOUNT'),
    call(delay, 1200),
  ]);

  // CXIO check for if user has the integrated onboarding feature flag and an incomplete profile (Module 1 & Module 2)
  const hasIncompleteProfile = yield call(userHasIncompleteProfile);

  // Setup internal context.
  yield setContext({
    basePath: action.payload.basePath,
  });

  // CX IO flow: Navigate user to financial suitability (Module 2) if they have not completed it yet
  if (hasIncompleteProfile) {
    yield put(startFlow('FINANCIAL_SUITABILITY'));
    yield call(navigate, {
      to: '/onboarding/financial-details/disclosures',
      query: {
        product: 'invest',
        previousRouteName: '/d/invest/invest-marketing',
      },
    });
    return;
  }

  let initialStep = primaryQuestionMap(
    getInitialInvestQuestion(investAccountQuestions),
  );

  if (isOpenAccountBlocked) {
    initialStep = INVEST_ONBOARDING_FLOW_STEPS.SYSTEM_UNAVAILABLE;
  }

  // Setup our listeners
  yield fork(setupFlowListeners, investAccountQuestions);

  // Kick things off.
  yield call(changeStep, initialStep, true);
}

function* setupFlowListeners(
  investAccountQuestions: Array<InvestAccountOpeningQuestion>,
): SagaIterator<void> {
  yield takeEvery(
    ACTIONS.CHANGE_INVEST_ACCOUNT_SETUP_FLOW_STEP,
    function* (action: ChangeInvestAccountSetupStep): SagaIterator<void> {
      yield call(changeStep, action.payload);
    },
  );

  // Suitability Questions
  yield takeEvery(
    ACTIONS.SUBMITTED_INVEST_PROFILE_INPUT,
    function* (action: any): SagaIterator<void> {
      yield call(
        handleInvestSuitabilityNavigation,
        action,
        investAccountQuestions,
      );
    },
  );

  yield takeEvery(
    ACTIONS.SKIPPED_INVEST_TRUSTED_CONTACT,
    function* (): SagaIterator<void> {
      yield call(
        handleInvestSuitabilityNavigation,
        {
          payload: {
            field: 'suitability.trustedContact',
          },
        },
        investAccountQuestions,
      );
    },
  );

  yield takeEvery(
    ACTIONS.COLLECTED_INVEST_TRUSTED_CONTACT,
    function* (): SagaIterator<void> {
      yield call(
        handleInvestSuitabilityNavigation,
        {
          payload: {
            field: 'suitability.trustedContact',
          },
        },
        investAccountQuestions,
      );
    },
  );

  yield takeEvery(
    ACTIONS.SUBMITTED_INTRODUCTION_SOURCE,
    function* (): SagaIterator<void> {
      yield call(
        handleInvestSuitabilityNavigation,
        {
          payload: {
            field: 'suitability.introductionSource',
          },
        },
        investAccountQuestions,
      );
    },
  );

  // Account Review
  yield takeEvery(
    ACTIONS.FINISHED_INVEST_ACCOUNT_SETUP_REVIEW,
    function* (): SagaIterator<void> {
      yield call(handleFinishedReview);
    },
  );
}
