import { SagaIterator } from 'redux-saga';
import { call, setContext, takeEvery, takeLatest } from 'redux-saga/effects';

import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import { KNOW_YOUR_CUSTOMER_FLOW_STEPS as KYC_STEPS } from '~/static-constants';

import { changeStep } from '../utils';

export function* knowYourCustomerSaga(): SagaIterator<void> {
  yield takeLatest('BEGIN_KNOW_YOUR_CUSTOMER_FLOW', knowYourCustomerFlow);
}

function* knowYourCustomerFlow(action: any): SagaIterator<void> {
  const { basePath } = action.payload;
  yield setContext({
    basePath,
  });

  yield takeEvery(ACTIONS.SUBMITTED_PROFILE_INPUT, handleProfileInput);
}

function* handleProfileInput(action: any): Generator<any, any, any> {
  const nextSteps = {
    'suitability.annualIncomeAmount': KYC_STEPS.COLLECT_TOTAL_NET_WORTH,
    'suitability.totalNetWorth': KYC_STEPS.COLLECT_LIQUID_NET_WORTH,
    'suitability.liquidNetWorth': KYC_STEPS.COLLECT_INTRODUCTION_SOURCE,
  };
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ 'suitability.annualIncomeAmount': string; 'suitability.totalNetWorth': string; 'suitability.liquidNetWorth': string; }'.
  yield call(changeStep, nextSteps[action.payload.field]);
}
