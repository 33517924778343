import * as React from 'react';

import { SecurityDetailFragment } from '~/graphql/types';

import { MissingSecurityData } from '../MissingSecurityData';

import { SecurityDataContainer } from '../SecurityDataContainer';

import { CryptoKeyData } from './CryptoKeyData';
import { EquityKeyData } from './EquityKeyData';
type SecurityKeyDataProps = {
  security: SecurityDetailFragment;
};

const hasCryptoFields = (security: SecurityDetailFragment): boolean => {
  return Boolean(
    'marketCap' in security ||
      'volume' in security ||
      'circulatingSupply' in security,
  );
};

export const SecurityKeyData = ({ security }: SecurityKeyDataProps) => {
  const { fundamentals, __typename } = security;
  const hasNoFundamentals =
    !fundamentals ||
    Object.values(fundamentals).every((value) => value === null);

  if (hasNoFundamentals && !hasCryptoFields(security)) {
    return (
      <SecurityDataContainer
        headingContent="Key Data"
        flexDirection="column"
        maxWidth={790}
      >
        <MissingSecurityData title="No key data" />
      </SecurityDataContainer>
    );
  } else if (__typename === 'CryptoAsset') {
    return <CryptoKeyData security={security} />;
  }
  return <EquityKeyData security={security} />;
};
