import * as React from 'react';

import { SliceableMarket } from '~/components/invest/SliceableMarket';

import { SliceableLogo } from '~/components/pie';
import { SecurityCellFragment } from '~/graphql/types';
import { Cell, CellProps } from '~/toolbox/cell';

export type SecurityCellProps = Omit<
  CellProps,
  'icon' | 'subtitle' | 'title'
> & {
  className?: string;
  includeSymbol?: boolean | null | undefined;
  security: SecurityCellFragment & { market: string };
};

export const SecurityCell = ({
  includeSymbol,
  security,
  className,
  ...rest
}: SecurityCellProps) => (
  <Cell
    className={className}
    icon={
      <span
        style={{
          maxWidth: 32,
        }}
      >
        <SliceableLogo sliceable={security} />
      </span>
    }
    subtitle={
      readIncludeSymbol(includeSymbol) && (
        <SliceableMarket sliceable={security} />
      )
    }
    title={security.name}
    {...rest}
  />
);

function readIncludeSymbol(includeSymbol: boolean | null | undefined): boolean {
  if (typeof includeSymbol === 'boolean') {
    return includeSymbol;
  } else if (typeof includeSymbol !== 'undefined') {
    return Boolean(includeSymbol);
  }
  return true;
}
