import { LayoutableProps } from '@m1/liquid-react';
import * as React from 'react';

import { AnnouncementFragment } from '~/graphql/types';

import { InlineAnnouncement } from './InlineAnnouncement';
import { StyledRootBox } from './StyledAnnouncementCard';
import { WideAnnouncement } from './WideAnnouncement';

export type AnnouncementAsCardProps = LayoutableProps & {
  announcement: AnnouncementFragment;
  layout: 'inline' | 'wide';
  onDismiss?: () => void;
};

export const AnnouncementCard = (props: AnnouncementAsCardProps) => {
  const [isDismissed, setIsDismissed] = React.useState(false);

  const handleClick = (): void => {
    setIsDismissed(true);
  };

  const handleAnimationEnd = (): void => {
    if (props.onDismiss) {
      props.onDismiss();
    }
  };

  const { announcement, layout, onDismiss, ...rest } = props;
  return (
    <StyledRootBox
      {...rest}
      isDismissed={isDismissed}
      onAnimationEnd={handleAnimationEnd}
    >
      {layout === 'inline' ? (
        <InlineAnnouncement announcement={announcement} />
      ) : (
        <WideAnnouncement
          announcement={announcement}
          handleClick={handleClick}
        />
      )}
    </StyledRootBox>
  );
};
