import * as React from 'react';
import { Route } from 'react-router-dom';

import { RewardsPayoutLocation } from './page';

export const CreditCardRewardsPayoutRoute = () => {
  return (
    <Route
      path="rewards-payout/:step?"
      element={<RewardsPayoutLocation />}
      handle={{
        fallbackRoute: {
          to: '/d/spend/credit/rewards/summary',
        },
      }}
    />
  );
};
