import * as React from 'react';
import { Route } from 'react-router-dom';

import { ConfirmIdentityPage } from './ConfirmIdentityPage';

export const ConfirmIdentityRoute = () => {
  return (
    <Route
      path="confirm-identity"
      element={<ConfirmIdentityPage />}
      handle={{
        fallbackRoute: {
          to: '/d/invest/portfolio',
        },
      }}
    />
  );
};
