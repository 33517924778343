import { Banner } from '@m1/liquid-react';
import * as React from 'react';

import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';

export const ReacceptLegalTermsBanner = () => {
  return (
    <Container>
      <Banner
        action={<Link to="/d/c/reaccept-legal-terms">Review Terms</Link>}
        content="We’ve updated our terms and conditions to offer even more awesome features!"
        kind="alert"
        size="wide"
      />
    </Container>
  );
};
