import * as React from 'react';

import { OnDemandTradeFragment } from '~/graphql/types';

import { GridTable } from '~/toolbox/grid-table';

import { OnDemandAllocatedTrade } from './OnDemandAllocatedTrade';

export const OnDemandAllocatedTradesTable = ({
  trades,
  label,
}: {
  label: string;
  trades: OnDemandTradeFragment[] | null | undefined;
}) => {
  return (
    <GridTable
      emptyMessage={`No ${label} to display`}
      gridTemplateColumns="200px auto 15% 20% 15%"
    >
      <GridTable.HeaderRow>
        <GridTable.HeaderCell label="Name" />
        <GridTable.HeaderCell label="Status" textAlign="right" />
        <GridTable.HeaderCell label="Avg. Price" textAlign="right" />
        <GridTable.HeaderCell label="Quantity" textAlign="right" />
        <GridTable.HeaderCell label="Amount" textAlign="right" />
      </GridTable.HeaderRow>
      {trades &&
        trades.length > 0 &&
        trades.map((trade) => (
          <GridTable.Row key={trade.of?.id}>
            <OnDemandAllocatedTrade trade={trade} />
          </GridTable.Row>
        ))}
    </GridTable>
  );
};
