import { LL, Flex } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import { GridTable } from '~/toolbox/grid-table';

type LiquidateModalTradingWindowProps = {
  nextTradingTime: string;
};

export const LiquidateModalTradingWindow = (
  liquidateModalTradingWindow: LiquidateModalTradingWindowProps,
) => {
  const nextTradingTime = liquidateModalTradingWindow.nextTradingTime;
  const nextTradeDayModifier =
    moment(nextTradingTime).hour() > moment().hour() ? 'today' : 'tomorrow';

  const isMorning = moment(nextTradingTime).hour() < 12;
  const nextTradeTimeOfDay = isMorning ? 'Morning' : 'Afternoon';
  const nextTradeTimeModifier = isMorning ? 'AM' : 'PM';

  return (
    <GridTable hasHeaderRow={false} gridTemplateColumns="70% auto">
      <GridTable.Row>
        <GridTable.Cell>
          <Flex alignItems="center">
            <LL>
              {`${nextTradeTimeOfDay} `}
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                {` ${moment(nextTradingTime).format(
                  'H:mm',
                )} ${nextTradeTimeModifier} ${nextTradeDayModifier}`}
              </span>
            </LL>
          </Flex>
        </GridTable.Cell>
      </GridTable.Row>
    </GridTable>
  );
};
