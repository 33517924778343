import React from 'react';

import { IraDistributionReasonEnumDropdownOptions } from '~/static-constants';

import { DropdownField } from './dropdown-field';

const getSource = (isRecurring: boolean | null | undefined) => {
  if (isRecurring) {
    return IraDistributionReasonEnumDropdownOptions.filter(
      (reason) =>
        reason.name !== 'EXCESS_CONTRIBUTION_REMOVAL_BEFORE_TAX_DEADLINE' &&
        reason.name !== 'EXCESS_CONTRIBUTION_REMOVAL_AFTER_TAX_DEADLINE',
    );
  }
  return IraDistributionReasonEnumDropdownOptions;
};

type RetirementDisbursementReasonDropdownProps = {
  isRecurringTransfer?: boolean;
  label: string;
  name: string;
  style?: Record<string, any>;
  validate?: [(...args: Array<any>) => any];
};

export const RetirementDisbursementReasonDropdownField = ({
  isRecurringTransfer,
  ...rest
}: RetirementDisbursementReasonDropdownProps) => {
  return (
    <DropdownField
      {...rest}
      placeholder="Select"
      source={getSource(isRecurringTransfer)}
    />
  );
};
