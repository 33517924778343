import { HXS, PL, Card } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

export type TransfersEmptyStateCardProps = {
  content: string;
  title: string;
};

export const TransfersEmptyStateCard = ({
  content,
  title,
}: TransfersEmptyStateCardProps) => {
  return (
    <Card
      borderColor="transparent"
      style={{
        padding: 64,
        textAlign: 'center',
      }}
    >
      {' '}
      <Illustration name="noTransferActivity" />
      <HXS content={title} mt={32} />
      <PL content={content} mt={32} />
    </Card>
  );
};
