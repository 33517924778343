import { Box, Button, Flex, HXS, HXXS, LM, PL } from '@m1/liquid-react';
import * as React from 'react';

import { CryptoDisclosure } from '~/components/CryptoDisclosure';
import { GenericSystemError } from '~/components/GenericSystemError';
import { useReviewPageRequiredDocumentsQuery } from '~/graphql/hooks';
import { OnlineAccountRegistrationEnum } from '~/graphql/types';
import { useDispatch } from '~/redux/hooks';
import { OPEN_INVEST_ACCOUNT_STEPS } from '~/static-constants';
import { Divider } from '~/toolbox/divider';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

import { DocumentsColumns } from '../DocumentsColumns';

type OnFinishPayload = {
  signature: string;
};

type CryptoReviewStepProps = {
  onFinishStep: (arg0: OnFinishPayload) => void;
};

export const CryptoReviewStep = ({ onFinishStep }: CryptoReviewStepProps) => {
  const dispatch = useDispatch();

  const { data, error, loading } = useReviewPageRequiredDocumentsQuery({
    variables: {
      accountRegistration: OnlineAccountRegistrationEnum.Crypto,
    },
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (error || !data?.viewer.invest?.requiredDocuments) {
    return <GenericSystemError />;
  }

  const requiredDocuments = data?.viewer.invest?.requiredDocuments;

  return (
    <Box maxWidth={588} mx="auto">
      <HXS content="Does everything look correct?" pb={16} />
      <Flex justifyContent="space-between">
        <Flex flexDirection="column">
          <HXXS content="Account Type" />
          <LM content="Crypto" pt={8} />
        </Flex>
        <Button
          kind="link"
          label="Edit"
          onClick={() => {
            dispatch({
              // @ts-ignore rando type for now
              type: 'CHANGE_OPEN_INVEST_ACCOUNT_STEP',
              payload: OPEN_INVEST_ACCOUNT_STEPS.SELECT_ACCOUNT_TYPE,
            });
          }}
        />
      </Flex>
      <Divider spacing="relaxed" />
      <Box>
        <PL
          content={requiredDocuments.preamble}
          mb={16}
          color="foregroundNeutralMain"
        />
        <DocumentsColumns>
          {requiredDocuments.documents.map((d) => (
            <Box key={d.url} pb={8}>
              <Link to={d.url} target="_blank">
                {d.title}
              </Link>
            </Box>
          ))}
        </DocumentsColumns>
        <CryptoDisclosure includeTradeDisclosure={false} />
        <PL content={requiredDocuments.postamble} pt={8} />
      </Box>
      <Divider spacing="relaxed" />
      <Button
        size="large"
        label="Continue"
        onClick={() => {
          onFinishStep({
            signature: requiredDocuments.signature,
          });
        }}
        mb={64}
      />
    </Box>
  );
};
