import { InputProps } from '@m1/liquid-react';
import * as React from 'react';

import {
  Controller,
  type ControllerProps,
  type FieldValues,
  type Path,
} from 'react-hook-form';

import { AmountInput } from '~/toolbox/AmountInput/AmountInput';

export type AmountInputFieldProps<
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues = FieldValues,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  Pick<InputProps, 'style'>;

export function ControlledAmountInput<
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues,
>({
  control,
  name,
  rules,
  ...inputProps
}: AmountInputFieldProps<TName, TFieldValues> & { autoFocus: boolean }) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState, formState, ...rest }) => (
        <AmountInput
          {...field}
          {...inputProps}
          {...rest}
          error={
            fieldState.isDirty
              ? (fieldState.error?.message ?? Boolean(fieldState.error))
              : undefined
          }
          placeholder="$0"
          scale={2}
        />
      )}
      rules={rules}
    />
  );
}
