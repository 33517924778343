import { PL, LM, PM, Flex, Box, HXXS, Skeleton } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { AppContext } from '~/AppContext';
import { TransfersCompletedTable } from '~/components/transfers/TransfersCompletedTable';
import { HomePageQuery } from '~/graphql/types';
import { Link } from '~/toolbox/link';

export type HomeTransfersProps = {
  transfers: HomePageQuery['viewer']['transfers'] | null | undefined;
};

export const HomeTransfers = ({ transfers }: HomeTransfersProps) => {
  const { analytics } = React.useContext(AppContext);
  return (
    <Flex
      data-testid="home-transfers"
      flexDirection="column"
      maxWidth="100%"
      pt={32}
      gap={16}
    >
      <Flex justifyContent="space-between">
        <Skeleton>
          <HXXS
            fontWeight={300}
            color="foregroundNeutralMain"
            content="Transfers"
          />
        </Skeleton>
        <Skeleton>
          <Flex flexDirection="row" gap={8}>
            <Link
              to="/d/transfers"
              onClick={() =>
                analytics.recordEvent('m1_home_view_all_transfers_clicked')
              }
            >
              <PL fontWeight={600} content="All transfers" />
            </Link>
            <LM color="borderMain">|</LM>
            <Link to="/d/transfers/rules">
              <PL fontWeight={600} content="Recurring" />
            </Link>
          </Flex>
        </Skeleton>
      </Flex>
      <Skeleton skeletonWidth="100%">
        <TransfersCompletedTable
          emptyMessage={<EmptyTransfers />}
          routeToNavigateToOnFinish="/d/home"
          transferInstanceConnection={transfers?.allInstances}
          rowAnalytics="m1_home_transfer_clicked"
        />
      </Skeleton>
    </Flex>
  );
};

const EmptyTransfers = () => {
  return (
    <Box width={320} textAlign="center" my={32}>
      <Box mb={14}>
        <Illustration height={100} name="noTransferActivity" />
      </Box>
      <PM>When you fund your account, transactions will appear here</PM>
    </Box>
  );
};
