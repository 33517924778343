import * as React from 'react';

import { Link } from '~/toolbox/link';

type StringAndLinksArray = (JSX.Element | string)[];

const formatPhoneNumberInText = (
  text: string,
  singularNumber: string,
): StringAndLinksArray => {
  return text // Split the text at the given number, this removes the number from the string and creates an array
    .split(singularNumber) // Merge in the phone number as a link before each element in the array, i.e. in the same spots the number was
    .flatMap((x, i) => [
      <Link
        key={`${singularNumber}-${i}`}
        usePlainAnchor
        to={`tel:${singularNumber}`}
      >
        {singularNumber}
      </Link>,
      x,
    ]) // Hack off the first element in the array (which would be a phone Link that we don't want)
    .slice(1);
};

/**
 * @property phoneNumber The phone number(s) in which you want to turn into links in
 *                       the given text. Numbers should be formatted for anchor tel links.
 * @property text The text in which you want to turn phone numbers into links
 */
export type TextWithPhoneLinksProps = {
  phoneNumber: Array<string | null | undefined> | string | null | undefined;
  text: string | null | undefined;
};

export const TextWithPhoneLinks: React.FC<TextWithPhoneLinksProps> = ({
  text,
  phoneNumber,
}) => {
  if (!text) {
    return null;
  }

  if (!phoneNumber) {
    return <>{text}</>;
  }

  const phoneNumbers = Array.isArray(phoneNumber)
    ? phoneNumber.filter(Boolean)
    : [phoneNumber];

  const formattedNumber = phoneNumbers.reduce(
    // @ts-expect-error - TS2769 - No overload matches this call.
    (acc, num) => {
      return acc.flatMap((txt) =>
        // flatMap will flatten any nested arrays resulting from formatPhoneNumberInText
        // Find numbers in strings only, skip over already mapped toolbox Links
        // @ts-expect-error - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'string'.
        typeof txt === 'string' ? formatPhoneNumberInText(txt, num) : txt,
      );
    },
    [text],
  );

  return <>{formattedNumber}</>;
};
