import { HXS, PL } from '@m1/liquid-react';
import * as React from 'react';

import { InvestBankConnectionAccountNodeFragment } from '~/graphql/types';

import { ChangeBankLink } from './ChangeBankLink';

export const BankRelationshipViaLink = ({
  achRelationship,
  canChangeBank,
}: {
  achRelationship:
    | InvestBankConnectionAccountNodeFragment['lastAchRelationship']
    | null
    | undefined;
  canChangeBank: boolean;
}) => {
  if (!achRelationship) {
    return null;
  }

  return (
    <>
      <HXS mb={32} content="This is your linked account:" />
      <PL mb={16} content={achRelationship?.nickname} />
      {canChangeBank && <ChangeBankLink achRelationship={achRelationship} />}
    </>
  );
};
