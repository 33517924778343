import { HS, PL } from '@m1/liquid-react';
import React from 'react';

import { clickedOnSliceable } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { Section } from '~/toolbox/section';

import { PieList } from './PieList';
import { useMyPiesContext } from './useMyPiesContext';

export const CryptoMyPiesSection = () => {
  const {
    cryptoPies: { linkedPies, totalPies, unlinkedPies },
  } = useMyPiesContext();

  const dispatch = useDispatch();

  return (
    <>
      {totalPies > 0 && (
        <>
          <HS mb={8}>Crypto Pies</HS>
          <PL mb={32}>
            You can't put stocks or funds into these Pies or your Crypto
            Account.
          </PL>
        </>
      )}
      {linkedPies.length > 0 && (
        <Section>
          <PL content="Used in your Crypto Account" mb={8} fontWeight={600} />
          <PieList
            onClick={(pieId) => dispatch(clickedOnSliceable(pieId, true))}
            userPies={linkedPies}
          />
        </Section>
      )}
      {unlinkedPies.length > 0 && (
        <Section>
          <PL
            content="Crypto Pies not used in your account"
            mb={8}
            fontWeight={600}
          />
          <PieList
            onClick={(pieId) => dispatch(clickedOnSliceable(pieId, true))}
            userPies={unlinkedPies}
          />
        </Section>
      )}
    </>
  );
};
