export const FORM_ACTIONS = Object.freeze({
  CLICKED_NO_DISCLOSURES_APPLY: 'CLICKED_NO_DISCLOSURES_APPLY' as const,
});

export type ClickedNoDisclosuresApplyAction = {
  payload: unknown;
  type: typeof FORM_ACTIONS.CLICKED_NO_DISCLOSURES_APPLY;
};

export const clickedNoDisclosuresApply = (
  payload: ClickedNoDisclosuresApplyAction['payload'],
) => ({
  payload,
  type: FORM_ACTIONS.CLICKED_NO_DISCLOSURES_APPLY,
});

export type FormAction = ClickedNoDisclosuresApplyAction;
