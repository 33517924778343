import { Button, Flex } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';

import { InvestAcatWizardTransferTypeSelectionNavigationEnum } from '~/graphql/types';

import { useAnalytics } from '~/hooks/useAnalytics';
import { AppInformationalMessageCard } from '~/lens-toolbox/AppInformationalMessageCard';

import { AcatWizardAccountDropdown } from '../components/AcatWizardAccountDropdown';
import { AcatWizardBackButton } from '../components/AcatWizardBackButton';
import { AcatWizardErrorState } from '../components/AcatWizardErrorState';
import { AcatWizardStepCard } from '../components/AcatWizardStepCard';
import { AcatWizardStepContainer } from '../components/AcatWizardStepContainer';
import { useAcatWizardFormContext } from '../hooks/useAcatWizardFormContext';
import { useAcatWizardQueryContext } from '../hooks/useAcatWizardQueryContext';
import { handleEnterKeyDownUtils } from '../utils';

export const AcatWizardDestinationAccountStep = () => {
  const data = useAcatWizardQueryContext();

  const { goTo, watch, back, next } = useAcatWizardFormContext();
  const { destinationAccountId, selectedTransferType } = watch();

  const destinationAccountContent =
    data?.viewer.invest?.acatWizard?.destinationAccountContent;

  const analytics = useAnalytics();

  React.useEffect(() => {
    analytics.pageView('m1_acat_wizard_select_destination_account_screen_view');
  }, [analytics]);

  if (!destinationAccountContent) {
    return (
      <AcatWizardErrorState>
        <BackArrow content="Back" onClick={back} />
      </AcatWizardErrorState>
    );
  }

  const {
    ctaLabel,
    header,
    informationalMessage,
    validParticipants,
    validParticipantsLabel,
  } = destinationAccountContent;

  return (
    <AcatWizardStepContainer>
      <AcatWizardBackButton />
      <AcatWizardStepCard
        header={header}
        onKeyPress={(event: React.KeyboardEvent) =>
          handleEnterKeyDownUtils(event, Boolean(destinationAccountId), next)
        }
      >
        <AcatWizardAccountDropdown
          accounts={validParticipants}
          label={validParticipantsLabel}
        />
        {informationalMessage && (
          <AppInformationalMessageCard
            appInformationalMessageCard={informationalMessage}
            mt={16}
          />
        )}
      </AcatWizardStepCard>
      <Flex>
        <Button
          disabled={!destinationAccountId}
          label={ctaLabel}
          onClick={() => {
            if (
              selectedTransferType !==
              InvestAcatWizardTransferTypeSelectionNavigationEnum.PartialAcatTransfer
            ) {
              goTo('acat-confirm');
            } else {
              goTo('select-assets');
            }
          }}
          size="large"
        />
      </Flex>
    </AcatWizardStepContainer>
  );
};
