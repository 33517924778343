import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useBorrowMarginMarketingPageQuery } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import { BorrowMarketingContainer } from '~/pages/dashboard/borrow/components/BorrowMarketingContainer';

export const BorrowMarginMarketingPage = () => {
  const { data, loading } = useBorrowMarginMarketingPageQuery();
  const navigate = useNavigate();

  if (loading) {
    return null;
  }
  if (!data?.viewer) {
    return <GenericSystemError />;
  }

  if (data.viewer.borrow?.hasBorrowAccounts) {
    navigate({ to: '/d/borrow/margin' });
  }

  return <BorrowMarketingContainer />;
};
