import React from 'react';

import { Grid } from '~/toolbox/grid';

import { CryptoResultsTable } from './CryptoResultsTable';
import { CryptoScreenerProvider } from './CryptoScreenerProvider';

export const CryptoScreener = () => {
  return (
    <CryptoScreenerProvider>
      <Grid
        style={{
          paddingTop: 16,
          paddingBottom: 16,
        }}
      >
        <Grid.Row>
          <Grid.Col xs={12}>
            <CryptoResultsTable />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </CryptoScreenerProvider>
  );
};
