import { Box } from '@m1/liquid-react';
import React from 'react';

import { ModelPortfolioCategories } from './components/ModelPortfolioCategories';

export const ModelPortfolioPage = () => (
  <Box mt={16}>
    <ModelPortfolioCategories />
  </Box>
);
