import * as React from 'react';
import { Field } from 'redux-form';

import { withProps } from '~/hocs';
import { Checkbox } from '~/toolbox/checkbox';

// @ts-expect-error - TS7031 - Binding element 'input' implicitly has an 'any' type. | TS7031 - Binding element 'meta' implicitly has an 'any' type.
const renderField = ({ input, meta, ...rest }) => {
  return React.createElement(Checkbox, {
    ...rest,
    ...meta,
    ...input,
  });
};

const enhancer = withProps({
  component: renderField,
});

export const CheckboxField = enhancer(Field) as any;
