import { Box, Button, Flex, HXXS, PL } from '@m1/liquid-react';
import * as React from 'react';

import { RetirementAccountComparisonModal } from '~/components/RetirementAccountComparisonModal/RetirementAccountComparisonModal';
import { RetirementAccountTypes } from '~/redux/actions';
import { RETIREMENT_ACCOUNT_TYPES } from '~/static-constants';

import { Radio } from '~/toolbox/radio';

type OnFinishPayload = {
  retirementChoiceType: RetirementAccountTypes;
};

type RetirementTypeSelectStepComponentProps = {
  onFinishStep: (arg0: OnFinishPayload) => void;
};

export const RetirementTypeSelectStep = ({
  onFinishStep,
}: RetirementTypeSelectStepComponentProps) => {
  const [choice, setChoice] = React.useState<RetirementAccountTypes | null>(
    null,
  );

  const onChange = (choice: RetirementAccountTypes) => {
    setChoice(choice);
  };

  const onContinue = () => {
    if (!choice) {
      return;
    }

    onFinishStep({
      retirementChoiceType: choice,
    });
  };

  return (
    <Flex flexDirection="column">
      <HXXS content="What kind of retirement account?" mt={16} />
      <PL mt={16}>
        Choosing between a traditional or roth IRA can be tricky. We've put
        together a brief{' '}
        <RetirementAccountComparisonModal content="comparison of the two" /> if
        you need some pointers.
      </PL>

      <Radio.Group
        onChange={onChange}
        style={{
          marginTop: 24,
        }}
        value={choice}
      >
        <Radio.Choice
          label={
            <>
              <PL content="Traditional IRA" fontWeight={600} />
              <PL content="Open a Traditional IRA" />
            </>
          }
          value={RETIREMENT_ACCOUNT_TYPES.TRADITIONAL}
        />
        <Radio.Choice
          label={
            <>
              <PL content="Roth IRA" fontWeight={600} />
              <PL content="Open a Roth IRA" />
            </>
          }
          value={RETIREMENT_ACCOUNT_TYPES.ROTH}
        />
        <Radio.Choice
          label={
            <>
              <PL
                content="SEP (Simplified Employee Pension)"
                fontWeight={600}
              />
              <PL content="Open an IRA built for self employed individuals that has a higher contribution limit than individual IRAs" />
            </>
          }
          value={RETIREMENT_ACCOUNT_TYPES.SEP}
        />
        <Radio.Choice
          label={
            <>
              <PL content="Rollover" fontWeight={600} />
              <PL content="Rollover a 401(k) from a previous employer into a M1 IRA" />
            </>
          }
          value={RETIREMENT_ACCOUNT_TYPES.ROLLOVER}
        />
      </Radio.Group>

      <Box>
        <Button
          disabled={!choice}
          kind="primary"
          label="Continue"
          mt={64}
          onClick={onContinue}
          size="large"
        />
      </Box>
    </Flex>
  );
};
