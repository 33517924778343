import {
  Button,
  Flex,
  HXS,
  Image,
  LS,
  PL,
  PM,
  PXL,
  styled,
  useThemeMode,
} from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import moment from 'moment';
import * as React from 'react';

import { ExternalLinkButton } from '~/components/ExternalLinkButton';
import { updateUserData } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

import smartTransferStep1Dark from './images/1-high-yield-account-dark.png';
import smartTransferStep1Light from './images/1-high-yield-account.png';
import smartTransferStep2Dark from './images/2-high-yield-dark.png';
import smartTransferStep2Light from './images/2-high-yield.png';
import smartTransferStep3Dark from './images/3-high-yield-account-dark.png';
import smartTransferStep3Light from './images/3-high-yield-account.png';
import smartTransferStep4Dark from './images/4-high-yield-account-to-portfolio-dark.png';
import smartTransferStep4Light from './images/4-high-yield-account-to-portfolio.png';

type SmartTransferUsageType = {
  description: string;
  title: string;
};

type IntroduceSmartTransferProps = {
  onFinishStep: () => void;
};

// Constants
const usages: Array<SmartTransferUsageType> = [
  {
    title: 'Set up an emergency fund',
    description:
      'Set a cash minimum and move overages into a high-yield account to prepare for unexpected events.',
  },
  {
    title: 'Invest excess cash',
    description:
      'Get maximum exposure to the market by automatically moving your excess high-yield account funds to an investment account.',
  },
  {
    title: 'Save your dividends',
    description:
      'Automatically move excess cash from your investment account to your high-yield accounts to take advantage of your dividends.',
  },
  {
    title: 'Automate Margin payments',
    description:
      'Pay back your Margin Loan interest or principal with overages from your high-yield accounts.',
  },
];

// Styled Components
const StyledUseCaseCard = styled(Flex)`
  border: 1px solid ${(props) => props.theme.colors.borderMain};
  border-radius: 8px;
`;

const StyledHowDoesThisWorkContainer = styled(Flex)`
  background: ${(props) => props.theme.colors.backgroundNeutralMain};
  border-radius: 8px;
`;

export const IntroduceSmartTransfer = ({
  onFinishStep,
}: IntroduceSmartTransferProps) => {
  const dispatch = useDispatch();

  const { activeThemeMode } = useThemeMode();

  React.useEffect(() => {
    dispatch(
      updateUserData({
        key: 'smartTransferIntroDate',
        value: moment().toISOString(),
      }),
    );
  }, [dispatch]);

  let smartTransferStep1 = smartTransferStep1Light;
  let smartTransferStep2 = smartTransferStep2Light;
  let smartTransferStep3 = smartTransferStep3Light;
  let smartTransferStep4 = smartTransferStep4Light;

  if (activeThemeMode === 'dark') {
    smartTransferStep1 = smartTransferStep1Dark;
    smartTransferStep2 = smartTransferStep2Dark;
    smartTransferStep3 = smartTransferStep3Dark;
    smartTransferStep4 = smartTransferStep4Dark;
  }
  const introductionParagraph = `Your M1 accounts are interconnected, and money can move between them automatically using rules you set up. The rules are called Smart Transfers, and they’re available to clients with a High-Yield Cash or Savings account.`;

  return (
    <Flex flexDirection="column" mx="auto" my={120} width={542}>
      <Illustration height={250} name="noTransferRulesCreated" />
      <HXS
        content="Automate your money movements with Smart Transfers by M1"
        mt={32}
      />
      <PL content={introductionParagraph} mt={8} />
      <Flex flexWrap="wrap" justifyContent="space-between" mt={32}>
        {usages.map((useCase) => (
          <StyledUseCaseCard
            key={useCase.title}
            flexDirection="column"
            mb={16}
            p={16}
            width={260}
          >
            <PL fontWeight={600} content={useCase.title} />
            <PM content={useCase.description} />
          </StyledUseCaseCard>
        ))}
      </Flex>
      <>
        <PXL fontWeight={600} content="How does this work?" mb={8} mt={16} />
        <StyledHowDoesThisWorkContainer
          flexDirection="column"
          pb={24}
          pt={16}
          px={24}
        >
          <PL
            fontWeight={600}
            content="Step 1: Choose your starting point"
            mb={8}
          />
          <Image alt="" src={smartTransferStep1} />
          <PL
            fontWeight={600}
            content="Step 2: Select a destination"
            mb={8}
            mt={32}
          />
          <Image alt="" src={smartTransferStep2} />
          <PL fontWeight={600} content="Step 3: Set the rules" mb={8} mt={32} />
          <Image alt="" src={smartTransferStep3} />
          <PL
            fontWeight={600}
            content="Step 4: Review and create your Smart Transfer"
            mb={8}
            mt={32}
          />
          <Image alt="" src={smartTransferStep4} />
          <LS
            color="foregroundNeutralMain"
            content="Your Smart Transfer will run based on your choices from step 1. Invest Smart Transfers run before trade windows. High-yield Smart Transfers check for balance changes throughout the day."
            mt={16}
          />
        </StyledHowDoesThisWorkContainer>
      </>
      <ButtonGroup mt={64}>
        <>
          <ExternalLinkButton
            destination="FAQ_SMART_TRANSFERS"
            label="Learn More"
            kind="secondary"
            size="large"
          />
          <Button
            kind="primary"
            label="Create Smart Transfer"
            onClick={() => onFinishStep()}
            size="large"
          />
        </>
      </ButtonGroup>
    </Flex>
  );
};
