import { Button, Flex, HM, PL } from '@m1/liquid-react';
import React from 'react';

import { useForm } from 'react-hook-form';

import { AppContext } from '~/AppContext';
import { useWizardContext } from '~/flows/wizard';
import { useAuthenticateMutation, useUserEmailQuery } from '~/graphql/hooks';
import { AuthenticateErrorEnum } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { clearSingleReactHookForm } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';
import { ControlledCodeInput } from '~/toolbox/CodeInput/ControlledCodeInput';

type CollectAmfaCodeProps = {
  exitFlow: () => void;
};

type CollectAmfaCodeFormValues = {
  code: string;
};

export const CollectAmfaCode = ({ exitFlow }: CollectAmfaCodeProps) => {
  const { goTo, back } = useWizardContext();

  const [authenticate, { loading: authenticateMutationLoading }] =
    useAuthenticateMutation();

  const { data: userEmailData, loading: userEmailQueryLoading } =
    useUserEmailQuery();

  const password = useSelector<string | undefined>(
    (state) => state.reactHookForm.enhancedAuthPasswordForm?.password,
  );

  const { addToast } = useToast();

  const [codeInputError, setCodeInputError] =
    React.useState<AuthenticateErrorEnum | null>(null);

  const dispatch = useDispatch();

  const { auth } = React.useContext(AppContext);

  const analytics = useAnalytics();

  React.useEffect(() => {
    analytics.pageView('m1_MFA_code_screen_page_view');
  }, [analytics]);

  const submitForm = async (code: string) => {
    if (!userEmailData?.viewer?.user?.username || !password) {
      throw new Error(
        'Attempted to authenticate without a username or password.',
      );
    }

    await authenticate({
      variables: {
        input: {
          username: userEmailData.viewer.user.username,
          password,
          emailCode: code,
          mfaAudience: true,
        },
      },
      onCompleted: (data) => {
        if (!data?.authenticate?.outcome) {
          throw new Error('Unexpected mutation response after authenticating.');
        }

        // Update auth with the outcome of this mutation.
        // This ensures all future requests are made with the correct auth headers.
        auth.updateRequiredAuth(data.authenticate.outcome);

        if (codeInputError) {
          setCodeInputError(null);
        }

        dispatch(clearSingleReactHookForm('enhancedAuthPasswordForm'));

        goTo('AUTH_APP_SETUP');
      },
      onError: (error) => {
        const authenticateError =
          error.graphQLErrors.reduce<AuthenticateErrorEnum | null>(
            (acc, graphqlError) => {
              if (graphqlError.extensions?.code === 'INCORRECT_AMFA_CODE') {
                return AuthenticateErrorEnum.IncorrectAmfaCode;
              }

              if (graphqlError.extensions?.code === 'AMFA_TOKEN_EXPIRED') {
                return AuthenticateErrorEnum.AmfaTokenExpired;
              }

              return acc;
            },
            null,
          );

        if (authenticateError === AuthenticateErrorEnum.IncorrectAmfaCode) {
          addToast({
            content: 'Incorrect code. Please try again.',
            kind: 'alert',
            duration: 'short',
          });
        } else if (
          authenticateError === AuthenticateErrorEnum.AmfaTokenExpired
        ) {
          addToast({
            content:
              'This code is expired. Select cancel to receive a new email code.',
            kind: 'alert',
            duration: 'short',
          });
        } else {
          addToast({
            content:
              'Something went wrong. Please try again or contact support.',
            kind: 'alert',
            duration: 'short',
          });
        }

        setError('code', {
          message: 'Incorrect code. Please try again.',
        });
      },
    });
  };

  const { control, handleSubmit, watch, setError, formState } =
    useForm<CollectAmfaCodeFormValues>({
      defaultValues: {
        code: '',
      },
    });

  const codeValue = watch('code');

  const loading = userEmailQueryLoading || authenticateMutationLoading;

  return (
    <Flex flexDirection="column" maxWidth={550}>
      <HM
        pt={64}
        content="Great. We emailed you a security code to enter below."
        fontWeight={300}
        onClick={back}
      />
      <PL py={16} content="The 6-digit code expires in 15 minutes." />
      <form
        name="2fa-amfa-input-form"
        onSubmit={handleSubmit(async (data) => {
          await submitForm(data.code);
        })}
      >
        <ControlledCodeInput
          control={control}
          name="code"
          value={codeValue}
          // TODO: figure out how to remove onChange from prop set
          onChange={() => {}}
          rules={{
            required: true,
            minLength: 6,
            maxLength: 6,
          }}
        />
        <Flex mt={64} gap={32}>
          <Button
            type="submit"
            label="Next"
            disabled={!formState.isValid || loading}
          />
          <Button kind="link" label="Cancel" onClick={exitFlow} />
        </Flex>
      </form>
    </Flex>
  );
};
