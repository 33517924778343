import { styled, Flex, Card, css } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';

export const StyledAppCard = styled(Card)<{ hasSiblingCards: boolean }>`
  container: appCardContainer / inline-size;

  ${({ hasSiblingCards }) =>
    hasSiblingCards &&
    css`
      display: flex;
      flex-direction: column;
      align-items: stretch;
      height: 100%;
    `}
`;

export const AppCardBodyContainer = styled(Flex)`
  justify-content: space-between;
  flex-direction: row;

  @container appCardContainer (max-width: 800px) {
    flex-direction: column;
  }
`;

export const StyledCollapseIcon = styled(Icon)`
  cursor: pointer;
`;

export const AppCardButtonContainer = styled(Flex)<{ isColumn: boolean }>`
  gap: 8px;

  @container appCardContainer (max-width: 340px) {
    flex-direction: ${({ isColumn }) => isColumn && `column`};
  }
`;
