import { styled, Flex, Box, theme, keyframes, css } from '@m1/liquid-react';

// eslint-disable-next-line no-restricted-imports
import { CSSObject } from 'styled-components';

const outCss = css`
  transform: translateX(-25%);
  opacity: 0;
`;
const inCss = css`
  transform: translateX(0);
  opacity: 1;
`;

const slideIn = keyframes`
  from {
    ${outCss}
  }
  to {
    ${inCss}
  }
`;
const slideOut = keyframes`
  from {
    ${inCss}
  }
  to {
    ${outCss}
  }
`;

export const AnimatedDataContainer = styled(Box)<{
  opacity: CSSObject['opacity'];
}>`
  animation: ${({ opacity }) => css`
    ${typeof opacity === 'number' && opacity > 0.5
      ? slideIn
      : slideOut} 0.5s ease-out forwards
  `};
  transition: opacity 0.2s ease-in-out;
`;

export const BorderLeftDataContainer = styled(Box)<{
  opacity: CSSObject['opacity'];
}>`
  border-left: 1px solid ${theme.colors.borderFeature};
  opacity: ${({ opacity }) => opacity};
  padding-left: 32px;

  @media (max-width: ${theme.breakpoints.MEDIUM}) {
    border-left: none;
    padding-left: 0;
  }
`;

export const MainDataSetContainer = styled(Box)`
  margin-top: -20px;
  width: calc(33%);
`;

export const TabularDataContainer = styled(Flex)`
  @media (max-width: ${theme.breakpoints.MEDIUM}) {
    flex-direction: column;
  }
`;

export const SecondaryDataSetContainer = styled(Flex)`
  @media (max-width: ${theme.breakpoints.MEDIUM}) {
    margin-top: 24px;
  }
`;

export const StyledDataSetContainer = styled(Box)<{
  opacity: CSSObject['opacity'];
}>`
  opacity: ${({ opacity }) => opacity};
  transition: opacity 0.2s ease-in-out;
`;

export const LandingPageStaticHeader = styled(Flex)<{
  opacity: CSSObject['opacity'];
}>`
  min-height: 72px;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid ${theme.colors.borderFeature};
    z-index: -1;
    opacity: ${({ opacity }) => opacity};
    transition: opacity 0.2s ease-in-out;
  }
`;

export const LandingPageDivider = styled(Box)`
  margin-top: 20px;
  border-bottom: 1px solid ${theme.colors.borderFeature};
`;

export const LandingPageHeaderContentContainer = styled(Flex)<{
  isCompact: boolean;
}>`
  align-items: center;

  ${({ isCompact }) =>
    isCompact &&
    css`
      @media (max-width: ${theme.breakpoints.MEDIUM}) {
        flex-wrap: wrap;
        padding: 12px 0;
      }
    `}
`;

export const LandingPageHeaderSecondaryContentContainer = styled(Flex)<{
  isCompact: boolean;
}>`
  flex-basis: 100%;

  ${({ isCompact }) =>
    isCompact &&
    css`
      @media (max-width: ${theme.breakpoints.MEDIUM}) {
        padding-top: 12px;
      }
    `}
`;
