import * as React from 'react';
import CSSTransitionGroup from 'react-addons-css-transition-group';

import style from './style.module.scss';

interface CSSTransitionGroupTransitionName {
  enter: string;
  enterActive?: string | undefined;
  leave: string;
  leaveActive?: string | undefined;
  appear?: string | undefined;
  appearActive?: string | undefined;
}

type Props = {
  children?: React.ReactNode;
  enterTimeout?: number;
  leaveTimeout?: number;
  transitionName?: CSSTransitionGroupTransitionName;
};

export const TransitionGroup = ({
  enterTimeout = 200,
  leaveTimeout = 200,
  transitionName = {
    enter: style.enter,
    enterActive: style.enterActive,
    leave: style.leave,
    leaveActive: style.leaveActive,
  },
  children,
}: Props) => {
  return (
    <CSSTransitionGroup
      transitionName={transitionName}
      transitionEnterTimeout={enterTimeout}
      transitionLeaveTimeout={leaveTimeout}
    >
      {children}
    </CSSTransitionGroup>
  );
};
