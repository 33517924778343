import findIndex from 'lodash-es/findIndex';
import * as React from 'react';

import { LegacyTabs } from '~/toolbox/LegacyTabs';

import { LegacyPeriodTab } from './PeriodSelector.styled';

export type LegacyPeriodSelectorProps = {
  onClick: (...args: Array<any>) => any;
  periods: Array<{
    label: string;
    value: string;
  }>;
  size: 'tiny' | 'normal';
  value: string;
};

export const PeriodSelectorTabs = ({
  onClick,
  value,
  periods,
  size = 'normal',
}: LegacyPeriodSelectorProps) => {
  const handleClick = (value: string) => () => {
    onClick(value);
  };
  return (
    <LegacyTabs index={findIndex(periods, ['value', value])}>
      {periods.map((period) => (
        <LegacyPeriodTab
          {...period}
          key={period.value}
          $size={size}
          $isActive={value === period.value}
          onClick={handleClick(period.value)}
        />
      ))}
    </LegacyTabs>
  );
};
