import { HXS, PL, Box } from '@m1/liquid-react';
import * as React from 'react';

import { useChoosePieStepQuery } from '~/graphql/hooks';
import { Spinner } from '~/toolbox/spinner';

import { CryptoCreatePieCard } from './CryptoCreatePieCard';
import { CryptoQuickClickPiesSection } from './CryptoQuickClickPiesSection';
import { CryptoUserPiesSection } from './CryptoUserPiesSection';

type CryptoChoosePieStepProps = {
  onFinishStep?: (pieId: string) => void;
};

export const CryptoChoosePieStep = ({
  onFinishStep,
}: CryptoChoosePieStepProps) => {
  const { data, loading } = useChoosePieStepQuery({
    nextFetchPolicy: 'no-cache',
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  const crypto = data?.viewer?.crypto;
  const userPies = data?.viewer?.userPies;

  return (
    <Box maxWidth={792} mx="auto">
      <HXS
        content="Your account is open. Get started by adding your first Pie."
        mb={8}
      />
      <PL
        color="foregroundNeutralMain"
        content="You'll be able to edit your Pie if your strategy changes."
        mb={16}
      />
      <CryptoCreatePieCard />
      <CryptoQuickClickPiesSection crypto={crypto} />
      <CryptoUserPiesSection userPies={userPies} onFinishStep={onFinishStep} />
    </Box>
  );
};
