import { Box, Flex, LM } from '@m1/liquid-react';
import isNil from 'lodash-es/isNil';
import * as React from 'react';

import {
  FundLimitOptionInput,
  SecurityLimitOptionInput,
} from '~/graphql/types';
import { getEnumDescription } from '~/utils';

import { LimitInput } from './input';

export type LimitChange = (
  limit: SecurityLimitOptionInput | FundLimitOptionInput,
) => void;

export type LimitProps = {
  format?: 'numeric' | 'currency';
  label?: string | (React.ReactElement<any> | null | undefined);
  limit: SecurityLimitOptionInput | FundLimitOptionInput;
  onLimitChange: LimitChange;
};

const getLabelContentForLimitType = (limitType: string): string => {
  const securityDesc = getEnumDescription(limitType, 'SecurityLimitTypeEnum');

  if (securityDesc !== '') {
    return securityDesc;
  }

  return getEnumDescription(limitType, 'FundLimitTypeEnum');
};

export const Limit = ({
  format = 'numeric',
  label,
  limit,
  onLimitChange,
}: LimitProps) => {
  const labelContent = isNil(label)
    ? getLabelContentForLimitType(limit.type)
    : label;

  const handleChange = React.useCallback(
    (field: string, value: number | null | undefined): void => {
      const newLimit = {
        [field]: value,
      };

      onLimitChange({
        ...limit,
        ...newLimit,
      });
    },
    [onLimitChange, limit],
  );

  return (
    <Box py={10}>
      <LM color="foregroundNeutralMain" content={labelContent} pb={8} />
      <Flex>
        <LimitInput
          label="Min."
          name="min"
          value={limit.min}
          format={format}
          onChange={(value: number | null | undefined): void => {
            handleChange('min', value);
          }}
        />
        <Flex alignItems="center" justifyContent="center" inline mx={16}>
          -
        </Flex>
        <LimitInput
          label="Max."
          name="min"
          value={limit.max}
          format={format}
          onChange={(value: number | null | undefined): void => {
            handleChange('max', value);
          }}
        />
      </Flex>
    </Box>
  );
};
