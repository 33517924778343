import * as React from 'react';

import {
  Controller,
  ControllerProps,
  FieldValues,
  Path,
} from 'react-hook-form';

import { Checkbox, CheckboxProps } from '~/toolbox/checkbox';

type CheckboxFieldProps<
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues = FieldValues,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  CheckboxProps & {
    transformInput?: (v: React.ChangeEvent<HTMLInputElement>) => any;
  };

export const ControlledCheckbox = <
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues,
>({
  control,
  name,
  rules,
  transformInput,
  ...inputProps
}: CheckboxFieldProps<TName, TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, ref, ...field } }) => (
        <Checkbox
          name={name}
          label={inputProps.label}
          my={8}
          checked={Boolean(value)}
          onChange={(v) => {
            field.onChange(v);
            inputProps?.onChange?.(v);
          }}
          onBlur={field.onBlur}
        />
      )}
    />
  );
};
