import { PL, PM } from '@m1/liquid-react';
import React from 'react';

import { AnnouncementFragment } from '~/graphql/types';
import { AppImage } from '~/lens-toolbox/AppImage';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';

import {
  StyledIcon,
  StyledContent,
  StyledCloseIcon,
} from './StyledAnnouncementCard';

export const WideAnnouncement = ({
  announcement,
  handleClick,
}: {
  announcement: AnnouncementFragment;
  handleClick: () => void;
}) => {
  const { title, description, announcementIcon, appLink, timeDescription } =
    announcement;

  return (
    <>
      {announcementIcon && (
        <StyledIcon>
          <AppImage appImage={announcementIcon} />
        </StyledIcon>
      )}
      <StyledContent>
        <PL content={title} fontWeight={600} />
        {description && (
          <PM mt={4}>
            {description}
            {appLink && (
              <>
                {' '}
                <LinkableLink linkable={appLink} />
              </>
            )}
          </PM>
        )}
        {timeDescription && (
          <PM content={timeDescription} fontStyle="italic" mt={8} />
        )}
      </StyledContent>
      <div>
        <StyledCloseIcon
          data-testid="dismiss-wide-announcement"
          name="close16"
          onClick={handleClick}
        />
      </div>
    </>
  );
};
