import { styled, HM, PM, Flex, PS, PL, theme, HXS } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import React from 'react';

import { useIsPhoneDevice } from '~/pages/personal-loan-direct/utils/isPhoneDevice';

const Container = styled(Flex)`
  border-radius: 12px;
  border: ${(props) => `1px solid ${props.theme.colors.borderMain}`};
  display: flex;
  white-space: nowrap;
  cursor: pointer;
  margin-bottom: 16px;

  @media screen and (min-width: ${theme.breakpoints.XSMALL}) {
    min-width: 415px;
  }

  @media screen and (max-width: ${theme.breakpoints.XSMALL}) {
    margin-bottom: 8px;
  }

  &:hover {
    background-color: ${(props) =>
      props.theme.colors.backgroundNeutralTertiary};
  }
`;

const RadioContainer = styled(Flex)`
  min-width: 40px;
  border-right: ${(props) => `1px solid ${props.theme.colors.borderMain}`};
  justify-content: center;
  align-items: center;
`;

const OfferDetailsContainer = styled.div`
  padding: 16px;
`;

const MonthlyPaymentContainer = styled(Flex)`
  align-items: flex-end;
  margin-bottom: 16px;
`;

interface OfferCardProps {
  id: string;
  formattedAmount: string;
  formattedMonthlyPayment: string;
  selectedOfferId: string | null;
  formattedMonthlyDuration: string;
  formattedAnnualPercentageInfo: string;
  handleSelectOffer: (id: string) => void;
}

export const OfferCard = ({
  id,
  selectedOfferId,
  formattedAmount,
  formattedMonthlyPayment,
  formattedMonthlyDuration,
  formattedAnnualPercentageInfo,
  handleSelectOffer,
}: OfferCardProps) => {
  const isPhoneDevice = useIsPhoneDevice();

  return (
    <Container
      backgroundColor="backgroundNeutralMain"
      onClick={() => handleSelectOffer(id)}
    >
      <RadioContainer>
        {selectedOfferId === id ? (
          <Icon name="radioActive24" color="teal04" />
        ) : (
          <Icon name="radioEmpty24" color="teal04" />
        )}
      </RadioContainer>

      <OfferDetailsContainer>
        {/* TODO: add pill once BE and Lens can deliver */}
        {/* <PillTextContainer>
          <PS content="Lowest monthly payment" fontWeight={600} />
        </PillTextContainer> */}
        {isPhoneDevice && (
          <MonthlyPaymentContainer>
            <HM content={formattedMonthlyPayment} />
            <PM
              content="Monthly payment"
              ml={8}
              mb={4}
              color="foregroundNeutralSecondary"
            />
          </MonthlyPaymentContainer>
        )}

        <Flex>
          {!isPhoneDevice && (
            <Flex flexDirection="column" mr={32}>
              <HXS content={formattedMonthlyPayment} />
              <PS
                content="Monthly payment"
                color="foregroundNeutralSecondary"
              />
            </Flex>
          )}

          <Flex alignItems="flex-end" mb={4}>
            <Flex flexDirection="column" mr={16}>
              <PL content={formattedAmount} />
              <PS content="Loan amount" color="foregroundNeutralSecondary" />
            </Flex>

            <Flex flexDirection="column" mr={16}>
              <PL content={formattedAnnualPercentageInfo} />
              <PS content="APR" color="foregroundNeutralSecondary" />
            </Flex>

            <Flex flexDirection="column" mr={16}>
              <PL content={formattedMonthlyDuration} />
              <PS content="Term" color="foregroundNeutralSecondary" />
            </Flex>
          </Flex>
        </Flex>
      </OfferDetailsContainer>
    </Container>
  );
};
