import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import {
  HasUserOnboardedSagaDocument,
  HasUserOnboardedSagaQueryResult,
} from '~/graphql/hooks';

import { setProfileCreated, setUserHasOnboarded } from '~/redux/actions';

import { apolloQuerySaga } from '../apolloQuerySaga';

export function* hasUserOnboarded(): SagaIterator<boolean> {
  const { data }: HasUserOnboardedSagaQueryResult = yield call(
    apolloQuerySaga,
    {
      query: HasUserOnboardedSagaDocument,
    },
  );

  if (!data?.viewer || !data.viewer.user) {
    throw new Error('Unable to determine whether the user has onboarded.');
  }

  const hasOnboarded = data.viewer.user.hasOnboarded;
  const hasProfile = Boolean(data.viewer.profile);

  yield put(setProfileCreated(hasProfile));
  yield put(setUserHasOnboarded(hasOnboarded));
  yield put({
    type: 'SET_HAS_PRODUCT',
    payload: data.viewer.user.hasProduct,
  });

  return hasOnboarded;
}
