import { Box, Button, HXS, ModalContent, PL } from '@m1/liquid-react';
import * as React from 'react';

import { useSelectInvestAccountModalQuery } from '~/graphql/hooks';
import {
  InvestAccountModalAccountNodeFragment,
  InvestAccountModalSliceableNodeFragment,
  SliceableTypeEnum,
} from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNewPieEditor } from '~/hooks/useNewPieEditor';
import { useNewPieEditorEntry } from '~/pages/dashboard/wizards/pie-editor/PieEditorEntryButton';
import { investAccountSelected } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

import { Spinner } from '~/toolbox/spinner';
import { toArray } from '~/utils';

import { SelectInvestAccountListRow } from './SelectInvestAccountListRow';

type SelectInvestAccountModalContentProps = {
  sliceableId: string;
};

export const SelectInvestAccountModalContent = ({
  sliceableId,
}: SelectInvestAccountModalContentProps) => {
  const dispatch = useDispatch();
  const { showNewPieEditor } = useNewPieEditor();
  const handleNewPieEditorAdd = useNewPieEditorEntry();

  const analytics = useAnalytics();

  const [selectedAccountId, setSelectedAccountId] = React.useState<string>('');

  const { data, loading } = useSelectInvestAccountModalQuery({
    variables: {
      sliceableId,
    },
  });

  if (loading) {
    return (
      <Box p={20}>
        <Spinner />
      </Box>
    );
  }

  const sliceable = data?.sliceable as InvestAccountModalSliceableNodeFragment;

  // Instead of doing a bunch of `__typename checks` here, we just check that the
  // sliceable has a `type` field defined. If it doesn't, we know it's not
  // valid to use in this context:
  if (!('type' in (sliceable ?? {}))) {
    return null;
  }

  if (!data?.viewer) {
    return null;
  }

  const accounts = toArray(data.viewer.accounts?.edges)
    .map((edge) => edge?.node)
    .filter(Boolean) as InvestAccountModalAccountNodeFragment[];

  const handleClick = (): void => {
    analytics.recordAppAnalyticsEvent({
      name: 'm1_security_added_to_portfolio',
      customParameters: [
        {
          name: 'sliceable_type',
          value: sliceable.type,
        },
      ],
      valueParameter: null,
      customBoolParameters: [],
      customNumberParameters: [],
    });

    if (selectedAccountId !== '') {
      if (showNewPieEditor) {
        handleNewPieEditorAdd({
          pieEditorRouteParam: selectedAccountId,
          isCrypto: false,
          withAddedSlice: {
            id: sliceable.id,
            isPie: [
              SliceableTypeEnum.UserPie,
              SliceableTypeEnum.SystemPie,
              SliceableTypeEnum.CryptoSystemPie,
            ].includes(sliceable.type),
            symbolOrName: sliceable.name,
          },
        });
      } else {
        dispatch(investAccountSelected(selectedAccountId));
      }
    }
  };

  return (
    <ModalContent>
      <HXS>Select Invest Account</HXS>
      <PL pt={8}>
        Which account would you like to add <b>{sliceable.name}</b> to?
        {showNewPieEditor &&
          ' It will be added to a pie in the root portfolio by itself, which you can then move anywhere.'}
      </PL>

      {accounts.length !== 0 && (
        <Box px={0} pt={16} pb={32}>
          {accounts.map((account, index) => (
            <SelectInvestAccountListRow
              key={account.id}
              account={account}
              sliceable={sliceable}
              zIndex={accounts.length - index}
              selectedAccountId={selectedAccountId}
              onSelect={setSelectedAccountId}
            />
          ))}
        </Box>
      )}

      <Button
        kind="primary"
        size="large"
        fullWidth
        disabled={selectedAccountId === ''}
        label="Add"
        onClick={handleClick}
      />
    </ModalContent>
  );
};
