import { Box, HXS, PL } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import React from 'react';

import { AnnouncementContext } from '~/components/announcement';
import { useReferralsQuery } from '~/graphql/hooks';
import { AnnouncementContextEnum } from '~/graphql/types';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { Grid } from '~/toolbox/grid';

import { HasExistingReferralLinkContainer } from './components/HasExistingReferralLink';
import { NoExistingReferralLinkContainer } from './components/NoExistingReferralLink';

export const ReferralsPageLayout = () => {
  const { data, loading } = useReferralsQuery();

  if (loading) {
    return null;
  }

  if (!data?.viewer.user?.referrals) {
    return <p>Error fetching referrals information. Please try again later.</p>;
  }

  const announcement = data.viewer.announcements?.forReferrals;

  const referrals = data.viewer.user.referrals;
  const {
    referrerStatus,
    isParticipating,
    referralsDescription,
    referralsTermsLink,
    referralsTitle,
  } = referrals;

  const hasInvestAccount = data.viewer.invest?.hasActiveInvestAccount ?? false;

  return (
    <Grid
      constrain
      style={{
        padding: '16px 0',
      }}
    >
      {announcement && (
        <Grid.Row>
          <Grid.Col xs={12}>
            <AnnouncementContext
              announcement={announcement}
              context={AnnouncementContextEnum.Referrals}
            />
          </Grid.Col>
        </Grid.Row>
      )}

      <Grid.Row xsCenter xsTextCenter>
        <Grid.Col xs={7}>
          <Box pt={50} pb={50}>
            <Illustration name="referAndEarn" width={300} />
          </Box>
          <HXS content={referralsTitle} />
          <PL my={16} content={referralsDescription} />
          {referralsTermsLink && <LinkableLink linkable={referralsTermsLink} />}
          {isParticipating ? (
            <HasExistingReferralLinkContainer referrerStatus={referrerStatus} />
          ) : (
            <NoExistingReferralLinkContainer
              hasInvestAccount={hasInvestAccount}
            />
          )}
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};
