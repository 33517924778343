import React from 'react';
import { useDispatch } from 'react-redux';

import { FullyPaidLendingDisclosure } from '~/components/FullyPaidLendingDisclosure';

import { useInvestFullyPaidLendingDisclosureQuery } from '~/graphql/hooks';
import { OnlineAccountRegistrationEnum } from '~/graphql/types';
import { setFullyPaidLendingStatus } from '~/redux/actions/newFlows/investOnboarding/investOnboardingActions';

type FullyPaidLendingDisclosureProps = {
  accountRegistration: OnlineAccountRegistrationEnum;
};

export const InvestFullyPaidLendingDisclosure = ({
  accountRegistration,
}: FullyPaidLendingDisclosureProps) => {
  const dispatch = useDispatch();

  const { data, loading } = useInvestFullyPaidLendingDisclosureQuery({
    variables: {
      accountRegistration: accountRegistration as OnlineAccountRegistrationEnum,
    },
  });

  const disclosure = data?.viewer.invest?.fullyPaidLendingDisclosure;

  const handleDispatch = React.useCallback(
    (fplOptIn: boolean) => {
      dispatch(setFullyPaidLendingStatus(fplOptIn));
    },
    [dispatch],
  );

  if (!disclosure || loading) {
    return null;
  }

  return (
    <FullyPaidLendingDisclosure
      disclosure={disclosure}
      handleDispatch={handleDispatch}
    />
  );
};
