import { Box, globalColors, styled } from '@m1/liquid-react';
import * as React from 'react';

import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';
import { TransitionGroup } from '~/toolbox/transition-group';

const StyledBox = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.foregroundNeutralOnDark};
  background: rgba(${globalColors.achromaticPureBlack[0]} / 0.45);
  z-index: 301;
`;

export const GlobalLoadingSpinner = () => {
  const isWaitingOnServer = useSelector((state) => state.remote.isBusy);
  return (
    <TransitionGroup>
      {isWaitingOnServer && (
        <StyledBox>
          <Spinner
            radius={70}
            thickness={8}
            primaryColor="backgroundNeutralSecondary"
            secondaryColor="foregroundNeutralTertiary"
          />
        </StyledBox>
      )}
    </TransitionGroup>
  );
};
