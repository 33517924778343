import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { PieDetailsPage } from '~/components/pie/PieDetailsPage/PieDetailsPage';
import { useSearchParams } from '~/hooks/useSearchParams';

export const CryptoPieDetailsStep = () => {
  const [searchParams] = useSearchParams();
  const pieId = searchParams.get('pieId');

  if (!pieId) {
    return <GenericSystemError />;
  }

  return (
    <Box mt={-64} mx={-128}>
      <PieDetailsPage
        pieId={pieId}
        isCrypto
        disableAdding
        disableBreadcrumbs
        disableEditing
        disableSharing
        disablePerformance
        isAccountSetup
      />
    </Box>
  );
};
