import { Box, lightPieColors, styled, useTheme } from '@m1/liquid-react';
import * as Highcharts from 'highcharts';
import highchartsSunburst from 'highcharts/modules/sunburst';
import HighchartsReact from 'highcharts-react-official';
import * as React from 'react';

import { TreeItems } from '../../../PieEditor.types';

highchartsSunburst(Highcharts);

const gapKey = ' ';

const SunburstContainer = styled(Box)`
  .highcharts-breadcrumbs-group {
    display: none;
  }
`;

const validColors = Object.entries(lightPieColors)
  .filter(([key, _]) => {
    return key.endsWith('inactive');
  })
  .map(([_, value]) => value) as string[];

export const SunburstPie = ({ items }: { items: TreeItems }) => {
  const theme = useTheme();
  const getItemsWithGapsRecursively = (
    items: TreeItems,
    depth = 1,
  ): TreeItems => {
    return items
      .filter((item) => item.meta.type !== 'add')
      .map((item) => {
        if (item.children.length > 0) {
          return {
            ...item,
            children: getItemsWithGapsRecursively(item.children, depth + 1),
          };
        } else if (depth < maxDepth) {
          const gapChild = {
            id: crypto.randomUUID(),
            meta: {
              id: 'gap',
              name: 'gap',
              type: 'sortable',
              isSystemPie: false,
            } as const,
            percentage: 100,
            allowsChildren: false,
            children:
              depth + 1 < maxDepth
                ? getItemsWithGapsRecursively(
                    [
                      {
                        id: crypto.randomUUID(),
                        meta: {
                          id: 'gap',
                          name: 'gap',
                          type: 'sortable',
                          isSystemPie: false,
                        },
                        percentage: 100,
                        allowsChildren: false,
                        children: [],
                      },
                    ],
                    depth + 1,
                  )
                : [],
          };
          return {
            ...item,
            children: [gapChild],
          };
        } else if (depth === maxDepth) {
          return item;
        }
        return item;
      });
  };
  const getMaxDepth = (items: TreeItems, depth: number = 0): number => {
    return items.reduce((max, item) => {
      if (item.children) {
        return Math.max(max, getMaxDepth(item.children, depth + 1));
      }
      return Math.max(max, depth);
    }, depth);
  };
  const maxDepth = getMaxDepth(items) + 1;
  const itemsWithGaps = getItemsWithGapsRecursively(items);

  const flattenTreeItems = (
    items: TreeItems,
    depth: number = 1,
    parent: string | undefined = '0.0',
    indexCounter: Record<number, number> = {},
  ): Array<{
    id: string;
    parent?: string | undefined;
    name?: string;
    value?: number;
  }> => {
    return items
      .flatMap((item) => {
        // Use the indexCounter to keep track of the index at each depth level
        const id = `${depth}.${indexCounter[depth] ?? (indexCounter[depth] = 1)}`;
        indexCounter[depth]++;

        const name = String(
          item.meta.securityInfo?.symbol ?? item.meta.name ?? item.meta.id,
        );
        const value = item.percentage;

        const children =
          item.children.length > 0
            ? flattenTreeItems(item.children, depth + 1, id, indexCounter)
            : [];

        const color =
          depth === 1
            ? validColors[(validColors.length - 1) % indexCounter[depth]]
            : undefined;

        return [
          {
            id,
            parent,
            name: item.meta.id === 'gap' ? gapKey : name,
            value,
            color: item.meta.id === 'gap' ? 'transparent' : color,
          },
          ...children,
        ];
      })
      .sort((a, b) => {
        const [aDepth, aIndex] = a.id.split('.').map(Number);
        const [bDepth, bIndex] = b.id.split('.').map(Number);

        return aDepth - bDepth || aIndex - bIndex;
      })
      .filter((item) => Number(item.id.split('.')[0]) < maxDepth);
  };

  const flattenedItems = [{ id: '0.0' }, ...flattenTreeItems(itemsWithGaps)];

  const chartRef = React.useRef(null);
  const levels = Array.from({ length: maxDepth }, (_, i) => {
    return {
      level: i + 1,
      levelSize: {
        unit: 'weight',
        value: i === 0 ? 0 : Math.pow(maxDepth - i, 2),
      },
      colorVariation: {
        key: 'brightness',
        to: -0.4,
      },
    };
  });

  return (
    <SunburstContainer>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType="chart"
        options={{
          chart: {
            type: 'sunburst',
            height: '100%',
            backgroundColor: 'transparent',
            style: {
              fontFamily: 'Inter',
            },
          },
          credits: {
            enabled: false,
          },
          title: {
            text: null,
            align: 'left',
          },
          tooltip: {
            valueSuffix: '%',
            style: {
              fontSize: '14px',
              padding: '10px',
            },
            formatter: function (): boolean | string {
              // @ts-ignore Highcharts types not working
              return this.key === gapKey
                ? false
                : // @ts-ignore Highcharts types not working
                  `<span style=''><strong>${this.key}</strong><br />${this.point.value}%</span>`;
            },
          },
          plotOptions: {
            series: {
              states: {
                hover: {
                  borderColor: theme.colors.backgroundNeutralSecondary,
                },
              },
            },
          },
          series: [
            {
              type: 'sunburst',
              data: flattenedItems,
              name: 'Root',
              allowDrillToNode: true,
              borderRadius: 3,
              borderColor: theme.colors.backgroundNeutralSecondary,
              cursor: 'pointer',
              dataLabels: {
                format: '{point.name}',
                style: {
                  fontSize: '14px',
                },
              },
              levels,
            },
          ],
        }}
        ref={chartRef}
      />
    </SunburstContainer>
  );
};
