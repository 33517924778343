import { Box, Button, Flex, HXXS, PL, PM } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { GenericSystemError } from '~/components/GenericSystemError';
import {
  useProfileBeneficiariesQuery,
  useProfileReviewLazyQuery,
  useUpdateProfileMutation,
} from '~/graphql/hooks';
import { BeneficiaryInfoFragment, NewBeneficiaryInput } from '~/graphql/types';
import { withoutTypename } from '~/graphql/utils';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useToast } from '~/toasts';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { GridTable } from '~/toolbox/grid-table';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

import { BeneficiaryConfirmationModal } from './components/BeneficiaryConfirmationModal';
import { BeneficiaryModal } from './components/BeneficiaryModal';
import { BeneficiaryRow } from './components/BeneficiaryRow';

type EmptyStateProps = {
  disabled: boolean;
  onAddBeneficiary: any;
};

type RemoveBeneficiaryObject = {
  [key: string]: BeneficiaryInfoFragment;
};

const EmptyState = ({ disabled, onAddBeneficiary }: EmptyStateProps) => (
  <Flex flexDirection="column" alignItems="center">
    <Box>
      <HXXS content="You have not set up any beneficiaries yet." />
    </Box>
    <Box pt={32} pb={8}>
      <Button
        label="Add Beneficiary"
        kind="primary"
        size="large"
        onClick={onAddBeneficiary}
        disabled={disabled}
      />
    </Box>
  </Flex>
);

const DisclosureGuidance = () => (
  <Flex flexDirection="column" alignItems="center">
    <Box>
      <PM pt={32}>
        Anyone can be a named beneficiary on a transfer-on-death (TOD) for your
        taxable account or beneficiary on your IRA, including minors, non-family
        members, and entities such as trusts. The names you list must exactly
        match your beneficiaries' legal names, so please don't use nicknames.
        You must also provide the beneficiaries' SSN or EIN.
      </PM>
      <PM pt={16}>
        Transfer-on-death allows assets in your taxable Invest account to be
        passed to named beneficiaries without going through probate. Named
        beneficiaries of an IRA will inherit the IRA upon the death of the
        original IRA account holder.
      </PM>
      <PM pt={16}>
        These beneficiaries will be the default for all your accounts on M1. If
        you would like to designate a Trust or different beneficiaries when you
        have multiple accounts,{' '}
        <Link font="PM" to="/d/contact-us">
          please reach out to support.
        </Link>
      </PM>
    </Box>
  </Flex>
);

const UNSAVED_CHANGES_ERROR = 'You have unsaved changes';

export const BeneficiariesPage = () => {
  const { addToast } = useToast();
  const [sectionError, setSectionError] = React.useState<string | null>(null);
  const [beneficiariesToRemove, setBeneficiariesToRemove] =
    React.useState<RemoveBeneficiaryObject>({});
  // activeBeneficiaryIndex is the field index of the beneficiary being edited/added
  const [activeBeneficiaryIndex, setActiveBeneficiaryIndex] =
    React.useState(-1);
  // isSavingBeneficiaries triggers the SSN modal required before saving changes
  const [isSavingBeneficiaries, setIsSavingBeneficiaries] =
    React.useState(false);

  const { data, loading, refetch } = useProfileBeneficiariesQuery();
  const [fetchProfileData, { loading: isFetchingProfile }] =
    useProfileReviewLazyQuery();
  const [updateProfile, { loading: isUpdating }] = useUpdateProfileMutation();

  const isBeneficiaryMarkedForRemoval = (index: number) => {
    return Object.keys(beneficiariesToRemove).includes(index.toString());
  };

  usePortaledSpinner(isFetchingProfile || isUpdating);

  const beneficiaries = data?.viewer.profile?.beneficiaries ?? [];

  const formMethods = useForm<{
    beneficiaries: Array<BeneficiaryInfoFragment>;
  }>({ defaultValues: { beneficiaries }, mode: 'all' });

  const {
    control,
    reset: setFormValues,
    formState: { isDirty: hasUnsavedUpdates },
  } = formMethods;

  const { fields, append, update } = useFieldArray({
    control,
    name: 'beneficiaries',
  });

  // Ensures can save is true if there are unsaved changes or beneficiaries to remove and make sure there are no errors
  const canSave =
    (hasUnsavedUpdates || Object.keys(beneficiariesToRemove).length > 0) &&
    (sectionError === null || sectionError === UNSAVED_CHANGES_ERROR);

  const watchedFields = formMethods.watch('beneficiaries');
  // without this, the row fields won't be reflected in the top-level form
  // see: the "Controlled Field Array" section at `https://react-hook-form.com/docs/usefieldarray`
  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchedFields[index],
  }));

  React.useEffect(() => {
    const fields = controlledFields;
    const primaryFields = fields.filter(
      ({ isPrimary }, index) =>
        isPrimary && !isBeneficiaryMarkedForRemoval(index),
    );
    const contingentFields = fields.filter(
      ({ isPrimary }, index) =>
        !isPrimary && !isBeneficiaryMarkedForRemoval(index),
    );

    const primaryPercentage = primaryFields
      .map(({ sharePercentage }) => sharePercentage || 0)
      .reduce(
        (totalPercentage, sharePercentage) => totalPercentage + sharePercentage,
        0,
      );

    const contingentPercentage = contingentFields
      .map(({ sharePercentage }) => sharePercentage || 0)
      .reduce(
        (totalPercentage, sharePercentage) => totalPercentage + sharePercentage,
        0,
      );

    if (
      primaryFields.length > 0 &&
      (primaryPercentage < 100 || primaryPercentage > 100)
    ) {
      setSectionError(
        'Primary beneficiary share percentages should add up to 100%',
      );
    } else if (
      contingentFields.length > 0 &&
      (contingentPercentage < 100 || contingentPercentage > 100)
    ) {
      setSectionError(
        'Contingent beneficiary share percentages should add up to 100%',
      );
    } else {
      setSectionError(hasUnsavedUpdates ? UNSAVED_CHANGES_ERROR : null);
    }
  }, [controlledFields]);

  React.useEffect(
    () => setFormValues({ beneficiaries }),
    [beneficiaries, setFormValues],
  );

  function addNewBeneficiary() {
    setActiveBeneficiaryIndex(fields.length);
  }

  async function saveBeneficiaries(beneficiaries: NewBeneficiaryInput[]) {
    const { data } = await fetchProfileData();

    const beneficiariesToSubmit = beneficiaries
      .filter((_, i) => {
        const beneficiaryToRemove = beneficiariesToRemove[i];
        return (
          !beneficiaryToRemove ||
          beneficiaryToRemove.firstName !== _.beneficiary.firstName ||
          beneficiaryToRemove.lastName !== _.beneficiary.lastName ||
          beneficiaryToRemove.dateOfBirth !== _.beneficiary.dateOfBirth
        );
      })
      .map((b) => withoutTypename<NewBeneficiaryInput>(b));

    if (!data?.viewer.profile?.primary) {
      addToast({
        content:
          'Something went wrong! If the problem persists, please contact support.',
        kind: 'alert',
        duration: 'long',
      });
    } else {
      const { primary, secondary } = data.viewer.profile;
      updateProfile({
        variables: {
          input: {
            profile: {
              primary,
              secondary,
              beneficiaries: beneficiariesToSubmit,
            },
          },
        },
        onCompleted() {
          addToast({
            content: 'Updated beneficiaries',
            kind: 'success',
            duration: 'long',
          });
          setSectionError(null);
          setBeneficiariesToRemove({});
          refetch();
        },
        onError(err) {
          addToast({
            content: err.message,
            kind: 'alert',
            duration: 'long',
          });
        },
      });
    }
  }

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.viewer.profile) {
    return (
      <GenericSystemError content="Could not load beneficiaries. Please try again later." />
    );
  }

  return (
    <>
      <BeneficiaryModal
        open={activeBeneficiaryIndex > -1}
        beneficiary={controlledFields[activeBeneficiaryIndex]}
        onClose={() => setActiveBeneficiaryIndex(-1)}
        onSubmit={(beneficiary: BeneficiaryInfoFragment) =>
          activeBeneficiaryIndex < fields.length
            ? update(activeBeneficiaryIndex, beneficiary)
            : append(beneficiary)
        }
      />
      <BeneficiaryConfirmationModal
        open={isSavingBeneficiaries}
        beneficiaries={controlledFields.filter(
          (_, i) => !isBeneficiaryMarkedForRemoval(i),
        )}
        onClose={() => setIsSavingBeneficiaries(false)}
        onSubmit={saveBeneficiaries}
      />
      {controlledFields.length === 0 && !hasUnsavedUpdates ? (
        <EmptyState onAddBeneficiary={addNewBeneficiary} disabled={false} />
      ) : (
        <Flex flexDirection="column">
          <Flex flexDirection="column">
            <Flex flexDirection="row" justifyContent="space-between">
              <Box>
                <PL>
                  Add and/or remove all of your desired beneficiaries before
                  clicking "Save".
                </PL>
              </Box>
              <ButtonGroup>
                <Button
                  label="Add beneficiary"
                  kind="secondary"
                  size="medium"
                  onClick={addNewBeneficiary}
                />
                <Button
                  label="Save"
                  kind="secondary"
                  size="medium"
                  disabled={!canSave}
                  onClick={() =>
                    controlledFields.length > 0
                      ? setIsSavingBeneficiaries(true)
                      : saveBeneficiaries([])
                  }
                />
              </ButtonGroup>
            </Flex>
            <Box alignSelf="flex-end" my={16}>
              {sectionError ? (
                <Flex alignItems="center">
                  <Icon
                    name="alert16"
                    display="inline-block"
                    color="critical"
                    mr={5}
                  />
                  <PL color="critical">{sectionError}</PL>
                </Flex>
              ) : null}
            </Box>
          </Flex>
          <GridTable
            gridTemplateColumns="minmax(160px, 1fr) minmax(100px, 2fr) 160px 100px 66px"
            isNewStyle
          >
            <GridTable.HeaderRow>
              <GridTable.HeaderCell label="Name" />
              <GridTable.HeaderCell label="Address" />
              <GridTable.HeaderCell label="Type" />
              <GridTable.HeaderCell label="Share %" />
              <GridTable.HeaderCell label="&nbsp;" />
            </GridTable.HeaderRow>
            {controlledFields.map((field, index) => (
              <BeneficiaryRow<{ beneficiaries: BeneficiaryInfoFragment[] }>
                key={field.id}
                name={`beneficiaries.${index}`}
                beneficiary={field}
                formMethods={formMethods}
                isRemoved={isBeneficiaryMarkedForRemoval(index)}
                onEditBeneficiaryInfo={() => setActiveBeneficiaryIndex(index)}
                onRemoveBeneficiary={() => {
                  setBeneficiariesToRemove({
                    ...beneficiariesToRemove,
                    [index]: beneficiaries[index],
                  });
                }}
                onRestoreBeneficiary={() => {
                  setBeneficiariesToRemove(() => {
                    const newBeneficiariesToRemove: RemoveBeneficiaryObject = {
                      ...beneficiariesToRemove,
                    };
                    delete newBeneficiariesToRemove[index];
                    return newBeneficiariesToRemove;
                  });
                }}
              />
            ))}
          </GridTable>
        </Flex>
      )}
      <DisclosureGuidance />
    </>
  );
};
