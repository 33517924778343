import * as React from 'react';
import { Route } from 'react-router-dom';

import { PortfolioOrganizerPage } from '~/components/PortfolioOrganizerPage';

export const PortfolioOrganizerRoute = () => {
  return (
    <Route path="portfolio-organizer" element={<PortfolioOrganizerPage />} />
  );
};
