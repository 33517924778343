import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { usePersonalLoansPageQuery } from '~/graphql/hooks';
import { useLayout } from '~/hooks/useLayout';
import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';
import { StyledPageContent } from '~/pages/dashboard/navigation/Navigation.styled';
import { setBorrowAccountDestination } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { PersonalLoansNotEligiblePage } from './not-eligible/PersonalLoansNotEligiblePage';

export const PersonalLoansPage = () => {
  const { contentWidth } = useLayout();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, loading } = usePersonalLoansPageQuery({
    onCompleted: (d) => {
      if (location.pathname === '/d/borrow/personal') {
        if (!d.viewer.borrow?.hasActivePersonalLoan) {
          navigate({
            to: '/d/borrow/marketing',
          });
        } else {
          navigate({
            to: '/d/borrow/personal/manage-loan',
          });
        }
      }
    },
  });

  const inPersonalLoanProductLandingPage =
    location.pathname.includes('transactions');

  React.useEffect(() => {
    dispatch(setBorrowAccountDestination('personal'));
  }, [dispatch]);

  if (loading) {
    return <Spinner fullScreen centered />;
  }

  // This is to lock the "borrow/personal" URLs from anyone without the flag
  if (!data?.viewer.borrow?.isEligibleToApplyForPersonalLoan) {
    return <PersonalLoansNotEligiblePage />;
  }

  return inPersonalLoanProductLandingPage ? (
    <Outlet />
  ) : (
    <StyledPageContent contentWidth={contentWidth}>
      <Outlet />
    </StyledPageContent>
  );
};
