import { Button, HM, Box } from '@m1/liquid-react';
import * as React from 'react';
import { InjectedFormProps } from 'redux-form';

import { FormMocker } from '~/forms/FormMockers/FormMocker';
import { connectForm } from '~/hocs';

import { formatCurrencyWithCommasOrNull } from '~/utils/formatting';

import { normalizeStringAsNumber } from '~/utils/normalizers';

import { TextField } from './fields';
import { requireNonNil } from './validators';

const IncomeFormComponent = ({ handleSubmit }: InjectedFormProps<any>) => {
  return (
    <Box maxWidth={588} mx="auto">
      <form onSubmit={handleSubmit}>
        <HM content="How much do you make before taxes each year?" py={32} />
        <Box marginTop={32}>
          <TextField
            autoFocus
            format={formatCurrencyWithCommasOrNull}
            name="annualIncomeAmount"
            label="Yearly income"
            normalize={normalizeStringAsNumber}
            validate={[requireNonNil]}
            maxLength={11}
            minOf={0}
          />
        </Box>
        <Box marginTop={48}>
          <Button kind="primary" size="large" label="Continue" type="submit" />
          <FormMocker
            formName="income-form"
            fields={[
              {
                name: 'annualIncomeAmount',
                value: 150000,
              },
            ]}
          />
        </Box>
      </form>
    </Box>
  );
};

export const IncomeForm = connectForm({
  form: 'income-form',
})(IncomeFormComponent);
