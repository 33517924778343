import { Text } from '@m1/liquid-react';
import * as React from 'react';
import { components } from 'react-select';

import { withProps } from '~/hocs';
import { DropdownOption } from '~/toolbox/Dropdown';

import { requireNonNil } from '../validators';

import { DropdownField } from './dropdown-field';

type Props = {
  disabled: boolean;
  options?: Array<Record<string, any>> | null | undefined;
};

class Placeholder extends React.Component<Props> {
  static defaultProps = {
    disabled: false,
  };

  render() {
    return (
      // @ts-expect-error - TS2740 - Type '{ children: Element; disabled: boolean; options?: Record<string, any>[] | null | undefined; }' is missing the following properties from type 'CommonProps<any>': clearValue, cx, getStyles, getValue, and 5 more. | TS2786 - 'components.Placeholder' cannot be used as a JSX component.
      <components.Placeholder {...this.props}>
        {/* @ts-expect-error - TS2322 - Type '{ color: string; content: string; } | { content: string; color?: undefined; }' is not assignable to type 'IntrinsicAttributes & Omit<TextProps, DisallowedTextPropsKeys> & { color?: "success" | "warning" | "grey00" | ... 96 more ... | undefined; } & Partial<...>'. */}
        <Text {...this.readTextProps()} />
      </components.Placeholder>
    );
  }

  readTextProps() {
    const { options } = this.props;
    const hasOptionsLoaded = Boolean(options);

    if (!hasOptionsLoaded) {
      return {
        color: 'foregroundNeutralMain',
        content: 'Loading...',
      };
    }
    return {
      content: 'Select',
    };
  }
}
const enhancer = withProps((props) => {
  // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type '{ isEvenWeek: any; }'.
  const options = readIsEvenWeeksOptions(props);
  return {
    components: {
      Placeholder,
    },
    disabled: isDisabled({
      // @ts-expect-error - TS2698 - Spread types may only be created from object types.
      ...props,
      options,
    }),
    label: 'Start',
    name: 'isEvenWeeks',
    normalize: Boolean,
    options: options,
    validate: [requireNonNil],
  };
});

function readIsEvenWeeksOptions({
  // @ts-expect-error - TS7031 - Binding element 'isEvenWeek' implicitly has an 'any' type.
  isEvenWeek,
}): Array<DropdownOption> | null | undefined {
  return [
    {
      value: isEvenWeek,
      label: 'This week',
    },
    {
      value: !isEvenWeek,
      label: 'Next week',
    },
  ];
}

// @ts-expect-error - TS7031 - Binding element 'disabled' implicitly has an 'any' type. | TS7031 - Binding element 'options' implicitly has an 'any' type.
function isDisabled({ disabled, options }): boolean {
  return Boolean(disabled || !(Array.isArray(options) && options.length > 0));
}

export const BiweeklyIsEvenWeeksDropdownField = enhancer(DropdownField) as any;
