import moment from 'moment';

import {
  TimeIntervalEnum,
  TimeLengthUnitEnum,
  HistoricalQuotePeriodEnum,
} from '~/graphql/types';

type TimeRangeInput = {
  end?: string;
  inclusive?: boolean;
  length?: {
    amount: number;
    unit: TimeLengthUnitEnum;
  };
  start?: string;
};

export const PERIOD_TO_LENGTH: Partial<
  Record<HistoricalQuotePeriodEnum, TimeRangeInput['length']>
> = {
  [HistoricalQuotePeriodEnum.YearToDate]: {
    amount: moment().dayOfYear(),
    unit: TimeLengthUnitEnum.Days,
  },
  [HistoricalQuotePeriodEnum.OneMonth]: {
    amount: 1,
    unit: TimeLengthUnitEnum.Months,
  },
  [HistoricalQuotePeriodEnum.OneYear]: {
    amount: 1,
    unit: TimeLengthUnitEnum.Years,
  },
  [HistoricalQuotePeriodEnum.TwoYears]: {
    amount: 2,
    unit: TimeLengthUnitEnum.Years,
  },
  [HistoricalQuotePeriodEnum.FiveYears]: {
    amount: 5,
    unit: TimeLengthUnitEnum.Years,
  },
};

export const PERIOD_TO_INTERVAL: Partial<
  Record<HistoricalQuotePeriodEnum, TimeIntervalEnum>
> = {
  [HistoricalQuotePeriodEnum.YearToDate]: TimeIntervalEnum.Day,
  [HistoricalQuotePeriodEnum.OneMonth]: TimeIntervalEnum.Month,
  [HistoricalQuotePeriodEnum.FiveYears]: TimeIntervalEnum.Quarter,
};
