import * as React from 'react';

import { FormProvider } from 'react-hook-form';

import { useWizard } from '~/flows/wizard/useWizard';
import { WizardProvider } from '~/flows/wizard/WizardProvider';
import { useGenerateIdempotencyKeyMutation } from '~/graphql/hooks';
import { useLocation } from '~/hooks/useLocation';
import { useSentry } from '~/hooks/useSentry';
import { beginInitialFundingFlow } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

import { useInitialFundingForm } from './hooks/useInitialFundingForm';
import { AdditiveMakeInitialDepositStep } from './steps/AdditiveMakeInitialDepositStep';
import { ConnectExternalBankStep } from './steps/ConnectExternalBankStep';
import { InitialFundingDepositConfirmationStep } from './steps/InitialFundingDepositConfirmationStep';
import { InitialFundingIntroductionStep } from './steps/InitialFundingIntroductionStep';
import { SkippedInitialDepositStep } from './steps/SkippedInitialDepositStep';

export type InitialFundingFlowSteps =
  | 'BEGIN_INITIAL_FUNDING'
  | 'CONNECT_EXTERNAL_BANK'
  | 'DEPOSIT_CONFIRMATION'
  | 'MAKE_INITIAL_DEPOSIT'
  | 'SKIPPED_DEPOSIT_CONFIRMATION';

// TODO: Do we need the rest of the props?
export type InitialFundingFlowProps = {
  accountId: string;
  achRelationshipId: string | null | undefined;
  basePath: string | null | undefined;
  onFinish: () => void;
};

export const InitialFundingFlow = ({ basePath }: InitialFundingFlowProps) => {
  const sentry = useSentry();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const formMethods = useInitialFundingForm();
  const [generateIdempotencyKey] = useGenerateIdempotencyKeyMutation({});

  const steps: Record<InitialFundingFlowSteps, React.ReactElement> = {
    BEGIN_INITIAL_FUNDING: <InitialFundingIntroductionStep />,
    CONNECT_EXTERNAL_BANK: (
      <ConnectExternalBankStep basePath={basePath ?? ''} />
    ),
    MAKE_INITIAL_DEPOSIT: <AdditiveMakeInitialDepositStep />,
    DEPOSIT_CONFIRMATION: <InitialFundingDepositConfirmationStep />,
    SKIPPED_DEPOSIT_CONFIRMATION: <SkippedInitialDepositStep />,
  };

  const { step, goTo, stepKey, ...rest } = useWizard(steps);

  React.useEffect(() => {
    const fn = async () => {
      const { data } = await generateIdempotencyKey({
        variables: { input: {} },
      });

      if (!data?.generateIdempotencyKey?.outcome?.idempotencyKey) {
        sentry.message('generateIdempotencyKey mutation failed', {});
      } else {
        dispatch({
          type: 'SET_IDEMPOTENCY_KEY',
          payload: data.generateIdempotencyKey.outcome.idempotencyKey,
        });
      }
    };

    fn();

    // This is solely for redux state to remain in tact with relevant info
    // TODO: future case, post-redux, is to utilize the wizard context for state management
    dispatch(beginInitialFundingFlow({ basePath }));
  }, [basePath, dispatch, generateIdempotencyKey, sentry]);

  // The handler that triggers the SKIPPED_DEPOSIT_CONFIRMATION resides outside of the wizard flow
  // So we have to use the location to determine whether this has been navigated to or not
  React.useEffect(() => {
    if (
      /skipped-deposit-confirmation/.test(pathname) &&
      stepKey !== 'SKIPPED_DEPOSIT_CONFIRMATION'
    ) {
      goTo('SKIPPED_DEPOSIT_CONFIRMATION');
    }
  }, [goTo, pathname, stepKey]);

  return (
    <FormProvider {...formMethods}>
      <WizardProvider value={{ step, goTo, stepKey, ...rest }}>
        {step}
      </WizardProvider>
    </FormProvider>
  );
};
