import { globalColors, ResolvedThemeMode, Theme } from '@m1/liquid-react';
import { DashStyleValue } from 'highcharts/highstock';
import capitalize from 'lodash-es/capitalize';
import React from 'react';

import { useStableReference } from '~/hooks/useStableReference';

import { AnalyticsReporter } from '~/loggers';
import { CompactCashFormatter } from '~/utils';

import {
  SeriesTypes,
  StockChartFeatures,
  StockChartPeriodDuration,
  StockChartProps,
} from './StockChart.types';

export const useStockChartFeature = (initialFeatures: StockChartFeatures) => {
  const [featureState, setFeatureState] =
    React.useState<StockChartFeatures>(initialFeatures);
  const memoizedFeatures = useStableReference(initialFeatures);

  const updateFeatureState = React.useCallback(
    (property: keyof StockChartFeatures) => {
      setFeatureState((prevState) => ({
        ...prevState,
        [property]: !prevState[property],
      }));
    },
    [],
  );

  const supportsFeature = React.useCallback(
    (property: keyof StockChartFeatures) => {
      // Some features have options that enable partial support (e.g. navigator can be toggled or disabled entirely)
      return property in memoizedFeatures;
    },
    [memoizedFeatures],
  );

  const isFeatureEnabled = React.useCallback(
    (property: keyof StockChartFeatures) => {
      return Boolean(supportsFeature(property) && featureState[property]);
    },
    [featureState, supportsFeature],
  );

  return {
    featureState,
    updateFeatureState,
    supportsFeature,
    isFeatureEnabled,
  };
};

export const periodDurationToRangeSelectorButton = ({
  periodDuration,
  analytics,
  chartName,
  onChangePeriod,
}: {
  periodDuration: StockChartPeriodDuration;
  analytics?: AnalyticsReporter;
  chartName: string;
  onChangePeriod?: StockChartProps['onChangePeriod'];
}): Highcharts.RangeSelectorButtonsOptions => {
  const recordRangeSelectionEvent = (period: StockChartPeriodDuration) => {
    analytics?.recordEvent('range_selection_clicked', null, {
      chart: chartName,
      selection: period,
    });
  };

  const handleClick = () => {
    recordRangeSelectionEvent(periodDuration);
    onChangePeriod?.(periodDuration);
  };

  switch (periodDuration) {
    case 'ytd':
    case 'all':
      return {
        type: periodDuration,
        text: periodDuration,
        events: {
          click: handleClick,
        },
      };
    default: {
      const duration = parseInt(periodDuration, 10);
      if (isNaN(duration)) {
        // return all by default
        return {
          type: 'all',
          text: 'all',
          events: {
            click: handleClick,
          },
        };
      }
      const timePeriod = periodDuration.slice(-1);

      switch (timePeriod) {
        case 'd':
          return {
            type: 'day',
            count: duration,
            text: periodDuration,
            events: {
              click: handleClick,
            },
          };
        case 'w':
          return {
            type: 'week',
            count: duration,
            text: periodDuration,
            events: {
              click: handleClick,
            },
          };
        case 'M':
          return {
            type: 'month',
            count: duration,
            text: periodDuration,
            events: {
              click: handleClick,
            },
          };
        case 'y':
          return {
            type: 'year',
            count: duration,
            text: periodDuration,
            events: {
              click: handleClick,
            },
          };
        default:
          // return all by default
          return {
            type: 'all',
            text: 'all',
            events: {
              click: handleClick,
            },
          };
      }
    }
  }
};

export const getSeriesDatumValue = (
  datum: Maybe<NonNullable<SeriesTypes['data']>[number]>,
) => {
  if (typeof datum === 'number') {
    return { x: undefined, y: datum };
  } else if (Array.isArray(datum)) {
    return { x: Number(datum[0]), y: datum[1] };
  } else if (typeof datum === 'object') {
    return { x: datum?.x, y: datum?.y };
  }
  return null;
};

export const gradientFillColor = (
  activeThemeMode: ResolvedThemeMode,
): Highcharts.PlotAreaOptions['fillColor'] => {
  const indexOfThemeInColorsArray = Number(activeThemeMode === 'dark');
  return {
    linearGradient: {
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 1,
    },
    stops: [
      [0.58, `rgb(${globalColors.blue03[indexOfThemeInColorsArray]} / 0.24)`],
      [1, `rgb(${globalColors.blue03[indexOfThemeInColorsArray]} / 0)`],
    ],
  };
};

export const generateOptionsConfig = ({
  theme,
  isOnDarkBackground,
  isMobile,
  fullscreenOpen,
  fullscreenClose,
  isFeatureEnabled,
  afterSetExtremes,
  plotLines,
}: {
  theme: Theme;
  isOnDarkBackground: StockChartProps['isOnDarkBackground'];
  isMobile: boolean;
  isFeatureEnabled: (feature: keyof StockChartFeatures) => boolean;
  fullscreenOpen?: () => void;
  fullscreenClose?: () => void;
  afterSetExtremes?: (event: Highcharts.AxisSetExtremesEventObject) => void;
  plotLines?: StockChartProps['plotLines'];
}): Highcharts.Options => ({
  loading: {
    style: {
      opacity: 0,
    },
  },
  chart: {
    backgroundColor: 'transparent',
    panning: {
      enabled: false,
    },
    zooming: {
      mouseWheel: false,
    },
    style: {
      fontFamily: 'Inter',
    },
    events: {
      fullscreenOpen,
      fullscreenClose,
    },
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    type: 'datetime',
    lineColor: isOnDarkBackground
      ? theme.colors.foregroundNeutralTertiary
      : theme.colors.borderMain,
    minRange: 24 * 3600 * 1000,
    labels: {
      style: {
        color: isOnDarkBackground
          ? theme.colors.foregroundNeutralOnDark
          : theme.colors.foregroundNeutralSecondary,
        fontSize: '11px',
      },
    },
    crosshair: {
      color: theme.colors.foregroundSecondaryTint,
    },
    ordinal: false,
    tickColor: isOnDarkBackground
      ? theme.colors.foregroundNeutralTertiary
      : theme.colors.borderMain,
    events: {
      afterSetExtremes,
    },
  },
  // @ts-expect-error Types are wrong, dashStyle is valid according to docs
  yAxis: {
    opposite: false,
    labels: {
      formatter: ({ value }: any) => {
        return CompactCashFormatter.format(value);
      },
      style: {
        color: isOnDarkBackground
          ? theme.colors.foregroundNeutralOnDark
          : theme.colors.foregroundNeutralSecondary,
        fontSize: '12px',
      },
      align: 'center',
    },
    showLastLabel: true,
    plotLines,
    crosshair: {
      color: theme.colors.foregroundSecondaryTint,
      dashStyle: '4 4' as DashStyleValue,
    },
    gridLineColor: isOnDarkBackground
      ? theme.colors.backgroundPlotFeature
      : theme.colors.borderMain,
    lineColor: theme.colors.borderMain,
    tickColor: theme.colors.borderMain,
  },
  rangeSelector: {
    buttons: [], // range selector buttons will be initialized in an effect to allow local storage to select the default
    buttonPosition: {
      align: 'right',
    },
    buttonTheme: {
      width: 28,
      height: 28,
      r: '8px',
      fill: 'transparent',
      style: {
        color: isOnDarkBackground
          ? theme.colors.foregroundNeutralOnDark
          : theme.colors.foregroundNeutralMain,
        fontWeight: '400',
        fontSize: '12px',
        opacity: 100,
        textTransform: 'uppercase',
        textDecoration: 'none',
      },
      states: {
        hover: {
          fill: isOnDarkBackground
            ? theme.colors.backgroundPlotFeature
            : theme.colors.borderMain,
          style: {
            fontWeight: '400',
            opacity: '100%',
          },
        },
        select: {
          fill: 'none',
          style: {
            color: isOnDarkBackground
              ? theme.colors.primaryTint
              : theme.colors.primary,
            fontWeight: '600',
            opacity: '100%',
            textDecoration: 'underline',
          },
        },
        disabled: {
          style: {
            color: theme.colors.foregroundNeutralTertiary,
          },
        },
      },
    },
    dropdown: isMobile ? 'always' : 'never',
    inputEnabled: isFeatureEnabled('dateRangeInputs'),
    inputBoxBorderColor: 'gray',
    inputPosition: {
      align: 'left',
      x: 0,
    },
    inputBoxWidth: 80,
    inputBoxHeight: 18,
    inputDateFormat: '%b %e, %Y',
    inputStyle: {
      color: theme.colors.foregroundNeutralSecondary,
      borderRadius: 5,
      fontSize: '12px',
    },
    labelStyle: {
      color: theme.colors.foregroundNeutralMain,
      fontWeight: '400',
      fontSize: '12px',
    },
  },
  navigator: {
    outlineColor: theme.colors.foregroundNeutralTertiary,
    xAxis: {
      gridLineColor: theme.colors.foregroundNeutralTertiary,
    },
  },
  plotOptions: {
    series: {
      showInNavigator: true,
      lineWidth: 2,
      dataGrouping: {
        enabled: false,
      },
      marker: {
        states: {
          hover: {
            radius: 6,
            fillColor: isOnDarkBackground
              ? theme.chartColors[0].datapointFeature
              : theme.colors.blue04,
            lineWidth: 30,
            lineColor: `rgba(85, 102, 185, 0.2)`,
          },
        },
      },
    },
  },
  tooltip: {
    useHTML: true,
    // TODO turn back on once this is fixed: https://github.com/highcharts/highcharts/issues/22088
    // This will also fix the issue where the flag title is higher z-index than tooltip when useHtml is true on the flag series
    // outside: true,
    split: false,
    padding: 12,
    formatter: function () {
      // @ts-ignore this is valid
      return this.series.tooltipOptions.customTooltipPerSeries.call(this);
    },
    valueDecimals: 2,
    backgroundColor: theme.colors.backgroundNeutralSecondary,
    className: 'chartable-slice-chart-tooltip',
    shadow: {
      color: 'rgba(0, 0, 0, 0.2)',
      offsetX: 2,
      offsetY: 3,
      opacity: 0.15,
      width: 10,
    },
    style: {
      fontSize: '14px',
      opacity: isOnDarkBackground ? 1.0 : 0.85,
    },
  },
  exporting: {
    buttons: {
      contextButton: {
        useHTML: true,
        symbol: undefined,
        symbolFill: 'transparent',
        symbolStroke: undefined,
        theme: {
          fill: 'transparent',
          // @ts-ignore states is a valid key
          states: {
            hover: {
              fill: 'red',
            },
            select: {
              fill: theme.colors.backgroundNeutralMain,
            },
          },
        },
      },
    },
    csv: {
      columnHeaderFormatter: function (item: any, key: string) {
        if (key === 'x') {
          return 'Date';
        }
        if (key === 'y') {
          return 'Value';
        }
        return capitalize(key);
      },
    },
  },
  noData: {
    style: {
      fontWeight: '400',
      fontSize: '16px',
      color: theme.colors.foregroundNeutralMain,
    },
  },
});
