import { useResendEmailVerificationMutation } from '~/graphql/hooks';
import { useToast } from '~/toasts';

import { useNavigate } from './useNavigate';
import { usePortaledSpinner } from './usePortaledSpinner';

export const useResendEmailVerification = () => {
  const [resendEmailVerification, { loading }] =
    useResendEmailVerificationMutation();
  const { addToast } = useToast();
  const navigate = useNavigate();

  usePortaledSpinner(loading);

  const handleResendVerificationEmailClick = (options?: {
    pathToRedirectToOnSuccess: string;
  }) => {
    resendEmailVerification({
      variables: {
        input: {},
      },
      onCompleted: () => {
        addToast({
          content: 'Email sent',
          kind: 'success',
        });
        if (options?.pathToRedirectToOnSuccess) {
          navigate({ to: options.pathToRedirectToOnSuccess });
        }
      },
      onError: () => {
        addToast({
          content: 'Failed to resend email',
          kind: 'alert',
        });
      },
    });
  };

  return handleResendVerificationEmailClick;
};
