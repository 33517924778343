import takeWhile from 'lodash-es/takeWhile';

export const getOnlyNumbers = (value: string): string => {
  return value.replace(/\D/g, '');
};

export function compose(
  ...functions: Array<(...args: Array<any>) => any>
): (...args: Array<any>) => any {
  return (value: string | null | undefined, ...args: Array<any>) => {
    return functions.reduce((p, v) => v(p, ...args), value);
  };
}

export const normalizeNoSpaces = (value: string): string => {
  if (
    value === undefined ||
    value === null ||
    value === '' ||
    typeof value !== 'string'
  ) {
    return value;
  }

  return value.trim();
};

export function onlyNumbers(value: string | null | undefined): string {
  return value ? getOnlyNumbers(value) : '';
}

export const normalizeSSN = (value: string): string => {
  if (!value || value === '') {
    return value;
  }

  return getOnlyNumbers(value).slice(0, 9);
};

export const normalizeCurrencyWithNegativeValue = (
  value: string | null | undefined,
): string | null | undefined => {
  if (!value || value === '-') {
    return value;
  }
  const isNegative = value.charAt(0) === '-';
  const normalized = normalizeCurrencyString(value);

  return isNegative ? `-${normalized}` : normalized;
};

export const normalizeCurrency = (value: unknown): unknown => {
  if (!value || typeof value !== 'string') {
    return value;
  }

  return normalizeCurrencyString(value);
};

export const normalizeCurrencyString = (value: string): string => {
  // eslint-disable-next-line
  const input = value.match(/[\d|\.]/g) || [''];

  // Remove leading "0"s until a digit 1-9 is found
  while (input[0] === '0') {
    input.shift();
  }

  let periodIndex: number | null | undefined = null;
  const cleaned = takeWhile(input, (value: string, index: number): boolean => {
    if (
      typeof periodIndex === 'number' &&
      (value === '.' || index - 2 > periodIndex)
    ) {
      return false;
    }

    if (value === '.') {
      periodIndex = index;
    }

    return true;
  });

  return cleaned.join('');
};

export const normalizeToMax = (max: number): ((...args: Array<any>) => any) => {
  return (value: string): string => {
    if (!value || value === '') {
      return '0';
    }

    return Number(value) > max ? `${max}` : value;
  };
};

export const normalizeToLength = (
  length: number,
): ((...args: Array<any>) => any) => {
  return (value: string): string => {
    if (!value || value === '') {
      return value;
    }

    return value.slice(0, length);
  };
};

export const normalizePhoneNumber = compose(
  getOnlyNumbers,
  normalizeToLength(10),
);

export const normalizeStringAsNumber = (
  value: string | null | undefined,
): number | null | undefined => {
  if (value) {
    const onlyDigits = value.replace(/[^\d]/g, '');
    return onlyDigits.length === 0 ? null : Number(onlyDigits);
  }

  return null;
};

export const normalizeOnlyDigitsAsString = (
  value: string | null | undefined,
): string | null | undefined => {
  if (value && value.length > 0) {
    return value.replace(/[^\d]/g, '');
  }
  return null;
};
