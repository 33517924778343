import { Box, HM } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useAccountsPageQuery } from '~/graphql/hooks';
import { GridTable } from '~/toolbox/grid-table';
import { Spinner } from '~/toolbox/spinner';

import { InvestAccountsRows } from './components/InvestAccountsRows';
import { PersonalLoansRows } from './components/PersonalLoansRows';
import { SavingsAccountsRows } from './components/SavingsAccountsRows';

export const SettingsAccountsPage = () => {
  const [activeRow, setActiveRow] = React.useState<string | null>(null);
  const [hoverRow, setHoverRow] = React.useState<string | null | undefined>(
    null,
  );

  const { loading, data, error, refetch } = useAccountsPageQuery();

  if (loading) {
    return <Spinner />;
  }

  const viewer = data?.viewer;

  if ((error && !data) || !viewer) {
    return <GenericSystemError onClickRetry={refetch} />;
  }

  return (
    <Box px={16}>
      <HM content="Accounts" fontWeight={300} mb={16} />
      <GridTable gridTemplateColumns="minmax(320px, auto) minmax(160px, 20%) minmax(160px, 20%) minmax(160px, 20%)">
        <GridTable.HeaderRow>
          <GridTable.HeaderCell label="Nickname" />
          <GridTable.HeaderCell label="Number" />
          <GridTable.HeaderCell label="Type" />
          <GridTable.HeaderCell label="Status" />
        </GridTable.HeaderRow>
        <SavingsAccountsRows
          savingsAccount={viewer.save?.savings?.savingsAccounts}
          activeRow={activeRow}
          hoverRow={hoverRow}
          setActiveRow={setActiveRow}
          setHoverRow={setHoverRow}
        />
        <InvestAccountsRows
          investAccounts={viewer.accounts}
          activeRow={activeRow}
          hoverRow={hoverRow}
          setActiveRow={setActiveRow}
          setHoverRow={setHoverRow}
        />
        <PersonalLoansRows personalLoans={viewer.borrow?.personalLoans} />
      </GridTable>
    </Box>
  );
};
