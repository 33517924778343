import { PL } from '@m1/liquid-react';
import * as React from 'react';

import { InvestBankConnectionAccountNodeFragment } from '~/graphql/types';

import { ChangeBankLink } from './ChangeBankLink';

export const BankRelationshipViaDeposits = ({
  achRelationship,
  canChangeBank,
  isPrimaryEmailVerified,
}: {
  achRelationship:
    | InvestBankConnectionAccountNodeFragment['lastAchRelationship']
    | null
    | undefined;
  canChangeBank: boolean;
  isPrimaryEmailVerified: boolean;
}) => {
  if (!achRelationship) {
    return null;
  }

  // check to see if users email is verified
  if (!isPrimaryEmailVerified) {
    return (
      <PL mt={48}>
        Please verify your email to continue the bank connection process.
        <br />
        <br />
        Once verified, we'll send micro-deposits to verify your bank. The
        amounts should appear in your bank account within 1-3 business days.
      </PL>
    );
  }
  if (achRelationship?.status === 'PENDING') {
    return (
      <PL mt={48}>
        We use micro-deposits to verify your bank. Two small deposit amounts
        should appear within your bank account within 2-3 days. After verifying
        these amounts, you can begin depositing money to your M1 account.
      </PL>
    );
  }

  return (
    <>
      <PL content={achRelationship?.nickname} />
      {canChangeBank && <ChangeBankLink achRelationship={achRelationship} />}
    </>
  );
};
