import * as React from 'react';

/*
  useOnMount - Runs a function once after a component has been rendered
  to the DOM. Conceptually similar to the legacy componentDidMount
  lifecycle method (hence the name).

  Note this is NOT to be used as a way to circumvent exhaustive-deps rules.
  It should only be used in instances where you have a true "once, on mount"
  effect that needs to run.
 */
export const useOnMount = (fn: () => void | (() => void)): void => {
  React.useEffect(() => {
    return fn(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
