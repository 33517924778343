import {
  Box,
  Button,
  Flex,
  HXXS,
  Modal,
  ModalContent,
  PL,
} from '@m1/liquid-react';
import * as React from 'react';

const handleRedirect = () => {
  window.open('https://m1.com/crypto', '_blank');
};

export const CryptoModal = ({
  modalVisible,
  closeModal,
}: {
  closeModal: () => void;
  modalVisible: boolean;
}) => (
  <Modal open={modalVisible} onClose={closeModal}>
    <ModalContent>
      {[
        {
          title: 'Crypto Accounts',
          body: 'This new kind of account will keep your crypto separate from stocks and funds.',
        },
        {
          title: 'No commissions',
          // TODO: learn more link
          body: "M1 won't charge you commissions when you buy and sell crypto or fees to make recurring deposits. Others fees may apply.",
        },
        {
          title: 'Model Crypto Portfolios',
          body: 'Pre-built Pies based on thematic strategies like DeFi, Web3, and large-cap crypto.',
        },
        {
          title: 'Secure crypto storage',
          body: 'Your crypto assets and keys will be securely held in a custodial wallet powered by Bakkt.',
        },
        {
          title: 'Two-factor authentication available',
          body: 'You can turn this on in Settings.',
        },
      ].map((line, i) => (
        <Box key={i} mt={32}>
          <HXXS>{line.title}</HXXS>
          <PL mt={8}>{line.body}</PL>
        </Box>
      ))}
      <Flex mt={16}>
        <Button
          kind="link"
          label="The M1 approach to Crypto"
          onClick={() => handleRedirect()}
        />
      </Flex>
      <Button label="Got It" onClick={closeModal} mt={18} size="large" />
    </ModalContent>
  </Modal>
);
