import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';

import { MarketingScreenTemplate } from '~/components/MarketingScreenTemplate';
import { useEarnMarketingScreenQuery } from '~/graphql/hooks';
import { AnnouncementContextEnum } from '~/graphql/types';

export const SaveMarketingPage = () => {
  const { data, loading } = useEarnMarketingScreenQuery({
    errorPolicy: 'all',
  });

  if (loading) {
    return null;
  }

  if (!data?.viewer) {
    return <GenericSystemError />;
  }

  const title = data?.viewer?.earn?.earnMarketingScreen?.header;
  const productMarketingCards =
    data?.viewer?.earn?.earnMarketingScreen?.productMarketingCards;
  const disclosures = data?.viewer?.earn?.earnMarketingScreen?.disclosures;
  const announcement = data?.viewer?.announcements?.forSaveMarketing;

  return (
    <MarketingScreenTemplate
      title={title}
      productMarketingCards={productMarketingCards}
      disclosures={disclosures}
      context={AnnouncementContextEnum.SaveMarketing}
      announcement={announcement}
    />
  );
};
