import {
  Box,
  Button,
  styled,
  Flex,
  HM,
  PL,
  Skeleton,
  useSkeleton,
  BoxProps,
} from '@m1/liquid-react';
import * as React from 'react';

import { anInfoPageListItem, aText } from '~/graphql/fixtures';
import { AppInfoPageFragment, RichTextFragment } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';

import { AppRichTextDisclosureLink } from '../AppDisclosureLink/AppDisclosureLink';
import { AppImage } from '../AppImage';

import { RichText } from '../RichText/RichText';

import { ButtonOrLink } from './ButtonOrLink';

export const StyledBoxContainer = styled(Flex)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.SMALL}) {
    padding: 0 48px 48px 48px;
  }
`;

export const StyledButtonFlexContainer = styled(Flex)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.SMALL}) {
    margin-top: 48px;
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledSkipButtonContainer = styled(Box)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.SMALL}) {
    right: 36px;
  }
`;

export type AppInfoPageProps = {
  appInfoPage: Maybe<AppInfoPageFragment>;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  onSkipClick?: () => void;
  containerProps?: BoxProps;
};

export const AppInfoPage = ({
  appInfoPage,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onSkipClick,
  containerProps,
}: AppInfoPageProps) => {
  const analytics = useAnalytics();
  const { isLoading } = useSkeleton();
  React.useEffect(() => {
    if (appInfoPage?.pageViewAnalytics) {
      analytics.recordAppAnalyticsEvent(appInfoPage?.pageViewAnalytics);
    }
  }, [appInfoPage?.pageViewAnalytics]);

  const {
    illustration,
    title,
    subtitle,
    infoList,
    paragraphs,
    richTextDisclosures,
    primaryCtaLink,
    primaryCtaButton,
    secondaryCtaLink,
    secondaryCtaButton,
    skipLabel,
  } = appInfoPage ?? {};

  const mockInfoList = [
    anInfoPageListItem({
      title: {
        content: [aText({ font: 'PL' })],
      },
      subtitle: null,
    }),
    anInfoPageListItem({
      title: {
        content: [aText({ font: 'PL' })],
      },
      subtitle: null,
    }),
    anInfoPageListItem({
      title: {
        content: [aText({ font: 'PL' })],
      },
      subtitle: null,
    }),
  ];

  const list = isLoading ? mockInfoList : infoList;

  return (
    <StyledBoxContainer
      maxWidth="588px"
      alignItems="center"
      flexDirection="column"
      margin="0 auto"
      {...containerProps}
    >
      <StyledSkipButtonContainer position="absolute" right={172} mt={40}>
        {onSkipClick && skipLabel && (
          <Button kind="text" label={skipLabel} onClick={onSkipClick} />
        )}
      </StyledSkipButtonContainer>
      <Skeleton skeletonWidth={343} skeletonHeight={343}>
        {illustration && (
          <Box mt={56}>
            <AppImage appImage={illustration} />
          </Box>
        )}
      </Skeleton>
      <Box textAlign="left" width="100%">
        <Skeleton skeletonWidth="100%" mt={48} mb={24}>
          <HM fontWeight={300}>{title}</HM>
        </Skeleton>
        <Skeleton skeletonWidth="100%" skeletonHeight={64} mb={16}>
          <PL>{subtitle}</PL>
        </Skeleton>
        {list?.map((infoListItem, i) => (
          <Flex key={i} mb={16}>
            <Skeleton skeletonWidth={32} skeletonHeight={32} mr={8}>
              {infoListItem.icon && <AppImage appImage={infoListItem.icon} />}
            </Skeleton>
            <Box
              {...(!infoListItem.subtitle && {
                display: 'flex',
                alignItems: 'center',
              })}
              width="100%"
            >
              <Skeleton mb={8} skeletonWidth="100%">
                <RichText richText={infoListItem.title as RichTextFragment} />
              </Skeleton>
              {infoListItem.subtitle?.map((subtitle, i) => (
                <RichText key={i} richText={subtitle as RichTextFragment} />
              ))}
            </Box>
          </Flex>
        ))}
        {/* Used less often, so for now, no skeleton needed */}
        {paragraphs && (
          <Box mt={32}>
            {paragraphs.map((paragraph, i) => (
              <Box key={i} mb={16}>
                <RichText
                  richText={paragraph}
                  containerProps={{ display: 'inline' }}
                />
              </Box>
            ))}
          </Box>
        )}
        <StyledButtonFlexContainer>
          <ButtonOrLink
            onClick={onPrimaryButtonClick}
            linkable={primaryCtaLink}
            button={primaryCtaButton}
            kind="primary"
          />
          <ButtonOrLink
            onClick={onSecondaryButtonClick}
            linkable={secondaryCtaLink}
            button={secondaryCtaButton}
            kind="text"
          />
        </StyledButtonFlexContainer>
        <Box>
          {richTextDisclosures?.map((disclosure, i) => (
            <AppRichTextDisclosureLink
              appRichTextDisclosureLink={disclosure}
              key={i}
            />
          ))}
        </Box>
      </Box>
    </StyledBoxContainer>
  );
};
