import { Box, PM } from '@m1/liquid-react';
import * as React from 'react';

import { FullyPaidLendingDisclosureFragment } from '~/graphql/types';
import { Checkbox } from '~/toolbox/checkbox';

export const FullyPaidLendingDisclosure = ({
  disclosure,
  handleDispatch,
}: {
  disclosure: FullyPaidLendingDisclosureFragment;
  handleDispatch: (fplOptIn: boolean) => void;
}) => {
  const [fplOptIn, setFplOptIn] = React.useState<boolean>(true);

  // there are several places in account opening where we update this status, so we need to make sure its available in global state
  React.useEffect(() => {
    if (disclosure) {
      setFplOptIn(disclosure.optInDefault);
      handleDispatch(disclosure.optInDefault);
    }
  }, [disclosure, handleDispatch]);

  if (!disclosure) {
    return null;
  }

  const handleOnChange = () => {
    handleDispatch(!fplOptIn);
    setFplOptIn(!fplOptIn);
  };

  return (
    <Box mb={32}>
      {disclosure.introduction.map((item: any, i: number) => (
        <PM fontWeight={400} key={i}>
          {item}
        </PM>
      ))}
      <PM fontWeight={600} mt={8}>
        {disclosure.reviewTitle}
      </PM>
      {disclosure.reviewBody.map((item: any, i: number) => (
        <PM mt={4} key={i}>
          {item}
        </PM>
      ))}
      <PM fontWeight={600} mt={8}>
        {disclosure.disclosureTitle}
      </PM>
      {disclosure.disclosureBody.map((item: any, i: number) => (
        <PM mt={4} key={i}>
          {item}
        </PM>
      ))}
      <Box mt={18}>
        <Checkbox
          label={disclosure.optInText}
          checked={fplOptIn}
          onChange={handleOnChange}
        />
      </Box>
    </Box>
  );
};
