import { styled, Text } from '@m1/liquid-react';
import capitalize from 'lodash-es/capitalize';
import * as React from 'react';

import { TransferParticipantCell } from '~/components/transfers/TransferParticipantCell';
import { ScheduledTransferRuleFragment } from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';
import { formatNumber } from '~/utils';

import { TransferRuleStatus } from '../TransferRuleStatus/TransferRuleStatus';

export type TransfersRulesTableProps = {
  scheduledTransferRules: (ScheduledTransferRuleFragment | null | undefined)[];
  style?: Record<string, any>;
};

const NoWrap = styled(Text)`
  white-space: nowrap;
`;

export const TransfersRulesTable = ({
  style,
  scheduledTransferRules,
}: TransfersRulesTableProps) => {
  return (
    <GridTable
      emptyMessage="You have no schedules."
      gridTemplateColumns="270px 270px 110px auto auto"
      style={style}
      isNewStyle
    >
      <GridTable.HeaderRow>
        <GridTable.HeaderCell label="From" />
        <GridTable.HeaderCell label="To" />
        <GridTable.HeaderCell label="Type" />
        <GridTable.HeaderCell label="Details" />
        <GridTable.HeaderCell justifySelf="end" label="Amount" />
      </GridTable.HeaderRow>
      {scheduledTransferRules?.map(
        (rule) => rule && <RuleRow rule={rule} key={rule.id} />,
      )}
    </GridTable>
  );
};

const RuleRow = ({ rule }: { rule: ScheduledTransferRuleFragment }) => {
  const getRuleType = () => {
    const isAutoPay = rule.isCreditCardAutoPay || rule.isPersonalLoanAutoPay;
    if (isAutoPay) {
      return rule.scheduledTransferListLabel;
    } else if (rule.schedule) {
      return rule.schedule.title;
    }
    return '--';
  };

  const getAmount = () => {
    const isAutoPay = rule.isCreditCardAutoPay || rule.isPersonalLoanAutoPay;
    if (
      isAutoPay &&
      rule.schedulePresetIndicator &&
      rule.schedulePresetIndicator !== 'FIXED_AMOUNT'
    ) {
      return capitalize(rule.schedulePresetIndicator.replace('_', ' '));
    } else if (typeof rule.amount === 'number') {
      return formatNumber(rule.amount, '$0,0[.]00');
    }
    return '$--';
  };
  return (
    <GridTable.NavigableRow
      key={rule.id}
      params={{
        transferRuleId: rule.id,
      }}
      to="/d/c/rule-details/:transferRuleId"
    >
      <GridTable.Cell
        content={
          rule.from ? (
            <TransferParticipantCell transferParticipant={rule.from} />
          ) : (
            '--'
          )
        }
      />
      <GridTable.Cell
        content={
          rule.to ? (
            <TransferParticipantCell transferParticipant={rule.to} />
          ) : (
            '--'
          )
        }
      />
      <GridTable.Cell content={getRuleType()} />
      <GridTable.Cell content={<TransferRuleStatus transferRule={rule} />} />
      <GridTable.Cell
        content={<NoWrap>{getAmount()}</NoWrap>}
        justifySelf="end"
      />
    </GridTable.NavigableRow>
  );
};
