import { Box, Button, Flex } from '@m1/liquid-react';
import React from 'react';
import { useForm } from 'react-hook-form';

import { ControlledDropdown } from '~/components/form/ControlledDropdown';
import { ControlledInput } from '~/components/form/ControlledInput';
import { useUpdateProfileMutation } from '~/graphql/hooks';
import {
  AccountProfileEmploymentFragment,
  EmploymentDropdownOptionsFragment,
  EmploymentStatusEnum,
} from '~/graphql/types';
import { withoutTypename } from '~/graphql/utils';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useToast } from '~/toasts';
import { getEnumEntries } from '~/utils';

type EmploymentFormProps = {
  dropdownOptions: NonNullable<
    EmploymentDropdownOptionsFragment['occupationDropdownOptions']
  >;
  profileType: 'primary' | 'secondary';
  value: AccountProfileEmploymentFragment;
};

export const EmploymentForm = (props: EmploymentFormProps) => {
  const { addToast } = useToast();
  const [updateProfile, { loading }] = useUpdateProfileMutation();

  usePortaledSpinner(loading);

  const formMethods = useForm<{
    employer: string | null;
    occupation: string | null;
    status: EmploymentStatusEnum;
  }>({
    defaultValues: props.value.employmentInfo ?? undefined,
  });

  const occupationsWithNames = props.dropdownOptions.map((option) => {
    return {
      name: option.label,
      description: option.label,
    };
  });

  function onSubmit(
    employment: AccountProfileEmploymentFragment['employmentInfo'],
  ) {
    updateProfile({
      variables: {
        input: {
          profile: {
            primary: {},
            [props.profileType]: withoutTypename({
              employment,
            }),
          },
        },
      },
      onCompleted: () =>
        addToast({
          content: 'Profile updated',
          kind: 'success',
          duration: 'long',
        }),
      onError: () =>
        addToast({
          content: 'Error updated profile. Please try again later.',
          kind: 'alert',
          duration: 'long',
        }),
    });
  }

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
      <Flex flexDirection="column">
        <Flex justifyContent="stretch">
          <Box width="100%">
            <ControlledDropdown
              name="status"
              label="Employment status"
              placeholder="Select"
              source={getEnumEntries('EmploymentStatusEnum')}
              control={formMethods.control}
              rules={{ required: 'Required' }}
            />
          </Box>
        </Flex>
        {/^(self.)?employed/i.test(formMethods.watch('status')) ? (
          <Flex flexDirection="row" alignContent="space-between">
            <Box width="100%" pr={10}>
              <ControlledInput
                name="employer"
                label="Employer"
                control={formMethods.control}
                rules={{ required: 'Required' }}
              />
            </Box>
            <Box width="100%" pl={10}>
              <ControlledDropdown
                name="occupation"
                label="Occupation/Industry"
                source={occupationsWithNames}
                control={formMethods.control}
                rules={{ required: 'Required' }}
              />
            </Box>
          </Flex>
        ) : null}
        <Flex flexDirection="row" justifyContent="flex-end">
          <Button
            label="Save"
            type="submit"
            disabled={!formMethods.formState.isDirty}
          />
        </Flex>
      </Flex>
    </form>
  );
};
