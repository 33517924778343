import { Box, PS, styled, useTheme } from '@m1/liquid-react';
import React from 'react';

import { ChartableSliceChart } from '~/components/ChartableSliceChart/ChartableSliceChart';
import { ChartableSliceChartRangeSelectionChange } from '~/components/ChartableSliceChart/ChartableSliceChart.types';
import { ExternalLink } from '~/components/ExternalLink';
import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';
import { Grid } from '~/toolbox/grid';
import { Section } from '~/toolbox/section';

import { usePieDetailsContext } from './hooks/usePieDetailsContext';
import { PieGraphSectionHeader } from './PieGraphSectionHeader';

const PieGraphChart = styled(Box)`
  .highcharts-range-selector-group > .highcharts-range-selector-buttons {
    @media ${RESPONSIVE_BREAKPOINTS.TABLET} {
      transform: translate(320px, 10px) !important;
    }
  }
`;

export const PieGraphSection = () => {
  const { pie, chartInfo, setChartInfo } = usePieDetailsContext();
  const theme = useTheme();

  const calcPercentChange = ({
    chartStartDate,
    chartEndDate,
    percentChange,
  }: ChartableSliceChartRangeSelectionChange) => {
    setChartInfo({
      percentChange,
      startDate: chartStartDate,
      endDate: chartEndDate,
    });
  };

  return (
    <Section>
      <Grid.Row>
        <Grid.Col xs={12}>
          <PieGraphSectionHeader
            valuePercentChange={
              chartInfo?.percentChange ??
              pie?.analysis?.backtesting?.valuePercentChange
            }
          />
        </Grid.Col>
      </Grid.Row>
      <PieGraphChart>
        <ChartableSliceChart
          chartableSliceIds={[pie?.id]}
          chartName="research_pies"
          features={{ navigator: true, dateRangeInputs: true }}
          periods={['1w', '1M', '3M', 'ytd', '1y', '3y', 'all']}
          onRangeSelectionChange={calcPercentChange}
          plotLines={[
            // $100 starting value comparison
            {
              value: 100,
              width: 1,
              dashStyle: '6 6',
              color: theme.colors.foregroundNeutralTertiary,
              zIndex: 2,
            },
          ]}
        />
      </PieGraphChart>
      <Grid.Row>
        <Grid.Col xs={6}>
          <PS content="Returns based on an initial deposit of $100." />
        </Grid.Col>
        <Grid.Col xs={6} xsTextRight>
          <PS>
            <ExternalLink
              destination={
                pie?.__typename === 'UserPie'
                  ? 'FAQ_CUSTOM_PIE_PERFORMANCE'
                  : 'FAQ_BACKTESTING'
              }
              label="How are returns calculated?"
            />
          </PS>
        </Grid.Col>
      </Grid.Row>
    </Section>
  );
};
