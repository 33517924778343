import { ApolloError } from '@apollo/client';

import { useWizardContext } from '~/flows/wizard';
import {
  useCreateIraTransferMutation,
  useCreateTransferInstanceMutation,
} from '~/graphql/hooks';
import {
  CreateIraTransferInput,
  CreateIraTransferMutation,
  CreateTransferInstanceInput,
  CreateTransferInstanceMutation,
} from '~/graphql/types';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { ACTIONS } from '~/redux/actions/rootAction';
import { useDispatch } from '~/redux/hooks';
import { useToast } from '~/toasts';

import { InitialFundingFlowSteps } from '../InitialFundingFlow';

type InputWithRequiredIdempotencyKey = Omit<
  CreateIraTransferInput | CreateTransferInstanceInput,
  'idempotencyKey'
> & { idempotencyKey: string };

const inputIsIraTransfer = (
  input: InputWithRequiredIdempotencyKey,
): input is CreateIraTransferInput => {
  return Boolean(input.iraContributionYear);
};

type CreateOneTimeTransferCallbackProps = {
  input: InputWithRequiredIdempotencyKey;
  isInitialFunding?: boolean;
  onCompleted?: (
    data: CreateIraTransferMutation | CreateTransferInstanceMutation,
  ) => void;
  onError?: (err: ApolloError) => void;
  showSuccessToast?: boolean;
};

export const useCreateOneTimeTransfer = ({
  confirmationStep,
}: {
  confirmationStep?: InitialFundingFlowSteps;
} = {}) => {
  const { goTo } = useWizardContext();
  const { addToast } = useToast();
  const dispatch = useDispatch();

  const [createTransferInstance, { loading: createTransferInstanceLoading }] =
    useCreateTransferInstanceMutation();

  const [
    createIRATransferInstance,
    { loading: createIRATransferInstanceLoading },
  ] = useCreateIraTransferMutation();

  usePortaledSpinner(
    createTransferInstanceLoading || createIRATransferInstanceLoading,
  );

  const onCompleted = (
    data: CreateIraTransferMutation | CreateTransferInstanceMutation,
    {
      showSuccessToast,
      onCompleted: onCompletedProp,
    }: CreateOneTimeTransferCallbackProps,
  ) => {
    if (showSuccessToast) {
      addToast({
        content: 'Your deposit was successful.',
        kind: 'success',
      });
    }

    const outcome =
      'createTransferInstance' in data
        ? data.createTransferInstance.outcome
        : data.createIraTransfer.outcome;

    dispatch({
      type: ACTIONS.INITIAL_DEPOSIT_MUTATION_COMPLETED,
      payload: outcome?.instance?.id,
    });
    dispatch({
      type: 'TRANSFER_INSTANCE_CREATED',
      payload: {
        outcome,
      },
    });
    // Go to the confirmation step of the flow.
    confirmationStep && goTo(confirmationStep);
    onCompletedProp?.(data);
  };

  const onError = (
    err: ApolloError,
    {
      onError: onErrorProp,
      isInitialFunding,
    }: CreateOneTimeTransferCallbackProps,
  ) => {
    if (isInitialFunding) {
      dispatch({
        type: ACTIONS.INITIAL_DEPOSIT_MUTATION_FAILED,
        payload: err.message,
      });
    }
    addToast({
      content:
        'We were unable to complete your transfer. Please contact Client Support',
      kind: 'alert',
    });
    onErrorProp?.(err);
  };

  return async (args: CreateOneTimeTransferCallbackProps) => {
    if (inputIsIraTransfer(args.input)) {
      createIRATransferInstance({
        variables: {
          input: args.input,
        },
        onCompleted: (data) => onCompleted(data, args),
        onError: (err) => onError(err, args),
      });
    } else {
      createTransferInstance({
        variables: {
          input: args.input,
        },
        onCompleted: (data) => onCompleted(data, args),
        onError: (err) => onError(err, args),
      });
    }
  };
};
