import { Button, HXS, PL, Flex, Box, styled } from '@m1/liquid-react';
import * as React from 'react';

import { Notifications } from '~/components/notifications';
import { useSelector } from '~/redux/hooks';

type MarginCallInformationDisableAutoInvestProps = {
  onFinishStep: () => void;
};

// Styled Components
const StyledContainer = styled(Flex)`
  margin: auto;
  width: 583px;
`;

export const MarginCallInformationDisableAutoInvestStep = ({
  onFinishStep,
}: MarginCallInformationDisableAutoInvestProps) => {
  const isDisableAutoInvestMutationSubmitting = useSelector<boolean>(
    (state) =>
      state.newFlows.MARGIN_CALL_INFORMATION
        .isDisableAutoInvestMutationSubmitting,
  );
  return (
    <StyledContainer flexDirection="column" pt={161}>
      <Box mb={32}>
        <Notifications location="RESOLVE_MARGIN_CALL_TURN_OFF_AUTO_INVEST" />
      </Box>
      <HXS content="Resolve call" mb={16} />
      <PL
        content="Turn off auto-invest to ensure that the funds do not get automatically reinvested."
        mb={64}
      />
      <Box>
        <Button
          disabled={isDisableAutoInvestMutationSubmitting}
          label="Turn Off Auto-Invest"
          onClick={() => onFinishStep()}
          size="large"
        />
      </Box>
    </StyledContainer>
  );
};
