import { Button } from '@m1/liquid-react';
import React from 'react';

import { useNavigate } from '~/hooks/useNavigate';

export const SaveAndCloseButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      kind="text"
      size="large"
      label="Save and close"
      onClick={() => navigate({ to: '/d/home' })}
    />
  );
};
