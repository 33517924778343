import { Box, Flex, LM, PL } from '@m1/liquid-react';
import React from 'react';

import { PaginationButtonGroup } from '~/components/PaginationButtonGroup';
import { useBorrowBillsQuery } from '~/graphql/hooks';
import { useSelector } from '~/redux/hooks';
import { GridTable } from '~/toolbox/grid-table';
import { Spinner } from '~/toolbox/spinner';

import { BorrowBillRow } from './BorrowBillRow';

export const BorrowBillsTable = () => {
  const [cursors, setCursors] = React.useState<string[]>([]);
  const borrowAccountId = useSelector((state) => state.global.borrowAccountId);
  const { data, loading, refetch, previousData } = useBorrowBillsQuery({
    variables: {
      borrowAccountId: borrowAccountId as string,
      first: 10,
    },
    skip: !borrowAccountId,
    notifyOnNetworkStatusChange: true,
  });

  const bills =
    data?.borrowAccount?.__typename === 'BorrowAccount'
      ? data.borrowAccount.marginProductLandingPage?.statements
      : null;

  if (loading && !previousData) {
    return <Spinner />;
  }

  if (!bills) {
    return (
      <Box py={16}>
        <PL content="Bills are temporarily unavailable." />
      </Box>
    );
  }

  const handleClickLoadPrevPage = () => {
    const newCursors = cursors.slice(0, -1);
    const after = newCursors[newCursors.length - 1] || null;

    refetch({
      after,
    });

    setCursors(newCursors);
  };

  const handleNextPage = () => {
    if (typeof bills?.pageInfo?.endCursor === 'string') {
      const after = bills.pageInfo.endCursor;

      refetch({
        after,
      });

      setCursors((prevCursors) => [...prevCursors, after]);
    }
  };

  return (
    <>
      <Flex mt={-48} mb={8} alignItems="center" justifyContent="flex-end">
        <PaginationButtonGroup
          handleNextPage={handleNextPage}
          handlePreviousPage={handleClickLoadPrevPage}
          hasNextPage={Boolean(bills?.pageInfo.hasNextPage)}
          hasPreviousPage={cursors.length > 0}
        />
      </Flex>
      <GridTable
        gridTemplateColumns="120px auto"
        emptyMessage="No bills to display."
        isNewStyle
      >
        <GridTable.HeaderRow>
          <GridTable.HeaderCell label="Date" />
          <GridTable.HeaderCell label="Amount" justifySelf="end" />
        </GridTable.HeaderRow>

        {bills.edges?.map((edge, i) => {
          if (!edge?.node) {
            return (
              <GridTable.Row key={i}>
                <GridTable.Cell>
                  <LM content="Entry unavailable" />
                </GridTable.Cell>
              </GridTable.Row>
            );
          }
          return <BorrowBillRow billNode={edge.node} key={i} />;
        })}
      </GridTable>
    </>
  );
};
