import { SagaIterator } from 'redux-saga';
import { call, select, put } from 'redux-saga/effects';

import { ProductListIdentifier } from '~/graphql/types';
import {
  changeBeginOnboardingStep,
  setOnboardingProduct,
  beginOnboarding,
} from '~/redux/actions';
import { readLandingUrl } from '~/redux/selectors';

import { getLoggers, getSentryReporter } from './getLoggersSaga';
import { hasInvestAccountSaga } from './hasInvestAccountSaga';
import { hasUserOnboarded } from './hasUserOnboardedSaga';

type RedirectUrlEnum =
  | '/onboarding/setup-invest-account'
  | '/d/invest/portfolio'
  | '/onboarding/savings-onboarding'
  | '/d/save';

type RedirectPaths = {
  route: RedirectUrlEnum;
  previousRoute?: RedirectUrlEnum;
};

export function* handleProductAutoSelectDeepLinksSaga(): SagaIterator<RedirectPaths | null> {
  const { analytics } = yield call(getLoggers);
  const sentry = yield call(getSentryReporter);

  try {
    const landingUrl: string | null | undefined = yield select(readLandingUrl);
    const userHasOnboarded: boolean = yield call(hasUserOnboarded);

    if (landingUrl) {
      if (landingUrl.includes('/d/open-invest-account')) {
        const userHasInvestAccount = yield call(hasInvestAccountSaga);

        if (userHasOnboarded && !userHasInvestAccount) {
          return { route: '/onboarding/setup-invest-account' };
        }

        if (userHasInvestAccount) {
          return { route: '/d/invest/portfolio' };
        }

        yield put(beginOnboarding());

        yield put(
          setOnboardingProduct({
            destination: 'Invest',
            productIdentifier: ProductListIdentifier.InvestIndividual,
          }),
        );

        yield put(
          changeBeginOnboardingStep({
            step: 'BEGIN_INVEST_ONBOARDING',
          }),
        );

        analytics.recordEvent('m1_onboarding_product_selector_skipped');
      }
      if (landingUrl.includes('savings-onboarding')) {
        if (userHasOnboarded) {
          return {
            route: '/onboarding/savings-onboarding',
            // Set previous route so if the user hits back from onboarding
            // they'll go to Earn account or marketing screen.
            previousRoute: '/d/save',
          };
        }

        yield put(beginOnboarding());

        yield put(
          setOnboardingProduct({
            destination: 'Savings',
            productIdentifier: ProductListIdentifier.SpendSavings,
          }),
        );

        yield put(
          changeBeginOnboardingStep({
            step: 'BEGIN_SAVINGS_ONBOARDING',
          }),
        );

        analytics.recordEvent('m1_onboarding_product_selector_skipped');
      }
    }
  } catch (e: any) {
    sentry.message('Failed to handle deeplink.', {
      rawError: e,
    });
  }

  return null;
}
