import * as React from 'react';

import { BankImage as BankImageComponent } from '~/components/BankImage';
import { InvestBankConnectionAccountNodeFragment } from '~/graphql/types';

export const BankImage = ({
  achRelationship,
}: {
  achRelationship:
    | Pick<
        NonNullable<
          InvestBankConnectionAccountNodeFragment['lastAchRelationship']
        >,
        '__typename' | 'status'
      >
    | null
    | undefined;
}) => {
  const getStatus = () => {
    if (!achRelationship) {
      return undefined;
    }

    if (achRelationship) {
      if (/(CANCELED|REJECTED)/.test(achRelationship.status)) {
        return undefined;
      } else if (
        achRelationship.__typename === 'AchRelationshipViaDeposits' &&
        achRelationship.status === 'PENDING'
      ) {
        return 'pending';
      } else if (/(PENDING|APPROVED)/.test(achRelationship.status)) {
        return 'verified';
      }
    }

    return undefined;
  };

  return <BankImageComponent status={getStatus()} />;
};
