import { PM, styled, css, Box, theme, Flex } from '@m1/liquid-react';
import * as React from 'react';

import {
  CommonTransactionsEmptyStateFragment,
  RichTextFragment,
} from '~/graphql/types';
import { RichText } from '~/lens-toolbox/RichText/RichText';

import { GridTableCell, StyledGridTableCell } from './cell';
import { GridContext } from './context';
import { GridTableGroup, GridTableGroups } from './groups';
import {
  GridTableHeaderCell,
  StyledGridTableHeaderCell,
  GridTableHeaderCellProps,
} from './header-cell';
import { GridTableHeaderRow, GridTableHeaderRowProps } from './header-row';
import { GridTableRow, GridTableNavigableRow, StyledGridTableRow } from './row';

export type VirtualizationProps<T> = {
  visibleRowCount: number;
  rowList: T[];
};

export type GridTableProps<T> = React.PropsWithChildren<{
  defaultAlignSelf?: React.CSSProperties['alignSelf'];
  emptyMessage?: React.ReactNode | string | null;
  emptyStateContent?: CommonTransactionsEmptyStateFragment | null;
  gridTemplateColumns: string;
  hasHeaderRow?: boolean;
  isNavigable?: boolean;
  virtualization?: VirtualizationProps<T> | undefined;
  style?: React.CSSProperties;
  isNewStyle?: boolean;
  isTableEmpty?: boolean; // Allows for default empty state to be overridden.
}>;

const hoverStyle = css`
  ${StyledGridTableRow}:hover {
    cursor: pointer;
  }
`;

const newStyleCss = css`
  ${StyledGridTableCell}:last-child {
    padding: 0;
  }

  .stickyColumn {
    position: sticky;
    right: 0;
    z-index: 1;
  }
  ${StyledGridTableHeaderCell}.stickyColumn {
    background: ${theme.colors.backgroundNeutralSecondary};
  }
`;

const oldStyleCss = css`
  ${StyledGridTableHeaderCell}:last-child {
    padding-right: 24px;
  }
  ${StyledGridTableCell}:last-child {
    padding: 0;
  }
`;

const StyledGridTable = styled(Box)<
  GridTableProps<any> & {
    isNavigable: boolean;
  }
>`
  overflow-x: auto;
  ${(props) => (props?.isNewStyle ? newStyleCss : oldStyleCss)};
  ${(props) => props.isNavigable && hoverStyle};
`;

const ContentWrapper = styled(Box)`
  display: inline-block;
  white-space: nowrap;
  min-width: 100%;
`;

const GridTable = <T,>(props: GridTableProps<T>) => {
  const {
    isNewStyle = false,
    defaultAlignSelf = 'center',
    hasHeaderRow = true,
    gridTemplateColumns,
    children,
    emptyMessage,
    emptyStateContent,
    isNavigable,
    virtualization,
    isTableEmpty,
  } = props;

  const childrenArray = React.Children.toArray(children);

  const legacyIsNavigable = childrenArray.some(
    // @ts-expect-error - TS2339 - Property 'type' does not exist on type 'ReactChild | ReactFragment | ReactPortal'. | TS2339 - Property 'displayName' does not exist on type 'Class<Component<any, any, any>>'.
    (c) => c.type.displayName === GridTableNavigableRow.displayName,
  );

  let isEmpty = false;

  if (isTableEmpty) {
    isEmpty = true;
  } else if (virtualization) {
    isEmpty = virtualization.rowList.length === 0;
  } else {
    isEmpty = hasHeaderRow
      ? childrenArray.length === 1
      : childrenArray.length === 0;
  }

  // Empty state row is not navigable, only set styles if table is not empty.
  const hasClickableStyle = !isEmpty && (isNavigable ?? legacyIsNavigable);

  return (
    <GridContext.Provider
      value={{
        defaultAlignSelf,
        gridTemplateColumns,
        isNewStyle,
        virtualization,
        isVirtualized: virtualization
          ? virtualization.rowList.length > virtualization.visibleRowCount
          : false,
      }}
    >
      <StyledGridTable {...props} isNavigable={hasClickableStyle}>
        <ContentWrapper>
          {children}
          {isEmpty &&
            (emptyStateContent ? (
              <GridTableRow gridTemplateColumns="auto">
                <GridTableCell alignSelf="center" justifySelf="center">
                  <Flex flexDirection="column" alignItems="center">
                    {emptyStateContent?.title &&
                      emptyStateContent?.title?.map((item, idx) => (
                        <RichText
                          key={idx}
                          richText={item as RichTextFragment}
                          textProps={{
                            font: 'PL',
                            color: 'foregroundNeutralMain',
                          }}
                        />
                      ))}
                    {emptyStateContent?.subtitle &&
                      emptyStateContent?.subtitle?.map((item, idx) => (
                        <RichText
                          key={idx}
                          richText={item as RichTextFragment}
                          textProps={{
                            font: 'PM',
                            color: 'foregroundNeutralMain',
                          }}
                        />
                      ))}
                  </Flex>
                </GridTableCell>
              </GridTableRow>
            ) : (
              <GridTableRow gridTemplateColumns="auto">
                <GridTableCell alignSelf="center" justifySelf="center">
                  <PM
                    color="foregroundNeutralMain"
                    content={emptyMessage}
                    style={{
                      fontStyle: 'italic',
                    }}
                  />
                </GridTableCell>
              </GridTableRow>
            ))}
        </ContentWrapper>
      </StyledGridTable>
    </GridContext.Provider>
  );
};

GridTable.HeaderCell = GridTableHeaderCell;
GridTable.HeaderRow = GridTableHeaderRow;
GridTable.Row = GridTableRow;
GridTable.Cell = GridTableCell;
GridTable.NavigableRow = GridTableNavigableRow;
GridTable.Groups = GridTableGroups;
GridTable.Group = GridTableGroup;

export {
  GridTable,
  type GridTableHeaderCellProps,
  type GridTableHeaderRowProps,
};
