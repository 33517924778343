import * as React from 'react';

import { ProcessingRequest } from '~/components/ProcessingRequest';

export const ProcessingOfferSubmission = () => {
  return (
    <ProcessingRequest
      heading="We have submitted your offer selection."
      content="This may take up to a minute."
    />
  );
};
