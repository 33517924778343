import { PS } from '@m1/liquid-react';
import * as React from 'react';

import { submittedLiquidNetWorth } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { GridTable } from '~/toolbox/grid-table';
import { isNotNil } from '~/utils';

import {
  SingleSelectButtonInput,
  SingleSelectButtonInputProps,
} from '../SingleSelectButtonInput';

type OnboardingTable = {
  content: Array<Array<string>>;
  header: Array<string>;
};

type OnboardingTooltip = {
  table?: OnboardingTable;
  text?: Array<string>;
};

type BaseProps = SingleSelectButtonInputProps<string>;
type Props = Pick<BaseProps, 'content' | 'field' | 'options' | 'question'> & {
  onClick?: BaseProps['onClick'];
  tooltip?: OnboardingTooltip;
};

export const renderTableTooltip = (
  table: OnboardingTable | null | undefined,
) => {
  if (!table) {
    return null;
  }
  const headers = table.header.map((text) => (
    <GridTable.HeaderCell label={text} key={text} />
  ));

  const rows = table.content.map((row, index) => (
    <GridTable.Row key={index}>
      {row.map((text, idx) => (
        <GridTable.Cell
          textAlign="left"
          content={text}
          key={`${text}:${idx}`}
        />
      ))}
    </GridTable.Row>
  ));

  return (
    <GridTable gridTemplateColumns="80px 80px 80px auto">
      <GridTable.HeaderRow
        style={{
          height: '88px',
          alignContent: 'center',
          whiteSpace: 'normal',
        }}
      >
        {headers}
      </GridTable.HeaderRow>
      {rows}
    </GridTable>
  );
};

export const CollectProfileInput = ({
  content,
  field,
  options,
  onClick,
  question,
  tooltip,
}: Props) => {
  const dispatch = useDispatch();

  const handleOptionClick = React.useCallback(
    (value: number | string) => {
      dispatch(
        submittedLiquidNetWorth({
          field,
          value,
        }),
      );
    },
    [field, dispatch],
  );

  return (
    <SingleSelectButtonInput
      field={field}
      question={question}
      tooltip={
        isNotNil(tooltip) ? (
          <>
            {tooltip.text?.map((text: string) => (
              <PS key={text} content={text} px={16} py={6} />
            ))}
            {renderTableTooltip(tooltip.table)}
          </>
        ) : null
      }
      content={content}
      options={options}
      onClick={onClick ?? handleOptionClick}
    />
  );
};
