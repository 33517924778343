import { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  fork,
  put,
  select,
  setContext,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {
  HasIdentityInfoDocument,
  RequestOfflineOpenAccountDocument,
} from '~/graphql/hooks';
import {
  IdentityFirstAccountSetupProfileQuery,
  OfflineAccountRegistrationEnum,
  OnboardingIdentityFirstQuestionType,
  RequestOfflineOpenAccountInput,
} from '~/graphql/types';
import {
  accountSetupFlowCanceled,
  accountSetupFlowFinished,
  ACTION_TYPES as ACTIONS,
  displayNotification,
  hideLoadingSpinner,
  selectedRetirementAccountType,
  setIdentityFirstOnboardingQuestions,
  setInitialUserProfileInput,
  showLoadingSpinner,
} from '~/redux/actions';
import { apolloMutationSaga } from '~/redux/sagas/apolloMutationSaga';
import { apolloQuerySaga } from '~/redux/sagas/apolloQuerySaga';
import {
  IDENTITY_FIRST_FLOW_STEPS as STEPS,
  INVEST_ACCOUNT_TYPES,
  NOTIFICATION_LOCATIONS,
  NOTIFICATION_TYPES,
  OPEN_ACCOUNT_FLOW_MODES,
  RETIREMENT_ACCOUNT_TYPES,
} from '~/static-constants';
import { delay } from '~/utils';

import type { AppState } from '../../../../reducers/types';
import { isFunctionBlocked } from '../../../systemStatus';
import { changeStep, makeFlowFuncs } from '../../utils';

import { primaryQuestionMap } from './mappings';

import {
  handleFinishedCustodialReview,
  handleFinishedReview,
} from './profile-review';
import {
  queryInvestAccountQuestions,
  queryOnboardingQuestions,
  queryViewerProfile,
} from './remote';
import {
  finishedFinancialSuitabilityQuestion,
  handleInvestAndSuitabilityNavigation,
} from './suitabilitySection';
import {
  finishedUserProfileModuleOne,
  finishedUserProfileQuestion,
  getInitialUserProfileQuestion,
} from './userProfileSection';

import {
  changeStepAfterAccountSelection,
  finishedChoosePie,
  getNextQuestion,
  handleSelectedRootPie,
} from './utils';

const { takeFlowStep } = makeFlowFuncs('IDENTITY_FIRST');

export type IdentityFirstAccountSetupProfile =
  | IdentityFirstAccountSetupProfileQuery['viewer']['profile']
  | null
  | undefined;

export function* identityFirstSaga(): SagaIterator<void> {
  yield takeLatest(
    ACTIONS.BEGIN_ACCOUNT_SETUP_FLOW_MODERN,
    beginAccountSetupFlow,
  );
}

function* beginAccountSetupFlow(
  action: Record<string, any>,
): SagaIterator<void> {
  const { data } = yield call(apolloQuerySaga, {
    query: HasIdentityInfoDocument,
  });
  const hasIdentityInfo = data?.viewer?.user?.hasIdentityInfo;
  const latestProductSelection = data?.viewer?.user?.latestProductSelection;
  const shouldRouteToModuleTwo = hasIdentityInfo && latestProductSelection;
  const onboardingQuestions = yield call(queryOnboardingQuestions);
  const investAccountQuestions = yield call(queryInvestAccountQuestions);

  const allUserProfileQuestions =
    onboardingQuestions.userProfileSection.questions;
  const allFinancialSuitabilityQuestions =
    onboardingQuestions.financialSuitabilitySection.questions;
  const allInvestAndSuitabilityQuestions =
    allFinancialSuitabilityQuestions.concat(investAccountQuestions.questions);

  const cachedUserProfileStateFromParentFlow = yield select(
    (state) => state.newFlows.onboarding?.userProfileInput,
  );

  if (cachedUserProfileStateFromParentFlow) {
    yield put(setInitialUserProfileInput(cachedUserProfileStateFromParentFlow));
  }

  yield put(
    setIdentityFirstOnboardingQuestions([
      ...allUserProfileQuestions,
      ...allInvestAndSuitabilityQuestions,
    ]),
  );

  const [isOpenAccountBlocked, profile]: [
    boolean,
    IdentityFirstAccountSetupProfile,
  ] = yield all([
    call(isFunctionBlocked, 'OPEN_ACCOUNT'),
    call(queryViewerProfile),
    call(delay, 1200),
  ]);

  const isRolloverRetirementAccount =
    action.payload.mode === OPEN_ACCOUNT_FLOW_MODES.RETIREMENT;

  let initialStep = primaryQuestionMap(
    getInitialUserProfileQuestion(allUserProfileQuestions),
  );

  if (isOpenAccountBlocked) {
    initialStep = STEPS.SYSTEM_UNAVAILABLE;
  } else if (shouldRouteToModuleTwo) {
    initialStep = STEPS.COLLECT_PRIMARY_DISCLOSURES;
  }

  // Setup internal context.
  yield setContext({
    basePath: action.payload.basePath,
    profile: profile,
  });

  // Setup all action listeners that we are concerned about for the lifecycle
  // of the flow.
  yield takeEvery(
    ACTIONS.CHANGE_ACCOUNT_SETUP_FLOW_STEP_MODERN,
    function* (action: any): SagaIterator<void> {
      yield call(changeStep, action.payload);
    },
  );
  yield takeEvery(ACTIONS.CLICKED_CANCEL_ACCOUNT_SETUP_MODERN, handleCancel);
  yield takeEvery(ACTIONS.SELECTED_ACCOUNT_TYPE_MODERN, selectedAccountType);
  yield takeEvery(
    ACTIONS.SELECTED_RETIREMENT_ACCOUNT_TYPE_MODERN,
    selectedRetirementAccountTypeSaga,
  );
  yield takeEvery(
    ACTIONS.SELECTED_ROLLOVER_ACCOUNT_TYPE_MODERN,
    selectedRolloverAccountType,
  );
  yield takeEvery(
    ACTIONS.SELECTED_OTHER_ACCOUNT_TYPE_MODERN,
    selectedOtherAccountType,
  );
  yield takeEvery(
    ACTIONS.SUBMITTED_CUSTODIAL_CONTACT_INFO_FORM_MODERN,
    collectedCustodialContactInfo,
  );

  // Begin Invest User Profile Sections
  yield takeEvery(
    ACTIONS.SUBMITTED_CONTACT_INFO_FORM_MODERN,
    function* (action: any): SagaIterator<void> {
      yield call(
        finishedUserProfileQuestion,
        action,
        getNextQuestion(
          OnboardingIdentityFirstQuestionType.NameAndAddress,
          allUserProfileQuestions,
        ),
      );
    },
  );

  yield takeEvery(
    ACTIONS.SUBMITTED_IDENTITY_INFO_FORM_MODERN,
    function* (action: any): SagaIterator<void> {
      yield call(
        finishedUserProfileQuestion,
        action,
        getNextQuestion(
          OnboardingIdentityFirstQuestionType.DateOfBirthAndCitizenship,
          allUserProfileQuestions,
        ),
      );
    },
  );

  yield takeEvery(
    ACTIONS.COLLECTED_HOLDER_SSN_MODERN,
    function* (): SagaIterator<void> {
      yield call(finishedUserProfileModuleOne);
    },
  );

  yield takeEvery(
    ACTIONS.SUBMITTED_CONFIRM_PROFILE,
    function* (action: any): SagaIterator<void> {
      yield call(finishedUserProfileQuestion, action, null);
    },
  );
  // End Invest user Profile Sections

  yield takeEvery(
    ACTIONS.SUBMITTED_DISCLOSURES_FORM_MODERN,
    submittedDisclosuresForm,
  );

  yield takeEvery(
    'COLLECTED_TRUSTED_CONTACT',
    function* (): SagaIterator<void> {
      yield call(
        handleInvestAndSuitabilityNavigation,
        {
          payload: {
            field: 'suitability.trustedContact',
          },
        },
        allInvestAndSuitabilityQuestions,
      );
    },
  );
  yield takeEvery('SKIPPED_TRUSTED_CONTACT', function* (): SagaIterator<void> {
    yield call(
      handleInvestAndSuitabilityNavigation,
      {
        payload: {
          field: 'suitability.trustedContact',
        },
      },
      allInvestAndSuitabilityQuestions,
    );
  });

  yield takeEvery(
    ACTIONS.EXPLAINED_PROFILE_COLLECTION_MODERN,
    explainedProfileCollection,
  );

  yield takeEvery(
    ACTIONS.SUBMITTED_EMPLOYMENT_INFO_MODERN,
    function* (action: any): SagaIterator<void> {
      yield call(
        finishedFinancialSuitabilityQuestion,
        action,
        getNextQuestion(
          OnboardingIdentityFirstQuestionType.EmploymentStatusAndWhereDoYouWork,
          allFinancialSuitabilityQuestions,
        ),
      );
    },
  );
  yield takeEvery(
    ACTIONS.SUBMITTED_PROFILE_INPUT_MODERN,
    function* (action: any): SagaIterator<void> {
      yield call(
        handleInvestAndSuitabilityNavigation,
        action,
        allInvestAndSuitabilityQuestions,
      );
    },
  );

  yield takeEvery(
    ACTIONS.SUBMITTED_ANNUAL_INCOME_FORM,
    function* (action: any): SagaIterator<void> {
      yield call(
        finishedUserProfileQuestion,
        { ...action, payload: { ...action.payload, holder: 'primary' } },
        getNextQuestion(
          OnboardingIdentityFirstQuestionType.AnnualIncome,
          allUserProfileQuestions,
        ),
      );
    },
  );

  yield takeEvery(
    ACTIONS.SUBMITTED_LIQUID_NET_WORTH_FORM,
    function* (action: any): SagaIterator<void> {
      yield call(
        finishedUserProfileQuestion,
        { ...action, payload: { ...action.payload, holder: 'primary' } },
        getNextQuestion(
          OnboardingIdentityFirstQuestionType.LiquidNetWorth,
          allUserProfileQuestions,
        ),
      );
    },
  );

  yield takeEvery(
    ACTIONS.SUBMITTED_INTRODUCTION_SOURCE_MODERN,
    handleIntroductionSource,
  );
  yield takeEvery(
    ACTIONS.FINISHED_ACCOUNT_SETUP_REVIEW_MODERN,
    handleFinishedAccountReview,
  );
  yield takeEvery(
    ACTIONS.FINISHED_READING_ROLLOVER_RECEIPT_MODERN,
    handleFinishedReadingRolloverReceipt,
  );
  yield takeEvery(
    ACTIONS.FINISHED_READING_OTHER_ACCOUNT_RECEIPT_MODERN,
    handleFinishedReadingOtherAccountReceipt,
  );

  // Crypto onboarding
  yield fork(takeFlowStep, STEPS.CHOOSE_PIE, finishedChoosePie);

  // @ts-expect-error - TS2769 - No overload matches this call.
  yield takeEvery('SET_SELECTED_ROOT_PIE', handleSelectedRootPie);

  // Kick things off.
  yield call(changeStep, initialStep, true);

  if (isRolloverRetirementAccount) {
    yield put(selectedRetirementAccountType(RETIREMENT_ACCOUNT_TYPES.ROLLOVER));
  }
}

function* selectedAccountType(action: any): SagaIterator<void> {
  const accountType: keyof typeof INVEST_ACCOUNT_TYPES = action.payload;
  switch (accountType) {
    case INVEST_ACCOUNT_TYPES.RETIREMENT:
      yield call(changeStep, STEPS.SELECT_RETIREMENT_ACCOUNT_TYPE);
      break;
    case INVEST_ACCOUNT_TYPES.CUSTODIAL:
      yield call(changeStep, STEPS.SELECT_CUSTODIAL_ACCOUNT_TYPE);
      break;
    case INVEST_ACCOUNT_TYPES.OTHER:
      yield call(changeStep, STEPS.SELECT_OTHER_ACCOUNT_TYPE);
      break;
    default:
      yield call(
        changeStepAfterAccountSelection,
        accountType === INVEST_ACCOUNT_TYPES.JOINT_TAXABLE,
      );
      break;
  }
}

function* selectedRetirementAccountTypeSaga(action: any): SagaIterator<void> {
  const retirementAccountType: keyof typeof RETIREMENT_ACCOUNT_TYPES =
    action.payload;
  if (retirementAccountType === RETIREMENT_ACCOUNT_TYPES.ROLLOVER) {
    yield call(changeStep, STEPS.SELECT_ROLLOVER_ACCOUNT_TYPE);
  } else {
    yield call(changeStepAfterAccountSelection, false);
  }
}

function* selectedRolloverAccountType(): SagaIterator<void> {
  yield call(changeStepAfterAccountSelection, false);
}

function* selectedOtherAccountType(action: any): SagaIterator<void> {
  const otherAccountType: OfflineAccountRegistrationEnum = action.payload;
  try {
    yield put(showLoadingSpinner());

    yield all([
      call(apolloMutationSaga, {
        mutation: RequestOfflineOpenAccountDocument,
        variables: {
          input: {
            registration: otherAccountType,
          } satisfies RequestOfflineOpenAccountInput,
        },
      }),
      call(delay, 1600),
    ]);

    yield call(changeStep, STEPS.SHOW_PAPER_ACCOUNT_RECEIPT);
  } catch (e: any) {
    console.error(e); // eslint-disable-line

    yield put(
      displayNotification({
        type: NOTIFICATION_TYPES.ERROR_ALT,
        location: NOTIFICATION_LOCATIONS.OTHER_ACCOUNT_TYPES,
        message: 'There was a problem. Please contact support.',
      }),
    );
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function* collectedCustodialContactInfo(): SagaIterator<void> {
  yield call(changeStep, STEPS.REVIEW);
}

function* submittedDisclosuresForm(): SagaIterator<void> {
  yield call(changeStep, STEPS.COLLECT_EMPLOYMENT);
}

function* explainedProfileCollection(action: any): SagaIterator<void> {
  // if there's a secondary account,
  const nextStep =
    action.payload.holder === 'secondary'
      ? STEPS.COLLECT_SECONDARY_DISCLOSURES
      : STEPS.COLLECT_PRIMARY_DISCLOSURES;
  yield call(changeStep, nextStep);
}

function* handleIntroductionSource(): SagaIterator<void> {
  yield call(changeStep, STEPS.REVIEW);
}

function* handleFinishedReadingRolloverReceipt(): SagaIterator<void> {
  const accountId = yield select(
    (state: AppState) => state.newFlows.accountSetup.accountId,
  );
  yield put(accountSetupFlowFinished(accountId));
}

function* handleFinishedReadingOtherAccountReceipt(): SagaIterator<void> {
  const accountId = null;
  yield put(accountSetupFlowFinished(accountId));
}

function* handleFinishedAccountReview(action: any): SagaIterator<void> {
  const { isCustodialAccount, signature } = action.payload;
  if (isCustodialAccount) {
    yield call(handleFinishedCustodialReview, signature);
  } else {
    yield call(handleFinishedReview);
  }
}

function* handleCancel(): SagaIterator<void> {
  yield put(accountSetupFlowCanceled());
}
