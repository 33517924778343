import { styled, css, Box } from '@m1/liquid-react';
import * as React from 'react';

export const OuterRectangleSpan = styled.span(
  ({ theme }) => css`
    background: ${theme.colors.backgroundNeutralTertiary};
    border-radius: 50px;
    display: inline-flex;
    width: inherit;
    height: 28px;
    padding: 4px;
    display: flex;
    align-items: center;
  `,
);

export const ProgressIndicatorBox = styled(Box)<{ percentage: number }>`
  background: linear-gradient(270deg, #3d5a94 26.12%, #a6c9da 100%);
  border-radius: 39px;
  height: 20px;
  width: ${(props) => `${props.percentage}%`};
`;

type ProgressIndicatorProps = {
  percentage: number;
};

export const ProgressIndicator = ({ percentage }: ProgressIndicatorProps) => {
  return (
    <OuterRectangleSpan>
      <ProgressIndicatorBox percentage={percentage} />
    </OuterRectangleSpan>
  );
};
