import * as React from 'react';

import { Sliceable_SystemPie_Fragment } from '~/graphql/types';
import { useSelector } from '~/redux/hooks';
import { APP_MODES } from '~/static-constants';
import { Pill } from '~/toolbox/Pill';

import { BreadCrumb } from './BreadCrumb';

type SystemPieBreadCrumbsProps = {
  systemPie: Sliceable_SystemPie_Fragment;
  navigable: boolean;
};

export const SystemPieBreadCrumbs = ({
  systemPie,
  navigable,
}: SystemPieBreadCrumbsProps) => {
  const mode = useSelector((state) => state.mode);
  const toLink =
    mode === APP_MODES.ADD
      ? '/d/c/add-slices/model-portfolios'
      : '/d/research/model-portfolios';

  const pieCategory =
    systemPie.categorizationDetails.length > 0
      ? systemPie.categorizationDetails[0]
      : null;

  return (
    <Pill.Group>
      <BreadCrumb
        label="Model Portfolios"
        {...(navigable && {
          linkProps: { to: toLink },
        })}
      />
      {pieCategory && (
        <BreadCrumb
          label={pieCategory.name}
          {...(navigable && {
            linkProps: {
              to: `${toLink}/:categoryKey`,
              params: {
                categoryKey: pieCategory?.key,
              },
            },
          })}
        />
      )}
    </Pill.Group>
  );
};
