import * as React from 'react';
import { Route } from 'react-router-dom';

import { ResendVerificationEmail } from './ResendVerificationEmail';

export const ResendVerificationEmailRoute = () => (
  <Route
    element={<ResendVerificationEmail />}
    handle={{
      fallbackRoute: {
        to: '/d/invest',
      },
    }}
    path="resend-verification-email"
  />
);
