import { Card } from '@m1/liquid-react';
import * as React from 'react';

import {
  TimingDescriptionCell,
  TimingDescriptionCellProps,
} from '../../transfers-presentation-components';

export const TransferRequirementsTimingDescriptionCell = (
  props: TimingDescriptionCellProps,
) => {
  if (!props.timingDescription) {
    return null;
  }
  return (
    <Card
      borderColor="transparent"
      mt={16}
      p="12px 16px"
      width="100%"
      backgroundColor="backgroundInfoSubtle"
    >
      <TimingDescriptionCell
        dependsOnHolds={props.dependsOnHolds}
        dependsOnSells={props.dependsOnSells}
        timingDescription={props.timingDescription}
      />
    </Card>
  );
};
