import classNames from 'classnames';
import * as React from 'react';

import style from './style.module.scss';

type Props = {
  align?: 'left' | 'center' | 'right';
  borderLeftColor?: string;
  children?: React.ReactNode;
  colSpan?: number;
  content?: React.ReactNode;
  onClick?: (...args: Array<any>) => any;
  width?: string | number;
};

export class TableCell extends React.Component<Props> {
  static defaultProps = {
    align: 'left',
    colSpan: 1,
    width: 'auto',
  };

  render() {
    const { align, borderLeftColor, children, content, width } = this.props;
    const classes = classNames(style.cell, {
      [style.click]: Boolean(this.props.onClick),
      [style.alignLeft]: align === 'left',
      [style.alignCenter]: align === 'center',
      [style.alignRight]: align === 'right',
    });

    return (
      <td
        className={classes}
        colSpan={this.props.colSpan}
        // @ts-expect-error - TS2322 - Type '(e: Event) => void' is not assignable to type 'MouseEventHandler<HTMLTableDataCellElement>'.
        onClick={this.handleClick}
        style={{
          width,
        }}
      >
        {borderLeftColor && (
          <div
            className={style.cellBorderColor}
            style={{
              background: borderLeftColor,
            }}
          />
        )}
        {content || children}
      </td>
    );
  }

  handleClick = (e: Event): void => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };
}
