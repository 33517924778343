import {
  ApolloClient,
  MutationOptions,
  MutationResult,
  NormalizedCache,
} from '@apollo/client';
import { SagaIterator } from 'redux-saga';
import { call, getContext } from 'redux-saga/effects';

export function* apolloMutationSaga(
  options: MutationOptions,
): SagaIterator<MutationResult> {
  const apolloClient: ApolloClient<NormalizedCache> =
    yield getContext('apolloClient');

  if (!apolloClient) {
    throw new Error('apolloMutationSaga required an Apollo Client in context.');
  }

  return yield call(() => {
    return apolloClient.mutate(options);
  });
}
