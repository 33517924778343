import { Box } from '@m1/liquid-react';

import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { PersonalLoansApplicationFlow } from '~/flows';
import { useNavigate } from '~/hooks/useNavigate';

export const PersonalLoansOnboardingPage = () => {
  const navigate = useNavigate();
  const handleFinish = (): void => {
    navigate({
      to: '/d/borrow/marketing',
      options: { state: { forceRefetch: true } },
    });
  };

  return (
    <PageErrorBoundary onClickRetry={handleFinish} retryButtonLabel="Exit">
      <Box backgroundColor="backgroundNeutralSecondary">
        <PersonalLoansApplicationFlow
          basePath="onboarding/personal-loans-onboarding"
          onFinish={handleFinish}
        />
      </Box>
    </PageErrorBoundary>
  );
};
