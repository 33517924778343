export const PERSONAL_LOAN_APPLICATION_ACTIONS = Object.freeze({
  PERSONAL_LOANS_STORE_APPLICATION_ID:
    'PERSONAL_LOANS_STORE_APPLICATION_ID' as const,
  PERSONAL_LOANS_STORE_LOAN_ID: 'PERSONAL_LOANS_STORE_LOAN_ID' as const,
  SET_PERSONAL_LOAN_DEPOSIT_ACCOUNT:
    'SET_PERSONAL_LOAN_DEPOSIT_ACCOUNT' as const,
  COMPLETE_PLAID_INCOME_VERIFICATION:
    'COMPLETE_PLAID_INCOME_VERIFICATION' as const,
  SET_PERSONAL_LOAN_AUTOPAY_ACCOUNT:
    'SET_PERSONAL_LOAN_AUTOPAY_ACCOUNT' as const,
});

export type SetPersonalLoanAutoPayAccountAction = {
  payload: string | null | undefined;
  type: typeof PERSONAL_LOAN_APPLICATION_ACTIONS.SET_PERSONAL_LOAN_AUTOPAY_ACCOUNT;
};

export type SetPersonalLoanDepositAccountAction = {
  payload: string | null | undefined;
  type: typeof PERSONAL_LOAN_APPLICATION_ACTIONS.SET_PERSONAL_LOAN_DEPOSIT_ACCOUNT;
};

export type CompletePlaidIncomeVerificationAction = {
  payload: {
    onExit: boolean;
    onSuccess: boolean;
  };
  type: typeof PERSONAL_LOAN_APPLICATION_ACTIONS.COMPLETE_PLAID_INCOME_VERIFICATION;
};

export type PersonalLoansStoreApplicationId = {
  payload: {
    applicationId: string;
  };
  type: typeof PERSONAL_LOAN_APPLICATION_ACTIONS.PERSONAL_LOANS_STORE_APPLICATION_ID;
};

export type PersonalLoansStoreLoanId = {
  payload: {
    loanId: string;
  };
  type: typeof PERSONAL_LOAN_APPLICATION_ACTIONS.PERSONAL_LOANS_STORE_LOAN_ID;
};

export const setPersonalLoanAutoPayAccount = (
  payload: SetPersonalLoanAutoPayAccountAction['payload'],
) => ({
  payload,
  type: PERSONAL_LOAN_APPLICATION_ACTIONS.SET_PERSONAL_LOAN_AUTOPAY_ACCOUNT,
});

export const setPersonalLoanDepositAccount = (
  payload: SetPersonalLoanDepositAccountAction['payload'],
): SetPersonalLoanDepositAccountAction => ({
  payload,
  type: PERSONAL_LOAN_APPLICATION_ACTIONS.SET_PERSONAL_LOAN_DEPOSIT_ACCOUNT,
});

export const completePlaidIncomeVerification = (
  payload: CompletePlaidIncomeVerificationAction['payload'],
): CompletePlaidIncomeVerificationAction => ({
  payload,
  type: PERSONAL_LOAN_APPLICATION_ACTIONS.COMPLETE_PLAID_INCOME_VERIFICATION,
});

export type PersonalLoansApplicationAction =
  | SetPersonalLoanDepositAccountAction
  | SetPersonalLoanAutoPayAccountAction
  | CompletePlaidIncomeVerificationAction
  | PersonalLoansStoreApplicationId
  | PersonalLoansStoreLoanId;
