import { Box, PL, Card } from '@m1/liquid-react';
import * as React from 'react';

import { useDisclosuresForSavingsAndCashPageQuery } from '~/graphql/hooks';
import { SaveAccountTypeEnum } from '~/graphql/types';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

export const DisclosuresForCashPage = () => {
  const { data, loading } = useDisclosuresForSavingsAndCashPageQuery({
    variables: {
      accountType: SaveAccountTypeEnum['Cash'],
    },
  });

  if (loading) {
    return <Spinner />;
  }
  const latestAgreements =
    data?.viewer.save?.savings?.savingsAgreements?.agreements?.documents;

  if (!latestAgreements) {
    return (
      <PL content="Cash disclosures are currently unavailable. Please try again later." />
    );
  }

  return (
    <Card p={32}>
      {latestAgreements.map((document) => {
        return (
          <Box key={`${document.title}`} mt={4}>
            <Link to={document.url} target="_blank">
              {document.title}
            </Link>
          </Box>
        );
      })}
    </Card>
  );
};
