import { Button, LM } from '@m1/liquid-react';
import * as React from 'react';
import { Path, useFormContext } from 'react-hook-form';

import { ControlledCheckbox } from '~/components/form/ControlledCheckbox';

import { InvestActivityFormValues } from '../ActivityPage';

import { StyledFilterByTypeOptionRoot } from './ActivityTable.styled';

export const FilterByTypeOption = ({
  name,
  label,
}: {
  name: Path<InvestActivityFormValues>;
  label: string;
}) => {
  const { control, setValue } = useFormContext<InvestActivityFormValues>();

  const handleCheck = (isChecked: boolean) => {
    setValue(name, isChecked);
  };

  const handleOnlyClick = () => {
    const updatedFormValues: Partial<InvestActivityFormValues> = {
      includeCategoryCash: false,
      includeCategoryDividend: false,
      includeCategoryPosition: false,
      includeCategoryTrading: false,
      includeCategoryTransfer: false,
      [name]: true,
    };

    for (const [key, value] of Object.entries(updatedFormValues)) {
      setValue(key as keyof InvestActivityFormValues, value, {
        shouldDirty: true,
      });
    }
  };
  return (
    <StyledFilterByTypeOptionRoot>
      <ControlledCheckbox
        control={control}
        name={name}
        label={label}
        size="small"
        onChange={(e) => handleCheck(e.target.checked)}
      />
      <LM onClick={handleOnlyClick}>
        <Button
          kind="link"
          label="Only"
          style={{ paddingRight: '16px', fontSize: '16px' }}
        />
      </LM>
    </StyledFilterByTypeOptionRoot>
  );
};
