import { HXS, styled } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { TextWithPhoneLinks } from '~/components/TextWithPhoneLinks';
import { useCreditCardClosedPageQuery } from '~/graphql/hooks';
import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';

const StyledContainer = styled(Container)`
  width: 497px;
  margin-top: 32px;
`;

const StyledHeading = styled(HXS)`
  width: 501px;
`;

export const CreditCardClosedPage = () => {
  const { data, error } = useCreditCardClosedPageQuery();
  const closedAccountContent = data?.viewer.credit?.closedAccountContent;

  if (!error && !data) {
    return null;
  }

  if (error || !closedAccountContent) {
    return <GenericSystemError />;
  }

  return (
    <StyledContainer>
      <StyledHeading mb={20}>{closedAccountContent.title}</StyledHeading>
      <TextWithPhoneLinks
        text={closedAccountContent.subtitle}
        phoneNumber={closedAccountContent.phoneNumber}
      />
      <Link to="/d/settings/documents" mt={32}>
        View statements
      </Link>
    </StyledContainer>
  );
};
