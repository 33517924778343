import { Button } from '@m1/liquid-react';
import * as React from 'react';

import { AppContext } from '~/AppContext';

import { StepTemplate } from '../components/StepTemplate';

type NoLinkedAccountProps = {
  onFinishStep: () => void;
};

export const NoLinkedAccount = ({ onFinishStep }: NoLinkedAccountProps) => {
  const { analytics } = React.useContext(AppContext);

  const title = 'You’ll first need to connect a bank account.';
  const content =
    'Please link a checking or savings account before applying for a loan. Once approved, we’ll send loan funds to this account.';
  const button = (
    <Button
      label="Connect a bank"
      onClick={() => {
        analytics.recordEvent('m1_personal_loan_link_account_now_clicked');
        onFinishStep();
      }}
      size="large"
    />
  );
  const illustrationName = 'bankConnectionBank';

  return (
    <StepTemplate
      title={title}
      content={content}
      button={button}
      illustrationName={illustrationName}
    />
  );
};
