import merge from 'lodash-es/merge';
import compose from 'recompose/compose';
import mapProps from 'recompose/mapProps';
import { reduxForm } from 'redux-form';

export function connectForm(
  config: ((...args: Array<any>) => any) | Record<string, any>,
) {
  return compose<any, any>(
    mapProps((props) => {
      return typeof config === 'function'
        ? merge({}, config(props), props)
        : {
            ...config,
            // @ts-expect-error - TS2698 - Spread types may only be created from object types.
            ...props,
          };
    }),
    // @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
    reduxForm(),
  );
}
