import { Box, PL, PM, Tooltip, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import React from 'react';

const StyledReferralCountContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding: 2rem;
  text-align: left;
  background: var(--colors-background-neutral-secondary);
  border-radius: 8px;
`;

export const ReferralCompletions = ({
  completeReferralCount,
}: {
  completeReferralCount: number;
}) => {
  return (
    <div>
      {completeReferralCount > 0 ? (
        <StyledReferralCountContainer>
          <Icon color="primary" p="0 10px" name="present24" />
          <PL as="div" pl={16}>
            You received <b>{completeReferralCount}</b> successful referrals so
            far! Where's my money?
            <Tooltip
              placement="top-start"
              body={
                <PM p={16}>
                  It may take up to 14 days for money to be credited to your
                  investment account. Your credit will appear on your
                  portfolio’s Activity screen.
                </PM>
              }
            />
          </PL>
        </StyledReferralCountContainer>
      ) : (
        <StyledReferralCountContainer>
          <Icon color="primary" p="0 10px" name="smiley24" />
          <PL pl={16}>
            You haven't received any successful referrals yet, keep sharing!
          </PL>
        </StyledReferralCountContainer>
      )}
    </div>
  );
};
