import { ListItem, PL } from '@m1/liquid-react';
import React from 'react';

export const SingleLineItem = ({
  label,
  subtitle,
  value,
  title,
  padding,
}: {
  title?: string | null;
  label?: string | null;
  value: string | null;
  subtitle?: string | null;
  padding?: string | null;
}) => {
  return (
    <ListItem
      pl={0}
      pr={0}
      header={
        <PL content={label || title} color="foregroundNeutralSecondary" />
      }
      headerRight={<PL content={value} fontWeight={600} whiteSpace="nowrap" />}
      subheader={
        <PL
          content={subtitle && subtitle}
          pr={48}
          color="foregroundNeutralTertiary"
        />
      }
      padding={padding || '16px'}
      size="LARGE"
    />
  );
};
