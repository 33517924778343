import { Button } from '@m1/liquid-react';
import * as React from 'react';

import { Grid } from '~/toolbox/grid';

export const SubmitButtons = ({
  buttonLayout,
  isDisabled,
}: {
  buttonLayout: 'primary' | 'secondary';
  isDisabled: boolean;
}) => {
  switch (buttonLayout) {
    case 'secondary':
      return (
        <Grid.Row xsTextRight>
          <Grid.Col xs={12}>
            <Button
              type="submit"
              kind="primary"
              size="medium"
              label="Save"
              disabled={isDisabled}
            />
          </Grid.Col>
        </Grid.Row>
      );
    default:
      return (
        <Grid.Row
          style={{
            paddingTop: 48,
            textAlign: 'left',
          }}
          xsTextCenter
        >
          <Grid.Col xs={12}>
            <Button
              type="submit"
              kind="primary"
              size="large"
              label="Continue"
            />
          </Grid.Col>
        </Grid.Row>
      );
  }
};
