import { Button, HS, PL, Box, styled, BoxProps } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { useSupportLink } from '~/hooks/useSupportLink';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

import { NavigableButton } from '../NavigableButton';

export type GenericSystemErrorProps = BoxProps & {
  button?: React.ReactNode;
  content?: string;
  image?: React.ReactNode;
  onClickRetry?: (() => void) | (() => Promise<void>) | null | undefined;
  title?: string;
};

const StyledPl = styled(PL)`
  margin-top: 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XSMALL}) {
    padding: 10px;
  }
`;

export const GenericSystemError = ({
  button,
  content = 'We’re having trouble retrieving the information that you requested. Try refreshing this page to resolve the error. If the error persists, contact our support team for further assistance.',
  image,
  onClickRetry,
  title = 'Sorry, something went wrong.',
  ...props
}: GenericSystemErrorProps) => {
  const supportLink = useSupportLink();

  /*
   * Doing this, so we're able to pass a function that returns a Promise for the
   * onClickRetry prop (otherwise it would cause a "no-misused-promises" lint error).
   * This wouldn't be considered a "misuse" of Promises because we're not awaiting
   * anything by refetching. The component props will change when it gets the
   * refetched data, so it will be re-rendered.
   */
  const handleRetryClick = () => {
    if (onClickRetry) {
      onClickRetry();
    }
  };

  return (
    <Box margin="0 auto" maxWidth={750} pt={96} textAlign="center" {...props}>
      {/* @ts-expect-error - TS2322 - Type '{ alt: string; name: "importantAlert"; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'. */}
      {image || <Illustration alt="" name="importantAlert" />}
      <HS content={title} mt={48} />
      <StyledPl content={content} />
      <ButtonGroup behavior="centered" mt={48}>
        {onClickRetry && (
          <Button label="Retry" kind="primary" onClick={handleRetryClick} />
        )}
        {button || (
          <NavigableButton
            label="Contact Support"
            size="large"
            kind={onClickRetry ? 'secondary' : 'primary'}
            {...supportLink}
          />
        )}
      </ButtonGroup>
    </Box>
  );
};
