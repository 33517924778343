import * as React from 'react';
import { Route } from 'react-router-dom';

import { CreditCardApplicationPage } from './CreditCardApplicationPage';

export const CreditCardApplicationRoute = () => {
  return (
    <Route
      path="credit-card-application/:step?"
      handle={{
        fallbackRoute: {
          to: '/d/spend/credit',
        },
        navigationState: {
          forceRefetch: true,
        },
      }}
      element={<CreditCardApplicationPage />}
    />
  );
};
