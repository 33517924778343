import * as React from 'react';

type Props = {
  children?: React.ReactNode;
  initialMinHeight?: number;
  maxHeight: number;
};

type State = {
  minHeight: number | null | undefined;
};

export class PreserveHeight extends React.Component<Props, State> {
  static defaultProps = {
    maxHeight: Number.MAX_SAFE_INTEGER,
  };

  constructor(props: Props) {
    super(props);

    const minHeight =
      typeof props.initialMinHeight === 'number'
        ? props.initialMinHeight
        : null;
    this.state = {
      minHeight,
    };
  }

  render() {
    return (
      <div
        style={{
          minHeight: this.state.minHeight ?? undefined,
          width: '100%',
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
