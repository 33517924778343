import * as React from 'react';

import { HelpPopup, HelpPopupProps } from '~/components/HelpPopup';

type NegativeDividendPopupProps = HelpPopupProps & {
  tooltipCopy: string;
};

export const NegativeDividendPopup = ({
  tooltipCopy,
  ...rest
}: NegativeDividendPopupProps) => {
  return <HelpPopup placement="bottom-start" {...rest} body={tooltipCopy} />;
};
