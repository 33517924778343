import { styled } from '@m1/liquid-react';

export const HistoricalChartContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const TooltipValue = styled.div`
  margin: 0.3rem 0;
  font-size: 1.6rem;
  font-weight: 700;
`;

export const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const PeriodReturn = styled.div`
  padding-bottom: 1px;
  margin-right: 16px;
  font-weight: 700;

  > * {
    margin: 0 4px;
  }
`;

export const ControlsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NoDataMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
