import { Button } from '@m1/liquid-react';
import * as React from 'react';

import {
  EditSlicesButtonGroupFragment,
  UserKeysFragment,
} from '~/graphql/types';

import {
  equalizeSliceListAllocations,
  Pie,
  PortfolioSlice,
  Slice,
} from '~/pie-trees';
import { useDispatch, useSelector } from '~/redux/hooks';
import { PORTFOLIO_EDITOR_STEPS } from '~/static-constants';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

import { useMoveSlicesDisabled } from '../../hooks/useMoveSlicesDisabled';
import { EditorFeature } from '../../PortfolioEditor.types';

import { MoveSlicesButton } from './MoveSlicesButton';

const getPortfolioSlicesFromSlices = (
  slices: Array<Slice>,
  sourcePiePortfolioSliceId: string,
): Array<PortfolioSlice> => {
  if (!slices.length) {
    return [];
  }

  return slices.reduce<Array<PortfolioSlice>>((portfolioSliceIds, slice) => {
    // @ts-expect-error - TS2339 - Property 'portfolioLinks' does not exist on type 'Sliceable'.
    if (!slice.to.portfolioLinks) {
      return portfolioSliceIds;
    }

    // retrieve the portfolioLink who's immediate parent (last ancestor) is the pie we're editing
    // @ts-expect-error - TS2339 - Property 'portfolioLinks' does not exist on type 'Sliceable'. | TS7006 - Parameter 'link' implicitly has an 'any' type.
    const matchingPortfolioLink = slice.to.portfolioLinks.find((link) => {
      if (!link.ancestors?.length) {
        return null;
      }

      const lastAncestor = link.ancestors[link.ancestors.length - 1];
      return lastAncestor && lastAncestor.id === sourcePiePortfolioSliceId;
    });

    if (matchingPortfolioLink) {
      portfolioSliceIds.push({
        id: matchingPortfolioLink.id,
        to: slice,
      });
    }

    return portfolioSliceIds;
  }, []);
};

type SourcePieSelectorData = {
  sourcePie: Pie;
  sourcePieBeforeUpdates: Pie;
  sourcePiePortfolioSliceId: string;
};

type SelectedSlicesData = {
  allSliceIds: Array<string>;
  portfolioSlices: Array<PortfolioSlice>;
  selectedSlices: Array<Slice>;
  selectedSlicesIds: Array<string>;
};

type EditSlicesButtonGroupProps = {
  disabledFeatures?: Array<EditorFeature>;
  hideCheckboxes?: boolean;
  moveSlices: EditSlicesButtonGroupFragment | null | undefined;
  pie: Pie | null | undefined;
  user: UserKeysFragment | null | undefined;
};

export const EditSlicesButtonGroup = ({
  disabledFeatures = [],
  hideCheckboxes = false,
  moveSlices,
  pie,
  user,
}: EditSlicesButtonGroupProps) => {
  const dispatch = useDispatch();

  const { sourcePie, sourcePieBeforeUpdates, sourcePiePortfolioSliceId } =
    useSelector<SourcePieSelectorData>((state) => {
      const { sourcePie, sourcePieBeforeUpdates, sourcePiePortfolioSliceId } =
        state.newFlows.PORTFOLIO_EDITOR;

      return {
        sourcePie,
        sourcePieBeforeUpdates,
        sourcePiePortfolioSliceId,
      };
    });

  const { allSliceIds, portfolioSlices, selectedSlices, selectedSlicesIds } =
    React.useMemo<SelectedSlicesData>(() => {
      const pieSlices = pie?.slices || [];

      if (!pieSlices.length) {
        return {
          allSliceIds: [],
          portfolioSlices: [],
          selectedSlices: [],
          selectedSlicesIds: [],
        };
      }

      const allSliceIds = pieSlices.map((slice) => slice.to.__id);
      const selectedSlices = pieSlices.filter((slice) => slice.to.__checked);
      const selectedSlicesIds = selectedSlices.map((slice) => slice.to.__id);
      const portfolioSlices = getPortfolioSlicesFromSlices(
        selectedSlices,
        sourcePiePortfolioSliceId,
      );

      return {
        allSliceIds,
        portfolioSlices,
        selectedSlices: hideCheckboxes ? pieSlices : selectedSlices,
        selectedSlicesIds,
      };
    }, [hideCheckboxes, pie?.slices, sourcePiePortfolioSliceId]);

  const portfolioSlicesOfSlicesMarkedForDeletion = React.useMemo<
    Array<PortfolioSlice>
  >(() => {
    const currentSlices = sourcePie.slices || [];
    const originalSlices = sourcePieBeforeUpdates.slices || [];

    const zeroPercentPieSlices = currentSlices.filter(
      (s) => s.to.type === 'old_pie' && s.percentage === 0,
    );

    const deletedPieSlices = originalSlices.filter((slice) => {
      const matchingSlice = currentSlices.find(
        (s) => s.to.__id === slice.to.__id,
      );

      return slice.to.type === 'old_pie' && !matchingSlice;
    });

    return getPortfolioSlicesFromSlices(
      zeroPercentPieSlices.concat(deletedPieSlices),
      sourcePiePortfolioSliceId,
    );
  }, [sourcePie, sourcePieBeforeUpdates, sourcePiePortfolioSliceId]);

  const onEqualizeClick = React.useCallback(() => {
    const equalizedSlices = equalizeSliceListAllocations(selectedSlices, true);

    equalizedSlices.forEach((slice) => {
      dispatch({
        payload: {
          id: slice.to.__id,
          percentage: slice.percentage,
        },
        type: 'ADJUSTED_PORTFOLIO_EDITOR_SLICE_PERCENTAGE',
      });
    });
  }, [dispatch, selectedSlices]);

  const onRemoveClick = React.useCallback(() => {
    dispatch({
      payload: selectedSlices,
      type: 'REMOVE_PORTFOLIO_EDITOR_SLICES',
    });

    dispatch({
      payload: {
        ids: selectedSlicesIds,
      },
      type: 'REMOVE_PORTFOLIO_EDITOR_ACTIVE_SLICE_IDS',
    });
  }, [dispatch, selectedSlices, selectedSlicesIds]);

  const disableEqualize = React.useMemo<boolean>(() => {
    const pieSlices = pie?.slices || [];
    const allSlicesAreSelected = selectedSlices.length === pieSlices.length;

    return Boolean(
      pieSlices.length > 100 ||
        (!hideCheckboxes && (!selectedSlices.length || !allSlicesAreSelected)),
    );
  }, [hideCheckboxes, selectedSlices.length, pie?.slices]);

  const currentStep = useSelector<string>(
    (state) => state.newFlows.PORTFOLIO_EDITOR.step,
  );

  const { disabled: disableMoveSlices, reason } = useMoveSlicesDisabled(
    allSliceIds,
    selectedSlices,
    sourcePieBeforeUpdates,
  );

  // tooltip text used for the move button if its disabled
  const tooltipText = React.useMemo<
    ReadonlyArray<string | null | undefined> | null | undefined
  >(() => {
    if (moveSlices?.moveDisabledTradingWindowTooltip) {
      return moveSlices.moveDisabledTradingWindowTooltip.text;
    } else if (currentStep === PORTFOLIO_EDITOR_STEPS.SOURCE_PIE) {
      return moveSlices?.moveDisabledDestinationPieSet?.text;
    } else if (reason === 'ALL_SLICES_ARE_SELECTED') {
      return [
        'You cannot move all Slices out of a Pie because all Pies require a minimum of one Slice.',
      ];
    } else if (reason === 'INCLUDES_NEW_SLICES') {
      return [
        'A newly added Slice needs to be saved to a Pie before it can be moved. Avoid that step by only adding Slices where you ultimately want them.',
      ];
    } else if (reason === 'INCLUDES_INACTIVE_SLICES') {
      return [
        'A Slice that you’ve selected is inactive and can’t be moved. Delete inactive Slice(s) to continue.',
      ];
    } else if (reason === 'INCLUDES_ZERO_PERCENT_SLICES') {
      return ['You cannot move a Slice set to 0%.'];
    }

    return null;
  }, [
    currentStep,
    moveSlices?.moveDisabledTradingWindowTooltip,
    moveSlices?.moveDisabledDestinationPieSet?.text,
    reason,
  ]);

  return (
    <ButtonGroup behavior="centered" height="min-content">
      {!disabledFeatures.includes('EQUALIZE_SLICES') && (
        <Button
          disabled={disableEqualize}
          kind="secondary"
          label="Equalize"
          onClick={onEqualizeClick}
          size="medium"
        />
      )}
      {!disabledFeatures.includes('MOVE_SLICES') && moveSlices && (
        <MoveSlicesButton
          disabled={!moveSlices.enableMoveSlices || disableMoveSlices}
          portfolioSlicesOfSlicesMarkedForDeletion={
            portfolioSlicesOfSlicesMarkedForDeletion
          }
          tooltipText={tooltipText}
          portfolioSlicesToMove={portfolioSlices}
          user={user}
        />
      )}
      {!disabledFeatures.includes('DELETE_SLICES') && (
        <Button
          disabled={selectedSlices.length === 0}
          kind="secondary"
          label="Delete"
          onClick={onRemoveClick}
          size="medium"
        />
      )}
    </ButtonGroup>
  );
};
