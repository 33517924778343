export const NAVIGATION_ACTIONS = Object.freeze({
  SET_SPEND_ACCOUNT_DESTINATION: 'SET_SPEND_ACCOUNT_DESTINATION' as const,
  SET_BORROW_ACCOUNT_DESTINATION: 'SET_BORROW_ACCOUNT_DESTINATION' as const,
  ENDED_INVEST_MARKETING_SESSION: 'ENDED_INVEST_MARKETING_SESSION' as const,
  SET_INVEST_MARKETING_SESSION: 'SET_INVEST_MARKETING_SESSION' as const,
  SET_FINANCIAL_SUITABILITY_PRODUCT:
    'SET_FINANCIAL_SUITABILITY_PRODUCT' as const,
});

export type SpendAccountDestinationEnumValues =
  | 'credit'
  | 'checking'
  | 'savings';

export type BorrowAccountDestinationEnumValues = 'margin' | 'personal';

export type EndedInvestMarketingSession = {
  type: typeof NAVIGATION_ACTIONS.ENDED_INVEST_MARKETING_SESSION;
};

export type SetInvestMarketingSession = {
  type: typeof NAVIGATION_ACTIONS.SET_INVEST_MARKETING_SESSION;
};

export type SetSpendAccountDestinationAction = {
  payload: SpendAccountDestinationEnumValues;
  type: 'SET_SPEND_ACCOUNT_DESTINATION';
};

export type SetBorrowAccountDestinationAction = {
  payload: BorrowAccountDestinationEnumValues;
  type: 'SET_BORROW_ACCOUNT_DESTINATION';
};

export const setSpendAccountDestination = (
  payload: SetSpendAccountDestinationAction['payload'],
): SetSpendAccountDestinationAction => ({
  payload,
  type: NAVIGATION_ACTIONS.SET_SPEND_ACCOUNT_DESTINATION,
});

export const setBorrowAccountDestination = (
  payload: SetBorrowAccountDestinationAction['payload'],
): SetBorrowAccountDestinationAction => ({
  payload,
  type: NAVIGATION_ACTIONS.SET_BORROW_ACCOUNT_DESTINATION,
});

export type SetFinancialSuitabilityProductAction = {
  payload: string;
  type: 'SET_FINANCIAL_SUITABILITY_PRODUCT';
};
export const setFinancialSuitabilityProduct = (
  payload: SetFinancialSuitabilityProductAction['payload'],
): SetFinancialSuitabilityProductAction => ({
  payload,
  type: NAVIGATION_ACTIONS.SET_FINANCIAL_SUITABILITY_PRODUCT,
});

export type NavigationAction =
  | EndedInvestMarketingSession
  | SetInvestMarketingSession
  | SetSpendAccountDestinationAction
  | SetBorrowAccountDestinationAction
  | SetFinancialSuitabilityProductAction;
