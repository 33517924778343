import { Box, Button, Flex, HM, PL } from '@m1/liquid-react';
import React from 'react';

import { useForm } from 'react-hook-form';

import { AppContext } from '~/AppContext';
import { PasswordInput } from '~/components/form/PasswordInput';
import { useWizardContext } from '~/flows/wizard';
import {
  useAuthenticateMutation,
  useTwoFactorAuthQuery,
  useUserEmailQuery,
} from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { submitReactFormData } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { FIELD_MAX_LENGTHS } from '~/static-constants';
import { useToast } from '~/toasts';

export type EnhancedAuthPasswordForm = {
  password: string;
};

type PerformEnhancedAuthProps = {
  exitFlow: () => void;
};

export const PerformEnhancedAuth = ({ exitFlow }: PerformEnhancedAuthProps) => {
  const { control, handleSubmit, watch, formState } =
    useForm<EnhancedAuthPasswordForm>({
      defaultValues: {
        password: '',
      },
    });

  const { data: userEmailData } = useUserEmailQuery();

  const { goTo } = useWizardContext();

  const { addToast } = useToast();

  const dispatch = useDispatch();

  const password = watch('password');

  const { data: twoFactorAuthQueryData } = useTwoFactorAuthQuery({
    fetchPolicy: 'network-only',
  });

  const { auth } = React.useContext(AppContext);

  const analytics = useAnalytics();

  React.useEffect(() => {
    analytics.pageView('m1_MFA_re-enter_password_page_view');
  }, [analytics]);

  const [authenticate, { loading }] = useAuthenticateMutation({
    onCompleted: (data) => {
      if (!data?.authenticate?.outcome) {
        throw new Error('Unexpected mutation response after authenticating.');
      }

      // Update auth with the outcome of this mutation.
      // This ensures all future requests are made with the correct auth headers.
      auth.updateRequiredAuth(data.authenticate.outcome);

      if (!twoFactorAuthQueryData?.viewer?.user) {
        throw new Error('Unable to read user field from auth query.');
      }

      const { isTwoFactorAuthEnabled } = twoFactorAuthQueryData.viewer.user;

      // If the user successfully generated a 2FA token, go to the next step.
      if (isTwoFactorAuthEnabled) {
        goTo('DISABLE_TWO_FACTOR_AUTH');
      } else {
        goTo('AUTH_APP_SETUP');
      }
    },
    onError: (error) => {
      const requiresSteppedUpAuth = error.graphQLErrors.some(
        (graphqlError) =>
          graphqlError.extensions.code === 'EMAIL_AMFA_REQUIRED',
      );

      if (requiresSteppedUpAuth) {
        // publish the form data to the store, so we can consume it in the next step
        dispatch(
          submitReactFormData({
            enhancedAuthPasswordForm: {
              password,
            },
          }),
        );

        goTo('COLLECT_AMFA_CODE');
      } else {
        addToast({
          content: error.message,
          kind: 'alert',
          duration: 'long',
        });
      }
    },
  });

  return (
    <Flex flexDirection="column" maxWidth={550}>
      <HM
        pt={64}
        fontWeight={300}
        content="Make changes to two-factor authentication."
      />
      <PL
        py={16}
        content="For security purposes, start by entering your M1 password."
      />
      <form
        data-testid="enhanced-auth-password"
        name="enhanced-auth-password"
        onSubmit={handleSubmit(async (data) => {
          if (!userEmailData?.viewer?.user?.username) {
            throw new Error('Attempted to authenticate without a username');
          }

          await authenticate({
            variables: {
              input: {
                username: userEmailData.viewer.user.username,
                password: data.password,
                mfaAudience: true,
              },
            },
          });
        })}
      >
        <Box maxWidth={375}>
          <PasswordInput
            id="password"
            name="password"
            rules={{
              required: 'Required',
            }}
            maxLength={FIELD_MAX_LENGTHS.DEFAULT}
            label="Password"
            control={control}
            hint=""
          />
        </Box>
        <Flex mt={64} gap={32} alignItems="center">
          <Button
            disabled={!formState.isValid || loading}
            type="submit"
            label="Next"
          />
          <Button kind="link" label="Cancel" onClick={exitFlow} />
        </Flex>
      </form>
    </Flex>
  );
};
