import { Button } from '@m1/liquid-react';
import React from 'react';

import { ButtonGroup } from '~/toolbox/ButtonGroup';

type ScreenerPaginationButtonGroupProps = {
  handleNextPage: any;
  handlePreviousPage: any;
  hasPreviousPage: boolean;
  hasNextPage: boolean | null | undefined;
};

export const ScreenerPaginationButtonGroup = (
  props: ScreenerPaginationButtonGroupProps,
) => {
  const { handleNextPage, handlePreviousPage, hasPreviousPage, hasNextPage } =
    props;

  return (
    <ButtonGroup>
      <Button
        label="Previous"
        kind="secondary"
        size="small"
        disabled={!hasPreviousPage}
        onClick={() => handlePreviousPage()}
      />
      <Button
        label="Next"
        kind="secondary"
        size="small"
        disabled={!hasNextPage}
        onClick={() => handleNextPage()}
      />
    </ButtonGroup>
  );
};
