import * as React from 'react';
import { Route } from 'react-router-dom';

import { Grid } from '~/toolbox/grid';

import { Watchlist } from './components/Watchlist';

export const WatchlistPage = () => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Col xs={12}>
          <br />
          <Watchlist />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};

export const WatchlistRoute = () => {
  return <Route path="watchlist" element={<WatchlistPage />} />;
};
