import { Box, PL, styled } from '@m1/liquid-react';
import React from 'react';

import { CopyableCard } from '~/components/CopyableCard';
import { useReferralOfferQuery } from '~/graphql/hooks';
import { Link } from '~/toolbox/link';
import { formatCurrencyWithCommasOrNull } from '~/utils';

type HasExistingReferralLinkProps = {
  shareUrl: string;
  shareMessage: string | null | undefined;
};
const StyledSocialShare = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const StyledShareButtons = styled(Box)`
  display: flex;
  align-items: center;
`;

export const ShareUrlContainer = ({
  shareUrl,
  shareMessage,
}: HasExistingReferralLinkProps) => {
  const { data } = useReferralOfferQuery();
  const giveAmount = data?.viewer?.referralOffer?.giveAmount || 75;

  return (
    <Box m="36px 0">
      <StyledSocialShare>
        <PL fontWeight={600} content="Share with your friends" />{' '}
        {shareMessage && (
          <StyledShareButtons>
            <Link
              kind="primary"
              to={`mailto:yourfriend@example.com?subject=Here’s a ${formatCurrencyWithCommasOrNull(giveAmount)} bonus for an M1 investment account&body=${shareMessage}`}
              usePlainAnchor
              font="PM"
            >
              Share via email
            </Link>
          </StyledShareButtons>
        )}
      </StyledSocialShare>

      <CopyableCard
        size="small"
        css={{
          marginTop: 12,
          padding: '12px 16px',
        }}
        value={shareUrl}
      />
    </Box>
  );
};
