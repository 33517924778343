import * as React from 'react';
import { Route } from 'react-router-dom';

import { FinancialSuitabilityRoute } from '~/pages/onboarding/financial-suitability-wizard';
import { SetupIraAccountRoute } from '~/pages/setup-ira-account';
import { RequireAuthGuard } from '~/router/Authentication/RequireAuthGuard';
import { ONBOARDING_FLOW_STEPS as STEPS } from '~/static-constants';

import { InitialFundingPage } from './initial-funding';
import { OnboardingPage } from './OnboardingPage';
import { PersonalLoansOnboardingPage } from './PersonalLoansOnboardingPage';
import { PhoneVerificationPage } from './phone-verification';
import { SavingsOnboardingPage } from './SavingsOnboardingPage';
import { IdentityFirstWrapper } from './setup-account';
import { SetupInvestAccount } from './SetupInvestAccount';

export const OnboardingRoute = () => {
  return (
    <Route
      path="onboarding"
      element={
        <RequireAuthGuard>
          <OnboardingPage />
        </RequireAuthGuard>
      }
    >
      <Route
        element={<PhoneVerificationPage />}
        path={`${STEPS.PHONE_VERIFICATION}/:phoneVerificationStep?`}
      />
      <Route
        element={<IdentityFirstWrapper />}
        path={`${STEPS.SETUP_ACCOUNT}/:accountSetupStep?`}
      />
      {FinancialSuitabilityRoute()}
      <Route
        path="personal-loans-onboarding/:step?"
        element={<PersonalLoansOnboardingPage />}
      />
      <Route
        path="savings-onboarding/:step?"
        element={<SavingsOnboardingPage />}
      />
      <Route
        element={<SetupInvestAccount />}
        path={`${STEPS.SETUP_INVEST_ACCOUNT}/:investAccountSetupStep?`}
      />
      {SetupIraAccountRoute()}
      <Route
        element={<InitialFundingPage />}
        path={`${STEPS.INITIAL_FUNDING}/:initialFundingStep?/:childStep?`}
      />
      <Route index element={<PhoneVerificationPage />} />
    </Route>
  );
};
