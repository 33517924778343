import { Flex, PL, PM, PS } from '@m1/liquid-react';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { HelpPopup } from '~/components/HelpPopup';
import { UnmanagedHoldingsMoveDrawerFragment } from '~/graphql/types';
import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';

import { MoveDrawerRowsList } from './MoveDrawerRowsList';

export type UnmanagedHoldingsMoveDrawerProps = {
  receivedSecurities: UnmanagedHoldingsMoveDrawerFragment;
  selectedCheckboxes: Array<string>;
};

export const UnmanagedHoldingsMoveDrawer = ({
  receivedSecurities,
  selectedCheckboxes,
}: UnmanagedHoldingsMoveDrawerProps) => {
  const isDesktop = useMediaQuery({
    query: RESPONSIVE_BREAKPOINTS.LARGE,
  });

  const { moveSecurities } = receivedSecurities;

  if (!moveSecurities) {
    return null;
  }

  const { title, subheader, tooltip } = moveSecurities;

  return (
    <Flex
      backgroundColor="backgroundNeutralMain"
      borderColor="borderMain"
      borderRadius={8}
      borderStyle="solid"
      borderWidth={1}
      flexDirection="column"
      width={335}
    >
      <Flex
        borderColor="borderMain"
        borderStyle="solid"
        borderWidth="0 0 1px 0"
        flexDirection="column"
        padding={16}
      >
        <Flex mb={8}>
          <PL fontWeight={600} color="foregroundNeutralSecondary">
            {title}
          </PL>
          {tooltip?.text && (
            <HelpPopup
              placement={isDesktop ? 'bottom-start' : 'bottom-end'}
              body={<PM color="foregroundNeutralMain">{tooltip.text}</PM>}
            />
          )}
        </Flex>
        <PS mb={8}>{subheader}</PS>
      </Flex>
      <Flex flexDirection="column">
        <MoveDrawerRowsList
          receivedSecurities={receivedSecurities}
          selectedCheckboxes={selectedCheckboxes}
        />
      </Flex>
    </Flex>
  );
};
