import { Flex, HXS } from '@m1/liquid-react';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { HelpPopup } from '~/components/HelpPopup';
import { LiquidateUnmanangedHoldingsModal } from '~/components/LiquidateUnmanangedHoldingsModal/LiquidateUnmanangedHoldingsModal';
import { useUnmanagedHoldingsSectionPaginationQuery } from '~/graphql/hooks';
import { CollectionHoldingsFragment } from '~/graphql/types';

import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';

import { UnmanagedHoldingsSectionControls } from './UnmanagedHoldingsSectionControls';
import { UnmanagedHoldingsTable } from './UnmanagedHoldingsTable';

const getActionableHoldingIds = (
  holdings: CollectionHoldingsFragment | null | undefined,
): Array<string> => {
  const actionableHoldingIds: string[] = [];

  holdings?.edges?.forEach((edge) => {
    const node = edge?.node;

    if (node && !node.isPendingLiquidation) {
      actionableHoldingIds.push(node.id);
    }
  });

  return actionableHoldingIds;
};

export type UnmanagedHoldingsSectionProps = {
  isAccountTradingInProgress: boolean;
  collectionId: string;
};

export const UnmanagedHoldingsSection = ({
  isAccountTradingInProgress,
  collectionId,
}: UnmanagedHoldingsSectionProps) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = React.useState<string[]>(
    [],
  );
  const [disableConfirm, setDisableConfirm] = React.useState(false);
  const [liquidationModalOpen, setLiquidationModalOpen] = React.useState(false);
  const { data, fetchMore } = useUnmanagedHoldingsSectionPaginationQuery({
    variables: {
      collectionId,
      first: 10,
    },
  });

  const loadMore = React.useCallback(
    (totalHoldings: number) => {
      fetchMore({
        variables: {
          first: totalHoldings + 10,
        },
      });
    },
    [fetchMore],
  );

  const unmanagedHoldings =
    data?.unmanagedHoldings?.__typename === 'UnmanagedHoldingsCollection'
      ? data.unmanagedHoldings
      : null;

  const isDesktop = useMediaQuery({
    query: RESPONSIVE_BREAKPOINTS.LARGE,
  });

  const onSelectAll = React.useCallback(() => {
    const actionableHoldingIds = getActionableHoldingIds(
      unmanagedHoldings?.holdings,
    );

    const selected =
      selectedCheckboxes.length &&
      selectedCheckboxes.length === actionableHoldingIds.length
        ? []
        : actionableHoldingIds;

    setSelectedCheckboxes(selected);
  }, [selectedCheckboxes, unmanagedHoldings?.holdings]);

  const onCheckboxSelect = React.useCallback(
    (checkboxId: string) => {
      const selected = selectedCheckboxes.includes(checkboxId)
        ? selectedCheckboxes.filter((id) => id !== checkboxId)
        : selectedCheckboxes.concat([checkboxId]);

      setSelectedCheckboxes(selected);
    },
    [selectedCheckboxes],
  );

  const onSuccessCallBack = React.useCallback(() => {
    setLiquidationModalOpen(false);
    setSelectedCheckboxes([]);
    setDisableConfirm(false);
  }, [setSelectedCheckboxes, setLiquidationModalOpen]);

  const onErrorCallBack = React.useCallback(() => {
    setDisableConfirm(false);
  }, []);

  if (!unmanagedHoldings?.holdings?.total) {
    return null;
  }

  const isTradingInProgress = Boolean(isAccountTradingInProgress);
  const { header, holdings, tooltip, type } = unmanagedHoldings;
  const hideCheckboxes = type === 'CONTACT_OPS' || type === 'DO_NOTHING';
  const renderLiquidateModal =
    selectedCheckboxes && type === 'LIQUIDATE' && liquidationModalOpen;
  const actionableHoldingIds = getActionableHoldingIds(holdings);

  return (
    <Flex flexDirection="column" mb={32} width="100%">
      <Flex justifyContent="space-between" mb={16} width="100%">
        <Flex alignItems="center">
          {renderLiquidateModal && (
            <LiquidateUnmanangedHoldingsModal
              disableConfirm={disableConfirm}
              unmanagedHoldingIds={selectedCheckboxes}
              active={liquidationModalOpen}
              onCompleted={onSuccessCallBack}
              onError={onErrorCallBack}
              onConfirm={() => setDisableConfirm(true)}
              onClose={() => setLiquidationModalOpen(false)}
            />
          )}
          <HXS>{header}</HXS>
          {tooltip?.text && (
            <HelpPopup
              placement={isDesktop ? 'bottom-start' : 'bottom-end'}
              body={tooltip.text}
            />
          )}
        </Flex>
        {type && (
          <UnmanagedHoldingsSectionControls
            disabled={!selectedCheckboxes.length || isTradingInProgress}
            holdingsCollection={unmanagedHoldings}
            selectedCheckboxes={selectedCheckboxes}
            toggleLiquidationModal={() =>
              setLiquidationModalOpen(!liquidationModalOpen)
            }
          />
        )}
      </Flex>
      <UnmanagedHoldingsTable
        actionableHoldingIds={actionableHoldingIds}
        loadMore={() =>
          loadMore(unmanagedHoldings.holdings?.edges?.length ?? 0)
        }
        hideCheckboxes={hideCheckboxes}
        holdingsCollection={unmanagedHoldings}
        isTradingInProgress={isTradingInProgress}
        onSelectAll={onSelectAll}
        onCheckboxSelect={onCheckboxSelect}
        selectedCheckboxes={selectedCheckboxes}
      />
    </Flex>
  );
};
