import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { DocumentList } from './components/DocumentList';
import { DocumentsContainer } from './DocumentsContainer';
import { routes } from './routes';

export const SettingsDocumentsRoute = () => (
  <>
    <Route path="documents" element={<DocumentsContainer />}>
      {routes.map(({ path, documentType, label: title }) => (
        <Route
          key={path}
          path={path}
          element={
            <DocumentList
              // Set a unique key so each DocumentList instance maintains
              // its own state, including the selected year.
              key={documentType}
              documentType={documentType}
              title={title}
            />
          }
        />
      ))}
      <Route index element={<Navigate to={routes[0].path} replace />} />
    </Route>
  </>
);
