import { Box, Flex, PXL, PL } from '@m1/liquid-react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { RadioChoice } from '~/components/form/Radio';
import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import { PhoneNumberField } from '~/forms/fields/PhoneNumberField';
import { FormMockerReactHookForm } from '~/forms/FormMockers/FormMockerReactHookForm';

import {
  useInitiatePhoneVerificationMutation,
  usePersonalLoansDirectQuery,
} from '~/graphql/hooks';
import {
  PersonalLoanDirectQuestionEnum,
  InitiatePhoneVerificationInput,
  PhoneVerificationChannelEnum,
  PersonalLoanDirectQuestion,
} from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { submitReactFormData } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';

import { Spinner } from '~/toolbox/spinner';
import { normalizePhoneNumber } from '~/utils';

import { BackButton } from '../BackButton';
import { StyledHeader } from '../components/StyledHeader';
import { usePersonalLoanDirectWizardContext } from '../personal-loan-direct-wizard/usePersonalLoanDirectWizardContext';
import { getQuestionProps } from '../utils/getQuestionprops';

export const PhoneNumber = () => {
  const { data, loading: personalLoansDirectLoading } =
    usePersonalLoansDirectQuery();

  const { phoneNumber: persistedPhoneNumber } = useSelector((state) => {
    if (!state.reactHookForm['directToPersonalLoansPhoneNumber']) {
      return { phoneNumber: '' };
    }
    return state.reactHookForm['directToPersonalLoansPhoneNumber'];
  });
  const questions =
    data?.viewer?.borrow?.personalLoans?.applicationFlowDirect?.questions;

  let props: Maybe<Partial<PersonalLoanDirectQuestion>> | undefined;

  if (questions) {
    props = getQuestionProps({
      question: PersonalLoanDirectQuestionEnum.PhoneNumber,
      questions,
    });
  }

  const dispatch = useDispatch();
  const [initiatePhoneVerification, { loading }] =
    useInitiatePhoneVerificationMutation();
  const { addToast } = useToast();
  const analytics = useAnalytics();
  const { goTo, back } = usePersonalLoanDirectWizardContext();

  usePortaledSpinner(loading || personalLoansDirectLoading);

  React.useEffect(() => {
    analytics.pageView('/direct-loan-application/phone-verification');
  }, [analytics]);

  const formMethods = useForm<InitiatePhoneVerificationInput>({
    defaultValues: {
      channel: PhoneVerificationChannelEnum.Sms,
      phone: persistedPhoneNumber,
    },
  });

  const onSubmit = (input: InitiatePhoneVerificationInput) => {
    const phone = normalizePhoneNumber(input.phone);

    initiatePhoneVerification({
      variables: {
        input: {
          channel: input.channel,
          phone,
        },
      },
      onCompleted: () => {
        const { nextQuestionType } = props || {};

        dispatch(
          submitReactFormData({
            directToPersonalLoansPhoneNumber: {
              phoneNumber: phone,
            },
          }),
        );

        goTo(nextQuestionType ?? 'PHONE_NUMBER_VERIFICATION');
      },
      onError: (err) => {
        addToast({
          content: err.message,
          kind: 'alert',
          duration: 'short',
        });
      },
    });
  };

  if (loading) {
    return <Spinner fullScreen centered />;
  }

  const { continueLabel, title, subtitle } = props || {};

  return (
    <>
      <BackButton onBackClick={() => back()} />

      <FormProvider {...formMethods}>
        <Flex flexDirection="column">
          <Box mb={8}>
            <StyledHeader content={title ?? 'Let’s make sure it’s you.'} />
            <PL content={subtitle} mt={8} />
          </Box>

          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <PhoneNumberField
              placeholder="(XXX) XXX-XXXX"
              autoFocus
              backgroundColor="backgroundNeutralSecondary"
            />
            <PXL fontWeight={600} content="Deliver code by:" mt={16} />

            <Box mt={16}>
              <RadioChoice
                name="channel"
                label="Phone call"
                value={PhoneVerificationChannelEnum.Call}
              />
              <RadioChoice
                name="channel"
                label="Text"
                value={PhoneVerificationChannelEnum.Sms}
              />
            </Box>
            <Box mt={48}>
              <ResponsiveButton
                label={continueLabel || 'Continue'}
                size="large"
                type="submit"
              />
              <FormMockerReactHookForm
                setValue={formMethods.setValue}
                fields={[
                  {
                    name: 'phone',
                    value: '6301234567',
                  },
                ]}
              />
            </Box>
          </form>
        </Flex>
      </FormProvider>
    </>
  );
};
