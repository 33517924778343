import {
  Banner,
  Box,
  Button,
  Flex,
  HXXS,
  LL,
  PM,
  PS,
  styled,
  Card,
} from '@m1/liquid-react';
import isEqual from 'lodash-es/isEqual';
import memoize from 'lodash-es/memoize';
import moment from 'moment';
import * as React from 'react';
import {
  blur,
  change,
  FormSection,
  formValueSelector,
  SubmissionError,
} from 'redux-form';

import { CryptoDisclosure } from '~/components/CryptoDisclosure';
import { IRSContributionLimitsDescription } from '~/components/IRSContributionLimitsDescription';
import { LegacyFormTransferDetailsMessageCard } from '~/components/TransferDetailsMessageCard/LegacyFormTransferDetailsMessageCard';
import { TransferRequirementsTimingDescriptionCell } from '~/components/transfers/TransferRequirementsTimingDescriptionCell';

import { useGetTransferFormViewerQuery } from '~/graphql/hooks';
import {
  Borrow,
  ContraTransferParticipantInput,
  CreateTransferInstanceInput,
  Credit,
  GetTransferFormViewerQuery,
  IraDistributionReasonEnum,
  ScheduledTransferIndicatorEnum,
  ScheduledTransferRule,
  TransferParticipant,
  TransferParticipantList,
  TransferRequirements,
} from '~/graphql/types';
import {
  compose,
  connectForm,
  onlyUpdateForKeys,
  UNSAFE_connectRedux,
} from '~/hocs';
import { useAnalytics } from '~/hooks/useAnalytics';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import type { AppState } from '~/redux';
import { useDispatch, useSelector } from '~/redux/hooks';
import {
  CREATE_TRANSFER_FLOW_MODES as MODES,
  TRANSFER_SCHEDULE_FREQUENCIES,
} from '~/static-constants';
import { DropdownGroup } from '~/toolbox/Dropdown';
import { Link } from '~/toolbox/link';

import { Pill, PillGroup } from '~/toolbox/Pill';

import { PaymentPresets } from '../components/PaymentPresets';

import {
  CheckboxField,
  RetirementContributionYearDropdownField,
  RetirementDisbursementReasonDropdownField,
  ScheduleFrequencyFields,
  TransferAmountField,
  TransferParticipantDropdownField,
} from './fields';
import { TextFieldProps } from './fields/text-field';
import { required } from './validators';

type SubmitButtonProps = {
  disabled?: boolean;
  label: string;
  usedPill?: boolean;
};

const TransferSubmitButton = (props: SubmitButtonProps) => {
  const { disabled, label, usedPill } = props;
  const { amount, frequency } = useSelector((state) => ({
    amount: state.form['transfer-instance'].values.amount,
    frequency: Object.keys(
      state.form['transfer-instance'].values.schedule ?? {},
    )[0],
  }));
  const analytics = useAnalytics();

  return (
    <Button
      disabled={disabled}
      kind="primary"
      label={label}
      size="large"
      type="submit"
      onClick={() => {
        if (!disabled) {
          analytics.recordEvent(
            'm1_invest_schedule_recurring_deposit_confirm',
            null,
            {
              amount,
              frequency,
              pill: `${usedPill}`,
            },
          );
        }
      }}
    />
  );
};

type CreateOneTimeOrScheduledTransferProps = {
  amount: number | null | undefined;
  dispatch: (...args: Array<any>) => any;
  navigate: (...args: Array<any>) => any;
  fromParticipantId: string | null | undefined;
  handleSubmit: (...args: Array<any>) => any;
  hasAnyTransferInput: boolean;
  hasValidTransferInput: boolean;
  initialValues: Partial<CreateTransferInstanceInput>;
  iraDistributionReason: ValueOf<IraDistributionReasonEnum>;
  isCreditAutoPayEnabled: boolean;
  isCreditCardPayment: boolean;
  isFromDropdownDisabled: boolean;
  isIraRollover: boolean;
  isPaymentAmountSelected: boolean;
  isPersonalLoanPayment: boolean;
  isPersonalLoansAutoPayEnabled: boolean;
  isToDropdownDisabled: boolean;
  onSubmit: (...args: Array<any>) => any;
  onSwitchModes: (mode: ValueOf<typeof MODES>) => void;
  requiresInitialDeposit: boolean;
  showTransactionError: boolean;
  toParticipant: ContraTransferParticipantInput | null | undefined;
  toParticipantId: string | null | undefined;
  transferAmountFromStore: number;
  transferType: string;
  viewer:
    | {
        borrow: Borrow | null | undefined;
        credit: Credit | null | undefined;
        transfers: TransfersAlias | null | undefined;
      }
    | null
    | undefined;
};

type CreateOneTimeOrScheduleTransferState = {
  isInputAmountInteractedWith: boolean;
  sentTransferRequest: boolean;
  pillSelected: boolean;
};

export type TransfersAlias = {
  destinationParticipants: TransferParticipantList | null | undefined;
  enhancedIraDistribution: boolean | null | undefined;
  id: string;
  isEvenWeek: boolean | null | undefined;
  requirements: TransferRequirements | null | undefined;
  sourceParticipants: TransferParticipantList | null | undefined;
  defaultAmountPills: Array<{ label: string; kind: string }>;
};

const paymentOtherValue = 'OTHER_AMOUNT' as const;
type TransferOtherAmountEnumValue = typeof paymentOtherValue;

const AutoPayBannerButton = styled(Button)`
  white-space: nowrap;
  align-self: end;
`;

class CreateOneTimeOrScheduledTransfer extends React.Component<
  CreateOneTimeOrScheduledTransferProps,
  CreateOneTimeOrScheduleTransferState
> {
  amountInputId: string = 'amount-input-id';

  state = {
    isInputAmountInteractedWith: false,
    sentTransferRequest: false,
    pillSelected: false,
  };

  componentDidUpdate(prevProps: CreateOneTimeOrScheduledTransferProps) {
    const currentMinInitialDeposit = this.readMinimumTransferAmount(
      this.props.viewer,
    );

    // if an initial deposit is required,
    // the user has not interacted with the amount field,
    // and we have a valid min initial amount,
    // force a blur on the form to pre-fill
    if (
      this.props.requiresInitialDeposit &&
      !this.state.isInputAmountInteractedWith &&
      typeof currentMinInitialDeposit === 'number' &&
      !this.props.initialValues.amount
    ) {
      this.forceBlurOnAmountField(currentMinInitialDeposit);
    }

    if (!prevProps.viewer && this.props.viewer) {
      this.clearInvalidParticipants();
    }
  }

  clearInvalidParticipants() {
    // Reset the participant IDs to null if no matching valid participant is found
    const transfers = this.readTransfers();
    if (
      transfers?.sourceParticipants?.list &&
      !transfers.sourceParticipants.list.some(
        (p) => p.id === this.props.fromParticipantId,
      )
    ) {
      this.props.dispatch(
        change('transfer-instance', 'fromParticipantId', null),
      );
    }
    if (
      transfers?.destinationParticipants?.list &&
      !transfers.destinationParticipants.list.some(
        (p) => p.id === this.props.toParticipantId,
      )
    ) {
      this.props.dispatch(change('transfer-instance', 'toParticipantId', null));
    }
  }

  render() {
    const transfers = this.readTransfers();
    const { isCreditCardPayment, isPersonalLoanPayment } = this.props;

    return (
      <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
        <HXXS color="foregroundNeutralMain" content="Accounts" />
        <TransferParticipantDropdownField
          label="From"
          name="fromParticipantId"
          options={this.readSourceParticipants(transfers)}
          disabled={this.props.isFromDropdownDisabled}
        />
        <TransferParticipantDropdownField
          label="To"
          name="toParticipantId"
          options={this.readDestinationParticipants(transfers)}
          style={{
            marginTop: 16,
          }}
          disabled={this.props.isToDropdownDisabled}
        />
        <HXXS color="foregroundNeutralMain" content="Amount" mt={24} />
        {isCreditCardPayment || (isPersonalLoanPayment && this.isSchedule())
          ? this.renderPaymentAmounts()
          : this.renderAmountField({})}
        {this.renderErrorLink()}
        {this.renderDistributionReason()}
        {this.renderContributionYear()}
        {this.renderRequirementsMessage()}
        {this.renderScheduleFields(transfers)}
        {this.renderAutoPaymentDate()}
        {this.renderCreditCardAutoPaymentTermsLink()}
        {this.renderIraRolloverOption()}
        {this.renderConvertToScheduleLink()}
        {this.renderHoldsOrSellsMessage(transfers)}
        {this.renderCryptoDisclosure()}
        <div
          style={{
            paddingTop: 64,
            textAlign: 'center',
          }}
        >
          {/* <TransferInputContext.Provider value={{ amount: }} */}
          <TransferSubmitButton
            usedPill={this.state.pillSelected}
            disabled={
              !this.props.hasValidTransferInput ||
              this.state.sentTransferRequest ||
              this.isPaymentButtonDisabled()
            }
            label={
              (isCreditCardPayment || isPersonalLoanPayment) &&
              !this.isSchedule()
                ? 'Make a payment'
                : 'Continue'
            }
          />
        </div>
      </form>
    );
  }

  renderDistributionReason(): React.ReactNode | null | undefined {
    const { viewer } = this.props;
    const enhancedIraDistribution = viewer?.transfers?.enhancedIraDistribution;
    if (
      viewer?.transfers?.requirements?.isIraDistributionReasonRequired &&
      (this.isSchedule() || !enhancedIraDistribution)
    ) {
      return (
        <>
          <RetirementDisbursementReasonDropdownField
            name="iraDistributionReason"
            label="Distribution Reason"
            isRecurringTransfer
            style={{
              marginTop: 16,
            }}
            validate={[required]}
          />
          {(this.props.iraDistributionReason === 'DEATH' ||
            this.props.iraDistributionReason === 'DISABILITY') && (
            <Card
              borderColor="transparent"
              backgroundColor="backgroundNeutralTertiary"
              p={16}
              mt={16}
            >
              <PS
                color="foregroundNeutralMain"
                content={
                  <>
                    Supporting documents will need to be sent to{' '}
                    <Link to="mailto:help@m1.com" font="PM" usePlainAnchor>
                      help@m1.com
                    </Link>{' '}
                    before the completion of the distribution.
                  </>
                }
              />
            </Card>
          )}
        </>
      );
    }
    return null;
  }

  renderContributionYear(): React.ReactNode | null | undefined {
    const { viewer } = this.props;
    if (
      viewer &&
      viewer.transfers &&
      viewer.transfers.requirements &&
      viewer.transfers.requirements.isIraContributionYearRequired
    ) {
      return (
        <>
          <RetirementContributionYearDropdownField
            name="iraContributionYear"
            label="Contribution Year"
            style={{
              marginTop: 16,
            }}
            validate={[required]}
          />
          <Card
            borderColor="transparent"
            backgroundColor="backgroundNeutralTertiary"
            p={16}
            mt={16}
          >
            <IRSContributionLimitsDescription />
          </Card>
        </>
      );
    }
    return null;
  }

  renderHoldsOrSellsMessage(transfers: TransfersAlias | null | undefined) {
    const {
      amount,
      isFromDropdownDisabled,
      isToDropdownDisabled,
      transferType,
    } = this.props;
    const isSchedule = transferType === 'SCHEDULED_TRANSFER_RULE';
    if (
      isSchedule ||
      !transfers ||
      !transfers.requirements ||
      !amount ||
      isFromDropdownDisabled ||
      isToDropdownDisabled
    ) {
      return null;
    }
    return (
      <div
        style={{
          marginTop: 32,
        }}
      >
        <TransferRequirementsTimingDescriptionCell
          transferRequirements={transfers.requirements}
        />
      </div>
    );
  }

  renderIraRolloverOption() {
    const { viewer } = this.props;
    if (!viewer?.transfers?.requirements?.isIraRolloverOptionRequired) {
      return null;
    }
    return (
      <Flex mt={32} ml={-4}>
        <CheckboxField
          name="isIraRollover"
          label={
            <>
              <LL content="Select if this transfer is a rollover" pl={4} />
              <PS
                color="foregroundNeutralSecondary"
                content="Use within 60 days of a distribution."
                pl={4}
              />
            </>
          }
        />
      </Flex>
    );
  }

  renderScheduleFields(
    transfers: TransfersAlias | null | undefined,
  ): React.ReactNode | null | undefined {
    if (
      this.isSchedule() &&
      !this.props.isCreditCardPayment &&
      !this.props.isPersonalLoanPayment
    ) {
      return (
        <FormSection name="schedule">
          <HXXS content="Schedule" mt={16} />
          <ScheduleFrequencyFields
            initialFrequency={TRANSFER_SCHEDULE_FREQUENCIES.MONTHLY}
            isEvenWeek={transfers && transfers.isEvenWeek}
          />
        </FormSection>
      );
    }

    return null;
  }

  renderAutoPaymentDate() {
    const reqs = this.readTransfers()?.requirements;
    if (!reqs) {
      return null;
    }
    return (
      this.isSchedule() &&
      (this.props.isCreditCardPayment || this.props.isPersonalLoanPayment) && (
        <>
          {reqs.autoPayInformationContent && (
            <>
              <HXXS content="Payment Date" mt={24} mb={16} />
              <Card label="AutoPay">
                <PM color="foregroundNeutralMain">
                  {reqs.autoPayInformationContent}
                </PM>
              </Card>
            </>
          )}
          {reqs.autoPayDueDateMessage && (
            <Banner
              mt={16}
              kind="alert"
              content={reqs.autoPayDueDateMessage}
              size="inline"
              action={
                <AutoPayBannerButton
                  kind="link"
                  label="One-time payment"
                  style={{ fontSize: '16px' }}
                  onClick={() => this.props.onSwitchModes(MODES.ONE_TIME)}
                />
              }
            />
          )}
        </>
      )
    );
  }

  renderCreditCardAutoPaymentTermsLink() {
    const reqs = this.readTransfers()?.requirements;
    if (!reqs || !reqs.transferOverviewLink || !this.isSchedule()) {
      return null;
    }
    return <LinkableLink linkable={reqs.transferOverviewLink} mt={32} />;
  }

  renderRequirementsMessage() {
    return (
      <LegacyFormTransferDetailsMessageCard
        onTransferSuggestionLinkClick={(event, transferSuggestion) => {
          const { dispatch } = this.props;
          if (transferSuggestion) {
            dispatch(
              change('transfer-instance', 'amount', transferSuggestion.amount),
            );
            dispatch(
              change(
                'transfer-instance',
                'fromParticipantId',
                transferSuggestion.fromParticipant
                  ? transferSuggestion.fromParticipant.id
                  : null,
              ),
            );
            dispatch(
              change(
                'transfer-instance',
                'toParticipantId',
                transferSuggestion.toParticipant
                  ? transferSuggestion.toParticipant.id
                  : null,
              ),
            );
          }
        }}
      />
    );
  }

  readPersonalLoanAutoPayInstance(): ScheduledTransferRule | null | undefined {
    const loans = this.props.viewer?.borrow?.personalLoans?.loans?.edges;

    const selectedLoan = loans?.find(
      // @ts-expect-error - TS2531 - Object is possibly 'null'.
      (loan) => loan.node?.id === this.props.toParticipantId,
    );

    return selectedLoan?.node?.autoPayInstance;
  }

  renderConvertToScheduleLink() {
    const requirements = this.props.viewer?.transfers?.requirements;
    if (!requirements?.canBeScheduledRule || this.isSchedule()) {
      return null;
    }
    const {
      isCreditCardPayment,
      isPersonalLoanPayment,
      isPersonalLoansAutoPayEnabled,
      navigate,
      viewer,
    } = this.props;

    const isIraDistribution =
      viewer?.transfers?.requirements?.isIraDistributionReasonRequired ?? false;

    if (
      (isPersonalLoanPayment && !isPersonalLoansAutoPayEnabled) ||
      isIraDistribution
    ) {
      return null;
    }
    let content;
    let onClick;
    const personalLoanAutoPayInstance = isPersonalLoanPayment
      ? this.readPersonalLoanAutoPayInstance()
      : null;
    if (isCreditCardPayment && viewer?.credit?.autoPayInstance) {
      const transferRuleId = viewer.credit.autoPayInstance.id;
      content = 'View AutoPay Details';
      onClick = () =>
        navigate({
          to: `/d/c/rule-details/:transferRuleId`,
          params: { transferRuleId },
        });
    } else if (isPersonalLoanPayment && Boolean(personalLoanAutoPayInstance)) {
      const transferRuleId = personalLoanAutoPayInstance?.id;
      content = 'View AutoPay Details';
      onClick = () =>
        navigate({
          to: `/d/c/rule-details/:transferRuleId`,
          params: { transferRuleId },
        });
    } else {
      onClick = () => this.props.onSwitchModes(MODES.SCHEDULE);
      content =
        isCreditCardPayment || isPersonalLoanPayment
          ? 'Enable AutoPay'
          : 'Switch to a recurring schedule';
    }
    return <Button kind="text" label={content} onClick={onClick} mt={24} />;
  }

  renderAmountField({
    autoFocus = true,
    label = 'Enter amount',
    isRequired = true,
    ...props
  }: Partial<TextFieldProps & { isRequired: boolean }>) {
    const { showTransactionError } = this.props;
    const validations = [this.limitsValidation];
    if (isRequired) {
      // @ts-expect-error - TS2345 - Argument of type '(value: any) => string | null | undefined' is not assignable to parameter of type '(value: any) => string | undefined'.
      validations.push(required);
    }
    const amountPills = this.props.viewer?.transfers?.defaultAmountPills;

    return (
      <Box>
        <Box pb={24}>
          <TransferAmountField
            {...props}
            autoFocus={autoFocus}
            id={this.amountInputId}
            name="amount"
            label={label ? label : undefined}
            nonSubmissionError={showTransactionError}
            validate={validations}
            onChange={() => {
              this.setState({
                isInputAmountInteractedWith:
                  !this.state.isInputAmountInteractedWith || true,
                pillSelected: false,
              });
            }}
          />
        </Box>
        <Box justifyContent="center">
          <PillGroup justifyContent="center">
            {amountPills?.map(({ label }) => (
              <Pill
                key={`pill-${label}`}
                label={label}
                size="large"
                kind="interactive"
                onClick={() => {
                  this.setState({
                    pillSelected: true,
                  });
                  this.props.dispatch(
                    change(
                      'transfer-instance',
                      'amount',
                      parseInt(label.substring(1).replace(',', ''), 10),
                    ),
                  );
                }}
              />
            ))}
          </PillGroup>
        </Box>
      </Box>
    );
  }

  renderPaymentAmounts() {
    const { dispatch } = this.props;
    const reqs = this.readTransfers()?.requirements;
    const presets = this.isSchedule()
      ? reqs?.scheduledTransferAmountPresets
      : reqs?.transferAmountPresets;

    if (!presets) {
      return null;
    }

    // @ts-expect-error - TS2349 - This expression is not callable. | TS7006 - Parameter 'preset' implicitly has an 'any' type.
    const isOtherDisabled = presets.every((preset) => preset.value === 0);

    return (
      <Card label="Select amount" mt={16}>
        <PaymentPresets
          amountField={this.renderAmountField({
            label: null,
            autoFocus: false,
            disabled: isOtherDisabled,
            isRequired: false,
            onFocus: () => {
              dispatch(
                change('transfer-instance', 'paymentAmount', paymentOtherValue),
              );
            },
          })}
          // @ts-expect-error - TS2322 - Type 'Maybe<ScheduledTransferPresetOption>[] | Maybe<TransferAmountPresetOption>[]' is not assignable to type 'TransferAmountPresetOption[] | ScheduledTransferPresetOption[]'.
          presets={presets}
          paymentOtherValue={paymentOtherValue}
          isOtherDisabled={isOtherDisabled}
          isSchedule={this.isSchedule()}
          onSelect={(value) => {
            if (value !== paymentOtherValue) {
              dispatch(change('transfer-instance', 'amount', ''));
            }
          }}
        />
      </Card>
    );
  }

  renderCryptoDisclosure() {
    const isCryptoDestination = this.isCryptoDestination();

    return isCryptoDestination ? (
      <Box mt={16}>
        <CryptoDisclosure />
      </Box>
    ) : null;
  }

  renderErrorLink() {
    const transfers = this.readTransfers();

    if (
      !transfers?.requirements?.maxTransferAmountErrorLink ||
      !this.props.transferAmountFromStore ||
      !transfers.requirements.maxTransferAmount
    ) {
      return null;
    }

    return Number(this.props.transferAmountFromStore) >
      transfers.requirements.maxTransferAmount ? (
      <LinkableLink
        mt={16}
        linkable={transfers.requirements.maxTransferAmountErrorLink}
      />
    ) : null;
  }

  isCryptoDestination() {
    const destinations = this.readTransfers()?.destinationParticipants?.list;
    const destination = destinations?.find(
      (d) => d.id === this.props.toParticipantId,
    );
    return destination?.transferParticipantType === 'CRYPTO';
  }

  isPaymentButtonDisabled(): boolean {
    const { isCreditCardPayment, isPaymentAmountSelected } = this.props;
    return isCreditCardPayment && !isPaymentAmountSelected;
  }

  isSchedule(): boolean {
    return this.props.transferType === 'SCHEDULED_TRANSFER_RULE';
  }

  handleSubmit = ({ amount, paymentAmount, ...rest }: any): void => {
    const { viewer } = this.props;
    const isOneTimeIraDistribution =
      viewer?.transfers?.enhancedIraDistribution &&
      viewer.transfers.requirements?.isIraDistributionReasonRequired &&
      !this.isSchedule();
    if (!this.state.sentTransferRequest) {
      const mergedAmount = mergePaymentAmounts(amount, paymentAmount);

      /**
       * Do a final check for validation on submit.
       * Throw a SubmissionError (redux-form) to surface
       * the error to the user.
       */
      const error = this.limitsValidation(mergedAmount);
      if (error) {
        throw new SubmissionError({
          amount: error,
        });
      }

      let scheduleAndLabel;

      if (
        this.isSchedule() &&
        (this.props.isCreditCardPayment || this.props.isPersonalLoanPayment)
      ) {
        scheduleAndLabel = this.getScheduleAndLabel(paymentAmount);
      }

      this.props.onSubmit({
        ...rest,
        ...scheduleAndLabel,
        amount: Number(mergedAmount) || 0,
        isCreditCardPayment: this.props.isCreditCardPayment,
        isPersonalLoanPayment: this.props.isPersonalLoanPayment,
        isOneTimeIraDistribution,
      });
      this.setState({
        sentTransferRequest: true,
      });
    }
  };

  readTransfers(): TransfersAlias | null | undefined {
    return this.props.viewer && this.props.viewer.transfers;
  }

  readSourceParticipants = memoize(
    (transfers: TransfersAlias | null | undefined) => {
      if (
        transfers &&
        transfers.sourceParticipants &&
        Array.isArray(transfers.sourceParticipants.list)
      ) {
        return this.readParticipants(transfers.sourceParticipants.list);
      }

      return null;
    },
  );

  readDestinationParticipants = memoize(
    (transfers: TransfersAlias | null | undefined) => {
      if (
        transfers &&
        transfers.destinationParticipants &&
        Array.isArray(transfers.destinationParticipants.list)
      ) {
        return this.readParticipants(transfers.destinationParticipants.list);
      }

      return null;
    },
  );

  readParticipants(
    participants: Array<TransferParticipant>,
  ): Array<DropdownGroup> {
    const sources = new Map();

    for (const {
      id,
      transferParticipantType,
      transferParticipantName,
    } of participants) {
      if (
        !this.props.isCreditCardPayment &&
        this.isSchedule() &&
        transferParticipantType === 'CREDIT_CARD'
      ) {
        continue;
      }

      const participantTypeLabel =
        transferParticipantType === 'SAVE' ? 'EARN' : transferParticipantType;
      const option = {
        label: transferParticipantName,
        value: id,
      };
      const options = sources.get(participantTypeLabel);
      if (options) {
        sources.set(participantTypeLabel, [...options, option]);
      } else {
        sources.set(participantTypeLabel, [option]);
      }
    }

    return Array.from(sources, ([label, options]) => ({
      label,
      options,
    }));
  }

  getScheduleAndLabel = (
    indicator:
      | ValueOf<ScheduledTransferIndicatorEnum>
      | TransferOtherAmountEnumValue,
  ) => {
    let label;
    const autoPayPaymentValue =
      indicator === paymentOtherValue ? 'FIXED_AMOUNT' : indicator;

    const presets =
      this.props.viewer?.transfers?.requirements
        ?.scheduledTransferAmountPresets;
    if (presets) {
      const preset = presets.find(
        // @ts-expect-error - TS2339 - Property 'indicator' does not exist on type 'Maybe<ScheduledTransferPresetOption>'.
        ({ indicator: presetIndicator }) => presetIndicator === indicator,
      );
      label = preset?.label;
    }

    return {
      schedule: {
        monthly: {
          autoPayPaymentValue,
        },
      },
      label,
    };
  };

  limitsValidation = (value: any) => {
    const transfers = this.readTransfers();
    const valueAsNum = Number(value);
    if (value && transfers && transfers.requirements) {
      if (
        transfers.requirements.maxTransferAmount &&
        (valueAsNum > transfers.requirements.maxTransferAmount ||
          transfers.requirements.maxTransferAmount === 0)
      ) {
        return transfers.requirements.maxTransferAmountErrorMessage;
      }

      if (
        transfers.requirements.minTransferAmount &&
        valueAsNum < transfers.requirements.minTransferAmount
      ) {
        return transfers.requirements.minTransferAmountErrorMessage;
      }
    }
  };

  forceBlurOnAmountField = (minTransferRequirement?: number) => {
    const { dispatch, transferAmountFromStore } = this.props;
    // NOTE: The DOM query and blur dispatch below is a hack for triggering a manual synchronous validation check when a user toggles between a buy and sell order.
    // Redux form does not allow manual validation out of the box. See https://github.com/redux-form/redux-form/issues/3992 and https://github.com/redux-form/redux-form/issues/3466.
    // Same pattern is used for orders.
    const inputEl: HTMLElement | null | undefined = document.getElementById(
      this.amountInputId,
    );
    if (inputEl) {
      inputEl.focus();
    }

    // if there is a valid min transfer requirement, update the input to hold that value
    if (
      minTransferRequirement &&
      (Number(transferAmountFromStore) === 0 ||
        Number(transferAmountFromStore) !== minTransferRequirement)
    ) {
      dispatch(
        blur('transfer-instance', 'amount', minTransferRequirement, true),
      );
    }
  };

  readMinimumTransferAmount(
    viewer:
      | {
          transfers: TransfersAlias | null | undefined;
        }
      | null
      | undefined,
  ): number | null | undefined {
    if (
      viewer &&
      viewer.transfers &&
      viewer.transfers.requirements &&
      viewer.transfers.requirements.minTransferAmount
    ) {
      return viewer.transfers.requirements.minTransferAmount;
    }
    return null;
  }
}

// @ts-expect-error - TS7006 - Parameter 'otherAmount' implicitly has an 'any' type. | TS7006 - Parameter 'radioOption' implicitly has an 'any' type.
const mergePaymentAmounts = (otherAmount, radioOption) => {
  // If no radioOption, we are not in CC payment mode, so always use otherAmount
  return !radioOption || radioOption === paymentOtherValue
    ? otherAmount
    : radioOption?.split('-')[0]; // Get number ex. '2341' from '2341-Statement balance'
};

const enhancer = compose<any, any>(
  connectForm({
    form: 'transfer-instance',
  }),
  createTransferFormHoc('transfer-instance'),
);

export function createTransferFormHoc(
  formName: string,
  isEditTransferSchedule: boolean = false,
): (...args: Array<any>) => any {
  const formSelector = formValueSelector(formName);
  return compose<any, any>(
    UNSAFE_connectRedux((state: AppState): any => {
      const fAmount = formSelector(state, 'amount');
      const fPaymentAmount = formSelector(state, 'paymentAmount');
      const isPaymentAmountSelected = Boolean(
        (fPaymentAmount === paymentOtherValue && fAmount) ||
          (fPaymentAmount && fPaymentAmount !== paymentOtherValue),
      );
      const fFromParticipantId = formSelector(state, 'fromParticipantId');
      const fToParticipantId = formSelector(state, 'toParticipantId');
      const isSchedule =
        state.newFlows.CREATE_TRANSFER.mode === MODES.SCHEDULE ||
        isEditTransferSchedule;
      const mergedAmount = mergePaymentAmounts(fAmount, fPaymentAmount);

      let amount =
        typeof mergedAmount === 'string' || typeof mergedAmount === 'number'
          ? Number(mergedAmount)
          : null;
      if (isEditTransferSchedule) {
        amount = amount || 0;
      }

      return {
        amount,
        transferType: isSchedule
          ? 'SCHEDULED_TRANSFER_RULE'
          : 'TRANSFER_INSTANCE',
        fromParticipantId: fFromParticipantId || null,
        iraDistributionReason: formSelector(state, 'iraDistributionReason'),
        isIraRollover: formSelector(state, 'isIraRollover'),
        // Will use this to disable the submit button if no CC radio button
        // selected or if other amount is selected but has no value
        isPaymentAmountSelected,
        toParticipantId: fToParticipantId || null,
      };
    }),
    onlyUpdateForKeys([
      'amount',
      'fromParticipantId',
      'iraDistributionReason',
      'isPaymentAmountSelected',
      'isIraRollover',
      'meta',
      'toParticipantId',
      'isScheduleSwitchDisabled',
      'requiresInitialDeposit',
      'transferType',
    ]),

    // @ts-expect-error - TS7006 - Parameter 'Component' implicitly has an 'any' type.
    (Component) => (props) => {
      const dispatch = useDispatch();

      const { data, loading, variables, refetch } =
        useGetTransferFormViewerQuery({
          variables: {
            date: moment().format('YYYY-MM-DD'),
            transferType: null,
            fromParticipantId: null,
            toParticipantId: null,
          },
        });

      const viewer = data?.viewer;

      React.useEffect(() => {
        const newVariables = {
          fromParticipantId: props.fromParticipantId,
          toParticipantId: props.toParticipantId,
          transferType: props.transferType,
        };
        if (
          !isEqual(
            {
              fromParticipantId: variables?.fromParticipantId,
              toParticipantId: variables?.toParticipantId,
              transferType: variables?.transferType,
            },
            newVariables,
          )
        ) {
          refetch(newVariables);
        }
      }, [
        props.transferType,
        props.fromParticipantId,
        props.toParticipantId,
        refetch,
        variables,
      ]);

      React.useEffect(() => {
        if (variables?.toParticipantId && !variables.fromParticipantId) {
          const fromId = fromAccountIdToSelectWithToAccountSelected(
            viewer,
            variables,
          );
          if (fromId) {
            dispatch(change('transfer-instance', 'fromParticipantId', fromId));
            refetch({
              fromParticipantId: fromId,
            });
          }
        }
      }, [dispatch, variables, viewer, refetch]);

      const {
        fromParticipantId,
        toParticipantId,
        isFromDropdownDisabled,
        isToDropdownDisabled,
      } = props;
      return (
        <Component
          {...props}
          {...variables}
          isFromDropdownDisabled={
            isFromDropdownDisabled ||
            (loading && Boolean(toParticipantId) && !fromParticipantId)
          }
          isToDropdownDisabled={
            isToDropdownDisabled ||
            (loading && Boolean(fromParticipantId) && !toParticipantId)
          }
          viewer={viewer}
        />
      );
    },

    UNSAFE_connectRedux((state: AppState): any => {
      let transferInstanceErrors = null;
      let transferInstanceAmount = null;

      if (state.form) {
        const form =
          state.form['transfer-instance'] ||
          state.form['edit-transfer-schedule'];
        if (form?.syncErrors) {
          transferInstanceErrors = form.syncErrors;
        }
        if (form?.values?.amount) {
          transferInstanceAmount = form.values.amount;
        }
      }
      const hasValidTransferInput = transferInstanceErrors === null;
      const hasAnyTransferInput = Boolean(transferInstanceAmount);

      return {
        transferAmountFromStore: transferInstanceAmount,
        hasValidTransferInput,
        hasAnyTransferInput,
        navigate: state.routing.navigate,
        showTransactionError: hasAnyTransferInput && !hasValidTransferInput,
      };
    }),
  );
}

function fromAccountIdToSelectWithToAccountSelected(
  viewer: GetTransferFormViewerQuery['viewer'] | null | undefined,
  variables: Record<string, any>,
): string | null | undefined {
  if (
    viewer?.transfers?.destinationParticipants?.list &&
    viewer.transfers.destinationParticipants.list.length === 1 &&
    variables?.toParticipantId
  ) {
    return viewer.transfers.destinationParticipants.list[0].id;
  }

  return null;
}

export const CreateOneTimeOrScheduledTransferForm = enhancer(
  CreateOneTimeOrScheduledTransfer,
) as any;
