export const MONITIOR_ACTIONS = Object.freeze({
  FINISHED_ADD_SHARED_PIE: 'FINISHED_ADD_SHARED_PIE' as const,
  CLICKED_CANCEL_TRANSFER_INSTANCE: 'CLICKED_CANCEL_TRANSFER_INSTANCE' as const,
});

export type FinishedAddSharedPieAction = {
  payload: {
    didAttemptPieCreation: boolean;
    didRegisterNewUser: boolean;
    isCrypto?: boolean | null | undefined;
    pieId: string | null | undefined;
    shareToken: string;
  };
  type: typeof MONITIOR_ACTIONS.FINISHED_ADD_SHARED_PIE;
};

export type ClickedCancelTransferInstanceAction = {
  payload: {
    source: string;
    transferInstance: string;
  };
  type: typeof MONITIOR_ACTIONS.CLICKED_CANCEL_TRANSFER_INSTANCE;
};

export const finishedAddSharedPie = (
  payload: FinishedAddSharedPieAction['payload'],
): FinishedAddSharedPieAction => ({
  payload,
  type: MONITIOR_ACTIONS.FINISHED_ADD_SHARED_PIE,
});

export const clickedCancelTransferInstance = (
  payload: ClickedCancelTransferInstanceAction['payload'],
): ClickedCancelTransferInstanceAction => ({
  type: MONITIOR_ACTIONS.CLICKED_CANCEL_TRANSFER_INSTANCE,
  payload,
});

export type MonitorAction =
  | FinishedAddSharedPieAction
  | ClickedCancelTransferInstanceAction;
