import { Flex, Box } from '@m1/liquid-react';
import React from 'react';

import { PieOrganizerNavigationButton } from '~/components/PieOrganizerNavigationButton/PieOrganizerNavigationButton';
import { useNewPieEditor } from '~/hooks/useNewPieEditor';
import { PieEditorEntryButton } from '~/pages/dashboard/wizards/pie-editor/PieEditorEntryButton';

export const BuildMyPieButtonGroup = () => {
  const { showNewPieEditor } = useNewPieEditor();

  return (
    <Flex justifyContent="flex-end">
      {showNewPieEditor ? (
        <PieEditorEntryButton
          label="Build a stock-and-fund Pie"
          kind="secondary"
          leftIcon="stocks24"
          size="large"
        />
      ) : (
        <PieOrganizerNavigationButton
          event={{
            initConfig: {
              confirmationDialog: {
                showApplicableLocations: false,
              },
              mode: 'NEW_PIE',
              sliceableIds: [],
              returnTo: '/d/research/my-pies',
            },
            type: 'INITIALIZE_ORGANIZER',
          }}
          kind="secondary"
          label="Build a stock-and-fund Pie"
          leftIcon="stocks24"
          size="large"
        />
      )}
      <Box ml={16}>
        {showNewPieEditor ? (
          <PieEditorEntryButton
            label="Build a crypto Pie"
            kind="secondary"
            leftIcon="crypto24"
            size="large"
            isCrypto
          />
        ) : (
          <PieOrganizerNavigationButton
            event={{
              initConfig: {
                confirmationDialog: {
                  showApplicableLocations: false,
                },
                mode: 'NEW_PIE',
                sliceableIds: [],
                returnTo: '/d/research/my-pies',
                isCrypto: true,
              },
              type: 'INITIALIZE_ORGANIZER',
            }}
            label="Build a crypto Pie"
            leftIcon="crypto24"
            kind="secondary"
            size="large"
          />
        )}
      </Box>
    </Flex>
  );
};
