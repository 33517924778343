import { Box, styled } from '@m1/liquid-react';
import * as React from 'react';

import { CONTENT_PADDING, useLayout } from '~/hooks/useLayout';

const Container = styled(Box)<{ contentWidth: number }>`
  max-width: ${({ contentWidth }) => `${contentWidth}px`};
  width: 100%;
  padding: 0 ${CONTENT_PADDING}px 32px;
  margin: 0 auto;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.MEDIUM}) {
    padding: 0 48px 32px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.SMALL}) {
    padding: 0 24px 20px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XXSMALL}) {
    padding: 0 16px 16px;
  }
`;

export const HomeContainer = ({ children }: { children: React.ReactNode }) => {
  const { contentWidth } = useLayout();
  return (
    <Container contentWidth={contentWidth} flex="1">
      {children}
    </Container>
  );
};
