import { Icon } from '@m1/liquid-react/icons';
import moment from 'moment';
import * as React from 'react';

import { UserKeysFragment } from '~/graphql/types';
import { updateUserData } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { OnboardingHelperModal } from '~/toolbox/helper';

import smartTransfersOnboardingHelper from './images/st-onboarding-helper.svg';

type OnboardingSmartTransferModalProps = {
  user: UserKeysFragment | null | undefined;
};

export const OnboardingSmartTransferModal = ({
  user,
}: OnboardingSmartTransferModalProps) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    const hasUserSeenIntroductionModal = user?.data.some((dataPoint) =>
      dataPoint.key.includes('smartTransferHelperDate'),
    );

    if (!hasUserSeenIntroductionModal) {
      setIsModalOpen(true);
    }

    dispatch(
      updateUserData({
        key: 'smartTransferHelperDate',
        value: moment().toISOString(),
      }),
    );
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <OnboardingHelperModal
      helperBodyContent={
        <>
          Located on the top right corner, <b>Helper</b> can be switched on to
          explain what is being displayed on screen. Access to FAQs are in{' '}
          <b>Helper</b>. Tap on <Icon name="helpFill24" color="primary" /> to
          switch off.
        </>
      }
      helperHeaderContent="Helper"
      helperModalOpen={isModalOpen}
      handleCloseOnboardingHelperModal={handleCloseModal}
      helperImageSource={smartTransfersOnboardingHelper}
    />
  );
};
