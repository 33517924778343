export const deserializeDisclosureString = (
  // @ts-expect-error - TS7006 - Parameter 'disclosureString' implicitly has an 'any' type.
  disclosureString,
): Array<string> => {
  if (Array.isArray(disclosureString)) {
    return disclosureString;
  }
  if (disclosureString && disclosureString.includes(',')) {
    return disclosureString.split(',');
  }
  return [disclosureString];
};
