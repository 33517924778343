export const getMobileOperatingSystem = (): 'Android' | 'iOS' | null => {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // @ts-ignore - `!window.MSStream` is to not incorrectly detect IE11
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return null;
};
