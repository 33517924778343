import {
  Box,
  Button,
  Flex,
  HXS,
  PM,
  PXL,
  styled,
  Card,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { SmartTransfersEndpointNavigationWarningModal } from '~/components/modals';
import { TransferParticipantCell } from '~/components/transfers/TransferParticipantCell';
import { TransferParticipantCellRenderer } from '~/components/transfers/TransferParticipantCellRenderer';
import {
  ACTION_TYPES as ACTIONS,
  addSmartTransferContraParticipantIdAction,
} from '~/redux/actions';
import type { SmartTransferFulfillmentConditionInput } from '~/redux/actions/smartTransfer/smartTransferActions.types';
import { useDispatch, useSelector } from '~/redux/hooks';

import {
  SmartTransferBubble,
  SmartTransferToggle,
} from '../../../components/SmartTransferHelpers';

import { ActiveContraParticipantEntryTags } from './ActiveContraParticipantEntryTags';
import { FulfillmentConditionInputs } from './FulfillmentConditionInputs.apollo';

type FulfillmentConditionRefetchContainerComponentProps = {
  newSmartTransferContraParticipantEntryOption: any;
  onFinishStep: (arg0: {
    contraEditType:
      | 'ADD_ACTIVE_CONTRA_PARTICIPANT'
      | 'DELETE_ACTIVE_CONTRA_PARTICIPANT'
      | 'REPLACE_ACTIVE_CONTRA_PARTICIPANT';
  }) => void;
  refetch: any;
};

const StyledLeftColumnBox = styled(Box)`
  background-color: ${(props) => props.theme.colors.backgroundNeutralSecondary};
`;

const StyledEmptyStateImage = styled(Illustration)`
  // allow the asset to be flush with the bottom right corner of the parent
  bottom: 0;
  position: absolute;
  right: 0;
  width: 40vw;
`;

const StyledSelectedAccount = styled(Flex)`
  border: 1px solid ${(props) => props.theme.colors.foregroundNeutralTertiary};
  border-radius: 8px;
  padding: 16px 32px;
`;

export const FulfillmentConditionRefetchContainer = ({
  newSmartTransferContraParticipantEntryOption,
  onFinishStep,
  refetch,
}: FulfillmentConditionRefetchContainerComponentProps) => {
  const activeContraParticipantId: string | null | undefined = useSelector(
    (state) =>
      state.newFlows.CREATE_SMART_TRANSFER.activeContraParticipantEntry
        ?.contraParticipantId,
  );

  const [contraParticipantId, setContraParticipantId] = React.useState(
    activeContraParticipantId,
  );
  const [editRequirementsId, setEditRequirementsId] = React.useState(null);

  const dispatch = useDispatch();
  // once a contraParticipant is selected, add its id to the store
  // similarly, when an editRequirements id is found, add it to the store for future use
  React.useEffect(() => {
    if (contraParticipantId) {
      dispatch(addSmartTransferContraParticipantIdAction(contraParticipantId));
    }
    if (contraParticipantId && editRequirementsId) {
      dispatch({
        type: ACTIONS.ADD_SMART_TRANSFER_EDIT_REQUIREMENTS_ID,
        payload: {
          contraParticipantId,
          editRequirementsId,
        },
      });
    }
  }, [dispatch, contraParticipantId, editRequirementsId]);
  const [isWarningModalOpen, setIsWarningModalOpen] = React.useState(false);
  const [
    isInitialFulfillmentOptionSelected,
    setIsInitialFulfillmentOptionSelected,
  ] = React.useState(false);

  const req = newSmartTransferContraParticipantEntryOption.editRequirements;

  // once a editRequirements.id is found from our remote object, store it in state
  React.useEffect(() => {
    if (req && req.id && editRequirementsId === null) {
      setEditRequirementsId(req.id);
    }
  }, [editRequirementsId, req]);

  if (req && !req.fulfillmentConditionOptionSet) {
    throw new Error(
      "Unable to read 'fulfillmentConditionOptionSet' on SmartTransferContraParticipantEntryEditRequirements",
    );
  }
  const fulfillmentConditionOptionSet =
    req && req.fulfillmentConditionOptionSet;
  const contraParticipants = React.useMemo(
    () =>
      (newSmartTransferContraParticipantEntryOption.contraParticipants &&
        newSmartTransferContraParticipantEntryOption.contraParticipants.list) ||
      [],
    [newSmartTransferContraParticipantEntryOption.contraParticipants],
  );

  React.useEffect(() => {
    if (contraParticipantId) {
      refetch({
        contraParticipantId,
        newSmartTransferContraParticipantEntryOptionId:
          newSmartTransferContraParticipantEntryOption.id,
      });
    }

    if (contraParticipants.length === 1) {
      setContraParticipantId(contraParticipants[0].id);
    }
  }, [
    contraParticipantId,
    contraParticipants,
    newSmartTransferContraParticipantEntryOption,
    refetch,
  ]);

  const onFulfillmentDataChange = (
    fulfillmentCondition: SmartTransferFulfillmentConditionInput,
  ) => {
    dispatch({
      type: ACTIONS.SET_SMART_TRANSFER_FULFILLMENT_CONDITIONS,
      payload: fulfillmentCondition,
    });
  };

  const leftColumnTitle =
    req?.title || newSmartTransferContraParticipantEntryOption.title;
  const contraParticipantSectionLabel =
    req?.contraParticipantLabel || 'Select account';

  const [activeStep, setActiveStep] = React.useState(1);

  return (
    <>
      <StyledLeftColumnBox height="100vh" py={100} width="50%">
        <Box maxWidth={550} ml="auto" mr={64}>
          <BackArrow
            content="Create Smart Transfer"
            mb={16}
            to="/d/c/create-smart-transfer/set-smart-transfer"
          />
          <Flex justifyContent="space-between">
            {leftColumnTitle && <HXS content={leftColumnTitle} mt={6} />}
            <SmartTransferToggle />
          </Flex>
          <>
            <Card
              isElevated
              backgroundColor="backgroundNeutralMain"
              mt={64}
              minHeight={230}
              p={32}
            >
              <PXL
                fontWeight={600}
                content={contraParticipantSectionLabel}
                mb={12}
              />
              <ContraParticipantsSection
                contraParticipants={contraParticipants}
                contraParticipantId={contraParticipantId}
                setContraParticipantId={setContraParticipantId}
                setIsWarningModalOpen={setIsWarningModalOpen}
              />
            </Card>
            {req && !isInitialFulfillmentOptionSelected && (
              <>
                <SmartTransferBubble
                  activeStepNumber={activeStep}
                  title="Account overview"
                  description="This area displays your selected account, its current balance info, and conditions for transferring to this account."
                  maxWidth={560}
                  stepNumber={1}
                  totalSteps={2}
                  handleGoForward={() => setActiveStep(2)}
                />
                <Card
                  borderColor="transparent"
                  backgroundColor="backgroundPrimarySubtle"
                  mt={32}
                  p="28px 32px"
                  position="relative"
                >
                  <PXL
                    fontWeight={600}
                    content={req.fulfillmentConditionOptionEmptyTitle}
                  />
                  <Box maxWidth={300}>
                    <PM
                      content={req.fulfillmentConditionOptionEmptyDescription}
                      pt={4}
                    />
                  </Box>
                  <Illustration
                    // @ts-expect-error - TS2322 - Type '{ alt: string; name: "smartTransfer"; position: string; right: string; bottom: string; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'.
                    alt=""
                    name="smartTransfer"
                    position="absolute"
                    right="-1px"
                    bottom="-1px"
                  />
                </Card>
                <SmartTransferBubble
                  activeStepNumber={activeStep}
                  title="Set endpoint"
                  description="Setting the endpoint gives you the ability to customize when an active Smart Transfer rule should end."
                  maxWidth={560}
                  stepNumber={2}
                  totalSteps={2}
                  handleGoBackward={() => setActiveStep(1)}
                />
              </>
            )}
          </>
        </Box>
      </StyledLeftColumnBox>
      <Box width="50%">
        {fulfillmentConditionOptionSet && contraParticipantId ? (
          <FulfillmentConditionInputs
            fulfillmentConditionOptionSet={fulfillmentConditionOptionSet}
            isEditMode={false}
            onFinishStep={onFinishStep}
            onFulfillmentDataChange={onFulfillmentDataChange}
            onInitialOptionSelect={() => {
              setIsInitialFulfillmentOptionSelected(true);
            }}
            preamble={req && req.fulfillmentConditionOptionSetListingPreamble}
          />
        ) : (
          // @ts-expect-error - TS2769 - No overload matches this call.
          <StyledEmptyStateImage alt="" name="setSource" />
        )}
      </Box>
      <SmartTransfersEndpointNavigationWarningModal
        isWarningModalOpen={isWarningModalOpen}
        setIsWarningModalOpen={setIsWarningModalOpen}
        onConfirmation={() => {
          setContraParticipantId(null);
        }}
      />
    </>
  );
};

// Below are subcomponent(s) intended to help make the re-fetch container above easier to understand.
// These component(s) are intentionally placed in this file instead of their own to couple them with the above implementation.

type ContraParticipantsSectionProps = {
  contraParticipantId: string | null | undefined;

  contraParticipants: any;
  setContraParticipantId: (arg0: string) => void;
  setIsWarningModalOpen: (arg0: boolean) => void;
};

export const ContraParticipantsSection = ({
  contraParticipantId,
  contraParticipants,
  setContraParticipantId,
  setIsWarningModalOpen,
}: ContraParticipantsSectionProps) => {
  // if no contraParticipants are found, enter error state.
  // if a contraParticipant has been selected, render it.
  // otherwise, render all contraParticipant options.
  if (contraParticipants.length === 0) {
    throw new Error('No contraParticipants found');
  }

  if (contraParticipantId) {
    return (
      <>
        <StyledSelectedAccount
          alignItems="center"
          justifyContent="space-between"
        >
          <TransferParticipantCellRenderer
            includeDescription
            id={contraParticipantId}
          />
          {contraParticipants.length > 1 && (
            <Button
              kind="link"
              label="Change"
              style={{ fontSize: '16px' }}
              onClick={() => setIsWarningModalOpen(true)}
            />
          )}
        </StyledSelectedAccount>
        <ActiveContraParticipantEntryTags mt={16} mb={48} />
      </>
    );
  }
  return (
    <>
      {contraParticipants.map((contraParticipant: any) => (
        <Card
          key={contraParticipant.id}
          onClick={() => {
            setContraParticipantId(contraParticipant.id);
          }}
          my={16}
          p="12px 16px"
        >
          <Flex alignItems="center" justifyContent="space-between">
            <TransferParticipantCell
              includeDescription
              transferParticipant={contraParticipant}
            />
            <Icon name="caretRight16" />
          </Flex>
        </Card>
      ))}
    </>
  );
};
