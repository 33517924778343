import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';

import { OpenInvestJointAccountWizard } from '~/flows/components/OpenInvestJointAccountWizard';

export const OpenInvestJointAccountPage = () => {
  return (
    <PageErrorBoundary>
      <Box position="relative" mx="auto" minWidth={588}>
        <OpenInvestJointAccountWizard />
      </Box>
    </PageErrorBoundary>
  );
};
