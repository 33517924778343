import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { useSpendPageQuery } from '~/graphql/hooks';
import { Spinner } from '~/toolbox/spinner';
import { isNotNil } from '~/utils';

import { SpendRedirect } from './SpendRedirect';

export const SpendPage = () => {
  const terminalApplicationStatuses = ['APPROVED', 'ACCEPTED', 'REJECTED'];
  const { data, loading } = useSpendPageQuery();

  if (loading) {
    return <Spinner />;
  }

  const credit = data?.viewer.credit;
  const hasClosedCreditCardAccount = credit?.hasClosedAccount ?? false;
  const hasActiveCreditCardAccount = credit?.hasActiveAccount ?? false;
  const hasSuspendedCreditCardAccount = credit?.hasSuspendedAccount ?? false;

  const hasActiveApplication =
    isNotNil(credit?.activeApplication) &&
    !terminalApplicationStatuses.includes(credit.activeApplication.status);

  const hasCreditCardAccount =
    hasActiveCreditCardAccount || hasSuspendedCreditCardAccount;

  return (
    <SpendRedirect
      hasActiveApplication={hasActiveApplication}
      hasClosedCreditCardAccount={hasClosedCreditCardAccount}
      hasCreditCardAccount={hasCreditCardAccount}
    >
      <Outlet />
    </SpendRedirect>
  );
};
