import * as React from 'react';

import { LabelWithValue } from '~/components/label-with-value';
import { TransferRuleDetailFragment } from '~/graphql/types';
import { Grid } from '~/toolbox/grid';

import { RecurrenceScheduleDetails } from './RecurrenceScheduleDetails';

export const ScheduleDetails = ({
  isAutoPay,
  transferRule,
  displayAsRow,
}: {
  displayAsRow?: boolean | null | undefined;
  isAutoPay?: boolean;
  transferRule: TransferRuleDetailFragment;
}) => {
  const Wrapper = displayAsRow ? Grid.Col : React.Fragment;

  switch (transferRule.__typename) {
    case 'ScheduledTransferRule':
      return (
        <>
          <Wrapper xs={6}>
            <LabelWithValue
              label="Frequency"
              style={{
                marginTop: displayAsRow ? 12 : 0,
              }}
              value={transferRule.schedule ? transferRule.schedule.title : '--'}
            />
          </Wrapper>
          <Wrapper xs={6}>
            {transferRule.schedule && !isAutoPay && (
              <RecurrenceScheduleDetails schedule={transferRule.schedule} />
            )}
          </Wrapper>
        </>
      );
    default:
      return null;
  }
};
