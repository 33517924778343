import { Box, Button, PM } from '@m1/liquid-react';
import * as React from 'react';
import { useFormContext, SubmitHandler } from 'react-hook-form';

import { ControlledDropdown } from '~/components/form/ControlledDropdown';
import { ControlledInput } from '~/components/form/ControlledInput';
import { printableAsciiChars } from '~/forms/validators';
import {
  EmploymentStatusEnum,
  OnboardingIdentityFirstQuestionType,
} from '~/graphql/types';
import { FIELD_MAX_LENGTHS } from '~/static-constants';
import { getEnumEntries } from '~/utils';

import { FinancialSuitabilityFormFields } from '../hooks/useFinancialSuitabilityWizardForm';

type PrimaryEmploymentFormProps = {
  occupationOptions: { label: string }[];
  continueLabel: string;
  nextQuestion?: OnboardingIdentityFirstQuestionType | null;
  onSubmit: SubmitHandler<FinancialSuitabilityFormFields>;
  handleNext: () => void;
};

const isProduction = __NODE_ENV__ === 'production' && __ENV__ === 'production';

export const PrimaryEmploymentForm = ({
  occupationOptions,
  continueLabel,
  nextQuestion,
  onSubmit,
  handleNext,
}: PrimaryEmploymentFormProps) => {
  const {
    control,
    watch,
    setValue,
    formState,
    register,
    unregister,
    handleSubmit,
  } = useFormContext<FinancialSuitabilityFormFields>();
  const disabled = !formState.isValid;
  const values = watch();

  const employmentStatus = values?.primary?.employment?.status;
  const isEmployed =
    employmentStatus === 'EMPLOYED' || employmentStatus === 'SELF_EMPLOYED';

  const occupationsWithNames = occupationOptions?.map((option) => {
    return {
      name: option.label,
      description: option.label,
    };
  });

  React.useEffect(() => {
    if (!isEmployed) {
      unregister('primary.employment.employer');
      unregister('primary.employment.occupation');
    } else {
      register('primary.employment.employer');
      register('primary.employment.occupation');
    }
  }, [isEmployed]);

  return (
    <Box>
      <Box pb={8}>
        <ControlledDropdown
          name="primary.employment.status"
          label="Employment status"
          control={control}
          source={getEnumEntries('EmploymentStatusEnum')}
          rules={{ required: true }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        />
      </Box>
      {isEmployed && (
        <>
          <Box pb={8}>
            <ControlledInput
              name="primary.employment.employer"
              label="Employer name"
              control={control}
              rules={{
                required: true,
                maxLength: FIELD_MAX_LENGTHS.DEFAULT,
                validate: {
                  printableAsciiChars: (value) =>
                    typeof value === 'string' && printableAsciiChars(value),
                },
              }}
            />
          </Box>
          <Box pb={8}>
            <ControlledDropdown
              name="primary.employment.occupation"
              label="Occupation/Industry"
              source={occupationsWithNames}
              control={control}
              rules={{
                required: true,
              }}
            />
          </Box>
        </>
      )}
      <Button
        type={nextQuestion ? 'button' : 'submit'}
        mt={32}
        size="large"
        label={continueLabel}
        onClick={() => (nextQuestion ? handleNext() : handleSubmit(onSubmit)())}
        disabled={disabled}
      />
      {!isProduction && (
        <PM
          color="primary"
          cursor="pointer"
          content="Mock this value!"
          onClick={() => {
            setValue(
              'primary.employment.status',
              EmploymentStatusEnum.Student,
              {
                shouldValidate: true,
              },
            );
          }}
          pt={8}
        />
      )}
    </Box>
  );
};
