import { PXL } from '@m1/liquid-react';
import * as React from 'react';

import { LabelWithValue } from '~/components/label-with-value';
import { TransferRuleDetail_ScheduledTransferRule_Fragment } from '~/graphql/types';
import { Grid } from '~/toolbox/grid';

import { ScheduleDetails } from './ScheduleDetails';

export const CheckTransferDetails = ({
  transferRule,
}: {
  transferRule: TransferRuleDetail_ScheduledTransferRule_Fragment;
}) => (
  <>
    <Grid.Row>
      <Grid.Col xs={6}>
        <LabelWithValue
          label="From"
          value={
            transferRule.from ? transferRule.from.transferParticipantName : '--'
          }
        />
      </Grid.Col>
      <Grid.Col xs={6}>
        <LabelWithValue
          label="To"
          value={
            transferRule.to ? transferRule.to.transferParticipantName : '--'
          }
        />
      </Grid.Col>
    </Grid.Row>
    {transferRule.__typename === 'ScheduledTransferRule' &&
      transferRule.additionalDetails?.map(
        (detail) =>
          detail && (
            <Grid.Row key={detail.label}>
              <Grid.Col xs={6}>
                <LabelWithValue
                  style={{
                    marginTop: 12,
                  }}
                  label={detail.label}
                  value={detail.values.map((value) => (
                    <PXL key={value} fontWeight={600}>
                      {value}
                      <br />
                    </PXL>
                  ))}
                />
              </Grid.Col>
            </Grid.Row>
          ),
      )}

    <Grid.Row>
      <ScheduleDetails transferRule={transferRule} displayAsRow />
    </Grid.Row>
  </>
);
