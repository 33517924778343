import merge from 'lodash-es/merge';
import set from 'lodash-es/set';

import { TrustedContactInput } from '~/graphql/types';
import { ACTION_TYPES as ACTIONS, ROUTING_ACTIONS } from '~/redux/actions';
import { RetirementAccountTypes } from '~/redux/actions/openInvestAccountActions';
import { OPEN_INVEST_ACCOUNT_STEPS as STEPS } from '~/static-constants';

import {
  createFlowReducer,
  createStepReducer,
} from '../utils/createFlowReducer';

export type OpenInvestAccountFlowState = {
  basePath: string;
  step: ValueOf<typeof STEPS>;
  secondary: Record<string, any>;
  secondarySsn: string | null;
  suitability: Record<string, any>;
  input: Record<string, any>;
  trustedContact: TrustedContactInput | null;
  retirementAccountType: RetirementAccountTypes | null;
};

const initialState: OpenInvestAccountFlowState = {
  basePath: '/d/open-invest-account',
  step: STEPS.SELECT_ACCOUNT_TYPE,
  secondary: {},
  secondarySsn: null,
  suitability: {},
  input: {},
  trustedContact: null,
  retirementAccountType: null,
};

const stepReducer = createStepReducer(initialState);

function reducer(
  state: OpenInvestAccountFlowState = initialState,
  action: any,
): OpenInvestAccountFlowState {
  switch (action.type) {
    case ACTIONS.BEGIN_FLOW:
      return {
        ...initialState,
        step: initialState.step,
      };
    case ACTIONS.FINISHED_FLOW_STEP:
      return {
        ...state,
      };
    case ROUTING_ACTIONS.LOCATION_CHANGE:
      return {
        ...state,
        step: stepReducer(state, action),
      };
    case ACTIONS.END_FLOW:
      return {
        ...initialState,
      };

    // joint accounts
    case ACTIONS.SUBMITTED_CONTACT_INFO_FORM_MODERN:
    case ACTIONS.SUBMITTED_CUSTODIAL_CONTACT_INFO_FORM_MODERN:
    case ACTIONS.SUBMITTED_IDENTITY_INFO_FORM_MODERN:
    case ACTIONS.SUBMITTED_DISCLOSURES_FORM_MODERN:
    case ACTIONS.SUBMITTED_EMPLOYMENT_INFO_MODERN:
    case 'EXPLAINED_PROFILE_COLLECTION':
      return merge({}, state, action.payload.values);
    case ACTIONS.COLLECTED_HOLDER_SSN_MODERN: {
      const { holder, ssn } = action.payload;
      return merge({}, state, {
        [`${holder}Ssn`]: ssn,
        holder,
      });
    }
    case 'COLLECTED_INVEST_TRUSTED_CONTACT': {
      return merge({}, state, action.payload);
    }
    case 'SKIPPED_INVEST_TRUSTED_CONTACT': {
      return merge({}, state, {
        trustedContact: null,
      });
    }
    case ACTIONS.SUBMITTED_PROFILE_INPUT_MODERN: {
      const { field, value } = action.payload;
      return merge(
        {
          ...state,
        },
        set({}, field, value),
      );
    }
    case 'SET_RETIREMENT_ACCOUNT_TYPE':
      return {
        ...state,
        retirementAccountType: action.payload,
      };
    default:
      return state;
  }
}

export const openInvestAccountReducer = createFlowReducer(
  'OPEN_INVEST_ACCOUNT',
  reducer,
);
