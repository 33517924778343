import { Button, Flex, HXS, PL, PXL } from '@m1/liquid-react';
import * as React from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { ControlledInput } from '~/components/form/ControlledInput';
import { useWizardContext } from '~/flows/wizard';
import { useCalculateNiaQuery } from '~/graphql/hooks';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { submitReactFormData } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

import { calculateNiaAmount } from '../utils/calculateNiaAmount';

import { StyledTransfersCard } from './TransferDetails';

export type TransferNiaCalculatorFormValues = {
  amount: number | null | undefined;
  excessToBeRemoved: number | null;
  adjestOpeningBalance: number | null;
  adjustedClosingBalance: number | null;
};

const getNiaDisplayValue = (
  amount: string | null | undefined,
  niaAmount: number | null | undefined,
): string | null | undefined => {
  if (!amount || typeof niaAmount !== 'number') {
    return null;
  } else if (niaAmount < 0) {
    return amount.replace('-', '-$');
  }
  return `$${amount}`;
};

const getNiaAmount = (
  excessAmount: number | null | undefined,
  acbAmount: number | null | undefined,
  aobAmount: number | null | undefined,
): number | null | undefined => {
  if (excessAmount && acbAmount && aobAmount) {
    const amount = calculateNiaAmount(excessAmount, acbAmount, aobAmount);
    return amount;
  }
  return null;
};

/*
  The entrypoint to this component was removed because of a compliance request.
  Left here in case it ever needs/wants to be re-added.
*/
export const TransferNiaCalculatorStep = () => {
  const { data } = useCalculateNiaQuery();
  const { goTo } = useWizardContext();
  const dispatch = useDispatch();
  const [niaAmount, setNiaAmount] = React.useState<number | null | undefined>(
    null,
  );
  const formMethods = useForm<TransferNiaCalculatorFormValues>({
    defaultValues: {
      amount: null,
      excessToBeRemoved: null,
      adjestOpeningBalance: null,
      adjustedClosingBalance: null,
    },
  });
  const { handleSubmit, watch } = formMethods;

  const reset = () => {
    formMethods.reset({
      amount: null,
      excessToBeRemoved: null,
      adjestOpeningBalance: null,
      adjustedClosingBalance: null,
    });
  };

  const handleApply: SubmitHandler<TransferNiaCalculatorFormValues> = (
    formValues,
  ) => {
    dispatch(
      submitReactFormData({
        niaCalculator: {
          ...formValues,
          amount: niaAmount,
        },
      }),
    );
    goTo('TRANSFER_DETAILS');
  };
  const { excessToBeRemoved, adjestOpeningBalance, adjustedClosingBalance } =
    watch();

  React.useEffect(() => {
    const niaAmount = getNiaAmount(
      excessToBeRemoved,
      adjustedClosingBalance,
      adjestOpeningBalance,
    );
    setNiaAmount(niaAmount);
  }, [excessToBeRemoved, adjestOpeningBalance, adjustedClosingBalance]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleApply)}>
        <StyledTransfersCard>
          <HXS content="Calculate Net Income Attributable" mb={16} />
          <ControlledInput
            name="excessToBeRemoved"
            label="Excess to be removed"
            maskType="money"
            maxLength={10}
          />
          <ControlledInput
            name="adjustedClosingBalance"
            label="Adjusted closing balance"
            maskType="money"
            maxLength={10}
          />
          <ControlledInput
            name="adjestOpeningBalance"
            label="Adjusted opening balance"
            maskType="money"
            maxLength={10}
          />
          <Flex mt={16} alignItems="center" justifyContent="space-between">
            <PXL content="Net income attributable" />
            <Button kind="link" size="large" label="Clear" onClick={reset} />
          </Flex>
          <PXL mt={8}>
            {getNiaDisplayValue(niaAmount?.toFixed(2), 0) || '$0'}
          </PXL>
          <PL
            content="This tool is provided to assist you with calculating NIA and may not be accurate."
            mt={32}
          />
          <PL
            content="M1 does not provide tax advice, you should consult your own tax advisor regarding your personal circumstances before taking any action that may have tax consequences."
            mt={16}
          />
          {data?.viewer.transfers?.niaLearnMore && (
            <LinkableLink
              linkable={data.viewer.transfers.niaLearnMore}
              mt={16}
            />
          )}
          <ButtonGroup behavior="fill-space" mt={32}>
            <Button
              kind="secondary"
              label="Back"
              onClick={() => goTo('TRANSFER_DETAILS')}
            />
            <Button
              disabled={!niaAmount}
              kind="primary"
              type="submit"
              label="Apply"
            />
          </ButtonGroup>
        </StyledTransfersCard>
      </form>
    </FormProvider>
  );
};
