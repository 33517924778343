import partial from 'lodash-es/partial';

import { UNSAFE_connectRedux } from '~/hocs';
import type { AppState } from '~/redux';
import { NewFlowsState } from '~/redux/reducers/newFlows';
import { Progress } from '~/toolbox/progress';

type FlowStateAccessor = (state: AppState) => ValueOf<NewFlowsState>;
export type StepProgressMap = Record<string, number>;

export function stepToProgress(
  stateAccessor: FlowStateAccessor,
  stepsToProgress: StepProgressMap | string[],
) {
  let stepProgressMap: StepProgressMap = {};

  // If steps given in array, evenly space them
  if (Array.isArray(stepsToProgress)) {
    const progressPerStep = Math.ceil(100 / stepsToProgress.length);
    for (let stepI = 0; stepI < stepsToProgress.length; stepI++) {
      const step = stepsToProgress[stepI];
      stepProgressMap[step] = progressPerStep * stepI;
    }
  } else {
    stepProgressMap = stepsToProgress;
  }
  const reader = partial(readProgress, stepProgressMap);
  const enhancer = UNSAFE_connectRedux((state: AppState): any => ({
    percent: reader(stateAccessor(state).step),
  }));

  return enhancer(Progress);
}

function readProgress(progressMap: StepProgressMap, step: string): number {
  return progressMap[step] ?? 5;
}
