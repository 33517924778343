import {
  Box,
  HM,
  PL,
  Flex,
  styled,
  TextProps,
  Tooltip,
} from '@m1/liquid-react';
import * as React from 'react';

import { isNotNil } from '~/utils';

import { BackArrow } from '../BackArrow';

type Option<TOptionValue> = {
  description: string;
  name: TOptionValue;
};

export type SingleSelectButtonInputProps<TOptionValue = string> = {
  content?: TextProps['content'];
  field: string;
  getOptionKey?: (value: TOptionValue) => string;
  options: Array<Option<TOptionValue>>;
  onBackClick?: () => void;
  onClick: (value: TOptionValue) => void;
  question: string;
  showBackButton?: boolean;
  tooltip?: React.ReactNode;
};

const StyledOption = styled(Box)`
  border: 2px solid;
  border-radius: 24px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
  outline: none;
  padding: 1rem;
  user-select: none;

  ${(props) => `
    color: ${props.theme.colors.primary};
    border-color: ${props.theme.colors.primary};
  `}

  &:focus, &:hover {
    ${(props) => `
      color: ${props.theme.colors.primaryShade};
      border-color: ${props.theme.colors.primaryShade};
    `}
`;

export const SingleSelectButtonInput = <TOptionValue,>({
  content,
  field,
  getOptionKey = (value: TOptionValue) => `${value}`,
  options,
  onBackClick = () => window.history.back(),
  onClick: handleClick,
  question,
  showBackButton = false,
  tooltip,
}: SingleSelectButtonInputProps<TOptionValue>) => {
  return (
    <Box maxWidth={588} mx="auto">
      {showBackButton && (
        <Box mt={32}>
          <BackArrow content="Back" onClick={onBackClick} />
        </Box>
      )}
      <Flex
        flexDirection="row"
        justifyContent="left"
        alignItems="center"
        my={32}
      >
        <HM content={question} mr={8} />
        {isNotNil(tooltip) && (
          <Tooltip
            body={tooltip}
            iconColor="foregroundPrimary"
            placement="bottom-start"
          />
        )}
      </Flex>
      {isNotNil(content) && <PL content={content} my={32} />}
      {options.map(({ name, description }) => (
        <StyledOption
          role="button"
          tabIndex={0}
          key={`${field}:${getOptionKey(name)}`}
          onClick={() => handleClick(name)}
          onKeyDown={(event: React.KeyboardEvent) => {
            if (event.key === 'Enter' || event.code === 'Space') {
              handleClick(name);
            }
          }}
        >
          {description}
        </StyledOption>
      ))}
    </Box>
  );
};
