import { Button, HXS, PL, Flex, Box } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

type AccountConfirmationProps = {
  onFinishStep: () => void;
};

export const AccountConfirmation = ({
  onFinishStep,
}: AccountConfirmationProps) => {
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      mt={150}
      justifyContent="center"
    >
      <Illustration
        name="creditCardApplicationConfirmation"
        style={{ marginBottom: 40 }}
      />
      <Box width={500} textAlign="center">
        <HXS content="Your account has been opened." />
        <PL
          mt={32}
          content="Your physical card will arrive in the mail within 7-10 business days. For now,
          you can familiarize yourself with the M1 Credit Card dashboard and set up how
          you would like your rewards to be processed."
        />
        <Button
          mt={64}
          kind="primary"
          size="large"
          label="Continue"
          onClick={onFinishStep}
        />
      </Box>
    </Flex>
  );
};
