import * as React from 'react';
import { Route } from 'react-router-dom';

import { CreateSmartTransferPage } from './CreateSmartTransferPage';

export const CreateSmartTransferRoute = () => {
  return (
    <Route
      path="create-smart-transfer/:step?"
      element={<CreateSmartTransferPage />}
      handle={{
        fallbackRoute: {
          to: '/d/transfers',
        },
      }}
    />
  );
};
