import { Box, styled, type BoxProps } from '@m1/liquid-react';
import * as React from 'react';

export type ContainerProps = BoxProps & {
  centered?: boolean;
  children?: React.ReactNode;
  css?: string;
  marginBottom?: number;
  marginTop?: number;
  padded?: boolean | 'wide';
  text?: boolean;
};

const StyledContainer = styled(Box)<ContainerProps>`
  position: relative;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
  padding: ${(props) => {
    if (props.padded === 'wide') {
      return `32px 0`;
    }
    return props.padded ? '16px 0' : null;
  }};
  text-align: ${(props) => props.centered && 'center'};
  /* 1200 or 1024px for the large */
  max-width: ${(props) => (props.text ? '768px' : '1200px')};

  margin-bottom: ${(props) => props.marginBottom && `${props.marginBottom}px`};
  margin-top: ${(props) => props.marginTop && `${props.marginTop}px`};
  height: calc(100% - 60px);
  width: 100%;
`;

export const Container = (props: ContainerProps) => {
  const {
    centered = false,
    padded = false,
    text = false,
    children,
    ...rest
  } = props;
  return (
    <StyledContainer centered={centered} padded={padded} text={text} {...rest}>
      {children}
    </StyledContainer>
  );
};
