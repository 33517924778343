import { Theme } from '@m1/liquid-react';

export function generateSliceFillColorsArray(
  themeSliceColors: any,
  sliceLength: number,
): NonNullable<Theme['pieSliceColors']> {
  if (!themeSliceColors) {
    return [];
  }

  return themeSliceColors.slice(0, sliceLength);
}

export function pickSliceFillColor(
  colorArray: any,
  index: number,
  greyscale: boolean,
): string | null | undefined {
  const color =
    colorArray[
      index > colorArray.length - 1 ? index % colorArray.length : index
    ];

  if (!color) {
    return;
  }

  return color[!greyscale ? 'active' : 'greyscale'];
}
