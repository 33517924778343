import { Box, Flex, css, styled } from '@m1/liquid-react';
import * as React from 'react';

import { useDynamicSizing } from '~/hooks/useDynamicSizing';

import { Arrows } from './Arrows';
import { ProgressDots } from './ProgressDots';
import { ProgressNumbers } from './ProgressNumbers';

export type CarouselComponentProps = {
  displayQuantity?: number;
  elementWidth?: number;
  fixedProgressIndicator?: boolean;
  fullSize?: boolean;
  indicator?: 'dots' | 'numbers' | null;

  /**
   * The index of the item in the items array that will be initially displayed
   */
  initialItemIndex?: number;
  items: Array<React.ReactNode>;
  onItemChange?: (selected: number, total: number) => void;
  scrollSnap?: boolean;
};

const CarouselAndProgress = styled(Flex)`
  flex-direction: column;
  height: fit-content;
  container: carouselContainer / inline-size;
`;

const StyledCarouselContainer = styled.div<{
  $fullSize: boolean;
  $scrollSnap: boolean;
  $indicator: 'dots' | 'numbers' | null;
}>`
  overflow-x: scroll;
  overflow-y: hidden;
  align-self: flex-start;
  height: 100%;

  ${({ $indicator }) =>
    $indicator === 'numbers' &&
    // Design wants to hide scrollbars for numbered carousels
    css`
      /* Firefox */
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    `}

  ${({ $scrollSnap }) =>
    $scrollSnap &&
    css`
      scroll-snap-type: x mandatory;
    `}

  ${({ $fullSize }) =>
    $fullSize
      ? css`
          flex: 1;
        `
      : css`
          padding: 8px 24px 32px 36px;
          -webkit-mask-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 5%,
            rgba(255, 255, 255, 1) 85%,
            rgba(255, 255, 255, 0) 100%,
            rgba(255, 255, 255, 1) 160%
          );
        `}
`;

const ProgressContainer = styled(Box)<{
  fixedProgressIndicator: boolean;
  fullSize: boolean;
}>`
  ${({ fullSize }) =>
    fullSize &&
    css`
      margin-bottom: 16px;
      position: ${({ fixedProgressIndicator }: any) =>
        fixedProgressIndicator ? 'fixed' : 'initial'};
      left: 0;
      right: 0;
      bottom: 0;
    `}
`;

const ScrollSnap = styled(Box)<{
  isScrollSnapPoint: boolean;
}>`
  ${({ isScrollSnapPoint }) =>
    isScrollSnapPoint &&
    css`
      scroll-snap-align: start;
    `}
`;

export const Carousel = ({
  items,
  displayQuantity = 1,
  elementWidth: elementWidthProp = 120,
  indicator = null,
  fixedProgressIndicator = true,
  fullSize = false,
  onItemChange,
  initialItemIndex,
  scrollSnap = false,
}: CarouselComponentProps) => {
  const [elementWidth, setElementWidth] =
    React.useState<number>(elementWidthProp);
  const [activeItemIndex, setActiveItemIndex] = React.useState<number>(0);
  const [scrollAmount, setScrollAmount] = React.useState<number>(
    elementWidth * displayQuantity,
  );
  const [scrollPosition, setScrollPosition] = React.useState<number>(0);
  const [scrollContainerRefWidth, setScrollContainerRefWidth] =
    React.useState<number>(0);
  const [scrollContainerScrollWidth, setScrollContainerScrollWidth] =
    React.useState<number>(0);

  const scrollContainerRef = React.useRef<HTMLDivElement | null | undefined>();
  const scrollContainerRefCallBack = React.useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null) {
        scrollContainerRef.current = node;
        setScrollContainerScrollWidth(node.scrollWidth);
        setScrollContainerRefWidth(node.getBoundingClientRect().width);

        let width;
        if (fullSize) {
          width = node.getBoundingClientRect().width;
          setElementWidth(width);
        } else {
          width = elementWidth;
        }

        if (typeof initialItemIndex === 'number') {
          const left = width * Math.min(initialItemIndex, items.length);
          setScrollPosition(left);
          if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
              left,
            });
          }
        }
      }
    },
    [],
  );

  const [{ width }, resizeRef] = useDynamicSizing({
    shouldResize: true,
  });
  React.useEffect(() => {
    /*
     * Check for changes in window width and update elementWidth if necessary
     * Debounces changes by only updating width if change exceeds 50px
     */
    if (Math.abs(elementWidth - width) > 50) {
      setElementWidth(width);
      return;
    }
    // Updates the activeItemIndex when the scroll position changes only if the index has changed
    const newIndex =
      scrollPosition === 0 ? 0 : Math.round(scrollPosition / elementWidth);
    if (newIndex !== activeItemIndex) {
      setActiveItemIndex(newIndex);
      onItemChange && onItemChange(newIndex, numberOfDots);
    }
  }, [scrollPosition, elementWidth]);

  /* If there is an "uneven" number of elements in the carousel
  (if the last set of items to display is less than the displayQuantity), then the last slide/set of elements to display
  has a smaller width than the previous slides. This means that there is a smaller px amount to scroll by in order to scroll back to the previous set of items.
  To accommodate for that, if the last slide has less elements than the displayQuantity and the user has reached the end of the carousel,
  the scroll amount is re-calculated to be the width of the last slide. Afterwards, the scrollAmount is reset back to the width per slide.
  */
  React.useEffect(() => {
    const remainingItems = items.length % displayQuantity;
    const hasRemainingItems = remainingItems !== 0;

    // if the last item is being displayed and the slide has less items than the displayQuantity
    if (
      hasRemainingItems &&
      activeItemIndex === items.length - 1 - remainingItems
    ) {
      // set the scroll amount to the width of the each item * the number of items shown
      setScrollAmount(remainingItems * elementWidth);
    } else {
      setScrollAmount(elementWidth * displayQuantity);
    }
  }, [activeItemIndex, items.length, displayQuantity, elementWidth]);

  const handleClickScroll = (direction: 'LEFT' | 'RIGHT') => {
    if (scrollContainerRef.current) {
      // determine what amount of px should be removed from the scroll amount in state so that an option is always scrolled into view
      // in other words, we do not want a user to scroll by click and be brought to a state where a chunk of an option is overflowing
      const scrollLeft = scrollContainerRef.current.scrollLeft;
      const numOfItemsScrolled = Math.round(scrollLeft / elementWidth);
      const scrollOffsetAmount = scrollLeft - numOfItemsScrolled * elementWidth;
      // perform scroll on ref
      scrollContainerRef.current &&
        scrollContainerRef.current.scrollBy({
          top: 0,
          left:
            (direction === 'LEFT' ? -scrollAmount : scrollAmount) -
            scrollOffsetAmount,
          behavior: 'smooth',
        });
    }
  };

  const shouldRenderControls = !(
    scrollContainerScrollWidth === Math.floor(scrollContainerRefWidth) ||
    scrollContainerScrollWidth === Math.ceil(scrollContainerRefWidth)
  );

  const shouldRenderLeftArrow = Boolean(activeItemIndex !== 0);

  const shouldRenderRightArrow = Boolean(
    activeItemIndex + displayQuantity < items.length,
  );

  const numberOfDots = Math.ceil(items.length / displayQuantity);
  const selectedDot = Math.ceil(activeItemIndex / displayQuantity);

  React.useEffect(() => {
    onItemChange && onItemChange(selectedDot, numberOfDots);
  }, []);

  return (
    <CarouselAndProgress>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        position="relative"
        flex={fullSize ? '1' : undefined}
        ref={resizeRef}
      >
        <StyledCarouselContainer
          ref={scrollContainerRefCallBack}
          onScroll={(event: React.UIEvent<HTMLDivElement>) => {
            setScrollPosition(event.currentTarget.scrollLeft);
          }}
          $fullSize={fullSize}
          $scrollSnap={scrollSnap}
          $indicator={indicator}
        >
          <Flex position="relative" height={fullSize ? '100%' : undefined}>
            {items.map((element, index) => {
              const marginRight = index === items.length - 1 ? 36 : 0;
              return (
                <ScrollSnap
                  key={index}
                  minWidth={fullSize ? '100%' : undefined}
                  isScrollSnapPoint={index % displayQuantity === 0}
                >
                  <Box
                    mr={fullSize ? 0 : marginRight}
                    height={fullSize ? '100%' : undefined}
                  >
                    {element}
                  </Box>
                </ScrollSnap>
              );
            })}
          </Flex>
        </StyledCarouselContainer>
        {shouldRenderControls && indicator !== 'numbers' && (
          <Arrows
            shouldRenderLeftArrow={shouldRenderLeftArrow}
            shouldRenderRightArrow={shouldRenderRightArrow}
            fullSize={fullSize}
            handleClickScroll={handleClickScroll}
          />
        )}
      </Flex>
      {indicator && shouldRenderControls && (
        <ProgressContainer
          fullSize={fullSize}
          fixedProgressIndicator={fixedProgressIndicator}
        >
          {indicator === 'dots' && (
            <ProgressDots total={numberOfDots} selected={selectedDot} />
          )}
          {indicator === 'numbers' && numberOfDots > 1 && (
            <ProgressNumbers
              max={numberOfDots}
              selected={selectedDot}
              onClickScroll={handleClickScroll}
            />
          )}
        </ProgressContainer>
      )}
    </CarouselAndProgress>
  );
};
