import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { MyPiesEmptyState } from '~/pages/dashboard/research/my-pies/components/MyPiesEmptyState';
import { Spinner } from '~/toolbox/spinner';

import { BuildMyPieButtonGroup } from './BuildMyPieButtonGroup';
import { CryptoMyPiesSection } from './CryptoMyPiesSection';
import { EquityMyPiesSection } from './EquityMyPiesSection';
import { MyPiesPageProvider } from './MyPiesPageProvider';
import { useMyPiesContext } from './useMyPiesContext';

export const MyPiesResearchPage = () => {
  return (
    <MyPiesPageProvider>
      <MyPiesLayout />
    </MyPiesPageProvider>
  );
};

const MyPiesLayout = () => {
  const {
    minimal,
    shouldShowCryptoPies,
    shouldShowNonCryptoPies,
    queryData: { loading, data },
  } = useMyPiesContext();
  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.viewer.userPies?.edges || !data.viewer.userCryptoPies?.edges) {
    return <GenericSystemError />;
  }

  if (
    data.viewer.userPies.edges.length === 0 &&
    data.viewer.userCryptoPies.edges.length === 0
  ) {
    return <MyPiesEmptyState />;
  }

  return (
    <Box py={16} mx="auto" width="100%">
      {!minimal && <BuildMyPieButtonGroup />}
      {shouldShowNonCryptoPies && <EquityMyPiesSection />}
      {shouldShowCryptoPies && <CryptoMyPiesSection />}
    </Box>
  );
};
