import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { CreateSmartTransferFlow } from '~/flows';
import { useNavigate } from '~/hooks/useNavigate';

export const CreateSmartTransferPage = () => {
  const navigate = useNavigate();
  const handleFinish = React.useCallback(() => {
    navigate({ to: '/d/transfers/rules' });
  }, [navigate]);
  return (
    <Box height="100vh">
      <PageErrorBoundary onClickRetry={handleFinish} retryButtonLabel="Exit">
        {/** TODO: Need to enable this if ST Onboarding Helper is ready to go in time for production release */}
        {/** See #5045 for tracking the enablement/disablement of ST Onboarding Helper effort */}
        {/* <Box m="auto" mt={75} maxWidth={650}>
         <SmartTransferHelperNotifications />
         </Box> */}
        <CreateSmartTransferFlow
          basePath="d/c/create-smart-transfer"
          onFinish={handleFinish}
        />
      </PageErrorBoundary>
    </Box>
  );
};
