import { HM, PS } from '@m1/liquid-react';
import React from 'react';

import { PieDetailsNodeFragment, SliceableLogoFragment } from '~/graphql/types';

import { SliceableLogo } from '../SliceableLogo';

import {
  StyledModelPortfolioLogo,
  StyledHeaderContainer,
  StyledHeaderWrapper,
} from './PieDetails.styled';

type SystemPieHeaderContainerProps = {
  pie: PieDetailsNodeFragment;
};

export const SystemPieHeaderContainer = ({
  pie,
}: SystemPieHeaderContainerProps) => (
  <StyledHeaderContainer
    featureUrl={'featureUrl' in pie ? pie.featureUrl : undefined}
  >
    <StyledHeaderWrapper>
      <StyledModelPortfolioLogo>
        {pie.portfolioLinks !== undefined ? (
          <SliceableLogo sliceable={pie as SliceableLogoFragment} />
        ) : null}
      </StyledModelPortfolioLogo>
      <HM color="foregroundNeutralMain" content={pie.name} />
      {pie.__typename === 'SystemPie' && pie.disclosure && (
        <PS content={pie.disclosure} />
      )}
    </StyledHeaderWrapper>
  </StyledHeaderContainer>
);
