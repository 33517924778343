import {
  Flex,
  LayoutableProps,
  PS,
  Text,
  Tooltip,
  styled,
} from '@m1/liquid-react';
import * as React from 'react';

import { NiaDisclaimerFragment } from '~/graphql/types';
import { AppImage } from '~/lens-toolbox/AppImage';

const StyledAppImagePopupIcon = styled(AppImage)`
  margin-left: 8px;
`;

type TransfersDisclaimerProps = LayoutableProps & {
  disclaimer: NiaDisclaimerFragment;
};

export const TransfersDisclaimer = ({
  disclaimer,
  ...rest
}: TransfersDisclaimerProps) => {
  const { text, tooltip } = disclaimer;
  return (
    <Flex {...rest}>
      <Text>
        {text}
        {tooltip && (
          <Tooltip
            placement="bottom-start"
            triggerBoxProps={{ style: { verticalAlign: 'middle' } }}
            body={
              <>
                {tooltip.text.map((text, idx) => (
                  <PS key={idx} content={text} py={6} />
                ))}
              </>
            }
          >
            <StyledAppImagePopupIcon height={20} appImage={tooltip.icon} />
          </Tooltip>
        )}
      </Text>
    </Flex>
  );
};
