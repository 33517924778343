import React from 'react';

import { MultiStepFormProvider } from '~/components/form/MultiStepForm';
import { AcatWizardQueryResult } from '~/graphql/hooks';
import { useLocation } from '~/hooks/useLocation';
import { isNotNil } from '~/utils';

import { useAcatWizardForm } from '../hooks/useAcatWizardForm';

export const AcatWizardFormProvider = ({
  children,
  data,
}: React.PropsWithChildren<{
  data: AcatWizardQueryResult['data'];
}>) => {
  const location = useLocation();

  const acatWizard = data?.viewer.invest?.acatWizard;
  if (!acatWizard) {
    throw new Error('ACAT wizards requires `Invest.acatWizard` to resolve.');
  }

  const accountId: string | null | undefined = location?.state?.accountId;
  const destinationAccount =
    acatWizard.destinationAccountContent?.validParticipants?.edges
      ?.filter(isNotNil)
      .find((account) => accountId && accountId === account.node?.id);

  const formMethods = useAcatWizardForm({
    defaultValues: {
      acatWizardId: acatWizard.id,
      cashAmount: null,
      assets: [],
      originAccountNumber: '',
      destinationAccountId: destinationAccount?.node?.id ?? null,
      externalBrokerageId: null,
      selectedTransferType: null,
    },
  });

  return (
    <MultiStepFormProvider value={formMethods}>
      {children}
    </MultiStepFormProvider>
  );
};
