import { Box, HS, Image, PS } from '@m1/liquid-react';

import * as React from 'react';

import tradeProcessedIcon from '~/images/icons/trade-processed.svg';

import { Spinner } from '~/toolbox/spinner';

export const OnDemandAllocatedTradesHeader = ({
  hasIncompleteTrades,
  hasAllCompletedTrades,
}: {
  hasAllCompletedTrades: boolean;
  hasIncompleteTrades: boolean;
}) => {
  if (hasAllCompletedTrades) {
    return (
      <Box textAlign="center">
        <Image alt="" src={tradeProcessedIcon} mb={8} />
        <HS mb={16}>Your trades have processed!</HS>
      </Box>
    );
  } else if (hasIncompleteTrades) {
    return (
      <Box mb={16}>
        <HS>Some of your trades could not be completed.</HS>
        <PS>
          You can contact Client Support for assistance with incomplete orders.
        </PS>
      </Box>
    );
  }
  return (
    <HS mb={16}>
      <Spinner radius={24} thickness={3} centered={false} display="inline" />
      <Box ml={8} display="inline">
        Your trades are processing
      </Box>
    </HS>
  );
};
