import { PS, Card } from '@m1/liquid-react';
import React from 'react';

export const DepositInfoCard = () => (
  <Card
    mt={24}
    p={16}
    borderColor="transparent"
    backgroundColor="backgroundNeutralTertiary"
  >
    <PS
      color="foregroundNeutralMain"
      content="Your first deposit will happen today. Future deposits will follow your chosen schedule."
    />
  </Card>
);
