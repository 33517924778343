import {
  Box,
  Button,
  Flex,
  HL,
  PL,
  PXL,
  useThemeMode,
  Card,
} from '@m1/liquid-react';

import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { AnnouncementContext } from '~/components/announcement';
import { SetupChecklistCard } from '~/components/SetupChecklistCard';

import {
  AnnouncementContextEnum,
  PortfolioPageAccountNodeFragment,
  PortfolioPageViewerFragment,
} from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import type { AppState } from '~/redux';
import { useDispatch, useSelector } from '~/redux/hooks';

type NewPortfolioPageProps = {
  account: PortfolioPageAccountNodeFragment;
  viewer: PortfolioPageViewerFragment;
};

export const NewPortfolioPage = (props: NewPortfolioPageProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activeThemeMode } = useThemeMode();

  const announcementForInvest = props.viewer?.announcements?.forInvest;

  const isCryptoAccount = useSelector(
    (state: AppState) => state.global.activeAccountIsCrypto,
  );
  const getStartedCopy = isCryptoAccount
    ? 'Choose Cryptocurrencies and Crypto Pies to start building your portfolio.'
    : 'Choose Stocks, ETFs, and Pies to start building your portfolio.';

  const addSlicesDestination = isCryptoAccount
    ? '/d/c/add-slices/crypto'
    : '/d/c/add-slices';

  const isPhone = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const handleClick = () => {
    navigate({
      to: addSlicesDestination,
      options: {
        state: {
          context: 'NEW_ROOT_PIE',
        },
      },
    });

    dispatch({
      type: 'UPDATE_IS_CRYPTO_PORTFOLIO_ORGANIZER',
      payload: isCryptoAccount,
    });
  };

  return (
    <Box maxWidth={1200} ml="auto" mr="auto" px={8}>
      <Flex mt={32} mx={8} flexDirection={isPhone ? 'column-reverse' : 'row'}>
        <Box>
          <HL
            content={`Build your ${isCryptoAccount ? 'crypto ' : ''}portfolio`}
          />
          <PXL
            content="Continue completing the steps below to start investing."
            pt={8}
          />
        </Box>
        {announcementForInvest && (
          <Flex width={isPhone ? 'undefined' : 321} my={16} mx="auto">
            <AnnouncementContext
              announcement={announcementForInvest}
              context={AnnouncementContextEnum.Invest}
            />
          </Flex>
        )}
      </Flex>
      <Flex
        alignItems="flex-start"
        justifyContent="space-between"
        mt={isPhone ? 8 : 32}
        flexDirection={isPhone ? 'column-reverse' : 'row'}
      >
        <Box px={8} width={isPhone ? '100%' : undefined}>
          <Card
            backgroundColor="backgroundNeutralSecondary"
            isElevated
            px={16}
            py={12}
          >
            <video
              autoPlay
              loop
              muted
              style={{
                verticalAlign: 'center',
                width: '100%',
                height: 400,
              }}
            >
              <source
                src={
                  activeThemeMode === 'light'
                    ? '/animations/intro-to-pies-light.mp4'
                    : '/animations/intro-to-pies-dark.mp4'
                }
                type="video/mp4"
              />
            </video>
          </Card>
          <PL mt={32} content={getStartedCopy} />
          <Button
            label={`Choose ${isCryptoAccount ? 'crypto' : 'securities'}`}
            my={16}
            fullWidth={isPhone}
            onClick={handleClick}
            size="large"
          />
        </Box>
        {props.account.setupChecklist && (
          <Box px={8} mb={16} width={isPhone ? '100%' : undefined}>
            <SetupChecklistCard
              toParticipantId={props.account.id}
              routelessDestinations={{
                'Build portfolio': handleClick,
              }}
              setupChecklist={props.account.setupChecklist}
            />
          </Box>
        )}
      </Flex>
    </Box>
  );
};
