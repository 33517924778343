import React from 'react';

import { useGetEstimatedBuysQuery } from '~/graphql/hooks';

import { AccountEstimatedTradesTable } from './AccountEstimatedTradesTable';
import { LoadMoreButton } from './LoadMoreButton';
import { NoTradesContent } from './NoTradesContent';

type EstimatedBuysTableProps = {
  accountId: string;
  style?: any;
};

export const EstimatedBuysTable = ({
  accountId,
  style,
}: EstimatedBuysTableProps) => {
  const { data, loading, variables, refetch } = useGetEstimatedBuysQuery({
    fetchPolicy: 'network-only',
    variables: {
      accountId,
      first: 100,
    },
  });

  const account = data?.account?.__typename === 'Account' ? data.account : null;

  const buys = account?.estimatedTrading?.buys;

  const pageInfo = buys?.pageInfo;

  const handleFetchMore = React.useCallback(() => {
    const oldFirst = variables?.first ?? 0;
    refetch({
      first: oldFirst + 100,
    });
  }, [refetch, variables]);

  if (!buys?.edges && !loading) {
    return (
      <NoTradesContent
        picture="cryptoNoPendingBuys"
        content="No pending buys"
      />
    );
  }

  return (
    <AccountEstimatedTradesTable
      totalTrades={buys?.total ?? 0}
      edges={buys?.edges}
      style={style}
      loadMoreTradesButton={
        <LoadMoreButton
          handleLoadMore={handleFetchMore}
          loading={loading}
          pageInfo={pageInfo}
        />
      }
    />
  );
};
