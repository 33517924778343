import { Flex, styled } from '@m1/liquid-react';
import * as React from 'react';

type ArrowDirection = 'LEFT' | 'RIGHT';

export type WidthConstrainerWithArrowProps = React.PropsWithChildren<{
  direction?: ArrowDirection;
}>;

const StyledRoot = styled(Flex)`
  > div:first-of-type {
    flex: 1;
  }
`;

const StyledArrow = styled.svg<{
  $direction: ArrowDirection;
}>`
  transform: ${(props) =>
    props.$direction === 'LEFT' ? 'rotate(180deg)' : null};
  margin-left: 8px;

  > g path {
    &:first-of-type {
      stroke: ${(props) => props.theme.colors.foregroundNeutralSecondary};
    }

    &:last-of-type {
      fill: ${(props) => props.theme.colors.foregroundNeutralSecondary};
    }
  }
`;

export const WidthConstrainerWithArrow = ({
  direction = 'RIGHT',
  children,
}: WidthConstrainerWithArrowProps) => (
  <StyledRoot alignItems="center">
    {children}
    <StyledArrow
      $direction={direction}
      width={27}
      viewBox="0 0 28 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 6.5 L28 6.5" strokeWidth={2} />
        <path d="M21 0 L28 6.5 L21 13 Z" strokeLinejoin="round" />
      </g>
    </StyledArrow>
  </StyledRoot>
);
