import * as React from 'react';
import { Route } from 'react-router-dom';

import { SecurityDetailsLayout } from '~/pages/dashboard/research/SecurityDetailsLayout';

export const AddCryptoDetailsRoute = () => {
  return (
    <Route
      path="details/:cryptoId"
      element={<SecurityDetailsLayout isCover type="CRYPTO" disableWatchlist />}
    />
  );
};
