import { Button, Flex } from '@m1/liquid-react';
import React from 'react';

import { AddToPortfolioButton } from '~/components/security/AddToPortfolioButton';
import { useCryptoEligibilityQuery } from '~/graphql/hooks';
import { SecurityDetail_CryptoAsset_Fragment } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';

type CryptoDetailsButtonGroupProps = {
  crypto: SecurityDetail_CryptoAsset_Fragment;
};

export const CryptoDetailsButtonGroup = ({
  crypto,
}: CryptoDetailsButtonGroupProps) => {
  const { data } = useCryptoEligibilityQuery();
  const analytics = useAnalytics();
  const navigate = useNavigate();

  const restrictions = crypto.restrictions || [];

  const { isNewYorkRestricted } = restrictions.reduce(
    (acc, val) => {
      if (val === 'NY_REGION') {
        acc.isNewYorkRestricted = true;
      }

      return acc;
    },
    {
      isNewYorkRestricted: false,
    },
  );
  if (isNewYorkRestricted) {
    return null;
  }

  if (!data?.viewer.crypto || !data.viewer.crypto.isEligible) {
    return null;
  }

  const { hasCryptoAccount } = data.viewer.crypto;

  if (hasCryptoAccount) {
    return (
      <AddToPortfolioButton
        securityId={crypto.id}
        type={crypto.type}
        sliceable={crypto}
      />
    );
  }

  return (
    <Flex mt={76} flexDirection="column" ml={130} width={280}>
      <Button
        label="Open a Crypto Account"
        onClick={(): void => {
          analytics.recordEvent('m1_crypto_account_open_cta_click', null, {
            cta_id: 'crypto_details',
          });

          navigate({
            to: '/d/c/open-account',
            query: { defaultAccountType: 'CRYPTO' },
          });
        }}
      />
    </Flex>
  );
};
