import { PL, Flex, styled, css } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

export type ProgressNumbersProps = {
  /**
   * The maximum number to be shown in the progress numbers
   */
  max: number;
  /**
   * The index of the selected carousel item
   */
  selected: number;
  onClickScroll: (direction: 'LEFT' | 'RIGHT') => void;
};

const Arrow = styled(Icon)<{ $selected: boolean }>`
  ${({ $selected, theme }) =>
    $selected
      ? css`
          color: ${theme.colors.foregroundNeutralTertiary};
          cursor: default;
        `
      : css`
          color: ${theme.colors.foregroundNeutralMain};
          cursor: pointer;
        `}

  &:first-of-type {
    margin-right: 48px;
  }
  &:last-of-type {
    margin-left: 48px;
  }

  @container carouselContainer (max-width: 480px) {
    &:first-of-type {
      margin-right: 24px;
    }
    &:last-of-type {
      margin-left: 24px;
    }
  }

  @container carouselContainer (max-width: 240px) {
    &:first-of-type {
      margin-right: 12px;
    }
    &:last-of-type {
      margin-left: 12px;
    }
  }
`;
const ProgressNumbersContainer = styled(PL)`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

export const ProgressNumbers = ({
  selected,
  max,
  onClickScroll,
}: ProgressNumbersProps) => {
  return (
    <ProgressNumbersContainer>
      <Arrow
        name="caretLeft24"
        $selected={selected === 0}
        onClick={() => selected !== 0 && onClickScroll('LEFT')}
      />
      <Flex width={50} justifyContent="center">
        {selected + 1} of {max}
      </Flex>
      <Arrow
        name="caretRight24"
        $selected={selected === max - 1}
        onClick={() => selected !== max - 1 && onClickScroll('RIGHT')}
      />
    </ProgressNumbersContainer>
  );
};
