import * as React from 'react';
import { Route } from 'react-router-dom';

import { SecurityDetailsLayout } from '~/pages/dashboard/research/SecurityDetailsLayout';

export const AddFundDetailsRoute = () => {
  return (
    <Route
      path="details/:fundId"
      element={<SecurityDetailsLayout disableWatchlist isCover type="FUND" />}
    />
  );
};
