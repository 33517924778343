import { Box, Color, Flex, LL, PM, Card } from '@m1/liquid-react';
import * as React from 'react';

import {
  FundedAccountFragment,
  FundingAccountParticipantFragment,
  RecurrenceScheduleInput,
} from '~/graphql/types';
import { Divider } from '~/toolbox/divider';

import { RecurringScheduleCopyText } from './RecurringScheduleCopyText';

type FundAccountConfirmationCardRowProps = {
  color?: Color;
  copy?: string;
  label: string;
  children: React.ReactNode;
};

const FundAccountConfirmationCardRow = ({
  color,
  copy,
  label,
  children,
}: FundAccountConfirmationCardRowProps) => (
  <Box py={4}>
    <Flex justifyContent="space-between">
      <LL content={label} />
      <PM color={color} fontWeight={600}>
        {children}
      </PM>
    </Flex>

    {copy !== undefined && <PM mt={8} content={copy} />}
  </Box>
);

type FundAccountConfirmationCardProps = {
  account: FundedAccountFragment;
  amount: number;
  fromParticipant: FundingAccountParticipantFragment | null;
  isScheduleSwitchOn: boolean;
  schedule: RecurrenceScheduleInput | null;
};

export const FundAccountConfirmationCard = ({
  account,
  amount,
  fromParticipant,
  isScheduleSwitchOn,
  schedule,
}: FundAccountConfirmationCardProps) => {
  const canAutoInvest = (account.maxCashThreshold ?? 0) >= 0;

  return (
    <Card mb={32} p={16}>
      <FundAccountConfirmationCardRow label="Amount">
        ${amount}
      </FundAccountConfirmationCardRow>
      <Divider spacing="compact" />

      {fromParticipant !== null && (
        <FundAccountConfirmationCardRow label="From">
          {fromParticipant.transferParticipantName}
        </FundAccountConfirmationCardRow>
      )}
      <FundAccountConfirmationCardRow label="To">
        {account.name}
      </FundAccountConfirmationCardRow>
      <Divider spacing="compact" />

      {isScheduleSwitchOn && (
        <>
          <FundAccountConfirmationCardRow label="Schedule">
            <RecurringScheduleCopyText schedule={schedule} />
          </FundAccountConfirmationCardRow>
          <Divider spacing="compact" />
        </>
      )}

      <FundAccountConfirmationCardRow
        label="Auto-invest"
        color={canAutoInvest ? 'primaryShade' : 'foregroundNeutralMain'}
        copy="Deposits will be automatically invested. After your first deposit, you can change this setting on the portfolio screen."
      >
        {canAutoInvest ? 'On' : 'Off'}
      </FundAccountConfirmationCardRow>
    </Card>
  );
};
