import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import { AppButtons } from '~/components/AppButtons';

import { AppToastFragment } from '~/graphql/types';
import { RichText } from '~/lens-toolbox/RichText';
import { useDispatch, useSelector } from '~/redux/hooks';

import { ToastKind, ToastProps } from '~/toolbox/toast';

const toastIsFragment = (
  toast: ToastProps | AppToastFragment,
): toast is AppToastFragment => {
  return '__typename' in toast;
};

const mapLensKindToToastKind = (kind: AppToastFragment['kind']): ToastKind => {
  switch (kind) {
    case 'ALERT':
      return 'alert';
    case 'WARNING':
      return 'warning';
    case 'SUCCESS':
      return 'success';
    case 'INFORMATIONAL':
    default:
      return 'informational';
  }
};

export const useToast = () => {
  const dispatch = useDispatch();

  const currentToast = useSelector((state) => state.toasts.toast);

  const clearToast = () =>
    dispatch({
      type: 'CLEAR_TOAST',
    });

  const addToast = (toastProps: Maybe<ToastProps | AppToastFragment>) => {
    if (!toastProps) {
      return;
    }

    let toast: ToastProps;
    if (toastIsFragment(toastProps)) {
      toast = {
        content: (
          <Flex flexDirection="column" gap={16}>
            {toastProps.paragraphs.map((p, i) => (
              <RichText richText={p} key={i} />
            ))}
          </Flex>
        ),
        kind: mapLensKindToToastKind(toastProps.kind),
        link: toastProps.link ? (
          <AppButtons button={toastProps.link} useLink />
        ) : undefined,
      };
    } else {
      toast = toastProps;
    }

    if (currentToast) {
      // Clear current toast state to reset timers
      // and animations for the new toast.
      clearToast();
      window.setTimeout(() => {
        dispatch({
          type: 'ADD_TOAST',
          payload: toast,
        });
      });
    } else {
      dispatch({
        type: 'ADD_TOAST',
        payload: toast,
      });
    }
  };

  return {
    addToast,
    clearToast,
    currentToast,
  };
};
