import { Button, PM, LS } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import { useCreateAccountDocumentLinkMutation } from '~/graphql/hooks';
import { DocumentNode_AccountDocument_Fragment } from '~/graphql/types';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useToast } from '~/toasts';
import { GridTable } from '~/toolbox/grid-table';
import { isNotNil } from '~/utils';

export type AccountDocumentRowProps = {
  document: DocumentNode_AccountDocument_Fragment;
};

export const AccountDocumentRow = ({ document }: AccountDocumentRowProps) => {
  const [createDocumentLink, { loading }] =
    useCreateAccountDocumentLinkMutation();

  const { addToast } = useToast();

  usePortaledSpinner(loading);

  const handleLinkClick = (): void => {
    createDocumentLink({
      variables: {
        input: {
          accountDocumentId: document.id,
        },
      },
      onCompleted: (data) => {
        window.open(data.createAccountDocumentLink.outcome?.url, '_blank');
      },
      onError: (err) => {
        addToast({
          kind: 'alert',
          content: err.message,
          duration: 'short',
        });
      },
    });
  };

  return (
    <GridTable.Row key={document.id}>
      <GridTable.Cell>{moment(document.date).format('ll')}</GridTable.Cell>
      <GridTable.Cell>
        {isNotNil(document.accountName) ? (
          <>
            <PM
              fontWeight={600}
              overflow="hidden"
              textOverflow="ellipsis"
              content={document.accountName.label}
            />
            {isNotNil(document.accountName.subLabel) ? (
              <LS
                as="small"
                color="foregroundNeutralMain"
                overflow="hidden"
                textOverflow="ellipsis"
                content={document.accountName.subLabel}
              />
            ) : null}
          </>
        ) : (
          '--'
        )}
      </GridTable.Cell>
      <GridTable.Cell>
        <Button
          kind="link"
          font="PM"
          fontWeight={600}
          onClick={handleLinkClick}
        >
          {document.name}
        </Button>
      </GridTable.Cell>
    </GridTable.Row>
  );
};
