import { styled, css } from '@m1/liquid-react';
import * as React from 'react';

import { CONTROL_IMG_DIRECTIONS } from '~/static-constants';

import { ControlImg } from './ControlImg';

export type ArrowsProps = {
  fullSize: boolean;
  handleClickScroll: (direction: 'LEFT' | 'RIGHT') => void;
  shouldRenderLeftArrow: boolean;
  shouldRenderRightArrow: boolean;
};

type ArrowDirectionEnum = 'prev' | 'next';

export const CarouselArrow = styled.div<{
  arrowDirection: ArrowDirectionEnum;
  fullSize: boolean;
  shouldDisplay: boolean;
}>`
  position: absolute;
  ${({ arrowDirection, fullSize }) => {
    const offset = fullSize ? '24px' : '-16px';
    return css`
      ${arrowDirection === 'prev' ? 'left' : 'right'}: ${offset};
    `;
  }}
  opacity: ${({ shouldDisplay }) => (!shouldDisplay ? 0 : 1)};
  margin-bottom: 16px;
`;

export const Arrows = ({
  shouldRenderLeftArrow,
  shouldRenderRightArrow,
  handleClickScroll,
  fullSize,
}: ArrowsProps) => {
  return (
    <>
      <CarouselArrow
        onClick={() => handleClickScroll('LEFT')}
        arrowDirection="prev"
        shouldDisplay={shouldRenderLeftArrow}
        fullSize={fullSize}
      >
        <ControlImg />
      </CarouselArrow>
      <CarouselArrow
        onClick={() => handleClickScroll('RIGHT')}
        arrowDirection="next"
        shouldDisplay={shouldRenderRightArrow}
        fullSize={fullSize}
      >
        {/* @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'ControlImgDirection | undefined'. */}
        <ControlImg direction={CONTROL_IMG_DIRECTIONS.REVERSED} />
      </CarouselArrow>
    </>
  );
};
