import { Box } from '@m1/liquid-react';
import take from 'lodash-es/take';
import * as React from 'react';

import { SliceableNameCell } from '~/components/pie/SliceableNameCell';
import { useDiscoverSearchLazyQuery } from '~/graphql/hooks';
import {
  SliceableFragment,
  SliceableStatusEnum,
  SliceableTypeEnum,
} from '~/graphql/types';

import { useDebounce } from '~/hooks/useDebounce';
import { clickedOnSliceable, finishedSearchSliceables } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { SearchInput } from '~/toolbox/search-input';

import {
  StyledDiscoverySearchRoot,
  StyledSearchResultsContainer,
  StyledSearchResult,
  StyledSearchResults,
} from './StyledDiscoverSearch';

type DiscoverSearchProps = {
  onResultClick?: (slice: SliceableFragment) => void;
  placeholder?: string;
  borderless?: boolean;
  filterStatuses: SliceableStatusEnum[];
  filterTypes: SliceableTypeEnum[];
  staticResultCell?: React.ReactNode;
};

export const DiscoverSearch = ({
  onResultClick,
  placeholder = 'Search by symbol or company name',
  borderless,
  filterStatuses,
  filterTypes,
  staticResultCell = null,
}: DiscoverSearchProps) => {
  const [currentSearch, setCurrentSearch] = React.useState('');

  const [fireSearch, { data, loading }] = useDiscoverSearchLazyQuery();

  const dispatch = useDispatch();

  const fireDebounce = useDebounce(
    (args) =>
      fireSearch({
        variables: {
          query: args[0],
          first: 5,
          filterTypes,
          filterStatuses,
        },
      }),
    60,
  );

  const search = data?.viewer.search;
  const MAX_EDGES = 5;
  const shownSlices = take(search?.edges, MAX_EDGES);

  const handleChange = (e: React.SyntheticEvent<any>) => {
    setCurrentSearch(e.currentTarget.value);
    fireDebounce(e.currentTarget.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // If the user enters a valid symbol, but it _isn't_ shown in the search
    // results list, ensure they're not able to add it to their pie:
    if (shownSlices.length === 0) {
      return;
    }

    if (event.key === 'Enter') {
      onResultClick && shownSlices[0]?.node?.id
        ? onResultClick(shownSlices[0]?.node)
        : dispatch(
            finishedSearchSliceables({ query: event.currentTarget.value }),
          );
      setCurrentSearch('');
    }
  };

  const handleResultClick = (slice: SliceableFragment) => {
    setCurrentSearch('');
    onResultClick
      ? onResultClick(slice)
      : dispatch(clickedOnSliceable(slice.id));
  };

  return (
    <StyledDiscoverySearchRoot>
      <SearchInput
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={currentSearch}
        borderless={borderless}
        size={34}
        width="auto"
      />
      <StyledSearchResultsContainer>
        {currentSearch && (
          <StyledSearchResults>
            {shownSlices.map((slice) => {
              const sliceId = slice?.node?.id;

              if (sliceId) {
                return (
                  <StyledSearchResult
                    key={sliceId}
                    onClick={() =>
                      handleResultClick(slice.node as SliceableFragment)
                    }
                  >
                    <SliceableNameCell sliceable={slice.node} />
                  </StyledSearchResult>
                );
              }
            })}
            {!loading && staticResultCell ? (
              <Box p={16}>{staticResultCell}</Box>
            ) : null}
          </StyledSearchResults>
        )}
      </StyledSearchResultsContainer>
    </StyledDiscoverySearchRoot>
  );
};
