import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const AppImageFragmentDoc = gql`
    fragment AppImage on AppImage {
  type
  names
  color
  lightTheme {
    scale1xUrl
    scale2xUrl
    scale3xUrl
  }
  darkTheme {
    scale1xUrl
    scale2xUrl
    scale3xUrl
  }
}
    `;
export const AnalyticsEventFragmentDoc = gql`
    fragment AnalyticsEvent on AppAnalyticsEvent {
  name
  valueParameter
  customParameters {
    name
    value
  }
  customBoolParameters {
    name
    value
  }
  customNumberParameters {
    name
    value
  }
}
    `;
export const OnboardingProductFragmentDoc = gql`
    fragment OnboardingProduct on OnboardingProduct {
  identifier
  icon {
    ...AppImage
  }
  illustrationName
  illustration {
    ...AppImage
  }
  header
  subheader
  ctaLabel
  destination
  analyticsEvent {
    ...AnalyticsEvent
  }
  analyticsEventClick {
    ...AnalyticsEvent
  }
  disclosure {
    identifier
    label
    content
  }
}
    ${AppImageFragmentDoc}
${AnalyticsEventFragmentDoc}`;
export const BorrowAccountFragmentDoc = gql`
    fragment BorrowAccount on Account {
  borrowAccount {
    hasCreditBorrowed
    creditBorrowed
    status {
      excessMarginEquity
      requiredMarginEquity
      marginEquity
    }
  }
}
    `;
export const AppPillFragmentDoc = gql`
    fragment AppPill on AppPill {
  kind
  label
  icon
}
    `;
export const CommonTransactionFragmentDoc = gql`
    fragment CommonTransaction on CommonTransactionEntry {
  id
  groupBy
  icon {
    ...AppImage
  }
  title
  subtitle
  status
  amount {
    color
    text
  }
  logoUrl
  pill {
    ...AppPill
  }
  metadata {
    id
    ... on PersonalLoanCommonTransactionMetadata {
      toInterest
      toPrincipal
    }
    ... on CreditCardCommonTransactionMetadata {
      rewardTierPill {
        label
        kind
        icon
      }
    }
  }
}
    ${AppImageFragmentDoc}
${AppPillFragmentDoc}`;
export const CommonTransactionsFragmentDoc = gql`
    fragment CommonTransactions on CommonTransactionEntryConnection {
  pageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
  tableHeaders {
    title
    align
  }
  edges {
    cursor
    node {
      ...CommonTransaction
    }
  }
}
    ${CommonTransactionFragmentDoc}`;
export const AppLinkFragmentDoc = gql`
    fragment AppLink on AppLink {
  articleId
  internalPath
  title
  url
  analyticsEvent {
    ...AnalyticsEvent
  }
  kind
  size
  underline
  font
  fontWeight
}
    ${AnalyticsEventFragmentDoc}`;
export const TransferSuggestionFragmentDoc = gql`
    fragment TransferSuggestion on TransferSuggestionLink {
  title
  suggestion {
    transferType
    fromParticipant {
      id
    }
    toParticipant {
      id
    }
    amount
  }
}
    `;
export const LinkableFragmentDoc = gql`
    fragment Linkable on Linkable {
  __typename
  title
  ...AppLink
  analyticsEvent {
    ...AnalyticsEvent
  }
  ...TransferSuggestion
}
    ${AppLinkFragmentDoc}
${AnalyticsEventFragmentDoc}
${TransferSuggestionFragmentDoc}`;
export const TextFragmentDoc = gql`
    fragment Text on Text {
  text
  color
  font
  fontWeight
}
    `;
export const AppButtonFragmentDoc = gql`
    fragment AppButton on AppButton {
  label
  action
  disabled
  analyticsEvent {
    ...AnalyticsEvent
  }
  kind
  size
}
    ${AnalyticsEventFragmentDoc}`;
export const AppTooltipFragmentDoc = gql`
    fragment AppTooltip on AppTooltip {
  header
  body
  link {
    ...AppLink
  }
  icon {
    ...AppImage
  }
}
    ${AppLinkFragmentDoc}
${AppImageFragmentDoc}`;
export const AppShareButtonFragmentDoc = gql`
    fragment AppShareButton on AppShareButton {
  label
  disabled
  analyticsEvent {
    ...AnalyticsEvent
  }
  kind
  size
  shareContent
  shareType
}
    ${AnalyticsEventFragmentDoc}`;
export const NonRecursiveRichTextContentFragmentDoc = gql`
    fragment NonRecursiveRichTextContent on RichTextUnion {
  __typename
  ...Linkable
  ...Text
  ...AppButton
  ...AppLink
  ...AppTooltip
  ...AppShareButton
}
    ${LinkableFragmentDoc}
${TextFragmentDoc}
${AppButtonFragmentDoc}
${AppLinkFragmentDoc}
${AppTooltipFragmentDoc}
${AppShareButtonFragmentDoc}`;
export const ApolloRichTextDisclosureLinkFragmentDoc = gql`
    fragment ApolloRichTextDisclosureLink on AppRichTextDisclosureLink {
  linkText
  paragraphs {
    content {
      ...NonRecursiveRichTextContent
    }
  }
  underline
  font
  fontWeight
}
    ${NonRecursiveRichTextContentFragmentDoc}`;
export const RecursiveRichTextContentFragmentDoc = gql`
    fragment RecursiveRichTextContent on RichTextUnion {
  __typename
  ...ApolloRichTextDisclosureLink
}
    ${ApolloRichTextDisclosureLinkFragmentDoc}`;
export const RichTextFragmentDoc = gql`
    fragment RichText on RichTextContent {
  content {
    ...NonRecursiveRichTextContent
    ...RecursiveRichTextContent
  }
}
    ${NonRecursiveRichTextContentFragmentDoc}
${RecursiveRichTextContentFragmentDoc}`;
export const CommonTransactionsEmptyStateFragmentDoc = gql`
    fragment CommonTransactionsEmptyState on CommonTransactionsEmptyState {
  title {
    ...RichText
  }
  subtitle {
    ...RichText
  }
}
    ${RichTextFragmentDoc}`;
export const AppDisclosureLinkFragmentDoc = gql`
    fragment AppDisclosureLink on AppDisclosureLink {
  linkText
  paragraphs
  underline
  font
  fontWeight
}
    `;
export const AppRichTextDisclosureLinkFragmentDoc = gql`
    fragment AppRichTextDisclosureLink on AppRichTextDisclosureLink {
  linkText
  paragraphs {
    ...RichText
  }
}
    ${RichTextFragmentDoc}`;
export const AppInfoPageFragmentDoc = gql`
    fragment AppInfoPage on AppInfoPage {
  title
  subtitle
  paragraphs {
    ...RichText
  }
  richTextDisclosures {
    ...AppRichTextDisclosureLink
  }
  illustration {
    ...AppImage
  }
  primaryCtaLink {
    ...AppLink
  }
  primaryCtaButton {
    ...AppButton
  }
  secondaryCtaLink {
    ...AppLink
  }
  secondaryCtaButton {
    ...AppButton
  }
  infoList {
    icon {
      ...AppImage
    }
    title {
      ...RichText
    }
    subtitle {
      ...RichText
    }
  }
  pageViewAnalytics {
    ...AnalyticsEvent
  }
  skipLabel
}
    ${RichTextFragmentDoc}
${AppRichTextDisclosureLinkFragmentDoc}
${AppImageFragmentDoc}
${AppLinkFragmentDoc}
${AppButtonFragmentDoc}
${AnalyticsEventFragmentDoc}`;
export const AppInformationalMessageCardFragmentDoc = gql`
    fragment AppInformationalMessageCard on AppInformationalMessageCard {
  icon {
    ...AppImage
  }
  items {
    label
    tooltip {
      icon {
        ...AppImage
      }
      text
    }
    value
  }
  link {
    ...Linkable
  }
  message
  status {
    ...AppPill
  }
  title
}
    ${AppImageFragmentDoc}
${LinkableFragmentDoc}
${AppPillFragmentDoc}`;
export const AppButtonUnionFragmentDoc = gql`
    fragment AppButtonUnion on AppButtonUnion {
  __typename
  ...AppButton
  ...AppShareButton
  ...AppLink
}
    ${AppButtonFragmentDoc}
${AppShareButtonFragmentDoc}
${AppLinkFragmentDoc}`;
export const AppToastFragmentDoc = gql`
    fragment AppToast on AppToast {
  paragraphs {
    ...RichText
  }
  link {
    ...AppButtonUnion
  }
  kind
}
    ${RichTextFragmentDoc}
${AppButtonUnionFragmentDoc}`;
export const FullyPaidLendingDisclosureFragmentDoc = gql`
    fragment FullyPaidLendingDisclosure on InvestFullyPaidLendingDisclosure {
  introduction
  reviewTitle
  reviewBody
  disclosureBody
  disclosureTitle
  optInText
  optInDefault
}
    `;
export const MarketingCardFragmentDoc = gql`
    fragment MarketingCard on MarketingCard {
  title
  titleTooltip
  cardImage {
    ...AppImage
  }
  cardIcon {
    ...AppImage
  }
  marketingPerks {
    icon {
      ...AppImage
    }
    title
    subtitle
    tooltip
  }
  marketingLinks {
    ...Linkable
  }
  ctaButtonDestination {
    ...Linkable
  }
}
    ${AppImageFragmentDoc}
${LinkableFragmentDoc}`;
export const RequiredDocumentFragmentDoc = gql`
    fragment RequiredDocument on RequiredDocument {
  title
  url
}
    `;
export const RequiredDocumentsBundleFragmentDoc = gql`
    fragment RequiredDocumentsBundle on RequiredDocumentsBundle {
  id
  key
  documents {
    ...RequiredDocument
  }
  preamble
  postamble
  richPostamble {
    ...RichText
  }
  signature
}
    ${RequiredDocumentFragmentDoc}
${RichTextFragmentDoc}`;
export const ContactUsSectionCardFragmentDoc = gql`
    fragment ContactUsSectionCard on ContactUsCard {
  buttonDestinationUrl
  buttonLabel
  content
  identifier
  title
}
    `;
export const ContactUsCardsFragmentDoc = gql`
    fragment ContactUsCards on ContactUs {
  cards {
    ...ContactUsSectionCard
  }
}
    ${ContactUsSectionCardFragmentDoc}`;
export const VideoTutorialsVideoFragmentDoc = gql`
    fragment VideoTutorialsVideo on Video {
  title
  url
}
    `;
export const VideoTutorialsViewAllFragmentDoc = gql`
    fragment VideoTutorialsViewAll on VideoTutorialsSectionViewAll {
  destination
  label
}
    `;
export const VideoTutorialsSectionFragmentDoc = gql`
    fragment VideoTutorialsSection on ContactUs {
  videoTutorialsSection {
    title
    subtitle
    videos {
      ...VideoTutorialsVideo
    }
    viewAll {
      ...VideoTutorialsViewAll
    }
  }
}
    ${VideoTutorialsVideoFragmentDoc}
${VideoTutorialsViewAllFragmentDoc}`;
export const CreditStatusBannerFragmentDoc = gql`
    fragment CreditStatusBanner on Credit {
  suspendedAccountContent {
    content
    phoneNumber
  }
}
    `;
export const IntradayQuoteTimeSeriesFragmentDoc = gql`
    fragment IntradayQuoteTimeSeries on IntradayQuoteTimeSeries {
  lastUpdatedTime
  series {
    time
    data {
      percentChangeFromPreviousClosePrice
    }
  }
  timeSpan {
    firstOpenTime
    lastCloseTime
  }
}
    `;
export const MarketIndexProxyFragmentDoc = gql`
    fragment MarketIndexProxy on IndexProxy {
  __typename
  name
  proxy {
    id
    symbol
    latestQuote {
      percentChangeFromPreviousClosePrice
    }
    intradayQuotes(period: LATEST_DAY, interval: TEN_MINUTE) {
      ...IntradayQuoteTimeSeries
    }
  }
}
    ${IntradayQuoteTimeSeriesFragmentDoc}`;
export const InvestSetupChecklistFragmentDoc = gql`
    fragment InvestSetupChecklist on SetupChecklist {
  steps {
    destination {
      path
      url
    }
    analyticsEvent {
      name
      valueParameter
      customParameters {
        name
        value
      }
    }
    isCompleted
    isReady
    title
  }
  stepsCompleted
  stepsTotal
  title
}
    `;
export const PendingCashFlowFragmentDoc = gql`
    fragment PendingCashFlow on PortfolioSlice {
  id
  orderStatus {
    id
    key
    isLocked
    lockReason
    isSetOnSelf
    isSetInDescendants
    isSetOnSelfOrInDescendants
    netDescendantBuyOrderAmount
    netDescendantSellOrderAmount
    netDescendantOrderAmount
    order {
      cashFlow
    }
  }
  rebalanceStatus {
    id
    key
    isLocked
    lockReason
    isSetOnSelf
    isSetInDescendants
    isSetOnSelfOrInDescendants
  }
}
    `;
export const PortfolioCashFlowFragmentDoc = gql`
    fragment PortfolioCashFlow on PortfolioSlice {
  children {
    ...PendingCashFlow
    children {
      ...PendingCashFlow
      children {
        ...PendingCashFlow
        children {
          ...PendingCashFlow
          children {
            ...PendingCashFlow
          }
        }
      }
    }
  }
  ...PendingCashFlow
}
    ${PendingCashFlowFragmentDoc}`;
export const LandingPageHeaderFragmentDoc = gql`
    fragment LandingPageHeader on ProductAccountLandingPageHeader {
  icon {
    ...AppImage
  }
  title
  value
  tooltip {
    ...AppTooltip
  }
}
    ${AppImageFragmentDoc}
${AppTooltipFragmentDoc}`;
export const LandingPageheaderFragmentDoc = gql`
    fragment LandingPageheader on ProductAccountLandingPageHeader {
  icon {
    ...AppImage
  }
  title
  value
  tooltip {
    ...AppTooltip
  }
}
    ${AppImageFragmentDoc}
${AppTooltipFragmentDoc}`;
export const LandingPageValuePropCardFragmentDoc = gql`
    fragment LandingPageValuePropCard on ValuePropCard {
  title
  illustration {
    ...AppImage
  }
  primaryValue {
    value
    valueColor
    description
    descriptionColor
    tooltip {
      ...AppTooltip
    }
  }
  details {
    label
    labelColor
    value
    valueColor
    tooltip {
      ...AppTooltip
    }
  }
}
    ${AppImageFragmentDoc}
${AppTooltipFragmentDoc}`;
export const SmartTransferFulfillmentConditionFragmentDoc = gql`
    fragment SmartTransferFulfillmentCondition on SmartTransferContraParticipantEntry {
  fulfillmentCondition {
    fulfillmentConditionType
    ... on SmartTransferCashBalanceCapFulfillmentCondition {
      capAmount
    }
    ... on SmartTransferFundingPerPeriodFulfillmentCondition {
      fundingAmount
      fundingPeriod
    }
    ... on SmartTransferIraFundingPerPeriodFulfillmentCondition {
      fundingAmount
    }
    ... on SmartTransferCreditBorrowedCapFulfillmentCondition {
      borrowAmount
    }
    ... on SmartTransferCreditUtilizationCapFulfillmentCondition {
      utilization
    }
  }
}
    `;
export const SmartTransferFulfillmentConditionOptionSetFragmentDoc = gql`
    fragment SmartTransferFulfillmentConditionOptionSet on SmartTransferFulfillmentConditionOptionSet {
  options {
    title
    description
    fulfillmentConditionRequirements {
      fulfillmentConditionType
      ... on SmartTransferIraFundingPerPeriodFulfillmentConditionEditRequirements {
        minFundingAmount
        maxFundingAmount
      }
      ... on SmartTransferFundingPerPeriodFulfillmentConditionEditRequirements {
        fundingPeriods {
          label
          value
        }
        minFundingAmount
        maxFundingAmount
      }
      ... on SmartTransferCashBalanceCapFulfillmentConditionEditRequirements {
        minCapAmount
        maxCapAmount
      }
    }
  }
}
    `;
export const TransferCreationDetailsFragmentDoc = gql`
    fragment TransferCreationDetails on Details {
  description
  detailsLink {
    internalPath
  }
}
    `;
export const UserKeysFragmentDoc = gql`
    fragment UserKeys on User {
  data {
    key
    value
  }
}
    `;
export const MoveMoneyListItemFragmentDoc = gql`
    fragment MoveMoneyListItem on ListItem {
  header
  size
  description
  subheader
  subheaderItems
  icon {
    ...AppImage
  }
  pill {
    kind
    label
  }
  link {
    title
    url
    internalPath
    analyticsEvent {
      name
      customParameters {
        name
        value
      }
    }
  }
}
    ${AppImageFragmentDoc}`;
export const AcatWizardSelectionAssetsSectionContentFragmentDoc = gql`
    fragment AcatWizardSelectionAssetsSectionContent on InvestAcatWizardAssetSelectionAssetsSectionContent {
  header {
    ...RichText
  }
  description {
    ...RichText
  }
  searchInput {
    placeholder
  }
  sharesInput {
    label
  }
  cta {
    label
    icon {
      names
    }
  }
}
    ${RichTextFragmentDoc}`;
export const PendingTransferInstancesFragmentDoc = gql`
    fragment PendingTransferInstances on TransferParticipant {
  pendingTransferInstanceSummary
  pendingTransferInstances {
    id
    amount
    from {
      id
      transferParticipantName
    }
    to {
      id
      transferParticipantName
    }
  }
}
    `;
export const BorrowMarketingHeaderAccountFragmentDoc = gql`
    fragment BorrowMarketingHeaderAccount on BorrowAccount {
  id
  creditAvailable
  hasCreditAvailable
  creditBorrowed
  __typename
  name
  creditLimit
  hasBorrowedBefore
  hasPendingTransferInstances
  inUsePercent
  rate {
    ratePercent
  }
  billDue {
    startDate
    endDate
    dueDate
    isDue
    rateDescription
    amount
  }
  billUpcoming {
    specialMessage
    dueDate
    totalInterestToDate
    projectedTotalInterest
  }
  status {
    alertBanner
    code
    hasOpenMaintenanceCall
    borrowAccount {
      investAccount {
        id
        registration
      }
    }
    description
    title
    valueDecreaseToMaintenanceCallPercent
  }
  ...PendingTransferInstances
}
    ${PendingTransferInstancesFragmentDoc}`;
export const BorrowBillNodeFragmentDoc = gql`
    fragment BorrowBillNode on BorrowBill {
  startDate
  endDate
  dueDate
  amount
  isDue
  rateDescription
}
    `;
export const AnnouncementFragmentDoc = gql`
    fragment Announcement on Announcement {
  displayPreference
  announcementIcon {
    ...AppImage
  }
  title
  description
  timeDescription
  tag
  appearance
  id
  action {
    type
    title
    analyticsEvent {
      name
      valueParameter
      customParameters {
        name
        value
      }
    }
  }
  appLink {
    ...Linkable
  }
}
    ${AppImageFragmentDoc}
${LinkableFragmentDoc}`;
export const BorrowMarketingHeaderFragmentDoc = gql`
    fragment BorrowMarketingHeader on Query {
  viewer {
    announcements {
      forBorrow {
        ...Announcement
      }
    }
    borrow {
      borrowAccounts {
        edges {
          node {
            id
            creditBorrowed
            hasCreditAvailable
          }
        }
      }
      maxBorrowableAccountValuePercent
      minRequiredAccountValue
      hasBorrowAccounts
      hasBorrowedBefore
      baseRateForPlus
      baseRate
      personalLoans {
        activeApplication {
          status {
            status
          }
        }
      }
    }
  }
}
    ${AnnouncementFragmentDoc}`;
export const HistoricalDividendFragmentDoc = gql`
    fragment HistoricalDividend on HistoricalDividend {
  symbol
  amount
}
    `;
export const TradeCashFlowFragmentDoc = gql`
    fragment TradeCashFlow on TradeCashFlow {
  symbol
  amount
}
    `;
export const ChartableSliceDatumFragmentDoc = gql`
    fragment ChartableSliceDatum on ChartableSliceDatum {
  date
  open
  high
  low
  close
  volume
  previousClose
  sumDividends
  dividends {
    ...HistoricalDividend
  }
  sumTrades
  trades {
    ...TradeCashFlow
  }
}
    ${HistoricalDividendFragmentDoc}
${TradeCashFlowFragmentDoc}`;
export const ChartableSliceDataFragmentDoc = gql`
    fragment ChartableSliceData on ChartableSliceData {
  name
  seriesId
  seriesType
  data {
    ...ChartableSliceDatum
  }
  additionalData {
    name
    onSeriesId
    seriesType
    dataType
    data {
      ...ChartableSliceDatum
    }
  }
}
    ${ChartableSliceDatumFragmentDoc}`;
export const CreditCardApplicationSummaryApplicantFragmentDoc = gql`
    fragment CreditCardApplicationSummaryApplicant on Credit {
  applicantInformation {
    fullName
    dateOfBirth
    ssnLastFour
    email
    phoneNumber
    legalResidence
  }
}
    `;
export const CreditCardSummaryFragmentDoc = gql`
    fragment CreditCardSummary on Credit {
  applicantInformation {
    id
  }
  termsAndConditions {
    signature
    preamble
    postamble
    documents {
      title
      url
    }
  }
  ...CreditCardApplicationSummaryApplicant
}
    ${CreditCardApplicationSummaryApplicantFragmentDoc}`;
export const CreditCardRewardsSummaryFragmentDoc = gql`
    fragment CreditCardRewardsSummary on CreditCardRewardsSummary {
  currentCycleRewardsEstimate {
    label
    value
    tooltipContent {
      title
      content
    }
    subtitle
    pill {
      ...AppPill
    }
  }
  lifetimeRewardsOverview {
    label
    value
  }
  rewardsPayoutLocation {
    label
    icon {
      ...AppImage
    }
    value
  }
}
    ${AppPillFragmentDoc}
${AppImageFragmentDoc}`;
export const CreditCardRewardsProgramFragmentDoc = gql`
    fragment CreditCardRewardsProgram on CreditCardRewardsProgramOverview {
  id
  creditCardRewardsDetailsScreenId
  securityLogoUrl
  title
  value
}
    `;
export const OwnersRewardProgramsFragmentDoc = gql`
    fragment OwnersRewardPrograms on CreditCardOwnersRewardsProgramHighlights {
  programs {
    ...CreditCardRewardsProgram
  }
}
    ${CreditCardRewardsProgramFragmentDoc}`;
export const CreditCardRewardsDetailsFragmentDoc = gql`
    fragment CreditCardRewardsDetails on CreditCardRewardsPortalScreen {
  standardRewardsOverview {
    title
    highlights {
      icon {
        ...AppImage
      }
      description
      creditCardRewardsDetailsScreenId
    }
  }
  ownersRewardsOverview {
    title
    tooltip {
      copy
      link {
        ...Linkable
      }
    }
    highlights {
      title
      link {
        title
        destination
      }
      programs {
        id
      }
      ...OwnersRewardPrograms
    }
  }
}
    ${AppImageFragmentDoc}
${LinkableFragmentDoc}
${OwnersRewardProgramsFragmentDoc}`;
export const RelatedTransactionFragmentDoc = gql`
    fragment RelatedTransaction on SpendActivityEntry {
  date
  merchant
  amount
  details {
    id
    colorizedTransactionRewardTier {
      kind
      label
      icon
    }
  }
}
    `;
export const RelatedTransactionsFragmentDoc = gql`
    fragment RelatedTransactions on CreditCardTransactionConnection {
  edges {
    node {
      ...RelatedTransaction
    }
  }
}
    ${RelatedTransactionFragmentDoc}`;
export const SelectRewardDestinationContentFragmentDoc = gql`
    fragment SelectRewardDestinationContent on CreditCardSelectRewardDestinationContent {
  title
  description
  placeholder
  buttonLabel
  destinationOptions {
    name
    id
  }
}
    `;
export const DocumentNodeFragmentDoc = gql`
    fragment DocumentNode on Document {
  __typename
  accountName {
    label
    subLabel
  }
  date
  id
  name
  type
  ... on InvestAccountDocument {
    url
    inserts
  }
}
    `;
export const DocumentFragmentDoc = gql`
    fragment Document on DocumentUploadRequest {
  id
  associatedServiceType
  associatedServiceDescription
  acceptableFileTypes
  acceptableMimeTypes
  businessPurpose
  header
  selectLabel
  uploadLabel
  acceptableDocumentTypes {
    docType
    description
  }
  maximumFileSizeInBytes
  maximumFileSizeDescription
}
    `;
export const AppNavigationItemFragmentDoc = gql`
    fragment AppNavigationItem on AppNavigationItem {
  title
  subtitle
  analyticsEvent {
    ...AnalyticsEvent
  }
  url
  icon {
    ...AppImage
  }
  internalPath
  kind
  size
  underline
  font
  fontWeight
}
    ${AnalyticsEventFragmentDoc}
${AppImageFragmentDoc}`;
export const AccountTileFragmentDoc = gql`
    fragment AccountTile on AccountTile {
  title
  subtitle {
    text
    icon {
      ...AppImage
    }
  }
  icon {
    ...AppImage
  }
  illustration {
    ...AppImage
  }
  primaryAction {
    ...AppLink
  }
  secondaryAction {
    ...AppNavigationItem
  }
  value
  subValue {
    text
    color
  }
  analyticsEvent {
    ...AnalyticsEvent
  }
}
    ${AppImageFragmentDoc}
${AppLinkFragmentDoc}
${AppNavigationItemFragmentDoc}
${AnalyticsEventFragmentDoc}`;
export const HomeTilesSectionFragmentDoc = gql`
    fragment HomeTilesSection on HomeTilesSection {
  header
  tiles {
    ...AccountTile
  }
}
    ${AccountTileFragmentDoc}`;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
    `;
export const HomeTilesSectionConnectionFragmentDoc = gql`
    fragment HomeTilesSectionConnection on HomeTilesSectionConnection {
  edges {
    node {
      ...HomeTilesSection
    }
  }
  pageInfo {
    ...PageInfo
  }
}
    ${HomeTilesSectionFragmentDoc}
${PageInfoFragmentDoc}`;
export const OverviewBalancesFragmentDoc = gql`
    fragment OverviewBalances on BalancesOverview {
  total
  assets {
    value
  }
  liabilities {
    value
  }
  assetRatio
}
    `;
export const OverviewCardContentFragmentDoc = gql`
    fragment OverviewCardContent on OverviewCardInterface {
  title {
    icon {
      ...AppImage
    }
    text
    subtitle
  }
  content {
    text
    ... on OverviewInvestCardContent {
      gains {
        change
        isGain
      }
    }
  }
  marketingImage {
    ...AppImage
  }
  link {
    internalPath
    title
    analyticsEvent {
      ...AnalyticsEvent
    }
  }
  footer {
    text
    icon {
      ...AppImage
    }
  }
  displayAnalyticsEvent {
    ...AnalyticsEvent
  }
}
    ${AppImageFragmentDoc}
${AnalyticsEventFragmentDoc}`;
export const OverviewCardGroupsFragmentDoc = gql`
    fragment OverviewCardGroups on OverviewCards {
  assets {
    ...OverviewCardContent
  }
  liabilities {
    ...OverviewCardContent
  }
}
    ${OverviewCardContentFragmentDoc}`;
export const TransferParticipantFragmentDoc = gql`
    fragment TransferParticipant on TransferParticipant {
  id
  transferParticipantName
  transferParticipantDescription
  transferParticipantType
}
    `;
export const TransferInstanceNodeFragmentDoc = gql`
    fragment TransferInstanceNode on TransferInstance {
  id
  isCheck
  isCreditCardPayment
  isCreditCardAutoPayInstance
  startDate
  amount
  from {
    ...TransferParticipant
  }
  to {
    ...TransferParticipant
  }
  status {
    code
    isCancelable
    isCanceled
    isComplete
    isEnded
    isFailed
    isPending
  }
  isLiquidation
}
    ${TransferParticipantFragmentDoc}`;
export const TransferInstancesFragmentDoc = gql`
    fragment TransferInstances on TransferInstanceConnection {
  edges {
    node {
      ...TransferInstanceNode
    }
  }
}
    ${TransferInstanceNodeFragmentDoc}`;
export const AppCardFragmentDoc = gql`
    fragment AppCard on AppCard {
  isCollapsible
  isDismissible
  backgroundColor
  borderColor
  highlightColor
  icon {
    ...AppImage
  }
  title {
    ...RichText
  }
  paragraphs {
    ...RichText
  }
  primaryCta {
    ...AppButtonUnion
  }
  secondaryCta {
    ...AppButtonUnion
  }
  tappableCta {
    ...AppButtonUnion
  }
  displayAnalyticsEvent {
    ...AnalyticsEvent
  }
  actionAnalyticsEvent {
    ...AnalyticsEvent
  }
}
    ${AppImageFragmentDoc}
${RichTextFragmentDoc}
${AppButtonUnionFragmentDoc}
${AnalyticsEventFragmentDoc}`;
export const HomePagePromotionsFragmentDoc = gql`
    fragment HomePagePromotions on OverviewPromotions {
  header {
    ...RichText
  }
  cards {
    ...AppCard
  }
}
    ${RichTextFragmentDoc}
${AppCardFragmentDoc}`;
export const AccountCashBalanceNodeFragmentDoc = gql`
    fragment AccountCashBalanceNode on Account {
  id
  maxCashThreshold
  autoInvestTriggerMinimum
  balance {
    cash {
      available
    }
  }
}
    `;
export const DividendConfigurationSettingFragmentDoc = gql`
    fragment DividendConfigurationSetting on AccountConfigDividendSetting {
  accounts {
    selected
    transferParticipant {
      id
      transferParticipantName
      transferParticipantDescription
      transferParticipantType
    }
  }
  contextualLink {
    ...Linkable
  }
  description
  disabled
  label
  selected
  value
  valueLabel
}
    ${LinkableFragmentDoc}`;
export const DividendConfigurationHoldingSettingFragmentDoc = gql`
    fragment DividendConfigurationHoldingSetting on AccountConfigurationDividendsHoldings {
  position {
    descriptor
    security {
      profile {
        logoUrl
      }
    }
    symbol
  }
  settings {
    ...DividendConfigurationSetting
  }
}
    ${DividendConfigurationSettingFragmentDoc}`;
export const AccountConfigurationDividendsFragmentDoc = gql`
    fragment AccountConfigurationDividends on AccountConfiguration {
  dividends {
    footerText
    heading
    subheading
    contextualLink {
      ...Linkable
    }
    selectedSettingType
    settings {
      ...DividendConfigurationSetting
    }
    holdings {
      ...DividendConfigurationHoldingSetting
    }
  }
  id
}
    ${LinkableFragmentDoc}
${DividendConfigurationSettingFragmentDoc}
${DividendConfigurationHoldingSettingFragmentDoc}`;
export const AccountConfigurationFragmentDoc = gql`
    fragment AccountConfiguration on Account {
  configuration {
    ...AccountConfigurationDividends
  }
  id
}
    ${AccountConfigurationDividendsFragmentDoc}`;
export const ConfirmationDialogPieFragmentDoc = gql`
    fragment ConfirmationDialogPie on Pie {
  id
  name
  ... on UserPie {
    portfolioLinks {
      id
      isRootSlice
      account {
        id
        name
      }
      __typename
      ... on ChildPortfolioSlice {
        ancestors {
          to {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export const PieChangesConfirmationFragmentDoc = gql`
    fragment PieChangesConfirmation on PieChangesConfirmation {
  applicableLocationsMessage
  pieDetails {
    name {
      value
      actionPill {
        kind
        label
      }
    }
    description {
      value
      actionPill {
        kind
        label
      }
    }
  }
  addedOrUpdated {
    slices {
      actionPill {
        kind
        label
      }
      name
      percentage
      symbol
    }
    message
  }
  removed {
    slices {
      symbol
      name
      value
    }
    message
  }
}
    `;
export const CustodialAccountBeneficiaryFragmentDoc = gql`
    fragment CustodialAccountBeneficiary on Account {
  custodialAccountBeneficiary {
    id
    firstName
    middleInitial
    lastName
    suffix
    dateOfBirth
    countryOfCitizenship
    formattedLegalResidence
    legalResidence {
      lineOne
      lineTwo
      city
      stateOrProvince
      postalCode
    }
  }
  custodialAccountDescription
}
    `;
export const HeaderAnnouncementsFragmentDoc = gql`
    fragment HeaderAnnouncements on Announcements {
  hasAny
  hasNew
  list(first: 100) {
    edges {
      node {
        id
        ...Announcement
      }
    }
  }
}
    ${AnnouncementFragmentDoc}`;
export const SecurityFundamentalsFragmentDoc = gql`
    fragment SecurityFundamentals on Security {
  id
  fundamentals {
    averageDailyVolumeShort
    beta
    dividendYield
    eps
    marketCapShort
    peRatio
    priceToSales
    week52HighPrice
    week52LowPrice
    sharesOutstandingShort
  }
  ... on Fund {
    status
    fundCategory
    fundFundamentals {
      inceptionDate
      totalNumberOfHoldings
      netExpenseRatio
      topHoldings {
        symbol
        name
        weighting
        quantity
      }
      assetAllocations {
        name
        percentage
      }
    }
  }
  ... on CryptoAsset {
    marketCap
    volume
    circulatingSupply
    maxSupply
  }
}
    `;
export const SecurityQuoteFragmentDoc = gql`
    fragment SecurityQuote on Security {
  id
  latestQuote {
    lastPrice
    changeFromPreviousClosePrice
    percentChangeFromPreviousClosePrice
    asOfTime
    lastTradeTime
    localAsOfTime
    localLastTradeTime
    tradeVolume
  }
  quote {
    previousClosePrice
    openPrice
    highPrice
    lowPrice
  }
  ... on CryptoAsset {
    week52High
    week52Low
  }
}
    `;
export const SecurityMarginRequirementsFragmentDoc = gql`
    fragment SecurityMarginRequirements on Security {
  id
  isMarginable
  maintenanceMargin
  symbol
  marginRequirementTooltip {
    icon {
      ...AppImage
    }
    label
    text
  }
  marginEligibileTooltip {
    icon {
      ...AppImage
    }
    label
    text
  }
  marginEligibleIcon {
    ...AppImage
  }
}
    ${AppImageFragmentDoc}`;
export const SecurityRiskLevelFragmentDoc = gql`
    fragment SecurityRiskLevel on Security {
  ... on Equity {
    fundamentals {
      peRatio
      peRatioGrade
    }
  }
  ... on Fund {
    fundFundamentals {
      netExpenseRatio
      netExpenseRatioGrade
    }
  }
}
    `;
export const SliceableLogoFragmentDoc = gql`
    fragment SliceableLogo on Sliceable {
  __typename
  name
  ... on Security {
    symbol
    profile {
      logoUrl
    }
  }
  ... on SystemPie {
    key
    logoUrl
    categorizationDetails {
      logoUrl
      name
      key
    }
  }
  ... on UserPie {
    portfolioLinks {
      isRootSlice
    }
  }
}
    `;
export const SecurityProfileFragmentDoc = gql`
    fragment SecurityProfile on Security {
  id
  profile {
    ceo
    numberOfEmployees
    headquarters
    description
    longDescription
    issueType
    website
    sector
    industry
    websiteIcon {
      ...AppImage
    }
    securitySecUrl {
      ...Linkable
    }
    secIcon {
      ...AppImage
    }
  }
  ... on CryptoAsset {
    createdDate
    coinType
    developmentStatus
    openSource
    orgStructure
    hashAlgorithm
    whitepaperLink {
      ...Linkable
    }
    whitepaperIcon {
      ...AppImage
    }
  }
}
    ${AppImageFragmentDoc}
${LinkableFragmentDoc}`;
export const SliceableCellFragmentDoc = gql`
    fragment SliceableCell on Sliceable {
  __typename
  id
  isActive
  name
  ... on Security {
    symbol
    status
  }
  ... on SystemPie {
    systemPieStatus: status
  }
  ...SliceableLogo
}
    ${SliceableLogoFragmentDoc}`;
export const SecurityDetailFragmentDoc = gql`
    fragment SecurityDetail on Security {
  __typename
  id
  symbol
  type
  market
  isActive
  status
  name
  securityPill {
    ...AppPill
  }
  ...SecurityFundamentals
  ...SecurityQuote
  ...SecurityMarginRequirements
  ...SecurityRiskLevel
  ...SliceableLogo
  ...SecurityProfile
  ... on CryptoAsset {
    restrictions
  }
}
    ${AppPillFragmentDoc}
${SecurityFundamentalsFragmentDoc}
${SecurityQuoteFragmentDoc}
${SecurityMarginRequirementsFragmentDoc}
${SecurityRiskLevelFragmentDoc}
${SliceableLogoFragmentDoc}
${SecurityProfileFragmentDoc}`;
export const SliceableFragmentDoc = gql`
    fragment Sliceable on Sliceable {
  __typename
  type
  id
  name
  isActive
  ...SecurityFundamentals
  ...SecurityQuote
  ...SecurityMarginRequirements
  ...SecurityRiskLevel
  ...SliceableLogo
  ...SecurityProfile
  ...SliceableLogo
  ...SliceableCell
  ...SecurityDetail
}
    ${SecurityFundamentalsFragmentDoc}
${SecurityQuoteFragmentDoc}
${SecurityMarginRequirementsFragmentDoc}
${SecurityRiskLevelFragmentDoc}
${SliceableLogoFragmentDoc}
${SecurityProfileFragmentDoc}
${SliceableCellFragmentDoc}
${SecurityDetailFragmentDoc}`;
export const SliceAvatarStatusFragmentDoc = gql`
    fragment SliceAvatarStatus on PortfolioSlice {
  orderStatus {
    isSetOnSelfOrInDescendants
  }
  rebalanceStatus {
    isSetOnSelfOrInDescendants
  }
  to {
    isActive
  }
}
    `;
export const PortfolioDividendChildrenFragmentDoc = gql`
    fragment PortfolioDividendChildren on PortfolioSlice {
  children {
    id
    to {
      name
      ...Sliceable
    }
    performance(period: $period) {
      earnedDividends
    }
    ...SliceAvatarStatus
  }
}
    ${SliceableFragmentDoc}
${SliceAvatarStatusFragmentDoc}`;
export const SliceEarnedDividendsFragmentDoc = gql`
    fragment SliceEarnedDividends on PortfolioSlice {
  performance(period: $period) {
    earnedDividends
  }
  ...PortfolioDividendChildren
}
    ${PortfolioDividendChildrenFragmentDoc}`;
export const EstimatedTradeEdgeFragmentDoc = gql`
    fragment EstimatedTradeEdge on EstimatedTradeAmountEdge {
  node {
    __typename
    amount
    isBuy
    of {
      ... on Security {
        symbol
      }
      ...SliceableCell
      profile {
        logoUrl
      }
    }
  }
}
    ${SliceableCellFragmentDoc}`;
export const EstimatedTradingFragmentDoc = gql`
    fragment EstimatedTrading on EstimatedTradeAmountConnection {
  edges {
    ...EstimatedTradeEdge
  }
  pageInfo {
    ...PageInfo
  }
  total
}
    ${EstimatedTradeEdgeFragmentDoc}
${PageInfoFragmentDoc}`;
export const EstimatedBuysFragmentDoc = gql`
    fragment EstimatedBuys on Account {
  estimatedTrading {
    buys(first: $first, after: $after) {
      ...EstimatedTrading
    }
  }
}
    ${EstimatedTradingFragmentDoc}`;
export const EstimatedSellsFragmentDoc = gql`
    fragment EstimatedSells on Account {
  estimatedTrading {
    sells(first: $first, after: $after) {
      ...EstimatedTrading
    }
  }
}
    ${EstimatedTradingFragmentDoc}`;
export const ExcludedBuysFragmentDoc = gql`
    fragment ExcludedBuys on Account {
  estimatedTrading {
    excludedBuys(first: $first, after: $after) {
      ...EstimatedTrading
    }
  }
}
    ${EstimatedTradingFragmentDoc}`;
export const ExcludedBuysAccountNodeFragmentDoc = gql`
    fragment ExcludedBuysAccountNode on Account {
  minimumBuyTradeAmount
}
    `;
export const FundingAccountParticipantFragmentDoc = gql`
    fragment FundingAccountParticipant on TransferParticipant {
  id
  transferParticipantType
  transferParticipantName
  transferParticipantDescription
}
    `;
export const FundedAccountFragmentDoc = gql`
    fragment FundedAccount on Account {
  id
  name
  status
  minimumInitialDeposit
  maximumInitialDeposit
  isCryptoAccount
  maxCashThreshold
  isRetirement
  iraContributionLimits {
    currentYearLimit
  }
}
    `;
export const InvestActivityNodeFragmentDoc = gql`
    fragment InvestActivityNode on InvestActivityEntry {
  __typename
  id
  title
  date(local: true)
  description
  ... on InvestActivityTradeSummaryEntry {
    countOfBuys
    countOfSells
    amountOfBuys
    amountOfSells
  }
  ... on InvestActivityTradeEntry {
    amount
    tradeSecurity {
      descriptor
      security {
        symbol
        name
      }
    }
  }
  ... on InvestActivityCashEntry {
    amount
    isRelatedToSecurity
    cashSecurity {
      descriptor
      security {
        symbol
        name
      }
    }
    transferDetails {
      transferSummary
      transferId
      totalAmount
    }
  }
  ... on InvestActivityPositionEntry {
    quantity
    positionSecurity {
      symbol
      descriptor
      security {
        symbol
        name
      }
    }
  }
}
    `;
export const InvestActivityFragmentDoc = gql`
    fragment InvestActivity on Account {
  investActivity {
    activity(first: $first, after: $after, filter: $filter, sort: $sort) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          ...InvestActivityNode
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${InvestActivityNodeFragmentDoc}`;
export const SecurityCellFragmentDoc = gql`
    fragment SecurityCell on Security {
  id
  name
  ...SliceableLogo
}
    ${SliceableLogoFragmentDoc}`;
export const InvestActivityEntryFragmentDoc = gql`
    fragment InvestActivityEntry on InvestActivityEntry {
  id
  date(local: true)
  title
  description
  ... on InvestActivityTradeEntry {
    tradeSecurity {
      descriptor
      security {
        type
        ...SecurityCell
        market
      }
    }
    quantity
    sharePrice
    amount
  }
  ... on InvestActivityCashEntry {
    isRelatedToSecurity
    cashSecurity {
      descriptor
      security {
        ...SecurityCell
      }
    }
    amount
    activityDescriptionLink {
      title
      url
    }
    tooltipCopy
    transferDetails {
      transferId
    }
  }
  ... on InvestActivityPositionEntry {
    positionSecurity {
      symbol
      descriptor
    }
    quantity
  }
  ... on InvestActivityTradeSummaryEntry {
    countOfBuys
    countOfSells
    amountOfBuys
    amountOfSells
  }
}
    ${SecurityCellFragmentDoc}`;
export const TradeEntryFragmentDoc = gql`
    fragment TradeEntry on InvestActivityTradeEntry {
  id
  tradeSecurity {
    descriptor
    symbol
    security {
      ...SliceableCell
    }
  }
  amount
}
    ${SliceableCellFragmentDoc}`;
export const TradeConnectionFragmentDoc = gql`
    fragment TradeConnection on InvestActivityTradeEntryConnection {
  edges {
    node {
      ...TradeEntry
    }
  }
  pageInfo {
    ...PageInfo
  }
}
    ${TradeEntryFragmentDoc}
${PageInfoFragmentDoc}`;
export const InvestActivityTradeFragmentDoc = gql`
    fragment InvestActivityTrade on InvestActivityTradeSummaryEntry {
  countOfBuys
  countOfSells
  excludedBuys {
    total
  }
  buyTrades: trades(
    first: $firstForBuys
    after: $afterForBuys
    includeBuys: true
    includeSells: false
  ) {
    ...TradeConnection
  }
  sellTrades: trades(
    first: $firstForSells
    after: $afterForSells
    includeBuys: false
    includeSells: true
  ) {
    ...TradeConnection
  }
}
    ${TradeConnectionFragmentDoc}`;
export const ExcludedBuyEntryFragmentDoc = gql`
    fragment ExcludedBuyEntry on InvestActivityExcludedTrade {
  tradeSecurity {
    descriptor
    symbol
    security {
      ...SliceableCell
    }
  }
  amount
}
    ${SliceableCellFragmentDoc}`;
export const ExcludedBuysTableFragmentDoc = gql`
    fragment ExcludedBuysTable on InvestActivityTradeSummaryEntry {
  excludedBuys(first: $first, after: $after) {
    pageInfo {
      ...PageInfo
    }
    edges {
      node {
        ...ExcludedBuyEntry
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${ExcludedBuyEntryFragmentDoc}`;
export const ExcludedBuysPageDetailsFragmentDoc = gql`
    fragment ExcludedBuysPageDetails on InvestActivityTradeSummaryEntry {
  id
  account {
    minimumBuyTradeAmount
  }
  ...ExcludedBuysTable
}
    ${ExcludedBuysTableFragmentDoc}`;
export const InvestBankConnectionAccountNodeFragmentDoc = gql`
    fragment InvestBankConnectionAccountNode on Account {
  id
  allowsExternalFunding
  isCryptoAccount
  isFunded
  isLiquidating
  isRetirement
  number
  status
  shouldPromptForTransfer
  hasPendingTransferInstances
  lastAchRelationship {
    __typename
    id
    isActive
    status
    rejectionReason
    nickname
  }
  balance {
    totalValue {
      hasValue
    }
  }
}
    `;
export const InvestFundingFragmentDoc = gql`
    fragment InvestFunding on Account {
  id
  isRetirement
  fundingTotals {
    id
    totalsByYear {
      year
      totalDeposits
      totalWithdrawals
    }
  }
  iraContributionTotals {
    totalsByYear {
      year
      totalContribution
      maxContribution
    }
  }
}
    `;
export const InvestPageAccountNodeFragmentDoc = gql`
    fragment InvestPageAccountNode on Account {
  allowsExternalFunding
  isCryptoAccount
  isCustodialAccount
  id
  name
  number
}
    `;
export const TabularDataItemFragmentDoc = gql`
    fragment TabularDataItem on TabularDataItem {
  title
  titleLink {
    ...AppLink
  }
  tooltip {
    ...AppTooltip
  }
  value
  valueLink {
    ...AppLink
  }
  valueIcon {
    ...AppImage
  }
  richTextValue {
    ...RichText
  }
}
    ${AppLinkFragmentDoc}
${AppTooltipFragmentDoc}
${AppImageFragmentDoc}
${RichTextFragmentDoc}`;
export const PortfolioSliceBuyingPowerFragmentDoc = gql`
    fragment PortfolioSliceBuyingPower on PortfolioSlice {
  id
  buyingPower {
    cash {
      ...TabularDataItem
    }
    margin {
      ...TabularDataItem
    }
    total {
      ...TabularDataItem
    }
  }
}
    ${TabularDataItemFragmentDoc}`;
export const InvestmentFragmentDoc = gql`
    fragment Investment on Position {
  id
  cost {
    averageSharePrice
    cost
  }
  marginability {
    maintenanceEquityRequirementPercent
  }
  positionSecurity {
    descriptor
    security {
      __typename
      id
      profile {
        logoUrl
      }
      type
      ... on Security {
        symbol
      }
      ...SliceableCell
    }
    symbol
  }
  quantity
  unrealizedGain {
    gain
    gainPercent
  }
  value {
    value
  }
}
    ${SliceableCellFragmentDoc}`;
export const InvestmentsFragmentDoc = gql`
    fragment Investments on Investments {
  positions(first: $first, after: $after, sort: $positionsSort) {
    pageInfo {
      hasNextPage
      endCursor
    }
    total
    edges {
      cursor
      node {
        ...Investment
      }
    }
  }
  totalValue {
    isPartial
    value
  }
  totalUnrealizedGain {
    gain
    gainPercent
  }
  totalCost {
    cost
    isPartial
  }
}
    ${InvestmentFragmentDoc}`;
export const BorrowAccountNodeFragmentDoc = gql`
    fragment BorrowAccountNode on BorrowAccount {
  investAccount {
    name
  }
  status {
    maintenanceCallMarginEquityDeficit
    marginEquity
    requiredMarginEquity
  }
}
    `;
export const BorrowAccountMarginStateFragmentDoc = gql`
    fragment BorrowAccountMarginState on BorrowAccount {
  status {
    maintenanceCallMarginEquityDeficit
  }
}
    `;
export const SlicePerformanceMetricsFragmentDoc = gql`
    fragment SlicePerformanceMetrics on PortfolioSlicePerformance {
  earnedDividends
  moneyWeightedRateOfReturn
  netCashFlow
}
    `;
export const RootPerformanceMetricsFragmentDoc = gql`
    fragment RootPerformanceMetrics on RootPortfolioSlice {
  id
  performance(period: $period) {
    ...SlicePerformanceMetrics
  }
}
    ${SlicePerformanceMetricsFragmentDoc}`;
export const ChildPerformanceMetricsFragmentDoc = gql`
    fragment ChildPerformanceMetrics on ChildPortfolioSlice {
  id
  performance(period: $period) {
    ...SlicePerformanceMetrics
  }
}
    ${SlicePerformanceMetricsFragmentDoc}`;
export const TransferFundsButtonViewerFragmentDoc = gql`
    fragment TransferFundsButtonViewer on Viewer {
  transfers {
    participants(
      transferType: TRANSFER_INSTANCE
      forSide: TO
      withOppositeParticipantId: null
    ) {
      isEmpty
    }
  }
}
    `;
export const EstimatedTradesFragmentDoc = gql`
    fragment EstimatedTrades on EstimatedTrading {
  hasExcludedBuys
  hasEstimatedTrades
  hasTrades
  buys {
    total
  }
  excludedBuys {
    total
  }
  sells {
    total
  }
}
    `;
export const ReceivedSecuritiesCardFragmentDoc = gql`
    fragment ReceivedSecuritiesCard on ReceivedSecuritiesCard {
  header
  icon {
    ...AppImage
  }
  sections {
    label
    value
  }
  link {
    url
    ...Linkable
  }
}
    ${AppImageFragmentDoc}
${LinkableFragmentDoc}`;
export const SetupChecklistFragmentDoc = gql`
    fragment SetupChecklist on SetupChecklist {
  steps {
    destination {
      path
      url
    }
    analyticsEvent {
      ...AnalyticsEvent
    }
    isCompleted
    isReady
    title
  }
  stepsCompleted
  stepsTotal
  title
}
    ${AnalyticsEventFragmentDoc}`;
export const OnDemandTradingStatusCardFragmentDoc = gql`
    fragment OnDemandTradingStatusCard on AccountTradingStatus {
  isInVendorMaintenanceWindow
  onDemandTradeWindowsLeft
  maxOnDemandTradeWindows
}
    `;
export const TradingPauseFragmentDoc = gql`
    fragment TradingPause on AccountTradingPause {
  title
  description
  appLink {
    ...Linkable
  }
}
    ${LinkableFragmentDoc}`;
export const TransferFundsButtonFragmentDoc = gql`
    fragment TransferFundsButton on Account {
  allowsExternalFunding
  isLiquidating
  isCryptoAccount
  isFunded
  lastAchRelationship {
    __typename
    status
    isActive
  }
}
    `;
export const CashControlFragmentDoc = gql`
    fragment CashControl on Account {
  id
  balance {
    cash {
      investCashAvailable
      cryptoCashAvailable
    }
  }
  isCryptoAccount
  maxCashThreshold
  autoInvestTriggerMinimum
  ...TransferFundsButton
}
    ${TransferFundsButtonFragmentDoc}`;
export const PortfolioSliceButtonFragmentDoc = gql`
    fragment PortfolioSliceButton on Account {
  id
  isFunded
  hasPendingTransferInstances
}
    `;
export const PortfolioDetailsAccountNodeFragmentDoc = gql`
    fragment PortfolioDetailsAccountNode on Account {
  configuration {
    dividends {
      cta {
        label
        link {
          ...Linkable
        }
        value
      }
    }
  }
  estimatedTrading {
    ...EstimatedTrades
  }
  name
  registration
  hasPendingTransferInstances
  hasPortfolio
  hasPreferredTradeWindowAccess
  id
  isCryptoAccount
  isTradingPaused
  preferredTradeWindow
  receivedSecurities {
    receivedSecuritiesCard {
      ...ReceivedSecuritiesCard
    }
  }
  setupChecklist {
    ...SetupChecklist
  }
  trading {
    ...OnDemandTradingStatusCard
  }
  tradingPause {
    ...TradingPause
  }
  ...CashControl
  ...PendingTransferInstances
  ...PortfolioSliceButton
}
    ${LinkableFragmentDoc}
${EstimatedTradesFragmentDoc}
${ReceivedSecuritiesCardFragmentDoc}
${SetupChecklistFragmentDoc}
${OnDemandTradingStatusCardFragmentDoc}
${TradingPauseFragmentDoc}
${CashControlFragmentDoc}
${PendingTransferInstancesFragmentDoc}
${PortfolioSliceButtonFragmentDoc}`;
export const PortfolioSliceLandingPageFragmentDoc = gql`
    fragment PortfolioSliceLandingPage on PortfolioSlice {
  investProductLandingPage {
    promotionCards {
      ...AppCard
    }
  }
}
    ${AppCardFragmentDoc}`;
export const PortfolioSlicePieFragmentDoc = gql`
    fragment PortfolioSlicePie on PortfolioSlice {
  account {
    id
  }
  id
  to {
    name
    ... on Security {
      status
    }
  }
  value {
    total
  }
  performance(period: MAX) {
    moneyWeightedRateOfReturn
    totalGain
  }
  children {
    id
    percentage
    to {
      id
      name
    }
    value {
      total
      hasValue
    }
    performance(period: MAX) {
      moneyWeightedRateOfReturn
      totalGain
    }
    targetValuePercent
    portionOfParentValuePercent
  }
  ... on ChildPortfolioSlice {
    ancestors {
      children {
        id
        percentage
        to {
          id
          name
        }
        value {
          total
          hasValue
        }
        targetValuePercent
        portionOfParentValuePercent
      }
    }
  }
}
    `;
export const PortfolioSliceSecondaryMetricsFragmentDoc = gql`
    fragment PortfolioSliceSecondaryMetrics on PortfolioSlice {
  ... on RootPortfolioSlice {
    id
    isRootSlice
    performance(period: MAX) {
      startValue {
        date
        value
      }
      capitalGain
      earnedDividends
      netCashFlow
    }
    to {
      type
    }
  }
  ... on PortfolioSlice {
    isRootSlice
  }
  ... on ChildPortfolioSlice {
    id
    isRootSlice
    performance(period: MAX) {
      startValue {
        date
        value
      }
      capitalGain
      earnedDividends
      netCashFlow
    }
    to {
      type
    }
    targetValueDiff
  }
}
    `;
export const PortfolioSlicePendingRebalanceBannerFragmentDoc = gql`
    fragment PortfolioSlicePendingRebalanceBanner on PortfolioSlice {
  id
  rebalanceStatus {
    isSetOnSelf
  }
}
    `;
export const PortfolioSlicePendingOrderBannerFragmentDoc = gql`
    fragment PortfolioSlicePendingOrderBanner on PortfolioSlice {
  id
  orderStatus {
    isSetOnSelf
    order {
      cashFlow
    }
  }
}
    `;
export const PortfolioSlicePrimaryMetricsFragmentDoc = gql`
    fragment PortfolioSlicePrimaryMetrics on PortfolioSlice {
  performance(period: MAX) {
    moneyWeightedRateOfReturn
    totalGain
  }
  value {
    total
  }
}
    `;
export const PortfolioSliceParentBreadcrumbFragmentDoc = gql`
    fragment PortfolioSliceParentBreadcrumb on ChildPortfolioSlice {
  ancestors {
    id
    to {
      name
    }
  }
}
    `;
export const PortfolioSliceFirstPositionSectionFragmentDoc = gql`
    fragment PortfolioSliceFirstPositionSection on PortfolioSlice {
  investments {
    allocatedPositions(first: 100) {
      edges {
        node {
          allocation
          allocationPercent
          position {
            cost {
              cost
              averageSharePrice
            }
            marginability {
              isMarginable
              maintenanceEquityRequirementPercent
            }
            quantity
          }
        }
      }
    }
  }
}
    `;
export const PortfolioSliceOrderButtonFragmentDoc = gql`
    fragment PortfolioSliceOrderButton on PortfolioSlice {
  id
  orderStatus {
    isSetOnSelf
  }
}
    `;
export const PortfolioSliceRebalanceStatusFragmentDoc = gql`
    fragment PortfolioSliceRebalanceStatus on PortfolioSliceRebalanceStatus {
  id
  isSetOnSelf
  isSetInDescendants
  isSetOnSelfOrInDescendants
  isLocked
  key
  lockReason
}
    `;
export const PortfolioSliceRebalanceButtonFragmentDoc = gql`
    fragment PortfolioSliceRebalanceButton on PortfolioSlice {
  id
  to {
    type
    name
  }
  rebalanceStatus {
    ...PortfolioSliceRebalanceStatus
  }
}
    ${PortfolioSliceRebalanceStatusFragmentDoc}`;
export const PortfolioSliceEditButtonFragmentDoc = gql`
    fragment PortfolioSliceEditButton on PortfolioSlice {
  id
  to {
    id
  }
}
    `;
export const PortfolioSliceDeallocatedChildrenSectionFragmentDoc = gql`
    fragment PortfolioSliceDeallocatedChildrenSection on PortfolioSlice {
  deallocatedChildren {
    to {
      id
      ...SliceableCell
    }
    value {
      total
    }
  }
}
    ${SliceableCellFragmentDoc}`;
export const PortfolioToPieFragmentDoc = gql`
    fragment PortfolioToPie on PortfolioSlice {
  to {
    ... on UserPie {
      description
    }
    ... on SystemPie {
      description
    }
  }
  ...PortfolioSliceDeallocatedChildrenSection
}
    ${PortfolioSliceDeallocatedChildrenSectionFragmentDoc}`;
export const SecurityCurrentQuoteDailyChangeFragmentDoc = gql`
    fragment SecurityCurrentQuoteDailyChange on Security {
  id
  latestQuote {
    changeFromPreviousClosePrice
    percentChangeFromPreviousClosePrice
  }
}
    `;
export const SecurityCurrentQuoteLastTradeTimeFragmentDoc = gql`
    fragment SecurityCurrentQuoteLastTradeTime on Security {
  id
  latestQuote {
    asOfTime
    lastTradeTime
    localAsOfTime
    localLastTradeTime
    tradeVolume
  }
}
    `;
export const SecurityCurrentQuotePriceFragmentDoc = gql`
    fragment SecurityCurrentQuotePrice on Security {
  id
  latestQuote {
    lastPrice
  }
}
    `;
export const SecurityMetricsFragmentDoc = gql`
    fragment SecurityMetrics on Security {
  fundamentals {
    marketCap
    peRatio
    dividendYield
  }
  ... on Fund {
    fundFundamentals {
      totalAssets
      netExpenseRatio
    }
  }
  ... on CryptoAsset {
    marketCap
    circulatingSupply
    volume
  }
}
    `;
export const SecurityDetailContainerFragmentDoc = gql`
    fragment SecurityDetailContainer on Security {
  symbol
  latestQuote {
    asOfTime
    lastTradeTime
    lastPrice
    changeFromPreviousClosePrice
    percentChangeFromPreviousClosePrice
  }
  ...SecurityCurrentQuoteDailyChange
  ...SecurityCurrentQuoteLastTradeTime
  ...SecurityCurrentQuotePrice
  ...SecurityMetrics
}
    ${SecurityCurrentQuoteDailyChangeFragmentDoc}
${SecurityCurrentQuoteLastTradeTimeFragmentDoc}
${SecurityCurrentQuotePriceFragmentDoc}
${SecurityMetricsFragmentDoc}`;
export const PortfolioToSecurityFragmentDoc = gql`
    fragment PortfolioToSecurity on PortfolioSlice {
  id
  to {
    id
    ...SecurityDetailContainer
  }
  investments {
    allocatedPositions(first: 100) {
      edges {
        node {
          allocation
          allocationPercent
          position {
            quantity
            cost {
              cost
              averageSharePrice
            }
          }
        }
      }
    }
  }
}
    ${SecurityDetailContainerFragmentDoc}`;
export const PortfolioSliceTargetActualFragmentDoc = gql`
    fragment PortfolioSliceTargetActual on ChildPortfolioSlice {
  percentage
  portionOfParentValuePercent
}
    `;
export const PortfolioSliceChildrenPerformanceFragmentDoc = gql`
    fragment PortfolioSliceChildrenPerformance on PortfolioSlice {
  __typename
  id
  children {
    id
    percentage
    to {
      __typename
      id
      ...SliceableCell
    }
    value {
      hasValue
      total
    }
    performance(period: $period) {
      totalGain
      moneyWeightedRateOfReturn
    }
    ...SliceAvatarStatus
    ...PortfolioSliceTargetActual
  }
}
    ${SliceableCellFragmentDoc}
${SliceAvatarStatusFragmentDoc}
${PortfolioSliceTargetActualFragmentDoc}`;
export const PortfolioSliceOrderLinkFragmentDoc = gql`
    fragment PortfolioSliceOrderLink on PortfolioSlice {
  id
  orderStatus {
    isLocked
    isSetOnSelf
    lockReason
  }
}
    `;
export const PortfolioLinkFragmentDoc = gql`
    fragment PortfolioLink on ChildPortfolioSlice {
  id
  value {
    total
  }
  ancestors {
    to {
      name
    }
  }
  percentage
  ...PortfolioSliceOrderLink
}
    ${PortfolioSliceOrderLinkFragmentDoc}`;
export const PositionDetailsFragmentDoc = gql`
    fragment PositionDetails on Position {
  cost {
    averageSharePrice
    cost
  }
  investAccount {
    id
    borrowAccount {
      status {
        hasOpenMaintenanceCall
      }
    }
  }
  marginability {
    isMarginable
    maintenanceEquityRequirement
    maintenanceEquityRequirementPercent
  }
  positionSecurity {
    symbol
    security {
      __typename
      id
      portfolioLinks {
        ...PortfolioLink
      }
      ... on Sliceable {
        ...SliceableCell
        __typename
        id
        type
        name
        ... on Security {
          symbol
          profile {
            logoUrl
          }
        }
        ... on SystemPie {
          key
          logoUrl
          categorizationDetails {
            logoUrl
          }
        }
        ... on UserPie {
          portfolioLinks {
            isRootSlice
          }
        }
        isActive
      }
    }
  }
  fullyPaidLending {
    quantity
  }
  quantity
  unrealizedGain {
    gain
    gainPercent
  }
  value {
    value
  }
}
    ${PortfolioLinkFragmentDoc}
${SliceableCellFragmentDoc}`;
export const HistoricalQuotesNodeFragmentDoc = gql`
    fragment HistoricalQuotesNode on Security {
  id
  intradayQuotes(period: $period, interval: $interval) {
    series {
      time
      data {
        percentChangeFromPreviousClosePrice
      }
    }
    timeSpan {
      firstOpenTime
      lastCloseTime
    }
    lastUpdatedTime
  }
}
    `;
export const InvestAccountModalAccountNodeFragmentDoc = gql`
    fragment InvestAccountModalAccountNode on Account {
  id
  name
  number
  registration
  rootPortfolioSlice {
    to {
      id
      slices {
        to {
          id
          __typename
          ... on CryptoAsset {
            legacyId
          }
        }
      }
    }
  }
}
    `;
export const InvestAccountModalSliceableNodeFragmentDoc = gql`
    fragment InvestAccountModalSliceableNode on Sliceable {
  id
  __typename
  name
  type
  ... on CryptoAsset {
    legacyId
  }
  ... on Pie {
    containsCrypto
  }
}
    `;
export const CryptoAccountCurrenciesFragmentDoc = gql`
    fragment CryptoAccountCurrencies on PortfolioSlice {
  currencies: children {
    to {
      name
    }
  }
}
    `;
export const UnmanagedHoldingSummaryCardFragmentDoc = gql`
    fragment UnmanagedHoldingSummaryCard on UnmanagedHoldingsScreen {
  learnMore {
    ...Linkable
  }
  title
  unmanagedHoldingsScreenCard {
    quantitySection {
      label
      value
    }
    totalValueSection {
      label
      value
    }
    unrealizedGainSection {
      label
      amount
      percent
      tooltip {
        text
      }
    }
  }
}
    ${LinkableFragmentDoc}`;
export const UnmanagedHoldingFragmentDoc = gql`
    fragment UnmanagedHolding on UnmanagedHolding {
  id
  label
  sublabel
  value
  isPendingLiquidation
  quantity
  unmanagedHoldingPills {
    ...AppPill
  }
  security {
    profile {
      logoUrl
    }
  }
  allocationDate {
    label
    value
  }
}
    ${AppPillFragmentDoc}`;
export const CollectionHoldingsFragmentDoc = gql`
    fragment CollectionHoldings on UnmanagedHoldingsConnection {
  total
  pageInfo {
    ...PageInfo
  }
  edges {
    cursor
    node {
      ...UnmanagedHolding
    }
  }
}
    ${PageInfoFragmentDoc}
${UnmanagedHoldingFragmentDoc}`;
export const CollectionFragmentDoc = gql`
    fragment Collection on UnmanagedHoldingsCollection {
  id
  type
  holdings(first: $first, after: $after) {
    ...CollectionHoldings
  }
  header
  tooltip {
    text
  }
}
    ${CollectionHoldingsFragmentDoc}`;
export const MoveDrawerRowFragmentDoc = gql`
    fragment MoveDrawerRow on MoveSecuritiesConfirmTargets {
  title
  header
  link {
    title
    url
  }
  saveTargetsCtaLabel
}
    `;
export const MoveDrawerRowsListFragmentDoc = gql`
    fragment MoveDrawerRowsList on ReceivedSecurities {
  moveSecurities {
    validPortfolioSliceDestinations {
      id
      to {
        id
        name
        portfolioLinks {
          __typename
          isRootSlice
          to {
            id
            name
          }
          ... on ChildPortfolioSlice {
            ancestors {
              to {
                id
                name
              }
            }
          }
        }
        type
      }
    }
  }
  moveSecuritiesConfirmTargets {
    ...MoveDrawerRow
  }
}
    ${MoveDrawerRowFragmentDoc}`;
export const UnmanagedHoldingsMoveDrawerFragmentDoc = gql`
    fragment UnmanagedHoldingsMoveDrawer on ReceivedSecurities {
  moveSecurities {
    title
    header
    subheader
    tooltip {
      text
    }
  }
  ...MoveDrawerRowsList
}
    ${MoveDrawerRowsListFragmentDoc}`;
export const UnmanagedHoldingsSectionControlsAccountNodeFragmentDoc = gql`
    fragment UnmanagedHoldingsSectionControlsAccountNode on Account {
  number
  receivedSecurities {
    unmanagedHoldingsScreen {
      contactUs {
        ...Linkable
      }
    }
    ...UnmanagedHoldingsMoveDrawer
  }
}
    ${LinkableFragmentDoc}
${UnmanagedHoldingsMoveDrawerFragmentDoc}`;
export const ValidSliceDestinationFragmentDoc = gql`
    fragment ValidSliceDestination on PortfolioSlice {
  id
  to {
    id
    name
  }
}
    `;
export const MoveSecuritiesConfirmTargetsFragmentDoc = gql`
    fragment MoveSecuritiesConfirmTargets on MoveSecuritiesConfirmTargets {
  title
  header
  link {
    title
    url
  }
  saveTargetsCtaLabel
}
    `;
export const MarginBillDueDetailsFragmentDoc = gql`
    fragment MarginBillDueDetails on BorrowBill {
  amount
  dueDate
  startDate
  endDate
  isDue
  rateDescription
}
    `;
export const MarginDetailFragmentDoc = gql`
    fragment MarginDetail on MarginDetail {
  label
  labelValue
  numericValue
  backgroundColor
  foregroundColor
  tooltip {
    ...AppTooltip
  }
}
    ${AppTooltipFragmentDoc}`;
export const MarginAvailableToBorrowFragmentDoc = gql`
    fragment MarginAvailableToBorrow on MarginAvailableToBorrow {
  title
  investableMaxDetail {
    ...MarginDetail
  }
  withdrawableMaxDetail {
    ...MarginDetail
  }
  additionalDetails {
    title
    description {
      ...RichText
    }
    investableMaxDetail {
      ...MarginDetail
    }
    withdrawableMaxDetail {
      ...MarginDetail
    }
  }
  currentBalanceDetail {
    ...MarginDetail
  }
  breakdown {
    ...RichText
  }
}
    ${MarginDetailFragmentDoc}
${RichTextFragmentDoc}`;
export const InformationBannerFragmentDoc = gql`
    fragment InformationBanner on InformationBanner {
  label
  kind
  link {
    ...Linkable
  }
  button {
    ...AppButton
  }
  icon {
    ...AppImage
  }
}
    ${LinkableFragmentDoc}
${AppButtonFragmentDoc}
${AppImageFragmentDoc}`;
export const MarginHealthFragmentDoc = gql`
    fragment MarginHealth on MarginHealth {
  title
  thresholds {
    thresholdMin
    thresholdMax
    color
  }
  viewHoldingsButton {
    ...AppButton
  }
  learnMoreLink {
    ...Linkable
  }
  currentScore {
    ...MarginDetail
  }
  breakdown {
    ...RichText
  }
  banner {
    ...InformationBanner
  }
}
    ${AppButtonFragmentDoc}
${LinkableFragmentDoc}
${MarginDetailFragmentDoc}
${RichTextFragmentDoc}
${InformationBannerFragmentDoc}`;
export const ListItemFragmentDoc = gql`
    fragment ListItem on ListItem {
  disabled
  analyticsEventClick {
    ...AnalyticsEvent
  }
  value
  header
  icon {
    ...AppImage
  }
  subheader
  subheaderItems
  description
  headerRight
  link {
    ...AppLink
  }
  pill {
    ...AppPill
  }
  size
}
    ${AnalyticsEventFragmentDoc}
${AppImageFragmentDoc}
${AppLinkFragmentDoc}
${AppPillFragmentDoc}`;
export const MarginLoanInterestFragmentDoc = gql`
    fragment MarginLoanInterest on MarginLoanInterest {
  listItems {
    ...ListItem
  }
  title
}
    ${ListItemFragmentDoc}`;
export const FinancialInformationDueDiligenceFragmentDoc = gql`
    fragment FinancialInformationDueDiligence on Onboarding {
  dueDiligence {
    occupationDropdownOptions {
      label
    }
  }
}
    `;
export const PoliticalDisclosureFragmentDoc = gql`
    fragment PoliticalDisclosure on PoliticalExposureDisclosure {
  isPoliticallyExposed
  politicalOrganization
  immediateFamilyMembers
}
    `;
export const AccountProfileEmploymentFragmentDoc = gql`
    fragment AccountProfileEmployment on AccountHolder {
  employmentInfo {
    employer
    occupation
    status
  }
}
    `;
export const FinancialInformationProfileFragmentDoc = gql`
    fragment FinancialInformationProfile on Profile {
  primary {
    exchangeOrFinraAffiliationDisclosure {
      isAffiliated
      firmName
    }
    controlPersonDisclosure {
      isControlPerson
      companySymbols
    }
    politicalDisclosure {
      ...PoliticalDisclosure
    }
    backupWithholding {
      isSubjectToBackupWithholding
    }
    ...AccountProfileEmployment
  }
  suitability {
    annualIncomeAmount
    totalNetWorth
    liquidNetWorth
  }
}
    ${PoliticalDisclosureFragmentDoc}
${AccountProfileEmploymentFragmentDoc}`;
export const IdentityFirstConfirmationProfileFragmentDoc = gql`
    fragment IdentityFirstConfirmationProfile on Profile {
  primary {
    firstName
    middleInitial
    lastName
    suffix
    dateOfBirth
    countryOfCitizenship
    homeAddress {
      lineOne
      lineTwo
      city
      stateOrProvince
      postalCode
      country
    }
    phoneNumber
  }
  trustedContact {
    firstName
    lastName
    email
    phoneNumber
  }
}
    `;
export const IdentityFirstConfirmationUserFragmentDoc = gql`
    fragment IdentityFirstConfirmationUser on User {
  username
  hasOnboarded
  hasProduct
}
    `;
export const IdentityFirstConfirmationScreenRequiredDocumentsFragmentDoc = gql`
    fragment IdentityFirstConfirmationScreenRequiredDocuments on RequiredDocumentsBundle {
  documents {
    title
    url
  }
  preamble
  postamble
}
    `;
export const PersonalLoanGraphDeatilsFragmentDoc = gql`
    fragment PersonalLoanGraphDeatils on PersonalLoanLandingPageGraphDetails {
  title
  percentage
  description
  tooltip {
    ...AppTooltip
  }
}
    ${AppTooltipFragmentDoc}`;
export const BillingMethodSectionFragmentDoc = gql`
    fragment BillingMethodSection on PlatformBillingMethodSection {
  title
  subtitle
  titleIcon {
    ...AppImage
  }
  preferredPaymentMethodId
  links {
    ...AppLink
  }
  disclosures {
    linkText
    paragraphs {
      ...RichText
    }
  }
  billingMethods {
    list {
      ...TransferParticipant
    }
  }
}
    ${AppImageFragmentDoc}
${AppLinkFragmentDoc}
${RichTextFragmentDoc}
${TransferParticipantFragmentDoc}`;
export const PlatformBillingEntryFragmentDoc = gql`
    fragment PlatformBillingEntry on PlatformBillingEntry {
  id
  listItem {
    ...ListItem
  }
}
    ${ListItemFragmentDoc}`;
export const PlatformBillingHistorySectionFragmentDoc = gql`
    fragment PlatformBillingHistorySection on PlatformBillingHistorySection {
  title
  titleIcon {
    ...AppImage
  }
  billingHistory(first: $first, after: $after) {
    pageInfo {
      ...PageInfo
    }
    edges {
      cursor
      node {
        ...PlatformBillingEntry
      }
    }
  }
  billingHistoryFallbackCopy
}
    ${AppImageFragmentDoc}
${PageInfoFragmentDoc}
${PlatformBillingEntryFragmentDoc}`;
export const MovesSlicesDestinationRowFragmentDoc = gql`
    fragment MovesSlicesDestinationRow on MoveSlicesAugmentedPortfolioSliceDestinations {
  validity {
    isValid
    message
  }
  portfolioSlice {
    id
    to {
      id
      name
    }
    ... on ChildPortfolioSlice {
      ancestors {
        to {
          id
          name
        }
      }
    }
  }
}
    `;
export const MoveSlicesDestinationMenuFragmentDoc = gql`
    fragment MoveSlicesDestinationMenu on MoveSlicesMoveToPieContent {
  primaryLabel
  secondaryLabel
  tooltip {
    text
  }
  tutorialLabel
  validAugmentedPortfolioSliceDestinations {
    portfolioSlice {
      id
    }
    ...MovesSlicesDestinationRow
  }
}
    ${MovesSlicesDestinationRowFragmentDoc}`;
export const TutorialModalFragmentDoc = gql`
    fragment TutorialModal on MoveSlices {
  moveSlicesLabel
  moveSlicesTutorialContent {
    header
    items {
      icon {
        ...AppImage
      }
      copy
    }
    learnMore {
      ...Linkable
    }
    exitLabel
  }
}
    ${AppImageFragmentDoc}
${LinkableFragmentDoc}`;
export const MoveSlicesButtonFragmentDoc = gql`
    fragment MoveSlicesButton on MoveSlices {
  moveSlicesLabel
  enableMoveSlices
  moveToPieContent(portfolioSliceIdsToMove: $portfolioSliceIdsToMove) {
    ...MoveSlicesDestinationMenu
  }
  ...TutorialModal
}
    ${MoveSlicesDestinationMenuFragmentDoc}
${TutorialModalFragmentDoc}`;
export const EditSlicesButtonGroupFragmentDoc = gql`
    fragment EditSlicesButtonGroup on MoveSlices {
  enableMoveSlices
  moveDisabledTradingWindowTooltip {
    text
  }
  moveDisabledDestinationPieSet {
    text
  }
  ...MoveSlicesButton
}
    ${MoveSlicesButtonFragmentDoc}`;
export const DestinationPieAccountNodeFragmentDoc = gql`
    fragment DestinationPieAccountNode on Account {
  moveSlices {
    setDestinationSliceContent {
      header
      subheader
      tooltip {
        text
      }
    }
    ...EditSlicesButtonGroup
  }
}
    ${EditSlicesButtonGroupFragmentDoc}`;
export const EditPortfolioAccountNodeFragmentDoc = gql`
    fragment EditPortfolioAccountNode on Account {
  moveSlices {
    ...EditSlicesButtonGroup
  }
}
    ${EditSlicesButtonGroupFragmentDoc}`;
export const PortfolioNameFragmentDoc = gql`
    fragment PortfolioName on PortfolioSlice {
  id
  to {
    name
  }
}
    `;
export const MoveSlicesButtonRefetchAccountNodeFragmentDoc = gql`
    fragment MoveSlicesButtonRefetchAccountNode on Account {
  moveSlices {
    ...EditSlicesButtonGroup
  }
}
    ${EditSlicesButtonGroupFragmentDoc}`;
export const NextAvailableTradeWindowSectionFragmentDoc = gql`
    fragment NextAvailableTradeWindowSection on MoveSlicesChangesConfirmationNextAvailableTradeWindowSection {
  label
  trading {
    nextTradingTime
    nextWindowForAccount
  }
}
    `;
export const DestinationPieChangesSectionFragmentDoc = gql`
    fragment DestinationPieChangesSection on MoveSlicesChangesConfirmationDestinationPiesChangesSection {
  label
  applicableLocationsMessage
  adjustedSlices {
    name
    symbol
    oldPercentage
    percentage
    type
    actionPill {
      ...AppPill
    }
    logoUrl
  }
}
    ${AppPillFragmentDoc}`;
export const SourcePieChangesSectionFragmentDoc = gql`
    fragment SourcePieChangesSection on MoveSlicesChangesConfirmationSourcePieChangesSection {
  label
  applicableLocationsMessage
  adjustedSlices {
    name
    symbol
    oldPercentage
    percentage
    type
    actionPill {
      ...AppPill
    }
    logoUrl
  }
}
    ${AppPillFragmentDoc}`;
export const MyPieFragmentDoc = gql`
    fragment MyPie on UserPieEdge {
  node {
    id
    portfolioLinks {
      id
      account {
        name
      }
    }
    name
    containsCrypto
    created
    description
    analysis {
      totalHoldings
    }
    ...Sliceable
  }
}
    ${SliceableFragmentDoc}`;
export const OrderStatusFragmentDoc = gql`
    fragment OrderStatus on PortfolioSlice {
  orderStatus {
    isLocked
    lockReason
  }
}
    `;
export const PieAnalysisFragmentDoc = gql`
    fragment PieAnalysis on PieAnalysis {
  backtesting(period: $period) {
    startTime
    valuePercentChange
    series {
      time
      value
    }
  }
  totalHoldings
  dividendYield
  netExpenseRatio
}
    `;
export const PieDetailsNodeFragmentDoc = gql`
    fragment PieDetailsNode on Pie {
  __typename
  id
  name
  description
  disclosure
  slices {
    to {
      type
      id
      name
    }
    percentage
  }
  portfolioLinks @include(if: $showPortfolioLinks) {
    id
    isRootSlice
    __typename
    ... on ChildPortfolioSlice {
      ancestors {
        to {
          id
          name
        }
      }
    }
  }
  analysis {
    ...PieAnalysis
  }
  isOwnPie
  ... on SystemPie {
    key
    methodology
    categorizationDetails {
      name
    }
    performance {
      riskLevel
      returnYearToDatePercent
      returnOneYearPercent
      returnThreeYearPercent
      returnFiveYearPercent
    }
    featureUrl(width: 1200)
  }
  ...Sliceable
}
    ${PieAnalysisFragmentDoc}
${SliceableFragmentDoc}`;
export const AccountBannerFragmentDoc = gql`
    fragment AccountBanner on Account {
  isRejected
  hasBeneficiaryReachedAgeOfMaturity
  isTradingPausedWithAcat
  informationalBanner {
    ...InformationBanner
  }
}
    ${InformationBannerFragmentDoc}`;
export const DividendTopEarnerFragmentDoc = gql`
    fragment DividendTopEarner on DividendTopEarner {
  amount
  securityRef {
    descriptor
    security {
      name
      profile {
        logoUrl
      }
      symbol
    }
  }
}
    `;
export const DividendTablePositionFragmentDoc = gql`
    fragment DividendTablePosition on Position {
  id
  dividends {
    averageCost
    dividendYield
    dividendFrequency
    yieldOnCost
    lastTwelveMonthsPaid
  }
  positionSecurity {
    symbol
    security {
      ...SliceableCell
    }
  }
}
    ${SliceableCellFragmentDoc}`;
export const DividendTableUpcomingDividendFragmentDoc = gql`
    fragment DividendTableUpcomingDividend on UpcomingDividend {
  security {
    ...SliceableCell
  }
  payDate
  exDividendDate
  numOfShares
  dividendPerShare
  paymentAmount
  dividendStatus {
    ...AppPill
  }
}
    ${SliceableCellFragmentDoc}
${AppPillFragmentDoc}`;
export const PortfolioPageAccountNodeFragmentDoc = gql`
    fragment PortfolioPageAccountNode on Account {
  hasPortfolio
  rootPortfolioSlice {
    id
    account {
      name
    }
  }
  id
  setPortfolioOptions {
    starterPieSlice {
      title
      description
      pie {
        id
        name
        description
        summary
      }
    }
    shouldOfferUserPies
  }
  setupChecklist {
    ...SetupChecklist
  }
}
    ${SetupChecklistFragmentDoc}`;
export const PortfolioPageViewerFragmentDoc = gql`
    fragment PortfolioPageViewer on Viewer {
  announcements {
    forInvest {
      ...Announcement
    }
  }
}
    ${AnnouncementFragmentDoc}`;
export const TradingStatusFragmentDoc = gql`
    fragment TradingStatus on AccountTradingStatus {
  hasAccessToNextAfternoonWindow
  hasAccessToAndCanTradeInNextAfternoonWindow
  id
  inProgressWindowForAccount
  isInVendorMaintenanceWindow
  maxOnDemandTradeWindows
  minAccountValueForMultipleDailyWindows
  nextWindowForAccount
  nextMorningWindow {
    type
    startTime
  }
  nextAfternoonWindow {
    type
    startTime
  }
  nextOnDemandWindow {
    type
    startTime
  }
  onDemandTradeWindowsLeft
}
    `;
export const AccountOrdersTableFragmentDoc = gql`
    fragment AccountOrdersTable on Account {
  portfolioSlicesWithBuyOrders {
    id
    to {
      ...SliceableCell
    }
    orderStatus {
      order {
        cashFlow
      }
    }
  }
  portfolioSlicesWithSellOrders {
    id
    to {
      ...SliceableCell
    }
    orderStatus {
      order {
        cashFlow
      }
    }
  }
}
    ${SliceableCellFragmentDoc}`;
export const AccountRebalancesTableFragmentDoc = gql`
    fragment AccountRebalancesTable on Account {
  portfolioSlicesWithRebalances {
    id
    to {
      ...SliceableCell
    }
  }
}
    ${SliceableCellFragmentDoc}`;
export const PortfolioTradingFragmentDoc = gql`
    fragment PortfolioTrading on Account {
  id
  preferredTradeWindow
  portfolioSlicesWithBuyOrders {
    id
  }
  portfolioSlicesWithSellOrders {
    id
  }
  portfolioSlicesWithRebalances {
    id
  }
  isCryptoAccount
  estimatedTrading {
    hasExcludedBuys
    buys {
      total
    }
    excludedBuys {
      total
    }
    sells {
      total
    }
    totalBuyAmount
    totalSellAmount
    message {
      content
      link {
        ...Linkable
      }
    }
  }
  trading {
    ...TradingStatus
    isInProgress
    onDemandTradingRestrictionReason
  }
  ...AccountOrdersTable
  ...AccountRebalancesTable
}
    ${LinkableFragmentDoc}
${TradingStatusFragmentDoc}
${AccountOrdersTableFragmentDoc}
${AccountRebalancesTableFragmentDoc}`;
export const SetOrderPreloadFragmentDoc = gql`
    fragment SetOrderPreload on PortfolioSlice {
  id
  account {
    imminentPendingCashFlowForOrders
    balance {
      cash {
        available
      }
    }
    maxCashThreshold
    isCryptoAccount
  }
  to {
    name
    ... on Security {
      status
    }
  }
  value {
    total
  }
  orderStatus {
    isLocked
    lockReason
    isSetOnSelf
    netDescendantBuyOrderAmount
    netDescendantSellOrderAmount
    order {
      cashFlow
    }
  }
  ... on ChildPortfolioSlice {
    rootAncestor {
      orderStatus {
        netDescendantBuyOrderAmount
        netDescendantSellOrderAmount
        order {
          cashFlow
        }
      }
    }
  }
}
    `;
export const MarginBreakdownDetailFragmentDoc = gql`
    fragment MarginBreakdownDetail on BreakdownDetail {
  label
  labelValue
  numericValue
  tooltip {
    ...AppTooltip
  }
}
    ${AppTooltipFragmentDoc}`;
export const ManualOrderBreakdownFragmentDoc = gql`
    fragment ManualOrderBreakdown on ManualOrderBreakdown {
  slice {
    ...MarginBreakdownDetail
  }
  cashBuyingPower {
    ...MarginBreakdownDetail
  }
  marginBuyingPower {
    ...MarginBreakdownDetail
  }
  totalBuyingPower {
    ...MarginBreakdownDetail
  }
  tradeWindow {
    ...MarginBreakdownDetail
  }
}
    ${MarginBreakdownDetailFragmentDoc}`;
export const SetupOrderStepPortfolioSliceFragmentDoc = gql`
    fragment SetupOrderStepPortfolioSlice on PortfolioSlice {
  __typename
  account {
    trading {
      nextWindowForAccount
    }
    isCryptoAccount
    borrowAccount {
      status {
        hasOpenMaintenanceCall
      }
      rate {
        ratePercent
      }
    }
    maxCashThreshold
  }
  investments {
    allocatedPositions(first: 100) {
      edges {
        node {
          position {
            id
            marginability {
              maintenanceEquityRequirementReductionBySelling(sellAmount: $sellAmount)
            }
          }
        }
      }
    }
  }
  to {
    ... on Security {
      status
    }
  }
  value {
    total
  }
  manualOrderBreakdown {
    ...ManualOrderBreakdown
  }
}
    ${ManualOrderBreakdownFragmentDoc}`;
export const SourcePieAccountNodeFragmentDoc = gql`
    fragment SourcePieAccountNode on Account {
  moveSlices {
    setSourceSliceContent {
      copy
      tooltip {
        text
      }
    }
    ...EditSlicesButtonGroup
  }
}
    ${EditSlicesButtonGroupFragmentDoc}`;
export const ProfileDisclosureDetailsFragmentDoc = gql`
    fragment ProfileDisclosureDetails on AccountHolder {
  backupWithholding {
    isSubjectToBackupWithholding
  }
  controlPersonDisclosure {
    companySymbols
    isControlPerson
  }
  exchangeOrFinraAffiliationDisclosure {
    firmName
    isAffiliated
  }
  politicalDisclosure {
    ...PoliticalDisclosure
  }
}
    ${PoliticalDisclosureFragmentDoc}`;
export const DisclosuresProfilePageAccountsFragmentDoc = gql`
    fragment DisclosuresProfilePageAccounts on Profile {
  primary {
    ...ProfileDisclosureDetails
  }
  secondary {
    ...ProfileDisclosureDetails
  }
}
    ${ProfileDisclosureDetailsFragmentDoc}`;
export const EmploymentDropdownOptionsFragmentDoc = gql`
    fragment EmploymentDropdownOptions on OnboardingDueDiligence {
  occupationDropdownOptions {
    label
  }
}
    `;
export const BeneficiaryInfoFragmentDoc = gql`
    fragment BeneficiaryInfo on Beneficiary {
  firstName
  lastName
  suffix
  dateOfBirth
  address {
    lineOne
    lineTwo
    city
    stateOrProvince
    postalCode
    country
  }
  relationship
  sharePercentage
  isPrimary
}
    `;
export const HomeAddressFragmentDoc = gql`
    fragment HomeAddress on MailingAddress {
  lineOne
  lineTwo
  city
  stateOrProvince
  postalCode
  country
}
    `;
export const ProfileReviewAccountHolderFragmentDoc = gql`
    fragment ProfileReviewAccountHolder on AccountHolder {
  additionalEmail
  firstName
  lastName
  middleInitial
  suffix
  phoneNumber
  homeAddress {
    ...HomeAddress
  }
}
    ${HomeAddressFragmentDoc}`;
export const AccountProfilesFragmentDoc = gql`
    fragment AccountProfiles on Profile {
  allowsHomeAddressUpdates
  primary {
    ...ProfileReviewAccountHolder
  }
  secondary {
    ...ProfileReviewAccountHolder
  }
}
    ${ProfileReviewAccountHolderFragmentDoc}`;
export const ProfileUpdateBannerFlagsFragmentDoc = gql`
    fragment ProfileUpdateBannerFlags on Profile {
  allowsHomeAddressUpdates
  identityStatus
}
    `;
export const ProfileSuitabilitiesFragmentDoc = gql`
    fragment ProfileSuitabilities on SuitabilityProfile {
  annualIncomeAmount
  totalNetWorth
  liquidNetWorth
  investmentExperience
  riskTolerance
  timeHorizon
  liquidityNeeds
}
    `;
export const ProfileNavigationFragmentDoc = gql`
    fragment ProfileNavigation on Viewer {
  id
  profile {
    allowsBeneficiaries
  }
  crypto {
    id
    isEligible
  }
  credit {
    id
    hasActiveAccount
  }
  promotions {
    promotionsAgreements {
      id
      documents {
        title
      }
    }
  }
}
    `;
export const TrustedContactFieldsFragmentDoc = gql`
    fragment TrustedContactFields on TrustedContact {
  firstName
  lastName
  email
  phoneNumber
}
    `;
export const ScreenerCryptoFragmentDoc = gql`
    fragment ScreenerCrypto on CryptoScreenerEdge {
  node {
    id
    restrictions
    priceChange(period: $period) {
      percentChange
    }
    symbol
    marketCap
    volume
    circulatingSupply
    maxSupply
    quote {
      lastPrice
    }
    restrictionPill {
      ...AppPill
    }
    week52High
    week52Low
    createdDate
    coinType
    developmentStatus
    openSource
    orgStructure
    hashAlgorithm
    whitepaperLink {
      ...AppLink
    }
    whitepaperIcon {
      ...AppImage
    }
    ...Sliceable
  }
}
    ${AppPillFragmentDoc}
${AppLinkFragmentDoc}
${AppImageFragmentDoc}
${SliceableFragmentDoc}`;
export const ScreenerFundFragmentDoc = gql`
    fragment ScreenerFund on FundScreenerEdge {
  node {
    fundamentals {
      marketCap
      peRatio
      dividendYield
    }
    fundFundamentals {
      totalAssets
    }
    ...SecurityRiskLevel
    ...Sliceable
    ... on Security {
      id
      priceChange(period: $period) {
        percentChange
      }
    }
  }
}
    ${SecurityRiskLevelFragmentDoc}
${SliceableFragmentDoc}`;
export const ModelPortfolioScreenerNodeFragmentDoc = gql`
    fragment ModelPortfolioScreenerNode on SystemPie {
  id
  key
  name
  isActive
  systemPieStatus: status
  analysis {
    totalHoldings
    dividendYield
  }
  performance {
    riskGrade
    riskLevel
  }
  ...SliceableLogo
}
    ${SliceableLogoFragmentDoc}`;
export const SecurityNewsArticleFragmentDoc = gql`
    fragment SecurityNewsArticle on News {
  date
  headline
  source
  sourceLogoUrl
  summary
  url
  imageUrl
  newsType
}
    `;
export const SecurityNewsFragmentDoc = gql`
    fragment SecurityNews on Security {
  __typename
  id
  securityNews(first: $first, after: $after) {
    edges {
      node {
        ...SecurityNewsArticle
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${SecurityNewsArticleFragmentDoc}
${PageInfoFragmentDoc}`;
export const ScreenerSecurityFragmentDoc = gql`
    fragment ScreenerSecurity on SecurityScreenerEdge {
  node {
    fundamentals {
      marketCap
      peRatio
      dividendYield
    }
    ...SecurityRiskLevel
    ...Sliceable
    ... on Security {
      id
      priceChange(period: $period) {
        percentChange
      }
    }
  }
}
    ${SecurityRiskLevelFragmentDoc}
${SliceableFragmentDoc}`;
export const WatchlistItemLatestQuoteFragmentDoc = gql`
    fragment WatchlistItemLatestQuote on Quote {
  lastPrice
  changeFromPreviousClosePrice
  percentChangeFromPreviousClosePrice
}
    `;
export const WatchlistItemFragmentDoc = gql`
    fragment WatchlistItem on Sliceable {
  __typename
  id
  name
  isActive
  ... on Security {
    fundamentals {
      marketCap
      peRatio
    }
    latestQuote {
      ...WatchlistItemLatestQuote
    }
    symbol
  }
  type
  ...Sliceable
}
    ${WatchlistItemLatestQuoteFragmentDoc}
${SliceableFragmentDoc}`;
export const ViewerAccountRootSliceNodeFragmentDoc = gql`
    fragment ViewerAccountRootSliceNode on Account {
  id
  name
  registration
  rootPortfolioSlice {
    id
    to {
      id
      slices {
        to {
          id
        }
      }
    }
  }
}
    `;
export const SavingsDisclosureNodeFragmentDoc = gql`
    fragment SavingsDisclosureNode on SavingsDisclosure {
  paragraphs
  linkText
}
    `;
export const SecurityHistoricalQuoteFragmentDoc = gql`
    fragment SecurityHistoricalQuote on Security {
  id
  name
  priceChange(period: $securityPeriod) {
    percentChange
  }
  historicalQuotes(period: $securityPeriod, dailyInChronologicalOrder: true) @include(if: $useSecurityPeriod) {
    date
    closePrice
    previousQuoteClosePrice
    changeInPriceFromPeriodOpen
    percentChangeFromBeginningOfPeriod
    shareVolume
    dividends {
      amount
      type
    }
    split {
      toFactor
      forFactor
    }
  }
  intradayQuotes(
    period: $intradayPeriod
    interval: $intradayInterval
    isCrypto: $isCrypto
  ) @include(if: $useIntradayPeriod) {
    series {
      time
      data {
        closePrice
        previousQuoteClosePrice
        previousClosePrice
        changeFromPreviousClosePrice
        percentChangeFromPreviousClosePrice
        shareVolume
      }
    }
    timeSpan {
      firstOpenTime
      lastCloseTime
    }
  }
}
    `;
export const AccountStockLoanGroupsFragmentDoc = gql`
    fragment AccountStockLoanGroups on AccountStockLoanHoldingGroup {
  header
  emptyGroupMessage
  stockLoans {
    quantity
    securityReference {
      descriptor
      security {
        name
        profile {
          logoUrl
        }
        symbol
      }
    }
  }
}
    `;
export const SharePieHeaderPropsFragmentDoc = gql`
    fragment SharePieHeaderProps on SharePieLandingPageHeader {
  text
  icon {
    ...AppImage
  }
  ctaButton {
    ...AppButton
  }
}
    ${AppImageFragmentDoc}
${AppButtonFragmentDoc}`;
export const SharePieMarketingSectionPropsFragmentDoc = gql`
    fragment SharePieMarketingSectionProps on SharePieMarketingDetails {
  title
  marketingContent {
    title
    description
    illustration {
      ...AppImage
    }
  }
}
    ${AppImageFragmentDoc}`;
export const SharePieAccountSectionPropsFragmentDoc = gql`
    fragment SharePieAccountSectionProps on SharePieLogInBanner {
  title
  description
  primaryCTA {
    ...AppButton
  }
  logInCTA {
    ...AppButton
  }
  createAccountDisclosure {
    ...RichText
  }
  illustration {
    ...AppImage
  }
}
    ${AppButtonFragmentDoc}
${RichTextFragmentDoc}
${AppImageFragmentDoc}`;
export const SavingsAccountSettingsFragmentDoc = gql`
    fragment SavingsAccountSettings on Savings {
  savingsAccounts {
    edges {
      node {
        status {
          description
        }
        descriptor
        name
        id
        accountNumber
        accountType
      }
    }
  }
}
    `;
export const PersonalLoansAccountSettingsFragmentDoc = gql`
    fragment PersonalLoansAccountSettings on Borrow {
  personalLoans {
    loans(filterByStatus: [ACTIVE, CLOSED]) {
      edges {
        node {
          id
          name
          descriptor
          status
        }
      }
    }
  }
}
    `;
export const InvestAccountSettingsFragmentDoc = gql`
    fragment InvestAccountSettings on Viewer {
  accounts(first: 50, filterStatus: []) {
    edges {
      node {
        id
        hasPortfolio
        registration
        status
        name
        number
        originator
      }
    }
  }
}
    `;
export const SpendActivityEntryDetailsCardFragmentDoc = gql`
    fragment SpendActivityEntryDetailsCard on SpendActivityEntryDetails {
  id
  title
  amount
  disputeDetails {
    id
    viaDetailsCtaLabel
  }
  isEligibleForCashBack
  isReversalOrReturn
  additionalDetails {
    label
    value
    pill {
      ...AppPill
    }
  }
  colorizedTransactionRewardTier {
    kind
    label
    icon
  }
  additionalLinks {
    ...RichText
  }
  supplementaryMessage {
    content
    link {
      ...Linkable
    }
  }
  categoryDetails {
    ... on SpendActivityEntryCategoryDetails {
      subtitle
      title
      icon {
        ...AppImage
      }
    }
  }
  status {
    ...AppPill
  }
}
    ${AppPillFragmentDoc}
${RichTextFragmentDoc}
${LinkableFragmentDoc}
${AppImageFragmentDoc}`;
export const OnDemandTradeFragmentDoc = gql`
    fragment OnDemandTrade on OnDemandAllocatedTrade {
  side
  status
  statusCopy
  of {
    ...SliceableCell
    symbol
    name
    profile {
      logoUrl
    }
    ... on Sliceable {
      __typename
      id
      type
      name
      ... on Security {
        symbol
      }
      ... on Sliceable {
        __typename
        id
        name
        ... on Security {
          symbol
          profile {
            logoUrl
          }
        }
        ... on SystemPie {
          key
          logoUrl
          categorizationDetails {
            logoUrl
          }
        }
        ... on UserPie {
          portfolioLinks {
            isRootSlice
          }
        }
        isActive
      }
    }
  }
  note
  statusPill {
    ...AppPill
  }
  allocatedQuantity
  allocatedPrice
  allocatedAmount
}
    ${SliceableCellFragmentDoc}
${AppPillFragmentDoc}`;
export const FundingSourceNodeFragmentDoc = gql`
    fragment FundingSourceNode on FundingSourceAccount {
  id
  status
  externalName
  createdAt
  updatedAt
  accountNumber
  verificationType
}
    `;
export const TransferRuleDetailFragmentDoc = gql`
    fragment TransferRuleDetail on TransferRule {
  __typename
  id
  isEnabled
  ... on ScheduledTransferRule {
    from {
      transferParticipantName
    }
    to {
      transferParticipantName
      transferParticipantType
    }
    latestInstance {
      id
      startDate
      amount
    }
    amount
    memo
    sendCheckRecipient {
      id
      lineOne
      lineTwo
    }
    additionalDetails {
      label
      values
    }
    isCreditCardAutoPay
    isPersonalLoanAutoPay
    schedulePresetIndicator
    schedule {
      __typename
      title
      description
      ... on MonthlySchedule {
        dayOfMonth
      }
      ... on WeekOfMonthSchedule {
        weekOfMonth
        dayOfWeek
      }
      ... on BiweeklySchedule {
        dayOfWeek
        isEvenWeeks
      }
      ... on WeeklySchedule {
        dayOfWeek
      }
    }
  }
}
    `;
export const NewSmartTransferFocusOptionSetFragmentDoc = gql`
    fragment NewSmartTransferFocusOptionSet on NewSmartTransferFocusOptionSet {
  options {
    description
    disabledReasonDescription
    id
    isEnabled
    name
    utilizationSummary
  }
  optionsFooter
}
    `;
export const NiaDisclaimerFragmentDoc = gql`
    fragment NiaDisclaimer on TransfersDisclaimer {
  text
  tooltip {
    text
    icon {
      ...AppImage
    }
  }
}
    ${AppImageFragmentDoc}`;
export const PendingLiquidationUnmanagedHoldingFragmentDoc = gql`
    fragment PendingLiquidationUnmanagedHolding on UnmanagedHolding {
  id
  liquidationPendingDetailsScreen {
    header
    details {
      label
      value
    }
    primaryButtonLabel
  }
  trading {
    isInProgress
  }
}
    `;
export const ScheduledTransferRuleNodeFragmentDoc = gql`
    fragment ScheduledTransferRuleNode on ScheduledTransferRule {
  id
  from {
    id
  }
  to {
    id
    transferParticipantType
  }
  amount
  iraContributionYear
  iraDistributionReason
  isCreditCardAutoPay
  isPersonalLoanAutoPay
  schedulePresetIndicator
  schedule {
    __typename
    title
    description
    ... on MonthlySchedule {
      dayOfMonth
    }
    ... on WeekOfMonthSchedule {
      weekOfMonth
      dayOfWeek
    }
    ... on BiweeklySchedule {
      dayOfWeek
      isEvenWeeks
    }
    ... on WeeklySchedule {
      dayOfWeek
    }
  }
}
    `;
export const TransferDetailsMessageTooltipBodyFragmentDoc = gql`
    fragment TransferDetailsMessageTooltipBody on TransferDetailsMessageTooltipBody {
  title
  subtitle
  numberedList
}
    `;
export const TransferDetailsMessageCardFragmentDoc = gql`
    fragment TransferDetailsMessageCard on TransferDetailsMessage {
  title
  titleTag
  icon {
    ...AppImage
  }
  items {
    title
    value
    description
  }
  descriptionTooltip {
    icon {
      ...AppImage
    }
    tooltipBody {
      ...TransferDetailsMessageTooltipBody
    }
  }
  description
  link {
    ...Linkable
  }
  displayAnalyticsEvent {
    ...AnalyticsEvent
  }
  transferSuggestionLink {
    ...Linkable
  }
}
    ${AppImageFragmentDoc}
${TransferDetailsMessageTooltipBodyFragmentDoc}
${LinkableFragmentDoc}
${AnalyticsEventFragmentDoc}`;
export const TransferRequirementsTimingDescriptionCellFragmentDoc = gql`
    fragment TransferRequirementsTimingDescriptionCell on TransferRequirements {
  dependsOnSells
  dependsOnHolds
  timingDescription
}
    `;
export const TransferFormViewerFragmentDoc = gql`
    fragment TransferFormViewer on Viewer {
  id
  credit {
    autoPayInstance {
      id
    }
  }
  borrow {
    personalLoans {
      loans {
        edges {
          node {
            id
            autoPayInstance {
              id
              isEnabled
            }
          }
        }
      }
    }
  }
  transfers {
    id
    isEvenWeek(date: $date)
    defaultAmountPills(transferScenario: SCHEDULED_TRANSFER_FOR_REBALANCE) {
      label
      kind
      icon
    }
    requirements(
      fromParticipantId: $fromParticipantId
      toParticipantId: $toParticipantId
      transferType: $transferType
    ) {
      detailsMessage {
        ...TransferDetailsMessageCard
      }
      ...TransferRequirementsTimingDescriptionCell
      minTransferAmount
      minTransferAmountErrorMessage
      maxTransferAmount
      maxTransferAmountErrorMessage
      maxTransferAmountErrorLink {
        ...Linkable
      }
      isIraContributionYearRequired
      isIraDistributionReasonRequired
      isIraRolloverOptionRequired
      transferAmountPresets {
        label
        value
        description
        __typename
      }
      scheduledTransferAmountPresets {
        label
        value
        indicator
        __typename
      }
      autoPayInformationContent
      autoPayDueDateMessage
      canBeScheduledRule
      transferOverviewLink {
        ...Linkable
      }
    }
    sourceParticipants: participants(
      transferType: $transferType
      forSide: FROM
      withOppositeParticipantId: $toParticipantId
    ) {
      isEmpty
      list {
        ...TransferParticipant
      }
    }
    destinationParticipants: participants(
      transferType: $transferType
      forSide: TO
      withOppositeParticipantId: $fromParticipantId
    ) {
      isEmpty
      list {
        ...TransferParticipant
      }
    }
    enhancedIraDistribution
  }
}
    ${TransferDetailsMessageCardFragmentDoc}
${TransferRequirementsTimingDescriptionCellFragmentDoc}
${LinkableFragmentDoc}
${TransferParticipantFragmentDoc}`;
export const SmartTransferContraParticipantEntryInstanceNodeFragmentDoc = gql`
    fragment SmartTransferContraParticipantEntryInstanceNode on TransferInstance {
  id
  startDate
  to {
    ...TransferParticipant
  }
  status {
    isFailed
    isCanceled
  }
  amount
  isLiquidation
}
    ${TransferParticipantFragmentDoc}`;
export const SmartTransferRuleSummaryFragmentDoc = gql`
    fragment SmartTransferRuleSummary on SmartTransferRule {
  balanceTrigger {
    balanceTriggerType
  }
  contraParticipantEntries {
    contraParticipant {
      id
      ...TransferParticipant
    }
  }
  description
  focusParticipant {
    ...TransferParticipant
  }
  fullDescription
  title
}
    ${TransferParticipantFragmentDoc}`;
export const SmartTransferRuleActivityFragmentDoc = gql`
    fragment SmartTransferRuleActivity on SmartTransferRule {
  id
  contraParticipantEntries {
    hasInstances
    id
    title
    totalTransferAmount
    transferCountDescription
  }
}
    `;
export const SmartTransferEnableSwitchFragmentDoc = gql`
    fragment SmartTransferEnableSwitch on SmartTransferRule {
  cautionStatusMessage
  id
  isEnabled
  isPauseToggleDisabled
}
    `;
export const PendingInstancesFragmentDoc = gql`
    fragment PendingInstances on Transfers {
  pendingInstances(onlyManuallyCreated: false) {
    ...TransferInstanceNode
  }
}
    ${TransferInstanceNodeFragmentDoc}`;
export const PastInstancesFragmentDoc = gql`
    fragment PastInstances on Transfers {
  pastInstances(first: $first, after: $after) {
    pageInfo {
      ...PageInfo
    }
    edges {
      node {
        ...TransferInstanceNode
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${TransferInstanceNodeFragmentDoc}`;
export const TransfersPendingAcatInstancesFragmentDoc = gql`
    fragment TransfersPendingAcatInstances on Transfers {
  acatInstances(type: INCOMPLETE) {
    id
    startDate
    endDate
    destination {
      name
    }
    source
    type
    amount
    details {
      pill {
        ...AppPill
      }
    }
  }
}
    ${AppPillFragmentDoc}`;
export const SpendBankConnectorFragmentDoc = gql`
    fragment SpendBankConnector on Spend {
  isExternalAccountInitiated
  externalAccount {
    name
    status {
      isAwaitingVerification
      isBlockedVerification
      isVerified
      isLocked
    }
    ...TransferParticipant
  }
}
    ${TransferParticipantFragmentDoc}`;
export const TransferInstanceStepCellFragmentDoc = gql`
    fragment TransferInstanceStepCell on TransferInstanceStep {
  status {
    description
  }
  title
  to {
    ...TransferParticipant
  }
}
    ${TransferParticipantFragmentDoc}`;
export const TransferInstanceStepCardFragmentDoc = gql`
    fragment TransferInstanceStepCard on TransferInstanceSteps {
  from {
    ...TransferParticipant
  }
  steps {
    id
    status {
      isCompleted
      isEnded
    }
    to {
      ...TransferParticipant
    }
    ...TransferInstanceStepCell
  }
}
    ${TransferParticipantFragmentDoc}
${TransferInstanceStepCellFragmentDoc}`;
export const TransferInstanceBottomMessageCardFragmentDoc = gql`
    fragment TransferInstanceBottomMessageCard on TransferInstanceDetailsMessage {
  title
  description
  verifyEmailCta
  icon {
    ...AppImage
  }
  link {
    ...Linkable
  }
}
    ${AppImageFragmentDoc}
${LinkableFragmentDoc}`;
export const TransferInstanceDetailsFragmentDoc = gql`
    fragment TransferInstanceDetails on TransferInstance {
  id
  details {
    id
    title
    amountLabel
    amount
    additionalDetails {
      label
      value1
      value2
      value3
      value4
    }
    pill {
      ...AppPill
    }
    steps {
      label
      ...TransferInstanceStepCard
    }
    transferSpecificMessage {
      ...TransferInstanceBottomMessageCard
    }
    transferTimingMessage {
      icon {
        ...AppImage
      }
      link {
        ...Linkable
      }
    }
    ifCancelable {
      cancelCtaCopy
      cancelDialogCopy
      cancelDialogConfirmCtaCopy
      cancelDialogCancelCtaCopy
    }
    disclaimer {
      disclaimerCopy
      phoneNumber
      link {
        ...Linkable
      }
    }
    outstandingTransferCredits {
      creditedAmount
      outstandingAmount
      totalAmount
    }
  }
}
    ${AppPillFragmentDoc}
${TransferInstanceStepCardFragmentDoc}
${TransferInstanceBottomMessageCardFragmentDoc}
${AppImageFragmentDoc}
${LinkableFragmentDoc}`;
export const TransferRuleFragmentDoc = gql`
    fragment TransferRule on TransferRule {
  hasPendingInstance
  isEnabled
  ... on ScheduledTransferRule {
    latestInstance {
      startDate
    }
  }
}
    `;
export const SmartTransferRuleFragmentDoc = gql`
    fragment SmartTransferRule on SmartTransferRule {
  id
  balanceTrigger {
    balanceTriggerType
  }
  isEnabled
  initialContraParticipantEntry {
    contraParticipant {
      ...TransferParticipant
    }
  }
  focusParticipant {
    ...TransferParticipant
  }
  title
  ...TransferRule
}
    ${TransferParticipantFragmentDoc}
${TransferRuleFragmentDoc}`;
export const ScheduledTransferRuleFragmentDoc = gql`
    fragment ScheduledTransferRule on ScheduledTransferRule {
  __typename
  id
  title
  from {
    ...TransferParticipant
  }
  to {
    ...TransferParticipant
  }
  amount
  isCreditCardAutoPay
  isPersonalLoanAutoPay
  schedulePresetIndicator
  scheduledTransferListLabel
  schedule {
    title
  }
  ...TransferRule
}
    ${TransferParticipantFragmentDoc}
${TransferRuleFragmentDoc}`;
export const WireInstructionsFragmentDoc = gql`
    fragment WireInstructions on MoveMoneyWireInstructions {
  title
  subtitle
  accountHeader
  accountLabel
  illustration {
    ...AppImage
  }
  wireInstructionValues {
    title
    value
  }
  helpfulTips
  wireTransferFAQLink {
    ...Linkable
  }
  contactUsLink {
    ...Linkable
  }
}
    ${AppImageFragmentDoc}
${LinkableFragmentDoc}`;
export const TransferAmountPillFragmentDoc = gql`
    fragment TransferAmountPill on AppPill {
  label
  kind
  icon
}
    `;
export const TransferWizardParticipantFragmentDoc = gql`
    fragment TransferWizardParticipant on TransferParticipant {
  id
  transferParticipantName
  transferParticipantType
  transferParticipantDescription
  ... on Account {
    registration
    isCryptoAccount
    isRetirement
  }
  ... on FundingSourceAccount {
    logoUrl
  }
}
    `;
export const TransferWizardParticipantDetailsFragmentDoc = gql`
    fragment TransferWizardParticipantDetails on TransferParticipantDetails {
  account {
    ...TransferWizardParticipant
  }
  relationships {
    min
    max
    destination {
      ...TransferWizardParticipant
    }
    source {
      ...TransferWizardParticipant
    }
    minErrorMessage
    maxErrorMessage
  }
}
    ${TransferWizardParticipantFragmentDoc}`;
export const TransferWizardRequirementsFieldsFragmentDoc = gql`
    fragment TransferWizardRequirementsFields on TransferRequirements {
  timingDescription
  detailsMessage {
    ...TransferDetailsMessageCard
  }
  isIraContributionYearRequired
  isIraContributionLimitRequired
  isIraDistributionReasonRequired
  isIraRolloverOptionRequired
}
    ${TransferDetailsMessageCardFragmentDoc}`;
export const ConfirmLiquidationAccountNodeFragmentDoc = gql`
    fragment ConfirmLiquidationAccountNode on Account {
  id
  isRetirement
}
    `;
export const MoveMoneyOptionFragmentDoc = gql`
    fragment MoveMoneyOption on MoveMoneyOption {
  title
  subtitle
  icon {
    ...AppImage
  }
  pill {
    ...AppPill
  }
  link {
    ...Linkable
  }
  userDataEntries {
    key
    value
  }
}
    ${AppImageFragmentDoc}
${AppPillFragmentDoc}
${LinkableFragmentDoc}`;
export const AuthenticateDocument = gql`
    mutation Authenticate($input: AuthenticateInput!) {
  authenticate(input: $input) {
    didSucceed
    error
    outcome {
      accessToken
      refreshToken
      viewer {
        user {
          id
          correlationKey
        }
      }
    }
  }
}
    `;
export type AuthenticateMutationFn = Apollo.MutationFunction<Types.AuthenticateMutation, Types.AuthenticateMutationVariables>;

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticateMutation(baseOptions?: Apollo.MutationHookOptions<Types.AuthenticateMutation, Types.AuthenticateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AuthenticateMutation, Types.AuthenticateMutationVariables>(AuthenticateDocument, options);
      }
export type AuthenticateMutationHookResult = ReturnType<typeof useAuthenticateMutation>;
export type AuthenticateMutationResult = Apollo.MutationResult<Types.AuthenticateMutation>;
export type AuthenticateMutationOptions = Apollo.BaseMutationOptions<Types.AuthenticateMutation, Types.AuthenticateMutationVariables>;
export const RegisterUserDocument = gql`
    mutation RegisterUser($input: RegisterUserInput!) {
  registerUser(input: $input) {
    didSucceed
    error
    outcome {
      accessToken
      refreshToken
      viewer {
        user {
          created
        }
      }
      promotionSignUpOutcomePage {
        ...AppInfoPage
      }
    }
  }
}
    ${AppInfoPageFragmentDoc}`;
export type RegisterUserMutationFn = Apollo.MutationFunction<Types.RegisterUserMutation, Types.RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.RegisterUserMutation, Types.RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RegisterUserMutation, Types.RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<Types.RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<Types.RegisterUserMutation, Types.RegisterUserMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    didSucceed
    error
    outcome {
      accessToken
      refreshToken
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<Types.ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>;
export const RevokeRefreshDocument = gql`
    mutation RevokeRefresh($input: RevokeRefreshInput!) {
  revokeRefresh(input: $input) {
    didSucceed
  }
}
    `;
export type RevokeRefreshMutationFn = Apollo.MutationFunction<Types.RevokeRefreshMutation, Types.RevokeRefreshMutationVariables>;

/**
 * __useRevokeRefreshMutation__
 *
 * To run a mutation, you first call `useRevokeRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeRefreshMutation, { data, loading, error }] = useRevokeRefreshMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeRefreshMutation(baseOptions?: Apollo.MutationHookOptions<Types.RevokeRefreshMutation, Types.RevokeRefreshMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RevokeRefreshMutation, Types.RevokeRefreshMutationVariables>(RevokeRefreshDocument, options);
      }
export type RevokeRefreshMutationHookResult = ReturnType<typeof useRevokeRefreshMutation>;
export type RevokeRefreshMutationResult = Apollo.MutationResult<Types.RevokeRefreshMutation>;
export type RevokeRefreshMutationOptions = Apollo.BaseMutationOptions<Types.RevokeRefreshMutation, Types.RevokeRefreshMutationVariables>;
export const SubmitInvestAcatTransferDocument = gql`
    mutation SubmitInvestAcatTransfer($input: SubmitInvestAcatTransferInput!) {
  submitInvestAcatTransfer(input: $input) {
    clientMutationId
    didSucceed
    errorMessage
    toast {
      ...AppToast
    }
  }
}
    ${AppToastFragmentDoc}`;
export type SubmitInvestAcatTransferMutationFn = Apollo.MutationFunction<Types.SubmitInvestAcatTransferMutation, Types.SubmitInvestAcatTransferMutationVariables>;

/**
 * __useSubmitInvestAcatTransferMutation__
 *
 * To run a mutation, you first call `useSubmitInvestAcatTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitInvestAcatTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitInvestAcatTransferMutation, { data, loading, error }] = useSubmitInvestAcatTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitInvestAcatTransferMutation(baseOptions?: Apollo.MutationHookOptions<Types.SubmitInvestAcatTransferMutation, Types.SubmitInvestAcatTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SubmitInvestAcatTransferMutation, Types.SubmitInvestAcatTransferMutationVariables>(SubmitInvestAcatTransferDocument, options);
      }
export type SubmitInvestAcatTransferMutationHookResult = ReturnType<typeof useSubmitInvestAcatTransferMutation>;
export type SubmitInvestAcatTransferMutationResult = Apollo.MutationResult<Types.SubmitInvestAcatTransferMutation>;
export type SubmitInvestAcatTransferMutationOptions = Apollo.BaseMutationOptions<Types.SubmitInvestAcatTransferMutation, Types.SubmitInvestAcatTransferMutationVariables>;
export const GenerateIdempotencyKeyDocument = gql`
    mutation GenerateIdempotencyKey($input: GenerateIdempotencyKeyInput!) {
  generateIdempotencyKey(input: $input) {
    didSucceed
    outcome {
      idempotencyKey
    }
    error
  }
}
    `;
export type GenerateIdempotencyKeyMutationFn = Apollo.MutationFunction<Types.GenerateIdempotencyKeyMutation, Types.GenerateIdempotencyKeyMutationVariables>;

/**
 * __useGenerateIdempotencyKeyMutation__
 *
 * To run a mutation, you first call `useGenerateIdempotencyKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateIdempotencyKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateIdempotencyKeyMutation, { data, loading, error }] = useGenerateIdempotencyKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateIdempotencyKeyMutation(baseOptions?: Apollo.MutationHookOptions<Types.GenerateIdempotencyKeyMutation, Types.GenerateIdempotencyKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GenerateIdempotencyKeyMutation, Types.GenerateIdempotencyKeyMutationVariables>(GenerateIdempotencyKeyDocument, options);
      }
export type GenerateIdempotencyKeyMutationHookResult = ReturnType<typeof useGenerateIdempotencyKeyMutation>;
export type GenerateIdempotencyKeyMutationResult = Apollo.MutationResult<Types.GenerateIdempotencyKeyMutation>;
export type GenerateIdempotencyKeyMutationOptions = Apollo.BaseMutationOptions<Types.GenerateIdempotencyKeyMutation, Types.GenerateIdempotencyKeyMutationVariables>;
export const RenameAccountDocument = gql`
    mutation RenameAccount($input: RenameAccountInput!) {
  renameAccount(input: $input) {
    didSucceed
    error
    outcome {
      name
    }
  }
}
    `;
export type RenameAccountMutationFn = Apollo.MutationFunction<Types.RenameAccountMutation, Types.RenameAccountMutationVariables>;

/**
 * __useRenameAccountMutation__
 *
 * To run a mutation, you first call `useRenameAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameAccountMutation, { data, loading, error }] = useRenameAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenameAccountMutation(baseOptions?: Apollo.MutationHookOptions<Types.RenameAccountMutation, Types.RenameAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RenameAccountMutation, Types.RenameAccountMutationVariables>(RenameAccountDocument, options);
      }
export type RenameAccountMutationHookResult = ReturnType<typeof useRenameAccountMutation>;
export type RenameAccountMutationResult = Apollo.MutationResult<Types.RenameAccountMutation>;
export type RenameAccountMutationOptions = Apollo.BaseMutationOptions<Types.RenameAccountMutation, Types.RenameAccountMutationVariables>;
export const AcceptCreditCardOfferDocument = gql`
    mutation AcceptCreditCardOffer($input: AcceptCreditCardOfferInput!) {
  acceptCreditCardOffer(input: $input) {
    didSucceed
    outcome {
      applicationDetails {
        status
      }
    }
    error
  }
}
    `;
export type AcceptCreditCardOfferMutationFn = Apollo.MutationFunction<Types.AcceptCreditCardOfferMutation, Types.AcceptCreditCardOfferMutationVariables>;

/**
 * __useAcceptCreditCardOfferMutation__
 *
 * To run a mutation, you first call `useAcceptCreditCardOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCreditCardOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCreditCardOfferMutation, { data, loading, error }] = useAcceptCreditCardOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptCreditCardOfferMutation(baseOptions?: Apollo.MutationHookOptions<Types.AcceptCreditCardOfferMutation, Types.AcceptCreditCardOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AcceptCreditCardOfferMutation, Types.AcceptCreditCardOfferMutationVariables>(AcceptCreditCardOfferDocument, options);
      }
export type AcceptCreditCardOfferMutationHookResult = ReturnType<typeof useAcceptCreditCardOfferMutation>;
export type AcceptCreditCardOfferMutationResult = Apollo.MutationResult<Types.AcceptCreditCardOfferMutation>;
export type AcceptCreditCardOfferMutationOptions = Apollo.BaseMutationOptions<Types.AcceptCreditCardOfferMutation, Types.AcceptCreditCardOfferMutationVariables>;
export const ActivateCreditCardDocument = gql`
    mutation ActivateCreditCard($input: ActivateCreditCardInput!) {
  activateCreditCard(input: $input) {
    didSucceed
    outcome {
      creditCard {
        id
        key
        lastFour
        network
        cardType
        status
      }
    }
    error
  }
}
    `;
export type ActivateCreditCardMutationFn = Apollo.MutationFunction<Types.ActivateCreditCardMutation, Types.ActivateCreditCardMutationVariables>;

/**
 * __useActivateCreditCardMutation__
 *
 * To run a mutation, you first call `useActivateCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCreditCardMutation, { data, loading, error }] = useActivateCreditCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateCreditCardMutation(baseOptions?: Apollo.MutationHookOptions<Types.ActivateCreditCardMutation, Types.ActivateCreditCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ActivateCreditCardMutation, Types.ActivateCreditCardMutationVariables>(ActivateCreditCardDocument, options);
      }
export type ActivateCreditCardMutationHookResult = ReturnType<typeof useActivateCreditCardMutation>;
export type ActivateCreditCardMutationResult = Apollo.MutationResult<Types.ActivateCreditCardMutation>;
export type ActivateCreditCardMutationOptions = Apollo.BaseMutationOptions<Types.ActivateCreditCardMutation, Types.ActivateCreditCardMutationVariables>;
export const ContinueCreditCardApplicationAfterUserCreditIsUnfrozenDocument = gql`
    mutation ContinueCreditCardApplicationAfterUserCreditIsUnfrozen($input: ContinueCreditCardApplicationAfterUserCreditIsUnfrozenInput!) {
  continueCreditCardApplicationAfterUserCreditIsUnfrozen(input: $input) {
    didSucceed
    outcome {
      applicationDetails {
        status
      }
    }
    error
  }
}
    `;
export type ContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutationFn = Apollo.MutationFunction<Types.ContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutation, Types.ContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutationVariables>;

/**
 * __useContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutation__
 *
 * To run a mutation, you first call `useContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [continueCreditCardApplicationAfterUserCreditIsUnfrozenMutation, { data, loading, error }] = useContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutation(baseOptions?: Apollo.MutationHookOptions<Types.ContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutation, Types.ContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutation, Types.ContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutationVariables>(ContinueCreditCardApplicationAfterUserCreditIsUnfrozenDocument, options);
      }
export type ContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutationHookResult = ReturnType<typeof useContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutation>;
export type ContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutationResult = Apollo.MutationResult<Types.ContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutation>;
export type ContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutationOptions = Apollo.BaseMutationOptions<Types.ContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutation, Types.ContinueCreditCardApplicationAfterUserCreditIsUnfrozenMutationVariables>;
export const SelectPaymentsAccountDocument = gql`
    mutation SelectPaymentsAccount($input: SelectPaymentsAccountInput!) {
  selectPaymentsAccount(input: $input) {
    didSucceed
    error
  }
}
    `;
export type SelectPaymentsAccountMutationFn = Apollo.MutationFunction<Types.SelectPaymentsAccountMutation, Types.SelectPaymentsAccountMutationVariables>;

/**
 * __useSelectPaymentsAccountMutation__
 *
 * To run a mutation, you first call `useSelectPaymentsAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectPaymentsAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectPaymentsAccountMutation, { data, loading, error }] = useSelectPaymentsAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelectPaymentsAccountMutation(baseOptions?: Apollo.MutationHookOptions<Types.SelectPaymentsAccountMutation, Types.SelectPaymentsAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SelectPaymentsAccountMutation, Types.SelectPaymentsAccountMutationVariables>(SelectPaymentsAccountDocument, options);
      }
export type SelectPaymentsAccountMutationHookResult = ReturnType<typeof useSelectPaymentsAccountMutation>;
export type SelectPaymentsAccountMutationResult = Apollo.MutationResult<Types.SelectPaymentsAccountMutation>;
export type SelectPaymentsAccountMutationOptions = Apollo.BaseMutationOptions<Types.SelectPaymentsAccountMutation, Types.SelectPaymentsAccountMutationVariables>;
export const SetLockOnCreditCardDocument = gql`
    mutation SetLockOnCreditCard($input: SetLockOnCreditCardInput!) {
  setLockOnCreditCard(input: $input) {
    didSucceed
    error
    outcome {
      ... on SetLockOnCreditCardOutcome {
        creditCard {
          id
          isLocked
        }
      }
    }
  }
}
    `;
export type SetLockOnCreditCardMutationFn = Apollo.MutationFunction<Types.SetLockOnCreditCardMutation, Types.SetLockOnCreditCardMutationVariables>;

/**
 * __useSetLockOnCreditCardMutation__
 *
 * To run a mutation, you first call `useSetLockOnCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLockOnCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLockOnCreditCardMutation, { data, loading, error }] = useSetLockOnCreditCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetLockOnCreditCardMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetLockOnCreditCardMutation, Types.SetLockOnCreditCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetLockOnCreditCardMutation, Types.SetLockOnCreditCardMutationVariables>(SetLockOnCreditCardDocument, options);
      }
export type SetLockOnCreditCardMutationHookResult = ReturnType<typeof useSetLockOnCreditCardMutation>;
export type SetLockOnCreditCardMutationResult = Apollo.MutationResult<Types.SetLockOnCreditCardMutation>;
export type SetLockOnCreditCardMutationOptions = Apollo.BaseMutationOptions<Types.SetLockOnCreditCardMutation, Types.SetLockOnCreditCardMutationVariables>;
export const CreditCardLockRefetchDocument = gql`
    query CreditCardLockRefetch {
  viewer {
    credit {
      manageCard {
        cardPill {
          label
          kind
        }
      }
      activeAccount {
        activeCard {
          id
          isLocked
        }
      }
    }
  }
}
    `;

/**
 * __useCreditCardLockRefetchQuery__
 *
 * To run a query within a React component, call `useCreditCardLockRefetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditCardLockRefetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditCardLockRefetchQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreditCardLockRefetchQuery(baseOptions?: Apollo.QueryHookOptions<Types.CreditCardLockRefetchQuery, Types.CreditCardLockRefetchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CreditCardLockRefetchQuery, Types.CreditCardLockRefetchQueryVariables>(CreditCardLockRefetchDocument, options);
      }
export function useCreditCardLockRefetchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CreditCardLockRefetchQuery, Types.CreditCardLockRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CreditCardLockRefetchQuery, Types.CreditCardLockRefetchQueryVariables>(CreditCardLockRefetchDocument, options);
        }
export function useCreditCardLockRefetchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CreditCardLockRefetchQuery, Types.CreditCardLockRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CreditCardLockRefetchQuery, Types.CreditCardLockRefetchQueryVariables>(CreditCardLockRefetchDocument, options);
        }
export type CreditCardLockRefetchQueryHookResult = ReturnType<typeof useCreditCardLockRefetchQuery>;
export type CreditCardLockRefetchLazyQueryHookResult = ReturnType<typeof useCreditCardLockRefetchLazyQuery>;
export type CreditCardLockRefetchSuspenseQueryHookResult = ReturnType<typeof useCreditCardLockRefetchSuspenseQuery>;
export type CreditCardLockRefetchQueryResult = Apollo.QueryResult<Types.CreditCardLockRefetchQuery, Types.CreditCardLockRefetchQueryVariables>;
export const SubmitCreditCardApplicationDocument = gql`
    mutation SubmitCreditCardApplication($input: SubmitCreditCardApplicationInput!) {
  submitCreditCardApplication(input: $input) {
    didSucceed
    outcome {
      applicationDetails {
        status
      }
      successAnalyticsEvent {
        ...AnalyticsEvent
      }
    }
    error
  }
}
    ${AnalyticsEventFragmentDoc}`;
export type SubmitCreditCardApplicationMutationFn = Apollo.MutationFunction<Types.SubmitCreditCardApplicationMutation, Types.SubmitCreditCardApplicationMutationVariables>;

/**
 * __useSubmitCreditCardApplicationMutation__
 *
 * To run a mutation, you first call `useSubmitCreditCardApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitCreditCardApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitCreditCardApplicationMutation, { data, loading, error }] = useSubmitCreditCardApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitCreditCardApplicationMutation(baseOptions?: Apollo.MutationHookOptions<Types.SubmitCreditCardApplicationMutation, Types.SubmitCreditCardApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SubmitCreditCardApplicationMutation, Types.SubmitCreditCardApplicationMutationVariables>(SubmitCreditCardApplicationDocument, options);
      }
export type SubmitCreditCardApplicationMutationHookResult = ReturnType<typeof useSubmitCreditCardApplicationMutation>;
export type SubmitCreditCardApplicationMutationResult = Apollo.MutationResult<Types.SubmitCreditCardApplicationMutation>;
export type SubmitCreditCardApplicationMutationOptions = Apollo.BaseMutationOptions<Types.SubmitCreditCardApplicationMutation, Types.SubmitCreditCardApplicationMutationVariables>;
export const UpdateCreditCardRewardPayoutDestinationDocument = gql`
    mutation UpdateCreditCardRewardPayoutDestination($input: UpdateCreditCardRewardPayoutDestinationInput!) {
  updateCreditCardRewardPayoutDestination(input: $input) {
    didSucceed
    outcome {
      rewardsPortal {
        summary {
          rewardsPayoutLocation {
            icon {
              ...AppImage
            }
            label
            value
          }
        }
      }
    }
    error
  }
}
    ${AppImageFragmentDoc}`;
export type UpdateCreditCardRewardPayoutDestinationMutationFn = Apollo.MutationFunction<Types.UpdateCreditCardRewardPayoutDestinationMutation, Types.UpdateCreditCardRewardPayoutDestinationMutationVariables>;

/**
 * __useUpdateCreditCardRewardPayoutDestinationMutation__
 *
 * To run a mutation, you first call `useUpdateCreditCardRewardPayoutDestinationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditCardRewardPayoutDestinationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditCardRewardPayoutDestinationMutation, { data, loading, error }] = useUpdateCreditCardRewardPayoutDestinationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreditCardRewardPayoutDestinationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCreditCardRewardPayoutDestinationMutation, Types.UpdateCreditCardRewardPayoutDestinationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCreditCardRewardPayoutDestinationMutation, Types.UpdateCreditCardRewardPayoutDestinationMutationVariables>(UpdateCreditCardRewardPayoutDestinationDocument, options);
      }
export type UpdateCreditCardRewardPayoutDestinationMutationHookResult = ReturnType<typeof useUpdateCreditCardRewardPayoutDestinationMutation>;
export type UpdateCreditCardRewardPayoutDestinationMutationResult = Apollo.MutationResult<Types.UpdateCreditCardRewardPayoutDestinationMutation>;
export type UpdateCreditCardRewardPayoutDestinationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCreditCardRewardPayoutDestinationMutation, Types.UpdateCreditCardRewardPayoutDestinationMutationVariables>;
export const StartCryptoOnDemandTradeWindowDocument = gql`
    mutation StartCryptoOnDemandTradeWindow($input: StartCryptoOnDemandTradeWindowInput!) {
  startCryptoOnDemandTradeWindow(input: $input) {
    didSucceed
    outcome {
      tradeWindowId
    }
    error
    errorMessage
  }
}
    `;
export type StartCryptoOnDemandTradeWindowMutationFn = Apollo.MutationFunction<Types.StartCryptoOnDemandTradeWindowMutation, Types.StartCryptoOnDemandTradeWindowMutationVariables>;

/**
 * __useStartCryptoOnDemandTradeWindowMutation__
 *
 * To run a mutation, you first call `useStartCryptoOnDemandTradeWindowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartCryptoOnDemandTradeWindowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startCryptoOnDemandTradeWindowMutation, { data, loading, error }] = useStartCryptoOnDemandTradeWindowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartCryptoOnDemandTradeWindowMutation(baseOptions?: Apollo.MutationHookOptions<Types.StartCryptoOnDemandTradeWindowMutation, Types.StartCryptoOnDemandTradeWindowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.StartCryptoOnDemandTradeWindowMutation, Types.StartCryptoOnDemandTradeWindowMutationVariables>(StartCryptoOnDemandTradeWindowDocument, options);
      }
export type StartCryptoOnDemandTradeWindowMutationHookResult = ReturnType<typeof useStartCryptoOnDemandTradeWindowMutation>;
export type StartCryptoOnDemandTradeWindowMutationResult = Apollo.MutationResult<Types.StartCryptoOnDemandTradeWindowMutation>;
export type StartCryptoOnDemandTradeWindowMutationOptions = Apollo.BaseMutationOptions<Types.StartCryptoOnDemandTradeWindowMutation, Types.StartCryptoOnDemandTradeWindowMutationVariables>;
export const CompleteDocumentUploadRequestDocument = gql`
    mutation CompleteDocumentUploadRequest($input: CompleteDocumentUploadRequestInput!) {
  completeDocumentUploadRequest(input: $input) {
    didSucceed
  }
}
    `;
export type CompleteDocumentUploadRequestMutationFn = Apollo.MutationFunction<Types.CompleteDocumentUploadRequestMutation, Types.CompleteDocumentUploadRequestMutationVariables>;

/**
 * __useCompleteDocumentUploadRequestMutation__
 *
 * To run a mutation, you first call `useCompleteDocumentUploadRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteDocumentUploadRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeDocumentUploadRequestMutation, { data, loading, error }] = useCompleteDocumentUploadRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteDocumentUploadRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompleteDocumentUploadRequestMutation, Types.CompleteDocumentUploadRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompleteDocumentUploadRequestMutation, Types.CompleteDocumentUploadRequestMutationVariables>(CompleteDocumentUploadRequestDocument, options);
      }
export type CompleteDocumentUploadRequestMutationHookResult = ReturnType<typeof useCompleteDocumentUploadRequestMutation>;
export type CompleteDocumentUploadRequestMutationResult = Apollo.MutationResult<Types.CompleteDocumentUploadRequestMutation>;
export type CompleteDocumentUploadRequestMutationOptions = Apollo.BaseMutationOptions<Types.CompleteDocumentUploadRequestMutation, Types.CompleteDocumentUploadRequestMutationVariables>;
export const PostDocumentUploadRefetchDocument = gql`
    query PostDocumentUploadRefetch {
  viewer {
    accounts(first: 100) {
      edges {
        node {
          informationalBanner {
            kind
            label
            link {
              ...Linkable
            }
          }
        }
      }
    }
  }
}
    ${LinkableFragmentDoc}`;

/**
 * __usePostDocumentUploadRefetchQuery__
 *
 * To run a query within a React component, call `usePostDocumentUploadRefetchQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostDocumentUploadRefetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostDocumentUploadRefetchQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostDocumentUploadRefetchQuery(baseOptions?: Apollo.QueryHookOptions<Types.PostDocumentUploadRefetchQuery, Types.PostDocumentUploadRefetchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PostDocumentUploadRefetchQuery, Types.PostDocumentUploadRefetchQueryVariables>(PostDocumentUploadRefetchDocument, options);
      }
export function usePostDocumentUploadRefetchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PostDocumentUploadRefetchQuery, Types.PostDocumentUploadRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PostDocumentUploadRefetchQuery, Types.PostDocumentUploadRefetchQueryVariables>(PostDocumentUploadRefetchDocument, options);
        }
export function usePostDocumentUploadRefetchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PostDocumentUploadRefetchQuery, Types.PostDocumentUploadRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PostDocumentUploadRefetchQuery, Types.PostDocumentUploadRefetchQueryVariables>(PostDocumentUploadRefetchDocument, options);
        }
export type PostDocumentUploadRefetchQueryHookResult = ReturnType<typeof usePostDocumentUploadRefetchQuery>;
export type PostDocumentUploadRefetchLazyQueryHookResult = ReturnType<typeof usePostDocumentUploadRefetchLazyQuery>;
export type PostDocumentUploadRefetchSuspenseQueryHookResult = ReturnType<typeof usePostDocumentUploadRefetchSuspenseQuery>;
export type PostDocumentUploadRefetchQueryResult = Apollo.QueryResult<Types.PostDocumentUploadRefetchQuery, Types.PostDocumentUploadRefetchQueryVariables>;
export const CreateAccountDocumentLinkDocument = gql`
    mutation CreateAccountDocumentLink($input: CreateAccountDocumentLinkInput!) {
  createAccountDocumentLink(input: $input) {
    didSucceed
    outcome {
      url
    }
    error
  }
}
    `;
export type CreateAccountDocumentLinkMutationFn = Apollo.MutationFunction<Types.CreateAccountDocumentLinkMutation, Types.CreateAccountDocumentLinkMutationVariables>;

/**
 * __useCreateAccountDocumentLinkMutation__
 *
 * To run a mutation, you first call `useCreateAccountDocumentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountDocumentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountDocumentLinkMutation, { data, loading, error }] = useCreateAccountDocumentLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountDocumentLinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAccountDocumentLinkMutation, Types.CreateAccountDocumentLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateAccountDocumentLinkMutation, Types.CreateAccountDocumentLinkMutationVariables>(CreateAccountDocumentLinkDocument, options);
      }
export type CreateAccountDocumentLinkMutationHookResult = ReturnType<typeof useCreateAccountDocumentLinkMutation>;
export type CreateAccountDocumentLinkMutationResult = Apollo.MutationResult<Types.CreateAccountDocumentLinkMutation>;
export type CreateAccountDocumentLinkMutationOptions = Apollo.BaseMutationOptions<Types.CreateAccountDocumentLinkMutation, Types.CreateAccountDocumentLinkMutationVariables>;
export const GenerateGenericPresignedDocumentUrlDocument = gql`
    mutation GenerateGenericPresignedDocumentUrl($input: GenerateGenericPresignedDocumentUrlInput!) {
  generateGenericPresignedDocumentUrl(input: $input) {
    didSucceed
    outcome {
      presignedUrl
    }
    error
  }
}
    `;
export type GenerateGenericPresignedDocumentUrlMutationFn = Apollo.MutationFunction<Types.GenerateGenericPresignedDocumentUrlMutation, Types.GenerateGenericPresignedDocumentUrlMutationVariables>;

/**
 * __useGenerateGenericPresignedDocumentUrlMutation__
 *
 * To run a mutation, you first call `useGenerateGenericPresignedDocumentUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateGenericPresignedDocumentUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateGenericPresignedDocumentUrlMutation, { data, loading, error }] = useGenerateGenericPresignedDocumentUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateGenericPresignedDocumentUrlMutation(baseOptions?: Apollo.MutationHookOptions<Types.GenerateGenericPresignedDocumentUrlMutation, Types.GenerateGenericPresignedDocumentUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GenerateGenericPresignedDocumentUrlMutation, Types.GenerateGenericPresignedDocumentUrlMutationVariables>(GenerateGenericPresignedDocumentUrlDocument, options);
      }
export type GenerateGenericPresignedDocumentUrlMutationHookResult = ReturnType<typeof useGenerateGenericPresignedDocumentUrlMutation>;
export type GenerateGenericPresignedDocumentUrlMutationResult = Apollo.MutationResult<Types.GenerateGenericPresignedDocumentUrlMutation>;
export type GenerateGenericPresignedDocumentUrlMutationOptions = Apollo.BaseMutationOptions<Types.GenerateGenericPresignedDocumentUrlMutation, Types.GenerateGenericPresignedDocumentUrlMutationVariables>;
export const GeneratePresignedDocumentUploadRequestUrlDocument = gql`
    mutation GeneratePresignedDocumentUploadRequestUrl($input: GeneratePresignedDocumentUploadRequestUrlInput!) {
  generatePresignedDocumentUploadRequestUrl(input: $input) {
    didSucceed
    outcome {
      presignedUrl
    }
    error
  }
}
    `;
export type GeneratePresignedDocumentUploadRequestUrlMutationFn = Apollo.MutationFunction<Types.GeneratePresignedDocumentUploadRequestUrlMutation, Types.GeneratePresignedDocumentUploadRequestUrlMutationVariables>;

/**
 * __useGeneratePresignedDocumentUploadRequestUrlMutation__
 *
 * To run a mutation, you first call `useGeneratePresignedDocumentUploadRequestUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePresignedDocumentUploadRequestUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePresignedDocumentUploadRequestUrlMutation, { data, loading, error }] = useGeneratePresignedDocumentUploadRequestUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGeneratePresignedDocumentUploadRequestUrlMutation(baseOptions?: Apollo.MutationHookOptions<Types.GeneratePresignedDocumentUploadRequestUrlMutation, Types.GeneratePresignedDocumentUploadRequestUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GeneratePresignedDocumentUploadRequestUrlMutation, Types.GeneratePresignedDocumentUploadRequestUrlMutationVariables>(GeneratePresignedDocumentUploadRequestUrlDocument, options);
      }
export type GeneratePresignedDocumentUploadRequestUrlMutationHookResult = ReturnType<typeof useGeneratePresignedDocumentUploadRequestUrlMutation>;
export type GeneratePresignedDocumentUploadRequestUrlMutationResult = Apollo.MutationResult<Types.GeneratePresignedDocumentUploadRequestUrlMutation>;
export type GeneratePresignedDocumentUploadRequestUrlMutationOptions = Apollo.BaseMutationOptions<Types.GeneratePresignedDocumentUploadRequestUrlMutation, Types.GeneratePresignedDocumentUploadRequestUrlMutationVariables>;
export const CreatePieShareUrlDocument = gql`
    mutation CreatePieShareUrl($input: CreatePieShareUrlInput!) {
  createPieShareUrl(input: $input) {
    result {
      didSucceed
      inputError
    }
    url
    hasReferrerCode
    referrerCode
    token
    socialMediaShareButtons {
      ...AppNavigationItem
    }
  }
}
    ${AppNavigationItemFragmentDoc}`;
export type CreatePieShareUrlMutationFn = Apollo.MutationFunction<Types.CreatePieShareUrlMutation, Types.CreatePieShareUrlMutationVariables>;

/**
 * __useCreatePieShareUrlMutation__
 *
 * To run a mutation, you first call `useCreatePieShareUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePieShareUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPieShareUrlMutation, { data, loading, error }] = useCreatePieShareUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePieShareUrlMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreatePieShareUrlMutation, Types.CreatePieShareUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreatePieShareUrlMutation, Types.CreatePieShareUrlMutationVariables>(CreatePieShareUrlDocument, options);
      }
export type CreatePieShareUrlMutationHookResult = ReturnType<typeof useCreatePieShareUrlMutation>;
export type CreatePieShareUrlMutationResult = Apollo.MutationResult<Types.CreatePieShareUrlMutation>;
export type CreatePieShareUrlMutationOptions = Apollo.BaseMutationOptions<Types.CreatePieShareUrlMutation, Types.CreatePieShareUrlMutationVariables>;
export const OpenAccountDocument = gql`
    mutation OpenAccount($input: OpenAccountInput!) {
  openAccount(input: $input) {
    error
    didSucceed
    outcome {
      account {
        id
        name
        status
      }
    }
  }
}
    `;
export type OpenAccountMutationFn = Apollo.MutationFunction<Types.OpenAccountMutation, Types.OpenAccountMutationVariables>;

/**
 * __useOpenAccountMutation__
 *
 * To run a mutation, you first call `useOpenAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openAccountMutation, { data, loading, error }] = useOpenAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOpenAccountMutation(baseOptions?: Apollo.MutationHookOptions<Types.OpenAccountMutation, Types.OpenAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.OpenAccountMutation, Types.OpenAccountMutationVariables>(OpenAccountDocument, options);
      }
export type OpenAccountMutationHookResult = ReturnType<typeof useOpenAccountMutation>;
export type OpenAccountMutationResult = Apollo.MutationResult<Types.OpenAccountMutation>;
export type OpenAccountMutationOptions = Apollo.BaseMutationOptions<Types.OpenAccountMutation, Types.OpenAccountMutationVariables>;
export const OpenCustodialAccountDocument = gql`
    mutation OpenCustodialAccount($input: OpenCustodialAccountInput!) {
  openCustodialAccount(input: $input) {
    didSucceed
    outcome {
      account {
        id
        status
      }
      viewer {
        accounts(first: 20) {
          edges {
            node {
              id
              name
              number
              balance {
                totalValue {
                  value
                }
              }
              transferParticipantType
            }
          }
        }
      }
    }
    error
  }
}
    `;
export type OpenCustodialAccountMutationFn = Apollo.MutationFunction<Types.OpenCustodialAccountMutation, Types.OpenCustodialAccountMutationVariables>;

/**
 * __useOpenCustodialAccountMutation__
 *
 * To run a mutation, you first call `useOpenCustodialAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenCustodialAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openCustodialAccountMutation, { data, loading, error }] = useOpenCustodialAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOpenCustodialAccountMutation(baseOptions?: Apollo.MutationHookOptions<Types.OpenCustodialAccountMutation, Types.OpenCustodialAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.OpenCustodialAccountMutation, Types.OpenCustodialAccountMutationVariables>(OpenCustodialAccountDocument, options);
      }
export type OpenCustodialAccountMutationHookResult = ReturnType<typeof useOpenCustodialAccountMutation>;
export type OpenCustodialAccountMutationResult = Apollo.MutationResult<Types.OpenCustodialAccountMutation>;
export type OpenCustodialAccountMutationOptions = Apollo.BaseMutationOptions<Types.OpenCustodialAccountMutation, Types.OpenCustodialAccountMutationVariables>;
export const CustodialAccountRefetchDocument = gql`
    query CustodialAccountRefetch {
  viewer {
    accounts(first: 20) {
      edges {
        node {
          id
          name
          number
          balance {
            totalValue {
              value
            }
          }
          transferParticipantType
        }
      }
    }
  }
}
    `;

/**
 * __useCustodialAccountRefetchQuery__
 *
 * To run a query within a React component, call `useCustodialAccountRefetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustodialAccountRefetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustodialAccountRefetchQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustodialAccountRefetchQuery(baseOptions?: Apollo.QueryHookOptions<Types.CustodialAccountRefetchQuery, Types.CustodialAccountRefetchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CustodialAccountRefetchQuery, Types.CustodialAccountRefetchQueryVariables>(CustodialAccountRefetchDocument, options);
      }
export function useCustodialAccountRefetchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CustodialAccountRefetchQuery, Types.CustodialAccountRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CustodialAccountRefetchQuery, Types.CustodialAccountRefetchQueryVariables>(CustodialAccountRefetchDocument, options);
        }
export function useCustodialAccountRefetchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CustodialAccountRefetchQuery, Types.CustodialAccountRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CustodialAccountRefetchQuery, Types.CustodialAccountRefetchQueryVariables>(CustodialAccountRefetchDocument, options);
        }
export type CustodialAccountRefetchQueryHookResult = ReturnType<typeof useCustodialAccountRefetchQuery>;
export type CustodialAccountRefetchLazyQueryHookResult = ReturnType<typeof useCustodialAccountRefetchLazyQuery>;
export type CustodialAccountRefetchSuspenseQueryHookResult = ReturnType<typeof useCustodialAccountRefetchSuspenseQuery>;
export type CustodialAccountRefetchQueryResult = Apollo.QueryResult<Types.CustodialAccountRefetchQuery, Types.CustodialAccountRefetchQueryVariables>;
export const SetAccountConfigurationForDividendsDocument = gql`
    mutation SetAccountConfigurationForDividends($input: SetAccountConfigurationDividendSettingsInput!) {
  accountConfigurationDividends(input: $input) {
    didSucceed
    error
    errorMessage
    outcome {
      configuration {
        ...AccountConfigurationDividends
      }
      successAnalyticsEvent {
        ...AnalyticsEvent
      }
      successMessage
    }
  }
}
    ${AccountConfigurationDividendsFragmentDoc}
${AnalyticsEventFragmentDoc}`;
export type SetAccountConfigurationForDividendsMutationFn = Apollo.MutationFunction<Types.SetAccountConfigurationForDividendsMutation, Types.SetAccountConfigurationForDividendsMutationVariables>;

/**
 * __useSetAccountConfigurationForDividendsMutation__
 *
 * To run a mutation, you first call `useSetAccountConfigurationForDividendsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAccountConfigurationForDividendsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAccountConfigurationForDividendsMutation, { data, loading, error }] = useSetAccountConfigurationForDividendsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetAccountConfigurationForDividendsMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetAccountConfigurationForDividendsMutation, Types.SetAccountConfigurationForDividendsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetAccountConfigurationForDividendsMutation, Types.SetAccountConfigurationForDividendsMutationVariables>(SetAccountConfigurationForDividendsDocument, options);
      }
export type SetAccountConfigurationForDividendsMutationHookResult = ReturnType<typeof useSetAccountConfigurationForDividendsMutation>;
export type SetAccountConfigurationForDividendsMutationResult = Apollo.MutationResult<Types.SetAccountConfigurationForDividendsMutation>;
export type SetAccountConfigurationForDividendsMutationOptions = Apollo.BaseMutationOptions<Types.SetAccountConfigurationForDividendsMutation, Types.SetAccountConfigurationForDividendsMutationVariables>;
export const SetAccountConfigurationForDividendsForHoldingDocument = gql`
    mutation SetAccountConfigurationForDividendsForHolding($input: SetAccountConfigurationDividendSettingsForHoldingsInput!) {
  accountConfigurationDividendsForHoldings(input: $input) {
    didSucceed
    error
    errorMessage
    outcome {
      configuration {
        ...AccountConfigurationDividends
      }
      successAnalyticsEvent {
        ...AnalyticsEvent
      }
      successMessage
    }
  }
}
    ${AccountConfigurationDividendsFragmentDoc}
${AnalyticsEventFragmentDoc}`;
export type SetAccountConfigurationForDividendsForHoldingMutationFn = Apollo.MutationFunction<Types.SetAccountConfigurationForDividendsForHoldingMutation, Types.SetAccountConfigurationForDividendsForHoldingMutationVariables>;

/**
 * __useSetAccountConfigurationForDividendsForHoldingMutation__
 *
 * To run a mutation, you first call `useSetAccountConfigurationForDividendsForHoldingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAccountConfigurationForDividendsForHoldingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAccountConfigurationForDividendsForHoldingMutation, { data, loading, error }] = useSetAccountConfigurationForDividendsForHoldingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetAccountConfigurationForDividendsForHoldingMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetAccountConfigurationForDividendsForHoldingMutation, Types.SetAccountConfigurationForDividendsForHoldingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetAccountConfigurationForDividendsForHoldingMutation, Types.SetAccountConfigurationForDividendsForHoldingMutationVariables>(SetAccountConfigurationForDividendsForHoldingDocument, options);
      }
export type SetAccountConfigurationForDividendsForHoldingMutationHookResult = ReturnType<typeof useSetAccountConfigurationForDividendsForHoldingMutation>;
export type SetAccountConfigurationForDividendsForHoldingMutationResult = Apollo.MutationResult<Types.SetAccountConfigurationForDividendsForHoldingMutation>;
export type SetAccountConfigurationForDividendsForHoldingMutationOptions = Apollo.BaseMutationOptions<Types.SetAccountConfigurationForDividendsForHoldingMutation, Types.SetAccountConfigurationForDividendsForHoldingMutationVariables>;
export const SetDirectDepositDestinationDocument = gql`
    mutation SetDirectDepositDestination($input: SetDirectDepositDestinationInput!) {
  setDirectDepositDestination(input: $input) {
    didSucceed
    error
    outcome {
      successMessage
    }
  }
}
    `;
export type SetDirectDepositDestinationMutationFn = Apollo.MutationFunction<Types.SetDirectDepositDestinationMutation, Types.SetDirectDepositDestinationMutationVariables>;

/**
 * __useSetDirectDepositDestinationMutation__
 *
 * To run a mutation, you first call `useSetDirectDepositDestinationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDirectDepositDestinationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDirectDepositDestinationMutation, { data, loading, error }] = useSetDirectDepositDestinationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDirectDepositDestinationMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetDirectDepositDestinationMutation, Types.SetDirectDepositDestinationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetDirectDepositDestinationMutation, Types.SetDirectDepositDestinationMutationVariables>(SetDirectDepositDestinationDocument, options);
      }
export type SetDirectDepositDestinationMutationHookResult = ReturnType<typeof useSetDirectDepositDestinationMutation>;
export type SetDirectDepositDestinationMutationResult = Apollo.MutationResult<Types.SetDirectDepositDestinationMutation>;
export type SetDirectDepositDestinationMutationOptions = Apollo.BaseMutationOptions<Types.SetDirectDepositDestinationMutation, Types.SetDirectDepositDestinationMutationVariables>;
export const SetFullyPaidLendingStatusDocument = gql`
    mutation SetFullyPaidLendingStatus($input: SetFullyPaidLendingStatusInput!) {
  setFullyPaidLendingStatus(input: $input) {
    clientMutationId
    didSucceed
    outcome {
      successAnalyticsEvent {
        ...AnalyticsEvent
      }
    }
  }
}
    ${AnalyticsEventFragmentDoc}`;
export type SetFullyPaidLendingStatusMutationFn = Apollo.MutationFunction<Types.SetFullyPaidLendingStatusMutation, Types.SetFullyPaidLendingStatusMutationVariables>;

/**
 * __useSetFullyPaidLendingStatusMutation__
 *
 * To run a mutation, you first call `useSetFullyPaidLendingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFullyPaidLendingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFullyPaidLendingStatusMutation, { data, loading, error }] = useSetFullyPaidLendingStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetFullyPaidLendingStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetFullyPaidLendingStatusMutation, Types.SetFullyPaidLendingStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetFullyPaidLendingStatusMutation, Types.SetFullyPaidLendingStatusMutationVariables>(SetFullyPaidLendingStatusDocument, options);
      }
export type SetFullyPaidLendingStatusMutationHookResult = ReturnType<typeof useSetFullyPaidLendingStatusMutation>;
export type SetFullyPaidLendingStatusMutationResult = Apollo.MutationResult<Types.SetFullyPaidLendingStatusMutation>;
export type SetFullyPaidLendingStatusMutationOptions = Apollo.BaseMutationOptions<Types.SetFullyPaidLendingStatusMutation, Types.SetFullyPaidLendingStatusMutationVariables>;
export const UpdateFullyPaidLendingStatusDocument = gql`
    mutation updateFullyPaidLendingStatus($input: UpdateFullyPaidLendingStatusInput!) {
  updateFullyPaidLendingStatus(input: $input) {
    didSucceed
    error
  }
}
    `;
export type UpdateFullyPaidLendingStatusMutationFn = Apollo.MutationFunction<Types.UpdateFullyPaidLendingStatusMutation, Types.UpdateFullyPaidLendingStatusMutationVariables>;

/**
 * __useUpdateFullyPaidLendingStatusMutation__
 *
 * To run a mutation, you first call `useUpdateFullyPaidLendingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFullyPaidLendingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFullyPaidLendingStatusMutation, { data, loading, error }] = useUpdateFullyPaidLendingStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFullyPaidLendingStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateFullyPaidLendingStatusMutation, Types.UpdateFullyPaidLendingStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateFullyPaidLendingStatusMutation, Types.UpdateFullyPaidLendingStatusMutationVariables>(UpdateFullyPaidLendingStatusDocument, options);
      }
export type UpdateFullyPaidLendingStatusMutationHookResult = ReturnType<typeof useUpdateFullyPaidLendingStatusMutation>;
export type UpdateFullyPaidLendingStatusMutationResult = Apollo.MutationResult<Types.UpdateFullyPaidLendingStatusMutation>;
export type UpdateFullyPaidLendingStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateFullyPaidLendingStatusMutation, Types.UpdateFullyPaidLendingStatusMutationVariables>;
export const AcceptPersonalLoanDocument = gql`
    mutation AcceptPersonalLoan($input: AcceptPersonalLoanInput!) {
  acceptPersonalLoan(input: $input) {
    didSucceed
    outcome {
      loanDisbursement {
        title
        subtitle
        destinationList {
          id
          transferParticipantType
          transferParticipantName
          transferParticipantDescription
        }
        continueCta
      }
    }
    error
  }
}
    `;
export type AcceptPersonalLoanMutationFn = Apollo.MutationFunction<Types.AcceptPersonalLoanMutation, Types.AcceptPersonalLoanMutationVariables>;

/**
 * __useAcceptPersonalLoanMutation__
 *
 * To run a mutation, you first call `useAcceptPersonalLoanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptPersonalLoanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptPersonalLoanMutation, { data, loading, error }] = useAcceptPersonalLoanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptPersonalLoanMutation(baseOptions?: Apollo.MutationHookOptions<Types.AcceptPersonalLoanMutation, Types.AcceptPersonalLoanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AcceptPersonalLoanMutation, Types.AcceptPersonalLoanMutationVariables>(AcceptPersonalLoanDocument, options);
      }
export type AcceptPersonalLoanMutationHookResult = ReturnType<typeof useAcceptPersonalLoanMutation>;
export type AcceptPersonalLoanMutationResult = Apollo.MutationResult<Types.AcceptPersonalLoanMutation>;
export type AcceptPersonalLoanMutationOptions = Apollo.BaseMutationOptions<Types.AcceptPersonalLoanMutation, Types.AcceptPersonalLoanMutationVariables>;
export const AcceptPersonalLoanOfferDocument = gql`
    mutation AcceptPersonalLoanOffer($input: AcceptPersonalLoanOfferInput!) {
  acceptPersonalLoanOffer(input: $input) {
    didSucceed
    outcome {
      offerId
    }
    error
  }
}
    `;
export type AcceptPersonalLoanOfferMutationFn = Apollo.MutationFunction<Types.AcceptPersonalLoanOfferMutation, Types.AcceptPersonalLoanOfferMutationVariables>;

/**
 * __useAcceptPersonalLoanOfferMutation__
 *
 * To run a mutation, you first call `useAcceptPersonalLoanOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptPersonalLoanOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptPersonalLoanOfferMutation, { data, loading, error }] = useAcceptPersonalLoanOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptPersonalLoanOfferMutation(baseOptions?: Apollo.MutationHookOptions<Types.AcceptPersonalLoanOfferMutation, Types.AcceptPersonalLoanOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AcceptPersonalLoanOfferMutation, Types.AcceptPersonalLoanOfferMutationVariables>(AcceptPersonalLoanOfferDocument, options);
      }
export type AcceptPersonalLoanOfferMutationHookResult = ReturnType<typeof useAcceptPersonalLoanOfferMutation>;
export type AcceptPersonalLoanOfferMutationResult = Apollo.MutationResult<Types.AcceptPersonalLoanOfferMutation>;
export type AcceptPersonalLoanOfferMutationOptions = Apollo.BaseMutationOptions<Types.AcceptPersonalLoanOfferMutation, Types.AcceptPersonalLoanOfferMutationVariables>;
export const AutoPayApplicationSetUpDocument = gql`
    mutation AutoPayApplicationSetUp($input: AutoPayApplicationSetUpInput!) {
  autoPayApplicationSetUp(input: $input) {
    didSucceed
    error
  }
}
    `;
export type AutoPayApplicationSetUpMutationFn = Apollo.MutationFunction<Types.AutoPayApplicationSetUpMutation, Types.AutoPayApplicationSetUpMutationVariables>;

/**
 * __useAutoPayApplicationSetUpMutation__
 *
 * To run a mutation, you first call `useAutoPayApplicationSetUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAutoPayApplicationSetUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [autoPayApplicationSetUpMutation, { data, loading, error }] = useAutoPayApplicationSetUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAutoPayApplicationSetUpMutation(baseOptions?: Apollo.MutationHookOptions<Types.AutoPayApplicationSetUpMutation, Types.AutoPayApplicationSetUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AutoPayApplicationSetUpMutation, Types.AutoPayApplicationSetUpMutationVariables>(AutoPayApplicationSetUpDocument, options);
      }
export type AutoPayApplicationSetUpMutationHookResult = ReturnType<typeof useAutoPayApplicationSetUpMutation>;
export type AutoPayApplicationSetUpMutationResult = Apollo.MutationResult<Types.AutoPayApplicationSetUpMutation>;
export type AutoPayApplicationSetUpMutationOptions = Apollo.BaseMutationOptions<Types.AutoPayApplicationSetUpMutation, Types.AutoPayApplicationSetUpMutationVariables>;
export const CompletePlaidIncomeVerificationDocument = gql`
    mutation CompletePlaidIncomeVerification($input: CompletePlaidIncomeVerificationInput!) {
  completePlaidIncomeVerification(input: $input) {
    outcome {
      verificationSubmitted
    }
    didSucceed
    error
  }
}
    `;
export type CompletePlaidIncomeVerificationMutationFn = Apollo.MutationFunction<Types.CompletePlaidIncomeVerificationMutation, Types.CompletePlaidIncomeVerificationMutationVariables>;

/**
 * __useCompletePlaidIncomeVerificationMutation__
 *
 * To run a mutation, you first call `useCompletePlaidIncomeVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePlaidIncomeVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePlaidIncomeVerificationMutation, { data, loading, error }] = useCompletePlaidIncomeVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompletePlaidIncomeVerificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompletePlaidIncomeVerificationMutation, Types.CompletePlaidIncomeVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompletePlaidIncomeVerificationMutation, Types.CompletePlaidIncomeVerificationMutationVariables>(CompletePlaidIncomeVerificationDocument, options);
      }
export type CompletePlaidIncomeVerificationMutationHookResult = ReturnType<typeof useCompletePlaidIncomeVerificationMutation>;
export type CompletePlaidIncomeVerificationMutationResult = Apollo.MutationResult<Types.CompletePlaidIncomeVerificationMutation>;
export type CompletePlaidIncomeVerificationMutationOptions = Apollo.BaseMutationOptions<Types.CompletePlaidIncomeVerificationMutation, Types.CompletePlaidIncomeVerificationMutationVariables>;
export const GeneratePlaidIncomeLinkTokenDocument = gql`
    mutation GeneratePlaidIncomeLinkToken($input: GeneratePlaidIncomeLinkTokenInput!) {
  generatePlaidIncomeLinkToken(input: $input) {
    didSucceed
    outcome {
      plaidIncomeLinkToken
    }
  }
}
    `;
export type GeneratePlaidIncomeLinkTokenMutationFn = Apollo.MutationFunction<Types.GeneratePlaidIncomeLinkTokenMutation, Types.GeneratePlaidIncomeLinkTokenMutationVariables>;

/**
 * __useGeneratePlaidIncomeLinkTokenMutation__
 *
 * To run a mutation, you first call `useGeneratePlaidIncomeLinkTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePlaidIncomeLinkTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePlaidIncomeLinkTokenMutation, { data, loading, error }] = useGeneratePlaidIncomeLinkTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGeneratePlaidIncomeLinkTokenMutation(baseOptions?: Apollo.MutationHookOptions<Types.GeneratePlaidIncomeLinkTokenMutation, Types.GeneratePlaidIncomeLinkTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GeneratePlaidIncomeLinkTokenMutation, Types.GeneratePlaidIncomeLinkTokenMutationVariables>(GeneratePlaidIncomeLinkTokenDocument, options);
      }
export type GeneratePlaidIncomeLinkTokenMutationHookResult = ReturnType<typeof useGeneratePlaidIncomeLinkTokenMutation>;
export type GeneratePlaidIncomeLinkTokenMutationResult = Apollo.MutationResult<Types.GeneratePlaidIncomeLinkTokenMutation>;
export type GeneratePlaidIncomeLinkTokenMutationOptions = Apollo.BaseMutationOptions<Types.GeneratePlaidIncomeLinkTokenMutation, Types.GeneratePlaidIncomeLinkTokenMutationVariables>;
export const GeneratePlaidLinkItemAddClientTokenDocument = gql`
    mutation GeneratePlaidLinkItemAddClientToken($input: GeneratePlaidLinkItemAddClientTokenInput!) {
  generatePlaidLinkItemAddClientToken(input: $input) {
    didSucceed
    outcome {
      plaidLinkItemAddToken
    }
  }
}
    `;
export type GeneratePlaidLinkItemAddClientTokenMutationFn = Apollo.MutationFunction<Types.GeneratePlaidLinkItemAddClientTokenMutation, Types.GeneratePlaidLinkItemAddClientTokenMutationVariables>;

/**
 * __useGeneratePlaidLinkItemAddClientTokenMutation__
 *
 * To run a mutation, you first call `useGeneratePlaidLinkItemAddClientTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePlaidLinkItemAddClientTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePlaidLinkItemAddClientTokenMutation, { data, loading, error }] = useGeneratePlaidLinkItemAddClientTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGeneratePlaidLinkItemAddClientTokenMutation(baseOptions?: Apollo.MutationHookOptions<Types.GeneratePlaidLinkItemAddClientTokenMutation, Types.GeneratePlaidLinkItemAddClientTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GeneratePlaidLinkItemAddClientTokenMutation, Types.GeneratePlaidLinkItemAddClientTokenMutationVariables>(GeneratePlaidLinkItemAddClientTokenDocument, options);
      }
export type GeneratePlaidLinkItemAddClientTokenMutationHookResult = ReturnType<typeof useGeneratePlaidLinkItemAddClientTokenMutation>;
export type GeneratePlaidLinkItemAddClientTokenMutationResult = Apollo.MutationResult<Types.GeneratePlaidLinkItemAddClientTokenMutation>;
export type GeneratePlaidLinkItemAddClientTokenMutationOptions = Apollo.BaseMutationOptions<Types.GeneratePlaidLinkItemAddClientTokenMutation, Types.GeneratePlaidLinkItemAddClientTokenMutationVariables>;
export const QueueLoanDisbursementDocument = gql`
    mutation QueueLoanDisbursement($input: QueueLoanDisbursementInput!) {
  queueLoanDisbursement(input: $input) {
    didSucceed
    outcome {
      successAnalyticsEvent {
        ...AnalyticsEvent
      }
    }
    error
  }
}
    ${AnalyticsEventFragmentDoc}`;
export type QueueLoanDisbursementMutationFn = Apollo.MutationFunction<Types.QueueLoanDisbursementMutation, Types.QueueLoanDisbursementMutationVariables>;

/**
 * __useQueueLoanDisbursementMutation__
 *
 * To run a mutation, you first call `useQueueLoanDisbursementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQueueLoanDisbursementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [queueLoanDisbursementMutation, { data, loading, error }] = useQueueLoanDisbursementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueueLoanDisbursementMutation(baseOptions?: Apollo.MutationHookOptions<Types.QueueLoanDisbursementMutation, Types.QueueLoanDisbursementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.QueueLoanDisbursementMutation, Types.QueueLoanDisbursementMutationVariables>(QueueLoanDisbursementDocument, options);
      }
export type QueueLoanDisbursementMutationHookResult = ReturnType<typeof useQueueLoanDisbursementMutation>;
export type QueueLoanDisbursementMutationResult = Apollo.MutationResult<Types.QueueLoanDisbursementMutation>;
export type QueueLoanDisbursementMutationOptions = Apollo.BaseMutationOptions<Types.QueueLoanDisbursementMutation, Types.QueueLoanDisbursementMutationVariables>;
export const ResubmitLoanAfterCreditFreezeDocument = gql`
    mutation ResubmitLoanAfterCreditFreeze($input: ResubmitLoanAfterCreditFreezeInput!) {
  resubmitLoanAfterCreditFreeze(input: $input) {
    didSucceed
    outcome {
      applicationId
      loanId
    }
    error
  }
}
    `;
export type ResubmitLoanAfterCreditFreezeMutationFn = Apollo.MutationFunction<Types.ResubmitLoanAfterCreditFreezeMutation, Types.ResubmitLoanAfterCreditFreezeMutationVariables>;

/**
 * __useResubmitLoanAfterCreditFreezeMutation__
 *
 * To run a mutation, you first call `useResubmitLoanAfterCreditFreezeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResubmitLoanAfterCreditFreezeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resubmitLoanAfterCreditFreezeMutation, { data, loading, error }] = useResubmitLoanAfterCreditFreezeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResubmitLoanAfterCreditFreezeMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResubmitLoanAfterCreditFreezeMutation, Types.ResubmitLoanAfterCreditFreezeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResubmitLoanAfterCreditFreezeMutation, Types.ResubmitLoanAfterCreditFreezeMutationVariables>(ResubmitLoanAfterCreditFreezeDocument, options);
      }
export type ResubmitLoanAfterCreditFreezeMutationHookResult = ReturnType<typeof useResubmitLoanAfterCreditFreezeMutation>;
export type ResubmitLoanAfterCreditFreezeMutationResult = Apollo.MutationResult<Types.ResubmitLoanAfterCreditFreezeMutation>;
export type ResubmitLoanAfterCreditFreezeMutationOptions = Apollo.BaseMutationOptions<Types.ResubmitLoanAfterCreditFreezeMutation, Types.ResubmitLoanAfterCreditFreezeMutationVariables>;
export const SubmitPersonalLoanApplicationDocument = gql`
    mutation SubmitPersonalLoanApplication($input: SubmitPersonalLoanApplicationInput!) {
  submitPersonalLoanApplication(input: $input) {
    didSucceed
    outcome {
      applicationId
    }
  }
}
    `;
export type SubmitPersonalLoanApplicationMutationFn = Apollo.MutationFunction<Types.SubmitPersonalLoanApplicationMutation, Types.SubmitPersonalLoanApplicationMutationVariables>;

/**
 * __useSubmitPersonalLoanApplicationMutation__
 *
 * To run a mutation, you first call `useSubmitPersonalLoanApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPersonalLoanApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPersonalLoanApplicationMutation, { data, loading, error }] = useSubmitPersonalLoanApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitPersonalLoanApplicationMutation(baseOptions?: Apollo.MutationHookOptions<Types.SubmitPersonalLoanApplicationMutation, Types.SubmitPersonalLoanApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SubmitPersonalLoanApplicationMutation, Types.SubmitPersonalLoanApplicationMutationVariables>(SubmitPersonalLoanApplicationDocument, options);
      }
export type SubmitPersonalLoanApplicationMutationHookResult = ReturnType<typeof useSubmitPersonalLoanApplicationMutation>;
export type SubmitPersonalLoanApplicationMutationResult = Apollo.MutationResult<Types.SubmitPersonalLoanApplicationMutation>;
export type SubmitPersonalLoanApplicationMutationOptions = Apollo.BaseMutationOptions<Types.SubmitPersonalLoanApplicationMutation, Types.SubmitPersonalLoanApplicationMutationVariables>;
export const WithdrawPersonalLoanDocument = gql`
    mutation WithdrawPersonalLoan($input: WithdrawPersonalLoanInput!) {
  withdrawPersonalLoan(input: $input) {
    didSucceed
  }
}
    `;
export type WithdrawPersonalLoanMutationFn = Apollo.MutationFunction<Types.WithdrawPersonalLoanMutation, Types.WithdrawPersonalLoanMutationVariables>;

/**
 * __useWithdrawPersonalLoanMutation__
 *
 * To run a mutation, you first call `useWithdrawPersonalLoanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawPersonalLoanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawPersonalLoanMutation, { data, loading, error }] = useWithdrawPersonalLoanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawPersonalLoanMutation(baseOptions?: Apollo.MutationHookOptions<Types.WithdrawPersonalLoanMutation, Types.WithdrawPersonalLoanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WithdrawPersonalLoanMutation, Types.WithdrawPersonalLoanMutationVariables>(WithdrawPersonalLoanDocument, options);
      }
export type WithdrawPersonalLoanMutationHookResult = ReturnType<typeof useWithdrawPersonalLoanMutation>;
export type WithdrawPersonalLoanMutationResult = Apollo.MutationResult<Types.WithdrawPersonalLoanMutation>;
export type WithdrawPersonalLoanMutationOptions = Apollo.BaseMutationOptions<Types.WithdrawPersonalLoanMutation, Types.WithdrawPersonalLoanMutationVariables>;
export const WithdrawPersonalLoanApplicationDocument = gql`
    mutation WithdrawPersonalLoanApplication($input: WithdrawPersonalLoanApplicationInput!) {
  withdrawPersonalLoanApplication(input: $input) {
    didSucceed
    outcome {
      status
      successAnalyticsEvent {
        ...AnalyticsEvent
      }
    }
  }
}
    ${AnalyticsEventFragmentDoc}`;
export type WithdrawPersonalLoanApplicationMutationFn = Apollo.MutationFunction<Types.WithdrawPersonalLoanApplicationMutation, Types.WithdrawPersonalLoanApplicationMutationVariables>;

/**
 * __useWithdrawPersonalLoanApplicationMutation__
 *
 * To run a mutation, you first call `useWithdrawPersonalLoanApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawPersonalLoanApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawPersonalLoanApplicationMutation, { data, loading, error }] = useWithdrawPersonalLoanApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawPersonalLoanApplicationMutation(baseOptions?: Apollo.MutationHookOptions<Types.WithdrawPersonalLoanApplicationMutation, Types.WithdrawPersonalLoanApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.WithdrawPersonalLoanApplicationMutation, Types.WithdrawPersonalLoanApplicationMutationVariables>(WithdrawPersonalLoanApplicationDocument, options);
      }
export type WithdrawPersonalLoanApplicationMutationHookResult = ReturnType<typeof useWithdrawPersonalLoanApplicationMutation>;
export type WithdrawPersonalLoanApplicationMutationResult = Apollo.MutationResult<Types.WithdrawPersonalLoanApplicationMutation>;
export type WithdrawPersonalLoanApplicationMutationOptions = Apollo.BaseMutationOptions<Types.WithdrawPersonalLoanApplicationMutation, Types.WithdrawPersonalLoanApplicationMutationVariables>;
export const SetNotificationPreferencesDocument = gql`
    mutation SetNotificationPreferences($input: SetNotificationPreferencesInput!) {
  setNotificationPreferences(input: $input) {
    didSucceed
    error
    outcome {
      preferences {
        transferCompletion {
          sendViaEmail
          sendViaPush
        }
        investActivity {
          sendViaEmail
          sendViaPush
        }
        spendTransaction {
          sendViaEmail
          sendViaPush
        }
      }
    }
  }
}
    `;
export type SetNotificationPreferencesMutationFn = Apollo.MutationFunction<Types.SetNotificationPreferencesMutation, Types.SetNotificationPreferencesMutationVariables>;

/**
 * __useSetNotificationPreferencesMutation__
 *
 * To run a mutation, you first call `useSetNotificationPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNotificationPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNotificationPreferencesMutation, { data, loading, error }] = useSetNotificationPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetNotificationPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetNotificationPreferencesMutation, Types.SetNotificationPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetNotificationPreferencesMutation, Types.SetNotificationPreferencesMutationVariables>(SetNotificationPreferencesDocument, options);
      }
export type SetNotificationPreferencesMutationHookResult = ReturnType<typeof useSetNotificationPreferencesMutation>;
export type SetNotificationPreferencesMutationResult = Apollo.MutationResult<Types.SetNotificationPreferencesMutation>;
export type SetNotificationPreferencesMutationOptions = Apollo.BaseMutationOptions<Types.SetNotificationPreferencesMutation, Types.SetNotificationPreferencesMutationVariables>;
export const CompletePhoneVerificationDocument = gql`
    mutation CompletePhoneVerification($input: CompletePhoneVerificationInput!) {
  completePhoneVerification(input: $input) {
    didSucceed
    outcome {
      phone
    }
    error
  }
}
    `;
export type CompletePhoneVerificationMutationFn = Apollo.MutationFunction<Types.CompletePhoneVerificationMutation, Types.CompletePhoneVerificationMutationVariables>;

/**
 * __useCompletePhoneVerificationMutation__
 *
 * To run a mutation, you first call `useCompletePhoneVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePhoneVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePhoneVerificationMutation, { data, loading, error }] = useCompletePhoneVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompletePhoneVerificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompletePhoneVerificationMutation, Types.CompletePhoneVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompletePhoneVerificationMutation, Types.CompletePhoneVerificationMutationVariables>(CompletePhoneVerificationDocument, options);
      }
export type CompletePhoneVerificationMutationHookResult = ReturnType<typeof useCompletePhoneVerificationMutation>;
export type CompletePhoneVerificationMutationResult = Apollo.MutationResult<Types.CompletePhoneVerificationMutation>;
export type CompletePhoneVerificationMutationOptions = Apollo.BaseMutationOptions<Types.CompletePhoneVerificationMutation, Types.CompletePhoneVerificationMutationVariables>;
export const InitiatePhoneVerificationDocument = gql`
    mutation InitiatePhoneVerification($input: InitiatePhoneVerificationInput!) {
  initiatePhoneVerification(input: $input) {
    didSucceed
    outcome {
      phone
    }
    error
  }
}
    `;
export type InitiatePhoneVerificationMutationFn = Apollo.MutationFunction<Types.InitiatePhoneVerificationMutation, Types.InitiatePhoneVerificationMutationVariables>;

/**
 * __useInitiatePhoneVerificationMutation__
 *
 * To run a mutation, you first call `useInitiatePhoneVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiatePhoneVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiatePhoneVerificationMutation, { data, loading, error }] = useInitiatePhoneVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitiatePhoneVerificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.InitiatePhoneVerificationMutation, Types.InitiatePhoneVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InitiatePhoneVerificationMutation, Types.InitiatePhoneVerificationMutationVariables>(InitiatePhoneVerificationDocument, options);
      }
export type InitiatePhoneVerificationMutationHookResult = ReturnType<typeof useInitiatePhoneVerificationMutation>;
export type InitiatePhoneVerificationMutationResult = Apollo.MutationResult<Types.InitiatePhoneVerificationMutation>;
export type InitiatePhoneVerificationMutationOptions = Apollo.BaseMutationOptions<Types.InitiatePhoneVerificationMutation, Types.InitiatePhoneVerificationMutationVariables>;
export const RequestOfflineOpenAccountDocument = gql`
    mutation RequestOfflineOpenAccount($input: RequestOfflineOpenAccountInput!) {
  requestOfflineOpenAccount(input: $input) {
    result {
      didSucceed
    }
  }
}
    `;
export type RequestOfflineOpenAccountMutationFn = Apollo.MutationFunction<Types.RequestOfflineOpenAccountMutation, Types.RequestOfflineOpenAccountMutationVariables>;

/**
 * __useRequestOfflineOpenAccountMutation__
 *
 * To run a mutation, you first call `useRequestOfflineOpenAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestOfflineOpenAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestOfflineOpenAccountMutation, { data, loading, error }] = useRequestOfflineOpenAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestOfflineOpenAccountMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestOfflineOpenAccountMutation, Types.RequestOfflineOpenAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestOfflineOpenAccountMutation, Types.RequestOfflineOpenAccountMutationVariables>(RequestOfflineOpenAccountDocument, options);
      }
export type RequestOfflineOpenAccountMutationHookResult = ReturnType<typeof useRequestOfflineOpenAccountMutation>;
export type RequestOfflineOpenAccountMutationResult = Apollo.MutationResult<Types.RequestOfflineOpenAccountMutation>;
export type RequestOfflineOpenAccountMutationOptions = Apollo.BaseMutationOptions<Types.RequestOfflineOpenAccountMutation, Types.RequestOfflineOpenAccountMutationVariables>;
export const SetPreferredPlatformPaymentMethodDocument = gql`
    mutation SetPreferredPlatformPaymentMethod($input: SetPreferredPlatformPaymentMethodInput!) {
  setPreferredPlatformPaymentMethod(input: $input) {
    didSucceed
    error
    outcome {
      preferredPaymentMethodId
      successAnalyticsEvent {
        ...AnalyticsEvent
      }
    }
  }
}
    ${AnalyticsEventFragmentDoc}`;
export type SetPreferredPlatformPaymentMethodMutationFn = Apollo.MutationFunction<Types.SetPreferredPlatformPaymentMethodMutation, Types.SetPreferredPlatformPaymentMethodMutationVariables>;

/**
 * __useSetPreferredPlatformPaymentMethodMutation__
 *
 * To run a mutation, you first call `useSetPreferredPlatformPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPreferredPlatformPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPreferredPlatformPaymentMethodMutation, { data, loading, error }] = useSetPreferredPlatformPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPreferredPlatformPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetPreferredPlatformPaymentMethodMutation, Types.SetPreferredPlatformPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetPreferredPlatformPaymentMethodMutation, Types.SetPreferredPlatformPaymentMethodMutationVariables>(SetPreferredPlatformPaymentMethodDocument, options);
      }
export type SetPreferredPlatformPaymentMethodMutationHookResult = ReturnType<typeof useSetPreferredPlatformPaymentMethodMutation>;
export type SetPreferredPlatformPaymentMethodMutationResult = Apollo.MutationResult<Types.SetPreferredPlatformPaymentMethodMutation>;
export type SetPreferredPlatformPaymentMethodMutationOptions = Apollo.BaseMutationOptions<Types.SetPreferredPlatformPaymentMethodMutation, Types.SetPreferredPlatformPaymentMethodMutationVariables>;
export const ArchivePieDocument = gql`
    mutation ArchivePie($input: ArchivePieInput!) {
  archivePie(input: $input) {
    didSucceed
    error
  }
}
    `;
export type ArchivePieMutationFn = Apollo.MutationFunction<Types.ArchivePieMutation, Types.ArchivePieMutationVariables>;

/**
 * __useArchivePieMutation__
 *
 * To run a mutation, you first call `useArchivePieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchivePieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archivePieMutation, { data, loading, error }] = useArchivePieMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchivePieMutation(baseOptions?: Apollo.MutationHookOptions<Types.ArchivePieMutation, Types.ArchivePieMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ArchivePieMutation, Types.ArchivePieMutationVariables>(ArchivePieDocument, options);
      }
export type ArchivePieMutationHookResult = ReturnType<typeof useArchivePieMutation>;
export type ArchivePieMutationResult = Apollo.MutationResult<Types.ArchivePieMutation>;
export type ArchivePieMutationOptions = Apollo.BaseMutationOptions<Types.ArchivePieMutation, Types.ArchivePieMutationVariables>;
export const CreateProfileDocument = gql`
    mutation CreateProfile($input: CreateProfileInput!) {
  createProfile(input: $input) {
    didSucceed
    error
    outcome {
      userProfileId
    }
  }
}
    `;
export type CreateProfileMutationFn = Apollo.MutationFunction<Types.CreateProfileMutation, Types.CreateProfileMutationVariables>;

/**
 * __useCreateProfileMutation__
 *
 * To run a mutation, you first call `useCreateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfileMutation, { data, loading, error }] = useCreateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProfileMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateProfileMutation, Types.CreateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateProfileMutation, Types.CreateProfileMutationVariables>(CreateProfileDocument, options);
      }
export type CreateProfileMutationHookResult = ReturnType<typeof useCreateProfileMutation>;
export type CreateProfileMutationResult = Apollo.MutationResult<Types.CreateProfileMutation>;
export type CreateProfileMutationOptions = Apollo.BaseMutationOptions<Types.CreateProfileMutation, Types.CreateProfileMutationVariables>;
export const MoveSlicesDocument = gql`
    mutation MoveSlices($input: MoveSlicesInput!) {
  moveSlices(input: $input) {
    clientMutationId
    didSucceed
    errorMessage
    successMessage
  }
}
    `;
export type MoveSlicesMutationFn = Apollo.MutationFunction<Types.MoveSlicesMutation, Types.MoveSlicesMutationVariables>;

/**
 * __useMoveSlicesMutation__
 *
 * To run a mutation, you first call `useMoveSlicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveSlicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveSlicesMutation, { data, loading, error }] = useMoveSlicesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveSlicesMutation(baseOptions?: Apollo.MutationHookOptions<Types.MoveSlicesMutation, Types.MoveSlicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MoveSlicesMutation, Types.MoveSlicesMutationVariables>(MoveSlicesDocument, options);
      }
export type MoveSlicesMutationHookResult = ReturnType<typeof useMoveSlicesMutation>;
export type MoveSlicesMutationResult = Apollo.MutationResult<Types.MoveSlicesMutation>;
export type MoveSlicesMutationOptions = Apollo.BaseMutationOptions<Types.MoveSlicesMutation, Types.MoveSlicesMutationVariables>;
export const MoveSlicesBatchDocument = gql`
    mutation MoveSlicesBatch($input: MoveSlicesBatchInput!) {
  moveSlicesBatch(input: $input) {
    clientMutationId
    didSucceed
    outcome {
      pie {
        id
        name
        portfolioLinks {
          id
          to {
            id
            name
          }
          children {
            id
            percentage
            targetValuePercent
            to {
              id
            }
            portionOfParentValuePercent
            targetPortionOfAllValuePercent
          }
        }
      }
    }
  }
}
    `;
export type MoveSlicesBatchMutationFn = Apollo.MutationFunction<Types.MoveSlicesBatchMutation, Types.MoveSlicesBatchMutationVariables>;

/**
 * __useMoveSlicesBatchMutation__
 *
 * To run a mutation, you first call `useMoveSlicesBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveSlicesBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveSlicesBatchMutation, { data, loading, error }] = useMoveSlicesBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveSlicesBatchMutation(baseOptions?: Apollo.MutationHookOptions<Types.MoveSlicesBatchMutation, Types.MoveSlicesBatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MoveSlicesBatchMutation, Types.MoveSlicesBatchMutationVariables>(MoveSlicesBatchDocument, options);
      }
export type MoveSlicesBatchMutationHookResult = ReturnType<typeof useMoveSlicesBatchMutation>;
export type MoveSlicesBatchMutationResult = Apollo.MutationResult<Types.MoveSlicesBatchMutation>;
export type MoveSlicesBatchMutationOptions = Apollo.BaseMutationOptions<Types.MoveSlicesBatchMutation, Types.MoveSlicesBatchMutationVariables>;
export const MoveUnmanagedHoldingsDocument = gql`
    mutation MoveUnmanagedHoldings($input: MoveUnmanagedHoldingsInput!) {
  moveUnmanagedHoldings(input: $input) {
    didSucceed
    errorMessage
    successMessage
    account {
      receivedSecurities {
        ...UnmanagedHoldingsMoveDrawer
      }
    }
  }
}
    ${UnmanagedHoldingsMoveDrawerFragmentDoc}`;
export type MoveUnmanagedHoldingsMutationFn = Apollo.MutationFunction<Types.MoveUnmanagedHoldingsMutation, Types.MoveUnmanagedHoldingsMutationVariables>;

/**
 * __useMoveUnmanagedHoldingsMutation__
 *
 * To run a mutation, you first call `useMoveUnmanagedHoldingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveUnmanagedHoldingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveUnmanagedHoldingsMutation, { data, loading, error }] = useMoveUnmanagedHoldingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveUnmanagedHoldingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.MoveUnmanagedHoldingsMutation, Types.MoveUnmanagedHoldingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MoveUnmanagedHoldingsMutation, Types.MoveUnmanagedHoldingsMutationVariables>(MoveUnmanagedHoldingsDocument, options);
      }
export type MoveUnmanagedHoldingsMutationHookResult = ReturnType<typeof useMoveUnmanagedHoldingsMutation>;
export type MoveUnmanagedHoldingsMutationResult = Apollo.MutationResult<Types.MoveUnmanagedHoldingsMutation>;
export type MoveUnmanagedHoldingsMutationOptions = Apollo.BaseMutationOptions<Types.MoveUnmanagedHoldingsMutation, Types.MoveUnmanagedHoldingsMutationVariables>;
export const SetRootPieDocument = gql`
    mutation SetRootPie($input: SetRootPieInput!) {
  setRootPie(input: $input) {
    didSucceed
    error
    account {
      rootPortfolioSlice {
        id
      }
    }
    successAnalyticsEvent {
      ...AnalyticsEvent
    }
  }
}
    ${AnalyticsEventFragmentDoc}`;
export type SetRootPieMutationFn = Apollo.MutationFunction<Types.SetRootPieMutation, Types.SetRootPieMutationVariables>;

/**
 * __useSetRootPieMutation__
 *
 * To run a mutation, you first call `useSetRootPieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRootPieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRootPieMutation, { data, loading, error }] = useSetRootPieMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetRootPieMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetRootPieMutation, Types.SetRootPieMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetRootPieMutation, Types.SetRootPieMutationVariables>(SetRootPieDocument, options);
      }
export type SetRootPieMutationHookResult = ReturnType<typeof useSetRootPieMutation>;
export type SetRootPieMutationResult = Apollo.MutationResult<Types.SetRootPieMutation>;
export type SetRootPieMutationOptions = Apollo.BaseMutationOptions<Types.SetRootPieMutation, Types.SetRootPieMutationVariables>;
export const UpdatePieTreeDocument = gql`
    mutation UpdatePieTree($input: UpdatePieTreeInput!) {
  updatePieTree(input: $input) {
    result {
      didSucceed
      inputError
    }
    pie {
      ... on Node {
        ... on UserPie {
          id
          name
          portfolioLinks {
            id
            to {
              id
              name
            }
            children {
              id
              percentage
              targetValuePercent
              to {
                id
              }
              portionOfParentValuePercent
              targetPortionOfAllValuePercent
            }
          }
        }
      }
    }
    viewer {
      userPies(first: 1000) {
        edges {
          node {
            id
          }
        }
      }
      userCryptoPies: userPies(first: 1000, isCrypto: true) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
    `;
export type UpdatePieTreeMutationFn = Apollo.MutationFunction<Types.UpdatePieTreeMutation, Types.UpdatePieTreeMutationVariables>;

/**
 * __useUpdatePieTreeMutation__
 *
 * To run a mutation, you first call `useUpdatePieTreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePieTreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePieTreeMutation, { data, loading, error }] = useUpdatePieTreeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePieTreeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePieTreeMutation, Types.UpdatePieTreeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePieTreeMutation, Types.UpdatePieTreeMutationVariables>(UpdatePieTreeDocument, options);
      }
export type UpdatePieTreeMutationHookResult = ReturnType<typeof useUpdatePieTreeMutation>;
export type UpdatePieTreeMutationResult = Apollo.MutationResult<Types.UpdatePieTreeMutation>;
export type UpdatePieTreeMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePieTreeMutation, Types.UpdatePieTreeMutationVariables>;
export const DisableTwoFactorAuthDocument = gql`
    mutation DisableTwoFactorAuth($input: DisableTwoFactorAuthInput!) {
  disableTwoFactorAuth(input: $input) {
    didSucceed
  }
}
    `;
export type DisableTwoFactorAuthMutationFn = Apollo.MutationFunction<Types.DisableTwoFactorAuthMutation, Types.DisableTwoFactorAuthMutationVariables>;

/**
 * __useDisableTwoFactorAuthMutation__
 *
 * To run a mutation, you first call `useDisableTwoFactorAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableTwoFactorAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableTwoFactorAuthMutation, { data, loading, error }] = useDisableTwoFactorAuthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisableTwoFactorAuthMutation(baseOptions?: Apollo.MutationHookOptions<Types.DisableTwoFactorAuthMutation, Types.DisableTwoFactorAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DisableTwoFactorAuthMutation, Types.DisableTwoFactorAuthMutationVariables>(DisableTwoFactorAuthDocument, options);
      }
export type DisableTwoFactorAuthMutationHookResult = ReturnType<typeof useDisableTwoFactorAuthMutation>;
export type DisableTwoFactorAuthMutationResult = Apollo.MutationResult<Types.DisableTwoFactorAuthMutation>;
export type DisableTwoFactorAuthMutationOptions = Apollo.BaseMutationOptions<Types.DisableTwoFactorAuthMutation, Types.DisableTwoFactorAuthMutationVariables>;
export const DismissAnnouncementsDocument = gql`
    mutation DismissAnnouncements($input: DismissAnnouncementsInput!) {
  dismissAnnouncements(input: $input) {
    didSucceed
  }
}
    `;
export type DismissAnnouncementsMutationFn = Apollo.MutationFunction<Types.DismissAnnouncementsMutation, Types.DismissAnnouncementsMutationVariables>;

/**
 * __useDismissAnnouncementsMutation__
 *
 * To run a mutation, you first call `useDismissAnnouncementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissAnnouncementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissAnnouncementsMutation, { data, loading, error }] = useDismissAnnouncementsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDismissAnnouncementsMutation(baseOptions?: Apollo.MutationHookOptions<Types.DismissAnnouncementsMutation, Types.DismissAnnouncementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DismissAnnouncementsMutation, Types.DismissAnnouncementsMutationVariables>(DismissAnnouncementsDocument, options);
      }
export type DismissAnnouncementsMutationHookResult = ReturnType<typeof useDismissAnnouncementsMutation>;
export type DismissAnnouncementsMutationResult = Apollo.MutationResult<Types.DismissAnnouncementsMutation>;
export type DismissAnnouncementsMutationOptions = Apollo.BaseMutationOptions<Types.DismissAnnouncementsMutation, Types.DismissAnnouncementsMutationVariables>;
export const EnableTwoFactorAuthDocument = gql`
    mutation EnableTwoFactorAuth($input: EnableTwoFactorAuthInput!) {
  enableTwoFactorAuth(input: $input) {
    didSucceed
    error
  }
}
    `;
export type EnableTwoFactorAuthMutationFn = Apollo.MutationFunction<Types.EnableTwoFactorAuthMutation, Types.EnableTwoFactorAuthMutationVariables>;

/**
 * __useEnableTwoFactorAuthMutation__
 *
 * To run a mutation, you first call `useEnableTwoFactorAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableTwoFactorAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableTwoFactorAuthMutation, { data, loading, error }] = useEnableTwoFactorAuthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableTwoFactorAuthMutation(baseOptions?: Apollo.MutationHookOptions<Types.EnableTwoFactorAuthMutation, Types.EnableTwoFactorAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EnableTwoFactorAuthMutation, Types.EnableTwoFactorAuthMutationVariables>(EnableTwoFactorAuthDocument, options);
      }
export type EnableTwoFactorAuthMutationHookResult = ReturnType<typeof useEnableTwoFactorAuthMutation>;
export type EnableTwoFactorAuthMutationResult = Apollo.MutationResult<Types.EnableTwoFactorAuthMutation>;
export type EnableTwoFactorAuthMutationOptions = Apollo.BaseMutationOptions<Types.EnableTwoFactorAuthMutation, Types.EnableTwoFactorAuthMutationVariables>;
export const InitializeTwoFactorAuthDocument = gql`
    mutation InitializeTwoFactorAuth($input: InitializeTwoFactorAuthInput!) {
  initializeTwoFactorAuth(input: $input) {
    didSucceed
    error
    outcome {
      secret
      uri
    }
  }
}
    `;
export type InitializeTwoFactorAuthMutationFn = Apollo.MutationFunction<Types.InitializeTwoFactorAuthMutation, Types.InitializeTwoFactorAuthMutationVariables>;

/**
 * __useInitializeTwoFactorAuthMutation__
 *
 * To run a mutation, you first call `useInitializeTwoFactorAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitializeTwoFactorAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initializeTwoFactorAuthMutation, { data, loading, error }] = useInitializeTwoFactorAuthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitializeTwoFactorAuthMutation(baseOptions?: Apollo.MutationHookOptions<Types.InitializeTwoFactorAuthMutation, Types.InitializeTwoFactorAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InitializeTwoFactorAuthMutation, Types.InitializeTwoFactorAuthMutationVariables>(InitializeTwoFactorAuthDocument, options);
      }
export type InitializeTwoFactorAuthMutationHookResult = ReturnType<typeof useInitializeTwoFactorAuthMutation>;
export type InitializeTwoFactorAuthMutationResult = Apollo.MutationResult<Types.InitializeTwoFactorAuthMutation>;
export type InitializeTwoFactorAuthMutationOptions = Apollo.BaseMutationOptions<Types.InitializeTwoFactorAuthMutation, Types.InitializeTwoFactorAuthMutationVariables>;
export const RequestReferralsParticipationDocument = gql`
    mutation RequestReferralsParticipation($input: RequestReferralsParticipationInput!) {
  requestReferralsParticipation(input: $input) {
    outcome {
      user {
        id
        username
        referrals {
          isEligible
          isParticipating
          referrerStatus {
            completeReferralCount
            shareMessage
            shareUrl
          }
          getAmount
          giveAmount
          referralsTitle
          referralsDescription
          referralsTermsLink {
            ...Linkable
          }
        }
      }
    }
    didSucceed
    error
  }
}
    ${LinkableFragmentDoc}`;
export type RequestReferralsParticipationMutationFn = Apollo.MutationFunction<Types.RequestReferralsParticipationMutation, Types.RequestReferralsParticipationMutationVariables>;

/**
 * __useRequestReferralsParticipationMutation__
 *
 * To run a mutation, you first call `useRequestReferralsParticipationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestReferralsParticipationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestReferralsParticipationMutation, { data, loading, error }] = useRequestReferralsParticipationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestReferralsParticipationMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestReferralsParticipationMutation, Types.RequestReferralsParticipationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestReferralsParticipationMutation, Types.RequestReferralsParticipationMutationVariables>(RequestReferralsParticipationDocument, options);
      }
export type RequestReferralsParticipationMutationHookResult = ReturnType<typeof useRequestReferralsParticipationMutation>;
export type RequestReferralsParticipationMutationResult = Apollo.MutationResult<Types.RequestReferralsParticipationMutation>;
export type RequestReferralsParticipationMutationOptions = Apollo.BaseMutationOptions<Types.RequestReferralsParticipationMutation, Types.RequestReferralsParticipationMutationVariables>;
export const ResendEmailVerificationDocument = gql`
    mutation ResendEmailVerification($input: ResendEmailVerificationInput!) {
  resendEmailVerification(input: $input) {
    didSucceed
    error
  }
}
    `;
export type ResendEmailVerificationMutationFn = Apollo.MutationFunction<Types.ResendEmailVerificationMutation, Types.ResendEmailVerificationMutationVariables>;

/**
 * __useResendEmailVerificationMutation__
 *
 * To run a mutation, you first call `useResendEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailVerificationMutation, { data, loading, error }] = useResendEmailVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendEmailVerificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResendEmailVerificationMutation, Types.ResendEmailVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResendEmailVerificationMutation, Types.ResendEmailVerificationMutationVariables>(ResendEmailVerificationDocument, options);
      }
export type ResendEmailVerificationMutationHookResult = ReturnType<typeof useResendEmailVerificationMutation>;
export type ResendEmailVerificationMutationResult = Apollo.MutationResult<Types.ResendEmailVerificationMutation>;
export type ResendEmailVerificationMutationOptions = Apollo.BaseMutationOptions<Types.ResendEmailVerificationMutation, Types.ResendEmailVerificationMutationVariables>;
export const SignAgreementBundleDocument = gql`
    mutation SignAgreementBundle($input: SignAgreementBundleInput!) {
  signAgreementBundle(input: $input) {
    didSucceed
    error
    outcome {
      user {
        id
        agreementStatus {
          isNewAgreementRequired
        }
      }
    }
  }
}
    `;
export type SignAgreementBundleMutationFn = Apollo.MutationFunction<Types.SignAgreementBundleMutation, Types.SignAgreementBundleMutationVariables>;

/**
 * __useSignAgreementBundleMutation__
 *
 * To run a mutation, you first call `useSignAgreementBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignAgreementBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signAgreementBundleMutation, { data, loading, error }] = useSignAgreementBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignAgreementBundleMutation(baseOptions?: Apollo.MutationHookOptions<Types.SignAgreementBundleMutation, Types.SignAgreementBundleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignAgreementBundleMutation, Types.SignAgreementBundleMutationVariables>(SignAgreementBundleDocument, options);
      }
export type SignAgreementBundleMutationHookResult = ReturnType<typeof useSignAgreementBundleMutation>;
export type SignAgreementBundleMutationResult = Apollo.MutationResult<Types.SignAgreementBundleMutation>;
export type SignAgreementBundleMutationOptions = Apollo.BaseMutationOptions<Types.SignAgreementBundleMutation, Types.SignAgreementBundleMutationVariables>;
export const UpdateCustodialAccountBeneficiaryLegalAddressDocument = gql`
    mutation UpdateCustodialAccountBeneficiaryLegalAddress($input: UpdateCustodialAccountBeneficiaryLegalAddressInput!) {
  updateCustodialAccountBeneficiaryLegalAddress(input: $input) {
    didSucceed
    error
  }
}
    `;
export type UpdateCustodialAccountBeneficiaryLegalAddressMutationFn = Apollo.MutationFunction<Types.UpdateCustodialAccountBeneficiaryLegalAddressMutation, Types.UpdateCustodialAccountBeneficiaryLegalAddressMutationVariables>;

/**
 * __useUpdateCustodialAccountBeneficiaryLegalAddressMutation__
 *
 * To run a mutation, you first call `useUpdateCustodialAccountBeneficiaryLegalAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustodialAccountBeneficiaryLegalAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustodialAccountBeneficiaryLegalAddressMutation, { data, loading, error }] = useUpdateCustodialAccountBeneficiaryLegalAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustodialAccountBeneficiaryLegalAddressMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCustodialAccountBeneficiaryLegalAddressMutation, Types.UpdateCustodialAccountBeneficiaryLegalAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCustodialAccountBeneficiaryLegalAddressMutation, Types.UpdateCustodialAccountBeneficiaryLegalAddressMutationVariables>(UpdateCustodialAccountBeneficiaryLegalAddressDocument, options);
      }
export type UpdateCustodialAccountBeneficiaryLegalAddressMutationHookResult = ReturnType<typeof useUpdateCustodialAccountBeneficiaryLegalAddressMutation>;
export type UpdateCustodialAccountBeneficiaryLegalAddressMutationResult = Apollo.MutationResult<Types.UpdateCustodialAccountBeneficiaryLegalAddressMutation>;
export type UpdateCustodialAccountBeneficiaryLegalAddressMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCustodialAccountBeneficiaryLegalAddressMutation, Types.UpdateCustodialAccountBeneficiaryLegalAddressMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($input: UpdateProfileInput!) {
  updateProfile(input: $input) {
    didSucceed
    error
    outcome {
      viewer {
        profile {
          ...AccountProfiles
          primary {
            ...ProfileDisclosureDetails
            ...ProfileReviewAccountHolder
            ...AccountProfileEmployment
          }
          secondary {
            ...ProfileDisclosureDetails
            ...ProfileReviewAccountHolder
            ...AccountProfileEmployment
          }
          beneficiaries {
            ...BeneficiaryInfo
          }
          trustedContact {
            ...TrustedContactFields
          }
          suitability {
            ...ProfileSuitabilities
          }
        }
      }
    }
  }
}
    ${AccountProfilesFragmentDoc}
${ProfileDisclosureDetailsFragmentDoc}
${ProfileReviewAccountHolderFragmentDoc}
${AccountProfileEmploymentFragmentDoc}
${BeneficiaryInfoFragmentDoc}
${TrustedContactFieldsFragmentDoc}
${ProfileSuitabilitiesFragmentDoc}`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<Types.UpdateProfileMutation, Types.UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProfileMutation, Types.UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProfileMutation, Types.UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<Types.UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProfileMutation, Types.UpdateProfileMutationVariables>;
export const UpdateUserDataDocument = gql`
    mutation UpdateUserData($input: UpdateUserDataInput!) {
  updateUserData(input: $input) {
    didSucceed
  }
}
    `;
export type UpdateUserDataMutationFn = Apollo.MutationFunction<Types.UpdateUserDataMutation, Types.UpdateUserDataMutationVariables>;

/**
 * __useUpdateUserDataMutation__
 *
 * To run a mutation, you first call `useUpdateUserDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDataMutation, { data, loading, error }] = useUpdateUserDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserDataMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserDataMutation, Types.UpdateUserDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserDataMutation, Types.UpdateUserDataMutationVariables>(UpdateUserDataDocument, options);
      }
export type UpdateUserDataMutationHookResult = ReturnType<typeof useUpdateUserDataMutation>;
export type UpdateUserDataMutationResult = Apollo.MutationResult<Types.UpdateUserDataMutation>;
export type UpdateUserDataMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserDataMutation, Types.UpdateUserDataMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($input: VerifyEmailInput!) {
  verifyEmail(input: $input) {
    didSucceed
    error
  }
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<Types.VerifyEmailMutation, Types.VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.VerifyEmailMutation, Types.VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.VerifyEmailMutation, Types.VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<Types.VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<Types.VerifyEmailMutation, Types.VerifyEmailMutationVariables>;
export const AcceptPromotionCampaignDocument = gql`
    mutation acceptPromotionCampaign($input: AcceptPromotionCampaignInput!) {
  acceptPromotionCampaign(input: $input) {
    clientMutationId
    didSucceed
    error
    errorMessage
    outcome {
      successAnalyticsEvent {
        ...AnalyticsEvent
      }
    }
  }
}
    ${AnalyticsEventFragmentDoc}`;
export type AcceptPromotionCampaignMutationFn = Apollo.MutationFunction<Types.AcceptPromotionCampaignMutation, Types.AcceptPromotionCampaignMutationVariables>;

/**
 * __useAcceptPromotionCampaignMutation__
 *
 * To run a mutation, you first call `useAcceptPromotionCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptPromotionCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptPromotionCampaignMutation, { data, loading, error }] = useAcceptPromotionCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptPromotionCampaignMutation(baseOptions?: Apollo.MutationHookOptions<Types.AcceptPromotionCampaignMutation, Types.AcceptPromotionCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AcceptPromotionCampaignMutation, Types.AcceptPromotionCampaignMutationVariables>(AcceptPromotionCampaignDocument, options);
      }
export type AcceptPromotionCampaignMutationHookResult = ReturnType<typeof useAcceptPromotionCampaignMutation>;
export type AcceptPromotionCampaignMutationResult = Apollo.MutationResult<Types.AcceptPromotionCampaignMutation>;
export type AcceptPromotionCampaignMutationOptions = Apollo.BaseMutationOptions<Types.AcceptPromotionCampaignMutation, Types.AcceptPromotionCampaignMutationVariables>;
export const OpenHighYieldSavingsAccountDocument = gql`
    mutation OpenHighYieldSavingsAccount($input: OpenHighYieldSavingsAccountInput!) {
  openHighYieldSavingsAccount(input: $input) {
    didSucceed
    error
    outcome {
      accountId
      hasExternalFundingSource
      hasAvailableFundingSources
      initialTransferParticipant {
        id
        transferParticipantType
        transferParticipantName
      }
      savingsTransferParticipant {
        id
        transferParticipantType
        transferParticipantName
      }
      successAnalyticsEvent {
        ...AnalyticsEvent
      }
    }
  }
}
    ${AnalyticsEventFragmentDoc}`;
export type OpenHighYieldSavingsAccountMutationFn = Apollo.MutationFunction<Types.OpenHighYieldSavingsAccountMutation, Types.OpenHighYieldSavingsAccountMutationVariables>;

/**
 * __useOpenHighYieldSavingsAccountMutation__
 *
 * To run a mutation, you first call `useOpenHighYieldSavingsAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenHighYieldSavingsAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openHighYieldSavingsAccountMutation, { data, loading, error }] = useOpenHighYieldSavingsAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOpenHighYieldSavingsAccountMutation(baseOptions?: Apollo.MutationHookOptions<Types.OpenHighYieldSavingsAccountMutation, Types.OpenHighYieldSavingsAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.OpenHighYieldSavingsAccountMutation, Types.OpenHighYieldSavingsAccountMutationVariables>(OpenHighYieldSavingsAccountDocument, options);
      }
export type OpenHighYieldSavingsAccountMutationHookResult = ReturnType<typeof useOpenHighYieldSavingsAccountMutation>;
export type OpenHighYieldSavingsAccountMutationResult = Apollo.MutationResult<Types.OpenHighYieldSavingsAccountMutation>;
export type OpenHighYieldSavingsAccountMutationOptions = Apollo.BaseMutationOptions<Types.OpenHighYieldSavingsAccountMutation, Types.OpenHighYieldSavingsAccountMutationVariables>;
export const CreateSpendAccountDocumentLinkDocument = gql`
    mutation CreateSpendAccountDocumentLink($input: CreateSpendAccountDocumentLinkInput!) {
  createSpendAccountDocumentLink(input: $input) {
    didSucceed
    outcome {
      url
    }
    error
  }
}
    `;
export type CreateSpendAccountDocumentLinkMutationFn = Apollo.MutationFunction<Types.CreateSpendAccountDocumentLinkMutation, Types.CreateSpendAccountDocumentLinkMutationVariables>;

/**
 * __useCreateSpendAccountDocumentLinkMutation__
 *
 * To run a mutation, you first call `useCreateSpendAccountDocumentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpendAccountDocumentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpendAccountDocumentLinkMutation, { data, loading, error }] = useCreateSpendAccountDocumentLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSpendAccountDocumentLinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateSpendAccountDocumentLinkMutation, Types.CreateSpendAccountDocumentLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateSpendAccountDocumentLinkMutation, Types.CreateSpendAccountDocumentLinkMutationVariables>(CreateSpendAccountDocumentLinkDocument, options);
      }
export type CreateSpendAccountDocumentLinkMutationHookResult = ReturnType<typeof useCreateSpendAccountDocumentLinkMutation>;
export type CreateSpendAccountDocumentLinkMutationResult = Apollo.MutationResult<Types.CreateSpendAccountDocumentLinkMutation>;
export type CreateSpendAccountDocumentLinkMutationOptions = Apollo.BaseMutationOptions<Types.CreateSpendAccountDocumentLinkMutation, Types.CreateSpendAccountDocumentLinkMutationVariables>;
export const CreditCardPageDocument = gql`
    query CreditCardPage($keys: [String!]) {
  viewer {
    user {
      id
      data(keys: $keys) {
        key
        value
      }
    }
    credit {
      id
      hasActiveAccount
      hasClosedAccount
      hasSuspendedAccount
      activeApplication {
        id
        status
      }
    }
  }
}
    `;

/**
 * __useCreditCardPageQuery__
 *
 * To run a query within a React component, call `useCreditCardPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditCardPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditCardPageQuery({
 *   variables: {
 *      keys: // value for 'keys'
 *   },
 * });
 */
export function useCreditCardPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.CreditCardPageQuery, Types.CreditCardPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CreditCardPageQuery, Types.CreditCardPageQueryVariables>(CreditCardPageDocument, options);
      }
export function useCreditCardPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CreditCardPageQuery, Types.CreditCardPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CreditCardPageQuery, Types.CreditCardPageQueryVariables>(CreditCardPageDocument, options);
        }
export function useCreditCardPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CreditCardPageQuery, Types.CreditCardPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CreditCardPageQuery, Types.CreditCardPageQueryVariables>(CreditCardPageDocument, options);
        }
export type CreditCardPageQueryHookResult = ReturnType<typeof useCreditCardPageQuery>;
export type CreditCardPageLazyQueryHookResult = ReturnType<typeof useCreditCardPageLazyQuery>;
export type CreditCardPageSuspenseQueryHookResult = ReturnType<typeof useCreditCardPageSuspenseQuery>;
export type CreditCardPageQueryResult = Apollo.QueryResult<Types.CreditCardPageQuery, Types.CreditCardPageQueryVariables>;
export const MarkUnmanagedHoldingsForLiquidationDocument = gql`
    mutation MarkUnmanagedHoldingsForLiquidation($input: MarkUnmanagedHoldingsForLiquidationInput!) {
  markUnmanagedHoldingsForLiquidation(input: $input) {
    didSucceed
    errorMessage
    successMessage
  }
}
    `;
export type MarkUnmanagedHoldingsForLiquidationMutationFn = Apollo.MutationFunction<Types.MarkUnmanagedHoldingsForLiquidationMutation, Types.MarkUnmanagedHoldingsForLiquidationMutationVariables>;

/**
 * __useMarkUnmanagedHoldingsForLiquidationMutation__
 *
 * To run a mutation, you first call `useMarkUnmanagedHoldingsForLiquidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkUnmanagedHoldingsForLiquidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markUnmanagedHoldingsForLiquidationMutation, { data, loading, error }] = useMarkUnmanagedHoldingsForLiquidationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkUnmanagedHoldingsForLiquidationMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkUnmanagedHoldingsForLiquidationMutation, Types.MarkUnmanagedHoldingsForLiquidationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MarkUnmanagedHoldingsForLiquidationMutation, Types.MarkUnmanagedHoldingsForLiquidationMutationVariables>(MarkUnmanagedHoldingsForLiquidationDocument, options);
      }
export type MarkUnmanagedHoldingsForLiquidationMutationHookResult = ReturnType<typeof useMarkUnmanagedHoldingsForLiquidationMutation>;
export type MarkUnmanagedHoldingsForLiquidationMutationResult = Apollo.MutationResult<Types.MarkUnmanagedHoldingsForLiquidationMutation>;
export type MarkUnmanagedHoldingsForLiquidationMutationOptions = Apollo.BaseMutationOptions<Types.MarkUnmanagedHoldingsForLiquidationMutation, Types.MarkUnmanagedHoldingsForLiquidationMutationVariables>;
export const RebalancePortfolioSliceDocument = gql`
    mutation RebalancePortfolioSlice($input: RebalancePortfolioSliceInput!) {
  rebalancePortfolioSlice(input: $input) {
    didSucceed
    error
    outcome {
      account {
        id
        isTradingPaused
        isCryptoAccount
        preferredTradeWindow
        hasPreferredTradeWindowAccess
        trading {
          ...TradingStatus
        }
        estimatedTrading {
          ...EstimatedTrades
        }
        tradingPause {
          ...TradingPause
        }
        rootPortfolioSlice {
          ...PortfolioCashFlow
        }
        portfolioSlicesWithRebalances {
          id
        }
      }
      portfolioSlice {
        ...PortfolioCashFlow
      }
    }
  }
}
    ${TradingStatusFragmentDoc}
${EstimatedTradesFragmentDoc}
${TradingPauseFragmentDoc}
${PortfolioCashFlowFragmentDoc}`;
export type RebalancePortfolioSliceMutationFn = Apollo.MutationFunction<Types.RebalancePortfolioSliceMutation, Types.RebalancePortfolioSliceMutationVariables>;

/**
 * __useRebalancePortfolioSliceMutation__
 *
 * To run a mutation, you first call `useRebalancePortfolioSliceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRebalancePortfolioSliceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rebalancePortfolioSliceMutation, { data, loading, error }] = useRebalancePortfolioSliceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRebalancePortfolioSliceMutation(baseOptions?: Apollo.MutationHookOptions<Types.RebalancePortfolioSliceMutation, Types.RebalancePortfolioSliceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RebalancePortfolioSliceMutation, Types.RebalancePortfolioSliceMutationVariables>(RebalancePortfolioSliceDocument, options);
      }
export type RebalancePortfolioSliceMutationHookResult = ReturnType<typeof useRebalancePortfolioSliceMutation>;
export type RebalancePortfolioSliceMutationResult = Apollo.MutationResult<Types.RebalancePortfolioSliceMutation>;
export type RebalancePortfolioSliceMutationOptions = Apollo.BaseMutationOptions<Types.RebalancePortfolioSliceMutation, Types.RebalancePortfolioSliceMutationVariables>;
export const SetMaxCashThresholdDocument = gql`
    mutation SetMaxCashThreshold($input: SetMaxCashThresholdInput!) {
  setMaxCashThreshold(input: $input) {
    result {
      didSucceed
      inputError
    }
    account {
      id
      maxCashThreshold
      estimatedTrading {
        ...EstimatedTrades
      }
    }
  }
}
    ${EstimatedTradesFragmentDoc}`;
export type SetMaxCashThresholdMutationFn = Apollo.MutationFunction<Types.SetMaxCashThresholdMutation, Types.SetMaxCashThresholdMutationVariables>;

/**
 * __useSetMaxCashThresholdMutation__
 *
 * To run a mutation, you first call `useSetMaxCashThresholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMaxCashThresholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMaxCashThresholdMutation, { data, loading, error }] = useSetMaxCashThresholdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMaxCashThresholdMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetMaxCashThresholdMutation, Types.SetMaxCashThresholdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetMaxCashThresholdMutation, Types.SetMaxCashThresholdMutationVariables>(SetMaxCashThresholdDocument, options);
      }
export type SetMaxCashThresholdMutationHookResult = ReturnType<typeof useSetMaxCashThresholdMutation>;
export type SetMaxCashThresholdMutationResult = Apollo.MutationResult<Types.SetMaxCashThresholdMutation>;
export type SetMaxCashThresholdMutationOptions = Apollo.BaseMutationOptions<Types.SetMaxCashThresholdMutation, Types.SetMaxCashThresholdMutationVariables>;
export const SetPortfolioSliceOrderDocument = gql`
    mutation SetPortfolioSliceOrder($input: SetPortfolioSliceOrderInput!) {
  setPortfolioSliceOrder(input: $input) {
    didSucceed
    error
    outcome {
      account {
        id
        isTradingPaused
        isCryptoAccount
        preferredTradeWindow
        hasPreferredTradeWindowAccess
        trading {
          ...TradingStatus
        }
        estimatedTrading {
          ...EstimatedTrades
        }
        tradingPause {
          ...TradingPause
        }
        rootPortfolioSlice {
          ...PortfolioCashFlow
          ...PortfolioSliceBuyingPower
        }
      }
    }
  }
}
    ${TradingStatusFragmentDoc}
${EstimatedTradesFragmentDoc}
${TradingPauseFragmentDoc}
${PortfolioCashFlowFragmentDoc}
${PortfolioSliceBuyingPowerFragmentDoc}`;
export type SetPortfolioSliceOrderMutationFn = Apollo.MutationFunction<Types.SetPortfolioSliceOrderMutation, Types.SetPortfolioSliceOrderMutationVariables>;

/**
 * __useSetPortfolioSliceOrderMutation__
 *
 * To run a mutation, you first call `useSetPortfolioSliceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPortfolioSliceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPortfolioSliceOrderMutation, { data, loading, error }] = useSetPortfolioSliceOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPortfolioSliceOrderMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetPortfolioSliceOrderMutation, Types.SetPortfolioSliceOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetPortfolioSliceOrderMutation, Types.SetPortfolioSliceOrderMutationVariables>(SetPortfolioSliceOrderDocument, options);
      }
export type SetPortfolioSliceOrderMutationHookResult = ReturnType<typeof useSetPortfolioSliceOrderMutation>;
export type SetPortfolioSliceOrderMutationResult = Apollo.MutationResult<Types.SetPortfolioSliceOrderMutation>;
export type SetPortfolioSliceOrderMutationOptions = Apollo.BaseMutationOptions<Types.SetPortfolioSliceOrderMutation, Types.SetPortfolioSliceOrderMutationVariables>;
export const SetPreferredTradeWindowDocument = gql`
    mutation SetPreferredTradeWindow($input: SetPreferredTradeWindowInput!) {
  setPreferredTradeWindow(input: $input) {
    didSucceed
    outcome {
      account {
        id
        preferredTradeWindow
      }
    }
  }
}
    `;
export type SetPreferredTradeWindowMutationFn = Apollo.MutationFunction<Types.SetPreferredTradeWindowMutation, Types.SetPreferredTradeWindowMutationVariables>;

/**
 * __useSetPreferredTradeWindowMutation__
 *
 * To run a mutation, you first call `useSetPreferredTradeWindowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPreferredTradeWindowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPreferredTradeWindowMutation, { data, loading, error }] = useSetPreferredTradeWindowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPreferredTradeWindowMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetPreferredTradeWindowMutation, Types.SetPreferredTradeWindowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetPreferredTradeWindowMutation, Types.SetPreferredTradeWindowMutationVariables>(SetPreferredTradeWindowDocument, options);
      }
export type SetPreferredTradeWindowMutationHookResult = ReturnType<typeof useSetPreferredTradeWindowMutation>;
export type SetPreferredTradeWindowMutationResult = Apollo.MutationResult<Types.SetPreferredTradeWindowMutation>;
export type SetPreferredTradeWindowMutationOptions = Apollo.BaseMutationOptions<Types.SetPreferredTradeWindowMutation, Types.SetPreferredTradeWindowMutationVariables>;
export const UnmarkUnmanagedHoldingsForLiquidationDocument = gql`
    mutation UnmarkUnmanagedHoldingsForLiquidation($input: UnmarkUnmanagedHoldingsForLiquidationInput!) {
  unmarkUnmanagedHoldingsForLiquidation(input: $input) {
    didSucceed
    errorMessage
    successMessage
  }
}
    `;
export type UnmarkUnmanagedHoldingsForLiquidationMutationFn = Apollo.MutationFunction<Types.UnmarkUnmanagedHoldingsForLiquidationMutation, Types.UnmarkUnmanagedHoldingsForLiquidationMutationVariables>;

/**
 * __useUnmarkUnmanagedHoldingsForLiquidationMutation__
 *
 * To run a mutation, you first call `useUnmarkUnmanagedHoldingsForLiquidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnmarkUnmanagedHoldingsForLiquidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unmarkUnmanagedHoldingsForLiquidationMutation, { data, loading, error }] = useUnmarkUnmanagedHoldingsForLiquidationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnmarkUnmanagedHoldingsForLiquidationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnmarkUnmanagedHoldingsForLiquidationMutation, Types.UnmarkUnmanagedHoldingsForLiquidationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnmarkUnmanagedHoldingsForLiquidationMutation, Types.UnmarkUnmanagedHoldingsForLiquidationMutationVariables>(UnmarkUnmanagedHoldingsForLiquidationDocument, options);
      }
export type UnmarkUnmanagedHoldingsForLiquidationMutationHookResult = ReturnType<typeof useUnmarkUnmanagedHoldingsForLiquidationMutation>;
export type UnmarkUnmanagedHoldingsForLiquidationMutationResult = Apollo.MutationResult<Types.UnmarkUnmanagedHoldingsForLiquidationMutation>;
export type UnmarkUnmanagedHoldingsForLiquidationMutationOptions = Apollo.BaseMutationOptions<Types.UnmarkUnmanagedHoldingsForLiquidationMutation, Types.UnmarkUnmanagedHoldingsForLiquidationMutationVariables>;
export const ArchiveTransferRuleDocument = gql`
    mutation ArchiveTransferRule($input: ArchiveTransferRuleInput!) {
  archiveTransferRule(input: $input) {
    didSucceed
    error
  }
}
    `;
export type ArchiveTransferRuleMutationFn = Apollo.MutationFunction<Types.ArchiveTransferRuleMutation, Types.ArchiveTransferRuleMutationVariables>;

/**
 * __useArchiveTransferRuleMutation__
 *
 * To run a mutation, you first call `useArchiveTransferRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveTransferRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveTransferRuleMutation, { data, loading, error }] = useArchiveTransferRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveTransferRuleMutation(baseOptions?: Apollo.MutationHookOptions<Types.ArchiveTransferRuleMutation, Types.ArchiveTransferRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ArchiveTransferRuleMutation, Types.ArchiveTransferRuleMutationVariables>(ArchiveTransferRuleDocument, options);
      }
export type ArchiveTransferRuleMutationHookResult = ReturnType<typeof useArchiveTransferRuleMutation>;
export type ArchiveTransferRuleMutationResult = Apollo.MutationResult<Types.ArchiveTransferRuleMutation>;
export type ArchiveTransferRuleMutationOptions = Apollo.BaseMutationOptions<Types.ArchiveTransferRuleMutation, Types.ArchiveTransferRuleMutationVariables>;
export const CancelAchRelationshipDocument = gql`
    mutation CancelAchRelationship($input: CancelAchRelationshipInput!) {
  cancelAchRelationship(input: $input) {
    result {
      didSucceed
      inputError
    }
  }
}
    `;
export type CancelAchRelationshipMutationFn = Apollo.MutationFunction<Types.CancelAchRelationshipMutation, Types.CancelAchRelationshipMutationVariables>;

/**
 * __useCancelAchRelationshipMutation__
 *
 * To run a mutation, you first call `useCancelAchRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAchRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAchRelationshipMutation, { data, loading, error }] = useCancelAchRelationshipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelAchRelationshipMutation(baseOptions?: Apollo.MutationHookOptions<Types.CancelAchRelationshipMutation, Types.CancelAchRelationshipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CancelAchRelationshipMutation, Types.CancelAchRelationshipMutationVariables>(CancelAchRelationshipDocument, options);
      }
export type CancelAchRelationshipMutationHookResult = ReturnType<typeof useCancelAchRelationshipMutation>;
export type CancelAchRelationshipMutationResult = Apollo.MutationResult<Types.CancelAchRelationshipMutation>;
export type CancelAchRelationshipMutationOptions = Apollo.BaseMutationOptions<Types.CancelAchRelationshipMutation, Types.CancelAchRelationshipMutationVariables>;
export const CancelTransferInstanceDocument = gql`
    mutation CancelTransferInstance($input: CancelTransferInstanceInput!) {
  cancelTransferInstance(input: $input) {
    didSucceed
    error
  }
}
    `;
export type CancelTransferInstanceMutationFn = Apollo.MutationFunction<Types.CancelTransferInstanceMutation, Types.CancelTransferInstanceMutationVariables>;

/**
 * __useCancelTransferInstanceMutation__
 *
 * To run a mutation, you first call `useCancelTransferInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTransferInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTransferInstanceMutation, { data, loading, error }] = useCancelTransferInstanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelTransferInstanceMutation(baseOptions?: Apollo.MutationHookOptions<Types.CancelTransferInstanceMutation, Types.CancelTransferInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CancelTransferInstanceMutation, Types.CancelTransferInstanceMutationVariables>(CancelTransferInstanceDocument, options);
      }
export type CancelTransferInstanceMutationHookResult = ReturnType<typeof useCancelTransferInstanceMutation>;
export type CancelTransferInstanceMutationResult = Apollo.MutationResult<Types.CancelTransferInstanceMutation>;
export type CancelTransferInstanceMutationOptions = Apollo.BaseMutationOptions<Types.CancelTransferInstanceMutation, Types.CancelTransferInstanceMutationVariables>;
export const CancelTransferInstanceRefetchDocument = gql`
    query CancelTransferInstanceRefetch($first: Int!, $onlyManuallyCreated: Boolean!) {
  viewer {
    accounts(first: $first) {
      edges {
        node {
          balance {
            cash {
              buyingPower
              cryptoBuyingPower
              breakdownList {
                value
                label
                listItemType
                cashBalanceFloat
              }
            }
          }
        }
      }
    }
    transfers {
      pendingInstances(onlyManuallyCreated: $onlyManuallyCreated) {
        id
        from {
          transferParticipantName
          transferParticipantDescription
          transferParticipantType
        }
        to {
          transferParticipantName
          transferParticipantDescription
          transferParticipantType
        }
        status {
          code
          isPending
          isComplete
          isCanceled
          isFailed
        }
        amount
        isLiquidation
      }
    }
  }
}
    `;

/**
 * __useCancelTransferInstanceRefetchQuery__
 *
 * To run a query within a React component, call `useCancelTransferInstanceRefetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCancelTransferInstanceRefetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCancelTransferInstanceRefetchQuery({
 *   variables: {
 *      first: // value for 'first'
 *      onlyManuallyCreated: // value for 'onlyManuallyCreated'
 *   },
 * });
 */
export function useCancelTransferInstanceRefetchQuery(baseOptions: Apollo.QueryHookOptions<Types.CancelTransferInstanceRefetchQuery, Types.CancelTransferInstanceRefetchQueryVariables> & ({ variables: Types.CancelTransferInstanceRefetchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CancelTransferInstanceRefetchQuery, Types.CancelTransferInstanceRefetchQueryVariables>(CancelTransferInstanceRefetchDocument, options);
      }
export function useCancelTransferInstanceRefetchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CancelTransferInstanceRefetchQuery, Types.CancelTransferInstanceRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CancelTransferInstanceRefetchQuery, Types.CancelTransferInstanceRefetchQueryVariables>(CancelTransferInstanceRefetchDocument, options);
        }
export function useCancelTransferInstanceRefetchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CancelTransferInstanceRefetchQuery, Types.CancelTransferInstanceRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CancelTransferInstanceRefetchQuery, Types.CancelTransferInstanceRefetchQueryVariables>(CancelTransferInstanceRefetchDocument, options);
        }
export type CancelTransferInstanceRefetchQueryHookResult = ReturnType<typeof useCancelTransferInstanceRefetchQuery>;
export type CancelTransferInstanceRefetchLazyQueryHookResult = ReturnType<typeof useCancelTransferInstanceRefetchLazyQuery>;
export type CancelTransferInstanceRefetchSuspenseQueryHookResult = ReturnType<typeof useCancelTransferInstanceRefetchSuspenseQuery>;
export type CancelTransferInstanceRefetchQueryResult = Apollo.QueryResult<Types.CancelTransferInstanceRefetchQuery, Types.CancelTransferInstanceRefetchQueryVariables>;
export const ConfirmManualPlaidMicroDepositsDocument = gql`
    mutation ConfirmManualPlaidMicroDeposits($input: ConfirmManualPlaidMicroDepositsInput!) {
  confirmManualPlaidMicroDeposits(input: $input) {
    didSucceed
  }
}
    `;
export type ConfirmManualPlaidMicroDepositsMutationFn = Apollo.MutationFunction<Types.ConfirmManualPlaidMicroDepositsMutation, Types.ConfirmManualPlaidMicroDepositsMutationVariables>;

/**
 * __useConfirmManualPlaidMicroDepositsMutation__
 *
 * To run a mutation, you first call `useConfirmManualPlaidMicroDepositsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmManualPlaidMicroDepositsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmManualPlaidMicroDepositsMutation, { data, loading, error }] = useConfirmManualPlaidMicroDepositsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmManualPlaidMicroDepositsMutation(baseOptions?: Apollo.MutationHookOptions<Types.ConfirmManualPlaidMicroDepositsMutation, Types.ConfirmManualPlaidMicroDepositsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ConfirmManualPlaidMicroDepositsMutation, Types.ConfirmManualPlaidMicroDepositsMutationVariables>(ConfirmManualPlaidMicroDepositsDocument, options);
      }
export type ConfirmManualPlaidMicroDepositsMutationHookResult = ReturnType<typeof useConfirmManualPlaidMicroDepositsMutation>;
export type ConfirmManualPlaidMicroDepositsMutationResult = Apollo.MutationResult<Types.ConfirmManualPlaidMicroDepositsMutation>;
export type ConfirmManualPlaidMicroDepositsMutationOptions = Apollo.BaseMutationOptions<Types.ConfirmManualPlaidMicroDepositsMutation, Types.ConfirmManualPlaidMicroDepositsMutationVariables>;
export const CreateAchRelationshipViaDepositsDocument = gql`
    mutation CreateAchRelationshipViaDeposits($input: CreateAchRelationshipViaDepositsInput!) {
  createAchRelationshipViaDeposits(input: $input) {
    result {
      didSucceed
      inputError
    }
    achRelationship {
      id
    }
  }
}
    `;
export type CreateAchRelationshipViaDepositsMutationFn = Apollo.MutationFunction<Types.CreateAchRelationshipViaDepositsMutation, Types.CreateAchRelationshipViaDepositsMutationVariables>;

/**
 * __useCreateAchRelationshipViaDepositsMutation__
 *
 * To run a mutation, you first call `useCreateAchRelationshipViaDepositsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAchRelationshipViaDepositsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAchRelationshipViaDepositsMutation, { data, loading, error }] = useCreateAchRelationshipViaDepositsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAchRelationshipViaDepositsMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAchRelationshipViaDepositsMutation, Types.CreateAchRelationshipViaDepositsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateAchRelationshipViaDepositsMutation, Types.CreateAchRelationshipViaDepositsMutationVariables>(CreateAchRelationshipViaDepositsDocument, options);
      }
export type CreateAchRelationshipViaDepositsMutationHookResult = ReturnType<typeof useCreateAchRelationshipViaDepositsMutation>;
export type CreateAchRelationshipViaDepositsMutationResult = Apollo.MutationResult<Types.CreateAchRelationshipViaDepositsMutation>;
export type CreateAchRelationshipViaDepositsMutationOptions = Apollo.BaseMutationOptions<Types.CreateAchRelationshipViaDepositsMutation, Types.CreateAchRelationshipViaDepositsMutationVariables>;
export const CreateAchRelationshipViaFundingSourceDocument = gql`
    mutation CreateAchRelationshipViaFundingSource($input: CreateAchRelationshipViaFundingSourceInput!) {
  createAchRelationshipViaFundingSource(input: $input) {
    didSucceed
    outcome {
      achRelationship {
        id
      }
    }
    error
  }
}
    `;
export type CreateAchRelationshipViaFundingSourceMutationFn = Apollo.MutationFunction<Types.CreateAchRelationshipViaFundingSourceMutation, Types.CreateAchRelationshipViaFundingSourceMutationVariables>;

/**
 * __useCreateAchRelationshipViaFundingSourceMutation__
 *
 * To run a mutation, you first call `useCreateAchRelationshipViaFundingSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAchRelationshipViaFundingSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAchRelationshipViaFundingSourceMutation, { data, loading, error }] = useCreateAchRelationshipViaFundingSourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAchRelationshipViaFundingSourceMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAchRelationshipViaFundingSourceMutation, Types.CreateAchRelationshipViaFundingSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateAchRelationshipViaFundingSourceMutation, Types.CreateAchRelationshipViaFundingSourceMutationVariables>(CreateAchRelationshipViaFundingSourceDocument, options);
      }
export type CreateAchRelationshipViaFundingSourceMutationHookResult = ReturnType<typeof useCreateAchRelationshipViaFundingSourceMutation>;
export type CreateAchRelationshipViaFundingSourceMutationResult = Apollo.MutationResult<Types.CreateAchRelationshipViaFundingSourceMutation>;
export type CreateAchRelationshipViaFundingSourceMutationOptions = Apollo.BaseMutationOptions<Types.CreateAchRelationshipViaFundingSourceMutation, Types.CreateAchRelationshipViaFundingSourceMutationVariables>;
export const CreateFundingSourceRelationshipDocument = gql`
    mutation CreateFundingSourceRelationship($input: CreateFundingSourceRelationshipInput!) {
  createFundingSourceRelationship(input: $input) {
    didSucceed
    outcome {
      fundingSourceTransferParticipant {
        transferParticipantType
        transferParticipantName
        id
        hasPendingTransferInstances
      }
      internalTransferParticipant {
        transferParticipantType
        transferParticipantName
        id
        hasPendingTransferInstances
      }
    }
    error
  }
}
    `;
export type CreateFundingSourceRelationshipMutationFn = Apollo.MutationFunction<Types.CreateFundingSourceRelationshipMutation, Types.CreateFundingSourceRelationshipMutationVariables>;

/**
 * __useCreateFundingSourceRelationshipMutation__
 *
 * To run a mutation, you first call `useCreateFundingSourceRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFundingSourceRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFundingSourceRelationshipMutation, { data, loading, error }] = useCreateFundingSourceRelationshipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFundingSourceRelationshipMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateFundingSourceRelationshipMutation, Types.CreateFundingSourceRelationshipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateFundingSourceRelationshipMutation, Types.CreateFundingSourceRelationshipMutationVariables>(CreateFundingSourceRelationshipDocument, options);
      }
export type CreateFundingSourceRelationshipMutationHookResult = ReturnType<typeof useCreateFundingSourceRelationshipMutation>;
export type CreateFundingSourceRelationshipMutationResult = Apollo.MutationResult<Types.CreateFundingSourceRelationshipMutation>;
export type CreateFundingSourceRelationshipMutationOptions = Apollo.BaseMutationOptions<Types.CreateFundingSourceRelationshipMutation, Types.CreateFundingSourceRelationshipMutationVariables>;
export const CreateFundingSourceRelationshipRefetchDocument = gql`
    query CreateFundingSourceRelationshipRefetch {
  viewer {
    transfers {
      activeFundingSource {
        id
        externalName
      }
    }
  }
}
    `;

/**
 * __useCreateFundingSourceRelationshipRefetchQuery__
 *
 * To run a query within a React component, call `useCreateFundingSourceRelationshipRefetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateFundingSourceRelationshipRefetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateFundingSourceRelationshipRefetchQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateFundingSourceRelationshipRefetchQuery(baseOptions?: Apollo.QueryHookOptions<Types.CreateFundingSourceRelationshipRefetchQuery, Types.CreateFundingSourceRelationshipRefetchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CreateFundingSourceRelationshipRefetchQuery, Types.CreateFundingSourceRelationshipRefetchQueryVariables>(CreateFundingSourceRelationshipRefetchDocument, options);
      }
export function useCreateFundingSourceRelationshipRefetchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CreateFundingSourceRelationshipRefetchQuery, Types.CreateFundingSourceRelationshipRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CreateFundingSourceRelationshipRefetchQuery, Types.CreateFundingSourceRelationshipRefetchQueryVariables>(CreateFundingSourceRelationshipRefetchDocument, options);
        }
export function useCreateFundingSourceRelationshipRefetchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CreateFundingSourceRelationshipRefetchQuery, Types.CreateFundingSourceRelationshipRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CreateFundingSourceRelationshipRefetchQuery, Types.CreateFundingSourceRelationshipRefetchQueryVariables>(CreateFundingSourceRelationshipRefetchDocument, options);
        }
export type CreateFundingSourceRelationshipRefetchQueryHookResult = ReturnType<typeof useCreateFundingSourceRelationshipRefetchQuery>;
export type CreateFundingSourceRelationshipRefetchLazyQueryHookResult = ReturnType<typeof useCreateFundingSourceRelationshipRefetchLazyQuery>;
export type CreateFundingSourceRelationshipRefetchSuspenseQueryHookResult = ReturnType<typeof useCreateFundingSourceRelationshipRefetchSuspenseQuery>;
export type CreateFundingSourceRelationshipRefetchQueryResult = Apollo.QueryResult<Types.CreateFundingSourceRelationshipRefetchQuery, Types.CreateFundingSourceRelationshipRefetchQueryVariables>;
export const CreateIraTransferDocument = gql`
    mutation CreateIraTransfer($input: CreateIraTransferInput!) {
  createIraTransfer(input: $input) {
    didSucceed
    outcome {
      __typename
      instance {
        id
        to {
          transferParticipantType
        }
      }
      autoInvestImplications {
        maxCashThresholdOnDestinationAccount
        estimatedBuyTradesDestinationAmount
      }
      successAnalyticsEvent {
        ...AnalyticsEvent
      }
      details {
        ...TransferCreationDetails
      }
    }
    error
    errorMessage
  }
}
    ${AnalyticsEventFragmentDoc}
${TransferCreationDetailsFragmentDoc}`;
export type CreateIraTransferMutationFn = Apollo.MutationFunction<Types.CreateIraTransferMutation, Types.CreateIraTransferMutationVariables>;

/**
 * __useCreateIraTransferMutation__
 *
 * To run a mutation, you first call `useCreateIraTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIraTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIraTransferMutation, { data, loading, error }] = useCreateIraTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIraTransferMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateIraTransferMutation, Types.CreateIraTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateIraTransferMutation, Types.CreateIraTransferMutationVariables>(CreateIraTransferDocument, options);
      }
export type CreateIraTransferMutationHookResult = ReturnType<typeof useCreateIraTransferMutation>;
export type CreateIraTransferMutationResult = Apollo.MutationResult<Types.CreateIraTransferMutation>;
export type CreateIraTransferMutationOptions = Apollo.BaseMutationOptions<Types.CreateIraTransferMutation, Types.CreateIraTransferMutationVariables>;
export const CreateTransferInstanceDocument = gql`
    mutation CreateTransferInstance($input: CreateTransferInstanceInput!) {
  createTransferInstance(input: $input) {
    didSucceed
    outcome {
      __typename
      instance {
        id
        to {
          transferParticipantType
        }
      }
      autoInvestImplications {
        maxCashThresholdOnDestinationAccount
        estimatedBuyTradesDestinationAmount
      }
      successAnalyticsEvent {
        ...AnalyticsEvent
      }
      details {
        ...TransferCreationDetails
      }
    }
    error
  }
}
    ${AnalyticsEventFragmentDoc}
${TransferCreationDetailsFragmentDoc}`;
export type CreateTransferInstanceMutationFn = Apollo.MutationFunction<Types.CreateTransferInstanceMutation, Types.CreateTransferInstanceMutationVariables>;

/**
 * __useCreateTransferInstanceMutation__
 *
 * To run a mutation, you first call `useCreateTransferInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransferInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransferInstanceMutation, { data, loading, error }] = useCreateTransferInstanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTransferInstanceMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateTransferInstanceMutation, Types.CreateTransferInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateTransferInstanceMutation, Types.CreateTransferInstanceMutationVariables>(CreateTransferInstanceDocument, options);
      }
export type CreateTransferInstanceMutationHookResult = ReturnType<typeof useCreateTransferInstanceMutation>;
export type CreateTransferInstanceMutationResult = Apollo.MutationResult<Types.CreateTransferInstanceMutation>;
export type CreateTransferInstanceMutationOptions = Apollo.BaseMutationOptions<Types.CreateTransferInstanceMutation, Types.CreateTransferInstanceMutationVariables>;
export const CreateTransferInstanceRefetchDocument = gql`
    query CreateTransferInstanceRefetch {
  viewer {
    accounts(first: 20) {
      edges {
        node {
          balance {
            cash {
              available
              buyingPower
              cryptoBuyingPower
              investCashAvailable
              cryptoCashAvailable
            }
          }
          hasPendingTransferInstances
          id
          estimatedTrading {
            id
            hasTrades
          }
          pendingTransferInstances {
            amount
            id
            from {
              id
              transferParticipantName
            }
            to {
              id
              transferParticipantName
            }
          }
        }
      }
    }
    transfers {
      pendingInstances(onlyManuallyCreated: false) {
        id
        from {
          transferParticipantName
          transferParticipantDescription
          transferParticipantType
        }
        to {
          transferParticipantName
          transferParticipantDescription
          transferParticipantType
        }
        status {
          code
          isPending
          isComplete
          isCanceled
          isFailed
        }
        amount
        isLiquidation
      }
    }
  }
}
    `;

/**
 * __useCreateTransferInstanceRefetchQuery__
 *
 * To run a query within a React component, call `useCreateTransferInstanceRefetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateTransferInstanceRefetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateTransferInstanceRefetchQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateTransferInstanceRefetchQuery(baseOptions?: Apollo.QueryHookOptions<Types.CreateTransferInstanceRefetchQuery, Types.CreateTransferInstanceRefetchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CreateTransferInstanceRefetchQuery, Types.CreateTransferInstanceRefetchQueryVariables>(CreateTransferInstanceRefetchDocument, options);
      }
export function useCreateTransferInstanceRefetchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CreateTransferInstanceRefetchQuery, Types.CreateTransferInstanceRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CreateTransferInstanceRefetchQuery, Types.CreateTransferInstanceRefetchQueryVariables>(CreateTransferInstanceRefetchDocument, options);
        }
export function useCreateTransferInstanceRefetchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CreateTransferInstanceRefetchQuery, Types.CreateTransferInstanceRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CreateTransferInstanceRefetchQuery, Types.CreateTransferInstanceRefetchQueryVariables>(CreateTransferInstanceRefetchDocument, options);
        }
export type CreateTransferInstanceRefetchQueryHookResult = ReturnType<typeof useCreateTransferInstanceRefetchQuery>;
export type CreateTransferInstanceRefetchLazyQueryHookResult = ReturnType<typeof useCreateTransferInstanceRefetchLazyQuery>;
export type CreateTransferInstanceRefetchSuspenseQueryHookResult = ReturnType<typeof useCreateTransferInstanceRefetchSuspenseQuery>;
export type CreateTransferInstanceRefetchQueryResult = Apollo.QueryResult<Types.CreateTransferInstanceRefetchQuery, Types.CreateTransferInstanceRefetchQueryVariables>;
export const GenerateAtomicTokenDocument = gql`
    mutation GenerateAtomicToken($input: GenerateAtomicTokenInput!) {
  generateAtomicToken(input: $input) {
    didSucceed
    outcome {
      token
    }
    error
    errorMessage
  }
}
    `;
export type GenerateAtomicTokenMutationFn = Apollo.MutationFunction<Types.GenerateAtomicTokenMutation, Types.GenerateAtomicTokenMutationVariables>;

/**
 * __useGenerateAtomicTokenMutation__
 *
 * To run a mutation, you first call `useGenerateAtomicTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAtomicTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAtomicTokenMutation, { data, loading, error }] = useGenerateAtomicTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateAtomicTokenMutation(baseOptions?: Apollo.MutationHookOptions<Types.GenerateAtomicTokenMutation, Types.GenerateAtomicTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GenerateAtomicTokenMutation, Types.GenerateAtomicTokenMutationVariables>(GenerateAtomicTokenDocument, options);
      }
export type GenerateAtomicTokenMutationHookResult = ReturnType<typeof useGenerateAtomicTokenMutation>;
export type GenerateAtomicTokenMutationResult = Apollo.MutationResult<Types.GenerateAtomicTokenMutation>;
export type GenerateAtomicTokenMutationOptions = Apollo.BaseMutationOptions<Types.GenerateAtomicTokenMutation, Types.GenerateAtomicTokenMutationVariables>;
export const LinkExternalAccountsDocument = gql`
    mutation LinkExternalAccounts($input: LinkExternalAccountsInput!) {
  linkExternalAccounts(input: $input) {
    didSucceed
    error
  }
}
    `;
export type LinkExternalAccountsMutationFn = Apollo.MutationFunction<Types.LinkExternalAccountsMutation, Types.LinkExternalAccountsMutationVariables>;

/**
 * __useLinkExternalAccountsMutation__
 *
 * To run a mutation, you first call `useLinkExternalAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkExternalAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkExternalAccountsMutation, { data, loading, error }] = useLinkExternalAccountsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkExternalAccountsMutation(baseOptions?: Apollo.MutationHookOptions<Types.LinkExternalAccountsMutation, Types.LinkExternalAccountsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.LinkExternalAccountsMutation, Types.LinkExternalAccountsMutationVariables>(LinkExternalAccountsDocument, options);
      }
export type LinkExternalAccountsMutationHookResult = ReturnType<typeof useLinkExternalAccountsMutation>;
export type LinkExternalAccountsMutationResult = Apollo.MutationResult<Types.LinkExternalAccountsMutation>;
export type LinkExternalAccountsMutationOptions = Apollo.BaseMutationOptions<Types.LinkExternalAccountsMutation, Types.LinkExternalAccountsMutationVariables>;
export const LinkFundingSourcesDocument = gql`
    mutation LinkFundingSources($input: LinkFundingSourcesInput!) {
  linkFundingSources(input: $input) {
    didSucceed
    error
    outcome {
      linkedFundingSources {
        id
      }
    }
  }
}
    `;
export type LinkFundingSourcesMutationFn = Apollo.MutationFunction<Types.LinkFundingSourcesMutation, Types.LinkFundingSourcesMutationVariables>;

/**
 * __useLinkFundingSourcesMutation__
 *
 * To run a mutation, you first call `useLinkFundingSourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkFundingSourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkFundingSourcesMutation, { data, loading, error }] = useLinkFundingSourcesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkFundingSourcesMutation(baseOptions?: Apollo.MutationHookOptions<Types.LinkFundingSourcesMutation, Types.LinkFundingSourcesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.LinkFundingSourcesMutation, Types.LinkFundingSourcesMutationVariables>(LinkFundingSourcesDocument, options);
      }
export type LinkFundingSourcesMutationHookResult = ReturnType<typeof useLinkFundingSourcesMutation>;
export type LinkFundingSourcesMutationResult = Apollo.MutationResult<Types.LinkFundingSourcesMutation>;
export type LinkFundingSourcesMutationOptions = Apollo.BaseMutationOptions<Types.LinkFundingSourcesMutation, Types.LinkFundingSourcesMutationVariables>;
export const LinkFundingSourcesRefetchDocument = gql`
    query LinkFundingSourcesRefetch {
  viewer {
    fundingSources {
      hasFundingSources
      fundingSourcesGroup(first: 10) {
        edges {
          node {
            id
            status
            externalName
            createdAt
            updatedAt
            accountNumber
            verificationType
          }
        }
      }
    }
  }
}
    `;

/**
 * __useLinkFundingSourcesRefetchQuery__
 *
 * To run a query within a React component, call `useLinkFundingSourcesRefetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkFundingSourcesRefetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkFundingSourcesRefetchQuery({
 *   variables: {
 *   },
 * });
 */
export function useLinkFundingSourcesRefetchQuery(baseOptions?: Apollo.QueryHookOptions<Types.LinkFundingSourcesRefetchQuery, Types.LinkFundingSourcesRefetchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LinkFundingSourcesRefetchQuery, Types.LinkFundingSourcesRefetchQueryVariables>(LinkFundingSourcesRefetchDocument, options);
      }
export function useLinkFundingSourcesRefetchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LinkFundingSourcesRefetchQuery, Types.LinkFundingSourcesRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LinkFundingSourcesRefetchQuery, Types.LinkFundingSourcesRefetchQueryVariables>(LinkFundingSourcesRefetchDocument, options);
        }
export function useLinkFundingSourcesRefetchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LinkFundingSourcesRefetchQuery, Types.LinkFundingSourcesRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LinkFundingSourcesRefetchQuery, Types.LinkFundingSourcesRefetchQueryVariables>(LinkFundingSourcesRefetchDocument, options);
        }
export type LinkFundingSourcesRefetchQueryHookResult = ReturnType<typeof useLinkFundingSourcesRefetchQuery>;
export type LinkFundingSourcesRefetchLazyQueryHookResult = ReturnType<typeof useLinkFundingSourcesRefetchLazyQuery>;
export type LinkFundingSourcesRefetchSuspenseQueryHookResult = ReturnType<typeof useLinkFundingSourcesRefetchSuspenseQuery>;
export type LinkFundingSourcesRefetchQueryResult = Apollo.QueryResult<Types.LinkFundingSourcesRefetchQuery, Types.LinkFundingSourcesRefetchQueryVariables>;
export const SetEnabledOnTransferRuleDocument = gql`
    mutation SetEnabledOnTransferRule($input: SetEnabledOnTransferRuleInput!) {
  setEnabledOnTransferRule(input: $input) {
    didSucceed
    error
    outcome {
      rule {
        id
        isEnabled
      }
    }
  }
}
    `;
export type SetEnabledOnTransferRuleMutationFn = Apollo.MutationFunction<Types.SetEnabledOnTransferRuleMutation, Types.SetEnabledOnTransferRuleMutationVariables>;

/**
 * __useSetEnabledOnTransferRuleMutation__
 *
 * To run a mutation, you first call `useSetEnabledOnTransferRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEnabledOnTransferRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEnabledOnTransferRuleMutation, { data, loading, error }] = useSetEnabledOnTransferRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetEnabledOnTransferRuleMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetEnabledOnTransferRuleMutation, Types.SetEnabledOnTransferRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetEnabledOnTransferRuleMutation, Types.SetEnabledOnTransferRuleMutationVariables>(SetEnabledOnTransferRuleDocument, options);
      }
export type SetEnabledOnTransferRuleMutationHookResult = ReturnType<typeof useSetEnabledOnTransferRuleMutation>;
export type SetEnabledOnTransferRuleMutationResult = Apollo.MutationResult<Types.SetEnabledOnTransferRuleMutation>;
export type SetEnabledOnTransferRuleMutationOptions = Apollo.BaseMutationOptions<Types.SetEnabledOnTransferRuleMutation, Types.SetEnabledOnTransferRuleMutationVariables>;
export const SetScheduledTransferRuleDocument = gql`
    mutation SetScheduledTransferRule($input: SetScheduledTransferRuleInput!) {
  setScheduledTransferRule(input: $input) {
    didSucceed
    outcome {
      __typename
      rule {
        id
        ...ScheduledTransferRule
      }
      details {
        ...TransferCreationDetails
      }
      successAnalyticsEvent {
        ...AnalyticsEvent
      }
    }
    error
  }
}
    ${ScheduledTransferRuleFragmentDoc}
${TransferCreationDetailsFragmentDoc}
${AnalyticsEventFragmentDoc}`;
export type SetScheduledTransferRuleMutationFn = Apollo.MutationFunction<Types.SetScheduledTransferRuleMutation, Types.SetScheduledTransferRuleMutationVariables>;

/**
 * __useSetScheduledTransferRuleMutation__
 *
 * To run a mutation, you first call `useSetScheduledTransferRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetScheduledTransferRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setScheduledTransferRuleMutation, { data, loading, error }] = useSetScheduledTransferRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetScheduledTransferRuleMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetScheduledTransferRuleMutation, Types.SetScheduledTransferRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetScheduledTransferRuleMutation, Types.SetScheduledTransferRuleMutationVariables>(SetScheduledTransferRuleDocument, options);
      }
export type SetScheduledTransferRuleMutationHookResult = ReturnType<typeof useSetScheduledTransferRuleMutation>;
export type SetScheduledTransferRuleMutationResult = Apollo.MutationResult<Types.SetScheduledTransferRuleMutation>;
export type SetScheduledTransferRuleMutationOptions = Apollo.BaseMutationOptions<Types.SetScheduledTransferRuleMutation, Types.SetScheduledTransferRuleMutationVariables>;
export const SetScheduledTransferRuleRefetchDocument = gql`
    query SetScheduledTransferRuleRefetch {
  viewer {
    transfers {
      scheduledTransferRules {
        __typename
        id
        title
        from {
          transferParticipantName
          transferParticipantDescription
          transferParticipantType
        }
        to {
          transferParticipantName
          transferParticipantDescription
          transferParticipantType
        }
        ... on ScheduledTransferRule {
          amount
          schedule {
            title
          }
          latestInstance {
            startDate
          }
        }
        ... on TransferRule {
          hasPendingInstance
          isEnabled
        }
      }
    }
  }
}
    `;

/**
 * __useSetScheduledTransferRuleRefetchQuery__
 *
 * To run a query within a React component, call `useSetScheduledTransferRuleRefetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetScheduledTransferRuleRefetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetScheduledTransferRuleRefetchQuery({
 *   variables: {
 *   },
 * });
 */
export function useSetScheduledTransferRuleRefetchQuery(baseOptions?: Apollo.QueryHookOptions<Types.SetScheduledTransferRuleRefetchQuery, Types.SetScheduledTransferRuleRefetchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SetScheduledTransferRuleRefetchQuery, Types.SetScheduledTransferRuleRefetchQueryVariables>(SetScheduledTransferRuleRefetchDocument, options);
      }
export function useSetScheduledTransferRuleRefetchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SetScheduledTransferRuleRefetchQuery, Types.SetScheduledTransferRuleRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SetScheduledTransferRuleRefetchQuery, Types.SetScheduledTransferRuleRefetchQueryVariables>(SetScheduledTransferRuleRefetchDocument, options);
        }
export function useSetScheduledTransferRuleRefetchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SetScheduledTransferRuleRefetchQuery, Types.SetScheduledTransferRuleRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SetScheduledTransferRuleRefetchQuery, Types.SetScheduledTransferRuleRefetchQueryVariables>(SetScheduledTransferRuleRefetchDocument, options);
        }
export type SetScheduledTransferRuleRefetchQueryHookResult = ReturnType<typeof useSetScheduledTransferRuleRefetchQuery>;
export type SetScheduledTransferRuleRefetchLazyQueryHookResult = ReturnType<typeof useSetScheduledTransferRuleRefetchLazyQuery>;
export type SetScheduledTransferRuleRefetchSuspenseQueryHookResult = ReturnType<typeof useSetScheduledTransferRuleRefetchSuspenseQuery>;
export type SetScheduledTransferRuleRefetchQueryResult = Apollo.QueryResult<Types.SetScheduledTransferRuleRefetchQuery, Types.SetScheduledTransferRuleRefetchQueryVariables>;
export const SetSmartTransferRuleDocument = gql`
    mutation SetSmartTransferRule($input: SetSmartTransferRuleInput!) {
  setSmartTransferRule(input: $input) {
    didSucceed
    outcome {
      rule {
        id
      }
    }
    error
  }
}
    `;
export type SetSmartTransferRuleMutationFn = Apollo.MutationFunction<Types.SetSmartTransferRuleMutation, Types.SetSmartTransferRuleMutationVariables>;

/**
 * __useSetSmartTransferRuleMutation__
 *
 * To run a mutation, you first call `useSetSmartTransferRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSmartTransferRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSmartTransferRuleMutation, { data, loading, error }] = useSetSmartTransferRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSmartTransferRuleMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetSmartTransferRuleMutation, Types.SetSmartTransferRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetSmartTransferRuleMutation, Types.SetSmartTransferRuleMutationVariables>(SetSmartTransferRuleDocument, options);
      }
export type SetSmartTransferRuleMutationHookResult = ReturnType<typeof useSetSmartTransferRuleMutation>;
export type SetSmartTransferRuleMutationResult = Apollo.MutationResult<Types.SetSmartTransferRuleMutation>;
export type SetSmartTransferRuleMutationOptions = Apollo.BaseMutationOptions<Types.SetSmartTransferRuleMutation, Types.SetSmartTransferRuleMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
  changePassword(input: $input) {
    didSucceed
    error
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<Types.ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>;
export const InitializeTwoFactorVerificationDocument = gql`
    mutation InitializeTwoFactorVerification($input: InitializeTwoFactorVerificationInput!) {
  initializeTwoFactorVerification(input: $input) {
    didSucceed
    error
  }
}
    `;
export type InitializeTwoFactorVerificationMutationFn = Apollo.MutationFunction<Types.InitializeTwoFactorVerificationMutation, Types.InitializeTwoFactorVerificationMutationVariables>;

/**
 * __useInitializeTwoFactorVerificationMutation__
 *
 * To run a mutation, you first call `useInitializeTwoFactorVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitializeTwoFactorVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initializeTwoFactorVerificationMutation, { data, loading, error }] = useInitializeTwoFactorVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitializeTwoFactorVerificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.InitializeTwoFactorVerificationMutation, Types.InitializeTwoFactorVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InitializeTwoFactorVerificationMutation, Types.InitializeTwoFactorVerificationMutationVariables>(InitializeTwoFactorVerificationDocument, options);
      }
export type InitializeTwoFactorVerificationMutationHookResult = ReturnType<typeof useInitializeTwoFactorVerificationMutation>;
export type InitializeTwoFactorVerificationMutationResult = Apollo.MutationResult<Types.InitializeTwoFactorVerificationMutation>;
export type InitializeTwoFactorVerificationMutationOptions = Apollo.BaseMutationOptions<Types.InitializeTwoFactorVerificationMutation, Types.InitializeTwoFactorVerificationMutationVariables>;
export const InitiateResetPasswordDocument = gql`
    mutation InitiateResetPassword($input: InitiateResetPasswordInput!) {
  initiateResetPassword(input: $input) {
    didSucceed
    error
  }
}
    `;
export type InitiateResetPasswordMutationFn = Apollo.MutationFunction<Types.InitiateResetPasswordMutation, Types.InitiateResetPasswordMutationVariables>;

/**
 * __useInitiateResetPasswordMutation__
 *
 * To run a mutation, you first call `useInitiateResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateResetPasswordMutation, { data, loading, error }] = useInitiateResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitiateResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<Types.InitiateResetPasswordMutation, Types.InitiateResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InitiateResetPasswordMutation, Types.InitiateResetPasswordMutationVariables>(InitiateResetPasswordDocument, options);
      }
export type InitiateResetPasswordMutationHookResult = ReturnType<typeof useInitiateResetPasswordMutation>;
export type InitiateResetPasswordMutationResult = Apollo.MutationResult<Types.InitiateResetPasswordMutation>;
export type InitiateResetPasswordMutationOptions = Apollo.BaseMutationOptions<Types.InitiateResetPasswordMutation, Types.InitiateResetPasswordMutationVariables>;
export const ReauthenticateDocument = gql`
    mutation Reauthenticate($input: ReauthenticateInput!) {
  reauthenticate(input: $input) {
    didSucceed
    error
    outcome {
      accessToken
      refreshToken
    }
  }
}
    `;
export type ReauthenticateMutationFn = Apollo.MutationFunction<Types.ReauthenticateMutation, Types.ReauthenticateMutationVariables>;

/**
 * __useReauthenticateMutation__
 *
 * To run a mutation, you first call `useReauthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReauthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reauthenticateMutation, { data, loading, error }] = useReauthenticateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReauthenticateMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReauthenticateMutation, Types.ReauthenticateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ReauthenticateMutation, Types.ReauthenticateMutationVariables>(ReauthenticateDocument, options);
      }
export type ReauthenticateMutationHookResult = ReturnType<typeof useReauthenticateMutation>;
export type ReauthenticateMutationResult = Apollo.MutationResult<Types.ReauthenticateMutation>;
export type ReauthenticateMutationOptions = Apollo.BaseMutationOptions<Types.ReauthenticateMutation, Types.ReauthenticateMutationVariables>;
export const SetIntroductionSourceDocument = gql`
    mutation SetIntroductionSource($input: SetIntroductionSourceInput!) {
  setIntroductionSource(input: $input) {
    didSucceed
  }
}
    `;
export type SetIntroductionSourceMutationFn = Apollo.MutationFunction<Types.SetIntroductionSourceMutation, Types.SetIntroductionSourceMutationVariables>;

/**
 * __useSetIntroductionSourceMutation__
 *
 * To run a mutation, you first call `useSetIntroductionSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIntroductionSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIntroductionSourceMutation, { data, loading, error }] = useSetIntroductionSourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetIntroductionSourceMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetIntroductionSourceMutation, Types.SetIntroductionSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetIntroductionSourceMutation, Types.SetIntroductionSourceMutationVariables>(SetIntroductionSourceDocument, options);
      }
export type SetIntroductionSourceMutationHookResult = ReturnType<typeof useSetIntroductionSourceMutation>;
export type SetIntroductionSourceMutationResult = Apollo.MutationResult<Types.SetIntroductionSourceMutation>;
export type SetIntroductionSourceMutationOptions = Apollo.BaseMutationOptions<Types.SetIntroductionSourceMutation, Types.SetIntroductionSourceMutationVariables>;
export const StartCustomerSupportConversationDocument = gql`
    mutation StartCustomerSupportConversation($input: StartCustomerSupportConversationInput!) {
  startCustomerSupportConversation(input: $input) {
    didSucceed
    outcome {
      externalConversationId
    }
  }
}
    `;
export type StartCustomerSupportConversationMutationFn = Apollo.MutationFunction<Types.StartCustomerSupportConversationMutation, Types.StartCustomerSupportConversationMutationVariables>;

/**
 * __useStartCustomerSupportConversationMutation__
 *
 * To run a mutation, you first call `useStartCustomerSupportConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartCustomerSupportConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startCustomerSupportConversationMutation, { data, loading, error }] = useStartCustomerSupportConversationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartCustomerSupportConversationMutation(baseOptions?: Apollo.MutationHookOptions<Types.StartCustomerSupportConversationMutation, Types.StartCustomerSupportConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.StartCustomerSupportConversationMutation, Types.StartCustomerSupportConversationMutationVariables>(StartCustomerSupportConversationDocument, options);
      }
export type StartCustomerSupportConversationMutationHookResult = ReturnType<typeof useStartCustomerSupportConversationMutation>;
export type StartCustomerSupportConversationMutationResult = Apollo.MutationResult<Types.StartCustomerSupportConversationMutation>;
export type StartCustomerSupportConversationMutationOptions = Apollo.BaseMutationOptions<Types.StartCustomerSupportConversationMutation, Types.StartCustomerSupportConversationMutationVariables>;
export const SubmitUserFeedbackDocument = gql`
    mutation SubmitUserFeedback($input: SubmitUserFeedbackInput!) {
  submitUserFeedback(input: $input) {
    didSucceed
  }
}
    `;
export type SubmitUserFeedbackMutationFn = Apollo.MutationFunction<Types.SubmitUserFeedbackMutation, Types.SubmitUserFeedbackMutationVariables>;

/**
 * __useSubmitUserFeedbackMutation__
 *
 * To run a mutation, you first call `useSubmitUserFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitUserFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitUserFeedbackMutation, { data, loading, error }] = useSubmitUserFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitUserFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<Types.SubmitUserFeedbackMutation, Types.SubmitUserFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SubmitUserFeedbackMutation, Types.SubmitUserFeedbackMutationVariables>(SubmitUserFeedbackDocument, options);
      }
export type SubmitUserFeedbackMutationHookResult = ReturnType<typeof useSubmitUserFeedbackMutation>;
export type SubmitUserFeedbackMutationResult = Apollo.MutationResult<Types.SubmitUserFeedbackMutation>;
export type SubmitUserFeedbackMutationOptions = Apollo.BaseMutationOptions<Types.SubmitUserFeedbackMutation, Types.SubmitUserFeedbackMutationVariables>;
export const UpdateEmailDocument = gql`
    mutation UpdateEmail($input: UpdateEmailInput!) {
  updateEmail(input: $input) {
    didSucceed
    error
    successMessage
    errorMessage
  }
}
    `;
export type UpdateEmailMutationFn = Apollo.MutationFunction<Types.UpdateEmailMutation, Types.UpdateEmailMutationVariables>;

/**
 * __useUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailMutation, { data, loading, error }] = useUpdateEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEmailMutation, Types.UpdateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateEmailMutation, Types.UpdateEmailMutationVariables>(UpdateEmailDocument, options);
      }
export type UpdateEmailMutationHookResult = ReturnType<typeof useUpdateEmailMutation>;
export type UpdateEmailMutationResult = Apollo.MutationResult<Types.UpdateEmailMutation>;
export type UpdateEmailMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEmailMutation, Types.UpdateEmailMutationVariables>;
export const BootstrapAuthedUserDocument = gql`
    query BootstrapAuthedUser {
  viewer {
    accounts(first: 20, filterStatus: [NEW, OPENED, REJECTED, CLOSED]) {
      edges {
        node {
          id
          isActive
          registration
          originator
        }
      }
    }
    borrow {
      borrowAccounts {
        edges {
          node {
            id
            hasBorrowedBefore
          }
        }
      }
      personalLoans {
        loans {
          edges {
            node {
              id
              status
            }
          }
        }
      }
    }
    credit {
      activeAccount {
        id
      }
    }
    id
    user {
      correlationKey
      data(keys: ["watchlist"]) {
        key
        value
      }
      username
    }
    save {
      savings {
        hasSavingsAccounts
        savingsAccounts {
          edges {
            node {
              id
            }
          }
        }
      }
    }
    featureFlags {
      hasFeature(keys: ["M1_EMPLOYEE"])
    }
    netWorth {
      id
    }
  }
}
    `;

/**
 * __useBootstrapAuthedUserQuery__
 *
 * To run a query within a React component, call `useBootstrapAuthedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useBootstrapAuthedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBootstrapAuthedUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useBootstrapAuthedUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.BootstrapAuthedUserQuery, Types.BootstrapAuthedUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BootstrapAuthedUserQuery, Types.BootstrapAuthedUserQueryVariables>(BootstrapAuthedUserDocument, options);
      }
export function useBootstrapAuthedUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BootstrapAuthedUserQuery, Types.BootstrapAuthedUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BootstrapAuthedUserQuery, Types.BootstrapAuthedUserQueryVariables>(BootstrapAuthedUserDocument, options);
        }
export function useBootstrapAuthedUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BootstrapAuthedUserQuery, Types.BootstrapAuthedUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BootstrapAuthedUserQuery, Types.BootstrapAuthedUserQueryVariables>(BootstrapAuthedUserDocument, options);
        }
export type BootstrapAuthedUserQueryHookResult = ReturnType<typeof useBootstrapAuthedUserQuery>;
export type BootstrapAuthedUserLazyQueryHookResult = ReturnType<typeof useBootstrapAuthedUserLazyQuery>;
export type BootstrapAuthedUserSuspenseQueryHookResult = ReturnType<typeof useBootstrapAuthedUserSuspenseQuery>;
export type BootstrapAuthedUserQueryResult = Apollo.QueryResult<Types.BootstrapAuthedUserQuery, Types.BootstrapAuthedUserQueryVariables>;
export const InitializeUserDataDocument = gql`
    query InitializeUserData($watchlist: [ID!]!) {
  nodes(ids: $watchlist) {
    __typename
    id
    ... on Sliceable {
      isActive
    }
  }
}
    `;

/**
 * __useInitializeUserDataQuery__
 *
 * To run a query within a React component, call `useInitializeUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitializeUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitializeUserDataQuery({
 *   variables: {
 *      watchlist: // value for 'watchlist'
 *   },
 * });
 */
export function useInitializeUserDataQuery(baseOptions: Apollo.QueryHookOptions<Types.InitializeUserDataQuery, Types.InitializeUserDataQueryVariables> & ({ variables: Types.InitializeUserDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InitializeUserDataQuery, Types.InitializeUserDataQueryVariables>(InitializeUserDataDocument, options);
      }
export function useInitializeUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InitializeUserDataQuery, Types.InitializeUserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InitializeUserDataQuery, Types.InitializeUserDataQueryVariables>(InitializeUserDataDocument, options);
        }
export function useInitializeUserDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.InitializeUserDataQuery, Types.InitializeUserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.InitializeUserDataQuery, Types.InitializeUserDataQueryVariables>(InitializeUserDataDocument, options);
        }
export type InitializeUserDataQueryHookResult = ReturnType<typeof useInitializeUserDataQuery>;
export type InitializeUserDataLazyQueryHookResult = ReturnType<typeof useInitializeUserDataLazyQuery>;
export type InitializeUserDataSuspenseQueryHookResult = ReturnType<typeof useInitializeUserDataSuspenseQuery>;
export type InitializeUserDataQueryResult = Apollo.QueryResult<Types.InitializeUserDataQuery, Types.InitializeUserDataQueryVariables>;
export const ChatContextDocument = gql`
    query ChatContext {
  viewer {
    user {
      id
      username
      isPrimaryEmailVerified
      correlationKey
      intercomIdentityHash
    }
    profile {
      primary {
        firstName
        fullName
        phoneNumber
      }
    }
  }
}
    `;

/**
 * __useChatContextQuery__
 *
 * To run a query within a React component, call `useChatContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useChatContextQuery(baseOptions?: Apollo.QueryHookOptions<Types.ChatContextQuery, Types.ChatContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ChatContextQuery, Types.ChatContextQueryVariables>(ChatContextDocument, options);
      }
export function useChatContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ChatContextQuery, Types.ChatContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ChatContextQuery, Types.ChatContextQueryVariables>(ChatContextDocument, options);
        }
export function useChatContextSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ChatContextQuery, Types.ChatContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ChatContextQuery, Types.ChatContextQueryVariables>(ChatContextDocument, options);
        }
export type ChatContextQueryHookResult = ReturnType<typeof useChatContextQuery>;
export type ChatContextLazyQueryHookResult = ReturnType<typeof useChatContextLazyQuery>;
export type ChatContextSuspenseQueryHookResult = ReturnType<typeof useChatContextSuspenseQuery>;
export type ChatContextQueryResult = Apollo.QueryResult<Types.ChatContextQuery, Types.ChatContextQueryVariables>;
export const MoveMoneyOptionsDocument = gql`
    query MoveMoneyOptions {
  viewer {
    transfers {
      moveMoneyV2 {
        callToAction {
          title
        }
        listItems {
          primaryOptions {
            ...MoveMoneyListItem
          }
          secondaryOptions {
            ...MoveMoneyListItem
          }
        }
      }
    }
  }
}
    ${MoveMoneyListItemFragmentDoc}`;

/**
 * __useMoveMoneyOptionsQuery__
 *
 * To run a query within a React component, call `useMoveMoneyOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoveMoneyOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoveMoneyOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMoveMoneyOptionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.MoveMoneyOptionsQuery, Types.MoveMoneyOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MoveMoneyOptionsQuery, Types.MoveMoneyOptionsQueryVariables>(MoveMoneyOptionsDocument, options);
      }
export function useMoveMoneyOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MoveMoneyOptionsQuery, Types.MoveMoneyOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MoveMoneyOptionsQuery, Types.MoveMoneyOptionsQueryVariables>(MoveMoneyOptionsDocument, options);
        }
export function useMoveMoneyOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MoveMoneyOptionsQuery, Types.MoveMoneyOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MoveMoneyOptionsQuery, Types.MoveMoneyOptionsQueryVariables>(MoveMoneyOptionsDocument, options);
        }
export type MoveMoneyOptionsQueryHookResult = ReturnType<typeof useMoveMoneyOptionsQuery>;
export type MoveMoneyOptionsLazyQueryHookResult = ReturnType<typeof useMoveMoneyOptionsLazyQuery>;
export type MoveMoneyOptionsSuspenseQueryHookResult = ReturnType<typeof useMoveMoneyOptionsSuspenseQuery>;
export type MoveMoneyOptionsQueryResult = Apollo.QueryResult<Types.MoveMoneyOptionsQuery, Types.MoveMoneyOptionsQueryVariables>;
export const PromotionCampaignAcceptancePageDocument = gql`
    query PromotionCampaignAcceptancePage($promotionKey: PromotionCampaignKey!) {
  viewer {
    promotions {
      campaign(promotionKey: $promotionKey) {
        campaignId
        documentBundle {
          ...RequiredDocumentsBundle
        }
        acceptanceScreen {
          ...AppInfoPage
        }
      }
    }
  }
}
    ${RequiredDocumentsBundleFragmentDoc}
${AppInfoPageFragmentDoc}`;

/**
 * __usePromotionCampaignAcceptancePageQuery__
 *
 * To run a query within a React component, call `usePromotionCampaignAcceptancePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionCampaignAcceptancePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionCampaignAcceptancePageQuery({
 *   variables: {
 *      promotionKey: // value for 'promotionKey'
 *   },
 * });
 */
export function usePromotionCampaignAcceptancePageQuery(baseOptions: Apollo.QueryHookOptions<Types.PromotionCampaignAcceptancePageQuery, Types.PromotionCampaignAcceptancePageQueryVariables> & ({ variables: Types.PromotionCampaignAcceptancePageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PromotionCampaignAcceptancePageQuery, Types.PromotionCampaignAcceptancePageQueryVariables>(PromotionCampaignAcceptancePageDocument, options);
      }
export function usePromotionCampaignAcceptancePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PromotionCampaignAcceptancePageQuery, Types.PromotionCampaignAcceptancePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PromotionCampaignAcceptancePageQuery, Types.PromotionCampaignAcceptancePageQueryVariables>(PromotionCampaignAcceptancePageDocument, options);
        }
export function usePromotionCampaignAcceptancePageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PromotionCampaignAcceptancePageQuery, Types.PromotionCampaignAcceptancePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PromotionCampaignAcceptancePageQuery, Types.PromotionCampaignAcceptancePageQueryVariables>(PromotionCampaignAcceptancePageDocument, options);
        }
export type PromotionCampaignAcceptancePageQueryHookResult = ReturnType<typeof usePromotionCampaignAcceptancePageQuery>;
export type PromotionCampaignAcceptancePageLazyQueryHookResult = ReturnType<typeof usePromotionCampaignAcceptancePageLazyQuery>;
export type PromotionCampaignAcceptancePageSuspenseQueryHookResult = ReturnType<typeof usePromotionCampaignAcceptancePageSuspenseQuery>;
export type PromotionCampaignAcceptancePageQueryResult = Apollo.QueryResult<Types.PromotionCampaignAcceptancePageQuery, Types.PromotionCampaignAcceptancePageQueryVariables>;
export const AcatWizardDocument = gql`
    query AcatWizard($forIra: Boolean) {
  viewer {
    invest {
      acatWizard(forIra: $forIra) {
        id
        brokerageSelectionContent {
          header
          contraParticipants {
            edges {
              node {
                id
                name
                icon {
                  ...AppImage
                }
              }
            }
          }
        }
        transferInformationScreenContent {
          header
          footer
          ctaLabel
          transferDetails {
            icon {
              ...AppImage
            }
            title
            link {
              ...Linkable
            }
            content
          }
        }
        confirmSelectionsScreenContent {
          header
          ctaLabel
        }
        acatTransferTypeScreenContent {
          header
          headerLink {
            ...Linkable
          }
          selections {
            label
            sublabel
            navigation
            analyticsEvent {
              ...AnalyticsEvent
            }
          }
          ctaLabel
        }
        destinationAccountContent {
          header
          validParticipantsLabel
          informationalMessage {
            ...AppInformationalMessageCard
          }
          ctaLabel
          validParticipants {
            ... on AccountConnection {
              edges {
                node {
                  accountTypeWithNumberDescriptor
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${AppImageFragmentDoc}
${LinkableFragmentDoc}
${AnalyticsEventFragmentDoc}
${AppInformationalMessageCardFragmentDoc}`;

/**
 * __useAcatWizardQuery__
 *
 * To run a query within a React component, call `useAcatWizardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcatWizardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcatWizardQuery({
 *   variables: {
 *      forIra: // value for 'forIra'
 *   },
 * });
 */
export function useAcatWizardQuery(baseOptions?: Apollo.QueryHookOptions<Types.AcatWizardQuery, Types.AcatWizardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AcatWizardQuery, Types.AcatWizardQueryVariables>(AcatWizardDocument, options);
      }
export function useAcatWizardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AcatWizardQuery, Types.AcatWizardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AcatWizardQuery, Types.AcatWizardQueryVariables>(AcatWizardDocument, options);
        }
export function useAcatWizardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AcatWizardQuery, Types.AcatWizardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AcatWizardQuery, Types.AcatWizardQueryVariables>(AcatWizardDocument, options);
        }
export type AcatWizardQueryHookResult = ReturnType<typeof useAcatWizardQuery>;
export type AcatWizardLazyQueryHookResult = ReturnType<typeof useAcatWizardLazyQuery>;
export type AcatWizardSuspenseQueryHookResult = ReturnType<typeof useAcatWizardSuspenseQuery>;
export type AcatWizardQueryResult = Apollo.QueryResult<Types.AcatWizardQuery, Types.AcatWizardQueryVariables>;
export const AcatWizardConfirmTransferStepDocument = gql`
    query AcatWizardConfirmTransferStep($acatWizardId: ID!, $input: SubmitInvestAcatTransferInput) {
  acatWizard: node(id: $acatWizardId) {
    ... on InvestAcatWizardContent {
      confirmSelectionsScreenContent(input: $input) {
        ctaLabel
        destinationAccount {
          accountTypeWithNumberDescriptor
          id
          name
        }
        externalBrokerage {
          id
          name
        }
        header
        cash {
          header {
            ...RichText
          }
          amount
        }
        assets {
          header {
            ...RichText
          }
          assetsListLinkLabel
        }
        transferDetails {
          header
          details {
            icon {
              ...AppImage
            }
            body {
              ...RichText
            }
          }
        }
        sections {
          header
          entries {
            label
            value
          }
        }
      }
      transferInformationScreenContent {
        ctaLabel
        footer
        header
        transferDetails {
          content
          link {
            ...Linkable
          }
          icon {
            ...AppImage
          }
          title
        }
      }
    }
  }
}
    ${RichTextFragmentDoc}
${AppImageFragmentDoc}
${LinkableFragmentDoc}`;

/**
 * __useAcatWizardConfirmTransferStepQuery__
 *
 * To run a query within a React component, call `useAcatWizardConfirmTransferStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcatWizardConfirmTransferStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcatWizardConfirmTransferStepQuery({
 *   variables: {
 *      acatWizardId: // value for 'acatWizardId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcatWizardConfirmTransferStepQuery(baseOptions: Apollo.QueryHookOptions<Types.AcatWizardConfirmTransferStepQuery, Types.AcatWizardConfirmTransferStepQueryVariables> & ({ variables: Types.AcatWizardConfirmTransferStepQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AcatWizardConfirmTransferStepQuery, Types.AcatWizardConfirmTransferStepQueryVariables>(AcatWizardConfirmTransferStepDocument, options);
      }
export function useAcatWizardConfirmTransferStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AcatWizardConfirmTransferStepQuery, Types.AcatWizardConfirmTransferStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AcatWizardConfirmTransferStepQuery, Types.AcatWizardConfirmTransferStepQueryVariables>(AcatWizardConfirmTransferStepDocument, options);
        }
export function useAcatWizardConfirmTransferStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AcatWizardConfirmTransferStepQuery, Types.AcatWizardConfirmTransferStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AcatWizardConfirmTransferStepQuery, Types.AcatWizardConfirmTransferStepQueryVariables>(AcatWizardConfirmTransferStepDocument, options);
        }
export type AcatWizardConfirmTransferStepQueryHookResult = ReturnType<typeof useAcatWizardConfirmTransferStepQuery>;
export type AcatWizardConfirmTransferStepLazyQueryHookResult = ReturnType<typeof useAcatWizardConfirmTransferStepLazyQuery>;
export type AcatWizardConfirmTransferStepSuspenseQueryHookResult = ReturnType<typeof useAcatWizardConfirmTransferStepSuspenseQuery>;
export type AcatWizardConfirmTransferStepQueryResult = Apollo.QueryResult<Types.AcatWizardConfirmTransferStepQuery, Types.AcatWizardConfirmTransferStepQueryVariables>;
export const AcatWizardContraBrokerageDetailsStepDocument = gql`
    query AcatWizardContraBrokerageDetailsStep($id: ID!) {
  viewer {
    invest {
      acatWizard {
        brokerageAccountDetailsContent(investAcatWizardContraParticipantId: $id) {
          header
          accountNumberTextField {
            label
            placeholder
            minAccountLength
            maxAccountLength
          }
          informationalMessage {
            icon {
              ...AppImage
            }
            copy
          }
          ctaLabel
        }
      }
    }
  }
}
    ${AppImageFragmentDoc}`;

/**
 * __useAcatWizardContraBrokerageDetailsStepQuery__
 *
 * To run a query within a React component, call `useAcatWizardContraBrokerageDetailsStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcatWizardContraBrokerageDetailsStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcatWizardContraBrokerageDetailsStepQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcatWizardContraBrokerageDetailsStepQuery(baseOptions: Apollo.QueryHookOptions<Types.AcatWizardContraBrokerageDetailsStepQuery, Types.AcatWizardContraBrokerageDetailsStepQueryVariables> & ({ variables: Types.AcatWizardContraBrokerageDetailsStepQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AcatWizardContraBrokerageDetailsStepQuery, Types.AcatWizardContraBrokerageDetailsStepQueryVariables>(AcatWizardContraBrokerageDetailsStepDocument, options);
      }
export function useAcatWizardContraBrokerageDetailsStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AcatWizardContraBrokerageDetailsStepQuery, Types.AcatWizardContraBrokerageDetailsStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AcatWizardContraBrokerageDetailsStepQuery, Types.AcatWizardContraBrokerageDetailsStepQueryVariables>(AcatWizardContraBrokerageDetailsStepDocument, options);
        }
export function useAcatWizardContraBrokerageDetailsStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AcatWizardContraBrokerageDetailsStepQuery, Types.AcatWizardContraBrokerageDetailsStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AcatWizardContraBrokerageDetailsStepQuery, Types.AcatWizardContraBrokerageDetailsStepQueryVariables>(AcatWizardContraBrokerageDetailsStepDocument, options);
        }
export type AcatWizardContraBrokerageDetailsStepQueryHookResult = ReturnType<typeof useAcatWizardContraBrokerageDetailsStepQuery>;
export type AcatWizardContraBrokerageDetailsStepLazyQueryHookResult = ReturnType<typeof useAcatWizardContraBrokerageDetailsStepLazyQuery>;
export type AcatWizardContraBrokerageDetailsStepSuspenseQueryHookResult = ReturnType<typeof useAcatWizardContraBrokerageDetailsStepSuspenseQuery>;
export type AcatWizardContraBrokerageDetailsStepQueryResult = Apollo.QueryResult<Types.AcatWizardContraBrokerageDetailsStepQuery, Types.AcatWizardContraBrokerageDetailsStepQueryVariables>;
export const AcatWizardDestinationAccountStepDocument = gql`
    query AcatWizardDestinationAccountStep {
  viewer {
    invest {
      acatWizard {
        destinationAccountContent {
          header
          validParticipantsLabel
          informationalMessage {
            ...AppInformationalMessageCard
          }
          ctaLabel
          validParticipants {
            ... on AccountConnection {
              edges {
                node {
                  id
                  name
                  accountTypeWithNumberDescriptor
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${AppInformationalMessageCardFragmentDoc}`;

/**
 * __useAcatWizardDestinationAccountStepQuery__
 *
 * To run a query within a React component, call `useAcatWizardDestinationAccountStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcatWizardDestinationAccountStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcatWizardDestinationAccountStepQuery({
 *   variables: {
 *   },
 * });
 */
export function useAcatWizardDestinationAccountStepQuery(baseOptions?: Apollo.QueryHookOptions<Types.AcatWizardDestinationAccountStepQuery, Types.AcatWizardDestinationAccountStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AcatWizardDestinationAccountStepQuery, Types.AcatWizardDestinationAccountStepQueryVariables>(AcatWizardDestinationAccountStepDocument, options);
      }
export function useAcatWizardDestinationAccountStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AcatWizardDestinationAccountStepQuery, Types.AcatWizardDestinationAccountStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AcatWizardDestinationAccountStepQuery, Types.AcatWizardDestinationAccountStepQueryVariables>(AcatWizardDestinationAccountStepDocument, options);
        }
export function useAcatWizardDestinationAccountStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AcatWizardDestinationAccountStepQuery, Types.AcatWizardDestinationAccountStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AcatWizardDestinationAccountStepQuery, Types.AcatWizardDestinationAccountStepQueryVariables>(AcatWizardDestinationAccountStepDocument, options);
        }
export type AcatWizardDestinationAccountStepQueryHookResult = ReturnType<typeof useAcatWizardDestinationAccountStepQuery>;
export type AcatWizardDestinationAccountStepLazyQueryHookResult = ReturnType<typeof useAcatWizardDestinationAccountStepLazyQuery>;
export type AcatWizardDestinationAccountStepSuspenseQueryHookResult = ReturnType<typeof useAcatWizardDestinationAccountStepSuspenseQuery>;
export type AcatWizardDestinationAccountStepQueryResult = Apollo.QueryResult<Types.AcatWizardDestinationAccountStepQuery, Types.AcatWizardDestinationAccountStepQueryVariables>;
export const AcatWizardRolloverInitiateDocument = gql`
    query AcatWizardRolloverInitiate {
  viewer {
    invest {
      acatWizard {
        rolloverCreationScreenContent {
          initiateRolloverWebviewUrl
        }
      }
    }
  }
}
    `;

/**
 * __useAcatWizardRolloverInitiateQuery__
 *
 * To run a query within a React component, call `useAcatWizardRolloverInitiateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcatWizardRolloverInitiateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcatWizardRolloverInitiateQuery({
 *   variables: {
 *   },
 * });
 */
export function useAcatWizardRolloverInitiateQuery(baseOptions?: Apollo.QueryHookOptions<Types.AcatWizardRolloverInitiateQuery, Types.AcatWizardRolloverInitiateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AcatWizardRolloverInitiateQuery, Types.AcatWizardRolloverInitiateQueryVariables>(AcatWizardRolloverInitiateDocument, options);
      }
export function useAcatWizardRolloverInitiateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AcatWizardRolloverInitiateQuery, Types.AcatWizardRolloverInitiateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AcatWizardRolloverInitiateQuery, Types.AcatWizardRolloverInitiateQueryVariables>(AcatWizardRolloverInitiateDocument, options);
        }
export function useAcatWizardRolloverInitiateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AcatWizardRolloverInitiateQuery, Types.AcatWizardRolloverInitiateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AcatWizardRolloverInitiateQuery, Types.AcatWizardRolloverInitiateQueryVariables>(AcatWizardRolloverInitiateDocument, options);
        }
export type AcatWizardRolloverInitiateQueryHookResult = ReturnType<typeof useAcatWizardRolloverInitiateQuery>;
export type AcatWizardRolloverInitiateLazyQueryHookResult = ReturnType<typeof useAcatWizardRolloverInitiateLazyQuery>;
export type AcatWizardRolloverInitiateSuspenseQueryHookResult = ReturnType<typeof useAcatWizardRolloverInitiateSuspenseQuery>;
export type AcatWizardRolloverInitiateQueryResult = Apollo.QueryResult<Types.AcatWizardRolloverInitiateQuery, Types.AcatWizardRolloverInitiateQueryVariables>;
export const AcatWizardRolloverOverviewDocument = gql`
    query AcatWizardRolloverOverview {
  viewer {
    invest {
      acatWizard {
        id
        rolloverInformationScreenContent {
          ...AppInfoPage
        }
      }
    }
  }
}
    ${AppInfoPageFragmentDoc}`;

/**
 * __useAcatWizardRolloverOverviewQuery__
 *
 * To run a query within a React component, call `useAcatWizardRolloverOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcatWizardRolloverOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcatWizardRolloverOverviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useAcatWizardRolloverOverviewQuery(baseOptions?: Apollo.QueryHookOptions<Types.AcatWizardRolloverOverviewQuery, Types.AcatWizardRolloverOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AcatWizardRolloverOverviewQuery, Types.AcatWizardRolloverOverviewQueryVariables>(AcatWizardRolloverOverviewDocument, options);
      }
export function useAcatWizardRolloverOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AcatWizardRolloverOverviewQuery, Types.AcatWizardRolloverOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AcatWizardRolloverOverviewQuery, Types.AcatWizardRolloverOverviewQueryVariables>(AcatWizardRolloverOverviewDocument, options);
        }
export function useAcatWizardRolloverOverviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AcatWizardRolloverOverviewQuery, Types.AcatWizardRolloverOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AcatWizardRolloverOverviewQuery, Types.AcatWizardRolloverOverviewQueryVariables>(AcatWizardRolloverOverviewDocument, options);
        }
export type AcatWizardRolloverOverviewQueryHookResult = ReturnType<typeof useAcatWizardRolloverOverviewQuery>;
export type AcatWizardRolloverOverviewLazyQueryHookResult = ReturnType<typeof useAcatWizardRolloverOverviewLazyQuery>;
export type AcatWizardRolloverOverviewSuspenseQueryHookResult = ReturnType<typeof useAcatWizardRolloverOverviewSuspenseQuery>;
export type AcatWizardRolloverOverviewQueryResult = Apollo.QueryResult<Types.AcatWizardRolloverOverviewQuery, Types.AcatWizardRolloverOverviewQueryVariables>;
export const AcatWizardSelectAccountTypeStepDocument = gql`
    query AcatWizardSelectAccountTypeStep {
  viewer {
    invest {
      acatWizard {
        acatTransferTypeScreenContent {
          header
          headerLink {
            ...Linkable
          }
          selections {
            label
            sublabel
            navigation
          }
          ctaLabel
        }
      }
    }
  }
}
    ${LinkableFragmentDoc}`;

/**
 * __useAcatWizardSelectAccountTypeStepQuery__
 *
 * To run a query within a React component, call `useAcatWizardSelectAccountTypeStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcatWizardSelectAccountTypeStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcatWizardSelectAccountTypeStepQuery({
 *   variables: {
 *   },
 * });
 */
export function useAcatWizardSelectAccountTypeStepQuery(baseOptions?: Apollo.QueryHookOptions<Types.AcatWizardSelectAccountTypeStepQuery, Types.AcatWizardSelectAccountTypeStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AcatWizardSelectAccountTypeStepQuery, Types.AcatWizardSelectAccountTypeStepQueryVariables>(AcatWizardSelectAccountTypeStepDocument, options);
      }
export function useAcatWizardSelectAccountTypeStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AcatWizardSelectAccountTypeStepQuery, Types.AcatWizardSelectAccountTypeStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AcatWizardSelectAccountTypeStepQuery, Types.AcatWizardSelectAccountTypeStepQueryVariables>(AcatWizardSelectAccountTypeStepDocument, options);
        }
export function useAcatWizardSelectAccountTypeStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AcatWizardSelectAccountTypeStepQuery, Types.AcatWizardSelectAccountTypeStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AcatWizardSelectAccountTypeStepQuery, Types.AcatWizardSelectAccountTypeStepQueryVariables>(AcatWizardSelectAccountTypeStepDocument, options);
        }
export type AcatWizardSelectAccountTypeStepQueryHookResult = ReturnType<typeof useAcatWizardSelectAccountTypeStepQuery>;
export type AcatWizardSelectAccountTypeStepLazyQueryHookResult = ReturnType<typeof useAcatWizardSelectAccountTypeStepLazyQuery>;
export type AcatWizardSelectAccountTypeStepSuspenseQueryHookResult = ReturnType<typeof useAcatWizardSelectAccountTypeStepSuspenseQuery>;
export type AcatWizardSelectAccountTypeStepQueryResult = Apollo.QueryResult<Types.AcatWizardSelectAccountTypeStepQuery, Types.AcatWizardSelectAccountTypeStepQueryVariables>;
export const AcatWizardSelectAssetsStepDocument = gql`
    query AcatWizardSelectAssetsStep($brokerageId: ID!) {
  viewer {
    invest {
      acatWizard {
        assetSelectionContent(investAcatWizardContraParticipantId: $brokerageId) {
          header {
            ...RichText
          }
          cash {
            header {
              ...RichText
            }
            description {
              ...RichText
            }
            input {
              label
              placeholder
            }
          }
          assets {
            ...AcatWizardSelectionAssetsSectionContent
          }
          ctaLabel
        }
      }
    }
  }
}
    ${RichTextFragmentDoc}
${AcatWizardSelectionAssetsSectionContentFragmentDoc}`;

/**
 * __useAcatWizardSelectAssetsStepQuery__
 *
 * To run a query within a React component, call `useAcatWizardSelectAssetsStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcatWizardSelectAssetsStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcatWizardSelectAssetsStepQuery({
 *   variables: {
 *      brokerageId: // value for 'brokerageId'
 *   },
 * });
 */
export function useAcatWizardSelectAssetsStepQuery(baseOptions: Apollo.QueryHookOptions<Types.AcatWizardSelectAssetsStepQuery, Types.AcatWizardSelectAssetsStepQueryVariables> & ({ variables: Types.AcatWizardSelectAssetsStepQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AcatWizardSelectAssetsStepQuery, Types.AcatWizardSelectAssetsStepQueryVariables>(AcatWizardSelectAssetsStepDocument, options);
      }
export function useAcatWizardSelectAssetsStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AcatWizardSelectAssetsStepQuery, Types.AcatWizardSelectAssetsStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AcatWizardSelectAssetsStepQuery, Types.AcatWizardSelectAssetsStepQueryVariables>(AcatWizardSelectAssetsStepDocument, options);
        }
export function useAcatWizardSelectAssetsStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AcatWizardSelectAssetsStepQuery, Types.AcatWizardSelectAssetsStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AcatWizardSelectAssetsStepQuery, Types.AcatWizardSelectAssetsStepQueryVariables>(AcatWizardSelectAssetsStepDocument, options);
        }
export type AcatWizardSelectAssetsStepQueryHookResult = ReturnType<typeof useAcatWizardSelectAssetsStepQuery>;
export type AcatWizardSelectAssetsStepLazyQueryHookResult = ReturnType<typeof useAcatWizardSelectAssetsStepLazyQuery>;
export type AcatWizardSelectAssetsStepSuspenseQueryHookResult = ReturnType<typeof useAcatWizardSelectAssetsStepSuspenseQuery>;
export type AcatWizardSelectAssetsStepQueryResult = Apollo.QueryResult<Types.AcatWizardSelectAssetsStepQuery, Types.AcatWizardSelectAssetsStepQueryVariables>;
export const AcatWizardSelectContraBrokerageStepDocument = gql`
    query AcatWizardSelectContraBrokerageStep {
  viewer {
    invest {
      acatWizard {
        brokerageSelectionContent {
          header
          contraParticipants {
            edges {
              node {
                id
                name
                icon {
                  ...AppImage
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${AppImageFragmentDoc}`;

/**
 * __useAcatWizardSelectContraBrokerageStepQuery__
 *
 * To run a query within a React component, call `useAcatWizardSelectContraBrokerageStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcatWizardSelectContraBrokerageStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcatWizardSelectContraBrokerageStepQuery({
 *   variables: {
 *   },
 * });
 */
export function useAcatWizardSelectContraBrokerageStepQuery(baseOptions?: Apollo.QueryHookOptions<Types.AcatWizardSelectContraBrokerageStepQuery, Types.AcatWizardSelectContraBrokerageStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AcatWizardSelectContraBrokerageStepQuery, Types.AcatWizardSelectContraBrokerageStepQueryVariables>(AcatWizardSelectContraBrokerageStepDocument, options);
      }
export function useAcatWizardSelectContraBrokerageStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AcatWizardSelectContraBrokerageStepQuery, Types.AcatWizardSelectContraBrokerageStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AcatWizardSelectContraBrokerageStepQuery, Types.AcatWizardSelectContraBrokerageStepQueryVariables>(AcatWizardSelectContraBrokerageStepDocument, options);
        }
export function useAcatWizardSelectContraBrokerageStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AcatWizardSelectContraBrokerageStepQuery, Types.AcatWizardSelectContraBrokerageStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AcatWizardSelectContraBrokerageStepQuery, Types.AcatWizardSelectContraBrokerageStepQueryVariables>(AcatWizardSelectContraBrokerageStepDocument, options);
        }
export type AcatWizardSelectContraBrokerageStepQueryHookResult = ReturnType<typeof useAcatWizardSelectContraBrokerageStepQuery>;
export type AcatWizardSelectContraBrokerageStepLazyQueryHookResult = ReturnType<typeof useAcatWizardSelectContraBrokerageStepLazyQuery>;
export type AcatWizardSelectContraBrokerageStepSuspenseQueryHookResult = ReturnType<typeof useAcatWizardSelectContraBrokerageStepSuspenseQuery>;
export type AcatWizardSelectContraBrokerageStepQueryResult = Apollo.QueryResult<Types.AcatWizardSelectContraBrokerageStepQuery, Types.AcatWizardSelectContraBrokerageStepQueryVariables>;
export const AcatWizardTransferInformationStepDocument = gql`
    query AcatWizardTransferInformationStep {
  viewer {
    invest {
      acatWizard {
        transferInformationScreenContent {
          header
          footer
          ctaLabel
          transferDetails {
            icon {
              ...AppImage
            }
            title
            link {
              ...Linkable
            }
            content
          }
        }
      }
    }
  }
}
    ${AppImageFragmentDoc}
${LinkableFragmentDoc}`;

/**
 * __useAcatWizardTransferInformationStepQuery__
 *
 * To run a query within a React component, call `useAcatWizardTransferInformationStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcatWizardTransferInformationStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcatWizardTransferInformationStepQuery({
 *   variables: {
 *   },
 * });
 */
export function useAcatWizardTransferInformationStepQuery(baseOptions?: Apollo.QueryHookOptions<Types.AcatWizardTransferInformationStepQuery, Types.AcatWizardTransferInformationStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AcatWizardTransferInformationStepQuery, Types.AcatWizardTransferInformationStepQueryVariables>(AcatWizardTransferInformationStepDocument, options);
      }
export function useAcatWizardTransferInformationStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AcatWizardTransferInformationStepQuery, Types.AcatWizardTransferInformationStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AcatWizardTransferInformationStepQuery, Types.AcatWizardTransferInformationStepQueryVariables>(AcatWizardTransferInformationStepDocument, options);
        }
export function useAcatWizardTransferInformationStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AcatWizardTransferInformationStepQuery, Types.AcatWizardTransferInformationStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AcatWizardTransferInformationStepQuery, Types.AcatWizardTransferInformationStepQueryVariables>(AcatWizardTransferInformationStepDocument, options);
        }
export type AcatWizardTransferInformationStepQueryHookResult = ReturnType<typeof useAcatWizardTransferInformationStepQuery>;
export type AcatWizardTransferInformationStepLazyQueryHookResult = ReturnType<typeof useAcatWizardTransferInformationStepLazyQuery>;
export type AcatWizardTransferInformationStepSuspenseQueryHookResult = ReturnType<typeof useAcatWizardTransferInformationStepSuspenseQuery>;
export type AcatWizardTransferInformationStepQueryResult = Apollo.QueryResult<Types.AcatWizardTransferInformationStepQuery, Types.AcatWizardTransferInformationStepQueryVariables>;
export const TransferAcatInstanceDetailsPageDocument = gql`
    query TransferAcatInstanceDetailsPage($transferInstanceId: ID!) {
  node(id: $transferInstanceId) {
    ... on AcatTransferInstance {
      startDate
      type
      amount
      status {
        isCompleted
        isFailed
      }
      details {
        title
        pill {
          ...AppPill
        }
        steps {
          title
          subtitle
        }
        supportTicketNumber
        nextSteps {
          title
          steps
        }
        transferSpecificMessage {
          title
          description
          link {
            ...Linkable
          }
        }
      }
    }
  }
}
    ${AppPillFragmentDoc}
${LinkableFragmentDoc}`;

/**
 * __useTransferAcatInstanceDetailsPageQuery__
 *
 * To run a query within a React component, call `useTransferAcatInstanceDetailsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferAcatInstanceDetailsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferAcatInstanceDetailsPageQuery({
 *   variables: {
 *      transferInstanceId: // value for 'transferInstanceId'
 *   },
 * });
 */
export function useTransferAcatInstanceDetailsPageQuery(baseOptions: Apollo.QueryHookOptions<Types.TransferAcatInstanceDetailsPageQuery, Types.TransferAcatInstanceDetailsPageQueryVariables> & ({ variables: Types.TransferAcatInstanceDetailsPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TransferAcatInstanceDetailsPageQuery, Types.TransferAcatInstanceDetailsPageQueryVariables>(TransferAcatInstanceDetailsPageDocument, options);
      }
export function useTransferAcatInstanceDetailsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TransferAcatInstanceDetailsPageQuery, Types.TransferAcatInstanceDetailsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TransferAcatInstanceDetailsPageQuery, Types.TransferAcatInstanceDetailsPageQueryVariables>(TransferAcatInstanceDetailsPageDocument, options);
        }
export function useTransferAcatInstanceDetailsPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TransferAcatInstanceDetailsPageQuery, Types.TransferAcatInstanceDetailsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TransferAcatInstanceDetailsPageQuery, Types.TransferAcatInstanceDetailsPageQueryVariables>(TransferAcatInstanceDetailsPageDocument, options);
        }
export type TransferAcatInstanceDetailsPageQueryHookResult = ReturnType<typeof useTransferAcatInstanceDetailsPageQuery>;
export type TransferAcatInstanceDetailsPageLazyQueryHookResult = ReturnType<typeof useTransferAcatInstanceDetailsPageLazyQuery>;
export type TransferAcatInstanceDetailsPageSuspenseQueryHookResult = ReturnType<typeof useTransferAcatInstanceDetailsPageSuspenseQuery>;
export type TransferAcatInstanceDetailsPageQueryResult = Apollo.QueryResult<Types.TransferAcatInstanceDetailsPageQuery, Types.TransferAcatInstanceDetailsPageQueryVariables>;
export const BankConnectionDocument = gql`
    query BankConnection {
  viewer {
    fundingSources {
      hasFundingSources
    }
    transfers {
      isEligibleForFundingSourceUpdate
      activeFundingSource {
        id
        externalName
      }
    }
  }
}
    `;

/**
 * __useBankConnectionQuery__
 *
 * To run a query within a React component, call `useBankConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankConnectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useBankConnectionQuery(baseOptions?: Apollo.QueryHookOptions<Types.BankConnectionQuery, Types.BankConnectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BankConnectionQuery, Types.BankConnectionQueryVariables>(BankConnectionDocument, options);
      }
export function useBankConnectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BankConnectionQuery, Types.BankConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BankConnectionQuery, Types.BankConnectionQueryVariables>(BankConnectionDocument, options);
        }
export function useBankConnectionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BankConnectionQuery, Types.BankConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BankConnectionQuery, Types.BankConnectionQueryVariables>(BankConnectionDocument, options);
        }
export type BankConnectionQueryHookResult = ReturnType<typeof useBankConnectionQuery>;
export type BankConnectionLazyQueryHookResult = ReturnType<typeof useBankConnectionLazyQuery>;
export type BankConnectionSuspenseQueryHookResult = ReturnType<typeof useBankConnectionSuspenseQuery>;
export type BankConnectionQueryResult = Apollo.QueryResult<Types.BankConnectionQuery, Types.BankConnectionQueryVariables>;
export const IncompleteBankConnectionScreenDocument = gql`
    query IncompleteBankConnectionScreen($transferAmount: Float) {
  viewer {
    transfers {
      initialFunding {
        initialFundingScreens {
          initialFundingIncompleteConnectionScreen(transferAmount: $transferAmount) {
            ...AppInfoPage
          }
        }
      }
    }
  }
}
    ${AppInfoPageFragmentDoc}`;

/**
 * __useIncompleteBankConnectionScreenQuery__
 *
 * To run a query within a React component, call `useIncompleteBankConnectionScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncompleteBankConnectionScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncompleteBankConnectionScreenQuery({
 *   variables: {
 *      transferAmount: // value for 'transferAmount'
 *   },
 * });
 */
export function useIncompleteBankConnectionScreenQuery(baseOptions?: Apollo.QueryHookOptions<Types.IncompleteBankConnectionScreenQuery, Types.IncompleteBankConnectionScreenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IncompleteBankConnectionScreenQuery, Types.IncompleteBankConnectionScreenQueryVariables>(IncompleteBankConnectionScreenDocument, options);
      }
export function useIncompleteBankConnectionScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IncompleteBankConnectionScreenQuery, Types.IncompleteBankConnectionScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IncompleteBankConnectionScreenQuery, Types.IncompleteBankConnectionScreenQueryVariables>(IncompleteBankConnectionScreenDocument, options);
        }
export function useIncompleteBankConnectionScreenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.IncompleteBankConnectionScreenQuery, Types.IncompleteBankConnectionScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IncompleteBankConnectionScreenQuery, Types.IncompleteBankConnectionScreenQueryVariables>(IncompleteBankConnectionScreenDocument, options);
        }
export type IncompleteBankConnectionScreenQueryHookResult = ReturnType<typeof useIncompleteBankConnectionScreenQuery>;
export type IncompleteBankConnectionScreenLazyQueryHookResult = ReturnType<typeof useIncompleteBankConnectionScreenLazyQuery>;
export type IncompleteBankConnectionScreenSuspenseQueryHookResult = ReturnType<typeof useIncompleteBankConnectionScreenSuspenseQuery>;
export type IncompleteBankConnectionScreenQueryResult = Apollo.QueryResult<Types.IncompleteBankConnectionScreenQuery, Types.IncompleteBankConnectionScreenQueryVariables>;
export const PendingBankConnectionDocument = gql`
    query PendingBankConnection {
  viewer {
    transfers {
      initialFunding {
        initialFundingScreens {
          initialFundingPendingPlaidConnectionScreen {
            ...AppInfoPage
          }
        }
      }
    }
  }
}
    ${AppInfoPageFragmentDoc}`;

/**
 * __usePendingBankConnectionQuery__
 *
 * To run a query within a React component, call `usePendingBankConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingBankConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingBankConnectionQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingBankConnectionQuery(baseOptions?: Apollo.QueryHookOptions<Types.PendingBankConnectionQuery, Types.PendingBankConnectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PendingBankConnectionQuery, Types.PendingBankConnectionQueryVariables>(PendingBankConnectionDocument, options);
      }
export function usePendingBankConnectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PendingBankConnectionQuery, Types.PendingBankConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PendingBankConnectionQuery, Types.PendingBankConnectionQueryVariables>(PendingBankConnectionDocument, options);
        }
export function usePendingBankConnectionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PendingBankConnectionQuery, Types.PendingBankConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PendingBankConnectionQuery, Types.PendingBankConnectionQueryVariables>(PendingBankConnectionDocument, options);
        }
export type PendingBankConnectionQueryHookResult = ReturnType<typeof usePendingBankConnectionQuery>;
export type PendingBankConnectionLazyQueryHookResult = ReturnType<typeof usePendingBankConnectionLazyQuery>;
export type PendingBankConnectionSuspenseQueryHookResult = ReturnType<typeof usePendingBankConnectionSuspenseQuery>;
export type PendingBankConnectionQueryResult = Apollo.QueryResult<Types.PendingBankConnectionQuery, Types.PendingBankConnectionQueryVariables>;
export const BorrowAccountDetailsPageDocument = gql`
    query BorrowAccountDetailsPage($borrowAccountId: ID!) {
  ...BorrowMarketingHeader
  viewer {
    announcements {
      forBorrow {
        ...Announcement
      }
    }
    borrow {
      borrowAccounts {
        edges {
          node {
            id
          }
        }
      }
    }
  }
  borrowAccount: node(id: $borrowAccountId) {
    ...BorrowMarketingHeaderAccount
  }
}
    ${BorrowMarketingHeaderFragmentDoc}
${AnnouncementFragmentDoc}
${BorrowMarketingHeaderAccountFragmentDoc}`;

/**
 * __useBorrowAccountDetailsPageQuery__
 *
 * To run a query within a React component, call `useBorrowAccountDetailsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBorrowAccountDetailsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBorrowAccountDetailsPageQuery({
 *   variables: {
 *      borrowAccountId: // value for 'borrowAccountId'
 *   },
 * });
 */
export function useBorrowAccountDetailsPageQuery(baseOptions: Apollo.QueryHookOptions<Types.BorrowAccountDetailsPageQuery, Types.BorrowAccountDetailsPageQueryVariables> & ({ variables: Types.BorrowAccountDetailsPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BorrowAccountDetailsPageQuery, Types.BorrowAccountDetailsPageQueryVariables>(BorrowAccountDetailsPageDocument, options);
      }
export function useBorrowAccountDetailsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BorrowAccountDetailsPageQuery, Types.BorrowAccountDetailsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BorrowAccountDetailsPageQuery, Types.BorrowAccountDetailsPageQueryVariables>(BorrowAccountDetailsPageDocument, options);
        }
export function useBorrowAccountDetailsPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BorrowAccountDetailsPageQuery, Types.BorrowAccountDetailsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BorrowAccountDetailsPageQuery, Types.BorrowAccountDetailsPageQueryVariables>(BorrowAccountDetailsPageDocument, options);
        }
export type BorrowAccountDetailsPageQueryHookResult = ReturnType<typeof useBorrowAccountDetailsPageQuery>;
export type BorrowAccountDetailsPageLazyQueryHookResult = ReturnType<typeof useBorrowAccountDetailsPageLazyQuery>;
export type BorrowAccountDetailsPageSuspenseQueryHookResult = ReturnType<typeof useBorrowAccountDetailsPageSuspenseQuery>;
export type BorrowAccountDetailsPageQueryResult = Apollo.QueryResult<Types.BorrowAccountDetailsPageQuery, Types.BorrowAccountDetailsPageQueryVariables>;
export const BorrowActivityDocument = gql`
    query BorrowActivity($borrowAccountId: ID!, $first: Int, $after: String) {
  borrowAccount: node(id: $borrowAccountId) {
    ... on BorrowAccount {
      id
      marginProductLandingPage {
        activity(first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              date
              title
              subTitle
              amount
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBorrowActivityQuery__
 *
 * To run a query within a React component, call `useBorrowActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useBorrowActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBorrowActivityQuery({
 *   variables: {
 *      borrowAccountId: // value for 'borrowAccountId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBorrowActivityQuery(baseOptions: Apollo.QueryHookOptions<Types.BorrowActivityQuery, Types.BorrowActivityQueryVariables> & ({ variables: Types.BorrowActivityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BorrowActivityQuery, Types.BorrowActivityQueryVariables>(BorrowActivityDocument, options);
      }
export function useBorrowActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BorrowActivityQuery, Types.BorrowActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BorrowActivityQuery, Types.BorrowActivityQueryVariables>(BorrowActivityDocument, options);
        }
export function useBorrowActivitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BorrowActivityQuery, Types.BorrowActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BorrowActivityQuery, Types.BorrowActivityQueryVariables>(BorrowActivityDocument, options);
        }
export type BorrowActivityQueryHookResult = ReturnType<typeof useBorrowActivityQuery>;
export type BorrowActivityLazyQueryHookResult = ReturnType<typeof useBorrowActivityLazyQuery>;
export type BorrowActivitySuspenseQueryHookResult = ReturnType<typeof useBorrowActivitySuspenseQuery>;
export type BorrowActivityQueryResult = Apollo.QueryResult<Types.BorrowActivityQuery, Types.BorrowActivityQueryVariables>;
export const BorrowBillsDocument = gql`
    query BorrowBills($borrowAccountId: ID!, $first: Int, $after: String) {
  borrowAccount: node(id: $borrowAccountId) {
    ... on BorrowAccount {
      id
      marginProductLandingPage {
        statements(first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              ...BorrowBillNode
            }
          }
        }
      }
    }
  }
}
    ${BorrowBillNodeFragmentDoc}`;

/**
 * __useBorrowBillsQuery__
 *
 * To run a query within a React component, call `useBorrowBillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBorrowBillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBorrowBillsQuery({
 *   variables: {
 *      borrowAccountId: // value for 'borrowAccountId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBorrowBillsQuery(baseOptions: Apollo.QueryHookOptions<Types.BorrowBillsQuery, Types.BorrowBillsQueryVariables> & ({ variables: Types.BorrowBillsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BorrowBillsQuery, Types.BorrowBillsQueryVariables>(BorrowBillsDocument, options);
      }
export function useBorrowBillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BorrowBillsQuery, Types.BorrowBillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BorrowBillsQuery, Types.BorrowBillsQueryVariables>(BorrowBillsDocument, options);
        }
export function useBorrowBillsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BorrowBillsQuery, Types.BorrowBillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BorrowBillsQuery, Types.BorrowBillsQueryVariables>(BorrowBillsDocument, options);
        }
export type BorrowBillsQueryHookResult = ReturnType<typeof useBorrowBillsQuery>;
export type BorrowBillsLazyQueryHookResult = ReturnType<typeof useBorrowBillsLazyQuery>;
export type BorrowBillsSuspenseQueryHookResult = ReturnType<typeof useBorrowBillsSuspenseQuery>;
export type BorrowBillsQueryResult = Apollo.QueryResult<Types.BorrowBillsQuery, Types.BorrowBillsQueryVariables>;
export const BorrowMarginCallDialogDocument = gql`
    query BorrowMarginCallDialog {
  viewer {
    borrow {
      hasBorrowAccountWithOpenMaintenanceCall
      borrowAccounts {
        edges {
          node {
            status {
              id
              hasOpenMaintenanceCall
              borrowAccount {
                id
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBorrowMarginCallDialogQuery__
 *
 * To run a query within a React component, call `useBorrowMarginCallDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useBorrowMarginCallDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBorrowMarginCallDialogQuery({
 *   variables: {
 *   },
 * });
 */
export function useBorrowMarginCallDialogQuery(baseOptions?: Apollo.QueryHookOptions<Types.BorrowMarginCallDialogQuery, Types.BorrowMarginCallDialogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BorrowMarginCallDialogQuery, Types.BorrowMarginCallDialogQueryVariables>(BorrowMarginCallDialogDocument, options);
      }
export function useBorrowMarginCallDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BorrowMarginCallDialogQuery, Types.BorrowMarginCallDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BorrowMarginCallDialogQuery, Types.BorrowMarginCallDialogQueryVariables>(BorrowMarginCallDialogDocument, options);
        }
export function useBorrowMarginCallDialogSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BorrowMarginCallDialogQuery, Types.BorrowMarginCallDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BorrowMarginCallDialogQuery, Types.BorrowMarginCallDialogQueryVariables>(BorrowMarginCallDialogDocument, options);
        }
export type BorrowMarginCallDialogQueryHookResult = ReturnType<typeof useBorrowMarginCallDialogQuery>;
export type BorrowMarginCallDialogLazyQueryHookResult = ReturnType<typeof useBorrowMarginCallDialogLazyQuery>;
export type BorrowMarginCallDialogSuspenseQueryHookResult = ReturnType<typeof useBorrowMarginCallDialogSuspenseQuery>;
export type BorrowMarginCallDialogQueryResult = Apollo.QueryResult<Types.BorrowMarginCallDialogQuery, Types.BorrowMarginCallDialogQueryVariables>;
export const BorrowMarginMarketingPageDocument = gql`
    query BorrowMarginMarketingPage {
  ...BorrowMarketingHeader
  viewer {
    borrow {
      hasBorrowAccounts
      borrowAccounts {
        edges {
          node {
            id
          }
        }
      }
    }
    announcements {
      forBorrow {
        ...Announcement
      }
    }
  }
}
    ${BorrowMarketingHeaderFragmentDoc}
${AnnouncementFragmentDoc}`;

/**
 * __useBorrowMarginMarketingPageQuery__
 *
 * To run a query within a React component, call `useBorrowMarginMarketingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBorrowMarginMarketingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBorrowMarginMarketingPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useBorrowMarginMarketingPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.BorrowMarginMarketingPageQuery, Types.BorrowMarginMarketingPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BorrowMarginMarketingPageQuery, Types.BorrowMarginMarketingPageQueryVariables>(BorrowMarginMarketingPageDocument, options);
      }
export function useBorrowMarginMarketingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BorrowMarginMarketingPageQuery, Types.BorrowMarginMarketingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BorrowMarginMarketingPageQuery, Types.BorrowMarginMarketingPageQueryVariables>(BorrowMarginMarketingPageDocument, options);
        }
export function useBorrowMarginMarketingPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BorrowMarginMarketingPageQuery, Types.BorrowMarginMarketingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BorrowMarginMarketingPageQuery, Types.BorrowMarginMarketingPageQueryVariables>(BorrowMarginMarketingPageDocument, options);
        }
export type BorrowMarginMarketingPageQueryHookResult = ReturnType<typeof useBorrowMarginMarketingPageQuery>;
export type BorrowMarginMarketingPageLazyQueryHookResult = ReturnType<typeof useBorrowMarginMarketingPageLazyQuery>;
export type BorrowMarginMarketingPageSuspenseQueryHookResult = ReturnType<typeof useBorrowMarginMarketingPageSuspenseQuery>;
export type BorrowMarginMarketingPageQueryResult = Apollo.QueryResult<Types.BorrowMarginMarketingPageQuery, Types.BorrowMarginMarketingPageQueryVariables>;
export const BorrowMarketingContainerDocument = gql`
    query BorrowMarketingContainer {
  ...BorrowMarketingHeader
}
    ${BorrowMarketingHeaderFragmentDoc}`;

/**
 * __useBorrowMarketingContainerQuery__
 *
 * To run a query within a React component, call `useBorrowMarketingContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useBorrowMarketingContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBorrowMarketingContainerQuery({
 *   variables: {
 *   },
 * });
 */
export function useBorrowMarketingContainerQuery(baseOptions?: Apollo.QueryHookOptions<Types.BorrowMarketingContainerQuery, Types.BorrowMarketingContainerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BorrowMarketingContainerQuery, Types.BorrowMarketingContainerQueryVariables>(BorrowMarketingContainerDocument, options);
      }
export function useBorrowMarketingContainerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BorrowMarketingContainerQuery, Types.BorrowMarketingContainerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BorrowMarketingContainerQuery, Types.BorrowMarketingContainerQueryVariables>(BorrowMarketingContainerDocument, options);
        }
export function useBorrowMarketingContainerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BorrowMarketingContainerQuery, Types.BorrowMarketingContainerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BorrowMarketingContainerQuery, Types.BorrowMarketingContainerQueryVariables>(BorrowMarketingContainerDocument, options);
        }
export type BorrowMarketingContainerQueryHookResult = ReturnType<typeof useBorrowMarketingContainerQuery>;
export type BorrowMarketingContainerLazyQueryHookResult = ReturnType<typeof useBorrowMarketingContainerLazyQuery>;
export type BorrowMarketingContainerSuspenseQueryHookResult = ReturnType<typeof useBorrowMarketingContainerSuspenseQuery>;
export type BorrowMarketingContainerQueryResult = Apollo.QueryResult<Types.BorrowMarketingContainerQuery, Types.BorrowMarketingContainerQueryVariables>;
export const BorrowMarketingScreenDocument = gql`
    query BorrowMarketingScreen {
  viewer {
    borrow {
      borrowMarketingScreen {
        header
        marginPersonalLoanInfoLink {
          title
          url
        }
        productMarketingCards {
          illustration {
            ...AppImage
          }
          title
          titleTooltip
          subtitle
          primaryCtaButton {
            ...AppLink
          }
          secondaryCtaButton {
            ...AppLink
          }
          analyticsEvent {
            ...AnalyticsEvent
          }
        }
        disclosures {
          ...AppRichTextDisclosureLink
        }
        marginLoanModal {
          title
          subtitle
          marginLoanAccounts {
            transferParticipantId
            transferParticipantName
          }
          ctaLabel
        }
        bankDisclosure {
          text
          icon {
            ...AppImage
          }
        }
      }
    }
    announcements {
      ... on Announcements {
        forBorrowMarketing {
          ...Announcement
        }
      }
    }
  }
}
    ${AppImageFragmentDoc}
${AppLinkFragmentDoc}
${AnalyticsEventFragmentDoc}
${AppRichTextDisclosureLinkFragmentDoc}
${AnnouncementFragmentDoc}`;

/**
 * __useBorrowMarketingScreenQuery__
 *
 * To run a query within a React component, call `useBorrowMarketingScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useBorrowMarketingScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBorrowMarketingScreenQuery({
 *   variables: {
 *   },
 * });
 */
export function useBorrowMarketingScreenQuery(baseOptions?: Apollo.QueryHookOptions<Types.BorrowMarketingScreenQuery, Types.BorrowMarketingScreenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BorrowMarketingScreenQuery, Types.BorrowMarketingScreenQueryVariables>(BorrowMarketingScreenDocument, options);
      }
export function useBorrowMarketingScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BorrowMarketingScreenQuery, Types.BorrowMarketingScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BorrowMarketingScreenQuery, Types.BorrowMarketingScreenQueryVariables>(BorrowMarketingScreenDocument, options);
        }
export function useBorrowMarketingScreenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BorrowMarketingScreenQuery, Types.BorrowMarketingScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BorrowMarketingScreenQuery, Types.BorrowMarketingScreenQueryVariables>(BorrowMarketingScreenDocument, options);
        }
export type BorrowMarketingScreenQueryHookResult = ReturnType<typeof useBorrowMarketingScreenQuery>;
export type BorrowMarketingScreenLazyQueryHookResult = ReturnType<typeof useBorrowMarketingScreenLazyQuery>;
export type BorrowMarketingScreenSuspenseQueryHookResult = ReturnType<typeof useBorrowMarketingScreenSuspenseQuery>;
export type BorrowMarketingScreenQueryResult = Apollo.QueryResult<Types.BorrowMarketingScreenQuery, Types.BorrowMarketingScreenQueryVariables>;
export const BorrowSplashPageDocument = gql`
    query BorrowSplashPage {
  ...BorrowMarketingHeader
  viewer {
    announcements {
      forBorrow {
        ...Announcement
      }
    }
  }
}
    ${BorrowMarketingHeaderFragmentDoc}
${AnnouncementFragmentDoc}`;

/**
 * __useBorrowSplashPageQuery__
 *
 * To run a query within a React component, call `useBorrowSplashPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBorrowSplashPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBorrowSplashPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useBorrowSplashPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.BorrowSplashPageQuery, Types.BorrowSplashPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BorrowSplashPageQuery, Types.BorrowSplashPageQueryVariables>(BorrowSplashPageDocument, options);
      }
export function useBorrowSplashPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BorrowSplashPageQuery, Types.BorrowSplashPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BorrowSplashPageQuery, Types.BorrowSplashPageQueryVariables>(BorrowSplashPageDocument, options);
        }
export function useBorrowSplashPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BorrowSplashPageQuery, Types.BorrowSplashPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BorrowSplashPageQuery, Types.BorrowSplashPageQueryVariables>(BorrowSplashPageDocument, options);
        }
export type BorrowSplashPageQueryHookResult = ReturnType<typeof useBorrowSplashPageQuery>;
export type BorrowSplashPageLazyQueryHookResult = ReturnType<typeof useBorrowSplashPageLazyQuery>;
export type BorrowSplashPageSuspenseQueryHookResult = ReturnType<typeof useBorrowSplashPageSuspenseQuery>;
export type BorrowSplashPageQueryResult = Apollo.QueryResult<Types.BorrowSplashPageQuery, Types.BorrowSplashPageQueryVariables>;
export const BorrowUseCasesDocument = gql`
    query BorrowUseCases {
  viewer {
    borrow {
      id
      baseRate
      maxBorrowableAccountValuePercent
    }
  }
}
    `;

/**
 * __useBorrowUseCasesQuery__
 *
 * To run a query within a React component, call `useBorrowUseCasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBorrowUseCasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBorrowUseCasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBorrowUseCasesQuery(baseOptions?: Apollo.QueryHookOptions<Types.BorrowUseCasesQuery, Types.BorrowUseCasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BorrowUseCasesQuery, Types.BorrowUseCasesQueryVariables>(BorrowUseCasesDocument, options);
      }
export function useBorrowUseCasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BorrowUseCasesQuery, Types.BorrowUseCasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BorrowUseCasesQuery, Types.BorrowUseCasesQueryVariables>(BorrowUseCasesDocument, options);
        }
export function useBorrowUseCasesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BorrowUseCasesQuery, Types.BorrowUseCasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BorrowUseCasesQuery, Types.BorrowUseCasesQueryVariables>(BorrowUseCasesDocument, options);
        }
export type BorrowUseCasesQueryHookResult = ReturnType<typeof useBorrowUseCasesQuery>;
export type BorrowUseCasesLazyQueryHookResult = ReturnType<typeof useBorrowUseCasesLazyQuery>;
export type BorrowUseCasesSuspenseQueryHookResult = ReturnType<typeof useBorrowUseCasesSuspenseQuery>;
export type BorrowUseCasesQueryResult = Apollo.QueryResult<Types.BorrowUseCasesQuery, Types.BorrowUseCasesQueryVariables>;
export const BorrowAccountUseCaseInfoDocument = gql`
    query BorrowAccountUseCaseInfo($borrowAccountId: ID!) {
  borrowAccount: node(id: $borrowAccountId) {
    ...BorrowMarketingHeaderAccount
  }
}
    ${BorrowMarketingHeaderAccountFragmentDoc}`;

/**
 * __useBorrowAccountUseCaseInfoQuery__
 *
 * To run a query within a React component, call `useBorrowAccountUseCaseInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBorrowAccountUseCaseInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBorrowAccountUseCaseInfoQuery({
 *   variables: {
 *      borrowAccountId: // value for 'borrowAccountId'
 *   },
 * });
 */
export function useBorrowAccountUseCaseInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.BorrowAccountUseCaseInfoQuery, Types.BorrowAccountUseCaseInfoQueryVariables> & ({ variables: Types.BorrowAccountUseCaseInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BorrowAccountUseCaseInfoQuery, Types.BorrowAccountUseCaseInfoQueryVariables>(BorrowAccountUseCaseInfoDocument, options);
      }
export function useBorrowAccountUseCaseInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BorrowAccountUseCaseInfoQuery, Types.BorrowAccountUseCaseInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BorrowAccountUseCaseInfoQuery, Types.BorrowAccountUseCaseInfoQueryVariables>(BorrowAccountUseCaseInfoDocument, options);
        }
export function useBorrowAccountUseCaseInfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BorrowAccountUseCaseInfoQuery, Types.BorrowAccountUseCaseInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BorrowAccountUseCaseInfoQuery, Types.BorrowAccountUseCaseInfoQueryVariables>(BorrowAccountUseCaseInfoDocument, options);
        }
export type BorrowAccountUseCaseInfoQueryHookResult = ReturnType<typeof useBorrowAccountUseCaseInfoQuery>;
export type BorrowAccountUseCaseInfoLazyQueryHookResult = ReturnType<typeof useBorrowAccountUseCaseInfoLazyQuery>;
export type BorrowAccountUseCaseInfoSuspenseQueryHookResult = ReturnType<typeof useBorrowAccountUseCaseInfoSuspenseQuery>;
export type BorrowAccountUseCaseInfoQueryResult = Apollo.QueryResult<Types.BorrowAccountUseCaseInfoQuery, Types.BorrowAccountUseCaseInfoQueryVariables>;
export const DepositInfoPageDocument = gql`
    query DepositInfoPage($loanId: ID!) {
  viewer {
    borrow {
      personalLoans {
        disbursementFlow(loanId: $loanId) {
          loanDisbursement {
            title
            subtitle
            destinationList {
              id
              transferParticipantType
              transferParticipantName
              transferParticipantDescription
            }
            continueCta
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDepositInfoPageQuery__
 *
 * To run a query within a React component, call `useDepositInfoPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepositInfoPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositInfoPageQuery({
 *   variables: {
 *      loanId: // value for 'loanId'
 *   },
 * });
 */
export function useDepositInfoPageQuery(baseOptions: Apollo.QueryHookOptions<Types.DepositInfoPageQuery, Types.DepositInfoPageQueryVariables> & ({ variables: Types.DepositInfoPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DepositInfoPageQuery, Types.DepositInfoPageQueryVariables>(DepositInfoPageDocument, options);
      }
export function useDepositInfoPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DepositInfoPageQuery, Types.DepositInfoPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DepositInfoPageQuery, Types.DepositInfoPageQueryVariables>(DepositInfoPageDocument, options);
        }
export function useDepositInfoPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.DepositInfoPageQuery, Types.DepositInfoPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DepositInfoPageQuery, Types.DepositInfoPageQueryVariables>(DepositInfoPageDocument, options);
        }
export type DepositInfoPageQueryHookResult = ReturnType<typeof useDepositInfoPageQuery>;
export type DepositInfoPageLazyQueryHookResult = ReturnType<typeof useDepositInfoPageLazyQuery>;
export type DepositInfoPageSuspenseQueryHookResult = ReturnType<typeof useDepositInfoPageSuspenseQuery>;
export type DepositInfoPageQueryResult = Apollo.QueryResult<Types.DepositInfoPageQuery, Types.DepositInfoPageQueryVariables>;
export const PersonalLoanLandingPageDocument = gql`
    query PersonalLoanLandingPage {
  viewer {
    borrow {
      personalLoans {
        applicationFlow {
          optionalDocuments {
            documents {
              title
              url
            }
            signature
          }
          requiredDocuments {
            documents {
              title
              url
            }
            signature
          }
          landingPage {
            loanDisclaimer
            bulletPoints {
              copy
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePersonalLoanLandingPageQuery__
 *
 * To run a query within a React component, call `usePersonalLoanLandingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalLoanLandingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalLoanLandingPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalLoanLandingPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.PersonalLoanLandingPageQuery, Types.PersonalLoanLandingPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonalLoanLandingPageQuery, Types.PersonalLoanLandingPageQueryVariables>(PersonalLoanLandingPageDocument, options);
      }
export function usePersonalLoanLandingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonalLoanLandingPageQuery, Types.PersonalLoanLandingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonalLoanLandingPageQuery, Types.PersonalLoanLandingPageQueryVariables>(PersonalLoanLandingPageDocument, options);
        }
export function usePersonalLoanLandingPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PersonalLoanLandingPageQuery, Types.PersonalLoanLandingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonalLoanLandingPageQuery, Types.PersonalLoanLandingPageQueryVariables>(PersonalLoanLandingPageDocument, options);
        }
export type PersonalLoanLandingPageQueryHookResult = ReturnType<typeof usePersonalLoanLandingPageQuery>;
export type PersonalLoanLandingPageLazyQueryHookResult = ReturnType<typeof usePersonalLoanLandingPageLazyQuery>;
export type PersonalLoanLandingPageSuspenseQueryHookResult = ReturnType<typeof usePersonalLoanLandingPageSuspenseQuery>;
export type PersonalLoanLandingPageQueryResult = Apollo.QueryResult<Types.PersonalLoanLandingPageQuery, Types.PersonalLoanLandingPageQueryVariables>;
export const MarginCallInformationHoldingsOverviewDocument = gql`
    query MarginCallInformationHoldingsOverview($borrowAccountId: ID!) {
  node(id: $borrowAccountId) {
    ... on BorrowAccount {
      investAccount {
        id
        ...BorrowAccount
      }
    }
  }
}
    ${BorrowAccountFragmentDoc}`;

/**
 * __useMarginCallInformationHoldingsOverviewQuery__
 *
 * To run a query within a React component, call `useMarginCallInformationHoldingsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarginCallInformationHoldingsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarginCallInformationHoldingsOverviewQuery({
 *   variables: {
 *      borrowAccountId: // value for 'borrowAccountId'
 *   },
 * });
 */
export function useMarginCallInformationHoldingsOverviewQuery(baseOptions: Apollo.QueryHookOptions<Types.MarginCallInformationHoldingsOverviewQuery, Types.MarginCallInformationHoldingsOverviewQueryVariables> & ({ variables: Types.MarginCallInformationHoldingsOverviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MarginCallInformationHoldingsOverviewQuery, Types.MarginCallInformationHoldingsOverviewQueryVariables>(MarginCallInformationHoldingsOverviewDocument, options);
      }
export function useMarginCallInformationHoldingsOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MarginCallInformationHoldingsOverviewQuery, Types.MarginCallInformationHoldingsOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MarginCallInformationHoldingsOverviewQuery, Types.MarginCallInformationHoldingsOverviewQueryVariables>(MarginCallInformationHoldingsOverviewDocument, options);
        }
export function useMarginCallInformationHoldingsOverviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MarginCallInformationHoldingsOverviewQuery, Types.MarginCallInformationHoldingsOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MarginCallInformationHoldingsOverviewQuery, Types.MarginCallInformationHoldingsOverviewQueryVariables>(MarginCallInformationHoldingsOverviewDocument, options);
        }
export type MarginCallInformationHoldingsOverviewQueryHookResult = ReturnType<typeof useMarginCallInformationHoldingsOverviewQuery>;
export type MarginCallInformationHoldingsOverviewLazyQueryHookResult = ReturnType<typeof useMarginCallInformationHoldingsOverviewLazyQuery>;
export type MarginCallInformationHoldingsOverviewSuspenseQueryHookResult = ReturnType<typeof useMarginCallInformationHoldingsOverviewSuspenseQuery>;
export type MarginCallInformationHoldingsOverviewQueryResult = Apollo.QueryResult<Types.MarginCallInformationHoldingsOverviewQuery, Types.MarginCallInformationHoldingsOverviewQueryVariables>;
export const RemoveCreditFreezePageDocument = gql`
    query RemoveCreditFreezePage {
  viewer {
    borrow {
      personalLoans {
        applicationFlow {
          loanDeclinedErrorScreen {
            title
            subtitle
            analyticsEvent {
              name
            }
            checkBoxText
            bureaus
            ctaLink {
              title
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useRemoveCreditFreezePageQuery__
 *
 * To run a query within a React component, call `useRemoveCreditFreezePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreditFreezePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemoveCreditFreezePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useRemoveCreditFreezePageQuery(baseOptions?: Apollo.QueryHookOptions<Types.RemoveCreditFreezePageQuery, Types.RemoveCreditFreezePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RemoveCreditFreezePageQuery, Types.RemoveCreditFreezePageQueryVariables>(RemoveCreditFreezePageDocument, options);
      }
export function useRemoveCreditFreezePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RemoveCreditFreezePageQuery, Types.RemoveCreditFreezePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RemoveCreditFreezePageQuery, Types.RemoveCreditFreezePageQueryVariables>(RemoveCreditFreezePageDocument, options);
        }
export function useRemoveCreditFreezePageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RemoveCreditFreezePageQuery, Types.RemoveCreditFreezePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RemoveCreditFreezePageQuery, Types.RemoveCreditFreezePageQueryVariables>(RemoveCreditFreezePageDocument, options);
        }
export type RemoveCreditFreezePageQueryHookResult = ReturnType<typeof useRemoveCreditFreezePageQuery>;
export type RemoveCreditFreezePageLazyQueryHookResult = ReturnType<typeof useRemoveCreditFreezePageLazyQuery>;
export type RemoveCreditFreezePageSuspenseQueryHookResult = ReturnType<typeof useRemoveCreditFreezePageSuspenseQuery>;
export type RemoveCreditFreezePageQueryResult = Apollo.QueryResult<Types.RemoveCreditFreezePageQuery, Types.RemoveCreditFreezePageQueryVariables>;
export const ChartableSliceChartDocument = gql`
    query ChartableSliceChart($ids: [ID!]!, $initialValueForBacktesting: Float) {
  nodes(ids: $ids) {
    ... on ChartableSlice {
      id
      chartData(initialValueForBacktesting: $initialValueForBacktesting) {
        ...ChartableSliceData
      }
    }
  }
}
    ${ChartableSliceDataFragmentDoc}`;

/**
 * __useChartableSliceChartQuery__
 *
 * To run a query within a React component, call `useChartableSliceChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartableSliceChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartableSliceChartQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      initialValueForBacktesting: // value for 'initialValueForBacktesting'
 *   },
 * });
 */
export function useChartableSliceChartQuery(baseOptions: Apollo.QueryHookOptions<Types.ChartableSliceChartQuery, Types.ChartableSliceChartQueryVariables> & ({ variables: Types.ChartableSliceChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ChartableSliceChartQuery, Types.ChartableSliceChartQueryVariables>(ChartableSliceChartDocument, options);
      }
export function useChartableSliceChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ChartableSliceChartQuery, Types.ChartableSliceChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ChartableSliceChartQuery, Types.ChartableSliceChartQueryVariables>(ChartableSliceChartDocument, options);
        }
export function useChartableSliceChartSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ChartableSliceChartQuery, Types.ChartableSliceChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ChartableSliceChartQuery, Types.ChartableSliceChartQueryVariables>(ChartableSliceChartDocument, options);
        }
export type ChartableSliceChartQueryHookResult = ReturnType<typeof useChartableSliceChartQuery>;
export type ChartableSliceChartLazyQueryHookResult = ReturnType<typeof useChartableSliceChartLazyQuery>;
export type ChartableSliceChartSuspenseQueryHookResult = ReturnType<typeof useChartableSliceChartSuspenseQuery>;
export type ChartableSliceChartQueryResult = Apollo.QueryResult<Types.ChartableSliceChartQuery, Types.ChartableSliceChartQueryVariables>;
export const CommonTransactionDetailsDocument = gql`
    query CommonTransactionDetails($transactionId: ID!) {
  node(id: $transactionId) {
    ... on CommonTransaction {
      details {
        id
        header
        description {
          ...RichText
        }
        status
        primaryDetails {
          amount {
            label
            value {
              color
              text
            }
          }
          status {
            label
            pill {
              ...AppPill
            }
          }
        }
        detailSections {
          header
          details {
            label
            value {
              icon {
                ...AppImage
              }
              text
            }
            metadata
            pill {
              ...AppPill
            }
            rewardTierPill {
              label
              kind
              icon
            }
            disclaimers {
              ...RichText
            }
          }
        }
        link {
          ...AppNavigationItem
        }
      }
    }
  }
}
    ${RichTextFragmentDoc}
${AppPillFragmentDoc}
${AppImageFragmentDoc}
${AppNavigationItemFragmentDoc}`;

/**
 * __useCommonTransactionDetailsQuery__
 *
 * To run a query within a React component, call `useCommonTransactionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommonTransactionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommonTransactionDetailsQuery({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useCommonTransactionDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.CommonTransactionDetailsQuery, Types.CommonTransactionDetailsQueryVariables> & ({ variables: Types.CommonTransactionDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CommonTransactionDetailsQuery, Types.CommonTransactionDetailsQueryVariables>(CommonTransactionDetailsDocument, options);
      }
export function useCommonTransactionDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CommonTransactionDetailsQuery, Types.CommonTransactionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CommonTransactionDetailsQuery, Types.CommonTransactionDetailsQueryVariables>(CommonTransactionDetailsDocument, options);
        }
export function useCommonTransactionDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CommonTransactionDetailsQuery, Types.CommonTransactionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CommonTransactionDetailsQuery, Types.CommonTransactionDetailsQueryVariables>(CommonTransactionDetailsDocument, options);
        }
export type CommonTransactionDetailsQueryHookResult = ReturnType<typeof useCommonTransactionDetailsQuery>;
export type CommonTransactionDetailsLazyQueryHookResult = ReturnType<typeof useCommonTransactionDetailsLazyQuery>;
export type CommonTransactionDetailsSuspenseQueryHookResult = ReturnType<typeof useCommonTransactionDetailsSuspenseQuery>;
export type CommonTransactionDetailsQueryResult = Apollo.QueryResult<Types.CommonTransactionDetailsQuery, Types.CommonTransactionDetailsQueryVariables>;
export const AcceptOfferDocument = gql`
    query AcceptOffer {
  viewer {
    credit {
      activeOffer {
        cardholderAgreement {
          url
          title
        }
      }
      onboardingContent {
        applicationStatusScreen {
          id
          key
          title
          header
          stepOne {
            title
            body
            icon {
              ...AppImage
            }
            details {
              creditLimit
              annualPercentageRate
            }
          }
          stepTwo {
            icon {
              ...AppImage
            }
            title
            cardholderAgreement {
              title
            }
            viewedPill {
              label
              kind
            }
            notViewedPill {
              label
              kind
            }
            errorIcon {
              ...AppImage
            }
            errorMessage
          }
          stepThree {
            icon {
              ...AppImage
            }
            title
            termsAndConditions {
              documents {
                title
                url
              }
            }
          }
          additionalContent
          footer
          buttonLabel
        }
      }
    }
  }
}
    ${AppImageFragmentDoc}`;

/**
 * __useAcceptOfferQuery__
 *
 * To run a query within a React component, call `useAcceptOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcceptOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcceptOfferQuery({
 *   variables: {
 *   },
 * });
 */
export function useAcceptOfferQuery(baseOptions?: Apollo.QueryHookOptions<Types.AcceptOfferQuery, Types.AcceptOfferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AcceptOfferQuery, Types.AcceptOfferQueryVariables>(AcceptOfferDocument, options);
      }
export function useAcceptOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AcceptOfferQuery, Types.AcceptOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AcceptOfferQuery, Types.AcceptOfferQueryVariables>(AcceptOfferDocument, options);
        }
export function useAcceptOfferSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AcceptOfferQuery, Types.AcceptOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AcceptOfferQuery, Types.AcceptOfferQueryVariables>(AcceptOfferDocument, options);
        }
export type AcceptOfferQueryHookResult = ReturnType<typeof useAcceptOfferQuery>;
export type AcceptOfferLazyQueryHookResult = ReturnType<typeof useAcceptOfferLazyQuery>;
export type AcceptOfferSuspenseQueryHookResult = ReturnType<typeof useAcceptOfferSuspenseQuery>;
export type AcceptOfferQueryResult = Apollo.QueryResult<Types.AcceptOfferQuery, Types.AcceptOfferQueryVariables>;
export const ApplicationPendingDocument = gql`
    query ApplicationPending {
  viewer {
    credit {
      activeApplication {
        status
      }
    }
  }
}
    `;

/**
 * __useApplicationPendingQuery__
 *
 * To run a query within a React component, call `useApplicationPendingQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationPendingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationPendingQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationPendingQuery(baseOptions?: Apollo.QueryHookOptions<Types.ApplicationPendingQuery, Types.ApplicationPendingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ApplicationPendingQuery, Types.ApplicationPendingQueryVariables>(ApplicationPendingDocument, options);
      }
export function useApplicationPendingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ApplicationPendingQuery, Types.ApplicationPendingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ApplicationPendingQuery, Types.ApplicationPendingQueryVariables>(ApplicationPendingDocument, options);
        }
export function useApplicationPendingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ApplicationPendingQuery, Types.ApplicationPendingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ApplicationPendingQuery, Types.ApplicationPendingQueryVariables>(ApplicationPendingDocument, options);
        }
export type ApplicationPendingQueryHookResult = ReturnType<typeof useApplicationPendingQuery>;
export type ApplicationPendingLazyQueryHookResult = ReturnType<typeof useApplicationPendingLazyQuery>;
export type ApplicationPendingSuspenseQueryHookResult = ReturnType<typeof useApplicationPendingSuspenseQuery>;
export type ApplicationPendingQueryResult = Apollo.QueryResult<Types.ApplicationPendingQuery, Types.ApplicationPendingQueryVariables>;
export const ApplicationRejectedDocument = gql`
    query ApplicationRejected {
  viewer {
    credit {
      activeApplication {
        nextApplicationEligibilityDate
      }
    }
  }
}
    `;

/**
 * __useApplicationRejectedQuery__
 *
 * To run a query within a React component, call `useApplicationRejectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationRejectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationRejectedQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationRejectedQuery(baseOptions?: Apollo.QueryHookOptions<Types.ApplicationRejectedQuery, Types.ApplicationRejectedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ApplicationRejectedQuery, Types.ApplicationRejectedQueryVariables>(ApplicationRejectedDocument, options);
      }
export function useApplicationRejectedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ApplicationRejectedQuery, Types.ApplicationRejectedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ApplicationRejectedQuery, Types.ApplicationRejectedQueryVariables>(ApplicationRejectedDocument, options);
        }
export function useApplicationRejectedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ApplicationRejectedQuery, Types.ApplicationRejectedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ApplicationRejectedQuery, Types.ApplicationRejectedQueryVariables>(ApplicationRejectedDocument, options);
        }
export type ApplicationRejectedQueryHookResult = ReturnType<typeof useApplicationRejectedQuery>;
export type ApplicationRejectedLazyQueryHookResult = ReturnType<typeof useApplicationRejectedLazyQuery>;
export type ApplicationRejectedSuspenseQueryHookResult = ReturnType<typeof useApplicationRejectedSuspenseQuery>;
export type ApplicationRejectedQueryResult = Apollo.QueryResult<Types.ApplicationRejectedQuery, Types.ApplicationRejectedQueryVariables>;
export const CreditApplicationDocument = gql`
    query CreditApplication {
  viewer {
    credit {
      hasActiveAccount
      activeApplication {
        id
        status
      }
      activeAccount {
        id
      }
    }
  }
}
    `;

/**
 * __useCreditApplicationQuery__
 *
 * To run a query within a React component, call `useCreditApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditApplicationQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreditApplicationQuery(baseOptions?: Apollo.QueryHookOptions<Types.CreditApplicationQuery, Types.CreditApplicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CreditApplicationQuery, Types.CreditApplicationQueryVariables>(CreditApplicationDocument, options);
      }
export function useCreditApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CreditApplicationQuery, Types.CreditApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CreditApplicationQuery, Types.CreditApplicationQueryVariables>(CreditApplicationDocument, options);
        }
export function useCreditApplicationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CreditApplicationQuery, Types.CreditApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CreditApplicationQuery, Types.CreditApplicationQueryVariables>(CreditApplicationDocument, options);
        }
export type CreditApplicationQueryHookResult = ReturnType<typeof useCreditApplicationQuery>;
export type CreditApplicationLazyQueryHookResult = ReturnType<typeof useCreditApplicationLazyQuery>;
export type CreditApplicationSuspenseQueryHookResult = ReturnType<typeof useCreditApplicationSuspenseQuery>;
export type CreditApplicationQueryResult = Apollo.QueryResult<Types.CreditApplicationQuery, Types.CreditApplicationQueryVariables>;
export const CreditCardClosedPageDocument = gql`
    query CreditCardClosedPage {
  viewer {
    credit {
      closedAccountContent {
        title
        subtitle
        phoneNumber
      }
    }
  }
}
    `;

/**
 * __useCreditCardClosedPageQuery__
 *
 * To run a query within a React component, call `useCreditCardClosedPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditCardClosedPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditCardClosedPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreditCardClosedPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.CreditCardClosedPageQuery, Types.CreditCardClosedPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CreditCardClosedPageQuery, Types.CreditCardClosedPageQueryVariables>(CreditCardClosedPageDocument, options);
      }
export function useCreditCardClosedPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CreditCardClosedPageQuery, Types.CreditCardClosedPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CreditCardClosedPageQuery, Types.CreditCardClosedPageQueryVariables>(CreditCardClosedPageDocument, options);
        }
export function useCreditCardClosedPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CreditCardClosedPageQuery, Types.CreditCardClosedPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CreditCardClosedPageQuery, Types.CreditCardClosedPageQueryVariables>(CreditCardClosedPageDocument, options);
        }
export type CreditCardClosedPageQueryHookResult = ReturnType<typeof useCreditCardClosedPageQuery>;
export type CreditCardClosedPageLazyQueryHookResult = ReturnType<typeof useCreditCardClosedPageLazyQuery>;
export type CreditCardClosedPageSuspenseQueryHookResult = ReturnType<typeof useCreditCardClosedPageSuspenseQuery>;
export type CreditCardClosedPageQueryResult = Apollo.QueryResult<Types.CreditCardClosedPageQuery, Types.CreditCardClosedPageQueryVariables>;
export const CreditCardCommonTransactionsDocument = gql`
    query CreditCardCommonTransactions($accountId: ID!, $first: Int!, $after: String) {
  node(id: $accountId) {
    ... on CreditCardAccount {
      id
      creditCardProductLandingPage {
        transactions(first: $first, after: $after) {
          ...CommonTransactions
        }
        transactionsEmptyState {
          ...CommonTransactionsEmptyState
        }
      }
    }
  }
}
    ${CommonTransactionsFragmentDoc}
${CommonTransactionsEmptyStateFragmentDoc}`;

/**
 * __useCreditCardCommonTransactionsQuery__
 *
 * To run a query within a React component, call `useCreditCardCommonTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditCardCommonTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditCardCommonTransactionsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useCreditCardCommonTransactionsQuery(baseOptions: Apollo.QueryHookOptions<Types.CreditCardCommonTransactionsQuery, Types.CreditCardCommonTransactionsQueryVariables> & ({ variables: Types.CreditCardCommonTransactionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CreditCardCommonTransactionsQuery, Types.CreditCardCommonTransactionsQueryVariables>(CreditCardCommonTransactionsDocument, options);
      }
export function useCreditCardCommonTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CreditCardCommonTransactionsQuery, Types.CreditCardCommonTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CreditCardCommonTransactionsQuery, Types.CreditCardCommonTransactionsQueryVariables>(CreditCardCommonTransactionsDocument, options);
        }
export function useCreditCardCommonTransactionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CreditCardCommonTransactionsQuery, Types.CreditCardCommonTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CreditCardCommonTransactionsQuery, Types.CreditCardCommonTransactionsQueryVariables>(CreditCardCommonTransactionsDocument, options);
        }
export type CreditCardCommonTransactionsQueryHookResult = ReturnType<typeof useCreditCardCommonTransactionsQuery>;
export type CreditCardCommonTransactionsLazyQueryHookResult = ReturnType<typeof useCreditCardCommonTransactionsLazyQuery>;
export type CreditCardCommonTransactionsSuspenseQueryHookResult = ReturnType<typeof useCreditCardCommonTransactionsSuspenseQuery>;
export type CreditCardCommonTransactionsQueryResult = Apollo.QueryResult<Types.CreditCardCommonTransactionsQuery, Types.CreditCardCommonTransactionsQueryVariables>;
export const CreditCardLandingPageBannersDocument = gql`
    query CreditCardLandingPageBanners($accountId: ID!) {
  node(id: $accountId) {
    ... on CreditCardAccount {
      id
      creditCardProductLandingPage {
        banners {
          ...InformationBanner
        }
      }
    }
  }
}
    ${InformationBannerFragmentDoc}`;

/**
 * __useCreditCardLandingPageBannersQuery__
 *
 * To run a query within a React component, call `useCreditCardLandingPageBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditCardLandingPageBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditCardLandingPageBannersQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useCreditCardLandingPageBannersQuery(baseOptions: Apollo.QueryHookOptions<Types.CreditCardLandingPageBannersQuery, Types.CreditCardLandingPageBannersQueryVariables> & ({ variables: Types.CreditCardLandingPageBannersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CreditCardLandingPageBannersQuery, Types.CreditCardLandingPageBannersQueryVariables>(CreditCardLandingPageBannersDocument, options);
      }
export function useCreditCardLandingPageBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CreditCardLandingPageBannersQuery, Types.CreditCardLandingPageBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CreditCardLandingPageBannersQuery, Types.CreditCardLandingPageBannersQueryVariables>(CreditCardLandingPageBannersDocument, options);
        }
export function useCreditCardLandingPageBannersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CreditCardLandingPageBannersQuery, Types.CreditCardLandingPageBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CreditCardLandingPageBannersQuery, Types.CreditCardLandingPageBannersQueryVariables>(CreditCardLandingPageBannersDocument, options);
        }
export type CreditCardLandingPageBannersQueryHookResult = ReturnType<typeof useCreditCardLandingPageBannersQuery>;
export type CreditCardLandingPageBannersLazyQueryHookResult = ReturnType<typeof useCreditCardLandingPageBannersLazyQuery>;
export type CreditCardLandingPageBannersSuspenseQueryHookResult = ReturnType<typeof useCreditCardLandingPageBannersSuspenseQuery>;
export type CreditCardLandingPageBannersQueryResult = Apollo.QueryResult<Types.CreditCardLandingPageBannersQuery, Types.CreditCardLandingPageBannersQueryVariables>;
export const CreditCardLandingPageGraphDetailsDocument = gql`
    query CreditCardLandingPageGraphDetails($accountId: ID!) {
  node(id: $accountId) {
    ... on CreditCardAccount {
      id
      creditCardProductLandingPage {
        graphDetails {
          title
          tooltip {
            ...AppTooltip
          }
          creditLimit
          currentBalance
          percentage
          description
        }
      }
    }
  }
}
    ${AppTooltipFragmentDoc}`;

/**
 * __useCreditCardLandingPageGraphDetailsQuery__
 *
 * To run a query within a React component, call `useCreditCardLandingPageGraphDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditCardLandingPageGraphDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditCardLandingPageGraphDetailsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useCreditCardLandingPageGraphDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.CreditCardLandingPageGraphDetailsQuery, Types.CreditCardLandingPageGraphDetailsQueryVariables> & ({ variables: Types.CreditCardLandingPageGraphDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CreditCardLandingPageGraphDetailsQuery, Types.CreditCardLandingPageGraphDetailsQueryVariables>(CreditCardLandingPageGraphDetailsDocument, options);
      }
export function useCreditCardLandingPageGraphDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CreditCardLandingPageGraphDetailsQuery, Types.CreditCardLandingPageGraphDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CreditCardLandingPageGraphDetailsQuery, Types.CreditCardLandingPageGraphDetailsQueryVariables>(CreditCardLandingPageGraphDetailsDocument, options);
        }
export function useCreditCardLandingPageGraphDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CreditCardLandingPageGraphDetailsQuery, Types.CreditCardLandingPageGraphDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CreditCardLandingPageGraphDetailsQuery, Types.CreditCardLandingPageGraphDetailsQueryVariables>(CreditCardLandingPageGraphDetailsDocument, options);
        }
export type CreditCardLandingPageGraphDetailsQueryHookResult = ReturnType<typeof useCreditCardLandingPageGraphDetailsQuery>;
export type CreditCardLandingPageGraphDetailsLazyQueryHookResult = ReturnType<typeof useCreditCardLandingPageGraphDetailsLazyQuery>;
export type CreditCardLandingPageGraphDetailsSuspenseQueryHookResult = ReturnType<typeof useCreditCardLandingPageGraphDetailsSuspenseQuery>;
export type CreditCardLandingPageGraphDetailsQueryResult = Apollo.QueryResult<Types.CreditCardLandingPageGraphDetailsQuery, Types.CreditCardLandingPageGraphDetailsQueryVariables>;
export const CreditCardApplicationSummaryDocument = gql`
    query CreditCardApplicationSummary {
  viewer {
    credit {
      ...CreditCardSummary
    }
  }
}
    ${CreditCardSummaryFragmentDoc}`;

/**
 * __useCreditCardApplicationSummaryQuery__
 *
 * To run a query within a React component, call `useCreditCardApplicationSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditCardApplicationSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditCardApplicationSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreditCardApplicationSummaryQuery(baseOptions?: Apollo.QueryHookOptions<Types.CreditCardApplicationSummaryQuery, Types.CreditCardApplicationSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CreditCardApplicationSummaryQuery, Types.CreditCardApplicationSummaryQueryVariables>(CreditCardApplicationSummaryDocument, options);
      }
export function useCreditCardApplicationSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CreditCardApplicationSummaryQuery, Types.CreditCardApplicationSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CreditCardApplicationSummaryQuery, Types.CreditCardApplicationSummaryQueryVariables>(CreditCardApplicationSummaryDocument, options);
        }
export function useCreditCardApplicationSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CreditCardApplicationSummaryQuery, Types.CreditCardApplicationSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CreditCardApplicationSummaryQuery, Types.CreditCardApplicationSummaryQueryVariables>(CreditCardApplicationSummaryDocument, options);
        }
export type CreditCardApplicationSummaryQueryHookResult = ReturnType<typeof useCreditCardApplicationSummaryQuery>;
export type CreditCardApplicationSummaryLazyQueryHookResult = ReturnType<typeof useCreditCardApplicationSummaryLazyQuery>;
export type CreditCardApplicationSummarySuspenseQueryHookResult = ReturnType<typeof useCreditCardApplicationSummarySuspenseQuery>;
export type CreditCardApplicationSummaryQueryResult = Apollo.QueryResult<Types.CreditCardApplicationSummaryQuery, Types.CreditCardApplicationSummaryQueryVariables>;
export const RewardsLandingScreenComponentDocument = gql`
    query RewardsLandingScreenComponent {
  viewer {
    credit {
      rewardsPortal {
        documents {
          ...Linkable
        }
        ...CreditCardRewardsDetails
        summary {
          ...CreditCardRewardsSummary
        }
      }
    }
  }
}
    ${LinkableFragmentDoc}
${CreditCardRewardsDetailsFragmentDoc}
${CreditCardRewardsSummaryFragmentDoc}`;

/**
 * __useRewardsLandingScreenComponentQuery__
 *
 * To run a query within a React component, call `useRewardsLandingScreenComponentQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsLandingScreenComponentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsLandingScreenComponentQuery({
 *   variables: {
 *   },
 * });
 */
export function useRewardsLandingScreenComponentQuery(baseOptions?: Apollo.QueryHookOptions<Types.RewardsLandingScreenComponentQuery, Types.RewardsLandingScreenComponentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RewardsLandingScreenComponentQuery, Types.RewardsLandingScreenComponentQueryVariables>(RewardsLandingScreenComponentDocument, options);
      }
export function useRewardsLandingScreenComponentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RewardsLandingScreenComponentQuery, Types.RewardsLandingScreenComponentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RewardsLandingScreenComponentQuery, Types.RewardsLandingScreenComponentQueryVariables>(RewardsLandingScreenComponentDocument, options);
        }
export function useRewardsLandingScreenComponentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RewardsLandingScreenComponentQuery, Types.RewardsLandingScreenComponentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RewardsLandingScreenComponentQuery, Types.RewardsLandingScreenComponentQueryVariables>(RewardsLandingScreenComponentDocument, options);
        }
export type RewardsLandingScreenComponentQueryHookResult = ReturnType<typeof useRewardsLandingScreenComponentQuery>;
export type RewardsLandingScreenComponentLazyQueryHookResult = ReturnType<typeof useRewardsLandingScreenComponentLazyQuery>;
export type RewardsLandingScreenComponentSuspenseQueryHookResult = ReturnType<typeof useRewardsLandingScreenComponentSuspenseQuery>;
export type RewardsLandingScreenComponentQueryResult = Apollo.QueryResult<Types.RewardsLandingScreenComponentQuery, Types.RewardsLandingScreenComponentQueryVariables>;
export const RewardsSingularProgramComponentDocument = gql`
    query RewardsSingularProgramComponent($programId: String!) {
  viewer {
    credit {
      rewardsPortal {
        rewardsDetails(programId: $programId) {
          title
          header
          content
          programOverview {
            title
            value
            securityLogoUrl
          }
          relatedTransactions {
            ...RelatedTransactions
          }
        }
      }
    }
  }
}
    ${RelatedTransactionsFragmentDoc}`;

/**
 * __useRewardsSingularProgramComponentQuery__
 *
 * To run a query within a React component, call `useRewardsSingularProgramComponentQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsSingularProgramComponentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsSingularProgramComponentQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useRewardsSingularProgramComponentQuery(baseOptions: Apollo.QueryHookOptions<Types.RewardsSingularProgramComponentQuery, Types.RewardsSingularProgramComponentQueryVariables> & ({ variables: Types.RewardsSingularProgramComponentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RewardsSingularProgramComponentQuery, Types.RewardsSingularProgramComponentQueryVariables>(RewardsSingularProgramComponentDocument, options);
      }
export function useRewardsSingularProgramComponentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RewardsSingularProgramComponentQuery, Types.RewardsSingularProgramComponentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RewardsSingularProgramComponentQuery, Types.RewardsSingularProgramComponentQueryVariables>(RewardsSingularProgramComponentDocument, options);
        }
export function useRewardsSingularProgramComponentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RewardsSingularProgramComponentQuery, Types.RewardsSingularProgramComponentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RewardsSingularProgramComponentQuery, Types.RewardsSingularProgramComponentQueryVariables>(RewardsSingularProgramComponentDocument, options);
        }
export type RewardsSingularProgramComponentQueryHookResult = ReturnType<typeof useRewardsSingularProgramComponentQuery>;
export type RewardsSingularProgramComponentLazyQueryHookResult = ReturnType<typeof useRewardsSingularProgramComponentLazyQuery>;
export type RewardsSingularProgramComponentSuspenseQueryHookResult = ReturnType<typeof useRewardsSingularProgramComponentSuspenseQuery>;
export type RewardsSingularProgramComponentQueryResult = Apollo.QueryResult<Types.RewardsSingularProgramComponentQuery, Types.RewardsSingularProgramComponentQueryVariables>;
export const RewardsViewAllProgramsComponentDocument = gql`
    query RewardsViewAllProgramsComponent($programType: CreditCardOwnersRewardsProgramHighlightLinkEnum!) {
  viewer {
    credit {
      rewardsProgramBreakdownByOwnedOrUnowned(ownershipStatus: $programType) {
        title
        subtitle
        programs {
          edges {
            node {
              ...CreditCardRewardsProgram
            }
          }
        }
      }
    }
  }
}
    ${CreditCardRewardsProgramFragmentDoc}`;

/**
 * __useRewardsViewAllProgramsComponentQuery__
 *
 * To run a query within a React component, call `useRewardsViewAllProgramsComponentQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsViewAllProgramsComponentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsViewAllProgramsComponentQuery({
 *   variables: {
 *      programType: // value for 'programType'
 *   },
 * });
 */
export function useRewardsViewAllProgramsComponentQuery(baseOptions: Apollo.QueryHookOptions<Types.RewardsViewAllProgramsComponentQuery, Types.RewardsViewAllProgramsComponentQueryVariables> & ({ variables: Types.RewardsViewAllProgramsComponentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RewardsViewAllProgramsComponentQuery, Types.RewardsViewAllProgramsComponentQueryVariables>(RewardsViewAllProgramsComponentDocument, options);
      }
export function useRewardsViewAllProgramsComponentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RewardsViewAllProgramsComponentQuery, Types.RewardsViewAllProgramsComponentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RewardsViewAllProgramsComponentQuery, Types.RewardsViewAllProgramsComponentQueryVariables>(RewardsViewAllProgramsComponentDocument, options);
        }
export function useRewardsViewAllProgramsComponentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RewardsViewAllProgramsComponentQuery, Types.RewardsViewAllProgramsComponentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RewardsViewAllProgramsComponentQuery, Types.RewardsViewAllProgramsComponentQueryVariables>(RewardsViewAllProgramsComponentDocument, options);
        }
export type RewardsViewAllProgramsComponentQueryHookResult = ReturnType<typeof useRewardsViewAllProgramsComponentQuery>;
export type RewardsViewAllProgramsComponentLazyQueryHookResult = ReturnType<typeof useRewardsViewAllProgramsComponentLazyQuery>;
export type RewardsViewAllProgramsComponentSuspenseQueryHookResult = ReturnType<typeof useRewardsViewAllProgramsComponentSuspenseQuery>;
export type RewardsViewAllProgramsComponentQueryResult = Apollo.QueryResult<Types.RewardsViewAllProgramsComponentQuery, Types.RewardsViewAllProgramsComponentQueryVariables>;
export const StartApplicationDocument = gql`
    query StartApplication {
  viewer {
    credit {
      onboardingContent {
        startApplicationScreen {
          header
          label
          content
          disclaimer
          buttonLabel
        }
      }
    }
  }
}
    `;

/**
 * __useStartApplicationQuery__
 *
 * To run a query within a React component, call `useStartApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartApplicationQuery({
 *   variables: {
 *   },
 * });
 */
export function useStartApplicationQuery(baseOptions?: Apollo.QueryHookOptions<Types.StartApplicationQuery, Types.StartApplicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StartApplicationQuery, Types.StartApplicationQueryVariables>(StartApplicationDocument, options);
      }
export function useStartApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StartApplicationQuery, Types.StartApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StartApplicationQuery, Types.StartApplicationQueryVariables>(StartApplicationDocument, options);
        }
export function useStartApplicationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.StartApplicationQuery, Types.StartApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.StartApplicationQuery, Types.StartApplicationQueryVariables>(StartApplicationDocument, options);
        }
export type StartApplicationQueryHookResult = ReturnType<typeof useStartApplicationQuery>;
export type StartApplicationLazyQueryHookResult = ReturnType<typeof useStartApplicationLazyQuery>;
export type StartApplicationSuspenseQueryHookResult = ReturnType<typeof useStartApplicationSuspenseQuery>;
export type StartApplicationQueryResult = Apollo.QueryResult<Types.StartApplicationQuery, Types.StartApplicationQueryVariables>;
export const UpdateRewardsPayoutLocationStepDocument = gql`
    query UpdateRewardsPayoutLocationStep {
  viewer {
    credit {
      selectRewardDestinationContent {
        ...SelectRewardDestinationContent
      }
    }
  }
}
    ${SelectRewardDestinationContentFragmentDoc}`;

/**
 * __useUpdateRewardsPayoutLocationStepQuery__
 *
 * To run a query within a React component, call `useUpdateRewardsPayoutLocationStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateRewardsPayoutLocationStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateRewardsPayoutLocationStepQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpdateRewardsPayoutLocationStepQuery(baseOptions?: Apollo.QueryHookOptions<Types.UpdateRewardsPayoutLocationStepQuery, Types.UpdateRewardsPayoutLocationStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UpdateRewardsPayoutLocationStepQuery, Types.UpdateRewardsPayoutLocationStepQueryVariables>(UpdateRewardsPayoutLocationStepDocument, options);
      }
export function useUpdateRewardsPayoutLocationStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UpdateRewardsPayoutLocationStepQuery, Types.UpdateRewardsPayoutLocationStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UpdateRewardsPayoutLocationStepQuery, Types.UpdateRewardsPayoutLocationStepQueryVariables>(UpdateRewardsPayoutLocationStepDocument, options);
        }
export function useUpdateRewardsPayoutLocationStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UpdateRewardsPayoutLocationStepQuery, Types.UpdateRewardsPayoutLocationStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UpdateRewardsPayoutLocationStepQuery, Types.UpdateRewardsPayoutLocationStepQueryVariables>(UpdateRewardsPayoutLocationStepDocument, options);
        }
export type UpdateRewardsPayoutLocationStepQueryHookResult = ReturnType<typeof useUpdateRewardsPayoutLocationStepQuery>;
export type UpdateRewardsPayoutLocationStepLazyQueryHookResult = ReturnType<typeof useUpdateRewardsPayoutLocationStepLazyQuery>;
export type UpdateRewardsPayoutLocationStepSuspenseQueryHookResult = ReturnType<typeof useUpdateRewardsPayoutLocationStepSuspenseQuery>;
export type UpdateRewardsPayoutLocationStepQueryResult = Apollo.QueryResult<Types.UpdateRewardsPayoutLocationStepQuery, Types.UpdateRewardsPayoutLocationStepQueryVariables>;
export const CreditRewardDestinationRefetchDocument = gql`
    query CreditRewardDestinationRefetch {
  viewer {
    credit {
      rewardsPortal {
        summary {
          rewardsPayoutLocation {
            icon {
              lightTheme {
                scale1xUrl
                scale2xUrl
                scale3xUrl
              }
              darkTheme {
                scale1xUrl
                scale2xUrl
                scale3xUrl
              }
            }
            label
            value
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCreditRewardDestinationRefetchQuery__
 *
 * To run a query within a React component, call `useCreditRewardDestinationRefetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditRewardDestinationRefetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditRewardDestinationRefetchQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreditRewardDestinationRefetchQuery(baseOptions?: Apollo.QueryHookOptions<Types.CreditRewardDestinationRefetchQuery, Types.CreditRewardDestinationRefetchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CreditRewardDestinationRefetchQuery, Types.CreditRewardDestinationRefetchQueryVariables>(CreditRewardDestinationRefetchDocument, options);
      }
export function useCreditRewardDestinationRefetchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CreditRewardDestinationRefetchQuery, Types.CreditRewardDestinationRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CreditRewardDestinationRefetchQuery, Types.CreditRewardDestinationRefetchQueryVariables>(CreditRewardDestinationRefetchDocument, options);
        }
export function useCreditRewardDestinationRefetchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CreditRewardDestinationRefetchQuery, Types.CreditRewardDestinationRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CreditRewardDestinationRefetchQuery, Types.CreditRewardDestinationRefetchQueryVariables>(CreditRewardDestinationRefetchDocument, options);
        }
export type CreditRewardDestinationRefetchQueryHookResult = ReturnType<typeof useCreditRewardDestinationRefetchQuery>;
export type CreditRewardDestinationRefetchLazyQueryHookResult = ReturnType<typeof useCreditRewardDestinationRefetchLazyQuery>;
export type CreditRewardDestinationRefetchSuspenseQueryHookResult = ReturnType<typeof useCreditRewardDestinationRefetchSuspenseQuery>;
export type CreditRewardDestinationRefetchQueryResult = Apollo.QueryResult<Types.CreditRewardDestinationRefetchQuery, Types.CreditRewardDestinationRefetchQueryVariables>;
export const CryptoEligibilityDocument = gql`
    query CryptoEligibility {
  viewer {
    crypto {
      isEligible
      hasCryptoAccount
    }
  }
}
    `;

/**
 * __useCryptoEligibilityQuery__
 *
 * To run a query within a React component, call `useCryptoEligibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCryptoEligibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCryptoEligibilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useCryptoEligibilityQuery(baseOptions?: Apollo.QueryHookOptions<Types.CryptoEligibilityQuery, Types.CryptoEligibilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CryptoEligibilityQuery, Types.CryptoEligibilityQueryVariables>(CryptoEligibilityDocument, options);
      }
export function useCryptoEligibilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CryptoEligibilityQuery, Types.CryptoEligibilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CryptoEligibilityQuery, Types.CryptoEligibilityQueryVariables>(CryptoEligibilityDocument, options);
        }
export function useCryptoEligibilitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CryptoEligibilityQuery, Types.CryptoEligibilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CryptoEligibilityQuery, Types.CryptoEligibilityQueryVariables>(CryptoEligibilityDocument, options);
        }
export type CryptoEligibilityQueryHookResult = ReturnType<typeof useCryptoEligibilityQuery>;
export type CryptoEligibilityLazyQueryHookResult = ReturnType<typeof useCryptoEligibilityLazyQuery>;
export type CryptoEligibilitySuspenseQueryHookResult = ReturnType<typeof useCryptoEligibilitySuspenseQuery>;
export type CryptoEligibilityQueryResult = Apollo.QueryResult<Types.CryptoEligibilityQuery, Types.CryptoEligibilityQueryVariables>;
export const DisclosuresForCryptoPageDocument = gql`
    query DisclosuresForCryptoPage {
  viewer {
    invest {
      requiredDocuments(accountRegistration: CRYPTO) {
        documents {
          title
          url
        }
      }
    }
  }
}
    `;

/**
 * __useDisclosuresForCryptoPageQuery__
 *
 * To run a query within a React component, call `useDisclosuresForCryptoPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisclosuresForCryptoPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisclosuresForCryptoPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useDisclosuresForCryptoPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.DisclosuresForCryptoPageQuery, Types.DisclosuresForCryptoPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DisclosuresForCryptoPageQuery, Types.DisclosuresForCryptoPageQueryVariables>(DisclosuresForCryptoPageDocument, options);
      }
export function useDisclosuresForCryptoPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DisclosuresForCryptoPageQuery, Types.DisclosuresForCryptoPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DisclosuresForCryptoPageQuery, Types.DisclosuresForCryptoPageQueryVariables>(DisclosuresForCryptoPageDocument, options);
        }
export function useDisclosuresForCryptoPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.DisclosuresForCryptoPageQuery, Types.DisclosuresForCryptoPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DisclosuresForCryptoPageQuery, Types.DisclosuresForCryptoPageQueryVariables>(DisclosuresForCryptoPageDocument, options);
        }
export type DisclosuresForCryptoPageQueryHookResult = ReturnType<typeof useDisclosuresForCryptoPageQuery>;
export type DisclosuresForCryptoPageLazyQueryHookResult = ReturnType<typeof useDisclosuresForCryptoPageLazyQuery>;
export type DisclosuresForCryptoPageSuspenseQueryHookResult = ReturnType<typeof useDisclosuresForCryptoPageSuspenseQuery>;
export type DisclosuresForCryptoPageQueryResult = Apollo.QueryResult<Types.DisclosuresForCryptoPageQuery, Types.DisclosuresForCryptoPageQueryVariables>;
export const NavigationDocument = gql`
    query Navigation {
  viewer {
    accounts(first: 100) {
      edges {
        node {
          allowsExternalFunding
          id
          isCryptoAccount
          isCustodialAccount
          name
          number
          registration
          balance {
            totalValue {
              value
            }
          }
          transferParticipantType
          rootPortfolioSlice {
            buyingPower {
              total {
                value
              }
            }
            manualOrderBreakdown {
              marginBuyingPower {
                numericValue
              }
            }
          }
        }
      }
    }
    borrow {
      isEligibleToApplyForPersonalLoan
      hasBorrowedBefore
      hasBorrowAccountWithOpenMaintenanceCall
      hasLatePersonalLoan
      hasFailedPersonalLoanDisbursement
      borrowAccounts {
        edges {
          node {
            id
            descriptor
            name
            transferParticipantType
          }
        }
      }
      personalLoans {
        activeApplication {
          status {
            status
          }
        }
        applyForPersonalLoanCta {
          url
          title
          internalPath
        }
        loans {
          edges {
            node {
              id
              name
              descriptor
            }
          }
        }
      }
    }
    credit {
      isEnabled
      isEligible
      hasActiveAccount
      hasClosedAccount
      hasSuspendedAccount
      activeAccount {
        id
        currentBalance
      }
      activeApplication {
        id
        status
      }
    }
    invest {
      hasActiveInvestAccount
    }
    save {
      isSavingsEnabled
      isMultipleAccountsEligible
      isJointAccountsEligible
      addSaveAccountCta {
        title
        subtitle
        icon {
          ...AppImage
        }
        internalPath
      }
      savings {
        hasSavingsAccounts
        savingsAccounts {
          edges {
            node {
              accountType
              descriptor
              name
              id
            }
          }
        }
      }
    }
    user {
      id
      username
      data(
        keys: ["approvedCreditCardApplicationIds", "acceptedCreditCardApplicationIds"]
      ) {
        key
        value
      }
    }
    profile {
      primary {
        fullName
        firstName
      }
    }
  }
}
    ${AppImageFragmentDoc}`;

/**
 * __useNavigationQuery__
 *
 * To run a query within a React component, call `useNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavigationQuery(baseOptions?: Apollo.QueryHookOptions<Types.NavigationQuery, Types.NavigationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NavigationQuery, Types.NavigationQueryVariables>(NavigationDocument, options);
      }
export function useNavigationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NavigationQuery, Types.NavigationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NavigationQuery, Types.NavigationQueryVariables>(NavigationDocument, options);
        }
export function useNavigationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.NavigationQuery, Types.NavigationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.NavigationQuery, Types.NavigationQueryVariables>(NavigationDocument, options);
        }
export type NavigationQueryHookResult = ReturnType<typeof useNavigationQuery>;
export type NavigationLazyQueryHookResult = ReturnType<typeof useNavigationLazyQuery>;
export type NavigationSuspenseQueryHookResult = ReturnType<typeof useNavigationSuspenseQuery>;
export type NavigationQueryResult = Apollo.QueryResult<Types.NavigationQuery, Types.NavigationQueryVariables>;
export const DocumentAnnouncementsDocument = gql`
    query DocumentAnnouncements {
  viewer {
    announcements {
      forDocuments {
        ...Announcement
      }
    }
  }
}
    ${AnnouncementFragmentDoc}`;

/**
 * __useDocumentAnnouncementsQuery__
 *
 * To run a query within a React component, call `useDocumentAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentAnnouncementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDocumentAnnouncementsQuery(baseOptions?: Apollo.QueryHookOptions<Types.DocumentAnnouncementsQuery, Types.DocumentAnnouncementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DocumentAnnouncementsQuery, Types.DocumentAnnouncementsQueryVariables>(DocumentAnnouncementsDocument, options);
      }
export function useDocumentAnnouncementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentAnnouncementsQuery, Types.DocumentAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DocumentAnnouncementsQuery, Types.DocumentAnnouncementsQueryVariables>(DocumentAnnouncementsDocument, options);
        }
export function useDocumentAnnouncementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.DocumentAnnouncementsQuery, Types.DocumentAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DocumentAnnouncementsQuery, Types.DocumentAnnouncementsQueryVariables>(DocumentAnnouncementsDocument, options);
        }
export type DocumentAnnouncementsQueryHookResult = ReturnType<typeof useDocumentAnnouncementsQuery>;
export type DocumentAnnouncementsLazyQueryHookResult = ReturnType<typeof useDocumentAnnouncementsLazyQuery>;
export type DocumentAnnouncementsSuspenseQueryHookResult = ReturnType<typeof useDocumentAnnouncementsSuspenseQuery>;
export type DocumentAnnouncementsQueryResult = Apollo.QueryResult<Types.DocumentAnnouncementsQuery, Types.DocumentAnnouncementsQueryVariables>;
export const DocumentListDocument = gql`
    query DocumentList($first: Int!, $documentType: DocumentTypeFilterEnumType!, $startDate: String, $endDate: String, $after: String) {
  viewer {
    documents(
      first: $first
      documentType: $documentType
      startDate: $startDate
      endDate: $endDate
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...DocumentNode
        }
      }
      missingDocumentsBanner {
        ...InformationBanner
      }
    }
    user {
      id
      created
    }
  }
}
    ${DocumentNodeFragmentDoc}
${InformationBannerFragmentDoc}`;

/**
 * __useDocumentListQuery__
 *
 * To run a query within a React component, call `useDocumentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      documentType: // value for 'documentType'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useDocumentListQuery(baseOptions: Apollo.QueryHookOptions<Types.DocumentListQuery, Types.DocumentListQueryVariables> & ({ variables: Types.DocumentListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DocumentListQuery, Types.DocumentListQueryVariables>(DocumentListDocument, options);
      }
export function useDocumentListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentListQuery, Types.DocumentListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DocumentListQuery, Types.DocumentListQueryVariables>(DocumentListDocument, options);
        }
export function useDocumentListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.DocumentListQuery, Types.DocumentListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DocumentListQuery, Types.DocumentListQueryVariables>(DocumentListDocument, options);
        }
export type DocumentListQueryHookResult = ReturnType<typeof useDocumentListQuery>;
export type DocumentListLazyQueryHookResult = ReturnType<typeof useDocumentListLazyQuery>;
export type DocumentListSuspenseQueryHookResult = ReturnType<typeof useDocumentListSuspenseQuery>;
export type DocumentListQueryResult = Apollo.QueryResult<Types.DocumentListQuery, Types.DocumentListQueryVariables>;
export const DocumentManagementPageDocument = gql`
    query DocumentManagementPage($associatedService: DocumentUploadRequestAssociatedService) {
  viewer {
    documentUploadRequestsCenter {
      documentUploadRequests(
        first: 10
        filterByStatus: Requested
        filterByAssociatedService: $associatedService
      ) {
        edges {
          node {
            ...Document
          }
        }
      }
    }
  }
}
    ${DocumentFragmentDoc}`;

/**
 * __useDocumentManagementPageQuery__
 *
 * To run a query within a React component, call `useDocumentManagementPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentManagementPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentManagementPageQuery({
 *   variables: {
 *      associatedService: // value for 'associatedService'
 *   },
 * });
 */
export function useDocumentManagementPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.DocumentManagementPageQuery, Types.DocumentManagementPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DocumentManagementPageQuery, Types.DocumentManagementPageQueryVariables>(DocumentManagementPageDocument, options);
      }
export function useDocumentManagementPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentManagementPageQuery, Types.DocumentManagementPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DocumentManagementPageQuery, Types.DocumentManagementPageQueryVariables>(DocumentManagementPageDocument, options);
        }
export function useDocumentManagementPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.DocumentManagementPageQuery, Types.DocumentManagementPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DocumentManagementPageQuery, Types.DocumentManagementPageQueryVariables>(DocumentManagementPageDocument, options);
        }
export type DocumentManagementPageQueryHookResult = ReturnType<typeof useDocumentManagementPageQuery>;
export type DocumentManagementPageLazyQueryHookResult = ReturnType<typeof useDocumentManagementPageLazyQuery>;
export type DocumentManagementPageSuspenseQueryHookResult = ReturnType<typeof useDocumentManagementPageSuspenseQuery>;
export type DocumentManagementPageQueryResult = Apollo.QueryResult<Types.DocumentManagementPageQuery, Types.DocumentManagementPageQueryVariables>;
export const DocumentRequirementsDocument = gql`
    query DocumentRequirements {
  viewer {
    documentUploadRequestsCenter {
      documentRequirements {
        acceptableFileTypes
        acceptableMimeTypes
        maximumFileSizeInBytes
        maximumFileSizeDescription
      }
    }
  }
}
    `;

/**
 * __useDocumentRequirementsQuery__
 *
 * To run a query within a React component, call `useDocumentRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentRequirementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDocumentRequirementsQuery(baseOptions?: Apollo.QueryHookOptions<Types.DocumentRequirementsQuery, Types.DocumentRequirementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DocumentRequirementsQuery, Types.DocumentRequirementsQueryVariables>(DocumentRequirementsDocument, options);
      }
export function useDocumentRequirementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentRequirementsQuery, Types.DocumentRequirementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DocumentRequirementsQuery, Types.DocumentRequirementsQueryVariables>(DocumentRequirementsDocument, options);
        }
export function useDocumentRequirementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.DocumentRequirementsQuery, Types.DocumentRequirementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DocumentRequirementsQuery, Types.DocumentRequirementsQueryVariables>(DocumentRequirementsDocument, options);
        }
export type DocumentRequirementsQueryHookResult = ReturnType<typeof useDocumentRequirementsQuery>;
export type DocumentRequirementsLazyQueryHookResult = ReturnType<typeof useDocumentRequirementsLazyQuery>;
export type DocumentRequirementsSuspenseQueryHookResult = ReturnType<typeof useDocumentRequirementsSuspenseQuery>;
export type DocumentRequirementsQueryResult = Apollo.QueryResult<Types.DocumentRequirementsQuery, Types.DocumentRequirementsQueryVariables>;
export const UploadDocumentsDocument = gql`
    query UploadDocuments {
  viewer {
    credit {
      onboardingContent {
        uploadDocumentLink {
          ...Linkable
        }
      }
    }
  }
}
    ${LinkableFragmentDoc}`;

/**
 * __useUploadDocumentsQuery__
 *
 * To run a query within a React component, call `useUploadDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUploadDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUploadDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<Types.UploadDocumentsQuery, Types.UploadDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UploadDocumentsQuery, Types.UploadDocumentsQueryVariables>(UploadDocumentsDocument, options);
      }
export function useUploadDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UploadDocumentsQuery, Types.UploadDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UploadDocumentsQuery, Types.UploadDocumentsQueryVariables>(UploadDocumentsDocument, options);
        }
export function useUploadDocumentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UploadDocumentsQuery, Types.UploadDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UploadDocumentsQuery, Types.UploadDocumentsQueryVariables>(UploadDocumentsDocument, options);
        }
export type UploadDocumentsQueryHookResult = ReturnType<typeof useUploadDocumentsQuery>;
export type UploadDocumentsLazyQueryHookResult = ReturnType<typeof useUploadDocumentsLazyQuery>;
export type UploadDocumentsSuspenseQueryHookResult = ReturnType<typeof useUploadDocumentsSuspenseQuery>;
export type UploadDocumentsQueryResult = Apollo.QueryResult<Types.UploadDocumentsQuery, Types.UploadDocumentsQueryVariables>;
export const EarnAnnouncementsDocument = gql`
    query EarnAnnouncements {
  viewer {
    announcements {
      forSave {
        ...Announcement
      }
    }
  }
}
    ${AnnouncementFragmentDoc}`;

/**
 * __useEarnAnnouncementsQuery__
 *
 * To run a query within a React component, call `useEarnAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarnAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarnAnnouncementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEarnAnnouncementsQuery(baseOptions?: Apollo.QueryHookOptions<Types.EarnAnnouncementsQuery, Types.EarnAnnouncementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EarnAnnouncementsQuery, Types.EarnAnnouncementsQueryVariables>(EarnAnnouncementsDocument, options);
      }
export function useEarnAnnouncementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EarnAnnouncementsQuery, Types.EarnAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EarnAnnouncementsQuery, Types.EarnAnnouncementsQueryVariables>(EarnAnnouncementsDocument, options);
        }
export function useEarnAnnouncementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EarnAnnouncementsQuery, Types.EarnAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EarnAnnouncementsQuery, Types.EarnAnnouncementsQueryVariables>(EarnAnnouncementsDocument, options);
        }
export type EarnAnnouncementsQueryHookResult = ReturnType<typeof useEarnAnnouncementsQuery>;
export type EarnAnnouncementsLazyQueryHookResult = ReturnType<typeof useEarnAnnouncementsLazyQuery>;
export type EarnAnnouncementsSuspenseQueryHookResult = ReturnType<typeof useEarnAnnouncementsSuspenseQuery>;
export type EarnAnnouncementsQueryResult = Apollo.QueryResult<Types.EarnAnnouncementsQuery, Types.EarnAnnouncementsQueryVariables>;
export const EarnCommonTransactionsDocument = gql`
    query EarnCommonTransactions($accountId: ID!, $first: Int!, $after: String) {
  node(id: $accountId) {
    ... on SavingsAccount {
      id
      earnProductLandingPage {
        transactions(first: $first, after: $after) {
          ...CommonTransactions
        }
        transactionsEmptyState {
          ...CommonTransactionsEmptyState
        }
      }
      isFunded
      initialTransferParticipant {
        id
      }
      setupChecklist {
        ...SetupChecklist
      }
    }
  }
}
    ${CommonTransactionsFragmentDoc}
${CommonTransactionsEmptyStateFragmentDoc}
${SetupChecklistFragmentDoc}`;

/**
 * __useEarnCommonTransactionsQuery__
 *
 * To run a query within a React component, call `useEarnCommonTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarnCommonTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarnCommonTransactionsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useEarnCommonTransactionsQuery(baseOptions: Apollo.QueryHookOptions<Types.EarnCommonTransactionsQuery, Types.EarnCommonTransactionsQueryVariables> & ({ variables: Types.EarnCommonTransactionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EarnCommonTransactionsQuery, Types.EarnCommonTransactionsQueryVariables>(EarnCommonTransactionsDocument, options);
      }
export function useEarnCommonTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EarnCommonTransactionsQuery, Types.EarnCommonTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EarnCommonTransactionsQuery, Types.EarnCommonTransactionsQueryVariables>(EarnCommonTransactionsDocument, options);
        }
export function useEarnCommonTransactionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EarnCommonTransactionsQuery, Types.EarnCommonTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EarnCommonTransactionsQuery, Types.EarnCommonTransactionsQueryVariables>(EarnCommonTransactionsDocument, options);
        }
export type EarnCommonTransactionsQueryHookResult = ReturnType<typeof useEarnCommonTransactionsQuery>;
export type EarnCommonTransactionsLazyQueryHookResult = ReturnType<typeof useEarnCommonTransactionsLazyQuery>;
export type EarnCommonTransactionsSuspenseQueryHookResult = ReturnType<typeof useEarnCommonTransactionsSuspenseQuery>;
export type EarnCommonTransactionsQueryResult = Apollo.QueryResult<Types.EarnCommonTransactionsQuery, Types.EarnCommonTransactionsQueryVariables>;
export const EarnLandingPageBannersDocument = gql`
    query EarnLandingPageBanners($accountId: ID!) {
  node(id: $accountId) {
    ... on SavingsAccount {
      earnProductLandingPage {
        banners {
          ...InformationBanner
        }
      }
    }
  }
}
    ${InformationBannerFragmentDoc}`;

/**
 * __useEarnLandingPageBannersQuery__
 *
 * To run a query within a React component, call `useEarnLandingPageBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarnLandingPageBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarnLandingPageBannersQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useEarnLandingPageBannersQuery(baseOptions: Apollo.QueryHookOptions<Types.EarnLandingPageBannersQuery, Types.EarnLandingPageBannersQueryVariables> & ({ variables: Types.EarnLandingPageBannersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EarnLandingPageBannersQuery, Types.EarnLandingPageBannersQueryVariables>(EarnLandingPageBannersDocument, options);
      }
export function useEarnLandingPageBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EarnLandingPageBannersQuery, Types.EarnLandingPageBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EarnLandingPageBannersQuery, Types.EarnLandingPageBannersQueryVariables>(EarnLandingPageBannersDocument, options);
        }
export function useEarnLandingPageBannersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EarnLandingPageBannersQuery, Types.EarnLandingPageBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EarnLandingPageBannersQuery, Types.EarnLandingPageBannersQueryVariables>(EarnLandingPageBannersDocument, options);
        }
export type EarnLandingPageBannersQueryHookResult = ReturnType<typeof useEarnLandingPageBannersQuery>;
export type EarnLandingPageBannersLazyQueryHookResult = ReturnType<typeof useEarnLandingPageBannersLazyQuery>;
export type EarnLandingPageBannersSuspenseQueryHookResult = ReturnType<typeof useEarnLandingPageBannersSuspenseQuery>;
export type EarnLandingPageBannersQueryResult = Apollo.QueryResult<Types.EarnLandingPageBannersQuery, Types.EarnLandingPageBannersQueryVariables>;
export const EarnProductLandingPageHeaderDocument = gql`
    query EarnProductLandingPageHeader($id: ID!) {
  node(id: $id) {
    ... on SavingsAccount {
      earnProductLandingPage {
        title
        header {
          icon {
            ...AppImage
          }
          title
          value
          tooltip {
            ...AppTooltip
          }
        }
        accountDetails {
          ...TabularDataItem
        }
      }
      fundAccountLink {
        ...Linkable
      }
    }
  }
}
    ${AppImageFragmentDoc}
${AppTooltipFragmentDoc}
${TabularDataItemFragmentDoc}
${LinkableFragmentDoc}`;

/**
 * __useEarnProductLandingPageHeaderQuery__
 *
 * To run a query within a React component, call `useEarnProductLandingPageHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarnProductLandingPageHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarnProductLandingPageHeaderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEarnProductLandingPageHeaderQuery(baseOptions: Apollo.QueryHookOptions<Types.EarnProductLandingPageHeaderQuery, Types.EarnProductLandingPageHeaderQueryVariables> & ({ variables: Types.EarnProductLandingPageHeaderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EarnProductLandingPageHeaderQuery, Types.EarnProductLandingPageHeaderQueryVariables>(EarnProductLandingPageHeaderDocument, options);
      }
export function useEarnProductLandingPageHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EarnProductLandingPageHeaderQuery, Types.EarnProductLandingPageHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EarnProductLandingPageHeaderQuery, Types.EarnProductLandingPageHeaderQueryVariables>(EarnProductLandingPageHeaderDocument, options);
        }
export function useEarnProductLandingPageHeaderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EarnProductLandingPageHeaderQuery, Types.EarnProductLandingPageHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EarnProductLandingPageHeaderQuery, Types.EarnProductLandingPageHeaderQueryVariables>(EarnProductLandingPageHeaderDocument, options);
        }
export type EarnProductLandingPageHeaderQueryHookResult = ReturnType<typeof useEarnProductLandingPageHeaderQuery>;
export type EarnProductLandingPageHeaderLazyQueryHookResult = ReturnType<typeof useEarnProductLandingPageHeaderLazyQuery>;
export type EarnProductLandingPageHeaderSuspenseQueryHookResult = ReturnType<typeof useEarnProductLandingPageHeaderSuspenseQuery>;
export type EarnProductLandingPageHeaderQueryResult = Apollo.QueryResult<Types.EarnProductLandingPageHeaderQuery, Types.EarnProductLandingPageHeaderQueryVariables>;
export const EarnLandingPageValuePropCardDocument = gql`
    query EarnLandingPageValuePropCard($id: ID!) {
  node(id: $id) {
    ... on SavingsAccount {
      earnProductLandingPage {
        valuePropCard {
          ...LandingPageValuePropCard
        }
      }
    }
  }
}
    ${LandingPageValuePropCardFragmentDoc}`;

/**
 * __useEarnLandingPageValuePropCardQuery__
 *
 * To run a query within a React component, call `useEarnLandingPageValuePropCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarnLandingPageValuePropCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarnLandingPageValuePropCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEarnLandingPageValuePropCardQuery(baseOptions: Apollo.QueryHookOptions<Types.EarnLandingPageValuePropCardQuery, Types.EarnLandingPageValuePropCardQueryVariables> & ({ variables: Types.EarnLandingPageValuePropCardQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EarnLandingPageValuePropCardQuery, Types.EarnLandingPageValuePropCardQueryVariables>(EarnLandingPageValuePropCardDocument, options);
      }
export function useEarnLandingPageValuePropCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EarnLandingPageValuePropCardQuery, Types.EarnLandingPageValuePropCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EarnLandingPageValuePropCardQuery, Types.EarnLandingPageValuePropCardQueryVariables>(EarnLandingPageValuePropCardDocument, options);
        }
export function useEarnLandingPageValuePropCardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EarnLandingPageValuePropCardQuery, Types.EarnLandingPageValuePropCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EarnLandingPageValuePropCardQuery, Types.EarnLandingPageValuePropCardQueryVariables>(EarnLandingPageValuePropCardDocument, options);
        }
export type EarnLandingPageValuePropCardQueryHookResult = ReturnType<typeof useEarnLandingPageValuePropCardQuery>;
export type EarnLandingPageValuePropCardLazyQueryHookResult = ReturnType<typeof useEarnLandingPageValuePropCardLazyQuery>;
export type EarnLandingPageValuePropCardSuspenseQueryHookResult = ReturnType<typeof useEarnLandingPageValuePropCardSuspenseQuery>;
export type EarnLandingPageValuePropCardQueryResult = Apollo.QueryResult<Types.EarnLandingPageValuePropCardQuery, Types.EarnLandingPageValuePropCardQueryVariables>;
export const EarnMarketingScreenDocument = gql`
    query EarnMarketingScreen {
  viewer {
    earn {
      earnMarketingScreen {
        header
        productMarketingCards {
          illustration {
            ...AppImage
          }
          title
          titleTooltip
          subtitle
          primaryCtaButton {
            ...AppLink
          }
          secondaryCtaButton {
            ...AppLink
          }
          analyticsEvent {
            ...AnalyticsEvent
          }
        }
        disclosures {
          ...AppRichTextDisclosureLink
        }
      }
    }
    announcements {
      forSaveMarketing {
        ...Announcement
      }
    }
  }
}
    ${AppImageFragmentDoc}
${AppLinkFragmentDoc}
${AnalyticsEventFragmentDoc}
${AppRichTextDisclosureLinkFragmentDoc}
${AnnouncementFragmentDoc}`;

/**
 * __useEarnMarketingScreenQuery__
 *
 * To run a query within a React component, call `useEarnMarketingScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarnMarketingScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarnMarketingScreenQuery({
 *   variables: {
 *   },
 * });
 */
export function useEarnMarketingScreenQuery(baseOptions?: Apollo.QueryHookOptions<Types.EarnMarketingScreenQuery, Types.EarnMarketingScreenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EarnMarketingScreenQuery, Types.EarnMarketingScreenQueryVariables>(EarnMarketingScreenDocument, options);
      }
export function useEarnMarketingScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EarnMarketingScreenQuery, Types.EarnMarketingScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EarnMarketingScreenQuery, Types.EarnMarketingScreenQueryVariables>(EarnMarketingScreenDocument, options);
        }
export function useEarnMarketingScreenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EarnMarketingScreenQuery, Types.EarnMarketingScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EarnMarketingScreenQuery, Types.EarnMarketingScreenQueryVariables>(EarnMarketingScreenDocument, options);
        }
export type EarnMarketingScreenQueryHookResult = ReturnType<typeof useEarnMarketingScreenQuery>;
export type EarnMarketingScreenLazyQueryHookResult = ReturnType<typeof useEarnMarketingScreenLazyQuery>;
export type EarnMarketingScreenSuspenseQueryHookResult = ReturnType<typeof useEarnMarketingScreenSuspenseQuery>;
export type EarnMarketingScreenQueryResult = Apollo.QueryResult<Types.EarnMarketingScreenQuery, Types.EarnMarketingScreenQueryVariables>;
export const EarnProductLandingPageDocument = gql`
    query EarnProductLandingPage($accountId: ID!) {
  node(id: $accountId) {
    ... on SavingsAccount {
      earnProductLandingPage {
        disclosures {
          ...AppRichTextDisclosureLink
        }
      }
    }
  }
  viewer {
    announcements {
      forSave {
        ...Announcement
      }
    }
  }
}
    ${AppRichTextDisclosureLinkFragmentDoc}
${AnnouncementFragmentDoc}`;

/**
 * __useEarnProductLandingPageQuery__
 *
 * To run a query within a React component, call `useEarnProductLandingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarnProductLandingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarnProductLandingPageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useEarnProductLandingPageQuery(baseOptions: Apollo.QueryHookOptions<Types.EarnProductLandingPageQuery, Types.EarnProductLandingPageQueryVariables> & ({ variables: Types.EarnProductLandingPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EarnProductLandingPageQuery, Types.EarnProductLandingPageQueryVariables>(EarnProductLandingPageDocument, options);
      }
export function useEarnProductLandingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EarnProductLandingPageQuery, Types.EarnProductLandingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EarnProductLandingPageQuery, Types.EarnProductLandingPageQueryVariables>(EarnProductLandingPageDocument, options);
        }
export function useEarnProductLandingPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EarnProductLandingPageQuery, Types.EarnProductLandingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EarnProductLandingPageQuery, Types.EarnProductLandingPageQueryVariables>(EarnProductLandingPageDocument, options);
        }
export type EarnProductLandingPageQueryHookResult = ReturnType<typeof useEarnProductLandingPageQuery>;
export type EarnProductLandingPageLazyQueryHookResult = ReturnType<typeof useEarnProductLandingPageLazyQuery>;
export type EarnProductLandingPageSuspenseQueryHookResult = ReturnType<typeof useEarnProductLandingPageSuspenseQuery>;
export type EarnProductLandingPageQueryResult = Apollo.QueryResult<Types.EarnProductLandingPageQuery, Types.EarnProductLandingPageQueryVariables>;
export const EarnProductLandingPageDetailsDocument = gql`
    query EarnProductLandingPageDetails($id: ID!) {
  node(id: $id) {
    ... on SavingsAccount {
      earnProductLandingPage {
        accountDetailsCard {
          title
          details {
            header
            rows {
              label
              tooltip {
                ...AppTooltip
              }
              value
              valueMasked
              icon {
                ...AppImage
              }
              iconClicked {
                ...AppImage
              }
            }
          }
        }
        disclaimers
      }
    }
  }
}
    ${AppTooltipFragmentDoc}
${AppImageFragmentDoc}`;

/**
 * __useEarnProductLandingPageDetailsQuery__
 *
 * To run a query within a React component, call `useEarnProductLandingPageDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarnProductLandingPageDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarnProductLandingPageDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEarnProductLandingPageDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.EarnProductLandingPageDetailsQuery, Types.EarnProductLandingPageDetailsQueryVariables> & ({ variables: Types.EarnProductLandingPageDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EarnProductLandingPageDetailsQuery, Types.EarnProductLandingPageDetailsQueryVariables>(EarnProductLandingPageDetailsDocument, options);
      }
export function useEarnProductLandingPageDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EarnProductLandingPageDetailsQuery, Types.EarnProductLandingPageDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EarnProductLandingPageDetailsQuery, Types.EarnProductLandingPageDetailsQueryVariables>(EarnProductLandingPageDetailsDocument, options);
        }
export function useEarnProductLandingPageDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EarnProductLandingPageDetailsQuery, Types.EarnProductLandingPageDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EarnProductLandingPageDetailsQuery, Types.EarnProductLandingPageDetailsQueryVariables>(EarnProductLandingPageDetailsDocument, options);
        }
export type EarnProductLandingPageDetailsQueryHookResult = ReturnType<typeof useEarnProductLandingPageDetailsQuery>;
export type EarnProductLandingPageDetailsLazyQueryHookResult = ReturnType<typeof useEarnProductLandingPageDetailsLazyQuery>;
export type EarnProductLandingPageDetailsSuspenseQueryHookResult = ReturnType<typeof useEarnProductLandingPageDetailsSuspenseQuery>;
export type EarnProductLandingPageDetailsQueryResult = Apollo.QueryResult<Types.EarnProductLandingPageDetailsQuery, Types.EarnProductLandingPageDetailsQueryVariables>;
export const FeedbackFormDocument = gql`
    query FeedbackForm {
  viewer {
    id
    feedbackForm {
      showFeedbackForm
    }
  }
}
    `;

/**
 * __useFeedbackFormQuery__
 *
 * To run a query within a React component, call `useFeedbackFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedbackFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedbackFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeedbackFormQuery(baseOptions?: Apollo.QueryHookOptions<Types.FeedbackFormQuery, Types.FeedbackFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FeedbackFormQuery, Types.FeedbackFormQueryVariables>(FeedbackFormDocument, options);
      }
export function useFeedbackFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FeedbackFormQuery, Types.FeedbackFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FeedbackFormQuery, Types.FeedbackFormQueryVariables>(FeedbackFormDocument, options);
        }
export function useFeedbackFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.FeedbackFormQuery, Types.FeedbackFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.FeedbackFormQuery, Types.FeedbackFormQueryVariables>(FeedbackFormDocument, options);
        }
export type FeedbackFormQueryHookResult = ReturnType<typeof useFeedbackFormQuery>;
export type FeedbackFormLazyQueryHookResult = ReturnType<typeof useFeedbackFormLazyQuery>;
export type FeedbackFormSuspenseQueryHookResult = ReturnType<typeof useFeedbackFormSuspenseQuery>;
export type FeedbackFormQueryResult = Apollo.QueryResult<Types.FeedbackFormQuery, Types.FeedbackFormQueryVariables>;
export const HomeAnnouncementsDocument = gql`
    query HomeAnnouncements {
  viewer {
    announcements {
      ... on Announcements {
        forHome {
          ...Announcement
        }
        forHomeModal {
          ...Announcement
        }
      }
    }
  }
}
    ${AnnouncementFragmentDoc}`;

/**
 * __useHomeAnnouncementsQuery__
 *
 * To run a query within a React component, call `useHomeAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeAnnouncementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeAnnouncementsQuery(baseOptions?: Apollo.QueryHookOptions<Types.HomeAnnouncementsQuery, Types.HomeAnnouncementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HomeAnnouncementsQuery, Types.HomeAnnouncementsQueryVariables>(HomeAnnouncementsDocument, options);
      }
export function useHomeAnnouncementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HomeAnnouncementsQuery, Types.HomeAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HomeAnnouncementsQuery, Types.HomeAnnouncementsQueryVariables>(HomeAnnouncementsDocument, options);
        }
export function useHomeAnnouncementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.HomeAnnouncementsQuery, Types.HomeAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.HomeAnnouncementsQuery, Types.HomeAnnouncementsQueryVariables>(HomeAnnouncementsDocument, options);
        }
export type HomeAnnouncementsQueryHookResult = ReturnType<typeof useHomeAnnouncementsQuery>;
export type HomeAnnouncementsLazyQueryHookResult = ReturnType<typeof useHomeAnnouncementsLazyQuery>;
export type HomeAnnouncementsSuspenseQueryHookResult = ReturnType<typeof useHomeAnnouncementsSuspenseQuery>;
export type HomeAnnouncementsQueryResult = Apollo.QueryResult<Types.HomeAnnouncementsQuery, Types.HomeAnnouncementsQueryVariables>;
export const HomeDisclosuresDocument = gql`
    query HomeDisclosures {
  viewer {
    overview {
      disclosuresFooter {
        linkText
        paragraphs {
          ...RichText
        }
      }
    }
  }
}
    ${RichTextFragmentDoc}`;

/**
 * __useHomeDisclosuresQuery__
 *
 * To run a query within a React component, call `useHomeDisclosuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeDisclosuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeDisclosuresQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeDisclosuresQuery(baseOptions?: Apollo.QueryHookOptions<Types.HomeDisclosuresQuery, Types.HomeDisclosuresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HomeDisclosuresQuery, Types.HomeDisclosuresQueryVariables>(HomeDisclosuresDocument, options);
      }
export function useHomeDisclosuresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HomeDisclosuresQuery, Types.HomeDisclosuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HomeDisclosuresQuery, Types.HomeDisclosuresQueryVariables>(HomeDisclosuresDocument, options);
        }
export function useHomeDisclosuresSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.HomeDisclosuresQuery, Types.HomeDisclosuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.HomeDisclosuresQuery, Types.HomeDisclosuresQueryVariables>(HomeDisclosuresDocument, options);
        }
export type HomeDisclosuresQueryHookResult = ReturnType<typeof useHomeDisclosuresQuery>;
export type HomeDisclosuresLazyQueryHookResult = ReturnType<typeof useHomeDisclosuresLazyQuery>;
export type HomeDisclosuresSuspenseQueryHookResult = ReturnType<typeof useHomeDisclosuresSuspenseQuery>;
export type HomeDisclosuresQueryResult = Apollo.QueryResult<Types.HomeDisclosuresQuery, Types.HomeDisclosuresQueryVariables>;
export const HomePageDocument = gql`
    query HomePage($first: Int!, $after: String) {
  viewer {
    netWorth {
      id
      disclosuresTooltip {
        ...AppTooltip
      }
    }
    save {
      savings {
        hasSavingsAccounts
      }
    }
    profile {
      ... on Profile {
        primary {
          firstName
        }
      }
    }
    overview {
      balances {
        ...OverviewBalances
      }
      cards {
        ...OverviewCardGroups
      }
      homeTilesSections {
        ...HomeTilesSectionConnection
      }
      showMoveMoney
      promotions {
        ...HomePagePromotions
      }
    }
    transfers {
      activeFundingSource {
        id
        externalName
      }
      allInstances(first: $first, after: $after) {
        ...TransferInstances
      }
    }
  }
}
    ${AppTooltipFragmentDoc}
${OverviewBalancesFragmentDoc}
${OverviewCardGroupsFragmentDoc}
${HomeTilesSectionConnectionFragmentDoc}
${HomePagePromotionsFragmentDoc}
${TransferInstancesFragmentDoc}`;

/**
 * __useHomePageQuery__
 *
 * To run a query within a React component, call `useHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useHomePageQuery(baseOptions: Apollo.QueryHookOptions<Types.HomePageQuery, Types.HomePageQueryVariables> & ({ variables: Types.HomePageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HomePageQuery, Types.HomePageQueryVariables>(HomePageDocument, options);
      }
export function useHomePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HomePageQuery, Types.HomePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HomePageQuery, Types.HomePageQueryVariables>(HomePageDocument, options);
        }
export function useHomePageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.HomePageQuery, Types.HomePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.HomePageQuery, Types.HomePageQueryVariables>(HomePageDocument, options);
        }
export type HomePageQueryHookResult = ReturnType<typeof useHomePageQuery>;
export type HomePageLazyQueryHookResult = ReturnType<typeof useHomePageLazyQuery>;
export type HomePageSuspenseQueryHookResult = ReturnType<typeof useHomePageSuspenseQuery>;
export type HomePageQueryResult = Apollo.QueryResult<Types.HomePageQuery, Types.HomePageQueryVariables>;
export const HomePagePromotionsQueryDocument = gql`
    query HomePagePromotionsQuery {
  viewer {
    overview {
      promotions {
        ...HomePagePromotions
      }
    }
  }
}
    ${HomePagePromotionsFragmentDoc}`;

/**
 * __useHomePagePromotionsQueryQuery__
 *
 * To run a query within a React component, call `useHomePagePromotionsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePagePromotionsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePagePromotionsQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomePagePromotionsQueryQuery(baseOptions?: Apollo.QueryHookOptions<Types.HomePagePromotionsQueryQuery, Types.HomePagePromotionsQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HomePagePromotionsQueryQuery, Types.HomePagePromotionsQueryQueryVariables>(HomePagePromotionsQueryDocument, options);
      }
export function useHomePagePromotionsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HomePagePromotionsQueryQuery, Types.HomePagePromotionsQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HomePagePromotionsQueryQuery, Types.HomePagePromotionsQueryQueryVariables>(HomePagePromotionsQueryDocument, options);
        }
export function useHomePagePromotionsQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.HomePagePromotionsQueryQuery, Types.HomePagePromotionsQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.HomePagePromotionsQueryQuery, Types.HomePagePromotionsQueryQueryVariables>(HomePagePromotionsQueryDocument, options);
        }
export type HomePagePromotionsQueryQueryHookResult = ReturnType<typeof useHomePagePromotionsQueryQuery>;
export type HomePagePromotionsQueryLazyQueryHookResult = ReturnType<typeof useHomePagePromotionsQueryLazyQuery>;
export type HomePagePromotionsQuerySuspenseQueryHookResult = ReturnType<typeof useHomePagePromotionsQuerySuspenseQuery>;
export type HomePagePromotionsQueryQueryResult = Apollo.QueryResult<Types.HomePagePromotionsQueryQuery, Types.HomePagePromotionsQueryQueryVariables>;
export const LiveNetWorthDocument = gql`
    query LiveNetWorth {
  viewer {
    netWorth {
      id
      liveNetWorth {
        ...ChartableSliceDatum
      }
    }
  }
}
    ${ChartableSliceDatumFragmentDoc}`;

/**
 * __useLiveNetWorthQuery__
 *
 * To run a query within a React component, call `useLiveNetWorthQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveNetWorthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveNetWorthQuery({
 *   variables: {
 *   },
 * });
 */
export function useLiveNetWorthQuery(baseOptions?: Apollo.QueryHookOptions<Types.LiveNetWorthQuery, Types.LiveNetWorthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LiveNetWorthQuery, Types.LiveNetWorthQueryVariables>(LiveNetWorthDocument, options);
      }
export function useLiveNetWorthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LiveNetWorthQuery, Types.LiveNetWorthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LiveNetWorthQuery, Types.LiveNetWorthQueryVariables>(LiveNetWorthDocument, options);
        }
export function useLiveNetWorthSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LiveNetWorthQuery, Types.LiveNetWorthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LiveNetWorthQuery, Types.LiveNetWorthQueryVariables>(LiveNetWorthDocument, options);
        }
export type LiveNetWorthQueryHookResult = ReturnType<typeof useLiveNetWorthQuery>;
export type LiveNetWorthLazyQueryHookResult = ReturnType<typeof useLiveNetWorthLazyQuery>;
export type LiveNetWorthSuspenseQueryHookResult = ReturnType<typeof useLiveNetWorthSuspenseQuery>;
export type LiveNetWorthQueryResult = Apollo.QueryResult<Types.LiveNetWorthQuery, Types.LiveNetWorthQueryVariables>;
export const GetInitialFundingWizardPreloadDocument = gql`
    query GetInitialFundingWizardPreload($accountRegistration: AccountRegistrationEnum!) {
  viewer {
    transfers {
      initialFunding {
        initialFundingScreens {
          initialFundingOverviewScreen(accountRegistration: $accountRegistration) {
            title
            primaryCtaButton {
              ...AppButton
            }
            secondaryCtaButton {
              ...AppButton
            }
            content {
              icon {
                ...AppImage
              }
              title
              description {
                ...RichText
              }
            }
            skipCta {
              label
              analyticsEvent {
                ...AnalyticsEvent
              }
            }
            pageViewAnalyticsEvent {
              ...AnalyticsEvent
            }
          }
        }
      }
    }
  }
}
    ${AppButtonFragmentDoc}
${AppImageFragmentDoc}
${RichTextFragmentDoc}
${AnalyticsEventFragmentDoc}`;

/**
 * __useGetInitialFundingWizardPreloadQuery__
 *
 * To run a query within a React component, call `useGetInitialFundingWizardPreloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInitialFundingWizardPreloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInitialFundingWizardPreloadQuery({
 *   variables: {
 *      accountRegistration: // value for 'accountRegistration'
 *   },
 * });
 */
export function useGetInitialFundingWizardPreloadQuery(baseOptions: Apollo.QueryHookOptions<Types.GetInitialFundingWizardPreloadQuery, Types.GetInitialFundingWizardPreloadQueryVariables> & ({ variables: Types.GetInitialFundingWizardPreloadQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetInitialFundingWizardPreloadQuery, Types.GetInitialFundingWizardPreloadQueryVariables>(GetInitialFundingWizardPreloadDocument, options);
      }
export function useGetInitialFundingWizardPreloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInitialFundingWizardPreloadQuery, Types.GetInitialFundingWizardPreloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetInitialFundingWizardPreloadQuery, Types.GetInitialFundingWizardPreloadQueryVariables>(GetInitialFundingWizardPreloadDocument, options);
        }
export function useGetInitialFundingWizardPreloadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetInitialFundingWizardPreloadQuery, Types.GetInitialFundingWizardPreloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetInitialFundingWizardPreloadQuery, Types.GetInitialFundingWizardPreloadQueryVariables>(GetInitialFundingWizardPreloadDocument, options);
        }
export type GetInitialFundingWizardPreloadQueryHookResult = ReturnType<typeof useGetInitialFundingWizardPreloadQuery>;
export type GetInitialFundingWizardPreloadLazyQueryHookResult = ReturnType<typeof useGetInitialFundingWizardPreloadLazyQuery>;
export type GetInitialFundingWizardPreloadSuspenseQueryHookResult = ReturnType<typeof useGetInitialFundingWizardPreloadSuspenseQuery>;
export type GetInitialFundingWizardPreloadQueryResult = Apollo.QueryResult<Types.GetInitialFundingWizardPreloadQuery, Types.GetInitialFundingWizardPreloadQueryVariables>;
export const AccountCashBalanceControlDocument = gql`
    query AccountCashBalanceControl($id: ID!) {
  node(id: $id) {
    id
    ...AccountCashBalanceNode
  }
}
    ${AccountCashBalanceNodeFragmentDoc}`;

/**
 * __useAccountCashBalanceControlQuery__
 *
 * To run a query within a React component, call `useAccountCashBalanceControlQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountCashBalanceControlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountCashBalanceControlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountCashBalanceControlQuery(baseOptions: Apollo.QueryHookOptions<Types.AccountCashBalanceControlQuery, Types.AccountCashBalanceControlQueryVariables> & ({ variables: Types.AccountCashBalanceControlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AccountCashBalanceControlQuery, Types.AccountCashBalanceControlQueryVariables>(AccountCashBalanceControlDocument, options);
      }
export function useAccountCashBalanceControlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AccountCashBalanceControlQuery, Types.AccountCashBalanceControlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AccountCashBalanceControlQuery, Types.AccountCashBalanceControlQueryVariables>(AccountCashBalanceControlDocument, options);
        }
export function useAccountCashBalanceControlSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AccountCashBalanceControlQuery, Types.AccountCashBalanceControlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AccountCashBalanceControlQuery, Types.AccountCashBalanceControlQueryVariables>(AccountCashBalanceControlDocument, options);
        }
export type AccountCashBalanceControlQueryHookResult = ReturnType<typeof useAccountCashBalanceControlQuery>;
export type AccountCashBalanceControlLazyQueryHookResult = ReturnType<typeof useAccountCashBalanceControlLazyQuery>;
export type AccountCashBalanceControlSuspenseQueryHookResult = ReturnType<typeof useAccountCashBalanceControlSuspenseQuery>;
export type AccountCashBalanceControlQueryResult = Apollo.QueryResult<Types.AccountCashBalanceControlQuery, Types.AccountCashBalanceControlQueryVariables>;
export const AccountConfigurationDividendsPageDocument = gql`
    query AccountConfigurationDividendsPage($accountId: ID!) {
  account: node(id: $accountId) {
    id
    ... on Account {
      ...AccountConfiguration
    }
  }
}
    ${AccountConfigurationFragmentDoc}`;

/**
 * __useAccountConfigurationDividendsPageQuery__
 *
 * To run a query within a React component, call `useAccountConfigurationDividendsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountConfigurationDividendsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountConfigurationDividendsPageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountConfigurationDividendsPageQuery(baseOptions: Apollo.QueryHookOptions<Types.AccountConfigurationDividendsPageQuery, Types.AccountConfigurationDividendsPageQueryVariables> & ({ variables: Types.AccountConfigurationDividendsPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AccountConfigurationDividendsPageQuery, Types.AccountConfigurationDividendsPageQueryVariables>(AccountConfigurationDividendsPageDocument, options);
      }
export function useAccountConfigurationDividendsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AccountConfigurationDividendsPageQuery, Types.AccountConfigurationDividendsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AccountConfigurationDividendsPageQuery, Types.AccountConfigurationDividendsPageQueryVariables>(AccountConfigurationDividendsPageDocument, options);
        }
export function useAccountConfigurationDividendsPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AccountConfigurationDividendsPageQuery, Types.AccountConfigurationDividendsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AccountConfigurationDividendsPageQuery, Types.AccountConfigurationDividendsPageQueryVariables>(AccountConfigurationDividendsPageDocument, options);
        }
export type AccountConfigurationDividendsPageQueryHookResult = ReturnType<typeof useAccountConfigurationDividendsPageQuery>;
export type AccountConfigurationDividendsPageLazyQueryHookResult = ReturnType<typeof useAccountConfigurationDividendsPageLazyQuery>;
export type AccountConfigurationDividendsPageSuspenseQueryHookResult = ReturnType<typeof useAccountConfigurationDividendsPageSuspenseQuery>;
export type AccountConfigurationDividendsPageQueryResult = Apollo.QueryResult<Types.AccountConfigurationDividendsPageQuery, Types.AccountConfigurationDividendsPageQueryVariables>;
export const AccountListFilteredByPaymentEligibilityDocument = gql`
    query AccountListFilteredByPaymentEligibility($first: Int, $filterForPaymentsEligibility: Boolean) {
  viewer {
    accounts(
      first: $first
      filterForPaymentsEligibility: $filterForPaymentsEligibility
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useAccountListFilteredByPaymentEligibilityQuery__
 *
 * To run a query within a React component, call `useAccountListFilteredByPaymentEligibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountListFilteredByPaymentEligibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountListFilteredByPaymentEligibilityQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filterForPaymentsEligibility: // value for 'filterForPaymentsEligibility'
 *   },
 * });
 */
export function useAccountListFilteredByPaymentEligibilityQuery(baseOptions?: Apollo.QueryHookOptions<Types.AccountListFilteredByPaymentEligibilityQuery, Types.AccountListFilteredByPaymentEligibilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AccountListFilteredByPaymentEligibilityQuery, Types.AccountListFilteredByPaymentEligibilityQueryVariables>(AccountListFilteredByPaymentEligibilityDocument, options);
      }
export function useAccountListFilteredByPaymentEligibilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AccountListFilteredByPaymentEligibilityQuery, Types.AccountListFilteredByPaymentEligibilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AccountListFilteredByPaymentEligibilityQuery, Types.AccountListFilteredByPaymentEligibilityQueryVariables>(AccountListFilteredByPaymentEligibilityDocument, options);
        }
export function useAccountListFilteredByPaymentEligibilitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AccountListFilteredByPaymentEligibilityQuery, Types.AccountListFilteredByPaymentEligibilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AccountListFilteredByPaymentEligibilityQuery, Types.AccountListFilteredByPaymentEligibilityQueryVariables>(AccountListFilteredByPaymentEligibilityDocument, options);
        }
export type AccountListFilteredByPaymentEligibilityQueryHookResult = ReturnType<typeof useAccountListFilteredByPaymentEligibilityQuery>;
export type AccountListFilteredByPaymentEligibilityLazyQueryHookResult = ReturnType<typeof useAccountListFilteredByPaymentEligibilityLazyQuery>;
export type AccountListFilteredByPaymentEligibilitySuspenseQueryHookResult = ReturnType<typeof useAccountListFilteredByPaymentEligibilitySuspenseQuery>;
export type AccountListFilteredByPaymentEligibilityQueryResult = Apollo.QueryResult<Types.AccountListFilteredByPaymentEligibilityQuery, Types.AccountListFilteredByPaymentEligibilityQueryVariables>;
export const AccountRebalancePageDocument = gql`
    query AccountRebalancePage($accountId: ID!) {
  account: node(id: $accountId) {
    ... on Account {
      id
      preferredTradeWindow
      trading {
        nextWindowForAccount
      }
    }
  }
}
    `;

/**
 * __useAccountRebalancePageQuery__
 *
 * To run a query within a React component, call `useAccountRebalancePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountRebalancePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountRebalancePageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountRebalancePageQuery(baseOptions: Apollo.QueryHookOptions<Types.AccountRebalancePageQuery, Types.AccountRebalancePageQueryVariables> & ({ variables: Types.AccountRebalancePageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AccountRebalancePageQuery, Types.AccountRebalancePageQueryVariables>(AccountRebalancePageDocument, options);
      }
export function useAccountRebalancePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AccountRebalancePageQuery, Types.AccountRebalancePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AccountRebalancePageQuery, Types.AccountRebalancePageQueryVariables>(AccountRebalancePageDocument, options);
        }
export function useAccountRebalancePageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AccountRebalancePageQuery, Types.AccountRebalancePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AccountRebalancePageQuery, Types.AccountRebalancePageQueryVariables>(AccountRebalancePageDocument, options);
        }
export type AccountRebalancePageQueryHookResult = ReturnType<typeof useAccountRebalancePageQuery>;
export type AccountRebalancePageLazyQueryHookResult = ReturnType<typeof useAccountRebalancePageLazyQuery>;
export type AccountRebalancePageSuspenseQueryHookResult = ReturnType<typeof useAccountRebalancePageSuspenseQuery>;
export type AccountRebalancePageQueryResult = Apollo.QueryResult<Types.AccountRebalancePageQuery, Types.AccountRebalancePageQueryVariables>;
export const AccountTaxLotsDocument = gql`
    query AccountTaxLots($id: ID!, $lotType: LotTypeEnum!, $first: Int, $after: String) {
  node(id: $id) {
    ... on Account {
      number
      taxLots(lotType: $lotType, first: $first, after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            symbol
            cusip
            acquisitionDate
            quantity
            costBasis
            shortLongTermHolding
            unrealizedGainLoss
            closeDate
            shortTermRealizedGainLoss
            longTermRealizedGainLoss
            washSaleIndicator
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAccountTaxLotsQuery__
 *
 * To run a query within a React component, call `useAccountTaxLotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTaxLotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTaxLotsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      lotType: // value for 'lotType'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAccountTaxLotsQuery(baseOptions: Apollo.QueryHookOptions<Types.AccountTaxLotsQuery, Types.AccountTaxLotsQueryVariables> & ({ variables: Types.AccountTaxLotsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AccountTaxLotsQuery, Types.AccountTaxLotsQueryVariables>(AccountTaxLotsDocument, options);
      }
export function useAccountTaxLotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AccountTaxLotsQuery, Types.AccountTaxLotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AccountTaxLotsQuery, Types.AccountTaxLotsQueryVariables>(AccountTaxLotsDocument, options);
        }
export function useAccountTaxLotsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AccountTaxLotsQuery, Types.AccountTaxLotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AccountTaxLotsQuery, Types.AccountTaxLotsQueryVariables>(AccountTaxLotsDocument, options);
        }
export type AccountTaxLotsQueryHookResult = ReturnType<typeof useAccountTaxLotsQuery>;
export type AccountTaxLotsLazyQueryHookResult = ReturnType<typeof useAccountTaxLotsLazyQuery>;
export type AccountTaxLotsSuspenseQueryHookResult = ReturnType<typeof useAccountTaxLotsSuspenseQuery>;
export type AccountTaxLotsQueryResult = Apollo.QueryResult<Types.AccountTaxLotsQuery, Types.AccountTaxLotsQueryVariables>;
export const AccountTradingStatusDocument = gql`
    query AccountTradingStatus($accountId: ID!) {
  node(id: $accountId) {
    ... on Account {
      __typename
      id
      isTradingPaused
      isCryptoAccount
      preferredTradeWindow
      hasPreferredTradeWindowAccess
      trading {
        ...TradingStatus
      }
      estimatedTrading {
        ...EstimatedTrades
      }
      tradingPause {
        ...TradingPause
      }
    }
  }
  viewer {
    transfers {
      scheduledTransferRules(accountId: $accountId, enabledOnly: true) {
        id
      }
    }
    user {
      isPrimaryEmailVerified
    }
  }
}
    ${TradingStatusFragmentDoc}
${EstimatedTradesFragmentDoc}
${TradingPauseFragmentDoc}`;

/**
 * __useAccountTradingStatusQuery__
 *
 * To run a query within a React component, call `useAccountTradingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTradingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTradingStatusQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountTradingStatusQuery(baseOptions: Apollo.QueryHookOptions<Types.AccountTradingStatusQuery, Types.AccountTradingStatusQueryVariables> & ({ variables: Types.AccountTradingStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AccountTradingStatusQuery, Types.AccountTradingStatusQueryVariables>(AccountTradingStatusDocument, options);
      }
export function useAccountTradingStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AccountTradingStatusQuery, Types.AccountTradingStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AccountTradingStatusQuery, Types.AccountTradingStatusQueryVariables>(AccountTradingStatusDocument, options);
        }
export function useAccountTradingStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AccountTradingStatusQuery, Types.AccountTradingStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AccountTradingStatusQuery, Types.AccountTradingStatusQueryVariables>(AccountTradingStatusDocument, options);
        }
export type AccountTradingStatusQueryHookResult = ReturnType<typeof useAccountTradingStatusQuery>;
export type AccountTradingStatusLazyQueryHookResult = ReturnType<typeof useAccountTradingStatusLazyQuery>;
export type AccountTradingStatusSuspenseQueryHookResult = ReturnType<typeof useAccountTradingStatusSuspenseQuery>;
export type AccountTradingStatusQueryResult = Apollo.QueryResult<Types.AccountTradingStatusQuery, Types.AccountTradingStatusQueryVariables>;
export const BuyingPowerOverviewDocument = gql`
    query BuyingPowerOverview($id: ID!) {
  node(id: $id) {
    ... on PortfolioSlice {
      buyingPower {
        overview {
          header
          details {
            ...RichText
          }
          marginPromotionCard {
            ...AppCard
          }
          breakdown {
            header
            items {
              ...MarginBreakdownDetail
            }
            total {
              ...MarginBreakdownDetail
            }
            footer {
              ...RichText
            }
          }
          link {
            ...AppLink
          }
          marginRisk {
            ...RichText
          }
          primaryCta {
            ...AppButton
          }
          secondaryCta {
            ...AppLink
          }
          disclosures {
            ...AppDisclosureLink
          }
          displayAnalyticsEvent {
            ...AnalyticsEvent
          }
        }
      }
    }
  }
}
    ${RichTextFragmentDoc}
${AppCardFragmentDoc}
${MarginBreakdownDetailFragmentDoc}
${AppLinkFragmentDoc}
${AppButtonFragmentDoc}
${AppDisclosureLinkFragmentDoc}
${AnalyticsEventFragmentDoc}`;

/**
 * __useBuyingPowerOverviewQuery__
 *
 * To run a query within a React component, call `useBuyingPowerOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyingPowerOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyingPowerOverviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBuyingPowerOverviewQuery(baseOptions: Apollo.QueryHookOptions<Types.BuyingPowerOverviewQuery, Types.BuyingPowerOverviewQueryVariables> & ({ variables: Types.BuyingPowerOverviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyingPowerOverviewQuery, Types.BuyingPowerOverviewQueryVariables>(BuyingPowerOverviewDocument, options);
      }
export function useBuyingPowerOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyingPowerOverviewQuery, Types.BuyingPowerOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyingPowerOverviewQuery, Types.BuyingPowerOverviewQueryVariables>(BuyingPowerOverviewDocument, options);
        }
export function useBuyingPowerOverviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BuyingPowerOverviewQuery, Types.BuyingPowerOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BuyingPowerOverviewQuery, Types.BuyingPowerOverviewQueryVariables>(BuyingPowerOverviewDocument, options);
        }
export type BuyingPowerOverviewQueryHookResult = ReturnType<typeof useBuyingPowerOverviewQuery>;
export type BuyingPowerOverviewLazyQueryHookResult = ReturnType<typeof useBuyingPowerOverviewLazyQuery>;
export type BuyingPowerOverviewSuspenseQueryHookResult = ReturnType<typeof useBuyingPowerOverviewSuspenseQuery>;
export type BuyingPowerOverviewQueryResult = Apollo.QueryResult<Types.BuyingPowerOverviewQuery, Types.BuyingPowerOverviewQueryVariables>;
export const ConfirmationDialogContainerDocument = gql`
    query ConfirmationDialogContainer($isCrypto: Boolean, $pieId: ID!, $newSerializedPieTree: String!, $unmanagedHoldingIdsForMergedSlices: [ID!]) {
  node(id: $pieId) {
    ...ConfirmationDialogPie
  }
  viewer {
    invest {
      pieChangesConfirmation(
        isCrypto: $isCrypto
        newSerializedPieTree: $newSerializedPieTree
        unmanagedHoldingIdsForMergedSlices: $unmanagedHoldingIdsForMergedSlices
      ) {
        ...PieChangesConfirmation
      }
    }
  }
}
    ${ConfirmationDialogPieFragmentDoc}
${PieChangesConfirmationFragmentDoc}`;

/**
 * __useConfirmationDialogContainerQuery__
 *
 * To run a query within a React component, call `useConfirmationDialogContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmationDialogContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmationDialogContainerQuery({
 *   variables: {
 *      isCrypto: // value for 'isCrypto'
 *      pieId: // value for 'pieId'
 *      newSerializedPieTree: // value for 'newSerializedPieTree'
 *      unmanagedHoldingIdsForMergedSlices: // value for 'unmanagedHoldingIdsForMergedSlices'
 *   },
 * });
 */
export function useConfirmationDialogContainerQuery(baseOptions: Apollo.QueryHookOptions<Types.ConfirmationDialogContainerQuery, Types.ConfirmationDialogContainerQueryVariables> & ({ variables: Types.ConfirmationDialogContainerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConfirmationDialogContainerQuery, Types.ConfirmationDialogContainerQueryVariables>(ConfirmationDialogContainerDocument, options);
      }
export function useConfirmationDialogContainerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConfirmationDialogContainerQuery, Types.ConfirmationDialogContainerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConfirmationDialogContainerQuery, Types.ConfirmationDialogContainerQueryVariables>(ConfirmationDialogContainerDocument, options);
        }
export function useConfirmationDialogContainerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ConfirmationDialogContainerQuery, Types.ConfirmationDialogContainerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ConfirmationDialogContainerQuery, Types.ConfirmationDialogContainerQueryVariables>(ConfirmationDialogContainerDocument, options);
        }
export type ConfirmationDialogContainerQueryHookResult = ReturnType<typeof useConfirmationDialogContainerQuery>;
export type ConfirmationDialogContainerLazyQueryHookResult = ReturnType<typeof useConfirmationDialogContainerLazyQuery>;
export type ConfirmationDialogContainerSuspenseQueryHookResult = ReturnType<typeof useConfirmationDialogContainerSuspenseQuery>;
export type ConfirmationDialogContainerQueryResult = Apollo.QueryResult<Types.ConfirmationDialogContainerQuery, Types.ConfirmationDialogContainerQueryVariables>;
export const CustodialBeneficiaryInfoDocument = gql`
    query CustodialBeneficiaryInfo($accountId: ID!) {
  node(id: $accountId) {
    id
    ...CustodialAccountBeneficiary
  }
}
    ${CustodialAccountBeneficiaryFragmentDoc}`;

/**
 * __useCustodialBeneficiaryInfoQuery__
 *
 * To run a query within a React component, call `useCustodialBeneficiaryInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustodialBeneficiaryInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustodialBeneficiaryInfoQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useCustodialBeneficiaryInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.CustodialBeneficiaryInfoQuery, Types.CustodialBeneficiaryInfoQueryVariables> & ({ variables: Types.CustodialBeneficiaryInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CustodialBeneficiaryInfoQuery, Types.CustodialBeneficiaryInfoQueryVariables>(CustodialBeneficiaryInfoDocument, options);
      }
export function useCustodialBeneficiaryInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CustodialBeneficiaryInfoQuery, Types.CustodialBeneficiaryInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CustodialBeneficiaryInfoQuery, Types.CustodialBeneficiaryInfoQueryVariables>(CustodialBeneficiaryInfoDocument, options);
        }
export function useCustodialBeneficiaryInfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CustodialBeneficiaryInfoQuery, Types.CustodialBeneficiaryInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CustodialBeneficiaryInfoQuery, Types.CustodialBeneficiaryInfoQueryVariables>(CustodialBeneficiaryInfoDocument, options);
        }
export type CustodialBeneficiaryInfoQueryHookResult = ReturnType<typeof useCustodialBeneficiaryInfoQuery>;
export type CustodialBeneficiaryInfoLazyQueryHookResult = ReturnType<typeof useCustodialBeneficiaryInfoLazyQuery>;
export type CustodialBeneficiaryInfoSuspenseQueryHookResult = ReturnType<typeof useCustodialBeneficiaryInfoSuspenseQuery>;
export type CustodialBeneficiaryInfoQueryResult = Apollo.QueryResult<Types.CustodialBeneficiaryInfoQuery, Types.CustodialBeneficiaryInfoQueryVariables>;
export const DashboardHeaderDocument = gql`
    query DashboardHeader {
  viewer {
    id
    announcements {
      ...HeaderAnnouncements
    }
    borrow {
      id
      hasBorrowAccountWithOpenMaintenanceCall
      hasLatePersonalLoan
      hasFailedPersonalLoanDisbursement
    }
    user {
      id
      correlationKey
      username
      referrals {
        isEligible
      }
    }
    profile {
      primary {
        fullName
        firstName
      }
    }
  }
}
    ${HeaderAnnouncementsFragmentDoc}`;

/**
 * __useDashboardHeaderQuery__
 *
 * To run a query within a React component, call `useDashboardHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardHeaderQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardHeaderQuery(baseOptions?: Apollo.QueryHookOptions<Types.DashboardHeaderQuery, Types.DashboardHeaderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DashboardHeaderQuery, Types.DashboardHeaderQueryVariables>(DashboardHeaderDocument, options);
      }
export function useDashboardHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DashboardHeaderQuery, Types.DashboardHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DashboardHeaderQuery, Types.DashboardHeaderQueryVariables>(DashboardHeaderDocument, options);
        }
export function useDashboardHeaderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.DashboardHeaderQuery, Types.DashboardHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DashboardHeaderQuery, Types.DashboardHeaderQueryVariables>(DashboardHeaderDocument, options);
        }
export type DashboardHeaderQueryHookResult = ReturnType<typeof useDashboardHeaderQuery>;
export type DashboardHeaderLazyQueryHookResult = ReturnType<typeof useDashboardHeaderLazyQuery>;
export type DashboardHeaderSuspenseQueryHookResult = ReturnType<typeof useDashboardHeaderSuspenseQuery>;
export type DashboardHeaderQueryResult = Apollo.QueryResult<Types.DashboardHeaderQuery, Types.DashboardHeaderQueryVariables>;
export const HeaderAnnouncementRefetchDocument = gql`
    query HeaderAnnouncementRefetch {
  viewer {
    id
    announcements {
      hasNew
      forTransfers {
        id
      }
      forMoveMoney {
        id
      }
      forInvest {
        id
      }
      forBorrow {
        id
      }
      forPersonalLoans {
        id
      }
      forReferrals {
        id
      }
      forDocuments {
        id
      }
    }
  }
}
    `;

/**
 * __useHeaderAnnouncementRefetchQuery__
 *
 * To run a query within a React component, call `useHeaderAnnouncementRefetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeaderAnnouncementRefetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeaderAnnouncementRefetchQuery({
 *   variables: {
 *   },
 * });
 */
export function useHeaderAnnouncementRefetchQuery(baseOptions?: Apollo.QueryHookOptions<Types.HeaderAnnouncementRefetchQuery, Types.HeaderAnnouncementRefetchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HeaderAnnouncementRefetchQuery, Types.HeaderAnnouncementRefetchQueryVariables>(HeaderAnnouncementRefetchDocument, options);
      }
export function useHeaderAnnouncementRefetchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HeaderAnnouncementRefetchQuery, Types.HeaderAnnouncementRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HeaderAnnouncementRefetchQuery, Types.HeaderAnnouncementRefetchQueryVariables>(HeaderAnnouncementRefetchDocument, options);
        }
export function useHeaderAnnouncementRefetchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.HeaderAnnouncementRefetchQuery, Types.HeaderAnnouncementRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.HeaderAnnouncementRefetchQuery, Types.HeaderAnnouncementRefetchQueryVariables>(HeaderAnnouncementRefetchDocument, options);
        }
export type HeaderAnnouncementRefetchQueryHookResult = ReturnType<typeof useHeaderAnnouncementRefetchQuery>;
export type HeaderAnnouncementRefetchLazyQueryHookResult = ReturnType<typeof useHeaderAnnouncementRefetchLazyQuery>;
export type HeaderAnnouncementRefetchSuspenseQueryHookResult = ReturnType<typeof useHeaderAnnouncementRefetchSuspenseQuery>;
export type HeaderAnnouncementRefetchQueryResult = Apollo.QueryResult<Types.HeaderAnnouncementRefetchQuery, Types.HeaderAnnouncementRefetchQueryVariables>;
export const EarnedDividendsDocument = gql`
    query EarnedDividends($portfolioSliceId: ID!, $period: PortfolioSlicePerformancePeriodEnum!) {
  node(id: $portfolioSliceId) {
    ...SliceEarnedDividends
  }
}
    ${SliceEarnedDividendsFragmentDoc}`;

/**
 * __useEarnedDividendsQuery__
 *
 * To run a query within a React component, call `useEarnedDividendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarnedDividendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarnedDividendsQuery({
 *   variables: {
 *      portfolioSliceId: // value for 'portfolioSliceId'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useEarnedDividendsQuery(baseOptions: Apollo.QueryHookOptions<Types.EarnedDividendsQuery, Types.EarnedDividendsQueryVariables> & ({ variables: Types.EarnedDividendsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EarnedDividendsQuery, Types.EarnedDividendsQueryVariables>(EarnedDividendsDocument, options);
      }
export function useEarnedDividendsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EarnedDividendsQuery, Types.EarnedDividendsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EarnedDividendsQuery, Types.EarnedDividendsQueryVariables>(EarnedDividendsDocument, options);
        }
export function useEarnedDividendsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EarnedDividendsQuery, Types.EarnedDividendsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EarnedDividendsQuery, Types.EarnedDividendsQueryVariables>(EarnedDividendsDocument, options);
        }
export type EarnedDividendsQueryHookResult = ReturnType<typeof useEarnedDividendsQuery>;
export type EarnedDividendsLazyQueryHookResult = ReturnType<typeof useEarnedDividendsLazyQuery>;
export type EarnedDividendsSuspenseQueryHookResult = ReturnType<typeof useEarnedDividendsSuspenseQuery>;
export type EarnedDividendsQueryResult = Apollo.QueryResult<Types.EarnedDividendsQuery, Types.EarnedDividendsQueryVariables>;
export const GetEstimatedBuysDocument = gql`
    query GetEstimatedBuys($accountId: ID!, $first: Int!, $after: String) {
  account: node(id: $accountId) {
    id
    ...EstimatedBuys
  }
}
    ${EstimatedBuysFragmentDoc}`;

/**
 * __useGetEstimatedBuysQuery__
 *
 * To run a query within a React component, call `useGetEstimatedBuysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstimatedBuysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstimatedBuysQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetEstimatedBuysQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEstimatedBuysQuery, Types.GetEstimatedBuysQueryVariables> & ({ variables: Types.GetEstimatedBuysQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEstimatedBuysQuery, Types.GetEstimatedBuysQueryVariables>(GetEstimatedBuysDocument, options);
      }
export function useGetEstimatedBuysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEstimatedBuysQuery, Types.GetEstimatedBuysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEstimatedBuysQuery, Types.GetEstimatedBuysQueryVariables>(GetEstimatedBuysDocument, options);
        }
export function useGetEstimatedBuysSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEstimatedBuysQuery, Types.GetEstimatedBuysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEstimatedBuysQuery, Types.GetEstimatedBuysQueryVariables>(GetEstimatedBuysDocument, options);
        }
export type GetEstimatedBuysQueryHookResult = ReturnType<typeof useGetEstimatedBuysQuery>;
export type GetEstimatedBuysLazyQueryHookResult = ReturnType<typeof useGetEstimatedBuysLazyQuery>;
export type GetEstimatedBuysSuspenseQueryHookResult = ReturnType<typeof useGetEstimatedBuysSuspenseQuery>;
export type GetEstimatedBuysQueryResult = Apollo.QueryResult<Types.GetEstimatedBuysQuery, Types.GetEstimatedBuysQueryVariables>;
export const GetEstimatedSellsDocument = gql`
    query GetEstimatedSells($accountId: ID!, $first: Int!, $after: String) {
  account: node(id: $accountId) {
    id
    ...EstimatedSells
  }
}
    ${EstimatedSellsFragmentDoc}`;

/**
 * __useGetEstimatedSellsQuery__
 *
 * To run a query within a React component, call `useGetEstimatedSellsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstimatedSellsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstimatedSellsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetEstimatedSellsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEstimatedSellsQuery, Types.GetEstimatedSellsQueryVariables> & ({ variables: Types.GetEstimatedSellsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEstimatedSellsQuery, Types.GetEstimatedSellsQueryVariables>(GetEstimatedSellsDocument, options);
      }
export function useGetEstimatedSellsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEstimatedSellsQuery, Types.GetEstimatedSellsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEstimatedSellsQuery, Types.GetEstimatedSellsQueryVariables>(GetEstimatedSellsDocument, options);
        }
export function useGetEstimatedSellsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEstimatedSellsQuery, Types.GetEstimatedSellsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEstimatedSellsQuery, Types.GetEstimatedSellsQueryVariables>(GetEstimatedSellsDocument, options);
        }
export type GetEstimatedSellsQueryHookResult = ReturnType<typeof useGetEstimatedSellsQuery>;
export type GetEstimatedSellsLazyQueryHookResult = ReturnType<typeof useGetEstimatedSellsLazyQuery>;
export type GetEstimatedSellsSuspenseQueryHookResult = ReturnType<typeof useGetEstimatedSellsSuspenseQuery>;
export type GetEstimatedSellsQueryResult = Apollo.QueryResult<Types.GetEstimatedSellsQuery, Types.GetEstimatedSellsQueryVariables>;
export const GetExcludedBuysDocument = gql`
    query GetExcludedBuys($accountId: ID!, $first: Int!, $after: String) {
  account: node(id: $accountId) {
    id
    ...ExcludedBuys
  }
}
    ${ExcludedBuysFragmentDoc}`;

/**
 * __useGetExcludedBuysQuery__
 *
 * To run a query within a React component, call `useGetExcludedBuysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExcludedBuysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExcludedBuysQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetExcludedBuysQuery(baseOptions: Apollo.QueryHookOptions<Types.GetExcludedBuysQuery, Types.GetExcludedBuysQueryVariables> & ({ variables: Types.GetExcludedBuysQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetExcludedBuysQuery, Types.GetExcludedBuysQueryVariables>(GetExcludedBuysDocument, options);
      }
export function useGetExcludedBuysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetExcludedBuysQuery, Types.GetExcludedBuysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetExcludedBuysQuery, Types.GetExcludedBuysQueryVariables>(GetExcludedBuysDocument, options);
        }
export function useGetExcludedBuysSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetExcludedBuysQuery, Types.GetExcludedBuysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetExcludedBuysQuery, Types.GetExcludedBuysQueryVariables>(GetExcludedBuysDocument, options);
        }
export type GetExcludedBuysQueryHookResult = ReturnType<typeof useGetExcludedBuysQuery>;
export type GetExcludedBuysLazyQueryHookResult = ReturnType<typeof useGetExcludedBuysLazyQuery>;
export type GetExcludedBuysSuspenseQueryHookResult = ReturnType<typeof useGetExcludedBuysSuspenseQuery>;
export type GetExcludedBuysQueryResult = Apollo.QueryResult<Types.GetExcludedBuysQuery, Types.GetExcludedBuysQueryVariables>;
export const ExcludedBuysPageDocument = gql`
    query ExcludedBuysPage($accountId: ID!) {
  account: node(id: $accountId) {
    ...ExcludedBuysAccountNode
  }
}
    ${ExcludedBuysAccountNodeFragmentDoc}`;

/**
 * __useExcludedBuysPageQuery__
 *
 * To run a query within a React component, call `useExcludedBuysPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useExcludedBuysPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExcludedBuysPageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useExcludedBuysPageQuery(baseOptions: Apollo.QueryHookOptions<Types.ExcludedBuysPageQuery, Types.ExcludedBuysPageQueryVariables> & ({ variables: Types.ExcludedBuysPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ExcludedBuysPageQuery, Types.ExcludedBuysPageQueryVariables>(ExcludedBuysPageDocument, options);
      }
export function useExcludedBuysPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ExcludedBuysPageQuery, Types.ExcludedBuysPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ExcludedBuysPageQuery, Types.ExcludedBuysPageQueryVariables>(ExcludedBuysPageDocument, options);
        }
export function useExcludedBuysPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ExcludedBuysPageQuery, Types.ExcludedBuysPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ExcludedBuysPageQuery, Types.ExcludedBuysPageQueryVariables>(ExcludedBuysPageDocument, options);
        }
export type ExcludedBuysPageQueryHookResult = ReturnType<typeof useExcludedBuysPageQuery>;
export type ExcludedBuysPageLazyQueryHookResult = ReturnType<typeof useExcludedBuysPageLazyQuery>;
export type ExcludedBuysPageSuspenseQueryHookResult = ReturnType<typeof useExcludedBuysPageSuspenseQuery>;
export type ExcludedBuysPageQueryResult = Apollo.QueryResult<Types.ExcludedBuysPageQuery, Types.ExcludedBuysPageQueryVariables>;
export const FilterBySymbolDocument = gql`
    query FilterBySymbol($query: String!, $filterTypes: [SliceableTypeEnum!], $first: Int!, $after: String) {
  viewer {
    ... on Viewer {
      searchSliceables(
        query: $query
        filterTypes: $filterTypes
        first: $first
        after: $after
      ) {
        edges {
          node {
            id
            ...SliceableCell
          }
        }
      }
    }
  }
}
    ${SliceableCellFragmentDoc}`;

/**
 * __useFilterBySymbolQuery__
 *
 * To run a query within a React component, call `useFilterBySymbolQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterBySymbolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterBySymbolQuery({
 *   variables: {
 *      query: // value for 'query'
 *      filterTypes: // value for 'filterTypes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFilterBySymbolQuery(baseOptions: Apollo.QueryHookOptions<Types.FilterBySymbolQuery, Types.FilterBySymbolQueryVariables> & ({ variables: Types.FilterBySymbolQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FilterBySymbolQuery, Types.FilterBySymbolQueryVariables>(FilterBySymbolDocument, options);
      }
export function useFilterBySymbolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FilterBySymbolQuery, Types.FilterBySymbolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FilterBySymbolQuery, Types.FilterBySymbolQueryVariables>(FilterBySymbolDocument, options);
        }
export function useFilterBySymbolSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.FilterBySymbolQuery, Types.FilterBySymbolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.FilterBySymbolQuery, Types.FilterBySymbolQueryVariables>(FilterBySymbolDocument, options);
        }
export type FilterBySymbolQueryHookResult = ReturnType<typeof useFilterBySymbolQuery>;
export type FilterBySymbolLazyQueryHookResult = ReturnType<typeof useFilterBySymbolLazyQuery>;
export type FilterBySymbolSuspenseQueryHookResult = ReturnType<typeof useFilterBySymbolSuspenseQuery>;
export type FilterBySymbolQueryResult = Apollo.QueryResult<Types.FilterBySymbolQuery, Types.FilterBySymbolQueryVariables>;
export const InvestFullyPaidLendingDisclosureDocument = gql`
    query InvestFullyPaidLendingDisclosure($accountRegistration: OnlineAccountRegistrationEnum!) {
  viewer {
    invest {
      fullyPaidLendingDisclosure(accountRegistration: $accountRegistration) {
        ...FullyPaidLendingDisclosure
      }
    }
  }
}
    ${FullyPaidLendingDisclosureFragmentDoc}`;

/**
 * __useInvestFullyPaidLendingDisclosureQuery__
 *
 * To run a query within a React component, call `useInvestFullyPaidLendingDisclosureQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestFullyPaidLendingDisclosureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestFullyPaidLendingDisclosureQuery({
 *   variables: {
 *      accountRegistration: // value for 'accountRegistration'
 *   },
 * });
 */
export function useInvestFullyPaidLendingDisclosureQuery(baseOptions: Apollo.QueryHookOptions<Types.InvestFullyPaidLendingDisclosureQuery, Types.InvestFullyPaidLendingDisclosureQueryVariables> & ({ variables: Types.InvestFullyPaidLendingDisclosureQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvestFullyPaidLendingDisclosureQuery, Types.InvestFullyPaidLendingDisclosureQueryVariables>(InvestFullyPaidLendingDisclosureDocument, options);
      }
export function useInvestFullyPaidLendingDisclosureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvestFullyPaidLendingDisclosureQuery, Types.InvestFullyPaidLendingDisclosureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvestFullyPaidLendingDisclosureQuery, Types.InvestFullyPaidLendingDisclosureQueryVariables>(InvestFullyPaidLendingDisclosureDocument, options);
        }
export function useInvestFullyPaidLendingDisclosureSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.InvestFullyPaidLendingDisclosureQuery, Types.InvestFullyPaidLendingDisclosureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.InvestFullyPaidLendingDisclosureQuery, Types.InvestFullyPaidLendingDisclosureQueryVariables>(InvestFullyPaidLendingDisclosureDocument, options);
        }
export type InvestFullyPaidLendingDisclosureQueryHookResult = ReturnType<typeof useInvestFullyPaidLendingDisclosureQuery>;
export type InvestFullyPaidLendingDisclosureLazyQueryHookResult = ReturnType<typeof useInvestFullyPaidLendingDisclosureLazyQuery>;
export type InvestFullyPaidLendingDisclosureSuspenseQueryHookResult = ReturnType<typeof useInvestFullyPaidLendingDisclosureSuspenseQuery>;
export type InvestFullyPaidLendingDisclosureQueryResult = Apollo.QueryResult<Types.InvestFullyPaidLendingDisclosureQuery, Types.InvestFullyPaidLendingDisclosureQueryVariables>;
export const FundAccountPageDocument = gql`
    query FundAccountPage($accountId: ID!) {
  account: node(id: $accountId) {
    ...FundedAccount
  }
  viewer {
    transfers {
      defaultAmountPills(transferScenario: INVEST_INITIAL_DEPOSIT) {
        label
      }
      participants(withOppositeParticipantId: $accountId) {
        ... on TransferParticipantList {
          list {
            ...FundingAccountParticipant
          }
        }
      }
      requirements(
        amount: null
        fromParticipantId: null
        toParticipantId: $accountId
        transferType: null
      ) {
        isIraContributionYearRequired
        isIraContributionLimitRequired
      }
    }
    accounts(first: 50) {
      edges {
        node {
          id
        }
      }
    }
  }
}
    ${FundedAccountFragmentDoc}
${FundingAccountParticipantFragmentDoc}`;

/**
 * __useFundAccountPageQuery__
 *
 * To run a query within a React component, call `useFundAccountPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundAccountPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundAccountPageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useFundAccountPageQuery(baseOptions: Apollo.QueryHookOptions<Types.FundAccountPageQuery, Types.FundAccountPageQueryVariables> & ({ variables: Types.FundAccountPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FundAccountPageQuery, Types.FundAccountPageQueryVariables>(FundAccountPageDocument, options);
      }
export function useFundAccountPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FundAccountPageQuery, Types.FundAccountPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FundAccountPageQuery, Types.FundAccountPageQueryVariables>(FundAccountPageDocument, options);
        }
export function useFundAccountPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.FundAccountPageQuery, Types.FundAccountPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.FundAccountPageQuery, Types.FundAccountPageQueryVariables>(FundAccountPageDocument, options);
        }
export type FundAccountPageQueryHookResult = ReturnType<typeof useFundAccountPageQuery>;
export type FundAccountPageLazyQueryHookResult = ReturnType<typeof useFundAccountPageLazyQuery>;
export type FundAccountPageSuspenseQueryHookResult = ReturnType<typeof useFundAccountPageSuspenseQuery>;
export type FundAccountPageQueryResult = Apollo.QueryResult<Types.FundAccountPageQuery, Types.FundAccountPageQueryVariables>;
export const HoldingsPageDocument = gql`
    query HoldingsPage($accountId: ID!) {
  account: node(id: $accountId) {
    id
    ... on Account {
      __typename
      ...BorrowAccount
      isCryptoAccount
      hasPortfolio
      receivedSecurities {
        receivedSecuritiesCard {
          ...ReceivedSecuritiesCard
        }
        unmanagedHoldingsScreen {
          hasUnmanagedHoldings
        }
      }
    }
  }
}
    ${BorrowAccountFragmentDoc}
${ReceivedSecuritiesCardFragmentDoc}`;

/**
 * __useHoldingsPageQuery__
 *
 * To run a query within a React component, call `useHoldingsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHoldingsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHoldingsPageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useHoldingsPageQuery(baseOptions: Apollo.QueryHookOptions<Types.HoldingsPageQuery, Types.HoldingsPageQueryVariables> & ({ variables: Types.HoldingsPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HoldingsPageQuery, Types.HoldingsPageQueryVariables>(HoldingsPageDocument, options);
      }
export function useHoldingsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HoldingsPageQuery, Types.HoldingsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HoldingsPageQuery, Types.HoldingsPageQueryVariables>(HoldingsPageDocument, options);
        }
export function useHoldingsPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.HoldingsPageQuery, Types.HoldingsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.HoldingsPageQuery, Types.HoldingsPageQueryVariables>(HoldingsPageDocument, options);
        }
export type HoldingsPageQueryHookResult = ReturnType<typeof useHoldingsPageQuery>;
export type HoldingsPageLazyQueryHookResult = ReturnType<typeof useHoldingsPageLazyQuery>;
export type HoldingsPageSuspenseQueryHookResult = ReturnType<typeof useHoldingsPageSuspenseQuery>;
export type HoldingsPageQueryResult = Apollo.QueryResult<Types.HoldingsPageQuery, Types.HoldingsPageQueryVariables>;
export const IraContributionYearsDocument = gql`
    query IraContributionYears {
  viewer {
    irsRegulations {
      iraContributionYears {
        year
        limitUnder50
        limitOver50
        isCurrent
      }
    }
  }
}
    `;

/**
 * __useIraContributionYearsQuery__
 *
 * To run a query within a React component, call `useIraContributionYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIraContributionYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIraContributionYearsQuery({
 *   variables: {
 *   },
 * });
 */
export function useIraContributionYearsQuery(baseOptions?: Apollo.QueryHookOptions<Types.IraContributionYearsQuery, Types.IraContributionYearsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IraContributionYearsQuery, Types.IraContributionYearsQueryVariables>(IraContributionYearsDocument, options);
      }
export function useIraContributionYearsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IraContributionYearsQuery, Types.IraContributionYearsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IraContributionYearsQuery, Types.IraContributionYearsQueryVariables>(IraContributionYearsDocument, options);
        }
export function useIraContributionYearsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.IraContributionYearsQuery, Types.IraContributionYearsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IraContributionYearsQuery, Types.IraContributionYearsQueryVariables>(IraContributionYearsDocument, options);
        }
export type IraContributionYearsQueryHookResult = ReturnType<typeof useIraContributionYearsQuery>;
export type IraContributionYearsLazyQueryHookResult = ReturnType<typeof useIraContributionYearsLazyQuery>;
export type IraContributionYearsSuspenseQueryHookResult = ReturnType<typeof useIraContributionYearsSuspenseQuery>;
export type IraContributionYearsQueryResult = Apollo.QueryResult<Types.IraContributionYearsQuery, Types.IraContributionYearsQueryVariables>;
export const IdentityInfoFormDocument = gql`
    query IdentityInfoForm {
  viewer {
    onboarding {
      dueDiligence {
        occupationDropdownOptions {
          label
        }
      }
    }
  }
}
    `;

/**
 * __useIdentityInfoFormQuery__
 *
 * To run a query within a React component, call `useIdentityInfoFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdentityInfoFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdentityInfoFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useIdentityInfoFormQuery(baseOptions?: Apollo.QueryHookOptions<Types.IdentityInfoFormQuery, Types.IdentityInfoFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IdentityInfoFormQuery, Types.IdentityInfoFormQueryVariables>(IdentityInfoFormDocument, options);
      }
export function useIdentityInfoFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IdentityInfoFormQuery, Types.IdentityInfoFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IdentityInfoFormQuery, Types.IdentityInfoFormQueryVariables>(IdentityInfoFormDocument, options);
        }
export function useIdentityInfoFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.IdentityInfoFormQuery, Types.IdentityInfoFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IdentityInfoFormQuery, Types.IdentityInfoFormQueryVariables>(IdentityInfoFormDocument, options);
        }
export type IdentityInfoFormQueryHookResult = ReturnType<typeof useIdentityInfoFormQuery>;
export type IdentityInfoFormLazyQueryHookResult = ReturnType<typeof useIdentityInfoFormLazyQuery>;
export type IdentityInfoFormSuspenseQueryHookResult = ReturnType<typeof useIdentityInfoFormSuspenseQuery>;
export type IdentityInfoFormQueryResult = Apollo.QueryResult<Types.IdentityInfoFormQuery, Types.IdentityInfoFormQueryVariables>;
export const InvestAcatWizardContraSearchFormContainerRefetchDocument = gql`
    query InvestAcatWizardContraSearchFormContainerRefetch($searchTerm: String) {
  viewer {
    invest {
      acatWizard {
        brokerageSelectionContent {
          searchForm {
            ... on InvestAcatWizardContraSearchForm {
              id
              key
              placeholder
              contraUnavailableNotice {
                content
                link {
                  ...Linkable
                }
              }
              contraParticipantList(searchTerm: $searchTerm) {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${LinkableFragmentDoc}`;

/**
 * __useInvestAcatWizardContraSearchFormContainerRefetchQuery__
 *
 * To run a query within a React component, call `useInvestAcatWizardContraSearchFormContainerRefetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestAcatWizardContraSearchFormContainerRefetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestAcatWizardContraSearchFormContainerRefetchQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useInvestAcatWizardContraSearchFormContainerRefetchQuery(baseOptions?: Apollo.QueryHookOptions<Types.InvestAcatWizardContraSearchFormContainerRefetchQuery, Types.InvestAcatWizardContraSearchFormContainerRefetchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvestAcatWizardContraSearchFormContainerRefetchQuery, Types.InvestAcatWizardContraSearchFormContainerRefetchQueryVariables>(InvestAcatWizardContraSearchFormContainerRefetchDocument, options);
      }
export function useInvestAcatWizardContraSearchFormContainerRefetchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvestAcatWizardContraSearchFormContainerRefetchQuery, Types.InvestAcatWizardContraSearchFormContainerRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvestAcatWizardContraSearchFormContainerRefetchQuery, Types.InvestAcatWizardContraSearchFormContainerRefetchQueryVariables>(InvestAcatWizardContraSearchFormContainerRefetchDocument, options);
        }
export function useInvestAcatWizardContraSearchFormContainerRefetchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.InvestAcatWizardContraSearchFormContainerRefetchQuery, Types.InvestAcatWizardContraSearchFormContainerRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.InvestAcatWizardContraSearchFormContainerRefetchQuery, Types.InvestAcatWizardContraSearchFormContainerRefetchQueryVariables>(InvestAcatWizardContraSearchFormContainerRefetchDocument, options);
        }
export type InvestAcatWizardContraSearchFormContainerRefetchQueryHookResult = ReturnType<typeof useInvestAcatWizardContraSearchFormContainerRefetchQuery>;
export type InvestAcatWizardContraSearchFormContainerRefetchLazyQueryHookResult = ReturnType<typeof useInvestAcatWizardContraSearchFormContainerRefetchLazyQuery>;
export type InvestAcatWizardContraSearchFormContainerRefetchSuspenseQueryHookResult = ReturnType<typeof useInvestAcatWizardContraSearchFormContainerRefetchSuspenseQuery>;
export type InvestAcatWizardContraSearchFormContainerRefetchQueryResult = Apollo.QueryResult<Types.InvestAcatWizardContraSearchFormContainerRefetchQuery, Types.InvestAcatWizardContraSearchFormContainerRefetchQueryVariables>;
export const GetInvestActivityDocument = gql`
    query GetInvestActivity($id: ID!, $first: Int, $after: String, $filter: InvestActivityEntryFilterInput, $sort: [InvestActivityEntrySortInput!]) {
  node(id: $id) {
    ...InvestActivity
  }
}
    ${InvestActivityFragmentDoc}`;

/**
 * __useGetInvestActivityQuery__
 *
 * To run a query within a React component, call `useGetInvestActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetInvestActivityQuery(baseOptions: Apollo.QueryHookOptions<Types.GetInvestActivityQuery, Types.GetInvestActivityQueryVariables> & ({ variables: Types.GetInvestActivityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetInvestActivityQuery, Types.GetInvestActivityQueryVariables>(GetInvestActivityDocument, options);
      }
export function useGetInvestActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInvestActivityQuery, Types.GetInvestActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetInvestActivityQuery, Types.GetInvestActivityQueryVariables>(GetInvestActivityDocument, options);
        }
export function useGetInvestActivitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetInvestActivityQuery, Types.GetInvestActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetInvestActivityQuery, Types.GetInvestActivityQueryVariables>(GetInvestActivityDocument, options);
        }
export type GetInvestActivityQueryHookResult = ReturnType<typeof useGetInvestActivityQuery>;
export type GetInvestActivityLazyQueryHookResult = ReturnType<typeof useGetInvestActivityLazyQuery>;
export type GetInvestActivitySuspenseQueryHookResult = ReturnType<typeof useGetInvestActivitySuspenseQuery>;
export type GetInvestActivityQueryResult = Apollo.QueryResult<Types.GetInvestActivityQuery, Types.GetInvestActivityQueryVariables>;
export const InvestActivityPageDocument = gql`
    query InvestActivityPage($id: ID!) {
  node(id: $id) {
    ...InvestActivityEntry
  }
}
    ${InvestActivityEntryFragmentDoc}`;

/**
 * __useInvestActivityPageQuery__
 *
 * To run a query within a React component, call `useInvestActivityPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestActivityPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestActivityPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvestActivityPageQuery(baseOptions: Apollo.QueryHookOptions<Types.InvestActivityPageQuery, Types.InvestActivityPageQueryVariables> & ({ variables: Types.InvestActivityPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvestActivityPageQuery, Types.InvestActivityPageQueryVariables>(InvestActivityPageDocument, options);
      }
export function useInvestActivityPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvestActivityPageQuery, Types.InvestActivityPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvestActivityPageQuery, Types.InvestActivityPageQueryVariables>(InvestActivityPageDocument, options);
        }
export function useInvestActivityPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.InvestActivityPageQuery, Types.InvestActivityPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.InvestActivityPageQuery, Types.InvestActivityPageQueryVariables>(InvestActivityPageDocument, options);
        }
export type InvestActivityPageQueryHookResult = ReturnType<typeof useInvestActivityPageQuery>;
export type InvestActivityPageLazyQueryHookResult = ReturnType<typeof useInvestActivityPageLazyQuery>;
export type InvestActivityPageSuspenseQueryHookResult = ReturnType<typeof useInvestActivityPageSuspenseQuery>;
export type InvestActivityPageQueryResult = Apollo.QueryResult<Types.InvestActivityPageQuery, Types.InvestActivityPageQueryVariables>;
export const InvestActivityTradeSummaryDocument = gql`
    query InvestActivityTradeSummary($id: ID!, $firstForBuys: Int, $firstForSells: Int, $afterForBuys: String, $afterForSells: String) {
  node(id: $id) {
    ...InvestActivityTrade
  }
}
    ${InvestActivityTradeFragmentDoc}`;

/**
 * __useInvestActivityTradeSummaryQuery__
 *
 * To run a query within a React component, call `useInvestActivityTradeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestActivityTradeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestActivityTradeSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      firstForBuys: // value for 'firstForBuys'
 *      firstForSells: // value for 'firstForSells'
 *      afterForBuys: // value for 'afterForBuys'
 *      afterForSells: // value for 'afterForSells'
 *   },
 * });
 */
export function useInvestActivityTradeSummaryQuery(baseOptions: Apollo.QueryHookOptions<Types.InvestActivityTradeSummaryQuery, Types.InvestActivityTradeSummaryQueryVariables> & ({ variables: Types.InvestActivityTradeSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvestActivityTradeSummaryQuery, Types.InvestActivityTradeSummaryQueryVariables>(InvestActivityTradeSummaryDocument, options);
      }
export function useInvestActivityTradeSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvestActivityTradeSummaryQuery, Types.InvestActivityTradeSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvestActivityTradeSummaryQuery, Types.InvestActivityTradeSummaryQueryVariables>(InvestActivityTradeSummaryDocument, options);
        }
export function useInvestActivityTradeSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.InvestActivityTradeSummaryQuery, Types.InvestActivityTradeSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.InvestActivityTradeSummaryQuery, Types.InvestActivityTradeSummaryQueryVariables>(InvestActivityTradeSummaryDocument, options);
        }
export type InvestActivityTradeSummaryQueryHookResult = ReturnType<typeof useInvestActivityTradeSummaryQuery>;
export type InvestActivityTradeSummaryLazyQueryHookResult = ReturnType<typeof useInvestActivityTradeSummaryLazyQuery>;
export type InvestActivityTradeSummarySuspenseQueryHookResult = ReturnType<typeof useInvestActivityTradeSummarySuspenseQuery>;
export type InvestActivityTradeSummaryQueryResult = Apollo.QueryResult<Types.InvestActivityTradeSummaryQuery, Types.InvestActivityTradeSummaryQueryVariables>;
export const GetExcludedBuysPageDocument = gql`
    query GetExcludedBuysPage($id: ID!, $first: Int!, $after: String) {
  node(id: $id) {
    ...ExcludedBuysPageDetails
  }
}
    ${ExcludedBuysPageDetailsFragmentDoc}`;

/**
 * __useGetExcludedBuysPageQuery__
 *
 * To run a query within a React component, call `useGetExcludedBuysPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExcludedBuysPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExcludedBuysPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetExcludedBuysPageQuery(baseOptions: Apollo.QueryHookOptions<Types.GetExcludedBuysPageQuery, Types.GetExcludedBuysPageQueryVariables> & ({ variables: Types.GetExcludedBuysPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetExcludedBuysPageQuery, Types.GetExcludedBuysPageQueryVariables>(GetExcludedBuysPageDocument, options);
      }
export function useGetExcludedBuysPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetExcludedBuysPageQuery, Types.GetExcludedBuysPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetExcludedBuysPageQuery, Types.GetExcludedBuysPageQueryVariables>(GetExcludedBuysPageDocument, options);
        }
export function useGetExcludedBuysPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetExcludedBuysPageQuery, Types.GetExcludedBuysPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetExcludedBuysPageQuery, Types.GetExcludedBuysPageQueryVariables>(GetExcludedBuysPageDocument, options);
        }
export type GetExcludedBuysPageQueryHookResult = ReturnType<typeof useGetExcludedBuysPageQuery>;
export type GetExcludedBuysPageLazyQueryHookResult = ReturnType<typeof useGetExcludedBuysPageLazyQuery>;
export type GetExcludedBuysPageSuspenseQueryHookResult = ReturnType<typeof useGetExcludedBuysPageSuspenseQuery>;
export type GetExcludedBuysPageQueryResult = Apollo.QueryResult<Types.GetExcludedBuysPageQuery, Types.GetExcludedBuysPageQueryVariables>;
export const InvestBankConnectionDocument = gql`
    query InvestBankConnection($id: ID!) {
  account: node(id: $id) {
    ...InvestBankConnectionAccountNode
  }
  viewer {
    fundingSources {
      hasFundingSources
    }
    user {
      isPrimaryEmailVerified
    }
    transfers {
      isEligibleForFundingSourceUpdate
    }
  }
}
    ${InvestBankConnectionAccountNodeFragmentDoc}`;

/**
 * __useInvestBankConnectionQuery__
 *
 * To run a query within a React component, call `useInvestBankConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestBankConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestBankConnectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvestBankConnectionQuery(baseOptions: Apollo.QueryHookOptions<Types.InvestBankConnectionQuery, Types.InvestBankConnectionQueryVariables> & ({ variables: Types.InvestBankConnectionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvestBankConnectionQuery, Types.InvestBankConnectionQueryVariables>(InvestBankConnectionDocument, options);
      }
export function useInvestBankConnectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvestBankConnectionQuery, Types.InvestBankConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvestBankConnectionQuery, Types.InvestBankConnectionQueryVariables>(InvestBankConnectionDocument, options);
        }
export function useInvestBankConnectionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.InvestBankConnectionQuery, Types.InvestBankConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.InvestBankConnectionQuery, Types.InvestBankConnectionQueryVariables>(InvestBankConnectionDocument, options);
        }
export type InvestBankConnectionQueryHookResult = ReturnType<typeof useInvestBankConnectionQuery>;
export type InvestBankConnectionLazyQueryHookResult = ReturnType<typeof useInvestBankConnectionLazyQuery>;
export type InvestBankConnectionSuspenseQueryHookResult = ReturnType<typeof useInvestBankConnectionSuspenseQuery>;
export type InvestBankConnectionQueryResult = Apollo.QueryResult<Types.InvestBankConnectionQuery, Types.InvestBankConnectionQueryVariables>;
export const InvestFundingHistoryDocument = gql`
    query InvestFundingHistory($id: ID!) {
  node(id: $id) {
    ...InvestFunding
  }
}
    ${InvestFundingFragmentDoc}`;

/**
 * __useInvestFundingHistoryQuery__
 *
 * To run a query within a React component, call `useInvestFundingHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestFundingHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestFundingHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvestFundingHistoryQuery(baseOptions: Apollo.QueryHookOptions<Types.InvestFundingHistoryQuery, Types.InvestFundingHistoryQueryVariables> & ({ variables: Types.InvestFundingHistoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvestFundingHistoryQuery, Types.InvestFundingHistoryQueryVariables>(InvestFundingHistoryDocument, options);
      }
export function useInvestFundingHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvestFundingHistoryQuery, Types.InvestFundingHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvestFundingHistoryQuery, Types.InvestFundingHistoryQueryVariables>(InvestFundingHistoryDocument, options);
        }
export function useInvestFundingHistorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.InvestFundingHistoryQuery, Types.InvestFundingHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.InvestFundingHistoryQuery, Types.InvestFundingHistoryQueryVariables>(InvestFundingHistoryDocument, options);
        }
export type InvestFundingHistoryQueryHookResult = ReturnType<typeof useInvestFundingHistoryQuery>;
export type InvestFundingHistoryLazyQueryHookResult = ReturnType<typeof useInvestFundingHistoryLazyQuery>;
export type InvestFundingHistorySuspenseQueryHookResult = ReturnType<typeof useInvestFundingHistorySuspenseQuery>;
export type InvestFundingHistoryQueryResult = Apollo.QueryResult<Types.InvestFundingHistoryQuery, Types.InvestFundingHistoryQueryVariables>;
export const InvestMarketingScreenDocument = gql`
    query InvestMarketingScreen {
  viewer {
    invest {
      investMarketingScreen {
        header
        footer
        productMarketingCards {
          illustration {
            ...AppImage
          }
          title
          titleTooltip
          subtitle
          primaryCtaButton {
            ...AppLink
          }
          secondaryCtaButton {
            ...AppLink
          }
          analyticsEvent {
            ...AnalyticsEvent
          }
        }
        disclosures {
          ...AppRichTextDisclosureLink
        }
      }
    }
    announcements {
      ... on Announcements {
        forInvestMarketing {
          ...Announcement
        }
      }
    }
  }
}
    ${AppImageFragmentDoc}
${AppLinkFragmentDoc}
${AnalyticsEventFragmentDoc}
${AppRichTextDisclosureLinkFragmentDoc}
${AnnouncementFragmentDoc}`;

/**
 * __useInvestMarketingScreenQuery__
 *
 * To run a query within a React component, call `useInvestMarketingScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestMarketingScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestMarketingScreenQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvestMarketingScreenQuery(baseOptions?: Apollo.QueryHookOptions<Types.InvestMarketingScreenQuery, Types.InvestMarketingScreenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvestMarketingScreenQuery, Types.InvestMarketingScreenQueryVariables>(InvestMarketingScreenDocument, options);
      }
export function useInvestMarketingScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvestMarketingScreenQuery, Types.InvestMarketingScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvestMarketingScreenQuery, Types.InvestMarketingScreenQueryVariables>(InvestMarketingScreenDocument, options);
        }
export function useInvestMarketingScreenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.InvestMarketingScreenQuery, Types.InvestMarketingScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.InvestMarketingScreenQuery, Types.InvestMarketingScreenQueryVariables>(InvestMarketingScreenDocument, options);
        }
export type InvestMarketingScreenQueryHookResult = ReturnType<typeof useInvestMarketingScreenQuery>;
export type InvestMarketingScreenLazyQueryHookResult = ReturnType<typeof useInvestMarketingScreenLazyQuery>;
export type InvestMarketingScreenSuspenseQueryHookResult = ReturnType<typeof useInvestMarketingScreenSuspenseQuery>;
export type InvestMarketingScreenQueryResult = Apollo.QueryResult<Types.InvestMarketingScreenQuery, Types.InvestMarketingScreenQueryVariables>;
export const InvestPageDocument = gql`
    query InvestPage($activeAccountId: ID!) {
  account: node(id: $activeAccountId) {
    ...InvestPageAccountNode
  }
  viewer {
    invest {
      acatWizardEntryLink {
        ...Linkable
      }
    }
    accounts(first: 50) {
      edges {
        node {
          id
          name
          number
          registration
          balance {
            totalValue {
              value
            }
          }
          transferParticipantType
        }
      }
    }
    transfers {
      isEligibleForFundingSourceUpdate
    }
  }
}
    ${InvestPageAccountNodeFragmentDoc}
${LinkableFragmentDoc}`;

/**
 * __useInvestPageQuery__
 *
 * To run a query within a React component, call `useInvestPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestPageQuery({
 *   variables: {
 *      activeAccountId: // value for 'activeAccountId'
 *   },
 * });
 */
export function useInvestPageQuery(baseOptions: Apollo.QueryHookOptions<Types.InvestPageQuery, Types.InvestPageQueryVariables> & ({ variables: Types.InvestPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvestPageQuery, Types.InvestPageQueryVariables>(InvestPageDocument, options);
      }
export function useInvestPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvestPageQuery, Types.InvestPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvestPageQuery, Types.InvestPageQueryVariables>(InvestPageDocument, options);
        }
export function useInvestPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.InvestPageQuery, Types.InvestPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.InvestPageQuery, Types.InvestPageQueryVariables>(InvestPageDocument, options);
        }
export type InvestPageQueryHookResult = ReturnType<typeof useInvestPageQuery>;
export type InvestPageLazyQueryHookResult = ReturnType<typeof useInvestPageLazyQuery>;
export type InvestPageSuspenseQueryHookResult = ReturnType<typeof useInvestPageSuspenseQuery>;
export type InvestPageQueryResult = Apollo.QueryResult<Types.InvestPageQuery, Types.InvestPageQueryVariables>;
export const InvestPortfolioDetailsHeaderDocument = gql`
    query InvestPortfolioDetailsHeader($nodeId: ID!) {
  node(id: $nodeId) {
    ...PortfolioSliceBuyingPower
  }
}
    ${PortfolioSliceBuyingPowerFragmentDoc}`;

/**
 * __useInvestPortfolioDetailsHeaderQuery__
 *
 * To run a query within a React component, call `useInvestPortfolioDetailsHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestPortfolioDetailsHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestPortfolioDetailsHeaderQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useInvestPortfolioDetailsHeaderQuery(baseOptions: Apollo.QueryHookOptions<Types.InvestPortfolioDetailsHeaderQuery, Types.InvestPortfolioDetailsHeaderQueryVariables> & ({ variables: Types.InvestPortfolioDetailsHeaderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvestPortfolioDetailsHeaderQuery, Types.InvestPortfolioDetailsHeaderQueryVariables>(InvestPortfolioDetailsHeaderDocument, options);
      }
export function useInvestPortfolioDetailsHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvestPortfolioDetailsHeaderQuery, Types.InvestPortfolioDetailsHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvestPortfolioDetailsHeaderQuery, Types.InvestPortfolioDetailsHeaderQueryVariables>(InvestPortfolioDetailsHeaderDocument, options);
        }
export function useInvestPortfolioDetailsHeaderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.InvestPortfolioDetailsHeaderQuery, Types.InvestPortfolioDetailsHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.InvestPortfolioDetailsHeaderQuery, Types.InvestPortfolioDetailsHeaderQueryVariables>(InvestPortfolioDetailsHeaderDocument, options);
        }
export type InvestPortfolioDetailsHeaderQueryHookResult = ReturnType<typeof useInvestPortfolioDetailsHeaderQuery>;
export type InvestPortfolioDetailsHeaderLazyQueryHookResult = ReturnType<typeof useInvestPortfolioDetailsHeaderLazyQuery>;
export type InvestPortfolioDetailsHeaderSuspenseQueryHookResult = ReturnType<typeof useInvestPortfolioDetailsHeaderSuspenseQuery>;
export type InvestPortfolioDetailsHeaderQueryResult = Apollo.QueryResult<Types.InvestPortfolioDetailsHeaderQuery, Types.InvestPortfolioDetailsHeaderQueryVariables>;
export const InvestProductSelectionDocument = gql`
    query InvestProductSelection {
  viewer {
    onboarding {
      onboardingInvest {
        investProductSelectionScreen {
          screenViewedAnalyticsEvent {
            ...AnalyticsEvent
          }
          title
          productSelection {
            ...ListItem
          }
        }
      }
    }
  }
}
    ${AnalyticsEventFragmentDoc}
${ListItemFragmentDoc}`;

/**
 * __useInvestProductSelectionQuery__
 *
 * To run a query within a React component, call `useInvestProductSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestProductSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestProductSelectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvestProductSelectionQuery(baseOptions?: Apollo.QueryHookOptions<Types.InvestProductSelectionQuery, Types.InvestProductSelectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvestProductSelectionQuery, Types.InvestProductSelectionQueryVariables>(InvestProductSelectionDocument, options);
      }
export function useInvestProductSelectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvestProductSelectionQuery, Types.InvestProductSelectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvestProductSelectionQuery, Types.InvestProductSelectionQueryVariables>(InvestProductSelectionDocument, options);
        }
export function useInvestProductSelectionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.InvestProductSelectionQuery, Types.InvestProductSelectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.InvestProductSelectionQuery, Types.InvestProductSelectionQueryVariables>(InvestProductSelectionDocument, options);
        }
export type InvestProductSelectionQueryHookResult = ReturnType<typeof useInvestProductSelectionQuery>;
export type InvestProductSelectionLazyQueryHookResult = ReturnType<typeof useInvestProductSelectionLazyQuery>;
export type InvestProductSelectionSuspenseQueryHookResult = ReturnType<typeof useInvestProductSelectionSuspenseQuery>;
export type InvestProductSelectionQueryResult = Apollo.QueryResult<Types.InvestProductSelectionQuery, Types.InvestProductSelectionQueryVariables>;
export const InvestTradeDisclosuresDocument = gql`
    query InvestTradeDisclosures($disclosuresFor: InvestDisclosuresType!) {
  viewer {
    invest {
      investDisclosures {
        investTradeDisclosures(for: $disclosuresFor) {
          disclosures {
            title
            content
            links {
              title
              url
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useInvestTradeDisclosuresQuery__
 *
 * To run a query within a React component, call `useInvestTradeDisclosuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestTradeDisclosuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestTradeDisclosuresQuery({
 *   variables: {
 *      disclosuresFor: // value for 'disclosuresFor'
 *   },
 * });
 */
export function useInvestTradeDisclosuresQuery(baseOptions: Apollo.QueryHookOptions<Types.InvestTradeDisclosuresQuery, Types.InvestTradeDisclosuresQueryVariables> & ({ variables: Types.InvestTradeDisclosuresQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvestTradeDisclosuresQuery, Types.InvestTradeDisclosuresQueryVariables>(InvestTradeDisclosuresDocument, options);
      }
export function useInvestTradeDisclosuresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvestTradeDisclosuresQuery, Types.InvestTradeDisclosuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvestTradeDisclosuresQuery, Types.InvestTradeDisclosuresQueryVariables>(InvestTradeDisclosuresDocument, options);
        }
export function useInvestTradeDisclosuresSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.InvestTradeDisclosuresQuery, Types.InvestTradeDisclosuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.InvestTradeDisclosuresQuery, Types.InvestTradeDisclosuresQueryVariables>(InvestTradeDisclosuresDocument, options);
        }
export type InvestTradeDisclosuresQueryHookResult = ReturnType<typeof useInvestTradeDisclosuresQuery>;
export type InvestTradeDisclosuresLazyQueryHookResult = ReturnType<typeof useInvestTradeDisclosuresLazyQuery>;
export type InvestTradeDisclosuresSuspenseQueryHookResult = ReturnType<typeof useInvestTradeDisclosuresSuspenseQuery>;
export type InvestTradeDisclosuresQueryResult = Apollo.QueryResult<Types.InvestTradeDisclosuresQuery, Types.InvestTradeDisclosuresQueryVariables>;
export const InvestmentsTablePaginationDocument = gql`
    query InvestmentsTablePagination($accountId: ID!, $first: Int!, $after: String, $positionsSort: [PositionSortOptionInput!]!) {
  account: node(id: $accountId) {
    ... on Account {
      __typename
      id
      originator
      ...BorrowAccount
      balance {
        investments {
          hasPositions
          ...Investments
        }
      }
    }
  }
}
    ${BorrowAccountFragmentDoc}
${InvestmentsFragmentDoc}`;

/**
 * __useInvestmentsTablePaginationQuery__
 *
 * To run a query within a React component, call `useInvestmentsTablePaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestmentsTablePaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestmentsTablePaginationQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      positionsSort: // value for 'positionsSort'
 *   },
 * });
 */
export function useInvestmentsTablePaginationQuery(baseOptions: Apollo.QueryHookOptions<Types.InvestmentsTablePaginationQuery, Types.InvestmentsTablePaginationQueryVariables> & ({ variables: Types.InvestmentsTablePaginationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvestmentsTablePaginationQuery, Types.InvestmentsTablePaginationQueryVariables>(InvestmentsTablePaginationDocument, options);
      }
export function useInvestmentsTablePaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvestmentsTablePaginationQuery, Types.InvestmentsTablePaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvestmentsTablePaginationQuery, Types.InvestmentsTablePaginationQueryVariables>(InvestmentsTablePaginationDocument, options);
        }
export function useInvestmentsTablePaginationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.InvestmentsTablePaginationQuery, Types.InvestmentsTablePaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.InvestmentsTablePaginationQuery, Types.InvestmentsTablePaginationQueryVariables>(InvestmentsTablePaginationDocument, options);
        }
export type InvestmentsTablePaginationQueryHookResult = ReturnType<typeof useInvestmentsTablePaginationQuery>;
export type InvestmentsTablePaginationLazyQueryHookResult = ReturnType<typeof useInvestmentsTablePaginationLazyQuery>;
export type InvestmentsTablePaginationSuspenseQueryHookResult = ReturnType<typeof useInvestmentsTablePaginationSuspenseQuery>;
export type InvestmentsTablePaginationQueryResult = Apollo.QueryResult<Types.InvestmentsTablePaginationQuery, Types.InvestmentsTablePaginationQueryVariables>;
export const IraDistributionInfoDocument = gql`
    query IraDistributionInfo($accountId: ID!) {
  node(id: $accountId) {
    ... on Account {
      iraDistributionReasons
      iraWithholding {
        defaultStateWithholding
        defaultFederalWithholding
        isStateEditable
        stateWithholdingInformationText
        iraWithholdingLink {
          ...Linkable
        }
        stateWithholdingInformationLink {
          ...Linkable
        }
      }
    }
  }
  viewer {
    transfers {
      niaWithholdingDisclaimer {
        text
      }
      niaDisclaimer {
        text
      }
      niaLearnMore {
        ...Linkable
      }
      iraOvercontributionFAQ {
        ...Linkable
      }
      iraDistributionReasonFAQ {
        ...Linkable
      }
    }
    irsRegulations {
      iraExcessContributionRemovalYears {
        year
        isCurrent
      }
    }
  }
}
    ${LinkableFragmentDoc}`;

/**
 * __useIraDistributionInfoQuery__
 *
 * To run a query within a React component, call `useIraDistributionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useIraDistributionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIraDistributionInfoQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useIraDistributionInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.IraDistributionInfoQuery, Types.IraDistributionInfoQueryVariables> & ({ variables: Types.IraDistributionInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IraDistributionInfoQuery, Types.IraDistributionInfoQueryVariables>(IraDistributionInfoDocument, options);
      }
export function useIraDistributionInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IraDistributionInfoQuery, Types.IraDistributionInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IraDistributionInfoQuery, Types.IraDistributionInfoQueryVariables>(IraDistributionInfoDocument, options);
        }
export function useIraDistributionInfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.IraDistributionInfoQuery, Types.IraDistributionInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IraDistributionInfoQuery, Types.IraDistributionInfoQueryVariables>(IraDistributionInfoDocument, options);
        }
export type IraDistributionInfoQueryHookResult = ReturnType<typeof useIraDistributionInfoQuery>;
export type IraDistributionInfoLazyQueryHookResult = ReturnType<typeof useIraDistributionInfoLazyQuery>;
export type IraDistributionInfoSuspenseQueryHookResult = ReturnType<typeof useIraDistributionInfoSuspenseQuery>;
export type IraDistributionInfoQueryResult = Apollo.QueryResult<Types.IraDistributionInfoQuery, Types.IraDistributionInfoQueryVariables>;
export const IraYearDropdownDocument = gql`
    query IraYearDropdown {
  viewer {
    irsRegulations {
      iraContributionYears {
        year
      }
    }
  }
}
    `;

/**
 * __useIraYearDropdownQuery__
 *
 * To run a query within a React component, call `useIraYearDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useIraYearDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIraYearDropdownQuery({
 *   variables: {
 *   },
 * });
 */
export function useIraYearDropdownQuery(baseOptions?: Apollo.QueryHookOptions<Types.IraYearDropdownQuery, Types.IraYearDropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IraYearDropdownQuery, Types.IraYearDropdownQueryVariables>(IraYearDropdownDocument, options);
      }
export function useIraYearDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IraYearDropdownQuery, Types.IraYearDropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IraYearDropdownQuery, Types.IraYearDropdownQueryVariables>(IraYearDropdownDocument, options);
        }
export function useIraYearDropdownSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.IraYearDropdownQuery, Types.IraYearDropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IraYearDropdownQuery, Types.IraYearDropdownQueryVariables>(IraYearDropdownDocument, options);
        }
export type IraYearDropdownQueryHookResult = ReturnType<typeof useIraYearDropdownQuery>;
export type IraYearDropdownLazyQueryHookResult = ReturnType<typeof useIraYearDropdownLazyQuery>;
export type IraYearDropdownSuspenseQueryHookResult = ReturnType<typeof useIraYearDropdownSuspenseQuery>;
export type IraYearDropdownQueryResult = Apollo.QueryResult<Types.IraYearDropdownQuery, Types.IraYearDropdownQueryVariables>;
export const BorrowAccountRouteDocument = gql`
    query BorrowAccountRoute($borrowAccountId: ID!) {
  node(id: $borrowAccountId) {
    ...BorrowAccountNode
  }
}
    ${BorrowAccountNodeFragmentDoc}`;

/**
 * __useBorrowAccountRouteQuery__
 *
 * To run a query within a React component, call `useBorrowAccountRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useBorrowAccountRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBorrowAccountRouteQuery({
 *   variables: {
 *      borrowAccountId: // value for 'borrowAccountId'
 *   },
 * });
 */
export function useBorrowAccountRouteQuery(baseOptions: Apollo.QueryHookOptions<Types.BorrowAccountRouteQuery, Types.BorrowAccountRouteQueryVariables> & ({ variables: Types.BorrowAccountRouteQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BorrowAccountRouteQuery, Types.BorrowAccountRouteQueryVariables>(BorrowAccountRouteDocument, options);
      }
export function useBorrowAccountRouteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BorrowAccountRouteQuery, Types.BorrowAccountRouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BorrowAccountRouteQuery, Types.BorrowAccountRouteQueryVariables>(BorrowAccountRouteDocument, options);
        }
export function useBorrowAccountRouteSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.BorrowAccountRouteQuery, Types.BorrowAccountRouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.BorrowAccountRouteQuery, Types.BorrowAccountRouteQueryVariables>(BorrowAccountRouteDocument, options);
        }
export type BorrowAccountRouteQueryHookResult = ReturnType<typeof useBorrowAccountRouteQuery>;
export type BorrowAccountRouteLazyQueryHookResult = ReturnType<typeof useBorrowAccountRouteLazyQuery>;
export type BorrowAccountRouteSuspenseQueryHookResult = ReturnType<typeof useBorrowAccountRouteSuspenseQuery>;
export type BorrowAccountRouteQueryResult = Apollo.QueryResult<Types.BorrowAccountRouteQuery, Types.BorrowAccountRouteQueryVariables>;
export const MarginCallInformationOptionsStepDocument = gql`
    query MarginCallInformationOptionsStep($borrowAccountId: ID!) {
  node(id: $borrowAccountId) {
    ...BorrowAccountMarginState
  }
}
    ${BorrowAccountMarginStateFragmentDoc}`;

/**
 * __useMarginCallInformationOptionsStepQuery__
 *
 * To run a query within a React component, call `useMarginCallInformationOptionsStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarginCallInformationOptionsStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarginCallInformationOptionsStepQuery({
 *   variables: {
 *      borrowAccountId: // value for 'borrowAccountId'
 *   },
 * });
 */
export function useMarginCallInformationOptionsStepQuery(baseOptions: Apollo.QueryHookOptions<Types.MarginCallInformationOptionsStepQuery, Types.MarginCallInformationOptionsStepQueryVariables> & ({ variables: Types.MarginCallInformationOptionsStepQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MarginCallInformationOptionsStepQuery, Types.MarginCallInformationOptionsStepQueryVariables>(MarginCallInformationOptionsStepDocument, options);
      }
export function useMarginCallInformationOptionsStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MarginCallInformationOptionsStepQuery, Types.MarginCallInformationOptionsStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MarginCallInformationOptionsStepQuery, Types.MarginCallInformationOptionsStepQueryVariables>(MarginCallInformationOptionsStepDocument, options);
        }
export function useMarginCallInformationOptionsStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MarginCallInformationOptionsStepQuery, Types.MarginCallInformationOptionsStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MarginCallInformationOptionsStepQuery, Types.MarginCallInformationOptionsStepQueryVariables>(MarginCallInformationOptionsStepDocument, options);
        }
export type MarginCallInformationOptionsStepQueryHookResult = ReturnType<typeof useMarginCallInformationOptionsStepQuery>;
export type MarginCallInformationOptionsStepLazyQueryHookResult = ReturnType<typeof useMarginCallInformationOptionsStepLazyQuery>;
export type MarginCallInformationOptionsStepSuspenseQueryHookResult = ReturnType<typeof useMarginCallInformationOptionsStepSuspenseQuery>;
export type MarginCallInformationOptionsStepQueryResult = Apollo.QueryResult<Types.MarginCallInformationOptionsStepQuery, Types.MarginCallInformationOptionsStepQueryVariables>;
export const PortfolioChartPerformanceDocument = gql`
    query PortfolioChartPerformance($portfolioId: ID!, $period: PortfolioSlicePerformancePeriodEnum!) {
  node(id: $portfolioId) {
    __typename
    ...RootPerformanceMetrics
    ...ChildPerformanceMetrics
  }
}
    ${RootPerformanceMetricsFragmentDoc}
${ChildPerformanceMetricsFragmentDoc}`;

/**
 * __usePortfolioChartPerformanceQuery__
 *
 * To run a query within a React component, call `usePortfolioChartPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioChartPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioChartPerformanceQuery({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *      period: // value for 'period'
 *   },
 * });
 */
export function usePortfolioChartPerformanceQuery(baseOptions: Apollo.QueryHookOptions<Types.PortfolioChartPerformanceQuery, Types.PortfolioChartPerformanceQueryVariables> & ({ variables: Types.PortfolioChartPerformanceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PortfolioChartPerformanceQuery, Types.PortfolioChartPerformanceQueryVariables>(PortfolioChartPerformanceDocument, options);
      }
export function usePortfolioChartPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PortfolioChartPerformanceQuery, Types.PortfolioChartPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PortfolioChartPerformanceQuery, Types.PortfolioChartPerformanceQueryVariables>(PortfolioChartPerformanceDocument, options);
        }
export function usePortfolioChartPerformanceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PortfolioChartPerformanceQuery, Types.PortfolioChartPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PortfolioChartPerformanceQuery, Types.PortfolioChartPerformanceQueryVariables>(PortfolioChartPerformanceDocument, options);
        }
export type PortfolioChartPerformanceQueryHookResult = ReturnType<typeof usePortfolioChartPerformanceQuery>;
export type PortfolioChartPerformanceLazyQueryHookResult = ReturnType<typeof usePortfolioChartPerformanceLazyQuery>;
export type PortfolioChartPerformanceSuspenseQueryHookResult = ReturnType<typeof usePortfolioChartPerformanceSuspenseQuery>;
export type PortfolioChartPerformanceQueryResult = Apollo.QueryResult<Types.PortfolioChartPerformanceQuery, Types.PortfolioChartPerformanceQueryVariables>;
export const PortfolioDetailsSliceDocument = gql`
    query PortfolioDetailsSlice($accountId: ID!, $portfolioId: ID!) {
  account: node(id: $accountId) {
    ...PortfolioDetailsAccountNode
  }
  portfolioSlice: node(id: $portfolioId) {
    ...PortfolioSliceLandingPage
    ...PortfolioSlicePie
    ...PortfolioSliceSecondaryMetrics
    ...PortfolioSlicePendingRebalanceBanner
    ...PortfolioSlicePendingOrderBanner
    ...PortfolioSlicePrimaryMetrics
    ...PortfolioSliceParentBreadcrumb
    ...PortfolioSliceFirstPositionSection
    ...PortfolioToSecurity
    ...PortfolioSliceOrderButton
    ...PortfolioSliceRebalanceButton
    ...PortfolioSliceEditButton
    ...PortfolioToPie
    ...PortfolioToSecurity
  }
  viewer {
    ...TransferFundsButtonViewer
    announcements {
      forInvest {
        ...Announcement
      }
    }
    borrow {
      hasBorrowAccountWithOpenMaintenanceCall
    }
    transfers {
      scheduledTransferRules(accountId: $accountId, enabledOnly: true) {
        id
      }
    }
    user {
      isPrimaryEmailVerified
    }
  }
}
    ${PortfolioDetailsAccountNodeFragmentDoc}
${PortfolioSliceLandingPageFragmentDoc}
${PortfolioSlicePieFragmentDoc}
${PortfolioSliceSecondaryMetricsFragmentDoc}
${PortfolioSlicePendingRebalanceBannerFragmentDoc}
${PortfolioSlicePendingOrderBannerFragmentDoc}
${PortfolioSlicePrimaryMetricsFragmentDoc}
${PortfolioSliceParentBreadcrumbFragmentDoc}
${PortfolioSliceFirstPositionSectionFragmentDoc}
${PortfolioToSecurityFragmentDoc}
${PortfolioSliceOrderButtonFragmentDoc}
${PortfolioSliceRebalanceButtonFragmentDoc}
${PortfolioSliceEditButtonFragmentDoc}
${PortfolioToPieFragmentDoc}
${TransferFundsButtonViewerFragmentDoc}
${AnnouncementFragmentDoc}`;

/**
 * __usePortfolioDetailsSliceQuery__
 *
 * To run a query within a React component, call `usePortfolioDetailsSliceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioDetailsSliceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioDetailsSliceQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function usePortfolioDetailsSliceQuery(baseOptions: Apollo.QueryHookOptions<Types.PortfolioDetailsSliceQuery, Types.PortfolioDetailsSliceQueryVariables> & ({ variables: Types.PortfolioDetailsSliceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PortfolioDetailsSliceQuery, Types.PortfolioDetailsSliceQueryVariables>(PortfolioDetailsSliceDocument, options);
      }
export function usePortfolioDetailsSliceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PortfolioDetailsSliceQuery, Types.PortfolioDetailsSliceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PortfolioDetailsSliceQuery, Types.PortfolioDetailsSliceQueryVariables>(PortfolioDetailsSliceDocument, options);
        }
export function usePortfolioDetailsSliceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PortfolioDetailsSliceQuery, Types.PortfolioDetailsSliceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PortfolioDetailsSliceQuery, Types.PortfolioDetailsSliceQueryVariables>(PortfolioDetailsSliceDocument, options);
        }
export type PortfolioDetailsSliceQueryHookResult = ReturnType<typeof usePortfolioDetailsSliceQuery>;
export type PortfolioDetailsSliceLazyQueryHookResult = ReturnType<typeof usePortfolioDetailsSliceLazyQuery>;
export type PortfolioDetailsSliceSuspenseQueryHookResult = ReturnType<typeof usePortfolioDetailsSliceSuspenseQuery>;
export type PortfolioDetailsSliceQueryResult = Apollo.QueryResult<Types.PortfolioDetailsSliceQuery, Types.PortfolioDetailsSliceQueryVariables>;
export const HighlightedSliceDocument = gql`
    query HighlightedSlice($id: ID!) {
  node(id: $id) {
    ... on PortfolioSlice {
      to {
        name
      }
    }
  }
}
    `;

/**
 * __useHighlightedSliceQuery__
 *
 * To run a query within a React component, call `useHighlightedSliceQuery` and pass it any options that fit your needs.
 * When your component renders, `useHighlightedSliceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHighlightedSliceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHighlightedSliceQuery(baseOptions: Apollo.QueryHookOptions<Types.HighlightedSliceQuery, Types.HighlightedSliceQueryVariables> & ({ variables: Types.HighlightedSliceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HighlightedSliceQuery, Types.HighlightedSliceQueryVariables>(HighlightedSliceDocument, options);
      }
export function useHighlightedSliceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HighlightedSliceQuery, Types.HighlightedSliceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HighlightedSliceQuery, Types.HighlightedSliceQueryVariables>(HighlightedSliceDocument, options);
        }
export function useHighlightedSliceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.HighlightedSliceQuery, Types.HighlightedSliceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.HighlightedSliceQuery, Types.HighlightedSliceQueryVariables>(HighlightedSliceDocument, options);
        }
export type HighlightedSliceQueryHookResult = ReturnType<typeof useHighlightedSliceQuery>;
export type HighlightedSliceLazyQueryHookResult = ReturnType<typeof useHighlightedSliceLazyQuery>;
export type HighlightedSliceSuspenseQueryHookResult = ReturnType<typeof useHighlightedSliceSuspenseQuery>;
export type HighlightedSliceQueryResult = Apollo.QueryResult<Types.HighlightedSliceQuery, Types.HighlightedSliceQueryVariables>;
export const PortfolioSliceChildrenTableDocument = gql`
    query PortfolioSliceChildrenTable($sliceId: ID!, $period: PortfolioSlicePerformancePeriodEnum! = MAX) {
  portfolioSlice: node(id: $sliceId) {
    ...PortfolioSliceChildrenPerformance
  }
}
    ${PortfolioSliceChildrenPerformanceFragmentDoc}`;

/**
 * __usePortfolioSliceChildrenTableQuery__
 *
 * To run a query within a React component, call `usePortfolioSliceChildrenTableQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioSliceChildrenTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioSliceChildrenTableQuery({
 *   variables: {
 *      sliceId: // value for 'sliceId'
 *      period: // value for 'period'
 *   },
 * });
 */
export function usePortfolioSliceChildrenTableQuery(baseOptions: Apollo.QueryHookOptions<Types.PortfolioSliceChildrenTableQuery, Types.PortfolioSliceChildrenTableQueryVariables> & ({ variables: Types.PortfolioSliceChildrenTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PortfolioSliceChildrenTableQuery, Types.PortfolioSliceChildrenTableQueryVariables>(PortfolioSliceChildrenTableDocument, options);
      }
export function usePortfolioSliceChildrenTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PortfolioSliceChildrenTableQuery, Types.PortfolioSliceChildrenTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PortfolioSliceChildrenTableQuery, Types.PortfolioSliceChildrenTableQueryVariables>(PortfolioSliceChildrenTableDocument, options);
        }
export function usePortfolioSliceChildrenTableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PortfolioSliceChildrenTableQuery, Types.PortfolioSliceChildrenTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PortfolioSliceChildrenTableQuery, Types.PortfolioSliceChildrenTableQueryVariables>(PortfolioSliceChildrenTableDocument, options);
        }
export type PortfolioSliceChildrenTableQueryHookResult = ReturnType<typeof usePortfolioSliceChildrenTableQuery>;
export type PortfolioSliceChildrenTableLazyQueryHookResult = ReturnType<typeof usePortfolioSliceChildrenTableLazyQuery>;
export type PortfolioSliceChildrenTableSuspenseQueryHookResult = ReturnType<typeof usePortfolioSliceChildrenTableSuspenseQuery>;
export type PortfolioSliceChildrenTableQueryResult = Apollo.QueryResult<Types.PortfolioSliceChildrenTableQuery, Types.PortfolioSliceChildrenTableQueryVariables>;
export const PositionDetailsRendererDocument = gql`
    query PositionDetailsRenderer($positionId: ID!) {
  position: node(id: $positionId) {
    ...PositionDetails
  }
}
    ${PositionDetailsFragmentDoc}`;

/**
 * __usePositionDetailsRendererQuery__
 *
 * To run a query within a React component, call `usePositionDetailsRendererQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionDetailsRendererQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionDetailsRendererQuery({
 *   variables: {
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function usePositionDetailsRendererQuery(baseOptions: Apollo.QueryHookOptions<Types.PositionDetailsRendererQuery, Types.PositionDetailsRendererQueryVariables> & ({ variables: Types.PositionDetailsRendererQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PositionDetailsRendererQuery, Types.PositionDetailsRendererQueryVariables>(PositionDetailsRendererDocument, options);
      }
export function usePositionDetailsRendererLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PositionDetailsRendererQuery, Types.PositionDetailsRendererQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PositionDetailsRendererQuery, Types.PositionDetailsRendererQueryVariables>(PositionDetailsRendererDocument, options);
        }
export function usePositionDetailsRendererSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PositionDetailsRendererQuery, Types.PositionDetailsRendererQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PositionDetailsRendererQuery, Types.PositionDetailsRendererQueryVariables>(PositionDetailsRendererDocument, options);
        }
export type PositionDetailsRendererQueryHookResult = ReturnType<typeof usePositionDetailsRendererQuery>;
export type PositionDetailsRendererLazyQueryHookResult = ReturnType<typeof usePositionDetailsRendererLazyQuery>;
export type PositionDetailsRendererSuspenseQueryHookResult = ReturnType<typeof usePositionDetailsRendererSuspenseQuery>;
export type PositionDetailsRendererQueryResult = Apollo.QueryResult<Types.PositionDetailsRendererQuery, Types.PositionDetailsRendererQueryVariables>;
export const SecurityMiniChartIntradayDocument = gql`
    query SecurityMiniChartIntraday($securityId: ID!, $period: IntradayQuotePeriodEnum, $interval: IntradayQuoteIntervalEnum) {
  node(id: $securityId) {
    ...HistoricalQuotesNode
  }
}
    ${HistoricalQuotesNodeFragmentDoc}`;

/**
 * __useSecurityMiniChartIntradayQuery__
 *
 * To run a query within a React component, call `useSecurityMiniChartIntradayQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecurityMiniChartIntradayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecurityMiniChartIntradayQuery({
 *   variables: {
 *      securityId: // value for 'securityId'
 *      period: // value for 'period'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useSecurityMiniChartIntradayQuery(baseOptions: Apollo.QueryHookOptions<Types.SecurityMiniChartIntradayQuery, Types.SecurityMiniChartIntradayQueryVariables> & ({ variables: Types.SecurityMiniChartIntradayQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SecurityMiniChartIntradayQuery, Types.SecurityMiniChartIntradayQueryVariables>(SecurityMiniChartIntradayDocument, options);
      }
export function useSecurityMiniChartIntradayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SecurityMiniChartIntradayQuery, Types.SecurityMiniChartIntradayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SecurityMiniChartIntradayQuery, Types.SecurityMiniChartIntradayQueryVariables>(SecurityMiniChartIntradayDocument, options);
        }
export function useSecurityMiniChartIntradaySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SecurityMiniChartIntradayQuery, Types.SecurityMiniChartIntradayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SecurityMiniChartIntradayQuery, Types.SecurityMiniChartIntradayQueryVariables>(SecurityMiniChartIntradayDocument, options);
        }
export type SecurityMiniChartIntradayQueryHookResult = ReturnType<typeof useSecurityMiniChartIntradayQuery>;
export type SecurityMiniChartIntradayLazyQueryHookResult = ReturnType<typeof useSecurityMiniChartIntradayLazyQuery>;
export type SecurityMiniChartIntradaySuspenseQueryHookResult = ReturnType<typeof useSecurityMiniChartIntradaySuspenseQuery>;
export type SecurityMiniChartIntradayQueryResult = Apollo.QueryResult<Types.SecurityMiniChartIntradayQuery, Types.SecurityMiniChartIntradayQueryVariables>;
export const SelectInvestAccountModalDocument = gql`
    query SelectInvestAccountModal($sliceableId: ID!) {
  viewer {
    ... on Viewer {
      accounts(first: 20) {
        edges {
          node {
            ...InvestAccountModalAccountNode
          }
        }
      }
    }
  }
  sliceable: node(id: $sliceableId) {
    ...InvestAccountModalSliceableNode
  }
}
    ${InvestAccountModalAccountNodeFragmentDoc}
${InvestAccountModalSliceableNodeFragmentDoc}`;

/**
 * __useSelectInvestAccountModalQuery__
 *
 * To run a query within a React component, call `useSelectInvestAccountModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectInvestAccountModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectInvestAccountModalQuery({
 *   variables: {
 *      sliceableId: // value for 'sliceableId'
 *   },
 * });
 */
export function useSelectInvestAccountModalQuery(baseOptions: Apollo.QueryHookOptions<Types.SelectInvestAccountModalQuery, Types.SelectInvestAccountModalQueryVariables> & ({ variables: Types.SelectInvestAccountModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SelectInvestAccountModalQuery, Types.SelectInvestAccountModalQueryVariables>(SelectInvestAccountModalDocument, options);
      }
export function useSelectInvestAccountModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SelectInvestAccountModalQuery, Types.SelectInvestAccountModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SelectInvestAccountModalQuery, Types.SelectInvestAccountModalQueryVariables>(SelectInvestAccountModalDocument, options);
        }
export function useSelectInvestAccountModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SelectInvestAccountModalQuery, Types.SelectInvestAccountModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SelectInvestAccountModalQuery, Types.SelectInvestAccountModalQueryVariables>(SelectInvestAccountModalDocument, options);
        }
export type SelectInvestAccountModalQueryHookResult = ReturnType<typeof useSelectInvestAccountModalQuery>;
export type SelectInvestAccountModalLazyQueryHookResult = ReturnType<typeof useSelectInvestAccountModalLazyQuery>;
export type SelectInvestAccountModalSuspenseQueryHookResult = ReturnType<typeof useSelectInvestAccountModalSuspenseQuery>;
export type SelectInvestAccountModalQueryResult = Apollo.QueryResult<Types.SelectInvestAccountModalQuery, Types.SelectInvestAccountModalQueryVariables>;
export const SetOrderCryptoConfirmationQueryDocument = gql`
    query SetOrderCryptoConfirmationQuery($id: ID!) {
  account: node(id: $id) {
    id
    ... on ChildPortfolioSlice {
      to {
        name
      }
      ...CryptoAccountCurrencies
    }
    ... on RootPortfolioSlice {
      to {
        name
      }
      ...CryptoAccountCurrencies
    }
  }
}
    ${CryptoAccountCurrenciesFragmentDoc}`;

/**
 * __useSetOrderCryptoConfirmationQueryQuery__
 *
 * To run a query within a React component, call `useSetOrderCryptoConfirmationQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetOrderCryptoConfirmationQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetOrderCryptoConfirmationQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetOrderCryptoConfirmationQueryQuery(baseOptions: Apollo.QueryHookOptions<Types.SetOrderCryptoConfirmationQueryQuery, Types.SetOrderCryptoConfirmationQueryQueryVariables> & ({ variables: Types.SetOrderCryptoConfirmationQueryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SetOrderCryptoConfirmationQueryQuery, Types.SetOrderCryptoConfirmationQueryQueryVariables>(SetOrderCryptoConfirmationQueryDocument, options);
      }
export function useSetOrderCryptoConfirmationQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SetOrderCryptoConfirmationQueryQuery, Types.SetOrderCryptoConfirmationQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SetOrderCryptoConfirmationQueryQuery, Types.SetOrderCryptoConfirmationQueryQueryVariables>(SetOrderCryptoConfirmationQueryDocument, options);
        }
export function useSetOrderCryptoConfirmationQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SetOrderCryptoConfirmationQueryQuery, Types.SetOrderCryptoConfirmationQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SetOrderCryptoConfirmationQueryQuery, Types.SetOrderCryptoConfirmationQueryQueryVariables>(SetOrderCryptoConfirmationQueryDocument, options);
        }
export type SetOrderCryptoConfirmationQueryQueryHookResult = ReturnType<typeof useSetOrderCryptoConfirmationQueryQuery>;
export type SetOrderCryptoConfirmationQueryLazyQueryHookResult = ReturnType<typeof useSetOrderCryptoConfirmationQueryLazyQuery>;
export type SetOrderCryptoConfirmationQuerySuspenseQueryHookResult = ReturnType<typeof useSetOrderCryptoConfirmationQuerySuspenseQuery>;
export type SetOrderCryptoConfirmationQueryQueryResult = Apollo.QueryResult<Types.SetOrderCryptoConfirmationQueryQuery, Types.SetOrderCryptoConfirmationQueryQueryVariables>;
export const TradingPausedDocument = gql`
    query TradingPaused($accountId: ID!) {
  node(id: $accountId) {
    ... on Account {
      __typename
      isTradingPaused
      tradingPause {
        ...TradingPause
      }
    }
  }
  viewer {
    user {
      isPrimaryEmailVerified
    }
  }
}
    ${TradingPauseFragmentDoc}`;

/**
 * __useTradingPausedQuery__
 *
 * To run a query within a React component, call `useTradingPausedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradingPausedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradingPausedQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useTradingPausedQuery(baseOptions: Apollo.QueryHookOptions<Types.TradingPausedQuery, Types.TradingPausedQueryVariables> & ({ variables: Types.TradingPausedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradingPausedQuery, Types.TradingPausedQueryVariables>(TradingPausedDocument, options);
      }
export function useTradingPausedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradingPausedQuery, Types.TradingPausedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradingPausedQuery, Types.TradingPausedQueryVariables>(TradingPausedDocument, options);
        }
export function useTradingPausedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TradingPausedQuery, Types.TradingPausedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TradingPausedQuery, Types.TradingPausedQueryVariables>(TradingPausedDocument, options);
        }
export type TradingPausedQueryHookResult = ReturnType<typeof useTradingPausedQuery>;
export type TradingPausedLazyQueryHookResult = ReturnType<typeof useTradingPausedLazyQuery>;
export type TradingPausedSuspenseQueryHookResult = ReturnType<typeof useTradingPausedSuspenseQuery>;
export type TradingPausedQueryResult = Apollo.QueryResult<Types.TradingPausedQuery, Types.TradingPausedQueryVariables>;
export const UnmanagedHoldingsPageDocument = gql`
    query UnmanagedHoldingsPage($accountId: ID!, $first: Int!, $after: String) {
  node(id: $accountId) {
    ... on Account {
      receivedSecurities {
        unmanagedHoldingsScreen {
          title
          hasUnmanagedHoldings
          unmanagedHoldingsCollections {
            ... on UnmanagedHoldingsCollection {
              id
              type
              holdings(first: $first, after: $after) {
                total
                pageInfo {
                  hasNextPage
                  startCursor
                  endCursor
                }
                edges {
                  cursor
                  node {
                    id
                    isPendingLiquidation
                  }
                }
              }
              header
              tooltip {
                text
              }
            }
          }
          bottomDisclaimer
          ...UnmanagedHoldingSummaryCard
        }
      }
      trading {
        isInProgress
      }
      number
      ... on Account {
        number
        receivedSecurities {
          unmanagedHoldingsScreen {
            contactUs {
              ...Linkable
            }
          }
          ... on ReceivedSecurities {
            moveSecurities {
              title
              header
              subheader
              tooltip {
                text
              }
            }
            moveSecuritiesConfirmTargets {
              ... on MoveSecuritiesConfirmTargets {
                title
                header
                link {
                  title
                  url
                }
                saveTargetsCtaLabel
              }
            }
          }
        }
      }
    }
  }
}
    ${UnmanagedHoldingSummaryCardFragmentDoc}
${LinkableFragmentDoc}`;

/**
 * __useUnmanagedHoldingsPageQuery__
 *
 * To run a query within a React component, call `useUnmanagedHoldingsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnmanagedHoldingsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnmanagedHoldingsPageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUnmanagedHoldingsPageQuery(baseOptions: Apollo.QueryHookOptions<Types.UnmanagedHoldingsPageQuery, Types.UnmanagedHoldingsPageQueryVariables> & ({ variables: Types.UnmanagedHoldingsPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UnmanagedHoldingsPageQuery, Types.UnmanagedHoldingsPageQueryVariables>(UnmanagedHoldingsPageDocument, options);
      }
export function useUnmanagedHoldingsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UnmanagedHoldingsPageQuery, Types.UnmanagedHoldingsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UnmanagedHoldingsPageQuery, Types.UnmanagedHoldingsPageQueryVariables>(UnmanagedHoldingsPageDocument, options);
        }
export function useUnmanagedHoldingsPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UnmanagedHoldingsPageQuery, Types.UnmanagedHoldingsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UnmanagedHoldingsPageQuery, Types.UnmanagedHoldingsPageQueryVariables>(UnmanagedHoldingsPageDocument, options);
        }
export type UnmanagedHoldingsPageQueryHookResult = ReturnType<typeof useUnmanagedHoldingsPageQuery>;
export type UnmanagedHoldingsPageLazyQueryHookResult = ReturnType<typeof useUnmanagedHoldingsPageLazyQuery>;
export type UnmanagedHoldingsPageSuspenseQueryHookResult = ReturnType<typeof useUnmanagedHoldingsPageSuspenseQuery>;
export type UnmanagedHoldingsPageQueryResult = Apollo.QueryResult<Types.UnmanagedHoldingsPageQuery, Types.UnmanagedHoldingsPageQueryVariables>;
export const UnmanagedHoldingsSectionPaginationDocument = gql`
    query UnmanagedHoldingsSectionPagination($collectionId: ID!, $first: Int!, $after: String) {
  unmanagedHoldings: node(id: $collectionId) {
    ...Collection
  }
}
    ${CollectionFragmentDoc}`;

/**
 * __useUnmanagedHoldingsSectionPaginationQuery__
 *
 * To run a query within a React component, call `useUnmanagedHoldingsSectionPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnmanagedHoldingsSectionPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnmanagedHoldingsSectionPaginationQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUnmanagedHoldingsSectionPaginationQuery(baseOptions: Apollo.QueryHookOptions<Types.UnmanagedHoldingsSectionPaginationQuery, Types.UnmanagedHoldingsSectionPaginationQueryVariables> & ({ variables: Types.UnmanagedHoldingsSectionPaginationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UnmanagedHoldingsSectionPaginationQuery, Types.UnmanagedHoldingsSectionPaginationQueryVariables>(UnmanagedHoldingsSectionPaginationDocument, options);
      }
export function useUnmanagedHoldingsSectionPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UnmanagedHoldingsSectionPaginationQuery, Types.UnmanagedHoldingsSectionPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UnmanagedHoldingsSectionPaginationQuery, Types.UnmanagedHoldingsSectionPaginationQueryVariables>(UnmanagedHoldingsSectionPaginationDocument, options);
        }
export function useUnmanagedHoldingsSectionPaginationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UnmanagedHoldingsSectionPaginationQuery, Types.UnmanagedHoldingsSectionPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UnmanagedHoldingsSectionPaginationQuery, Types.UnmanagedHoldingsSectionPaginationQueryVariables>(UnmanagedHoldingsSectionPaginationDocument, options);
        }
export type UnmanagedHoldingsSectionPaginationQueryHookResult = ReturnType<typeof useUnmanagedHoldingsSectionPaginationQuery>;
export type UnmanagedHoldingsSectionPaginationLazyQueryHookResult = ReturnType<typeof useUnmanagedHoldingsSectionPaginationLazyQuery>;
export type UnmanagedHoldingsSectionPaginationSuspenseQueryHookResult = ReturnType<typeof useUnmanagedHoldingsSectionPaginationSuspenseQuery>;
export type UnmanagedHoldingsSectionPaginationQueryResult = Apollo.QueryResult<Types.UnmanagedHoldingsSectionPaginationQuery, Types.UnmanagedHoldingsSectionPaginationQueryVariables>;
export const UnmanagedHoldingsSectionControlsDocument = gql`
    query UnmanagedHoldingsSectionControls($id: ID!) {
  node(id: $id) {
    ...UnmanagedHoldingsSectionControlsAccountNode
  }
}
    ${UnmanagedHoldingsSectionControlsAccountNodeFragmentDoc}`;

/**
 * __useUnmanagedHoldingsSectionControlsQuery__
 *
 * To run a query within a React component, call `useUnmanagedHoldingsSectionControlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnmanagedHoldingsSectionControlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnmanagedHoldingsSectionControlsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnmanagedHoldingsSectionControlsQuery(baseOptions: Apollo.QueryHookOptions<Types.UnmanagedHoldingsSectionControlsQuery, Types.UnmanagedHoldingsSectionControlsQueryVariables> & ({ variables: Types.UnmanagedHoldingsSectionControlsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UnmanagedHoldingsSectionControlsQuery, Types.UnmanagedHoldingsSectionControlsQueryVariables>(UnmanagedHoldingsSectionControlsDocument, options);
      }
export function useUnmanagedHoldingsSectionControlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UnmanagedHoldingsSectionControlsQuery, Types.UnmanagedHoldingsSectionControlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UnmanagedHoldingsSectionControlsQuery, Types.UnmanagedHoldingsSectionControlsQueryVariables>(UnmanagedHoldingsSectionControlsDocument, options);
        }
export function useUnmanagedHoldingsSectionControlsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UnmanagedHoldingsSectionControlsQuery, Types.UnmanagedHoldingsSectionControlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UnmanagedHoldingsSectionControlsQuery, Types.UnmanagedHoldingsSectionControlsQueryVariables>(UnmanagedHoldingsSectionControlsDocument, options);
        }
export type UnmanagedHoldingsSectionControlsQueryHookResult = ReturnType<typeof useUnmanagedHoldingsSectionControlsQuery>;
export type UnmanagedHoldingsSectionControlsLazyQueryHookResult = ReturnType<typeof useUnmanagedHoldingsSectionControlsLazyQuery>;
export type UnmanagedHoldingsSectionControlsSuspenseQueryHookResult = ReturnType<typeof useUnmanagedHoldingsSectionControlsSuspenseQuery>;
export type UnmanagedHoldingsSectionControlsQueryResult = Apollo.QueryResult<Types.UnmanagedHoldingsSectionControlsQuery, Types.UnmanagedHoldingsSectionControlsQueryVariables>;
export const UnmanagedHoldingsSelectParentPiePageDocument = gql`
    query UnmanagedHoldingsSelectParentPiePage($accountId: ID!) {
  node(id: $accountId) {
    ... on Account {
      receivedSecurities {
        ... on ReceivedSecurities {
          moveSecuritiesNewPieLocation {
            title
            validPortfolioSliceDestinations {
              ...ValidSliceDestination
            }
          }
          moveSecuritiesConfirmTargets {
            ...MoveSecuritiesConfirmTargets
          }
        }
      }
    }
  }
}
    ${ValidSliceDestinationFragmentDoc}
${MoveSecuritiesConfirmTargetsFragmentDoc}`;

/**
 * __useUnmanagedHoldingsSelectParentPiePageQuery__
 *
 * To run a query within a React component, call `useUnmanagedHoldingsSelectParentPiePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnmanagedHoldingsSelectParentPiePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnmanagedHoldingsSelectParentPiePageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useUnmanagedHoldingsSelectParentPiePageQuery(baseOptions: Apollo.QueryHookOptions<Types.UnmanagedHoldingsSelectParentPiePageQuery, Types.UnmanagedHoldingsSelectParentPiePageQueryVariables> & ({ variables: Types.UnmanagedHoldingsSelectParentPiePageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UnmanagedHoldingsSelectParentPiePageQuery, Types.UnmanagedHoldingsSelectParentPiePageQueryVariables>(UnmanagedHoldingsSelectParentPiePageDocument, options);
      }
export function useUnmanagedHoldingsSelectParentPiePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UnmanagedHoldingsSelectParentPiePageQuery, Types.UnmanagedHoldingsSelectParentPiePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UnmanagedHoldingsSelectParentPiePageQuery, Types.UnmanagedHoldingsSelectParentPiePageQueryVariables>(UnmanagedHoldingsSelectParentPiePageDocument, options);
        }
export function useUnmanagedHoldingsSelectParentPiePageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UnmanagedHoldingsSelectParentPiePageQuery, Types.UnmanagedHoldingsSelectParentPiePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UnmanagedHoldingsSelectParentPiePageQuery, Types.UnmanagedHoldingsSelectParentPiePageQueryVariables>(UnmanagedHoldingsSelectParentPiePageDocument, options);
        }
export type UnmanagedHoldingsSelectParentPiePageQueryHookResult = ReturnType<typeof useUnmanagedHoldingsSelectParentPiePageQuery>;
export type UnmanagedHoldingsSelectParentPiePageLazyQueryHookResult = ReturnType<typeof useUnmanagedHoldingsSelectParentPiePageLazyQuery>;
export type UnmanagedHoldingsSelectParentPiePageSuspenseQueryHookResult = ReturnType<typeof useUnmanagedHoldingsSelectParentPiePageSuspenseQuery>;
export type UnmanagedHoldingsSelectParentPiePageQueryResult = Apollo.QueryResult<Types.UnmanagedHoldingsSelectParentPiePageQuery, Types.UnmanagedHoldingsSelectParentPiePageQueryVariables>;
export const UpcomingActivityDocument = gql`
    query UpcomingActivity($accountId: ID!) {
  node(id: $accountId) {
    ... on Account {
      __typename
      id
      isTradingPaused
      trading {
        ...TradingStatus
      }
      estimatedTrading {
        ...EstimatedTrades
      }
      tradingPause {
        ...TradingPause
      }
    }
  }
  viewer {
    user {
      isPrimaryEmailVerified
    }
  }
}
    ${TradingStatusFragmentDoc}
${EstimatedTradesFragmentDoc}
${TradingPauseFragmentDoc}`;

/**
 * __useUpcomingActivityQuery__
 *
 * To run a query within a React component, call `useUpcomingActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingActivityQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useUpcomingActivityQuery(baseOptions: Apollo.QueryHookOptions<Types.UpcomingActivityQuery, Types.UpcomingActivityQueryVariables> & ({ variables: Types.UpcomingActivityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UpcomingActivityQuery, Types.UpcomingActivityQueryVariables>(UpcomingActivityDocument, options);
      }
export function useUpcomingActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UpcomingActivityQuery, Types.UpcomingActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UpcomingActivityQuery, Types.UpcomingActivityQueryVariables>(UpcomingActivityDocument, options);
        }
export function useUpcomingActivitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UpcomingActivityQuery, Types.UpcomingActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UpcomingActivityQuery, Types.UpcomingActivityQueryVariables>(UpcomingActivityDocument, options);
        }
export type UpcomingActivityQueryHookResult = ReturnType<typeof useUpcomingActivityQuery>;
export type UpcomingActivityLazyQueryHookResult = ReturnType<typeof useUpcomingActivityLazyQuery>;
export type UpcomingActivitySuspenseQueryHookResult = ReturnType<typeof useUpcomingActivitySuspenseQuery>;
export type UpcomingActivityQueryResult = Apollo.QueryResult<Types.UpcomingActivityQuery, Types.UpcomingActivityQueryVariables>;
export const IraAccountTypeDocument = gql`
    query IRAAccountType {
  viewer {
    onboarding {
      onboardingInvest {
        iraOnboarding {
          iraAccountTypeSelectionScreen {
            accountTypes {
              ...ListItem
            }
            disclosures
            helpTitle
            learnMoreLink {
              ...Linkable
            }
            screenViewedAnalyticsEvent {
              ...AnalyticsEvent
            }
            title
          }
        }
      }
    }
  }
}
    ${ListItemFragmentDoc}
${LinkableFragmentDoc}
${AnalyticsEventFragmentDoc}`;

/**
 * __useIraAccountTypeQuery__
 *
 * To run a query within a React component, call `useIraAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIraAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIraAccountTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useIraAccountTypeQuery(baseOptions?: Apollo.QueryHookOptions<Types.IraAccountTypeQuery, Types.IraAccountTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IraAccountTypeQuery, Types.IraAccountTypeQueryVariables>(IraAccountTypeDocument, options);
      }
export function useIraAccountTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IraAccountTypeQuery, Types.IraAccountTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IraAccountTypeQuery, Types.IraAccountTypeQueryVariables>(IraAccountTypeDocument, options);
        }
export function useIraAccountTypeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.IraAccountTypeQuery, Types.IraAccountTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IraAccountTypeQuery, Types.IraAccountTypeQueryVariables>(IraAccountTypeDocument, options);
        }
export type IraAccountTypeQueryHookResult = ReturnType<typeof useIraAccountTypeQuery>;
export type IraAccountTypeLazyQueryHookResult = ReturnType<typeof useIraAccountTypeLazyQuery>;
export type IraAccountTypeSuspenseQueryHookResult = ReturnType<typeof useIraAccountTypeSuspenseQuery>;
export type IraAccountTypeQueryResult = Apollo.QueryResult<Types.IraAccountTypeQuery, Types.IraAccountTypeQueryVariables>;
export const IraFundingTypeDocument = gql`
    query IRAFundingType {
  viewer {
    onboarding {
      onboardingInvest {
        iraOnboarding {
          iraFundingSelectionScreen {
            title
            fundingTypes {
              ...ListItem
            }
            screenViewedAnalyticsEvent {
              ...AnalyticsEvent
            }
          }
        }
      }
    }
  }
}
    ${ListItemFragmentDoc}
${AnalyticsEventFragmentDoc}`;

/**
 * __useIraFundingTypeQuery__
 *
 * To run a query within a React component, call `useIraFundingTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIraFundingTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIraFundingTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useIraFundingTypeQuery(baseOptions?: Apollo.QueryHookOptions<Types.IraFundingTypeQuery, Types.IraFundingTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IraFundingTypeQuery, Types.IraFundingTypeQueryVariables>(IraFundingTypeDocument, options);
      }
export function useIraFundingTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IraFundingTypeQuery, Types.IraFundingTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IraFundingTypeQuery, Types.IraFundingTypeQueryVariables>(IraFundingTypeDocument, options);
        }
export function useIraFundingTypeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.IraFundingTypeQuery, Types.IraFundingTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IraFundingTypeQuery, Types.IraFundingTypeQueryVariables>(IraFundingTypeDocument, options);
        }
export type IraFundingTypeQueryHookResult = ReturnType<typeof useIraFundingTypeQuery>;
export type IraFundingTypeLazyQueryHookResult = ReturnType<typeof useIraFundingTypeLazyQuery>;
export type IraFundingTypeSuspenseQueryHookResult = ReturnType<typeof useIraFundingTypeSuspenseQuery>;
export type IraFundingTypeQueryResult = Apollo.QueryResult<Types.IraFundingTypeQuery, Types.IraFundingTypeQueryVariables>;
export const IraRolloverTypeDocument = gql`
    query IRARolloverType {
  viewer {
    onboarding {
      onboardingInvest {
        iraOnboarding {
          iraRolloverTypeSelectionScreen {
            disclosures
            helpSection {
              title
              copy
            }
            learnMoreLink {
              ...Linkable
            }
            rolloverTypes {
              ...ListItem
            }
            title
            subtitle
            screenViewedAnalyticsEvent {
              ...AnalyticsEvent
            }
          }
        }
      }
    }
  }
}
    ${LinkableFragmentDoc}
${ListItemFragmentDoc}
${AnalyticsEventFragmentDoc}`;

/**
 * __useIraRolloverTypeQuery__
 *
 * To run a query within a React component, call `useIraRolloverTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIraRolloverTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIraRolloverTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useIraRolloverTypeQuery(baseOptions?: Apollo.QueryHookOptions<Types.IraRolloverTypeQuery, Types.IraRolloverTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IraRolloverTypeQuery, Types.IraRolloverTypeQueryVariables>(IraRolloverTypeDocument, options);
      }
export function useIraRolloverTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IraRolloverTypeQuery, Types.IraRolloverTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IraRolloverTypeQuery, Types.IraRolloverTypeQueryVariables>(IraRolloverTypeDocument, options);
        }
export function useIraRolloverTypeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.IraRolloverTypeQuery, Types.IraRolloverTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IraRolloverTypeQuery, Types.IraRolloverTypeQueryVariables>(IraRolloverTypeDocument, options);
        }
export type IraRolloverTypeQueryHookResult = ReturnType<typeof useIraRolloverTypeQuery>;
export type IraRolloverTypeLazyQueryHookResult = ReturnType<typeof useIraRolloverTypeLazyQuery>;
export type IraRolloverTypeSuspenseQueryHookResult = ReturnType<typeof useIraRolloverTypeSuspenseQuery>;
export type IraRolloverTypeQueryResult = Apollo.QueryResult<Types.IraRolloverTypeQuery, Types.IraRolloverTypeQueryVariables>;
export const LoanInformationDocument = gql`
    query LoanInformation {
  viewer {
    borrow {
      personalLoans {
        applicationFlow {
          loanPurposeOptions {
            edges {
              node {
                title
                value
              }
            }
          }
          loanMinAndMaxDetails {
            minAmount
            maxAmount
            rangeDescription
          }
          loanTermOptions {
            value
            description
          }
        }
      }
    }
  }
}
    `;

/**
 * __useLoanInformationQuery__
 *
 * To run a query within a React component, call `useLoanInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoanInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoanInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoanInformationQuery(baseOptions?: Apollo.QueryHookOptions<Types.LoanInformationQuery, Types.LoanInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LoanInformationQuery, Types.LoanInformationQueryVariables>(LoanInformationDocument, options);
      }
export function useLoanInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LoanInformationQuery, Types.LoanInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LoanInformationQuery, Types.LoanInformationQueryVariables>(LoanInformationDocument, options);
        }
export function useLoanInformationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LoanInformationQuery, Types.LoanInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LoanInformationQuery, Types.LoanInformationQueryVariables>(LoanInformationDocument, options);
        }
export type LoanInformationQueryHookResult = ReturnType<typeof useLoanInformationQuery>;
export type LoanInformationLazyQueryHookResult = ReturnType<typeof useLoanInformationLazyQuery>;
export type LoanInformationSuspenseQueryHookResult = ReturnType<typeof useLoanInformationSuspenseQuery>;
export type LoanInformationQueryResult = Apollo.QueryResult<Types.LoanInformationQuery, Types.LoanInformationQueryVariables>;
export const LoanOffersAndSubmitPageDocument = gql`
    query LoanOffersAndSubmitPage {
  viewer {
    borrow {
      personalLoans {
        activeApplication {
          id
          applicationId
          creditCheckDisclaimer
          loanDisclosure
          applicationOffers {
            edges {
              node {
                amount
                formattedAmount
                id
                annualPercentageRate
                formattedAnnualPercentageInfo {
                  formattedValue
                }
                monthlyPayment
                formattedMonthlyPayment
                formattedMonthlyDuration
                monthlyDuration
                offerSubtitle
                offerTitle
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useLoanOffersAndSubmitPageQuery__
 *
 * To run a query within a React component, call `useLoanOffersAndSubmitPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoanOffersAndSubmitPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoanOffersAndSubmitPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoanOffersAndSubmitPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.LoanOffersAndSubmitPageQuery, Types.LoanOffersAndSubmitPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LoanOffersAndSubmitPageQuery, Types.LoanOffersAndSubmitPageQueryVariables>(LoanOffersAndSubmitPageDocument, options);
      }
export function useLoanOffersAndSubmitPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LoanOffersAndSubmitPageQuery, Types.LoanOffersAndSubmitPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LoanOffersAndSubmitPageQuery, Types.LoanOffersAndSubmitPageQueryVariables>(LoanOffersAndSubmitPageDocument, options);
        }
export function useLoanOffersAndSubmitPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LoanOffersAndSubmitPageQuery, Types.LoanOffersAndSubmitPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LoanOffersAndSubmitPageQuery, Types.LoanOffersAndSubmitPageQueryVariables>(LoanOffersAndSubmitPageDocument, options);
        }
export type LoanOffersAndSubmitPageQueryHookResult = ReturnType<typeof useLoanOffersAndSubmitPageQuery>;
export type LoanOffersAndSubmitPageLazyQueryHookResult = ReturnType<typeof useLoanOffersAndSubmitPageLazyQuery>;
export type LoanOffersAndSubmitPageSuspenseQueryHookResult = ReturnType<typeof useLoanOffersAndSubmitPageSuspenseQuery>;
export type LoanOffersAndSubmitPageQueryResult = Apollo.QueryResult<Types.LoanOffersAndSubmitPageQuery, Types.LoanOffersAndSubmitPageQueryVariables>;
export const LoanTermsAndAcceptPageDocument = gql`
    query LoanTermsAndAcceptPage($applicationId: ID!) {
  viewer {
    user {
      isPrimaryEmailVerified
    }
    borrow {
      personalLoans {
        application(applicationId: $applicationId) {
          status {
            status
          }
          loan {
            id
          }
        }
        applicationFlow {
          id
          viewLoanTerms {
            dynamicLoanAgreementPresignedUrl {
              ...Linkable
            }
            title
            subtitle
            viewLoanDocumentsHeader
            notViewedErrorMessage {
              copy
            }
            loanDetails {
              title
              subtitle
              value
            }
            additionalTerms {
              documents {
                title
                url
              }
              signature
            }
            viewedPill {
              kind
              label
            }
            notViewedPill {
              kind
              label
            }
            paymentSchedule {
              label
              value
            }
          }
        }
      }
    }
  }
}
    ${LinkableFragmentDoc}`;

/**
 * __useLoanTermsAndAcceptPageQuery__
 *
 * To run a query within a React component, call `useLoanTermsAndAcceptPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoanTermsAndAcceptPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoanTermsAndAcceptPageQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useLoanTermsAndAcceptPageQuery(baseOptions: Apollo.QueryHookOptions<Types.LoanTermsAndAcceptPageQuery, Types.LoanTermsAndAcceptPageQueryVariables> & ({ variables: Types.LoanTermsAndAcceptPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LoanTermsAndAcceptPageQuery, Types.LoanTermsAndAcceptPageQueryVariables>(LoanTermsAndAcceptPageDocument, options);
      }
export function useLoanTermsAndAcceptPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LoanTermsAndAcceptPageQuery, Types.LoanTermsAndAcceptPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LoanTermsAndAcceptPageQuery, Types.LoanTermsAndAcceptPageQueryVariables>(LoanTermsAndAcceptPageDocument, options);
        }
export function useLoanTermsAndAcceptPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LoanTermsAndAcceptPageQuery, Types.LoanTermsAndAcceptPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LoanTermsAndAcceptPageQuery, Types.LoanTermsAndAcceptPageQueryVariables>(LoanTermsAndAcceptPageDocument, options);
        }
export type LoanTermsAndAcceptPageQueryHookResult = ReturnType<typeof useLoanTermsAndAcceptPageQuery>;
export type LoanTermsAndAcceptPageLazyQueryHookResult = ReturnType<typeof useLoanTermsAndAcceptPageLazyQuery>;
export type LoanTermsAndAcceptPageSuspenseQueryHookResult = ReturnType<typeof useLoanTermsAndAcceptPageSuspenseQuery>;
export type LoanTermsAndAcceptPageQueryResult = Apollo.QueryResult<Types.LoanTermsAndAcceptPageQuery, Types.LoanTermsAndAcceptPageQueryVariables>;
export const ManualReviewPageDocument = gql`
    query ManualReviewPage {
  viewer {
    borrow {
      personalLoans {
        applicationFlow {
          loanManualReviewScreen {
            title
            content
            ctaLink {
              ...Linkable
            }
            illustration {
              ...AppImage
            }
          }
        }
      }
    }
  }
}
    ${LinkableFragmentDoc}
${AppImageFragmentDoc}`;

/**
 * __useManualReviewPageQuery__
 *
 * To run a query within a React component, call `useManualReviewPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useManualReviewPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManualReviewPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useManualReviewPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.ManualReviewPageQuery, Types.ManualReviewPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ManualReviewPageQuery, Types.ManualReviewPageQueryVariables>(ManualReviewPageDocument, options);
      }
export function useManualReviewPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ManualReviewPageQuery, Types.ManualReviewPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ManualReviewPageQuery, Types.ManualReviewPageQueryVariables>(ManualReviewPageDocument, options);
        }
export function useManualReviewPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ManualReviewPageQuery, Types.ManualReviewPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ManualReviewPageQuery, Types.ManualReviewPageQueryVariables>(ManualReviewPageDocument, options);
        }
export type ManualReviewPageQueryHookResult = ReturnType<typeof useManualReviewPageQuery>;
export type ManualReviewPageLazyQueryHookResult = ReturnType<typeof useManualReviewPageLazyQuery>;
export type ManualReviewPageSuspenseQueryHookResult = ReturnType<typeof useManualReviewPageSuspenseQuery>;
export type ManualReviewPageQueryResult = Apollo.QueryResult<Types.ManualReviewPageQuery, Types.ManualReviewPageQueryVariables>;
export const PersonalLoansLandingPageDocument = gql`
    query PersonalLoansLandingPage {
  viewer {
    borrow {
      personalLoans {
        applicationFlow {
          optionalDocuments {
            documents {
              title
              url
            }
            signature
          }
          requiredDocuments {
            documents {
              title
              url
            }
            signature
          }
          landingPage {
            loanDisclaimer
            bulletPoints {
              copy
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePersonalLoansLandingPageQuery__
 *
 * To run a query within a React component, call `usePersonalLoansLandingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalLoansLandingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalLoansLandingPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalLoansLandingPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.PersonalLoansLandingPageQuery, Types.PersonalLoansLandingPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonalLoansLandingPageQuery, Types.PersonalLoansLandingPageQueryVariables>(PersonalLoansLandingPageDocument, options);
      }
export function usePersonalLoansLandingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonalLoansLandingPageQuery, Types.PersonalLoansLandingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonalLoansLandingPageQuery, Types.PersonalLoansLandingPageQueryVariables>(PersonalLoansLandingPageDocument, options);
        }
export function usePersonalLoansLandingPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PersonalLoansLandingPageQuery, Types.PersonalLoansLandingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonalLoansLandingPageQuery, Types.PersonalLoansLandingPageQueryVariables>(PersonalLoansLandingPageDocument, options);
        }
export type PersonalLoansLandingPageQueryHookResult = ReturnType<typeof usePersonalLoansLandingPageQuery>;
export type PersonalLoansLandingPageLazyQueryHookResult = ReturnType<typeof usePersonalLoansLandingPageLazyQuery>;
export type PersonalLoansLandingPageSuspenseQueryHookResult = ReturnType<typeof usePersonalLoansLandingPageSuspenseQuery>;
export type PersonalLoansLandingPageQueryResult = Apollo.QueryResult<Types.PersonalLoansLandingPageQuery, Types.PersonalLoansLandingPageQueryVariables>;
export const PersonalLoansNotEligiblePageDocument = gql`
    query PersonalLoansNotEligiblePage {
  viewer {
    borrow {
      personalLoans {
        applicationFlow {
          loanIneligibilityScreen {
            title
            subtitle
            eligibilityCriteriaLink {
              ...Linkable
            }
          }
        }
      }
    }
  }
}
    ${LinkableFragmentDoc}`;

/**
 * __usePersonalLoansNotEligiblePageQuery__
 *
 * To run a query within a React component, call `usePersonalLoansNotEligiblePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalLoansNotEligiblePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalLoansNotEligiblePageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalLoansNotEligiblePageQuery(baseOptions?: Apollo.QueryHookOptions<Types.PersonalLoansNotEligiblePageQuery, Types.PersonalLoansNotEligiblePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonalLoansNotEligiblePageQuery, Types.PersonalLoansNotEligiblePageQueryVariables>(PersonalLoansNotEligiblePageDocument, options);
      }
export function usePersonalLoansNotEligiblePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonalLoansNotEligiblePageQuery, Types.PersonalLoansNotEligiblePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonalLoansNotEligiblePageQuery, Types.PersonalLoansNotEligiblePageQueryVariables>(PersonalLoansNotEligiblePageDocument, options);
        }
export function usePersonalLoansNotEligiblePageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PersonalLoansNotEligiblePageQuery, Types.PersonalLoansNotEligiblePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonalLoansNotEligiblePageQuery, Types.PersonalLoansNotEligiblePageQueryVariables>(PersonalLoansNotEligiblePageDocument, options);
        }
export type PersonalLoansNotEligiblePageQueryHookResult = ReturnType<typeof usePersonalLoansNotEligiblePageQuery>;
export type PersonalLoansNotEligiblePageLazyQueryHookResult = ReturnType<typeof usePersonalLoansNotEligiblePageLazyQuery>;
export type PersonalLoansNotEligiblePageSuspenseQueryHookResult = ReturnType<typeof usePersonalLoansNotEligiblePageSuspenseQuery>;
export type PersonalLoansNotEligiblePageQueryResult = Apollo.QueryResult<Types.PersonalLoansNotEligiblePageQuery, Types.PersonalLoansNotEligiblePageQueryVariables>;
export const PersonalLoansPageDocument = gql`
    query PersonalLoansPage {
  viewer {
    borrow {
      hasActivePersonalLoan
      isEligibleToApplyForPersonalLoan
    }
  }
}
    `;

/**
 * __usePersonalLoansPageQuery__
 *
 * To run a query within a React component, call `usePersonalLoansPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalLoansPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalLoansPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalLoansPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.PersonalLoansPageQuery, Types.PersonalLoansPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonalLoansPageQuery, Types.PersonalLoansPageQueryVariables>(PersonalLoansPageDocument, options);
      }
export function usePersonalLoansPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonalLoansPageQuery, Types.PersonalLoansPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonalLoansPageQuery, Types.PersonalLoansPageQueryVariables>(PersonalLoansPageDocument, options);
        }
export function usePersonalLoansPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PersonalLoansPageQuery, Types.PersonalLoansPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonalLoansPageQuery, Types.PersonalLoansPageQueryVariables>(PersonalLoansPageDocument, options);
        }
export type PersonalLoansPageQueryHookResult = ReturnType<typeof usePersonalLoansPageQuery>;
export type PersonalLoansPageLazyQueryHookResult = ReturnType<typeof usePersonalLoansPageLazyQuery>;
export type PersonalLoansPageSuspenseQueryHookResult = ReturnType<typeof usePersonalLoansPageSuspenseQuery>;
export type PersonalLoansPageQueryResult = Apollo.QueryResult<Types.PersonalLoansPageQuery, Types.PersonalLoansPageQueryVariables>;
export const PersonalLoansRequiredDisclosuresDocument = gql`
    query PersonalLoansRequiredDisclosures {
  viewer {
    borrow {
      personalLoans {
        applicationFlow {
          requiredDisclosures {
            documents {
              title
              url
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePersonalLoansRequiredDisclosuresQuery__
 *
 * To run a query within a React component, call `usePersonalLoansRequiredDisclosuresQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalLoansRequiredDisclosuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalLoansRequiredDisclosuresQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalLoansRequiredDisclosuresQuery(baseOptions?: Apollo.QueryHookOptions<Types.PersonalLoansRequiredDisclosuresQuery, Types.PersonalLoansRequiredDisclosuresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonalLoansRequiredDisclosuresQuery, Types.PersonalLoansRequiredDisclosuresQueryVariables>(PersonalLoansRequiredDisclosuresDocument, options);
      }
export function usePersonalLoansRequiredDisclosuresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonalLoansRequiredDisclosuresQuery, Types.PersonalLoansRequiredDisclosuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonalLoansRequiredDisclosuresQuery, Types.PersonalLoansRequiredDisclosuresQueryVariables>(PersonalLoansRequiredDisclosuresDocument, options);
        }
export function usePersonalLoansRequiredDisclosuresSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PersonalLoansRequiredDisclosuresQuery, Types.PersonalLoansRequiredDisclosuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonalLoansRequiredDisclosuresQuery, Types.PersonalLoansRequiredDisclosuresQueryVariables>(PersonalLoansRequiredDisclosuresDocument, options);
        }
export type PersonalLoansRequiredDisclosuresQueryHookResult = ReturnType<typeof usePersonalLoansRequiredDisclosuresQuery>;
export type PersonalLoansRequiredDisclosuresLazyQueryHookResult = ReturnType<typeof usePersonalLoansRequiredDisclosuresLazyQuery>;
export type PersonalLoansRequiredDisclosuresSuspenseQueryHookResult = ReturnType<typeof usePersonalLoansRequiredDisclosuresSuspenseQuery>;
export type PersonalLoansRequiredDisclosuresQueryResult = Apollo.QueryResult<Types.PersonalLoansRequiredDisclosuresQuery, Types.PersonalLoansRequiredDisclosuresQueryVariables>;
export const PersonalLoansShouldAskForIncomeDocument = gql`
    query PersonalLoansShouldAskForIncome {
  viewer {
    borrow {
      personalLoans {
        applicationFlow {
          shouldAskForAnnualIncome
        }
      }
    }
  }
}
    `;

/**
 * __usePersonalLoansShouldAskForIncomeQuery__
 *
 * To run a query within a React component, call `usePersonalLoansShouldAskForIncomeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalLoansShouldAskForIncomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalLoansShouldAskForIncomeQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalLoansShouldAskForIncomeQuery(baseOptions?: Apollo.QueryHookOptions<Types.PersonalLoansShouldAskForIncomeQuery, Types.PersonalLoansShouldAskForIncomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonalLoansShouldAskForIncomeQuery, Types.PersonalLoansShouldAskForIncomeQueryVariables>(PersonalLoansShouldAskForIncomeDocument, options);
      }
export function usePersonalLoansShouldAskForIncomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonalLoansShouldAskForIncomeQuery, Types.PersonalLoansShouldAskForIncomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonalLoansShouldAskForIncomeQuery, Types.PersonalLoansShouldAskForIncomeQueryVariables>(PersonalLoansShouldAskForIncomeDocument, options);
        }
export function usePersonalLoansShouldAskForIncomeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PersonalLoansShouldAskForIncomeQuery, Types.PersonalLoansShouldAskForIncomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonalLoansShouldAskForIncomeQuery, Types.PersonalLoansShouldAskForIncomeQueryVariables>(PersonalLoansShouldAskForIncomeDocument, options);
        }
export type PersonalLoansShouldAskForIncomeQueryHookResult = ReturnType<typeof usePersonalLoansShouldAskForIncomeQuery>;
export type PersonalLoansShouldAskForIncomeLazyQueryHookResult = ReturnType<typeof usePersonalLoansShouldAskForIncomeLazyQuery>;
export type PersonalLoansShouldAskForIncomeSuspenseQueryHookResult = ReturnType<typeof usePersonalLoansShouldAskForIncomeSuspenseQuery>;
export type PersonalLoansShouldAskForIncomeQueryResult = Apollo.QueryResult<Types.PersonalLoansShouldAskForIncomeQuery, Types.PersonalLoansShouldAskForIncomeQueryVariables>;
export const MarginBillDueDocument = gql`
    query MarginBillDue($borrowAccountId: ID!) {
  node(id: $borrowAccountId) {
    ... on BorrowAccount {
      __typename
      id
      billDue {
        ...MarginBillDueDetails
      }
    }
  }
}
    ${MarginBillDueDetailsFragmentDoc}`;

/**
 * __useMarginBillDueQuery__
 *
 * To run a query within a React component, call `useMarginBillDueQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarginBillDueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarginBillDueQuery({
 *   variables: {
 *      borrowAccountId: // value for 'borrowAccountId'
 *   },
 * });
 */
export function useMarginBillDueQuery(baseOptions: Apollo.QueryHookOptions<Types.MarginBillDueQuery, Types.MarginBillDueQueryVariables> & ({ variables: Types.MarginBillDueQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MarginBillDueQuery, Types.MarginBillDueQueryVariables>(MarginBillDueDocument, options);
      }
export function useMarginBillDueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MarginBillDueQuery, Types.MarginBillDueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MarginBillDueQuery, Types.MarginBillDueQueryVariables>(MarginBillDueDocument, options);
        }
export function useMarginBillDueSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MarginBillDueQuery, Types.MarginBillDueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MarginBillDueQuery, Types.MarginBillDueQueryVariables>(MarginBillDueDocument, options);
        }
export type MarginBillDueQueryHookResult = ReturnType<typeof useMarginBillDueQuery>;
export type MarginBillDueLazyQueryHookResult = ReturnType<typeof useMarginBillDueLazyQuery>;
export type MarginBillDueSuspenseQueryHookResult = ReturnType<typeof useMarginBillDueSuspenseQuery>;
export type MarginBillDueQueryResult = Apollo.QueryResult<Types.MarginBillDueQuery, Types.MarginBillDueQueryVariables>;
export const MarginLandingPageDocument = gql`
    query MarginLandingPage($id: ID!) {
  viewer {
    announcements {
      forBorrow {
        ...Announcement
      }
    }
  }
  node(id: $id) {
    ... on BorrowAccount {
      id
      investAccount {
        id
      }
      hasBorrowedBefore
      ...PendingTransferInstances
      marginProductLandingPage {
        title
        primaryCta {
          ...AppNavigationItem
        }
        secondaryCta {
          ...Linkable
        }
        availableToBorrow {
          ...MarginAvailableToBorrow
        }
        header {
          ...LandingPageHeader
        }
        banners {
          ...InformationBanner
        }
        accountDetails {
          ...TabularDataItem
        }
        health {
          ...MarginHealth
        }
        loanInterest {
          ...MarginLoanInterest
        }
        howBillingWorks {
          ...MarginDetail
        }
        promotionCards {
          ...AppCard
        }
        priorityPromotionCards {
          ...AppCard
        }
      }
    }
  }
}
    ${AnnouncementFragmentDoc}
${PendingTransferInstancesFragmentDoc}
${AppNavigationItemFragmentDoc}
${LinkableFragmentDoc}
${MarginAvailableToBorrowFragmentDoc}
${LandingPageHeaderFragmentDoc}
${InformationBannerFragmentDoc}
${TabularDataItemFragmentDoc}
${MarginHealthFragmentDoc}
${MarginLoanInterestFragmentDoc}
${MarginDetailFragmentDoc}
${AppCardFragmentDoc}`;

/**
 * __useMarginLandingPageQuery__
 *
 * To run a query within a React component, call `useMarginLandingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarginLandingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarginLandingPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarginLandingPageQuery(baseOptions: Apollo.QueryHookOptions<Types.MarginLandingPageQuery, Types.MarginLandingPageQueryVariables> & ({ variables: Types.MarginLandingPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MarginLandingPageQuery, Types.MarginLandingPageQueryVariables>(MarginLandingPageDocument, options);
      }
export function useMarginLandingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MarginLandingPageQuery, Types.MarginLandingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MarginLandingPageQuery, Types.MarginLandingPageQueryVariables>(MarginLandingPageDocument, options);
        }
export function useMarginLandingPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MarginLandingPageQuery, Types.MarginLandingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MarginLandingPageQuery, Types.MarginLandingPageQueryVariables>(MarginLandingPageDocument, options);
        }
export type MarginLandingPageQueryHookResult = ReturnType<typeof useMarginLandingPageQuery>;
export type MarginLandingPageLazyQueryHookResult = ReturnType<typeof useMarginLandingPageLazyQuery>;
export type MarginLandingPageSuspenseQueryHookResult = ReturnType<typeof useMarginLandingPageSuspenseQuery>;
export type MarginLandingPageQueryResult = Apollo.QueryResult<Types.MarginLandingPageQuery, Types.MarginLandingPageQueryVariables>;
export const MoveMoneyDirectDepositDocument = gql`
    query MoveMoneyDirectDeposit {
  viewer {
    accounts(first: 20, filterStatus: [NEW, OPENED, REJECTED, CLOSED]) {
      edges {
        node {
          id
          isActive
          registration
          originator
        }
      }
    }
    save {
      savings {
        hasSavingsAccounts
        savingsAccounts {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMoveMoneyDirectDepositQuery__
 *
 * To run a query within a React component, call `useMoveMoneyDirectDepositQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoveMoneyDirectDepositQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoveMoneyDirectDepositQuery({
 *   variables: {
 *   },
 * });
 */
export function useMoveMoneyDirectDepositQuery(baseOptions?: Apollo.QueryHookOptions<Types.MoveMoneyDirectDepositQuery, Types.MoveMoneyDirectDepositQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MoveMoneyDirectDepositQuery, Types.MoveMoneyDirectDepositQueryVariables>(MoveMoneyDirectDepositDocument, options);
      }
export function useMoveMoneyDirectDepositLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MoveMoneyDirectDepositQuery, Types.MoveMoneyDirectDepositQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MoveMoneyDirectDepositQuery, Types.MoveMoneyDirectDepositQueryVariables>(MoveMoneyDirectDepositDocument, options);
        }
export function useMoveMoneyDirectDepositSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MoveMoneyDirectDepositQuery, Types.MoveMoneyDirectDepositQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MoveMoneyDirectDepositQuery, Types.MoveMoneyDirectDepositQueryVariables>(MoveMoneyDirectDepositDocument, options);
        }
export type MoveMoneyDirectDepositQueryHookResult = ReturnType<typeof useMoveMoneyDirectDepositQuery>;
export type MoveMoneyDirectDepositLazyQueryHookResult = ReturnType<typeof useMoveMoneyDirectDepositLazyQuery>;
export type MoveMoneyDirectDepositSuspenseQueryHookResult = ReturnType<typeof useMoveMoneyDirectDepositSuspenseQuery>;
export type MoveMoneyDirectDepositQueryResult = Apollo.QueryResult<Types.MoveMoneyDirectDepositQuery, Types.MoveMoneyDirectDepositQueryVariables>;
export const NotificationPageDocument = gql`
    query NotificationPage {
  viewer {
    notifications {
      preferences {
        transferCompletion {
          sendViaEmail
          sendViaPush
        }
        investActivity {
          sendViaEmail
          sendViaPush
        }
        spendTransaction {
          sendViaEmail
          sendViaPush
        }
      }
    }
  }
}
    `;

/**
 * __useNotificationPageQuery__
 *
 * To run a query within a React component, call `useNotificationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.NotificationPageQuery, Types.NotificationPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NotificationPageQuery, Types.NotificationPageQueryVariables>(NotificationPageDocument, options);
      }
export function useNotificationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NotificationPageQuery, Types.NotificationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NotificationPageQuery, Types.NotificationPageQueryVariables>(NotificationPageDocument, options);
        }
export function useNotificationPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.NotificationPageQuery, Types.NotificationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.NotificationPageQuery, Types.NotificationPageQueryVariables>(NotificationPageDocument, options);
        }
export type NotificationPageQueryHookResult = ReturnType<typeof useNotificationPageQuery>;
export type NotificationPageLazyQueryHookResult = ReturnType<typeof useNotificationPageLazyQuery>;
export type NotificationPageSuspenseQueryHookResult = ReturnType<typeof useNotificationPageSuspenseQuery>;
export type NotificationPageQueryResult = Apollo.QueryResult<Types.NotificationPageQuery, Types.NotificationPageQueryVariables>;
export const ChoosePieStepDocument = gql`
    query ChoosePieStep {
  viewer {
    userPies(first: 1000, isCrypto: true) {
      edges {
        node {
          id
          name
          ... on Sliceable {
            __typename
            id
            name
            ... on Security {
              symbol
              profile {
                logoUrl
              }
            }
            ... on SystemPie {
              key
              logoUrl
              categorizationDetails {
                logoUrl
              }
            }
            ... on UserPie {
              portfolioLinks {
                isRootSlice
              }
            }
          }
        }
      }
    }
    ... on Viewer {
      crypto {
        screenCrypto(first: 100) {
          edges {
            node {
              id
              legacyId
              symbol
              name
              restrictions
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useChoosePieStepQuery__
 *
 * To run a query within a React component, call `useChoosePieStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useChoosePieStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChoosePieStepQuery({
 *   variables: {
 *   },
 * });
 */
export function useChoosePieStepQuery(baseOptions?: Apollo.QueryHookOptions<Types.ChoosePieStepQuery, Types.ChoosePieStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ChoosePieStepQuery, Types.ChoosePieStepQueryVariables>(ChoosePieStepDocument, options);
      }
export function useChoosePieStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ChoosePieStepQuery, Types.ChoosePieStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ChoosePieStepQuery, Types.ChoosePieStepQueryVariables>(ChoosePieStepDocument, options);
        }
export function useChoosePieStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ChoosePieStepQuery, Types.ChoosePieStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ChoosePieStepQuery, Types.ChoosePieStepQueryVariables>(ChoosePieStepDocument, options);
        }
export type ChoosePieStepQueryHookResult = ReturnType<typeof useChoosePieStepQuery>;
export type ChoosePieStepLazyQueryHookResult = ReturnType<typeof useChoosePieStepLazyQuery>;
export type ChoosePieStepSuspenseQueryHookResult = ReturnType<typeof useChoosePieStepSuspenseQuery>;
export type ChoosePieStepQueryResult = Apollo.QueryResult<Types.ChoosePieStepQuery, Types.ChoosePieStepQueryVariables>;
export const CollectPrimaryDisclosuresStepDocument = gql`
    query CollectPrimaryDisclosuresStep {
  viewer {
    profile {
      primary {
        exchangeOrFinraAffiliationDisclosure {
          isAffiliated
          firmName
        }
        controlPersonDisclosure {
          isControlPerson
          companySymbols
        }
        politicalDisclosure {
          ...PoliticalDisclosure
        }
        backupWithholding {
          isSubjectToBackupWithholding
        }
      }
    }
  }
}
    ${PoliticalDisclosureFragmentDoc}`;

/**
 * __useCollectPrimaryDisclosuresStepQuery__
 *
 * To run a query within a React component, call `useCollectPrimaryDisclosuresStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectPrimaryDisclosuresStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectPrimaryDisclosuresStepQuery({
 *   variables: {
 *   },
 * });
 */
export function useCollectPrimaryDisclosuresStepQuery(baseOptions?: Apollo.QueryHookOptions<Types.CollectPrimaryDisclosuresStepQuery, Types.CollectPrimaryDisclosuresStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CollectPrimaryDisclosuresStepQuery, Types.CollectPrimaryDisclosuresStepQueryVariables>(CollectPrimaryDisclosuresStepDocument, options);
      }
export function useCollectPrimaryDisclosuresStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CollectPrimaryDisclosuresStepQuery, Types.CollectPrimaryDisclosuresStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CollectPrimaryDisclosuresStepQuery, Types.CollectPrimaryDisclosuresStepQueryVariables>(CollectPrimaryDisclosuresStepDocument, options);
        }
export function useCollectPrimaryDisclosuresStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CollectPrimaryDisclosuresStepQuery, Types.CollectPrimaryDisclosuresStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CollectPrimaryDisclosuresStepQuery, Types.CollectPrimaryDisclosuresStepQueryVariables>(CollectPrimaryDisclosuresStepDocument, options);
        }
export type CollectPrimaryDisclosuresStepQueryHookResult = ReturnType<typeof useCollectPrimaryDisclosuresStepQuery>;
export type CollectPrimaryDisclosuresStepLazyQueryHookResult = ReturnType<typeof useCollectPrimaryDisclosuresStepLazyQuery>;
export type CollectPrimaryDisclosuresStepSuspenseQueryHookResult = ReturnType<typeof useCollectPrimaryDisclosuresStepSuspenseQuery>;
export type CollectPrimaryDisclosuresStepQueryResult = Apollo.QueryResult<Types.CollectPrimaryDisclosuresStepQuery, Types.CollectPrimaryDisclosuresStepQueryVariables>;
export const CollectSecondaryDisclosuresStepDocument = gql`
    query CollectSecondaryDisclosuresStep {
  viewer {
    profile {
      secondary {
        exchangeOrFinraAffiliationDisclosure {
          isAffiliated
          firmName
        }
        controlPersonDisclosure {
          isControlPerson
          companySymbols
        }
        politicalDisclosure {
          ...PoliticalDisclosure
        }
        backupWithholding {
          isSubjectToBackupWithholding
        }
      }
    }
  }
}
    ${PoliticalDisclosureFragmentDoc}`;

/**
 * __useCollectSecondaryDisclosuresStepQuery__
 *
 * To run a query within a React component, call `useCollectSecondaryDisclosuresStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectSecondaryDisclosuresStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectSecondaryDisclosuresStepQuery({
 *   variables: {
 *   },
 * });
 */
export function useCollectSecondaryDisclosuresStepQuery(baseOptions?: Apollo.QueryHookOptions<Types.CollectSecondaryDisclosuresStepQuery, Types.CollectSecondaryDisclosuresStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CollectSecondaryDisclosuresStepQuery, Types.CollectSecondaryDisclosuresStepQueryVariables>(CollectSecondaryDisclosuresStepDocument, options);
      }
export function useCollectSecondaryDisclosuresStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CollectSecondaryDisclosuresStepQuery, Types.CollectSecondaryDisclosuresStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CollectSecondaryDisclosuresStepQuery, Types.CollectSecondaryDisclosuresStepQueryVariables>(CollectSecondaryDisclosuresStepDocument, options);
        }
export function useCollectSecondaryDisclosuresStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CollectSecondaryDisclosuresStepQuery, Types.CollectSecondaryDisclosuresStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CollectSecondaryDisclosuresStepQuery, Types.CollectSecondaryDisclosuresStepQueryVariables>(CollectSecondaryDisclosuresStepDocument, options);
        }
export type CollectSecondaryDisclosuresStepQueryHookResult = ReturnType<typeof useCollectSecondaryDisclosuresStepQuery>;
export type CollectSecondaryDisclosuresStepLazyQueryHookResult = ReturnType<typeof useCollectSecondaryDisclosuresStepLazyQuery>;
export type CollectSecondaryDisclosuresStepSuspenseQueryHookResult = ReturnType<typeof useCollectSecondaryDisclosuresStepSuspenseQuery>;
export type CollectSecondaryDisclosuresStepQueryResult = Apollo.QueryResult<Types.CollectSecondaryDisclosuresStepQuery, Types.CollectSecondaryDisclosuresStepQueryVariables>;
export const InvestAccountReviewStepRequiredDocumentsDocument = gql`
    query InvestAccountReviewStepRequiredDocuments($accountRegistration: OnlineAccountRegistrationEnum!) {
  viewer {
    invest {
      requiredDocuments(accountRegistration: $accountRegistration) {
        documents {
          title
          url
        }
        preamble
        postamble
        signature
      }
    }
  }
}
    `;

/**
 * __useInvestAccountReviewStepRequiredDocumentsQuery__
 *
 * To run a query within a React component, call `useInvestAccountReviewStepRequiredDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestAccountReviewStepRequiredDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestAccountReviewStepRequiredDocumentsQuery({
 *   variables: {
 *      accountRegistration: // value for 'accountRegistration'
 *   },
 * });
 */
export function useInvestAccountReviewStepRequiredDocumentsQuery(baseOptions: Apollo.QueryHookOptions<Types.InvestAccountReviewStepRequiredDocumentsQuery, Types.InvestAccountReviewStepRequiredDocumentsQueryVariables> & ({ variables: Types.InvestAccountReviewStepRequiredDocumentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvestAccountReviewStepRequiredDocumentsQuery, Types.InvestAccountReviewStepRequiredDocumentsQueryVariables>(InvestAccountReviewStepRequiredDocumentsDocument, options);
      }
export function useInvestAccountReviewStepRequiredDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvestAccountReviewStepRequiredDocumentsQuery, Types.InvestAccountReviewStepRequiredDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvestAccountReviewStepRequiredDocumentsQuery, Types.InvestAccountReviewStepRequiredDocumentsQueryVariables>(InvestAccountReviewStepRequiredDocumentsDocument, options);
        }
export function useInvestAccountReviewStepRequiredDocumentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.InvestAccountReviewStepRequiredDocumentsQuery, Types.InvestAccountReviewStepRequiredDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.InvestAccountReviewStepRequiredDocumentsQuery, Types.InvestAccountReviewStepRequiredDocumentsQueryVariables>(InvestAccountReviewStepRequiredDocumentsDocument, options);
        }
export type InvestAccountReviewStepRequiredDocumentsQueryHookResult = ReturnType<typeof useInvestAccountReviewStepRequiredDocumentsQuery>;
export type InvestAccountReviewStepRequiredDocumentsLazyQueryHookResult = ReturnType<typeof useInvestAccountReviewStepRequiredDocumentsLazyQuery>;
export type InvestAccountReviewStepRequiredDocumentsSuspenseQueryHookResult = ReturnType<typeof useInvestAccountReviewStepRequiredDocumentsSuspenseQuery>;
export type InvestAccountReviewStepRequiredDocumentsQueryResult = Apollo.QueryResult<Types.InvestAccountReviewStepRequiredDocumentsQuery, Types.InvestAccountReviewStepRequiredDocumentsQueryVariables>;
export const EmailVerificationPageDocument = gql`
    query EmailVerificationPage {
  viewer {
    emailVerificationPage {
      title
      content
      ctaLabel
      illustration {
        names
      }
    }
  }
}
    `;

/**
 * __useEmailVerificationPageQuery__
 *
 * To run a query within a React component, call `useEmailVerificationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailVerificationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailVerificationPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmailVerificationPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.EmailVerificationPageQuery, Types.EmailVerificationPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EmailVerificationPageQuery, Types.EmailVerificationPageQueryVariables>(EmailVerificationPageDocument, options);
      }
export function useEmailVerificationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EmailVerificationPageQuery, Types.EmailVerificationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EmailVerificationPageQuery, Types.EmailVerificationPageQueryVariables>(EmailVerificationPageDocument, options);
        }
export function useEmailVerificationPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EmailVerificationPageQuery, Types.EmailVerificationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EmailVerificationPageQuery, Types.EmailVerificationPageQueryVariables>(EmailVerificationPageDocument, options);
        }
export type EmailVerificationPageQueryHookResult = ReturnType<typeof useEmailVerificationPageQuery>;
export type EmailVerificationPageLazyQueryHookResult = ReturnType<typeof useEmailVerificationPageLazyQuery>;
export type EmailVerificationPageSuspenseQueryHookResult = ReturnType<typeof useEmailVerificationPageSuspenseQuery>;
export type EmailVerificationPageQueryResult = Apollo.QueryResult<Types.EmailVerificationPageQuery, Types.EmailVerificationPageQueryVariables>;
export const GetFinancialInformationProfileDocument = gql`
    query GetFinancialInformationProfile {
  viewer {
    profile {
      ...FinancialInformationProfile
      ...IdentityFirstConfirmationProfile
    }
  }
}
    ${FinancialInformationProfileFragmentDoc}
${IdentityFirstConfirmationProfileFragmentDoc}`;

/**
 * __useGetFinancialInformationProfileQuery__
 *
 * To run a query within a React component, call `useGetFinancialInformationProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialInformationProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialInformationProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFinancialInformationProfileQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetFinancialInformationProfileQuery, Types.GetFinancialInformationProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFinancialInformationProfileQuery, Types.GetFinancialInformationProfileQueryVariables>(GetFinancialInformationProfileDocument, options);
      }
export function useGetFinancialInformationProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFinancialInformationProfileQuery, Types.GetFinancialInformationProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFinancialInformationProfileQuery, Types.GetFinancialInformationProfileQueryVariables>(GetFinancialInformationProfileDocument, options);
        }
export function useGetFinancialInformationProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetFinancialInformationProfileQuery, Types.GetFinancialInformationProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetFinancialInformationProfileQuery, Types.GetFinancialInformationProfileQueryVariables>(GetFinancialInformationProfileDocument, options);
        }
export type GetFinancialInformationProfileQueryHookResult = ReturnType<typeof useGetFinancialInformationProfileQuery>;
export type GetFinancialInformationProfileLazyQueryHookResult = ReturnType<typeof useGetFinancialInformationProfileLazyQuery>;
export type GetFinancialInformationProfileSuspenseQueryHookResult = ReturnType<typeof useGetFinancialInformationProfileSuspenseQuery>;
export type GetFinancialInformationProfileQueryResult = Apollo.QueryResult<Types.GetFinancialInformationProfileQuery, Types.GetFinancialInformationProfileQueryVariables>;
export const HasCxIntegratedOnboardingDocument = gql`
    query HasCxIntegratedOnboarding {
  viewer {
    user {
      onboardingIdentityFirst {
        hasCxIntegratedOnboarding
      }
    }
  }
}
    `;

/**
 * __useHasCxIntegratedOnboardingQuery__
 *
 * To run a query within a React component, call `useHasCxIntegratedOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasCxIntegratedOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasCxIntegratedOnboardingQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasCxIntegratedOnboardingQuery(baseOptions?: Apollo.QueryHookOptions<Types.HasCxIntegratedOnboardingQuery, Types.HasCxIntegratedOnboardingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HasCxIntegratedOnboardingQuery, Types.HasCxIntegratedOnboardingQueryVariables>(HasCxIntegratedOnboardingDocument, options);
      }
export function useHasCxIntegratedOnboardingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HasCxIntegratedOnboardingQuery, Types.HasCxIntegratedOnboardingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HasCxIntegratedOnboardingQuery, Types.HasCxIntegratedOnboardingQueryVariables>(HasCxIntegratedOnboardingDocument, options);
        }
export function useHasCxIntegratedOnboardingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.HasCxIntegratedOnboardingQuery, Types.HasCxIntegratedOnboardingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.HasCxIntegratedOnboardingQuery, Types.HasCxIntegratedOnboardingQueryVariables>(HasCxIntegratedOnboardingDocument, options);
        }
export type HasCxIntegratedOnboardingQueryHookResult = ReturnType<typeof useHasCxIntegratedOnboardingQuery>;
export type HasCxIntegratedOnboardingLazyQueryHookResult = ReturnType<typeof useHasCxIntegratedOnboardingLazyQuery>;
export type HasCxIntegratedOnboardingSuspenseQueryHookResult = ReturnType<typeof useHasCxIntegratedOnboardingSuspenseQuery>;
export type HasCxIntegratedOnboardingQueryResult = Apollo.QueryResult<Types.HasCxIntegratedOnboardingQuery, Types.HasCxIntegratedOnboardingQueryVariables>;
export const HasIdentityInfoDocument = gql`
    query HasIdentityInfo {
  viewer {
    user {
      hasIdentityInfo
      latestProductSelection
    }
  }
}
    `;

/**
 * __useHasIdentityInfoQuery__
 *
 * To run a query within a React component, call `useHasIdentityInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasIdentityInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasIdentityInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasIdentityInfoQuery(baseOptions?: Apollo.QueryHookOptions<Types.HasIdentityInfoQuery, Types.HasIdentityInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HasIdentityInfoQuery, Types.HasIdentityInfoQueryVariables>(HasIdentityInfoDocument, options);
      }
export function useHasIdentityInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HasIdentityInfoQuery, Types.HasIdentityInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HasIdentityInfoQuery, Types.HasIdentityInfoQueryVariables>(HasIdentityInfoDocument, options);
        }
export function useHasIdentityInfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.HasIdentityInfoQuery, Types.HasIdentityInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.HasIdentityInfoQuery, Types.HasIdentityInfoQueryVariables>(HasIdentityInfoDocument, options);
        }
export type HasIdentityInfoQueryHookResult = ReturnType<typeof useHasIdentityInfoQuery>;
export type HasIdentityInfoLazyQueryHookResult = ReturnType<typeof useHasIdentityInfoLazyQuery>;
export type HasIdentityInfoSuspenseQueryHookResult = ReturnType<typeof useHasIdentityInfoSuspenseQuery>;
export type HasIdentityInfoQueryResult = Apollo.QueryResult<Types.HasIdentityInfoQuery, Types.HasIdentityInfoQueryVariables>;
export const IdentityFirstFinancialSuitabilityQuestionsDocument = gql`
    query IdentityFirstFinancialSuitabilityQuestions {
  viewer {
    user {
      onboardingIdentityFirst {
        financialSuitabilitySection {
          title
          questions {
            questionType
            title
            subtitle
            section
            continueLabel
            skipLabel
            disclaimerTooltipContent
            previousQuestionType
            nextQuestionType
          }
        }
      }
    }
  }
}
    `;

/**
 * __useIdentityFirstFinancialSuitabilityQuestionsQuery__
 *
 * To run a query within a React component, call `useIdentityFirstFinancialSuitabilityQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdentityFirstFinancialSuitabilityQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdentityFirstFinancialSuitabilityQuestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useIdentityFirstFinancialSuitabilityQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.IdentityFirstFinancialSuitabilityQuestionsQuery, Types.IdentityFirstFinancialSuitabilityQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IdentityFirstFinancialSuitabilityQuestionsQuery, Types.IdentityFirstFinancialSuitabilityQuestionsQueryVariables>(IdentityFirstFinancialSuitabilityQuestionsDocument, options);
      }
export function useIdentityFirstFinancialSuitabilityQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IdentityFirstFinancialSuitabilityQuestionsQuery, Types.IdentityFirstFinancialSuitabilityQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IdentityFirstFinancialSuitabilityQuestionsQuery, Types.IdentityFirstFinancialSuitabilityQuestionsQueryVariables>(IdentityFirstFinancialSuitabilityQuestionsDocument, options);
        }
export function useIdentityFirstFinancialSuitabilityQuestionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.IdentityFirstFinancialSuitabilityQuestionsQuery, Types.IdentityFirstFinancialSuitabilityQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IdentityFirstFinancialSuitabilityQuestionsQuery, Types.IdentityFirstFinancialSuitabilityQuestionsQueryVariables>(IdentityFirstFinancialSuitabilityQuestionsDocument, options);
        }
export type IdentityFirstFinancialSuitabilityQuestionsQueryHookResult = ReturnType<typeof useIdentityFirstFinancialSuitabilityQuestionsQuery>;
export type IdentityFirstFinancialSuitabilityQuestionsLazyQueryHookResult = ReturnType<typeof useIdentityFirstFinancialSuitabilityQuestionsLazyQuery>;
export type IdentityFirstFinancialSuitabilityQuestionsSuspenseQueryHookResult = ReturnType<typeof useIdentityFirstFinancialSuitabilityQuestionsSuspenseQuery>;
export type IdentityFirstFinancialSuitabilityQuestionsQueryResult = Apollo.QueryResult<Types.IdentityFirstFinancialSuitabilityQuestionsQuery, Types.IdentityFirstFinancialSuitabilityQuestionsQueryVariables>;
export const AccountLastAchRelationshipIdDocument = gql`
    query AccountLastAchRelationshipId($accountId: ID!) {
  node(id: $accountId) {
    ... on Account {
      registration
      lastAchRelationship {
        id
      }
    }
  }
}
    `;

/**
 * __useAccountLastAchRelationshipIdQuery__
 *
 * To run a query within a React component, call `useAccountLastAchRelationshipIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountLastAchRelationshipIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountLastAchRelationshipIdQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountLastAchRelationshipIdQuery(baseOptions: Apollo.QueryHookOptions<Types.AccountLastAchRelationshipIdQuery, Types.AccountLastAchRelationshipIdQueryVariables> & ({ variables: Types.AccountLastAchRelationshipIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AccountLastAchRelationshipIdQuery, Types.AccountLastAchRelationshipIdQueryVariables>(AccountLastAchRelationshipIdDocument, options);
      }
export function useAccountLastAchRelationshipIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AccountLastAchRelationshipIdQuery, Types.AccountLastAchRelationshipIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AccountLastAchRelationshipIdQuery, Types.AccountLastAchRelationshipIdQueryVariables>(AccountLastAchRelationshipIdDocument, options);
        }
export function useAccountLastAchRelationshipIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AccountLastAchRelationshipIdQuery, Types.AccountLastAchRelationshipIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AccountLastAchRelationshipIdQuery, Types.AccountLastAchRelationshipIdQueryVariables>(AccountLastAchRelationshipIdDocument, options);
        }
export type AccountLastAchRelationshipIdQueryHookResult = ReturnType<typeof useAccountLastAchRelationshipIdQuery>;
export type AccountLastAchRelationshipIdLazyQueryHookResult = ReturnType<typeof useAccountLastAchRelationshipIdLazyQuery>;
export type AccountLastAchRelationshipIdSuspenseQueryHookResult = ReturnType<typeof useAccountLastAchRelationshipIdSuspenseQuery>;
export type AccountLastAchRelationshipIdQueryResult = Apollo.QueryResult<Types.AccountLastAchRelationshipIdQuery, Types.AccountLastAchRelationshipIdQueryVariables>;
export const PersonalLoansCreateAccountReviewPageDocument = gql`
    query PersonalLoansCreateAccountReviewPage {
  viewer {
    onboarding {
      ...FinancialInformationDueDiligence
    }
    profile {
      ...FinancialInformationProfile
      ...IdentityFirstConfirmationProfile
    }
    user {
      ...IdentityFirstConfirmationUser
    }
    borrow {
      personalLoans {
        applicationFlow {
          loanTermOptions {
            value
            description
          }
        }
      }
    }
  }
}
    ${FinancialInformationDueDiligenceFragmentDoc}
${FinancialInformationProfileFragmentDoc}
${IdentityFirstConfirmationProfileFragmentDoc}
${IdentityFirstConfirmationUserFragmentDoc}`;

/**
 * __usePersonalLoansCreateAccountReviewPageQuery__
 *
 * To run a query within a React component, call `usePersonalLoansCreateAccountReviewPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalLoansCreateAccountReviewPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalLoansCreateAccountReviewPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalLoansCreateAccountReviewPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.PersonalLoansCreateAccountReviewPageQuery, Types.PersonalLoansCreateAccountReviewPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonalLoansCreateAccountReviewPageQuery, Types.PersonalLoansCreateAccountReviewPageQueryVariables>(PersonalLoansCreateAccountReviewPageDocument, options);
      }
export function usePersonalLoansCreateAccountReviewPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonalLoansCreateAccountReviewPageQuery, Types.PersonalLoansCreateAccountReviewPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonalLoansCreateAccountReviewPageQuery, Types.PersonalLoansCreateAccountReviewPageQueryVariables>(PersonalLoansCreateAccountReviewPageDocument, options);
        }
export function usePersonalLoansCreateAccountReviewPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PersonalLoansCreateAccountReviewPageQuery, Types.PersonalLoansCreateAccountReviewPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonalLoansCreateAccountReviewPageQuery, Types.PersonalLoansCreateAccountReviewPageQueryVariables>(PersonalLoansCreateAccountReviewPageDocument, options);
        }
export type PersonalLoansCreateAccountReviewPageQueryHookResult = ReturnType<typeof usePersonalLoansCreateAccountReviewPageQuery>;
export type PersonalLoansCreateAccountReviewPageLazyQueryHookResult = ReturnType<typeof usePersonalLoansCreateAccountReviewPageLazyQuery>;
export type PersonalLoansCreateAccountReviewPageSuspenseQueryHookResult = ReturnType<typeof usePersonalLoansCreateAccountReviewPageSuspenseQuery>;
export type PersonalLoansCreateAccountReviewPageQueryResult = Apollo.QueryResult<Types.PersonalLoansCreateAccountReviewPageQuery, Types.PersonalLoansCreateAccountReviewPageQueryVariables>;
export const InvestConfirmationStepDocument = gql`
    query InvestConfirmationStep($accountRegistration: OnlineAccountRegistrationEnum!) {
  viewer {
    invest {
      requiredDocuments(accountRegistration: $accountRegistration) {
        signature
        ...IdentityFirstConfirmationScreenRequiredDocuments
      }
    }
    onboarding {
      ...FinancialInformationDueDiligence
    }
    profile {
      ...FinancialInformationProfile
      ...IdentityFirstConfirmationProfile
    }
    user {
      ...IdentityFirstConfirmationUser
    }
  }
}
    ${IdentityFirstConfirmationScreenRequiredDocumentsFragmentDoc}
${FinancialInformationDueDiligenceFragmentDoc}
${FinancialInformationProfileFragmentDoc}
${IdentityFirstConfirmationProfileFragmentDoc}
${IdentityFirstConfirmationUserFragmentDoc}`;

/**
 * __useInvestConfirmationStepQuery__
 *
 * To run a query within a React component, call `useInvestConfirmationStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestConfirmationStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestConfirmationStepQuery({
 *   variables: {
 *      accountRegistration: // value for 'accountRegistration'
 *   },
 * });
 */
export function useInvestConfirmationStepQuery(baseOptions: Apollo.QueryHookOptions<Types.InvestConfirmationStepQuery, Types.InvestConfirmationStepQueryVariables> & ({ variables: Types.InvestConfirmationStepQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvestConfirmationStepQuery, Types.InvestConfirmationStepQueryVariables>(InvestConfirmationStepDocument, options);
      }
export function useInvestConfirmationStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvestConfirmationStepQuery, Types.InvestConfirmationStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvestConfirmationStepQuery, Types.InvestConfirmationStepQueryVariables>(InvestConfirmationStepDocument, options);
        }
export function useInvestConfirmationStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.InvestConfirmationStepQuery, Types.InvestConfirmationStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.InvestConfirmationStepQuery, Types.InvestConfirmationStepQueryVariables>(InvestConfirmationStepDocument, options);
        }
export type InvestConfirmationStepQueryHookResult = ReturnType<typeof useInvestConfirmationStepQuery>;
export type InvestConfirmationStepLazyQueryHookResult = ReturnType<typeof useInvestConfirmationStepLazyQuery>;
export type InvestConfirmationStepSuspenseQueryHookResult = ReturnType<typeof useInvestConfirmationStepSuspenseQuery>;
export type InvestConfirmationStepQueryResult = Apollo.QueryResult<Types.InvestConfirmationStepQuery, Types.InvestConfirmationStepQueryVariables>;
export const OnboardingIdentityFirstQuestionsDocument = gql`
    query OnboardingIdentityFirstQuestions {
  viewer {
    user {
      onboardingIdentityFirst {
        userProfileSection {
          questions {
            questionType
            title
            subtitle
            disclaimerTooltipContent
          }
        }
      }
    }
  }
}
    `;

/**
 * __useOnboardingIdentityFirstQuestionsQuery__
 *
 * To run a query within a React component, call `useOnboardingIdentityFirstQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingIdentityFirstQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingIdentityFirstQuestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingIdentityFirstQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.OnboardingIdentityFirstQuestionsQuery, Types.OnboardingIdentityFirstQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OnboardingIdentityFirstQuestionsQuery, Types.OnboardingIdentityFirstQuestionsQueryVariables>(OnboardingIdentityFirstQuestionsDocument, options);
      }
export function useOnboardingIdentityFirstQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OnboardingIdentityFirstQuestionsQuery, Types.OnboardingIdentityFirstQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OnboardingIdentityFirstQuestionsQuery, Types.OnboardingIdentityFirstQuestionsQueryVariables>(OnboardingIdentityFirstQuestionsDocument, options);
        }
export function useOnboardingIdentityFirstQuestionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.OnboardingIdentityFirstQuestionsQuery, Types.OnboardingIdentityFirstQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.OnboardingIdentityFirstQuestionsQuery, Types.OnboardingIdentityFirstQuestionsQueryVariables>(OnboardingIdentityFirstQuestionsDocument, options);
        }
export type OnboardingIdentityFirstQuestionsQueryHookResult = ReturnType<typeof useOnboardingIdentityFirstQuestionsQuery>;
export type OnboardingIdentityFirstQuestionsLazyQueryHookResult = ReturnType<typeof useOnboardingIdentityFirstQuestionsLazyQuery>;
export type OnboardingIdentityFirstQuestionsSuspenseQueryHookResult = ReturnType<typeof useOnboardingIdentityFirstQuestionsSuspenseQuery>;
export type OnboardingIdentityFirstQuestionsQueryResult = Apollo.QueryResult<Types.OnboardingIdentityFirstQuestionsQuery, Types.OnboardingIdentityFirstQuestionsQueryVariables>;
export const OnboardingPageDocument = gql`
    query OnboardingPage {
  viewer {
    user {
      ...UserKeys
    }
    accounts(first: 1) {
      edges {
        node {
          id
          lastAchRelationship {
            id
            isActive
          }
        }
      }
    }
  }
}
    ${UserKeysFragmentDoc}`;

/**
 * __useOnboardingPageQuery__
 *
 * To run a query within a React component, call `useOnboardingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.OnboardingPageQuery, Types.OnboardingPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OnboardingPageQuery, Types.OnboardingPageQueryVariables>(OnboardingPageDocument, options);
      }
export function useOnboardingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OnboardingPageQuery, Types.OnboardingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OnboardingPageQuery, Types.OnboardingPageQueryVariables>(OnboardingPageDocument, options);
        }
export function useOnboardingPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.OnboardingPageQuery, Types.OnboardingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.OnboardingPageQuery, Types.OnboardingPageQueryVariables>(OnboardingPageDocument, options);
        }
export type OnboardingPageQueryHookResult = ReturnType<typeof useOnboardingPageQuery>;
export type OnboardingPageLazyQueryHookResult = ReturnType<typeof useOnboardingPageLazyQuery>;
export type OnboardingPageSuspenseQueryHookResult = ReturnType<typeof useOnboardingPageSuspenseQuery>;
export type OnboardingPageQueryResult = Apollo.QueryResult<Types.OnboardingPageQuery, Types.OnboardingPageQueryVariables>;
export const OnboardingProgressIndicatorDocument = gql`
    query OnboardingProgressIndicator {
  viewer {
    user {
      requiresPhoneVerification
    }
  }
}
    `;

/**
 * __useOnboardingProgressIndicatorQuery__
 *
 * To run a query within a React component, call `useOnboardingProgressIndicatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingProgressIndicatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingProgressIndicatorQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingProgressIndicatorQuery(baseOptions?: Apollo.QueryHookOptions<Types.OnboardingProgressIndicatorQuery, Types.OnboardingProgressIndicatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OnboardingProgressIndicatorQuery, Types.OnboardingProgressIndicatorQueryVariables>(OnboardingProgressIndicatorDocument, options);
      }
export function useOnboardingProgressIndicatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OnboardingProgressIndicatorQuery, Types.OnboardingProgressIndicatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OnboardingProgressIndicatorQuery, Types.OnboardingProgressIndicatorQueryVariables>(OnboardingProgressIndicatorDocument, options);
        }
export function useOnboardingProgressIndicatorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.OnboardingProgressIndicatorQuery, Types.OnboardingProgressIndicatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.OnboardingProgressIndicatorQuery, Types.OnboardingProgressIndicatorQueryVariables>(OnboardingProgressIndicatorDocument, options);
        }
export type OnboardingProgressIndicatorQueryHookResult = ReturnType<typeof useOnboardingProgressIndicatorQuery>;
export type OnboardingProgressIndicatorLazyQueryHookResult = ReturnType<typeof useOnboardingProgressIndicatorLazyQuery>;
export type OnboardingProgressIndicatorSuspenseQueryHookResult = ReturnType<typeof useOnboardingProgressIndicatorSuspenseQuery>;
export type OnboardingProgressIndicatorQueryResult = Apollo.QueryResult<Types.OnboardingProgressIndicatorQuery, Types.OnboardingProgressIndicatorQueryVariables>;
export const ProfileConfirmationRequiredDocumentsDocument = gql`
    query ProfileConfirmationRequiredDocuments {
  viewer {
    onboarding {
      profileConfirmationRequiredDocuments {
        documents {
          title
          url
        }
        postamble
        richPostamble {
          ...RichText
        }
      }
    }
  }
}
    ${RichTextFragmentDoc}`;

/**
 * __useProfileConfirmationRequiredDocumentsQuery__
 *
 * To run a query within a React component, call `useProfileConfirmationRequiredDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileConfirmationRequiredDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileConfirmationRequiredDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileConfirmationRequiredDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ProfileConfirmationRequiredDocumentsQuery, Types.ProfileConfirmationRequiredDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProfileConfirmationRequiredDocumentsQuery, Types.ProfileConfirmationRequiredDocumentsQueryVariables>(ProfileConfirmationRequiredDocumentsDocument, options);
      }
export function useProfileConfirmationRequiredDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProfileConfirmationRequiredDocumentsQuery, Types.ProfileConfirmationRequiredDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProfileConfirmationRequiredDocumentsQuery, Types.ProfileConfirmationRequiredDocumentsQueryVariables>(ProfileConfirmationRequiredDocumentsDocument, options);
        }
export function useProfileConfirmationRequiredDocumentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProfileConfirmationRequiredDocumentsQuery, Types.ProfileConfirmationRequiredDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProfileConfirmationRequiredDocumentsQuery, Types.ProfileConfirmationRequiredDocumentsQueryVariables>(ProfileConfirmationRequiredDocumentsDocument, options);
        }
export type ProfileConfirmationRequiredDocumentsQueryHookResult = ReturnType<typeof useProfileConfirmationRequiredDocumentsQuery>;
export type ProfileConfirmationRequiredDocumentsLazyQueryHookResult = ReturnType<typeof useProfileConfirmationRequiredDocumentsLazyQuery>;
export type ProfileConfirmationRequiredDocumentsSuspenseQueryHookResult = ReturnType<typeof useProfileConfirmationRequiredDocumentsSuspenseQuery>;
export type ProfileConfirmationRequiredDocumentsQueryResult = Apollo.QueryResult<Types.ProfileConfirmationRequiredDocumentsQuery, Types.ProfileConfirmationRequiredDocumentsQueryVariables>;
export const ReviewPageRequiredDocumentsDocument = gql`
    query ReviewPageRequiredDocuments($accountRegistration: OnlineAccountRegistrationEnum!) {
  viewer {
    invest {
      requiredDocuments(accountRegistration: $accountRegistration) {
        documents {
          title
          url
        }
        preamble
        postamble
        signature
      }
    }
  }
}
    `;

/**
 * __useReviewPageRequiredDocumentsQuery__
 *
 * To run a query within a React component, call `useReviewPageRequiredDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewPageRequiredDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewPageRequiredDocumentsQuery({
 *   variables: {
 *      accountRegistration: // value for 'accountRegistration'
 *   },
 * });
 */
export function useReviewPageRequiredDocumentsQuery(baseOptions: Apollo.QueryHookOptions<Types.ReviewPageRequiredDocumentsQuery, Types.ReviewPageRequiredDocumentsQueryVariables> & ({ variables: Types.ReviewPageRequiredDocumentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReviewPageRequiredDocumentsQuery, Types.ReviewPageRequiredDocumentsQueryVariables>(ReviewPageRequiredDocumentsDocument, options);
      }
export function useReviewPageRequiredDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReviewPageRequiredDocumentsQuery, Types.ReviewPageRequiredDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReviewPageRequiredDocumentsQuery, Types.ReviewPageRequiredDocumentsQueryVariables>(ReviewPageRequiredDocumentsDocument, options);
        }
export function useReviewPageRequiredDocumentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ReviewPageRequiredDocumentsQuery, Types.ReviewPageRequiredDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReviewPageRequiredDocumentsQuery, Types.ReviewPageRequiredDocumentsQueryVariables>(ReviewPageRequiredDocumentsDocument, options);
        }
export type ReviewPageRequiredDocumentsQueryHookResult = ReturnType<typeof useReviewPageRequiredDocumentsQuery>;
export type ReviewPageRequiredDocumentsLazyQueryHookResult = ReturnType<typeof useReviewPageRequiredDocumentsLazyQuery>;
export type ReviewPageRequiredDocumentsSuspenseQueryHookResult = ReturnType<typeof useReviewPageRequiredDocumentsSuspenseQuery>;
export type ReviewPageRequiredDocumentsQueryResult = Apollo.QueryResult<Types.ReviewPageRequiredDocumentsQuery, Types.ReviewPageRequiredDocumentsQueryVariables>;
export const UserEmailDocument = gql`
    query UserEmail {
  viewer {
    user {
      username
    }
  }
}
    `;

/**
 * __useUserEmailQuery__
 *
 * To run a query within a React component, call `useUserEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEmailQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserEmailQuery(baseOptions?: Apollo.QueryHookOptions<Types.UserEmailQuery, Types.UserEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserEmailQuery, Types.UserEmailQueryVariables>(UserEmailDocument, options);
      }
export function useUserEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserEmailQuery, Types.UserEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserEmailQuery, Types.UserEmailQueryVariables>(UserEmailDocument, options);
        }
export function useUserEmailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UserEmailQuery, Types.UserEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UserEmailQuery, Types.UserEmailQueryVariables>(UserEmailDocument, options);
        }
export type UserEmailQueryHookResult = ReturnType<typeof useUserEmailQuery>;
export type UserEmailLazyQueryHookResult = ReturnType<typeof useUserEmailLazyQuery>;
export type UserEmailSuspenseQueryHookResult = ReturnType<typeof useUserEmailSuspenseQuery>;
export type UserEmailQueryResult = Apollo.QueryResult<Types.UserEmailQuery, Types.UserEmailQueryVariables>;
export const UserHasCompletedProfileDocument = gql`
    query UserHasCompletedProfile {
  viewer {
    user {
      hasCompletedProfile
    }
  }
}
    `;

/**
 * __useUserHasCompletedProfileQuery__
 *
 * To run a query within a React component, call `useUserHasCompletedProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHasCompletedProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHasCompletedProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserHasCompletedProfileQuery(baseOptions?: Apollo.QueryHookOptions<Types.UserHasCompletedProfileQuery, Types.UserHasCompletedProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserHasCompletedProfileQuery, Types.UserHasCompletedProfileQueryVariables>(UserHasCompletedProfileDocument, options);
      }
export function useUserHasCompletedProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserHasCompletedProfileQuery, Types.UserHasCompletedProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserHasCompletedProfileQuery, Types.UserHasCompletedProfileQueryVariables>(UserHasCompletedProfileDocument, options);
        }
export function useUserHasCompletedProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UserHasCompletedProfileQuery, Types.UserHasCompletedProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UserHasCompletedProfileQuery, Types.UserHasCompletedProfileQueryVariables>(UserHasCompletedProfileDocument, options);
        }
export type UserHasCompletedProfileQueryHookResult = ReturnType<typeof useUserHasCompletedProfileQuery>;
export type UserHasCompletedProfileLazyQueryHookResult = ReturnType<typeof useUserHasCompletedProfileLazyQuery>;
export type UserHasCompletedProfileSuspenseQueryHookResult = ReturnType<typeof useUserHasCompletedProfileSuspenseQuery>;
export type UserHasCompletedProfileQueryResult = Apollo.QueryResult<Types.UserHasCompletedProfileQuery, Types.UserHasCompletedProfileQueryVariables>;
export const VerifyReceivedCodeStepDocument = gql`
    query VerifyReceivedCodeStep {
  viewer {
    user {
      ... on User {
        isPrimaryEmailVerified
      }
    }
  }
}
    `;

/**
 * __useVerifyReceivedCodeStepQuery__
 *
 * To run a query within a React component, call `useVerifyReceivedCodeStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyReceivedCodeStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyReceivedCodeStepQuery({
 *   variables: {
 *   },
 * });
 */
export function useVerifyReceivedCodeStepQuery(baseOptions?: Apollo.QueryHookOptions<Types.VerifyReceivedCodeStepQuery, Types.VerifyReceivedCodeStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.VerifyReceivedCodeStepQuery, Types.VerifyReceivedCodeStepQueryVariables>(VerifyReceivedCodeStepDocument, options);
      }
export function useVerifyReceivedCodeStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.VerifyReceivedCodeStepQuery, Types.VerifyReceivedCodeStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.VerifyReceivedCodeStepQuery, Types.VerifyReceivedCodeStepQueryVariables>(VerifyReceivedCodeStepDocument, options);
        }
export function useVerifyReceivedCodeStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.VerifyReceivedCodeStepQuery, Types.VerifyReceivedCodeStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.VerifyReceivedCodeStepQuery, Types.VerifyReceivedCodeStepQueryVariables>(VerifyReceivedCodeStepDocument, options);
        }
export type VerifyReceivedCodeStepQueryHookResult = ReturnType<typeof useVerifyReceivedCodeStepQuery>;
export type VerifyReceivedCodeStepLazyQueryHookResult = ReturnType<typeof useVerifyReceivedCodeStepLazyQuery>;
export type VerifyReceivedCodeStepSuspenseQueryHookResult = ReturnType<typeof useVerifyReceivedCodeStepSuspenseQuery>;
export type VerifyReceivedCodeStepQueryResult = Apollo.QueryResult<Types.VerifyReceivedCodeStepQuery, Types.VerifyReceivedCodeStepQueryVariables>;
export const AutoPayEnrollmentDocument = gql`
    query AutoPayEnrollment($loanId: ID!) {
  viewer {
    borrow {
      personalLoans {
        disbursementFlow(loanId: $loanId) {
          autoPaySetup {
            title
            subtitle
            autoPayAccountList {
              id
              transferParticipantName
              transferParticipantType
            }
            autoPayInformationalCard {
              ...AppInformationalMessageCard
            }
          }
        }
      }
    }
  }
}
    ${AppInformationalMessageCardFragmentDoc}`;

/**
 * __useAutoPayEnrollmentQuery__
 *
 * To run a query within a React component, call `useAutoPayEnrollmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutoPayEnrollmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutoPayEnrollmentQuery({
 *   variables: {
 *      loanId: // value for 'loanId'
 *   },
 * });
 */
export function useAutoPayEnrollmentQuery(baseOptions: Apollo.QueryHookOptions<Types.AutoPayEnrollmentQuery, Types.AutoPayEnrollmentQueryVariables> & ({ variables: Types.AutoPayEnrollmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AutoPayEnrollmentQuery, Types.AutoPayEnrollmentQueryVariables>(AutoPayEnrollmentDocument, options);
      }
export function useAutoPayEnrollmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AutoPayEnrollmentQuery, Types.AutoPayEnrollmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AutoPayEnrollmentQuery, Types.AutoPayEnrollmentQueryVariables>(AutoPayEnrollmentDocument, options);
        }
export function useAutoPayEnrollmentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AutoPayEnrollmentQuery, Types.AutoPayEnrollmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AutoPayEnrollmentQuery, Types.AutoPayEnrollmentQueryVariables>(AutoPayEnrollmentDocument, options);
        }
export type AutoPayEnrollmentQueryHookResult = ReturnType<typeof useAutoPayEnrollmentQuery>;
export type AutoPayEnrollmentLazyQueryHookResult = ReturnType<typeof useAutoPayEnrollmentLazyQuery>;
export type AutoPayEnrollmentSuspenseQueryHookResult = ReturnType<typeof useAutoPayEnrollmentSuspenseQuery>;
export type AutoPayEnrollmentQueryResult = Apollo.QueryResult<Types.AutoPayEnrollmentQuery, Types.AutoPayEnrollmentQueryVariables>;
export const HardPullConsentPageDocument = gql`
    query HardPullConsentPage($applicationId: ID!) {
  viewer {
    borrow {
      personalLoans {
        applicationFlowDirect {
          activeApplicationStatus
          questions {
            previousQuestionType
            questionType
            nextQuestionType
            title
            subtitle
            continueLabel
            skipLabel
          }
        }
        applicationFlow {
          loanPurposeOptions {
            edges {
              node {
                title
                value
              }
            }
          }
          loanMinAndMaxDetails {
            minAmount
            maxAmount
            rangeDescription
          }
          loanTermOptions {
            value
            description
          }
        }
        application(applicationId: $applicationId) {
          status {
            status
          }
          loan {
            id
            status
          }
        }
      }
    }
  }
}
    `;

/**
 * __useHardPullConsentPageQuery__
 *
 * To run a query within a React component, call `useHardPullConsentPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHardPullConsentPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHardPullConsentPageQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useHardPullConsentPageQuery(baseOptions: Apollo.QueryHookOptions<Types.HardPullConsentPageQuery, Types.HardPullConsentPageQueryVariables> & ({ variables: Types.HardPullConsentPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HardPullConsentPageQuery, Types.HardPullConsentPageQueryVariables>(HardPullConsentPageDocument, options);
      }
export function useHardPullConsentPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HardPullConsentPageQuery, Types.HardPullConsentPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HardPullConsentPageQuery, Types.HardPullConsentPageQueryVariables>(HardPullConsentPageDocument, options);
        }
export function useHardPullConsentPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.HardPullConsentPageQuery, Types.HardPullConsentPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.HardPullConsentPageQuery, Types.HardPullConsentPageQueryVariables>(HardPullConsentPageDocument, options);
        }
export type HardPullConsentPageQueryHookResult = ReturnType<typeof useHardPullConsentPageQuery>;
export type HardPullConsentPageLazyQueryHookResult = ReturnType<typeof useHardPullConsentPageLazyQuery>;
export type HardPullConsentPageSuspenseQueryHookResult = ReturnType<typeof useHardPullConsentPageSuspenseQuery>;
export type HardPullConsentPageQueryResult = Apollo.QueryResult<Types.HardPullConsentPageQuery, Types.HardPullConsentPageQueryVariables>;
export const LoanApplicationRejectedDocument = gql`
    query LoanApplicationRejected {
  viewer {
    borrow {
      personalLoans {
        applicationFlow {
          loanDeclinedErrorScreen {
            ctaLink {
              ...Linkable
            }
            title
            subtitle
          }
        }
      }
    }
  }
}
    ${LinkableFragmentDoc}`;

/**
 * __useLoanApplicationRejectedQuery__
 *
 * To run a query within a React component, call `useLoanApplicationRejectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoanApplicationRejectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoanApplicationRejectedQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoanApplicationRejectedQuery(baseOptions?: Apollo.QueryHookOptions<Types.LoanApplicationRejectedQuery, Types.LoanApplicationRejectedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LoanApplicationRejectedQuery, Types.LoanApplicationRejectedQueryVariables>(LoanApplicationRejectedDocument, options);
      }
export function useLoanApplicationRejectedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LoanApplicationRejectedQuery, Types.LoanApplicationRejectedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LoanApplicationRejectedQuery, Types.LoanApplicationRejectedQueryVariables>(LoanApplicationRejectedDocument, options);
        }
export function useLoanApplicationRejectedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LoanApplicationRejectedQuery, Types.LoanApplicationRejectedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LoanApplicationRejectedQuery, Types.LoanApplicationRejectedQueryVariables>(LoanApplicationRejectedDocument, options);
        }
export type LoanApplicationRejectedQueryHookResult = ReturnType<typeof useLoanApplicationRejectedQuery>;
export type LoanApplicationRejectedLazyQueryHookResult = ReturnType<typeof useLoanApplicationRejectedLazyQuery>;
export type LoanApplicationRejectedSuspenseQueryHookResult = ReturnType<typeof useLoanApplicationRejectedSuspenseQuery>;
export type LoanApplicationRejectedQueryResult = Apollo.QueryResult<Types.LoanApplicationRejectedQuery, Types.LoanApplicationRejectedQueryVariables>;
export const LoanRecapStepDocument = gql`
    query LoanRecapStep($loanId: ID) {
  viewer {
    borrow {
      personalLoans {
        disbursementFlow(loanId: $loanId) {
          reviewLoanScreen {
            details {
              label
              value
            }
            customizedMessage
            loanAgreementDocLink {
              ...Linkable
            }
          }
        }
      }
    }
  }
}
    ${LinkableFragmentDoc}`;

/**
 * __useLoanRecapStepQuery__
 *
 * To run a query within a React component, call `useLoanRecapStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoanRecapStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoanRecapStepQuery({
 *   variables: {
 *      loanId: // value for 'loanId'
 *   },
 * });
 */
export function useLoanRecapStepQuery(baseOptions?: Apollo.QueryHookOptions<Types.LoanRecapStepQuery, Types.LoanRecapStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LoanRecapStepQuery, Types.LoanRecapStepQueryVariables>(LoanRecapStepDocument, options);
      }
export function useLoanRecapStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LoanRecapStepQuery, Types.LoanRecapStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LoanRecapStepQuery, Types.LoanRecapStepQueryVariables>(LoanRecapStepDocument, options);
        }
export function useLoanRecapStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LoanRecapStepQuery, Types.LoanRecapStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LoanRecapStepQuery, Types.LoanRecapStepQueryVariables>(LoanRecapStepDocument, options);
        }
export type LoanRecapStepQueryHookResult = ReturnType<typeof useLoanRecapStepQuery>;
export type LoanRecapStepLazyQueryHookResult = ReturnType<typeof useLoanRecapStepLazyQuery>;
export type LoanRecapStepSuspenseQueryHookResult = ReturnType<typeof useLoanRecapStepSuspenseQuery>;
export type LoanRecapStepQueryResult = Apollo.QueryResult<Types.LoanRecapStepQuery, Types.LoanRecapStepQueryVariables>;
export const PersonalLoanAnnouncementsDocument = gql`
    query PersonalLoanAnnouncements {
  viewer {
    announcements {
      forPersonalLoans {
        ...Announcement
      }
    }
  }
}
    ${AnnouncementFragmentDoc}`;

/**
 * __usePersonalLoanAnnouncementsQuery__
 *
 * To run a query within a React component, call `usePersonalLoanAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalLoanAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalLoanAnnouncementsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalLoanAnnouncementsQuery(baseOptions?: Apollo.QueryHookOptions<Types.PersonalLoanAnnouncementsQuery, Types.PersonalLoanAnnouncementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonalLoanAnnouncementsQuery, Types.PersonalLoanAnnouncementsQueryVariables>(PersonalLoanAnnouncementsDocument, options);
      }
export function usePersonalLoanAnnouncementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonalLoanAnnouncementsQuery, Types.PersonalLoanAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonalLoanAnnouncementsQuery, Types.PersonalLoanAnnouncementsQueryVariables>(PersonalLoanAnnouncementsDocument, options);
        }
export function usePersonalLoanAnnouncementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PersonalLoanAnnouncementsQuery, Types.PersonalLoanAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonalLoanAnnouncementsQuery, Types.PersonalLoanAnnouncementsQueryVariables>(PersonalLoanAnnouncementsDocument, options);
        }
export type PersonalLoanAnnouncementsQueryHookResult = ReturnType<typeof usePersonalLoanAnnouncementsQuery>;
export type PersonalLoanAnnouncementsLazyQueryHookResult = ReturnType<typeof usePersonalLoanAnnouncementsLazyQuery>;
export type PersonalLoanAnnouncementsSuspenseQueryHookResult = ReturnType<typeof usePersonalLoanAnnouncementsSuspenseQuery>;
export type PersonalLoanAnnouncementsQueryResult = Apollo.QueryResult<Types.PersonalLoanAnnouncementsQuery, Types.PersonalLoanAnnouncementsQueryVariables>;
export const PersonalLoanCommonTransactionsDocument = gql`
    query PersonalLoanCommonTransactions($loanId: ID!, $first: Int!, $after: String) {
  node(id: $loanId) {
    ... on PersonalLoan {
      personalLoanProductLandingPage {
        transactions(first: $first, after: $after) {
          ...CommonTransactions
        }
        transactionsEmptyState {
          ...CommonTransactionsEmptyState
        }
      }
    }
  }
}
    ${CommonTransactionsFragmentDoc}
${CommonTransactionsEmptyStateFragmentDoc}`;

/**
 * __usePersonalLoanCommonTransactionsQuery__
 *
 * To run a query within a React component, call `usePersonalLoanCommonTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalLoanCommonTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalLoanCommonTransactionsQuery({
 *   variables: {
 *      loanId: // value for 'loanId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePersonalLoanCommonTransactionsQuery(baseOptions: Apollo.QueryHookOptions<Types.PersonalLoanCommonTransactionsQuery, Types.PersonalLoanCommonTransactionsQueryVariables> & ({ variables: Types.PersonalLoanCommonTransactionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonalLoanCommonTransactionsQuery, Types.PersonalLoanCommonTransactionsQueryVariables>(PersonalLoanCommonTransactionsDocument, options);
      }
export function usePersonalLoanCommonTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonalLoanCommonTransactionsQuery, Types.PersonalLoanCommonTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonalLoanCommonTransactionsQuery, Types.PersonalLoanCommonTransactionsQueryVariables>(PersonalLoanCommonTransactionsDocument, options);
        }
export function usePersonalLoanCommonTransactionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PersonalLoanCommonTransactionsQuery, Types.PersonalLoanCommonTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonalLoanCommonTransactionsQuery, Types.PersonalLoanCommonTransactionsQueryVariables>(PersonalLoanCommonTransactionsDocument, options);
        }
export type PersonalLoanCommonTransactionsQueryHookResult = ReturnType<typeof usePersonalLoanCommonTransactionsQuery>;
export type PersonalLoanCommonTransactionsLazyQueryHookResult = ReturnType<typeof usePersonalLoanCommonTransactionsLazyQuery>;
export type PersonalLoanCommonTransactionsSuspenseQueryHookResult = ReturnType<typeof usePersonalLoanCommonTransactionsSuspenseQuery>;
export type PersonalLoanCommonTransactionsQueryResult = Apollo.QueryResult<Types.PersonalLoanCommonTransactionsQuery, Types.PersonalLoanCommonTransactionsQueryVariables>;
export const PersonalLoansDirectDocument = gql`
    query PersonalLoansDirect {
  viewer {
    borrow {
      personalLoans {
        applicationFlowDirect {
          activeApplicationStatus
          questions {
            previousQuestionType
            questionType
            nextQuestionType
            title
            subtitle
            continueLabel
            skipLabel
            link {
              ...AppLink
            }
            image {
              ...AppImage
            }
          }
        }
      }
    }
  }
}
    ${AppLinkFragmentDoc}
${AppImageFragmentDoc}`;

/**
 * __usePersonalLoansDirectQuery__
 *
 * To run a query within a React component, call `usePersonalLoansDirectQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalLoansDirectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalLoansDirectQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalLoansDirectQuery(baseOptions?: Apollo.QueryHookOptions<Types.PersonalLoansDirectQuery, Types.PersonalLoansDirectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonalLoansDirectQuery, Types.PersonalLoansDirectQueryVariables>(PersonalLoansDirectDocument, options);
      }
export function usePersonalLoansDirectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonalLoansDirectQuery, Types.PersonalLoansDirectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonalLoansDirectQuery, Types.PersonalLoansDirectQueryVariables>(PersonalLoansDirectDocument, options);
        }
export function usePersonalLoansDirectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PersonalLoansDirectQuery, Types.PersonalLoansDirectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonalLoansDirectQuery, Types.PersonalLoansDirectQueryVariables>(PersonalLoansDirectDocument, options);
        }
export type PersonalLoansDirectQueryHookResult = ReturnType<typeof usePersonalLoansDirectQuery>;
export type PersonalLoansDirectLazyQueryHookResult = ReturnType<typeof usePersonalLoansDirectLazyQuery>;
export type PersonalLoansDirectSuspenseQueryHookResult = ReturnType<typeof usePersonalLoansDirectSuspenseQuery>;
export type PersonalLoansDirectQueryResult = Apollo.QueryResult<Types.PersonalLoansDirectQuery, Types.PersonalLoansDirectQueryVariables>;
export const PersonalLoanDirectPreApprovedOffersPageDocument = gql`
    query PersonalLoanDirectPreApprovedOffersPage {
  viewer {
    borrow {
      personalLoans {
        applicationFlowDirect {
          activeApplicationStatus
          questions {
            previousQuestionType
            questionType
            nextQuestionType
            title
            subtitle
            continueLabel
            skipLabel
          }
        }
        activeApplication {
          id
          applicationId
          creditCheckDisclaimer
          loanDisclosure
          applicationOffers {
            edges {
              node {
                amount
                formattedAmount
                id
                annualPercentageRate
                formattedAnnualPercentageInfo {
                  formattedValue
                }
                monthlyPayment
                formattedMonthlyPayment
                formattedMonthlyDuration
                monthlyDuration
                offerSubtitle
                offerTitle
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePersonalLoanDirectPreApprovedOffersPageQuery__
 *
 * To run a query within a React component, call `usePersonalLoanDirectPreApprovedOffersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalLoanDirectPreApprovedOffersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalLoanDirectPreApprovedOffersPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalLoanDirectPreApprovedOffersPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.PersonalLoanDirectPreApprovedOffersPageQuery, Types.PersonalLoanDirectPreApprovedOffersPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonalLoanDirectPreApprovedOffersPageQuery, Types.PersonalLoanDirectPreApprovedOffersPageQueryVariables>(PersonalLoanDirectPreApprovedOffersPageDocument, options);
      }
export function usePersonalLoanDirectPreApprovedOffersPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonalLoanDirectPreApprovedOffersPageQuery, Types.PersonalLoanDirectPreApprovedOffersPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonalLoanDirectPreApprovedOffersPageQuery, Types.PersonalLoanDirectPreApprovedOffersPageQueryVariables>(PersonalLoanDirectPreApprovedOffersPageDocument, options);
        }
export function usePersonalLoanDirectPreApprovedOffersPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PersonalLoanDirectPreApprovedOffersPageQuery, Types.PersonalLoanDirectPreApprovedOffersPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonalLoanDirectPreApprovedOffersPageQuery, Types.PersonalLoanDirectPreApprovedOffersPageQueryVariables>(PersonalLoanDirectPreApprovedOffersPageDocument, options);
        }
export type PersonalLoanDirectPreApprovedOffersPageQueryHookResult = ReturnType<typeof usePersonalLoanDirectPreApprovedOffersPageQuery>;
export type PersonalLoanDirectPreApprovedOffersPageLazyQueryHookResult = ReturnType<typeof usePersonalLoanDirectPreApprovedOffersPageLazyQuery>;
export type PersonalLoanDirectPreApprovedOffersPageSuspenseQueryHookResult = ReturnType<typeof usePersonalLoanDirectPreApprovedOffersPageSuspenseQuery>;
export type PersonalLoanDirectPreApprovedOffersPageQueryResult = Apollo.QueryResult<Types.PersonalLoanDirectPreApprovedOffersPageQuery, Types.PersonalLoanDirectPreApprovedOffersPageQueryVariables>;
export const PersonalLoanLandingPageGraphDetailsDocument = gql`
    query PersonalLoanLandingPageGraphDetails($loanId: ID!) {
  node(id: $loanId) {
    ... on PersonalLoan {
      personalLoanProductLandingPage {
        graphDetails {
          ...PersonalLoanGraphDeatils
        }
      }
    }
  }
}
    ${PersonalLoanGraphDeatilsFragmentDoc}`;

/**
 * __usePersonalLoanLandingPageGraphDetailsQuery__
 *
 * To run a query within a React component, call `usePersonalLoanLandingPageGraphDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalLoanLandingPageGraphDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalLoanLandingPageGraphDetailsQuery({
 *   variables: {
 *      loanId: // value for 'loanId'
 *   },
 * });
 */
export function usePersonalLoanLandingPageGraphDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.PersonalLoanLandingPageGraphDetailsQuery, Types.PersonalLoanLandingPageGraphDetailsQueryVariables> & ({ variables: Types.PersonalLoanLandingPageGraphDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonalLoanLandingPageGraphDetailsQuery, Types.PersonalLoanLandingPageGraphDetailsQueryVariables>(PersonalLoanLandingPageGraphDetailsDocument, options);
      }
export function usePersonalLoanLandingPageGraphDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonalLoanLandingPageGraphDetailsQuery, Types.PersonalLoanLandingPageGraphDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonalLoanLandingPageGraphDetailsQuery, Types.PersonalLoanLandingPageGraphDetailsQueryVariables>(PersonalLoanLandingPageGraphDetailsDocument, options);
        }
export function usePersonalLoanLandingPageGraphDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PersonalLoanLandingPageGraphDetailsQuery, Types.PersonalLoanLandingPageGraphDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonalLoanLandingPageGraphDetailsQuery, Types.PersonalLoanLandingPageGraphDetailsQueryVariables>(PersonalLoanLandingPageGraphDetailsDocument, options);
        }
export type PersonalLoanLandingPageGraphDetailsQueryHookResult = ReturnType<typeof usePersonalLoanLandingPageGraphDetailsQuery>;
export type PersonalLoanLandingPageGraphDetailsLazyQueryHookResult = ReturnType<typeof usePersonalLoanLandingPageGraphDetailsLazyQuery>;
export type PersonalLoanLandingPageGraphDetailsSuspenseQueryHookResult = ReturnType<typeof usePersonalLoanLandingPageGraphDetailsSuspenseQuery>;
export type PersonalLoanLandingPageGraphDetailsQueryResult = Apollo.QueryResult<Types.PersonalLoanLandingPageGraphDetailsQuery, Types.PersonalLoanLandingPageGraphDetailsQueryVariables>;
export const PersonalLoanProductLandingPageBannersDocument = gql`
    query PersonalLoanProductLandingPageBanners($loanId: ID!) {
  node(id: $loanId) {
    ... on PersonalLoan {
      personalLoanProductLandingPage {
        banners {
          ...InformationBanner
        }
      }
    }
  }
}
    ${InformationBannerFragmentDoc}`;

/**
 * __usePersonalLoanProductLandingPageBannersQuery__
 *
 * To run a query within a React component, call `usePersonalLoanProductLandingPageBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalLoanProductLandingPageBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalLoanProductLandingPageBannersQuery({
 *   variables: {
 *      loanId: // value for 'loanId'
 *   },
 * });
 */
export function usePersonalLoanProductLandingPageBannersQuery(baseOptions: Apollo.QueryHookOptions<Types.PersonalLoanProductLandingPageBannersQuery, Types.PersonalLoanProductLandingPageBannersQueryVariables> & ({ variables: Types.PersonalLoanProductLandingPageBannersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonalLoanProductLandingPageBannersQuery, Types.PersonalLoanProductLandingPageBannersQueryVariables>(PersonalLoanProductLandingPageBannersDocument, options);
      }
export function usePersonalLoanProductLandingPageBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonalLoanProductLandingPageBannersQuery, Types.PersonalLoanProductLandingPageBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonalLoanProductLandingPageBannersQuery, Types.PersonalLoanProductLandingPageBannersQueryVariables>(PersonalLoanProductLandingPageBannersDocument, options);
        }
export function usePersonalLoanProductLandingPageBannersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PersonalLoanProductLandingPageBannersQuery, Types.PersonalLoanProductLandingPageBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonalLoanProductLandingPageBannersQuery, Types.PersonalLoanProductLandingPageBannersQueryVariables>(PersonalLoanProductLandingPageBannersDocument, options);
        }
export type PersonalLoanProductLandingPageBannersQueryHookResult = ReturnType<typeof usePersonalLoanProductLandingPageBannersQuery>;
export type PersonalLoanProductLandingPageBannersLazyQueryHookResult = ReturnType<typeof usePersonalLoanProductLandingPageBannersLazyQuery>;
export type PersonalLoanProductLandingPageBannersSuspenseQueryHookResult = ReturnType<typeof usePersonalLoanProductLandingPageBannersSuspenseQuery>;
export type PersonalLoanProductLandingPageBannersQueryResult = Apollo.QueryResult<Types.PersonalLoanProductLandingPageBannersQuery, Types.PersonalLoanProductLandingPageBannersQueryVariables>;
export const PersonalLoanProductLandingPageHeaderDocument = gql`
    query PersonalLoanProductLandingPageHeader($loanId: ID!) {
  node(id: $loanId) {
    ... on PersonalLoan {
      isActive
      personalLoanProductLandingPage {
        title
        header {
          icon {
            ...AppImage
          }
          title
          value
          tooltip {
            ...AppTooltip
          }
        }
        accountDetails {
          ...TabularDataItem
        }
      }
    }
  }
}
    ${AppImageFragmentDoc}
${AppTooltipFragmentDoc}
${TabularDataItemFragmentDoc}`;

/**
 * __usePersonalLoanProductLandingPageHeaderQuery__
 *
 * To run a query within a React component, call `usePersonalLoanProductLandingPageHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalLoanProductLandingPageHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalLoanProductLandingPageHeaderQuery({
 *   variables: {
 *      loanId: // value for 'loanId'
 *   },
 * });
 */
export function usePersonalLoanProductLandingPageHeaderQuery(baseOptions: Apollo.QueryHookOptions<Types.PersonalLoanProductLandingPageHeaderQuery, Types.PersonalLoanProductLandingPageHeaderQueryVariables> & ({ variables: Types.PersonalLoanProductLandingPageHeaderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonalLoanProductLandingPageHeaderQuery, Types.PersonalLoanProductLandingPageHeaderQueryVariables>(PersonalLoanProductLandingPageHeaderDocument, options);
      }
export function usePersonalLoanProductLandingPageHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonalLoanProductLandingPageHeaderQuery, Types.PersonalLoanProductLandingPageHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonalLoanProductLandingPageHeaderQuery, Types.PersonalLoanProductLandingPageHeaderQueryVariables>(PersonalLoanProductLandingPageHeaderDocument, options);
        }
export function usePersonalLoanProductLandingPageHeaderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PersonalLoanProductLandingPageHeaderQuery, Types.PersonalLoanProductLandingPageHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonalLoanProductLandingPageHeaderQuery, Types.PersonalLoanProductLandingPageHeaderQueryVariables>(PersonalLoanProductLandingPageHeaderDocument, options);
        }
export type PersonalLoanProductLandingPageHeaderQueryHookResult = ReturnType<typeof usePersonalLoanProductLandingPageHeaderQuery>;
export type PersonalLoanProductLandingPageHeaderLazyQueryHookResult = ReturnType<typeof usePersonalLoanProductLandingPageHeaderLazyQuery>;
export type PersonalLoanProductLandingPageHeaderSuspenseQueryHookResult = ReturnType<typeof usePersonalLoanProductLandingPageHeaderSuspenseQuery>;
export type PersonalLoanProductLandingPageHeaderQueryResult = Apollo.QueryResult<Types.PersonalLoanProductLandingPageHeaderQuery, Types.PersonalLoanProductLandingPageHeaderQueryVariables>;
export const PersonalLoanProductLandingPageLoanDetailsDocument = gql`
    query PersonalLoanProductLandingPageLoanDetails($loanId: ID!) {
  node(id: $loanId) {
    ... on PersonalLoan {
      assets {
        equalHousingIcon {
          ...AppImage
        }
      }
      personalLoanProductLandingPage {
        loanDetails {
          header
          details {
            title
            titleLink {
              ...AppLink
            }
            value
            valueLink {
              ...AppLink
            }
            tooltip {
              ...AppTooltip
            }
          }
        }
      }
    }
  }
}
    ${AppImageFragmentDoc}
${AppLinkFragmentDoc}
${AppTooltipFragmentDoc}`;

/**
 * __usePersonalLoanProductLandingPageLoanDetailsQuery__
 *
 * To run a query within a React component, call `usePersonalLoanProductLandingPageLoanDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalLoanProductLandingPageLoanDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalLoanProductLandingPageLoanDetailsQuery({
 *   variables: {
 *      loanId: // value for 'loanId'
 *   },
 * });
 */
export function usePersonalLoanProductLandingPageLoanDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.PersonalLoanProductLandingPageLoanDetailsQuery, Types.PersonalLoanProductLandingPageLoanDetailsQueryVariables> & ({ variables: Types.PersonalLoanProductLandingPageLoanDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonalLoanProductLandingPageLoanDetailsQuery, Types.PersonalLoanProductLandingPageLoanDetailsQueryVariables>(PersonalLoanProductLandingPageLoanDetailsDocument, options);
      }
export function usePersonalLoanProductLandingPageLoanDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonalLoanProductLandingPageLoanDetailsQuery, Types.PersonalLoanProductLandingPageLoanDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonalLoanProductLandingPageLoanDetailsQuery, Types.PersonalLoanProductLandingPageLoanDetailsQueryVariables>(PersonalLoanProductLandingPageLoanDetailsDocument, options);
        }
export function usePersonalLoanProductLandingPageLoanDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PersonalLoanProductLandingPageLoanDetailsQuery, Types.PersonalLoanProductLandingPageLoanDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonalLoanProductLandingPageLoanDetailsQuery, Types.PersonalLoanProductLandingPageLoanDetailsQueryVariables>(PersonalLoanProductLandingPageLoanDetailsDocument, options);
        }
export type PersonalLoanProductLandingPageLoanDetailsQueryHookResult = ReturnType<typeof usePersonalLoanProductLandingPageLoanDetailsQuery>;
export type PersonalLoanProductLandingPageLoanDetailsLazyQueryHookResult = ReturnType<typeof usePersonalLoanProductLandingPageLoanDetailsLazyQuery>;
export type PersonalLoanProductLandingPageLoanDetailsSuspenseQueryHookResult = ReturnType<typeof usePersonalLoanProductLandingPageLoanDetailsSuspenseQuery>;
export type PersonalLoanProductLandingPageLoanDetailsQueryResult = Apollo.QueryResult<Types.PersonalLoanProductLandingPageLoanDetailsQuery, Types.PersonalLoanProductLandingPageLoanDetailsQueryVariables>;
export const PersonalLoansPromptForBankConnectionDocument = gql`
    query PersonalLoansPromptForBankConnection($loanId: ID!) {
  viewer {
    borrow {
      personalLoans {
        disbursementFlow(loanId: $loanId) {
          hasDisbursementOption
          promptForBankConnection {
            image {
              ...AppImage
            }
            header
            subheader
            subheaderImage {
              names
            }
            primaryContinueLink {
              ...Linkable
            }
            secondaryContinueLink {
              ...Linkable
            }
          }
        }
      }
    }
  }
}
    ${AppImageFragmentDoc}
${LinkableFragmentDoc}`;

/**
 * __usePersonalLoansPromptForBankConnectionQuery__
 *
 * To run a query within a React component, call `usePersonalLoansPromptForBankConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalLoansPromptForBankConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalLoansPromptForBankConnectionQuery({
 *   variables: {
 *      loanId: // value for 'loanId'
 *   },
 * });
 */
export function usePersonalLoansPromptForBankConnectionQuery(baseOptions: Apollo.QueryHookOptions<Types.PersonalLoansPromptForBankConnectionQuery, Types.PersonalLoansPromptForBankConnectionQueryVariables> & ({ variables: Types.PersonalLoansPromptForBankConnectionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonalLoansPromptForBankConnectionQuery, Types.PersonalLoansPromptForBankConnectionQueryVariables>(PersonalLoansPromptForBankConnectionDocument, options);
      }
export function usePersonalLoansPromptForBankConnectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonalLoansPromptForBankConnectionQuery, Types.PersonalLoansPromptForBankConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonalLoansPromptForBankConnectionQuery, Types.PersonalLoansPromptForBankConnectionQueryVariables>(PersonalLoansPromptForBankConnectionDocument, options);
        }
export function usePersonalLoansPromptForBankConnectionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PersonalLoansPromptForBankConnectionQuery, Types.PersonalLoansPromptForBankConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonalLoansPromptForBankConnectionQuery, Types.PersonalLoansPromptForBankConnectionQueryVariables>(PersonalLoansPromptForBankConnectionDocument, options);
        }
export type PersonalLoansPromptForBankConnectionQueryHookResult = ReturnType<typeof usePersonalLoansPromptForBankConnectionQuery>;
export type PersonalLoansPromptForBankConnectionLazyQueryHookResult = ReturnType<typeof usePersonalLoansPromptForBankConnectionLazyQuery>;
export type PersonalLoansPromptForBankConnectionSuspenseQueryHookResult = ReturnType<typeof usePersonalLoansPromptForBankConnectionSuspenseQuery>;
export type PersonalLoansPromptForBankConnectionQueryResult = Apollo.QueryResult<Types.PersonalLoansPromptForBankConnectionQuery, Types.PersonalLoansPromptForBankConnectionQueryVariables>;
export const NewPieEditorDocument = gql`
    query NewPieEditor {
  viewer {
    user {
      ...UserKeys
    }
  }
}
    ${UserKeysFragmentDoc}`;

/**
 * __useNewPieEditorQuery__
 *
 * To run a query within a React component, call `useNewPieEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewPieEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewPieEditorQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewPieEditorQuery(baseOptions?: Apollo.QueryHookOptions<Types.NewPieEditorQuery, Types.NewPieEditorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NewPieEditorQuery, Types.NewPieEditorQueryVariables>(NewPieEditorDocument, options);
      }
export function useNewPieEditorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NewPieEditorQuery, Types.NewPieEditorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NewPieEditorQuery, Types.NewPieEditorQueryVariables>(NewPieEditorDocument, options);
        }
export function useNewPieEditorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.NewPieEditorQuery, Types.NewPieEditorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.NewPieEditorQuery, Types.NewPieEditorQueryVariables>(NewPieEditorDocument, options);
        }
export type NewPieEditorQueryHookResult = ReturnType<typeof useNewPieEditorQuery>;
export type NewPieEditorLazyQueryHookResult = ReturnType<typeof useNewPieEditorLazyQuery>;
export type NewPieEditorSuspenseQueryHookResult = ReturnType<typeof useNewPieEditorSuspenseQuery>;
export type NewPieEditorQueryResult = Apollo.QueryResult<Types.NewPieEditorQuery, Types.NewPieEditorQueryVariables>;
export const PieEditModelDocument = gql`
    query PieEditModel($pieEditorRouteParam: ID!) {
  node(id: $pieEditorRouteParam) {
    __typename
    ... on UserPie {
      editModel(mode: NO_CLONE_FULL_DEPTH)
      containsCrypto
    }
    ... on SystemPie {
      editModel(mode: NO_CLONE_FULL_DEPTH)
      containsCrypto
    }
    ... on ChildPortfolioSlice {
      rootAncestor {
        stringifiedModel
        to {
          editModel(mode: NO_CLONE_FULL_DEPTH)
          containsCrypto
        }
      }
    }
    ... on RootPortfolioSlice {
      stringifiedModel
      to {
        editModel(mode: NO_CLONE_FULL_DEPTH)
        containsCrypto
      }
    }
    ... on Account {
      rootPortfolioSlice {
        stringifiedModel
        to {
          editModel(mode: NO_CLONE_FULL_DEPTH)
          containsCrypto
        }
      }
    }
  }
}
    `;

/**
 * __usePieEditModelQuery__
 *
 * To run a query within a React component, call `usePieEditModelQuery` and pass it any options that fit your needs.
 * When your component renders, `usePieEditModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePieEditModelQuery({
 *   variables: {
 *      pieEditorRouteParam: // value for 'pieEditorRouteParam'
 *   },
 * });
 */
export function usePieEditModelQuery(baseOptions: Apollo.QueryHookOptions<Types.PieEditModelQuery, Types.PieEditModelQueryVariables> & ({ variables: Types.PieEditModelQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PieEditModelQuery, Types.PieEditModelQueryVariables>(PieEditModelDocument, options);
      }
export function usePieEditModelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PieEditModelQuery, Types.PieEditModelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PieEditModelQuery, Types.PieEditModelQueryVariables>(PieEditModelDocument, options);
        }
export function usePieEditModelSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PieEditModelQuery, Types.PieEditModelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PieEditModelQuery, Types.PieEditModelQueryVariables>(PieEditModelDocument, options);
        }
export type PieEditModelQueryHookResult = ReturnType<typeof usePieEditModelQuery>;
export type PieEditModelLazyQueryHookResult = ReturnType<typeof usePieEditModelLazyQuery>;
export type PieEditModelSuspenseQueryHookResult = ReturnType<typeof usePieEditModelSuspenseQuery>;
export type PieEditModelQueryResult = Apollo.QueryResult<Types.PieEditModelQuery, Types.PieEditModelQueryVariables>;
export const PiePerformanceDocument = gql`
    query PiePerformance($pieId: ID!, $period: PieBacktestPeriodEnum!) {
  pie: node(id: $pieId) {
    ... on Pie {
      analysis {
        ...PieAnalysis
      }
    }
  }
}
    ${PieAnalysisFragmentDoc}`;

/**
 * __usePiePerformanceQuery__
 *
 * To run a query within a React component, call `usePiePerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePiePerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePiePerformanceQuery({
 *   variables: {
 *      pieId: // value for 'pieId'
 *      period: // value for 'period'
 *   },
 * });
 */
export function usePiePerformanceQuery(baseOptions: Apollo.QueryHookOptions<Types.PiePerformanceQuery, Types.PiePerformanceQueryVariables> & ({ variables: Types.PiePerformanceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PiePerformanceQuery, Types.PiePerformanceQueryVariables>(PiePerformanceDocument, options);
      }
export function usePiePerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PiePerformanceQuery, Types.PiePerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PiePerformanceQuery, Types.PiePerformanceQueryVariables>(PiePerformanceDocument, options);
        }
export function usePiePerformanceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PiePerformanceQuery, Types.PiePerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PiePerformanceQuery, Types.PiePerformanceQueryVariables>(PiePerformanceDocument, options);
        }
export type PiePerformanceQueryHookResult = ReturnType<typeof usePiePerformanceQuery>;
export type PiePerformanceLazyQueryHookResult = ReturnType<typeof usePiePerformanceLazyQuery>;
export type PiePerformanceSuspenseQueryHookResult = ReturnType<typeof usePiePerformanceSuspenseQuery>;
export type PiePerformanceQueryResult = Apollo.QueryResult<Types.PiePerformanceQuery, Types.PiePerformanceQueryVariables>;
export const SliceableContentDocument = gql`
    query SliceableContent($sliceableId: ID!) {
  node(id: $sliceableId) {
    __typename
    ... on Sliceable {
      ...SecurityDetail
      type
      isActive
      name
      portfolioLinks {
        id
        ...SliceAvatarStatus
      }
    }
    ... on Security {
      name
      symbol
      profile {
        logoUrl
      }
    }
    ... on CryptoAsset {
      name
      symbol
      profile {
        logoUrl
      }
    }
  }
}
    ${SecurityDetailFragmentDoc}
${SliceAvatarStatusFragmentDoc}`;

/**
 * __useSliceableContentQuery__
 *
 * To run a query within a React component, call `useSliceableContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSliceableContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSliceableContentQuery({
 *   variables: {
 *      sliceableId: // value for 'sliceableId'
 *   },
 * });
 */
export function useSliceableContentQuery(baseOptions: Apollo.QueryHookOptions<Types.SliceableContentQuery, Types.SliceableContentQueryVariables> & ({ variables: Types.SliceableContentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SliceableContentQuery, Types.SliceableContentQueryVariables>(SliceableContentDocument, options);
      }
export function useSliceableContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SliceableContentQuery, Types.SliceableContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SliceableContentQuery, Types.SliceableContentQueryVariables>(SliceableContentDocument, options);
        }
export function useSliceableContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SliceableContentQuery, Types.SliceableContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SliceableContentQuery, Types.SliceableContentQueryVariables>(SliceableContentDocument, options);
        }
export type SliceableContentQueryHookResult = ReturnType<typeof useSliceableContentQuery>;
export type SliceableContentLazyQueryHookResult = ReturnType<typeof useSliceableContentLazyQuery>;
export type SliceableContentSuspenseQueryHookResult = ReturnType<typeof useSliceableContentSuspenseQuery>;
export type SliceableContentQueryResult = Apollo.QueryResult<Types.SliceableContentQuery, Types.SliceableContentQueryVariables>;
export const PlatformBillingSettingsPageDocument = gql`
    query PlatformBillingSettingsPage($first: Int!, $after: String) {
  viewer {
    settings {
      platformBilling {
        header {
          title
          subtitles
        }
        billingHistorySection {
          ...PlatformBillingHistorySection
        }
        billingMethodSection {
          ...BillingMethodSection
        }
        paymentFailureBanner {
          ...InformationBanner
        }
      }
    }
  }
}
    ${PlatformBillingHistorySectionFragmentDoc}
${BillingMethodSectionFragmentDoc}
${InformationBannerFragmentDoc}`;

/**
 * __usePlatformBillingSettingsPageQuery__
 *
 * To run a query within a React component, call `usePlatformBillingSettingsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlatformBillingSettingsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlatformBillingSettingsPageQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePlatformBillingSettingsPageQuery(baseOptions: Apollo.QueryHookOptions<Types.PlatformBillingSettingsPageQuery, Types.PlatformBillingSettingsPageQueryVariables> & ({ variables: Types.PlatformBillingSettingsPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PlatformBillingSettingsPageQuery, Types.PlatformBillingSettingsPageQueryVariables>(PlatformBillingSettingsPageDocument, options);
      }
export function usePlatformBillingSettingsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PlatformBillingSettingsPageQuery, Types.PlatformBillingSettingsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PlatformBillingSettingsPageQuery, Types.PlatformBillingSettingsPageQueryVariables>(PlatformBillingSettingsPageDocument, options);
        }
export function usePlatformBillingSettingsPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PlatformBillingSettingsPageQuery, Types.PlatformBillingSettingsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PlatformBillingSettingsPageQuery, Types.PlatformBillingSettingsPageQueryVariables>(PlatformBillingSettingsPageDocument, options);
        }
export type PlatformBillingSettingsPageQueryHookResult = ReturnType<typeof usePlatformBillingSettingsPageQuery>;
export type PlatformBillingSettingsPageLazyQueryHookResult = ReturnType<typeof usePlatformBillingSettingsPageLazyQuery>;
export type PlatformBillingSettingsPageSuspenseQueryHookResult = ReturnType<typeof usePlatformBillingSettingsPageSuspenseQuery>;
export type PlatformBillingSettingsPageQueryResult = Apollo.QueryResult<Types.PlatformBillingSettingsPageQuery, Types.PlatformBillingSettingsPageQueryVariables>;
export const PaymentsPageDocument = gql`
    query PaymentsPage {
  viewer {
    user {
      id
      billing {
        selectedPaymentsAccount {
          account {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePaymentsPageQuery__
 *
 * To run a query within a React component, call `usePaymentsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentsPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.PaymentsPageQuery, Types.PaymentsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PaymentsPageQuery, Types.PaymentsPageQueryVariables>(PaymentsPageDocument, options);
      }
export function usePaymentsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PaymentsPageQuery, Types.PaymentsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PaymentsPageQuery, Types.PaymentsPageQueryVariables>(PaymentsPageDocument, options);
        }
export function usePaymentsPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PaymentsPageQuery, Types.PaymentsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PaymentsPageQuery, Types.PaymentsPageQueryVariables>(PaymentsPageDocument, options);
        }
export type PaymentsPageQueryHookResult = ReturnType<typeof usePaymentsPageQuery>;
export type PaymentsPageLazyQueryHookResult = ReturnType<typeof usePaymentsPageLazyQuery>;
export type PaymentsPageSuspenseQueryHookResult = ReturnType<typeof usePaymentsPageSuspenseQuery>;
export type PaymentsPageQueryResult = Apollo.QueryResult<Types.PaymentsPageQuery, Types.PaymentsPageQueryVariables>;
export const ProductSelectionPageDocument = gql`
    query ProductSelectionPage {
  viewer {
    onboarding {
      onboardingInvest {
        iraOnboarding {
          directToIRAOnboardingEnabled
        }
      }
      productSelection {
        title
        products {
          identifier
          destination
          header
          subheader
          ctaLabel
          icon {
            ...AppImage
          }
          illustrationName
          illustration {
            ...AppImage
          }
          analyticsEventClick {
            ...AnalyticsEvent
          }
          analyticsEvent {
            ...AnalyticsEvent
          }
          disclosure {
            label
            content
          }
        }
      }
    }
  }
}
    ${AppImageFragmentDoc}
${AnalyticsEventFragmentDoc}`;

/**
 * __useProductSelectionPageQuery__
 *
 * To run a query within a React component, call `useProductSelectionPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSelectionPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSelectionPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductSelectionPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.ProductSelectionPageQuery, Types.ProductSelectionPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductSelectionPageQuery, Types.ProductSelectionPageQueryVariables>(ProductSelectionPageDocument, options);
      }
export function useProductSelectionPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductSelectionPageQuery, Types.ProductSelectionPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductSelectionPageQuery, Types.ProductSelectionPageQueryVariables>(ProductSelectionPageDocument, options);
        }
export function useProductSelectionPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProductSelectionPageQuery, Types.ProductSelectionPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProductSelectionPageQuery, Types.ProductSelectionPageQueryVariables>(ProductSelectionPageDocument, options);
        }
export type ProductSelectionPageQueryHookResult = ReturnType<typeof useProductSelectionPageQuery>;
export type ProductSelectionPageLazyQueryHookResult = ReturnType<typeof useProductSelectionPageLazyQuery>;
export type ProductSelectionPageSuspenseQueryHookResult = ReturnType<typeof useProductSelectionPageSuspenseQuery>;
export type ProductSelectionPageQueryResult = Apollo.QueryResult<Types.ProductSelectionPageQuery, Types.ProductSelectionPageQueryVariables>;
export const CancelOrderModalDocument = gql`
    query CancelOrderModal($portfolioSliceId: ID!) {
  node(id: $portfolioSliceId) {
    ... on PortfolioSlice {
      to {
        name
      }
      orderStatus {
        order {
          cashFlow
        }
      }
    }
  }
}
    `;

/**
 * __useCancelOrderModalQuery__
 *
 * To run a query within a React component, call `useCancelOrderModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCancelOrderModalQuery({
 *   variables: {
 *      portfolioSliceId: // value for 'portfolioSliceId'
 *   },
 * });
 */
export function useCancelOrderModalQuery(baseOptions: Apollo.QueryHookOptions<Types.CancelOrderModalQuery, Types.CancelOrderModalQueryVariables> & ({ variables: Types.CancelOrderModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CancelOrderModalQuery, Types.CancelOrderModalQueryVariables>(CancelOrderModalDocument, options);
      }
export function useCancelOrderModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CancelOrderModalQuery, Types.CancelOrderModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CancelOrderModalQuery, Types.CancelOrderModalQueryVariables>(CancelOrderModalDocument, options);
        }
export function useCancelOrderModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CancelOrderModalQuery, Types.CancelOrderModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CancelOrderModalQuery, Types.CancelOrderModalQueryVariables>(CancelOrderModalDocument, options);
        }
export type CancelOrderModalQueryHookResult = ReturnType<typeof useCancelOrderModalQuery>;
export type CancelOrderModalLazyQueryHookResult = ReturnType<typeof useCancelOrderModalLazyQuery>;
export type CancelOrderModalSuspenseQueryHookResult = ReturnType<typeof useCancelOrderModalSuspenseQuery>;
export type CancelOrderModalQueryResult = Apollo.QueryResult<Types.CancelOrderModalQuery, Types.CancelOrderModalQueryVariables>;
export const DestinationPieStepDocument = gql`
    query DestinationPieStep($activeAccountId: ID!, $portfolioSliceIdsToMove: [ID!]!) {
  node(id: $activeAccountId) {
    ...DestinationPieAccountNode
  }
  viewer {
    user {
      ...UserKeys
    }
  }
}
    ${DestinationPieAccountNodeFragmentDoc}
${UserKeysFragmentDoc}`;

/**
 * __useDestinationPieStepQuery__
 *
 * To run a query within a React component, call `useDestinationPieStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useDestinationPieStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDestinationPieStepQuery({
 *   variables: {
 *      activeAccountId: // value for 'activeAccountId'
 *      portfolioSliceIdsToMove: // value for 'portfolioSliceIdsToMove'
 *   },
 * });
 */
export function useDestinationPieStepQuery(baseOptions: Apollo.QueryHookOptions<Types.DestinationPieStepQuery, Types.DestinationPieStepQueryVariables> & ({ variables: Types.DestinationPieStepQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DestinationPieStepQuery, Types.DestinationPieStepQueryVariables>(DestinationPieStepDocument, options);
      }
export function useDestinationPieStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DestinationPieStepQuery, Types.DestinationPieStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DestinationPieStepQuery, Types.DestinationPieStepQueryVariables>(DestinationPieStepDocument, options);
        }
export function useDestinationPieStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.DestinationPieStepQuery, Types.DestinationPieStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DestinationPieStepQuery, Types.DestinationPieStepQueryVariables>(DestinationPieStepDocument, options);
        }
export type DestinationPieStepQueryHookResult = ReturnType<typeof useDestinationPieStepQuery>;
export type DestinationPieStepLazyQueryHookResult = ReturnType<typeof useDestinationPieStepLazyQuery>;
export type DestinationPieStepSuspenseQueryHookResult = ReturnType<typeof useDestinationPieStepSuspenseQuery>;
export type DestinationPieStepQueryResult = Apollo.QueryResult<Types.DestinationPieStepQuery, Types.DestinationPieStepQueryVariables>;
export const EditPortfolioStepDocument = gql`
    query EditPortfolioStep($activeAccountId: ID!, $portfolioSliceIdsToMove: [ID!]!) {
  node(id: $activeAccountId) {
    ...EditPortfolioAccountNode
  }
  viewer {
    user {
      ...UserKeys
    }
  }
}
    ${EditPortfolioAccountNodeFragmentDoc}
${UserKeysFragmentDoc}`;

/**
 * __useEditPortfolioStepQuery__
 *
 * To run a query within a React component, call `useEditPortfolioStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPortfolioStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPortfolioStepQuery({
 *   variables: {
 *      activeAccountId: // value for 'activeAccountId'
 *      portfolioSliceIdsToMove: // value for 'portfolioSliceIdsToMove'
 *   },
 * });
 */
export function useEditPortfolioStepQuery(baseOptions: Apollo.QueryHookOptions<Types.EditPortfolioStepQuery, Types.EditPortfolioStepQueryVariables> & ({ variables: Types.EditPortfolioStepQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EditPortfolioStepQuery, Types.EditPortfolioStepQueryVariables>(EditPortfolioStepDocument, options);
      }
export function useEditPortfolioStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EditPortfolioStepQuery, Types.EditPortfolioStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EditPortfolioStepQuery, Types.EditPortfolioStepQueryVariables>(EditPortfolioStepDocument, options);
        }
export function useEditPortfolioStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EditPortfolioStepQuery, Types.EditPortfolioStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EditPortfolioStepQuery, Types.EditPortfolioStepQueryVariables>(EditPortfolioStepDocument, options);
        }
export type EditPortfolioStepQueryHookResult = ReturnType<typeof useEditPortfolioStepQuery>;
export type EditPortfolioStepLazyQueryHookResult = ReturnType<typeof useEditPortfolioStepLazyQuery>;
export type EditPortfolioStepSuspenseQueryHookResult = ReturnType<typeof useEditPortfolioStepSuspenseQuery>;
export type EditPortfolioStepQueryResult = Apollo.QueryResult<Types.EditPortfolioStepQuery, Types.EditPortfolioStepQueryVariables>;
export const GetPortfolioSliceDocument = gql`
    query GetPortfolioSlice($id: ID!) {
  node(id: $id) {
    ...PortfolioName
  }
}
    ${PortfolioNameFragmentDoc}`;

/**
 * __useGetPortfolioSliceQuery__
 *
 * To run a query within a React component, call `useGetPortfolioSliceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfolioSliceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfolioSliceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPortfolioSliceQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPortfolioSliceQuery, Types.GetPortfolioSliceQueryVariables> & ({ variables: Types.GetPortfolioSliceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPortfolioSliceQuery, Types.GetPortfolioSliceQueryVariables>(GetPortfolioSliceDocument, options);
      }
export function useGetPortfolioSliceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPortfolioSliceQuery, Types.GetPortfolioSliceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPortfolioSliceQuery, Types.GetPortfolioSliceQueryVariables>(GetPortfolioSliceDocument, options);
        }
export function useGetPortfolioSliceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetPortfolioSliceQuery, Types.GetPortfolioSliceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetPortfolioSliceQuery, Types.GetPortfolioSliceQueryVariables>(GetPortfolioSliceDocument, options);
        }
export type GetPortfolioSliceQueryHookResult = ReturnType<typeof useGetPortfolioSliceQuery>;
export type GetPortfolioSliceLazyQueryHookResult = ReturnType<typeof useGetPortfolioSliceLazyQuery>;
export type GetPortfolioSliceSuspenseQueryHookResult = ReturnType<typeof useGetPortfolioSliceSuspenseQuery>;
export type GetPortfolioSliceQueryResult = Apollo.QueryResult<Types.GetPortfolioSliceQuery, Types.GetPortfolioSliceQueryVariables>;
export const MarginBorrowTextQueryDocument = gql`
    query MarginBorrowTextQuery($portfolioSliceId: ID!, $borrowAmount: Float!) {
  node(id: $portfolioSliceId) {
    ... on PortfolioSlice {
      buyingPower {
        marginBorrowingText(borrowAmount: $borrowAmount)
      }
    }
  }
}
    `;

/**
 * __useMarginBorrowTextQueryQuery__
 *
 * To run a query within a React component, call `useMarginBorrowTextQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarginBorrowTextQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarginBorrowTextQueryQuery({
 *   variables: {
 *      portfolioSliceId: // value for 'portfolioSliceId'
 *      borrowAmount: // value for 'borrowAmount'
 *   },
 * });
 */
export function useMarginBorrowTextQueryQuery(baseOptions: Apollo.QueryHookOptions<Types.MarginBorrowTextQueryQuery, Types.MarginBorrowTextQueryQueryVariables> & ({ variables: Types.MarginBorrowTextQueryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MarginBorrowTextQueryQuery, Types.MarginBorrowTextQueryQueryVariables>(MarginBorrowTextQueryDocument, options);
      }
export function useMarginBorrowTextQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MarginBorrowTextQueryQuery, Types.MarginBorrowTextQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MarginBorrowTextQueryQuery, Types.MarginBorrowTextQueryQueryVariables>(MarginBorrowTextQueryDocument, options);
        }
export function useMarginBorrowTextQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MarginBorrowTextQueryQuery, Types.MarginBorrowTextQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MarginBorrowTextQueryQuery, Types.MarginBorrowTextQueryQueryVariables>(MarginBorrowTextQueryDocument, options);
        }
export type MarginBorrowTextQueryQueryHookResult = ReturnType<typeof useMarginBorrowTextQueryQuery>;
export type MarginBorrowTextQueryLazyQueryHookResult = ReturnType<typeof useMarginBorrowTextQueryLazyQuery>;
export type MarginBorrowTextQuerySuspenseQueryHookResult = ReturnType<typeof useMarginBorrowTextQuerySuspenseQuery>;
export type MarginBorrowTextQueryQueryResult = Apollo.QueryResult<Types.MarginBorrowTextQueryQuery, Types.MarginBorrowTextQueryQueryVariables>;
export const ModelPortfoliosCategoriesDocument = gql`
    query ModelPortfoliosCategories($filterCategory: [String!]) {
  viewer {
    screenSystemPies(filterCategory: $filterCategory) {
      subCategories {
        categoryDetails {
          key
          name
          description
          suitability
          logoUrl
          featureUrl
        }
      }
    }
  }
}
    `;

/**
 * __useModelPortfoliosCategoriesQuery__
 *
 * To run a query within a React component, call `useModelPortfoliosCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelPortfoliosCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelPortfoliosCategoriesQuery({
 *   variables: {
 *      filterCategory: // value for 'filterCategory'
 *   },
 * });
 */
export function useModelPortfoliosCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.ModelPortfoliosCategoriesQuery, Types.ModelPortfoliosCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ModelPortfoliosCategoriesQuery, Types.ModelPortfoliosCategoriesQueryVariables>(ModelPortfoliosCategoriesDocument, options);
      }
export function useModelPortfoliosCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ModelPortfoliosCategoriesQuery, Types.ModelPortfoliosCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ModelPortfoliosCategoriesQuery, Types.ModelPortfoliosCategoriesQueryVariables>(ModelPortfoliosCategoriesDocument, options);
        }
export function useModelPortfoliosCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ModelPortfoliosCategoriesQuery, Types.ModelPortfoliosCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ModelPortfoliosCategoriesQuery, Types.ModelPortfoliosCategoriesQueryVariables>(ModelPortfoliosCategoriesDocument, options);
        }
export type ModelPortfoliosCategoriesQueryHookResult = ReturnType<typeof useModelPortfoliosCategoriesQuery>;
export type ModelPortfoliosCategoriesLazyQueryHookResult = ReturnType<typeof useModelPortfoliosCategoriesLazyQuery>;
export type ModelPortfoliosCategoriesSuspenseQueryHookResult = ReturnType<typeof useModelPortfoliosCategoriesSuspenseQuery>;
export type ModelPortfoliosCategoriesQueryResult = Apollo.QueryResult<Types.ModelPortfoliosCategoriesQuery, Types.ModelPortfoliosCategoriesQueryVariables>;
export const MoveSlicesButtonRefetchDocument = gql`
    query MoveSlicesButtonRefetch($activeAccountId: ID!, $portfolioSliceIdsToMove: [ID!]!) {
  node(id: $activeAccountId) {
    ...MoveSlicesButtonRefetchAccountNode
  }
}
    ${MoveSlicesButtonRefetchAccountNodeFragmentDoc}`;

/**
 * __useMoveSlicesButtonRefetchQuery__
 *
 * To run a query within a React component, call `useMoveSlicesButtonRefetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoveSlicesButtonRefetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoveSlicesButtonRefetchQuery({
 *   variables: {
 *      activeAccountId: // value for 'activeAccountId'
 *      portfolioSliceIdsToMove: // value for 'portfolioSliceIdsToMove'
 *   },
 * });
 */
export function useMoveSlicesButtonRefetchQuery(baseOptions: Apollo.QueryHookOptions<Types.MoveSlicesButtonRefetchQuery, Types.MoveSlicesButtonRefetchQueryVariables> & ({ variables: Types.MoveSlicesButtonRefetchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MoveSlicesButtonRefetchQuery, Types.MoveSlicesButtonRefetchQueryVariables>(MoveSlicesButtonRefetchDocument, options);
      }
export function useMoveSlicesButtonRefetchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MoveSlicesButtonRefetchQuery, Types.MoveSlicesButtonRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MoveSlicesButtonRefetchQuery, Types.MoveSlicesButtonRefetchQueryVariables>(MoveSlicesButtonRefetchDocument, options);
        }
export function useMoveSlicesButtonRefetchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MoveSlicesButtonRefetchQuery, Types.MoveSlicesButtonRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MoveSlicesButtonRefetchQuery, Types.MoveSlicesButtonRefetchQueryVariables>(MoveSlicesButtonRefetchDocument, options);
        }
export type MoveSlicesButtonRefetchQueryHookResult = ReturnType<typeof useMoveSlicesButtonRefetchQuery>;
export type MoveSlicesButtonRefetchLazyQueryHookResult = ReturnType<typeof useMoveSlicesButtonRefetchLazyQuery>;
export type MoveSlicesButtonRefetchSuspenseQueryHookResult = ReturnType<typeof useMoveSlicesButtonRefetchSuspenseQuery>;
export type MoveSlicesButtonRefetchQueryResult = Apollo.QueryResult<Types.MoveSlicesButtonRefetchQuery, Types.MoveSlicesButtonRefetchQueryVariables>;
export const MoveSlicesConfirmationModalWrapperDocument = gql`
    query MoveSlicesConfirmationModalWrapper($accountId: ID!, $sourcePieSerialized: String!, $destinationPieSerialized: String!, $moveSliceIds: [ID!]!) {
  node(id: $accountId) {
    ... on Account {
      moveSlices {
        moveSlicesChangesConfirmation(
          sourcePieSerialized: $sourcePieSerialized
          destinationPieSerialized: $destinationPieSerialized
          moveSliceIds: $moveSliceIds
        ) {
          title
          cancelLabel
          confirmLabel
          sourcePieDetails {
            name
          }
          sourcePieChangesSection {
            ...SourcePieChangesSection
          }
          destinationPieChangesSection {
            ...DestinationPieChangesSection
          }
          nextAvailableTradeWindowSection {
            ...NextAvailableTradeWindowSection
          }
          infoCard {
            message
          }
        }
      }
    }
  }
}
    ${SourcePieChangesSectionFragmentDoc}
${DestinationPieChangesSectionFragmentDoc}
${NextAvailableTradeWindowSectionFragmentDoc}`;

/**
 * __useMoveSlicesConfirmationModalWrapperQuery__
 *
 * To run a query within a React component, call `useMoveSlicesConfirmationModalWrapperQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoveSlicesConfirmationModalWrapperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoveSlicesConfirmationModalWrapperQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      sourcePieSerialized: // value for 'sourcePieSerialized'
 *      destinationPieSerialized: // value for 'destinationPieSerialized'
 *      moveSliceIds: // value for 'moveSliceIds'
 *   },
 * });
 */
export function useMoveSlicesConfirmationModalWrapperQuery(baseOptions: Apollo.QueryHookOptions<Types.MoveSlicesConfirmationModalWrapperQuery, Types.MoveSlicesConfirmationModalWrapperQueryVariables> & ({ variables: Types.MoveSlicesConfirmationModalWrapperQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MoveSlicesConfirmationModalWrapperQuery, Types.MoveSlicesConfirmationModalWrapperQueryVariables>(MoveSlicesConfirmationModalWrapperDocument, options);
      }
export function useMoveSlicesConfirmationModalWrapperLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MoveSlicesConfirmationModalWrapperQuery, Types.MoveSlicesConfirmationModalWrapperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MoveSlicesConfirmationModalWrapperQuery, Types.MoveSlicesConfirmationModalWrapperQueryVariables>(MoveSlicesConfirmationModalWrapperDocument, options);
        }
export function useMoveSlicesConfirmationModalWrapperSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MoveSlicesConfirmationModalWrapperQuery, Types.MoveSlicesConfirmationModalWrapperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MoveSlicesConfirmationModalWrapperQuery, Types.MoveSlicesConfirmationModalWrapperQueryVariables>(MoveSlicesConfirmationModalWrapperDocument, options);
        }
export type MoveSlicesConfirmationModalWrapperQueryHookResult = ReturnType<typeof useMoveSlicesConfirmationModalWrapperQuery>;
export type MoveSlicesConfirmationModalWrapperLazyQueryHookResult = ReturnType<typeof useMoveSlicesConfirmationModalWrapperLazyQuery>;
export type MoveSlicesConfirmationModalWrapperSuspenseQueryHookResult = ReturnType<typeof useMoveSlicesConfirmationModalWrapperSuspenseQuery>;
export type MoveSlicesConfirmationModalWrapperQueryResult = Apollo.QueryResult<Types.MoveSlicesConfirmationModalWrapperQuery, Types.MoveSlicesConfirmationModalWrapperQueryVariables>;
export const MyPiesPageDocument = gql`
    query MyPiesPage {
  viewer {
    ... on Viewer {
      id
      userPies(first: 1000) {
        edges {
          ...MyPie
        }
      }
      userCryptoPies: userPies(first: 1000, isCrypto: true) {
        edges {
          ...MyPie
        }
      }
    }
  }
}
    ${MyPieFragmentDoc}`;

/**
 * __useMyPiesPageQuery__
 *
 * To run a query within a React component, call `useMyPiesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPiesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPiesPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyPiesPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.MyPiesPageQuery, Types.MyPiesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MyPiesPageQuery, Types.MyPiesPageQueryVariables>(MyPiesPageDocument, options);
      }
export function useMyPiesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MyPiesPageQuery, Types.MyPiesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MyPiesPageQuery, Types.MyPiesPageQueryVariables>(MyPiesPageDocument, options);
        }
export function useMyPiesPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MyPiesPageQuery, Types.MyPiesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MyPiesPageQuery, Types.MyPiesPageQueryVariables>(MyPiesPageDocument, options);
        }
export type MyPiesPageQueryHookResult = ReturnType<typeof useMyPiesPageQuery>;
export type MyPiesPageLazyQueryHookResult = ReturnType<typeof useMyPiesPageLazyQuery>;
export type MyPiesPageSuspenseQueryHookResult = ReturnType<typeof useMyPiesPageSuspenseQuery>;
export type MyPiesPageQueryResult = Apollo.QueryResult<Types.MyPiesPageQuery, Types.MyPiesPageQueryVariables>;
export const OrderLockedStepDocument = gql`
    query OrderLockedStep($id: ID!) {
  node(id: $id) {
    ...OrderStatus
  }
}
    ${OrderStatusFragmentDoc}`;

/**
 * __useOrderLockedStepQuery__
 *
 * To run a query within a React component, call `useOrderLockedStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderLockedStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderLockedStepQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderLockedStepQuery(baseOptions: Apollo.QueryHookOptions<Types.OrderLockedStepQuery, Types.OrderLockedStepQueryVariables> & ({ variables: Types.OrderLockedStepQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrderLockedStepQuery, Types.OrderLockedStepQueryVariables>(OrderLockedStepDocument, options);
      }
export function useOrderLockedStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrderLockedStepQuery, Types.OrderLockedStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrderLockedStepQuery, Types.OrderLockedStepQueryVariables>(OrderLockedStepDocument, options);
        }
export function useOrderLockedStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.OrderLockedStepQuery, Types.OrderLockedStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.OrderLockedStepQuery, Types.OrderLockedStepQueryVariables>(OrderLockedStepDocument, options);
        }
export type OrderLockedStepQueryHookResult = ReturnType<typeof useOrderLockedStepQuery>;
export type OrderLockedStepLazyQueryHookResult = ReturnType<typeof useOrderLockedStepLazyQuery>;
export type OrderLockedStepSuspenseQueryHookResult = ReturnType<typeof useOrderLockedStepSuspenseQuery>;
export type OrderLockedStepQueryResult = Apollo.QueryResult<Types.OrderLockedStepQuery, Types.OrderLockedStepQueryVariables>;
export const PieDetailsPageDocument = gql`
    query PieDetailsPage($pieId: ID!, $period: PieBacktestPeriodEnum!, $showPortfolioLinks: Boolean!) {
  node(id: $pieId) {
    ...PieDetailsNode
  }
}
    ${PieDetailsNodeFragmentDoc}`;

/**
 * __usePieDetailsPageQuery__
 *
 * To run a query within a React component, call `usePieDetailsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePieDetailsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePieDetailsPageQuery({
 *   variables: {
 *      pieId: // value for 'pieId'
 *      period: // value for 'period'
 *      showPortfolioLinks: // value for 'showPortfolioLinks'
 *   },
 * });
 */
export function usePieDetailsPageQuery(baseOptions: Apollo.QueryHookOptions<Types.PieDetailsPageQuery, Types.PieDetailsPageQueryVariables> & ({ variables: Types.PieDetailsPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PieDetailsPageQuery, Types.PieDetailsPageQueryVariables>(PieDetailsPageDocument, options);
      }
export function usePieDetailsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PieDetailsPageQuery, Types.PieDetailsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PieDetailsPageQuery, Types.PieDetailsPageQueryVariables>(PieDetailsPageDocument, options);
        }
export function usePieDetailsPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PieDetailsPageQuery, Types.PieDetailsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PieDetailsPageQuery, Types.PieDetailsPageQueryVariables>(PieDetailsPageDocument, options);
        }
export type PieDetailsPageQueryHookResult = ReturnType<typeof usePieDetailsPageQuery>;
export type PieDetailsPageLazyQueryHookResult = ReturnType<typeof usePieDetailsPageLazyQuery>;
export type PieDetailsPageSuspenseQueryHookResult = ReturnType<typeof usePieDetailsPageSuspenseQuery>;
export type PieDetailsPageQueryResult = Apollo.QueryResult<Types.PieDetailsPageQuery, Types.PieDetailsPageQueryVariables>;
export const PortfolioBannerDocument = gql`
    query PortfolioBanner($accountId: ID!) {
  node(id: $accountId) {
    id
    ...AccountBanner
  }
  viewer {
    user {
      id
      billing {
        hasPendingCreditsButNoPaymentsAccount
      }
      agreementStatus {
        isNewAgreementRequired
      }
    }
  }
}
    ${AccountBannerFragmentDoc}`;

/**
 * __usePortfolioBannerQuery__
 *
 * To run a query within a React component, call `usePortfolioBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioBannerQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function usePortfolioBannerQuery(baseOptions: Apollo.QueryHookOptions<Types.PortfolioBannerQuery, Types.PortfolioBannerQueryVariables> & ({ variables: Types.PortfolioBannerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PortfolioBannerQuery, Types.PortfolioBannerQueryVariables>(PortfolioBannerDocument, options);
      }
export function usePortfolioBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PortfolioBannerQuery, Types.PortfolioBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PortfolioBannerQuery, Types.PortfolioBannerQueryVariables>(PortfolioBannerDocument, options);
        }
export function usePortfolioBannerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PortfolioBannerQuery, Types.PortfolioBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PortfolioBannerQuery, Types.PortfolioBannerQueryVariables>(PortfolioBannerDocument, options);
        }
export type PortfolioBannerQueryHookResult = ReturnType<typeof usePortfolioBannerQuery>;
export type PortfolioBannerLazyQueryHookResult = ReturnType<typeof usePortfolioBannerLazyQuery>;
export type PortfolioBannerSuspenseQueryHookResult = ReturnType<typeof usePortfolioBannerSuspenseQuery>;
export type PortfolioBannerQueryResult = Apollo.QueryResult<Types.PortfolioBannerQuery, Types.PortfolioBannerQueryVariables>;
export const PortfolioDividendsChartDocument = gql`
    query PortfolioDividendsChart($accountId: ID!, $period: DividendTrackerDataPeriod) {
  node(id: $accountId) {
    ... on Account {
      id
      isCryptoAccount
    }
  }
  viewer {
    id
    invest {
      id
      dividends(accountId: $accountId, period: $period) {
        cacheId
        dividendSummary {
          averageMonthlyIncome
          totalIncomeEarned
          totalPaidForTimeRange
          totalConfirmedForTimeRange
          totalUpcomingForTimeRange
        }
        dividendPerformance {
          paid
          groupedBy
          label
          upcoming
          pending
          total
          topEarners {
            ...DividendTopEarner
          }
        }
      }
    }
  }
}
    ${DividendTopEarnerFragmentDoc}`;

/**
 * __usePortfolioDividendsChartQuery__
 *
 * To run a query within a React component, call `usePortfolioDividendsChartQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioDividendsChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioDividendsChartQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      period: // value for 'period'
 *   },
 * });
 */
export function usePortfolioDividendsChartQuery(baseOptions: Apollo.QueryHookOptions<Types.PortfolioDividendsChartQuery, Types.PortfolioDividendsChartQueryVariables> & ({ variables: Types.PortfolioDividendsChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PortfolioDividendsChartQuery, Types.PortfolioDividendsChartQueryVariables>(PortfolioDividendsChartDocument, options);
      }
export function usePortfolioDividendsChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PortfolioDividendsChartQuery, Types.PortfolioDividendsChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PortfolioDividendsChartQuery, Types.PortfolioDividendsChartQueryVariables>(PortfolioDividendsChartDocument, options);
        }
export function usePortfolioDividendsChartSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PortfolioDividendsChartQuery, Types.PortfolioDividendsChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PortfolioDividendsChartQuery, Types.PortfolioDividendsChartQueryVariables>(PortfolioDividendsChartDocument, options);
        }
export type PortfolioDividendsChartQueryHookResult = ReturnType<typeof usePortfolioDividendsChartQuery>;
export type PortfolioDividendsChartLazyQueryHookResult = ReturnType<typeof usePortfolioDividendsChartLazyQuery>;
export type PortfolioDividendsChartSuspenseQueryHookResult = ReturnType<typeof usePortfolioDividendsChartSuspenseQuery>;
export type PortfolioDividendsChartQueryResult = Apollo.QueryResult<Types.PortfolioDividendsChartQuery, Types.PortfolioDividendsChartQueryVariables>;
export const PortfolioDividendsTablesDocument = gql`
    query PortfolioDividendsTables($accountId: ID!) {
  viewer {
    id
    invest {
      id
      dividends(accountId: $accountId) {
        cacheId
        disclosure {
          ...AppRichTextDisclosureLink
        }
        currentPositions(first: 100) {
          edges {
            node {
              ...DividendTablePosition
            }
          }
        }
        upcomingDividends(first: 100) {
          edges {
            node {
              ...DividendTableUpcomingDividend
            }
          }
        }
      }
    }
  }
}
    ${AppRichTextDisclosureLinkFragmentDoc}
${DividendTablePositionFragmentDoc}
${DividendTableUpcomingDividendFragmentDoc}`;

/**
 * __usePortfolioDividendsTablesQuery__
 *
 * To run a query within a React component, call `usePortfolioDividendsTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioDividendsTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioDividendsTablesQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function usePortfolioDividendsTablesQuery(baseOptions: Apollo.QueryHookOptions<Types.PortfolioDividendsTablesQuery, Types.PortfolioDividendsTablesQueryVariables> & ({ variables: Types.PortfolioDividendsTablesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PortfolioDividendsTablesQuery, Types.PortfolioDividendsTablesQueryVariables>(PortfolioDividendsTablesDocument, options);
      }
export function usePortfolioDividendsTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PortfolioDividendsTablesQuery, Types.PortfolioDividendsTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PortfolioDividendsTablesQuery, Types.PortfolioDividendsTablesQueryVariables>(PortfolioDividendsTablesDocument, options);
        }
export function usePortfolioDividendsTablesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PortfolioDividendsTablesQuery, Types.PortfolioDividendsTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PortfolioDividendsTablesQuery, Types.PortfolioDividendsTablesQueryVariables>(PortfolioDividendsTablesDocument, options);
        }
export type PortfolioDividendsTablesQueryHookResult = ReturnType<typeof usePortfolioDividendsTablesQuery>;
export type PortfolioDividendsTablesLazyQueryHookResult = ReturnType<typeof usePortfolioDividendsTablesLazyQuery>;
export type PortfolioDividendsTablesSuspenseQueryHookResult = ReturnType<typeof usePortfolioDividendsTablesSuspenseQuery>;
export type PortfolioDividendsTablesQueryResult = Apollo.QueryResult<Types.PortfolioDividendsTablesQuery, Types.PortfolioDividendsTablesQueryVariables>;
export const PortfolioPageDocument = gql`
    query PortfolioPage($accountId: ID!) {
  node(id: $accountId) {
    id
    ...PortfolioPageAccountNode
  }
  viewer {
    ...PortfolioPageViewer
  }
}
    ${PortfolioPageAccountNodeFragmentDoc}
${PortfolioPageViewerFragmentDoc}`;

/**
 * __usePortfolioPageQuery__
 *
 * To run a query within a React component, call `usePortfolioPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioPageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function usePortfolioPageQuery(baseOptions: Apollo.QueryHookOptions<Types.PortfolioPageQuery, Types.PortfolioPageQueryVariables> & ({ variables: Types.PortfolioPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PortfolioPageQuery, Types.PortfolioPageQueryVariables>(PortfolioPageDocument, options);
      }
export function usePortfolioPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PortfolioPageQuery, Types.PortfolioPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PortfolioPageQuery, Types.PortfolioPageQueryVariables>(PortfolioPageDocument, options);
        }
export function usePortfolioPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PortfolioPageQuery, Types.PortfolioPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PortfolioPageQuery, Types.PortfolioPageQueryVariables>(PortfolioPageDocument, options);
        }
export type PortfolioPageQueryHookResult = ReturnType<typeof usePortfolioPageQuery>;
export type PortfolioPageLazyQueryHookResult = ReturnType<typeof usePortfolioPageLazyQuery>;
export type PortfolioPageSuspenseQueryHookResult = ReturnType<typeof usePortfolioPageSuspenseQuery>;
export type PortfolioPageQueryResult = Apollo.QueryResult<Types.PortfolioPageQuery, Types.PortfolioPageQueryVariables>;
export const PortfolioTradingDetailsDocument = gql`
    query PortfolioTradingDetails($id: ID!) {
  viewer {
    id
  }
  node(id: $id) {
    ...PortfolioTrading
  }
}
    ${PortfolioTradingFragmentDoc}`;

/**
 * __usePortfolioTradingDetailsQuery__
 *
 * To run a query within a React component, call `usePortfolioTradingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioTradingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioTradingDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePortfolioTradingDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.PortfolioTradingDetailsQuery, Types.PortfolioTradingDetailsQueryVariables> & ({ variables: Types.PortfolioTradingDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PortfolioTradingDetailsQuery, Types.PortfolioTradingDetailsQueryVariables>(PortfolioTradingDetailsDocument, options);
      }
export function usePortfolioTradingDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PortfolioTradingDetailsQuery, Types.PortfolioTradingDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PortfolioTradingDetailsQuery, Types.PortfolioTradingDetailsQueryVariables>(PortfolioTradingDetailsDocument, options);
        }
export function usePortfolioTradingDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PortfolioTradingDetailsQuery, Types.PortfolioTradingDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PortfolioTradingDetailsQuery, Types.PortfolioTradingDetailsQueryVariables>(PortfolioTradingDetailsDocument, options);
        }
export type PortfolioTradingDetailsQueryHookResult = ReturnType<typeof usePortfolioTradingDetailsQuery>;
export type PortfolioTradingDetailsLazyQueryHookResult = ReturnType<typeof usePortfolioTradingDetailsLazyQuery>;
export type PortfolioTradingDetailsSuspenseQueryHookResult = ReturnType<typeof usePortfolioTradingDetailsSuspenseQuery>;
export type PortfolioTradingDetailsQueryResult = Apollo.QueryResult<Types.PortfolioTradingDetailsQuery, Types.PortfolioTradingDetailsQueryVariables>;
export const PreloadSetOrderDocument = gql`
    query PreloadSetOrder($portfolioSliceId: ID!) {
  portfolioSlice: node(id: $portfolioSliceId) {
    ...SetOrderPreload
  }
}
    ${SetOrderPreloadFragmentDoc}`;

/**
 * __usePreloadSetOrderQuery__
 *
 * To run a query within a React component, call `usePreloadSetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreloadSetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreloadSetOrderQuery({
 *   variables: {
 *      portfolioSliceId: // value for 'portfolioSliceId'
 *   },
 * });
 */
export function usePreloadSetOrderQuery(baseOptions: Apollo.QueryHookOptions<Types.PreloadSetOrderQuery, Types.PreloadSetOrderQueryVariables> & ({ variables: Types.PreloadSetOrderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PreloadSetOrderQuery, Types.PreloadSetOrderQueryVariables>(PreloadSetOrderDocument, options);
      }
export function usePreloadSetOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PreloadSetOrderQuery, Types.PreloadSetOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PreloadSetOrderQuery, Types.PreloadSetOrderQueryVariables>(PreloadSetOrderDocument, options);
        }
export function usePreloadSetOrderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PreloadSetOrderQuery, Types.PreloadSetOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PreloadSetOrderQuery, Types.PreloadSetOrderQueryVariables>(PreloadSetOrderDocument, options);
        }
export type PreloadSetOrderQueryHookResult = ReturnType<typeof usePreloadSetOrderQuery>;
export type PreloadSetOrderLazyQueryHookResult = ReturnType<typeof usePreloadSetOrderLazyQuery>;
export type PreloadSetOrderSuspenseQueryHookResult = ReturnType<typeof usePreloadSetOrderSuspenseQuery>;
export type PreloadSetOrderQueryResult = Apollo.QueryResult<Types.PreloadSetOrderQuery, Types.PreloadSetOrderQueryVariables>;
export const SetupOrderStepDocument = gql`
    query SetupOrderStep($portfolioSliceId: ID!, $sellAmount: Float!) {
  node(id: $portfolioSliceId) {
    ...SetupOrderStepPortfolioSlice
  }
}
    ${SetupOrderStepPortfolioSliceFragmentDoc}`;

/**
 * __useSetupOrderStepQuery__
 *
 * To run a query within a React component, call `useSetupOrderStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupOrderStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupOrderStepQuery({
 *   variables: {
 *      portfolioSliceId: // value for 'portfolioSliceId'
 *      sellAmount: // value for 'sellAmount'
 *   },
 * });
 */
export function useSetupOrderStepQuery(baseOptions: Apollo.QueryHookOptions<Types.SetupOrderStepQuery, Types.SetupOrderStepQueryVariables> & ({ variables: Types.SetupOrderStepQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SetupOrderStepQuery, Types.SetupOrderStepQueryVariables>(SetupOrderStepDocument, options);
      }
export function useSetupOrderStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SetupOrderStepQuery, Types.SetupOrderStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SetupOrderStepQuery, Types.SetupOrderStepQueryVariables>(SetupOrderStepDocument, options);
        }
export function useSetupOrderStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SetupOrderStepQuery, Types.SetupOrderStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SetupOrderStepQuery, Types.SetupOrderStepQueryVariables>(SetupOrderStepDocument, options);
        }
export type SetupOrderStepQueryHookResult = ReturnType<typeof useSetupOrderStepQuery>;
export type SetupOrderStepLazyQueryHookResult = ReturnType<typeof useSetupOrderStepLazyQuery>;
export type SetupOrderStepSuspenseQueryHookResult = ReturnType<typeof useSetupOrderStepSuspenseQuery>;
export type SetupOrderStepQueryResult = Apollo.QueryResult<Types.SetupOrderStepQuery, Types.SetupOrderStepQueryVariables>;
export const SharePieDocument = gql`
    query SharePie {
  viewer {
    invest {
      sharePie {
        sharePieModal {
          title
          subtitle
          referrals {
            referralsDescription
            referralsTitle
            referralsTermsLink {
              ...Linkable
            }
          }
        }
      }
    }
  }
}
    ${LinkableFragmentDoc}`;

/**
 * __useSharePieQuery__
 *
 * To run a query within a React component, call `useSharePieQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharePieQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharePieQuery({
 *   variables: {
 *   },
 * });
 */
export function useSharePieQuery(baseOptions?: Apollo.QueryHookOptions<Types.SharePieQuery, Types.SharePieQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SharePieQuery, Types.SharePieQueryVariables>(SharePieDocument, options);
      }
export function useSharePieLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SharePieQuery, Types.SharePieQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SharePieQuery, Types.SharePieQueryVariables>(SharePieDocument, options);
        }
export function useSharePieSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SharePieQuery, Types.SharePieQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SharePieQuery, Types.SharePieQueryVariables>(SharePieDocument, options);
        }
export type SharePieQueryHookResult = ReturnType<typeof useSharePieQuery>;
export type SharePieLazyQueryHookResult = ReturnType<typeof useSharePieLazyQuery>;
export type SharePieSuspenseQueryHookResult = ReturnType<typeof useSharePieSuspenseQuery>;
export type SharePieQueryResult = Apollo.QueryResult<Types.SharePieQuery, Types.SharePieQueryVariables>;
export const SliceByIdDocument = gql`
    query SliceById($id: ID!) {
  node(id: $id) {
    ...SliceableCell
  }
}
    ${SliceableCellFragmentDoc}`;

/**
 * __useSliceByIdQuery__
 *
 * To run a query within a React component, call `useSliceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSliceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSliceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSliceByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.SliceByIdQuery, Types.SliceByIdQueryVariables> & ({ variables: Types.SliceByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SliceByIdQuery, Types.SliceByIdQueryVariables>(SliceByIdDocument, options);
      }
export function useSliceByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SliceByIdQuery, Types.SliceByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SliceByIdQuery, Types.SliceByIdQueryVariables>(SliceByIdDocument, options);
        }
export function useSliceByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SliceByIdQuery, Types.SliceByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SliceByIdQuery, Types.SliceByIdQueryVariables>(SliceByIdDocument, options);
        }
export type SliceByIdQueryHookResult = ReturnType<typeof useSliceByIdQuery>;
export type SliceByIdLazyQueryHookResult = ReturnType<typeof useSliceByIdLazyQuery>;
export type SliceByIdSuspenseQueryHookResult = ReturnType<typeof useSliceByIdSuspenseQuery>;
export type SliceByIdQueryResult = Apollo.QueryResult<Types.SliceByIdQuery, Types.SliceByIdQueryVariables>;
export const SourcePieStepDocument = gql`
    query SourcePieStep($activeAccountId: ID!, $portfolioSliceIdsToMove: [ID!]!) {
  node(id: $activeAccountId) {
    ...SourcePieAccountNode
  }
  viewer {
    user {
      ...UserKeys
    }
  }
}
    ${SourcePieAccountNodeFragmentDoc}
${UserKeysFragmentDoc}`;

/**
 * __useSourcePieStepQuery__
 *
 * To run a query within a React component, call `useSourcePieStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourcePieStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourcePieStepQuery({
 *   variables: {
 *      activeAccountId: // value for 'activeAccountId'
 *      portfolioSliceIdsToMove: // value for 'portfolioSliceIdsToMove'
 *   },
 * });
 */
export function useSourcePieStepQuery(baseOptions: Apollo.QueryHookOptions<Types.SourcePieStepQuery, Types.SourcePieStepQueryVariables> & ({ variables: Types.SourcePieStepQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SourcePieStepQuery, Types.SourcePieStepQueryVariables>(SourcePieStepDocument, options);
      }
export function useSourcePieStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SourcePieStepQuery, Types.SourcePieStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SourcePieStepQuery, Types.SourcePieStepQueryVariables>(SourcePieStepDocument, options);
        }
export function useSourcePieStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SourcePieStepQuery, Types.SourcePieStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SourcePieStepQuery, Types.SourcePieStepQueryVariables>(SourcePieStepDocument, options);
        }
export type SourcePieStepQueryHookResult = ReturnType<typeof useSourcePieStepQuery>;
export type SourcePieStepLazyQueryHookResult = ReturnType<typeof useSourcePieStepLazyQuery>;
export type SourcePieStepSuspenseQueryHookResult = ReturnType<typeof useSourcePieStepSuspenseQuery>;
export type SourcePieStepQueryResult = Apollo.QueryResult<Types.SourcePieStepQuery, Types.SourcePieStepQueryVariables>;
export const CountryOfCitizenshipFieldDocument = gql`
    query CountryOfCitizenshipField($accountRegistration: OnlineAccountRegistrationEnum!) {
  viewer {
    invest {
      permissibleCountriesForAccountOpen(accountRegistration: $accountRegistration) {
        description
        name
      }
    }
  }
}
    `;

/**
 * __useCountryOfCitizenshipFieldQuery__
 *
 * To run a query within a React component, call `useCountryOfCitizenshipFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryOfCitizenshipFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryOfCitizenshipFieldQuery({
 *   variables: {
 *      accountRegistration: // value for 'accountRegistration'
 *   },
 * });
 */
export function useCountryOfCitizenshipFieldQuery(baseOptions: Apollo.QueryHookOptions<Types.CountryOfCitizenshipFieldQuery, Types.CountryOfCitizenshipFieldQueryVariables> & ({ variables: Types.CountryOfCitizenshipFieldQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountryOfCitizenshipFieldQuery, Types.CountryOfCitizenshipFieldQueryVariables>(CountryOfCitizenshipFieldDocument, options);
      }
export function useCountryOfCitizenshipFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountryOfCitizenshipFieldQuery, Types.CountryOfCitizenshipFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountryOfCitizenshipFieldQuery, Types.CountryOfCitizenshipFieldQueryVariables>(CountryOfCitizenshipFieldDocument, options);
        }
export function useCountryOfCitizenshipFieldSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CountryOfCitizenshipFieldQuery, Types.CountryOfCitizenshipFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CountryOfCitizenshipFieldQuery, Types.CountryOfCitizenshipFieldQueryVariables>(CountryOfCitizenshipFieldDocument, options);
        }
export type CountryOfCitizenshipFieldQueryHookResult = ReturnType<typeof useCountryOfCitizenshipFieldQuery>;
export type CountryOfCitizenshipFieldLazyQueryHookResult = ReturnType<typeof useCountryOfCitizenshipFieldLazyQuery>;
export type CountryOfCitizenshipFieldSuspenseQueryHookResult = ReturnType<typeof useCountryOfCitizenshipFieldSuspenseQuery>;
export type CountryOfCitizenshipFieldQueryResult = Apollo.QueryResult<Types.CountryOfCitizenshipFieldQuery, Types.CountryOfCitizenshipFieldQueryVariables>;
export const DisclosuresForCreditDocument = gql`
    query DisclosuresForCredit {
  viewer {
    credit {
      userAcceptedCreditCardAgreements {
        onboardingCreditCardAgreements {
          documents {
            title
            url
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDisclosuresForCreditQuery__
 *
 * To run a query within a React component, call `useDisclosuresForCreditQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisclosuresForCreditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisclosuresForCreditQuery({
 *   variables: {
 *   },
 * });
 */
export function useDisclosuresForCreditQuery(baseOptions?: Apollo.QueryHookOptions<Types.DisclosuresForCreditQuery, Types.DisclosuresForCreditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DisclosuresForCreditQuery, Types.DisclosuresForCreditQueryVariables>(DisclosuresForCreditDocument, options);
      }
export function useDisclosuresForCreditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DisclosuresForCreditQuery, Types.DisclosuresForCreditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DisclosuresForCreditQuery, Types.DisclosuresForCreditQueryVariables>(DisclosuresForCreditDocument, options);
        }
export function useDisclosuresForCreditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.DisclosuresForCreditQuery, Types.DisclosuresForCreditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DisclosuresForCreditQuery, Types.DisclosuresForCreditQueryVariables>(DisclosuresForCreditDocument, options);
        }
export type DisclosuresForCreditQueryHookResult = ReturnType<typeof useDisclosuresForCreditQuery>;
export type DisclosuresForCreditLazyQueryHookResult = ReturnType<typeof useDisclosuresForCreditLazyQuery>;
export type DisclosuresForCreditSuspenseQueryHookResult = ReturnType<typeof useDisclosuresForCreditSuspenseQuery>;
export type DisclosuresForCreditQueryResult = Apollo.QueryResult<Types.DisclosuresForCreditQuery, Types.DisclosuresForCreditQueryVariables>;
export const DisclosuresProfilePageDocument = gql`
    query DisclosuresProfilePage {
  viewer {
    profile {
      ...DisclosuresProfilePageAccounts
    }
  }
}
    ${DisclosuresProfilePageAccountsFragmentDoc}`;

/**
 * __useDisclosuresProfilePageQuery__
 *
 * To run a query within a React component, call `useDisclosuresProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisclosuresProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisclosuresProfilePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useDisclosuresProfilePageQuery(baseOptions?: Apollo.QueryHookOptions<Types.DisclosuresProfilePageQuery, Types.DisclosuresProfilePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DisclosuresProfilePageQuery, Types.DisclosuresProfilePageQueryVariables>(DisclosuresProfilePageDocument, options);
      }
export function useDisclosuresProfilePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DisclosuresProfilePageQuery, Types.DisclosuresProfilePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DisclosuresProfilePageQuery, Types.DisclosuresProfilePageQueryVariables>(DisclosuresProfilePageDocument, options);
        }
export function useDisclosuresProfilePageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.DisclosuresProfilePageQuery, Types.DisclosuresProfilePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DisclosuresProfilePageQuery, Types.DisclosuresProfilePageQueryVariables>(DisclosuresProfilePageDocument, options);
        }
export type DisclosuresProfilePageQueryHookResult = ReturnType<typeof useDisclosuresProfilePageQuery>;
export type DisclosuresProfilePageLazyQueryHookResult = ReturnType<typeof useDisclosuresProfilePageLazyQuery>;
export type DisclosuresProfilePageSuspenseQueryHookResult = ReturnType<typeof useDisclosuresProfilePageSuspenseQuery>;
export type DisclosuresProfilePageQueryResult = Apollo.QueryResult<Types.DisclosuresProfilePageQuery, Types.DisclosuresProfilePageQueryVariables>;
export const EmailProfilePageDocument = gql`
    query EmailProfilePage {
  viewer {
    user {
      username
    }
  }
}
    `;

/**
 * __useEmailProfilePageQuery__
 *
 * To run a query within a React component, call `useEmailProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailProfilePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmailProfilePageQuery(baseOptions?: Apollo.QueryHookOptions<Types.EmailProfilePageQuery, Types.EmailProfilePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EmailProfilePageQuery, Types.EmailProfilePageQueryVariables>(EmailProfilePageDocument, options);
      }
export function useEmailProfilePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EmailProfilePageQuery, Types.EmailProfilePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EmailProfilePageQuery, Types.EmailProfilePageQueryVariables>(EmailProfilePageDocument, options);
        }
export function useEmailProfilePageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EmailProfilePageQuery, Types.EmailProfilePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EmailProfilePageQuery, Types.EmailProfilePageQueryVariables>(EmailProfilePageDocument, options);
        }
export type EmailProfilePageQueryHookResult = ReturnType<typeof useEmailProfilePageQuery>;
export type EmailProfilePageLazyQueryHookResult = ReturnType<typeof useEmailProfilePageLazyQuery>;
export type EmailProfilePageSuspenseQueryHookResult = ReturnType<typeof useEmailProfilePageSuspenseQuery>;
export type EmailProfilePageQueryResult = Apollo.QueryResult<Types.EmailProfilePageQuery, Types.EmailProfilePageQueryVariables>;
export const EmploymentInfoFormModernDocument = gql`
    query EmploymentInfoFormModern {
  viewer {
    profile {
      primary {
        ...AccountProfileEmployment
      }
      secondary {
        ...AccountProfileEmployment
      }
    }
    onboarding {
      dueDiligence {
        ...EmploymentDropdownOptions
      }
    }
  }
}
    ${AccountProfileEmploymentFragmentDoc}
${EmploymentDropdownOptionsFragmentDoc}`;

/**
 * __useEmploymentInfoFormModernQuery__
 *
 * To run a query within a React component, call `useEmploymentInfoFormModernQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmploymentInfoFormModernQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmploymentInfoFormModernQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmploymentInfoFormModernQuery(baseOptions?: Apollo.QueryHookOptions<Types.EmploymentInfoFormModernQuery, Types.EmploymentInfoFormModernQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EmploymentInfoFormModernQuery, Types.EmploymentInfoFormModernQueryVariables>(EmploymentInfoFormModernDocument, options);
      }
export function useEmploymentInfoFormModernLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EmploymentInfoFormModernQuery, Types.EmploymentInfoFormModernQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EmploymentInfoFormModernQuery, Types.EmploymentInfoFormModernQueryVariables>(EmploymentInfoFormModernDocument, options);
        }
export function useEmploymentInfoFormModernSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EmploymentInfoFormModernQuery, Types.EmploymentInfoFormModernQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EmploymentInfoFormModernQuery, Types.EmploymentInfoFormModernQueryVariables>(EmploymentInfoFormModernDocument, options);
        }
export type EmploymentInfoFormModernQueryHookResult = ReturnType<typeof useEmploymentInfoFormModernQuery>;
export type EmploymentInfoFormModernLazyQueryHookResult = ReturnType<typeof useEmploymentInfoFormModernLazyQuery>;
export type EmploymentInfoFormModernSuspenseQueryHookResult = ReturnType<typeof useEmploymentInfoFormModernSuspenseQuery>;
export type EmploymentInfoFormModernQueryResult = Apollo.QueryResult<Types.EmploymentInfoFormModernQuery, Types.EmploymentInfoFormModernQueryVariables>;
export const GetEmailVerificationStatusDocument = gql`
    query GetEmailVerificationStatus {
  viewer {
    user {
      isPrimaryEmailVerified
    }
  }
}
    `;

/**
 * __useGetEmailVerificationStatusQuery__
 *
 * To run a query within a React component, call `useGetEmailVerificationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailVerificationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailVerificationStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailVerificationStatusQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetEmailVerificationStatusQuery, Types.GetEmailVerificationStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmailVerificationStatusQuery, Types.GetEmailVerificationStatusQueryVariables>(GetEmailVerificationStatusDocument, options);
      }
export function useGetEmailVerificationStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmailVerificationStatusQuery, Types.GetEmailVerificationStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmailVerificationStatusQuery, Types.GetEmailVerificationStatusQueryVariables>(GetEmailVerificationStatusDocument, options);
        }
export function useGetEmailVerificationStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmailVerificationStatusQuery, Types.GetEmailVerificationStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmailVerificationStatusQuery, Types.GetEmailVerificationStatusQueryVariables>(GetEmailVerificationStatusDocument, options);
        }
export type GetEmailVerificationStatusQueryHookResult = ReturnType<typeof useGetEmailVerificationStatusQuery>;
export type GetEmailVerificationStatusLazyQueryHookResult = ReturnType<typeof useGetEmailVerificationStatusLazyQuery>;
export type GetEmailVerificationStatusSuspenseQueryHookResult = ReturnType<typeof useGetEmailVerificationStatusSuspenseQuery>;
export type GetEmailVerificationStatusQueryResult = Apollo.QueryResult<Types.GetEmailVerificationStatusQuery, Types.GetEmailVerificationStatusQueryVariables>;
export const ProfileBeneficiariesDocument = gql`
    query ProfileBeneficiaries {
  viewer {
    profile {
      beneficiaries {
        ...BeneficiaryInfo
      }
    }
  }
}
    ${BeneficiaryInfoFragmentDoc}`;

/**
 * __useProfileBeneficiariesQuery__
 *
 * To run a query within a React component, call `useProfileBeneficiariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileBeneficiariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileBeneficiariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileBeneficiariesQuery(baseOptions?: Apollo.QueryHookOptions<Types.ProfileBeneficiariesQuery, Types.ProfileBeneficiariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProfileBeneficiariesQuery, Types.ProfileBeneficiariesQueryVariables>(ProfileBeneficiariesDocument, options);
      }
export function useProfileBeneficiariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProfileBeneficiariesQuery, Types.ProfileBeneficiariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProfileBeneficiariesQuery, Types.ProfileBeneficiariesQueryVariables>(ProfileBeneficiariesDocument, options);
        }
export function useProfileBeneficiariesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProfileBeneficiariesQuery, Types.ProfileBeneficiariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProfileBeneficiariesQuery, Types.ProfileBeneficiariesQueryVariables>(ProfileBeneficiariesDocument, options);
        }
export type ProfileBeneficiariesQueryHookResult = ReturnType<typeof useProfileBeneficiariesQuery>;
export type ProfileBeneficiariesLazyQueryHookResult = ReturnType<typeof useProfileBeneficiariesLazyQuery>;
export type ProfileBeneficiariesSuspenseQueryHookResult = ReturnType<typeof useProfileBeneficiariesSuspenseQuery>;
export type ProfileBeneficiariesQueryResult = Apollo.QueryResult<Types.ProfileBeneficiariesQuery, Types.ProfileBeneficiariesQueryVariables>;
export const ProfileReviewDocument = gql`
    query ProfileReview {
  viewer {
    user {
      username
    }
    profile {
      ...AccountProfiles
      ...ProfileUpdateBannerFlags
    }
  }
}
    ${AccountProfilesFragmentDoc}
${ProfileUpdateBannerFlagsFragmentDoc}`;

/**
 * __useProfileReviewQuery__
 *
 * To run a query within a React component, call `useProfileReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileReviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileReviewQuery(baseOptions?: Apollo.QueryHookOptions<Types.ProfileReviewQuery, Types.ProfileReviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProfileReviewQuery, Types.ProfileReviewQueryVariables>(ProfileReviewDocument, options);
      }
export function useProfileReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProfileReviewQuery, Types.ProfileReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProfileReviewQuery, Types.ProfileReviewQueryVariables>(ProfileReviewDocument, options);
        }
export function useProfileReviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProfileReviewQuery, Types.ProfileReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProfileReviewQuery, Types.ProfileReviewQueryVariables>(ProfileReviewDocument, options);
        }
export type ProfileReviewQueryHookResult = ReturnType<typeof useProfileReviewQuery>;
export type ProfileReviewLazyQueryHookResult = ReturnType<typeof useProfileReviewLazyQuery>;
export type ProfileReviewSuspenseQueryHookResult = ReturnType<typeof useProfileReviewSuspenseQuery>;
export type ProfileReviewQueryResult = Apollo.QueryResult<Types.ProfileReviewQuery, Types.ProfileReviewQueryVariables>;
export const ProfileSuitabilityDocument = gql`
    query ProfileSuitability {
  viewer {
    profile {
      suitability {
        ...ProfileSuitabilities
      }
    }
  }
}
    ${ProfileSuitabilitiesFragmentDoc}`;

/**
 * __useProfileSuitabilityQuery__
 *
 * To run a query within a React component, call `useProfileSuitabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileSuitabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileSuitabilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileSuitabilityQuery(baseOptions?: Apollo.QueryHookOptions<Types.ProfileSuitabilityQuery, Types.ProfileSuitabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProfileSuitabilityQuery, Types.ProfileSuitabilityQueryVariables>(ProfileSuitabilityDocument, options);
      }
export function useProfileSuitabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProfileSuitabilityQuery, Types.ProfileSuitabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProfileSuitabilityQuery, Types.ProfileSuitabilityQueryVariables>(ProfileSuitabilityDocument, options);
        }
export function useProfileSuitabilitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ProfileSuitabilityQuery, Types.ProfileSuitabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ProfileSuitabilityQuery, Types.ProfileSuitabilityQueryVariables>(ProfileSuitabilityDocument, options);
        }
export type ProfileSuitabilityQueryHookResult = ReturnType<typeof useProfileSuitabilityQuery>;
export type ProfileSuitabilityLazyQueryHookResult = ReturnType<typeof useProfileSuitabilityLazyQuery>;
export type ProfileSuitabilitySuspenseQueryHookResult = ReturnType<typeof useProfileSuitabilitySuspenseQuery>;
export type ProfileSuitabilityQueryResult = Apollo.QueryResult<Types.ProfileSuitabilityQuery, Types.ProfileSuitabilityQueryVariables>;
export const RootProfileDocument = gql`
    query RootProfile {
  viewer {
    ...ProfileNavigation
  }
}
    ${ProfileNavigationFragmentDoc}`;

/**
 * __useRootProfileQuery__
 *
 * To run a query within a React component, call `useRootProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useRootProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRootProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useRootProfileQuery(baseOptions?: Apollo.QueryHookOptions<Types.RootProfileQuery, Types.RootProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RootProfileQuery, Types.RootProfileQueryVariables>(RootProfileDocument, options);
      }
export function useRootProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RootProfileQuery, Types.RootProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RootProfileQuery, Types.RootProfileQueryVariables>(RootProfileDocument, options);
        }
export function useRootProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RootProfileQuery, Types.RootProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RootProfileQuery, Types.RootProfileQueryVariables>(RootProfileDocument, options);
        }
export type RootProfileQueryHookResult = ReturnType<typeof useRootProfileQuery>;
export type RootProfileLazyQueryHookResult = ReturnType<typeof useRootProfileLazyQuery>;
export type RootProfileSuspenseQueryHookResult = ReturnType<typeof useRootProfileSuspenseQuery>;
export type RootProfileQueryResult = Apollo.QueryResult<Types.RootProfileQuery, Types.RootProfileQueryVariables>;
export const SetGoalPageDocument = gql`
    query SetGoalPage {
  viewer {
    onboarding {
      collectUserGoalsOnboardingEnabled
    }
  }
}
    `;

/**
 * __useSetGoalPageQuery__
 *
 * To run a query within a React component, call `useSetGoalPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetGoalPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetGoalPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useSetGoalPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.SetGoalPageQuery, Types.SetGoalPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SetGoalPageQuery, Types.SetGoalPageQueryVariables>(SetGoalPageDocument, options);
      }
export function useSetGoalPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SetGoalPageQuery, Types.SetGoalPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SetGoalPageQuery, Types.SetGoalPageQueryVariables>(SetGoalPageDocument, options);
        }
export function useSetGoalPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SetGoalPageQuery, Types.SetGoalPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SetGoalPageQuery, Types.SetGoalPageQueryVariables>(SetGoalPageDocument, options);
        }
export type SetGoalPageQueryHookResult = ReturnType<typeof useSetGoalPageQuery>;
export type SetGoalPageLazyQueryHookResult = ReturnType<typeof useSetGoalPageLazyQuery>;
export type SetGoalPageSuspenseQueryHookResult = ReturnType<typeof useSetGoalPageSuspenseQuery>;
export type SetGoalPageQueryResult = Apollo.QueryResult<Types.SetGoalPageQuery, Types.SetGoalPageQueryVariables>;
export const TrustedContactPageDocument = gql`
    query TrustedContactPage {
  viewer {
    profile {
      trustedContact {
        ...TrustedContactFields
      }
    }
  }
}
    ${TrustedContactFieldsFragmentDoc}`;

/**
 * __useTrustedContactPageQuery__
 *
 * To run a query within a React component, call `useTrustedContactPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrustedContactPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrustedContactPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrustedContactPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.TrustedContactPageQuery, Types.TrustedContactPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TrustedContactPageQuery, Types.TrustedContactPageQueryVariables>(TrustedContactPageDocument, options);
      }
export function useTrustedContactPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TrustedContactPageQuery, Types.TrustedContactPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TrustedContactPageQuery, Types.TrustedContactPageQueryVariables>(TrustedContactPageDocument, options);
        }
export function useTrustedContactPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TrustedContactPageQuery, Types.TrustedContactPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TrustedContactPageQuery, Types.TrustedContactPageQueryVariables>(TrustedContactPageDocument, options);
        }
export type TrustedContactPageQueryHookResult = ReturnType<typeof useTrustedContactPageQuery>;
export type TrustedContactPageLazyQueryHookResult = ReturnType<typeof useTrustedContactPageLazyQuery>;
export type TrustedContactPageSuspenseQueryHookResult = ReturnType<typeof useTrustedContactPageSuspenseQuery>;
export type TrustedContactPageQueryResult = Apollo.QueryResult<Types.TrustedContactPageQuery, Types.TrustedContactPageQueryVariables>;
export const DisclosuresForPromotionsDocument = gql`
    query DisclosuresForPromotions {
  viewer {
    promotions {
      promotionsAgreements {
        documents {
          title
          url
        }
      }
    }
  }
}
    `;

/**
 * __useDisclosuresForPromotionsQuery__
 *
 * To run a query within a React component, call `useDisclosuresForPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisclosuresForPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisclosuresForPromotionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDisclosuresForPromotionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.DisclosuresForPromotionsQuery, Types.DisclosuresForPromotionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DisclosuresForPromotionsQuery, Types.DisclosuresForPromotionsQueryVariables>(DisclosuresForPromotionsDocument, options);
      }
export function useDisclosuresForPromotionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DisclosuresForPromotionsQuery, Types.DisclosuresForPromotionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DisclosuresForPromotionsQuery, Types.DisclosuresForPromotionsQueryVariables>(DisclosuresForPromotionsDocument, options);
        }
export function useDisclosuresForPromotionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.DisclosuresForPromotionsQuery, Types.DisclosuresForPromotionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DisclosuresForPromotionsQuery, Types.DisclosuresForPromotionsQueryVariables>(DisclosuresForPromotionsDocument, options);
        }
export type DisclosuresForPromotionsQueryHookResult = ReturnType<typeof useDisclosuresForPromotionsQuery>;
export type DisclosuresForPromotionsLazyQueryHookResult = ReturnType<typeof useDisclosuresForPromotionsLazyQuery>;
export type DisclosuresForPromotionsSuspenseQueryHookResult = ReturnType<typeof useDisclosuresForPromotionsSuspenseQuery>;
export type DisclosuresForPromotionsQueryResult = Apollo.QueryResult<Types.DisclosuresForPromotionsQuery, Types.DisclosuresForPromotionsQueryVariables>;
export const EarnPromoCardDocument = gql`
    query EarnPromoCard {
  viewer {
    save {
      savings {
        savingsAccounts {
          edges {
            node {
              id
              earnProductLandingPage {
                promotionCards {
                  ...AppCard
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${AppCardFragmentDoc}`;

/**
 * __useEarnPromoCardQuery__
 *
 * To run a query within a React component, call `useEarnPromoCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarnPromoCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarnPromoCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useEarnPromoCardQuery(baseOptions?: Apollo.QueryHookOptions<Types.EarnPromoCardQuery, Types.EarnPromoCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EarnPromoCardQuery, Types.EarnPromoCardQueryVariables>(EarnPromoCardDocument, options);
      }
export function useEarnPromoCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EarnPromoCardQuery, Types.EarnPromoCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EarnPromoCardQuery, Types.EarnPromoCardQueryVariables>(EarnPromoCardDocument, options);
        }
export function useEarnPromoCardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EarnPromoCardQuery, Types.EarnPromoCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EarnPromoCardQuery, Types.EarnPromoCardQueryVariables>(EarnPromoCardDocument, options);
        }
export type EarnPromoCardQueryHookResult = ReturnType<typeof useEarnPromoCardQuery>;
export type EarnPromoCardLazyQueryHookResult = ReturnType<typeof useEarnPromoCardLazyQuery>;
export type EarnPromoCardSuspenseQueryHookResult = ReturnType<typeof useEarnPromoCardSuspenseQuery>;
export type EarnPromoCardQueryResult = Apollo.QueryResult<Types.EarnPromoCardQuery, Types.EarnPromoCardQueryVariables>;
export const SignUpWithPromotionDocument = gql`
    query SignUpWithPromotion($promoCode: String) {
  viewer {
    unauthenticated {
      promotions(promoCode: $promoCode) {
        signUpPage {
          header
          disclosures {
            ...AppDisclosureLink
          }
          termsAndConditions {
            ...RequiredDocumentsBundle
          }
          primaryCtaLabel
          marketingContent {
            ...RichText
          }
          illustration {
            ...AppImage
          }
        }
      }
    }
  }
}
    ${AppDisclosureLinkFragmentDoc}
${RequiredDocumentsBundleFragmentDoc}
${RichTextFragmentDoc}
${AppImageFragmentDoc}`;

/**
 * __useSignUpWithPromotionQuery__
 *
 * To run a query within a React component, call `useSignUpWithPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignUpWithPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignUpWithPromotionQuery({
 *   variables: {
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function useSignUpWithPromotionQuery(baseOptions?: Apollo.QueryHookOptions<Types.SignUpWithPromotionQuery, Types.SignUpWithPromotionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SignUpWithPromotionQuery, Types.SignUpWithPromotionQueryVariables>(SignUpWithPromotionDocument, options);
      }
export function useSignUpWithPromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SignUpWithPromotionQuery, Types.SignUpWithPromotionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SignUpWithPromotionQuery, Types.SignUpWithPromotionQueryVariables>(SignUpWithPromotionDocument, options);
        }
export function useSignUpWithPromotionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SignUpWithPromotionQuery, Types.SignUpWithPromotionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SignUpWithPromotionQuery, Types.SignUpWithPromotionQueryVariables>(SignUpWithPromotionDocument, options);
        }
export type SignUpWithPromotionQueryHookResult = ReturnType<typeof useSignUpWithPromotionQuery>;
export type SignUpWithPromotionLazyQueryHookResult = ReturnType<typeof useSignUpWithPromotionLazyQuery>;
export type SignUpWithPromotionSuspenseQueryHookResult = ReturnType<typeof useSignUpWithPromotionSuspenseQuery>;
export type SignUpWithPromotionQueryResult = Apollo.QueryResult<Types.SignUpWithPromotionQuery, Types.SignUpWithPromotionQueryVariables>;
export const ReferralOfferDocument = gql`
    query ReferralOffer {
  viewer {
    referralOffer {
      getAmount
      giveAmount
    }
  }
}
    `;

/**
 * __useReferralOfferQuery__
 *
 * To run a query within a React component, call `useReferralOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralOfferQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferralOfferQuery(baseOptions?: Apollo.QueryHookOptions<Types.ReferralOfferQuery, Types.ReferralOfferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReferralOfferQuery, Types.ReferralOfferQueryVariables>(ReferralOfferDocument, options);
      }
export function useReferralOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReferralOfferQuery, Types.ReferralOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReferralOfferQuery, Types.ReferralOfferQueryVariables>(ReferralOfferDocument, options);
        }
export function useReferralOfferSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ReferralOfferQuery, Types.ReferralOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReferralOfferQuery, Types.ReferralOfferQueryVariables>(ReferralOfferDocument, options);
        }
export type ReferralOfferQueryHookResult = ReturnType<typeof useReferralOfferQuery>;
export type ReferralOfferLazyQueryHookResult = ReturnType<typeof useReferralOfferLazyQuery>;
export type ReferralOfferSuspenseQueryHookResult = ReturnType<typeof useReferralOfferSuspenseQuery>;
export type ReferralOfferQueryResult = Apollo.QueryResult<Types.ReferralOfferQuery, Types.ReferralOfferQueryVariables>;
export const ReferralPaymentAccountDocument = gql`
    query ReferralPaymentAccount($first: Int, $filterForPaymentsEligibility: Boolean) {
  viewer {
    accounts(
      first: $first
      filterForPaymentsEligibility: $filterForPaymentsEligibility
    ) {
      edges {
        node {
          id
          accountCategory
          name
        }
      }
    }
    user {
      id
      billing {
        selectedPaymentsAccount {
          account {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useReferralPaymentAccountQuery__
 *
 * To run a query within a React component, call `useReferralPaymentAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralPaymentAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralPaymentAccountQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filterForPaymentsEligibility: // value for 'filterForPaymentsEligibility'
 *   },
 * });
 */
export function useReferralPaymentAccountQuery(baseOptions?: Apollo.QueryHookOptions<Types.ReferralPaymentAccountQuery, Types.ReferralPaymentAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReferralPaymentAccountQuery, Types.ReferralPaymentAccountQueryVariables>(ReferralPaymentAccountDocument, options);
      }
export function useReferralPaymentAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReferralPaymentAccountQuery, Types.ReferralPaymentAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReferralPaymentAccountQuery, Types.ReferralPaymentAccountQueryVariables>(ReferralPaymentAccountDocument, options);
        }
export function useReferralPaymentAccountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ReferralPaymentAccountQuery, Types.ReferralPaymentAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReferralPaymentAccountQuery, Types.ReferralPaymentAccountQueryVariables>(ReferralPaymentAccountDocument, options);
        }
export type ReferralPaymentAccountQueryHookResult = ReturnType<typeof useReferralPaymentAccountQuery>;
export type ReferralPaymentAccountLazyQueryHookResult = ReturnType<typeof useReferralPaymentAccountLazyQuery>;
export type ReferralPaymentAccountSuspenseQueryHookResult = ReturnType<typeof useReferralPaymentAccountSuspenseQuery>;
export type ReferralPaymentAccountQueryResult = Apollo.QueryResult<Types.ReferralPaymentAccountQuery, Types.ReferralPaymentAccountQueryVariables>;
export const ReferralsDocument = gql`
    query Referrals {
  viewer {
    invest {
      hasActiveInvestAccount
    }
    user {
      id
      username
      referrals {
        isEligible
        isParticipating
        referrerStatus {
          completeReferralCount
          shareMessage
          shareUrl
        }
        getAmount
        giveAmount
        referralsTitle
        referralsDescription
        referralsTermsLink {
          ...Linkable
        }
      }
    }
    announcements {
      forReferrals {
        ...Announcement
      }
    }
  }
}
    ${LinkableFragmentDoc}
${AnnouncementFragmentDoc}`;

/**
 * __useReferralsQuery__
 *
 * To run a query within a React component, call `useReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferralsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ReferralsQuery, Types.ReferralsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReferralsQuery, Types.ReferralsQueryVariables>(ReferralsDocument, options);
      }
export function useReferralsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReferralsQuery, Types.ReferralsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReferralsQuery, Types.ReferralsQueryVariables>(ReferralsDocument, options);
        }
export function useReferralsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ReferralsQuery, Types.ReferralsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReferralsQuery, Types.ReferralsQueryVariables>(ReferralsDocument, options);
        }
export type ReferralsQueryHookResult = ReturnType<typeof useReferralsQuery>;
export type ReferralsLazyQueryHookResult = ReturnType<typeof useReferralsLazyQuery>;
export type ReferralsSuspenseQueryHookResult = ReturnType<typeof useReferralsSuspenseQuery>;
export type ReferralsQueryResult = Apollo.QueryResult<Types.ReferralsQuery, Types.ReferralsQueryVariables>;
export const ReferralsSignupPageDocument = gql`
    query ReferralsSignupPage {
  viewer {
    referralOffer {
      referralsTitle
      referralsIllustration {
        ...AppImage
      }
      referralsDisclosureContent {
        ...RichText
      }
    }
  }
}
    ${AppImageFragmentDoc}
${RichTextFragmentDoc}`;

/**
 * __useReferralsSignupPageQuery__
 *
 * To run a query within a React component, call `useReferralsSignupPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsSignupPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsSignupPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferralsSignupPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.ReferralsSignupPageQuery, Types.ReferralsSignupPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReferralsSignupPageQuery, Types.ReferralsSignupPageQueryVariables>(ReferralsSignupPageDocument, options);
      }
export function useReferralsSignupPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReferralsSignupPageQuery, Types.ReferralsSignupPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReferralsSignupPageQuery, Types.ReferralsSignupPageQueryVariables>(ReferralsSignupPageDocument, options);
        }
export function useReferralsSignupPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ReferralsSignupPageQuery, Types.ReferralsSignupPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReferralsSignupPageQuery, Types.ReferralsSignupPageQueryVariables>(ReferralsSignupPageDocument, options);
        }
export type ReferralsSignupPageQueryHookResult = ReturnType<typeof useReferralsSignupPageQuery>;
export type ReferralsSignupPageLazyQueryHookResult = ReturnType<typeof useReferralsSignupPageLazyQuery>;
export type ReferralsSignupPageSuspenseQueryHookResult = ReturnType<typeof useReferralsSignupPageSuspenseQuery>;
export type ReferralsSignupPageQueryResult = Apollo.QueryResult<Types.ReferralsSignupPageQuery, Types.ReferralsSignupPageQueryVariables>;
export const ReferralsTopNavigationItemDocument = gql`
    query ReferralsTopNavigationItem {
  viewer {
    user {
      referrals {
        referralsTopNavigationItem {
          title
          subtitle
          icon {
            ...AppImage
          }
          url
          internalPath
          analyticsEvent {
            ...AnalyticsEvent
          }
        }
      }
    }
  }
}
    ${AppImageFragmentDoc}
${AnalyticsEventFragmentDoc}`;

/**
 * __useReferralsTopNavigationItemQuery__
 *
 * To run a query within a React component, call `useReferralsTopNavigationItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsTopNavigationItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsTopNavigationItemQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferralsTopNavigationItemQuery(baseOptions?: Apollo.QueryHookOptions<Types.ReferralsTopNavigationItemQuery, Types.ReferralsTopNavigationItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReferralsTopNavigationItemQuery, Types.ReferralsTopNavigationItemQueryVariables>(ReferralsTopNavigationItemDocument, options);
      }
export function useReferralsTopNavigationItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReferralsTopNavigationItemQuery, Types.ReferralsTopNavigationItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReferralsTopNavigationItemQuery, Types.ReferralsTopNavigationItemQueryVariables>(ReferralsTopNavigationItemDocument, options);
        }
export function useReferralsTopNavigationItemSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ReferralsTopNavigationItemQuery, Types.ReferralsTopNavigationItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReferralsTopNavigationItemQuery, Types.ReferralsTopNavigationItemQueryVariables>(ReferralsTopNavigationItemDocument, options);
        }
export type ReferralsTopNavigationItemQueryHookResult = ReturnType<typeof useReferralsTopNavigationItemQuery>;
export type ReferralsTopNavigationItemLazyQueryHookResult = ReturnType<typeof useReferralsTopNavigationItemLazyQuery>;
export type ReferralsTopNavigationItemSuspenseQueryHookResult = ReturnType<typeof useReferralsTopNavigationItemSuspenseQuery>;
export type ReferralsTopNavigationItemQueryResult = Apollo.QueryResult<Types.ReferralsTopNavigationItemQuery, Types.ReferralsTopNavigationItemQueryVariables>;
export const CryptoScreenerDocument = gql`
    query CryptoScreener($sort: [CryptoSortOptionInput!], $period: HistoricalQuotePeriodEnum!, $first: Int!, $after: String) {
  viewer {
    crypto {
      screenCrypto(first: $first, sort: $sort, after: $after) {
        total
        edges {
          ...ScreenerCrypto
        }
      }
    }
  }
}
    ${ScreenerCryptoFragmentDoc}`;

/**
 * __useCryptoScreenerQuery__
 *
 * To run a query within a React component, call `useCryptoScreenerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCryptoScreenerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCryptoScreenerQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      period: // value for 'period'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useCryptoScreenerQuery(baseOptions: Apollo.QueryHookOptions<Types.CryptoScreenerQuery, Types.CryptoScreenerQueryVariables> & ({ variables: Types.CryptoScreenerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CryptoScreenerQuery, Types.CryptoScreenerQueryVariables>(CryptoScreenerDocument, options);
      }
export function useCryptoScreenerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CryptoScreenerQuery, Types.CryptoScreenerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CryptoScreenerQuery, Types.CryptoScreenerQueryVariables>(CryptoScreenerDocument, options);
        }
export function useCryptoScreenerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CryptoScreenerQuery, Types.CryptoScreenerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CryptoScreenerQuery, Types.CryptoScreenerQueryVariables>(CryptoScreenerDocument, options);
        }
export type CryptoScreenerQueryHookResult = ReturnType<typeof useCryptoScreenerQuery>;
export type CryptoScreenerLazyQueryHookResult = ReturnType<typeof useCryptoScreenerLazyQuery>;
export type CryptoScreenerSuspenseQueryHookResult = ReturnType<typeof useCryptoScreenerSuspenseQuery>;
export type CryptoScreenerQueryResult = Apollo.QueryResult<Types.CryptoScreenerQuery, Types.CryptoScreenerQueryVariables>;
export const DiscoverSearchDocument = gql`
    query DiscoverSearch($filterTypes: [SliceableTypeEnum!], $filterStatuses: [SliceableStatusEnum!], $query: String!, $first: Int!) {
  viewer {
    search: searchSliceables(
      first: $first
      query: $query
      filterTypes: $filterTypes
      filterStatuses: $filterStatuses
    ) {
      edges {
        node {
          id
          ... on Sliceable {
            __typename
            id
            type
            name
            isActive
            ... on Security {
              symbol
            }
            ... on Fund {
              fundCategory
            }
            ...Sliceable
          }
        }
        highlights {
          match
          snippet
        }
      }
    }
  }
}
    ${SliceableFragmentDoc}`;

/**
 * __useDiscoverSearchQuery__
 *
 * To run a query within a React component, call `useDiscoverSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscoverSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscoverSearchQuery({
 *   variables: {
 *      filterTypes: // value for 'filterTypes'
 *      filterStatuses: // value for 'filterStatuses'
 *      query: // value for 'query'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useDiscoverSearchQuery(baseOptions: Apollo.QueryHookOptions<Types.DiscoverSearchQuery, Types.DiscoverSearchQueryVariables> & ({ variables: Types.DiscoverSearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DiscoverSearchQuery, Types.DiscoverSearchQueryVariables>(DiscoverSearchDocument, options);
      }
export function useDiscoverSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DiscoverSearchQuery, Types.DiscoverSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DiscoverSearchQuery, Types.DiscoverSearchQueryVariables>(DiscoverSearchDocument, options);
        }
export function useDiscoverSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.DiscoverSearchQuery, Types.DiscoverSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DiscoverSearchQuery, Types.DiscoverSearchQueryVariables>(DiscoverSearchDocument, options);
        }
export type DiscoverSearchQueryHookResult = ReturnType<typeof useDiscoverSearchQuery>;
export type DiscoverSearchLazyQueryHookResult = ReturnType<typeof useDiscoverSearchLazyQuery>;
export type DiscoverSearchSuspenseQueryHookResult = ReturnType<typeof useDiscoverSearchSuspenseQuery>;
export type DiscoverSearchQueryResult = Apollo.QueryResult<Types.DiscoverSearchQuery, Types.DiscoverSearchQueryVariables>;
export const FundScreenerDocument = gql`
    query FundScreener($filterCategory: [String!], $limit: [FundLimitOptionInput!], $sort: [FundSortOptionInput!], $after: String, $first: Int!, $period: HistoricalQuotePeriodEnum!) {
  viewer {
    securities: screenFunds(
      filterCategory: $filterCategory
      limit: $limit
      sort: $sort
      after: $after
      first: $first
    ) {
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
      }
      subCategories {
        category
        count
      }
      total
      edges {
        ...ScreenerFund
      }
    }
  }
}
    ${ScreenerFundFragmentDoc}`;

/**
 * __useFundScreenerQuery__
 *
 * To run a query within a React component, call `useFundScreenerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundScreenerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundScreenerQuery({
 *   variables: {
 *      filterCategory: // value for 'filterCategory'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useFundScreenerQuery(baseOptions: Apollo.QueryHookOptions<Types.FundScreenerQuery, Types.FundScreenerQueryVariables> & ({ variables: Types.FundScreenerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FundScreenerQuery, Types.FundScreenerQueryVariables>(FundScreenerDocument, options);
      }
export function useFundScreenerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FundScreenerQuery, Types.FundScreenerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FundScreenerQuery, Types.FundScreenerQueryVariables>(FundScreenerDocument, options);
        }
export function useFundScreenerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.FundScreenerQuery, Types.FundScreenerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.FundScreenerQuery, Types.FundScreenerQueryVariables>(FundScreenerDocument, options);
        }
export type FundScreenerQueryHookResult = ReturnType<typeof useFundScreenerQuery>;
export type FundScreenerLazyQueryHookResult = ReturnType<typeof useFundScreenerLazyQuery>;
export type FundScreenerSuspenseQueryHookResult = ReturnType<typeof useFundScreenerSuspenseQuery>;
export type FundScreenerQueryResult = Apollo.QueryResult<Types.FundScreenerQuery, Types.FundScreenerQueryVariables>;
export const MarketTrendsDocument = gql`
    query MarketTrends {
  viewer {
    markets {
      ... on Markets {
        indexProxies {
          ...MarketIndexProxy
        }
      }
    }
  }
}
    ${MarketIndexProxyFragmentDoc}`;

/**
 * __useMarketTrendsQuery__
 *
 * To run a query within a React component, call `useMarketTrendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketTrendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketTrendsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketTrendsQuery(baseOptions?: Apollo.QueryHookOptions<Types.MarketTrendsQuery, Types.MarketTrendsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MarketTrendsQuery, Types.MarketTrendsQueryVariables>(MarketTrendsDocument, options);
      }
export function useMarketTrendsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MarketTrendsQuery, Types.MarketTrendsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MarketTrendsQuery, Types.MarketTrendsQueryVariables>(MarketTrendsDocument, options);
        }
export function useMarketTrendsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MarketTrendsQuery, Types.MarketTrendsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MarketTrendsQuery, Types.MarketTrendsQueryVariables>(MarketTrendsDocument, options);
        }
export type MarketTrendsQueryHookResult = ReturnType<typeof useMarketTrendsQuery>;
export type MarketTrendsLazyQueryHookResult = ReturnType<typeof useMarketTrendsLazyQuery>;
export type MarketTrendsSuspenseQueryHookResult = ReturnType<typeof useMarketTrendsSuspenseQuery>;
export type MarketTrendsQueryResult = Apollo.QueryResult<Types.MarketTrendsQuery, Types.MarketTrendsQueryVariables>;
export const MarketNewsPaginationDocument = gql`
    query MarketNewsPagination($first: Int!, $after: String) {
  viewer {
    markets {
      ... on Markets {
        marketNews(first: $first, after: $after) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              date
              headline
              source
              sourceLogoUrl
              summary
              url
              imageUrl
              newsType
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMarketNewsPaginationQuery__
 *
 * To run a query within a React component, call `useMarketNewsPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketNewsPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketNewsPaginationQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useMarketNewsPaginationQuery(baseOptions: Apollo.QueryHookOptions<Types.MarketNewsPaginationQuery, Types.MarketNewsPaginationQueryVariables> & ({ variables: Types.MarketNewsPaginationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MarketNewsPaginationQuery, Types.MarketNewsPaginationQueryVariables>(MarketNewsPaginationDocument, options);
      }
export function useMarketNewsPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MarketNewsPaginationQuery, Types.MarketNewsPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MarketNewsPaginationQuery, Types.MarketNewsPaginationQueryVariables>(MarketNewsPaginationDocument, options);
        }
export function useMarketNewsPaginationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MarketNewsPaginationQuery, Types.MarketNewsPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MarketNewsPaginationQuery, Types.MarketNewsPaginationQueryVariables>(MarketNewsPaginationDocument, options);
        }
export type MarketNewsPaginationQueryHookResult = ReturnType<typeof useMarketNewsPaginationQuery>;
export type MarketNewsPaginationLazyQueryHookResult = ReturnType<typeof useMarketNewsPaginationLazyQuery>;
export type MarketNewsPaginationSuspenseQueryHookResult = ReturnType<typeof useMarketNewsPaginationSuspenseQuery>;
export type MarketNewsPaginationQueryResult = Apollo.QueryResult<Types.MarketNewsPaginationQuery, Types.MarketNewsPaginationQueryVariables>;
export const ModelPortfolioInfoDocument = gql`
    query ModelPortfolioInfo($filterCategory: [String!]) {
  viewer {
    screenSystemPies(filterCategory: $filterCategory) {
      total
      currentCategory {
        categoryDetails {
          key
          name
          description
          disclosure
          featureUrl(width: 1200)
        }
      }
      subCategories {
        category
        categoryDetails {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useModelPortfolioInfoQuery__
 *
 * To run a query within a React component, call `useModelPortfolioInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelPortfolioInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelPortfolioInfoQuery({
 *   variables: {
 *      filterCategory: // value for 'filterCategory'
 *   },
 * });
 */
export function useModelPortfolioInfoQuery(baseOptions?: Apollo.QueryHookOptions<Types.ModelPortfolioInfoQuery, Types.ModelPortfolioInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ModelPortfolioInfoQuery, Types.ModelPortfolioInfoQueryVariables>(ModelPortfolioInfoDocument, options);
      }
export function useModelPortfolioInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ModelPortfolioInfoQuery, Types.ModelPortfolioInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ModelPortfolioInfoQuery, Types.ModelPortfolioInfoQueryVariables>(ModelPortfolioInfoDocument, options);
        }
export function useModelPortfolioInfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ModelPortfolioInfoQuery, Types.ModelPortfolioInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ModelPortfolioInfoQuery, Types.ModelPortfolioInfoQueryVariables>(ModelPortfolioInfoDocument, options);
        }
export type ModelPortfolioInfoQueryHookResult = ReturnType<typeof useModelPortfolioInfoQuery>;
export type ModelPortfolioInfoLazyQueryHookResult = ReturnType<typeof useModelPortfolioInfoLazyQuery>;
export type ModelPortfolioInfoSuspenseQueryHookResult = ReturnType<typeof useModelPortfolioInfoSuspenseQuery>;
export type ModelPortfolioInfoQueryResult = Apollo.QueryResult<Types.ModelPortfolioInfoQuery, Types.ModelPortfolioInfoQueryVariables>;
export const ModelPortfolioScreenerDocument = gql`
    query ModelPortfolioScreener($filterCategory: [String!], $sort: [SystemPieSortOptionInput!], $first: Int, $after: String) {
  viewer {
    screenSystemPies(
      filterCategory: $filterCategory
      sort: $sort
      first: $first
      after: $after
    ) {
      total
      currentCategory {
        categoryDetails {
          key
          name
          description
          featureUrl(width: 1200)
        }
      }
      subCategories {
        category
        categoryDetails {
          name
        }
      }
      edges {
        node {
          __typename
          ...ModelPortfolioScreenerNode
        }
      }
    }
  }
}
    ${ModelPortfolioScreenerNodeFragmentDoc}`;

/**
 * __useModelPortfolioScreenerQuery__
 *
 * To run a query within a React component, call `useModelPortfolioScreenerQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelPortfolioScreenerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelPortfolioScreenerQuery({
 *   variables: {
 *      filterCategory: // value for 'filterCategory'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useModelPortfolioScreenerQuery(baseOptions?: Apollo.QueryHookOptions<Types.ModelPortfolioScreenerQuery, Types.ModelPortfolioScreenerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ModelPortfolioScreenerQuery, Types.ModelPortfolioScreenerQueryVariables>(ModelPortfolioScreenerDocument, options);
      }
export function useModelPortfolioScreenerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ModelPortfolioScreenerQuery, Types.ModelPortfolioScreenerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ModelPortfolioScreenerQuery, Types.ModelPortfolioScreenerQueryVariables>(ModelPortfolioScreenerDocument, options);
        }
export function useModelPortfolioScreenerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ModelPortfolioScreenerQuery, Types.ModelPortfolioScreenerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ModelPortfolioScreenerQuery, Types.ModelPortfolioScreenerQueryVariables>(ModelPortfolioScreenerDocument, options);
        }
export type ModelPortfolioScreenerQueryHookResult = ReturnType<typeof useModelPortfolioScreenerQuery>;
export type ModelPortfolioScreenerLazyQueryHookResult = ReturnType<typeof useModelPortfolioScreenerLazyQuery>;
export type ModelPortfolioScreenerSuspenseQueryHookResult = ReturnType<typeof useModelPortfolioScreenerSuspenseQuery>;
export type ModelPortfolioScreenerQueryResult = Apollo.QueryResult<Types.ModelPortfolioScreenerQuery, Types.ModelPortfolioScreenerQueryVariables>;
export const GetRetirementYearFiltersDocument = gql`
    query GetRetirementYearFilters {
  viewer {
    screenSystemPies(filterCategory: ["RETIREMENT"]) {
      subCategories {
        category
        categoryDetails {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetRetirementYearFiltersQuery__
 *
 * To run a query within a React component, call `useGetRetirementYearFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRetirementYearFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRetirementYearFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRetirementYearFiltersQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetRetirementYearFiltersQuery, Types.GetRetirementYearFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetRetirementYearFiltersQuery, Types.GetRetirementYearFiltersQueryVariables>(GetRetirementYearFiltersDocument, options);
      }
export function useGetRetirementYearFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetRetirementYearFiltersQuery, Types.GetRetirementYearFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetRetirementYearFiltersQuery, Types.GetRetirementYearFiltersQueryVariables>(GetRetirementYearFiltersDocument, options);
        }
export function useGetRetirementYearFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetRetirementYearFiltersQuery, Types.GetRetirementYearFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetRetirementYearFiltersQuery, Types.GetRetirementYearFiltersQueryVariables>(GetRetirementYearFiltersDocument, options);
        }
export type GetRetirementYearFiltersQueryHookResult = ReturnType<typeof useGetRetirementYearFiltersQuery>;
export type GetRetirementYearFiltersLazyQueryHookResult = ReturnType<typeof useGetRetirementYearFiltersLazyQuery>;
export type GetRetirementYearFiltersSuspenseQueryHookResult = ReturnType<typeof useGetRetirementYearFiltersSuspenseQuery>;
export type GetRetirementYearFiltersQueryResult = Apollo.QueryResult<Types.GetRetirementYearFiltersQuery, Types.GetRetirementYearFiltersQueryVariables>;
export const SecurityCategoriesDocument = gql`
    query SecurityCategories($filterCategory: [String!]) {
  viewer {
    securities: screenSecurities(
      filterTypes: [EQUITY]
      filterCategory: $filterCategory
    ) {
      subCategories {
        category
        count
      }
    }
  }
}
    `;

/**
 * __useSecurityCategoriesQuery__
 *
 * To run a query within a React component, call `useSecurityCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecurityCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecurityCategoriesQuery({
 *   variables: {
 *      filterCategory: // value for 'filterCategory'
 *   },
 * });
 */
export function useSecurityCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.SecurityCategoriesQuery, Types.SecurityCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SecurityCategoriesQuery, Types.SecurityCategoriesQueryVariables>(SecurityCategoriesDocument, options);
      }
export function useSecurityCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SecurityCategoriesQuery, Types.SecurityCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SecurityCategoriesQuery, Types.SecurityCategoriesQueryVariables>(SecurityCategoriesDocument, options);
        }
export function useSecurityCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SecurityCategoriesQuery, Types.SecurityCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SecurityCategoriesQuery, Types.SecurityCategoriesQueryVariables>(SecurityCategoriesDocument, options);
        }
export type SecurityCategoriesQueryHookResult = ReturnType<typeof useSecurityCategoriesQuery>;
export type SecurityCategoriesLazyQueryHookResult = ReturnType<typeof useSecurityCategoriesLazyQuery>;
export type SecurityCategoriesSuspenseQueryHookResult = ReturnType<typeof useSecurityCategoriesSuspenseQuery>;
export type SecurityCategoriesQueryResult = Apollo.QueryResult<Types.SecurityCategoriesQuery, Types.SecurityCategoriesQueryVariables>;
export const FundCategoriesDocument = gql`
    query FundCategories($filterCategory: [String!]) {
  viewer {
    securities: screenFunds(filterCategory: $filterCategory) {
      subCategories {
        category
        count
      }
    }
  }
}
    `;

/**
 * __useFundCategoriesQuery__
 *
 * To run a query within a React component, call `useFundCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundCategoriesQuery({
 *   variables: {
 *      filterCategory: // value for 'filterCategory'
 *   },
 * });
 */
export function useFundCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.FundCategoriesQuery, Types.FundCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FundCategoriesQuery, Types.FundCategoriesQueryVariables>(FundCategoriesDocument, options);
      }
export function useFundCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FundCategoriesQuery, Types.FundCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FundCategoriesQuery, Types.FundCategoriesQueryVariables>(FundCategoriesDocument, options);
        }
export function useFundCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.FundCategoriesQuery, Types.FundCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.FundCategoriesQuery, Types.FundCategoriesQueryVariables>(FundCategoriesDocument, options);
        }
export type FundCategoriesQueryHookResult = ReturnType<typeof useFundCategoriesQuery>;
export type FundCategoriesLazyQueryHookResult = ReturnType<typeof useFundCategoriesLazyQuery>;
export type FundCategoriesSuspenseQueryHookResult = ReturnType<typeof useFundCategoriesSuspenseQuery>;
export type FundCategoriesQueryResult = Apollo.QueryResult<Types.FundCategoriesQuery, Types.FundCategoriesQueryVariables>;
export const SecurityDetailsDocument = gql`
    query SecurityDetails($id: ID!) {
  security: node(id: $id) {
    ...SecurityDetail
  }
}
    ${SecurityDetailFragmentDoc}`;

/**
 * __useSecurityDetailsQuery__
 *
 * To run a query within a React component, call `useSecurityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecurityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecurityDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSecurityDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.SecurityDetailsQuery, Types.SecurityDetailsQueryVariables> & ({ variables: Types.SecurityDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SecurityDetailsQuery, Types.SecurityDetailsQueryVariables>(SecurityDetailsDocument, options);
      }
export function useSecurityDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SecurityDetailsQuery, Types.SecurityDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SecurityDetailsQuery, Types.SecurityDetailsQueryVariables>(SecurityDetailsDocument, options);
        }
export function useSecurityDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SecurityDetailsQuery, Types.SecurityDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SecurityDetailsQuery, Types.SecurityDetailsQueryVariables>(SecurityDetailsDocument, options);
        }
export type SecurityDetailsQueryHookResult = ReturnType<typeof useSecurityDetailsQuery>;
export type SecurityDetailsLazyQueryHookResult = ReturnType<typeof useSecurityDetailsLazyQuery>;
export type SecurityDetailsSuspenseQueryHookResult = ReturnType<typeof useSecurityDetailsSuspenseQuery>;
export type SecurityDetailsQueryResult = Apollo.QueryResult<Types.SecurityDetailsQuery, Types.SecurityDetailsQueryVariables>;
export const GetSecurityNewsDocument = gql`
    query GetSecurityNews($first: Int!, $after: String, $securityId: ID!) {
  security: node(id: $securityId) {
    ...SecurityNews
  }
}
    ${SecurityNewsFragmentDoc}`;

/**
 * __useGetSecurityNewsQuery__
 *
 * To run a query within a React component, call `useGetSecurityNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecurityNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecurityNewsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      securityId: // value for 'securityId'
 *   },
 * });
 */
export function useGetSecurityNewsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSecurityNewsQuery, Types.GetSecurityNewsQueryVariables> & ({ variables: Types.GetSecurityNewsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSecurityNewsQuery, Types.GetSecurityNewsQueryVariables>(GetSecurityNewsDocument, options);
      }
export function useGetSecurityNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSecurityNewsQuery, Types.GetSecurityNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSecurityNewsQuery, Types.GetSecurityNewsQueryVariables>(GetSecurityNewsDocument, options);
        }
export function useGetSecurityNewsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetSecurityNewsQuery, Types.GetSecurityNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetSecurityNewsQuery, Types.GetSecurityNewsQueryVariables>(GetSecurityNewsDocument, options);
        }
export type GetSecurityNewsQueryHookResult = ReturnType<typeof useGetSecurityNewsQuery>;
export type GetSecurityNewsLazyQueryHookResult = ReturnType<typeof useGetSecurityNewsLazyQuery>;
export type GetSecurityNewsSuspenseQueryHookResult = ReturnType<typeof useGetSecurityNewsSuspenseQuery>;
export type GetSecurityNewsQueryResult = Apollo.QueryResult<Types.GetSecurityNewsQuery, Types.GetSecurityNewsQueryVariables>;
export const SecurityScreenerDocument = gql`
    query SecurityScreener($filterTypes: [SecurityTypeEnum!], $filterCategory: [String!], $limit: [SecurityLimitOptionInput!], $sort: [SecuritySortOptionInput!], $after: String, $first: Int!, $period: HistoricalQuotePeriodEnum!) {
  viewer {
    securities: screenSecurities(
      filterTypes: $filterTypes
      filterCategory: $filterCategory
      limit: $limit
      sort: $sort
      after: $after
      first: $first
    ) {
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
      }
      subCategories {
        category
        count
      }
      total
      edges {
        ...ScreenerSecurity
      }
    }
  }
}
    ${ScreenerSecurityFragmentDoc}`;

/**
 * __useSecurityScreenerQuery__
 *
 * To run a query within a React component, call `useSecurityScreenerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecurityScreenerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecurityScreenerQuery({
 *   variables: {
 *      filterTypes: // value for 'filterTypes'
 *      filterCategory: // value for 'filterCategory'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useSecurityScreenerQuery(baseOptions: Apollo.QueryHookOptions<Types.SecurityScreenerQuery, Types.SecurityScreenerQueryVariables> & ({ variables: Types.SecurityScreenerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SecurityScreenerQuery, Types.SecurityScreenerQueryVariables>(SecurityScreenerDocument, options);
      }
export function useSecurityScreenerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SecurityScreenerQuery, Types.SecurityScreenerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SecurityScreenerQuery, Types.SecurityScreenerQueryVariables>(SecurityScreenerDocument, options);
        }
export function useSecurityScreenerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SecurityScreenerQuery, Types.SecurityScreenerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SecurityScreenerQuery, Types.SecurityScreenerQueryVariables>(SecurityScreenerDocument, options);
        }
export type SecurityScreenerQueryHookResult = ReturnType<typeof useSecurityScreenerQuery>;
export type SecurityScreenerLazyQueryHookResult = ReturnType<typeof useSecurityScreenerLazyQuery>;
export type SecurityScreenerSuspenseQueryHookResult = ReturnType<typeof useSecurityScreenerSuspenseQuery>;
export type SecurityScreenerQueryResult = Apollo.QueryResult<Types.SecurityScreenerQuery, Types.SecurityScreenerQueryVariables>;
export const WatchlistItemsDocument = gql`
    query WatchlistItems($ids: [ID!]!) {
  nodes(ids: $ids) {
    ...WatchlistItem
  }
}
    ${WatchlistItemFragmentDoc}`;

/**
 * __useWatchlistItemsQuery__
 *
 * To run a query within a React component, call `useWatchlistItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWatchlistItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchlistItemsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useWatchlistItemsQuery(baseOptions: Apollo.QueryHookOptions<Types.WatchlistItemsQuery, Types.WatchlistItemsQueryVariables> & ({ variables: Types.WatchlistItemsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WatchlistItemsQuery, Types.WatchlistItemsQueryVariables>(WatchlistItemsDocument, options);
      }
export function useWatchlistItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WatchlistItemsQuery, Types.WatchlistItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WatchlistItemsQuery, Types.WatchlistItemsQueryVariables>(WatchlistItemsDocument, options);
        }
export function useWatchlistItemsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WatchlistItemsQuery, Types.WatchlistItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WatchlistItemsQuery, Types.WatchlistItemsQueryVariables>(WatchlistItemsDocument, options);
        }
export type WatchlistItemsQueryHookResult = ReturnType<typeof useWatchlistItemsQuery>;
export type WatchlistItemsLazyQueryHookResult = ReturnType<typeof useWatchlistItemsLazyQuery>;
export type WatchlistItemsSuspenseQueryHookResult = ReturnType<typeof useWatchlistItemsSuspenseQuery>;
export type WatchlistItemsQueryResult = Apollo.QueryResult<Types.WatchlistItemsQuery, Types.WatchlistItemsQueryVariables>;
export const WatchlistDocument = gql`
    query Watchlist {
  viewer {
    user {
      data {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useWatchlistQuery__
 *
 * To run a query within a React component, call `useWatchlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useWatchlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchlistQuery({
 *   variables: {
 *   },
 * });
 */
export function useWatchlistQuery(baseOptions?: Apollo.QueryHookOptions<Types.WatchlistQuery, Types.WatchlistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WatchlistQuery, Types.WatchlistQueryVariables>(WatchlistDocument, options);
      }
export function useWatchlistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WatchlistQuery, Types.WatchlistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WatchlistQuery, Types.WatchlistQueryVariables>(WatchlistDocument, options);
        }
export function useWatchlistSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WatchlistQuery, Types.WatchlistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WatchlistQuery, Types.WatchlistQueryVariables>(WatchlistDocument, options);
        }
export type WatchlistQueryHookResult = ReturnType<typeof useWatchlistQuery>;
export type WatchlistLazyQueryHookResult = ReturnType<typeof useWatchlistLazyQuery>;
export type WatchlistSuspenseQueryHookResult = ReturnType<typeof useWatchlistSuspenseQuery>;
export type WatchlistQueryResult = Apollo.QueryResult<Types.WatchlistQuery, Types.WatchlistQueryVariables>;
export const ViewerIdDocument = gql`
    query ViewerId {
  viewer {
    id
  }
}
    `;

/**
 * __useViewerIdQuery__
 *
 * To run a query within a React component, call `useViewerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerIdQuery(baseOptions?: Apollo.QueryHookOptions<Types.ViewerIdQuery, Types.ViewerIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ViewerIdQuery, Types.ViewerIdQueryVariables>(ViewerIdDocument, options);
      }
export function useViewerIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ViewerIdQuery, Types.ViewerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ViewerIdQuery, Types.ViewerIdQueryVariables>(ViewerIdDocument, options);
        }
export function useViewerIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ViewerIdQuery, Types.ViewerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ViewerIdQuery, Types.ViewerIdQueryVariables>(ViewerIdDocument, options);
        }
export type ViewerIdQueryHookResult = ReturnType<typeof useViewerIdQuery>;
export type ViewerIdLazyQueryHookResult = ReturnType<typeof useViewerIdLazyQuery>;
export type ViewerIdSuspenseQueryHookResult = ReturnType<typeof useViewerIdSuspenseQuery>;
export type ViewerIdQueryResult = Apollo.QueryResult<Types.ViewerIdQuery, Types.ViewerIdQueryVariables>;
export const GetUserIdDocument = gql`
    query GetUserId {
  viewer {
    user {
      id
    }
  }
}
    `;

/**
 * __useGetUserIdQuery__
 *
 * To run a query within a React component, call `useGetUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserIdQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetUserIdQuery, Types.GetUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserIdQuery, Types.GetUserIdQueryVariables>(GetUserIdDocument, options);
      }
export function useGetUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserIdQuery, Types.GetUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserIdQuery, Types.GetUserIdQueryVariables>(GetUserIdDocument, options);
        }
export function useGetUserIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetUserIdQuery, Types.GetUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetUserIdQuery, Types.GetUserIdQueryVariables>(GetUserIdDocument, options);
        }
export type GetUserIdQueryHookResult = ReturnType<typeof useGetUserIdQuery>;
export type GetUserIdLazyQueryHookResult = ReturnType<typeof useGetUserIdLazyQuery>;
export type GetUserIdSuspenseQueryHookResult = ReturnType<typeof useGetUserIdSuspenseQuery>;
export type GetUserIdQueryResult = Apollo.QueryResult<Types.GetUserIdQuery, Types.GetUserIdQueryVariables>;
export const HasUserOnboardedSagaDocument = gql`
    query HasUserOnboardedSaga {
  viewer {
    user {
      hasOnboarded
      hasProduct
    }
    profile {
      identityStatus
    }
  }
}
    `;

/**
 * __useHasUserOnboardedSagaQuery__
 *
 * To run a query within a React component, call `useHasUserOnboardedSagaQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasUserOnboardedSagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasUserOnboardedSagaQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasUserOnboardedSagaQuery(baseOptions?: Apollo.QueryHookOptions<Types.HasUserOnboardedSagaQuery, Types.HasUserOnboardedSagaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HasUserOnboardedSagaQuery, Types.HasUserOnboardedSagaQueryVariables>(HasUserOnboardedSagaDocument, options);
      }
export function useHasUserOnboardedSagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HasUserOnboardedSagaQuery, Types.HasUserOnboardedSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HasUserOnboardedSagaQuery, Types.HasUserOnboardedSagaQueryVariables>(HasUserOnboardedSagaDocument, options);
        }
export function useHasUserOnboardedSagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.HasUserOnboardedSagaQuery, Types.HasUserOnboardedSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.HasUserOnboardedSagaQuery, Types.HasUserOnboardedSagaQueryVariables>(HasUserOnboardedSagaDocument, options);
        }
export type HasUserOnboardedSagaQueryHookResult = ReturnType<typeof useHasUserOnboardedSagaQuery>;
export type HasUserOnboardedSagaLazyQueryHookResult = ReturnType<typeof useHasUserOnboardedSagaLazyQuery>;
export type HasUserOnboardedSagaSuspenseQueryHookResult = ReturnType<typeof useHasUserOnboardedSagaSuspenseQuery>;
export type HasUserOnboardedSagaQueryResult = Apollo.QueryResult<Types.HasUserOnboardedSagaQuery, Types.HasUserOnboardedSagaQueryVariables>;
export const CheckSystemStatusDocument = gql`
    query CheckSystemStatus {
  system {
    version
    status
    statusMessage
    isStatusBlocking
    blockedFunctionality
  }
}
    `;

/**
 * __useCheckSystemStatusQuery__
 *
 * To run a query within a React component, call `useCheckSystemStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckSystemStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckSystemStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckSystemStatusQuery(baseOptions?: Apollo.QueryHookOptions<Types.CheckSystemStatusQuery, Types.CheckSystemStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckSystemStatusQuery, Types.CheckSystemStatusQueryVariables>(CheckSystemStatusDocument, options);
      }
export function useCheckSystemStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckSystemStatusQuery, Types.CheckSystemStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckSystemStatusQuery, Types.CheckSystemStatusQueryVariables>(CheckSystemStatusDocument, options);
        }
export function useCheckSystemStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CheckSystemStatusQuery, Types.CheckSystemStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CheckSystemStatusQuery, Types.CheckSystemStatusQueryVariables>(CheckSystemStatusDocument, options);
        }
export type CheckSystemStatusQueryHookResult = ReturnType<typeof useCheckSystemStatusQuery>;
export type CheckSystemStatusLazyQueryHookResult = ReturnType<typeof useCheckSystemStatusLazyQuery>;
export type CheckSystemStatusSuspenseQueryHookResult = ReturnType<typeof useCheckSystemStatusSuspenseQuery>;
export type CheckSystemStatusQueryResult = Apollo.QueryResult<Types.CheckSystemStatusQuery, Types.CheckSystemStatusQueryVariables>;
export const SharedPieTokenDocument = gql`
    query SharedPieToken($shareToken: String!) {
  viewer {
    sharedPie(shareToken: $shareToken) {
      ... on SharedPie {
        id
        name
        description
        editModel
      }
    }
  }
}
    `;

/**
 * __useSharedPieTokenQuery__
 *
 * To run a query within a React component, call `useSharedPieTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedPieTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedPieTokenQuery({
 *   variables: {
 *      shareToken: // value for 'shareToken'
 *   },
 * });
 */
export function useSharedPieTokenQuery(baseOptions: Apollo.QueryHookOptions<Types.SharedPieTokenQuery, Types.SharedPieTokenQueryVariables> & ({ variables: Types.SharedPieTokenQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SharedPieTokenQuery, Types.SharedPieTokenQueryVariables>(SharedPieTokenDocument, options);
      }
export function useSharedPieTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SharedPieTokenQuery, Types.SharedPieTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SharedPieTokenQuery, Types.SharedPieTokenQueryVariables>(SharedPieTokenDocument, options);
        }
export function useSharedPieTokenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SharedPieTokenQuery, Types.SharedPieTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SharedPieTokenQuery, Types.SharedPieTokenQueryVariables>(SharedPieTokenDocument, options);
        }
export type SharedPieTokenQueryHookResult = ReturnType<typeof useSharedPieTokenQuery>;
export type SharedPieTokenLazyQueryHookResult = ReturnType<typeof useSharedPieTokenLazyQuery>;
export type SharedPieTokenSuspenseQueryHookResult = ReturnType<typeof useSharedPieTokenSuspenseQuery>;
export type SharedPieTokenQueryResult = Apollo.QueryResult<Types.SharedPieTokenQuery, Types.SharedPieTokenQueryVariables>;
export const GetAccountIdFromPortfolioSliceIdDocument = gql`
    query GetAccountIdFromPortfolioSliceId($portfolioSliceId: ID!) {
  node(id: $portfolioSliceId) {
    ... on PortfolioSlice {
      account {
        id
      }
    }
  }
}
    `;

/**
 * __useGetAccountIdFromPortfolioSliceIdQuery__
 *
 * To run a query within a React component, call `useGetAccountIdFromPortfolioSliceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountIdFromPortfolioSliceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountIdFromPortfolioSliceIdQuery({
 *   variables: {
 *      portfolioSliceId: // value for 'portfolioSliceId'
 *   },
 * });
 */
export function useGetAccountIdFromPortfolioSliceIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAccountIdFromPortfolioSliceIdQuery, Types.GetAccountIdFromPortfolioSliceIdQueryVariables> & ({ variables: Types.GetAccountIdFromPortfolioSliceIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAccountIdFromPortfolioSliceIdQuery, Types.GetAccountIdFromPortfolioSliceIdQueryVariables>(GetAccountIdFromPortfolioSliceIdDocument, options);
      }
export function useGetAccountIdFromPortfolioSliceIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAccountIdFromPortfolioSliceIdQuery, Types.GetAccountIdFromPortfolioSliceIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAccountIdFromPortfolioSliceIdQuery, Types.GetAccountIdFromPortfolioSliceIdQueryVariables>(GetAccountIdFromPortfolioSliceIdDocument, options);
        }
export function useGetAccountIdFromPortfolioSliceIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetAccountIdFromPortfolioSliceIdQuery, Types.GetAccountIdFromPortfolioSliceIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetAccountIdFromPortfolioSliceIdQuery, Types.GetAccountIdFromPortfolioSliceIdQueryVariables>(GetAccountIdFromPortfolioSliceIdDocument, options);
        }
export type GetAccountIdFromPortfolioSliceIdQueryHookResult = ReturnType<typeof useGetAccountIdFromPortfolioSliceIdQuery>;
export type GetAccountIdFromPortfolioSliceIdLazyQueryHookResult = ReturnType<typeof useGetAccountIdFromPortfolioSliceIdLazyQuery>;
export type GetAccountIdFromPortfolioSliceIdSuspenseQueryHookResult = ReturnType<typeof useGetAccountIdFromPortfolioSliceIdSuspenseQuery>;
export type GetAccountIdFromPortfolioSliceIdQueryResult = Apollo.QueryResult<Types.GetAccountIdFromPortfolioSliceIdQuery, Types.GetAccountIdFromPortfolioSliceIdQueryVariables>;
export const HasInvestSuitabilityDocument = gql`
    query HasInvestSuitability {
  viewer {
    profile {
      suitability {
        investmentExperience
        liquidityNeeds
        riskTolerance
        timeHorizon
      }
    }
  }
}
    `;

/**
 * __useHasInvestSuitabilityQuery__
 *
 * To run a query within a React component, call `useHasInvestSuitabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasInvestSuitabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasInvestSuitabilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasInvestSuitabilityQuery(baseOptions?: Apollo.QueryHookOptions<Types.HasInvestSuitabilityQuery, Types.HasInvestSuitabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HasInvestSuitabilityQuery, Types.HasInvestSuitabilityQueryVariables>(HasInvestSuitabilityDocument, options);
      }
export function useHasInvestSuitabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HasInvestSuitabilityQuery, Types.HasInvestSuitabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HasInvestSuitabilityQuery, Types.HasInvestSuitabilityQueryVariables>(HasInvestSuitabilityDocument, options);
        }
export function useHasInvestSuitabilitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.HasInvestSuitabilityQuery, Types.HasInvestSuitabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.HasInvestSuitabilityQuery, Types.HasInvestSuitabilityQueryVariables>(HasInvestSuitabilityDocument, options);
        }
export type HasInvestSuitabilityQueryHookResult = ReturnType<typeof useHasInvestSuitabilityQuery>;
export type HasInvestSuitabilityLazyQueryHookResult = ReturnType<typeof useHasInvestSuitabilityLazyQuery>;
export type HasInvestSuitabilitySuspenseQueryHookResult = ReturnType<typeof useHasInvestSuitabilitySuspenseQuery>;
export type HasInvestSuitabilityQueryResult = Apollo.QueryResult<Types.HasInvestSuitabilityQuery, Types.HasInvestSuitabilityQueryVariables>;
export const HasInvestAccountSagaDocument = gql`
    query HasInvestAccountSaga {
  viewer {
    invest {
      hasInvestAccount
    }
  }
}
    `;

/**
 * __useHasInvestAccountSagaQuery__
 *
 * To run a query within a React component, call `useHasInvestAccountSagaQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasInvestAccountSagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasInvestAccountSagaQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasInvestAccountSagaQuery(baseOptions?: Apollo.QueryHookOptions<Types.HasInvestAccountSagaQuery, Types.HasInvestAccountSagaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HasInvestAccountSagaQuery, Types.HasInvestAccountSagaQueryVariables>(HasInvestAccountSagaDocument, options);
      }
export function useHasInvestAccountSagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HasInvestAccountSagaQuery, Types.HasInvestAccountSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HasInvestAccountSagaQuery, Types.HasInvestAccountSagaQueryVariables>(HasInvestAccountSagaDocument, options);
        }
export function useHasInvestAccountSagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.HasInvestAccountSagaQuery, Types.HasInvestAccountSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.HasInvestAccountSagaQuery, Types.HasInvestAccountSagaQueryVariables>(HasInvestAccountSagaDocument, options);
        }
export type HasInvestAccountSagaQueryHookResult = ReturnType<typeof useHasInvestAccountSagaQuery>;
export type HasInvestAccountSagaLazyQueryHookResult = ReturnType<typeof useHasInvestAccountSagaLazyQuery>;
export type HasInvestAccountSagaSuspenseQueryHookResult = ReturnType<typeof useHasInvestAccountSagaSuspenseQuery>;
export type HasInvestAccountSagaQueryResult = Apollo.QueryResult<Types.HasInvestAccountSagaQuery, Types.HasInvestAccountSagaQueryVariables>;
export const HasFundingSourceSagaDocument = gql`
    query HasFundingSourceSaga {
  viewer {
    fundingSources {
      hasFundingSources
    }
  }
}
    `;

/**
 * __useHasFundingSourceSagaQuery__
 *
 * To run a query within a React component, call `useHasFundingSourceSagaQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasFundingSourceSagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasFundingSourceSagaQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasFundingSourceSagaQuery(baseOptions?: Apollo.QueryHookOptions<Types.HasFundingSourceSagaQuery, Types.HasFundingSourceSagaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HasFundingSourceSagaQuery, Types.HasFundingSourceSagaQueryVariables>(HasFundingSourceSagaDocument, options);
      }
export function useHasFundingSourceSagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HasFundingSourceSagaQuery, Types.HasFundingSourceSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HasFundingSourceSagaQuery, Types.HasFundingSourceSagaQueryVariables>(HasFundingSourceSagaDocument, options);
        }
export function useHasFundingSourceSagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.HasFundingSourceSagaQuery, Types.HasFundingSourceSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.HasFundingSourceSagaQuery, Types.HasFundingSourceSagaQueryVariables>(HasFundingSourceSagaDocument, options);
        }
export type HasFundingSourceSagaQueryHookResult = ReturnType<typeof useHasFundingSourceSagaQuery>;
export type HasFundingSourceSagaLazyQueryHookResult = ReturnType<typeof useHasFundingSourceSagaLazyQuery>;
export type HasFundingSourceSagaSuspenseQueryHookResult = ReturnType<typeof useHasFundingSourceSagaSuspenseQuery>;
export type HasFundingSourceSagaQueryResult = Apollo.QueryResult<Types.HasFundingSourceSagaQuery, Types.HasFundingSourceSagaQueryVariables>;
export const QueryAllPortfoliosDocument = gql`
    query QueryAllPortfolios {
  viewer {
    accounts(first: 20) {
      edges {
        node {
          hasPortfolio
        }
      }
    }
  }
}
    `;

/**
 * __useQueryAllPortfoliosQuery__
 *
 * To run a query within a React component, call `useQueryAllPortfoliosQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryAllPortfoliosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryAllPortfoliosQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryAllPortfoliosQuery(baseOptions?: Apollo.QueryHookOptions<Types.QueryAllPortfoliosQuery, Types.QueryAllPortfoliosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.QueryAllPortfoliosQuery, Types.QueryAllPortfoliosQueryVariables>(QueryAllPortfoliosDocument, options);
      }
export function useQueryAllPortfoliosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.QueryAllPortfoliosQuery, Types.QueryAllPortfoliosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.QueryAllPortfoliosQuery, Types.QueryAllPortfoliosQueryVariables>(QueryAllPortfoliosDocument, options);
        }
export function useQueryAllPortfoliosSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.QueryAllPortfoliosQuery, Types.QueryAllPortfoliosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.QueryAllPortfoliosQuery, Types.QueryAllPortfoliosQueryVariables>(QueryAllPortfoliosDocument, options);
        }
export type QueryAllPortfoliosQueryHookResult = ReturnType<typeof useQueryAllPortfoliosQuery>;
export type QueryAllPortfoliosLazyQueryHookResult = ReturnType<typeof useQueryAllPortfoliosLazyQuery>;
export type QueryAllPortfoliosSuspenseQueryHookResult = ReturnType<typeof useQueryAllPortfoliosSuspenseQuery>;
export type QueryAllPortfoliosQueryResult = Apollo.QueryResult<Types.QueryAllPortfoliosQuery, Types.QueryAllPortfoliosQueryVariables>;
export const InvestAccountOpeningQuestionsDocument = gql`
    query InvestAccountOpeningQuestions {
  viewer {
    invest {
      hasActiveInvestAccount
      accountOpening {
        title
        questions {
          questionType
          title
          subtitle
          continueLabel
          skipLabel
          disclaimerTooltipContent
          previousQuestionType
          nextQuestionType
        }
      }
    }
  }
}
    `;

/**
 * __useInvestAccountOpeningQuestionsQuery__
 *
 * To run a query within a React component, call `useInvestAccountOpeningQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestAccountOpeningQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestAccountOpeningQuestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvestAccountOpeningQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.InvestAccountOpeningQuestionsQuery, Types.InvestAccountOpeningQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvestAccountOpeningQuestionsQuery, Types.InvestAccountOpeningQuestionsQueryVariables>(InvestAccountOpeningQuestionsDocument, options);
      }
export function useInvestAccountOpeningQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvestAccountOpeningQuestionsQuery, Types.InvestAccountOpeningQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvestAccountOpeningQuestionsQuery, Types.InvestAccountOpeningQuestionsQueryVariables>(InvestAccountOpeningQuestionsDocument, options);
        }
export function useInvestAccountOpeningQuestionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.InvestAccountOpeningQuestionsQuery, Types.InvestAccountOpeningQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.InvestAccountOpeningQuestionsQuery, Types.InvestAccountOpeningQuestionsQueryVariables>(InvestAccountOpeningQuestionsDocument, options);
        }
export type InvestAccountOpeningQuestionsQueryHookResult = ReturnType<typeof useInvestAccountOpeningQuestionsQuery>;
export type InvestAccountOpeningQuestionsLazyQueryHookResult = ReturnType<typeof useInvestAccountOpeningQuestionsLazyQuery>;
export type InvestAccountOpeningQuestionsSuspenseQueryHookResult = ReturnType<typeof useInvestAccountOpeningQuestionsSuspenseQuery>;
export type InvestAccountOpeningQuestionsQueryResult = Apollo.QueryResult<Types.InvestAccountOpeningQuestionsQuery, Types.InvestAccountOpeningQuestionsQueryVariables>;
export const IdentityFirstQuestionsDocument = gql`
    query IdentityFirstQuestions {
  viewer {
    user {
      onboardingIdentityFirst {
        userProfileSection {
          title
          questions {
            questionType
            title
            subtitle
            section
            continueLabel
            skipLabel
            disclaimerTooltipContent
            previousQuestionType
            nextQuestionType
          }
        }
        financialSuitabilitySection {
          title
          questions {
            questionType
            title
            subtitle
            section
            continueLabel
            skipLabel
            disclaimerTooltipContent
            previousQuestionType
            nextQuestionType
          }
        }
      }
    }
  }
}
    `;

/**
 * __useIdentityFirstQuestionsQuery__
 *
 * To run a query within a React component, call `useIdentityFirstQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdentityFirstQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdentityFirstQuestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useIdentityFirstQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.IdentityFirstQuestionsQuery, Types.IdentityFirstQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IdentityFirstQuestionsQuery, Types.IdentityFirstQuestionsQueryVariables>(IdentityFirstQuestionsDocument, options);
      }
export function useIdentityFirstQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IdentityFirstQuestionsQuery, Types.IdentityFirstQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IdentityFirstQuestionsQuery, Types.IdentityFirstQuestionsQueryVariables>(IdentityFirstQuestionsDocument, options);
        }
export function useIdentityFirstQuestionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.IdentityFirstQuestionsQuery, Types.IdentityFirstQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IdentityFirstQuestionsQuery, Types.IdentityFirstQuestionsQueryVariables>(IdentityFirstQuestionsDocument, options);
        }
export type IdentityFirstQuestionsQueryHookResult = ReturnType<typeof useIdentityFirstQuestionsQuery>;
export type IdentityFirstQuestionsLazyQueryHookResult = ReturnType<typeof useIdentityFirstQuestionsLazyQuery>;
export type IdentityFirstQuestionsSuspenseQueryHookResult = ReturnType<typeof useIdentityFirstQuestionsSuspenseQuery>;
export type IdentityFirstQuestionsQueryResult = Apollo.QueryResult<Types.IdentityFirstQuestionsQuery, Types.IdentityFirstQuestionsQueryVariables>;
export const IdentityFirstAccountSetupProfileDocument = gql`
    query IdentityFirstAccountSetupProfile {
  viewer {
    profile {
      primary {
        firstName
      }
      secondary {
        firstName
      }
      suitability {
        annualIncome
      }
      identityStatus
      beneficiaries {
        firstName
        lastName
        suffix
      }
    }
  }
}
    `;

/**
 * __useIdentityFirstAccountSetupProfileQuery__
 *
 * To run a query within a React component, call `useIdentityFirstAccountSetupProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdentityFirstAccountSetupProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdentityFirstAccountSetupProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useIdentityFirstAccountSetupProfileQuery(baseOptions?: Apollo.QueryHookOptions<Types.IdentityFirstAccountSetupProfileQuery, Types.IdentityFirstAccountSetupProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IdentityFirstAccountSetupProfileQuery, Types.IdentityFirstAccountSetupProfileQueryVariables>(IdentityFirstAccountSetupProfileDocument, options);
      }
export function useIdentityFirstAccountSetupProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IdentityFirstAccountSetupProfileQuery, Types.IdentityFirstAccountSetupProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IdentityFirstAccountSetupProfileQuery, Types.IdentityFirstAccountSetupProfileQueryVariables>(IdentityFirstAccountSetupProfileDocument, options);
        }
export function useIdentityFirstAccountSetupProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.IdentityFirstAccountSetupProfileQuery, Types.IdentityFirstAccountSetupProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IdentityFirstAccountSetupProfileQuery, Types.IdentityFirstAccountSetupProfileQueryVariables>(IdentityFirstAccountSetupProfileDocument, options);
        }
export type IdentityFirstAccountSetupProfileQueryHookResult = ReturnType<typeof useIdentityFirstAccountSetupProfileQuery>;
export type IdentityFirstAccountSetupProfileLazyQueryHookResult = ReturnType<typeof useIdentityFirstAccountSetupProfileLazyQuery>;
export type IdentityFirstAccountSetupProfileSuspenseQueryHookResult = ReturnType<typeof useIdentityFirstAccountSetupProfileSuspenseQuery>;
export type IdentityFirstAccountSetupProfileQueryResult = Apollo.QueryResult<Types.IdentityFirstAccountSetupProfileQuery, Types.IdentityFirstAccountSetupProfileQueryVariables>;
export const IdentityFirstAccountSetupCountDocument = gql`
    query IdentityFirstAccountSetupCount {
  viewer {
    accounts(first: 20) {
      edges {
        node {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useIdentityFirstAccountSetupCountQuery__
 *
 * To run a query within a React component, call `useIdentityFirstAccountSetupCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdentityFirstAccountSetupCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdentityFirstAccountSetupCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useIdentityFirstAccountSetupCountQuery(baseOptions?: Apollo.QueryHookOptions<Types.IdentityFirstAccountSetupCountQuery, Types.IdentityFirstAccountSetupCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IdentityFirstAccountSetupCountQuery, Types.IdentityFirstAccountSetupCountQueryVariables>(IdentityFirstAccountSetupCountDocument, options);
      }
export function useIdentityFirstAccountSetupCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IdentityFirstAccountSetupCountQuery, Types.IdentityFirstAccountSetupCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IdentityFirstAccountSetupCountQuery, Types.IdentityFirstAccountSetupCountQueryVariables>(IdentityFirstAccountSetupCountDocument, options);
        }
export function useIdentityFirstAccountSetupCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.IdentityFirstAccountSetupCountQuery, Types.IdentityFirstAccountSetupCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IdentityFirstAccountSetupCountQuery, Types.IdentityFirstAccountSetupCountQueryVariables>(IdentityFirstAccountSetupCountDocument, options);
        }
export type IdentityFirstAccountSetupCountQueryHookResult = ReturnType<typeof useIdentityFirstAccountSetupCountQuery>;
export type IdentityFirstAccountSetupCountLazyQueryHookResult = ReturnType<typeof useIdentityFirstAccountSetupCountLazyQuery>;
export type IdentityFirstAccountSetupCountSuspenseQueryHookResult = ReturnType<typeof useIdentityFirstAccountSetupCountSuspenseQuery>;
export type IdentityFirstAccountSetupCountQueryResult = Apollo.QueryResult<Types.IdentityFirstAccountSetupCountQuery, Types.IdentityFirstAccountSetupCountQueryVariables>;
export const IdentityFirstAccountSetupSpendOnboardedDocument = gql`
    query IdentityFirstAccountSetupSpendOnboarded {
  viewer {
    invest {
      hasInvestAccount
    }
  }
}
    `;

/**
 * __useIdentityFirstAccountSetupSpendOnboardedQuery__
 *
 * To run a query within a React component, call `useIdentityFirstAccountSetupSpendOnboardedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdentityFirstAccountSetupSpendOnboardedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdentityFirstAccountSetupSpendOnboardedQuery({
 *   variables: {
 *   },
 * });
 */
export function useIdentityFirstAccountSetupSpendOnboardedQuery(baseOptions?: Apollo.QueryHookOptions<Types.IdentityFirstAccountSetupSpendOnboardedQuery, Types.IdentityFirstAccountSetupSpendOnboardedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IdentityFirstAccountSetupSpendOnboardedQuery, Types.IdentityFirstAccountSetupSpendOnboardedQueryVariables>(IdentityFirstAccountSetupSpendOnboardedDocument, options);
      }
export function useIdentityFirstAccountSetupSpendOnboardedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IdentityFirstAccountSetupSpendOnboardedQuery, Types.IdentityFirstAccountSetupSpendOnboardedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IdentityFirstAccountSetupSpendOnboardedQuery, Types.IdentityFirstAccountSetupSpendOnboardedQueryVariables>(IdentityFirstAccountSetupSpendOnboardedDocument, options);
        }
export function useIdentityFirstAccountSetupSpendOnboardedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.IdentityFirstAccountSetupSpendOnboardedQuery, Types.IdentityFirstAccountSetupSpendOnboardedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IdentityFirstAccountSetupSpendOnboardedQuery, Types.IdentityFirstAccountSetupSpendOnboardedQueryVariables>(IdentityFirstAccountSetupSpendOnboardedDocument, options);
        }
export type IdentityFirstAccountSetupSpendOnboardedQueryHookResult = ReturnType<typeof useIdentityFirstAccountSetupSpendOnboardedQuery>;
export type IdentityFirstAccountSetupSpendOnboardedLazyQueryHookResult = ReturnType<typeof useIdentityFirstAccountSetupSpendOnboardedLazyQuery>;
export type IdentityFirstAccountSetupSpendOnboardedSuspenseQueryHookResult = ReturnType<typeof useIdentityFirstAccountSetupSpendOnboardedSuspenseQuery>;
export type IdentityFirstAccountSetupSpendOnboardedQueryResult = Apollo.QueryResult<Types.IdentityFirstAccountSetupSpendOnboardedQuery, Types.IdentityFirstAccountSetupSpendOnboardedQueryVariables>;
export const FullUserProfileSagaDocument = gql`
    query FullUserProfileSaga {
  viewer {
    id
    profile {
      primary {
        countryOfCitizenship
        dateOfBirth
        employmentInfo {
          status
          employer
          occupation
        }
        firstName
        middleInitial
        lastName
        suffix
        homeAddress {
          lineOne
          lineTwo
          city
          stateOrProvince
          postalCode
          country
        }
        phoneNumber
        additionalEmail
        exchangeOrFinraAffiliationDisclosure {
          isAffiliated
          firmName
        }
        controlPersonDisclosure {
          isControlPerson
          companySymbols
        }
        politicalDisclosure {
          isPoliticallyExposed
          politicalOrganization
          immediateFamilyMembers
        }
        backupWithholding {
          isSubjectToBackupWithholding
        }
      }
      secondary {
        countryOfCitizenship
        dateOfBirth
        employmentInfo {
          status
          employer
          occupation
        }
        firstName
        middleInitial
        lastName
        suffix
        homeAddress {
          lineOne
          lineTwo
          city
          stateOrProvince
          postalCode
          country
        }
        phoneNumber
        additionalEmail
      }
      beneficiaries {
        firstName
        lastName
        suffix
        dateOfBirth
        address {
          lineOne
          lineTwo
          city
          stateOrProvince
          postalCode
          country
        }
        relationship
        sharePercentage
        isPrimary
      }
      suitability {
        annualIncome
        totalNetWorth
        liquidNetWorth
        investmentExperience
        riskTolerance
        timeHorizon
        liquidityNeeds
      }
      allowsBeneficiaries
      trustedContact {
        firstName
        lastName
        email
        phoneNumber
      }
    }
  }
}
    `;

/**
 * __useFullUserProfileSagaQuery__
 *
 * To run a query within a React component, call `useFullUserProfileSagaQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullUserProfileSagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullUserProfileSagaQuery({
 *   variables: {
 *   },
 * });
 */
export function useFullUserProfileSagaQuery(baseOptions?: Apollo.QueryHookOptions<Types.FullUserProfileSagaQuery, Types.FullUserProfileSagaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FullUserProfileSagaQuery, Types.FullUserProfileSagaQueryVariables>(FullUserProfileSagaDocument, options);
      }
export function useFullUserProfileSagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FullUserProfileSagaQuery, Types.FullUserProfileSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FullUserProfileSagaQuery, Types.FullUserProfileSagaQueryVariables>(FullUserProfileSagaDocument, options);
        }
export function useFullUserProfileSagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.FullUserProfileSagaQuery, Types.FullUserProfileSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.FullUserProfileSagaQuery, Types.FullUserProfileSagaQueryVariables>(FullUserProfileSagaDocument, options);
        }
export type FullUserProfileSagaQueryHookResult = ReturnType<typeof useFullUserProfileSagaQuery>;
export type FullUserProfileSagaLazyQueryHookResult = ReturnType<typeof useFullUserProfileSagaLazyQuery>;
export type FullUserProfileSagaSuspenseQueryHookResult = ReturnType<typeof useFullUserProfileSagaSuspenseQuery>;
export type FullUserProfileSagaQueryResult = Apollo.QueryResult<Types.FullUserProfileSagaQuery, Types.FullUserProfileSagaQueryVariables>;
export const PartialUserProfileSagaDocument = gql`
    query PartialUserProfileSaga {
  viewer {
    id
    profile {
      primary {
        firstName
        middleInitial
        lastName
        suffix
        homeAddress {
          lineOne
          lineTwo
          city
          stateOrProvince
          postalCode
          country
        }
        phoneNumber
        additionalEmail
      }
      secondary {
        firstName
        middleInitial
        lastName
        suffix
        homeAddress {
          lineOne
          lineTwo
          city
          stateOrProvince
          postalCode
          country
        }
        phoneNumber
        additionalEmail
      }
      beneficiaries {
        firstName
        lastName
        suffix
        dateOfBirth
        address {
          lineOne
          lineTwo
          city
          stateOrProvince
          postalCode
          country
        }
        relationship
        sharePercentage
        isPrimary
      }
      allowsBeneficiaries
      trustedContact {
        firstName
        lastName
        email
        phoneNumber
      }
    }
  }
}
    `;

/**
 * __usePartialUserProfileSagaQuery__
 *
 * To run a query within a React component, call `usePartialUserProfileSagaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartialUserProfileSagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartialUserProfileSagaQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartialUserProfileSagaQuery(baseOptions?: Apollo.QueryHookOptions<Types.PartialUserProfileSagaQuery, Types.PartialUserProfileSagaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PartialUserProfileSagaQuery, Types.PartialUserProfileSagaQueryVariables>(PartialUserProfileSagaDocument, options);
      }
export function usePartialUserProfileSagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PartialUserProfileSagaQuery, Types.PartialUserProfileSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PartialUserProfileSagaQuery, Types.PartialUserProfileSagaQueryVariables>(PartialUserProfileSagaDocument, options);
        }
export function usePartialUserProfileSagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PartialUserProfileSagaQuery, Types.PartialUserProfileSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PartialUserProfileSagaQuery, Types.PartialUserProfileSagaQueryVariables>(PartialUserProfileSagaDocument, options);
        }
export type PartialUserProfileSagaQueryHookResult = ReturnType<typeof usePartialUserProfileSagaQuery>;
export type PartialUserProfileSagaLazyQueryHookResult = ReturnType<typeof usePartialUserProfileSagaLazyQuery>;
export type PartialUserProfileSagaSuspenseQueryHookResult = ReturnType<typeof usePartialUserProfileSagaSuspenseQuery>;
export type PartialUserProfileSagaQueryResult = Apollo.QueryResult<Types.PartialUserProfileSagaQuery, Types.PartialUserProfileSagaQueryVariables>;
export const DetermineInitialSmartTransferStepDocument = gql`
    query DetermineInitialSmartTransferStep {
  viewer {
    user {
      data {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useDetermineInitialSmartTransferStepQuery__
 *
 * To run a query within a React component, call `useDetermineInitialSmartTransferStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetermineInitialSmartTransferStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetermineInitialSmartTransferStepQuery({
 *   variables: {
 *   },
 * });
 */
export function useDetermineInitialSmartTransferStepQuery(baseOptions?: Apollo.QueryHookOptions<Types.DetermineInitialSmartTransferStepQuery, Types.DetermineInitialSmartTransferStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DetermineInitialSmartTransferStepQuery, Types.DetermineInitialSmartTransferStepQueryVariables>(DetermineInitialSmartTransferStepDocument, options);
      }
export function useDetermineInitialSmartTransferStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DetermineInitialSmartTransferStepQuery, Types.DetermineInitialSmartTransferStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DetermineInitialSmartTransferStepQuery, Types.DetermineInitialSmartTransferStepQueryVariables>(DetermineInitialSmartTransferStepDocument, options);
        }
export function useDetermineInitialSmartTransferStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.DetermineInitialSmartTransferStepQuery, Types.DetermineInitialSmartTransferStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DetermineInitialSmartTransferStepQuery, Types.DetermineInitialSmartTransferStepQueryVariables>(DetermineInitialSmartTransferStepDocument, options);
        }
export type DetermineInitialSmartTransferStepQueryHookResult = ReturnType<typeof useDetermineInitialSmartTransferStepQuery>;
export type DetermineInitialSmartTransferStepLazyQueryHookResult = ReturnType<typeof useDetermineInitialSmartTransferStepLazyQuery>;
export type DetermineInitialSmartTransferStepSuspenseQueryHookResult = ReturnType<typeof useDetermineInitialSmartTransferStepSuspenseQuery>;
export type DetermineInitialSmartTransferStepQueryResult = Apollo.QueryResult<Types.DetermineInitialSmartTransferStepQuery, Types.DetermineInitialSmartTransferStepQueryVariables>;
export const FocusParticipantsOnFocusOptionDocument = gql`
    query FocusParticipantsOnFocusOption($focusOptionId: ID!) {
  node(id: $focusOptionId) {
    ... on NewSmartTransferFocusOption {
      focusParticipants {
        list {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useFocusParticipantsOnFocusOptionQuery__
 *
 * To run a query within a React component, call `useFocusParticipantsOnFocusOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFocusParticipantsOnFocusOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFocusParticipantsOnFocusOptionQuery({
 *   variables: {
 *      focusOptionId: // value for 'focusOptionId'
 *   },
 * });
 */
export function useFocusParticipantsOnFocusOptionQuery(baseOptions: Apollo.QueryHookOptions<Types.FocusParticipantsOnFocusOptionQuery, Types.FocusParticipantsOnFocusOptionQueryVariables> & ({ variables: Types.FocusParticipantsOnFocusOptionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FocusParticipantsOnFocusOptionQuery, Types.FocusParticipantsOnFocusOptionQueryVariables>(FocusParticipantsOnFocusOptionDocument, options);
      }
export function useFocusParticipantsOnFocusOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FocusParticipantsOnFocusOptionQuery, Types.FocusParticipantsOnFocusOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FocusParticipantsOnFocusOptionQuery, Types.FocusParticipantsOnFocusOptionQueryVariables>(FocusParticipantsOnFocusOptionDocument, options);
        }
export function useFocusParticipantsOnFocusOptionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.FocusParticipantsOnFocusOptionQuery, Types.FocusParticipantsOnFocusOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.FocusParticipantsOnFocusOptionQuery, Types.FocusParticipantsOnFocusOptionQueryVariables>(FocusParticipantsOnFocusOptionDocument, options);
        }
export type FocusParticipantsOnFocusOptionQueryHookResult = ReturnType<typeof useFocusParticipantsOnFocusOptionQuery>;
export type FocusParticipantsOnFocusOptionLazyQueryHookResult = ReturnType<typeof useFocusParticipantsOnFocusOptionLazyQuery>;
export type FocusParticipantsOnFocusOptionSuspenseQueryHookResult = ReturnType<typeof useFocusParticipantsOnFocusOptionSuspenseQuery>;
export type FocusParticipantsOnFocusOptionQueryResult = Apollo.QueryResult<Types.FocusParticipantsOnFocusOptionQuery, Types.FocusParticipantsOnFocusOptionQueryVariables>;
export const PhoneVerificationSagaDocument = gql`
    query PhoneVerificationSaga {
  viewer {
    user {
      requiresPhoneVerification
    }
  }
}
    `;

/**
 * __usePhoneVerificationSagaQuery__
 *
 * To run a query within a React component, call `usePhoneVerificationSagaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneVerificationSagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneVerificationSagaQuery({
 *   variables: {
 *   },
 * });
 */
export function usePhoneVerificationSagaQuery(baseOptions?: Apollo.QueryHookOptions<Types.PhoneVerificationSagaQuery, Types.PhoneVerificationSagaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PhoneVerificationSagaQuery, Types.PhoneVerificationSagaQueryVariables>(PhoneVerificationSagaDocument, options);
      }
export function usePhoneVerificationSagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PhoneVerificationSagaQuery, Types.PhoneVerificationSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PhoneVerificationSagaQuery, Types.PhoneVerificationSagaQueryVariables>(PhoneVerificationSagaDocument, options);
        }
export function usePhoneVerificationSagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PhoneVerificationSagaQuery, Types.PhoneVerificationSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PhoneVerificationSagaQuery, Types.PhoneVerificationSagaQueryVariables>(PhoneVerificationSagaDocument, options);
        }
export type PhoneVerificationSagaQueryHookResult = ReturnType<typeof usePhoneVerificationSagaQuery>;
export type PhoneVerificationSagaLazyQueryHookResult = ReturnType<typeof usePhoneVerificationSagaLazyQuery>;
export type PhoneVerificationSagaSuspenseQueryHookResult = ReturnType<typeof usePhoneVerificationSagaSuspenseQuery>;
export type PhoneVerificationSagaQueryResult = Apollo.QueryResult<Types.PhoneVerificationSagaQuery, Types.PhoneVerificationSagaQueryVariables>;
export const PortfolioOrganizerSagaFetchSliceableSagaDocument = gql`
    query PortfolioOrganizerSagaFetchSliceableSaga($sliceableId: ID!) {
  node(id: $sliceableId) {
    ... on Sliceable {
      id
      name
      type
      __typename
      isActive
    }
    ... on Pie {
      description
      slices {
        percentage
        to {
          id
          isActive
          name
          type
          ... on Pie {
            description
            slices {
              percentage
              to {
                id
                isActive
                name
                type
                ... on Security {
                  symbol
                  profile {
                    logoUrl
                  }
                }
                ... on CryptoAsset {
                  legacyId
                }
              }
            }
          }
          ... on Security {
            symbol
            profile {
              logoUrl
            }
          }
          ... on CryptoAsset {
            legacyId
          }
        }
      }
    }
    ... on Security {
      symbol
      profile {
        logoUrl
      }
    }
    ... on CryptoAsset {
      legacyId
    }
  }
}
    `;

/**
 * __usePortfolioOrganizerSagaFetchSliceableSagaQuery__
 *
 * To run a query within a React component, call `usePortfolioOrganizerSagaFetchSliceableSagaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioOrganizerSagaFetchSliceableSagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioOrganizerSagaFetchSliceableSagaQuery({
 *   variables: {
 *      sliceableId: // value for 'sliceableId'
 *   },
 * });
 */
export function usePortfolioOrganizerSagaFetchSliceableSagaQuery(baseOptions: Apollo.QueryHookOptions<Types.PortfolioOrganizerSagaFetchSliceableSagaQuery, Types.PortfolioOrganizerSagaFetchSliceableSagaQueryVariables> & ({ variables: Types.PortfolioOrganizerSagaFetchSliceableSagaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PortfolioOrganizerSagaFetchSliceableSagaQuery, Types.PortfolioOrganizerSagaFetchSliceableSagaQueryVariables>(PortfolioOrganizerSagaFetchSliceableSagaDocument, options);
      }
export function usePortfolioOrganizerSagaFetchSliceableSagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PortfolioOrganizerSagaFetchSliceableSagaQuery, Types.PortfolioOrganizerSagaFetchSliceableSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PortfolioOrganizerSagaFetchSliceableSagaQuery, Types.PortfolioOrganizerSagaFetchSliceableSagaQueryVariables>(PortfolioOrganizerSagaFetchSliceableSagaDocument, options);
        }
export function usePortfolioOrganizerSagaFetchSliceableSagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PortfolioOrganizerSagaFetchSliceableSagaQuery, Types.PortfolioOrganizerSagaFetchSliceableSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PortfolioOrganizerSagaFetchSliceableSagaQuery, Types.PortfolioOrganizerSagaFetchSliceableSagaQueryVariables>(PortfolioOrganizerSagaFetchSliceableSagaDocument, options);
        }
export type PortfolioOrganizerSagaFetchSliceableSagaQueryHookResult = ReturnType<typeof usePortfolioOrganizerSagaFetchSliceableSagaQuery>;
export type PortfolioOrganizerSagaFetchSliceableSagaLazyQueryHookResult = ReturnType<typeof usePortfolioOrganizerSagaFetchSliceableSagaLazyQuery>;
export type PortfolioOrganizerSagaFetchSliceableSagaSuspenseQueryHookResult = ReturnType<typeof usePortfolioOrganizerSagaFetchSliceableSagaSuspenseQuery>;
export type PortfolioOrganizerSagaFetchSliceableSagaQueryResult = Apollo.QueryResult<Types.PortfolioOrganizerSagaFetchSliceableSagaQuery, Types.PortfolioOrganizerSagaFetchSliceableSagaQueryVariables>;
export const PortfolioOrganizerSagaFetchSliceablesDocument = gql`
    query PortfolioOrganizerSagaFetchSliceables($sliceableIds: [ID!]!) {
  nodes(ids: $sliceableIds) {
    ... on Sliceable {
      id
      name
      type
      __typename
      isActive
    }
    ... on Pie {
      description
      slices {
        percentage
        to {
          id
          isActive
          name
          ... on Security {
            profile {
              logoUrl
            }
          }
          type
        }
      }
    }
    ... on Security {
      profile {
        logoUrl
      }
    }
    ... on UnmanagedHolding {
      __typename
      security {
        id
        isActive
        name
        profile {
          logoUrl
        }
        symbol
        type
      }
    }
    ... on CryptoAsset {
      legacyId
    }
  }
}
    `;

/**
 * __usePortfolioOrganizerSagaFetchSliceablesQuery__
 *
 * To run a query within a React component, call `usePortfolioOrganizerSagaFetchSliceablesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioOrganizerSagaFetchSliceablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioOrganizerSagaFetchSliceablesQuery({
 *   variables: {
 *      sliceableIds: // value for 'sliceableIds'
 *   },
 * });
 */
export function usePortfolioOrganizerSagaFetchSliceablesQuery(baseOptions: Apollo.QueryHookOptions<Types.PortfolioOrganizerSagaFetchSliceablesQuery, Types.PortfolioOrganizerSagaFetchSliceablesQueryVariables> & ({ variables: Types.PortfolioOrganizerSagaFetchSliceablesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PortfolioOrganizerSagaFetchSliceablesQuery, Types.PortfolioOrganizerSagaFetchSliceablesQueryVariables>(PortfolioOrganizerSagaFetchSliceablesDocument, options);
      }
export function usePortfolioOrganizerSagaFetchSliceablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PortfolioOrganizerSagaFetchSliceablesQuery, Types.PortfolioOrganizerSagaFetchSliceablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PortfolioOrganizerSagaFetchSliceablesQuery, Types.PortfolioOrganizerSagaFetchSliceablesQueryVariables>(PortfolioOrganizerSagaFetchSliceablesDocument, options);
        }
export function usePortfolioOrganizerSagaFetchSliceablesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PortfolioOrganizerSagaFetchSliceablesQuery, Types.PortfolioOrganizerSagaFetchSliceablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PortfolioOrganizerSagaFetchSliceablesQuery, Types.PortfolioOrganizerSagaFetchSliceablesQueryVariables>(PortfolioOrganizerSagaFetchSliceablesDocument, options);
        }
export type PortfolioOrganizerSagaFetchSliceablesQueryHookResult = ReturnType<typeof usePortfolioOrganizerSagaFetchSliceablesQuery>;
export type PortfolioOrganizerSagaFetchSliceablesLazyQueryHookResult = ReturnType<typeof usePortfolioOrganizerSagaFetchSliceablesLazyQuery>;
export type PortfolioOrganizerSagaFetchSliceablesSuspenseQueryHookResult = ReturnType<typeof usePortfolioOrganizerSagaFetchSliceablesSuspenseQuery>;
export type PortfolioOrganizerSagaFetchSliceablesQueryResult = Apollo.QueryResult<Types.PortfolioOrganizerSagaFetchSliceablesQuery, Types.PortfolioOrganizerSagaFetchSliceablesQueryVariables>;
export const LoanAutoPaySagaDocument = gql`
    query LoanAutoPaySaga($personalLoanAccountId: ID!) {
  loan: node(id: $personalLoanAccountId) {
    ... on PersonalLoan {
      autoPayInstance {
        id
      }
    }
  }
}
    `;

/**
 * __useLoanAutoPaySagaQuery__
 *
 * To run a query within a React component, call `useLoanAutoPaySagaQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoanAutoPaySagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoanAutoPaySagaQuery({
 *   variables: {
 *      personalLoanAccountId: // value for 'personalLoanAccountId'
 *   },
 * });
 */
export function useLoanAutoPaySagaQuery(baseOptions: Apollo.QueryHookOptions<Types.LoanAutoPaySagaQuery, Types.LoanAutoPaySagaQueryVariables> & ({ variables: Types.LoanAutoPaySagaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LoanAutoPaySagaQuery, Types.LoanAutoPaySagaQueryVariables>(LoanAutoPaySagaDocument, options);
      }
export function useLoanAutoPaySagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LoanAutoPaySagaQuery, Types.LoanAutoPaySagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LoanAutoPaySagaQuery, Types.LoanAutoPaySagaQueryVariables>(LoanAutoPaySagaDocument, options);
        }
export function useLoanAutoPaySagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LoanAutoPaySagaQuery, Types.LoanAutoPaySagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LoanAutoPaySagaQuery, Types.LoanAutoPaySagaQueryVariables>(LoanAutoPaySagaDocument, options);
        }
export type LoanAutoPaySagaQueryHookResult = ReturnType<typeof useLoanAutoPaySagaQuery>;
export type LoanAutoPaySagaLazyQueryHookResult = ReturnType<typeof useLoanAutoPaySagaLazyQuery>;
export type LoanAutoPaySagaSuspenseQueryHookResult = ReturnType<typeof useLoanAutoPaySagaSuspenseQuery>;
export type LoanAutoPaySagaQueryResult = Apollo.QueryResult<Types.LoanAutoPaySagaQuery, Types.LoanAutoPaySagaQueryVariables>;
export const RemoteAccountIdDocument = gql`
    query RemoteAccountId {
  viewer {
    credit {
      activeAccount {
        id
      }
      autoPayInstance {
        id
      }
    }
  }
}
    `;

/**
 * __useRemoteAccountIdQuery__
 *
 * To run a query within a React component, call `useRemoteAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemoteAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemoteAccountIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useRemoteAccountIdQuery(baseOptions?: Apollo.QueryHookOptions<Types.RemoteAccountIdQuery, Types.RemoteAccountIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RemoteAccountIdQuery, Types.RemoteAccountIdQueryVariables>(RemoteAccountIdDocument, options);
      }
export function useRemoteAccountIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RemoteAccountIdQuery, Types.RemoteAccountIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RemoteAccountIdQuery, Types.RemoteAccountIdQueryVariables>(RemoteAccountIdDocument, options);
        }
export function useRemoteAccountIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RemoteAccountIdQuery, Types.RemoteAccountIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RemoteAccountIdQuery, Types.RemoteAccountIdQueryVariables>(RemoteAccountIdDocument, options);
        }
export type RemoteAccountIdQueryHookResult = ReturnType<typeof useRemoteAccountIdQuery>;
export type RemoteAccountIdLazyQueryHookResult = ReturnType<typeof useRemoteAccountIdLazyQuery>;
export type RemoteAccountIdSuspenseQueryHookResult = ReturnType<typeof useRemoteAccountIdSuspenseQuery>;
export type RemoteAccountIdQueryResult = Apollo.QueryResult<Types.RemoteAccountIdQuery, Types.RemoteAccountIdQueryVariables>;
export const PersonalLoansApplicationByIdSagaDocument = gql`
    query PersonalLoansApplicationByIdSaga($applicationId: ID!) {
  viewer {
    borrow {
      personalLoans {
        application(applicationId: $applicationId) {
          status {
            status
          }
          loan {
            id
            status
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePersonalLoansApplicationByIdSagaQuery__
 *
 * To run a query within a React component, call `usePersonalLoansApplicationByIdSagaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalLoansApplicationByIdSagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalLoansApplicationByIdSagaQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function usePersonalLoansApplicationByIdSagaQuery(baseOptions: Apollo.QueryHookOptions<Types.PersonalLoansApplicationByIdSagaQuery, Types.PersonalLoansApplicationByIdSagaQueryVariables> & ({ variables: Types.PersonalLoansApplicationByIdSagaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonalLoansApplicationByIdSagaQuery, Types.PersonalLoansApplicationByIdSagaQueryVariables>(PersonalLoansApplicationByIdSagaDocument, options);
      }
export function usePersonalLoansApplicationByIdSagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonalLoansApplicationByIdSagaQuery, Types.PersonalLoansApplicationByIdSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonalLoansApplicationByIdSagaQuery, Types.PersonalLoansApplicationByIdSagaQueryVariables>(PersonalLoansApplicationByIdSagaDocument, options);
        }
export function usePersonalLoansApplicationByIdSagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PersonalLoansApplicationByIdSagaQuery, Types.PersonalLoansApplicationByIdSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonalLoansApplicationByIdSagaQuery, Types.PersonalLoansApplicationByIdSagaQueryVariables>(PersonalLoansApplicationByIdSagaDocument, options);
        }
export type PersonalLoansApplicationByIdSagaQueryHookResult = ReturnType<typeof usePersonalLoansApplicationByIdSagaQuery>;
export type PersonalLoansApplicationByIdSagaLazyQueryHookResult = ReturnType<typeof usePersonalLoansApplicationByIdSagaLazyQuery>;
export type PersonalLoansApplicationByIdSagaSuspenseQueryHookResult = ReturnType<typeof usePersonalLoansApplicationByIdSagaSuspenseQuery>;
export type PersonalLoansApplicationByIdSagaQueryResult = Apollo.QueryResult<Types.PersonalLoansApplicationByIdSagaQuery, Types.PersonalLoansApplicationByIdSagaQueryVariables>;
export const PersonalLoansApplicationSagaDocument = gql`
    query PersonalLoansApplicationSaga($savedLoanIdFromApplication: ID) {
  viewer {
    borrow {
      isEligibleToApplyForPersonalLoan
      hasFailedPersonalLoanDisbursement
      hasActivePersonalLoan
      personalLoans {
        disbursementFlow(loanId: $savedLoanIdFromApplication) {
          hasDisbursementOption
        }
        loans {
          edges {
            node {
              status
            }
          }
        }
        activeApplication {
          id
          status {
            status
          }
          loan {
            id
            status
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePersonalLoansApplicationSagaQuery__
 *
 * To run a query within a React component, call `usePersonalLoansApplicationSagaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalLoansApplicationSagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalLoansApplicationSagaQuery({
 *   variables: {
 *      savedLoanIdFromApplication: // value for 'savedLoanIdFromApplication'
 *   },
 * });
 */
export function usePersonalLoansApplicationSagaQuery(baseOptions?: Apollo.QueryHookOptions<Types.PersonalLoansApplicationSagaQuery, Types.PersonalLoansApplicationSagaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PersonalLoansApplicationSagaQuery, Types.PersonalLoansApplicationSagaQueryVariables>(PersonalLoansApplicationSagaDocument, options);
      }
export function usePersonalLoansApplicationSagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PersonalLoansApplicationSagaQuery, Types.PersonalLoansApplicationSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PersonalLoansApplicationSagaQuery, Types.PersonalLoansApplicationSagaQueryVariables>(PersonalLoansApplicationSagaDocument, options);
        }
export function usePersonalLoansApplicationSagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PersonalLoansApplicationSagaQuery, Types.PersonalLoansApplicationSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PersonalLoansApplicationSagaQuery, Types.PersonalLoansApplicationSagaQueryVariables>(PersonalLoansApplicationSagaDocument, options);
        }
export type PersonalLoansApplicationSagaQueryHookResult = ReturnType<typeof usePersonalLoansApplicationSagaQuery>;
export type PersonalLoansApplicationSagaLazyQueryHookResult = ReturnType<typeof usePersonalLoansApplicationSagaLazyQuery>;
export type PersonalLoansApplicationSagaSuspenseQueryHookResult = ReturnType<typeof usePersonalLoansApplicationSagaSuspenseQuery>;
export type PersonalLoansApplicationSagaQueryResult = Apollo.QueryResult<Types.PersonalLoansApplicationSagaQuery, Types.PersonalLoansApplicationSagaQueryVariables>;
export const PortfolioEditorSagaFetchSliceableDocument = gql`
    query PortfolioEditorSagaFetchSliceable($accountId: ID!, $sliceableId: ID!) {
  node(id: $sliceableId) {
    ... on Sliceable {
      id
      name
      type
      __typename
      isActive
    }
    ... on Pie {
      description
      slices {
        percentage
        to {
          id
          isActive
          name
          type
          portfolioLinks(onlyAccountId: $accountId) {
            id
            to {
              id
              name
            }
            ... on ChildPortfolioSlice {
              ancestors {
                id
                to {
                  id
                  name
                }
              }
            }
          }
          ... on Security {
            symbol
            profile {
              logoUrl
            }
          }
          ... on Pie {
            description
            slices {
              percentage
              to {
                id
                isActive
                name
                type
                ... on Security {
                  symbol
                  profile {
                    logoUrl
                  }
                }
              }
            }
          }
        }
      }
    }
    ... on Security {
      symbol
      profile {
        logoUrl
      }
      portfolioLinks(onlyAccountId: $accountId) {
        id
        to {
          id
          name
        }
        ... on ChildPortfolioSlice {
          ancestors {
            id
            to {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePortfolioEditorSagaFetchSliceableQuery__
 *
 * To run a query within a React component, call `usePortfolioEditorSagaFetchSliceableQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioEditorSagaFetchSliceableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioEditorSagaFetchSliceableQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      sliceableId: // value for 'sliceableId'
 *   },
 * });
 */
export function usePortfolioEditorSagaFetchSliceableQuery(baseOptions: Apollo.QueryHookOptions<Types.PortfolioEditorSagaFetchSliceableQuery, Types.PortfolioEditorSagaFetchSliceableQueryVariables> & ({ variables: Types.PortfolioEditorSagaFetchSliceableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PortfolioEditorSagaFetchSliceableQuery, Types.PortfolioEditorSagaFetchSliceableQueryVariables>(PortfolioEditorSagaFetchSliceableDocument, options);
      }
export function usePortfolioEditorSagaFetchSliceableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PortfolioEditorSagaFetchSliceableQuery, Types.PortfolioEditorSagaFetchSliceableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PortfolioEditorSagaFetchSliceableQuery, Types.PortfolioEditorSagaFetchSliceableQueryVariables>(PortfolioEditorSagaFetchSliceableDocument, options);
        }
export function usePortfolioEditorSagaFetchSliceableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PortfolioEditorSagaFetchSliceableQuery, Types.PortfolioEditorSagaFetchSliceableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PortfolioEditorSagaFetchSliceableQuery, Types.PortfolioEditorSagaFetchSliceableQueryVariables>(PortfolioEditorSagaFetchSliceableDocument, options);
        }
export type PortfolioEditorSagaFetchSliceableQueryHookResult = ReturnType<typeof usePortfolioEditorSagaFetchSliceableQuery>;
export type PortfolioEditorSagaFetchSliceableLazyQueryHookResult = ReturnType<typeof usePortfolioEditorSagaFetchSliceableLazyQuery>;
export type PortfolioEditorSagaFetchSliceableSuspenseQueryHookResult = ReturnType<typeof usePortfolioEditorSagaFetchSliceableSuspenseQuery>;
export type PortfolioEditorSagaFetchSliceableQueryResult = Apollo.QueryResult<Types.PortfolioEditorSagaFetchSliceableQuery, Types.PortfolioEditorSagaFetchSliceableQueryVariables>;
export const PortfolioEditorSagaFetchSliceablesDocument = gql`
    query PortfolioEditorSagaFetchSliceables($accountId: ID!, $sliceableIds: [ID!]!) {
  nodes(ids: $sliceableIds) {
    ... on Sliceable {
      id
      name
      type
      __typename
      isActive
      portfolioLinks(onlyAccountId: $accountId) {
        id
        to {
          id
          name
        }
        ... on ChildPortfolioSlice {
          ancestors {
            id
            to {
              id
              name
            }
          }
        }
      }
    }
    ... on Pie {
      description
      slices {
        percentage
        to {
          id
          isActive
          name
          type
          portfolioLinks(onlyAccountId: $accountId) {
            id
            to {
              id
              name
            }
            ... on ChildPortfolioSlice {
              ancestors {
                id
                to {
                  id
                  name
                }
              }
            }
          }
          ... on Security {
            profile {
              logoUrl
            }
          }
        }
      }
    }
    ... on Security {
      profile {
        logoUrl
      }
    }
  }
}
    `;

/**
 * __usePortfolioEditorSagaFetchSliceablesQuery__
 *
 * To run a query within a React component, call `usePortfolioEditorSagaFetchSliceablesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioEditorSagaFetchSliceablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioEditorSagaFetchSliceablesQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      sliceableIds: // value for 'sliceableIds'
 *   },
 * });
 */
export function usePortfolioEditorSagaFetchSliceablesQuery(baseOptions: Apollo.QueryHookOptions<Types.PortfolioEditorSagaFetchSliceablesQuery, Types.PortfolioEditorSagaFetchSliceablesQueryVariables> & ({ variables: Types.PortfolioEditorSagaFetchSliceablesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PortfolioEditorSagaFetchSliceablesQuery, Types.PortfolioEditorSagaFetchSliceablesQueryVariables>(PortfolioEditorSagaFetchSliceablesDocument, options);
      }
export function usePortfolioEditorSagaFetchSliceablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PortfolioEditorSagaFetchSliceablesQuery, Types.PortfolioEditorSagaFetchSliceablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PortfolioEditorSagaFetchSliceablesQuery, Types.PortfolioEditorSagaFetchSliceablesQueryVariables>(PortfolioEditorSagaFetchSliceablesDocument, options);
        }
export function usePortfolioEditorSagaFetchSliceablesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PortfolioEditorSagaFetchSliceablesQuery, Types.PortfolioEditorSagaFetchSliceablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PortfolioEditorSagaFetchSliceablesQuery, Types.PortfolioEditorSagaFetchSliceablesQueryVariables>(PortfolioEditorSagaFetchSliceablesDocument, options);
        }
export type PortfolioEditorSagaFetchSliceablesQueryHookResult = ReturnType<typeof usePortfolioEditorSagaFetchSliceablesQuery>;
export type PortfolioEditorSagaFetchSliceablesLazyQueryHookResult = ReturnType<typeof usePortfolioEditorSagaFetchSliceablesLazyQuery>;
export type PortfolioEditorSagaFetchSliceablesSuspenseQueryHookResult = ReturnType<typeof usePortfolioEditorSagaFetchSliceablesSuspenseQuery>;
export type PortfolioEditorSagaFetchSliceablesQueryResult = Apollo.QueryResult<Types.PortfolioEditorSagaFetchSliceablesQuery, Types.PortfolioEditorSagaFetchSliceablesQueryVariables>;
export const IsEligibleForFundingSourceUpdateDocument = gql`
    query IsEligibleForFundingSourceUpdate {
  viewer {
    transfers {
      isEligibleForFundingSourceUpdate
    }
  }
}
    `;

/**
 * __useIsEligibleForFundingSourceUpdateQuery__
 *
 * To run a query within a React component, call `useIsEligibleForFundingSourceUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEligibleForFundingSourceUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEligibleForFundingSourceUpdateQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsEligibleForFundingSourceUpdateQuery(baseOptions?: Apollo.QueryHookOptions<Types.IsEligibleForFundingSourceUpdateQuery, Types.IsEligibleForFundingSourceUpdateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IsEligibleForFundingSourceUpdateQuery, Types.IsEligibleForFundingSourceUpdateQueryVariables>(IsEligibleForFundingSourceUpdateDocument, options);
      }
export function useIsEligibleForFundingSourceUpdateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IsEligibleForFundingSourceUpdateQuery, Types.IsEligibleForFundingSourceUpdateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IsEligibleForFundingSourceUpdateQuery, Types.IsEligibleForFundingSourceUpdateQueryVariables>(IsEligibleForFundingSourceUpdateDocument, options);
        }
export function useIsEligibleForFundingSourceUpdateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.IsEligibleForFundingSourceUpdateQuery, Types.IsEligibleForFundingSourceUpdateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.IsEligibleForFundingSourceUpdateQuery, Types.IsEligibleForFundingSourceUpdateQueryVariables>(IsEligibleForFundingSourceUpdateDocument, options);
        }
export type IsEligibleForFundingSourceUpdateQueryHookResult = ReturnType<typeof useIsEligibleForFundingSourceUpdateQuery>;
export type IsEligibleForFundingSourceUpdateLazyQueryHookResult = ReturnType<typeof useIsEligibleForFundingSourceUpdateLazyQuery>;
export type IsEligibleForFundingSourceUpdateSuspenseQueryHookResult = ReturnType<typeof useIsEligibleForFundingSourceUpdateSuspenseQuery>;
export type IsEligibleForFundingSourceUpdateQueryResult = Apollo.QueryResult<Types.IsEligibleForFundingSourceUpdateQuery, Types.IsEligibleForFundingSourceUpdateQueryVariables>;
export const GetSecondaryProfileSagaDocument = gql`
    query GetSecondaryProfileSaga {
  viewer {
    profile {
      secondary {
        dateOfBirth
      }
    }
  }
}
    `;

/**
 * __useGetSecondaryProfileSagaQuery__
 *
 * To run a query within a React component, call `useGetSecondaryProfileSagaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecondaryProfileSagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecondaryProfileSagaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSecondaryProfileSagaQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetSecondaryProfileSagaQuery, Types.GetSecondaryProfileSagaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSecondaryProfileSagaQuery, Types.GetSecondaryProfileSagaQueryVariables>(GetSecondaryProfileSagaDocument, options);
      }
export function useGetSecondaryProfileSagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSecondaryProfileSagaQuery, Types.GetSecondaryProfileSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSecondaryProfileSagaQuery, Types.GetSecondaryProfileSagaQueryVariables>(GetSecondaryProfileSagaDocument, options);
        }
export function useGetSecondaryProfileSagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetSecondaryProfileSagaQuery, Types.GetSecondaryProfileSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetSecondaryProfileSagaQuery, Types.GetSecondaryProfileSagaQueryVariables>(GetSecondaryProfileSagaDocument, options);
        }
export type GetSecondaryProfileSagaQueryHookResult = ReturnType<typeof useGetSecondaryProfileSagaQuery>;
export type GetSecondaryProfileSagaLazyQueryHookResult = ReturnType<typeof useGetSecondaryProfileSagaLazyQuery>;
export type GetSecondaryProfileSagaSuspenseQueryHookResult = ReturnType<typeof useGetSecondaryProfileSagaSuspenseQuery>;
export type GetSecondaryProfileSagaQueryResult = Apollo.QueryResult<Types.GetSecondaryProfileSagaQuery, Types.GetSecondaryProfileSagaQueryVariables>;
export const SlicesQuerySagaDocument = gql`
    query SlicesQuerySaga($sliceableIds: [ID!]!) {
  nodes(ids: $sliceableIds) {
    __typename
    id
  }
}
    `;

/**
 * __useSlicesQuerySagaQuery__
 *
 * To run a query within a React component, call `useSlicesQuerySagaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlicesQuerySagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlicesQuerySagaQuery({
 *   variables: {
 *      sliceableIds: // value for 'sliceableIds'
 *   },
 * });
 */
export function useSlicesQuerySagaQuery(baseOptions: Apollo.QueryHookOptions<Types.SlicesQuerySagaQuery, Types.SlicesQuerySagaQueryVariables> & ({ variables: Types.SlicesQuerySagaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SlicesQuerySagaQuery, Types.SlicesQuerySagaQueryVariables>(SlicesQuerySagaDocument, options);
      }
export function useSlicesQuerySagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SlicesQuerySagaQuery, Types.SlicesQuerySagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SlicesQuerySagaQuery, Types.SlicesQuerySagaQueryVariables>(SlicesQuerySagaDocument, options);
        }
export function useSlicesQuerySagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SlicesQuerySagaQuery, Types.SlicesQuerySagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SlicesQuerySagaQuery, Types.SlicesQuerySagaQueryVariables>(SlicesQuerySagaDocument, options);
        }
export type SlicesQuerySagaQueryHookResult = ReturnType<typeof useSlicesQuerySagaQuery>;
export type SlicesQuerySagaLazyQueryHookResult = ReturnType<typeof useSlicesQuerySagaLazyQuery>;
export type SlicesQuerySagaSuspenseQueryHookResult = ReturnType<typeof useSlicesQuerySagaSuspenseQuery>;
export type SlicesQuerySagaQueryResult = Apollo.QueryResult<Types.SlicesQuerySagaQuery, Types.SlicesQuerySagaQueryVariables>;
export const ViewerAccountsRootSlicesSagaDocument = gql`
    query ViewerAccountsRootSlicesSaga {
  viewer {
    accounts(first: 20) {
      edges {
        node {
          ...ViewerAccountRootSliceNode
        }
      }
    }
  }
}
    ${ViewerAccountRootSliceNodeFragmentDoc}`;

/**
 * __useViewerAccountsRootSlicesSagaQuery__
 *
 * To run a query within a React component, call `useViewerAccountsRootSlicesSagaQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerAccountsRootSlicesSagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerAccountsRootSlicesSagaQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerAccountsRootSlicesSagaQuery(baseOptions?: Apollo.QueryHookOptions<Types.ViewerAccountsRootSlicesSagaQuery, Types.ViewerAccountsRootSlicesSagaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ViewerAccountsRootSlicesSagaQuery, Types.ViewerAccountsRootSlicesSagaQueryVariables>(ViewerAccountsRootSlicesSagaDocument, options);
      }
export function useViewerAccountsRootSlicesSagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ViewerAccountsRootSlicesSagaQuery, Types.ViewerAccountsRootSlicesSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ViewerAccountsRootSlicesSagaQuery, Types.ViewerAccountsRootSlicesSagaQueryVariables>(ViewerAccountsRootSlicesSagaDocument, options);
        }
export function useViewerAccountsRootSlicesSagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ViewerAccountsRootSlicesSagaQuery, Types.ViewerAccountsRootSlicesSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ViewerAccountsRootSlicesSagaQuery, Types.ViewerAccountsRootSlicesSagaQueryVariables>(ViewerAccountsRootSlicesSagaDocument, options);
        }
export type ViewerAccountsRootSlicesSagaQueryHookResult = ReturnType<typeof useViewerAccountsRootSlicesSagaQuery>;
export type ViewerAccountsRootSlicesSagaLazyQueryHookResult = ReturnType<typeof useViewerAccountsRootSlicesSagaLazyQuery>;
export type ViewerAccountsRootSlicesSagaSuspenseQueryHookResult = ReturnType<typeof useViewerAccountsRootSlicesSagaSuspenseQuery>;
export type ViewerAccountsRootSlicesSagaQueryResult = Apollo.QueryResult<Types.ViewerAccountsRootSlicesSagaQuery, Types.ViewerAccountsRootSlicesSagaQueryVariables>;
export const SliceableSagaDocument = gql`
    query SliceableSaga($sliceableId: ID!) {
  node(id: $sliceableId) {
    id
    ... on Sliceable {
      name
      type
    }
    ... on UserPie {
      containsCrypto
    }
  }
}
    `;

/**
 * __useSliceableSagaQuery__
 *
 * To run a query within a React component, call `useSliceableSagaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSliceableSagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSliceableSagaQuery({
 *   variables: {
 *      sliceableId: // value for 'sliceableId'
 *   },
 * });
 */
export function useSliceableSagaQuery(baseOptions: Apollo.QueryHookOptions<Types.SliceableSagaQuery, Types.SliceableSagaQueryVariables> & ({ variables: Types.SliceableSagaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SliceableSagaQuery, Types.SliceableSagaQueryVariables>(SliceableSagaDocument, options);
      }
export function useSliceableSagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SliceableSagaQuery, Types.SliceableSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SliceableSagaQuery, Types.SliceableSagaQueryVariables>(SliceableSagaDocument, options);
        }
export function useSliceableSagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SliceableSagaQuery, Types.SliceableSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SliceableSagaQuery, Types.SliceableSagaQueryVariables>(SliceableSagaDocument, options);
        }
export type SliceableSagaQueryHookResult = ReturnType<typeof useSliceableSagaQuery>;
export type SliceableSagaLazyQueryHookResult = ReturnType<typeof useSliceableSagaLazyQuery>;
export type SliceableSagaSuspenseQueryHookResult = ReturnType<typeof useSliceableSagaSuspenseQuery>;
export type SliceableSagaQueryResult = Apollo.QueryResult<Types.SliceableSagaQuery, Types.SliceableSagaQueryVariables>;
export const ClickedOnPortfolioSliceDocument = gql`
    query ClickedOnPortfolioSlice($portfolioSliceId: ID!) {
  node(id: $portfolioSliceId) {
    ... on PortfolioSlice {
      account {
        id
        registration
      }
      to {
        ... on Sliceable {
          __typename
          id
          ... on Security {
            isActive
          }
        }
      }
    }
  }
}
    `;

/**
 * __useClickedOnPortfolioSliceQuery__
 *
 * To run a query within a React component, call `useClickedOnPortfolioSliceQuery` and pass it any options that fit your needs.
 * When your component renders, `useClickedOnPortfolioSliceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClickedOnPortfolioSliceQuery({
 *   variables: {
 *      portfolioSliceId: // value for 'portfolioSliceId'
 *   },
 * });
 */
export function useClickedOnPortfolioSliceQuery(baseOptions: Apollo.QueryHookOptions<Types.ClickedOnPortfolioSliceQuery, Types.ClickedOnPortfolioSliceQueryVariables> & ({ variables: Types.ClickedOnPortfolioSliceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ClickedOnPortfolioSliceQuery, Types.ClickedOnPortfolioSliceQueryVariables>(ClickedOnPortfolioSliceDocument, options);
      }
export function useClickedOnPortfolioSliceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ClickedOnPortfolioSliceQuery, Types.ClickedOnPortfolioSliceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ClickedOnPortfolioSliceQuery, Types.ClickedOnPortfolioSliceQueryVariables>(ClickedOnPortfolioSliceDocument, options);
        }
export function useClickedOnPortfolioSliceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ClickedOnPortfolioSliceQuery, Types.ClickedOnPortfolioSliceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ClickedOnPortfolioSliceQuery, Types.ClickedOnPortfolioSliceQueryVariables>(ClickedOnPortfolioSliceDocument, options);
        }
export type ClickedOnPortfolioSliceQueryHookResult = ReturnType<typeof useClickedOnPortfolioSliceQuery>;
export type ClickedOnPortfolioSliceLazyQueryHookResult = ReturnType<typeof useClickedOnPortfolioSliceLazyQuery>;
export type ClickedOnPortfolioSliceSuspenseQueryHookResult = ReturnType<typeof useClickedOnPortfolioSliceSuspenseQuery>;
export type ClickedOnPortfolioSliceQueryResult = Apollo.QueryResult<Types.ClickedOnPortfolioSliceQuery, Types.ClickedOnPortfolioSliceQueryVariables>;
export const ClickedOnSecuritySagaDocument = gql`
    query ClickedOnSecuritySaga($securityId: ID!) {
  node(id: $securityId) {
    __typename
    ... on Security {
      isActive
    }
  }
}
    `;

/**
 * __useClickedOnSecuritySagaQuery__
 *
 * To run a query within a React component, call `useClickedOnSecuritySagaQuery` and pass it any options that fit your needs.
 * When your component renders, `useClickedOnSecuritySagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClickedOnSecuritySagaQuery({
 *   variables: {
 *      securityId: // value for 'securityId'
 *   },
 * });
 */
export function useClickedOnSecuritySagaQuery(baseOptions: Apollo.QueryHookOptions<Types.ClickedOnSecuritySagaQuery, Types.ClickedOnSecuritySagaQueryVariables> & ({ variables: Types.ClickedOnSecuritySagaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ClickedOnSecuritySagaQuery, Types.ClickedOnSecuritySagaQueryVariables>(ClickedOnSecuritySagaDocument, options);
      }
export function useClickedOnSecuritySagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ClickedOnSecuritySagaQuery, Types.ClickedOnSecuritySagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ClickedOnSecuritySagaQuery, Types.ClickedOnSecuritySagaQueryVariables>(ClickedOnSecuritySagaDocument, options);
        }
export function useClickedOnSecuritySagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ClickedOnSecuritySagaQuery, Types.ClickedOnSecuritySagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ClickedOnSecuritySagaQuery, Types.ClickedOnSecuritySagaQueryVariables>(ClickedOnSecuritySagaDocument, options);
        }
export type ClickedOnSecuritySagaQueryHookResult = ReturnType<typeof useClickedOnSecuritySagaQuery>;
export type ClickedOnSecuritySagaLazyQueryHookResult = ReturnType<typeof useClickedOnSecuritySagaLazyQuery>;
export type ClickedOnSecuritySagaSuspenseQueryHookResult = ReturnType<typeof useClickedOnSecuritySagaSuspenseQuery>;
export type ClickedOnSecuritySagaQueryResult = Apollo.QueryResult<Types.ClickedOnSecuritySagaQuery, Types.ClickedOnSecuritySagaQueryVariables>;
export const SliceableTypenameSagaDocument = gql`
    query SliceableTypenameSaga($sliceableId: ID!) {
  node(id: $sliceableId) {
    __typename
  }
}
    `;

/**
 * __useSliceableTypenameSagaQuery__
 *
 * To run a query within a React component, call `useSliceableTypenameSagaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSliceableTypenameSagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSliceableTypenameSagaQuery({
 *   variables: {
 *      sliceableId: // value for 'sliceableId'
 *   },
 * });
 */
export function useSliceableTypenameSagaQuery(baseOptions: Apollo.QueryHookOptions<Types.SliceableTypenameSagaQuery, Types.SliceableTypenameSagaQueryVariables> & ({ variables: Types.SliceableTypenameSagaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SliceableTypenameSagaQuery, Types.SliceableTypenameSagaQueryVariables>(SliceableTypenameSagaDocument, options);
      }
export function useSliceableTypenameSagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SliceableTypenameSagaQuery, Types.SliceableTypenameSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SliceableTypenameSagaQuery, Types.SliceableTypenameSagaQueryVariables>(SliceableTypenameSagaDocument, options);
        }
export function useSliceableTypenameSagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SliceableTypenameSagaQuery, Types.SliceableTypenameSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SliceableTypenameSagaQuery, Types.SliceableTypenameSagaQueryVariables>(SliceableTypenameSagaDocument, options);
        }
export type SliceableTypenameSagaQueryHookResult = ReturnType<typeof useSliceableTypenameSagaQuery>;
export type SliceableTypenameSagaLazyQueryHookResult = ReturnType<typeof useSliceableTypenameSagaLazyQuery>;
export type SliceableTypenameSagaSuspenseQueryHookResult = ReturnType<typeof useSliceableTypenameSagaSuspenseQuery>;
export type SliceableTypenameSagaQueryResult = Apollo.QueryResult<Types.SliceableTypenameSagaQuery, Types.SliceableTypenameSagaQueryVariables>;
export const CreateTransferLogSagaDocument = gql`
    query CreateTransferLogSaga($nodeId: ID!) {
  node(id: $nodeId) {
    __typename
    ... on TransferInstance {
      isLiquidation
      from {
        transferParticipantType
      }
      to {
        transferParticipantType
      }
    }
    ... on ScheduledTransferRule {
      from {
        transferParticipantType
      }
      to {
        transferParticipantType
      }
    }
  }
}
    `;

/**
 * __useCreateTransferLogSagaQuery__
 *
 * To run a query within a React component, call `useCreateTransferLogSagaQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateTransferLogSagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateTransferLogSagaQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useCreateTransferLogSagaQuery(baseOptions: Apollo.QueryHookOptions<Types.CreateTransferLogSagaQuery, Types.CreateTransferLogSagaQueryVariables> & ({ variables: Types.CreateTransferLogSagaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CreateTransferLogSagaQuery, Types.CreateTransferLogSagaQueryVariables>(CreateTransferLogSagaDocument, options);
      }
export function useCreateTransferLogSagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CreateTransferLogSagaQuery, Types.CreateTransferLogSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CreateTransferLogSagaQuery, Types.CreateTransferLogSagaQueryVariables>(CreateTransferLogSagaDocument, options);
        }
export function useCreateTransferLogSagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CreateTransferLogSagaQuery, Types.CreateTransferLogSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CreateTransferLogSagaQuery, Types.CreateTransferLogSagaQueryVariables>(CreateTransferLogSagaDocument, options);
        }
export type CreateTransferLogSagaQueryHookResult = ReturnType<typeof useCreateTransferLogSagaQuery>;
export type CreateTransferLogSagaLazyQueryHookResult = ReturnType<typeof useCreateTransferLogSagaLazyQuery>;
export type CreateTransferLogSagaSuspenseQueryHookResult = ReturnType<typeof useCreateTransferLogSagaSuspenseQuery>;
export type CreateTransferLogSagaQueryResult = Apollo.QueryResult<Types.CreateTransferLogSagaQuery, Types.CreateTransferLogSagaQueryVariables>;
export const HasAutoInvestTurnedOnSagaDocument = gql`
    query HasAutoInvestTurnedOnSaga($borrowAccountId: ID!) {
  node(id: $borrowAccountId) {
    ... on BorrowAccount {
      investAccount {
        id
        maxCashThreshold
      }
    }
  }
}
    `;

/**
 * __useHasAutoInvestTurnedOnSagaQuery__
 *
 * To run a query within a React component, call `useHasAutoInvestTurnedOnSagaQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasAutoInvestTurnedOnSagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasAutoInvestTurnedOnSagaQuery({
 *   variables: {
 *      borrowAccountId: // value for 'borrowAccountId'
 *   },
 * });
 */
export function useHasAutoInvestTurnedOnSagaQuery(baseOptions: Apollo.QueryHookOptions<Types.HasAutoInvestTurnedOnSagaQuery, Types.HasAutoInvestTurnedOnSagaQueryVariables> & ({ variables: Types.HasAutoInvestTurnedOnSagaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HasAutoInvestTurnedOnSagaQuery, Types.HasAutoInvestTurnedOnSagaQueryVariables>(HasAutoInvestTurnedOnSagaDocument, options);
      }
export function useHasAutoInvestTurnedOnSagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HasAutoInvestTurnedOnSagaQuery, Types.HasAutoInvestTurnedOnSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HasAutoInvestTurnedOnSagaQuery, Types.HasAutoInvestTurnedOnSagaQueryVariables>(HasAutoInvestTurnedOnSagaDocument, options);
        }
export function useHasAutoInvestTurnedOnSagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.HasAutoInvestTurnedOnSagaQuery, Types.HasAutoInvestTurnedOnSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.HasAutoInvestTurnedOnSagaQuery, Types.HasAutoInvestTurnedOnSagaQueryVariables>(HasAutoInvestTurnedOnSagaDocument, options);
        }
export type HasAutoInvestTurnedOnSagaQueryHookResult = ReturnType<typeof useHasAutoInvestTurnedOnSagaQuery>;
export type HasAutoInvestTurnedOnSagaLazyQueryHookResult = ReturnType<typeof useHasAutoInvestTurnedOnSagaLazyQuery>;
export type HasAutoInvestTurnedOnSagaSuspenseQueryHookResult = ReturnType<typeof useHasAutoInvestTurnedOnSagaSuspenseQuery>;
export type HasAutoInvestTurnedOnSagaQueryResult = Apollo.QueryResult<Types.HasAutoInvestTurnedOnSagaQuery, Types.HasAutoInvestTurnedOnSagaQueryVariables>;
export const AccountHasValueSagaDocument = gql`
    query AccountHasValueSaga($accountId: ID!) {
  node(id: $accountId) {
    ... on Account {
      balance {
        totalValue {
          hasValue
        }
      }
    }
  }
}
    `;

/**
 * __useAccountHasValueSagaQuery__
 *
 * To run a query within a React component, call `useAccountHasValueSagaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountHasValueSagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountHasValueSagaQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountHasValueSagaQuery(baseOptions: Apollo.QueryHookOptions<Types.AccountHasValueSagaQuery, Types.AccountHasValueSagaQueryVariables> & ({ variables: Types.AccountHasValueSagaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AccountHasValueSagaQuery, Types.AccountHasValueSagaQueryVariables>(AccountHasValueSagaDocument, options);
      }
export function useAccountHasValueSagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AccountHasValueSagaQuery, Types.AccountHasValueSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AccountHasValueSagaQuery, Types.AccountHasValueSagaQueryVariables>(AccountHasValueSagaDocument, options);
        }
export function useAccountHasValueSagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AccountHasValueSagaQuery, Types.AccountHasValueSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AccountHasValueSagaQuery, Types.AccountHasValueSagaQueryVariables>(AccountHasValueSagaDocument, options);
        }
export type AccountHasValueSagaQueryHookResult = ReturnType<typeof useAccountHasValueSagaQuery>;
export type AccountHasValueSagaLazyQueryHookResult = ReturnType<typeof useAccountHasValueSagaLazyQuery>;
export type AccountHasValueSagaSuspenseQueryHookResult = ReturnType<typeof useAccountHasValueSagaSuspenseQuery>;
export type AccountHasValueSagaQueryResult = Apollo.QueryResult<Types.AccountHasValueSagaQuery, Types.AccountHasValueSagaQueryVariables>;
export const LastAchRelationShipSagaDocument = gql`
    query LastAchRelationShipSaga($accountId: ID!) {
  node(id: $accountId) {
    ... on Account {
      lastAchRelationship {
        __typename
        id
      }
    }
  }
}
    `;

/**
 * __useLastAchRelationShipSagaQuery__
 *
 * To run a query within a React component, call `useLastAchRelationShipSagaQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastAchRelationShipSagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastAchRelationShipSagaQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useLastAchRelationShipSagaQuery(baseOptions: Apollo.QueryHookOptions<Types.LastAchRelationShipSagaQuery, Types.LastAchRelationShipSagaQueryVariables> & ({ variables: Types.LastAchRelationShipSagaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LastAchRelationShipSagaQuery, Types.LastAchRelationShipSagaQueryVariables>(LastAchRelationShipSagaDocument, options);
      }
export function useLastAchRelationShipSagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LastAchRelationShipSagaQuery, Types.LastAchRelationShipSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LastAchRelationShipSagaQuery, Types.LastAchRelationShipSagaQueryVariables>(LastAchRelationShipSagaDocument, options);
        }
export function useLastAchRelationShipSagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LastAchRelationShipSagaQuery, Types.LastAchRelationShipSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LastAchRelationShipSagaQuery, Types.LastAchRelationShipSagaQueryVariables>(LastAchRelationShipSagaDocument, options);
        }
export type LastAchRelationShipSagaQueryHookResult = ReturnType<typeof useLastAchRelationShipSagaQuery>;
export type LastAchRelationShipSagaLazyQueryHookResult = ReturnType<typeof useLastAchRelationShipSagaLazyQuery>;
export type LastAchRelationShipSagaSuspenseQueryHookResult = ReturnType<typeof useLastAchRelationShipSagaSuspenseQuery>;
export type LastAchRelationShipSagaQueryResult = Apollo.QueryResult<Types.LastAchRelationShipSagaQuery, Types.LastAchRelationShipSagaQueryVariables>;
export const SearchSliceablesSagaDocument = gql`
    query SearchSliceablesSaga($query: String!) {
  viewer {
    searchSliceables(first: 10, query: $query) {
      edges {
        node {
          id
        }
        highlights {
          match
          snippet
        }
      }
    }
  }
}
    `;

/**
 * __useSearchSliceablesSagaQuery__
 *
 * To run a query within a React component, call `useSearchSliceablesSagaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSliceablesSagaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSliceablesSagaQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchSliceablesSagaQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchSliceablesSagaQuery, Types.SearchSliceablesSagaQueryVariables> & ({ variables: Types.SearchSliceablesSagaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchSliceablesSagaQuery, Types.SearchSliceablesSagaQueryVariables>(SearchSliceablesSagaDocument, options);
      }
export function useSearchSliceablesSagaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchSliceablesSagaQuery, Types.SearchSliceablesSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchSliceablesSagaQuery, Types.SearchSliceablesSagaQueryVariables>(SearchSliceablesSagaDocument, options);
        }
export function useSearchSliceablesSagaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SearchSliceablesSagaQuery, Types.SearchSliceablesSagaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SearchSliceablesSagaQuery, Types.SearchSliceablesSagaQueryVariables>(SearchSliceablesSagaDocument, options);
        }
export type SearchSliceablesSagaQueryHookResult = ReturnType<typeof useSearchSliceablesSagaQuery>;
export type SearchSliceablesSagaLazyQueryHookResult = ReturnType<typeof useSearchSliceablesSagaLazyQuery>;
export type SearchSliceablesSagaSuspenseQueryHookResult = ReturnType<typeof useSearchSliceablesSagaSuspenseQuery>;
export type SearchSliceablesSagaQueryResult = Apollo.QueryResult<Types.SearchSliceablesSagaQuery, Types.SearchSliceablesSagaQueryVariables>;
export const DisclosuresForSavingsAndCashPageDocument = gql`
    query DisclosuresForSavingsAndCashPage($accountType: SaveAccountTypeEnum) {
  viewer {
    save {
      savings {
        savingsAgreements(accountType: $accountType) {
          agreements {
            documents {
              title
              url
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDisclosuresForSavingsAndCashPageQuery__
 *
 * To run a query within a React component, call `useDisclosuresForSavingsAndCashPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisclosuresForSavingsAndCashPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisclosuresForSavingsAndCashPageQuery({
 *   variables: {
 *      accountType: // value for 'accountType'
 *   },
 * });
 */
export function useDisclosuresForSavingsAndCashPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.DisclosuresForSavingsAndCashPageQuery, Types.DisclosuresForSavingsAndCashPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DisclosuresForSavingsAndCashPageQuery, Types.DisclosuresForSavingsAndCashPageQueryVariables>(DisclosuresForSavingsAndCashPageDocument, options);
      }
export function useDisclosuresForSavingsAndCashPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DisclosuresForSavingsAndCashPageQuery, Types.DisclosuresForSavingsAndCashPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DisclosuresForSavingsAndCashPageQuery, Types.DisclosuresForSavingsAndCashPageQueryVariables>(DisclosuresForSavingsAndCashPageDocument, options);
        }
export function useDisclosuresForSavingsAndCashPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.DisclosuresForSavingsAndCashPageQuery, Types.DisclosuresForSavingsAndCashPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.DisclosuresForSavingsAndCashPageQuery, Types.DisclosuresForSavingsAndCashPageQueryVariables>(DisclosuresForSavingsAndCashPageDocument, options);
        }
export type DisclosuresForSavingsAndCashPageQueryHookResult = ReturnType<typeof useDisclosuresForSavingsAndCashPageQuery>;
export type DisclosuresForSavingsAndCashPageLazyQueryHookResult = ReturnType<typeof useDisclosuresForSavingsAndCashPageLazyQuery>;
export type DisclosuresForSavingsAndCashPageSuspenseQueryHookResult = ReturnType<typeof useDisclosuresForSavingsAndCashPageSuspenseQuery>;
export type DisclosuresForSavingsAndCashPageQueryResult = Apollo.QueryResult<Types.DisclosuresForSavingsAndCashPageQuery, Types.DisclosuresForSavingsAndCashPageQueryVariables>;
export const SavePageDocument = gql`
    query SavePage {
  viewer {
    save {
      savings {
        hasSavingsAccounts
      }
    }
  }
}
    `;

/**
 * __useSavePageQuery__
 *
 * To run a query within a React component, call `useSavePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useSavePageQuery(baseOptions?: Apollo.QueryHookOptions<Types.SavePageQuery, Types.SavePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SavePageQuery, Types.SavePageQueryVariables>(SavePageDocument, options);
      }
export function useSavePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SavePageQuery, Types.SavePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SavePageQuery, Types.SavePageQueryVariables>(SavePageDocument, options);
        }
export function useSavePageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SavePageQuery, Types.SavePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SavePageQuery, Types.SavePageQueryVariables>(SavePageDocument, options);
        }
export type SavePageQueryHookResult = ReturnType<typeof useSavePageQuery>;
export type SavePageLazyQueryHookResult = ReturnType<typeof useSavePageLazyQuery>;
export type SavePageSuspenseQueryHookResult = ReturnType<typeof useSavePageSuspenseQuery>;
export type SavePageQueryResult = Apollo.QueryResult<Types.SavePageQuery, Types.SavePageQueryVariables>;
export const SavingsAccountInvitationPageDocument = gql`
    query SavingsAccountInvitationPage {
  viewer {
    user {
      username
    }
    profile {
      primary {
        phoneNumber
      }
    }
    save {
      savings {
        onboarding {
          accountInvitationPage {
            title
            subtitle
            primaryCtaButton {
              ...AppButton
            }
          }
        }
      }
    }
  }
}
    ${AppButtonFragmentDoc}`;

/**
 * __useSavingsAccountInvitationPageQuery__
 *
 * To run a query within a React component, call `useSavingsAccountInvitationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavingsAccountInvitationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavingsAccountInvitationPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useSavingsAccountInvitationPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.SavingsAccountInvitationPageQuery, Types.SavingsAccountInvitationPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SavingsAccountInvitationPageQuery, Types.SavingsAccountInvitationPageQueryVariables>(SavingsAccountInvitationPageDocument, options);
      }
export function useSavingsAccountInvitationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SavingsAccountInvitationPageQuery, Types.SavingsAccountInvitationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SavingsAccountInvitationPageQuery, Types.SavingsAccountInvitationPageQueryVariables>(SavingsAccountInvitationPageDocument, options);
        }
export function useSavingsAccountInvitationPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SavingsAccountInvitationPageQuery, Types.SavingsAccountInvitationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SavingsAccountInvitationPageQuery, Types.SavingsAccountInvitationPageQueryVariables>(SavingsAccountInvitationPageDocument, options);
        }
export type SavingsAccountInvitationPageQueryHookResult = ReturnType<typeof useSavingsAccountInvitationPageQuery>;
export type SavingsAccountInvitationPageLazyQueryHookResult = ReturnType<typeof useSavingsAccountInvitationPageLazyQuery>;
export type SavingsAccountInvitationPageSuspenseQueryHookResult = ReturnType<typeof useSavingsAccountInvitationPageSuspenseQuery>;
export type SavingsAccountInvitationPageQueryResult = Apollo.QueryResult<Types.SavingsAccountInvitationPageQuery, Types.SavingsAccountInvitationPageQueryVariables>;
export const SavingsAccountTypeDocument = gql`
    query SavingsAccountType {
  viewer {
    save {
      savings {
        onboarding {
          accountTypePage {
            title
            accountTypes {
              ...ListItem
            }
          }
        }
      }
    }
  }
}
    ${ListItemFragmentDoc}`;

/**
 * __useSavingsAccountTypeQuery__
 *
 * To run a query within a React component, call `useSavingsAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavingsAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavingsAccountTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useSavingsAccountTypeQuery(baseOptions?: Apollo.QueryHookOptions<Types.SavingsAccountTypeQuery, Types.SavingsAccountTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SavingsAccountTypeQuery, Types.SavingsAccountTypeQueryVariables>(SavingsAccountTypeDocument, options);
      }
export function useSavingsAccountTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SavingsAccountTypeQuery, Types.SavingsAccountTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SavingsAccountTypeQuery, Types.SavingsAccountTypeQueryVariables>(SavingsAccountTypeDocument, options);
        }
export function useSavingsAccountTypeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SavingsAccountTypeQuery, Types.SavingsAccountTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SavingsAccountTypeQuery, Types.SavingsAccountTypeQueryVariables>(SavingsAccountTypeDocument, options);
        }
export type SavingsAccountTypeQueryHookResult = ReturnType<typeof useSavingsAccountTypeQuery>;
export type SavingsAccountTypeLazyQueryHookResult = ReturnType<typeof useSavingsAccountTypeLazyQuery>;
export type SavingsAccountTypeSuspenseQueryHookResult = ReturnType<typeof useSavingsAccountTypeSuspenseQuery>;
export type SavingsAccountTypeQueryResult = Apollo.QueryResult<Types.SavingsAccountTypeQuery, Types.SavingsAccountTypeQueryVariables>;
export const SavingsConfirmationDocument = gql`
    query SavingsConfirmation($onboardingValue: String) {
  viewer {
    onboarding {
      ...FinancialInformationDueDiligence
    }
    profile {
      ...FinancialInformationProfile
      ...IdentityFirstConfirmationProfile
    }
    user {
      ...IdentityFirstConfirmationUser
    }
    save {
      savings {
        jointAccountInvitationsList {
          edges {
            node {
              firstName
              lastName
              phoneNumber
              email
              accountName
            }
          }
        }
        savingsAgreements(onboardingValue: $onboardingValue) {
          requiredAgreements {
            id
            postamble
            signature
            documents {
              title
              url
            }
          }
        }
        onboarding {
          fullyPaidLendingDisclosure(onboardingValue: $onboardingValue) {
            ...FullyPaidLendingDisclosure
          }
        }
      }
    }
  }
}
    ${FinancialInformationDueDiligenceFragmentDoc}
${FinancialInformationProfileFragmentDoc}
${IdentityFirstConfirmationProfileFragmentDoc}
${IdentityFirstConfirmationUserFragmentDoc}
${FullyPaidLendingDisclosureFragmentDoc}`;

/**
 * __useSavingsConfirmationQuery__
 *
 * To run a query within a React component, call `useSavingsConfirmationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavingsConfirmationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavingsConfirmationQuery({
 *   variables: {
 *      onboardingValue: // value for 'onboardingValue'
 *   },
 * });
 */
export function useSavingsConfirmationQuery(baseOptions?: Apollo.QueryHookOptions<Types.SavingsConfirmationQuery, Types.SavingsConfirmationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SavingsConfirmationQuery, Types.SavingsConfirmationQueryVariables>(SavingsConfirmationDocument, options);
      }
export function useSavingsConfirmationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SavingsConfirmationQuery, Types.SavingsConfirmationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SavingsConfirmationQuery, Types.SavingsConfirmationQueryVariables>(SavingsConfirmationDocument, options);
        }
export function useSavingsConfirmationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SavingsConfirmationQuery, Types.SavingsConfirmationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SavingsConfirmationQuery, Types.SavingsConfirmationQueryVariables>(SavingsConfirmationDocument, options);
        }
export type SavingsConfirmationQueryHookResult = ReturnType<typeof useSavingsConfirmationQuery>;
export type SavingsConfirmationLazyQueryHookResult = ReturnType<typeof useSavingsConfirmationLazyQuery>;
export type SavingsConfirmationSuspenseQueryHookResult = ReturnType<typeof useSavingsConfirmationSuspenseQuery>;
export type SavingsConfirmationQueryResult = Apollo.QueryResult<Types.SavingsConfirmationQuery, Types.SavingsConfirmationQueryVariables>;
export const SavingsConnectBankDocument = gql`
    query SavingsConnectBank($accountId: ID) {
  viewer {
    save {
      savings {
        onboarding {
          connectBankPage(accountId: $accountId) {
            ...AppInfoPage
          }
        }
      }
    }
  }
}
    ${AppInfoPageFragmentDoc}`;

/**
 * __useSavingsConnectBankQuery__
 *
 * To run a query within a React component, call `useSavingsConnectBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavingsConnectBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavingsConnectBankQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useSavingsConnectBankQuery(baseOptions?: Apollo.QueryHookOptions<Types.SavingsConnectBankQuery, Types.SavingsConnectBankQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SavingsConnectBankQuery, Types.SavingsConnectBankQueryVariables>(SavingsConnectBankDocument, options);
      }
export function useSavingsConnectBankLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SavingsConnectBankQuery, Types.SavingsConnectBankQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SavingsConnectBankQuery, Types.SavingsConnectBankQueryVariables>(SavingsConnectBankDocument, options);
        }
export function useSavingsConnectBankSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SavingsConnectBankQuery, Types.SavingsConnectBankQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SavingsConnectBankQuery, Types.SavingsConnectBankQueryVariables>(SavingsConnectBankDocument, options);
        }
export type SavingsConnectBankQueryHookResult = ReturnType<typeof useSavingsConnectBankQuery>;
export type SavingsConnectBankLazyQueryHookResult = ReturnType<typeof useSavingsConnectBankLazyQuery>;
export type SavingsConnectBankSuspenseQueryHookResult = ReturnType<typeof useSavingsConnectBankSuspenseQuery>;
export type SavingsConnectBankQueryResult = Apollo.QueryResult<Types.SavingsConnectBankQuery, Types.SavingsConnectBankQueryVariables>;
export const SavingsCustomerDueDiligenceQuestionsDocument = gql`
    query SavingsCustomerDueDiligenceQuestions($onboardingValue: String) {
  viewer {
    save {
      savings {
        onboarding {
          customerDueDiligenceQuestions(onboardingValue: $onboardingValue) {
            questions {
              questionId
              questionLabel
              answerType
              answers {
                answerId
                answerLabel
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSavingsCustomerDueDiligenceQuestionsQuery__
 *
 * To run a query within a React component, call `useSavingsCustomerDueDiligenceQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavingsCustomerDueDiligenceQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavingsCustomerDueDiligenceQuestionsQuery({
 *   variables: {
 *      onboardingValue: // value for 'onboardingValue'
 *   },
 * });
 */
export function useSavingsCustomerDueDiligenceQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.SavingsCustomerDueDiligenceQuestionsQuery, Types.SavingsCustomerDueDiligenceQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SavingsCustomerDueDiligenceQuestionsQuery, Types.SavingsCustomerDueDiligenceQuestionsQueryVariables>(SavingsCustomerDueDiligenceQuestionsDocument, options);
      }
export function useSavingsCustomerDueDiligenceQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SavingsCustomerDueDiligenceQuestionsQuery, Types.SavingsCustomerDueDiligenceQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SavingsCustomerDueDiligenceQuestionsQuery, Types.SavingsCustomerDueDiligenceQuestionsQueryVariables>(SavingsCustomerDueDiligenceQuestionsDocument, options);
        }
export function useSavingsCustomerDueDiligenceQuestionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SavingsCustomerDueDiligenceQuestionsQuery, Types.SavingsCustomerDueDiligenceQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SavingsCustomerDueDiligenceQuestionsQuery, Types.SavingsCustomerDueDiligenceQuestionsQueryVariables>(SavingsCustomerDueDiligenceQuestionsDocument, options);
        }
export type SavingsCustomerDueDiligenceQuestionsQueryHookResult = ReturnType<typeof useSavingsCustomerDueDiligenceQuestionsQuery>;
export type SavingsCustomerDueDiligenceQuestionsLazyQueryHookResult = ReturnType<typeof useSavingsCustomerDueDiligenceQuestionsLazyQuery>;
export type SavingsCustomerDueDiligenceQuestionsSuspenseQueryHookResult = ReturnType<typeof useSavingsCustomerDueDiligenceQuestionsSuspenseQuery>;
export type SavingsCustomerDueDiligenceQuestionsQueryResult = Apollo.QueryResult<Types.SavingsCustomerDueDiligenceQuestionsQuery, Types.SavingsCustomerDueDiligenceQuestionsQueryVariables>;
export const ESignAgreementDocument = gql`
    query ESignAgreement($onboardingValue: String) {
  viewer {
    save {
      savings {
        savingsAgreements(onboardingValue: $onboardingValue) {
          eSignAgreement {
            title
            url
          }
        }
      }
    }
  }
}
    `;

/**
 * __useESignAgreementQuery__
 *
 * To run a query within a React component, call `useESignAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `useESignAgreementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useESignAgreementQuery({
 *   variables: {
 *      onboardingValue: // value for 'onboardingValue'
 *   },
 * });
 */
export function useESignAgreementQuery(baseOptions?: Apollo.QueryHookOptions<Types.ESignAgreementQuery, Types.ESignAgreementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ESignAgreementQuery, Types.ESignAgreementQueryVariables>(ESignAgreementDocument, options);
      }
export function useESignAgreementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ESignAgreementQuery, Types.ESignAgreementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ESignAgreementQuery, Types.ESignAgreementQueryVariables>(ESignAgreementDocument, options);
        }
export function useESignAgreementSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ESignAgreementQuery, Types.ESignAgreementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ESignAgreementQuery, Types.ESignAgreementQueryVariables>(ESignAgreementDocument, options);
        }
export type ESignAgreementQueryHookResult = ReturnType<typeof useESignAgreementQuery>;
export type ESignAgreementLazyQueryHookResult = ReturnType<typeof useESignAgreementLazyQuery>;
export type ESignAgreementSuspenseQueryHookResult = ReturnType<typeof useESignAgreementSuspenseQuery>;
export type ESignAgreementQueryResult = Apollo.QueryResult<Types.ESignAgreementQuery, Types.ESignAgreementQueryVariables>;
export const SavingsFundAccountDocument = gql`
    query SavingsFundAccount($toParticipantId: ID!, $fromParticipantId: ID!, $transferType: TransferTypeEnum!, $date: String!) {
  node(id: $toParticipantId) {
    ... on TransferParticipant {
      transferParticipantType
      transferParticipantName
      transferParticipantDescription
    }
  }
  viewer {
    transfers {
      isEvenWeek(date: $date)
      requirements(
        amount: null
        fromParticipantId: $fromParticipantId
        toParticipantId: $toParticipantId
        transferType: $transferType
      ) {
        maxTransferAmount
        minTransferAmount
        ...TransferRequirementsTimingDescriptionCell
      }
      sourceParticipants: participants(
        transferType: $transferType
        forSide: FROM
        withOppositeParticipantId: $toParticipantId
      ) {
        isEmpty
        list {
          id
          transferParticipantType
          transferParticipantName
          transferParticipantDescription
        }
      }
    }
  }
}
    ${TransferRequirementsTimingDescriptionCellFragmentDoc}`;

/**
 * __useSavingsFundAccountQuery__
 *
 * To run a query within a React component, call `useSavingsFundAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavingsFundAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavingsFundAccountQuery({
 *   variables: {
 *      toParticipantId: // value for 'toParticipantId'
 *      fromParticipantId: // value for 'fromParticipantId'
 *      transferType: // value for 'transferType'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useSavingsFundAccountQuery(baseOptions: Apollo.QueryHookOptions<Types.SavingsFundAccountQuery, Types.SavingsFundAccountQueryVariables> & ({ variables: Types.SavingsFundAccountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SavingsFundAccountQuery, Types.SavingsFundAccountQueryVariables>(SavingsFundAccountDocument, options);
      }
export function useSavingsFundAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SavingsFundAccountQuery, Types.SavingsFundAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SavingsFundAccountQuery, Types.SavingsFundAccountQueryVariables>(SavingsFundAccountDocument, options);
        }
export function useSavingsFundAccountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SavingsFundAccountQuery, Types.SavingsFundAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SavingsFundAccountQuery, Types.SavingsFundAccountQueryVariables>(SavingsFundAccountDocument, options);
        }
export type SavingsFundAccountQueryHookResult = ReturnType<typeof useSavingsFundAccountQuery>;
export type SavingsFundAccountLazyQueryHookResult = ReturnType<typeof useSavingsFundAccountLazyQuery>;
export type SavingsFundAccountSuspenseQueryHookResult = ReturnType<typeof useSavingsFundAccountSuspenseQuery>;
export type SavingsFundAccountQueryResult = Apollo.QueryResult<Types.SavingsFundAccountQuery, Types.SavingsFundAccountQueryVariables>;
export const SavingsFundingCompleteDocument = gql`
    query SavingsFundingComplete($accountId: ID!) {
  viewer {
    save {
      savings {
        onboarding {
          fundingCompletePage(accountId: $accountId) {
            ...AppInfoPage
          }
        }
      }
    }
  }
}
    ${AppInfoPageFragmentDoc}`;

/**
 * __useSavingsFundingCompleteQuery__
 *
 * To run a query within a React component, call `useSavingsFundingCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavingsFundingCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavingsFundingCompleteQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useSavingsFundingCompleteQuery(baseOptions: Apollo.QueryHookOptions<Types.SavingsFundingCompleteQuery, Types.SavingsFundingCompleteQueryVariables> & ({ variables: Types.SavingsFundingCompleteQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SavingsFundingCompleteQuery, Types.SavingsFundingCompleteQueryVariables>(SavingsFundingCompleteDocument, options);
      }
export function useSavingsFundingCompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SavingsFundingCompleteQuery, Types.SavingsFundingCompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SavingsFundingCompleteQuery, Types.SavingsFundingCompleteQueryVariables>(SavingsFundingCompleteDocument, options);
        }
export function useSavingsFundingCompleteSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SavingsFundingCompleteQuery, Types.SavingsFundingCompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SavingsFundingCompleteQuery, Types.SavingsFundingCompleteQueryVariables>(SavingsFundingCompleteDocument, options);
        }
export type SavingsFundingCompleteQueryHookResult = ReturnType<typeof useSavingsFundingCompleteQuery>;
export type SavingsFundingCompleteLazyQueryHookResult = ReturnType<typeof useSavingsFundingCompleteLazyQuery>;
export type SavingsFundingCompleteSuspenseQueryHookResult = ReturnType<typeof useSavingsFundingCompleteSuspenseQuery>;
export type SavingsFundingCompleteQueryResult = Apollo.QueryResult<Types.SavingsFundingCompleteQuery, Types.SavingsFundingCompleteQueryVariables>;
export const SavingsJointInvitedDocument = gql`
    query SavingsJointInvited {
  viewer {
    save {
      savings {
        onboarding {
          jointInvitedPageOnboardingValue
          jointInvitedPage {
            ...AppInfoPage
          }
        }
      }
    }
  }
}
    ${AppInfoPageFragmentDoc}`;

/**
 * __useSavingsJointInvitedQuery__
 *
 * To run a query within a React component, call `useSavingsJointInvitedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavingsJointInvitedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavingsJointInvitedQuery({
 *   variables: {
 *   },
 * });
 */
export function useSavingsJointInvitedQuery(baseOptions?: Apollo.QueryHookOptions<Types.SavingsJointInvitedQuery, Types.SavingsJointInvitedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SavingsJointInvitedQuery, Types.SavingsJointInvitedQueryVariables>(SavingsJointInvitedDocument, options);
      }
export function useSavingsJointInvitedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SavingsJointInvitedQuery, Types.SavingsJointInvitedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SavingsJointInvitedQuery, Types.SavingsJointInvitedQueryVariables>(SavingsJointInvitedDocument, options);
        }
export function useSavingsJointInvitedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SavingsJointInvitedQuery, Types.SavingsJointInvitedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SavingsJointInvitedQuery, Types.SavingsJointInvitedQueryVariables>(SavingsJointInvitedDocument, options);
        }
export type SavingsJointInvitedQueryHookResult = ReturnType<typeof useSavingsJointInvitedQuery>;
export type SavingsJointInvitedLazyQueryHookResult = ReturnType<typeof useSavingsJointInvitedLazyQuery>;
export type SavingsJointInvitedSuspenseQueryHookResult = ReturnType<typeof useSavingsJointInvitedSuspenseQuery>;
export type SavingsJointInvitedQueryResult = Apollo.QueryResult<Types.SavingsJointInvitedQuery, Types.SavingsJointInvitedQueryVariables>;
export const SavingsNameAccountDocument = gql`
    query SavingsNameAccount {
  viewer {
    save {
      savings {
        onboarding {
          nameAccountPage {
            title
            subtitle
            primaryCtaButton {
              ...AppButton
            }
            secondaryCtaButton {
              ...AppButton
            }
            inputPlaceholder
            inputFieldName
          }
        }
      }
    }
  }
}
    ${AppButtonFragmentDoc}`;

/**
 * __useSavingsNameAccountQuery__
 *
 * To run a query within a React component, call `useSavingsNameAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavingsNameAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavingsNameAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useSavingsNameAccountQuery(baseOptions?: Apollo.QueryHookOptions<Types.SavingsNameAccountQuery, Types.SavingsNameAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SavingsNameAccountQuery, Types.SavingsNameAccountQueryVariables>(SavingsNameAccountDocument, options);
      }
export function useSavingsNameAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SavingsNameAccountQuery, Types.SavingsNameAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SavingsNameAccountQuery, Types.SavingsNameAccountQueryVariables>(SavingsNameAccountDocument, options);
        }
export function useSavingsNameAccountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SavingsNameAccountQuery, Types.SavingsNameAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SavingsNameAccountQuery, Types.SavingsNameAccountQueryVariables>(SavingsNameAccountDocument, options);
        }
export type SavingsNameAccountQueryHookResult = ReturnType<typeof useSavingsNameAccountQuery>;
export type SavingsNameAccountLazyQueryHookResult = ReturnType<typeof useSavingsNameAccountLazyQuery>;
export type SavingsNameAccountSuspenseQueryHookResult = ReturnType<typeof useSavingsNameAccountSuspenseQuery>;
export type SavingsNameAccountQueryResult = Apollo.QueryResult<Types.SavingsNameAccountQuery, Types.SavingsNameAccountQueryVariables>;
export const InitialFundingDocument = gql`
    query InitialFunding($savingsAccountId: ID!) {
  savingsnode: node(id: $savingsAccountId) {
    ... on SavingsAccount {
      id
      name
      initialTransferParticipant {
        id
        transferParticipantType
        transferParticipantName
        transferParticipantDescription
      }
      minimumInitialDeposit
      maximumInitialDeposit
    }
  }
}
    `;

/**
 * __useInitialFundingQuery__
 *
 * To run a query within a React component, call `useInitialFundingQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitialFundingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitialFundingQuery({
 *   variables: {
 *      savingsAccountId: // value for 'savingsAccountId'
 *   },
 * });
 */
export function useInitialFundingQuery(baseOptions: Apollo.QueryHookOptions<Types.InitialFundingQuery, Types.InitialFundingQueryVariables> & ({ variables: Types.InitialFundingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InitialFundingQuery, Types.InitialFundingQueryVariables>(InitialFundingDocument, options);
      }
export function useInitialFundingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InitialFundingQuery, Types.InitialFundingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InitialFundingQuery, Types.InitialFundingQueryVariables>(InitialFundingDocument, options);
        }
export function useInitialFundingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.InitialFundingQuery, Types.InitialFundingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.InitialFundingQuery, Types.InitialFundingQueryVariables>(InitialFundingDocument, options);
        }
export type InitialFundingQueryHookResult = ReturnType<typeof useInitialFundingQuery>;
export type InitialFundingLazyQueryHookResult = ReturnType<typeof useInitialFundingLazyQuery>;
export type InitialFundingSuspenseQueryHookResult = ReturnType<typeof useInitialFundingSuspenseQuery>;
export type InitialFundingQueryResult = Apollo.QueryResult<Types.InitialFundingQuery, Types.InitialFundingQueryVariables>;
export const SavingsPreloadDocument = gql`
    query SavingsPreload {
  viewer {
    id
    save {
      savings {
        hasSavingsAccounts
        onboarding {
          jointInvitedPage {
            ...AppInfoPage
          }
          nameAccountPage {
            title
            subtitle
            primaryCtaButton {
              ...AppButton
            }
            secondaryCtaButton {
              ...AppButton
            }
            inputPlaceholder
            inputFieldName
          }
          accountTypePage {
            title
            accountTypes {
              ...ListItem
            }
          }
          accountInvitationPage {
            title
            subtitle
            primaryCtaButton {
              ...AppButton
            }
          }
        }
        hasJointAccountInvitation
        jointAccountInvitationsList {
          edges {
            node {
              id
              firstName
              lastName
              phoneNumber
              email
              accountName
            }
          }
        }
      }
      isMultipleAccountsEligible
      isJointAccountsEligible
      isEligibleToOnboard
      isCashAccountsEligible
      isJointCashAccountsEligible
    }
    profile {
      primary {
        backupWithholding {
          isSubjectToBackupWithholding
        }
      }
      suitability {
        totalNetWorth
      }
    }
    user {
      hasOnboarded
    }
  }
}
    ${AppInfoPageFragmentDoc}
${AppButtonFragmentDoc}
${ListItemFragmentDoc}`;

/**
 * __useSavingsPreloadQuery__
 *
 * To run a query within a React component, call `useSavingsPreloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavingsPreloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavingsPreloadQuery({
 *   variables: {
 *   },
 * });
 */
export function useSavingsPreloadQuery(baseOptions?: Apollo.QueryHookOptions<Types.SavingsPreloadQuery, Types.SavingsPreloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SavingsPreloadQuery, Types.SavingsPreloadQueryVariables>(SavingsPreloadDocument, options);
      }
export function useSavingsPreloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SavingsPreloadQuery, Types.SavingsPreloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SavingsPreloadQuery, Types.SavingsPreloadQueryVariables>(SavingsPreloadDocument, options);
        }
export function useSavingsPreloadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SavingsPreloadQuery, Types.SavingsPreloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SavingsPreloadQuery, Types.SavingsPreloadQueryVariables>(SavingsPreloadDocument, options);
        }
export type SavingsPreloadQueryHookResult = ReturnType<typeof useSavingsPreloadQuery>;
export type SavingsPreloadLazyQueryHookResult = ReturnType<typeof useSavingsPreloadLazyQuery>;
export type SavingsPreloadSuspenseQueryHookResult = ReturnType<typeof useSavingsPreloadSuspenseQuery>;
export type SavingsPreloadQueryResult = Apollo.QueryResult<Types.SavingsPreloadQuery, Types.SavingsPreloadQueryVariables>;
export const GetSecurityHistoricalQuotesDocument = gql`
    query GetSecurityHistoricalQuotes($id: ID!, $securityPeriod: HistoricalQuotePeriodEnum!, $isCrypto: Boolean!, $useIntradayPeriod: Boolean!, $useSecurityPeriod: Boolean!, $intradayPeriod: IntradayQuotePeriodEnum, $intradayInterval: IntradayQuoteIntervalEnum) {
  security: node(id: $id) {
    ...SecurityHistoricalQuote
  }
}
    ${SecurityHistoricalQuoteFragmentDoc}`;

/**
 * __useGetSecurityHistoricalQuotesQuery__
 *
 * To run a query within a React component, call `useGetSecurityHistoricalQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecurityHistoricalQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecurityHistoricalQuotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      securityPeriod: // value for 'securityPeriod'
 *      isCrypto: // value for 'isCrypto'
 *      useIntradayPeriod: // value for 'useIntradayPeriod'
 *      useSecurityPeriod: // value for 'useSecurityPeriod'
 *      intradayPeriod: // value for 'intradayPeriod'
 *      intradayInterval: // value for 'intradayInterval'
 *   },
 * });
 */
export function useGetSecurityHistoricalQuotesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSecurityHistoricalQuotesQuery, Types.GetSecurityHistoricalQuotesQueryVariables> & ({ variables: Types.GetSecurityHistoricalQuotesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSecurityHistoricalQuotesQuery, Types.GetSecurityHistoricalQuotesQueryVariables>(GetSecurityHistoricalQuotesDocument, options);
      }
export function useGetSecurityHistoricalQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSecurityHistoricalQuotesQuery, Types.GetSecurityHistoricalQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSecurityHistoricalQuotesQuery, Types.GetSecurityHistoricalQuotesQueryVariables>(GetSecurityHistoricalQuotesDocument, options);
        }
export function useGetSecurityHistoricalQuotesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetSecurityHistoricalQuotesQuery, Types.GetSecurityHistoricalQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetSecurityHistoricalQuotesQuery, Types.GetSecurityHistoricalQuotesQueryVariables>(GetSecurityHistoricalQuotesDocument, options);
        }
export type GetSecurityHistoricalQuotesQueryHookResult = ReturnType<typeof useGetSecurityHistoricalQuotesQuery>;
export type GetSecurityHistoricalQuotesLazyQueryHookResult = ReturnType<typeof useGetSecurityHistoricalQuotesLazyQuery>;
export type GetSecurityHistoricalQuotesSuspenseQueryHookResult = ReturnType<typeof useGetSecurityHistoricalQuotesSuspenseQuery>;
export type GetSecurityHistoricalQuotesQueryResult = Apollo.QueryResult<Types.GetSecurityHistoricalQuotesQuery, Types.GetSecurityHistoricalQuotesQueryVariables>;
export const SecurityPerformanceDocument = gql`
    query SecurityPerformance($id: ID!, $range: TimeRangeInput, $interval: TimeIntervalEnum) {
  security: node(id: $id) {
    ... on Security {
      __typename
      historicalQuotes(range: $range, interval: $interval) {
        __typename
        date
        closePrice
      }
    }
  }
}
    `;

/**
 * __useSecurityPerformanceQuery__
 *
 * To run a query within a React component, call `useSecurityPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecurityPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecurityPerformanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      range: // value for 'range'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useSecurityPerformanceQuery(baseOptions: Apollo.QueryHookOptions<Types.SecurityPerformanceQuery, Types.SecurityPerformanceQueryVariables> & ({ variables: Types.SecurityPerformanceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SecurityPerformanceQuery, Types.SecurityPerformanceQueryVariables>(SecurityPerformanceDocument, options);
      }
export function useSecurityPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SecurityPerformanceQuery, Types.SecurityPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SecurityPerformanceQuery, Types.SecurityPerformanceQueryVariables>(SecurityPerformanceDocument, options);
        }
export function useSecurityPerformanceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SecurityPerformanceQuery, Types.SecurityPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SecurityPerformanceQuery, Types.SecurityPerformanceQueryVariables>(SecurityPerformanceDocument, options);
        }
export type SecurityPerformanceQueryHookResult = ReturnType<typeof useSecurityPerformanceQuery>;
export type SecurityPerformanceLazyQueryHookResult = ReturnType<typeof useSecurityPerformanceLazyQuery>;
export type SecurityPerformanceSuspenseQueryHookResult = ReturnType<typeof useSecurityPerformanceSuspenseQuery>;
export type SecurityPerformanceQueryResult = Apollo.QueryResult<Types.SecurityPerformanceQuery, Types.SecurityPerformanceQueryVariables>;
export const GetSecurityQuoteDocument = gql`
    query GetSecurityQuote($id: ID!) {
  security: node(id: $id) {
    ...SecurityQuote
  }
}
    ${SecurityQuoteFragmentDoc}`;

/**
 * __useGetSecurityQuoteQuery__
 *
 * To run a query within a React component, call `useGetSecurityQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecurityQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecurityQuoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSecurityQuoteQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSecurityQuoteQuery, Types.GetSecurityQuoteQueryVariables> & ({ variables: Types.GetSecurityQuoteQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSecurityQuoteQuery, Types.GetSecurityQuoteQueryVariables>(GetSecurityQuoteDocument, options);
      }
export function useGetSecurityQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSecurityQuoteQuery, Types.GetSecurityQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSecurityQuoteQuery, Types.GetSecurityQuoteQueryVariables>(GetSecurityQuoteDocument, options);
        }
export function useGetSecurityQuoteSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetSecurityQuoteQuery, Types.GetSecurityQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetSecurityQuoteQuery, Types.GetSecurityQuoteQueryVariables>(GetSecurityQuoteDocument, options);
        }
export type GetSecurityQuoteQueryHookResult = ReturnType<typeof useGetSecurityQuoteQuery>;
export type GetSecurityQuoteLazyQueryHookResult = ReturnType<typeof useGetSecurityQuoteLazyQuery>;
export type GetSecurityQuoteSuspenseQueryHookResult = ReturnType<typeof useGetSecurityQuoteSuspenseQuery>;
export type GetSecurityQuoteQueryResult = Apollo.QueryResult<Types.GetSecurityQuoteQuery, Types.GetSecurityQuoteQueryVariables>;
export const TwoFactorAuthDocument = gql`
    query TwoFactorAuth {
  viewer {
    user {
      isTwoFactorAuthEnabled
    }
  }
}
    `;

/**
 * __useTwoFactorAuthQuery__
 *
 * To run a query within a React component, call `useTwoFactorAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useTwoFactorAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTwoFactorAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useTwoFactorAuthQuery(baseOptions?: Apollo.QueryHookOptions<Types.TwoFactorAuthQuery, Types.TwoFactorAuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TwoFactorAuthQuery, Types.TwoFactorAuthQueryVariables>(TwoFactorAuthDocument, options);
      }
export function useTwoFactorAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TwoFactorAuthQuery, Types.TwoFactorAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TwoFactorAuthQuery, Types.TwoFactorAuthQueryVariables>(TwoFactorAuthDocument, options);
        }
export function useTwoFactorAuthSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TwoFactorAuthQuery, Types.TwoFactorAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TwoFactorAuthQuery, Types.TwoFactorAuthQueryVariables>(TwoFactorAuthDocument, options);
        }
export type TwoFactorAuthQueryHookResult = ReturnType<typeof useTwoFactorAuthQuery>;
export type TwoFactorAuthLazyQueryHookResult = ReturnType<typeof useTwoFactorAuthLazyQuery>;
export type TwoFactorAuthSuspenseQueryHookResult = ReturnType<typeof useTwoFactorAuthSuspenseQuery>;
export type TwoFactorAuthQueryResult = Apollo.QueryResult<Types.TwoFactorAuthQuery, Types.TwoFactorAuthQueryVariables>;
export const FullyPaidLendingSettingsPageDocument = gql`
    query FullyPaidLendingSettingsPage {
  viewer {
    settings {
      invest {
        fullyPaidLending {
          banner {
            ...InformationBanner
          }
          title
          subtitle
          helpLink {
            ...AppLink
          }
          incomeEarnedSection {
            icon {
              ...AppImage
            }
            content
          }
          accountStockLoanHoldings {
            ...AccountStockLoanGroups
          }
          optInSection {
            confirmContent {
              agreementLabel
              cancelCtaLabel
              confirmCtaLabel
              paragraphs {
                ...RichText
              }
              title
            }
            ctaLabel
          }
        }
      }
    }
  }
}
    ${InformationBannerFragmentDoc}
${AppLinkFragmentDoc}
${AppImageFragmentDoc}
${AccountStockLoanGroupsFragmentDoc}
${RichTextFragmentDoc}`;

/**
 * __useFullyPaidLendingSettingsPageQuery__
 *
 * To run a query within a React component, call `useFullyPaidLendingSettingsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullyPaidLendingSettingsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullyPaidLendingSettingsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFullyPaidLendingSettingsPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.FullyPaidLendingSettingsPageQuery, Types.FullyPaidLendingSettingsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FullyPaidLendingSettingsPageQuery, Types.FullyPaidLendingSettingsPageQueryVariables>(FullyPaidLendingSettingsPageDocument, options);
      }
export function useFullyPaidLendingSettingsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FullyPaidLendingSettingsPageQuery, Types.FullyPaidLendingSettingsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FullyPaidLendingSettingsPageQuery, Types.FullyPaidLendingSettingsPageQueryVariables>(FullyPaidLendingSettingsPageDocument, options);
        }
export function useFullyPaidLendingSettingsPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.FullyPaidLendingSettingsPageQuery, Types.FullyPaidLendingSettingsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.FullyPaidLendingSettingsPageQuery, Types.FullyPaidLendingSettingsPageQueryVariables>(FullyPaidLendingSettingsPageDocument, options);
        }
export type FullyPaidLendingSettingsPageQueryHookResult = ReturnType<typeof useFullyPaidLendingSettingsPageQuery>;
export type FullyPaidLendingSettingsPageLazyQueryHookResult = ReturnType<typeof useFullyPaidLendingSettingsPageLazyQuery>;
export type FullyPaidLendingSettingsPageSuspenseQueryHookResult = ReturnType<typeof useFullyPaidLendingSettingsPageSuspenseQuery>;
export type FullyPaidLendingSettingsPageQueryResult = Apollo.QueryResult<Types.FullyPaidLendingSettingsPageQuery, Types.FullyPaidLendingSettingsPageQueryVariables>;
export const SharePieDetailsDocument = gql`
    query SharePieDetails($editModel: String!, $period: PieBacktestPeriodEnum!) {
  viewer {
    readPieEditModel(editModel: $editModel) {
      analysis {
        ...PieAnalysis
      }
    }
  }
}
    ${PieAnalysisFragmentDoc}`;

/**
 * __useSharePieDetailsQuery__
 *
 * To run a query within a React component, call `useSharePieDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharePieDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharePieDetailsQuery({
 *   variables: {
 *      editModel: // value for 'editModel'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useSharePieDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.SharePieDetailsQuery, Types.SharePieDetailsQueryVariables> & ({ variables: Types.SharePieDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SharePieDetailsQuery, Types.SharePieDetailsQueryVariables>(SharePieDetailsDocument, options);
      }
export function useSharePieDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SharePieDetailsQuery, Types.SharePieDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SharePieDetailsQuery, Types.SharePieDetailsQueryVariables>(SharePieDetailsDocument, options);
        }
export function useSharePieDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SharePieDetailsQuery, Types.SharePieDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SharePieDetailsQuery, Types.SharePieDetailsQueryVariables>(SharePieDetailsDocument, options);
        }
export type SharePieDetailsQueryHookResult = ReturnType<typeof useSharePieDetailsQuery>;
export type SharePieDetailsLazyQueryHookResult = ReturnType<typeof useSharePieDetailsLazyQuery>;
export type SharePieDetailsSuspenseQueryHookResult = ReturnType<typeof useSharePieDetailsSuspenseQuery>;
export type SharePieDetailsQueryResult = Apollo.QueryResult<Types.SharePieDetailsQuery, Types.SharePieDetailsQueryVariables>;
export const SharePieLandingPageDocument = gql`
    query SharePieLandingPage($shareToken: String!) {
  viewer {
    sharedPie(shareToken: $shareToken) {
      ... on SharedPie {
        id
        name
        description
        editModel
        isActive
      }
    }
    unauthenticated {
      sharePieLandingPage(shareToken: $shareToken) {
        pageViewAnalyticsEvent {
          ...AnalyticsEvent
        }
        pieTitle
        header {
          ...SharePieHeaderProps
        }
        marketingDetails {
          ...SharePieMarketingSectionProps
        }
        delistedPieBanner {
          ...InformationBanner
        }
        disclosuresFooter {
          ...AppRichTextDisclosureLink
        }
      }
    }
  }
}
    ${AnalyticsEventFragmentDoc}
${SharePieHeaderPropsFragmentDoc}
${SharePieMarketingSectionPropsFragmentDoc}
${InformationBannerFragmentDoc}
${AppRichTextDisclosureLinkFragmentDoc}`;

/**
 * __useSharePieLandingPageQuery__
 *
 * To run a query within a React component, call `useSharePieLandingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharePieLandingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharePieLandingPageQuery({
 *   variables: {
 *      shareToken: // value for 'shareToken'
 *   },
 * });
 */
export function useSharePieLandingPageQuery(baseOptions: Apollo.QueryHookOptions<Types.SharePieLandingPageQuery, Types.SharePieLandingPageQueryVariables> & ({ variables: Types.SharePieLandingPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SharePieLandingPageQuery, Types.SharePieLandingPageQueryVariables>(SharePieLandingPageDocument, options);
      }
export function useSharePieLandingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SharePieLandingPageQuery, Types.SharePieLandingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SharePieLandingPageQuery, Types.SharePieLandingPageQueryVariables>(SharePieLandingPageDocument, options);
        }
export function useSharePieLandingPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SharePieLandingPageQuery, Types.SharePieLandingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SharePieLandingPageQuery, Types.SharePieLandingPageQueryVariables>(SharePieLandingPageDocument, options);
        }
export type SharePieLandingPageQueryHookResult = ReturnType<typeof useSharePieLandingPageQuery>;
export type SharePieLandingPageLazyQueryHookResult = ReturnType<typeof useSharePieLandingPageLazyQuery>;
export type SharePieLandingPageSuspenseQueryHookResult = ReturnType<typeof useSharePieLandingPageSuspenseQuery>;
export type SharePieLandingPageQueryResult = Apollo.QueryResult<Types.SharePieLandingPageQuery, Types.SharePieLandingPageQueryVariables>;
export const SharePieLogInBannerDocument = gql`
    query SharePieLogInBanner {
  viewer {
    unauthenticated {
      sharePieLandingPage {
        logInBanner {
          ...SharePieAccountSectionProps
        }
      }
    }
  }
}
    ${SharePieAccountSectionPropsFragmentDoc}`;

/**
 * __useSharePieLogInBannerQuery__
 *
 * To run a query within a React component, call `useSharePieLogInBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharePieLogInBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharePieLogInBannerQuery({
 *   variables: {
 *   },
 * });
 */
export function useSharePieLogInBannerQuery(baseOptions?: Apollo.QueryHookOptions<Types.SharePieLogInBannerQuery, Types.SharePieLogInBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SharePieLogInBannerQuery, Types.SharePieLogInBannerQueryVariables>(SharePieLogInBannerDocument, options);
      }
export function useSharePieLogInBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SharePieLogInBannerQuery, Types.SharePieLogInBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SharePieLogInBannerQuery, Types.SharePieLogInBannerQueryVariables>(SharePieLogInBannerDocument, options);
        }
export function useSharePieLogInBannerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SharePieLogInBannerQuery, Types.SharePieLogInBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SharePieLogInBannerQuery, Types.SharePieLogInBannerQueryVariables>(SharePieLogInBannerDocument, options);
        }
export type SharePieLogInBannerQueryHookResult = ReturnType<typeof useSharePieLogInBannerQuery>;
export type SharePieLogInBannerLazyQueryHookResult = ReturnType<typeof useSharePieLogInBannerLazyQuery>;
export type SharePieLogInBannerSuspenseQueryHookResult = ReturnType<typeof useSharePieLogInBannerSuspenseQuery>;
export type SharePieLogInBannerQueryResult = Apollo.QueryResult<Types.SharePieLogInBannerQuery, Types.SharePieLogInBannerQueryVariables>;
export const AccountsPageDocument = gql`
    query AccountsPage {
  viewer {
    save {
      savings {
        ...SavingsAccountSettings
      }
    }
    ...InvestAccountSettings
    borrow {
      ...PersonalLoansAccountSettings
    }
  }
}
    ${SavingsAccountSettingsFragmentDoc}
${InvestAccountSettingsFragmentDoc}
${PersonalLoansAccountSettingsFragmentDoc}`;

/**
 * __useAccountsPageQuery__
 *
 * To run a query within a React component, call `useAccountsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountsPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.AccountsPageQuery, Types.AccountsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AccountsPageQuery, Types.AccountsPageQueryVariables>(AccountsPageDocument, options);
      }
export function useAccountsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AccountsPageQuery, Types.AccountsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AccountsPageQuery, Types.AccountsPageQueryVariables>(AccountsPageDocument, options);
        }
export function useAccountsPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AccountsPageQuery, Types.AccountsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AccountsPageQuery, Types.AccountsPageQueryVariables>(AccountsPageDocument, options);
        }
export type AccountsPageQueryHookResult = ReturnType<typeof useAccountsPageQuery>;
export type AccountsPageLazyQueryHookResult = ReturnType<typeof useAccountsPageLazyQuery>;
export type AccountsPageSuspenseQueryHookResult = ReturnType<typeof useAccountsPageSuspenseQuery>;
export type AccountsPageQueryResult = Apollo.QueryResult<Types.AccountsPageQuery, Types.AccountsPageQueryVariables>;
export const CreditCardDetailsCardDocument = gql`
    query CreditCardDetailsCard($accountId: ID!) {
  node(id: $accountId) {
    ... on CreditCardAccount {
      id
      activeCard {
        id
        isLocked
      }
      creditCardProductLandingPage {
        detailsCard {
          title
          lockCardLabel
          details {
            ...TabularDataItem
          }
        }
      }
    }
  }
}
    ${TabularDataItemFragmentDoc}`;

/**
 * __useCreditCardDetailsCardQuery__
 *
 * To run a query within a React component, call `useCreditCardDetailsCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditCardDetailsCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditCardDetailsCardQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useCreditCardDetailsCardQuery(baseOptions: Apollo.QueryHookOptions<Types.CreditCardDetailsCardQuery, Types.CreditCardDetailsCardQueryVariables> & ({ variables: Types.CreditCardDetailsCardQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CreditCardDetailsCardQuery, Types.CreditCardDetailsCardQueryVariables>(CreditCardDetailsCardDocument, options);
      }
export function useCreditCardDetailsCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CreditCardDetailsCardQuery, Types.CreditCardDetailsCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CreditCardDetailsCardQuery, Types.CreditCardDetailsCardQueryVariables>(CreditCardDetailsCardDocument, options);
        }
export function useCreditCardDetailsCardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CreditCardDetailsCardQuery, Types.CreditCardDetailsCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CreditCardDetailsCardQuery, Types.CreditCardDetailsCardQueryVariables>(CreditCardDetailsCardDocument, options);
        }
export type CreditCardDetailsCardQueryHookResult = ReturnType<typeof useCreditCardDetailsCardQuery>;
export type CreditCardDetailsCardLazyQueryHookResult = ReturnType<typeof useCreditCardDetailsCardLazyQuery>;
export type CreditCardDetailsCardSuspenseQueryHookResult = ReturnType<typeof useCreditCardDetailsCardSuspenseQuery>;
export type CreditCardDetailsCardQueryResult = Apollo.QueryResult<Types.CreditCardDetailsCardQuery, Types.CreditCardDetailsCardQueryVariables>;
export const CreditCardLandingPageHeaderDocument = gql`
    query CreditCardLandingPageHeader($accountId: ID!) {
  node(id: $accountId) {
    ... on CreditCardAccount {
      id
      hasUnactivatedCard
      creditCardProductLandingPage {
        title
        header {
          ...LandingPageheader
        }
        floatingActionButton {
          title
        }
        accountDetails {
          ...TabularDataItem
        }
      }
    }
  }
}
    ${LandingPageheaderFragmentDoc}
${TabularDataItemFragmentDoc}`;

/**
 * __useCreditCardLandingPageHeaderQuery__
 *
 * To run a query within a React component, call `useCreditCardLandingPageHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditCardLandingPageHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditCardLandingPageHeaderQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useCreditCardLandingPageHeaderQuery(baseOptions: Apollo.QueryHookOptions<Types.CreditCardLandingPageHeaderQuery, Types.CreditCardLandingPageHeaderQueryVariables> & ({ variables: Types.CreditCardLandingPageHeaderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CreditCardLandingPageHeaderQuery, Types.CreditCardLandingPageHeaderQueryVariables>(CreditCardLandingPageHeaderDocument, options);
      }
export function useCreditCardLandingPageHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CreditCardLandingPageHeaderQuery, Types.CreditCardLandingPageHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CreditCardLandingPageHeaderQuery, Types.CreditCardLandingPageHeaderQueryVariables>(CreditCardLandingPageHeaderDocument, options);
        }
export function useCreditCardLandingPageHeaderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CreditCardLandingPageHeaderQuery, Types.CreditCardLandingPageHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CreditCardLandingPageHeaderQuery, Types.CreditCardLandingPageHeaderQueryVariables>(CreditCardLandingPageHeaderDocument, options);
        }
export type CreditCardLandingPageHeaderQueryHookResult = ReturnType<typeof useCreditCardLandingPageHeaderQuery>;
export type CreditCardLandingPageHeaderLazyQueryHookResult = ReturnType<typeof useCreditCardLandingPageHeaderLazyQuery>;
export type CreditCardLandingPageHeaderSuspenseQueryHookResult = ReturnType<typeof useCreditCardLandingPageHeaderSuspenseQuery>;
export type CreditCardLandingPageHeaderQueryResult = Apollo.QueryResult<Types.CreditCardLandingPageHeaderQuery, Types.CreditCardLandingPageHeaderQueryVariables>;
export const CreditCardLandingPageValuePropCardDocument = gql`
    query CreditCardLandingPageValuePropCard($id: ID!) {
  node(id: $id) {
    ... on CreditCardAccount {
      id
      creditCardProductLandingPage {
        valuePropCard {
          ...LandingPageValuePropCard
        }
      }
    }
  }
}
    ${LandingPageValuePropCardFragmentDoc}`;

/**
 * __useCreditCardLandingPageValuePropCardQuery__
 *
 * To run a query within a React component, call `useCreditCardLandingPageValuePropCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditCardLandingPageValuePropCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditCardLandingPageValuePropCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreditCardLandingPageValuePropCardQuery(baseOptions: Apollo.QueryHookOptions<Types.CreditCardLandingPageValuePropCardQuery, Types.CreditCardLandingPageValuePropCardQueryVariables> & ({ variables: Types.CreditCardLandingPageValuePropCardQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CreditCardLandingPageValuePropCardQuery, Types.CreditCardLandingPageValuePropCardQueryVariables>(CreditCardLandingPageValuePropCardDocument, options);
      }
export function useCreditCardLandingPageValuePropCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CreditCardLandingPageValuePropCardQuery, Types.CreditCardLandingPageValuePropCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CreditCardLandingPageValuePropCardQuery, Types.CreditCardLandingPageValuePropCardQueryVariables>(CreditCardLandingPageValuePropCardDocument, options);
        }
export function useCreditCardLandingPageValuePropCardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CreditCardLandingPageValuePropCardQuery, Types.CreditCardLandingPageValuePropCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CreditCardLandingPageValuePropCardQuery, Types.CreditCardLandingPageValuePropCardQueryVariables>(CreditCardLandingPageValuePropCardDocument, options);
        }
export type CreditCardLandingPageValuePropCardQueryHookResult = ReturnType<typeof useCreditCardLandingPageValuePropCardQuery>;
export type CreditCardLandingPageValuePropCardLazyQueryHookResult = ReturnType<typeof useCreditCardLandingPageValuePropCardLazyQuery>;
export type CreditCardLandingPageValuePropCardSuspenseQueryHookResult = ReturnType<typeof useCreditCardLandingPageValuePropCardSuspenseQuery>;
export type CreditCardLandingPageValuePropCardQueryResult = Apollo.QueryResult<Types.CreditCardLandingPageValuePropCardQuery, Types.CreditCardLandingPageValuePropCardQueryVariables>;
export const CreditCardProductLandingPageDocument = gql`
    query CreditCardProductLandingPage($accountId: ID!) {
  node(id: $accountId) {
    ... on CreditCardAccount {
      id
      creditCardProductLandingPage {
        disclosures {
          ...AppRichTextDisclosureLink
        }
      }
    }
  }
  viewer {
    announcements {
      forSpend {
        ...Announcement
      }
    }
  }
}
    ${AppRichTextDisclosureLinkFragmentDoc}
${AnnouncementFragmentDoc}`;

/**
 * __useCreditCardProductLandingPageQuery__
 *
 * To run a query within a React component, call `useCreditCardProductLandingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditCardProductLandingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditCardProductLandingPageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useCreditCardProductLandingPageQuery(baseOptions: Apollo.QueryHookOptions<Types.CreditCardProductLandingPageQuery, Types.CreditCardProductLandingPageQueryVariables> & ({ variables: Types.CreditCardProductLandingPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CreditCardProductLandingPageQuery, Types.CreditCardProductLandingPageQueryVariables>(CreditCardProductLandingPageDocument, options);
      }
export function useCreditCardProductLandingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CreditCardProductLandingPageQuery, Types.CreditCardProductLandingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CreditCardProductLandingPageQuery, Types.CreditCardProductLandingPageQueryVariables>(CreditCardProductLandingPageDocument, options);
        }
export function useCreditCardProductLandingPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CreditCardProductLandingPageQuery, Types.CreditCardProductLandingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CreditCardProductLandingPageQuery, Types.CreditCardProductLandingPageQueryVariables>(CreditCardProductLandingPageDocument, options);
        }
export type CreditCardProductLandingPageQueryHookResult = ReturnType<typeof useCreditCardProductLandingPageQuery>;
export type CreditCardProductLandingPageLazyQueryHookResult = ReturnType<typeof useCreditCardProductLandingPageLazyQuery>;
export type CreditCardProductLandingPageSuspenseQueryHookResult = ReturnType<typeof useCreditCardProductLandingPageSuspenseQuery>;
export type CreditCardProductLandingPageQueryResult = Apollo.QueryResult<Types.CreditCardProductLandingPageQuery, Types.CreditCardProductLandingPageQueryVariables>;
export const ReportTransactionIssuePageDocument = gql`
    query ReportTransactionIssuePage($nodeId: ID!) {
  node(id: $nodeId) {
    ... on SpendActivityEntryDisputeDetails {
      content
      title
      viaDetailsCtaLabel
      resolutionCta {
        ...Linkable
      }
      phoneNumber
      ifDebitCardRelated {
        id
        status {
          isVerified
          isLocked
          lockStatusCode
        }
      }
    }
  }
}
    ${LinkableFragmentDoc}`;

/**
 * __useReportTransactionIssuePageQuery__
 *
 * To run a query within a React component, call `useReportTransactionIssuePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTransactionIssuePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTransactionIssuePageQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useReportTransactionIssuePageQuery(baseOptions: Apollo.QueryHookOptions<Types.ReportTransactionIssuePageQuery, Types.ReportTransactionIssuePageQueryVariables> & ({ variables: Types.ReportTransactionIssuePageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReportTransactionIssuePageQuery, Types.ReportTransactionIssuePageQueryVariables>(ReportTransactionIssuePageDocument, options);
      }
export function useReportTransactionIssuePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReportTransactionIssuePageQuery, Types.ReportTransactionIssuePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReportTransactionIssuePageQuery, Types.ReportTransactionIssuePageQueryVariables>(ReportTransactionIssuePageDocument, options);
        }
export function useReportTransactionIssuePageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ReportTransactionIssuePageQuery, Types.ReportTransactionIssuePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ReportTransactionIssuePageQuery, Types.ReportTransactionIssuePageQueryVariables>(ReportTransactionIssuePageDocument, options);
        }
export type ReportTransactionIssuePageQueryHookResult = ReturnType<typeof useReportTransactionIssuePageQuery>;
export type ReportTransactionIssuePageLazyQueryHookResult = ReturnType<typeof useReportTransactionIssuePageLazyQuery>;
export type ReportTransactionIssuePageSuspenseQueryHookResult = ReturnType<typeof useReportTransactionIssuePageSuspenseQuery>;
export type ReportTransactionIssuePageQueryResult = Apollo.QueryResult<Types.ReportTransactionIssuePageQuery, Types.ReportTransactionIssuePageQueryVariables>;
export const SpendActivityEntryDetailsPageDocument = gql`
    query SpendActivityEntryDetailsPage($spendActivityEntryDetailsId: ID!) {
  node(id: $spendActivityEntryDetailsId) {
    ...SpendActivityEntryDetailsCard
  }
}
    ${SpendActivityEntryDetailsCardFragmentDoc}`;

/**
 * __useSpendActivityEntryDetailsPageQuery__
 *
 * To run a query within a React component, call `useSpendActivityEntryDetailsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendActivityEntryDetailsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendActivityEntryDetailsPageQuery({
 *   variables: {
 *      spendActivityEntryDetailsId: // value for 'spendActivityEntryDetailsId'
 *   },
 * });
 */
export function useSpendActivityEntryDetailsPageQuery(baseOptions: Apollo.QueryHookOptions<Types.SpendActivityEntryDetailsPageQuery, Types.SpendActivityEntryDetailsPageQueryVariables> & ({ variables: Types.SpendActivityEntryDetailsPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SpendActivityEntryDetailsPageQuery, Types.SpendActivityEntryDetailsPageQueryVariables>(SpendActivityEntryDetailsPageDocument, options);
      }
export function useSpendActivityEntryDetailsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SpendActivityEntryDetailsPageQuery, Types.SpendActivityEntryDetailsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SpendActivityEntryDetailsPageQuery, Types.SpendActivityEntryDetailsPageQueryVariables>(SpendActivityEntryDetailsPageDocument, options);
        }
export function useSpendActivityEntryDetailsPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SpendActivityEntryDetailsPageQuery, Types.SpendActivityEntryDetailsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SpendActivityEntryDetailsPageQuery, Types.SpendActivityEntryDetailsPageQueryVariables>(SpendActivityEntryDetailsPageDocument, options);
        }
export type SpendActivityEntryDetailsPageQueryHookResult = ReturnType<typeof useSpendActivityEntryDetailsPageQuery>;
export type SpendActivityEntryDetailsPageLazyQueryHookResult = ReturnType<typeof useSpendActivityEntryDetailsPageLazyQuery>;
export type SpendActivityEntryDetailsPageSuspenseQueryHookResult = ReturnType<typeof useSpendActivityEntryDetailsPageSuspenseQuery>;
export type SpendActivityEntryDetailsPageQueryResult = Apollo.QueryResult<Types.SpendActivityEntryDetailsPageQuery, Types.SpendActivityEntryDetailsPageQueryVariables>;
export const SpendMarketingScreenDocument = gql`
    query SpendMarketingScreen {
  viewer {
    spend {
      spendMarketingScreen {
        header
        productMarketingCards {
          illustration {
            ...AppImage
          }
          title
          titleTooltip
          subtitle
          primaryCtaButton {
            ...AppLink
          }
          secondaryCtaButton {
            ...AppLink
          }
          analyticsEvent {
            ...AnalyticsEvent
          }
        }
        disclosures {
          ...AppRichTextDisclosureLink
        }
        disclosureLinks {
          ...AppLink
        }
      }
    }
  }
}
    ${AppImageFragmentDoc}
${AppLinkFragmentDoc}
${AnalyticsEventFragmentDoc}
${AppRichTextDisclosureLinkFragmentDoc}`;

/**
 * __useSpendMarketingScreenQuery__
 *
 * To run a query within a React component, call `useSpendMarketingScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendMarketingScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendMarketingScreenQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendMarketingScreenQuery(baseOptions?: Apollo.QueryHookOptions<Types.SpendMarketingScreenQuery, Types.SpendMarketingScreenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SpendMarketingScreenQuery, Types.SpendMarketingScreenQueryVariables>(SpendMarketingScreenDocument, options);
      }
export function useSpendMarketingScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SpendMarketingScreenQuery, Types.SpendMarketingScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SpendMarketingScreenQuery, Types.SpendMarketingScreenQueryVariables>(SpendMarketingScreenDocument, options);
        }
export function useSpendMarketingScreenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SpendMarketingScreenQuery, Types.SpendMarketingScreenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SpendMarketingScreenQuery, Types.SpendMarketingScreenQueryVariables>(SpendMarketingScreenDocument, options);
        }
export type SpendMarketingScreenQueryHookResult = ReturnType<typeof useSpendMarketingScreenQuery>;
export type SpendMarketingScreenLazyQueryHookResult = ReturnType<typeof useSpendMarketingScreenLazyQuery>;
export type SpendMarketingScreenSuspenseQueryHookResult = ReturnType<typeof useSpendMarketingScreenSuspenseQuery>;
export type SpendMarketingScreenQueryResult = Apollo.QueryResult<Types.SpendMarketingScreenQuery, Types.SpendMarketingScreenQueryVariables>;
export const SpendPageDocument = gql`
    query SpendPage {
  viewer {
    user {
      id
      data(
        keys: ["approvedCreditCardApplicationIds", "acceptedCreditCardApplicationIds"]
      ) {
        key
        value
      }
    }
    credit {
      id
      isEnabled
      isEligible
      hasActiveAccount
      hasClosedAccount
      hasSuspendedAccount
      activeApplication {
        id
        status
      }
    }
  }
}
    `;

/**
 * __useSpendPageQuery__
 *
 * To run a query within a React component, call `useSpendPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpendPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.SpendPageQuery, Types.SpendPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SpendPageQuery, Types.SpendPageQueryVariables>(SpendPageDocument, options);
      }
export function useSpendPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SpendPageQuery, Types.SpendPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SpendPageQuery, Types.SpendPageQueryVariables>(SpendPageDocument, options);
        }
export function useSpendPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SpendPageQuery, Types.SpendPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SpendPageQuery, Types.SpendPageQueryVariables>(SpendPageDocument, options);
        }
export type SpendPageQueryHookResult = ReturnType<typeof useSpendPageQuery>;
export type SpendPageLazyQueryHookResult = ReturnType<typeof useSpendPageLazyQuery>;
export type SpendPageSuspenseQueryHookResult = ReturnType<typeof useSpendPageSuspenseQuery>;
export type SpendPageQueryResult = Apollo.QueryResult<Types.SpendPageQuery, Types.SpendPageQueryVariables>;
export const ContactUsPageDocument = gql`
    query ContactUsPage {
  viewer {
    contactUs {
      title
      subtitle
      ...ContactUsCards
      ...VideoTutorialsSection
    }
  }
}
    ${ContactUsCardsFragmentDoc}
${VideoTutorialsSectionFragmentDoc}`;

/**
 * __useContactUsPageQuery__
 *
 * To run a query within a React component, call `useContactUsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactUsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactUsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactUsPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.ContactUsPageQuery, Types.ContactUsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ContactUsPageQuery, Types.ContactUsPageQueryVariables>(ContactUsPageDocument, options);
      }
export function useContactUsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ContactUsPageQuery, Types.ContactUsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ContactUsPageQuery, Types.ContactUsPageQueryVariables>(ContactUsPageDocument, options);
        }
export function useContactUsPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ContactUsPageQuery, Types.ContactUsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ContactUsPageQuery, Types.ContactUsPageQueryVariables>(ContactUsPageDocument, options);
        }
export type ContactUsPageQueryHookResult = ReturnType<typeof useContactUsPageQuery>;
export type ContactUsPageLazyQueryHookResult = ReturnType<typeof useContactUsPageLazyQuery>;
export type ContactUsPageSuspenseQueryHookResult = ReturnType<typeof useContactUsPageSuspenseQuery>;
export type ContactUsPageQueryResult = Apollo.QueryResult<Types.ContactUsPageQuery, Types.ContactUsPageQueryVariables>;
export const LiquidateModalContainerDocument = gql`
    query LiquidateModalContainer($unmanagedHoldingIdsForMergedSlices: [ID!]!, $accountId: ID!) {
  node(id: $accountId) {
    ... on Account {
      receivedSecurities {
        unmanagedHoldingsStagedForLiquidate(
          unmanagedHoldingIds: $unmanagedHoldingIdsForMergedSlices
        ) {
          ... on UnmanagedHoldingsStagedForLiquidateConfirmation {
            title
            sectionHeader
            sectionMessage
            trading {
              nextTradingTime
            }
            holdings {
              label
              sublabel
              quantitySection {
                label
                value
              }
            }
            primaryButtonLabel
            secondaryButtonLabel
            accountDetails {
              header
              subheader
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useLiquidateModalContainerQuery__
 *
 * To run a query within a React component, call `useLiquidateModalContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiquidateModalContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiquidateModalContainerQuery({
 *   variables: {
 *      unmanagedHoldingIdsForMergedSlices: // value for 'unmanagedHoldingIdsForMergedSlices'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useLiquidateModalContainerQuery(baseOptions: Apollo.QueryHookOptions<Types.LiquidateModalContainerQuery, Types.LiquidateModalContainerQueryVariables> & ({ variables: Types.LiquidateModalContainerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LiquidateModalContainerQuery, Types.LiquidateModalContainerQueryVariables>(LiquidateModalContainerDocument, options);
      }
export function useLiquidateModalContainerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LiquidateModalContainerQuery, Types.LiquidateModalContainerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LiquidateModalContainerQuery, Types.LiquidateModalContainerQueryVariables>(LiquidateModalContainerDocument, options);
        }
export function useLiquidateModalContainerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LiquidateModalContainerQuery, Types.LiquidateModalContainerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LiquidateModalContainerQuery, Types.LiquidateModalContainerQueryVariables>(LiquidateModalContainerDocument, options);
        }
export type LiquidateModalContainerQueryHookResult = ReturnType<typeof useLiquidateModalContainerQuery>;
export type LiquidateModalContainerLazyQueryHookResult = ReturnType<typeof useLiquidateModalContainerLazyQuery>;
export type LiquidateModalContainerSuspenseQueryHookResult = ReturnType<typeof useLiquidateModalContainerSuspenseQuery>;
export type LiquidateModalContainerQueryResult = Apollo.QueryResult<Types.LiquidateModalContainerQuery, Types.LiquidateModalContainerQueryVariables>;
export const OnDemandPortfolioTradingInProgressPageDocument = gql`
    query OnDemandPortfolioTradingInProgressPage($accountId: ID!, $tradeWindowId: ID!) {
  node(id: $accountId) {
    ... on Account {
      trading {
        onDemandAllocatedTrades(tradeWindowId: $tradeWindowId) {
          ...OnDemandTrade
        }
      }
    }
  }
}
    ${OnDemandTradeFragmentDoc}`;

/**
 * __useOnDemandPortfolioTradingInProgressPageQuery__
 *
 * To run a query within a React component, call `useOnDemandPortfolioTradingInProgressPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnDemandPortfolioTradingInProgressPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDemandPortfolioTradingInProgressPageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      tradeWindowId: // value for 'tradeWindowId'
 *   },
 * });
 */
export function useOnDemandPortfolioTradingInProgressPageQuery(baseOptions: Apollo.QueryHookOptions<Types.OnDemandPortfolioTradingInProgressPageQuery, Types.OnDemandPortfolioTradingInProgressPageQueryVariables> & ({ variables: Types.OnDemandPortfolioTradingInProgressPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OnDemandPortfolioTradingInProgressPageQuery, Types.OnDemandPortfolioTradingInProgressPageQueryVariables>(OnDemandPortfolioTradingInProgressPageDocument, options);
      }
export function useOnDemandPortfolioTradingInProgressPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OnDemandPortfolioTradingInProgressPageQuery, Types.OnDemandPortfolioTradingInProgressPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OnDemandPortfolioTradingInProgressPageQuery, Types.OnDemandPortfolioTradingInProgressPageQueryVariables>(OnDemandPortfolioTradingInProgressPageDocument, options);
        }
export function useOnDemandPortfolioTradingInProgressPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.OnDemandPortfolioTradingInProgressPageQuery, Types.OnDemandPortfolioTradingInProgressPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.OnDemandPortfolioTradingInProgressPageQuery, Types.OnDemandPortfolioTradingInProgressPageQueryVariables>(OnDemandPortfolioTradingInProgressPageDocument, options);
        }
export type OnDemandPortfolioTradingInProgressPageQueryHookResult = ReturnType<typeof useOnDemandPortfolioTradingInProgressPageQuery>;
export type OnDemandPortfolioTradingInProgressPageLazyQueryHookResult = ReturnType<typeof useOnDemandPortfolioTradingInProgressPageLazyQuery>;
export type OnDemandPortfolioTradingInProgressPageSuspenseQueryHookResult = ReturnType<typeof useOnDemandPortfolioTradingInProgressPageSuspenseQuery>;
export type OnDemandPortfolioTradingInProgressPageQueryResult = Apollo.QueryResult<Types.OnDemandPortfolioTradingInProgressPageQuery, Types.OnDemandPortfolioTradingInProgressPageQueryVariables>;
export const AddContraEntryDocument = gql`
    query AddContraEntry($newSmartTransferContraParticipantEntryOptionId: ID!, $contraParticipantId: ID!) {
  node(id: $newSmartTransferContraParticipantEntryOptionId) {
    ... on NewSmartTransferContraParticipantEntryOption {
      contraParticipants {
        list {
          id
          ...TransferParticipant
        }
      }
      editRequirements(contraParticipantId: $contraParticipantId) {
        contraParticipantLabel
        fulfillmentConditionOptionEmptyTitle
        fulfillmentConditionOptionEmptyDescription
        fulfillmentConditionOptionSetListingPreamble
        fulfillmentConditionOptionSet {
          ...SmartTransferFulfillmentConditionOptionSet
        }
        id
        title
      }
      id
    }
  }
}
    ${TransferParticipantFragmentDoc}
${SmartTransferFulfillmentConditionOptionSetFragmentDoc}`;

/**
 * __useAddContraEntryQuery__
 *
 * To run a query within a React component, call `useAddContraEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddContraEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddContraEntryQuery({
 *   variables: {
 *      newSmartTransferContraParticipantEntryOptionId: // value for 'newSmartTransferContraParticipantEntryOptionId'
 *      contraParticipantId: // value for 'contraParticipantId'
 *   },
 * });
 */
export function useAddContraEntryQuery(baseOptions: Apollo.QueryHookOptions<Types.AddContraEntryQuery, Types.AddContraEntryQueryVariables> & ({ variables: Types.AddContraEntryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AddContraEntryQuery, Types.AddContraEntryQueryVariables>(AddContraEntryDocument, options);
      }
export function useAddContraEntryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AddContraEntryQuery, Types.AddContraEntryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AddContraEntryQuery, Types.AddContraEntryQueryVariables>(AddContraEntryDocument, options);
        }
export function useAddContraEntrySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AddContraEntryQuery, Types.AddContraEntryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AddContraEntryQuery, Types.AddContraEntryQueryVariables>(AddContraEntryDocument, options);
        }
export type AddContraEntryQueryHookResult = ReturnType<typeof useAddContraEntryQuery>;
export type AddContraEntryLazyQueryHookResult = ReturnType<typeof useAddContraEntryLazyQuery>;
export type AddContraEntrySuspenseQueryHookResult = ReturnType<typeof useAddContraEntrySuspenseQuery>;
export type AddContraEntryQueryResult = Apollo.QueryResult<Types.AddContraEntryQuery, Types.AddContraEntryQueryVariables>;
export const AvailableFundingSourcesPageDocument = gql`
    query AvailableFundingSourcesPage {
  viewer {
    fundingSources {
      fundingSourcesGroup(first: 100) {
        edges {
          node {
            ...FundingSourceNode
          }
        }
      }
    }
  }
}
    ${FundingSourceNodeFragmentDoc}`;

/**
 * __useAvailableFundingSourcesPageQuery__
 *
 * To run a query within a React component, call `useAvailableFundingSourcesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableFundingSourcesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableFundingSourcesPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableFundingSourcesPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.AvailableFundingSourcesPageQuery, Types.AvailableFundingSourcesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AvailableFundingSourcesPageQuery, Types.AvailableFundingSourcesPageQueryVariables>(AvailableFundingSourcesPageDocument, options);
      }
export function useAvailableFundingSourcesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AvailableFundingSourcesPageQuery, Types.AvailableFundingSourcesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AvailableFundingSourcesPageQuery, Types.AvailableFundingSourcesPageQueryVariables>(AvailableFundingSourcesPageDocument, options);
        }
export function useAvailableFundingSourcesPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.AvailableFundingSourcesPageQuery, Types.AvailableFundingSourcesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.AvailableFundingSourcesPageQuery, Types.AvailableFundingSourcesPageQueryVariables>(AvailableFundingSourcesPageDocument, options);
        }
export type AvailableFundingSourcesPageQueryHookResult = ReturnType<typeof useAvailableFundingSourcesPageQuery>;
export type AvailableFundingSourcesPageLazyQueryHookResult = ReturnType<typeof useAvailableFundingSourcesPageLazyQuery>;
export type AvailableFundingSourcesPageSuspenseQueryHookResult = ReturnType<typeof useAvailableFundingSourcesPageSuspenseQuery>;
export type AvailableFundingSourcesPageQueryResult = Apollo.QueryResult<Types.AvailableFundingSourcesPageQuery, Types.AvailableFundingSourcesPageQueryVariables>;
export const CalculateNiaDocument = gql`
    query CalculateNia {
  viewer {
    transfers {
      niaLearnMore {
        ...Linkable
      }
    }
  }
}
    ${LinkableFragmentDoc}`;

/**
 * __useCalculateNiaQuery__
 *
 * To run a query within a React component, call `useCalculateNiaQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateNiaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateNiaQuery({
 *   variables: {
 *   },
 * });
 */
export function useCalculateNiaQuery(baseOptions?: Apollo.QueryHookOptions<Types.CalculateNiaQuery, Types.CalculateNiaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CalculateNiaQuery, Types.CalculateNiaQueryVariables>(CalculateNiaDocument, options);
      }
export function useCalculateNiaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CalculateNiaQuery, Types.CalculateNiaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CalculateNiaQuery, Types.CalculateNiaQueryVariables>(CalculateNiaDocument, options);
        }
export function useCalculateNiaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CalculateNiaQuery, Types.CalculateNiaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CalculateNiaQuery, Types.CalculateNiaQueryVariables>(CalculateNiaDocument, options);
        }
export type CalculateNiaQueryHookResult = ReturnType<typeof useCalculateNiaQuery>;
export type CalculateNiaLazyQueryHookResult = ReturnType<typeof useCalculateNiaLazyQuery>;
export type CalculateNiaSuspenseQueryHookResult = ReturnType<typeof useCalculateNiaSuspenseQuery>;
export type CalculateNiaQueryResult = Apollo.QueryResult<Types.CalculateNiaQuery, Types.CalculateNiaQueryVariables>;
export const ConfirmSmartTransferDocument = gql`
    query ConfirmSmartTransfer($focusOptionId: ID!, $focusAccountId: ID!) {
  node(id: $focusOptionId) {
    ... on NewSmartTransferFocusOption {
      description
      editRequirements(focusParticipantId: $focusAccountId) {
        id
        focusParticipant {
          transferParticipantName
        }
      }
    }
  }
}
    `;

/**
 * __useConfirmSmartTransferQuery__
 *
 * To run a query within a React component, call `useConfirmSmartTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmSmartTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmSmartTransferQuery({
 *   variables: {
 *      focusOptionId: // value for 'focusOptionId'
 *      focusAccountId: // value for 'focusAccountId'
 *   },
 * });
 */
export function useConfirmSmartTransferQuery(baseOptions: Apollo.QueryHookOptions<Types.ConfirmSmartTransferQuery, Types.ConfirmSmartTransferQueryVariables> & ({ variables: Types.ConfirmSmartTransferQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConfirmSmartTransferQuery, Types.ConfirmSmartTransferQueryVariables>(ConfirmSmartTransferDocument, options);
      }
export function useConfirmSmartTransferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConfirmSmartTransferQuery, Types.ConfirmSmartTransferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConfirmSmartTransferQuery, Types.ConfirmSmartTransferQueryVariables>(ConfirmSmartTransferDocument, options);
        }
export function useConfirmSmartTransferSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ConfirmSmartTransferQuery, Types.ConfirmSmartTransferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ConfirmSmartTransferQuery, Types.ConfirmSmartTransferQueryVariables>(ConfirmSmartTransferDocument, options);
        }
export type ConfirmSmartTransferQueryHookResult = ReturnType<typeof useConfirmSmartTransferQuery>;
export type ConfirmSmartTransferLazyQueryHookResult = ReturnType<typeof useConfirmSmartTransferLazyQuery>;
export type ConfirmSmartTransferSuspenseQueryHookResult = ReturnType<typeof useConfirmSmartTransferSuspenseQuery>;
export type ConfirmSmartTransferQueryResult = Apollo.QueryResult<Types.ConfirmSmartTransferQuery, Types.ConfirmSmartTransferQueryVariables>;
export const EditContraEntryDocument = gql`
    query EditContraEntry($editRequirementsId: ID!) {
  node(id: $editRequirementsId) {
    ... on SmartTransferContraParticipantEntryEditRequirements {
      contraParticipantLabel
      fulfillmentConditionOptionSetListingPreamble
      fulfillmentConditionOptionSet {
        ...SmartTransferFulfillmentConditionOptionSet
      }
      id
      title
    }
  }
}
    ${SmartTransferFulfillmentConditionOptionSetFragmentDoc}`;

/**
 * __useEditContraEntryQuery__
 *
 * To run a query within a React component, call `useEditContraEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditContraEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditContraEntryQuery({
 *   variables: {
 *      editRequirementsId: // value for 'editRequirementsId'
 *   },
 * });
 */
export function useEditContraEntryQuery(baseOptions: Apollo.QueryHookOptions<Types.EditContraEntryQuery, Types.EditContraEntryQueryVariables> & ({ variables: Types.EditContraEntryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EditContraEntryQuery, Types.EditContraEntryQueryVariables>(EditContraEntryDocument, options);
      }
export function useEditContraEntryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EditContraEntryQuery, Types.EditContraEntryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EditContraEntryQuery, Types.EditContraEntryQueryVariables>(EditContraEntryDocument, options);
        }
export function useEditContraEntrySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EditContraEntryQuery, Types.EditContraEntryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EditContraEntryQuery, Types.EditContraEntryQueryVariables>(EditContraEntryDocument, options);
        }
export type EditContraEntryQueryHookResult = ReturnType<typeof useEditContraEntryQuery>;
export type EditContraEntryLazyQueryHookResult = ReturnType<typeof useEditContraEntryLazyQuery>;
export type EditContraEntrySuspenseQueryHookResult = ReturnType<typeof useEditContraEntrySuspenseQuery>;
export type EditContraEntryQueryResult = Apollo.QueryResult<Types.EditContraEntryQuery, Types.EditContraEntryQueryVariables>;
export const EditSmartTransferPagePrefetchDocument = gql`
    query EditSmartTransferPagePrefetch($smartTransferRuleId: ID!) {
  node(id: $smartTransferRuleId) {
    ... on SmartTransferRule {
      contraParticipantEntries {
        id
        ...SmartTransferFulfillmentCondition
      }
    }
  }
}
    ${SmartTransferFulfillmentConditionFragmentDoc}`;

/**
 * __useEditSmartTransferPagePrefetchQuery__
 *
 * To run a query within a React component, call `useEditSmartTransferPagePrefetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditSmartTransferPagePrefetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditSmartTransferPagePrefetchQuery({
 *   variables: {
 *      smartTransferRuleId: // value for 'smartTransferRuleId'
 *   },
 * });
 */
export function useEditSmartTransferPagePrefetchQuery(baseOptions: Apollo.QueryHookOptions<Types.EditSmartTransferPagePrefetchQuery, Types.EditSmartTransferPagePrefetchQueryVariables> & ({ variables: Types.EditSmartTransferPagePrefetchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EditSmartTransferPagePrefetchQuery, Types.EditSmartTransferPagePrefetchQueryVariables>(EditSmartTransferPagePrefetchDocument, options);
      }
export function useEditSmartTransferPagePrefetchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EditSmartTransferPagePrefetchQuery, Types.EditSmartTransferPagePrefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EditSmartTransferPagePrefetchQuery, Types.EditSmartTransferPagePrefetchQueryVariables>(EditSmartTransferPagePrefetchDocument, options);
        }
export function useEditSmartTransferPagePrefetchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EditSmartTransferPagePrefetchQuery, Types.EditSmartTransferPagePrefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EditSmartTransferPagePrefetchQuery, Types.EditSmartTransferPagePrefetchQueryVariables>(EditSmartTransferPagePrefetchDocument, options);
        }
export type EditSmartTransferPagePrefetchQueryHookResult = ReturnType<typeof useEditSmartTransferPagePrefetchQuery>;
export type EditSmartTransferPagePrefetchLazyQueryHookResult = ReturnType<typeof useEditSmartTransferPagePrefetchLazyQuery>;
export type EditSmartTransferPagePrefetchSuspenseQueryHookResult = ReturnType<typeof useEditSmartTransferPagePrefetchSuspenseQuery>;
export type EditSmartTransferPagePrefetchQueryResult = Apollo.QueryResult<Types.EditSmartTransferPagePrefetchQuery, Types.EditSmartTransferPagePrefetchQueryVariables>;
export const EditSmartTransferPageDocument = gql`
    query EditSmartTransferPage($contraParticipantEntries: [SmartTransferContraParticipantEntryInput!]!, $insertionContext: SmartTransferContraParticipantEntryInsertionContextInput, $smartTransferRuleId: ID!) {
  node(id: $smartTransferRuleId) {
    ... on SmartTransferRule {
      id
      balanceTrigger {
        balanceThreshold
        balanceTriggerType
        underBalanceRefillTarget
      }
      contraParticipantEntries {
        id
        contraParticipant {
          id
        }
        editRequirements {
          id
        }
        ...SmartTransferFulfillmentCondition
      }
      editRequirements {
        balanceThresholdPreamble
        balanceTriggerEditRequirements {
          ... on SmartTransferBalanceTriggerEditRequirements {
            minBalanceTriggerThreshold
            maxBalanceTriggerThreshold
          }
        }
        contraParticipantEntryAdditionLabel
        contraParticipantListPreamble
        id
        newContraParticipantEntryOptionSet(insertionContext: $insertionContext) {
          ... on NewSmartTransferContraParticipantEntryOptionSet {
            options {
              id
              name
              description
            }
          }
        }
        shouldShowContraParticipantEntryReordering(
          contraParticipantEntries: $contraParticipantEntries
        )
        ... on SmartTransferRuleEditRequirements {
          id
          contraParticipantEntryAdditionLabel
          shouldShowContraParticipantEntryAddition(
            contraParticipantEntries: $contraParticipantEntries
          )
        }
      }
      focusParticipant {
        id
        ...TransferParticipant
      }
    }
  }
}
    ${SmartTransferFulfillmentConditionFragmentDoc}
${TransferParticipantFragmentDoc}`;

/**
 * __useEditSmartTransferPageQuery__
 *
 * To run a query within a React component, call `useEditSmartTransferPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditSmartTransferPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditSmartTransferPageQuery({
 *   variables: {
 *      contraParticipantEntries: // value for 'contraParticipantEntries'
 *      insertionContext: // value for 'insertionContext'
 *      smartTransferRuleId: // value for 'smartTransferRuleId'
 *   },
 * });
 */
export function useEditSmartTransferPageQuery(baseOptions: Apollo.QueryHookOptions<Types.EditSmartTransferPageQuery, Types.EditSmartTransferPageQueryVariables> & ({ variables: Types.EditSmartTransferPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EditSmartTransferPageQuery, Types.EditSmartTransferPageQueryVariables>(EditSmartTransferPageDocument, options);
      }
export function useEditSmartTransferPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EditSmartTransferPageQuery, Types.EditSmartTransferPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EditSmartTransferPageQuery, Types.EditSmartTransferPageQueryVariables>(EditSmartTransferPageDocument, options);
        }
export function useEditSmartTransferPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.EditSmartTransferPageQuery, Types.EditSmartTransferPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.EditSmartTransferPageQuery, Types.EditSmartTransferPageQueryVariables>(EditSmartTransferPageDocument, options);
        }
export type EditSmartTransferPageQueryHookResult = ReturnType<typeof useEditSmartTransferPageQuery>;
export type EditSmartTransferPageLazyQueryHookResult = ReturnType<typeof useEditSmartTransferPageLazyQuery>;
export type EditSmartTransferPageSuspenseQueryHookResult = ReturnType<typeof useEditSmartTransferPageSuspenseQuery>;
export type EditSmartTransferPageQueryResult = Apollo.QueryResult<Types.EditSmartTransferPageQuery, Types.EditSmartTransferPageQueryVariables>;
export const GetTransferRuleDocument = gql`
    query GetTransferRule($id: ID!) {
  node(id: $id) {
    ...TransferRuleDetail
  }
}
    ${TransferRuleDetailFragmentDoc}`;

/**
 * __useGetTransferRuleQuery__
 *
 * To run a query within a React component, call `useGetTransferRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransferRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransferRuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTransferRuleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTransferRuleQuery, Types.GetTransferRuleQueryVariables> & ({ variables: Types.GetTransferRuleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTransferRuleQuery, Types.GetTransferRuleQueryVariables>(GetTransferRuleDocument, options);
      }
export function useGetTransferRuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTransferRuleQuery, Types.GetTransferRuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTransferRuleQuery, Types.GetTransferRuleQueryVariables>(GetTransferRuleDocument, options);
        }
export function useGetTransferRuleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetTransferRuleQuery, Types.GetTransferRuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetTransferRuleQuery, Types.GetTransferRuleQueryVariables>(GetTransferRuleDocument, options);
        }
export type GetTransferRuleQueryHookResult = ReturnType<typeof useGetTransferRuleQuery>;
export type GetTransferRuleLazyQueryHookResult = ReturnType<typeof useGetTransferRuleLazyQuery>;
export type GetTransferRuleSuspenseQueryHookResult = ReturnType<typeof useGetTransferRuleSuspenseQuery>;
export type GetTransferRuleQueryResult = Apollo.QueryResult<Types.GetTransferRuleQuery, Types.GetTransferRuleQueryVariables>;
export const InitialFundingDepositConfirmationDocument = gql`
    query InitialFundingDepositConfirmation($transferId: ID!) {
  viewer {
    transfers {
      initialFunding {
        initialFundingScreens {
          initialFundingConfirmationScreen(transferId: $transferId) {
            ...AppInfoPage
          }
        }
      }
    }
  }
}
    ${AppInfoPageFragmentDoc}`;

/**
 * __useInitialFundingDepositConfirmationQuery__
 *
 * To run a query within a React component, call `useInitialFundingDepositConfirmationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitialFundingDepositConfirmationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitialFundingDepositConfirmationQuery({
 *   variables: {
 *      transferId: // value for 'transferId'
 *   },
 * });
 */
export function useInitialFundingDepositConfirmationQuery(baseOptions: Apollo.QueryHookOptions<Types.InitialFundingDepositConfirmationQuery, Types.InitialFundingDepositConfirmationQueryVariables> & ({ variables: Types.InitialFundingDepositConfirmationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InitialFundingDepositConfirmationQuery, Types.InitialFundingDepositConfirmationQueryVariables>(InitialFundingDepositConfirmationDocument, options);
      }
export function useInitialFundingDepositConfirmationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InitialFundingDepositConfirmationQuery, Types.InitialFundingDepositConfirmationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InitialFundingDepositConfirmationQuery, Types.InitialFundingDepositConfirmationQueryVariables>(InitialFundingDepositConfirmationDocument, options);
        }
export function useInitialFundingDepositConfirmationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.InitialFundingDepositConfirmationQuery, Types.InitialFundingDepositConfirmationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.InitialFundingDepositConfirmationQuery, Types.InitialFundingDepositConfirmationQueryVariables>(InitialFundingDepositConfirmationDocument, options);
        }
export type InitialFundingDepositConfirmationQueryHookResult = ReturnType<typeof useInitialFundingDepositConfirmationQuery>;
export type InitialFundingDepositConfirmationLazyQueryHookResult = ReturnType<typeof useInitialFundingDepositConfirmationLazyQuery>;
export type InitialFundingDepositConfirmationSuspenseQueryHookResult = ReturnType<typeof useInitialFundingDepositConfirmationSuspenseQuery>;
export type InitialFundingDepositConfirmationQueryResult = Apollo.QueryResult<Types.InitialFundingDepositConfirmationQuery, Types.InitialFundingDepositConfirmationQueryVariables>;
export const InitializeSmartTransferDocument = gql`
    query InitializeSmartTransfer {
  viewer {
    transfers {
      isReadyForSmartTransfers
      newSmartTransferFocusOptionSet {
        ...NewSmartTransferFocusOptionSet
      }
    }
    user {
      ...UserKeys
    }
  }
}
    ${NewSmartTransferFocusOptionSetFragmentDoc}
${UserKeysFragmentDoc}`;

/**
 * __useInitializeSmartTransferQuery__
 *
 * To run a query within a React component, call `useInitializeSmartTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitializeSmartTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitializeSmartTransferQuery({
 *   variables: {
 *   },
 * });
 */
export function useInitializeSmartTransferQuery(baseOptions?: Apollo.QueryHookOptions<Types.InitializeSmartTransferQuery, Types.InitializeSmartTransferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InitializeSmartTransferQuery, Types.InitializeSmartTransferQueryVariables>(InitializeSmartTransferDocument, options);
      }
export function useInitializeSmartTransferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InitializeSmartTransferQuery, Types.InitializeSmartTransferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InitializeSmartTransferQuery, Types.InitializeSmartTransferQueryVariables>(InitializeSmartTransferDocument, options);
        }
export function useInitializeSmartTransferSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.InitializeSmartTransferQuery, Types.InitializeSmartTransferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.InitializeSmartTransferQuery, Types.InitializeSmartTransferQueryVariables>(InitializeSmartTransferDocument, options);
        }
export type InitializeSmartTransferQueryHookResult = ReturnType<typeof useInitializeSmartTransferQuery>;
export type InitializeSmartTransferLazyQueryHookResult = ReturnType<typeof useInitializeSmartTransferLazyQuery>;
export type InitializeSmartTransferSuspenseQueryHookResult = ReturnType<typeof useInitializeSmartTransferSuspenseQuery>;
export type InitializeSmartTransferQueryResult = Apollo.QueryResult<Types.InitializeSmartTransferQuery, Types.InitializeSmartTransferQueryVariables>;
export const MoveMoneyAnnouncementDocument = gql`
    query MoveMoneyAnnouncement {
  viewer {
    announcements {
      forMoveMoney {
        ...Announcement
      }
    }
  }
}
    ${AnnouncementFragmentDoc}`;

/**
 * __useMoveMoneyAnnouncementQuery__
 *
 * To run a query within a React component, call `useMoveMoneyAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoveMoneyAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoveMoneyAnnouncementQuery({
 *   variables: {
 *   },
 * });
 */
export function useMoveMoneyAnnouncementQuery(baseOptions?: Apollo.QueryHookOptions<Types.MoveMoneyAnnouncementQuery, Types.MoveMoneyAnnouncementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MoveMoneyAnnouncementQuery, Types.MoveMoneyAnnouncementQueryVariables>(MoveMoneyAnnouncementDocument, options);
      }
export function useMoveMoneyAnnouncementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MoveMoneyAnnouncementQuery, Types.MoveMoneyAnnouncementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MoveMoneyAnnouncementQuery, Types.MoveMoneyAnnouncementQueryVariables>(MoveMoneyAnnouncementDocument, options);
        }
export function useMoveMoneyAnnouncementSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MoveMoneyAnnouncementQuery, Types.MoveMoneyAnnouncementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MoveMoneyAnnouncementQuery, Types.MoveMoneyAnnouncementQueryVariables>(MoveMoneyAnnouncementDocument, options);
        }
export type MoveMoneyAnnouncementQueryHookResult = ReturnType<typeof useMoveMoneyAnnouncementQuery>;
export type MoveMoneyAnnouncementLazyQueryHookResult = ReturnType<typeof useMoveMoneyAnnouncementLazyQuery>;
export type MoveMoneyAnnouncementSuspenseQueryHookResult = ReturnType<typeof useMoveMoneyAnnouncementSuspenseQuery>;
export type MoveMoneyAnnouncementQueryResult = Apollo.QueryResult<Types.MoveMoneyAnnouncementQuery, Types.MoveMoneyAnnouncementQueryVariables>;
export const NiaReportDocument = gql`
    query NiaReport {
  viewer {
    transfers {
      niaWithholdingDisclaimer {
        text
      }
      niaLearnMore {
        ...Linkable
      }
      niaDisclaimer {
        ...NiaDisclaimer
      }
    }
  }
}
    ${LinkableFragmentDoc}
${NiaDisclaimerFragmentDoc}`;

/**
 * __useNiaReportQuery__
 *
 * To run a query within a React component, call `useNiaReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useNiaReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNiaReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useNiaReportQuery(baseOptions?: Apollo.QueryHookOptions<Types.NiaReportQuery, Types.NiaReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NiaReportQuery, Types.NiaReportQueryVariables>(NiaReportDocument, options);
      }
export function useNiaReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NiaReportQuery, Types.NiaReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NiaReportQuery, Types.NiaReportQueryVariables>(NiaReportDocument, options);
        }
export function useNiaReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.NiaReportQuery, Types.NiaReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.NiaReportQuery, Types.NiaReportQueryVariables>(NiaReportDocument, options);
        }
export type NiaReportQueryHookResult = ReturnType<typeof useNiaReportQuery>;
export type NiaReportLazyQueryHookResult = ReturnType<typeof useNiaReportLazyQuery>;
export type NiaReportSuspenseQueryHookResult = ReturnType<typeof useNiaReportSuspenseQuery>;
export type NiaReportQueryResult = Apollo.QueryResult<Types.NiaReportQuery, Types.NiaReportQueryVariables>;
export const PageEditTransferScheduleDocument = gql`
    query PageEditTransferSchedule($id: ID!) {
  node(id: $id) {
    ... on ScheduledTransferRule {
      isCreditCardAutoPay
      isPersonalLoanAutoPay
    }
  }
}
    `;

/**
 * __usePageEditTransferScheduleQuery__
 *
 * To run a query within a React component, call `usePageEditTransferScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageEditTransferScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageEditTransferScheduleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePageEditTransferScheduleQuery(baseOptions: Apollo.QueryHookOptions<Types.PageEditTransferScheduleQuery, Types.PageEditTransferScheduleQueryVariables> & ({ variables: Types.PageEditTransferScheduleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PageEditTransferScheduleQuery, Types.PageEditTransferScheduleQueryVariables>(PageEditTransferScheduleDocument, options);
      }
export function usePageEditTransferScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PageEditTransferScheduleQuery, Types.PageEditTransferScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PageEditTransferScheduleQuery, Types.PageEditTransferScheduleQueryVariables>(PageEditTransferScheduleDocument, options);
        }
export function usePageEditTransferScheduleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PageEditTransferScheduleQuery, Types.PageEditTransferScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PageEditTransferScheduleQuery, Types.PageEditTransferScheduleQueryVariables>(PageEditTransferScheduleDocument, options);
        }
export type PageEditTransferScheduleQueryHookResult = ReturnType<typeof usePageEditTransferScheduleQuery>;
export type PageEditTransferScheduleLazyQueryHookResult = ReturnType<typeof usePageEditTransferScheduleLazyQuery>;
export type PageEditTransferScheduleSuspenseQueryHookResult = ReturnType<typeof usePageEditTransferScheduleSuspenseQuery>;
export type PageEditTransferScheduleQueryResult = Apollo.QueryResult<Types.PageEditTransferScheduleQuery, Types.PageEditTransferScheduleQueryVariables>;
export const PendingLiquidationPageDocument = gql`
    query PendingLiquidationPage($unmanagedHoldingId: ID!) {
  node(id: $unmanagedHoldingId) {
    ...PendingLiquidationUnmanagedHolding
  }
}
    ${PendingLiquidationUnmanagedHoldingFragmentDoc}`;

/**
 * __usePendingLiquidationPageQuery__
 *
 * To run a query within a React component, call `usePendingLiquidationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingLiquidationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingLiquidationPageQuery({
 *   variables: {
 *      unmanagedHoldingId: // value for 'unmanagedHoldingId'
 *   },
 * });
 */
export function usePendingLiquidationPageQuery(baseOptions: Apollo.QueryHookOptions<Types.PendingLiquidationPageQuery, Types.PendingLiquidationPageQueryVariables> & ({ variables: Types.PendingLiquidationPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PendingLiquidationPageQuery, Types.PendingLiquidationPageQueryVariables>(PendingLiquidationPageDocument, options);
      }
export function usePendingLiquidationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PendingLiquidationPageQuery, Types.PendingLiquidationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PendingLiquidationPageQuery, Types.PendingLiquidationPageQueryVariables>(PendingLiquidationPageDocument, options);
        }
export function usePendingLiquidationPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.PendingLiquidationPageQuery, Types.PendingLiquidationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.PendingLiquidationPageQuery, Types.PendingLiquidationPageQueryVariables>(PendingLiquidationPageDocument, options);
        }
export type PendingLiquidationPageQueryHookResult = ReturnType<typeof usePendingLiquidationPageQuery>;
export type PendingLiquidationPageLazyQueryHookResult = ReturnType<typeof usePendingLiquidationPageLazyQuery>;
export type PendingLiquidationPageSuspenseQueryHookResult = ReturnType<typeof usePendingLiquidationPageSuspenseQuery>;
export type PendingLiquidationPageQueryResult = Apollo.QueryResult<Types.PendingLiquidationPageQuery, Types.PendingLiquidationPageQueryVariables>;
export const RolloverTransactionsDocument = gql`
    query RolloverTransactions {
  viewer {
    transfers {
      rollovers {
        rolloverTransactions {
          transactionsWebviewUrl
        }
      }
    }
  }
}
    `;

/**
 * __useRolloverTransactionsQuery__
 *
 * To run a query within a React component, call `useRolloverTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolloverTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolloverTransactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolloverTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.RolloverTransactionsQuery, Types.RolloverTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RolloverTransactionsQuery, Types.RolloverTransactionsQueryVariables>(RolloverTransactionsDocument, options);
      }
export function useRolloverTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RolloverTransactionsQuery, Types.RolloverTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RolloverTransactionsQuery, Types.RolloverTransactionsQueryVariables>(RolloverTransactionsDocument, options);
        }
export function useRolloverTransactionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RolloverTransactionsQuery, Types.RolloverTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RolloverTransactionsQuery, Types.RolloverTransactionsQueryVariables>(RolloverTransactionsDocument, options);
        }
export type RolloverTransactionsQueryHookResult = ReturnType<typeof useRolloverTransactionsQuery>;
export type RolloverTransactionsLazyQueryHookResult = ReturnType<typeof useRolloverTransactionsLazyQuery>;
export type RolloverTransactionsSuspenseQueryHookResult = ReturnType<typeof useRolloverTransactionsSuspenseQuery>;
export type RolloverTransactionsQueryResult = Apollo.QueryResult<Types.RolloverTransactionsQuery, Types.RolloverTransactionsQueryVariables>;
export const GetScheduledTransferRuleDocument = gql`
    query GetScheduledTransferRule($scheduledTransferRuleId: ID!) {
  scheduledTransferRule: node(id: $scheduledTransferRuleId) {
    ...ScheduledTransferRuleNode
  }
}
    ${ScheduledTransferRuleNodeFragmentDoc}`;

/**
 * __useGetScheduledTransferRuleQuery__
 *
 * To run a query within a React component, call `useGetScheduledTransferRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledTransferRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledTransferRuleQuery({
 *   variables: {
 *      scheduledTransferRuleId: // value for 'scheduledTransferRuleId'
 *   },
 * });
 */
export function useGetScheduledTransferRuleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScheduledTransferRuleQuery, Types.GetScheduledTransferRuleQueryVariables> & ({ variables: Types.GetScheduledTransferRuleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScheduledTransferRuleQuery, Types.GetScheduledTransferRuleQueryVariables>(GetScheduledTransferRuleDocument, options);
      }
export function useGetScheduledTransferRuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScheduledTransferRuleQuery, Types.GetScheduledTransferRuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScheduledTransferRuleQuery, Types.GetScheduledTransferRuleQueryVariables>(GetScheduledTransferRuleDocument, options);
        }
export function useGetScheduledTransferRuleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetScheduledTransferRuleQuery, Types.GetScheduledTransferRuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetScheduledTransferRuleQuery, Types.GetScheduledTransferRuleQueryVariables>(GetScheduledTransferRuleDocument, options);
        }
export type GetScheduledTransferRuleQueryHookResult = ReturnType<typeof useGetScheduledTransferRuleQuery>;
export type GetScheduledTransferRuleLazyQueryHookResult = ReturnType<typeof useGetScheduledTransferRuleLazyQuery>;
export type GetScheduledTransferRuleSuspenseQueryHookResult = ReturnType<typeof useGetScheduledTransferRuleSuspenseQuery>;
export type GetScheduledTransferRuleQueryResult = Apollo.QueryResult<Types.GetScheduledTransferRuleQuery, Types.GetScheduledTransferRuleQueryVariables>;
export const GetTransferTimingDocument = gql`
    query GetTransferTiming($transferType: TransferTypeEnum, $amount: Float, $fromParticipantId: ID, $toParticipantId: ID) {
  viewer {
    id
    transfers {
      requirements(
        fromParticipantId: $fromParticipantId
        toParticipantId: $toParticipantId
        transferType: $transferType
        amount: $amount
      ) {
        detailsMessage {
          ...TransferDetailsMessageCard
        }
        ...TransferRequirementsTimingDescriptionCell
        minTransferAmount
        minTransferAmountErrorMessage
        maxTransferAmount
        maxTransferAmountErrorMessage
        maxTransferAmountErrorLink {
          ...Linkable
        }
        isIraContributionYearRequired
        isIraDistributionReasonRequired
        isIraRolloverOptionRequired
        transferAmountPresets {
          label
          value
          description
          __typename
        }
        scheduledTransferAmountPresets {
          label
          value
          indicator
          __typename
        }
        autoPayInformationContent
        autoPayDueDateMessage
        transferOverviewLink {
          ...Linkable
        }
      }
    }
  }
}
    ${TransferDetailsMessageCardFragmentDoc}
${TransferRequirementsTimingDescriptionCellFragmentDoc}
${LinkableFragmentDoc}`;

/**
 * __useGetTransferTimingQuery__
 *
 * To run a query within a React component, call `useGetTransferTimingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransferTimingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransferTimingQuery({
 *   variables: {
 *      transferType: // value for 'transferType'
 *      amount: // value for 'amount'
 *      fromParticipantId: // value for 'fromParticipantId'
 *      toParticipantId: // value for 'toParticipantId'
 *   },
 * });
 */
export function useGetTransferTimingQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTransferTimingQuery, Types.GetTransferTimingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTransferTimingQuery, Types.GetTransferTimingQueryVariables>(GetTransferTimingDocument, options);
      }
export function useGetTransferTimingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTransferTimingQuery, Types.GetTransferTimingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTransferTimingQuery, Types.GetTransferTimingQueryVariables>(GetTransferTimingDocument, options);
        }
export function useGetTransferTimingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetTransferTimingQuery, Types.GetTransferTimingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetTransferTimingQuery, Types.GetTransferTimingQueryVariables>(GetTransferTimingDocument, options);
        }
export type GetTransferTimingQueryHookResult = ReturnType<typeof useGetTransferTimingQuery>;
export type GetTransferTimingLazyQueryHookResult = ReturnType<typeof useGetTransferTimingLazyQuery>;
export type GetTransferTimingSuspenseQueryHookResult = ReturnType<typeof useGetTransferTimingSuspenseQuery>;
export type GetTransferTimingQueryResult = Apollo.QueryResult<Types.GetTransferTimingQuery, Types.GetTransferTimingQueryVariables>;
export const GetTransferFormViewerDocument = gql`
    query GetTransferFormViewer($transferType: TransferTypeEnum, $fromParticipantId: ID, $toParticipantId: ID, $date: String!) {
  viewer {
    ...TransferFormViewer
  }
}
    ${TransferFormViewerFragmentDoc}`;

/**
 * __useGetTransferFormViewerQuery__
 *
 * To run a query within a React component, call `useGetTransferFormViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransferFormViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransferFormViewerQuery({
 *   variables: {
 *      transferType: // value for 'transferType'
 *      fromParticipantId: // value for 'fromParticipantId'
 *      toParticipantId: // value for 'toParticipantId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetTransferFormViewerQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTransferFormViewerQuery, Types.GetTransferFormViewerQueryVariables> & ({ variables: Types.GetTransferFormViewerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTransferFormViewerQuery, Types.GetTransferFormViewerQueryVariables>(GetTransferFormViewerDocument, options);
      }
export function useGetTransferFormViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTransferFormViewerQuery, Types.GetTransferFormViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTransferFormViewerQuery, Types.GetTransferFormViewerQueryVariables>(GetTransferFormViewerDocument, options);
        }
export function useGetTransferFormViewerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetTransferFormViewerQuery, Types.GetTransferFormViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetTransferFormViewerQuery, Types.GetTransferFormViewerQueryVariables>(GetTransferFormViewerDocument, options);
        }
export type GetTransferFormViewerQueryHookResult = ReturnType<typeof useGetTransferFormViewerQuery>;
export type GetTransferFormViewerLazyQueryHookResult = ReturnType<typeof useGetTransferFormViewerLazyQuery>;
export type GetTransferFormViewerSuspenseQueryHookResult = ReturnType<typeof useGetTransferFormViewerSuspenseQuery>;
export type GetTransferFormViewerQueryResult = Apollo.QueryResult<Types.GetTransferFormViewerQuery, Types.GetTransferFormViewerQueryVariables>;
export const SelectFocusAccountDocument = gql`
    query SelectFocusAccount($focusOptionId: ID!) {
  node(id: $focusOptionId) {
    ... on NewSmartTransferFocusOption {
      focusParticipants {
        list {
          id
          transferParticipantType
          ...TransferParticipant
        }
      }
    }
  }
}
    ${TransferParticipantFragmentDoc}`;

/**
 * __useSelectFocusAccountQuery__
 *
 * To run a query within a React component, call `useSelectFocusAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectFocusAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectFocusAccountQuery({
 *   variables: {
 *      focusOptionId: // value for 'focusOptionId'
 *   },
 * });
 */
export function useSelectFocusAccountQuery(baseOptions: Apollo.QueryHookOptions<Types.SelectFocusAccountQuery, Types.SelectFocusAccountQueryVariables> & ({ variables: Types.SelectFocusAccountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SelectFocusAccountQuery, Types.SelectFocusAccountQueryVariables>(SelectFocusAccountDocument, options);
      }
export function useSelectFocusAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SelectFocusAccountQuery, Types.SelectFocusAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SelectFocusAccountQuery, Types.SelectFocusAccountQueryVariables>(SelectFocusAccountDocument, options);
        }
export function useSelectFocusAccountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SelectFocusAccountQuery, Types.SelectFocusAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SelectFocusAccountQuery, Types.SelectFocusAccountQueryVariables>(SelectFocusAccountDocument, options);
        }
export type SelectFocusAccountQueryHookResult = ReturnType<typeof useSelectFocusAccountQuery>;
export type SelectFocusAccountLazyQueryHookResult = ReturnType<typeof useSelectFocusAccountLazyQuery>;
export type SelectFocusAccountSuspenseQueryHookResult = ReturnType<typeof useSelectFocusAccountSuspenseQuery>;
export type SelectFocusAccountQueryResult = Apollo.QueryResult<Types.SelectFocusAccountQuery, Types.SelectFocusAccountQueryVariables>;
export const SetFulfillmentConditionAddDocument = gql`
    query SetFulfillmentConditionAdd($newSmartTransferContraParticipantEntryOptionId: ID!, $contraParticipantId: ID!) {
  node(id: $newSmartTransferContraParticipantEntryOptionId) {
    ... on NewSmartTransferContraParticipantEntryOption {
      contraParticipants {
        list {
          id
          ...TransferParticipant
        }
      }
      editRequirements(contraParticipantId: $contraParticipantId) {
        contraParticipantLabel
        fulfillmentConditionOptionEmptyTitle
        fulfillmentConditionOptionEmptyDescription
        fulfillmentConditionOptionSetListingPreamble
        fulfillmentConditionOptionSet {
          ...SmartTransferFulfillmentConditionOptionSet
        }
        id
        title
      }
      id
      title
    }
  }
}
    ${TransferParticipantFragmentDoc}
${SmartTransferFulfillmentConditionOptionSetFragmentDoc}`;

/**
 * __useSetFulfillmentConditionAddQuery__
 *
 * To run a query within a React component, call `useSetFulfillmentConditionAddQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetFulfillmentConditionAddQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetFulfillmentConditionAddQuery({
 *   variables: {
 *      newSmartTransferContraParticipantEntryOptionId: // value for 'newSmartTransferContraParticipantEntryOptionId'
 *      contraParticipantId: // value for 'contraParticipantId'
 *   },
 * });
 */
export function useSetFulfillmentConditionAddQuery(baseOptions: Apollo.QueryHookOptions<Types.SetFulfillmentConditionAddQuery, Types.SetFulfillmentConditionAddQueryVariables> & ({ variables: Types.SetFulfillmentConditionAddQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SetFulfillmentConditionAddQuery, Types.SetFulfillmentConditionAddQueryVariables>(SetFulfillmentConditionAddDocument, options);
      }
export function useSetFulfillmentConditionAddLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SetFulfillmentConditionAddQuery, Types.SetFulfillmentConditionAddQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SetFulfillmentConditionAddQuery, Types.SetFulfillmentConditionAddQueryVariables>(SetFulfillmentConditionAddDocument, options);
        }
export function useSetFulfillmentConditionAddSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SetFulfillmentConditionAddQuery, Types.SetFulfillmentConditionAddQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SetFulfillmentConditionAddQuery, Types.SetFulfillmentConditionAddQueryVariables>(SetFulfillmentConditionAddDocument, options);
        }
export type SetFulfillmentConditionAddQueryHookResult = ReturnType<typeof useSetFulfillmentConditionAddQuery>;
export type SetFulfillmentConditionAddLazyQueryHookResult = ReturnType<typeof useSetFulfillmentConditionAddLazyQuery>;
export type SetFulfillmentConditionAddSuspenseQueryHookResult = ReturnType<typeof useSetFulfillmentConditionAddSuspenseQuery>;
export type SetFulfillmentConditionAddQueryResult = Apollo.QueryResult<Types.SetFulfillmentConditionAddQuery, Types.SetFulfillmentConditionAddQueryVariables>;
export const SetFulfillmentConditionEditDocument = gql`
    query SetFulfillmentConditionEdit($editRequirementsId: ID!) {
  node(id: $editRequirementsId) {
    ... on SmartTransferContraParticipantEntryEditRequirements {
      contraParticipantLabel
      fulfillmentConditionOptionSetListingPreamble
      fulfillmentConditionOptionSet {
        ...SmartTransferFulfillmentConditionOptionSet
      }
      id
      title
    }
  }
}
    ${SmartTransferFulfillmentConditionOptionSetFragmentDoc}`;

/**
 * __useSetFulfillmentConditionEditQuery__
 *
 * To run a query within a React component, call `useSetFulfillmentConditionEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetFulfillmentConditionEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetFulfillmentConditionEditQuery({
 *   variables: {
 *      editRequirementsId: // value for 'editRequirementsId'
 *   },
 * });
 */
export function useSetFulfillmentConditionEditQuery(baseOptions: Apollo.QueryHookOptions<Types.SetFulfillmentConditionEditQuery, Types.SetFulfillmentConditionEditQueryVariables> & ({ variables: Types.SetFulfillmentConditionEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SetFulfillmentConditionEditQuery, Types.SetFulfillmentConditionEditQueryVariables>(SetFulfillmentConditionEditDocument, options);
      }
export function useSetFulfillmentConditionEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SetFulfillmentConditionEditQuery, Types.SetFulfillmentConditionEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SetFulfillmentConditionEditQuery, Types.SetFulfillmentConditionEditQueryVariables>(SetFulfillmentConditionEditDocument, options);
        }
export function useSetFulfillmentConditionEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SetFulfillmentConditionEditQuery, Types.SetFulfillmentConditionEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SetFulfillmentConditionEditQuery, Types.SetFulfillmentConditionEditQueryVariables>(SetFulfillmentConditionEditDocument, options);
        }
export type SetFulfillmentConditionEditQueryHookResult = ReturnType<typeof useSetFulfillmentConditionEditQuery>;
export type SetFulfillmentConditionEditLazyQueryHookResult = ReturnType<typeof useSetFulfillmentConditionEditLazyQuery>;
export type SetFulfillmentConditionEditSuspenseQueryHookResult = ReturnType<typeof useSetFulfillmentConditionEditSuspenseQuery>;
export type SetFulfillmentConditionEditQueryResult = Apollo.QueryResult<Types.SetFulfillmentConditionEditQuery, Types.SetFulfillmentConditionEditQueryVariables>;
export const SetSmartTransferDocument = gql`
    query SetSmartTransfer($focusOptionId: ID!, $focusAccountId: ID!, $insertionContext: SmartTransferContraParticipantEntryInsertionContextInput) {
  node(id: $focusOptionId) {
    ... on NewSmartTransferFocusOption {
      editRequirements(focusParticipantId: $focusAccountId) {
        id
        ... on SmartTransferRuleEditRequirements {
          id
          balanceThresholdPreamble
          balanceTriggerEditRequirements {
            balanceTriggerType
            ... on SmartTransferBalanceTriggerEditRequirements {
              minBalanceTriggerThreshold
              maxBalanceTriggerThreshold
            }
          }
          contraParticipantListPreamble
          newContraParticipantEntryOptionSet(insertionContext: $insertionContext) {
            ... on NewSmartTransferContraParticipantEntryOptionSet {
              options {
                id
                name
                description
              }
            }
          }
          contraParticipantEntryAdditionLabel
        }
      }
    }
  }
}
    `;

/**
 * __useSetSmartTransferQuery__
 *
 * To run a query within a React component, call `useSetSmartTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetSmartTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetSmartTransferQuery({
 *   variables: {
 *      focusOptionId: // value for 'focusOptionId'
 *      focusAccountId: // value for 'focusAccountId'
 *      insertionContext: // value for 'insertionContext'
 *   },
 * });
 */
export function useSetSmartTransferQuery(baseOptions: Apollo.QueryHookOptions<Types.SetSmartTransferQuery, Types.SetSmartTransferQueryVariables> & ({ variables: Types.SetSmartTransferQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SetSmartTransferQuery, Types.SetSmartTransferQueryVariables>(SetSmartTransferDocument, options);
      }
export function useSetSmartTransferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SetSmartTransferQuery, Types.SetSmartTransferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SetSmartTransferQuery, Types.SetSmartTransferQueryVariables>(SetSmartTransferDocument, options);
        }
export function useSetSmartTransferSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SetSmartTransferQuery, Types.SetSmartTransferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SetSmartTransferQuery, Types.SetSmartTransferQueryVariables>(SetSmartTransferDocument, options);
        }
export type SetSmartTransferQueryHookResult = ReturnType<typeof useSetSmartTransferQuery>;
export type SetSmartTransferLazyQueryHookResult = ReturnType<typeof useSetSmartTransferLazyQuery>;
export type SetSmartTransferSuspenseQueryHookResult = ReturnType<typeof useSetSmartTransferSuspenseQuery>;
export type SetSmartTransferQueryResult = Apollo.QueryResult<Types.SetSmartTransferQuery, Types.SetSmartTransferQueryVariables>;
export const ShowFeedbackAfterDepositStepDocument = gql`
    query ShowFeedbackAfterDepositStep($id: ID!) {
  account: node(id: $id) {
    ... on Account {
      lastAchRelationship {
        __typename
      }
    }
  }
  viewer {
    user {
      isPrimaryEmailVerified
    }
  }
}
    `;

/**
 * __useShowFeedbackAfterDepositStepQuery__
 *
 * To run a query within a React component, call `useShowFeedbackAfterDepositStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowFeedbackAfterDepositStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowFeedbackAfterDepositStepQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShowFeedbackAfterDepositStepQuery(baseOptions: Apollo.QueryHookOptions<Types.ShowFeedbackAfterDepositStepQuery, Types.ShowFeedbackAfterDepositStepQueryVariables> & ({ variables: Types.ShowFeedbackAfterDepositStepQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ShowFeedbackAfterDepositStepQuery, Types.ShowFeedbackAfterDepositStepQueryVariables>(ShowFeedbackAfterDepositStepDocument, options);
      }
export function useShowFeedbackAfterDepositStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ShowFeedbackAfterDepositStepQuery, Types.ShowFeedbackAfterDepositStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ShowFeedbackAfterDepositStepQuery, Types.ShowFeedbackAfterDepositStepQueryVariables>(ShowFeedbackAfterDepositStepDocument, options);
        }
export function useShowFeedbackAfterDepositStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ShowFeedbackAfterDepositStepQuery, Types.ShowFeedbackAfterDepositStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ShowFeedbackAfterDepositStepQuery, Types.ShowFeedbackAfterDepositStepQueryVariables>(ShowFeedbackAfterDepositStepDocument, options);
        }
export type ShowFeedbackAfterDepositStepQueryHookResult = ReturnType<typeof useShowFeedbackAfterDepositStepQuery>;
export type ShowFeedbackAfterDepositStepLazyQueryHookResult = ReturnType<typeof useShowFeedbackAfterDepositStepLazyQuery>;
export type ShowFeedbackAfterDepositStepSuspenseQueryHookResult = ReturnType<typeof useShowFeedbackAfterDepositStepSuspenseQuery>;
export type ShowFeedbackAfterDepositStepQueryResult = Apollo.QueryResult<Types.ShowFeedbackAfterDepositStepQuery, Types.ShowFeedbackAfterDepositStepQueryVariables>;
export const SmartTransferContraParticipantEntryInstancesPageDocument = gql`
    query SmartTransferContraParticipantEntryInstancesPage($smartTransferContraParticipantEntryId: ID!, $first: Int!, $after: String) {
  node(id: $smartTransferContraParticipantEntryId) {
    ... on SmartTransferContraParticipantEntry {
      id
      instances(first: $first, after: $after) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...SmartTransferContraParticipantEntryInstanceNode
          }
        }
      }
    }
  }
}
    ${SmartTransferContraParticipantEntryInstanceNodeFragmentDoc}`;

/**
 * __useSmartTransferContraParticipantEntryInstancesPageQuery__
 *
 * To run a query within a React component, call `useSmartTransferContraParticipantEntryInstancesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartTransferContraParticipantEntryInstancesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartTransferContraParticipantEntryInstancesPageQuery({
 *   variables: {
 *      smartTransferContraParticipantEntryId: // value for 'smartTransferContraParticipantEntryId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSmartTransferContraParticipantEntryInstancesPageQuery(baseOptions: Apollo.QueryHookOptions<Types.SmartTransferContraParticipantEntryInstancesPageQuery, Types.SmartTransferContraParticipantEntryInstancesPageQueryVariables> & ({ variables: Types.SmartTransferContraParticipantEntryInstancesPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SmartTransferContraParticipantEntryInstancesPageQuery, Types.SmartTransferContraParticipantEntryInstancesPageQueryVariables>(SmartTransferContraParticipantEntryInstancesPageDocument, options);
      }
export function useSmartTransferContraParticipantEntryInstancesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SmartTransferContraParticipantEntryInstancesPageQuery, Types.SmartTransferContraParticipantEntryInstancesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SmartTransferContraParticipantEntryInstancesPageQuery, Types.SmartTransferContraParticipantEntryInstancesPageQueryVariables>(SmartTransferContraParticipantEntryInstancesPageDocument, options);
        }
export function useSmartTransferContraParticipantEntryInstancesPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SmartTransferContraParticipantEntryInstancesPageQuery, Types.SmartTransferContraParticipantEntryInstancesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SmartTransferContraParticipantEntryInstancesPageQuery, Types.SmartTransferContraParticipantEntryInstancesPageQueryVariables>(SmartTransferContraParticipantEntryInstancesPageDocument, options);
        }
export type SmartTransferContraParticipantEntryInstancesPageQueryHookResult = ReturnType<typeof useSmartTransferContraParticipantEntryInstancesPageQuery>;
export type SmartTransferContraParticipantEntryInstancesPageLazyQueryHookResult = ReturnType<typeof useSmartTransferContraParticipantEntryInstancesPageLazyQuery>;
export type SmartTransferContraParticipantEntryInstancesPageSuspenseQueryHookResult = ReturnType<typeof useSmartTransferContraParticipantEntryInstancesPageSuspenseQuery>;
export type SmartTransferContraParticipantEntryInstancesPageQueryResult = Apollo.QueryResult<Types.SmartTransferContraParticipantEntryInstancesPageQuery, Types.SmartTransferContraParticipantEntryInstancesPageQueryVariables>;
export const SmartTransferDetailsDocument = gql`
    query SmartTransferDetails($smartTransferRuleId: ID!) {
  node(id: $smartTransferRuleId) {
    ... on SmartTransferRule {
      ...SmartTransferEnableSwitch
      ...SmartTransferRuleActivity
      ...SmartTransferRuleSummary
    }
  }
}
    ${SmartTransferEnableSwitchFragmentDoc}
${SmartTransferRuleActivityFragmentDoc}
${SmartTransferRuleSummaryFragmentDoc}`;

/**
 * __useSmartTransferDetailsQuery__
 *
 * To run a query within a React component, call `useSmartTransferDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartTransferDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartTransferDetailsQuery({
 *   variables: {
 *      smartTransferRuleId: // value for 'smartTransferRuleId'
 *   },
 * });
 */
export function useSmartTransferDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.SmartTransferDetailsQuery, Types.SmartTransferDetailsQueryVariables> & ({ variables: Types.SmartTransferDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SmartTransferDetailsQuery, Types.SmartTransferDetailsQueryVariables>(SmartTransferDetailsDocument, options);
      }
export function useSmartTransferDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SmartTransferDetailsQuery, Types.SmartTransferDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SmartTransferDetailsQuery, Types.SmartTransferDetailsQueryVariables>(SmartTransferDetailsDocument, options);
        }
export function useSmartTransferDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SmartTransferDetailsQuery, Types.SmartTransferDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SmartTransferDetailsQuery, Types.SmartTransferDetailsQueryVariables>(SmartTransferDetailsDocument, options);
        }
export type SmartTransferDetailsQueryHookResult = ReturnType<typeof useSmartTransferDetailsQuery>;
export type SmartTransferDetailsLazyQueryHookResult = ReturnType<typeof useSmartTransferDetailsLazyQuery>;
export type SmartTransferDetailsSuspenseQueryHookResult = ReturnType<typeof useSmartTransferDetailsSuspenseQuery>;
export type SmartTransferDetailsQueryResult = Apollo.QueryResult<Types.SmartTransferDetailsQuery, Types.SmartTransferDetailsQueryVariables>;
export const SmartTransferReceiptDocument = gql`
    query SmartTransferReceipt($smartTransferRuleId: ID!) {
  node(id: $smartTransferRuleId) {
    ... on SmartTransferRule {
      editRequirements {
        id
        focusOption {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useSmartTransferReceiptQuery__
 *
 * To run a query within a React component, call `useSmartTransferReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartTransferReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartTransferReceiptQuery({
 *   variables: {
 *      smartTransferRuleId: // value for 'smartTransferRuleId'
 *   },
 * });
 */
export function useSmartTransferReceiptQuery(baseOptions: Apollo.QueryHookOptions<Types.SmartTransferReceiptQuery, Types.SmartTransferReceiptQueryVariables> & ({ variables: Types.SmartTransferReceiptQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SmartTransferReceiptQuery, Types.SmartTransferReceiptQueryVariables>(SmartTransferReceiptDocument, options);
      }
export function useSmartTransferReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SmartTransferReceiptQuery, Types.SmartTransferReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SmartTransferReceiptQuery, Types.SmartTransferReceiptQueryVariables>(SmartTransferReceiptDocument, options);
        }
export function useSmartTransferReceiptSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SmartTransferReceiptQuery, Types.SmartTransferReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SmartTransferReceiptQuery, Types.SmartTransferReceiptQueryVariables>(SmartTransferReceiptDocument, options);
        }
export type SmartTransferReceiptQueryHookResult = ReturnType<typeof useSmartTransferReceiptQuery>;
export type SmartTransferReceiptLazyQueryHookResult = ReturnType<typeof useSmartTransferReceiptLazyQuery>;
export type SmartTransferReceiptSuspenseQueryHookResult = ReturnType<typeof useSmartTransferReceiptSuspenseQuery>;
export type SmartTransferReceiptQueryResult = Apollo.QueryResult<Types.SmartTransferReceiptQuery, Types.SmartTransferReceiptQueryVariables>;
export const SmartTransferTimingMessageDocument = gql`
    query SmartTransferTimingMessage($balanceTrigger: SmartTransferBalanceTriggerInput, $editRequirementsId: ID!) {
  node(id: $editRequirementsId) {
    ... on SmartTransferRuleEditRequirements {
      balanceTriggerEditRequirements {
        timingMessage(balanceTrigger: $balanceTrigger) {
          messageType
          description
          link {
            ...Linkable
          }
        }
      }
    }
  }
}
    ${LinkableFragmentDoc}`;

/**
 * __useSmartTransferTimingMessageQuery__
 *
 * To run a query within a React component, call `useSmartTransferTimingMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartTransferTimingMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartTransferTimingMessageQuery({
 *   variables: {
 *      balanceTrigger: // value for 'balanceTrigger'
 *      editRequirementsId: // value for 'editRequirementsId'
 *   },
 * });
 */
export function useSmartTransferTimingMessageQuery(baseOptions: Apollo.QueryHookOptions<Types.SmartTransferTimingMessageQuery, Types.SmartTransferTimingMessageQueryVariables> & ({ variables: Types.SmartTransferTimingMessageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SmartTransferTimingMessageQuery, Types.SmartTransferTimingMessageQueryVariables>(SmartTransferTimingMessageDocument, options);
      }
export function useSmartTransferTimingMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SmartTransferTimingMessageQuery, Types.SmartTransferTimingMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SmartTransferTimingMessageQuery, Types.SmartTransferTimingMessageQueryVariables>(SmartTransferTimingMessageDocument, options);
        }
export function useSmartTransferTimingMessageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SmartTransferTimingMessageQuery, Types.SmartTransferTimingMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SmartTransferTimingMessageQuery, Types.SmartTransferTimingMessageQueryVariables>(SmartTransferTimingMessageDocument, options);
        }
export type SmartTransferTimingMessageQueryHookResult = ReturnType<typeof useSmartTransferTimingMessageQuery>;
export type SmartTransferTimingMessageLazyQueryHookResult = ReturnType<typeof useSmartTransferTimingMessageLazyQuery>;
export type SmartTransferTimingMessageSuspenseQueryHookResult = ReturnType<typeof useSmartTransferTimingMessageSuspenseQuery>;
export type SmartTransferTimingMessageQueryResult = Apollo.QueryResult<Types.SmartTransferTimingMessageQuery, Types.SmartTransferTimingMessageQueryVariables>;
export const SmartTransferWaterfallEntryDocument = gql`
    query SmartTransferWaterfallEntry($focusAccountId: ID!, $contraParticipantId: ID!) {
  focus: node(id: $focusAccountId) {
    ...TransferParticipant
  }
  contra: node(id: $contraParticipantId) {
    ...TransferParticipant
  }
}
    ${TransferParticipantFragmentDoc}`;

/**
 * __useSmartTransferWaterfallEntryQuery__
 *
 * To run a query within a React component, call `useSmartTransferWaterfallEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartTransferWaterfallEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartTransferWaterfallEntryQuery({
 *   variables: {
 *      focusAccountId: // value for 'focusAccountId'
 *      contraParticipantId: // value for 'contraParticipantId'
 *   },
 * });
 */
export function useSmartTransferWaterfallEntryQuery(baseOptions: Apollo.QueryHookOptions<Types.SmartTransferWaterfallEntryQuery, Types.SmartTransferWaterfallEntryQueryVariables> & ({ variables: Types.SmartTransferWaterfallEntryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SmartTransferWaterfallEntryQuery, Types.SmartTransferWaterfallEntryQueryVariables>(SmartTransferWaterfallEntryDocument, options);
      }
export function useSmartTransferWaterfallEntryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SmartTransferWaterfallEntryQuery, Types.SmartTransferWaterfallEntryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SmartTransferWaterfallEntryQuery, Types.SmartTransferWaterfallEntryQueryVariables>(SmartTransferWaterfallEntryDocument, options);
        }
export function useSmartTransferWaterfallEntrySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SmartTransferWaterfallEntryQuery, Types.SmartTransferWaterfallEntryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SmartTransferWaterfallEntryQuery, Types.SmartTransferWaterfallEntryQueryVariables>(SmartTransferWaterfallEntryDocument, options);
        }
export type SmartTransferWaterfallEntryQueryHookResult = ReturnType<typeof useSmartTransferWaterfallEntryQuery>;
export type SmartTransferWaterfallEntryLazyQueryHookResult = ReturnType<typeof useSmartTransferWaterfallEntryLazyQuery>;
export type SmartTransferWaterfallEntrySuspenseQueryHookResult = ReturnType<typeof useSmartTransferWaterfallEntrySuspenseQuery>;
export type SmartTransferWaterfallEntryQueryResult = Apollo.QueryResult<Types.SmartTransferWaterfallEntryQuery, Types.SmartTransferWaterfallEntryQueryVariables>;
export const SmartTransferWaterfallDocument = gql`
    query SmartTransferWaterfall($smartTransferRuleEditRequirementsId: ID!, $contraParticipantEntries: [SmartTransferContraParticipantEntryInput!]!) {
  node(id: $smartTransferRuleEditRequirementsId) {
    ... on SmartTransferRuleEditRequirements {
      id
      contraParticipantEntryAdditionLabel
      shouldShowContraParticipantEntryAddition(
        contraParticipantEntries: $contraParticipantEntries
      )
    }
  }
}
    `;

/**
 * __useSmartTransferWaterfallQuery__
 *
 * To run a query within a React component, call `useSmartTransferWaterfallQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartTransferWaterfallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartTransferWaterfallQuery({
 *   variables: {
 *      smartTransferRuleEditRequirementsId: // value for 'smartTransferRuleEditRequirementsId'
 *      contraParticipantEntries: // value for 'contraParticipantEntries'
 *   },
 * });
 */
export function useSmartTransferWaterfallQuery(baseOptions: Apollo.QueryHookOptions<Types.SmartTransferWaterfallQuery, Types.SmartTransferWaterfallQueryVariables> & ({ variables: Types.SmartTransferWaterfallQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SmartTransferWaterfallQuery, Types.SmartTransferWaterfallQueryVariables>(SmartTransferWaterfallDocument, options);
      }
export function useSmartTransferWaterfallLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SmartTransferWaterfallQuery, Types.SmartTransferWaterfallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SmartTransferWaterfallQuery, Types.SmartTransferWaterfallQueryVariables>(SmartTransferWaterfallDocument, options);
        }
export function useSmartTransferWaterfallSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SmartTransferWaterfallQuery, Types.SmartTransferWaterfallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SmartTransferWaterfallQuery, Types.SmartTransferWaterfallQueryVariables>(SmartTransferWaterfallDocument, options);
        }
export type SmartTransferWaterfallQueryHookResult = ReturnType<typeof useSmartTransferWaterfallQuery>;
export type SmartTransferWaterfallLazyQueryHookResult = ReturnType<typeof useSmartTransferWaterfallLazyQuery>;
export type SmartTransferWaterfallSuspenseQueryHookResult = ReturnType<typeof useSmartTransferWaterfallSuspenseQuery>;
export type SmartTransferWaterfallQueryResult = Apollo.QueryResult<Types.SmartTransferWaterfallQuery, Types.SmartTransferWaterfallQueryVariables>;
export const TransferActivityPageDocument = gql`
    query TransferActivityPage($first: Int!, $after: String) {
  viewer {
    transfers {
      ...PendingInstances
      ...PastInstances
      ...TransfersPendingAcatInstances
    }
  }
}
    ${PendingInstancesFragmentDoc}
${PastInstancesFragmentDoc}
${TransfersPendingAcatInstancesFragmentDoc}`;

/**
 * __useTransferActivityPageQuery__
 *
 * To run a query within a React component, call `useTransferActivityPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferActivityPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferActivityPageQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useTransferActivityPageQuery(baseOptions: Apollo.QueryHookOptions<Types.TransferActivityPageQuery, Types.TransferActivityPageQueryVariables> & ({ variables: Types.TransferActivityPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TransferActivityPageQuery, Types.TransferActivityPageQueryVariables>(TransferActivityPageDocument, options);
      }
export function useTransferActivityPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TransferActivityPageQuery, Types.TransferActivityPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TransferActivityPageQuery, Types.TransferActivityPageQueryVariables>(TransferActivityPageDocument, options);
        }
export function useTransferActivityPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TransferActivityPageQuery, Types.TransferActivityPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TransferActivityPageQuery, Types.TransferActivityPageQueryVariables>(TransferActivityPageDocument, options);
        }
export type TransferActivityPageQueryHookResult = ReturnType<typeof useTransferActivityPageQuery>;
export type TransferActivityPageLazyQueryHookResult = ReturnType<typeof useTransferActivityPageLazyQuery>;
export type TransferActivityPageSuspenseQueryHookResult = ReturnType<typeof useTransferActivityPageSuspenseQuery>;
export type TransferActivityPageQueryResult = Apollo.QueryResult<Types.TransferActivityPageQuery, Types.TransferActivityPageQueryVariables>;
export const TransfersPastInstancesDocument = gql`
    query TransfersPastInstances($first: Int!, $after: String) {
  viewer {
    transfers {
      ...PastInstances
    }
  }
}
    ${PastInstancesFragmentDoc}`;

/**
 * __useTransfersPastInstancesQuery__
 *
 * To run a query within a React component, call `useTransfersPastInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransfersPastInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransfersPastInstancesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useTransfersPastInstancesQuery(baseOptions: Apollo.QueryHookOptions<Types.TransfersPastInstancesQuery, Types.TransfersPastInstancesQueryVariables> & ({ variables: Types.TransfersPastInstancesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TransfersPastInstancesQuery, Types.TransfersPastInstancesQueryVariables>(TransfersPastInstancesDocument, options);
      }
export function useTransfersPastInstancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TransfersPastInstancesQuery, Types.TransfersPastInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TransfersPastInstancesQuery, Types.TransfersPastInstancesQueryVariables>(TransfersPastInstancesDocument, options);
        }
export function useTransfersPastInstancesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TransfersPastInstancesQuery, Types.TransfersPastInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TransfersPastInstancesQuery, Types.TransfersPastInstancesQueryVariables>(TransfersPastInstancesDocument, options);
        }
export type TransfersPastInstancesQueryHookResult = ReturnType<typeof useTransfersPastInstancesQuery>;
export type TransfersPastInstancesLazyQueryHookResult = ReturnType<typeof useTransfersPastInstancesLazyQuery>;
export type TransfersPastInstancesSuspenseQueryHookResult = ReturnType<typeof useTransfersPastInstancesSuspenseQuery>;
export type TransfersPastInstancesQueryResult = Apollo.QueryResult<Types.TransfersPastInstancesQuery, Types.TransfersPastInstancesQueryVariables>;
export const SpendBankConnectorComponentDocument = gql`
    query SpendBankConnectorComponent($id: ID!) {
  node(id: $id) {
    ...SpendBankConnector
  }
}
    ${SpendBankConnectorFragmentDoc}`;

/**
 * __useSpendBankConnectorComponentQuery__
 *
 * To run a query within a React component, call `useSpendBankConnectorComponentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendBankConnectorComponentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendBankConnectorComponentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpendBankConnectorComponentQuery(baseOptions: Apollo.QueryHookOptions<Types.SpendBankConnectorComponentQuery, Types.SpendBankConnectorComponentQueryVariables> & ({ variables: Types.SpendBankConnectorComponentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SpendBankConnectorComponentQuery, Types.SpendBankConnectorComponentQueryVariables>(SpendBankConnectorComponentDocument, options);
      }
export function useSpendBankConnectorComponentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SpendBankConnectorComponentQuery, Types.SpendBankConnectorComponentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SpendBankConnectorComponentQuery, Types.SpendBankConnectorComponentQueryVariables>(SpendBankConnectorComponentDocument, options);
        }
export function useSpendBankConnectorComponentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SpendBankConnectorComponentQuery, Types.SpendBankConnectorComponentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SpendBankConnectorComponentQuery, Types.SpendBankConnectorComponentQueryVariables>(SpendBankConnectorComponentDocument, options);
        }
export type SpendBankConnectorComponentQueryHookResult = ReturnType<typeof useSpendBankConnectorComponentQuery>;
export type SpendBankConnectorComponentLazyQueryHookResult = ReturnType<typeof useSpendBankConnectorComponentLazyQuery>;
export type SpendBankConnectorComponentSuspenseQueryHookResult = ReturnType<typeof useSpendBankConnectorComponentSuspenseQuery>;
export type SpendBankConnectorComponentQueryResult = Apollo.QueryResult<Types.SpendBankConnectorComponentQuery, Types.SpendBankConnectorComponentQueryVariables>;
export const TransferInstanceDetailsPageWrapperDocument = gql`
    query TransferInstanceDetailsPageWrapper($transferInstanceId: ID!) {
  node(id: $transferInstanceId) {
    ...TransferInstanceDetails
  }
}
    ${TransferInstanceDetailsFragmentDoc}`;

/**
 * __useTransferInstanceDetailsPageWrapperQuery__
 *
 * To run a query within a React component, call `useTransferInstanceDetailsPageWrapperQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferInstanceDetailsPageWrapperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferInstanceDetailsPageWrapperQuery({
 *   variables: {
 *      transferInstanceId: // value for 'transferInstanceId'
 *   },
 * });
 */
export function useTransferInstanceDetailsPageWrapperQuery(baseOptions: Apollo.QueryHookOptions<Types.TransferInstanceDetailsPageWrapperQuery, Types.TransferInstanceDetailsPageWrapperQueryVariables> & ({ variables: Types.TransferInstanceDetailsPageWrapperQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TransferInstanceDetailsPageWrapperQuery, Types.TransferInstanceDetailsPageWrapperQueryVariables>(TransferInstanceDetailsPageWrapperDocument, options);
      }
export function useTransferInstanceDetailsPageWrapperLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TransferInstanceDetailsPageWrapperQuery, Types.TransferInstanceDetailsPageWrapperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TransferInstanceDetailsPageWrapperQuery, Types.TransferInstanceDetailsPageWrapperQueryVariables>(TransferInstanceDetailsPageWrapperDocument, options);
        }
export function useTransferInstanceDetailsPageWrapperSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TransferInstanceDetailsPageWrapperQuery, Types.TransferInstanceDetailsPageWrapperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TransferInstanceDetailsPageWrapperQuery, Types.TransferInstanceDetailsPageWrapperQueryVariables>(TransferInstanceDetailsPageWrapperDocument, options);
        }
export type TransferInstanceDetailsPageWrapperQueryHookResult = ReturnType<typeof useTransferInstanceDetailsPageWrapperQuery>;
export type TransferInstanceDetailsPageWrapperLazyQueryHookResult = ReturnType<typeof useTransferInstanceDetailsPageWrapperLazyQuery>;
export type TransferInstanceDetailsPageWrapperSuspenseQueryHookResult = ReturnType<typeof useTransferInstanceDetailsPageWrapperSuspenseQuery>;
export type TransferInstanceDetailsPageWrapperQueryResult = Apollo.QueryResult<Types.TransferInstanceDetailsPageWrapperQuery, Types.TransferInstanceDetailsPageWrapperQueryVariables>;
export const TransfersPageDocument = gql`
    query TransfersPage {
  viewer {
    announcements {
      forTransfers {
        ...Announcement
      }
    }
    transfers {
      id
      activeFundingSource {
        id
        externalName
      }
    }
    user {
      isPrimaryEmailVerified
    }
  }
}
    ${AnnouncementFragmentDoc}`;

/**
 * __useTransfersPageQuery__
 *
 * To run a query within a React component, call `useTransfersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransfersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransfersPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransfersPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.TransfersPageQuery, Types.TransfersPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TransfersPageQuery, Types.TransfersPageQueryVariables>(TransfersPageDocument, options);
      }
export function useTransfersPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TransfersPageQuery, Types.TransfersPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TransfersPageQuery, Types.TransfersPageQueryVariables>(TransfersPageDocument, options);
        }
export function useTransfersPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TransfersPageQuery, Types.TransfersPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TransfersPageQuery, Types.TransfersPageQueryVariables>(TransfersPageDocument, options);
        }
export type TransfersPageQueryHookResult = ReturnType<typeof useTransfersPageQuery>;
export type TransfersPageLazyQueryHookResult = ReturnType<typeof useTransfersPageLazyQuery>;
export type TransfersPageSuspenseQueryHookResult = ReturnType<typeof useTransfersPageSuspenseQuery>;
export type TransfersPageQueryResult = Apollo.QueryResult<Types.TransfersPageQuery, Types.TransfersPageQueryVariables>;
export const TransfersRulesPageDocument = gql`
    query TransfersRulesPage {
  viewer {
    user {
      data {
        key
        value
      }
    }
    transfers {
      smartTransferRules {
        ...SmartTransferRule
      }
      scheduledTransferRules {
        ...ScheduledTransferRule
      }
    }
  }
}
    ${SmartTransferRuleFragmentDoc}
${ScheduledTransferRuleFragmentDoc}`;

/**
 * __useTransfersRulesPageQuery__
 *
 * To run a query within a React component, call `useTransfersRulesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransfersRulesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransfersRulesPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransfersRulesPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.TransfersRulesPageQuery, Types.TransfersRulesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TransfersRulesPageQuery, Types.TransfersRulesPageQueryVariables>(TransfersRulesPageDocument, options);
      }
export function useTransfersRulesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TransfersRulesPageQuery, Types.TransfersRulesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TransfersRulesPageQuery, Types.TransfersRulesPageQueryVariables>(TransfersRulesPageDocument, options);
        }
export function useTransfersRulesPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TransfersRulesPageQuery, Types.TransfersRulesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TransfersRulesPageQuery, Types.TransfersRulesPageQueryVariables>(TransfersRulesPageDocument, options);
        }
export type TransfersRulesPageQueryHookResult = ReturnType<typeof useTransfersRulesPageQuery>;
export type TransfersRulesPageLazyQueryHookResult = ReturnType<typeof useTransfersRulesPageLazyQuery>;
export type TransfersRulesPageSuspenseQueryHookResult = ReturnType<typeof useTransfersRulesPageSuspenseQuery>;
export type TransfersRulesPageQueryResult = Apollo.QueryResult<Types.TransfersRulesPageQuery, Types.TransfersRulesPageQueryVariables>;
export const WireTransferAccountsDocument = gql`
    query WireTransferAccounts {
  viewer {
    transfers {
      moveMoneyV2 {
        wireTransferParticipants {
          list {
            ...TransferParticipant
          }
        }
      }
    }
  }
}
    ${TransferParticipantFragmentDoc}`;

/**
 * __useWireTransferAccountsQuery__
 *
 * To run a query within a React component, call `useWireTransferAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWireTransferAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWireTransferAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWireTransferAccountsQuery(baseOptions?: Apollo.QueryHookOptions<Types.WireTransferAccountsQuery, Types.WireTransferAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WireTransferAccountsQuery, Types.WireTransferAccountsQueryVariables>(WireTransferAccountsDocument, options);
      }
export function useWireTransferAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WireTransferAccountsQuery, Types.WireTransferAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WireTransferAccountsQuery, Types.WireTransferAccountsQueryVariables>(WireTransferAccountsDocument, options);
        }
export function useWireTransferAccountsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WireTransferAccountsQuery, Types.WireTransferAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WireTransferAccountsQuery, Types.WireTransferAccountsQueryVariables>(WireTransferAccountsDocument, options);
        }
export type WireTransferAccountsQueryHookResult = ReturnType<typeof useWireTransferAccountsQuery>;
export type WireTransferAccountsLazyQueryHookResult = ReturnType<typeof useWireTransferAccountsLazyQuery>;
export type WireTransferAccountsSuspenseQueryHookResult = ReturnType<typeof useWireTransferAccountsSuspenseQuery>;
export type WireTransferAccountsQueryResult = Apollo.QueryResult<Types.WireTransferAccountsQuery, Types.WireTransferAccountsQueryVariables>;
export const WireTransferInstructionsDocument = gql`
    query WireTransferInstructions($accountId: ID!) {
  viewer {
    transfers {
      moveMoneyV2 {
        wireInstructions(accountId: $accountId) {
          ...WireInstructions
        }
      }
    }
  }
}
    ${WireInstructionsFragmentDoc}`;

/**
 * __useWireTransferInstructionsQuery__
 *
 * To run a query within a React component, call `useWireTransferInstructionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWireTransferInstructionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWireTransferInstructionsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useWireTransferInstructionsQuery(baseOptions: Apollo.QueryHookOptions<Types.WireTransferInstructionsQuery, Types.WireTransferInstructionsQueryVariables> & ({ variables: Types.WireTransferInstructionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.WireTransferInstructionsQuery, Types.WireTransferInstructionsQueryVariables>(WireTransferInstructionsDocument, options);
      }
export function useWireTransferInstructionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.WireTransferInstructionsQuery, Types.WireTransferInstructionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.WireTransferInstructionsQuery, Types.WireTransferInstructionsQueryVariables>(WireTransferInstructionsDocument, options);
        }
export function useWireTransferInstructionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.WireTransferInstructionsQuery, Types.WireTransferInstructionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.WireTransferInstructionsQuery, Types.WireTransferInstructionsQueryVariables>(WireTransferInstructionsDocument, options);
        }
export type WireTransferInstructionsQueryHookResult = ReturnType<typeof useWireTransferInstructionsQuery>;
export type WireTransferInstructionsLazyQueryHookResult = ReturnType<typeof useWireTransferInstructionsLazyQuery>;
export type WireTransferInstructionsSuspenseQueryHookResult = ReturnType<typeof useWireTransferInstructionsSuspenseQuery>;
export type WireTransferInstructionsQueryResult = Apollo.QueryResult<Types.WireTransferInstructionsQuery, Types.WireTransferInstructionsQueryVariables>;
export const TransferValidationDocument = gql`
    query TransferValidation($input: TransferValidationInput!) {
  viewer {
    transfers {
      transferValidation(input: $input) {
        title
        isLiquidation
        sourceParticipant {
          transferParticipantName
          transferParticipantType
        }
        destinationParticipant {
          transferParticipantType
          transferParticipantName
        }
        amount
        amountLineItems {
          label
          value
          formattedValue
          key
        }
        primaryMessages {
          type
          text
        }
        secondaryMessages {
          type
          text
        }
        ctaText {
          ...RichText
        }
        secondaryButton {
          label
        }
        primaryButton {
          title
          type
          analyticsEvent {
            ...AnalyticsEvent
          }
        }
      }
    }
  }
}
    ${RichTextFragmentDoc}
${AnalyticsEventFragmentDoc}`;

/**
 * __useTransferValidationQuery__
 *
 * To run a query within a React component, call `useTransferValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferValidationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransferValidationQuery(baseOptions: Apollo.QueryHookOptions<Types.TransferValidationQuery, Types.TransferValidationQueryVariables> & ({ variables: Types.TransferValidationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TransferValidationQuery, Types.TransferValidationQueryVariables>(TransferValidationDocument, options);
      }
export function useTransferValidationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TransferValidationQuery, Types.TransferValidationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TransferValidationQuery, Types.TransferValidationQueryVariables>(TransferValidationDocument, options);
        }
export function useTransferValidationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TransferValidationQuery, Types.TransferValidationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TransferValidationQuery, Types.TransferValidationQueryVariables>(TransferValidationDocument, options);
        }
export type TransferValidationQueryHookResult = ReturnType<typeof useTransferValidationQuery>;
export type TransferValidationLazyQueryHookResult = ReturnType<typeof useTransferValidationLazyQuery>;
export type TransferValidationSuspenseQueryHookResult = ReturnType<typeof useTransferValidationSuspenseQuery>;
export type TransferValidationQueryResult = Apollo.QueryResult<Types.TransferValidationQuery, Types.TransferValidationQueryVariables>;
export const GetTransferWizardPreloadDocument = gql`
    query GetTransferWizardPreload($date: String!) {
  viewer {
    irsRegulations {
      iraContributionYears {
        year
        limitUnder50
        limitOver50
        isCurrent
      }
    }
    transfers {
      id
      isEvenWeek(date: $date)
      defaultAmountPills(transferScenario: MOVE_MONEY_TRANSFER) {
        ...TransferAmountPill
      }
      participantGroups(transferScenario: MOVE_MONEY_TRANSFER) {
        oneTime {
          sourceParticipants {
            ...TransferWizardParticipantDetails
          }
          destinationParticipants {
            ...TransferWizardParticipantDetails
          }
        }
        scheduled {
          sourceParticipants {
            ...TransferWizardParticipantDetails
          }
          destinationParticipants {
            ...TransferWizardParticipantDetails
          }
        }
      }
    }
  }
}
    ${TransferAmountPillFragmentDoc}
${TransferWizardParticipantDetailsFragmentDoc}`;

/**
 * __useGetTransferWizardPreloadQuery__
 *
 * To run a query within a React component, call `useGetTransferWizardPreloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransferWizardPreloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransferWizardPreloadQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetTransferWizardPreloadQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTransferWizardPreloadQuery, Types.GetTransferWizardPreloadQueryVariables> & ({ variables: Types.GetTransferWizardPreloadQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTransferWizardPreloadQuery, Types.GetTransferWizardPreloadQueryVariables>(GetTransferWizardPreloadDocument, options);
      }
export function useGetTransferWizardPreloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTransferWizardPreloadQuery, Types.GetTransferWizardPreloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTransferWizardPreloadQuery, Types.GetTransferWizardPreloadQueryVariables>(GetTransferWizardPreloadDocument, options);
        }
export function useGetTransferWizardPreloadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetTransferWizardPreloadQuery, Types.GetTransferWizardPreloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetTransferWizardPreloadQuery, Types.GetTransferWizardPreloadQueryVariables>(GetTransferWizardPreloadDocument, options);
        }
export type GetTransferWizardPreloadQueryHookResult = ReturnType<typeof useGetTransferWizardPreloadQuery>;
export type GetTransferWizardPreloadLazyQueryHookResult = ReturnType<typeof useGetTransferWizardPreloadLazyQuery>;
export type GetTransferWizardPreloadSuspenseQueryHookResult = ReturnType<typeof useGetTransferWizardPreloadSuspenseQuery>;
export type GetTransferWizardPreloadQueryResult = Apollo.QueryResult<Types.GetTransferWizardPreloadQuery, Types.GetTransferWizardPreloadQueryVariables>;
export const TransferWizardRequirementsDocument = gql`
    query TransferWizardRequirements($amount: Float, $transferType: TransferTypeEnum, $sourceId: ID, $destinationId: ID) {
  viewer {
    transfers {
      requirements(
        amount: $amount
        transferType: $transferType
        fromParticipantId: $sourceId
        toParticipantId: $destinationId
      ) {
        ...TransferWizardRequirementsFields
      }
    }
  }
}
    ${TransferWizardRequirementsFieldsFragmentDoc}`;

/**
 * __useTransferWizardRequirementsQuery__
 *
 * To run a query within a React component, call `useTransferWizardRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferWizardRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferWizardRequirementsQuery({
 *   variables: {
 *      amount: // value for 'amount'
 *      transferType: // value for 'transferType'
 *      sourceId: // value for 'sourceId'
 *      destinationId: // value for 'destinationId'
 *   },
 * });
 */
export function useTransferWizardRequirementsQuery(baseOptions?: Apollo.QueryHookOptions<Types.TransferWizardRequirementsQuery, Types.TransferWizardRequirementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TransferWizardRequirementsQuery, Types.TransferWizardRequirementsQueryVariables>(TransferWizardRequirementsDocument, options);
      }
export function useTransferWizardRequirementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TransferWizardRequirementsQuery, Types.TransferWizardRequirementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TransferWizardRequirementsQuery, Types.TransferWizardRequirementsQueryVariables>(TransferWizardRequirementsDocument, options);
        }
export function useTransferWizardRequirementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TransferWizardRequirementsQuery, Types.TransferWizardRequirementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TransferWizardRequirementsQuery, Types.TransferWizardRequirementsQueryVariables>(TransferWizardRequirementsDocument, options);
        }
export type TransferWizardRequirementsQueryHookResult = ReturnType<typeof useTransferWizardRequirementsQuery>;
export type TransferWizardRequirementsLazyQueryHookResult = ReturnType<typeof useTransferWizardRequirementsLazyQuery>;
export type TransferWizardRequirementsSuspenseQueryHookResult = ReturnType<typeof useTransferWizardRequirementsSuspenseQuery>;
export type TransferWizardRequirementsQueryResult = Apollo.QueryResult<Types.TransferWizardRequirementsQuery, Types.TransferWizardRequirementsQueryVariables>;
export const ConfirmTransferStepDocument = gql`
    query ConfirmTransferStep($fromParticipantId: ID!, $toParticipantId: ID!, $transferType: TransferTypeEnum!, $amount: Float) {
  viewer {
    transfers {
      requirements(
        amount: $amount
        fromParticipantId: $fromParticipantId
        toParticipantId: $toParticipantId
        transferType: $transferType
      ) {
        summaryMessage
        isCreditCardPaymentTransfer
        isLoanPaymentTransfer
        ...TransferRequirementsTimingDescriptionCell
      }
    }
  }
  source: node(id: $fromParticipantId) {
    ...TransferParticipant
  }
  destination: node(id: $toParticipantId) {
    ...TransferParticipant
  }
}
    ${TransferRequirementsTimingDescriptionCellFragmentDoc}
${TransferParticipantFragmentDoc}`;

/**
 * __useConfirmTransferStepQuery__
 *
 * To run a query within a React component, call `useConfirmTransferStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmTransferStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmTransferStepQuery({
 *   variables: {
 *      fromParticipantId: // value for 'fromParticipantId'
 *      toParticipantId: // value for 'toParticipantId'
 *      transferType: // value for 'transferType'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useConfirmTransferStepQuery(baseOptions: Apollo.QueryHookOptions<Types.ConfirmTransferStepQuery, Types.ConfirmTransferStepQueryVariables> & ({ variables: Types.ConfirmTransferStepQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConfirmTransferStepQuery, Types.ConfirmTransferStepQueryVariables>(ConfirmTransferStepDocument, options);
      }
export function useConfirmTransferStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConfirmTransferStepQuery, Types.ConfirmTransferStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConfirmTransferStepQuery, Types.ConfirmTransferStepQueryVariables>(ConfirmTransferStepDocument, options);
        }
export function useConfirmTransferStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ConfirmTransferStepQuery, Types.ConfirmTransferStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ConfirmTransferStepQuery, Types.ConfirmTransferStepQueryVariables>(ConfirmTransferStepDocument, options);
        }
export type ConfirmTransferStepQueryHookResult = ReturnType<typeof useConfirmTransferStepQuery>;
export type ConfirmTransferStepLazyQueryHookResult = ReturnType<typeof useConfirmTransferStepLazyQuery>;
export type ConfirmTransferStepSuspenseQueryHookResult = ReturnType<typeof useConfirmTransferStepSuspenseQuery>;
export type ConfirmTransferStepQueryResult = Apollo.QueryResult<Types.ConfirmTransferStepQuery, Types.ConfirmTransferStepQueryVariables>;
export const CreateTransferShowReceiptDocument = gql`
    query CreateTransferShowReceipt($id: ID!) {
  node(id: $id) {
    id
    ... on TransferInstance {
      amount
      timingDescription
      isCreditCardPayment
      isPersonalLoanPayment
      additionalInfoCard {
        ...AppInformationalMessageCard
      }
      to {
        id
        ...TransferParticipant
        ... on Account {
          registration
        }
      }
    }
    ... on ScheduledTransferRule {
      to {
        ...TransferParticipant
      }
      isCreditCardAutoPay
      isPersonalLoanAutoPay
      schedule {
        __typename
      }
    }
  }
}
    ${AppInformationalMessageCardFragmentDoc}
${TransferParticipantFragmentDoc}`;

/**
 * __useCreateTransferShowReceiptQuery__
 *
 * To run a query within a React component, call `useCreateTransferShowReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateTransferShowReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateTransferShowReceiptQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateTransferShowReceiptQuery(baseOptions: Apollo.QueryHookOptions<Types.CreateTransferShowReceiptQuery, Types.CreateTransferShowReceiptQueryVariables> & ({ variables: Types.CreateTransferShowReceiptQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CreateTransferShowReceiptQuery, Types.CreateTransferShowReceiptQueryVariables>(CreateTransferShowReceiptDocument, options);
      }
export function useCreateTransferShowReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CreateTransferShowReceiptQuery, Types.CreateTransferShowReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CreateTransferShowReceiptQuery, Types.CreateTransferShowReceiptQueryVariables>(CreateTransferShowReceiptDocument, options);
        }
export function useCreateTransferShowReceiptSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CreateTransferShowReceiptQuery, Types.CreateTransferShowReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CreateTransferShowReceiptQuery, Types.CreateTransferShowReceiptQueryVariables>(CreateTransferShowReceiptDocument, options);
        }
export type CreateTransferShowReceiptQueryHookResult = ReturnType<typeof useCreateTransferShowReceiptQuery>;
export type CreateTransferShowReceiptLazyQueryHookResult = ReturnType<typeof useCreateTransferShowReceiptLazyQuery>;
export type CreateTransferShowReceiptSuspenseQueryHookResult = ReturnType<typeof useCreateTransferShowReceiptSuspenseQuery>;
export type CreateTransferShowReceiptQueryResult = Apollo.QueryResult<Types.CreateTransferShowReceiptQuery, Types.CreateTransferShowReceiptQueryVariables>;
export const ConfirmLiquidationDocument = gql`
    query ConfirmLiquidation($id: ID!) {
  node(id: $id) {
    ...ConfirmLiquidationAccountNode
  }
}
    ${ConfirmLiquidationAccountNodeFragmentDoc}`;

/**
 * __useConfirmLiquidationQuery__
 *
 * To run a query within a React component, call `useConfirmLiquidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmLiquidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmLiquidationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConfirmLiquidationQuery(baseOptions: Apollo.QueryHookOptions<Types.ConfirmLiquidationQuery, Types.ConfirmLiquidationQueryVariables> & ({ variables: Types.ConfirmLiquidationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConfirmLiquidationQuery, Types.ConfirmLiquidationQueryVariables>(ConfirmLiquidationDocument, options);
      }
export function useConfirmLiquidationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConfirmLiquidationQuery, Types.ConfirmLiquidationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConfirmLiquidationQuery, Types.ConfirmLiquidationQueryVariables>(ConfirmLiquidationDocument, options);
        }
export function useConfirmLiquidationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ConfirmLiquidationQuery, Types.ConfirmLiquidationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ConfirmLiquidationQuery, Types.ConfirmLiquidationQueryVariables>(ConfirmLiquidationDocument, options);
        }
export type ConfirmLiquidationQueryHookResult = ReturnType<typeof useConfirmLiquidationQuery>;
export type ConfirmLiquidationLazyQueryHookResult = ReturnType<typeof useConfirmLiquidationLazyQuery>;
export type ConfirmLiquidationSuspenseQueryHookResult = ReturnType<typeof useConfirmLiquidationSuspenseQuery>;
export type ConfirmLiquidationQueryResult = Apollo.QueryResult<Types.ConfirmLiquidationQuery, Types.ConfirmLiquidationQueryVariables>;
export const ConfirmTransferScheduleDocument = gql`
    query ConfirmTransferSchedule($sourceId: ID!, $destinationId: ID!, $schedule: RecurrenceScheduleInput, $date: String!) {
  viewer {
    transfers {
      scheduleDetails(schedule: $schedule) {
        description
      }
      isEvenWeek(date: $date)
    }
  }
  source: node(id: $sourceId) {
    id
    ...TransferParticipant
  }
  destination: node(id: $destinationId) {
    id
    ...TransferParticipant
  }
}
    ${TransferParticipantFragmentDoc}`;

/**
 * __useConfirmTransferScheduleQuery__
 *
 * To run a query within a React component, call `useConfirmTransferScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmTransferScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmTransferScheduleQuery({
 *   variables: {
 *      sourceId: // value for 'sourceId'
 *      destinationId: // value for 'destinationId'
 *      schedule: // value for 'schedule'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useConfirmTransferScheduleQuery(baseOptions: Apollo.QueryHookOptions<Types.ConfirmTransferScheduleQuery, Types.ConfirmTransferScheduleQueryVariables> & ({ variables: Types.ConfirmTransferScheduleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConfirmTransferScheduleQuery, Types.ConfirmTransferScheduleQueryVariables>(ConfirmTransferScheduleDocument, options);
      }
export function useConfirmTransferScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConfirmTransferScheduleQuery, Types.ConfirmTransferScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConfirmTransferScheduleQuery, Types.ConfirmTransferScheduleQueryVariables>(ConfirmTransferScheduleDocument, options);
        }
export function useConfirmTransferScheduleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.ConfirmTransferScheduleQuery, Types.ConfirmTransferScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.ConfirmTransferScheduleQuery, Types.ConfirmTransferScheduleQueryVariables>(ConfirmTransferScheduleDocument, options);
        }
export type ConfirmTransferScheduleQueryHookResult = ReturnType<typeof useConfirmTransferScheduleQuery>;
export type ConfirmTransferScheduleLazyQueryHookResult = ReturnType<typeof useConfirmTransferScheduleLazyQuery>;
export type ConfirmTransferScheduleSuspenseQueryHookResult = ReturnType<typeof useConfirmTransferScheduleSuspenseQuery>;
export type ConfirmTransferScheduleQueryResult = Apollo.QueryResult<Types.ConfirmTransferScheduleQuery, Types.ConfirmTransferScheduleQueryVariables>;
export const MoveMoneyStepDocument = gql`
    query MoveMoneyStep($type: MoveMoneyType!, $previousRouteName: String) {
  viewer {
    user {
      isPrimaryEmailVerified
    }
    transfers {
      moveMoney(type: $type, previousRouteName: $previousRouteName) {
        title
        subtitle
        backButtonLabel
        categories {
          label
          options {
            ...MoveMoneyOption
          }
        }
      }
    }
  }
}
    ${MoveMoneyOptionFragmentDoc}`;

/**
 * __useMoveMoneyStepQuery__
 *
 * To run a query within a React component, call `useMoveMoneyStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoveMoneyStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoveMoneyStepQuery({
 *   variables: {
 *      type: // value for 'type'
 *      previousRouteName: // value for 'previousRouteName'
 *   },
 * });
 */
export function useMoveMoneyStepQuery(baseOptions: Apollo.QueryHookOptions<Types.MoveMoneyStepQuery, Types.MoveMoneyStepQueryVariables> & ({ variables: Types.MoveMoneyStepQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MoveMoneyStepQuery, Types.MoveMoneyStepQueryVariables>(MoveMoneyStepDocument, options);
      }
export function useMoveMoneyStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MoveMoneyStepQuery, Types.MoveMoneyStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MoveMoneyStepQuery, Types.MoveMoneyStepQueryVariables>(MoveMoneyStepDocument, options);
        }
export function useMoveMoneyStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.MoveMoneyStepQuery, Types.MoveMoneyStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.MoveMoneyStepQuery, Types.MoveMoneyStepQueryVariables>(MoveMoneyStepDocument, options);
        }
export type MoveMoneyStepQueryHookResult = ReturnType<typeof useMoveMoneyStepQuery>;
export type MoveMoneyStepLazyQueryHookResult = ReturnType<typeof useMoveMoneyStepLazyQuery>;
export type MoveMoneyStepSuspenseQueryHookResult = ReturnType<typeof useMoveMoneyStepSuspenseQuery>;
export type MoveMoneyStepQueryResult = Apollo.QueryResult<Types.MoveMoneyStepQuery, Types.MoveMoneyStepQueryVariables>;
export const TransferParticipantCellDocument = gql`
    query TransferParticipantCell($id: ID!) {
  node(id: $id) {
    ...TransferParticipant
  }
}
    ${TransferParticipantFragmentDoc}`;

/**
 * __useTransferParticipantCellQuery__
 *
 * To run a query within a React component, call `useTransferParticipantCellQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferParticipantCellQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferParticipantCellQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTransferParticipantCellQuery(baseOptions: Apollo.QueryHookOptions<Types.TransferParticipantCellQuery, Types.TransferParticipantCellQueryVariables> & ({ variables: Types.TransferParticipantCellQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TransferParticipantCellQuery, Types.TransferParticipantCellQueryVariables>(TransferParticipantCellDocument, options);
      }
export function useTransferParticipantCellLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TransferParticipantCellQuery, Types.TransferParticipantCellQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TransferParticipantCellQuery, Types.TransferParticipantCellQueryVariables>(TransferParticipantCellDocument, options);
        }
export function useTransferParticipantCellSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TransferParticipantCellQuery, Types.TransferParticipantCellQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TransferParticipantCellQuery, Types.TransferParticipantCellQueryVariables>(TransferParticipantCellDocument, options);
        }
export type TransferParticipantCellQueryHookResult = ReturnType<typeof useTransferParticipantCellQuery>;
export type TransferParticipantCellLazyQueryHookResult = ReturnType<typeof useTransferParticipantCellLazyQuery>;
export type TransferParticipantCellSuspenseQueryHookResult = ReturnType<typeof useTransferParticipantCellSuspenseQuery>;
export type TransferParticipantCellQueryResult = Apollo.QueryResult<Types.TransferParticipantCellQuery, Types.TransferParticipantCellQueryVariables>;
export const TransferParticipantCellsDocument = gql`
    query TransferParticipantCells($ids: [ID!]!) {
  nodes(ids: $ids) {
    ...TransferParticipant
  }
}
    ${TransferParticipantFragmentDoc}`;

/**
 * __useTransferParticipantCellsQuery__
 *
 * To run a query within a React component, call `useTransferParticipantCellsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferParticipantCellsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferParticipantCellsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useTransferParticipantCellsQuery(baseOptions: Apollo.QueryHookOptions<Types.TransferParticipantCellsQuery, Types.TransferParticipantCellsQueryVariables> & ({ variables: Types.TransferParticipantCellsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TransferParticipantCellsQuery, Types.TransferParticipantCellsQueryVariables>(TransferParticipantCellsDocument, options);
      }
export function useTransferParticipantCellsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TransferParticipantCellsQuery, Types.TransferParticipantCellsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TransferParticipantCellsQuery, Types.TransferParticipantCellsQueryVariables>(TransferParticipantCellsDocument, options);
        }
export function useTransferParticipantCellsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.TransferParticipantCellsQuery, Types.TransferParticipantCellsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.TransferParticipantCellsQuery, Types.TransferParticipantCellsQueryVariables>(TransferParticipantCellsDocument, options);
        }
export type TransferParticipantCellsQueryHookResult = ReturnType<typeof useTransferParticipantCellsQuery>;
export type TransferParticipantCellsLazyQueryHookResult = ReturnType<typeof useTransferParticipantCellsLazyQuery>;
export type TransferParticipantCellsSuspenseQueryHookResult = ReturnType<typeof useTransferParticipantCellsSuspenseQuery>;
export type TransferParticipantCellsQueryResult = Apollo.QueryResult<Types.TransferParticipantCellsQuery, Types.TransferParticipantCellsQueryVariables>;
export const GetTransferViewerPreloadDocument = gql`
    query GetTransferViewerPreload($transferType: TransferTypeEnum, $fromParticipantId: ID, $toParticipantId: ID) {
  viewer {
    id
    transfers {
      requirements(
        fromParticipantId: $fromParticipantId
        toParticipantId: $toParticipantId
        transferType: $transferType
      ) {
        minTransferAmount
        requiresInitialDeposit
        canBeScheduledRule
        isCreditCardPaymentTransfer
        isLoanPaymentTransfer
        transferDueDateLabel
      }
    }
    credit {
      isAutoPayEnabled
    }
    borrow {
      personalLoans {
        isPersonalLoansAutoPayEnabled
      }
    }
  }
}
    `;

/**
 * __useGetTransferViewerPreloadQuery__
 *
 * To run a query within a React component, call `useGetTransferViewerPreloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransferViewerPreloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransferViewerPreloadQuery({
 *   variables: {
 *      transferType: // value for 'transferType'
 *      fromParticipantId: // value for 'fromParticipantId'
 *      toParticipantId: // value for 'toParticipantId'
 *   },
 * });
 */
export function useGetTransferViewerPreloadQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTransferViewerPreloadQuery, Types.GetTransferViewerPreloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTransferViewerPreloadQuery, Types.GetTransferViewerPreloadQueryVariables>(GetTransferViewerPreloadDocument, options);
      }
export function useGetTransferViewerPreloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTransferViewerPreloadQuery, Types.GetTransferViewerPreloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTransferViewerPreloadQuery, Types.GetTransferViewerPreloadQueryVariables>(GetTransferViewerPreloadDocument, options);
        }
export function useGetTransferViewerPreloadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetTransferViewerPreloadQuery, Types.GetTransferViewerPreloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetTransferViewerPreloadQuery, Types.GetTransferViewerPreloadQueryVariables>(GetTransferViewerPreloadDocument, options);
        }
export type GetTransferViewerPreloadQueryHookResult = ReturnType<typeof useGetTransferViewerPreloadQuery>;
export type GetTransferViewerPreloadLazyQueryHookResult = ReturnType<typeof useGetTransferViewerPreloadLazyQuery>;
export type GetTransferViewerPreloadSuspenseQueryHookResult = ReturnType<typeof useGetTransferViewerPreloadSuspenseQuery>;
export type GetTransferViewerPreloadQueryResult = Apollo.QueryResult<Types.GetTransferViewerPreloadQuery, Types.GetTransferViewerPreloadQueryVariables>;
export const GetAllAccountsDocument = gql`
    query GetAllAccounts {
  viewer {
    invest {
      hasInvestAccount
    }
    save {
      savings {
        hasSavingsAccounts
      }
    }
    crypto {
      hasCryptoAccount
    }
    credit {
      hasActiveAccount
    }
    borrow {
      hasBorrowAccounts
    }
  }
}
    `;

/**
 * __useGetAllAccountsQuery__
 *
 * To run a query within a React component, call `useGetAllAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAccountsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAllAccountsQuery, Types.GetAllAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllAccountsQuery, Types.GetAllAccountsQueryVariables>(GetAllAccountsDocument, options);
      }
export function useGetAllAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllAccountsQuery, Types.GetAllAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllAccountsQuery, Types.GetAllAccountsQueryVariables>(GetAllAccountsDocument, options);
        }
export function useGetAllAccountsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetAllAccountsQuery, Types.GetAllAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetAllAccountsQuery, Types.GetAllAccountsQueryVariables>(GetAllAccountsDocument, options);
        }
export type GetAllAccountsQueryHookResult = ReturnType<typeof useGetAllAccountsQuery>;
export type GetAllAccountsLazyQueryHookResult = ReturnType<typeof useGetAllAccountsLazyQuery>;
export type GetAllAccountsSuspenseQueryHookResult = ReturnType<typeof useGetAllAccountsSuspenseQuery>;
export type GetAllAccountsQueryResult = Apollo.QueryResult<Types.GetAllAccountsQuery, Types.GetAllAccountsQueryVariables>;
export const FinancialSuitabilityDocument = gql`
    query FinancialSuitability {
  viewer {
    profile {
      primary {
        ...ProfileDisclosureDetails
        ...AccountProfileEmployment
      }
      suitability {
        ...ProfileSuitabilities
      }
    }
  }
}
    ${ProfileDisclosureDetailsFragmentDoc}
${AccountProfileEmploymentFragmentDoc}
${ProfileSuitabilitiesFragmentDoc}`;

/**
 * __useFinancialSuitabilityQuery__
 *
 * To run a query within a React component, call `useFinancialSuitabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinancialSuitabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancialSuitabilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useFinancialSuitabilityQuery(baseOptions?: Apollo.QueryHookOptions<Types.FinancialSuitabilityQuery, Types.FinancialSuitabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FinancialSuitabilityQuery, Types.FinancialSuitabilityQueryVariables>(FinancialSuitabilityDocument, options);
      }
export function useFinancialSuitabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FinancialSuitabilityQuery, Types.FinancialSuitabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FinancialSuitabilityQuery, Types.FinancialSuitabilityQueryVariables>(FinancialSuitabilityDocument, options);
        }
export function useFinancialSuitabilitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.FinancialSuitabilityQuery, Types.FinancialSuitabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.FinancialSuitabilityQuery, Types.FinancialSuitabilityQueryVariables>(FinancialSuitabilityDocument, options);
        }
export type FinancialSuitabilityQueryHookResult = ReturnType<typeof useFinancialSuitabilityQuery>;
export type FinancialSuitabilityLazyQueryHookResult = ReturnType<typeof useFinancialSuitabilityLazyQuery>;
export type FinancialSuitabilitySuspenseQueryHookResult = ReturnType<typeof useFinancialSuitabilitySuspenseQuery>;
export type FinancialSuitabilityQueryResult = Apollo.QueryResult<Types.FinancialSuitabilityQuery, Types.FinancialSuitabilityQueryVariables>;
export const UserHasProductDocument = gql`
    query UserHasProduct {
  viewer {
    user {
      hasProduct
      hasOnboarded
    }
  }
}
    `;

/**
 * __useUserHasProductQuery__
 *
 * To run a query within a React component, call `useUserHasProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHasProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHasProductQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserHasProductQuery(baseOptions?: Apollo.QueryHookOptions<Types.UserHasProductQuery, Types.UserHasProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserHasProductQuery, Types.UserHasProductQueryVariables>(UserHasProductDocument, options);
      }
export function useUserHasProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserHasProductQuery, Types.UserHasProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserHasProductQuery, Types.UserHasProductQueryVariables>(UserHasProductDocument, options);
        }
export function useUserHasProductSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UserHasProductQuery, Types.UserHasProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UserHasProductQuery, Types.UserHasProductQueryVariables>(UserHasProductDocument, options);
        }
export type UserHasProductQueryHookResult = ReturnType<typeof useUserHasProductQuery>;
export type UserHasProductLazyQueryHookResult = ReturnType<typeof useUserHasProductLazyQuery>;
export type UserHasProductSuspenseQueryHookResult = ReturnType<typeof useUserHasProductSuspenseQuery>;
export type UserHasProductQueryResult = Apollo.QueryResult<Types.UserHasProductQuery, Types.UserHasProductQueryVariables>;
export const UserIdentityStatusDocument = gql`
    query UserIdentityStatus {
  viewer {
    profile {
      identityStatus
    }
  }
}
    `;

/**
 * __useUserIdentityStatusQuery__
 *
 * To run a query within a React component, call `useUserIdentityStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIdentityStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIdentityStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserIdentityStatusQuery(baseOptions?: Apollo.QueryHookOptions<Types.UserIdentityStatusQuery, Types.UserIdentityStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserIdentityStatusQuery, Types.UserIdentityStatusQueryVariables>(UserIdentityStatusDocument, options);
      }
export function useUserIdentityStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserIdentityStatusQuery, Types.UserIdentityStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserIdentityStatusQuery, Types.UserIdentityStatusQueryVariables>(UserIdentityStatusDocument, options);
        }
export function useUserIdentityStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UserIdentityStatusQuery, Types.UserIdentityStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UserIdentityStatusQuery, Types.UserIdentityStatusQueryVariables>(UserIdentityStatusDocument, options);
        }
export type UserIdentityStatusQueryHookResult = ReturnType<typeof useUserIdentityStatusQuery>;
export type UserIdentityStatusLazyQueryHookResult = ReturnType<typeof useUserIdentityStatusLazyQuery>;
export type UserIdentityStatusSuspenseQueryHookResult = ReturnType<typeof useUserIdentityStatusSuspenseQuery>;
export type UserIdentityStatusQueryResult = Apollo.QueryResult<Types.UserIdentityStatusQuery, Types.UserIdentityStatusQueryVariables>;
export const GetUserKeysDocument = gql`
    query GetUserKeys {
  viewer {
    user {
      ...UserKeys
    }
  }
}
    ${UserKeysFragmentDoc}`;

/**
 * __useGetUserKeysQuery__
 *
 * To run a query within a React component, call `useGetUserKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserKeysQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetUserKeysQuery, Types.GetUserKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserKeysQuery, Types.GetUserKeysQueryVariables>(GetUserKeysDocument, options);
      }
export function useGetUserKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserKeysQuery, Types.GetUserKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserKeysQuery, Types.GetUserKeysQueryVariables>(GetUserKeysDocument, options);
        }
export function useGetUserKeysSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetUserKeysQuery, Types.GetUserKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetUserKeysQuery, Types.GetUserKeysQueryVariables>(GetUserKeysDocument, options);
        }
export type GetUserKeysQueryHookResult = ReturnType<typeof useGetUserKeysQuery>;
export type GetUserKeysLazyQueryHookResult = ReturnType<typeof useGetUserKeysLazyQuery>;
export type GetUserKeysSuspenseQueryHookResult = ReturnType<typeof useGetUserKeysSuspenseQuery>;
export type GetUserKeysQueryResult = Apollo.QueryResult<Types.GetUserKeysQuery, Types.GetUserKeysQueryVariables>;
export const GetUserKeysByKeyDocument = gql`
    query GetUserKeysByKey($keys: [String!]!) {
  viewer {
    user {
      data(keys: $keys) {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useGetUserKeysByKeyQuery__
 *
 * To run a query within a React component, call `useGetUserKeysByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserKeysByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserKeysByKeyQuery({
 *   variables: {
 *      keys: // value for 'keys'
 *   },
 * });
 */
export function useGetUserKeysByKeyQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserKeysByKeyQuery, Types.GetUserKeysByKeyQueryVariables> & ({ variables: Types.GetUserKeysByKeyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserKeysByKeyQuery, Types.GetUserKeysByKeyQueryVariables>(GetUserKeysByKeyDocument, options);
      }
export function useGetUserKeysByKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserKeysByKeyQuery, Types.GetUserKeysByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserKeysByKeyQuery, Types.GetUserKeysByKeyQueryVariables>(GetUserKeysByKeyDocument, options);
        }
export function useGetUserKeysByKeySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetUserKeysByKeyQuery, Types.GetUserKeysByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetUserKeysByKeyQuery, Types.GetUserKeysByKeyQueryVariables>(GetUserKeysByKeyDocument, options);
        }
export type GetUserKeysByKeyQueryHookResult = ReturnType<typeof useGetUserKeysByKeyQuery>;
export type GetUserKeysByKeyLazyQueryHookResult = ReturnType<typeof useGetUserKeysByKeyLazyQuery>;
export type GetUserKeysByKeySuspenseQueryHookResult = ReturnType<typeof useGetUserKeysByKeySuspenseQuery>;
export type GetUserKeysByKeyQueryResult = Apollo.QueryResult<Types.GetUserKeysByKeyQuery, Types.GetUserKeysByKeyQueryVariables>;
export const LatestLegalAgreementsDocument = gql`
    query LatestLegalAgreements {
  viewer {
    latestAgreements {
      signature
      documents {
        title
        url
      }
    }
  }
}
    `;

/**
 * __useLatestLegalAgreementsQuery__
 *
 * To run a query within a React component, call `useLatestLegalAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestLegalAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestLegalAgreementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestLegalAgreementsQuery(baseOptions?: Apollo.QueryHookOptions<Types.LatestLegalAgreementsQuery, Types.LatestLegalAgreementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LatestLegalAgreementsQuery, Types.LatestLegalAgreementsQueryVariables>(LatestLegalAgreementsDocument, options);
      }
export function useLatestLegalAgreementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LatestLegalAgreementsQuery, Types.LatestLegalAgreementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LatestLegalAgreementsQuery, Types.LatestLegalAgreementsQueryVariables>(LatestLegalAgreementsDocument, options);
        }
export function useLatestLegalAgreementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LatestLegalAgreementsQuery, Types.LatestLegalAgreementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LatestLegalAgreementsQuery, Types.LatestLegalAgreementsQueryVariables>(LatestLegalAgreementsDocument, options);
        }
export type LatestLegalAgreementsQueryHookResult = ReturnType<typeof useLatestLegalAgreementsQuery>;
export type LatestLegalAgreementsLazyQueryHookResult = ReturnType<typeof useLatestLegalAgreementsLazyQuery>;
export type LatestLegalAgreementsSuspenseQueryHookResult = ReturnType<typeof useLatestLegalAgreementsSuspenseQuery>;
export type LatestLegalAgreementsQueryResult = Apollo.QueryResult<Types.LatestLegalAgreementsQuery, Types.LatestLegalAgreementsQueryVariables>;
export const UserCryptoEligibilityDocument = gql`
    query UserCryptoEligibility {
  viewer {
    crypto {
      isEnabled
      isEligible
    }
  }
}
    `;

/**
 * __useUserCryptoEligibilityQuery__
 *
 * To run a query within a React component, call `useUserCryptoEligibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCryptoEligibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCryptoEligibilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCryptoEligibilityQuery(baseOptions?: Apollo.QueryHookOptions<Types.UserCryptoEligibilityQuery, Types.UserCryptoEligibilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserCryptoEligibilityQuery, Types.UserCryptoEligibilityQueryVariables>(UserCryptoEligibilityDocument, options);
      }
export function useUserCryptoEligibilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserCryptoEligibilityQuery, Types.UserCryptoEligibilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserCryptoEligibilityQuery, Types.UserCryptoEligibilityQueryVariables>(UserCryptoEligibilityDocument, options);
        }
export function useUserCryptoEligibilitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UserCryptoEligibilityQuery, Types.UserCryptoEligibilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UserCryptoEligibilityQuery, Types.UserCryptoEligibilityQueryVariables>(UserCryptoEligibilityDocument, options);
        }
export type UserCryptoEligibilityQueryHookResult = ReturnType<typeof useUserCryptoEligibilityQuery>;
export type UserCryptoEligibilityLazyQueryHookResult = ReturnType<typeof useUserCryptoEligibilityLazyQuery>;
export type UserCryptoEligibilitySuspenseQueryHookResult = ReturnType<typeof useUserCryptoEligibilitySuspenseQuery>;
export type UserCryptoEligibilityQueryResult = Apollo.QueryResult<Types.UserCryptoEligibilityQuery, Types.UserCryptoEligibilityQueryVariables>;