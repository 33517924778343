import { Box, styled } from '@m1/liquid-react';

import { AnnouncementTag } from '../AnnouncementTag';

export const StyledHeaderBox = styled(Box)`
  background-color: ${(props) => props.theme.colors.gradientLinearFeature};
  border-radius: 10px 10px 0 0;
  padding: 32px 0;
  position: 'relative';
  text-align: center;
  width: 100%;
`;

export const StyledAnnouncementTag = styled(AnnouncementTag)`
  position: absolute;
  top: 16px;
  left: 16px;
`;
