export const SELECTED_SLICES_ACTIONS = Object.freeze({
  CLICKED_SLICEABLE_SELECTOR: 'CLICKED_SLICEABLE_SELECTOR' as const,
  START_ADD_SLICES: 'START_ADD_SLICES' as const,
  FINISHED_ADD_SLICES: 'FINISHED_ADD_SLICES' as const,
});

export type ClickedSliceableSelectorAction = {
  payload: unknown;
  type: typeof SELECTED_SLICES_ACTIONS.CLICKED_SLICEABLE_SELECTOR;
};

export type StartAddSlicesAction = {
  payload: unknown;
  type: typeof SELECTED_SLICES_ACTIONS.START_ADD_SLICES;
};

export type FinishedAddSlicesAction = {
  payload: unknown;
  type: typeof SELECTED_SLICES_ACTIONS.FINISHED_ADD_SLICES;
};

export const clickedSliceableSelector = (
  payload: ClickedSliceableSelectorAction['payload'],
): ClickedSliceableSelectorAction => ({
  payload,
  type: SELECTED_SLICES_ACTIONS.CLICKED_SLICEABLE_SELECTOR,
});

export const startAddSlices = (
  payload: StartAddSlicesAction['payload'],
): StartAddSlicesAction => ({
  payload,
  type: SELECTED_SLICES_ACTIONS.START_ADD_SLICES,
});

export const finishedAddSlices = (
  payload: FinishedAddSlicesAction['payload'],
): FinishedAddSlicesAction => ({
  payload,
  type: SELECTED_SLICES_ACTIONS.FINISHED_ADD_SLICES,
});

export type SelectedSlicesAction =
  | ClickedSliceableSelectorAction
  | StartAddSlicesAction
  | FinishedAddSlicesAction;
