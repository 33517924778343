import { useForm, useFormContext } from 'react-hook-form';

export type InitialFundingForm = {
  achRelationshipId: string | null;
};

export const useInitialFundingForm = () => {
  const formMethods = useForm<InitialFundingForm>({
    mode: 'onChange',
    defaultValues: {
      achRelationshipId: null,
    },
  });

  return formMethods;
};

export const useInitialFundingFormContext = () => {
  const initialFundingFormContext = useFormContext<InitialFundingForm>();

  if (!initialFundingFormContext) {
    throw new Error(
      'You must use useInitialFundingFormContext within InitialFundingFormProvider!',
    );
  }

  return initialFundingFormContext;
};
