import { Box, Button, Flex, HXS, PL, PM, Card } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import moment from 'moment';
import * as React from 'react';

import { OnDemandTradingStatusCardFragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';

type CryptoTradingStatusProps = {
  isCrypto: boolean;
  portfolioSliceId: string;
  trading: OnDemandTradingStatusCardFragment | null | undefined;
};

function getNumberOfTradesCardCopy(
  onDemandTradeWindowsLeft: number,
  maxOnDemandTradeWindows: number,
): string {
  if (onDemandTradeWindowsLeft <= 0) {
    return `You’re out of on-demand trade windows. You’ll get ${maxOnDemandTradeWindows} more on-demand trade windows for crypto on ${moment()
      .add(1, 'M')
      .startOf('month')
      .format('MMM D')}.`;
  }

  return `You have ${onDemandTradeWindowsLeft} of ${maxOnDemandTradeWindows} on-demand trade windows for crypto remaining this month.`;
}

export const OnDemandTradingStatusCard = ({
  isCrypto,
  portfolioSliceId,
  trading,
}: CryptoTradingStatusProps) => {
  const navigate = useNavigate();

  if (!isCrypto || !trading) {
    return null;
  }

  const {
    isInVendorMaintenanceWindow,
    maxOnDemandTradeWindows,
    onDemandTradeWindowsLeft,
  } = trading;
  if (isInVendorMaintenanceWindow) {
    return (
      <Box mt={32}>
        <HXS content="Want to trade crypto now?" />
        <Card mt={16} px={12} py={16}>
          <Flex>
            <Flex flexDirection="column" justifyContent="flex-start" pt={4}>
              <Icon name="onDemand32" />
            </Flex>
            <PM
              pl={8}
              content="Scheduled maintenance runs between 4:30pm to 5:05pm CT. You can run an on-demand trade window after."
            />
          </Flex>
        </Card>
      </Box>
    );
  }

  if (
    typeof onDemandTradeWindowsLeft !== 'number' ||
    typeof maxOnDemandTradeWindows !== 'number'
  ) {
    return null;
  }

  return (
    <Card mt={16}>
      <Flex p={24}>
        <Box>
          <PL fontWeight={600} content="On-demand trades" mb={8} />
          <PM
            mb={20}
            fontWeight={400}
            color="foregroundNeutralSecondary"
            content={getNumberOfTradesCardCopy(
              onDemandTradeWindowsLeft,
              maxOnDemandTradeWindows,
            )}
          />
          <Box>
            <Button
              label="Review on-demand window"
              kind="secondary"
              size="medium"
              onClick={() => {
                navigate({
                  to: '/d/invest/portfolio/:portfolioSliceId/on-demand-trading',
                  params: { portfolioSliceId },
                });
              }}
            />
          </Box>
        </Box>
      </Flex>
    </Card>
  );
};
