// ERROR STEPS

export const SMART_TRANSFER_STEPS = {
  INTRODUCE_SMART_TRANSFER: 'introduce-smart-transfer',
  INITIALIZE_SMART_TRANSFER: 'initialize-smart-transfer',
  LOADING: 'loading',
  SELECT_FOCUS_ACCOUNT: 'select-focus-account',
  SET_SMART_TRANSFER: 'set-smart-transfer',
  SET_FULFILLMENT_CONDITION: 'set-fulfillment-condition',
  CONFIRM_SMART_TRANSFER: 'confirm-smart-transfer',
  SMART_TRANSFER_RECEIPT: 'smart-transfer-receipt',

  // ERROR STATES
  REMOTE_ERROR: 'remote-error',
};
