import {
  useSkeleton,
  Button,
  ButtonProps,
  ButtonKind,
  ButtonSize,
} from '@m1/liquid-react';
import { MonochromaticIconName } from '@m1/liquid-react/icons';
import * as React from 'react';

import {
  useLinkable,
  OnClickLinkable,
  LinkableFragments,
} from '~/hooks/useLinkable';

import { LinkProps } from '~/toolbox/link';

import { NavigableButton } from '../../components/NavigableButton';

export type LinkableButtonProps = OmitUnion<
  ButtonProps,
  'label' | 'kind' | 'size'
> &
  OmitUnion<LinkProps, 'to' | 'kind'> & {
    linkable: LinkableFragments;
    onClick?: OnClickLinkable;
    kind?: ButtonKind;
    size?: ButtonSize;
  };

export type LinkableSkeletonButtonProps = OmitUnion<
  LinkableButtonProps,
  'linkable'
> & {
  linkable: Maybe<LinkableFragments>;
};

export const LinkableButton = ({
  linkable,
  onClick,
  ...rest
}: LinkableButtonProps) => {
  const { routeName, onClickLinkable, queryParams } = useLinkable(
    linkable,
    onClick,
  );

  const buttonHref = 'url' in linkable ? linkable.url : '';

  return routeName ? (
    // @ts-ignore
    <NavigableButton
      {...rest}
      label={linkable.title}
      onClick={onClickLinkable}
      leftIcon={
        'icon' in linkable && linkable.icon?.names?.[0]
          ? (linkable.icon.names[0] as MonochromaticIconName<'24'>)
          : undefined
      }
      to={routeName}
      query={queryParams}
    />
  ) : (
    // @ts-ignore
    <NavigableButton
      {...rest}
      label={linkable.title}
      onClick={onClickLinkable}
      leftIcon={
        'icon' in linkable && linkable.icon?.names?.[0]
          ? (linkable.icon.names[0] as MonochromaticIconName<'24'>)
          : undefined
      }
      to={buttonHref}
      target="_blank"
    />
  );
};

/** Use this and wrap this in a Skeleton for nullable linkable  */
export const LinkableSkeletonButton = ({
  linkable,
  ...rest
}: LinkableSkeletonButtonProps) => {
  const { isLoading } = useSkeleton();

  if (linkable) {
    return <LinkableButton linkable={linkable} {...rest} />;
  } else if (isLoading && !linkable) {
    // The `size` prop is causing an error, but it matches the `ButtonSize`
    // prop for the `Button`, so the type error is a false positive. This may
    // be caused by the `size` being part of an object type union in LIRE.
    // @ts-ignore
    return <Button {...rest} disabled label="Loading" kind="link" />;
  }
  return null;
};
