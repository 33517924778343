import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import { DashboardNavigation } from '~/hooks/useDashboardNavigation';

import { useLocation } from '~/hooks/useLocation';

import { TopNavLink } from './TopNavLink';

export const TopNavTransfers = ({
  selectedNavigation,
}: {
  selectedNavigation: DashboardNavigation;
}) => {
  const location = useLocation();
  const items = location.pathname.includes('/transfers/rollovers')
    ? []
    : [
        {
          id: 'activity',
          to: '/d/transfers/activity',
          label: 'All transfers',
        },
        {
          id: 'rules',
          to: '/d/transfers/rules',
          label: 'Recurring',
        },
      ];

  return (
    <Flex flexDirection="row">
      {items.map((item) => {
        return (
          <TopNavLink
            selected={
              selectedNavigation.activeL1Nav === 'transfers' &&
              selectedNavigation.activeL3Nav === item.id
            }
            key={item.to}
            label={item.label}
            linkTo={item.to}
          />
        );
      })}
    </Flex>
  );
};
