import { Modal } from '@m1/liquid-react';
import * as React from 'react';

import { useModal } from '~/hooks/useModal';
import { confirmCancelOrder } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

import { OrderActionCancelModalContent } from './OrderActionCancelModalContent';

export const OrderActionCancelModal = () => {
  const { hideModal, modalState } = useModal('ORDER_ACTION_CANCEL');
  const dispatch = useDispatch();
  const handleConfirm = () => dispatch(confirmCancelOrder(modalState.payload));
  return (
    <Modal open={modalState.isOpened} onClose={hideModal}>
      {modalState.payload && (
        <OrderActionCancelModalContent
          portfolioSliceId={modalState.payload}
          onCancel={hideModal}
          onConfirm={handleConfirm}
        />
      )}
    </Modal>
  );
};
