export function generateRandomSSN(): string {
  const ssn = '66600';

  // random number between 0 and 1
  const randomFraction = Math.random();

  // scale the random number to be between 0 and 9999
  const scaledNumber = Math.floor(randomFraction * 10000);

  // ensure it has exactly 4 digits by padding with leading zeros if necessary
  const fourRandomDigits = String(scaledNumber).padStart(4, '0');

  // concat with the static amount
  return ssn + fourRandomDigits;
}
