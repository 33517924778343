import { styled, Text } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';

import { LinkProps } from './Link.types';

export const StyledLink = styled(Text)<{
  $disabled: LinkProps['disabled'];
  $iconPosition: LinkProps['iconPosition'] | undefined;
  $kind: LinkProps['kind'];
  $underline: LinkProps['underline'];
}>`
  cursor: pointer;
  display: inline-flex;
  ${(props) => {
    if (props.$underline) {
      return 'text-decoration: underline;';
    }
  }}

  ${(props) => {
    if (props.$iconPosition === 'left') {
      return 'flex-direction: row-reverse;';
    }
  }} ${(props) => {
    if (props.$disabled) {
      return `
      color: ${props.theme.colors.foregroundNeutralTertiary};
      cursor: not-allowed;
    `;
    }
    if (props.$kind === 'inverse') {
      return `
        color: ${props.theme.colors.primaryTint};
        &:hover {
          color: ${props.theme.colors.primaryTint};
        }
      `;
    } else if (props.$kind === 'secondary') {
      return `
        color: ${props.theme.colors.foregroundNeutralMain};
        &:hover {
          color: ${props.theme.colors.foregroundNeutralMain};
        }
      `;
    } else if (props.$kind === 'plus') {
      return `
        color: ${props.theme.colors.m1Plus};
        &:hover {
          color: ${props.theme.colors.m1Plus};
        }
      `;
    } else if (props.$kind === 'plus-accent') {
      return `
        color: ${props.theme.colors.m1Plus};
      `;
    } else if (props.$kind === 'warning') {
      return `
      color: ${props.theme.colors.warningShade};
    `;
    } else if (props.$kind === 'critical') {
      return `
      color: ${props.theme.colors.critical};
    `;
    }
    return `
      color: ${props.theme.colors.primary};
      &:hover {
        color: ${props.theme.colors.primaryShade};
      }
    `;
  }};
`;

export const StyledLinkIcon = styled(Icon)<{
  $iconPosition: LinkProps['iconPosition'] | undefined;
}>`
  position: relative;
  top: 1px;

  ${(props) => {
    switch (props.$iconPosition) {
      case 'left':
        return 'padding-right: 8px;';
      case 'right':
        return 'padding-left: 8px;';
      default:
        return null;
    }
  }};
`;
