import get from 'lodash-es/get';
import isPlainObject from 'lodash-es/isPlainObject';
import mapValues from 'lodash-es/mapValues';
import pickBy from 'lodash-es/pickBy';
import set from 'lodash-es/set';

import { isNotNil } from './typeAssertions';

export function pickByPaths(
  source: Record<string, any>,
  paths: Array<string | [string, string]>,
): Record<string, any> {
  const obj = {};
  for (const path of paths) {
    if (Array.isArray(path)) {
      const [destinationPath, sourcePath] = path;
      set(obj, destinationPath, get(source, sourcePath));
    } else {
      set(obj, path, get(source, path));
    }
  }
  return obj;
}

export function deepPickBy(
  obj: Record<string, any>,
  predicate: (...args: Array<any>) => any,
): Record<string, any> {
  const _obj = mapValues(obj, (v) => {
    if (Array.isArray(v)) {
      return v.map((item) => {
        return isPlainObject(item) ? deepPickBy(item, predicate) : item;
      });
    }

    if (isPlainObject(v)) {
      return deepPickBy(v, predicate);
    }

    return v;
  });

  return pickBy(_obj, predicate);
}

/**
 * A util that takes in a record of string -> any and removes all nil values (null, undefined) from it.
 * @param query - A Record<string, any> that may contain nil values.
 * @returns - A Record<string, NonNullable<T>>, i.e a record that does not contain nil values.
 */
export const filterNilValues = <T>(
  query: Record<string, T> | undefined,
): Record<string, NonNullable<T>> => {
  const initialQuery: Record<string, NonNullable<T>> = {};

  if (!query) {
    return initialQuery;
  }

  return Object.entries(query).reduce((agg, [key, value]) => {
    if (isNotNil(value)) {
      agg[key] = value;
    }
    return agg;
  }, initialQuery);
};
