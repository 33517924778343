import * as React from 'react';

import { RegisterUserForm } from '~/forms/register-user-form';
import { UNSAFE_connectRedux } from '~/hocs';
import { submitRegisterUserForm } from '~/redux/actions';

type Props = {
  autoFocus: boolean;
  dispatch: (...args: Array<any>) => any;
};

class CollectUserDetailsComponent extends React.Component<Props> {
  static defaultProps = {
    autoFocus: false,
  };
  render() {
    return (
      <RegisterUserForm
        onSubmit={this.handleSubmit}
        autoFocus={this.props.autoFocus}
      />
    );
  }

  handleSubmit = (values: Record<string, any>): void =>
    this.props.dispatch(submitRegisterUserForm(values));
}

const enhancer = UNSAFE_connectRedux();

export const CollectUserDetails = enhancer(CollectUserDetailsComponent) as any;
