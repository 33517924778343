import { Button, Flex, HM, PL, PM } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { useIraRolloverTypeQuery } from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useBreakpoints } from '~/hooks/useBreakpoints';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { Spinner } from '~/toolbox/spinner';
import { isNotNil } from '~/utils';

import { AccountSelectionCard } from '../components/AccountSelectionCard';
import { IRAContributionHelpModal } from '../components/IRAContributionHelpModal';
import { useIRAWizardContext } from '../hooks/useIRAWizardContext';
import { RetirementAccountValues } from '../types';

export type SelectIRARolloverTypeStepProps = {
  onWizardComplete: (value: RetirementAccountValues) => void;
};

export const SelectIRARolloverTypeStep = ({
  onWizardComplete,
}: SelectIRARolloverTypeStepProps) => {
  const analytics = useAnalytics();
  const { isMedium } = useBreakpoints();
  const { goTo } = useIRAWizardContext();
  const { data, loading } = useIraRolloverTypeQuery();
  const [helpModalIsOpen, setHelpModalIsOpen] = React.useState(false);

  const iraRolloverTypeSelectionScreen =
    data?.viewer.onboarding?.onboardingInvest.iraOnboarding
      .iraRolloverTypeSelectionScreen;

  const analyticsEvent =
    iraRolloverTypeSelectionScreen?.screenViewedAnalyticsEvent;

  React.useEffect(() => {
    if (analyticsEvent) {
      analytics.recordAppAnalyticsEvent(analyticsEvent);
    }
  }, [analytics, analyticsEvent]);

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!iraRolloverTypeSelectionScreen) {
    return <GenericSystemError />;
  }

  const iraRolloverTypes = iraRolloverTypeSelectionScreen?.rolloverTypes;

  return (
    <Flex
      flexDirection="column"
      maxWidth={1200}
      mx="auto"
      mt={88}
      px={isMedium ? 48 : 0}
      width="100%"
    >
      <BackArrow
        alignSelf="flex-start"
        content="Back"
        mb={48}
        onClick={() => {
          goTo('SELECT_IRA_FUNDING_TYPE');
        }}
      />
      <Flex flexDirection="column" width="100%" maxWidth={588} mx="auto">
        <Flex flexDirection="column" width="100%" mb={24}>
          <HM content={iraRolloverTypeSelectionScreen.title} mb={8} />
          <PL content={iraRolloverTypeSelectionScreen.subtitle} mb={48} />
          <>
            {iraRolloverTypes.filter(isNotNil).map((card, index) => (
              <AccountSelectionCard
                key={index}
                card={card}
                onClick={onWizardComplete}
              />
            ))}
          </>
        </Flex>
        {iraRolloverTypeSelectionScreen.helpSection && (
          <Button
            kind="link"
            label="Did you make both types of contributions?"
            mb={32}
            onClick={() => {
              setHelpModalIsOpen(true);
            }}
          />
        )}
        {iraRolloverTypeSelectionScreen.learnMoreLink && (
          <LinkableLink
            linkable={iraRolloverTypeSelectionScreen.learnMoreLink}
            mb={32}
          />
        )}
        {iraRolloverTypeSelectionScreen.disclosures.map((disclosure, index) => (
          <PM
            color="foregroundNeutralSecondary"
            content={disclosure}
            key={index}
          />
        ))}
      </Flex>
      <IRAContributionHelpModal
        onCancel={() => {
          setHelpModalIsOpen(false);
        }}
        open={helpModalIsOpen}
      />
    </Flex>
  );
};
