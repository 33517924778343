import { Box } from '@m1/liquid-react';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useWizard, WizardProvider } from '~/flows/wizard';
import {
  CompletePhoneVerificationInput,
  InitiatePhoneVerificationInput,
  PhoneVerificationChannelEnum,
} from '~/graphql/types';
import { useDispatch } from '~/redux/hooks';
import { PHONE_VERIFICATION_FLOW_STEPS as STEPS } from '~/static-constants';
import { Grid } from '~/toolbox/grid';

import { CollectPhoneNumber } from './steps/CollectPhoneNumber';
import { VerifyReceivedCodeStep } from './steps/VerifyReceivedCodeStep';

type PhoneVerificationWizardSteps = Record<
  keyof typeof STEPS,
  React.ReactElement
>;

type PhoneVerificationWizardProps = {
  onWizardCompletion: (args?: any) => void;
};

export const PhoneVerificationWizard = ({
  onWizardCompletion,
}: PhoneVerificationWizardProps) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch({
      type: 'START_FLOW',
      payload: {
        flow: 'PHONE_VERIFICATION',
      },
    });
  }, [dispatch]);

  const steps: PhoneVerificationWizardSteps = {
    COLLECT_PHONE_NUMBER: <CollectPhoneNumber />,
    VERIFY_RECEIVED_CODE: (
      <VerifyReceivedCodeStep onFinishStep={onWizardCompletion} />
    ),
  };

  const form = useForm<
    InitiatePhoneVerificationInput & CompletePhoneVerificationInput
  >({
    defaultValues: {
      channel: PhoneVerificationChannelEnum.Sms,
      code: '',
      phone: '',
    },
  });

  const wizard = useWizard(steps);

  return (
    <Box mt={40}>
      <Grid constrain>
        <Grid.Row>
          <Grid.Col xs={12}>
            <Box width={490} margin="0 auto">
              <FormProvider {...form}>
                <WizardProvider value={{ ...wizard }}>
                  {wizard.step}
                </WizardProvider>
              </FormProvider>
            </Box>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Box>
  );
};
