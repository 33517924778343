import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useLoanApplicationRejectedQuery } from '~/graphql/hooks';
import { LinkableButton } from '~/lens-toolbox/LinkableButton';
import { Spinner } from '~/toolbox/spinner';

import { StepTemplate } from '../components/StepTemplate';

type ApplicationRejectedRefreshProps = {
  onFinishStep: () => void;
};

export const ApplicationRejected = ({
  onFinishStep,
}: ApplicationRejectedRefreshProps) => {
  const { data, loading } = useLoanApplicationRejectedQuery();

  if (loading) {
    return <Spinner fullScreen centered />;
  }
  const { title, subtitle, ctaLink } =
    data?.viewer.borrow?.personalLoans?.applicationFlow
      ?.loanDeclinedErrorScreen ?? {};

  if (!title || !subtitle || !ctaLink) {
    return <GenericSystemError />;
  }

  return (
    <StepTemplate
      title={title}
      content={subtitle}
      button={
        <LinkableButton
          onClick={onFinishStep}
          kind="primary"
          linkable={ctaLink}
          size="large"
        />
      }
      illustrationName="loanNotApproved"
    />
  );
};
