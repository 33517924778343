import {
  AddBeneficiaryAction,
  BENEFICIARY_ACTIONS,
  CleanupBeneficiariesAction,
  PrepareBeneficiariesAction,
  UpdateBeneficiaryAction,
} from './beneficiariesActions.types';

export const prepareBeneficiaries = (
  payload: PrepareBeneficiariesAction['payload'],
): PrepareBeneficiariesAction => ({
  payload,
  type: BENEFICIARY_ACTIONS.PREPARE_BENEFICIARIES,
});

export const cleanupBeneficiaries = (
  payload: CleanupBeneficiariesAction['payload'],
): CleanupBeneficiariesAction => ({
  payload,
  type: BENEFICIARY_ACTIONS.CLEANUP_BENEFICIARIES,
});

export const addBeneficiary = (
  payload: AddBeneficiaryAction['payload'],
): AddBeneficiaryAction => ({
  payload,
  type: BENEFICIARY_ACTIONS.ADD_BENEFICIARY,
});

export const updateBeneficiary = (
  payload: UpdateBeneficiaryAction['payload'],
): UpdateBeneficiaryAction => ({
  payload,
  type: BENEFICIARY_ACTIONS.UPDATE_BENEFICIARY,
});
