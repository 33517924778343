import { Button, Flex } from '@m1/liquid-react';
import * as React from 'react';

import { useNavigate } from '~/hooks/useNavigate';
import {
  confirmedPieOrganizerExit,
  savePieOrganizerAction,
} from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

import { PortfolioOrganizerExitConfirmationModal } from './PortfolioOrganizerExitConfirmationModal';
import { PortfolioOrganizerSaveButton } from './PortfolioOrganizerSaveButton';

export type PortfolioOrganizerActionButtonsProps = {
  setShowConfirmationDialog: (showDialog: boolean) => void;
};

export const PortfolioOrganizerActionButtons = ({
  setShowConfirmationDialog,
}: PortfolioOrganizerActionButtonsProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isExitModalOpen, setIsExitModalOpen] = React.useState<boolean>(false);
  const hasInvestAccount = useSelector<boolean>((state) =>
    Boolean(state.global.investAccountId),
  );

  const { buttons, isEditing, onExit } = useSelector<{
    buttons?: {
      primary: string;
      secondary?: string;
    };
    isEditing: boolean;
    onExit: (() => void) | null | undefined;
  }>((state) => {
    const { buttons, onExit, session } = state.portfolioOrganizer;

    return {
      buttons: buttons && {
        primary: buttons.primary,
        secondary: buttons.secondary,
      },
      onExit,
      isEditing:
        session.mode === 'EDIT_PIE' || session.mode === 'EDIT_ROOT_PIE',
    };
  });

  const accountId = useSelector<string | null | undefined>(
    (state) => state.global.activeAccountId,
  );

  return (
    <Flex>
      <ButtonGroup>
        {(!buttons || buttons.secondary) && (
          <Button
            kind="inverse-secondary"
            label={buttons?.secondary || 'Exit'}
            onClick={() => {
              setIsExitModalOpen(true);
            }}
          />
        )}
        <PortfolioOrganizerSaveButton
          onClick={() =>
            isEditing
              ? setShowConfirmationDialog(true)
              : dispatch(savePieOrganizerAction(accountId))
          }
          primaryButtonLabel={buttons?.primary}
        />
      </ButtonGroup>
      <PortfolioOrganizerExitConfirmationModal
        onCancel={() => {
          setIsExitModalOpen(false);
        }}
        onConfirm={() => {
          setIsExitModalOpen(false);

          // Reset the pie organizer state since we're leaving
          dispatch(confirmedPieOrganizerExit());

          // If an onExit callback was handed on initialization call that else navigate
          if (onExit) {
            onExit();
          } else if (hasInvestAccount) {
            navigate({ to: '/d/invest' });
          } else {
            navigate({ to: '/d/research/my-pies' });
          }
        }}
        open={isExitModalOpen}
      />
    </Flex>
  );
};
