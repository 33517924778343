import { useTheme } from '@m1/liquid-react';
import * as React from 'react';

import { highlightSlice } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { PieProps, Pie } from '~/toolbox/pie';

export const ConnectedPie = (props: PieProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const highlightedSlice = useSelector(
    (state) => state.interface.highlightedSliceId,
  );

  return (
    <Pie
      {...props}
      highlightSlice={(id: string | null | undefined) =>
        dispatch(highlightSlice(id))
      }
      highlightedSlice={highlightedSlice}
      theme={theme}
    />
  );
};
