import { Color, useTheme, css, styled } from '@m1/liquid-react';
import * as React from 'react';

const SpinnerContainer = styled.div<{
  $centered: boolean;
  $containerHeight: string;
  $display?: string;
}>`
  ${({ $centered }) => {
    return (
      $centered &&
      css`
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      `
    );
  }}
  display: ${({ $display }) => $display};
  height: ${({ $containerHeight }) => $containerHeight};
`;

const StyledSpinner = styled.div<{
  $primaryColor: string;
  $radius: number;
  $secondaryColor: string;
  $thickness: number;
}>`
  display: inline-block;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
  border-radius: 50%;

  ${({ $primaryColor, $radius, $secondaryColor, $thickness }) => {
    return css`
      width: ${$radius}px;
      height: ${$radius}px;
      font-size: ${$thickness}px;
      border-left: 1em solid ${$primaryColor};
      border-top: 1em solid ${$secondaryColor};
      border-right: 1em solid ${$secondaryColor};
      border-bottom: 1em solid ${$secondaryColor};
    `;
  }}

  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

type SpinnerProps = Pick<React.CSSProperties, 'display'> & {
  centered?: boolean;
  fullScreen?: boolean;
  primaryColor?: Color | string;
  radius?: number;
  secondaryColor?: Color | string;
  thickness?: number;
  style?: Record<string, any>;
};

export const Spinner = ({
  centered = true,
  display,
  primaryColor = 'primary',
  radius = 50,
  secondaryColor = 'foregroundNeutralTertiary',
  thickness = 5,
  fullScreen = false,
  style,
}: SpinnerProps) => {
  const [containerHeight, setContainerHeight] = React.useState<
    number | null | undefined
  >(null);
  const theme = useTheme();
  let root: HTMLElement | null | undefined;

  React.useEffect(() => {
    if (root && root.parentElement) {
      setContainerHeight(
        Math.max(root.parentElement.getBoundingClientRect().height, radius),
      );
    }
  }, []);

  const getDisplay = (): {
    display: 'none';
  } | null => {
    if (!(typeof containerHeight === 'number')) {
      return {
        display: 'none',
      };
    }
    return null;
  };

  return (
    <SpinnerContainer
      {...getDisplay()}
      data-testid="spinner"
      data-spinner
      $display={display}
      ref={(ref) => {
        root = ref;
      }}
      $centered={centered}
      $containerHeight={fullScreen ? '100vh' : `${containerHeight ?? 0}px`}
      role="status"
      aria-live="polite"
      aria-label="Loading"
      style={style}
    >
      <StyledSpinner
        $radius={radius}
        $thickness={thickness}
        $primaryColor={theme.colors[primaryColor as Color] ?? primaryColor}
        // prettier-ignore
        $secondaryColor={theme.colors[secondaryColor as Color] ?? secondaryColor}
      />
    </SpinnerContainer>
  );
};
