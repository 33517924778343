import {
  Box,
  Button,
  Flex,
  HM,
  PL,
  ResolvedThemeMode,
  css,
  styled,
  useThemeMode,
} from '@m1/liquid-react';
import {
  Illustration,
  type IllustrationsType,
} from '@m1/liquid-react/illustrations';
import * as React from 'react';

import {
  useResendEmailVerificationMutation,
  useEmailVerificationPageQuery,
  useUserEmailQuery,
} from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import type { AppState } from '~/redux';
import { useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';
import { Divider } from '~/toolbox/divider';
import { Spinner } from '~/toolbox/spinner';

const GradientDiv = styled(Box)<{ activeTheme: ResolvedThemeMode }>`
  background: ${({ activeTheme }) =>
    activeTheme === 'light'
      ? css`linear-gradient(
    180.36deg,
    rgba(166, 201, 218, 0.64) -20.23%,
    rgba(229, 239, 244, 0.0768) 51.08%,
    rgba(166, 201, 218, 0) 119.94%
  )`
      : css`linear-gradient(
    180deg,
    rgba(22, 64, 85, 0.38) 0.24%,
    rgba(13, 37, 49, 0.05) 62.50%,
    rgba(22, 64, 85, 0.00) 99.79%
  )`};
`;

export const ResendVerificationEmail = () => {
  const previousRoute = useSelector(
    (state: AppState) => state.routing.appHistory[1],
  );
  const navigate = useNavigate();
  const { activeThemeMode } = useThemeMode();
  const { addToast } = useToast();

  const { data: userData } = useUserEmailQuery();
  const { data: emailVerificationPageData, loading } =
    useEmailVerificationPageQuery();
  const [resendVerificationEmail] = useResendEmailVerificationMutation({
    variables: {
      input: {},
    },
  });

  const email = userData?.viewer?.user?.username;
  const successContent = email
    ? `A verification email has been sent to ${email}.`
    : 'A verification email has been sent.';
  const emailVerificationPage =
    emailVerificationPageData?.viewer?.emailVerificationPage;

  const handleResend = () => {
    resendVerificationEmail({
      onCompleted: () => {
        addToast({
          content: successContent,
          kind: 'success',
          duration: 'short',
        });
        navigate({ to: previousRoute || '/d/home' });
      },
      onError: () => {
        addToast({
          content: 'Failed to resend email',
          kind: 'alert',
        });
      },
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <GradientDiv activeTheme={activeThemeMode}>
      <Flex flexDirection="column" m="auto" maxWidth={558} mt={132}>
        <Box alignSelf="center">
          <Illustration
            name={
              (emailVerificationPage?.illustration
                ?.names?.[0] as IllustrationsType) ?? 'emailVerifyInProcess'
            }
          />
        </Box>
        <Box alignSelf="left">
          <HM content={emailVerificationPage?.title} my={32} />
          {emailVerificationPage?.content?.map((content: string) => (
            <PL key={content} content={content} mb={48} />
          ))}
          <Divider />
          <Button
            label={
              emailVerificationPage?.ctaLabel ?? 'Resend verification link'
            }
            onClick={handleResend}
            size="large"
            mt={12}
          />
        </Box>
      </Flex>
    </GradientDiv>
  );
};
