import * as React from 'react';

import { SliceableLogoFragment } from '~/graphql/types';

import { Market } from './Market';

type Props = {
  className?: string;
  sliceable: (SliceableLogoFragment & { market: string }) | null | undefined;
};

/*
  This has long since moved on from just displaying the market of a secrity.
  Now generally displays a "secondary identifier". Markets for a security,
  category for system pie, "Custom Pie" for user pies.
*/

export const SliceableMarket = ({ sliceable, className }: Props) => {
  if (!sliceable) {
    return null;
  }

  switch (sliceable.__typename) {
    case 'Equity':
    case 'Fund':
      return (
        <span className={className}>
          {sliceable.market && (
            <span>
              <Market value={sliceable.market} />:
            </span>
          )}{' '}
          {sliceable.symbol}
        </span>
      );
    case 'SystemPie': {
      const market = sliceable.categorizationDetails[0].name;
      return <span className={className}>{market}</span>;
    }
    case 'UserPie': {
      return <span className={className}>My Pie</span>;
    }
    default:
      return <div className={className} />;
  }
};
