import * as React from 'react';
import { Route } from 'react-router-dom';

import { SecurityDetailsLayout } from '../../SecurityDetailsLayout';

export const ResearchFundDetailsRoute = () => {
  return (
    <Route
      path="details/:fundId"
      element={<SecurityDetailsLayout type="FUND" />}
    />
  );
};
