import { Flex, PXL } from '@m1/liquid-react';
import * as React from 'react';

import { TransferParticipantCell } from '~/components/transfers/TransferParticipantCell';
import { TransferParticipantIcon } from '~/components/transfers/TransferParticipantIcon';
import { SmartTransferRuleFragment } from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';

import { TransferRuleStatus } from '../TransferRuleStatus';

type SmartTransferRulesTableProps = {
  smartTransferRules: (SmartTransferRuleFragment | null | undefined)[];
};

export const SmartTransferRulesTable = ({
  smartTransferRules,
}: SmartTransferRulesTableProps) => {
  return (
    <GridTable gridTemplateColumns="auto 400px 160px" isNewStyle>
      <GridTable.HeaderRow>
        <GridTable.HeaderCell label="When" />
        <GridTable.HeaderCell label="To/From" />
        <GridTable.HeaderCell label="Details" />
      </GridTable.HeaderRow>
      {smartTransferRules &&
        smartTransferRules.map((smartTransferRule) => {
          if (!smartTransferRule) {
            return null;
          }

          const { id, focusParticipant, initialContraParticipantEntry, title } =
            smartTransferRule;
          return (
            <GridTable.NavigableRow
              key={id}
              params={{
                smartTransferRuleId: id,
              }}
              to="/d/c/smart-transfer-details/:smartTransferRuleId"
            >
              <GridTable.Cell
                content={
                  focusParticipant && (
                    <Flex alignItems="center">
                      <TransferParticipantIcon
                        transferParticipant={focusParticipant}
                      />
                      <PXL fontWeight={600} content={title} ml={8} />
                    </Flex>
                  )
                }
              />
              <GridTable.Cell
                content={
                  initialContraParticipantEntry?.contraParticipant && (
                    <TransferParticipantCell
                      transferParticipant={
                        initialContraParticipantEntry.contraParticipant
                      }
                    />
                  )
                }
              />
              <GridTable.Cell
                content={
                  <TransferRuleStatus transferRule={smartTransferRule} />
                }
              />
            </GridTable.NavigableRow>
          );
        })}
    </GridTable>
  );
};
