import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';

export function* showFailureToast(content: string): SagaIterator<void> {
  yield put({
    payload: {
      content,
      kind: 'alert',
    },
    type: 'ADD_TOAST',
  });
}

export function* showSuccessToast(content: string): SagaIterator<void> {
  yield put({
    payload: {
      content,
      kind: 'success',
    },
    type: 'ADD_TOAST',
  });
}
