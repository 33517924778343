import {
  Text,
  Box,
  BoxProps,
  TextProps,
  ButtonProps,
  SemanticColorNames,
  TooltipProps,
  Typography,
  FontWeight,
} from '@m1/liquid-react';
import * as React from 'react';

import {
  AppRichTextDisclosureLinkFragment,
  RichTextFragment,
} from '~/graphql/types';

import { LinkProps } from '~/toolbox/link';

import { createRichTextParagraphs } from '~/utils/createRichTextParagraphs';

import {
  DisclosureLink,
  type DisclosureLinkProps,
} from '../../components/DisclosureLink/DisclosureLink';
import { AppButton } from '../AppButton';
import { AppTooltip } from '../AppTooltip';
import { LinkableLink } from '../LinkableLink';

type RichTextProps = {
  richText: Maybe<RichTextFragment>;
  textProps?: TextProps;
  linkProps?: Omit<LinkProps, 'to'>;
  buttonProps?: Partial<ButtonProps>;
  containerProps?: BoxProps;
  disclosureLinkProps?: DisclosureLinkProps;
  tooltipProps?: Partial<TooltipProps>;
};

export const RichText = ({
  richText,
  textProps,
  linkProps,
  buttonProps,
  containerProps,
  disclosureLinkProps,
  tooltipProps,
}: RichTextProps) => {
  if (!richText?.content) {
    return null;
  }
  return (
    <Box
      {...{
        display: 'inline-flex',
        gap: '4px',
        alignItems: 'center',
        ...containerProps,
      }}
    >
      {richText.content.map((resource, i) => {
        switch (resource?.__typename) {
          case 'Text': {
            const textStartsWithPunctuation =
              resource.text && /^[^\w\s].*/.test(resource.text);
            return (
              <Text
                key={i}
                ml={textStartsWithPunctuation ? -4 : 0}
                color={resource.color as SemanticColorNames}
                font={resource.font as Typography}
                // Lens returns a string but the prop expects a number. CSS can handle both so this is fine.
                fontWeight={resource.fontWeight as unknown as FontWeight}
                {...textProps}
              >
                {resource.text}
              </Text>
            );
          }
          case 'AppLink':
            return (
              <LinkableLink
                underline={resource.underline}
                font={resource.font as Typography}
                fontWeight={resource.fontWeight as unknown as FontWeight}
                linkable={resource}
                key={i}
                {...linkProps}
              />
            );
          case 'AppButton':
            return <AppButton {...buttonProps} appButton={resource} key={i} />;
          case 'AppTooltip':
            return (
              <AppTooltip {...tooltipProps} appTooltip={resource} key={i} />
            );
          case 'AppRichTextDisclosureLink':
            return (
              <DisclosureLink
                {...disclosureLinkProps}
                linkText={resource?.linkText}
                paragraphs={createRichTextParagraphs(
                  resource.paragraphs as AppRichTextDisclosureLinkFragment['paragraphs'],
                )}
                key={i}
                underline={resource.underline}
                font={resource.font as Typography}
                fontWeight={resource.fontWeight as unknown as FontWeight}
              />
            );
          default:
            return null;
        }
      })}
    </Box>
  );
};
