import * as React from 'react';

import { useMyPiesPageQuery } from '~/graphql/hooks';
import { MyPieFragment } from '~/graphql/types';
import { useSelector } from '~/redux/hooks';

import { PieListState, MyPiesPageProps } from './types';
import { MyPiesContext } from './useMyPiesContext';

const pieReduce = (
  pies: (MyPieFragment | null | undefined)[] | null | undefined,
): PieListState => {
  const pieData = {
    linkedPies: [],
    unlinkedPies: [],
    totalPies: 0,
  } as PieListState;

  if (!pies) {
    return pieData;
  }

  return pies?.reduce((acc, val) => {
    if (!val?.node) {
      return acc;
    }

    acc.totalPies++;
    if (val.node.portfolioLinks.length) {
      acc.linkedPies.push(val.node);
    } else {
      acc.unlinkedPies.push(val.node);
    }

    return acc;
  }, pieData);
};

export const MyPiesPageProvider = ({
  minimal,
  children,
}: React.PropsWithChildren<MyPiesPageProps>) => {
  const mode = useSelector((state) => state.mode);
  const { isCrypto } = useSelector((state) => ({
    isCrypto: state.portfolioOrganizer.isCrypto || state.pieEditor.isCrypto,
  }));
  const { data, loading, error, ...rest } = useMyPiesPageQuery({
    fetchPolicy: 'network-only',
  });

  const viewer = data?.viewer;

  const equityPies = pieReduce(viewer?.userPies?.edges);
  const cryptoPies = pieReduce(viewer?.userCryptoPies?.edges);

  // Show non-crypto pies when researching or when adding to a regular pie ONLY
  const shouldShowNonCryptoPies = !isCrypto || mode === 'DEFAULT';
  // Show crypto pies for research or when adding to a crypto pie ONLY
  const shouldShowCryptoPies = isCrypto || mode === 'DEFAULT';

  return (
    <MyPiesContext.Provider
      value={{
        mode,
        minimal,
        shouldShowNonCryptoPies,
        shouldShowCryptoPies,
        cryptoPies,
        equityPies,
        queryData: {
          data,
          loading,
          error,
          ...rest,
        },
      }}
    >
      {children}
    </MyPiesContext.Provider>
  );
};
