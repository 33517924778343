import { styled } from '@m1/liquid-react';
import * as React from 'react';

import { Diamond } from './Diamond';
import { PillKind, PillSize, PillIconsEnum } from './Pill.types';

type PillIconProps = {
  icon: PillIconsEnum;
  kind: PillKind;
  size: PillSize;
};

const collectImageSize = (
  size: PillSize,
): {
  height: number;
  width: number;
} => {
  switch (size) {
    case 'medium':
      return {
        height: 14,
        width: 16,
      };
    case 'large':
      return {
        height: 16,
        width: 19,
      };
    case 'announcement':
    default:
      return {
        height: 12,
        width: 14,
      };
  }
};

const StyledPillIcon = styled.svg<{
  $size: PillSize;
}>`
  width: ${({ $size }) => `${collectImageSize($size).width}px`};
  height: ${({ $size }) => `${collectImageSize($size).height}px`};
  margin-right: 4px;

  path {
    fill: currentColor;
  }
`;

const generateSvgPaths = (icon: PillIconsEnum) => {
  switch (icon) {
    case 'diamond':
      return Diamond;
    default:
      return null;
  }
};

export const PillIcon = ({ icon, kind, size }: PillIconProps) => {
  return (
    // @ts-expect-error - TS2769 - No overload matches this call.
    <StyledPillIcon $kind={kind} $size={size} viewBox="0 0 14 12">
      {generateSvgPaths(icon)}
    </StyledPillIcon>
  );
};
