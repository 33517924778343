import { Box } from '@m1/liquid-react';
import range from 'lodash-es/range';
import React from 'react';

import { StyledFilters, Filter } from '~/components/research/Screener';
import { useSecurityCategoriesQuery } from '~/graphql/hooks';

import { useStockScreenerResult } from './StockScreenerProvider';

const STOCK_FILTER_LABELS = ['Sector', 'Industry'];

export const StockScreenerCategories = () => {
  const {
    queryResult: { variables },
    updateScreenerQuery,
  } = useStockScreenerResult();

  // Resets category filters onReset
  const categoriesArr = React.useMemo(() => {
    if (!variables?.filterCategory) {
      return [];
    }
    if (Array.isArray(variables.filterCategory)) {
      return variables.filterCategory;
    }

    return [variables.filterCategory];
  }, [variables]);

  const handleFilterSelection = (position: number, filter: string) => {
    const filters = [...categoriesArr.slice(0, position), filter];

    updateScreenerQuery({
      filterCategory: filters,
    });
  };

  return (
    <Box>
      <StyledFilters>
        {range(categoriesArr?.length + 1).map((n) => (
          <StockFilter
            key={n}
            categories={categoriesArr?.slice(0, n)}
            onChangeFilter={(filter: string) =>
              handleFilterSelection(n, filter)
            }
            selected={categoriesArr[n]}
            label={STOCK_FILTER_LABELS[n]}
          />
        ))}
      </StyledFilters>
    </Box>
  );
};

type StockFilterProps = {
  categories: string[];
  onChangeFilter: (filter: string) => void;
  selected: string;
  label: string;
};

const StockFilter = ({
  categories,
  onChangeFilter,
  selected,
  label,
}: StockFilterProps) => {
  const { data } = useSecurityCategoriesQuery({
    variables: {
      filterCategory: categories,
    },
  });

  const subCategories = data?.viewer.securities.subCategories;
  if (!subCategories?.length) {
    return null;
  }

  return (
    <Filter
      categories={subCategories}
      onChangeFilter={onChangeFilter}
      selected={selected}
      label={label}
    />
  );
};
