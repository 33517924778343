import { Flex, useTheme } from '@m1/liquid-react';
import React from 'react';

import { Account } from '~/graphql/types';
import { useSelector } from '~/redux/hooks';
import { formatCurrencyWithCommas } from '~/utils/formatting';
import { normalizeCurrency } from '~/utils/normalizers';

import { AmountField } from '../fields/amount-field';

export const DepositAmountInput = ({
  account,
}: {
  account: Account | null | undefined;
}) => {
  const theme = useTheme();
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const handleBlur = React.useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleFocus = React.useCallback(() => {
    setIsFocused(true);
  }, []);

  const isIraRollover = useSelector(
    (state) => state.form['fund-account']?.values?.isIraRollover ?? false,
  );

  const amount = useSelector(
    (state) => state.form['fund-account']?.values?.fundAccountAmount ?? false,
  );

  const validate = React.useCallback(
    (valueAsString: string): string | null | undefined => {
      const value = Number(valueAsString);
      const valueIsBelowMinimumDeposit =
        account?.minimumInitialDeposit &&
        value < account.minimumInitialDeposit &&
        value > 0;

      const maximumInitialDeposit = account?.maximumInitialDeposit ?? 0;
      const maxIraContribution =
        account?.isRetirement && !isIraRollover
          ? (account.iraContributionLimits?.currentYearLimit ?? 0)
          : maximumInitialDeposit;

      const maxValue = account?.isRetirement
        ? maxIraContribution
        : maximumInitialDeposit;

      const valueIsAboveMaximumDeposit =
        account?.maximumInitialDeposit && value > (maxValue ?? 0);

      if (valueIsBelowMinimumDeposit) {
        return `The minimum initial deposit is $${formatCurrencyWithCommas(
          account.minimumInitialDeposit,
        )}`;
      } else if (valueIsAboveMaximumDeposit) {
        return `The maximum initial deposit is $${formatCurrencyWithCommas(
          maxValue,
        )}`;
      }
    },
    [
      account?.maximumInitialDeposit,
      account?.minimumInitialDeposit,
      isIraRollover,
    ],
  );

  /*
   * -- NOTE --
   * We can't pass in a validation prop to the AmountField below, since the
   * validation function is a callback function that gets re-defined every
   * time the `isIraRollover` value changes (ie: the checkbox is checked or unchecked).
   * Passing a new validation function to the AmountField component causes it to be
   * de-registered and then re-registered to the form's redux store.
   * The field's re-registration resets the value of the field, causing a bug where
   * the field displays a 0 amount when the isIraRollover checkbox is clicked.
   * The workaround here is to calculate the error externally (ie: below) and pass that
   * to the field via the "error" prop.
   */
  const error = validate(amount);

  return (
    <Flex
      height={100}
      minWidth={300}
      justifyContent="center"
      mb={24}
      mx="auto"
      style={{
        borderBottom: `${theme.colors.borderMain} 1px solid`,
      }}
    >
      <AmountField
        placeholder={0}
        onBlur={handleBlur}
        onFocus={handleFocus}
        isFocused={isFocused}
        normalize={normalizeCurrency}
        error={error}
        format={formatCurrencyWithCommas}
        name="fundAccountAmount"
        autoComplete="off"
        showErrorBeforeSubmit
        maxLength={8} // ensure we always deal with a number value by parsing on every render cycle.
        // this resolves a strange redux form but that converts the default input to a string when blurring right off the bat.
        // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
        parse={(value) => {
          if (value === '') {
            return 0;
          }
          return parseInt(value.replace(/,/g, ''), 10);
        }}
      />
    </Flex>
  );
};
