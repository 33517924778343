import { styled } from '@m1/liquid-react';
import * as React from 'react';

import { UNSAFE_connectRedux } from '~/hocs';
import { submittedIntroductionSourceModern } from '~/redux/actions';

type Props = {
  dispatch: (...args: Array<any>) => any;
  kind: 'pill' | 'plain';
  label: string;
  onFinish?: (...args: Array<any>) => any;
  value: string;
};

const StyledOption = styled.div<{
  kind: 'pill' | 'plain';
}>`
  ${(props) => {
    if (props.kind === 'pill') {
      return `
        border: 2px solid;
        border-color: ${props.theme.colors.primary};
        border-radius: 24px;
        color: ${props.theme.colors.primary};
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        margin: 1rem;
        text-align: center;
        outline: none;
        padding: 1rem;
        user-select: none;

        &:hover, &:focus {
          color: ${props.theme.colors.primaryShade};
          border-color: ${props.theme.colors.primaryShade};
        }
      `;
    } else if (props.kind === 'plain') {
      return `
        padding: 16px 0;
        color: ${props.theme.colors.primary};
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        text-align: center;

        &:hover {
          color: ${props.theme.colors.primaryShade};
          border-color: ${props.theme.colors.primaryShade};
        }

        &:focus {
          color: ${props.theme.colors.primaryShade};
          border-color: ${props.theme.colors.primaryShade};
        }
      `;
    }
  }};
`;

const IntroductionSourceOptionComponent = (props: Props) => {
  const handleClick = (): void => {
    if (props.onFinish) {
      props.onFinish();
    } else {
      props.dispatch(submittedIntroductionSourceModern(props.value));
    }
  };

  return (
    <StyledOption
      tabIndex={0}
      kind={props.kind}
      onClick={handleClick}
      // @ts-expect-error - TS2769 - No overload matches this call.
      onKeyDown={(event: KeyboardEvent) => {
        if (event.key === 'Enter' || event.code === 'Space') {
          return handleClick();
        }
      }}
    >
      {props.label}
    </StyledOption>
  );
};

const enhancer = UNSAFE_connectRedux();

export const IntroductionSourceOption = enhancer(
  IntroductionSourceOptionComponent,
) as any;
