import { Flex } from '@m1/liquid-react';
import * as React from 'react';
import { FormProvider } from 'react-hook-form';

import { useIRAWizardForm } from './hooks/useIRAWizardForm';
import { IRAWizard } from './IRAWizard';

export const SetupIRAAccountPage = () => {
  const formMethods = useIRAWizardForm();

  return (
    <Flex>
      <FormProvider {...formMethods}>
        <IRAWizard />
      </FormProvider>
    </Flex>
  );
};
