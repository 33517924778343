import { Button, HXS, PL } from '@m1/liquid-react';
import * as React from 'react';
import { InjectedFormProps } from 'redux-form';

import { connectForm, compose, UNSAFE_connectRedux } from '~/hocs';
import { Grid } from '~/toolbox/grid';

import { AddressFields, TextField } from './fields';
import { required, printableAsciiChars } from './validators';

type RecipientInformationFormProps = {
  continueCtaCopy: string;
  handleSubmit: NonNullable<InjectedFormProps['handleSubmit']>;
  recipientAddressTitle: string;
  recipientNameTitle: string;
  title: string;
};
const RecipientName = () => {
  return (
    <Grid.Row>
      <Grid.Col sm={12}>
        <TextField
          name="businessName"
          label="Name"
          maxLength={30}
          validate={[required, printableAsciiChars]}
        />
      </Grid.Col>
    </Grid.Row>
  );
};

const RecipientInformationFormComponent = ({
  continueCtaCopy,
  handleSubmit,
  recipientAddressTitle,
  recipientNameTitle,
  title,
}: RecipientInformationFormProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <HXS content={title} mb={32} />
      <PL fontWeight={600} content={recipientNameTitle} />
      {/* @ts-expect-error - TS2322 - Type '{ recipientNameTitle: string; }' is not assignable to type 'IntrinsicAttributes'. */}
      <RecipientName recipientNameTitle={recipientNameTitle} />
      <PL fontWeight={600} content={recipientAddressTitle} mt={32} />
      <AddressFields kind="NORMAL" />

      <Grid.Row
        style={{
          paddingTop: 64,
        }}
      >
        <Grid.Col xs={12}>
          <Button
            type="submit"
            kind="primary"
            size="large"
            label={continueCtaCopy}
          />
        </Grid.Col>
      </Grid.Row>
    </form>
  );
};

export const RecipientInformationForm = compose<any, any>(
  UNSAFE_connectRedux((state) => ({
    // @ts-expect-error - TS2339 - Property 'newFlows' does not exist on type 'DefaultRootState'.
    recipient: state.newFlows.CREATE_SEND_CHECK.input.recipient,
  })),
  connectForm((props) => {
    return {
      form: 'recipient-information-form',
      initialValues: {
        ...props.recipient,
      },
    };
  }),
)(RecipientInformationFormComponent);
