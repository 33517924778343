import { PL } from '@m1/liquid-react';
import * as React from 'react';

import { TruncateStringContent } from '~/components/TruncateStringContent';
import { useHighlightedSliceQuery } from '~/graphql/hooks';
import { useSelector } from '~/redux/hooks';

/*
  An opinionated component meant to be rendered in the top-left corner of the
  pie card on Portfolio. If different logic is required, consider refactoring,
  or making a new component.
*/

export const HighlightedPortfolioSliceName = () => {
  const portfolioSliceId = useSelector(
    (state) => state.interface.highlightedSliceId,
  );
  // TODO: consider refactoring to pull this data from useReactiveVar()
  const { data, loading } = useHighlightedSliceQuery({
    variables: {
      id: portfolioSliceId as string,
    },
    skip: !portfolioSliceId,
  });

  if (!portfolioSliceId || loading) {
    return null;
  }

  const portfolioSlice = data?.node && 'to' in data.node ? data.node.to : null;

  if (!portfolioSlice) {
    return null;
  }

  return (
    <PL fontWeight={600}>
      <TruncateStringContent>{portfolioSlice.name}</TruncateStringContent>
    </PL>
  );
};
