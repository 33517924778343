import { LM } from '@m1/liquid-react';
import * as React from 'react';

import { Checkbox } from '~/toolbox/checkbox';
import { GridTable } from '~/toolbox/grid-table';

export type UnmanagedHoldingsTableHeaderRowProps = {
  actionableHoldingIds: Array<string>;
  hideCheckboxes: boolean;
  isDesktop: boolean;
  isTradingInProgress: boolean;
  onSelectAll: () => void;
  selectedCheckboxes: Array<string>;
};

export const UnmanagedHoldingsTableHeaderRow = ({
  actionableHoldingIds,
  hideCheckboxes = false,
  isDesktop,
  isTradingInProgress,
  onSelectAll,
  selectedCheckboxes,
}: UnmanagedHoldingsTableHeaderRowProps) => {
  const selectAllIsChecked = Boolean(
    actionableHoldingIds.length &&
      actionableHoldingIds.length === selectedCheckboxes.length,
  );

  const selectAllCheckboxLabel = (
    <Checkbox
      checked={selectAllIsChecked}
      disabled={isTradingInProgress || actionableHoldingIds.length === 0}
      onChange={onSelectAll}
      size="large"
      label={
        <LM
          content={
            selectedCheckboxes.length
              ? `Select all (${selectedCheckboxes.length} selected)`
              : 'Select all'
          }
          pl={8}
        />
      }
    />
  );

  if (isDesktop) {
    return (
      <GridTable.HeaderRow>
        <GridTable.HeaderCell
          label={!hideCheckboxes ? selectAllCheckboxLabel : null}
        />
        <GridTable.HeaderCell align="right" label="Date received" />
        <GridTable.HeaderCell align="right" label="Quantity" />
        <GridTable.HeaderCell align="right" label="Value" />
      </GridTable.HeaderRow>
    );
  }

  return (
    <GridTable.HeaderRow>
      <GridTable.HeaderCell
        label={!hideCheckboxes ? selectAllCheckboxLabel : null}
      />
      <GridTable.HeaderCell align="right" label="Value" />
    </GridTable.HeaderRow>
  );
};
