import {
  LayoutableProps,
  PM,
  PS,
  Flex,
  Box,
  Color,
  Card,
} from '@m1/liquid-react';
import * as React from 'react';

import { AppInformationalMessageCardFragment } from '~/graphql/types';
import { AppImage } from '~/lens-toolbox/AppImage';
import { AppPill } from '~/lens-toolbox/AppPill';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';

import { AppInformationalMessagePopup } from './AppInformationalMessagePopup';

export const AppInformationalMessageCard = ({
  appInformationalMessageCard: data,
  backgroundColor,
  ...rest
}: LayoutableProps & {
  backgroundColor?: Color;
  appInformationalMessageCard: AppInformationalMessageCardFragment;
}) => {
  const items = data.items?.filter(Boolean) ?? [];

  return (
    <Card
      {...rest}
      borderColor="transparent"
      backgroundColor={backgroundColor || 'backgroundNeutralTertiary'}
      p="12px 16px 16px"
    >
      <Flex width="100%">
        {data.icon && (
          <Flex alignItems="flex-start" flex="0 0 32px" mr={8}>
            <AppImage appImage={data.icon} />
          </Flex>
        )}
        <Flex flex="auto" flexDirection="column">
          {(data.title || data.status) && (
            <Flex justifyContent="space-between" mt={4}>
              <Flex>
                {data.title && <PM fontWeight={600} content={data.title} />}
                {data.status && <AppPill appPill={data.status} ml={16} />}
              </Flex>
            </Flex>
          )}
          {items.length > 0 && (
            <Box mt={4}>
              {items.map((item) => (
                <AppInformationalMessagePopup item={item} key={item?.value} />
              ))}
            </Box>
          )}
          {data.message && (
            <PS
              color="foregroundNeutralSecondary"
              content={data.message}
              mt={4}
            />
          )}
          {data.link && (
            <Box>
              <LinkableLink font="PS" linkable={data.link} mt={4} />
            </Box>
          )}
        </Flex>
      </Flex>
    </Card>
  );
};
