import { Flex, PL, Card } from '@m1/liquid-react';
import * as React from 'react';

import { Divider } from '~/toolbox/divider';
import { CashFormatter } from '~/utils';

import { validateWithholdingLessThanTransferAmount } from '../../utils/iraTaxWithholding';

import { WithholdingTotals } from './ConfirmTransferStepDeprecated';

export const IraTotals = ({
  amount,
  excessContributionAmount,
  niaAmount,
  federalWithholdingPercentage,
  stateWithholdingPercentage,
}: {
  amount: number | undefined;
  excessContributionAmount: number | null;
  federalWithholdingPercentage: number;
  niaAmount: number | null | undefined;
  stateWithholdingPercentage: number;
}) => {
  const transferAmount = amount ?? 0;

  const withholdingTotals = React.useMemo((): WithholdingTotals => {
    const { federalWithholdingAmount, stateWithholdingAmount, isValid } =
      validateWithholdingLessThanTransferAmount({
        stateWithholdingPercentage,
        federalWithholdingPercentage,
        amount: transferAmount,
      });

    const bankAmount =
      transferAmount - stateWithholdingAmount - federalWithholdingAmount;

    return {
      isValid,
      bankAmount,
      federalDollarAmount: federalWithholdingAmount,
      stateDollarAmount: stateWithholdingAmount,
    };
  }, [
    federalWithholdingPercentage,
    stateWithholdingPercentage,
    transferAmount,
  ]);
  return (amount && niaAmount) || withholdingTotals?.isValid ? (
    <Card
      backgroundColor="backgroundNeutralMain"
      borderColor="foregroundNeutralTertiary"
      my={24}
      p={16}
      color="foregroundNeutralMain"
    >
      {amount && niaAmount && (
        <Flex width="100%" flexDirection="column">
          <Flex justifyContent="space-between">
            <PL content="Excess contribution amount" />
            <PL
              content={CashFormatter.format(Number(excessContributionAmount))}
            />
          </Flex>
          <Flex justifyContent="space-between" py={12}>
            <PL content="Net Income Attributable" />
            <PL content={CashFormatter.format(niaAmount)} />
          </Flex>
          <Flex justifyContent="space-between">
            <PL content="Total amount" />
            <PL content={CashFormatter.format(amount)} />
          </Flex>
        </Flex>
      )}
      {amount && niaAmount && withholdingTotals?.isValid && <Divider />}
      {withholdingTotals?.isValid && (
        <Flex width="100%" flexDirection="column">
          <Flex justifyContent="space-between">
            <PL content="Federal tax withholding:" />
            <PL
              content={CashFormatter.format(
                withholdingTotals.federalDollarAmount,
              )}
            />
          </Flex>
          <Flex justifyContent="space-between" py={12}>
            <PL content="State tax withholding:" />
            <PL
              content={CashFormatter.format(
                withholdingTotals.stateDollarAmount,
              )}
            />
          </Flex>
          <Flex justifyContent="space-between">
            <PL content="Transfer to your bank:" />
            <PL content={CashFormatter.format(withholdingTotals.bankAmount)} />
          </Flex>
        </Flex>
      )}
    </Card>
  ) : null;
};
