import * as React from 'react';
import { Route } from 'react-router-dom';

import { OpenInvestAccountPage } from './OpenInvestAccountPage';

export const OpenInvestAccountRoute = () => {
  return (
    <Route
      path="open-invest-account/:step?"
      element={<OpenInvestAccountPage />}
    />
  );
};
