import * as React from 'react';
import { Route } from 'react-router-dom';

import { RewardsViewAllProgramsComponent } from './components/RewardsViewAllProgramsComponent';

export const RewardsViewAllProgramsRoute = () => {
  return (
    <Route
      path="rewards/programs/:programType"
      handle={{
        fallbackRoute: {
          to: '/d/spend/credit/rewards',
        },
      }}
      element={<RewardsViewAllProgramsComponent />}
    />
  );
};
