import { HS, PL, Flex, Box, styled, useTheme } from '@m1/liquid-react';
import * as React from 'react';

import { SecondaryHeader } from '~/components/secondary-header';
import { useSelector } from '~/redux/hooks';
import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';

import { HelperDialogToggle } from '../HelperDialog/HelperDialogToggle';
import { PortfolioOrganizerActionButtons } from '../PortfolioOrganizerActionButtons';

const SecondaryHeaderContainer = styled(Container)`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
  width: 100%;
`;

type OrganizerHeaderData = {
  isCreatingPortfolio: boolean;
  isRootPie: boolean;
  subtitle?: string | null | undefined;
  subtitleLink?:
    | {
        title: string;
        url: string;
      }
    | null
    | undefined;
  title: string;
};

type PieOrganizerHeaderProps = {
  isCrypto: boolean;
  resetDialogPageCallback: () => void;
  setConfirmationDialogOpen: () => void;
};

export const PieOrganizerHeader = ({
  isCrypto,
  resetDialogPageCallback,
  setConfirmationDialogOpen,
}: PieOrganizerHeaderProps) => {
  const theme = useTheme();

  const { isCreatingPortfolio, isRootPie, title, subtitle, subtitleLink } =
    useSelector<OrganizerHeaderData>((state) => {
      const { buttons, path, session, title, subtitle, subtitleLink } =
        state.portfolioOrganizer;
      const headerText =
        session.mode === 'NEW_ROOT_PIE'
          ? `Build your ${isCrypto ? 'crypto portfolio' : 'portfolio'}`
          : `Edit your ${isCrypto ? 'crypto pie' : 'pie'}`;

      return {
        backButton: buttons?.backButton,
        subtitle,
        subtitleLink,
        title: title ? title : headerText,
        isCreatingPortfolio: session.mode === 'NEW_ROOT_PIE',
        isRootPie: path.length === 0,
      };
    });

  return (
    <SecondaryHeader
      css={`
        box-shadow: none;
        border-bottom: none;
        width: 100%;
      `}
    >
      <SecondaryHeaderContainer>
        <Flex
          height={152}
          justifyContent="center"
          position="relative"
          width="100%"
        >
          <Flex
            style={{
              borderRight: `152px solid ${theme.colors.backgroundFeatureFlat}`,
              borderBottom: `152px solid ${theme.colors.backgroundFeatureFlat}`,
            }}
            width="30%"
          />
          <Flex
            style={{
              borderLeft: `152px solid ${theme.colors.backgroundFeatureFlat}`,
              borderBottom: `152px solid ${theme.colors.backgroundFeatureFlat}`,
            }}
            width="70%"
          />

          <Flex
            alignItems="center"
            height={152}
            justifyContent="space-between"
            maxWidth={1200}
            ml="auto"
            mr="auto"
            position="absolute"
            pl="1rem"
            pr="1rem"
            width="100%"
            padding="0px 80px"
          >
            <Flex flexDirection="column">
              <HS color="foregroundNeutralOnDark" content={title} mr={16} />
              <Box mt={4}>
                {subtitle && (
                  <PL
                    color="foregroundNeutralOnDark"
                    content={subtitle}
                    as="span"
                  />
                )}
                {subtitleLink && (
                  <Link
                    kind="inverse"
                    to={subtitleLink.url}
                    target="_blank"
                    pl={4}
                  >
                    {subtitleLink.title}
                  </Link>
                )}
              </Box>
            </Flex>
            <PortfolioOrganizerActionButtons
              setShowConfirmationDialog={setConfirmationDialogOpen}
            />
          </Flex>
        </Flex>

        <Flex
          alignItems="center"
          height={50}
          justifyContent={!isRootPie ? 'space-between' : 'flex-end'}
          maxWidth={1200}
          mx="auto"
          px="1rem"
          width="100%"
        >
          {isCreatingPortfolio && (
            <HelperDialogToggle
              resetDialogPageCallback={resetDialogPageCallback}
            />
          )}
        </Flex>
      </SecondaryHeaderContainer>
    </SecondaryHeader>
  );
};
