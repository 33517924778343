import { Box, PM } from '@m1/liquid-react';
import React from 'react';

import { SystemPieCategoryDetails } from '~/graphql/types';
import { List } from '~/toolbox/list';

type CategoryGoalDescriptionProps = {
  suitability: NonNullable<SystemPieCategoryDetails['suitability']>;
};

export const CategoryGoalDescription = ({
  suitability,
}: CategoryGoalDescriptionProps) => (
  <Box>
    <PM content="These Pies may appeal to those who:" />
    <List font="PM">
      {suitability.map((s, i) => (
        <List.Item content={s} key={i} />
      ))}
    </List>
  </Box>
);
