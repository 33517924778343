import * as React from 'react';

import { useDispatch } from '~/redux/hooks';

/**
 * A custom hook for showing or hiding a portaled loading spinner.
 *
 * **Use Cases**
 * This hook should ONLY be used in scenarios where data is already on the screen
 * and you are waiting for a specific action, like a GraphQL mutation, to complete.
 *
 * @param {boolean} loading - The loading state that determines whether to show or hide the spinner.
 *
 * @example
 * ```tsx
 * import React from 'react';
 * import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
 *
 * const MyComponent = () => {
 *   const [doMutation, { loading } ] = useSomeCoolMutation()
 *
 *   usePortaledSpinner(loading);
 *
 *   const handleAction = () => {
 *     doMutation({
 *        {
 *           variables: {...}
 *        }
 *     });
 *   };
 *
 *   return (
 *     <div>
 *       <button onClick={handleAction}>Click Me</button>
 *     </div>
 *   );
 * };
 * ```
 */

export const usePortaledSpinner = (loading: boolean) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch({
      type: loading ? 'SHOW_LOADING_SPINNER' : 'HIDE_LOADING_SPINNER',
    });

    return () =>
      dispatch({
        type: 'HIDE_LOADING_SPINNER',
      });
  }, [loading, dispatch]);
};
