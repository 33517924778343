import { ACTION_TYPES } from '~/redux/actions';
import {
  convertApiBeneficiaries,
  getActiveBeneficiaries,
} from '~/utils/beneficiaries';

export type BeneficiariesState = Array<Record<string, any>>;

export const initialState: BeneficiariesState = [];

export const beneficiariesReducer = (
  state: BeneficiariesState = initialState,
  action: any,
): BeneficiariesState => {
  switch (action.type) {
    case ACTION_TYPES.PREPARE_BENEFICIARIES:
      return convertApiBeneficiaries(action.payload);
    case ACTION_TYPES.CLEANUP_BENEFICIARIES:
      // @ts-expect-error - TS2345 - Argument of type 'BeneficiariesState' is not assignable to parameter of type 'LocalBeneficiary[]'.
      return getActiveBeneficiaries(state);
    case ACTION_TYPES.UPDATE_BENEFICIARY: {
      const { id, beneficiary: updatedBeneficiary } = action.payload;
      return state.map((beneficiary, index) => {
        return index === id
          ? {
              ...beneficiary,
              ...updatedBeneficiary,
            }
          : beneficiary;
      });
    }
    case ACTION_TYPES.ADD_BENEFICIARY:
      return [
        ...state,
        {
          ...action.payload,
          __id: state.length,
          __deleted: false,
        },
      ];
    default:
      return state;
  }
};
