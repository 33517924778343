import { styled, css } from '@m1/liquid-react';

import { LegacyTabs } from '~/toolbox/LegacyTabs';

export const LegacyPeriodTab = styled(LegacyTabs.Item)<{
  $isActive: boolean;
  $size: 'tiny' | 'normal';
}>(
  ({ $isActive, $size, theme }) => css`
    font-weight: 300;
    /* Our tokens currently don't support alpha, this should be 0.7 */
    color: ${theme.colors.foregroundNeutralMain};
    text-transform: none;
    transition: all 0.2s ease-in-out;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    label {
      display: flex;
      align-items: center;
      font-size: 1.4rem;

      align-self: stretch;
    }

    ${$isActive
      ? css`
          font-weight: 700;
          color: ${theme.colors.primary};
        `
      : ``}

    ${$size === `tiny`
      ? css`
          height: 3.6rem;
          margin: 0 0.3rem 0 0;

          > label {
            padding: 0 0.6rem;
          }
        `
      : css`
          height: 4rem;
          margin: 0 0.6rem 0 0.3rem;

          > label {
            padding: 0 0.8rem 0 0.7rem;
          }
        `}
  `,
);
