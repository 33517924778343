import * as React from 'react';
import { Route } from 'react-router-dom';

import { AcatWizard } from './AcatWizard';

export const AcatWizardRoute = () => (
  <Route
    path="acat-wizard"
    handle={{
      fallbackRoute: {
        to: '/d/invest',
      },
    }}
    element={<AcatWizard />}
  >
    <Route path="*" element={<AcatWizard />} />
  </Route>
);
