import { LS, PM, PS } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import { DocumentNode_InvestAccountDocument_Fragment } from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';
import { Link } from '~/toolbox/link';

type InvestAccountDocumentRowProps = {
  document: DocumentNode_InvestAccountDocument_Fragment;
};

export const InvestAccountDocumentRow = ({
  document,
}: InvestAccountDocumentRowProps) => {
  const displayDate = moment(document.date).format('ll');

  const inserts = document?.inserts ?? [];

  return (
    <GridTable.Row key={document.id}>
      <GridTable.Cell>{displayDate}</GridTable.Cell>
      <GridTable.Cell>
        {document.accountName ? (
          <>
            <PM
              fontWeight={600}
              overflow="hidden"
              textOverflow="ellipsis"
              content={document.accountName.label}
            />
            <LS
              as="small"
              color="foregroundNeutralMain"
              overflow="hidden"
              textOverflow="ellipsis"
              content={document.accountName.subLabel}
            />
          </>
        ) : (
          '--'
        )}
      </GridTable.Cell>
      <GridTable.Cell>
        <Link to={document.url} font="PM" target="_blank">
          {document.name}
        </Link>
        <InvestAccountDocumentInsertsList inserts={inserts} />
      </GridTable.Cell>
    </GridTable.Row>
  );
};

const InvestAccountDocumentInsertsList = ({
  inserts,
}: {
  inserts: string[];
}) => {
  if (inserts.length === 0) {
    return null;
  }

  return (
    <PS as="small" display="block" color="foregroundNeutralMain">
      Inserts:{' '}
      {inserts.map((url, index) => (
        <React.Fragment key={url}>
          <Link to={url} target="_blank" font="PS">
            {index + 1}
          </Link>

          {index + 1 !== inserts.length ? <span>, </span> : null}
        </React.Fragment>
      ))}
    </PS>
  );
};
