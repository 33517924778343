export const GLOBAL_ACTIONS = Object.freeze({
  SELECTED_INITIAL_ACCOUNTS: 'SELECTED_INITIAL_ACCOUNTS' as const,
  SET_IS_M1_EMPLOYEE: 'SET_IS_M1_EMPLOYEE' as const,
  SET_ENVIRONMENT: 'SET_ENVIRONMENT' as const,
  SET_ANONYMOUS_USER_ID: 'SET_ANONYMOUS_USER_ID' as const,
});

export type SelectedInitialAccounts = {
  payload: {
    borrowAccountId: string | null | undefined;
    creditAccountId: string | null | undefined;
    cryptoAccountId: string | null | undefined;
    investAccountId: string | null | undefined;
    savingsAccountId: string | null | undefined;
    personalLoanAccountId: string | null | undefined;
    netWorthNodeId: string | null | undefined;
  };
  type: typeof GLOBAL_ACTIONS.SELECTED_INITIAL_ACCOUNTS;
};

export type SetIsM1Employee = {
  payload: boolean;
  type: typeof GLOBAL_ACTIONS.SET_IS_M1_EMPLOYEE;
};

export type SetEnvironment = {
  payload: EnvironmentOption;
  type: typeof GLOBAL_ACTIONS.SET_ENVIRONMENT;
};

export type SetAnonymousUserID = {
  payload: string;
  type: typeof GLOBAL_ACTIONS.SET_ANONYMOUS_USER_ID;
};

export const selectedInitialAccounts = (
  payload: SelectedInitialAccounts['payload'],
): SelectedInitialAccounts => ({
  type: GLOBAL_ACTIONS.SELECTED_INITIAL_ACCOUNTS,
  payload: payload,
});

export type GlobalAction =
  | SelectedInitialAccounts
  | SetIsM1Employee
  | SetEnvironment
  | SetAnonymousUserID;
