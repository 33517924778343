import { Text } from '@m1/liquid-react';
import classNames from 'classnames';
import range from 'lodash-es/range';
import * as React from 'react';

import style from './style.module.scss';

export type RatingProps = {
  align: 'left' | 'center' | 'right';
  maxRating: number;
  rating: number;
  ratingLabel?: string | null | undefined;
  size: 'small' | 'medium' | 'large';
};

export class Rating extends React.Component<RatingProps> {
  static defaultProps = {
    maxRating: 5,
    size: 'medium',
    align: 'left',
  };

  render() {
    const { maxRating, rating } = this.props;
    const classes = classNames(
      style.root,
      style[this.props.size],
      style[this.props.align],
    );

    return (
      <div className={classes}>
        {this.props.ratingLabel && (
          <div className={style.label}>
            <Text content={this.props.ratingLabel} />
          </div>
        )}
        <div className={style.dots}>
          {range(maxRating).map((n) => (
            <div
              key={n}
              className={classNames(style.dot, {
                [style.dotActive]: n < rating,
              })}
            />
          ))}
        </div>
      </div>
    );
  }
}
