import { PXL, LM, PM, Flex } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { DottedArrow } from '~/components/DottedArrow';
import { SecurityLogo } from '~/components/SecurityLogo';
import { SourcePieChangesSectionFragment } from '~/graphql/types';
import { AppPill } from '~/lens-toolbox/AppPill';

type SourcePieChangesSectionProps = {
  sourceChanges: SourcePieChangesSectionFragment;
};

export const SourcePieChangesSection = ({
  sourceChanges,
}: SourcePieChangesSectionProps) => {
  const { adjustedSlices, applicableLocationsMessage, label } = sourceChanges;

  if (!adjustedSlices?.length) {
    return null;
  }

  const slices = adjustedSlices.filter(Boolean);

  if (!slices.length) {
    return null;
  }

  return (
    <Flex flexDirection="column" mb={32}>
      <PXL fontWeight={600} color="foregroundNeutralSecondary" mb={8}>
        {label}
      </PXL>
      {applicableLocationsMessage && (
        <PM color="foregroundNeutralSecondary" mb={8}>
          {applicableLocationsMessage}
        </PM>
      )}

      <Flex
        borderColor="borderMain"
        borderRadius={8}
        borderStyle="solid"
        borderWidth={1}
        flexDirection="column"
      >
        {slices.map((slice, index) => {
          // @ts-expect-error - TS2339 - Property 'actionPill' does not exist on type '{ readonly name: string; readonly symbol: string | null; readonly oldPercentage: number | null; readonly percentage: number | null; readonly type: MoveSlicesAdjustedSliceTypeEnum | null; readonly actionPill: { ...; } | null; readonly logoUrl: string | null; } | null'. | TS2339 - Property 'logoUrl' does not exist on type '{ readonly name: string; readonly symbol: string | null; readonly oldPercentage: number | null; readonly percentage: number | null; readonly type: MoveSlicesAdjustedSliceTypeEnum | null; readonly actionPill: { ...; } | null; readonly logoUrl: string | null; } | null'. | TS2339 - Property 'percentage' does not exist on type '{ readonly name: string; readonly symbol: string | null; readonly oldPercentage: number | null; readonly percentage: number | null; readonly type: MoveSlicesAdjustedSliceTypeEnum | null; readonly actionPill: { ...; } | null; readonly logoUrl: string | null; } | null'. | TS2339 - Property 'oldPercentage' does not exist on type '{ readonly name: string; readonly symbol: string | null; readonly oldPercentage: number | null; readonly percentage: number | null; readonly type: MoveSlicesAdjustedSliceTypeEnum | null; readonly actionPill: { ...; } | null; readonly logoUrl: string | null; } | null'.
          const { actionPill, logoUrl, percentage, oldPercentage } = slice;
          const isLastItem = adjustedSlices.length - 1 === index;
          const logo =
            // @ts-expect-error - TS2531 - Object is possibly 'null'.
            slice.type === 'USER_PIE' ? <Icon name="createPie32" /> : logoUrl;

          return (
            <Flex
              alignItems="center"
              borderColor="borderMain"
              borderStyle="solid"
              borderWidth={isLastItem ? '0' : '0 0 1px 0'}
              key={index}
              px={16}
              py={8}
            >
              <Flex width="70%">
                {logo && <SecurityLogo content={logo} />}
                <Flex flexDirection="column" justifyContent="center">
                  <PM fontWeight={600} color="foregroundNeutralSecondary">
                    {/* @ts-expect-error - TS2531 - Object is possibly 'null'. */}
                    {slice.symbol}
                  </PM>
                  {/* @ts-expect-error - TS2531 - Object is possibly 'null'. */}
                  <LM>{slice.name}</LM>
                </Flex>
              </Flex>
              <Flex width="30%" justifyContent="flex-end">
                <Flex alignItems="end" flexDirection="column">
                  {actionPill && <AppPill appPill={actionPill} my={4} />}
                  {typeof percentage === 'number' &&
                    typeof oldPercentage === 'number' && (
                      <Flex alignItems="center">
                        <PM mr={8}>{`${oldPercentage}%`}</PM>
                        <DottedArrow />
                        <PM ml={8}>{`${percentage}%`}</PM>
                      </Flex>
                    )}
                </Flex>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};
