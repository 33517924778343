import { PL } from '@m1/liquid-react';
import React from 'react';

import { ExternalLink } from '~/components/ExternalLink';
import { Container } from '~/toolbox/container';

export const BorrowUnavailableContainer = () => (
  <Container centered padded="wide" text>
    <PL>
      Portfolio Line of Credit is currently unavailable. If the problem
      persists, please contact{' '}
      <ExternalLink destination="SUPPORT_CENTER" label="support" />.
    </PL>
  </Container>
);
