import { PL, css, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import React, { ReactNode } from 'react';

import { useNavigate } from '~/hooks/useNavigate';

const BackButtonContainer = styled.div`
  background: transparent;
  margin-bottom: 24px;
`;

// TODO: see if I can replace this with liquid-react button.
const Button = styled.button(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.primary};
    cursor: pointer;
    font-family: system-ui;
    padding: unset;
    border: unset;
    background: unset;
    cursor: pointer;
    -webkit-appearance: unset;
  `,
);

export const BackButton = ({
  children,
  previousRouteName,
}: {
  children?: ReactNode;
  previousRouteName?: string;
}) => {
  const navigate = useNavigate();

  const onBackClick = () => {
    if (previousRouteName) {
      navigate({ to: previousRouteName });
    } else {
      window.history.back();
    }
  };

  return (
    <>
      <BackButtonContainer>
        <Button aria-label="back" onClick={onBackClick}>
          <Icon name="caretLeft24" />
          <PL fontWeight={600}>Back</PL>
        </Button>
      </BackButtonContainer>

      {children && children}
    </>
  );
};
