import { Box, Flex, styled } from '@m1/liquid-react';
import React from 'react';

import { Footer } from '~/components/Footer';
import { SignupNotifications } from '~/components/notifications';
import { useParams } from '~/hooks/useParams';

import { PromoHeader } from '../components';
import { RegisterUserPromotionFlow } from '../flow/RegisterUserPromotionFlow';

export const StyledFlex = styled(Flex)`
  position: relative;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgroundNeutralSecondary};
`;

export const CollectUsernamePassword = () => {
  const { promoCode } = useParams();
  return (
    <StyledFlex>
      <PromoHeader />

      <Box position="absolute" width="70vw" pt={24}>
        <SignupNotifications />
      </Box>

      <RegisterUserPromotionFlow promoCode={promoCode} />

      <Footer showMobileFooter breakpointOverride="XSMALL" />
    </StyledFlex>
  );
};
