import { LayoutableProps, Flex, styled, Pill } from '@m1/liquid-react';
import * as React from 'react';

import { SmartTransferFulfillmentConditionInput } from '~/redux/actions/smartTransfer/smartTransferActions.types';

import { CashFormatter } from '../../utils';

export type FulfillmentConditionInlineDescriptionTagsSmartTransferInputProps =
  SmartTransferFulfillmentConditionInput & {
    disabled?: boolean;
  };

export type FulfillmentConditionInlineDescriptionTagsProps = LayoutableProps &
  FulfillmentConditionInlineDescriptionTagsSmartTransferInputProps;

const FulfillmentConditionInlineDescriptionTagsContent = ({
  borrowAmount,
  borrowUtilization,
  capAmount,
  disabled,
  fulfillmentConditionType,
  fundingAmount,
  fundingPeriod,
}: FulfillmentConditionInlineDescriptionTagsSmartTransferInputProps) => {
  if (!fulfillmentConditionType) {
    return <Pill kind="danger" label="until endpoint" />;
  }

  switch (fulfillmentConditionType) {
    case 'CASH_BALANCE_CAP':
      return (
        <>
          <Pill kind="success" label="until reaching a balance of" />
          {typeof capAmount === 'number' ? (
            <Pill kind="success" label={CashFormatter.format(capAmount)} />
          ) : (
            <Pill kind="danger" label="a dollar amount" />
          )}
        </>
      );
    case 'CREDIT_BORROWED_CAP':
      return (
        <>
          <Pill kind="success" label="until reaching a balance of" />
          {typeof borrowAmount === 'number' ? (
            <Pill kind="success" label={CashFormatter.format(borrowAmount)} />
          ) : (
            <Pill kind="danger" label="a dollar amount" />
          )}
        </>
      );
    case 'CREDIT_UTILIZATION_CAP':
      return (
        <>
          <Pill kind="success" label="until reaching a total borrowed % of" />
          {typeof borrowUtilization === 'number' ? (
            <Pill kind="success" label={`${borrowUtilization}%`} />
          ) : (
            <Pill kind="danger" label="a percentage" />
          )}
        </>
      );
    case 'FUNDING_PER_PERIOD':
      return (
        <>
          <Pill kind="success" label="until transferring up to" />
          <Pill
            kind={fundingPeriod ? 'success' : 'danger'}
            label={readFundingPeriodLabel(fundingPeriod)}
          />
          {typeof fundingAmount === 'number' ? (
            <Pill kind="success" label={CashFormatter.format(fundingAmount)} />
          ) : (
            <Pill kind="danger" label="a dollar amount" />
          )}
        </>
      );
    case 'INDEFINITE':
      return (
        <Pill kind={disabled ? 'danger' : 'success'} label="indefinitely" />
      );
    case 'IRA_MONTHLY_FUNDING_PER_PERIOD':
    case 'IRA_QUARTERLY_FUNDING_PER_PERIOD':
    case 'IRA_WEEKLY_FUNDING_PER_PERIOD':
      return (
        <>
          <Pill kind="success" label="until contribution max" />
          <Pill
            kind="success"
            label={readIraTypePeriodLabel(fulfillmentConditionType)}
          />
          {typeof fundingAmount === 'number' ? (
            <Pill kind="success" label={CashFormatter.format(fundingAmount)} />
          ) : (
            <Pill kind="danger" label="a dollar amount" />
          )}
        </>
      );
    case 'IRA_YEARLY_FUNDING':
      return <Pill kind="success" label="until contribution max" />;
    default:
      return null;
  }
};

const readFundingPeriodLabel = (
  fundingPeriod:
    | SmartTransferFulfillmentConditionInput['fundingPeriod']
    | null
    | undefined,
): string => {
  switch (fundingPeriod) {
    case 'WEEK':
      return 'a weekly limit of';
    case 'MONTH':
      return 'a monthly limit of';
    case 'QUARTER':
      return 'a quarterly limit of';
    case 'YEAR':
      return 'a yearly limit of';
    default:
      return 'time limit';
  }
};

const readIraTypePeriodLabel = (
  iraType:
    | (
        | 'IRA_WEEKLY_FUNDING_PER_PERIOD'
        | 'IRA_MONTHLY_FUNDING_PER_PERIOD'
        | 'IRA_QUARTERLY_FUNDING_PER_PERIOD'
      )
    | null
    | undefined,
): string => {
  switch (iraType) {
    case 'IRA_WEEKLY_FUNDING_PER_PERIOD':
      return 'with an additional weekly limit of';
    case 'IRA_MONTHLY_FUNDING_PER_PERIOD':
      return 'with an additional monthly limit of';
    case 'IRA_QUARTERLY_FUNDING_PER_PERIOD':
      return 'with an additional quarterly limit of';
    default:
      return 'with an additional limit';
  }
};

const StyledFlexRoot = styled(Flex)`
  > *:not(:last-child) {
    margin-right: 4px;
  }
`;

export const FulfillmentConditionInlineDescriptionTags = ({
  borrowAmount,
  borrowUtilization,
  capAmount,
  disabled,
  fulfillmentConditionType,
  fundingAmount,
  fundingPeriod,
  ...rest
}: FulfillmentConditionInlineDescriptionTagsProps) => (
  <StyledFlexRoot {...rest}>
    <FulfillmentConditionInlineDescriptionTagsContent
      borrowAmount={borrowAmount}
      borrowUtilization={borrowUtilization}
      capAmount={capAmount}
      disabled={disabled}
      fulfillmentConditionType={fulfillmentConditionType}
      fundingAmount={fundingAmount}
      fundingPeriod={fundingPeriod}
    />
  </StyledFlexRoot>
);
