/* eslint-disable react/sort-comp */

import { Text, styled, css, Flex } from /* , LS, LL */ '@m1/liquid-react';
import * as React from 'react';

import { Divider } from '~/toolbox/divider';

export type RadioChoiceValue = string | boolean | number;

export type RadioChoiceProps = {
  disabled?: boolean;
  label: React.ReactElement | string;
  size?: 'small' | 'standard';
  value: RadioChoiceValue | null | undefined;
};

type Props = RadioChoiceProps & {
  border?: boolean;
  checked?: boolean;
  onChange?: (value: RadioChoiceValue | null | undefined) => void;
  contextualLink?: React.ReactElement | null | undefined;
};

// @ts-expect-error - TS7006 - Parameter 'props' implicitly has an 'any' type. | TS7006 - Parameter 'variable' implicitly has an 'any' type.
const useTheme = (props, variable) => props.theme.colors[variable];

type StyledInputProps = {
  $disabled: boolean;
  $size: RadioChoiceProps['size'];
};

export const RadioInput = styled.input<StyledInputProps>`
  position: relative;
  min-width: ${(props) => (props.$size === 'small' ? `14px` : `20px`)};
  height: ${(props) => (props.$size === 'small' ? '14px' : '20px')};
  margin-right: ${(props) => (props.$size === 'small' ? '8px' : '16px')};
  border-radius: 50%;
  cursor: pointer;
  outline: none;

  appearance: none;

  ${(props) => {
    return css`
      border: 2px solid ${useTheme(props, 'foregroundNeutralSecondary')};
      &:checked {
        background-color: ${useTheme(props, 'primary')};
        border-color: ${useTheme(props, 'primary')};
        &::before {
          transform: scale(0.45);
        }
      }

      &:disabled {
        background-color: ${useTheme(props, 'foregroundNeutralTertiary')};
        border: 2px solid ${useTheme(props, 'foregroundNeutralTertiary')};
        cursor: not-allowed;
      }

      &::before {
        position: absolute;
        width: 100%;
        height: 100%;

        background-color: ${useTheme(props, 'foregroundNeutralInverse')};
        border-radius: 50%;
        content: '';
        pointer-events: none;

        transition: transform 0.1s ease-in-out;
        transform: scale(1);
      }
    `;
  }};
`;

type StyledLabelProps = {
  $size: RadioChoiceProps['size'];
  $disabled: boolean;
};

const StyledLabel = styled.label<StyledLabelProps>`
  color: ${({ $disabled, ...props }) =>
    $disabled
      ? props.theme.colors.foregroundNeutralTertiary
      : props.theme.colors.foregroundNeutralMain};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'auto')};
  position: relative;
  padding: 2px 0;

  display: flex;
  align-items: center;
  cursor: pointer;

  ${(props) => {
    if (props.$size === 'small') {
      return css`
        &:hover {
          color: ${props.theme.colors.foregroundNeutralTertiary};
        }
        &:not(:last-child) {
          margin-bottom: 4px;
        }
      `;
    }

    if (props.$size === 'standard') {
      return css`
        &:not(:last-child) {
          margin-bottom: 14px;
        }
      `;
    }
  }};
`;

export class RadioChoice extends React.Component<Props> {
  static defaultProps = {
    checked: false,
    onChange: () => {},
    size: 'standard',
    border: false,
  };

  render() {
    const isDisabled = this.props.disabled ?? false;

    return (
      <>
        {this.props.border && <Divider spacing="compact" />}
        <StyledLabel $disabled={isDisabled} $size={this.props.size}>
          <RadioInput
            checked={this.props.checked}
            $disabled={isDisabled}
            $size={this.props.size}
            onChange={this.handleChange}
            disabled={isDisabled}
            type="radio"
          />
          {this.renderLabel()}
        </StyledLabel>
      </>
    );
  }

  renderLabel() {
    const { contextualLink, label, size } = this.props;

    if (typeof label === 'string') {
      return (
        <Flex flexDirection="column">
          <Text content={label} font={size === 'small' ? 'LS' : 'LL'} />
          {contextualLink}
        </Flex>
      );
    }

    return (
      <Flex flexDirection="column">
        {label}
        {contextualLink}
      </Flex>
    );
  }

  handleChange = (): void => {
    this.props.onChange?.(this.props.value);
  };
}
