import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { InvestActivityRoute } from './activity';
import { InvestAutomationRoutes } from './automation';
import { AvailableFundingSourcesRoute } from './available-funding-sources';
import { InvestBankConnectionRoute } from './bank-connection';
import { CustodialBeneficiaryRoute } from './custodial-beneficiary';
import { InvestFundAccountRoute } from './fund-account';
import { FundingHistoryRoute } from './funding-history';
import { HoldingRoute } from './holdings';

import { InvestPage } from './InvestPage';
import { InvestMarketingRoute } from './marketing';
import { PortfolioRoute } from './portfolio';
import { PortfolioEditorRoute } from './portfolio-editor';
import { PortfolioOrganizerRoute } from './portfolio-organizer';
import { UnmanagedHoldingsRoute } from './unmanaged-holdings';
import { UnmanagedHoldingsSelectParentPieRoute } from './unmanaged-holdings/select-parent-pie';

export const InvestRoute = () => {
  return (
    <Route path="invest" element={<InvestPage />}>
      {InvestAutomationRoutes()}
      {InvestFundAccountRoute()}
      {InvestMarketingRoute()}
      {InvestActivityRoute()}
      {InvestBankConnectionRoute()}
      {AvailableFundingSourcesRoute()}
      {FundingHistoryRoute()}
      {HoldingRoute()}
      {UnmanagedHoldingsRoute()}
      {PortfolioRoute()}
      {PortfolioEditorRoute()}
      {PortfolioOrganizerRoute()}
      {CustodialBeneficiaryRoute()}
      {UnmanagedHoldingsSelectParentPieRoute()}
      <Route
        path="funding"
        element={<Navigate to="/d/invest/portfolio" replace />}
      />
      <Route index element={<Navigate to="/d/invest/portfolio" replace />} />
    </Route>
  );
};
