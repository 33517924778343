import {
  ThemeMode,
  Box,
  PS,
  themeModes,
  useThemeMode,
  HM,
} from '@m1/liquid-react';
import capitalize from 'lodash-es/capitalize';
import * as React from 'react';

import { Radio } from '~/toolbox/radio';

export const SettingsAppearancePage = () => {
  const { themeMode, setThemeMode } = useThemeMode();

  const handleThemeChange = (value: ThemeMode) => {
    setThemeMode(value);
  };

  return (
    <Box maxWidth={600} px={16}>
      <HM fontWeight={300} content="Theme" />
      <Box p={16}>
        <Radio.Group value={themeMode} onChange={handleThemeChange}>
          {themeModes.map((mode) => (
            <Radio.Choice label={capitalize(mode)} value={mode} key={mode} />
          ))}
        </Radio.Group>

        <PS color="foregroundNeutralSecondary" mt={32}>
          By default, M1 uses your browser appearance as defined under Settings.
          However, you can choose either Light or Dark modes to match your
          preference.
        </PS>
      </Box>
    </Box>
  );
};
