import { styled, Box, theme } from '@m1/liquid-react';

export const StyledDiscoverySearchRoot = styled(Box)`
  position: relative;
  margin-top: 4px;
`;

export const StyledSearchResultsContainer = styled(Box)`
  box-shadow: 0px 0px 24px 0px rgba(83, 96, 115, 0.2);
  border-radius: 8px;
  margin-top: 8px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 300;
  width: 100%;
`;

export const StyledSearchResults = styled(Box)`
  background-color: ${theme.colors.backgroundNeutralSecondary};
  width: 100%;

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const StyledSearchResult = styled(Box)`
  display: flex;
  align-items: center;
  padding: 1rem;
  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.borderMain};
  }
  &:hover {
    cursor: pointer;
    background-color: ${theme.colors.backgroundNeutralMain};
    transition: background-color 200ms ease;
  }
`;
