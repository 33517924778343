import last from 'lodash-es/last';
import * as React from 'react';

import { useGetRetirementYearFiltersQuery } from '~/graphql/hooks';
import { Dropdown } from '~/toolbox/Dropdown';

import {
  StyledRetirementFilter,
  StyledRetirementFilterMessage,
} from './CategoryDetails.styled';

type RetirementFilterProps = {
  onChangeFilter: (filter: any) => void;
};

export const RetirementFilter = ({ onChangeFilter }: RetirementFilterProps) => {
  const { data } = useGetRetirementYearFiltersQuery();
  const [selectedYear, setSelectedYear] = React.useState('--');

  const handleSelectChange = (year: string) => {
    const value = year === '--' ? ['RETIREMENT'] : year.split(',');
    setSelectedYear(year);
    onChangeFilter(value);
  };

  const filterOptions = data?.viewer.screenSystemPies.subCategories.map(
    ({ category, categoryDetails }) => ({
      value: category.join(','),
      label: last(categoryDetails)?.name,
    }),
  );

  return (
    <StyledRetirementFilter>
      <StyledRetirementFilterMessage>
        I plan to retire in:{' '}
      </StyledRetirementFilterMessage>
      <Dropdown
        compact
        onChange={handleSelectChange}
        placeholder="--"
        name="retirementFilter"
        options={filterOptions}
        value={selectedYear}
      />
    </StyledRetirementFilter>
  );
};
