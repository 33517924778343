import { AuthenticateErrorEnum } from '~/graphql/types';

export const LOGIN_ACTIONS = Object.freeze({
  BEGIN_LOGIN_FLOW: 'BEGIN_LOGIN_FLOW' as const,
  CHANGE_LOGIN_FLOW_STEP: 'CHANGE_LOGIN_FLOW_STEP' as const,
  LOGIN_ATTEMPT_FAILED: 'LOGIN_ATTEMPT_FAILED' as const,
  FINISHED_LOGIN_FLOW: 'FINISHED_LOGIN_FLOW' as const,
  SUBMIT_LOGIN_FORM: 'SUBMIT_LOGIN_FORM' as const,
  SUBMIT_2FA_CODE_FORM: 'SUBMIT_2FA_CODE_FORM' as const,
  SUBMIT_AMFA_CODE_FORM: 'SUBMIT_AMFA_CODE_FORM' as const,
  CLICKED_REVIEW_LEGAL_TERMS: 'CLICKED_REVIEW_LEGAL_TERMS' as const,
  CONFIRM_ACCEPT_LEGAL_TERMS: 'CONFIRM_ACCEPT_LEGAL_TERMS' as const,
  CONFIRM_ACCEPT_LEGAL_TERMS_ON_LOGIN:
    'CONFIRM_ACCEPT_LEGAL_TERMS_ON_LOGIN' as const,
});

export type LoginAttemptFailedAction = {
  payload: AuthenticateErrorEnum | null | undefined;
  type: typeof LOGIN_ACTIONS.LOGIN_ATTEMPT_FAILED;
};

export type BeginLoginFLowAction = {
  payload: unknown;
  type: typeof LOGIN_ACTIONS.BEGIN_LOGIN_FLOW;
};

export type ChangeLoginFlowStepAction = {
  payload: unknown;
  type: typeof LOGIN_ACTIONS.CHANGE_LOGIN_FLOW_STEP;
};

export type SubmitLoginFormAction = {
  payload: unknown;
  type: typeof LOGIN_ACTIONS.SUBMIT_LOGIN_FORM;
};

export type ClickedReviewLegalTermsAction = {
  payload: unknown;
  type: typeof LOGIN_ACTIONS.CLICKED_REVIEW_LEGAL_TERMS;
};

export type Submit2faCodeFormAction = {
  payload: unknown;
  type: typeof LOGIN_ACTIONS.SUBMIT_2FA_CODE_FORM;
};

export type ConfirmAcceptLegalTermsAction = {
  payload: unknown;
  type: typeof LOGIN_ACTIONS.CONFIRM_ACCEPT_LEGAL_TERMS;
};

export type SubmitAmfaCodeForm = {
  payload: {
    emailCode: string;
  };
  type: typeof LOGIN_ACTIONS.SUBMIT_AMFA_CODE_FORM;
};

export type ConfirmAcceptLegalTermsOnLoginAction = {
  payload: unknown;
  type: typeof LOGIN_ACTIONS.CONFIRM_ACCEPT_LEGAL_TERMS_ON_LOGIN;
};

export type LoginAction =
  | LoginAttemptFailedAction
  | BeginLoginFLowAction
  | ChangeLoginFlowStepAction
  | ClickedReviewLegalTermsAction
  | ConfirmAcceptLegalTermsAction
  | ConfirmAcceptLegalTermsOnLoginAction
  | SubmitAmfaCodeForm
  | Submit2faCodeFormAction
  | SubmitLoginFormAction;
