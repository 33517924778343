import * as React from 'react';
import { Route } from 'react-router-dom';

import { SavingsOnboardingInitialFundingPage } from './SavingsOnboardingInitialFundingPage';

export const SavingsOnboardingInitialFunding = () => {
  return (
    <Route
      path="onboarding/savings-onboarding-initial-funding"
      element={<SavingsOnboardingInitialFundingPage />}
      handle={{
        fallbackRoute: {
          to: '/d/c/create-transfer',
        },
      }}
    />
  );
};
