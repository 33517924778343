import {
  Button,
  PXL,
  PL,
  Text,
  Flex,
  Box,
  Modal,
  ModalContent,
} from '@m1/liquid-react';
import * as React from 'react';

export type KeyDataModalProps = {
  closeModal: () => void;
  isOpen: boolean;
};

export const KeyDataModal = ({ isOpen, closeModal }: KeyDataModalProps) => {
  return (
    <Modal closeIcon={null} open={isOpen} onClose={() => closeModal()}>
      <ModalContent>
        <Flex
          alignItems="center"
          flexDirection="row"
          justifyContent="flex-start"
          mb={32}
          mt={12}
        >
          <Button
            kind="link"
            label=""
            leftIcon="close20"
            onClick={closeModal}
          />
          <Flex flex="1" justifyContent="center">
            <PXL fontWeight={600} content="Key Data" />
          </Flex>
        </Flex>
        <PL mb={24}>
          <Text fontWeight={600}>Current Price</Text>
          <Text fontWeight={400}>
            {' '}
            is the most recent selling price of a stock that is traded on an
            exchange and is the most reliable indicator of that security's
            present value.
          </Text>
        </PL>
        <PL mb={24}>
          <Text fontWeight={600}>Market Cap</Text>
          <Text fontWeight={400}>
            {' '}
            is the aggregate market value of a company represented in dollar
            amount.
          </Text>
        </PL>
        <PL mb={24}>
          <Text fontWeight={600}>Average Daily Trading Volume</Text>
          <Text fontWeight={400}>
            {' '}
            (10 day) is the average number of shares traded within a day in a
            given stock.
          </Text>
        </PL>
        <PL mb={24}>
          <Text fontWeight={600}>P/E Ratio</Text>
          <Text fontWeight={400}>
            {' '}
            (price-to-earnings ratio)is the ratio for valuing a company that
            measures its current share price relative to its earnings per share
            (EPS).
          </Text>
        </PL>
        <PL mb={24}>
          <Text fontWeight={600}>EPS</Text>
          <Text fontWeight={400}>
            {' '}
            (earnings per share) is calculated as a company's profit divided by
            the outstanding shares of its common stock.
          </Text>
        </PL>
        <PL mb={24}>
          <Text fontWeight={600}>Price/Sales Ratio</Text>
          <Text fontWeight={400}>
            {' '}
            is a valuation ratio that compares a company’s stock price to its
            revenues. It is an indicator of the value that financial markets
            have placed on each dollar of a company’s sales or revenues.
          </Text>
        </PL>
        <PL mb={24}>
          <Text fontWeight={600}>Dividend Yield</Text>
          <Text fontWeight={400}>
            {' '}
            is a financial ratio (dividend/price) that shows how much a company
            pays out in dividends each year relative to its stock price.
          </Text>
        </PL>
        <PL mb={24}>
          <Text fontWeight={600}>Beta</Text>
          <Text fontWeight={400}>
            {' '}
            is a measure used in fundamental analysis to determine the
            volatility of an asset or portfolio in relation to the overall
            market.
          </Text>
        </PL>
        <Flex justifyContent="flex-end">
          <Box width="50%">
            <Button label="Close" onClick={closeModal} fullWidth />
          </Box>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
