import { styled, keyframes } from '@m1/liquid-react';
import * as React from 'react';
import { components } from 'react-select';

// Not exported from LiRe
// eslint-disable-next-line no-restricted-imports
import { createGlobalStyle } from 'styled-components';

import { SelectProps } from './Dropdown.types';

export type MenuProps = {
  children?: React.ReactNode;
  className: string;
  selectProps: SelectProps;
};

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-15px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// FIXME(Wolf): Fix this once you upgrade react-select.
const ReactSelectMenu = components.Menu as any;

const StyledMenu = styled(ReactSelectMenu)``;

const GlobalMenuStyle = createGlobalStyle`
  ${StyledMenu} {
    animation: ${slideIn} 0.2s ease-in-out;
  }
`;

export const Menu = ({ children, ...rest }: MenuProps) => (
  <>
    <GlobalMenuStyle />
    <ReactSelectMenu {...rest} className={StyledMenu.styledComponentId}>
      {children}
    </ReactSelectMenu>
  </>
);
