import * as React from 'react';

import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';

import { useSearchParams } from '~/hooks/useSearchParams';
import { useSelector } from '~/redux/hooks';

export type RequireAuthGuardProps = {
  children: React.ReactNode;
};

export const RequireAuthGuard = ({ children }: RequireAuthGuardProps) => {
  const isLoggedIn = useSelector((state) => Boolean(state.auth.accessToken));
  const [isEvaluating, setIsEvaluating] = React.useState(true);

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isEvaluating) {
      if (!isLoggedIn) {
        // If user has personal loan direct link, route them to PLOE signup page
        if (currentPath?.includes('direct-loan-application')) {
          navigate({
            to: '/direct-loan-application',
            options: { replace: true },
          });
        }

        navigate({
          to: `login?${searchParams.toString()}`,
          options: { replace: true },
        });
      } else {
        setIsEvaluating(false);
      }
    }
  }, [
    navigate,
    isLoggedIn,
    currentPath,
    searchParams,
    setIsEvaluating,
    isEvaluating,
  ]);

  if (isEvaluating) {
    return null;
  }

  return children;
};
