import { styled } from '@m1/liquid-react';
import * as React from 'react';

import { MARGIN_CALL_INFORMATION_STEPS as STEPS } from '~/static-constants';

import { MarginCallInformationDisableAutoInvestStep } from './steps/disable-auto-invest';
import { MarginCallInformationHoldingsOverview } from './steps/MarginCallInformationHoldingsOverview';
import { MarginCallInformationInfoScreenStep } from './steps/MarginCallInformationInfoScreenStep';
import { MarginCallInformationOptionsStep } from './steps/MarginCallInformationOptionsStep';
import { MarginCallInformationPositionDetails } from './steps/MarginCallInformationPositionDetails';

// Styled Container
const StyledFlowContainer = styled.div`
  min-height: 100vh;
`;

type ResolveMarginCallContainerProps = {
  onFinishStep: () => void;
  step: ValueOf<typeof STEPS>;
};

export const ResolveMarginCallContainer = (
  props: ResolveMarginCallContainerProps,
) => {
  const steps = {
    // Step One
    [STEPS.INFO_SCREEN]: MarginCallInformationInfoScreenStep,

    // Step Two
    [STEPS.RESOLVE_CALL_OPTIONS]: MarginCallInformationOptionsStep,

    // Step Three - Sell Securities Flow
    [STEPS.DISABLE_AUTO_INVEST]: MarginCallInformationDisableAutoInvestStep,
    [STEPS.HOLDINGS_OVERVIEW]: MarginCallInformationHoldingsOverview,
    [STEPS.POSITION_DETAILS]: MarginCallInformationPositionDetails,
  };

  const Step = steps[props.step];
  return Step ? (
    <StyledFlowContainer>
      <Step {...props} />
    </StyledFlowContainer>
  ) : null;
};
