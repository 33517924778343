import { Button, HXS, PL } from '@m1/liquid-react';
import * as React from 'react';

import { NavigableButton } from '~/components/NavigableButton';

import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import { useSupportLink } from '~/hooks/useSupportLink';

import { ButtonGroup } from '~/toolbox/ButtonGroup';

export const ConfirmIdentityPage = () => {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const { to, onClick } = useSupportLink('SUPPORT_VERIFY_INVEST');

  const handleClickContinue = (
    e: React.KeyboardEvent | React.MouseEvent,
  ): void => {
    onClick?.(e);
    analytics.recordEvent('m1_document_upload_attempt');
    navigate({ to: '/d/home' });
  };

  return (
    <div
      style={{
        width: 490,
        margin: '0 auto',
        padding: `64px 0 100px 0`,
      }}
    >
      <HXS content="You're almost there!" />
      <PL
        content="We were unable to verify your identity automatically. Please send additional documentation through our secure upload."
        mt={16}
      />
      <PL content="Not verifying will prevent you from:" mt={32} />
      <ol
        style={{
          marginLeft: 24,
          marginTop: 16,
        }}
      >
        <PL as="li" content="Taking advantage of our Invest product" />
        <PL as="li" content="Depositing into a portfolio" mt={8} />
        <PL as="li" content="Any trading activities" mt={8} />
      </ol>
      <PL
        content="Please continue to complete verification and someone from our team will begin processing opening your account ASAP."
        mt={32}
      />
      <ButtonGroup mt={64}>
        <Button
          kind="secondary"
          label="Cancel"
          onClick={() => {
            navigate({ to: '/d/home' });
          }}
          size="large"
        />
        <NavigableButton
          kind="primary"
          label="Continue"
          onClick={handleClickContinue}
          size="large"
          to={to}
          target="_blank"
        />
      </ButtonGroup>
    </div>
  );
};
