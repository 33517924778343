import * as React from 'react';

import { SliceAvatarStatusFragment } from '~/graphql/types';
import { Indicator } from '~/toolbox/indicator';

export type SliceAvatarIndicatorProps = {
  portfolioSlice: SliceAvatarStatusFragment;
};

export const SliceAvatarIndicator = ({
  portfolioSlice,
}: SliceAvatarIndicatorProps) => {
  if (
    portfolioSlice.orderStatus &&
    portfolioSlice.orderStatus.isSetOnSelfOrInDescendants
  ) {
    return <Indicator color="java" icon="switch16" />;
  }

  if (
    portfolioSlice.rebalanceStatus &&
    portfolioSlice.rebalanceStatus.isSetOnSelfOrInDescendants
  ) {
    return <Indicator color="java" icon="rebalance16" />;
  }

  if (!portfolioSlice.to.isActive) {
    return <Indicator color="orange" icon="warning24" />;
  }

  return null;
};
