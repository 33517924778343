import React from 'react';

import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import { FormMockerReactHookForm } from '~/forms/FormMockers';
import { usePersonalLoansDirectQuery } from '~/graphql/hooks';
import { PersonalLoanDirectQuestionEnum } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { AppState } from '~/redux';
import { submitReactFormData } from '~/redux/actions';
import { Spinner } from '~/toolbox/spinner';

import { BackButton } from '../BackButton';
import { AnnualIncomeMonthlyRentMortgageForm } from '../components/AnnualIncomeMonthlyRentMortgageForm';
import { StyledHeader } from '../components/StyledHeader';
import { usePersonalLoanDirectWizardContext } from '../personal-loan-direct-wizard/usePersonalLoanDirectWizardContext';
import { getQuestionProps } from '../utils/getQuestionprops';

export type DirectToPersonalLoansAnnualIncomeMonthlyRentMortgageFormValues = {
  annualIncome: number;
  monthlyHousingCosts: number;
};

export const AnnualIncomeMonthlyRentMortgage = () => {
  const { goTo } = usePersonalLoanDirectWizardContext();
  const { back } = usePersonalLoanDirectWizardContext();
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  const persistedFormValues = useSelector((state: AppState) => {
    return state.reactHookForm['annualIncomeMonthlyHousingCost'];
  });
  const formMethods =
    useForm<DirectToPersonalLoansAnnualIncomeMonthlyRentMortgageFormValues>({
      defaultValues: persistedFormValues,
    });

  const { data: questionsData, loading: questionsLoading } =
    usePersonalLoansDirectQuery();

  const questions =
    questionsData?.viewer?.borrow?.personalLoans?.applicationFlowDirect
      ?.questions;
  let props;
  if (questions) {
    props = getQuestionProps({
      question: PersonalLoanDirectQuestionEnum.AnnualIncomeMonthlyRentMortgage,
      questions,
    });
  }

  const { continueLabel, title, subtitle, nextQuestionType } = props || {};

  const onSubmit: SubmitHandler<
    DirectToPersonalLoansAnnualIncomeMonthlyRentMortgageFormValues
  > = async (formData: any) => {
    dispatch(
      submitReactFormData({
        annualIncomeMonthlyHousingCost: {
          annualIncome: Number(formData.annualIncome),
          monthlyHousingCosts: Number(formData.monthlyHousingCosts),
        },
      }),
    );
    goTo(nextQuestionType ?? 'SOCIAL_SECURITY_NUMBER');
  };

  React.useEffect(() => {
    analytics.pageView('/direct-loan-application/financial-information');
  }, [analytics]);

  if (questionsLoading) {
    return <Spinner fullScreen centered />;
  }

  return (
    <div>
      <BackButton onBackClick={() => back()} />
      <StyledHeader
        content={title ?? 'Tell us about your financial situation.'}
        mb={24}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <AnnualIncomeMonthlyRentMortgageForm subtitle={subtitle} />
          <ResponsiveButton
            mt={48}
            label={continueLabel ?? 'Continue'}
            type="submit"
            size="large"
          />
          <FormMockerReactHookForm
            setValue={formMethods.setValue}
            fields={[
              {
                name: 'annualIncome',
                value: '150000',
              },
              {
                name: 'monthlyHousingCosts',
                value: '300',
              },
            ]}
          />
        </form>
      </FormProvider>
    </div>
  );
};
