import { isNil } from './typeAssertions';

export const isNumberString = (x: string): boolean => {
  return !isNaN(Number(x));
};

export const stringToValidNumber = (
  value: string | number | null | undefined,
  fallback: number = 0,
): number => {
  if (typeof value === 'number') {
    return value;
  }

  if (isNil(value)) {
    return fallback;
  }

  const numericValue = Number(value);
  if (Number.isNaN(numericValue)) {
    return fallback;
  }

  return numericValue;
};

export const isValidPercentage = (value: string): string => {
  const isValid = /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?\.?)$/.test(value);
  if (isValid) {
    return value;
  }
  return value.slice(0, -1);
};
