import { Box, Button } from '@m1/liquid-react';
import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { OnboardingHeader } from '~/components/Onboarding/OnboardingHeader';
import { useOnboardingPageQuery } from '~/graphql/hooks';
import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';
import { beginOnboardingFlow, logout } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

import style from './style.module.scss';

export const OnboardingPage = () => {
  const { data, loading } = useOnboardingPageQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const onboardingProps = useSelector((state) => ({
    config: state.config,
    product: state.newFlows.onboarding.product,
    step: state.newFlows.onboarding.step,
    upcomingStep: state.newFlows.onboarding.upcomingStep,
    subProduct: state.newFlows.onboarding.subProduct,
  }));
  const dispatch = useDispatch();

  const handleFinish = React.useCallback(
    (createdAccount: boolean): void => {
      const destination = createdAccount ? '/d/home' : '/logout';
      navigate({ to: destination });
    },
    [navigate],
  );

  React.useEffect(() => {
    const viewer = data?.viewer;
    const accountId =
      viewer?.accounts &&
      Array.isArray(viewer.accounts.edges) &&
      viewer.accounts.edges.length > 0 &&
      viewer.accounts.edges[0]?.node
        ? viewer.accounts.edges[0].node.id
        : null;
    const achRelationshipId =
      viewer?.accounts &&
      Array.isArray(viewer?.accounts?.edges) &&
      viewer.accounts.edges.length > 0 &&
      viewer?.accounts.edges[0]?.node?.lastAchRelationship?.isActive
        ? viewer.accounts.edges[0].node.lastAchRelationship.id
        : null;

    dispatch(
      beginOnboardingFlow({
        basePath: 'onboarding',
        initialState: {
          accountId,
          achRelationshipId,
        },
        onFinish: handleFinish,
        product: onboardingProps.product,
        plaidRedirect: location.state?.plaidPayload
          ? location.state.plaidPayload
          : null,
        plaidFailure: location.state?.plaidFailure
          ? location.state.plaidFailure
          : false,
        subProduct: onboardingProps.subProduct,
      }),
    );

    return () =>
      dispatch({
        // @ts-ignore add
        type: 'FINISHED_ONBOARDING_FLOW',
      });
  }, [
    dispatch,
    data?.viewer,
    handleFinish,
    location.state?.plaidFailure,
    location.state?.plaidPayload,
    onboardingProps.product,
    onboardingProps.subProduct,
  ]);

  if (loading || !data?.viewer) {
    return null;
  }

  return (
    <Box backgroundColor="backgroundNeutralSecondary" height="100vh">
      <OnboardingHeader flowName="onboarding" />
      <Outlet />
      {__ENV__ !== 'production' && (
        <div className={style.logout}>
          <Button
            kind="link"
            label="logout"
            onClick={() => dispatch(logout())}
          />
        </div>
      )}
    </Box>
  );
};
