import { Flex, Box, PXS, PS, useTheme } from '@m1/liquid-react';
import Highcharts from 'highcharts';
import capitalize from 'lodash-es/capitalize';
import * as React from 'react';

import {
  DataTypeEnum,
  HistoricalDividendFragment,
  TradeCashFlowFragment,
} from '~/graphql/types';
import { CashFormatter } from '~/utils';

import { ChartableSliceDatumPoint } from './ChartableSliceChart.types';

export const ChartableSliceChartFlagTooltip = ({
  point,
  totalYValue,
  dataType,
  x,
}: {
  point: ChartableSliceDatumPoint;
  totalYValue: Maybe<number>;
  dataType: DataTypeEnum | null | undefined;
  x: string | number | undefined;
}) => {
  const theme = useTheme();
  const dataTypeName = dataType?.replace(/_/g, ' ').toLowerCase();
  const TopForPoint = ({
    top,
  }: {
    top: HistoricalDividendFragment | TradeCashFlowFragment;
  }) => (
    <Flex
      minWidth={200}
      m="8px 0"
      color="foregroundNeutralMain"
      justifyContent="space-between"
    >
      <PS>{top.symbol}</PS>
      <PS>{CashFormatter.format(top.amount)}</PS>
    </Flex>
  );

  return (
    <>
      <Flex justifyContent="space-between" mb={4} color="foregroundNeutralMain">
        <PS>{capitalize(dataTypeName)}s</PS>
        {typeof totalYValue === 'number' && (
          <PS>{CashFormatter.format(totalYValue)}</PS>
        )}
      </Flex>
      <PXS color="foregroundNeutralSecondary" mb={11}>
        {typeof x === 'number' ? Highcharts.dateFormat('%B %e, %Y', x) : ''}
      </PXS>
      <Box
        height={1}
        minWidth={200}
        style={{
          borderTop: `1px solid ${theme.colors.borderMain}`,
        }}
      />
      <PXS mt={8} color="foregroundNeutralSecondary">
        Top {dataType === DataTypeEnum.NetCashFlow ? 'cash flow' : dataTypeName}
        s
      </PXS>
      {point.top?.map((top, index) => <TopForPoint key={index} top={top} />)}
    </>
  );
};
