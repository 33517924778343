import { Button, Flex, HM, useTheme } from '@m1/liquid-react';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { CloseButton } from '~/components/Cover/components';
import { GenericSystemError } from '~/components/GenericSystemError';
import { FinancialInformationConfirm } from '~/components/Onboarding/FinancialInformationConfirm';
import {
  IdentityFirstConfirmationScreenProfileSection,
  IdentityFirstConfirmationScreenSection,
  SectionRowData,
} from '~/components/Onboarding/IdentityFirstConfirmationScreenHelpers';
import { usePersonalLoansCreateAccountReviewPageQuery } from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import type { AppState } from '~/redux';
import { useSelector } from '~/redux/hooks';
import type { PersonalLoansApplicationFlowState } from '~/redux/reducers/newFlows/reducers/personalLoansApplicationReducer';
import { Divider } from '~/toolbox/divider';
import { Spinner } from '~/toolbox/spinner';
import { getEnumDescription } from '~/utils';
import { formatCurrencyWithCommas } from '~/utils/formatting';

import { PersonalLoansRequiredDocuments } from './PersonalLoansRequiredDocuments';

type PersonalLoansCreateAccountReviewComponentProps = {
  onFinishStep: (args?: 'edit') => void;
};

export const PersonalLoansCreateAccountReviewPage = ({
  onFinishStep,
}: PersonalLoansCreateAccountReviewComponentProps) => {
  const navigate = useNavigate();
  const { data, loading } = usePersonalLoansCreateAccountReviewPageQuery();
  const analytics = useAnalytics();
  const theme = useTheme();

  const isSmallViewport = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.SMALL})`,
  });

  const { loanAmount, loanPurpose, loanTerm, monthlyHousingCosts } =
    useSelector<PersonalLoansApplicationFlowState>(
      (state: AppState) => state.newFlows.PERSONAL_LOANS_APPLICATION,
    );

  if (loading) {
    return <Spinner fullScreen centered />;
  }

  if (!data?.viewer.profile || !data.viewer.onboarding || !data.viewer.user) {
    return <GenericSystemError />;
  }

  const { borrow, onboarding, profile, user } = data.viewer;
  const showModuleOneSection = user?.hasProduct;

  const loanPurposeDescription = getEnumDescription(
    loanPurpose,
    'LoanPurposeEnum',
  );

  const loanTermOptions =
    borrow?.personalLoans?.applicationFlow?.loanTermOptions;

  const loanTermDescription = loanTermOptions?.find(
    (option) => option?.value === Number(loanTerm),
  )?.description;

  const personalLoansQuestions = [
    { label: 'Loan purpose', value: loanPurposeDescription },
    {
      label: 'Preferred loan amount',
      value: `$${formatCurrencyWithCommas(loanAmount)}`,
    },
    { label: 'Preferred loan term', value: loanTermDescription },
    {
      label: 'Monthly housing',
      value: `$${formatCurrencyWithCommas(monthlyHousingCosts)}`,
    },
  ] as Array<SectionRowData>;

  return (
    <Flex flexDirection="column" margin="0 auto" maxWidth="996px" px={16}>
      <HM my={32}>Does everything look correct?</HM>
      <CloseButton
        color="foregroundNeutralSecondary"
        onClick={() => navigate({ to: '/d/home' })}
      />
      {isSmallViewport ? (
        <Flex flexDirection="column">
          {showModuleOneSection && (
            <IdentityFirstConfirmationScreenProfileSection
              profile={profile}
              user={user}
            />
          )}
          <FinancialInformationConfirm
            analyticsEvent="m1_pl_confirmation_edit"
            onboarding={onboarding}
            profile={profile}
          />
          {personalLoansQuestions && (
            <IdentityFirstConfirmationScreenSection
              headingLabel="Personal Loan details"
              editLabel="Edit"
              onEdit={() => {
                analytics.recordEvent('m1_pl_confirmation_edit', null, {
                  section_clicked: 'personalLoans',
                });
                onFinishStep('edit');
              }}
              rows={personalLoansQuestions}
            />
          )}
        </Flex>
      ) : (
        <Flex>
          <Flex flexDirection="column" width="50%" pr={24}>
            {showModuleOneSection ? (
              <IdentityFirstConfirmationScreenProfileSection
                profile={profile}
                user={user}
              />
            ) : (
              <FinancialInformationConfirm
                analyticsEvent="m1_pl_confirmation_edit"
                onboarding={onboarding}
                profile={profile}
              />
            )}
          </Flex>
          <Flex flexDirection="column" width="50%" pl={24}>
            {showModuleOneSection && (
              <FinancialInformationConfirm
                analyticsEvent="m1_pl_confirmation_edit"
                onboarding={onboarding}
                profile={profile}
              />
            )}
            {personalLoansQuestions && (
              <IdentityFirstConfirmationScreenSection
                headingLabel="Personal Loan details"
                editLabel="Edit"
                onEdit={() => {
                  analytics.recordEvent('m1_pl_confirmation_edit', null, {
                    section_clicked: 'personalLoans',
                  });
                  onFinishStep('edit');
                }}
                rows={personalLoansQuestions}
              />
            )}
          </Flex>
        </Flex>
      )}
      <Divider spacing="none" />
      <PersonalLoansRequiredDocuments />
      <Divider spacing="none" />
      <Flex>
        <Button
          mt={16}
          kind="primary"
          size="large"
          label="Yes, see my offers"
          type="submit"
          onClick={() => {
            analytics.recordEvent('m1_pl_confirmation_clicked');
            onFinishStep();
          }}
        />
      </Flex>
    </Flex>
  );
};
