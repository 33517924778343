import React from 'react';

import { useDocumentManagementPageQuery } from '~/graphql/hooks';
import {
  DocumentAssociatedService,
  DocumentBusinessPurpose,
  DocumentUploadRequestAssociatedService,
} from '~/graphql/types';
import { useSearchParams } from '~/hooks/useSearchParams';
import { Spinner } from '~/toolbox/spinner';
import { mapStringToEnumValue } from '~/utils/enums';

import { DocumentUploadGenericPage } from './components/DocumentUploadGenericPage';
import { DocumentUploadRequestPage } from './components/DocumentUploadRequestPage';

export const DocumentManagementPage = () => {
  const [searchParams] = useSearchParams();
  const { data, loading } = useDocumentManagementPageQuery({
    variables: {
      associatedService:
        (searchParams.get(
          'associatedService',
        ) as DocumentUploadRequestAssociatedService) ?? undefined,
    },
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  const documentRequestCenter = data?.viewer.documentUploadRequestsCenter;
  if (!documentRequestCenter?.documentUploadRequests?.edges?.[0]?.node) {
    // if there is no document request, show the generic upload page
    // first, check for path parameters
    const associatedService = searchParams.get('searchParams');
    const businessPurpose = searchParams.get('businessPurpose');

    return (
      <DocumentUploadGenericPage
        associatedService={mapStringToEnumValue(
          associatedService,
          DocumentAssociatedService,
          DocumentAssociatedService.Other,
        )}
        businessPurpose={mapStringToEnumValue(
          businessPurpose,
          DocumentBusinessPurpose,
          DocumentBusinessPurpose.Other,
        )}
      />
    );
  }

  return (
    <DocumentUploadRequestPage documentRequestCenter={documentRequestCenter} />
  );
};
