import { Button, HXS, PXL, Box, styled } from '@m1/liquid-react';
import * as React from 'react';

import { useAccountListFilteredByPaymentEligibilityQuery } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import { ChangePaymentAccountForm } from '~/pages/dashboard/referrals/components/ChangePaymentAccountForm';
import { selectPaymentsAccount } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Spinner } from '~/toolbox/spinner';

// Styled Components
const StyledContainer = styled(Box)`
  margin: 0 auto;
  padding: 64px 0 100px 0;
  width: 580px;
`;

export const SelectPaymentAccountPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, loading } = useAccountListFilteredByPaymentEligibilityQuery({
    variables: {
      first: 100,
      filterForPaymentsEligibility: true,
    },
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  const viewer = data?.viewer;
  const hasEligibleAccounts = viewer?.accounts?.edges?.length;

  const handleClose = () => {
    navigate({ to: '/d/settings/payments' });
  };

  const onSubmit = (values: { accountId: string }): void => {
    dispatch(
      selectPaymentsAccount({
        accountId: values.accountId,
        navigateTo: '/d/invest/portfolio',
      }),
    );
  };

  return (
    <StyledContainer>
      {hasEligibleAccounts ? (
        <>
          <HXS content="Select Payment Account" mb={16} />
          <PXL
            content="Choose a default account for receiving payments. (Sorry, no retirement accounts!)"
            mb={32}
          />
          <ChangePaymentAccountForm onSubmit={onSubmit} />
        </>
      ) : (
        <>
          <HXS content="No Account Eligible" mb={16} />
          <PXL
            content="You have no eligible accounts for receiving payments. Please open a non-retirement account to continue."
            mb={32}
          />
          <ButtonGroup>
            <Button
              kind="secondary"
              label="Close"
              onClick={handleClose}
              size="large"
            />
            <Button
              label="Open Account"
              onClick={() => {
                navigate({ to: '/d/c/open-account' });
              }}
              size="large"
            />
          </ButtonGroup>
        </>
      )}
    </StyledContainer>
  );
};
