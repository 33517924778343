import {
  Box,
  Button,
  Flex,
  LL,
  LM,
  LS,
  Modal,
  ModalContent,
  ModalTitle,
  PL,
  PM,
  PXL,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import {
  LiquidateModalContainerDocument,
  useLiquidateModalContainerQuery,
  useMarkUnmanagedHoldingsForLiquidationMutation,
} from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { GridTable } from '~/toolbox/grid-table';
import { Spinner } from '~/toolbox/spinner';

import { LiquidateModalTradingWindow } from './LiquidateModalTradingWindow';

type LiquidateUnmanangedHoldingsModalProps = {
  active: boolean;
  disableConfirm: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onCompleted: () => void;
  onError: () => void;
  unmanagedHoldingIds: Array<string>;
};

export const LiquidateUnmanangedHoldingsModal = ({
  active,
  disableConfirm,
  onClose,
  onConfirm,
  onCompleted,
  onError,
  unmanagedHoldingIds,
}: LiquidateUnmanangedHoldingsModalProps) => {
  const accountId = useSelector((state) => state.global.activeAccountId);
  const navigate = useNavigate();

  const { addToast } = useToast();

  const [markUnmanagedHoldingsForLiquidation, { loading: mutationLoading }] =
    useMarkUnmanagedHoldingsForLiquidationMutation({});

  const handleConfirm = () => {
    onConfirm();
    markUnmanagedHoldingsForLiquidation({
      variables: {
        input: {
          unmanagedHoldingIds,
        },
      },
      onCompleted: (data) => {
        if (data.markUnmanagedHoldingsForLiquidation) {
          const { errorMessage, successMessage } =
            data.markUnmanagedHoldingsForLiquidation;
          if (successMessage) {
            addToast({
              content: successMessage ?? errorMessage,
              duration: 'short',
              kind: successMessage ? 'success' : 'alert',
            });

            if (successMessage) {
              onCompleted();
            }
          }
        }
      },
      onError: (err) => {
        onError();
        addToast({
          content: err.message,
          duration: 'short',
          kind: 'alert',
        });
      },
      refetchQueries: [
        {
          query: LiquidateModalContainerDocument,
          variables: {
            accountId,
            unmanagedHoldingIdsForMergedSlices: unmanagedHoldingIds,
          },
        },
      ],
    });
    navigate({ to: '/d/invest/unmanaged-holdings' });
  };

  usePortaledSpinner(mutationLoading);

  const { data, loading } = useLiquidateModalContainerQuery({
    variables: {
      accountId: accountId as string,
      unmanagedHoldingIdsForMergedSlices: unmanagedHoldingIds,
    },
    skip: !accountId,
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (
    data?.node?.__typename !== 'Account' ||
    !data.node.receivedSecurities?.unmanagedHoldingsStagedForLiquidate
  ) {
    return <GenericSystemError />;
  }

  const unmanagedHoldingsStagedForLiquidate =
    data?.node?.receivedSecurities?.unmanagedHoldingsStagedForLiquidate;

  const {
    accountDetails,
    holdings,
    primaryButtonLabel,
    secondaryButtonLabel,
    sectionHeader,
    sectionMessage,
    title,
    trading,
  } = unmanagedHoldingsStagedForLiquidate;

  if (!primaryButtonLabel || !secondaryButtonLabel || !accountDetails) {
    return <GenericSystemError />;
  }

  return (
    <Modal open={active} onClose={onClose}>
      <ModalContent width="wide">
        <Flex
          alignItems="center"
          flexDirection="column"
          justifyContent="space-between"
          mb={64}
          mt={16}
          width="100%"
        >
          {title && (
            <Box mb={32}>
              <ModalTitle>{title}</ModalTitle>
            </Box>
          )}
          {accountDetails.header && (
            <PL color="foregroundNeutralSecondary" fontWeight={600}>
              {accountDetails.header}
            </PL>
          )}
          {accountDetails.subheader && (
            <Flex alignItems="center" mb={16} mt={8}>
              <Icon name="rootPies24" />
              <PXL fontWeight={600} display="inline-flex" ml={8}>
                {accountDetails.subheader}
              </PXL>
            </Flex>
          )}
        </Flex>
        <Flex flexDirection="column" mt={24}>
          {sectionHeader && (
            <PXL fontWeight={600} color="foregroundNeutralSecondary">
              {sectionHeader}
            </PXL>
          )}
          {sectionMessage && (
            <PM mt={8} color="foregroundNeutralSecondary">
              {sectionMessage}
            </PM>
          )}
        </Flex>
        <Box mt={16}>
          <GridTable
            hasHeaderRow={false}
            gridTemplateColumns="70% auto"
            style={{
              paddingTop: '8px',
              maxHeight: '300px',
              overflowY: 'auto',
            }}
          >
            {holdings?.map((holding, index) => (
              <GridTable.Row key={index}>
                <GridTable.Cell>
                  <Flex flexDirection="column">
                    {holding?.sublabel && (
                      <LS color="foregroundNeutralSecondary">
                        {holding.sublabel}
                      </LS>
                    )}
                    {holding?.label && <LM>{holding.label}</LM>}
                  </Flex>
                </GridTable.Cell>
                <GridTable.Cell>
                  <Flex flexDirection="column" alignItems="flex-end">
                    {holding?.quantitySection?.label && (
                      <LS color="foregroundNeutralSecondary">
                        {holding.quantitySection.label}
                      </LS>
                    )}
                    {holding?.quantitySection?.value && (
                      <LL mt={4}>{holding.quantitySection.value}</LL>
                    )}
                  </Flex>
                </GridTable.Cell>
              </GridTable.Row>
            ))}
          </GridTable>
        </Box>
        {trading?.nextTradingTime && (
          <>
            <Box mt={24} mb={16}>
              <PXL fontWeight={600} color="foregroundNeutralSecondary">
                Next trade window
              </PXL>
            </Box>
            <LiquidateModalTradingWindow
              nextTradingTime={trading.nextTradingTime}
            />
          </>
        )}
        <ButtonGroup behavior="centered" mt={32}>
          <Button
            label={secondaryButtonLabel}
            kind="secondary"
            onClick={onClose}
          />
          <Button
            onClick={handleConfirm}
            label={primaryButtonLabel}
            disabled={disableConfirm}
            kind="primary"
          />
        </ButtonGroup>
      </ModalContent>
    </Modal>
  );
};
