import * as React from 'react';
import { Route } from 'react-router-dom';

import { PositionDetailsPage } from './PositionDetailsPage';

export const PositionDetailsRoute = () => {
  return (
    <Route path="position/:positionId" element={<PositionDetailsPage />} />
  );
};
