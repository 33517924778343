import * as React from 'react';

import { NavigationQuery } from '~/graphql/types';
import { DashboardNavigation } from '~/hooks/useDashboardNavigation';
import { CashFormatter } from '~/utils/formatting';

import { SideNavLink } from './SideNavLink';

type SideNavSpendProps = {
  selectedNavigation: DashboardNavigation;
  data: NavigationQuery | undefined;
};

export const SideNavSpend = ({
  selectedNavigation,
  data,
}: SideNavSpendProps) => {
  const creditInfo = data?.viewer?.credit;
  const hasActiveAccount = creditInfo?.hasActiveAccount ?? false;
  const hasSuspendedAccount = creditInfo?.hasSuspendedAccount ?? false;
  const hasAccount = hasActiveAccount || hasSuspendedAccount;
  const activeAccount = creditInfo?.activeAccount;

  return (
    <>
      {hasAccount && activeAccount ? (
        <SideNavLink
          selected={selectedNavigation.activeL1Nav === 'spend'}
          key="spend"
          label={
            <>
              <div>Owner's Rewards</div>
              <div>Credit Card</div>
            </>
          }
          subText={CashFormatter.format(activeAccount?.currentBalance ?? 0)}
          linkTo="/d/spend"
        />
      ) : null}
      {!hasAccount ? (
        <SideNavLink
          selected={selectedNavigation.activeL1Nav === 'spend'}
          icon={{
            position: 'right',
            default: 'addBubble16',
            active: 'addBubble16',
          }}
          key="spend"
          label="Add account"
          subText="Credit"
          linkTo="/d/spend"
          analyticsProps={{
            name: 'add_account_clicked',
            parameters: {
              product: 'spend',
            },
          }}
          id="add-account-spend"
        />
      ) : null}
    </>
  );
};
