import * as React from 'react';
import {
  Collapse as CollapseKeepOnClose,
  UnmountClosed as CollapseUnmountOnClose,
} from 'react-collapse';

import styles from './style.module.scss';

/* React-Collapse now requires custom transition styling, it is no longer automatically applied. If adding a custom
theme, be sure to add a 'transition' style prop. https://github.com/nkbt/react-collapse#migrating-from-v4-to-v5
*/
type CollapseProps = {
  theme?: {
    collapse?: Record<string, any>;
    content?: Record<string, any>;
  };
  unmountOnClose?: boolean | null | undefined;
};

export class Collapse extends React.Component<CollapseProps> {
  render() {
    const { unmountOnClose, theme, ...rest } = this.props;
    const Component = unmountOnClose
      ? CollapseUnmountOnClose
      : CollapseKeepOnClose;
    return (
      <Component
        {...rest}
        // @ts-expect-error - TS2769 - No overload matches this call.
        theme={
          theme || {
            collapse: styles.CollapseCustom,
          }
        }
      />
    );
  }
}
