import { SagaIterator } from 'redux-saga';

import {
  UpdatePieTreeDocument,
  UpdatePieTreeMutationResult,
} from '~/graphql/hooks';
import { clearPieShareUrlData } from '~/graphql/local/reactiveVars/pieShareUrl';
import { UpdatePieTreeInput } from '~/graphql/types';

import { apolloMutationSaga } from '../apolloMutationSaga';

import { call } from '../effects';

export function* updatePieTreeSaga(
  serializedPieTree: string,
  isCrypto?: boolean,
): SagaIterator<UpdatePieTreeMutationResult> {
  try {
    const result: UpdatePieTreeMutationResult = yield call(apolloMutationSaga, {
      mutation: UpdatePieTreeDocument,
      variables: {
        input: {
          serializedTree: serializedPieTree,
          isCrypto,
        } satisfies UpdatePieTreeInput,
      },
    });

    const pieId = result.data?.updatePieTree?.pie?.id;
    if (pieId) {
      clearPieShareUrlData(pieId);
    }

    return result;
  } catch (e: any) {
    const defaultErrorMessage =
      'This update could not be saved. Try again or contact us for further assistance. ';

    throw new Error(e.message ?? defaultErrorMessage);
  }
}
