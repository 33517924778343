import {
  HistoricalQuotePeriodEnum,
  IntradayQuotePeriodEnum,
  IntradayQuoteIntervalEnum,
} from '~/graphql/types';

export const INITIAL_VARIABLES = {
  securityPeriod: HistoricalQuotePeriodEnum.ThreeMonths,
  intradayPeriod: IntradayQuotePeriodEnum.LatestDay,
  intradayInterval: IntradayQuoteIntervalEnum.OneMinute,
  useSecurityPeriod: false,
  useIntradayPeriod: true,
  isCrypto: false,
};

export const PERIODS: {
  label: string;
  value: HistoricalQuotePeriodEnum | IntradayQuotePeriodEnum;
}[] = [
  {
    label: '1D',
    value: IntradayQuotePeriodEnum.LatestDay,
  },
  {
    label: '1W',
    value: IntradayQuotePeriodEnum.LatestWeek,
  },
  {
    label: '1M',
    value: HistoricalQuotePeriodEnum.OneMonth,
  },
  {
    label: '3M',
    value: HistoricalQuotePeriodEnum.ThreeMonths,
  },
  {
    label: '6M',
    value: HistoricalQuotePeriodEnum.SixMonths,
  },
  {
    label: 'YTD',
    value: HistoricalQuotePeriodEnum.YearToDate,
  },
  {
    label: '1Y',
    value: HistoricalQuotePeriodEnum.OneYear,
  },
  {
    label: '2Y',
    value: HistoricalQuotePeriodEnum.TwoYears,
  },
  {
    label: '5Y',
    value: HistoricalQuotePeriodEnum.FiveYears,
  },
];
