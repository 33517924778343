import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { RequireAuthGuard } from '~/router/Authentication/RequireAuthGuard';

import { SettingsAccountsRoute } from './accounts';
import { SettingsAppearanceRoute } from './appearance';
import { SettingsDocumentsRoute } from './documents';
import { FeedbackFormRoute } from './feedback';
import { InvestSettingsRoute } from './invest/InvestSettingsRoute';
import { SettingsNotificationsRoute } from './notifications';
import { SettingsPaymentsRoute } from './payments';
import { SettingsPlatformBillingRoute } from './platform-billing';
import { ProfileRoute } from './profile';
import { SettingsSecurityRoute } from './security';
import { SettingsPage } from './SettingsPage';

export const SettingsRoute = () => {
  return (
    <Route
      path="settings"
      element={
        <RequireAuthGuard>
          <SettingsPage />
        </RequireAuthGuard>
      }
    >
      {SettingsAccountsRoute()}
      {SettingsPlatformBillingRoute()}
      {InvestSettingsRoute()}
      {SettingsDocumentsRoute()}
      {SettingsNotificationsRoute()}
      {SettingsPaymentsRoute()}
      {ProfileRoute()}
      {SettingsSecurityRoute()}
      {FeedbackFormRoute()}
      {SettingsAppearanceRoute()}
      <Route index element={<Navigate to="/d/settings/profile" replace />} />
    </Route>
  );
};
