import { Box, css, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

// TODO: We need to add WebTarget as an export of  LiRe or tweak our eslint config.
// eslint-disable-next-line
import { WebTarget } from 'styled-components';

import type { LinkProps } from '../link';
import { Link } from '../link/Link';

import { hasRouterLinkProps } from '../link/Link.utils';

import { GridTableCell } from './cell';
import { GridContext } from './context';

export type GridTableRowProps = React.PropsWithChildren<{
  as?: WebTarget | undefined;
  __navigable?: boolean;
  gridTemplateColumns?: string;
  isHighlighted?: boolean;
  leftBorderColor?: string;
  isNewStyle?: boolean;
  isVirtualized?: boolean;
  usesCaretLeftIndicator?: boolean;
  isCaretLeftCell?: boolean;
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: React.MouseEventHandler<HTMLElement>;
  leftBorderTopRadius?: number | string;
  rightBorderTopRadius?: number | string;
}>;

const getRowPadding = (leftBorderColor?: string, isCaretLeftCell?: boolean) => {
  if (leftBorderColor) {
    return '0 2px 0 8px';
  }
  if (isCaretLeftCell) {
    return '0 2px 0 0';
  }
  return '0 2px 0 2px';
};

export const StyledGridTableRow = styled(Box)<
  GridTableRowProps & {
    gridTemplateColumns: string;
  }
>`
  color: ${({ theme: { colors } }) => colors.foregroundNeutralMain};
  display: grid;
  grid-template-rows: minmax(52px, auto);
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  grid-column-gap: 8px;
  border-top: ${({ theme: { colors } }) => `1px solid ${colors.borderMain}`};

  margin-top: -1px;

  ${({
    isNewStyle,
    theme: { colors },
    leftBorderColor,
    isCaretLeftCell,
    leftBorderTopRadius,
    rightBorderTopRadius,
  }) =>
    !isNewStyle
      ? css`
          padding: 0 8px;
          border-right: 1px solid ${colors.borderMain};
          border-bottom: 1px solid ${colors.borderMain};
          border-left: ${leftBorderColor
            ? `8px solid ${leftBorderColor}`
            : `1px solid ${colors.borderMain}`};

          &:first-child {
            border-top-left-radius: ${leftBorderTopRadius ?? '8px'};
            border-top-right-radius: ${rightBorderTopRadius ?? '8px'};
            border-top: 1px solid ${colors.borderMain};
            padding: 0 8px;
          }
          &:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            border-bottom-color: ${colors.borderMain};
          }

          @media screen and (min-width: ${({ theme }) =>
              theme.breakpoints.XXSMALL}) {
            grid-column-gap: 16px;
            padding: 0 16px;
          }
        `
      : css`
          padding: ${getRowPadding(leftBorderColor, isCaretLeftCell)};
          border-left: ${leftBorderColor
            ? `4px solid ${leftBorderColor}`
            : `none`};
          min-height: 64px;

          &:first-child {
            border-top: none;
          }
          &:last-child {
            margin-bottom: 24px;
          }

          @media screen and (min-width: ${({ theme }) =>
              theme.breakpoints.XXSMALL}) {
            grid-column-gap: 24px;
          }
        `}

  ${({ __navigable, theme: { colors } }) =>
    __navigable &&
    `
    color: ${colors.foregroundNeutralMain};
    cursor: pointer;
    transition: background-color 200ms;
    &:hover {
      background-color: ${colors.foregroundNeutralInverse}
    }
    `};

  &:hover {
    background-color: ${({ isHighlighted, theme: { colors } }) =>
      !isHighlighted && colors.backgroundNeutralMain};
  }

  background: ${({ isHighlighted, theme: { colors } }) =>
    isHighlighted
      ? `linear-gradient(to right, ${colors.backgroundNeutralTertiary} 50%, ${colors.backgroundNeutralMain} 50%);`
      : colors.backgroundNeutralSecondary};
  background-size: 200% 100%;
  background-position: bottom right;
  transition: all 0.7s ease-out;
  background-position: ${({ isHighlighted }) =>
    isHighlighted ? 'left bottom' : 'right bottom'};

  ${({ isVirtualized, theme: { breakpoints } }) =>
    isVirtualized &&
    `
    &:first-child {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      padding-left: 16px;
      padding-right: 8px;
      @media screen and (min-width: ${breakpoints.XXSMALL}) {
        padding-right: 8px; // Need this to override media query in oldStyleCss
      }
    }
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-width: 1px;
    border-right-width: 1px;
    padding-right: 8px;
    @media screen and (min-width: ${breakpoints.XXSMALL}) {
      padding-right: 8px; // Need this to override media query in oldStyleCss
    }
    background-color: transparent; // White background causes a bleed effect on the bottom border when virtualized
  `};
`;

const IconContainer = styled(Box)`
  animation: 0.75s show;

  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 32px;
    }
  }
`;

export const GridTableRow = ({ children, ...props }: GridTableRowProps) => {
  const context = React.useContext(GridContext);
  const gridTemplateColumns =
    props.gridTemplateColumns || context.gridTemplateColumns || 'repeat(auto)';

  const showCaretLeftIndicator =
    props?.usesCaretLeftIndicator && props?.isHighlighted;

  return (
    <StyledGridTableRow
      {...props}
      gridTemplateColumns={
        showCaretLeftIndicator
          ? `32px ${gridTemplateColumns}`
          : gridTemplateColumns
      }
      isNewStyle={context.isNewStyle}
      isCaretLeftCell
      isVirtualized={context.isVirtualized}
    >
      {showCaretLeftIndicator && (
        <GridTableCell
          textAlign="center"
          content={
            <IconContainer>
              <Icon color="foregroundNeutralMain" name="caretLeft16" />
            </IconContainer>
          }
          isCaretLeftCell
        />
      )}

      {children}
    </StyledGridTableRow>
  );
};

type GridTableNavigableRowProps = GridTableRowProps &
  Omit<LinkProps, 'to'> & {
    to?: string;
    params?: Record<string, any>;
  };

export const GridTableNavigableRow = (props: GridTableNavigableRowProps) => {
  const context = React.useContext(GridContext);
  const gridTemplateColumns =
    props.gridTemplateColumns || context.gridTemplateColumns || 'repeat(auto)';
  const isNewStyle = context.isNewStyle;

  // This potentially could be its own component and used elsewhere. (from legacy code)
  const includesRoutableProps = hasRouterLinkProps(props);
  const showCaretLeftIndicator =
    !context.isNewStyle && props.usesCaretLeftIndicator !== false;

  return (
    <GridTableRow
      {...props}
      __navigable
      as={includesRoutableProps ? Link : 'a'}
      gridTemplateColumns={`${gridTemplateColumns} ${showCaretLeftIndicator ? '8px' : ''}`}
      isNewStyle={isNewStyle}
    >
      {props.children}
      {showCaretLeftIndicator && (
        <GridTableCell
          alignSelf="center"
          content={
            <Icon color="foregroundNeutralSecondary" name="caretRight16" />
          }
          key="navigable-row-caret-icon-cell"
        />
      )}
    </GridTableRow>
  );
};
GridTableNavigableRow.displayName = 'GridTableNavigableRow';
