import { ObservableQuery } from '@apollo/client';
import * as React from 'react';

import {
  CryptoSortOptionInput,
  CryptoSortTypeEnum,
  FundLimitOptionInput,
  FundLimitTypeEnum,
  FundSortOptionInput,
  FundSortTypeEnum,
  SecurityLimitOptionInput,
  SecurityLimitTypeEnum,
  SecuritySortOptionInput,
  SecuritySortTypeEnum,
  SortDirectionEnum,
  SystemPieSortTypeEnum,
  SystemPieSortOptionInput,
} from '~/graphql/types';

type InputType<T extends FundLimitTypeEnum | SecurityLimitTypeEnum> =
  T extends FundLimitTypeEnum
    ? FundLimitOptionInput
    : T extends SecurityLimitTypeEnum
      ? SecurityLimitOptionInput
      : never;

export const makeLimit = <T extends FundLimitTypeEnum | SecurityLimitTypeEnum>(
  type: T,
): InputType<T> => {
  return {
    type: type,
    min: null,
    max: null,
    inclusive: true,
  } as InputType<T>;
};

export const onlyActiveLimits = (
  limit: SecurityLimitOptionInput | FundLimitOptionInput,
): boolean => {
  return Boolean(limit.min || limit.max);
};

type SecurityChangeInput =
  | SecuritySortOptionInput
  | SecuritySortOptionInput[]
  | null
  | undefined;
type FundChangeInput =
  | FundSortOptionInput
  | FundSortOptionInput[]
  | null
  | undefined;
type CryptoChangeInput =
  | CryptoSortOptionInput
  | CryptoSortOptionInput[]
  | null
  | undefined;

export const onScreenerSortChange = <
  T extends
    | SecuritySortTypeEnum
    | FundSortTypeEnum
    | CryptoSortTypeEnum
    | SystemPieSortTypeEnum,
>(
  sort:
    | SecurityChangeInput
    | FundChangeInput
    | CryptoChangeInput
    | SystemPieSortOptionInput,
  sortType: T,
) => {
  if (!sort) {
    return [];
  }

  const currentSort = Array.isArray(sort) ? sort[0] : sort;

  let direction = SortDirectionEnum.Desc;
  if (currentSort.type === sortType) {
    direction =
      currentSort.direction === SortDirectionEnum.Asc
        ? SortDirectionEnum.Desc
        : SortDirectionEnum.Asc;
  } else if (sortType === 'NAME') {
    direction = SortDirectionEnum.Asc;
  }

  return [
    {
      type: sortType,
      direction,
    },
  ];
};

type HandlePreviousScreenerPageProps = {
  setPrevCursor: React.Dispatch<React.SetStateAction<(string | null)[]>>;
  prevCursor: (string | null)[];
  fetchMore: ObservableQuery['fetchMore'];
};

export const handlePreviousScreenerPage = ({
  setPrevCursor,
  prevCursor,
  fetchMore,
}: HandlePreviousScreenerPageProps) =>
  fetchMore({
    variables: {
      after: prevCursor[1],
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      if (!fetchMoreResult) {
        return prev;
      }

      setPrevCursor((prevCursors) => {
        const newCursors = prevCursors;
        newCursors.shift();
        return newCursors;
      });

      return Object.assign({}, prev, {
        ...fetchMoreResult,
        viewer: {
          ...fetchMoreResult.viewer,
          securities: fetchMoreResult.viewer.securities,
        },
      });
    },
  });

type HandleNextScreenerPageProps = {
  setPrevCursor: React.Dispatch<React.SetStateAction<(string | null)[]>>;
  endCursor: string | null | undefined;
  fetchMore: ObservableQuery['fetchMore'];
};

export const handleNextScreenerPage = ({
  endCursor,
  setPrevCursor,
  fetchMore,
}: HandleNextScreenerPageProps) => {
  fetchMore({
    variables: {
      after: endCursor,
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      if (!fetchMoreResult) {
        return prev;
      }

      setPrevCursor((prevCursors) => [
        prev.viewer.securities.pageInfo.endCursor || null,
        ...prevCursors,
      ]);

      return Object.assign({}, prev, {
        ...fetchMoreResult,
        viewer: {
          ...fetchMoreResult.viewer,
          securities: fetchMoreResult.viewer.securities,
        },
      });
    },
  });
};
