import { Box, Flex, HS, PL } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';

import { useUpdateRewardsPayoutLocationStepQuery } from '~/graphql/hooks';

import { Spinner } from '~/toolbox/spinner';

import { CreditCardPayoutActions } from './components/PayoutActions';

export const RewardsPayoutLocation = () => {
  const { data, loading } = useUpdateRewardsPayoutLocationStepQuery();

  if (loading) {
    return <Spinner />;
  }

  const destinationContent =
    data?.viewer.credit?.selectRewardDestinationContent;

  if (!destinationContent) {
    return <GenericSystemError />;
  }

  return (
    <Box width={550} m="0 auto">
      <Flex
        alignItems="center"
        flexDirection="column"
        mt={150}
        justifyContent="center"
      >
        <Box width={600}>
          <HS content={destinationContent.title} mb={12} />
          <PL content={destinationContent.description} mb={32} />
          <CreditCardPayoutActions destinationDetails={destinationContent} />
        </Box>
      </Flex>
    </Box>
  );
};
