import { css, styled } from '@m1/liquid-react';

// these styles re defined as standalone css
// instead of a styled element so that they
// can be re-used for sub-grids, such as
// within a <Hero /> component which spans
// the full-width of a <Grid />, but it;s
// child lements will still need to be
// placed on a grid themselves
export const grid = css`
  --columns: 12;
  --gap: 24px;
  --sidebarWidth: minmax(0, 1fr);
  --columnWidth: minmax(0, 78px);
  display: grid;
  grid-template-columns:
    [full-width-start gutter-left-start]
    var(--sidebarWidth)
    [gutter-left-end content-start left-start]
    repeat(calc(var(--columns) / 2), var(--columnWidth))
    [left-end right-start]
    repeat(calc(var(--columns) / 2), var(--columnWidth))
    [right-end content-end gutter-right-start]
    var(--sidebarWidth)
    [gutter-right-end full-width-end];
  grid-column-gap: var(--gap);
`;

// Grid is used as the layout wrapper for all
// of a page's main content, with only the
// top-level site navigation and full-screen
// modals living outside of that content area
export const Grid = styled.main`
  ${grid}
  grid-auto-rows: min-content;
  width: 100%;
  height: 100%;
  max-height: 100dvh;
  margin: 0;
  padding-top: 40px;
`;

// A Stack is a vertical section of content
// usually filling the entire available height
// of the screen, containing one or more Cells
// for each horizontal row of content
export const Stack = styled.section<{ column?: string }>(
  ({ column = `content` }) => css`
    grid-column: ${column};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    height: 100%;
    max-height: inherit;
    padding: 24px 0;
  `,
);

// A Cell represents a horizontal grouping of content.
// The `as` prop should be used to change this from
// rendering an article element to whichever makes
// the most sense for the given piece of content, such
// as a Header (h1, h2, wtc), Paragraph, or List (ul, ol)
export const Cell = styled.article<{ vertical?: boolean }>(
  ({ vertical }) => css`
    display: flex;
    flex-direction: ${vertical ? ` column` : `row`};
    width: 100%;
  `,
);

export const Divider = styled.hr(
  ({ theme }) => css`
    width: 100%;
    margin: 0;
    border: 0;
    border-bottom: 1px solid ${theme.colors.borderMain};
  `,
);
