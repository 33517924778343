import get from 'lodash-es/get';
import * as React from 'react';
import { InjectedFormProps, blur } from 'redux-form';

import { compose, connectForm, UNSAFE_connectRedux } from '~/hocs';
import { OrderDirection } from '~/redux/reducers/newFlows/reducers/setOrderReducer';

import { SliceOrderAmountField } from './fields';
import { minOf, maxOf, greaterThan, required } from './validators';

type Validator = (value: string) => string | null | undefined;

const sliceHasValueValidator = (currentValue: number): Validator => {
  return () => {
    if (currentValue <= 0) {
      return 'You have no money invested in this Slice.';
    }
  };
};

const cryptoAtLeast1DollarValidator = (): Validator => {
  return (value: string) => {
    if (Number(value) < 1) {
      return 'Sell orders must be at least $1.';
    }
  };
};

type Props = InjectedFormProps<any> &
  React.PropsWithChildren<{
    amountFromStore: number;
    totalBuyingPower: Maybe<number>;
    currentValue: number;
    direction: OrderDirection;
    dispatch: (...args: Array<any>) => any;
    isCrypto: boolean;
    isMarginAccount: boolean;
    isOrderBlocked: boolean;
    maxCashThreshold: Maybe<number>;
    showInputError: boolean;
    borrowedAmountText: Maybe<string>;
    ratePercent: Maybe<number>;
  }>;

class PortfolioOrderFormComponent extends React.Component<Props> {
  inputId: string;
  buyValidations: Array<(...args: Array<any>) => any>;
  sellValidations: Array<(...args: Array<any>) => any>;

  constructor(props: Props) {
    super(props);

    this.inputId = 'slice-order-input';

    this.buyValidations = [
      required,
      minOf(1, 'Buy orders must be at least $1.'),
      maxOf(
        props.totalBuyingPower ?? 0,
        this.props.isMarginAccount ? 'Exceeds buying power.' : 'Exceeds cash.',
      ),
    ];

    this.sellValidations = [
      required,
      sliceHasValueValidator(props.currentValue),
    ];

    if (props.isCrypto) {
      this.sellValidations.push(cryptoAtLeast1DollarValidator());
    } else {
      this.sellValidations.push(
        greaterThan(0, 'Sell orders must be greater than $0.'),
      );
    }
  }

  // @ts-expect-error - TS7006 - Parameter 'prevProps' implicitly has an 'any' type.
  componentDidUpdate(prevProps) {
    const { direction, amountFromStore, dispatch, maxCashThreshold } =
      this.props;

    // NOTE: The DOM query and blur dispatch below is a hack for triggering a manual synchronous validation check when a user toggles between a buy and sell order.
    // Redux form does not allow manual validation out of the box. See https://github.com/redux-form/redux-form/issues/3992 and https://github.com/redux-form/redux-form/issues/3466.
    if (
      prevProps.direction !== direction ||
      prevProps.maxCashThreshold !== maxCashThreshold
    ) {
      const inputEl: HTMLElement | null | undefined = document.getElementById(
        this.inputId,
      );
      if (inputEl) {
        inputEl.focus();
      }
      dispatch(
        blur('portfolio-order', 'amount', Number(amountFromStore), false),
      );
    }

    // on sell screen, if auto-invest props has maxCashThreshold has changed, update sellValidations
    if (
      direction === 'sell' &&
      prevProps.maxCashThreshold !== maxCashThreshold
    ) {
      this.sellValidations = [
        required,
        sliceHasValueValidator(this.props.currentValue),
      ];

      if (this.props.isCrypto) {
        this.sellValidations.push(cryptoAtLeast1DollarValidator());
      }
    }
  }

  // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
  validate = (value): string | null | undefined => {
    const { direction } = this.props;
    if (direction === 'buy') {
      return runValidations(this.buyValidations, value);
    }
    return runValidations(this.sellValidations, value);
  };

  render() {
    const { isOrderBlocked, showInputError, children } = this.props;
    return (
      <form
        onSubmit={(e) => {
          // prevent reloading the page on form submission
          e.preventDefault();
          if (!isOrderBlocked) {
            this.props.handleSubmit(e);
          }
        }}
      >
        <SliceOrderAmountField
          autoFocus
          id={this.inputId}
          name="amount"
          showErrorBeforeSubmit={showInputError}
          validate={this.validate}
          subtitle={this.props.borrowedAmountText}
        />
        {children}
      </form>
    );
  }
}

function runValidations(
  validations: Array<(...args: Array<any>) => any>,
  value: string | null | undefined,
): string | null | undefined {
  for (const validator of validations) {
    const result = validator(value);
    if (result) {
      return result;
    }
  }
}

const enhancer = compose<any, any>(
  connectForm({
    form: 'portfolio-order',
  }),
  UNSAFE_connectRedux((state) => ({
    // @ts-expect-error - TS2339 - Property 'form' does not exist on type 'DefaultRootState'.
    amountFromStore: get(state.form['portfolio-order'], 'values.amount', '0'),
  })),
);

export const PortfolioOrderForm = enhancer(PortfolioOrderFormComponent) as any;
