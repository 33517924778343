import { Flex } from '@m1/liquid-react';
import React from 'react';

import { StyledHighlightedPortfolioSliceNameContainer } from '../PortfolioDetailsPage.styled';

import { HighlightedPortfolioSliceName } from './HighlightedPortfolioSliceName';

export const PieCardUpperContent = () => {
  return (
    <Flex alignItems="center" justifyContent="space-between" minHeight="20px">
      <StyledHighlightedPortfolioSliceNameContainer>
        <HighlightedPortfolioSliceName />
      </StyledHighlightedPortfolioSliceNameContainer>
    </Flex>
  );
};
