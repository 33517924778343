/**
 * Calculates the Net Income Attributable value for the specified excess and
 * balances.
 */
export const calculateNiaAmount = (
  excess: number,
  adjustedClosingBalance: number,
  adjustedOpeningBalance: number,
): number => {
  const adjustedBalanceDelta = adjustedClosingBalance - adjustedOpeningBalance;

  const adjustedBalanceRatio = adjustedBalanceDelta / adjustedOpeningBalance;

  return Math.ceil(excess * adjustedBalanceRatio * 100) / 100;
};
