import React from 'react';

import { useParams } from '~/hooks/useParams';

import { SecurityDetailsPage } from './SecurityDetailsPage';
import { SecurityDetailsProvider } from './SecurityDetailsProvider';

type Props = {
  disableActions?: boolean;
  disableBreadcrumbs?: boolean;
  disableWatchlist?: boolean;
  type: 'CRYPTO' | 'EQUITY' | 'FUND';
  isCover?: boolean;
};
export const SecurityDetailsLayout = (props: Props) => {
  const { fundId, equityId, cryptoId } = useParams();
  let id;
  switch (props.type) {
    case 'CRYPTO':
      id = cryptoId;
      break;
    case 'EQUITY':
      id = equityId;
      break;
    case 'FUND':
      id = fundId;
      break;
    default:
      id = undefined;
  }
  return (
    <SecurityDetailsProvider {...props} securityId={id as string}>
      <SecurityDetailsPage isCover={props.isCover} />
    </SecurityDetailsProvider>
  );
};
