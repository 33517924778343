import { Box, SemanticColorNames, Tooltip, styled } from '@m1/liquid-react';
import clamp from 'lodash-es/clamp';
import * as React from 'react';

import { Container, Bar } from './elements';

type ProgressProps = {
  borderRadius?: number;
  borderColor?: SemanticColorNames;
  borderWidth?: number;
  color?: SemanticColorNames;
  curvedProgressIndicator?: boolean;
  backgroundColor?: SemanticColorNames;
  height?: number;
  mx?: number;
  my?: number;
  percent: number;
  style?: Record<string, any>;
  secondaryIndicator?: number;
  secondaryIndicatorLabel?: {
    label: React.ReactNode;
    foregroundColor: SemanticColorNames;
    backgroundColor: SemanticColorNames;
  };
};

const SecondaryIndicator = styled(Box)`
  background-color: transparent;
  border-left-width: 1px;
  border-left-style: solid;
`;

export const Progress = ({
  borderRadius = 0,
  borderColor,
  borderWidth = 0,
  height = 2,
  my = 0,
  mx = 0,
  percent,
  color,
  backgroundColor,
  style = {},
  secondaryIndicator,
  secondaryIndicatorLabel,
  curvedProgressIndicator = true,
}: ProgressProps) => {
  const container = React.useRef<HTMLDivElement>(null);
  return (
    <Container
      height={height}
      mx={mx}
      my={my}
      borderRadius={borderRadius}
      color={backgroundColor}
      style={style}
      borderColor={borderColor}
      borderWidth={borderWidth}
      overflow="hidden"
      ref={container}
    >
      <Bar
        position="relative"
        left={-borderWidth}
        top={-borderWidth}
        $color={color}
        width={`calc(${clamp(percent, 0, 100)}% + ${borderWidth}px)`}
        height={height}
        borderRadius={
          curvedProgressIndicator
            ? borderRadius
            : `${borderRadius}px 0 0 ${borderRadius}px`
        }
        borderColor={borderColor}
        borderWidth={borderWidth}
      />
      {typeof secondaryIndicator === 'number' && (
        <>
          {secondaryIndicatorLabel ? (
            <Tooltip
              body={secondaryIndicatorLabel.label}
              visible
              color={secondaryIndicatorLabel.foregroundColor}
              backgroundColor={secondaryIndicatorLabel.backgroundColor}
              zIndex={1}
              popperOptions={{
                modifiers: [
                  {
                    name: 'flip',
                    options: {
                      fallbackPlacements: [],
                    },
                  },
                  {
                    name: 'preventOverflow',
                    options: {
                      boundary: container.current,
                      padding: -16,
                    },
                  },
                ],
              }}
              triggerBoxProps={{
                position: 'relative',
                // if indicator is 0, give it negative left so it disappears
                left: `${secondaryIndicator <= 0 ? -1 : clamp(secondaryIndicator, 0, 100)}%`,
                top: -borderWidth - height,
              }}
            >
              <SecondaryIndicator height={height} borderColor={borderColor} />
            </Tooltip>
          ) : (
            <Bar
              zIndex={2}
              position="relative"
              left={-borderWidth}
              top={-borderWidth - height}
              $color="transparent"
              width={`${secondaryIndicator}%`}
              height={height}
              borderRadius={`${borderRadius}px 0 0 ${borderRadius}px`}
              borderColor={borderColor}
              borderWidth={borderWidth}
              $borderRightWidth={1}
            />
          )}
        </>
      )}
    </Container>
  );
};
