import { Text } from '@m1/liquid-react';
import * as React from 'react';
import { components } from 'react-select';

import { SelectProps } from './Dropdown.types';

type Props = {
  children?: React.ReactNode;
  className: string;
  selectProps: SelectProps;
};

// FIXME(Wolf): Fix this once you upgrade react-select.
const ReactSelectPlaceholder = components.Placeholder as any;

export const Placeholder = ({ children, ...rest }: Props) => {
  const { isDisabled } = rest.selectProps;
  return (
    <ReactSelectPlaceholder {...rest}>
      <Text
        color={
          isDisabled ? 'foregroundNeutralTertiary' : 'foregroundNeutralMain'
        }
        content={children}
      />
    </ReactSelectPlaceholder>
  );
};
