import classNames from 'classnames';
import * as React from 'react';

import style from './style.module.scss';

type Props = {
  children?: React.ReactNode;
  className?: string;
  // Should be a React Component or html tag
  // EX: div, nav, span, etc
  componentClass?: React.ElementType;
  lg?: number;
  lgBaseline?: boolean;
  lgCenter?: boolean;
  lgEnd?: boolean;
  lgFirst?: boolean;
  lgLast?: boolean;
  lgOffset?: number;
  lgOrder?: boolean;
  lgStart?: boolean;
  lgStretch?: boolean;
  lgTextCenter?: boolean;
  lgTextLeft?: boolean;
  lgTextRight?: boolean;
  md?: number;
  mdBaseline?: boolean;
  mdCenter?: boolean;
  mdEnd?: boolean;
  mdFirst?: boolean;
  mdLast?: boolean;
  mdOffset?: number;
  mdOrder?: boolean;
  mdStart?: boolean;
  mdStretch?: boolean;
  mdTextCenter?: boolean;
  mdTextLeft?: boolean;
  mdTextRight?: boolean;
  // Configures column order to set number (1-12)
  // NOTE: You can use a number up to the amount of columns in the grid
  order?: number;
  sm?: number;
  smBaseline?: boolean;
  smCenter?: boolean;
  smEnd?: boolean;
  smFirst?: boolean;
  smLast?: boolean;
  smOffset?: number;
  smOrder?: boolean;
  smStart?: boolean;
  smStretch?: boolean;
  smTextCenter?: boolean;
  smTextLeft?: boolean;
  smTextRight?: boolean;
  // The number of columns you wish to span
  xs?: number;
  // Configures column to align-self: baseline
  xsBaseline?: boolean;
  // Configures column to align-self: center
  xsCenter?: boolean;
  // Configures column to align-self: end
  xsEnd?: boolean;
  // Re-orders column to be first
  xsFirst?: boolean;
  // Re-orders column to be last
  xsLast?: boolean;
  // Move columns to the right
  xsOffset?: number;
  // ?
  xsOrder?: boolean;
  // Configures column to align-self: start
  xsStart?: boolean;
  // Configures column to align-self: stretch
  xsStretch?: boolean;
  // Columns have text aligned center
  xsTextCenter?: boolean;
  // Columns have text aligned left
  xsTextLeft?: boolean;
  // Columns have text aligned right
  xsTextRight?: boolean;
};

export class Col extends React.Component<Props> {
  static defaultProps = {
    componentClass: 'div',
  };

  render() {
    const ComponentClass = this.props.componentClass ?? 'div';
    const classes = {
      [style.root]: true,
    };
    const sizes = {
      lg: 'lg',
      md: 'md',
      sm: 'sm',
      xs: 'xs',
    };

    Object.keys(sizes).forEach((key) => {
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ lg: string; md: string; sm: string; xs: string; }'.
      const size = sizes[key];
      let prop = size;
      let classPart = size;

      // Ex: <Col xs={4}></Col>
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop]) {
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
        classes[style[classPart + this.props[prop]]] = true;
      }

      // Ex: <Col xsOffset={4}></Col>
      prop = size + 'Offset';
      classPart = size + 'Offset';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop] >= 0) {
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
        classes[style[classPart + this.props[prop]]] = true;
      }

      // Ex: <Col xsFirst></Col>
      prop = size + 'First';
      classPart = size + 'First';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop]) {
        classes[style[classPart]] = true;
      }

      // Ex: <Col xsLast></Col>
      prop = size + 'Last';
      classPart = size + 'Last';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop]) {
        classes[style[classPart]] = true;
      }

      // Ex: <Col xsOrder={1}></Col>
      prop = size + 'Order';
      classPart = size + 'Order';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop] >= 0) {
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
        classes[style[classPart + this.props[prop]]] = true;
      }

      // Ex: <Col xsStart></Col>
      prop = size + 'Start';
      classPart = size + 'Start';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop]) {
        classes[style[classPart]] = true;
      }

      // Ex: <Col xsCenter></Col>
      prop = size + 'Center';
      classPart = size + 'Center';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop]) {
        classes[style[classPart]] = true;
      }

      // Ex: <Col xsEnd></Col>
      prop = size + 'End';
      classPart = size + 'End';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop]) {
        classes[style[classPart]] = true;
      }

      // Ex: <Col xsBaseline></Col>
      prop = size + 'Baseline';
      classPart = size + 'Baseline';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop]) {
        classes[style[classPart]] = true;
      }

      // Ex: <Col xsStretch></Col>
      prop = size + 'Stretch';
      classPart = size + 'Stretch';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop]) {
        classes[style[classPart]] = true;
      }

      // Ex: <Col xsTextLeft></Col>
      prop = size + 'TextLeft';
      classPart = size + 'TextLeft';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop]) {
        classes[style[classPart]] = true;
      }

      // Ex: <Col xsTextCenter></Col>
      prop = size + 'TextCenter';
      classPart = size + 'TextCenter';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop]) {
        classes[style[classPart]] = true;
      }

      // Ex: <Col xsTextRight></Col>
      prop = size + 'TextRight';
      classPart = size + 'TextRight';
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
      if (this.props[prop]) {
        classes[style[classPart]] = true;
      }
    }, Col);

    // Extract all known props

    /* eslint-disable unused-imports/no-unused-imports,unused-imports/no-unused-vars */
    const {
      xs,
      sm,
      md,
      lg,
      xsOffset,
      smOffset,
      mdOffset,
      lgOffset,
      xsFirst,
      smFirst,
      mdFirst,
      lgFirst,
      xsLast,
      smLast,
      mdLast,
      lgLast,
      xsStart,
      smStart,
      mdStart,
      lgStart,
      xsCenter,
      smCenter,
      mdCenter,
      lgCenter,
      xsEnd,
      smEnd,
      mdEnd,
      lgEnd,
      xsBaseline,
      smBaseline,
      mdBaseline,
      lgBaseline,
      xsStretch,
      smStretch,
      mdStretch,
      lgStretch,
      xsOrder,
      smOrder,
      mdOrder,
      lgOrder,
      xsTextLeft,
      smTextLeft,
      mdTextLeft,
      lgTextLeft,
      xsTextCenter,
      smTextCenter,
      mdTextCenter,
      lgTextCenter,
      xsTextRight,
      smTextRight,
      mdTextRight,
      lgTextRight,
      componentClass,
      ...others
    } = this.props;

    return (
      <ComponentClass
        {...others}
        ref="column"
        className={classNames(this.props.className, classes)}
      >
        {this.props.children}
      </ComponentClass>
    );
  }
}
