import { Box, Button, Flex, HXXS, LL, PL, PXL, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { DottedArrow } from '~/components/DottedArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { useAcatWizardConfirmTransferStepQuery } from '~/graphql/hooks';
import { AppImage } from '~/lens-toolbox/AppImage';
import { RichText } from '~/lens-toolbox/RichText/RichText';
import { Divider } from '~/toolbox/divider';

import { AcatWizardBackButton } from '../components/AcatWizardBackButton';
import { AcatWizardLoadingPlaceholder } from '../components/AcatWizardLoadingPlaceholder';
import { AcatWizardStepCard } from '../components/AcatWizardStepCard';
import { AcatWizardStepContainer } from '../components/AcatWizardStepContainer';
import {
  toMutationInput,
  useAcatWizardFormContext,
} from '../hooks/useAcatWizardFormContext';

const StyledTransferDetails = styled(Flex)`
  flex-direction: column;
  gap: 32px;
  width: 100%;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.XSMALL}) {
    flex-direction: row;
  }
`;

const StyledArrow = styled(Flex)`
  display: none;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.XSMALL}) {
    display: flex;
  }
`;

export const AcatWizardConfirmTransferStep = () => {
  const { watch, goTo } = useAcatWizardFormContext();
  const fields = watch();

  const { data, loading } = useAcatWizardConfirmTransferStepQuery({
    variables: {
      acatWizardId: fields.acatWizardId,
      input: toMutationInput(fields),
    },
  });

  const confirmSelectionsScreenContent =
    data?.acatWizard && data.acatWizard.__typename === 'InvestAcatWizardContent'
      ? data.acatWizard.confirmSelectionsScreenContent
      : null;
  const transferInformationScreenContent =
    data?.acatWizard && data.acatWizard.__typename === 'InvestAcatWizardContent'
      ? data?.acatWizard.transferInformationScreenContent
      : null;

  if (!data && loading) {
    // TODO: Replace this with a skeleton loader
    return <AcatWizardLoadingPlaceholder />;
  } else if (
    !confirmSelectionsScreenContent ||
    !transferInformationScreenContent
  ) {
    return <GenericSystemError />;
  }

  const { ctaLabel, destinationAccount, externalBrokerage, header } =
    confirmSelectionsScreenContent;

  return (
    <AcatWizardStepContainer>
      <AcatWizardBackButton />
      <AcatWizardStepCard header={header}>
        {externalBrokerage && destinationAccount && (
          <>
            <StyledTransferDetails>
              <Flex
                flexDirection="column"
                flex="1 1 35%"
                gap={4}
                justifyContent="center"
              >
                <Flex alignItems="center" gap={8}>
                  <Icon
                    name="accountBankPrimary32"
                    css={{ height: '16px', width: '16px' }}
                    p={6}
                    paddingTop="6px !important"
                  />
                  <Flex flexDirection="column">
                    <PL
                      fontWeight={600}
                      content={externalBrokerage.name}
                      mr={4}
                    />
                    <LL content={fields.originAccountNumber} />
                  </Flex>
                </Flex>
              </Flex>
              <StyledArrow alignItems="center">
                <DottedArrow direction="RIGHT" />
              </StyledArrow>
              <Flex
                flexDirection="column"
                flex="1 1 65%"
                gap={4}
                justifyContent="center"
              >
                <Flex alignItems="center" gap={8}>
                  <Icon
                    name="accountInvestPrimary32"
                    css={{ height: '16px', width: '16px' }}
                    p={6}
                    paddingTop="6px !important"
                  />
                  <Flex flexDirection="column">
                    <PL fontWeight={600} content={destinationAccount.name} />
                    <LL
                      content={
                        destinationAccount.accountTypeWithNumberDescriptor
                      }
                    />
                  </Flex>
                </Flex>
              </Flex>
            </StyledTransferDetails>
            <Divider spacing="relaxed" />
          </>
        )}
        {confirmSelectionsScreenContent.cash && (
          <>
            <Flex justifyContent="space-between" gap={16} mb={24}>
              <RichText richText={confirmSelectionsScreenContent.cash.header} />
              <PXL color="foregroundNeutralSecondary" fontWeight={400}>
                {confirmSelectionsScreenContent.cash.amount}
              </PXL>
            </Flex>
            <Box mb={24}>
              <Divider spacing="none" />
            </Box>
          </>
        )}
        {confirmSelectionsScreenContent.assets && (
          <>
            <Flex justifyContent="space-between" flexDirection="column">
              <RichText
                richText={confirmSelectionsScreenContent.assets.header}
              />
              <StyledSectionEntry
                onClick={() => goTo('acat-wizard-confirmed-assets')}
              >
                <PXL color="foregroundNeutralSecondary" fontWeight={400}>
                  {confirmSelectionsScreenContent.assets.assetsListLinkLabel}
                </PXL>
                <Icon name="caretRight24" />
              </StyledSectionEntry>
            </Flex>
            <Box mb={24}>
              <Divider spacing="none" />
            </Box>
          </>
        )}
        <Flex flexDirection="column">
          <HXXS fontWeight={600} mb={32}>
            {confirmSelectionsScreenContent.transferDetails?.header}
          </HXXS>
          {confirmSelectionsScreenContent.transferDetails?.details?.map(
            (detail, idx) => (
              <Flex mb={32} key={idx}>
                {detail.icon && (
                  <Box minWidth={32} mr={16}>
                    <AppImage appImage={detail.icon} />
                  </Box>
                )}
                <RichText
                  richText={detail.body}
                  containerProps={{ display: 'inline' }}
                />
              </Flex>
            ),
          )}
        </Flex>
        <Box mt={32}>
          <Button autoFocus label={ctaLabel} size="large" type="submit" />
        </Box>
      </AcatWizardStepCard>
    </AcatWizardStepContainer>
  );
};

const StyledSectionEntry = styled.button`
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  padding: 24px;

  &:hover {
    transition: background-color 200ms;
    background-color: ${(props) =>
      props.theme.colors.backgroundNeutralTertiary};
  }
`;
