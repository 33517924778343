import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { FinancialInformationForm } from '~/forms';
import { useSelector } from '~/redux/hooks';

type FinancialInformationProps = {
  onFinishStep: (income: number) => void;
};

export const FinancialInformation = ({
  onFinishStep,
}: FinancialInformationProps) => {
  const { step, totalAnnualIncome, housingType, monthlyPayments } = useSelector(
    (state) => state.newFlows.CREDIT_CARD_APPLICATION,
  );

  const isEditing = step === 'edit';
  const initialValues = {
    totalAnnualIncome,
    housingType,
    monthlyPayments,
  };

  // @ts-expect-error - TS7006 - Parameter 'financialInformation' implicitly has an 'any' type.
  const submitIncome = (financialInformation) => {
    onFinishStep(financialInformation);
  };

  return (
    <PageErrorBoundary isRetryAllowed>
      <FinancialInformationForm
        onSubmit={submitIncome}
        onFinishStep={onFinishStep}
        isEditing={isEditing}
        initialValues={initialValues}
      />
    </PageErrorBoundary>
  );
};
