import { makeFlowComponent } from '~/flows/components/utils';
import { beginResolveCallFlow } from '~/redux/actions/newFlows/resolveMarginCall';

import { ResolveMarginCallFlowContainer } from './ResolveMarginCallFlowContainer';

export const ResolveMarginCallFlow = makeFlowComponent({
  begin: beginResolveCallFlow,
  name: 'RESOLVE_CALL',
  Component: ResolveMarginCallFlowContainer,
});
