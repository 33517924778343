import { Flex, styled } from '@m1/liquid-react';

import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { BorrowMarginCallDialog } from '~/components/borrow';
import { useInvestPageQuery } from '~/graphql/hooks';
import { useLayout } from '~/hooks/useLayout';
import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';
import { StyledPageContent } from '~/pages/dashboard/navigation/Navigation.styled';
import { useDispatch, useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

const PortfolioPageBackground = styled(Flex)`
  background-image: ${({ theme }) =>
    `linear-gradient(${theme.colors.backgroundFeatureFlat}, ${theme.colors.backgroundFeatureFlat}), linear-gradient(${theme.colors.backgroundNeutralMain}, ${theme.colors.backgroundNeutralMain})`};
  background-size:
    100% 200px,
    100% 100%;
  background-repeat: no-repeat;
  width: 100%;
`;

const InvestPageContent = styled(StyledPageContent)`
  padding-top: 0;
`;

export const InvestPage = () => {
  const dispatch = useDispatch();
  const { contentWidth } = useLayout();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const activeAccountId = useSelector((state) => state.global.activeAccountId);

  const { data, loading } = useInvestPageQuery({
    variables: {
      activeAccountId: activeAccountId as string,
    },
    skip: !activeAccountId,
  });

  const inPortfolioEditor =
    pathname.includes('portfolio-editor') ||
    pathname.includes('portfolio-organizer');

  const onInvestMarketing = pathname.includes('invest-marketing');

  React.useEffect(() => {
    if (!activeAccountId && !inPortfolioEditor && !onInvestMarketing) {
      navigate({
        to: '/d/invest/invest-marketing',
        options: { replace: true },
      });
      dispatch({
        type: 'SET_INVEST_MARKETING_SESSION',
      });
    }
  }, [dispatch, activeAccountId, navigate]);

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.account) {
    return (
      <>
        <Outlet />
        <BorrowMarginCallDialog previousRouteName="/d/invest/portfolio" />
      </>
    );
  }

  if (inPortfolioEditor) {
    return (
      <PortfolioPageBackground>
        <StyledPageContent contentWidth={contentWidth}>
          <Outlet />
          <BorrowMarginCallDialog previousRouteName="/d/invest/portfolio" />
        </StyledPageContent>
      </PortfolioPageBackground>
    );
  }

  return (
    <InvestPageContent contentWidth={contentWidth}>
      <Outlet />
      <BorrowMarginCallDialog previousRouteName="/d/invest/portfolio" />
    </InvestPageContent>
  );
};
