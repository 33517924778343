import { LS } from '@m1/liquid-react';
import sample from 'lodash-es/sample';
import moment from 'moment-timezone';
import * as React from 'react';

import { MarketIndexProxyFragment } from '~/graphql/types';

export const IndexesLastUpdated = ({
  indexProxies,
}: {
  indexProxies: MarketIndexProxyFragment[];
}) => {
  const intradayTimeSeries = sample(indexProxies)?.proxy.intradayQuotes;
  if (!intradayTimeSeries) {
    return null;
  }
  const time = moment(intradayTimeSeries.lastUpdatedTime);
  const content = time.isValid()
    ? `Last updated ${time.tz('America/New_York').fromNow()}`
    : 'Data updates every 10 mins';

  return <LS color="foregroundNeutralSecondary" content={content} />;
};
