import { AuthenticateErrorEnum } from '~/graphql/types';
import { ACTION_TYPES as ACTIONS } from '~/redux/actions';

import { FlowState } from '../newFlowsReducer.types';

export type LoginFlowState = FlowState<
  string,
  {
    attemptInProgress: boolean;
    error: AuthenticateErrorEnum | null | undefined;
    username?: string;
  }
>;

export const loginFlowInitialState: LoginFlowState = {
  step: '',
  stepTitle: '',
  error: null,
  attemptInProgress: false,
};

export function login(
  state: LoginFlowState = loginFlowInitialState,
  action: any,
): LoginFlowState {
  switch (action.type) {
    case ACTIONS.BEGIN_LOGIN_FLOW:
      return loginFlowInitialState;
    case ACTIONS.CHANGE_LOGIN_FLOW_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case ACTIONS.SUBMIT_LOGIN_FORM:
    case ACTIONS.SUBMIT_2FA_CODE_FORM:
    case ACTIONS.SUBMIT_AMFA_CODE_FORM:
      return {
        ...state,
        ...action.payload,
        attemptInProgress: true,
      };
    case ACTIONS.LOGIN_ATTEMPT_FAILED:
      return {
        ...state,
        attemptInProgress: false,
        error: action.payload,
      };
    case ACTIONS.FINISHED_LOGIN_FLOW:
      return {
        ...state,
        error: null,
        attemptInProgress: false,
      };
    default:
      return state;
  }
}
