import { Box } from '@m1/liquid-react';
import React from 'react';

import { useEarnPromoCardQuery } from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { AppCard } from '~/lens-toolbox/AppCard/AppCard';
import { Carousel } from '~/toolbox/carousel';

export const EarnReferralPromoCards = () => {
  const analytics = useAnalytics();
  const { data } = useEarnPromoCardQuery();

  if (!data) {
    return null;
  }

  const node = data?.viewer?.save?.savings?.savingsAccounts?.edges?.[0]?.node;
  const promotions = node?.earnProductLandingPage?.promotionCards;

  return (
    <>
      {promotions && promotions.length > 0 && (
        <Box>
          <Carousel
            onItemChange={(selected) => {
              const event = promotions?.[selected]?.displayAnalyticsEvent;

              if (event) {
                analytics?.recordAppAnalyticsEvent(event);
              }
            }}
            items={promotions.map((appCard) => (
              <AppCard
                key={node?.id}
                appCard={appCard}
                mx={1}
                justifyContent="space-between"
                height="100%"
                allowDisplayAnalytics={false}
                hasSiblingCards={promotions.length > 1}
              />
            ))}
            fullSize
            scrollSnap
            indicator="numbers"
            fixedProgressIndicator={false}
          />
        </Box>
      )}
    </>
  );
};
