import {
  Box,
  Button,
  Flex,
  HXS,
  PM,
  PXL,
  styled,
  Card,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { FulfillmentConditionInlineDescriptionTags } from '~/components/FulfillmentConditionInlineDescriptionTags';
import { SmartTransfersEndpointNavigationWarningModal } from '~/components/modals';
import { TransferParticipantCell } from '~/components/transfers/TransferParticipantCell';
import { TransferParticipantCellRenderer } from '~/components/transfers/TransferParticipantCellRenderer';

import { FulfillmentConditionInputs } from '~/flows/components/create-smart-transfer/FulfillmentConditionInputs.apollo';
import { SmartTransferFulfillmentConditionInput } from '~/redux/actions/smartTransfer/smartTransferActions.types';

import type { LocalContraParticipant } from './types';

type FulfillmentConditionRefetchContainerEditComponentProps = {
  newSmartTransferContraParticipantEntryOption: any;
  onFinishAddContraEntry: (arg0: LocalContraParticipant) => void;
  refetch: any;
  smartTransferRuleId: string;
};

const StyledLeftColumnBox = styled(Box)`
  background-color: ${(props) => props.theme.colors.backgroundNeutralSecondary};
`;

const StyledEmptyStateImage = styled(Illustration)`
  // allow the asset to be flush with the bottom right corner of the parent
  bottom: 0;
  position: absolute;
  right: 0;
  width: 40vw;
`;

const StyledSelectedAccount = styled(Flex)`
  border: 1px solid ${(props) => props.theme.colors.foregroundNeutralTertiary};
  border-radius: 8px;
  padding: 16px 32px;
`;

export const FulfillmentConditionRefetchContainerAddContra = ({
  newSmartTransferContraParticipantEntryOption,
  onFinishAddContraEntry,
  refetch,
  smartTransferRuleId,
}: FulfillmentConditionRefetchContainerEditComponentProps) => {
  const [contraParticipantId, setContraParticipantId] = React.useState(null);
  const [contraEntry, setContraEntry] = React.useState<LocalContraParticipant>({
    __editRequirementsId: null,
    contraParticipantId: null,
    fulfillmentCondition: null,
    type: 'CONTRA_PARTICIPANT',
  });
  const [isWarningModalOpen, setIsWarningModalOpen] = React.useState(false);
  const [
    isInitialFulfillmentOptionSelected,
    setIsInitialFulfillmentOptionSelected,
  ] = React.useState(false);
  const editRequirements =
    newSmartTransferContraParticipantEntryOption.editRequirements;

  // when a contraParticipant is selected, add its id to state
  React.useEffect(() => {
    const existingContraId = contraEntry.contraParticipantId;
    if (contraParticipantId !== existingContraId) {
      setContraEntry((contraEntry) => ({
        ...contraEntry,
        contraParticipantId,
      }));
    }
  }, [contraParticipantId, contraEntry.contraParticipantId, setContraEntry]);
  // when an editRequirements id is read from Lens, add it to state
  React.useEffect(() => {
    const existingEditRequirementsId = contraEntry.__editRequirementsId;
    if (
      editRequirements &&
      editRequirements.id &&
      editRequirements.id !== existingEditRequirementsId
    ) {
      setContraEntry((contraEntry) => ({
        ...contraEntry,
        __editRequirementsId: editRequirements.id,
      }));
    }
  }, [contraEntry.__editRequirementsId, editRequirements]);

  const onFulfillmentDataChange = React.useCallback(
    (fulfillmentCondition: SmartTransferFulfillmentConditionInput) => {
      setContraEntry((contraEntry) => {
        return {
          ...contraEntry,
          fulfillmentCondition,
        };
      });
    },
    [],
  );

  if (editRequirements && !editRequirements.fulfillmentConditionOptionSet) {
    throw new Error(
      "Unable to read 'fulfillmentConditionOptionSet' on SmartTransferContraParticipantEntryEditRequirements",
    );
  }
  const fulfillmentConditionOptionSet =
    editRequirements && editRequirements.fulfillmentConditionOptionSet;
  const contraParticipants = React.useMemo(
    () =>
      (newSmartTransferContraParticipantEntryOption.contraParticipants &&
        newSmartTransferContraParticipantEntryOption.contraParticipants.list) ||
      [],
    [newSmartTransferContraParticipantEntryOption.contraParticipants],
  );

  React.useEffect(() => {
    if (contraParticipantId) {
      refetch({
        contraParticipantId,
        newSmartTransferContraParticipantEntryOptionId:
          newSmartTransferContraParticipantEntryOption.id,
      });
    }

    if (contraParticipants.length === 1) {
      setContraParticipantId(contraParticipants[0].id);
    }
  }, [
    contraParticipantId,
    contraParticipants,
    newSmartTransferContraParticipantEntryOption,
    refetch,
  ]);
  return (
    <>
      <StyledLeftColumnBox height="100vh" py={100} width="50%">
        <Box maxWidth={550} ml="auto" mr={64}>
          <BackArrow
            content="Edit Smart Transfer"
            mb={16}
            params={{
              smartTransferRuleId,
            }}
            to="/d/c/edit-smart-transfer/:smartTransferRuleId"
          />
          <HXS content={(editRequirements && editRequirements.title) || ''} />
          <>
            <Card
              isElevated
              backgroundColor="backgroundNeutralMain"
              mt={64}
              minHeight={230}
              p="12px 32px"
            >
              <PXL
                fontWeight={600}
                content={
                  (editRequirements &&
                    editRequirements.contraParticipantLabel) ||
                  ''
                }
                mb={16}
                mt={24}
              />
              <ContraParticipantsSection
                contraEntry={contraEntry}
                contraParticipants={contraParticipants}
                contraParticipantId={contraParticipantId}
                // @ts-expect-error - TS2322 - Type 'Dispatch<SetStateAction<null>>' is not assignable to type '(arg0: string) => void'.
                setContraParticipantId={setContraParticipantId}
                setIsWarningModalOpen={setIsWarningModalOpen}
              />
            </Card>
            {editRequirements && !isInitialFulfillmentOptionSelected && (
              <Card
                borderColor="transparent"
                backgroundColor="backgroundPrimarySubtle"
                mt={32}
                p="28px 32px"
                position="relative"
              >
                <PXL
                  fontWeight={600}
                  content={
                    editRequirements.fulfillmentConditionOptionEmptyTitle
                  }
                />
                <Box maxWidth={300}>
                  <PM
                    content={
                      editRequirements.fulfillmentConditionOptionEmptyDescription
                    }
                    pt={4}
                  />
                </Box>
                <Illustration
                  // @ts-expect-error - TS2322 - Type '{ alt: string; name: "smartTransfer"; position: string; right: number; bottom: number; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'.
                  alt=""
                  name="smartTransfer"
                  position="absolute"
                  right={-1}
                  bottom={-1}
                />
              </Card>
            )}
          </>
        </Box>
      </StyledLeftColumnBox>
      <Box width="50%">
        {fulfillmentConditionOptionSet && contraParticipantId ? (
          <FulfillmentConditionInputs
            fulfillmentConditionOptionSet={fulfillmentConditionOptionSet}
            isEditMode={false}
            onFinishStep={() => onFinishAddContraEntry(contraEntry)}
            onFulfillmentDataChange={onFulfillmentDataChange}
            onInitialOptionSelect={() =>
              setIsInitialFulfillmentOptionSelected(true)
            }
            preamble={
              editRequirements &&
              editRequirements.fulfillmentConditionOptionSetListingPreamble
            }
          />
        ) : (
          // @ts-expect-error - TS2769 - No overload matches this call.
          <StyledEmptyStateImage alt="" name="setSource" />
        )}
      </Box>
      <SmartTransfersEndpointNavigationWarningModal
        isWarningModalOpen={isWarningModalOpen}
        setIsWarningModalOpen={setIsWarningModalOpen}
        onConfirmation={() => {
          setContraParticipantId(null);
        }}
      />
    </>
  );
};

type ContraParticipantsSectionProps = {
  // TODO: simplify this prop set
  contraEntry: LocalContraParticipant;
  contraParticipantId: string | null | undefined;
  contraParticipants: any;
  setContraParticipantId: (arg0: string) => void;
  setIsWarningModalOpen: (arg0: boolean) => void;
};

const ContraParticipantsSection = ({
  contraEntry,
  contraParticipantId,
  contraParticipants,
  setContraParticipantId,
  setIsWarningModalOpen,
}: ContraParticipantsSectionProps) => {
  // if no contraParticipants are found, enter error state.
  // if a contraParticipant has been selected, render it.
  // otherwise, render all contraParticipant options.
  if (contraParticipants.length === 0) {
    throw new Error('No contraParticipants found');
  }

  if (contraParticipantId) {
    return (
      <>
        <StyledSelectedAccount
          alignItems="center"
          justifyContent="space-between"
        >
          <TransferParticipantCellRenderer
            includeDescription
            id={contraParticipantId}
          />
          {contraParticipants.length > 1 && (
            <Button
              kind="link"
              label="Change"
              style={{ fontSize: '16px' }}
              onClick={() => setIsWarningModalOpen(true)}
            />
          )}
        </StyledSelectedAccount>
        {contraEntry.fulfillmentCondition && (
          <FulfillmentConditionInlineDescriptionTags
            {...contraEntry.fulfillmentCondition}
            mb={48}
            mt={16}
          />
        )}
      </>
    );
  }
  return (
    <>
      {contraParticipants.map((contraParticipant: any) => (
        <Card
          key={contraParticipant.id}
          onClick={() => {
            setContraParticipantId(contraParticipant.id);
          }}
          my={16}
          p="12px 16px"
        >
          <Flex alignItems="center" justifyContent="space-between">
            <TransferParticipantCell
              includeDescription
              transferParticipant={contraParticipant}
            />
            <Icon name="caretRight16" />
          </Flex>
        </Card>
      ))}
    </>
  );
};
