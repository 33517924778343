import { Flex, HXS, PM } from '@m1/liquid-react';
import * as React from 'react';

import { ExternalLink } from '~/components/ExternalLink';
import { InvestFundingFragment } from '~/graphql/types';
import { Legend } from '~/toolbox/legend';

import { BrokerageTotals } from './BrokerageTotals';
import { EmptyFundingHistory } from './EmptyFundingHistory';
import { IraTotals } from './IraTotals';

export type AccountFundingHistorySectionProps = {
  account: InvestFundingFragment;
};

export const AccountFundingHistorySection = ({
  account,
}: AccountFundingHistorySectionProps) => {
  const { isRetirement, fundingTotals } = account;
  if (!fundingTotals) {
    return <EmptyFundingHistory />;
  }

  return (
    <>
      <Flex
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        mb={16}
      >
        <HXS content="Funding history" />
        {!isRetirement && (
          <Legend>
            <Legend.Item color="primary" label="Deposits" />
            <Legend.Item
              color="foregroundNeutralTertiary"
              label="Withdrawals"
            />
          </Legend>
        )}
      </Flex>
      {isRetirement ? (
        <IraTotals account={account} />
      ) : (
        <BrokerageTotals account={account} />
      )}
      <div>
        <PM
          display="inline"
          color="foregroundNeutralSecondary"
          content="Some types of transfers may not be reflected here."
          pl={48}
        />
        &nbsp;
        <ExternalLink
          destination="SUPPORT_FUNDING_HISTORY"
          label="Learn more."
          font="PM"
        />
      </div>
    </>
  );
};
