import { Flex, css, styled } from '@m1/liquid-react';

type PeriodProps = {
  $isActive: boolean;
  onClick: () => void;
};

export const Period = styled(Flex)<PeriodProps>(
  ({ $isActive, theme }) => css`
    font-size: 12px;
    height: 30px;
    line-height: normal;
    position: relative;
    width: 37px;
    padding: 0 28px;

    :first-child,
    :last-child {
      width: 30px;
    }

    &::before {
      border-radius: 50%;
      content: '';
      position: absolute;
    }

    ${$isActive
      ? css`
          color: ${theme.colors.foregroundNeutralMain};

          &::before {
            background-color: ${theme.colors.backgroundNeutralTertiary};
            height: 38px;
            left: 9px;
            pointer-events: none;
            top: -4px;
            width: 38px;
          }

          :first-child,
          :last-child {
            &::before {
              left: 9px;
            }
          }
        `
      : css`
          color: ${theme.colors.foregroundNeutralSecondary};

          &:hover {
            cursor: pointer;

            &::before {
              background-color: ${theme.colors.foregroundNeutralTertiary};
              height: 30px;
              width: 30px;
            }
          }
        `};
  `,
);
