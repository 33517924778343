import { Box, styled } from '@m1/liquid-react';

import { Table } from '~/toolbox/table';

export const StyledTitleBar = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
`;

export const StyledTitle = styled(Box)`
  display: flex;
  align-items: baseline;
`;

export const StyledResultTable = styled(Table)`
  table-layout: fixed;

  thead::after {
    z-index: -1;
  }

  th {
    position: relative;
    padding-bottom: 0 !important;
  }
`;

export const StyledPerformanceCell = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    padding-left: 8px;
  }
`;

export const StyledPaginationButtonContainer = styled(Box)`
  padding-top: 16px;
  textalign: 'right';
`;

export const StyledPerformanceHeaderCell = styled(Box)`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    padding-left: 8px;

    &:first-child {
      padding-left: 0;
    }
  }
`;
