import {
  // @ts-expect-error - TS2305 - Module '"~/graphql/types"' has no exported member 'IntradayQuotePeriodEnumValues'.
  IntradayQuotePeriodEnumValues,
  // @ts-expect-error - TS2305 - Module '"~/graphql/types"' has no exported member 'HistoricalQuotePeriodEnumValues'.
  HistoricalQuotePeriodEnumValues,
} from '~/graphql/types';
import { AppAction } from '~/redux/actions';

export type SecurityDetailsState = {
  dateRange:
    | {
        first: string | null | undefined;
        last: string | null | undefined;
      }
    | null
    | undefined;
  period: IntradayQuotePeriodEnumValues | HistoricalQuotePeriodEnumValues;
  snapshot: {
    date?: string | null | undefined;
    percentChange: (number | null | undefined) | string;
    shareVolume: number | null | undefined;
    value: (number | null | undefined) | string;
    valueChange: number | null | undefined;
  };
};

const initialState = {
  dateRange: null,
  period: 'LATEST_DAY',
  snapshot: {
    date: null,
    percentChange: null,
    shareVolume: null,
    value: null,
    valueChange: null,
  },
};

export const securityDetailsReducer = (
  state: SecurityDetailsState = initialState,
  action: AppAction,
): SecurityDetailsState => {
  switch (action.type) {
    case 'SET_SECURITY_SNAPSHOT':
      return {
        ...state,
        // @ts-expect-error - TS2322 - Type '{ date?: string | undefined; percentChange: string | number; shareVolume?: number | null | undefined; value?: string | number | undefined; valueChange: number; }' is not assignable to type '{ date?: string | null | undefined; percentChange: string | number | null | undefined; shareVolume: number | null | undefined; value: string | number | null | undefined; valueChange: number | ... 1 more ... | undefined; }'.
        snapshot: action.payload,
      };
    case 'CLEAR_SECURITY_SNAPSHOT':
      return {
        ...state,
        snapshot: {
          ...initialState.snapshot,
        },
      };
    case 'SET_SECURITY_QUOTE_PERIOD':
      return {
        ...state,
        period: action.payload,
      };
    case 'SET_SECURITY_DATE_RANGE':
      return {
        ...state,
        dateRange: action.payload,
      };
    case 'CLEAR_SECURITY_DATE_RANGE':
      return {
        ...state,
        dateRange: null,
      };
    default:
      return state;
  }
};
