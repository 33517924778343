import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';

import { readLandingUrl } from '~/redux/selectors';

import { getLoggers } from '../../common';

export function* logMarketingDeepLinkClickSaga(): SagaIterator {
  const { analytics } = yield call(getLoggers);
  const landingUrl = yield select(readLandingUrl);

  if (typeof landingUrl === 'string') {
    if (landingUrl?.includes('/d/open-invest-account') as boolean) {
      analytics.recordAppAnalyticsEvent({
        name: 'm1_onboarding_deeplink_clicked',
        valueParameter: null,
        customParameters: [
          {
            name: 'product',
            value: 'invest',
          },
        ],
      });
    }

    if (landingUrl?.includes('savings-onboarding') as boolean) {
      analytics.recordAppAnalyticsEvent({
        name: 'm1_onboarding_deeplink_clicked',
        valueParameter: null,
        customParameters: [
          {
            name: 'product',
            value: 'savings',
          },
        ],
      });
    }
  }
}
