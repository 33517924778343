import { Box } from '@m1/liquid-react';
import * as React from 'react';
import { Outlet, useNavigationType, useRouteError } from 'react-router-dom';

import { GlobalLoadingSpinner } from '~/components/GlobalLoadingSpinner';
import * as modals from '~/components/modals';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';
import { useOnMount } from '~/hooks/useOnMount';
import { useSentry } from '~/hooks/useSentry';
import { locationChange, setNavigate } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { ToastContainer } from '~/toasts/ToastContainer';

export const AppLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const analytics = useAnalytics();
  const sentry = useSentry();
  const error = useRouteError() as Error;
  const navigationType = useNavigationType();

  useOnMount(() => {
    // Not a great solution, but setting the navigate function in Redux ended up
    // being a much easier way to get access to navigate in sagas, rather than trying
    // to implement our own functions for manipulating the history.
    dispatch(setNavigate(navigate));
  });

  React.useEffect(() => {
    if (error) {
      sentry.exception(error);
    }
  }, [sentry, error]);

  React.useLayoutEffect(() => {
    // Update redux so that the store stays synced with the router.
    dispatch(
      locationChange({
        location: location,
        action: navigationType,
      }),
    );
  }, [location, dispatch, analytics, navigationType]);

  return (
    <Box
      backgroundColor="backgroundNeutralMain"
      color="foregroundNeutralMain"
      minHeight="100%"
    >
      <ToastContainer />
      <Outlet />
      <GlobalLoadingSpinner />
      {Object.entries(modals).map(([name, Modal]) => (
        <Modal key={name} />
      ))}
    </Box>
  );
};
