import { readPieTreeByPath } from './readPieTreeByPath';
import { Pie } from './types';

/**
 * A Helper utility which takes a list of sliceable ids to be added to a given pieTree
 * and a path to the current pie in the tree and returns true if adding any of the slices
 * would make the pieTree into a circular structure
 * @param sliceableIds - The ids of sliceables we want to add to the tree
 * @param pieTree - The pie we want to add slices to
 * @param path - A path of pie ids leading from the root of the pie tree to the current pie we're operating on,
 * note the path should not include the root pie id itself
 * @returns - returns true if adding any of the ids in the list to the current pieTree would cause a circular structure.
 */
export const hasCircularReference = (
  sliceableIds: Array<string>,
  pieTree: Pie,
  path: Array<string>,
): boolean => {
  const currentPie = readPieTreeByPath(pieTree, path);

  return sliceableIds.reduce((prev, id) => {
    let hasCircularReference = prev;

    const idInPath = path.length && path.includes(id);
    const idIsCurrentPie = id === currentPie.__id;
    const idIsRootPie = pieTree.__id === id;

    if (idInPath || idIsCurrentPie || idIsRootPie) {
      hasCircularReference = true;
    }

    return hasCircularReference;
  }, false);
};
