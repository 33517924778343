// TODO: figure out a better place to put these and delete this. We shouldn't add any more to this object.
export const OTHER_ACTIONS = Object.freeze({
  SUBMIT_ORDER_FORM: 'SUBMIT_ORDER_FORM' as const,
  SUBMIT_NEW_CASH_THRESHOLD: 'SUBMIT_NEW_CASH_THRESHOLD' as const,
  SELECT_PAYMENTS_ACCOUNT: 'SELECT_PAYMENTS_ACCOUNT' as const,
  REQUEST_REFERRALS_PARTICIPATION: 'REQUEST_REFERRALS_PARTICIPATION' as const,
  FINISHED_SEARCH_SLICEABLES: 'FINISHED_SEARCH_SLICEABLES' as const,
});

export type FinishedSearchSliceables = {
  payload: { query: string };
  type: typeof OTHER_ACTIONS.FINISHED_SEARCH_SLICEABLES;
};

export type SelectPaymentsAccountAction = {
  payload: {
    accountId: string;
    navigateTo: string;
  };
  type: typeof OTHER_ACTIONS.SELECT_PAYMENTS_ACCOUNT;
};

export const selectPaymentsAccount = (
  payload: SelectPaymentsAccountAction['payload'],
) => ({
  payload,
  type: OTHER_ACTIONS.SELECT_PAYMENTS_ACCOUNT,
});

export const finishedSearchSliceables = (
  payload: FinishedSearchSliceables['payload'],
): FinishedSearchSliceables => ({
  payload,
  type: OTHER_ACTIONS.FINISHED_SEARCH_SLICEABLES,
});

export type OtherAction =
  | FinishedSearchSliceables
  | SelectPaymentsAccountAction;
