import * as React from 'react';

import { WizardContextValue } from './types';

import { WizardProviderContext } from './WizardProvider';

export const useWizardContext = <K extends string>(): WizardContextValue<K> => {
  const wizardContext = React.useContext(WizardProviderContext);
  if (!wizardContext) {
    throw new Error('You must use useWizardContext within WizardProvider.');
  }
  return wizardContext;
};
