import * as React from 'react';

import {
  IdentityFirstConfirmationScreenSection,
  SectionRowData,
} from '~/components/Onboarding/IdentityFirstConfirmationScreenHelpers/IdentityFirstConfirmationScreenSection';
import { FinancialInformationProfileFragment } from '~/graphql/types';

import {
  getKeyValuePairsFromInput,
  mapResponseToFinancialSuitabilityData,
} from './IdentityFirstConfirmationScreenFinancialSectionHelpers';

type IdentityFirstConfirmationScreenFinancialSectionProps = {
  onClickEdit: () => void;
  profile: FinancialInformationProfileFragment;
};

export const IdentityFirstConfirmationScreenFinancialSection = ({
  onClickEdit,
  profile: remoteProfile,
}: IdentityFirstConfirmationScreenFinancialSectionProps) => {
  let rows = [] as Array<SectionRowData>;

  if (remoteProfile) {
    rows = getKeyValuePairsFromInput(
      mapResponseToFinancialSuitabilityData(remoteProfile),
    );
  }

  return (
    <IdentityFirstConfirmationScreenSection
      headingLabel="Financial information"
      editLabel="Edit"
      rows={rows}
      onEdit={onClickEdit}
    />
  );
};
