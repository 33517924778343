import { Button } from '@m1/liquid-react';
import type { IllustrationsType } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { useNavigate } from '~/hooks/useNavigate';

import { StepTemplate } from '../components/StepTemplate';

export const ApplicationReceived = () => {
  const navigate = useNavigate();

  const title = 'Your application has been received.';
  const content =
    'Please check back tomorrow to review your application status.';
  const button = (
    <Button
      kind="primary"
      label="Return Home"
      onClick={() => {
        navigate({ to: '/d/home' });
      }}
      size="large"
    />
  );
  const illustrationName = 'loanApproved' as IllustrationsType;

  return (
    <StepTemplate
      title={title}
      content={content}
      button={button}
      illustrationName={illustrationName}
    />
  );
};
