import { Box, HXS, HXXS, PL } from '@m1/liquid-react';
import * as React from 'react';

import { usePortfolioSliceChildrenTableLazyQuery } from '~/graphql/hooks';

import {
  ChildPortfolioSlice,
  PortfolioSlice,
  PortfolioSliceChildrenTableQuery,
  PortfolioSliceDeallocatedChildrenSectionFragment,
  PortfolioSlicePerformancePeriodEnum,
  SortDirectionEnum,
} from '~/graphql/types';
import { PortfolioSliceSortKey } from '~/portfolio-slice';

import { PortfolioSliceChildrenTable } from './PortfolioSliceChildrenTable';
import { PortfolioSliceDeallocatedChildrenSection } from './PortfolioSliceDeallocatedChildrenSection';

type PortfolioToPiePartialProps = {
  colors: Map<
    string,
    {
      activeColor: string;
      inactiveColor: string;
    }
  >;
  deallocatedChildren: PortfolioSliceDeallocatedChildrenSectionFragment['deallocatedChildren'];
  chartPeriod: PortfolioSlicePerformancePeriodEnum;
  onChangeSort: (...args: Array<any>) => any;
  period: string;
  portfolioSliceId: string;
  portfolioSliceDescription?: string | null;
  sort: (...args: Array<any>) => any;
  sortDirection: SortDirectionEnum;
  sortKey: PortfolioSliceSortKey;
};

export const PortfolioToPieContainer = ({
  colors,
  deallocatedChildren,
  onChangeSort,
  portfolioSliceId,
  portfolioSliceDescription,
  chartPeriod,
  sort,
  sortDirection,
  sortKey,
}: PortfolioToPiePartialProps) => {
  const [fetchChildSliceDetails] = usePortfolioSliceChildrenTableLazyQuery({
    variables: {
      sliceId: portfolioSliceId,
      period: chartPeriod,
    },
  });

  // in order to keep data from disappearing during a load, we're using React.useState to persist
  // a loaded value until the next has been loaded.
  const [data, setData] =
    React.useState<PortfolioSliceChildrenTableQuery | null>(null);

  React.useEffect(() => {
    fetchChildSliceDetails({
      variables: {
        sliceId: portfolioSliceId,
        period: chartPeriod,
      },
      onCompleted(responseData) {
        setData(responseData);
      },
    });
  }, [portfolioSliceId, chartPeriod]);

  const children = ((data?.portfolioSlice as PortfolioSlice)?.children ??
    []) as ChildPortfolioSlice[];
  return (
    <>
      <Box mt={32}>
        <HXXS fontWeight={300} mb={16}>
          Slices: {children.length}
        </HXXS>
        <PortfolioSliceChildrenTable
          colors={colors}
          children={children}
          onChangeSort={onChangeSort}
          sort={sort}
          sortDirection={sortDirection}
          sortKey={sortKey}
        />
      </Box>
      {portfolioSliceDescription && (
        <Box mt={32}>
          <HXS content="Description" mb={12} />
          <PL content={portfolioSliceDescription} />
        </Box>
      )}
      <PortfolioSliceDeallocatedChildrenSection
        deallocatedChildren={deallocatedChildren}
        style={{
          marginTop: 32,
        }}
      />
    </>
  );
};
