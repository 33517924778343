import { PL } from '@m1/liquid-react';
import React from 'react';

import { HelpPopup } from '~/components/HelpPopup';
import { useModelPortfolioInfoQuery } from '~/graphql/hooks';
import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';
import { Grid } from '~/toolbox/grid';

import {
  StyledBackButtonLink,
  StyledCategoryName,
  StyledModelPortfolioHeaderContainer,
  StyledModelPortfolioHeaderGrid,
} from './CategoryDetails.styled';

type CategoryDetailsHeaderProps = {
  categoryKey: string | undefined;
};

export const CategoryDetailsHeader = ({
  categoryKey,
}: CategoryDetailsHeaderProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data: modelPortfolioData } = useModelPortfolioInfoQuery({
    variables: {
      filterCategory: [categoryKey as string],
    },
  });

  const handleBackClick = React.useCallback(() => {
    navigate(-1);
  }, [pathname]);

  const categoryDetails =
    modelPortfolioData?.viewer.screenSystemPies?.currentCategory
      .categoryDetails[0];

  if (!categoryDetails) {
    return null;
  }
  const imageSrc = categoryDetails.featureUrl;

  return (
    <StyledModelPortfolioHeaderContainer imageSrc={imageSrc}>
      <StyledModelPortfolioHeaderGrid constrain>
        <Grid.Row>
          <Grid.Col sm={9} xs={12}>
            <StyledCategoryName>{categoryDetails.name}</StyledCategoryName>
            <PL>
              {categoryDetails.description}
              {categoryDetails.disclosure ? (
                <HelpPopup body={categoryDetails.disclosure} />
              ) : null}
            </PL>
          </Grid.Col>
          <Grid.Col sm={3} smTextRight smEnd xs={12}>
            <p>
              <StyledBackButtonLink
                kind="link"
                label="Back To Categories"
                onClick={handleBackClick}
              />
            </p>
          </Grid.Col>
        </Grid.Row>
      </StyledModelPortfolioHeaderGrid>
    </StyledModelPortfolioHeaderContainer>
  );
};
