import * as React from 'react';

import { Logo } from '~/toolbox/logo';

export type SecurityLogoProps = {
  content: React.ReactNode;
};

export const SecurityLogo = ({ content }: SecurityLogoProps) => {
  return (
    <Logo
      content={content}
      placeholder=""
      style={{
        borderRadius: '4px',
        height: '36px',
        marginRight: 16,
        paddingBottom: 0,
        userSelect: 'none',
        width: '36px',
      }}
    />
  );
};
