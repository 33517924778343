import { LS, HXXS, Flex, styled, PS } from '@m1/liquid-react';
import * as React from 'react';

import { PercentGain } from '~/components/percent-gain';
import { SecurityMiniChartIntraday } from '~/components/security/security-mini-chart-intraday/SecurityMiniChartIntraday';
import { MarketIndexProxyFragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';

const StyledMarketIndexProxy = styled(Flex)`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.borderMain}`};
  cursor: pointer;
  padding: 16px;
  flex-wrap: wrap;
  min-width: 150px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundNeutralMain};
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const MarketIndexProxy = ({
  market,
}: {
  market: MarketIndexProxyFragment;
}) => {
  const navigate = useNavigate();
  const latestPercentChange =
    market.proxy.latestQuote?.percentChangeFromPreviousClosePrice;

  return (
    <StyledMarketIndexProxy
      data-testid="market-index-proxy"
      className="testing"
      key={market.proxy.id}
      onClick={() => {
        navigate({
          to: '/d/research/funds/details/:fundId',
          params: { fundId: market.proxy.id },
        });
      }}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flexGrow="1"
        flexWrap="wrap"
      >
        <Flex flex="1 0 auto" flexDirection="column">
          <HXXS fontWeight={600} mb={4}>
            {market.proxy.symbol}
          </HXXS>
          <LS
            as="div"
            color="foregroundNeutralSecondary"
            mb={4}
            overflowWrap="break-word"
          >
            Tracks {market.name}
          </LS>
          {typeof latestPercentChange === 'number' && (
            <PS display="flex" as="div">
              <PercentGain value={latestPercentChange} />
            </PS>
          )}
        </Flex>
        <Flex flex="0 1 auto">
          <SecurityMiniChartIntraday
            intradayQuotes={market.proxy.intradayQuotes}
          />
        </Flex>
      </Flex>
    </StyledMarketIndexProxy>
  );
};
