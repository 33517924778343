import { Box, HXXS } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useEmploymentInfoFormModernQuery } from '~/graphql/hooks';
import { Divider } from '~/toolbox/divider';
import { Spinner } from '~/toolbox/spinner';
import { isNotNil } from '~/utils';

import { EmploymentForm } from './EmploymentForm';

export const EmploymentPage = () => {
  const { data, loading } = useEmploymentInfoFormModernQuery();

  if (loading) {
    return <Spinner fullScreen />;
  }

  const profile = data?.viewer.profile;
  const occupationDropdownOptions =
    data?.viewer?.onboarding?.dueDiligence?.occupationDropdownOptions;

  if (!profile || !occupationDropdownOptions) {
    return (
      <GenericSystemError content="Your profile is currently unavailable." />
    );
  }

  const { secondary: secondaryProfile } = profile;
  const hasJointAccount = isNotNil(secondaryProfile);

  return (
    <>
      {hasJointAccount && (
        <Box pb={16}>
          <HXXS content="Primary account holder" />
        </Box>
      )}
      <EmploymentForm
        dropdownOptions={occupationDropdownOptions}
        profileType="primary"
        value={profile.primary}
      />
      {hasJointAccount && (
        <>
          <Divider spacing="relaxed" />
          <Box pb={16}>
            <HXXS content="Secondary account holder" />
          </Box>
          <EmploymentForm
            dropdownOptions={occupationDropdownOptions}
            profileType="secondary"
            value={secondaryProfile}
          />
        </>
      )}
    </>
  );
};
