import {
  Box,
  Button,
  Flex,
  HM,
  HS,
  HXXS,
  PL,
  PM,
  PXL,
  styled,
  theme,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { AppContext } from '~/AppContext';
import { EmailVerificationBanner } from '~/components/Banners';
import { GenericSystemError } from '~/components/GenericSystemError';

import {
  StyledApplicationBottomCardContainer,
  StyledApplicationLeftCardContainer,
  StyledApplicationRightCardContainer,
  StyledApplicationTopCardContainer,
} from '~/flows/components/personal-loans/application/components/ApplicationCard';
import { StyledApplicationContainer } from '~/flows/components/personal-loans/application/components/ApplicationContainer';
import { useLoanTermsAndAcceptPageQuery } from '~/graphql/hooks';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { useSelector } from '~/redux/hooks';
import { NOTIFICATION_LOCATIONS } from '~/static-constants';
import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';
import { Pill } from '~/toolbox/Pill';
import { Spinner } from '~/toolbox/spinner';

import { SaveAndCloseButton } from '../components/SaveAndCloseButton';

import { WithdrawButton } from '../components/WithdrawButton';

interface AdditionalDocument {
  url: string;
  title: string;
}

const StyledPaymentSchedule = styled(Flex)`
  margin-left: 24px;
  margin-top: 136px;
  padding: 24px;
  padding-bottom: 8px;
  border-radius: 8px;
  border: 1px solid #dddddd;
  flex-direction: column;
  background-color: ${theme.colors.backgroundNeutralMain};
`;

const StyledLoanDetailsCard = styled(Flex)`
  flex: auto;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
  padding-bottom: 16px;
`;

const LoanDetailsCard = ({
  title,
  subtitle,
  value,
  noBorder,
}: {
  subtitle: string | null | undefined;
  title: string | null | undefined;
  value: string | null | undefined;
  noBorder: boolean | null | undefined;
}) => (
  <StyledLoanDetailsCard
    style={{
      borderBottom: !noBorder ? '1px solid' : 0,
      borderColor: theme.colors.grey03,
    }}
  >
    <Flex flexDirection="column">
      <PXL fontWeight={600}>{title}</PXL>
      <PM
        fontWeight={400}
        mt={4}
        color="foregroundNeutralSecondary"
        width={260}
      >
        {subtitle}
      </PM>
    </Flex>
    <Flex justifyContent="flexEnd">
      <HS fontWeight={500} color="foregroundNeutralMain">
        {value}
      </HS>
    </Flex>
  </StyledLoanDetailsCard>
);

const PaymentScheduleLineItem = ({
  label,
  value,
  noBorder,
}: {
  label: string | null | undefined;
  value: string | null | undefined;
  noBorder: boolean | null | undefined;
}) => {
  return (
    <Flex
      justifyContent="space-between"
      pt={16}
      pb={16}
      style={{
        borderBottom: !noBorder ? '1px solid' : 0,
        borderColor: theme.colors.grey03,
      }}
    >
      <PL color="foregroundNeutralSecondary">{label}</PL>
      <PL fontWeight={600}>{value}</PL>
    </Flex>
  );
};

type LoanTermsAndAcceptPageProps = {
  onFinishStep: (arg0: {
    loanAcceptanceSignature: string;
    loanId: string;
    withdrawingApplication?: boolean;
  }) => void;
};

export const LoanTermsAndAcceptPage = ({
  onFinishStep,
}: LoanTermsAndAcceptPageProps) => {
  const { analytics } = React.useContext(AppContext);
  const [viewedLoanAgreement, setViewedLoanAgreement] =
    React.useState<boolean>(false);
  const [requiredDocumentError, setRequiredDocumentError] =
    React.useState<boolean>(false);
  const { applicationId } = useSelector(
    (state) => state.newFlows.PERSONAL_LOANS_APPLICATION,
  );

  const { data, loading } = useLoanTermsAndAcceptPageQuery({
    variables: {
      applicationId,
    },
    errorPolicy: 'all',
  });

  const handleFinish = (
    requiredDocuments: Array<any>,
    loanAcceptanceSignature: string,
    loanId: string,
  ) => {
    if (viewedLoanAgreement) {
      setRequiredDocumentError(false);
      onFinishStep({
        loanId,
        loanAcceptanceSignature,
      });
    } else {
      setRequiredDocumentError(true);
    }
  };

  if (loading) {
    return <Spinner fullScreen centered />;
  }

  const loanTerms =
    data?.viewer.borrow?.personalLoans?.applicationFlow?.viewLoanTerms;
  const loanId = data?.viewer.borrow?.personalLoans?.application?.loan?.id;
  const loanAcceptanceSignature = loanTerms?.additionalTerms?.signature;
  const isPrimaryEmailVerified = data?.viewer?.user?.isPrimaryEmailVerified;

  if (
    !loanTerms ||
    !loanId ||
    !loanAcceptanceSignature ||
    !loanTerms.dynamicLoanAgreementPresignedUrl
  ) {
    if (!loanTerms?.dynamicLoanAgreementPresignedUrl) {
      analytics.recordEvent('m1_personal_loan_lad_failure');
    }
    return <GenericSystemError />;
  }

  const {
    loanDetails = [],
    subtitle,
    title,
    viewLoanDocumentsHeader,
    notViewedErrorMessage,
    dynamicLoanAgreementPresignedUrl,
  } = loanTerms;

  if (dynamicLoanAgreementPresignedUrl) {
    analytics.recordEvent('m1_personal_loan_lad_success');
  }

  return (
    <Container marginTop={32}>
      {!isPrimaryEmailVerified && (
        <Box mb={-28} mt={-28}>
          <EmailVerificationBanner
            content="You can’t accept a loan offer until your email is verified. Please check your inbox."
            notificationLocation={
              NOTIFICATION_LOCATIONS.PERSONAL_LOANS_VIEW_TERMS_EMAIL_VERIFICATION
            }
          />
        </Box>
      )}
      <WithdrawButton
        mt={isPrimaryEmailVerified ? -24 : 24}
        onFinish={() => {
          return onFinishStep({
            loanId,
            loanAcceptanceSignature,
            withdrawingApplication: true,
          });
        }}
      />
      <StyledApplicationContainer>
        <StyledApplicationTopCardContainer>
          <StyledApplicationLeftCardContainer>
            <HM>{title}</HM>
            <PXL mt={16} mb={32}>
              {subtitle}
            </PXL>
            {loanDetails &&
              loanDetails.map((loanDetail, index: number) => (
                <LoanDetailsCard
                  value={loanDetail?.value}
                  title={loanDetail?.title}
                  key={loanDetail?.title}
                  subtitle={loanDetail?.subtitle}
                  noBorder={index === loanDetails.length - 1}
                />
              ))}
            <Flex>
              <PL fontWeight={600} mt={28} mb={16}>
                {viewLoanDocumentsHeader}
              </PL>
            </Flex>
            <Flex mb={6}>
              <LinkableLink
                linkable={dynamicLoanAgreementPresignedUrl}
                fontSize="16px"
                fontWeight={400}
                textDecoration="underline"
                onClick={() => {
                  setRequiredDocumentError(false);
                  setViewedLoanAgreement(true);
                }}
              />
              <Pill
                kind={viewedLoanAgreement ? 'success' : 'caution'}
                label={viewedLoanAgreement ? 'Viewed' : 'Not viewed'}
                ml={8}
              />
            </Flex>
            <Flex flexDirection="column">
              {loanTerms.additionalTerms?.documents.map(
                (additionalDocument: AdditionalDocument) => (
                  <Flex key={additionalDocument.url} mt={8}>
                    <Link
                      to={additionalDocument.url}
                      target="_blank"
                      fontSize="16px"
                      fontWeight={400}
                      textDecoration="underline"
                    >
                      {additionalDocument.title}
                    </Link>
                  </Flex>
                ),
              )}
            </Flex>
            {requiredDocumentError && (
              <Flex mt={6} alignItems="center">
                <Icon name="alert16" color="foregroundCritical" />
                <PM
                  ml={4}
                  color="critical"
                  content={notViewedErrorMessage?.copy}
                />
              </Flex>
            )}
          </StyledApplicationLeftCardContainer>
          <StyledApplicationRightCardContainer
            style={{
              alignItems: 'flex-start',
            }}
          >
            <StyledPaymentSchedule>
              <HXXS>Payment schedule</HXXS>
              <Box mt={16} borderRadius={8} width={380}>
                {loanTerms.paymentSchedule?.map(
                  (paymentScheduleLineItem, index) => (
                    <PaymentScheduleLineItem
                      key={paymentScheduleLineItem?.label}
                      label={paymentScheduleLineItem?.label}
                      value={paymentScheduleLineItem?.value}
                      noBorder={
                        index ===
                        (loanTerms?.paymentSchedule
                          ? loanTerms.paymentSchedule.length
                          : 0) -
                          1
                      }
                    />
                  ),
                )}
              </Box>
            </StyledPaymentSchedule>
          </StyledApplicationRightCardContainer>
        </StyledApplicationTopCardContainer>
        <StyledApplicationBottomCardContainer>
          <Flex>
            <Button
              kind="primary"
              size="large"
              label="Accept this offer"
              type="submit"
              onClick={() => {
                analytics.recordEvent('m1_personal_loan_accept_offer');
                return handleFinish(
                  [dynamicLoanAgreementPresignedUrl],
                  loanAcceptanceSignature,
                  loanId,
                );
              }}
            />
            <SaveAndCloseButton />
          </Flex>
        </StyledApplicationBottomCardContainer>
      </StyledApplicationContainer>
    </Container>
  );
};
