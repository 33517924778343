import { SagaIterator } from 'redux-saga';

import { NavigateFunction } from '~/hooks/useNavigate';
import { ACTION_TYPES as ACTIONS, beginOnboarding } from '~/redux/actions';

import { put, take, call, select } from '../effects';

export function* signupMonitorSaga(): SagaIterator<void> {
  const navigate: NavigateFunction = yield select(
    (state) => state.routing.navigate,
  );

  yield take(ACTIONS.FINISHED_REGISTER_USER_FLOW);
  yield call(navigate, { to: '/onboarding/phone-verification' });
  yield put(beginOnboarding());
}
