import { Box, Flex } from '@m1/liquid-react';
import React from 'react';

import { SignupNotifications } from '~/components/notifications';
import { useAnalytics } from '~/hooks/useAnalytics';

import { StyledHeader } from '../components/StyledHeader';
import { RegisterUserPersonalLoansDirectFlow } from '../flow/RegisterUserPersonalLoansDirectFlow';

export const NameEmailPassword = () => {
  const analytics = useAnalytics();

  React.useEffect(() => {
    analytics.pageView('/direct-loan-application/signup');
  }, [analytics]);

  return (
    <Flex flexDirection="column">
      <Box>
        <Box pb={8}>
          <SignupNotifications />
        </Box>
        <StyledHeader>Welcome to M1.</StyledHeader>
        <StyledHeader>
          Create a quick profile to view your loan offers.
        </StyledHeader>
      </Box>
      <RegisterUserPersonalLoansDirectFlow />
    </Flex>
  );
};
