import { Box } from '@m1/liquid-react';
import React from 'react';
import { FormSection } from 'redux-form';

import { ScheduleFrequencyFields } from '~/forms/fields';

import { TRANSFER_SCHEDULE_FREQUENCIES } from '~/static-constants';

import { DepositInfoCard } from '../DepositInfoCard';

type RecurringDepositFrequencyFieldsProps = {
  isScheduleSwitchOn: boolean;
};

export const RecurringDepositFrequencyFields = ({
  isScheduleSwitchOn,
}: RecurringDepositFrequencyFieldsProps) => {
  return (
    <Box
      style={{
        display: isScheduleSwitchOn ? 'initial' : 'none',
      }}
    >
      <FormSection name="schedule">
        <ScheduleFrequencyFields
          initialFrequency={TRANSFER_SCHEDULE_FREQUENCIES.MONTHLY}
          isScheduleSwitchOn={isScheduleSwitchOn}
        />
      </FormSection>
      <DepositInfoCard />
    </Box>
  );
};
