import { Box, Button, Flex, PM } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { CreditCardSummaryFragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { useSelector } from '~/redux/hooks';
import { Checkbox } from '~/toolbox/checkbox';
import { Link } from '~/toolbox/link';

import { CreditCardApplicationSummary } from './CreditCardApplicationSummary';

export type SummaryComponentProps = {
  creditInformation: CreditCardSummaryFragment;
  onFinishStep: (...args: Array<any>) => any;
};

export const FinalSummaryComponent = ({
  creditInformation,
  onFinishStep,
}: SummaryComponentProps) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = React.useState<boolean>(false);

  const { totalAnnualIncome, monthlyPayments, housingType } = useSelector(
    (state) => state.newFlows.CREDIT_CARD_APPLICATION,
  );

  const { applicantInformation, termsAndConditions } = creditInformation;

  if (!applicantInformation || !termsAndConditions) {
    const closeButton = (
      <Button
        kind="primary"
        onClick={() => {
          navigate({ to: '/d/spend' });
        }}
        size="large"
        label="Close"
      />
    );

    return (
      <GenericSystemError
        title="Oh no!"
        content="Something went wrong! If the problem persists, please contact support."
        button={closeButton}
      />
    );
  }
  const creditCardApplicationMutationData = {
    termsAndConditionsSignature: termsAndConditions.signature,
    annualIncome: totalAnnualIncome,
    monthlyExpenses: Number(monthlyPayments),
    housingType: housingType?.toUpperCase(),
    userAttestsApplicationData: isChecked,
  };
  return (
    <>
      <CreditCardApplicationSummary
        creditInformation={creditInformation}
        onFinishStep={onFinishStep}
        showIncomeInformation
      />
      <Box pb={60} mt={32}>
        <Box p={24} backgroundColor="backgroundNeutralMain" borderRadius={2}>
          <PM content={termsAndConditions.preamble} pb={8} />
          <Flex flexWrap="wrap" flexDirection="row">
            {termsAndConditions.documents.map((d) => (
              <Box
                style={{
                  paddingBottom: 8,
                  width: 325,
                }}
                key={d.url}
              >
                <Link to={d.url} target="_blank">
                  {d.title}
                </Link>
              </Box>
            ))}
          </Flex>
          <PM content={termsAndConditions.postamble} pb={4} />
        </Box>
      </Box>
      <Checkbox
        mb={16}
        checked={isChecked}
        label="I confirm that the information above is accurate."
        onChange={() => setIsChecked(!isChecked)}
        size="medium"
      />
      <Button
        kind="secondary"
        label="Update Profile Information"
        onClick={() => {
          navigate({ to: '/d/settings' });
        }}
        size="large"
      />
      <Button
        ml={12}
        kind="primary"
        label="Submit application"
        size="large"
        disabled={!isChecked}
        onClick={() => onFinishStep(creditCardApplicationMutationData)}
      />
    </>
  );
};
