/**
 *
 * @param value - number to be converted to fixed point number
 * @param precision - number of decimal places
 * @returns fixed point number
 */
export const toFixedPointNumber = (
  value: number,
  precision: number,
): number => {
  return Number(Number(value).toFixed(precision));
};
