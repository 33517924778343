import { Box, HXS, PL } from '@m1/liquid-react';
import React from 'react';

type CategoryCardHeaderProps = {
  name: string;
  description: string;
};

export const CategoryCardHeader = ({
  name,
  description,
}: CategoryCardHeaderProps) => (
  <Box position="relative">
    <HXS color="foregroundNeutralMain" content={name} />
    <PL color="foregroundNeutralMain" content={description} mt={8} />
  </Box>
);
