import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { OpenInvestAccountFlow } from '~/flows/components/open-invest-account';

export const OpenInvestAccountPage = () => {
  return (
    <PageErrorBoundary>
      {/* @ts-ignore basePath is not part of the prop set for some reason */}
      <OpenInvestAccountFlow basePath="d/open-invest-account" />
    </PageErrorBoundary>
  );
};
