import { SagaIterator } from 'redux-saga';
import { call, fork, getContext, spawn } from 'redux-saga/effects';

import { parseQuery } from '~/utils';

type ReferralCodes = {
  affiliateCode: string | null | undefined;
  referrerCode: string | null | undefined;
};

export function* determineReferral(
  location: Location,
): SagaIterator<ReferralCodes | null | undefined> {
  const cdsSender = yield getContext('cdsSender');
  const q = parseQuery(location.search);

  const referralCodes: ReferralCodes = {
    referrerCode: null,
    affiliateCode: null,
  };

  // PRIORITY 1: URL Parameters

  // First, check for an Affiliate's referral code
  const affiliateCodeFromQuery = q['affiliateCode'] || null;
  if (affiliateCodeFromQuery) {
    // Async save back affiliateCodeFromQuery into the CDS.
    yield spawn(function* () {
      try {
        yield call([cdsSender, 'init']);
        yield fork([cdsSender, 'setAffiliateCode'], affiliateCodeFromQuery);
      } catch (e: any) {
        // Ignore CDS errors here.
      }
    });

    referralCodes.affiliateCode = affiliateCodeFromQuery;
    return referralCodes;
  }

  // Second, check for a U2U referral code
  const referrerCodeFromQuery = q['referrerCode'] || null;
  if (referrerCodeFromQuery) {
    // Async save back referrerCodeFromQuery into the CDS.
    yield spawn(function* () {
      try {
        yield call([cdsSender, 'init']);
        yield fork([cdsSender, 'setReferrerCode'], referrerCodeFromQuery);
      } catch (e: any) {
        // Ignore CDS errors here.
      }
    });

    referralCodes.referrerCode = referrerCodeFromQuery;
    return referralCodes;
  }

  // PRIORITY 2: CDS stored values

  try {
    yield call([cdsSender, 'init']);

    // First, check CDS for an Affiliate's referral code
    const affiliateCodeFromCds = yield call(
      [cdsSender, 'get'],
      'affiliateCode',
    );
    if (affiliateCodeFromCds) {
      referralCodes.affiliateCode = affiliateCodeFromCds;
      return referralCodes;
    }

    // Second, check CDS for a U2U referral code
    const referrerCodeFromCds = yield call([cdsSender, 'get'], 'referrerCode');
    if (referrerCodeFromCds) {
      referralCodes.referrerCode = referrerCodeFromCds;
      return referralCodes;
    }
  } catch (e: any) {
    // Do nothing.
  }

  return null;
}
