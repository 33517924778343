import { Box, Button, Flex, PL, PXL } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { useNavigate } from '~/hooks/useNavigate';

export const CreditConfirmationPage = () => {
  const navigate = useNavigate();
  const handleFinish = (): void => {
    navigate({
      to: '/d/spend/credit/transactions',
      options: { state: { forceRefetch: true } },
    });
  };

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      mt={150}
      justifyContent="center"
    >
      <Illustration name="creditCardGreenCheck" />
      <Box width={650} textAlign="center" mt={40}>
        <PXL fontWeight={600} content="Your card has been activated!" />
        <PL
          mt={16}
          content="For added convenience, make purchases with your mobile phone by adding your card to Apple Wallet or Google Pay."
        />
      </Box>
      <Button
        kind="primary"
        size="large"
        label="Done"
        type="submit"
        onClick={handleFinish}
        mt={64}
      />
    </Flex>
  );
};
