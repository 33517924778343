import * as React from 'react';
import { Route } from 'react-router-dom';

import { OpenInvestJointAccountPage } from './OpenInvestJointAccountPage';

export const OpenInvestJointAccountRoute = () => {
  return (
    <Route
      path="open-invest-joint-account"
      element={<OpenInvestJointAccountPage />}
    />
  );
};
