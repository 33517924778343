import * as React from 'react';

import { useLayout } from '~/hooks/useLayout';
import { ManualReviewPage } from '~/pages/dashboard/borrow/personal/loan-application/ManualReviewPage';
import { PersonalLoansNotEligiblePage } from '~/pages/dashboard/borrow/personal/not-eligible/PersonalLoansNotEligiblePage';
import { StyledPageContent } from '~/pages/dashboard/navigation/Navigation.styled';

import { Progress } from '~/toolbox/progress';

import { ProcessingApplication } from '../../credit-card/application/steps';

import { STEPS } from './constants';
import { ApplicationReceived } from './steps/ApplicationReceived';
import { ApplicationRejected } from './steps/ApplicationRejected';
import { AutoPayEnrollment } from './steps/AutoPayEnrollment';
import { DepositInfoPage } from './steps/DepositInfoPage';
import { DisbursementError } from './steps/DisbursementError';
import { FinancialInformation } from './steps/FinancialInformation';
import { GeneralErrorPage } from './steps/GeneralErrorPage';
import { LandingPage } from './steps/LandingPage';
import { LoanInformation } from './steps/LoanInformation';
import { LoanOffersAndSubmitPage } from './steps/LoanOffersAndSubmitPage';
import { LoanRecap } from './steps/LoanRecap';
import { LoanTermsAndAcceptPage } from './steps/LoanTermsAndAcceptPage';
import { NoLinkedAccount } from './steps/NoLinkedAccount';
import { PlaidIncomeVerification } from './steps/PlaidIncomeVerification';
import { PlaidVerificationFlow } from './steps/PlaidVerificationFlow';
import { ProcessingOfferSubmission } from './steps/ProcessingOfferSubmission';
import { ProcessingResubmission } from './steps/ProcessingResubmission';
import { PromptForBankConnection } from './steps/PromptForBankConnection';
import { RemoveCreditFreezePage } from './steps/RemoveCreditFreezePage';
import { PersonalLoansCreateAccountReviewPage } from './steps/Review/PersonalLoansCreateAccountReviewPage';
const StepOrder = {
  'landing-page': 1,
  'loan-information': 2,
  'financial-information': 3,
  'financial-review': 4,
  'loan-offers': 5,
  'loan-terms': 6,
  'plaid-income-verification': 7,
  'manual-review': 8,
  'deposit-info': 9,
  'autopay-enrollment': 10,
  'loan-recap': 11,
};

const TotalNumberOfSteps: number = Object.keys(StepOrder).length;

export type PersonalLoansContainerProps = {
  onFinishStep: () => void;
  step: ValueOf<typeof STEPS>;
};

export const PersonalLoansApplicationContainer = ({
  step,
  ...rest
}: PersonalLoansContainerProps) => {
  const { contentWidth } = useLayout();

  const steps = {
    // Start application, disclosures
    [STEPS.LANDING_PAGE]: LandingPage,
    // Step One, User Input, Information on Loan request
    [STEPS.LOAN_INFORMATION]: LoanInformation,
    // Step Three, User Input, Employment and Housing information
    [STEPS.FINANCIAL_INFORMATION]: FinancialInformation,
    // Step Four, Review and Confirm Financial Information
    [STEPS.FINANCIAL_REVIEW]: PersonalLoansCreateAccountReviewPage,
    // Step Five, Present user loan offers & submit application
    [STEPS.LOAN_OFFERS_AND_SUBMIT]: LoanOffersAndSubmitPage,
    // Step Six, Application Results
    [STEPS.LOAN_TERMS_AND_ACCEPT]: LoanTermsAndAcceptPage,
    // Step Seven, Verify Income
    [STEPS.INCOME_VERIFICATION]: PlaidIncomeVerification,
    [STEPS.PLAID_VERIFICATION_FLOW]: PlaidVerificationFlow,
    [STEPS.MANUAL_REVIEW]: ManualReviewPage,
    // Step Eight, User Input, If application and loan accepted, deposit information for loan
    [STEPS.DEPOSIT_INFO]: DepositInfoPage,
    [STEPS.PROMPT_FOR_BANK]: PromptForBankConnection,
    // Step Nine, Setup AutoPay
    [STEPS.AUTOPAY_ENROLLMENT]: AutoPayEnrollment,
    // Step Ten, Recap of loan details
    [STEPS.LOAN_RECAP]: LoanRecap,
    // Processing application
    [STEPS.PROCESSING_OFFER_SUBMISSION]: ProcessingOfferSubmission,
    [STEPS.PROCESSING_RESUBMISSION]: ProcessingResubmission,
    [STEPS.PROCESSING_APPLICATION]: ProcessingApplication,
    [STEPS.APPLICATION_RECEIVED]: ApplicationReceived,
    // Errors
    [STEPS.APPLICATION_REJECTED]: ApplicationRejected,
    [STEPS.REMOVE_CREDIT_FREEZE]: RemoveCreditFreezePage,
    [STEPS.NO_LINKED_ACCOUNT]: NoLinkedAccount,
    [STEPS.DISBURSEMENT_ERROR]: DisbursementError,
    [STEPS.GENERAL_ERROR_PAGE]: GeneralErrorPage,
    [STEPS.NOT_ELIGIBLE]: PersonalLoansNotEligiblePage,
  };

  const Step = steps[step];
  const progressBarStep = StepOrder[step as keyof typeof StepOrder];

  return Step ? (
    <StyledPageContent margin="auto" contentWidth={contentWidth}>
      {progressBarStep && (
        <Progress
          percent={(progressBarStep / TotalNumberOfSteps) * 100}
          height={4}
        />
      )}
      <Step {...rest} />
    </StyledPageContent>
  ) : null;
};
