import { HXS } from '@m1/liquid-react';
import classNames from 'classnames';
import * as React from 'react';

import style from './style.module.scss';

type Props = {
  action?: React.ReactNode | null | undefined;
  label: string;
  size: 'xsmall' | 'small' | 'medium' | 'beta';
};

export class SectionHeader extends React.Component<Props> {
  static defaultProps = {
    size: 'medium',
  };

  render() {
    const classes = classNames(style.root, style[`size_${this.props.size}`]);
    return (
      <div className={classes}>
        <HXS as="h2" content={this.props.label} font={this.readFont()} />
        <div className={style.action}>{this.props.action}</div>
      </div>
    );
  }

  readFont() {
    switch (this.props.size) {
      case 'xsmall':
        return 'PM';
      case 'small':
        // TODO(Wolf): Find out if this is the correct size.
        return 'PXL';
      case 'beta':
        return 'HM';
      default:
        return 'HM';
    }
  }
}
