import {
  BeginInitialFundingFlowAction,
  FinishedBeginInitialFunding,
  FinishedInitialBankConnectionAction,
  FinishedInitialFundingFlowAction,
  FinishedReadingInitialDepositFeedbackAction,
  INITIAL_FUNDING_ACTIONS,
  InitialFundingDepositConfirmationAction,
  SkippedOnboardingDeposit,
  SubmittedInitialDepositFundsFormAction,
} from './initialFundingActions.types';

export const beginInitialFundingFlow = (
  payload: BeginInitialFundingFlowAction['payload'],
): BeginInitialFundingFlowAction => ({
  payload,
  type: INITIAL_FUNDING_ACTIONS.BEGIN_INITIAL_FUNDING_FLOW,
});

export const finishedInitialBankConnection = (
  payload: FinishedInitialBankConnectionAction['payload'],
): FinishedInitialBankConnectionAction => ({
  payload,
  type: INITIAL_FUNDING_ACTIONS.FINISHED_INITIAL_BANK_CONNECTION,
});

export const submittedInitialDepositFundsForm = (
  payload: SubmittedInitialDepositFundsFormAction['payload'],
): SubmittedInitialDepositFundsFormAction => ({
  payload,
  type: INITIAL_FUNDING_ACTIONS.SUBMITTED_INITIAL_DEPOSIT_FORM,
});

export const finishedReadingInitialDepositFeedback = (
  payload: FinishedReadingInitialDepositFeedbackAction['payload'],
): FinishedReadingInitialDepositFeedbackAction => ({
  payload,
  type: INITIAL_FUNDING_ACTIONS.READ_INITIAL_DEPOSIT_FEEDBACK,
});

export const finishedInitialFundingFlow =
  (): FinishedInitialFundingFlowAction => ({
    type: INITIAL_FUNDING_ACTIONS.FINISHED_INITIAL_FUNDING_FLOW,
  });

export const skippedOnboardingDeposit = (): SkippedOnboardingDeposit => ({
  type: INITIAL_FUNDING_ACTIONS.SKIPPED_ONBOARDING_DEPOSIT,
});

export const initialFundingDepositConfirmation = (
  payload: InitialFundingDepositConfirmationAction['payload'],
): InitialFundingDepositConfirmationAction => ({
  type: INITIAL_FUNDING_ACTIONS.INITIAL_FUNDING_DEPOSIT_CONFIRMATION,
  payload,
});

export const finishedBeginInitialFunding = (): FinishedBeginInitialFunding => ({
  type: INITIAL_FUNDING_ACTIONS.FINISHED_BEGIN_INITIAL_FUNDING,
});
