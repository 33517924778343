import { Box, HXS, PL } from '@m1/liquid-react';
import * as React from 'react';

import { ExternalLink } from '~/components/ExternalLink';
import { M1Logo } from '~/toolbox/M1Logo';

export const VerifyEmailFailure = () => (
  <>
    <Box pb={32}>
      <M1Logo />
    </Box>
    <HXS content="Oops! Please try again." pb={16} />
    <PL pb={16}>
      Please double check the link sent to your email and try again. You can
      also copy and paste the link from your email directly into your browser.
    </PL>
    <PL>
      Still having trouble? Please contact{' '}
      <ExternalLink destination="SUPPORT_CENTER">Support</ExternalLink> for
      assistance.
    </PL>
  </>
);
