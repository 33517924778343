import pick from 'lodash-es/pick';

import { SmartTransferBalanceTriggerInput } from '~/graphql/types';
import { ACTION_TYPES as ACTIONS, ROUTING_ACTIONS } from '~/redux/actions';
import type { SmartTransferFulfillmentConditionInput } from '~/redux/actions/smartTransfer/smartTransferActions.types';

// TODO: move steps into static constants
import { SMART_TRANSFER_STEPS as STEPS } from '../../../../flows/components/create-smart-transfer/constants';

import type { FlowState } from '../newFlowsReducer.types';
import { createFlowReducer, createStepReducer } from '../utils';

// Below, we are re-defining a handful for ST types as exact/readonly.
// This is done so we can safely use exact/readonly props in ST components across the ST flow.
// Eventually, we'll have to move away from this since this pattern will break as types update.
export type SetSmartTransferRuleInput = {
  balanceTrigger: SmartTransferBalanceTriggerInput;
  contraParticipantEntries: Array<SmartTransferContraParticipantEntryInput>;
  existingSmartTransferRuleId: string | null | undefined;
  focusParticipantId: string | null | undefined;
};

export type SetSmartTransferRuleInputNullableBalanceTriggerDefault =
  SetSmartTransferRuleInput & {
    balanceTrigger: SmartTransferBalanceTriggerInput | null | undefined;
  };

export type SmartTransferContraParticipantEntryInput = {
  contraParticipantId: string | null | undefined;
  existingContraParticipantEntryId: string | null | undefined;
  fulfillmentCondition: SmartTransferFulfillmentConditionInput;
};

type EditRequirementsIdByContraId =
  | Readonly<Record<string, string>>
  | null
  | undefined;

export type CreateSmartTransferFlowState = FlowState<
  string,
  {
    activeContraParticipantEntry:
      | SmartTransferContraParticipantEntryInput
      | null
      | undefined;
    basePath: string;
    editRequirementsIdByContraId: EditRequirementsIdByContraId;
    focusOptionId: string | null | undefined;
    fulfillmentConditionMode: ('ADD' | 'EDIT') | null | undefined;
    input: SetSmartTransferRuleInputNullableBalanceTriggerDefault;
    isHelperOn: boolean;
    newSmartTransferContraParticipantEntryOptionId: string | null | undefined;
    smartTransferRuleId: string | null | undefined;
  }
>;

const defaultActiveContraParticipantEntry = {
  contraParticipantId: null,
  existingContraParticipantEntryId: null,
  fulfillmentCondition: null,
};

export const createSmartTransferFlowInitialState: CreateSmartTransferFlowState =
  {
    basePath: '',
    editRequirementsIdByContraId: null,
    focusOptionId: null,
    fulfillmentConditionMode: null,
    input: {
      existingSmartTransferRuleId: null,
      focusParticipantId: null,
      // @ts-expect-error - TS2322 - Type 'null' is not assignable to type 'SmartTransferBalanceTriggerInput'.
      balanceTrigger: null,
      contraParticipantEntries: [],
    },
    isHelperOn: false,
    activeContraParticipantEntry: null,
    newSmartTransferContraParticipantEntryOptionId: null,
    smartTransferRuleId: null,
    step: STEPS.LOADING,
    stepTitle: '',
  };

const stepReducer = createStepReducer(createSmartTransferFlowInitialState);

/**
 * Adds contra to the end of the contra entries unless an entry with an INDEFINITE fulfillment condition type is found.
 * If such a contra is found then it gets added one position before it.
 */
const getUpdatedContrasWithActiveContra = (
  contraParticipantEntries: Array<SmartTransferContraParticipantEntryInput>,
  activeContraParticipantEntry: SmartTransferContraParticipantEntryInput,
): Array<SmartTransferContraParticipantEntryInput> => {
  // @ts-expect-error - TS7006 - Parameter 'entry' implicitly has an 'any' type.
  const isIndefinite = (entry) =>
    entry.fulfillmentCondition.fulfillmentConditionType === 'INDEFINITE';
  const indexOfIndefinite = contraParticipantEntries.findIndex(isIndefinite);
  if (indexOfIndefinite !== -1) {
    return [
      ...contraParticipantEntries.slice(0, indexOfIndefinite),
      activeContraParticipantEntry,
      ...contraParticipantEntries.slice(indexOfIndefinite),
    ];
  }
  return [...contraParticipantEntries, activeContraParticipantEntry];
};

/**
 * Removes active contra from wherever it exists in entries
 */
const getUpdatedContrasWithRemovedActiveContra = (
  contraParticipantEntries: Array<SmartTransferContraParticipantEntryInput>,
  activeContraParticipantEntry: SmartTransferContraParticipantEntryInput,
): Array<SmartTransferContraParticipantEntryInput> => {
  const indexOfActiveContra = contraParticipantEntries
    .map((entry) => entry.contraParticipantId)
    .indexOf(activeContraParticipantEntry.contraParticipantId);
  return [
    ...contraParticipantEntries.slice(0, indexOfActiveContra),
    ...contraParticipantEntries.slice(indexOfActiveContra + 1),
  ];
};

/**
 * Replaces active contra wherever it exists in entries
 */
const getUpdatedContrasWithReplacedActiveContra = (
  contraParticipantEntries: Array<SmartTransferContraParticipantEntryInput>,
  activeContraParticipantEntry: SmartTransferContraParticipantEntryInput,
): Array<SmartTransferContraParticipantEntryInput> => {
  const indexOfActiveContra = contraParticipantEntries
    .map((entry) => entry.contraParticipantId)
    .indexOf(activeContraParticipantEntry.contraParticipantId);
  return [
    ...contraParticipantEntries.slice(0, indexOfActiveContra),
    activeContraParticipantEntry,
    ...contraParticipantEntries.slice(indexOfActiveContra + 1),
  ];
};

const getReorderedContraParticipantEntries = (
  contraParticipantEntries: Array<SmartTransferContraParticipantEntryInput>,
  updatedParticipantIdsOrder: Array<string>,
): Array<SmartTransferContraParticipantEntryInput> => {
  // @ts-expect-error - TS2322 - Type '(SmartTransferContraParticipantEntryInput | undefined)[]' is not assignable to type 'SmartTransferContraParticipantEntryInput[]'.
  return updatedParticipantIdsOrder
    .map((participantId) =>
      contraParticipantEntries.find(
        (participant) => participant.contraParticipantId === participantId,
      ),
    )
    .filter(Boolean);
};

function reducer(
  state: CreateSmartTransferFlowState = createSmartTransferFlowInitialState,
  action: any,
): CreateSmartTransferFlowState {
  switch (action.type) {
    case ACTIONS.BEGIN_FLOW:
      return {
        ...createSmartTransferFlowInitialState,
        ...pick(
          action.payload,
          Object.keys(createSmartTransferFlowInitialState),
        ),
        step: createSmartTransferFlowInitialState.step,
      };
    case ACTIONS.FINISHED_FLOW_STEP:
      switch (action.meta.step) {
        case STEPS.INITIALIZE_SMART_TRANSFER:
          return {
            ...state,
            focusOptionId: action.payload,
          };
        case STEPS.SET_SMART_TRANSFER:
          if (!action.payload?.newSmartTransferContraParticipantEntryOptionId) {
            return state;
          }
          return {
            ...state,
            activeContraParticipantEntry: null,
            newSmartTransferContraParticipantEntryOptionId:
              action.payload.newSmartTransferContraParticipantEntryOptionId,
          };
        default:
          return state;
      }
    case ACTIONS.SAVE_FOCUS_ACCOUNT_ID:
      return {
        ...state,
        input: {
          ...state.input,
          focusParticipantId: action.payload,
        },
      };
    case ACTIONS.SET_SMART_TRANSFER_BALANCE_TRIGGER_DATA:
      return {
        ...state,
        input: {
          ...state.input,
          balanceTrigger: {
            balanceTriggerType: action.payload.balanceTriggerType,
            balanceThreshold: action.payload.amount,
            underBalanceRefillTarget: action.payload.underBalanceRefillTarget,
          },
        },
      };
    case ACTIONS.SET_SMART_TRANSFER_FULFILLMENT_CONDITION_MODE:
      return {
        ...state,
        fulfillmentConditionMode: action.payload,
      };
    case ACTIONS.ADD_SMART_TRANSFER_CONTRA_PARTICIPANT_ID:
      return {
        ...state,
        // TODO: Fix this typing
        // @ts-expect-error - TS2322 - Type '{ contraParticipantId: any; existingContraParticipantEntryId: null; fulfillmentCondition: null; }' is not assignable to type 'SmartTransferContraParticipantEntryInput'.
        activeContraParticipantEntry: {
          ...defaultActiveContraParticipantEntry,
          contraParticipantId: action.payload,
        },
      };
    case ACTIONS.ADD_SMART_TRANSFER_EDIT_REQUIREMENTS_ID:
      return {
        ...state,
        editRequirementsIdByContraId: {
          ...state.editRequirementsIdByContraId,
          [action.payload.contraParticipantId]:
            action.payload.editRequirementsId,
        },
      };
    case ACTIONS.SET_SMART_TRANSFER_FULFILLMENT_CONDITIONS:
      if (state.activeContraParticipantEntry) {
        return {
          ...state,
          activeContraParticipantEntry: {
            ...state.activeContraParticipantEntry,
            fulfillmentCondition: {
              ...action.payload,
            },
          },
        };
      }
      return state;
    case ACTIONS.ADD_ACTIVE_CONTRA_PARTICIPANT:
      if (state.activeContraParticipantEntry) {
        return {
          ...state,
          activeContraParticipantEntry: null,
          input: {
            ...state.input,
            contraParticipantEntries: getUpdatedContrasWithActiveContra(
              state.input.contraParticipantEntries,
              state.activeContraParticipantEntry,
            ),
          },
          fulfillmentConditionMode: null,
        };
      }
      return state;
    case ACTIONS.DELETE_ACTIVE_CONTRA_PARTICIPANT:
      if (state.activeContraParticipantEntry) {
        return {
          ...state,
          activeContraParticipantEntry: null,
          input: {
            ...state.input,
            contraParticipantEntries: getUpdatedContrasWithRemovedActiveContra(
              state.input.contraParticipantEntries,
              state.activeContraParticipantEntry,
            ),
          },
          fulfillmentConditionMode: null,
        };
      }
      return state;
    case ACTIONS.REPLACE_ACTIVE_CONTRA_PARTICIPANT:
      if (state.activeContraParticipantEntry) {
        return {
          ...state,
          activeContraParticipantEntry: null,
          input: {
            ...state.input,
            contraParticipantEntries: getUpdatedContrasWithReplacedActiveContra(
              state.input.contraParticipantEntries,
              state.activeContraParticipantEntry,
            ),
          },
          fulfillmentConditionMode: null,
        };
      }
      return state;
    case ACTIONS.REORDER_ACTIVE_CONTRA_PARTICIPANTS:
      return {
        ...state,
        input: {
          ...state.input,
          contraParticipantEntries: getReorderedContraParticipantEntries(
            state.input.contraParticipantEntries,
            action.payload,
          ),
        },
      };
    case ACTIONS.RESET_SMART_TRANSFER_ACTIVE_CONTRA_PARTICIPANTS:
      return {
        ...state,
        input: {
          ...state.input,
          contraParticipantEntries: [],
        },
      };
    case ACTIONS.SET_SMART_TRANSFER_SUCCESS:
      return {
        ...state,
        smartTransferRuleId: action.payload,
      };
    case ACTIONS.TOGGLE_SMART_TRANSFER_HELPER:
      return {
        ...state,
        isHelperOn: !state.isHelperOn,
      };
    case ACTIONS.END_FLOW:
      return createSmartTransferFlowInitialState;
    case ROUTING_ACTIONS.LOCATION_CHANGE:
      return {
        ...state,
        step: stepReducer(state, action),
      };
    default:
      return state;
  }
}

export const createSmartTransfer = createFlowReducer(
  'CREATE_SMART_TRANSFER',
  reducer,
);
