import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

// Keep in sync with CSS variables.
const TABLET_WIDTH = 768;

type ResponsiveProps = {
  children: React.ReactNode | ((matches: boolean) => React.ReactNode);
};

function renderMatchingChildren(
  matches: boolean,
  children: React.ReactNode | ((matches: boolean) => React.ReactNode),
) {
  if (typeof children === 'function') {
    return children(matches);
  }
  return matches ? children : null;
}

export const Mobile = ({ children }: ResponsiveProps) => {
  const matches: boolean = useMediaQuery({
    query: `(max-width: ${TABLET_WIDTH - 1}px)`,
  });

  return <>{renderMatchingChildren(matches, children)}</>;
};

export const SmallOrWiderScreen = ({ children }: ResponsiveProps) => {
  const matches: boolean = useMediaQuery({
    query: `(min-width: ${TABLET_WIDTH}px)`,
  });

  return <>{renderMatchingChildren(matches, children)}</>;
};
