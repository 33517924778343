import merge from 'lodash-es/merge';

import { ACTION_TYPES } from '~/redux/actions';

export const storageReducer = (
  reducer: (...args: Array<any>) => any,
): ((...args: Array<any>) => any) => {
  return (state, action) =>
    reducer(
      action.type === ACTION_TYPES.LOAD_FROM_STORAGE
        ? merge(state, action.payload)
        : state,
      action,
    );
};
