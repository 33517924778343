import { Box, PXL, TwoColumn4by8, Card } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useRewardsLandingScreenComponentQuery } from '~/graphql/hooks';
import { AppLinkFragment } from '~/graphql/types';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { Spinner } from '~/toolbox/spinner';

import { RewardsDetails } from './RewardsDetails';
import { RewardsSummary } from './RewardsSummary';

export const RewardsLandingScreenComponent = () => {
  const { data, loading } = useRewardsLandingScreenComponentQuery();
  if (loading) {
    return <Spinner fullScreen />;
  }
  const rewardsPortal = data?.viewer.credit?.rewardsPortal;
  const rewardsSummary = rewardsPortal?.summary;

  if (!rewardsSummary || !rewardsPortal) {
    return (
      <Box mb={100}>
        <GenericSystemError />
      </Box>
    );
  }
  const { currentCycleRewardsEstimate, lifetimeRewardsOverview } =
    rewardsSummary;

  const { documents, ownersRewardsOverview, standardRewardsOverview } =
    rewardsPortal;
  if (
    !currentCycleRewardsEstimate ||
    !lifetimeRewardsOverview ||
    !standardRewardsOverview ||
    !documents ||
    !ownersRewardsOverview?.highlights
  ) {
    return (
      <Box mb={100}>
        <GenericSystemError />
      </Box>
    );
  }

  return (
    <Box mt={16}>
      <TwoColumn4by8>
        <TwoColumn4by8.Column1>
          <Box>
            <RewardsSummary rewardsSummary={rewardsSummary} />
            <Documents documents={documents} />
          </Box>
        </TwoColumn4by8.Column1>
        <TwoColumn4by8.Column2>
          <RewardsDetails rewardsDetails={rewardsPortal} />
        </TwoColumn4by8.Column2>
      </TwoColumn4by8>
    </Box>
  );
};

const Documents = ({
  documents,
}: {
  documents: (AppLinkFragment | null | undefined)[] | null | undefined;
}) => {
  if (!documents) {
    return null;
  }
  return (
    <>
      <PXL fontWeight={600} content="Documents" mt={32} />
      <Card mt={4} p="12px 12px 4px 12px">
        {documents.map((document, i) => (
          <React.Fragment key={`cc-rewards-document-${i}`}>
            {document && (
              <Box pb={8}>
                <LinkableLink linkable={document} />
              </Box>
            )}
          </React.Fragment>
        ))}
      </Card>
    </>
  );
};
