import * as React from 'react';

import { SecurityTypeEnum } from '~/graphql/types';

import { useScreenerState } from '../../useScreenerState';

import { defaultLimits, INITIAL_FUND_SCREENER_VARIABLES } from './constants';
import { FundScreenerQueryResultContextValue } from './types';

const FundScreenerQueryResultContext = React.createContext(
  {} as FundScreenerQueryResultContextValue,
);

type FundScreenerProviderProps = {
  children: React.ReactNode;
};

export const FundScreenerProvider = ({
  children,
}: FundScreenerProviderProps) => {
  const screenerState = useScreenerState({
    screenerType: SecurityTypeEnum.Fund,
    defaultVariables: INITIAL_FUND_SCREENER_VARIABLES,
    defaultLimits: defaultLimits,
  });

  return (
    <FundScreenerQueryResultContext.Provider value={screenerState}>
      {children}
    </FundScreenerQueryResultContext.Provider>
  );
};

export const useFundScreenerResult = () => {
  const queryResult = React.useContext(FundScreenerQueryResultContext);
  if (!queryResult.queryResult) {
    throw new Error(
      'useFundScreenerResult must be used within a FundScreenerProvider',
    );
  }
  return queryResult;
};
