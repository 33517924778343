import { Box, Flex, HM, PL } from '@m1/liquid-react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { BackArrow } from '~/components/BackArrow';
import { useOpenInvestJointAccountWizardContext } from '~/flows/components/OpenInvestJointAccountWizard/hooks/useOpenInvestJointAccountWizardContext';
import { StyledOption } from '~/pages/onboarding/financial-suitability-wizard/consts';
import { submitReactFormData } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { getEnumEntries } from '~/utils';

export interface LiquidNetWorthValues {
  liquidNetWorth: string;
}
export const LiquidNetWorth = () => {
  const dispatch = useDispatch();
  const submittedFormValues = useSelector((state) => {
    if (!state.reactHookForm.openJointInvestAccountLiquidNetWorth) {
      return {
        liquidNetWorth: undefined,
      };
    }

    return state.reactHookForm.openJointInvestAccountLiquidNetWorth;
  });

  const { setValue, handleSubmit } = useForm<LiquidNetWorthValues>({
    defaultValues: submittedFormValues,
  });

  const wiz = useOpenInvestJointAccountWizardContext();

  const options = getEnumEntries('LiquidNetWorthEnum');

  const onSubmit = (data: LiquidNetWorthValues) => {
    dispatch(
      submitReactFormData({ openJointInvestAccountLiquidNetWorth: data }),
    );

    wiz.next();
  };

  const handleOptionClick = (name: string) => {
    setValue('liquidNetWorth', name);
    handleSubmit(onSubmit)();
  };

  return (
    <Flex maxWidth={588} flexDirection="column" mx="auto">
      <Box pt={32}>
        <BackArrow content="Back" onClick={() => wiz.back()} />
      </Box>
      <Flex pt={48} mb={32} flexDirection="column">
        <HM content="How much cash do you have available?" />
        <PL pt={16}>
          Include checking, savings, money market, and brokerage account
          balances. Don’t include the value of retirement accounts, real estate,
          businesses, cars, etc.
        </PL>
      </Flex>
      <form onSubmit={handleSubmit(onSubmit)}>
        {options.map(({ name, description }) => (
          <StyledOption
            tabIndex={0}
            key={name}
            onClick={() => handleOptionClick(name)}
            onKeyDown={(event: React.KeyboardEvent) => {
              if (event.key === 'Enter' || event.code === 'Space') {
                handleOptionClick(name);
              }
            }}
          >
            {description}
          </StyledOption>
        ))}
      </form>
    </Flex>
  );
};
