import { Box, styled, css } from '@m1/liquid-react';

export const StyledHeaderContainer = styled(Box)<{
  featureUrl?: string | undefined | null;
}>`
  position: relative;
  display: flex;
  width: 100%;
  height: 19rem;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    background: var(--colors-foreground-neutral-on-light);
    opacity: 0;
  }

  background-image: ${(props) =>
    props.featureUrl ? `url(${props.featureUrl})` : null};
  ${(props) =>
    props.featureUrl
      ? css`
          &::before {
            opacity: 0.5;
          }
        `
      : css`
          background-color: var(--colors-foreground-neutral-on-light);
        `};
`;

export const StyledHeaderWrapper = styled(Box)`
  position: relative;
  display: flex;
  width: 1200px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const StyledModelPortfolioLogo = styled(Box)`
  width: 5.5rem;
  margin-bottom: 0.5rem;
`;

export const StyledPieHighlightContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  display: flex;
  width: 19rem;
  height: 19rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--colors-background-neutral-secondary);
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
  transform-origin: 0 0 0;
  text-align: center;
`;
