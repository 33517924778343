import * as React from 'react';
import { Outlet, Route } from 'react-router-dom';

import { RequireAuthGuard } from '~/router/Authentication/RequireAuthGuard';

import { AcatWizardRoute } from '../../acat-wizard';

import { PieEditorRoute } from './pie-editor/PieEditorRoute';

export const WizardsRoute = () => {
  return (
    <Route
      path="w"
      element={
        <RequireAuthGuard>
          <Outlet />
        </RequireAuthGuard>
      }
    >
      {AcatWizardRoute()}
      {PieEditorRoute()}
    </Route>
  );
};
