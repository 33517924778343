import { ApolloError } from '@apollo/client';
import { Modal, ModalContent, ModalTitle, PL, PM } from '@m1/liquid-react';
import React from 'react';

import {
  useRequestReferralsParticipationMutation,
  useSelectPaymentsAccountMutation,
} from '~/graphql/hooks';
import { SelectPaymentsAccountInput } from '~/graphql/types';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { EXTERNAL_LINKS } from '~/static-constants';
import { useToast } from '~/toasts';
import { Link } from '~/toolbox/link';

import { ChangePaymentAccountForm } from './ChangePaymentAccountForm';
import { GetReferralLinkButton } from './GetReferralLinkButton';

type NoExistingReferralLinkProps = {
  hasInvestAccount: boolean;
};

export const NoExistingReferralLinkContainer = ({
  hasInvestAccount,
}: NoExistingReferralLinkProps) => {
  const { addToast } = useToast();
  const onError = (e: ApolloError) => {
    addToast({
      content: e.message,
      kind: 'alert',
    });
  };

  const [selectPaymentAccount, { loading: paymentAccountLoading }] =
    useSelectPaymentsAccountMutation({
      onError,
      onCompleted: () => requestReferralsParticipation(),
    });

  const [requestReferralsParticipation, { loading: requestReferralLoading }] =
    useRequestReferralsParticipationMutation({
      // If variables is allowed to be empty, this should be nullable on Lens
      variables: {
        input: {},
      },
      onError,
      onCompleted: () => {
        addToast({
          content: 'Referrals opt-in successful.',
          kind: 'success',
        });
      },
    });

  usePortaledSpinner(paymentAccountLoading || requestReferralLoading);

  const onSubmit = (input: SelectPaymentsAccountInput) => {
    if (input.accountId) {
      selectPaymentAccount({
        variables: {
          input,
        },
      });
    }
  };

  return (
    <div>
      {hasInvestAccount ? (
        <Modal trigger={<GetReferralLinkButton />}>
          <ModalContent>
            <ModalTitle>Choose An Account</ModalTitle>
            <PL>
              Where would you like us to send your referral payments? (Don't
              worry, if you want to use a different account later you can always
              change your preferences in Settings.)
            </PL>
            <ChangePaymentAccountForm onSubmit={onSubmit} />
          </ModalContent>
        </Modal>
      ) : (
        <GetReferralLinkButton onClick={requestReferralsParticipation} />
      )}
      <PM
        color="foregroundNeutralMain"
        style={{
          display: 'inline-block',
          marginTop: 16,
        }}
      >
        By clicking "Get My Referral Link" you are accepting the following{' '}
        <Link to={EXTERNAL_LINKS.REFERRAL_PROGRAM_TERMS} target="_blank">
          terms and conditions
        </Link>
      </PM>
    </div>
  );
};
