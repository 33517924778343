import { PL, Card } from '@m1/liquid-react';
import * as React from 'react';

import { AgreementBundleLinkList } from '~/components/AgreementBundleLinkList';

import { useDisclosuresForCreditQuery } from '~/graphql/hooks';
import { Spinner } from '~/toolbox/spinner';

export const DisclosuresForCreditPage = () => {
  const { data, loading } = useDisclosuresForCreditQuery();

  if (loading) {
    return <Spinner />;
  }

  const latestAgreements =
    data?.viewer?.credit?.userAcceptedCreditCardAgreements
      ?.onboardingCreditCardAgreements;
  if (!latestAgreements) {
    return (
      <PL content="Spend Credit Card disclosures are currently unavailable. Please try again later." />
    );
  }
  return (
    <Card p={32}>
      <AgreementBundleLinkList agreementBundle={latestAgreements} />
    </Card>
  );
};
