import { LS, Box, styled } from '@m1/liquid-react';
import * as React from 'react';

import { ClearButton } from './ClearButton';

type TagProps = {
  label: string;
  onClick: (label: string) => void;
};

const StyledTag = styled(Box)`
  margin-bottom: 8px;
  padding: 3px 8px;

  display: flex;
  align-items: center;

  background-color: ${(props) => props.theme.colors.backgroundNeutralTertiary};
  border-radius: 16px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

export const SymbolTag = ({ label, onClick }: TagProps) => (
  <StyledTag onClick={() => onClick(label)}>
    <LS content={label} mr={14} />
    <ClearButton />
  </StyledTag>
);
