import { Button, Flex, HXS, PL } from '@m1/liquid-react';
import * as React from 'react';

import { BankImage } from '~/components/BankImage';
import { GenericSystemError } from '~/components/GenericSystemError';
import { useBankConnectionQuery } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import { useSearchParams } from '~/hooks/useSearchParams';
import { useSupportLink } from '~/hooks/useSupportLink';
import { useSelector } from '~/redux/hooks';
import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

import { filterNilValues } from '~/utils/objects';

import { AvailableFundingSourcesPage } from './AvailableFundingSourcesPage';
import { DefaultBankConnectionPage } from './components/DefaultBankConnectionPage';

export const BankConnectionPage = () => {
  const [searchParams] = useSearchParams();
  const [initialActiveFlow, setInitialActiveFlow] = React.useState('');
  const savingsAccountId = useSelector(
    (state) => state.global.savingsAccountId,
  );
  const activeAccountId = useSelector((state) => state.global.activeAccountId);
  const activeFlow = useSelector((state) => state.global.activeFlow);
  const supportLink = useSupportLink('SUPPORT_BANKING');
  const navigate = useNavigate();

  const queryType = searchParams.get('type');

  const { data, loading } = useBankConnectionQuery();

  // Note on useState below: Attempted to deconstruct this page into three separate routes (Connected FS, Connect New FS, Available FS),
  // but that caused a number of routing issues such as broken deeplinks, broken query params, discrepancies between
  // "type" and "connectionType", etc. I've instead created a tech debt task with details:
  // https://app.asana.com/0/1199373453582180/1205154003120405/f
  const [hasClickedConnectBank, setClickedConnectBank] = React.useState(false);

  let accountId: string | null | undefined;

  React.useEffect(() => {
    if (!initialActiveFlow && activeFlow) {
      // capture the initial activeFlow, so other bank connection pages don't flash when the user routes away
      setInitialActiveFlow(activeFlow);
    }
  }, [setInitialActiveFlow, activeFlow, initialActiveFlow]);

  if (queryType === 'savings') {
    accountId = savingsAccountId;
  } else if (queryType === 'invest') {
    accountId = activeAccountId;
  }

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.viewer) {
    return <GenericSystemError />;
  }

  const { fundingSources, transfers } = data.viewer;

  const isEligibleForFundingSourceUpdate =
    transfers?.isEligibleForFundingSourceUpdate;

  if (transfers?.activeFundingSource?.id && !hasClickedConnectBank) {
    return (
      <Container height="100vh">
        <Flex
          flexDirection="column"
          maxWidth={489}
          mx="auto"
          textAlign="center"
        >
          <BankImage status="verified" />
          {isEligibleForFundingSourceUpdate ? null : (
            <PL mt={32}>
              If you'd like to change your connected bank, you'll need to cancel
              any pending transfers. Please{' '}
              <Link {...supportLink}>contact support</Link> if you have
              questions.
            </PL>
          )}
          <HXS my={32} content="This is your linked account:" />
          <PL mb={32} content={transfers.activeFundingSource?.externalName} />
          <Flex
            flexDirection="column"
            justifyContent="center"
            mx="auto"
            alignContent="center"
          >
            <Button
              kind="primary"
              label="Deposit"
              onClick={() => {
                navigate({
                  to: '/d/c/create-transfer',
                  query: filterNilValues({
                    toParticipantId: accountId,
                    fromParticipantId: transfers.activeFundingSource?.id,
                  }),
                });
              }}
              size="large"
              mb={24}
            />
            {isEligibleForFundingSourceUpdate ? (
              <Button
                kind="link"
                label="Connect a new bank"
                onClick={() => setClickedConnectBank(true)}
              />
            ) : (
              <Link
                to="/d/c/create-transfer"
                query={{
                  toParticipantId: transfers.activeFundingSource?.id,
                  fromParticipantId: accountId,
                }}
              >
                Withdraw funds from M1
              </Link>
            )}
          </Flex>
        </Flex>
      </Container>
    );
  }

  if (fundingSources?.hasFundingSources || hasClickedConnectBank) {
    return <AvailableFundingSourcesPage />;
  }

  return <DefaultBankConnectionPage />;
};
