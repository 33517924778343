import { Flex, styled, theme, css } from '@m1/liquid-react';
import * as React from 'react';

import { useNavigationQuery } from '~/graphql/hooks';
import { useDashboardNavigation } from '~/hooks/useDashboardNavigation';
import { useLayout } from '~/hooks/useLayout';

import { SideNav } from './components/SideNav';
import { TopNav } from './components/TopNav';
import { getInvestAccounts } from './utils/getInvestAccounts';

const landingPageStyles = css`
  background-color: none;
`;

const marketingPageStyles = css`
  background: ${theme.colors.gradientLinearInverse};
  background-color: none;
  margin-top: 0;
  height: unset;
`;

const StyledPageContainer = styled(Flex)<{
  sideNavWidth: number;
  contentWidth: number;
  isMarketingPage?: boolean;
  isLandingPage?: boolean | null;
}>`
  position: relative;
  margin-left: ${({ sideNavWidth }) => `${sideNavWidth}px`};
  flex-direction: column;
  width: 100%;

  /* This must be height: 100vh for sticky elements in portfolio details to work */
  height: 100vh;

  overflow-x: hidden;
  background-color: ${theme.colors.backgroundNeutralSecondary};

  ${({ isMarketingPage, isLandingPage }) => {
    if (isMarketingPage || isLandingPage) {
      return css`
        ${isMarketingPage && marketingPageStyles}
        ${isLandingPage && landingPageStyles}
      `;
    }

    return ``;
  }}
`;
// 100% width to anchor sidebar

const StyledContentContainer = styled(Flex)`
  width: 100%;
  height: 100%;
`;

export type NavigationProps = {
  children: React.ReactElement<any>;
};

export const Navigation = ({ children }: NavigationProps) => {
  const { sideNavWidth, contentWidth, setScrollTop } = useLayout();
  // todo: call hook in components that need it instead of drilling props
  const navSelections = useDashboardNavigation();
  const { data } = useNavigationQuery();
  const ref = React.useRef<HTMLDivElement>(null);
  const viewer = data?.viewer;

  const investAccounts = getInvestAccounts(viewer?.accounts?.edges);
  React.useEffect(() => {
    const scrollableRef = ref.current;
    const updateScrollTop = () => {
      setScrollTop(scrollableRef?.scrollTop ?? 0);
    };

    // Update scroll height on mount
    updateScrollTop();

    // Update scroll height whenever the user scrolls the page
    scrollableRef?.addEventListener('scroll', updateScrollTop);

    // Clean up event listener on unmount
    return () => {
      scrollableRef?.removeEventListener('scroll', updateScrollTop);
    };
  }, [ref.current]);

  const isMarketingPage = navSelections?.activeL3Nav?.includes('marketing');
  const isLandingPage = navSelections?.activeL3Nav?.includes('transactions');

  return (
    <Flex minHeight="100vh" width="100%" margin="auto">
      <SideNav
        firstName={viewer?.profile?.primary?.fullName ?? ''}
        investAccounts={investAccounts}
        selectedNavigation={navSelections}
        data={data}
      />
      <StyledPageContainer
        sideNavWidth={sideNavWidth}
        contentWidth={!isMarketingPage ? 0 : contentWidth}
        isMarketingPage={isMarketingPage}
        isLandingPage={isLandingPage}
        ref={ref}
      >
        <TopNav selectedNavigation={navSelections} />
        <StyledContentContainer>{children}</StyledContentContainer>
      </StyledPageContainer>
    </Flex>
  );
};
