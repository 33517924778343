import { Banner, Flex, styled } from '@m1/liquid-react';
import React from 'react';

import { useGetEmailVerificationStatusQuery } from '~/graphql/hooks';
import { NOTIFICATION_LOCATIONS } from '~/static-constants';

import { ResendEmailVerificationAction } from './ResendEmailVerificationAction';

type EmailVerificationBannerProps = {
  content?: string;
  notificationLocation?: ValueOf<typeof NOTIFICATION_LOCATIONS>;
};

const StyledBanner = styled(Banner)`
  width: 100%;
  max-width: 996px;
`;

export const EmailVerificationBanner = ({
  content,
  notificationLocation,
}: EmailVerificationBannerProps) => {
  const { data } = useGetEmailVerificationStatusQuery({
    fetchPolicy: 'network-only',
  });

  if (!data?.viewer.user || data.viewer.user.isPrimaryEmailVerified) {
    return null;
  }

  return (
    <Flex justifyContent="center">
      <StyledBanner
        action={
          <ResendEmailVerificationAction
            notificationLocation={notificationLocation}
          />
        }
        content={
          content ||
          'To fund your account, you need to confirm your email. Please check your inbox to verify.'
        }
        icon="alert20"
        kind="warning"
        mt={32}
        size="wide"
      />
    </Flex>
  );
};
