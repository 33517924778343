import { Flex } from '@m1/liquid-react';
import React from 'react';

import { TransferParticipantDropdownField } from '~/forms/fields';
import { TransferParticipantTypeEnum } from '~/graphql/types';

type TransferParticipants = {
  label: string;
  value: string;
  type: TransferParticipantTypeEnum;
};

type TransferParticipantSectionProps = {
  transferParticipants: Array<TransferParticipants>;
};

export const TransferParticipantSection = ({
  transferParticipants,
}: TransferParticipantSectionProps) => {
  return (
    <Flex flexDirection="column" justifyContent="center" mx="auto" mt={24}>
      <TransferParticipantDropdownField
        label="From"
        name="fromParticipantId"
        options={transferParticipants}
        disabled={false}
      />
    </Flex>
  );
};
