import { Box, Text, styled, css } from '@m1/liquid-react';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { Divider } from '~/toolbox/divider';

type RadioSize = 'small' | 'standard';

export type RadioChoiceProps = {
  disabled?: boolean;
  label: React.ReactNode;
  size?: RadioSize;
};

type Props = RadioChoiceProps & {
  border?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  checked?: HTMLInputElement['checked'];
  name: string;
  value: string;
};

type StyledInputProps = {
  $disabled: boolean;
  $size: RadioSize;
};

const StyledInput = styled.input<StyledInputProps>`
  position: relative;
  min-width: ${(props) => (props.$size === 'small' ? `14px` : `20px`)};
  height: ${(props) => (props.$size === 'small' ? '14px' : '20px')};
  margin-right: ${(props) => (props.$size === 'small' ? '8px' : '16px')};
  border-radius: 50%;
  cursor: pointer;
  outline: none;

  appearance: none;

  ${(props) => {
    return css`
      border: 2px solid ${props.theme.colors.foregroundNeutralSecondary};
      &:checked {
        background-color: ${props.theme.colors.primary};
        border-color: ${props.theme.colors.primary};
        &::before {
          transform: scale(0.45);
        }
      }

      &:disabled {
        background-color: ${props.theme.colors.foregroundNeutralTertiary};
        border: 2px solid ${props.theme.colors.foregroundNeutralTertiary};
        cursor: not-allowed;
      }

      &::before {
        position: absolute;
        width: 100%;
        height: 100%;

        background-color: ${props.theme.colors.foregroundNeutralInverse};
        border-radius: 50%;
        content: '';
        pointer-events: none;

        transition: transform 0.1s ease-in-out;
        transform: scale(1);
      }
    `;
  }};
`;

type StyledLabelProps = {
  $size: RadioSize;
};
const StyledLabel = styled.label<StyledLabelProps>`
  position: relative;
  padding: 2px 0;

  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  ${(props) => {
    if (props.$size === 'small') {
      return css`
        &:hover {
          color: ${props.theme.colors.foregroundNeutralTertiary};
        }
        &:not(:last-child) {
          margin-bottom: 4px;
        }
      `;
    }

    if (props.$size === 'standard') {
      return css`
        &:not(:last-child) {
          margin-bottom: 14px;
        }
      `;
    }
  }};
`;
export const RadioChoice = (props: Props) => {
  const { label, size = 'standard', border, disabled = false, ...rest } = props;
  const { register } = useFormContext();

  return (
    <>
      {border && <Divider spacing="compact" />}
      <StyledLabel $size={size || 'standard'}>
        <StyledInput
          {...rest}
          {...register(props.name)}
          $disabled={disabled}
          $size={size}
          disabled={disabled}
          type="radio"
        />
        {typeof label === 'string' ? (
          <Text content={label} font={size === 'small' ? 'LS' : 'LL'} />
        ) : (
          <Box>{label}</Box>
        )}
      </StyledLabel>
    </>
  );
};
