import {
  Box,
  Flex,
  HM,
  HXXS,
  PL,
  styled,
  TwoColumn6by6,
} from '@m1/liquid-react';
import {
  Illustration,
  type IllustrationsType,
} from '@m1/liquid-react/illustrations';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { ControlledInput } from '~/components/form/ControlledInput';
import { PasswordInput } from '~/components/form/PasswordInput';
import { GenericSystemError } from '~/components/GenericSystemError';
import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import { FormMockerReactHookForm } from '~/forms/FormMockers';
import { email as emailValidator, securePassword } from '~/forms/validators';
import { useSignUpWithPromotionQuery } from '~/graphql/hooks';
import {
  AppDisclosureLinkFragment,
  RequiredDocument,
  RichTextFragment,
} from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { useSearchParams } from '~/hooks/useSearchParams';
import { AppDisclosureLink } from '~/lens-toolbox/AppDisclosureLink/AppDisclosureLink';
import { RichText } from '~/lens-toolbox/RichText/RichText';
import { AppState } from '~/redux';
import { submitRegisterUserForm } from '~/redux/actions';
import { useSelector } from '~/redux/hooks';
import { FIELD_MAX_LENGTHS } from '~/static-constants';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

const Content = styled(Flex)`
  background: ${({ theme }) => theme.colors.gradientLinearFeature};
  align-items: center;
  flex-direction: column;
  padding-bottom: 64px;
  padding-top: 64px;
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.XSMALL}) {
    padding-top: 159px;
    height: 100%;
  }
`;

export const FormContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.backgroundNeutralSecondary};
  padding: 24px 48px;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.XSMALL}) {
    padding-top: 88px;
  }
`;

const StyledBackgroundImage = styled(Illustration)`
  position: absolute;
  inset: 0;
  z-index: 0;
  margin: auto;
`;

const StyledIllustration = styled(Illustration)`
  position: relative;
  z-index: 1;
`;

export const StyledAppDisclosureLink = styled(AppDisclosureLink)`
  margin-top: 16px;
  padding-top: 8px;
  padding-bottom: 8px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XXSMALL}) {
    margin-top: 24px;
  }
`;

const StyledColumn1 = styled(TwoColumn6by6.Column1)`
  height: unset;
`;

export interface PromotionSignUpFormValues {
  username: string;
  password: string;
}
// TODO: How to type check props of the factory-created component?
export const PromotionSignupPage = (props: any) => {
  const { data: queryData, loading } = useSignUpWithPromotionQuery({
    variables: {
      promoCode: props.promoCode,
    },
    skip: Boolean(props.promoCode) === false,
  });
  const [searchParams] = useSearchParams();
  const queryStep = searchParams.get('step');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitted, isDirty, isValid },
  } = useForm<PromotionSignUpFormValues>();

  const isLoggedIn = useSelector((state: AppState) =>
    Boolean(state.auth.accessToken),
  );

  React.useEffect(() => {
    // If the user is actually logged in, reroute them to home page
    if (
      !isDirty &&
      isLoggedIn &&
      !isSubmitted &&
      Boolean(queryStep) === false
    ) {
      navigate({ to: '/d/home' });
    }
  }, [isLoggedIn, isDirty, isSubmitted, queryStep, navigate]);

  const onSubmit: SubmitHandler<PromotionSignUpFormValues> = (data) => {
    const promoCodeBundleSignature =
      queryData?.viewer?.unauthenticated?.promotions?.signUpPage
        ?.termsAndConditions?.signature;
    const input = {
      ...data,
      promoCodeBundleSignature,
      promoCode: props.promoCode,
    };
    dispatch(submitRegisterUserForm(input));

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  if (loading) {
    return <Spinner fullScreen />;
  }

  const signUpPageContent =
    queryData?.viewer.unauthenticated?.promotions?.signUpPage;
  const termsAndConditions = signUpPageContent?.termsAndConditions;
  const illustration =
    signUpPageContent?.illustration?.names?.[0] ?? 'phoneDevice';
  const marketingContent = signUpPageContent?.marketingContent;

  if (
    Boolean(props.promoCode) === false ||
    !signUpPageContent ||
    !termsAndConditions
  ) {
    return <GenericSystemError height="100%" />;
  }

  return (
    <TwoColumn6by6 reverseMobileColumns minHeight="100vh">
      <StyledColumn1 backgroundColor="gradientLinearFeature">
        <Content>
          <StyledBackgroundImage name="cryptoWaveBackground" />
          <StyledIllustration name={illustration as IllustrationsType} />

          <RichText
            richText={marketingContent?.[0] as RichTextFragment}
            textProps={{
              font: 'HXS',
              fontWeight: 300,
              color: 'foregroundNeutralOnDark',
              textAlign: 'center',
              maxWidth: 404,
              mt: 32,
            }}
          />
        </Content>
      </StyledColumn1>
      <TwoColumn6by6.Column2>
        <FormContainer>
          <Flex flexDirection="column" maxWidth={588}>
            <HM content={signUpPageContent.header} fontWeight={300} mb={24} />
            <HXXS content="Email and password" />
            <PL mt={8} content="You'll use these to log in to M1." />
            <form onSubmit={handleSubmit(onSubmit)}>
              <ControlledInput
                name="username"
                rules={{
                  required: 'Required',
                  validate: (value = '') => {
                    if (!value) {
                      return undefined;
                    }
                    const errorMessage = emailValidator(value);
                    return errorMessage ?? undefined;
                  },
                }}
                label="Email"
                control={control}
                error={errors.username?.message}
              />
              <PasswordInput
                name="password"
                rules={{
                  required: 'Required',
                  validate: (value = '') => {
                    if (!value) {
                      return undefined;
                    }
                    const errorMessage = securePassword(value);
                    return errorMessage ?? undefined;
                  },
                }}
                maxLength={FIELD_MAX_LENGTHS.DEFAULT}
                label="Password"
                control={control}
                error={errors.password?.message}
              />
              {signUpPageContent.disclosures &&
                signUpPageContent.disclosures.map(
                  (disclosure: Maybe<AppDisclosureLinkFragment>) =>
                    disclosure && (
                      <StyledAppDisclosureLink
                        key={`${disclosure.linkText}`}
                        appDisclosureLink={disclosure}
                        font="PS"
                        fontWeight={400}
                      />
                    ),
                )}
              <Box mt={40}>
                <HXXS
                  content="Agreements and disclosures"
                  fontWeight={600}
                  mb={8}
                />
                <Flex flexDirection="column">
                  {termsAndConditions.documents?.map(
                    (document: RequiredDocument) => (
                      <Box key={document.url} pb={8}>
                        <Link
                          my="8px"
                          key={document.title}
                          underline
                          fontWeight={400}
                          style={{
                            width: 'fit-content',
                          }}
                          to={document.url}
                          target="_blank"
                        >
                          {document.title}
                        </Link>
                      </Box>
                    ),
                  )}
                  <Box my={24}>
                    {termsAndConditions.richPostamble &&
                      termsAndConditions.richPostamble.map(
                        (content: Maybe<RichTextFragment>, i: number) =>
                          content && (
                            <Flex mt={i > 0 ? 8 : 0} key={i}>
                              <RichText
                                textProps={{
                                  color: 'foregroundNeutralMain',
                                  fontSize: '12px',
                                }}
                                richText={content}
                              />
                            </Flex>
                          ),
                      )}
                  </Box>
                </Flex>
              </Box>
              <Box mb={48}>
                <ResponsiveButton
                  type="submit"
                  size="large"
                  disabled={!isValid}
                  label={signUpPageContent.primaryCtaLabel || 'Join M1'}
                  kind="primary"
                />
                <FormMockerReactHookForm
                  setValue={setValue}
                  fields={[
                    {
                      name: 'username',
                      value: `promo${Math.floor(Math.random() * 1000000)}@example.com`,
                    },
                    {
                      name: 'password',
                      value: 'Test123456',
                    },
                  ]}
                />
              </Box>
            </form>
          </Flex>
        </FormContainer>
      </TwoColumn6by6.Column2>
    </TwoColumn6by6>
  );
};
