import { Button, PL, PS } from '@m1/liquid-react';
import * as React from 'react';
import { InjectedFormProps, FormSection } from 'redux-form';

import { FormMocker } from '~/forms/FormMockers/FormMocker';
import { OnlineAccountRegistrationEnum } from '~/graphql/types';
import { connectForm } from '~/hocs';
import { Grid } from '~/toolbox/grid';
import { getEnumEntries } from '~/utils';

import {
  AddressFields,
  CountryOfCitizenshipField,
  DateOfBirthField,
  DropdownField,
  SsnField,
  TextField,
} from './fields';
import { date, required, printableAsciiChars } from './validators';

const CustodialContactInfoFormComponent = ({
  handleSubmit,
}: InjectedFormProps<any>) => {
  const suffixSource = [
    {
      description: 'None',
      name: '__NONE__',
    },
    ...getEnumEntries('NameSuffixEnum'),
  ];
  return (
    <form onSubmit={handleSubmit}>
      <PL fontWeight={600} content="Personal Information" />

      <Grid.Row>
        <Grid.Col sm={9} xs={12}>
          <TextField
            name="firstName"
            label="First name"
            maxLength={30}
            validate={[required, printableAsciiChars]}
          />
        </Grid.Col>
        <Grid.Col>
          <TextField
            name="middleInitial"
            label="Middle initial (Opt.)"
            maxLength={1}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col sm={9} xs={12}>
          <TextField
            name="lastName"
            label="Last name"
            maxLength={30}
            validate={[required, printableAsciiChars]}
          />
        </Grid.Col>
        <Grid.Col>
          <DropdownField
            name="suffix"
            source={suffixSource}
            placeholder="Suffix"
            // @ts-expect-error - TS7006 - Parameter 'v' implicitly has an 'any' type.
            parse={(v) => {
              return v && v !== '__NONE__' ? v : null;
            }}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col sm={6} xs={12}>
          <Grid.Row>
            <Grid.Col xs={12}>
              <DateOfBirthField
                validate={
                  CustodialContactInfoFormComponent.custodialDatOfBirthValidation
                }
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col xs={12}>
              <SsnField />
              <PS
                color="foregroundNeutralMain"
                content="We are legally required to ask for the minor's SSN. We use this to verify their identity but do not perform a credit check."
              />
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
        <Grid.Col>
          <CountryOfCitizenshipField
            accountRegistration={OnlineAccountRegistrationEnum.Custodial}
          />
        </Grid.Col>
      </Grid.Row>
      <PL fontWeight={600} content="Legal Residence" mt={32} />
      <FormSection name="legalResidence">
        <AddressFields kind="CUSTODIAL" />
      </FormSection>

      <Grid.Row
        style={{
          paddingTop: 64,
        }}
      >
        <Grid.Col xs={12}>
          <Button type="submit" kind="primary" size="large" label="Next" />
          <FormMocker
            formName="custodial-contact-info"
            fields={[
              {
                name: 'firstName',
                value: 'j0n',
              },
              {
                name: 'lastName',
                value: 'sn0w',
              },
              {
                name: 'dateOfBirth',
                value: '01/01/2020',
              },
              {
                name: 'ssn',
                value: '123456789',
              },
              {
                name: 'countryOfCitizenship',
                value: 'USA',
              },
              {
                name: 'legalResidence.lineOne',
                value: '123 Main St',
              },
              {
                name: 'legalResidence.city',
                value: 'Chicago',
              },
              {
                name: 'legalResidence.stateOrProvince',
                value: 'IL',
              },
              {
                name: 'legalResidence.postalCode',
                value: '60647',
              },
            ]}
          />
        </Grid.Col>
      </Grid.Row>
    </form>
  );
};

CustodialContactInfoFormComponent.custodialDatOfBirthValidation = [
  date({
    rejectFutureDateOfBirth: true,
    checkCustodialAccountAge: true,
  }),
  required,
  printableAsciiChars,
];

export const CustodialContactInfoForm = connectForm({
  form: 'custodial-contact-info',
  destroyOnUnmount: false,
})(CustodialContactInfoFormComponent);
