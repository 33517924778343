import { LM, Flex } from '@m1/liquid-react';
import * as React from 'react';

import { readPieTreeByPath } from '~/pie-trees';
import { useDispatch, useSelector } from '~/redux/hooks';
import { Checkbox } from '~/toolbox/checkbox';

type SelectorResponseShape = {
  allSliceIds: Array<string>;
  selectedSliceIds: Array<string>;
};

export const SelectAllCheckbox = () => {
  const dispatch = useDispatch();

  const [isSelectAllChecked, setIsSelectAllChecked] = React.useState(false);

  const { allSliceIds, selectedSliceIds } = useSelector<SelectorResponseShape>(
    (state) => {
      const { slices } = readPieTreeByPath(
        state.portfolioOrganizer.pieTree,
        state.portfolioOrganizer.path,
      );

      return {
        allSliceIds: slices ? slices.map((slice) => slice.to.__id) : [],
        selectedSliceIds: slices
          ? slices.reduce((result, { to }) => {
              if (to.__checked) {
                // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'never'.
                result.push(to.__id);
              }
              return result;
            }, [])
          : [],
      };
    },
  );

  const areAllSlicesChecked = Boolean(
    selectedSliceIds.length && selectedSliceIds.length === allSliceIds.length,
  );

  const onSelectAllCheckboxChange = React.useCallback(() => {
    if (areAllSlicesChecked) {
      dispatch({
        type: 'REMOVE_PORTFOLIO_ORGANIZER_ACTIVE_SLICE_IDS',
        payload: {
          ids: allSliceIds,
        },
      });
    } else {
      dispatch({
        type: 'UPDATE_PORTFOLIO_ORGANIZER_ACTIVE_SLICE_IDS',
        payload: {
          ids: allSliceIds,
        },
      });
    }

    setIsSelectAllChecked(!isSelectAllChecked);
  }, [areAllSlicesChecked, isSelectAllChecked, dispatch, allSliceIds]);

  return (
    <Flex ml={4}>
      <Checkbox
        checked={areAllSlicesChecked}
        onChange={onSelectAllCheckboxChange}
        label={<LM content="Select all" pl={8} />}
        size="large"
      />
    </Flex>
  );
};
