import * as React from 'react';
import { Route } from 'react-router-dom';

import { RewardsSingularProgramPage } from './RewardsSingularProgramPage';

export const RewardsSingularProgram = () => {
  return (
    <Route
      path="rewards/program/:programType/:programId"
      element={<RewardsSingularProgramPage />}
      handle={{
        fallbackRoute: {
          to: '/d/spend/credit/rewards',
        },
      }}
    />
  );
};
