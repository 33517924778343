import { TrustedContact, TrustedContactInput } from '~/graphql/types';
import {
  IDENTITY_FIRST_FLOW_STEPS,
  IDENTITY_FIRST_FLOW_STEP_VALUES,
  INVEST_ONBOARDING_FLOW_STEPS,
  INVEST_ONBOARDING_FLOW_STEP_VALUES,
} from '~/static-constants';
import { formatPhoneNumber } from '~/utils/formatting';

export type ConfirmationSection = 'financial' | 'invest';
export const getStepFromSectionClicked = (
  sectionClicked: ConfirmationSection,
): IDENTITY_FIRST_FLOW_STEP_VALUES | INVEST_ONBOARDING_FLOW_STEP_VALUES => {
  switch (sectionClicked) {
    case 'financial':
      return IDENTITY_FIRST_FLOW_STEPS.EXPLAIN_PROFILE_COLLECTION;
    case 'invest':
      return INVEST_ONBOARDING_FLOW_STEPS.COLLECT_INVESTMENT_EXPERIENCE;
    default:
      return IDENTITY_FIRST_FLOW_STEPS.COLLECT_INVESTMENT_EXPERIENCE;
  }
};

export const accountTypeFromAccountRegistrationMapping = {
  CRYPTO: null,
  CUSTODIAL: null,
  JOINT: null,
  INDIVIDUAL: null,
  ROTH_IRA: 'Roth IRA',
  SEP_IRA: 'SEP IRA',
  TRADITIONAL_IRA: 'Traditional IRA',
};

export const formatTrustedContactAsAnArray = (
  trustedContact: TrustedContact | TrustedContactInput,
): string[] => {
  return [
    `${trustedContact.firstName} ${trustedContact.lastName}`,
    trustedContact.email || '',
    formatPhoneNumber(trustedContact.phoneNumber),
  ];
};
