export const PieColorSchemes = {
  PRIMARY_COLORS: 'PRIMARY' as const,
  GRAYSCALE_COLORS: 'GRAYSCALE' as const,
};

export type PieColorScheme = ValueOf<typeof PieColorSchemes>;

export type SliceData = {
  id: string | null | undefined;
  isPlaceholder?: boolean;
  percentage: number;
};

export type D3PieDatum<T> = {
  data: T;
  endAngle: number;
  index: number;
  padAngle: number;
  startAngle: number;
  value: number;
};

export type D3Arc<T> = (datum: D3PieDatum<T>) => {
  centroid: () => [number, number];
  context: (...args: Array<any>) => any;
  cornerRadius: (
    arg: ((...args: Array<any>) => any) | number | null,
  ) => (...args: Array<any>) => any;
  endAngle: (
    arg: ((...args: Array<any>) => any) | number | null,
  ) => (...args: Array<any>) => any;
  innerRadius: (
    arg: ((...args: Array<any>) => any) | number | null,
  ) => (...args: Array<any>) => any;
  length: number;
  name: string;
  outerRadius: (
    arg: ((...args: Array<any>) => any) | number | null,
  ) => (...args: Array<any>) => any;
  padAngle: (
    arg: ((...args: Array<any>) => any) | number | null,
  ) => (...args: Array<any>) => any;
  padRadius: (
    arg: ((...args: Array<any>) => any) | number | null,
  ) => (...args: Array<any>) => any;
  prototype: Record<string, any>;
  startAngle: (
    arg: ((...args: Array<any>) => any) | number | null,
  ) => (...args: Array<any>) => any;
};
