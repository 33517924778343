import { Box } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import React, { forwardRef } from 'react';

import { Action, ActionProps } from '../Action';

export const Handle = forwardRef<HTMLButtonElement, ActionProps>(
  (props, ref) => {
    return props.hidden ? (
      <Box p={15} css={{ opacity: 0 }} />
    ) : (
      <Action
        ref={ref}
        cursor="grab"
        data-cypress="draggable-handle"
        {...props}
      >
        <Icon name="drag20" color="foregroundNeutralMain" />
      </Action>
    );
  },
);
