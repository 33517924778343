import { Button, PM } from '@m1/liquid-react';
import * as React from 'react';

type ConfirmationScreenStepProps = {
  onFinishStep: () => void;
};

export const ConfirmationScreenStep = ({
  onFinishStep,
}: ConfirmationScreenStepProps) => (
  <>
    <PM mb={32} content="Confirmation Screen Step" />
    <Button label="Continue" onClick={onFinishStep} size="large" />
  </>
);
