import { Box } from '@m1/liquid-react';

import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { SavingsOnboardingFlow } from '~/flows';
import { useNavigate } from '~/hooks/useNavigate';

export const SavingsOnboardingPage = () => {
  const navigate = useNavigate();
  const handleFinish = (): void => {
    navigate({
      to: '/d/spend/savings/transactions',
      options: { state: { forceRefetch: true } },
    });
  };

  return (
    <PageErrorBoundary onClickRetry={handleFinish} retryButtonLabel="Exit">
      <Box backgroundColor="backgroundNeutralSecondary">
        <SavingsOnboardingFlow
          basePath="onboarding/savings-onboarding"
          onFinish={handleFinish}
        />
      </Box>
    </PageErrorBoundary>
  );
};
