import { Box, Flex, HS, PM, styled, Card } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { SelectableCard } from '~/components/cards';
import { GenericSystemError } from '~/components/GenericSystemError';
import { SecurityLogo } from '~/components/SecurityLogo';
import { useUnmanagedHoldingsSelectParentPiePageQuery } from '~/graphql/hooks';
import { ValidSliceDestinationFragment } from '~/graphql/types';
import { useLocation } from '~/hooks/useLocation';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { UnmanagedHoldingsSelectParentPieContinueButton } from './components/UnmanagedHoldingsSelectParentPieContinueButton';

const StyledParentPieContainer = styled(Flex)`
  overflow-y: scroll;
`;

const StyledSectionHeader = styled(Flex)`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderMain};
`;

export const UnmanagedHoldingsSelectParentPiePage = () => {
  const {
    state: { newPie, previousPieEditorConfig },
  } = useLocation();
  const accountId = useSelector((state) => state.global.activeAccountId);
  const [selectedRow, setSelectedRow] =
    React.useState<null | ValidSliceDestinationFragment>(null);

  const { data, loading, error } = useUnmanagedHoldingsSelectParentPiePageQuery(
    {
      variables: {
        accountId: accountId as string,
      },
      skip: !accountId,
    },
  );

  if (loading) {
    return (
      <Box p={16}>
        <Spinner fullScreen />
      </Box>
    );
  }

  // Some catastrophic error occurred
  if (
    error ||
    !data?.node ||
    data.node.__typename !== 'Account' ||
    !data.node.receivedSecurities ||
    !newPie ||
    !previousPieEditorConfig
  ) {
    return <GenericSystemError />;
  }

  const receivedSecurities = data.node.receivedSecurities;

  if (
    !receivedSecurities.moveSecuritiesNewPieLocation ||
    !receivedSecurities.moveSecuritiesConfirmTargets
  ) {
    return null;
  }

  const { moveSecuritiesConfirmTargets, moveSecuritiesNewPieLocation } =
    receivedSecurities;

  const { title, validPortfolioSliceDestinations: validDestinations } =
    moveSecuritiesNewPieLocation;

  return (
    <Flex flexDirection="column" mx="auto" maxWidth={550}>
      <BackArrow
        content="Set targets"
        mt={32}
        mb={16}
        state={{
          event: {
            type: 'INITIALIZE_ORGANIZER',
            initConfig: {
              ...previousPieEditorConfig,
              mode: 'EDIT_PIE',
              pieId: newPie.id,
            },
          },
        }}
        to="/d/invest/portfolio-organizer"
      />
      <HS content={title} mb={32} />
      <Card mb={32}>
        <StyledSectionHeader alignItems="center" mb={16} py={16} px={32}>
          <SecurityLogo content={<Icon name="createPie32" />} />
          <PM>{newPie.name}</PM>
        </StyledSectionHeader>
        <Flex flexDirection="column">
          <StyledParentPieContainer flexDirection="column" maxHeight={320}>
            {validDestinations?.map(
              (destination, index) =>
                destination && (
                  <SelectableCard
                    icon={
                      <SecurityLogo content={<Icon name="createPie32" />} />
                    }
                    isSelected={selectedRow?.id === destination.id}
                    key={index}
                    my={16}
                    mx={64}
                    p={16}
                    onClick={() => setSelectedRow(destination)}
                    title={destination.to.name}
                  />
                ),
            )}
          </StyledParentPieContainer>
        </Flex>
        <Flex justifyContent="center" mt={16} mb={32}>
          <UnmanagedHoldingsSelectParentPieContinueButton
            label="Continue"
            previousPieEditorConfig={previousPieEditorConfig}
            moveSecuritiesConfirmTargets={moveSecuritiesConfirmTargets}
            newPie={newPie}
            selectedRow={selectedRow}
          />
        </Flex>
      </Card>
    </Flex>
  );
};
