import { Box, Flex, HM, PXL } from '@m1/liquid-react';
import * as React from 'react';

import { CollectPhoneVerificationForm } from '~/components/phoneVerification/components/CollectPhoneVerificationForm';

export const CollectPhoneNumber = () => {
  return (
    <Flex flexDirection="column">
      <Box>
        <HM content="Welcome to M1. Let’s begin by making sure it’s you." />
        <PXL content="We’ll send your phone a confirmation code." mt={24} />
      </Box>
      <CollectPhoneVerificationForm
        style={{
          marginTop: 16,
        }}
      />
    </Flex>
  );
};
