import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { useLayout } from '~/hooks/useLayout';
import { enteredPage, exitedPage } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

import { StyledPageContent } from '../navigation/Navigation.styled';

export const ResearchLayout = () => {
  const dispatch = useDispatch();
  const { contentWidth } = useLayout();
  React.useEffect(() => {
    dispatch(enteredPage('RESEARCH', undefined));

    return () => dispatch(exitedPage('RESEARCH', undefined));
  }, []);

  return (
    <StyledPageContent contentWidth={contentWidth}>
      <PageErrorBoundary>
        <Outlet />
      </PageErrorBoundary>
    </StyledPageContent>
  );
};
