import { useCallback } from 'react';

import { useStartCustomerSupportConversationMutation } from '~/graphql/hooks';
import { useToast } from '~/toasts';
import { isNil } from '~/utils';

import { useSentry } from './useSentry';

export const useIntercomConversation = () => {
  const sentry = useSentry();
  const toast = useToast();

  const [startConversation] = useStartCustomerSupportConversationMutation();

  const showErrorToast = useCallback(
    (errorMessageToLogs?: string) => {
      if (errorMessageToLogs) {
        sentry.message(errorMessageToLogs, null);
      }
      toast.addToast({
        content:
          'Unable to start Support conversation. Please try again later.',
        duration: 'short',
        kind: 'alert',
      });
    },
    [sentry, toast],
  );

  const handleConversation = useCallback(
    (url: URL, event?: React.KeyboardEvent | React.MouseEvent) => {
      if (isNil(window.Intercom)) {
        // If intercom is not available, we can't do any custom handling.
        // Continue with default link behavior.
        return;
      }
      // If we have intercom, prevent default and do custom logic.
      event?.preventDefault();
      const workflow = url.searchParams.get('workflow');
      if (!workflow) {
        // If we don't have a workflow ID, open an empty chat window.
        window.Intercom('showNewMessage');
      } else {
        toast.addToast({
          content: 'Starting Support conversation...',
          duration: 'short',
          kind: 'informational',
        });
        startConversation({
          onCompleted: (data) => {
            const outcome = data.startCustomerSupportConversation?.outcome;
            if (isNil(outcome)) {
              // This shouldn't happen. If didSucceed: true, we should have an outcome.
              // If didSucceed: false, we should go to onError instead.
              showErrorToast(
                'startCustomerSupportConversation response missing conversation ID.',
              );
            } else {
              const conversationId = outcome.externalConversationId;
              window.Intercom('showConversation', conversationId);
            }
          },
          onError: () => showErrorToast(),
          variables: {
            input: {
              workflow,
            },
          },
        });
      }
    },
    [showErrorToast, startConversation, toast],
  );

  return handleConversation;
};
