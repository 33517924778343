import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { Logo } from '~/toolbox/logo';

type UserPieLogoProps = {
  portfolioLinks?: { isRootSlice?: boolean }[];
};
export const UserPieLogo = ({ portfolioLinks }: UserPieLogoProps) => {
  const starred = portfolioLinks?.some((link) => link.isRootSlice);

  return (
    <Logo
      placeholder=""
      starred={starred}
      content={
        <Icon
          backgroundColor="backgroundNeutralMain"
          borderRadius="20%"
          name="myPies20"
        />
      }
    />
  );
};
