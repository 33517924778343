import { isPieDescriptionValid } from './isPieDescriptionValid';
import { isPieNameValid } from './isPieNameValid';
import { Pie, Slice } from './types';

/*
  Validity checks (done for every pie in the tree with slices, recursively)
  - Each Pie has between 1 and 100 slices, inclusive.
  - Each Pie slice allocations total 100.
  - Each Pie can have a sliceable appear at most once.
*/
export const isPieTreeValid = (pie: Pie): boolean => {
  // TODO: Determine what we should do here.
  if (!pie.slices) {
    return true;
  }

  if (pie.slices.length < 1 || pie.slices.length > 100) {
    return false;
  }

  // Check the slice weight allocations
  if (sumSliceListPercentages(pie.slices) !== 100) {
    return false;
  }

  // Check our pie name and description
  if (
    pie.type === 'new_pie' ||
    (pie.type === 'old_pie' && pie.__typename === 'USER_PIE')
  ) {
    if (
      !isPieNameValid(pie.name, true) ||
      !isPieDescriptionValid(pie.description)
    ) {
      return false;
    }
  }

  // Purely to make Flow happy. sumSliceListPercentages does not operate
  // on the slice list.
  if (!pie.slices) {
    return true;
  }

  // Check for duplicates, inactives and recurse if necessary
  const sliceIdMap = new Set();
  for (const slice of pie.slices) {
    if (sliceIdMap.has(slice.to.__id)) {
      return false;
    }

    sliceIdMap.add(slice.to.__id);

    if (
      slice.to.type === 'old_pie' ||
      (slice.to.type === 'new_pie' && pie.slices)
    ) {
      return isPieTreeValid(slice.to);
    }
  }

  return true;
};

const sumSliceListPercentages = (slices: Array<Slice>): number => {
  return slices.reduce((p, v) => p + v.percentage, 0);
};
