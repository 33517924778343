import * as React from 'react';

import { CollectContactInfoStep } from './CollectContactInfoStep';

export const CollectSecondaryContactInfoStep = () => (
  <CollectContactInfoStep
    holder="secondary"
    title="Secondary Applicant Information"
    body={
      <>
        Joint investment accounts have both a primary and secondary account
        holder. Please input the details of the <b>secondary account holder</b>{' '}
        below.
      </>
    }
    showPhoneNumber
    skipAddressAutofill
  />
);
