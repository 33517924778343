import {
  InvestmentExperienceEnum,
  LiquidityNeedsEnum,
  RiskToleranceEnum,
  TimeHorizonEnum,
} from '~/graphql/types';

import { EnumDropdown } from './types';

export const InvestmentExperienceEnumDropdownOptions: EnumDropdown<InvestmentExperienceEnum> =
  [
    {
      'name': InvestmentExperienceEnum.None,
      'description': 'None',
    },
    {
      'name': InvestmentExperienceEnum.Limited,
      'description': 'Limited',
    },
    {
      'name': InvestmentExperienceEnum.Good,
      'description': 'Good',
    },
    {
      'name': InvestmentExperienceEnum.Extensive,
      'description': 'Extensive',
    },
  ];
export const RiskToleranceEnumDropdownOptions: EnumDropdown<RiskToleranceEnum> =
  [
    {
      'name': RiskToleranceEnum.Low,
      'description': 'Low',
    },
    {
      'name': RiskToleranceEnum.Medium,
      'description': 'Medium',
    },
    {
      'name': RiskToleranceEnum.High,
      'description': 'High',
    },
  ];
export const TimeHorizonEnumDropdownOptions: EnumDropdown<TimeHorizonEnum> = [
  {
    'name': TimeHorizonEnum.Short,
    'description': 'Short (Less than 3 years)',
  },
  {
    'name': TimeHorizonEnum.Average,
    'description': 'Average (4 to 7 years)',
  },
  {
    'name': TimeHorizonEnum.Longest,
    'description': 'Long (8 years or more)',
  },
];
export const LiquidityNeedsEnumDropdownOptions: EnumDropdown<LiquidityNeedsEnum> =
  [
    {
      'name': LiquidityNeedsEnum.VeryImportant,
      'description': 'Very Important',
    },
    {
      'name': LiquidityNeedsEnum.SomewhatImportant,
      'description': 'Somewhat Important',
    },
    {
      'name': LiquidityNeedsEnum.NotImportant,
      'description': 'Not Important',
    },
  ];
