import { Box, Button, Flex, HXS, HXXS } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { useAccountCashBalanceControlQuery } from '~/graphql/hooks';
import { clickedOrderDirectionToggle, showModal } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

type BlockOrderStepProps = {
  onFinishStep: (...args: Array<any>) => any;
};

export const BlockOrderStep = (props: BlockOrderStepProps) => {
  const { onFinishStep } = props;
  const [isAutoInvestActive, setIsAutoInvestActive] = React.useState(false);
  const { accountId, modal } = useSelector((state: any) => ({
    accountId: state.global.activeAccountId,
    modal: state.modals.CASH_CONTROL,
  }));

  const { loading, data, refetch } = useAccountCashBalanceControlQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: {
      id: accountId,
    },
  });

  React.useEffect(() => {
    if (
      data?.node?.__typename === 'Account' &&
      data?.node?.maxCashThreshold !== null &&
      isAutoInvestActive === false
    ) {
      setIsAutoInvestActive(true);
    } else if (
      data?.node?.__typename === 'Account' &&
      data?.node?.maxCashThreshold === null &&
      isAutoInvestActive === true
    ) {
      onFinishStep('BACK');
    } else if (modal.isOpened === false && loading === false) {
      refetch();
    }
  }, [
    modal.isOpened,
    data,
    isAutoInvestActive,
    loading,
    onFinishStep,
    refetch,
  ]);

  const dispatch = useDispatch();

  function goBack() {
    if (isAutoInvestActive) {
      // set toggle back to `buy` if auto invest wasn't turned off and back button clicked
      dispatch(clickedOrderDirectionToggle('buy'));
    }
    window.history.back();
  }

  function openAutoInvestModal() {
    dispatch(
      showModal('CASH_CONTROL', {
        shouldDisplayNotification: false,
        hideCashMinimumInput: true,
      }),
    );
  }

  return (
    <Flex
      flexDirection="column"
      alignItems="stretch"
      margin="0 auto"
      padding={32}
      maxWidth={490}
    >
      <Flex mt={20} mb={20} justifyContent="center">
        <Illustration name="announcements" />
      </Flex>
      <Box mt={20}>
        <HXS fontWeight={600}>Before you can sell</HXS>
      </Box>
      <Box mt={20}>
        <HXXS fontWeight={300}>
          We put all sale proceeds off to the side in your portfolio to await
          your next move. We can only do this if you turn off auto-invest.
        </HXXS>
      </Box>
      <Box mt={40}>
        <Button
          onClick={openAutoInvestModal}
          label="Turn off auto-invest"
          size="large"
          fullWidth
        />
      </Box>
      <Box mt={12}>
        <Button
          onClick={goBack}
          kind="inverse-secondary"
          label="Back"
          size="large"
          fullWidth
        />
      </Box>
    </Flex>
  );
};
