import { useLaunchDarkly } from './useLaunchDarkly';

export const useNewPieEditor = () => {
  const { didSucceed, flagResult } = useLaunchDarkly('new-pie-editor', false);
  const newPieEditorFlag = Boolean(didSucceed && flagResult);

  return {
    showNewPieEditor: newPieEditorFlag,
  };
};
