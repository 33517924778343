import * as React from 'react';
import { Route } from 'react-router-dom';

import { TransferRuleDetailsPage } from './TransferRuleDetailsPage';

export const TransferRuleDetailsRoute = () => {
  return (
    <Route
      path="rule-details/:transferRuleId"
      element={<TransferRuleDetailsPage />}
      handle={{
        fallbackRoute: {
          to: '/d/transfers',
        },
      }}
    />
  );
};
