import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { IdentityFirstFlow } from '~/flows';
import { useSearchParams } from '~/hooks/useSearchParams';

import { useSelector } from '~/redux/hooks';
import { Container } from '~/toolbox/container';

export const IdentityFirstWrapper = () => {
  const [queryParams] = useSearchParams();
  const phone = useSelector((state) => state.newFlows.onboarding.phone);

  const basePath = 'onboarding/setup-account';
  const registration = queryParams.get('registration');

  return (
    <Container>
      <Box mt={32}>
        <IdentityFirstFlow
          basePath={basePath}
          phone={phone}
          registration={registration}
        />
      </Box>
    </Container>
  );
};
