import React from 'react';

import { WithdrawModal } from '~/components/WithdrawModal';
import {
  useWithdrawPersonalLoanMutation,
  useWithdrawPersonalLoanApplicationMutation,
} from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import { useToast } from '~/toasts';

import {
  APPLICATION_WITHDRAWN_ERROR_MESSAGE,
  APPLICATION_WITHDRAWN_SUCCESS_MESSAGE,
} from '../constants';

type WithdrawApplicationProps = {
  loanId?: string;
  applicationId: string;
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
};

export const WithdrawApplicationModal = ({
  loanId,
  applicationId,
  isOpen,
  setIsOpen,
}: WithdrawApplicationProps) => {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [withdrawPersonalLoan] = useWithdrawPersonalLoanMutation();
  const [withdrawPersonalLoanApplication] =
    useWithdrawPersonalLoanApplicationMutation();

  const handleWithdrawApplication = () => {
    if (loanId) {
      withdrawPersonalLoan({
        variables: {
          input: {
            loanId,
          },
        },
        onCompleted: () => {
          addToast({
            content: APPLICATION_WITHDRAWN_SUCCESS_MESSAGE,
            kind: 'success',
            duration: 'short',
          });
          navigate({ to: '/d/home' });
        },
        onError: () => {
          addToast({
            content: APPLICATION_WITHDRAWN_ERROR_MESSAGE,
            kind: 'alert',
            duration: 'short',
          });
        },
      });
    } else {
      withdrawPersonalLoanApplication({
        variables: {
          input: {
            applicationId,
          },
        },
        onCompleted: () => {
          addToast({
            content: APPLICATION_WITHDRAWN_SUCCESS_MESSAGE,
            kind: 'success',
            duration: 'short',
          });
          navigate({ to: '/d/home' });
        },
        onError: () => {
          addToast({
            content: APPLICATION_WITHDRAWN_ERROR_MESSAGE,
            kind: 'alert',
            duration: 'short',
          });
        },
      });
    }
  };

  return (
    <WithdrawModal
      open={isOpen}
      onClick={() => {
        analytics.recordEvent('m1_personal_loan_speedbump_withdraw_confirmed');
        setIsOpen(false);
        handleWithdrawApplication();
      }}
      onClose={() => {
        analytics.recordEvent('m1_personal_loan_speedbump_resume_clicked');
        return setIsOpen(false);
      }}
    />
  );
};
