import { Box, HM } from '@m1/liquid-react';
import memoize from 'lodash-es/memoize';
import * as React from 'react';

import { getEnumEntries, EnumEntry } from '~/utils';

import { IntroductionSourceOption } from './option';

type Props = {
  onFinish?: (...args: Array<any>) => any;
};

export class CollectIntroductionSourceStep extends React.Component<Props> {
  render() {
    const [options, declinedOption] = this.readOptions(
      getEnumEntries('IntroductionSourceEnum'),
    );

    return (
      <Box maxWidth={588} mx="auto">
        <HM content="How did you hear about us?" py={32} />
        {options.map(
          ({ name, description }: { name: string; description: string }) => (
            <IntroductionSourceOption
              key={name}
              label={description}
              value={name}
              kind="pill"
              onFinish={this.props.onFinish}
            />
          ),
        )}
        <IntroductionSourceOption
          label="Skip this question"
          value={declinedOption?.name}
          kind="plain"
          onFinish={this.props.onFinish}
        />
      </Box>
    );
  }

  readOptions = memoize(
    (enumEntries: EnumEntry[]): [EnumEntry[], EnumEntry | undefined] => {
      const options = [];

      let declinedOption;
      for (const option of enumEntries) {
        if (option.name !== 'DECLINED_TO_ANSWER') {
          options.push(option);
        } else {
          declinedOption = option;
        }
      }
      return [options, declinedOption];
    },
  );
}
