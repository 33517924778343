/* eslint-disable react/sort-comp */

import classNames from 'classnames';
import * as React from 'react';
import ReactDOM from 'react-dom';

import style from './style.module.scss';

export type Props = {
  centered: boolean;
  className?: string;
  loading: boolean;
  spread: number;
};

type State = {
  active: boolean;
  left: number;
  restarting: boolean;
  top: number;
  width: number;
};

export class Ripple extends React.Component<Props, State> {
  static defaultProps = {
    centered: false,
    className: '',
    loading: false,
    spread: 2,
  };

  state = {
    active: false,
    restarting: false,
    top: 0,
    left: 0,
    width: 0,
  };
  touch: boolean | null | undefined = false;

  render() {
    const { left, top, width } = this.state;
    const scale = this.state.restarting ? 0 : 1;
    let rippleStyle = {
      width,
      height: width,
    };

    if (!this.props.loading) {
      rippleStyle = {
        // @ts-expect-error - TS2322 - Type '{ width: number; height: number; transform: string; }' is not assignable to type '{ width: number; height: number; }'.
        transform: `translate3d(${-width / 2 + left}px, ${
          -width / 2 + top
        }px, 0) scale(${scale})`,
        ...rippleStyle,
      };
    }

    const className = classNames(
      style[this.props.loading ? 'loading' : 'normal'],
      this.props.className,
      this.state.active ? style.active : false,
      this.state.restarting ? style.restarting : false,
    );

    return (
      <span className={style.wrapper}>
        <span ref="ripple" className={className} style={rippleStyle} />
      </span>
    );
  }

  handleEnd = (): void => {
    document.removeEventListener(
      this.touch ? 'touchend' : 'mouseup',
      this.handleEnd,
    );
    this.setState({
      active: false,
    });
  };

  // This is called from parent components
  // Ex: this.refs.ripple.start(event);
  start(
    { pageX, pageY }: React.MouseEvent<any>,
    touch: boolean | null | undefined,
  ) {
    this.touch = touch;
    document.addEventListener(
      this.touch ? 'touchend' : 'mouseup',
      this.handleEnd,
    );
    const descriptor = this._getDescriptor(pageX, pageY);
    if (descriptor) {
      const { top, left, width } = descriptor;
      this.setState(
        {
          active: false,
          restarting: true,
          // @ts-expect-error - TS2322 - Type 'number | undefined' is not assignable to type 'number'.
          top,
          // @ts-expect-error - TS2322 - Type 'number | undefined' is not assignable to type 'number'.
          left,
          // @ts-expect-error - TS2322 - Type 'number | undefined' is not assignable to type 'number'.
          width,
        },
        () => {
          // @ts-expect-error - TS2339 - Property 'offsetWidth' does not exist on type 'ReactInstance'.
          this.refs.ripple.offsetWidth; // eslint-disable-line no-unused-expressions

          this.setState({
            active: true,
            restarting: false,
          });
        },
      );
    }
  }

  _getDescriptor(
    pageX: number,
    pageY: number,
  ): Partial<State> | null | undefined {
    const node = ReactDOM.findDOMNode(this);
    if (node) {
      // @ts-expect-error - TS2339 - Property 'getBoundingClientRect' does not exist on type 'Element | Text'.
      const { left, top, height, width } = node.getBoundingClientRect();
      return {
        left: this.props.centered
          ? 0
          : pageX - left - width / 2 - window.scrollX,
        top: this.props.centered
          ? 0
          : pageY - top - height / 2 - window.scrollY,
        width: width * this.props.spread,
      };
    }
  }
}
