import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

import { StrictTypedTypePolicies } from './apolloHelpers';
import { possibleTypes } from './possible-types.json';

const typePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {
      viewer: {
        merge: true,
      },
    },
  },
  Investments: {
    fields: {
      positions: relayStylePagination(),
    },
  },
  Markets: {
    fields: {
      marketNews: relayStylePagination(),
    },
  },
  Dividends: {
    keyFields: ['cacheId'],
  },
  PositionSecurityReference: {
    keyFields: ['symbol'],
  },
  PersonalLoanViewTerms: {
    fields: {
      dynamicLoanAgreementPresignedUrl: {
        read(existing) {
          if (existing) {
            return existing;
          }
          return undefined;
        },
        merge(existing, incoming) {
          if (existing && !incoming) {
            return existing;
          }
          return incoming;
        },
      },
    },
  },
  InvestActivity: {
    fields: {
      activity: relayStylePagination(),
    },
  },
  Security: {
    fields: {
      securityNews: relayStylePagination(),
    },
  },
  UnmanagedHoldingsCollection: {
    fields: {
      holdings: relayStylePagination(),
    },
  },
  SecurityScreenerConnection: {
    fields: {
      // @ts-ignore investigate the ts-error here
      securities: relayStylePagination(),
    },
  },
  FundScreenerConnection: {
    fields: {
      // @ts-ignore investigate the ts-error here
      securities: relayStylePagination(),
    },
  },
  SystemPieScreenerConnection: {
    fields: {
      // @ts-ignore investigate the ts-error here
      screenSystemPies: relayStylePagination(),
    },
  },
  Spend: {
    fields: {
      activity: {
        merge: true,
      },
      spendAccount: {
        merge: true,
      },
    },
  },
  BorrowAccount: {
    fields: {
      activity: relayStylePagination(),
      bills: relayStylePagination(),
    },
  },
  SpendAccount: {
    fields: {
      balance: {
        merge: true,
      },
    },
  },
  CreditCardAccount: {
    fields: {
      pendingTransactions: {
        merge: true,

        read(existing) {
          if (existing) {
            return existing;
          }
          return undefined;
        },
      },
      settledTransactions: {
        merge: true,

        read(existing) {
          if (existing) {
            return existing;
          }
          return undefined;
        },
      },
      creditCardProductLandingPage: {
        merge: true,
      },
    },
  },
  /* relayStylePagination did not work with the nested nature/approach we have
   * for SpendActivity pagination.  This allows us to merge the fields for cache, but
   * only read the most recent array of activity.

   * keyArgs also separate the cache out as to not combine all activity into
   * one cache item.
   * */
  SpendActivity: {
    fields: {
      activity: {
        keyArgs: ['mode'],
        merge: true,

        read(existing) {
          if (existing) {
            return existing;
          }
          return undefined;
        },
      },
    },
  },
  Viewer: {
    fields: {
      documents: relayStylePagination(['documentType', 'startDate', 'endDate']),
      transfers: {
        merge: true,
      },
      announcements: {
        merge: true,
      },
    },
  },
  Profile: {
    merge: true,
  },
  Borrow: {
    merge: true,
  },
  Invest: {
    merge: true,
  },
  Account: {
    fields: {
      estimatedTrading: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
    },
  },
};

export const cache = new InMemoryCache({
  possibleTypes,
  typePolicies,
});
