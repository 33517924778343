import { MailingAddress, MailingAddressCountryEnum } from '~/graphql/types';

type PlaceResult = google.maps.places.PlaceResult;

export function mapPlaceToAddress(place: PlaceResult): MailingAddress | null {
  if (!place.address_components) {
    return null;
  }

  const lineOne = [
    (place.address_components[0] && place.address_components[0].short_name) ||
      '',
    (place.address_components[1] && place.address_components[1].short_name) ||
      '',
  ].join(' ');

  const city = place.address_components.filter(
    (f) =>
      JSON.stringify(f.types) === JSON.stringify(['locality', 'political']),
  )[0]?.short_name;

  const stateOrProvince = place.address_components.filter(
    (f) =>
      JSON.stringify(f.types) ===
      JSON.stringify(['administrative_area_level_1', 'political']),
  )[0]?.short_name;

  const postalCode = place.address_components.filter(
    (f) => JSON.stringify(f.types) === JSON.stringify(['postal_code']),
  )[0]?.short_name;

  return {
    autofilledAddress: place.formatted_address,
    lineOne,
    lineTwo: null,
    city,
    // @ts-ignore the lens type expects this to be an enum but a two character length string works just fine
    stateOrProvince,
    postalCode,
    country: MailingAddressCountryEnum.Usa,
  };
}
