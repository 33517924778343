import { SemanticColorNames } from '@m1/liquid-react';
import * as React from 'react';

import type { LinkProps } from '../link/Link.types';

export const durations = {
  'short': 4000,
  'long': 8000,
};

export type ToastDurations = keyof typeof durations;

export type ToastKind = 'success' | 'informational' | 'alert' | 'warning';
export type ToastKindConfig = {
  backgroundColor: SemanticColorNames;
  border: string;
  color: SemanticColorNames;
  linkColor: SemanticColorNames;
};

export type ToastProps = {
  content: React.ReactNode;
  duration?: ToastDurations;
  kind: ToastKind;
  link?: LinkProps | React.ReactNode;
  onDismiss?: () => void;
  icon?: React.ReactNode;
};
