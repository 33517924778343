import { Box, css, styled } from '@m1/liquid-react';

export const SecondaryHistoryChartContainer = styled(Box)`
  position: relative;
  height: 100%;
`;

export const Loader = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ControlsContainer = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ControlsContainerModal = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export const ChartContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HoverTarget = styled(Box)`
  position: absolute;
  top: 0;
  width: calc(100% + 12px);
  pointer-events: fill;
`;

export const Scrubber = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    width: 1px;
    pointer-events: none;
    background: ${theme.colors.backgroundFeatureFlat};
    opacity: 0;
  `,
);

export const NoDataMessage = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid ${theme.colors.borderMain};
    width: 90%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    border-radius: 8px;
  `,
);
