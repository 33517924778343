import { BoxProps } from '@m1/liquid-react';
import * as React from 'react';

import { AppPillFragment } from '~/graphql/types';
import { Pill, PillProps } from '~/toolbox/Pill';

export type AppPillProps = Omit<PillProps, 'kind' | 'label'> &
  BoxProps & {
    appPill: AppPillFragment;
  };

export const AppPill = ({
  appPill: { kind, label, icon },
  ...rest
}: AppPillProps) => {
  // Map remote AppPill to local toolbox Pill kind
  const pillKind = React.useMemo(() => {
    switch (kind) {
      case 'CAUTION':
        return 'caution';
      case 'DANGER':
        return 'danger';
      case 'DANGER_INVERSE':
        return 'danger';
      case 'IMPORTANT':
        return 'important';
      case 'IMPORTANT_INVERSE':
        return 'important';
      case 'SUCCESS':
        return 'success';
      case 'WHATS_NEW':
        return 'new';
      case 'WHATS_NEW_INVERSE':
        return 'new';
      default:
        return 'neutral';
    }
  }, [kind]);

  // Map remote AppPill icon to local toolbox Pill icon prop
  const pillIcon = React.useMemo(() => {
    switch (icon) {
      case 'DIAMOND':
        return 'diamond';
      default:
        return null;
    }
  }, [icon]);

  return <Pill {...rest} icon={pillIcon} kind={pillKind} label={label} />;
};
