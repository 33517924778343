import {
  // @ts-expect-error - TS2305 - Module '"~/graphql/types"' has no exported member 'IraDistributionReasonEnumValues'.
  IraDistributionReasonEnumValues,
  // @ts-expect-error - TS2305 - Module '"~/graphql/types"' has no exported member 'IraContributionYearEnumValues'.
  IraContributionYearEnumValues,
  CreateTransferInstanceMutation,
  SetScheduledTransferRuleMutation,
} from '~/graphql/types';

export const CREATE_TRANSFER_ACTIONS = Object.freeze({
  SWITCHED_MODES: 'SWITCHED_MODES' as const,
  SET_IRA_DISTRIBUTION_REASON: 'SET_IRA_DISTRIBUTION_REASON' as const,
  SET_IRA_EXCESS_CONTRIBUTION_YEAR: 'SET_IRA_EXCESS_CONTRIBUTION_YEAR' as const,
  SET_IRA_NIA_AMOUNT: 'SET_IRA_NIA_AMOUNT' as const,
  SET_TRANSFER_IDEMPOTENCY_KEY: 'SET_TRANSFER_IDEMPOTENCY_KEY' as const,
  TRANSFER_INSTANCE_CREATED: 'TRANSFER_INSTANCE_CREATED' as const,
  SCHEDULED_TRANSFER_RULE_SET: 'SCHEDULED_TRANSFER_RULE_SET' as const,
  SET_FEDERAL_WITHHOLDING_AMOUNT: 'SET_FEDERAL_WITHHOLDING_AMOUNT' as const,
  SET_STATE_WITHHOLDING_AMOUNT: 'SET_STATE_WITHHOLDING_AMOUNT' as const,
});

export type ClickedSwitchModes = {
  payload: unknown;
  type: typeof CREATE_TRANSFER_ACTIONS.SWITCHED_MODES;
};

export type SetTransferIdempotencyKey = {
  payload: string | null | undefined;
  type: typeof CREATE_TRANSFER_ACTIONS.SET_TRANSFER_IDEMPOTENCY_KEY;
};

export type SetFederalWithholdingPercentage = {
  payload: number | null | undefined;
  type: typeof CREATE_TRANSFER_ACTIONS.SET_FEDERAL_WITHHOLDING_AMOUNT;
};

export type SetStateWithholdingPercentage = {
  payload: number | null | undefined;
  type: typeof CREATE_TRANSFER_ACTIONS.SET_STATE_WITHHOLDING_AMOUNT;
};

export type SetIraDistributionReason = {
  payload: IraDistributionReasonEnumValues;
  type: typeof CREATE_TRANSFER_ACTIONS.SET_IRA_DISTRIBUTION_REASON;
};

export type SetIraExcessContributionYear = {
  payload: IraContributionYearEnumValues | null | undefined;
  type: typeof CREATE_TRANSFER_ACTIONS.SET_IRA_EXCESS_CONTRIBUTION_YEAR;
};

export type ClearExcessIraContributionData = {
  type: 'CLEAR_EXCESS_CONTRIBUTION_DATA';
};

export type SetIraNiaAmount = {
  payload: number | null | undefined;
  type: typeof CREATE_TRANSFER_ACTIONS.SET_IRA_NIA_AMOUNT;
};

export type CreateTransferInstance = {
  payload: {
    outcome:
      | CreateTransferInstanceMutation['createTransferInstance']['outcome']
      | null
      | undefined;
  };
  type: typeof CREATE_TRANSFER_ACTIONS.TRANSFER_INSTANCE_CREATED;
};

export type SetScheduledTransferRule = {
  payload: {
    outcome:
      | SetScheduledTransferRuleMutation['setScheduledTransferRule']['outcome']
      | null
      | undefined;
  };
  type: typeof CREATE_TRANSFER_ACTIONS.SCHEDULED_TRANSFER_RULE_SET;
};

export const clickedSwitchModes = (
  payload: ClickedSwitchModes['payload'],
): ClickedSwitchModes => ({
  payload,
  type: CREATE_TRANSFER_ACTIONS.SWITCHED_MODES,
});

export const setTransferIdempotencyKey = (
  payload: SetTransferIdempotencyKey['payload'] | null | undefined,
): SetTransferIdempotencyKey => ({
  type: CREATE_TRANSFER_ACTIONS.SET_TRANSFER_IDEMPOTENCY_KEY,
  payload,
});

export const setFederalWithholdingPercentage = (
  payload: SetFederalWithholdingPercentage['payload'] | null | undefined,
): SetFederalWithholdingPercentage => ({
  type: CREATE_TRANSFER_ACTIONS.SET_FEDERAL_WITHHOLDING_AMOUNT,
  payload,
});

export const setStateWithholdingPercentage = (
  payload: SetStateWithholdingPercentage['payload'] | null | undefined,
): SetStateWithholdingPercentage => ({
  type: CREATE_TRANSFER_ACTIONS.SET_STATE_WITHHOLDING_AMOUNT,
  payload,
});

export const setIraDistributionReason = (
  payload: SetIraDistributionReason['payload'],
): SetIraDistributionReason => ({
  type: CREATE_TRANSFER_ACTIONS.SET_IRA_DISTRIBUTION_REASON,
  payload,
});

export const setIraExcessContributionYear = (
  payload: SetIraExcessContributionYear['payload'] | null | undefined,
): SetIraExcessContributionYear => ({
  type: CREATE_TRANSFER_ACTIONS.SET_IRA_EXCESS_CONTRIBUTION_YEAR,
  payload,
});

export const setIraNiaAmount = (
  payload: SetIraNiaAmount['payload'] | null | undefined,
): SetIraNiaAmount => ({
  type: CREATE_TRANSFER_ACTIONS.SET_IRA_NIA_AMOUNT,
  payload,
});

export type CreateTransferAction =
  | ClearExcessIraContributionData
  | ClickedSwitchModes
  | SetFederalWithholdingPercentage
  | SetIraDistributionReason
  | SetIraExcessContributionYear
  | ClearExcessIraContributionData
  | SetIraNiaAmount
  | CreateTransferInstance
  | SetScheduledTransferRule
  | SetIraNiaAmount
  | SetStateWithholdingPercentage
  | SetTransferIdempotencyKey;
