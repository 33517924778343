import { Box, Button, PM, HM, PXL, Flex } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { BackButton } from '~/flows/components/personal-loans/application/components/BackButton';
import { LoanInformationForm } from '~/flows/components/personal-loans/application/steps/LoanInformation/LoanInformationForm';
import { FormMocker } from '~/forms/FormMockers/FormMocker';
import { useLoanInformationQuery } from '~/graphql/hooks';
import { useSelector } from '~/redux/hooks';
import { Divider } from '~/toolbox/divider';
import { Spinner } from '~/toolbox/spinner';

type PersonalLoansInformationPageProps = {
  onFinishStep: (loanInfo: {
    loanAmount: string;
    loanPurpose: string;
    loanTerm: string;
  }) => void;
};
export const LoanInformation: React.FC<PersonalLoansInformationPageProps> = ({
  onFinishStep,
}) => {
  const { loanAmount, loanPurpose, loanTerm } = useSelector(
    (state) => state.newFlows.PERSONAL_LOANS_APPLICATION,
  );

  const { data, loading } = useLoanInformationQuery();
  if (loading) {
    return <Spinner fullScreen centered />;
  }

  const initialLoanTermOptions =
    data?.viewer.borrow?.personalLoans?.applicationFlow?.loanTermOptions || [];

  const initialLoanPurposeOptions =
    data?.viewer.borrow?.personalLoans?.applicationFlow?.loanPurposeOptions
      ?.edges || [];

  if (
    initialLoanPurposeOptions.length === 0 ||
    initialLoanTermOptions.length === 0
  ) {
    return <GenericSystemError />;
  }

  const loanPurposeOptions = initialLoanPurposeOptions.map(
    (loanPurposeOption) => ({
      label: loanPurposeOption?.node?.title || '',
      value: loanPurposeOption?.node?.value || '',
    }),
  );

  const loanTermOptions = initialLoanTermOptions.reduce<
    { label: string | null; value: number }[]
  >((arr, term) => {
    if (term?.value) {
      arr.push({
        label: term.description ?? '',
        value: term.value,
      });
    }
    return arr;
  }, []);

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex flexDirection="column" width="50%">
          <Box pt={32}>
            <BackButton />
          </Box>
          <HM pt={16}>Loan information</HM>
          <PXL pt={16} pb={64}>
            Tell us about the money you're looking to borrow.
          </PXL>
          <LoanInformationForm
            onSubmit={onFinishStep}
            initialValues={{
              loanPurposeOptions,
              loanTermOptions,
              loanAmount,
              loanPurpose,
              loanTerm,
            }}
          />
        </Flex>
        <Flex>
          <Illustration name="loan" width={480} height={360} />
        </Flex>
      </Flex>
      <PM color="foregroundNeutralSecondary" pt={48}>
        Please note, you can't use these loans to pay for educational expenses.
      </PM>
      <Divider spacing="relaxed" />
      <Box>
        <Button
          type="submit"
          form="loanInformationForm"
          kind="primary"
          size="large"
          label="Continue"
        />
      </Box>
      <FormMocker
        formName="LoanInformationForm"
        fields={[
          {
            name: 'loanPurpose',
            value: 'SPECIAL_OCCASION',
          },
          {
            name: 'loanAmount',
            value: 5000,
          },
          {
            name: 'loanTerm',
            value: 36,
          },
        ]}
        label="Mock this form!"
      />
    </>
  );
};
