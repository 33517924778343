import get from 'lodash-es/get';
import last from 'lodash-es/last';

import {
  ChildPortfolioSlice,
  PortfolioSlice,
  SortDirectionEnum,
} from '~/graphql/types';
import { PortfolioSliceSortKey } from '~/portfolio-slice';
import { generateSliceFillColorsArray } from '~/utils/slices';

export type PortfolioSliceSorter = (
  a: ChildPortfolioSlice,
  b: ChildPortfolioSlice,
) => number;

export function createPortfolioSliceSorter(
  sortKey: PortfolioSliceSortKey,
  sortDirection: SortDirectionEnum,
): PortfolioSliceSorter {
  return function (a, b) {
    if (sortKey === 'to.name') {
      return sortByName(a, b, sortKey, sortDirection);
    } else if (
      sortKey === 'performance.moneyWeightedRateOfReturn' ||
      sortKey === 'value.total'
    ) {
      const aPerf = readNumericValue(a, sortKey, sortDirection);
      const bPerf = readNumericValue(b, sortKey, sortDirection);
      if (aPerf !== bPerf) {
        return sortDirection === 'DESC' ? bPerf - aPerf : aPerf - bPerf;
      }
    }
    const aPct = a.percentage;
    const bPct = b.percentage;
    if (aPct !== bPct) {
      return sortDirection === 'DESC' ? bPct - aPct : aPct - bPct;
    }
    return sortByName(a, b, sortKey, sortDirection);
  };
}

export function getChildrenFromProps(
  portfolioSlice: Partial<PortfolioSlice>,
): Array<ChildPortfolioSlice> {
  if (!portfolioSlice) {
    return [];
  }
  // @ts-expect-error - TS2339 - Property '__typename' does not exist on type 'PortfolioSlice'.
  return portfolioSlice.__typename === 'ChildPortfolioSlice' &&
    // @ts-expect-error - TS2339 - Property '__typename' does not exist on type 'Sliceable'.
    ['Equity', 'Fund', 'CryptoAsset'].includes(portfolioSlice.to.__typename)
    ? // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2339 - Property 'ancestors' does not exist on type 'PortfolioSlice'.
      last(portfolioSlice.ancestors).children
    : portfolioSlice.children;
}

export function generateColorsForPie(
  portfolioSlice: Partial<PortfolioSlice>,
  themeSliceColors: {
    active: string;
    inactive: string;
    greyscale: string;
    inactiveGreyscale: string;
  }[],
): Map<
  string,
  {
    activeColor: string;
    inactiveColor: string;
  }
> {
  const colors = new Map<
    string,
    {
      activeColor: string;
      inactiveColor: string;
    }
  >();
  const slices = getChildrenFromProps(portfolioSlice);
  const portfolioSliceRowBorderColors = generateSliceFillColorsArray(
    themeSliceColors,
    slices ? slices.length : 0,
  );

  for (const [index, slice] of slices.entries()) {
    if (slice !== null) {
      const hasValue = Boolean(slice.value && slice.value.hasValue);

      colors.set(slice.id, {
        activeColor:
          portfolioSliceRowBorderColors[
            index % portfolioSliceRowBorderColors.length
          ][hasValue ? 'active' : 'greyscale'],
        inactiveColor:
          portfolioSliceRowBorderColors[
            index % portfolioSliceRowBorderColors.length
          ][hasValue ? 'inactive' : 'inactiveGreyscale'],
      });
    }
  }

  return colors;
}

export function sortByName(
  a: ChildPortfolioSlice,
  b: ChildPortfolioSlice,
  sortKey: PortfolioSliceSortKey,
  sortDirection: SortDirectionEnum,
) {
  const aName = get(a, 'to.name').toLowerCase();
  const bName = get(b, 'to.name').toLowerCase();
  if (sortKey === 'to.name' && sortDirection === 'DESC') {
    return aName < bName ? 1 : -1;
  }
  return bName < aName ? 1 : -1;
}

export function readNumericValue(
  s: ChildPortfolioSlice,
  k: PortfolioSliceSortKey,
  d: SortDirectionEnum,
): number {
  const mwrr = get(s, k);
  const placeholder =
    d === 'DESC' ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  return typeof mwrr === 'number' ? mwrr : placeholder;
}

function pluralize(num: number, word: string): string {
  return num === 1 ? word : `${word}s`;
}

export function formatBuySellCount(count: number, word: string): string | null {
  if (count === 0) {
    return null;
  }
  return `${count} ${pluralize(count, word)}`;
}

export function buildEstimatedTradesText(
  buyContent: string | null,
  sellContent: string | null,
): string {
  if (buyContent && sellContent) {
    return `${buyContent}, ${sellContent}`;
  } else if (buyContent) {
    return `${buyContent}`;
  } else if (sellContent) {
    return `${sellContent}`;
  }

  return 'None';
}
