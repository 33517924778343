import * as React from 'react';
import { Route } from 'react-router-dom';

import { ForwardIfAuthGuard } from '~/router/Authentication/ForwardIfAuthGuard';

import { LoginPage } from './LoginPage';

export const LoginRoute = () => {
  return (
    <Route
      path="login"
      element={
        <ForwardIfAuthGuard>
          <LoginPage />
        </ForwardIfAuthGuard>
      }
    />
  );
};
