import {
  PL,
  Flex,
  styled,
  Button,
  type ButtonProps,
  FlexProps,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { Link } from '~/toolbox/link/Link';

export type BackArrowProps = OmitUnion<
  ButtonProps,
  'label' | 'leftIcon' | 'rightIcon'
> &
  FlexProps & {
    content: string;
    onDarkBackground?: boolean;
    caret?: boolean;
    to?: string;
    params?: Record<string, any>;
    state?: Record<string, any>;
  };

const StyledFlex = styled(Flex)<{
  $onDarkBackground: boolean;
}>`
  transition: all 200ms;

  color: ${(props) => {
    return props.$onDarkBackground
      ? props.theme.colors.foregroundNeutralOnDark
      : props.theme.colors.primary;
  }};

  svg {
    path {
      fill: ${(props) => {
        return props.$onDarkBackground
          ? props.theme.colors.foregroundNeutralOnDark
          : props.theme.colors.primary;
      }};
    }
  }

  &:hover {
    color: ${(props) => {
      return props.$onDarkBackground
        ? props.theme.colors.foregroundNeutralTertiary
        : props.theme.colors.primary;
    }};

    svg {
      path {
        fill: ${(props) => {
          return props.$onDarkBackground
            ? props.theme.colors.foregroundNeutralTertiary
            : props.theme.colors.primary;
        }};
      }
    }
  }
`;

const Arrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      fill="none"
      viewBox="0 0 20 16"
    >
      <path
        fillRule="evenodd"
        d="M.163 7.58c-.082.106-.139.227-.15.369 0 .015-.01.027-.01.041C.003 7.994 0 7.997 0 8l.002.012c0 .009.005.016.006.025.009.172.075.327.182.448.009.01.005.025.014.035.01.01.025.006.035.014.01.009.024.007.034.015l7.823 7.243c.272.277.714.277.986 0s.272-.726 0-1.003L2.537 8.727h16.749C19.68 8.727 20 8.4 20 8c0-.401-.32-.727-.714-.727H2.536l6.546-6.06c.272-.279.272-.728 0-1.005-.272-.277-.714-.277-.986 0L.313 7.415c-.012.008-.018.022-.029.031-.024.02-.058.011-.08.034-.027.028-.02.07-.041.1z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const BackArrow = ({
  content,
  onDarkBackground = false,
  onClick,
  caret = false,
  to,
  params,
  state,
  ...rest
}: BackArrowProps) => {
  // if `to` is passed, we assume it's supposed to navigate via Link props
  if (to) {
    return (
      // @ts-ignore - Spreading rest here causes TS to throw a fit.
      //              Ideally we shouldn't spread rest, but we'd have to track down everything thats being used.
      <Link
        {...rest}
        data-testid="back-arrow"
        to={to}
        params={params}
        style={{ justifyContent: 'flex-start' }}
        state={state}
      >
        <StyledFlex alignItems="center" $onDarkBackground={onDarkBackground}>
          {caret ? <Icon color="primary" name="caretLeft24" /> : <Arrow />}
          <PL content={content} ml={8} fontWeight={600} />
        </StyledFlex>
      </Link>
    );
  }

  return (
    <Button
      {...rest}
      kind="link"
      data-testid="back-arrow"
      style={{ justifyContent: 'flex-start' }}
      label={
        <StyledFlex alignItems="center" $onDarkBackground={onDarkBackground}>
          {caret ? <Icon color="primary" name="caretLeft24" /> : <Arrow />}
          <PL content={content} ml={8} fontWeight={600} />
        </StyledFlex>
      }
      onClick={(e) => {
        onClick?.(e);
      }}
    />
  );
};
