import { PL, PM, Tooltip } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { ExternalLink } from '~/components/ExternalLink';
import { TradingStatusFragment } from '~/graphql/types';
import { formatNumber } from '~/utils';

export const AccountTradingStatusAfternoonWindowEligibilityPopup = ({
  trading,
}: {
  trading: TradingStatusFragment | null | undefined;
}) => {
  const popup = React.createRef<React.ElementRef<typeof Tooltip>>();

  if (!trading) {
    return null;
  }

  const {
    minAccountValueForMultipleDailyWindows,
    hasAccessToAndCanTradeInNextAfternoonWindow,
  } = trading;

  // User needs a certain account value to be eligible for afternoon trade window.
  if (hasAccessToAndCanTradeInNextAfternoonWindow === false) {
    const minValue =
      typeof minAccountValueForMultipleDailyWindows === 'number'
        ? formatNumber(minAccountValueForMultipleDailyWindows, '$0,0')
        : 'a certain value';

    return (
      <Tooltip
        placement="bottom-start"
        iconColor="primary"
        trigger="click"
        body={
          <>
            <PM fontWeight={600} content="Not eligible today" />
            <PM
              content={`Invest accounts with less than ${minValue} can only trade once per day. The afternoon trade window is not available today because this account already traded in the morning.`}
              style={{
                marginTop: 4,
              }}
            />
            <ExternalLink
              kind="inverse"
              label="Learn more"
              destination="SUPPORT_TRADING_WINDOW"
              onClick={() => {
                // @ts-expect-error - Property 'hide' does not exist on type 'Element'.
                popup.current && popup.current.hide?.();
              }}
              font="PM"
              style={{
                marginTop: 8,
              }}
            />
          </>
        }
      >
        <PL
          color="primary"
          content={
            <>
              <Icon name="lock20" ml={8} mr={4} />
              Ineligible
            </>
          }
        />
      </Tooltip>
    );
  }

  return null;
};
