import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import { PersonalLoansAccountSettingsFragment } from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';
import { getEnumDescription } from '~/utils';

type PersonalLoansRowsProps = {
  personalLoans:
    | PersonalLoansAccountSettingsFragment['personalLoans']
    | null
    | undefined;
};

export const PersonalLoansRows = ({
  personalLoans,
}: PersonalLoansRowsProps) => (
  <>
    {(personalLoans?.loans?.edges ?? []).map((edge) =>
      edge?.node ? (
        <GridTable.Row key={edge.node.id}>
          <GridTable.Cell
            content={
              <Flex alignItems="center" height={40} inline>
                {edge.node?.name}
              </Flex>
            }
          />
          {/* Todo: add personal loan number here once BE implements it */}
          <GridTable.Cell content="-" />
          <GridTable.Cell content="Personal Loan" />
          <GridTable.Cell
            content={getEnumDescription(
              edge.node?.status,
              'PersonalLoanStatusEnum',
            )}
          />
        </GridTable.Row>
      ) : null,
    )}
  </>
);
