import * as React from 'react';

import { clickedOrderDirectionToggle } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { OrderDirection } from '~/redux/reducers/newFlows/reducers/setOrderReducer';
import { Tabs } from '~/toolbox/tabs';

export const OrderDirectionToggle = () => {
  return (
    <Tabs>
      <TabOption value="buy" label="Buy" />
      <TabOption value="sell" label="Sell" />
    </Tabs>
  );
};

const TabOption = ({
  value,
  label,
}: {
  value: OrderDirection;
  label: string;
}) => {
  const direction = useSelector((state) => state.newFlows.SET_ORDER.direction);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(clickedOrderDirectionToggle(value));
  };

  return (
    <Tabs.Tab
      isActive={direction === value}
      label={label}
      onClick={handleClick}
    />
  );
};
