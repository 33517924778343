import React from 'react';

import { SliceableNameCell } from '~/components/pie';
import { StyledPerformanceCell } from '~/components/research/Screener';
import { SecurityMiniChartAndReturn } from '~/components/security/SecurityMiniChartAndReturn';
import { SecurityRiskLevel } from '~/components/security/SecurityRiskLevel';

import {
  HistoricalQuotePeriodEnum,
  ScreenerFundFragment,
} from '~/graphql/types';
import { clickedOnSliceable } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';
import { Table } from '~/toolbox/table';
import { formatNumber } from '~/utils';

import { useFundScreenerResult } from './FundScreenerProvider';

type FundScreenerTableRowProps = {
  fund: ScreenerFundFragment | null | undefined;
};

export const FundScreenerTableRow = ({ fund }: FundScreenerTableRowProps) => {
  const { queryResult } = useFundScreenerResult();
  const dispatch = useDispatch();

  const { loading } = queryResult;

  if (!fund?.node) {
    return null;
  }
  const { fundFundamentals, fundamentals, priceChange, symbol, id } = fund.node;

  const totalAssets =
    typeof fundFundamentals?.totalAssets === 'number'
      ? formatNumber(fundFundamentals?.totalAssets, '0.0a').toUpperCase()
      : '--';

  const dividendYield =
    typeof fundamentals?.dividendYield === 'number'
      ? `${fundamentals.dividendYield.toFixed(2)}%`
      : '--';

  return (
    <Table.Row
      key={symbol}
      onClick={(event) => {
        // Explicitly check that the click target is not the checkbox inside the row.
        if (event.target?.nodeName !== 'INPUT') {
          dispatch(clickedOnSliceable(id));
        }
      }}
    >
      <Table.Cell
        content={<SliceableNameCell selectable sliceable={fund.node} />}
      />
      <Table.Cell align="right" content={totalAssets} />
      <Table.Cell align="right" content={dividendYield} />
      <Table.Cell align="right">
        <StyledPerformanceCell>
          {loading ? (
            <Spinner
              radius={22}
              thickness={2}
              secondaryColor="backgroundNeutralMain"
            />
          ) : (
            <SecurityMiniChartAndReturn
              period={
                queryResult.variables?.period ||
                HistoricalQuotePeriodEnum.OneYear
              }
              securityId={id}
              percentChange={priceChange?.percentChange}
            />
          )}
        </StyledPerformanceCell>
      </Table.Cell>
      <Table.Cell align="right">
        <SecurityRiskLevel align="right" security={fund.node} size="small" />
      </Table.Cell>
    </Table.Row>
  );
};
