import * as React from 'react';

type UsePollTimerProps = {
  shouldStartPollTimer: boolean;
  pollInterval: number;
  pollLimit: number;
};

export const usePollTimer = ({
  shouldStartPollTimer,
  pollInterval,
  pollLimit,
}: UsePollTimerProps) => {
  const interval = React.useRef(null) as any;
  const [pollCountState, setPollCountState] = React.useState<number>(0);

  const startPollTimer = React.useCallback(
    (cleanup: any) => {
      setPollCountState(0);
      if (shouldStartPollTimer) {
        interval.current = setInterval(() => {
          setPollCountState((count) => count + 1);
          return cleanup;
        }, pollInterval);
      }
    },
    [shouldStartPollTimer],
  );

  React.useEffect(() => {
    const cleanup = () => clearInterval(interval.current);
    startPollTimer(cleanup);
    return cleanup;
  }, [startPollTimer]);

  return {
    isPollLimitReached: pollCountState >= pollLimit,
  };
};
