import { HXXS, Tooltip } from '@m1/liquid-react';
import * as React from 'react';

import { SliceableNameCell } from '~/components/pie';

import { PortfolioSliceDeallocatedChildrenSectionFragment } from '~/graphql/types';
import { GridTable } from '~/toolbox/grid-table';
import { formatNumber } from '~/utils';

export type PortfolioSliceDeallocatedChildrenSectionProps = {
  deallocatedChildren: PortfolioSliceDeallocatedChildrenSectionFragment['deallocatedChildren'];
  style?: Record<string, any>;
};

export const PortfolioSliceDeallocatedChildrenSection = ({
  deallocatedChildren,
  style,
}: PortfolioSliceDeallocatedChildrenSectionProps) => {
  if (deallocatedChildren.length === 0) {
    return null;
  }

  return (
    <div style={style}>
      <HXXS fontWeight={300} mb={16}>
        Removed slices{' '}
        <Tooltip
          icon="tooltip20"
          body="These slices will be sold during the next trade window. Proceeds will be
        reinvested into this pie."
        />
      </HXXS>
      <GridTable
        emptyMessage="You have no Slices pending removal."
        gridTemplateColumns="auto 22%"
        style={{
          marginTop: 12,
        }}
      >
        <GridTable.HeaderRow>
          <GridTable.HeaderCell label="Name" />
          <GridTable.HeaderCell justifySelf="end" label="Value" />
        </GridTable.HeaderRow>
        {deallocatedChildren.map((child) => (
          <GridTable.Row key={child.to.id}>
            <GridTable.Cell
              content={<SliceableNameCell sliceable={child.to} />}
            />
            <GridTable.Cell justifySelf="end">
              {typeof child.value?.total === 'number'
                ? formatNumber(child.value.total, '$0,0.00')
                : '--'}
            </GridTable.Cell>
          </GridTable.Row>
        ))}
      </GridTable>
    </div>
  );
};
