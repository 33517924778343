import * as React from 'react';
import { Outlet, Route } from 'react-router-dom';

import { ReferralsSignupPage } from '~/pages/signup/ReferralsSignupPage';
import { SignupPage } from '~/pages/signup/SignupPage';
import { ForwardIfAuthGuard } from '~/router/Authentication/ForwardIfAuthGuard';

import { CollectUsernamePassword } from '../promo-signup/flow/CollectUsernamePassword';
import { PromotionSignUpOutcomePage } from '../promo-signup/flow/PromotionSignupOutcomePage';

export const SignupRoutes = () => {
  return (
    <Route
      path="signup"
      element={
        <ForwardIfAuthGuard>
          <Outlet />
        </ForwardIfAuthGuard>
      }
    >
      <Route index element={<SignupPage />} />
      <Route path="referrals" element={<ReferralsSignupPage />} />
      <Route path="promotion/:promoCode" element={<CollectUsernamePassword />}>
        <Route path="outcome" element={<PromotionSignUpOutcomePage />} />
      </Route>
      <Route path="referral/:promoCode" element={<CollectUsernamePassword />}>
        <Route path="outcome" element={<PromotionSignUpOutcomePage />} />
      </Route>
    </Route>
  );
};
