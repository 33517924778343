import { Box, Button, Flex, HM, PL } from '@m1/liquid-react';
import * as React from 'react';

import { useTwoFactorAuthQuery } from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';

type TwoFactorAuthFormProps = {
  onInitializeAttempt: (arg0: boolean) => void;
};

export const TwoFactorAuthForm = ({
  onInitializeAttempt,
}: TwoFactorAuthFormProps) => {
  const { data, loading } = useTwoFactorAuthQuery({
    fetchPolicy: 'network-only',
  });

  const analytics = useAnalytics();

  if (loading || !data?.viewer?.user) {
    return null;
  }

  const { isTwoFactorAuthEnabled } = data.viewer.user;

  return (
    <Flex flexDirection="column">
      <HM
        content={`${isTwoFactorAuthEnabled ? 'Turn off' : 'Turn on'} two-factor authentication.`}
        fontWeight={300}
      />
      <PL
        pt={16}
        content="Two-factor authentication offers an extra layer of account security. When it’s on, you log in using your password and a one-time code created by an authentication app."
      />
      <Box>
        <Button
          mt={32}
          kind="secondary"
          label={isTwoFactorAuthEnabled ? 'Turn it off' : 'Turn it on'}
          onClick={() => {
            analytics.recordEvent('m1_update_2FA_status', null, {
              status: isTwoFactorAuthEnabled ? 'off' : 'on',
            });

            onInitializeAttempt(!isTwoFactorAuthEnabled);
          }}
        />
      </Box>
    </Flex>
  );
};
