import { Box } from '@m1/liquid-react';

import * as React from 'react';

import { CloseButton } from '~/components/Cover/components';
import { GenericSystemError } from '~/components/GenericSystemError';

import { useInitialFundingDepositConfirmationLazyQuery } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import { AppInfoPage } from '~/lens-toolbox/AppInfoPage';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

export const InitialFundingDepositConfirmationStep = () => {
  const payload = useSelector(
    (state) => state.newFlows.initialFunding.createTransferInstancePayload,
  );
  const navigate = useNavigate();
  const [fetchQuery, { data, loading }] =
    useInitialFundingDepositConfirmationLazyQuery();

  React.useEffect(() => {
    if (payload?.didSucceed && payload.transferInstanceId) {
      fetchQuery({
        variables: {
          transferId: payload.transferInstanceId,
        },
      });
    }
  }, [payload]);

  if (loading || !data) {
    return <Spinner />;
  }

  if (
    !payload?.didSucceed ||
    !data?.viewer.transfers?.initialFunding.initialFundingScreens
      .initialFundingConfirmationScreen
  ) {
    return <GenericSystemError />;
  }

  return (
    <Box m="0 auto" maxWidth={490} textAlign="center">
      <CloseButton
        color="foregroundNeutralSecondary"
        onClick={() => navigate({ to: '/d/home' })}
        top={72}
        right={16}
      />
      <AppInfoPage
        appInfoPage={
          data.viewer.transfers.initialFunding.initialFundingScreens
            .initialFundingConfirmationScreen
        }
        onPrimaryButtonClick={() =>
          navigate({
            to: '/d/invest/portfolio-organizer',
            options: {
              state: {
                event: {
                  initConfig: {
                    confirmationDialog: {
                      showApplicableLocations: false,
                    },
                    mode: 'NEW_ROOT_PIE',
                    returnTo: '/d/invest',
                    sliceableIds: [],
                  },
                  type: 'INITIALIZE_ORGANIZER',
                },
              },
            },
          })
        }
      />
    </Box>
  );
};
