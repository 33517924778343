import { ACTION_TYPES } from '~/redux/actions';

export type BorrowMarginState = {
  hasUserDismissedMarginCallDialog: boolean;
};

const defaultBorrowMarginState: BorrowMarginState = {
  hasUserDismissedMarginCallDialog: false,
};

export const borrowMarginReducer = (
  state: BorrowMarginState = defaultBorrowMarginState,
  action: any,
): BorrowMarginState => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_HAS_USER_DISMISSED_MARGIN_CALL_DIALOG:
      return {
        ...state,
        hasUserDismissedMarginCallDialog: action.payload,
      };
    default:
      return state;
  }
};
