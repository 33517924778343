import { styled, Box } from '@m1/liquid-react';

import { Container } from '~/toolbox/container';

export const StyledAddressFieldsContainer = styled(Container)`
  padding-left: 0px;
  display: grid;
  grid-template-columns: 320px auto;
  grid-template-rows: 64px 64px 64px;
  grid-template-areas: 'left right';
`;

export const StyledPersonalInformationLeftColumn = styled(Box)`
  grid-area: left;
`;
export const StyledPersonalInformationRightColumn = styled(Box)`
  grid-area: right;
`;

export const StyledInformationContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.backgroundNeutralSecondary};
  position: relative;
  z-index: 2;
`;
