export const INITIAL_FUNDING_ACTIONS = Object.freeze({
  BEGIN_INITIAL_FUNDING_FLOW: 'BEGIN_INITIAL_FUNDING_FLOW' as const,
  FINISHED_BEGIN_INITIAL_FUNDING: 'FINISHED_BEGIN_INITIAL_FUNDING' as const,
  FINISHED_INITIAL_BANK_CONNECTION: 'FINISHED_INITIAL_BANK_CONNECTION' as const,
  SUBMITTED_INITIAL_DEPOSIT_FORM: 'SUBMITTED_INITIAL_DEPOSIT_FORM' as const,
  INITIAL_DEPOSIT_MUTATION_COMPLETED:
    'INITIAL_DEPOSIT_MUTATION_COMPLETED' as const,
  INITIAL_DEPOSIT_MUTATION_FAILED: 'INITIAL_DEPOSIT_MUTATION_FAILED' as const,
  STORE_CALLBACK_URL: 'STORE_CALLBACK_URL' as const,
  FUNDED_DEPOSIT_CONFIRMATION: 'FUNDED_DEPOSIT_CONFIRMATION' as const,
  SKIPPED_DEPOSIT_CONFIRMATION: 'SKIPPED_DEPOSIT_CONFIRMATION' as const,
  INITIAL_FUNDING_DEPOSIT_CONFIRMATION:
    'INITIAL_FUNDING_DEPOSIT_CONFIRMATION' as const,
  READ_INITIAL_DEPOSIT_FEEDBACK: 'READ_INITIAL_DEPOSIT_FEEDBACK' as const,
  FINISHED_INITIAL_FUNDING_FLOW: 'FINISHED_INITIAL_FUNDING_FLOW' as const,
  SKIPPED_ONBOARDING_DEPOSIT: 'SKIPPED_ONBOARDING_DEPOSIT' as const,
  SET_IDEMPOTENCY_KEY: 'SET_IDEMPOTENCY_KEY' as const,
});

export type SkippedOnboardingDeposit = {
  type: typeof INITIAL_FUNDING_ACTIONS.SKIPPED_ONBOARDING_DEPOSIT;
};

export type InitialFundingDepositConfirmationAction = {
  payload: {
    callbackUrl: string;
  };
  type: typeof INITIAL_FUNDING_ACTIONS.INITIAL_FUNDING_DEPOSIT_CONFIRMATION;
};

export type FinishedBeginInitialFunding = {
  type: typeof INITIAL_FUNDING_ACTIONS.FINISHED_BEGIN_INITIAL_FUNDING;
};

export type BeginInitialFundingFlowAction = {
  payload: unknown;
  type: typeof INITIAL_FUNDING_ACTIONS.BEGIN_INITIAL_FUNDING_FLOW;
};

export type FinishedInitialBankConnectionAction = {
  payload: unknown;
  type: typeof INITIAL_FUNDING_ACTIONS.FINISHED_INITIAL_BANK_CONNECTION;
};

export type SubmittedInitialDepositFundsFormAction = {
  payload: unknown;
  type: typeof INITIAL_FUNDING_ACTIONS.SUBMITTED_INITIAL_DEPOSIT_FORM;
};

export type FinishedReadingInitialDepositFeedbackAction = {
  payload: unknown;
  type: typeof INITIAL_FUNDING_ACTIONS.READ_INITIAL_DEPOSIT_FEEDBACK;
};

export type FinishedInitialFundingFlowAction = {
  type: typeof INITIAL_FUNDING_ACTIONS.FINISHED_INITIAL_FUNDING_FLOW;
};

export type InitialDepositMutationCompletedAction = {
  payload: string | null | undefined;
  type: typeof INITIAL_FUNDING_ACTIONS.INITIAL_DEPOSIT_MUTATION_COMPLETED;
};

export type InitialDepositMutationFailedAction = {
  payload: string | null | undefined;
  type: typeof INITIAL_FUNDING_ACTIONS.INITIAL_DEPOSIT_MUTATION_FAILED;
};

export type SetIdempotencyKey = {
  payload: string;
  type: typeof INITIAL_FUNDING_ACTIONS.SET_IDEMPOTENCY_KEY;
};

export type InitialFundingAction =
  | BeginInitialFundingFlowAction
  | SkippedOnboardingDeposit
  | InitialFundingDepositConfirmationAction
  | FinishedInitialBankConnectionAction
  | FinishedReadingInitialDepositFeedbackAction
  | FinishedInitialFundingFlowAction
  | SubmittedInitialDepositFundsFormAction
  | FinishedBeginInitialFunding
  | InitialDepositMutationCompletedAction
  | InitialDepositMutationFailedAction
  | SetIdempotencyKey;
