/*
  Creates a new pie from a serialized (base64) string passed from the `model`
  query parameter in thr URL (?model=xxxxxx)
*/

import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import {
  SharedPieTokenDocument,
  SharedPieTokenQueryResult,
} from '~/graphql/hooks';

import { PieLabelEnum } from '~/graphql/types';

import { apolloQuerySaga } from '../apolloQuerySaga';

import { createPieFromEditModel } from './createPieFromEditModelSaga';

export function* createPieFromShareToken(
  shareToken: string,
  isCrypto?: boolean | null | undefined,
): SagaIterator<string> {
  const { data }: SharedPieTokenQueryResult = yield call(apolloQuerySaga, {
    query: SharedPieTokenDocument,
    variables: {
      shareToken,
    },
  });

  if (!data?.viewer.sharedPie?.editModel) {
    throw new Error('Unable to create pie from share token');
  }
  return yield call(
    createPieFromEditModel,
    data.viewer.sharedPie.editModel,
    PieLabelEnum.SharedPie,
    isCrypto,
    true,
  );
}
