import { Box, styled, css, Skeleton, Flex } from '@m1/liquid-react';
import isNil from 'lodash-es/isNil';
import * as React from 'react';

import { SortDirectionEnum } from '~/graphql/types';

import { GridContext } from './context';

type SharedProps = Pick<React.CSSProperties, 'justifySelf' | 'textAlign'> & {
  align?: React.CSSProperties['textAlign'];
  onClick?: React.MouseEventHandler;
};

type StyledGridTableHeaderCellProps = SharedProps & {
  children: React.ReactNode;
  clickable: boolean;
  isNewStyle?: boolean;
};

export type GridTableHeaderCellProps = SharedProps & {
  label?: React.ReactNode;
  sortDirection?: SortDirectionEnum | null | undefined;
  className?: string;
};

const newStyleCss = css`
  font-weight: 600;
`;

export const StyledGridTableHeaderCell = styled(
  Skeleton,
)<StyledGridTableHeaderCellProps>`
  ${({ isNewStyle }) => isNewStyle && newStyleCss}

  margin: 12px 0;
  height: calc(100% - 24px);
  align-content: center;

  display: inline-flex;
  flex-direction: ${(props) =>
    props.align === 'left' ? 'row' : 'row-reverse'};
  align-items: center;

  color: ${(props) => props.theme.colors.foregroundNeutralSecondary};
  font-size: 12px;
  line-height: 1.5em;

  cursor: ${(props) => props.clickable && 'pointer'};
  justify-self: ${(props) => props.justifySelf};
  text-align: ${(props) => props.align};
`;

const StyledTriangle = styled(Box)<{
  $align: React.CSSProperties['textAlign'];
  $direction: SortDirectionEnum;
}>`
  display: inline-block;
  width: 9px;
  height: 5px;

  margin: ${(props) => {
    switch (props.$align) {
      case 'left':
        return '0 0 2px 8px';
      case 'right':
        return '0 8px 2px 0';
      default:
        return '0 0 2px 0';
    }
  }};

  background-color: ${(props) => props.theme.colors.foregroundNeutralSecondary};

  clip-path: ${(props) =>
    props.$direction === 'ASC'
      ? 'polygon(50% 0, 0 100%, 100% 100%);'
      : 'polygon(0 0, 100% 0, 50% 100%);'};
`;

export const GridTableHeaderCell = ({
  align = 'left',
  justifySelf,
  label,
  onClick,
  sortDirection,
  textAlign,
  ...rest
}: GridTableHeaderCellProps) => {
  const { isNewStyle } = React.useContext(GridContext);
  const isClickable = typeof onClick === 'function';
  const ariaLabel = isNil(sortDirection)
    ? undefined
    : `Sort ${sortDirection === 'ASC' ? 'descending' : 'ascending'}`;

  return (
    <StyledGridTableHeaderCell
      align={textAlign ?? align}
      clickable={isClickable}
      justifySelf={justifySelf}
      onClick={onClick}
      aria-label={ariaLabel}
      isNewStyle={isNewStyle}
      {...rest}
    >
      <Flex alignItems="center" height="100%">
        {label}
        {sortDirection && (
          <StyledTriangle
            $align={align ?? 'left'}
            $direction={sortDirection}
            role="presentation"
          />
        )}
      </Flex>
    </StyledGridTableHeaderCell>
  );
};
