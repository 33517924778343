import { css, styled } from '@m1/liquid-react';
import * as React from 'react';

import { CashFormatter } from '~/utils';

export type TransactionType = 'credit' | 'checking' | 'savings';

export type GainProps = {
  strikeThrough?: boolean;
  type?: TransactionType;
  value: number | null | undefined;
};

const StyledRoot = styled.span<{
  strikeThrough: boolean;
  type?: TransactionType;
  value: number;
}>`
  ${({ strikeThrough, theme, type, value }) => {
    if (strikeThrough) {
      return css`
        text-decoration: line-through;
      `;
    }
    if (
      (type === 'credit' && value > 0) ||
      ((type === 'checking' || type === 'savings') && value < 0)
    ) {
      return css`
        color: ${theme.colors.foregroundNeutralMain};
      `;
    }
    if (value > 0 || (type === 'credit' && value < 0)) {
      return css`
        color: ${theme.colors.success};
      `;
    }
    if (value < 0) {
      return css`
        color: ${theme.colors.critical};
      `;
    }
  }};
`;

export const Gain = ({ value, strikeThrough = false, type }: GainProps) => {
  if (typeof value !== 'number') {
    return <span>$--</span>;
  }

  const prefix = value > 0 && type !== 'credit' ? '+' : '';

  return (
    <StyledRoot strikeThrough={strikeThrough} value={value} type={type}>
      {prefix}
      {CashFormatter.format(value)}
    </StyledRoot>
  );
};
