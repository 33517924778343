import * as React from 'react';

import {
  DisclosureLink,
  DisclosureLinkProps,
} from '~/components/DisclosureLink/DisclosureLink';
import {
  AppDisclosureLinkFragment,
  AppRichTextDisclosureLinkFragment,
} from '~/graphql/types';

import { RichText } from '../RichText/RichText';

export const AppDisclosureLink = ({
  appDisclosureLink,
  ...rest
}: OmitUnion<DisclosureLinkProps, 'linkText' | 'paragraphs'> & {
  appDisclosureLink: AppDisclosureLinkFragment;
}) => {
  const { linkText, paragraphs } = appDisclosureLink;

  return (
    <DisclosureLink {...rest} linkText={linkText} paragraphs={paragraphs} />
  );
};

export const AppRichTextDisclosureLink = ({
  appRichTextDisclosureLink,
  ...rest
}: OmitUnion<DisclosureLinkProps, 'linkText' | 'paragraphs'> & {
  appRichTextDisclosureLink: AppRichTextDisclosureLinkFragment;
}) => {
  const { linkText, paragraphs } = appRichTextDisclosureLink;

  return (
    <DisclosureLink
      {...rest}
      linkText={linkText}
      paragraphs={
        paragraphs?.map((p, i) => (
          <RichText
            richText={p}
            key={i}
            containerProps={{ display: 'inline' }}
          />
        )) ?? [<React.Fragment key={1} />]
      }
    />
  );
};
