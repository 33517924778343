import { Button, Flex, HM, PL, Box, styled } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import React from 'react';

import { useFundingSources } from '~/hooks/useFundingSources';
import { useNavigate } from '~/hooks/useNavigate';
import { CONNECT_BANK_FLOW_STEPS } from '~/static-constants';
import { Divider } from '~/toolbox/divider';
import { Spinner } from '~/toolbox/spinner';

const Container = styled(Flex)`
  background: linear-gradient(
    180deg,
    rgba(166, 201, 218, 0.64) -20.23%,
    rgba(229, 239, 244, 0.08) 51.08%,
    rgba(166, 201, 218, 0) 119.94%
  );
  padding-top: 100px;
  height: 100%;
`;

export const PlaidVerifyMicrodeposits = () => {
  const { pendingFundingSources, loading } = useFundingSources();

  const navigate = useNavigate();
  const pendingFundingSource = pendingFundingSources[0];

  if (loading) {
    return <Spinner fullScreen />;
  }

  const handlePendingFundingSource = (): void => {
    navigate({
      to: '/d/c/connect-bank',
      query: {
        initialStep: CONNECT_BANK_FLOW_STEPS.SELECT_PLAID,
        connectionType: 'personal_loans',
        previousRouteName: '/d/borrow',
        fs: pendingFundingSource.id,
      },
    });
  };

  const illustrationName = 'bankConnectionBank';

  return (
    <Container alignItems="center" flexDirection="column">
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        maxWidth={588}
      >
        <Illustration name={illustrationName} />
        <HM mt={40}>Just one more step to finish setting up your account.</HM>
        <PL mt={24} mb={8}>
          Verify your external account to connect your bank, and we’ll disburse
          your funds.
        </PL>

        <Divider />

        <Box width="100%" marginTop={8}>
          <Button
            label="Connect a bank"
            onClick={() => {
              handlePendingFundingSource();
            }}
            size="large"
          />
        </Box>
      </Flex>
    </Container>
  );
};
