import { Box } from '@m1/liquid-react';
import React from 'react';

import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';
import { Tab } from '~/toolbox/tabs/Tab';

export const HoldingsNav = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dividendsIsActive = pathname.includes('dividends');

  return (
    <Box display="flex" justifyContent="start" margin="24px 0px">
      <Tab
        isActive={!dividendsIsActive}
        onClick={() => navigate({ to: '/d/invest/holdings' })}
        label="Overview"
      />
      <Tab
        isActive={dividendsIsActive}
        label="Dividend income"
        onClick={() => navigate({ to: '/d/invest/holdings/dividends' })}
      />
    </Box>
  );
};
