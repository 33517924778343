import { Box, Flex, HM, PM, SkeletonProvider } from '@m1/liquid-react';
import isNil from 'lodash-es/isNil';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import {
  useNotificationPageQuery,
  NotificationPageDocument,
  useSetNotificationPreferencesMutation,
} from '~/graphql/hooks';
import { useToast } from '~/toasts';
import { Spinner } from '~/toolbox/spinner';

import {
  NotificationPreferenceName,
  NotificationPreferenceRow,
  NotificationsPreferencesInputs,
} from './components/NotificationPreferenceRow';
import { NotificationPreferencesFooter } from './components/NotificationPreferencesFooter';

export const SettingsNotificationsPage = () => {
  const {
    data,
    loading: queryLoading,
    error,
    refetch,
  } = useNotificationPageQuery();

  const { addToast } = useToast();

  const [isSaveEnabled, setIsSaveEnabled] = React.useState<boolean>(false);

  const [inputValues, setInputValues] =
    React.useState<NotificationsPreferencesInputs>({
      investActivity: null,
      spendTransaction: null,
      transferCompletion: null,
    });

  React.useEffect(() => {
    const preferences = data?.viewer?.notifications?.preferences;

    setInputValues({
      investActivity: preferences?.investActivity ?? null,
      spendTransaction: preferences?.spendTransaction ?? null,
      transferCompletion: preferences?.transferCompletion ?? null,
    });
  }, [data?.viewer?.notifications?.preferences, setInputValues]);

  const [setNotificationPreferencesMutation, { loading: mutationLoading }] =
    useSetNotificationPreferencesMutation();

  if (queryLoading && !error) {
    return (
      <Box mt={32}>
        <Spinner />
      </Box>
    );
  }

  if (!data?.viewer) {
    return <GenericSystemError onClickRetry={refetch} />;
  }

  const handlePreferenceChange = (
    preferenceName: NotificationPreferenceName,
    event: React.SyntheticEvent<HTMLInputElement>,
  ): void => {
    setIsSaveEnabled(true);

    const existingPreferenceValues = inputValues[preferenceName];

    if (existingPreferenceValues === null) {
      return;
    }

    const { name, value } = event.currentTarget;

    const updatedPreferenceValue = value === 'false';

    setInputValues({
      ...inputValues,
      [preferenceName]: {
        ...existingPreferenceValues,
        [name]: updatedPreferenceValue,
      },
    });
  };

  const handleSaveClick = async () => {
    setIsSaveEnabled(false);

    // Need to omit the `__typename` field from the input before submitting the
    // mutation:
    const input = {
      investActivity: {
        sendViaEmail: inputValues.investActivity?.sendViaEmail,
        sendViaPush: inputValues.investActivity?.sendViaPush,
      },
      spendTransaction: {
        sendViaEmail: inputValues.spendTransaction?.sendViaEmail,
        sendViaPush: inputValues.spendTransaction?.sendViaPush,
      },

      transferCompletion: {
        sendViaEmail: inputValues.transferCompletion?.sendViaEmail,
        sendViaPush: inputValues.transferCompletion?.sendViaPush,
      },
    };

    const showErrorToast = (errorResponse: string): void => {
      let content;

      // prettier-ignore
      if (errorResponse === 'PREFERENCES_NOT_AVAILABLE') {
        content = 'Notifications cannot be modified right now. Please try again later.';
      } else {
        content = 'Something went wrong with your request. Please try again later or contact support.';
      }

      addToast({
        content,
        kind: 'alert',
        duration: 'short',
      });
    };

    await setNotificationPreferencesMutation({
      variables: { input },
      onCompleted(data) {
        const errorResponse = data.setNotificationPreferences?.error ?? null;

        if (isNil(errorResponse)) {
          addToast({
            content: 'Notifications updated.',
            kind: 'success',
            duration: 'short',
          });
        } else {
          showErrorToast(errorResponse);
        }
      },
      update(cache, { data }) {
        if (data?.setNotificationPreferences?.didSucceed) {
          const preferences =
            data?.setNotificationPreferences?.outcome?.preferences;
          cache.writeQuery({
            query: NotificationPageDocument,
            data: {
              viewer: {
                notifications: {
                  preferences: {
                    ...preferences,
                  },
                },
              },
            },
          });
        }
      },
      onError(err) {
        showErrorToast(err.message);
      },
    });
  };

  return (
    <SkeletonProvider isLoading={mutationLoading} fadeOut>
      <Flex ml={18} maxWidth={600} flexDirection="column">
        <HM content="Notification Settings" fontWeight={300} />
        <Flex
          alignItems="center"
          ml="auto"
          mb={16}
          width={124}
          justifyContent="space-around"
        >
          <PM content="Push" />
          <PM content="Email" />
        </Flex>

        <NotificationPreferenceRow
          title="Invest"
          content="Notifications for activity on your Invest accounts."
          preferenceName="investActivity"
          inputValues={inputValues.investActivity}
          areCheckboxesDisabled={mutationLoading}
          onChange={handlePreferenceChange}
        />

        <NotificationPreferenceRow
          title="Earn / Spend"
          content="Notifications for high-yield account transactions and Owner's Rewards Card."
          preferenceName="spendTransaction"
          inputValues={inputValues.spendTransaction}
          areCheckboxesDisabled={mutationLoading}
          onChange={handlePreferenceChange}
        />

        <NotificationPreferenceRow
          title="Transfers"
          content="Notifications for completed transfers."
          preferenceName="transferCompletion"
          inputValues={inputValues.transferCompletion}
          areCheckboxesDisabled={mutationLoading}
          onChange={handlePreferenceChange}
        />

        <NotificationPreferencesFooter
          isSaveEnabled={isSaveEnabled}
          handleSaveClick={handleSaveClick}
        />
      </Flex>
    </SkeletonProvider>
  );
};
