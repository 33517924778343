import * as React from 'react';
import { Route } from 'react-router-dom';

import { ForwardIfAuthGuard } from '~/router/Authentication/ForwardIfAuthGuard';

import { PersonalLoanDirectWizard } from './PersonalLoanDirectWizard';

export const PersonalLoanDirectWizardRoute = () => (
  <Route
    path="direct-loan-application"
    element={
      <ForwardIfAuthGuard>
        <PersonalLoanDirectWizard />
      </ForwardIfAuthGuard>
    }
    handle={{
      fallbackRoute: {
        to: '/signup',
      },
    }}
  />
);
