import { Flex, keyframes, css, styled } from '@m1/liquid-react';

import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';

const expandContainer = keyframes`
  to {
    max-height: 500px;
    pointer-events: initial;
  }
`;

const slideContentUp = keyframes`
  to {
    transform: translateY(0);
  }
`;

const fadeIn = keyframes`
  to {
    opacity: 1;
  }
`;

export const AnnouncementNudgeContainer = styled.div`
  max-height: 0;
  pointer-events: none;
  animation: ${expandContainer} 850ms linear forwards 600ms;

  background: ${({ theme }) =>
    css`radial-gradient(
        508.42% 164.81% at 100% 100%,
        ${theme.colors.teal02} 0%,
        ${theme.colors.jade01} 62.01%,
        ${theme.colors.teal02} 100%
  )`};
`;

export const AnnouncementNudgeContent = styled(Flex)`
  position: relative;
  min-height: 64px;
  padding: 24px 80px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;

  transform: translateY(100%);
  opacity: 0;
  animation:
    ${slideContentUp} 500ms ease forwards 600ms,
    ${fadeIn} 1000ms cubic-bezier(0.57, 0, 1, 1) forwards 550ms;
`;

export const AnnouncementIconContainer = styled.div`
  margin-right: 32px;

  @media screen and ${RESPONSIVE_BREAKPOINTS.TABLET} {
    margin-right: 24px;
    margin-left: 8px;
  }
`;
