import { Button, type ButtonProps } from '@m1/liquid-react';
import * as React from 'react';

import { BorrowMarketingHeaderAccountFragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';

type BorrowTransferButtonProps = Pick<ButtonProps, 'kind'> & {
  borrowAccount: BorrowMarketingHeaderAccountFragment;
  direction: 'Borrow' | 'Payback';
};

export const BorrowTransferButton = ({
  borrowAccount,
  direction,
  kind,
}: BorrowTransferButtonProps) => {
  const navigate = useNavigate();

  const { creditBorrowed, hasCreditAvailable } = borrowAccount;
  const isDisabled = React.useMemo(() => {
    if (direction === 'Borrow') {
      return !hasCreditAvailable;
    }
    if (direction === 'Payback') {
      return typeof creditBorrowed !== 'number' || creditBorrowed === 0;
    }
    return false;
  }, [hasCreditAvailable, creditBorrowed, direction]);

  const label = direction === 'Borrow' ? 'Borrow on margin' : 'Pay back';

  const directionKey =
    direction === 'Borrow' ? 'fromParticipantId' : 'toParticipantId';

  const query = {
    [directionKey]: borrowAccount.id,
    previousRouteName: '/d/borrow',
  };

  return (
    <Button
      disabled={isDisabled}
      kind={kind}
      label={label}
      onClick={() => {
        navigate({ to: '/d/c/create-transfer', query });
      }}
      size="medium"
    />
  );
};
