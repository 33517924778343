import { Box, Flex, styled } from '@m1/liquid-react';
import React from 'react';

import { SetupChecklistCard } from '~/components/SetupChecklistCard';
import { PortfolioDetailsFooter } from '~/pages/dashboard/invest/portfolio/PortfolioDetailsPage/components/PortfolioDetailsFooter';
import { Carousel } from '~/toolbox/carousel';

import { CarouselArrow } from '~/toolbox/carousel/Arrows';

import { PieCardContent } from './components/PieContent';
import { PortfolioDetailsContainer } from './components/PortfolioDetailsContainer';
import { PortfolioSliceActionsButtonGroup } from './components/PortfolioSliceActionsButtonGroup';
import { PortfolioSliceParentBreadcrumb } from './components/PortfolioSliceParentBreadcrumb';
import { PortfolioSliceValueHistoryChart } from './components/PortfolioSliceValueHistoryChart';
import { TradingDetailsSection } from './components/TradingDetailsSection';
import { PortfolioDetailsContext } from './PortfolioDetailsContext';
import { BreadcrumbContainer } from './PortfolioDetailsPage.styled';

const CarouselContainer = styled(Box)`
  padding: 0 1rem;
  position: relative;
  width: 100%;
  height: fit-content;

  /** For some reason, :first-of-type doesn't work */
  ${CarouselArrow}:nth-child(2) {
    left: -48px;

    @media (max-width: 700px) {
      left: -24px;
    }
  }

  ${CarouselArrow}:last-of-type {
    right: -48px;

    @media (max-width: 700px) {
      right: -24px;
    }
  }
`;

export const PortfolioDetailsMobileView = () => {
  const { account, portfolioSlice, isCrypto } = React.useContext(
    PortfolioDetailsContext,
  );

  const isAccountSetup = Boolean(!account?.setupChecklist);

  return (
    <CarouselContainer>
      <Carousel
        fullSize
        scrollSnap
        indicator="dots"
        fixedProgressIndicator={false}
        items={[
          <React.Fragment key="left">
            <Flex justifyContent="space-evenly" flexDirection="column" px={2}>
              <Box>
                {portfolioSlice.__typename === 'ChildPortfolioSlice' && (
                  <BreadcrumbContainer>
                    <PortfolioSliceParentBreadcrumb
                      portfolioSlice={portfolioSlice}
                    />
                  </BreadcrumbContainer>
                )}
                <PieCardContent />
              </Box>
              <Box>
                <TradingDetailsSection />
              </Box>
            </Flex>
            <Box maxWidth={384} mx="auto" mt={16}>
              <PortfolioSliceActionsButtonGroup
                account={account}
                portfolioSlice={portfolioSlice}
              />
            </Box>
          </React.Fragment>,
          <React.Fragment key="right">
            <Flex width="100%" alignItems="center" px={2}>
              {isAccountSetup ? (
                <PortfolioSliceValueHistoryChart
                  portfolioSlice={portfolioSlice}
                />
              ) : (
                account?.setupChecklist && (
                  <SetupChecklistCard
                    toParticipantId={account.id}
                    setupChecklist={account.setupChecklist}
                  />
                )
              )}
            </Flex>
          </React.Fragment>,
        ]}
      />
      <PortfolioDetailsContainer />
      <PortfolioDetailsFooter isCrypto={isCrypto} />
    </CarouselContainer>
  );
};
