import React from 'react';

type UseTrackRemoteSubmitStateInput = {
  loading: boolean;
};

type Behavior =
  | 'auto-submit-always'
  | 'auto-submit-just-once'
  | 'never-auto-submit';

type SubmitEvent =
  | React.SyntheticEvent<HTMLInputElement | HTMLFormElement | HTMLButtonElement>
  | null
  | undefined;

type UseAutoSubmitInput = {
  behavior: Behavior;
  codeValue: string;
  handleSubmitThrottled: (e: SubmitEvent, code: string) => void;
  hasFinishedSubmittingOnce: boolean;
};

type UseTrackRemoteSubmitStateResult = {
  hasFinishedSubmittingOnce: boolean;
  setSubmitAttemptsCount: (arg0: number) => void;
  submitAttemptsCount: number;
};

export function useTrackRemoteSubmitState({
  loading,
}: UseTrackRemoteSubmitStateInput): UseTrackRemoteSubmitStateResult {
  const [submitAttemptsCount, setSubmitAttemptsCount] =
    React.useState<number>(0);
  const [hasFinishedSubmittingOnce, setHasFinishedSubmittingOnce] =
    React.useState(false);

  React.useEffect(() => {
    // use 'loading' state to track when a full submit is complete
    // this would be much easier of submits were async
    // @ts-expect-error - TS7034 - Variable 'timeout' implicitly has type 'any' in some locations where its type cannot be determined.
    let timeout;
    if (submitAttemptsCount === 1 && !loading) {
      // add a full second delay to allow side effects like re-routing
      // to occur before we update our state
      timeout = window.setTimeout(() => {
        setHasFinishedSubmittingOnce(true);
      }, 1000);
    }

    return () => {
      // @ts-expect-error - TS7005 - Variable 'timeout' implicitly has an 'any' type.
      window.clearTimeout(timeout);
    };
  }, [hasFinishedSubmittingOnce, loading, submitAttemptsCount]);

  return {
    submitAttemptsCount,
    setSubmitAttemptsCount,
    hasFinishedSubmittingOnce,
  };
}

export function useHandleAutoSubmit({
  codeValue,
  behavior,
  hasFinishedSubmittingOnce,
  handleSubmitThrottled,
}: UseAutoSubmitInput): void {
  React.useEffect(() => {
    // auto-submit-behavior
    if (
      codeValue.length === 6 &&
      (behavior === 'auto-submit-always' ||
        (behavior === 'auto-submit-just-once' && !hasFinishedSubmittingOnce))
    ) {
      handleSubmitThrottled(null, codeValue);
    }
  }, [behavior, codeValue]);
}
