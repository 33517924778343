import { PL, HXXS, Box } from '@m1/liquid-react';
import React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';
import { useRemoveCreditFreezePageQuery } from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { Checkbox } from '~/toolbox/checkbox';
import { List } from '~/toolbox/list';
import { Spinner } from '~/toolbox/spinner';

import { StyledHeader } from './StyledHeader';
import { WithdrawApplicationModal } from './WithdrawApplicationModal';

type RejectedCreditFrozenFormProps = {
  handleResubmitLoanAfterCreditFreeze: () => void;
  applicationId: string;
  loanId?: string;
};

export const RejectedCreditFrozenForm = ({
  handleResubmitLoanAfterCreditFreeze,
  applicationId,
  loanId,
}: RejectedCreditFrozenFormProps) => {
  const analytics = useAnalytics();

  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [isChecked, setIsChecked] = React.useState<boolean>(false);

  const { data, loading } = useRemoveCreditFreezePageQuery();

  const loanDeclinedErrorScreenData =
    data?.viewer?.borrow?.personalLoans?.applicationFlow
      ?.loanDeclinedErrorScreen;

  if (loading) {
    return <Spinner fullScreen centered />;
  }

  if (!data) {
    return <GenericSystemError />;
  }

  return (
    loanDeclinedErrorScreenData && (
      <div>
        <WithdrawApplicationModal
          loanId={loanId}
          applicationId={applicationId}
          isOpen={modalIsOpen}
          setIsOpen={setModalIsOpen}
        />
        <StyledHeader content={loanDeclinedErrorScreenData.title} />
        <PL content={loanDeclinedErrorScreenData.subtitle} pt={8} pb={8} />
        {loanDeclinedErrorScreenData.bureaus && (
          <>
            <HXXS
              mt={25}
              fontWeight={500}
              content="Credit bureau(s) to unfreeze:"
            />
            <List>
              {loanDeclinedErrorScreenData.bureaus?.map((bureau) => {
                return (
                  <List.Item key={bureau} mt={10} ml={30} content={bureau} />
                );
              })}
            </List>
          </>
        )}
        <Checkbox
          ml={-4}
          mt={40}
          label={
            loanDeclinedErrorScreenData.checkBoxText ??
            'My credit is no longer frozen.'
          }
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
        <Box mt={48}>
          <ResponsiveButton
            kind="primary"
            label="Continue"
            disabled={!isChecked}
            onClick={() => {
              analytics.recordEvent(
                'm1_personal_loan_credit_freeze_resubmit_clicked',
              );
              handleResubmitLoanAfterCreditFreeze();
            }}
            size="large"
          />
          <ResponsiveButton
            kind="text"
            label="Withdraw application"
            size="large"
            onClick={() => {
              if (loanDeclinedErrorScreenData.analyticsEvent?.name) {
                analytics.recordEvent(
                  loanDeclinedErrorScreenData.analyticsEvent.name,
                );
              }
              return setModalIsOpen(true);
            }}
          />
        </Box>
      </div>
    )
  );
};
