import { styled, css } from '@m1/liquid-react';

export const LoanDetailsList = styled.ul(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      justify-content: space-between;
      padding: 16px 0;
      ${theme.typography.PL};

      span {
        &:first-child {
          color: ${theme.colors.foregroundNeutralSecondary};
        }

        &:last-child {
          color: ${theme.colors.foregroundNeutralMain};
          font-weight: 600;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid ${theme.colors.borderMain};
      }
    }
  `,
);
