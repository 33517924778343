import { Box, Button, Flex, PL, PM, PXL } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import { LegacyIllustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useStartApplicationQuery } from '~/graphql/hooks';
import { Spinner } from '~/toolbox/spinner';

type StartApplicationProps = {
  onFinishStep: () => void;
};

export const StartApplication = ({ onFinishStep }: StartApplicationProps) => {
  const { data, loading } = useStartApplicationQuery();
  if (loading) {
    return <Spinner />;
  }

  const startApplicationScreenContent =
    data?.viewer.credit?.onboardingContent?.startApplicationScreen;

  if (!startApplicationScreenContent) {
    return <GenericSystemError />;
  }

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      mt={150}
      justifyContent="center"
    >
      <LegacyIllustration alt="" name="creditCard" />
      <Box maxWidth={500}>
        <PXL
          textAlign="center"
          fontWeight={600}
          py={32}
          content={startApplicationScreenContent.header}
        />
        <PL
          fontWeight={600}
          pb={16}
          content={startApplicationScreenContent.label}
        />
        {startApplicationScreenContent.content.map((instruction) => (
          <Flex key={instruction} alignItems="flex-start" mb={32}>
            <Icon name="check24" />
            <PL ml={21} content={instruction} />
          </Flex>
        ))}
        <PM content={startApplicationScreenContent.disclaimer} />
        <Button
          data-testid="credit-card-application-start-label"
          my={64}
          kind="primary"
          size="large"
          label={startApplicationScreenContent.buttonLabel}
          onClick={onFinishStep}
        />
      </Box>
    </Flex>
  );
};
