import { Text, Flex, Color, styled } from '@m1/liquid-react';
import * as React from 'react';

export type SwitchProps = {
  checked?: boolean;
  disabled?: boolean;
  id?: string;
  label?: React.ReactNode;
  loading?: boolean;
  name?: string;
  onChange?: (...args: Array<any>) => any;
};

// Styled Components
// @ts-expect-error - TS7006 - Parameter 'props' implicitly has an 'any' type.
const useTheme = (props, variable: Color) => props.theme.colors[variable];

const StyledSwitchContainer = styled.label<{
  $disabled: boolean;
}>`
  align-items: center;
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  opacity: ${(props) => (props.$disabled ? '0.6' : '1')};
  position: relative;
`;

type StyledSwitchInputProps = {
  checked: boolean;
  id?: string;
  name?: string;
  onChange: (...args: Array<any>) => any;
  readOnly: boolean;
  type: string;
};
const StyledSwitchInput = styled.input<StyledSwitchInputProps>`
  border: 0;
  height: 1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

type StyledSwitchBackgroundProps = {
  $checked: boolean;
  $disabled: boolean;
};

const StyledSwitchBackground = styled(Flex)<StyledSwitchBackgroundProps>`
  width: 52px;
  background-color: ${(props) => {
    if (props.$disabled) {
      return useTheme(props, 'backgroundNeutralTertiary');
    }

    return useTheme(
      props,
      props.$checked ? 'primary' : 'foregroundNeutralTertiary',
    );
  }};
  border-radius: 32px;
  padding: 2.5px;
`;

type StyledSwitchThumbProps = {
  $checked: boolean;
  $disabled: boolean;
  $loading: boolean;
};

const StyledSwitchThumb = styled.div<StyledSwitchThumbProps>`
  background: ${(props) =>
    useTheme(
      props,
      props.$disabled ? 'foregroundNeutralTertiary' : 'foregroundNeutralOnDark',
    )};
  border: ${(props) =>
    props.$disabled &&
    `0.5px solid ${useTheme(props, 'foregroundNeutralTertiary')}`};
  border-radius: 50%;
  box-shadow: ${(props) =>
    props.$disabled
      ? 'none'
      : '0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1),\n    0 3px 1px 0 rgba(0, 0, 0, 0.05);'};
  height: 28px;
  left: ${(props) => (props.$checked ? 'calc(100% - 28px)' : '0')};
  margin-top: -0.5px;
  position: relative;
  transition: left 0.15s ease-in-out;
  width: 28px;

  // loading state, mostly copy and pasted from global spinner
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    ${({ $loading }) => {
      if ($loading) {
        return `animation: load8 1.1s infinite linear;`;
      }
      return `opacity: 0;`;
    }}
    display: inline-block;
    transform: translateZ(0);
    border-radius: 50%;
    content: '';
    width: 22px;
    height: 22px;
    border-left: 3px solid ${(props) => useTheme(props, 'primary')};
    border-top: 3px solid
      ${(props) => useTheme(props, 'foregroundNeutralTertiary')};
    border-right: 3px solid
      ${(props) => useTheme(props, 'foregroundNeutralTertiary')};
    border-bottom: 3px solid
      ${(props) => useTheme(props, 'foregroundNeutralTertiary')};

    @keyframes load8 {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

export const Switch = ({
  id,
  name,
  label,
  checked = false,
  disabled = false,
  loading = false,
  onChange = () => {},
}: SwitchProps) => {
  const handleChange = (event: React.SyntheticEvent<any>): void => {
    if (!disabled) {
      onChange(!checked, event);
    }
  };

  return (
    <StyledSwitchContainer $disabled={disabled}>
      <StyledSwitchInput
        checked={checked}
        id={id}
        name={name}
        onChange={handleChange}
        readOnly
        type="checkbox"
      />
      <StyledSwitchBackground
        $checked={checked}
        {...(id && {
          id: `${id}-background`,
        })}
        $disabled={disabled}
        alignItems="center"
      >
        <StyledSwitchThumb
          {...(id && {
            id: `${id}-thumb`,
          })}
          $loading={loading}
          $checked={checked}
          $disabled={disabled}
        />
      </StyledSwitchBackground>
      {label && <Text content={label} pl={16} />}
    </StyledSwitchContainer>
  );
};
