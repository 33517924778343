import * as React from 'react';

import { ExternalLink } from '~/components/ExternalLink';

import { TransferRequirementsTimingDescriptionCellFragment } from '~/graphql/types';

import { CellIconName, Cell } from '~/toolbox/cell';

export type TimingDescriptionCellProps =
  NonNullable<TransferRequirementsTimingDescriptionCellFragment>;

export const TimingDescriptionCell = ({
  dependsOnHolds,
  dependsOnSells,
  timingDescription,
}: TimingDescriptionCellProps) => {
  let title;
  let iconName: CellIconName = 'hold24';
  if (dependsOnHolds || dependsOnSells) {
    if (!dependsOnHolds) {
      title = 'Pending sells';
      iconName = 'bell24';
    } else if (!dependsOnSells) {
      title = 'Pending holds';
    } else {
      title = 'Pending holds and sells';
    }
  }

  return (
    <Cell
      content={
        <>
          {timingDescription}{' '}
          <ExternalLink
            destination="SUPPORT_TRANSFER_HOLDS_SELLS"
            label="Learn more"
            font="LS"
          />
        </>
      }
      iconName={iconName}
      iconAlignSelf="flex-start"
      title={title}
      titleFont="PM"
    />
  );
};
