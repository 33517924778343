import { Button } from '@m1/liquid-react';
import React from 'react';
import { useDispatch } from 'react-redux';

import { clickedResendVerificationEmail } from '~/redux/actions';
import { NOTIFICATION_LOCATIONS } from '~/static-constants';

type ResendEmailVerificationActionProps = {
  notificationLocation?: ValueOf<typeof NOTIFICATION_LOCATIONS>;
};

export const ResendEmailVerificationAction = ({
  notificationLocation,
}: ResendEmailVerificationActionProps) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    const notificationProps = notificationLocation
      ? {
          errorNotificationLocation: notificationLocation,
          successNotificationLocation: notificationLocation,
        }
      : {};

    dispatch(clickedResendVerificationEmail({ ...notificationProps }));
  };

  return (
    <Button
      kind="destructive-text"
      label="Resend email"
      style={{
        padding: '0px',
        height: 'auto',
        minHeight: 0,
      }}
      onClick={handleClick}
    />
  );
};
