import { Box } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import { DownloadCSV } from '~/components/DownloadCSV/DownloadCSV';
import { prepareActivityForDownload } from '~/components/DownloadCSV/utils';
import { InvestActivityNodeFragment } from '~/graphql/types';

import { StyledFilterFlex } from './ActivityTable.styled';

import { FilterByDateRange } from './FilterByDateRange';
import { FilterBySymbol } from './FilterBySymbol';
import { FilterByTypePopup } from './FilterByTypePopup';

export const InvestFiltersContainer = ({
  activities,
}: {
  activities: InvestActivityNodeFragment[];
}) => {
  return (
    <StyledFilterFlex>
      <FilterByDateRange />
      <FilterBySymbol />
      <FilterByTypePopup />
      <Box mt={8}>
        <DownloadCSV
          label="Activity summary"
          filename={'Activity-' + moment().format('MMM-DD-YYYY')}
          headers={['Date', 'Activity', 'Summary', 'Value']}
          data={prepareActivityForDownload(activities)}
        />
      </Box>
    </StyledFilterFlex>
  );
};
