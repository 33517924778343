import { ACCOUNT_ACTIONS } from './account';
import { APP_ACTIONS } from './app';
import { AUTH_ACTIONS } from './auth';
import { BENEFICIARY_ACTIONS } from './beneficiaries';
import { BORROW_MARGIN_ACTIONS } from './borrowMarginActions';
import { EVENT_ACTIONS } from './events';
import { FLOW_ACTIONS } from './flowsActions';
import { FORM_ACTIONS } from './formActions';
import { GLOBAL_ACTIONS } from './globalActions';
import { INTERFACE_ACTIONS } from './interfaceActions';
import { MODAL_ACTION_TYPES } from './modalActions';
import { MODE_ACTIONS } from './modeActions';
import { MONITIOR_ACTIONS } from './monitorActions';
import { NAVIGATION_ACTIONS } from './navigationActions';
import { NEW_FLOWS_ACTIONS } from './newFlows';
import { NOTIFICATION_ACTIONS } from './notification';
import { OPEN_INVEST_ACCOUNT_ACTIONS } from './openInvestAccountActions';
import { OTHER_ACTIONS } from './otherActions';
import { PIE_ORGANIZAER_ACTIONS } from './pieOrganizer';
import { REMOTE_ACTIONS } from './remoteActions';
import { ROUTING_ACTIONS } from './routingActions';
import { SECURITY_DETAIL_ACTIONS } from './securityDetailsActions';
import { SELECTED_SLICES_ACTIONS } from './selectedSlicesActions';
import { SMART_TRANSFER_ACTIONS } from './smartTransfer';
import { TOAST_ACTIONS } from './toastActions';
import { UNMANAGED_HOLDINGS_ACTIONS } from './unmanagedHoldingsActions';
import { USER_DATA_ACTIONS } from './userDataActions';
import { VERIFY_EMAIL_BANNER_ACTIONS } from './verifyEmailBannerActions';
import { WATCHLIST_ACTIONS } from './watchlistActions';

export const ACTIONS = Object.freeze({
  ...ACCOUNT_ACTIONS,
  ...APP_ACTIONS,
  ...AUTH_ACTIONS,
  ...BENEFICIARY_ACTIONS,
  ...BORROW_MARGIN_ACTIONS,
  ...EVENT_ACTIONS,
  ...FLOW_ACTIONS,
  ...FORM_ACTIONS,
  ...GLOBAL_ACTIONS,
  ...INTERFACE_ACTIONS,
  ...MODAL_ACTION_TYPES,
  ...MODE_ACTIONS,
  ...MONITIOR_ACTIONS,
  ...NAVIGATION_ACTIONS,
  ...NEW_FLOWS_ACTIONS,
  ...NOTIFICATION_ACTIONS,
  ...OPEN_INVEST_ACCOUNT_ACTIONS,
  ...OTHER_ACTIONS,
  ...PIE_ORGANIZAER_ACTIONS,
  ...REMOTE_ACTIONS,
  ...ROUTING_ACTIONS,
  ...SECURITY_DETAIL_ACTIONS,
  ...SELECTED_SLICES_ACTIONS,
  ...SMART_TRANSFER_ACTIONS,
  ...TOAST_ACTIONS,
  ...UNMANAGED_HOLDINGS_ACTIONS,
  ...USER_DATA_ACTIONS,
  ...VERIFY_EMAIL_BANNER_ACTIONS,
  ...WATCHLIST_ACTIONS,
});
