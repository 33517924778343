import { Box, Skeleton, SkeletonProvider } from '@m1/liquid-react';
import * as React from 'react';

import { usePersonalLoanProductLandingPageBannersQuery } from '~/graphql/hooks';
import { InformationBanner } from '~/lens-toolbox/InformationBanner';

export const LandingPageBanners = ({ accountId }: { accountId: string }) => {
  const { data, loading } = usePersonalLoanProductLandingPageBannersQuery({
    variables: {
      loanId: accountId,
    },
  });

  if (data?.node?.__typename !== 'PersonalLoan') {
    return null;
  }

  const { banners } = data.node.personalLoanProductLandingPage || {};

  if (!banners) {
    return null;
  }

  return (
    <SkeletonProvider isLoading={loading} fadeOut>
      <Skeleton skeletonHeight="100%" skeletonWidth="100%">
        <Box mb={-32}>
          {banners.map((banner, index) => (
            <InformationBanner
              appBanner={banner}
              my={16}
              position="relative"
              key={index}
              contain={false}
            />
          ))}
        </Box>
      </Skeleton>
    </SkeletonProvider>
  );
};
