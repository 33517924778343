import { Slice } from '~/pie-trees';

export const mergeSlicesForMove = (
  existingSlices: Array<Slice>,
  slicesToMove: Array<Slice>,
): Array<Slice> => {
  // @ts-expect-error - TS7034 - Variable 'head' implicitly has type 'any[]' in some locations where its type cannot be determined.
  const head = [];
  let tail = [...existingSlices];

  slicesToMove.forEach((slice) => {
    const matchingSliceIndex = tail.findIndex(
      (s) => s.to.__id === slice.to.__id,
    );

    if (matchingSliceIndex > -1) {
      const matchingSlice = tail[matchingSliceIndex];
      matchingSlice.to.__highlighted__ = true;

      if (matchingSlice.to.type !== 'new_pie') {
        matchingSlice.to.__merged__ = true;
      }

      head.push(matchingSlice);
      tail = tail.filter((s) => s.to.__id !== matchingSlice.to.__id);
    } else {
      head.push({
        ...slice,
        percentage: 1,
        to: {
          ...slice.to,
          __checked: false,
          __highlighted__: true,
        },
      });
    }
  });

  // @ts-expect-error - TS7005 - Variable 'head' implicitly has an 'any[]' type.
  return head.concat(tail);
};
