import { Modal, ModalContent } from '@m1/liquid-react';
import * as React from 'react';

import { CreatePieForm } from './Forms';

export const PieOrganizerCreatePieFormModal = ({
  onCancel,
  onConfirm,
  open,
}: {
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
}) => {
  return (
    <Modal onClose={onCancel} open={open}>
      <ModalContent width="wide" withPadding={false}>
        <CreatePieForm onCancel={onCancel} onCreate={onConfirm} />
      </ModalContent>
    </Modal>
  );
};
