import { Box, HXXS, Skeleton, SkeletonProvider, Card } from '@m1/liquid-react';
import * as React from 'react';

import { usePersonalLoanProductLandingPageLoanDetailsQuery } from '~/graphql/hooks';
import { AppImage } from '~/lens-toolbox/AppImage';
import {
  StyledDetailsCard,
  StyledDetailsCardContainer,
  StyledDetailsCardDivider,
} from '~/pages/dashboard/common/ProductLandingPageCard.styled';

import { LinkOrText } from '../components/LinkOrText';

export const LoanDetailsCard = ({ accountId }: { accountId: string }) => {
  const { data, loading } = usePersonalLoanProductLandingPageLoanDetailsQuery({
    variables: {
      loanId: accountId,
    },
  });

  if (data?.node?.__typename !== 'PersonalLoan') {
    return null;
  }

  const { equalHousingIcon } = data.node.assets || {};
  const { header, details } =
    data.node.personalLoanProductLandingPage?.loanDetails || {};

  return (
    <SkeletonProvider isLoading={loading} fadeOut>
      <Skeleton skeletonWidth="100%" mt={24}>
        <StyledDetailsCardContainer id="loan-details-card">
          <Card
            minHeight={190}
            p={24}
            backgroundColor="backgroundNeutralSecondary"
            minWidth={248}
          >
            <HXXS fontWeight={300} content={header} mb={16} />
            <>
              {details?.map((detail, i) => {
                const { title, titleLink, value, valueLink } = detail || {};
                return (
                  <Box key={`loan-detail-${i}`}>
                    <StyledDetailsCard
                      minHeight={48}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <LinkOrText text={title} link={titleLink} />
                      <LinkOrText text={value} link={valueLink} />
                    </StyledDetailsCard>
                    {i !== details.length - 1 && <StyledDetailsCardDivider />}
                  </Box>
                );
              })}
            </>
          </Card>
          {equalHousingIcon && (
            <Box mt={8}>
              <AppImage
                width={35}
                height={35}
                appImage={equalHousingIcon}
                alt="Equal Housing Lender icon"
              />
            </Box>
          )}
        </StyledDetailsCardContainer>
      </Skeleton>
    </SkeletonProvider>
  );
};
