import { Button } from '@m1/liquid-react';
import * as React from 'react';

import {
  CreditRewardDestinationRefetchDocument,
  useUpdateCreditCardRewardPayoutDestinationMutation,
} from '~/graphql/hooks';

import { SelectRewardDestinationContentFragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useToast } from '~/toasts';
import { Dropdown } from '~/toolbox/Dropdown';

export type PayoutActionsProps = {
  destinationDetails: Pick<
    SelectRewardDestinationContentFragment,
    'placeholder' | 'destinationOptions' | 'buttonLabel'
  >;
};

export const CreditCardPayoutActions = ({
  destinationDetails,
}: PayoutActionsProps) => {
  const [destination, setDestination] = React.useState('');
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [updateCreditCardPayoutDestination, { loading }] =
    useUpdateCreditCardRewardPayoutDestinationMutation();

  const handleSubmit = () => {
    updateCreditCardPayoutDestination({
      variables: {
        input: {
          rewardPayoutDestinationId: destination,
        },
      },
      onCompleted: () => {
        navigate({ to: '/d/spend/credit/rewards/summary' });
        addToast({
          kind: 'success',
          content: 'Your payout location has been successfully updated.',
          duration: 'short',
        });
      },
      onError: (err) => {
        addToast({
          kind: 'alert',
          content: err.message,
          duration: 'short',
        });
      },
      refetchQueries: [
        {
          query: CreditRewardDestinationRefetchDocument,
        },
      ],
    });
  };

  usePortaledSpinner(loading);

  const options = destinationDetails.destinationOptions?.map((destination) => ({
    label: destination?.name ?? '',
    value: destination?.id ?? '',
  }));

  return (
    <>
      <Dropdown
        onChange={setDestination}
        options={options}
        value={destination}
        label={destinationDetails.placeholder ?? ''}
        name="rewards-payout-location"
      />
      <Button
        kind="primary"
        size="large"
        mt={32}
        label={destinationDetails.buttonLabel}
        onClick={handleSubmit}
        disabled={!destination}
      />
    </>
  );
};
