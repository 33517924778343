import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { PersonalLoansApplicationFlow } from '~/flows';
import { useNavigate } from '~/hooks/useNavigate';

const PersonalLoansApplication = () => {
  const navigate = useNavigate();
  const handleFinish = () => {
    navigate({
      to: '/d/borrow/marketing',
      options: {
        state: {
          forceRefetch: true,
        },
      },
    });
  };
  return (
    <Box backgroundColor="backgroundNeutralSecondary" height="100vh">
      <PageErrorBoundary onClickRetry={handleFinish} retryButtonLabel="Exit">
        <PersonalLoansApplicationFlow
          basePath="d/borrow/personal/loan-application"
          onFinish={handleFinish}
        />
      </PageErrorBoundary>
    </Box>
  );
};

export const PersonalLoansApplicationPage = PersonalLoansApplication;
