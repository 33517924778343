import * as React from 'react';
import { Route } from 'react-router-dom';

import { StockScreenerPage } from '~/pages/dashboard/research/stocks/components';

import { AddStockDetailsRoute } from './details';

export const AddStocksRoute = () => {
  return (
    <Route path="stocks">
      {AddStockDetailsRoute()}
      <Route index element={<StockScreenerPage />} />
    </Route>
  );
};
