import { Flex, styled } from '@m1/liquid-react';

export const StyledApplicationContainer = styled(Flex)`
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 56px auto 24px auto;
`;
