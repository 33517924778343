import { styled } from '@m1/liquid-react';

export const SuccessIcon = styled.span`
  &::after {
    content: '';
    display: block;

    width: 5px;
    height: 10px;

    ${(props) =>
      `border: solid ${props.theme.colors.primary};`} border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: relative;
    bottom: 1px;
  }
`;
