import { Flex, HXXS, PL, PXL, styled } from '@m1/liquid-react';
import * as React from 'react';

import { ValueToDisplay } from './utils';

export type SectionRowData = {
  label: string;
  value: string | Array<string>;
  disabled?: boolean;
};

type IdentityFirstConfirmationScreenSectionProps = {
  headingLabel: string;
  editLabel: string | null;
  onEdit?: () => void | null;
  rows: Array<SectionRowData>;
  footer?: React.ReactNode;
};

const StyledEditLabel = styled(PXL)`
  cursor: pointer;
`;

export const IdentityFirstConfirmationScreenSection = ({
  headingLabel,
  editLabel,
  onEdit,
  rows,
  footer,
}: IdentityFirstConfirmationScreenSectionProps) => {
  return (
    <Flex flexDirection="column" width="100%" pt={4} pb={12}>
      <Flex justifyContent="space-between" pb={4}>
        <HXXS color="foregroundNeutralMain" content={headingLabel} />
        {editLabel && onEdit && (
          <StyledEditLabel
            content={editLabel}
            fontWeight={600}
            onClick={onEdit}
            color="primary"
          />
        )}
      </Flex>
      <Flex flexDirection="column">
        {rows.map((row) => {
          return (
            <Flex
              justifyContent="space-between"
              key={`${row.label}-${JSON.stringify(row.value)}`}
            >
              <Flex width="50%" pr={4}>
                <PL
                  color="foregroundNeutralSecondary"
                  content={row.label}
                  key={row.label}
                  py={4}
                />
              </Flex>
              <Flex width="50%" pl={4}>
                <ValueToDisplay
                  value={row.value}
                  color={
                    row.disabled
                      ? 'foregroundNeutralSecondary'
                      : 'foregroundNeutralMain'
                  }
                />
              </Flex>
            </Flex>
          );
        })}
      </Flex>
      {footer}
    </Flex>
  );
};
