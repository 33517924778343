import * as React from 'react';
import { Route } from 'react-router-dom';

import { BorrowAccountDetailsPage } from './BorrowAccountDetailsPage';
import { MarginMarketingRoute } from './marketing';

export const MarginRoute = () => {
  return (
    <Route path="margin">
      {MarginMarketingRoute()}
      <Route index element={<BorrowAccountDetailsPage />} />
    </Route>
  );
};
