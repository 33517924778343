import { Box, Flex, PM, PXL, theme } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { Logo } from '~/toolbox/logo';

import { AcatWizardStepCard } from '../components/AcatWizardStepCard';
import { AcatWizardStepContainer } from '../components/AcatWizardStepContainer';

import { useAcatWizardFormContext } from '../hooks/useAcatWizardFormContext';

export const AcatWizardConfirmedAssetsStep = () => {
  const { goTo, getValues } = useAcatWizardFormContext();

  return (
    <AcatWizardStepContainer>
      <BackArrow onClick={() => goTo('acat-confirm')} caret content="Back" />
      <AcatWizardStepCard>
        <PXL fontWeight={600} textAlign="right">
          Shares
        </PXL>
        {getValues('assets').map((asset) => (
          <Flex
            key={JSON.stringify(asset)}
            flexDirection="column"
            py={16}
            css={{
              '&:not(:last-child)': {
                'borderBottom': `1px solid ${theme.colors.borderMain}`,
              },
            }}
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Flex alignItems="center" flexDirection="row" gap={8}>
                <Box height={40} width={40} flex="0 0 auto">
                  <Logo content={asset.logoUrl} placeholder={asset.symbol} />
                </Box>
                <Box flex="1 0 auto">
                  <PM color="foregroundNeutralSecondary" fontWeight={400}>
                    {asset.symbol}
                  </PM>
                  <PM color="foregroundNeutralMain" fontWeight={600}>
                    {asset.name}
                  </PM>
                </Box>
              </Flex>
              <Box>{asset.quantity}</Box>
            </Flex>
          </Flex>
        ))}
      </AcatWizardStepCard>
    </AcatWizardStepContainer>
  );
};
