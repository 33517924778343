import * as React from 'react';
import { Outlet, Route } from 'react-router-dom';

import { InvestProductSelectionPage } from '~/pages/start/InvestProductSelectionPage';

import { RequireAuthGuard } from '~/router/Authentication/RequireAuthGuard';
import { BEGIN_ONBOARDING_FLOW_STEPS as STEPS } from '~/static-constants';

import { ProductSelectionPage, SetGoalPage } from './screens';

export const StartRoute = () => {
  return (
    <Route
      path="start"
      element={
        <RequireAuthGuard>
          <Outlet />
        </RequireAuthGuard>
      }
    >
      <Route
        path={STEPS.PRODUCT_SELECTION}
        element={<ProductSelectionPage />}
      />
      <Route path={STEPS.SET_GOALS} element={<SetGoalPage />} />
      <Route
        path={STEPS.INVEST_PRODUCT_SELECTION}
        element={<InvestProductSelectionPage />}
      />
    </Route>
  );
};
