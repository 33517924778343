import { Box, Button, Flex } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useCreditCardApplicationSummaryQuery } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import { Spinner } from '~/toolbox/spinner';

import { CreditCardApplicationSummary } from '../components/CreditCardApplicationSummary';

type PrefilledSummaryProps = {
  onFinishStep: () => void;
};

export const PreFilledSummary = ({ onFinishStep }: PrefilledSummaryProps) => {
  const { data, loading, error } = useCreditCardApplicationSummaryQuery();
  const navigate = useNavigate();

  if (loading) {
    return <Spinner />;
  }

  if (error || !data?.viewer.credit) {
    return <GenericSystemError />;
  }

  if (!data.viewer.credit.applicantInformation) {
    return (
      <GenericSystemError
        title="Oh no!"
        content="Something went wrong! If the problem persists, please contact support."
        button={
          <Button
            kind="primary"
            size="large"
            label="Close"
            onClick={() => {
              navigate({ to: '/d/spend' });
            }}
          />
        }
      />
    );
  }

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      alignContent="center"
      pb={45}
    >
      <Box width={700}>
        <CreditCardApplicationSummary
          creditInformation={data.viewer.credit}
          onFinishStep={onFinishStep}
        />
        <Box mt={60}>
          <Button
            kind="secondary"
            label="Update Profile Information"
            onClick={() => {
              navigate({ to: '/d/settings' });
            }}
            size="large"
          />
          <Button
            kind="primary"
            label="Next: Financial Information"
            size="large"
            ml={12}
            onClick={onFinishStep}
          />
        </Box>
      </Box>
    </Flex>
  );
};
