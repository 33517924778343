import { Button } from '@m1/liquid-react';
import * as React from 'react';

import { clickedAddToPortfolio } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

type AddToPortfolioLinkProps = {
  pieId: string;
};

export const AddToPortfolioLinkButton = ({
  pieId,
}: AddToPortfolioLinkProps) => {
  const dispatch = useDispatch();

  return (
    <Button
      kind="link"
      label="Add to Portfolio"
      onClick={() => dispatch(clickedAddToPortfolio(pieId))}
    />
  );
};
