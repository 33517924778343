import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { AppCard } from '~/lens-toolbox/AppCard/AppCard';
import { Carousel } from '~/toolbox/carousel';

import { PortfolioDetailsContext } from '../PortfolioDetailsContext';

import { AccountTradingPausedCard } from './AccountTradingStatus/AccountTradingPausedCard';
import { Automation } from './AccountTradingStatus/Automation';
import { OnDemandTradingStatusCard } from './OnDemandTradingStatusCard';
import { PortfolioSliceFirstPositionSection } from './PortfolioSliceFirstPositionSection';
import { UpcomingActivity } from './UpcomingActivity';

type SliceType =
  | 'CryptoAsset'
  | 'CryptoSystemPie'
  | 'Equity'
  | 'Fund'
  | 'SystemPie'
  | 'UserPie'
  | 'SharedPie'
  | undefined;

const isPie = <T extends SliceType>(sliceType: T) => {
  return (
    sliceType && ['SystemPie', 'UserPie', 'CryptoSystemPie'].includes(sliceType)
  );
};

export const TradingDetailsSection = () => {
  const { portfolioSlice, account, isCrypto } = React.useContext(
    PortfolioDetailsContext,
  );

  const cards = portfolioSlice.investProductLandingPage?.promotionCards || [];

  return (
    <>
      <AccountTradingPausedCard />
      <OnDemandTradingStatusCard
        isCrypto={isCrypto}
        portfolioSliceId={portfolioSlice.id}
        trading={account?.trading}
      />
      <UpcomingActivity portfolioSlice={portfolioSlice} account={account} />
      <Box mt={24}>
        {cards.length > 0 && (
          <Carousel
            indicator="numbers"
            fullSize
            scrollSnap
            fixedProgressIndicator={false}
            items={cards.map((card, i) => (
              <AppCard
                hasSiblingCards={cards.length > 1}
                key={i}
                appCard={card}
              />
            ))}
          />
        )}
      </Box>
      {isPie(portfolioSlice.to.__typename) ? (
        <Automation account={account} />
      ) : (
        <PortfolioSliceFirstPositionSection portfolioSlice={portfolioSlice} />
      )}
    </>
  );
};
