import {
  AUTH_ACTIONS,
  LoginAction,
  LogoutAction,
  ProvideAccountChallengeAction,
  RegisterUserAction,
  ResetPasswordAction,
} from './authActions.types';

export const registerUser = (
  payload: RegisterUserAction['payload'],
): RegisterUserAction => ({
  payload,
  type: AUTH_ACTIONS.REGISTER_USER,
});

export const login = (payload: LoginAction['payload']): LoginAction => ({
  payload,
  type: AUTH_ACTIONS.LOGIN,
});

export const resetPassword = (
  payload: ResetPasswordAction['payload'],
): ResetPasswordAction => ({
  payload,
  type: AUTH_ACTIONS.RESET_PASSWORD,
});

export const provideAccountChallenge = (
  payload: ProvideAccountChallengeAction,
): ProvideAccountChallengeAction => ({
  payload,
  type: AUTH_ACTIONS.PROVIDE_SSN_CHALLENGE,
});

export const logout = (): LogoutAction => ({
  type: AUTH_ACTIONS.LOGOUT,
});
