import * as React from 'react';
import { Route } from 'react-router-dom';

import { RequireAuthGuard } from '~/router/Authentication/RequireAuthGuard';

import { LogoutPage } from './LogoutPage';

export const LogoutRoute = () => {
  return (
    <Route
      path="logout"
      element={
        <RequireAuthGuard>
          <LogoutPage />
        </RequireAuthGuard>
      }
    />
  );
};
